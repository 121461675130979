import { createContext, ReactNode, useEffect } from "react";
import { User } from "@datadog/browser-core";
import { getClientConfigVar } from "src/client/config";
import packageJson from "../../../package.json";
import { useCurrentUser } from "../auth/useCurrentUser";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogUserMetadata } from "./utils";

export const datadogLoggerInstance = createContext(datadogLogs);

export function DatadogLoggerProvider({ children }: { children: ReactNode }) {
  useEffect(() => {
    if (getClientConfigVar("CLIENT_LOGGING_ENABLED")) {
      datadogLogs.init({
        clientToken: getClientConfigVar("CLIENT_DATADOG_CLIENT_TOKEN"),
        site: getClientConfigVar("CLIENT_DATADOG_SITE"),
        service: getClientConfigVar("CLIENT_DATADOG_SERVICE"),
        forwardConsoleLogs: "all",
        forwardReports: "all",
        sessionSampleRate: 100,
        version: packageJson.version,
        env: getClientConfigVar("CLIENT_STAGE"),
      });
    }
  }, []);

  const user = useCurrentUser();

  useEffect(() => {
    if (user === undefined) return;

    datadogLogs.setGlobalContext(datadogUserMetadata(user) as User);
  }, [user]);

  return (
    <datadogLoggerInstance.Provider value={datadogLogs}>
      {children}
    </datadogLoggerInstance.Provider>
  );
}
