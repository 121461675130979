// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var ReducerUtils = require("../ReducerUtils/index.js");
var update = function (v) {
    return function (v1) {
        if (v1 instanceof Actions.SetRoute) {
            return new Data_Maybe.Just(v1.value0);
        };
        return v;
    };
};
var initialState = Data_Maybe.Nothing.value;
var reducer = ReducerUtils.mkReducer(initialState)(update);
module.exports = {
    initialState: initialState,
    update: update,
    reducer: reducer
};
