// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Calculation_Income = require("../Calculation.Income/index.js");
var Client_WASM = require("../Client.WASM/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Controls = require("../Controls/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_JSDate = require("../Data.JSDate/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var DateFnsFFI = require("../DateFnsFFI/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var Foreign_Object = require("../Foreign.Object/index.js");
var Locale = require("../Locale/index.js");
var $$Math = require("../Math/index.js");
var NarrowLayout = require("../NarrowLayout/index.js");
var OwnProjectTaxes = require("../OwnProjectTaxes/index.js");
var R200Calculations = require("../R200Calculations/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoDropdown = require("../TacoDropdown/index.js");
var TacoDropdown_Types = require("../TacoDropdown.Types/index.js");
var TacoLoader = require("../TacoLoader/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var UseFetch = require("../UseFetch/index.js");
var UseLocalStorage = require("../UseLocalStorage/index.js");
var Utils = require("../Utils/index.js");
var ShowSumChart = (function () {
    function ShowSumChart() {

    };
    ShowSumChart.value = new ShowSumChart();
    return ShowSumChart;
})();
var ShowChartFor89 = (function () {
    function ShowChartFor89() {

    };
    ShowChartFor89.value = new ShowChartFor89();
    return ShowChartFor89;
})();
var ShowMonthlyProfitsChart = (function () {
    function ShowMonthlyProfitsChart() {

    };
    ShowMonthlyProfitsChart.value = new ShowMonthlyProfitsChart();
    return ShowMonthlyProfitsChart;
})();
var ShowTargetsChart = (function () {
    function ShowTargetsChart() {

    };
    ShowTargetsChart.value = new ShowTargetsChart();
    return ShowTargetsChart;
})();
var prepareYAxisSumHorizontal = function (groups) {
    return {
        type: "category",
        data: Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.name;
        })(Data_Array.reverse(groups)),
        axisLine: {
            show: false
        },
        axisTick: {
            show: false
        },
        axisLabel: {
            color: "grey",
            fontSize: 14
        }
    };
};
var prepareYAxis = function (min, max) {
    return {
        show: true,
        type: "value",
        min: min,
        max: max,
        boundaryGap: [ 0.0, 0.1 ]
    };
};
var prepareXAxisSumHorizontal = function (max) {
    return {
        show: true,
        type: "value",
        max: $$Math.ceil(((max * 1.1) / 100000.0) * 100000.0),
        boundaryGap: [ 0.0, 0.1 ]
    };
};
var prepareXAxis = function (groups) {
    return {
        type: "category",
        data: groups,
        axisLabel: {
            color: "grey",
            fontSize: 14,
            interval: 0
        }
    };
};
var prepareGridSumHorizontal = function (v) {
    return {
        left: "2%",
        right: "10%",
        bottom: "3%",
        containLabel: true
    };
};
var prepareGrid = function (v) {
    return {
        left: "2%",
        right: "250px",
        bottom: "0%",
        containLabel: true
    };
};
var getChartXAxisInterval = function (maxLabelsVisible) {
    return function (xLength) {
        return Data_Int.floor(xLength / maxLabelsVisible);
    };
};
var getAccumulatedChartLineData = function (props) {
    var isItemDateBefore = function (date) {
        return function (item) {
            return DateFnsFFI.isBefore(props.toDate(item))(date);
        };
    };
    var valuesUntilDate = function (date) {
        return Data_Array.filter(isItemDateBefore(date))(props.items);
    };
    var dataEntryForDate = function (date) {
        return Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(props.toValue)(valuesUntilDate(date)));
    };
    return Data_Functor.map(Data_Functor.functorArray)(dataEntryForDate)(props.dates);
};
var component = TofuHooks.mkHookComponent("MonthlyReportContainer")(function (v) {
    return function __do() {
        var v1 = UseFetch.useFetchItemsByProjectId(v.projectId)(function (v1) {
            return v1.costGroups;
        })(Actions.ProjectCostGroupsRequest.create)();
        var v2 = UseFetch.useFetchValueByProjectId(v.projectId)(function (v2) {
            return v2.mainCostGroups;
        })(Actions.MainCostGroupsRequest.create)();
        var v3 = UseFetch.useFetchItemsByProjectId(v.projectId)(function (v3) {
            return v3.estimateItems;
        })(Actions.ProjectEstimateItemsRequest.create)();
        var v4 = UseFetch.useFetchItemsByProjectId(v.projectId)(function (v4) {
            return v4.incomeItems;
        })(Actions.GetIncomeItemsRequest.create)();
        var v5 = UseFetch.useFetchItemsByProjectId(v.projectId)(function (v5) {
            return v5.socialExpenses;
        })(Actions.SocialExpensesRequest.create)();
        var v6 = UseFetch.useFetchItemsByProjectId(v.projectId)(function (v6) {
            return v6.invoices;
        })(Actions.GetInvoicesRequest.create)();
        var v7 = Client_WASM.useCostClasses(v.projectId)();
        var v8 = UseFetch.useFetchItemsByProjectId(v.projectId)(function (v8) {
            return v8.salesInvoices;
        })(Actions.GetSalesInvoicesRequest.create)();
        var v9 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v9) {
            return v9.costControlExcludeActualCostSocialExpenses;
        })(Actions.GetCostControlExcludeActualCostSocialExpensesRequest.value)();
        var disableSocialCostToActual = (function () {
            if (v9.value0 instanceof Data_Maybe.Just && v9.value0.value0.costControlExcludeActualCostSocialExpenses) {
                return Calculation_Income.DisableSocialCostToActual.value;
            };
            return Calculation_Income.AllowSocialCostToActual.value;
        })();
        var mainCostGroups = Data_Maybe.fromMaybe(Foreign_Object.empty)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v10) {
            return v10.items;
        })(v2.value0));
        var currentDate$prime = Effect_Unsafe.unsafePerformEffect(Data_JSDate.now);
        var endMonth$prime = DateFnsFFI["endOfMonth'"](currentDate$prime);
        var startMonth$prime = DateFnsFFI["startOfMonth'"](DateFnsFFI.subMonths(endMonth$prime)(3));
        var v10 = TofuHooks.useState(startMonth$prime)();
        var v11 = TofuHooks.useState(new Data_Maybe.Just(endMonth$prime))();
        var v12 = TofuHooks.useState(currentDate$prime)();
        var v13 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)("showActualIncomeBasedOnApprovedIncomeItemsOnly.JSON")(false)(false)();
        var v14 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)("showSumChart.JSON")(false)(false)();
        var v15 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)("showChartFor89.JSON")(false)(false)();
        var v16 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)("showMonthlyProfitsChart")(false)(false)();
        var v17 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)("showTargetsChart")(false)(false)();
        var v18 = UseFetch.useFetchValueByProjectId(v.projectId)(function (v18) {
            return v18.selectedProject;
        })(Actions.GetProjectRequest.create)();
        var toggleProperty = function (property) {
            if (property instanceof ShowSumChart) {
                return v14.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean));
            };
            if (property instanceof ShowChartFor89) {
                return v15.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean));
            };
            if (property instanceof ShowMonthlyProfitsChart) {
                return v16.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean));
            };
            if (property instanceof ShowTargetsChart) {
                return v17.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean));
            };
            throw new Error("Failed pattern match at MonthlyReportContainer (line 122, column 31 - line 126, column 50): " + [ property.constructor.name ]);
        };
        var mkShowChartDropdown = function (labelText) {
            return function (isChecked) {
                return function (property) {
                    return TacoCheckbox.component()()({
                        className: "toggle-item",
                        isChecked: isChecked,
                        onToggle: new TacoCheckbox.NoEvent(toggleProperty(property)),
                        labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel(labelText)),
                        testId: "toggle-item-" + labelText
                    });
                };
            };
        };
        var actionsDropdownComponent = Controls.component()()({
            rightControls: [ TacoDropdown.uncontrolled({
                sections: [ {
                    title: Data_Maybe.Just.create(Locale.lookup_("report_dropdown_actions_category__view")),
                    items: [ TacoCheckbox.component()()({
                        className: "use-only-approved-incomeItems-toggle",
                        isChecked: v13.value0,
                        onToggle: TacoCheckbox.NoEvent.create(v13.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))),
                        labelText: Data_Maybe.Just.create(TacoCheckbox.StringLabel.create(Locale.lookup_("report_action_toggle_actual_income_based_on_approved_incomeItems_only"))),
                        testId: "use-only-approved-incomeItems-toggle"
                    }), mkShowChartDropdown("N\xe4yt\xe4 vaaka p\xe4\xe4ryhm\xe4t")(v14.value0)(ShowSumChart.value), mkShowChartDropdown("N\xe4yt\xe4 suhteellinen 89")(v15.value0)(ShowChartFor89.value), mkShowChartDropdown("N\xe4yt\xe4 pysty p\xe4\xe4ryhm\xe4t")(v16.value0)(ShowMonthlyProfitsChart.value), mkShowChartDropdown("N\xe4yt\xe4 p\xe4\xe4ryhmien muutokset")(v17.value0)(ShowTargetsChart.value) ]
                } ],
                align: TacoDropdown_Types.AlignRight.value,
                testId: "monthly-report-actions-dropdown"
            }) ]
        });
        var _costItems = Data_Functor.map(Data_Functor.functorArray)(R200Calculations.costGroupToR200CostGroupItem)(v1.value0);
        var costItems = R200Calculations.getMainR200CostGroupItems(_costItems)(mainCostGroups);
        var datas = Control_Bind.bind(Data_Maybe.bindMaybe)(v18.value0)(function (v19) {
            var reportData = R200Calculations.getMonthlyReportData({
                estimateItems: v3.value0,
                incomeItems: v4.value0,
                socialExpenses: v5.value0,
                costItems: costItems,
                startMonth: v10.value0,
                endMonth: v11.value0,
                currentDate: v12.value0,
                makeTimelineSlicer: v.makeTimelineSlicer,
                state: {
                    startMonth: v10.value0,
                    endMonth: v11.value0,
                    currentDate: v12.value0
                },
                setState: {
                    setStartMonth: v10.value1,
                    setEndMonth: v11.value1,
                    setCurrentDate: v12.value1
                },
                invoices: v6.value0,
                ownProject: v19.project.ownProject,
                projectTaxStatus: OwnProjectTaxes.getProjectTaxRate(v19.project),
                costClasses: v7.value0,
                projectStartDate: v19.project.startDate,
                projectEndDate: v19.project.endDate
            });
            var actualCostsAndIncomeChartData = (function () {
                var v20 = Data_Nullable.toMaybe(v19.project.endDate);
                var v21 = Data_Nullable.toMaybe(v19.project.startDate);
                if (v21 instanceof Data_Maybe.Just && v20 instanceof Data_Maybe.Just) {
                    var valueWithSocialExpenses = function (x) {
                        var mCostClass = Data_Array.find((function () {
                            var $125 = Data_Eq.eq(Types_Newtypes.eqCostClassCode)(x.costClassCode);
                            return function ($126) {
                                return $125((function (v22) {
                                    return v22.costClassCode;
                                })($126));
                            };
                        })())(v7.value0);
                        var mSEP = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function ($127) {
                            return Data_Nullable.toMaybe((function (v22) {
                                return v22.socialExpensePercentageInCostControlForEstimatesAndActuals;
                            })($127));
                        })(mCostClass);
                        var socialExpensesPercentage = (function () {
                            if (disableSocialCostToActual instanceof Calculation_Income.AllowSocialCostToActual) {
                                return Data_Maybe.fromMaybe(0.0)(mSEP);
                            };
                            return 0.0;
                        })();
                        return x.value * (1.0 + socialExpensesPercentage);
                    };
                    var projectTaxRate = OwnProjectTaxes.getProjectTaxRate(v19.project);
                    var projectEndDate = Effect_Unsafe.unsafePerformEffect(Data_JSDate.parse(v20.value0));
                    var nowDate = Effect_Unsafe.unsafePerformEffect(Data_JSDate.now);
                    var toDate = DateFnsFFI.min(nowDate)(projectEndDate);
                    var fromDate = Effect_Unsafe.unsafePerformEffect(Data_JSDate.parse(v21.value0));
                    var monthsForWholeProject = DateFnsFFI.monthsBetween(fromDate)(new Data_Maybe.Just(projectEndDate));
                    var monthLabels = Data_Functor.map(Data_Functor.functorArray)(function (month) {
                        return DateFnsFFI.format(month)("M/YY");
                    })(monthsForWholeProject);
                    var monthsFromProjectStart = DateFnsFFI.monthsBetween(fromDate)(new Data_Maybe.Just(toDate));
                    var accumulatedLinearCostsEstimateChartLine = (function () {
                        var projectTotalEstimatedCosts = OwnProjectTaxes.applyTaxesForCosts(projectTaxRate)(Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v22) {
                            return v22.estimatedCosts;
                        })(v1.value0)));
                        var projectMonthsCount = Data_Int.toNumber(Data_Array.length(monthsForWholeProject));
                        var estimatedSocialExpenses = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v22) {
                            return v22.estimatedSocialExpenses;
                        })(v5.value0));
                        var projectTotalEstimatedWithSocialExp = estimatedSocialExpenses + Data_Newtype.unwrap()(projectTotalEstimatedCosts.total);
                        var calcAvgForMonth = function (idx) {
                            return function (v22) {
                                return (Data_Int.toNumber(idx + 1 | 0) / projectMonthsCount) * projectTotalEstimatedWithSocialExp;
                            };
                        };
                        return Data_Array.mapWithIndex(calcAvgForMonth)(monthsForWholeProject);
                    })();
                    var accumulatedIncomeEstimateChartLine = getAccumulatedChartLineData({
                        dates: DateFnsFFI.monthsBetween(fromDate)(new Data_Maybe.Just(projectEndDate)),
                        items: v4.value0,
                        toValue: (function () {
                            var $128 = Data_Newtype.unwrap();
                            var $129 = OwnProjectTaxes.applyTaxesForIncomeItems(projectTaxRate)(function (v22) {
                                return v22.valueWithoutVAT;
                            });
                            return function ($130) {
                                return $128((function (v22) {
                                    return v22.total;
                                })($129($130)));
                            };
                        })(),
                        toDate: function ($131) {
                            return Utils.lossyParseJSDate((function (v22) {
                                return v22.plannedInvoiceDate;
                            })($131));
                        }
                    });
                    var accumulatedIncomeActualChartLine = (function () {
                        if (v13.value0) {
                            return getAccumulatedChartLineData({
                                dates: monthsFromProjectStart,
                                items: Data_Array.filter(function (v22) {
                                    return v22.isApproved;
                                })(v4.value0),
                                toValue: (function () {
                                    var $132 = Data_Newtype.unwrap();
                                    var $133 = OwnProjectTaxes.applyTaxesForIncomeItems(projectTaxRate)(function (v22) {
                                        return v22.valueWithoutVAT;
                                    });
                                    return function ($134) {
                                        return $132((function (v22) {
                                            return v22.total;
                                        })($133($134)));
                                    };
                                })(),
                                toDate: (function () {
                                    var $135 = Utils.defaultNull("");
                                    return function ($136) {
                                        return Utils.lossyParseJSDate($135((function (v22) {
                                            return v22.dateOfApproval;
                                        })($136)));
                                    };
                                })()
                            });
                        };
                        return getAccumulatedChartLineData({
                            dates: monthsFromProjectStart,
                            items: v8.value0,
                            toValue: OwnProjectTaxes["applyTaxesForItemWithTaxRate'"](projectTaxRate)(function (v22) {
                                return v22.valueWithoutVAT;
                            }),
                            toDate: (function () {
                                var $137 = Utils.defaultNull("");
                                return function ($138) {
                                    return Utils.lossyParseJSDate($137((function (v22) {
                                        return v22.date;
                                    })($138)));
                                };
                            })()
                        });
                    })();
                    var accumulatedCostsActualChartLine = getAccumulatedChartLineData({
                        dates: monthsFromProjectStart,
                        items: v6.value0,
                        toValue: (function () {
                            var $139 = Data_Newtype.unwrap();
                            var $140 = OwnProjectTaxes.applyTaxesForCosts(projectTaxRate);
                            return function ($141) {
                                return $139((function (v22) {
                                    return v22.total;
                                })($140(valueWithSocialExpenses($141))));
                            };
                        })(),
                        toDate: (function () {
                            var $142 = Utils.defaultNull("");
                            return function ($143) {
                                return Utils.lossyParseJSDate($142((function (v22) {
                                    return v22.date;
                                })($143)));
                            };
                        })()
                    });
                    return {
                        monthLabels: monthLabels,
                        actualCosts: {
                            data: accumulatedCostsActualChartLine,
                            name: Locale.lookup_("monthly_report__actual_costs_chart_legend")
                        },
                        actualIncome: {
                            data: accumulatedIncomeActualChartLine,
                            name: Locale.lookup_("monthly_report__actual_income_chart_legend")
                        },
                        estimatedCosts: {
                            data: accumulatedLinearCostsEstimateChartLine,
                            name: Locale.lookup_("monthly_report__estimated_costs_chart_legend")
                        },
                        estimatedIncome: {
                            data: accumulatedIncomeEstimateChartLine,
                            name: Locale.lookup_("monthly_report__estimated_income_chart_legend")
                        }
                    };
                };
                return {
                    monthLabels: [  ],
                    actualCosts: {
                        data: [  ],
                        name: ""
                    },
                    actualIncome: {
                        data: [  ],
                        name: ""
                    },
                    estimatedCosts: {
                        data: [  ],
                        name: ""
                    },
                    estimatedIncome: {
                        data: [  ],
                        name: ""
                    }
                };
            })();
            return Control_Applicative.pure(Data_Maybe.applicativeMaybe)({
                reportData: reportData,
                actualCostsAndIncomeChartData: actualCostsAndIncomeChartData
            });
        });
        var charts = function (v19) {
            if (v19 instanceof Data_Maybe.Nothing) {
                return TacoLoader.component()()({
                    size: "2rem"
                });
            };
            if (v19 instanceof Data_Maybe.Just) {
                return v.makeCharts({
                    actionsDropdownComponent: actionsDropdownComponent,
                    reportData: v19.value0.reportData,
                    costItems: costItems,
                    actualCostsAndIncomeChartData: v19.value0.actualCostsAndIncomeChartData,
                    showSumChart: v14.value0,
                    showChartFor89: v15.value0,
                    showMonthlyProfitsChart: v16.value0,
                    showTargetsChart: v17.value0
                });
            };
            throw new Error("Failed pattern match at MonthlyReportContainer (line 273, column 5 - line 273, column 61): " + [ v19.constructor.name ]);
        };
        return NarrowLayout.component()()({
            children: [ charts(datas) ],
            verticalPadding: true
        });
    };
});
var mkMonthlyReportContainer = component;
module.exports = {
    mkMonthlyReportContainer: mkMonthlyReportContainer,
    prepareGrid: prepareGrid,
    prepareGridSumHorizontal: prepareGridSumHorizontal,
    prepareXAxis: prepareXAxis,
    prepareXAxisSumHorizontal: prepareXAxisSumHorizontal,
    prepareYAxis: prepareYAxis,
    prepareYAxisSumHorizontal: prepareYAxisSumHorizontal,
    getChartXAxisInterval: getChartXAxisInterval
};
