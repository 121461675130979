// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var UseCallbackRef = require("../UseCallbackRef/index.js");
var Viewport_Styles = require("../Viewport.Styles/index.js");
var component = TofuHooks.mkHookComponent("Viewport")(function (props) {
    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        className: "Viewport",
        css: Viewport_Styles.viewportContainerStyles,
        ref: UseCallbackRef.toNodeRef(props.viewportRef),
        children: [ React_Basic_DOM_Generated.canvas()({
            tabIndex: 1,
            id: "dixon-canvas"
        }) ]
    }));
});
module.exports = {
    component: component
};
