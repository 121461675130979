// Generated by purs version 0.14.5
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Kishimen = require("../Kishimen/index.js");
var ReadParam = require("../ReadParam/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Supplier = function (x) {
    return x;
};
var SubprojectId = function (x) {
    return x;
};
var ProjectType = function (x) {
    return x;
};
var ProjectRoleId = function (x) {
    return x;
};
var DoesntExist = (function () {
    function DoesntExist() {

    };
    DoesntExist.value = new DoesntExist();
    return DoesntExist;
})();
var DeletePMSuccess = (function () {
    function DeletePMSuccess() {

    };
    DeletePMSuccess.value = new DeletePMSuccess();
    return DeletePMSuccess;
})();
var EmptyPMName = (function () {
    function EmptyPMName() {

    };
    EmptyPMName.value = new EmptyPMName();
    return EmptyPMName;
})();
var TooLongPMName = (function () {
    function TooLongPMName() {

    };
    TooLongPMName.value = new TooLongPMName();
    return TooLongPMName;
})();
var DuplicatePM = (function () {
    function DuplicatePM() {

    };
    DuplicatePM.value = new DuplicatePM();
    return DuplicatePM;
})();
var AddPMSuccess = (function () {
    function AddPMSuccess() {

    };
    AddPMSuccess.value = new AddPMSuccess();
    return AddPMSuccess;
})();
var ProjectManager = function (x) {
    return x;
};
var ProjectId = function (x) {
    return x;
};
var ProjectGroup = function (x) {
    return x;
};
var ProjectCode = function (x) {
    return x;
};
var FinancingType = function (x) {
    return x;
};
var Currency = function (x) {
    return x;
};
var CopyCostGroupsFrom = (function () {
    function CopyCostGroupsFrom(value0) {
        this.value0 = value0;
    };
    CopyCostGroupsFrom.create = function (value0) {
        return new CopyCostGroupsFrom(value0);
    };
    return CopyCostGroupsFrom;
})();
var DontCopyCostGroups = (function () {
    function DontCopyCostGroups() {

    };
    DontCopyCostGroups.value = new DontCopyCostGroups();
    return DontCopyCostGroups;
})();
var ContractType = function (x) {
    return x;
};
var ConstructionType = function (x) {
    return x;
};
var CompanyName = function (x) {
    return x;
};
var CompanyId = function (x) {
    return x;
};
var BusinessId = function (x) {
    return x;
};
var ShouldUpdateBusinessId = (function () {
    function ShouldUpdateBusinessId(value0) {
        this.value0 = value0;
    };
    ShouldUpdateBusinessId.create = function (value0) {
        return new ShouldUpdateBusinessId(value0);
    };
    return ShouldUpdateBusinessId;
})();
var NoOpShouldUpdateBusinessId = (function () {
    function NoOpShouldUpdateBusinessId() {

    };
    NoOpShouldUpdateBusinessId.value = new NoOpShouldUpdateBusinessId();
    return NoOpShouldUpdateBusinessId;
})();
var ReportingMode = (function () {
    function ReportingMode() {

    };
    ReportingMode.value = new ReportingMode();
    return ReportingMode;
})();
var EstimationMode = (function () {
    function EstimationMode() {

    };
    EstimationMode.value = new EstimationMode();
    return EstimationMode;
})();
var OrganizationMode = (function () {
    function OrganizationMode() {

    };
    OrganizationMode.value = new OrganizationMode();
    return OrganizationMode;
})();
var IntegrationMode = (function () {
    function IntegrationMode() {

    };
    IntegrationMode.value = new IntegrationMode();
    return IntegrationMode;
})();
var AdminMode = (function () {
    function AdminMode() {

    };
    AdminMode.value = new AdminMode();
    return AdminMode;
})();
var SuperAdminMode = (function () {
    function SuperAdminMode() {

    };
    SuperAdminMode.value = new SuperAdminMode();
    return SuperAdminMode;
})();
var EtcMode = (function () {
    function EtcMode() {

    };
    EtcMode.value = new EtcMode();
    return EtcMode;
})();
var AllocateToProjectCode = (function () {
    function AllocateToProjectCode(value0) {
        this.value0 = value0;
    };
    AllocateToProjectCode.create = function (value0) {
        return new AllocateToProjectCode(value0);
    };
    return AllocateToProjectCode;
})();
var AllocateToProjectId = (function () {
    function AllocateToProjectId(value0) {
        this.value0 = value0;
    };
    AllocateToProjectId.create = function (value0) {
        return new AllocateToProjectId(value0);
    };
    return AllocateToProjectId;
})();
var AllocateToProject = function (x) {
    return x;
};
var AddSubproject = (function () {
    function AddSubproject(value0) {
        this.value0 = value0;
    };
    AddSubproject.create = function (value0) {
        return new AddSubproject(value0);
    };
    return AddSubproject;
})();
var EditSubproject = (function () {
    function EditSubproject(value0) {
        this.value0 = value0;
    };
    EditSubproject.create = function (value0) {
        return new EditSubproject(value0);
    };
    return EditSubproject;
})();
var DeleteSubproject = (function () {
    function DeleteSubproject(value0) {
        this.value0 = value0;
    };
    DeleteSubproject.create = function (value0) {
        return new DeleteSubproject(value0);
    };
    return DeleteSubproject;
})();
var writeParamSupplier = ReadParam.writeParamString;
var writeParamSubprojectId = ReadParam.writeParamInt;
var writeParamProjectType = ReadParam.writeParamString;
var writeParamProjectManager = ReadParam.writeParamString;
var writeParamProjectId = ReadParam.writeParamInt;
var writeParamProjectGroup = ReadParam.writeParamString;
var writeParamProjectCode = ReadParam.writeParamString;
var writeParamFinancingType = ReadParam.writeParamString;
var writeParamContractType = ReadParam.writeParamString;
var writeParamConstructionType = ReadParam.writeParamString;
var writeParamBusinessId = ReadParam.writeParamString;
var tofuJSONSupplier = TofuJSON.readString;
var tofuJSONSubprojectId = TofuJSON.readInt;
var tofuJSONProjectType = TofuJSON.readString;
var tofuJSONProjectRoleId = TofuJSON.readString;
var tofuJSONProjectManager = TofuJSON.readString;
var tofuJSONProjectId = TofuJSON.readInt;
var tofuJSONProjectGroup = TofuJSON.readString;
var tofuJSONProjectCode = TofuJSON.readString;
var tofuJSONFinancingType = TofuJSON.readString;
var tofuJSONCurrency = TofuJSON.readString;
var tofuJSONContractType = TofuJSON.readString;
var tofuJSONConstructionType = TofuJSON.readString;
var tofuJSONCompanyName = TofuJSON.readString;
var tofuJSONCompanyId = TofuJSON.readInt;
var tofuJSONBusinessId = TofuJSON.readString;
var stringToApplicationMode = function (v) {
    if (v === "ReportingMode") {
        return ReportingMode.value;
    };
    if (v === "EstimationMode") {
        return EstimationMode.value;
    };
    if (v === "OrganizationMode") {
        return OrganizationMode.value;
    };
    if (v === "IntegrationMode") {
        return IntegrationMode.value;
    };
    if (v === "AdminMode") {
        return AdminMode.value;
    };
    if (v === "SuperAdminMode") {
        return SuperAdminMode.value;
    };
    if (v === "EtcMode") {
        return EtcMode.value;
    };
    return EtcMode.value;
};
var showSupplier = Data_Show.showString;
var showSubprojectId = Data_Show.showInt;
var showProjectType = Data_Show.showString;
var showProjectManager = Data_Show.showString;
var showProjectId = Data_Show.showInt;
var showProjectGroup = Data_Show.showString;
var showProjectCode = Data_Show.showString;
var showFinancingType = Data_Show.showString;
var showContractType = Data_Show.showString;
var showConstructionType = Data_Show.showString;
var showCompanyName = Data_Show.showString;
var showBusinessId = Data_Show.showString;
var readParamSupplier = ReadParam.stringReadParam;
var readParamSubprojectId = ReadParam.intReadParam;
var readParamProjectType = ReadParam.stringReadParam;
var readParamProjectManager = ReadParam.stringReadParam;
var readParamProjectId = ReadParam.intReadParam;
var readParamProjectGroup = ReadParam.stringReadParam;
var readParamProjectCode = ReadParam.stringReadParam;
var readParamFinancingType = ReadParam.stringReadParam;
var readParamContractType = ReadParam.stringReadParam;
var readParamConstructionType = ReadParam.stringReadParam;
var readParamBusinessId = ReadParam.stringReadParam;
var ordSupplier = Data_Ord.ordString;
var ordSubprojectId = Data_Ord.ordInt;
var ordProjectId = Data_Ord.ordInt;
var ordProjectGroup = Data_Ord.ordString;
var ordProjectCode = Data_Ord.ordString;
var ordCurrency = Data_Ord.ordString;
var ordBusinessId = Data_Ord.ordString;
var newtypeSupplier = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeSubprojectId = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeProjectType = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeProjectRoleId = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeProjectManager = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeProjectId = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeProjectGroup = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeProjectCode = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeFinancingType = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeCurrency = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeContractType = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeConstructionType = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeCompanyName = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeCompanyId = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeBusinessId = {
    Coercible0: function () {
        return undefined;
    }
};
var genericSubprojectAction = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new AddSubproject(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new EditSubproject(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
            return new DeleteSubproject(x.value0.value0);
        };
        throw new Error("Failed pattern match at Types.Project (line 834, column 1 - line 834, column 70): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof AddSubproject) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof EditSubproject) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof DeleteSubproject) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0));
        };
        throw new Error("Failed pattern match at Types.Project (line 834, column 1 - line 834, column 70): " + [ x.constructor.name ]);
    }
};
var tofuJSONSubprojectAction = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddSubproject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "countInMeasurementTotal";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "projectId";
        }
    })(tofuJSONProjectId)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "countInMeasurementTotal";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "projectId";
        }
    })(tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DeleteSubproject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditSubproject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "countInMeasurementTotal";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "id";
        }
    })(tofuJSONSubprojectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "projectId";
        }
    })(tofuJSONProjectId)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "countInMeasurementTotal";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "id";
        }
    })(tofuJSONSubprojectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "projectId";
        }
    })(tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))()(TofuJSON.readVariantNil))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddSubproject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "countInMeasurementTotal";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "projectId";
        }
    })(tofuJSONProjectId)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "countInMeasurementTotal";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "projectId";
        }
    })(tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DeleteSubproject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditSubproject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "countInMeasurementTotal";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "id";
        }
    })(tofuJSONSubprojectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "projectId";
        }
    })(tofuJSONProjectId)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "countInMeasurementTotal";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "id";
        }
    })(tofuJSONSubprojectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "projectId";
        }
    })(tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))()(TofuJSON.nilWriteForeignVariant))))(genericSubprojectAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddSubproject";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditSubproject";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DeleteSubproject";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditSubproject";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DeleteSubproject";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddSubproject";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditSubproject";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteSubproject";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericSubprojectAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddSubproject";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditSubproject";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DeleteSubproject";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditSubproject";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DeleteSubproject";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddSubproject";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditSubproject";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteSubproject";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddSubproject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "countInMeasurementTotal";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "projectId";
        }
    })(tofuJSONProjectId)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "countInMeasurementTotal";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "projectId";
        }
    })(tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DeleteSubproject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditSubproject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "countInMeasurementTotal";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "id";
        }
    })(tofuJSONSubprojectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "projectId";
        }
    })(tofuJSONProjectId)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "countInMeasurementTotal";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "id";
        }
    })(tofuJSONSubprojectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "projectId";
        }
    })(tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))()(TofuJSON.readVariantNil))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddSubproject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "countInMeasurementTotal";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "projectId";
        }
    })(tofuJSONProjectId)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "countInMeasurementTotal";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "projectId";
        }
    })(tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DeleteSubproject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditSubproject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "countInMeasurementTotal";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "id";
        }
    })(tofuJSONSubprojectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "projectId";
        }
    })(tofuJSONProjectId)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "countInMeasurementTotal";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "id";
        }
    })(tofuJSONSubprojectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "projectId";
        }
    })(tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))()(TofuJSON.nilWriteForeignVariant))))
};
var genericShouldUpdateBusinessId = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new ShouldUpdateBusinessId(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return NoOpShouldUpdateBusinessId.value;
        };
        throw new Error("Failed pattern match at Types.Project (line 806, column 1 - line 806, column 82): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof ShouldUpdateBusinessId) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof NoOpShouldUpdateBusinessId) {
            return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
        };
        throw new Error("Failed pattern match at Types.Project (line 806, column 1 - line 806, column 82): " + [ x.constructor.name ]);
    }
};
var tofuJSONShouldUpdateBusinessId = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "NoOpShouldUpdateBusinessId";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ShouldUpdateBusinessId";
        }
    })(TofuJSON.readMaybe(tofuJSONBusinessId))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "NoOpShouldUpdateBusinessId";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ShouldUpdateBusinessId";
        }
    })(TofuJSON.readMaybe(tofuJSONBusinessId))()(TofuJSON.nilWriteForeignVariant)))(genericShouldUpdateBusinessId)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ShouldUpdateBusinessId";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "NoOpShouldUpdateBusinessId";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ShouldUpdateBusinessId";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "NoOpShouldUpdateBusinessId";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericShouldUpdateBusinessId)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ShouldUpdateBusinessId";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "NoOpShouldUpdateBusinessId";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ShouldUpdateBusinessId";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "NoOpShouldUpdateBusinessId";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "NoOpShouldUpdateBusinessId";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ShouldUpdateBusinessId";
        }
    })(TofuJSON.readMaybe(tofuJSONBusinessId))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "NoOpShouldUpdateBusinessId";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ShouldUpdateBusinessId";
        }
    })(TofuJSON.readMaybe(tofuJSONBusinessId))()(TofuJSON.nilWriteForeignVariant)))
};
var genericProjectManagerDeletionResponse = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return DoesntExist.value;
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return DeletePMSuccess.value;
        };
        throw new Error("Failed pattern match at Types.Project (line 825, column 1 - line 825, column 98): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof DoesntExist) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof DeletePMSuccess) {
            return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
        };
        throw new Error("Failed pattern match at Types.Project (line 825, column 1 - line 825, column 98): " + [ x.constructor.name ]);
    }
};
var tofuJSONPMDel = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DeletePMSuccess";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DoesntExist";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DeletePMSuccess";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DoesntExist";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))(genericProjectManagerDeletionResponse)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DoesntExist";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DeletePMSuccess";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DoesntExist";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DeletePMSuccess";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericProjectManagerDeletionResponse)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DoesntExist";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DeletePMSuccess";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DoesntExist";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DeletePMSuccess";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DeletePMSuccess";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DoesntExist";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DeletePMSuccess";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DoesntExist";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))
};
var genericProjectManagerAdditionResponse = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return EmptyPMName.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return TooLongPMName.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return DuplicatePM.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr)) {
            return AddPMSuccess.value;
        };
        throw new Error("Failed pattern match at Types.Project (line 819, column 1 - line 819, column 98): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof EmptyPMName) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof TooLongPMName) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
        };
        if (x instanceof DuplicatePM) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
        };
        if (x instanceof AddPMSuccess) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)));
        };
        throw new Error("Failed pattern match at Types.Project (line 819, column 1 - line 819, column 98): " + [ x.constructor.name ]);
    }
};
var tofuJSONPMAdd = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddPMSuccess";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DuplicatePM";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EmptyPMName";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "TooLongPMName";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddPMSuccess";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DuplicatePM";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EmptyPMName";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "TooLongPMName";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))))(genericProjectManagerAdditionResponse)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EmptyPMName";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "TooLongPMName";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DuplicatePM";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddPMSuccess";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DuplicatePM";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddPMSuccess";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "TooLongPMName";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DuplicatePM";
        }
    }))({
        reflectSymbol: function () {
            return "AddPMSuccess";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EmptyPMName";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "TooLongPMName";
        }
    }))({
        reflectSymbol: function () {
            return "DuplicatePM";
        }
    }))({
        reflectSymbol: function () {
            return "AddPMSuccess";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericProjectManagerAdditionResponse)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EmptyPMName";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "TooLongPMName";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DuplicatePM";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddPMSuccess";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DuplicatePM";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddPMSuccess";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "TooLongPMName";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DuplicatePM";
        }
    }))({
        reflectSymbol: function () {
            return "AddPMSuccess";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EmptyPMName";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "TooLongPMName";
        }
    }))({
        reflectSymbol: function () {
            return "DuplicatePM";
        }
    }))({
        reflectSymbol: function () {
            return "AddPMSuccess";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddPMSuccess";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DuplicatePM";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EmptyPMName";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "TooLongPMName";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddPMSuccess";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DuplicatePM";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EmptyPMName";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "TooLongPMName";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))))
};
var genericCopyCostGroupsFromProject = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new CopyCostGroupsFrom(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return DontCopyCostGroups.value;
        };
        throw new Error("Failed pattern match at Types.Project (line 802, column 1 - line 802, column 88): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof CopyCostGroupsFrom) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof DontCopyCostGroups) {
            return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
        };
        throw new Error("Failed pattern match at Types.Project (line 802, column 1 - line 802, column 88): " + [ x.constructor.name ]);
    }
};
var tofuJSONCopyCostGroupsFromProject = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CopyCostGroupsFrom";
        }
    })(tofuJSONProjectId)()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DontCopyCostGroups";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CopyCostGroupsFrom";
        }
    })(tofuJSONProjectId)()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DontCopyCostGroups";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))(genericCopyCostGroupsFromProject)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CopyCostGroupsFrom";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DontCopyCostGroups";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopyCostGroupsFrom";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DontCopyCostGroups";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericCopyCostGroupsFromProject)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CopyCostGroupsFrom";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DontCopyCostGroups";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopyCostGroupsFrom";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DontCopyCostGroups";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CopyCostGroupsFrom";
        }
    })(tofuJSONProjectId)()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DontCopyCostGroups";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CopyCostGroupsFrom";
        }
    })(tofuJSONProjectId)()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DontCopyCostGroups";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))
};
var genericApplicationMode = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return ReportingMode.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return EstimationMode.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return OrganizationMode.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return IntegrationMode.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
            return AdminMode.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))) {
            return SuperAdminMode.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr))))) {
            return EtcMode.value;
        };
        throw new Error("Failed pattern match at Types.Project (line 737, column 1 - line 737, column 68): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof ReportingMode) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof EstimationMode) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
        };
        if (x instanceof OrganizationMode) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
        };
        if (x instanceof IntegrationMode) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))));
        };
        if (x instanceof AdminMode) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))));
        };
        if (x instanceof SuperAdminMode) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))))));
        };
        if (x instanceof EtcMode) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value))))));
        };
        throw new Error("Failed pattern match at Types.Project (line 737, column 1 - line 737, column 68): " + [ x.constructor.name ]);
    }
};
var tofuJSONApplicationMode = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AdminMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EstimationMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EtcMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "IntegrationMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "OrganizationMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ReportingMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "SuperAdminMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil))))))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AdminMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EstimationMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EtcMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "IntegrationMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "OrganizationMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ReportingMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "SuperAdminMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant))))))))(genericApplicationMode)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ReportingMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EstimationMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "OrganizationMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "IntegrationMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AdminMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "SuperAdminMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EtcMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SuperAdminMode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EtcMode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AdminMode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SuperAdminMode";
        }
    }))({
        reflectSymbol: function () {
            return "EtcMode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "IntegrationMode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SuperAdminMode";
        }
    }))({
        reflectSymbol: function () {
            return "EtcMode";
        }
    }))({
        reflectSymbol: function () {
            return "AdminMode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "OrganizationMode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SuperAdminMode";
        }
    }))({
        reflectSymbol: function () {
            return "IntegrationMode";
        }
    }))({
        reflectSymbol: function () {
            return "EtcMode";
        }
    }))({
        reflectSymbol: function () {
            return "AdminMode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EstimationMode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SuperAdminMode";
        }
    }))({
        reflectSymbol: function () {
            return "OrganizationMode";
        }
    }))({
        reflectSymbol: function () {
            return "IntegrationMode";
        }
    }))({
        reflectSymbol: function () {
            return "EtcMode";
        }
    }))({
        reflectSymbol: function () {
            return "AdminMode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ReportingMode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SuperAdminMode";
        }
    }))({
        reflectSymbol: function () {
            return "OrganizationMode";
        }
    }))({
        reflectSymbol: function () {
            return "IntegrationMode";
        }
    }))({
        reflectSymbol: function () {
            return "EtcMode";
        }
    }))({
        reflectSymbol: function () {
            return "EstimationMode";
        }
    }))({
        reflectSymbol: function () {
            return "AdminMode";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericApplicationMode)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ReportingMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EstimationMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "OrganizationMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "IntegrationMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AdminMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "SuperAdminMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EtcMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SuperAdminMode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EtcMode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AdminMode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SuperAdminMode";
        }
    }))({
        reflectSymbol: function () {
            return "EtcMode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "IntegrationMode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SuperAdminMode";
        }
    }))({
        reflectSymbol: function () {
            return "EtcMode";
        }
    }))({
        reflectSymbol: function () {
            return "AdminMode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "OrganizationMode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SuperAdminMode";
        }
    }))({
        reflectSymbol: function () {
            return "IntegrationMode";
        }
    }))({
        reflectSymbol: function () {
            return "EtcMode";
        }
    }))({
        reflectSymbol: function () {
            return "AdminMode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EstimationMode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SuperAdminMode";
        }
    }))({
        reflectSymbol: function () {
            return "OrganizationMode";
        }
    }))({
        reflectSymbol: function () {
            return "IntegrationMode";
        }
    }))({
        reflectSymbol: function () {
            return "EtcMode";
        }
    }))({
        reflectSymbol: function () {
            return "AdminMode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ReportingMode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SuperAdminMode";
        }
    }))({
        reflectSymbol: function () {
            return "OrganizationMode";
        }
    }))({
        reflectSymbol: function () {
            return "IntegrationMode";
        }
    }))({
        reflectSymbol: function () {
            return "EtcMode";
        }
    }))({
        reflectSymbol: function () {
            return "EstimationMode";
        }
    }))({
        reflectSymbol: function () {
            return "AdminMode";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AdminMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EstimationMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EtcMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "IntegrationMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "OrganizationMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ReportingMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "SuperAdminMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil))))))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AdminMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EstimationMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EtcMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "IntegrationMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "OrganizationMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ReportingMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "SuperAdminMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant))))))))
};
var genericAllocateToProjectProperty = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new AllocateToProjectCode(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return new AllocateToProjectId(x.value0);
        };
        throw new Error("Failed pattern match at Types.Project (line 829, column 1 - line 829, column 88): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof AllocateToProjectCode) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof AllocateToProjectId) {
            return new Data_Generic_Rep.Inr(x.value0);
        };
        throw new Error("Failed pattern match at Types.Project (line 829, column 1 - line 829, column 88): " + [ x.constructor.name ]);
    }
};
var tofuJSONAllocateToProjectProperty = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AllocateToProjectCode";
        }
    })(tofuJSONProjectCode)()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AllocateToProjectId";
        }
    })(tofuJSONProjectId)()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AllocateToProjectCode";
        }
    })(tofuJSONProjectCode)()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AllocateToProjectId";
        }
    })(tofuJSONProjectId)()(TofuJSON.nilWriteForeignVariant)))(genericAllocateToProjectProperty)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AllocateToProjectCode";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AllocateToProjectId";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AllocateToProjectCode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AllocateToProjectId";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericAllocateToProjectProperty)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AllocateToProjectCode";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AllocateToProjectId";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AllocateToProjectCode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AllocateToProjectId";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AllocateToProjectCode";
        }
    })(tofuJSONProjectCode)()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AllocateToProjectId";
        }
    })(tofuJSONProjectId)()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AllocateToProjectCode";
        }
    })(tofuJSONProjectCode)()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AllocateToProjectId";
        }
    })(tofuJSONProjectId)()(TofuJSON.nilWriteForeignVariant)))
};
var tofuJSONAllocateToProject = TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "ids";
    }
})(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "property";
    }
})(tofuJSONAllocateToProjectProperty)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "ids";
    }
})(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "property";
    }
})(tofuJSONAllocateToProjectProperty)(TofuJSON.nilWriteForeignFields)()()())()()());
var eqSupplier = Data_Eq.eqString;
var eqSubprojectId = Data_Eq.eqInt;
var eqProjectType = Data_Eq.eqString;
var eqProjectRoleId = Data_Eq.eqString;
var eqProjectManagerDeletionResponse = {
    eq: function (x) {
        return function (y) {
            if (x instanceof DoesntExist && y instanceof DoesntExist) {
                return true;
            };
            if (x instanceof DeletePMSuccess && y instanceof DeletePMSuccess) {
                return true;
            };
            return false;
        };
    }
};
var ordProjectManagerDeletionResponse = {
    compare: function (x) {
        return function (y) {
            if (x instanceof DoesntExist && y instanceof DoesntExist) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof DoesntExist) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof DoesntExist) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof DeletePMSuccess && y instanceof DeletePMSuccess) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at Types.Project (line 824, column 1 - line 824, column 88): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqProjectManagerDeletionResponse;
    }
};
var eqProjectManagerAdditionResponse = {
    eq: function (x) {
        return function (y) {
            if (x instanceof EmptyPMName && y instanceof EmptyPMName) {
                return true;
            };
            if (x instanceof TooLongPMName && y instanceof TooLongPMName) {
                return true;
            };
            if (x instanceof DuplicatePM && y instanceof DuplicatePM) {
                return true;
            };
            if (x instanceof AddPMSuccess && y instanceof AddPMSuccess) {
                return true;
            };
            return false;
        };
    }
};
var ordProjectManagerAdditionResponse = {
    compare: function (x) {
        return function (y) {
            if (x instanceof EmptyPMName && y instanceof EmptyPMName) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof EmptyPMName) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof EmptyPMName) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof TooLongPMName && y instanceof TooLongPMName) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof TooLongPMName) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof TooLongPMName) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof DuplicatePM && y instanceof DuplicatePM) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof DuplicatePM) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof DuplicatePM) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AddPMSuccess && y instanceof AddPMSuccess) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at Types.Project (line 818, column 1 - line 818, column 88): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqProjectManagerAdditionResponse;
    }
};
var eqProjectManager = Data_Eq.eqString;
var eqProjectId = Data_Eq.eqInt;
var eqProjectGroup = Data_Eq.eqString;
var eqProjectCode = Data_Eq.eqString;
var eqFinancingType = Data_Eq.eqString;
var eqCurrency = Data_Eq.eqString;
var eqCopyCostGroupsFromProject = {
    eq: function (x) {
        return function (y) {
            if (x instanceof CopyCostGroupsFrom && y instanceof CopyCostGroupsFrom) {
                return Data_Eq.eq(eqProjectId)(x.value0)(y.value0);
            };
            if (x instanceof DontCopyCostGroups && y instanceof DontCopyCostGroups) {
                return true;
            };
            return false;
        };
    }
};
var eqContractType = Data_Eq.eqString;
var eqConstructionType = Data_Eq.eqString;
var eqCompanyName = Data_Eq.eqString;
var eqCompanyId = Data_Eq.eqInt;
var eqBusinessId = Data_Eq.eqString;
var eqShouldUpdateBusinessId = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ShouldUpdateBusinessId && y instanceof ShouldUpdateBusinessId) {
                return Data_Eq.eq(Data_Maybe.eqMaybe(eqBusinessId))(x.value0)(y.value0);
            };
            if (x instanceof NoOpShouldUpdateBusinessId && y instanceof NoOpShouldUpdateBusinessId) {
                return true;
            };
            return false;
        };
    }
};
var eqApplicationMode = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ReportingMode && y instanceof ReportingMode) {
                return true;
            };
            if (x instanceof EstimationMode && y instanceof EstimationMode) {
                return true;
            };
            if (x instanceof OrganizationMode && y instanceof OrganizationMode) {
                return true;
            };
            if (x instanceof IntegrationMode && y instanceof IntegrationMode) {
                return true;
            };
            if (x instanceof AdminMode && y instanceof AdminMode) {
                return true;
            };
            if (x instanceof SuperAdminMode && y instanceof SuperAdminMode) {
                return true;
            };
            if (x instanceof EtcMode && y instanceof EtcMode) {
                return true;
            };
            return false;
        };
    }
};
var eqAllocateToProjectProperty = {
    eq: function (x) {
        return function (y) {
            if (x instanceof AllocateToProjectCode && y instanceof AllocateToProjectCode) {
                return Data_Eq.eq(eqProjectCode)(x.value0)(y.value0);
            };
            if (x instanceof AllocateToProjectId && y instanceof AllocateToProjectId) {
                return Data_Eq.eq(eqProjectId)(x.value0)(y.value0);
            };
            return false;
        };
    }
};
var eqAllocateToProject = {
    eq: function (x) {
        return function (y) {
            return Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqInt))(x.ids)(y.ids) && Data_Eq.eq(eqAllocateToProjectProperty)(x.property)(y.property);
        };
    }
};
var applicationModeToString = function (v) {
    if (v instanceof ReportingMode) {
        return "ReportingMode";
    };
    if (v instanceof EstimationMode) {
        return "EstimationMode";
    };
    if (v instanceof OrganizationMode) {
        return "OrganizationMode";
    };
    if (v instanceof IntegrationMode) {
        return "IntegrationMode";
    };
    if (v instanceof AdminMode) {
        return "AdminMode";
    };
    if (v instanceof SuperAdminMode) {
        return "SuperAdminMode";
    };
    if (v instanceof EtcMode) {
        return "EtcMode";
    };
    throw new Error("Failed pattern match at Types.Project (line 52, column 1 - line 52, column 53): " + [ v.constructor.name ]);
};
module.exports = {
    BusinessId: BusinessId,
    ProjectRoleId: ProjectRoleId,
    ProjectId: ProjectId,
    ProjectCode: ProjectCode,
    SubprojectId: SubprojectId,
    ProjectManager: ProjectManager,
    ProjectGroup: ProjectGroup,
    ConstructionType: ConstructionType,
    FinancingType: FinancingType,
    ProjectType: ProjectType,
    ContractType: ContractType,
    Supplier: Supplier,
    CompanyName: CompanyName,
    CompanyId: CompanyId,
    Currency: Currency,
    EmptyPMName: EmptyPMName,
    TooLongPMName: TooLongPMName,
    DuplicatePM: DuplicatePM,
    AddPMSuccess: AddPMSuccess,
    DoesntExist: DoesntExist,
    DeletePMSuccess: DeletePMSuccess,
    ReportingMode: ReportingMode,
    EstimationMode: EstimationMode,
    OrganizationMode: OrganizationMode,
    IntegrationMode: IntegrationMode,
    AdminMode: AdminMode,
    SuperAdminMode: SuperAdminMode,
    EtcMode: EtcMode,
    applicationModeToString: applicationModeToString,
    stringToApplicationMode: stringToApplicationMode,
    ShouldUpdateBusinessId: ShouldUpdateBusinessId,
    NoOpShouldUpdateBusinessId: NoOpShouldUpdateBusinessId,
    CopyCostGroupsFrom: CopyCostGroupsFrom,
    DontCopyCostGroups: DontCopyCostGroups,
    AddSubproject: AddSubproject,
    EditSubproject: EditSubproject,
    DeleteSubproject: DeleteSubproject,
    AllocateToProjectCode: AllocateToProjectCode,
    AllocateToProjectId: AllocateToProjectId,
    AllocateToProject: AllocateToProject,
    eqShouldUpdateBusinessId: eqShouldUpdateBusinessId,
    eqCopyCostGroupsFromProject: eqCopyCostGroupsFromProject,
    eqAllocateToProjectProperty: eqAllocateToProjectProperty,
    eqAllocateToProject: eqAllocateToProject,
    newtypeCurrency: newtypeCurrency,
    eqCurrency: eqCurrency,
    ordCurrency: ordCurrency,
    tofuJSONCurrency: tofuJSONCurrency,
    newtypeSubprojectId: newtypeSubprojectId,
    readParamSubprojectId: readParamSubprojectId,
    writeParamSubprojectId: writeParamSubprojectId,
    tofuJSONSubprojectId: tofuJSONSubprojectId,
    showSubprojectId: showSubprojectId,
    eqSubprojectId: eqSubprojectId,
    ordSubprojectId: ordSubprojectId,
    newtypeProjectId: newtypeProjectId,
    readParamProjectId: readParamProjectId,
    writeParamProjectId: writeParamProjectId,
    tofuJSONProjectId: tofuJSONProjectId,
    showProjectId: showProjectId,
    eqProjectId: eqProjectId,
    ordProjectId: ordProjectId,
    eqApplicationMode: eqApplicationMode,
    genericApplicationMode: genericApplicationMode,
    tofuJSONApplicationMode: tofuJSONApplicationMode,
    newtypeSupplier: newtypeSupplier,
    readParamSupplier: readParamSupplier,
    writeParamSupplier: writeParamSupplier,
    tofuJSONSupplier: tofuJSONSupplier,
    showSupplier: showSupplier,
    eqSupplier: eqSupplier,
    ordSupplier: ordSupplier,
    newtypeBusinessId: newtypeBusinessId,
    readParamBusinessId: readParamBusinessId,
    writeParamBusinessId: writeParamBusinessId,
    tofuJSONBusinessId: tofuJSONBusinessId,
    showBusinessId: showBusinessId,
    eqBusinessId: eqBusinessId,
    ordBusinessId: ordBusinessId,
    newtypeCompanyId: newtypeCompanyId,
    eqCompanyId: eqCompanyId,
    tofuJSONCompanyId: tofuJSONCompanyId,
    newtypeCompanyName: newtypeCompanyName,
    eqCompanyName: eqCompanyName,
    showCompanyName: showCompanyName,
    tofuJSONCompanyName: tofuJSONCompanyName,
    newtypeProjectRoleId: newtypeProjectRoleId,
    eqProjectRoleId: eqProjectRoleId,
    tofuJSONProjectRoleId: tofuJSONProjectRoleId,
    newtypeProjectManager: newtypeProjectManager,
    readParamProjectManager: readParamProjectManager,
    writeParamProjectManager: writeParamProjectManager,
    tofuJSONProjectManager: tofuJSONProjectManager,
    showProjectManager: showProjectManager,
    eqProjectManager: eqProjectManager,
    newtypeProjectGroup: newtypeProjectGroup,
    readParamProjectGroup: readParamProjectGroup,
    writeParamProjectGroup: writeParamProjectGroup,
    tofuJSONProjectGroup: tofuJSONProjectGroup,
    showProjectGroup: showProjectGroup,
    eqProjectGroup: eqProjectGroup,
    ordProjectGroup: ordProjectGroup,
    newtypeConstructionType: newtypeConstructionType,
    readParamConstructionType: readParamConstructionType,
    writeParamConstructionType: writeParamConstructionType,
    tofuJSONConstructionType: tofuJSONConstructionType,
    showConstructionType: showConstructionType,
    eqConstructionType: eqConstructionType,
    newtypeFinancingType: newtypeFinancingType,
    readParamFinancingType: readParamFinancingType,
    writeParamFinancingType: writeParamFinancingType,
    tofuJSONFinancingType: tofuJSONFinancingType,
    showFinancingType: showFinancingType,
    eqFinancingType: eqFinancingType,
    newtypeProjectType: newtypeProjectType,
    readParamProjectType: readParamProjectType,
    writeParamProjectType: writeParamProjectType,
    tofuJSONProjectType: tofuJSONProjectType,
    showProjectType: showProjectType,
    eqProjectType: eqProjectType,
    newtypeContractType: newtypeContractType,
    readParamContractType: readParamContractType,
    writeParamContractType: writeParamContractType,
    tofuJSONContractType: tofuJSONContractType,
    showContractType: showContractType,
    eqContractType: eqContractType,
    genericCopyCostGroupsFromProject: genericCopyCostGroupsFromProject,
    tofuJSONCopyCostGroupsFromProject: tofuJSONCopyCostGroupsFromProject,
    genericShouldUpdateBusinessId: genericShouldUpdateBusinessId,
    tofuJSONShouldUpdateBusinessId: tofuJSONShouldUpdateBusinessId,
    newtypeProjectCode: newtypeProjectCode,
    readParamProjectCode: readParamProjectCode,
    writeParamProjectCode: writeParamProjectCode,
    tofuJSONProjectCode: tofuJSONProjectCode,
    showProjectCode: showProjectCode,
    eqProjectCode: eqProjectCode,
    ordProjectCode: ordProjectCode,
    eqProjectManagerAdditionResponse: eqProjectManagerAdditionResponse,
    ordProjectManagerAdditionResponse: ordProjectManagerAdditionResponse,
    genericProjectManagerAdditionResponse: genericProjectManagerAdditionResponse,
    tofuJSONPMAdd: tofuJSONPMAdd,
    eqProjectManagerDeletionResponse: eqProjectManagerDeletionResponse,
    ordProjectManagerDeletionResponse: ordProjectManagerDeletionResponse,
    genericProjectManagerDeletionResponse: genericProjectManagerDeletionResponse,
    tofuJSONPMDel: tofuJSONPMDel,
    genericAllocateToProjectProperty: genericAllocateToProjectProperty,
    tofuJSONAllocateToProjectProperty: tofuJSONAllocateToProjectProperty,
    tofuJSONAllocateToProject: tofuJSONAllocateToProject,
    genericSubprojectAction: genericSubprojectAction,
    tofuJSONSubprojectAction: tofuJSONSubprojectAction
};
