import { Prisma, ReportTemplate } from "@prisma/client";
import { Button, FileUpload, Input, Loader } from "@tocoman/ui";
import { format } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CreateReportTemplateDialog from "./CreateReportTemplateDialog";
import {
  useGetReportTemplates,
  useUpdateReportTemplate,
} from "./useReportTemplates";

export const ReportTemplateList = () => {
  const { t } = useTranslation("superAdmin", { keyPrefix: "reportDesigner" });
  const { data: templates, isLoading, isError } = useGetReportTemplates();
  const saveReportTemplate = useUpdateReportTemplate();

  const [projectIdInput, setProjectIdInput] = useState<string>("");
  const [projectId, setProjectId] = useState<number | null>(null);

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [files, setFiles] = useState<File[]>([]);

  const openReportDesigner = (template: ReportTemplate) => {
    window.open(
      `/new/superadmin/report-templates/${template.name}/edit/${projectId}`,
      "newWin",
      `width=1920,height=1024,left=${window.screen.availWidth / 2 - 960},top=${
        window.screen.availHeight / 2 - 540
      }`
    );
  };

  const importTemplate = async (name: string, template: Prisma.JsonValue) => {
    return saveReportTemplate.mutateAsync({ name, template });
  };

  const confirmImport = async (name: string) => {
    if (files.length === 0) {
      return;
    }

    const template = JSON.parse(await files[0].text());
    await importTemplate(name, template);

    setIsUploadModalOpen(false);
    setFiles([]);
  };

  if (isLoading) {
    return <Loader isLoading={true} kind="spinner" />;
  }

  if (isError) {
    return (
      <span className="text-red-500 text-sm">{t`errorLoadingTemplates`}</span>
    );
  }

  if (!templates || templates.length === 0) {
    return <div>{t`noTemplates`}</div>;
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between w-full py-2">
        <div className="flex flex-row gap-2">
          <Input
            className={"w-1/2"}
            label={t("projectId")}
            value={projectIdInput}
            onChange={(e) => setProjectIdInput(e.target.value)}
          />
          <Button
            label={t("setProject")}
            className="w-1/2 mt-3"
            onClick={() => setProjectId(Number(projectIdInput))}
          />
        </div>

        <CreateReportTemplateDialog />
      </div>

      <ul className="flex flex-col gap-2">
        {templates.map((template) => (
          <li
            key={template.id}
            className="flex border border-slate-200 rounded-md p-2 gap-2 justify-between items-center"
          >
            <div className="flex flex-col gap-1">
              <span className="text-lg bold">{template.name}</span>
              <span className="text-xs">
                {t("lastEditedBy", {
                  lastEditedBy: template.lastEditedBy.email,
                  lastEditedAt: format(template.updatedAt, "DD.MM.YYYY HH:mm"),
                })}
              </span>
            </div>

            <div className="flex gap-2">
              <FileUpload
                browseButtonLabel={t`import.browseButton`}
                title={t`import.title`}
                description={t`import.description`}
                dragDropLabel={t`import.dragDrop`}
                confirmLabel={t`import.confirm`}
                cancelLabel={t`import.cancel`}
                isOpen={isUploadModalOpen}
                isLoading={false}
                onClose={() => {
                  setIsUploadModalOpen(false);
                  setFiles([]);
                }}
                onConfirm={() => confirmImport(template.name)}
                files={files}
                onFilesChange={(files) =>
                  // Limit to one file
                  files.length > 0 ? setFiles([files[0]]) : setFiles([])
                }
              />
              <Button onClick={() => setIsUploadModalOpen(true)}>
                {t`import.label`}
              </Button>
              <a
                href={`/api/super-admin/report-templates/${template.name}/template`}
                download
              >
                <Button>{t`export`}</Button>
              </a>
              <Button onClick={() => openReportDesigner(template)}>
                {t`edit`}
              </Button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
