// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var TacoButton_Styles = require("../TacoButton.Styles/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var dixonFileLabelStyles = function (props) {
    var buttonStyleProps = {
        align: TacoButton_Types.Left.value,
        buttonStyle: (function () {
            if (props.update) {
                return TacoButton_Types.Text.value;
            };
            return TacoButton_Types.Filled.value;
        })(),
        children: Data_Maybe.Nothing.value,
        color: TacoConstants.PrimaryBlue.value,
        disabled: false,
        text: "",
        size: (function () {
            if (props.update) {
                return TacoConstants.S.value;
            };
            return TacoConstants.XL.value;
        })(),
        vertical: false,
        styles: [  ]
    };
    return Box.boxStyle([ Data_Monoid.guard(Box.monoidBoxProp)(props.update)(Box.Width.create(new TacoConstants.CustomSize("100%"))), Box.Style.create(TacoButton_Styles.buttonStyles(buttonStyleProps)), Data_Monoid.guard(Box.monoidBoxProp)(!props.update)(new Box.Padding(TacoConstants.S.value)), Box.Style.create(React_Basic_Emotion.css()({
        "::-webkit-file-upload-button": React_Basic_Emotion.nested(React_Basic_Emotion.css()({
            visibility: React_Basic_Emotion.str("hidden")
        }))
    })) ]);
};
var dixonFileInputStyles = Box.boxStyle([ new Box.Display(Box.None.value) ]);
var dixonFileInputContainerStyles = function (props) {
    return Box.boxStyle([ Data_Monoid.guard(Box.monoidBoxProp)(!props.update)(new Box.Padding(TacoConstants.M.value)) ]);
};
module.exports = {
    dixonFileInputStyles: dixonFileInputStyles,
    dixonFileInputContainerStyles: dixonFileInputContainerStyles,
    dixonFileLabelStyles: dixonFileLabelStyles
};
