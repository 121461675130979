// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var ColumnWidgets = require("../ColumnWidgets/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_List_Lazy_Types = require("../Data.List.Lazy.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Data_Validation_Semigroup = require("../Data.Validation.Semigroup/index.js");
var Effect = require("../Effect/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Class_Console = require("../Effect.Class.Console/index.js");
var Locale = require("../Locale/index.js");
var ProjectComponents_Styles = require("../ProjectComponents.Styles/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var Record = require("../Record/index.js");
var StateHooks = require("../StateHooks/index.js");
var StringUtils = require("../StringUtils/index.js");
var TacoLoader = require("../TacoLoader/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_CostClass = require("../Types.CostClass/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Project = require("../Types.Project/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var UseFetch = require("../UseFetch/index.js");
var Utils = require("../Utils/index.js");
var socialExpensesTableInstance = TacoTable.mkTableStateless(TofuHooks.getNameVoid)("SocialExpensePercentagesTable");
var percentagesTable = function (dispatch) {
    return function (costClasses) {
        return function (projectId) {
            var socialExpensesTableColumns = (function () {
                var validate = function (inputStr) {
                    var v = StringUtils.readStrToNumber(inputStr);
                    if (v instanceof Data_Maybe.Nothing) {
                        return Data_Validation_Semigroup.invalid(Control_Applicative.pure(Data_List_Lazy_Types.applicativeNonEmptyList)("Sy\xf6tteen t\xe4ytyy olla numero."));
                    };
                    if (v instanceof Data_Maybe.Just) {
                        return Control_Applicative.pure(Data_Validation_Semigroup.applicativeV(Data_List_Lazy_Types.semigroupNonEmptyList))(inputStr);
                    };
                    throw new Error("Failed pattern match at SocialExpensePercentages (line 125, column 13 - line 127, column 38): " + [ v.constructor.name ]);
                };
                var percentageCell = function (f) {
                    return new TacoTable_Types.PlainPercentageCell(1, (function () {
                        var $19 = Data_Maybe.fromMaybe(0.0);
                        return function ($20) {
                            return $19(f($20));
                        };
                    })());
                };
                var onSuccess = function (costClass) {
                    return function (percentStr) {
                        return function __do() {
                            Effect_Class_Console.log(Effect_Class.monadEffectEffect)(percentStr)();
                            Effect_Class_Console.log(Effect_Class.monadEffectEffect)(costClass.name)();
                            var p = Utils.pureNullable(StringUtils.readStrToNumberDefault0(percentStr) / 100.0);
                            return dispatch(new Actions.UpdateCostControlSocialExpensesAction({
                                projectId: projectId,
                                params: Record.merge()()({
                                    socialExpensePercentageInCostControlForEstimatesAndActuals: p
                                })(costClass)
                            }))();
                        };
                    };
                };
                var sepCostControlForEstimatesAndActualsCell = function (r) {
                    return ColumnWidgets.percentPrompt(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Newtype.un()(Types_Unit.PercentageOfZeroToOne))(r.sepCostControlForEstimatesAndActuals))("Sy\xf6t\xe4 uusi sosiaalikuluprosentti")(validate)(onSuccess(r.costClass));
                };
                return [ {
                    key: "name",
                    label: React_Basic_DOM.text(Locale.lookup_("costClass")),
                    cell: new TacoTable_Types.PlainTextCell(function (v) {
                        return v.name;
                    }),
                    sortProperty: TacoTable_Types.noSortProperty,
                    width: new TacoTable_Types.Flex(1),
                    headerJSX: Data_Maybe.Nothing.value
                }, {
                    key: "sepCostEstimation",
                    label: React_Basic_DOM.text(Locale.lookup_("in_cost_estimation")),
                    cell: percentageCell(function (v) {
                        return v.sepCostEstimation;
                    }),
                    sortProperty: TacoTable_Types.noSortProperty,
                    width: new TacoTable_Types.Flex(1),
                    headerJSX: Data_Maybe.Nothing.value
                }, {
                    key: "sepCostControlForEstimatesAndActuals",
                    label: React_Basic_DOM.text(Locale.lookup_("for_estimates_and_actuals")),
                    cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value ], sepCostControlForEstimatesAndActualsCell),
                    sortProperty: TacoTable_Types.noSortProperty,
                    width: new TacoTable_Types.Flex(1),
                    headerJSX: Data_Maybe.Nothing.value
                }, {
                    key: "sepCostControlForComponents",
                    label: React_Basic_DOM.text(Locale.lookup_("for_components")),
                    cell: percentageCell(function (v) {
                        return v.sepCostControlForComponents;
                    }),
                    sortProperty: TacoTable_Types.noSortProperty,
                    width: new TacoTable_Types.Flex(1),
                    headerJSX: Data_Maybe.Nothing.value
                }, {
                    key: "sepCostControlForTargets",
                    label: React_Basic_DOM.text(Locale.lookup_("for_targets")),
                    cell: percentageCell(function (v) {
                        return v.sepCostControlForTargets;
                    }),
                    sortProperty: TacoTable_Types.noSortProperty,
                    width: new TacoTable_Types.Flex(1),
                    headerJSX: Data_Maybe.Nothing.value
                } ];
            })();
            var mkRowData = function (r) {
                return {
                    name: Data_Newtype.unwrap()(r.costClassCode) + (": " + r.name),
                    costClass: r,
                    sepCostEstimation: Data_Nullable.toMaybe(Utils.mapNullable(Types_Unit.PercentageOfZeroToOne)(r.socialExpensePercentageInCostEstimation)),
                    sepCostControlForEstimatesAndActuals: Data_Nullable.toMaybe(Utils.mapNullable(Types_Unit.PercentageOfZeroToOne)(r.socialExpensePercentageInCostControlForEstimatesAndActuals)),
                    sepCostControlForComponents: Data_Nullable.toMaybe(Utils.mapNullable(Types_Unit.PercentageOfZeroToOne)(r.socialExpensePercentageInCostControlForComponents)),
                    sepCostControlForTargets: Data_Nullable.toMaybe(Utils.mapNullable(Types_Unit.PercentageOfZeroToOne)(r.socialExpensePercentageInCostControlForTargets))
                };
            };
            var rowData = Data_Functor.map(Data_Functor.functorArray)(mkRowData)(costClasses);
            var getRow = function (r) {
                return {
                    className: "__SocialExpensePercentagesRow",
                    rowData: r,
                    foldingElement: Data_Maybe.Nothing.value,
                    onClick: function (v) {
                        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                    },
                    rowKey: r.name,
                    columns: socialExpensesTableColumns
                };
            };
            var rows = Data_Functor.map(Data_Functor.functorArray)(getRow)(rowData);
            return TacoTable["tableStateless'"](TofuHooks.getNameVoid)(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "sepCostEstimation";
                }
            })(Data_Maybe.eqMaybe(Types_Unit.eqPercentageOfZeroToOne)))()({
                reflectSymbol: function () {
                    return "sepCostControlForTargets";
                }
            })(Data_Maybe.eqMaybe(Types_Unit.eqPercentageOfZeroToOne)))()({
                reflectSymbol: function () {
                    return "sepCostControlForEstimatesAndActuals";
                }
            })(Data_Maybe.eqMaybe(Types_Unit.eqPercentageOfZeroToOne)))()({
                reflectSymbol: function () {
                    return "sepCostControlForComponents";
                }
            })(Data_Maybe.eqMaybe(Types_Unit.eqPercentageOfZeroToOne)))()({
                reflectSymbol: function () {
                    return "name";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "costClass";
                }
            })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "socialExpensePercentageInCostEstimation";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                reflectSymbol: function () {
                    return "socialExpensePercentageInCostControlForTargets";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                reflectSymbol: function () {
                    return "socialExpensePercentageInCostControlForEstimatesAndActuals";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                reflectSymbol: function () {
                    return "socialExpensePercentageInCostControlForComponents";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                reflectSymbol: function () {
                    return "projectId";
                }
            })(Types_Project.eqProjectId))()({
                reflectSymbol: function () {
                    return "name";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "multiplier";
                }
            })(Data_Eq.eqNumber))()({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_CostClass.eqCostClassId))()({
                reflectSymbol: function () {
                    return "hourlyPricing";
                }
            })(Data_Eq.eqBoolean))()({
                reflectSymbol: function () {
                    return "costClassCode";
                }
            })(Types_Newtypes.eqCostClassCode)))))(true)(socialExpensesTableInstance)(rows)(socialExpensesTableColumns);
        };
    };
};
var component = TofuHooks.mkHookComponent("SocialExpensePercentages")(function (props) {
    return function __do() {
        var v = UseFetch.useFetchValueByProjectId(props.projectId)(function (v) {
            return v.costClasses;
        })(Actions.ProjectCostClassesRequest.create)();
        var appDispatch = StateHooks.useDispatch();
        var loading = React_Basic_DOM_Generated.div()({
            className: "social_expense_percentages-loading",
            children: [ TacoLoader.component()()({
                size: "1rem"
            }) ]
        });
        var content = (function () {
            if (v.value0 instanceof Data_Maybe.Just && !v.value1) {
                return percentagesTable(appDispatch)(v.value0.value0.items)(props.projectId);
            };
            return loading;
        })();
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "__SocialExpensesPercentages",
            css: ProjectComponents_Styles.projectDetailsSectionStyles,
            children: [ TacoText.component()()({
                text: Locale.lookup_("social_expense_percentages"),
                weight: TacoText_Types.Bold.value,
                gutterBottom: true,
                variant: TacoText_Types.Paragraph.value
            }), content ]
        });
    };
});
module.exports = {
    component: component
};
