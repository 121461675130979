import {
  Project,
  TargetAssessment,
  TargetAssessmentLetter,
} from "@tocoman/adminet-openapi-client";
import axios from "../../../../axiosConfig";
import { handleTargetAmountError } from "./AdminetHttpErrorHandler";

type AdminetProjectsParams = {
  adminetTenantId: string | null;
};
export type FetchAdminetProjectsParams = {
  searchWord: string;
} & AdminetProjectsParams;

export type TransferCalculationParams = {
  targetAmount: TargetAssessment;
  adminetProjectId: string;
  accessToken: string;
} & AdminetProjectsParams;

export const fetchUltimaProjects = async ({
  searchWord,
  adminetTenantId,
}: FetchAdminetProjectsParams): Promise<Project[]> => {
  const endpoint = "integration/ultima/projects/search";

  const response = await axios.get(endpoint, {
    params: {
      searchWord,
      adminetTenantId: adminetTenantId,
    },
  });
  return await response.data;
};

export const fetchUltimaCostGroups = async ({
  adminetTenantId,
}: AdminetProjectsParams): Promise<TargetAssessmentLetter[]> => {
  const endpoint = "integration/ultima/cost-groups";

  const response = await axios.get(endpoint, {
    params: { adminetTenantId: adminetTenantId ?? "" },
  });
  return await response.data;
};

export const transferCalculationToUltima = async ({
  targetAmount,
  accessToken,
  adminetProjectId,
  adminetTenantId,
}: TransferCalculationParams): Promise<void | Response> => {
  const params = new URLSearchParams({
    ...(adminetTenantId && { adminetTenantId }),
  });
  const endpoint = `/api/integration/ultima/projects/${adminetProjectId}/cost-calculation?${params}`;

  const response = await fetch(endpoint, {
    method: "POST",
    headers: {
      Authorization: accessToken,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      targetAmount,
    }),
  });
  if (!response.ok) {
    await handleTargetAmountError(response, targetAmount);
  }
  return response;
};
