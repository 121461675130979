// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var SuperAdmin_Styles = require("../SuperAdmin.Styles/index.js");
var TacoInput = require("../TacoInput/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var component = TofuHooks.mkHookComponent("FilterUsers")(function (v) {
    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        className: "filterUsersContainer",
        css: SuperAdmin_Styles.filterUsersStyles,
        children: [ TacoInput.stringInput()()(Data_Eq.eqString)({
            onChange: v.setFilter,
            value: v.filter,
            placeholder: "Etsi k\xe4ytt\xe4j\xe4\xe4...",
            testId: "superadmin-filter-users"
        }) ]
    }));
});
module.exports = {
    component: component
};
