import { Button, Modal } from "@tocoman/ui";
import { Trans, useTranslation } from "react-i18next";
import { useWorkerAction } from "../../../hooks/useWorkerAction";
import { EstimationProjectSummary } from "../../../../../ts-bindings/EstimationProjectSummary";
import { mkProjectDeleteAction } from "../../../actions/project";
import { useOnEq } from "../../../hooks/useOnEq";

type ConfirmDeleteProjectModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  project?: EstimationProjectSummary;
};
export const ConfirmDeleteProjectModal = ({
  isOpen,
  closeModal,
  project,
}: ConfirmDeleteProjectModalProps) => {
  const { t } = useTranslation("costControl", {
    keyPrefix: "projectList.confirmDelete",
  });

  const [deleteStatus, deleteProject] = useWorkerAction(
    "ProjectFormUpdateActionState",
    (projectId: number, projectVersion: string, projectCode: string) => {
      return mkProjectDeleteAction(projectId, projectVersion, projectCode);
    }
  );

  const confirmDelete = () => {
    project && deleteProject(project.id, project.version, project.code);
  };

  useOnEq(deleteStatus, "Resolved", () => closeModal());

  const confirmTitle = t`title`;

  const confirmActions = (
    <>
      <div className={"flex justify-between w-full"}>
        <Button color="danger" variant="text" size="large" onClick={closeModal}>
          {t`cancel`}
        </Button>
        <Button
          testId="confirmDeleteBtn"
          color="danger"
          size="large"
          loading={deleteStatus === "Pending"}
          disabled={deleteStatus === "Pending"}
          onClick={() => {
            confirmDelete();
          }}
        >
          {t`delete`}
        </Button>
      </div>
    </>
  );

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      title={confirmTitle}
      actions={confirmActions}
    >
      <Trans
        ns="costControl"
        i18nKey={"projectList.confirmDelete.description"}
        values={{
          projectName: project?.name,
          projectVersion: project?.version,
        }}
      />
    </Modal>
  );
};
