// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var CostEstimation_SelectResource_Styles = require("../CostEstimation.SelectResource.Styles/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var EstimationUtils = require("../EstimationUtils/index.js");
var Formatters = require("../Formatters/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var Record = require("../Record/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_ClassificationGroup = require("../Types.ClassificationGroup/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_Estimation = require("../Types.Estimation/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Project = require("../Types.Project/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var UseNullableRef = require("../UseNullableRef/index.js");
var Utils = require("../Utils/index.js");
var PriceListRowId = (function () {
    function PriceListRowId(value0) {
        this.value0 = value0;
    };
    PriceListRowId.create = function (value0) {
        return new PriceListRowId(value0);
    };
    return PriceListRowId;
})();
var ProjectResourceId = (function () {
    function ProjectResourceId(value0) {
        this.value0 = value0;
    };
    ProjectResourceId.create = function (value0) {
        return new ProjectResourceId(value0);
    };
    return ProjectResourceId;
})();
var wrapCurrencySymbol = function (currencyId) {
    return Utils.toNullable(Data_Functor.map(Data_Maybe.functorMaybe)(Types_Project.Currency)(Data_Nullable.toMaybe(currencyId)));
};
var unwrapCurrencySymbol = function (currencyId) {
    return Utils.toNullable(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Newtype.unwrap())(Data_Nullable.toMaybe(currencyId)));
};
var removeIds = function (dictLacks) {
    return function (dictLacks1) {
        return function (record) {
            return Record["delete"]({
                reflectSymbol: function () {
                    return "uiRowId";
                }
            })()()(Data_Symbol.SProxy.value)(Record["delete"]({
                reflectSymbol: function () {
                    return "databaseRowId";
                }
            })()()(Data_Symbol.SProxy.value)(record));
        };
    };
};
var removeCostClassPrices = function (resources) {
    var isRealResource = function (row) {
        return !row.isCostClassPrice;
    };
    return Data_Array.filter(isRealResource)(resources);
};
var getColumns = function (props) {
    var prepare = function (r) {
        return {
            key: r.key,
            label: r.label,
            width: r.width,
            cell: r.cell,
            headerJSX: Data_Maybe.Nothing.value,
            sortProperty: TacoTable_Types.noSortProperty
        };
    };
    var currencySymbol = EstimationUtils.getCurrencySymbol(props.projectCurrency);
    return Data_Functor.map(Data_Functor.functorArray)(prepare)([ {
        key: "costClassCode",
        label: React_Basic_DOM.text("PL"),
        width: new TacoTable_Types.Fixed("4rem"),
        cell: TacoTable_Types.PlainTextCell.create((function () {
            var $49 = Utils.nullable("")(Data_Newtype.unwrap());
            return function ($50) {
                return $49((function (v) {
                    return v.costClassCode;
                })($50));
            };
        })())
    }, {
        key: "resourceClassificationGroup",
        label: React_Basic_DOM.text("PRyhm\xe4"),
        width: new TacoTable_Types.Fixed("8rem"),
        cell: TacoTable_Types.PlainTextCell.create((function () {
            var $51 = Utils.fromNullable("");
            return function ($52) {
                return $51((function (v) {
                    return v.resourceClassificationGroup;
                })($52));
            };
        })())
    }, {
        key: "name",
        label: React_Basic_DOM.text("Nimi"),
        width: new TacoTable_Types.FlexMinWidth(1, "20rem"),
        cell: TacoTable_Types.PlainTextCell.create(function (v) {
            return v.name;
        })
    }, {
        key: "unit",
        label: React_Basic_DOM.text("Yks"),
        width: new TacoTable_Types.Fixed("8rem"),
        cell: TacoTable_Types.PlainTextCell.create((function () {
            var $53 = Utils.fromNullable("");
            return function ($54) {
                return $53((function (v) {
                    return v.unit;
                })($54));
            };
        })())
    }, {
        key: "pricePerUnit",
        label: React_Basic_DOM.text("Hinta"),
        width: new TacoTable_Types.Fixed("8rem"),
        cell: TacoTable_Types.PlainNumberCell.create(2)(function (v) {
            return v.pricePerUnit;
        })
    }, {
        key: "pricePerUnitLabel",
        label: React_Basic_DOM.text(""),
        width: new TacoTable_Types.Fixed("8rem"),
        cell: new TacoTable_Types.PlainTextCell(function (resource) {
            return Utils.fromNullable("")(Utils.mapNullable(Data_Semigroup.append(Data_Semigroup.semigroupString)(currencySymbol + "/"))(resource.unit));
        })
    }, {
        key: "discountPerentage",
        label: React_Basic_DOM.text("Ale-%"),
        width: new TacoTable_Types.Fixed("8rem"),
        cell: TacoTable_Types.PlainTextCell.create((function () {
            var $55 = Formatters.formatDecimalValue(2);
            return function ($56) {
                return $55((function (v) {
                    return v.discountPercentage;
                })($56));
            };
        })())
    }, {
        key: "priceWithDiscount",
        label: React_Basic_DOM.text("Hinta sis. ale"),
        width: new TacoTable_Types.Fixed("8rem"),
        cell: new TacoTable_Types.PlainNumberCell(2, function (v) {
            return v.priceWithDiscount;
        })
    }, {
        key: "button",
        label: React_Basic_DOM.text(""),
        width: new TacoTable_Types.Fixed("8rem"),
        cell: new TacoTable_Types.JSXCell([  ], function (r) {
            return TacoButton.component()()({
                text: "+ Lis\xe4\xe4 panos",
                buttonStyle: TacoButton_Types.Link.value,
                color: TacoConstants.resourceColors.border,
                onClick: new Data_Maybe.Just((function () {
                    var actionType = (function () {
                        if (props.componentSplitParams instanceof Data_Maybe.Nothing) {
                            return Types_Estimation.InPlaceResourceUpdate.value;
                        };
                        if (props.componentSplitParams instanceof Data_Maybe.Just) {
                            return new Types_Estimation.SplitElementComponent(props.componentSplitParams.value0);
                        };
                        throw new Error("Failed pattern match at CostEstimation.SelectResource.SelectResourceTable (line 290, column 30 - line 292, column 75): " + [ props.componentSplitParams.constructor.name ]);
                    })();
                    if (r.databaseRowId instanceof PriceListRowId) {
                        return props.dispatch(new Actions.EstimationResourceActionRequest({
                            projectId: props.projectId,
                            params: {
                                actionType: actionType,
                                actionParams: new Types_Estimation.AddResourcesToComponentFromOrgPriceList({
                                    componentId: props.componentId,
                                    orgResourcePriceIds: [ r.databaseRowId.value0 ]
                                })
                            },
                            handler: function (v) {
                                return props.dispatch(new Actions.GetReferenceEstimationResourcesRequest({
                                    projectId: props.projectId
                                }));
                            }
                        }));
                    };
                    if (r.databaseRowId instanceof ProjectResourceId) {
                        return props.dispatch(new Actions.EstimationResourceActionRequest({
                            projectId: props.projectId,
                            params: {
                                actionType: actionType,
                                actionParams: new Types_Estimation.CopyResourcesToComponent({
                                    componentId: props.componentId,
                                    resourceIds: [ r.databaseRowId.value0 ]
                                })
                            },
                            handler: function (v) {
                                return props.dispatch(new Actions.GetReferenceEstimationResourcesRequest({
                                    projectId: props.projectId
                                }));
                            }
                        }));
                    };
                    throw new Error("Failed pattern match at CostEstimation.SelectResource.SelectResourceTable (line 293, column 15 - line 319, column 22): " + [ r.databaseRowId.constructor.name ]);
                })())
            });
        })
    } ]);
};
var fromWasmEstimationResource = function (r) {
    return {
        uiRowId: Data_Newtype.unwrap()(r.id),
        databaseRowId: new ProjectResourceId(r.id),
        projectId: r.projectId,
        usagesCount: r.usagesCount,
        costClassCode: r.costClassCode,
        resourceClassificationGroup: r.resourceClassificationGroup,
        sortingNumber: r.sortingNumber,
        taxPercentage: r.taxPercentage,
        multiplier: 1.0,
        name: r.name,
        unit: r.unit,
        pricePerUnit: Utils.fromNullable(0.0)(r.pricePerUnit),
        discountPercentage: r.discountPercentage,
        priceWithDiscount: r.priceWithDiscount,
        group: r.group,
        index: r.index,
        orgResourcePriceId: r.orgResourcePriceId,
        orgResourcePriceListId: r.orgResourcePriceListId,
        currency: wrapCurrencySymbol(r.currencyId),
        vendor: r.vendor,
        discountGroupCode: r.discountGroupCode,
        changeDate: r.changeDate,
        validDate: r.validDate,
        contract: r.contract,
        eanCode: r.eanCode,
        vendorCode: r.vendorCode,
        vendorUnit: r.vendorUnit,
        vendorBatchSize: r.vendorBatchSize,
        unitConsumptionRate: Data_Nullable.notNull(r.unitConsumptionRate),
        wastePercentage: Data_Nullable.notNull(r.wastePercentage)
    };
};
var fromOrgResourcePrice = function (r) {
    return {
        uiRowId: r.orgResourcePriceId,
        databaseRowId: new PriceListRowId(r.orgResourcePriceId),
        projectId: Data_Nullable["null"],
        usagesCount: 0.0,
        costClassCode: r.costClassCode,
        resourceClassificationGroup: r.resourceClassificationGroup,
        sortingNumber: r.sortingNumber,
        taxPercentage: Types_Unit.Taxrate(Utils.fromNullable(0.0)(r.taxRate)),
        multiplier: 1.0,
        name: Utils.fromNullable("")(r.name),
        unit: r.unit,
        pricePerUnit: Utils.fromNullable(0.0)(r.price),
        discountPercentage: r.discountPercentage,
        priceWithDiscount: r.priceWithDiscount,
        group: Utils.nullValue,
        index: r.index,
        orgResourcePriceId: Utils.pureNullable(r.orgResourcePriceId),
        orgResourcePriceListId: Utils.pureNullable(r.priceListId),
        currency: wrapCurrencySymbol(r.currencyId),
        vendor: r.vendor,
        discountGroupCode: r.discountGroupCode,
        changeDate: r.changeDate,
        validDate: r.validDate,
        contract: r.contract,
        eanCode: r.eanCode,
        vendorCode: r.vendorCode,
        vendorUnit: r.vendorUnit,
        vendorBatchSize: r.vendorBatchSize,
        unitConsumptionRate: Data_Nullable["null"],
        wastePercentage: Data_Nullable["null"]
    };
};
var eqResourceFieldId = {
    eq: function (x) {
        return function (y) {
            if (x instanceof PriceListRowId && y instanceof PriceListRowId) {
                return x.value0 === y.value0;
            };
            if (x instanceof ProjectResourceId && y instanceof ProjectResourceId) {
                return Data_Eq.eq(Types_Component.eqEstimationResourceId)(x.value0)(y.value0);
            };
            return false;
        };
    }
};
var ordResourceFieldId = {
    compare: function (x) {
        return function (y) {
            if (x instanceof PriceListRowId && y instanceof PriceListRowId) {
                return Data_Ord.compare(Data_Ord.ordInt)(x.value0)(y.value0);
            };
            if (x instanceof PriceListRowId) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof PriceListRowId) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ProjectResourceId && y instanceof ProjectResourceId) {
                return Data_Ord.compare(Types_Component.ordEstimationResourceId)(x.value0)(y.value0);
            };
            throw new Error("Failed pattern match at CostEstimation.SelectResource.SelectResourceTable (line 93, column 1 - line 93, column 58): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqResourceFieldId;
    }
};
var selectResourceOptimizedTable = TacoTable.tableOptimized(TofuHooks.getNameVoid)(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "wastePercentage";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "vendorUnit";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "vendorCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "vendorBatchSize";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "vendor";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "validDate";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "usagesCount";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "unitConsumptionRate";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "unit";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "uiRowId";
    }
})(Data_Eq.eqInt))()({
    reflectSymbol: function () {
        return "taxPercentage";
    }
})(Types_Unit.eqTaxrate))()({
    reflectSymbol: function () {
        return "sortingNumber";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "resourceClassificationGroup";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "projectId";
    }
})(Data_Nullable.eqNullable(Types_Project.eqProjectId)))()({
    reflectSymbol: function () {
        return "priceWithDiscount";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "pricePerUnit";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "orgResourcePriceListId";
    }
})(Data_Nullable.eqNullable(Types_Component.eqOrgEstimationResourceId)))()({
    reflectSymbol: function () {
        return "orgResourcePriceId";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqInt)))()({
    reflectSymbol: function () {
        return "name";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "multiplier";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "index";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "group";
    }
})(Data_Nullable.eqNullable(Types_ClassificationGroup.eqClassificationGroupCode)))()({
    reflectSymbol: function () {
        return "eanCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "discountPercentage";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "discountGroupCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "databaseRowId";
    }
})(eqResourceFieldId))()({
    reflectSymbol: function () {
        return "currency";
    }
})(Data_Nullable.eqNullable(Types_Project.eqCurrency)))()({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(Data_Nullable.eqNullable(Types_Newtypes.eqCostClassCode)))()({
    reflectSymbol: function () {
        return "contract";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "changeDate";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString))));
var selectResourceTable = TofuHooks.mkHookComponent("SelectResourceTable")(function (props) {
    return function __do() {
        var tableRef = UseNullableRef.useNullableRef();
        var columns = getColumns(props);
        var mkRow = function (v) {
            return function (res) {
                return {
                    className: "select-resource-row",
                    rowData: res,
                    foldingElement: Data_Maybe.Nothing.value,
                    onClick: function (r) {
                        return props.selectResource(r);
                    },
                    rowKey: Data_Show.show(Data_Show.showInt)(res.uiRowId),
                    columns: columns
                };
            };
        };
        var table = selectResourceOptimizedTable({
            sortProperty: Data_Maybe.Nothing.value,
            onSort: Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit))),
            sortingOrder: TacoTable_Types.Ascending.value,
            rows: props.resources,
            mkRow: mkRow,
            rowHeight: TacoTable_Types.RowSmall.value,
            containerRef: tableRef,
            showHeader: true,
            columns: columns,
            styleSet: TacoTable_Styles.optimizedTableStyleSet,
            initialScrollRow: Data_Maybe.Nothing.value,
            onResize: function (v) {
                return function (v1) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                };
            },
            columnSizeOffsets: Data_Maybe.Nothing.value
        });
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "__SelectResourceTableWrapper",
            css: CostEstimation_SelectResource_Styles.addResourceTableWrapperStyles,
            children: [ table ]
        });
    };
});
var compareWithoutIds = function (dictLacks) {
    return function (dictLacks1) {
        return function (dictRowToList) {
            return function (dictOrdRecord) {
                return function (record1) {
                    return function (record2) {
                        var record2$prime = removeIds()()(record2);
                        var record1$prime = removeIds()()(record1);
                        return Data_Ord.compare(Data_Ord.ordRecord()(dictOrdRecord))(record1$prime)(record2$prime);
                    };
                };
            };
        };
    };
};
module.exports = {
    selectResourceTable: selectResourceTable,
    PriceListRowId: PriceListRowId,
    ProjectResourceId: ProjectResourceId,
    removeIds: removeIds,
    compareWithoutIds: compareWithoutIds,
    removeCostClassPrices: removeCostClassPrices,
    wrapCurrencySymbol: wrapCurrencySymbol,
    unwrapCurrencySymbol: unwrapCurrencySymbol,
    fromWasmEstimationResource: fromWasmEstimationResource,
    fromOrgResourcePrice: fromOrgResourcePrice,
    getColumns: getColumns,
    selectResourceOptimizedTable: selectResourceOptimizedTable,
    eqResourceFieldId: eqResourceFieldId,
    ordResourceFieldId: ordResourceFieldId
};
