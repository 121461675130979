// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
module.exports = {
    addMouseClickEventListenerToDocument: $foreign.addMouseClickEventListenerToDocument,
    addMouseClickEventListenerToDocument_: $foreign.addMouseClickEventListenerToDocument_,
    addMouseMoveEventListenerToDocument: $foreign.addMouseMoveEventListenerToDocument,
    addMouseUpEventListenerToDocument: $foreign.addMouseUpEventListenerToDocument,
    addScrollEventListenerToElement: $foreign.addScrollEventListenerToElement,
    addWindowResizeListener: $foreign.addWindowResizeListener,
    asDistinctStream: $foreign.asDistinctStream,
    removeMouseClickEventListenerFromDocument: $foreign.removeMouseClickEventListenerFromDocument,
    removeMouseMoveEventListenerFromDocument: $foreign.removeMouseMoveEventListenerFromDocument,
    removeMouseUpEventListenerFromDocument: $foreign.removeMouseUpEventListenerFromDocument,
    removeScrollEventListenerFromElement: $foreign.removeScrollEventListenerFromElement,
    removeWindowResizeListener: $foreign.removeWindowResizeListener
};
