import { WorkerRequest } from "../../../ts-bindings/WorkerRequest";
import { mkWorkerAction } from "./state";
import { CopyProjectAction } from "../../../ts-bindings/CopyProjectAction";
import { CopyProjectParams } from "../../../ts-bindings/CopyProjectParams";

export const mkCopyProjectUpdateAction = (
  action: CopyProjectAction
): WorkerRequest =>
  mkWorkerAction({
    type: "CopyProject",
    value: {
      action,
    },
  });
export const mkCopyProject = (params: CopyProjectParams): WorkerRequest =>
  mkCopyProjectUpdateAction({
    type: "CopyProject",
    value: params,
  });
