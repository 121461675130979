// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Kishimen = require("../Kishimen/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var Table = require("../Table/index.js");
var Table_Internal = require("../Table.Internal/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var TableRow = function (x) {
    return x;
};
var tableRowGeneric = {
    to: function (x) {
        return x;
    },
    from: function (x) {
        return x;
    }
};
var tofuJSONTableRow = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "TableRow";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editable";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "id";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "number";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "string";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editable";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "id";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "number";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "string";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.readVariantNil))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "TableRow";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editable";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "id";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "number";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "string";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editable";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "id";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "number";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "string";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.nilWriteForeignVariant))(tableRowGeneric)(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "TableRow";
        }
    })()(Kishimen.genericSumToVariantArgArguments)),
    readImpl: TofuJSON.readGenericSum(tableRowGeneric)(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "TableRow";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "TableRow";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editable";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "id";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "number";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "string";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editable";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "id";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "number";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "string";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.readVariantNil))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "TableRow";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editable";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "id";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "number";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "string";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editable";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "id";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "number";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "string";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.nilWriteForeignVariant))
};
var tableRowEq = {
    eq: function (x) {
        return function (y) {
            return x.editable === y.editable && x.id === y.id && x.number === y.number && x.string === y.string;
        };
    }
};
var newtypeTableRow = {
    Coercible0: function () {
        return undefined;
    }
};
var initialRowData = [ {
    id: 1,
    editable: "doubleclick me",
    string: "a",
    number: 1
}, {
    id: 2,
    editable: "or me",
    string: "b",
    number: 2
}, {
    id: 3,
    editable: "or me",
    string: "c",
    number: 3
} ];
var demoTable = TofuHooks.mkHookComponent("demoTable")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState(initialRowData)();
        var stringLength = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringInt)(Data_Functor.map(Data_Functor.functorArray)((function () {
            var $22 = Data_Newtype.unwrap();
            return function ($23) {
                return Data_String_CodePoints.length((function (v2) {
                    return v2.editable;
                })($22($23)));
            };
        })())(v1.value0));
        var onCellEditRequestHandler = function (v2) {
            return v1.value1(Data_Functor.map(Data_Functor.functorArray)(function (v3) {
                var $19 = v3.id === v2.id;
                if ($19) {
                    return v2;
                };
                return v3;
            }));
        };
        var props = [ new Table_Internal.ClassName("w-full h-full"), new Table_Internal.ColumnDefs([ [ new Table_Internal.Field("string"), new Table_Internal.HeaderName("string header") ], [ new Table_Internal.Field("editable"), new Table_Internal.Editable(true) ], [ new Table_Internal.Field("number"), new Table_Internal.HeaderName("Editable Number"), new Table_Internal.Width(150), new Table_Internal.Editable(true) ] ]), new Table_Internal.RowData(v1.value0), new Table_Internal.ReadOnlyEdit(true), new Table_Internal.OnCellEditRequest(onCellEditRequestHandler) ];
        var numSum = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringInt)(Data_Functor.map(Data_Functor.functorArray)((function () {
            var $24 = Data_Newtype.unwrap();
            return function ($25) {
                return (function (v2) {
                    return v2.number;
                })($24($25));
            };
        })())(v1.value0));
        return Box.box("boxDemo")([ new Box.Width(new TacoConstants.CustomSize("700px")), new Box.Height(new TacoConstants.CustomSize("700px")) ])([ React_Basic_DOM.text("Total length of editable strings: " + (Data_Show.show(Data_Show.showInt)(stringLength) + (", sum of numbers: " + Data_Show.show(Data_Show.showInt)(numSum)))), Table.table(tofuJSONTableRow)(props) ]);
    };
});
var sections = [ {
    title: "Table element",
    view: [ demoTable({}) ],
    code: "\x0a-- Since we're sharing our TableRow type with ag-grid, we need to\x0a-- implement TofuJSON for it. That also makes it so it has to be a\x0a-- newtype.\x0atype TableRecord =\x0a  { editable :: String\x0a  , id :: Int\x0a  , number :: Int\x0a  , string :: String\x0a  }\x0anewtype TableRow = TableRow TableRecord\x0a\x0aderive instance newtypeTableRow :: Newtype TableRow _\x0aderive instance tableRowEq :: Eq TableRow\x0aderive instance tableRowGeneric :: Generic TableRow _\x0a\x0ainstance tofuJSONTableRow :: TofuJSON TableRow where\x0a  writeImpl = writeGenericSum\x0a  readImpl = readGenericSum\x0a\x0ademoTable :: {} -> RB.JSX\x0ademoTable = mkHookComponent \"demoTable\" \\_ -> do\x0a  Tuple rows updateRows <- useState initialRowData\x0a\x0a  let\x0a    -- Update the row with a matching id to the changed row\x0a    onCellEditRequestHandler :: TableRow -> Effect Unit\x0a    onCellEditRequestHandler (TableRow newRow) =\x0a      updateRows $ map (\\(TableRow oldRow) -> if oldRow.id == newRow.id then TableRow newRow else TableRow oldRow)\x0a\x0a    props =\x0a      [ ClassName \"w-full h-full\"\x0a      , ColumnDefs\x0a        [ [Field \"string\", HeaderName \"string header\"]\x0a        , [Field \"editable\", Editable true]\x0a        , [Field \"number\", HeaderName \"Editable Number\", Width 150, Editable true]\x0a        ]\x0a      , RowData rows\x0a      , ReadOnlyEdit true\x0a      , OnCellEditRequest onCellEditRequestHandler\x0a      ]\x0a\x0a    stringLength = sum $ String.length <<< _.editable <<< unwrap <$> rows\x0a    numSum = sum $ _.number <<< unwrap <$> rows\x0a\x0a  pure $ B.box \"boxDemo\" [B.Width (CustomSize \"700px\"), B.Height (CustomSize \"700px\")]\x0a    [ R.text $ \"Total length of editable strings: \" <> show stringLength <> \", sum of numbers: \" <> show numSum\x0a    , TacoButton.component\x0a    , table props\x0a    ]\x0a    "
} ];
var demoView = {
    sections: sections,
    title: "Table",
    description: "Wrapper for AG Grid"
};
module.exports = {
    demoView: demoView
};
