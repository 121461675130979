// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var errorsContainerStyles = Box.boxStyle([ new Box.Position(Box.Fixed.value), Box.FlexColumn.value, Box.AlignEnd.value, Box.MaxWidth.create(new TacoConstants.CustomSize("30rem")), Box.PosTop.create(new TacoConstants.CustomSize("5rem")), new Box.PosRight(TacoConstants.M.value), new Box.ZIndex(TacoConstants.zIndexNotification) ]);
var errorDismissClickStyles = Box.boxStyle([ new Box.MarginLeft(TacoConstants.XS.value), new Box.Cursor(Box.Pointer.value), Box.Style.create(React_Basic_Emotion.css()({
    fontWeight: React_Basic_Emotion.str("bold")
})) ]);
module.exports = {
    errorsContainerStyles: errorsContainerStyles,
    errorDismissClickStyles: errorDismissClickStyles
};
