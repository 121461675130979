import { useQuery } from "react-query";
import axiosClient from "src/client-ts/axiosConfig";
import { NotificationsResponse } from "src/server-ts/notifications/NotificationsRouter";

const endpoint = "/notifications";

export const useNotifications = () =>
  useQuery(
    ["getNotifications"],
    async () => (await axiosClient.get<NotificationsResponse>(endpoint)).data,
    {
      refetchInterval: 1000 * 60 * 5, // 5 minutes
    }
  );
