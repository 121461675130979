import { ErrorObject, TargetAssessment } from "@tocoman/adminet-openapi-client";
import { get } from "lodash";

export type InvalidTargetAmountCause = {
  type: string;
  code: string;
  message: string;
  invalidCostGroupCodes: string[];
};

export const mapValidationErrorResponseToInvalidTargetAmountCause = (
  response: { error: ErrorObject },
  invalidTargetAmount: TargetAssessment
): InvalidTargetAmountCause => {
  const error = response.error;

  return {
    type: error.type,
    code: error.code,
    message: error.message,
    invalidCostGroupCodes: mapValidationErrorsToInvalidCostGroupCodes(
      error,
      invalidTargetAmount
    ),
  };
};

const mapValidationErrorsToInvalidCostGroupCodes = (
  error: ErrorObject,
  invalidTargetAmount: TargetAssessment
): string[] => {
  const validationErrors = error.validationErrors;
  if (!validationErrors) {
    return [];
  }

  const missingCostGroupCodeErrors: string[] = filterMissingCostGroupCodeErrors(
    validationErrors
  );

  return missingCostGroupCodeErrors
    .map((errorPath) => get(invalidTargetAmount, errorPath))
    .filter((errorCode) => errorCode);
};

const filterMissingCostGroupCodeErrors = (validationErrors: {
  [p: string]: Array<string>;
}): string[] => {
  const missingCostGroupCodeErrors: string[] = [];

  for (const errorKey in validationErrors) {
    const errorMessages = validationErrors[errorKey];

    if (isCostGroupMissingValidationError(errorKey, errorMessages)) {
      missingCostGroupCodeErrors.push(errorKey);
    }
  }

  return missingCostGroupCodeErrors;
};

const isCostGroupMissingValidationError = (
  errorKey: string,
  errorMessages: Array<string>
): boolean =>
  errorKey.endsWith("costGroupCode") &&
  errorMessages.includes("validation.exists");
