// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Filterable = require("../Data.Filterable/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Ref = require("../Effect.Ref/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var FRP_Event_Class = require("../FRP.Event.Class/index.js");
var Unsafe_Reference = require("../Unsafe.Reference/index.js");
var Event = function (x) {
    return x;
};
var subscribe = function (v) {
    return function (k) {
        return v((function () {
            var $59 = Data_Functor["void"](Effect.functorEffect);
            return function ($60) {
                return $59(k($60));
            };
        })());
    };
};
var sampleOn = function (v) {
    return function (v1) {
        return function (k) {
            return function __do() {
                var latest = Effect_Ref["new"](Data_Maybe.Nothing.value)();
                var c1 = v(function (a) {
                    return Effect_Ref.write(new Data_Maybe.Just(a))(latest);
                })();
                var c2 = v1(function (f) {
                    return Control_Bind.bind(Effect.bindEffect)(Effect_Ref.read(latest))(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(function ($61) {
                        return k(f($61));
                    }));
                })();
                return Control_Apply.applySecond(Effect.applyEffect)(c1)(c2);
            };
        };
    };
};
var makeEvent = Event;
var keepLatest = function (v) {
    return function (k) {
        return function __do() {
            var cancelInner = Effect_Ref["new"](Data_Maybe.Nothing.value)();
            var cancelOuter = v(function (inner) {
                return function __do() {
                    Control_Bind.bind(Effect.bindEffect)(Effect_Ref.read(cancelInner))(Data_Foldable.sequence_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe))();
                    var c = subscribe(inner)(k)();
                    return Effect_Ref.write(new Data_Maybe.Just(c))(cancelInner)();
                };
            })();
            return function __do() {
                Control_Bind.bind(Effect.bindEffect)(Effect_Ref.read(cancelInner))(Data_Foldable.sequence_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe))();
                return cancelOuter();
            };
        };
    };
};
var functorEvent = {
    map: function (f) {
        return function (v) {
            return function (k) {
                return v(function ($62) {
                    return k(f($62));
                });
            };
        };
    }
};
var fromRight_ = function (dictPartial) {
    return function (v) {
        if (v instanceof Data_Either.Right) {
            return v.value0;
        };
        throw new Error("Failed pattern match at FRP.Event (line 53, column 1 - line 53, column 53): " + [ v.constructor.name ]);
    };
};
var fromLeft_ = function (dictPartial) {
    return function (v) {
        if (v instanceof Data_Either.Left) {
            return v.value0;
        };
        throw new Error("Failed pattern match at FRP.Event (line 50, column 1 - line 50, column 52): " + [ v.constructor.name ]);
    };
};
var fold = function (f) {
    return function (v) {
        return function (b) {
            return function (k) {
                return function __do() {
                    var result = Effect_Ref["new"](b)();
                    return v(function (a) {
                        return Control_Bind.bind(Effect.bindEffect)(Effect_Ref.modify(f(a))(result))(k);
                    })();
                };
            };
        };
    };
};
var filter = function (p) {
    return function (v) {
        return function (k) {
            return v(function (a) {
                var $48 = p(a);
                if ($48) {
                    return k(a);
                };
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            });
        };
    };
};
var create = function __do() {
    var subscribers = Effect_Ref["new"]([  ])();
    return {
        event: function (k) {
            return function __do() {
                Effect_Ref.modify(function (v) {
                    return Data_Semigroup.append(Data_Semigroup.semigroupArray)(v)([ k ]);
                })(subscribers)();
                return function __do() {
                    Effect_Ref.modify(Data_Array.deleteBy(Unsafe_Reference.unsafeRefEq)(k))(subscribers)();
                    return Data_Unit.unit;
                };
            };
        },
        push: function (a) {
            return Control_Bind.bind(Effect.bindEffect)(Effect_Ref.read(subscribers))(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableArray)(function (k) {
                return k(a);
            }));
        }
    };
};
var fix = function (f) {
    var v = Effect_Unsafe.unsafePerformEffect(create);
    var v1 = f(v.event);
    return function (k) {
        return function __do() {
            var c1 = subscribe(v1.input)(v.push)();
            var c2 = subscribe(v1.output)(k)();
            return Control_Apply.applySecond(Effect.applyEffect)(c1)(c2);
        };
    };
};
var compactableEvent = {
    compact: function (xs) {
        return Data_Functor.map(functorEvent)(function (x) {
            return Data_Maybe.fromJust()(x);
        })(filter(Data_Maybe.isJust)(xs));
    },
    separate: function (xs) {
        return {
            left: Data_Functor.map(functorEvent)(fromLeft_())(filter(Data_Either.isLeft)(xs)),
            right: Data_Functor.map(functorEvent)(fromRight_())(filter(Data_Either.isRight)(xs))
        };
    }
};
var filterableEvent = {
    filter: filter,
    filterMap: function (f) {
        var $63 = Data_Functor.map(functorEvent)(function (x) {
            return Data_Maybe.fromJust()(x);
        });
        var $64 = filter(Data_Maybe.isJust);
        var $65 = Data_Functor.map(functorEvent)(f);
        return function ($66) {
            return $63($64($65($66)));
        };
    },
    partition: function (p) {
        return function (xs) {
            return {
                yes: filter(p)(xs),
                no: filter((function () {
                    var $67 = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean);
                    return function ($68) {
                        return $67(p($68));
                    };
                })())(xs)
            };
        };
    },
    partitionMap: function (f) {
        return function (xs) {
            return {
                left: Data_Filterable.filterMap(filterableEvent)((function () {
                    var $69 = Data_Either.either(Data_Maybe.Just.create)(Data_Function["const"](Data_Maybe.Nothing.value));
                    return function ($70) {
                        return $69(f($70));
                    };
                })())(xs),
                right: Data_Filterable.filterMap(filterableEvent)(function ($71) {
                    return Data_Either.hush(f($71));
                })(xs)
            };
        };
    },
    Compactable0: function () {
        return compactableEvent;
    },
    Functor1: function () {
        return functorEvent;
    }
};
var applyEvent = {
    apply: function (v) {
        return function (v1) {
            return function (k) {
                return function __do() {
                    var latestA = Effect_Ref["new"](Data_Maybe.Nothing.value)();
                    var latestB = Effect_Ref["new"](Data_Maybe.Nothing.value)();
                    var c1 = v(function (a) {
                        return function __do() {
                            Effect_Ref.write(new Data_Maybe.Just(a))(latestA)();
                            return Control_Bind.bind(Effect.bindEffect)(Effect_Ref.read(latestB))(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(function ($72) {
                                return k(a($72));
                            }))();
                        };
                    })();
                    var c2 = v1(function (b) {
                        return function __do() {
                            Effect_Ref.write(new Data_Maybe.Just(b))(latestB)();
                            return Control_Bind.bind(Effect.bindEffect)(Effect_Ref.read(latestA))(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(function ($73) {
                                return k((function (v2) {
                                    return v2(b);
                                })($73));
                            }))();
                        };
                    })();
                    return Control_Apply.applySecond(Effect.applyEffect)(c1)(c2);
                };
            };
        };
    },
    Functor0: function () {
        return functorEvent;
    }
};
var semigroupEvent = function (dictSemigroup) {
    return {
        append: Control_Apply.lift2(applyEvent)(Data_Semigroup.append(dictSemigroup))
    };
};
var applicativeEvent = {
    pure: function (a) {
        return function (k) {
            return function __do() {
                k(a)();
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            };
        };
    },
    Apply0: function () {
        return applyEvent;
    }
};
var monoidEvent = function (dictMonoid) {
    return {
        mempty: Control_Applicative.pure(applicativeEvent)(Data_Monoid.mempty(dictMonoid)),
        Semigroup0: function () {
            return semigroupEvent(dictMonoid.Semigroup0());
        }
    };
};
var altEvent = {
    alt: function (v) {
        return function (v1) {
            return function (k) {
                return function __do() {
                    var c1 = v(k)();
                    var c2 = v1(k)();
                    return Control_Apply.applySecond(Effect.applyEffect)(c1)(c2);
                };
            };
        };
    },
    Functor0: function () {
        return functorEvent;
    }
};
var plusEvent = {
    empty: function (v) {
        return Control_Applicative.pure(Effect.applicativeEffect)(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit));
    },
    Alt0: function () {
        return altEvent;
    }
};
var alternativeEvent = {
    Applicative0: function () {
        return applicativeEvent;
    },
    Plus1: function () {
        return plusEvent;
    }
};
var eventIsEvent = {
    fold: fold,
    keepLatest: keepLatest,
    sampleOn: sampleOn,
    fix: fix,
    Alternative0: function () {
        return alternativeEvent;
    },
    Filterable1: function () {
        return filterableEvent;
    }
};
module.exports = {
    create: create,
    makeEvent: makeEvent,
    subscribe: subscribe,
    functorEvent: functorEvent,
    compactableEvent: compactableEvent,
    filterableEvent: filterableEvent,
    applyEvent: applyEvent,
    applicativeEvent: applicativeEvent,
    altEvent: altEvent,
    plusEvent: plusEvent,
    alternativeEvent: alternativeEvent,
    semigroupEvent: semigroupEvent,
    monoidEvent: monoidEvent,
    eventIsEvent: eventIsEvent,
    count: FRP_Event_Class.count,
    filterMap: FRP_Event_Class.filterMap,
    fix: FRP_Event_Class.fix,
    fold: FRP_Event_Class.fold,
    folded: FRP_Event_Class.folded,
    gate: FRP_Event_Class.gate,
    gateBy: FRP_Event_Class.gateBy,
    keepLatest: FRP_Event_Class.keepLatest,
    mapAccum: FRP_Event_Class.mapAccum,
    sampleOn: FRP_Event_Class.sampleOn,
    sampleOn_: FRP_Event_Class.sampleOn_,
    withLast: FRP_Event_Class.withLast
};
