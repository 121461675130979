// Generated by purs version 0.14.5
"use strict";
var Components_Utils = require("../Components.Utils/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var HtmlUtils = require("../HtmlUtils/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var Record = require("../Record/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon = require("../TacoIcon/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoInput_Styles = require("../TacoInput.Styles/index.js");
var TacoSelect_Styles = require("../TacoSelect.Styles/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var UseNullableRef = require("../UseNullableRef/index.js");
var UseUUID = require("../UseUUID/index.js");
var Web_HTML_HTMLElement = require("../Web.HTML.HTMLElement/index.js");
var defaultProps = {
    items: [  ],
    value: "",
    label: "",
    title: "",
    onChange: Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit))),
    onFocus: Control_Applicative.pure(Effect.applicativeEffect)(Data_Monoid.mempty(Data_Monoid.monoidUnit)),
    onBlur: Control_Applicative.pure(Effect.applicativeEffect)(Data_Monoid.mempty(Data_Monoid.monoidUnit)),
    autoFocus: false,
    disabled: false,
    disabledOptions: [  ],
    showEmpty: false,
    rendering: TacoInput_Styles.DefaultRendering.value,
    clickEventHandling: TacoInput.Capture.value,
    testId: ""
};
var component = function (dictUnion) {
    return function (dictNub) {
        return TofuHooks.mkHookComponent("TacoSelect")(function (props) {
            var v = Record.merge()()(props)(defaultProps);
            return function __do() {
                var ref = UseNullableRef.useNullableRef();
                var autoFocusRef = TofuHooks.useRef(v.autoFocus)();
                TofuHooks.useEffect([ v.autoFocus ])(function __do() {
                    var autoFocusRefVal = TofuHooks.readRef(autoFocusRef)();
                    Control_Applicative.when(Effect.applicativeEffect)(v.autoFocus && !autoFocusRefVal)(function __do() {
                        var elem = HtmlUtils.readRefMaybeElement(ref)();
                        return Data_Maybe.maybe(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit))(Web_HTML_HTMLElement.focus)(elem)();
                    })();
                    TofuHooks.writeRef(autoFocusRef)(v.autoFocus)();
                    return Data_Monoid.mempty(Effect.monoidEffect(Effect.monoidEffect(Data_Monoid.monoidUnit)))();
                })();
                var id = UseUUID.useUUID();
                var mkOption = function (item) {
                    return React_Basic_DOM_Generated.option()({
                        value: item.value,
                        children: [ React_Basic_DOM.text(item.text) ],
                        disabled: Data_Array.elem(Data_Eq.eqString)(item.value)(v.disabledOptions)
                    });
                };
                var inputLabel = function (l) {
                    return TacoText.component()()({
                        text: l,
                        variant: new TacoText_Types.Label(id),
                        color: TacoConstants.TextSecondary.value,
                        size: new TacoConstants.CustomSize("10px"),
                        align: TacoText_Types.Left.value
                    });
                };
                var emptyItem = {
                    value: "",
                    text: "Ei valittu"
                };
                var items$prime = (function () {
                    if (v.showEmpty) {
                        return Data_Array.cons(emptyItem)(v.items);
                    };
                    return v.items;
                })();
                var options = Data_Functor.map(Data_Functor.functorArray)(mkOption)(items$prime);
                var select = React_Basic_Emotion.element(React_Basic_DOM_Generated["select'"]())({
                    className: "input",
                    css: TacoSelect_Styles.selectStyles({
                        rendering: v.rendering
                    }),
                    id: id,
                    ref: ref,
                    children: options,
                    value: v.value,
                    title: v.title,
                    onChange: React_Basic_Events.handler(React_Basic_DOM_Events.targetValue)(function (maybeValue) {
                        if (maybeValue instanceof Data_Maybe.Just) {
                            return v.onChange(function (v1) {
                                return maybeValue.value0;
                            });
                        };
                        if (maybeValue instanceof Data_Maybe.Nothing) {
                            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                        };
                        throw new Error("Failed pattern match at TacoSelect (line 141, column 11 - line 143, column 33): " + [ maybeValue.constructor.name ]);
                    }),
                    onFocus: React_Basic_Events.handler_(v.onFocus),
                    onBlur: React_Basic_Events.handler_(v.onBlur),
                    onClick: (function () {
                        if (v.clickEventHandling instanceof TacoInput.NoHandling) {
                            return React_Basic_Events.handler_(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit));
                        };
                        if (v.clickEventHandling instanceof TacoInput.PreventDefault) {
                            return React_Basic_Events.handler(React_Basic_DOM_Events.preventDefault)(Control_Applicative.pure(Control_Applicative.applicativeFn)(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit)));
                        };
                        if (v.clickEventHandling instanceof TacoInput.StopPropagation) {
                            return React_Basic_Events.handler(React_Basic_DOM_Events.stopPropagation)(Control_Applicative.pure(Control_Applicative.applicativeFn)(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit)));
                        };
                        if (v.clickEventHandling instanceof TacoInput.Capture) {
                            return React_Basic_DOM_Events.capture_(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit));
                        };
                        throw new Error("Failed pattern match at TacoSelect (line 146, column 18 - line 150, column 42): " + [ v.clickEventHandling.constructor.name ]);
                    })(),
                    disabled: v.disabled,
                    "_data": Components_Utils.mkTestIdAttrs(v.testId)
                });
                var arrow = React_Basic_Emotion.element(React_Basic_DOM_Generated["span'"]())({
                    className: "arrow",
                    css: TacoSelect_Styles.arrowStyles,
                    children: [ TacoIcon.component()()()()({
                        icon: TacoIcon_Icons_Types.IconTriangle.value,
                        size: TacoConstants.XS.value
                    }) ]
                });
                var selectContainer = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                    className: "input-container",
                    css: TacoSelect_Styles.selectContainerStyles,
                    children: [ select, arrow ]
                });
                var selectWrapper = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                    className: "select-wrapper",
                    css: TacoSelect_Styles.selectWrapperStyles,
                    children: [ selectContainer ]
                });
                return ReactBasicUtils["div'"]("__TacoSelect")([ Data_Monoid.guard(Data_Monoid.monoidFn(React_Basic.monoidJSX))(v.label !== "")(inputLabel)(v.label), selectWrapper ]);
            };
        });
    };
};
module.exports = {
    defaultProps: defaultProps,
    component: component
};
