exports.convertRTFToPlain = function (rtf) {
  var plain = rtf
    .replace(/\\par[d]?/g, "\n")
    .replace(/\{\*?\\[^{}]+}|[{}]|\\\n?[A-Za-z]+\n?(?:-?\d+)?[ ]?/g, "") // This is copy paste from https://stackoverflow.com/questions/29922771/convert-rtf-to-and-from-plain-text
    .replace(/\\'e4/g, "ä")
    .replace(/\\'f6/g, "ö")
    .trim();
  return plain;
};

exports.replaceSpaces = function (s) {
  return s.replace(/\s/g, "");
};

const whitespaceRe = /(\t| |\s)/;

exports.splitByWhitespace = function (s) {
  return s.split(whitespaceRe);
};

exports.padStart = (targetLength, fillString, str) => {
  return str.padStart(targetLength, fillString);
};

exports.padEnd = (targetLength, fillString, str) => {
  return str.padEnd(targetLength, fillString);
};
