import { Logo, LogoEstima, LogoInsite } from "@tocoman/ui";
import { UserDataWithPermissions } from "../../../server-ts/user/UserRouter";
import "./style.css";
import { useFrontEndNavigation } from "../../FrontEndNavigation/useFrontEndNavigation";

type AppSelectionProps = {
  userData: UserDataWithPermissions | undefined;
  currentProgram?: string;
  onNavigate: () => void;
};

export const AppSelection = ({
  userData,
  currentProgram,
  onNavigate,
}: AppSelectionProps) => {
  const hasCCRights = userData?.permissions.costControlPermission;
  const hasCERights = userData?.permissions.costEstimationPermission;
  let otherAppButton;
  const navigate = useFrontEndNavigation();
  switch (currentProgram) {
    case "Laskenta":
      otherAppButton = hasCCRights && (
        <button
          onClick={() => {
            onNavigate();
            navigate("ReportingProjectListRoute");
          }}
          className={"nav-popover-button"}
        >
          <Logo logo={LogoInsite} />
        </button>
      );
      break;
    case "Määrälaskenta":
      otherAppButton = hasCCRights && (
        <button
          onClick={() => {
            onNavigate();
            navigate("ReportingProjectListRoute");
          }}
          className={"nav-popover-button"}
        >
          <Logo logo={LogoInsite} />
        </button>
      );
      break;
    case "Raportointi":
      otherAppButton = hasCERights && (
        <button
          onClick={() => {
            onNavigate();
            navigate("EstimationProjectsRoute");
          }}
          className={"nav-popover-button"}
        >
          <Logo logo={LogoEstima} />
        </button>
      );
      break;
    default:
      otherAppButton = (
        <>
          {hasCCRights && (
            <button
              onClick={() => {
                onNavigate();
                navigate("ReportingProjectListRoute");
              }}
              className={"nav-popover-button"}
            >
              <Logo logo={LogoInsite} />
            </button>
          )}
          {hasCERights && (
            <button
              onClick={() => {
                onNavigate();
                navigate("EstimationProjectsRoute");
              }}
              className={"nav-popover-button"}
            >
              <Logo logo={LogoEstima} />
            </button>
          )}
        </>
      );
  }
  return <div className={"flex flex-col gap-3"}>{otherAppButton}</div>;
};
