// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Color = require("../Color/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Monad_Except = require("../Control.Monad.Except/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Function_Uncurried = require("../Data.Function.Uncurried/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Number_Format = require("../Data.Number.Format/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Foreign = require("../Foreign/index.js");
var Foreign_Object = require("../Foreign.Object/index.js");
var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");
var style = function (dict) {
    return dict.style;
};
var str = Unsafe_Coerce.unsafeCoerce;
var stretch = str("stretch");
var toUnitWith = function (f) {
    return function (s) {
        return function (a) {
            return str(f(a) + s);
        };
    };
};
var vh = toUnitWith(Data_Number_Format.toString)("vh");
var vmax = toUnitWith(Data_Number_Format.toString)("vmax");
var vmin = toUnitWith(Data_Number_Format.toString)("vmin");
var vw = toUnitWith(Data_Number_Format.toString)("vw");
var toUnitWithN = function (f) {
    return function (s) {
        return function (as) {
            return str(Data_String_Common.joinWith(" ")(Data_Functor.map(Data_Functor.functorArray)(function (a) {
                return f(a) + s;
            })(as)));
        };
    };
};
var toUnitWith2 = function (f) {
    return function (s) {
        return function (a) {
            return function (b) {
                return toUnitWithN(f)(s)([ a, b ]);
            };
        };
    };
};
var vh2 = toUnitWith2(Data_Number_Format.toString)("vh");
var vmax2 = toUnitWith2(Data_Number_Format.toString)("vmax");
var vmin2 = toUnitWith2(Data_Number_Format.toString)("vmin");
var vw2 = toUnitWith2(Data_Number_Format.toString)("vw");
var toUnitWith4 = function (f) {
    return function (s) {
        return function (a) {
            return function (b) {
                return function (c) {
                    return function (d) {
                        return toUnitWithN(f)(s)([ a, b, c, d ]);
                    };
                };
            };
        };
    };
};
var vh4 = toUnitWith4(Data_Number_Format.toString)("vh");
var vmax4 = toUnitWith4(Data_Number_Format.toString)("vmax");
var vmin4 = toUnitWith4(Data_Number_Format.toString)("vmin");
var vw4 = toUnitWith4(Data_Number_Format.toString)("vw");
var underline = str("underline");
var unset = str("unset");
var url = function (v) {
    return str("url(" + (v + ")"));
};
var $$var = function (n) {
    return str("var(" + (n + ")"));
};
var visible = str("visible");
var wrap = str("wrap");
var sticky = str("sticky");
var spaceEvenly = str("space-evenly");
var spaceBetween = str("space-between");
var spaceAround = str("space-around");
var solid = str("solid");
var scroll = str("scroll");
var row = str("row");
var revert = str("revert");
var rem4 = toUnitWith4(Data_Number_Format.toString)("rem");
var rem2 = toUnitWith2(Data_Number_Format.toString)("rem");
var rem = toUnitWith(Data_Number_Format.toString)("rem");
var relative = str("relative");
var px4$prime = toUnitWith4(Data_Number_Format.toString)("px");
var px4 = toUnitWith4(Data_Int.toStringAs(Data_Int.decimal))("px");
var px2$prime = toUnitWith2(Data_Number_Format.toString)("px");
var px2 = toUnitWith2(Data_Int.toStringAs(Data_Int.decimal))("px");
var px$prime = toUnitWith(Data_Number_Format.toString)("px");
var px = toUnitWith(Data_Int.toStringAs(Data_Int.decimal))("px");
var pt4 = toUnitWith4(Data_Number_Format.toString)("pt");
var pt2 = toUnitWith2(Data_Number_Format.toString)("pt");
var pt = toUnitWith(Data_Number_Format.toString)("pt");
var prop = function (dict) {
    return dict.prop;
};
var preWrap = str("pre-wrap");
var pointer = str("pointer");
var percent4 = toUnitWith4(Data_Number_Format.toString)("%");
var percent2 = toUnitWith2(Data_Number_Format.toString)("%");
var percent = toUnitWith(Data_Number_Format.toString)("%");
var pc4 = toUnitWith4(Data_Number_Format.toString)("pc");
var pc2 = toUnitWith2(Data_Number_Format.toString)("pc");
var pc = toUnitWith(Data_Number_Format.toString)("pc");
var num = Unsafe_Coerce.unsafeCoerce;
var nowrap = str("nowrap");
var none = str("none");
var nested = Unsafe_Coerce.unsafeCoerce;
var mm4 = toUnitWith4(Data_Number_Format.toString)("mm");
var mm2 = toUnitWith2(Data_Number_Format.toString)("mm");
var mm = toUnitWith(Data_Number_Format.toString)("mm");
var minContent = str("min-content");
var merge = Unsafe_Coerce.unsafeCoerce;
var semigroupStyle = {
    append: function (x) {
        return function (y) {
            var yF = Foreign.unsafeToForeign(y);
            var xF = Foreign.unsafeToForeign(x);
            var v = Control_Monad_Except.runExcept(Foreign.readArray(Data_Identity.monadIdentity)(yF));
            var v1 = Control_Monad_Except.runExcept(Foreign.readArray(Data_Identity.monadIdentity)(xF));
            if (v1 instanceof Data_Either.Right && v instanceof Data_Either.Right) {
                return merge(Data_Semigroup.append(Data_Semigroup.semigroupArray)(v1.value0)(v.value0));
            };
            if (v1 instanceof Data_Either.Left && v instanceof Data_Either.Right) {
                return merge(Data_Array.cons(x)(v.value0));
            };
            if (v1 instanceof Data_Either.Right && v instanceof Data_Either.Left) {
                return merge(Data_Array.snoc(v1.value0)(y));
            };
            if (v1 instanceof Data_Either.Left && v instanceof Data_Either.Left) {
                return merge([ x, y ]);
            };
            throw new Error("Failed pattern match at React.Basic.Emotion (line 99, column 7 - line 103, column 41): " + [ v1.constructor.name, v.constructor.name ]);
        };
    }
};
var monoidStyle = {
    mempty: $foreign.emptyStyle,
    Semigroup0: function () {
        return semigroupStyle;
    }
};
var maxContent = str("max-content");
var manipulation = str("manipulation");
var keyframes = function (dictHomogeneous) {
    var $34 = Foreign_Object.fromHomogeneous();
    return function ($35) {
        return $foreign.keyframes_($34($35));
    };
};
var isStyleStyle = {
    style: Control_Category.identity(Control_Category.categoryFn)
};
var isStylePropertyStyleProperty = {
    prop: Control_Category.identity(Control_Category.categoryFn)
};
var $$int = Unsafe_Coerce.unsafeCoerce;
var inlineGrid = str("inline-grid");
var inlineFlex = str("inline-flex");
var inlineBlock = str("inline-block");
var initial = str("initial");
var inherit = str("inherit");
var inches4 = toUnitWith4(Data_Number_Format.toString)("in");
var inches2 = toUnitWith2(Data_Number_Format.toString)("in");
var inches = toUnitWith(Data_Number_Format.toString)("in");
var hidden = str("hidden");
var grid = str("grid");
var flexStart = str("flex-start");
var flexEnd = str("flex-end");
var flex = str("flex");
var fixed = str("fixed");
var fallbacks = Unsafe_Coerce.unsafeCoerce;
var semigroupStyleProperty = {
    append: function (x) {
        return function (y) {
            var yF = Foreign.unsafeToForeign(y);
            var xF = Foreign.unsafeToForeign(x);
            var v = Control_Monad_Except.runExcept(Foreign.readArray(Data_Identity.monadIdentity)(yF));
            var v1 = Control_Monad_Except.runExcept(Foreign.readArray(Data_Identity.monadIdentity)(xF));
            if (v1 instanceof Data_Either.Right && v instanceof Data_Either.Right) {
                return fallbacks(Data_Semigroup.append(Data_Semigroup.semigroupArray)(v1.value0)(v.value0));
            };
            if (v1 instanceof Data_Either.Left && v instanceof Data_Either.Right) {
                return fallbacks(Data_Array.cons(x)(v.value0));
            };
            if (v1 instanceof Data_Either.Right && v instanceof Data_Either.Left) {
                return fallbacks(Data_Array.snoc(v1.value0)(y));
            };
            if (v1 instanceof Data_Either.Left && v instanceof Data_Either.Left) {
                return fallbacks([ x, y ]);
            };
            throw new Error("Failed pattern match at React.Basic.Emotion (line 125, column 7 - line 129, column 45): " + [ v1.constructor.name, v.constructor.name ]);
        };
    }
};
var monoidStyleProperty = {
    mempty: $foreign.emptyStyleProperty,
    Semigroup0: function () {
        return semigroupStyleProperty;
    }
};
var ex4 = toUnitWith4(Data_Number_Format.toString)("ex");
var ex2 = toUnitWith2(Data_Number_Format.toString)("ex");
var ex = toUnitWith(Data_Number_Format.toString)("ex");
var em4 = toUnitWith4(Data_Number_Format.toString)("em");
var em2 = toUnitWith2(Data_Number_Format.toString)("em");
var em = toUnitWith(Data_Number_Format.toString)("em");
var ellipsis = str("ellipsis");
var elementKeyed = Data_Function_Uncurried.runFn2($foreign.elementKeyed_);
var element = Data_Function_Uncurried.runFn2($foreign.element_);
var $$default = str("default");
var css = function (dictHomogeneous) {
    var $36 = Foreign_Object.fromHomogeneous();
    return function ($37) {
        return $foreign.css_($36($37));
    };
};
var contentBox = str("content-box");
var column = str("column");
var color = function ($38) {
    return str(Color.cssStringHSLA($38));
};
var cm4 = toUnitWith4(Data_Number_Format.toString)("cm");
var cm2 = toUnitWith2(Data_Number_Format.toString)("cm");
var cm = toUnitWith(Data_Number_Format.toString)("cm");
var ch4 = toUnitWith4(Data_Number_Format.toString)("ch");
var ch2 = toUnitWith2(Data_Number_Format.toString)("ch");
var ch = toUnitWith(Data_Number_Format.toString)("ch");
var center = str("center");
var borderBox = str("border-box");
var block = str("block");
var baseline = str("baseline");
var auto = str("auto");
var absolute = str("absolute");
module.exports = {
    style: style,
    prop: prop,
    element: element,
    elementKeyed: elementKeyed,
    css: css,
    keyframes: keyframes,
    nested: nested,
    merge: merge,
    str: str,
    "int": $$int,
    num: num,
    fallbacks: fallbacks,
    url: url,
    color: color,
    none: none,
    auto: auto,
    inherit: inherit,
    unset: unset,
    initial: initial,
    revert: revert,
    borderBox: borderBox,
    contentBox: contentBox,
    hidden: hidden,
    visible: visible,
    scroll: scroll,
    wrap: wrap,
    nowrap: nowrap,
    row: row,
    column: column,
    "default": $$default,
    manipulation: manipulation,
    pointer: pointer,
    solid: solid,
    ellipsis: ellipsis,
    underline: underline,
    fixed: fixed,
    absolute: absolute,
    relative: relative,
    sticky: sticky,
    block: block,
    inlineBlock: inlineBlock,
    flex: flex,
    inlineFlex: inlineFlex,
    grid: grid,
    inlineGrid: inlineGrid,
    flexStart: flexStart,
    flexEnd: flexEnd,
    center: center,
    stretch: stretch,
    baseline: baseline,
    spaceAround: spaceAround,
    spaceBetween: spaceBetween,
    spaceEvenly: spaceEvenly,
    minContent: minContent,
    maxContent: maxContent,
    preWrap: preWrap,
    "var": $$var,
    px: px,
    "px'": px$prime,
    cm: cm,
    mm: mm,
    inches: inches,
    pt: pt,
    pc: pc,
    em: em,
    ex: ex,
    ch: ch,
    rem: rem,
    vw: vw,
    vh: vh,
    vmin: vmin,
    vmax: vmax,
    percent: percent,
    px2: px2,
    "px2'": px2$prime,
    cm2: cm2,
    mm2: mm2,
    inches2: inches2,
    pt2: pt2,
    pc2: pc2,
    em2: em2,
    ex2: ex2,
    ch2: ch2,
    rem2: rem2,
    vw2: vw2,
    vh2: vh2,
    vmin2: vmin2,
    vmax2: vmax2,
    percent2: percent2,
    px4: px4,
    "px4'": px4$prime,
    cm4: cm4,
    mm4: mm4,
    inches4: inches4,
    pt4: pt4,
    pc4: pc4,
    em4: em4,
    ex4: ex4,
    ch4: ch4,
    rem4: rem4,
    vw4: vw4,
    vh4: vh4,
    vmin4: vmin4,
    vmax4: vmax4,
    percent4: percent4,
    semigroupStyle: semigroupStyle,
    monoidStyle: monoidStyle,
    isStyleStyle: isStyleStyle,
    semigroupStyleProperty: semigroupStyleProperty,
    monoidStyleProperty: monoidStyleProperty,
    isStylePropertyStyleProperty: isStylePropertyStyleProperty,
    global: $foreign.global,
    important: $foreign.important
};
