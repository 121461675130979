// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Color = require("../Color/index.js");
var Data_Number_Format = require("../Data.Number.Format/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var tacoLoaderProgressBarStyles = function (v) {
    return Box.boxStyle([ new Box.Height(TacoConstants.XS.value), Box.Width.create(new TacoConstants.CustomSize("auto")), new Box.Position(Box.Absolute.value), Box.PosTop.create(new TacoConstants.CustomSize("0px")), Box.PosLeft.create(new TacoConstants.CustomSize("0px")), Box.PosBottom.create(new TacoConstants.CustomSize("0px")), Box.Style.create(React_Basic_Emotion.css()({
        transition: React_Basic_Emotion.str("transform 0.2s linear"),
        transformOrigin: React_Basic_Emotion.str("left"),
        animation: React_Basic_Emotion.str((function () {
            var $3 = !v.secondary;
            if ($3) {
                return "progress-bar-primary 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite";
            };
            return "progress-bar-secondary 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s infinite";
        })())
    })), new Box.BackgroundColor(v.color) ]);
};
var tacoLoaderProgressBarContainerStyles = function (v) {
    var lightColor = (function () {
        var temp = Color.toRGBA(TacoConstants.color(v.color));
        return Color.rgba(temp.r)(temp.g)(temp.b)(0.35);
    })();
    return Box.boxStyle([ new Box.Height(TacoConstants.XS.value), Box.BackgroundColor.create(new TacoConstants.CustomColor(lightColor)), new Box.Position(Box.Relative.value), new Box.Overflow(Box.Hidden.value, Box.Hidden.value), new Box.Opacity((function () {
        if (v.isLoading) {
            return 1.0;
        };
        return 0.0;
    })()), new Box.Transition([ "opacity" ]) ]);
};
var tacoLoaderContainerStyles = function (props) {
    return Box.boxStyle([ new Box.Position(Box.Relative.value), new Box.ZIndex(TacoConstants.zIndexLoadingSpinner), new Box.Opacity((function () {
        if (props.hasMounted) {
            return 1.0;
        };
        return 0.0;
    })()), Box.Width.create(new TacoConstants.CustomSize(props.size)), Box.Height.create(new TacoConstants.CustomSize(props.size)), Box.Style.create(React_Basic_Emotion.css()({
        transitionProperty: React_Basic_Emotion.str("opacity"),
        transitionDelay: React_Basic_Emotion.str("250ms"),
        transitionDuration: React_Basic_Emotion.str("750ms")
    })) ]);
};
var offset = "187";
var duration = 1.5;
var loadingSpinnerPathStyles = React_Basic_Emotion.css()({
    strokeDasharray: React_Basic_Emotion.str(offset),
    strokeDashoffset: React_Basic_Emotion.str("1"),
    transformOrigin: React_Basic_Emotion.str("center"),
    animation: React_Basic_Emotion.str("dash " + (Data_Number_Format.toString(duration) + ("s ease-in-out infinite, orangeColors " + (Data_Number_Format.toString(duration * 4.0) + "s ease-in-out infinite"))))
});
var loadingSpinnerSvgStyles = Box.boxStyle([ new Box.Display(Box.InlineBlock.value), Box.Style.create(React_Basic_Emotion.css()({
    animation: React_Basic_Emotion.str("rotator " + (Data_Number_Format.toString(duration) + "s linear infinite"))
})) ]);
module.exports = {
    tacoLoaderContainerStyles: tacoLoaderContainerStyles,
    duration: duration,
    loadingSpinnerSvgStyles: loadingSpinnerSvgStyles,
    offset: offset,
    loadingSpinnerPathStyles: loadingSpinnerPathStyles,
    tacoLoaderProgressBarContainerStyles: tacoLoaderProgressBarContainerStyles,
    tacoLoaderProgressBarStyles: tacoLoaderProgressBarStyles
};
