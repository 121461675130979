// Generated by purs version 0.14.5
"use strict";
var Data_Monoid = require("../Data.Monoid/index.js");
var EstimationUtils = require("../EstimationUtils/index.js");
var React_Basic = require("../React.Basic/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoText = require("../TacoText/index.js");
var reportingWarning = TacoText.component()()({
    text: "Hanke julkaistu raportointiin",
    backgroundColor: TacoConstants.ButtonBackgroundNormal.value
});
var reportingAndProductionPlanningWarning = TacoText.component()()({
    text: "Hanke julkaistu raportointiin ja tuotannonsuunnitteluun",
    backgroundColor: TacoConstants.ButtonBackgroundNormal.value
});
var productionPlanningWarning = TacoText.component()()({
    text: "Hanke julkaistu tuotannonsuunnitteluun",
    backgroundColor: TacoConstants.ButtonBackgroundNormal.value
});
var projectPublishingStateWarning = function (p) {
    var v = EstimationUtils.isReporting(p);
    var v1 = EstimationUtils.isProductionPlanning(p);
    if (!v1 && !v) {
        return Data_Monoid.mempty(React_Basic.monoidJSX);
    };
    if (v1 && !v) {
        return productionPlanningWarning;
    };
    if (!v1 && v) {
        return reportingWarning;
    };
    if (v1 && v) {
        return reportingAndProductionPlanningWarning;
    };
    throw new Error("Failed pattern match at CostEstimation.ProductionPlanningWarning (line 46, column 35 - line 50, column 54): " + [ v1.constructor.name, v.constructor.name ]);
};
var productionPlanningElementConnectionsWarning = TacoText.component()()({
    text: "Hankkeen rakenne kohdistukset eiv\xe4t n\xe4y. Katso Tuotannonsuunnittelun puolelta.",
    backgroundColor: TacoConstants.DangerRedLight.value
});
module.exports = {
    projectPublishingStateWarning: projectPublishingStateWarning,
    productionPlanningElementConnectionsWarning: productionPlanningElementConnectionsWarning
};
