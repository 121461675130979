import { ValueGetterParams, ValueSetterParams } from "ag-grid-community";
import { safeDivide } from "../../../../utils/safeDivide";
import { formatOfferValueToNumber } from "../OfferPageCommonFunctions";
import { AgGridReact } from "ag-grid-react";
import { TFunction } from "i18next";

export function getPercentageToAdd(params: ValueGetterParams) {
  if (!params.data) {
    return null;
  }
  return params.data.additionalTotal / params.data.baseTotal;
}

export function getAdditionalTotal(params: ValueGetterParams) {
  if (!params.data) {
    return null;
  }
  if (params.node?.isRowPinned()) {
    let newTotal = 0;
    params.api.forEachNode((node) => {
      newTotal += Number(node.data.additionalTotal);
    });
    return newTotal;
  }
  return params.data.baseTotal * (params.data.percentageToAdd ?? 0);
}

export function setPercentageToAdd(params: ValueSetterParams) {
  const newValue = formatOfferValueToNumber(params);
  if (!params.node || params.newValue === params.oldValue) {
    return false;
  }
  if (params.node.isRowPinned()) {
    params.api.forEachNode((node) => {
      node.data.percentageToAdd = newValue;
      node.data.additionalTotal = node.data.baseTotal * newValue;
      params.api.refreshCells({ rowNodes: [node], force: true });
    });
    return true;
  }
  params.node.data.percentageToAdd = newValue;
  params.node.data.additionalTotal =
    params.node.data.baseTotal * (params.node.data.percentageToAdd ?? 0);
  return true;
}

export function setAdditionInCurrency(params: ValueSetterParams) {
  const newValue = formatOfferValueToNumber(params);
  if (!params.node || params.newValue === params.oldValue) {
    return false;
  }
  if (params.node.isRowPinned()) {
    const newPercentage = safeDivide(newValue, params.node.data.baseTotal);
    params.api.forEachNode((node) => {
      node.data.percentageToAdd = newPercentage;
      node.data.additionalTotal = node.data.baseTotal * newPercentage;
      params.api.refreshCells({ rowNodes: [node], force: true });
    });
    params.node.data.additionalTotal = newValue;
    return true;
  }
  params.node.data.percentageToAdd = safeDivide(
    newValue,
    params.node.data.baseTotal
  );
  params.node.data.additionalTotal = newValue;
  return true;
}

export function getCostItemsPinnedTotalRow(
  gridRef: React.RefObject<AgGridReact>,
  t: TFunction
) {
  if (!gridRef.current) {
    return undefined;
  }
  let baseTotal = 0;
  let additionalTotal = 0;
  let total = 0;
  gridRef.current.api?.forEachNodeAfterFilter((node) => {
    if (node.data) {
      baseTotal += node.data.baseTotal;
      additionalTotal += node.data.additionalTotal;
      total += node.data.total;
    }
  });
  const percentage = safeDivide(additionalTotal, baseTotal);
  return [
    {
      code: "total",
      description: t`total`,
      baseTotal,
      additionalTotal,
      total,
      percentageToAdd: percentage,
    },
  ];
}
