import { useMutation } from "react-query";
import axiosClient from "../../../../axiosConfig";
import { reportError } from "../../../../utils/error";

type ToggleComponentFlagMutation = {
  components: number[];
  flagged: boolean;
};
export function useToggleComponentFlags(projectId: number, onSave: () => void) {
  return useMutation(
    (data: ToggleComponentFlagMutation) => {
      return axiosClient.post(
        `/project/${projectId}/toggleFlagForComponents`,
        data
      );
    },
    {
      onSuccess: () => {
        onSave();
      },
      onError: (error) => {
        reportError(
          "Error while toggling component flag status",
          error as Error
        );
      },
    }
  );
}

type ToggleComponentCheckMutation = {
  components: number[];
  checked: boolean;
};
export function useToggleComponentChecks(
  projectId: number,
  onSave: () => void
) {
  return useMutation(
    (data: ToggleComponentCheckMutation) => {
      return axiosClient.post(
        `/project/${projectId}/toggleCheckedForComponents`,
        data
      );
    },
    {
      onSuccess: () => {
        onSave();
      },
      onError: (error) => {
        reportError(
          "Error while toggling component checked status",
          error as Error
        );
      },
    }
  );
}
