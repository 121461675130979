// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var rowStyles = function (boxProps) {
    return function (widths) {
        return React_Basic_Emotion.merge([ Box.boxStyle(boxProps), React_Basic_Emotion.css()({
            display: React_Basic_Emotion.str("grid"),
            gridTemplateColumns: React_Basic_Emotion.str(Data_Foldable.intercalate(Data_Foldable.foldableArray)(Data_Monoid.monoidString)(" ")(widths))
        }) ]);
    };
};
var gridStyles = function (boxProps) {
    return Box.boxStyle(Data_Array.concat([ [ new Box.Display(Box.Grid.value) ], boxProps ]));
};
module.exports = {
    gridStyles: gridStyles,
    rowStyles: rowStyles
};
