// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Type_Data_Row = require("../Type.Data.Row/index.js");
var Type_Data_RowList = require("../Type.Data.RowList/index.js");
var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");
var upcastVariantError = Unsafe_Coerce.unsafeCoerce;
var nilMatchKey = {
    matchKeyImpl: function (v) {
        return function (v1) {
            return false;
        };
    }
};
var mkVariantError$prime = function (message) {
    return function (v) {
        return $foreign["_mkVariantError"](v, message);
    };
};
var mkVariantError = function (message) {
    return function (v) {
        return upcastVariantError(mkVariantError$prime(message)(v));
    };
};
var matchKeyImpl = function (dict) {
    return dict.matchKeyImpl;
};
var matchKeyInst = function (dictMatchKeyImpl) {
    return function (dictRowToList) {
        return {
            matchKey: function (v) {
                return matchKeyImpl(dictMatchKeyImpl)(Type_Data_RowList.RLProxy.value);
            }
        };
    };
};
var matchKey = function (dict) {
    return dict.matchKey;
};
var readVariant = function (dictMatchKey) {
    return function (err) {
        return Data_Functor.map(Data_Maybe.functorMaybe)($foreign["_getVariant"])($foreign["_readVariantError"](matchKey(dictMatchKey)(Type_Data_Row.RProxy.value), Data_Maybe.Nothing.value, Data_Maybe.Just.create, err));
    };
};
var readVariantError = function (dictMatchKey) {
    return function (err) {
        return $foreign["_readVariantError"](matchKey(dictMatchKey)(Type_Data_Row.RProxy.value), Data_Maybe.Nothing.value, Data_Maybe.Just.create, err);
    };
};
var getVariant = $foreign["_getVariant"];
var consMatchKey = function (dictMatchKeyImpl) {
    return function (dictIsSymbol) {
        return {
            matchKeyImpl: function (v) {
                return function (s) {
                    var curr = Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value);
                    var $12 = s === curr;
                    if ($12) {
                        return true;
                    };
                    return matchKeyImpl(dictMatchKeyImpl)(Type_Data_RowList.RLProxy.value)(s);
                };
            }
        };
    };
};
module.exports = {
    matchKey: matchKey,
    matchKeyImpl: matchKeyImpl,
    mkVariantError: mkVariantError,
    "mkVariantError'": mkVariantError$prime,
    upcastVariantError: upcastVariantError,
    readVariant: readVariant,
    readVariantError: readVariantError,
    getVariant: getVariant,
    matchKeyInst: matchKeyInst,
    nilMatchKey: nilMatchKey,
    consMatchKey: consMatchKey,
    isVariantError: $foreign.isVariantError,
    "_mkVariantError": $foreign["_mkVariantError"],
    "_readVariantError": $foreign["_readVariantError"],
    "_getVariant": $foreign["_getVariant"]
};
