import { Table } from "../../../../components/Table";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { EstimationResource } from "../../../../../../ts-bindings/EstimationResource";
import { ColDef, ColGroupDef } from "ag-grid-community";
import {
  getPriceWithDiscount,
  getPriceWithDiscountWithSocialExpenses,
  getResourceAmount,
  getResourceTotal,
  getResourceTotalPerUnit,
  getResourceTotalPerUnitWithSocialExpenses,
  getResourceTotalWithSocialExpenses,
  getResourceCostClass,
  resourcesTotalPerUnitHeader,
} from "./ResourceDetailsFunctions";
import { AgGridCommon } from "ag-grid-community/dist/lib/interfaces/iCommon";
import { IRowNode } from "ag-grid-community/dist/lib/interfaces/iRowNode";
import { ProjectClassificationClass } from "../../../../../../ts-bindings/ProjectClassificationClass";
import { Measurement } from "../../../../../../ts-bindings/Measurement";
import { ComponentWithResources } from "../../../../../../ts-bindings/ComponentWithResources";
import { CostClass } from "../../../../../../ts-bindings/CostClass";
import { Subproject } from "../../../../../../ts-bindings/Subproject";
import { ComponentSummary } from "../Printing";
import "./resourceDetailsStyles.css";

export type EstimationContext = {
  classifications: ProjectClassificationClass[] | null;
  measurements: Measurement[];
  components: ComponentWithResources[] | null;
  costClasses: CostClass[];
  subprojects: Subproject[];
};

export interface ResourceProcessRowGroupForExportParams<
  TData = ComponentSummary,
  TContext = EstimationContext
> extends AgGridCommon<TData, TContext> {
  data: TData;
  node: IRowNode<TData>;
}
export const ResourceDetails = (
  detailsParams: ResourceProcessRowGroupForExportParams,
  onDetailRowsRendered: (value: boolean) => void
) => {
  const { t } = useTranslation("estimation", { keyPrefix: "printing" });

  const rowId = detailsParams.node.id;
  useEffect(() => {
    return () => {
      if (rowId) {
        detailsParams.api.removeDetailGridInfo(rowId);
      }
    };
  }, [detailsParams.api, rowId]);

  const colDefs: (ColDef | ColGroupDef)[] = useMemo(() => {
    const columns: (
      | ColDef<EstimationResource>
      | ColGroupDef<EstimationResource>
    )[] = [
      {
        field: `costClassCode`,
        headerName: t`resourceColumns.costClassCode`,
        valueGetter: (params) => getResourceCostClass(params, detailsParams),
        sort: "asc",
      },
      {
        field: `name`,
        headerName: t`resourceColumns.name`,
      },
      {
        field: `priceWithDiscount`,
        headerName: t`resourceColumns.priceWithDiscount`,
        valueGetter: getPriceWithDiscount,
        type: ["numericColumn"],
      },
      {
        field: t`resourceColumns.priceWithDiscountWithSocialExpenses`,
        headerName: t`resourceColumns.priceWithDiscountWithSocialExpenses`,
        valueGetter: (params) =>
          getPriceWithDiscountWithSocialExpenses(params, detailsParams),
        type: ["numericColumn"],
      },
      {
        field: `unit`,
        headerName: t`resourceColumns.unit`,
      },
      {
        field: `unitConsumptionRate`,
        headerName: t`resourceColumns.unitConsumptionRate`,
        type: ["numericColumn"],
      },
      {
        field: `wastePercentage`,
        headerName: t`resourceColumns.wastePercentage`,
        type: ["numericColumn"],
      },
      {
        colId: `amount`,
        headerName: t`resourceColumns.amount`,
        valueGetter: (params) => getResourceAmount(params, detailsParams),
        type: ["numericColumn"],
      },
      {
        colId: `totalPerUnit`,
        headerComponent: resourcesTotalPerUnitHeader,
        headerComponentParams: { data: detailsParams.data },
        valueGetter: getResourceTotalPerUnit,
        type: ["numericColumn"],
      },
      {
        colId: `totalPerUnitWithSocialExpenses`,
        headerComponent: resourcesTotalPerUnitHeader,
        headerComponentParams: { data: detailsParams.data },
        valueGetter: (params) =>
          getResourceTotalPerUnitWithSocialExpenses(params, detailsParams),
        type: ["numericColumn"],
      },
      {
        colId: `total`,
        headerName: t`resourceColumns.total`,
        valueGetter: (params) => getResourceTotal(params, detailsParams),
        type: ["numericColumn"],
      },
      {
        colId: `totalWithSocialCosts`,
        headerName: t`resourceColumns.totalWithSocialCosts`,
        valueGetter: (params) =>
          getResourceTotalWithSocialExpenses(params, detailsParams),
        type: ["numericColumn"],
      },
    ];
    return columns;
  }, [t, detailsParams]);

  const defaultColDef = {
    flex: 1,
    sortable: true,
  };

  const onGridReady = () => {
    const gridInfo = {
      id: rowId ? rowId : "",
      api: detailsParams.api,
      columnApi: detailsParams.columnApi,
    };

    if (rowId) {
      detailsParams.api.addDetailGridInfo(rowId, gridInfo);
    }
  };

  const onFirstDataRendered = () => {
    onDetailRowsRendered(true);
  };

  return (
    <div id={"resourceDetails"}>
      <Table<EstimationResource>
        className={`p-3`}
        theme={"alpine"}
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        rowData={detailsParams.data.resources}
        onGridReady={onGridReady}
        domLayout={"autoHeight"}
        suppressScrollOnNewData={true}
        onFirstDataRendered={onFirstDataRendered}
      />
    </div>
  );
};
