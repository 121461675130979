import { ExcelCell, ProcessRowGroupForExportParams } from "ag-grid-community";
import { TFunction } from "react-i18next";
import { EstimationResource } from "../../../../../../ts-bindings/EstimationResource";
import {
  getPriceWithDiscountWithSocialExpenses,
  getResourceAmount,
  getResourceTotal,
  getResourceTotalPerUnit,
  getResourceTotalPerUnitWithSocialExpenses,
  getResourceTotalWithSocialExpenses,
  getResourceCostClass,
  resourcesTotalPerUnitHeader,
  roundWithTwoDecimals,
} from "./ResourceDetailsFunctions";
import { ComponentWithResources } from "../../../../../../ts-bindings/ComponentWithResources";

const cell = (text: string | null, styleId: string): ExcelCell => {
  return {
    styleId: styleId,
    data: {
      type: /^\d+$/.test(text as string) ? "Number" : "String",
      value: String(text),
    },
  };
};
export const getResourceRows = (
  params: ProcessRowGroupForExportParams<ComponentWithResources>,
  t: TFunction<"estimation", "printing">,
  includeResources: boolean
) => {
  if (!params.node.data?.resources || !includeResources) {
    return undefined;
  }
  return [
    {
      outlineLevel: 1,
      cells: [
        cell(t`resourceColumns.costClassCode`, "header"),
        cell(t`resourceColumns.name`, "header"),
        cell(t`resourceColumns.priceWithDiscount`, "header"),
        cell(t`resourceColumns.priceWithDiscountWithSocialExpenses`, "header"),
        cell(t`resourceColumns.unit`, "header"),
        cell(t`resourceColumns.unitConsumptionRate`, "header"),
        cell(t`resourceColumns.wastePercentage`, "header"),
        cell(t`resourceColumns.amount`, "header"),
        cell(resourcesTotalPerUnitHeader(params.node), "header"),
        cell(resourcesTotalPerUnitHeader(params.node), "header"),
        cell(t`resourceColumns.total`, "header"),
        cell(t`resourceColumns.totalWithSocialCosts`, "header"),
      ],
    },
  ].concat(
    ...params.node.data.resources
      .sort(
        (a: EstimationResource, b: EstimationResource) =>
          Number(a.costClassCode) - Number(b.costClassCode)
      )
      .map((item: EstimationResource) => [
        {
          outlineLevel: 1,
          cells: [
            cell(getResourceCostClass(item, params), "body"),
            cell(item.name, "body"),
            cell(roundWithTwoDecimals(item.priceWithDiscount), "body"),
            cell(getPriceWithDiscountWithSocialExpenses(item, params), "body"),
            cell(item.unit, "body"),
            cell(item.unitConsumptionRate.toString(), "body"),
            cell(item.wastePercentage.toString(), "body"),
            cell(getResourceAmount(item, params), "body"),
            cell(getResourceTotalPerUnit(item), "body"),
            cell(
              getResourceTotalPerUnitWithSocialExpenses(item, params),
              "body"
            ),
            cell(getResourceTotal(item, params), "body"),
            cell(getResourceTotalWithSocialExpenses(item, params), "body"),
          ],
        },
      ])
  );
};
