// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Client_WASM = require("../Client.WASM/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var ProjectComponents = require("../ProjectComponents/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var UseFetch = require("../UseFetch/index.js");
var component = TofuHooks.mkHookComponent("AddProject")(function (props) {
    return function __do() {
        var v = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v) {
            return v.constructionTypes;
        })(Actions.ConstructionTypesRequest.value)();
        var v1 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v1) {
            return v1.projectManagers;
        })(Actions.GetProjectManagersRequest.value)();
        var v2 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v2) {
            return v2.projectTypes;
        })(Actions.ProjectTypesRequest.value)();
        var v3 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v3) {
            return v3.orgAddressArea;
        })(Actions.OrgAddressAreaRequest.value)();
        var v4 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v4) {
            return v4.classifications;
        })(Actions.ClassificationsRequest.value)();
        var v5 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v5) {
            return v5.projectGroups;
        })(Actions.ProjectGroupsRequest.value)();
        var v6 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v6) {
            return v6.financingTypes;
        })(Actions.FinancingTypesRequest.value)();
        var v7 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v7) {
            return v7.contractTypes;
        })(Actions.ContractTypesRequest.value)();
        var v8 = Client_WASM.useEstimationProjects();
        return React_Basic_DOM_Generated.div()({
            className: "add-project-page",
            children: [ TacoText.component()()({
                text: "Hankkeen tiedot",
                weight: TacoText_Types.Bold.value,
                gutterBottom: true,
                variant: new TacoText_Types.Heading(1),
                size: new TacoConstants.CustomSize("14px")
            }), ProjectComponents.component({
                projectComponentsMode: props.projectComponentsMode,
                mProjectId: Data_Maybe.Nothing.value,
                mProject: Data_Maybe.Nothing.value,
                mVATExclusionCostGroupsString: Data_Maybe.Nothing.value,
                mConstructionTypes: v.value0,
                disableClassifications: false,
                mProjectManagers: v1.value0,
                mProjectTypes: v2.value0,
                mOrgAddressArea: v3.value0,
                mClassifications: v4.value0,
                mProjectGroups: v5.value0,
                mFinancingTypes: v6.value0,
                mContractTypes: v7.value0,
                mProjectList: new Data_Maybe.Just(v8.value0)
            }) ]
        });
    };
});
module.exports = {
    component: component
};
