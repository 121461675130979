import { useMutation, UseMutationResult } from "react-query";
import axiosClient from "../../../../axiosConfig";
import { ConvertToParams, ConvertToResponse } from "../ConvertToTopic";

export function useConvertToRegular(
  onSave: () => void
): UseMutationResult<ConvertToResponse, unknown, ConvertToParams> {
  return useMutation({
    mutationFn: async (value: ConvertToParams) => {
      const { data } = await axiosClient.post(
        `/project/${value.projectId}/component/convertToRegular`,
        value
      );
      return data;
    },
    onSuccess: () => {
      onSave();
    },
  });
}

export function useConvertToTopic(
  onSave: () => void
): UseMutationResult<ConvertToResponse, unknown, ConvertToParams> {
  return useMutation({
    mutationFn: async (value: ConvertToParams) => {
      const { data } = await axiosClient.post(
        `/project/${value.projectId}/component/convertToTopic`,
        value
      );
      return data;
    },
    onSuccess: () => {
      onSave();
    },
  });
}
