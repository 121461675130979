// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Color = require("../Color/index.js");
var CostEstimationLayout_Types = require("../CostEstimationLayout.Types/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var StyleProperties = require("../StyleProperties/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var rightSidebarStyles = Box.boxStyle([ Box.Style.create(React_Basic_Emotion.css()({
    gridArea: React_Basic_Emotion.str("right-sidebar")
})), Box.FlexColumn.value, Box.Style.create(React_Basic_Emotion.css()({
    borderLeft: StyleProperties.border(TacoConstants.BorderLight.value)
})), new Box.Selector("> *", [ new Box.Width(TacoConstants.XXL.value) ]), new Box.Overflow(Box.Hidden.value, Box.Auto.value), new Box.ZIndex(2) ]);
var rightPanelStyles = Box.boxStyle([ Box.Style.create(React_Basic_Emotion.css()({
    gridArea: React_Basic_Emotion.str("right-panel")
})), Box.FlexColumn.value, Box.Style.create(React_Basic_Emotion.css()({
    borderLeft: StyleProperties.border(TacoConstants.BorderLight.value)
})), new Box.ZIndex(2) ]);
var panelModeButtonsStyles = function (v) {
    return Box.boxStyle([ Box.FlexRow.value, new Box.MarginRight(TacoConstants.S.value) ]);
};
var panelModeButtonStyles = function (v) {
    return Box.boxStyle([ new Box.Width(TacoConstants.M.value), new Box.Height((function () {
        if (v.panelMode instanceof CostEstimationLayout_Types.MinimizedMode) {
            return TacoConstants.XXS.value;
        };
        if (v.panelMode instanceof CostEstimationLayout_Types.SmallMode) {
            return TacoConstants.S.value;
        };
        if (v.panelMode instanceof CostEstimationLayout_Types.NormalMode) {
            if (v.smallMode) {
                return TacoConstants.M.value;
            };
            return TacoConstants.S.value;
        };
        if (v.panelMode instanceof CostEstimationLayout_Types.MaximizedMode) {
            return TacoConstants.M.value;
        };
        throw new Error("Failed pattern match at CostEstimationLayout.Styles (line 256, column 12 - line 260, column 25): " + [ v.panelMode.constructor.name ]);
    })()), new Box.Display(Box.Block.value), Box.Style.create(React_Basic_Emotion.css()({
        backgroundColor: React_Basic_Emotion.str("currentColor")
    })), new Box.BorderRadius(TacoConstants.XXS.value) ]);
};
var mainStyles = Box.boxStyle([ Box.Style.create(React_Basic_Emotion.css()({
    gridArea: React_Basic_Emotion.str("main")
})), new Box.Overflow(Box.Auto.value, Box.Hidden.value), new Box.Display(Box.Flex.value), new Box.Position(Box.Relative.value) ]);
var mainHeight = "minmax(106px, 2fr)";
var loadingDimmerContainerStyles = function (v) {
    return Box.boxStyle([ Box.Width.create(new TacoConstants.CustomSize("100%")), Box.Height.create(new TacoConstants.CustomSize("100%")) ]);
};
var loaderContainerStyles = Box.boxStyle([ new Box.Position(Box.Absolute.value), Box.PosLeft.create(new TacoConstants.CustomSize("0")), Box.PosRight.create(new TacoConstants.CustomSize("0")) ]);
var leftSidebarStyles = Box.boxStyle([ Box.Style.create(React_Basic_Emotion.css()({
    gridArea: React_Basic_Emotion.str("left-sidebar")
})), Box.FlexColumn.value, Box.Style.create(React_Basic_Emotion.css()({
    borderRight: StyleProperties.border(TacoConstants.BorderLight.value)
})), new Box.Selector("> *", [ new Box.Width(TacoConstants.XXL.value) ]), new Box.Overflow(Box.Hidden.value, Box.Auto.value), new Box.ZIndex(2) ]);
var leftPanelStyles = Box.boxStyle([ Box.Style.create(React_Basic_Emotion.css()({
    gridArea: React_Basic_Emotion.str("left-panel")
})), Box.FlexColumn.value, Box.Style.create(React_Basic_Emotion.css()({
    borderRight: StyleProperties.border(TacoConstants.BorderLight.value)
})), new Box.Position(Box.Relative.value), new Box.ZIndex(2) ]);
var getDetailsHeight = function (props) {
    if (!props.hasDetailsPanel) {
        return "auto";
    };
    if (props.hasDetailsPanel && props.panelProps.detailsPanelMode instanceof CostEstimationLayout_Types.MinimizedMode) {
        return "auto";
    };
    if (props.hasDetailsPanel && props.panelProps.detailsPanelMode instanceof CostEstimationLayout_Types.SmallMode) {
        return "1fr";
    };
    if (props.hasDetailsPanel && props.panelProps.detailsPanelMode instanceof CostEstimationLayout_Types.NormalMode) {
        return "4fr";
    };
    if (props.hasDetailsPanel && props.panelProps.detailsPanelMode instanceof CostEstimationLayout_Types.MaximizedMode) {
        return "12fr";
    };
    throw new Error("Failed pattern match at CostEstimationLayout.Styles (line 25, column 26 - line 30, column 32): " + [ props.hasDetailsPanel.constructor.name, props.panelProps.detailsPanelMode.constructor.name ]);
};
var normalStyles = function (props) {
    return Box.boxStyle([ Box.Style.create(React_Basic_Emotion.css()({
        "--left-panel-width": React_Basic_Emotion.str("1fr"),
        "--right-panel-width": React_Basic_Emotion.str((function () {
            var $10 = Data_Eq.notEq(CostEstimationLayout_Types.eqPanelMode)(props.panelProps.leftPanelMode)(CostEstimationLayout_Types.MinimizedMode.value);
            if ($10) {
                return "0px";
            };
            return "minmax(300px, 1fr)";
        })()),
        "--right-panel-width-maximized": React_Basic_Emotion.str((function () {
            var $11 = Data_Eq.notEq(CostEstimationLayout_Types.eqPanelMode)(props.panelProps.leftPanelMode)(CostEstimationLayout_Types.MinimizedMode.value);
            if ($11) {
                return "0px";
            };
            return "minmax(300px, 3fr)";
        })()),
        "--main-panel-width": React_Basic_Emotion.str((function () {
            var $12 = Data_Eq.eq(CostEstimationLayout_Types.eqPanelMode)(props.panelProps.rightPanelMode)(CostEstimationLayout_Types.MinimizedMode.value) && Data_Eq.eq(CostEstimationLayout_Types.eqPanelMode)(props.panelProps.leftPanelMode)(CostEstimationLayout_Types.MinimizedMode.value);
            if ($12) {
                return "1fr";
            };
            return "0px";
        })()),
        gridTemplate: React_Basic_Emotion.str("\x0a          \"left-sidebar left-panel controls right-panel right-sidebar\" auto\x0a          \"left-sidebar left-panel main     right-panel right-sidebar\" 2fr\x0a          \"left-sidebar left-panel details  right-panel right-sidebar\" 5fr\x0a          "),
        gridTemplateRows: (function () {
            var details = getDetailsHeight(props);
            return React_Basic_Emotion.str(Data_Foldable.intercalate(Data_Foldable.foldableArray)(Data_Monoid.monoidString)(" ")([ "auto", mainHeight, details ]));
        })(),
        gridTemplateColumns: (function () {
            var right = (function () {
                if (!props.hasRightPanel) {
                    return "auto";
                };
                if (props.hasRightPanel && props.panelProps.rightPanelMode instanceof CostEstimationLayout_Types.MinimizedMode) {
                    return "auto";
                };
                if (props.hasRightPanel && props.panelProps.rightPanelMode instanceof CostEstimationLayout_Types.SmallMode) {
                    return "var(--right-panel-width)";
                };
                if (props.hasRightPanel && props.panelProps.rightPanelMode instanceof CostEstimationLayout_Types.NormalMode) {
                    return "var(--right-panel-width)";
                };
                if (props.hasRightPanel && props.panelProps.rightPanelMode instanceof CostEstimationLayout_Types.MaximizedMode) {
                    return "var(--right-panel-width-maximized)";
                };
                throw new Error("Failed pattern match at CostEstimationLayout.Styles (line 93, column 21 - line 98, column 74): " + [ props.hasRightPanel.constructor.name, props.panelProps.rightPanelMode.constructor.name ]);
            })();
            var left = (function () {
                if (!props.hasLeftPanel) {
                    return "auto";
                };
                if (props.hasLeftPanel && props.panelProps.leftPanelMode instanceof CostEstimationLayout_Types.MinimizedMode) {
                    return "auto";
                };
                if (props.hasLeftPanel) {
                    return "var(--left-panel-width)";
                };
                throw new Error("Failed pattern match at CostEstimationLayout.Styles (line 86, column 20 - line 89, column 51): " + [ props.hasLeftPanel.constructor.name, props.panelProps.leftPanelMode.constructor.name ]);
            })();
            return React_Basic_Emotion.str(Data_Foldable.intercalate(Data_Foldable.foldableArray)(Data_Monoid.monoidString)(" ")([ "auto", left, "var(--main-panel-width)", right, "auto" ]));
        })()
    })), new Box.Media(Box.TabletM.value, [ Box.Style.create(React_Basic_Emotion.css()({
        "--left-panel-width": React_Basic_Emotion.str("minmax(380px, auto)"),
        "--right-panel-width": React_Basic_Emotion.str("minmax(380px, 1fr)"),
        "--right-panel-width-maximized": React_Basic_Emotion.str("minmax(380px, 3fr)"),
        "--main-panel-width": React_Basic_Emotion.str((function () {
            var $17 = Data_Eq.eq(CostEstimationLayout_Types.eqPanelMode)(props.panelProps.rightPanelMode)(CostEstimationLayout_Types.MinimizedMode.value);
            if ($17) {
                return "1fr";
            };
            return "0px";
        })())
    })) ]), new Box.Media(Box.DesktopM.value, [ Box.Style.create(React_Basic_Emotion.css()({
        "--main-panel-width": React_Basic_Emotion.str("1fr")
    })) ]) ]);
};
var panelStyles = function (props) {
    return React_Basic_Emotion.css()({
        gridTemplate: React_Basic_Emotion.str("\x0a      \"controls\" auto\x0a      \"main\"     2fr\x0a      \"details\"  5fr\x0a      "),
        gridTemplateRows: (function () {
            var details = getDetailsHeight(props);
            return React_Basic_Emotion.str(Data_Foldable.intercalate(Data_Foldable.foldableArray)(Data_Monoid.monoidString)(" ")([ "auto", mainHeight, details ]));
        })()
    });
};
var detailsStyles = (function () {
    var invisibleBorder = "0px solid " + Color.cssStringRGBA(TacoConstants.color(TacoConstants.GrayLight.value));
    return Box.boxStyle([ Box.Style.create(React_Basic_Emotion.css()({
        gridArea: React_Basic_Emotion.str("details")
    })), new Box.Display(Box.Flex.value), new Box.Selector("&, *", [ Box.MinHeight.create(new TacoConstants.CustomSize("0")) ]), new Box.Selector(".__ScrollContainer", [ Box.Height.create(new TacoConstants.CustomSize("100%")) ]), new Box.Overflow(Box.Auto.value, Box.Hidden.value), Box.Style.create(React_Basic_Emotion.css()({
        borderTop: StyleProperties.border(TacoConstants.BorderLight.value),
        borderLeft: React_Basic_Emotion.str(invisibleBorder),
        borderRight: React_Basic_Emotion.str(invisibleBorder),
        borderRadius: StyleProperties.size(new TacoConstants.CustomSize("10px")),
        boxShadow: React_Basic_Emotion.str("0px -5px 10px rgba(0, 0, 0, 0.06)")
    })), new Box.ZIndex(1) ]);
})();
var costEstimationLayoutContainerStyles = function (props) {
    return Box.boxStyle([ new Box.FlexGrow(1), Box.Width.create(new TacoConstants.CustomSize("100%")), new Box.Display(Box.Grid.value), new Box.BackgroundColor(TacoConstants.AppBackground.value), (function () {
        if (props.isPanel) {
            return Box.Style.create(panelStyles(props));
        };
        return Box.Style.create(normalStyles(props));
    })(), Box.MinHeight.create(new TacoConstants.CustomSize("0")) ]);
};
var controlsStyles = function (mRefPanelMode) {
    return Box.boxStyle([ Box.Style.create(React_Basic_Emotion.css()({
        gridArea: React_Basic_Emotion.str("controls"),
        borderBottom: StyleProperties.border(TacoConstants.BorderLight.value)
    })), new Box.Style((function () {
        if (mRefPanelMode instanceof Data_Maybe.Just) {
            var colors = (function () {
                if (mRefPanelMode.value0 instanceof CostEstimationLayout_Types.ReferenceElementsPanel) {
                    return TacoConstants.elementColors;
                };
                if (mRefPanelMode.value0 instanceof CostEstimationLayout_Types.ReferenceComponentPanel) {
                    return TacoConstants.componentColors;
                };
                if (mRefPanelMode.value0 instanceof CostEstimationLayout_Types.ReferenceResourcePanel) {
                    return TacoConstants.resourcePriceListColors;
                };
                throw new Error("Failed pattern match at CostEstimationLayout.Styles (line 199, column 20 - line 202, column 62): " + [ mRefPanelMode.value0.constructor.name ]);
            })();
            return React_Basic_Emotion.css()({
                background: StyleProperties.colorScheme(colors.background),
                border: StyleProperties.colorScheme(colors.border),
                foreground: StyleProperties.colorScheme(colors.foreground)
            });
        };
        if (mRefPanelMode instanceof Data_Maybe.Nothing) {
            return Data_Monoid.mempty(React_Basic_Emotion.monoidStyle);
        };
        throw new Error("Failed pattern match at CostEstimationLayout.Styles (line 196, column 11 - line 208, column 24): " + [ mRefPanelMode.constructor.name ]);
    })()), Box.FlexRow.value, new Box.Overflow(Box.Auto.value, Box.Hidden.value), new Box.Position(Box.Relative.value) ]);
};
module.exports = {
    mainHeight: mainHeight,
    getDetailsHeight: getDetailsHeight,
    panelStyles: panelStyles,
    normalStyles: normalStyles,
    costEstimationLayoutContainerStyles: costEstimationLayoutContainerStyles,
    leftPanelStyles: leftPanelStyles,
    leftSidebarStyles: leftSidebarStyles,
    rightPanelStyles: rightPanelStyles,
    rightSidebarStyles: rightSidebarStyles,
    controlsStyles: controlsStyles,
    mainStyles: mainStyles,
    detailsStyles: detailsStyles,
    panelModeButtonsStyles: panelModeButtonsStyles,
    panelModeButtonStyles: panelModeButtonStyles,
    loaderContainerStyles: loaderContainerStyles,
    loadingDimmerContainerStyles: loadingDimmerContainerStyles
};
