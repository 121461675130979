// Generated by purs version 0.14.5
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Kishimen = require("../Kishimen/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Normal = (function () {
    function Normal() {

    };
    Normal.value = new Normal();
    return Normal;
})();
var Hover = (function () {
    function Hover() {

    };
    Hover.value = new Hover();
    return Hover;
})();
var Editing = (function () {
    function Editing(value0) {
        this.value0 = value0;
    };
    Editing.create = function (value0) {
        return new Editing(value0);
    };
    return Editing;
})();
var PointMode = (function () {
    function PointMode() {

    };
    PointMode.value = new PointMode();
    return PointMode;
})();
var LineMode = (function () {
    function LineMode() {

    };
    LineMode.value = new LineMode();
    return LineMode;
})();
var PathMode = (function () {
    function PathMode() {

    };
    PathMode.value = new PathMode();
    return PathMode;
})();
var AreaMode = (function () {
    function AreaMode() {

    };
    AreaMode.value = new AreaMode();
    return AreaMode;
})();
var RectMode = (function () {
    function RectMode() {

    };
    RectMode.value = new RectMode();
    return RectMode;
})();
var SelectMode = (function () {
    function SelectMode() {

    };
    SelectMode.value = new SelectMode();
    return SelectMode;
})();
var BoxSelectMode = (function () {
    function BoxSelectMode() {

    };
    BoxSelectMode.value = new BoxSelectMode();
    return BoxSelectMode;
})();
var SetScaleMode = (function () {
    function SetScaleMode() {

    };
    SetScaleMode.value = new SetScaleMode();
    return SetScaleMode;
})();
var Points = (function () {
    function Points(value0) {
        this.value0 = value0;
    };
    Points.create = function (value0) {
        return new Points(value0);
    };
    return Points;
})();
var Polyline = (function () {
    function Polyline(value0) {
        this.value0 = value0;
    };
    Polyline.create = function (value0) {
        return new Polyline(value0);
    };
    return Polyline;
})();
var Area = (function () {
    function Area(value0) {
        this.value0 = value0;
    };
    Area.create = function (value0) {
        return new Area(value0);
    };
    return Area;
})();
var Arc = (function () {
    function Arc(value0) {
        this.value0 = value0;
    };
    Arc.create = function (value0) {
        return new Arc(value0);
    };
    return Arc;
})();
var genericShape = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new Points(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new Polyline(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return new Area(x.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr)) {
            return new Arc(x.value0.value0.value0);
        };
        throw new Error("Failed pattern match at DixonMeasurements.Types (line 30, column 1 - line 30, column 48): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof Points) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof Polyline) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof Area) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
        };
        if (x instanceof Arc) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0)));
        };
        throw new Error("Failed pattern match at DixonMeasurements.Types (line 30, column 1 - line 30, column 48): " + [ x.constructor.name ]);
    }
};
var tofuJSONShape = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "Arc";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "center";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "end_angle";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "radius";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "start_angle";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "center";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "end_angle";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "radius";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "start_angle";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "Area";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vertices";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vertices";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())))(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "Points";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vertices";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vertices";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())))(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "Polyline";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vertices";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vertices";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())))(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantNil)))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "Arc";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "center";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "end_angle";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "radius";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "start_angle";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "center";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "end_angle";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "radius";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "start_angle";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "Area";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vertices";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vertices";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())))(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "Points";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vertices";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vertices";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())))(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "Polyline";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vertices";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vertices";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())))(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.nilWriteForeignVariant)))))(genericShape)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "Points";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "Polyline";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "Area";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "Arc";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "Area";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "Arc";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "Polyline";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "Area";
        }
    }))({
        reflectSymbol: function () {
            return "Arc";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "Points";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "Polyline";
        }
    }))({
        reflectSymbol: function () {
            return "Area";
        }
    }))({
        reflectSymbol: function () {
            return "Arc";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericShape)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "Points";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "Polyline";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "Area";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "Arc";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "Area";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "Arc";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "Polyline";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "Area";
        }
    }))({
        reflectSymbol: function () {
            return "Arc";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "Points";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "Polyline";
        }
    }))({
        reflectSymbol: function () {
            return "Area";
        }
    }))({
        reflectSymbol: function () {
            return "Arc";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "Arc";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "center";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "end_angle";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "radius";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "start_angle";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "center";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "end_angle";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "radius";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "start_angle";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "Area";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vertices";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vertices";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())))(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "Points";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vertices";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vertices";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())))(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "Polyline";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vertices";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vertices";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())))(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantNil)))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "Arc";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "center";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "end_angle";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "radius";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "start_angle";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "center";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "end_angle";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "radius";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "start_angle";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "Area";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vertices";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vertices";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())))(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "Points";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vertices";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vertices";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())))(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "Polyline";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "vertices";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "vertices";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "x";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "y";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())))(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.nilWriteForeignVariant)))))
};
var genericMeasurementState = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return Normal.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return Hover.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
            return new Editing(x.value0.value0);
        };
        throw new Error("Failed pattern match at DixonMeasurements.Types (line 44, column 1 - line 44, column 70): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof Normal) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof Hover) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
        };
        if (x instanceof Editing) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0));
        };
        throw new Error("Failed pattern match at DixonMeasurements.Types (line 44, column 1 - line 44, column 70): " + [ x.constructor.name ]);
    }
};
var tofuJSONMeasurementState = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "Editing";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "Hover";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "Normal";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "Editing";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "Hover";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "Normal";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant))))(genericMeasurementState)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "Normal";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "Hover";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "Editing";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "Hover";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "Editing";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "Normal";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "Hover";
        }
    }))({
        reflectSymbol: function () {
            return "Editing";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericMeasurementState)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "Normal";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "Hover";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "Editing";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "Hover";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "Editing";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "Normal";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "Hover";
        }
    }))({
        reflectSymbol: function () {
            return "Editing";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "Editing";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "Hover";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "Normal";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "Editing";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "index";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "Hover";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "Normal";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant))))
};
var genericDixonEditorMode = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return PointMode.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return LineMode.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return PathMode.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return AreaMode.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
            return RectMode.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))) {
            return SelectMode.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))) {
            return BoxSelectMode.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr)))))) {
            return SetScaleMode.value;
        };
        throw new Error("Failed pattern match at DixonMeasurements.Types (line 78, column 1 - line 78, column 68): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof PointMode) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof LineMode) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
        };
        if (x instanceof PathMode) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
        };
        if (x instanceof AreaMode) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))));
        };
        if (x instanceof RectMode) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))));
        };
        if (x instanceof SelectMode) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))))));
        };
        if (x instanceof BoxSelectMode) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))))));
        };
        if (x instanceof SetScaleMode) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)))))));
        };
        throw new Error("Failed pattern match at DixonMeasurements.Types (line 78, column 1 - line 78, column 68): " + [ x.constructor.name ]);
    }
};
var tofuJSONDixonEditorMode = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AreaMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "BoxSelectMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "LineMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "PathMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "PointMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "RectMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "SelectMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "SetScaleMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))))))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AreaMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "BoxSelectMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "LineMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "PathMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "PointMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "RectMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "SelectMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "SetScaleMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))))))))(genericDixonEditorMode)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "PointMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "LineMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "PathMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AreaMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "RectMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "SelectMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "BoxSelectMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "SetScaleMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "BoxSelectMode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetScaleMode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SelectMode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetScaleMode";
        }
    }))({
        reflectSymbol: function () {
            return "BoxSelectMode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "RectMode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetScaleMode";
        }
    }))({
        reflectSymbol: function () {
            return "SelectMode";
        }
    }))({
        reflectSymbol: function () {
            return "BoxSelectMode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AreaMode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetScaleMode";
        }
    }))({
        reflectSymbol: function () {
            return "SelectMode";
        }
    }))({
        reflectSymbol: function () {
            return "RectMode";
        }
    }))({
        reflectSymbol: function () {
            return "BoxSelectMode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "PathMode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetScaleMode";
        }
    }))({
        reflectSymbol: function () {
            return "SelectMode";
        }
    }))({
        reflectSymbol: function () {
            return "RectMode";
        }
    }))({
        reflectSymbol: function () {
            return "BoxSelectMode";
        }
    }))({
        reflectSymbol: function () {
            return "AreaMode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "LineMode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetScaleMode";
        }
    }))({
        reflectSymbol: function () {
            return "SelectMode";
        }
    }))({
        reflectSymbol: function () {
            return "RectMode";
        }
    }))({
        reflectSymbol: function () {
            return "PathMode";
        }
    }))({
        reflectSymbol: function () {
            return "BoxSelectMode";
        }
    }))({
        reflectSymbol: function () {
            return "AreaMode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "PointMode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetScaleMode";
        }
    }))({
        reflectSymbol: function () {
            return "SelectMode";
        }
    }))({
        reflectSymbol: function () {
            return "RectMode";
        }
    }))({
        reflectSymbol: function () {
            return "PathMode";
        }
    }))({
        reflectSymbol: function () {
            return "LineMode";
        }
    }))({
        reflectSymbol: function () {
            return "BoxSelectMode";
        }
    }))({
        reflectSymbol: function () {
            return "AreaMode";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericDixonEditorMode)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "PointMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "LineMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "PathMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AreaMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "RectMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "SelectMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "BoxSelectMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "SetScaleMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "BoxSelectMode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetScaleMode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SelectMode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetScaleMode";
        }
    }))({
        reflectSymbol: function () {
            return "BoxSelectMode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "RectMode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetScaleMode";
        }
    }))({
        reflectSymbol: function () {
            return "SelectMode";
        }
    }))({
        reflectSymbol: function () {
            return "BoxSelectMode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AreaMode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetScaleMode";
        }
    }))({
        reflectSymbol: function () {
            return "SelectMode";
        }
    }))({
        reflectSymbol: function () {
            return "RectMode";
        }
    }))({
        reflectSymbol: function () {
            return "BoxSelectMode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "PathMode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetScaleMode";
        }
    }))({
        reflectSymbol: function () {
            return "SelectMode";
        }
    }))({
        reflectSymbol: function () {
            return "RectMode";
        }
    }))({
        reflectSymbol: function () {
            return "BoxSelectMode";
        }
    }))({
        reflectSymbol: function () {
            return "AreaMode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "LineMode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetScaleMode";
        }
    }))({
        reflectSymbol: function () {
            return "SelectMode";
        }
    }))({
        reflectSymbol: function () {
            return "RectMode";
        }
    }))({
        reflectSymbol: function () {
            return "PathMode";
        }
    }))({
        reflectSymbol: function () {
            return "BoxSelectMode";
        }
    }))({
        reflectSymbol: function () {
            return "AreaMode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "PointMode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SetScaleMode";
        }
    }))({
        reflectSymbol: function () {
            return "SelectMode";
        }
    }))({
        reflectSymbol: function () {
            return "RectMode";
        }
    }))({
        reflectSymbol: function () {
            return "PathMode";
        }
    }))({
        reflectSymbol: function () {
            return "LineMode";
        }
    }))({
        reflectSymbol: function () {
            return "BoxSelectMode";
        }
    }))({
        reflectSymbol: function () {
            return "AreaMode";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AreaMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "BoxSelectMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "LineMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "PathMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "PointMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "RectMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "SelectMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "SetScaleMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))))))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AreaMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "BoxSelectMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "LineMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "PathMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "PointMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "RectMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "SelectMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "SetScaleMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))))))))
};
var eqShape = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Points && y instanceof Points) {
                return Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "y";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "x";
                    }
                })(Data_Eq.eqNumber))))(x.value0.vertices)(y.value0.vertices);
            };
            if (x instanceof Polyline && y instanceof Polyline) {
                return Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "y";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "x";
                    }
                })(Data_Eq.eqNumber))))(x.value0.vertices)(y.value0.vertices);
            };
            if (x instanceof Area && y instanceof Area) {
                return Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "y";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "x";
                    }
                })(Data_Eq.eqNumber))))(x.value0.vertices)(y.value0.vertices);
            };
            if (x instanceof Arc && y instanceof Arc) {
                return x.value0.center.x === y.value0.center.x && x.value0.center.y === y.value0.center.y && x.value0.end_angle === y.value0.end_angle && x.value0.radius === y.value0.radius && x.value0.start_angle === y.value0.start_angle;
            };
            return false;
        };
    }
};
var eqMeasurementState = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Normal && y instanceof Normal) {
                return true;
            };
            if (x instanceof Hover && y instanceof Hover) {
                return true;
            };
            if (x instanceof Editing && y instanceof Editing) {
                return x.value0.index === y.value0.index;
            };
            return false;
        };
    }
};
var eqDixonEditorMode = {
    eq: function (x) {
        return function (y) {
            if (x instanceof PointMode && y instanceof PointMode) {
                return true;
            };
            if (x instanceof LineMode && y instanceof LineMode) {
                return true;
            };
            if (x instanceof PathMode && y instanceof PathMode) {
                return true;
            };
            if (x instanceof AreaMode && y instanceof AreaMode) {
                return true;
            };
            if (x instanceof RectMode && y instanceof RectMode) {
                return true;
            };
            if (x instanceof SelectMode && y instanceof SelectMode) {
                return true;
            };
            if (x instanceof BoxSelectMode && y instanceof BoxSelectMode) {
                return true;
            };
            if (x instanceof SetScaleMode && y instanceof SetScaleMode) {
                return true;
            };
            return false;
        };
    }
};
module.exports = {
    Points: Points,
    Polyline: Polyline,
    Area: Area,
    Arc: Arc,
    Normal: Normal,
    Hover: Hover,
    Editing: Editing,
    PointMode: PointMode,
    LineMode: LineMode,
    PathMode: PathMode,
    AreaMode: AreaMode,
    RectMode: RectMode,
    SelectMode: SelectMode,
    BoxSelectMode: BoxSelectMode,
    SetScaleMode: SetScaleMode,
    eqShape: eqShape,
    genericShape: genericShape,
    tofuJSONShape: tofuJSONShape,
    eqMeasurementState: eqMeasurementState,
    genericMeasurementState: genericMeasurementState,
    tofuJSONMeasurementState: tofuJSONMeasurementState,
    eqDixonEditorMode: eqDixonEditorMode,
    genericDixonEditorMode: genericDixonEditorMode,
    tofuJSONDixonEditorMode: tofuJSONDixonEditorMode
};
