// Generated by purs version 0.14.5
"use strict";
var Data_Nullable = require("../Data.Nullable/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_ReasonableCoerce = require("../Types.ReasonableCoerce/index.js");
var useCallbackRef = function (key) {
    return function (callback) {
        return function __do() {
            var ref = TofuHooks.useCallback(key)(callback)();
            var callbackRef = function (nullable) {
                return ref(Data_Nullable.toMaybe(nullable))();
            };
            var memoized = TofuHooks.useMemo(key)(function (v) {
                return callbackRef;
            })();
            return memoized;
        };
    };
};
var toNodeRef = Types_ReasonableCoerce.reasonableCoerce("react supports callback refs but they are missing in react-basic types");
module.exports = {
    useCallbackRef: useCallbackRef,
    toNodeRef: toNodeRef
};
