import { useTranslation } from "react-i18next";
import { useWorkerState } from "../../../hooks/useWorkerState";
import { Controller, useForm } from "react-hook-form";
import { Combobox, Loader, SelectItem } from "@tocoman/ui";
import { useCallback, useState } from "react";
import { useModalActionButtons } from "./hooks/useModalActionButtons";
import { useRunOnce } from "../../../hooks/useRunOnce";
import { useChangeSubproject } from "./hooks/useChangeSubproject";
import { ErrorMessage } from "src/client-ts/components/ErrorMessage";
import _ from "lodash";

type ChangeSubprojectProps = {
  componentIds: number[];
  projectId: number;
  onSave: () => void;
  closeModal: () => void;
  setActionButtons: (actions: JSX.Element) => void;
};
type ChangeSubprojectForm = {
  newSubproject: number;
};

type ValidSubproject = {
  valid: boolean;
  invalidComponentCodes?: string[];
  error?: string;
};

export function ChangeSubproject({
  projectId,
  componentIds,
  onSave,
  closeModal,
  setActionButtons,
}: ChangeSubprojectProps) {
  const { t } = useTranslation("estimation", {
    keyPrefix: "componentView.editMultipleComponentsModal",
  });

  const [errorMessage, setErrorMessage] = useState<string>("");
  const methods = useForm<ChangeSubprojectForm>();
  const { control, handleSubmit } = methods;

  const subprojectUpdate = useChangeSubproject(projectId, onSave);

  // Needed to determine if component has been linked to an element
  const elementSpecs = useWorkerState("EstimationElementSpecsState", projectId);
  const subprojects = useWorkerState("EstimationSubprojectsState", projectId);
  const projectComponents = useWorkerState(
    "EstimationComponentsState",
    projectId
  );

  const componentsLinkedToElements = useCallback(() => {
    return componentIds.some((componentId) =>
      elementSpecs?.map((spec) => spec.componentId).includes(componentId)
    );
  }, [elementSpecs, componentIds]);

  const selectedComponents = projectComponents?.filter((c) =>
    componentIds.includes(c.id)
  );

  const componentsWithDuplicateCodes =
    _.uniq(selectedComponents?.map((c) => c.code) ?? []).length !==
    selectedComponents?.length;

  const validateSelection = (
    selectedSubproject: SelectItem<number> | null
  ): ValidSubproject => {
    if (selectedSubproject === null) {
      return { valid: false, invalidComponentCodes: [] };
    }
    const identicalCodeComponents = selectedComponents?.filter((c) => {
      return projectComponents?.some(
        (p) =>
          p.code === c.code &&
          p.subProjectId === selectedSubproject.key &&
          c.subProjectId !== selectedSubproject.key
      );
    });
    if (identicalCodeComponents && identicalCodeComponents.length > 0) {
      return {
        valid: false,
        invalidComponentCodes: identicalCodeComponents.map((c) => c.code ?? ""),
        error: t`form.identicalCodeError`,
      };
    }
    return { valid: true };
  };

  const handleSave = (data: ChangeSubprojectForm) => {
    if (data.newSubproject === null) {
      return;
    }
    const componentIds =
      selectedComponents
        ?.filter((c) => c.subProjectId !== data.newSubproject)
        .map((c) => c.id) ?? [];
    subprojectUpdate.mutate({
      components: componentIds,
      subprojectId: data.newSubproject,
    });
  };

  const setActions = useModalActionButtons(
    setActionButtons,
    handleSubmit(handleSave),
    closeModal
  );

  useRunOnce(() => {
    setActions(true);
  });

  if (!subprojects) {
    return <Loader />;
  }

  const subprojectItems = subprojects.map((s) => ({
    label: `${s.code} - ${s.name}`,
    key: s.id,
    value: s.id,
  }));

  return (
    <div>
      {componentsLinkedToElements() ? (
        <p>{t`form.linkedElementsWarn`}</p>
      ) : (
        <Controller
          control={control}
          name={"newSubproject"}
          render={({ field: { onChange, value } }) => (
            <Combobox
              label={t`form.subproject`}
              items={subprojectItems}
              onValueChange={(value) => {
                const validation = validateSelection(value);
                if (validation.error && validation.invalidComponentCodes) {
                  setErrorMessage(
                    `${
                      validation.error
                    } ${validation.invalidComponentCodes.join(", ")}`
                  );
                } else {
                  setErrorMessage("");
                }
                setActions(!validation.valid || componentsWithDuplicateCodes);
                onChange(value?.key);
              }}
              selected={subprojectItems.find((s) => s.key === value) ?? null}
            />
          )}
        />
      )}
      {componentsWithDuplicateCodes && (
        <ErrorMessage errorMessage={t`form.duplicateCodeError`} />
      )}
      <div className={"mt-2"}>
        {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
      </div>
    </div>
  );
}
