// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var StyleProperties = require("../StyleProperties/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoDropdown_Types = require("../TacoDropdown.Types/index.js");
var tacoDropdownSeparatorStyles = Box.boxStyle([  ]);
var tacoDropdownSectionsStyles = function (props) {
    return Box.boxStyle([ new Box.MarginTop(TacoConstants.S.value), new Box.Position(Box.Absolute.value), (function () {
        if (props.align instanceof TacoDropdown_Types.AlignLeft) {
            return Box.Style.create(React_Basic_Emotion.css()({
                top: StyleProperties.px(Data_Int.floor(props.anchorDimensions.top + props.anchorDimensions.height)),
                left: StyleProperties.px(Data_Int.floor((props.anchorDimensions.left + props.anchorDimensions.width / 2.0) - 12.0))
            }));
        };
        if (props.align instanceof TacoDropdown_Types.AlignRight) {
            return Box.Style.create(React_Basic_Emotion.css()({
                top: StyleProperties.px(Data_Int.floor(props.anchorDimensions.top + props.anchorDimensions.height)),
                left: StyleProperties.px(Data_Int.floor((props.anchorDimensions.left - props.dropdownDimensions.width) + props.anchorDimensions.width / 2.0 + 12.0))
            }));
        };
        throw new Error("Failed pattern match at TacoDropdown.Styles (line 24, column 5 - line 41, column 10): " + [ props.align.constructor.name ]);
    })(), new Box.Padding(TacoConstants.S.value), new Box.ZIndex(TacoConstants.zIndexDropdown), new Box.BackgroundColor(TacoConstants.White.value), new Box.Border(TacoConstants.BorderLight.value), new Box.BorderRadius(TacoConstants.XXS.value), Box.MinWidth.create(new TacoConstants.CustomSize("20rem")), Box.MaxWidth.create(new TacoConstants.CustomSize("80vw")), new Box.BoxShadow(TacoConstants.S.value), new Box.Visibility(props.toggled) ]);
};
var tacoDropdownSectionItemStyles = function (v) {
    return Box.boxStyle([ Data_Monoid.guard(Data_Monoid.monoidFn(Box.monoidBoxProp))(!v.lastItem)(Box.Style.create)(React_Basic_Emotion.css()({
        borderBottom: StyleProperties.border(TacoConstants.BorderLight.value),
        marginBottom: StyleProperties.size(TacoConstants.S.value),
        paddingBottom: StyleProperties.size(TacoConstants.S.value)
    })), new Box.Selector(".__TacoButton, .__TacoCheckbox", [ Box.Width.create(new TacoConstants.CustomSize("100%")) ]) ]);
};
var tacoDropdownSectionHeadingStyles = Box.boxStyle([ new Box.MarginBottom(TacoConstants.XS.value) ]);
var tacoDropdownContainerStyles = Box.boxStyle([ new Box.Display(Box.InlineBlock.value), new Box.Position(Box.Relative.value) ]);
var tacoDropdownCalloutTriangleStyles = function (props) {
    return Box.boxStyle([ new Box.Position(Box.Absolute.value), Box.Style.create(React_Basic_Emotion.css()({
        borderWidth: React_Basic_Emotion.str("0px 8px 8px 8px"),
        borderStyle: React_Basic_Emotion.str("solid"),
        borderColor: React_Basic_Emotion.str("transparent transparent " + (TacoConstants.colorSchemeToStr(TacoConstants.Gray.value) + " transparent"))
    })), Box.PosTop.create(TacoConstants.CustomSize.create("-8px")), (function () {
        if (props.align instanceof TacoDropdown_Types.AlignLeft) {
            return new Box.PosLeft(TacoConstants.XXS.value);
        };
        if (props.align instanceof TacoDropdown_Types.AlignRight) {
            return new Box.PosRight(TacoConstants.XXS.value);
        };
        throw new Error("Failed pattern match at TacoDropdown.Styles (line 77, column 5 - line 79, column 33): " + [ props.align.constructor.name ]);
    })(), new Box.Selector("&:after", [ new Box.Position(Box.Absolute.value), Box.Style.create(React_Basic_Emotion.css()({
        borderWidth: React_Basic_Emotion.str("0px 7px 7px 7px"),
        borderStyle: React_Basic_Emotion.str("solid"),
        borderColor: React_Basic_Emotion.str("transparent transparent " + (TacoConstants.colorSchemeToStr(TacoConstants.White.value) + " transparent"))
    })), Box.PosTop.create(TacoConstants.CustomSize.create("1px")), Box.PosLeft.create(new TacoConstants.CustomSize("-7px")), Box.Style.create(React_Basic_Emotion.css()({
        content: React_Basic_Emotion.str("' '")
    })) ]) ]);
};
module.exports = {
    tacoDropdownContainerStyles: tacoDropdownContainerStyles,
    tacoDropdownSectionsStyles: tacoDropdownSectionsStyles,
    tacoDropdownSectionItemStyles: tacoDropdownSectionItemStyles,
    tacoDropdownCalloutTriangleStyles: tacoDropdownCalloutTriangleStyles,
    tacoDropdownSectionHeadingStyles: tacoDropdownSectionHeadingStyles,
    tacoDropdownSeparatorStyles: tacoDropdownSeparatorStyles
};
