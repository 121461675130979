// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoCheckbox_Styles = require("../TacoCheckbox.Styles/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_Estimation = require("../Types.Estimation/index.js");
var UseFetch = require("../UseFetch/index.js");
var plTableInstance = TacoTable.mkTableStateless(TofuHooks.getNameVoid)("CostEstimation.ResourcesView.UpdateResources.plTable");
var mkRow = function (selected) {
    return function (priceList) {
        return {
            selected: Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "orgPriceListId";
                }
            })(Types_Component.eqOrgEstimationResourceId))()({
                reflectSymbol: function () {
                    return "name";
                }
            })(Data_Eq.eqString))))(selected)(new Data_Maybe.Just(priceList)),
            priceList: priceList
        };
    };
};
var mkMatchingCheckbox = function (selectedMatching) {
    return function (setSelectedMatching) {
        return function (match) {
            return function (title) {
                return TacoCheckbox.component()()({
                    isChecked: Data_Eq.eq(Types_Estimation.eqMatchingRules)(selectedMatching)(match),
                    title: title,
                    labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel(title)),
                    style: TacoCheckbox_Styles.RadioButton.value,
                    onToggle: TacoCheckbox.NoEvent.create(setSelectedMatching(function (v) {
                        return match;
                    }))
                });
            };
        };
    };
};
var matchingCriteriaSelection = function (v) {
    var triplet = mkMatchingCheckbox(v.selectedMatching)(v.setSelectedMatching)(Types_Estimation.CostClassGroupSortingNumberMatch.value)("PL + PRyhm\xe4 + J\xe4rjestysnumero -yhdistelm\xe4");
    var internalId = mkMatchingCheckbox(v.selectedMatching)(v.setSelectedMatching)(Types_Estimation.InternalOrgResourcePriceIdMatch.value)("Sis\xe4inen tunnus");
    var description = [ TacoText.component()()({
        text: "Mill\xe4 perusteella panokset yhdistet\xe4\xe4n uusiin hintoihin?",
        variant: new TacoText_Types.Heading(2)
    }), TacoText.component()()({
        text: "M\xe4\xe4rit\xe4 yhdist\xe4mistapa",
        variant: TacoText_Types.Paragraph.value
    }) ];
    return React_Basic_DOM_Generated.div_(Data_Semigroup.append(Data_Semigroup.semigroupArray)(description)([ internalId, triplet ]));
};
var checkboxCell = function (updateSelectedPL) {
    return function (row) {
        return TacoCheckbox.component()()({
            isChecked: row.selected,
            style: TacoCheckbox_Styles.RadioButton.value,
            onToggle: TacoCheckbox.NoEvent.create(updateSelectedPL(function (v) {
                return new Data_Maybe.Just(row.priceList);
            }))
        });
    };
};
var columns = function (updateSelectedPL) {
    var mkColumn = function (v) {
        return {
            key: v.key,
            label: v.label,
            width: v.width,
            cell: v.cell,
            sortProperty: TacoTable_Types.noSortProperty,
            headerJSX: Data_Maybe.Nothing.value
        };
    };
    return Data_Functor.map(Data_Functor.functorArray)(mkColumn)([ {
        key: "selected",
        label: React_Basic_DOM.text(""),
        width: new TacoTable_Types.Flex(3),
        cell: new TacoTable_Types.JSXCell([  ], checkboxCell(updateSelectedPL))
    }, {
        key: "id",
        label: React_Basic_DOM.text("tunnus"),
        width: new TacoTable_Types.Flex(5),
        cell: TacoTable_Types.PlainTextCell.create((function () {
            var $34 = Data_Newtype.unwrap();
            return function ($35) {
                return $34((function (v) {
                    return v.priceList.orgPriceListId;
                })($35));
            };
        })())
    }, {
        key: "name",
        label: React_Basic_DOM.text("nimi"),
        width: new TacoTable_Types.Flex(5),
        cell: new TacoTable_Types.PlainTextCell(function (v) {
            return v.priceList.name;
        })
    } ]);
};
var getRow = function (updateSelectedPL) {
    return function (r) {
        return {
            className: "pricelist-table-row",
            rowData: r,
            onClick: function (v) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            },
            foldingElement: Data_Maybe.Nothing.value,
            rowKey: Data_Newtype.unwrap()(r.priceList.orgPriceListId),
            columns: columns(updateSelectedPL)
        };
    };
};
var plTable = function (updateSelectedPL) {
    return function (priceLists) {
        return React_Basic_DOM_Generated.div_([ TacoText.component()()({
            text: "Mist\xe4 panostiedot p\xe4ivitet\xe4\xe4n?",
            variant: new TacoText_Types.Heading(2)
        }), TacoText.component()()({
            text: "M\xe4\xe4rit\xe4 pist\xe4 hinnastoista panostiedot p\xe4ivitet\xe4\xe4n",
            variant: TacoText_Types.Paragraph.value
        }), TacoTable.tableStateless(TofuHooks.getNameVoid)(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "selected";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "priceList";
            }
        })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "orgPriceListId";
            }
        })(Types_Component.eqOrgEstimationResourceId))()({
            reflectSymbol: function () {
                return "name";
            }
        })(Data_Eq.eqString)))))(plTableInstance)({
            sortProperty: Data_Maybe.Nothing.value,
            onSort: Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit))),
            sortingOrder: TacoTable_Types.Ascending.value,
            rows: Data_Functor.map(Data_Functor.functorArray)(getRow(updateSelectedPL))(priceLists),
            columns: columns(updateSelectedPL),
            showHeader: true,
            styleSet: TacoTable_Styles.foldingTableStyleSet,
            onResize: function (v) {
                return function (v1) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                };
            },
            columnSizeOffsets: Data_Maybe.Nothing.value
        }) ]);
    };
};
var component = TofuHooks.mkHookComponent("CostEstimation.ResourcesView.SelectResourceUpdateCriteriaModal")(function (v) {
    return function __do() {
        var v1 = UseFetch.useFetchItems(Data_Eq.eqUnit)(Data_Unit.unit)(function (v1) {
            return v1.orgPriceLists;
        })(Actions.GetOrgPriceLists.value)();
        var v2 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var v3 = TofuHooks.useState(Types_Estimation.InternalOrgResourcePriceIdMatch.value)();
        var findResourcesButton = TacoButton.component()()({
            onClick: Data_Functor.map(Data_Maybe.functorMaybe)(v.openMatchResourcesModal(v3.value0))(v2.value0),
            buttonStyle: TacoButton_Types.Filled.value,
            text: "Etsi vastaavat panokset hinnastosta",
            disabled: Data_Maybe.isNothing(v2.value0)
        });
        var cancelButton = TacoButton.component()()({
            onClick: new Data_Maybe.Just(v.closeModal),
            text: "Peruuta"
        });
        return TacoModal.component()()({
            onClose: v.closeModal,
            isActive: true,
            heading: "Etsi valitut hinnat",
            contents: [ TacoModal.modalBody({
                contents: [ matchingCriteriaSelection({
                    selectedMatching: v3.value0,
                    setSelectedMatching: v3.value1
                }), plTable(v2.value1)(Data_Functor.map(Data_Functor.functorArray)(mkRow(v2.value0))(v1.value0)) ]
            }), TacoModal.modalActions({
                contents: [ cancelButton, findResourcesButton ]
            }) ]
        });
    };
});
module.exports = {
    component: component
};
