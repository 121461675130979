import { useDefault } from "../../../hooks/useDefault";
import { useWorkerState } from "../../../hooks/useWorkerState";
import { Table } from "../../../components/Table";
import { useMemo, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  ColDef,
  ColGroupDef,
  ICellRendererParams,
  IsGroupOpenByDefaultParams,
  IStatusPanelParams,
  SideBarDef,
  StatusPanelDef,
} from "ag-grid-community";
import { useTranslation } from "react-i18next";
import { CostControlComponents } from "../../../../../ts-bindings/CostControlComponents";
import { CostClass } from "../../../../../ts-bindings/CostClass";
import { costClassColumnDefinitions } from "./costClassColumnDefinitions";
import { MainCostGroup } from "../../../../../ts-bindings/MainCostGroup";
import { mapCostControlPrintingRowData } from "./mapCostControlPrintingRowData";
import {
  cellStyles,
  differenceStyle,
  getComponentsTotalValue,
  getCostGroup,
  getMainCostGroup,
  getTotalValueFromGroups,
  groupCellRendererSelector,
  reduceEstimateFromTarget,
  sumAllCostClasses,
  sumValues,
  valueOnlyInGroups,
} from "./printingFunctions";
import { ExportToExcelStatusPanelButton } from "../../../utils/Printing/ExportToExcelStatusPanelButton";
import { usePrintingExcelExportSettings } from "./excel/usePrintingExcelExportSettings";
import { useFirstDataRendered } from "../../../components/Table/useFirstDataRendered";
import { usePinnedBottomRowUpdater } from "../../../components/Table/usePinnedBottomRowUpdater";

type PrintingProps = {
  projectId: number;
};

export type CostControlPrintingCostGroup = {
  name?: string;
  actual?: null | number;
  code?: string;
  estimate?: null | number;
  socialExpenses?: number;
  value?: number;
  projectId?: number;
  target?: null | number;
};

export type CostControlPrintingRow = {
  mainCostGroup?: MainCostGroup;
  costClasses?: (CostClass & { value: number; hours: number })[];
  costGroup?: CostControlPrintingCostGroup;
  component?: CostControlComponents | null;
};

export const Printing = ({ projectId }: PrintingProps) => {
  const { t } = useTranslation("costControl", { keyPrefix: "printing" });

  const gridRef = useRef<AgGridReact<CostControlPrintingRow>>(null);
  const costGroups = useDefault(
    useWorkerState("CostControlCostGroupState", projectId),
    []
  );

  const costClasses = useDefault(
    useWorkerState("CostControlCostClassesState", projectId),
    []
  );

  const components = useDefault(
    useWorkerState("CostControlComponentsState", projectId),
    []
  );

  const resources = useDefault(
    useWorkerState("CostControlResourcesState", projectId),
    []
  );

  const targets = useDefault(
    useWorkerState("CostControlTargetItemsState", projectId),
    []
  );

  const estimates = useDefault(
    useWorkerState("CostControlEstimateItemsState", projectId),
    []
  );

  const invoices = useDefault(
    useWorkerState("CostControlInvoicesState", projectId),
    []
  );

  const mainCostGroups = useDefault(
    useWorkerState("MainCostGroupsState", projectId),
    {} as { [code: string]: MainCostGroup }
  );

  const project = useWorkerState("ProjectDetailsDataState", projectId);

  const mainCostGroupsArray = Object.values(mainCostGroups);
  const values = { targets: targets, estimates: estimates, invoices: invoices };
  const groups = {
    costGroups: costGroups,
    mainCostGroups: mainCostGroupsArray,
  };
  const rowData: CostControlPrintingRow[] = mapCostControlPrintingRowData(
    costClasses,
    components,
    resources,
    values,
    groups,
    t
  );

  const columnDefs: (
    | ColDef<CostControlPrintingRow>
    | ColGroupDef<CostControlPrintingRow>
  )[] = useMemo(() => {
    const columns: (
      | ColDef<CostControlPrintingRow>
      | ColGroupDef<CostControlPrintingRow>
    )[] = [
      {
        field: "mainCostGroup",
        headerName: t`tableColumns.mainCostGroup`,
        minWidth: 125,
        valueGetter: getMainCostGroup,
        filter: "agSetColumnFilter",
        rowGroup: true,
        initialHide: true,
        aggFunc: null,
        sort: "asc",
        enableRowGroup: true,
      },
      {
        field: "costGroup",
        headerName: t`tableColumns.costGroup`,
        minWidth: 125,
        valueGetter: getCostGroup,
        filter: "agSetColumnFilter",
        rowGroup: true,
        initialHide: true,
        aggFunc: null,
        sort: "asc",
        enableRowGroup: true,
      },
      {
        field: "component.description",
        headerName: t`tableColumns.component.description`,
        aggFunc: null,
      },
      {
        colId: "costGroup.target",
        headerName: t`tableColumns.target`,
        type: ["money"],
        aggFunc: (p) => sumValues(p, "costGroup.target"),
        cellRenderer: valueOnlyInGroups,
        valueGetter: (p) => getTotalValueFromGroups(p, "costGroup.target"),
      },
      {
        colId: "costGroup.estimate",
        headerName: t`tableColumns.estimate`,
        type: ["money"],
        aggFunc: (p) => sumValues(p, "costGroup.estimate"),
        cellRenderer: valueOnlyInGroups,
        valueGetter: (p) => getTotalValueFromGroups(p, "costGroup.estimate"),
      },
      {
        colId: "costGroup.difference",
        headerName: t`tableColumns.difference`,
        type: ["difference"],
        cellStyle: differenceStyle,
        aggFunc: reduceEstimateFromTarget,
        cellRenderer: valueOnlyInGroups,
        valueGetter: (p) => {
          const target = getTotalValueFromGroups(p, "costGroup.target") ?? 0;
          const estimate =
            getTotalValueFromGroups(p, "costGroup.estimate") ?? 0;
          return target - estimate;
        },
      },
      {
        colId: "costGroup.actual",
        headerName: t`tableColumns.actual`,
        type: ["money"],
        aggFunc: (p) => sumValues(p, "costGroup.actual"),
        cellRenderer: valueOnlyInGroups,
        valueGetter: (p) => getTotalValueFromGroups(p, "costGroup.actual"),
      },
      {
        colId: "component.total",
        headerName: t`tableColumns.component.total`,
        type: ["money"],
        aggFunc: sumAllCostClasses,
        valueGetter: getComponentsTotalValue,
      },
    ];
    return columns.concat(costClassColumnDefinitions(costClasses, t));
  }, [costClasses, t]);

  const defaultColDef: ColDef<CostControlPrintingRow> = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      enableValue: true,
      initialAggFunc: "sum",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      filterParams: {
        defaultOption: "startsWith",
      },
      cellStyle: cellStyles,
      cellClassRules: {
        bold: (params) => {
          const pivotMode = params.api.getGridOption("pivotMode");
          if (
            params.node.group &&
            !pivotMode &&
            params.node.allLeafChildren[0].data?.component
          ) {
            return params.node.group;
          }
          if (params.node.isRowPinned()) {
            return true;
          }
          return params.node.level === 0;
        },
      },
    };
  }, []);
  const autoGroupColumnDefs = useMemo(() => {
    return {
      filter: "agGroupColumnFilter",
      headerName: t`groupColumn.headerName`,
      colId: "autoGroupColumn",
      cellRendererParams: {
        suppressCount: true,
        suppressPadding: true,
      },
      cellRendererSelector: (params: ICellRendererParams) =>
        groupCellRendererSelector(params, t),
    };
  }, [t]);

  const isGroupOpen = (params: IsGroupOpenByDefaultParams) => {
    if (params.field === "mainCostGroup") {
      return true;
    }
    const costGroupCode = params.rowNode.key?.split(" - ")[0].slice(0, 2) ?? "";
    const component = components.find((component) =>
      component.code?.startsWith(costGroupCode)
    );
    return !!(params.field === "costGroup" && component);
  };

  const sideBar: SideBarDef = useMemo(() => {
    return {
      toolPanels: [
        {
          id: "columns",
          labelDefault: t`columns`,
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
        },
      ],
    };
  }, [t]);

  const tableContext = {
    targets: targets,
    estimates: estimates,
    costClasses: costClasses,
    invoices: invoices,
  };

  const pinnedBottomRowDataWithSocial = useMemo(() => {
    return [
      {
        id: "totalRowWithSocial",
        autoGroupColumn: t`grandTotalWithSocial`,
      },
    ];
  }, [t]);

  const onFilterChange = () => {
    if (gridRef.current) {
      gridRef.current.api.refreshCells();
    }
  };

  const statusBar = useMemo<{ statusPanels: StatusPanelDef[] }>(() => {
    return {
      statusPanels: [
        {
          statusPanel: (params: IStatusPanelParams) =>
            ExportToExcelStatusPanelButton(params, "costControl"),
        },
      ],
    };
  }, []);

  const printingExcelSettings = usePrintingExcelExportSettings(
    project,
    gridRef.current?.api
  );

  const [firstDataRendered, onFirstDataRendered] = useFirstDataRendered(false);

  usePinnedBottomRowUpdater(
    gridRef,
    firstDataRendered,
    pinnedBottomRowDataWithSocial
  );

  const recountTotalRow = () => {
    if (gridRef.current) {
      gridRef.current.api.refreshCells({ force: true });
    }
  };

  return (
    <div className={"flex flex-col h-full"}>
      <Table<CostControlPrintingRow>
        gridRef={gridRef}
        className={"h-full m-4 p-4"}
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        autoGroupColumnDef={autoGroupColumnDefs}
        sideBar={sideBar}
        rowGroupPanelShow={"always"}
        suppressAggFuncInHeader={true}
        context={tableContext}
        isGroupOpenByDefault={isGroupOpen}
        onFilterChanged={onFilterChange}
        statusBar={statusBar}
        {...printingExcelSettings}
        onFirstDataRendered={onFirstDataRendered}
        onModelUpdated={recountTotalRow}
      />
    </div>
  );
};
