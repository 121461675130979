// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var Record = require("../Record/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Utils_Listeners = require("../Utils.Listeners/index.js");
var pageX = React_Basic_Events.unsafeEventFn(function (e) {
    return Data_Nullable.toMaybe(e.pageX);
});
var defaultProps = {
    className: "",
    headerRectLeft: 0.0,
    scrollLeft: 0.0,
    onResize: function (v) {
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    },
    resizeable: false
};
var component = function (dictUnion) {
    return function (dictNub) {
        return TofuHooks.mkHookComponent("TableHeaderSeparator")(function (props$prime) {
            var v = Record.merge()()(props$prime)(defaultProps);
            return function __do() {
                var startPageXRef = TofuHooks.useRef(0.0)();
                var v1 = TofuHooks.useState(false)();
                var v2 = TofuHooks.useState(0.0)();
                return React_Basic.fragment([ React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                    className: "table-header-separator" + v.className,
                    css: TacoTable_Styles.tableHeaderSeparatorStyles(v.resizeable),
                    role: "separator",
                    onClick: React_Basic_Events.handler(Control_Semigroupoid.composeFlipped(React_Basic_Events.semigroupoidBuilder)(React_Basic_DOM_Events.preventDefault)(React_Basic_DOM_Events.stopPropagation))(Control_Applicative.pure(Control_Applicative.applicativeFn)(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit))),
                    onMouseDown: (function () {
                        if (v.resizeable) {
                            return React_Basic_Events.handler(Control_Semigroupoid.composeFlipped(React_Basic_Events.semigroupoidBuilder)(React_Basic_DOM_Events.preventDefault)(Control_Semigroupoid.composeFlipped(React_Basic_Events.semigroupoidBuilder)(React_Basic_DOM_Events.stopPropagation)(pageX)))(function (_px) {
                                return function __do() {
                                    (function () {
                                        if (_px instanceof Data_Maybe.Just) {
                                            React_Basic_Hooks.writeRef(startPageXRef)(_px.value0)();
                                            v2.value1(function (v3) {
                                                return _px.value0;
                                            })();
                                            return v1.value1(function (v3) {
                                                return true;
                                            })();
                                        };
                                        if (_px instanceof Data_Maybe.Nothing) {
                                            return Data_Unit.unit;
                                        };
                                        throw new Error("Failed pattern match at TacoTable.HeaderSeparator (line 73, column 15 - line 78, column 37): " + [ _px.constructor.name ]);
                                    })();
                                    var mouseMoveEventListener = Utils_Listeners.addMouseMoveEventListenerToDocument(function (v3) {
                                        return function __do() {
                                            v3.preventDefault();
                                            v3.stopPropagation();
                                            v2.value1(function (v4) {
                                                return v3.pageX;
                                            })();
                                            return Data_Unit.unit;
                                        };
                                    })();
                                    return Data_Functor["void"](Effect.functorEffect)(Utils_Listeners.addMouseUpEventListenerToDocument(function (v3) {
                                        return function __do() {
                                            Utils_Listeners.removeMouseMoveEventListenerFromDocument(mouseMoveEventListener)();
                                            var startClientX = React_Basic_Hooks.readRef(startPageXRef)();
                                            v.onResize(v3.clientX - startClientX)();
                                            React_Basic_Hooks.writeRef(startPageXRef)(0.0)();
                                            v1.value1(function (v4) {
                                                return false;
                                            })();
                                            return Data_Unit.unit;
                                        };
                                    })({
                                        once: true
                                    }))();
                                };
                            });
                        };
                        if (!v.resizeable) {
                            return React_Basic_Events.handler(Control_Semigroupoid.composeFlipped(React_Basic_Events.semigroupoidBuilder)(React_Basic_DOM_Events.preventDefault)(React_Basic_DOM_Events.stopPropagation))(Control_Applicative.pure(Control_Applicative.applicativeFn)(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit)));
                        };
                        throw new Error("Failed pattern match at TacoTable.HeaderSeparator (line 71, column 24 - line 94, column 91): " + [ v.resizeable.constructor.name ]);
                    })()
                }), React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                    className: "table-header-separator-line",
                    css: TacoTable_Styles.tableHeaderSeparatorLineStyles(v1.value0)(v2.value0)(v.headerRectLeft)(v.scrollLeft)
                }) ]);
            };
        });
    };
};
module.exports = {
    component: component
};
