const Printing = require("../../src/client-ts/containers/Estimation/Printing/Printing")
  .Printing;
const ProjectDetailsModal = require("../../src/client-ts/containers/Projects/ProjectDetailsModal/ProjectDetailsModal")
  .ProjectDetailsModal;
const Report = require("../../src/client-ts/containers/Estimation/Report/Report")
  .Report;

exports.printing = Printing;
exports.projectDetailsModal = ProjectDetailsModal;
exports.report = Report;
