// Generated by purs version 0.14.5
"use strict";
var Data_Date = require("../Data.Date/index.js");
var Data_Date_Component = require("../Data.Date.Component/index.js");
var Data_Enum = require("../Data.Enum/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Time = require("../Data.Time/index.js");
var Data_Time_Component = require("../Data.Time.Component/index.js");
var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");
var toSql = function (dict) {
    return dict.toSql;
};
var isSqlValueString = {
    toSql: Unsafe_Coerce.unsafeCoerce
};
var isSqlValueNumber = {
    toSql: Unsafe_Coerce.unsafeCoerce
};
var isSqlValueMaybe = function (dictIsSqlValue) {
    return {
        toSql: function ($10) {
            return Data_Nullable.toNullable((function (v) {
                return Data_Functor.map(Data_Maybe.functorMaybe)(toSql(dictIsSqlValue))(v);
            })($10));
        }
    };
};
var isSqlValueInt = {
    toSql: function ($11) {
        return Data_Int.toNumber($11);
    }
};
var isSqlValueDateTime = {
    toSql: (function () {
        var zeroPad = function (i) {
            if (i < 10) {
                return "0" + Data_Show.show(Data_Show.showInt)(i);
            };
            return Data_Show.show(Data_Show.showInt)(i);
        };
        var format = function (v) {
            return Data_Show.show(Data_Show.showInt)(Data_Enum.fromEnum(Data_Date_Component.boundedEnumYear)(Data_Date.year(v.value0))) + ("-" + (zeroPad(Data_Enum.fromEnum(Data_Date_Component.boundedEnumMonth)(Data_Date.month(v.value0))) + ("-" + (zeroPad(Data_Enum.fromEnum(Data_Date_Component.boundedEnumDay)(Data_Date.day(v.value0))) + (" " + (zeroPad(Data_Enum.fromEnum(Data_Time_Component.boundedEnumHour)(Data_Time.hour(v.value1))) + (":" + (zeroPad(Data_Enum.fromEnum(Data_Time_Component.boundedEnumMinute)(Data_Time.minute(v.value1))) + (":" + zeroPad(Data_Enum.fromEnum(Data_Time_Component.boundedEnumSecond)(Data_Time.second(v.value1))))))))))));
        };
        var $12 = toSql(isSqlValueString);
        return function ($13) {
            return $12(format($13));
        };
    })()
};
var isSqlValueBoolean = {
    toSql: function (val) {
        if (val) {
            return toSql(isSqlValueString)("true");
        };
        return toSql(isSqlValueString)("false");
    }
};
var isSqlValueArray = function (dictIsSqlValue) {
    return {
        toSql: (function () {
            var $14 = Data_Functor.map(Data_Functor.functorArray)(toSql(dictIsSqlValue));
            return function ($15) {
                return $14($15);
            };
        })()
    };
};
module.exports = {
    toSql: toSql,
    isSqlValueString: isSqlValueString,
    isSqlValueBoolean: isSqlValueBoolean,
    isSqlValueNumber: isSqlValueNumber,
    isSqlValueInt: isSqlValueInt,
    isSqlValueMaybe: isSqlValueMaybe,
    isSqlValueArray: isSqlValueArray,
    isSqlValueDateTime: isSqlValueDateTime
};
