// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_EuclideanRing = require("../Data.EuclideanRing/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var StyleProperties = require("../StyleProperties/index.js");
var StyleUtils = require("../StyleUtils/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TopRight = (function () {
    function TopRight() {

    };
    TopRight.value = new TopRight();
    return TopRight;
})();
var TopLeft = (function () {
    function TopLeft() {

    };
    TopLeft.value = new TopLeft();
    return TopLeft;
})();
var Top = (function () {
    function Top() {

    };
    Top.value = new Top();
    return Top;
})();
var BottomRight = (function () {
    function BottomRight() {

    };
    BottomRight.value = new BottomRight();
    return BottomRight;
})();
var BottomLeft = (function () {
    function BottomLeft() {

    };
    BottomLeft.value = new BottomLeft();
    return BottomLeft;
})();
var Bottom = (function () {
    function Bottom() {

    };
    Bottom.value = new Bottom();
    return Bottom;
})();
var Always = (function () {
    function Always() {

    };
    Always.value = new Always();
    return Always;
})();
var OnHover = (function () {
    function OnHover() {

    };
    OnHover.value = new OnHover();
    return OnHover;
})();
var Never = (function () {
    function Never() {

    };
    Never.value = new Never();
    return Never;
})();
var tooltipWrapperStyles = function (v) {
    var visibility = (function () {
        if (v.active instanceof Always) {
            return true;
        };
        return v.hovered;
    })();
    return Box.boxStyle([ new Box.Position(Box.Absolute.value), Box.Width.create(new TacoConstants.CustomSize("320px")), new Box.Visibility(visibility), Box.Style.create(React_Basic_Emotion.css()({
        top: StyleProperties.px(Data_Int.floor(v.containerRect.top + v.containerRect.height)),
        left: StyleProperties.px(Data_Int.floor(v.containerRect.left))
    })) ]);
};
var tooltipContainerStyles = Box.boxStyle([ new Box.Position(Box.Relative.value), new Box.Display(Box.InlineBlock.value) ]);
var eqJustification = {
    eq: function (x) {
        return function (y) {
            if (x instanceof TopRight && y instanceof TopRight) {
                return true;
            };
            if (x instanceof TopLeft && y instanceof TopLeft) {
                return true;
            };
            if (x instanceof Top && y instanceof Top) {
                return true;
            };
            if (x instanceof BottomRight && y instanceof BottomRight) {
                return true;
            };
            if (x instanceof BottomLeft && y instanceof BottomLeft) {
                return true;
            };
            if (x instanceof Bottom && y instanceof Bottom) {
                return true;
            };
            return false;
        };
    }
};
var arrowSize = 6;
var tooltipStyles = function (props) {
    var topAligned = Box.PosBottom.create(TacoConstants.CustomSize.create(StyleUtils.intToPx(props.containerHeight + (arrowSize * 2 | 0) | 0)));
    var rightAligned = Box.Style.create(Box.boxStyle([ Box.PosLeft.create(TacoConstants.CustomSize.create(StyleUtils.intToPx(props.containerWidth - props.tipWidth | 0))), Box.JustifyEnd.value ]));
    var leftAligned = Box.PosLeft.create(TacoConstants.CustomSize.create("0px"));
    var centered = Box.PosLeft.create(TacoConstants.CustomSize.create(StyleUtils.intToPx(Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(props.containerWidth - props.tipWidth | 0)(2))));
    var bottomAligned = Box.PosTop.create(TacoConstants.CustomSize.create(StyleUtils.intToPx(arrowSize * 2 | 0)));
    return Box.boxStyle([ new Box.Display(Box.InlineFlex.value), new Box.Position(Box.Absolute.value), new Box.BackgroundColor(TacoConstants.GrayDark.value), new Box.ForegroundColor(TacoConstants.GrayLightest.value), new Box.Padding(TacoConstants.XS.value), Box.BorderRadius.create(new TacoConstants.CustomSize("4px")), Box.MaxWidth.create(new TacoConstants.CustomSize("320px")), new Box.ZIndex(TacoConstants.zIndexTooltip), Box.Style.create(React_Basic_Emotion.css()({
        whiteSpace: React_Basic_Emotion.str("pre-line")
    })), Data_Monoid.guard(Box.monoidBoxProp)(Data_Eq.eq(eqJustification)(props.justify)(TopRight.value) || (Data_Eq.eq(eqJustification)(props.justify)(TopLeft.value) || Data_Eq.eq(eqJustification)(props.justify)(Top.value)))(topAligned), Data_Monoid.guard(Box.monoidBoxProp)(Data_Eq.eq(eqJustification)(props.justify)(BottomRight.value) || (Data_Eq.eq(eqJustification)(props.justify)(BottomLeft.value) || Data_Eq.eq(eqJustification)(props.justify)(Bottom.value)))(bottomAligned), Data_Monoid.guard(Box.monoidBoxProp)(Data_Eq.eq(eqJustification)(props.justify)(TopLeft.value) || Data_Eq.eq(eqJustification)(props.justify)(BottomLeft.value))(leftAligned), Data_Monoid.guard(Box.monoidBoxProp)(Data_Eq.eq(eqJustification)(props.justify)(Top.value) || Data_Eq.eq(eqJustification)(props.justify)(Bottom.value))(centered), Data_Monoid.guard(Box.monoidBoxProp)(Data_Eq.eq(eqJustification)(props.justify)(TopRight.value) || Data_Eq.eq(eqJustification)(props.justify)(BottomRight.value))(rightAligned) ]);
};
var arrowBorder = React_Basic_Emotion.str(StyleUtils.intToPx(arrowSize) + (" solid " + TacoConstants.colorSchemeToStr(TacoConstants.GrayDark.value)));
var arrowStyles = function (props) {
    var topAligned = Box.Style.create(Box.boxStyle([ Box.PosBottom.create(TacoConstants.CustomSize.create(StyleUtils.intToPx(props.containerHeight))), Box.Style.create(React_Basic_Emotion.css()({
        borderTop: arrowBorder
    })) ]));
    var rightAligned = Box.PosLeft.create(TacoConstants.CustomSize.create(StyleUtils.intToPx((props.containerWidth - arrowSize | 0) - (8 * 2 | 0) | 0)));
    var leftAligned = Box.PosLeft.create(TacoConstants.CustomSize.create("8px"));
    var centered = Box.PosLeft.create(TacoConstants.CustomSize.create(StyleUtils.intToPx(Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingInt)(props.containerWidth - arrowSize | 0)(2))));
    var bottomAligned = Box.Style.create(Box.boxStyle([ Box.PosTop.create(TacoConstants.CustomSize.create("0px")), Box.Style.create(React_Basic_Emotion.css()({
        borderBottom: arrowBorder
    })) ]));
    return Box.boxStyle([ new Box.Position(Box.Absolute.value), Box.Width.create(TacoConstants.CustomSize.create(StyleUtils.intToPx(arrowSize * 2 | 0))), Box.Height.create(TacoConstants.CustomSize.create(StyleUtils.intToPx(arrowSize * 2 | 0))), Box.Style.create(React_Basic_Emotion.css()({
        boxSizing: React_Basic_Emotion.str("border-box"),
        borderWidth: React_Basic_Emotion.str(StyleUtils.intToPx(arrowSize)),
        borderStyle: React_Basic_Emotion.str("solid"),
        borderColor: React_Basic_Emotion.str("transparent")
    })), Data_Monoid.guard(Box.monoidBoxProp)(Data_Eq.eq(eqJustification)(props.justify)(TopRight.value) || (Data_Eq.eq(eqJustification)(props.justify)(TopLeft.value) || Data_Eq.eq(eqJustification)(props.justify)(Top.value)))(topAligned), Data_Monoid.guard(Box.monoidBoxProp)(Data_Eq.eq(eqJustification)(props.justify)(BottomRight.value) || (Data_Eq.eq(eqJustification)(props.justify)(BottomLeft.value) || Data_Eq.eq(eqJustification)(props.justify)(Bottom.value)))(bottomAligned), Data_Monoid.guard(Box.monoidBoxProp)(Data_Eq.eq(eqJustification)(props.justify)(TopLeft.value) || Data_Eq.eq(eqJustification)(props.justify)(BottomLeft.value))(leftAligned), Data_Monoid.guard(Box.monoidBoxProp)(Data_Eq.eq(eqJustification)(props.justify)(Top.value) || Data_Eq.eq(eqJustification)(props.justify)(Bottom.value))(centered), Data_Monoid.guard(Box.monoidBoxProp)(Data_Eq.eq(eqJustification)(props.justify)(TopRight.value) || Data_Eq.eq(eqJustification)(props.justify)(BottomRight.value))(rightAligned) ]);
};
module.exports = {
    arrowSize: arrowSize,
    tooltipContainerStyles: tooltipContainerStyles,
    Always: Always,
    OnHover: OnHover,
    Never: Never,
    tooltipWrapperStyles: tooltipWrapperStyles,
    TopRight: TopRight,
    TopLeft: TopLeft,
    Top: Top,
    BottomRight: BottomRight,
    BottomLeft: BottomLeft,
    Bottom: Bottom,
    tooltipStyles: tooltipStyles,
    arrowBorder: arrowBorder,
    arrowStyles: arrowStyles,
    eqJustification: eqJustification
};
