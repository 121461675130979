// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var StyleProperties = require("../StyleProperties/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Column = (function () {
    function Column() {

    };
    Column.value = new Column();
    return Column;
})();
var Row = (function () {
    function Row() {

    };
    Row.value = new Row();
    return Row;
})();
var styles = function (spacing) {
    return function (direction) {
        return function (overflow) {
            var textOverflowStyles = React_Basic_Emotion.css()({
                display: React_Basic_Emotion.str("block"),
                overflow: React_Basic_Emotion.str("hidden"),
                textOverflow: React_Basic_Emotion.str("ellipsis"),
                whiteSpace: React_Basic_Emotion.str("nowrap")
            });
            return Data_Semigroup.append(React_Basic_Emotion.semigroupStyle)(React_Basic_Emotion.css()({
                display: React_Basic_Emotion.str("inline-grid"),
                gap: StyleProperties.size(spacing),
                gridAutoFlow: React_Basic_Emotion.str((function () {
                    if (direction instanceof Column) {
                        return "row";
                    };
                    if (direction instanceof Row) {
                        return "column";
                    };
                    throw new Error("Failed pattern match at Gap (line 34, column 26 - line 36, column 22): " + [ direction.constructor.name ]);
                })())
            }))(Data_Monoid.guard(React_Basic_Emotion.monoidStyle)(overflow)(textOverflowStyles));
        };
    };
};
var component = TofuHooks.mkHookComponent("Gap")(function (props) {
    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        className: "__Gap",
        children: props.contents,
        css: React_Basic_Emotion.merge([ props.css, styles(props.spacing)(props.direction)(props.overflow) ])
    }));
});
var gapX = function (spacing) {
    return function (contents) {
        return component({
            spacing: spacing,
            contents: contents,
            direction: Row.value,
            overflow: false,
            css: Data_Monoid.mempty(React_Basic_Emotion.monoidStyle)
        });
    };
};
var gapY = function (spacing) {
    return function (contents) {
        return component({
            spacing: spacing,
            contents: contents,
            direction: Column.value,
            overflow: false,
            css: Data_Monoid.mempty(React_Basic_Emotion.monoidStyle)
        });
    };
};
module.exports = {
    gapX: gapX,
    gapY: gapY,
    component: component,
    Column: Column,
    Row: Row
};
