// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Calculation_Portfolio = require("../Calculation.Portfolio/index.js");
var Common = require("../Common/index.js");
var Control_Alt = require("../Control.Alt/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Controls = require("../Controls/index.js");
var CostEstimation_Layout = require("../CostEstimation.Layout/index.js");
var CostEstimation_SummariesPanel = require("../CostEstimation.SummariesPanel/index.js");
var CostEstimation_SummariesSidebar = require("../CostEstimation.SummariesSidebar/index.js");
var CostEstimationLayout_Types = require("../CostEstimationLayout.Types/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var EstimationUtils = require("../EstimationUtils/index.js");
var FeatureHooks = require("../FeatureHooks/index.js");
var FetchHooks = require("../FetchHooks/index.js");
var Formatters = require("../Formatters/index.js");
var Gap = require("../Gap/index.js");
var HtmlUtils = require("../HtmlUtils/index.js");
var Locale = require("../Locale/index.js");
var ProjectClassificationTable = require("../ProjectClassificationTable/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var SocialExpenseIndicator = require("../SocialExpenseIndicator/index.js");
var SocialExpensesControls = require("../SocialExpensesControls/index.js");
var StateHooks = require("../StateHooks/index.js");
var StringUtils = require("../StringUtils/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoDropdown = require("../TacoDropdown/index.js");
var TacoDropdown_Types = require("../TacoDropdown.Types/index.js");
var TacoIcon = require("../TacoIcon/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoLoader = require("../TacoLoader/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Header = require("../TacoTable.Header/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_CostClass = require("../Types.CostClass/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Project = require("../Types.Project/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var UseLicenseFeature = require("../UseLicenseFeature/index.js");
var UseLocalStorage = require("../UseLocalStorage/index.js");
var UseNullableRef = require("../UseNullableRef/index.js");
var UseSocialExpensesInCosts = require("../UseSocialExpensesInCosts/index.js");
var UseWorkerData = require("../UseWorkerData/index.js");
var Utils = require("../Utils/index.js");
var updateOfferRowRatios = function (v) {
    var updatePercentageOfTotal = function (r) {
        return {
            percentageOfTotal: Types_Unit.PercentageOfZeroToOne(Utils.defaultZeroDiv(r.totalWithRiskAndMargin)(v.summaryRow.r.totalWithRiskAndMargin)),
            percentageOfFinalTotal: Types_Unit.PercentageOfZeroToOne(Utils.defaultZeroDiv(r.finalTotal)(v.summaryRow.r.finalTotal)),
            finalTotal: r.finalTotal,
            totalWithRiskAndMargin: r.totalWithRiskAndMargin,
            description: r.description,
            hourly_rate: r.hourly_rate,
            hours: r.hours,
            id: r.id,
            marginAmount: r.marginAmount,
            marginPercentage: r.marginPercentage,
            socialExpensePrice: r.socialExpensePrice,
            socialExpensePriceWithMarginPercentage: r.socialExpensePriceWithMarginPercentage,
            totalForClassification: r.totalForClassification,
            totalOverride: r.totalOverride,
            total_price_by_cost_classes: r.total_price_by_cost_classes,
            total_price_by_cost_classes_with_margin: r.total_price_by_cost_classes_with_margin
        };
    };
    return Data_Functor.mapFlipped(Data_Functor.functorArray)(v.offerRows)(function (or) {
        return {
            r: updatePercentageOfTotal(or.r),
            allowEditing: or.allowEditing,
            mParams: or.mParams,
            marginWasSet: or.marginWasSet,
            overrideWasSet: or.overrideWasSet,
            showCostClasses: or.showCostClasses,
            showEditOverride: or.showEditOverride,
            showHours: or.showHours,
            showMargin: or.showMargin,
            showOverride: or.showOverride,
            showPercentage: or.showPercentage,
            showTotal: or.showTotal,
            totalMarginSet: or.totalMarginSet,
            totalOverrideSet: or.totalOverrideSet
        };
    });
};
var summaryId = "summary";
var removeSummaryEntry = Data_Map_Internal["delete"](Data_Ord.ordString)(summaryId);
var overrideInputWarning = "Sy\xf6tetyt tarjoussummat sis\xe4lt\xe4v\xe4t aina sosiaalikulut.\x0aSosiaalikuluja ei saa erilliselle riville kun olet sy\xf6tt\xe4nyt k\xe4sin tarjoussarakkeeseen tarjoussummia.\x0aVoit poistaa sy\xf6tetyt tarjoussummat vaihtamalla n\xe4ille riveille Kate % -sarakkeeseen prosentin, tarjoussarake lasketaan t\xe4ll\xf6in uudestaan prosentin mukaisesti.";
var offerTableInstance = TacoTable.tableOptimized(TofuHooks.getNameVoid)(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "totalOverrideSet";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "totalMarginSet";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "showTotal";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "showPercentage";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "showOverride";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "showMargin";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "showHours";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "showEditOverride";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "showCostClasses";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "r";
    }
})(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "total_price_by_cost_classes_with_margin";
    }
})(Data_Maybe.eqMaybe(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "totalPrice";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "socialExpensePrice";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "costClass";
    }
})(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostEstimation";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostControlForTargets";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostControlForEstimatesAndActuals";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostControlForComponents";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "projectId";
    }
})(Types_Project.eqProjectId))()({
    reflectSymbol: function () {
        return "name";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "multiplier";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "id";
    }
})(Types_CostClass.eqCostClassId))()({
    reflectSymbol: function () {
        return "hourlyPricing";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(Types_Newtypes.eqCostClassCode))))))))()({
    reflectSymbol: function () {
        return "total_price_by_cost_classes";
    }
})(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "totalPrice";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "socialExpensePrice";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "costClass";
    }
})(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostEstimation";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostControlForTargets";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostControlForEstimatesAndActuals";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostControlForComponents";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "projectId";
    }
})(Types_Project.eqProjectId))()({
    reflectSymbol: function () {
        return "name";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "multiplier";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "id";
    }
})(Types_CostClass.eqCostClassId))()({
    reflectSymbol: function () {
        return "hourlyPricing";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(Types_Newtypes.eqCostClassCode)))))))()({
    reflectSymbol: function () {
        return "totalWithRiskAndMargin";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "totalOverride";
    }
})(Data_Maybe.eqMaybe(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "totalForClassification";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "socialExpensePriceWithMarginPercentage";
    }
})(Data_Maybe.eqMaybe(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "socialExpensePrice";
    }
})(Data_Maybe.eqMaybe(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "percentageOfTotal";
    }
})(Types_Unit.eqPercentageOfZeroToOne))()({
    reflectSymbol: function () {
        return "percentageOfFinalTotal";
    }
})(Types_Unit.eqPercentageOfZeroToOne))()({
    reflectSymbol: function () {
        return "marginPercentage";
    }
})(Types_Unit.eqPercentageOfZeroToOne))()({
    reflectSymbol: function () {
        return "marginAmount";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "id";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "hours";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "hourly_rate";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "finalTotal";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "description";
    }
})(Data_Eq.eqString))))()({
    reflectSymbol: function () {
        return "overrideWasSet";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "marginWasSet";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "mParams";
    }
})(Data_Maybe.eqMaybe(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "showEconomicMargin";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "riskPercentages";
    }
})(Data_Map_Internal.eqMap(Data_Eq.eqString)(Types_Unit.eqPercentageOfZeroToOne)))()({
    reflectSymbol: function () {
        return "offerOverrides";
    }
})(Data_Map_Internal.eqMap(Data_Eq.eqString)(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "marginPercentages";
    }
})(Data_Map_Internal.eqMap(Data_Eq.eqString)(Types_Unit.eqPercentageOfZeroToOne))))))()({
    reflectSymbol: function () {
        return "allowEditing";
    }
})(Data_Eq.eqBoolean)));
var offerTableHeaderRow = function (props) {
    var textSize = new TacoConstants.CustomSize("1.25rem");
    var prepare = function (r) {
        return {
            key: r.key,
            label: r.label,
            width: r.width,
            sortProperty: TacoTable_Types.noSortProperty,
            headerJSX: r.headerJSX,
            cell: r.cell
        };
    };
    var totalColumns = Data_Functor.map(Data_Functor.functorArray)(prepare)([ {
        key: "totalWithRiskAndMargin",
        label: React_Basic_DOM.text(""),
        width: new TacoTable_Types.Fixed("9rem"),
        headerJSX: Data_Maybe.Just.create(TacoText.component()()({
            size: textSize,
            align: TacoText_Types.Center.value,
            text: ""
        })),
        cell: new TacoTable_Types.PlainTextCell(function (v) {
            return "";
        })
    }, {
        key: "percentageOfTotal",
        label: React_Basic_DOM.text(""),
        width: new TacoTable_Types.Fixed("5rem"),
        headerJSX: Data_Maybe.Nothing.value,
        cell: new TacoTable_Types.PlainTextCell(function (v) {
            return "";
        })
    }, {
        key: "totalOverride",
        label: React_Basic_DOM.text(""),
        width: new TacoTable_Types.Fixed("9rem"),
        headerJSX: Data_Maybe.Just.create(TacoText.component()()({
            size: textSize,
            align: TacoText_Types.Center.value,
            text: ""
        })),
        cell: new TacoTable_Types.PlainTextCell(function (v) {
            return "";
        })
    }, {
        key: "percentageOfFinalTotal",
        label: React_Basic_DOM.text(""),
        width: new TacoTable_Types.Fixed("5rem"),
        headerJSX: Data_Maybe.Nothing.value,
        cell: new TacoTable_Types.PlainTextCell(function (v) {
            return "";
        })
    } ]);
    var hoursColumn = (function () {
        var $104 = !props.showHoursColumns;
        if ($104) {
            return Common.emptyArray;
        };
        return Data_Functor.map(Data_Functor.functorArray)(prepare)([ {
            key: "hours",
            label: React_Basic_DOM.text(""),
            width: new TacoTable_Types.Fixed("18rem"),
            headerJSX: Data_Maybe.Just.create(TacoText.component()()({
                size: textSize,
                align: TacoText_Types.Center.value,
                text: "Kokonaistunnit"
            })),
            cell: new TacoTable_Types.PlainTextCell(function (v) {
                return "";
            })
        } ]);
    })();
    var costClassColumn = (function () {
        var $105 = !props.showCostClassColumns;
        if ($105) {
            return Common.emptyArray;
        };
        return Data_Functor.map(Data_Functor.functorArray)(prepare)([ {
            key: "costclasses",
            label: React_Basic_DOM.text(""),
            width: TacoTable_Types.Fixed.create(Data_Show.show(Data_Show.showInt)(9 * Data_Array.length(props.costClassesWithHash.value) | 0) + "rem"),
            headerJSX: Data_Maybe.Just.create(TacoText.component()()({
                size: textSize,
                align: TacoText_Types.Center.value,
                text: "Tekninen hinta panoslajeittain"
            })),
            cell: new TacoTable_Types.PlainTextCell(function (v) {
                return "";
            })
        } ]);
    })();
    var columns = Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_Functor.map(Data_Functor.functorArray)(prepare)([ {
        key: "description",
        label: React_Basic_DOM.text(""),
        width: new TacoTable_Types.FlexMinWidth(1, "12rem"),
        headerJSX: Data_Maybe.Nothing.value,
        cell: new TacoTable_Types.PlainTextCell(function (v) {
            return "";
        })
    }, {
        key: "totalForClassification",
        label: React_Basic_DOM.text(""),
        width: new TacoTable_Types.Fixed("12rem"),
        headerJSX: Data_Maybe.Just.create(TacoText.component()()({
            size: textSize,
            align: TacoText_Types.Right.value,
            text: "Tekninen hinta"
        })),
        cell: new TacoTable_Types.PlainTextCell(function (v) {
            return "";
        })
    }, {
        key: "percentages",
        label: React_Basic_DOM.text(""),
        width: new TacoTable_Types.Fixed("18rem"),
        headerJSX: Data_Maybe.Just.create(TacoText.component()()({
            size: textSize,
            align: TacoText_Types.Center.value,
            text: (function () {
                if (props.showEconomicMargin) {
                    return "Kate tarjoushinnasta";
                };
                return "Teknisest\xe4 hinnasta";
            })()
        })),
        cell: new TacoTable_Types.PlainTextCell(function (v) {
            return "";
        })
    } ]))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(totalColumns)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(hoursColumn)(costClassColumn)));
    return TacoTable_Header.simpleHeader(TofuHooks.getNameVoid)(Data_Eq.eqVoid)(Data_Eq.eqVoid)(columns)(TacoTable_Styles.defaultHeaderStyleSet);
};
var offerTableColumns = function (props) {
    var prepare = function (r) {
        return {
            key: r.key,
            label: r.label,
            width: r.width,
            sortProperty: TacoTable_Types.noSortProperty,
            headerJSX: r.headerJSX,
            cell: r.cell
        };
    };
    var overrideCellValue = function (r) {
        return Data_Maybe.fromMaybe(r.r.finalTotal)(r.r.totalOverride);
    };
    var overrideCell$prime = function (r) {
        return TacoInput.stringInput()()(Data_Eq.eqString)({
            value: Formatters.formatDecimalValue(2)(overrideCellValue(r)),
            color: (function () {
                if (false) {
                    return new Data_Maybe.Just(TacoConstants.GrayDark.value);
                };
                return Data_Maybe.Nothing.value;
            })(),
            fontWeight: (function () {
                if (r.overrideWasSet) {
                    return new Data_Maybe.Just("bold");
                };
                return Data_Maybe.Nothing.value;
            })(),
            disabled: !r.allowEditing,
            align: TacoInput.RightAlign.value,
            onSave: function (s) {
                var v = StringUtils.readStrToNumber(s);
                var v1 = Data_String_Common["null"](Data_String_Common.trim(s));
                if (!v1 && v instanceof Data_Maybe.Nothing) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                };
                if (v1) {
                    return props.updateParams(function (params) {
                        return {
                            riskPercentages: params.riskPercentages,
                            marginPercentages: params.marginPercentages,
                            offerOverrides: Data_Map_Internal["delete"](Data_Ord.ordString)(r.r.id)(params.offerOverrides),
                            showEconomicMargin: params.showEconomicMargin
                        };
                    });
                };
                if (!v1 && v instanceof Data_Maybe.Just) {
                    return props.updateParams(function (params) {
                        var $111 = r.r.id === summaryId;
                        if ($111) {
                            return {
                                riskPercentages: params.riskPercentages,
                                marginPercentages: params.marginPercentages,
                                offerOverrides: Data_Map_Internal.singleton(summaryId)(v.value0),
                                showEconomicMargin: params.showEconomicMargin
                            };
                        };
                        return {
                            riskPercentages: params.riskPercentages,
                            marginPercentages: params.marginPercentages,
                            offerOverrides: Data_Map_Internal.insert(Data_Ord.ordString)(r.r.id)(v.value0)(removeSummaryEntry(params.offerOverrides)),
                            showEconomicMargin: params.showEconomicMargin
                        };
                    });
                };
                throw new Error("Failed pattern match at OfferPageView (line 1070, column 25 - line 1084, column 22): " + [ v1.constructor.name, v.constructor.name ]);
            }
        });
    };
    var overrideCell = (function () {
        if (props.allowEditing) {
            return new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value, TacoTable_Types.Input.value ], function (r) {
                var $114 = r.showOverride && (r.allowEditing && r.showEditOverride);
                if ($114) {
                    return overrideCell$prime(r);
                };
                return React_Basic_DOM.text(Formatters.formatDecimalValue(2)(r.r.finalTotal) + " \u20ac");
            });
        };
        return new TacoTable_Types.PlainEuroCell(overrideCellValue);
    })();
    var mkSocialExpenseLabel$prime = function (jsx) {
        return Gap.gapX(TacoConstants.XS.value)(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ Data_Monoid.guard(React_Basic.monoidJSX)(props.showSocialExpensesInCosts)(SocialExpenseIndicator.socialExpenseIndicator) ])(jsx));
    };
    var mkSocialExpenseLabel = function (text) {
        return mkSocialExpenseLabel$prime([ React_Basic_DOM.text(text) ]);
    };
    var marginPercentageCell$prime = function (r) {
        var isCalculatedValue = r.overrideWasSet || (r.r.id !== summaryId && r.totalMarginSet || r.r.id === summaryId && !r.totalMarginSet);
        return TacoInput.stringInput()()(Data_Eq.eqString)({
            value: Formatters.formatDecimalValue(2)(Data_Newtype.unwrap()(r.r.marginPercentage) * 100.0),
            color: (function () {
                if (isCalculatedValue) {
                    return new Data_Maybe.Just(TacoConstants.GrayDark.value);
                };
                return Data_Maybe.Nothing.value;
            })(),
            fontWeight: (function () {
                if (r.marginWasSet) {
                    return new Data_Maybe.Just("bold");
                };
                return Data_Maybe.Nothing.value;
            })(),
            disabled: !r.allowEditing,
            align: TacoInput.RightAlign.value,
            onSave: function (s) {
                var v = StringUtils.readStrToNumber(s);
                var v1 = Data_String_Common["null"](Data_String_Common.trim(s));
                if (!v1 && v instanceof Data_Maybe.Nothing) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                };
                if (v1) {
                    return props.updateParams(function (params) {
                        return {
                            riskPercentages: params.riskPercentages,
                            marginPercentages: Data_Map_Internal["delete"](Data_Ord.ordString)(r.r.id)(params.marginPercentages),
                            offerOverrides: params.offerOverrides,
                            showEconomicMargin: params.showEconomicMargin
                        };
                    });
                };
                if (!v1 && v instanceof Data_Maybe.Just) {
                    return props.updateParams(function (params) {
                        var value = v.value0 / 100.0;
                        var $119 = r.r.id === summaryId;
                        if ($119) {
                            return {
                                riskPercentages: params.riskPercentages,
                                marginPercentages: Data_Map_Internal.singleton(summaryId)(value),
                                offerOverrides: params.offerOverrides,
                                showEconomicMargin: params.showEconomicMargin
                            };
                        };
                        return {
                            riskPercentages: params.riskPercentages,
                            marginPercentages: Data_Map_Internal.insert(Data_Ord.ordString)(r.r.id)(value)(removeSummaryEntry(params.marginPercentages)),
                            offerOverrides: Data_Map_Internal["delete"](Data_Ord.ordString)(r.r.id)(removeSummaryEntry(params.offerOverrides)),
                            showEconomicMargin: params.showEconomicMargin
                        };
                    });
                };
                throw new Error("Failed pattern match at OfferPageView (line 1108, column 25 - line 1124, column 22): " + [ v1.constructor.name, v.constructor.name ]);
            }
        });
    };
    var marginPercentageCell = (function () {
        if (props.allowEditing) {
            return new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value, TacoTable_Types.Input.value ], function (r) {
                if (r.showMargin) {
                    return marginPercentageCell$prime(r);
                };
                return React_Basic.empty;
            });
        };
        return new TacoTable_Types.MaybePlainPercentageCell(2, function (r) {
            if (r.showMargin) {
                return new Data_Maybe.Just(r.r.marginPercentage);
            };
            return Data_Maybe.Nothing.value;
        });
    })();
    var guardMaybeValue = function (cond) {
        return function (fn) {
            return function (r) {
                var $124 = cond(r);
                if ($124) {
                    return new Data_Maybe.Just(fn(r));
                };
                return Data_Maybe.Nothing.value;
            };
        };
    };
    var hoursColumns = (function () {
        if (props.showHoursColumns) {
            return Data_Functor.map(Data_Functor.functorArray)(prepare)([ {
                key: "hours",
                label: React_Basic_DOM.text("Tunnit"),
                width: new TacoTable_Types.Fixed("9rem"),
                headerJSX: Data_Maybe.Nothing.value,
                cell: TacoTable_Types.MaybePlainNumberCell.create(Calculation_Portfolio.decimalPrecision1)(guardMaybeValue(function (v) {
                    return v.showHours;
                })(function (v) {
                    return v.r.hours;
                }))
            }, {
                key: "hourly_rate",
                label: mkSocialExpenseLabel("Keskituntiansio"),
                width: new TacoTable_Types.Fixed("9rem"),
                headerJSX: Data_Maybe.Nothing.value,
                cell: TacoTable_Types.MaybePlainNumberCell.create(Calculation_Portfolio.decimalPrecision1)(guardMaybeValue(function (v) {
                    return v.showHours;
                })(function (v) {
                    return v.r.hourly_rate;
                }))
            } ]);
        };
        return Common.emptyArray;
    })();
    var totalColumns = Data_Functor.map(Data_Functor.functorArray)(prepare)([ {
        key: "totalWithRiskAndMargin",
        label: mkSocialExpenseLabel("Yhteens\xe4"),
        width: new TacoTable_Types.Fixed("9rem"),
        headerJSX: Data_Maybe.Nothing.value,
        cell: new TacoTable_Types.PlainEuroCell(function (v) {
            return v.r.totalWithRiskAndMargin;
        })
    }, {
        key: "percentageOfTotal",
        label: mkSocialExpenseLabel("%"),
        width: new TacoTable_Types.Fixed("5rem"),
        headerJSX: Data_Maybe.Nothing.value,
        cell: TacoTable_Types.MaybePlainPercentageCell.create(1)(guardMaybeValue(function (v) {
            return v.showPercentage;
        })(function (v) {
            return v.r.percentageOfTotal;
        }))
    }, {
        key: "totalOverride",
        label: mkSocialExpenseLabel$prime([ TacoText.component()()({
            text: "Tarjous"
        }), (function () {
            if (props.allowEditing) {
                return TacoIcon.component()()()()({
                    icon: TacoIcon_Icons_Types.IconInfoWhite.value,
                    size: TacoConstants.XS.value,
                    title: overrideInputWarning
                });
            };
            return React_Basic.empty;
        })() ]),
        width: new TacoTable_Types.Fixed("9rem"),
        headerJSX: Data_Maybe.Nothing.value,
        cell: overrideCell
    }, {
        key: "percentageOfFinalTotal",
        label: mkSocialExpenseLabel("%"),
        width: new TacoTable_Types.Fixed("5rem"),
        headerJSX: Data_Maybe.Nothing.value,
        cell: TacoTable_Types.MaybePlainPercentageCell.create(1)(guardMaybeValue(function (v) {
            return v.showPercentage;
        })(function (v) {
            return v.r.percentageOfFinalTotal;
        }))
    } ]);
    var getTotalOfCC = function (cc) {
        return function (r) {
            return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.find((function () {
                var $229 = Data_Eq.eq(Types_Newtypes.eqCostClassCode)(cc.costClassCode);
                return function ($230) {
                    return $229((function (v) {
                        return v.costClass.costClassCode;
                    })($230));
                };
            })())(r.r.total_price_by_cost_classes))(function (match) {
                return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(match.totalPrice);
            });
        };
    };
    var mkCostClassColumn = function (cc) {
        return prepare({
            key: cc.name,
            label: (function () {
                var text = Data_Newtype.unwrap()(cc.costClassCode) + (": " + cc.name);
                var v = Data_Map_Internal.lookup(Types_Newtypes.ordCostClassCode)(cc.costClassCode)(props.socialExpensePercentagesByCostClass);
                if (v instanceof Data_Maybe.Just && Data_Newtype.unwrap()(Data_Newtype.unwrap()(v.value0)) !== 0.0) {
                    return mkSocialExpenseLabel(text);
                };
                return React_Basic_DOM.text(text);
            })(),
            width: new TacoTable_Types.Fixed("9rem"),
            headerJSX: Data_Maybe.Nothing.value,
            cell: TacoTable_Types.MaybePlainEuroCell.create(guardMaybeValue(function (v) {
                return v.showCostClasses;
            })((function () {
                var $231 = Data_Maybe.fromMaybe(0.0);
                var $232 = getTotalOfCC(cc);
                return function ($233) {
                    return $231($232($233));
                };
            })()))
        });
    };
    var costClassColumns = (function () {
        if (props.showCostClassColumns) {
            return Data_Functor.map(Data_Functor.functorArray)(mkCostClassColumn)(props.costClassesWithHash.value);
        };
        return Common.emptyArray;
    })();
    return Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_Functor.map(Data_Functor.functorArray)(prepare)([ {
        key: "description",
        label: React_Basic_DOM.text("Nimikkeist\xf6"),
        width: new TacoTable_Types.FlexMinWidth(1, "12rem"),
        headerJSX: Data_Maybe.Nothing.value,
        cell: new TacoTable_Types.PlainTextCell(function (v) {
            return v.r.description;
        })
    }, {
        key: "totalForClassification",
        label: mkSocialExpenseLabel("Yhteens\xe4"),
        width: new TacoTable_Types.Fixed("12rem"),
        headerJSX: Data_Maybe.Nothing.value,
        cell: TacoTable_Types.MaybePlainEuroCell.create(guardMaybeValue(function (v) {
            return v.showTotal;
        })(function (v) {
            return v.r.totalForClassification;
        }))
    } ]))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_Functor.map(Data_Functor.functorArray)(prepare)([ {
        key: "marginPercentage",
        label: mkSocialExpenseLabel("Kate %"),
        width: new TacoTable_Types.Fixed("9rem"),
        headerJSX: Data_Maybe.Nothing.value,
        cell: marginPercentageCell
    }, {
        key: "marginAmount",
        label: mkSocialExpenseLabel("Kate"),
        width: new TacoTable_Types.Fixed("9rem"),
        headerJSX: Data_Maybe.Nothing.value,
        cell: TacoTable_Types.MaybePlainEuroCell.create(guardMaybeValue(function (v) {
            return v.showMargin;
        })(function (v) {
            return v.r.marginAmount;
        }))
    } ]))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(totalColumns)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(hoursColumns)(costClassColumns))));
};
var offerPageViewControls = TofuHooks.mkHookComponent("OfferPageViewControls")(function (props) {
    return function __do() {
        var dispatch = StateHooks.useDispatch();
        var v = TofuHooks.useState(false)();
        var v1 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var leftControls = [ TacoText.component()()({
            text: "Tarjouslaskenta",
            variant: new TacoText_Types.Heading(2),
            wrap: TacoText_Types.NoWrap.value,
            overflow: TacoText_Types.TextOverflowEllipsis.value
        }) ];
        var hasOverrides = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Map_Internal.isEmpty)(removeSummaryEntry(props.offerOverrides));
        var socialCostsToggledDisableReason = (function () {
            var $133 = hasOverrides && props.showSocialExpensesInCosts;
            if ($133) {
                return new Data_Maybe.Just(overrideInputWarning);
            };
            return Data_Maybe.Nothing.value;
        })();
        var rightDropdown = TacoDropdown.component({
            sections: [ {
                title: Data_Maybe.Nothing.value,
                items: [ SocialExpensesControls.component({
                    isSocialExpensesToggled: props.showSocialExpensesInCosts,
                    onToggle: props.onSocialExpensesToggle,
                    projectId: props.projectId,
                    disabled: socialCostsToggledDisableReason
                }), TacoCheckbox.component()()({
                    isChecked: props.allowEditing,
                    onToggle: TacoCheckbox.NoEvent.create(props.setAllowEditing(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))),
                    labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Salli muokkaus")),
                    testId: "allow-editing-control"
                }), TacoCheckbox.component()()({
                    isChecked: props.showEconomicMargin,
                    onToggle: TacoCheckbox.NoEvent.create(props.setShowEconomicMargin(!props.showEconomicMargin)),
                    labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Kate tarjoushinnasta")),
                    testId: "show-economic-margin-control"
                }), TacoCheckbox.component()()({
                    isChecked: props.showHoursColumns,
                    onToggle: TacoCheckbox.NoEvent.create(props.setShowHoursColumns(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))),
                    labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("N\xe4yt\xe4 tunnit")),
                    testId: "show-hours-columns-control"
                }), TacoCheckbox.component()()({
                    isChecked: props.showCostClassColumns,
                    onToggle: TacoCheckbox.NoEvent.create(props.setShowCostClassColumns(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))),
                    labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("N\xe4yt\xe4 panoslajit")),
                    testId: "show-cost-class-columns-control"
                }), TacoButton.component()()({
                    onClick: new Data_Maybe.Just(dispatch(new Actions.GetOfferPageExcelExportRequest({
                        project: props.project,
                        offerRows: Data_Functor.map(Data_Functor.functorArray)(function (v2) {
                            return v2.r;
                        })(props.offerRows),
                        costClasses: props.costClassesWithHash.value,
                        showHoursColumns: props.showHoursColumns,
                        showCostClassColumns: props.showCostClassColumns,
                        showSocialExpensesInCosts: props.showSocialExpensesInCosts
                    }))),
                    text: "Vie Exceliin",
                    icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconFileDownloadV1.value),
                    align: TacoButton_Types.Left.value,
                    testId: "offer-page-excel-export-button"
                }) ]
            } ],
            toggled: v.value0,
            setToggled: v.value1,
            anchorEl: v1.value0,
            align: TacoDropdown_Types.AlignRight.value
        });
        var rightControls = [ rightDropdown, TacoButton.component()()({
            onClickEventHandler: Data_Maybe.Just.create(React_Basic_Events.handler(React_Basic_DOM_Events.currentTarget)(function (target) {
                return function __do() {
                    v.value1(function (s) {
                        return !s;
                    })();
                    return v1.value1(function (v2) {
                        return Data_Maybe.Just.create(HtmlUtils.coerceEventTargetToHTMLElement(target));
                    })();
                };
            })),
            text: Locale.lookup_("actions"),
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconMenuV2.value)
        }) ];
        var controls = Box.box("__OfferPageViewControls")([ new Box.Padding(TacoConstants.S.value), new Box.FlexGrow(1), Box.FlexColumn.value, Box.AlignStretch.value ])([ Controls.component()()({
            leftControls: leftControls,
            rightControls: rightControls,
            gap: true,
            bottomGutter: false
        }) ]);
        return controls;
    };
});
var mkVATRows = function (v) {
    return function (summary) {
        var vatPercentage = Data_Maybe.fromMaybe(0.24)(Data_Functor.map(Data_Maybe.functorMaybe)((function () {
            var $234 = Data_Newtype.unwrap();
            return function ($235) {
                return (function (v1) {
                    return v1 / 100.0;
                })($234($235));
            };
        })())(v.projectTaxRate));
        var totalWithRiskAndMarginVAT = summary.r.totalWithRiskAndMargin * vatPercentage;
        var totalWithRiskAndMargin = summary.r.totalWithRiskAndMargin + totalWithRiskAndMarginVAT;
        var mkRow = function (r) {
            return {
                r: r,
                marginWasSet: false,
                overrideWasSet: false,
                totalMarginSet: false,
                totalOverrideSet: false,
                mParams: Data_Maybe.Nothing.value,
                allowEditing: false,
                showTotal: false,
                showMargin: false,
                showOverride: false,
                showEditOverride: false,
                showPercentage: false,
                showHours: false,
                showCostClasses: true
            };
        };
        var getVATAmount = function (r) {
            return {
                costClass: r.costClass,
                socialExpensePrice: r.socialExpensePrice,
                totalPrice: r.totalPrice * vatPercentage
            };
        };
        var finalTotalVAT = summary.r.finalTotal * vatPercentage;
        var vatRow = {
            id: "vat-of-summary",
            description: "ALV (" + (Data_Show.show(Data_Show.showInt)(Data_Int.floor(vatPercentage * 100.0)) + "%)"),
            marginPercentage: 0.0,
            marginAmount: 0.0,
            socialExpensePrice: Data_Maybe.Nothing.value,
            socialExpensePriceWithMarginPercentage: Data_Maybe.Nothing.value,
            totalForClassification: 0.0,
            totalOverride: Data_Maybe.Nothing.value,
            totalWithRiskAndMargin: totalWithRiskAndMarginVAT,
            finalTotal: finalTotalVAT,
            hours: 0.0,
            hourly_rate: 0.0,
            total_price_by_cost_classes: Data_Functor.map(Data_Functor.functorArray)(getVATAmount)(summary.r.total_price_by_cost_classes),
            total_price_by_cost_classes_with_margin: Data_Maybe.Nothing.value,
            percentageOfTotal: 0.0,
            percentageOfFinalTotal: 0.0
        };
        var finalTotal = summary.r.finalTotal + finalTotalVAT;
        var addVATAmount = function (r) {
            return {
                costClass: r.costClass,
                socialExpensePrice: r.socialExpensePrice,
                totalPrice: r.totalPrice + r.totalPrice * vatPercentage
            };
        };
        var totalRow = {
            id: "summary-with-vat",
            description: "Yhteens\xe4",
            marginPercentage: 0.0,
            marginAmount: 0.0,
            socialExpensePrice: Data_Maybe.Nothing.value,
            socialExpensePriceWithMarginPercentage: Data_Maybe.Nothing.value,
            totalForClassification: 0.0,
            totalOverride: Data_Maybe.Nothing.value,
            totalWithRiskAndMargin: totalWithRiskAndMargin,
            finalTotal: finalTotal,
            hours: 0.0,
            hourly_rate: 0.0,
            total_price_by_cost_classes: Data_Functor.map(Data_Functor.functorArray)(addVATAmount)(summary.r.total_price_by_cost_classes),
            total_price_by_cost_classes_with_margin: Data_Maybe.Nothing.value,
            percentageOfTotal: Types_Unit.PercentageOfZeroToOne(Utils.defaultZeroDiv(totalWithRiskAndMargin)(totalWithRiskAndMargin)),
            percentageOfFinalTotal: Types_Unit.PercentageOfZeroToOne(Utils.defaultZeroDiv(finalTotal)(finalTotal))
        };
        return {
            vatRow: mkRow(vatRow),
            totalRow: mkRow(totalRow)
        };
    };
};
var mkSocialExpensesRow = function (costClasses) {
    return function (props) {
        return function (mParams) {
            return function (v) {
                return function (v1) {
                    return function (classificationRowData) {
                        var totalOverrideSet = Data_Maybe.maybe(false)((function () {
                            var $236 = Data_Map_Internal.member(Data_Ord.ordString)(summaryId);
                            return function ($237) {
                                return $236((function (v2) {
                                    return v2.offerOverrides;
                                })($237));
                            };
                        })())(mParams);
                        var totalMarginSet = Data_Maybe.maybe(false)((function () {
                            var $238 = Data_Map_Internal.member(Data_Ord.ordString)(summaryId);
                            return function ($239) {
                                return $238((function (v2) {
                                    return v2.marginPercentages;
                                })($239));
                            };
                        })())(mParams);
                        var _total_price_by_cost_classes_with_margin = function (r) {
                            return Data_Maybe.fromMaybe(Common.emptyArray)(r.r.total_price_by_cost_classes_with_margin);
                        };
                        var _total_price_by_cost_classes = function (r) {
                            return r.r.total_price_by_cost_classes;
                        };
                        var totalPricesByCostClasses = Control_Bind.bindFlipped(Control_Bind.bindArray)(_total_price_by_cost_classes)(classificationRowData);
                        var calculate_total_price_by_cost_classes = function (cc) {
                            var matchCostClassCode = function (r) {
                                return Data_Eq.eq(Types_Newtypes.eqCostClassCode)(r.costClass.costClassCode)(cc.costClassCode);
                            };
                            var matched = Data_Array.filter(matchCostClassCode)(totalPricesByCostClasses);
                            var sumValue = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v2) {
                                return v2.socialExpensePrice;
                            })(matched));
                            return {
                                costClass: cc,
                                totalPrice: sumValue,
                                socialExpensePrice: 0.0
                            };
                        };
                        var total_price_by_cost_classes = Data_Functor.map(Data_Functor.functorArray)(calculate_total_price_by_cost_classes)(costClasses);
                        var _socialExpensePriceWOMargin = function (r) {
                            return r.r.socialExpensePrice;
                        };
                        var totalWithoutMargin = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Array.mapMaybe(_socialExpensePriceWOMargin)(classificationRowData));
                        var _socialExpensePrice = function (r) {
                            return r.r.socialExpensePriceWithMarginPercentage;
                        };
                        var total = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Array.mapMaybe(_socialExpensePrice)(classificationRowData));
                        var calculatedMarginPercentage$prime = (function () {
                            if (props.showEconomicMargin) {
                                return Utils.defaultZeroDiv(total - totalWithoutMargin)(total);
                            };
                            return Utils.defaultZeroDiv(total)(totalWithoutMargin) - 1.0;
                        })();
                        var calculatedMarginAmount = (function () {
                            if (props.showEconomicMargin) {
                                return total - totalWithoutMargin;
                            };
                            return totalWithoutMargin * calculatedMarginPercentage$prime;
                        })();
                        var row = {
                            id: "social_cost",
                            description: "Sosiaalikulut",
                            marginPercentage: calculatedMarginPercentage$prime,
                            marginAmount: calculatedMarginAmount,
                            socialExpensePrice: Data_Maybe.Nothing.value,
                            socialExpensePriceWithMarginPercentage: Data_Maybe.Nothing.value,
                            totalForClassification: totalWithoutMargin,
                            totalWithRiskAndMargin: total,
                            totalOverride: Data_Maybe.Nothing.value,
                            finalTotal: total,
                            hours: 0.0,
                            hourly_rate: 0.0,
                            total_price_by_cost_classes: total_price_by_cost_classes,
                            total_price_by_cost_classes_with_margin: Data_Maybe.Nothing.value,
                            percentageOfTotal: 0.0,
                            percentageOfFinalTotal: 0.0
                        };
                        return {
                            r: row,
                            marginWasSet: false,
                            overrideWasSet: false,
                            totalMarginSet: totalMarginSet,
                            totalOverrideSet: totalOverrideSet,
                            mParams: mParams,
                            allowEditing: false,
                            showTotal: true,
                            showMargin: true,
                            showOverride: false,
                            showEditOverride: false,
                            showPercentage: true,
                            showHours: false,
                            showCostClasses: true
                        };
                    };
                };
            };
        };
    };
};
var mkOfferTableRow = function (props) {
    return function (v) {
        return function (r) {
            return {
                columns: offerTableColumns(props),
                rowKey: r.r.id,
                onClick: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit))),
                className: "",
                foldingElement: Data_Maybe.Nothing.value,
                rowData: r
            };
        };
    };
};
var mkOfferRowsSummaryWithoutSocialExpenses = function (props) {
    return function (mParams) {
        return function (xs) {
            var totalOverrideSet = Data_Maybe.maybe(false)((function () {
                var $240 = Data_Map_Internal.member(Data_Ord.ordString)(summaryId);
                return function ($241) {
                    return $240((function (v) {
                        return v.offerOverrides;
                    })($241));
                };
            })())(mParams);
            var totalMarginSet = Data_Maybe.maybe(false)((function () {
                var $242 = Data_Map_Internal.member(Data_Ord.ordString)(summaryId);
                return function ($243) {
                    return $242((function (v) {
                        return v.marginPercentages;
                    })($243));
                };
            })())(mParams);
            var mTotalMarginPercentage = Control_Bind.bind(Data_Maybe.bindMaybe)(mParams)(function (x) {
                return Data_Map_Internal.lookup(Data_Ord.ordString)(summaryId)(x.marginPercentages);
            });
            var marginPercentage = Data_Maybe.fromMaybe(0.0)(mTotalMarginPercentage);
            var totalWithRiskAndMargin = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
                return v.r.totalWithRiskAndMargin;
            })(xs));
            var totalForClassification = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
                return v.r.totalForClassification;
            })(xs));
            var hours = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
                return v.r.hours;
            })(xs));
            var finalTotal = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
                return v.r.finalTotal;
            })(xs));
            var calculatedMarginAmount = (function () {
                var $150 = props.showEconomicMargin && marginPercentage !== 0.0;
                if ($150) {
                    return finalTotal - totalForClassification;
                };
                return totalForClassification * marginPercentage;
            })();
            var allTotalPricesByCostClasses = Control_Bind.bindFlipped(Control_Bind.bindArray)(function (v) {
                return v.r.total_price_by_cost_classes;
            })(xs);
            var total_price_by_cost_classes = (function () {
                var v = Data_Array.head(xs);
                if (v instanceof Data_Maybe.Nothing) {
                    return Common.emptyArray;
                };
                if (v instanceof Data_Maybe.Just) {
                    return Data_Function.flip(Data_Functor.map(Data_Functor.functorArray))(v.value0.r.total_price_by_cost_classes)(function (v1) {
                        var matchedTotalPricesByCostClasses = Data_Function.flip(Data_Array.filter)(allTotalPricesByCostClasses)(function (r) {
                            return Data_Eq.eq(Types_Newtypes.eqCostClassCode)(r.costClass.costClassCode)(v1.costClass.costClassCode);
                        });
                        var socialExpensePrices = Data_Functor.map(Data_Functor.functorArray)(function (v2) {
                            return v2.socialExpensePrice;
                        })(matchedTotalPricesByCostClasses);
                        var socialExpensePrice = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(socialExpensePrices);
                        var totalPrices = Data_Functor.map(Data_Functor.functorArray)(function (v2) {
                            return v2.totalPrice;
                        })(matchedTotalPricesByCostClasses);
                        var totalPrice = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(totalPrices);
                        return {
                            costClass: v1.costClass,
                            totalPrice: totalPrice,
                            socialExpensePrice: socialExpensePrice
                        };
                    });
                };
                throw new Error("Failed pattern match at OfferPageView (line 596, column 7 - line 606, column 56): " + [ v.constructor.name ]);
            })();
            var hourly_pricing_cost_classes = Data_Array.filter(function (v) {
                return v.costClass.hourlyPricing;
            })(total_price_by_cost_classes);
            var hourly_rate = Utils.defaultZeroDiv(Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
                return v.totalPrice;
            })(hourly_pricing_cost_classes)))(hours);
            var row = {
                id: "summary-without-social-expenses",
                description: "Yhteens\xe4",
                marginPercentage: marginPercentage,
                marginAmount: calculatedMarginAmount,
                socialExpensePrice: Data_Maybe.Nothing.value,
                socialExpensePriceWithMarginPercentage: Data_Maybe.Nothing.value,
                totalForClassification: totalForClassification,
                totalOverride: Data_Maybe.Nothing.value,
                totalWithRiskAndMargin: totalWithRiskAndMargin,
                finalTotal: finalTotal,
                hours: hours,
                hourly_rate: hourly_rate,
                total_price_by_cost_classes: total_price_by_cost_classes,
                total_price_by_cost_classes_with_margin: Data_Maybe.Nothing.value,
                percentageOfTotal: Types_Unit.PercentageOfZeroToOne(Utils.defaultZeroDiv(totalWithRiskAndMargin)(totalWithRiskAndMargin)),
                percentageOfFinalTotal: Types_Unit.PercentageOfZeroToOne(Utils.defaultZeroDiv(finalTotal)(finalTotal))
            };
            return {
                r: row,
                marginWasSet: false,
                overrideWasSet: false,
                totalMarginSet: totalMarginSet,
                totalOverrideSet: totalOverrideSet,
                mParams: Data_Maybe.Nothing.value,
                allowEditing: false,
                showTotal: true,
                showMargin: true,
                showOverride: false,
                showEditOverride: false,
                showPercentage: true,
                showHours: true,
                showCostClasses: true
            };
        };
    };
};
var mkOfferRowsSummary = function (props) {
    return function (mParams) {
        return function (marginPercentages) {
            return function (overrides) {
                return function (xs) {
                    var totalOverrideSet = Data_Maybe.maybe(false)((function () {
                        var $244 = Data_Map_Internal.member(Data_Ord.ordString)(summaryId);
                        return function ($245) {
                            return $244((function (v) {
                                return v.offerOverrides;
                            })($245));
                        };
                    })())(mParams);
                    var totalMarginSet = Data_Maybe.maybe(false)((function () {
                        var $246 = Data_Map_Internal.member(Data_Ord.ordString)(summaryId);
                        return function ($247) {
                            return $246((function (v) {
                                return v.marginPercentages;
                            })($247));
                        };
                    })())(mParams);
                    var mMarginPercentage = Data_Map_Internal.lookup(Data_Ord.ordString)(summaryId)(marginPercentages);
                    var marginPercentage = Data_Maybe.fromMaybe(0.0)(mMarginPercentage);
                    var totalWithRiskAndMargin = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
                        return v.r.totalWithRiskAndMargin;
                    })(xs));
                    var totalOverride = Data_Map_Internal.lookup(Data_Ord.ordString)(summaryId)(overrides);
                    var totalForClassification = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
                        return v.r.totalForClassification;
                    })(xs));
                    var hours = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
                        return v.r.hours;
                    })(xs));
                    var finalTotal$prime = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
                        return v.r.finalTotal;
                    })(xs));
                    var finalTotal = (function () {
                        if (totalOverride instanceof Data_Maybe.Nothing) {
                            return finalTotal$prime;
                        };
                        if (totalOverride instanceof Data_Maybe.Just) {
                            return totalOverride.value0;
                        };
                        throw new Error("Failed pattern match at OfferPageView (line 745, column 18 - line 747, column 18): " + [ totalOverride.constructor.name ]);
                    })();
                    var calculatedMarginPercentage$prime = (function () {
                        if (mMarginPercentage instanceof Data_Maybe.Just) {
                            return mMarginPercentage.value0;
                        };
                        if (mMarginPercentage instanceof Data_Maybe.Nothing) {
                            return Types_Unit.PercentageOfZeroToOne((function () {
                                if (props.showEconomicMargin) {
                                    return Utils.defaultZeroDiv(finalTotal - totalForClassification)(finalTotal);
                                };
                                return Utils.defaultZeroDiv(finalTotal - totalForClassification)(totalForClassification);
                            })());
                        };
                        throw new Error("Failed pattern match at OfferPageView (line 761, column 35 - line 766, column 85): " + [ mMarginPercentage.constructor.name ]);
                    })();
                    var calculatedMarginPercentage = (function () {
                        if (totalOverride instanceof Data_Maybe.Just && totalForClassification !== 0.0) {
                            return Types_Unit.PercentageOfZeroToOne((function () {
                                if (props.showEconomicMargin) {
                                    return Utils.defaultZeroDiv(totalOverride.value0 - totalForClassification)(totalOverride.value0);
                                };
                                return Utils.defaultZeroDiv(totalOverride.value0)(totalForClassification) - 1.0;
                            })());
                        };
                        if (totalOverride instanceof Data_Maybe.Nothing) {
                            return calculatedMarginPercentage$prime;
                        };
                        return calculatedMarginPercentage$prime;
                    })();
                    var calculatedMarginAmount = (function () {
                        if (props.showEconomicMargin) {
                            return finalTotal - totalForClassification;
                        };
                        return totalForClassification * Data_Newtype.unwrap()(calculatedMarginPercentage);
                    })();
                    var allTotalPricesByCostClasses = Control_Bind.bindFlipped(Control_Bind.bindArray)(function (v) {
                        return v.r.total_price_by_cost_classes;
                    })(xs);
                    var total_price_by_cost_classes = (function () {
                        var v = Data_Array.head(xs);
                        if (v instanceof Data_Maybe.Nothing) {
                            return Common.emptyArray;
                        };
                        if (v instanceof Data_Maybe.Just) {
                            return Data_Function.flip(Data_Functor.map(Data_Functor.functorArray))(v.value0.r.total_price_by_cost_classes)(function (v1) {
                                var matchedTotalPricesByCostClasses = Data_Function.flip(Data_Array.filter)(allTotalPricesByCostClasses)(function (r) {
                                    return Data_Eq.eq(Types_Newtypes.eqCostClassCode)(r.costClass.costClassCode)(v1.costClass.costClassCode);
                                });
                                var socialExpensePrices = Data_Functor.map(Data_Functor.functorArray)(function (v2) {
                                    return v2.socialExpensePrice;
                                })(matchedTotalPricesByCostClasses);
                                var socialExpensePrice = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(socialExpensePrices);
                                var totalPrices = Data_Functor.map(Data_Functor.functorArray)(function (v2) {
                                    return v2.totalPrice;
                                })(matchedTotalPricesByCostClasses);
                                var totalPrice = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(totalPrices);
                                return {
                                    costClass: v1.costClass,
                                    totalPrice: totalPrice,
                                    socialExpensePrice: socialExpensePrice
                                };
                            });
                        };
                        throw new Error("Failed pattern match at OfferPageView (line 750, column 7 - line 760, column 56): " + [ v.constructor.name ]);
                    })();
                    var hourly_pricing_cost_classes = Data_Array.filter(function (v) {
                        return v.costClass.hourlyPricing;
                    })(total_price_by_cost_classes);
                    var hourly_rate = Utils.defaultZeroDiv(Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
                        return v.totalPrice;
                    })(hourly_pricing_cost_classes)))(hours);
                    var row = {
                        id: summaryId,
                        description: "Yhteens\xe4 sis. sosiaalikulut",
                        marginPercentage: calculatedMarginPercentage,
                        marginAmount: calculatedMarginAmount,
                        socialExpensePrice: Data_Maybe.Nothing.value,
                        socialExpensePriceWithMarginPercentage: Data_Maybe.Nothing.value,
                        totalForClassification: totalForClassification,
                        totalOverride: totalOverride,
                        totalWithRiskAndMargin: totalWithRiskAndMargin,
                        finalTotal: finalTotal,
                        hours: hours,
                        hourly_rate: hourly_rate,
                        total_price_by_cost_classes: total_price_by_cost_classes,
                        total_price_by_cost_classes_with_margin: Data_Maybe.Nothing.value,
                        percentageOfTotal: Types_Unit.PercentageOfZeroToOne(Utils.defaultZeroDiv(totalWithRiskAndMargin)(totalWithRiskAndMargin)),
                        percentageOfFinalTotal: Types_Unit.PercentageOfZeroToOne(Utils.defaultZeroDiv(finalTotal)(finalTotal))
                    };
                    return {
                        r: row,
                        marginWasSet: Data_Maybe.isJust(mMarginPercentage),
                        overrideWasSet: Data_Maybe.isJust(totalOverride),
                        totalMarginSet: totalMarginSet,
                        totalOverrideSet: totalOverrideSet,
                        mParams: mParams,
                        allowEditing: props.allowEditing,
                        showTotal: true,
                        showMargin: true,
                        showOverride: true,
                        showEditOverride: props.showSocialExpensesInCosts,
                        showPercentage: true,
                        showHours: true,
                        showCostClasses: true
                    };
                };
            };
        };
    };
};
var mkOfferRowData = function (props) {
    return function (mParams) {
        return function (marginPercentages) {
            return function (overrides) {
                return function (v) {
                    var totalOverrideSet = Data_Maybe.maybe(false)((function () {
                        var $248 = Data_Map_Internal.member(Data_Ord.ordString)(summaryId);
                        return function ($249) {
                            return $248((function (v1) {
                                return v1.offerOverrides;
                            })($249));
                        };
                    })())(mParams);
                    var totalMarginSet = Data_Maybe.maybe(false)((function () {
                        var $250 = Data_Map_Internal.member(Data_Ord.ordString)(summaryId);
                        return function ($251) {
                            return $250((function (v1) {
                                return v1.marginPercentages;
                            })($251));
                        };
                    })())(mParams);
                    var mTotalMarginPercentage = Control_Bind.bind(Data_Maybe.bindMaybe)(mParams)(function (x) {
                        return Data_Map_Internal.lookup(Data_Ord.ordString)(summaryId)(x.marginPercentages);
                    });
                    var mMarginPercentage = Data_Map_Internal.lookup(Data_Ord.ordString)(v.code)(marginPercentages);
                    var marginPercentage = Data_Maybe.fromMaybe(0.0)(Control_Alt.alt(Data_Maybe.altMaybe)(mMarginPercentage)(mTotalMarginPercentage));
                    var totalWithRiskAndMargin = (function () {
                        if (props.showEconomicMargin) {
                            return Utils.defaultZeroDiv(v.total)(1.0 - marginPercentage);
                        };
                        return v.total + v.total * marginPercentage;
                    })();
                    var totalOverride = Data_Map_Internal.lookup(Data_Ord.ordString)(v.code)(overrides);
                    var finalTotal = (function () {
                        if (totalOverride instanceof Data_Maybe.Nothing) {
                            return totalWithRiskAndMargin;
                        };
                        if (totalOverride instanceof Data_Maybe.Just) {
                            return totalOverride.value0;
                        };
                        throw new Error("Failed pattern match at OfferPageView (line 442, column 18 - line 444, column 18): " + [ totalOverride.constructor.name ]);
                    })();
                    var calculatedMarginPercentage$prime = (function () {
                        if (totalOverride instanceof Data_Maybe.Nothing) {
                            return marginPercentage;
                        };
                        if (totalOverride instanceof Data_Maybe.Just && v.total !== 0.0) {
                            if (props.showEconomicMargin) {
                                return Utils.defaultZeroDiv(totalOverride.value0 - v.total)(totalOverride.value0);
                            };
                            return Utils.defaultZeroDiv(totalOverride.value0)(v.total) - 1.0;
                        };
                        return marginPercentage;
                    })();
                    var socialExpensePriceWithMarginPercentage = Data_Maybe.Just.create((function () {
                        if (props.showEconomicMargin) {
                            return -1.0 * Utils.defaultZeroDiv(v.socialExpensePrice)(calculatedMarginPercentage$prime - 1.0);
                        };
                        return v.socialExpensePrice + v.socialExpensePrice * calculatedMarginPercentage$prime;
                    })());
                    var calculatedMarginAmount = (function () {
                        if (props.showEconomicMargin) {
                            return Utils.defaultZeroDiv(calculatedMarginPercentage$prime * v.total)(1.0 - calculatedMarginPercentage$prime);
                        };
                        return v.total * calculatedMarginPercentage$prime;
                    })();
                    var applyMarginToTotalPriceByCostClasses = function (t) {
                        return {
                            totalPrice: (function () {
                                if (props.showEconomicMargin) {
                                    return -1.0 * Utils.defaultZeroDiv(t.totalPrice)(calculatedMarginPercentage$prime - 1.0);
                                };
                                return t.totalPrice * (1.0 + calculatedMarginPercentage$prime);
                            })(),
                            socialExpensePrice: (function () {
                                if (props.showEconomicMargin) {
                                    return -1.0 * Utils.defaultZeroDiv(t.socialExpensePrice)(calculatedMarginPercentage$prime - 1.0);
                                };
                                return t.socialExpensePrice * (1.0 + calculatedMarginPercentage$prime);
                            })(),
                            costClass: t.costClass
                        };
                    };
                    var row = {
                        id: v.code,
                        description: v.code + (" " + v.description),
                        marginPercentage: calculatedMarginPercentage$prime,
                        marginAmount: calculatedMarginAmount,
                        socialExpensePrice: new Data_Maybe.Just(v.socialExpensePrice),
                        socialExpensePriceWithMarginPercentage: socialExpensePriceWithMarginPercentage,
                        totalForClassification: v.total,
                        totalOverride: totalOverride,
                        totalWithRiskAndMargin: totalWithRiskAndMargin,
                        finalTotal: finalTotal,
                        hours: v.hours,
                        hourly_rate: v.hourlyRate,
                        total_price_by_cost_classes: v.totalPriceByCostClasses,
                        total_price_by_cost_classes_with_margin: Data_Maybe.Just.create(Data_Functor.map(Data_Functor.functorArray)(applyMarginToTotalPriceByCostClasses)(v.totalPriceByCostClasses)),
                        percentageOfTotal: 0.0,
                        percentageOfFinalTotal: 0.0
                    };
                    return {
                        r: row,
                        marginWasSet: Data_Maybe.isJust(mMarginPercentage),
                        overrideWasSet: Data_Maybe.isJust(totalOverride),
                        totalMarginSet: totalMarginSet,
                        totalOverrideSet: totalOverrideSet,
                        mParams: mParams,
                        allowEditing: props.allowEditing,
                        showTotal: true,
                        showMargin: true,
                        showOverride: true,
                        showEditOverride: props.showSocialExpensesInCosts,
                        showPercentage: true,
                        showHours: true,
                        showCostClasses: true
                    };
                };
            };
        };
    };
};
var offerPageView = TofuHooks.mkHookComponent("OfferPageView")(function (props) {
    return function __do() {
        var appDispatch = StateHooks.useDispatch();
        var v = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)("new-offer-page")(false)(false)();
        var premiumOfferLicense = UseLicenseFeature.useLicenseFeature(UseLicenseFeature.PremiumOfferPage.value)();
        var premiumOfferPageEnabled = FeatureHooks.useFeatureEnabled("premium-offer-page")();
        var v1 = UseSocialExpensesInCosts.useSocialExpensesInCosts(props.projectId)();
        var v2 = FetchHooks.useCostClassesWithHash(props.projectId)();
        var v3 = FetchHooks.useOfferPageParams(props.projectId)();
        var v4 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)("offerpage-allow-editing")(false)(false)();
        var v5 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)("offerpage-show-hours-columns")(false)(false)();
        var v6 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)("offerpage-show-cc-columns")(false)(false)();
        var updateParams = function (update) {
            if (v3.value0 instanceof Data_Maybe.Just) {
                return appDispatch(new Actions.SetOfferPageParamsRequest({
                    projectId: props.projectId,
                    items: update(v3.value0.value0)
                }));
            };
            if (v3.value0 instanceof Data_Maybe.Nothing) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            };
            throw new Error("Failed pattern match at OfferPageView (line 111, column 7 - line 113, column 29): " + [ v3.value0.constructor.name ]);
        };
        var showEconomicMargin = Data_Maybe.maybe(true)(function (v7) {
            return v7.showEconomicMargin;
        })(v3.value0);
        var setShowEconomicMargin = function (flag) {
            return updateParams(function (params) {
                return {
                    riskPercentages: params.riskPercentages,
                    marginPercentages: params.marginPercentages,
                    offerOverrides: params.offerOverrides,
                    showEconomicMargin: flag
                };
            });
        };
        var offerOverrides = (function () {
            if (v1.showSocialExpensesInCosts) {
                return Data_Maybe.maybe(Data_Map_Internal.empty)(function (v7) {
                    return v7.offerOverrides;
                })(v3.value0);
            };
            return Data_Map_Internal.empty;
        })();
        var marginPercentages = Data_Maybe.maybe(Data_Map_Internal.empty)(function (v7) {
            return v7.marginPercentages;
        })(v3.value0);
        var tableRef = UseNullableRef.useNullableRef();
        var v7 = TacoLoader.useLoading(false)();
        var v8 = UseWorkerData.useWorkerItemsByKey(props.projectId)(function (v8) {
            return v8.workerProjectClassificationDataState;
        })("ProjectClassificationDataState")();
        var v9 = UseWorkerData.useWorkerItemsByKey(props.projectId)(function (v9) {
            return v9.workerUnfilteredSepProjectClassificationDataState;
        })("UnfilteredSepProjectClassificationDataState")();
        var panelProps = CostEstimation_Layout.useCostEstimationLayout({
            leftPanelInitMode: CostEstimationLayout_Types.MinimizedMode.value,
            rightPanelInitMode: CostEstimationLayout_Types.MinimizedMode.value,
            detailsPanelInitMode: CostEstimationLayout_Types.MinimizedMode.value,
            localStorageKey: "OfferPageView"
        })();
        var offerTableProps = {
            updateParams: updateParams,
            showEconomicMargin: showEconomicMargin,
            costClassesWithHash: v2.value0,
            showHoursColumns: v5.value0,
            showCostClassColumns: v6.value0,
            allowEditing: v4.value0,
            showSocialExpensesInCosts: v1.showSocialExpensesInCosts,
            socialExpensePercentagesByCostClass: v1.socialExpensePercentagesByCostClassWithHash.value,
            projectTaxRate: props.project.taxRate
        };
        var newOfferPageComponent = (function () {
            var $199 = premiumOfferPageEnabled && premiumOfferLicense;
            if ($199) {
                return React_Basic.element($foreign.premiumOfferPage)({
                    project: EstimationUtils.projectToTSProject(props.project)
                });
            };
            return React_Basic.element($foreign.newOfferPage)({
                project: EstimationUtils.projectToTSProject(props.project)
            });
        })();
        var initialOfferRows_ = Data_Functor.map(Data_Functor.functorArray)(mkOfferRowData(offerTableProps)(v3.value0)(marginPercentages)(offerOverrides))(v8.value0);
        var summaryWithoutSERow_ = mkOfferRowsSummaryWithoutSocialExpenses(offerTableProps)(v3.value0)(initialOfferRows_);
        var summaryWithoutSERow = (function () {
            if (v1.showSocialExpensesInCosts) {
                return Common.emptyArray;
            };
            return [ summaryWithoutSERow_ ];
        })();
        var initialOfferRowsWithUnfilteredSEP = Data_Functor.map(Data_Functor.functorArray)(mkOfferRowData(offerTableProps)(v3.value0)(marginPercentages)(offerOverrides))(v9.value0);
        var socialExpensesRow_ = mkSocialExpensesRow(v2.value0.value)(offerTableProps)(v3.value0)(marginPercentages)(offerOverrides)(initialOfferRowsWithUnfilteredSEP);
        var initialOfferRows = (function () {
            if (v1.showSocialExpensesInCosts) {
                return initialOfferRows_;
            };
            return Data_Semigroup.append(Data_Semigroup.semigroupArray)(initialOfferRows_)([ socialExpensesRow_ ]);
        })();
        var summaryRow = mkOfferRowsSummary(offerTableProps)(v3.value0)(marginPercentages)(offerOverrides)(initialOfferRows);
        var updatedOfferRows = updateOfferRowRatios({
            summaryRow: summaryRow,
            offerRows: initialOfferRows_
        });
        var updatedSocialExpensesRow = (function () {
            if (v1.showSocialExpensesInCosts) {
                return Common.emptyArray;
            };
            return updateOfferRowRatios({
                summaryRow: summaryRow,
                offerRows: [ socialExpensesRow_ ]
            });
        })();
        var vatRows = mkVATRows(offerTableProps)(summaryRow);
        var offerRows = Data_Semigroup.append(Data_Semigroup.semigroupArray)(updatedOfferRows)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(summaryWithoutSERow)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(updatedSocialExpensesRow)(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ summaryRow ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ vatRows.vatRow ])([ vatRows.totalRow ])))));
        var offerControls = offerPageViewControls({
            showSocialExpensesInCosts: v1.showSocialExpensesInCosts,
            onSocialExpensesToggle: v1.onSocialExpensesToggle,
            projectId: props.projectId,
            project: props.project,
            offerRows: offerRows,
            costClassesWithHash: v2.value0,
            showEconomicMargin: showEconomicMargin,
            setShowEconomicMargin: setShowEconomicMargin,
            showHoursColumns: v5.value0,
            setShowHoursColumns: v5.value1,
            showCostClassColumns: v6.value0,
            setShowCostClassColumns: v6.value1,
            allowEditing: v4.value0,
            setAllowEditing: v4.value1,
            offerOverrides: offerOverrides
        });
        var offerTable = offerTableInstance({
            containerRef: tableRef,
            rows: offerRows,
            columns: offerTableColumns(offerTableProps),
            mkRow: mkOfferTableRow(offerTableProps),
            sortProperty: TacoTable_Types.noSortProperty,
            sortingOrder: TacoTable_Types.Ascending.value,
            rowHeight: TacoTable_Types.RowLarge.value,
            onSort: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit))),
            showHeader: true,
            styleSet: TacoTable_Styles.optimizedTableStyleSet,
            initialScrollRow: Data_Maybe.Nothing.value,
            onResize: function (v10) {
                return function (v11) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                };
            },
            columnSizeOffsets: Data_Maybe.Nothing.value
        });
        var headerRow = offerTableHeaderRow(offerTableProps);
        var oldOfferPageComponent = CostEstimation_Layout.component({
            controls: new Data_Maybe.Just(offerControls),
            controlsLoading: CostEstimationLayout_Types.LoadingFinished.value,
            leftPanel: Data_Maybe.Just.create(CostEstimation_SummariesPanel.component({
                panelMode: panelProps.leftPanelMode,
                getContents: function (v10) {
                    return React_Basic.fragment((function () {
                        var classificationTable = ProjectClassificationTable.projectClassificationTable({
                            selectWithAClassificationCode: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit))),
                            showSocialExpensesInCosts: v1.showSocialExpensesInCosts,
                            projectId: props.projectId,
                            project: props.project,
                            toggleCodeFilter: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit))),
                            toggleNoCodeMatchFilter: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit))),
                            setLoading: v7.setLoading
                        });
                        return [ classificationTable ];
                    })());
                }
            })),
            leftSidebar: Data_Maybe.Just.create(CostEstimation_SummariesSidebar.component({
                panelMode: panelProps.leftPanelMode,
                setPanelMode: panelProps.setLeftPanelMode
            })),
            main: Box.box("__OfferPageViewMain")([ new Box.FlexGrow(1), Box.FlexColumn.value, Box.AlignStretch.value ])([ headerRow, offerTable ]),
            rightPanel: Data_Maybe.Nothing.value,
            rightSidebar: Data_Maybe.Nothing.value,
            details: Data_Maybe.Nothing.value,
            panelProps: {
                leftPanelMode: panelProps.leftPanelMode,
                setLeftPanelMode: panelProps.setLeftPanelMode,
                rightPanelMode: CostEstimationLayout_Types.MinimizedMode.value,
                setRightPanelMode: panelProps.setRightPanelMode,
                detailsPanelMode: panelProps.detailsPanelMode,
                setDetailsPanelMode: panelProps.setDetailsPanelMode
            },
            leftPanelLoading: (function () {
                if (v7.isLoading) {
                    return CostEstimationLayout_Types.LoadingBarDimmedContents.value;
                };
                return CostEstimationLayout_Types.LoadingFinished.value;
            })(),
            mRefPanelMode: Data_Maybe.Nothing.value
        });
        var changeViewButtonText = (function () {
            if (v.value0) {
                return "Vaihda vanhaan tarjouslaskennan k\xe4ytt\xf6liittym\xe4\xe4n t\xe4st\xe4";
            };
            return "Vaihda uuteen tarjouslaskennan k\xe4ytt\xf6liittym\xe4\xe4n t\xe4st\xe4";
        })();
        return React_Basic.fragment([ TacoButton.component()()({
            onClick: Data_Maybe.Just.create(v.value1(function (old) {
                return !old;
            })),
            className: "flex items-center justify-center p-4 border border-light bg-slate-100 m-4",
            buttonStyle: TacoButton_Types.Outlined.value,
            children: new Data_Maybe.Just([ TacoText.component()()({
                text: changeViewButtonText,
                variant: new TacoText_Types.Heading(3)
            }) ])
        }), (function () {
            if (v.value0) {
                return newOfferPageComponent;
            };
            return oldOfferPageComponent;
        })() ]);
    };
});
module.exports = {
    offerPageView: offerPageView,
    offerPageViewControls: offerPageViewControls,
    offerTableInstance: offerTableInstance,
    mkOfferRowData: mkOfferRowData,
    mkSocialExpensesRow: mkSocialExpensesRow,
    updateOfferRowRatios: updateOfferRowRatios,
    mkOfferRowsSummaryWithoutSocialExpenses: mkOfferRowsSummaryWithoutSocialExpenses,
    mkVATRows: mkVATRows,
    summaryId: summaryId,
    mkOfferRowsSummary: mkOfferRowsSummary,
    mkOfferTableRow: mkOfferTableRow,
    offerTableHeaderRow: offerTableHeaderRow,
    offerTableColumns: offerTableColumns,
    removeSummaryEntry: removeSummaryEntry,
    overrideInputWarning: overrideInputWarning,
    newOfferPage: $foreign.newOfferPage,
    premiumOfferPage: $foreign.premiumOfferPage
};
