// These are used in the purescript imports! Some js uses formatHelper.js
// Most of this is from babel which might explain the ugliness.

var React = require("react");
var React2 = _interopRequireDefault(React);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

const currencyFormatter = new Intl.NumberFormat("fi-FI", {
  style: "currency",
  currency: "EUR",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

var formatCurrencyValue = function formatCurrencyValue(value) {
  return currencyFormatter.format(value);
};

var formatCurrencyDecimalValue = function formatCurrencyDecimalValue(value) {
  return formatCurrencyValue(value, 2);
};

var formatCurrencyDifferenceDecimalValue = function formatCurrencyDifferenceDecimalValue(
  value
) {
  return formatCurrencyDifferenceValue(value, 2);
};

function getClassesForNumberSign(value) {
  var isPositive = value > 0;
  var isZero = value === 0;
  var isNegative = value < 0;
  var classes = [
    isNegative && "currency-negative ",
    isZero && "currency-zero ",
    isPositive && "currency-positive ",
  ];
  return classes.filter(isTruthy);

  function isTruthy(x) {
    return !!x;
  }
}

var formatCurrencyDifferenceValue = function formatCurrencyDifferenceValue(
  value
) {
  var fractionDigits =
    arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

  if (value == null) return null;
  var isPositive = value > 0;
  var classes = getClassesForNumberSign(value);
  var prefix = isPositive ? "+" : "";
  return React2.default.createElement(
    "span",
    { className: classes },
    "" + prefix + formatCurrencyValue(value, fractionDigits)
  );
};

var formatPercentageValue = function formatPercentageValue(ratio) {
  var decimals =
    arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;

  if (Number.isNaN(ratio)) {
    return "-";
  } else if (Math.abs(ratio) === Infinity) {
    return "-";
  } else {
    return (ratio * 100).toFixed(decimals) + " %";
  }
};

var formatPercentageValueDigits = function formatPercentageValueDigits(
  ratio,
  decimals
) {
  return formatPercentageValue(ratio, decimals);
};

var formatMaybePercentageValue = function formatMaybePercentageValue(ratio) {
  var decimals =
    arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;

  if (!ratio || Number.isNaN(ratio.value0) || ratio.value0 === undefined) {
    return "-";
  } else if (Math.abs(ratio.value0) === Infinity) {
    return "-";
  } else {
    return (ratio.value0 * 100).toFixed(decimals) + " %";
  }
};

var formatPercentageDifferenceValue = function formatPercentageDifferenceValue(
  ratio,
  decimals
) {
  var isPositive = ratio > 0;
  var prefix = isPositive ? "+" : "";
  return "" + prefix + formatPercentageValue(ratio, decimals);
};

var formatPercentageDifferenceValueJSX = function formatPercentageDifferenceValueJSX(
  ratio,
  decimals
) {
  var classes = getClassesForNumberSign(ratio);
  return React2.default.createElement(
    "span",
    { className: classes },
    formatPercentageValue(ratio, decimals)
  );
};

var formatCurrencyDifferenceAndPercentageValue = function formatCurrencyDifferenceAndPercentageValue(
  value
) {
  if (
    value == null ||
    typeof value !== "object" ||
    value.diff == null ||
    value.margin == null
  )
    return null;
  var isPositive = value.diff > 0;
  var classes = getClassesForNumberSign(value.diff);
  var prefix = isPositive ? "+" : "";
  return React2.default.createElement(
    "span",
    { className: classes },
    "" +
      prefix +
      formatCurrencyValue(value.diff) +
      " (" +
      formatPercentageValue(value.margin) +
      ")"
  );
};

const dateFormatter = new Intl.DateTimeFormat("fi-FI");

var formatDateString = function formatDateString(dateString) {
  if (dateString != null) {
    var date = new Date(dateString);
    if (!isNaN(date.getTime())) {
      return React2.default.createElement(
        "span",
        null,
        dateFormatter.format(date)
      );
    } else {
      return React2.default.createElement("span", null, dateString);
    }
  }
  return React2.default.createElement("span", null);
};

var formatTimestamp = function formatTimestamp(dateString) {
  if (dateString != null) {
    var date = new Date(dateString);
    var options = { hour: "2-digit", minute: "2-digit", second: "2-digit" };
    if (!isNaN(date.getTime())) {
      return React2.default.createElement(
        "span",
        null,
        date.toLocaleDateString("FI-fi", options)
      );
    } else {
      return React2.default.createElement("span", null, dateString);
    }
  }
  return React2.default.createElement("span", null);
};

exports.formatHelper = {
  formatCurrencyValue: formatCurrencyValue,
  formatCurrencyDecimalValue: formatCurrencyDecimalValue,
  formatCurrencyDifferenceValue: formatCurrencyDifferenceValue,
  formatCurrencyDifferenceDecimalValue: formatCurrencyDifferenceDecimalValue,
  formatPercentageValue: formatPercentageValue,
  formatPercentageValueDigits: formatPercentageValueDigits,
  formatMaybePercentageValue: formatMaybePercentageValue,
  formatPercentageDifferenceValue: formatPercentageDifferenceValue,
  formatPercentageDifferenceValueJSX: formatPercentageDifferenceValueJSX,
  formatPercentageDifferenceValueJSX2: formatPercentageDifferenceValueJSX,
  formatCurrencyDifferenceAndPercentageValue: formatCurrencyDifferenceAndPercentageValue,
  formatDateString: formatDateString,
  formatTimestamp: formatTimestamp,
};
