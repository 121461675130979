import { useMutation, UseMutationResult } from "react-query";
import axiosClient from "../../../../axiosConfig";

type EditGroupParams = {
  type: string;
  value: {
    componentId: number;
    editAction: {
      type: string;
      value: {
        group: string;
      };
    };
  }[];
};

export function useEditGroup(
  projectId: number,
  onSave: () => void
): UseMutationResult<{ componentIds: number[] }, unknown, EditGroupParams> {
  return useMutation({
    mutationFn: async (value: EditGroupParams) => {
      const { data } = await axiosClient.post(
        `project/${projectId}/estimationComponentAction`,
        value
      );
      return data;
    },
    onSuccess: () => {
      onSave();
    },
  });
}
