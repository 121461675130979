// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var SuperAdmin_Styles = require("../SuperAdmin.Styles/index.js");
var TacoAutocomplete = require("../TacoAutocomplete/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoChip = require("../TacoChip/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Organization = require("../Types.Organization/index.js");
var newUser = function (email) {
    return function (orgs) {
        return {
            userData: {
                email: Data_String_Common.toLower(email),
                org: Data_Array_NonEmpty.head(orgs),
                superAdmin: false,
                hasSSOLogin: false,
                orgPlan: Types_Organization.Pro.value,
                sendInvitation: true
            },
            orgs: Data_Array_NonEmpty.toArray(orgs)
        };
    };
};
var initEmails = [ "" ];
var component = TofuHooks.mkHookComponent("AddUsers")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState(initEmails)();
        var v2 = TofuHooks.useState([  ])();
        var v3 = TofuHooks.useState(false)();
        var resetForm = function __do() {
            v1.value1(function (v4) {
                return initEmails;
            })();
            return v2.value1(function (v4) {
                return [  ];
            })();
        };
        var orgChip = function (v4) {
            return Box.box("chipWrapper")([ new Box.MarginY(TacoConstants.XXS.value) ])([ TacoChip.component()()({
                contents: [ React_Basic_DOM.text(v4) ],
                onRemove: Data_Maybe.Just.create(v2.value1(function (prevOrgs) {
                    return Data_Array["delete"](Types_Organization.eqOrganization)(v4)(prevOrgs);
                })),
                testId: "org-chip-" + v4
            }) ]);
        };
        var mkEmailInput = function (idx) {
            return function (email) {
                return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                    className: "emailInput",
                    css: SuperAdmin_Styles.emailInputStyles,
                    children: [ TacoInput.stringInput()()(Data_Eq.eqString)({
                        placeholder: "nimi@tocoman.com",
                        value: email,
                        onSave: function (value) {
                            return v1.value1(Data_Array.updateAtIndices(Data_Foldable.foldableArray)([ new Data_Tuple.Tuple(idx, value) ]));
                        },
                        testId: "add-user-email-" + Data_Show.show(Data_Show.showInt)(idx)
                    }) ]
                });
            };
        };
        var createNewUser = (function () {
            var maybeNonEmptyOrgs = Data_Array_NonEmpty.fromArray(v2.value0);
            if (maybeNonEmptyOrgs instanceof Data_Maybe.Just) {
                var addUser = function (email) {
                    return v.appDispatch(new Actions.AddUsersRequest([ newUser(email)(maybeNonEmptyOrgs.value0) ]));
                };
                return function __do() {
                    Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableArray)(addUser)(Data_Array.filter(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_String_Common["null"]))(v1.value0))();
                    resetForm();
                    return v3.value1(function (v4) {
                        return false;
                    })();
                };
            };
            if (maybeNonEmptyOrgs instanceof Data_Maybe.Nothing) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            };
            throw new Error("Failed pattern match at AddUsers (line 140, column 7 - line 147, column 29): " + [ maybeNonEmptyOrgs.constructor.name ]);
        })();
        var modalActions = TacoModal.modalActions({
            contents: [ TacoButton.component()()({
                onClick: Data_Maybe.Just.create(v3.value1(function (v4) {
                    return false;
                })),
                text: "Peruuta",
                testId: "add-user-cancel"
            }), TacoButton.component()()({
                onClick: Data_Maybe.Just.create(createNewUser),
                buttonStyle: TacoButton_Types.Filled.value,
                text: "Luo k\xe4ytt\xe4j\xe4t",
                submit: true,
                testId: "add-user-submit"
            }) ]
        });
        var addToOrganization = Box.box("addToOrgWrapper")([ Box.FlexColumn.value ])([ TacoText.component()()({
            text: "Organisaatiot",
            weight: TacoText_Types.Bold.value,
            variant: new TacoText_Types.Heading(3),
            gutterBottom: true
        }), Box.box("orgChipsContainer")([ Box.FlexColumn.value, Box.AlignStart.value ])([ Box.box("orgChipsList")([ Box.GridColumn.value, new Box.Gap(TacoConstants.XS.value), new Box.PaddingBottom(TacoConstants.M.value) ])(Data_Functor.map(Data_Functor.functorArray)(orgChip)(v2.value0)), TacoAutocomplete.component()()({
            sections: [ {
                text: Data_Maybe.Nothing.value,
                list: Data_Functor.map(Data_Functor.functorArray)(function (v4) {
                    return {
                        id: v4,
                        text: v4
                    };
                })(Data_Array.difference(Types_Organization.eqOrganization)(v.organizations)(v2.value0))
            } ],
            clearButton: false,
            onSelect: function (value) {
                if (value instanceof Data_Maybe.Just) {
                    return v2.value1(function (prev) {
                        return Data_Array.snoc(prev)(value.value0.id);
                    });
                };
                if (value instanceof Data_Maybe.Nothing) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                };
                throw new Error("Failed pattern match at AddUsers (line 71, column 39 - line 73, column 41): " + [ value.constructor.name ]);
            },
            selected: Data_Maybe.Nothing.value,
            placeholder: "Lis\xe4\xe4 organisaatio",
            testId: "add-users-org-dropdown"
        }) ]) ]);
        var addMoreEmailsButton = TacoButton.component()()({
            text: "Lis\xe4\xe4 rivi",
            onClick: new Data_Maybe.Just(v1.value1(Data_Functor.flap(Data_Functor.functorFn)(Data_Array.snoc)(""))),
            title: "Lis\xe4\xe4 useampi k\xe4ytt\xe4j\xe4 kerralla samaan organisaatioon"
        });
        var emailInputs = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "emailInputs",
            css: SuperAdmin_Styles.emailInputsStyles,
            children: [ TacoText.component()()({
                text: "S\xe4hk\xf6posti",
                weight: TacoText_Types.Bold.value,
                variant: new TacoText_Types.Heading(3),
                gutterBottom: true
            }), React_Basic.fragment(Data_Array.mapWithIndex(mkEmailInput)(v1.value0)), addMoreEmailsButton ]
        });
        var modalBody = TacoModal.modalBody({
            contents: [ addToOrganization, emailInputs ]
        });
        return React_Basic_DOM_Generated.div_([ TacoButton.component()()({
            onClick: Data_Maybe.Just.create(v3.value1(function (v4) {
                return true;
            })),
            buttonStyle: TacoButton_Types.Outlined.value,
            text: "+ Luo uudet k\xe4ytt\xe4j\xe4t",
            testId: "add-user-button"
        }), TacoModal.component()()({
            onClose: v3.value1(function (v4) {
                return false;
            }),
            isActive: v3.value0,
            heading: "Luo uudet k\xe4ytt\xe4j\xe4t",
            contents: [ React_Basic_DOM_Generated.form()({
                onSubmit: React_Basic_DOM_Events.capture_(createNewUser),
                children: [ modalBody, modalActions ]
            }) ]
        }) ]);
    };
});
module.exports = {
    component: component
};
