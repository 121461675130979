// Generated by purs version 0.14.5
"use strict";
var Alert_Styles = require("../Alert.Styles/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Effect = require("../Effect/index.js");
var Locale = require("../Locale/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Alert = require("../Types.Alert/index.js");
var Utils = require("../Utils/index.js");
var prepareTextNodes = function (s) {
    var words = Data_String_Common.split(" ")(s);
    var prepareWord = function (word) {
        var $1 = Utils.isLink(word);
        if ($1) {
            return [ React_Basic_DOM_Generated.a()({
                href: word,
                children: [ React_Basic_DOM.text(word) ]
            }) ];
        };
        return [ React_Basic_DOM.text(word) ];
    };
    return Data_Array.intercalate(Data_Monoid.monoidArray)([ React_Basic_DOM.text(" ") ])(Data_Functor.map(Data_Functor.functorArray)(prepareWord)(words));
};
var lvlToStr = function (v) {
    if (v instanceof Types_Alert.AlertError) {
        return "error";
    };
    if (v instanceof Types_Alert.AlertWarning) {
        return "warning";
    };
    if (v instanceof Types_Alert.AlertInfo) {
        return "info";
    };
    throw new Error("Failed pattern match at TacoAlert (line 30, column 1 - line 30, column 33): " + [ v.constructor.name ]);
};
var fetchProjectErrorMsg = Types_Alert.mkAlert(Types_Alert.AlertError.value)(Locale.lookup_("error_loading_projects_please_refresh"));
var extractMsg = function (r) {
    return r.text;
};
var createParagraphs = function (str) {
    var strings = Data_String_Common.split("\x0a")(str);
    var ps = Data_Functor.map(Data_Functor.functorArray)(function (s) {
        return [ React_Basic_DOM_Generated.p_(prepareTextNodes(s)) ];
    })(strings);
    return Data_Array.intercalate(Data_Monoid.monoidArray)([ React_Basic_DOM_Generated.br()({}) ])(ps);
};
var component = TofuHooks.mkHookComponent("Alert")(function (props) {
    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        className: "app-alert app-alert-" + lvlToStr(props.alert.level),
        css: Alert_Styles.alertContainerStyles({
            alert: props.alert
        }),
        children: [ React_Basic_DOM_Generated.div_(createParagraphs(extractMsg(props.alert))), props.fragment ]
    }));
});
module.exports = {
    component: component,
    fetchProjectErrorMsg: fetchProjectErrorMsg
};
