// Generated by purs version 0.14.5
"use strict";
var Control_Category = require("../Control.Category/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_String_Regex = require("../Data.String.Regex/index.js");
var Partial_Unsafe = require("../Partial.Unsafe/index.js");
var unsafeRegex = function (s) {
    return function (f) {
        return Data_Either.either(Partial_Unsafe.unsafeCrashWith)(Control_Category.identity(Control_Category.categoryFn))(Data_String_Regex.regex(s)(f));
    };
};
module.exports = {
    unsafeRegex: unsafeRegex
};
