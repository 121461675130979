import {
  ProjectsIdTargetAssessmentPost422ResponseFromJSON,
  TargetAssessment,
} from "@tocoman/adminet-openapi-client";
import { mapValidationErrorResponseToInvalidTargetAmountCause } from "../mappers/ToInvalidTargetAmountCauseMapper";

export enum AdminetHttpErrors {
  TargetAmountsLockedError = "TargetAmountsLockedError",
  BudgetAlreadyCreatedError = "BudgetAlreadyCreatedError",
}

export const handleTargetAmountError = async (
  response: Response,
  targetAmount: TargetAssessment
): Promise<void> => {
  await handleInline409ResponseIfExists(response);
  await handleInline422ResponseIfExists(response, targetAmount);
  throw new Error("Error occurred while saving costCalculation");
};

const handleInline409ResponseIfExists = async (
  response: Response
): Promise<void> => {
  if (response.status === 409) {
    const jsonResponse = await response.json();
    if (jsonResponse.body.error.code === "projects_target_amounts_locked") {
      const targetAmountsLockedError = new Error(
        jsonResponse.body.error.message
      );
      targetAmountsLockedError.name =
        AdminetHttpErrors.TargetAmountsLockedError;
      throw targetAmountsLockedError;
    } else if (
      jsonResponse.body.error.code === "projects_budget_has_been_created"
    ) {
      const budgetAlreadyCreatedError = new Error(
        jsonResponse.body.error.message
      );
      budgetAlreadyCreatedError.name =
        AdminetHttpErrors.BudgetAlreadyCreatedError;
      throw budgetAlreadyCreatedError;
    }
  }
};

export const handleInline422ResponseIfExists = async (
  response: Response,
  invalidTargetAmount: TargetAssessment
): Promise<void> => {
  if (response.status === 422) {
    const jsonResponse = await response.json();

    if ("validation_errors" in jsonResponse.error) {
      throw mapValidationErrorResponseToInvalidTargetAmountCause(
        ProjectsIdTargetAssessmentPost422ResponseFromJSON(jsonResponse),
        invalidTargetAmount
      );
    }
  }
};
