// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Effect = require("../Effect/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var component = TofuHooks.mkHookComponent("WorkAchievementControls")(function (props) {
    return Control_Applicative.pure(Effect.applicativeEffect)(ReactBasicUtils["div'"]("__WorkAchievementControls")([ TacoCheckbox.component()()({
        className: "toggle-show-prices-with-work-achievement",
        isChecked: props.isWorkAchievementToggled,
        onToggle: (function () {
            if (props.disabled instanceof Data_Maybe.Just) {
                return new TacoCheckbox.Disabled(props.disabled.value0);
            };
            if (props.disabled instanceof Data_Maybe.Nothing) {
                return new TacoCheckbox.NoEvent(props.onToggle);
            };
            throw new Error("Failed pattern match at WorkAchievementControls (line 25, column 23 - line 27, column 61): " + [ props.disabled.constructor.name ]);
        })(),
        labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("N\xe4yt\xe4 ty\xf6saavutus")),
        labelTextWeight: (function () {
            if (props.isWorkAchievementToggled) {
                return TacoText_Types.Bold.value;
            };
            return TacoText_Types.Normal.value;
        })(),
        testId: "toggle-show-prices-with-work-achievement-checkbox"
    }) ]));
});
module.exports = {
    component: component
};
