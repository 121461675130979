// Generated by purs version 0.14.5
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Show = require("../Data.Show/index.js");
var Unedited = (function () {
    function Unedited() {

    };
    Unedited.value = new Unedited();
    return Unedited;
})();
var Edited = (function () {
    function Edited(value0) {
        this.value0 = value0;
    };
    Edited.create = function (value0) {
        return new Edited(value0);
    };
    return Edited;
})();
var showEdited = function (dictShow) {
    return {
        show: function (v) {
            if (v instanceof Unedited) {
                return "Unedited";
            };
            if (v instanceof Edited) {
                return "(Edited" + (Data_Show.show(dictShow)(v.value0) + ")");
            };
            throw new Error("Failed pattern match at Edited (line 14, column 1 - line 16, column 47): " + [ v.constructor.name ]);
        }
    };
};
var isEdited = function (v) {
    if (v instanceof Edited) {
        return true;
    };
    if (v instanceof Unedited) {
        return false;
    };
    throw new Error("Failed pattern match at Edited (line 26, column 1 - line 26, column 42): " + [ v.constructor.name ]);
};
var functorEdited = {
    map: function (v) {
        return function (v1) {
            if (v1 instanceof Unedited) {
                return Unedited.value;
            };
            if (v1 instanceof Edited) {
                return new Edited(v(v1.value0));
            };
            throw new Error("Failed pattern match at Edited (line 17, column 1 - line 19, column 34): " + [ v.constructor.name, v1.constructor.name ]);
        };
    }
};
var fromEdited = function ($$default) {
    return function (ed) {
        if (ed instanceof Unedited) {
            return $$default;
        };
        if (ed instanceof Edited) {
            return ed.value0;
        };
        throw new Error("Failed pattern match at Edited (line 22, column 25 - line 24, column 24): " + [ ed.constructor.name ]);
    };
};
var flattenEdited = fromEdited(Data_Maybe.Nothing.value);
var eqEdited = function (dictEq) {
    return {
        eq: function (x) {
            return function (y) {
                if (x instanceof Unedited && y instanceof Unedited) {
                    return true;
                };
                if (x instanceof Edited && y instanceof Edited) {
                    return Data_Eq.eq(dictEq)(x.value0)(y.value0);
                };
                return false;
            };
        }
    };
};
var editedToMaybe = function (v) {
    if (v instanceof Edited) {
        return new Data_Maybe.Just(v.value0);
    };
    if (v instanceof Unedited) {
        return Data_Maybe.Nothing.value;
    };
    throw new Error("Failed pattern match at Edited (line 30, column 1 - line 30, column 47): " + [ v.constructor.name ]);
};
module.exports = {
    Unedited: Unedited,
    Edited: Edited,
    fromEdited: fromEdited,
    isEdited: isEdited,
    editedToMaybe: editedToMaybe,
    flattenEdited: flattenEdited,
    eqEdited: eqEdited,
    showEdited: showEdited,
    functorEdited: functorEdited
};
