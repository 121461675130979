// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Color = require("../Color/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var StyleProperties = require("../StyleProperties/index.js");
var StyleUtils = require("../StyleUtils/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var separatorStyles = Box.boxStyle([ Box.Style.create(React_Basic_Emotion.css()({
    borderLeft: StyleProperties.border(TacoConstants.BorderSemiTransparent.value)
})), new Box.Position(Box.Relative.value), Box.Height.create(new TacoConstants.CustomSize("24px")), Box.PosTop.create(new TacoConstants.CustomSize("-6px")), Box.MarginBottom.create(new TacoConstants.CustomSize("-12px")) ]);
var linkStyles = React_Basic_Emotion.css()({
    padding: React_Basic_Emotion.unset
});
var iconButtonStyles = Box.boxStyle([ Box.Padding.create(new TacoConstants.CustomSize("5px")) ]);
var getPrimaryColor = function (props) {
    if (props.disabled) {
        return TacoConstants.TextInactive.value;
    };
    if (!props.disabled) {
        return props.color;
    };
    throw new Error("Failed pattern match at TacoButton.Styles (line 37, column 25 - line 39, column 23): " + [ props.disabled.constructor.name ]);
};
var sharedButtonStyles = function (v) {
    var verticalPadding = (function () {
        if (v.size instanceof TacoConstants.M) {
            return new TacoConstants.CustomSize("5px");
        };
        if (v.size instanceof TacoConstants.L) {
            return new TacoConstants.CustomSize("8px");
        };
        return new TacoConstants.CustomSize("5px");
    })();
    var primaryColor = getPrimaryColor(v);
    return Box.boxStyle([ Box.FlexRow.value, new Box.Display(Box.InlineFlex.value), Box.AlignCenter.value, new Box.PaddingX(TacoConstants.M.value), new Box.PaddingY(verticalPadding), Data_Monoid.guard(Box.monoidBoxProp)(v.vertical)(Box.Padding.create(new TacoConstants.CustomSize("11px"))), new Box.ForegroundColor(primaryColor), new Box.BackgroundColor(TacoConstants.Transparent.value), new Box.Border(TacoConstants.BorderTransparent.value), new Box.Cursor(Box.Pointer.value), Box.Style.create(React_Basic_Emotion.css()({
        lineHeight: React_Basic_Emotion.num(1.0),
        outline: React_Basic_Emotion.str("none"),
        transition: React_Basic_Emotion.str("all 0.2s ease-out"),
        justifyContent: React_Basic_Emotion.str(TacoButton_Types.alignToStr(v.align)),
        textAlign: React_Basic_Emotion.str((function () {
            if (v.align instanceof TacoButton_Types.Left) {
                return "left";
            };
            if (v.align instanceof TacoButton_Types.Center) {
                return "center";
            };
            if (v.align instanceof TacoButton_Types.Right) {
                return "right";
            };
            throw new Error("Failed pattern match at TacoButton.Styles (line 125, column 29 - line 128, column 27): " + [ v.align.constructor.name ]);
        })()),
        wordBreak: React_Basic_Emotion.str("break-all")
    })) ]);
};
var buttonBorder = function (color) {
    return React_Basic_Emotion.css()({
        borderRadius: StyleProperties.px(7),
        borderWidth: StyleProperties.px(1),
        borderStyle: React_Basic_Emotion.str("solid"),
        borderColor: StyleProperties.colorScheme(color)
    });
};
var filledStyles = function (props) {
    var primaryColor = getPrimaryColor(props);
    var primaryBrightness = Color.brightness(TacoConstants.color(primaryColor));
    var styles = React_Basic_Emotion.css()({
        color: React_Basic_Emotion.color((function () {
            var $9 = primaryBrightness < 0.7;
            if ($9) {
                return Color.white;
            };
            return TacoConstants.color(TacoConstants.TextPrimary.value);
        })()),
        backgroundColor: StyleProperties.colorScheme(primaryColor)
    });
    return React_Basic_Emotion.merge([ buttonBorder(primaryColor), styles ]);
};
var sidebarStyles = function (props) {
    return function (sidebarStatus) {
        return Box.boxStyle([ Data_Monoid.guard(Data_Monoid.monoidFn(Box.monoidBoxProp))(Data_Eq.eq(TacoButton_Types.eqSidebarStatus)(sidebarStatus)(TacoButton_Types.SidebarActive.value))(Box.Style.create)(filledStyles(props)), Box.BorderRadius.create(new TacoConstants.CustomSize("0px")) ]);
    };
};
var outlinedStyles = function (props) {
    var primaryColor = getPrimaryColor(props);
    return buttonBorder(primaryColor);
};
var activeStyles = function (v) {
    if (v.disabled) {
        return React_Basic_Emotion.css()({
            cursor: React_Basic_Emotion.str("not-allowed !important")
        });
    };
    var primaryColor = getPrimaryColor(v);
    var modifier = (function () {
        if (v.buttonStyle instanceof TacoButton_Types.Sidebar && v.buttonStyle.value0 instanceof TacoButton_Types.SidebarActive) {
            return StyleUtils.BgModifier.value;
        };
        if (v.buttonStyle instanceof TacoButton_Types.Sidebar && v.buttonStyle.value0 instanceof TacoButton_Types.SidebarInactive) {
            return StyleUtils.FgModifier.value;
        };
        if (v.buttonStyle instanceof TacoButton_Types.Link) {
            return StyleUtils.FgModifier.value;
        };
        return StyleUtils.BgModifier.value;
    })();
    var focusMix = (function () {
        if (v.buttonStyle instanceof TacoButton_Types.Filled) {
            return -0.2;
        };
        if (v.buttonStyle instanceof TacoButton_Types.Sidebar && v.buttonStyle.value0 instanceof TacoButton_Types.SidebarActive) {
            return -0.2;
        };
        if (v.buttonStyle instanceof TacoButton_Types.Sidebar && v.buttonStyle.value0 instanceof TacoButton_Types.SidebarInactive) {
            return 0.3;
        };
        if (v.buttonStyle instanceof TacoButton_Types.Link) {
            return 0.3;
        };
        return 0.8;
    })();
    var activeMix = (function () {
        if (v.buttonStyle instanceof TacoButton_Types.Filled) {
            return 0.1;
        };
        if (v.buttonStyle instanceof TacoButton_Types.Sidebar && v.buttonStyle.value0 instanceof TacoButton_Types.SidebarActive) {
            return 0.1;
        };
        if (v.buttonStyle instanceof TacoButton_Types.Sidebar && v.buttonStyle.value0 instanceof TacoButton_Types.SidebarInactive) {
            return 0.7;
        };
        if (v.buttonStyle instanceof TacoButton_Types.Link) {
            return 0.7;
        };
        return 0.9;
    })();
    return StyleUtils.interactionStyles()()({
        primaryColor: primaryColor,
        modifier: modifier,
        focusMix: focusMix,
        activeMix: activeMix
    });
};
var buttonStyles = function (props) {
    return Box.boxStyle(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ Box.Style.create(sharedButtonStyles(props)), Box.Style.create(activeStyles(props)), Box.Style.create((function () {
        if (props.buttonStyle instanceof TacoButton_Types.Link) {
            return linkStyles;
        };
        if (props.buttonStyle instanceof TacoButton_Types.Filled) {
            return filledStyles(props);
        };
        if (props.buttonStyle instanceof TacoButton_Types.Outlined) {
            return outlinedStyles(props);
        };
        if (props.buttonStyle instanceof TacoButton_Types.Sidebar) {
            return sidebarStyles(props)(props.buttonStyle.value0);
        };
        return Data_Monoid.mempty(React_Basic_Emotion.monoidStyle);
    })()), Data_Monoid.guard(Box.monoidBoxProp)(Data_Maybe.isNothing(props.children) && props.text === "")(new Box.Style(iconButtonStyles)) ])(props.styles));
};
module.exports = {
    buttonBorder: buttonBorder,
    getPrimaryColor: getPrimaryColor,
    outlinedStyles: outlinedStyles,
    filledStyles: filledStyles,
    linkStyles: linkStyles,
    sidebarStyles: sidebarStyles,
    activeStyles: activeStyles,
    iconButtonStyles: iconButtonStyles,
    sharedButtonStyles: sharedButtonStyles,
    buttonStyles: buttonStyles,
    separatorStyles: separatorStyles
};
