// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Data_Array = require("../Data.Array/index.js");
var ScrollContainer = require("../ScrollContainer/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var lorem = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";
var example1 = "import Box (box)\x0aimport Box.CSS (flexDirection)\x0aimport Data.Array as Array\x0aimport ScrollContainer as ScrollContainer\x0aimport TacoText as TacoText\x0aimport TacoText.Types as Text\x0a\x0abox \"scrollContainerExampleWrapper\"\x0a  [ Width $ CustomSize \"300px\"\x0a  , Height $ CustomSize \"300px\"\x0a  , FlexColumn\x0a  ]\x0a  [ ScrollContainer.component\x0a      { children: Array.replicate 10 $ TacoText.component { text: lorem, variant: Text.Paragraph } }\x0a  ]";
var sections = [ {
    title: "Default",
    view: [ Box.box("scrollContainerExamplerapper")([ Box.Width.create(new TacoConstants.CustomSize("300px")), Box.Height.create(new TacoConstants.CustomSize("300px")), Box.FlexColumn.value ])([ ScrollContainer.component({
        children: Data_Array.replicate(10)(TacoText.component()()({
            text: lorem,
            variant: TacoText_Types.Paragraph.value
        }))
    }) ]) ],
    code: example1
} ];
var demoView = {
    sections: sections,
    title: "ScrollContainer",
    description: "A container component that makes its contents scrollable."
};
module.exports = {
    demoView: demoView
};
