// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Context = require("../Context/index.js");
var CostEstimation_Filters = require("../CostEstimation.Filters/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Record = require("../Record/index.js");
var StateHooks = require("../StateHooks/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Project = require("../Types.Project/index.js");
var updateUiStateWith = function (projectId) {
    return function (updateFn) {
        var emptyState = CostEstimation_Filters.mkEmptyEstimationUiState(projectId);
        return function (state) {
            var $4 = Data_Eq.eq(Types_Project.eqProjectId)(state.project)(projectId);
            if ($4) {
                return updateFn(state);
            };
            return updateFn(emptyState);
        };
    };
};
var useEstimationUiState = function (projectId) {
    var emptyState = CostEstimation_Filters.mkEmptyEstimationUiState(projectId);
    return function __do() {
        var appDispatch = StateHooks.useDispatch();
        var v = TofuHooks.useContext(Context.context)();
        var appState = v.getState();
        var currentFilters = (function () {
            if (appState.estimationUiState instanceof Data_Maybe.Just) {
                var $7 = Data_Eq.eq(Types_Project.eqProjectId)(appState.estimationUiState.value0.project)(projectId);
                if ($7) {
                    return appState.estimationUiState.value0;
                };
                return emptyState;
            };
            if (appState.estimationUiState instanceof Data_Maybe.Nothing) {
                return emptyState;
            };
            throw new Error("Failed pattern match at UseEstimationUiState (line 44, column 23 - line 48, column 29): " + [ appState.estimationUiState.constructor.name ]);
        })();
        var updateUiState = function (updateFn) {
            return appDispatch(Actions.SetEstimationUiState.create(updateUiStateWith(projectId)(updateFn)(currentFilters)));
        };
        return new Data_Tuple.Tuple(currentFilters, updateUiState);
    };
};
var toggleUpdateGrouping = function (dictEq) {
    return function (dictIsSymbol) {
        return function (dictCons) {
            return function (field) {
                return function (newVal) {
                    return function (state) {
                        var toggleOrSwitch = function (oldVal) {
                            var $10 = Data_Eq.eq(Data_Maybe.eqMaybe(dictEq))(oldVal)(new Data_Maybe.Just(newVal));
                            if ($10) {
                                return Data_Maybe.Nothing.value;
                            };
                            return new Data_Maybe.Just(newVal);
                        };
                        return Record.modify(dictIsSymbol)()()(field)(toggleOrSwitch)(state);
                    };
                };
            };
        };
    };
};
module.exports = {
    useEstimationUiState: useEstimationUiState,
    toggleUpdateGrouping: toggleUpdateGrouping
};
