// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Client_WASM = require("../Client.WASM/index.js");
var Common = require("../Common/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var CostEstimation_ProjectAutoComplete = require("../CostEstimation.ProjectAutoComplete/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Gap = require("../Gap/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Estimation = require("../Types.Estimation/index.js");
var Types_Project = require("../Types.Project/index.js");
var UseUpdateOnChange = require("../UseUpdateOnChange/index.js");
var CopyElementsToAnotherProject = (function () {
    function CopyElementsToAnotherProject(value0) {
        this.value0 = value0;
    };
    CopyElementsToAnotherProject.create = function (value0) {
        return new CopyElementsToAnotherProject(value0);
    };
    return CopyElementsToAnotherProject;
})();
var eqCopyProjectElementsOptions = {
    eq: function (x) {
        return function (y) {
            return x.value0.addWildcardLocation === y.value0.addWildcardLocation && x.value0.copyResources === y.value0.copyResources;
        };
    }
};
var copyProjectElementsModal = TofuHooks.mkHookComponent("CopyProjectElementsModal")(function (props) {
    return function __do() {
        var dispatch = StateHooks.useDispatch();
        var v = Client_WASM.useEstimationProjects();
        var projectList = Data_Array.filter((function () {
            var $56 = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean)))(Data_Eq.eq(Types_Project.eqProjectId))(props.projectId);
            return function ($57) {
                return $56((function (v1) {
                    return v1.id;
                })($57));
            };
        })())(v.value0);
        var v1 = TofuHooks.useState(new CopyElementsToAnotherProject({
            addWildcardLocation: true,
            copyResources: true
        }))();
        var rowCount = Data_Set.size(props.checkedElements);
        var rowCountText = (function () {
            var $25 = rowCount > 1;
            if ($25) {
                return " rakennetta";
            };
            return " rakenne";
        })();
        var initProject = Data_Array.head(projectList);
        var v2 = TofuHooks.useState(initProject)();
        var v3 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var setTargetProject = TofuHooks.useMemo([ v2.value1 ])(function (v4) {
            return function (update) {
                return function __do() {
                    v3.value1(function (v5) {
                        return Data_Maybe.Nothing.value;
                    })();
                    return v2.value1(update)();
                };
            };
        })();
        var subprojects = Data_Maybe.maybe(Common.emptyArray)(function (v4) {
            return v4.subprojects;
        })(v2.value0);
        UseUpdateOnChange.useUpdateOnChange(Data_Maybe.eqMaybe(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "version";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "subprojects";
            }
        })(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.eqProjectId))()({
            reflectSymbol: function () {
                return "name";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "id";
            }
        })(Types_Project.eqSubprojectId))()({
            reflectSymbol: function () {
                return "countInMeasurementTotal";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "code";
            }
        })(Data_Eq.eqString)))))()({
            reflectSymbol: function () {
                return "state";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "projectGroup";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "programType";
            }
        })(Data_Eq.eqInt))()({
            reflectSymbol: function () {
                return "name";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "isReportingProject";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "id";
            }
        })(Types_Project.eqProjectId))()({
            reflectSymbol: function () {
                return "currency";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "createdDate";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "code";
            }
        })(Types_Project.eqProjectCode))()({
            reflectSymbol: function () {
                return "applicationMode";
            }
        })(Data_Eq.eqString))))(initProject)(setTargetProject)();
        UseUpdateOnChange.useUpdateOnChange(Data_Maybe.eqMaybe(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.eqProjectId))()({
            reflectSymbol: function () {
                return "name";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "id";
            }
        })(Types_Project.eqSubprojectId))()({
            reflectSymbol: function () {
                return "countInMeasurementTotal";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "code";
            }
        })(Data_Eq.eqString))))(Data_Maybe.Nothing.value)(v3.value1)();
        var projectAndSubprojectSelection = (function () {
            var subprojectTacoAutocomplete = CostEstimation_ProjectAutoComplete.mkSubprojectTacoAutocomplete({
                subprojects: subprojects,
                targetSubproject: v3.value0,
                setTargetSubproject: v3.value1
            });
            var subprojectSelect = ReactBasicUtils["span'"]("select-target-subproject-btn")([ subprojectTacoAutocomplete ]);
            var projectTacoAutocomplete = CostEstimation_ProjectAutoComplete.mkProjectTacoAutocomplete({
                projectList: projectList,
                setTargetProject: setTargetProject,
                targetProject: v2.value0
            });
            return ReactBasicUtils["div'"]("sub-project-selection")([ TacoText.component()()({
                text: "2. Valitse hanke ja alakohde",
                weight: TacoText_Types.Bold.value,
                variant: new TacoText_Types.Heading(4)
            }), TacoText.component()()({
                text: "Kopioidaan rakenteet hankkeen"
            }), projectTacoAutocomplete, TacoText.component()()({
                text: "alakohteelle"
            }), subprojectSelect ]);
        })();
        var mkCopyOperationOption = function (option) {
            return function (selectedCopyElementOption) {
                return function (optionChildren) {
                    return TacoButton.component()()({
                        children: Data_Maybe.Just.create(Control_Applicative.pure(Control_Applicative.applicativeArray)(Gap.gapY(TacoConstants.XS.value)(optionChildren))),
                        disabled: Data_Maybe.isNothing(option),
                        onClick: new Data_Maybe.Just((function () {
                            if (option instanceof Data_Maybe.Just) {
                                return v1.value1(function (v4) {
                                    return option.value0;
                                });
                            };
                            if (option instanceof Data_Maybe.Nothing) {
                                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                            };
                            throw new Error("Failed pattern match at CostEstimation.CopyProjectElements (line 126, column 13 - line 129, column 35): " + [ option.constructor.name ]);
                        })()),
                        buttonStyle: (function () {
                            var $34 = Data_Eq.eq(Data_Maybe.eqMaybe(eqCopyProjectElementsOptions))(new Data_Maybe.Just(selectedCopyElementOption))(option);
                            if ($34) {
                                return TacoButton_Types.Outlined.value;
                            };
                            return TacoButton_Types.Text.value;
                        })(),
                        align: TacoButton_Types.Left.value,
                        testId: "copy-operation-selection-button"
                    });
                };
            };
        };
        var copyOperationSelection = ReactBasicUtils["div'"]("copy-operation-selection")([ TacoText.component()()({
            text: "1. Valitse toimenpide",
            weight: TacoText_Types.Bold.value,
            variant: new TacoText_Types.Heading(4),
            gutterBottom: true
        }), Gap.gapY(TacoConstants.S.value)([ mkCopyOperationOption(Data_Maybe.Just.create(new CopyElementsToAnotherProject({
            copyResources: true,
            addWildcardLocation: true
        })))(v1.value0)([ TacoText.component()()({
            inheritColor: true,
            text: "Kopioi rakenne toiselle hankkeelle",
            weight: TacoText_Types.Bold.value,
            variant: new TacoText_Types.Heading(5)
        }), TacoText.component()()({
            inheritColor: true,
            text: "Kopioi valitut rakenteet toiselle hankkeelle"
        }) ]) ]) ]);
        var modalBody = TacoModal.modalBody({
            contents: [ copyOperationSelection, projectAndSubprojectSelection ]
        });
        var attemptSubmit = (function () {
            var copyOperationAction = function (targetProjectId) {
                return function (targetSubprojectId) {
                    return new Actions.EstimationElementActionRequest({
                        projectId: targetProjectId,
                        params: new Types_Estimation.CopySelectedElements({
                            estimationElementIds: Data_Array.fromFoldable(Data_Set.foldableSet)(props.checkedElements),
                            targetProjectId: targetProjectId,
                            targetSubprojectId: targetSubprojectId,
                            copyOperationType: new Types_Estimation.CopyElementsToNewProject(v1.value0.value0)
                        }),
                        handler: function (v4) {
                            return props.closeCopyProjectElementsModal;
                        }
                    });
                };
            };
            if (v2.value0 instanceof Data_Maybe.Just && v3.value0 instanceof Data_Maybe.Just) {
                return dispatch(copyOperationAction(v2.value0.value0.id)(v3.value0.value0.id));
            };
            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
        })();
        var modalActions = TacoModal.modalActions({
            contents: [ TacoButton.component()()({
                onClick: new Data_Maybe.Just(props.closeCopyProjectElementsModal),
                text: "Peruuta",
                testId: "cancel-copy-project-button"
            }), TacoButton.component()()({
                className: "button primary confirm outlined",
                buttonStyle: TacoButton_Types.Outlined.value,
                disabled: Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "projectId";
                    }
                })(Types_Project.eqProjectId))()({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Project.eqSubprojectId))()({
                    reflectSymbol: function () {
                        return "countInMeasurementTotal";
                    }
                })(Data_Eq.eqBoolean))()({
                    reflectSymbol: function () {
                        return "code";
                    }
                })(Data_Eq.eqString))))(v3.value0)(Data_Maybe.Nothing.value),
                onClick: new Data_Maybe.Just(attemptSubmit),
                text: "Kopioi",
                testId: "copy-project-submit-button"
            }) ]
        });
        return ReactBasicUtils["div'"]("__CopyProjectElements")([ TacoModal.component()()({
            isActive: props.copyProjectElementsModalOpen,
            onClose: props.closeCopyProjectElementsModal,
            headingTextJsx: [ React_Basic_DOM.text("Kopioi "), React_Basic_DOM_Generated.var_([ React_Basic_DOM.text(Data_Show.show(Data_Show.showInt)(rowCount)) ]), React_Basic_DOM.text(rowCountText) ],
            contents: [ modalBody, modalActions ]
        }) ]);
    };
});
module.exports = {
    copyProjectElementsModal: copyProjectElementsModal
};
