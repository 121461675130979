import { FullProjectData } from "../../../Estimation/Report/Report";
import { ParamsForARJS } from "../AllReportParameters";
import { mapC200Data } from "./C200Data";
import * as C200Template from "./C200Template.json";
import { Rdl as ARJS } from "@mescius/activereportsjs/core";
import { AllFilters } from "../AllFilters";
import { AllDataSets } from "../AllDataSets";

export const c200Report = (
  reportData: FullProjectData,
  selectedParameters: ParamsForARJS[],
  selectedDataSources: string[]
): ARJS.Report => {
  const allDataSources = [
    {
      name: "Components",
      result: mapC200Data(reportData),
    },
    {
      name: "ProjectDetails",
      result: reportData?.projectData ?? [],
    },
    {
      name: "SubProjects",
      result: reportData?.subProjects ?? [],
    },
    {
      name: "Locations",
      result: reportData?.locations ?? [],
    },
    {
      name: "CostClasses",
      result: reportData?.costClasses ?? [],
    },
    {
      name: "Elements",
      result: reportData?.elements ?? [],
    },
    {
      name: "Measurements",
      result: reportData?.measurements ?? [],
    },
  ];

  const finalDataSources =
    allDataSources
      .filter((ds) => selectedDataSources.includes(ds.name))
      ?.map(({ name, result }) => ({
        Name: `${name}JSON`,
        ConnectionProperties: {
          DataProvider: "JSONEMBED",
          ConnectString: "jsondata=" + JSON.stringify(result),
        },
      })) ?? [];

  const currencyParameter = {
    AllowBlank: true,
    DataType: "String",
    Name: "currency",
    Hidden: true,
    DefaultValue: { Values: [reportData?.projectData?.currency ?? "EUR"] },
  };

  const reportParameters =
    selectedParameters
      ?.filter((param) => param.Name !== "report")
      .map((param) => {
        if (typeof param.Value === "string") {
          return {
            AllowBlank: true,
            DataType: "String",
            Name: param.Name,
            Hidden: true,
          };
        }
        if (typeof param.Value === "boolean") {
          return {
            DefaultValue: { Values: ["False"] },
            DataType: "Boolean",
            Name: param.Name,
            Hidden: true,
          };
        }
        return {
          AllowBlank: true,
          DataType: "String",
          MultiValue: true,
          Name: param.Name,
          Hidden: true,
        };
      }) ?? [];

  const finalReportParameters = [...reportParameters, currencyParameter];

  const report = C200Template as ARJS.Report;

  const allFilters = AllFilters;
  const allDataSets = AllDataSets;

  const selectedFilters = allFilters
    .filter((filter) => selectedDataSources.includes(filter.DataSourceName))
    .map((filter) => {
      const matchingFilters = filter.Filters.filter((f) =>
        selectedParameters.some((param) => param.Name === f.ParameterName)
      );

      if (matchingFilters.length > 0) {
        return {
          ...filter,
          Filters: matchingFilters,
        };
      } else {
        return null;
      }
    })
    .filter((filter) => filter !== null);

  const selectedDataSets = allDataSets.filter((ds) =>
    selectedDataSources.some((selectedDS) => ds.DataSourceName === selectedDS)
  );

  const finalDataSets = selectedDataSets.flatMap((ds) => {
    const filters = selectedFilters.find(
      (filter) => filter?.DataSourceName === ds.DataSourceName
    );
    const modifiedFilters = filters?.Filters.map(
      ({ ParameterName, ...rest }) => rest
    );
    return ds.DataSets.map((dataSet) => {
      if (dataSet.Name === filters?.DataSourceName) {
        return {
          ...dataSet,
          Query: {
            ...dataSet.Query,
          },
          Filters: modifiedFilters,
        };
      }
      return dataSet;
    });
  });

  return {
    ...report,
    DataSources: finalDataSources,
    ReportParameters: finalReportParameters,
    DataSets: finalDataSets,
  } as ARJS.Report;
};
