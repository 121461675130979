import { Trans, useTranslation } from "react-i18next";
import { Accordion, createTable, Switch, Table } from "@tocoman/ui";
import { useCommonTableProps } from "../../../utils/table";
import { User } from "ts-bindings/User";
import { TabIds, UserRolesData } from "./EditUserRolesModal";
import { EstimationProjectSummary } from "../../../../../ts-bindings/EstimationProjectSummary";
import { useCallback, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";

type PermissionAccordionProps = {
  user: User | null;
  application: TabIds;
};

type PermissionRowTypes = {
  readPermission: boolean;
  writePermission: boolean;
};

type ProjectRow = EstimationProjectSummary & PermissionRowTypes;
type ProjectGroupId = string;
type ProjectGroupRow = {
  id: ProjectGroupId;
  name: string;
} & PermissionRowTypes;

const projectsTable = createTable().setRowType<ProjectRow>();
const projectGroupsTable = createTable().setRowType<ProjectGroupRow>();

export const PermissionAccordion = ({
  user,
  application,
}: PermissionAccordionProps) => {
  const { t } = useTranslation("admin", {
    keyPrefix: `users.edit.form.${application}`,
  });

  const { control, watch, setValue, getValues } = useFormContext<
    UserRolesData
  >();

  const projectRows = getValues(`${application}.project`);
  const projectGroupRows = getValues(`${application}.projectGroup`);

  type PermissionSwitchCell = {
    row: {
      original?: ProjectRow | ProjectGroupRow;
    };
    value: boolean;
  };

  const mkReadPermissionSwitch = useCallback(
    (projectType: "project" | "projectGroup") =>
      function PermissionSwitch(cell: PermissionSwitchCell) {
        const index =
          projectType === "project"
            ? projectRows.findIndex((item) => item.id === cell.row.original?.id)
            : projectGroupRows.findIndex(
                (item) => item.id === cell.row.original?.id
              );

        const watchRead = watch(`${application}.readAll`);
        const watchAppPerm = watch(`${application}.appPermission`);

        return (
          <Controller
            control={control}
            name={`${application}.${projectType}.${index}.readPermission`}
            render={({ field: { value = cell.value, onChange } }) => (
              <Switch
                checked={watchRead ? true : value}
                disabled={!watchAppPerm}
                setChecked={(checked) => {
                  onChange(checked);
                  if (!checked) {
                    setValue(
                      `${application}.${projectType}.${index}.writePermission`,
                      false
                    );
                  }
                }}
              />
            )}
          />
        );
      },
    [control, projectGroupRows, projectRows, application, setValue, watch]
  );

  const mkWritePermissionSwitch = useCallback(
    (projectType: "project" | "projectGroup") =>
      function PermissionSwitch(cell: PermissionSwitchCell) {
        const index =
          projectType === "project"
            ? projectRows.findIndex((item) => item.id === cell.row.original?.id)
            : projectGroupRows.findIndex(
                (item) => item.id === cell.row.original?.id
              );

        const watchWrite = watch(`${application}.writeAll`);
        const watchAppPerm = watch(`${application}.appPermission`);

        return (
          <Controller
            control={control}
            name={`${application}.${projectType}.${index}.writePermission`}
            render={({ field: { value = cell.value, onChange } }) => (
              <Switch
                checked={watchWrite ? true : value}
                disabled={!watchAppPerm}
                setChecked={(checked) => {
                  onChange(checked);
                  if (checked) {
                    setValue(
                      `${application}.${projectType}.${index}.readPermission`,
                      true
                    );
                  }
                }}
              />
            )}
          />
        );
      },
    [control, projectGroupRows, projectRows, application, setValue, watch]
  );

  const projectColumns = useMemo(
    () => [
      projectsTable.createDataColumn("name", {
        header: t`projectsTable.project`,
      }),
      projectsTable.createDataColumn("readPermission", {
        header: t`projectsTable.readPermission`,
        enableColumnFilter: false,
        size: 120,
        cell: mkReadPermissionSwitch("project"),
      }),
      projectsTable.createDataColumn("writePermission", {
        header: t`projectsTable.writePermission`,
        enableColumnFilter: false,
        size: 120,
        cell: mkWritePermissionSwitch("project"),
      }),
      projectsTable.createDataColumn("version", {
        header: t`projectsTable.version`,
      }),
      projectsTable.createDataColumn("code", {
        header: t`projectsTable.code`,
      }),
    ],
    [t, mkReadPermissionSwitch, mkWritePermissionSwitch]
  );

  const projectGroupColumns = useMemo(
    () => [
      projectGroupsTable.createDataColumn("name", {
        header: t`projectGroupsTable.name`,
      }),
      projectGroupsTable.createDataColumn("readPermission", {
        header: t`projectGroupsTable.readPermission`,
        enableColumnFilter: false,
        size: 120,
        cell: mkReadPermissionSwitch("projectGroup"),
      }),
      projectGroupsTable.createDataColumn("writePermission", {
        header: t`projectGroupsTable.writePermission`,
        enableColumnFilter: false,
        size: 120,
        cell: mkWritePermissionSwitch("projectGroup"),
      }),
    ],
    [mkReadPermissionSwitch, mkWritePermissionSwitch, t]
  );
  return (
    <Accordion
      fullWidth
      className="-mx-5 w-[calc(100%+16px*2)]"
      items={[
        {
          title: t`projectsTitle`,
          subtitle: (
            <Trans
              ns="admin"
              i18nKey={t`projectDescription`}
              values={{ user: user?.userData.email }}
            />
          ),
          defaultOpen: true,
          contents: (
            <Table
              {...useCommonTableProps()}
              table={projectsTable}
              data={projectRows}
              rowId={(row: ProjectRow) => row.id.toString()}
              columns={projectColumns}
              className="h-[300px]"
            />
          ),
        },
        {
          title: t`projectGroupsTitle`,
          subtitle: (
            <Trans
              ns="admin"
              i18nKey={t`groupsDescription`}
              values={{ user: user?.userData.email }}
            />
          ),
          contents: (
            <Table
              {...useCommonTableProps()}
              table={projectGroupsTable}
              data={projectGroupRows}
              rowId={(row: ProjectGroupRow) => row.id}
              columns={projectGroupColumns}
              className="h-[300px]"
            />
          ),
        },
      ]}
    />
  );
};
