// Generated by purs version 0.14.5
"use strict";
var AddProject = require("../AddProject/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var EditProject = require("../EditProject/index.js");
var Effect = require("../Effect/index.js");
var ImportEstimationProject = require("../ImportEstimationProject/index.js");
var NarrowLayout = require("../NarrowLayout/index.js");
var ProjectComponents = require("../ProjectComponents/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var AddCostControlProjectPCMode = (function () {
    function AddCostControlProjectPCMode() {

    };
    AddCostControlProjectPCMode.value = new AddCostControlProjectPCMode();
    return AddCostControlProjectPCMode;
})();
var AddEstimationProjectPCMode = (function () {
    function AddEstimationProjectPCMode() {

    };
    AddEstimationProjectPCMode.value = new AddEstimationProjectPCMode();
    return AddEstimationProjectPCMode;
})();
var ImportEstimationProjectPCMode = (function () {
    function ImportEstimationProjectPCMode() {

    };
    ImportEstimationProjectPCMode.value = new ImportEstimationProjectPCMode();
    return ImportEstimationProjectPCMode;
})();
var EditCostControlProjectPCMode = (function () {
    function EditCostControlProjectPCMode(value0) {
        this.value0 = value0;
    };
    EditCostControlProjectPCMode.create = function (value0) {
        return new EditCostControlProjectPCMode(value0);
    };
    return EditCostControlProjectPCMode;
})();
var component = TofuHooks.mkHookComponent("ProjectContainer")(function (props) {
    var childComponent = (function () {
        if (props.mode instanceof AddCostControlProjectPCMode) {
            return AddProject.component({
                projectComponentsMode: ProjectComponents.AddCostControlProjectMode.value
            });
        };
        if (props.mode instanceof AddEstimationProjectPCMode) {
            return AddProject.component({
                projectComponentsMode: ProjectComponents.AddEstimationProjectMode.value
            });
        };
        if (props.mode instanceof ImportEstimationProjectPCMode) {
            return ImportEstimationProject.component({});
        };
        if (props.mode instanceof EditCostControlProjectPCMode) {
            return EditProject.component({
                projectId: props.mode.value0
            });
        };
        throw new Error("Failed pattern match at ProjectContainer (line 31, column 7 - line 35, column 86): " + [ props.mode.constructor.name ]);
    })();
    return Control_Applicative.pure(Effect.applicativeEffect)(NarrowLayout.component()()({
        verticalPadding: true,
        children: [ childComponent ]
    }));
});
module.exports = {
    component: component,
    AddCostControlProjectPCMode: AddCostControlProjectPCMode,
    AddEstimationProjectPCMode: AddEstimationProjectPCMode,
    ImportEstimationProjectPCMode: ImportEstimationProjectPCMode,
    EditCostControlProjectPCMode: EditCostControlProjectPCMode
};
