// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var narrowLayoutContainerStyles = function (v) {
    return Box.boxStyle([ Box.Width.create(new TacoConstants.CustomSize("100%")), new Box.PaddingX(TacoConstants.M.value), Data_Monoid.guard(Box.monoidBoxProp)(v.verticalPadding)(new Box.PaddingY(TacoConstants.M.value)), Data_Monoid.guard(Box.monoidBoxProp)(v.typescriptChildComponent)(Box.Style.create(Box.boxStyle([ Box.FlexColumn.value, Box.AlignCenter.value, Box.Height.create(new TacoConstants.CustomSize("100%")) ]))), Box.Style.create(Box.boxStyle((function () {
        if (v.minWidth instanceof Data_Maybe.Just) {
            return [ new Box.MinWidth(v.minWidth.value0) ];
        };
        if (v.minWidth instanceof Data_Maybe.Nothing) {
            return [  ];
        };
        throw new Error("Failed pattern match at AppLayout.Styles (line 44, column 24 - line 46, column 20): " + [ v.minWidth.constructor.name ]);
    })())), Data_Monoid.guard(Box.monoidBoxProp)(!v.fullWidth)(Box.Style.create(Box.boxStyle([ new Box.Media(Box.TabletL.value, [ new Box.Width(TacoConstants.pageContentWidth), Box.Style.create(React_Basic_Emotion.css()({
        alignSelf: React_Basic_Emotion.str("center")
    })) ]) ]))) ]);
};
var mainViewStyles = Box.boxStyle([ Box.MinHeight.create(new TacoConstants.CustomSize("0px")), Box.FlexColumn.value, new Box.FlexGrow(1), new Box.BackgroundColor(TacoConstants.AppBackground.value) ]);
var appLayoutContainerStyles = Box.boxStyle([ Box.FlexColumn.value, Box.Width.create(new TacoConstants.CustomSize("100vw")), Box.Height.create(new TacoConstants.CustomSize("100vh")), new Box.Overflow(Box.Auto.value, Box.Auto.value), new Box.BackgroundColor(TacoConstants.AppBackground.value) ]);
module.exports = {
    appLayoutContainerStyles: appLayoutContainerStyles,
    mainViewStyles: mainViewStyles,
    narrowLayoutContainerStyles: narrowLayoutContainerStyles
};
