exports.triggerClick = function (id) {
  return function () {
    var ele = document.getElementById(id);
    if (ele) {
      ele.click();
    }
  };
};

exports.getLocationPathName = function () {
  return window.location.pathname;
};

exports.getDOMElementScrollTop = (el) => () => el.scrollTop;

exports.isFocusEventTargetContained = (e) => () => {
  return e.currentTarget.contains(e.relatedTarget);
};

exports.blurActiveElement = () => document.activeElement.blur();

exports.navigateToUrl = (url) => () => {
  window.location.href = url;
};

exports.navigateToUrlInNewTab = (url) => () => {
  window.open(url, "_blank");
};
