// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Effect = require("../Effect/index.js");
var Grid_Styles = require("../Grid.Styles/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var Record = require("../Record/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Flex = (function () {
    function Flex(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    Flex.create = function (value0) {
        return function (value1) {
            return new Flex(value0, value1);
        };
    };
    return Flex;
})();
var Auto = (function () {
    function Auto(value0) {
        this.value0 = value0;
    };
    Auto.create = function (value0) {
        return new Auto(value0);
    };
    return Auto;
})();
var renderGridItem = function (gridItem) {
    if (gridItem instanceof Flex) {
        return gridItem.value1;
    };
    if (gridItem instanceof Auto) {
        return gridItem.value0;
    };
    throw new Error("Failed pattern match at Grid (line 22, column 3 - line 24, column 24): " + [ gridItem.constructor.name ]);
};
var gridItemWidthStr = function (v) {
    if (v instanceof Flex) {
        return Data_Int.toStringAs(Data_Int.decimal)(v.value0) + "fr";
    };
    if (v instanceof Auto) {
        return "1fr";
    };
    throw new Error("Failed pattern match at Grid (line 27, column 20 - line 29, column 18): " + [ v.constructor.name ]);
};
var renderRow = function (boxProps) {
    return function (rowItems) {
        var children = Data_Functor.map(Data_Functor.functorArray)(renderGridItem)(rowItems);
        var widths = Data_Functor.map(Data_Functor.functorArray)(gridItemWidthStr)(rowItems);
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "row",
            css: Grid_Styles.rowStyles(boxProps)(widths),
            children: children
        });
    };
};
var defaultProps = {
    boxProps: [  ],
    contents: [  ]
};
var component = function (dictUnion) {
    return function (dictNub) {
        return function (dictLacks) {
            return function (dictLacks1) {
                return TofuHooks.mkHookComponent("Grid")(function (props) {
                    var v = Record.merge()()(props)(defaultProps);
                    var children = Data_Functor.map(Data_Functor.functorArray)(renderRow(v.boxProps))(v.contents);
                    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                        children: children,
                        className: "__Grid",
                        css: Grid_Styles.gridStyles(v.boxProps)
                    }));
                });
            };
        };
    };
};
var grid = function (boxProps) {
    return function (contents) {
        return component()()()()({
            boxProps: boxProps,
            contents: contents
        });
    };
};
module.exports = {
    Flex: Flex,
    Auto: Auto,
    renderGridItem: renderGridItem,
    gridItemWidthStr: gridItemWidthStr,
    renderRow: renderRow,
    defaultProps: defaultProps,
    component: component,
    grid: grid
};
