// Generated by purs version 0.14.5
"use strict";
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Kishimen = require("../Kishimen/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var ReferenceElementsPanel = (function () {
    function ReferenceElementsPanel() {

    };
    ReferenceElementsPanel.value = new ReferenceElementsPanel();
    return ReferenceElementsPanel;
})();
var ReferenceComponentPanel = (function () {
    function ReferenceComponentPanel() {

    };
    ReferenceComponentPanel.value = new ReferenceComponentPanel();
    return ReferenceComponentPanel;
})();
var ReferenceResourcePanel = (function () {
    function ReferenceResourcePanel() {

    };
    ReferenceResourcePanel.value = new ReferenceResourcePanel();
    return ReferenceResourcePanel;
})();
var MinimizedMode = (function () {
    function MinimizedMode() {

    };
    MinimizedMode.value = new MinimizedMode();
    return MinimizedMode;
})();
var SmallMode = (function () {
    function SmallMode() {

    };
    SmallMode.value = new SmallMode();
    return SmallMode;
})();
var NormalMode = (function () {
    function NormalMode() {

    };
    NormalMode.value = new NormalMode();
    return NormalMode;
})();
var MaximizedMode = (function () {
    function MaximizedMode() {

    };
    MaximizedMode.value = new MaximizedMode();
    return MaximizedMode;
})();
var LoadingFinished = (function () {
    function LoadingFinished() {

    };
    LoadingFinished.value = new LoadingFinished();
    return LoadingFinished;
})();
var LoadingBar = (function () {
    function LoadingBar() {

    };
    LoadingBar.value = new LoadingBar();
    return LoadingBar;
})();
var LoadingBarDimmedContents = (function () {
    function LoadingBarDimmedContents() {

    };
    LoadingBarDimmedContents.value = new LoadingBarDimmedContents();
    return LoadingBarDimmedContents;
})();
var showPanelMode = {
    show: function (v) {
        if (v instanceof MinimizedMode) {
            return "MinimizedMode";
        };
        if (v instanceof SmallMode) {
            return "SmallMode";
        };
        if (v instanceof NormalMode) {
            return "NormalMode";
        };
        if (v instanceof MaximizedMode) {
            return "MaximizedMode";
        };
        throw new Error("Failed pattern match at CostEstimationLayout.Types (line 20, column 1 - line 24, column 39): " + [ v.constructor.name ]);
    }
};
var genericPanelMode = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return MinimizedMode.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return SmallMode.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return NormalMode.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr)) {
            return MaximizedMode.value;
        };
        throw new Error("Failed pattern match at CostEstimationLayout.Types (line 16, column 1 - line 16, column 56): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof MinimizedMode) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof SmallMode) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
        };
        if (x instanceof NormalMode) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
        };
        if (x instanceof MaximizedMode) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)));
        };
        throw new Error("Failed pattern match at CostEstimationLayout.Types (line 16, column 1 - line 16, column 56): " + [ x.constructor.name ]);
    }
};
var tofuJSONPanelMode = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "MaximizedMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "MinimizedMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "NormalMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "SmallMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "MaximizedMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "MinimizedMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "NormalMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "SmallMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))))(genericPanelMode)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "MinimizedMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "SmallMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "NormalMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "MaximizedMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "NormalMode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "MaximizedMode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SmallMode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "NormalMode";
        }
    }))({
        reflectSymbol: function () {
            return "MaximizedMode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "MinimizedMode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SmallMode";
        }
    }))({
        reflectSymbol: function () {
            return "NormalMode";
        }
    }))({
        reflectSymbol: function () {
            return "MaximizedMode";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericPanelMode)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "MinimizedMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "SmallMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "NormalMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "MaximizedMode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "NormalMode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "MaximizedMode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SmallMode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "NormalMode";
        }
    }))({
        reflectSymbol: function () {
            return "MaximizedMode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "MinimizedMode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "SmallMode";
        }
    }))({
        reflectSymbol: function () {
            return "NormalMode";
        }
    }))({
        reflectSymbol: function () {
            return "MaximizedMode";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "MaximizedMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "MinimizedMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "NormalMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "SmallMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "MaximizedMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "MinimizedMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "NormalMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "SmallMode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))))
};
var eqPanelMode = {
    eq: function (x) {
        return function (y) {
            if (x instanceof MinimizedMode && y instanceof MinimizedMode) {
                return true;
            };
            if (x instanceof SmallMode && y instanceof SmallMode) {
                return true;
            };
            if (x instanceof NormalMode && y instanceof NormalMode) {
                return true;
            };
            if (x instanceof MaximizedMode && y instanceof MaximizedMode) {
                return true;
            };
            return false;
        };
    }
};
var eqPanelLoadingIndicator = {
    eq: function (x) {
        return function (y) {
            if (x instanceof LoadingFinished && y instanceof LoadingFinished) {
                return true;
            };
            if (x instanceof LoadingBar && y instanceof LoadingBar) {
                return true;
            };
            if (x instanceof LoadingBarDimmedContents && y instanceof LoadingBarDimmedContents) {
                return true;
            };
            return false;
        };
    }
};
module.exports = {
    MinimizedMode: MinimizedMode,
    SmallMode: SmallMode,
    NormalMode: NormalMode,
    MaximizedMode: MaximizedMode,
    LoadingFinished: LoadingFinished,
    LoadingBar: LoadingBar,
    LoadingBarDimmedContents: LoadingBarDimmedContents,
    ReferenceElementsPanel: ReferenceElementsPanel,
    ReferenceComponentPanel: ReferenceComponentPanel,
    ReferenceResourcePanel: ReferenceResourcePanel,
    eqPanelMode: eqPanelMode,
    genericPanelMode: genericPanelMode,
    tofuJSONPanelMode: tofuJSONPanelMode,
    showPanelMode: showPanelMode,
    eqPanelLoadingIndicator: eqPanelLoadingIndicator
};
