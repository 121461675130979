// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Color = require("../Color/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var Types_Alert = require("../Types.Alert/index.js");
var alertContainerStyles = function (props) {
    return Box.boxStyle([ new Box.Position(Box.Relative.value), new Box.Padding(TacoConstants.M.value), new Box.MarginBottom(TacoConstants.M.value), new Box.BorderRadius(TacoConstants.XXS.value), Box.FlexRow.value, new Box.Style((function () {
        if (props.alert.level instanceof Types_Alert.AlertInfo) {
            return Box.boxStyle([ Box.BackgroundColor.create(TacoConstants.CustomColor.create(Color.rgb(246)(252)(255))), Box.ForegroundColor.create(TacoConstants.CustomColor.create(Color.rgb(74)(144)(226))), Box.Style.create(React_Basic_Emotion.css()({
                border: React_Basic_Emotion.str("1px solid " + "#4a90e2")
            })) ]);
        };
        if (props.alert.level instanceof Types_Alert.AlertWarning) {
            return Box.boxStyle([ Box.BackgroundColor.create(TacoConstants.CustomColor.create(Color.rgb(255)(252)(248))), Box.ForegroundColor.create(TacoConstants.CustomColor.create(Color.rgb(255)(153)(0))), Box.Style.create(React_Basic_Emotion.css()({
                border: React_Basic_Emotion.str("1px solid " + "#ff9900")
            })) ]);
        };
        if (props.alert.level instanceof Types_Alert.AlertError) {
            return Box.boxStyle([ Box.BackgroundColor.create(TacoConstants.CustomColor.create(Color.rgb(255)(223)(226))), Box.ForegroundColor.create(TacoConstants.CustomColor.create(Color.rgb(163)(0)(29))), Box.Style.create(React_Basic_Emotion.css()({
                border: React_Basic_Emotion.str("1px solid " + "#a3001d")
            })) ]);
        };
        throw new Error("Failed pattern match at Alert.Styles (line 22, column 11 - line 40, column 10): " + [ props.alert.level.constructor.name ]);
    })()) ]);
};
module.exports = {
    alertContainerStyles: alertContainerStyles
};
