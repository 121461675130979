// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Actions = require("../Actions/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var RemoteDataUtils = require("../RemoteDataUtils/index.js");
var WasmWorker_WorkerResponse = require("../WasmWorker.WorkerResponse/index.js");
var RemoteDataFetching = (function () {
    function RemoteDataFetching() {

    };
    RemoteDataFetching.value = new RemoteDataFetching();
    return RemoteDataFetching;
})();
var RemoteDataFailure = (function () {
    function RemoteDataFailure(value0) {
        this.value0 = value0;
    };
    RemoteDataFailure.create = function (value0) {
        return new RemoteDataFailure(value0);
    };
    return RemoteDataFailure;
})();
var RemoteDataSuccess = (function () {
    function RemoteDataSuccess(value0) {
        this.value0 = value0;
    };
    RemoteDataSuccess.create = function (value0) {
        return new RemoteDataSuccess(value0);
    };
    return RemoteDataSuccess;
})();
var RemoteDataStandby = (function () {
    function RemoteDataStandby() {

    };
    RemoteDataStandby.value = new RemoteDataStandby();
    return RemoteDataStandby;
})();
var RemoteDataUpdate = (function () {
    function RemoteDataUpdate(value0) {
        this.value0 = value0;
    };
    RemoteDataUpdate.create = function (value0) {
        return new RemoteDataUpdate(value0);
    };
    return RemoteDataUpdate;
})();
var mkReducer = function (initialState) {
    return function (update) {
        return {
            update: update,
            initialState: initialState
        };
    };
};
var mkRemoteDataReducer = function (read) {
    var update = function (v) {
        return function (v1) {
            if (v1 instanceof RemoteDataFetching) {
                return RemoteDataUtils.Fetching.value;
            };
            if (v1 instanceof RemoteDataFailure) {
                return new RemoteDataUtils["Error"](v1.value0);
            };
            if (v1 instanceof RemoteDataSuccess) {
                return new RemoteDataUtils.Fetched(v1.value0);
            };
            if (v1 instanceof RemoteDataStandby) {
                return RemoteDataUtils.Standby.value;
            };
            if (v1 instanceof RemoteDataUpdate) {
                if (v instanceof RemoteDataUtils.Fetched) {
                    return new RemoteDataUtils.Fetched(v1.value0(v.value0));
                };
                return v;
            };
            throw new Error("Failed pattern match at ReducerUtils (line 55, column 5 - line 55, column 43): " + [ v.constructor.name, v1.constructor.name ]);
        };
    };
    var update$prime = function (s) {
        return function (action) {
            var v = read(action);
            if (v instanceof Data_Maybe.Just) {
                return update(s)(v.value0);
            };
            if (v instanceof Data_Maybe.Nothing) {
                return s;
            };
            throw new Error("Failed pattern match at ReducerUtils (line 51, column 24 - line 53, column 19): " + [ v.constructor.name ]);
        };
    };
    return mkReducer(RemoteDataUtils.Standby.value)(update$prime);
};
var mkWasmWorkerReducer = function (key) {
    var update$prime = function (s) {
        return function (action) {
            if (action instanceof Actions.WorkerResponseAction && action.value0 instanceof WasmWorker_WorkerResponse.BundledWorkerStateUpdate) {
                var match = Data_Array.find(function (u$prime) {
                    return u$prime.node.type === key;
                })(action.value0.value0.changed);
                if (match instanceof Data_Maybe.Just) {
                    return Data_Nullable.notNull(match.value0.node.value);
                };
                return s;
            };
            return s;
        };
    };
    var v = Effect_Unsafe.unsafePerformEffect($foreign.setSubscribedWasmState(key));
    return mkReducer(Data_Nullable["null"])(update$prime);
};
module.exports = {
    RemoteDataFetching: RemoteDataFetching,
    RemoteDataFailure: RemoteDataFailure,
    RemoteDataSuccess: RemoteDataSuccess,
    RemoteDataStandby: RemoteDataStandby,
    RemoteDataUpdate: RemoteDataUpdate,
    mkReducer: mkReducer,
    mkRemoteDataReducer: mkRemoteDataReducer,
    mkWasmWorkerReducer: mkWasmWorkerReducer,
    readSubscribedWasmState: $foreign.readSubscribedWasmState
};
