// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var sidebarStyles = Box.boxStyle([ Box.FlexColumn.value ]);
var contentsContainerStyles = Box.boxStyle([ new Box.Selector("& > *", [ new Box.Padding(TacoConstants.S.value) ]), new Box.Overflow(Box.Visible.value, Box.Auto.value), new Box.Selector("&, *", [ Box.MinHeight.create(new TacoConstants.CustomSize("auto")) ]), Box.Width.create(new TacoConstants.CustomSize("100%")) ]);
var containerStyles = Box.boxStyle([ Box.FlexRow.value, Box.Height.create(new TacoConstants.CustomSize("100%")) ]);
module.exports = {
    containerStyles: containerStyles,
    sidebarStyles: sidebarStyles,
    contentsContainerStyles: contentsContainerStyles
};
