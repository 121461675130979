// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var ButtonWithConfirmation = require("../ButtonWithConfirmation/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var EditUser = require("../EditUser/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var StringUtils = require("../StringUtils/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_JSDateString = require("../Types.JSDateString/index.js");
var Types_Organization = require("../Types.Organization/index.js");
var ByEmail = (function () {
    function ByEmail() {

    };
    ByEmail.value = new ByEmail();
    return ByEmail;
})();
var ByOrgs = (function () {
    function ByOrgs() {

    };
    ByOrgs.value = new ByOrgs();
    return ByOrgs;
})();
var ByCreatedAt = (function () {
    function ByCreatedAt() {

    };
    ByCreatedAt.value = new ByCreatedAt();
    return ByCreatedAt;
})();
var BySuperAdmin = (function () {
    function BySuperAdmin() {

    };
    BySuperAdmin.value = new BySuperAdmin();
    return BySuperAdmin;
})();
var toggleInArray = function (dictEq) {
    return function (dictOrd) {
        return function (item) {
            return function (array) {
                var $27 = Data_Array.elem(dictEq)(item)(array);
                if ($27) {
                    return Data_Array["delete"](dictEq)(item)(array);
                };
                return Data_Array.insert(dictOrd)(item)(array);
            };
        };
    };
};
var styleSet = TacoTable_Styles.foldingTableStyleSet;
var getNameListUsersSortProperty = {
    getName: function (v) {
        return "ListUsersSortProperty";
    }
};
var listUsersTableInstance = TacoTable.mkTableSortable(getNameListUsersSortProperty)("ListUsers");
var getListUsersSortingValue = function (v) {
    if (v instanceof ByEmail) {
        return function ($54) {
            return TacoTable_Types.StringValue.create((function (v1) {
                return v1.userData.email;
            })((function (v1) {
                return v1.user;
            })($54)));
        };
    };
    if (v instanceof ByOrgs) {
        var $55 = Data_Show.show(Data_Show.showArray(Types_Organization.showOrganization));
        return function ($56) {
            return TacoTable_Types.StringValue.create($55((function (v1) {
                return v1.organizations;
            })((function (v1) {
                return v1.user;
            })($56))));
        };
    };
    if (v instanceof ByCreatedAt) {
        return function ($57) {
            return TacoTable_Types.StringValue.create(Types_JSDateString.extractMaybeJSDateString((function (v1) {
                return v1.createdAt;
            })((function (v1) {
                return v1.user;
            })($57))));
        };
    };
    if (v instanceof BySuperAdmin) {
        return function ($58) {
            return TacoTable_Types.BooleanValue.create((function (v1) {
                return v1.userData.superAdmin;
            })((function (v1) {
                return v1.user;
            })($58)));
        };
    };
    throw new Error("Failed pattern match at ListUsers (line 188, column 1 - line 188, column 80): " + [ v.constructor.name ]);
};
var eqListUsersSortProperty = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ByEmail && y instanceof ByEmail) {
                return true;
            };
            if (x instanceof ByOrgs && y instanceof ByOrgs) {
                return true;
            };
            if (x instanceof ByCreatedAt && y instanceof ByCreatedAt) {
                return true;
            };
            if (x instanceof BySuperAdmin && y instanceof BySuperAdmin) {
                return true;
            };
            return false;
        };
    }
};
var ordListUsersSortProperty = {
    compare: function (x) {
        return function (y) {
            if (x instanceof ByEmail && y instanceof ByEmail) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByEmail) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByEmail) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByOrgs && y instanceof ByOrgs) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByOrgs) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByOrgs) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByCreatedAt && y instanceof ByCreatedAt) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByCreatedAt) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByCreatedAt) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof BySuperAdmin && y instanceof BySuperAdmin) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at ListUsers (line 178, column 1 - line 178, column 70): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqListUsersSortProperty;
    }
};
var component = TofuHooks.mkHookComponent("ListUsers")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var userToUserRow = function (user) {
            return {
                user: user,
                isSelected: Data_Array.elem(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "userData";
                    }
                })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "superAdmin";
                    }
                })(Data_Eq.eqBoolean))()({
                    reflectSymbol: function () {
                        return "orgPlan";
                    }
                })(Types_Organization.eqPlan))()({
                    reflectSymbol: function () {
                        return "org";
                    }
                })(Types_Organization.eqOrganization))()({
                    reflectSymbol: function () {
                        return "hasSSOLogin";
                    }
                })(Data_Eq.eqBoolean))()({
                    reflectSymbol: function () {
                        return "email";
                    }
                })(Data_Eq.eqString))))()({
                    reflectSymbol: function () {
                        return "organizations";
                    }
                })(Data_Eq.eqArray(Types_Organization.eqOrganization)))()({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Organization.eqUserId))()({
                    reflectSymbol: function () {
                        return "createdAt";
                    }
                })(Data_Maybe.eqMaybe(Types_JSDateString.eqJSDateString))))(user)(v.selectedUsers)
            };
        };
        var toggleSuperadminStatus = function (user) {
            return v.appDispatch(new Actions.SetSuperadmin({
                email: user.email,
                superadmin: !user.superAdmin
            }));
        };
        var superAdminCheckbox = function (user) {
            return TacoCheckbox.component()()({
                className: "superadmin-checkbox",
                isChecked: user.superAdmin,
                onToggle: TacoCheckbox.NoEffect.value,
                labelText: Data_Maybe.Nothing.value
            });
        };
        var orgsButton = function (v2) {
            return TacoButton.component()()({
                text: Data_Array.intercalate(Data_Monoid.monoidString)(", ")(Data_Functor.map(Data_Functor.functorArray)(Data_Newtype.un()(Types_Organization.Organization))(v2.user.organizations)),
                onClick: Data_Maybe.Just.create(v1.value1(function (v3) {
                    return new Data_Maybe.Just(v2.user);
                })),
                testId: "edit-user-" + Data_Newtype.un()(Types_Organization.UserId)(v2.user.id),
                icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconEditV1.value)
            });
        };
        var isTocomanUser = function (user) {
            return StringUtils.endsWith("@tocoman.com")(user.email);
        };
        var toggleSuperadmin = function (user) {
            return ButtonWithConfirmation.component()()({
                buttonText: "Muuta",
                confirmHeadingText: "Oletko varma ett\xe4 haluat vaihtaa superadmin tilaa",
                confirmBody: React_Basic_DOM.text("Olet vaihtamassa k\xe4ytt\xe4j\xe4n " + (user.email + " superadmin-tilaa.")),
                confirmButtonText: "Kyll\xe4",
                disabled: !isTocomanUser(user),
                onAction: toggleSuperadminStatus(user)
            });
        };
        var superAdminControls = function (v2) {
            return Box.box("superAdminControls")([  ])([ superAdminCheckbox(v2.user.userData), toggleSuperadmin(v2.user.userData) ]);
        };
        var filteredUsers = (function () {
            if (v.filter === "") {
                return v.users;
            };
            return Data_Array.filter(function (user) {
                return Data_String_CodeUnits.contains(v.filter)(user.userData.email);
            })(v.users);
        })();
        var userRows = Data_Functor.map(Data_Functor.functorArray)(userToUserRow)(filteredUsers);
        var actionCheckbox = function (v2) {
            return TacoCheckbox.component()()({
                className: "component-action-checkbox",
                isChecked: Data_Array.elem(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "userData";
                    }
                })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "superAdmin";
                    }
                })(Data_Eq.eqBoolean))()({
                    reflectSymbol: function () {
                        return "orgPlan";
                    }
                })(Types_Organization.eqPlan))()({
                    reflectSymbol: function () {
                        return "org";
                    }
                })(Types_Organization.eqOrganization))()({
                    reflectSymbol: function () {
                        return "hasSSOLogin";
                    }
                })(Data_Eq.eqBoolean))()({
                    reflectSymbol: function () {
                        return "email";
                    }
                })(Data_Eq.eqString))))()({
                    reflectSymbol: function () {
                        return "organizations";
                    }
                })(Data_Eq.eqArray(Types_Organization.eqOrganization)))()({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Organization.eqUserId))()({
                    reflectSymbol: function () {
                        return "createdAt";
                    }
                })(Data_Maybe.eqMaybe(Types_JSDateString.eqJSDateString))))(v2.user)(v.selectedUsers),
                onToggle: TacoCheckbox.NoEvent.create(v.setSelectedUsers(function (current) {
                    return toggleInArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                        reflectSymbol: function () {
                            return "userData";
                        }
                    })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                        reflectSymbol: function () {
                            return "superAdmin";
                        }
                    })(Data_Eq.eqBoolean))()({
                        reflectSymbol: function () {
                            return "orgPlan";
                        }
                    })(Types_Organization.eqPlan))()({
                        reflectSymbol: function () {
                            return "org";
                        }
                    })(Types_Organization.eqOrganization))()({
                        reflectSymbol: function () {
                            return "hasSSOLogin";
                        }
                    })(Data_Eq.eqBoolean))()({
                        reflectSymbol: function () {
                            return "email";
                        }
                    })(Data_Eq.eqString))))()({
                        reflectSymbol: function () {
                            return "organizations";
                        }
                    })(Data_Eq.eqArray(Types_Organization.eqOrganization)))()({
                        reflectSymbol: function () {
                            return "id";
                        }
                    })(Types_Organization.eqUserId))()({
                        reflectSymbol: function () {
                            return "createdAt";
                        }
                    })(Data_Maybe.eqMaybe(Types_JSDateString.eqJSDateString))))(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()({
                        reflectSymbol: function () {
                            return "userData";
                        }
                    })(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()({
                        reflectSymbol: function () {
                            return "superAdmin";
                        }
                    })(Data_Ord.ordBoolean))()({
                        reflectSymbol: function () {
                            return "orgPlan";
                        }
                    })(Types_Organization.ordPlan))()({
                        reflectSymbol: function () {
                            return "org";
                        }
                    })(Types_Organization.ordOrganization))()({
                        reflectSymbol: function () {
                            return "hasSSOLogin";
                        }
                    })(Data_Ord.ordBoolean))()({
                        reflectSymbol: function () {
                            return "email";
                        }
                    })(Data_Ord.ordString))))()({
                        reflectSymbol: function () {
                            return "organizations";
                        }
                    })(Data_Ord.ordArray(Types_Organization.ordOrganization)))()({
                        reflectSymbol: function () {
                            return "id";
                        }
                    })(Types_Organization.ordUserId))()({
                        reflectSymbol: function () {
                            return "createdAt";
                        }
                    })(Data_Maybe.ordMaybe(Types_JSDateString.ordJSDateString))))(v2.user)(current);
                })),
                labelText: Data_Maybe.Nothing.value,
                testId: "select-user-" + Data_Newtype.un()(Types_Organization.UserId)(v2.user.id)
            });
        };
        var columns = [ {
            key: "checkbox",
            label: React_Basic_DOM.text(""),
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], actionCheckbox),
            sortProperty: Data_Maybe.Nothing.value,
            width: new TacoTable_Types.Flex(1),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "email",
            label: React_Basic_DOM.text("S\xe4hk\xf6posti"),
            cell: new TacoTable_Types.PlainTextCell(function (v2) {
                return v2.user.userData.email;
            }),
            sortProperty: new Data_Maybe.Just(ByEmail.value),
            width: new TacoTable_Types.Flex(8),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "orgs",
            label: React_Basic_DOM.text("Organisaatiot"),
            cell: new TacoTable_Types.JSXCell([  ], orgsButton),
            sortProperty: new Data_Maybe.Just(ByOrgs.value),
            width: new TacoTable_Types.Flex(8),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "created-at",
            label: React_Basic_DOM.text("Perustettu"),
            cell: TacoTable_Types.PlainTextCell.create(function ($59) {
                return Types_JSDateString.extractMaybeJSDateString((function (v2) {
                    return v2.user.createdAt;
                })($59));
            }),
            sortProperty: new Data_Maybe.Just(ByCreatedAt.value),
            width: new TacoTable_Types.Flex(8),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "superadmin",
            label: React_Basic_DOM.text("Super Admin"),
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], superAdminControls),
            sortProperty: new Data_Maybe.Just(BySuperAdmin.value),
            width: new TacoTable_Types.Flex(8),
            headerJSX: Data_Maybe.Nothing.value
        } ];
        var mkUserRow = function (v2) {
            return {
                rowData: v2,
                rowKey: Data_Show.show(Types_Organization.showUserId)(v2.user.id),
                onClick: function (v3) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                },
                className: "user-row",
                foldingElement: Data_Maybe.Nothing.value,
                columns: columns
            };
        };
        return React_Basic_DOM_Generated.div_([ (function () {
            if (v1.value0 instanceof Data_Maybe.Just) {
                return EditUser.component({
                    appDispatch: v.appDispatch,
                    user: v1.value0.value0,
                    organizations: v.organizations,
                    onClose: v1.value1(function (v2) {
                        return Data_Maybe.Nothing.value;
                    })
                });
            };
            return Data_Monoid.mempty(React_Basic.monoidJSX);
        })(), TacoTable.tableSortable(getNameListUsersSortProperty)(eqListUsersSortProperty)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "user";
            }
        })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "userData";
            }
        })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "superAdmin";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "orgPlan";
            }
        })(Types_Organization.eqPlan))()({
            reflectSymbol: function () {
                return "org";
            }
        })(Types_Organization.eqOrganization))()({
            reflectSymbol: function () {
                return "hasSSOLogin";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "email";
            }
        })(Data_Eq.eqString))))()({
            reflectSymbol: function () {
                return "organizations";
            }
        })(Data_Eq.eqArray(Types_Organization.eqOrganization)))()({
            reflectSymbol: function () {
                return "id";
            }
        })(Types_Organization.eqUserId))()({
            reflectSymbol: function () {
                return "createdAt";
            }
        })(Data_Maybe.eqMaybe(Types_JSDateString.eqJSDateString)))))()({
            reflectSymbol: function () {
                return "isSelected";
            }
        })(Data_Eq.eqBoolean)))(listUsersTableInstance)(new TacoTable_Types.SortingCriteria(TacoTable_Types.Ascending.value, new Data_Maybe.Just(ByEmail.value), getListUsersSortingValue))(Data_Maybe.Nothing.value)({
            rows: Data_Functor.map(Data_Functor.functorArray)(mkUserRow)(userRows),
            columns: columns,
            showHeader: true,
            styleSet: styleSet
        }) ]);
    };
});
module.exports = {
    component: component
};
