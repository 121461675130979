// Generated by purs version 0.14.5
"use strict";
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_List_Lazy_Types = require("../Data.List.Lazy.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Data_Validation_Semigroup = require("../Data.Validation.Semigroup/index.js");
var Foreign = require("../Foreign/index.js");
var FormattingHelpers = require("../FormattingHelpers/index.js");
var Locale = require("../Locale/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var WindowUtils = require("../WindowUtils/index.js");
var promptValue = function (defaultValue) {
    return function (promptMessage) {
        return function (validate) {
            return function (onSuccess) {
                var promptValue$prime = function (dValue) {
                    return function (message) {
                        return function __do() {
                            var input = WindowUtils.prompt(message)(defaultValue)();
                            if (input instanceof Data_Maybe.Nothing) {
                                return Data_Unit.unit;
                            };
                            if (input instanceof Data_Maybe.Just) {
                                return Data_Validation_Semigroup.validation(function (errors) {
                                    return promptValue$prime(dValue)(message + (" " + Data_Foldable.intercalate(Data_List_Lazy_Types.foldableNonEmptyList)(Data_Monoid.monoidString)(", ")(errors)));
                                })(onSuccess)(validate(Data_String_Common.trim(input.value0)))();
                            };
                            throw new Error("Failed pattern match at ColumnWidgets (line 60, column 7 - line 66, column 37): " + [ input.constructor.name ]);
                        };
                    };
                };
                return promptValue$prime(defaultValue)(promptMessage);
            };
        };
    };
};
var stringPrompt = function (defaultValue) {
    return function (placeholderText) {
        return function (promptMessage) {
            return function (validate) {
                return function (onSuccess) {
                    var labelText = (function () {
                        if (defaultValue instanceof Data_Maybe.Nothing) {
                            return Data_Maybe.fromMaybe(Locale.lookup_("edit"))(placeholderText);
                        };
                        if (defaultValue instanceof Data_Maybe.Just) {
                            return defaultValue.value0;
                        };
                        throw new Error("Failed pattern match at ColumnWidgets (line 30, column 17 - line 32, column 22): " + [ defaultValue.constructor.name ]);
                    })();
                    var defaultValue$prime = Data_Maybe.fromMaybe("")(defaultValue);
                    return TacoButton.component()()({
                        onClick: Data_Maybe.Just.create(promptValue(defaultValue$prime)(promptMessage)(validate)(onSuccess)),
                        icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconEditV1.value),
                        text: labelText
                    });
                };
            };
        };
    };
};
var percentPrompt = function (defaultValue) {
    return function (promptMessage) {
        return function (validate) {
            return function (onSuccess) {
                var labelText = (function () {
                    if (defaultValue instanceof Data_Maybe.Nothing) {
                        return Locale.lookup_("edit");
                    };
                    if (defaultValue instanceof Data_Maybe.Just) {
                        return FormattingHelpers.formatHelper.formatPercentageValue(Foreign.unsafeToForeign(defaultValue.value0));
                    };
                    throw new Error("Failed pattern match at ColumnWidgets (line 45, column 17 - line 47, column 78): " + [ defaultValue.constructor.name ]);
                })();
                var defaultValue$prime = Data_Show.show(Data_Show.showNumber)(100.0 * Data_Maybe.fromMaybe(0.0)(defaultValue));
                return TacoButton.component()()({
                    onClick: Data_Maybe.Just.create(promptValue(defaultValue$prime)(promptMessage)(validate)(onSuccess)),
                    icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconEditV1.value),
                    text: labelText
                });
            };
        };
    };
};
module.exports = {
    stringPrompt: stringPrompt,
    percentPrompt: percentPrompt,
    promptValue: promptValue
};
