// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var semigroupJSX = {
    append: function (a) {
        return function (b) {
            return $foreign.fragment([ a, b ]);
        };
    }
};
var provider = function (context) {
    return function (value) {
        return function (children) {
            return $foreign.element($foreign.contextProvider(context))({
                value: value,
                children: children
            });
        };
    };
};
var monoidJSX = {
    mempty: $foreign.empty,
    Semigroup0: function () {
        return semigroupJSX;
    }
};
var consumer = function (context) {
    return function (children) {
        return $foreign.element($foreign.contextConsumer(context))({
            children: children
        });
    };
};
module.exports = {
    provider: provider,
    consumer: consumer,
    semigroupJSX: semigroupJSX,
    monoidJSX: monoidJSX,
    empty: $foreign.empty,
    keyed: $foreign.keyed,
    fragment: $foreign.fragment,
    element: $foreign.element,
    elementKeyed: $foreign.elementKeyed,
    createContext: $foreign.createContext,
    contextProvider: $foreign.contextProvider,
    contextConsumer: $foreign.contextConsumer
};
