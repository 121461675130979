// Generated by purs version 0.14.5
"use strict";
var Common = require("../Common/index.js");
var Context = require("../Context/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var useWasmWorker = function __do() {
    var v = TofuHooks.useContext(Context.context)();
    return v.wasmWorker;
};
var useSelector = function (selector) {
    return function __do() {
        var v = TofuHooks.useState(0)();
        var forceRender = v.value1(function (value) {
            return value + 1 | 0;
        });
        var v1 = TofuHooks.useContext(Context.context)();
        var state = v1.getState();
        var latestSelectedStateRef = TofuHooks.useRef(selector(state))();
        TofuHooks.useEffect([  ])(function __do() {
            var unsubscribe = v1.subscribe(function (v2) {
                return function __do() {
                    var state$prime = v1.getState();
                    var latestSelectedState = React_Basic_Hooks.readRef(latestSelectedStateRef)();
                    var newState = selector(state$prime);
                    return Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean)))(Common.refEquals)(latestSelectedState)(newState))(function __do() {
                        React_Basic_Hooks.writeRef(latestSelectedStateRef)(newState)();
                        return forceRender();
                    })();
                };
            })();
            return unsubscribe;
        })();
        return React_Basic_Hooks.readRef(latestSelectedStateRef)();
    };
};
var useDispatch = function __do() {
    var v = TofuHooks.useContext(Context.context)();
    return v.dispatch;
};
module.exports = {
    useSelector: useSelector,
    useDispatch: useDispatch,
    useWasmWorker: useWasmWorker
};
