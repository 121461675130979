// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var projectListDemo = React_Basic.element($foreign["_projectList"])({});
var onClickFn = function (v) {
    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
};
var buttonDemo = React_Basic.element($foreign.tocoUiButton)({
    label: "Demo button",
    onClick: onClickFn
});
var sections = [ {
    title: "Button",
    view: [ buttonDemo ],
    code: ""
}, {
    title: "ProjectList",
    view: [ projectListDemo ],
    code: ""
} ];
var demoView = {
    sections: sections,
    title: "TSComponentDemo",
    description: "Demonstration of using typescript components from within purescript. See comments in TSComponentDemo.purs."
};
module.exports = {
    demoView: demoView
};
