// Generated by purs version 0.14.5
"use strict";
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Utils = require("../Utils/index.js");
var useUUID = function __do() {
    var id = TofuHooks.useMemo([  ])(function (v) {
        return Effect_Unsafe.unsafePerformEffect(Utils.genv4UUID);
    })();
    return id;
};
module.exports = {
    useUUID: useUUID
};
