import { useEffect, useRef } from "react";

/**
 * Calls given callback when a value has just changed and now equals another
 * comparison value
 */
export const useOnEq = <T>(value: T, comp: T, f: (value: T) => void) => {
  const prev = useRef(value);

  useEffect(() => {
    // Checks for changes to the value
    if (prev.current !== value) {
      // Checks whether values are equal
      if (value === comp) {
        f(value);
      }

      prev.current = value;
    }
  }, [value, comp, f]);
};
