import React, { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { Header, Icon } from "@tocoman/ui";

type AdminetMessageContainerProps = {
  title: string;
  titleParams?: { [key: string]: string };
  message: string;
  messageParams?: { [key: string]: string };
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
};

const messageContainerStyles: CSSProperties = {
  display: "flex",
  alignItems: "center",
  alignContent: "center",
  gap: "20px",
};

export const AdminetMessageContainer = ({
  title,
  titleParams,
  message,
  messageParams,
  icon,
}: AdminetMessageContainerProps) => {
  const { t } = useTranslation("integration");

  return (
    <div className="mt-7 ml-3" style={messageContainerStyles}>
      <Icon scale={2} icon={icon}></Icon>
      <Header titleSize="small" title={t(title, { titleParams })}>
        {t(message, messageParams)}
      </Header>
    </div>
  );
};
