import { useCallback } from "react";
import { useGetAvailableVersionQuery } from "./useGetAvailableVersionQuery";
import { reportError } from "../utils/error";
import { AxiosError } from "axios";

/**
 * Hook to set the next available version for a given project code
 * @param setVersion react-hook-forms setValue function
 * @returns onCodeChange function to be called when the project code field changes/blurs and isLoading boolean
 */
export function useSetNextAvailableVersion(
  setVersion: (field: "version", value: string) => void
): {
  setNextAvailableVersion: (code: string) => void;
  versionLoading: boolean;
} {
  const { isLoading, mutateAsync } = useGetAvailableVersionQuery();

  const setNextAvailableVersion = useCallback(
    async (code: string) => {
      if (code.length <= 12) {
        // 12 is the max length of a project code (Projektitunnus) in the database
        try {
          const { nextVersion } = await mutateAsync(code);
          setVersion("version", String(nextVersion));
        } catch (e) {
          reportError("Error fetching next version", e as AxiosError);
        }
      }
    },
    [mutateAsync, setVersion]
  );

  return { setNextAvailableVersion, versionLoading: isLoading };
}
