// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Show = require("../Data.Show/index.js");
var Effect = require("../Effect/index.js");
var Effect_Ref = require("../Effect.Ref/index.js");
var Routing = require("../Routing/index.js");
var Web_DOM_Document = require("../Web.DOM.Document/index.js");
var Web_DOM_MutationObserver = require("../Web.DOM.MutationObserver/index.js");
var Web_DOM_Node = require("../Web.DOM.Node/index.js");
var Web_DOM_Text = require("../Web.DOM.Text/index.js");
var Web_Event_EventTarget = require("../Web.Event.EventTarget/index.js");
var Web_HTML = require("../Web.HTML/index.js");
var Web_HTML_Event_PopStateEvent_EventTypes = require("../Web.HTML.Event.PopStateEvent.EventTypes/index.js");
var Web_HTML_HTMLDocument = require("../Web.HTML.HTMLDocument/index.js");
var Web_HTML_History = require("../Web.HTML.History/index.js");
var Web_HTML_Location = require("../Web.HTML.Location/index.js");
var Web_HTML_Window = require("../Web.HTML.Window/index.js");
var makeImmediate = function (run) {
    return function __do() {
        var document = Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)((function () {
            var $7 = Data_Functor.map(Effect.functorEffect)(Web_HTML_HTMLDocument.toDocument);
            return function ($8) {
                return $7(Web_HTML_Window.document($8));
            };
        })())();
        var nextTick = Effect_Ref["new"](new Data_Either.Right(0))();
        var obsvNode = Data_Functor.map(Effect.functorEffect)(Web_DOM_Text.toNode)(Web_DOM_Document.createTextNode("")(document))();
        var observer = Web_DOM_MutationObserver.mutationObserver(function (v) {
            return function (v1) {
                return function __do() {
                    Effect_Ref.modify_(Data_Either.either((function () {
                        var $9 = Data_Semiring.add(Data_Semiring.semiringInt)(1);
                        return function ($10) {
                            return Data_Either.Right.create($9($10));
                        };
                    })())(Data_Either.Right.create))(nextTick)();
                    return run();
                };
            };
        })();
        Web_DOM_MutationObserver.observe()(obsvNode)({
            characterData: true
        })(observer)();
        return Control_Bind.bind(Effect.bindEffect)(Effect_Ref.read(nextTick))(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableEither)(function (tick) {
            return function __do() {
                Effect_Ref.write(new Data_Either.Left(tick + 1 | 0))(nextTick)();
                return Web_DOM_Node.setNodeValue(Data_Show.show(Data_Show.showInt)(tick))(obsvNode)();
            };
        }));
    };
};
var makeInterface = function __do() {
    var freshRef = Effect_Ref["new"](0)();
    var listenersRef = Effect_Ref["new"](Data_Map_Internal.empty)();
    var notify = function (ev) {
        return Control_Bind.bindFlipped(Effect.bindEffect)(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Map_Internal.foldableMap)(function (v) {
            return v(ev);
        }))(Effect_Ref.read(listenersRef));
    };
    var locationState = function __do() {
        var loc = Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.location)();
        var state = Control_Bind.bind(Effect.bindEffect)(Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.history))(Web_HTML_History.state)();
        var pathname = Web_HTML_Location.pathname(loc)();
        var search = Web_HTML_Location.search(loc)();
        var hash = Web_HTML_Location.hash(loc)();
        var path = pathname + (search + hash);
        return {
            state: state,
            pathname: pathname,
            search: search,
            hash: hash,
            path: path
        };
    };
    var listen = function (k) {
        return function __do() {
            var fresh = Effect_Ref.read(freshRef)();
            Effect_Ref.write(fresh + 1 | 0)(freshRef)();
            Effect_Ref.modify_(Data_Map_Internal.insert(Data_Ord.ordInt)(fresh)(k))(listenersRef)();
            return Effect_Ref.modify_(Data_Map_Internal["delete"](Data_Ord.ordInt)(fresh))(listenersRef);
        };
    };
    var schedule = makeImmediate(Control_Bind.bindFlipped(Effect.bindEffect)(notify)(locationState))();
    var stateFn = function (op) {
        return function (state) {
            return function (path) {
                return function __do() {
                    Control_Bind.bind(Effect.bindEffect)(Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)(Web_HTML_Window.history))(op(state)("")(path))();
                    return schedule();
                };
            };
        };
    };
    var listener = Web_Event_EventTarget.eventListener(function (v) {
        return Control_Bind.bindFlipped(Effect.bindEffect)(notify)(locationState);
    })();
    Control_Bind.bind(Effect.bindEffect)(Web_HTML.window)((function () {
        var $11 = Web_Event_EventTarget.addEventListener(Web_HTML_Event_PopStateEvent_EventTypes.popstate)(listener)(false);
        return function ($12) {
            return $11(Web_HTML_Window.toEventTarget($12));
        };
    })())();
    return {
        pushState: stateFn(Web_HTML_History.pushState),
        replaceState: stateFn(Web_HTML_History.replaceState),
        locationState: locationState,
        listen: listen
    };
};
var foldLocations = function (cb) {
    return function (init) {
        return function (psi) {
            return function __do() {
                var ref = Control_Bind.bindFlipped(Effect.bindEffect)(Effect_Ref["new"])(Control_Bind.bindFlipped(Effect.bindEffect)(init)(psi.locationState))();
                return psi.listen(function (loc) {
                    return Control_Bind.bindFlipped(Effect.bindEffect)(Data_Function.flip(Effect_Ref.write)(ref))(Control_Bind.bindFlipped(Effect.bindEffect)(Data_Function.flip(cb)(loc))(Effect_Ref.read(ref)));
                })();
            };
        };
    };
};
var foldPaths = function (cb) {
    return function (init) {
        return foldLocations(function (a) {
            var $13 = cb(a);
            return function ($14) {
                return $13((function (v) {
                    return v.path;
                })($14));
            };
        })(function ($15) {
            return init((function (v) {
                return v.path;
            })($15));
        });
    };
};
var matchesWith = function (dictFoldable) {
    return function (parser) {
        return function (cb) {
            var go = function (a) {
                var $16 = Data_Maybe.maybe(Control_Applicative.pure(Effect.applicativeEffect)(a))(function (b) {
                    return Data_Functor.voidRight(Effect.functorEffect)(new Data_Maybe.Just(b))(cb(a)(b));
                });
                var $17 = Data_Foldable.indexl(dictFoldable)(0);
                return function ($18) {
                    return $16($17(parser($18)));
                };
            };
            return foldPaths(go)(go(Data_Maybe.Nothing.value));
        };
    };
};
var matches = (function () {
    var $19 = matchesWith(Data_Foldable.foldableEither);
    return function ($20) {
        return $19(Routing.match($20));
    };
})();
var paths = matchesWith(Data_Foldable.foldableMaybe)(Data_Maybe.Just.create);
var locations = function (cb) {
    var go = function (a) {
        return function (b) {
            return Data_Functor.voidRight(Effect.functorEffect)(new Data_Maybe.Just(b))(cb(a)(b));
        };
    };
    return foldLocations(go)(go(Data_Maybe.Nothing.value));
};
module.exports = {
    makeInterface: makeInterface,
    foldLocations: foldLocations,
    locations: locations,
    foldPaths: foldPaths,
    paths: paths,
    matches: matches,
    matchesWith: matchesWith
};
