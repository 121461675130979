// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Show = require("../Data.Show/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var paginationControls = TofuHooks.mkHookComponent("PaginationControls")(function (props) {
    var statusLabel = React_Basic_DOM_Generated.span()({
        className: "status-label",
        children: [ props.statusLabel ]
    });
    var prevBtn = (function () {
        var disabled = props.currentPageIdx === 0;
        return TacoButton.component()()({
            disabled: disabled,
            onClick: Data_Maybe.Just.create(props.onPageChange(props.currentPageIdx - 1 | 0)),
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconLtV1.value)
        });
    })();
    var nextBtn = (function () {
        var disabled = props.currentPageIdx >= (props.pagesCount - 1 | 0);
        return TacoButton.component()()({
            disabled: disabled,
            onClick: Data_Maybe.Just.create(props.onPageChange(props.currentPageIdx + 1 | 0)),
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconGtV1.value)
        });
    })();
    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_DOM_Generated.div()({
        className: "pagination-controls " + props.className,
        children: [ prevBtn, statusLabel, nextBtn ]
    }));
});
var mkPaginationControls = function (v) {
    var pageCount = Data_Int.ceil(Data_Int.toNumber(v.itemCount) / Data_Int.toNumber(v.pageSize));
    var offset = v.pageIdx * v.pageSize | 0;
    var $2 = pageCount > 1;
    if ($2) {
        return paginationControls({
            className: "",
            currentPageIdx: v.pageIdx,
            pagesCount: pageCount,
            onPageChange: v.onPageChange,
            statusLabel: React_Basic_DOM_Generated.span()({
                className: "current-page-label",
                children: Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM.text(Data_Show.show(Data_Show.showInt)(offset + 1 | 0) + ("-" + (Data_Show.show(Data_Show.showInt)(Data_Ord.min(Data_Ord.ordInt)(offset + v.pageSize | 0)(v.itemCount)) + (" / " + Data_Show.show(Data_Show.showInt)(v.itemCount))))))
            })
        });
    };
    return React_Basic.empty;
};
module.exports = {
    mkPaginationControls: mkPaginationControls
};
