import { useCallback, useEffect } from "react";
import { useFeatureEnabled } from "../split/useFeatureEnabled";
import { FeatureFlags } from "../split/FeatureFlags";
import { getClientConfigVarOptional } from "src/client/config";
import { useUserDataQuery } from "../containers/Projects/useUserData";
import { useTranslation } from "react-i18next";

// Localize does not have typescript definitions
// eslint-disable-next-line
declare const Localize: any;

let localizeInitialized = false;

export function useLocalize() {
  const localizeEnabled = useFeatureEnabled(FeatureFlags.LOCALIZE);

  const { i18n } = useTranslation();

  const saveNewPhrases =
    getClientConfigVarOptional("CLIENT_LOCALIZE_SAVE_NEW_PHRASES") === "true";

  const user = useUserDataQuery();
  const currentLanguage = user?.userData.language ?? "fi";

  useEffect(() => {
    if (currentLanguage == Localize.getLanguage() || !currentLanguage) {
      return;
    }
    changeLocalizeLanguage(currentLanguage);
  }, [currentLanguage]);

  useEffect(() => {
    if (localizeInitialized || !localizeEnabled) {
      return;
    }
    Localize.initialize({
      key: "dIc4P1pNUT61o",
      targetLanguage: "fi",
      saveNewPhrases: saveNewPhrases,
    });
    localizeInitialized = true;
  }, [localizeEnabled]);

  const changeLocalizeLanguage = useCallback((language: string) => {
    Localize.setLanguage(language);
    i18n.changeLanguage(language);
  }, []);

  return changeLocalizeLanguage;
}
