// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var StyleProperties = require("../StyleProperties/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var tacoAutocompleteSeparatorStyles = Box.boxStyle([ Box.Height.create(new TacoConstants.CustomSize("1px")), Box.Width.create(new TacoConstants.CustomSize("100%")), Box.Style.create(React_Basic_Emotion.css()({
    borderBottom: StyleProperties.border(TacoConstants.BorderLight.value)
})) ]);
var tacoAutocompleteSectionStyles = Box.boxStyle([  ]);
var tacoAutocompleteHeadingStyles = Box.boxStyle([ new Box.PaddingX(TacoConstants.M.value), new Box.ForegroundColor(TacoConstants.Gray.value), new Box.MarginTop(TacoConstants.M.value) ]);
var autocompleteMatchesStyles = Box.boxStyle([ new Box.Border(TacoConstants.BorderLight.value), new Box.Overflow(Box.Auto.value, Box.Scroll.value), Box.MaxHeight.create(new TacoConstants.CustomSize("32rem")), Box.Width.create(new TacoConstants.CustomSize("100%")), new Box.BackgroundColor(TacoConstants.White.value) ]);
var autocompleteMatchStyles = function (props) {
    return Box.boxStyle([ new Box.Padding(TacoConstants.XXS.value), new Box.Border(TacoConstants.BorderTransparent.value), Data_Monoid.guard(Box.monoidBoxProp)(props.focused)(Box.Border.create(new TacoConstants.BorderCustom(TacoConstants.componentColors.border))), Data_Monoid.guard(Box.monoidBoxProp)(props.selected)(new Box.ColorSet(TacoConstants.componentColors)) ]);
};
module.exports = {
    autocompleteMatchesStyles: autocompleteMatchesStyles,
    autocompleteMatchStyles: autocompleteMatchStyles,
    tacoAutocompleteSeparatorStyles: tacoAutocompleteSeparatorStyles,
    tacoAutocompleteHeadingStyles: tacoAutocompleteHeadingStyles,
    tacoAutocompleteSectionStyles: tacoAutocompleteSectionStyles
};
