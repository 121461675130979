// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Control_Alt = require("../Control.Alt/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Monad_Except = require("../Control.Monad.Except/index.js");
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Bifunctor = require("../Data.Bifunctor/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_JSDate = require("../Data.JSDate/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Data_TraversableWithIndex = require("../Data.TraversableWithIndex/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unfoldable = require("../Data.Unfoldable/index.js");
var Data_Variant = require("../Data.Variant/index.js");
var Effect_Exception = require("../Effect.Exception/index.js");
var Effect_Uncurried = require("../Effect.Uncurried/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var Foreign = require("../Foreign/index.js");
var Foreign_Index = require("../Foreign.Index/index.js");
var Foreign_Object = require("../Foreign.Object/index.js");
var Kishimen = require("../Kishimen/index.js");
var Partial_Unsafe = require("../Partial.Unsafe/index.js");
var Record = require("../Record/index.js");
var Record_Builder = require("../Record.Builder/index.js");
var Type_Data_RowList = require("../Type.Data.RowList/index.js");
var writeVariantImpl = function (dict) {
    return dict.writeVariantImpl;
};
var writeImplFields = function (dict) {
    return dict.writeImplFields;
};
var writeImpl = function (dict) {
    return dict.writeImpl;
};
var writeJSON = function (dictTofuJSON) {
    var $116 = writeImpl(dictTofuJSON);
    return function ($117) {
        return $foreign.unsafeStringify($116($117));
    };
};
var write = function (dictTofuJSON) {
    return writeImpl(dictTofuJSON);
};
var $$undefined = $foreign["_undefined"];
var tofuJSONForeign = {
    readImpl: Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity)),
    writeImpl: Control_Category.identity(Control_Category.categoryFn)
};
var readVariantNil = {
    readVariantImpl: function (v) {
        return function (v1) {
            return Foreign.fail(Data_Identity.monadIdentity)(new Foreign.ForeignError("Unable to match any variant member."));
        };
    }
};
var readVariantImpl = function (dict) {
    return dict.readVariantImpl;
};
var tofuJSONVariant = function (dictRowToList) {
    return function (dictTofuJSONInVariant) {
        return function (dictTofuJSONOutVariant) {
            return {
                readImpl: function (o) {
                    return readVariantImpl(dictTofuJSONInVariant)(Type_Data_RowList.RLProxy.value)(o);
                },
                writeImpl: function (variant) {
                    return writeVariantImpl(dictTofuJSONOutVariant)(Type_Data_RowList.RLProxy.value)(variant);
                }
            };
        };
    };
};
var writeGenericSum = function (dictRowToList) {
    return function (dictTofuJSONInVariant) {
        return function (dictTofuJSONOutVariant) {
            return function (dictGeneric) {
                return function (dictGenericSumToVariant) {
                    var $118 = writeImpl(tofuJSONVariant()(dictTofuJSONInVariant)(dictTofuJSONOutVariant));
                    var $119 = Kishimen.genericSumToVariant(dictGeneric)(dictGenericSumToVariant);
                    return function ($120) {
                        return $118($119($120));
                    };
                };
            };
        };
    };
};
var readString = {
    readImpl: Foreign.readString(Data_Identity.monadIdentity),
    writeImpl: Foreign.unsafeToForeign
};
var readNumber = {
    readImpl: Foreign.readNumber(Data_Identity.monadIdentity),
    writeImpl: Foreign.unsafeToForeign
};
var readJSDate = {
    readImpl: Data_JSDate.readDate,
    writeImpl: Foreign.unsafeToForeign
};
var readInt = {
    readImpl: Foreign.readInt(Data_Identity.monadIdentity),
    writeImpl: Foreign.unsafeToForeign
};
var readImpl = function (dict) {
    return dict.readImpl;
};
var readMaybe = function (dictTofuJSON) {
    return {
        readImpl: (function () {
            var readNullOrUndefined = function (v) {
                return function (value) {
                    if (Foreign.isNull(value) || Foreign.isUndefined(value)) {
                        return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Data_Maybe.Nothing.value);
                    };
                    return Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Data_Maybe.Just.create)(v(value));
                };
            };
            return readNullOrUndefined(readImpl(dictTofuJSON));
        })(),
        writeImpl: Data_Maybe.maybe($$undefined)(writeImpl(dictTofuJSON))
    };
};
var readNullable = function (dictTofuJSON) {
    return {
        readImpl: function (o) {
            var reformat = function (error) {
                if (error instanceof Foreign.TypeMismatch) {
                    return new Foreign.TypeMismatch("Nullable " + error.value0, error.value1);
                };
                return error;
            };
            return Control_Monad_Except.withExcept(Data_Functor.map(Data_List_Types.functorNonEmptyList)(reformat))(Control_Bind.bindFlipped(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(Data_Functor.map(Data_Functor.functorFn)(Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Data_Nullable.toNullable))(Data_Traversable.traverse(Data_Traversable.traversableMaybe)(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(readImpl(dictTofuJSON))))(Foreign.readNullOrUndefined(Data_Identity.monadIdentity)(o)));
        },
        writeImpl: (function () {
            var $121 = Data_Maybe.maybe(Foreign.unsafeToForeign(Data_Nullable.toNullable(Data_Maybe.Nothing.value)))(writeImpl(dictTofuJSON));
            return function ($122) {
                return $121(Data_Nullable.toMaybe($122));
            };
        })()
    };
};
var readObject = function (dictTofuJSON) {
    return {
        readImpl: (function () {
            var readObject$prime = function (value) {
                if (Foreign.tagOf(value) === "Object") {
                    return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Foreign.unsafeFromForeign(value));
                };
                if (Data_Boolean.otherwise) {
                    return Foreign.fail(Data_Identity.monadIdentity)(new Foreign.TypeMismatch("Object", Foreign.tagOf(value)));
                };
                throw new Error("Failed pattern match at TofuJSON (line 220, column 7 - line 220, column 51): " + [ value.constructor.name ]);
            };
            return Control_Bind.composeKleisliFlipped(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))((function () {
                var $123 = Data_Traversable.sequence(Foreign_Object.traversableObject)(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity));
                var $124 = Foreign_Object.mapWithKey(Data_Function["const"](readImpl(dictTofuJSON)));
                return function ($125) {
                    return $123($124($125));
                };
            })())(readObject$prime);
        })(),
        writeImpl: (function () {
            var $126 = Foreign_Object.mapWithKey(Data_Function["const"](writeImpl(dictTofuJSON)));
            return function ($127) {
                return Foreign.unsafeToForeign($126($127));
            };
        })()
    };
};
var readGenericSum = function (dictGeneric) {
    return function (dictGenericSumToVariant) {
        return function (dictRowToList) {
            return function (dictTofuJSONInVariant) {
                return function (dictTofuJSONOutVariant) {
                    var $128 = Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Kishimen.variantToGenericSum(dictGeneric)(dictGenericSumToVariant));
                    var $129 = readImpl(tofuJSONVariant()(dictTofuJSONInVariant)(dictTofuJSONOutVariant));
                    return function ($130) {
                        return $128($129($130));
                    };
                };
            };
        };
    };
};
var readFieldsNil = {
    getFields: function (v) {
        return function (v1) {
            return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Control_Category.identity(Record_Builder.categoryBuilder));
        };
    }
};
var readChar = {
    readImpl: Foreign.readChar(Data_Identity.monadIdentity),
    writeImpl: Foreign.unsafeToForeign
};
var readBoolean = {
    readImpl: Foreign.readBoolean(Data_Identity.monadIdentity),
    writeImpl: Foreign.unsafeToForeign
};
var readAtIdx$prime = function (dictTofuJSON) {
    return function (i) {
        return function (f) {
            return Control_Monad_Except.withExcept(Data_Functor.map(Data_List_Types.functorNonEmptyList)(Foreign.ErrorAtIndex.create(i)))(readImpl(dictTofuJSON)(f));
        };
    };
};
var readArray = function (dictTofuJSON) {
    return {
        readImpl: Control_Bind.composeKleisliFlipped(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(Data_TraversableWithIndex.traverseWithIndex(Data_TraversableWithIndex.traversableWithIndexArray)(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(readAtIdx$prime(dictTofuJSON)))(Foreign.readArray(Data_Identity.monadIdentity)),
        writeImpl: function (xs) {
            return Foreign.unsafeToForeign(Data_Functor.map(Data_Functor.functorArray)(writeImpl(dictTofuJSON))(xs));
        }
    };
};
var tofuJSONSet = function (dictOrd) {
    return function (dictTofuJSON) {
        return {
            readImpl: function (f) {
                return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(readImpl(readArray(dictTofuJSON))(f))(function (v) {
                    return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Data_Set.fromFoldable(Data_Foldable.foldableArray)(dictOrd)(v));
                });
            },
            writeImpl: function (s) {
                var array = Data_Set.toUnfoldable(Data_Unfoldable.unfoldableArray)(s);
                return writeImpl(readArray(dictTofuJSON))(array);
            }
        };
    };
};
var read$prime = function (dictTofuJSON) {
    return readImpl(dictTofuJSON);
};
var read = function (dictTofuJSON) {
    var $131 = readImpl(dictTofuJSON);
    return function ($132) {
        return Control_Monad_Except.runExcept($131($132));
    };
};
var readAtIdxOfArray = function (dictTofuJSON) {
    return function (i) {
        return function (xs) {
            var mValue = Data_Array.index(xs)(i);
            if (mValue instanceof Data_Maybe.Nothing) {
                return Data_Either.Left.create(Control_Applicative.pure(Data_List_Types.applicativeNonEmptyList)(new Foreign.ErrorAtIndex(i, new Foreign.ForeignError("Indeksi N/A"))));
            };
            if (mValue instanceof Data_Maybe.Just) {
                return read(dictTofuJSON)(mValue.value0);
            };
            throw new Error("Failed pattern match at TofuJSON (line 190, column 3 - line 192, column 21): " + [ mValue.constructor.name ]);
        };
    };
};
var readMaybeAtIdxOfArray = function (dictTofuJSON) {
    return function (i) {
        return function (xs) {
            var mValue = Data_Array.index(xs)(i);
            if (mValue instanceof Data_Maybe.Nothing) {
                return Control_Applicative.pure(Data_Either.applicativeEither)(Data_Maybe.Nothing.value);
            };
            if (mValue instanceof Data_Maybe.Just) {
                return Data_Functor.map(Data_Either.functorEither)(Data_Maybe.Just.create)(read(dictTofuJSON)(mValue.value0));
            };
            throw new Error("Failed pattern match at TofuJSON (line 197, column 3 - line 199, column 39): " + [ mValue.constructor.name ]);
        };
    };
};
var read_ = function (dictTofuJSON) {
    var $133 = read(dictTofuJSON);
    return function ($134) {
        return Data_Either.hush($133($134));
    };
};
var parseJSON = (function () {
    var $135 = Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)((function () {
        var $138 = Control_Applicative.pure(Data_List_Types.applicativeNonEmptyList);
        return function ($139) {
            return $138(Foreign.ForeignError.create(Effect_Exception.message($139)));
        };
    })());
    var $136 = Effect_Uncurried.runEffectFn1($foreign["_parseJSON"]);
    return function ($137) {
        return Control_Monad_Except_Trans.ExceptT(Data_Identity.Identity($135(Effect_Unsafe.unsafePerformEffect(Effect_Exception["try"]($136($137))))));
    };
})();
var readJSON = function (dictTofuJSON) {
    var $140 = Control_Bind.composeKleisliFlipped(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(readImpl(dictTofuJSON))(parseJSON);
    return function ($141) {
        return Control_Monad_Except.runExcept($140($141));
    };
};
var readJSON_ = function (dictTofuJSON) {
    var $142 = readJSON(dictTofuJSON);
    return function ($143) {
        return Data_Either.hush($142($143));
    };
};
var readJSON$prime = function (dictTofuJSON) {
    return Control_Bind.composeKleisliFlipped(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(readImpl(dictTofuJSON))(parseJSON);
};
var nilWriteForeignVariant = {
    writeVariantImpl: function (v) {
        return function (v1) {
            return Partial_Unsafe.unsafeCrashWith("Variant was not able to be writen row WriteForeign.");
        };
    }
};
var nilWriteForeignFields = {
    writeImplFields: function (v) {
        return function (v1) {
            return Control_Category.identity(Record_Builder.categoryBuilder);
        };
    }
};
var getFields = function (dict) {
    return dict.getFields;
};
var readRecord = function (dictRowToList) {
    return function (dictTofuJSONFieldsIn) {
        return function (dictTofuJSONFieldsOut) {
            return {
                readImpl: function (o) {
                    return Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Data_Function.flip(Record_Builder.build)({}))(getFields(dictTofuJSONFieldsIn)(Type_Data_RowList.RLProxy.value)(o));
                },
                writeImpl: function (rec) {
                    var steps = writeImplFields(dictTofuJSONFieldsOut)(Type_Data_RowList.RLProxy.value)(rec);
                    return Foreign.unsafeToForeign(Record_Builder.build(steps)({}));
                }
            };
        };
    };
};
var consWriteForeignVariant = function (dictIsSymbol) {
    return function (dictTofuJSON) {
        return function (dictCons) {
            return function (dictTofuJSONOutVariant) {
                return {
                    writeVariantImpl: function (v) {
                        return function (variant) {
                            var writeVariant = function (value) {
                                return Foreign.unsafeToForeign({
                                    type: Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value),
                                    value: writeImpl(dictTofuJSON)(value)
                                });
                            };
                            return Data_Variant.on()(dictIsSymbol)(Data_Symbol.SProxy.value)(writeVariant)(writeVariantImpl(dictTofuJSONOutVariant)(Type_Data_RowList.RLProxy.value))(variant);
                        };
                    }
                };
            };
        };
    };
};
var consWriteForeignFields = function (dictIsSymbol) {
    return function (dictTofuJSON) {
        return function (dictTofuJSONFieldsOut) {
            return function (dictCons) {
                return function (dictLacks) {
                    return function (dictCons1) {
                        return {
                            writeImplFields: function (v) {
                                return function (rec) {
                                    var rest = writeImplFields(dictTofuJSONFieldsOut)(Type_Data_RowList.RLProxy.value)(rec);
                                    var value = writeImpl(dictTofuJSON)(Record.get(dictIsSymbol)()(Data_Symbol.SProxy.value)(rec));
                                    var result = Control_Semigroupoid.compose(Record_Builder.semigroupoidBuilder)(Record_Builder.insert()()(dictIsSymbol)(Data_Symbol.SProxy.value)(value))(rest);
                                    return result;
                                };
                            }
                        };
                    };
                };
            };
        };
    };
};
var applyEither = function (dictSemigroup) {
    return function (v) {
        return function (v1) {
            if (v instanceof Data_Either.Left && v1 instanceof Data_Either.Right) {
                return new Data_Either.Left(v.value0);
            };
            if (v instanceof Data_Either.Left && v1 instanceof Data_Either.Left) {
                return new Data_Either.Left(Data_Semigroup.append(dictSemigroup)(v.value0)(v1.value0));
            };
            if (v instanceof Data_Either.Right && v1 instanceof Data_Either.Left) {
                return new Data_Either.Left(v1.value0);
            };
            if (v instanceof Data_Either.Right && v1 instanceof Data_Either.Right) {
                return new Data_Either.Right(v.value0(v1.value0));
            };
            throw new Error("Failed pattern match at TofuJSON (line 293, column 1 - line 293, column 90): " + [ v.constructor.name, v1.constructor.name ]);
        };
    };
};
var exceptTApply = function (dictSemigroup) {
    return function (dictApplicative) {
        return function (fun) {
            return function (a) {
                return Control_Monad_Except_Trans.ExceptT(Control_Apply.apply(dictApplicative.Apply0())(Data_Functor.map((dictApplicative.Apply0()).Functor0())(applyEither(dictSemigroup))(Control_Monad_Except_Trans.runExceptT(fun)))(Control_Monad_Except_Trans.runExceptT(a)));
            };
        };
    };
};
var readFieldsCons = function (dictIsSymbol) {
    return function (dictTofuJSON) {
        return function (dictTofuJSONFieldsIn) {
            return function (dictLacks) {
                return function (dictCons) {
                    return {
                        getFields: function (v) {
                            return function (obj) {
                                var rest = getFields(dictTofuJSONFieldsIn)(Type_Data_RowList.RLProxy.value)(obj);
                                var name = Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value);
                                var withExcept$prime = Control_Monad_Except.withExcept(Data_Functor.map(Data_List_Types.functorNonEmptyList)(Foreign.ErrorAtProperty.create(name)));
                                var first = Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(withExcept$prime(Control_Bind.bindFlipped(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(readImpl(dictTofuJSON))(Foreign_Index.readProp(Data_Identity.monadIdentity)(name)(obj))))(function (value) {
                                    return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Record_Builder.insert()()(dictIsSymbol)(Data_Symbol.SProxy.value)(value));
                                });
                                return exceptTApply(Data_List_Types.semigroupNonEmptyList)(Data_Identity.applicativeIdentity)(Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Control_Semigroupoid.compose(Record_Builder.semigroupoidBuilder))(first))(rest);
                            };
                        }
                    };
                };
            };
        };
    };
};
var readVariantCons = function (dictIsSymbol) {
    return function (dictTofuJSON) {
        return function (dictCons) {
            return function (dictTofuJSONInVariant) {
                return {
                    readVariantImpl: function (v) {
                        return function (o) {
                            var name = Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value);
                            return Control_Alt.alt(Control_Monad_Except_Trans.altExceptT(Data_List_Types.semigroupNonEmptyList)(Data_Identity.monadIdentity))(Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(readImpl(readRecord()(readFieldsCons({
                                reflectSymbol: function () {
                                    return "type";
                                }
                            })(readString)(readFieldsCons({
                                reflectSymbol: function () {
                                    return "value";
                                }
                            })(tofuJSONForeign)(readFieldsNil)()())()())(consWriteForeignFields({
                                reflectSymbol: function () {
                                    return "type";
                                }
                            })(readString)(consWriteForeignFields({
                                reflectSymbol: function () {
                                    return "value";
                                }
                            })(tofuJSONForeign)(nilWriteForeignFields)()()())()()()))(o))(function (v1) {
                                var $107 = v1.type === name;
                                if ($107) {
                                    return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(readImpl(dictTofuJSON)(v1.value))(function (v2) {
                                        return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Data_Variant.inj()(dictIsSymbol)(Data_Symbol.SProxy.value)(v2));
                                    });
                                };
                                return Foreign.fail(Data_Identity.monadIdentity)(Foreign.ForeignError.create("Did not match variant tag " + name));
                            }))(readVariantImpl(dictTofuJSONInVariant)(Type_Data_RowList.RLProxy.value)(o));
                        };
                    }
                };
            };
        };
    };
};
var tofuJSONMap = function (dictOrd) {
    return function (dictTofuJSON) {
        return function (dictTofuJSON1) {
            return {
                readImpl: function (f) {
                    var toTuple = function (v) {
                        return new Data_Tuple.Tuple(v.key, v.value);
                    };
                    return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(readImpl(readArray(readRecord()(readFieldsCons({
                        reflectSymbol: function () {
                            return "key";
                        }
                    })(dictTofuJSON)(readFieldsCons({
                        reflectSymbol: function () {
                            return "value";
                        }
                    })(dictTofuJSON1)(readFieldsNil)()())()())(consWriteForeignFields({
                        reflectSymbol: function () {
                            return "key";
                        }
                    })(dictTofuJSON)(consWriteForeignFields({
                        reflectSymbol: function () {
                            return "value";
                        }
                    })(dictTofuJSON1)(nilWriteForeignFields)()()())()()())))(f))(function (v) {
                        return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Data_Map_Internal.fromFoldable(dictOrd)(Data_Foldable.foldableArray)(Data_Functor.map(Data_Functor.functorArray)(toTuple)(v)));
                    });
                },
                writeImpl: function (m) {
                    var recs = Data_Map_Internal.toUnfoldableUnordered(Data_Unfoldable.unfoldableArray)(m);
                    var fromTuple = function (v) {
                        return {
                            key: v.value0,
                            value: v.value1
                        };
                    };
                    return writeImpl(readArray(readRecord()(readFieldsCons({
                        reflectSymbol: function () {
                            return "key";
                        }
                    })(dictTofuJSON)(readFieldsCons({
                        reflectSymbol: function () {
                            return "value";
                        }
                    })(dictTofuJSON1)(readFieldsNil)()())()())(consWriteForeignFields({
                        reflectSymbol: function () {
                            return "key";
                        }
                    })(dictTofuJSON)(consWriteForeignFields({
                        reflectSymbol: function () {
                            return "value";
                        }
                    })(dictTofuJSON1)(nilWriteForeignFields)()()())()()())))(Data_Functor.map(Data_Functor.functorArray)(fromTuple)(recs));
                }
            };
        };
    };
};
module.exports = {
    getFields: getFields,
    readImpl: readImpl,
    readVariantImpl: readVariantImpl,
    writeImpl: writeImpl,
    writeImplFields: writeImplFields,
    writeVariantImpl: writeVariantImpl,
    readJSON: readJSON,
    "readJSON'": readJSON$prime,
    readJSON_: readJSON_,
    writeJSON: writeJSON,
    write: write,
    read: read,
    "read'": read$prime,
    read_: read_,
    writeGenericSum: writeGenericSum,
    readGenericSum: readGenericSum,
    parseJSON: parseJSON,
    "undefined": $$undefined,
    "readAtIdx'": readAtIdx$prime,
    readAtIdxOfArray: readAtIdxOfArray,
    readMaybeAtIdxOfArray: readMaybeAtIdxOfArray,
    exceptTApply: exceptTApply,
    applyEither: applyEither,
    tofuJSONForeign: tofuJSONForeign,
    readChar: readChar,
    readNumber: readNumber,
    readInt: readInt,
    readString: readString,
    readJSDate: readJSDate,
    readBoolean: readBoolean,
    readArray: readArray,
    readMaybe: readMaybe,
    readNullable: readNullable,
    readObject: readObject,
    readRecord: readRecord,
    tofuJSONMap: tofuJSONMap,
    tofuJSONSet: tofuJSONSet,
    readFieldsCons: readFieldsCons,
    readFieldsNil: readFieldsNil,
    tofuJSONVariant: tofuJSONVariant,
    readVariantNil: readVariantNil,
    readVariantCons: readVariantCons,
    consWriteForeignFields: consWriteForeignFields,
    nilWriteForeignFields: nilWriteForeignFields,
    nilWriteForeignVariant: nilWriteForeignVariant,
    consWriteForeignVariant: consWriteForeignVariant,
    unsafeStringify: $foreign.unsafeStringify,
    "_parseJSON": $foreign["_parseJSON"],
    "_undefined": $foreign["_undefined"]
};
