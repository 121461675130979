// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Client_WASM = require("../Client.WASM/index.js");
var Common = require("../Common/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var FeatureHooks = require("../FeatureHooks/index.js");
var Formatters = require("../Formatters/index.js");
var Gap = require("../Gap/index.js");
var LengthValidator = require("../LengthValidator/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_DOM_Internal = require("../React.Basic.DOM.Internal/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoList = require("../TacoList/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoTable_Header = require("../TacoTable.Header/index.js");
var TacoTable_Row = require("../TacoTable.Row/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_ClassificationGroup = require("../Types.ClassificationGroup/index.js");
var Types_Project = require("../Types.Project/index.js");
var UseLocalStorage = require("../UseLocalStorage/index.js");
var UseWorkerData = require("../UseWorkerData/index.js");
var Utils = require("../Utils/index.js");
var WasmWorker = require("../WasmWorker/index.js");
var WasmWorker_WorkerRequest = require("../WasmWorker.WorkerRequest/index.js");
var title$prime = function (v) {
    return Data_Newtype.un()(Types_ClassificationGroup.ClassificationGroupCode)(v.code) + Data_Maybe.maybe("")(Data_Semigroup.append(Data_Semigroup.semigroupString)(" \u2013 "))(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Utils.stringMaybeEmpty)(Data_Nullable.toMaybe(v.description)));
};
var editGroupsModal = TofuHooks.mkMemoHookComponent("editGroupsModal")(function (props) {
    var defaultNewGroupInputs = {
        code: "",
        description: ""
    };
    return function __do() {
        var v = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var v1 = TofuHooks.useState(defaultNewGroupInputs)();
        var dispatch = StateHooks.useDispatch();
        var updateAction = function (fn) {
            return function (value) {
                return function (handler) {
                    return new Actions.ClassificationGroupActionRequest({
                        handler: handler,
                        projectId: props.projectId,
                        params: Types_ClassificationGroup.UpdateClassificationGroup.create(fn(value))
                    });
                };
            };
        };
        var removeButton = function (group) {
            return TacoButton.component()()({
                text: "Poista",
                disabled: Data_Set.member(Types_ClassificationGroup.ordClassificationGroupCode)((Data_Newtype.unwrap()(group)).code)(props.nonEmptyGroups),
                title: "Vain tyhj\xe4n ryhm\xe4n voi poistaa",
                onClick: Data_Maybe.Just.create(v.value1(function (v2) {
                    return new Data_Maybe.Just(group);
                })),
                testId: "component-groups-tree-" + (Data_Show.show(Data_Show.showInt)(Data_Newtype.unwrap()((Data_Newtype.unwrap()(group)).id)) + "-remove-button")
            });
        };
        var newGroupSubmitButton = TacoButton.component()()({
            text: "Luo uusi ryhm\xe4",
            disabled: Data_String_Common["null"](v1.value0.code) || (Data_String_Common["null"](v1.value0.description) || Data_String_CodePoints.length(v1.value0.description) > 50),
            onClick: new Data_Maybe.Just(dispatch(new Actions.ClassificationGroupActionRequest({
                handler: function (v2) {
                    return v1.value1(function (v3) {
                        return defaultNewGroupInputs;
                    });
                },
                projectId: props.projectId,
                params: new Types_ClassificationGroup.AddClassificationGroup({
                    projectId: props.projectId,
                    code: v1.value0.code,
                    description: new Data_Maybe.Just(v1.value0.description),
                    memo: Data_Maybe.Nothing.value
                })
            }))),
            testId: "component-groups-tree-new-group-submit-button"
        });
        var newGroupDescription = TacoInput.stringInput()()(Data_Eq.eqString)({
            value: v1.value0.description,
            onChange: function (fn) {
                return v1.value1(function (r) {
                    return {
                        code: r.code,
                        description: fn(r.description)
                    };
                });
            },
            validator: LengthValidator.lengthValidator(0)(50),
            testId: "component-groups-tree-new-group-description-input"
        });
        var newGroupCode = TacoInput.stringInput()()(Data_Eq.eqString)({
            value: v1.value0.code,
            onChange: function (fn) {
                return v1.value1(function (r) {
                    return {
                        code: fn(r.code),
                        description: r.description
                    };
                });
            },
            validator: LengthValidator.lengthValidator(1)(20),
            testId: "component-groups-tree-new-group-code-input"
        });
        var newGroupColumns = [ {
            key: "code",
            label: React_Basic_DOM.text("Tunnus"),
            width: new TacoTable_Types.Flex(1),
            sortProperty: TacoTable_Types.noSortProperty,
            headerJSX: Data_Maybe.Nothing.value,
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (v2) {
                return newGroupCode;
            })
        }, {
            key: "name",
            label: React_Basic_DOM.text("Kuvaus"),
            width: new TacoTable_Types.Flex(3),
            sortProperty: TacoTable_Types.noSortProperty,
            headerJSX: Data_Maybe.Nothing.value,
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (v2) {
                return newGroupDescription;
            })
        }, {
            key: "button",
            label: React_Basic_DOM.text(""),
            width: new TacoTable_Types.Flex(1),
            sortProperty: TacoTable_Types.noSortProperty,
            headerJSX: Data_Maybe.Nothing.value,
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (v2) {
                return newGroupSubmitButton;
            })
        } ];
        var newGroupRow = React_Basic.keyed("new-group-row")(TacoTable_Row.row(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "description";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "code";
            }
        })(Data_Eq.eqString)))({
            rowData: v1.value0,
            css: React_Basic_Emotion.css()({}),
            rowHeight: TacoTable_Types.RowMedium.value,
            sortProperty: TacoTable_Types.noSortProperty,
            onClick: function (v2) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            },
            className: "",
            foldingElement: Data_Maybe.Nothing.value,
            styleSet: TacoTable_Styles.foldingTableStyleSetWithoutSticky.rowStyleSet,
            style: React_Basic_DOM_Internal.css({}),
            columns: newGroupColumns,
            rowKey: "new-group-row"
        }));
        var nameUpdateAction = function (r) {
            return updateAction(function (val) {
                var v2 = Data_Newtype.unwrap()(r);
                return {
                    id: v2.id,
                    projectId: v2.projectId,
                    code: v2.code,
                    description: Utils.pureNullable(val),
                    memo: v2.memo
                };
            });
        };
        var deletePromptModalBody = TacoModal.modalBody({
            contents: (function () {
                if (v.value0 instanceof Data_Maybe.Nothing) {
                    return [  ];
                };
                if (v.value0 instanceof Data_Maybe.Just) {
                    return Control_Applicative.pure(Control_Applicative.applicativeArray)(TacoText.component()()({
                        text: "Haluatko varmasti poistaa ryhm\xe4n " + (Data_Show.show(Types_ClassificationGroup.showClassificationGroupCode)(v.value0.value0.code) + Utils.nullable("")(function (x) {
                            return " \"" + (x + "\"");
                        })(v.value0.value0.description))
                    }));
                };
                throw new Error("Failed pattern match at ComponentGroupsTree (line 299, column 19 - line 302, column 132): " + [ v.value0.constructor.name ]);
            })()
        });
        var deletePromptModalActions = TacoModal.modalActions({
            contents: [ TacoButton.component()()({
                onClick: Data_Maybe.Just.create(v.value1(function (v2) {
                    return Data_Maybe.Nothing.value;
                })),
                text: "Peruuta",
                testId: "cancel-delete-component-groups-tree-button"
            }), TacoButton.component()()({
                onClick: (function () {
                    if (v.value0 instanceof Data_Maybe.Nothing) {
                        return Data_Maybe.Nothing.value;
                    };
                    if (v.value0 instanceof Data_Maybe.Just) {
                        return Data_Maybe.Just.create(dispatch(new Actions.ClassificationGroupActionRequest({
                            handler: function (v2) {
                                return v.value1(function (v3) {
                                    return Data_Maybe.Nothing.value;
                                });
                            },
                            projectId: props.projectId,
                            params: new Types_ClassificationGroup.DeleteClassificationGroup((Data_Newtype.unwrap()(v.value0.value0)).id)
                        })));
                    };
                    throw new Error("Failed pattern match at ComponentGroupsTree (line 313, column 26 - line 322, column 26): " + [ v.value0.constructor.name ]);
                })(),
                text: "Poista",
                color: TacoConstants.SecondaryRed.value,
                testId: "submit-delete-component-groups-tree-button"
            }) ]
        });
        var deletePromptModal = TacoModal.component()()({
            onClose: v.value1(function (v2) {
                return Data_Maybe.Nothing.value;
            }),
            isActive: Data_Maybe.isJust(v.value0),
            heading: "Poista ryhm\xe4",
            contents: [ deletePromptModalBody, deletePromptModalActions ]
        });
        var codeUpdateAction = function (r) {
            return updateAction(function (val) {
                var v2 = Data_Newtype.unwrap()(r);
                return {
                    id: v2.id,
                    projectId: v2.projectId,
                    code: val,
                    description: v2.description,
                    memo: v2.memo
                };
            });
        };
        var columns = [ {
            key: "code",
            label: React_Basic_DOM.text("Tunnus"),
            width: new TacoTable_Types.Flex(1),
            sortProperty: TacoTable_Types.noSortProperty,
            headerJSX: Data_Maybe.Nothing.value,
            cell: TacoTable_Types.JSXCell.create([ TacoTable_Types.Input.value ])(function (r) {
                return TacoInput.remoteStringField()()({
                    value: Data_Newtype.unwrap()((Data_Newtype.unwrap()(r)).code),
                    mkAction: codeUpdateAction(r),
                    validator: LengthValidator.lengthValidator(1)(20),
                    testId: "component-groups-tree-" + (Data_Show.show(Data_Show.showInt)(Data_Newtype.unwrap()((Data_Newtype.unwrap()(r)).id)) + "-code-input")
                });
            })
        }, {
            key: "name",
            label: React_Basic_DOM.text("Kuvaus"),
            width: new TacoTable_Types.Flex(3),
            sortProperty: TacoTable_Types.noSortProperty,
            headerJSX: Data_Maybe.Nothing.value,
            cell: TacoTable_Types.JSXCell.create([ TacoTable_Types.Input.value ])(function (r) {
                return TacoInput.remoteStringField()()({
                    value: Utils.fromNullable("")((Data_Newtype.unwrap()(r)).description),
                    mkAction: nameUpdateAction(r),
                    validator: LengthValidator.lengthValidator(0)(50),
                    testId: "component-groups-tree-" + (Data_Show.show(Data_Show.showInt)(Data_Newtype.unwrap()((Data_Newtype.unwrap()(r)).id)) + "-name-input")
                });
            })
        }, {
            key: "button",
            label: React_Basic_DOM.text(""),
            width: new TacoTable_Types.Flex(1),
            sortProperty: TacoTable_Types.noSortProperty,
            headerJSX: Data_Maybe.Nothing.value,
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (group) {
                return removeButton(group);
            })
        } ];
        var header = TacoTable_Header.header(TofuHooks.getNameVoid)(Data_Eq.eqVoid)(Types_ClassificationGroup.eqClassificationGroup)({
            sortProperty: TacoTable_Types.noSortProperty,
            sortingOrder: TacoTable_Types.Ascending.value,
            styleSet: TacoTable_Styles.foldingTableStyleSetWithoutSticky.headerStyleSet,
            onSort: function (v2) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            },
            foldingElement: Data_Maybe.Nothing.value,
            columns: columns,
            onResize: function (v2) {
                return function (v3) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                };
            },
            columnSizeOffsets: Data_Maybe.Nothing.value,
            scrollLeft: 0.0
        });
        var mkGroupRow = function (group) {
            var rowKey = "group-" + Data_Show.show(Types_ClassificationGroup.showClassificationGroupId)((Data_Newtype.unwrap()(group)).id);
            return React_Basic.keyed(rowKey)(TacoTable_Row.row(Data_Eq.eqVoid)(Types_ClassificationGroup.eqClassificationGroup)({
                rowData: group,
                css: React_Basic_Emotion.css()({}),
                rowHeight: TacoTable_Types.RowLarge.value,
                sortProperty: TacoTable_Types.noSortProperty,
                onClick: function (v2) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                },
                className: "",
                foldingElement: Data_Maybe.Nothing.value,
                styleSet: TacoTable_Styles.foldingTableStyleSetWithoutSticky.rowStyleSet,
                style: React_Basic_DOM_Internal.css({}),
                columns: columns,
                rowKey: rowKey
            }));
        };
        var groupRows = Data_Functor.map(Data_Functor.functorArray)(mkGroupRow)(props.groups);
        var table = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "table-rows",
            css: Box.boxStyle([ Box.Width.create(new TacoConstants.CustomSize("100%")) ]),
            children: Data_Semigroup.append(Data_Semigroup.semigroupArray)([ header ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(groupRows)([ newGroupRow ]))
        });
        var modalBody = TacoModal.modalBody({
            contents: [ table, deletePromptModal ]
        });
        var cancelButton = TacoButton.component()()({
            onClick: new Data_Maybe.Just(props.onClose),
            text: "Peruuta",
            testId: "close-component-groups-tree-button"
        });
        var modalActions = TacoModal.modalActions({
            contents: [ cancelButton ]
        });
        return TacoModal.component()()({
            isActive: props.isActive,
            onClose: props.onClose,
            heading: "Muokkaa ryhmi\xe4",
            contents: [ modalBody, modalActions ]
        });
    };
});
var componentGroupsTree = TofuHooks.mkMemoHookComponent("ComponentGroupsTree")(function (props) {
    return function __do() {
        var v = Client_WASM.useClassificationGroups(props.projectId)();
        var newGroupsEditModalEnabled = FeatureHooks.useFeatureEnabled("component-group-edit-modal-new")();
        var v1 = UseWorkerData.useWorkerItemsByKey(props.projectId)(function (v1) {
            return v1.workerComponentGroupsData;
        })("ComponentGroupsDataState")();
        var v2 = UseWorkerData.useWorkerItemsByKey(props.projectId)(function (v2) {
            return v2.workerUnfilteredSEPComponentGroupsData;
        })("UnfilteredSepComponentGroupsDataState")();
        var loadingGroupsData = v1.value1 || v2.value1;
        var v3 = TofuHooks.useState(false)();
        var v4 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)("component-groups-tree-show-contents-" + Data_Show.show(Types_Project.showProjectId)(props.projectId))(true)(false)();
        var v5 = TofuHooks.useState(false)();
        var prepareItem = TofuHooks.useMemo([ ReactHooksUtils.utf(props.toggleGroupFilter), ReactHooksUtils.utf(props.toggleWithoutGroupFilter) ])(function (v6) {
            return function (gd) {
                var group = Data_Newtype.un()(Types_ClassificationGroup.WasmClassificationGroup)(gd.group);
                var code = Data_Newtype.un()(Types_ClassificationGroup.ClassificationGroupCode)(group.code);
                var title = title$prime(gd.group);
                return {
                    title: title,
                    id: title,
                    titleJSX: Data_Maybe.Nothing.value,
                    value: TacoList.TextValue.create(Formatters.formatCurrencyValue(props.projectCurrency)(gd.total)),
                    onClick: (function () {
                        var $61 = code === "?????";
                        if ($61) {
                            return Data_Maybe.Just.create(props.toggleWithoutGroupFilter("no-group"));
                        };
                        return Data_Maybe.Just.create(props.toggleGroupFilter(group.code));
                    })(),
                    initExpanded: false,
                    disabled: gd.total === 0.0,
                    getItems: function (v7) {
                        return [  ];
                    },
                    hasItems: false
                };
            };
        })();
        var nonEmptyGroups = TofuHooks.useMemo([ ReactHooksUtils.utf(props.computedComponentWithResources) ])(function (v6) {
            var byResources = Data_Set.fromFoldable(Data_Foldable.foldableArray)(Types_ClassificationGroup.ordClassificationGroupCode)(Data_Array.catMaybes(Data_Functor.map(Data_Functor.functorArray)(function ($83) {
                return Data_Nullable.toMaybe((function (v7) {
                    return v7.group;
                })($83));
            })(Control_Bind.bindFlipped(Control_Bind.bindArray)(function (v7) {
                return v7.resources;
            })(props.computedComponentWithResources))));
            var byComponents = Data_Set.fromFoldable(Data_Foldable.foldableArray)(Types_ClassificationGroup.ordClassificationGroupCode)(Data_Array.catMaybes(Data_Functor.map(Data_Functor.functorArray)(function ($84) {
                return Data_Nullable.toMaybe((function (v7) {
                    return v7.component.group;
                })($84));
            })(props.computedComponentWithResources)));
            return Data_Set.union(Types_ClassificationGroup.ordClassificationGroupCode)(byComponents)(byResources);
        })();
        var groupsItems = TofuHooks.useMemo([ ReactHooksUtils.utf(v1.value0), ReactHooksUtils.utf(v2.value0), ReactHooksUtils.utf(prepareItem) ])(function (v6) {
            var socialExpenseText = function (text) {
                return TacoText.component()()({
                    text: text,
                    color: TacoConstants.GrayDark.value,
                    testId: "groups-total-social-expense-price"
                });
            };
            var groups = Data_Functor.map(Data_Functor.functorArray)(prepareItem)(v1.value0);
            var _socialExpensePrice = function (r) {
                return r.socialExpensePrice;
            };
            var totalSocialExpensePrice = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(_socialExpensePrice)(v2.value0));
            var socialExpenseRowItem = {
                title: "Sosiaalikulut (ei mukana summissa)",
                titleJSX: Data_Maybe.Just.create(socialExpenseText("Sosiaalikulut (ei mukana summissa)")),
                value: TacoList.JSXValue.create(socialExpenseText(Formatters.formatCurrencyValue(props.projectCurrency)(totalSocialExpensePrice))),
                disabled: false,
                onClick: Data_Maybe.Nothing.value,
                getItems: function (v7) {
                    return [  ];
                },
                hasItems: false,
                initExpanded: false,
                id: "Sosiaalikulut (ei mukana summissa)"
            };
            var socialExpenseRow = (function () {
                if (props.showSocialExpensesInCosts) {
                    return Common.emptyArray;
                };
                return [ socialExpenseRowItem ];
            })();
            return Data_Semigroup.append(Data_Semigroup.semigroupArray)(groups)(socialExpenseRow);
        })();
        var onSaveModal = function __do() {
            Effect_Class.liftEffect(Effect_Class.monadEffectEffect)(WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshClassificationGroups({
                request_params: props.projectId
            })))();
            return v5.value1(function (v6) {
                return false;
            })();
        };
        var loading = v.value1 || loadingGroupsData;
        props.setLoading("ComponentGroupsTree")(loading)();
        var newGroupsEditModal = React_Basic.element($foreign.newComponentGroupEditModal)({
            isOpen: v5.value0,
            onClose: v5.value1(function (v6) {
                return false;
            }),
            onSave: onSaveModal,
            projectId: props.projectId
        });
        return ReactBasicUtils["div'"]("__ComponentGroupsTree")([ Gap.gapX(TacoConstants.M.value)([ React_Basic_DOM_Generated.h3()({
            className: "heading",
            children: Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM.text("Ryhm\xe4t"))
        }), TacoButton.component()()({
            buttonStyle: TacoButton_Types.Link.value,
            text: "Muokkaa",
            onClick: Data_Maybe.Just.create(v3.value1(function (v6) {
                return true;
            })),
            testId: "edit-component-groups-button"
        }), TacoButton.component()()({
            buttonStyle: TacoButton_Types.Link.value,
            text: (function () {
                if (v4.value0) {
                    return "Piilota";
                };
                return "N\xe4yt\xe4";
            })(),
            onClick: Data_Maybe.Just.create(v4.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))),
            testId: "toggle-component-groups-button"
        }), Data_Monoid.guard(React_Basic.monoidJSX)(newGroupsEditModalEnabled)(TacoButton.component()()({
            buttonStyle: TacoButton_Types.Link.value,
            text: "Muokkaa (uusi)",
            onClick: Data_Maybe.Just.create(v5.value1(function (v6) {
                return true;
            })),
            testId: "toggle-component-groups-button"
        })) ]), Data_Monoid.guard(React_Basic.monoidJSX)(v4.value0)(TacoList.component({
            items: groupsItems
        })), editGroupsModal({
            isActive: v3.value0,
            onClose: v3.value1(function (v6) {
                return false;
            }),
            groups: v.value0,
            projectId: props.projectId,
            nonEmptyGroups: nonEmptyGroups
        }), Data_Monoid.guard(React_Basic.monoidJSX)(v5.value0)(newGroupsEditModal) ]);
    };
});
module.exports = {
    componentGroupsTree: componentGroupsTree
};
