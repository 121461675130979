// Generated by purs version 0.14.5
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoModal = require("../TacoModal/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var component = TofuHooks.mkHookComponent("editOrganizationModal")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState(new Data_Maybe.Just(v.orgData.quotas.costControlUsers))();
        var v2 = TofuHooks.useState(new Data_Maybe.Just(v.orgData.quotas.costControlRWUsers))();
        var v3 = TofuHooks.useState(new Data_Maybe.Just(v.orgData.quotas.estimationUsers))();
        var v4 = TofuHooks.useState(new Data_Maybe.Just(v.orgData.quotas.estimationRWUsers))();
        var v5 = TofuHooks.useState(Data_Maybe.maybe("")(Data_Newtype.unwrap())(v.orgData.cognitoIdentityProviderName))();
        var v6 = TofuHooks.useState(Data_Maybe.maybe("")(Data_Newtype.unwrap())(v.orgData.adminetTenantId))();
        var setCognitoIdentityProviderName = function ($$new) {
            return v5.value1(function (v7) {
                return $$new;
            });
        };
        var setCEUsersQuota = function ($$new) {
            return v3.value1(function (v7) {
                return new Data_Maybe.Just($$new);
            });
        };
        var setCERWUsersQuota = function ($$new) {
            return v4.value1(function (v7) {
                return new Data_Maybe.Just($$new);
            });
        };
        var setCCUsersQuota = function ($$new) {
            return v1.value1(function (v7) {
                return new Data_Maybe.Just($$new);
            });
        };
        var setCCRWUsersQuota = function ($$new) {
            return v2.value1(function (v7) {
                return new Data_Maybe.Just($$new);
            });
        };
        var setAdminetTenantId = function ($$new) {
            return v6.value1(function (v7) {
                return $$new;
            });
        };
        var saveButton = (function () {
            if (v1.value0 instanceof Data_Maybe.Just && (v2.value0 instanceof Data_Maybe.Just && (v3.value0 instanceof Data_Maybe.Just && v4.value0 instanceof Data_Maybe.Just))) {
                return TacoButton.component()()({
                    text: "Tallenna organisaation tiedot",
                    buttonStyle: TacoButton_Types.Outlined.value,
                    onClick: new Data_Maybe.Just(function __do() {
                        v.onSave({
                            organization: v.orgData.organization,
                            cognitoIdentityProviderName: (function () {
                                var $25 = Data_String_Common["null"](v5.value0);
                                if ($25) {
                                    return Data_Maybe.Nothing.value;
                                };
                                return Data_Maybe.Just.create(v5.value0);
                            })(),
                            adminetTenantId: (function () {
                                var $26 = Data_String_Common["null"](v6.value0);
                                if ($26) {
                                    return Data_Maybe.Nothing.value;
                                };
                                return Data_Maybe.Just.create(v6.value0);
                            })(),
                            quotas: {
                                costControlUsers: v1.value0.value0,
                                costControlRWUsers: v2.value0.value0,
                                estimationUsers: v3.value0.value0,
                                estimationRWUsers: v4.value0.value0
                            }
                        })();
                        return v.onClose();
                    })
                });
            };
            return TacoButton.component()()({
                text: "Tallenna organisaation tiedot",
                buttonStyle: TacoButton_Types.Outlined.value,
                onClick: Data_Maybe.Nothing.value,
                disabled: true
            });
        })();
        var modalBody = TacoModal.modalBody({
            contents: [ TacoInput.numberInput()()(Data_Eq.eqNumber)({
                label: "Raportoinnin k\xe4ytt\xe4jien raja",
                value: Data_Int.toNumber(Data_Maybe.fromMaybe(0)(v1.value0)),
                precision: 0,
                onSave: function ($46) {
                    return setCCUsersQuota(Data_Int.floor($46));
                }
            }), TacoInput.numberInput()()(Data_Eq.eqNumber)({
                label: "Kirjoitusoikeudellisten raportoinnin k\xe4ytt\xe4jien raja",
                value: Data_Int.toNumber(Data_Maybe.fromMaybe(0)(v2.value0)),
                precision: 0,
                onSave: function ($47) {
                    return setCCRWUsersQuota(Data_Int.floor($47));
                }
            }), TacoInput.numberInput()()(Data_Eq.eqNumber)({
                label: "Laskennan k\xe4ytt\xe4jien raja",
                value: Data_Int.toNumber(Data_Maybe.fromMaybe(0)(v3.value0)),
                precision: 0,
                onSave: function ($48) {
                    return setCEUsersQuota(Data_Int.floor($48));
                }
            }), TacoInput.numberInput()()(Data_Eq.eqNumber)({
                label: "Kirjoitusoikeudellisten laskennan k\xe4ytt\xe4jien raja",
                value: Data_Int.toNumber(Data_Maybe.fromMaybe(0)(v4.value0)),
                precision: 0,
                onSave: function ($49) {
                    return setCERWUsersQuota(Data_Int.floor($49));
                }
            }), TacoInput.stringInput()()(Data_Eq.eqString)({
                label: "Cognito Identity Provider Name",
                value: v5.value0,
                onSave: setCognitoIdentityProviderName
            }), TacoInput.stringInput()()(Data_Eq.eqString)({
                label: "Adminet Tenant ID, Ei toiminnassa, k\xe4yt\xe4 uutta superadmin sivua asettaaksesi tenantin",
                value: v6.value0,
                disabled: true,
                onSave: setAdminetTenantId
            }) ]
        });
        var modalActions = TacoModal.modalActions({
            contents: [ TacoButton.component()()({
                onClick: new Data_Maybe.Just(v.onClose),
                text: "Peruuta"
            }), saveButton ]
        });
        return TacoModal.component()()({
            isActive: true,
            onClose: v.onClose,
            heading: "Muokkaa organisaatioa" + Data_Newtype.unwrap()(v.orgData.organization),
            contents: [ modalBody, modalActions ]
        });
    };
});
module.exports = {
    component: component
};
