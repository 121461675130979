// Generated by purs version 0.14.5
"use strict";
var Data_Array = require("../Data.Array/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_String_Pattern = require("../Data.String.Pattern/index.js");
var DateFnsFFI = require("../DateFnsFFI/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var Types_CostGroup = require("../Types.CostGroup/index.js");
var Types_Invoice = require("../Types.Invoice/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Project = require("../Types.Project/index.js");
var Utils = require("../Utils/index.js");
var ByCostGroupCode = (function () {
    function ByCostGroupCode() {

    };
    ByCostGroupCode.value = new ByCostGroupCode();
    return ByCostGroupCode;
})();
var ByCostClassCode = (function () {
    function ByCostClassCode() {

    };
    ByCostClassCode.value = new ByCostClassCode();
    return ByCostClassCode;
})();
var ByDate = (function () {
    function ByDate() {

    };
    ByDate.value = new ByDate();
    return ByDate;
})();
var ByInvoiceBunchId = (function () {
    function ByInvoiceBunchId() {

    };
    ByInvoiceBunchId.value = new ByInvoiceBunchId();
    return ByInvoiceBunchId;
})();
var ByInvoiceNumber = (function () {
    function ByInvoiceNumber() {

    };
    ByInvoiceNumber.value = new ByInvoiceNumber();
    return ByInvoiceNumber;
})();
var ByLineDescription = (function () {
    function ByLineDescription() {

    };
    ByLineDescription.value = new ByLineDescription();
    return ByLineDescription;
})();
var BySupplier = (function () {
    function BySupplier() {

    };
    BySupplier.value = new BySupplier();
    return BySupplier;
})();
var BySupplierBusinessId = (function () {
    function BySupplierBusinessId() {

    };
    BySupplierBusinessId.value = new BySupplierBusinessId();
    return BySupplierBusinessId;
})();
var BySupplierInvoiceNumber = (function () {
    function BySupplierInvoiceNumber() {

    };
    BySupplierInvoiceNumber.value = new BySupplierInvoiceNumber();
    return BySupplierInvoiceNumber;
})();
var ByHours = (function () {
    function ByHours() {

    };
    ByHours.value = new ByHours();
    return ByHours;
})();
var ByValue = (function () {
    function ByValue() {

    };
    ByValue.value = new ByValue();
    return ByValue;
})();
var InvoiceLinesByInvoice = (function () {
    function InvoiceLinesByInvoice(value0) {
        this.value0 = value0;
    };
    InvoiceLinesByInvoice.create = function (value0) {
        return new InvoiceLinesByInvoice(value0);
    };
    return InvoiceLinesByInvoice;
})();
var InvoiceLinesBySupplier = (function () {
    function InvoiceLinesBySupplier(value0) {
        this.value0 = value0;
    };
    InvoiceLinesBySupplier.create = function (value0) {
        return new InvoiceLinesBySupplier(value0);
    };
    return InvoiceLinesBySupplier;
})();
var InvoiceLinesByInvoiceBunchId = (function () {
    function InvoiceLinesByInvoiceBunchId(value0) {
        this.value0 = value0;
    };
    InvoiceLinesByInvoiceBunchId.create = function (value0) {
        return new InvoiceLinesByInvoiceBunchId(value0);
    };
    return InvoiceLinesByInvoiceBunchId;
})();
var matchesInvoiceMonth = function (dates) {
    return function (line) {
        var lineDate = Utils.lossyParseJSDate(Utils.defaultNull("")(line.date));
        return Data_Array.any(DateFnsFFI.isSameMonth(lineDate))(dates);
    };
};
var matchesInvoiceLineField = function (pattern) {
    return function (invoiceLine) {
        var invoiceLineFieldValues = [ Utils.defaultNull("")(invoiceLine.lineDescription), Utils.defaultNull("")(invoiceLine.description), Data_Show.show(Types_Invoice.showInvoiceId)(invoiceLine.invoiceId), Data_Show.show(Data_Nullable.showNullable(Types_Invoice.showInvoiceBunchId))(invoiceLine.invoiceBunchId), Utils.defaultNull("")(invoiceLine.invoiceNumber), Utils.defaultNull("")(invoiceLine.supplier), Utils.defaultNull("")(invoiceLine.supplierBusinessId), Utils.defaultNull("")(invoiceLine.supplierInvoiceNumber), Data_Newtype.un()(Types_CostGroup.CostGroupCode)(Utils.defaultNull("")(invoiceLine.costGroupCode)), Utils.defaultNull("")(invoiceLine.costClass) ];
        return Data_Array.any((function () {
            var $68 = Data_String_CodeUnits.contains(pattern);
            return function ($69) {
                return $68(Data_String_Common.toLower($69));
            };
        })())(invoiceLineFieldValues);
    };
};
var invoiceLinesToInvoices = function (invoiceLines) {
    var mkPurchaseInvoice = function (lines) {
        var head = Data_Array_NonEmpty.head(lines);
        return {
            type: head.type,
            date: Utils.defaultNull("")(head.date),
            description: head.description,
            invoiceId: head.invoiceId,
            invoiceNumber: Utils.defaultNull("")(head.invoiceNumber),
            invoiceImg: Utils.defaultNull("")(head.invoiceImg),
            supplier: Types_Project.Supplier(Utils.defaultNull("")(head.supplier)),
            supplierBusinessId: Data_Nullable.toMaybe(head.supplierBusinessId),
            supplierInvoiceNumber: Data_Nullable.toMaybe(head.supplierInvoiceNumber),
            invoiceLines: Data_Array_NonEmpty.toArray(lines)
        };
    };
    return Data_Functor.map(Data_Functor.functorArray)(mkPurchaseInvoice)(Data_Array.groupBy(Data_Function.on(Data_Eq.eq(Types_Invoice.eqInvoiceId))(function (v) {
        return v.invoiceId;
    }))(Data_Array.sortWith(Types_Invoice.ordInvoiceId)(function (v) {
        return v.invoiceId;
    })(invoiceLines)));
};
var getSupplierInvoiceLineGroupSortingValue = function (v) {
    if (v instanceof BySupplierBusinessId) {
        var $70 = Data_Maybe.fromMaybe("");
        return function ($71) {
            return TacoTable_Types.StringValue.create($70((function (v1) {
                return v1.supplierBusinessId;
            })($71)));
        };
    };
    if (v instanceof ByValue) {
        var $72 = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber);
        var $73 = Data_Functor.map(Data_Functor.functorArray)(function (v1) {
            return v1.value;
        });
        return function ($74) {
            return TacoTable_Types.NumberValue.create($72($73((function (v1) {
                return v1.invoiceLines;
            })($74))));
        };
    };
    if (v instanceof ByHours) {
        var $75 = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber);
        var $76 = Data_Functor.map(Data_Functor.functorArray)(Utils.defaultNull(0.0));
        var $77 = Data_Functor.map(Data_Functor.functorArray)(function (v1) {
            return v1.hours;
        });
        return function ($78) {
            return TacoTable_Types.NumberValue.create($75($76($77((function (v1) {
                return v1.invoiceLines;
            })($78)))));
        };
    };
    var $79 = Data_Newtype.un()(Types_Project.Supplier);
    return function ($80) {
        return TacoTable_Types.StringValue.create($79((function (v1) {
            return v1.supplier;
        })($80)));
    };
};
var getPurchaseInvoiceSortingValue = function (v) {
    if (v instanceof ByInvoiceNumber) {
        return function ($81) {
            return TacoTable_Types.StringValue.create((function (v1) {
                return v1.invoiceNumber;
            })($81));
        };
    };
    if (v instanceof BySupplier) {
        var $82 = Data_Newtype.un()(Types_Project.Supplier);
        return function ($83) {
            return TacoTable_Types.StringValue.create($82((function (v1) {
                return v1.supplier;
            })($83)));
        };
    };
    if (v instanceof BySupplierBusinessId) {
        var $84 = Data_Maybe.fromMaybe("");
        return function ($85) {
            return TacoTable_Types.StringValue.create($84((function (v1) {
                return v1.supplierBusinessId;
            })($85)));
        };
    };
    if (v instanceof ByValue) {
        var $86 = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber);
        var $87 = Data_Functor.map(Data_Functor.functorArray)(function (v1) {
            return v1.value;
        });
        return function ($88) {
            return TacoTable_Types.NumberValue.create($86($87((function (v1) {
                return v1.invoiceLines;
            })($88))));
        };
    };
    if (v instanceof ByInvoiceBunchId) {
        var $89 = Data_String_Common.joinWith(", ");
        var $90 = Data_Array.sort(Data_Ord.ordString);
        var $91 = Data_Functor.map(Data_Functor.functorArray)(Data_String_Common.toLower);
        var $92 = Data_Array.nub(Data_Ord.ordString);
        var $93 = Data_Functor.map(Data_Functor.functorArray)(Data_Newtype.un()(Types_Invoice.InvoiceBunchId));
        var $94 = Data_Functor.map(Data_Functor.functorArray)(Data_Nullable.toMaybe);
        var $95 = Data_Functor.map(Data_Functor.functorArray)(function (v1) {
            return v1.invoiceBunchId;
        });
        return function ($96) {
            return TacoTable_Types.StringValue.create($89($90($91($92($93(Data_Array.catMaybes($94($95((function (v1) {
                return v1.invoiceLines;
            })($96))))))))));
        };
    };
    return function ($97) {
        return TacoTable_Types.StringValue.create((function (v1) {
            return v1.date;
        })($97));
    };
};
var getNameInvoiceLineSortProperty = {
    getName: function (v) {
        return "InvoiceLineSortProperty";
    }
};
var invoiceLinesTableInstance = TacoTable.mkTableSortable(getNameInvoiceLineSortProperty)("InvoiceLines");
var getInvoiceLineSortingValue = function (v) {
    if (v instanceof ByCostClassCode) {
        var $98 = Data_Newtype.un()(Types_Newtypes.CostClassCode);
        return function ($99) {
            return TacoTable_Types.StringValue.create($98((function (v1) {
                return v1.costClassCode;
            })($99)));
        };
    };
    if (v instanceof ByDate) {
        var $100 = Utils.defaultNull("");
        return function ($101) {
            return TacoTable_Types.StringValue.create($100((function (v1) {
                return v1.date;
            })($101)));
        };
    };
    if (v instanceof ByInvoiceNumber) {
        var $102 = Utils.defaultNull("");
        return function ($103) {
            return TacoTable_Types.StringValue.create($102((function (v1) {
                return v1.invoiceNumber;
            })($103)));
        };
    };
    if (v instanceof ByLineDescription) {
        var $104 = Utils.defaultNull("");
        return function ($105) {
            return TacoTable_Types.StringValue.create($104((function (v1) {
                return v1.lineDescription;
            })($105)));
        };
    };
    if (v instanceof ByValue) {
        return function ($106) {
            return TacoTable_Types.NumberValue.create((function (v1) {
                return v1.value;
            })($106));
        };
    };
    if (v instanceof ByInvoiceBunchId) {
        var $107 = Data_Newtype.un()(Types_Invoice.InvoiceBunchId);
        var $108 = Utils.defaultNull("");
        return function ($109) {
            return TacoTable_Types.StringValue.create($107($108((function (v1) {
                return v1.invoiceBunchId;
            })($109))));
        };
    };
    if (v instanceof ByHours) {
        var $110 = Utils.defaultNull(0.0);
        return function ($111) {
            return TacoTable_Types.NumberValue.create($110((function (v1) {
                return v1.hours;
            })($111)));
        };
    };
    var $112 = Data_Show.show(Types_CostGroup.showCostGroupCode);
    var $113 = Utils.defaultNull("");
    return function ($114) {
        return TacoTable_Types.StringValue.create($112($113((function (v1) {
            return v1.costGroupCode;
        })($114))));
    };
};
var getInvoiceBunchIdGroupSortingValue = function (v) {
    if (v instanceof ByInvoiceBunchId) {
        var $115 = Data_Newtype.un()(Types_Invoice.InvoiceBunchId);
        var $116 = Data_Maybe.fromMaybe("");
        return function ($117) {
            return TacoTable_Types.StringValue.create($115($116((function (v1) {
                return v1.invoiceBunchId;
            })($117))));
        };
    };
    if (v instanceof ByValue) {
        var $118 = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber);
        var $119 = Data_Functor.map(Data_Functor.functorArray)(function (v1) {
            return v1.value;
        });
        return function ($120) {
            return TacoTable_Types.NumberValue.create($118($119((function (v1) {
                return v1.invoiceLines;
            })($120))));
        };
    };
    if (v instanceof ByHours) {
        var $121 = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber);
        var $122 = Data_Functor.map(Data_Functor.functorArray)(Utils.defaultNull(0.0));
        var $123 = Data_Functor.map(Data_Functor.functorArray)(function (v1) {
            return v1.hours;
        });
        return function ($124) {
            return TacoTable_Types.NumberValue.create($121($122($123((function (v1) {
                return v1.invoiceLines;
            })($124)))));
        };
    };
    var $125 = Data_Newtype.un()(Types_Invoice.InvoiceBunchId);
    var $126 = Data_Maybe.fromMaybe("");
    return function ($127) {
        return TacoTable_Types.StringValue.create($125($126((function (v1) {
            return v1.invoiceBunchId;
        })($127))));
    };
};
var filterStringToPattern = function ($128) {
    return Data_String_Pattern.Pattern(Data_String_Common.trim(Data_String_Common.toLower($128)));
};
var matchesByTypeAndPattern = function (types) {
    return function (filterString) {
        return function (invoiceLine) {
            var pattern = filterStringToPattern(filterString);
            var byInvoiceType = function (line) {
                return Data_Array.elem(Types_Invoice.eqInvoiceType)(line.type)(types);
            };
            var byFilterString = function (line) {
                return matchesInvoiceLineField(pattern)(line);
            };
            return Data_Foldable.all(Data_Foldable.foldableArray)(Data_HeytingAlgebra.heytingAlgebraBoolean)(Data_Eq.eq(Data_Eq.eqBoolean)(true))([ byFilterString(invoiceLine), byInvoiceType(invoiceLine) ]);
        };
    };
};
var eqInvoiceLineSortProperty = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ByCostGroupCode && y instanceof ByCostGroupCode) {
                return true;
            };
            if (x instanceof ByCostClassCode && y instanceof ByCostClassCode) {
                return true;
            };
            if (x instanceof ByDate && y instanceof ByDate) {
                return true;
            };
            if (x instanceof ByInvoiceBunchId && y instanceof ByInvoiceBunchId) {
                return true;
            };
            if (x instanceof ByInvoiceNumber && y instanceof ByInvoiceNumber) {
                return true;
            };
            if (x instanceof ByLineDescription && y instanceof ByLineDescription) {
                return true;
            };
            if (x instanceof BySupplier && y instanceof BySupplier) {
                return true;
            };
            if (x instanceof BySupplierBusinessId && y instanceof BySupplierBusinessId) {
                return true;
            };
            if (x instanceof BySupplierInvoiceNumber && y instanceof BySupplierInvoiceNumber) {
                return true;
            };
            if (x instanceof ByHours && y instanceof ByHours) {
                return true;
            };
            if (x instanceof ByValue && y instanceof ByValue) {
                return true;
            };
            return false;
        };
    }
};
var ordInvoiceLineSortProperty = {
    compare: function (x) {
        return function (y) {
            if (x instanceof ByCostGroupCode && y instanceof ByCostGroupCode) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByCostGroupCode) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByCostGroupCode) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByCostClassCode && y instanceof ByCostClassCode) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByCostClassCode) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByCostClassCode) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByDate && y instanceof ByDate) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByDate) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByDate) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByInvoiceBunchId && y instanceof ByInvoiceBunchId) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByInvoiceBunchId) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByInvoiceBunchId) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByInvoiceNumber && y instanceof ByInvoiceNumber) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByInvoiceNumber) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByInvoiceNumber) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByLineDescription && y instanceof ByLineDescription) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByLineDescription) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByLineDescription) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof BySupplier && y instanceof BySupplier) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof BySupplier) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof BySupplier) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof BySupplierBusinessId && y instanceof BySupplierBusinessId) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof BySupplierBusinessId) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof BySupplierBusinessId) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof BySupplierInvoiceNumber && y instanceof BySupplierInvoiceNumber) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof BySupplierInvoiceNumber) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof BySupplierInvoiceNumber) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByHours && y instanceof ByHours) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByHours) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByHours) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByValue && y instanceof ByValue) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at InvoiceUtils (line 156, column 1 - line 156, column 74): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqInvoiceLineSortProperty;
    }
};
var createInvoiceResultsGroupedBySupplier = function (v) {
    return function (lines) {
        var supplierComputationalValue = (function () {
            var $129 = Utils.defaultNull("");
            return function ($130) {
                return Data_String_Common.toLower(Data_String_Common.trim($129($130)));
            };
        })();
        var mkResult = function (invoiceLines) {
            return {
                supplier: Types_Project.Supplier(Utils.defaultNull("")((function (v1) {
                    return v1.supplier;
                })(Data_Array_NonEmpty.head(invoiceLines)))),
                supplierBusinessId: Data_Nullable.toMaybe((function (v1) {
                    return v1.supplierBusinessId;
                })(Data_Array_NonEmpty.head(invoiceLines))),
                supplierInvoiceNumber: Data_Nullable.toMaybe((function (v1) {
                    return v1.supplierInvoiceNumber;
                })(Data_Array_NonEmpty.head(invoiceLines))),
                invoiceLines: Data_Array_NonEmpty.toArray(invoiceLines)
            };
        };
        return TacoTable_Types.sortItemsByComparable(getSupplierInvoiceLineGroupSortingValue)(v.sortingOrder)(v.sortProperty)(Data_Functor.map(Data_Functor.functorArray)(mkResult)(Data_Array.groupBy(function (a) {
            return function (b) {
                return supplierComputationalValue(a.supplier) === supplierComputationalValue(b.supplier);
            };
        })(Data_Array.sortWith(Data_Ord.ordString)(function ($131) {
            return supplierComputationalValue((function (v1) {
                return v1.supplier;
            })($131));
        })(lines))));
    };
};
var createInvoiceResultsGroupedByInvoiceBunchId = function (v) {
    return function (lines) {
        var mkResult = function (invoiceLines) {
            return {
                invoiceBunchId: Data_Nullable.toMaybe((function (v1) {
                    return v1.invoiceBunchId;
                })(Data_Array_NonEmpty.head(invoiceLines))),
                invoiceLines: Data_Array_NonEmpty.toArray(invoiceLines)
            };
        };
        var invoiceBunchIdComputationalValue = (function () {
            var $132 = Data_Newtype.un()(Types_Invoice.InvoiceBunchId);
            var $133 = Utils.defaultNull("");
            return function ($134) {
                return Data_String_Common.toLower(Data_String_Common.trim($132($133($134))));
            };
        })();
        return TacoTable_Types.sortItemsByComparable(getInvoiceBunchIdGroupSortingValue)(v.sortingOrder)(v.sortProperty)(Data_Functor.map(Data_Functor.functorArray)(mkResult)(Data_Array.groupBy(function (a) {
            return function (b) {
                return invoiceBunchIdComputationalValue(a.invoiceBunchId) === invoiceBunchIdComputationalValue(b.invoiceBunchId);
            };
        })(Data_Array.sortWith(Data_Ord.ordString)(function ($135) {
            return invoiceBunchIdComputationalValue((function (v1) {
                return v1.invoiceBunchId;
            })($135));
        })(lines))));
    };
};
var createInvoiceResults = function (v) {
    return function (invoiceLines) {
        return TacoTable_Types.sortItemsByComparable(getPurchaseInvoiceSortingValue)(v.sortingOrder)(v.sortProperty)(invoiceLinesToInvoices(invoiceLines));
    };
};
module.exports = {
    createInvoiceResults: createInvoiceResults,
    createInvoiceResultsGroupedBySupplier: createInvoiceResultsGroupedBySupplier,
    createInvoiceResultsGroupedByInvoiceBunchId: createInvoiceResultsGroupedByInvoiceBunchId,
    getPurchaseInvoiceSortingValue: getPurchaseInvoiceSortingValue,
    getSupplierInvoiceLineGroupSortingValue: getSupplierInvoiceLineGroupSortingValue,
    getInvoiceBunchIdGroupSortingValue: getInvoiceBunchIdGroupSortingValue,
    getInvoiceLineSortingValue: getInvoiceLineSortingValue,
    matchesByTypeAndPattern: matchesByTypeAndPattern,
    matchesInvoiceLineField: matchesInvoiceLineField,
    matchesInvoiceMonth: matchesInvoiceMonth,
    filterStringToPattern: filterStringToPattern,
    invoiceLinesToInvoices: invoiceLinesToInvoices,
    ByCostGroupCode: ByCostGroupCode,
    ByCostClassCode: ByCostClassCode,
    ByDate: ByDate,
    ByInvoiceBunchId: ByInvoiceBunchId,
    ByInvoiceNumber: ByInvoiceNumber,
    ByLineDescription: ByLineDescription,
    BySupplier: BySupplier,
    BySupplierBusinessId: BySupplierBusinessId,
    BySupplierInvoiceNumber: BySupplierInvoiceNumber,
    ByHours: ByHours,
    ByValue: ByValue,
    InvoiceLinesByInvoice: InvoiceLinesByInvoice,
    InvoiceLinesBySupplier: InvoiceLinesBySupplier,
    InvoiceLinesByInvoiceBunchId: InvoiceLinesByInvoiceBunchId,
    invoiceLinesTableInstance: invoiceLinesTableInstance,
    eqInvoiceLineSortProperty: eqInvoiceLineSortProperty,
    ordInvoiceLineSortProperty: ordInvoiceLineSortProperty,
    getNameInvoiceLineSortProperty: getNameInvoiceLineSortProperty
};
