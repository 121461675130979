// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var StyleProperties = require("../StyleProperties/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var r200TableGroupContainerStyles = function (props) {
    return Box.boxStyle([ new Box.PaddingBottom(TacoConstants.XXL.value), Data_Monoid.guard(Box.monoidBoxProp)(props.shouldMarginLeft)(new Box.MarginLeft(TacoConstants.XL.value)) ]);
};
var r200ResultTableRowStyles = Box.boxStyle([ Box.Style.create(React_Basic_Emotion.css()({
    borderTop: StyleProperties.border(TacoConstants.BorderLight.value)
})) ]);
var r200CostGroupSummariesStyles = Box.boxStyle([ new Box.MarginLeft(TacoConstants.XL.value) ]);
module.exports = {
    r200TableGroupContainerStyles: r200TableGroupContainerStyles,
    r200CostGroupSummariesStyles: r200CostGroupSummariesStyles,
    r200ResultTableRowStyles: r200ResultTableRowStyles
};
