export const AllFilters = [
  {
    DataSourceName: "Components",
    Filters: [
      {
        FilterExpression: "=Fields!subProject.Value",
        Operator: "In",
        FilterValues: ["=Parameters!subProjects.Value"],
        ParameterName: "subProjects",
      },
      {
        FilterExpression:
          "=Code.ArrayContainsItemsOfArray(Fields!locations.Value, Parameters!locations.Value)",
        Operator: "Equal",
        FilterValues: ["True"],
        ParameterName: "locations",
      },
      {
        FilterExpression:
          "=Code.RowFilter(Fields!checked.Value, Fields!flagged.Value, Parameters!rowType.Value)",
        Operator: "Equal",
        FilterValues: ["True"],
        ParameterName: "rowType",
      },
      {
        FilterExpression:
          "=Code.FilterCode(Fields!componentCode.Value, Parameters!codeRange.Value)",
        Operator: "Equal",
        FilterValues: ["True"],
        ParameterName: "codeRange",
      },
      {
        FilterExpression:
          "=Code.FilterTotalPrice(Fields!totalPrice.Value, Parameters!showOnlyRowsWithValue.Value)",
        Operator: "Equal",
        FilterValues: ["True"],
        ParameterName: "showOnlyRowsWithValue",
      },
      {
        FilterExpression:
          "=Code.FilterRowsWithMemo(Fields!memo.Value, Parameters!showOnlyRowsWithMemo.Value)",
        Operator: "Equal",
        FilterValues: ["True"],
        ParameterName: "showOnlyRowsWithMemo",
      },
      {
        FilterExpression:
          "=Code.FilterElementsByClass(Fields!elements.Value, Parameters!elementClass.Value)",
        Operator: "Equal",
        FilterValues: ["True"],
        ParameterName: "elementClass",
      },
      {
        FilterExpression:
          "=Code.FilterElementsByCode(Fields!elements.Value, Parameters!elementCode.Value)",
        Operator: "Equal",
        FilterValues: ["True"],
        ParameterName: "elementCode",
      },
    ],
  },
];
