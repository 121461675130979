// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Number_Format = require("../Data.Number.Format/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var Record = require("../Record/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Income = require("../Types.Income/index.js");
var Types_Project = require("../Types.Project/index.js");
var UseNullableRef = require("../UseNullableRef/index.js");
var ByDescription = (function () {
    function ByDescription() {

    };
    ByDescription.value = new ByDescription();
    return ByDescription;
})();
var ByIncomeItemCode = (function () {
    function ByIncomeItemCode() {

    };
    ByIncomeItemCode.value = new ByIncomeItemCode();
    return ByIncomeItemCode;
})();
var ByProjectId = (function () {
    function ByProjectId() {

    };
    ByProjectId.value = new ByProjectId();
    return ByProjectId;
})();
var ByInvoiceNumber = (function () {
    function ByInvoiceNumber() {

    };
    ByInvoiceNumber.value = new ByInvoiceNumber();
    return ByInvoiceNumber;
})();
var ByInvoiceDate = (function () {
    function ByInvoiceDate() {

    };
    ByInvoiceDate.value = new ByInvoiceDate();
    return ByInvoiceDate;
})();
var ByNetSum = (function () {
    function ByNetSum() {

    };
    ByNetSum.value = new ByNetSum();
    return ByNetSum;
})();
var ByCreatedAt = (function () {
    function ByCreatedAt() {

    };
    ByCreatedAt.value = new ByCreatedAt();
    return ByCreatedAt;
})();
var ByExportDate = (function () {
    function ByExportDate() {

    };
    ByExportDate.value = new ByExportDate();
    return ByExportDate;
})();
var styleSet = TacoTable_Styles.optimizedTableStyleSet;
var getNameImportSalesInvoiceSortProperty = {
    getName: function (v) {
        return "ImportSalesInvoiceSortProperty";
    }
};
var eqInvoiceLineSortProperty = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ByDescription && y instanceof ByDescription) {
                return true;
            };
            if (x instanceof ByIncomeItemCode && y instanceof ByIncomeItemCode) {
                return true;
            };
            if (x instanceof ByProjectId && y instanceof ByProjectId) {
                return true;
            };
            if (x instanceof ByInvoiceNumber && y instanceof ByInvoiceNumber) {
                return true;
            };
            if (x instanceof ByInvoiceDate && y instanceof ByInvoiceDate) {
                return true;
            };
            if (x instanceof ByNetSum && y instanceof ByNetSum) {
                return true;
            };
            if (x instanceof ByCreatedAt && y instanceof ByCreatedAt) {
                return true;
            };
            if (x instanceof ByExportDate && y instanceof ByExportDate) {
                return true;
            };
            return false;
        };
    }
};
var importSalesInvoicesTableOptimized = function (dictEq) {
    return TacoTable.tableOptimized(getNameImportSalesInvoiceSortProperty)(eqInvoiceLineSortProperty)(dictEq);
};
var mkImportSalesInvoicesTable = TofuHooks.mkHookComponent("MkImportSalesInvoicesTable")(function (props) {
    var isSelected = function (id) {
        return Data_Array.elem(Data_Eq.eqInt)(id)(props.selected);
    };
    var getMappedRow = function (line) {
        var emptyInvoice = {
            id: 0,
            invoiceNumber: "",
            invoiceDate: "",
            dueDate: "",
            lines: [  ]
        };
        var invoice = Data_Maybe.fromMaybe(emptyInvoice)(Data_Array.find(function (i) {
            return i.id === line.invoiceId;
        })(props.invoices));
        return Record.merge()()(line)({
            invoiceNumber: invoice.invoiceNumber,
            invoiceDate: invoice.invoiceDate
        });
    };
    var mappedInvoiceRows = Data_Functor.map(Data_Functor.functorArray)(getMappedRow)(props.lines);
    var columns = (function () {
        var incomeItemCodeElement = function (r) {
            return Data_Newtype.un()(Types_Income.IncomeItemCode)(Data_Maybe.fromMaybe("")(r.incomeItemCode));
        };
        var checkBoxElement = function (v) {
            return TacoCheckbox.component()()({
                className: "",
                isChecked: isSelected(v.id),
                onToggle: TacoCheckbox.WithShift.create(props.toggleCheckbox(v.id)),
                labelText: Data_Maybe.Nothing.value,
                testId: "checkbox-" + Data_Show.show(Data_Show.showInt)(v.id)
            });
        };
        return [ {
            key: "checkbox",
            label: React_Basic_DOM.text(""),
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], checkBoxElement),
            sortProperty: new Data_Maybe.Just(ByProjectId.value),
            width: new TacoTable_Types.Flex(2),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "projectId",
            label: React_Basic_DOM.text("Projektin id"),
            cell: TacoTable_Types.PlainTextCell.create((function () {
                var $29 = Data_Maybe.fromMaybe("");
                var $30 = Data_Functor.map(Data_Maybe.functorMaybe)(Data_Show.show(Types_Project.showProjectId));
                return function ($31) {
                    return $29($30((function (v) {
                        return v.projectId;
                    })($31)));
                };
            })()),
            sortProperty: new Data_Maybe.Just(ByProjectId.value),
            width: new TacoTable_Types.Flex(30),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "invoiceNumber",
            label: React_Basic_DOM.text("Nro."),
            cell: new TacoTable_Types.PlainTextCell(function (v) {
                return v.invoiceNumber;
            }),
            sortProperty: new Data_Maybe.Just(ByInvoiceNumber.value),
            width: new TacoTable_Types.Flex(30),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "invoiceDate",
            label: React_Basic_DOM.text("Laskunpvm."),
            cell: new TacoTable_Types.PlainDateStringCell(function (v) {
                return v.invoiceDate;
            }),
            sortProperty: new Data_Maybe.Just(ByInvoiceDate.value),
            width: new TacoTable_Types.Flex(35),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "incomeItemCode",
            label: React_Basic_DOM.text("Maksuer\xe4tunnus"),
            cell: new TacoTable_Types.PlainTextCell(incomeItemCodeElement),
            sortProperty: new Data_Maybe.Just(ByIncomeItemCode.value),
            width: new TacoTable_Types.Flex(40),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "description",
            label: React_Basic_DOM.text("Riviselite"),
            cell: TacoTable_Types.PlainTextCell.create((function () {
                var $32 = Data_Maybe.fromMaybe("");
                return function ($33) {
                    return $32((function (v) {
                        return v.description;
                    })($33));
                };
            })()),
            sortProperty: new Data_Maybe.Just(ByDescription.value),
            width: new TacoTable_Types.Flex(40),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "netSum",
            label: React_Basic_DOM.text("Summa"),
            cell: TacoTable_Types.PlainEuroCell.create(function (r) {
                return r.netSum / 100.0;
            }),
            sortProperty: new Data_Maybe.Just(ByNetSum.value),
            width: new TacoTable_Types.Flex(40),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "vat",
            label: React_Basic_DOM.text("ALV%"),
            cell: TacoTable_Types.PlainTextCell.create((function () {
                var $34 = Data_Maybe.fromMaybe("");
                var $35 = Data_Functor.map(Data_Maybe.functorMaybe)(function ($37) {
                    return Data_Number_Format.toString(Data_Int.toNumber($37));
                });
                return function ($36) {
                    return $34($35((function (v) {
                        return v.vatPercent;
                    })($36)));
                };
            })()),
            sortProperty: new Data_Maybe.Just(ByNetSum.value),
            width: new TacoTable_Types.Flex(15),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "createdAt",
            label: React_Basic_DOM.text("Siirtoaika"),
            cell: new TacoTable_Types.PlainDateStringCell(function (v) {
                return v.createdAt;
            }),
            sortProperty: new Data_Maybe.Just(ByCreatedAt.value),
            width: new TacoTable_Types.Flex(40),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "exportDate",
            label: React_Basic_DOM.text("Vientiaika"),
            cell: TacoTable_Types.PlainDateStringCell.create((function () {
                var $38 = Data_Maybe.fromMaybe("");
                return function ($39) {
                    return $38((function (v) {
                        return v.exportDate;
                    })($39));
                };
            })()),
            sortProperty: new Data_Maybe.Just(ByExportDate.value),
            width: new TacoTable_Types.Flex(40),
            headerJSX: Data_Maybe.Nothing.value
        } ];
    })();
    var getInvoiceLineRow = function (v) {
        return function (row) {
            return {
                rowData: row,
                rowKey: Data_Show.show(Data_Show.showInt)(row.id),
                onClick: function (v1) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                },
                className: (function () {
                    var $23 = isSelected(row.id);
                    if ($23) {
                        return "isSelected";
                    };
                    return "";
                })(),
                foldingElement: Data_Maybe.Nothing.value,
                columns: columns
            };
        };
    };
    return function __do() {
        var tableRef = UseNullableRef.useNullableRef();
        return importSalesInvoicesTableOptimized(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "vatPercent";
            }
        })(Data_Maybe.eqMaybe(Data_Eq.eqInt)))()({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Data_Maybe.eqMaybe(Types_Project.eqProjectId)))()({
            reflectSymbol: function () {
                return "netSum";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "lineNumber";
            }
        })(Data_Eq.eqInt))()({
            reflectSymbol: function () {
                return "invoiceNumber";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "invoiceId";
            }
        })(Data_Eq.eqInt))()({
            reflectSymbol: function () {
                return "invoiceDate";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "incomeItemCode";
            }
        })(Data_Maybe.eqMaybe(Types_Income.eqIncomeItemCode)))()({
            reflectSymbol: function () {
                return "id";
            }
        })(Data_Eq.eqInt))()({
            reflectSymbol: function () {
                return "exportDate";
            }
        })(Data_Maybe.eqMaybe(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "description";
            }
        })(Data_Maybe.eqMaybe(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "createdAt";
            }
        })(Data_Eq.eqString)))({
            containerRef: tableRef,
            rows: mappedInvoiceRows,
            columns: columns,
            mkRow: getInvoiceLineRow,
            sortProperty: new Data_Maybe.Just(ByDescription.value),
            sortingOrder: TacoTable_Types.Ascending.value,
            rowHeight: TacoTable_Types.RowSmall.value,
            onSort: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit))),
            showHeader: true,
            styleSet: styleSet,
            initialScrollRow: Data_Maybe.Nothing.value,
            onResize: function (v) {
                return function (v1) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                };
            },
            columnSizeOffsets: Data_Maybe.Nothing.value
        });
    };
});
var ordInvoiceLineSortProperty = {
    compare: function (x) {
        return function (y) {
            if (x instanceof ByDescription && y instanceof ByDescription) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByDescription) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByDescription) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByIncomeItemCode && y instanceof ByIncomeItemCode) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByIncomeItemCode) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByIncomeItemCode) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByProjectId && y instanceof ByProjectId) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByProjectId) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByProjectId) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByInvoiceNumber && y instanceof ByInvoiceNumber) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByInvoiceNumber) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByInvoiceNumber) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByInvoiceDate && y instanceof ByInvoiceDate) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByInvoiceDate) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByInvoiceDate) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByNetSum && y instanceof ByNetSum) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByNetSum) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByNetSum) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByCreatedAt && y instanceof ByCreatedAt) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByCreatedAt) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByCreatedAt) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByExportDate && y instanceof ByExportDate) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at IncomeTable (line 128, column 1 - line 128, column 81): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqInvoiceLineSortProperty;
    }
};
module.exports = {
    mkImportSalesInvoicesTable: mkImportSalesInvoicesTable
};
