import { useTranslation } from "react-i18next";
import { Select, SelectItem } from "@tocoman/ui";

type OfferPageSettingsParams = {
  marginType?: SelectItem<string>;
  marginTypeChanged: (value: SelectItem<string> | undefined) => void;
  undoRedoStackChange: (value: { undo: number; redo: number }) => void;
};
export const OfferPageSettings = ({
  marginType,
  marginTypeChanged,
  undoRedoStackChange,
}: OfferPageSettingsParams) => {
  const { t } = useTranslation("estimation", { keyPrefix: "offerPage" });

  const marginTypes: SelectItem<string>[] = [
    {
      value: "economicMargin",
      label: t`economicMargin`,
      key: "economicMargin",
    },
    {
      value: "technicalMargin",
      label: t`technicalMargin`,
      key: "technicalMargin",
    },
  ];

  const handleMarginTypeChanged = (value: SelectItem<string> | undefined) => {
    marginTypeChanged(value);
    undoRedoStackChange({ undo: 0, redo: 0 });
  };

  return (
    <div className={"w-[220px]"}>
      <Select
        items={marginTypes}
        selected={marginType}
        onValueChange={handleMarginTypeChanged}
      />
    </div>
  );
};
