import { useState } from "react";

export const useFirstDataRendered = (
  initialState: boolean
): [boolean, () => void] => {
  const [firstDataRendered, setFirstDataRendered] = useState<boolean>(
    initialState
  );

  const onFirstDataRendered = () => {
    setFirstDataRendered(true);
  };

  return [firstDataRendered, onFirstDataRendered];
};
