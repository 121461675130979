import { Table } from "../../../../components/Table";
import { useMemo, useRef, useState } from "react";
import {
  CellValueChangedEvent,
  ColDef,
  ColGroupDef,
  ICellRendererParams,
  ValueGetterParams,
} from "ag-grid-community";
import { OfferClassificationSummary } from "./OfferPage";
import { useTranslation } from "react-i18next";
import { OfferPageSettings } from "./OfferPageSettings";
import { AgGridReact } from "ag-grid-react";
import { SelectItem } from "@tocoman/ui";
import {
  OfferParamsWithValue,
  ProOfferParams,
  useProOfferParamsQuery,
  useSetProOfferParamsQuery,
} from "../useOfferParamsQuery";
import {
  getCodeAndDescription,
  getHourlyRate,
  getHours,
  getMargin,
  getMarginPercentage,
  getMarginPercentageOfTotal,
  getMeasurementValue,
  getPinnedEconomicTotal,
  getTotal,
  getTotalWithMargin,
  getVATOfTotal,
  getVATWithTotal,
  setMargin,
  setMarginPercentage,
  setTotalWithMargin,
} from "./OfferPageTableFunctions";
import { editableCellRenderer } from "../../../../components/Table/editableCellColDef";
import { nonEditableCellRenderer } from "../../../../components/Table/nonEditableCellColDef";
import { Measurement } from "../../../../../../ts-bindings/Measurement";
import { UndoRedoButtons } from "./OfferPageUndoRedoButtons";
import {
  getAllOfferParams,
  savePinnedRowEdit,
  saveRegularRowEdit,
} from "./offerPageEventHandlers";
import { useUsersAgGridConfiguration } from "../../../../components/Table/saveAgGridConfigurations/useUsersAgGridConfiguration";
import { useFirstDataRendered } from "../../../../components/Table/useFirstDataRendered";
import { usePinnedBottomRowUpdater } from "../../../../components/Table/usePinnedBottomRowUpdater";
import { reportError } from "src/client-ts/utils/error";
import { AxiosError } from "axios";

type OfferPageProps = {
  rowData: OfferClassificationSummary[] | null;
  projectData: { projectId: number; taxRate: number | null; currency: string };
  measurementUnits: Measurement[];
  marginType: SelectItem<string> | undefined;
  onMarginTypeChange: (marginType: SelectItem<string> | undefined) => void;
};

export function OfferPageTable({
  rowData,
  projectData,
  measurementUnits,
  marginType,
  onMarginTypeChange,
}: OfferPageProps) {
  const { projectId, taxRate } = projectData;
  const { t } = useTranslation("estimation", { keyPrefix: "offerPage" });

  const gridRef = useRef<AgGridReact<OfferClassificationSummary>>(null);

  const [gridReady, setGridReady] = useState(false);
  const [undoRedoStack, setUndoRedoStack] = useState({ undo: 0, redo: 0 });
  const onGridReady = () => {
    setGridReady(true);
  };

  const offerParams = useProOfferParamsQuery(projectId);
  const editOfferValue = useSetProOfferParamsQuery(projectId);

  const measurementColumns = useMemo(
    () =>
      measurementUnits.map((unit) => ({
        colId: unit.unit.name,
        headerName: unit.unit.name,
        valueGetter: (params: ValueGetterParams<OfferClassificationSummary>) =>
          getMeasurementValue(params, unit, marginType?.value),
        type: ["twoDecimal", "numericColumn"],
        initialWidth: 120,
        initialHide: true,
      })),
    [marginType?.value, measurementUnits]
  );

  const columnDefs = useMemo<
    (
      | ColDef<OfferClassificationSummary>
      | ColGroupDef<OfferClassificationSummary>
    )[]
  >(() => {
    const columns: (
      | ColDef<OfferClassificationSummary>
      | ColGroupDef<OfferClassificationSummary>
    )[] = [
      {
        colId: "codeAndDescription",
        headerName: t`columns.classification`,
        valueGetter: (p) => getCodeAndDescription(p, t),
        initialWidth: 300,
      },
      {
        field: "hourlyRate",
        headerName: t`columns.hourlyRate`,
        type: ["twoDecimal", "numericColumn"],
        valueGetter: getHourlyRate,
        initialWidth: 160,
      },
      {
        field: "hours",
        headerName: t`columns.hours`,
        type: ["twoDecimal", "numericColumn"],
        valueGetter: getHours,
        initialWidth: 90,
      },
      {
        field: "total",
        headerName: t`columns.total`,
        type: ["money", "numericColumn"],
        valueGetter: getTotal,
        initialWidth: 150,
      },
      {
        field: "marginPercentage",
        headerName: t`columns.marginPercentage`,
        type: ["percentageWithZeroValue", "numericColumn", "editable"],
        valueGetter: getMarginPercentage,
        initialWidth: 100,
        valueSetter: setMarginPercentage,
      },
      {
        colId: "margin",
        headerName: t`columns.margin`,
        type: ["money", "numericColumn", "editable"],
        valueGetter: (p) => getMargin(p, marginType?.value),
        initialWidth: 120,
        valueSetter: (p) => setMargin(p, marginType?.value),
      },
      {
        colId: "totalWithMargin",
        headerName: t`columns.totalWithMargin`,
        type: ["money", "numericColumn", "editable"],
        valueGetter: (p) => getTotalWithMargin(p, marginType?.value),
        initialWidth: 150,
        cellStyle: { fontWeight: "bold" },
        editable: (params) => !params.node.isRowPinned(),
        valueSetter: (p) => setTotalWithMargin(p, marginType?.value),
        cellRenderer: (params: ICellRendererParams) => {
          if (params.node.isRowPinned()) {
            return nonEditableCellRenderer(params);
          }
          return editableCellRenderer(params);
        },
      },
      {
        colId: "marginPercentageOfTotal",
        headerName: t`columns.marginPercentageOfTotal`,
        type: ["percentageWithZeroValue", "numericColumn"],
        valueGetter: getMarginPercentageOfTotal,
        initialWidth: 120,
      },
      {
        colId: "taxOfTotal",
        headerName: t`columns.taxOfTotal` + ` ${taxRate}%`,
        valueGetter: (p) => getVATOfTotal(p, marginType?.value),
        type: ["numericColumn", "twoDecimal"],
        initialWidth: 120,
      },
      {
        colId: "totalWithTax",
        headerName: t`columns.totalWithTax`,
        valueGetter: (p) => getVATWithTotal(p, marginType?.value),
        type: ["numericColumn", "twoDecimal"],
        initialWidth: 120,
      },
    ];

    return columns.concat(measurementColumns);
  }, [marginType?.value, measurementColumns, t, taxRate]);

  const defaultColDef: ColDef<OfferClassificationSummary> = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      enableRowGroup: true,
      enableValue: true,
      cellStyle: (params) => {
        if (params.node.isRowPinned()) {
          return { fontWeight: "bold" };
        }
      },
    };
  }, []);

  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelKey: "columns",
        labelDefault: "Columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressPivotMode: true,
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
    ],
  };
  const grandTotalRow = getPinnedEconomicTotal(gridRef, marginType?.value);

  const [firstDataRendered, onFirstDataRendered] = useFirstDataRendered(false);

  const [resetClicked, setResetClicked] = useState<boolean>(false);

  const onCellValueChanged = async (
    event: CellValueChangedEvent<OfferClassificationSummary>
  ) => {
    setUndoRedoStack({
      undo: gridRef.current?.api.getCurrentUndoSize() ?? 0,
      redo: gridRef.current?.api.getCurrentRedoSize() ?? 0,
    });
    const currentOfferValues = offerParams.data;
    if (!currentOfferValues) {
      return;
    }
    if (event.source === "undo" && event.rowPinned) {
      event.data.generalMarginPercentage = undefined;
      event.data.marginPercentage = event.newValue;
      event.api.forEachNode((node) => {
        if (node.data) {
          node.data.generalMarginPercentage = undefined;
        }
      });
      event.api.refreshCells({ force: true });
      const editOfferData = getAllOfferParams(
        gridRef,
        marginType?.value,
        offerParams.data
      );
      const proOfferData = createProOfferData(editOfferData);
      try {
        await editOfferValue.mutateAsync(proOfferData);
      } catch (e) {
        reportError("Failed to edit offer value", e as AxiosError);
        event.api.undoCellEditing();
      }
      event.api.refreshCells({ rowNodes: [event.node], force: true });
      return;
    }
    if (event.source === "edit" && event.rowPinned) {
      const offerData = savePinnedRowEdit(
        event,
        marginType?.value,
        offerParams.data
      );
      try {
        await editOfferValue.mutateAsync(createProOfferData(offerData));
      } catch (e) {
        reportError("Failed to edit offer value", e as AxiosError);
        event.api.undoCellEditing();
      }

      event.api.refreshCells({ rowNodes: [event.node], force: true });
    }
    if (!event.rowPinned) {
      const editOfferData = saveRegularRowEdit(
        event,
        currentOfferValues,
        marginType?.value
      );
      try {
        await editOfferValue.mutateAsync(createProOfferData(editOfferData));
      } catch (e) {
        reportError("Failed to edit offer value", e as AxiosError);
        event.api.undoCellEditing();
      }
      event.api.refreshCells({ force: true });
    }
  };

  const onRowDataChanged = async () => {
    if (resetClicked) {
      const editOfferData = getAllOfferParams(
        gridRef,
        marginType?.value,
        offerParams.data
      );
      try {
        await editOfferValue.mutateAsync(createProOfferData(editOfferData));
      } catch (e) {
        gridRef.current?.api.setGridOption("rowData", rowData);
      }
      setUndoRedoStack({ undo: 0, redo: 0 });
    }
  };

  const { configEvents } = useUsersAgGridConfiguration(
    gridReady,
    gridRef,
    "offer-page",
    projectId
  );

  usePinnedBottomRowUpdater(gridRef, firstDataRendered, grandTotalRow);

  return (
    <>
      <div className={"mx-4 px-4 flex justify-between"}>
        <UndoRedoButtons
          gridRef={gridRef}
          undoRedoStack={undoRedoStack}
          resetClicked={setResetClicked}
        />
        <OfferPageSettings
          marginType={marginType}
          marginTypeChanged={onMarginTypeChange}
          undoRedoStackChange={setUndoRedoStack}
        />
      </div>
      <Table<OfferClassificationSummary>
        gridRef={gridRef}
        defaultColDef={defaultColDef}
        className={"h-3/4 m-4 p-4"}
        rowData={rowData}
        columnDefs={columnDefs}
        sideBar={sideBar}
        onCellValueChanged={onCellValueChanged}
        context={{
          taxRate: taxRate,
          currency: projectData.currency,
        }}
        onFirstDataRendered={onFirstDataRendered}
        {...configEvents}
        onGridReady={onGridReady}
        maintainColumnOrder={true}
        undoRedoCellEditing={true}
        getRowId={(row) => row.data.code}
        onRowDataUpdated={onRowDataChanged}
      />
    </>
  );
}

const createProOfferData = (params: OfferParamsWithValue): ProOfferParams => {
  return {
    showEconomicMargin: params.showEconomicMargin,
    marginPercentages: params.values.marginPercentages,
    riskPercentages: params.values.riskPercentages,
    offerOverrides: params.values.offerOverrides,
  };
};
