// Generated by purs version 0.14.5
"use strict";
var Controls_Styles = require("../Controls.Styles/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var Record = require("../Record/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var defaultProps = {
    leftControls: [  ],
    rightControls: [  ],
    bottomGutter: false,
    topGutter: false,
    gap: false
};
var component = function (dictUnion) {
    return function (dictNub) {
        return TofuHooks.mkHookComponent("Controls")(function (props) {
            var v = Record.merge()()(props)(defaultProps);
            return function __do() {
                var css = TofuHooks.useMemo([ ReactHooksUtils.utf(v.bottomGutter), ReactHooksUtils.utf(v.topGutter) ])(function (v1) {
                    return Controls_Styles.controlsContainerStyles({
                        bottomGutter: v.bottomGutter,
                        topGutter: v.topGutter
                    });
                })();
                var leftControlsStyles$prime = TofuHooks.useMemo([ ReactHooksUtils.utf(v.gap) ])(function (v1) {
                    return Controls_Styles.leftControlsStyles({
                        gap: v.gap
                    });
                })();
                return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                    className: "__Controls",
                    css: css,
                    children: [ React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                        className: "left-controls",
                        css: leftControlsStyles$prime,
                        children: v.leftControls
                    }), React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                        className: "right-controls",
                        css: Controls_Styles.rightControlsStyles,
                        children: v.rightControls
                    }) ]
                });
            };
        });
    };
};
module.exports = {
    defaultProps: defaultProps,
    component: component
};
