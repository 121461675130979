// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Gap = require("../Gap/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var example5 = "import TacoText as TacoText\x0aimport TacoText.Types as Text\x0a\x0a[ TacoText.component { text: \"Left aligned (default)\", variant: Text.Paragraph }\x0a, TacoText.component { text: \"Center aligned\", align: Text.Center, variant: Text.Paragraph  }\x0a, TacoText.component { text: \"Right aligned\", align: Text.Right, variant: Text.Paragraph  }\x0a]";
var example4 = "import TacoText as TacoText\x0aimport TacoText.Types as Text\x0aimport Gap (gapY)\x0a\x0agapY S\x0a  [ TacoText.component { text: \"Default (none)\" }\x0a  , TacoText.component { text: \"Uppercase\", transform: Text.Uppercase }\x0a  , TacoText.component { text: \"Lowercase\", transform: Text.Lowercase }\x0a  , TacoText.component { text: \"Capitalize every word\", transform: Text.Capitalize }\x0a  ]";
var example3 = "import TacoText as TacoText\x0aimport TacoText.Types as Text\x0aimport Gap (gapY)\x0a\x0agapY S\x0a  [ TacoText.component { text: \"Default (medium) weight\" }\x0a  , TacoText.component { text: \"Bold weight\", weight: Text.Bold }\x0a  ]";
var example2 = "import TacoConstants (Size(..))\x0aimport TacoText as TacoText\x0aimport TacoText.Types as Text\x0aimport Gap (gapY)\x0a\x0agapY S\x0a  [ TacoText.component { text: \"Default (Medium) font size\" }\x0a  , TacoText.component { text: \"Large font size\", size: L }\x0a  , TacoText.component { text: \"XLarge font size\", size: XL }\x0a  ]";
var example1 = "import TacoText as TacoText\x0aimport TacoText.Types as Text\x0a\x0a[ TacoText.component { text: \"Default (inline span tag does not \" }\x0a, TacoText.component { text: \"wrap)\" }\x0a, TacoText.component { text: \"Paragraph\", variant: Text.Paragraph }\x0a, TacoText.component { text: \"Heading 2\", variant: Text.Heading 2 }\x0a]";
var sections = [ {
    title: "Variants",
    view: [ TacoText.component()()({
        text: "Default (inline span tag does not "
    }), TacoText.component()()({
        text: "wrap)"
    }), TacoText.component()()({
        text: "Paragraph",
        variant: TacoText_Types.Paragraph.value
    }), TacoText.component()()({
        text: "Heading 2",
        variant: new TacoText_Types.Heading(2)
    }) ],
    code: example1
}, {
    title: "Font sizes",
    view: Control_Applicative.pure(Control_Applicative.applicativeArray)(Gap.gapY(TacoConstants.S.value)([ TacoText.component()()({
        text: "Default (Medium) font size"
    }), TacoText.component()()({
        text: "Large font size",
        size: TacoConstants.L.value
    }), TacoText.component()()({
        text: "XLarge font size",
        size: TacoConstants.XL.value
    }) ])),
    code: example2
}, {
    title: "Font weight",
    view: Control_Applicative.pure(Control_Applicative.applicativeArray)(Gap.gapY(TacoConstants.S.value)([ TacoText.component()()({
        text: "Default (medium) weight"
    }), TacoText.component()()({
        text: "Bold weight",
        weight: TacoText_Types.Bold.value
    }) ])),
    code: example3
}, {
    title: "Text transforms",
    view: Control_Applicative.pure(Control_Applicative.applicativeArray)(Gap.gapY(TacoConstants.S.value)([ TacoText.component()()({
        text: "Default (none)"
    }), TacoText.component()()({
        text: "Uppercase",
        transform: TacoText_Types.Uppercase.value
    }), TacoText.component()()({
        text: "Lowercase",
        transform: TacoText_Types.Lowercase.value
    }), TacoText.component()()({
        text: "Capitalize every word",
        transform: TacoText_Types.Capitalize.value
    }) ])),
    code: example4
}, {
    title: "Text align",
    view: [ TacoText.component()()({
        text: "Left aligned (default)",
        variant: TacoText_Types.Paragraph.value
    }), TacoText.component()()({
        text: "Center aligned",
        align: TacoText_Types.Center.value,
        variant: TacoText_Types.Paragraph.value
    }), TacoText.component()()({
        text: "Right aligned",
        align: TacoText_Types.Right.value,
        variant: TacoText_Types.Paragraph.value
    }) ],
    code: example5
} ];
var demoView = {
    sections: sections,
    title: "TacoText",
    description: "Text component for text items, headings and paragraphs"
};
module.exports = {
    demoView: demoView
};
