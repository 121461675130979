// Generated by purs version 0.14.5
"use strict";
var Client_WASM = require("../Client.WASM/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Array_NonEmpty_Internal = require("../Data.Array.NonEmpty.Internal/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_ClassificationGroup = require("../Types.ClassificationGroup/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Project = require("../Types.Project/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var mkMergingKey = function (resource) {
    return {
        costClassCode: resource.costClassCode,
        name: resource.name,
        unit: resource.unit,
        pricePerUnit: resource.pricePerUnit,
        currencyId: resource.currencyId,
        changeDate: resource.changeDate,
        contract: resource.contract,
        discountGroupCode: resource.discountGroupCode,
        discountPercentage: resource.discountPercentage,
        eanCode: resource.eanCode,
        index: resource.index,
        priceWithDiscount: resource.priceWithDiscount,
        resourceClassificationGroup: resource.resourceClassificationGroup,
        taxPercentage: resource.taxPercentage,
        usagesCount: resource.usagesCount,
        validDate: resource.validDate,
        vendor: resource.vendor,
        vendorBatchSize: resource.vendorBatchSize,
        vendorCode: resource.vendorCode,
        vendorUnit: resource.vendorUnit,
        orgResourcePriceListId: Data_Nullable.toNullable(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Newtype.un()(Types_Component.OrgPriceListId))(Data_Nullable.toMaybe(resource.orgResourcePriceListId))),
        orgResourcePriceId: resource.orgResourcePriceId
    };
};
var mkEstimationResourceMap = function (resources) {
    var addEstimationResource = function (resource) {
        return function (oldValue) {
            return Data_Maybe.Just.create(Data_Array_NonEmpty.insert(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()({
                reflectSymbol: function () {
                    return "wastePercentage";
                }
            })(Data_Ord.ordNumber))()({
                reflectSymbol: function () {
                    return "vendorUnit";
                }
            })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                reflectSymbol: function () {
                    return "vendorCode";
                }
            })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                reflectSymbol: function () {
                    return "vendorBatchSize";
                }
            })(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
                reflectSymbol: function () {
                    return "vendor";
                }
            })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                reflectSymbol: function () {
                    return "validDate";
                }
            })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                reflectSymbol: function () {
                    return "usagesCount";
                }
            })(Data_Ord.ordNumber))()({
                reflectSymbol: function () {
                    return "unitConsumptionRate";
                }
            })(Data_Ord.ordNumber))()({
                reflectSymbol: function () {
                    return "unit";
                }
            })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                reflectSymbol: function () {
                    return "taxPercentage";
                }
            })(Types_Unit.ordTaxrate))()({
                reflectSymbol: function () {
                    return "sortingNumber";
                }
            })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                reflectSymbol: function () {
                    return "resourceClassificationGroup";
                }
            })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                reflectSymbol: function () {
                    return "projectId";
                }
            })(Data_Nullable.ordNullable(Types_Project.ordProjectId)))()({
                reflectSymbol: function () {
                    return "priceWithDiscount";
                }
            })(Data_Ord.ordNumber))()({
                reflectSymbol: function () {
                    return "pricePerUnit";
                }
            })(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
                reflectSymbol: function () {
                    return "orgResourcePriceListId";
                }
            })(Data_Nullable.ordNullable(Types_Component.ordOrgEstimationResourceId)))()({
                reflectSymbol: function () {
                    return "orgResourcePriceId";
                }
            })(Data_Nullable.ordNullable(Data_Ord.ordInt)))()({
                reflectSymbol: function () {
                    return "name";
                }
            })(Data_Ord.ordString))()({
                reflectSymbol: function () {
                    return "multiplier";
                }
            })(Data_Ord.ordNumber))()({
                reflectSymbol: function () {
                    return "isCostClassPrice";
                }
            })(Data_Ord.ordBoolean))()({
                reflectSymbol: function () {
                    return "index";
                }
            })(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Component.ordEstimationResourceId))()({
                reflectSymbol: function () {
                    return "group";
                }
            })(Data_Nullable.ordNullable(Types_ClassificationGroup.ordClassificationGroupCode)))()({
                reflectSymbol: function () {
                    return "estimationComponentId";
                }
            })(Data_Nullable.ordNullable(Types_Component.ordComponentId)))()({
                reflectSymbol: function () {
                    return "eanCode";
                }
            })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                reflectSymbol: function () {
                    return "discountPercentage";
                }
            })(Data_Ord.ordNumber))()({
                reflectSymbol: function () {
                    return "discountGroupCode";
                }
            })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                reflectSymbol: function () {
                    return "currencyId";
                }
            })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                reflectSymbol: function () {
                    return "costClassCode";
                }
            })(Data_Nullable.ordNullable(Types_Newtypes.ordCostClassCode)))()({
                reflectSymbol: function () {
                    return "contract";
                }
            })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                reflectSymbol: function () {
                    return "changeDate";
                }
            })(Data_Nullable.ordNullable(Data_Ord.ordString))))(resource)(oldValue));
        };
    };
    var addResource = function (acc) {
        return function (resource) {
            var key = mkMergingKey(resource);
            var v = Data_Map_Internal.lookup(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()({
                reflectSymbol: function () {
                    return "vendorUnit";
                }
            })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                reflectSymbol: function () {
                    return "vendorCode";
                }
            })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                reflectSymbol: function () {
                    return "vendorBatchSize";
                }
            })(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
                reflectSymbol: function () {
                    return "vendor";
                }
            })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                reflectSymbol: function () {
                    return "validDate";
                }
            })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                reflectSymbol: function () {
                    return "usagesCount";
                }
            })(Data_Ord.ordNumber))()({
                reflectSymbol: function () {
                    return "unit";
                }
            })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                reflectSymbol: function () {
                    return "taxPercentage";
                }
            })(Types_Unit.ordTaxrate))()({
                reflectSymbol: function () {
                    return "resourceClassificationGroup";
                }
            })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                reflectSymbol: function () {
                    return "priceWithDiscount";
                }
            })(Data_Ord.ordNumber))()({
                reflectSymbol: function () {
                    return "pricePerUnit";
                }
            })(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
                reflectSymbol: function () {
                    return "orgResourcePriceListId";
                }
            })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                reflectSymbol: function () {
                    return "orgResourcePriceId";
                }
            })(Data_Nullable.ordNullable(Data_Ord.ordInt)))()({
                reflectSymbol: function () {
                    return "name";
                }
            })(Data_Ord.ordString))()({
                reflectSymbol: function () {
                    return "index";
                }
            })(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
                reflectSymbol: function () {
                    return "eanCode";
                }
            })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                reflectSymbol: function () {
                    return "discountPercentage";
                }
            })(Data_Ord.ordNumber))()({
                reflectSymbol: function () {
                    return "discountGroupCode";
                }
            })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                reflectSymbol: function () {
                    return "currencyId";
                }
            })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                reflectSymbol: function () {
                    return "costClassCode";
                }
            })(Data_Nullable.ordNullable(Types_Newtypes.ordCostClassCode)))()({
                reflectSymbol: function () {
                    return "contract";
                }
            })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                reflectSymbol: function () {
                    return "changeDate";
                }
            })(Data_Nullable.ordNullable(Data_Ord.ordString))))(key)(acc);
            if (v instanceof Data_Maybe.Nothing) {
                var v1 = Data_Array_NonEmpty.fromArray([ resource ]);
                if (v1 instanceof Data_Maybe.Nothing) {
                    return acc;
                };
                if (v1 instanceof Data_Maybe.Just) {
                    return Data_Map_Internal.insert(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()({
                        reflectSymbol: function () {
                            return "vendorUnit";
                        }
                    })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                        reflectSymbol: function () {
                            return "vendorCode";
                        }
                    })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                        reflectSymbol: function () {
                            return "vendorBatchSize";
                        }
                    })(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
                        reflectSymbol: function () {
                            return "vendor";
                        }
                    })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                        reflectSymbol: function () {
                            return "validDate";
                        }
                    })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                        reflectSymbol: function () {
                            return "usagesCount";
                        }
                    })(Data_Ord.ordNumber))()({
                        reflectSymbol: function () {
                            return "unit";
                        }
                    })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                        reflectSymbol: function () {
                            return "taxPercentage";
                        }
                    })(Types_Unit.ordTaxrate))()({
                        reflectSymbol: function () {
                            return "resourceClassificationGroup";
                        }
                    })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                        reflectSymbol: function () {
                            return "priceWithDiscount";
                        }
                    })(Data_Ord.ordNumber))()({
                        reflectSymbol: function () {
                            return "pricePerUnit";
                        }
                    })(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
                        reflectSymbol: function () {
                            return "orgResourcePriceListId";
                        }
                    })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                        reflectSymbol: function () {
                            return "orgResourcePriceId";
                        }
                    })(Data_Nullable.ordNullable(Data_Ord.ordInt)))()({
                        reflectSymbol: function () {
                            return "name";
                        }
                    })(Data_Ord.ordString))()({
                        reflectSymbol: function () {
                            return "index";
                        }
                    })(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
                        reflectSymbol: function () {
                            return "eanCode";
                        }
                    })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                        reflectSymbol: function () {
                            return "discountPercentage";
                        }
                    })(Data_Ord.ordNumber))()({
                        reflectSymbol: function () {
                            return "discountGroupCode";
                        }
                    })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                        reflectSymbol: function () {
                            return "currencyId";
                        }
                    })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                        reflectSymbol: function () {
                            return "costClassCode";
                        }
                    })(Data_Nullable.ordNullable(Types_Newtypes.ordCostClassCode)))()({
                        reflectSymbol: function () {
                            return "contract";
                        }
                    })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                        reflectSymbol: function () {
                            return "changeDate";
                        }
                    })(Data_Nullable.ordNullable(Data_Ord.ordString))))(key)(v1.value0)(acc);
                };
                throw new Error("Failed pattern match at MergedEstimationResources (line 73, column 48 - line 75, column 88): " + [ v1.constructor.name ]);
            };
            if (v instanceof Data_Maybe.Just) {
                return Data_Map_Internal.update(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()({
                    reflectSymbol: function () {
                        return "vendorUnit";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "vendorCode";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "vendorBatchSize";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
                    reflectSymbol: function () {
                        return "vendor";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "validDate";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "usagesCount";
                    }
                })(Data_Ord.ordNumber))()({
                    reflectSymbol: function () {
                        return "unit";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "taxPercentage";
                    }
                })(Types_Unit.ordTaxrate))()({
                    reflectSymbol: function () {
                        return "resourceClassificationGroup";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "priceWithDiscount";
                    }
                })(Data_Ord.ordNumber))()({
                    reflectSymbol: function () {
                        return "pricePerUnit";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
                    reflectSymbol: function () {
                        return "orgResourcePriceListId";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "orgResourcePriceId";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordInt)))()({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(Data_Ord.ordString))()({
                    reflectSymbol: function () {
                        return "index";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
                    reflectSymbol: function () {
                        return "eanCode";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "discountPercentage";
                    }
                })(Data_Ord.ordNumber))()({
                    reflectSymbol: function () {
                        return "discountGroupCode";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "currencyId";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "costClassCode";
                    }
                })(Data_Nullable.ordNullable(Types_Newtypes.ordCostClassCode)))()({
                    reflectSymbol: function () {
                        return "contract";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
                    reflectSymbol: function () {
                        return "changeDate";
                    }
                })(Data_Nullable.ordNullable(Data_Ord.ordString))))(addEstimationResource(resource))(key)(acc);
            };
            throw new Error("Failed pattern match at MergedEstimationResources (line 72, column 35 - line 76, column 99): " + [ v.constructor.name ]);
        };
    };
    return Data_Array.foldl(addResource)(Data_Map_Internal.empty)(resources);
};
var mkComponentMap = function (components) {
    var kvs = Data_Functor.map(Data_Functor.functorArray)(function (c) {
        return new Data_Tuple.Tuple(c.id, c);
    })(components);
    return Data_Map_Internal.fromFoldable(Types_Component.ordComponentId)(Data_Foldable.foldableArray)(kvs);
};
var associateComponent = function (componentMap) {
    return function (resource) {
        var getComponent = function (componentId) {
            return Data_Map_Internal.lookup(Types_Component.ordComponentId)(componentId)(componentMap);
        };
        var resourceComponent = Control_Bind.join(Data_Maybe.bindMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(getComponent)(Data_Nullable.toMaybe(resource.estimationComponentId)));
        return new Data_Tuple.Tuple(resource, resourceComponent);
    };
};
var mkMergedEstimationResource = function (componentMap) {
    return function (resources) {
        var res = Data_Array_NonEmpty.head(resources);
        var merged = mkMergingKey(res);
        var estimationResources = Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)(associateComponent(componentMap))(resources);
        return {
            estimationResources: estimationResources,
            key: Data_Show.show(Data_Show.showRecord()(Data_Show.showRecordFieldsCons({
                reflectSymbol: function () {
                    return "changeDate";
                }
            })(Data_Show.showRecordFieldsCons({
                reflectSymbol: function () {
                    return "contract";
                }
            })(Data_Show.showRecordFieldsCons({
                reflectSymbol: function () {
                    return "costClassCode";
                }
            })(Data_Show.showRecordFieldsCons({
                reflectSymbol: function () {
                    return "currencyId";
                }
            })(Data_Show.showRecordFieldsCons({
                reflectSymbol: function () {
                    return "discountGroupCode";
                }
            })(Data_Show.showRecordFieldsCons({
                reflectSymbol: function () {
                    return "discountPercentage";
                }
            })(Data_Show.showRecordFieldsCons({
                reflectSymbol: function () {
                    return "eanCode";
                }
            })(Data_Show.showRecordFieldsCons({
                reflectSymbol: function () {
                    return "index";
                }
            })(Data_Show.showRecordFieldsCons({
                reflectSymbol: function () {
                    return "name";
                }
            })(Data_Show.showRecordFieldsCons({
                reflectSymbol: function () {
                    return "orgResourcePriceId";
                }
            })(Data_Show.showRecordFieldsCons({
                reflectSymbol: function () {
                    return "orgResourcePriceListId";
                }
            })(Data_Show.showRecordFieldsCons({
                reflectSymbol: function () {
                    return "pricePerUnit";
                }
            })(Data_Show.showRecordFieldsCons({
                reflectSymbol: function () {
                    return "priceWithDiscount";
                }
            })(Data_Show.showRecordFieldsCons({
                reflectSymbol: function () {
                    return "resourceClassificationGroup";
                }
            })(Data_Show.showRecordFieldsCons({
                reflectSymbol: function () {
                    return "taxPercentage";
                }
            })(Data_Show.showRecordFieldsCons({
                reflectSymbol: function () {
                    return "unit";
                }
            })(Data_Show.showRecordFieldsCons({
                reflectSymbol: function () {
                    return "usagesCount";
                }
            })(Data_Show.showRecordFieldsCons({
                reflectSymbol: function () {
                    return "validDate";
                }
            })(Data_Show.showRecordFieldsCons({
                reflectSymbol: function () {
                    return "vendor";
                }
            })(Data_Show.showRecordFieldsCons({
                reflectSymbol: function () {
                    return "vendorBatchSize";
                }
            })(Data_Show.showRecordFieldsCons({
                reflectSymbol: function () {
                    return "vendorCode";
                }
            })(Data_Show.showRecordFieldsCons({
                reflectSymbol: function () {
                    return "vendorUnit";
                }
            })(Data_Show.showRecordFieldsNil)(Data_Nullable.showNullable(Data_Show.showString)))(Data_Nullable.showNullable(Data_Show.showString)))(Data_Nullable.showNullable(Data_Show.showNumber)))(Data_Nullable.showNullable(Data_Show.showString)))(Data_Nullable.showNullable(Data_Show.showString)))(Data_Show.showNumber))(Data_Nullable.showNullable(Data_Show.showString)))(Types_Unit.showTaxrate))(Data_Nullable.showNullable(Data_Show.showString)))(Data_Show.showNumber))(Data_Nullable.showNullable(Data_Show.showNumber)))(Data_Nullable.showNullable(Data_Show.showString)))(Data_Nullable.showNullable(Data_Show.showInt)))(Data_Show.showString))(Data_Nullable.showNullable(Data_Show.showNumber)))(Data_Nullable.showNullable(Data_Show.showString)))(Data_Show.showNumber))(Data_Nullable.showNullable(Data_Show.showString)))(Data_Nullable.showNullable(Data_Show.showString)))(Data_Nullable.showNullable(Types_Newtypes.showCostClassCode)))(Data_Nullable.showNullable(Data_Show.showString)))(Data_Nullable.showNullable(Data_Show.showString))))(merged),
            id: res.id,
            costClassCode: merged.costClassCode,
            name: merged.name,
            unit: merged.unit,
            pricePerUnit: merged.pricePerUnit,
            currencyId: merged.currencyId,
            changeDate: merged.changeDate,
            contract: merged.contract,
            discountGroupCode: merged.discountGroupCode,
            discountPercentage: merged.discountPercentage,
            eanCode: merged.eanCode,
            index: merged.index,
            priceWithDiscount: merged.priceWithDiscount,
            resourceClassificationGroup: merged.resourceClassificationGroup,
            taxPercentage: merged.taxPercentage,
            usagesCount: merged.usagesCount,
            validDate: merged.validDate,
            vendor: merged.vendor,
            vendorBatchSize: merged.vendorBatchSize,
            vendorCode: merged.vendorCode,
            vendorUnit: merged.vendorUnit,
            orgResourcePriceListId: merged.orgResourcePriceListId,
            orgResourcePriceId: merged.orgResourcePriceId
        };
    };
};
var mkMergedEstimationResources = function (resources) {
    return function (components) {
        var resourceMap = mkEstimationResourceMap(resources);
        var resourceGroups = Data_Array_NonEmpty.fromFoldable(Data_List_Types.foldableList)(Data_Map_Internal.values(resourceMap));
        var componentMap = mkComponentMap(components);
        var v = Data_Functor.map(Data_Maybe.functorMaybe)(Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)(mkMergedEstimationResource(componentMap)))(resourceGroups);
        if (v instanceof Data_Maybe.Nothing) {
            return [  ];
        };
        if (v instanceof Data_Maybe.Just) {
            return Data_Array_NonEmpty.toArray(v.value0);
        };
        throw new Error("Failed pattern match at MergedEstimationResources (line 22, column 3 - line 24, column 31): " + [ v.constructor.name ]);
    };
};
var useMergedEstimationResources = function (projectId) {
    return function __do() {
        var v = Client_WASM.useEstimationResources(projectId)();
        var estimationResources = TofuHooks.useMemo([ v.value0 ])(function (v1) {
            return Data_Array.filter(function (res) {
                return !res.isCostClassPrice;
            })(v.value0);
        })();
        var v1 = Client_WASM.useEstimationComponents(projectId)();
        var mergedEstimationResources = ReactHooksUtils.usePersistMemo("BuildingResourcesViewMergedEstimationResources")([ ReactHooksUtils.utf(estimationResources), ReactHooksUtils.utf(v1.value0) ])(function (v2) {
            return mkMergedEstimationResources(estimationResources)(v1.value0);
        })();
        return mergedEstimationResources;
    };
};
module.exports = {
    mkMergedEstimationResources: mkMergedEstimationResources,
    mkMergedEstimationResource: mkMergedEstimationResource,
    mkEstimationResourceMap: mkEstimationResourceMap,
    mkComponentMap: mkComponentMap,
    associateComponent: associateComponent,
    mkMergingKey: mkMergingKey,
    useMergedEstimationResources: useMergedEstimationResources
};
