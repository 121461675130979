// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var indicatorOffset = 16;
var floatingActionButtonsStyles = Box.boxStyle([ new Box.Position(Box.Absolute.value), new Box.PosBottom(TacoConstants.M.value), new Box.PosLeft(TacoConstants.M.value), Box.GridColumn.value, new Box.Gap(TacoConstants.M.value) ]);
var costEstimationTableWrapperStyles = function (v) {
    return Box.boxStyle([ new Box.FlexGrow(1), Box.FlexColumn.value, new Box.Position(Box.Relative.value), new Box.Selector(".__TacoTable", [ Box.MinHeight.create(new TacoConstants.CustomSize("auto")), new Box.Overflow(Box.Auto.value, Box.Auto.value), new Box.FlexGrow(1), new Box.FlexBasis("0"), Box.PaddingBottom.create(new TacoConstants.CustomSize("4.5rem")), new Box.Selector(".table-rows", [ Box.MinHeight.create(new TacoConstants.CustomSize("auto")) ]) ]), Box.Width.create(new TacoConstants.CustomSize("100%")) ]);
};
var borderSize = 1;
module.exports = {
    borderSize: borderSize,
    indicatorOffset: indicatorOffset,
    costEstimationTableWrapperStyles: costEstimationTableWrapperStyles,
    floatingActionButtonsStyles: floatingActionButtonsStyles
};
