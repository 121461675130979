// Generated by purs version 0.14.5
"use strict";
var Vertical = (function () {
    function Vertical() {

    };
    Vertical.value = new Vertical();
    return Vertical;
})();
var Horizontal = (function () {
    function Horizontal() {

    };
    Horizontal.value = new Horizontal();
    return Horizontal;
})();
var NormalTab = (function () {
    function NormalTab(value0) {
        this.value0 = value0;
    };
    NormalTab.create = function (value0) {
        return new NormalTab(value0);
    };
    return NormalTab;
})();
var ButtonTab = (function () {
    function ButtonTab(value0) {
        this.value0 = value0;
    };
    ButtonTab.create = function (value0) {
        return new ButtonTab(value0);
    };
    return ButtonTab;
})();
var SeparatorTab = (function () {
    function SeparatorTab() {

    };
    SeparatorTab.value = new SeparatorTab();
    return SeparatorTab;
})();
var JsxTab = (function () {
    function JsxTab(value0) {
        this.value0 = value0;
    };
    JsxTab.create = function (value0) {
        return new JsxTab(value0);
    };
    return JsxTab;
})();
var isNormalTab = function (v) {
    if (v instanceof NormalTab) {
        return true;
    };
    return false;
};
var getTabId = function (tab) {
    if (tab instanceof NormalTab) {
        return tab.value0.id;
    };
    if (tab instanceof ButtonTab) {
        return tab.value0.id;
    };
    if (tab instanceof SeparatorTab) {
        return "separator";
    };
    if (tab instanceof JsxTab) {
        return "jsx";
    };
    throw new Error("Failed pattern match at TacoTabs.Types (line 63, column 16 - line 67, column 20): " + [ tab.constructor.name ]);
};
var eqTabsOrientation = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Vertical && y instanceof Vertical) {
                return true;
            };
            if (x instanceof Horizontal && y instanceof Horizontal) {
                return true;
            };
            return false;
        };
    }
};
module.exports = {
    Vertical: Vertical,
    Horizontal: Horizontal,
    NormalTab: NormalTab,
    ButtonTab: ButtonTab,
    SeparatorTab: SeparatorTab,
    JsxTab: JsxTab,
    getTabId: getTabId,
    isNormalTab: isNormalTab,
    eqTabsOrientation: eqTabsOrientation
};
