// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var ColumnWidgets = require("../ColumnWidgets/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_List_Lazy_Types = require("../Data.List.Lazy.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Data_Validation_Semigroup = require("../Data.Validation.Semigroup/index.js");
var Effect = require("../Effect/index.js");
var InvoiceBunchIdInvoiceDetails = require("../InvoiceBunchIdInvoiceDetails/index.js");
var InvoiceDetails = require("../InvoiceDetails/index.js");
var InvoiceImgLink = require("../InvoiceImgLink/index.js");
var InvoiceUtils = require("../InvoiceUtils/index.js");
var Invoices_Styles = require("../Invoices.Styles/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var StateHooks = require("../StateHooks/index.js");
var SupplierInvoiceDetails = require("../SupplierInvoiceDetails/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_CostGroup = require("../Types.CostGroup/index.js");
var Types_Invoice = require("../Types.Invoice/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Project = require("../Types.Project/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var Utils = require("../Utils/index.js");
var Utils_ClassNames = require("../Utils.ClassNames/index.js");
var invoicesTableInstance = TacoTable.mkTableStateless(InvoiceUtils.getNameInvoiceLineSortProperty)("InvoicesTable");
var invoicesTable = TofuHooks.mkHookComponent("InvoicesTable")(function (props) {
    return function __do() {
        var appDispatch = StateHooks.useDispatch();
        var v = TofuHooks.useState(Data_Set.empty)();
        var v1 = TofuHooks.useState(Data_Set.empty)();
        var v2 = TofuHooks.useState(Data_Set.empty)();
        var toggleSupplierRowFolded = (function () {
            var $72 = Data_Set.toggle(Types_Project.ordSupplier);
            return function ($73) {
                return v1.value1($72($73));
            };
        })();
        var toggleInvoiceRowFolded = (function () {
            var $74 = Data_Set.toggle(Types_Invoice.ordInvoiceId);
            return function ($75) {
                return v.value1($74($75));
            };
        })();
        var toggleInvoiceBunchIdRowFolded = (function () {
            var $76 = Data_Set.toggle(Data_Maybe.ordMaybe(Types_Invoice.ordInvoiceBunchId));
            return function ($77) {
                return v2.value1($76($77));
            };
        })();
        var supplierWithDescriptionHead = function (invoiceType) {
            return function (supplier) {
                return function (description) {
                    return React_Basic_DOM_Generated.div()({
                        className: "supplier-with-description-head",
                        children: [ (function () {
                            if (invoiceType instanceof Types_Invoice.Salary) {
                                return React_Basic_DOM_Generated.span()({
                                    className: "supplier",
                                    children: Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM.text("Palkat"))
                                });
                            };
                            return React_Basic_DOM_Generated.span()({
                                className: "supplier",
                                children: Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM.text(Data_Newtype.un()(Types_Project.Supplier)(supplier)))
                            });
                        })(), (function () {
                            var v3 = Data_Nullable.toMaybe(description);
                            if (v3 instanceof Data_Maybe.Just) {
                                return React_Basic_Emotion.element(React_Basic_DOM_Generated["span'"]())({
                                    className: "description-container",
                                    css: Invoices_Styles.descriptionContainerStyles,
                                    children: [ TacoText.component()()({
                                        text: v3.value0,
                                        color: TacoConstants.Gray.value
                                    }) ]
                                });
                            };
                            if (v3 instanceof Data_Maybe.Nothing) {
                                return React_Basic.empty;
                            };
                            throw new Error("Failed pattern match at InvoicesTable (line 343, column 11 - line 350, column 32): " + [ v3.constructor.name ]);
                        })() ]
                    });
                };
            };
        };
        var onSupplierBusinessIdInput = function (supplier) {
            return function (businessId) {
                var params = {
                    projectId: props.projectId,
                    supplier: supplier,
                    businessId: businessId
                };
                var request = new Actions.UpdateSupplierBusinessIdRequest(params);
                return appDispatch(request);
            };
        };
        var isInvoiceLineSelected = (function () {
            var $78 = Data_Function.flip(Data_Foldable.elem(Data_Foldable.foldableArray)(Types_Invoice.eqInvoiceRowId))(props.selectedInvoiceLineIds);
            return function ($79) {
                return $78((function (v3) {
                    return v3.id;
                })($79));
            };
        })();
        var isInvoiceLineChecked = function (invoiceLines) {
            return Data_Foldable.all(Data_Foldable.foldableArray)(Data_HeytingAlgebra.heytingAlgebraBoolean)(Data_Eq.eq(Data_Eq.eqBoolean)(true))(Data_Functor.map(Data_Functor.functorArray)(isInvoiceLineSelected)(invoiceLines));
        };
        var invoiceLinesSelectedClassName = function (invoiceLines) {
            var selectedLines = Data_Functor.map(Data_Functor.functorArray)((function () {
                var $80 = Data_Show.show(Types_Invoice.showInvoiceRowId);
                return function ($81) {
                    return Data_Maybe.Just.create($80((function (v3) {
                        return v3.id;
                    })($81)));
                };
            })())(Data_Array.filter(isInvoiceLineSelected)(invoiceLines));
            var selectedLinesClassName = Utils_ClassNames.mkClassName(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ new Data_Maybe.Just("lines-checked") ])(selectedLines));
            return Data_Monoid.guard(Data_Maybe.monoidMaybe(Data_Semigroup.semigroupString))(Data_Array.length(selectedLines) > 0)(new Data_Maybe.Just(selectedLinesClassName));
        };
        var invoiceImgLinkComponent = InvoiceImgLink.createInvoiceImgLinkJSX(appDispatch)(new Data_Maybe.Just(props.projectId));
        var invoiceBunchIdsOfLines = (function () {
            var $82 = Data_String_Common.joinWith(", ");
            var $83 = Data_Array.sortBy(Data_String_Common.localeCompare);
            var $84 = Data_Array.nub(Data_Ord.ordString);
            var $85 = Data_Array.mapMaybe((function () {
                var $87 = Data_Functor.map(Data_Maybe.functorMaybe)(Data_Newtype.un()(Types_Invoice.InvoiceBunchId));
                return function ($88) {
                    return $87(Data_Nullable.toMaybe((function (v3) {
                        return v3.invoiceBunchId;
                    })($88)));
                };
            })());
            return function ($86) {
                return $82($83($84($85($86))));
            };
        })();
        var purchaseInvoiceColumns = (function () {
            var supplierInvoiceNumbersColumn = (function () {
                if (props.showSupplierInvoiceNumbers) {
                    return [ {
                        key: "supplierInvoiceNumber",
                        label: React_Basic_DOM.text("Supplier No."),
                        cell: TacoTable_Types.PlainTextCell.create((function () {
                            var $89 = Data_Maybe.fromMaybe("");
                            return function ($90) {
                                return $89((function (v3) {
                                    return v3.supplierInvoiceNumber;
                                })($90));
                            };
                        })()),
                        sortProperty: new Data_Maybe.Just(InvoiceUtils.BySupplierInvoiceNumber.value),
                        width: new TacoTable_Types.Flex(5),
                        headerJSX: Data_Maybe.Nothing.value
                    } ];
                };
                return [  ];
            })();
            var invoiceNumberWithImageLink = function (invoice) {
                return invoiceImgLinkComponent({
                    invoiceNumber: invoice.invoiceNumber,
                    invoiceImg: (function () {
                        var $43 = Data_String_Common["null"](invoice.invoiceImg);
                        if ($43) {
                            return Data_Maybe.Nothing.value;
                        };
                        return new Data_Maybe.Just(invoice.invoiceImg);
                    })()
                });
            };
            var invoiceBunchIdsColumn = (function () {
                if (props.showInvoiceBunchIds) {
                    return [ {
                        key: "invoiceBunchId",
                        label: React_Basic_DOM.text("Tunnukset"),
                        cell: TacoTable_Types.PlainTextCell.create(function ($91) {
                            return invoiceBunchIdsOfLines((function (v3) {
                                return v3.invoiceLines;
                            })($91));
                        }),
                        sortProperty: new Data_Maybe.Just(InvoiceUtils.ByInvoiceBunchId.value),
                        width: new TacoTable_Types.Flex(5),
                        headerJSX: Data_Maybe.Nothing.value
                    } ];
                };
                return [  ];
            })();
            var checkBoxElement = function (invoice) {
                var isChecked = isInvoiceLineChecked(invoice.invoiceLines);
                var toggleF = (function () {
                    if (isChecked) {
                        return props.onInvoiceLinesDeselect;
                    };
                    return props.onInvoiceLinesSelect;
                })();
                return TacoCheckbox.component()()({
                    className: "no-margin",
                    isChecked: isChecked,
                    onToggle: TacoCheckbox.NoEvent.create(toggleF(Data_Functor.map(Data_Functor.functorArray)(function (v3) {
                        return v3.id;
                    })(invoice.invoiceLines))),
                    labelText: Data_Maybe.Nothing.value,
                    testId: "invoice-lines-toggle-" + Data_Show.show(Types_Invoice.showInvoiceId)(invoice.invoiceId)
                });
            };
            var optionalCheckbox = (function () {
                if (props.showCheckboxes) {
                    return [ {
                        key: "checkbox",
                        label: React_Basic_DOM.text(""),
                        cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], checkBoxElement),
                        sortProperty: Data_Maybe.Nothing.value,
                        width: new TacoTable_Types.Flex(2),
                        headerJSX: Data_Maybe.Nothing.value
                    } ];
                };
                return [  ];
            })();
            return Data_Semigroup.append(Data_Semigroup.semigroupArray)(optionalCheckbox)(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ {
                key: "date",
                label: React_Basic_DOM.text("P\xe4iv\xe4m\xe4\xe4r\xe4"),
                cell: new TacoTable_Types.PlainDateStringCell(function (v3) {
                    return v3.date;
                }),
                sortProperty: new Data_Maybe.Just(InvoiceUtils.ByDate.value),
                width: new TacoTable_Types.Flex(5),
                headerJSX: Data_Maybe.Nothing.value
            }, {
                key: "invoiceNumber",
                label: React_Basic_DOM.text("Nro."),
                cell: new TacoTable_Types.JSXCell([  ], invoiceNumberWithImageLink),
                sortProperty: new Data_Maybe.Just(InvoiceUtils.ByInvoiceNumber.value),
                width: new TacoTable_Types.Flex(8),
                headerJSX: Data_Maybe.Nothing.value
            } ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(invoiceBunchIdsColumn)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(supplierInvoiceNumbersColumn)([ {
                key: "supplier",
                label: React_Basic_DOM.text("Toimittaja"),
                cell: TacoTable_Types.JSXCell.create([  ])(function (invoice) {
                    return supplierWithDescriptionHead(invoice.type)(invoice.supplier)(invoice.description);
                }),
                sortProperty: new Data_Maybe.Just(InvoiceUtils.BySupplier.value),
                width: new TacoTable_Types.Flex(25),
                headerJSX: Data_Maybe.Nothing.value
            }, {
                key: "value",
                label: React_Basic_DOM.text("Summa"),
                cell: TacoTable_Types.PlainEuroCell.create((function () {
                    var $92 = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber);
                    var $93 = Data_Functor.map(Data_Functor.functorArray)(function (v3) {
                        return v3.value;
                    });
                    return function ($94) {
                        return $92($93((function (v3) {
                            return v3.invoiceLines;
                        })($94)));
                    };
                })()),
                sortProperty: new Data_Maybe.Just(InvoiceUtils.ByValue.value),
                width: new TacoTable_Types.Flex(6),
                headerJSX: Data_Maybe.Nothing.value
            } ]))));
        })();
        var invoiceBunchIdWithDescriptionHead = function (invoiceBunchId) {
            return React_Basic_DOM_Generated.div()({
                className: "invoice-bunch-id-with-description-head",
                children: [ React_Basic_DOM_Generated.span()({
                    className: "invoice-bunch-id",
                    children: Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM.text(Data_Newtype.un()(Types_Invoice.InvoiceBunchId)(Utils.defaultNull("")(invoiceBunchId))))
                }) ]
            });
        };
        var invoiceBunchIdInvoiceColumns = (function () {
            var invoiceInvoiceBunchIdInvoiceNumbersColumn = (function () {
                if (props.showInvoiceBunchIds) {
                    return [ {
                        key: "invoiceBunchId",
                        label: React_Basic_DOM.text("Tunnukset"),
                        cell: TacoTable_Types.PlainTextCell.create(function ($95) {
                            return invoiceBunchIdsOfLines((function (v3) {
                                return v3.invoiceLines;
                            })($95));
                        }),
                        sortProperty: new Data_Maybe.Just(InvoiceUtils.ByInvoiceBunchId.value),
                        width: new TacoTable_Types.Flex(5),
                        headerJSX: Data_Maybe.Nothing.value
                    } ];
                };
                return [  ];
            })();
            var checkBoxElement = function (linesByBunchId) {
                var filteredLines = Data_Array.filter(function (line) {
                    return Data_Foldable.elem(Data_Foldable.foldableArray)(Types_Invoice.eqInvoiceId)(line.invoiceId)(Data_Functor.map(Data_Functor.functorArray)(function (v3) {
                        return v3.invoiceId;
                    })(props.filteredRows));
                })(linesByBunchId.invoiceLines);
                var isChecked = isInvoiceLineChecked(filteredLines);
                var toggleF = (function () {
                    if (isChecked) {
                        return props.onInvoiceLinesDeselect;
                    };
                    return props.onInvoiceLinesSelect;
                })();
                return TacoCheckbox.component()()({
                    className: "no-margin",
                    isChecked: isChecked,
                    onToggle: TacoCheckbox.NoEvent.create(toggleF(Data_Functor.map(Data_Functor.functorArray)(function (v3) {
                        return v3.id;
                    })(filteredLines))),
                    labelText: Data_Maybe.Nothing.value,
                    testId: "toggle-lines-by-bunch-id-" + Data_Show.show(Data_Maybe.showMaybe(Types_Invoice.showInvoiceBunchId))(linesByBunchId.invoiceBunchId)
                });
            };
            var optionalCheckbox = (function () {
                if (props.showCheckboxes) {
                    return [ {
                        key: "checkbox",
                        label: React_Basic_DOM.text(""),
                        cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], checkBoxElement),
                        sortProperty: new Data_Maybe.Just(InvoiceUtils.ByDate.value),
                        width: new TacoTable_Types.Flex(2),
                        headerJSX: Data_Maybe.Nothing.value
                    } ];
                };
                return [  ];
            })();
            return Data_Semigroup.append(Data_Semigroup.semigroupArray)(optionalCheckbox)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(invoiceInvoiceBunchIdInvoiceNumbersColumn)([ {
                key: "invoiceBunchId",
                label: React_Basic_DOM.text("Koontilasku"),
                cell: TacoTable_Types.JSXCell.create([  ])(function (linesByBunchId) {
                    return invoiceBunchIdWithDescriptionHead(Data_Nullable.toNullable(linesByBunchId.invoiceBunchId));
                }),
                sortProperty: new Data_Maybe.Just(InvoiceUtils.ByInvoiceBunchId.value),
                width: new TacoTable_Types.Flex(25),
                headerJSX: Data_Maybe.Nothing.value
            }, {
                key: "value",
                label: React_Basic_DOM.text("Summa"),
                cell: TacoTable_Types.PlainEuroCell.create((function () {
                    var $96 = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber);
                    var $97 = Data_Functor.map(Data_Functor.functorArray)(function (v3) {
                        return v3.value;
                    });
                    var $98 = Data_Array.filter(function (line) {
                        return Data_Foldable.elem(Data_Foldable.foldableArray)(Types_Invoice.eqInvoiceId)(line.invoiceId)(Data_Functor.map(Data_Functor.functorArray)(function (v3) {
                            return v3.invoiceId;
                        })(props.filteredRows));
                    });
                    return function ($99) {
                        return $96($97($98((function (v3) {
                            return v3.invoiceLines;
                        })($99))));
                    };
                })()),
                sortProperty: new Data_Maybe.Just(InvoiceUtils.ByValue.value),
                width: new TacoTable_Types.Flex(6),
                headerJSX: Data_Maybe.Nothing.value
            } ]));
        })();
        var getInvoiceBunchIdInvoicesRow = function (linesByBunchId) {
            var mkInvoiceBunchIdFoldingElement = function (invoiceLinesForBunchId) {
                return InvoiceBunchIdInvoiceDetails.invoiceBunchIdInvoiceDetails({
                    invoiceLinesForBunchId: invoiceLinesForBunchId,
                    selectedInvoiceLineIds: props.selectedInvoiceLineIds,
                    onInvoiceLinesSelect: props.onInvoiceLinesSelect,
                    onInvoiceLinesDeselect: props.onInvoiceLinesDeselect,
                    showInvoiceBunchIds: props.showInvoiceBunchIds,
                    showCheckboxes: props.showCheckboxes,
                    showQuantityColumn: props.showQuantityColumn,
                    showUnitColumn: props.showUnitColumn,
                    showUnitPriceColumn: props.showUnitPriceColumn,
                    invoiceImgLinkComponent: invoiceImgLinkComponent,
                    filteredRows: props.filteredRows
                });
            };
            var className = Utils_ClassNames.mkClassName([ Data_Monoid.guard(Data_Maybe.monoidMaybe(Data_Semigroup.semigroupString))(isInvoiceLineChecked(linesByBunchId.invoiceLines))(new Data_Maybe.Just("checked")), invoiceLinesSelectedClassName(linesByBunchId.invoiceLines) ]);
            var bunchFoldingState = (function () {
                var $50 = Data_Set.member(Data_Maybe.ordMaybe(Types_Invoice.ordInvoiceBunchId))(linesByBunchId.invoiceBunchId)(v2.value0);
                if ($50) {
                    return TacoTable_Types.Unfolded.value;
                };
                return TacoTable_Types.Folded.value;
            })();
            return {
                rowData: linesByBunchId,
                rowKey: Data_Show.show(Data_Show.showRecord()(Data_Show.showRecordFieldsCons({
                    reflectSymbol: function () {
                        return "invoiceBunchId";
                    }
                })(Data_Show.showRecordFieldsCons({
                    reflectSymbol: function () {
                        return "showSettings";
                    }
                })(Data_Show.showRecordFieldsNil)(Data_Show.showArray(Data_Show.showBoolean)))(Data_Maybe.showMaybe(Types_Invoice.showInvoiceBunchId))))({
                    invoiceBunchId: linesByBunchId.invoiceBunchId,
                    showSettings: [ props.showQuantityColumn, props.showUnitColumn, props.showUnitPriceColumn ]
                }),
                onClick: function (v3) {
                    return toggleInvoiceBunchIdRowFolded(linesByBunchId.invoiceBunchId);
                },
                className: className,
                foldingElement: Data_Maybe.Just.create(new TacoTable_Types.FoldingElement(bunchFoldingState, mkInvoiceBunchIdFoldingElement)),
                columns: invoiceBunchIdInvoiceColumns
            };
        };
        var getInvoiceBunchInvoicesTable = function (ivs) {
            return TacoTable.tableStateless(InvoiceUtils.getNameInvoiceLineSortProperty)(InvoiceUtils.eqInvoiceLineSortProperty)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "invoiceLines";
                }
            })(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "value";
                }
            })(Data_Eq.eqNumber))()({
                reflectSymbol: function () {
                    return "unit";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_Invoice.eqInvoiceType))()({
                reflectSymbol: function () {
                    return "supplierInvoiceNumber";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "supplierBusinessId";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "supplier";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "socialExpensesPercentage";
                }
            })(Types_Unit.eqPercentageOfHundred))()({
                reflectSymbol: function () {
                    return "quantity";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                reflectSymbol: function () {
                    return "lineDescription";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "invoiceNumber";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "invoiceImg";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "invoiceId";
                }
            })(Types_Invoice.eqInvoiceId))()({
                reflectSymbol: function () {
                    return "invoiceBunchId";
                }
            })(Data_Nullable.eqNullable(Types_Invoice.eqInvoiceBunchId)))()({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Invoice.eqInvoiceRowId))()({
                reflectSymbol: function () {
                    return "hours";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                reflectSymbol: function () {
                    return "description";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "date";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "costGroupCode";
                }
            })(Data_Nullable.eqNullable(Types_CostGroup.eqCostGroupCode)))()({
                reflectSymbol: function () {
                    return "costClassCode";
                }
            })(Types_Newtypes.eqCostClassCode))()({
                reflectSymbol: function () {
                    return "costClass";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString))))))()({
                reflectSymbol: function () {
                    return "invoiceBunchId";
                }
            })(Data_Maybe.eqMaybe(Types_Invoice.eqInvoiceBunchId))))(invoicesTableInstance)({
                rows: Data_Functor.map(Data_Functor.functorArray)(getInvoiceBunchIdInvoicesRow)(ivs),
                columns: invoiceBunchIdInvoiceColumns,
                sortProperty: new Data_Maybe.Just(props.sortProperty),
                sortingOrder: props.sortingOrder,
                onSort: props.onSort,
                showHeader: true,
                styleSet: TacoTable_Styles.foldingTableStyleSet,
                onResize: function (v3) {
                    return function (v4) {
                        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                    };
                },
                columnSizeOffsets: Data_Maybe.Nothing.value
            });
        };
        var validateBusinessId = function (s) {
            var $51 = Data_String_CodePoints.length(s) > 50;
            if ($51) {
                return Data_Validation_Semigroup.invalid(Control_Applicative.pure(Data_List_Lazy_Types.applicativeNonEmptyList)("Sy\xf6tt\xe4m\xe4ss\xe4si Y-tunnuksessa on " + (Data_Show.show(Data_Show.showInt)(Data_String_CodePoints.length(s) - 50 | 0) + " merkki\xe4 liikaa.")));
            };
            return Control_Applicative.pure(Data_Validation_Semigroup.applicativeV(Data_List_Lazy_Types.semigroupNonEmptyList))(s);
        };
        var businessIdInputMessage = function (supplier) {
            return "Sy\xf6t\xe4 uusi Y-tunnus toimittajalle \"" + (Data_Show.show(Types_Project.showSupplier)(supplier) + ("\". Max " + (Data_Show.show(Data_Show.showInt)(50) + " merkki\xe4.")));
        };
        var mkSupplierBusinessIdComponent = function (supplier) {
            return function (businessId) {
                if (supplier === "") {
                    return React_Basic.empty;
                };
                var input = ColumnWidgets.stringPrompt(businessId)(new Data_Maybe.Just("Lis\xe4\xe4 Y-tunnus"))(businessIdInputMessage(supplier))(validateBusinessId)(onSupplierBusinessIdInput(supplier));
                return React_Basic_DOM_Generated.div()({
                    className: "supplier-business-id-input",
                    children: [ input ]
                });
            };
        };
        var mkInvoiceFoldingElement = function (invoice) {
            return InvoiceDetails.invoiceDetails({
                invoice: invoice,
                selectedInvoiceLineIds: props.selectedInvoiceLineIds,
                onInvoiceLinesSelect: props.onInvoiceLinesSelect,
                onInvoiceLinesDeselect: props.onInvoiceLinesDeselect,
                supplierBusinessIdComponent: mkSupplierBusinessIdComponent(invoice.supplier)(invoice.supplierBusinessId),
                showInvoiceBunchIds: props.showInvoiceBunchIds,
                showSupplierInvoiceNumbers: props.showSupplierInvoiceNumbers,
                showCheckboxes: props.showCheckboxes,
                showQuantityColumn: props.showQuantityColumn,
                showUnitColumn: props.showUnitColumn,
                showUnitPriceColumn: props.showUnitPriceColumn
            });
        };
        var getPurchaseInvoiceRow = function (invoice) {
            var foldingState = (function () {
                var $53 = Data_Set.member(Types_Invoice.ordInvoiceId)(invoice.invoiceId)(v.value0);
                if ($53) {
                    return TacoTable_Types.Unfolded.value;
                };
                return TacoTable_Types.Folded.value;
            })();
            var className = Utils_ClassNames.mkClassName([ Data_Monoid.guard(Data_Maybe.monoidMaybe(Data_Semigroup.semigroupString))(isInvoiceLineChecked(invoice.invoiceLines))(new Data_Maybe.Just("checked")), invoiceLinesSelectedClassName(invoice.invoiceLines) ]);
            return {
                rowData: invoice,
                rowKey: Data_Show.show(Data_Show.showRecord()(Data_Show.showRecordFieldsCons({
                    reflectSymbol: function () {
                        return "invoiceId";
                    }
                })(Data_Show.showRecordFieldsCons({
                    reflectSymbol: function () {
                        return "showSettings";
                    }
                })(Data_Show.showRecordFieldsNil)(Data_Show.showArray(Data_Show.showBoolean)))(Types_Invoice.showInvoiceId)))({
                    invoiceId: invoice.invoiceId,
                    showSettings: [ props.showQuantityColumn, props.showUnitColumn, props.showUnitPriceColumn ]
                }),
                onClick: function (v3) {
                    return toggleInvoiceRowFolded(invoice.invoiceId);
                },
                className: className,
                foldingElement: Data_Maybe.Just.create(new TacoTable_Types.FoldingElement(foldingState, mkInvoiceFoldingElement)),
                columns: purchaseInvoiceColumns
            };
        };
        var getPurchaseInvoiceTable = function (ivs) {
            return TacoTable.tableStateless(InvoiceUtils.getNameInvoiceLineSortProperty)(InvoiceUtils.eqInvoiceLineSortProperty)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_Invoice.eqInvoiceType))()({
                reflectSymbol: function () {
                    return "supplierInvoiceNumber";
                }
            })(Data_Maybe.eqMaybe(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "supplierBusinessId";
                }
            })(Data_Maybe.eqMaybe(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "supplier";
                }
            })(Types_Project.eqSupplier))()({
                reflectSymbol: function () {
                    return "invoiceNumber";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "invoiceLines";
                }
            })(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "value";
                }
            })(Data_Eq.eqNumber))()({
                reflectSymbol: function () {
                    return "unit";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_Invoice.eqInvoiceType))()({
                reflectSymbol: function () {
                    return "supplierInvoiceNumber";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "supplierBusinessId";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "supplier";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "socialExpensesPercentage";
                }
            })(Types_Unit.eqPercentageOfHundred))()({
                reflectSymbol: function () {
                    return "quantity";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                reflectSymbol: function () {
                    return "lineDescription";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "invoiceNumber";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "invoiceImg";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "invoiceId";
                }
            })(Types_Invoice.eqInvoiceId))()({
                reflectSymbol: function () {
                    return "invoiceBunchId";
                }
            })(Data_Nullable.eqNullable(Types_Invoice.eqInvoiceBunchId)))()({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Invoice.eqInvoiceRowId))()({
                reflectSymbol: function () {
                    return "hours";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                reflectSymbol: function () {
                    return "description";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "date";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "costGroupCode";
                }
            })(Data_Nullable.eqNullable(Types_CostGroup.eqCostGroupCode)))()({
                reflectSymbol: function () {
                    return "costClassCode";
                }
            })(Types_Newtypes.eqCostClassCode))()({
                reflectSymbol: function () {
                    return "costClass";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString))))))()({
                reflectSymbol: function () {
                    return "invoiceImg";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "invoiceId";
                }
            })(Types_Invoice.eqInvoiceId))()({
                reflectSymbol: function () {
                    return "description";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "date";
                }
            })(Data_Eq.eqString)))(invoicesTableInstance)({
                rows: Data_Functor.map(Data_Functor.functorArray)(getPurchaseInvoiceRow)(ivs),
                columns: purchaseInvoiceColumns,
                sortProperty: new Data_Maybe.Just(props.sortProperty),
                sortingOrder: props.sortingOrder,
                onSort: props.onSort,
                showHeader: true,
                styleSet: TacoTable_Styles.foldingTableStyleSet,
                onResize: function (v3) {
                    return function (v4) {
                        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                    };
                },
                columnSizeOffsets: Data_Maybe.Nothing.value
            });
        };
        var supplierInvoiceColumns = (function () {
            var supplierWithPossibleDescriptionHead = function (linesBySupplier) {
                var allInvoiceLinesHaveSameDescription = function (desc) {
                    return Data_Foldable.all(Data_Foldable.foldableArray)(Data_HeytingAlgebra.heytingAlgebraBoolean)(Data_Eq.eq(Data_Nullable.eqNullable(Data_Eq.eqString))(desc))(Data_Functor.map(Data_Functor.functorArray)(function (v3) {
                        return v3.description;
                    })(linesBySupplier.invoiceLines));
                };
                return React_Basic_DOM_Generated.div_(Control_Applicative.pure(Control_Applicative.applicativeArray)((function () {
                    var v3 = Data_Functor.map(Data_Maybe.functorMaybe)(function (v4) {
                        return v4.description;
                    })(Data_Array.head(linesBySupplier.invoiceLines));
                    if (v3 instanceof Data_Maybe.Just) {
                        var $55 = allInvoiceLinesHaveSameDescription(v3.value0);
                        if ($55) {
                            return supplierWithDescriptionHead(Types_Invoice.Invoice.value)(linesBySupplier.supplier)(v3.value0);
                        };
                        return React_Basic_DOM.text(Data_Newtype.un()(Types_Project.Supplier)(linesBySupplier.supplier));
                    };
                    return React_Basic_DOM.text("");
                })()));
            };
            var invoiceSupplierInvoiceNumbersColumn = (function () {
                if (props.showSupplierInvoiceNumbers) {
                    return [ {
                        key: "supplierInvoiceNumber",
                        label: React_Basic_DOM.text("Supplier No."),
                        cell: TacoTable_Types.PlainTextCell.create((function () {
                            var $100 = Data_Maybe.fromMaybe("");
                            return function ($101) {
                                return $100((function (v3) {
                                    return v3.supplierInvoiceNumber;
                                })($101));
                            };
                        })()),
                        sortProperty: new Data_Maybe.Just(InvoiceUtils.BySupplierInvoiceNumber.value),
                        width: new TacoTable_Types.Flex(5),
                        headerJSX: Data_Maybe.Nothing.value
                    } ];
                };
                return [  ];
            })();
            var invoiceBunchIdsColumn = (function () {
                if (props.showInvoiceBunchIds) {
                    return [ {
                        key: "invoiceBunchId",
                        label: React_Basic_DOM.text("Tunnukset"),
                        cell: TacoTable_Types.PlainTextCell.create(function ($102) {
                            return invoiceBunchIdsOfLines((function (v3) {
                                return v3.invoiceLines;
                            })($102));
                        }),
                        sortProperty: new Data_Maybe.Just(InvoiceUtils.ByInvoiceBunchId.value),
                        width: new TacoTable_Types.Flex(5),
                        headerJSX: Data_Maybe.Nothing.value
                    } ];
                };
                return [  ];
            })();
            var checkBoxElement = function (linesBySupplier) {
                var isChecked = isInvoiceLineChecked(linesBySupplier.invoiceLines);
                var toggleF = (function () {
                    if (isChecked) {
                        return props.onInvoiceLinesDeselect;
                    };
                    return props.onInvoiceLinesSelect;
                })();
                return TacoCheckbox.component()()({
                    className: "no-margin",
                    isChecked: isChecked,
                    onToggle: TacoCheckbox.NoEvent.create(toggleF(Data_Functor.map(Data_Functor.functorArray)(function (v3) {
                        return v3.id;
                    })(linesBySupplier.invoiceLines))),
                    labelText: Data_Maybe.Nothing.value,
                    testId: "toggle-lines-by-supplier-" + Data_Show.show(Types_Project.showSupplier)(linesBySupplier.supplier)
                });
            };
            var optionalCheckbox = (function () {
                if (props.showCheckboxes) {
                    return [ {
                        key: "checkbox",
                        label: React_Basic_DOM.text(""),
                        cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], checkBoxElement),
                        sortProperty: new Data_Maybe.Just(InvoiceUtils.ByDate.value),
                        width: new TacoTable_Types.Flex(2),
                        headerJSX: Data_Maybe.Nothing.value
                    } ];
                };
                return [  ];
            })();
            return Data_Semigroup.append(Data_Semigroup.semigroupArray)(optionalCheckbox)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(invoiceBunchIdsColumn)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(invoiceSupplierInvoiceNumbersColumn)([ {
                key: "supplierBusinessId",
                label: React_Basic_DOM.text("Y-Tunnus"),
                cell: TacoTable_Types.JSXCell.create([ TacoTable_Types.Input.value ])(function (iv) {
                    return mkSupplierBusinessIdComponent(iv.supplier)(iv.supplierBusinessId);
                }),
                sortProperty: new Data_Maybe.Just(InvoiceUtils.BySupplierBusinessId.value),
                width: new TacoTable_Types.Flex(6),
                headerJSX: Data_Maybe.Nothing.value
            }, {
                key: "supplier",
                label: React_Basic_DOM.text("Toimittaja"),
                cell: new TacoTable_Types.JSXCell([  ], supplierWithPossibleDescriptionHead),
                sortProperty: new Data_Maybe.Just(InvoiceUtils.BySupplier.value),
                width: new TacoTable_Types.Flex(25),
                headerJSX: Data_Maybe.Nothing.value
            }, {
                key: "value",
                label: React_Basic_DOM.text("Summa"),
                cell: TacoTable_Types.PlainEuroCell.create((function () {
                    var $103 = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber);
                    var $104 = Data_Functor.map(Data_Functor.functorArray)(function (v3) {
                        return v3.value;
                    });
                    return function ($105) {
                        return $103($104((function (v3) {
                            return v3.invoiceLines;
                        })($105)));
                    };
                })()),
                sortProperty: new Data_Maybe.Just(InvoiceUtils.ByValue.value),
                width: new TacoTable_Types.Flex(6),
                headerJSX: Data_Maybe.Nothing.value
            } ])));
        })();
        var getSupplierInvoicesRow = function (linesBySupplier) {
            var mkSupplierFoldingElement = function (invoiceLinesForSupplier) {
                return SupplierInvoiceDetails.supplierInvoiceDetails({
                    invoiceLinesForSupplier: invoiceLinesForSupplier,
                    selectedInvoiceLineIds: props.selectedInvoiceLineIds,
                    onInvoiceLinesSelect: props.onInvoiceLinesSelect,
                    onInvoiceLinesDeselect: props.onInvoiceLinesDeselect,
                    showInvoiceBunchIds: props.showInvoiceBunchIds,
                    showQuantityColumn: props.showQuantityColumn,
                    showUnitColumn: props.showUnitColumn,
                    showUnitPriceColumn: props.showUnitPriceColumn,
                    invoiceImgLinkComponent: invoiceImgLinkComponent,
                    showCheckboxes: props.showCheckboxes
                });
            };
            var foldingState = (function () {
                var $61 = Data_Set.member(Types_Project.ordSupplier)(linesBySupplier.supplier)(v1.value0);
                if ($61) {
                    return TacoTable_Types.Unfolded.value;
                };
                return TacoTable_Types.Folded.value;
            })();
            var className = Utils_ClassNames.mkClassName([ Data_Monoid.guard(Data_Maybe.monoidMaybe(Data_Semigroup.semigroupString))(isInvoiceLineChecked(linesBySupplier.invoiceLines))(new Data_Maybe.Just("checked")), invoiceLinesSelectedClassName(linesBySupplier.invoiceLines) ]);
            return {
                rowData: linesBySupplier,
                rowKey: Data_Show.show(Data_Show.showRecord()(Data_Show.showRecordFieldsCons({
                    reflectSymbol: function () {
                        return "showSettings";
                    }
                })(Data_Show.showRecordFieldsCons({
                    reflectSymbol: function () {
                        return "supplierId";
                    }
                })(Data_Show.showRecordFieldsNil)(Types_Project.showSupplier))(Data_Show.showArray(Data_Show.showBoolean))))({
                    supplierId: linesBySupplier.supplier,
                    showSettings: [ props.showQuantityColumn, props.showUnitColumn, props.showUnitPriceColumn ]
                }),
                onClick: function (v3) {
                    return toggleSupplierRowFolded(linesBySupplier.supplier);
                },
                className: className,
                foldingElement: Data_Maybe.Just.create(new TacoTable_Types.FoldingElement(foldingState, mkSupplierFoldingElement)),
                columns: supplierInvoiceColumns
            };
        };
        var getSupplierInvoicesTable = function (ivs) {
            return TacoTable.tableStateless(InvoiceUtils.getNameInvoiceLineSortProperty)(InvoiceUtils.eqInvoiceLineSortProperty)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "supplierInvoiceNumber";
                }
            })(Data_Maybe.eqMaybe(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "supplierBusinessId";
                }
            })(Data_Maybe.eqMaybe(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "supplier";
                }
            })(Types_Project.eqSupplier))()({
                reflectSymbol: function () {
                    return "invoiceLines";
                }
            })(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "value";
                }
            })(Data_Eq.eqNumber))()({
                reflectSymbol: function () {
                    return "unit";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_Invoice.eqInvoiceType))()({
                reflectSymbol: function () {
                    return "supplierInvoiceNumber";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "supplierBusinessId";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "supplier";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "socialExpensesPercentage";
                }
            })(Types_Unit.eqPercentageOfHundred))()({
                reflectSymbol: function () {
                    return "quantity";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                reflectSymbol: function () {
                    return "lineDescription";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "invoiceNumber";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "invoiceImg";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "invoiceId";
                }
            })(Types_Invoice.eqInvoiceId))()({
                reflectSymbol: function () {
                    return "invoiceBunchId";
                }
            })(Data_Nullable.eqNullable(Types_Invoice.eqInvoiceBunchId)))()({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Invoice.eqInvoiceRowId))()({
                reflectSymbol: function () {
                    return "hours";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                reflectSymbol: function () {
                    return "description";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "date";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "costGroupCode";
                }
            })(Data_Nullable.eqNullable(Types_CostGroup.eqCostGroupCode)))()({
                reflectSymbol: function () {
                    return "costClassCode";
                }
            })(Types_Newtypes.eqCostClassCode))()({
                reflectSymbol: function () {
                    return "costClass";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))))))(invoicesTableInstance)({
                rows: Data_Functor.map(Data_Functor.functorArray)(getSupplierInvoicesRow)(ivs),
                columns: supplierInvoiceColumns,
                sortProperty: new Data_Maybe.Just(props.sortProperty),
                sortingOrder: props.sortingOrder,
                onSort: props.onSort,
                showHeader: true,
                styleSet: TacoTable_Styles.foldingTableStyleSet,
                onResize: function (v3) {
                    return function (v4) {
                        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                    };
                },
                columnSizeOffsets: Data_Maybe.Nothing.value
            });
        };
        return React_Basic_DOM_Generated.div()({
            className: "invoices-table",
            children: Control_Applicative.pure(Control_Applicative.applicativeArray)((function () {
                if (props.invoiceData instanceof InvoiceUtils.InvoiceLinesByInvoice) {
                    return getPurchaseInvoiceTable(props.invoiceData.value0);
                };
                if (props.invoiceData instanceof InvoiceUtils.InvoiceLinesBySupplier) {
                    return getSupplierInvoicesTable(props.invoiceData.value0);
                };
                if (props.invoiceData instanceof InvoiceUtils.InvoiceLinesByInvoiceBunchId) {
                    return getInvoiceBunchInvoicesTable(props.invoiceData.value0);
                };
                throw new Error("Failed pattern match at InvoicesTable (line 394, column 7 - line 397, column 113): " + [ props.invoiceData.constructor.name ]);
            })())
        });
    };
});
module.exports = {
    invoicesTable: invoicesTable
};
