// Generated by purs version 0.14.5
"use strict";
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var LengthValidator = require("../LengthValidator/index.js");
var QuantityFormula_Parser = require("../QuantityFormula.Parser/index.js");
var QuantityFormula_Types = require("../QuantityFormula.Types/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var TacoInput = require("../TacoInput/index.js");
var Types_BuildingElementsView = require("../Types.BuildingElementsView/index.js");
var Types_Element = require("../Types.Element/index.js");
var Types_Estimation = require("../Types.Estimation/index.js");
var varNameValidator = (function () {
    var isValidVarName = function (name) {
        var v = QuantityFormula_Parser.parseQF(name);
        if (v instanceof Data_Either.Right && v.value0 instanceof QuantityFormula_Types.QVar) {
            return TacoInput.Valid.value;
        };
        return new TacoInput.Invalid("Muuttujanimess\xe4 saa esiinty\xe4 vain kirjaimia, numeroita ja alaviivoja.");
    };
    return TacoInput.andValueValidity(LengthValidator.lengthValidator(1)(3))(isValidVarName);
})();
var hasVariableNameConflicts = function (v) {
    if (v instanceof Types_Estimation.AddElementSpecs) {
        return !Data_Array["null"](v.value0.noCopyingForMeasures);
    };
    return false;
};
var renderVariableNameConflicts = function (v) {
    if (v instanceof Types_Estimation.AddElementSpecs) {
        var variableNames = Data_Functor.map(Data_Functor.functorArray)(function (v1) {
            return v1.code;
        })(v.value0.noCopyingForMeasures);
        var $22 = hasVariableNameConflicts(v);
        if ($22) {
            return React_Basic_DOM.text("Seuraavat muuttujat ovat jo kohderakenteella: " + Data_String_Common.joinWith(", ")(variableNames));
        };
        return React_Basic.empty;
    };
    return React_Basic.empty;
};
var conflictingMeasures = function (v) {
    return function (v1) {
        return function (v2) {
            return function (v3) {
                if (v instanceof Data_Maybe.Nothing) {
                    return [  ];
                };
                if (v2 instanceof Data_Maybe.Nothing) {
                    return [  ];
                };
                if (v instanceof Data_Maybe.Just && v2 instanceof Data_Maybe.Just) {
                    var targetElementMeasures = Data_Array.filter(function (v4) {
                        return Data_Eq.eq(Data_Maybe.eqMaybe(Types_Element.eqElementId))(v4.elementId)(new Data_Maybe.Just(v2.value0));
                    })(v3);
                    var sourceElementId = (function (v4) {
                        return v4.elementId;
                    })(Data_Newtype.un()(Types_Element.WasmEstimationElement)((function (v4) {
                        return v4.element;
                    })(Data_Newtype.un()(Types_BuildingElementsView.WasmElementsData)(v.value0))));
                    var sourceElementMeasures = Data_Array.filter(function (v4) {
                        return Data_Eq.eq(Data_Maybe.eqMaybe(Types_Element.eqElementId))(v4.elementId)(new Data_Maybe.Just(sourceElementId));
                    })(v1);
                    var collectMatchingVariable = function (acc) {
                        return function (v4) {
                            var v5 = Data_Array.findIndex(function (v6) {
                                return v6.code === v4.code;
                            })(targetElementMeasures);
                            if (v5 instanceof Data_Maybe.Nothing) {
                                return acc;
                            };
                            if (v5 instanceof Data_Maybe.Just) {
                                return Data_Array.cons(v4)(acc);
                            };
                            throw new Error("Failed pattern match at ElementUtils (line 42, column 7 - line 44, column 39): " + [ v5.constructor.name ]);
                        };
                    };
                    return Data_Array.foldl(collectMatchingVariable)([  ])(sourceElementMeasures);
                };
                throw new Error("Failed pattern match at ElementUtils (line 33, column 1 - line 33, column 137): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name, v3.constructor.name ]);
            };
        };
    };
};
module.exports = {
    conflictingMeasures: conflictingMeasures,
    renderVariableNameConflicts: renderVariableNameConflicts,
    hasVariableNameConflicts: hasVariableNameConflicts,
    varNameValidator: varNameValidator
};
