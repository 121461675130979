// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Auth = require("../Auth/index.js");
var Box = require("../Box/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Promise = require("../Control.Promise/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Utils = require("../Utils/index.js");
var component = TofuHooks.mkHookComponent("OauthCode")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState(false)();
        TofuHooks.useEffect([  ])(function __do() {
            Effect_Aff.launchAff_(Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(Control_Promise.toAffE(Auth.oAuthLogin)))(function (e) {
                if (e instanceof Data_Either.Right) {
                    return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)($foreign.hardRefreshRoot);
                };
                if (e instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Utils["unsafeLog2'"](Effect_Aff.monadEffectAff)("Error:")(e.value0))(function () {
                        return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(v1.value1(function (v2) {
                            return true;
                        }));
                    });
                };
                throw new Error("Failed pattern match at OauthCode (line 29, column 7 - line 33, column 46): " + [ e.constructor.name ]);
            }))();
            return Data_Monoid.mempty(Effect.monoidEffect(Effect.monoidEffect(Data_Monoid.monoidUnit)))();
        })();
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "oauth-login-message",
            css: Box.boxStyle([ Box.FlexColumn.value, Box.AlignCenter.value, Box.JustifyCenter.value, new Box.MinHeight(new TacoConstants.CustomSize("100vh")) ]),
            children: [ TacoText.component()()({
                text: (function () {
                    if (v1.value0) {
                        return "Kirjautuminen ep\xe4onnistui. Ota yhteytt\xe4 tukipalveluun.";
                    };
                    return "Kirjaudutaan sis\xe4\xe4n. Odota hetki.";
                })(),
                variant: new TacoText_Types.Heading(2),
                size: TacoConstants.XXL.value
            }) ]
        });
    };
});
module.exports = {
    component: component,
    hardRefreshRoot: $foreign.hardRefreshRoot
};
