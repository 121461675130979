// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var Record = require("../Record/index.js");
var TacoTabs_ButtonTab = require("../TacoTabs.ButtonTab/index.js");
var TacoTabs_NormalTab = require("../TacoTabs.NormalTab/index.js");
var TacoTabs_Styles = require("../TacoTabs.Styles/index.js");
var TacoTabs_Types = require("../TacoTabs.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var controlled = TofuHooks.mkHookComponent("TacoTabsUncontrolled")(function (v) {
    var isActive = function (tab) {
        return Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(new Data_Maybe.Just(TacoTabs_Types.getTabId(tab)))(v.activeTabId);
    };
    var injectProps = function (tab) {
        return Record.merge()()({
            active: Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(v.activeTabId)(new Data_Maybe.Just(tab.id)),
            setActiveTabId: v.setActiveTabId,
            orientation: v.orientation
        })(tab);
    };
    var renderTab = function (tab) {
        if (tab instanceof TacoTabs_Types.NormalTab) {
            return TacoTabs_NormalTab.component(injectProps(tab.value0));
        };
        if (tab instanceof TacoTabs_Types.ButtonTab) {
            return TacoTabs_ButtonTab.component(tab.value0);
        };
        if (tab instanceof TacoTabs_Types.SeparatorTab) {
            return Box.box("__TacoTabs__Separator")([ new Box.FlexGrow(1) ])([  ]);
        };
        if (tab instanceof TacoTabs_Types.JsxTab) {
            return tab.value0;
        };
        throw new Error("Failed pattern match at TacoTabs (line 40, column 21 - line 44, column 24): " + [ tab.constructor.name ]);
    };
    var tabsJSX = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        className: "__TacoTabs__TabsContainer",
        css: TacoTabs_Styles.tabsContainerStyles({
            orientation: v.orientation
        }),
        children: Data_Functor.map(Data_Functor.functorArray)(renderTab)(v.tabs)
    });
    var getActiveTab = Data_Array.find(isActive)(v.tabs);
    var activeTabChildren = (function () {
        if (getActiveTab instanceof Data_Maybe.Just && getActiveTab.value0 instanceof TacoTabs_Types.NormalTab) {
            return getActiveTab.value0.value0.children;
        };
        return [ React_Basic.empty ];
    })();
    var contentsJSX = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        className: "contents",
        css: TacoTabs_Styles.contentsStyles({
            orientation: v.orientation
        }),
        children: activeTabChildren
    });
    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        className: "__TacoTabs",
        css: TacoTabs_Styles.containerStyles({
            orientation: v.orientation
        }),
        children: [ tabsJSX, Data_Monoid.guard(React_Basic.monoidJSX)(!v.hideContents)(contentsJSX) ]
    }));
});
var component = TofuHooks.mkHookComponent("TacoTabs")(function (v) {
    var firstTab = Data_Array.find(TacoTabs_Types.isNormalTab)(v.tabs);
    return function __do() {
        var v1 = TofuHooks.useState(Data_Functor.map(Data_Maybe.functorMaybe)(TacoTabs_Types.getTabId)(firstTab))();
        return controlled({
            tabs: v.tabs,
            orientation: v.orientation,
            hideContents: v.hideContents,
            activeTabId: v1.value0,
            setActiveTabId: v1.value1
        });
    };
});
module.exports = {
    controlled: controlled,
    component: component
};
