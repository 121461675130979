// Generated by purs version 0.14.5
"use strict";
var IconAccountCircle24Px_1 = require("../IconAccountCircle24Px/index.js");
var IconAddPlusV1_1 = require("../IconAddPlusV1/index.js");
var IconArchive_1 = require("../IconArchive/index.js");
var IconArea_1 = require("../IconArea/index.js");
var IconArrow_1 = require("../IconArrow/index.js");
var IconArrowDown_1 = require("../IconArrowDown/index.js");
var IconArrowIndicator45Deg_1 = require("../IconArrowIndicator45Deg/index.js");
var IconArrowLeft_1 = require("../IconArrowLeft/index.js");
var IconArrowRight_1 = require("../IconArrowRight/index.js");
var IconArrowUp_1 = require("../IconArrowUp/index.js");
var IconBoxSelect24Px_1 = require("../IconBoxSelect24Px/index.js");
var IconCheck24Px_1 = require("../IconCheck24Px/index.js");
var IconCheckBox24Px_1 = require("../IconCheckBox24Px/index.js");
var IconCheckCircleBlueV1_1 = require("../IconCheckCircleBlueV1/index.js");
var IconCheckCircleV1_1 = require("../IconCheckCircleV1/index.js");
var IconCheckboxSelectedDisabledV1_1 = require("../IconCheckboxSelectedDisabledV1/index.js");
var IconCheckboxSelectedV1_1 = require("../IconCheckboxSelectedV1/index.js");
var IconChevronDownV1_1 = require("../IconChevronDownV1/index.js");
var IconChevronUpV1_1 = require("../IconChevronUpV1/index.js");
var IconClose24Px_1 = require("../IconClose24Px/index.js");
var IconCloseCrossV1_1 = require("../IconCloseCrossV1/index.js");
var IconCopyToProjectV1_1 = require("../IconCopyToProjectV1/index.js");
var IconDelete24Px_1 = require("../IconDelete24Px/index.js");
var IconDot_1 = require("../IconDot/index.js");
var IconDot2_1 = require("../IconDot2/index.js");
var IconEditInactiveV1_1 = require("../IconEditInactiveV1/index.js");
var IconEditV1_1 = require("../IconEditV1/index.js");
var IconFetch_1 = require("../IconFetch/index.js");
var IconFileDownloadV1_1 = require("../IconFileDownloadV1/index.js");
var IconFileUploadV1_1 = require("../IconFileUploadV1/index.js");
var IconFilterListV1_1 = require("../IconFilterListV1/index.js");
var IconFlagV1_1 = require("../IconFlagV1/index.js");
var IconGetApp24Px_1 = require("../IconGetApp24Px/index.js");
var IconGetAppV1_1 = require("../IconGetAppV1/index.js");
var IconGt24Px_1 = require("../IconGt24Px/index.js");
var IconGtDisabledV1_1 = require("../IconGtDisabledV1/index.js");
var IconGtLight24Px_1 = require("../IconGtLight24Px/index.js");
var IconGtV1_1 = require("../IconGtV1/index.js");
var IconGtWhite24Px_1 = require("../IconGtWhite24Px/index.js");
var IconInfoWhite_1 = require("../IconInfoWhite/index.js");
var IconInvoiceImageV1_1 = require("../IconInvoiceImageV1/index.js");
var IconLine_1 = require("../IconLine/index.js");
var IconLogout_1 = require("../IconLogout/index.js");
var IconLtDisabledV1_1 = require("../IconLtDisabledV1/index.js");
var IconLtV1_1 = require("../IconLtV1/index.js");
var IconMemoIndicatorV1_1 = require("../IconMemoIndicatorV1/index.js");
var IconMenuV2_1 = require("../IconMenuV2/index.js");
var IconMove_1 = require("../IconMove/index.js");
var IconPolyline_1 = require("../IconPolyline/index.js");
var IconRectangle_1 = require("../IconRectangle/index.js");
var IconRotate90_1 = require("../IconRotate90/index.js");
var IconSearch24Px_1 = require("../IconSearch24Px/index.js");
var IconSortIndicatorActiveReversedV1_1 = require("../IconSortIndicatorActiveReversedV1/index.js");
var IconSortIndicatorActiveV1_1 = require("../IconSortIndicatorActiveV1/index.js");
var IconSortIndicatorInactiveV1_1 = require("../IconSortIndicatorInactiveV1/index.js");
var IconSubtractV1_1 = require("../IconSubtractV1/index.js");
var IconTocV1_1 = require("../IconTocV1/index.js");
var IconTriangle_1 = require("../IconTriangle/index.js");
var IconUnarchive_1 = require("../IconUnarchive/index.js");
var IconAccountCircle24Px = (function () {
    function IconAccountCircle24Px() {

    };
    IconAccountCircle24Px.value = new IconAccountCircle24Px();
    return IconAccountCircle24Px;
})();
var IconAddPlusV1 = (function () {
    function IconAddPlusV1() {

    };
    IconAddPlusV1.value = new IconAddPlusV1();
    return IconAddPlusV1;
})();
var IconArchive = (function () {
    function IconArchive() {

    };
    IconArchive.value = new IconArchive();
    return IconArchive;
})();
var IconArea = (function () {
    function IconArea() {

    };
    IconArea.value = new IconArea();
    return IconArea;
})();
var IconArrow = (function () {
    function IconArrow() {

    };
    IconArrow.value = new IconArrow();
    return IconArrow;
})();
var IconArrowDown = (function () {
    function IconArrowDown() {

    };
    IconArrowDown.value = new IconArrowDown();
    return IconArrowDown;
})();
var IconArrowIndicator45Deg = (function () {
    function IconArrowIndicator45Deg() {

    };
    IconArrowIndicator45Deg.value = new IconArrowIndicator45Deg();
    return IconArrowIndicator45Deg;
})();
var IconArrowLeft = (function () {
    function IconArrowLeft() {

    };
    IconArrowLeft.value = new IconArrowLeft();
    return IconArrowLeft;
})();
var IconArrowRight = (function () {
    function IconArrowRight() {

    };
    IconArrowRight.value = new IconArrowRight();
    return IconArrowRight;
})();
var IconArrowUp = (function () {
    function IconArrowUp() {

    };
    IconArrowUp.value = new IconArrowUp();
    return IconArrowUp;
})();
var IconBoxSelect24Px = (function () {
    function IconBoxSelect24Px() {

    };
    IconBoxSelect24Px.value = new IconBoxSelect24Px();
    return IconBoxSelect24Px;
})();
var IconCheck24Px = (function () {
    function IconCheck24Px() {

    };
    IconCheck24Px.value = new IconCheck24Px();
    return IconCheck24Px;
})();
var IconCheckBox24Px = (function () {
    function IconCheckBox24Px() {

    };
    IconCheckBox24Px.value = new IconCheckBox24Px();
    return IconCheckBox24Px;
})();
var IconCheckCircleBlueV1 = (function () {
    function IconCheckCircleBlueV1() {

    };
    IconCheckCircleBlueV1.value = new IconCheckCircleBlueV1();
    return IconCheckCircleBlueV1;
})();
var IconCheckCircleV1 = (function () {
    function IconCheckCircleV1() {

    };
    IconCheckCircleV1.value = new IconCheckCircleV1();
    return IconCheckCircleV1;
})();
var IconCheckboxSelectedDisabledV1 = (function () {
    function IconCheckboxSelectedDisabledV1() {

    };
    IconCheckboxSelectedDisabledV1.value = new IconCheckboxSelectedDisabledV1();
    return IconCheckboxSelectedDisabledV1;
})();
var IconCheckboxSelectedV1 = (function () {
    function IconCheckboxSelectedV1() {

    };
    IconCheckboxSelectedV1.value = new IconCheckboxSelectedV1();
    return IconCheckboxSelectedV1;
})();
var IconChevronDownV1 = (function () {
    function IconChevronDownV1() {

    };
    IconChevronDownV1.value = new IconChevronDownV1();
    return IconChevronDownV1;
})();
var IconChevronUpV1 = (function () {
    function IconChevronUpV1() {

    };
    IconChevronUpV1.value = new IconChevronUpV1();
    return IconChevronUpV1;
})();
var IconClose24Px = (function () {
    function IconClose24Px() {

    };
    IconClose24Px.value = new IconClose24Px();
    return IconClose24Px;
})();
var IconCloseCrossV1 = (function () {
    function IconCloseCrossV1() {

    };
    IconCloseCrossV1.value = new IconCloseCrossV1();
    return IconCloseCrossV1;
})();
var IconCopyToProjectV1 = (function () {
    function IconCopyToProjectV1() {

    };
    IconCopyToProjectV1.value = new IconCopyToProjectV1();
    return IconCopyToProjectV1;
})();
var IconDelete24Px = (function () {
    function IconDelete24Px() {

    };
    IconDelete24Px.value = new IconDelete24Px();
    return IconDelete24Px;
})();
var IconDot = (function () {
    function IconDot() {

    };
    IconDot.value = new IconDot();
    return IconDot;
})();
var IconDot2 = (function () {
    function IconDot2() {

    };
    IconDot2.value = new IconDot2();
    return IconDot2;
})();
var IconEditInactiveV1 = (function () {
    function IconEditInactiveV1() {

    };
    IconEditInactiveV1.value = new IconEditInactiveV1();
    return IconEditInactiveV1;
})();
var IconEditV1 = (function () {
    function IconEditV1() {

    };
    IconEditV1.value = new IconEditV1();
    return IconEditV1;
})();
var IconFetch = (function () {
    function IconFetch() {

    };
    IconFetch.value = new IconFetch();
    return IconFetch;
})();
var IconFileDownloadV1 = (function () {
    function IconFileDownloadV1() {

    };
    IconFileDownloadV1.value = new IconFileDownloadV1();
    return IconFileDownloadV1;
})();
var IconFileUploadV1 = (function () {
    function IconFileUploadV1() {

    };
    IconFileUploadV1.value = new IconFileUploadV1();
    return IconFileUploadV1;
})();
var IconFilterListV1 = (function () {
    function IconFilterListV1() {

    };
    IconFilterListV1.value = new IconFilterListV1();
    return IconFilterListV1;
})();
var IconFlagV1 = (function () {
    function IconFlagV1() {

    };
    IconFlagV1.value = new IconFlagV1();
    return IconFlagV1;
})();
var IconGetApp24Px = (function () {
    function IconGetApp24Px() {

    };
    IconGetApp24Px.value = new IconGetApp24Px();
    return IconGetApp24Px;
})();
var IconGetAppV1 = (function () {
    function IconGetAppV1() {

    };
    IconGetAppV1.value = new IconGetAppV1();
    return IconGetAppV1;
})();
var IconGt24Px = (function () {
    function IconGt24Px() {

    };
    IconGt24Px.value = new IconGt24Px();
    return IconGt24Px;
})();
var IconGtDisabledV1 = (function () {
    function IconGtDisabledV1() {

    };
    IconGtDisabledV1.value = new IconGtDisabledV1();
    return IconGtDisabledV1;
})();
var IconGtLight24Px = (function () {
    function IconGtLight24Px() {

    };
    IconGtLight24Px.value = new IconGtLight24Px();
    return IconGtLight24Px;
})();
var IconGtV1 = (function () {
    function IconGtV1() {

    };
    IconGtV1.value = new IconGtV1();
    return IconGtV1;
})();
var IconGtWhite24Px = (function () {
    function IconGtWhite24Px() {

    };
    IconGtWhite24Px.value = new IconGtWhite24Px();
    return IconGtWhite24Px;
})();
var IconInfoWhite = (function () {
    function IconInfoWhite() {

    };
    IconInfoWhite.value = new IconInfoWhite();
    return IconInfoWhite;
})();
var IconInvoiceImageV1 = (function () {
    function IconInvoiceImageV1() {

    };
    IconInvoiceImageV1.value = new IconInvoiceImageV1();
    return IconInvoiceImageV1;
})();
var IconLine = (function () {
    function IconLine() {

    };
    IconLine.value = new IconLine();
    return IconLine;
})();
var IconLogout = (function () {
    function IconLogout() {

    };
    IconLogout.value = new IconLogout();
    return IconLogout;
})();
var IconLtDisabledV1 = (function () {
    function IconLtDisabledV1() {

    };
    IconLtDisabledV1.value = new IconLtDisabledV1();
    return IconLtDisabledV1;
})();
var IconLtV1 = (function () {
    function IconLtV1() {

    };
    IconLtV1.value = new IconLtV1();
    return IconLtV1;
})();
var IconMemoIndicatorV1 = (function () {
    function IconMemoIndicatorV1() {

    };
    IconMemoIndicatorV1.value = new IconMemoIndicatorV1();
    return IconMemoIndicatorV1;
})();
var IconMenuV2 = (function () {
    function IconMenuV2() {

    };
    IconMenuV2.value = new IconMenuV2();
    return IconMenuV2;
})();
var IconMove = (function () {
    function IconMove() {

    };
    IconMove.value = new IconMove();
    return IconMove;
})();
var IconPolyline = (function () {
    function IconPolyline() {

    };
    IconPolyline.value = new IconPolyline();
    return IconPolyline;
})();
var IconRectangle = (function () {
    function IconRectangle() {

    };
    IconRectangle.value = new IconRectangle();
    return IconRectangle;
})();
var IconRotate90 = (function () {
    function IconRotate90() {

    };
    IconRotate90.value = new IconRotate90();
    return IconRotate90;
})();
var IconSearch24Px = (function () {
    function IconSearch24Px() {

    };
    IconSearch24Px.value = new IconSearch24Px();
    return IconSearch24Px;
})();
var IconSortIndicatorActiveReversedV1 = (function () {
    function IconSortIndicatorActiveReversedV1() {

    };
    IconSortIndicatorActiveReversedV1.value = new IconSortIndicatorActiveReversedV1();
    return IconSortIndicatorActiveReversedV1;
})();
var IconSortIndicatorActiveV1 = (function () {
    function IconSortIndicatorActiveV1() {

    };
    IconSortIndicatorActiveV1.value = new IconSortIndicatorActiveV1();
    return IconSortIndicatorActiveV1;
})();
var IconSortIndicatorInactiveV1 = (function () {
    function IconSortIndicatorInactiveV1() {

    };
    IconSortIndicatorInactiveV1.value = new IconSortIndicatorInactiveV1();
    return IconSortIndicatorInactiveV1;
})();
var IconSubtractV1 = (function () {
    function IconSubtractV1() {

    };
    IconSubtractV1.value = new IconSubtractV1();
    return IconSubtractV1;
})();
var IconTocV1 = (function () {
    function IconTocV1() {

    };
    IconTocV1.value = new IconTocV1();
    return IconTocV1;
})();
var IconTriangle = (function () {
    function IconTriangle() {

    };
    IconTriangle.value = new IconTriangle();
    return IconTriangle;
})();
var IconUnarchive = (function () {
    function IconUnarchive() {

    };
    IconUnarchive.value = new IconUnarchive();
    return IconUnarchive;
})();
var IconCustom = (function () {
    function IconCustom(value0) {
        this.value0 = value0;
    };
    IconCustom.create = function (value0) {
        return new IconCustom(value0);
    };
    return IconCustom;
})();
var iconJSX = function (v) {
    if (v instanceof IconAccountCircle24Px) {
        return IconAccountCircle24Px_1.icon;
    };
    if (v instanceof IconAddPlusV1) {
        return IconAddPlusV1_1.icon;
    };
    if (v instanceof IconArchive) {
        return IconArchive_1.icon;
    };
    if (v instanceof IconArea) {
        return IconArea_1.icon;
    };
    if (v instanceof IconArrow) {
        return IconArrow_1.icon;
    };
    if (v instanceof IconArrowDown) {
        return IconArrowDown_1.icon;
    };
    if (v instanceof IconArrowIndicator45Deg) {
        return IconArrowIndicator45Deg_1.icon;
    };
    if (v instanceof IconArrowLeft) {
        return IconArrowLeft_1.icon;
    };
    if (v instanceof IconArrowRight) {
        return IconArrowRight_1.icon;
    };
    if (v instanceof IconArrowUp) {
        return IconArrowUp_1.icon;
    };
    if (v instanceof IconBoxSelect24Px) {
        return IconBoxSelect24Px_1.icon;
    };
    if (v instanceof IconCheck24Px) {
        return IconCheck24Px_1.icon;
    };
    if (v instanceof IconCheckBox24Px) {
        return IconCheckBox24Px_1.icon;
    };
    if (v instanceof IconCheckCircleBlueV1) {
        return IconCheckCircleBlueV1_1.icon;
    };
    if (v instanceof IconCheckCircleV1) {
        return IconCheckCircleV1_1.icon;
    };
    if (v instanceof IconCheckboxSelectedDisabledV1) {
        return IconCheckboxSelectedDisabledV1_1.icon;
    };
    if (v instanceof IconCheckboxSelectedV1) {
        return IconCheckboxSelectedV1_1.icon;
    };
    if (v instanceof IconChevronDownV1) {
        return IconChevronDownV1_1.icon;
    };
    if (v instanceof IconChevronUpV1) {
        return IconChevronUpV1_1.icon;
    };
    if (v instanceof IconClose24Px) {
        return IconClose24Px_1.icon;
    };
    if (v instanceof IconCloseCrossV1) {
        return IconCloseCrossV1_1.icon;
    };
    if (v instanceof IconCopyToProjectV1) {
        return IconCopyToProjectV1_1.icon;
    };
    if (v instanceof IconDelete24Px) {
        return IconDelete24Px_1.icon;
    };
    if (v instanceof IconDot) {
        return IconDot_1.icon;
    };
    if (v instanceof IconDot2) {
        return IconDot2_1.icon;
    };
    if (v instanceof IconEditInactiveV1) {
        return IconEditInactiveV1_1.icon;
    };
    if (v instanceof IconEditV1) {
        return IconEditV1_1.icon;
    };
    if (v instanceof IconFetch) {
        return IconFetch_1.icon;
    };
    if (v instanceof IconFileDownloadV1) {
        return IconFileDownloadV1_1.icon;
    };
    if (v instanceof IconFileUploadV1) {
        return IconFileUploadV1_1.icon;
    };
    if (v instanceof IconFilterListV1) {
        return IconFilterListV1_1.icon;
    };
    if (v instanceof IconFlagV1) {
        return IconFlagV1_1.icon;
    };
    if (v instanceof IconGetApp24Px) {
        return IconGetApp24Px_1.icon;
    };
    if (v instanceof IconGetAppV1) {
        return IconGetAppV1_1.icon;
    };
    if (v instanceof IconGt24Px) {
        return IconGt24Px_1.icon;
    };
    if (v instanceof IconGtDisabledV1) {
        return IconGtDisabledV1_1.icon;
    };
    if (v instanceof IconGtLight24Px) {
        return IconGtLight24Px_1.icon;
    };
    if (v instanceof IconGtV1) {
        return IconGtV1_1.icon;
    };
    if (v instanceof IconGtWhite24Px) {
        return IconGtWhite24Px_1.icon;
    };
    if (v instanceof IconInfoWhite) {
        return IconInfoWhite_1.icon;
    };
    if (v instanceof IconInvoiceImageV1) {
        return IconInvoiceImageV1_1.icon;
    };
    if (v instanceof IconLine) {
        return IconLine_1.icon;
    };
    if (v instanceof IconLogout) {
        return IconLogout_1.icon;
    };
    if (v instanceof IconLtDisabledV1) {
        return IconLtDisabledV1_1.icon;
    };
    if (v instanceof IconLtV1) {
        return IconLtV1_1.icon;
    };
    if (v instanceof IconMemoIndicatorV1) {
        return IconMemoIndicatorV1_1.icon;
    };
    if (v instanceof IconMenuV2) {
        return IconMenuV2_1.icon;
    };
    if (v instanceof IconMove) {
        return IconMove_1.icon;
    };
    if (v instanceof IconPolyline) {
        return IconPolyline_1.icon;
    };
    if (v instanceof IconRectangle) {
        return IconRectangle_1.icon;
    };
    if (v instanceof IconRotate90) {
        return IconRotate90_1.icon;
    };
    if (v instanceof IconSearch24Px) {
        return IconSearch24Px_1.icon;
    };
    if (v instanceof IconSortIndicatorActiveReversedV1) {
        return IconSortIndicatorActiveReversedV1_1.icon;
    };
    if (v instanceof IconSortIndicatorActiveV1) {
        return IconSortIndicatorActiveV1_1.icon;
    };
    if (v instanceof IconSortIndicatorInactiveV1) {
        return IconSortIndicatorInactiveV1_1.icon;
    };
    if (v instanceof IconSubtractV1) {
        return IconSubtractV1_1.icon;
    };
    if (v instanceof IconTocV1) {
        return IconTocV1_1.icon;
    };
    if (v instanceof IconTriangle) {
        return IconTriangle_1.icon;
    };
    if (v instanceof IconUnarchive) {
        return IconUnarchive_1.icon;
    };
    if (v instanceof IconCustom) {
        return v.value0;
    };
    throw new Error("Failed pattern match at TacoIcon.Icons.Types (line 131, column 1 - line 131, column 26): " + [ v.constructor.name ]);
};
module.exports = {
    IconAccountCircle24Px: IconAccountCircle24Px,
    IconAddPlusV1: IconAddPlusV1,
    IconArchive: IconArchive,
    IconArea: IconArea,
    IconArrow: IconArrow,
    IconArrowDown: IconArrowDown,
    IconArrowIndicator45Deg: IconArrowIndicator45Deg,
    IconArrowLeft: IconArrowLeft,
    IconArrowRight: IconArrowRight,
    IconArrowUp: IconArrowUp,
    IconBoxSelect24Px: IconBoxSelect24Px,
    IconCheck24Px: IconCheck24Px,
    IconCheckBox24Px: IconCheckBox24Px,
    IconCheckCircleBlueV1: IconCheckCircleBlueV1,
    IconCheckCircleV1: IconCheckCircleV1,
    IconCheckboxSelectedDisabledV1: IconCheckboxSelectedDisabledV1,
    IconCheckboxSelectedV1: IconCheckboxSelectedV1,
    IconChevronDownV1: IconChevronDownV1,
    IconChevronUpV1: IconChevronUpV1,
    IconClose24Px: IconClose24Px,
    IconCloseCrossV1: IconCloseCrossV1,
    IconCopyToProjectV1: IconCopyToProjectV1,
    IconDelete24Px: IconDelete24Px,
    IconDot: IconDot,
    IconDot2: IconDot2,
    IconEditInactiveV1: IconEditInactiveV1,
    IconEditV1: IconEditV1,
    IconFetch: IconFetch,
    IconFileDownloadV1: IconFileDownloadV1,
    IconFileUploadV1: IconFileUploadV1,
    IconFilterListV1: IconFilterListV1,
    IconFlagV1: IconFlagV1,
    IconGetApp24Px: IconGetApp24Px,
    IconGetAppV1: IconGetAppV1,
    IconGt24Px: IconGt24Px,
    IconGtDisabledV1: IconGtDisabledV1,
    IconGtLight24Px: IconGtLight24Px,
    IconGtV1: IconGtV1,
    IconGtWhite24Px: IconGtWhite24Px,
    IconInfoWhite: IconInfoWhite,
    IconInvoiceImageV1: IconInvoiceImageV1,
    IconLine: IconLine,
    IconLogout: IconLogout,
    IconLtDisabledV1: IconLtDisabledV1,
    IconLtV1: IconLtV1,
    IconMemoIndicatorV1: IconMemoIndicatorV1,
    IconMenuV2: IconMenuV2,
    IconMove: IconMove,
    IconPolyline: IconPolyline,
    IconRectangle: IconRectangle,
    IconRotate90: IconRotate90,
    IconSearch24Px: IconSearch24Px,
    IconSortIndicatorActiveReversedV1: IconSortIndicatorActiveReversedV1,
    IconSortIndicatorActiveV1: IconSortIndicatorActiveV1,
    IconSortIndicatorInactiveV1: IconSortIndicatorInactiveV1,
    IconSubtractV1: IconSubtractV1,
    IconTocV1: IconTocV1,
    IconTriangle: IconTriangle,
    IconUnarchive: IconUnarchive,
    IconCustom: IconCustom,
    iconJSX: iconJSX
};
