// Generated by purs version 0.14.5
"use strict";
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Kishimen = require("../Kishimen/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_Component = require("../Types.Component/index.js");
var ConvertToTopicComponentAction = (function () {
    function ConvertToTopicComponentAction(value0) {
        this.value0 = value0;
    };
    ConvertToTopicComponentAction.create = function (value0) {
        return new ConvertToTopicComponentAction(value0);
    };
    return ConvertToTopicComponentAction;
})();
var ConvertToRegularComponentAction = (function () {
    function ConvertToRegularComponentAction(value0) {
        this.value0 = value0;
    };
    ConvertToRegularComponentAction.create = function (value0) {
        return new ConvertToRegularComponentAction(value0);
    };
    return ConvertToRegularComponentAction;
})();
var CopyTemplateCostGroupsToProject = (function () {
    function CopyTemplateCostGroupsToProject(value0) {
        this.value0 = value0;
    };
    CopyTemplateCostGroupsToProject.create = function (value0) {
        return new CopyTemplateCostGroupsToProject(value0);
    };
    return CopyTemplateCostGroupsToProject;
})();
var AddPlanningCostGroup = (function () {
    function AddPlanningCostGroup(value0) {
        this.value0 = value0;
    };
    AddPlanningCostGroup.create = function (value0) {
        return new AddPlanningCostGroup(value0);
    };
    return AddPlanningCostGroup;
})();
var DeletePlanningCostGroup = (function () {
    function DeletePlanningCostGroup(value0) {
        this.value0 = value0;
    };
    DeletePlanningCostGroup.create = function (value0) {
        return new DeletePlanningCostGroup(value0);
    };
    return DeletePlanningCostGroup;
})();
var EditPlanningCostGroup = (function () {
    function EditPlanningCostGroup(value0) {
        this.value0 = value0;
    };
    EditPlanningCostGroup.create = function (value0) {
        return new EditPlanningCostGroup(value0);
    };
    return EditPlanningCostGroup;
})();
var PlanningSetComponentCostGroups = (function () {
    function PlanningSetComponentCostGroups(value0) {
        this.value0 = value0;
    };
    PlanningSetComponentCostGroups.create = function (value0) {
        return new PlanningSetComponentCostGroups(value0);
    };
    return PlanningSetComponentCostGroups;
})();
var genericPlanningAction = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new CopyTemplateCostGroupsToProject(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new AddPlanningCostGroup(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return new DeletePlanningCostGroup(x.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return new EditPlanningCostGroup(x.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inr))) {
            return new PlanningSetComponentCostGroups(x.value0.value0.value0.value0);
        };
        throw new Error("Failed pattern match at Types.Planning (line 81, column 1 - line 81, column 66): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof CopyTemplateCostGroupsToProject) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof AddPlanningCostGroup) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof DeletePlanningCostGroup) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
        };
        if (x instanceof EditPlanningCostGroup) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))));
        };
        if (x instanceof PlanningSetComponentCostGroups) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0))));
        };
        throw new Error("Failed pattern match at Types.Planning (line 81, column 1 - line 81, column 66): " + [ x.constructor.name ]);
    }
};
var tofuJSONPlanningAction = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddPlanningCostGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newCostGroupCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newDescription";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newCostGroupCode";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newDescription";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CopyTemplateCostGroupsToProject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "templateCostGroupClassificationId";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "templateCostGroupClassificationId";
        }
    })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DeletePlanningCostGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costGroupId";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costGroupId";
        }
    })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditPlanningCostGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costGroupId";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newCostGroupCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newDescription";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costGroupId";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newCostGroupCode";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newDescription";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "PlanningSetComponentCostGroups";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())))()(TofuJSON.readVariantNil))))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddPlanningCostGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newCostGroupCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newDescription";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newCostGroupCode";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newDescription";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CopyTemplateCostGroupsToProject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "templateCostGroupClassificationId";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "templateCostGroupClassificationId";
        }
    })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DeletePlanningCostGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costGroupId";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costGroupId";
        }
    })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditPlanningCostGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costGroupId";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newCostGroupCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newDescription";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costGroupId";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newCostGroupCode";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newDescription";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "PlanningSetComponentCostGroups";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())))()(TofuJSON.nilWriteForeignVariant))))))(genericPlanningAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CopyTemplateCostGroupsToProject";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddPlanningCostGroup";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DeletePlanningCostGroup";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditPlanningCostGroup";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "PlanningSetComponentCostGroups";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditPlanningCostGroup";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "PlanningSetComponentCostGroups";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DeletePlanningCostGroup";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "PlanningSetComponentCostGroups";
        }
    }))({
        reflectSymbol: function () {
            return "EditPlanningCostGroup";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddPlanningCostGroup";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "PlanningSetComponentCostGroups";
        }
    }))({
        reflectSymbol: function () {
            return "EditPlanningCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "DeletePlanningCostGroup";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopyTemplateCostGroupsToProject";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "PlanningSetComponentCostGroups";
        }
    }))({
        reflectSymbol: function () {
            return "EditPlanningCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "DeletePlanningCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "AddPlanningCostGroup";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericPlanningAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CopyTemplateCostGroupsToProject";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddPlanningCostGroup";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DeletePlanningCostGroup";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditPlanningCostGroup";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "PlanningSetComponentCostGroups";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditPlanningCostGroup";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "PlanningSetComponentCostGroups";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DeletePlanningCostGroup";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "PlanningSetComponentCostGroups";
        }
    }))({
        reflectSymbol: function () {
            return "EditPlanningCostGroup";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddPlanningCostGroup";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "PlanningSetComponentCostGroups";
        }
    }))({
        reflectSymbol: function () {
            return "EditPlanningCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "DeletePlanningCostGroup";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CopyTemplateCostGroupsToProject";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "PlanningSetComponentCostGroups";
        }
    }))({
        reflectSymbol: function () {
            return "EditPlanningCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "DeletePlanningCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "AddPlanningCostGroup";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddPlanningCostGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newCostGroupCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newDescription";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newCostGroupCode";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newDescription";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CopyTemplateCostGroupsToProject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "templateCostGroupClassificationId";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "templateCostGroupClassificationId";
        }
    })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DeletePlanningCostGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costGroupId";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costGroupId";
        }
    })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditPlanningCostGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costGroupId";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newCostGroupCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newDescription";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costGroupId";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newCostGroupCode";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newDescription";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "PlanningSetComponentCostGroups";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())))()(TofuJSON.readVariantNil))))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddPlanningCostGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newCostGroupCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newDescription";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newCostGroupCode";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newDescription";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CopyTemplateCostGroupsToProject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "templateCostGroupClassificationId";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "templateCostGroupClassificationId";
        }
    })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DeletePlanningCostGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costGroupId";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costGroupId";
        }
    })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditPlanningCostGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costGroupId";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newCostGroupCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newDescription";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costGroupId";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newCostGroupCode";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newDescription";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "PlanningSetComponentCostGroups";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())))()(TofuJSON.nilWriteForeignVariant))))))
};
module.exports = {
    ConvertToTopicComponentAction: ConvertToTopicComponentAction,
    ConvertToRegularComponentAction: ConvertToRegularComponentAction,
    CopyTemplateCostGroupsToProject: CopyTemplateCostGroupsToProject,
    AddPlanningCostGroup: AddPlanningCostGroup,
    DeletePlanningCostGroup: DeletePlanningCostGroup,
    EditPlanningCostGroup: EditPlanningCostGroup,
    PlanningSetComponentCostGroups: PlanningSetComponentCostGroups,
    genericPlanningAction: genericPlanningAction,
    tofuJSONPlanningAction: tofuJSONPlanningAction
};
