// Generated by purs version 0.14.5
"use strict";
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var UsePortal = require("../UsePortal/index.js");
var component = TofuHooks.mkHookComponent("Portal")(function (v) {
    return function __do() {
        var target = UsePortal.usePortal(v.id)();
        return React_Basic_DOM.createPortal(v.contents)(target);
    };
});
module.exports = {
    component: component
};
