// Generated by purs version 0.14.5
"use strict";
var Data_Maybe = require("../Data.Maybe/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoModal = require("../TacoModal/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var modalDemo = TofuHooks.mkHookComponent("ModalDemo")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState(false)();
        var openModal = v1.value1(function (v2) {
            return true;
        });
        var closeModal = v1.value1(function (v2) {
            return false;
        });
        var modalActions = [ TacoButton.component()()({
            onClick: Data_Maybe.Just.create(closeModal),
            text: "Cancel"
        }), TacoButton.component()()({
            onClick: Data_Maybe.Just.create(closeModal),
            buttonStyle: TacoButton_Types.Filled.value,
            color: TacoConstants.SecondaryRed.value,
            text: "Confirm"
        }) ];
        return React_Basic_DOM_Generated.div_([ TacoModal.component()()({
            onClose: closeModal,
            isActive: v1.value0,
            heading: "Modal heading",
            contents: [ TacoModal.modalBody({
                contents: [ React_Basic_DOM_Generated.div_([ React_Basic_DOM.text("Modal contents") ]), React_Basic_DOM_Generated.div_([ React_Basic_DOM.text("Section 2") ]) ]
            }), TacoModal.modalActions({
                contents: modalActions
            }) ]
        }), TacoButton.component()()({
            onClick: Data_Maybe.Just.create(openModal),
            text: "Open modal"
        }) ]);
    };
});
var example1 = "import TacoModal as TacoModal\x0aimport TacoButton as TacoButton\x0aimport Data.Tuple (Tuple(..))\x0aimport React.Basic.DOM as R\x0aimport React.Basic.Hooks as RH\x0a\x0aTuple modalOpen setModalOpen <- useState false\x0a\x0alet\x0a  closeModal = setModalOpen \\_ -> false\x0a  openModal = setModalOpen \\_ -> true\x0a\x0a  modalActions =\x0a    [ TacoButton.component\x0a        { onClick: Just $ closeModal\x0a        , text: \"Cancel\"\x0a        , testId: \"cancel-modal\"\x0a        }\x0a    , TacoButton.component\x0a        { onClick: Just $ closeModal\x0a        , buttonStyle: TacoButton.Filled\x0a        , color: SecondaryRed\x0a        , text: \"Confirm\"\x0a        , testId: \"confirm-modal\"\x0a        }\x0a    ]\x0a\x0apure $ R.div_\x0a  [ TacoModal.component\x0a      { onClose: closeModal\x0a      , isActive: modalOpen\x0a      , heading: \"Modal heading\"\x0a      , children:\x0a          [ R.div_ [ R.text \"Modal contents\" ]\x0a          , R.div_ [ R.text \"Section 2\" ]\x0a          ]\x0a      , actions: modalActions\x0a      }\x0a  , TacoButton.component\x0a      { onClick: Just $ openModal\x0a      , text: \"Open modal\"\x0a      }\x0a  ]";
var sections = [ {
    title: "Default",
    view: [ modalDemo({}) ],
    code: example1
} ];
var demoView = {
    sections: sections,
    title: "TacoModal",
    description: "Dialog used to capture user attention by blocking rest of the view"
};
module.exports = {
    demoView: demoView
};
