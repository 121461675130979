import { RadioGroup } from "@tocoman/ui";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { useRunOnce } from "../../../hooks/useRunOnce";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useModalActionButtons } from "./hooks/useModalActionButtons";
import {
  useToggleComponentFlags,
  useToggleComponentChecks,
} from "./hooks/useToggleComponentProperties";

type AddComponentPropertiesProps = {
  projectId: number;
  componentIds: number[];
  closeModal: () => void;
  onSave: () => void;
  setActionButtons: (actions: JSX.Element) => void;
};
export function AddComponentProperties({
  projectId,
  componentIds,
  closeModal,
  onSave,
  setActionButtons,
}: AddComponentPropertiesProps) {
  const { t } = useTranslation("estimation", {
    keyPrefix: "componentView.editMultipleComponentsModal",
  });

  const methods = useForm<{ selectedAction: string }>({
    defaultValues: { selectedAction: "checks" },
  });
  const { handleSubmit } = methods;

  const radioItems = [
    {
      value: "checks",
      label: t`add.marks`,
    },
    {
      value: "flags",
      label: t`add.flags`,
    },
  ];

  const { mutate: addFlags } = useToggleComponentFlags(projectId, onSave);

  const { mutate: addMarks } = useToggleComponentChecks(projectId, onSave);

  const handleSave = useCallback(
    // eslint-disable-next-line
    (form: any) => {
      const { selectedAction } = form;
      if (selectedAction === "checks") {
        addMarks({
          components: componentIds,
          checked: true,
        });
      }
      if (selectedAction === "flags") {
        addFlags({
          components: componentIds,
          flagged: true,
        });
      }
    },
    [addFlags, addMarks, componentIds]
  );

  const setActions = useModalActionButtons(
    setActionButtons,
    handleSubmit(handleSave),
    closeModal
  );

  useRunOnce(() => {
    setActions(false);
  });

  return (
    <div>
      <p>{t`add.info`}</p>
      <FormProvider {...methods}>
        <Controller
          name={"selectedAction"}
          render={({ field: { onChange, value } }) => (
            <RadioGroup
              selected={
                radioItems.find((item) => item.value === value) ?? radioItems[0]
              }
              onValueChange={(value) => onChange(value.value)}
              items={radioItems}
              horizontal={true}
            />
          )}
        />
      </FormProvider>
    </div>
  );
}
