// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var dixonFileListContainerStyles = Box.boxStyle([ Box.FlexColumn.value, new Box.FlexGrow(1), new Box.Overflow(Box.Auto.value, Box.Auto.value), Box.PaddingBottom.create(new TacoConstants.CustomSize("5rem")) ]);
var dixonFileFolderHeaderStyles = Box.boxStyle([ Box.FlexRow.value, new Box.Gap(TacoConstants.M.value), Box.Padding.create(new TacoConstants.CustomSize("0.5rem")) ]);
module.exports = {
    dixonFileListContainerStyles: dixonFileListContainerStyles,
    dixonFileFolderHeaderStyles: dixonFileFolderHeaderStyles
};
