// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var AddProjectBtn = require("../AddProjectBtn/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Controls = require("../Controls/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Effect = require("../Effect/index.js");
var FoldingTable_Filter = require("../FoldingTable.Filter/index.js");
var Kishimen = require("../Kishimen/index.js");
var Locale = require("../Locale/index.js");
var NarrowLayout = require("../NarrowLayout/index.js");
var NoProjectsFoundMsg = require("../NoProjectsFoundMsg/index.js");
var ProjectList_Styles = require("../ProjectList.Styles/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoLoader = require("../TacoLoader/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_FrontEndRoutes = require("../Types.FrontEndRoutes/index.js");
var Types_Project = require("../Types.Project/index.js");
var UseFetch = require("../UseFetch/index.js");
var UseLocalStorage = require("../UseLocalStorage/index.js");
var ByCode = (function () {
    function ByCode() {

    };
    ByCode.value = new ByCode();
    return ByCode;
})();
var ByName = (function () {
    function ByName() {

    };
    ByName.value = new ByName();
    return ByName;
})();
var ByVersion = (function () {
    function ByVersion() {

    };
    ByVersion.value = new ByVersion();
    return ByVersion;
})();
var ByCreatedAt = (function () {
    function ByCreatedAt() {

    };
    ByCreatedAt.value = new ByCreatedAt();
    return ByCreatedAt;
})();
var ByState = (function () {
    function ByState() {

    };
    ByState.value = new ByState();
    return ByState;
})();
var ByProjectGroup = (function () {
    function ByProjectGroup() {

    };
    ByProjectGroup.value = new ByProjectGroup();
    return ByProjectGroup;
})();
var styleSet = TacoTable_Styles.foldingTableStyleSet;
var projectListTableColumns = [ {
    key: "code",
    label: React_Basic_DOM.text(Locale.lookup_("project_code")),
    cell: TacoTable_Types.PlainTextCell.create((function () {
        var $72 = Data_Newtype.un()(Types_Project.ProjectCode);
        return function ($73) {
            return $72((function (v) {
                return v.code;
            })($73));
        };
    })()),
    sortProperty: new Data_Maybe.Just(ByCode.value),
    width: new TacoTable_Types.Flex(15),
    headerJSX: Data_Maybe.Nothing.value
}, {
    key: "name",
    label: React_Basic_DOM.text(Locale.lookup_("project_name")),
    cell: new TacoTable_Types.PlainTextCell(function (v) {
        return v.name;
    }),
    sortProperty: new Data_Maybe.Just(ByName.value),
    width: new TacoTable_Types.Flex(35),
    headerJSX: Data_Maybe.Nothing.value
}, {
    key: "version",
    label: React_Basic_DOM.text(Locale.lookup_("project_version")),
    cell: new TacoTable_Types.PlainTextCell(function (v) {
        return v.version;
    }),
    sortProperty: new Data_Maybe.Just(ByVersion.value),
    width: new TacoTable_Types.Flex(10),
    headerJSX: Data_Maybe.Nothing.value
}, {
    key: "createdDate",
    label: React_Basic_DOM.text(Locale.lookup_("common_created_date")),
    cell: TacoTable_Types.PlainDateStringCell.create((function () {
        var $74 = Data_Maybe.fromMaybe("");
        return function ($75) {
            return $74(Data_Nullable.toMaybe((function (v) {
                return v.createdDate;
            })($75)));
        };
    })()),
    sortProperty: new Data_Maybe.Just(ByCreatedAt.value),
    width: new TacoTable_Types.Flex(10),
    headerJSX: Data_Maybe.Nothing.value
}, {
    key: "state",
    label: React_Basic_DOM.text(Locale.lookup_("common_state")),
    cell: new TacoTable_Types.PlainTextCell(function (v) {
        return v.state;
    }),
    sortProperty: new Data_Maybe.Just(ByState.value),
    width: new TacoTable_Types.Flex(15),
    headerJSX: Data_Maybe.Nothing.value
}, {
    key: "projectGroup",
    label: React_Basic_DOM.text(Locale.lookup_("project_group")),
    cell: TacoTable_Types.PlainTextCell.create((function () {
        var $76 = Data_Maybe.fromMaybe("");
        return function ($77) {
            return $76(Data_Nullable.toMaybe((function (v) {
                return v.projectGroup;
            })($77)));
        };
    })()),
    sortProperty: new Data_Maybe.Just(ByProjectGroup.value),
    width: new TacoTable_Types.Flex(15),
    headerJSX: Data_Maybe.Nothing.value
} ];
var projectSummaryToRow = function (dispatch) {
    return function (summary) {
        return {
            rowData: summary,
            rowKey: Data_Show.show(Types_Project.showProjectId)(summary.id),
            className: "",
            onClick: function (v) {
                return dispatch(new Actions.NavigateRoute(new Types_FrontEndRoutes.CostControlFERoute({
                    projectId: v.id
                })));
            },
            foldingElement: Data_Maybe.Nothing.value,
            columns: projectListTableColumns
        };
    };
};
var getProjectSortingValue = function (v) {
    return function (v1) {
        if (v instanceof ByCode) {
            return new TacoTable_Types.StringValue(v1.code);
        };
        if (v instanceof ByName) {
            return new TacoTable_Types.StringValue(v1.name);
        };
        if (v instanceof ByVersion) {
            return new TacoTable_Types.StringValue(v1.version);
        };
        if (v instanceof ByCreatedAt) {
            return TacoTable_Types.StringValue.create(Data_Maybe.fromMaybe("")(Data_Nullable.toMaybe(v1.createdDate)));
        };
        if (v instanceof ByState) {
            return new TacoTable_Types.StringValue(v1.state);
        };
        if (v instanceof ByProjectGroup) {
            return TacoTable_Types.StringValue.create(Data_Maybe.fromMaybe("")(Data_Nullable.toMaybe(v1.projectGroup)));
        };
        throw new Error("Failed pattern match at ProjectListContainer (line 205, column 1 - line 205, column 87): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var getNameProjectSortProperty = {
    getName: function (v) {
        return "ProjectSortProperty";
    }
};
var projectTableInstance = TacoTable.mkTableStateless(getNameProjectSortProperty)("Projects");
var genericProjectSortProperty = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return ByCode.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return ByName.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return ByVersion.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return ByCreatedAt.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
            return ByState.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr)))) {
            return ByProjectGroup.value;
        };
        throw new Error("Failed pattern match at ProjectListContainer (line 223, column 1 - line 223, column 76): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof ByCode) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof ByName) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
        };
        if (x instanceof ByVersion) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
        };
        if (x instanceof ByCreatedAt) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))));
        };
        if (x instanceof ByState) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))));
        };
        if (x instanceof ByProjectGroup) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)))));
        };
        throw new Error("Failed pattern match at ProjectListContainer (line 223, column 1 - line 223, column 76): " + [ x.constructor.name ]);
    }
};
var tofuJSONProjectSortProperty = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ByCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ByCreatedAt";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ByName";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ByProjectGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ByState";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ByVersion";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ByCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ByCreatedAt";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ByName";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ByProjectGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ByState";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ByVersion";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))))))(genericProjectSortProperty)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ByCode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ByName";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ByVersion";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ByCreatedAt";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ByState";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ByProjectGroup";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ByState";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ByProjectGroup";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ByCreatedAt";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ByState";
        }
    }))({
        reflectSymbol: function () {
            return "ByProjectGroup";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ByVersion";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ByState";
        }
    }))({
        reflectSymbol: function () {
            return "ByProjectGroup";
        }
    }))({
        reflectSymbol: function () {
            return "ByCreatedAt";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ByName";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ByVersion";
        }
    }))({
        reflectSymbol: function () {
            return "ByState";
        }
    }))({
        reflectSymbol: function () {
            return "ByProjectGroup";
        }
    }))({
        reflectSymbol: function () {
            return "ByCreatedAt";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ByCode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ByVersion";
        }
    }))({
        reflectSymbol: function () {
            return "ByState";
        }
    }))({
        reflectSymbol: function () {
            return "ByProjectGroup";
        }
    }))({
        reflectSymbol: function () {
            return "ByName";
        }
    }))({
        reflectSymbol: function () {
            return "ByCreatedAt";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericProjectSortProperty)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ByCode";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ByName";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ByVersion";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ByCreatedAt";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ByState";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ByProjectGroup";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ByState";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ByProjectGroup";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ByCreatedAt";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ByState";
        }
    }))({
        reflectSymbol: function () {
            return "ByProjectGroup";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ByVersion";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ByState";
        }
    }))({
        reflectSymbol: function () {
            return "ByProjectGroup";
        }
    }))({
        reflectSymbol: function () {
            return "ByCreatedAt";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ByName";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ByVersion";
        }
    }))({
        reflectSymbol: function () {
            return "ByState";
        }
    }))({
        reflectSymbol: function () {
            return "ByProjectGroup";
        }
    }))({
        reflectSymbol: function () {
            return "ByCreatedAt";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ByCode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ByVersion";
        }
    }))({
        reflectSymbol: function () {
            return "ByState";
        }
    }))({
        reflectSymbol: function () {
            return "ByProjectGroup";
        }
    }))({
        reflectSymbol: function () {
            return "ByName";
        }
    }))({
        reflectSymbol: function () {
            return "ByCreatedAt";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ByCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ByCreatedAt";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ByName";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ByProjectGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ByState";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ByVersion";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ByCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ByCreatedAt";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ByName";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ByProjectGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ByState";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ByVersion";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))))))
};
var eqProjectSortProperty = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ByCode && y instanceof ByCode) {
                return true;
            };
            if (x instanceof ByName && y instanceof ByName) {
                return true;
            };
            if (x instanceof ByVersion && y instanceof ByVersion) {
                return true;
            };
            if (x instanceof ByCreatedAt && y instanceof ByCreatedAt) {
                return true;
            };
            if (x instanceof ByState && y instanceof ByState) {
                return true;
            };
            if (x instanceof ByProjectGroup && y instanceof ByProjectGroup) {
                return true;
            };
            return false;
        };
    }
};
var ordProjectSortProperty = {
    compare: function (x) {
        return function (y) {
            if (x instanceof ByCode && y instanceof ByCode) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByCode) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByCode) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByName && y instanceof ByName) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByName) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByName) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByVersion && y instanceof ByVersion) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByVersion) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByVersion) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByCreatedAt && y instanceof ByCreatedAt) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByCreatedAt) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByCreatedAt) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByState && y instanceof ByState) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByState) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByState) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByProjectGroup && y instanceof ByProjectGroup) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at ProjectListContainer (line 222, column 1 - line 222, column 66): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqProjectSortProperty;
    }
};
var component = TofuHooks.mkHookComponent("ProjectListContainer")(function (v) {
    return function __do() {
        var appDispatch = StateHooks.useDispatch();
        var v1 = UseLocalStorage.useLocalStorage("project_list_container_filter_string")("")(false)();
        var v2 = UseFetch.useFetchItems(Data_Eq.eqUnit)(Data_Unit.unit)(function (v2) {
            return v2.projectList;
        })(Actions.CostControlProjectsRequest.value)();
        var v3 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "order";
            }
        })(TacoTable_Types.tofuJSONSortingOrder)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "property";
            }
        })(tofuJSONProjectSortProperty)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "order";
            }
        })(TacoTable_Types.tofuJSONSortingOrder)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "property";
            }
        })(tofuJSONProjectSortProperty)(TofuJSON.nilWriteForeignFields)()()())()()()))("project_list_sort_state")({
            property: ByCode.value,
            order: TacoTable_Types.Descending.value
        })(false)();
        var sortSummaries = TacoTable_Types.sortItemsByComparable(getProjectSortingValue)(v3.value0.order)(v3.value0.property);
        var setSortingCriteria = function (property) {
            return v3.value1(function (s) {
                var $63 = Data_Eq.eq(eqProjectSortProperty)(s.property)(property);
                if ($63) {
                    return {
                        property: property,
                        order: TacoTable_Types.reverse(s.order)
                    };
                };
                return {
                    property: property,
                    order: s.order
                };
            });
        };
        var projectTable = function (summaries) {
            return TacoTable.tableStateless(getNameProjectSortProperty)(eqProjectSortProperty)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "version";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "subprojects";
                }
            })(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "projectId";
                }
            })(Types_Project.eqProjectId))()({
                reflectSymbol: function () {
                    return "name";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Project.eqSubprojectId))()({
                reflectSymbol: function () {
                    return "countInMeasurementTotal";
                }
            })(Data_Eq.eqBoolean))()({
                reflectSymbol: function () {
                    return "code";
                }
            })(Data_Eq.eqString)))))()({
                reflectSymbol: function () {
                    return "state";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "projectGroup";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "programType";
                }
            })(Data_Eq.eqInt))()({
                reflectSymbol: function () {
                    return "name";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "isReportingProject";
                }
            })(Data_Eq.eqBoolean))()({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Project.eqProjectId))()({
                reflectSymbol: function () {
                    return "currency";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "createdDate";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "code";
                }
            })(Types_Project.eqProjectCode))()({
                reflectSymbol: function () {
                    return "applicationMode";
                }
            })(Data_Eq.eqString)))(projectTableInstance)({
                sortProperty: new Data_Maybe.Just(v3.value0.property),
                onSort: setSortingCriteria,
                sortingOrder: v3.value0.order,
                rows: Data_Functor.map(Data_Functor.functorArray)(projectSummaryToRow(appDispatch))(sortSummaries(summaries)),
                columns: projectListTableColumns,
                showHeader: true,
                styleSet: styleSet,
                onResize: function (v4) {
                    return function (v5) {
                        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                    };
                },
                columnSizeOffsets: Data_Maybe.Nothing.value
            });
        };
        var projectRightsMessage = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "project-rights-info",
            css: ProjectList_Styles.projectRightsStyles,
            children: [ TacoText.component()()({
                text: Locale.lookup_("project_rights_info"),
                color: TacoConstants.Gray.value,
                align: TacoText_Types.Center.value,
                variant: new TacoText_Types.Heading(5)
            }) ]
        });
        var filteredProjects = FoldingTable_Filter.filterProjectList(v1.value0)(v2.value0);
        var projectList = (function () {
            var $64 = !v2.value1 && Data_Array.length(v2.value0) === 0;
            if ($64) {
                return NoProjectsFoundMsg.noProjectsFoundMsg(appDispatch);
            };
            if (v2.value1) {
                return TacoLoader.component()()({
                    size: "4rem"
                });
            };
            return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                className: "projects-table",
                css: ProjectList_Styles.projectsTableStyles,
                children: [ projectTable(filteredProjects), projectRightsMessage ]
            });
        })();
        var filterInput = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "projects-filter",
            css: ProjectList_Styles.filterStyles,
            children: Control_Applicative.pure(Control_Applicative.applicativeArray)(TacoInput.stringInput()()(Data_Eq.eqString)({
                label: "Hae",
                value: v1.value0,
                onChange: v1.value1,
                clearButton: true
            }))
        });
        var addProjectBtn = AddProjectBtn.component({
            mode: AddProjectBtn.AddProjectBtnCostControl.value
        });
        var controls = Controls.component()()({
            leftControls: [ filterInput ],
            rightControls: [ addProjectBtn ],
            bottomGutter: true
        });
        return NarrowLayout.component()()({
            verticalPadding: true,
            children: [ React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                className: "__ProjectList",
                css: ProjectList_Styles.projectListStyles,
                children: [ controls, projectList ]
            }) ]
        });
    };
});
module.exports = {
    component: component
};
