// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var TacoButton_Styles = require("../TacoButton.Styles/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var groupToggleStyles = function (props) {
    var rightBorder = Box.Style.create(React_Basic_Emotion.css()({
        borderRadius: React_Basic_Emotion.str("0 4px 4px 0")
    }));
    var leftBorder = Box.Style.create(React_Basic_Emotion.css()({
        borderRadius: React_Basic_Emotion.str("4px 0 0 4px")
    }));
    var color = (function () {
        if (props.isActive) {
            return TacoConstants.PrimaryBlue.value;
        };
        if (!props.isActive) {
            return TacoConstants.Gray.value;
        };
        throw new Error("Failed pattern match at TacoGroupToggle.Styles (line 27, column 13 - line 29, column 20): " + [ props.isActive.constructor.name ]);
    })();
    var buttonStyleProps = {
        align: TacoButton_Types.Center.value,
        buttonStyle: TacoButton_Types.Outlined.value,
        children: Data_Maybe.Nothing.value,
        color: color,
        disabled: false,
        text: "",
        size: TacoConstants.M.value,
        vertical: false,
        styles: [  ]
    };
    return Box.boxStyle([ new Box.Padding(TacoConstants.XS.value), Box.MinWidth.create(new TacoConstants.CustomSize("8rem")), Box.MarginRight.create(new TacoConstants.CustomSize("-1px")), Box.Style.create(TacoButton_Styles.buttonStyles(buttonStyleProps)), Box.Style.create(React_Basic_Emotion.css()({
        borderRadius: React_Basic_Emotion.str("0")
    })), new Box.Selector("&:first-of-type", [ leftBorder ]), new Box.Selector("&:last-child", [ rightBorder ]) ]);
};
var groupToggleContainerStyles = Box.boxStyle([ Box.FlexRow.value ]);
module.exports = {
    groupToggleContainerStyles: groupToggleContainerStyles,
    groupToggleStyles: groupToggleStyles
};
