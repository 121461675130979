import { FullProjectData } from "../Report";
import { Rdl as ARJS } from "@mescius/activereportsjs/core";
import { mapC200Data } from "../../../SuperAdmin/ReportDesigner/C200/C200Data";
import {
  componentMap,
  ComponentPropsMap,
} from "../../../SuperAdmin/ReportDesigner/AllReportParameters";

export const c200WithProjectData = (
  projectData: FullProjectData,
  reportTemplate: ARJS.Report
): {
  parameters: (keyof ComponentPropsMap)[] | undefined;
  report: ARJS.Report | undefined;
} => {
  if (!projectData || !reportTemplate) {
    return { report: undefined, parameters: undefined };
  }

  const allDataSources = [
    {
      name: "Components",
      result: mapC200Data(projectData),
    },
    {
      name: "ProjectDetails",
      result: projectData?.projectData ?? [],
    },
    {
      name: "SubProjects",
      result: projectData?.subProjects ?? [],
    },
    {
      name: "Locations",
      result: projectData?.locations ?? [],
    },
    {
      name: "CostClasses",
      result: projectData?.costClasses ?? [],
    },
    {
      name: "Elements",
      result: projectData?.elements ?? [],
    },
    {
      name: "Measurements",
      result: projectData?.measurements ?? [],
    },
  ];

  const reportParameters = reportTemplate.ReportParameters;
  const reportDataSets = reportTemplate.DataSets;

  const reportDataSources = allDataSources
    .filter((ds) => reportDataSets?.some((rds) => rds.Name === ds.name))
    .map(({ name, result }) => ({
      Name: `${name}JSON`,
      ConnectionProperties: {
        DataProvider: "JSONEMBED",
        ConnectString: "jsondata=" + JSON.stringify(result),
      },
    }));

  const parameters = reportParameters
    ?.map((param) => {
      if (param.Name in componentMap) {
        return param.Name as keyof ComponentPropsMap;
      }
      return undefined;
    })
    .filter((name): name is keyof ComponentPropsMap => name !== undefined);

  const currencyParameter = {
    AllowBlank: true,
    DataType: "String",
    Name: "currency",
    Hidden: true,
    DefaultValue: { Values: [projectData?.projectData?.currency ?? "EUR"] },
  };

  const reportParametersWithCurrency =
    reportParameters?.concat(currencyParameter as ARJS.ReportParameter) ?? [];

  return {
    report: {
      ...(reportTemplate as ARJS.Report),
      DataSources: reportDataSources,
      ReportParameters: reportParametersWithCurrency,
    },
    parameters,
  };
};
