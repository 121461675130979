// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var resourcesTableStyles = Box.boxStyle([ new Box.FlexGrow(1), Box.FlexColumn.value, new Box.BackgroundColor(TacoConstants.GrayLightest.value) ]);
var componentsTableStyles = Box.boxStyle([ new Box.FlexGrow(1), Box.FlexColumn.value ]);
module.exports = {
    componentsTableStyles: componentsTableStyles,
    resourcesTableStyles: resourcesTableStyles
};
