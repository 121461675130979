import {
  Designer,
  ParameterVariant,
  Viewer,
} from "@mescius/activereportsjs-react";
import "@mescius/activereportsjs/styles/ar-js-designer.css";
import "@mescius/activereportsjs/styles/ar-js-ui.css";
import { FormProvider, useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { AllReportParametersData, ParamsForARJS } from "./AllReportParameters";
import { DesignerParameterForm } from "./DesignerParameterForm";
import { useParams } from "react-router-dom";
import * as C200Template from "./C200/C200Template.json";
import { c200Data } from "./C200/C200Data";
import { c200Report } from "./C200/C200Report";
import { Rdl as ARJS } from "@mescius/activereportsjs/core";
import { Core } from "@mescius/activereportsjs";
import { reportCustomCodes } from "./CustomFunctions";
import { removeDataSources } from "./removeDataSources";
import { Button, IconPencil, IconPlay } from "@tocoman/ui";

Core.CustomCode.registerFunctions(reportCustomCodes);

export type ReportDesignerForm = {
  projectId?: number;
  dataSources?: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parameters?: { key: string; label: string; value: any }[];
} & AllReportParametersData;

export const ReportDesigner = () => {
  const { reportName, projectId } = useParams();
  // const saveReportTemplate = useUpdateReportTemplate();

  const methods = useForm<ReportDesignerForm>();

  const projectData = projectId ? c200Data(Number(projectId)) : null;

  const viewerRef = useRef<Viewer>(null);

  const [editMode, setEditMode] = useState(true);
  const [C200Report, setC200Report] = useState<ARJS.Report | undefined>();
  const [dataSource, setDataSource] = useState<string[]>([]);
  const [parametersData, setParametersData] = useState<ParamsForARJS[]>([]);
  const [templateEdited, setTemplateEdited] = useState(false);

  const saveTemplate = async (i: {
    id?: string;
    displayName?: string;
    definition?: ARJS.Report;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }): Promise<any> => {
    const newTemplate = removeDataSources(i.definition);
    localStorage.setItem("C200Template", JSON.stringify(newTemplate));

    return Promise.resolve({
      id: reportName,
      displayName: i.displayName ?? reportName,
    });
  };

  const loadTemplate = () => {
    const storedTemplate = localStorage.getItem("C200Template");
    if (storedTemplate) {
      setC200Report(JSON.parse(storedTemplate) as ARJS.Report);
    } else {
      setC200Report(C200Template as ARJS.Report);
    }
  };

  useEffect(() => {
    loadTemplate();
  }, []);

  useEffect(() => {
    if (projectData && parametersData && dataSource && templateEdited) {
      const newReport = c200Report(projectData, parametersData, dataSource);
      setC200Report(newReport);
    }
  }, [projectData, parametersData, dataSource, templateEdited]);

  const renderReport = (report: ARJS.Report) => {
    if (viewerRef.current && parametersData) {
      viewerRef.current.Viewer.open(report, {
        ReportParams: (parametersData as unknown) as {
          Name: string;
          Value: ParameterVariant[];
        }[],
      });
    }
  };

  useEffect(() => {
    if (C200Report) {
      renderReport(C200Report);
    }
  }, [renderReport, C200Report]);

  return (
    <div className="w-full h-full flex pl-5">
      <div className="flex flex-row items-start gap-5 h-full">
        <FormProvider {...methods}>
          <DesignerParameterForm
            projectData={projectData}
            reportName={reportName}
            savedReportTemplate={C200Report}
            setParametersData={setParametersData}
            setDataSource={setDataSource}
            setTemplateEdited={setTemplateEdited}
          />
        </FormProvider>
      </div>

      <div className="w-full h-full ml-[-10px] flex flex-col">
        {C200Report && editMode && (
          <div className="w-full h-full flex flex-col">
            <Designer
              report={{
                id: reportName,
                definition: C200Report,
                displayName: reportName,
              }}
              onSave={(info) => saveTemplate(info)}
              onSaveAs={(info) => saveTemplate(info)}
            />
          </div>
        )}
        {C200Report && !editMode && (
          <div className="w-full h-full flex flex-col">
            <Viewer theme="ActiveReports" ref={viewerRef} />
          </div>
        )}

        <div className="flex flex-row z-100 gap-5 ml-[60px] mt-[-60px]">
          {editMode ? (
            <Button
              onClick={() => setEditMode(false)}
              icon={IconPlay}
              className="rounded-full text-xl w-[40px] h-[40px]"
            />
          ) : (
            <Button
              onClick={() => setEditMode(true)}
              icon={IconPencil}
              className="rounded-full text-xl w-[40px] h-[40px]"
            />
          )}
        </div>
      </div>

      {/*
  <Designer
    report={{
      // The API is a bit confusing, but the id field is actually a path. Alternatively use definition field.
      id: `/api/super-admin/report-templates/${reportName}/template`,
      displayName: "C200",
    }}
    onSave={async (info) => {
      const name = info.displayName ?? reportName;

      await saveReportTemplate.mutateAsync({
        name,
        template: info.definition,
      });

      return {
        displayName: name,
      };
    }}
  />
  */}
    </div>
  );
};
