// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Types_CostEstimation = require("../Types.CostEstimation/index.js");
var Types_Estimation = require("../Types.Estimation/index.js");
var selected = function (v) {
    return v.checkboxState;
};
var selectedEstimationComponentsWithResources = function (components) {
    return Data_Array.filter(selected)(components);
};
var selectedECWRComponentIds = (function () {
    var $10 = Data_Functor.map(Data_Functor.functorArray)((function () {
        var $12 = Data_Newtype.un()(Types_CostEstimation.EstimationComponentWithResources);
        return function ($13) {
            return (function (v) {
                return v.component.id;
            })($12($13));
        };
    })());
    return function ($11) {
        return $10(selectedEstimationComponentsWithResources($11));
    };
})();
var mkCopyComponentsAction = function (v) {
    var params = Types_Estimation.CopyEstimationComponentsToProject.create({
        estimationComponentIds: v.componentIds,
        targetSubprojectId: v.targetSubprojectId,
        targetProjectId: v.targetProjectId,
        copyOperationType: v.copyOperationType,
        allowDuplicates: true
    });
    return new Actions.EstimationComponentActionRequest({
        projectId: v.targetProjectId,
        params: params,
        handler: v.resultHandler
    });
};
module.exports = {
    mkCopyComponentsAction: mkCopyComponentsAction,
    selectedECWRComponentIds: selectedECWRComponentIds
};
