// Generated by purs version 0.14.5
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Show = require("../Data.Show/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var SortProperty = function (x) {
    return x;
};
var AppVersion = function (x) {
    return x;
};
var tofuJSONSortProperty = TofuJSON.readString;
var tofuJSONAppVersion = TofuJSON.readString;
var showAppVersion = Data_Show.showString;
var newtypeSortProperty = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeAppVersion = {
    Coercible0: function () {
        return undefined;
    }
};
var eqSortProperty = Data_Eq.eqString;
var eqAppVersion = Data_Eq.eqString;
module.exports = {
    SortProperty: SortProperty,
    AppVersion: AppVersion,
    newtypeSortProperty: newtypeSortProperty,
    eqSortProperty: eqSortProperty,
    tofuJSONSortProperty: tofuJSONSortProperty,
    newtypeAppVersion: newtypeAppVersion,
    eqAppVersion: eqAppVersion,
    showAppVersion: showAppVersion,
    tofuJSONAppVersion: tofuJSONAppVersion
};
