// Generated by purs version 0.14.5
"use strict";
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Show = require("../Data.Show/index.js");
var Effect = require("../Effect/index.js");
var StringUtils = require("../StringUtils/index.js");
var TacoInput_Styles = require("../TacoInput.Styles/index.js");
var TacoSelect = require("../TacoSelect/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Component = require("../Types.Component/index.js");
var amountSourceSelect = TofuHooks.mkHookComponent("AmountSourceSelect")(function (props) {
    return function __do() {
        var v = TofuHooks.useState(props.value)();
        var items = function (currentStateValue) {
            var $4 = currentStateValue === 1;
            if ($4) {
                return [ {
                    text: Types_Component.amountSourceIntToLabel(1),
                    value: "1"
                } ];
            };
            return [ {
                text: Types_Component.amountSourceIntToLabel(0),
                value: "0"
            }, {
                text: Types_Component.amountSourceIntToLabel(2),
                value: "2"
            }, {
                text: Types_Component.amountSourceIntToLabel(3),
                value: "3"
            } ];
        };
        TofuHooks.useEffect([ props.value ])(function __do() {
            v.value1(function (v1) {
                return props.value;
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Effect.monoidEffect(Data_Monoid.monoidUnit)))();
        })();
        return TacoSelect.component()()({
            value: Data_Show.show(Data_Show.showInt)(v.value0),
            onChange: function (fn) {
                var newValue = fn(Data_Show.show(Data_Show.showInt)(v.value0));
                return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(StringUtils.readStrToInt(newValue))(function (value) {
                    return function __do() {
                        v.value1(function (v1) {
                            return value;
                        })();
                        return props.onChange(value)();
                    };
                });
            },
            items: items(v.value0),
            rendering: new TacoInput_Styles.TableCellRendering(TacoTable_Types.RowSmall.value),
            testId: props.testId
        });
    };
});
module.exports = {
    amountSourceSelect: amountSourceSelect
};
