// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var DOMUtils = require("../DOMUtils/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Timer = require("../Effect.Timer/index.js");
var FRP_Event = require("../FRP.Event/index.js");
var RouteUtils = require("../RouteUtils/index.js");
var Routing = require("../Routing/index.js");
var Routing_PushState = require("../Routing.PushState/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_FrontEndRoutes = require("../Types.FrontEndRoutes/index.js");
var NavigateRoute = function (x) {
    return x;
};
var readNavigateRoute = function (action) {
    if (action instanceof Actions.NavigateRoute) {
        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(action.value0);
    };
    return Data_Maybe.Nothing.value;
};
var routerDriver = function (dispatch) {
    return function (actions) {
        var handleRoute = function (v) {
            return function (v1) {
                return function (newRoute) {
                    return dispatch(new Actions.SetRoute(newRoute));
                };
            };
        };
        return Data_Functor.voidRight(Effect.functorEffect)(Data_Monoid.mempty(FRP_Event.monoidEvent(Data_Monoid.monoidUnit)))(function __do() {
            var nav = Routing_PushState.makeInterface();
            Effect_Timer.setTimeout(0)(function __do() {
                var url = DOMUtils.getLocationPathName();
                var maybeRoute = Data_Either.hush(Routing.match(Types_FrontEndRoutes.appRoute)(url));
                (function () {
                    if (maybeRoute instanceof Data_Maybe.Just) {
                        return dispatch(new Actions.SetRoute(maybeRoute.value0))();
                    };
                    if (maybeRoute instanceof Data_Maybe.Nothing) {
                        return Data_Unit.unit;
                    };
                    throw new Error("Failed pattern match at RouterMiddleware (line 38, column 5 - line 40, column 27): " + [ maybeRoute.constructor.name ]);
                })();
                Routing_PushState.matches(Types_FrontEndRoutes.appRoute)(handleRoute(nav))(nav)();
                return Data_Unit.unit;
            })();
            return Data_Functor["void"](Effect.functorEffect)(FRP_Event.subscribe(actions)(function (action) {
                var v = readNavigateRoute(action);
                if (v instanceof Data_Maybe.Just) {
                    var url = RouteUtils.routeToUrl(v.value0);
                    return nav.pushState(TofuJSON.write(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))({}))(url);
                };
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            }))();
        });
    };
};
var newtypeNavigateRoute = {
    Coercible0: function () {
        return undefined;
    }
};
module.exports = {
    routerDriver: routerDriver
};
