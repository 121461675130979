// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Foreign_Object = require("../Foreign.Object/index.js");
var Types_Invoice = require("../Types.Invoice/index.js");
var lookup = function (k) {
    return Foreign_Object.lookup(k)($foreign.fi);
};
var lookupNullable = function (k) {
    return Data_Nullable.toNullable(lookup(k));
};
var lookup_ = (function () {
    var $2 = Data_Maybe.fromMaybe("");
    return function ($3) {
        return $2(lookup($3));
    };
})();
var invoiceTypeAsFIString = function (v) {
    if (v instanceof Types_Invoice.Invoice) {
        return lookup_("invoice");
    };
    if (v instanceof Types_Invoice.Salary) {
        return lookup_("salary");
    };
    if (v instanceof Types_Invoice.Unknown) {
        return lookup_("unknown");
    };
    throw new Error("Failed pattern match at Locale (line 22, column 25 - line 25, column 31): " + [ v.constructor.name ]);
};
module.exports = {
    lookup: lookup,
    lookup_: lookup_,
    lookupNullable: lookupNullable,
    invoiceTypeAsFIString: invoiceTypeAsFIString,
    fi: $foreign.fi
};
