// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Show = require("../Data.Show/index.js");
var Effect = require("../Effect/index.js");
var ElementUtils = require("../ElementUtils/index.js");
var Formatters = require("../Formatters/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoInput_Styles = require("../TacoInput.Styles/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_CostEstimation = require("../Types.CostEstimation/index.js");
var Types_Element = require("../Types.Element/index.js");
var styleSet = TacoTable_Styles.foldingTableStyleSet;
var elementMeasuresTable = TacoTable.mkTableStateless(TofuHooks.getNameVoid)("ElementMeasuresTable");
var columns = function (v) {
    var testIdPrefix = function (r) {
        return function (id) {
            return Data_Show.show(Types_CostEstimation.showECViewMode)(r.viewMode) + ("-element-measure-" + (Data_Show.show(Types_Element.showElementMeasureId)((Data_Newtype.unwrap()(r.elementMeasure)).elementMeasureId) + ("-" + id)));
        };
    };
    var mkAction = function (mkEditAction) {
        return function (value) {
            return function (handler) {
                return new Actions.ElementMeasureActionRequest({
                    projectId: v.projectId,
                    params: mkEditAction(value),
                    handler: handler
                });
            };
        };
    };
    var valueInput = function (v1) {
        var $8 = v1.dummyRow || Data_Eq.eq(Types_CostEstimation.eqECViewMode)(v1.viewMode)(Types_CostEstimation.ReferenceMode.value);
        if ($8) {
            return TacoText.component()()({
                text: Formatters.formatDecimalValue(3)(v1.elementMeasure.value),
                testId: testIdPrefix(v1)("value")
            });
        };
        return TacoInput.remoteNumberField()()({
            value: v1.elementMeasure.value,
            precision: 3,
            mkAction: mkAction(function (value$prime) {
                return new Types_Element.EditElementMeasureValue({
                    value: value$prime,
                    elementMeasureId: v1.elementMeasure.elementMeasureId
                });
            }),
            stateKey: Data_Show.show(Types_Element.showElementMeasureId)(v1.elementMeasure.elementMeasureId),
            rendering: new TacoInput_Styles.TableCellRendering(TacoTable_Types.RowMedium.value),
            testId: testIdPrefix(v1)("value-input")
        });
    };
    var descriptionInput = function (v1) {
        var $15 = v1.dummyRow || Data_Eq.eq(Types_CostEstimation.eqECViewMode)(v1.viewMode)(Types_CostEstimation.ReferenceMode.value);
        if ($15) {
            return TacoText.component()()({
                text: v1.elementMeasure.description,
                testId: testIdPrefix(v1)("description")
            });
        };
        return TacoInput.remoteStringField()()({
            value: v1.elementMeasure.description,
            title: v1.elementMeasure.description,
            mkAction: mkAction(function (description$prime) {
                return new Types_Element.EditElementMeasureDescription({
                    description: description$prime,
                    elementMeasureId: v1.elementMeasure.elementMeasureId
                });
            }),
            stateKey: Data_Show.show(Types_Element.showElementMeasureId)(v1.elementMeasure.elementMeasureId),
            rendering: new TacoInput_Styles.TableCellRendering(TacoTable_Types.RowMedium.value),
            testId: testIdPrefix(v1)("description-input")
        });
    };
    var codeInput = function (v1) {
        var $22 = v1.dummyRow || Data_Eq.eq(Types_CostEstimation.eqECViewMode)(v1.viewMode)(Types_CostEstimation.ReferenceMode.value);
        if ($22) {
            return TacoText.component()()({
                text: v1.elementMeasure.code,
                testId: testIdPrefix(v1)("code")
            });
        };
        return TacoInput.remoteStringField()()({
            value: v1.elementMeasure.code,
            mkAction: mkAction(function (code$prime) {
                return new Types_Element.EditElementMeasureCode({
                    code: code$prime,
                    elementMeasureId: v1.elementMeasure.elementMeasureId
                });
            }),
            stateKey: Data_Show.show(Types_Element.showElementMeasureId)(v1.elementMeasure.elementMeasureId),
            rendering: new TacoInput_Styles.TableCellRendering(TacoTable_Types.RowMedium.value),
            testId: testIdPrefix(v1)("code-input"),
            validator: ElementUtils.varNameValidator
        });
    };
    return [ {
        key: "code",
        label: React_Basic_DOM.text("Symboli"),
        width: new TacoTable_Types.Flex(3),
        sortProperty: TacoTable_Types.noSortProperty,
        cell: new TacoTable_Types.JSXCell([  ], codeInput),
        headerJSX: Data_Maybe.Nothing.value
    }, {
        key: "description",
        label: React_Basic_DOM.text("Selite"),
        width: new TacoTable_Types.Flex(8),
        sortProperty: TacoTable_Types.noSortProperty,
        cell: new TacoTable_Types.JSXCell([  ], descriptionInput),
        headerJSX: Data_Maybe.Nothing.value
    }, {
        key: "value",
        label: React_Basic_DOM.text("Arvo"),
        width: new TacoTable_Types.Flex(2),
        sortProperty: TacoTable_Types.noSortProperty,
        cell: new TacoTable_Types.JSXCell([  ], valueInput),
        headerJSX: Data_Maybe.Nothing.value
    } ];
};
var mkRow = function (v) {
    return {
        className: v.className,
        rowData: v.rowData,
        foldingElement: Data_Maybe.Nothing.value,
        onClick: Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit))),
        rowKey: v.rowKey,
        columns: columns({
            dispatch: v.dispatch,
            projectId: v.projectId
        })
    };
};
var getRow = function (v) {
    return function (r) {
        return mkRow({
            className: "ElementMeasuresTableRow",
            rowData: {
                dummyRow: v.dummyRow,
                elementMeasure: r,
                viewMode: v.viewMode
            },
            rowKey: Data_Show.show(Types_CostEstimation.showECViewMode)(v.viewMode) + ("-element-measure-" + Data_Show.show(Data_Show.showInt)(Data_Newtype.un()(Types_Element.ElementMeasureId)((Data_Newtype.un()(Types_Element.ElementMeasure)(r)).elementMeasureId))),
            dispatch: v.dispatch,
            projectId: v.projectId
        });
    };
};
module.exports = {
    elementMeasuresTable: elementMeasuresTable,
    mkRow: mkRow,
    getRow: getRow,
    columns: columns,
    styleSet: styleSet
};
