// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var StyleProperties = require("../StyleProperties/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var sidebarWidth = new TacoConstants.CustomSize("180px");
var uiDemoSidebarContainerStyles = Box.boxStyle([ Box.Height.create(new TacoConstants.CustomSize("100vh")), new Box.Overflow(Box.Visible.value, Box.Auto.value), new Box.Width(sidebarWidth), new Box.MinWidth(sidebarWidth), new Box.Padding(TacoConstants.M.value), Box.Style.create(React_Basic_Emotion.css()({
    borderRight: StyleProperties.border(TacoConstants.BorderLight.value)
})) ]);
module.exports = {
    sidebarWidth: sidebarWidth,
    uiDemoSidebarContainerStyles: uiDemoSidebarContainerStyles
};
