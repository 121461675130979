import { useRef } from "react";
import { useWorkerState } from "src/client-ts/hooks/useWorkerState";
import { CostClass } from "ts-bindings/CostClass";
import { OrgCostClass } from "ts-bindings/OrgCostClass";

const orgCcToCc = (orgCc: OrgCostClass): CostClass => ({
  id: orgCc.orgCostClassId,
  name: orgCc.description,
  costClassCode: orgCc.code,
  projectId: 0,
  // OrgCostClass has socialExpensePercentage as a number between 0 and 100 and CostClass has it as a decimal
  socialExpensePercentageInCostEstimation: orgCc.socialExpensePercentage / 100,
  multiplier: 1,
  hourlyPricing: orgCc.hourlyPricing !== 0,
  socialExpensePercentageInCostControlForComponents: null,
  socialExpensePercentageInCostControlForEstimatesAndActuals: null,
  socialExpensePercentageInCostControlForTargets: null,
});

export const useCcFromClassification = (
  classification: string,
  onChange: (costClasses: CostClass[]) => void
) => {
  const prevClassification = useRef<string>();

  const orgCostClasses = useWorkerState("OrgCostClassesState") ?? [];
  const classificationCostClasses = orgCostClasses.filter(
    (cc) => cc.orgClassificationId === classification
  );

  if (prevClassification.current !== classification) {
    if (prevClassification.current !== undefined) {
      onChange(classificationCostClasses.map(orgCcToCc));
    }

    prevClassification.current = classification;
  }
};
