// The compiled purescript line does not have type annotations
// @ts-expect-error
import licenseFeature from "../../../output/UseLicenseFeature";

//These have to be kept in sync with LicenseFeature in UseLicenseFeature.purs
export enum LicenseFeature {
  ELEMENTS = "elements",
  SUBPROJECTS = "subprojects",
  LOCATIONS = "locations",
  COMPONENT_GROUPS = "componentGroups",
  PROJECT_GROUPS = "projectGroups",
  COST_GROUPS = "costGroups",
  PRINTING = "printing",
  USER_ROLES = "userRoles",
  PRODUCTION_PLANNING = "productionPlanning",
  PUBLISH_TO_REPORTING = "publishToReporting",
  ADMIN = "admin",
  PREMIUM_OFFER_PAGE = "premiumOfferPage",
}

export function useLicenseFeatureEnabled(feature: LicenseFeature): boolean {
  // The purescript function returns a TofuHooksRenderEffect, which is
  // an alias for Effect. Effects are represented as functions of 0
  // arguments, hence the extra ().

  return licenseFeature.useLicenseFeatureExt(feature)();
}
