import { useMutation } from "react-query";
import axiosClient from "../../../../axiosConfig";
import { reportError } from "../../../../utils/error";

type DeleteComponentAmountsMutation = {
  type: "ResetEstimationLocations";
  value: { componentId: number }[];
};
export function useDeleteComponentLocationAmounts(
  projectId: number,
  onSave: () => void
) {
  return useMutation(
    (data: DeleteComponentAmountsMutation) => {
      return axiosClient.post(
        `/project/${projectId}/estimationComponentAction`,
        data
      );
    },
    {
      onSuccess: () => {
        onSave();
      },
      onError: (error) => {
        reportError(
          "Error while deleting component location amounts",
          error as Error
        );
      },
    }
  );
}

type DeleteComponentGroupsMutation = {
  components: number[];
};
export function useDeleteComponentGroups(
  projectId: number,
  onSave: () => void
) {
  return useMutation(
    (data: DeleteComponentGroupsMutation) => {
      return axiosClient.post(
        `/project/${projectId}/componentGroupsMassDelete`,
        data
      );
    },
    {
      onSuccess: () => {
        onSave();
      },
      onError: (error) => {
        reportError("Error while deleting component groups", error as Error);
      },
    }
  );
}

type DeleteComponentPricesMutation = {
  components: number[];
};
export function useDeleteComponentPrices(
  projectId: number,
  onSave: () => void
) {
  return useMutation(
    (data: DeleteComponentPricesMutation) => {
      return axiosClient.post(
        `/project/${projectId}/componentPriceMassDelete`,
        data
      );
    },
    {
      onSuccess: () => {
        onSave();
      },
      onError: (error) => {
        reportError("Error while deleting component prices", error as Error);
      },
    }
  );
}
