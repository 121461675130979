// Generated by purs version 0.14.5
"use strict";
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var TacoInput = require("../TacoInput/index.js");
var requiredValidator = function (value) {
    var v = Data_String_CodeUnits.length(value);
    if (v < 1) {
        return TacoInput.Invalid.create("Pakollinen kentt\xe4.");
    };
    return TacoInput.Valid.value;
};
module.exports = {
    requiredValidator: requiredValidator
};
