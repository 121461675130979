// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Common = require("../Common/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function_Uncurried = require("../Data.Function.Uncurried/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var Foreign = require("../Foreign/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");
var Web_HTML_HTMLElement = require("../Web.HTML.HTMLElement/index.js");
var utf = Foreign.unsafeToForeign;
var unsafeReactFunctionComponent = Unsafe_Coerce.unsafeCoerce;
var unsafeDiscardRenderEffects = Unsafe_Coerce.unsafeCoerce;
var renderHookComponent = function (component) {
    return React_Basic.element(Effect_Unsafe.unsafePerformEffect(component));
};
var performRefElementEffect = function (ref) {
    return function (fn) {
        return function __do() {
            var maybeElement = Data_Functor.map(Effect.functorEffect)(function (v) {
                return Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Web_HTML_HTMLElement.fromNode)(v);
            })(React_Basic_Hooks.readRefMaybe(ref))();
            if (maybeElement instanceof Data_Maybe.Just) {
                return fn(maybeElement.value0)();
            };
            if (maybeElement instanceof Data_Maybe.Nothing) {
                return Data_Unit.unit;
            };
            throw new Error("Failed pattern match at ReactHooksUtils (line 104, column 3 - line 106, column 25): " + [ maybeElement.constructor.name ]);
        };
    };
};
var memoizeFn6 = function (fn) {
    return function (arg1) {
        return function (arg2) {
            return function (arg3) {
                return function (arg4) {
                    return function (arg5) {
                        return function (arg6) {
                            var fn6 = Data_Function_Uncurried.mkFn6(fn);
                            return $foreign.memoizeArgsFn_(utf(fn))(utf(fn6))([ utf(arg1), utf(arg2), utf(arg3), utf(arg4), utf(arg5), utf(arg6) ]);
                        };
                    };
                };
            };
        };
    };
};
var memoizeFn5 = function (fn) {
    return function (arg1) {
        return function (arg2) {
            return function (arg3) {
                return function (arg4) {
                    return function (arg5) {
                        var fn5 = Data_Function_Uncurried.mkFn5(fn);
                        return $foreign.memoizeArgsFn_(utf(fn))(utf(fn5))([ utf(arg1), utf(arg2), utf(arg3), utf(arg4), utf(arg5) ]);
                    };
                };
            };
        };
    };
};
var memoizeFn4 = function (fn) {
    return function (arg1) {
        return function (arg2) {
            return function (arg3) {
                return function (arg4) {
                    var fn4 = Data_Function_Uncurried.mkFn4(fn);
                    return $foreign.memoizeArgsFn_(utf(fn))(utf(fn4))([ utf(arg1), utf(arg2), utf(arg3), utf(arg4) ]);
                };
            };
        };
    };
};
var memoizeFn3 = function (fn) {
    return function (arg1) {
        return function (arg2) {
            return function (arg3) {
                var fn3 = Data_Function_Uncurried.mkFn3(fn);
                return $foreign.memoizeArgsFn_(utf(fn))(utf(fn3))([ utf(arg1), utf(arg2), utf(arg3) ]);
            };
        };
    };
};
var memoizeFn2 = function (fn) {
    return function (arg1) {
        return function (arg2) {
            var fn2 = Data_Function_Uncurried.mkFn2(fn);
            return $foreign.memoizeArgsFn_(utf(fn))(utf(fn2))([ utf(arg1), utf(arg2) ]);
        };
    };
};
var memoizeFn1 = function (fn) {
    return function (arg1) {
        var fn1 = Data_Function_Uncurried.mkFn1(fn);
        return $foreign.memoizeArgsFn_(utf(fn))(utf(fn1))([ utf(arg1) ]);
    };
};
var memoizeByEqKey = function (fn) {
    return function (cacheKey) {
        return function (key) {
            return function (checkEq) {
                return $foreign.memoizeByEqKey_(utf(fn))(cacheKey)(utf(checkEq))(key);
            };
        };
    };
};
var usePersistMemo = function (cacheKey) {
    return function (key) {
        return function (fn) {
            var tupleRefEquals = function (v) {
                return Common.refEquals(v.value0)(v.value1);
            };
            var checkRefEq = function (a) {
                return function (b) {
                    var pairs = Data_Array.zip(a)(b);
                    return Data_Array.all(tupleRefEquals)(pairs);
                };
            };
            return Control_Applicative.pure(Effect.applicativeEffect)(memoizeByEqKey(fn)(cacheKey)(key)(checkRefEq));
        };
    };
};
var usePersistMemoEq = function (dictEq) {
    return function (cacheKey) {
        return function (key) {
            return function (fn) {
                return Control_Applicative.pure(Effect.applicativeEffect)(memoizeByEqKey(fn)(cacheKey)(key)(Data_Eq.eq(dictEq)));
            };
        };
    };
};
var component$prime = function (dictLacks) {
    return function (dictLacks1) {
        return function (name) {
            return function (renderFn) {
                var c = unsafeReactFunctionComponent(function (props) {
                    return unsafeDiscardRenderEffects(renderFn(props))();
                });
                return function () {
                    return $foreign.unsafeSetDisplayName(name, c);
                };
            };
        };
    };
};
var make = function (dictLacks) {
    return function (dictLacks1) {
        return function (name) {
            return function (renderFn) {
                return renderHookComponent(React_Basic_Hooks.memo(component$prime()()(name)(renderFn)));
            };
        };
    };
};
module.exports = {
    unsafeReactFunctionComponent: unsafeReactFunctionComponent,
    unsafeDiscardRenderEffects: unsafeDiscardRenderEffects,
    "component'": component$prime,
    renderHookComponent: renderHookComponent,
    make: make,
    performRefElementEffect: performRefElementEffect,
    utf: utf,
    memoizeFn1: memoizeFn1,
    memoizeFn2: memoizeFn2,
    memoizeFn3: memoizeFn3,
    memoizeFn4: memoizeFn4,
    memoizeFn5: memoizeFn5,
    memoizeFn6: memoizeFn6,
    memoizeByEqKey: memoizeByEqKey,
    usePersistMemo: usePersistMemo,
    usePersistMemoEq: usePersistMemoEq,
    unsafeSetDisplayName: $foreign.unsafeSetDisplayName,
    createComponent: $foreign.createComponent,
    make_: $foreign.make_,
    memoizeArgsFn_: $foreign.memoizeArgsFn_,
    memoizeByEqKey_: $foreign.memoizeByEqKey_
};
