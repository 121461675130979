import { SplitButton } from "../../../components/SplitButton";
import { useCallback, useMemo, useState } from "react";
import { IconCopyToProjectV1, IconDelete24Px, IconEditV1 } from "@tocoman/ui";
import { EstimationProjectSummary } from "../../../../../ts-bindings/EstimationProjectSummary";
import { useTranslation } from "react-i18next";
import { ICellRendererParams } from "ag-grid-community";
import { CopyProjectModal } from "./CopyProjectModal";
import { useSelectRowsAsync } from "../../../components/Table/useSelectRowsAsync";
import { useFrontEndNavigation } from "src/client-ts/FrontEndNavigation/useFrontEndNavigation";

export function ProjectActionsButton({
  project,
  onProjectEdit,
  onProjectDelete,
  cellData,
}: {
  project: EstimationProjectSummary;
  onProjectEdit: (project: EstimationProjectSummary) => void;
  onProjectDelete: (project: EstimationProjectSummary) => void;
  cellData?: ICellRendererParams<EstimationProjectSummary>;
}) {
  const { t } = useTranslation("costControl", {
    keyPrefix: "projectList.splitButton",
  });
  const [projectToCopy, setProjectToCopy] = useState<
    EstimationProjectSummary | undefined
  >(undefined);
  const selectRowsAsync = useSelectRowsAsync(cellData?.api);

  const navigate = useFrontEndNavigation();

  const options = useMemo(
    () => [
      {
        icon: IconEditV1,
        label: t`edit`,
        disabled: false,
        color: "",
      },
      {
        icon: IconCopyToProjectV1,
        label: t`copy`,
        disabled: false,
        color: "",
      },
      {
        icon: IconDelete24Px,
        label: t`delete`,
        disabled: false,
        color: "red",
      },
    ],
    [t]
  );

  const onCopyProjectModalClose = useCallback(
    (createdProjectId: number | null) => {
      setProjectToCopy(undefined);
      if (createdProjectId && cellData) {
        selectRowsAsync([createdProjectId.toString()]);
        cellData.api.setFilterModel(null);
      }
    },
    [cellData, selectRowsAsync]
  );

  const onMenuItemClick = useCallback(
    (selectedItem: number) => {
      selectedItem === 0 && onProjectEdit(project);
      selectedItem === 1 && setProjectToCopy(project);
      selectedItem === 2 && onProjectDelete(project);
    },
    [onProjectEdit, project, onProjectDelete]
  );

  return (
    <>
      <SplitButton
        buttonLabel={t`open`}
        options={options}
        onMenuItemClick={onMenuItemClick}
        onButtonClick={() =>
          navigate("EstimationFERoute", { projectId: project.id })
        }
      />
      {projectToCopy && (
        <CopyProjectModal
          isOpen={true}
          closeModal={onCopyProjectModalClose}
          projectToCopy={projectToCopy}
        />
      )}
    </>
  );
}
