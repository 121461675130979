// Generated by purs version 0.14.5
"use strict";
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Kishimen = require("../Kishimen/index.js");
var ReadParam = require("../ReadParam/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Project = require("../Types.Project/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var TargetItemId = function (x) {
    return x;
};
var ResourceId = function (x) {
    return x;
};
var MainCostGroupId = function (x) {
    return x;
};
var EstimateItemId = function (x) {
    return x;
};
var CostGroupEstimateItemId = (function () {
    function CostGroupEstimateItemId(value0) {
        this.value0 = value0;
    };
    CostGroupEstimateItemId.create = function (value0) {
        return new CostGroupEstimateItemId(value0);
    };
    return CostGroupEstimateItemId;
})();
var CostGroupTargetItemId = (function () {
    function CostGroupTargetItemId(value0) {
        this.value0 = value0;
    };
    CostGroupTargetItemId.create = function (value0) {
        return new CostGroupTargetItemId(value0);
    };
    return CostGroupTargetItemId;
})();
var CostGroupItemAssociationId = function (x) {
    return x;
};
var CostGroupId = function (x) {
    return x;
};
var CostGroupCode = function (x) {
    return x;
};
var ReportingResource = function (x) {
    return x;
};
var VATExclusionCostGroups = function (x) {
    return x;
};
var SetVATExclusionCostGroupsAction = (function () {
    function SetVATExclusionCostGroupsAction(value0) {
        this.value0 = value0;
    };
    SetVATExclusionCostGroupsAction.create = function (value0) {
        return new SetVATExclusionCostGroupsAction(value0);
    };
    return SetVATExclusionCostGroupsAction;
})();
var DefaultCGCategory = (function () {
    function DefaultCGCategory() {

    };
    DefaultCGCategory.value = new DefaultCGCategory();
    return DefaultCGCategory;
})();
var CostIncreasesCGCategory = (function () {
    function CostIncreasesCGCategory() {

    };
    CostIncreasesCGCategory.value = new CostIncreasesCGCategory();
    return CostIncreasesCGCategory;
})();
var AdditionalWorkCGCategory = (function () {
    function AdditionalWorkCGCategory() {

    };
    AdditionalWorkCGCategory.value = new AdditionalWorkCGCategory();
    return AdditionalWorkCGCategory;
})();
var UnspecifiedCGCategory = (function () {
    function UnspecifiedCGCategory() {

    };
    UnspecifiedCGCategory.value = new UnspecifiedCGCategory();
    return UnspecifiedCGCategory;
})();
var CostGroupInfo = function (x) {
    return x;
};
var CostGroupUnit = function (x) {
    return x;
};
var AddCostGroupItemTypeEstimateItem = (function () {
    function AddCostGroupItemTypeEstimateItem() {

    };
    AddCostGroupItemTypeEstimateItem.value = new AddCostGroupItemTypeEstimateItem();
    return AddCostGroupItemTypeEstimateItem;
})();
var AddCostGroupItemTypeTargetItem = (function () {
    function AddCostGroupItemTypeTargetItem() {

    };
    AddCostGroupItemTypeTargetItem.value = new AddCostGroupItemTypeTargetItem();
    return AddCostGroupItemTypeTargetItem;
})();
var AddCostGroupEstimateItemsAction = (function () {
    function AddCostGroupEstimateItemsAction(value0) {
        this.value0 = value0;
    };
    AddCostGroupEstimateItemsAction.create = function (value0) {
        return new AddCostGroupEstimateItemsAction(value0);
    };
    return AddCostGroupEstimateItemsAction;
})();
var AddCostGroupTargetItemsAction = (function () {
    function AddCostGroupTargetItemsAction(value0) {
        this.value0 = value0;
    };
    AddCostGroupTargetItemsAction.create = function (value0) {
        return new AddCostGroupTargetItemsAction(value0);
    };
    return AddCostGroupTargetItemsAction;
})();
var UpdateTargetItemAction = (function () {
    function UpdateTargetItemAction(value0) {
        this.value0 = value0;
    };
    UpdateTargetItemAction.create = function (value0) {
        return new UpdateTargetItemAction(value0);
    };
    return UpdateTargetItemAction;
})();
var writeParamResourceId = ReadParam.writeParamInt;
var writeParamCostGroupId = ReadParam.writeParamInt;
var writeParamCostGroupCode = ReadParam.writeParamString;
var tofuJSONTargetItemId = TofuJSON.readInt;
var tofuJSONResourceId = TofuJSON.readInt;
var tofuJSONMainCostGroupId = TofuJSON.readInt;
var tofuJSONEstimateItemId = TofuJSON.readInt;
var tofuJSONCostGroupItemAssociationId = TofuJSON.readInt;
var tofuJSONCostGroupId = TofuJSON.readInt;
var tofuJSONCostGroupCode = TofuJSON.readString;
var tofuJSONReportingResource = TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "amount";
    }
})(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "componentId";
    }
})(TofuJSON.readMaybe(Types_Component.tofuJSONComponentId))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "costGroupCode";
    }
})(TofuJSON.readMaybe(tofuJSONCostGroupCode))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "id";
    }
})(tofuJSONResourceId)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "isCostClassPrice";
    }
})(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "name";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "pricePerUnit";
    }
})(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "unit";
    }
})(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "value";
    }
})(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "amount";
    }
})(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "componentId";
    }
})(TofuJSON.readMaybe(Types_Component.tofuJSONComponentId))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(TofuJSON.readMaybe(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "costGroupCode";
    }
})(TofuJSON.readMaybe(tofuJSONCostGroupCode))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "id";
    }
})(tofuJSONResourceId)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "isCostClassPrice";
    }
})(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "name";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "pricePerUnit";
    }
})(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "unit";
    }
})(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "value";
    }
})(TofuJSON.readMaybe(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()());
var tofuJSONVATExclusionCostGroups = TofuJSON.readArray(tofuJSONCostGroupCode);
var showTargetItemId = Data_Show.showInt;
var showResourceId = Data_Show.showInt;
var showMainCostGroupId = Data_Show.showInt;
var showEstimateItemId = Data_Show.showInt;
var showCostGroupItemAssociationId = Data_Show.showInt;
var showCostGroupId = Data_Show.showInt;
var showCostGroupCode = Data_Show.showString;
var showVATExclusionCostGroups = Data_Show.showArray(showCostGroupCode);
var readParamTargetItemId = ReadParam.intReadParam;
var readParamResourceId = ReadParam.intReadParam;
var readParamMainCostGroupId = ReadParam.intReadParam;
var readParamEstimateItemId = ReadParam.intReadParam;
var readParamCostGroupItemAssociationId = ReadParam.intReadParam;
var readParamCostGroupId = ReadParam.intReadParam;
var readParamCostGroupCode = ReadParam.stringReadParam;
var ordTargetItemId = Data_Ord.ordInt;
var ordResourceId = Data_Ord.ordInt;
var ordEstimateItemId = Data_Ord.ordInt;
var ordCostGroupId = Data_Ord.ordInt;
var ordCostGroupCode = Data_Ord.ordString;
var ordVATExclusionCostGroups = Data_Ord.ordArray(ordCostGroupCode);
var newtypecostGroupUnit = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeVATExclusionCostGroups = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeTargetItemId = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeResourceId = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeReportingResource = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeMainCostGroupId = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeEstimateItemId = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeCostGroupItemAssociationId = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeCostGroupInfo = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeCostGroupId = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeCostGroupCode = {
    Coercible0: function () {
        return undefined;
    }
};
var toCostGroupsListString = function (v) {
    return Data_Array.intercalate(Data_Monoid.monoidString)(",")(Data_Functor.map(Data_Functor.functorArray)(Data_Newtype.un()(CostGroupCode))(v));
};
var isCostGroupTargetItemId = function (x) {
    if (x.id instanceof CostGroupTargetItemId) {
        return true;
    };
    return false;
};
var isCostGroupEstimateItemId = function (x) {
    if (x.id instanceof CostGroupEstimateItemId) {
        return true;
    };
    return false;
};
var genericVATExclusionCostGroupsAction = {
    to: function (x) {
        return new SetVATExclusionCostGroupsAction(x);
    },
    from: function (x) {
        return x.value0;
    }
};
var tofuJSONVATExclusionCostGroupsAction = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "SetVATExclusionCostGroupsAction";
        }
    })(tofuJSONVATExclusionCostGroups)()(TofuJSON.readVariantNil))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "SetVATExclusionCostGroupsAction";
        }
    })(tofuJSONVATExclusionCostGroups)()(TofuJSON.nilWriteForeignVariant))(genericVATExclusionCostGroupsAction)(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "SetVATExclusionCostGroupsAction";
        }
    })()(Kishimen.genericSumToVariantArgArguments)),
    readImpl: TofuJSON.readGenericSum(genericVATExclusionCostGroupsAction)(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "SetVATExclusionCostGroupsAction";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "SetVATExclusionCostGroupsAction";
        }
    })(tofuJSONVATExclusionCostGroups)()(TofuJSON.readVariantNil))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "SetVATExclusionCostGroupsAction";
        }
    })(tofuJSONVATExclusionCostGroups)()(TofuJSON.nilWriteForeignVariant))
};
var genericCostGroupItemId = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new CostGroupEstimateItemId(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return new CostGroupTargetItemId(x.value0);
        };
        throw new Error("Failed pattern match at Types.CostGroup (line 345, column 1 - line 345, column 68): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof CostGroupEstimateItemId) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof CostGroupTargetItemId) {
            return new Data_Generic_Rep.Inr(x.value0);
        };
        throw new Error("Failed pattern match at Types.CostGroup (line 345, column 1 - line 345, column 68): " + [ x.constructor.name ]);
    }
};
var tofuJSONCostGroupItemId = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CostGroupEstimateItemId";
        }
    })(tofuJSONEstimateItemId)()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CostGroupTargetItemId";
        }
    })(tofuJSONTargetItemId)()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CostGroupEstimateItemId";
        }
    })(tofuJSONEstimateItemId)()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CostGroupTargetItemId";
        }
    })(tofuJSONTargetItemId)()(TofuJSON.nilWriteForeignVariant)))(genericCostGroupItemId)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CostGroupEstimateItemId";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CostGroupTargetItemId";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CostGroupEstimateItemId";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CostGroupTargetItemId";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericCostGroupItemId)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CostGroupEstimateItemId";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CostGroupTargetItemId";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CostGroupEstimateItemId";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CostGroupTargetItemId";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CostGroupEstimateItemId";
        }
    })(tofuJSONEstimateItemId)()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CostGroupTargetItemId";
        }
    })(tofuJSONTargetItemId)()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CostGroupEstimateItemId";
        }
    })(tofuJSONEstimateItemId)()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CostGroupTargetItemId";
        }
    })(tofuJSONTargetItemId)()(TofuJSON.nilWriteForeignVariant)))
};
var genericCostGroupItemAction = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new AddCostGroupEstimateItemsAction(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new AddCostGroupTargetItemsAction(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
            return new UpdateTargetItemAction(x.value0.value0);
        };
        throw new Error("Failed pattern match at Types.CostGroup (line 353, column 1 - line 353, column 76): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof AddCostGroupEstimateItemsAction) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof AddCostGroupTargetItemsAction) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof UpdateTargetItemAction) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0));
        };
        throw new Error("Failed pattern match at Types.CostGroup (line 353, column 1 - line 353, column 76): " + [ x.constructor.name ]);
    }
};
var tofuJSONCostGroupItemAction = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddCostGroupEstimateItemsAction";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(tofuJSONCostGroupCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(tofuJSONCostGroupCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddCostGroupTargetItemsAction";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(tofuJSONCostGroupCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(tofuJSONCostGroupCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UpdateTargetItemAction";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(tofuJSONCostGroupCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "value";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(tofuJSONCostGroupCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "value";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))()(TofuJSON.readVariantNil))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddCostGroupEstimateItemsAction";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(tofuJSONCostGroupCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(tofuJSONCostGroupCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddCostGroupTargetItemsAction";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(tofuJSONCostGroupCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(tofuJSONCostGroupCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UpdateTargetItemAction";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(tofuJSONCostGroupCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "value";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(tofuJSONCostGroupCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "value";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))()(TofuJSON.nilWriteForeignVariant))))(genericCostGroupItemAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddCostGroupEstimateItemsAction";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddCostGroupTargetItemsAction";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UpdateTargetItemAction";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddCostGroupTargetItemsAction";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateTargetItemAction";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddCostGroupEstimateItemsAction";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateTargetItemAction";
        }
    }))({
        reflectSymbol: function () {
            return "AddCostGroupTargetItemsAction";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericCostGroupItemAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddCostGroupEstimateItemsAction";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddCostGroupTargetItemsAction";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UpdateTargetItemAction";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddCostGroupTargetItemsAction";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateTargetItemAction";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddCostGroupEstimateItemsAction";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateTargetItemAction";
        }
    }))({
        reflectSymbol: function () {
            return "AddCostGroupTargetItemsAction";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddCostGroupEstimateItemsAction";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(tofuJSONCostGroupCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(tofuJSONCostGroupCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddCostGroupTargetItemsAction";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(tofuJSONCostGroupCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(tofuJSONCostGroupCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UpdateTargetItemAction";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(tofuJSONCostGroupCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "value";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(tofuJSONCostGroupCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "value";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))()(TofuJSON.readVariantNil))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddCostGroupEstimateItemsAction";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(tofuJSONCostGroupCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(tofuJSONCostGroupCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddCostGroupTargetItemsAction";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(tofuJSONCostGroupCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amount";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(tofuJSONCostGroupCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UpdateTargetItemAction";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(tofuJSONCostGroupCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "value";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costGroupCode";
        }
    })(tofuJSONCostGroupCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "value";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))()(TofuJSON.nilWriteForeignVariant))))
};
var genericCostGroupCategoryCode = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return DefaultCGCategory.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return CostIncreasesCGCategory.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return AdditionalWorkCGCategory.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr)) {
            return UnspecifiedCGCategory.value;
        };
        throw new Error("Failed pattern match at Types.CostGroup (line 340, column 1 - line 340, column 80): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof DefaultCGCategory) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof CostIncreasesCGCategory) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
        };
        if (x instanceof AdditionalWorkCGCategory) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
        };
        if (x instanceof UnspecifiedCGCategory) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)));
        };
        throw new Error("Failed pattern match at Types.CostGroup (line 340, column 1 - line 340, column 80): " + [ x.constructor.name ]);
    }
};
var tofuJSON = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AdditionalWorkCGCategory";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CostIncreasesCGCategory";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DefaultCGCategory";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UnspecifiedCGCategory";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AdditionalWorkCGCategory";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CostIncreasesCGCategory";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DefaultCGCategory";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UnspecifiedCGCategory";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))))(genericCostGroupCategoryCode)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DefaultCGCategory";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CostIncreasesCGCategory";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AdditionalWorkCGCategory";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UnspecifiedCGCategory";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AdditionalWorkCGCategory";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UnspecifiedCGCategory";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CostIncreasesCGCategory";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UnspecifiedCGCategory";
        }
    }))({
        reflectSymbol: function () {
            return "AdditionalWorkCGCategory";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DefaultCGCategory";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UnspecifiedCGCategory";
        }
    }))({
        reflectSymbol: function () {
            return "CostIncreasesCGCategory";
        }
    }))({
        reflectSymbol: function () {
            return "AdditionalWorkCGCategory";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericCostGroupCategoryCode)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DefaultCGCategory";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CostIncreasesCGCategory";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AdditionalWorkCGCategory";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UnspecifiedCGCategory";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AdditionalWorkCGCategory";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UnspecifiedCGCategory";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CostIncreasesCGCategory";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UnspecifiedCGCategory";
        }
    }))({
        reflectSymbol: function () {
            return "AdditionalWorkCGCategory";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DefaultCGCategory";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UnspecifiedCGCategory";
        }
    }))({
        reflectSymbol: function () {
            return "CostIncreasesCGCategory";
        }
    }))({
        reflectSymbol: function () {
            return "AdditionalWorkCGCategory";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AdditionalWorkCGCategory";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CostIncreasesCGCategory";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DefaultCGCategory";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UnspecifiedCGCategory";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AdditionalWorkCGCategory";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CostIncreasesCGCategory";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DefaultCGCategory";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UnspecifiedCGCategory";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))))
};
var tofuJSONCostGroupInfo = TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "category";
    }
})(tofuJSON)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "code";
    }
})(tofuJSONCostGroupCode)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "completionPercentage";
    }
})(Types_Unit.tofuJSONPercentageOfHundred)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "id";
    }
})(tofuJSONCostGroupId)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "memo";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "name";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "projectId";
    }
})(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "category";
    }
})(tofuJSON)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "code";
    }
})(tofuJSONCostGroupCode)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "completionPercentage";
    }
})(Types_Unit.tofuJSONPercentageOfHundred)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "id";
    }
})(tofuJSONCostGroupId)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "memo";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "name";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "projectId";
    }
})(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()());
var tofuJSONCostGroupUnit = TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "actualCosts";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "category";
    }
})(tofuJSON)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "code";
    }
})(tofuJSONCostGroupCode)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "completionPercentage";
    }
})(Types_Unit.tofuJSONPercentageOfHundred)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "estimateCosts";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "id";
    }
})(tofuJSONCostGroupId)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "memo";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "name";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "projectId";
    }
})(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "targetCosts";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "actualCosts";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "category";
    }
})(tofuJSON)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "code";
    }
})(tofuJSONCostGroupCode)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "completionPercentage";
    }
})(Types_Unit.tofuJSONPercentageOfHundred)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "estimateCosts";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "id";
    }
})(tofuJSONCostGroupId)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "memo";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "name";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "projectId";
    }
})(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "targetCosts";
    }
})(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()());
var genericAddCostGroupItemType = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return AddCostGroupItemTypeEstimateItem.value;
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return AddCostGroupItemTypeTargetItem.value;
        };
        throw new Error("Failed pattern match at Types.CostGroup (line 349, column 1 - line 349, column 78): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof AddCostGroupItemTypeEstimateItem) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof AddCostGroupItemTypeTargetItem) {
            return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
        };
        throw new Error("Failed pattern match at Types.CostGroup (line 349, column 1 - line 349, column 78): " + [ x.constructor.name ]);
    }
};
var tofuJSONAddCostGroupItemType = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddCostGroupItemTypeEstimateItem";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddCostGroupItemTypeTargetItem";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddCostGroupItemTypeEstimateItem";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddCostGroupItemTypeTargetItem";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))(genericAddCostGroupItemType)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddCostGroupItemTypeEstimateItem";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddCostGroupItemTypeTargetItem";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddCostGroupItemTypeEstimateItem";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddCostGroupItemTypeTargetItem";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericAddCostGroupItemType)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddCostGroupItemTypeEstimateItem";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddCostGroupItemTypeTargetItem";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddCostGroupItemTypeEstimateItem";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddCostGroupItemTypeTargetItem";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddCostGroupItemTypeEstimateItem";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddCostGroupItemTypeTargetItem";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddCostGroupItemTypeEstimateItem";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddCostGroupItemTypeTargetItem";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))
};
var fromRomanNumeralToCostGroupCategoryCode = function (romanNumeral) {
    if (romanNumeral === "I") {
        return new Data_Maybe.Just(DefaultCGCategory.value);
    };
    if (romanNumeral === "II") {
        return new Data_Maybe.Just(CostIncreasesCGCategory.value);
    };
    if (romanNumeral === "III") {
        return new Data_Maybe.Just(AdditionalWorkCGCategory.value);
    };
    if (romanNumeral === "IV") {
        return new Data_Maybe.Just(UnspecifiedCGCategory.value);
    };
    return Data_Maybe.Nothing.value;
};
var fromCostGroupsListString = function (s) {
    return VATExclusionCostGroups(Data_Functor.map(Data_Functor.functorArray)(CostGroupCode)(Data_String_Common.split(",")(s)));
};
var extractCostGroupItemId = function (x) {
    if (x instanceof CostGroupTargetItemId) {
        return x.value0;
    };
    if (x instanceof CostGroupEstimateItemId) {
        return x.value0;
    };
    throw new Error("Failed pattern match at Types.CostGroup (line 227, column 28 - line 229, column 52): " + [ x.constructor.name ]);
};
var eqTargetItemId = Data_Eq.eqInt;
var eqResourceId = Data_Eq.eqInt;
var eqMainCostGroupId = Data_Eq.eqInt;
var eqEstimateItemId = Data_Eq.eqInt;
var eqCostGroupItemId = {
    eq: function (x) {
        return function (y) {
            if (x instanceof CostGroupEstimateItemId && y instanceof CostGroupEstimateItemId) {
                return Data_Eq.eq(eqEstimateItemId)(x.value0)(y.value0);
            };
            if (x instanceof CostGroupTargetItemId && y instanceof CostGroupTargetItemId) {
                return Data_Eq.eq(eqTargetItemId)(x.value0)(y.value0);
            };
            return false;
        };
    }
};
var eqCostGroupItemAssociationId = Data_Eq.eqInt;
var eqCostGroupId = Data_Eq.eqInt;
var eqCostGroupCode = Data_Eq.eqString;
var eqCostGroupItemAction = {
    eq: function (x) {
        return function (y) {
            if (x instanceof AddCostGroupEstimateItemsAction && y instanceof AddCostGroupEstimateItemsAction) {
                return Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "description";
                    }
                })(Data_Eq.eqString))()({
                    reflectSymbol: function () {
                        return "costGroupCode";
                    }
                })(eqCostGroupCode))()({
                    reflectSymbol: function () {
                        return "costClassCode";
                    }
                })(Types_Newtypes.eqCostClassCode))()({
                    reflectSymbol: function () {
                        return "amount";
                    }
                })(Data_Eq.eqNumber))))(x.value0)(y.value0);
            };
            if (x instanceof AddCostGroupTargetItemsAction && y instanceof AddCostGroupTargetItemsAction) {
                return Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "description";
                    }
                })(Data_Eq.eqString))()({
                    reflectSymbol: function () {
                        return "costGroupCode";
                    }
                })(eqCostGroupCode))()({
                    reflectSymbol: function () {
                        return "costClassCode";
                    }
                })(Types_Newtypes.eqCostClassCode))()({
                    reflectSymbol: function () {
                        return "amount";
                    }
                })(Data_Eq.eqNumber))))(x.value0)(y.value0);
            };
            if (x instanceof UpdateTargetItemAction && y instanceof UpdateTargetItemAction) {
                return Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "value";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "costGroupCode";
                    }
                })(eqCostGroupCode))()({
                    reflectSymbol: function () {
                        return "costClassCode";
                    }
                })(Types_Newtypes.eqCostClassCode))))(x.value0)(y.value0);
            };
            return false;
        };
    }
};
var eqReportingResource = Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "value";
    }
})(Data_Maybe.eqMaybe(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "unit";
    }
})(Data_Maybe.eqMaybe(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "pricePerUnit";
    }
})(Data_Maybe.eqMaybe(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "name";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "isCostClassPrice";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "id";
    }
})(eqResourceId))()({
    reflectSymbol: function () {
        return "costGroupCode";
    }
})(Data_Maybe.eqMaybe(eqCostGroupCode)))()({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(Data_Maybe.eqMaybe(Types_Newtypes.eqCostClassCode)))()({
    reflectSymbol: function () {
        return "componentId";
    }
})(Data_Maybe.eqMaybe(Types_Component.eqComponentId)))()({
    reflectSymbol: function () {
        return "amount";
    }
})(Data_Maybe.eqMaybe(Data_Eq.eqNumber)));
var eqVATExclusionCostGroups = Data_Eq.eqArray(eqCostGroupCode);
var eqCostGroupCategoryCode = {
    eq: function (x) {
        return function (y) {
            if (x instanceof DefaultCGCategory && y instanceof DefaultCGCategory) {
                return true;
            };
            if (x instanceof CostIncreasesCGCategory && y instanceof CostIncreasesCGCategory) {
                return true;
            };
            if (x instanceof AdditionalWorkCGCategory && y instanceof AdditionalWorkCGCategory) {
                return true;
            };
            if (x instanceof UnspecifiedCGCategory && y instanceof UnspecifiedCGCategory) {
                return true;
            };
            return false;
        };
    }
};
var eqCostGroupInfo = Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "projectId";
    }
})(Types_Project.eqProjectId))()({
    reflectSymbol: function () {
        return "name";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "memo";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "id";
    }
})(eqCostGroupId))()({
    reflectSymbol: function () {
        return "completionPercentage";
    }
})(Types_Unit.eqPercentageOfHundred))()({
    reflectSymbol: function () {
        return "code";
    }
})(eqCostGroupCode))()({
    reflectSymbol: function () {
        return "category";
    }
})(eqCostGroupCategoryCode));
var equalCostGroupUnit = Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "targetCosts";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "projectId";
    }
})(Types_Project.eqProjectId))()({
    reflectSymbol: function () {
        return "name";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "memo";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "id";
    }
})(eqCostGroupId))()({
    reflectSymbol: function () {
        return "estimateCosts";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "completionPercentage";
    }
})(Types_Unit.eqPercentageOfHundred))()({
    reflectSymbol: function () {
        return "code";
    }
})(eqCostGroupCode))()({
    reflectSymbol: function () {
        return "category";
    }
})(eqCostGroupCategoryCode))()({
    reflectSymbol: function () {
        return "actualCosts";
    }
})(Data_Eq.eqNumber));
var ordCostGroupCategoryCode = {
    compare: function (x) {
        return function (y) {
            if (x instanceof DefaultCGCategory && y instanceof DefaultCGCategory) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof DefaultCGCategory) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof DefaultCGCategory) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof CostIncreasesCGCategory && y instanceof CostIncreasesCGCategory) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof CostIncreasesCGCategory) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof CostIncreasesCGCategory) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AdditionalWorkCGCategory && y instanceof AdditionalWorkCGCategory) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AdditionalWorkCGCategory) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AdditionalWorkCGCategory) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof UnspecifiedCGCategory && y instanceof UnspecifiedCGCategory) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at Types.CostGroup (line 339, column 1 - line 339, column 70): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqCostGroupCategoryCode;
    }
};
var eqAddCostGroupItemType = {
    eq: function (x) {
        return function (y) {
            if (x instanceof AddCostGroupItemTypeEstimateItem && y instanceof AddCostGroupItemTypeEstimateItem) {
                return true;
            };
            if (x instanceof AddCostGroupItemTypeTargetItem && y instanceof AddCostGroupItemTypeTargetItem) {
                return true;
            };
            return false;
        };
    }
};
var costGroupItemIdToString = function (v) {
    if (v instanceof CostGroupEstimateItemId) {
        return "CostGroupEstimateItemId " + Data_Show.show(Data_Show.showInt)(v.value0);
    };
    if (v instanceof CostGroupTargetItemId) {
        return "CostGroupTargetItemId " + Data_Show.show(Data_Show.showInt)(v.value0);
    };
    throw new Error("Failed pattern match at Types.CostGroup (line 221, column 1 - line 221, column 53): " + [ v.constructor.name ]);
};
var costGroupCategoryCodeToRomanNumeral = function (code) {
    if (code instanceof DefaultCGCategory) {
        return "I";
    };
    if (code instanceof CostIncreasesCGCategory) {
        return "II";
    };
    if (code instanceof AdditionalWorkCGCategory) {
        return "III";
    };
    if (code instanceof UnspecifiedCGCategory) {
        return "IV";
    };
    throw new Error("Failed pattern match at Types.CostGroup (line 119, column 3 - line 123, column 34): " + [ code.constructor.name ]);
};
module.exports = {
    CostGroupCode: CostGroupCode,
    CostGroupId: CostGroupId,
    CostGroupItemAssociationId: CostGroupItemAssociationId,
    MainCostGroupId: MainCostGroupId,
    ResourceId: ResourceId,
    EstimateItemId: EstimateItemId,
    TargetItemId: TargetItemId,
    CostGroupUnit: CostGroupUnit,
    DefaultCGCategory: DefaultCGCategory,
    CostIncreasesCGCategory: CostIncreasesCGCategory,
    AdditionalWorkCGCategory: AdditionalWorkCGCategory,
    UnspecifiedCGCategory: UnspecifiedCGCategory,
    fromRomanNumeralToCostGroupCategoryCode: fromRomanNumeralToCostGroupCategoryCode,
    costGroupCategoryCodeToRomanNumeral: costGroupCategoryCodeToRomanNumeral,
    CostGroupInfo: CostGroupInfo,
    isCostGroupEstimateItemId: isCostGroupEstimateItemId,
    isCostGroupTargetItemId: isCostGroupTargetItemId,
    CostGroupEstimateItemId: CostGroupEstimateItemId,
    CostGroupTargetItemId: CostGroupTargetItemId,
    costGroupItemIdToString: costGroupItemIdToString,
    extractCostGroupItemId: extractCostGroupItemId,
    AddCostGroupEstimateItemsAction: AddCostGroupEstimateItemsAction,
    AddCostGroupTargetItemsAction: AddCostGroupTargetItemsAction,
    UpdateTargetItemAction: UpdateTargetItemAction,
    AddCostGroupItemTypeEstimateItem: AddCostGroupItemTypeEstimateItem,
    AddCostGroupItemTypeTargetItem: AddCostGroupItemTypeTargetItem,
    ReportingResource: ReportingResource,
    VATExclusionCostGroups: VATExclusionCostGroups,
    fromCostGroupsListString: fromCostGroupsListString,
    toCostGroupsListString: toCostGroupsListString,
    SetVATExclusionCostGroupsAction: SetVATExclusionCostGroupsAction,
    newtypecostGroupUnit: newtypecostGroupUnit,
    equalCostGroupUnit: equalCostGroupUnit,
    newtypeCostGroupInfo: newtypeCostGroupInfo,
    eqCostGroupInfo: eqCostGroupInfo,
    tofuJSONCostGroupInfo: tofuJSONCostGroupInfo,
    eqCostGroupItemAction: eqCostGroupItemAction,
    eqAddCostGroupItemType: eqAddCostGroupItemType,
    newtypeCostGroupId: newtypeCostGroupId,
    readParamCostGroupId: readParamCostGroupId,
    writeParamCostGroupId: writeParamCostGroupId,
    tofuJSONCostGroupId: tofuJSONCostGroupId,
    showCostGroupId: showCostGroupId,
    eqCostGroupId: eqCostGroupId,
    ordCostGroupId: ordCostGroupId,
    newtypeCostGroupItemAssociationId: newtypeCostGroupItemAssociationId,
    readParamCostGroupItemAssociationId: readParamCostGroupItemAssociationId,
    tofuJSONCostGroupItemAssociationId: tofuJSONCostGroupItemAssociationId,
    showCostGroupItemAssociationId: showCostGroupItemAssociationId,
    eqCostGroupItemAssociationId: eqCostGroupItemAssociationId,
    newtypeMainCostGroupId: newtypeMainCostGroupId,
    readParamMainCostGroupId: readParamMainCostGroupId,
    tofuJSONMainCostGroupId: tofuJSONMainCostGroupId,
    showMainCostGroupId: showMainCostGroupId,
    eqMainCostGroupId: eqMainCostGroupId,
    newtypeCostGroupCode: newtypeCostGroupCode,
    readParamCostGroupCode: readParamCostGroupCode,
    writeParamCostGroupCode: writeParamCostGroupCode,
    tofuJSONCostGroupCode: tofuJSONCostGroupCode,
    showCostGroupCode: showCostGroupCode,
    eqCostGroupCode: eqCostGroupCode,
    ordCostGroupCode: ordCostGroupCode,
    eqCostGroupCategoryCode: eqCostGroupCategoryCode,
    ordCostGroupCategoryCode: ordCostGroupCategoryCode,
    genericCostGroupCategoryCode: genericCostGroupCategoryCode,
    tofuJSON: tofuJSON,
    eqCostGroupItemId: eqCostGroupItemId,
    genericCostGroupItemId: genericCostGroupItemId,
    tofuJSONCostGroupItemId: tofuJSONCostGroupItemId,
    genericAddCostGroupItemType: genericAddCostGroupItemType,
    tofuJSONAddCostGroupItemType: tofuJSONAddCostGroupItemType,
    genericCostGroupItemAction: genericCostGroupItemAction,
    tofuJSONCostGroupItemAction: tofuJSONCostGroupItemAction,
    tofuJSONCostGroupUnit: tofuJSONCostGroupUnit,
    newtypeEstimateItemId: newtypeEstimateItemId,
    readParamEstimateItemId: readParamEstimateItemId,
    tofuJSONEstimateItemId: tofuJSONEstimateItemId,
    showEstimateItemId: showEstimateItemId,
    eqEstimateItemId: eqEstimateItemId,
    ordEstimateItemId: ordEstimateItemId,
    newtypeTargetItemId: newtypeTargetItemId,
    readParamTargetItemId: readParamTargetItemId,
    tofuJSONTargetItemId: tofuJSONTargetItemId,
    showTargetItemId: showTargetItemId,
    eqTargetItemId: eqTargetItemId,
    ordTargetItemId: ordTargetItemId,
    newtypeResourceId: newtypeResourceId,
    readParamResourceId: readParamResourceId,
    writeParamResourceId: writeParamResourceId,
    tofuJSONResourceId: tofuJSONResourceId,
    showResourceId: showResourceId,
    eqResourceId: eqResourceId,
    ordResourceId: ordResourceId,
    newtypeReportingResource: newtypeReportingResource,
    tofuJSONReportingResource: tofuJSONReportingResource,
    eqReportingResource: eqReportingResource,
    newtypeVATExclusionCostGroups: newtypeVATExclusionCostGroups,
    tofuJSONVATExclusionCostGroups: tofuJSONVATExclusionCostGroups,
    showVATExclusionCostGroups: showVATExclusionCostGroups,
    eqVATExclusionCostGroups: eqVATExclusionCostGroups,
    ordVATExclusionCostGroups: ordVATExclusionCostGroups,
    genericVATExclusionCostGroupsAction: genericVATExclusionCostGroupsAction,
    tofuJSONVATExclusionCostGroupsAction: tofuJSONVATExclusionCostGroupsAction
};
