// Generated by purs version 0.14.5
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Kishimen = require("../Kishimen/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_Project = require("../Types.Project/index.js");
var ProjectLocationId = function (x) {
    return x;
};
var ProjectLocation = function (x) {
    return x;
};
var AddLocation = (function () {
    function AddLocation(value0) {
        this.value0 = value0;
    };
    AddLocation.create = function (value0) {
        return new AddLocation(value0);
    };
    return AddLocation;
})();
var EditLocation = (function () {
    function EditLocation(value0) {
        this.value0 = value0;
    };
    EditLocation.create = function (value0) {
        return new EditLocation(value0);
    };
    return EditLocation;
})();
var DeleteLocation = (function () {
    function DeleteLocation(value0) {
        this.value0 = value0;
    };
    DeleteLocation.create = function (value0) {
        return new DeleteLocation(value0);
    };
    return DeleteLocation;
})();
var tofuJSONProjectLocationId = TofuJSON.readInt;
var tofuJSONProjectLocation = TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "code";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "description";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "id";
    }
})(tofuJSONProjectLocationId)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "projectId";
    }
})(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "scale";
    }
})(TofuJSON.readInt)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "sortingNumber";
    }
})(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "code";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "description";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "id";
    }
})(tofuJSONProjectLocationId)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "projectId";
    }
})(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "scale";
    }
})(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "sortingNumber";
    }
})(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()());
var showProjectLocationId = Data_Show.showInt;
var showProjectLocation = Data_Show.showRecord()(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "code";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "description";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "id";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "projectId";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "scale";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "sortingNumber";
    }
})(Data_Show.showRecordFieldsNil)(Data_Show.showInt))(Data_Show.showInt))(Types_Project.showProjectId))(showProjectLocationId))(Data_Show.showString))(Data_Show.showString));
var ordProjectLocationId = Data_Ord.ordInt;
var ordProjectLocation = Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()({
    reflectSymbol: function () {
        return "sortingNumber";
    }
})(Data_Ord.ordInt))()({
    reflectSymbol: function () {
        return "scale";
    }
})(Data_Ord.ordInt))()({
    reflectSymbol: function () {
        return "projectId";
    }
})(Types_Project.ordProjectId))()({
    reflectSymbol: function () {
        return "id";
    }
})(ordProjectLocationId))()({
    reflectSymbol: function () {
        return "description";
    }
})(Data_Ord.ordString))()({
    reflectSymbol: function () {
        return "code";
    }
})(Data_Ord.ordString));
var newtypeProjectLocationId = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeProjectLocation = {
    Coercible0: function () {
        return undefined;
    }
};
var genericLocationAction = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new AddLocation(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new EditLocation(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
            return new DeleteLocation(x.value0.value0);
        };
        throw new Error("Failed pattern match at Types.Location (line 47, column 1 - line 47, column 66): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof AddLocation) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof EditLocation) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof DeleteLocation) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0));
        };
        throw new Error("Failed pattern match at Types.Location (line 47, column 1 - line 47, column 66): " + [ x.constructor.name ]);
    }
};
var tofuJSONLocationAction = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddLocation";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "scale";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "scale";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DeleteLocation";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "projectLocationId";
        }
    })(tofuJSONProjectLocationId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "projectLocationId";
        }
    })(tofuJSONProjectLocationId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditLocation";
        }
    })(tofuJSONProjectLocation)()(TofuJSON.readVariantNil))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddLocation";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "scale";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "scale";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DeleteLocation";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "projectLocationId";
        }
    })(tofuJSONProjectLocationId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "projectLocationId";
        }
    })(tofuJSONProjectLocationId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditLocation";
        }
    })(tofuJSONProjectLocation)()(TofuJSON.nilWriteForeignVariant))))(genericLocationAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddLocation";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditLocation";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DeleteLocation";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditLocation";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DeleteLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddLocation";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditLocation";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteLocation";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericLocationAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddLocation";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditLocation";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DeleteLocation";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditLocation";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DeleteLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddLocation";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditLocation";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteLocation";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddLocation";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "scale";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "scale";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DeleteLocation";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "projectLocationId";
        }
    })(tofuJSONProjectLocationId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "projectLocationId";
        }
    })(tofuJSONProjectLocationId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditLocation";
        }
    })(tofuJSONProjectLocation)()(TofuJSON.readVariantNil))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddLocation";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "scale";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "scale";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(TofuJSON.readInt)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DeleteLocation";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "projectLocationId";
        }
    })(tofuJSONProjectLocationId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "projectLocationId";
        }
    })(tofuJSONProjectLocationId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditLocation";
        }
    })(tofuJSONProjectLocation)()(TofuJSON.nilWriteForeignVariant))))
};
var eqProjectLocationId = Data_Eq.eqInt;
var eqProjectLocation = Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "sortingNumber";
    }
})(Data_Eq.eqInt))()({
    reflectSymbol: function () {
        return "scale";
    }
})(Data_Eq.eqInt))()({
    reflectSymbol: function () {
        return "projectId";
    }
})(Types_Project.eqProjectId))()({
    reflectSymbol: function () {
        return "id";
    }
})(eqProjectLocationId))()({
    reflectSymbol: function () {
        return "description";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "code";
    }
})(Data_Eq.eqString));
module.exports = {
    ProjectLocationId: ProjectLocationId,
    ProjectLocation: ProjectLocation,
    AddLocation: AddLocation,
    EditLocation: EditLocation,
    DeleteLocation: DeleteLocation,
    newtypeProjectLocationId: newtypeProjectLocationId,
    tofuJSONProjectLocationId: tofuJSONProjectLocationId,
    showProjectLocationId: showProjectLocationId,
    eqProjectLocationId: eqProjectLocationId,
    ordProjectLocationId: ordProjectLocationId,
    newtypeProjectLocation: newtypeProjectLocation,
    tofuJSONProjectLocation: tofuJSONProjectLocation,
    showProjectLocation: showProjectLocation,
    eqProjectLocation: eqProjectLocation,
    ordProjectLocation: ordProjectLocation,
    genericLocationAction: genericLocationAction,
    tofuJSONLocationAction: tofuJSONLocationAction
};
