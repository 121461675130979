// Generated by purs version 0.14.5
"use strict";
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Utils = require("../Utils/index.js");
var zeroPercent = 0.0;
var nullableString = Utils.defaultNull("");
var nullableNumber = Utils.defaultNull(0.0);
var prepareDiffAndMargin = function (v) {
    return {
        euro: nullableNumber(v.diff),
        percent: nullableNumber(v.margin)
    };
};
var targetDiffAndMargin = function (r) {
    return prepareDiffAndMargin({
        diff: r.targetDiff,
        margin: r.targetMargin
    });
};
var mkSummaryData = function (ps) {
    var addNullable = function (acc) {
        return function (nullable) {
            return Utils.foldNullable(acc)(Data_Semiring.add(Data_Semiring.semiringNumber)(acc))(nullable);
        };
    };
    var sumNullable = (function () {
        var $20 = Data_Foldable.foldl(Data_Foldable.foldableArray)(addNullable)(0.0);
        return function ($21) {
            return Utils.pureNullable($20($21));
        };
    })();
    return {
        name: "Hankkeet yhteens\xe4",
        actualCosts: sumNullable(Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.actualCosts;
        })(ps)),
        actualDiff: sumNullable(Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.actualDiff;
        })(ps)),
        actualDiffApproved: sumNullable(Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.actualDiffApproved;
        })(ps)),
        actualIncome: sumNullable(Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.actualIncome;
        })(ps)),
        actualIncomeApproved: sumNullable(Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.actualIncomeApproved;
        })(ps)),
        actualMargin: sumNullable(Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.actualMargin;
        })(ps)),
        actualMarginApproved: sumNullable(Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.actualMarginApproved;
        })(ps)),
        contractPrice: Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.contractPrice;
        })(ps)),
        estimatedCosts: sumNullable(Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.estimatedCosts;
        })(ps)),
        estimatedDiff: sumNullable(Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.estimatedDiff;
        })(ps)),
        estimatedIncome: sumNullable(Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.estimatedIncome;
        })(ps)),
        estimatedMargin: sumNullable(Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.estimatedMargin;
        })(ps)),
        targetCosts: sumNullable(Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.targetCosts;
        })(ps)),
        targetDiff: sumNullable(Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.targetDiff;
        })(ps)),
        targetIncome: sumNullable(Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.targetIncome;
        })(ps)),
        targetMargin: sumNullable(Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.targetMargin;
        })(ps))
    };
};
var estimatedDiffAndMargin = function (r) {
    return prepareDiffAndMargin({
        diff: r.estimatedDiff,
        margin: r.estimatedMargin
    });
};
var decimalPrecision1 = 1;
var actualDiffAndMarginApproved = function (r) {
    return prepareDiffAndMargin({
        diff: r.actualDiffApproved,
        margin: r.actualMarginApproved
    });
};
var actualDiffAndMargin = function (r) {
    return prepareDiffAndMargin({
        diff: r.actualDiff,
        margin: r.actualMargin
    });
};
module.exports = {
    mkSummaryData: mkSummaryData,
    nullableNumber: nullableNumber,
    nullableString: nullableString,
    zeroPercent: zeroPercent,
    decimalPrecision1: decimalPrecision1,
    prepareDiffAndMargin: prepareDiffAndMargin,
    targetDiffAndMargin: targetDiffAndMargin,
    estimatedDiffAndMargin: estimatedDiffAndMargin,
    actualDiffAndMargin: actualDiffAndMargin,
    actualDiffAndMarginApproved: actualDiffAndMarginApproved
};
