import { useCallback, useEffect, useState } from "react";
import {
  ColumnState,
  GridApi,
  GridState,
  RowGroupingDisplayType,
} from "ag-grid-community";

export function useAgGridConfiguration(gridApi: GridApi | null) {
  const [groupIncludeFooter, setGroupIncludeFooter] = useState(false);
  const [includeTotalFooter, setIncludeTotalFooter] = useState(false);
  const [groupDisplayType, setGroupDisplayType] = useState<
    RowGroupingDisplayType
  >("singleColumn");
  const [includeResources, setIncludeResources] = useState(false);
  const [
    includeTotalFooterWithSocial,
    setIncludeTotalFooterWithSocial,
  ] = useState(false);
  const [initialState, setInitialState] = useState<GridState>({});
  const [gridVisible, setGridVisible] = useState(true);

  const applyConfiguration = useCallback(
    (configuration: AgGridConfigurationData | string) => {
      if (!gridApi) {
        return;
      }
      if (typeof configuration === "string") {
        configuration = JSON.parse(configuration) as AgGridConfigurationData;
      }

      // Reset grid state to prevent column visibility issues with "multipleColumns" group display type
      if (groupDisplayType === "multipleColumns") {
        gridApi.setGridOption("columnDefs", []);
      }
      gridApi.setGridOption("rowData", []);

      // Apply column state based on old configurations
      if (configuration.version !== "v2") {
        setOldConfiguration(gridApi, configuration);
        setInitialState(gridApi.getState());
      } else {
        // Check if configuration data includes column and set visibility based on configuration
        const gridColumns = gridApi.getColumnState();
        const colIds = configuration.state.columnOrder?.orderedColIds;
        const newHiddenColumns = gridColumns
          ?.filter((col) => !colIds?.includes(col.colId))
          .map((col) => col.colId);
        const hiddenColumns = newHiddenColumns?.concat(
          configuration.state.columnVisibility?.hiddenColIds ?? []
        );
        const allColumns =
          configuration.state.columnOrder?.orderedColIds?.concat(
            newHiddenColumns
          ) ?? [];

        const configState = {
          ...configuration.state,
          columnOrder: {
            orderedColIds: allColumns,
          },
          columnVisibility: {
            hiddenColIds: hiddenColumns,
          },
        };

        setInitialState(configState);
      }

      setGroupDisplayType(configuration.groupDisplayType);
      setIncludeResources(configuration.includeResources);
      setGroupIncludeFooter(configuration.groupFooter);
      setIncludeTotalFooter(configuration.totalFooter);
      setIncludeTotalFooterWithSocial(configuration.totalFooterWithSocial);
      setGridVisible(false);
    },
    [gridApi]
  );

  useEffect(() => {
    const api = gridApi;
    if (!api || api.isDestroyed()) {
      return;
    }
    api.refreshClientSideRowModel();
  }, [
    gridApi,
    groupIncludeFooter,
    includeTotalFooter,
    groupDisplayType,
    includeResources,
  ]);

  useEffect(() => {
    if (!gridVisible) {
      setGridVisible(true);
    }
  }, [gridVisible]);

  useEffect(() => {
    if (gridVisible && gridApi && !gridApi.isDestroyed()) {
      gridApi.setGridOption("groupDisplayType", groupDisplayType);
    }
  }, [groupDisplayType, gridVisible, gridApi]);

  return {
    groupDisplayType,
    groupIncludeFooter,
    includeTotalFooter,
    includeResources,
    includeTotalFooterWithSocial: includeTotalFooterWithSocial,
    setGroupDisplayType,
    setGroupIncludeFooter,
    setIncludeTotalFooter: setIncludeTotalFooter,
    setIncludeResources,
    setIncludeTotalFooterWithSocial: setIncludeTotalFooterWithSocial,
    applyConfiguration,
    initialState,
    gridVisible,
  };
}
export type AgGridConfigurationData = {
  state: GridState;
  columns?: ColumnState[];
  pivot?: boolean;
  filters?: string[];
  groupFooter: boolean;
  totalFooter: boolean;
  totalFooterWithSocial: boolean;
  groupDisplayType: RowGroupingDisplayType;
  includeResources: boolean;
  includeSocialTotal: boolean;
  version?: string;
};

function setOldConfiguration(
  gridApi: GridApi,
  configuration: AgGridConfigurationData
) {
  gridApi.applyColumnState({
    state: configuration.columns,
    applyOrder: true,
  });
  // Check if configuration data includes column and set visibility based on configuration
  const gridColumns = gridApi.getColumnState();

  const configurationColumnsIds = configuration.columns?.map(
    (column) => column.colId
  );
  const configurationColumns = gridColumns?.filter((col) =>
    configurationColumnsIds?.includes(col.colId)
  );

  const columns = gridColumns?.map((col) => {
    const colId = col.colId;
    const column = gridApi.getColumn(col.colId);
    const colDef = column?.getColDef();
    const initialAggFunc = colDef?.initialAggFunc ?? null;
    if (configurationColumns?.includes(col)) {
      const configurationsColDefs = configurationColumns?.find(
        (confCol) => confCol.colId === colId
      );
      return {
        colId: colId,
        aggFunc: initialAggFunc,
        hide: configurationsColDefs?.hide ?? true,
      };
    }
    return { colId: colId, aggFunc: null, hide: true };
  });

  gridApi.applyColumnState({
    state: columns,
    applyOrder: true,
  });
  if (configuration.filters) {
    gridApi.setFilterModel(configuration.filters);
  }
  gridApi.setGridOption("groupDisplayType", configuration.groupDisplayType);

  gridApi.setGridOption("pivotMode", configuration.pivot);
}
