// Generated by purs version 0.14.5
"use strict";
var Control_Category = require("../Control.Category/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var WindowUtils = require("../WindowUtils/index.js");
var useRangeSelection = function (dictOrd) {
    return function (itemList) {
        return function (initialSelection) {
            var indexOf = function (a) {
                return Data_Array.findIndex(function (v) {
                    return Data_Eq.eq(dictOrd.Eq0())(v)(a);
                })(itemList);
            };
            var idsInRange = function (idx1) {
                return function (idx2) {
                    var b = (function () {
                        var $4 = idx1 > idx2;
                        if ($4) {
                            return idx1;
                        };
                        return idx2;
                    })();
                    var a = (function () {
                        var $5 = idx1 < idx2;
                        if ($5) {
                            return idx1;
                        };
                        return idx2;
                    })();
                    return Data_Array.slice(a)(b + 1 | 0)(itemList);
                };
            };
            return function __do() {
                var v = TofuHooks.useState({
                    selected: initialSelection,
                    "lastIdx'": Data_Maybe.Nothing.value
                })();
                var toggleSelectAll = v.value1(function (s) {
                    var $7 = Data_Set.isEmpty(s.selected);
                    if ($7) {
                        return {
                            selected: Data_Set.fromFoldable(Data_Foldable.foldableArray)(dictOrd)(itemList),
                            "lastIdx'": Data_Maybe.Nothing.value
                        };
                    };
                    return {
                        selected: Data_Set.empty,
                        "lastIdx'": Data_Maybe.Nothing.value
                    };
                });
                var onClickSelection = function (id) {
                    return function (selectRange) {
                        return function __do() {
                            (function () {
                                if (selectRange) {
                                    return WindowUtils.selectionRemoveAllRanges();
                                };
                                return Data_Unit.unit;
                            })();
                            return v.value1(function (v1) {
                                var newIdx$prime = indexOf(id);
                                if (selectRange && (v1["lastIdx'"] instanceof Data_Maybe.Just && newIdx$prime instanceof Data_Maybe.Just)) {
                                    var setInRange = Data_Set.fromFoldable(Data_Foldable.foldableArray)(dictOrd)(idsInRange(v1["lastIdx'"].value0)(newIdx$prime.value0));
                                    var $13 = Data_Set.member(dictOrd)(id)(v1.selected);
                                    if ($13) {
                                        return {
                                            selected: Data_Set.difference(dictOrd)(v1.selected)(setInRange),
                                            "lastIdx'": newIdx$prime
                                        };
                                    };
                                    return {
                                        selected: Data_Set.union(dictOrd)(setInRange)(v1.selected),
                                        "lastIdx'": newIdx$prime
                                    };
                                };
                                return {
                                    selected: Data_Set.toggle(dictOrd)(id)(v1.selected),
                                    "lastIdx'": newIdx$prime
                                };
                            })();
                        };
                    };
                };
                return {
                    selectedIds: v.value0.selected,
                    onClickSelection: onClickSelection,
                    toggleSelectAll: toggleSelectAll
                };
            };
        };
    };
};
var sortByCriteria = function (criteria) {
    if (criteria.value1 instanceof Data_Maybe.Just) {
        var withOrder = (function () {
            var $21 = Data_Eq.eq(TacoTable_Types.eqSortingOrder)(criteria.value0)(TacoTable_Types.Descending.value);
            if ($21) {
                return Data_Function.flip;
            };
            return Control_Category.identity(Control_Category.categoryFn);
        })();
        var sortableValue = function (x) {
            return criteria.value2(criteria.value1.value0)(x);
        };
        return Data_Array.sortBy(function (a) {
            return function (b) {
                return withOrder(Data_Ord.compare(TacoTable_Types.ordComparableValue))(sortableValue(a))(sortableValue(b));
            };
        });
    };
    return Control_Category.identity(Control_Category.categoryFn);
};
module.exports = {
    sortByCriteria: sortByCriteria,
    useRangeSelection: useRangeSelection
};
