import {
  QueryClient,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from "react-query";
import { UsersAgGridConfiguration } from "@prisma/client";
import axios from "../../../axiosConfig";

const baseUrl = "/users-ag-grid-configurations";

export function useGetUsersAgGridConfiguration(
  agGridName: string,
  projectId?: number
): UsersAgGridConfiguration | null {
  const { data } = useQuery({
    queryKey: [baseUrl],
    queryFn: async () => {
      const url = projectId
        ? `${baseUrl}/${agGridName}/${projectId}`
        : `${baseUrl}/${agGridName}`;
      const { data } = await axios.get<UsersAgGridConfiguration>(url);
      return data;
    },
  });

  return data ?? null;
}

export function useCreateOrUpdateUsersAgGridConfiguration(): UseMutationResult<
  UsersAgGridConfiguration,
  unknown,
  {
    agGridName: string;
    agGridConfiguration: string | null;
    projectId: number | null;
  }
> {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (value) => {
      const { data } = await axios.post(baseUrl, value);
      return data;
    },
    onSettled: () => invalidateUsersAgGridConfigurations(queryClient),
  });
}

function invalidateUsersAgGridConfigurations(queryClient: QueryClient) {
  return queryClient.invalidateQueries({
    queryKey: [baseUrl],
  });
}
