var React = require("react");
var ReactDOM = require("react-dom");

exports.unsafeGetStateFromInstance = function (inst) {
  return inst.state.$$state;
};

exports.getSelectionsFromInstance = function (inst) {
  return function (className) {
    return function () {
      try {
        // eslint-disable-next-line react/no-find-dom-node
        var domNode = ReactDOM.findDOMNode(inst);
        var textarea = domNode.querySelector("." + className);
        return [textarea.selectionStart, textarea.selectionEnd];
      } catch (e) {
        console.error("Failure in getting selections", e);
        return null;
      }
    };
  };
};
exports.setSelectionsFromInstance = function (inst) {
  return function (className) {
    return function (selections) {
      return function () {
        try {
          // eslint-disable-next-line react/no-find-dom-node
          var domNode = ReactDOM.findDOMNode(inst);
          var textarea = domNode.querySelector("." + className);
          textarea.selectionStart = selections[0];
          textarea.selectionEnd = selections[1];
        } catch (e) {
          console.error("Failure in setting selections", e);
        }
      };
    };
  };
};

// exactly same as above but uses refs instead of component instances
exports.getSelectionsFromRef = function (ref) {
  return function () {
    try {
      var textarea = ref.current;
      return [textarea.selectionStart, textarea.selectionEnd];
    } catch (e) {
      console.error("Failure in getting selections", e);
      return null;
    }
  };
};
exports.setSelectionsFromRef = function (ref) {
  return function (selections) {
    return function () {
      try {
        var textarea = ref.current;
        textarea.selectionStart = selections[0];
        textarea.selectionEnd = selections[1];
      } catch (e) {
        console.error("Failure in setting selections", e);
      }
    };
  };
};

exports.unsafeRecordEq = function (r1) {
  return function (r2) {
    if (r1 === r2) {
      return true;
    } else {
      var keys = Object.keys(r1);
      for (var i = 0; i < keys.length; i++) {
        var key = keys[i];
        if (!Object.prototype.hasOwnProperty.call(r2, key)) {
          return false;
        } else if (r1[key] !== r2[key]) {
          return false;
        }
      }
    }
    return true;
  };
};

exports.cloneElement_ = (element) => (props) => {
  return React.cloneElement(element, props);
};
