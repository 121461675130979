// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var AddBuildingElementTable = require("../AddBuildingElementTable/index.js");
var Box = require("../Box/index.js");
var Client_WASM = require("../Client.WASM/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var CostEstimation_AddComponent = require("../CostEstimation.AddComponent/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_String_Pattern = require("../Data.String.Pattern/index.js");
var Effect = require("../Effect/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Class_Console = require("../Effect.Class.Console/index.js");
var Grid = require("../Grid/index.js");
var LengthValidator = require("../LengthValidator/index.js");
var Locale = require("../Locale/index.js");
var NumberValidator = require("../NumberValidator/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_DOM_Internal = require("../React.Basic.DOM.Internal/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var StateHooks = require("../StateHooks/index.js");
var StringUtils = require("../StringUtils/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoInput_Styles = require("../TacoInput.Styles/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Element = require("../Types.Element/index.js");
var Types_Estimation = require("../Types.Estimation/index.js");
var Types_Project = require("../Types.Project/index.js");
var UseForm = require("../UseForm/index.js");
var UseRefProjects = require("../UseRefProjects/index.js");
var UseSuggestionsNavigation = require("../UseSuggestionsNavigation/index.js");
var UseUpdatingState = require("../UseUpdatingState/index.js");
var component = TofuHooks.mkHookComponent("CostEstimation.BuildingElementsView.AddBuildingElement")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState(false)();
        var v2 = Client_WASM.useSubprojects(v.projectId)();
        var mFirstSubprojectId = Data_Functor.map(Data_Maybe.functorMaybe)(function (v3) {
            return v3.id;
        })(Data_Array.head(v2.value0));
        UseRefProjects.useRefProjects(v.projectId)();
        var v3 = Client_WASM.useActiveRefElements(v.projectId)();
        var activeRefElementsIds = Data_Set.fromFoldable(Data_Foldable.foldableArray)(Types_Element.ordElementId)(v3.value0);
        var v4 = Client_WASM.useReferenceEstimationElements(v.projectId)();
        var activeRefElements = Data_Array.filter(function (v5) {
            return Data_Set.member(Types_Element.ordElementId)(v5.elementId)(activeRefElementsIds);
        })(v4.value0);
        var v5 = UseUpdatingState.useUpdatingState(Data_Maybe.eqMaybe(Types_Project.eqSubprojectId))(mFirstSubprojectId)();
        var v6 = TofuHooks.useState("")();
        var v7 = TofuHooks.useState("")();
        var v8 = TofuHooks.useState("")();
        var v9 = TofuHooks.useState("")();
        var v10 = TofuHooks.useState("")();
        var v11 = TofuHooks.useState(0.0)();
        var v12 = TofuHooks.useState("")();
        var matchesRtDescription = function (v13) {
            var value = Data_String_Common.toLower(v13.rtDescription);
            var condition = Data_String_CodeUnits.contains(Data_String_Pattern.Pattern(Data_String_Common.toLower(v12.value0)))(value);
            return condition;
        };
        var matchesRtCode = function (v13) {
            var value = Data_String_Common.toLower(v13.rtCode);
            var condition = Data_String_CodeUnits.contains(Data_String_Pattern.Pattern(Data_String_Common.toLower(v12.value0)))(value);
            return condition;
        };
        var matchesDescription = function (v13) {
            var value = Data_String_Common.toLower(v13.description);
            var condition = Data_String_CodeUnits.contains(Data_String_Pattern.Pattern(Data_String_Common.toLower(v12.value0)))(value);
            return condition;
        };
        var matchesCode = function (v13) {
            var value = Data_String_Common.toLower(Data_Newtype.un()(Types_Element.ElementCode)(v13.code));
            var condition = StringUtils.startsWith(Data_String_Pattern.Pattern(Data_String_Common.toLower(v12.value0)))(value);
            return condition;
        };
        var matchingElements = (function () {
            var predicate = function (c) {
                return Data_Foldable.or(Data_Foldable.foldableArray)(Data_HeytingAlgebra.heytingAlgebraBoolean)([ matchesCode(c), matchesDescription(c), matchesRtCode(c), matchesRtDescription(c) ]);
            };
            return Data_Array.filter(predicate)(activeRefElements);
        })();
        var activeFilter = v12.value0 !== "";
        var openModal = v1.value1(function (v13) {
            return true;
        });
        var closeModal = v1.value1(function (v13) {
            return false;
        });
        var dispatch = StateHooks.useDispatch();
        var v13 = UseForm.useForm((function () {
            var actionE = Control_Bind.bind(Data_Either.bindEither)(Data_Either.note("Subproject must be defined")(v5.value0))(function (subProjectId) {
                return Control_Applicative.pure(Data_Either.applicativeEither)(new Actions.EstimationElementActionRequest({
                    projectId: v.projectId,
                    params: new Types_Estimation.AddEstimationElement({
                        subProjectId: subProjectId,
                        code: v6.value0,
                        description: v7.value0,
                        rtCode: v8.value0,
                        rtDescription: v9.value0,
                        unit: v10.value0,
                        quantity: v11.value0
                    }),
                    handler: function (res) {
                        var v13 = function (v14) {
                            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Monoid.mempty(Data_Monoid.monoidUnit));
                        };
                        if (res instanceof Data_Either.Right) {
                            var $60 = Data_Array.head(res.value0.elementIds);
                            if ($60 instanceof Data_Maybe.Just) {
                                return v.setNewElementId(function (v14) {
                                    return new Data_Maybe.Just($60.value0);
                                });
                            };
                            return v13(true);
                        };
                        return v13(true);
                    }
                }));
            });
            if (actionE instanceof Data_Either.Right) {
                return function __do() {
                    dispatch(actionE.value0)();
                    return closeModal();
                };
            };
            if (actionE instanceof Data_Either.Left) {
                return Effect_Class_Console.error(Effect_Class.monadEffectEffect)(actionE.value0);
            };
            throw new Error("Failed pattern match at CostEstimation.BuildingElementsView.AddBuildingElement (line 138, column 5 - line 142, column 24): " + [ actionE.constructor.name ]);
        })())();
        var newElementInputs = Grid.grid([ new Box.Gap(TacoConstants.M.value), Box.AlignStart.value, Box.MaxWidth.create(new TacoConstants.CustomSize("800px")) ])([ [ Grid.Flex.create(1)(CostEstimation_AddComponent.mkSubprojectInput({
            subprojects: v2.value0,
            disabled: false,
            label: new Data_Maybe.Just("Alakohde"),
            currentId: v5.value0,
            onChange: function (id) {
                return v5.value1(function (v14) {
                    return new Data_Maybe.Just(id);
                });
            },
            onFocus: Control_Applicative.pure(Effect.applicativeEffect)(Data_Monoid.mempty(Data_Monoid.monoidUnit)),
            onBlur: Control_Applicative.pure(Effect.applicativeEffect)(Data_Monoid.mempty(Data_Monoid.monoidUnit)),
            autoFocus: false,
            rendering: TacoInput_Styles.DefaultRendering.value,
            testId: "new-element"
        })(Data_Maybe.Nothing.value)), Grid.Flex.create(1)(TacoInput.stringInput()()(Data_Eq.eqString)({
            value: v6.value0,
            label: "Luokka",
            validator: LengthValidator.lengthValidator(1)(50),
            onValidationChange: v13.updateFieldValidity("code"),
            error: v13.isFieldError("code"),
            onChange: v6.value1,
            testId: "new-element-code"
        })), Grid.Flex.create(1)(TacoInput.stringInput()()(Data_Eq.eqString)({
            value: v7.value0,
            label: "Luokan nimi",
            validator: LengthValidator.lengthValidator(1)(50),
            onValidationChange: v13.updateFieldValidity("description"),
            error: v13.isFieldError("description"),
            onChange: v7.value1,
            testId: "new-element-description"
        })), Grid.Flex.create(1)(TacoInput.stringInput()()(Data_Eq.eqString)({
            value: v8.value0,
            label: "Koodi",
            validator: LengthValidator.lengthValidator(1)(50),
            onValidationChange: v13.updateFieldValidity("rtCode"),
            error: v13.isFieldError("rtCode"),
            onChange: v8.value1,
            testId: "new-element-rt-code"
        })), Grid.Flex.create(3)(TacoInput.stringInput()()(Data_Eq.eqString)({
            value: v9.value0,
            label: "Selite",
            validator: LengthValidator.lengthValidator(1)(150),
            onValidationChange: v13.updateFieldValidity("rtDescription"),
            error: v13.isFieldError("rtDescription"),
            onChange: v9.value1,
            testId: "new-element-rt-description"
        })), Grid.Flex.create(1)(TacoInput.numberInput()()(Data_Eq.eqNumber)({
            value: v11.value0,
            label: "M\xe4\xe4r\xe4",
            precision: 2,
            validator: NumberValidator.numberValidator,
            onValidationChange: v13.updateFieldValidity("quantity"),
            error: v13.isFieldError("quantity"),
            onChange: v11.value1,
            testId: "new-element-quantity"
        })), Grid.Flex.create(1)(TacoInput.stringInput()()(Data_Eq.eqString)({
            value: v10.value0,
            label: "Yksikk\xf6",
            validator: LengthValidator.lengthValidator(0)(10),
            onValidationChange: v13.updateFieldValidity("unit"),
            error: v13.isFieldError("unit"),
            onChange: v10.value1,
            testId: "new-element-unit"
        })) ] ]);
        var matchingElementIds = Data_String_Common.joinWith(",")(Data_Functor.map(Data_Functor.functorArray)(function (v14) {
            return Data_Show.show(Types_Element.showElementId)(v14.elementId);
        })(matchingElements));
        var addRefElement = function (v14) {
            var actionE = Control_Bind.bind(Data_Either.bindEither)(Data_Either.note("Subprojects must be defined")(v5.value0))(function (subProjectId) {
                return Control_Applicative.pure(Data_Either.applicativeEither)(new Actions.EstimationElementActionRequest({
                    projectId: v.projectId,
                    params: new Types_Estimation.CopyEstimationElementsToProject({
                        estimationElementIds: [ v14.elementId ],
                        targetSubprojectId: subProjectId,
                        targetProjectId: v.projectId,
                        copyOperationType: new Types_Estimation.CopyElementWithComponents({
                            addWildcardLocation: true,
                            copyResources: true
                        })
                    }),
                    handler: function (res) {
                        var v15 = function (v16) {
                            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Monoid.mempty(Data_Monoid.monoidUnit));
                        };
                        if (res instanceof Data_Either.Right) {
                            var $71 = Data_Array.head(res.value0.elementIds);
                            if ($71 instanceof Data_Maybe.Just) {
                                return v.setNewElementId(function (v16) {
                                    return new Data_Maybe.Just($71.value0);
                                });
                            };
                            return v15(true);
                        };
                        return v15(true);
                    }
                }));
            });
            if (actionE instanceof Data_Either.Right) {
                return function __do() {
                    dispatch(actionE.value0)();
                    return closeModal();
                };
            };
            if (actionE instanceof Data_Either.Left) {
                return Effect_Class_Console.error(Effect_Class.monadEffectEffect)(actionE.value0);
            };
            throw new Error("Failed pattern match at CostEstimation.BuildingElementsView.AddBuildingElement (line 237, column 7 - line 241, column 30): " + [ actionE.constructor.name ]);
        };
        var v14 = UseSuggestionsNavigation.useSuggestionsNavigation({
            itemIds: matchingElementIds,
            items: matchingElements,
            modalOpen: v1.value0,
            activeFilter: activeFilter,
            onSelect: addRefElement
        })();
        TofuHooks.useLayoutEffect([ v1.value0 ])(function __do() {
            Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(!v1.value0)(function __do() {
                v14.setSelectedItemIdx(function (v15) {
                    return Data_Maybe.Nothing.value;
                })();
                v5.value1(function (v15) {
                    return mFirstSubprojectId;
                })();
                v6.value1(function (v15) {
                    return "";
                })();
                v7.value1(function (v15) {
                    return "";
                })();
                v8.value1(function (v15) {
                    return "";
                })();
                v9.value1(function (v15) {
                    return "";
                })();
                v10.value1(function (v15) {
                    return "";
                })();
                v11.value1(function (v15) {
                    return 0.0;
                })();
                return v12.value1(function (v15) {
                    return "";
                })();
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        })();
        var refElementsTable = AddBuildingElementTable.addElementTable({
            elements: matchingElements,
            addRefElement: addRefElement,
            selectedRefElementIdx: v14.selectedItemIdx
        });
        var modalActions = TacoModal.modalActions({
            contents: [ TacoButton.component()()({
                onClick: new Data_Maybe.Just(closeModal),
                text: "Peruuta",
                color: TacoConstants.SecondaryRed.value,
                testId: "add-building-element-cancel-button"
            }), TacoButton.component()()({
                submit: true,
                text: "Tallenna",
                testId: "add-building-element-submit-button"
            }) ]
        });
        var elementFilter = TacoInput.stringInput()()(Data_Eq.eqString)({
            value: v12.value0,
            onChange: v12.value1,
            placeholder: "Suodata rakenteita",
            autoFocus: true,
            width: Data_Maybe.Just.create(new TacoConstants.CustomSize("16rem")),
            testId: "new-element-filter-input"
        });
        var modalBody = TacoModal.modalBody({
            contents: [ TacoText.component()()({
                text: "Rakenteen tiedot",
                weight: TacoText_Types.Bold.value
            }), newElementInputs, TacoText.component()()({
                text: "Vastaavat viitehankkeiden rakenteet",
                weight: TacoText_Types.Bold.value
            }), elementFilter, refElementsTable ]
        });
        var modal = TacoModal.component()()({
            contents: Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM_Generated.form()({
                onSubmit: v13.onSubmit,
                ref: v13.formRef,
                style: React_Basic_DOM_Internal.css({
                    height: React_Basic_Emotion.str("100%")
                }),
                children: [ modalBody, modalActions ]
            })),
            onClose: closeModal,
            isActive: v1.value0,
            heading: "Lis\xe4\xe4 rakenne hankkeelle",
            containerStyles: [ new Box.Width(new TacoConstants.CustomSize("90vw")), new Box.Height(new TacoConstants.CustomSize("90vh")) ]
        });
        return React_Basic.fragment([ TacoButton.component()()({
            onClick: new Data_Maybe.Just(openModal),
            text: Locale.lookup_("new_element"),
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconAddPlusV1.value),
            color: TacoConstants.elementActionColors.border,
            buttonStyle: TacoButton_Types.Filled.value,
            size: TacoConstants.L.value,
            testId: "new-building-element-button"
        }), modal ]);
    };
});
module.exports = {
    component: component
};
