// Generated by purs version 0.14.5
"use strict";
var Data_Array = require("../Data.Array/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var mkClassName = (function () {
    var $0 = Data_String_Common.joinWith(" ");
    return function ($1) {
        return $0(Data_Array.catMaybes($1));
    };
})();
module.exports = {
    mkClassName: mkClassName
};
