// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var targetSumsStyles = Box.boxStyle([ Box.FlexRow.value, Box.JustifyEnd.value, new Box.MarginTop(TacoConstants.M.value) ]);
var componentsTabActionsStyles = Box.boxStyle([ new Box.MarginBottom(TacoConstants.M.value) ]);
module.exports = {
    targetSumsStyles: targetSumsStyles,
    componentsTabActionsStyles: componentsTabActionsStyles
};
