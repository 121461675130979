import { useCallback } from "react";
import { cloneDeep, last } from "lodash";
import {
  TransportFileData,
  TransportFileFormData,
} from "./TransportFileUploadModal";
import { Measurement } from "ts-bindings/Measurement";
import { CostClass } from "ts-bindings/CostClass";
import { Subproject } from "../../../../../../ts-bindings/Subproject";
import {
  NewCostClass,
  NewMeasurement,
  NewMeasurementValue,
  NewSubproject,
} from "../../../../../server-ts/import-file/transportFile";

// Callback which handles the file change event by reading the file
// and populating the form with the file's data.
export function useFileChange(
  populateCostClasses: (costClasses: CostClass[]) => void,
  populateFileCostClasses: (costClasses: CostClass[]) => void,
  populateMeasurementUnits: (measurementUnits: Measurement[]) => void,
  populateSubprojects: (subprojects: Subproject[]) => void,
  onFileChange: (files: File[]) => void,
  resetForm: (values: TransportFileFormData) => void,
  setVersion: (code: string) => void
): (transportFiles: File[]) => Promise<void> {
  return useCallback(
    async (transportFiles: File[]) => {
      const newestFile = last(transportFiles);
      if (!newestFile) {
        onFileChange([]);
        return;
      }

      onFileChange([newestFile]);
      const metadata = await readTransportFileMetadata(newestFile);
      const {
        newCostClasses,
        newMeasurements,
        newMeasurementValues,
        newSubprojects,
        ...rest
      } = metadata;

      const mappedCostClasses = mapCostClasses(newCostClasses);
      populateCostClasses(cloneDeep(mappedCostClasses));
      populateFileCostClasses(cloneDeep(mappedCostClasses));

      populateMeasurementUnits(
        mapMeasurements(newMeasurements, newMeasurementValues)
      );
      populateSubprojects(mapSubprojects(newSubprojects));

      resetForm({
        ...rest,
        version: "",
      });
      setVersion(rest.code);
    },
    [
      setVersion,
      onFileChange,
      populateCostClasses,
      populateFileCostClasses,
      populateMeasurementUnits,
      populateSubprojects,
      resetForm,
    ]
  );
}

function mapCostClasses(costClasses: NewCostClass[]): CostClass[] {
  return costClasses.map((costClass) => ({
    id: costClass.ID,
    name: costClass.Selite,
    costClassCode: costClass.Tunnus,
    projectId: costClass.Projekti_ID ?? 0,
    // NewCostClass percentages are percentages, CostClass percentages are decimals
    socialExpensePercentageInCostEstimation: costClass.SosiaaliKuluPros
      ? costClass.SosiaaliKuluPros / 100
      : null,
    socialExpensePercentageInCostControlForComponents: costClass.TakSosiaaliKuluPros
      ? costClass.TakSosiaaliKuluPros / 100
      : null,
    socialExpensePercentageInCostControlForEstimatesAndActuals: costClass.TavSosiaaliKuluPros
      ? costClass.TavSosiaaliKuluPros / 100
      : null,
    socialExpensePercentageInCostControlForTargets: costClass.TarkkailuSosiaalikulu
      ? costClass.TarkkailuSosiaalikulu / 100
      : null,
    multiplier: costClass.Kerroin,
    hourlyPricing: costClass.TyoKasittely !== 0,
  }));
}

function mapMeasurements(
  measurements: NewMeasurement[],
  measurementValues: NewMeasurementValue[]
): Measurement[] {
  return measurements.map((measurement) => ({
    unit: {
      id: Number(measurement.ID),
      name: measurement.Selite,
      unit: measurement.Tunnus,
      projectId: measurement.Projekti_ID ?? 0,
      mandatory: measurement.Pakollinen !== 0,
    },
    values: measurementValues
      .filter((value) => value.LaajuusTieto_ID === Number(measurement.ID))
      .map((value) => ({
        id: Number(value.ID),
        measurementId: Number(value.LaajuusTieto_ID),
        subprojectId: value.AlaKohde_ID,
        value: value.Arvo ?? 0,
      })),
  }));
}

function mapSubprojects(subprojects: NewSubproject[]): Subproject[] {
  return subprojects.map((subproject) => ({
    id: subproject.ID,
    name: subproject.Nimi,
    code: subproject.Tunnus,
    projectId: subproject.Projekti_ID,
    countInMeasurementTotal: subproject.MukanaLaajuusTiedoissa !== 0,
  }));
}

export async function readTransportFileMetadata(
  file: File
): Promise<Omit<TransportFileData, "version">> {
  if (!file.name.endsWith(".tcm")) {
    throw new Error("Filename does not end with .tcm");
  }

  const result = await file.text();
  const jsonResult = JSON.parse(result);
  const name = jsonResult.srcProjectDetails.Nimi;
  const code = jsonResult.srcProjectDetails.Projektitunnus;
  const projectGroup = jsonResult.srcProjectDetails.YleProjektiRyhma_ID;
  const classification = jsonResult.srcProjectDetails.YleNimikkeisto_ID;
  const newCostClasses = jsonResult.srcCostClasses;
  const newMeasurements = jsonResult.srcMeasurements;
  const newMeasurementValues = jsonResult.srcMeasurementValues;
  const newSubprojects = jsonResult.srcSubprojects;
  const currency = jsonResult.srcProjectDetails.OletusValuutta;

  return {
    name,
    code,
    projectGroup,
    classification,
    newCostClasses,
    newMeasurements,
    newMeasurementValues,
    newSubprojects,
    currency,
    fileStr: result,
  };
}
