// Generated by purs version 0.14.5
"use strict";
var Common = require("../Common/index.js");
var Components_Utils = require("../Components.Utils/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var Record = require("../Record/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoText_Styles = require("../TacoText.Styles/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var defaultProps = {
    text: "",
    children: Common.emptyArray,
    weight: TacoText_Types.Normal.value,
    size: TacoConstants.M.value,
    transform: TacoText_Types.None.value,
    gutterBottom: false,
    variant: TacoText_Types.Text.value,
    align: TacoText_Types.Left.value,
    color: TacoConstants.TextPrimary.value,
    backgroundColor: TacoConstants.Transparent.value,
    inheritColor: false,
    wrap: TacoText_Types.NormalWrap.value,
    overflow: TacoText_Types.TextOverflowEllipsis.value,
    testId: "",
    title: ""
};
var component = function (dictUnion) {
    return function (dictNub) {
        return TofuHooks.mkHookComponent("TacoText")(function (props) {
            var v = Record.merge()()(props)(defaultProps);
            var elementProps = {
                className: "__TacoText",
                css: TacoText_Styles.textStyles({
                    weight: v.weight,
                    size: v.size,
                    transform: v.transform,
                    gutter: v.gutterBottom,
                    align: v.align,
                    color: (function () {
                        if (v.inheritColor) {
                            return "inherit";
                        };
                        return TacoConstants.colorSchemeToStr(v.color);
                    })(),
                    backgroundColor: TacoConstants.colorSchemeToStr(v.backgroundColor),
                    variant: v.variant,
                    wrap: v.wrap,
                    overflow: v.overflow
                }),
                children: Data_Semigroup.append(Data_Semigroup.semigroupArray)([ React_Basic_DOM.text(v.text) ])(v.children),
                "_data": Components_Utils.mkTestIdAttrs(v.testId),
                title: v.title
            };
            var element = (function () {
                if (v.variant instanceof TacoText_Types.Text) {
                    return React_Basic_Emotion.element(React_Basic_DOM_Generated["span'"]())(elementProps);
                };
                if (v.variant instanceof TacoText_Types.Paragraph) {
                    return React_Basic_Emotion.element(React_Basic_DOM_Generated["p'"]())(elementProps);
                };
                if (v.variant instanceof TacoText_Types.Heading && v.variant.value0 === 1) {
                    return React_Basic_Emotion.element(React_Basic_DOM_Generated["h1'"]())(elementProps);
                };
                if (v.variant instanceof TacoText_Types.Heading && v.variant.value0 === 2) {
                    return React_Basic_Emotion.element(React_Basic_DOM_Generated["h2'"]())(elementProps);
                };
                if (v.variant instanceof TacoText_Types.Heading && v.variant.value0 === 3) {
                    return React_Basic_Emotion.element(React_Basic_DOM_Generated["h3'"]())(elementProps);
                };
                if (v.variant instanceof TacoText_Types.Heading && v.variant.value0 === 4) {
                    return React_Basic_Emotion.element(React_Basic_DOM_Generated["h4'"]())(elementProps);
                };
                if (v.variant instanceof TacoText_Types.Heading && v.variant.value0 === 5) {
                    return React_Basic_Emotion.element(React_Basic_DOM_Generated["h5'"]())(elementProps);
                };
                if (v.variant instanceof TacoText_Types.Heading && v.variant.value0 === 6) {
                    return React_Basic_Emotion.element(React_Basic_DOM_Generated["h6'"]())(elementProps);
                };
                if (v.variant instanceof TacoText_Types.Heading) {
                    return React_Basic_Emotion.element(React_Basic_DOM_Generated["h1'"]())(elementProps);
                };
                if (v.variant instanceof TacoText_Types.Label) {
                    return React_Basic_Emotion.element(React_Basic_DOM_Generated["label'"]())(Record.merge()()({
                        htmlFor: v.variant.value0
                    })(elementProps));
                };
                throw new Error("Failed pattern match at TacoText (line 98, column 15 - line 108, column 82): " + [ v.variant.constructor.name ]);
            })();
            return Control_Applicative.pure(Effect.applicativeEffect)(element);
        });
    };
};
module.exports = {
    component: component
};
