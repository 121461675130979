// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Common = require("../Common/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var Utils = require("../Utils/index.js");
var toNPrecision = function (v) {
    return $foreign["_toFixed"](v);
};
var toCurrencyPrecision = $foreign["_toFixed"](2);
var toAmountPrecision = $foreign["_toFixed"](1);
var resourceUnitConsumptionRate = function (component) {
    return function (resource) {
        return function (newResourceAmount) {
            if (resource.isCostClassPrice) {
                return 1.0;
            };
            return Utils.defaultZeroDiv(newResourceAmount)((1.0 + resource.wastePercentage * 1.0e-2) * component.amount);
        };
    };
};
var resourceTotalHours = function (component) {
    return function (resource) {
        return resource.unitConsumptionRate * (1.0 + resource.wastePercentage * 1.0e-2) * component.amount;
    };
};
var lookupSocialExpensePercentage = function (socialExpensePercentages) {
    return function (code) {
        return Data_Maybe.maybe(0.0)((function () {
            var $15 = Data_Newtype.un()(Types_Unit.PercentageOfZeroToOne);
            var $16 = Data_Newtype.un()(Types_Unit.SocialExpensePercentage);
            return function ($17) {
                return $15($16($17));
            };
        })())(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Data_Function.flip(Data_Map_Internal.lookup(Types_Newtypes.ordCostClassCode))(socialExpensePercentages))(Data_Nullable.toMaybe(code)));
    };
};
var resourceSocialExpensePercentage = function (socialExpensePercentages) {
    return function (resource) {
        return lookupSocialExpensePercentage(socialExpensePercentages)(resource.costClassCode);
    };
};
var resourcePricePerUnit = function (socialExpensePercentages) {
    return function (resource) {
        var socExpPercentage = resourceSocialExpensePercentage(socialExpensePercentages)(resource);
        var pricePerUnit = Utils.fromNullable(0.0)(resource.pricePerUnit);
        return pricePerUnit * (1.0 + socExpPercentage) * (1.0 - resource.discountPercentage * 1.0e-2);
    };
};
var resourcePricePerUnitWithoutDiscountPercentage = function (socialExpensePercentages) {
    return function (resource) {
        var socExpPercentage = resourceSocialExpensePercentage(socialExpensePercentages)(resource);
        var pricePerUnit = Utils.fromNullable(0.0)(resource.pricePerUnit);
        return pricePerUnit * (1.0 + socExpPercentage);
    };
};
var resourcePriceWithDiscount = function (socialExpensePercentages) {
    return function (resource) {
        var socExpPercentage = resourceSocialExpensePercentage(socialExpensePercentages)(resource);
        return resource.priceWithDiscount * (1.0 + socExpPercentage);
    };
};
var resourceSocialExpensePricePerUnit = function (socialExpensePercentages) {
    return function (resource) {
        var socExpPercentage = resourceSocialExpensePercentage(socialExpensePercentages)(resource);
        var pricePerUnit = Utils.fromNullable(0.0)(resource.pricePerUnit);
        return pricePerUnit * socExpPercentage * (1.0 - resource.discountPercentage * 1.0e-2);
    };
};
var solveForBaseResourcePricePerUnit = function (socialExpensePercentages) {
    return function (resource) {
        return function (input) {
            var socExpPercentage = resourceSocialExpensePercentage(socialExpensePercentages)(resource);
            return Utils.defaultZeroDiv(input)((1.0 + socExpPercentage) * (1.0 - resource.discountPercentage * 1.0e-2));
        };
    };
};
var solveForBaseResourcePricePerUnitWithoutDiscountPercentage = function (socialExpensePercentages) {
    return function (resource) {
        return function (input) {
            var socExpPercentage = resourceSocialExpensePercentage(socialExpensePercentages)(resource);
            return Utils.defaultZeroDiv(input)(1.0 + socExpPercentage);
        };
    };
};
var isHourlyPricedResource = function (hourly_pricing_cost_class_codes) {
    return function (res) {
        var v = Data_Nullable.toMaybe(res.costClassCode);
        if (v instanceof Data_Maybe.Nothing) {
            return false;
        };
        if (v instanceof Data_Maybe.Just) {
            return Data_Array.elem(Types_Newtypes.eqCostClassCode)(v.value0)(hourly_pricing_cost_class_codes);
        };
        throw new Error("Failed pattern match at CostEstimation.Calculations (line 39, column 62 - line 41, column 63): " + [ v.constructor.name ]);
    };
};
var filterEstimationResources = function (estimationComponent) {
    return function (estimationResources) {
        return Data_Maybe.fromMaybe(Common.emptyArray)(Data_Map_Internal.lookup(Types_Component.ordComponentId)(estimationComponent.id)(estimationResources));
    };
};
var extractClassificationGroupCodes = function (v) {
    return Data_Nullable.toMaybe(v.component.group);
};
var consumptionRate = function (resource) {
    if (resource.isCostClassPrice) {
        var v = Data_Nullable.toMaybe(resource.pricePerUnit);
        if (v instanceof Data_Maybe.Just && v.value0 !== 0.0) {
            return 1.0;
        };
        return 0.0;
    };
    return resource.unitConsumptionRate;
};
var resourceUnitPrice = function (socialExpensePercentages) {
    return function (resource) {
        var pricePerUnit = resourcePricePerUnit(socialExpensePercentages)(resource);
        return consumptionRate(resource) * (1.0 + resource.wastePercentage * 1.0e-2) * pricePerUnit;
    };
};
var resourceUnitSocialExpensePrice = function (socialExpensePercentages) {
    return function (resource) {
        var pricePerUnit = resourceSocialExpensePricePerUnit(socialExpensePercentages)(resource);
        return consumptionRate(resource) * (1.0 + resource.wastePercentage * 1.0e-2) * pricePerUnit;
    };
};
var solveForUnitPriceFromPricePerComponent = function (sep) {
    return function (resource) {
        return function (pricePerComponent) {
            var wastePercentage = 1.0 + resource.wastePercentage * 1.0e-2;
            var socialExpensePercentage = 1.0 + resourceSocialExpensePercentage(sep)(resource);
            var resourceConsumptionRate = consumptionRate(resource);
            var discountPercentage = 1.0 - resource.discountPercentage * 1.0e-2;
            return Utils.defaultZeroDiv(pricePerComponent)(wastePercentage * discountPercentage * socialExpensePercentage * resourceConsumptionRate);
        };
    };
};
var componentUnitPrice = function (sep) {
    var $18 = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber);
    var $19 = Data_Functor.map(Data_Functor.functorArray)(resourceUnitPrice(sep));
    return function ($20) {
        return $18($19($20));
    };
};
var componentTotalHours = function (component) {
    return function (resources) {
        return function (hourly_pricing_cost_class_codes) {
            var filtered = Data_Array.filter(isHourlyPricedResource(hourly_pricing_cost_class_codes))(resources);
            return Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(resourceTotalHours(component))(filtered));
        };
    };
};
var componentCalculationAmount = function (component) {
    return component.amount;
};
var resourceSocialExpensePrice = function (sep) {
    return function (component) {
        return function (resource) {
            return resourceUnitSocialExpensePrice(sep)(resource) * componentCalculationAmount(component);
        };
    };
};
var componentSocialExpensePrice = function (sep) {
    return function (component) {
        return function (resources) {
            return Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(resourceSocialExpensePrice(sep)(component))(resources));
        };
    };
};
var resourceTotalAmount = function (component) {
    return function (resource) {
        return consumptionRate(resource) * (1.0 + resource.wastePercentage * 1.0e-2) * componentCalculationAmount(component);
    };
};
var componentTotalAmount = function (component) {
    return function (resources) {
        return Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(resourceTotalAmount(component))(resources));
    };
};
var resourceTotalPrice = function (sep) {
    return function (component) {
        return function (resource) {
            return resourceUnitPrice(sep)(resource) * componentCalculationAmount(component);
        };
    };
};
var componentTotalPrice = function (sep) {
    return function (component) {
        return function (resources) {
            return Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(resourceTotalPrice(sep)(component))(resources));
        };
    };
};
var solveForUnitPriceFromTotalPrice = function (sep) {
    return function (component) {
        return function (resource) {
            return function (totalPrice) {
                var wastePercentage = 1.0 + resource.wastePercentage * 1.0e-2;
                var socialExpensePercentage = 1.0 + resourceSocialExpensePercentage(sep)(resource);
                var resourceConsumptionRate = consumptionRate(resource);
                var discountPercentage = 1.0 - resource.discountPercentage * 1.0e-2;
                var componentAmount = componentCalculationAmount(component);
                return Utils.defaultZeroDiv(totalPrice)(wastePercentage * discountPercentage * socialExpensePercentage * resourceConsumptionRate * componentAmount);
            };
        };
    };
};
var bundleEstimationResources = function (resources) {
    var toTuple = function (group) {
        var key = Data_Nullable.toMaybe((Data_Array_NonEmpty.head(group)).estimationComponentId);
        return Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(key)(function (key$prime) {
            return new Data_Tuple.Tuple(key$prime, Data_Array_NonEmpty.toArray(group));
        });
    };
    var sorted = Data_Array.sortBy(Data_Function.on(Data_Ord.compare(Data_Nullable.ordNullable(Types_Component.ordComponentId)))(function (v) {
        return v.estimationComponentId;
    }))(resources);
    var tuples = Data_Array.mapMaybe(toTuple)(Data_Array.groupBy(Data_Function.on(Data_Eq.eq(Data_Nullable.eqNullable(Types_Component.eqComponentId)))(function (v) {
        return v.estimationComponentId;
    }))(sorted));
    return Data_Map_Internal.fromFoldable(Types_Component.ordComponentId)(Data_Foldable.foldableArray)(tuples);
};
var mkResourcesByComponents = function (components) {
    return function (resources) {
        var bundled = bundleEstimationResources(resources);
        return Data_Functor.mapFlipped(Data_Functor.functorArray)(components)(function (component) {
            return {
                component: component,
                resources: filterEstimationResources(component)(bundled)
            };
        });
    };
};
module.exports = {
    componentUnitPrice: componentUnitPrice,
    componentTotalPrice: componentTotalPrice,
    componentTotalHours: componentTotalHours,
    isHourlyPricedResource: isHourlyPricedResource,
    resourceTotalHours: resourceTotalHours,
    componentTotalAmount: componentTotalAmount,
    componentSocialExpensePrice: componentSocialExpensePrice,
    resourceTotalPrice: resourceTotalPrice,
    resourceSocialExpensePrice: resourceSocialExpensePrice,
    solveForUnitPriceFromTotalPrice: solveForUnitPriceFromTotalPrice,
    resourceUnitSocialExpensePrice: resourceUnitSocialExpensePrice,
    resourceUnitPrice: resourceUnitPrice,
    resourcePricePerUnitWithoutDiscountPercentage: resourcePricePerUnitWithoutDiscountPercentage,
    resourcePriceWithDiscount: resourcePriceWithDiscount,
    resourcePricePerUnit: resourcePricePerUnit,
    resourceSocialExpensePricePerUnit: resourceSocialExpensePricePerUnit,
    solveForBaseResourcePricePerUnit: solveForBaseResourcePricePerUnit,
    solveForBaseResourcePricePerUnitWithoutDiscountPercentage: solveForBaseResourcePricePerUnitWithoutDiscountPercentage,
    solveForUnitPriceFromPricePerComponent: solveForUnitPriceFromPricePerComponent,
    resourceSocialExpensePercentage: resourceSocialExpensePercentage,
    lookupSocialExpensePercentage: lookupSocialExpensePercentage,
    resourceTotalAmount: resourceTotalAmount,
    resourceUnitConsumptionRate: resourceUnitConsumptionRate,
    consumptionRate: consumptionRate,
    componentCalculationAmount: componentCalculationAmount,
    toCurrencyPrecision: toCurrencyPrecision,
    toAmountPrecision: toAmountPrecision,
    toNPrecision: toNPrecision,
    bundleEstimationResources: bundleEstimationResources,
    filterEstimationResources: filterEstimationResources,
    extractClassificationGroupCodes: extractClassificationGroupCodes,
    mkResourcesByComponents: mkResourcesByComponents,
    "_toFixed": $foreign["_toFixed"]
};
