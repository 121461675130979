import { WorkerRequest } from "../../../ts-bindings/WorkerRequest";
import { mkWorkerAction } from "./state";
import { SetSuperAdminParams } from "../../../ts-bindings/SetSuperAdminParams";
import { SuperAdminAction } from "../../../ts-bindings/SuperAdminAction";
import { DeleteUserParams } from "../../../ts-bindings/DeleteUserParams";
import { SetErrorMsgParams } from "../../../ts-bindings/SetErrorMsgParams";
import { SuperAdminAddUsersParams } from "../../../ts-bindings/SuperAdminAddUsersParams";
import { SuperAdminEditUsersParams } from "../../../ts-bindings/SuperAdminEditUsersParams";
import { SuperAdminOrgOptionalParams } from "../../../ts-bindings/SuperAdminOrgOptionalParams";
import { SuperAdminOrgParams } from "../../../ts-bindings/SuperAdminOrgParams";

export const mkSuperAdminUpdateAction = (
  action: SuperAdminAction
): WorkerRequest =>
  mkWorkerAction({
    type: "SuperAdminUpdate",
    value: {
      action,
    },
  });

export const mkSetSuperAdminAction = (
  params: SetSuperAdminParams
): WorkerRequest =>
  mkSuperAdminUpdateAction({
    type: "SetSuperAdmin",
    value: params,
  });

export const mkSuperAdminDeleteUserAction = (
  params: DeleteUserParams
): WorkerRequest =>
  mkSuperAdminUpdateAction({
    type: "SuperAdminDeleteUsers",
    value: params,
  });

export const mkSetErrorMessage = (params: SetErrorMsgParams): WorkerRequest =>
  mkSuperAdminUpdateAction({
    type: "SetErrorMsg",
    value: params,
  });

export const mkSuperAdminAddUsers = (
  params: SuperAdminAddUsersParams
): WorkerRequest =>
  mkSuperAdminUpdateAction({
    type: "SuperAdminAddUsers",
    value: params,
  });

export const mkSuperAdminEditUser = (
  params: SuperAdminEditUsersParams
): WorkerRequest =>
  mkSuperAdminUpdateAction({
    type: "SuperAdminEditUsersOrgs",
    value: params,
  });

export const mkSuperAdminAddOrg = (
  params: SuperAdminOrgParams
): WorkerRequest =>
  mkSuperAdminUpdateAction({
    type: "SuperAdminAddOrg",
    value: params,
  });

export const mkSuperAdminEditOrg = (
  params: SuperAdminOrgOptionalParams
): WorkerRequest =>
  mkSuperAdminUpdateAction({
    type: "SuperAdminEditOrg",
    value: params,
  });
