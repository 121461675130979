import { PlanningCostGroupData } from "../../../../../../ts-bindings/PlanningCostGroupData";
import { TargetAssessmentLetter } from "@tocoman/adminet-openapi-client";
import { AdminetParams } from "../../../../../../ts-bindings/AdminetParams";
import { mapAdminetParamsToTargetAmount } from "./AdminetParamsToInvalidTargetAmountMapper";

type CostGroupUnitAndAmount = {
  unit: string | null;
  amount: number | null;
};

export const mapUnitsAndAmountToComponentCostGroups = (
  adminetParams: AdminetParams | undefined,
  adminetResourcesEnabled: boolean
): PlanningCostGroupData[] => {
  if (!adminetParams) {
    return [];
  }
  const targetAssessment = mapAdminetParamsToTargetAmount(
    adminetParams,
    adminetResourcesEnabled
  );

  return adminetParams.componentCostGroups.map((data) => {
    const found = targetAssessment.letters?.find(
      (letter) => letter.letterCode === data.costGroup.code
    );
    if (!found) {
      return {
        ...data,
      };
    }
    const { unit, amount } = findCostGroupUnitAndAmount(found);
    return {
      ...data,
      costGroup: {
        ...data.costGroup,
        unit,
        amount,
      },
    };
  });
};

const findCostGroupUnitAndAmount = (
  letter: TargetAssessmentLetter
): CostGroupUnitAndAmount => {
  const unitAndAmount: CostGroupUnitAndAmount = {
    unit: letter.components[0].unit ?? null,
    amount: 0,
  };

  for (const component of letter.components) {
    if (component.unit !== unitAndAmount.unit) {
      return {
        unit: null,
        amount: null,
      };
    }
    if (!unitAndAmount.amount) {
      unitAndAmount.amount = 0;
    }
    unitAndAmount.amount += component.quantity;
  }
  return unitAndAmount;
};
