// Generated by purs version 0.14.5
"use strict";
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Kishimen = require("../Kishimen/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_Alert = require("../Types.Alert/index.js");
var BundledWorkerStateUpdate = (function () {
    function BundledWorkerStateUpdate(value0) {
        this.value0 = value0;
    };
    BundledWorkerStateUpdate.create = function (value0) {
        return new BundledWorkerStateUpdate(value0);
    };
    return BundledWorkerStateUpdate;
})();
var UiNotification = (function () {
    function UiNotification(value0) {
        this.value0 = value0;
    };
    UiNotification.create = function (value0) {
        return new UiNotification(value0);
    };
    return UiNotification;
})();
var genericWorkerResponse = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new BundledWorkerStateUpdate(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return new UiNotification(x.value0);
        };
        throw new Error("Failed pattern match at WasmWorker.WorkerResponse (line 18, column 1 - line 18, column 66): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof BundledWorkerStateUpdate) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof UiNotification) {
            return new Data_Generic_Rep.Inr(x.value0);
        };
        throw new Error("Failed pattern match at WasmWorker.WorkerResponse (line 18, column 1 - line 18, column 66): " + [ x.constructor.name ]);
    }
};
var tofuJSONWorkerResponse = {
    writeImpl: (function () {
        var $9 = TofuJSON.writeImpl(TofuJSON.tofuJSONVariant()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "BundledWorkerStateUpdate";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "changed";
            }
        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "node";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "type";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "value";
            }
        })(TofuJSON.tofuJSONForeign)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "type";
            }
        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "value";
            }
        })(TofuJSON.tofuJSONForeign)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "node";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "type";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "value";
            }
        })(TofuJSON.tofuJSONForeign)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "type";
            }
        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "value";
            }
        })(TofuJSON.tofuJSONForeign)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.nilWriteForeignFields)()()())))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "changed";
            }
        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "node";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "type";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "value";
            }
        })(TofuJSON.tofuJSONForeign)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "type";
            }
        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "value";
            }
        })(TofuJSON.tofuJSONForeign)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "node";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "type";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "value";
            }
        })(TofuJSON.tofuJSONForeign)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "type";
            }
        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "value";
            }
        })(TofuJSON.tofuJSONForeign)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.nilWriteForeignFields)()()())))(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "UiNotification";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "alert_level";
            }
        })(Types_Alert.tofuJSONAlertLevel)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "msg";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "alert_level";
            }
        })(Types_Alert.tofuJSONAlertLevel)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "msg";
            }
        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "BundledWorkerStateUpdate";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "changed";
            }
        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "node";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "type";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "value";
            }
        })(TofuJSON.tofuJSONForeign)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "type";
            }
        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "value";
            }
        })(TofuJSON.tofuJSONForeign)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "node";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "type";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "value";
            }
        })(TofuJSON.tofuJSONForeign)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "type";
            }
        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "value";
            }
        })(TofuJSON.tofuJSONForeign)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.nilWriteForeignFields)()()())))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "changed";
            }
        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "node";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "type";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "value";
            }
        })(TofuJSON.tofuJSONForeign)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "type";
            }
        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "value";
            }
        })(TofuJSON.tofuJSONForeign)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "node";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "type";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "value";
            }
        })(TofuJSON.tofuJSONForeign)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "type";
            }
        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "value";
            }
        })(TofuJSON.tofuJSONForeign)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.nilWriteForeignFields)()()())))(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "UiNotification";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "alert_level";
            }
        })(Types_Alert.tofuJSONAlertLevel)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "msg";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "alert_level";
            }
        })(Types_Alert.tofuJSONAlertLevel)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "msg";
            }
        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.nilWriteForeignVariant))));
        var $10 = Kishimen.genericSumToVariant(genericWorkerResponse)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "BundledWorkerStateUpdate";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "UiNotification";
            }
        })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "BundledWorkerStateUpdate";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "UiNotification";
            }
        })));
        return function ($11) {
            return $9($10($11));
        };
    })(),
    readImpl: (function () {
        var $12 = Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Kishimen.variantToGenericSum(genericWorkerResponse)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "BundledWorkerStateUpdate";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "UiNotification";
            }
        })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "BundledWorkerStateUpdate";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "UiNotification";
            }
        }))));
        var $13 = TofuJSON.readImpl(TofuJSON.tofuJSONVariant()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "BundledWorkerStateUpdate";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "changed";
            }
        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "node";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "type";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "value";
            }
        })(TofuJSON.tofuJSONForeign)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "type";
            }
        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "value";
            }
        })(TofuJSON.tofuJSONForeign)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "node";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "type";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "value";
            }
        })(TofuJSON.tofuJSONForeign)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "type";
            }
        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "value";
            }
        })(TofuJSON.tofuJSONForeign)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.nilWriteForeignFields)()()())))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "changed";
            }
        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "node";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "type";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "value";
            }
        })(TofuJSON.tofuJSONForeign)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "type";
            }
        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "value";
            }
        })(TofuJSON.tofuJSONForeign)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "node";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "type";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "value";
            }
        })(TofuJSON.tofuJSONForeign)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "type";
            }
        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "value";
            }
        })(TofuJSON.tofuJSONForeign)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.nilWriteForeignFields)()()())))(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "UiNotification";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "alert_level";
            }
        })(Types_Alert.tofuJSONAlertLevel)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "msg";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "alert_level";
            }
        })(Types_Alert.tofuJSONAlertLevel)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "msg";
            }
        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "BundledWorkerStateUpdate";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "changed";
            }
        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "node";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "type";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "value";
            }
        })(TofuJSON.tofuJSONForeign)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "type";
            }
        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "value";
            }
        })(TofuJSON.tofuJSONForeign)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "node";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "type";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "value";
            }
        })(TofuJSON.tofuJSONForeign)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "type";
            }
        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "value";
            }
        })(TofuJSON.tofuJSONForeign)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.nilWriteForeignFields)()()())))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "changed";
            }
        })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "node";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "type";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "value";
            }
        })(TofuJSON.tofuJSONForeign)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "type";
            }
        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "value";
            }
        })(TofuJSON.tofuJSONForeign)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "node";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "type";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "value";
            }
        })(TofuJSON.tofuJSONForeign)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "type";
            }
        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "value";
            }
        })(TofuJSON.tofuJSONForeign)(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.nilWriteForeignFields)()()())))(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "UiNotification";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "alert_level";
            }
        })(Types_Alert.tofuJSONAlertLevel)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "msg";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "alert_level";
            }
        })(Types_Alert.tofuJSONAlertLevel)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "msg";
            }
        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.nilWriteForeignVariant))));
        return function ($14) {
            return $12($13($14));
        };
    })()
};
module.exports = {
    BundledWorkerStateUpdate: BundledWorkerStateUpdate,
    UiNotification: UiNotification,
    genericWorkerResponse: genericWorkerResponse,
    tofuJSONWorkerResponse: tofuJSONWorkerResponse
};
