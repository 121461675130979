import { ReactNode, useMemo } from "react";
import { SplitFactoryProvider } from "@splitsoftware/splitio-react";
import { useCurrentUser } from "../auth/useCurrentUser";
import { getClientConfigVarOptional } from "../../client/config";
import { mockedFeatures } from "./FeatureFlags";
import { datadogRum } from "@datadog/browser-rum";
import SplitIO from "@splitsoftware/splitio/types/splitio";

export function SplitConfiguration({ children }: { children: ReactNode }) {
  const currentUser = useCurrentUser();
  const config = useMemo(() => {
    const apiKey = getClientConfigVarOptional("CLIENT_SPLIT_API_KEY");
    if (apiKey == null) {
      console.warn(
        "Split API key not set(CLIENT_SPLIT_API_KEY), not initializing Split SDK"
      );
      return;
    }
    return {
      core: {
        authorizationKey: apiKey,
        key: currentUser.userId,
      },
      impressionListener: {
        logImpression(impressionData) {
          datadogRum.addFeatureFlagEvaluation(
            impressionData.impression.feature,
            impressionData.impression.treatment
          );
        },
      },
      // In development, use mocked features
      ...(apiKey === "localhost" ? { features: mockedFeatures } : null),
    } as SplitIO.IBrowserSettings;
  }, [currentUser]);

  return (
    <SplitFactoryProvider config={config}>{children}</SplitFactoryProvider>
  );
}
