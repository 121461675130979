// Generated by purs version 0.14.5
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Show = require("../Data.Show/index.js");
var ReadParam = require("../ReadParam/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Taxrate = function (x) {
    return x;
};
var PercentageOfZeroToOne = function (x) {
    return x;
};
var SocialExpensePercentage = function (x) {
    return x;
};
var PercentageOfHundred = function (x) {
    return x;
};
var DecimalPrecision = function (x) {
    return x;
};
var CalculatedTaxrate = function (x) {
    return x;
};
var writeParamTaxrate = ReadParam.writeParamNumber;
var writeParamCalculatedTaxrate = ReadParam.writeParamNumber;
var tofuJSONTaxrate = TofuJSON.readNumber;
var tofuJSONPercentageOfZeroToOne = TofuJSON.readNumber;
var tofuJSONSocialExpensePercentage = tofuJSONPercentageOfZeroToOne;
var tofuJSONPercentageOfHundred = TofuJSON.readNumber;
var tofuJSONCalculatedTaxrate = TofuJSON.readNumber;
var showTaxrate = Data_Show.showNumber;
var showPercentageOfZeroToOne = Data_Show.showNumber;
var showSocialExpensePercentage = showPercentageOfZeroToOne;
var showPercentageOfHundred = Data_Show.showNumber;
var showCalculatedTaxrate = Data_Show.showNumber;
var semiringPercentageOfHundred = Data_Semiring.semiringNumber;
var readParamTaxrate = ReadParam.numberReadParam;
var readParamCalculatedTaxrate = ReadParam.numberReadParam;
var ordTaxrate = Data_Ord.ordNumber;
var ordPercentageOfZeroToOne = Data_Ord.ordNumber;
var newtypeTaxrate = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeSocialExpensePercentage = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypePercentageOfZeroToOne = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypePercentageOfHundred = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeDecimalPrecision = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeCalculatedTaxrate = {
    Coercible0: function () {
        return undefined;
    }
};
var eqTaxrate = Data_Eq.eqNumber;
var eqPercentageOfZeroToOne = Data_Eq.eqNumber;
var eqSocialExpensePercentage = eqPercentageOfZeroToOne;
var eqPercentageOfHundred = Data_Eq.eqNumber;
var eqCalculatedTaxrate = Data_Eq.eqNumber;
module.exports = {
    PercentageOfZeroToOne: PercentageOfZeroToOne,
    DecimalPrecision: DecimalPrecision,
    PercentageOfHundred: PercentageOfHundred,
    Taxrate: Taxrate,
    CalculatedTaxrate: CalculatedTaxrate,
    SocialExpensePercentage: SocialExpensePercentage,
    newtypeDecimalPrecision: newtypeDecimalPrecision,
    newtypePercentageOfZeroToOne: newtypePercentageOfZeroToOne,
    eqPercentageOfZeroToOne: eqPercentageOfZeroToOne,
    ordPercentageOfZeroToOne: ordPercentageOfZeroToOne,
    showPercentageOfZeroToOne: showPercentageOfZeroToOne,
    tofuJSONPercentageOfZeroToOne: tofuJSONPercentageOfZeroToOne,
    newtypePercentageOfHundred: newtypePercentageOfHundred,
    eqPercentageOfHundred: eqPercentageOfHundred,
    showPercentageOfHundred: showPercentageOfHundred,
    semiringPercentageOfHundred: semiringPercentageOfHundred,
    tofuJSONPercentageOfHundred: tofuJSONPercentageOfHundred,
    newtypeTaxrate: newtypeTaxrate,
    readParamTaxrate: readParamTaxrate,
    writeParamTaxrate: writeParamTaxrate,
    tofuJSONTaxrate: tofuJSONTaxrate,
    showTaxrate: showTaxrate,
    eqTaxrate: eqTaxrate,
    ordTaxrate: ordTaxrate,
    newtypeCalculatedTaxrate: newtypeCalculatedTaxrate,
    readParamCalculatedTaxrate: readParamCalculatedTaxrate,
    writeParamCalculatedTaxrate: writeParamCalculatedTaxrate,
    tofuJSONCalculatedTaxrate: tofuJSONCalculatedTaxrate,
    showCalculatedTaxrate: showCalculatedTaxrate,
    eqCalculatedTaxrate: eqCalculatedTaxrate,
    newtypeSocialExpensePercentage: newtypeSocialExpensePercentage,
    eqSocialExpensePercentage: eqSocialExpensePercentage,
    showSocialExpensePercentage: showSocialExpensePercentage,
    tofuJSONSocialExpensePercentage: tofuJSONSocialExpensePercentage
};
