// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var ScrollContainer_Styles = require("../ScrollContainer.Styles/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var component = TofuHooks.mkHookComponent("ScrollContainer")(function (props) {
    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        className: "__ScrollContainer",
        css: ScrollContainer_Styles.scrollContainerStyles,
        tabIndex: -1 | 0,
        children: props.children
    }));
});
module.exports = {
    component: component
};
