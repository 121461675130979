// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Color = require("../Color/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var StyleProperties = require("../StyleProperties/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var overlayStyles = function (v) {
    return Box.boxStyle([ Box.BackgroundColor.create(TacoConstants.CustomColor.create(Color.rgba(0)(0)(0)(0.3))), Box.PlaceCenter.value, new Box.Position(Box.Fixed.value), Box.PosTop.create(new TacoConstants.CustomSize("0")), Box.PosLeft.create(new TacoConstants.CustomSize("0")), new Box.ZIndex(TacoConstants.zIndexModal), Box.Width.create(new TacoConstants.CustomSize("100vw")), Box.Height.create(new TacoConstants.CustomSize("100vh")), new Box.Display((function () {
        if (v.isActive) {
            return Box.Flex.value;
        };
        return Box.None.value;
    })()) ]);
};
var headingStyles = Box.boxStyle([ new Box.BackgroundColor(TacoConstants.LightBlue.value), Box.FlexRow.value, Box.JustifySpaceBetween.value, Box.AlignCenter.value, Box.Style.create(React_Basic_Emotion.css()({
    borderBottom: StyleProperties.border(TacoConstants.BorderLight.value),
    borderRadius: React_Basic_Emotion.str("4px 4px 0 0")
})), new Box.Padding(TacoConstants.M.value) ]);
var headingHeight = "48px";
var contentContainerStyles = Box.boxStyle([ Box.Style.create(React_Basic_Emotion.css()({
    borderBottom: StyleProperties.border(TacoConstants.BorderLight.value)
})), new Box.Overflow(Box.Auto.value, Box.Auto.value), new Box.Gap(TacoConstants.M.value), new Box.Padding(TacoConstants.M.value), new Box.FlexGrow(1), Box.FlexColumn.value ]);
var containerStyles = function (extraStyles) {
    return Box.boxStyle(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ Box.MinWidth.create(new TacoConstants.CustomSize("320px")), new Box.Media(Box.TabletM.value, [ Box.MinWidth.create(TacoConstants.CustomSize.create("700px")) ]), Box.MaxWidth.create(new TacoConstants.CustomSize("1024px")), new Box.BackgroundColor(TacoConstants.White.value), new Box.Border(TacoConstants.BorderLight.value), Box.BorderRadius.create(new TacoConstants.CustomSize("4px")), new Box.BoxShadow(TacoConstants.L.value), new Box.Position(Box.Relative.value), Box.FlexColumn.value, Box.Style.create(React_Basic_Emotion.css()({
        gridTemplateRows: React_Basic_Emotion.str("auto 1fr auto")
    })), new Box.Selector(".table-header", [ Box.PosTop.create(new TacoConstants.CustomSize("0")) ]), Box.MaxHeight.create(TacoConstants.CustomSize.create("calc(90vh - " + (headingHeight + " * 2)"))) ])(extraStyles));
};
var actionsContainerStyles = Box.boxStyle([ new Box.BackgroundColor(TacoConstants.LightBlue.value), Box.FlexRow.value, Box.JustifySpaceBetween.value, Box.Style.create(React_Basic_Emotion.css()({
    borderRadius: React_Basic_Emotion.str("4px 4px 0 0")
})), new Box.Padding(TacoConstants.M.value), new Box.FlexGrow(0) ]);
module.exports = {
    overlayStyles: overlayStyles,
    containerStyles: containerStyles,
    headingStyles: headingStyles,
    headingHeight: headingHeight,
    contentContainerStyles: contentContainerStyles,
    actionsContainerStyles: actionsContainerStyles
};
