// Generated by purs version 0.14.5
"use strict";
var TofuJSON = require("../TofuJSON/index.js");
var JSONString = function (x) {
    return x;
};
var writeJSONString = function (dictTofuJSON) {
    return function (a) {
        return JSONString(TofuJSON.writeJSON(dictTofuJSON)(a));
    };
};
var unsafeJSONStringify = function (a) {
    return JSONString(TofuJSON.unsafeStringify(a));
};
var tofuJSONJSONString = TofuJSON.readString;
var newtypeJSONString = {
    Coercible0: function () {
        return undefined;
    }
};
var eqJSONString = {
    eq: function (x) {
        return function (y) {
            return x === y;
        };
    }
};
module.exports = {
    writeJSONString: writeJSONString,
    unsafeJSONStringify: unsafeJSONStringify,
    JSONString: JSONString,
    newtypeJSONString: newtypeJSONString,
    eqJSONString: eqJSONString,
    tofuJSONJSONString: tofuJSONJSONString
};
