const React = require("react");

window.__hookComponentInstances = {};

exports.mkHookComponent_ = (memoize) => (name) => (mkComponent) => {
  // cache the component instance by name
  if (!window.__hookComponentInstances[name]) {
    // TODO: this wrapper is a bit dubious but needed as mkComponent is
    // `Record props -> Effect RB.JSX` while React expects `Record props -> RB.JSX`
    const component = (props) => mkComponent(props)();

    component.displayName = name;
    component.toString = () => name;

    if (memoize) {
      window.__hookComponentInstances[name] = React.memo(component);
    } else {
      window.__hookComponentInstances[name] = component;
    }
  }

  // eslint-disable-next-line react/display-name
  return (props) =>
    React.createElement(window.__hookComponentInstances[name], props);
};

exports.useState_ = (tuple) => (initialState) => () => {
  const [state, setState] = React.useState(initialState);

  const setStateEffect = (updater) => () => {
    return setState(updater);
  };

  // store the curried setStateEffect function reference, as otherwise its
  // reference would change on each render - which would be bad if we pass this
  // function down to child components
  const memoSetState = React.useRef(setStateEffect);

  return tuple(state)(memoSetState.current);
};

exports.useStateEffect_ = (tuple) => (initialState) => () => {
  const [state, setState] = React.useState(initialState);

  const setStateEffect = (updater) => () => {
    return setState((value) => updater(value)());
  };

  // store the curried setStateEffect function reference, as otherwise its
  // reference would change on each render - which would be bad if we pass this
  // function down to child components
  const memoSetState = React.useRef(setStateEffect);

  return tuple(state)(memoSetState.current);
};

exports.useRef_ = (initialValue) => () => React.useRef(initialValue);
exports.writeRef_ = (ref) => (value) => () => (ref.current = value);
exports.readRef_ = (ref) => () => ref.current;

exports.useEffect_ = (deps) => (effect) => () => React.useEffect(effect, deps);

exports.useEffectTimeout_ = (deps) => (timeoutAmount) => (fn) => () => {
  let timeoutIdRef = React.useRef(0);
  let unregister = React.useRef(null);

  React.useEffect(() => {
    clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(() => {
      unregister.current = fn();
    }, timeoutAmount);

    return () => {
      clearTimeout(timeoutIdRef.current);
      if (unregister.current) {
        unregister.current();
      }
    };
    // eslint-disable-next-line
  }, deps);
};

exports.useLayoutEffect_ = (deps) => (effect) => () =>
  React.useLayoutEffect(effect, deps);

exports.useCallback_ = (deps) => (fn) => () => React.useCallback(fn, deps);
exports.useMemo_ = (deps) => (fn) => () => React.useMemo(fn, deps);

exports.useContext_ = (context) => () => React.useContext(context);
