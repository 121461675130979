// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Effect = require("../Effect/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var component = TofuHooks.mkHookComponent("ButtonTab")(function (props) {
    var titleJSX = ReactBasicUtils["div'"]("title")([ TacoText.component()()({
        text: props.title,
        inheritColor: true,
        weight: TacoText_Types.Bold.value
    }) ]);
    return Control_Applicative.pure(Effect.applicativeEffect)(TacoButton.component()()({
        onClick: new Data_Maybe.Just(props.onClick),
        children: new Data_Maybe.Just([ titleJSX ]),
        buttonStyle: TacoButton_Types.Text.value,
        color: TacoConstants.TextSecondary.value,
        icon: props.icon,
        testId: "tab-" + props.id
    }));
});
module.exports = {
    component: component
};
