// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var EditOrganizationModal = require("../EditOrganizationModal/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Organization = require("../Types.Organization/index.js");
var UseFetch = require("../UseFetch/index.js");
var ByOrg = (function () {
    function ByOrg() {

    };
    ByOrg.value = new ByOrg();
    return ByOrg;
})();
var ByCCUserQuota = (function () {
    function ByCCUserQuota() {

    };
    ByCCUserQuota.value = new ByCCUserQuota();
    return ByCCUserQuota;
})();
var ByCCRWQuota = (function () {
    function ByCCRWQuota() {

    };
    ByCCRWQuota.value = new ByCCRWQuota();
    return ByCCRWQuota;
})();
var ByCognitoIdentityProviderName = (function () {
    function ByCognitoIdentityProviderName() {

    };
    ByCognitoIdentityProviderName.value = new ByCognitoIdentityProviderName();
    return ByCognitoIdentityProviderName;
})();
var orgsTableStyleSet = TacoTable_Styles.foldingTableStyleSet;
var getSortingValue = function (v) {
    if (v instanceof ByOrg) {
        var $45 = Data_Newtype.unwrap();
        return function ($46) {
            return TacoTable_Types.StringValue.create($45((function (v1) {
                return v1.organization;
            })($46)));
        };
    };
    if (v instanceof ByCCUserQuota) {
        return function ($47) {
            return TacoTable_Types.IntValue.create((function (v1) {
                return v1.costControlUsers;
            })((function (v1) {
                return v1.quotas;
            })($47)));
        };
    };
    if (v instanceof ByCCRWQuota) {
        return function ($48) {
            return TacoTable_Types.IntValue.create((function (v1) {
                return v1.costControlRWUsers;
            })((function (v1) {
                return v1.quotas;
            })($48)));
        };
    };
    if (v instanceof ByCognitoIdentityProviderName) {
        return function ($49) {
            return TacoTable_Types.BooleanValue.create(Data_Maybe.isJust((function (v1) {
                return v1.cognitoIdentityProviderName;
            })($49)));
        };
    };
    throw new Error("Failed pattern match at ListOrganizations (line 161, column 1 - line 161, column 88): " + [ v.constructor.name ]);
};
var getNameListOrgsSortProperty = {
    getName: function (v) {
        return "ListOrgsSortProperty";
    }
};
var orgsTableInstance = TacoTable.mkTableSortable(getNameListOrgsSortProperty)("ListOgs");
var eqListOrgsSortProperty = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ByOrg && y instanceof ByOrg) {
                return true;
            };
            if (x instanceof ByCCUserQuota && y instanceof ByCCUserQuota) {
                return true;
            };
            if (x instanceof ByCCRWQuota && y instanceof ByCCRWQuota) {
                return true;
            };
            if (x instanceof ByCognitoIdentityProviderName && y instanceof ByCognitoIdentityProviderName) {
                return true;
            };
            return false;
        };
    }
};
var ordListOrgsSortProperty = {
    compare: function (x) {
        return function (y) {
            if (x instanceof ByOrg && y instanceof ByOrg) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByOrg) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByOrg) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByCCUserQuota && y instanceof ByCCUserQuota) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByCCUserQuota) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByCCUserQuota) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByCCRWQuota && y instanceof ByCCRWQuota) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByCCRWQuota) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByCCRWQuota) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByCognitoIdentityProviderName && y instanceof ByCognitoIdentityProviderName) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at ListOrganizations (line 151, column 1 - line 151, column 68): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqListOrgsSortProperty;
    }
};
var component = TofuHooks.mkHookComponent("ListOrganizations")(function (v) {
    return function __do() {
        var v1 = UseFetch.useFetchItems(Data_Eq.eqUnit)(Data_Unit.unit)(function (v1) {
            return v1.allOrganizationConfigurations;
        })(Actions.GetAllOrganizationConfigurationsRequest.value)();
        var v2 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var updateOrgData = function (v3) {
            return v.dispatch(new Actions.SetOrganizationConfigurationRequest(v3.organization, {
                quotas: v3.quotas,
                cognitoIdentityProviderName: v3.cognitoIdentityProviderName,
                adminetTenantId: v3.adminetTenantId
            }));
        };
        var modal = (function () {
            if (v2.value0 instanceof Data_Maybe.Just) {
                return EditOrganizationModal.component({
                    orgData: v2.value0.value0,
                    onSave: updateOrgData,
                    onClose: v2.value1(function (v3) {
                        return Data_Maybe.Nothing.value;
                    })
                });
            };
            if (v2.value0 instanceof Data_Maybe.Nothing) {
                return React_Basic.empty;
            };
            throw new Error("Failed pattern match at ListOrganizations (line 122, column 13 - line 128, column 26): " + [ v2.value0.constructor.name ]);
        })();
        var editButton = function (orgData) {
            return TacoButton.component()()({
                text: "Muokkaa",
                buttonStyle: TacoButton_Types.Outlined.value,
                onClick: Data_Maybe.Just.create(v2.value1(function (v3) {
                    return new Data_Maybe.Just(orgData);
                }))
            });
        };
        var columns = [ {
            key: "org",
            label: React_Basic_DOM.text("Organisaatio"),
            cell: TacoTable_Types.PlainTextCell.create((function () {
                var $50 = Data_Newtype.unwrap();
                return function ($51) {
                    return $50((function (v3) {
                        return v3.organization;
                    })($51));
                };
            })()),
            sortProperty: new Data_Maybe.Just(ByOrg.value),
            width: new TacoTable_Types.Flex(20),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "ccUserQuota",
            label: React_Basic_DOM.text("Raportointik\xe4ytt\xe4jien m\xe4\xe4r\xe4"),
            cell: TacoTable_Types.PlainNumberCell.create(0)(function ($52) {
                return Data_Int.toNumber((function (v3) {
                    return v3.quotas.costControlUsers;
                })($52));
            }),
            sortProperty: new Data_Maybe.Just(ByCCUserQuota.value),
            width: new TacoTable_Types.Flex(10),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "ccRWUserQuota",
            label: React_Basic_DOM.text("Kirjoitusoikeuksien raportointik\xe4ytt\xe4jien m\xe4\xe4r\xe4"),
            cell: TacoTable_Types.PlainNumberCell.create(0)(function ($53) {
                return Data_Int.toNumber((function (v3) {
                    return v3.quotas.costControlRWUsers;
                })($53));
            }),
            sortProperty: new Data_Maybe.Just(ByCCRWQuota.value),
            width: new TacoTable_Types.Flex(10),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "ceUserQuota",
            label: React_Basic_DOM.text("Laskentak\xe4ytt\xe4jien m\xe4\xe4r\xe4"),
            cell: TacoTable_Types.PlainNumberCell.create(0)(function ($54) {
                return Data_Int.toNumber((function (v3) {
                    return v3.quotas.estimationUsers;
                })($54));
            }),
            sortProperty: new Data_Maybe.Just(ByCCUserQuota.value),
            width: new TacoTable_Types.Flex(10),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "ceRWUserQuota",
            label: React_Basic_DOM.text("Kirjoitusoikeuksien laskentaik\xe4ytt\xe4jien m\xe4\xe4r\xe4"),
            cell: TacoTable_Types.PlainNumberCell.create(0)(function ($55) {
                return Data_Int.toNumber((function (v3) {
                    return v3.quotas.estimationRWUsers;
                })($55));
            }),
            sortProperty: new Data_Maybe.Just(ByCCRWQuota.value),
            width: new TacoTable_Types.Flex(10),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "hasCognitoIdentityProviderName",
            label: React_Basic_DOM.text("SSO"),
            cell: new TacoTable_Types.JSXCell([  ], function (r) {
                return TacoCheckbox.component()()({
                    isChecked: Data_Maybe.isJust(r.cognitoIdentityProviderName)
                });
            }),
            sortProperty: new Data_Maybe.Just(ByCognitoIdentityProviderName.value),
            width: new TacoTable_Types.Fixed("4rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "editOrg",
            label: React_Basic_DOM.text(""),
            cell: new TacoTable_Types.JSXCell([  ], editButton),
            sortProperty: Data_Maybe.Nothing.value,
            width: new TacoTable_Types.Flex(15),
            headerJSX: Data_Maybe.Nothing.value
        } ];
        var mkRows = function (qs) {
            return {
                rowData: qs,
                rowKey: Data_Show.show(Data_Show.showString)(Data_Newtype.unwrap()(qs.organization)),
                onClick: function (v3) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                },
                className: "",
                foldingElement: Data_Maybe.Nothing.value,
                columns: columns
            };
        };
        var table = TacoTable.tableSortable(getNameListOrgsSortProperty)(eqListOrgsSortProperty)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "quotas";
            }
        })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "estimationUsers";
            }
        })(Data_Eq.eqInt))()({
            reflectSymbol: function () {
                return "estimationRWUsers";
            }
        })(Data_Eq.eqInt))()({
            reflectSymbol: function () {
                return "costControlUsers";
            }
        })(Data_Eq.eqInt))()({
            reflectSymbol: function () {
                return "costControlRWUsers";
            }
        })(Data_Eq.eqInt))))()({
            reflectSymbol: function () {
                return "organization";
            }
        })(Types_Organization.eqOrganization))()({
            reflectSymbol: function () {
                return "cognitoIdentityProviderName";
            }
        })(Data_Maybe.eqMaybe(Types_Organization.eqCognitoIdentityProviderName)))()({
            reflectSymbol: function () {
                return "adminetTenantId";
            }
        })(Data_Maybe.eqMaybe(Types_Organization.eqAdminetTenantId))))(orgsTableInstance)(new TacoTable_Types.SortingCriteria(TacoTable_Types.Ascending.value, new Data_Maybe.Just(ByOrg.value), getSortingValue))(Data_Maybe.Nothing.value)({
            rows: Data_Functor.map(Data_Functor.functorArray)(mkRows)(v1.value0),
            columns: columns,
            showHeader: true,
            styleSet: orgsTableStyleSet
        });
        return React_Basic_DOM_Generated.div()({
            className: "",
            children: [ TacoText.component()()({
                text: "Organisaatiot",
                variant: new TacoText_Types.Heading(2),
                weight: TacoText_Types.Bold.value,
                gutterBottom: true
            }), table, modal ]
        });
    };
});
module.exports = {
    component: component
};
