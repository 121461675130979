// Generated by purs version 0.14.5
"use strict";
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Milkis = require("../Milkis/index.js");
var ReadParam = require("../ReadParam/index.js");
var Record = require("../Record/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Type_Data_RowList = require("../Type.Data.RowList/index.js");
var Type_Proxy = require("../Type.Proxy/index.js");
var Route = (function () {
    function Route(value0) {
        this.value0 = value0;
    };
    Route.create = function (value0) {
        return new Route(value0);
    };
    return Route;
})();
var MProxy = (function () {
    function MProxy() {

    };
    MProxy.value = new MProxy();
    return MProxy;
})();
var getRequestMethodPost = {
    getRequestMethod: function (v) {
        return Milkis.postMethod;
    }
};
var getRequestMethodGet = {
    getRequestMethod: function (v) {
        return Milkis.getMethod;
    }
};
var getRequestMethodDelete = {
    getRequestMethod: function (v) {
        return Milkis.deleteMethod;
    }
};
var getRequestMethod = function (dict) {
    return dict.getRequestMethod;
};
var formatURLNil = {
    formatURLImpl: function (v) {
        return function (s) {
            return function (v1) {
                return s;
            };
        };
    }
};
var formatURLImpl = function (dict) {
    return dict.formatURLImpl;
};
var formatURLCons = function (dictCons) {
    return function (dictIsSymbol) {
        return function (dictWriteParam) {
            return function (dictFormatURL) {
                return {
                    formatURLImpl: function (v) {
                        return function (s) {
                            return function (r) {
                                var pattern = ":" + Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value);
                                var value = ReadParam.writeParam(dictWriteParam)(Record.get(dictIsSymbol)()(Data_Symbol.SProxy.value)(r));
                                var curr = Data_String_Common.replace(pattern)(value)(s);
                                return formatURLImpl(dictFormatURL)(Type_Data_RowList.RLProxy.value)(curr)(r);
                            };
                        };
                    }
                };
            };
        };
    };
};
var formatURL = function (dictRowToList) {
    return function (dictIsSymbol) {
        return function (dictFormatURL) {
            return function (queryP) {
                return function (params) {
                    return formatURLImpl(dictFormatURL)(Type_Data_RowList.RLProxy.value)(Data_Symbol.reflectSymbol(dictIsSymbol)(queryP))(params);
                };
            };
        };
    };
};
var formatRouteURL = function (dictRowToList) {
    return function (dictIsSymbol) {
        return function (dictFormatURL) {
            return function (v) {
                return function (params) {
                    return formatURLImpl(dictFormatURL)(Type_Data_RowList.RLProxy.value)(Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value))(params);
                };
            };
        };
    };
};
var mkRoute = function (dictTofuJSON) {
    return function (dictTofuJSON1) {
        return function (dictIsSymbol) {
            return function (dictRowToList) {
                return function (dictFormatURL) {
                    return function (dictGetRequestMethod) {
                        return new Route({
                            readRequest: TofuJSON.read(dictTofuJSON),
                            readRequestJSON: TofuJSON.readJSON(dictTofuJSON),
                            readResponse: TofuJSON.read(dictTofuJSON1),
                            readResponseJSON: TofuJSON.readJSON(dictTofuJSON1),
                            writeRequest: TofuJSON.write(dictTofuJSON),
                            writeRequestJSON: TofuJSON.writeJSON(dictTofuJSON),
                            writeResponse: TofuJSON.write(dictTofuJSON1),
                            writeResponseJSON: TofuJSON.writeJSON(dictTofuJSON1),
                            formatRouteURL: formatRouteURL()(dictIsSymbol)(dictFormatURL)(Data_Symbol.SProxy.value),
                            method: getRequestMethod(dictGetRequestMethod)(Type_Proxy["Proxy"].value)
                        });
                    };
                };
            };
        };
    };
};
var routeReflectProxy = function (dictTofuJSON) {
    return function (dictTofuJSON1) {
        return function (dictIsSymbol) {
            return function (dictRowToList) {
                return function (dictFormatURL) {
                    return function (dictGetRequestMethod) {
                        return {
                            reflectProxy: mkRoute(dictTofuJSON)(dictTofuJSON1)(dictIsSymbol)()(dictFormatURL)(dictGetRequestMethod)
                        };
                    };
                };
            };
        };
    };
};
module.exports = {
    formatURLImpl: formatURLImpl,
    getRequestMethod: getRequestMethod,
    MProxy: MProxy,
    Route: Route,
    mkRoute: mkRoute,
    formatRouteURL: formatRouteURL,
    formatURL: formatURL,
    routeReflectProxy: routeReflectProxy,
    formatURLNil: formatURLNil,
    formatURLCons: formatURLCons,
    getRequestMethodPost: getRequestMethodPost,
    getRequestMethodGet: getRequestMethodGet,
    getRequestMethodDelete: getRequestMethodDelete
};
