import { useMutation, UseMutationResult } from "react-query";
import axiosClient from "../../../../axiosConfig";

type EditCostGroupParams = {
  type: string;
  value: {
    componentId: number;
    costGroupCode: string;
  }[];
};

export function useEditCostGroup(
  projectId: number,
  onSave: () => void
): UseMutationResult<{ componentIds: number[] }, unknown, EditCostGroupParams> {
  return useMutation({
    mutationFn: async (value: EditCostGroupParams) => {
      const { data } = await axiosClient.post(
        `project/${projectId}/planningAction`,
        value
      );
      return data;
    },
    onSuccess: () => {
      onSave();
    },
  });
}
