// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var projectsTableStyles = Box.boxStyle([ new Box.Width(TacoConstants.pageContentWidth), new Box.Style(TacoTable_Styles.tableHoverStyles), new Box.Selector(".__TacoTable .table-header", [ Box.PosTop.create(new TacoConstants.CustomSize("3rem")) ]) ]);
var projectRightsStyles = Box.boxStyle([ new Box.PaddingY(TacoConstants.XL.value) ]);
var projectListStyles = Box.boxStyle([ Box.FlexColumn.value, Box.Width.create(new TacoConstants.CustomSize("100%")) ]);
var filterStyles = Box.boxStyle([ Box.Width.create(new TacoConstants.CustomSize("16rem")), new Box.PaddingBottom(TacoConstants.M.value) ]);
module.exports = {
    projectListStyles: projectListStyles,
    projectsTableStyles: projectsTableStyles,
    filterStyles: filterStyles,
    projectRightsStyles: projectRightsStyles
};
