// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Common = require("../Common/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var CostEstimation_Calculations = require("../CostEstimation.Calculations/index.js");
var CostEstimation_SelectResource = require("../CostEstimation.SelectResource/index.js");
var CostEstimation_Styles = require("../CostEstimation.Styles/index.js");
var CostEstimationTableWrapper = require("../CostEstimationTableWrapper/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Unfoldable = require("../Data.Unfoldable/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var EstimationUtils = require("../EstimationUtils/index.js");
var Formatters = require("../Formatters/index.js");
var Gap = require("../Gap/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_DOM_Internal = require("../React.Basic.DOM.Internal/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var SocialExpenseIndicator = require("../SocialExpenseIndicator/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoAutocomplete = require("../TacoAutocomplete/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoInput_Styles = require("../TacoInput.Styles/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoTable_Header = require("../TacoTable.Header/index.js");
var TacoTable_Row = require("../TacoTable.Row/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Alert = require("../Types.Alert/index.js");
var Types_ClassificationGroup = require("../Types.ClassificationGroup/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_CostEstimation = require("../Types.CostEstimation/index.js");
var Types_Estimation = require("../Types.Estimation/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Project = require("../Types.Project/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var UseLicenseFeature = require("../UseLicenseFeature/index.js");
var Utils = require("../Utils/index.js");
var renderCostClassOptions = function (costClasses) {
    var renderOption = function (costClass) {
        var id = Data_Newtype.unwrap()(costClass.costClassCode);
        return {
            id: id,
            text: id + (" " + costClass.name)
        };
    };
    return Data_Functor.map(Data_Functor.functorArray)(renderOption)(costClasses);
};
var ratePrecision = 3;
var pricePrecision = 2;
var percentagePrecision = 2;
var mkResourcePasteParams = function (componentId) {
    return function (existingResources) {
        return function (pasteResource) {
            var matches = Data_Array.filter(function (resource) {
                return Data_Eq.eq(Data_Nullable.eqNullable(Types_Newtypes.eqCostClassCode))(resource.costClassCode)(pasteResource.costClassCode);
            })(existingResources);
            if (pasteResource.isCostClassPrice) {
                return {
                    addResources: [ {
                        estimationComponentId: componentId,
                        costClassCode: Utils.fromNullable("1")(pasteResource.costClassCode),
                        name: pasteResource.name,
                        group: Data_Nullable.toMaybe(pasteResource.group),
                        isCostClassResource: pasteResource.isCostClassPrice,
                        price: Utils.fromNullable(0.0)(pasteResource.pricePerUnit),
                        unit: Data_Nullable.toMaybe(pasteResource.unit),
                        index: Data_Nullable.toMaybe(pasteResource.index),
                        orgResourcePriceId: Data_Nullable.toMaybe(pasteResource.orgResourcePriceId),
                        orgResourcePriceListId: Data_Nullable.toMaybe(pasteResource.orgResourcePriceListId),
                        details: new Data_Maybe.Just({
                            discountPercentage: pasteResource.discountPercentage,
                            resourceClassificationGroup: Data_Nullable.toMaybe(pasteResource.resourceClassificationGroup),
                            sortingNumber: Data_Nullable.toMaybe(pasteResource.sortingNumber),
                            multiplier: 1.0,
                            unitConsumptionRate: pasteResource.unitConsumptionRate,
                            wastePercentage: pasteResource.wastePercentage,
                            taxPercentage: pasteResource.taxPercentage,
                            currency: Data_Functor.map(Data_Maybe.functorMaybe)(Types_Project.Currency)(Data_Nullable.toMaybe(pasteResource.currencyId)),
                            vendor: Data_Nullable.toMaybe(pasteResource.vendor),
                            discountGroupCode: Data_Nullable.toMaybe(pasteResource.discountGroupCode),
                            changeDate: Data_Nullable.toMaybe(pasteResource.changeDate),
                            validDate: Data_Nullable.toMaybe(pasteResource.validDate),
                            contract: Data_Nullable.toMaybe(pasteResource.contract),
                            eanCode: Data_Nullable.toMaybe(pasteResource.eanCode),
                            vendorCode: Data_Nullable.toMaybe(pasteResource.vendorCode),
                            vendorBatchSize: Data_Nullable.toMaybe(pasteResource.vendorBatchSize)
                        })
                    } ],
                    deleteResources: Data_Functor.map(Data_Functor.functorArray)(function (v) {
                        return v.id;
                    })(matches)
                };
            };
            var matchingCostClassPrices = Data_Array.filter(function (v) {
                return v.isCostClassPrice;
            })(matches);
            return {
                addResources: [ {
                    estimationComponentId: componentId,
                    costClassCode: Utils.fromNullable("1")(pasteResource.costClassCode),
                    name: pasteResource.name,
                    group: Data_Nullable.toMaybe(pasteResource.group),
                    isCostClassResource: pasteResource.isCostClassPrice,
                    price: Utils.fromNullable(0.0)(pasteResource.pricePerUnit),
                    index: Data_Nullable.toMaybe(pasteResource.index),
                    unit: Data_Nullable.toMaybe(pasteResource.unit),
                    orgResourcePriceId: Data_Nullable.toMaybe(pasteResource.orgResourcePriceId),
                    orgResourcePriceListId: Data_Nullable.toMaybe(pasteResource.orgResourcePriceListId),
                    details: new Data_Maybe.Just({
                        discountPercentage: pasteResource.discountPercentage,
                        resourceClassificationGroup: Data_Nullable.toMaybe(pasteResource.resourceClassificationGroup),
                        sortingNumber: Data_Nullable.toMaybe(pasteResource.sortingNumber),
                        multiplier: 1.0,
                        unitConsumptionRate: pasteResource.unitConsumptionRate,
                        wastePercentage: pasteResource.wastePercentage,
                        taxPercentage: pasteResource.taxPercentage,
                        currency: Data_Functor.map(Data_Maybe.functorMaybe)(Types_Project.Currency)(Data_Nullable.toMaybe(pasteResource.currencyId)),
                        vendor: Data_Nullable.toMaybe(pasteResource.vendor),
                        discountGroupCode: Data_Nullable.toMaybe(pasteResource.discountGroupCode),
                        changeDate: Data_Nullable.toMaybe(pasteResource.changeDate),
                        validDate: Data_Nullable.toMaybe(pasteResource.validDate),
                        contract: Data_Nullable.toMaybe(pasteResource.contract),
                        eanCode: Data_Nullable.toMaybe(pasteResource.eanCode),
                        vendorCode: Data_Nullable.toMaybe(pasteResource.vendorCode),
                        vendorBatchSize: Data_Nullable.toMaybe(pasteResource.vendorBatchSize)
                    })
                } ],
                deleteResources: Data_Functor.map(Data_Functor.functorArray)(function (v) {
                    return v.id;
                })(matchingCostClassPrices)
            };
        };
    };
};
var mkPasteAction = function (actionType) {
    return function (componentId) {
        return function (existingResources) {
            return function (pasteResources) {
                var updates = Data_Functor.map(Data_Functor.functorArray)(mkResourcePasteParams(componentId)(existingResources))(pasteResources);
                var params = {
                    deleteResources: Data_Array.nub(Types_Component.ordEstimationResourceId)(Data_Array.concat(Data_Functor.map(Data_Functor.functorArray)(function (v) {
                        return v.deleteResources;
                    })(updates))),
                    addResources: Data_Array.concat(Data_Functor.map(Data_Functor.functorArray)(function (v) {
                        return v.addResources;
                    })(updates))
                };
                return {
                    actionType: actionType,
                    actionParams: new Types_Estimation.AddAndDeleteMultipleResources(params)
                };
            };
        };
    };
};
var amountPrecision = 1;
var getResourceColumns = function (resourceActionType) {
    return function (props) {
        return function (setSelectedResourceIds) {
            return function (groupsEnabled) {
                var testIdPrefix = function (resource) {
                    return function (id) {
                        return Data_Show.show(Types_CostEstimation.showECViewMode)(props.viewMode) + ("-resource-" + (Data_Show.show(Types_Component.showEstimationResourceId)(resource.id) + ("-" + id)));
                    };
                };
                var unitConsumptionRateUnitCell = function (v) {
                    var text = (function () {
                        var v1 = Data_Nullable.toMaybe(v.resource.unit);
                        if (props.componentUnit instanceof Data_Maybe.Just && v1 instanceof Data_Maybe.Just) {
                            return v1.value0 + ("/" + props.componentUnit.value0);
                        };
                        return "";
                    })();
                    return TacoText.component()()({
                        text: text,
                        testId: testIdPrefix(v.resource)("unit-consumption-rate-unit")
                    });
                };
                var workAchievementUnitCell = function (v) {
                    var text = (function () {
                        var v1 = Data_Nullable.toMaybe(v.resource.unit);
                        if (props.componentUnit instanceof Data_Maybe.Just && v1 instanceof Data_Maybe.Just) {
                            var $132 = v.showWorkAchievement && v.hourlyPricing;
                            if ($132) {
                                return props.componentUnit.value0 + ("/" + v1.value0);
                            };
                            return v1.value0 + ("/" + props.componentUnit.value0);
                        };
                        return "";
                    })();
                    return TacoText.component()()({
                        text: text,
                        testId: testIdPrefix(v.resource)("work-achievement-unit")
                    });
                };
                var selectResourceCheckbox = function (r) {
                    return TacoCheckbox.component()()({
                        className: "resource-checkbox",
                        isChecked: r.isSelected,
                        labelText: Data_Maybe.Nothing.value,
                        onToggle: new TacoCheckbox.NoEvent(setSelectedResourceIds(function (selectedResourceIds) {
                            return Utils.toggleInSet(Types_Component.ordEstimationResourceId)(r.resource.id)(selectedResourceIds);
                        })),
                        testId: testIdPrefix(r.resource)("checkbox"),
                        color: TacoConstants.resourceColors.border
                    });
                };
                var pricePerUnitLabel = Gap.gapX(TacoConstants.XS.value)([ Data_Monoid.guard(React_Basic.monoidJSX)(props.showSocialExpensesInCosts)(SocialExpenseIndicator.socialExpenseIndicator), TacoText.component()()({
                    text: "Hinta",
                    color: TacoConstants.TextSecondary.value,
                    align: TacoText_Types.Right.value
                }) ]);
                var mkSocialExpenseLabel = function (text) {
                    return Gap.gapX(TacoConstants.XS.value)([ Data_Monoid.guard(React_Basic.monoidJSX)(props.showSocialExpensesInCosts)(SocialExpenseIndicator.socialExpenseIndicator), React_Basic_DOM.text(text) ]);
                };
                var totalPriceLabel = mkSocialExpenseLabel("Yhteens\xe4");
                var mkAction = function (resourceId) {
                    return function (resourceParamsFn) {
                        return function (handler) {
                            return new Actions.EstimationResourceActionRequest({
                                projectId: props.projectId,
                                params: {
                                    actionType: resourceActionType,
                                    actionParams: new Types_Estimation.EditEstimationResource([ {
                                        resourceId: resourceId,
                                        resourceParams: resourceParamsFn(Types_Estimation.editEstimationResourceDefaultParams)
                                    } ])
                                },
                                handler: handler
                            });
                        };
                    };
                };
                var nameInput = function (v) {
                    var placeholder = (function () {
                        if (v.resource.isCostClassPrice) {
                            return "VIRHE: Poista t\xe4m\xe4 panoslajihinta";
                        };
                        return "Uusi panos";
                    })();
                    return TacoInput.remoteStringField()()({
                        value: v.resource.name,
                        placeholder: placeholder,
                        mkAction: function (name) {
                            return mkAction(v.resource.id)(function (v1) {
                                return {
                                    name: Data_Nullable.notNull(name),
                                    costClassCode: v1.costClassCode,
                                    pricePerUnit: v1.pricePerUnit,
                                    unit: v1.unit,
                                    wastePercentage: v1.wastePercentage,
                                    unitConsumptionRate: v1.unitConsumptionRate,
                                    group: v1.group,
                                    resourceClassificationGroup: v1.resourceClassificationGroup,
                                    sortingNumber: v1.sortingNumber,
                                    validDate: v1.validDate,
                                    changedDate: v1.changedDate,
                                    discountPercentage: v1.discountPercentage,
                                    eanCode: v1.eanCode,
                                    taxRate: v1.taxRate,
                                    index: v1.index,
                                    vendor: v1.vendor,
                                    vendorCode: v1.vendorCode,
                                    vendorUnit: v1.vendorUnit,
                                    vendorBatchSize: v1.vendorBatchSize,
                                    currencyId: v1.currencyId,
                                    orgResourcePriceListId: v1.orgResourcePriceListId,
                                    orgResourcePriceId: v1.orgResourcePriceId
                                };
                            });
                        },
                        disabled: v.resource.isCostClassPrice,
                        fontWeight: (function () {
                            if (v.resource.isCostClassPrice) {
                                return new Data_Maybe.Just("bold");
                            };
                            return Data_Maybe.Nothing.value;
                        })(),
                        title: (function () {
                            if (v.resource.isCostClassPrice) {
                                return "Panoslajihintoja ja panoshintoja ei voi sekoittaa. Poista jompikumpi!";
                            };
                            return "";
                        })(),
                        testId: testIdPrefix(v.resource)("name")
                    });
                };
                var pricePerUnitInput = function (v) {
                    var pricePerUnitStr = "Hinta: " + Formatters.formatDecimalValue(2)(Utils.fromNullable(0.0)(v.resource.pricePerUnit));
                    var discountPercentageStr = "Ale: " + Formatters.formatPercentageValue({
                        precision: percentagePrecision,
                        value: v.resource.discountPercentage / 100.0
                    });
                    var sepStr = "Sosiaalikulut: " + Formatters.formatPercentageValue({
                        precision: percentagePrecision,
                        value: CostEstimation_Calculations.resourceSocialExpensePercentage(props.socialExpensePercentagesByCostClass)(v.resource)
                    });
                    return TacoInput.remoteNumberField()()({
                        value: CostEstimation_Calculations.resourcePricePerUnit(props.socialExpensePercentagesByCostClass)(v.resource),
                        precision: 2,
                        align: TacoInput.RightAlign.value,
                        title: pricePerUnitStr + ("\x0a" + (discountPercentageStr + ("\x0a" + sepStr))),
                        mkAction: function (value) {
                            return mkAction(v.resource.id)(function (v1) {
                                return {
                                    name: v1.name,
                                    costClassCode: v1.costClassCode,
                                    pricePerUnit: Data_Nullable.notNull(CostEstimation_Calculations.solveForBaseResourcePricePerUnitWithoutDiscountPercentage(props.socialExpensePercentagesByCostClass)(v.resource)(value)),
                                    unit: v1.unit,
                                    wastePercentage: v1.wastePercentage,
                                    unitConsumptionRate: v1.unitConsumptionRate,
                                    group: v1.group,
                                    resourceClassificationGroup: v1.resourceClassificationGroup,
                                    sortingNumber: v1.sortingNumber,
                                    validDate: v1.validDate,
                                    changedDate: v1.changedDate,
                                    discountPercentage: Utils.pureNullable(0.0),
                                    eanCode: v1.eanCode,
                                    taxRate: v1.taxRate,
                                    index: v1.index,
                                    vendor: v1.vendor,
                                    vendorCode: v1.vendorCode,
                                    vendorUnit: v1.vendorUnit,
                                    vendorBatchSize: v1.vendorBatchSize,
                                    currencyId: v1.currencyId,
                                    orgResourcePriceListId: v1.orgResourcePriceListId,
                                    orgResourcePriceId: v1.orgResourcePriceId
                                };
                            });
                        },
                        rendering: new TacoInput_Styles.TableCellRendering(TacoTable_Types.RowSmall.value),
                        disabled: v.resource.isCostClassPrice,
                        testId: testIdPrefix(v.resource)("price-per-unit-input")
                    });
                };
                var unitConsumptionRateInput = function (v) {
                    return TacoInput.remoteNumberField()()({
                        value: CostEstimation_Calculations.consumptionRate(v.resource),
                        precision: ratePrecision,
                        align: TacoInput.RightAlign.value,
                        mkAction: function (value) {
                            return mkAction(v.resource.id)(function (v1) {
                                return {
                                    name: v1.name,
                                    costClassCode: v1.costClassCode,
                                    pricePerUnit: v1.pricePerUnit,
                                    unit: v1.unit,
                                    wastePercentage: v1.wastePercentage,
                                    unitConsumptionRate: Data_Nullable.notNull(value),
                                    group: v1.group,
                                    resourceClassificationGroup: v1.resourceClassificationGroup,
                                    sortingNumber: v1.sortingNumber,
                                    validDate: v1.validDate,
                                    changedDate: v1.changedDate,
                                    discountPercentage: v1.discountPercentage,
                                    eanCode: v1.eanCode,
                                    taxRate: v1.taxRate,
                                    index: v1.index,
                                    vendor: v1.vendor,
                                    vendorCode: v1.vendorCode,
                                    vendorUnit: v1.vendorUnit,
                                    vendorBatchSize: v1.vendorBatchSize,
                                    currencyId: v1.currencyId,
                                    orgResourcePriceListId: v1.orgResourcePriceListId,
                                    orgResourcePriceId: v1.orgResourcePriceId
                                };
                            });
                        },
                        rendering: new TacoInput_Styles.TableCellRendering(TacoTable_Types.RowSmall.value),
                        disabled: v.resource.isCostClassPrice,
                        testId: testIdPrefix(v.resource)("unit-consumption-rate-input")
                    });
                };
                var unitInput = function (v) {
                    return TacoInput.remoteStringField()()({
                        value: Utils.fromNullable("")(v.resource.unit),
                        mkAction: function (value) {
                            return mkAction(v.resource.id)(function (v1) {
                                return {
                                    name: v1.name,
                                    costClassCode: v1.costClassCode,
                                    pricePerUnit: v1.pricePerUnit,
                                    unit: Data_Nullable.notNull(value),
                                    wastePercentage: v1.wastePercentage,
                                    unitConsumptionRate: v1.unitConsumptionRate,
                                    group: v1.group,
                                    resourceClassificationGroup: v1.resourceClassificationGroup,
                                    sortingNumber: v1.sortingNumber,
                                    validDate: v1.validDate,
                                    changedDate: v1.changedDate,
                                    discountPercentage: v1.discountPercentage,
                                    eanCode: v1.eanCode,
                                    taxRate: v1.taxRate,
                                    index: v1.index,
                                    vendor: v1.vendor,
                                    vendorCode: v1.vendorCode,
                                    vendorUnit: v1.vendorUnit,
                                    vendorBatchSize: v1.vendorBatchSize,
                                    currencyId: v1.currencyId,
                                    orgResourcePriceListId: v1.orgResourcePriceListId,
                                    orgResourcePriceId: v1.orgResourcePriceId
                                };
                            });
                        },
                        rendering: new TacoInput_Styles.TableCellRendering(TacoTable_Types.RowSmall.value),
                        disabled: v.resource.isCostClassPrice,
                        testId: testIdPrefix(v.resource)("unit-input")
                    });
                };
                var wastePercentageInput = function (v) {
                    return TacoInput.remoteNumberField()()({
                        value: v.resource.wastePercentage,
                        precision: percentagePrecision,
                        align: TacoInput.RightAlign.value,
                        mkAction: function (value) {
                            return mkAction(v.resource.id)(function (v1) {
                                return {
                                    name: v1.name,
                                    costClassCode: v1.costClassCode,
                                    pricePerUnit: v1.pricePerUnit,
                                    unit: v1.unit,
                                    wastePercentage: Data_Nullable.notNull(value),
                                    unitConsumptionRate: v1.unitConsumptionRate,
                                    group: v1.group,
                                    resourceClassificationGroup: v1.resourceClassificationGroup,
                                    sortingNumber: v1.sortingNumber,
                                    validDate: v1.validDate,
                                    changedDate: v1.changedDate,
                                    discountPercentage: v1.discountPercentage,
                                    eanCode: v1.eanCode,
                                    taxRate: v1.taxRate,
                                    index: v1.index,
                                    vendor: v1.vendor,
                                    vendorCode: v1.vendorCode,
                                    vendorUnit: v1.vendorUnit,
                                    vendorBatchSize: v1.vendorBatchSize,
                                    currencyId: v1.currencyId,
                                    orgResourcePriceListId: v1.orgResourcePriceListId,
                                    orgResourcePriceId: v1.orgResourcePriceId
                                };
                            });
                        },
                        rendering: new TacoInput_Styles.TableCellRendering(TacoTable_Types.RowSmall.value),
                        disabled: v.resource.isCostClassPrice,
                        testId: testIdPrefix(v.resource)("waste-percentage-input")
                    });
                };
                var workAchievementInput$prime = function (v) {
                    return TacoInput.remoteNumberField()()({
                        value: (function () {
                            var $150 = v.showWorkAchievement && v.hourlyPricing;
                            if ($150) {
                                return Utils.defaultZeroInv(CostEstimation_Calculations.consumptionRate(v.resource));
                            };
                            return CostEstimation_Calculations.consumptionRate(v.resource);
                        })(),
                        precision: ratePrecision,
                        align: TacoInput.RightAlign.value,
                        mkAction: function (value) {
                            return mkAction(v.resource.id)(function (v1) {
                                return {
                                    name: v1.name,
                                    costClassCode: v1.costClassCode,
                                    pricePerUnit: v1.pricePerUnit,
                                    unit: v1.unit,
                                    wastePercentage: v1.wastePercentage,
                                    unitConsumptionRate: Data_Nullable.notNull((function () {
                                        var $151 = v.showWorkAchievement && v.hourlyPricing;
                                        if ($151) {
                                            return Utils.defaultZeroInv(value);
                                        };
                                        return value;
                                    })()),
                                    group: v1.group,
                                    resourceClassificationGroup: v1.resourceClassificationGroup,
                                    sortingNumber: v1.sortingNumber,
                                    validDate: v1.validDate,
                                    changedDate: v1.changedDate,
                                    discountPercentage: v1.discountPercentage,
                                    eanCode: v1.eanCode,
                                    taxRate: v1.taxRate,
                                    index: v1.index,
                                    vendor: v1.vendor,
                                    vendorCode: v1.vendorCode,
                                    vendorUnit: v1.vendorUnit,
                                    vendorBatchSize: v1.vendorBatchSize,
                                    currencyId: v1.currencyId,
                                    orgResourcePriceListId: v1.orgResourcePriceListId,
                                    orgResourcePriceId: v1.orgResourcePriceId
                                };
                            });
                        },
                        rendering: new TacoInput_Styles.TableCellRendering(TacoTable_Types.RowSmall.value),
                        disabled: v.resource.isCostClassPrice,
                        testId: testIdPrefix(v.resource)("work-achievement-input")
                    });
                };
                var workAchievementInput = function (r) {
                    return Data_Monoid.guard(React_Basic.monoidJSX)(r.showWorkAchievement && r.hourlyPricing)(workAchievementInput$prime(r));
                };
                var getPricePerComponent = (function () {
                    var $264 = CostEstimation_Calculations.resourceUnitPrice(props.socialExpensePercentagesByCostClass);
                    return function ($265) {
                        return $264((function (v) {
                            return v.resource;
                        })($265));
                    };
                })();
                var pricePerComponentInput = function (r) {
                    return TacoInput.remoteNumberField()()({
                        value: getPricePerComponent(r),
                        precision: 2,
                        align: TacoInput.RightAlign.value,
                        mkAction: function (newPrice) {
                            var newPricePerUnit = CostEstimation_Calculations.solveForUnitPriceFromPricePerComponent(props.socialExpensePercentagesByCostClass)(r.resource)(newPrice);
                            return mkAction(r.resource.id)(function (v) {
                                return {
                                    name: v.name,
                                    costClassCode: v.costClassCode,
                                    pricePerUnit: Data_Nullable.notNull(newPricePerUnit),
                                    unit: v.unit,
                                    wastePercentage: v.wastePercentage,
                                    unitConsumptionRate: v.unitConsumptionRate,
                                    group: v.group,
                                    resourceClassificationGroup: v.resourceClassificationGroup,
                                    sortingNumber: v.sortingNumber,
                                    validDate: v.validDate,
                                    changedDate: v.changedDate,
                                    discountPercentage: v.discountPercentage,
                                    eanCode: v.eanCode,
                                    taxRate: v.taxRate,
                                    index: v.index,
                                    vendor: v.vendor,
                                    vendorCode: v.vendorCode,
                                    vendorUnit: v.vendorUnit,
                                    vendorBatchSize: v.vendorBatchSize,
                                    currencyId: v.currencyId,
                                    orgResourcePriceListId: v.orgResourcePriceListId,
                                    orgResourcePriceId: v.orgResourcePriceId
                                };
                            });
                        },
                        rendering: new TacoInput_Styles.TableCellRendering(TacoTable_Types.RowSmall.value),
                        disabled: r.resource.isCostClassPrice,
                        testId: testIdPrefix(r.resource)("price-per-component-input")
                    });
                };
                var currencySymbol = EstimationUtils.getCurrencySymbol(props.projectCurrency);
                var pricePerComponentLabel = React_Basic_DOM.text((function () {
                    if (props.componentUnit instanceof Data_Maybe.Just) {
                        return currencySymbol + ("/" + props.componentUnit.value0);
                    };
                    if (props.componentUnit instanceof Data_Maybe.Nothing) {
                        return currencySymbol + "/suo.";
                    };
                    throw new Error("Failed pattern match at EstimationResourcesList (line 1323, column 37 - line 1325, column 43): " + [ props.componentUnit.constructor.name ]);
                })());
                var pricePerComponentUnitLabelCell = function (v) {
                    return TacoText.component()()({
                        text: Data_Maybe.fromMaybe("")(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Semigroup.append(Data_Semigroup.semigroupString)(currencySymbol + "/"))(props.componentUnit)),
                        testId: testIdPrefix(v.resource)("price-per-component-unit-label")
                    });
                };
                var pricePerUnitLabelCell = function (v) {
                    return TacoText.component()()({
                        text: Utils.nullable("")(Data_Semigroup.append(Data_Semigroup.semigroupString)(currencySymbol + "/"))(v.resource.unit),
                        testId: testIdPrefix(v.resource)("price-per-unit-label")
                    });
                };
                var totalPriceUnit = function (v) {
                    return TacoText.component()()({
                        text: currencySymbol,
                        testId: testIdPrefix(v.resource)("total-price-unit")
                    });
                };
                var component$prime = {
                    amount: props.componentAmount
                };
                var getTotalPrice = (function () {
                    var $266 = CostEstimation_Calculations.resourceTotalPrice(props.socialExpensePercentagesByCostClass)(component$prime);
                    return function ($267) {
                        return $266((function (v) {
                            return v.resource;
                        })($267));
                    };
                })();
                var totalPriceInput = function (component) {
                    return function (r) {
                        return TacoInput.remoteNumberField()()({
                            value: getTotalPrice(r),
                            precision: 2,
                            align: TacoInput.RightAlign.value,
                            mkAction: function (newTotal) {
                                var newPrice = CostEstimation_Calculations.solveForUnitPriceFromTotalPrice(props.socialExpensePercentagesByCostClass)(component$prime)(r.resource)(newTotal);
                                return mkAction(r.resource.id)(function (v) {
                                    return {
                                        name: v.name,
                                        costClassCode: v.costClassCode,
                                        pricePerUnit: Data_Nullable.notNull(newPrice),
                                        unit: v.unit,
                                        wastePercentage: v.wastePercentage,
                                        unitConsumptionRate: v.unitConsumptionRate,
                                        group: v.group,
                                        resourceClassificationGroup: v.resourceClassificationGroup,
                                        sortingNumber: v.sortingNumber,
                                        validDate: v.validDate,
                                        changedDate: v.changedDate,
                                        discountPercentage: v.discountPercentage,
                                        eanCode: v.eanCode,
                                        taxRate: v.taxRate,
                                        index: v.index,
                                        vendor: v.vendor,
                                        vendorCode: v.vendorCode,
                                        vendorUnit: v.vendorUnit,
                                        vendorBatchSize: v.vendorBatchSize,
                                        currencyId: v.currencyId,
                                        orgResourcePriceListId: v.orgResourcePriceListId,
                                        orgResourcePriceId: v.orgResourcePriceId
                                    };
                                });
                            },
                            rendering: new TacoInput_Styles.TableCellRendering(TacoTable_Types.RowSmall.value),
                            disabled: component.amount <= 0.0 || (CostEstimation_Calculations.consumptionRate(r.resource) <= 0.0 || r.resource.isCostClassPrice),
                            testId: testIdPrefix(r.resource)("total-price-input")
                        });
                    };
                };
                var classificationCodeInput = function (v) {
                    return TacoInput.remoteStringField()()({
                        value: Utils.nullable("")(Data_Newtype.un()(Types_ClassificationGroup.ClassificationGroupCode))(v.resource.group),
                        placeholder: Utils.nullable(v.classificationGroupCodePlaceholder)(Data_Newtype.un()(Types_ClassificationGroup.ClassificationGroupCode))(v.resource.group),
                        mkAction: function (value) {
                            return mkAction(v.resource.id)(function (v1) {
                                return {
                                    name: v1.name,
                                    costClassCode: v1.costClassCode,
                                    pricePerUnit: v1.pricePerUnit,
                                    unit: v1.unit,
                                    wastePercentage: v1.wastePercentage,
                                    unitConsumptionRate: v1.unitConsumptionRate,
                                    group: Data_Nullable.notNull(value),
                                    resourceClassificationGroup: v1.resourceClassificationGroup,
                                    sortingNumber: v1.sortingNumber,
                                    validDate: v1.validDate,
                                    changedDate: v1.changedDate,
                                    discountPercentage: v1.discountPercentage,
                                    eanCode: v1.eanCode,
                                    taxRate: v1.taxRate,
                                    index: v1.index,
                                    vendor: v1.vendor,
                                    vendorCode: v1.vendorCode,
                                    vendorUnit: v1.vendorUnit,
                                    vendorBatchSize: v1.vendorBatchSize,
                                    currencyId: v1.currencyId,
                                    orgResourcePriceListId: v1.orgResourcePriceListId,
                                    orgResourcePriceId: v1.orgResourcePriceId
                                };
                            });
                        },
                        rendering: new TacoInput_Styles.TableCellRendering(TacoTable_Types.RowSmall.value),
                        disabled: v.resource.isCostClassPrice,
                        testId: testIdPrefix(v.resource)("classification-code-input")
                    });
                };
                var canEdit = EstimationUtils.canEditView(props);
                var nameCell = (function () {
                    if (canEdit) {
                        return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], nameInput);
                    };
                    return new TacoTable_Types.JSXCell([  ], function (r) {
                        return TacoText.component()()({
                            text: r.resource.name,
                            testId: testIdPrefix(r.resource)("name")
                        });
                    });
                })();
                var pricePerComponentCell = (function () {
                    if (canEdit) {
                        return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value, TacoTable_Types.Numeric.value ], pricePerComponentInput);
                    };
                    return new TacoTable_Types.JSXCell([  ], function (r) {
                        return TacoText.component()()({
                            text: Formatters.formatDecimalValue(2)(getPricePerComponent(r)),
                            testId: testIdPrefix(r.resource)("price-per-component")
                        });
                    });
                })();
                var pricePerUnitCell = (function () {
                    if (canEdit) {
                        return new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value, TacoTable_Types.Input.value ], pricePerUnitInput);
                    };
                    return new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value ], function (r) {
                        return TacoText.component()()({
                            text: Formatters.formatDecimalValue(2)(CostEstimation_Calculations.resourcePricePerUnit(props.socialExpensePercentagesByCostClass)(r.resource)),
                            testId: testIdPrefix(r.resource)("price-per-unit")
                        });
                    });
                })();
                var resourceClassificationGroupCell = (function () {
                    if (canEdit) {
                        return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], classificationCodeInput);
                    };
                    return new TacoTable_Types.JSXCell([  ], function (r) {
                        return TacoText.component()()({
                            text: Utils.nullable("")(Data_Newtype.un()(Types_ClassificationGroup.ClassificationGroupCode))((function (v) {
                                return v.resource.group;
                            })(r)),
                            testId: testIdPrefix(r.resource)("classification-code")
                        });
                    });
                })();
                var groupColumn = (function () {
                    var $168 = !groupsEnabled;
                    if ($168) {
                        return Common.emptyArray;
                    };
                    return [ {
                        key: "resourceGroup",
                        label: React_Basic_DOM.text("Ryhm\xe4"),
                        cell: resourceClassificationGroupCell,
                        sortProperty: TacoTable_Types.noSortProperty,
                        width: new TacoTable_Types.FlexMinWidth(1, "6rem"),
                        headerJSX: Data_Maybe.Nothing.value
                    } ];
                })();
                var totalPriceCell = function (component) {
                    if (canEdit) {
                        return TacoTable_Types.JSXCell.create([ TacoTable_Types.Input.value, TacoTable_Types.Numeric.value ])(totalPriceInput(component));
                    };
                    return new TacoTable_Types.JSXCell([  ], function (r) {
                        return TacoText.component()()({
                            text: Formatters.formatDecimalValue(2)(getTotalPrice(r)),
                            testId: testIdPrefix(r.resource)("total-price")
                        });
                    });
                };
                var unitCell = (function () {
                    if (canEdit) {
                        return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], unitInput);
                    };
                    return new TacoTable_Types.JSXCell([  ], function (r) {
                        return TacoText.component()()({
                            text: Utils.fromNullable("")((function (v) {
                                return v.resource.unit;
                            })(r)),
                            testId: testIdPrefix(r.resource)("unit")
                        });
                    });
                })();
                var unitConsumptionRateCell = (function () {
                    if (canEdit) {
                        return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value, TacoTable_Types.Numeric.value ], unitConsumptionRateInput);
                    };
                    return new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value ], function (r) {
                        return TacoText.component()()({
                            text: Formatters.formatDecimalValue(ratePrecision)(CostEstimation_Calculations.consumptionRate(r.resource)),
                            testId: testIdPrefix(r.resource)("unit-consumption-rate")
                        });
                    });
                })();
                var wastePercentageCell = (function () {
                    if (canEdit) {
                        return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], wastePercentageInput);
                    };
                    return new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value ], function (r) {
                        return TacoText.component()()({
                            text: Formatters.formatDecimalValue(percentagePrecision)(r.resource.wastePercentage),
                            testId: testIdPrefix(r.resource)("waste-percentage")
                        });
                    });
                })();
                var workAchievementCell = (function () {
                    if (canEdit) {
                        return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], workAchievementInput);
                    };
                    return new TacoTable_Types.JSXCell([  ], function (r) {
                        var $174 = r.showWorkAchievement && r.hourlyPricing;
                        if ($174) {
                            return TacoText.component()()({
                                text: Formatters.formatDecimalValue(ratePrecision)(Utils.defaultZeroInv(CostEstimation_Calculations.consumptionRate(r.resource))),
                                testId: testIdPrefix(r.resource)("work-achievement")
                            });
                        };
                        return React_Basic.empty;
                    });
                })();
                var workAchievementCells = (function () {
                    var $175 = !props.showWorkAchievement;
                    if ($175) {
                        return Common.emptyArray;
                    };
                    return [ {
                        key: "workAchievement",
                        label: React_Basic_DOM.text(""),
                        cell: workAchievementCell,
                        sortProperty: TacoTable_Types.noSortProperty,
                        width: new TacoTable_Types.FlexMinWidth(1, "5rem"),
                        headerJSX: Data_Maybe.Nothing.value
                    }, {
                        key: "workAchievementUnit",
                        label: React_Basic_DOM.text(""),
                        cell: new TacoTable_Types.JSXCell([  ], workAchievementUnitCell),
                        sortProperty: TacoTable_Types.noSortProperty,
                        width: new TacoTable_Types.FlexMinWidth(1, "4rem"),
                        headerJSX: Data_Maybe.Nothing.value
                    } ];
                })();
                var amountInput = function (component) {
                    return function (precision) {
                        return function (v) {
                            var newUnitConsumptionRate = function (resourceAmount) {
                                return CostEstimation_Calculations.resourceUnitConsumptionRate(component$prime)(v.resource)(resourceAmount);
                            };
                            return TacoInput.remoteNumberField()()({
                                value: CostEstimation_Calculations.toAmountPrecision(CostEstimation_Calculations.resourceTotalAmount(component$prime)(v.resource)),
                                precision: precision,
                                align: TacoInput.RightAlign.value,
                                mkAction: function (value) {
                                    return mkAction(v.resource.id)(function (v1) {
                                        return {
                                            name: v1.name,
                                            costClassCode: v1.costClassCode,
                                            pricePerUnit: v1.pricePerUnit,
                                            unit: v1.unit,
                                            wastePercentage: v1.wastePercentage,
                                            unitConsumptionRate: Data_Nullable.notNull(newUnitConsumptionRate(value)),
                                            group: v1.group,
                                            resourceClassificationGroup: v1.resourceClassificationGroup,
                                            sortingNumber: v1.sortingNumber,
                                            validDate: v1.validDate,
                                            changedDate: v1.changedDate,
                                            discountPercentage: v1.discountPercentage,
                                            eanCode: v1.eanCode,
                                            taxRate: v1.taxRate,
                                            index: v1.index,
                                            vendor: v1.vendor,
                                            vendorCode: v1.vendorCode,
                                            vendorUnit: v1.vendorUnit,
                                            vendorBatchSize: v1.vendorBatchSize,
                                            currencyId: v1.currencyId,
                                            orgResourcePriceListId: v1.orgResourcePriceListId,
                                            orgResourcePriceId: v1.orgResourcePriceId
                                        };
                                    });
                                },
                                rendering: new TacoInput_Styles.TableCellRendering(TacoTable_Types.RowSmall.value),
                                disabled: component.amount <= 0.0 || v.resource.isCostClassPrice,
                                testId: testIdPrefix(v.resource)("amount-input")
                            });
                        };
                    };
                };
                var amountCell = function (component) {
                    return function (precision) {
                        if (canEdit) {
                            return TacoTable_Types.JSXCell.create([ TacoTable_Types.Numeric.value, TacoTable_Types.Input.value ])(amountInput(component)(precision));
                        };
                        return new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value ], function (r) {
                            return TacoText.component()()({
                                text: Formatters.formatDecimalValue(precision)(CostEstimation_Calculations.resourceTotalAmount(component$prime)((function (v) {
                                    return v.resource;
                                })(r))),
                                testId: testIdPrefix(r.resource)("amount")
                            });
                        });
                    };
                };
                return Data_Semigroup.append(Data_Semigroup.semigroupArray)([ {
                    key: "checkbox",
                    label: React_Basic_DOM.text(""),
                    cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], selectResourceCheckbox),
                    sortProperty: TacoTable_Types.noSortProperty,
                    width: new TacoTable_Types.Fixed("23px"),
                    headerJSX: Data_Maybe.Nothing.value
                } ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(groupColumn)(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ {
                    key: "name",
                    label: React_Basic_DOM.text("Nimi"),
                    cell: nameCell,
                    sortProperty: TacoTable_Types.noSortProperty,
                    width: new TacoTable_Types.FlexMinWidth(10, "16rem"),
                    headerJSX: Data_Maybe.Nothing.value
                }, {
                    key: "unit",
                    label: React_Basic_DOM.text(""),
                    cell: unitCell,
                    sortProperty: TacoTable_Types.noSortProperty,
                    width: new TacoTable_Types.FlexMinWidth(1, "4rem"),
                    headerJSX: Data_Maybe.Nothing.value
                }, {
                    key: "pricePerUnit",
                    label: pricePerUnitLabel,
                    cell: pricePerUnitCell,
                    sortProperty: TacoTable_Types.noSortProperty,
                    width: new TacoTable_Types.FlexMinWidth(2, "5rem"),
                    headerJSX: Data_Maybe.Nothing.value
                }, {
                    key: "pricePerUnitLabel",
                    label: React_Basic_DOM.text(""),
                    cell: new TacoTable_Types.JSXCell([  ], pricePerUnitLabelCell),
                    sortProperty: TacoTable_Types.noSortProperty,
                    width: new TacoTable_Types.FlexMinWidth(1, "4rem"),
                    headerJSX: Data_Maybe.Nothing.value
                }, {
                    key: "unitConsumptionRate",
                    label: React_Basic_DOM.text("Menekki"),
                    cell: unitConsumptionRateCell,
                    sortProperty: TacoTable_Types.noSortProperty,
                    width: new TacoTable_Types.FlexMinWidth(1, "5rem"),
                    headerJSX: Data_Maybe.Nothing.value
                }, {
                    key: "unitConsumptionRateUnit",
                    label: React_Basic_DOM.text(""),
                    cell: new TacoTable_Types.JSXCell([  ], unitConsumptionRateUnitCell),
                    sortProperty: TacoTable_Types.noSortProperty,
                    width: new TacoTable_Types.FlexMinWidth(1, "4rem"),
                    headerJSX: Data_Maybe.Nothing.value
                } ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(workAchievementCells)([ {
                    key: "wastePercentage",
                    label: React_Basic_DOM.text(""),
                    cell: wastePercentageCell,
                    sortProperty: TacoTable_Types.noSortProperty,
                    width: new TacoTable_Types.FlexMinWidth(1, "5rem"),
                    headerJSX: Data_Maybe.Nothing.value
                }, {
                    key: "totalAmount",
                    label: React_Basic_DOM.text("M\xe4\xe4r\xe4"),
                    cell: amountCell(component$prime)(amountPrecision),
                    sortProperty: TacoTable_Types.noSortProperty,
                    width: new TacoTable_Types.FlexMinWidth(2, "5rem"),
                    headerJSX: Data_Maybe.Nothing.value
                }, {
                    key: "pricePerComponent",
                    label: totalPriceLabel,
                    cell: pricePerComponentCell,
                    sortProperty: TacoTable_Types.noSortProperty,
                    width: new TacoTable_Types.FlexMinWidth(2, "7rem"),
                    headerJSX: Data_Maybe.Nothing.value
                }, {
                    key: "pricePerComponentUnit",
                    label: pricePerComponentLabel,
                    cell: new TacoTable_Types.JSXCell([  ], pricePerComponentUnitLabelCell),
                    sortProperty: TacoTable_Types.noSortProperty,
                    width: new TacoTable_Types.FlexMinWidth(1, "4rem"),
                    headerJSX: Data_Maybe.Nothing.value
                }, {
                    key: "totalPrice",
                    label: totalPriceLabel,
                    cell: totalPriceCell(component$prime),
                    sortProperty: TacoTable_Types.noSortProperty,
                    width: new TacoTable_Types.FlexMinWidth(2, "7rem"),
                    headerJSX: Data_Maybe.Nothing.value
                }, {
                    key: "totalPriceUnit",
                    label: React_Basic_DOM.text(currencySymbol),
                    cell: new TacoTable_Types.JSXCell([  ], totalPriceUnit),
                    sortProperty: TacoTable_Types.noSortProperty,
                    width: new TacoTable_Types.Fixed("2rem"),
                    headerJSX: Data_Maybe.Nothing.value
                } ]))));
            };
        };
    };
};
var _estimationResourcesListImpl = function (props) {
    return function __do() {
        var appDispatch = StateHooks.useDispatch();
        var v = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var v1 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var v2 = TofuHooks.useState(false)();
        var v3 = TofuHooks.useState(false)();
        var v4 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var clipboard = StateHooks.useSelector(function (v5) {
            return v5.clipboard;
        })();
        TofuHooks.useEffect([ ReactHooksUtils.utf(props.resourcesForComponent) ])(function __do() {
            props.setSelectedResourceIds(function (v5) {
                return Data_Set.empty;
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        })();
        var groupsLicenseEnabled = UseLicenseFeature.useLicenseFeature(UseLicenseFeature.ComponentGroups.value)();
        var validCostClassCodes = Data_Set.fromFoldable(Data_Foldable.foldableArray)(Types_Newtypes.ordCostClassCode)(Data_Functor.map(Data_Functor.functorArray)(function (v5) {
            return v5.costClassCode;
        })(props.costClasses));
        var resourceActionType = (function () {
            if (props.componentSplitParams instanceof Data_Maybe.Nothing) {
                return Types_Estimation.InPlaceResourceUpdate.value;
            };
            if (props.componentSplitParams instanceof Data_Maybe.Just) {
                return new Types_Estimation.SplitElementComponent(props.componentSplitParams.value0);
            };
            throw new Error("Failed pattern match at EstimationResourcesList (line 201, column 26 - line 203, column 50): " + [ props.componentSplitParams.constructor.name ]);
        })();
        var resourceColumns = getResourceColumns(resourceActionType)(props)(props.setSelectedResourceIds)(groupsLicenseEnabled);
        var removeWillChange = Box.boxStyle([ Box.Style.create(React_Basic_Emotion.css()({
            willChange: React_Basic_Emotion.str("auto")
        })) ]);
        var pasteDisabled = Data_Array.length(clipboard.resources) === 0;
        var modalBody = TacoModal.modalBody({
            contents: (function () {
                var mapMatchedResources = function (r) {
                    var $188 = Data_Foldable.elem(Data_Set.foldableSet)(Types_Component.eqEstimationResourceId)(r.id)(props.selectedResourceIds);
                    if ($188) {
                        return new Data_Maybe.Just(r.name);
                    };
                    return Data_Maybe.Nothing.value;
                };
                var resourceNames = Data_Array.sort(Data_Ord.ordString)(Data_Array.mapMaybe(mapMatchedResources)(props.resourcesForComponent));
                return [ Box.box("removeConfirmation")([ new Box.MarginBottom(TacoConstants.M.value) ])([ React_Basic_DOM.text("Haluatko varmasti poistaa n\xe4m\xe4 panokset?") ]), Box.box("resourceNames")([  ])([ React_Basic_DOM.text(Data_Array.intercalate(Data_Monoid.monoidString)(", ")(resourceNames)) ]) ];
            })()
        });
        var mkResourceWithState = function (v5) {
            return function (isNewResource) {
                return function (resource) {
                    return {
                        resource: resource,
                        componentId: props.componentId,
                        isSelected: Data_Set.member(Types_Component.ordEstimationResourceId)(resource.id)(props.selectedResourceIds),
                        selectedElementSpecId: Data_Functor.map(Data_Maybe.functorMaybe)(function (v6) {
                            return v6.elementSpecId;
                        })(props.componentSplitParams),
                        componentAmount: props.componentAmount,
                        socialExpensePercentage: Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Data_Function.flip(Data_Map_Internal.lookup(Types_Newtypes.ordCostClassCode))(props.socialExpensePercentagesByCostClass))(Data_Nullable.toMaybe(resource.costClassCode)),
                        showWorkAchievement: props.showWorkAchievement,
                        classificationGroupCodePlaceholder: v5.classificationGroupCodePlaceholder,
                        isNewResource: isNewResource,
                        hourlyPricing: v5.costClass.hourlyPricing
                    };
                };
            };
        };
        var mkResourceRow = function (v5) {
            return function (v6) {
                return React_Basic.keyed(Data_Show.show(Types_Component.showEstimationResourceId)(v6.resource.id))(TacoTable_Row.row(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "socialExpensePercentage";
                    }
                })(Data_Maybe.eqMaybe(Types_Unit.eqSocialExpensePercentage)))()({
                    reflectSymbol: function () {
                        return "showWorkAchievement";
                    }
                })(Data_Eq.eqBoolean))()({
                    reflectSymbol: function () {
                        return "selectedElementSpecId";
                    }
                })(Data_Maybe.eqMaybe(Types_Newtypes.eqElementSpecId)))()({
                    reflectSymbol: function () {
                        return "resource";
                    }
                })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "wastePercentage";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "vendorUnit";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "vendorCode";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "vendorBatchSize";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                    reflectSymbol: function () {
                        return "vendor";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "validDate";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "usagesCount";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "unitConsumptionRate";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "unit";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "taxPercentage";
                    }
                })(Types_Unit.eqTaxrate))()({
                    reflectSymbol: function () {
                        return "sortingNumber";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "resourceClassificationGroup";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "projectId";
                    }
                })(Data_Nullable.eqNullable(Types_Project.eqProjectId)))()({
                    reflectSymbol: function () {
                        return "priceWithDiscount";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "pricePerUnit";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                    reflectSymbol: function () {
                        return "orgResourcePriceListId";
                    }
                })(Data_Nullable.eqNullable(Types_Component.eqOrgEstimationResourceId)))()({
                    reflectSymbol: function () {
                        return "orgResourcePriceId";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqInt)))()({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(Data_Eq.eqString))()({
                    reflectSymbol: function () {
                        return "multiplier";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "isCostClassPrice";
                    }
                })(Data_Eq.eqBoolean))()({
                    reflectSymbol: function () {
                        return "index";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Component.eqEstimationResourceId))()({
                    reflectSymbol: function () {
                        return "group";
                    }
                })(Data_Nullable.eqNullable(Types_ClassificationGroup.eqClassificationGroupCode)))()({
                    reflectSymbol: function () {
                        return "estimationComponentId";
                    }
                })(Data_Nullable.eqNullable(Types_Component.eqComponentId)))()({
                    reflectSymbol: function () {
                        return "eanCode";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "discountPercentage";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "discountGroupCode";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "currencyId";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "costClassCode";
                    }
                })(Data_Nullable.eqNullable(Types_Newtypes.eqCostClassCode)))()({
                    reflectSymbol: function () {
                        return "contract";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "changeDate";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))))()({
                    reflectSymbol: function () {
                        return "isSelected";
                    }
                })(Data_Eq.eqBoolean))()({
                    reflectSymbol: function () {
                        return "isNewResource";
                    }
                })(Data_Eq.eqBoolean))()({
                    reflectSymbol: function () {
                        return "hourlyPricing";
                    }
                })(Data_Eq.eqBoolean))()({
                    reflectSymbol: function () {
                        return "componentId";
                    }
                })(Types_Component.eqComponentId))()({
                    reflectSymbol: function () {
                        return "componentAmount";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "classificationGroupCodePlaceholder";
                    }
                })(Data_Eq.eqString)))({
                    rowData: v6,
                    css: React_Basic_Emotion.css()({}),
                    rowHeight: TacoTable_Types.RowMedium.value,
                    foldingElement: Data_Maybe.Nothing.value,
                    onClick: function (v7) {
                        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                    },
                    className: "",
                    sortProperty: Data_Maybe.Nothing.value,
                    columns: resourceColumns,
                    styleSet: CostEstimation_Styles.costClassResourceRowStyleSet({
                        unused: false
                    }),
                    style: React_Basic_DOM_Internal.css({}),
                    rowKey: "resource-" + (Data_Show.show(Types_Component.showEstimationResourceId)(v6.resource.id) + ("-" + Data_Show.show(Types_Newtypes.showElementSpecId)(Data_Maybe.fromMaybe(-1 | 0)(v6.selectedElementSpecId))))
                }));
            };
        };
        var matchCC = function (code) {
            return function (r) {
                return Data_Eq.eq(Data_Nullable.eqNullable(Types_Newtypes.eqCostClassCode))(r.costClassCode)(Utils.pureNullable(code));
            };
        };
        var targetCostClassHasCostClassPrice = (function () {
            if (v4.value0 instanceof Data_Maybe.Nothing) {
                return false;
            };
            if (v4.value0 instanceof Data_Maybe.Just) {
                var matching = Data_Array.filter(matchCC(v4.value0.value0.id))(props.resourcesForComponent);
                var isCostClassPrices = Data_Array.filter(function (v5) {
                    return v5.isCostClassPrice;
                })(matching);
                return Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Array["null"])(isCostClassPrices);
            };
            throw new Error("Failed pattern match at EstimationResourcesList (line 398, column 40 - line 403, column 41): " + [ v4.value0.constructor.name ]);
        })();
        var dispatchEstimationResourceAction = function (action) {
            return appDispatch(new Actions.EstimationResourceActionRequest(action));
        };
        var pasteResourceRequest = function (pasteResource) {
            return dispatchEstimationResourceAction({
                projectId: props.projectId,
                params: mkPasteAction(resourceActionType)(props.componentId)(props.resourcesForComponent)(pasteResource),
                handler: Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)))
            });
        };
        var deleteResourceButton$prime = TacoButton.component()()({
            text: "Poista panokset",
            color: TacoConstants.resourceColors.border,
            onClick: new Data_Maybe.Just(v.value1(function (v5) {
                return new Data_Maybe.Just(Data_Array.fromFoldable(Data_Set.foldableSet)(props.selectedResourceIds));
            })),
            size: TacoConstants.L.value,
            buttonStyle: TacoButton_Types.Filled.value,
            testId: Data_Show.show(Types_CostEstimation.showECViewMode)(props.viewMode) + "-delete-resource-button"
        });
        var deleteResourceButton = (function () {
            var $200 = Data_Set.isEmpty(props.selectedResourceIds);
            if ($200) {
                return React_Basic.empty;
            };
            return deleteResourceButton$prime;
        })();
        var costClassOptions = [ {
            list: renderCostClassOptions(props.costClasses),
            text: Data_Maybe.Nothing.value
        } ];
        var copyResourceAction = function (resourceIds) {
            var findMatchingId = function (resource) {
                return Data_Set.member(Types_Component.ordEstimationResourceId)(resource.id)(resourceIds);
            };
            var selectedResources = Data_Array.filter(findMatchingId)(props.resourcesForComponent);
            var $201 = Data_Array.length(selectedResources) === 0;
            if ($201) {
                return appDispatch(new Actions.CopyResourcesToClipboard(props.resourcesForComponent));
            };
            return appDispatch(new Actions.CopyResourcesToClipboard(selectedResources));
        };
        var copyResourceButton = TacoButton.component()()({
            text: (function () {
                var $202 = Data_Set.isEmpty(props.selectedResourceIds);
                if ($202) {
                    return "Kopioi kaikki panokset";
                };
                return "Kopioi panokset";
            })(),
            color: TacoConstants.resourceColors.border,
            onClick: new Data_Maybe.Just(copyResourceAction(props.selectedResourceIds)),
            size: TacoConstants.L.value,
            buttonStyle: TacoButton_Types.Filled.value,
            testId: Data_Show.show(Types_CostEstimation.showECViewMode)(props.viewMode) + "-copy-resource-button"
        });
        var component$prime = {
            amount: props.componentAmount
        };
        var displayInvalidResource = function (r) {
            var totalPrice = CostEstimation_Calculations.resourceTotalPrice(props.socialExpensePercentagesByCostClass)(component$prime)(r);
            var totalPrice$prime = Formatters.formatCurrencyValue(props.projectCurrency)(totalPrice);
            var code = Utils.nullable("")(Data_Newtype.unwrap())(r.costClassCode);
            var text = "Virheellinen panoslaji panoksella '" + (code + ("'" + (": '" + (r.name + ("' (" + (totalPrice$prime + ")"))))));
            return TacoText.component()()({
                text: text,
                weight: TacoText_Types.Bold.value,
                backgroundColor: TacoConstants.DangerRedLight.value,
                size: TacoConstants.L.value
            });
        };
        var invalidResourcesRows = (function () {
            var invalidResources = Data_Function.flip(Data_Array.filter)(props.resourcesForComponent)(function (res) {
                var v5 = Data_Nullable.toMaybe(res.costClassCode);
                if (v5 instanceof Data_Maybe.Nothing) {
                    return false;
                };
                if (v5 instanceof Data_Maybe.Just) {
                    return Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean)))(Data_Set.member(Types_Newtypes.ordCostClassCode))(v5.value0)(validCostClassCodes);
                };
                throw new Error("Failed pattern match at EstimationResourcesList (line 514, column 11 - line 516, column 65): " + [ v5.constructor.name ]);
            });
            return React_Basic.fragment(Data_Functor.map(Data_Functor.functorArray)(displayInvalidResource)(invalidResources));
        })();
        var closeDeleteResourceModal = v.value1(function (v5) {
            return Data_Maybe.Nothing.value;
        });
        var modalActions = function (selectedResourceIds$prime) {
            return TacoModal.modalActions({
                contents: [ TacoButton.component()()({
                    onClick: new Data_Maybe.Just(closeDeleteResourceModal),
                    text: "Peruuta",
                    testId: "cancel-delete-resources-button"
                }), TacoButton.component()()({
                    text: "Poista",
                    color: TacoConstants.SecondaryRed.value,
                    onClick: new Data_Maybe.Just(function __do() {
                        dispatchEstimationResourceAction({
                            projectId: props.projectId,
                            params: {
                                actionType: resourceActionType,
                                actionParams: new Types_Estimation.DeleteEstimationResources(selectedResourceIds$prime)
                            },
                            handler: Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)))
                        })();
                        props.setSelectedResourceIds(function (v5) {
                            return Data_Set.empty;
                        })();
                        return closeDeleteResourceModal();
                    }),
                    testId: "submit-delete-resources-button"
                }) ]
            });
        };
        var deleteResourceModal = (function () {
            if (v.value0 instanceof Data_Maybe.Nothing) {
                return React_Basic.empty;
            };
            if (v.value0 instanceof Data_Maybe.Just) {
                return TacoModal.component()()({
                    isActive: true,
                    onClose: closeDeleteResourceModal,
                    heading: "Poista panokset",
                    contents: [ modalBody, modalActions(v.value0.value0) ]
                });
            };
            throw new Error("Failed pattern match at EstimationResourcesList (line 483, column 27 - line 493, column 10): " + [ v.value0.constructor.name ]);
        })();
        var closeChangeResourceCostClassModal = v3.value1(function (v5) {
            return false;
        });
        var clipboardContainsCostClassResources = !(Data_Array.length(Data_Array.filter(function (v5) {
            return v5.isCostClassPrice;
        })(clipboard.resources)) === 0);
        var pasteResourceButton = TacoButton.component()()({
            text: (function () {
                if (clipboardContainsCostClassResources) {
                    return "Liit\xe4 hinta";
                };
                return "Liit\xe4 panokset";
            })(),
            color: TacoConstants.resourceColors.border,
            onClick: new Data_Maybe.Just(pasteResourceRequest(clipboard.resources)),
            disabled: pasteDisabled,
            size: TacoConstants.L.value,
            buttonStyle: TacoButton_Types.Filled.value,
            testId: Data_Show.show(Types_CostEstimation.showECViewMode)(props.viewMode) + "-paste-resource-button"
        });
        var changeResourceModal = (function () {
            var mResourceId = Data_Array.head(Data_Set.toUnfoldable(Data_Unfoldable.unfoldableArray)(props.selectedResourceIds));
            var mResource = Data_Array.find(function (r) {
                return Data_Eq.eq(Data_Maybe.eqMaybe(Types_Component.eqEstimationResourceId))(new Data_Maybe.Just(r.id))(mResourceId);
            })(props.resourcesForComponent);
            if (mResource instanceof Data_Maybe.Just) {
                var resourcesForCostClass = (function () {
                    var justEqId = function (r) {
                        var v5 = Data_Nullable.toMaybe(r.costClassCode);
                        if (v5 instanceof Data_Maybe.Just) {
                            return Data_Eq.eq(Data_Maybe.eqMaybe(Types_Newtypes.eqCostClassCode))(new Data_Maybe.Just(v5.value0))(Data_Nullable.toMaybe(mResource.value0.costClassCode));
                        };
                        return false;
                    };
                    return Data_Array.filter(justEqId)(props.resourcesForComponent);
                })();
                var costClassResourceRow = Data_Array.find(function (v5) {
                    return v5.isCostClassPrice;
                })(resourcesForCostClass);
                var costClassGroupStr = Data_Functor.map(Data_Maybe.functorMaybe)(Data_Newtype.un()(Types_ClassificationGroup.ClassificationGroupCode))(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function ($268) {
                    return Data_Nullable.toMaybe((function (v5) {
                        return v5.group;
                    })($268));
                })(costClassResourceRow));
                var componentCostGlassGroupStr = Data_Maybe.maybe("")(Data_Newtype.un()(Types_ClassificationGroup.ClassificationGroupCode))(props.componentGroupCode);
                var classificationGroupCodePlaceholder = Data_Maybe.fromMaybe(componentCostGlassGroupStr)(costClassGroupStr);
                return CostEstimation_SelectResource.selectResourceComponent({
                    projectId: props.projectId,
                    activeResource: new Data_Maybe.Just(mResource.value0),
                    componentId: props.componentId,
                    isSelected: Data_Set.member(Types_Component.ordEstimationResourceId)(mResource.value0.id)(props.selectedResourceIds),
                    componentAmount: props.componentAmount,
                    classificationGroupCodePlaceholder: classificationGroupCodePlaceholder,
                    componentUnit: props.componentUnit,
                    socialExpensePercentagesByCostClass: props.socialExpensePercentagesByCostClass,
                    showWorkAchievement: props.showWorkAchievement,
                    modalOpen: v2.value0,
                    selectedCostClassCode: Data_Maybe.Nothing.value,
                    closeModal: v2.value1(function (v5) {
                        return false;
                    }),
                    resourcesForComponent: props.resourcesForComponent,
                    componentSplitParams: props.componentSplitParams,
                    projectCurrency: props.projectCurrency
                });
            };
            if (mResource instanceof Data_Maybe.Nothing) {
                return React_Basic.empty;
            };
            throw new Error("Failed pattern match at EstimationResourcesList (line 287, column 7 - line 317, column 28): " + [ mResource.constructor.name ]);
        })();
        var changeResourceCostClassButton = React_Basic.fragment([ TacoButton.component()()({
            text: "Vaihda panoslaji",
            buttonStyle: TacoButton_Types.Filled.value,
            color: TacoConstants.resourceColors.border,
            size: TacoConstants.L.value,
            onClick: new Data_Maybe.Just(v3.value1(function (v5) {
                return true;
            })),
            testId: Data_Show.show(Types_CostEstimation.showECViewMode)(props.viewMode) + "-change-resource-cost-class-button"
        }) ]);
        var changeResourceButton = TacoButton.component()()({
            text: "Vaihda panos",
            onClick: new Data_Maybe.Just(v2.value1(function (v5) {
                return true;
            })),
            buttonStyle: TacoButton_Types.Filled.value,
            color: TacoConstants.resourceColors.border,
            testId: Data_Show.show(Types_CostEstimation.showECViewMode)(props.viewMode) + "-change-resource-button"
        });
        var changeCostClassModalActions = function (selectedResourceIds$prime) {
            return TacoModal.modalActions({
                contents: [ TacoButton.component()()({
                    onClick: new Data_Maybe.Just(function __do() {
                        v4.value1(function (v5) {
                            return Data_Maybe.Nothing.value;
                        })();
                        return closeChangeResourceCostClassModal();
                    }),
                    text: "Peruuta",
                    testId: "cancel-change-resource-cost-class-button"
                }), TacoButton.component()()({
                    text: "Vaihda panoslaji",
                    color: TacoConstants.PrimaryBlue.value,
                    disabled: Utils.isNothing(v4.value0),
                    onClick: new Data_Maybe.Just((function () {
                        var matchCostClassCode = function (ccCode) {
                            return function (costClass) {
                                return Data_Newtype.unwrap()(costClass.costClassCode) === ccCode;
                            };
                        };
                        var findCostClass = function (ccCode) {
                            return Data_Array.find(matchCostClassCode(ccCode))(props.costClasses);
                        };
                        var selectedCostClass = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(findCostClass)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v5) {
                            return v5.id;
                        })(v4.value0));
                        if (selectedCostClass instanceof Data_Maybe.Nothing) {
                            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                        };
                        if (selectedCostClass instanceof Data_Maybe.Just) {
                            return function __do() {
                                dispatchEstimationResourceAction({
                                    projectId: props.projectId,
                                    params: {
                                        actionType: resourceActionType,
                                        actionParams: new Types_Estimation.SetEstimationResourcesCostClass({
                                            costClassCode: (function (v5) {
                                                return v5.costClassCode;
                                            })(selectedCostClass.value0),
                                            estimationResourceIds: Data_Array.fromFoldable(Data_Set.foldableSet)(selectedResourceIds$prime)
                                        })
                                    },
                                    handler: Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)))
                                })();
                                props.setSelectedResourceIds(function (v5) {
                                    return Data_Set.empty;
                                })();
                                v4.value1(function (v5) {
                                    return Data_Maybe.Nothing.value;
                                })();
                                return closeChangeResourceCostClassModal();
                            };
                        };
                        throw new Error("Failed pattern match at EstimationResourcesList (line 445, column 19 - line 462, column 56): " + [ selectedCostClass.constructor.name ]);
                    })()),
                    testId: "submit-change-resource-cost-class-button"
                }) ]
            });
        };
        var changeCostClassBody$prime = [ TacoText.component()()({
            text: "Valitse panoslaji",
            weight: TacoText_Types.Bold.value,
            variant: new TacoText_Types.Heading(4)
        }), TacoAutocomplete.component()()({
            sections: costClassOptions,
            placeholder: "Valitse panoslaji",
            onSelect: function (item) {
                return v4.value1(function (v5) {
                    return item;
                });
            },
            selected: v4.value0,
            clearButton: false
        }), TacoText.component()()({
            text: (function () {
                if (targetCostClassHasCostClassPrice) {
                    return "Varoitus: T\xe4m\xe4 poistaa olemassaolevan panoslaji hinnan!";
                };
                return "";
            })(),
            weight: TacoText_Types.Bold.value,
            variant: TacoText_Types.Paragraph.value,
            color: TacoConstants.Red.value
        }) ];
        var changeCostClassBody = TacoModal.modalBody({
            contents: changeCostClassBody$prime
        });
        var changeResourceCostClassModal = (function () {
            if (v3.value0) {
                return TacoModal.component()()({
                    isActive: true,
                    onClose: closeChangeResourceCostClassModal,
                    heading: "Vaihda panoslaji",
                    contents: [ changeCostClassBody, changeCostClassModalActions(props.selectedResourceIds) ],
                    containerStyles: [ new Box.MinHeight(new TacoConstants.CustomSize("30rem")) ]
                });
            };
            return React_Basic.empty;
        })();
        var canEdit = EstimationUtils.canEditView(props);
        var floatingActionButtons = React_Basic.fragment(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ Data_Monoid.guard(React_Basic.monoidJSX)(canEdit && !pasteDisabled)(pasteResourceButton), Data_Monoid.guard(React_Basic.monoidJSX)(canEdit)(copyResourceButton), Data_Monoid.guard(React_Basic.monoidJSX)(canEdit && Data_Set.size(props.selectedResourceIds) === 1)(changeResourceButton), Data_Monoid.guard(React_Basic.monoidJSX)(canEdit)(deleteResourceButton), Data_Monoid.guard(React_Basic.monoidJSX)(canEdit && !Data_Set.isEmpty(props.selectedResourceIds))(changeResourceCostClassButton) ])(props.extraControls));
        var addNewResourceModal$prime = function (v5) {
            return CostEstimation_SelectResource.selectResourceComponent({
                projectId: props.projectId,
                componentUnit: props.componentUnit,
                socialExpensePercentagesByCostClass: props.socialExpensePercentagesByCostClass,
                showWorkAchievement: props.showWorkAchievement,
                modalOpen: Data_Maybe.isJust(v1.value0),
                selectedCostClassCode: v1.value0,
                closeModal: v1.value1(function (v6) {
                    return Data_Maybe.Nothing.value;
                }),
                resourcesForComponent: props.resourcesForComponent,
                activeResource: Data_Maybe.Nothing.value,
                componentId: props.componentId,
                isSelected: false,
                componentAmount: props.componentAmount,
                classificationGroupCodePlaceholder: "",
                componentSplitParams: props.componentSplitParams,
                projectCurrency: props.projectCurrency
            });
        };
        var addNewResourceModal = (function () {
            var $216 = canEdit && Data_Maybe.isJust(v1.value0);
            if ($216) {
                return addNewResourceModal$prime(Data_Unit.unit);
            };
            return React_Basic.empty;
        })();
        var addNewResourceButton = function (costClass) {
            return React_Basic.fragment([ TacoButton.component()()({
                text: "+ Hae panos",
                buttonStyle: TacoButton_Types.Link.value,
                color: TacoConstants.resourceColors.border,
                onClick: new Data_Maybe.Just(v1.value1(function (v5) {
                    return new Data_Maybe.Just(costClass);
                })),
                testId: Data_Show.show(Types_CostEstimation.showECViewMode)(props.viewMode) + ("-cost-class-" + (Data_Newtype.unwrap()(costClass.costClassCode) + "-add-new-resource-button"))
            }) ]);
        };
        var actualResourceRows = function (xs) {
            var filtered = Data_Array.filter(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(function (v5) {
                return v5.isCostClassPrice;
            }))(xs);
            var v5 = Data_Array.length(filtered);
            if (v5 === 0) {
                return Common.emptyArray;
            };
            if (v5 !== Data_Array.length(xs)) {
                return xs;
            };
            return filtered;
        };
        var renderResourceTableForCostClass = function (costClass) {
            var workAchievementLabel = (function () {
                var $218 = props.showWorkAchievement && costClass.hourlyPricing;
                if ($218) {
                    return "Ty\xf6saavutus";
                };
                return "";
            })();
            var socialExpensePercentage = Data_Maybe.maybe(0.0)((function () {
                var $269 = Data_Newtype.un()(Types_Unit.PercentageOfZeroToOne);
                var $270 = Data_Newtype.un()(Types_Unit.SocialExpensePercentage);
                return function ($271) {
                    return $269($270($271));
                };
            })())(Data_Map_Internal.lookup(Types_Newtypes.ordCostClassCode)(costClass.costClassCode)(props.socialExpensePercentagesByCostClass));
            var socExpPercentageForCostClass = Data_Maybe.maybe(0.0)((function () {
                var $272 = Data_Newtype.un()(Types_Unit.PercentageOfZeroToOne);
                var $273 = Data_Newtype.un()(Types_Unit.SocialExpensePercentage);
                return function ($274) {
                    return $272($273($274));
                };
            })())(Data_Map_Internal.lookup(Types_Newtypes.ordCostClassCode)(costClass.costClassCode)(props.socialExpensePercentagesByCostClass));
            var resourcesForCostClass = (function () {
                var justEqId = function (resource) {
                    var v5 = Data_Nullable.toMaybe(resource.costClassCode);
                    if (v5 instanceof Data_Maybe.Just) {
                        return Data_Eq.eq(Types_Newtypes.eqCostClassCode)(v5.value0)(costClass.costClassCode);
                    };
                    return false;
                };
                return Data_Array.filter(justEqId)(props.resourcesForComponent);
            })();
            var rowData = {
                id: props.componentId,
                amount: props.componentAmount,
                unit: props.componentUnit,
                resourcesForCostClass: resourcesForCostClass,
                selectedElementSpecId: Data_Functor.map(Data_Maybe.functorMaybe)(function (v5) {
                    return v5.elementSpecId;
                })(props.componentSplitParams),
                selectedResourceIds: props.selectedResourceIds,
                showWorkAchievement: props.showWorkAchievement,
                socExp: props.socialExpensePercentagesByCostClass
            };
            var totalPrice = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(CostEstimation_Calculations.resourceTotalPrice(props.socialExpensePercentagesByCostClass)(component$prime))(resourcesForCostClass));
            var unused = totalPrice === 0.0;
            var priceWithoutSocialExpenses = function (price) {
                return price / (1.0 + socExpPercentageForCostClass);
            };
            var pricePerComponent = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(CostEstimation_Calculations.resourceUnitPrice(props.socialExpensePercentagesByCostClass))(resourcesForCostClass));
            var mkSetResourcePriceAction = function (resourcePriceParams) {
                var params = {
                    projectId: props.projectId,
                    params: {
                        actionType: resourceActionType,
                        actionParams: new Types_Estimation.EditEstimationCostClassPrice({
                            resourceId: resourcePriceParams.estimationResourceId,
                            componentId: resourcePriceParams.estimationComponentId,
                            costClassCode: resourcePriceParams.costClassCode,
                            pricePerUnit: resourcePriceParams.pricePerUnit
                        })
                    },
                    handler: resourcePriceParams.handler
                };
                var action = new Actions.EstimationResourceActionRequest(params);
                return action;
            };
            var mkPriceUpdateAction = function (newPrice) {
                return function (handler) {
                    return mkSetResourcePriceAction({
                        estimationComponentId: props.componentId,
                        costClassCode: costClass.costClassCode,
                        pricePerUnit: newPrice,
                        estimationResourceId: Data_Functor.map(Data_Maybe.functorMaybe)(function (v5) {
                            return v5.id;
                        })(Data_Array.find(function (v5) {
                            return v5.isCostClassPrice;
                        })(resourcesForCostClass)),
                        handler: handler
                    });
                };
            };
            var key = Data_Newtype.un()(Types_Newtypes.CostClassCode)(costClass.costClassCode);
            var isCostClassPrice = Data_Array["null"](actualResourceRows(resourcesForCostClass));
            var pricePerComponentInput = function (v5) {
                return TacoInput.remoteNumberField()()({
                    value: pricePerComponent,
                    mkAction: function ($275) {
                        return mkPriceUpdateAction(priceWithoutSocialExpenses($275));
                    },
                    align: TacoInput.RightAlign.value,
                    precision: 2,
                    rendering: new TacoInput_Styles.TableCellRendering(TacoTable_Types.RowSmall.value),
                    disabled: !isCostClassPrice,
                    testId: Data_Show.show(Types_CostEstimation.showECViewMode)(props.viewMode) + ("-cost-class-" + (Data_Show.show(Types_Newtypes.showCostClassCode)(costClass.costClassCode) + "-price-per-component-input"))
                });
            };
            var pricePerComponentCell = (function () {
                if (canEdit) {
                    return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value, TacoTable_Types.Numeric.value ], pricePerComponentInput);
                };
                return new TacoTable_Types.JSXCell([  ], function (v5) {
                    return TacoText.component()()({
                        text: Formatters.formatDecimalValue(2)(pricePerComponent),
                        testId: Data_Show.show(Types_CostEstimation.showECViewMode)(props.viewMode) + ("-cost-class-" + (Data_Show.show(Types_Newtypes.showCostClassCode)(costClass.costClassCode) + "price-per-component"))
                    });
                });
            })();
            var totalPriceInput = function (v5) {
                return TacoInput.remoteNumberField()()({
                    value: totalPrice,
                    mkAction: function ($276) {
                        return (function (newPrice) {
                            return mkPriceUpdateAction(Utils.defaultZeroDiv(newPrice)(props.componentAmount));
                        })(priceWithoutSocialExpenses($276));
                    },
                    align: TacoInput.RightAlign.value,
                    precision: 2,
                    rendering: new TacoInput_Styles.TableCellRendering(TacoTable_Types.RowSmall.value),
                    disabled: !isCostClassPrice,
                    testId: Data_Show.show(Types_CostEstimation.showECViewMode)(props.viewMode) + ("-cost-class-" + (Data_Show.show(Types_Newtypes.showCostClassCode)(costClass.costClassCode) + "-total-price-input"))
                });
            };
            var totalPriceCell = (function () {
                if (canEdit) {
                    return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value, TacoTable_Types.Numeric.value ], totalPriceInput);
                };
                return new TacoTable_Types.JSXCell([  ], function (v5) {
                    return TacoText.component()()({
                        text: Formatters.formatDecimalValue(2)(totalPrice),
                        testId: Data_Show.show(Types_CostEstimation.showECViewMode)(props.viewMode) + ("-cost-class-" + (Data_Show.show(Types_Newtypes.showCostClassCode)(costClass.costClassCode) + "total-price"))
                    });
                });
            })();
            var getHourlyPriceWithoutSEP = function (myHourlyPrice) {
                if (props.showSocialExpensesInCosts) {
                    return Utils.defaultZeroDiv(myHourlyPrice)(1.0 + socialExpensePercentage);
                };
                return myHourlyPrice;
            };
            var currencySymbol = EstimationUtils.getCurrencySymbol(props.projectCurrency);
            var pricePerUnitUnitCell = (function () {
                if (costClass.hourlyPricing) {
                    return new TacoTable_Types.PlainTextCell(function (v5) {
                        return currencySymbol + "/h";
                    });
                };
                return TacoTable_Types.emptyCell;
            })();
            var costClassResourceRow = Data_Array.find(function (v5) {
                return v5.isCostClassPrice;
            })(resourcesForCostClass);
            var mkSetResourceClassificationGroupAction = function (value) {
                return function (handler) {
                    var v5 = Data_Functor.map(Data_Maybe.functorMaybe)(function (v6) {
                        return v6.id;
                    })(costClassResourceRow);
                    if (v5 instanceof Data_Maybe.Just) {
                        return new Actions.EstimationResourceActionRequest({
                            projectId: props.projectId,
                            params: {
                                actionType: resourceActionType,
                                actionParams: Types_Estimation.EditEstimationResource.create(Data_Array.singleton({
                                    resourceId: v5.value0,
                                    resourceParams: {
                                        group: Data_Nullable.notNull(value),
                                        changedDate: Types_Estimation.editEstimationResourceDefaultParams.changedDate,
                                        costClassCode: Types_Estimation.editEstimationResourceDefaultParams.costClassCode,
                                        currencyId: Types_Estimation.editEstimationResourceDefaultParams.currencyId,
                                        discountPercentage: Types_Estimation.editEstimationResourceDefaultParams.discountPercentage,
                                        eanCode: Types_Estimation.editEstimationResourceDefaultParams.eanCode,
                                        index: Types_Estimation.editEstimationResourceDefaultParams.index,
                                        name: Types_Estimation.editEstimationResourceDefaultParams.name,
                                        orgResourcePriceId: Types_Estimation.editEstimationResourceDefaultParams.orgResourcePriceId,
                                        orgResourcePriceListId: Types_Estimation.editEstimationResourceDefaultParams.orgResourcePriceListId,
                                        pricePerUnit: Types_Estimation.editEstimationResourceDefaultParams.pricePerUnit,
                                        resourceClassificationGroup: Types_Estimation.editEstimationResourceDefaultParams.resourceClassificationGroup,
                                        sortingNumber: Types_Estimation.editEstimationResourceDefaultParams.sortingNumber,
                                        taxRate: Types_Estimation.editEstimationResourceDefaultParams.taxRate,
                                        unit: Types_Estimation.editEstimationResourceDefaultParams.unit,
                                        unitConsumptionRate: Types_Estimation.editEstimationResourceDefaultParams.unitConsumptionRate,
                                        validDate: Types_Estimation.editEstimationResourceDefaultParams.validDate,
                                        vendor: Types_Estimation.editEstimationResourceDefaultParams.vendor,
                                        vendorBatchSize: Types_Estimation.editEstimationResourceDefaultParams.vendorBatchSize,
                                        vendorCode: Types_Estimation.editEstimationResourceDefaultParams.vendorCode,
                                        vendorUnit: Types_Estimation.editEstimationResourceDefaultParams.vendorUnit,
                                        wastePercentage: Types_Estimation.editEstimationResourceDefaultParams.wastePercentage
                                    }
                                }))
                            },
                            handler: handler
                        });
                    };
                    if (v5 instanceof Data_Maybe.Nothing) {
                        var $227 = Data_Array["null"](resourcesForCostClass);
                        if ($227) {
                            return new Actions.EstimationResourceActionRequest({
                                projectId: props.projectId,
                                params: {
                                    actionType: resourceActionType,
                                    actionParams: new Types_Estimation.AddEstimationResource({
                                        estimationComponentId: props.componentId,
                                        costClassCode: costClass.costClassCode,
                                        name: "KL-" + key,
                                        price: 0.0,
                                        unit: Data_Maybe.Nothing.value,
                                        group: Data_Functor.map(Data_Maybe.functorMaybe)(Types_ClassificationGroup.ClassificationGroupCode)(Utils.stringMaybeEmpty(value)),
                                        isCostClassResource: true,
                                        index: Data_Maybe.Nothing.value,
                                        orgResourcePriceId: Data_Maybe.Nothing.value,
                                        orgResourcePriceListId: Data_Maybe.Nothing.value,
                                        details: Data_Maybe.Nothing.value
                                    })
                                },
                                handler: handler
                            });
                        };
                        return new Actions.EstimationResourceActionRequest({
                            projectId: props.projectId,
                            params: {
                                actionType: resourceActionType,
                                actionParams: new Types_Estimation.SetEstimationResourcesGroup({
                                    group: (function () {
                                        var $228 = Data_String_Common["null"](value);
                                        if ($228) {
                                            return Utils.nullValue;
                                        };
                                        return Utils.pureNullable(value);
                                    })(),
                                    estimationResourceIds: Data_Functor.map(Data_Functor.functorArray)(function (v6) {
                                        return v6.id;
                                    })(resourcesForCostClass)
                                })
                            },
                            handler: handler
                        });
                    };
                    throw new Error("Failed pattern match at EstimationResourcesList (line 706, column 11 - line 757, column 22): " + [ v5.constructor.name ]);
                };
            };
            var costClassResourceIds = Data_Set.fromFoldable(Data_Foldable.foldableArray)(Types_Component.ordEstimationResourceId)(Data_Functor.map(Data_Functor.functorArray)(function (v5) {
                return v5.id;
            })(actualResourceRows(resourcesForCostClass)));
            var costClassLabelCell = function (v5) {
                return Box.box("CostClassLabelWrapper")([ new Box.Gap(TacoConstants.XXS.value), new Box.PaddingRight(TacoConstants.XXS.value) ])([ TacoText.component()()({
                    text: Data_Newtype.un()(Types_Newtypes.CostClassCode)(costClass.costClassCode),
                    weight: TacoText_Types.Bold.value,
                    wrap: TacoText_Types.NoWrap.value
                }), TacoText.component()()({
                    text: " " + costClass.name,
                    weight: TacoText_Types.Bold.value,
                    wrap: TacoText_Types.NoWrap.value
                }) ]);
            };
            var resourceCountCell = function (r) {
                return React_Basic_Emotion.element(React_Basic_DOM_Generated["span'"]())({
                    className: "resource-count-wrapper",
                    css: CostEstimation_Styles.resourceCountWrapperStyles,
                    children: [ costClassLabelCell(r), addNewResourceButton(costClass) ]
                });
            };
            var costClassGroupStr = Data_Functor.map(Data_Maybe.functorMaybe)(Data_Newtype.un()(Types_ClassificationGroup.ClassificationGroupCode))(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function ($277) {
                return Data_Nullable.toMaybe((function (v5) {
                    return v5.group;
                })($277));
            })(costClassResourceRow));
            var consumptionRateUnitCell = (function () {
                if (costClass.hourlyPricing) {
                    return new TacoTable_Types.PlainTextCell(function (v5) {
                        return Data_Maybe.fromMaybe("")(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Semigroup.append(Data_Semigroup.semigroupString)("h/"))(props.componentUnit));
                    });
                };
                return TacoTable_Types.emptyCell;
            })();
            var componentCostGlassGroupStr = Data_Maybe.maybe("")(Data_Newtype.un()(Types_ClassificationGroup.ClassificationGroupCode))(props.componentGroupCode);
            var classificationGroupCodePlaceholder = Data_Maybe.fromMaybe(componentCostGlassGroupStr)(costClassGroupStr);
            var resourcesForCostClassToList = Data_Array.sortWith(Types_Component.ordEstimationResourceId)(function (v5) {
                return v5.resource.id;
            })(Data_Functor.map(Data_Functor.functorArray)(mkResourceWithState({
                classificationGroupCodePlaceholder: classificationGroupCodePlaceholder,
                costClass: costClass
            })(false))(actualResourceRows(resourcesForCostClass)));
            var hasItems = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Array["null"])(resourcesForCostClassToList);
            var resourceRows = (function () {
                if (hasItems) {
                    return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                        className: "resource-rows",
                        css: CostEstimation_Styles.costClassResourceRowsStyles,
                        children: Data_Functor.map(Data_Functor.functorArray)(mkResourceRow(costClass.projectId))(resourcesForCostClassToList)
                    });
                };
                return React_Basic.empty;
            })();
            var classificationCodeInput = function (v5) {
                return TacoInput.remoteStringField()()({
                    value: Data_Maybe.fromMaybe("")(costClassGroupStr),
                    placeholder: classificationGroupCodePlaceholder,
                    mkAction: mkSetResourceClassificationGroupAction,
                    rendering: new TacoInput_Styles.TableCellRendering(TacoTable_Types.RowSmall.value),
                    testId: Data_Show.show(Types_CostEstimation.showECViewMode)(props.viewMode) + ("-cost-class-" + (Data_Newtype.unwrap()(costClass.costClassCode) + "-classification-code-input"))
                });
            };
            var resourceClassificationGroupCell = (function () {
                if (canEdit) {
                    return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], classificationCodeInput);
                };
                return new TacoTable_Types.PlainTextCell(function (v5) {
                    return Data_Maybe.fromMaybe("")(costClassGroupStr);
                });
            })();
            var costClassGroupRow = (function () {
                var $232 = !groupsLicenseEnabled;
                if ($232) {
                    return Common.emptyArray;
                };
                return [ {
                    key: "costClassGroup",
                    label: React_Basic_DOM.text(""),
                    cell: resourceClassificationGroupCell,
                    sortProperty: TacoTable_Types.noSortProperty,
                    width: new TacoTable_Types.FlexMinWidth(1, "6rem"),
                    headerJSX: Data_Maybe.Nothing.value
                } ];
            })();
            var actualResources = actualResourceRows(resourcesForCostClass);
            var actualResourcesAmount = CostEstimation_Calculations.componentTotalAmount(component$prime)(actualResources);
            var hourlyAmount = (function () {
                if (costClassResourceRow instanceof Data_Maybe.Just) {
                    return costClassResourceRow.value0.unitConsumptionRate * props.componentAmount;
                };
                if (costClassResourceRow instanceof Data_Maybe.Nothing) {
                    return actualResourcesAmount;
                };
                throw new Error("Failed pattern match at EstimationResourcesList (line 950, column 24 - line 952, column 43): " + [ costClassResourceRow.constructor.name ]);
            })();
            var unitConsumptionRate = (function () {
                if (costClassResourceRow instanceof Data_Maybe.Nothing) {
                    return Utils.defaultZeroDiv(actualResourcesAmount)(props.componentAmount);
                };
                if (costClassResourceRow instanceof Data_Maybe.Just) {
                    return costClassResourceRow.value0.unitConsumptionRate;
                };
                throw new Error("Failed pattern match at EstimationResourcesList (line 954, column 31 - line 956, column 46): " + [ costClassResourceRow.constructor.name ]);
            })();
            var hourlyResourcesTotal = CostEstimation_Calculations.componentTotalPrice(props.socialExpensePercentagesByCostClass)(component$prime)(actualResources);
            var hourlyPrice = (function () {
                if (costClassResourceRow instanceof Data_Maybe.Just) {
                    return Utils.defaultZeroDiv(CostEstimation_Calculations.resourcePricePerUnit(props.socialExpensePercentagesByCostClass)(costClassResourceRow.value0))(costClassResourceRow.value0.unitConsumptionRate);
                };
                if (costClassResourceRow instanceof Data_Maybe.Nothing) {
                    return Utils.defaultZeroDiv(hourlyResourcesTotal)(hourlyAmount);
                };
                throw new Error("Failed pattern match at EstimationResourcesList (line 869, column 23 - line 885, column 76): " + [ costClassResourceRow.constructor.name ]);
            })();
            var consumptionRateCellInput = function (v5) {
                return TacoInput.remoteNumberField()()({
                    value: unitConsumptionRate,
                    mkAction: function (newUnitConsumptionRate) {
                        return function (handler) {
                            var newUnitPrice = getHourlyPriceWithoutSEP(hourlyPrice) * newUnitConsumptionRate;
                            return Actions.EstimationResourceActionRequest.create({
                                handler: handler,
                                projectId: props.projectId,
                                params: {
                                    actionType: resourceActionType,
                                    actionParams: new Types_Estimation.EditEstimationCostClassPriceAndConsumptionRate({
                                        resourceId: Data_Functor.map(Data_Maybe.functorMaybe)(function (v6) {
                                            return v6.id;
                                        })(costClassResourceRow),
                                        componentId: props.componentId,
                                        costClassCode: costClass.costClassCode,
                                        pricePerUnit: newUnitPrice,
                                        unitConsumptionRate: newUnitConsumptionRate
                                    })
                                }
                            });
                        };
                    },
                    align: TacoInput.RightAlign.value,
                    precision: ratePrecision,
                    rendering: new TacoInput_Styles.TableCellRendering(TacoTable_Types.RowSmall.value),
                    disabled: !isCostClassPrice,
                    testId: Data_Show.show(Types_CostEstimation.showECViewMode)(props.viewMode) + ("-cost-class-" + (Data_Show.show(Types_Newtypes.showCostClassCode)(costClass.costClassCode) + "-consumption-rate-input"))
                });
            };
            var hourlyAmountCellInput = function (r) {
                return TacoInput.remoteNumberField()()({
                    value: hourlyAmount,
                    mkAction: function (newAmount) {
                        return function (handler) {
                            var newUnitConsumptionRate = newAmount / r.amount;
                            var newPricePerUnit = newUnitConsumptionRate * getHourlyPriceWithoutSEP(hourlyPrice);
                            return Actions.EstimationResourceActionRequest.create({
                                handler: handler,
                                projectId: props.projectId,
                                params: {
                                    actionType: resourceActionType,
                                    actionParams: new Types_Estimation.EditEstimationCostClassPriceAndConsumptionRate({
                                        resourceId: Data_Functor.map(Data_Maybe.functorMaybe)(function (v5) {
                                            return v5.id;
                                        })(costClassResourceRow),
                                        componentId: props.componentId,
                                        costClassCode: costClass.costClassCode,
                                        unitConsumptionRate: newUnitConsumptionRate,
                                        pricePerUnit: newPricePerUnit
                                    })
                                }
                            });
                        };
                    },
                    align: TacoInput.RightAlign.value,
                    precision: amountPrecision,
                    rendering: new TacoInput_Styles.TableCellRendering(TacoTable_Types.RowSmall.value),
                    disabled: !isCostClassPrice,
                    title: Data_Monoid.guard(Data_Monoid.monoidString)(!isCostClassPrice)("Panoslajilla on panoksia, et voi muokata panoslajin hintaa. Poistamalla panokset p\xe4\xe4set muokkaamaan panoslajin hintaa"),
                    testId: Data_Show.show(Types_CostEstimation.showECViewMode)(props.viewMode) + ("-cost-class-" + (Data_Show.show(Types_Newtypes.showCostClassCode)(costClass.costClassCode) + "-consumption-rate-input"))
                });
            };
            var hourlyAmountCell = (function () {
                if (canEdit) {
                    return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value, TacoTable_Types.Numeric.value ], hourlyAmountCellInput);
                };
                return new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value ], function (v5) {
                    return TacoText.component()()({
                        text: Formatters.formatDecimalValue(amountPrecision)(hourlyAmount)
                    });
                });
            })();
            var hourlyPricePerUnitLabelCell$prime = new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value ], function (v5) {
                return TacoText.component()()({
                    text: Formatters.formatDecimalValue(pricePrecision)(hourlyPrice)
                });
            });
            var hourlyPricePerUnitLabelCellInput = function (v5) {
                return TacoInput.remoteNumberField()()({
                    value: hourlyPrice,
                    mkAction: function (newHourlyPrice) {
                        return function (handler) {
                            var newHourlyPrice$prime = getHourlyPriceWithoutSEP(newHourlyPrice);
                            var $240 = unitConsumptionRate !== 0.0;
                            if ($240) {
                                return Actions.EstimationResourceActionRequest.create({
                                    handler: handler,
                                    projectId: props.projectId,
                                    params: {
                                        actionType: resourceActionType,
                                        actionParams: new Types_Estimation.EditEstimationCostClassPrice({
                                            resourceId: Data_Functor.map(Data_Maybe.functorMaybe)(function (v6) {
                                                return v6.id;
                                            })(costClassResourceRow),
                                            componentId: props.componentId,
                                            costClassCode: costClass.costClassCode,
                                            pricePerUnit: newHourlyPrice$prime * unitConsumptionRate
                                        })
                                    }
                                });
                            };
                            return Actions.AddAlert.create(Types_Alert.AlertError.value)("Tuntihinta ei tallennu ilman, ett\xe4 lis\xe4\xe4t ty\xf6lle ensin menekin.");
                        };
                    },
                    align: TacoInput.RightAlign.value,
                    precision: pricePrecision,
                    rendering: new TacoInput_Styles.TableCellRendering(TacoTable_Types.RowSmall.value),
                    disabled: !isCostClassPrice || isCostClassPrice && unitConsumptionRate === 0.0,
                    title: Data_Monoid.guard(Data_Monoid.monoidString)(isCostClassPrice && unitConsumptionRate === 0.0)("Tuntihinta ei tallennu ilman, ett\xe4 lis\xe4\xe4t ty\xf6lle ensin menekin"),
                    testId: Data_Show.show(Types_CostEstimation.showECViewMode)(props.viewMode) + ("-cost-class-" + (Data_Show.show(Types_Newtypes.showCostClassCode)(costClass.costClassCode) + "-hourly-price-per-unit-input"))
                });
            };
            var hourlyPricePerUnitLabelCell = (function () {
                var $241 = canEdit && isCostClassPrice;
                if ($241) {
                    return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value, TacoTable_Types.Numeric.value ], hourlyPricePerUnitLabelCellInput);
                };
                return hourlyPricePerUnitLabelCell$prime;
            })();
            var resourcesCount = Data_Array.length(actualResources);
            var hourlyConsumptionRateCell = (function () {
                var $242 = resourcesCount > 0;
                if ($242) {
                    return new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value ], function (v5) {
                        return TacoText.component()()({
                            text: Formatters.formatDecimalValue(ratePrecision)(unitConsumptionRate)
                        });
                    });
                };
                if (canEdit) {
                    return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value, TacoTable_Types.Numeric.value ], consumptionRateCellInput);
                };
                return new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value ], function (v5) {
                    return TacoText.component()()({
                        text: Formatters.formatDecimalValue(ratePrecision)(unitConsumptionRate)
                    });
                });
            })();
            var labelCell = function (text) {
                var $244 = resourcesCount > 0;
                if ($244) {
                    return new TacoTable_Types.JSXCell([  ], function (v5) {
                        return TacoText.component()()({
                            text: text,
                            color: TacoConstants.TextSecondary.value
                        });
                    });
                };
                return new TacoTable_Types.PlainTextCell(function (v5) {
                    return "";
                });
            };
            var amountCell = (function () {
                if (costClass.hourlyPricing) {
                    return hourlyAmountCell;
                };
                return labelCell("M\xe4\xe4r\xe4");
            })();
            var workAchievementHeadingCols = (function () {
                var $246 = !props.showWorkAchievement;
                if ($246) {
                    return Common.emptyArray;
                };
                return [ {
                    key: "workAchievement",
                    label: React_Basic_DOM.text(""),
                    cell: labelCell(workAchievementLabel),
                    sortProperty: TacoTable_Types.noSortProperty,
                    width: new TacoTable_Types.FlexMinWidth(1, "5rem"),
                    headerJSX: Data_Maybe.Nothing.value
                }, {
                    key: "workAchievementUnit",
                    label: React_Basic_DOM.text(""),
                    cell: new TacoTable_Types.PlainTextCell(function (v5) {
                        return "";
                    }),
                    sortProperty: TacoTable_Types.noSortProperty,
                    width: new TacoTable_Types.FlexMinWidth(1, "4rem"),
                    headerJSX: Data_Maybe.Nothing.value
                } ];
            })();
            var labelCellNumeric = function (text) {
                var $247 = resourcesCount > 0;
                if ($247) {
                    return new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value ], function (v5) {
                        return TacoText.component()()({
                            text: text,
                            color: TacoConstants.TextSecondary.value,
                            align: TacoText_Types.Right.value
                        });
                    });
                };
                return new TacoTable_Types.PlainTextCell(function (v5) {
                    return "";
                });
            };
            var nonHourlyConsumptionRateCell = (function () {
                var $248 = resourcesCount > 0;
                if ($248) {
                    return labelCell("Menekki");
                };
                return new TacoTable_Types.PlainTextCell(function (v5) {
                    return "";
                });
            })();
            var consumptionRateCell = (function () {
                if (costClass.hourlyPricing) {
                    return hourlyConsumptionRateCell;
                };
                return nonHourlyConsumptionRateCell;
            })();
            var nonHourlyPricePerUnitLabelCell = (function () {
                var $250 = resourcesCount > 0;
                if ($250) {
                    return new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value ], function (v5) {
                        return Gap.gapX(TacoConstants.XS.value)([ Data_Monoid.guard(React_Basic.monoidJSX)(props.showSocialExpensesInCosts)(SocialExpenseIndicator.socialExpenseIndicator), TacoText.component()()({
                            text: "Hinta",
                            color: TacoConstants.TextSecondary.value,
                            align: TacoText_Types.Right.value
                        }) ]);
                    });
                };
                return new TacoTable_Types.PlainTextCell(function (v5) {
                    return "";
                });
            })();
            var pricePerUnitLabelCell = (function () {
                if (costClass.hourlyPricing) {
                    return hourlyPricePerUnitLabelCell;
                };
                return nonHourlyPricePerUnitLabelCell;
            })();
            var resourceHeadingCols = Data_Semigroup.append(Data_Semigroup.semigroupArray)([ {
                key: "unit",
                label: React_Basic_DOM.text(""),
                cell: labelCell("Yks."),
                sortProperty: TacoTable_Types.noSortProperty,
                width: new TacoTable_Types.FlexMinWidth(1, "4rem"),
                headerJSX: Data_Maybe.Nothing.value
            }, {
                key: "pricePerUnitLabel",
                label: React_Basic_DOM.text(""),
                cell: pricePerUnitLabelCell,
                sortProperty: TacoTable_Types.noSortProperty,
                width: new TacoTable_Types.FlexMinWidth(2, "5rem"),
                headerJSX: Data_Maybe.Nothing.value
            }, {
                key: "pricePerUnitUnit",
                label: React_Basic_DOM.text(""),
                cell: pricePerUnitUnitCell,
                sortProperty: TacoTable_Types.noSortProperty,
                width: new TacoTable_Types.FlexMinWidth(1, "4rem"),
                headerJSX: Data_Maybe.Nothing.value
            }, {
                key: "unitConsumptionRate",
                label: React_Basic_DOM.text(""),
                cell: consumptionRateCell,
                sortProperty: TacoTable_Types.noSortProperty,
                width: new TacoTable_Types.FlexMinWidth(1, "5rem"),
                headerJSX: Data_Maybe.Nothing.value
            }, {
                key: "unitConsumptionRateUnit",
                label: React_Basic_DOM.text(""),
                cell: consumptionRateUnitCell,
                sortProperty: TacoTable_Types.noSortProperty,
                width: new TacoTable_Types.FlexMinWidth(1, "4rem"),
                headerJSX: Data_Maybe.Nothing.value
            } ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(workAchievementHeadingCols)([ {
                key: "wastePercentage",
                label: React_Basic_DOM.text(""),
                cell: labelCellNumeric("Lis\xe4-%"),
                sortProperty: TacoTable_Types.noSortProperty,
                width: new TacoTable_Types.FlexMinWidth(1, "5rem"),
                headerJSX: Data_Maybe.Nothing.value
            }, {
                key: "totalQuantity",
                label: React_Basic_DOM.text(""),
                cell: amountCell,
                sortProperty: TacoTable_Types.noSortProperty,
                width: new TacoTable_Types.FlexMinWidth(2, "5rem"),
                headerJSX: Data_Maybe.Nothing.value
            } ]));
            var selectResourcesCheckbox = function (r) {
                return TacoCheckbox.component()()({
                    className: "resource-checkbox",
                    isChecked: resourcesCount > 0 && Data_Set.subset(Types_Component.ordEstimationResourceId)(costClassResourceIds)(r.selectedResourceIds),
                    labelText: Data_Maybe.Nothing.value,
                    onToggle: new TacoCheckbox.NoEvent(props.setSelectedResourceIds(function (selectedIds) {
                        var $252 = Data_Set.subset(Types_Component.ordEstimationResourceId)(costClassResourceIds)(selectedIds);
                        if ($252) {
                            return Data_Set.difference(Types_Component.ordEstimationResourceId)(selectedIds)(costClassResourceIds);
                        };
                        return Data_Set.union(Types_Component.ordEstimationResourceId)(selectedIds)(costClassResourceIds);
                    })),
                    testId: Data_Show.show(Types_CostEstimation.showECViewMode)(props.viewMode) + ("resource-checkbox-" + Data_Show.show(Data_Show.showString)(costClass.name)),
                    color: TacoConstants.resourceColors.border
                });
            };
            var costClassRow = TacoTable_Row.row(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "unit";
                }
            })(Data_Maybe.eqMaybe(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "socExp";
                }
            })(Data_Map_Internal.eqMap(Types_Newtypes.eqCostClassCode)(Types_Unit.eqSocialExpensePercentage)))()({
                reflectSymbol: function () {
                    return "showWorkAchievement";
                }
            })(Data_Eq.eqBoolean))()({
                reflectSymbol: function () {
                    return "selectedResourceIds";
                }
            })(Data_Set.eqSet(Types_Component.eqEstimationResourceId)))()({
                reflectSymbol: function () {
                    return "selectedElementSpecId";
                }
            })(Data_Maybe.eqMaybe(Types_Newtypes.eqElementSpecId)))()({
                reflectSymbol: function () {
                    return "resourcesForCostClass";
                }
            })(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "wastePercentage";
                }
            })(Data_Eq.eqNumber))()({
                reflectSymbol: function () {
                    return "vendorUnit";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "vendorCode";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "vendorBatchSize";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                reflectSymbol: function () {
                    return "vendor";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "validDate";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "usagesCount";
                }
            })(Data_Eq.eqNumber))()({
                reflectSymbol: function () {
                    return "unitConsumptionRate";
                }
            })(Data_Eq.eqNumber))()({
                reflectSymbol: function () {
                    return "unit";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "taxPercentage";
                }
            })(Types_Unit.eqTaxrate))()({
                reflectSymbol: function () {
                    return "sortingNumber";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "resourceClassificationGroup";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "projectId";
                }
            })(Data_Nullable.eqNullable(Types_Project.eqProjectId)))()({
                reflectSymbol: function () {
                    return "priceWithDiscount";
                }
            })(Data_Eq.eqNumber))()({
                reflectSymbol: function () {
                    return "pricePerUnit";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                reflectSymbol: function () {
                    return "orgResourcePriceListId";
                }
            })(Data_Nullable.eqNullable(Types_Component.eqOrgEstimationResourceId)))()({
                reflectSymbol: function () {
                    return "orgResourcePriceId";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqInt)))()({
                reflectSymbol: function () {
                    return "name";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "multiplier";
                }
            })(Data_Eq.eqNumber))()({
                reflectSymbol: function () {
                    return "isCostClassPrice";
                }
            })(Data_Eq.eqBoolean))()({
                reflectSymbol: function () {
                    return "index";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Component.eqEstimationResourceId))()({
                reflectSymbol: function () {
                    return "group";
                }
            })(Data_Nullable.eqNullable(Types_ClassificationGroup.eqClassificationGroupCode)))()({
                reflectSymbol: function () {
                    return "estimationComponentId";
                }
            })(Data_Nullable.eqNullable(Types_Component.eqComponentId)))()({
                reflectSymbol: function () {
                    return "eanCode";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "discountPercentage";
                }
            })(Data_Eq.eqNumber))()({
                reflectSymbol: function () {
                    return "discountGroupCode";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "currencyId";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "costClassCode";
                }
            })(Data_Nullable.eqNullable(Types_Newtypes.eqCostClassCode)))()({
                reflectSymbol: function () {
                    return "contract";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "changeDate";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString))))))()({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Component.eqComponentId))()({
                reflectSymbol: function () {
                    return "amount";
                }
            })(Data_Eq.eqNumber)))({
                rowData: rowData,
                rowHeight: TacoTable_Types.RowMedium.value,
                css: React_Basic_Emotion.css()({}),
                styleSet: CostEstimation_Styles.costClassSummaryRowStyleSet({
                    unused: unused
                }),
                style: React_Basic_DOM_Internal.css({}),
                foldingElement: Data_Maybe.Nothing.value,
                onClick: function (v5) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                },
                className: "cost-class-summary-row",
                sortProperty: TacoTable_Types.noSortProperty,
                columns: Data_Semigroup.append(Data_Semigroup.semigroupArray)([ {
                    key: "checkbox",
                    label: React_Basic_DOM.text(""),
                    cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], selectResourcesCheckbox),
                    sortProperty: TacoTable_Types.noSortProperty,
                    width: new TacoTable_Types.Fixed("23px"),
                    headerJSX: Data_Maybe.Nothing.value
                } ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(costClassGroupRow)(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ {
                    key: "resourceCount",
                    label: React_Basic_DOM.text(""),
                    cell: new TacoTable_Types.JSXCell([  ], resourceCountCell),
                    sortProperty: TacoTable_Types.noSortProperty,
                    width: new TacoTable_Types.FlexMinWidth(10, "16rem"),
                    headerJSX: Data_Maybe.Nothing.value
                } ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(resourceHeadingCols)([ {
                    key: "pricePerComponent",
                    label: React_Basic_DOM.text(""),
                    cell: pricePerComponentCell,
                    sortProperty: TacoTable_Types.noSortProperty,
                    width: new TacoTable_Types.FlexMinWidth(2, "7rem"),
                    headerJSX: Data_Maybe.Nothing.value
                }, {
                    key: "pricePerComponentUnit",
                    label: React_Basic_DOM.text(""),
                    cell: TacoTable_Types.PlainTextCell.create(function (v5) {
                        return Data_Maybe.fromMaybe("")(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Semigroup.append(Data_Semigroup.semigroupString)(currencySymbol + "/"))(props.componentUnit));
                    }),
                    sortProperty: TacoTable_Types.noSortProperty,
                    width: new TacoTable_Types.FlexMinWidth(1, "4rem"),
                    headerJSX: Data_Maybe.Nothing.value
                }, {
                    key: "totalPrice",
                    label: React_Basic_DOM.text(""),
                    cell: totalPriceCell,
                    sortProperty: TacoTable_Types.noSortProperty,
                    width: new TacoTable_Types.FlexMinWidth(2, "7rem"),
                    headerJSX: Data_Maybe.Nothing.value
                }, {
                    key: "totalPriceUnit",
                    label: React_Basic_DOM.text(""),
                    cell: new TacoTable_Types.PlainTextCell(function (v5) {
                        return currencySymbol;
                    }),
                    sortProperty: TacoTable_Types.noSortProperty,
                    width: new TacoTable_Types.Fixed("2rem"),
                    headerJSX: Data_Maybe.Nothing.value
                } ])))),
                rowKey: "cost-class-row"
            });
            var v5 = (function () {
                var $253 = resourcesCount === 0;
                if ($253) {
                    return Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function ($278) {
                        return Data_Nullable.toMaybe((function (v6) {
                            return v6.group;
                        })($278));
                    })(costClassResourceRow);
                };
                return Data_Maybe.Nothing.value;
            })();
            var elementSpecKey = Data_Maybe.fromMaybe("")(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Functor.map(Data_Functor.functorFn)(Data_Show.show(Types_Newtypes.showElementSpecId))(function (v6) {
                return v6.elementSpecId;
            }))(props.componentSplitParams));
            var costClassKey = Data_Show.show(Types_Newtypes.showCostClassCode)(costClass.costClassCode);
            var componentKey = Data_Show.show(Types_Component.showComponentId)(props.componentId);
            return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                className: "resources-by-cost-class",
                key: "resources-by-cost-class-component-" + (elementSpecKey + ("-" + (componentKey + ("-" + costClassKey)))),
                css: CostEstimation_Styles.costClassResourceTableStyles({
                    unused: unused,
                    hasItems: hasItems
                }),
                children: [ costClassRow, resourceRows ]
            });
        };
        var rows = Data_Functor.map(Data_Functor.functorArray)(renderResourceTableForCostClass)(Data_Array.sortWith(Types_Newtypes.ordCostClassCode)(function (v5) {
            return v5.costClassCode;
        })(props.costClasses));
        var tableContents = (function () {
            var resourceTableHeading = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                className: "resource-tables-heading",
                css: CostEstimation_Styles.estimationResourceListHeaderStyles,
                children: Control_Applicative.pure(Control_Applicative.applicativeArray)(TacoTable_Header.header(TofuHooks.getNameVoid)(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "socialExpensePercentage";
                    }
                })(Data_Maybe.eqMaybe(Types_Unit.eqSocialExpensePercentage)))()({
                    reflectSymbol: function () {
                        return "showWorkAchievement";
                    }
                })(Data_Eq.eqBoolean))()({
                    reflectSymbol: function () {
                        return "selectedElementSpecId";
                    }
                })(Data_Maybe.eqMaybe(Types_Newtypes.eqElementSpecId)))()({
                    reflectSymbol: function () {
                        return "resource";
                    }
                })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "wastePercentage";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "vendorUnit";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "vendorCode";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "vendorBatchSize";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                    reflectSymbol: function () {
                        return "vendor";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "validDate";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "usagesCount";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "unitConsumptionRate";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "unit";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "taxPercentage";
                    }
                })(Types_Unit.eqTaxrate))()({
                    reflectSymbol: function () {
                        return "sortingNumber";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "resourceClassificationGroup";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "projectId";
                    }
                })(Data_Nullable.eqNullable(Types_Project.eqProjectId)))()({
                    reflectSymbol: function () {
                        return "priceWithDiscount";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "pricePerUnit";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                    reflectSymbol: function () {
                        return "orgResourcePriceListId";
                    }
                })(Data_Nullable.eqNullable(Types_Component.eqOrgEstimationResourceId)))()({
                    reflectSymbol: function () {
                        return "orgResourcePriceId";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqInt)))()({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(Data_Eq.eqString))()({
                    reflectSymbol: function () {
                        return "multiplier";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "isCostClassPrice";
                    }
                })(Data_Eq.eqBoolean))()({
                    reflectSymbol: function () {
                        return "index";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Component.eqEstimationResourceId))()({
                    reflectSymbol: function () {
                        return "group";
                    }
                })(Data_Nullable.eqNullable(Types_ClassificationGroup.eqClassificationGroupCode)))()({
                    reflectSymbol: function () {
                        return "estimationComponentId";
                    }
                })(Data_Nullable.eqNullable(Types_Component.eqComponentId)))()({
                    reflectSymbol: function () {
                        return "eanCode";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "discountPercentage";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "discountGroupCode";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "currencyId";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "costClassCode";
                    }
                })(Data_Nullable.eqNullable(Types_Newtypes.eqCostClassCode)))()({
                    reflectSymbol: function () {
                        return "contract";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "changeDate";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))))()({
                    reflectSymbol: function () {
                        return "isSelected";
                    }
                })(Data_Eq.eqBoolean))()({
                    reflectSymbol: function () {
                        return "isNewResource";
                    }
                })(Data_Eq.eqBoolean))()({
                    reflectSymbol: function () {
                        return "hourlyPricing";
                    }
                })(Data_Eq.eqBoolean))()({
                    reflectSymbol: function () {
                        return "componentId";
                    }
                })(Types_Component.eqComponentId))()({
                    reflectSymbol: function () {
                        return "componentAmount";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "classificationGroupCodePlaceholder";
                    }
                })(Data_Eq.eqString)))({
                    sortProperty: Data_Maybe.Nothing.value,
                    sortingOrder: TacoTable_Types.Ascending.value,
                    columns: resourceColumns,
                    onSort: function (v5) {
                        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                    },
                    foldingElement: Data_Maybe.Nothing.value,
                    styleSet: CostEstimation_Styles.resourceTableHeaderStyleSet,
                    onResize: function (v5) {
                        return function (v6) {
                            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                        };
                    },
                    columnSizeOffsets: Data_Maybe.Nothing.value,
                    scrollLeft: 0.0
                }))
            });
            return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                className: "__TacoTable",
                css: React_Basic_Emotion.merge([ CostEstimation_Styles.estimationResourceListContentsStyles, removeWillChange ]),
                children: [ resourceTableHeading, ReactBasicUtils["div'"]("table-rows")(rows), invalidResourcesRows ]
            });
        })();
        var resourcesTable = CostEstimationTableWrapper.component({
            colorSet: TacoConstants.resourceColors,
            children: [ tableContents, Data_Monoid.guard(React_Basic.monoidJSX)(canEdit)(deleteResourceModal), Data_Monoid.guard(React_Basic.monoidJSX)(canEdit)(addNewResourceModal), Data_Monoid.guard(React_Basic.monoidJSX)(canEdit)(changeResourceModal), Data_Monoid.guard(React_Basic.monoidJSX)(canEdit)(changeResourceCostClassModal) ],
            floatingActionButtons: floatingActionButtons
        });
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "__EstimationResourcesList",
            css: CostEstimation_Styles.estimationResourcesListStyles,
            children: [ resourcesTable ]
        });
    };
};
var estimationResourcesList = TofuHooks.mkHookComponent("EstimationResourcesList")(_estimationResourcesListImpl);
module.exports = {
    estimationResourcesList: estimationResourcesList
};
