import {
  Button,
  IconCheck24Px,
  IconExclamation,
  Input,
  Notification,
} from "@tocoman/ui";
import { RegisterOptions, useForm, useWatch } from "react-hook-form";
import { useUpdateCurrentOrganization } from "./useUpdateCurrentOrganization";
import { OptionalOrganizationData } from "./OrganizationSettings";
import { useEffect, useState } from "react";
import { Organization } from "@prisma/client";
import { useTranslation } from "react-i18next";

type OrgInfoFormProps = {
  currentOrganization: Organization | undefined;
};

export const OrgInfoForm = ({ currentOrganization }: OrgInfoFormProps) => {
  const { t } = useTranslation("settings");

  const [orgNotFoundError, setOrgNotFoundError] = useState(false);
  const [success, setSuccess] = useState(false);

  const methods = useForm<OptionalOrganizationData>({
    values: {
      companyName: currentOrganization?.companyName,
      description: currentOrganization?.description,
      defaultVAT: currentOrganization?.defaultVAT ?? 25.5,
    },
  });

  const handleFormChange = () => {
    setSuccess(false);
    setOrgNotFoundError(false);
  };

  const { register, handleSubmit, reset, control } = methods;

  const watchName = useWatch({
    control,
    name: "companyName",
  });
  const watchDescription = useWatch({
    control,
    name: "description",
  });

  const mkInputProps = (
    field: keyof OptionalOrganizationData,
    registerOptions?: RegisterOptions<OptionalOrganizationData, typeof field>
  ) => ({
    label: t(`${field}`),
    ...register(field, registerOptions),
  });

  const updateCurrentOrganization = useUpdateCurrentOrganization();

  const onSave = (data: OptionalOrganizationData) => {
    const companyName = data.companyName === "" ? null : data.companyName;
    const description = data.description === "" ? null : data.description;
    const saveData: OptionalOrganizationData = {
      name: currentOrganization?.name,
      companyName: companyName,
      description: description,
      defaultVAT: Number(data.defaultVAT),
    };
    updateCurrentOrganization
      .mutateAsync(saveData)
      .then(() => {
        setSuccess(true);
      })
      .catch((e) => {
        if (e.response.status === 404) {
          setOrgNotFoundError(true);
        }
      });
  };

  const closeError = () => {
    setOrgNotFoundError(false);
    reset();
  };

  useEffect(() => {
    handleFormChange();
  }, [watchName, watchDescription]);

  return (
    <>
      <form
        className={"flex flex-col w-[200px] mt-5"}
        onSubmit={handleSubmit(onSave)}
      >
        <Input {...mkInputProps("companyName")} />

        <Input {...mkInputProps("description")} />

        <Input {...mkInputProps("defaultVAT")} type={"number"} step={"any"} />
        {success && (
          <Notification
            title={t("updateSuccess")}
            description={t("orgUpdated")}
            type={"success"}
            icon={IconCheck24Px}
            className={"w-[300px] mt-5"}
            onCloseFn={() => setSuccess(false)}
          />
        )}
        {orgNotFoundError && (
          <Notification
            title={t("error")}
            description={t("orgNotFound")}
            type={"error"}
            icon={IconExclamation}
            className={"w-[300px] mt-5"}
            onCloseFn={closeError}
          />
        )}
        <Button className={"w-auto self-start my-5"} type={"submit"}>
          {t("save")}
        </Button>
      </form>
    </>
  );
};
