import { UserDataWithPermissions } from "../../../server-ts/user/UserRouter";
import { useQuery } from "react-query";
import axiosClient from "../../axiosConfig";

const baseUrl = "/users/me";
export function useUserDataQuery(): UserDataWithPermissions | undefined {
  const { data } = useQuery({
    queryKey: [baseUrl],
    queryFn: async () => {
      const { data } = await axiosClient.get<UserDataWithPermissions>(baseUrl);
      return data;
    },
  });
  return data;
}
