import { Button, IconDelete24Px } from "@tocoman/ui";
import { IStatusPanelParams } from "ag-grid-community";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AdminUserTableRow } from "ts-bindings/AdminUserTableRow";
import { BulkUserDeleteModal } from "./BulkUserDeleteModal";
import { useSelectedRows } from "../../../components/Table/useSelectedRows";

export const BulkUserDeleteStatusPanelButton = ({
  api,
}: IStatusPanelParams<AdminUserTableRow>) => {
  const { t } = useTranslation("admin");
  const [usersToDelete, setUsersToDelete] = useState<
    AdminUserTableRow[] | undefined
  >(undefined);

  const selectedRows = useSelectedRows(api);
  const disabled = useMemo(() => selectedRows.length === 0, [selectedRows]);

  const onDeleteBulkUserClick = useCallback(() => {
    setUsersToDelete(api.getSelectedRows());
  }, [api]);

  const onClose = useCallback(() => {
    setUsersToDelete(undefined);
  }, []);

  return (
    <div className={"flex flex-row gap-2 py-3"}>
      <Button
        variant="text"
        color="danger"
        label={t`users.deleteSelectedUsers`}
        disabled={disabled}
        onClick={onDeleteBulkUserClick}
        icon={IconDelete24Px}
      />
      {usersToDelete && (
        <BulkUserDeleteModal
          isOpen={true}
          closeModal={onClose}
          usersToDelete={usersToDelete}
        />
      )}
    </div>
  );
};
