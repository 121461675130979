import { getClientConfigVarOptional } from "../../client/config";
import { ProductFruits } from "react-product-fruits";
import { useUserDataQuery } from "../containers/Projects/useUserData";

export function ProductFruitsInit() {
  const workspaceCode = getClientConfigVarOptional(
    "CLIENT_PRODUCTFRUITS_WORKSPACE_CODE"
  );
  const user = useUserDataQuery();
  if (!user) return null;

  if (!workspaceCode) {
    return null;
  }

  return (
    <ProductFruits
      workspaceCode={workspaceCode}
      language={user.userData.language ?? "fi"}
      user={{
        email: user.userData.email,
        username: user.userData.id,
        role: user.permissions.isAdmin ? "admin" : "user",
        // useUserDataQuery has wrong type for createdAt
        signUpAt: (user.userData.createdAt as unknown) as string,
        props: {
          tenantId: user.userData.currentOrganization,
        },
      }}
    />
  );
}
