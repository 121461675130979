// Generated by purs version 0.14.5
"use strict";
var Components_SubNavigation = require("../Components.SubNavigation/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var FeatureHooks = require("../FeatureHooks/index.js");
var Locale = require("../Locale/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_FrontEndRoutes = require("../Types.FrontEndRoutes/index.js");
var UseLicenseFeature = require("../UseLicenseFeature/index.js");
var navbar = TofuHooks.mkHookComponent("Navbar")(function (props) {
    return function __do() {
        var printingLicenseEnabled = UseLicenseFeature.useLicenseFeature(UseLicenseFeature.Printing.value)();
        var printingFeatureEnabled = FeatureHooks.useFeatureEnabled("cost-control-printing")();
        var showPrinting = printingFeatureEnabled && printingLicenseEnabled;
        var printingLink = Data_Monoid.guard(Data_Monoid.monoidArray)(showPrinting)([ Components_SubNavigation.Separator.value, new Components_SubNavigation.Link({
            title: "Tuloste",
            color: TacoConstants.PrimaryBlue.value,
            active: Data_Eq.eq(Types_FrontEndRoutes.eqFERoute)(new Types_FrontEndRoutes.CostControlPrintingFERoute({
                projectId: props.projectId
            }))(props.currentRoute),
            route: new Types_FrontEndRoutes.CostControlPrintingFERoute({
                projectId: props.projectId
            }),
            href: Types_FrontEndRoutes["fragments"]["cost-control-printing"],
            id: "cost-control-printing"
        }) ]);
        var link = function (id) {
            return function (href) {
                return function (route) {
                    return new Components_SubNavigation.Link({
                        title: Locale.lookup_(id),
                        color: TacoConstants.PrimaryBlue.value,
                        active: Data_Eq.eq(Types_FrontEndRoutes.eqFERoute)(route({
                            projectId: props.projectId
                        }))(props.currentRoute),
                        route: route({
                            projectId: props.projectId
                        }),
                        href: href,
                        id: id
                    });
                };
            };
        };
        var items = [ link("navigation_cost_control")(Types_FrontEndRoutes["fragments"]["cost-control"])(Types_FrontEndRoutes.CostControlFERoute.create), link("navigation_invoices")(Types_FrontEndRoutes.fragments.invoices)(Types_FrontEndRoutes.InvoicesFERoute.create), link("navigation_incomeItems")(Types_FrontEndRoutes.fragments.income)(Types_FrontEndRoutes.IncomeFERoute.create), Components_SubNavigation.Separator.value, link("navigation_r200_report")(Types_FrontEndRoutes.fragments.reports)(Types_FrontEndRoutes.ReportsFERoute.create), link("navigation_monthly_report")(Types_FrontEndRoutes["fragments"]["monthly-report"])(Types_FrontEndRoutes.MonthlyReportFERoute.create) ];
        var detailsLink = [ Components_SubNavigation.Spacing.value, link("navigation_project_details")(Types_FrontEndRoutes["fragments"]["project-details"])(Types_FrontEndRoutes.ProjectDetailsFERoute.create) ];
        var subNavigationItems = Data_Semigroup.append(Data_Semigroup.semigroupArray)(items)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(printingLink)(detailsLink));
        return Components_SubNavigation.component({
            items: subNavigationItems
        });
    };
});
module.exports = {
    navbar: navbar
};
