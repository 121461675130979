import { EstimationLocation } from "../../../../../ts-bindings/EstimationLocation";
import { Table } from "../../../components/Table";
import { useCallback, useMemo, useRef, useState } from "react";
import {
  CellValueChangedEvent,
  ColDef,
  FirstDataRenderedEvent,
  ICellRendererParams,
  ISetFilterParams,
  ValueFormatterParams,
  ValueGetterParams,
} from "ag-grid-community";
import { useTranslation } from "react-i18next";
import { useWorkerState } from "../../../hooks/useWorkerState";
import { useFrontEndNavigation } from "../../../FrontEndNavigation/useFrontEndNavigation";
import { Button, IconAddPlusV1, IconDelete24Px, Modal } from "@tocoman/ui";
import { AddLocationsModal } from "./AddLocationsModal";
import {
  useAddNewLocation,
  useDeleteComponentLocation,
  useGetComponentLocation,
  useGetProjectLocations,
  useUpdateComponentLocation,
} from "./useComponentLocations";
import { useSelectedRows } from "../../../components/Table/useSelectedRows";
import { AgGridReact } from "ag-grid-react";
import { useWorkerRequest } from "../../../hooks/useWorkerRequest";
import {
  mkRefreshComponents,
  mkRefreshLocations,
} from "../../../actions/state";

type LocationsTableProps = {
  projectId: number;
  estimationLocations: EstimationLocation[];
  isReportingProject: boolean;
};

export type LocationsRowData = {
  id: number;
  amount: number;
  amountSource: number;
  description: string;
  locationCode: string;
  createTime: string | null;
  userName: string;
  unit: string | null | undefined;
  elementCode?: string;
  elementDescription?: string;
  elementId?: number;
  rtCode?: string;
  rtDescription?: string;
};

export type ProjectLocation = {
  code: string;
  description: string;
  id: number;
  projectId: number;
  scale: number;
  sortingNumber: number;
};

export const LocationsTable = ({
  projectId,
  estimationLocations,
  isReportingProject,
}: LocationsTableProps) => {
  const navigate = useFrontEndNavigation();

  const [isAddLocationsModalOpen, setIsAddLocationsModalOpen] = useState(false);
  const gridRef = useRef<AgGridReact<LocationsRowData>>(null);

  const projectComponents = useWorkerState(
    "EstimationComponentsState",
    projectId
  );
  const currentComponent = useMemo(() => {
    return projectComponents?.find(
      (component) =>
        component.id === estimationLocations[0]?.estimationComponentId
    );
  }, [estimationLocations]);

  const componentLocations = currentComponent
    ? useGetComponentLocation(currentComponent.id)
    : [];

  const projectLocations = useGetProjectLocations(projectId);

  const refreshLocations = useWorkerRequest(() =>
    mkRefreshLocations(projectId)
  );
  const refreshComponents = useWorkerRequest(() =>
    mkRefreshComponents(projectId)
  );

  const { t } = useTranslation("estimation", {
    keyPrefix: "componentLocations.locationsTable",
  });
  const onFirstDataRendered = (params: FirstDataRenderedEvent) => {
    params.api.sizeColumnsToFit();
  };

  const sourceFormatter = useCallback(
    (params: ValueFormatterParams) => {
      switch (params.value) {
        case 0:
          return t`input`;
        case 1:
          return t`quantityCalculation`;
        case 2:
          return t`formula`;
        case 3:
          return t`demand`;
        case 4:
          return t`BIM`;
        default:
          return "-";
      }
    },
    [t]
  );

  const sourceFilterFormatter = useCallback(
    (params: ValueFormatterParams) => {
      switch (params.value) {
        case 0:
          return t`input`;
        case 1:
          return t`quantityCalculation`;
        case 2:
          return t`formula`;
        case 3:
          return t`demand`;
        case 4:
          return t`BIM`;
        default:
          return "-";
      }
    },
    [t]
  );
  const colDefs: ColDef[] = useMemo(
    () => [
      {
        field: "selected",
        headerName: "",
        checkboxSelection: true,
        initialWidth: 50,
      },
      {
        field: "locationCode",
        headerName: t("locationCode"),
        type: !isReportingProject ? ["editable"] : undefined,
        initialWidth: 100,
        cellClassRules: {
          "disable-editing": (params) => {
            return params.data.amountSource !== 0;
          },
        },
      },
      {
        field: "description",
        headerName: t("description"),
        type: !isReportingProject ? ["editable"] : undefined,
        initialWidth: 100,
        cellClassRules: {
          "disable-editing": (params) => {
            return params.data.amountSource !== 0;
          },
        },
      },
      {
        field: "amount",
        headerName: t("amount"),
        initialWidth: 100,
        type: !isReportingProject ? ["editable"] : undefined,
        cellClassRules: {
          "disable-editing": (params) => {
            return params.data.amountSource !== 0;
          },
        },
      },
      {
        field: "unit",
        headerName: t("unit"),
        initialWidth: 100,
      },
      {
        field: "amountSource",
        headerName: t("amountSource"),
        valueFormatter: sourceFormatter,
        filter: "agSetColumnFilter",
        filterParams: {
          valueFormatter: sourceFilterFormatter,
        } as ISetFilterParams,
        initialWidth: 150,
      },
      {
        field: "building",
        headerName: t("building"),
        valueGetter: (params: ValueGetterParams) => {
          if (!params.data.elementId) return "";
          return `${params.data.elementCode} ${params.data.elementDescription} - ${params.data.rtCode} ${params.data.rtDescription}`;
        },
        cellRenderer: (params: ICellRendererParams) => {
          if (!params.data.elementId) return "";
          return (
            <Button
              label={params.value}
              variant={"text"}
              onClick={() =>
                navigate("EstimationBuildingElementsRoute", {
                  projectId,
                  initialSelectedElement: params.data.elementId,
                })
              }
            />
          );
        },
        initialWidth: 350,
      },
      {
        field: "createTime",
        headerName: t("createTime"),
        initialWidth: 120,
        type: "isoDateWithTime",
      },
      {
        field: "userName",
        headerName: t("userName"),
        initialWidth: 200,
      },
    ],
    []
  );

  const defaultColDefs: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };

  const deleteLocation = useDeleteComponentLocation(projectId);
  const handleDeleteLocations = () => {
    const selectedRows = gridRef.current?.api.getSelectedRows();
    selectedRows?.forEach((row) => {
      try {
        deleteLocation.mutate(row.id);
      } catch (e) {
        reportError(e);
      }
    });
    setIsDeleteModalOpen(false);
  };

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const deleteModalActions = (
    <>
      <Button
        label={t("cancel")}
        variant={"text"}
        onClick={() => setIsDeleteModalOpen(false)}
      />
      <Button
        label={t("delete")}
        variant={"text"}
        onClick={handleDeleteLocations}
      />
    </>
  );

  const AddLocationRowButton = () => {
    return (
      <Button
        label={t("addLocation")}
        variant={"text"}
        icon={IconAddPlusV1}
        iconPosition={"left"}
        className={"m-3"}
        onClick={() => setIsAddLocationsModalOpen(true)}
      />
    );
  };

  const DeleteLocationsButton = () => {
    return (
      <Button
        label={t("deleteLocations")}
        variant={"text"}
        icon={IconDelete24Px}
        iconPosition={"left"}
        className={"m-3"}
        disabled={useSelectedRows(gridRef.current?.api).length === 0}
        onClick={() => setIsDeleteModalOpen(true)}
      />
    );
  };

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        {
          statusPanel: AddLocationRowButton,
        },
        {
          statusPanel: DeleteLocationsButton,
        },
      ],
    };
  }, []);

  const updateLocation = useUpdateComponentLocation(projectId);
  const addNewLocation = useAddNewLocation();
  const onCellValueChanged = async (params: CellValueChangedEvent) => {
    if (params.newValue === params.oldValue) return;
    const newData = {
      id: params.data.id,
      amount: params.data.amount,
      description: params.data.description ?? "",
      locationCode: params.data.locationCode,
    };
    if (
      projectLocations.find(
        (location) =>
          location.code.toLowerCase() === params.data.locationCode.toLowerCase()
      )
    ) {
      updateLocation.mutate(newData, {
        onSuccess: () => {
          refreshLocations();
          refreshComponents();
        },
      });
    } else {
      addNewLocation.mutate(
        {
          code: params.data.locationCode,
          description: params.data.description ?? "",
          projectId,
        },
        {
          onSuccess: () => {
            updateLocation.mutate(newData, {
              onSuccess: () => {
                refreshLocations();
                refreshComponents();
              },
            });
          },
        }
      );
    }
  };

  const rowData = useMemo(() => {
    return componentLocations.map((location) => {
      return {
        id: location.id,
        amount: location.amount,
        amountSource: location.amountSource,
        description: location.description,
        locationCode: location.locationCode,
        createTime: location.createTime,
        userName: location.userName,
        unit: currentComponent?.unit,
        elementCode: location.elementCode,
        elementDescription: location.elementDescription,
        elementId: location.elementId,
        rtCode: location.rtCode,
        rtDescription: location.rtDescription,
      };
    });
  }, [componentLocations, currentComponent]);

  return (
    <div className={"w-full h-full"}>
      <Table<LocationsRowData>
        gridRef={gridRef}
        className={"w-full h-full"}
        rowData={rowData}
        columnDefs={colDefs}
        defaultColDef={defaultColDefs}
        onFirstDataRendered={onFirstDataRendered}
        statusBar={statusBar}
        reactiveCustomComponents={true}
        rowSelection={"multiple"}
        onCellValueChanged={onCellValueChanged}
      />
      <AddLocationsModal
        isModalOpen={isAddLocationsModalOpen}
        closeModal={() => setIsAddLocationsModalOpen(false)}
        currentComponent={currentComponent}
        projectLocations={projectLocations}
        projectId={projectId}
      />
      {isDeleteModalOpen && (
        <Modal
          isOpen={isDeleteModalOpen}
          closeModal={() => setIsDeleteModalOpen(false)}
          actions={deleteModalActions}
        >
          {t("deleteLocationConfirm")}
          {gridRef.current?.api.getSelectedRows().map((row) => (
            <div key={row.id}>
              {row.locationCode} {row.description}
            </div>
          ))}
        </Modal>
      )}
    </div>
  );
};
