import { useMutation, UseMutationResult } from "react-query";
import axiosClient from "../../../../axiosConfig";

export type AdjustPricingParams = {
  projectId: number;
  componentIds: number[];
  costClassCode: string;
  dryRun: boolean;
  priceMultiplier: number;
};

export type PriceDifference = {
  priceDifference: number;
};

export function useAdjustPricingQuery(): UseMutationResult<
  PriceDifference,
  unknown,
  AdjustPricingParams
> {
  return useMutation({
    mutationFn: async (value: AdjustPricingParams) => {
      const { data } = await axiosClient.post(
        `project/${value.projectId}/price-adjustment`,
        value
      );
      return data;
    },
  });
}
