// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var CostEstimation_BuildingElementsView_ElementDetails = require("../CostEstimation.BuildingElementsView.ElementDetails/index.js");
var CostEstimation_BuildingElementsView_LocationsTable = require("../CostEstimation.BuildingElementsView.LocationsTable/index.js");
var CostEstimation_BuildingElementsView_Types = require("../CostEstimation.BuildingElementsView.Types/index.js");
var CostEstimation_Layout = require("../CostEstimation.Layout/index.js");
var CostEstimation_Styles = require("../CostEstimation.Styles/index.js");
var CostEstimationLayout_Types = require("../CostEstimationLayout.Types/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var ElementDetailsContainer_Styles = require("../ElementDetailsContainer.Styles/index.js");
var ElementMeasures = require("../ElementMeasures/index.js");
var ElementMemo = require("../ElementMemo/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var ScrollContainer = require("../ScrollContainer/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoTabs = require("../TacoTabs/index.js");
var TacoTabs_Types = require("../TacoTabs.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_BuildingElementsView = require("../Types.BuildingElementsView/index.js");
var Types_Element = require("../Types.Element/index.js");
var mkElementDetails = TofuHooks.mkHookComponent("ElementDetailsContainer")(function (props) {
    var selectedElementSpecId = Data_Functor.map(Data_Maybe.functorMaybe)(Data_Tuple.fst)(props.selectedComponentIds);
    var selectedComponentId = Data_Functor.map(Data_Maybe.functorMaybe)(Data_Tuple.snd)(props.selectedComponentIds);
    var detailsElementId = Data_Functor.map(Data_Maybe.functorMaybe)(CostEstimation_BuildingElementsView_Types["_elementId"])(props.selectedElement);
    var componentSplitParams = (function () {
        if (detailsElementId instanceof Data_Maybe.Just && (selectedElementSpecId instanceof Data_Maybe.Just && selectedComponentId instanceof Data_Maybe.Just)) {
            return new Data_Maybe.Just({
                elementId: detailsElementId.value0,
                elementSpecId: selectedElementSpecId.value0,
                componentId: selectedComponentId.value0
            });
        };
        return Data_Maybe.Nothing.value;
    })();
    return function __do() {
        StateHooks.useDispatch();
        var v = TofuHooks.useState(new Data_Maybe.Just((function () {
            var $10 = Data_Maybe.fromMaybe(false)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
                return v.hideComponents;
            })(props.dixonOptions));
            if ($10) {
                return "locations";
            };
            return "components";
        })()))();
        if (props.selectedElement instanceof Data_Maybe.Just) {
            var ed = Data_Newtype.un()(Types_BuildingElementsView.WasmElementsData)(props.selectedElement.value0);
            var element = Data_Newtype.un()(Types_Element.WasmEstimationElement)(ed.element);
            var memo = Box.box("MemoContainer")([ new Box.Margin(TacoConstants.M.value), Box.Width.create(new TacoConstants.CustomSize("100%")), Box.MaxWidth.create(new TacoConstants.CustomSize("48rem")) ])([ ElementMemo.component({
                elementId: element.elementId,
                value: element.memo,
                projectId: props.projectId
            }) ]);
            var elementMeasures = ElementMeasures.component({
                element: ed.element,
                amount: ed.amount,
                projectId: props.projectId,
                elementMeasures: props.elementMeasures,
                viewMode: props.viewMode,
                programType: props.programType,
                isReportingProject: props.isReportingProject
            });
            var details = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                className: "__ElementDetailsContainerTabs",
                css: ElementDetailsContainer_Styles.elementDetailsContainerTabsStyles,
                children: [ TacoTabs.controlled({
                    orientation: TacoTabs_Types.Horizontal.value,
                    hideContents: Data_Eq.eq(CostEstimationLayout_Types.eqPanelMode)(props.panelMode)(CostEstimationLayout_Types.MinimizedMode.value),
                    activeTabId: v.value0,
                    setActiveTabId: v.value1,
                    tabs: [ new TacoTabs_Types.NormalTab({
                        children: Control_Applicative.pure(Control_Applicative.applicativeArray)(CostEstimation_BuildingElementsView_ElementDetails.elementDetails({
                            props: props,
                            projectId: props.projectId,
                            id: element.elementId,
                            elementData: props.selectedElement.value0,
                            checkedSpecs: props.checkedSpecs,
                            setCheckedSpecs: props.setCheckedSpecs,
                            socialExpensePercentagesByCostClass: props.socialExpensePercentagesByCostClass,
                            showSocialExpensesInCosts: props.showSocialExpensesInCosts,
                            showWorkAchievement: props.showWorkAchievement,
                            setParentSelectedResourceIds: props.setParentSelectedResourceIds,
                            extraComponentControls: props.extraComponentControls,
                            extraResourceControls: props.extraResourceControls,
                            componentSplitParams: componentSplitParams,
                            projectCurrency: props.projectCurrency
                        })),
                        quantity: Data_Maybe.Just.create(Data_Array.length(ed.components)),
                        title: "Suoritteet",
                        id: "components",
                        color: TacoConstants.componentColors.border
                    }), new TacoTabs_Types.NormalTab({
                        children: [ CostEstimation_BuildingElementsView_LocationsTable.mkElementLocationDetails({
                            viewMode: props.viewMode,
                            projectId: props.projectId,
                            elementData: props.selectedElement.value0,
                            projectLocations: props.projectLocations
                        }) ],
                        quantity: Data_Maybe.Just.create(Data_Array.length(ed.locations)),
                        title: "Sijainnit",
                        id: "locations",
                        color: TacoConstants.elementColors.border
                    }), new TacoTabs_Types.NormalTab({
                        children: [ ScrollContainer.component({
                            children: [ memo ]
                        }) ],
                        quantity: Data_Maybe.Nothing.value,
                        title: "Muistio",
                        id: "memo",
                        color: TacoConstants.elementColors.border
                    }), new TacoTabs_Types.NormalTab({
                        children: [ ScrollContainer.component({
                            children: [ elementMeasures ]
                        }) ],
                        quantity: Data_Maybe.Nothing.value,
                        title: "Muuttujat",
                        id: "variables",
                        color: TacoConstants.elementColors.border
                    }), TacoTabs_Types.SeparatorTab.value, TacoTabs_Types.JsxTab.create(CostEstimation_Layout.panelModeButtons({
                        panelMode: props.panelMode,
                        setPanelMode: props.setPanelMode,
                        vertical: false,
                        smallMode: false,
                        testId: "element-details-panel-size"
                    })) ]
                }) ]
            });
            return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                className: "__ElementDetailsContainer",
                css: CostEstimation_Styles.costEstimationDetailsContainerStyles({
                    panelMode: props.panelMode
                }),
                children: [ details, props.controls ]
            });
        };
        if (props.selectedElement instanceof Data_Maybe.Nothing) {
            return React_Basic.empty;
        };
        throw new Error("Failed pattern match at CostEstimation.BuildingElementsView.ElementDetailsContainer (line 78, column 10 - line 179, column 24): " + [ props.selectedElement.constructor.name ]);
    };
});
var findElementAndComponent = function (elementId) {
    return function (datas) {
        var matchElement = function (v) {
            var $34 = Data_Eq.eq(Data_Maybe.eqMaybe(Types_Element.eqElementId))(new Data_Maybe.Just((Data_Newtype.unwrap()(v.element)).elementId))(new Data_Maybe.Just(elementId));
            if ($34) {
                return new Data_Maybe.Just(v);
            };
            return Data_Maybe.Nothing.value;
        };
        return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.findMap(matchElement)(datas))(function (e) {
            return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(e);
        });
    };
};
module.exports = {
    mkElementDetails: mkElementDetails,
    findElementAndComponent: findElementAndComponent
};
