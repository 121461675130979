// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var TacoTable_Cells = require("../TacoTable.Cells/index.js");
var TacoTable_HeaderSeparator = require("../TacoTable.HeaderSeparator/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Type_Proxy = require("../Type.Proxy/index.js");
var UseCallbackRef = require("../UseCallbackRef/index.js");
var UseMeasure = require("../UseMeasure/index.js");
var UseNullableRef = require("../UseNullableRef/index.js");
var header_ = function (dictGetName) {
    return function (dictEq) {
        return function (dictEq1) {
            return function (_nodeRef) {
                return TofuHooks.mkHookComponent("TableHeader" + TofuHooks.getName(dictGetName)(Type_Proxy["Proxy"].value))(function (props) {
                    return function __do() {
                        var v = UseMeasure.useMeasure();
                        var mkHeaderCell = function (column) {
                            return TacoTable_Cells.headerCell(dictGetName)(dictEq)({
                                column: column,
                                sortProperty: props.sortProperty,
                                sortingOrder: props.sortingOrder,
                                styleSet: props.styleSet.headerCellStyles,
                                onClick: props.onSort,
                                separator: (function () {
                                    if (props.columnSizeOffsets instanceof Data_Maybe.Nothing) {
                                        return React_Basic.empty;
                                    };
                                    if (props.columnSizeOffsets instanceof Data_Maybe.Just) {
                                        return TacoTable_HeaderSeparator.component()()({
                                            className: "table-header-separator",
                                            headerRectLeft: v.dimensions.left,
                                            scrollLeft: props.scrollLeft,
                                            onResize: function (val) {
                                                var key = (Data_Newtype.unwrap()(column)).key;
                                                var offset = Data_Maybe.fromMaybe(0.0)(Data_Map_Internal.lookup(Data_Ord.ordString)(key)(props.columnSizeOffsets.value0));
                                                var newVal = Data_Ord.max(Data_Ord.ordNumber)(0.0)(val + offset);
                                                return props.onResize(key)(newVal);
                                            },
                                            resizeable: (function () {
                                                var v1 = (Data_Newtype.unwrap()(column)).width;
                                                if (v1 instanceof TacoTable_Types.FlexResizeable) {
                                                    return true;
                                                };
                                                return false;
                                            })()
                                        });
                                    };
                                    throw new Error("Failed pattern match at TacoTable.Header (line 73, column 11 - line 88, column 16): " + [ props.columnSizeOffsets.constructor.name ]);
                                })()
                            });
                        };
                        var foldingIndicator = Control_Applicative.pure(Control_Applicative.applicativeArray)((function () {
                            if (props.foldingElement instanceof Data_Maybe.Just) {
                                return React_Basic_Emotion.element(React_Basic_DOM_Generated["span'"]())({
                                    className: "folding-indicator",
                                    css: TacoTable_Styles.foldingIndicatorStyles
                                });
                            };
                            return React_Basic.empty;
                        })());
                        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                            className: "table-header",
                            css: props.styleSet.headerStyles,
                            children: Data_Semigroup.append(Data_Semigroup.semigroupArray)(foldingIndicator)(Data_Functor.map(Data_Functor.functorArray)(mkHeaderCell)(props.columns)),
                            ref: UseCallbackRef.toNodeRef(v.callbackRef)
                        });
                    };
                });
            };
        };
    };
};
var header = function (dictGetName) {
    return function (dictEq) {
        return function (dictEq1) {
            return TofuHooks.mkHookComponent("TableHeader_" + TofuHooks.getName(dictGetName)(Type_Proxy["Proxy"].value))(function (props) {
                return function __do() {
                    var ref = UseNullableRef.useNullableRef();
                    return header_(dictGetName)(dictEq)(dictEq1)(ref)(props);
                };
            });
        };
    };
};
var simpleHeader = function (dictGetName) {
    return function (dictEq) {
        return function (dictEq1) {
            return function (columns) {
                return function (styleSet) {
                    return header(dictGetName)(dictEq)(dictEq1)({
                        sortProperty: Data_Maybe.Nothing.value,
                        sortingOrder: TacoTable_Types.Ascending.value,
                        columns: columns,
                        onSort: function (v) {
                            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                        },
                        foldingElement: Data_Maybe.Nothing.value,
                        styleSet: styleSet,
                        onResize: Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit)))),
                        columnSizeOffsets: Data_Maybe.Nothing.value,
                        scrollLeft: 0.0
                    });
                };
            };
        };
    };
};
module.exports = {
    simpleHeader: simpleHeader,
    header: header,
    header_: header_
};
