import { FilterChangedEvent, GridApi } from "ag-grid-community";
import { useState } from "react";
import { useGridEventListener } from "../../components/Table/useGridEventListener";

export function useGridFilterModel<T>(
  api: GridApi<T> | null | undefined
): Record<string, unknown> {
  const [filterModel, setFilterModel] = useState<Record<string, unknown>>({});

  useGridEventListener(
    api,
    "filterChanged",
    ({ api }: FilterChangedEvent<T>) => {
      setFilterModel(api.getFilterModel());
    }
  );
  return filterModel;
}
