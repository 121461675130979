import { useTranslation } from "react-i18next";
import { Input, Text } from "@tocoman/ui";
import { useForm } from "react-hook-form";
import { useGetEstimationLocations } from "./hooks/useGetEstimationLocations";
import { ErrorMessage } from "../../../components/ErrorMessage";
import { useModalActionButtons } from "./hooks/useModalActionButtons";
import { useRunOnce } from "../../../hooks/useRunOnce";
import { useEditQuantity } from "./hooks/useEditQuantity";

type EditQuantityProps = {
  projectId: number;
  componentIds: number[];
  onSave: () => void;
  closeModal: () => void;
  setActionButtons: (actions: JSX.Element) => void;
};

type EditQuantityForm = {
  newQuantity: number;
};

export const EditQuantity = ({
  projectId,
  componentIds,
  onSave,
  closeModal,
  setActionButtons,
}: EditQuantityProps) => {
  const { t } = useTranslation("estimation", {
    keyPrefix: "componentView.editMultipleComponentsModal",
  });
  const { register, handleSubmit } = useForm<EditQuantityForm>();

  const locations = useGetEstimationLocations(projectId) ?? [];
  const selectedComponentsLocations = locations.filter((location) =>
    componentIds.includes(location.estimationComponentId)
  );
  const hasSameAmount = selectedComponentsLocations.every(
    (location) => location.amount === selectedComponentsLocations[0]?.amount
  );
  const componentsCurrentQuantity = hasSameAmount
    ? selectedComponentsLocations[0]?.amount
    : undefined;
  const locationCodes = selectedComponentsLocations.map(
    (location) => location.locationCode
  );
  const hasOnlyDefaultLocations = locationCodes.every((code) => code === "*");

  const editQuantity = useEditQuantity(projectId, onSave);

  const handleSave = (data: EditQuantityForm) => {
    const values = componentIds.map((component) => {
      return {
        componentId: component,
        editAction: {
          type: "EditEstimationComponentQuantity",
          value: {
            quantity: Number(data.newQuantity),
          },
        },
      };
    });
    const editQuantityData = {
      type: "EditEstimationComponents",
      value: values,
    };
    editQuantity.mutate(editQuantityData);
  };

  const setActions = useModalActionButtons(
    setActionButtons,
    handleSubmit(handleSave),
    closeModal
  );
  useRunOnce(() => {
    setActions(!hasOnlyDefaultLocations);
  });

  return (
    <>
      {!hasOnlyDefaultLocations && (
        <ErrorMessage
          errorMessage={t`containsComponentsWithNotDefaultLocations`}
        />
      )}
      {hasOnlyDefaultLocations && (
        <>
          <Text text={t`form.editQuantityDescription`} />

          <div className={"w-[300px]"}>
            <Text text={t`form.amount`} className={"text-dark mt-1"} />
            <Input
              {...register("newQuantity")}
              testId={"new-quantity-input"}
              className={"w-full"}
              defaultValue={componentsCurrentQuantity}
              type={"number"}
            />
          </div>
        </>
      )}
    </>
  );
};
