// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var CostGroupDetails = require("../CostGroupDetails/index.js");
var CostGroupDetails_Types = require("../CostGroupDetails.Types/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Array_NonEmpty_Internal = require("../Data.Array.NonEmpty.Internal/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid_Additive = require("../Data.Monoid.Additive/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var EditCostGroupItems = require("../EditCostGroupItems/index.js");
var Effect = require("../Effect/index.js");
var Formatters = require("../Formatters/index.js");
var FormattingHelpers = require("../FormattingHelpers/index.js");
var Locale = require("../Locale/index.js");
var ProgressBar = require("../ProgressBar/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_CostGroup = require("../Types.CostGroup/index.js");
var Types_Income = require("../Types.Income/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Project = require("../Types.Project/index.js");
var Types_R200 = require("../Types.R200/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var UpdateStatusesReducer = require("../UpdateStatusesReducer/index.js");
var Utils = require("../Utils/index.js");
var ByCode = (function () {
    function ByCode() {

    };
    ByCode.value = new ByCode();
    return ByCode;
})();
var ByName = (function () {
    function ByName() {

    };
    ByName.value = new ByName();
    return ByName;
})();
var ByTarget = (function () {
    function ByTarget() {

    };
    ByTarget.value = new ByTarget();
    return ByTarget;
})();
var ByEstimate = (function () {
    function ByEstimate() {

    };
    ByEstimate.value = new ByEstimate();
    return ByEstimate;
})();
var ByTargetToEstimate = (function () {
    function ByTargetToEstimate() {

    };
    ByTargetToEstimate.value = new ByTargetToEstimate();
    return ByTargetToEstimate;
})();
var ByEstimateRemaining = (function () {
    function ByEstimateRemaining() {

    };
    ByEstimateRemaining.value = new ByEstimateRemaining();
    return ByEstimateRemaining;
})();
var ByActual = (function () {
    function ByActual() {

    };
    ByActual.value = new ByActual();
    return ByActual;
})();
var ByActualToEstimateRatio = (function () {
    function ByActualToEstimateRatio() {

    };
    ByActualToEstimateRatio.value = new ByActualToEstimateRatio();
    return ByActualToEstimateRatio;
})();
var ByCompletionPercentage = (function () {
    function ByCompletionPercentage() {

    };
    ByCompletionPercentage.value = new ByCompletionPercentage();
    return ByCompletionPercentage;
})();
var ByMemo = (function () {
    function ByMemo() {

    };
    ByMemo.value = new ByMemo();
    return ByMemo;
})();
var ColCheckbox = (function () {
    function ColCheckbox() {

    };
    ColCheckbox.value = new ColCheckbox();
    return ColCheckbox;
})();
var ColCode = (function () {
    function ColCode() {

    };
    ColCode.value = new ColCode();
    return ColCode;
})();
var ColName = (function () {
    function ColName() {

    };
    ColName.value = new ColName();
    return ColName;
})();
var ColTarget = (function () {
    function ColTarget() {

    };
    ColTarget.value = new ColTarget();
    return ColTarget;
})();
var ColEstimate = (function () {
    function ColEstimate() {

    };
    ColEstimate.value = new ColEstimate();
    return ColEstimate;
})();
var ColTargetToEstimate = (function () {
    function ColTargetToEstimate() {

    };
    ColTargetToEstimate.value = new ColTargetToEstimate();
    return ColTargetToEstimate;
})();
var ColActual = (function () {
    function ColActual() {

    };
    ColActual.value = new ColActual();
    return ColActual;
})();
var ColActualToEstimateRatio = (function () {
    function ColActualToEstimateRatio() {

    };
    ColActualToEstimateRatio.value = new ColActualToEstimateRatio();
    return ColActualToEstimateRatio;
})();
var ColCompletionPercentage = (function () {
    function ColCompletionPercentage() {

    };
    ColCompletionPercentage.value = new ColCompletionPercentage();
    return ColCompletionPercentage;
})();
var ColMemo = (function () {
    function ColMemo() {

    };
    ColMemo.value = new ColMemo();
    return ColMemo;
})();
var ColEstimateRemaining = (function () {
    function ColEstimateRemaining() {

    };
    ColEstimateRemaining.value = new ColEstimateRemaining();
    return ColEstimateRemaining;
})();
var CCTableAddLeftPadding = (function () {
    function CCTableAddLeftPadding() {

    };
    CCTableAddLeftPadding.value = new CCTableAddLeftPadding();
    return CCTableAddLeftPadding;
})();
var CCTableNoPadding = (function () {
    function CCTableNoPadding() {

    };
    CCTableNoPadding.value = new CCTableNoPadding();
    return CCTableNoPadding;
})();
var mkSummaryData = function (props) {
    return function (rd) {
        var targetCosts = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.targetCosts;
        })(rd));
        var estimateCosts = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.estimateCosts;
        })(rd));
        var actualCostSums = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.actualCosts;
        })(rd));
        var actualCosts = (function () {
            if (props.includeMissingCostGroupsInActualSummary) {
                return actualCostSums;
            };
            return actualCostSums + props.totalMissingCostGroupCodesInvoiceLinesAmount;
        })();
        return {
            targetCosts: targetCosts,
            estimateCosts: estimateCosts,
            targetToEstimate: targetCosts - estimateCosts,
            actualCosts: actualCosts,
            actualToEstimateRatio: Types_Unit.PercentageOfHundred(100.0 * Utils.defaultZeroDiv(actualCosts)(estimateCosts)),
            diffOfTargetToEstimate: targetCosts - estimateCosts,
            estimateRemaining: estimateCosts - actualCosts
        };
    };
};
var mkRowData = function (props) {
    return function (unfoldedCostGroups) {
        return function (modalState) {
            return function (r) {
                var displayProps = {
                    displayCompletionPercentage: props.displayCompletionPercentage,
                    displayEstimateRemaining: props.displayEstimateRemaining,
                    displayMemo: props.displayMemo,
                    displayActualToEstimateRatio: props.displayActualToEstimateRatio,
                    withSocialExpenses: props.withSocialExpenses,
                    descriptionDisplay: props.descriptionDisplay
                };
                return {
                    code: r.costGroupUnit.code,
                    name: r.costGroupUnit.name,
                    targetCosts: r.costGroupUnit.targetCosts,
                    estimateCosts: r.costGroupUnit.estimateCosts,
                    targetToEstimate: r.costGroupUnit.targetCosts - r.costGroupUnit.estimateCosts,
                    actualCosts: r.costGroupUnit.actualCosts,
                    actualToEstimateRatio: Types_Unit.PercentageOfHundred(100.0 * Utils.defaultZeroDiv(r.costGroupUnit.actualCosts)(r.costGroupUnit.estimateCosts)),
                    completionPercentage: r.costGroupUnit.completionPercentage,
                    costGroupUnitAndCostClasses: r,
                    foldingState: (function () {
                        var $83 = Data_Set.member(Types_CostGroup.ordCostGroupCode)(r.costGroupUnit.code)(unfoldedCostGroups);
                        if ($83) {
                            return TacoTable_Types.Unfolded.value;
                        };
                        return TacoTable_Types.Folded.value;
                    })(),
                    diffOfTargetToEstimate: r.costGroupUnit.targetCosts - r.costGroupUnit.estimateCosts,
                    estimateRemaining: r.costGroupUnit.estimateCosts - r.costGroupUnit.actualCosts,
                    displayProps: displayProps,
                    updateStatuses: props.updateStatuses,
                    modalState: modalState,
                    selectedCostGroups: props.selectedCostGroups
                };
            };
        };
    };
};
var mkPrepareColumns = function (padding) {
    return function (props) {
        return function (fn) {
            var optionalPadding = (function () {
                if (padding instanceof CCTableNoPadding) {
                    return [  ];
                };
                if (padding instanceof CCTableAddLeftPadding) {
                    return [ {
                        col: ColCode.value,
                        key: "left-padding",
                        label: React_Basic_DOM.text(""),
                        width: new TacoTable_Types.Fixed("2rem")
                    } ];
                };
                throw new Error("Failed pattern match at CostControlTable (line 667, column 23 - line 670, column 92): " + [ padding.constructor.name ]);
            })();
            var onFlag = function ($$boolean) {
                return function (value) {
                    if ($$boolean) {
                        return new Data_Maybe.Just(value);
                    };
                    return Data_Maybe.Nothing.value;
                };
            };
            var optionals = Data_Array.catMaybes([ onFlag(props.displayTarget)({
                col: ColTarget.value,
                key: "target",
                label: React_Basic_DOM.text(Locale.lookup_("report_costs_target")),
                width: new TacoTable_Types.Flex(15)
            }), onFlag(props.displayEstimate)({
                col: ColEstimate.value,
                key: "estimate",
                label: React_Basic_DOM.text(Locale.lookup_("report_costs_estimate")),
                width: new TacoTable_Types.Flex(15)
            }), onFlag(props.displayTargetToEstimate)({
                col: ColTargetToEstimate.value,
                key: "target-to-estimate",
                label: React_Basic_DOM.text(Locale.lookup_("common_diff")),
                width: new TacoTable_Types.Flex(15)
            }), onFlag(props.displayActual)({
                col: ColActual.value,
                key: "actual",
                label: React_Basic_DOM.text(Locale.lookup_("report_costs_actual")),
                width: new TacoTable_Types.Flex(15)
            }), onFlag(props.displayActualToEstimateRatio)({
                col: ColActualToEstimateRatio.value,
                key: "actual-to-estimate-ratio",
                label: React_Basic_DOM.text(Locale.lookup_("report_costs_actual_to_estimate_ratio")),
                width: new TacoTable_Types.Flex(15)
            }), onFlag(props.displayCompletionPercentage)({
                col: ColCompletionPercentage.value,
                key: "completionPercentage",
                label: React_Basic_DOM.text(Locale.lookup_("report_costs_completion_percentage")),
                width: new TacoTable_Types.Flex(28)
            }), onFlag(props.displayEstimateRemaining)({
                col: ColEstimateRemaining.value,
                key: "estimateRemaining",
                label: React_Basic_DOM.text("J\xe4ljell\xe4"),
                width: new TacoTable_Types.Flex(15)
            }), onFlag(props.displayMemo)({
                col: ColMemo.value,
                key: "memo",
                label: React_Basic_DOM.text(Locale.lookup_("cost_control_memo")),
                width: new TacoTable_Types.Flex(60)
            }) ]);
            var mkCol = function (v) {
                var $93 = fn(v.col);
                return {
                    sortProperty: $93.sortProperty,
                    key: v.key,
                    label: v.label,
                    width: v.width,
                    cell: $93.cell,
                    headerJSX: Data_Maybe.Nothing.value
                };
            };
            return Data_Functor.map(Data_Functor.functorArray)(mkCol)(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ {
                col: ColCheckbox.value,
                key: "checkbox",
                label: React_Basic_DOM.text(""),
                width: new TacoTable_Types.Flex(2)
            } ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(optionalPadding)(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ {
                col: ColCode.value,
                key: "code",
                label: React_Basic_DOM.text(Locale.lookup_("project_code")),
                width: new TacoTable_Types.Flex(12)
            }, {
                col: ColName.value,
                key: "name",
                label: React_Basic_DOM.text(Locale.lookup_("project_name")),
                width: new TacoTable_Types.Flex(30)
            } ])(optionals))));
        };
    };
};
var getTwoLevelSortingValue = function (sortKey) {
    return function (r) {
        if (sortKey instanceof ByCode) {
            return new TacoTable_Types.StringValue(r.code);
        };
        if (sortKey instanceof ByName) {
            return new TacoTable_Types.StringValue(r.name);
        };
        if (sortKey instanceof ByTarget) {
            return new TacoTable_Types.NumberValue(r.targetCosts);
        };
        if (sortKey instanceof ByEstimate) {
            return new TacoTable_Types.NumberValue(r.estimateCosts);
        };
        if (sortKey instanceof ByTargetToEstimate) {
            return new TacoTable_Types.NumberValue(r.targetToEstimate);
        };
        if (sortKey instanceof ByEstimateRemaining) {
            return new TacoTable_Types.NumberValue(r.estimateRemaining);
        };
        if (sortKey instanceof ByActual) {
            return new TacoTable_Types.NumberValue(r.actualCosts);
        };
        if (sortKey instanceof ByActualToEstimateRatio) {
            return TacoTable_Types.NumberValue.create(Data_Newtype.un()(Types_Unit.PercentageOfHundred)(r.actualToEstimateRatio));
        };
        if (sortKey instanceof ByCompletionPercentage) {
            return TacoTable_Types.NumberValue.create(Data_Newtype.un()(Types_Unit.PercentageOfHundred)(r.completionPercentage));
        };
        if (sortKey instanceof ByMemo) {
            return new TacoTable_Types.StringValue("");
        };
        throw new Error("Failed pattern match at CostControlTable (line 592, column 3 - line 602, column 29): " + [ sortKey.constructor.name ]);
    };
};
var nestedSortingCriteria = new TacoTable_Types.SortingCriteria(TacoTable_Types.Ascending.value, new Data_Maybe.Just(ByCode.value), getTwoLevelSortingValue);
var getTwoLevelColumns = function (fns) {
    return fns.prepareTwoLevelColumns(function (col) {
        if (col instanceof ColCheckbox) {
            return {
                sortProperty: Data_Maybe.Nothing.value,
                cell: TacoTable_Types.emptyCell
            };
        };
        if (col instanceof ColCode) {
            return {
                sortProperty: new Data_Maybe.Just(ByCode.value),
                cell: new TacoTable_Types.PlainTextCell(function (v) {
                    return v.code;
                })
            };
        };
        if (col instanceof ColName) {
            return {
                sortProperty: new Data_Maybe.Just(ByName.value),
                cell: new TacoTable_Types.PlainTextCell(function (v) {
                    return v.name;
                })
            };
        };
        if (col instanceof ColTarget) {
            return {
                sortProperty: new Data_Maybe.Just(ByTarget.value),
                cell: new TacoTable_Types.PlainEuroCell(function (v) {
                    return v.targetCosts;
                })
            };
        };
        if (col instanceof ColEstimate) {
            return {
                sortProperty: new Data_Maybe.Just(ByEstimate.value),
                cell: new TacoTable_Types.PlainEuroCell(function (v) {
                    return v.estimateCosts;
                })
            };
        };
        if (col instanceof ColTargetToEstimate) {
            return {
                sortProperty: new Data_Maybe.Just(ByTargetToEstimate.value),
                cell: TacoTable_Types.JSXCell.create([ TacoTable_Types.Numeric.value ])(function ($144) {
                    return FormattingHelpers.formatCurrencyDifferenceValue((function (v) {
                        return v.targetToEstimate;
                    })($144));
                })
            };
        };
        if (col instanceof ColActual) {
            return {
                sortProperty: new Data_Maybe.Just(ByActual.value),
                cell: new TacoTable_Types.PlainEuroCell(function (v) {
                    return v.actualCosts;
                })
            };
        };
        if (col instanceof ColActualToEstimateRatio) {
            return {
                sortProperty: new Data_Maybe.Just(ByActualToEstimateRatio.value),
                cell: TacoTable_Types.PlainTextCell.create(function ($145) {
                    return Formatters.formatPercentageOfHundredAsStringWithPercent((function (v) {
                        return v.actualToEstimateRatio;
                    })($145));
                })
            };
        };
        if (col instanceof ColCompletionPercentage) {
            return {
                sortProperty: new Data_Maybe.Just(ByCompletionPercentage.value),
                cell: TacoTable_Types.JSXCell.create([  ])(function ($146) {
                    return ProgressBar.mkCompletionPercentageComponent((function (v) {
                        return v.completionPercentage;
                    })($146));
                })
            };
        };
        if (col instanceof ColMemo) {
            return {
                sortProperty: Data_Maybe.Nothing.value,
                cell: new TacoTable_Types.PlainTextCell(function (v) {
                    return "";
                })
            };
        };
        if (col instanceof ColEstimateRemaining) {
            return {
                sortProperty: new Data_Maybe.Just(ByEstimateRemaining.value),
                cell: new TacoTable_Types.PlainEuroCell(function (v) {
                    return v.estimateRemaining;
                })
            };
        };
        throw new Error("Failed pattern match at CostControlTable (line 392, column 38 - line 414, column 93): " + [ col.constructor.name ]);
    });
};
var getSortingValue = function (sortKey) {
    return function (r) {
        if (sortKey instanceof ByCode) {
            return new TacoTable_Types.StringValue(r.code);
        };
        if (sortKey instanceof ByName) {
            return new TacoTable_Types.StringValue(r.name);
        };
        if (sortKey instanceof ByTarget) {
            return new TacoTable_Types.NumberValue(r.targetCosts);
        };
        if (sortKey instanceof ByEstimate) {
            return new TacoTable_Types.NumberValue(r.estimateCosts);
        };
        if (sortKey instanceof ByTargetToEstimate) {
            return new TacoTable_Types.NumberValue(r.targetToEstimate);
        };
        if (sortKey instanceof ByEstimateRemaining) {
            return new TacoTable_Types.NumberValue(r.estimateRemaining);
        };
        if (sortKey instanceof ByActual) {
            return new TacoTable_Types.NumberValue(r.actualCosts);
        };
        if (sortKey instanceof ByActualToEstimateRatio) {
            return TacoTable_Types.NumberValue.create(Data_Newtype.un()(Types_Unit.PercentageOfHundred)(r.actualToEstimateRatio));
        };
        if (sortKey instanceof ByCompletionPercentage) {
            return TacoTable_Types.NumberValue.create(Data_Newtype.un()(Types_Unit.PercentageOfHundred)(r.completionPercentage));
        };
        if (sortKey instanceof ByMemo) {
            return new TacoTable_Types.StringValue(r.costGroupUnitAndCostClasses.costGroupUnit.memo);
        };
        throw new Error("Failed pattern match at CostControlTable (line 576, column 3 - line 586, column 35): " + [ sortKey.constructor.name ]);
    };
};
var sortingCriteria = new TacoTable_Types.SortingCriteria(TacoTable_Types.Ascending.value, new Data_Maybe.Just(ByCode.value), getSortingValue);
var getNameSortProperty = {
    getName: function (v) {
        return "SortProperty";
    }
};
var nestedCostControlTableInstance = TacoTable.mkTableSortable(getNameSortProperty)("NestedCostControlTable");
var summaryCostControlTableInstance = TacoTable.mkTableStateless(getNameSortProperty)("SummaryCostControlTable");
var eqSortProperty = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ByCode && y instanceof ByCode) {
                return true;
            };
            if (x instanceof ByName && y instanceof ByName) {
                return true;
            };
            if (x instanceof ByTarget && y instanceof ByTarget) {
                return true;
            };
            if (x instanceof ByEstimate && y instanceof ByEstimate) {
                return true;
            };
            if (x instanceof ByTargetToEstimate && y instanceof ByTargetToEstimate) {
                return true;
            };
            if (x instanceof ByEstimateRemaining && y instanceof ByEstimateRemaining) {
                return true;
            };
            if (x instanceof ByActual && y instanceof ByActual) {
                return true;
            };
            if (x instanceof ByActualToEstimateRatio && y instanceof ByActualToEstimateRatio) {
                return true;
            };
            if (x instanceof ByCompletionPercentage && y instanceof ByCompletionPercentage) {
                return true;
            };
            if (x instanceof ByMemo && y instanceof ByMemo) {
                return true;
            };
            return false;
        };
    }
};
var ordSortProperty = {
    compare: function (x) {
        return function (y) {
            if (x instanceof ByCode && y instanceof ByCode) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByCode) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByCode) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByName && y instanceof ByName) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByName) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByName) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByTarget && y instanceof ByTarget) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByTarget) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByTarget) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByEstimate && y instanceof ByEstimate) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByEstimate) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByEstimate) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByTargetToEstimate && y instanceof ByTargetToEstimate) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByTargetToEstimate) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByTargetToEstimate) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByEstimateRemaining && y instanceof ByEstimateRemaining) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByEstimateRemaining) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByEstimateRemaining) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByActual && y instanceof ByActual) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByActual) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByActual) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByActualToEstimateRatio && y instanceof ByActualToEstimateRatio) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByActualToEstimateRatio) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByActualToEstimateRatio) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByCompletionPercentage && y instanceof ByCompletionPercentage) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByCompletionPercentage) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByCompletionPercentage) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByMemo && y instanceof ByMemo) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at CostControlTable (line 428, column 1 - line 428, column 52): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqSortProperty;
    }
};
var costControlTableStyleSet = TacoTable_Styles.foldingTableStyleSet;
var costControlTableInstance = TacoTable.mkTableSortable(getNameSortProperty)("CostControlTable");
var costClassTableStyleSet = TacoTable_Styles.foldingTableStyleSet;
var costClassTableInstance = TacoTable.mkTableStateless(getNameSortProperty)("CostControlTable");
var costGroupUnitFoldingDetails = TofuHooks.mkHookComponent("CostGroupUnitFoldingDetails")(function (props) {
    return function __do() {
        StateHooks.useDispatch();
        var detailProps = props.rowProps.mkCostGroupDetailProps(props.row.costGroupUnitAndCostClasses);
        var targetItems = Utils.matchCostGroupCode(props.row.costGroupUnitAndCostClasses.costGroupUnit)(detailProps.rawTargetItems);
        var prependNumber = function (v) {
            return {
                name: Data_Newtype.un()(Types_Newtypes.CostClassCode)(v.costClassCode) + (": " + v.name),
                costClassCode: v.costClassCode,
                target: v.target,
                estimate: v.estimate,
                actual: v.actual
            };
        };
        var prepareCostClassResultItems = function (v) {
            return function (v1) {
                return {
                    name: v1.name,
                    targetCosts: v1.target,
                    actualCosts: v1.actual,
                    targetToEstimate: v1.target - v1.estimate,
                    actualToEstimateRatio: Utils.defaultZeroDiv(v1.actual)(v1.estimate),
                    estimateRemaining: v1.estimate - v1.actual,
                    result: v1,
                    rowData: props.row
                };
            };
        };
        var estimateItems = Utils.matchCostGroupCode(props.row.costGroupUnitAndCostClasses.costGroupUnit)(detailProps.rawEstimateItems);
        var editCostGroupItems = EditCostGroupItems.component({
            projectId: props.row.costGroupUnitAndCostClasses.costGroupUnit.projectId,
            costGroupUnit: props.row.costGroupUnitAndCostClasses.costGroupUnit,
            costClasses: detailProps.costClasses,
            addCostGroupItemType: props.row.modalState.cgItemType,
            socialExpenses: props.row.costGroupUnitAndCostClasses.socialExpenses,
            costGroupItems: (function () {
                if (props.row.modalState.cgItemType instanceof Types_CostGroup.AddCostGroupItemTypeTargetItem) {
                    return targetItems;
                };
                if (props.row.modalState.cgItemType instanceof Types_CostGroup.AddCostGroupItemTypeEstimateItem) {
                    return estimateItems;
                };
                throw new Error("Failed pattern match at CostControlTable (line 293, column 25 - line 295, column 60): " + [ props.row.modalState.cgItemType.constructor.name ]);
            })(),
            costClassResults: props.row.costGroupUnitAndCostClasses.costClassResults,
            modalOpen: Data_Maybe.maybe(false)(Data_Eq.eq(Types_CostGroup.eqCostGroupId)(props.row.costGroupUnitAndCostClasses.costGroupUnit.id))(props.row.modalState.modalOpenForCgId),
            closeModal: props.fns.closeModal
        });
        var details = CostGroupDetails.component({
            costGroupUnitAndCostClasses: props.row.costGroupUnitAndCostClasses,
            costGroupCategories: detailProps.costGroupCategories,
            resources: detailProps.resources,
            rawEstimateItems: detailProps.rawEstimateItems,
            rawTargetItems: detailProps.rawTargetItems,
            costClassResults: props.row.costGroupUnitAndCostClasses.costClassResults,
            withSocialExpenses: props.row.displayProps.withSocialExpenses,
            descriptionDisplay: props.row.displayProps.descriptionDisplay,
            isCollapsed: false,
            projectId: props.rowProps.projectId,
            socialExpenses: props.row.costGroupUnitAndCostClasses.socialExpenses
        });
        var costClassResults = Data_Functor.map(Data_Functor.functorArray)(prependNumber)(props.row.costGroupUnitAndCostClasses.costClassResults);
        var costClassItems = Data_Semigroup.append(Data_Semigroup.semigroupArray)(costClassResults)((function () {
            if (props.rowProps.withSocialExpenses) {
                return [ props.row.costGroupUnitAndCostClasses.socialExpenses ];
            };
            return [  ];
        })());
        var costClassColumns = Data_Semigroup.append(Data_Semigroup.semigroupArray)([ {
            key: "placeholder",
            label: React_Basic_DOM.text(""),
            cell: TacoTable_Types.FoldingIndicatorPlaceholder.value,
            sortProperty: Data_Maybe.Nothing.value,
            width: new TacoTable_Types.Flex(0),
            headerJSX: Data_Maybe.Nothing.value
        } ])(props.fns.prepareCostClassColumns(function (col) {
            if (col instanceof ColCheckbox) {
                return {
                    sortProperty: Data_Maybe.Nothing.value,
                    cell: TacoTable_Types.emptyCell
                };
            };
            if (col instanceof ColCode) {
                return {
                    sortProperty: Data_Maybe.Nothing.value,
                    cell: new TacoTable_Types.PlainTextCell(function (v) {
                        return "";
                    })
                };
            };
            if (col instanceof ColName) {
                return {
                    sortProperty: Data_Maybe.Nothing.value,
                    cell: new TacoTable_Types.PlainTextCell(function (v) {
                        return v.name;
                    })
                };
            };
            if (col instanceof ColTarget) {
                return {
                    sortProperty: Data_Maybe.Nothing.value,
                    cell: TacoTable_Types.JSXCell.create([ TacoTable_Types.Numeric.value ])((function () {
                        var $147 = Formatters.formatCurrencyValue("EUR");
                        return function ($148) {
                            return React_Basic_DOM.text($147((function (v) {
                                return v.targetCosts;
                            })($148)));
                        };
                    })())
                };
            };
            if (col instanceof ColEstimate) {
                return {
                    sortProperty: Data_Maybe.Nothing.value,
                    cell: TacoTable_Types.JSXCell.create([ TacoTable_Types.Numeric.value ])((function () {
                        var $149 = Formatters.formatCurrencyValue("EUR");
                        return function ($150) {
                            return React_Basic_DOM.text($149((function (v) {
                                return v.result.estimate;
                            })($150)));
                        };
                    })())
                };
            };
            if (col instanceof ColTargetToEstimate) {
                return {
                    sortProperty: Data_Maybe.Nothing.value,
                    cell: new TacoTable_Types.PlainEuroCell(function (v) {
                        return v.targetToEstimate;
                    })
                };
            };
            if (col instanceof ColActual) {
                return {
                    sortProperty: Data_Maybe.Nothing.value,
                    cell: new TacoTable_Types.PlainEuroCell(function (v) {
                        return v.actualCosts;
                    })
                };
            };
            if (col instanceof ColActualToEstimateRatio) {
                return {
                    sortProperty: Data_Maybe.Nothing.value,
                    cell: TacoTable_Types.PlainTextCell.create((function () {
                        var $151 = Formatters.formatDecimalValue(2);
                        return function ($152) {
                            return $151((function (v) {
                                return v.actualToEstimateRatio;
                            })($152));
                        };
                    })())
                };
            };
            if (col instanceof ColCompletionPercentage) {
                return {
                    sortProperty: Data_Maybe.Nothing.value,
                    cell: new TacoTable_Types.PlainTextCell(function (v) {
                        return "";
                    })
                };
            };
            if (col instanceof ColMemo) {
                return {
                    sortProperty: Data_Maybe.Nothing.value,
                    cell: new TacoTable_Types.PlainTextCell(function (v) {
                        return "";
                    })
                };
            };
            if (col instanceof ColEstimateRemaining) {
                return {
                    sortProperty: Data_Maybe.Nothing.value,
                    cell: new TacoTable_Types.PlainEuroCell(function (v) {
                        return v.estimateRemaining;
                    })
                };
            };
            throw new Error("Failed pattern match at CostControlTable (line 232, column 43 - line 243, column 102): " + [ col.constructor.name ]);
        }));
        var getCostClassRow = function (ccr) {
            return {
                className: "cost-control-table-cost-class-row",
                rowData: ccr,
                foldingElement: Data_Maybe.Nothing.value,
                onClick: function (v) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                },
                rowKey: Data_Show.show(Data_Show.showString)(ccr.name),
                columns: costClassColumns
            };
        };
        var costClassTable = TacoTable.tableStateless(getNameSortProperty)(eqSortProperty)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "targetToEstimate";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "targetCosts";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "rowData";
            }
        })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "updateStatuses";
            }
        })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "loadingUpdateTargetItems";
            }
        })(Data_Eq.eqArray(Types_Project.eqProjectId)))()({
            reflectSymbol: function () {
                return "loadingRemoveIncomeItems";
            }
        })(Data_Eq.eqArray(Types_Income.eqIncomeItemId)))()({
            reflectSymbol: function () {
                return "loadingIncomeItemTargetInclusionStatuses";
            }
        })(Data_Eq.eqArray(Types_Income.eqIncomeItemId)))()({
            reflectSymbol: function () {
                return "loadingIncomeItemPlannedInvoiceDates";
            }
        })(Data_Eq.eqArray(Types_Income.eqIncomeItemId)))()({
            reflectSymbol: function () {
                return "loadingIncomeItemApprovalStatuses";
            }
        })(Data_Eq.eqArray(Types_Income.eqIncomeItemId)))()({
            reflectSymbol: function () {
                return "loadingIncomeItemApprovalDates";
            }
        })(Data_Eq.eqArray(Types_Income.eqIncomeItemId)))()({
            reflectSymbol: function () {
                return "loadingEstimationLocationAction";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "loadingEstimationComponentCopyOperation";
            }
        })(Data_Eq.eqArray(Types_Project.eqProjectId)))()({
            reflectSymbol: function () {
                return "loadingEstimateComponents";
            }
        })(Data_Map_Internal.eqMap(Types_Component.eqComponentId)(UpdateStatusesReducer.eqSaveState)))()({
            reflectSymbol: function () {
                return "loadingAddNewCostGroup";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "loadingAddCostGroupItems";
            }
        })(Data_Eq.eqArray(Types_CostGroup.eqCostGroupCode)))()({
            reflectSymbol: function () {
                return "costGroupSaveStatus";
            }
        })(Data_Map_Internal.eqMap(Types_CostGroup.eqCostGroupId)(UpdateStatusesReducer.eqSaveState)))))()({
            reflectSymbol: function () {
                return "targetToEstimate";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "targetCosts";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "selectedCostGroups";
            }
        })(Data_Eq.eqArray(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "name";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "modalState";
            }
        })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "modalOpenForCgId";
            }
        })(Data_Maybe.eqMaybe(Types_CostGroup.eqCostGroupId)))()({
            reflectSymbol: function () {
                return "cgItemType";
            }
        })(Types_CostGroup.eqAddCostGroupItemType))))()({
            reflectSymbol: function () {
                return "foldingState";
            }
        })(TacoTable_Types.eqFoldingState))()({
            reflectSymbol: function () {
                return "estimateRemaining";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "estimateCosts";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "displayProps";
            }
        })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "withSocialExpenses";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "displayMemo";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "displayEstimateRemaining";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "displayCompletionPercentage";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "displayActualToEstimateRatio";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "descriptionDisplay";
            }
        })(CostGroupDetails_Types.eqDescriptionDisplay))))()({
            reflectSymbol: function () {
                return "diffOfTargetToEstimate";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "costGroupUnitAndCostClasses";
            }
        })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "socialExpenses";
            }
        })(Types_R200.eqR200CostClassResult))()({
            reflectSymbol: function () {
                return "costGroupUnit";
            }
        })(Types_CostGroup.equalCostGroupUnit))()({
            reflectSymbol: function () {
                return "costClassResults";
            }
        })(Data_Eq.eqArray(Types_R200.eqR200CostClassResult)))))()({
            reflectSymbol: function () {
                return "completionPercentage";
            }
        })(Types_Unit.eqPercentageOfHundred))()({
            reflectSymbol: function () {
                return "code";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "actualToEstimateRatio";
            }
        })(Types_Unit.eqPercentageOfHundred))()({
            reflectSymbol: function () {
                return "actualCosts";
            }
        })(Data_Eq.eqNumber))))()({
            reflectSymbol: function () {
                return "result";
            }
        })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "target";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "name";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "estimate";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "costClassCode";
            }
        })(Types_Newtypes.eqCostClassCode))()({
            reflectSymbol: function () {
                return "actual";
            }
        })(Data_Eq.eqNumber))))()({
            reflectSymbol: function () {
                return "name";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "estimateRemaining";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "actualToEstimateRatio";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "actualCosts";
            }
        })(Data_Eq.eqNumber)))(costClassTableInstance)({
            sortProperty: Data_Maybe.Nothing.value,
            onSort: function (v) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            },
            sortingOrder: TacoTable_Types.Ascending.value,
            rows: Data_Functor.map(Data_Functor.functorArray)((function () {
                var $153 = prepareCostClassResultItems(props.row.costGroupUnitAndCostClasses.costGroupUnit.code);
                return function ($154) {
                    return getCostClassRow($153($154));
                };
            })())(costClassItems),
            columns: costClassColumns,
            showHeader: false,
            styleSet: costClassTableStyleSet,
            onResize: function (v) {
                return function (v1) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                };
            },
            columnSizeOffsets: Data_Maybe.Nothing.value
        });
        return ReactBasicUtils["div'"]("")([ costClassTable, editCostGroupItems, details ]);
    };
});
var cgu_ = function (v) {
    return v.costGroupUnitAndCostClasses.costGroupUnit;
};
var getCostGroupUnitRow = function (fns) {
    return function (columns) {
        return function (props) {
            return function (r) {
                var cgu = cgu_(r);
                return {
                    className: "cost-control-table-row",
                    rowData: r,
                    foldingElement: Data_Maybe.Just.create(new TacoTable_Types.FoldingElement(r.foldingState, function (v) {
                        return costGroupUnitFoldingDetails({
                            row: r,
                            rowProps: props,
                            fns: fns
                        });
                    })),
                    onClick: function (v) {
                        return fns.toggleCostGroupDetail(cgu.code);
                    },
                    rowKey: Data_Show.show(Types_CostGroup.showCostGroupCode)(cgu.code),
                    columns: columns
                };
            };
        };
    };
};
var renderRowDataTable = function (showHeader) {
    return function (fns) {
        return function (props) {
            return function (rowData) {
                var maybeButton = function (costs) {
                    return function (openModal) {
                        return function (r) {
                            var cgu = cgu_(r);
                            if (r.foldingState instanceof TacoTable_Types.Folded) {
                                return ReactBasicUtils["div'"]("")(Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM.text(Formatters.formatCurrencyValue("EUR")(costs(cgu)))));
                            };
                            if (r.foldingState instanceof TacoTable_Types.Unfolded) {
                                return TacoButton.component()()({
                                    text: Formatters.formatCurrencyValue("EUR")(costs(cgu)),
                                    icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconEditV1.value),
                                    onClick: Data_Maybe.Just.create(openModal(cgu.id)),
                                    buttonStyle: TacoButton_Types.Link.value
                                });
                            };
                            throw new Error("Failed pattern match at CostControlTable (line 368, column 39 - line 375, column 12): " + [ r.foldingState.constructor.name ]);
                        };
                    };
                };
                var checkboxElement = function (costGroup) {
                    var isChecked = Data_Array.elem(Data_Eq.eqString)(costGroup.code)(props.selectedCostGroups);
                    var toggleF = (function () {
                        if (isChecked) {
                            return props.removeSelectedCostGroups;
                        };
                        return props.selectCostGroups;
                    })();
                    return TacoCheckbox.component()()({
                        className: "no-margin",
                        isChecked: isChecked,
                        onToggle: TacoCheckbox.NoEvent.create(toggleF(Control_Applicative.pure(Control_Applicative.applicativeArray)(costGroup.code))),
                        labelText: Data_Maybe.Nothing.value,
                        testId: "toggle-cost-group-checkbox" + Data_Show.show(Data_Show.showString)(costGroup.code)
                    });
                };
                var costGroupUnitColumns = fns.prepareCostGroupUnitColumns(function (col) {
                    if (col instanceof ColCheckbox) {
                        return {
                            sortProperty: Data_Maybe.Nothing.value,
                            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], checkboxElement)
                        };
                    };
                    if (col instanceof ColCode) {
                        return {
                            sortProperty: new Data_Maybe.Just(ByCode.value),
                            cell: TacoTable_Types.PlainTextCell.create((function () {
                                var $155 = Data_Newtype.un()(Types_CostGroup.CostGroupCode);
                                return function ($156) {
                                    return $155((function (v) {
                                        return v.code;
                                    })(cgu_($156)));
                                };
                            })())
                        };
                    };
                    if (col instanceof ColName) {
                        return {
                            sortProperty: new Data_Maybe.Just(ByName.value),
                            cell: TacoTable_Types.PlainTextCell.create(function ($157) {
                                return (function (v) {
                                    return v.name;
                                })(cgu_($157));
                            })
                        };
                    };
                    if (col instanceof ColTarget) {
                        return {
                            sortProperty: new Data_Maybe.Just(ByTarget.value),
                            cell: TacoTable_Types.JSXCell.create([ TacoTable_Types.Numeric.value ])(maybeButton(function (v) {
                                return v.targetCosts;
                            })(fns.openTargetEditModal))
                        };
                    };
                    if (col instanceof ColEstimate) {
                        return {
                            sortProperty: new Data_Maybe.Just(ByEstimate.value),
                            cell: TacoTable_Types.JSXCell.create([ TacoTable_Types.Numeric.value ])(maybeButton(function (v) {
                                return v.estimateCosts;
                            })(fns.openEstimateEditModal))
                        };
                    };
                    if (col instanceof ColTargetToEstimate) {
                        return {
                            sortProperty: new Data_Maybe.Just(ByTargetToEstimate.value),
                            cell: TacoTable_Types.JSXCell.create([ TacoTable_Types.Numeric.value ])(function ($158) {
                                return FormattingHelpers.formatCurrencyDifferenceValue((function (v) {
                                    return v.diffOfTargetToEstimate;
                                })($158));
                            })
                        };
                    };
                    if (col instanceof ColActual) {
                        return {
                            sortProperty: new Data_Maybe.Just(ByActual.value),
                            cell: TacoTable_Types.PlainEuroCell.create(function ($159) {
                                return (function (v) {
                                    return v.actualCosts;
                                })(cgu_($159));
                            })
                        };
                    };
                    if (col instanceof ColActualToEstimateRatio) {
                        return {
                            sortProperty: new Data_Maybe.Just(ByActualToEstimateRatio.value),
                            cell: TacoTable_Types.PlainTextCell.create(function ($160) {
                                return Formatters.formatPercentageOfHundredAsStringWithPercent((function (v) {
                                    return v.actualToEstimateRatio;
                                })($160));
                            })
                        };
                    };
                    if (col instanceof ColCompletionPercentage) {
                        return {
                            sortProperty: new Data_Maybe.Just(ByCompletionPercentage.value),
                            cell: TacoTable_Types.JSXCell.create([  ])(function ($161) {
                                return ProgressBar.mkCompletionPercentageComponent((function (v) {
                                    return v.completionPercentage;
                                })(cgu_($161)));
                            })
                        };
                    };
                    if (col instanceof ColMemo) {
                        return {
                            sortProperty: new Data_Maybe.Just(ByMemo.value),
                            cell: TacoTable_Types.PlainTextCell.create(function ($162) {
                                return (function (v) {
                                    return v.memo;
                                })(cgu_($162));
                            })
                        };
                    };
                    if (col instanceof ColEstimateRemaining) {
                        return {
                            sortProperty: new Data_Maybe.Just(ByEstimateRemaining.value),
                            cell: TacoTable_Types.PlainEuroCell.create(function (v) {
                                return v.estimateRemaining;
                            })
                        };
                    };
                    throw new Error("Failed pattern match at CostControlTable (line 331, column 49 - line 353, column 101): " + [ col.constructor.name ]);
                });
                return TacoTable.tableSortable(getNameSortProperty)(eqSortProperty)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "updateStatuses";
                    }
                })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "loadingUpdateTargetItems";
                    }
                })(Data_Eq.eqArray(Types_Project.eqProjectId)))()({
                    reflectSymbol: function () {
                        return "loadingRemoveIncomeItems";
                    }
                })(Data_Eq.eqArray(Types_Income.eqIncomeItemId)))()({
                    reflectSymbol: function () {
                        return "loadingIncomeItemTargetInclusionStatuses";
                    }
                })(Data_Eq.eqArray(Types_Income.eqIncomeItemId)))()({
                    reflectSymbol: function () {
                        return "loadingIncomeItemPlannedInvoiceDates";
                    }
                })(Data_Eq.eqArray(Types_Income.eqIncomeItemId)))()({
                    reflectSymbol: function () {
                        return "loadingIncomeItemApprovalStatuses";
                    }
                })(Data_Eq.eqArray(Types_Income.eqIncomeItemId)))()({
                    reflectSymbol: function () {
                        return "loadingIncomeItemApprovalDates";
                    }
                })(Data_Eq.eqArray(Types_Income.eqIncomeItemId)))()({
                    reflectSymbol: function () {
                        return "loadingEstimationLocationAction";
                    }
                })(Data_Eq.eqBoolean))()({
                    reflectSymbol: function () {
                        return "loadingEstimationComponentCopyOperation";
                    }
                })(Data_Eq.eqArray(Types_Project.eqProjectId)))()({
                    reflectSymbol: function () {
                        return "loadingEstimateComponents";
                    }
                })(Data_Map_Internal.eqMap(Types_Component.eqComponentId)(UpdateStatusesReducer.eqSaveState)))()({
                    reflectSymbol: function () {
                        return "loadingAddNewCostGroup";
                    }
                })(Data_Eq.eqBoolean))()({
                    reflectSymbol: function () {
                        return "loadingAddCostGroupItems";
                    }
                })(Data_Eq.eqArray(Types_CostGroup.eqCostGroupCode)))()({
                    reflectSymbol: function () {
                        return "costGroupSaveStatus";
                    }
                })(Data_Map_Internal.eqMap(Types_CostGroup.eqCostGroupId)(UpdateStatusesReducer.eqSaveState)))))()({
                    reflectSymbol: function () {
                        return "targetToEstimate";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "targetCosts";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "selectedCostGroups";
                    }
                })(Data_Eq.eqArray(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(Data_Eq.eqString))()({
                    reflectSymbol: function () {
                        return "modalState";
                    }
                })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "modalOpenForCgId";
                    }
                })(Data_Maybe.eqMaybe(Types_CostGroup.eqCostGroupId)))()({
                    reflectSymbol: function () {
                        return "cgItemType";
                    }
                })(Types_CostGroup.eqAddCostGroupItemType))))()({
                    reflectSymbol: function () {
                        return "foldingState";
                    }
                })(TacoTable_Types.eqFoldingState))()({
                    reflectSymbol: function () {
                        return "estimateRemaining";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "estimateCosts";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "displayProps";
                    }
                })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "withSocialExpenses";
                    }
                })(Data_Eq.eqBoolean))()({
                    reflectSymbol: function () {
                        return "displayMemo";
                    }
                })(Data_Eq.eqBoolean))()({
                    reflectSymbol: function () {
                        return "displayEstimateRemaining";
                    }
                })(Data_Eq.eqBoolean))()({
                    reflectSymbol: function () {
                        return "displayCompletionPercentage";
                    }
                })(Data_Eq.eqBoolean))()({
                    reflectSymbol: function () {
                        return "displayActualToEstimateRatio";
                    }
                })(Data_Eq.eqBoolean))()({
                    reflectSymbol: function () {
                        return "descriptionDisplay";
                    }
                })(CostGroupDetails_Types.eqDescriptionDisplay))))()({
                    reflectSymbol: function () {
                        return "diffOfTargetToEstimate";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "costGroupUnitAndCostClasses";
                    }
                })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "socialExpenses";
                    }
                })(Types_R200.eqR200CostClassResult))()({
                    reflectSymbol: function () {
                        return "costGroupUnit";
                    }
                })(Types_CostGroup.equalCostGroupUnit))()({
                    reflectSymbol: function () {
                        return "costClassResults";
                    }
                })(Data_Eq.eqArray(Types_R200.eqR200CostClassResult)))))()({
                    reflectSymbol: function () {
                        return "completionPercentage";
                    }
                })(Types_Unit.eqPercentageOfHundred))()({
                    reflectSymbol: function () {
                        return "code";
                    }
                })(Data_Eq.eqString))()({
                    reflectSymbol: function () {
                        return "actualToEstimateRatio";
                    }
                })(Types_Unit.eqPercentageOfHundred))()({
                    reflectSymbol: function () {
                        return "actualCosts";
                    }
                })(Data_Eq.eqNumber)))(costControlTableInstance)(sortingCriteria)(Data_Maybe.Nothing.value)({
                    rows: Data_Functor.map(Data_Functor.functorArray)(getCostGroupUnitRow(fns)(costGroupUnitColumns)(props))(rowData),
                    columns: costGroupUnitColumns,
                    showHeader: showHeader,
                    styleSet: costControlTableStyleSet
                });
            };
        };
    };
};
var getTwoLevelRow = function (fns) {
    return function (columns) {
        return function (props) {
            return function (r) {
                return {
                    className: "nested-cost-control-table-row",
                    rowData: r,
                    foldingElement: Data_Maybe.Just.create(new TacoTable_Types.FoldingElement(r.foldingState, function (r$prime) {
                        return renderRowDataTable(false)(fns)(props)(r$prime.children);
                    })),
                    onClick: function (v) {
                        return fns.toggleTwoLevelNesting(r.code);
                    },
                    rowKey: Data_Show.show(Data_Show.showString)(r.code),
                    columns: columns
                };
            };
        };
    };
};
var takeCostGroupCode = (function () {
    var $163 = Data_Newtype.un()(Types_CostGroup.CostGroupCode);
    return function ($164) {
        return $163((function (v) {
            return v.code;
        })(cgu_($164)));
    };
})();
var take3Digits = (function () {
    var $165 = Data_String_CodePoints.take(3);
    return function ($166) {
        return $165(takeCostGroupCode($166));
    };
})();
var mkTwoLevelRowData = function (unfoldedTwoLevels) {
    return function (ys) {
        var code = take3Digits(Data_Array_NonEmpty.head(ys));
        var v = Data_Array_NonEmpty.partition(function (x) {
            return 3 === Data_String_CodePoints.length(takeCostGroupCode(x));
        })(ys);
        var ys$prime = Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)(cgu_)(ys);
        var targetCosts = Data_Foldable.sum(Data_Array_NonEmpty_Internal.foldableNonEmptyArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)(function (v1) {
            return v1.targetCosts;
        })(ys$prime));
        var label = Data_Functor.map(Data_Maybe.functorMaybe)(function ($167) {
            return (function (v1) {
                return v1.name;
            })(cgu_($167));
        })(Data_Array.head(v.yes));
        var itemsCount = "(" + (Data_Show.show(Data_Show.showInt)(Data_Array.length(v.no)) + ")");
        var estimateCosts = Data_Foldable.sum(Data_Array_NonEmpty_Internal.foldableNonEmptyArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)(function (v1) {
            return v1.estimateCosts;
        })(ys$prime));
        var weightedCompletionPercentage = function (arr) {
            return Data_Newtype.un()(Data_Monoid_Additive.Additive)(Data_Array.foldMap(Data_Monoid_Additive.monoidAdditive(Types_Unit.semiringPercentageOfHundred))(function (rd) {
                var i = cgu_(rd);
                var ratio = Utils.defaultZeroDiv(i.estimateCosts)(estimateCosts);
                var percentage = Data_Newtype.un()(Types_Unit.PercentageOfHundred)(i.completionPercentage);
                return Data_Monoid_Additive.Additive(Types_Unit.PercentageOfHundred(percentage * ratio));
            })(arr));
        };
        var actualCosts = Data_Foldable.sum(Data_Array_NonEmpty_Internal.foldableNonEmptyArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)(function (v1) {
            return v1.actualCosts;
        })(ys$prime));
        return {
            code: code,
            name: Data_Maybe.fromMaybe(itemsCount)(label),
            targetCosts: targetCosts,
            estimateCosts: estimateCosts,
            actualCosts: actualCosts,
            targetToEstimate: targetCosts - estimateCosts,
            actualToEstimateRatio: Types_Unit.PercentageOfHundred(100.0 * Utils.defaultZeroDiv(actualCosts)(estimateCosts)),
            completionPercentage: weightedCompletionPercentage(v.no),
            estimateRemaining: estimateCosts - actualCosts,
            children: v.no,
            foldingState: (function () {
                var $127 = Data_Set.member(Data_Ord.ordString)(code)(unfoldedTwoLevels);
                if ($127) {
                    return TacoTable_Types.Unfolded.value;
                };
                return TacoTable_Types.Folded.value;
            })()
        };
    };
};
var prepareTwoLevelRowData = function (unfoldedTwoLevels) {
    return function (rowData) {
        var groupedByCode = Data_Array.groupBy(Data_Function.on(Data_Eq.eq(Data_Eq.eqString))(take3Digits))(Data_Array.sortBy(Data_Function.on(Data_Ord.compare(Data_Ord.ordString))(take3Digits))(rowData));
        return Data_Functor.map(Data_Functor.functorArray)(mkTwoLevelRowData(unfoldedTwoLevels))(groupedByCode);
    };
};
var mkCostControlTable = TofuHooks.mkHookComponent("CostControlTable")(function (props) {
    return function __do() {
        var v = TofuHooks.useState(Data_Set.empty)();
        var toggleCostGroupDetail = function (costGroupCode) {
            return v.value1(Utils.toggleInSet(Types_CostGroup.ordCostGroupCode)(costGroupCode));
        };
        var v1 = TofuHooks.useState(Data_Set.empty)();
        var toggleTwoLevelNesting = function (code) {
            return v1.value1(Utils.toggleInSet(Data_Ord.ordString)(code));
        };
        var v2 = TofuHooks.useState(Types_CostGroup.AddCostGroupItemTypeTargetItem.value)();
        var v3 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var prepareTwoLevelColumns = mkPrepareColumns(CCTableNoPadding.value)(props);
        var prepareCostGroupUnitColumns = mkPrepareColumns(CCTableNoPadding.value)(props);
        var prepareCostClassColumns = mkPrepareColumns(CCTableNoPadding.value)(props);
        var openTargetEditModal = function (id) {
            return function __do() {
                v2.value1(function (v4) {
                    return Types_CostGroup.AddCostGroupItemTypeTargetItem.value;
                })();
                return v3.value1(function (v4) {
                    return new Data_Maybe.Just(id);
                })();
            };
        };
        var openEstimateEditModal = function (id) {
            return function __do() {
                v2.value1(function (v4) {
                    return Types_CostGroup.AddCostGroupItemTypeEstimateItem.value;
                })();
                return v3.value1(function (v4) {
                    return new Data_Maybe.Just(id);
                })();
            };
        };
        var modalState = {
            cgItemType: v2.value0,
            modalOpenForCgId: v3.value0
        };
        var rowData = Data_Functor.map(Data_Functor.functorArray)(mkRowData(props)(v.value0)(modalState))(props.costGroupUnitAndCostClasses);
        var closeModal = v3.value1(function (v4) {
            return Data_Maybe.Nothing.value;
        });
        var fns = {
            toggleCostGroupDetail: toggleCostGroupDetail,
            prepareCostClassColumns: prepareCostClassColumns,
            prepareCostGroupUnitColumns: prepareCostGroupUnitColumns,
            prepareTwoLevelColumns: prepareTwoLevelColumns,
            toggleTwoLevelNesting: toggleTwoLevelNesting,
            openTargetEditModal: openTargetEditModal,
            openEstimateEditModal: openEstimateEditModal,
            closeModal: closeModal
        };
        var twoLevelColumns = getTwoLevelColumns(fns);
        var summaryData = mkSummaryData(props)(rowData);
        var summaryColumns = mkPrepareColumns(CCTableAddLeftPadding.value)(props)(function (col) {
            if (col instanceof ColCheckbox) {
                return {
                    sortProperty: Data_Maybe.Nothing.value,
                    cell: TacoTable_Types.emptyCell
                };
            };
            if (col instanceof ColCode) {
                return {
                    sortProperty: Data_Maybe.Nothing.value,
                    cell: new TacoTable_Types.PlainTextCell(function (v4) {
                        return "";
                    })
                };
            };
            if (col instanceof ColName) {
                return {
                    sortProperty: Data_Maybe.Nothing.value,
                    cell: new TacoTable_Types.PlainTextCell(function (v4) {
                        return "Yhteens\xe4";
                    })
                };
            };
            if (col instanceof ColTarget) {
                return {
                    sortProperty: Data_Maybe.Nothing.value,
                    cell: TacoTable_Types.PlainEuroCell.create(function (v4) {
                        return v4.targetCosts;
                    })
                };
            };
            if (col instanceof ColEstimate) {
                return {
                    sortProperty: Data_Maybe.Nothing.value,
                    cell: TacoTable_Types.PlainEuroCell.create(function (v4) {
                        return v4.estimateCosts;
                    })
                };
            };
            if (col instanceof ColTargetToEstimate) {
                return {
                    sortProperty: Data_Maybe.Nothing.value,
                    cell: TacoTable_Types.JSXCell.create([ TacoTable_Types.Numeric.value ])(function ($168) {
                        return FormattingHelpers.formatCurrencyDifferenceValue((function (v4) {
                            return v4.diffOfTargetToEstimate;
                        })($168));
                    })
                };
            };
            if (col instanceof ColActual) {
                return {
                    sortProperty: Data_Maybe.Nothing.value,
                    cell: TacoTable_Types.PlainEuroCell.create(function (v4) {
                        return v4.actualCosts;
                    })
                };
            };
            if (col instanceof ColActualToEstimateRatio) {
                return {
                    sortProperty: Data_Maybe.Nothing.value,
                    cell: TacoTable_Types.PlainTextCell.create(function ($169) {
                        return Formatters.formatPercentageOfHundredAsStringWithPercent((function (v4) {
                            return v4.actualToEstimateRatio;
                        })($169));
                    })
                };
            };
            if (col instanceof ColCompletionPercentage) {
                return {
                    sortProperty: Data_Maybe.Nothing.value,
                    cell: new TacoTable_Types.PlainTextCell(function (v4) {
                        return "";
                    })
                };
            };
            if (col instanceof ColMemo) {
                return {
                    sortProperty: Data_Maybe.Nothing.value,
                    cell: new TacoTable_Types.PlainTextCell(function (v4) {
                        return "";
                    })
                };
            };
            if (col instanceof ColEstimateRemaining) {
                return {
                    sortProperty: Data_Maybe.Nothing.value,
                    cell: TacoTable_Types.PlainEuroCell.create(function (v4) {
                        return v4.estimateRemaining;
                    })
                };
            };
            throw new Error("Failed pattern match at CostControlTable (line 134, column 75 - line 145, column 102): " + [ col.constructor.name ]);
        });
        var summaryRow = {
            rowData: summaryData,
            foldingElement: Data_Maybe.Nothing.value,
            onClick: function (v4) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            },
            rowKey: "cc-summary-row",
            columns: summaryColumns,
            className: "summary-cost-control-table-row"
        };
        var summaryTable = TacoTable.tableStateless(getNameSortProperty)(eqSortProperty)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "targetToEstimate";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "targetCosts";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "estimateRemaining";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "estimateCosts";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "diffOfTargetToEstimate";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "actualToEstimateRatio";
            }
        })(Types_Unit.eqPercentageOfHundred))()({
            reflectSymbol: function () {
                return "actualCosts";
            }
        })(Data_Eq.eqNumber)))(summaryCostControlTableInstance)({
            rows: [ summaryRow ],
            columns: summaryColumns,
            showHeader: false,
            styleSet: TacoTable_Styles.summaryTableStyleSet,
            sortProperty: Data_Maybe.Nothing.value,
            onSort: function (v4) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            },
            sortingOrder: TacoTable_Types.Ascending.value,
            onResize: function (v4) {
                return function (v5) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                };
            },
            columnSizeOffsets: Data_Maybe.Nothing.value
        });
        if (props.nestCostGroupItemsBy3CodeDigits) {
            return React_Basic.fragment([ TacoTable.tableSortable(getNameSortProperty)(eqSortProperty)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "targetToEstimate";
                }
            })(Data_Eq.eqNumber))()({
                reflectSymbol: function () {
                    return "targetCosts";
                }
            })(Data_Eq.eqNumber))()({
                reflectSymbol: function () {
                    return "name";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "foldingState";
                }
            })(TacoTable_Types.eqFoldingState))()({
                reflectSymbol: function () {
                    return "estimateRemaining";
                }
            })(Data_Eq.eqNumber))()({
                reflectSymbol: function () {
                    return "estimateCosts";
                }
            })(Data_Eq.eqNumber))()({
                reflectSymbol: function () {
                    return "completionPercentage";
                }
            })(Types_Unit.eqPercentageOfHundred))()({
                reflectSymbol: function () {
                    return "code";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "children";
                }
            })(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "updateStatuses";
                }
            })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "loadingUpdateTargetItems";
                }
            })(Data_Eq.eqArray(Types_Project.eqProjectId)))()({
                reflectSymbol: function () {
                    return "loadingRemoveIncomeItems";
                }
            })(Data_Eq.eqArray(Types_Income.eqIncomeItemId)))()({
                reflectSymbol: function () {
                    return "loadingIncomeItemTargetInclusionStatuses";
                }
            })(Data_Eq.eqArray(Types_Income.eqIncomeItemId)))()({
                reflectSymbol: function () {
                    return "loadingIncomeItemPlannedInvoiceDates";
                }
            })(Data_Eq.eqArray(Types_Income.eqIncomeItemId)))()({
                reflectSymbol: function () {
                    return "loadingIncomeItemApprovalStatuses";
                }
            })(Data_Eq.eqArray(Types_Income.eqIncomeItemId)))()({
                reflectSymbol: function () {
                    return "loadingIncomeItemApprovalDates";
                }
            })(Data_Eq.eqArray(Types_Income.eqIncomeItemId)))()({
                reflectSymbol: function () {
                    return "loadingEstimationLocationAction";
                }
            })(Data_Eq.eqBoolean))()({
                reflectSymbol: function () {
                    return "loadingEstimationComponentCopyOperation";
                }
            })(Data_Eq.eqArray(Types_Project.eqProjectId)))()({
                reflectSymbol: function () {
                    return "loadingEstimateComponents";
                }
            })(Data_Map_Internal.eqMap(Types_Component.eqComponentId)(UpdateStatusesReducer.eqSaveState)))()({
                reflectSymbol: function () {
                    return "loadingAddNewCostGroup";
                }
            })(Data_Eq.eqBoolean))()({
                reflectSymbol: function () {
                    return "loadingAddCostGroupItems";
                }
            })(Data_Eq.eqArray(Types_CostGroup.eqCostGroupCode)))()({
                reflectSymbol: function () {
                    return "costGroupSaveStatus";
                }
            })(Data_Map_Internal.eqMap(Types_CostGroup.eqCostGroupId)(UpdateStatusesReducer.eqSaveState)))))()({
                reflectSymbol: function () {
                    return "targetToEstimate";
                }
            })(Data_Eq.eqNumber))()({
                reflectSymbol: function () {
                    return "targetCosts";
                }
            })(Data_Eq.eqNumber))()({
                reflectSymbol: function () {
                    return "selectedCostGroups";
                }
            })(Data_Eq.eqArray(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "name";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "modalState";
                }
            })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "modalOpenForCgId";
                }
            })(Data_Maybe.eqMaybe(Types_CostGroup.eqCostGroupId)))()({
                reflectSymbol: function () {
                    return "cgItemType";
                }
            })(Types_CostGroup.eqAddCostGroupItemType))))()({
                reflectSymbol: function () {
                    return "foldingState";
                }
            })(TacoTable_Types.eqFoldingState))()({
                reflectSymbol: function () {
                    return "estimateRemaining";
                }
            })(Data_Eq.eqNumber))()({
                reflectSymbol: function () {
                    return "estimateCosts";
                }
            })(Data_Eq.eqNumber))()({
                reflectSymbol: function () {
                    return "displayProps";
                }
            })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "withSocialExpenses";
                }
            })(Data_Eq.eqBoolean))()({
                reflectSymbol: function () {
                    return "displayMemo";
                }
            })(Data_Eq.eqBoolean))()({
                reflectSymbol: function () {
                    return "displayEstimateRemaining";
                }
            })(Data_Eq.eqBoolean))()({
                reflectSymbol: function () {
                    return "displayCompletionPercentage";
                }
            })(Data_Eq.eqBoolean))()({
                reflectSymbol: function () {
                    return "displayActualToEstimateRatio";
                }
            })(Data_Eq.eqBoolean))()({
                reflectSymbol: function () {
                    return "descriptionDisplay";
                }
            })(CostGroupDetails_Types.eqDescriptionDisplay))))()({
                reflectSymbol: function () {
                    return "diffOfTargetToEstimate";
                }
            })(Data_Eq.eqNumber))()({
                reflectSymbol: function () {
                    return "costGroupUnitAndCostClasses";
                }
            })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "socialExpenses";
                }
            })(Types_R200.eqR200CostClassResult))()({
                reflectSymbol: function () {
                    return "costGroupUnit";
                }
            })(Types_CostGroup.equalCostGroupUnit))()({
                reflectSymbol: function () {
                    return "costClassResults";
                }
            })(Data_Eq.eqArray(Types_R200.eqR200CostClassResult)))))()({
                reflectSymbol: function () {
                    return "completionPercentage";
                }
            })(Types_Unit.eqPercentageOfHundred))()({
                reflectSymbol: function () {
                    return "code";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "actualToEstimateRatio";
                }
            })(Types_Unit.eqPercentageOfHundred))()({
                reflectSymbol: function () {
                    return "actualCosts";
                }
            })(Data_Eq.eqNumber)))))()({
                reflectSymbol: function () {
                    return "actualToEstimateRatio";
                }
            })(Types_Unit.eqPercentageOfHundred))()({
                reflectSymbol: function () {
                    return "actualCosts";
                }
            })(Data_Eq.eqNumber)))(nestedCostControlTableInstance)(nestedSortingCriteria)(Data_Maybe.Nothing.value)({
                rows: Data_Functor.map(Data_Functor.functorArray)(getTwoLevelRow(fns)(twoLevelColumns)(props))(prepareTwoLevelRowData(v1.value0)(rowData)),
                columns: twoLevelColumns,
                showHeader: true,
                styleSet: costControlTableStyleSet
            }), summaryTable ]);
        };
        return React_Basic.fragment([ renderRowDataTable(true)(fns)(props)(rowData), summaryTable ]);
    };
});
module.exports = {
    mkCostControlTable: mkCostControlTable,
    ByCode: ByCode,
    ByName: ByName,
    ByTarget: ByTarget,
    ByEstimate: ByEstimate,
    ByTargetToEstimate: ByTargetToEstimate,
    ByEstimateRemaining: ByEstimateRemaining,
    ByActual: ByActual,
    ByActualToEstimateRatio: ByActualToEstimateRatio,
    ByCompletionPercentage: ByCompletionPercentage,
    ByMemo: ByMemo,
    eqSortProperty: eqSortProperty,
    ordSortProperty: ordSortProperty,
    getNameSortProperty: getNameSortProperty
};
