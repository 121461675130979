// Generated by purs version 0.14.5
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Show = require("../Data.Show/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var R200ResultMargin = function (x) {
    return x;
};
var R200IncomeInformationResult = function (x) {
    return x;
};
var R200CostClassResult = function (x) {
    return x;
};
var CostGroupColumnSpecRowItems = function (x) {
    return x;
};
var tofuJSONR200ResultMarginResult = TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "actual";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "estimate";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "name";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "target";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "actual";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "estimate";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "name";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "target";
    }
})(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()());
var tofuJSONR200IncomeInformationResult = TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "actual";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "estimate";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "name";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "target";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "actual";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "estimate";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "name";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "target";
    }
})(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()());
var tofuJSONR200CostClassResult = TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "actual";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "estimate";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "name";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "target";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "actual";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "estimate";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "name";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "target";
    }
})(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()());
var showR200ResultMargin = Data_Show.showRecord()(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "actual";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "estimate";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "name";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "target";
    }
})(Data_Show.showRecordFieldsNil)(Data_Show.showNumber))(Data_Show.showString))(Data_Show.showNumber))(Data_Show.showNumber));
var showR200IncomeInformationResult = Data_Show.showRecord()(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "actual";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "estimate";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "name";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "target";
    }
})(Data_Show.showRecordFieldsNil)(Data_Show.showNumber))(Data_Show.showString))(Data_Show.showNumber))(Data_Show.showNumber));
var newtypeR200ResultMargin = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeR200IncomeInformationResult = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeR200CostClassResult = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeCostGroupColumnSpecRowItems = {
    Coercible0: function () {
        return undefined;
    }
};
var eqR200ResultMargin = Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "target";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "name";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "estimate";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "actual";
    }
})(Data_Eq.eqNumber));
var eqR200IncomeInformationResult = Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "target";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "name";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "estimate";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "actual";
    }
})(Data_Eq.eqNumber));
var eqR200CostClassResult = Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "target";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "name";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "estimate";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(Types_Newtypes.eqCostClassCode))()({
    reflectSymbol: function () {
        return "actual";
    }
})(Data_Eq.eqNumber));
var eqCostGroupColumnSpecRowItems = {
    eq: function (x) {
        return function (y) {
            return Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "targetCosts";
                }
            })(Data_Eq.eqNumber))()({
                reflectSymbol: function () {
                    return "items";
                }
            })(Data_Maybe.eqMaybe(eqCostGroupColumnSpecRowItems)))()({
                reflectSymbol: function () {
                    return "estimatedCosts";
                }
            })(Data_Eq.eqNumber))()({
                reflectSymbol: function () {
                    return "estimateRemaining";
                }
            })(Data_Eq.eqNumber))()({
                reflectSymbol: function () {
                    return "displayName";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "diffOfTargetToEstimateRatio";
                }
            })(Data_Eq.eqNumber))()({
                reflectSymbol: function () {
                    return "diffOfTargetToEstimate";
                }
            })(Data_Eq.eqNumber))()({
                reflectSymbol: function () {
                    return "completionPercentage";
                }
            })(Types_Unit.eqPercentageOfZeroToOne))()({
                reflectSymbol: function () {
                    return "actualToEstimateRatio";
                }
            })(Data_Eq.eqNumber))()({
                reflectSymbol: function () {
                    return "actualCosts";
                }
            })(Data_Eq.eqNumber))))(x)(y);
        };
    }
};
module.exports = {
    R200CostClassResult: R200CostClassResult,
    R200ResultMargin: R200ResultMargin,
    R200IncomeInformationResult: R200IncomeInformationResult,
    CostGroupColumnSpecRowItems: CostGroupColumnSpecRowItems,
    newtypeR200IncomeInformationResult: newtypeR200IncomeInformationResult,
    eqR200IncomeInformationResult: eqR200IncomeInformationResult,
    showR200IncomeInformationResult: showR200IncomeInformationResult,
    tofuJSONR200IncomeInformationResult: tofuJSONR200IncomeInformationResult,
    newtypeR200ResultMargin: newtypeR200ResultMargin,
    eqR200ResultMargin: eqR200ResultMargin,
    showR200ResultMargin: showR200ResultMargin,
    tofuJSONR200ResultMarginResult: tofuJSONR200ResultMarginResult,
    newtypeCostGroupColumnSpecRowItems: newtypeCostGroupColumnSpecRowItems,
    eqCostGroupColumnSpecRowItems: eqCostGroupColumnSpecRowItems,
    newtypeR200CostClassResult: newtypeR200CostClassResult,
    tofuJSONR200CostClassResult: tofuJSONR200CostClassResult,
    eqR200CostClassResult: eqR200CostClassResult
};
