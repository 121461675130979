// Generated by purs version 0.14.5
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Kishimen = require("../Kishimen/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var FetchPurchaseInvoices = (function () {
    function FetchPurchaseInvoices() {

    };
    FetchPurchaseInvoices.value = new FetchPurchaseInvoices();
    return FetchPurchaseInvoices;
})();
var FetchSalesInvoices = (function () {
    function FetchSalesInvoices() {

    };
    FetchSalesInvoices.value = new FetchSalesInvoices();
    return FetchSalesInvoices;
})();
var FetchDataFromIntegration = function (x) {
    return x;
};
var genericFetchDataFromIntegrationProperty = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return FetchPurchaseInvoices.value;
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return FetchSalesInvoices.value;
        };
        throw new Error("Failed pattern match at Types.Integration (line 22, column 1 - line 22, column 102): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof FetchPurchaseInvoices) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof FetchSalesInvoices) {
            return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
        };
        throw new Error("Failed pattern match at Types.Integration (line 22, column 1 - line 22, column 102): " + [ x.constructor.name ]);
    }
};
var tofuJSONFetchDataFromIntegrationProperty = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FetchPurchaseInvoices";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FetchSalesInvoices";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FetchPurchaseInvoices";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FetchSalesInvoices";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))(genericFetchDataFromIntegrationProperty)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FetchPurchaseInvoices";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FetchSalesInvoices";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FetchPurchaseInvoices";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FetchSalesInvoices";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericFetchDataFromIntegrationProperty)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FetchPurchaseInvoices";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FetchSalesInvoices";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FetchPurchaseInvoices";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FetchSalesInvoices";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FetchPurchaseInvoices";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FetchSalesInvoices";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FetchPurchaseInvoices";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FetchSalesInvoices";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))
};
var tofuJSONFetchDataFromIntegration = TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "date";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "property";
    }
})(tofuJSONFetchDataFromIntegrationProperty)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "date";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "property";
    }
})(tofuJSONFetchDataFromIntegrationProperty)(TofuJSON.nilWriteForeignFields)()()())()()());
var eqFetchDataFromIntegrationProperty = {
    eq: function (x) {
        return function (y) {
            if (x instanceof FetchPurchaseInvoices && y instanceof FetchPurchaseInvoices) {
                return true;
            };
            if (x instanceof FetchSalesInvoices && y instanceof FetchSalesInvoices) {
                return true;
            };
            return false;
        };
    }
};
var eqFetchDataFromIntegration = {
    eq: function (x) {
        return function (y) {
            return x.date === y.date && Data_Eq.eq(eqFetchDataFromIntegrationProperty)(x.property)(y.property);
        };
    }
};
module.exports = {
    FetchPurchaseInvoices: FetchPurchaseInvoices,
    FetchSalesInvoices: FetchSalesInvoices,
    FetchDataFromIntegration: FetchDataFromIntegration,
    eqFetchDataFromIntegrationProperty: eqFetchDataFromIntegrationProperty,
    eqFetchDataFromIntegration: eqFetchDataFromIntegration,
    tofuJSONFetchDataFromIntegration: tofuJSONFetchDataFromIntegration,
    genericFetchDataFromIntegrationProperty: genericFetchDataFromIntegrationProperty,
    tofuJSONFetchDataFromIntegrationProperty: tofuJSONFetchDataFromIntegrationProperty
};
