// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect_Uncurried = require("../Effect.Uncurried/index.js");
var React_Basic = require("../React.Basic/index.js");
var NoUpdate = (function () {
    function NoUpdate() {

    };
    NoUpdate.value = new NoUpdate();
    return NoUpdate;
})();
var Update = (function () {
    function Update(value0) {
        this.value0 = value0;
    };
    Update.create = function (value0) {
        return new Update(value0);
    };
    return Update;
})();
var SideEffects = (function () {
    function SideEffects(value0) {
        this.value0 = value0;
    };
    SideEffects.create = function (value0) {
        return new SideEffects(value0);
    };
    return SideEffects;
})();
var UpdateAndSideEffects = (function () {
    function UpdateAndSideEffects(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    UpdateAndSideEffects.create = function (value0) {
        return function (value1) {
            return new UpdateAndSideEffects(value0, value1);
        };
    };
    return UpdateAndSideEffects;
})();
var makeStateless = function (component) {
    return function (render) {
        return $foreign.make()(component)({
            initialState: Data_Unit.unit,
            render: function (self) {
                return render(self.props);
            }
        });
    };
};
var buildStateUpdate = function (v) {
    if (v instanceof NoUpdate) {
        return {
            state: Data_Nullable["null"],
            effects: Data_Nullable["null"]
        };
    };
    if (v instanceof Update) {
        return {
            state: Data_Nullable.notNull(v.value0),
            effects: Data_Nullable["null"]
        };
    };
    if (v instanceof SideEffects) {
        return {
            state: Data_Nullable["null"],
            effects: Data_Nullable.notNull(v.value0)
        };
    };
    if (v instanceof UpdateAndSideEffects) {
        return {
            state: Data_Nullable.notNull(v.value0),
            effects: Data_Nullable.notNull(v.value1)
        };
    };
    throw new Error("Failed pattern match at React.Basic.Classic (line 297, column 20 - line 313, column 6): " + [ v.constructor.name ]);
};
var runUpdate = function (update) {
    return Effect_Uncurried.runEffectFn3($foreign.runUpdate_)(function (self, action) {
        return buildStateUpdate(update(self)(action));
    });
};
module.exports = {
    NoUpdate: NoUpdate,
    Update: Update,
    SideEffects: SideEffects,
    UpdateAndSideEffects: UpdateAndSideEffects,
    runUpdate: runUpdate,
    makeStateless: makeStateless,
    createComponent: $foreign.createComponent,
    readProps: $foreign.readProps,
    readState: $foreign.readState,
    make: $foreign.make,
    displayNameFromComponent: $foreign.displayNameFromComponent,
    displayNameFromSelf: $foreign.displayNameFromSelf,
    toReactComponent: $foreign.toReactComponent,
    consumer: React_Basic.consumer,
    contextConsumer: React_Basic.contextConsumer,
    contextProvider: React_Basic.contextProvider,
    createContext: React_Basic.createContext,
    element: React_Basic.element,
    elementKeyed: React_Basic.elementKeyed,
    empty: React_Basic.empty,
    fragment: React_Basic.fragment,
    keyed: React_Basic.keyed,
    provider: React_Basic.provider
};
