// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var elementDetailsSidepanelStyles = Box.boxStyle([ Box.Width.create(new TacoConstants.CustomSize("350px")), new Box.Margin(TacoConstants.M.value) ]);
var elementDetailsContainerTabsStyles = Box.boxStyle([ new Box.FlexGrow(1), Box.FlexRow.value, new Box.Selector(".__TacoTabs", [ new Box.FlexGrow(1) ]) ]);
module.exports = {
    elementDetailsSidepanelStyles: elementDetailsSidepanelStyles,
    elementDetailsContainerTabsStyles: elementDetailsContainerTabsStyles
};
