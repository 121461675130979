// Generated by purs version 0.14.5
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Timer = require("../Effect.Timer/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var TacoButton = require("../TacoButton/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var UseTranslation = require("../UseTranslation/index.js");
var testHook = function (setState) {
    return function __do() {
        TofuHooks.useEffect([ ReactHooksUtils.utf("foo"), ReactHooksUtils.utf(123) ])(function __do() {
            var timeout = Effect_Timer.setTimeout(1000)(setState(function (v) {
                return 42;
            }))();
            return Effect_Timer.clearTimeout(timeout);
        })();
        return TofuHooks.unsafeDiscardRenderEffects(React_Basic_Hooks.useEffect(Data_Eq.eqUnit)(Data_Unit.unit)(function __do() {
            setState(function (v) {
                return 1234;
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        }))();
    };
};
var testHookComponent = TofuHooks.mkHookComponent("TestHookComponent")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState(0)();
        testHook(v1.value1)();
        var t = UseTranslation.useTranslation("test")();
        return React_Basic_DOM_Generated.div()({
            children: [ React_Basic_DOM.text(Data_Show.show(Data_Show.showInt)(v1.value0)), TacoButton.component()()({
                onClick: Data_Maybe.Just.create(v1.value1(function (cur) {
                    return cur + 1 | 0;
                })),
                text: t("testTranslationKey")
            }) ]
        });
    };
});
var sections = [ {
    title: "Default",
    view: [ testHookComponent({}) ],
    code: ""
} ];
var demoView = {
    sections: sections,
    title: "Test",
    description: "Testtesttest"
};
module.exports = {
    demoView: demoView
};
