// Generated by purs version 0.14.5
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Kishimen = require("../Kishimen/index.js");
var ReadParam = require("../ReadParam/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var SalesInvoiceId = function (x) {
    return x;
};
var IncomeItemId = function (x) {
    return x;
};
var IncomeItemCode = function (x) {
    return x;
};
var IncomeGroupName = function (x) {
    return x;
};
var IncomeGroupCode = function (x) {
    return x;
};
var UpdateIncomeItemSumWithoutVAT = (function () {
    function UpdateIncomeItemSumWithoutVAT(value0) {
        this.value0 = value0;
    };
    UpdateIncomeItemSumWithoutVAT.create = function (value0) {
        return new UpdateIncomeItemSumWithoutVAT(value0);
    };
    return UpdateIncomeItemSumWithoutVAT;
})();
var UpdateIncomeItemName = (function () {
    function UpdateIncomeItemName(value0) {
        this.value0 = value0;
    };
    UpdateIncomeItemName.create = function (value0) {
        return new UpdateIncomeItemName(value0);
    };
    return UpdateIncomeItemName;
})();
var UpdateIncomeItemGroupCode = (function () {
    function UpdateIncomeItemGroupCode(value0) {
        this.value0 = value0;
    };
    UpdateIncomeItemGroupCode.create = function (value0) {
        return new UpdateIncomeItemGroupCode(value0);
    };
    return UpdateIncomeItemGroupCode;
})();
var UpdateIncomeItemCode = (function () {
    function UpdateIncomeItemCode(value0) {
        this.value0 = value0;
    };
    UpdateIncomeItemCode.create = function (value0) {
        return new UpdateIncomeItemCode(value0);
    };
    return UpdateIncomeItemCode;
})();
var UpdateIncomeItem = function (x) {
    return x;
};
var tofuJSONSalesInvoiceId = TofuJSON.readInt;
var tofuJSONIncomeItemId = TofuJSON.readInt;
var tofuJSONIncomeItemCode = TofuJSON.readString;
var tofuJSONIncomeGroupName = TofuJSON.readString;
var tofuJSONIncomeGroupCode = TofuJSON.readString;
var showSalesInvoiceId = Data_Show.showInt;
var showIncomeItemId = Data_Show.showInt;
var showIncomeItemCode = Data_Show.showString;
var showIncomeGroupName = Data_Show.showString;
var showIncomeGroupCode = Data_Show.showString;
var semigroupIncomeGroupName = Data_Semigroup.semigroupString;
var semigroupIncomeGroupCode = Data_Semigroup.semigroupString;
var readParamSalesInvoiceId = ReadParam.intReadParam;
var readParamIncomeItemId = ReadParam.intReadParam;
var readParamIncomeItemCode = ReadParam.stringReadParam;
var readParamIncomeGroupName = ReadParam.stringReadParam;
var readParamIncomeGroupCode = ReadParam.stringReadParam;
var ordSalesInvoiceId = Data_Ord.ordInt;
var ordIncomeItemId = Data_Ord.ordInt;
var ordIncomeItemCode = Data_Ord.ordString;
var ordIncomeGroupName = Data_Ord.ordString;
var ordIncomeGroupCode = Data_Ord.ordString;
var newtypeSalesInvoiceId = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeIncomeItemId = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeIncomeItemCode = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeIncomeGroupName = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeIncomeGroupCode = {
    Coercible0: function () {
        return undefined;
    }
};
var genericUpdateIncomeItemProperty = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new UpdateIncomeItemSumWithoutVAT(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new UpdateIncomeItemName(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return new UpdateIncomeItemGroupCode(x.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr)) {
            return new UpdateIncomeItemCode(x.value0.value0.value0);
        };
        throw new Error("Failed pattern match at Types.Income (line 131, column 1 - line 131, column 86): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof UpdateIncomeItemSumWithoutVAT) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof UpdateIncomeItemName) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof UpdateIncomeItemGroupCode) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
        };
        if (x instanceof UpdateIncomeItemCode) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0)));
        };
        throw new Error("Failed pattern match at Types.Income (line 131, column 1 - line 131, column 86): " + [ x.constructor.name ]);
    }
};
var tofuJSONUpdateIncomeItemProperty = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UpdateIncomeItemCode";
        }
    })(tofuJSONIncomeItemCode)()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UpdateIncomeItemGroupCode";
        }
    })(tofuJSONIncomeGroupCode)()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UpdateIncomeItemName";
        }
    })(TofuJSON.readString)()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UpdateIncomeItemSumWithoutVAT";
        }
    })(TofuJSON.readNumber)()(TofuJSON.readVariantNil)))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UpdateIncomeItemCode";
        }
    })(tofuJSONIncomeItemCode)()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UpdateIncomeItemGroupCode";
        }
    })(tofuJSONIncomeGroupCode)()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UpdateIncomeItemName";
        }
    })(TofuJSON.readString)()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UpdateIncomeItemSumWithoutVAT";
        }
    })(TofuJSON.readNumber)()(TofuJSON.nilWriteForeignVariant)))))(genericUpdateIncomeItemProperty)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UpdateIncomeItemSumWithoutVAT";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UpdateIncomeItemName";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UpdateIncomeItemGroupCode";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UpdateIncomeItemCode";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateIncomeItemGroupCode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateIncomeItemCode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateIncomeItemName";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateIncomeItemGroupCode";
        }
    }))({
        reflectSymbol: function () {
            return "UpdateIncomeItemCode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateIncomeItemSumWithoutVAT";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateIncomeItemName";
        }
    }))({
        reflectSymbol: function () {
            return "UpdateIncomeItemGroupCode";
        }
    }))({
        reflectSymbol: function () {
            return "UpdateIncomeItemCode";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericUpdateIncomeItemProperty)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UpdateIncomeItemSumWithoutVAT";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UpdateIncomeItemName";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UpdateIncomeItemGroupCode";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UpdateIncomeItemCode";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateIncomeItemGroupCode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateIncomeItemCode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateIncomeItemName";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateIncomeItemGroupCode";
        }
    }))({
        reflectSymbol: function () {
            return "UpdateIncomeItemCode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateIncomeItemSumWithoutVAT";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateIncomeItemName";
        }
    }))({
        reflectSymbol: function () {
            return "UpdateIncomeItemGroupCode";
        }
    }))({
        reflectSymbol: function () {
            return "UpdateIncomeItemCode";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UpdateIncomeItemCode";
        }
    })(tofuJSONIncomeItemCode)()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UpdateIncomeItemGroupCode";
        }
    })(tofuJSONIncomeGroupCode)()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UpdateIncomeItemName";
        }
    })(TofuJSON.readString)()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UpdateIncomeItemSumWithoutVAT";
        }
    })(TofuJSON.readNumber)()(TofuJSON.readVariantNil)))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UpdateIncomeItemCode";
        }
    })(tofuJSONIncomeItemCode)()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UpdateIncomeItemGroupCode";
        }
    })(tofuJSONIncomeGroupCode)()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UpdateIncomeItemName";
        }
    })(TofuJSON.readString)()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UpdateIncomeItemSumWithoutVAT";
        }
    })(TofuJSON.readNumber)()(TofuJSON.nilWriteForeignVariant)))))
};
var tofuJSONUpdateIncomeItem = TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "incomeItemId";
    }
})(tofuJSONIncomeItemId)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "property";
    }
})(tofuJSONUpdateIncomeItemProperty)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "incomeItemId";
    }
})(tofuJSONIncomeItemId)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "property";
    }
})(tofuJSONUpdateIncomeItemProperty)(TofuJSON.nilWriteForeignFields)()()())()()());
var eqSalesInvoiceId = Data_Eq.eqInt;
var eqIncomeItemId = Data_Eq.eqInt;
var eqIncomeItemCode = Data_Eq.eqString;
var eqIncomeGroupName = Data_Eq.eqString;
var eqIncomeGroupCode = Data_Eq.eqString;
var eqUpdateIncomeItemProperty = {
    eq: function (x) {
        return function (y) {
            if (x instanceof UpdateIncomeItemSumWithoutVAT && y instanceof UpdateIncomeItemSumWithoutVAT) {
                return x.value0 === y.value0;
            };
            if (x instanceof UpdateIncomeItemName && y instanceof UpdateIncomeItemName) {
                return x.value0 === y.value0;
            };
            if (x instanceof UpdateIncomeItemGroupCode && y instanceof UpdateIncomeItemGroupCode) {
                return Data_Eq.eq(eqIncomeGroupCode)(x.value0)(y.value0);
            };
            if (x instanceof UpdateIncomeItemCode && y instanceof UpdateIncomeItemCode) {
                return Data_Eq.eq(eqIncomeItemCode)(x.value0)(y.value0);
            };
            return false;
        };
    }
};
var eqUpdateIncomeItem = {
    eq: function (x) {
        return function (y) {
            return Data_Eq.eq(eqIncomeItemId)(x.incomeItemId)(y.incomeItemId) && Data_Eq.eq(eqUpdateIncomeItemProperty)(x.property)(y.property);
        };
    }
};
module.exports = {
    IncomeItemId: IncomeItemId,
    SalesInvoiceId: SalesInvoiceId,
    IncomeItemCode: IncomeItemCode,
    IncomeGroupCode: IncomeGroupCode,
    IncomeGroupName: IncomeGroupName,
    UpdateIncomeItemSumWithoutVAT: UpdateIncomeItemSumWithoutVAT,
    UpdateIncomeItemName: UpdateIncomeItemName,
    UpdateIncomeItemGroupCode: UpdateIncomeItemGroupCode,
    UpdateIncomeItemCode: UpdateIncomeItemCode,
    UpdateIncomeItem: UpdateIncomeItem,
    eqUpdateIncomeItemProperty: eqUpdateIncomeItemProperty,
    eqUpdateIncomeItem: eqUpdateIncomeItem,
    genericUpdateIncomeItemProperty: genericUpdateIncomeItemProperty,
    tofuJSONUpdateIncomeItemProperty: tofuJSONUpdateIncomeItemProperty,
    tofuJSONUpdateIncomeItem: tofuJSONUpdateIncomeItem,
    newtypeIncomeItemId: newtypeIncomeItemId,
    readParamIncomeItemId: readParamIncomeItemId,
    tofuJSONIncomeItemId: tofuJSONIncomeItemId,
    showIncomeItemId: showIncomeItemId,
    eqIncomeItemId: eqIncomeItemId,
    ordIncomeItemId: ordIncomeItemId,
    newtypeSalesInvoiceId: newtypeSalesInvoiceId,
    readParamSalesInvoiceId: readParamSalesInvoiceId,
    tofuJSONSalesInvoiceId: tofuJSONSalesInvoiceId,
    showSalesInvoiceId: showSalesInvoiceId,
    eqSalesInvoiceId: eqSalesInvoiceId,
    ordSalesInvoiceId: ordSalesInvoiceId,
    newtypeIncomeItemCode: newtypeIncomeItemCode,
    readParamIncomeItemCode: readParamIncomeItemCode,
    tofuJSONIncomeItemCode: tofuJSONIncomeItemCode,
    showIncomeItemCode: showIncomeItemCode,
    eqIncomeItemCode: eqIncomeItemCode,
    ordIncomeItemCode: ordIncomeItemCode,
    newtypeIncomeGroupCode: newtypeIncomeGroupCode,
    readParamIncomeGroupCode: readParamIncomeGroupCode,
    tofuJSONIncomeGroupCode: tofuJSONIncomeGroupCode,
    showIncomeGroupCode: showIncomeGroupCode,
    eqIncomeGroupCode: eqIncomeGroupCode,
    ordIncomeGroupCode: ordIncomeGroupCode,
    semigroupIncomeGroupCode: semigroupIncomeGroupCode,
    newtypeIncomeGroupName: newtypeIncomeGroupName,
    readParamIncomeGroupName: readParamIncomeGroupName,
    tofuJSONIncomeGroupName: tofuJSONIncomeGroupName,
    showIncomeGroupName: showIncomeGroupName,
    eqIncomeGroupName: eqIncomeGroupName,
    ordIncomeGroupName: ordIncomeGroupName,
    semigroupIncomeGroupName: semigroupIncomeGroupName
};
