import { ClientConfig } from "ts-bindings/ClientConfig";

let fetchedClientConfig: ClientConfig;

export const ensureClientConfig = async (): Promise<void> => {
  const res = await fetch("/api/config");
  const clientConfig = await res.json();
  fetchedClientConfig = clientConfig;
};

export const getClientConfigVar = (key: string): string => {
  if (fetchedClientConfig === undefined) {
    throw new Error(
      "Attempted reading from clientConfig before it has been set. Calling getClientConfigVar before ensureClientConfig has completed is a fatal error."
    );
  }

  const value = fetchedClientConfig[key];
  if (value === undefined) {
    throw new Error(
      `Could not find key ${key} in clientConfig. Make sure it is prefixed with "CLIENT_", and that it has been set in the backend's environment variables`
    );
  }

  return value;
};
export const getClientConfigVarOptional = (key: string): string => {
  if (fetchedClientConfig === undefined) {
    throw new Error(
      "Attempted reading from clientConfig before it has been set. Calling getClientConfigVar before ensureClientConfig has completed is a fatal error."
    );
  }

  return fetchedClientConfig[key];
};
