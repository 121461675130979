// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Effect = require("../Effect/index.js");
var LengthValidator = require("../LengthValidator/index.js");
var Locale = require("../Locale/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoModal = require("../TacoModal/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_CostGroup = require("../Types.CostGroup/index.js");
var addCostGroup = TofuHooks.mkHookComponent("AddCostGroup")(function (props) {
    return function __do() {
        var v = TofuHooks.useState("")();
        var v1 = TofuHooks.useState("")();
        var appDispatch = StateHooks.useDispatch();
        var placeholderName = Locale.lookup_("cost_group_default_name");
        var placeholderCode = Locale.lookup_("cost_group_default_code");
        var form = Box.box("form")([ Box.GridRow.value, new Box.Gap(TacoConstants.M.value), Box.MaxWidth.create(new TacoConstants.CustomSize("16rem")) ])([ TacoInput.stringInput()()(Data_Eq.eqString)({
            label: "Litteran tunnus",
            autoFocus: true,
            placeholder: placeholderCode,
            value: v.value0,
            onChange: v.value1,
            testId: "add-cost-group-code",
            validator: LengthValidator.lengthValidator(1)(10)
        }), TacoInput.stringInput()()(Data_Eq.eqString)({
            label: "Litteran nimi",
            placeholder: placeholderName,
            value: v1.value0,
            onChange: v1.value1,
            testId: "add-cost-group-name",
            validator: LengthValidator.lengthValidator(1)(50)
        }) ]);
        var createNewCostGroup = Control_Applicative.unless(Effect.applicativeEffect)(Data_String_Common["null"](v1.value0) || Data_String_Common["null"](v.value0))((function () {
            var value = {
                name: Data_String_Common.trim(v1.value0),
                code: Types_CostGroup.CostGroupCode(Data_String_Common.trim(v.value0))
            };
            var action = new Actions.AddCostGroupRequest({
                projectId: props.projectId,
                value: value
            });
            return function __do() {
                appDispatch(action)();
                return props.handleHideAddCostGroup();
            };
        })());
        var modalActions = TacoModal.modalActions({
            contents: [ TacoButton.component()()({
                onClick: new Data_Maybe.Just(props.handleHideAddCostGroup),
                text: "Peruuta",
                testId: "add-cost-group-cancel"
            }), TacoButton.component()()({
                onClick: Data_Maybe.Just.create(createNewCostGroup),
                buttonStyle: TacoButton_Types.Filled.value,
                text: "Luo uusi littera",
                testId: "add-cost-group-submit"
            }) ]
        });
        var modalBody = TacoModal.modalBody({
            contents: [ React_Basic_DOM_Generated.form()({
                className: "add-cost-group-form",
                children: [ form ],
                onSubmit: React_Basic_DOM_Events.capture_(createNewCostGroup),
                onKeyDown: React_Basic_Events.handler(React_Basic_DOM_Events.key)(function (key) {
                    return Control_Applicative.when(Effect.applicativeEffect)(Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(key)(new Data_Maybe.Just("Enter")))(createNewCostGroup);
                })
            }) ]
        });
        return React_Basic_DOM_Generated.div_([ TacoButton.component()()({
            onClick: new Data_Maybe.Just(props.handleHideAddCostGroup),
            buttonStyle: TacoButton_Types.Filled.value,
            text: "+ Luo uusi littera",
            testId: "add-cost-group-button"
        }), TacoModal.component()()({
            onClose: props.handleHideAddCostGroup,
            isActive: true,
            heading: "Luo uusi littera",
            contents: [ modalBody, modalActions ]
        }) ]);
    };
});
module.exports = {
    addCostGroup: addCostGroup
};
