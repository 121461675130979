// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Actions = require("../Actions/index.js");
var AppContainer_Styles = require("../AppContainer.Styles/index.js");
var AppLayout = require("../AppLayout/index.js");
var AppRoot = require("../AppRoot/index.js");
var Box = require("../Box/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var CostControlContainer = require("../CostControlContainer/index.js");
var CostEstimation_Types = require("../CostEstimation.Types/index.js");
var CostEstimationContainer = require("../CostEstimationContainer/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Timer = require("../Effect.Timer/index.js");
var EstimationProjectsContainer = require("../EstimationProjectsContainer/index.js");
var IncomeItemsContainer = require("../IncomeItemsContainer/index.js");
var IntegrationContainer = require("../IntegrationContainer/index.js");
var InvoicesContainer = require("../InvoicesContainer/index.js");
var Locale = require("../Locale/index.js");
var LogsContainer = require("../LogsContainer/index.js");
var NewEstimationResourcesList = require("../NewEstimationResourcesList/index.js");
var NotFound = require("../NotFound/index.js");
var NotificationsContainer = require("../NotificationsContainer/index.js");
var OauthCode = require("../OauthCode/index.js");
var OrganizationContainer = require("../OrganizationContainer/index.js");
var OrganizationManagement = require("../OrganizationManagement/index.js");
var PortfolioContainer = require("../PortfolioContainer/index.js");
var ProjectContainer = require("../ProjectContainer/index.js");
var ProjectListContainer = require("../ProjectListContainer/index.js");
var R200Container = require("../R200Container/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var ReleaseNotesContainer = require("../ReleaseNotesContainer/index.js");
var RemoteDataUtils = require("../RemoteDataUtils/index.js");
var RenameMainCostGroups = require("../RenameMainCostGroups/index.js");
var RequestUtils = require("../RequestUtils/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoAlert = require("../TacoAlert/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoLoader = require("../TacoLoader/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_CostEstimation = require("../Types.CostEstimation/index.js");
var Types_FrontEndRoutes = require("../Types.FrontEndRoutes/index.js");
var Types_Organization = require("../Types.Organization/index.js");
var Types_Project = require("../Types.Project/index.js");
var Types_Shared = require("../Types.Shared/index.js");
var UIDemoContainer = require("../UIDemoContainer/index.js");
var UseFetch = require("../UseFetch/index.js");
var UserManagement = require("../UserManagement/index.js");
var WasmWorker = require("../WasmWorker/index.js");
var WasmWorker_WorkerRequest = require("../WasmWorker.WorkerRequest/index.js");
var outageMessage = function (v) {
    if (v instanceof Data_Maybe.Nothing) {
        return React_Basic.empty;
    };
    if (v instanceof Data_Maybe.Just) {
        var message = Box.box("outageMessage")([  ])([ React_Basic_DOM.text(v.value0) ]);
        return Box.box("outageBanner")([ Box.AlignCenter.value, new Box.BackgroundColor(TacoConstants.DangerRedDark.value), Box.JustifyCenter.value, new Box.Width(new TacoConstants.CustomSize("100%")) ])([ message ]);
    };
    throw new Error("Failed pattern match at AppContainer (line 190, column 1 - line 190, column 40): " + [ v.constructor.name ]);
};
var noAppAccessRights = {
    costControl: false,
    estimation: false,
    organization: false,
    integration: false,
    superAdmin: false,
    admin: false
};
var getRouteContents = function (monthlyReport) {
    return function (route_) {
        return function (appAccessRights) {
            if (route_ instanceof Data_Maybe.Nothing) {
                return React_Basic.empty;
            };
            if (route_ instanceof Data_Maybe.Just) {
                if (route_.value0 instanceof Types_FrontEndRoutes.RootRoute) {
                    return AppRoot.component({
                        appAccessRights: appAccessRights
                    });
                };
                if (route_.value0 instanceof Types_FrontEndRoutes.TsRoute) {
                    return $foreign.tsApp({});
                };
                if (route_.value0 instanceof Types_FrontEndRoutes.AddCostControlProjectFERoute) {
                    var container = ProjectContainer.component({
                        mode: ProjectContainer.AddCostControlProjectPCMode.value
                    });
                    return AppLayout.appLayout({
                        applicationMode: Types_Project.ReportingMode.value,
                        appAccessRights: appAccessRights,
                        projectId: Data_Maybe.Nothing.value,
                        route: route_.value0,
                        children: [ container ]
                    });
                };
                if (route_.value0 instanceof Types_FrontEndRoutes.AddEstimationProjectFERoute) {
                    var container = ProjectContainer.component({
                        mode: ProjectContainer.AddEstimationProjectPCMode.value
                    });
                    return AppLayout.appLayout({
                        applicationMode: Types_Project.EstimationMode.value,
                        appAccessRights: appAccessRights,
                        projectId: Data_Maybe.Nothing.value,
                        route: route_.value0,
                        children: [ container ]
                    });
                };
                if (route_.value0 instanceof Types_FrontEndRoutes.ImportEstimationProjectFERoute) {
                    var container = ProjectContainer.component({
                        mode: ProjectContainer.ImportEstimationProjectPCMode.value
                    });
                    return AppLayout.appLayout({
                        applicationMode: Types_Project.EstimationMode.value,
                        appAccessRights: appAccessRights,
                        projectId: Data_Maybe.Nothing.value,
                        route: route_.value0,
                        children: [ container ]
                    });
                };
                if (route_.value0 instanceof Types_FrontEndRoutes.CostControlFERoute) {
                    var container = CostControlContainer.component({
                        projectId: route_.value0.value0.projectId
                    });
                    return AppLayout.appLayout({
                        applicationMode: Types_Project.ReportingMode.value,
                        appAccessRights: appAccessRights,
                        projectId: new Data_Maybe.Just(route_.value0.value0.projectId),
                        route: route_.value0,
                        children: [ container ]
                    });
                };
                if (route_.value0 instanceof Types_FrontEndRoutes.MonthlyReportFERoute) {
                    var container = React_Basic.element(monthlyReport)({
                        projectId: route_.value0.value0.projectId
                    });
                    return AppLayout.appLayout({
                        applicationMode: Types_Project.ReportingMode.value,
                        appAccessRights: appAccessRights,
                        projectId: new Data_Maybe.Just(route_.value0.value0.projectId),
                        route: route_.value0,
                        children: [ container ]
                    });
                };
                if (route_.value0 instanceof Types_FrontEndRoutes.CostControlPrintingFERoute) {
                    var container = React_Basic.element($foreign.costControlPrinting)({
                        projectId: route_.value0.value0.projectId
                    });
                    return AppLayout.appLayout({
                        applicationMode: Types_Project.ReportingMode.value,
                        appAccessRights: appAccessRights,
                        projectId: new Data_Maybe.Just(route_.value0.value0.projectId),
                        route: route_.value0,
                        children: [ container ]
                    });
                };
                if (route_.value0 instanceof Types_FrontEndRoutes.PortfolioReportFERoute) {
                    var container = PortfolioContainer.component({});
                    return AppLayout.appLayout({
                        applicationMode: Types_Project.ReportingMode.value,
                        appAccessRights: appAccessRights,
                        projectId: Data_Maybe.Nothing.value,
                        route: route_.value0,
                        children: [ container ]
                    });
                };
                if (route_.value0 instanceof Types_FrontEndRoutes.ProjectDetailsFERoute) {
                    var container = ProjectContainer.component({
                        mode: new ProjectContainer.EditCostControlProjectPCMode(route_.value0.value0.projectId)
                    });
                    return AppLayout.appLayout({
                        applicationMode: Types_Project.ReportingMode.value,
                        appAccessRights: appAccessRights,
                        projectId: new Data_Maybe.Just(route_.value0.value0.projectId),
                        route: route_.value0,
                        children: [ container ]
                    });
                };
                if (route_.value0 instanceof Types_FrontEndRoutes.ReportingProjectListRoute) {
                    var container = ProjectListContainer.component({});
                    return AppLayout.appLayout({
                        applicationMode: Types_Project.ReportingMode.value,
                        appAccessRights: appAccessRights,
                        projectId: Data_Maybe.Nothing.value,
                        route: route_.value0,
                        children: [ container ]
                    });
                };
                if (route_.value0 instanceof Types_FrontEndRoutes.EstimationProjectsRoute) {
                    var container = EstimationProjectsContainer.component({});
                    return AppLayout.appLayout({
                        applicationMode: Types_Project.EstimationMode.value,
                        appAccessRights: appAccessRights,
                        projectId: Data_Maybe.Nothing.value,
                        route: route_.value0,
                        children: [ container ]
                    });
                };
                if (route_.value0 instanceof Types_FrontEndRoutes.RenameMainCostGroupsFERoute) {
                    var container = RenameMainCostGroups.component({
                        projectId: route_.value0.value0.projectId
                    });
                    return AppLayout.appLayout({
                        applicationMode: Types_Project.ReportingMode.value,
                        appAccessRights: appAccessRights,
                        projectId: new Data_Maybe.Just(route_.value0.value0.projectId),
                        route: route_.value0,
                        children: [ container ]
                    });
                };
                if (route_.value0 instanceof Types_FrontEndRoutes.AdminFERoute) {
                    var container = OrganizationManagement.component({});
                    return AppLayout.appLayout({
                        applicationMode: Types_Project.AdminMode.value,
                        appAccessRights: appAccessRights,
                        projectId: Data_Maybe.Nothing.value,
                        route: route_.value0,
                        children: [ container ]
                    });
                };
                if (route_.value0 instanceof Types_FrontEndRoutes.SuperAdminFERoute) {
                    var container = UserManagement.component({});
                    return AppLayout.appLayout({
                        applicationMode: Types_Project.SuperAdminMode.value,
                        appAccessRights: appAccessRights,
                        projectId: Data_Maybe.Nothing.value,
                        route: route_.value0,
                        children: [ container ]
                    });
                };
                if (route_.value0 instanceof Types_FrontEndRoutes.IntegrationFERoute) {
                    var container = IntegrationContainer.component({});
                    return AppLayout.appLayout({
                        applicationMode: Types_Project.IntegrationMode.value,
                        appAccessRights: appAccessRights,
                        projectId: Data_Maybe.Nothing.value,
                        route: route_.value0,
                        children: [ container ]
                    });
                };
                if (route_.value0 instanceof Types_FrontEndRoutes.ReleaseNotesFERoute) {
                    var container = ReleaseNotesContainer.releaseNotesContainer({});
                    return AppLayout.appLayout({
                        applicationMode: Types_Project.EtcMode.value,
                        appAccessRights: appAccessRights,
                        projectId: Data_Maybe.Nothing.value,
                        route: route_.value0,
                        children: [ container ]
                    });
                };
                if (route_.value0 instanceof Types_FrontEndRoutes.EstimationFERoute) {
                    var container = CostEstimationContainer.component({
                        projectId: route_.value0.value0.projectId,
                        initialSelectedComponent: route_.value0.value0.initialSelectedComponent,
                        initialSelectedElement: Data_Maybe.Nothing.value,
                        mFileId: Data_Maybe.Nothing.value,
                        mDrawingId: Data_Maybe.Nothing.value,
                        mMeasurementGroupId: Data_Maybe.Nothing.value,
                        viewMode: Types_CostEstimation.EditMode.value,
                        estimationMode: CostEstimation_Types.ComponentsMode.value,
                        route: route_.value0
                    });
                    return AppLayout.appLayout({
                        applicationMode: Types_Project.EstimationMode.value,
                        appAccessRights: appAccessRights,
                        projectId: new Data_Maybe.Just(route_.value0.value0.projectId),
                        route: route_.value0,
                        children: [ container ]
                    });
                };
                if (route_.value0 instanceof Types_FrontEndRoutes.EstimationReferenceRoute) {
                    var container = CostEstimationContainer.component({
                        projectId: route_.value0.value0.projectId,
                        initialSelectedComponent: Data_Maybe.Nothing.value,
                        initialSelectedElement: Data_Maybe.Nothing.value,
                        mFileId: Data_Maybe.Nothing.value,
                        mDrawingId: Data_Maybe.Nothing.value,
                        mMeasurementGroupId: Data_Maybe.Nothing.value,
                        viewMode: Types_CostEstimation.ReferenceMode.value,
                        estimationMode: CostEstimation_Types.ComponentsMode.value,
                        route: route_.value0
                    });
                    return AppLayout.appLayout({
                        applicationMode: Types_Project.EstimationMode.value,
                        appAccessRights: appAccessRights,
                        projectId: new Data_Maybe.Just(route_.value0.value0.projectId),
                        route: route_.value0,
                        children: [ container ]
                    });
                };
                if (route_.value0 instanceof Types_FrontEndRoutes.ReferenceEstimationElementsRoute) {
                    var container = CostEstimationContainer.component({
                        projectId: route_.value0.value0.projectId,
                        initialSelectedComponent: Data_Maybe.Nothing.value,
                        initialSelectedElement: Data_Maybe.Nothing.value,
                        mFileId: Data_Maybe.Nothing.value,
                        mDrawingId: Data_Maybe.Nothing.value,
                        mMeasurementGroupId: Data_Maybe.Nothing.value,
                        viewMode: Types_CostEstimation.ReferenceMode.value,
                        estimationMode: CostEstimation_Types.BuildingElementsMode.value,
                        route: route_.value0
                    });
                    return AppLayout.appLayout({
                        applicationMode: Types_Project.EstimationMode.value,
                        appAccessRights: appAccessRights,
                        projectId: new Data_Maybe.Just(route_.value0.value0.projectId),
                        route: route_.value0,
                        children: [ container ]
                    });
                };
                if (route_.value0 instanceof Types_FrontEndRoutes.EstimationResourcesRoute) {
                    var estimation = CostEstimationContainer.component({
                        projectId: route_.value0.value0.projectId,
                        initialSelectedComponent: Data_Maybe.Nothing.value,
                        initialSelectedElement: Data_Maybe.Nothing.value,
                        mFileId: Data_Maybe.Nothing.value,
                        mDrawingId: Data_Maybe.Nothing.value,
                        mMeasurementGroupId: Data_Maybe.Nothing.value,
                        viewMode: Types_CostEstimation.EditMode.value,
                        estimationMode: CostEstimation_Types.ResourcesMode.value,
                        route: route_.value0
                    });
                    return AppLayout.appLayout({
                        applicationMode: Types_Project.EstimationMode.value,
                        appAccessRights: appAccessRights,
                        projectId: new Data_Maybe.Just(route_.value0.value0.projectId),
                        route: route_.value0,
                        children: [ estimation ]
                    });
                };
                if (route_.value0 instanceof Types_FrontEndRoutes.NewEstimationResourcesRoute) {
                    var container = NewEstimationResourcesList.component({});
                    return AppLayout.appLayout({
                        applicationMode: Types_Project.EstimationMode.value,
                        appAccessRights: appAccessRights,
                        projectId: new Data_Maybe.Just(route_.value0.value0.projectId),
                        route: route_.value0,
                        children: [ container ]
                    });
                };
                if (route_.value0 instanceof Types_FrontEndRoutes.EstimationResourcePriceListRoute) {
                    var estimation = CostEstimationContainer.component({
                        projectId: route_.value0.value0.projectId,
                        initialSelectedComponent: Data_Maybe.Nothing.value,
                        initialSelectedElement: Data_Maybe.Nothing.value,
                        mFileId: Data_Maybe.Nothing.value,
                        mDrawingId: Data_Maybe.Nothing.value,
                        mMeasurementGroupId: Data_Maybe.Nothing.value,
                        viewMode: Types_CostEstimation.ReferenceMode.value,
                        estimationMode: CostEstimation_Types.ResourcePriceListMode.value,
                        route: route_.value0
                    });
                    return AppLayout.appLayout({
                        applicationMode: Types_Project.EstimationMode.value,
                        appAccessRights: appAccessRights,
                        projectId: new Data_Maybe.Just(route_.value0.value0.projectId),
                        route: route_.value0,
                        children: [ estimation ]
                    });
                };
                if (route_.value0 instanceof Types_FrontEndRoutes.OfferPageRoute) {
                    var estimation = CostEstimationContainer.component({
                        projectId: route_.value0.value0.projectId,
                        initialSelectedComponent: Data_Maybe.Nothing.value,
                        initialSelectedElement: Data_Maybe.Nothing.value,
                        mFileId: Data_Maybe.Nothing.value,
                        mDrawingId: Data_Maybe.Nothing.value,
                        mMeasurementGroupId: Data_Maybe.Nothing.value,
                        viewMode: Types_CostEstimation.EditMode.value,
                        estimationMode: CostEstimation_Types.OfferPageMode.value,
                        route: route_.value0
                    });
                    return AppLayout.appLayout({
                        applicationMode: Types_Project.EstimationMode.value,
                        appAccessRights: appAccessRights,
                        projectId: new Data_Maybe.Just(route_.value0.value0.projectId),
                        route: route_.value0,
                        children: [ estimation ]
                    });
                };
                if (route_.value0 instanceof Types_FrontEndRoutes.EstimationBuildingElementsRoute) {
                    var container = CostEstimationContainer.component({
                        projectId: route_.value0.value0.projectId,
                        initialSelectedComponent: Data_Maybe.Nothing.value,
                        initialSelectedElement: route_.value0.value0.initialSelectedElement,
                        mFileId: Data_Maybe.Nothing.value,
                        mDrawingId: Data_Maybe.Nothing.value,
                        mMeasurementGroupId: Data_Maybe.Nothing.value,
                        viewMode: Types_CostEstimation.EditMode.value,
                        estimationMode: CostEstimation_Types.BuildingElementsMode.value,
                        route: route_.value0
                    });
                    return AppLayout.appLayout({
                        applicationMode: Types_Project.EstimationMode.value,
                        appAccessRights: appAccessRights,
                        projectId: new Data_Maybe.Just(route_.value0.value0.projectId),
                        route: route_.value0,
                        children: [ container ]
                    });
                };
                if (route_.value0 instanceof Types_FrontEndRoutes.IncomeFERoute) {
                    var container = IncomeItemsContainer.component({
                        projectId: route_.value0.value0.projectId
                    });
                    return AppLayout.appLayout({
                        applicationMode: Types_Project.ReportingMode.value,
                        appAccessRights: appAccessRights,
                        projectId: new Data_Maybe.Just(route_.value0.value0.projectId),
                        route: route_.value0,
                        children: [ container ]
                    });
                };
                if (route_.value0 instanceof Types_FrontEndRoutes.InvoicesFERoute) {
                    var container = InvoicesContainer.component({
                        projectId: route_.value0.value0.projectId
                    });
                    return AppLayout.appLayout({
                        applicationMode: Types_Project.ReportingMode.value,
                        appAccessRights: appAccessRights,
                        projectId: new Data_Maybe.Just(route_.value0.value0.projectId),
                        route: route_.value0,
                        children: [ container ]
                    });
                };
                if (route_.value0 instanceof Types_FrontEndRoutes.LogsFERoute) {
                    var container = LogsContainer.component({});
                    return AppLayout.appLayout({
                        applicationMode: Types_Project.EtcMode.value,
                        appAccessRights: appAccessRights,
                        projectId: Data_Maybe.Nothing.value,
                        route: route_.value0,
                        children: [ container ]
                    });
                };
                if (route_.value0 instanceof Types_FrontEndRoutes.NotificationsFERoute) {
                    var container = NotificationsContainer.component({});
                    return AppLayout.appLayout({
                        applicationMode: Types_Project.EtcMode.value,
                        appAccessRights: appAccessRights,
                        projectId: Data_Maybe.Nothing.value,
                        route: route_.value0,
                        children: [ container ]
                    });
                };
                if (route_.value0 instanceof Types_FrontEndRoutes.OauthCode) {
                    return OauthCode.component({});
                };
                if (route_.value0 instanceof Types_FrontEndRoutes.NotFoundFERoute) {
                    var container = NotFound.component({
                        message: Locale.lookup_("common_not_found_description")
                    });
                    return AppLayout.appLayout({
                        applicationMode: Types_Project.ReportingMode.value,
                        appAccessRights: appAccessRights,
                        projectId: Data_Maybe.Nothing.value,
                        route: route_.value0,
                        children: [ container ]
                    });
                };
                if (route_.value0 instanceof Types_FrontEndRoutes.OrganizationFERoute) {
                    var container = OrganizationContainer.component({});
                    return AppLayout.appLayout({
                        applicationMode: Types_Project.OrganizationMode.value,
                        appAccessRights: appAccessRights,
                        projectId: Data_Maybe.Nothing.value,
                        route: route_.value0,
                        children: [ container ]
                    });
                };
                if (route_.value0 instanceof Types_FrontEndRoutes.ReportsFERoute) {
                    var container = R200Container.component({
                        projectId: route_.value0.value0.projectId
                    });
                    return AppLayout.appLayout({
                        applicationMode: Types_Project.ReportingMode.value,
                        appAccessRights: appAccessRights,
                        projectId: new Data_Maybe.Just(route_.value0.value0.projectId),
                        route: route_.value0,
                        children: [ container ]
                    });
                };
                if (route_.value0 instanceof Types_FrontEndRoutes.UIDemoFERoute) {
                    return UIDemoContainer.component({
                        componentId: route_.value0.value0.componentId
                    });
                };
                if (route_.value0 instanceof Types_FrontEndRoutes.DixonFERoute) {
                    var container = CostEstimationContainer.component({
                        projectId: route_.value0.value0.projectId,
                        initialSelectedComponent: Data_Maybe.Nothing.value,
                        initialSelectedElement: Data_Maybe.Nothing.value,
                        mFileId: route_.value0.value0.mFileId,
                        mDrawingId: route_.value0.value0.mDrawingId,
                        mMeasurementGroupId: route_.value0.value0.mMeasurementGroupId,
                        viewMode: Types_CostEstimation.EditMode.value,
                        estimationMode: CostEstimation_Types.DixonMode.value,
                        route: route_.value0
                    });
                    return AppLayout.appLayout({
                        applicationMode: Types_Project.EstimationMode.value,
                        appAccessRights: appAccessRights,
                        projectId: Data_Maybe.Nothing.value,
                        route: route_.value0,
                        children: [ container ]
                    });
                };
                if (route_.value0 instanceof Types_FrontEndRoutes.PrintingFERoute) {
                    var container = CostEstimationContainer.component({
                        projectId: route_.value0.value0.projectId,
                        initialSelectedComponent: Data_Maybe.Nothing.value,
                        initialSelectedElement: Data_Maybe.Nothing.value,
                        mFileId: Data_Maybe.Nothing.value,
                        mDrawingId: Data_Maybe.Nothing.value,
                        mMeasurementGroupId: Data_Maybe.Nothing.value,
                        viewMode: Types_CostEstimation.EditMode.value,
                        estimationMode: CostEstimation_Types.PrintingMode.value,
                        route: route_.value0
                    });
                    return AppLayout.appLayout({
                        applicationMode: Types_Project.EstimationMode.value,
                        appAccessRights: appAccessRights,
                        projectId: new Data_Maybe.Just(route_.value0.value0.projectId),
                        route: route_.value0,
                        children: [ container ]
                    });
                };
                if (route_.value0 instanceof Types_FrontEndRoutes.EstimationProjectDetailsFERoute) {
                    var container = CostEstimationContainer.component({
                        projectId: route_.value0.value0.projectId,
                        initialSelectedComponent: Data_Maybe.Nothing.value,
                        initialSelectedElement: Data_Maybe.Nothing.value,
                        mFileId: Data_Maybe.Nothing.value,
                        mDrawingId: Data_Maybe.Nothing.value,
                        mMeasurementGroupId: Data_Maybe.Nothing.value,
                        viewMode: Types_CostEstimation.EditMode.value,
                        estimationMode: CostEstimation_Types.EditEstimationProjectMode.value,
                        route: route_.value0
                    });
                    return AppLayout.appLayout({
                        applicationMode: Types_Project.EstimationMode.value,
                        appAccessRights: appAccessRights,
                        projectId: new Data_Maybe.Just(route_.value0.value0.projectId),
                        route: route_.value0,
                        children: [ container ]
                    });
                };
                if (route_.value0 instanceof Types_FrontEndRoutes.EstimationReportsRoute) {
                    var estimation = CostEstimationContainer.component({
                        projectId: route_.value0.value0.projectId,
                        initialSelectedComponent: Data_Maybe.Nothing.value,
                        initialSelectedElement: Data_Maybe.Nothing.value,
                        mFileId: Data_Maybe.Nothing.value,
                        mDrawingId: Data_Maybe.Nothing.value,
                        mMeasurementGroupId: Data_Maybe.Nothing.value,
                        viewMode: Types_CostEstimation.ReferenceMode.value,
                        estimationMode: CostEstimation_Types.EstimationReportsMode.value,
                        route: route_.value0
                    });
                    return AppLayout.appLayout({
                        applicationMode: Types_Project.EstimationMode.value,
                        appAccessRights: appAccessRights,
                        projectId: new Data_Maybe.Just(route_.value0.value0.projectId),
                        route: route_.value0,
                        children: [ estimation ]
                    });
                };
                throw new Error("Failed pattern match at AppContainer (line 210, column 17 - line 695, column 10): " + [ route_.value0.constructor.name ]);
            };
            throw new Error("Failed pattern match at AppContainer (line 208, column 57 - line 695, column 10): " + [ route_.constructor.name ]);
        };
    };
};
var component_ = TofuHooks.mkHookComponent("AppContainer")(function (props) {
    return function __do() {
        var route = StateHooks.useSelector(function (v) {
            return v.route;
        })();
        var alerts = StateHooks.useSelector(function (v) {
            return v.alerts;
        })();
        var selectedProject = StateHooks.useSelector(function (v) {
            return v.selectedProject;
        })();
        var v = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v) {
            return v.serverStatus;
        })(Actions.GetServerStatusAction.value)();
        TofuHooks.useEffect([ ReactHooksUtils.utf(route), ReactHooksUtils.utf(selectedProject) ])((function () {
            var mSelected = RemoteDataUtils.getFetched(selectedProject);
            var mAppName = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Types_FrontEndRoutes.getRouteAppName)(route);
            return function __do() {
                $foreign.setDocumentTitle((function () {
                    if (mSelected instanceof Data_Maybe.Just) {
                        return Data_Newtype.unwrap()(mSelected.value0.project.code) + (" " + (mSelected.value0.project.name + (" " + (mSelected.value0.project.version + " | Tocoman"))));
                    };
                    if (mAppName instanceof Data_Maybe.Just) {
                        return mAppName.value0 + " | Tocoman";
                    };
                    if (mAppName instanceof Data_Maybe.Nothing) {
                        return "Tocoman";
                    };
                    throw new Error("Failed pattern match at AppContainer (line 97, column 22 - line 100, column 30): " + [ mSelected.constructor.name, mAppName.constructor.name ]);
                })())();
                return Data_Monoid.mempty(Effect.monoidEffect(Effect.monoidEffect(Data_Monoid.monoidUnit)))();
            };
        })())();
        var currentOrganizationRemoteData = StateHooks.useSelector(function (v1) {
            return v1.currentOrganization;
        })();
        var v1 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v1) {
            return v1.currentOrganization;
        })(Actions.GetCurrentOrganizationRequest.value)();
        var currentOrganization = Data_Functor.map(Data_Maybe.functorMaybe)(function (v2) {
            return v2.organization;
        })(v1.value0);
        var v2 = Data_Functor.map(Effect.functorEffect)(UseFetch.defaultFetchValue(noAppAccessRights))(UseFetch.useConditionalFetchValue(Data_Maybe.eqMaybe(Types_Organization.eqOrganization))(currentOrganization)(function (v2) {
            return v2.appAccessRights;
        })(function (key) {
            return Data_Functor.map(Data_Maybe.functorMaybe)(Actions.GetUserAppAccessRightsRequest.create)(key);
        }))();
        var appDispatch = StateHooks.useDispatch();
        var serverVersionString = Data_Maybe.fromMaybe("")(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Functor.map(Data_Functor.functorFn)(Data_Show.show(Types_Shared.showAppVersion))(function (v3) {
            return v3.version;
        }))(v.value0));
        var actionDispatchAff = (function () {
            var $88 = Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
            return function ($89) {
                return $88(appDispatch($89));
            };
        })();
        TofuHooks.useEffect([ serverVersionString ])(function __do() {
            (function () {
                if (v.value0 instanceof Data_Maybe.Just) {
                    return RequestUtils.checkVersions(v.value0.value0.version)(actionDispatchAff)();
                };
                if (v.value0 instanceof Data_Maybe.Nothing) {
                    return Data_Unit.unit;
                };
                throw new Error("Failed pattern match at AppContainer (line 118, column 5 - line 120, column 27): " + [ v.value0.constructor.name ]);
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        })();
        TofuHooks.useEffect([  ])(function __do() {
            appDispatch(Actions.GetServerStatusAction.value)();
            var intervalId = Effect_Timer.setInterval(300000)(appDispatch(Actions.GetServerStatusAction.value))();
            return Effect_Timer.clearInterval(intervalId);
        })();
        TofuHooks.useEffect([  ])((function () {
            var sendTokenToWorker = function (firstTime) {
                return Control_Bind.bind(Effect_Aff.bindAff)(RequestUtils.getAuthorization)(function (token) {
                    return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(WasmWorker.setWasmWorkerState(new WasmWorker_WorkerRequest.AccessTokenState(token))((function () {
                        if (firstTime) {
                            return Data_Maybe.Nothing.value;
                        };
                        return new Data_Maybe.Just(WasmWorker_WorkerRequest.SkipRecompute.value);
                    })()));
                });
            };
            return function __do() {
                Effect_Aff.launchAff_(sendTokenToWorker(true))();
                var intervalId = Effect_Timer.setInterval(1000)(Effect_Aff.launchAff_(sendTokenToWorker(false)))();
                return Effect_Timer.clearInterval(intervalId);
            };
        })())();
        var userIdNotInDatabaseError = (function () {
            if (currentOrganizationRemoteData instanceof RemoteDataUtils["Error"]) {
                return currentOrganizationRemoteData.value0.userIdNotInDatabase;
            };
            return false;
        })();
        var pageLoader = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "loader",
            css: Box.boxStyle([ Box.FlexColumn.value, Box.AlignCenter.value, Box.JustifyCenter.value, new Box.MinHeight(new TacoConstants.CustomSize("100vh")) ]),
            children: [ TacoLoader.component()()({
                size: "10rem"
            }) ]
        });
        var mkAlert = function (alert) {
            return TacoAlert.component({
                alert: alert,
                fragment: React_Basic.fragment(Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_Emotion.element(React_Basic_DOM_Generated["span'"]())({
                    className: "error-dismiss-click",
                    css: AppContainer_Styles.errorDismissClickStyles,
                    children: [ React_Basic_DOM.text("Sulje") ],
                    onClick: React_Basic_Events.handler_(appDispatch(new Actions.DismissAlert({
                        mId: new Data_Maybe.Just(alert.id)
                    })))
                })))
            });
        };
        var invalidUserWarning = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "invalid-user-warning",
            css: Box.boxStyle([ Box.FlexColumn.value, Box.AlignCenter.value, Box.JustifyCenter.value, new Box.MinHeight(new TacoConstants.CustomSize("100vh")) ]),
            children: [ TacoText.component()()({
                text: "K\xe4ytt\xe4j\xe4tunnuksesi ei ole kelvollinen. Ota yhteytt\xe4 j\xe4rjestelm\xe4n yll\xe4pit\xe4j\xe4\xe4n.",
                variant: new TacoText_Types.Heading(1)
            }) ]
        });
        var alertsJsx = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "errors-container",
            css: AppContainer_Styles.errorsContainerStyles,
            children: Data_Functor.map(Data_Functor.functorArray)(mkAlert)(alerts)
        });
        var children = (function () {
            if (userIdNotInDatabaseError) {
                return [ invalidUserWarning ];
            };
            if (v2.value1) {
                return [ pageLoader ];
            };
            return [ outageMessage(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v3) {
                return v3.outageMessage;
            })(v.value0)), getRouteContents(props.monthlyReport)(route)(v2.value0), alertsJsx ];
        })();
        return Box.box("app-wrapper")([  ])(children);
    };
});
var component = $foreign.tsAppWrapper(component_);
module.exports = {
    component: component
};
