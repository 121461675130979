import { WorkerRequest } from "../../../ts-bindings/WorkerRequest";
import { mkWorkerAction } from "./state";
import { NavigationAction } from "../../../ts-bindings/NavigationAction";
import { ChangeOrganizationParams } from "../../../ts-bindings/ChangeOrganizationParams";

export const mkNavigationUpdateAction = (
  action: NavigationAction
): WorkerRequest =>
  mkWorkerAction({
    type: "NavigationUpdate",
    value: {
      action,
    },
  });

export const mkChangeOrganizationAction = (
  params: ChangeOrganizationParams
): WorkerRequest =>
  mkNavigationUpdateAction({
    type: "ChangeOrganization",
    value: params,
  });
