// Generated by purs version 0.14.5
"use strict";
var AppState = require("../AppState/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Effect = require("../Effect/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var React_Basic = require("../React.Basic/index.js");
var Types_ReasonableCoerce = require("../Types.ReasonableCoerce/index.js");
var context = Effect_Unsafe.unsafePerformEffect(React_Basic.createContext({
    dispatch: Types_ReasonableCoerce.reasonableCoerce("this value is not used"),
    getState: Control_Applicative.pure(Effect.applicativeEffect)(AppState.rootReducer.initialState),
    subscribe: function (callback) {
        return function __do() {
            callback(AppState.rootReducer.initialState)();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        };
    },
    wasmWorker: Types_ReasonableCoerce.reasonableCoerce("this value is not used")({})
}));
module.exports = {
    context: context
};
