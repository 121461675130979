// Generated by purs version 0.14.5
"use strict";
var Control_Category = require("../Control.Category/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Map = require("../Data.Map/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unfoldable = require("../Data.Unfoldable/index.js");
var TacoGroupToggle = require("../TacoGroupToggle/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var example1_jsx = TofuHooks.mkHookComponent("TacoGroupToggleExample")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState(Data_Map_Internal.fromFoldable(Data_Ord.ordString)(Data_Foldable.foldableArray)([ new Data_Tuple.Tuple("Group 1", true), new Data_Tuple.Tuple("Group 2", false) ]))();
        var testActive = function (group) {
            return Data_Maybe.fromMaybe(false)(Data_Map_Internal.lookup(Data_Ord.ordString)(group)(v1.value0));
        };
        var handleToggleGroup = function (group) {
            return function (toggled) {
                return v1.value1(Data_Map_Internal.insert(Data_Ord.ordString)(group)(!toggled));
            };
        };
        return TacoGroupToggle.component({
            groups: Data_Set.toUnfoldable(Data_Unfoldable.unfoldableArray)(Data_Map.keys(v1.value0)),
            handleToggleGroup: handleToggleGroup,
            testActive: testActive,
            getName: Control_Category.identity(Control_Category.categoryFn)
        });
    };
});
var example1 = "example1_jsx :: {} -> RB.JSX\x0aexample1_jsx = mkHookComponent \"TacoGroupToggleExample_\" \\props -> do\x0a  Tuple groups setGroups <- useState $ Map.fromFoldable\x0a    [ Tuple \"Group 1\" true\x0a    , Tuple \"Group 2\" false\x0a    ]\x0a\x0a  let\x0a    handleToggleGroup group toggled =\x0a      setGroups $ Map.insert group $ not toggled\x0a\x0a    testActive group =\x0a      fromMaybe false $ Map.lookup group groups\x0a\x0a    getName = identity\x0a\x0a  pure $ TacoGroupToggle.component\x0a    { groups: Set.toUnfoldable $ Map.keys groups\x0a    , handleToggleGroup\x0a    , testActive\x0a    , getName\x0a    }\x0a";
var sections = [ {
    title: "Default",
    view: [ example1_jsx({}) ],
    code: example1
} ];
var demoView = {
    sections: sections,
    title: "TacoGroupToggle",
    description: "Toggle buttons that are grouped together"
};
module.exports = {
    demoView: demoView
};
