// Generated by purs version 0.14.5
"use strict";
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon = require("../TacoIcon/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var socialExpenseIndicator = TacoIcon.component()()()()({
    icon: TacoIcon_Icons_Types.IconDot2.value,
    size: TacoConstants.XS.value,
    color: TacoConstants.Orange.value,
    title: "Sis\xe4lt\xe4\xe4 sosiaalikulut"
});
module.exports = {
    socialExpenseIndicator: socialExpenseIndicator
};
