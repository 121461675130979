// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var FileUpload = require("../FileUpload/index.js");
var Locale = require("../Locale/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var StateHooks = require("../StateHooks/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var uploadCostGroups = TofuHooks.mkHookComponent("UploadCostGroups")(function (props) {
    return function __do() {
        var appDispatch = StateHooks.useDispatch();
        var uploadFileLabel = FileUpload.uploadFileLabel({
            filename: props.filename,
            className: "upload-costgroups",
            uniqueId: props.uniqueId,
            label: Locale.lookup_("upload_costgroups_excel")
        });
        var uploadFileInput = FileUpload.mkFileUpload({
            projectId: new Data_Maybe.Just(props.projectId),
            uploadType: FileUpload.CostGroupsUpload.value,
            endpointUrl: "/uploadCostGroups",
            uniqueId: props.uniqueId,
            filename: props.filename,
            setFilename: props.setFilename,
            uploadStatus: props.uploadStatus,
            setUploadStatus: props.setUploadStatus,
            onSuccess: Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(function __do() {
                appDispatch(new Actions.CostGroupInfosRequest({
                    projectId: props.projectId
                }))();
                appDispatch(new Actions.GetReportingResourcesRequest({
                    projectId: props.projectId
                }))();
                appDispatch(new Actions.GetReportingComponentsRequest({
                    projectId: props.projectId
                }))();
                appDispatch(new Actions.ProjectEstimateItemsRequest({
                    projectId: props.projectId
                }))();
                return appDispatch(new Actions.ProjectTargetItemsRequest({
                    projectId: props.projectId
                }))();
            })
        });
        return React_Basic_DOM_Generated.div()({
            className: props.className,
            children: [ uploadFileInput, uploadFileLabel, FileUpload.uploadStatusToJSX(props.uploadStatus) ]
        });
    };
});
module.exports = {
    uploadCostGroups: uploadCostGroups
};
