// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Effect = require("../Effect/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var UseWorkerData = require("../UseWorkerData/index.js");
var WasmWorker = require("../WasmWorker/index.js");
var WasmWorker_WorkerRequest = require("../WasmWorker.WorkerRequest/index.js");
var useUnfilteredSEPProjectLocationsData = function (projectId) {
    return UseWorkerData.useWorkerItemsByKey(projectId)(function (v) {
        return v.workerUnfilteredSEPProjectLocationsData;
    })("UnfilteredSepProjectLocationsDataState");
};
var useSubprojects = function (projectId) {
    return UseWorkerData.useWorkerItemsByKey(projectId)(function (v) {
        return v.workerEstimationSubprojects;
    })("EstimationSubprojectsState");
};
var useSetWorkerState = function (mkStateUpdate) {
    return function (value) {
        return TofuHooks.useEffect([ ReactHooksUtils.utf(value) ])((function () {
            var stateUpdate = mkStateUpdate(value);
            return function __do() {
                WasmWorker.setWasmWorkerState(stateUpdate)(Data_Maybe.Nothing.value)();
                return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
            };
        })());
    };
};
var useSetSelectedElements = useSetWorkerState(WasmWorker_WorkerRequest.SelectedElementsState.create);
var useSetSelectedComponents = useSetWorkerState(WasmWorker_WorkerRequest.SelectedComponentsState.create);
var useSetActiveRefProjects = useSetWorkerState(WasmWorker_WorkerRequest.ActiveRefProjectsState.create);
var useSetActiveRefElementFilters = useSetWorkerState(WasmWorker_WorkerRequest.ActiveRefElementFiltersState.create);
var useSetActiveRefComponentFilters = useSetWorkerState(WasmWorker_WorkerRequest.ActiveRefComponentFiltersState.create);
var useSetActiveLibraryTab = useSetWorkerState(WasmWorker_WorkerRequest.ActivateLibraryTabState.create);
var useSetActiveElementFilters = useSetWorkerState(WasmWorker_WorkerRequest.ActiveElementFiltersState.create);
var useSetActiveComponentFilters = useSetWorkerState(WasmWorker_WorkerRequest.ActiveComponentFiltersState.create);
var useResourcesByComponents = function (projectId) {
    return UseWorkerData.useWorkerItemsByKey(projectId)(function (v) {
        return v.workerResourcesByComponents;
    })("ResourcesByComponentsState");
};
var useReferenceSubprojects = function (projectId) {
    return UseWorkerData.useWorkerItemsByKey(projectId)(function (v) {
        return v.workerEstimationRefSubprojects;
    })("EstimationRefSubprojectsState");
};
var useReferenceEstimationResources = function (projectId) {
    return UseWorkerData.useWorkerItemsByKey(projectId)(function (v) {
        return v.workerEstimationRefResources;
    })("EstimationRefResourcesState");
};
var useReferenceEstimationLocations = function (projectId) {
    return UseWorkerData.useWorkerItemsByKey(projectId)(function (v) {
        return v.workerEstimationRefLocations;
    })("EstimationRefLocationsState");
};
var useReferenceEstimationElements = function (projectId) {
    return UseWorkerData.useWorkerItemsByKey(projectId)(function (v) {
        return v.workerEstimationRefElements;
    })("EstimationRefElementsState");
};
var useReferenceEstimationComponents = function (projectId) {
    return UseWorkerData.useWorkerItemsByKey(projectId)(function (v) {
        return v.workerEstimationRefComponents;
    })("EstimationRefComponentsState");
};
var useReferenceElementsData = function (projectId) {
    return UseWorkerData.useWorkerItemsByKey(projectId)(function (v) {
        return v.workerRefElementsData;
    })("RefElementsDataState");
};
var useReferenceElementSpecs = function (projectId) {
    return UseWorkerData.useWorkerItemsByKey(projectId)(function (v) {
        return v.workerEstimationRefElementSpecs;
    })("EstimationRefElementSpecsState");
};
var useReferenceElementLocations = function (projectId) {
    return UseWorkerData.useWorkerItemsByKey(projectId)(function (v) {
        return v.workerEstimationRefElementLocations;
    })("EstimationRefElementLocationsState");
};
var useReferenceComponentWithResources = function (projectId) {
    return UseWorkerData.useWorkerItemsByKey(projectId)(function (v) {
        return v.workerRefComponentWithResources;
    })("RefComponentsWithResourcesState");
};
var useProjectLocationsData = function (projectId) {
    return UseWorkerData.useWorkerItemsByKey(projectId)(function (v) {
        return v.workerProjectLocationsData;
    })("ProjectLocationsDataState");
};
var useProjectLocations = function (projectId) {
    return UseWorkerData.useWorkerItemsByKey(projectId)(function (v) {
        return v.workerProjectLocations;
    })("ProjectLocationsState");
};
var useProjectClassificationClasses = function (projectId) {
    return UseWorkerData.useWorkerItemsByKey(projectId)(function (v) {
        return v.workerClassificationClasses;
    })("ProjectClassificationClassesState");
};
var usePlanningCostGroups = function (projectId) {
    return UseWorkerData.useWorkerItemsByKey(projectId)(function (v) {
        return v.workerPlanningCostGroups;
    })("PlanningCostGroupsState");
};
var useInvalidResourceCurrencies = function (projectId) {
    return UseWorkerData.useWorkerDataByKey(projectId)(function (v) {
        return v.workerInvalidResourceCurrencies;
    })("InvalidResourceCurrenciesState");
};
var useFilteredRefElementIds = function (projectId) {
    return UseWorkerData.useWorkerItemsByKey(projectId)(function (v) {
        return v.workerFilteredRefElementIds;
    })("FilteredRefElementIdsState");
};
var useFilteredRefComponentIds = function (projectId) {
    return UseWorkerData.useWorkerItemsByKey(projectId)(function (v) {
        return v.workerFilteredRefComponentIds;
    })("FilteredRefComponentIdsState");
};
var useFilteredElementIds = function (projectId) {
    return UseWorkerData.useWorkerItemsByKey(projectId)(function (v) {
        return v.workerFilteredElementIds;
    })("FilteredElementIdsState");
};
var useFilteredComponentIds = function (projectId) {
    return UseWorkerData.useWorkerItemsByKey(projectId)(function (v) {
        return v.workerFilteredComponentIds;
    })("FilteredComponentIdsState");
};
var useEstimationResources = function (projectId) {
    return UseWorkerData.useWorkerItemsByKey(projectId)(function (v) {
        return v.workerEstimationResources;
    })("EstimationResourcesState");
};
var useEstimationProjects = UseWorkerData.useWorkerItems(function (v) {
    return v.workerEstimationProjects;
});
var useEstimationLocations = function (projectId) {
    return UseWorkerData.useWorkerItemsByKey(projectId)(function (v) {
        return v.workerEstimationLocations;
    })("EstimationLocationsState");
};
var useEstimationElementsTotals = function (projectId) {
    return UseWorkerData.useWorkerDataByKey(projectId)(function (v) {
        return v.workerElementsTotals;
    })("EstimationElementsTotalsState");
};
var useEstimationElements = function (projectId) {
    return UseWorkerData.useWorkerItemsByKey(projectId)(function (v) {
        return v.workerEstimationElements;
    })("EstimationElementsState");
};
var useEstimationComponentsTotals = function (projectId) {
    return UseWorkerData.useWorkerDataByKey(projectId)(function (v) {
        return v.workerEstimationComponentsTotals;
    })("EstimationComponentsTotalsState");
};
var useEstimationComponents = function (projectId) {
    return UseWorkerData.useWorkerItemsByKey(projectId)(function (v) {
        return v.workerEstimationComponents;
    })("EstimationComponentsState");
};
var useElementsData = function (projectId) {
    return UseWorkerData.useWorkerItemsByKey(projectId)(function (v) {
        return v.workerElementsData;
    })("ElementsDataState");
};
var useElementSpecs = function (projectId) {
    return UseWorkerData.useWorkerItemsByKey(projectId)(function (v) {
        return v.workerEstimationElementSpecs;
    })("EstimationElementSpecsState");
};
var useElementLocations = function (projectId) {
    return UseWorkerData.useWorkerItemsByKey(projectId)(function (v) {
        return v.workerEstimationElementLocations;
    })("EstimationElementLocationsState");
};
var useCostClasses = function (projectId) {
    return UseWorkerData.useWorkerItemsByKey(projectId)(function (v) {
        return v.workerCostClasses;
    })("EstimationCostClassesState");
};
var useComponentsWithResources = function (projectId) {
    return UseWorkerData.useWorkerItemsByKey(projectId)(function (v) {
        return v.workerComponentWithResources;
    })("ComponentsWithResourcesState");
};
var useComponentWithResources = function (projectId) {
    return UseWorkerData.useWorkerItemsByKey(projectId)(function (v) {
        return v.workerComponentWithResources;
    })("ComponentsWithResourcesState");
};
var useClassificationGroups = function (projectId) {
    return UseWorkerData.useWorkerItemsByKey(projectId)(function (v) {
        return v.workerEstimationClassificationGroups;
    })("EstimationClassificationGroupsState");
};
var useAdminetIntegrationActive = UseWorkerData.useWorkerData(function (v) {
    return v.workerAdminetIntegration;
});
var useActiveRefElements = function (projectId) {
    return UseWorkerData.useWorkerItemsByKey(projectId)(function (v) {
        return v.workerActiveRefElements;
    })("ActiveRefElementsState");
};
var useActiveRefComponents = function (projectId) {
    return UseWorkerData.useWorkerItemsByKey(projectId)(function (v) {
        return v.workerActiveRefComponents;
    })("ActiveRefComponentsState");
};
module.exports = {
    useComponentWithResources: useComponentWithResources,
    useReferenceComponentWithResources: useReferenceComponentWithResources,
    useEstimationComponents: useEstimationComponents,
    useEstimationElements: useEstimationElements,
    useReferenceEstimationElements: useReferenceEstimationElements,
    useReferenceEstimationComponents: useReferenceEstimationComponents,
    useReferenceEstimationResources: useReferenceEstimationResources,
    useEstimationResources: useEstimationResources,
    useInvalidResourceCurrencies: useInvalidResourceCurrencies,
    useEstimationLocations: useEstimationLocations,
    useReferenceEstimationLocations: useReferenceEstimationLocations,
    useSubprojects: useSubprojects,
    useReferenceSubprojects: useReferenceSubprojects,
    useElementLocations: useElementLocations,
    useReferenceElementLocations: useReferenceElementLocations,
    useElementSpecs: useElementSpecs,
    useReferenceElementSpecs: useReferenceElementSpecs,
    useElementsData: useElementsData,
    useReferenceElementsData: useReferenceElementsData,
    useClassificationGroups: useClassificationGroups,
    useEstimationProjects: useEstimationProjects,
    useAdminetIntegrationActive: useAdminetIntegrationActive,
    useResourcesByComponents: useResourcesByComponents,
    usePlanningCostGroups: usePlanningCostGroups,
    useProjectLocations: useProjectLocations,
    useComponentsWithResources: useComponentsWithResources,
    useFilteredComponentIds: useFilteredComponentIds,
    useFilteredRefComponentIds: useFilteredRefComponentIds,
    useFilteredElementIds: useFilteredElementIds,
    useFilteredRefElementIds: useFilteredRefElementIds,
    useProjectLocationsData: useProjectLocationsData,
    useUnfilteredSEPProjectLocationsData: useUnfilteredSEPProjectLocationsData,
    useProjectClassificationClasses: useProjectClassificationClasses,
    useCostClasses: useCostClasses,
    useEstimationComponentsTotals: useEstimationComponentsTotals,
    useEstimationElementsTotals: useEstimationElementsTotals,
    useActiveRefComponents: useActiveRefComponents,
    useActiveRefElements: useActiveRefElements,
    useSetWorkerState: useSetWorkerState,
    useSetActiveComponentFilters: useSetActiveComponentFilters,
    useSetActiveRefComponentFilters: useSetActiveRefComponentFilters,
    useSetSelectedComponents: useSetSelectedComponents,
    useSetActiveElementFilters: useSetActiveElementFilters,
    useSetActiveRefElementFilters: useSetActiveRefElementFilters,
    useSetSelectedElements: useSetSelectedElements,
    useSetActiveRefProjects: useSetActiveRefProjects,
    useSetActiveLibraryTab: useSetActiveLibraryTab,
    call_wasm_fn: $foreign.call_wasm_fn,
    getWasmModule: $foreign.getWasmModule
};
