import { Button, Header, IconAddPlusV1, Input } from "@tocoman/ui";
import { Measurement } from "ts-bindings/Measurement";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Subproject } from "ts-bindings/Subproject";
import { ErrorMessage } from "../../../components/ErrorMessage";
import { isNumber } from "src/client-ts/utils/validations";
import { useMeasurementValidations } from "./useMeasurementValidations";
import { MeasurementValue } from "ts-bindings/MeasurementValue";
import { useState } from "react";

export type NewMeasurementUnitFormProps = {
  onNewMeasurement: (measurement: Measurement) => void;
  subprojects: Subproject[];
  projectId?: number;
  measurementUnits: Measurement[];
};

type SubprojectMeasurement = {
  [key: number]: {
    value: number;
    countInMeasurementTotal: boolean;
  };
};

export function NewMeasurementUnitForm({
  onNewMeasurement,
  projectId,
  subprojects,
  measurementUnits,
}: NewMeasurementUnitFormProps) {
  const { t } = useTranslation("projects", { keyPrefix: "details" });

  const [projectMeasurementQuantity, setProjectMeasurementQuantity] = useState<
    number
  >(0);
  const [subprojectMeasurements, setSubprojectMeasurements] = useState<
    SubprojectMeasurement
  >([]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Measurement>();

  const {
    measurementError,
    validateMeasurementNameLength,
    validateUniqueMeasurement,
  } = useMeasurementValidations();

  const onSubmit = (formValue: Measurement) => {
    const fixedFormValues = formatSubmittedForm(formValue);
    onNewMeasurement(fixedFormValues);
    setProjectMeasurementQuantity(0);
    setSubprojectMeasurements([]);
    reset();
  };

  const checkIfFormValuesEmpty = (formValue: Measurement): boolean => {
    for (const entry of formValue.values) {
      if (entry.value != 0) {
        return false;
      }
    }
    return true;
  };

  const formatSubmittedForm = (formValue: Measurement): Measurement => {
    const formValuesToFloat = formValue.values.map(
      (measurementValue, index) => {
        return {
          ...measurementValue,
          subprojectId: index === 0 ? null : subprojects[index - 1].id,
          value: parseFloat(String(measurementValue.value)),
          measurementId: null,
          id: 0,
        };
      }
    );

    const calculatedValues = formValuesToFloat.map((measurementValue) => {
      if (!measurementValue.subprojectId) {
        return {
          ...measurementValue,
          value: calculateProjectMeasurement(formValuesToFloat),
        };
      }
      return measurementValue;
    });

    const unit =
      projectId !== undefined
        ? { ...formValue.unit, mandatory: false, projectId, id: null }
        : { ...formValue.unit, mandatory: false, id: null };

    return {
      ...formValue,
      values: checkIfFormValuesEmpty(formValue) ? [] : calculatedValues,
      unit,
    };
  };

  const calculateProjectMeasurement = (values: MeasurementValue[]): number => {
    const measurementsToSum = values.filter((measurement) => {
      const subproject = subprojects.find(
        (subproject) => subproject.id === measurement.subprojectId
      );

      return (
        measurement.subprojectId !== null && subproject?.countInMeasurementTotal
      );
    });

    return measurementsToSum.reduce(
      (acc, measurement) => acc + measurement.value,
      0
    );
  };

  const onSubprojectValueChange = (value: string, index: number) => {
    const numberValue = parseToNumber(value);
    const measurements = {
      ...subprojectMeasurements,
      [index]: {
        value: numberValue,
        countInMeasurementTotal: subprojects[index].countInMeasurementTotal,
      },
    };

    const projectMeasurementQuantity = calculateProjectMeasurementQuantity(
      measurements
    );
    setSubprojectMeasurements(measurements);
    setProjectMeasurementQuantity(projectMeasurementQuantity);
  };

  return (
    <>
      <Header
        titleSize="medium"
        title={t`measurementUnits.newMeasurement.title`}
      ></Header>
      <div className={"flex flex-row"}>
        <form className={"flex flex-row"}>
          <div>
            <Input
              testId={"addName"}
              className={"mx-2 w-[256px]"}
              label={t`measurementUnits.tableColumns.name`}
              {...register(`unit.name`, {
                validate: {
                  unique: (value) =>
                    validateUniqueMeasurement(value, measurementUnits),
                  length: (value) =>
                    validateMeasurementNameLength(value.toString()),
                },
                required: true,
              })}
            />
            <div className={"ml-2"}>
              {errors?.unit?.name && (
                <ErrorMessage
                  errorMessage={measurementError(errors.unit?.name?.type)}
                />
              )}
            </div>
          </div>
          <div>
            <Input
              className={"mx-2 w-[90px]"}
              label={t`measurementUnits.tableColumns.unit`}
              {...register(`unit.unit`, {
                maxLength: {
                  value: 20,
                  message: t(`errors.tooLong`, { maxLength: "20" }),
                },
              })}
            />
            {errors.unit?.unit?.type === "maxLength" && (
              <ErrorMessage errorMessage={errors.unit.unit.message} />
            )}
          </div>
          <div className={"flex flex-col w-[90px] mx-2"}>
            <Input
              testId={"addValue"}
              label={t`measurementUnits.tableColumns.projectQuantity`}
              value={projectMeasurementQuantity}
              disabled={true}
              {...register(`values.${0}.value`)}
            />
          </div>

          <div className={"flex flex-col"}>
            {subprojects.map((subproject, index) => {
              return (
                <div
                  key={subproject.id}
                  className={"flex flex-col w-[90px] mx-2"}
                >
                  <Input
                    label={
                      subproject.code +
                      `${subproject.countInMeasurementTotal ? "*" : ""}`
                    }
                    defaultValue={0}
                    {...register(`values.${index + 1}.value`, {
                      setValueAs: parseToNumber,
                      onChange: (e) =>
                        onSubprojectValueChange(e.target.value, index),
                      validate: isNumber,
                    })}
                  />
                  {errors.values &&
                    errors.values[index + 1]?.value?.type === "validate" && (
                      <ErrorMessage
                        errorMessage={t`measurementUnits.errors.notANumber`}
                      />
                    )}
                </div>
              );
            })}
          </div>

          <div className={"ml-3 h-[45px] w-[128px] pt-7 align-bottom"}>
            <Button
              className={"w-full"}
              icon={IconAddPlusV1}
              label={t`measurementUnits.newMeasurement.addForm`}
              color={"normal"}
              variant={"secondary"}
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </form>
      </div>
    </>
  );
}

function calculateProjectMeasurementQuantity(
  measurements: SubprojectMeasurement
) {
  const measurementValues = Object.values(measurements);
  return measurementValues.reduce((acc, measurement) => {
    if (measurement.countInMeasurementTotal) {
      return acc + measurement.value;
    }
    return acc;
  }, 0);
}

function parseToNumber(value: string): number {
  return Number(value.replace(",", "."));
}
