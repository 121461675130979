// Generated by purs version 0.14.5
"use strict";
var Components_Utils = require("../Components.Utils/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Effect = require("../Effect/index.js");
var HtmlUtils = require("../HtmlUtils/index.js");
var Kishimen = require("../Kishimen/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoFilter_Styles = require("../TacoFilter.Styles/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Utils_ClassNames = require("../Utils.ClassNames/index.js");
var Web_HTML_HTMLElement = require("../Web.HTML.HTMLElement/index.js");
var UseTacoFilterText = (function () {
    function UseTacoFilterText() {

    };
    UseTacoFilterText.value = new UseTacoFilterText();
    return UseTacoFilterText;
})();
var DontUseTacoFilterText = (function () {
    function DontUseTacoFilterText() {

    };
    DontUseTacoFilterText.value = new DontUseTacoFilterText();
    return DontUseTacoFilterText;
})();
var FVSNeedsFocus = (function () {
    function FVSNeedsFocus() {

    };
    FVSNeedsFocus.value = new FVSNeedsFocus();
    return FVSNeedsFocus;
})();
var FVSPreviouslySet = (function () {
    function FVSPreviouslySet() {

    };
    FVSPreviouslySet.value = new FVSPreviouslySet();
    return FVSPreviouslySet;
})();
var isNeedsFocus = function (filterValues) {
    if (filterValues.state instanceof FVSNeedsFocus) {
        return true;
    };
    if (filterValues.state instanceof FVSPreviouslySet) {
        return false;
    };
    throw new Error("Failed pattern match at TacoFilter (line 42, column 29 - line 44, column 28): " + [ filterValues.state.constructor.name ]);
};
var genericFilterValuesState = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return FVSNeedsFocus.value;
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return FVSPreviouslySet.value;
        };
        throw new Error("Failed pattern match at TacoFilter (line 174, column 1 - line 174, column 73): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof FVSNeedsFocus) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof FVSPreviouslySet) {
            return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
        };
        throw new Error("Failed pattern match at TacoFilter (line 174, column 1 - line 174, column 73): " + [ x.constructor.name ]);
    }
};
var tofuJSONFilterValuesState = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FVSNeedsFocus";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FVSPreviouslySet";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FVSNeedsFocus";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FVSPreviouslySet";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))(genericFilterValuesState)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FVSNeedsFocus";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FVSPreviouslySet";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FVSNeedsFocus";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FVSPreviouslySet";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericFilterValuesState)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FVSNeedsFocus";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FVSPreviouslySet";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FVSNeedsFocus";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FVSPreviouslySet";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FVSNeedsFocus";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FVSPreviouslySet";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FVSNeedsFocus";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FVSPreviouslySet";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))
};
var eqUseTacoFilterText = {
    eq: function (x) {
        return function (y) {
            if (x instanceof UseTacoFilterText && y instanceof UseTacoFilterText) {
                return true;
            };
            if (x instanceof DontUseTacoFilterText && y instanceof DontUseTacoFilterText) {
                return true;
            };
            return false;
        };
    }
};
var tacoFilter = TofuHooks.mkHookComponent("TacoFilter")(function (props) {
    return function __do() {
        var v = TofuHooks.useState("")();
        var onChange = function (v1) {
            if (v1 instanceof Data_Maybe.Just) {
                return v.value1(function (v2) {
                    return v1.value0;
                });
            };
            if (v1 instanceof Data_Maybe.Nothing) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            };
            throw new Error("Failed pattern match at TacoFilter (line 93, column 5 - line 93, column 54): " + [ v1.constructor.name ]);
        };
        var mkFilter = function (v1) {
            if (v1 === "") {
                return [  ];
            };
            return Data_Array.filter((function () {
                var $28 = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean);
                return function ($29) {
                    return $28(Data_String_Common["null"]($29));
                };
            })())(Data_String_Common.split(",")(v1));
        };
        var inputId = "stringFilterInput__" + (props.label + ("__" + props.id));
        var focusInput = HtmlUtils.runEffectOnHTMLElementById(inputId)(Web_HTML_HTMLElement.focus);
        var cleanInputValue = Data_String_Common.joinWith(",");
        var setFilterValue = (function () {
            var filter = mkFilter(v.value0);
            return function __do() {
                v.value1(function (v1) {
                    return cleanInputValue(filter);
                })();
                return props.onFilterUpdate({
                    state: FVSPreviouslySet.value,
                    values: filter
                })();
            };
        })();
        var className = Utils_ClassNames.mkClassName([ new Data_Maybe.Just("string-value-filter"), Data_Monoid.guard(Data_Maybe.monoidMaybe(Data_Semigroup.semigroupString))(!Data_Array["null"](props.filterValues.values))(new Data_Maybe.Just("active")) ]);
        var blurInput = HtmlUtils.runEffectOnHTMLElementById(inputId)(Web_HTML_HTMLElement.blur);
        TofuHooks.useEffect([  ])(function __do() {
            Control_Applicative.when(Effect.applicativeEffect)(!Data_Array["null"](props.filterValues.values))(v.value1(function (v1) {
                return cleanInputValue(props.filterValues.values);
            }))();
            Control_Applicative.when(Effect.applicativeEffect)(isNeedsFocus(props.filterValues))(focusInput)();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        })();
        TofuHooks.useEffect([ props.filterValues ])(function __do() {
            v.value1(function (v1) {
                return cleanInputValue(props.filterValues.values);
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        })();
        var input = React_Basic_Emotion.element(React_Basic_DOM_Generated["input'"]())({
            type: "text",
            className: "input",
            css: TacoFilter_Styles.inputStyles,
            id: inputId,
            value: v.value0,
            onChange: React_Basic_Events.handler(React_Basic_DOM_Events.targetValue)(onChange),
            onBlur: React_Basic_Events.handler_(setFilterValue),
            onKeyDown: React_Basic_Events.handler(React_Basic_DOM_Events.key)(function (key) {
                return Control_Applicative.when(Effect.applicativeEffect)(Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(key)(new Data_Maybe.Just("Enter")))(function __do() {
                    setFilterValue();
                    return blurInput();
                });
            }),
            "_data": Components_Utils.mkTestIdAttrs("taco-filter-input-" + props.testId)
        });
        var inputContainer = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "input-container",
            css: TacoFilter_Styles.inputContainerStyles,
            children: [ input ]
        });
        var filterValue = React_Basic_Emotion.element(React_Basic_DOM_Generated["span'"]())({
            className: Utils_ClassNames.mkClassName([ new Data_Maybe.Just("filter-value"), Data_Monoid.guard(Data_Maybe.monoidMaybe(Data_Semigroup.semigroupString))(Data_String_Common["null"](v.value0))(new Data_Maybe.Just("empty")) ]),
            css: TacoFilter_Styles.filterValueStyles,
            children: [ React_Basic_DOM.text((function () {
                var $23 = Data_String_Common["null"](v.value0) && Data_Eq.eq(eqUseTacoFilterText)(props.useTacoFilterText)(UseTacoFilterText.value);
                if ($23) {
                    return "Ei rajausta";
                };
                return v.value0;
            })()) ],
            "_data": Components_Utils.mkTestIdAttrs("taco-filter-value-" + props.testId)
        });
        var filterValueContainer = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "filter-value-container",
            css: TacoFilter_Styles.filterValueContainerStyles,
            children: [ filterValue, Data_Monoid.guard(React_Basic.monoidJSX)(Data_Eq.eq(eqUseTacoFilterText)(props.useTacoFilterText)(UseTacoFilterText.value))(inputContainer) ]
        });
        var filterLabel = React_Basic_Emotion.element(React_Basic_DOM_Generated["span'"]())({
            className: "filter-label",
            css: TacoFilter_Styles.filterLabelStyles,
            children: [ React_Basic_DOM.text(props.label) ]
        });
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["label'"]())({
            className: className,
            css: TacoFilter_Styles.tacoFilterContainerStyles,
            htmlFor: inputId,
            "_data": Components_Utils.mkTestIdAttrs("taco-filter-label-" + props.testId),
            children: [ filterLabel, filterValueContainer, TacoButton.component()()({
                icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconCloseCrossV1.value),
                onClick: new Data_Maybe.Just(props.onFilterRemove),
                testId: "taco-filter-" + (props.testId + "-remove-button")
            }) ]
        });
    };
});
var eqFilterValuesState = {
    eq: function (x) {
        return function (y) {
            if (x instanceof FVSNeedsFocus && y instanceof FVSNeedsFocus) {
                return true;
            };
            if (x instanceof FVSPreviouslySet && y instanceof FVSPreviouslySet) {
                return true;
            };
            return false;
        };
    }
};
var emptyNoFocusState = {
    state: FVSPreviouslySet.value,
    values: [  ]
};
var emptyFilterValues = {
    state: FVSNeedsFocus.value,
    values: [  ]
};
module.exports = {
    tacoFilter: tacoFilter,
    FVSNeedsFocus: FVSNeedsFocus,
    FVSPreviouslySet: FVSPreviouslySet,
    UseTacoFilterText: UseTacoFilterText,
    DontUseTacoFilterText: DontUseTacoFilterText,
    emptyFilterValues: emptyFilterValues,
    emptyNoFocusState: emptyNoFocusState,
    eqUseTacoFilterText: eqUseTacoFilterText,
    eqFilterValuesState: eqFilterValuesState,
    genericFilterValuesState: genericFilterValuesState,
    tofuJSONFilterValuesState: tofuJSONFilterValuesState
};
