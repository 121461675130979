// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import * as R from "ramda";
import Utils from "../../../../../output/Utils";
import Chart from "./Chart";
import { lookup } from "../../../utils/locale";
import * as FH from "../../../../../output/FormattingHelpers";
import {
  mkMonthlyReportContainer,
  prepareGrid,
  prepareGridSumHorizontal,
  prepareXAxis,
  prepareXAxisSumHorizontal,
  prepareYAxis,
  prepareYAxisSumHorizontal,
  getChartXAxisInterval,
} from "../../../../../output/MonthlyReportContainer";

const monthlyReport = (props) =>
  mkMonthlyReportContainer({
    ...props,
    makeTimelineSlicer,
    makeCharts: (mkMonthlyReportContainerProps) => {
      const {
        actionsDropdownComponent,
        reportData,
        costItems,
        actualCostsAndIncomeChartData,
        showSumChart,
        showChartFor89,
        showMonthlyProfitsChart,
        showTargetsChart,
      } = {
        ...props,
        ...mkMonthlyReportContainerProps,
      };

      const chartData = reportData.chartData;
      const chartDataSumHorizontal = reportData.chartDataSumHorizontal;
      const diffChartData = reportData.diffChartData;
      const timelineSlicer = reportData.timelineSlicer;
      const max = Math.ceil((findMax(chartData) * 1.1) / 200000) * 200000;
      const diffMax = Math.ceil((findMax(diffChartData) * 1.1) / 2000) * 2000;
      const diffMin = Math.floor((findMin(diffChartData) * 1.1) / 2000) * 2000;
      const formattedMonths = reportData.formattedMonths;
      const mcg89ChartData = reportData.mcg89ChartData;
      const cumulativeProfitMargins = reportData.cumulativeProfitMargins;

      const {
        actualCosts,
        actualIncome,
        estimatedCosts,
        estimatedIncome,
      } = actualCostsAndIncomeChartData;

      const actualCostsAndIncomeChartCommonProps = {
        type: "line",
        emphasis: {
          show: false,
          label: {
            show: false,
            position: "top",
            color: "#404040",
            backgroundColor: "#ffffff",
            borderWidth: 1,
            borderColor: "#e0e0e0",
            padding: 6,
            borderRadius: 3,
            fontsize: 15,
            backgroundcolor: "#ffffff",
            formatter: ({ value }) =>
              FH.formatHelper.formatCurrencyValue(value),
          },
        },
        symbolSize: 8,
      };

      const colors = {
        actualIncome: "#2284f0",
        actualCosts: "#f08e22",
        estimatedIncome: "#6fd1ff",
        estimatedCosts: "#ffdb6f",
      };

      const actualCostsAndIncomeChart = (
        <Chart
          xAxis={{
            type: "category",
            data: actualCostsAndIncomeChartData.monthLabels,
            axisLabel: {
              color: "grey",
              fontSize: 14,
              interval: getChartXAxisInterval(15.0)(
                R.length(actualCostsAndIncomeChartData.monthLabels)
              ),
            },
          }}
          yAxis={{
            type: "value",
            axisLabel: {
              formatter: (value) => FH.formatHelper.formatCurrencyValue(value),
            },
          }}
          series={[
            {
              ...actualCostsAndIncomeChartCommonProps,
              data: estimatedIncome.data,
              name: estimatedIncome.name,
              lineStyle: {
                type: "dashed",
              },
            },
            {
              ...actualCostsAndIncomeChartCommonProps,
              data: actualIncome.data,
              name: actualIncome.name,
              lineStyle: {
                type: "solid",
              },
            },
            {
              ...actualCostsAndIncomeChartCommonProps,
              data: estimatedCosts.data,
              name: estimatedCosts.name,
              lineStyle: {
                type: "dashed",
              },
            },
            {
              ...actualCostsAndIncomeChartCommonProps,
              data: actualCosts.data,
              name: actualCosts.name,
              lineStyle: {
                type: "solid",
              },
            },
          ]}
          color={[
            colors.estimatedIncome,
            colors.actualIncome,
            colors.estimatedCosts,
            colors.actualCosts,
          ]}
          legend={{
            selectedMode: true,
            right: "auto",
            top: "0",
            orient: "horizontal",
          }}
          height={500}
          data={actualCostsAndIncomeChartData.data}
          tooltip={{
            axisPointer: {
              z: 0,
            },
            trigger: "axis",
            backgroundColor: "#ffffff",
            borderWidth: 1,
            borderColor: "#e0e0e0",
            extraCssText:
              "box-shadow: 0 1px 3px rgba(250, 250, 250, 0.8); padding: 0.6em 0.8em;",
            formatter: (data) =>
              formatTooltip(data, FH.formatHelper.formatCurrencyValue),
          }}
        />
      );

      const noProjectDatesSelectedMsg = (
        <div className="no-project-dates-selected-msg">
          <span>
            {lookup("monthly_report__select_project_start_and_end_date")}
          </span>
        </div>
      );

      const sumChart = (
        <Chart
          title="Summa vaaka"
          groups={costItems}
          data={chartDataSumHorizontal}
          height={
            (chartDataSumHorizontal.length + 1) * (costItems.length + 1) * 18
          }
          xAxis={prepareXAxisSumHorizontal(
            findMaxSumHorizontal(chartDataSumHorizontal)
          )}
          yAxis={prepareYAxisSumHorizontal(chartData)}
          grid={prepareGridSumHorizontal()}
          series={prepareSeriesSumHorizontal(
            chartDataSumHorizontal,
            null,
            "20%",
            12
          )}
        />
      );

      const chartFor89 = (
        <Chart
          title="Toteutunut 8- & 9 ryhmä ja toteutunut aika"
          xAxis={prepareXAxis(formattedMonths)}
          yAxis={{ type: "value" }}
          series={mcg89ChartData.map((i) => ({
            data: i.data,
            name: i.name,
            type: "line",
          }))}
          height={500}
          data={mcg89ChartData}
        />
      );

      const monthlyProfitsChart = (
        <Chart
          title={"Kuukauden ennustetilanne ja kate-%"}
          groups={R.prepend("Tavoite", formattedMonths)}
          data={chartData}
          height={500}
          xAxis={prepareXAxis(R.prepend("Tavoite", formattedMonths))}
          yAxis={prepareYAxis(0, max)}
          grid={prepareGrid()}
          series={(() => {
            const stackKey = "one";
            const series = prepareSeries(
              chartData,
              stackKey,
              false,
              "50%",
              null
            );
            return series.concat([
              {
                name: "Ennusteen kate",
                type: "line",
                stack: stackKey,
                lineStyle: {
                  width: 0,
                },
                label: {
                  formatter: "{b}",
                  show: true,
                  position: "top",
                },
                // display this line on top of the stacked bar chart
                data: chartDataSumHorizontal.map((_, i) => {
                  const margin = cumulativeProfitMargins[i];
                  return {
                    name: FH.formatHelper.formatPercentageDifferenceValue(
                      margin,
                      2
                    ),
                    value: 0,
                  };
                }),
              },
            ]);
          })()}
          tooltip={null}
        />
      );

      const targetsChart = (
        <Chart
          title={lookup("common_diff")}
          groups={R.prepend("Tavoite", formattedMonths)}
          data={diffChartData}
          height={500}
          xAxis={prepareXAxis(R.prepend("Tavoite", formattedMonths))}
          yAxis={prepareYAxis(diffMin, diffMax)}
          grid={prepareGrid()}
          series={prepareSeries(diffChartData, "one", false, "50%", null)}
        />
      );

      return (
        <div className="monthly-report page">
          {actionsDropdownComponent}
          <div className="chart_actual-costs-and-income">
            <h2 className="chart-title">
              {lookup("monthly_report__actual_income_and_costs_chart_title")}
            </h2>
            {actualCostsAndIncomeChartData.monthLabels.length > 0
              ? actualCostsAndIncomeChart
              : noProjectDatesSelectedMsg}
          </div>
          {(showSumChart ||
            showChartFor89 ||
            showMonthlyProfitsChart ||
            showTargetsChart) &&
            timelineSlicer}
          {showSumChart && sumChart}
          {showChartFor89 && chartFor89}
          {showMonthlyProfitsChart && monthlyProfitsChart}
          {showTargetsChart && targetsChart}
        </div>
      );
    },
  });

export default monthlyReport;

function makeTimelineSlicer({
  availableMonths,
  selectedMonths,
  isSameMonth,
  state,
  setState,
}) {
  return (
    <div className="timeline-slicer">
      {availableMonths.map((month, index) => {
        const separator =
          month.getMonth() === 0 || index === 0 ? month.getFullYear() : null;
        const indexInSelected = selectedMonths.findIndex(isSameMonth(month));
        const isSelected =
          indexInSelected >= 0 || isSameMonth(month)(state.startMonth);
        return (
          <div
            key={month}
            className={`slice-parent + ${
              isSelected ? "selected" : "unselected"
            }`}
          >
            <strong>{separator}&nbsp;</strong>
            <br />
            {month.getMonth() + 1}
            <div
              className="slice"
              onClick={() => {
                if (Utils.isNothing(state.endMonth)) {
                  if (state.startMonth < month) {
                    setState.setEndMonth(() => Utils.just(month))();
                  } else {
                    setState.setStartMonth(() => month)();
                    setState.setEndMonth(() => Utils.just(state.startMonth))();
                  }
                } else {
                  setState.setStartMonth(() => month)();
                  setState.setEndMonth(() => Utils.nothing)();
                }
              }}
            />
          </div>
        );
      })}
    </div>
  );
}

function prepareSeries(data, stack, showlabels, barcategorygap, barwidth) {
  return R.reverse(data).map((series) => {
    return {
      name: series.name,
      type: "bar",
      stack: stack,
      data: series.data.map((i) => Math.round(i)),
      label: {
        show: showlabels,
        position: "right",
        color: "black",
        fontsize: 15,
        backgroundcolor: "white",
        formatter: (item) => (item.value === 0 ? "" : item.value),
      },
      emphasis: {
        show: true,
        label: {
          show: true,
          position: "right",
          color: "black",
          fontsize: 15,
          backgroundcolor: "white",
        },
      },
      barcategorygap: barcategorygap,
      barwidth: barwidth,
      cursor: "default",
    };
  });
}

function prepareSeriesSumHorizontal(data, stack, barcategorygap, barwidth) {
  return data.map((series, monthIndex) => {
    return {
      name: series.name,
      type: "bar",
      stack: stack,
      data: R.reverse(
        series.data.map((sd, _i) => {
          return {
            name: sd,
            value: sd,
          };
        })
      ),
      emphasis: {
        show: true,
        label: {
          show: true,
          position: "right",
          color: "black",
          fontsize: 15,
          backgroundcolor: "white",
          rich: {
            diff: {
              fontSize: 14,
            },
          },
          formatter: (params) => {
            const groupIndex = params.dataIndex;
            if (monthIndex > 0) {
              const prevMonth = data[monthIndex - 1];
              const prevValue =
                prevMonth.data[series.data.length - (groupIndex + 1)];
              return formatLabel(params, prevValue);
            } else {
              return "";
            }
          },
        },
      },
      barcategorygap: barcategorygap,
      barwidth: barwidth,
      cursor: "default",
    };
  });
}

function formatTooltip(seriesData, valueFormatter = (x) => x) {
  return seriesData.reduce((acc, series) => {
    const heading = `<h3 class="chart-tooltip-heading">${series.name}</h3>`;
    return `
    ${acc || heading}
    <div class="chart-tooltip-container">
      <span class="label">
        <span class="color" style="background-color: ${series.color};"></span>
        <span class="text">${series.seriesName}</span>
      </span>
      <span class="value">${valueFormatter(series.value)}</span>
    </div>
    `;
  }, "");
}

function formatLabel(params, prev) {
  const diff = params.value - prev;
  const diffRatioFormatted = FH.formatHelper.formatPercentageValue(diff / prev);
  const diffFormatted = addPlusOrMinusSign(
    FH.formatHelper.formatCurrencyValue(diff)
  );
  const diffStyle = "diff";
  return diff ? `{${diffStyle}|${diffFormatted} (${diffRatioFormatted})}` : "";
}

function addPlusOrMinusSign(numberStr) {
  return numberStr.startsWith("−") ? numberStr : "+" + numberStr;
}

// a fake maximum function used to determine ranges of charts
function findMax(chartData) {
  if (chartData.length < 1) return 0;
  const headData = R.head(chartData).data;
  return headData.reduce((acc, _, i) => {
    const sum = R.sum(
      chartData.map((cg) => {
        const value = cg.data[i];
        return value < 0 ? 0 : value;
      })
    );
    return sum > acc ? sum : acc;
  }, 0);
}

function findMaxSumHorizontal(chartData) {
  chartData
    .reduce((acc, cur) => R.concat(acc, cur.data), [])
    .reduce((acc, cur) => (cur > acc ? cur : acc), 0);
}

// fake minimum value function for preparing axis ranges
function findMin(chartData) {
  if (chartData.length < 1) return 0;
  return R.head(chartData).data.reduce((acc, _, i) => {
    const sum = R.sum(
      chartData.map((cg) => {
        const value = cg.data[i];
        return value > 0 ? 0 : value;
      })
    );
    return sum < acc ? sum : acc;
  }, 0);
}
