// Generated by purs version 0.14.5
"use strict";
var Data_Maybe = require("../Data.Maybe/index.js");
var HtmlUtils = require("../HtmlUtils/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoTabs = require("../TacoTabs/index.js");
var TacoTabs_Types = require("../TacoTabs.Types/index.js");
var Web_HTML_Window = require("../Web.HTML.Window/index.js");
var example3 = "import TacoTabs as TacoTabs\x0a\x0a[ TacoTabs.component\x0a    { orientation: Horizontal\x0a    , tabs:\x0a        [ NormalTab\x0a            { children: [ R.text \"Contents of tab 1\" ]\x0a            , quantity: Just $ 5\x0a            , title: \"Rakenteet\"\x0a            , id: \"elements\"\x0a            , color: PrimaryBlue\x0a            }\x0a        , NormalTab\x0a            { children: [ R.text \"Contents of tab 2\" ]\x0a            , quantity: Just $ 5\x0a            , title: \"Suoritteet\"\x0a            , id: \"components\"\x0a            , color: PrimaryBlue\x0a            }\x0a        , NormalTab\x0a            { children: [ R.text \"Contents of tab 3\" ]\x0a            , quantity: Just $ 5\x0a            , title: \"Panokset\"\x0a            , id: \"resources\"\x0a            , color: PrimaryBlue\x0a            }\x0a        , ButtonTab\x0a            { title: \"Click me\"\x0a            , id: \"button\"\x0a            , onClick: Window.alert \"Hello world\" window\x0a            , color: PrimaryBlue\x0a            }\x0a        ]\x0a    }\x0a]\x0a";
var example2 = "import TacoTabs as TacoTabs\x0a\x0a[ TacoTabs.component\x0a    { orientation: Horizontal\x0a    , tabs:\x0a        [ NormalTab\x0a            { children: [ R.text \"Contents of tab 1\" ]\x0a            , quantity: Just $ 5\x0a            , title: \"Rakenteet\"\x0a            , id: \"elements\"\x0a            , color: PrimaryBlue\x0a            }\x0a        , NormalTab\x0a            { children: [ R.text \"Contents of tab 2\" ]\x0a            , quantity: Just $ 5\x0a            , title: \"Suoritteet\"\x0a            , id: \"components\"\x0a            , color: PrimaryBlue\x0a            }\x0a        , NormalTab\x0a            { children: [ R.text \"Contents of tab 3\" ]\x0a            , quantity: Just $ 5\x0a            , title: \"Panokset\"\x0a            , id: \"resources\"\x0a            , color: PrimaryBlue\x0a            }\x0a        , NormalTab\x0a            { children: [ R.text \"Contents of tab 4\" ]\x0a            , quantity: Nothing\x0a            , title: \"Muistio\"\x0a            , id: \"memo\"\x0a            , color: PrimaryBlue\x0a            }\x0a        ]\x0a    }\x0a]\x0a";
var example1 = "import TacoTabs as TacoTabs\x0a\x0a[ TacoTabs.component\x0a    { orientation: Vertical\x0a    , tabs:\x0a        [ NormalTab\x0a            { children: [ R.text \"Contents of tab 1\" ]\x0a            , quantity: Just $ 5\x0a            , title: \"Rakenteet\"\x0a            , id: \"elements\"\x0a            , color: PrimaryBlue\x0a            }\x0a        , NormalTab\x0a            { children: [ R.text \"Contents of tab 2\" ]\x0a            , quantity: Just $ 5\x0a            , title: \"Suoritteet\"\x0a            , id: \"components\"\x0a            , color: PrimaryBlue\x0a            }\x0a        , NormalTab\x0a            { children: [ R.text \"Contents of tab 3\" ]\x0a            , quantity: Just $ 5\x0a            , title: \"Panokset\"\x0a            , id: \"resources\"\x0a            , color: PrimaryBlue\x0a            }\x0a        , NormalTab\x0a            { children: [ R.text \"Contents of tab 4\" ]\x0a            , quantity: Nothing\x0a            , title: \"Muistio\"\x0a            , id: \"memo\"\x0a            , color: PrimaryBlue\x0a            }\x0a        ]\x0a    }\x0a]\x0a";
var sections = [ {
    title: "Vertical",
    view: [ TacoTabs.component({
        orientation: TacoTabs_Types.Vertical.value,
        hideContents: false,
        tabs: [ new TacoTabs_Types.NormalTab({
            children: [ React_Basic_DOM.text("Contents of tab 1") ],
            quantity: Data_Maybe.Just.create(5),
            title: "Rakenteet",
            id: "elements",
            color: TacoConstants.elementColors.border
        }), new TacoTabs_Types.NormalTab({
            children: [ React_Basic_DOM.text("Contents of tab 2") ],
            quantity: Data_Maybe.Just.create(5),
            title: "Suoritteet",
            id: "components",
            color: TacoConstants.componentColors.border
        }), new TacoTabs_Types.NormalTab({
            children: [ React_Basic_DOM.text("Contents of tab 3") ],
            quantity: Data_Maybe.Just.create(5),
            title: "Panokset",
            id: "resources",
            color: TacoConstants.resourceColors.border
        }), new TacoTabs_Types.NormalTab({
            children: [ React_Basic_DOM.text("Contents of tab 4") ],
            quantity: Data_Maybe.Nothing.value,
            title: "Muistio",
            id: "memo",
            color: TacoConstants.PrimaryBlue.value
        }) ]
    }) ],
    code: example1
}, {
    title: "Horizontal",
    view: [ TacoTabs.component({
        orientation: TacoTabs_Types.Horizontal.value,
        hideContents: false,
        tabs: [ new TacoTabs_Types.NormalTab({
            children: [ React_Basic_DOM.text("Contents of tab 1") ],
            quantity: Data_Maybe.Just.create(5),
            title: "Rakenteet",
            id: "elements",
            color: TacoConstants.PrimaryBlue.value
        }), new TacoTabs_Types.NormalTab({
            children: [ React_Basic_DOM.text("Contents of tab 2") ],
            quantity: Data_Maybe.Just.create(5),
            title: "Suoritteet",
            id: "components",
            color: TacoConstants.PrimaryBlue.value
        }), new TacoTabs_Types.NormalTab({
            children: [ React_Basic_DOM.text("Contents of tab 3") ],
            quantity: Data_Maybe.Just.create(5),
            title: "Panokset",
            id: "resources",
            color: TacoConstants.PrimaryBlue.value
        }), new TacoTabs_Types.NormalTab({
            children: [ React_Basic_DOM.text("Contents of tab 4") ],
            quantity: Data_Maybe.Nothing.value,
            title: "Muistio",
            id: "memo",
            color: TacoConstants.PrimaryBlue.value
        }) ]
    }) ],
    code: example2
}, {
    title: "Tab button example",
    view: [ TacoTabs.component({
        orientation: TacoTabs_Types.Horizontal.value,
        hideContents: false,
        tabs: [ new TacoTabs_Types.NormalTab({
            children: [ React_Basic_DOM.text("Contents of tab 1") ],
            quantity: Data_Maybe.Just.create(5),
            title: "Rakenteet",
            id: "elements",
            color: TacoConstants.PrimaryBlue.value
        }), new TacoTabs_Types.NormalTab({
            children: [ React_Basic_DOM.text("Contents of tab 2") ],
            quantity: Data_Maybe.Just.create(5),
            title: "Suoritteet",
            id: "components",
            color: TacoConstants.PrimaryBlue.value
        }), new TacoTabs_Types.NormalTab({
            children: [ React_Basic_DOM.text("Contents of tab 3") ],
            quantity: Data_Maybe.Just.create(5),
            title: "Panokset",
            id: "resources",
            color: TacoConstants.PrimaryBlue.value
        }), new TacoTabs_Types.ButtonTab({
            title: "Click me",
            id: "button",
            onClick: Web_HTML_Window.alert("Hello world")(HtmlUtils.window),
            icon: Data_Maybe.Nothing.value,
            color: TacoConstants.PrimaryBlue.value
        }) ]
    }) ],
    code: example3
} ];
var demoView = {
    sections: sections,
    title: "TacoTabs",
    description: "Lists given tabs and shows contents of the active tab. A special ButtonTab can be used for triggering arbitrary Effects."
};
module.exports = {
    demoView: demoView
};
