// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Component } from "react";

const echarts = window.echarts;

class Chart extends Component {
  render() {
    return (
      <div
        ref={(node) => (this.node = node)}
        style={{ height: this.props.height }}
        className="bar-chart"
      />
    );
  }

  componentDidUpdate() {
    this.updateChartOptions();
    this.chart.resize();
  }

  componentDidMount() {
    this.chart = echarts.init(this.node, "light");
    this.updateChartOptions();
  }

  updateChartOptions() {
    const { grid, xAxis, yAxis, series, legend, color, tooltip } = this.props;
    this.chart.setOption(
      {
        title: this.prepareTitle(),
        color,
        legend: legend || this.prepareLegend(),
        tooltip,
        grid: grid,
        xAxis: xAxis,
        yAxis: yAxis,
        series: series,
      },
      true
    );
  }

  prepareTitle() {
    const { title } = this.props;
    return {
      show: true,
      text: title,
    };
  }

  prepareLegend() {
    const { data } = this.props;
    return {
      data: data.map((d) => d.name),
      selectedMode: false,
      right: "0",
      top: "50px",
      orient: "vertical",
    };
  }
}

export default Chart;
