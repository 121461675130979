// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Grid = require("../Grid/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var example1 = "child :: RB.JSX\x0achild = box \"child\" [ Box.BackgroundColor GrayLight, Box.Border \"1px solid gray\", Box.Height XL ] []\x0a\x0agrid []\x0a  [ [ Flex 2 child, Flex 2 child, Flex 2 child, Flex 2 child, Flex 2 child  ]\x0a  , [ Flex 3 child, Flex 3 child, Flex 3 child, Flex 3 child ]\x0a  , [ Flex 4 child, Flex 4 child, Flex 4 child ]\x0a  , [ Flex 6 child, Flex 6 child ]\x0a  , [ Flex 12 child ]\x0a  ]\x0a";
var child = Box.box("child")([ new Box.BackgroundColor(TacoConstants.GrayLight.value), Box.Border.create(new TacoConstants.BorderCustom(TacoConstants.Gray.value)), new Box.Height(TacoConstants.XL.value) ])([  ]);
var sections = [ {
    title: "Default",
    view: [ Grid.grid([  ])([ [ new Grid.Flex(2, child), new Grid.Flex(2, child), new Grid.Flex(2, child), new Grid.Flex(2, child), new Grid.Flex(2, child) ], [ new Grid.Flex(3, child), new Grid.Flex(3, child), new Grid.Flex(3, child), new Grid.Flex(3, child) ], [ new Grid.Flex(4, child), new Grid.Flex(4, child), new Grid.Flex(4, child) ], [ new Grid.Flex(6, child), new Grid.Flex(6, child) ], [ new Grid.Flex(12, child) ] ]) ],
    code: example1
} ];
var demoView = {
    sections: sections,
    title: "Grid",
    description: "Convenient grid wrapper"
};
module.exports = {
    demoView: demoView
};
