// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Actions = require("../Actions/index.js");
var AddProjectBtn = require("../AddProjectBtn/index.js");
var Client_WASM = require("../Client.WASM/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Controls = require("../Controls/index.js");
var CopyProjectButton = require("../CopyProjectButton/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Show = require("../Data.Show/index.js");
var EstimationProjectsContainer_Styles = require("../EstimationProjectsContainer.Styles/index.js");
var EstimationUtils = require("../EstimationUtils/index.js");
var FeatureHooks = require("../FeatureHooks/index.js");
var FoldingTable_Filter = require("../FoldingTable.Filter/index.js");
var NarrowLayout = require("../NarrowLayout/index.js");
var NoProjectsFoundMsg = require("../NoProjectsFoundMsg/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoLoader = require("../TacoLoader/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_FrontEndRoutes = require("../Types.FrontEndRoutes/index.js");
var Types_Project = require("../Types.Project/index.js");
var UseLocalStorage = require("../UseLocalStorage/index.js");
var Utils = require("../Utils/index.js");
var ByCode = (function () {
    function ByCode() {

    };
    ByCode.value = new ByCode();
    return ByCode;
})();
var ByName = (function () {
    function ByName() {

    };
    ByName.value = new ByName();
    return ByName;
})();
var ByVersion = (function () {
    function ByVersion() {

    };
    ByVersion.value = new ByVersion();
    return ByVersion;
})();
var ByDate = (function () {
    function ByDate() {

    };
    ByDate.value = new ByDate();
    return ByDate;
})();
var ByState = (function () {
    function ByState() {

    };
    ByState.value = new ByState();
    return ByState;
})();
var ByProjectGroup = (function () {
    function ByProjectGroup() {

    };
    ByProjectGroup.value = new ByProjectGroup();
    return ByProjectGroup;
})();
var styleSet = TacoTable_Styles.foldingTableStyleSet;
var sortProject = function (v) {
    return function (r) {
        if (v instanceof ByCode) {
            return TacoTable_Types.StringValue.create(Data_Newtype.un()(Types_Project.ProjectCode)(r.code));
        };
        if (v instanceof ByName) {
            return new TacoTable_Types.StringValue(r.name);
        };
        if (v instanceof ByVersion) {
            return new TacoTable_Types.StringValue(r.version);
        };
        if (v instanceof ByDate) {
            return TacoTable_Types.JSDateValue.create(Utils.lossyParseJSDate(Utils.defaultNull("")(r.createdDate)));
        };
        if (v instanceof ByProjectGroup) {
            return TacoTable_Types.StringValue.create(Utils.defaultNull("")(r.projectGroup));
        };
        if (v instanceof ByState) {
            return new TacoTable_Types.IntValue(r.programType);
        };
        throw new Error("Failed pattern match at EstimationProjectsContainer (line 155, column 1 - line 155, column 78): " + [ v.constructor.name, r.constructor.name ]);
    };
};
var projectColumns = (function () {
    var programTypeToText = Control_Bind.bind(Control_Bind.bindFn)(function (v) {
        return v.programType;
    })(function (programType) {
        return Control_Bind.bind(Control_Bind.bindFn)(function (v) {
            return v.isReportingProject;
        })(function (isReportingProject) {
            if (isReportingProject) {
                return Control_Applicative.pure(Control_Applicative.applicativeFn)("Raportointi");
            };
            var $19 = EstimationUtils.programTypeIsProductionPlanning(programType);
            if ($19) {
                return Control_Applicative.pure(Control_Applicative.applicativeFn)("Tuotannonsuunnittelu");
            };
            return Control_Applicative.pure(Control_Applicative.applicativeFn)("Laskenta");
        });
    });
    var createdDate = TacoTable_Types.PlainDateStringCell.create((function () {
        var $36 = Utils.defaultNull("");
        return function ($37) {
            return $36((function (v) {
                return v.createdDate;
            })($37));
        };
    })());
    return [ {
        key: "code",
        label: React_Basic_DOM.text("Tunnus"),
        cell: TacoTable_Types.PlainTextCell.create((function () {
            var $38 = Data_Newtype.un()(Types_Project.ProjectCode);
            return function ($39) {
                return $38((function (v) {
                    return v.code;
                })($39));
            };
        })()),
        sortProperty: new Data_Maybe.Just(ByCode.value),
        width: new TacoTable_Types.Flex(15),
        headerJSX: Data_Maybe.Nothing.value
    }, {
        key: "name",
        label: React_Basic_DOM.text("Nimi"),
        cell: new TacoTable_Types.PlainTextCell(function (v) {
            return v.name;
        }),
        sortProperty: new Data_Maybe.Just(ByName.value),
        width: new TacoTable_Types.Flex(35),
        headerJSX: Data_Maybe.Nothing.value
    }, {
        key: "version",
        label: React_Basic_DOM.text("Versio"),
        cell: new TacoTable_Types.PlainTextCell(function (v) {
            return v.version;
        }),
        sortProperty: new Data_Maybe.Just(ByVersion.value),
        width: new TacoTable_Types.Flex(5),
        headerJSX: Data_Maybe.Nothing.value
    }, {
        key: "createdDate",
        label: React_Basic_DOM.text("Luotu"),
        cell: createdDate,
        sortProperty: new Data_Maybe.Just(ByDate.value),
        width: new TacoTable_Types.Flex(10),
        headerJSX: Data_Maybe.Nothing.value
    }, {
        key: "projectGroup",
        label: React_Basic_DOM.text("Hankeryhm\xe4"),
        cell: TacoTable_Types.PlainTextCell.create((function () {
            var $40 = Utils.defaultNull("");
            return function ($41) {
                return $40((function (v) {
                    return v.projectGroup;
                })($41));
            };
        })()),
        sortProperty: new Data_Maybe.Just(ByProjectGroup.value),
        width: new TacoTable_Types.Flex(15),
        headerJSX: Data_Maybe.Nothing.value
    }, {
        key: "programType",
        label: React_Basic_DOM.text("Tila"),
        cell: TacoTable_Types.PlainTextCell.create(programTypeToText),
        sortProperty: new Data_Maybe.Just(ByState.value),
        width: new TacoTable_Types.Flex(15),
        headerJSX: Data_Maybe.Nothing.value
    } ];
})();
var mkProjectTableRow = function (dispatch) {
    return function (r) {
        return {
            rowData: r,
            rowKey: "project-" + Data_Show.show(Types_Project.showProjectId)(r.id),
            onClick: function (v) {
                return dispatch(Actions.NavigateRoute.create(new Types_FrontEndRoutes.EstimationFERoute({
                    projectId: r.id,
                    initialSelectedComponent: Data_Maybe.Nothing.value
                })));
            },
            className: "",
            foldingElement: Data_Maybe.Nothing.value,
            columns: projectColumns
        };
    };
};
var getNameProjectTableSortProps = {
    getName: function (v) {
        return "ProjectTableSortProps";
    }
};
var projectsTableInstance = TacoTable.mkTableSortable(getNameProjectTableSortProps)("Projects");
var eqProjectTableSortProps = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ByCode && y instanceof ByCode) {
                return true;
            };
            if (x instanceof ByName && y instanceof ByName) {
                return true;
            };
            if (x instanceof ByVersion && y instanceof ByVersion) {
                return true;
            };
            if (x instanceof ByDate && y instanceof ByDate) {
                return true;
            };
            if (x instanceof ByState && y instanceof ByState) {
                return true;
            };
            if (x instanceof ByProjectGroup && y instanceof ByProjectGroup) {
                return true;
            };
            return false;
        };
    }
};
var mkProjectsTable = function (dispatch) {
    return function (projects) {
        return TacoTable.tableSortable(getNameProjectTableSortProps)(eqProjectTableSortProps)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "version";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "subprojects";
            }
        })(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.eqProjectId))()({
            reflectSymbol: function () {
                return "name";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "id";
            }
        })(Types_Project.eqSubprojectId))()({
            reflectSymbol: function () {
                return "countInMeasurementTotal";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "code";
            }
        })(Data_Eq.eqString)))))()({
            reflectSymbol: function () {
                return "state";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "projectGroup";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "programType";
            }
        })(Data_Eq.eqInt))()({
            reflectSymbol: function () {
                return "name";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "isReportingProject";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "id";
            }
        })(Types_Project.eqProjectId))()({
            reflectSymbol: function () {
                return "currency";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "createdDate";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "code";
            }
        })(Types_Project.eqProjectCode))()({
            reflectSymbol: function () {
                return "applicationMode";
            }
        })(Data_Eq.eqString)))(projectsTableInstance)(new TacoTable_Types.SortingCriteria(TacoTable_Types.Descending.value, new Data_Maybe.Just(ByDate.value), sortProject))(Data_Maybe.Nothing.value)({
            rows: Data_Functor.map(Data_Functor.functorArray)(mkProjectTableRow(dispatch))(projects),
            columns: projectColumns,
            showHeader: true,
            styleSet: styleSet
        });
    };
};
var component = TofuHooks.mkHookComponent("EstimationProjectsContainer")(function (v) {
    return function __do() {
        var appDispatch = StateHooks.useDispatch();
        var v1 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)("new-project-list")(true)(false)();
        var v2 = TofuHooks.useState("")();
        var v3 = Client_WASM.useEstimationProjects();
        var newProjectListEnabled = FeatureHooks.useFeatureEnabled("project-management-aggrid")();
        var projectImportButton = TacoButton.component()()({
            text: "Tuo siirtotiedosto",
            onClick: new Data_Maybe.Just(appDispatch(new Actions.NavigateRoute(Types_FrontEndRoutes.ImportEstimationProjectFERoute.value))),
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconFileUploadV1.value),
            testId: "project-import-button"
        });
        var newProjectListComponent = React_Basic.element($foreign.newProjectList)({});
        var newProjectListContainer = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "new-project-list",
            css: EstimationProjectsContainer_Styles.newProjectTableStyles,
            children: [ newProjectListComponent ]
        });
        var layoutStyle = (function () {
            if (v1.value0) {
                return EstimationProjectsContainer_Styles.newEstimationProjectsContainerStyles;
            };
            return EstimationProjectsContainer_Styles.estimationProjectsContainerStyles;
        })();
        var filteredProjects = FoldingTable_Filter.filterProjectList(v2.value0)(v3.value0);
        var projectList = (function () {
            var $26 = !v3.value1 && Data_Array.length(v3.value0) === 0;
            if ($26) {
                return NoProjectsFoundMsg.noProjectsFoundMsg(appDispatch);
            };
            if (v3.value1) {
                return TacoLoader.component()()({
                    size: "4rem"
                });
            };
            return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                className: "projects-table",
                css: EstimationProjectsContainer_Styles.projectsTableStyles,
                children: [ mkProjectsTable(appDispatch)(filteredProjects) ]
            });
        })();
        var filterInput = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "projects-filter",
            css: EstimationProjectsContainer_Styles.filterStyles,
            children: Control_Applicative.pure(Control_Applicative.applicativeArray)(TacoInput.stringInput()()(Data_Eq.eqString)({
                label: "Hae",
                value: v2.value0,
                onChange: v2.value1,
                clearButton: true,
                testId: "project-search-input"
            }))
        });
        var copyProjectBtn = CopyProjectButton.copyEstimationProjectButton({
            projectList: v3.value0
        });
        var changeViewButtonText = (function () {
            if (v1.value0) {
                return "Vaihda vanhaan hankelistauksen k\xe4ytt\xf6liittym\xe4\xe4n t\xe4st\xe4!";
            };
            return "Vaihda uuteen hankelistauksen k\xe4ytt\xf6liittym\xe4\xe4n t\xe4st\xe4!";
        })();
        var changeViewButton = TacoButton.component()()({
            buttonStyle: TacoButton_Types.Outlined.value,
            className: "flex items-center justify-center p-4 w-full max-w-screen-2xl border border-light bg-slate-100",
            children: new Data_Maybe.Just([ TacoText.component()()({
                text: changeViewButtonText,
                variant: new TacoText_Types.Heading(3)
            }) ]),
            onClick: Data_Maybe.Just.create(v1.value1(function (old) {
                return !old;
            }))
        });
        var addProjectBtn = AddProjectBtn.component({
            mode: AddProjectBtn.AddProjectBtnEstimation.value
        });
        var controls = Controls.component()()({
            leftControls: [ filterInput ],
            rightControls: [ projectImportButton, copyProjectBtn, addProjectBtn ],
            bottomGutter: true
        });
        return NarrowLayout.component()()({
            verticalPadding: true,
            typescriptChildComponent: true,
            fullWidth: (function () {
                if (v1.value0) {
                    return true;
                };
                return false;
            })(),
            children: [ React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                className: "__EstimationProjects",
                css: layoutStyle,
                children: [ Data_Monoid.guard(React_Basic.monoidJSX)(newProjectListEnabled)(changeViewButton), Data_Monoid.guard(React_Basic.monoidJSX)(!v1.value0)(controls), Data_Monoid.guard(React_Basic.monoidJSX)(!v1.value0)(projectList), Data_Monoid.guard(React_Basic.monoidJSX)(v1.value0)(newProjectListContainer) ]
            }) ]
        });
    };
});
module.exports = {
    component: component
};
