// Generated by purs version 0.14.5
"use strict";
var Control_Alt = require("../Control.Alt/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Compactable = require("../Data.Compactable/index.js");
var Data_Filterable = require("../Data.Filterable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var sampleOn = function (dict) {
    return dict.sampleOn;
};
var sampleOn_ = function (dictIsEvent) {
    return function (a) {
        return function (b) {
            return sampleOn(dictIsEvent)(a)(Data_Functor.voidLeft((dictIsEvent.Filterable1()).Functor1())(b)(Control_Category.identity(Control_Category.categoryFn)));
        };
    };
};
var keepLatest = function (dict) {
    return dict.keepLatest;
};
var gateBy = function (dictIsEvent) {
    return function (f) {
        return function (sampled) {
            var $24 = Data_Compactable.compact((dictIsEvent.Filterable1()).Compactable0());
            var $25 = sampleOn(dictIsEvent)(Control_Alt.alt(((dictIsEvent.Alternative0()).Plus1()).Alt0())(Control_Applicative.pure((dictIsEvent.Alternative0()).Applicative0())(Data_Maybe.Nothing.value))(Data_Functor.map((dictIsEvent.Filterable1()).Functor1())(Data_Maybe.Just.create)(sampled)));
            var $26 = Data_Functor.map((dictIsEvent.Filterable1()).Functor1())(function (x) {
                return function (p) {
                    var $14 = f(p)(x);
                    if ($14) {
                        return new Data_Maybe.Just(x);
                    };
                    return Data_Maybe.Nothing.value;
                };
            });
            return function ($27) {
                return $24($25($26($27)));
            };
        };
    };
};
var gate = function (dictIsEvent) {
    return gateBy(dictIsEvent)(function (x) {
        return function (v) {
            return Data_Maybe.fromMaybe(false)(x);
        };
    });
};
var fold = function (dict) {
    return dict.fold;
};
var folded = function (dictIsEvent) {
    return function (dictMonoid) {
        return function (s) {
            return fold(dictIsEvent)(Data_Semigroup.append(dictMonoid.Semigroup0()))(s)(Data_Monoid.mempty(dictMonoid));
        };
    };
};
var mapAccum = function (dictIsEvent) {
    return function (f) {
        return function (xs) {
            return function (acc) {
                return Data_Filterable.filterMap(dictIsEvent.Filterable1())(Data_Tuple.snd)(fold(dictIsEvent)(function (a) {
                    return function (v) {
                        return Data_Functor.map(Data_Tuple.functorTuple)(Control_Applicative.pure(Data_Maybe.applicativeMaybe))(f(a)(v.value0));
                    };
                })(xs)(new Data_Tuple.Tuple(acc, Data_Maybe.Nothing.value)));
            };
        };
    };
};
var withLast = function (dictIsEvent) {
    return function (e) {
        var step = function (a) {
            return function (v) {
                if (v instanceof Data_Maybe.Nothing) {
                    return new Data_Maybe.Just({
                        now: a,
                        last: Data_Maybe.Nothing.value
                    });
                };
                if (v instanceof Data_Maybe.Just) {
                    return new Data_Maybe.Just({
                        now: a,
                        last: new Data_Maybe.Just(v.value0.now)
                    });
                };
                throw new Error("Failed pattern match at FRP.Event.Class (line 51, column 3 - line 51, column 60): " + [ a.constructor.name, v.constructor.name ]);
            };
        };
        return Data_Filterable.filterMap(dictIsEvent.Filterable1())(Control_Category.identity(Control_Category.categoryFn))(fold(dictIsEvent)(step)(e)(Data_Maybe.Nothing.value));
    };
};
var fix = function (dict) {
    return dict.fix;
};
var count = function (dictIsEvent) {
    return function (s) {
        return fold(dictIsEvent)(function (v) {
            return function (n) {
                return n + 1 | 0;
            };
        })(s)(0);
    };
};
module.exports = {
    fold: fold,
    folded: folded,
    count: count,
    mapAccum: mapAccum,
    withLast: withLast,
    sampleOn: sampleOn,
    sampleOn_: sampleOn_,
    keepLatest: keepLatest,
    fix: fix,
    gate: gate,
    gateBy: gateBy,
    filterMap: Data_Filterable.filterMap
};
