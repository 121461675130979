import { useMutation } from "react-query";
import axiosClient from "../../../../axiosConfig";

export type CreateNewGroupData = {
  type: string;
  value: { projectId: number; code: string; description: string };
};

export function useCreateNewGroup(projectId: number) {
  return useMutation({
    mutationFn: async (newGroup: CreateNewGroupData) => {
      const { data } = await axiosClient.post<CreateNewGroupData>(
        `/project/${projectId}/classificationGroupAction`,
        newGroup
      );
      return data;
    },
  });
}
