// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var rangeSeparatorStyles = Box.boxStyle([  ]);
var inputRangeContainerStyles = Box.boxStyle([ Box.GridColumn.value, Box.AlignCenter.value, Box.Style.create(React_Basic_Emotion.css()({
    gridTemplateColumns: React_Basic_Emotion.str("1fr auto 1fr")
})), new Box.Gap(TacoConstants.S.value) ]);
var inputControlRowStyles = Box.boxStyle([ Box.GridColumn.value, Box.Style.create(React_Basic_Emotion.css()({
    gridTemplateColumns: React_Basic_Emotion.str("1fr 2fr 1fr")
})), new Box.Gap(TacoConstants.S.value), new Box.MarginBottom(TacoConstants.XS.value) ]);
var closeButtonStyles = Box.boxStyle([ Box.GridColumn.value, new Box.PaddingY(TacoConstants.M.value) ]);
module.exports = {
    inputControlRowStyles: inputControlRowStyles,
    inputRangeContainerStyles: inputRangeContainerStyles,
    rangeSeparatorStyles: rangeSeparatorStyles,
    closeButtonStyles: closeButtonStyles
};
