import { EstimationProjectSummary } from "../../../../../ts-bindings/EstimationProjectSummary";
import { Button, Modal, Header, Input } from "@tocoman/ui";
import { useForm, RegisterOptions } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useWorkerAction } from "../../../hooks/useWorkerAction";
import { mkCopyProject } from "../../../actions/copyProject";
import { CopyProjectParams } from "../../../../../ts-bindings/CopyProjectParams";
import { ErrorMessage } from "../../../components/ErrorMessage";
import { useCloseModalOnSaveComplete } from "../useChooseNewProjectOnModalClose";
import { useSetNextAvailableVersion } from "../../../hooks/useSetNextAvailableVersion";
import { useRunOnce } from "../../../hooks/useRunOnce";

type Props = {
  isOpen: boolean;
  closeModal: (createdProjectId: number | null) => void;
  projectToCopy: EstimationProjectSummary;
};
export const CopyProjectModal = ({
  isOpen,
  closeModal,
  projectToCopy,
}: Props) => {
  const { t } = useTranslation("projects", { keyPrefix: "copy" });

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<CopyProjectParams>({
    defaultValues: mapProjectToCopyParams(projectToCopy),
    mode: "onBlur",
  });

  const existingValidationErrors = Object.keys(errors).length !== 0;

  const {
    setNextAvailableVersion,
    versionLoading,
  } = useSetNextAvailableVersion(setValue);
  useRunOnce(() => {
    setNextAvailableVersion(projectToCopy.code);
  });

  const mkInputProps = (
    field: keyof CopyProjectParams,
    registerOptions?: RegisterOptions<CopyProjectParams, typeof field>
  ) => ({
    label: t(`form.${field}`),
    ...register(field, registerOptions),
  });

  const [copyStatus, handleCopyProject] = useWorkerAction(
    "CopyProjectUpdateActionState",
    (data: CopyProjectParams) => {
      return mkCopyProject(data);
    }
  );
  useCloseModalOnSaveComplete(closeModal, copyStatus, projectToCopy.id);

  const handleSaveOnClick = async (data: CopyProjectParams) => {
    handleCopyProject(data);
  };

  const handleCancel = () => {
    closeModal(null);
  };

  const actions = (
    <>
      <div className="flex w-full justify-between">
        <Button variant="text" onClick={handleCancel}>{t`cancel`}</Button>
        <Button
          onClick={handleSubmit(handleSaveOnClick)}
          loading={copyStatus === "Pending"}
          disabled={copyStatus === "Pending" || existingValidationErrors}
        >{t`save`}</Button>
      </div>
    </>
  );

  return (
    <Modal
      closeModal={handleCancel}
      isOpen={isOpen}
      width={960}
      loading={versionLoading}
      title={
        <Trans
          ns="projects"
          i18nKey="copy.title"
          values={{ name: projectToCopy.name }}
        />
      }
      actions={actions}
    >
      <form>
        <Header titleSize={"medium"} title={t`newProjectTitle`}></Header>
        <div className={"flex flex-col"}>
          <Input
            {...mkInputProps("name", {
              minLength: {
                value: 3,
                message: t(`errors.tooShort`, { minLength: "3" }),
              },
              maxLength: {
                value: 50,
                message: t(`errors.tooLong`, { maxLength: "50" }),
              },
              required: { value: true, message: t`errors.required` },
            })}
          />
          {errors.name && (
            // Have to use String() with useFormContext for now
            // https://github.com/react-hook-form/react-hook-form/issues/8653
            <ErrorMessage errorMessage={String(errors.name.message)} />
          )}
          <Input
            disabled={versionLoading}
            {...mkInputProps("code", {
              maxLength: {
                value: 12,
                message: t(`errors.tooLong`, { maxLength: "12" }),
              },
              required: { value: true, message: t`errors.required` },
              onBlur: (e) => setNextAvailableVersion(e.target.value),
            })}
          />
          {errors.code && (
            <ErrorMessage errorMessage={String(errors.code.message)} />
          )}
          <Input
            {...mkInputProps("version", {
              minLength: {
                value: 1,
                message: t(`errors.tooShort`, { minLength: "1" }),
              },
              maxLength: {
                value: 3,
                message: t(`errors.tooLong`, { maxLength: "3" }),
              },
              required: { value: true, message: t`errors.required` },
            })}
            testId={"version-input"}
          />
          {errors.version && (
            <ErrorMessage errorMessage={String(errors.version.message)} />
          )}
        </div>
      </form>
    </Modal>
  );
};

function mapProjectToCopyParams(
  project: EstimationProjectSummary
): CopyProjectParams {
  return {
    name: project.name,
    code: project.code,
    sourceProjectId: project.id,
    version: "0",
  };
}
