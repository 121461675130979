import { useDefault } from "../../../hooks/useDefault";
import { useWorkerState } from "../../../hooks/useWorkerState";
import { useMemo } from "react";

export type RoleIds = {
  createProject: number;
  cc: number;
  ce: number;
  ccRead: number;
  ccReadAll: number;
  ccWrite: number;
  ccWriteAll: number;
  ceRead: number;
  ceReadAll: number;
  ceWrite: number;
  ceWriteAll: number;
  costClassTargetEdit: number;
  mainUser: number;
  all: number;
};

export function useRoleIds(): RoleIds {
  const roleIdByName = useDefault(useWorkerState("AdminRoleIdByNameState"), {});

  return useMemo(
    () => ({
      createProject: roleIdByName["projektien luonti"],
      cc: roleIdByName["raportointi"],
      ce: roleIdByName["kustannuslaskenta"],
      ccRead: roleIdByName["raportointi: projektin luku"],
      ccReadAll: roleIdByName["raportointi: kaikkien projektien luku"],
      ccWrite: roleIdByName["raportointi: projektin kirjoitus"],
      ccWriteAll: roleIdByName["raportointi: kaikkien projektien kirjoitus"],
      ceRead: roleIdByName["kustannuslaskenta: projektin luku"],
      ceReadAll: roleIdByName["kustannuslaskenta: kaikkien projektien luku"],
      ceWrite: roleIdByName["kustannuslaskenta: projektin kirjoitus"],
      ceWriteAll:
        roleIdByName["kustannuslaskenta: kaikkien projektien kirjoitus"],
      costClassTargetEdit:
        roleIdByName["litterakohtaisten tavoitteiden muokkaus"],
      mainUser: roleIdByName["pääkäyttäjä"],
      all: roleIdByName["all"],
    }),
    [roleIdByName]
  );
}
