// Generated by purs version 0.14.5
"use strict";
var Control_Alt = require("../Control.Alt/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unfoldable = require("../Data.Unfoldable/index.js");
var Edited = require("../Edited/index.js");
var Effect = require("../Effect/index.js");
var EmptyValidator = require("../EmptyValidator/index.js");
var Formatters = require("../Formatters/index.js");
var NumberValidator = require("../NumberValidator/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var StateHooks = require("../StateHooks/index.js");
var StringUtils = require("../StringUtils/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var ExistingLocation = (function () {
    function ExistingLocation(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    ExistingLocation.create = function (value0) {
        return function (value1) {
            return new ExistingLocation(value0, value1);
        };
    };
    return ExistingLocation;
})();
var NewLocation = (function () {
    function NewLocation(value0) {
        this.value0 = value0;
    };
    NewLocation.create = function (value0) {
        return new NewLocation(value0);
    };
    return NewLocation;
})();
var updateLocationsMatrixCellDescription = function (description) {
    return function (cell) {
        if (cell instanceof NewLocation) {
            return new NewLocation({
                amount: cell.value0.amount,
                description: {
                    orig: cell.value0.description.orig,
                    edited: new Data_Maybe.Just(description)
                },
                code: cell.value0.code
            });
        };
        if (cell instanceof ExistingLocation) {
            return ExistingLocation.create(cell.value0)({
                amount: cell.value1.amount,
                description: {
                    orig: cell.value1.description.orig,
                    edited: new Data_Maybe.Just(description)
                },
                code: cell.value1.code
            });
        };
        throw new Error("Failed pattern match at LocationEditor (line 71, column 61 - line 73, column 99): " + [ cell.constructor.name ]);
    };
};
var updateLocationsMatrixCellAmount = function (amount) {
    return function (cell) {
        if (cell instanceof NewLocation) {
            return new NewLocation({
                amount: amount,
                description: cell.value0.description,
                code: cell.value0.code
            });
        };
        if (cell instanceof ExistingLocation) {
            if (amount instanceof Data_Maybe.Just) {
                return ExistingLocation.create(cell.value0)({
                    amount: new Edited.Edited(amount.value0),
                    description: cell.value1.description,
                    code: cell.value1.code
                });
            };
            if (amount instanceof Data_Maybe.Nothing) {
                return ExistingLocation.create(cell.value0)({
                    amount: Edited.Unedited.value,
                    description: cell.value1.description,
                    code: cell.value1.code
                });
            };
            throw new Error("Failed pattern match at LocationEditor (line 66, column 29 - line 68, column 62): " + [ amount.constructor.name ]);
        };
        throw new Error("Failed pattern match at LocationEditor (line 64, column 51 - line 68, column 62): " + [ cell.constructor.name ]);
    };
};
var locationsMatrixTable = TacoTable.mkTableStateless(TofuHooks.getNameVoid)("LocationsMatrixTable");
var initLocationsMatrixCells = function (dictShow) {
    return function (r) {
        var newLocation = function (unit) {
            return function (v) {
                var key = "code-" + v.code;
                return new Data_Tuple.Tuple(key, {
                    cell: new NewLocation({
                        code: v.code,
                        amount: Data_Maybe.Nothing.value,
                        description: {
                            orig: "",
                            edited: Data_Maybe.Nothing.value
                        }
                    }),
                    locDescription: v.description,
                    unit: unit
                });
            };
        };
        var mkExistingLocation = function (loc) {
            var key = "loc-id" + Data_Show.show(dictShow)(loc.id);
            var cell = new ExistingLocation(loc, {
                code: loc.locationCode,
                amount: Edited.Unedited.value,
                description: {
                    orig: loc.description,
                    edited: Data_Maybe.Nothing.value
                }
            });
            var matrixRow = {
                cell: cell,
                locDescription: loc.description,
                unit: r.unit
            };
            return new Data_Tuple.Tuple(key, matrixRow);
        };
        var isComponentLocation = function (loc) {
            return loc.amountSource === 0;
        };
        var existingLocations = Data_Functor.map(Data_Functor.functorArray)(mkExistingLocation)(Data_Array.filter(isComponentLocation)(r.locations));
        var existingLocationCodes = Data_Set.fromFoldable(Data_Foldable.foldableArray)(Data_Ord.ordString)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.locationCode;
        })(r.locations));
        var isMissing = function (v) {
            return !Data_Set.member(Data_Ord.ordString)(v.code)(existingLocationCodes);
        };
        var newLocations = Data_Functor.map(Data_Functor.functorArray)(newLocation(r.unit))(Data_Array.filter(isMissing)(r.projectLocations));
        var locations = Data_Semigroup.append(Data_Semigroup.semigroupArray)(existingLocations)(newLocations);
        return Data_Map_Internal.fromFoldable(Data_Ord.ordString)(Data_Foldable.foldableArray)(locations);
    };
};
var getLocationsMatrixCellParams = function (v) {
    if (v instanceof NewLocation) {
        return v.value0;
    };
    if (v instanceof ExistingLocation) {
        return {
            amount: Control_Alt.alt(Data_Maybe.altMaybe)(Edited.editedToMaybe(v.value1.amount))(new Data_Maybe.Just(v.value0.amount)),
            description: v.value1.description,
            code: v.value0.locationCode
        };
    };
    throw new Error("Failed pattern match at LocationEditor (line 55, column 1 - line 55, column 153): " + [ v.constructor.name ]);
};
var getDescriptionString = function (r) {
    return Data_Maybe.fromMaybe(r.orig)(r.edited);
};
var fromWasmElementLocation = function (v) {
    return Data_Functor.map(Data_Maybe.functorMaybe)(function (v1) {
        return {
            id: v.elementLocationId,
            amount: v.amount,
            description: v.description,
            locationCode: v.locationCode,
            amountSource: v1
        };
    })(Data_Int.fromNumber(v.amountSourceCode));
};
var fromWasmComponentLocation = function (loc) {
    return {
        id: loc.id,
        amount: loc.amount,
        amountSource: loc.amountSource,
        description: loc.description,
        locationCode: loc.locationCode
    };
};
var eqLocationsMatrixCell = function (dictEq) {
    return {
        eq: function (x) {
            return function (y) {
                if (x instanceof ExistingLocation && y instanceof ExistingLocation) {
                    return x.value0.amount === y.value0.amount && x.value0.amountSource === y.value0.amountSource && x.value0.description === y.value0.description && Data_Eq.eq(dictEq)(x.value0.id)(y.value0.id) && x.value0.locationCode === y.value0.locationCode && (Data_Eq.eq(Edited.eqEdited(Data_Eq.eqNumber))(x.value1.amount)(y.value1.amount) && x.value1.code === y.value1.code && (Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(x.value1.description.edited)(y.value1.description.edited) && x.value1.description.orig === y.value1.description.orig));
                };
                if (x instanceof NewLocation && y instanceof NewLocation) {
                    return Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqNumber))(x.value0.amount)(y.value0.amount) && x.value0.code === y.value0.code && (Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(x.value0.description.edited)(y.value0.description.edited) && x.value0.description.orig === y.value0.description.orig);
                };
                return false;
            };
        }
    };
};
var _locationsMatrixImpl = function (dictShow) {
    return function (dictEq) {
        return function (props) {
            return function __do() {
                var appDispatch = StateHooks.useDispatch();
                var v = TofuHooks.useState(false)();
                var initCells = TofuHooks.useMemo([ ReactHooksUtils.utf(props.locations), ReactHooksUtils.utf(props.projectLocations) ])(function (v1) {
                    return initLocationsMatrixCells(dictShow)({
                        unit: props.unit,
                        locations: props.locations,
                        projectLocations: props.projectLocations
                    });
                })();
                var v1 = TofuHooks.useState(Data_Map_Internal.empty)();
                TofuHooks.useEffect([ ReactHooksUtils.utf(initCells) ])(Control_Apply.applyFirst(Effect.applyEffect)(Data_Monoid.mempty(Effect.monoidEffect(Effect.monoidEffect(Data_Monoid.monoidUnit))))(v1.value1(function (v2) {
                    return initCells;
                })))();
                var modalActions = TacoModal.modalActions({
                    contents: [ TacoButton.component()()({
                        onClick: Data_Maybe.Just.create(props.setShowLocationsMatrixModal(function (v2) {
                            return false;
                        })),
                        text: "Peruuta",
                        testId: "cancel-locations-matrix-button"
                    }), TacoButton.component()()({
                        text: "Tallenna",
                        disabled: v.value0,
                        onClick: Data_Maybe.Just.create(props.saveLocationMatrixChanges(v.value1)(appDispatch)(v1.value0)),
                        testId: "submit-locations-matrix-button"
                    }) ]
                });
                var mkInput = function (r) {
                    return TacoInput.stringInput()()(Data_Eq.eqString)({
                        value: r.value,
                        align: TacoInput.RightAlign.value,
                        validator: TacoInput.orValueValidity(EmptyValidator.emptyValidator)(NumberValidator.numberValidator),
                        onChange: function (fn) {
                            var newValue$prime = fn(r.value);
                            var newValue = (function () {
                                var $79 = Data_String_Common["null"](newValue$prime);
                                if ($79) {
                                    return Data_Maybe.Nothing.value;
                                };
                                return StringUtils.readStrToNumber(newValue$prime);
                            })();
                            var updateLocationsMatrixCellAmount$prime = function (t) {
                                return Data_Maybe.Just.create({
                                    cell: updateLocationsMatrixCellAmount(newValue)(r.cell),
                                    locDescription: t.locDescription,
                                    unit: t.unit
                                });
                            };
                            return v1.value1(function (s) {
                                return Data_Map_Internal.update(Data_Ord.ordString)(updateLocationsMatrixCellAmount$prime)(r.key)(s);
                            });
                        },
                        testId: "location-" + (r.code + "-value-input")
                    });
                };
                var mkDescriptionInput = function (r) {
                    return TacoInput.stringInput()()(Data_Eq.eqString)({
                        value: getDescriptionString(r.description),
                        onChange: function (fn) {
                            var newValue = fn(r.value);
                            var updateLocationsMatrixCellDescription$prime = function (t) {
                                return Data_Maybe.Just.create({
                                    cell: updateLocationsMatrixCellDescription(newValue)(r.cell),
                                    locDescription: t.locDescription,
                                    unit: t.unit
                                });
                            };
                            return v1.value1(function (s) {
                                return Data_Map_Internal.update(Data_Ord.ordString)(updateLocationsMatrixCellDescription$prime)(r.key)(s);
                            });
                        },
                        testId: "location-" + (r.code + "-description-input")
                    });
                };
                var columns = Data_Functor.map(Data_Functor.functorArray)(TacoTable_Types.Column)([ {
                    key: "code",
                    label: React_Basic_DOM.text("Sijainti"),
                    width: new TacoTable_Types.Flex(2),
                    sortProperty: Data_Maybe.Nothing.value,
                    headerJSX: Data_Maybe.Nothing.value,
                    cell: new TacoTable_Types.PlainTextCell(function (v2) {
                        return v2.code;
                    })
                }, {
                    key: "locDescription",
                    label: React_Basic_DOM.text("Sijainnin Selite"),
                    width: new TacoTable_Types.Flex(2),
                    sortProperty: Data_Maybe.Nothing.value,
                    headerJSX: Data_Maybe.Nothing.value,
                    cell: new TacoTable_Types.PlainTextCell(function (v2) {
                        return v2.locDescription;
                    })
                }, {
                    key: "description",
                    label: React_Basic_DOM.text("Selite"),
                    width: new TacoTable_Types.Flex(2),
                    sortProperty: Data_Maybe.Nothing.value,
                    headerJSX: Data_Maybe.Nothing.value,
                    cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], mkDescriptionInput)
                }, {
                    key: "amount",
                    label: React_Basic_DOM.text("Sy\xf6t\xe4 m\xe4\xe4r\xe4"),
                    width: new TacoTable_Types.Flex(2),
                    sortProperty: Data_Maybe.Nothing.value,
                    headerJSX: Data_Maybe.Nothing.value,
                    cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value, TacoTable_Types.Input.value ], mkInput)
                }, {
                    key: "unit",
                    label: React_Basic_DOM.text("Yksikk\xf6"),
                    width: new TacoTable_Types.Flex(1),
                    sortProperty: Data_Maybe.Nothing.value,
                    headerJSX: Data_Maybe.Nothing.value,
                    cell: new TacoTable_Types.PlainTextCell(function (v2) {
                        return v2.unit;
                    })
                } ]);
                var mkRow = function (v2) {
                    var params = getLocationsMatrixCellParams(v2.value1.cell);
                    var value = Data_Maybe.maybe("")(Formatters.formatDecimalValue(1))(params.amount);
                    var rowData = {
                        code: params.code,
                        locDescription: v2.value1.locDescription,
                        unit: v2.value1.unit,
                        value: value,
                        description: params.description,
                        cell: v2.value1.cell,
                        key: v2.value0
                    };
                    return {
                        rowData: rowData,
                        columns: columns,
                        rowKey: params.code,
                        onClick: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit))),
                        className: "",
                        foldingElement: Data_Maybe.Nothing.value
                    };
                };
                var rows = Data_Functor.map(Data_Functor.functorArray)(mkRow)(Data_Map_Internal.toUnfoldable(Data_Unfoldable.unfoldableArray)(v1.value0));
                var table = TacoTable.tableStateless(TofuHooks.getNameVoid)(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "value";
                    }
                })(Data_Eq.eqString))()({
                    reflectSymbol: function () {
                        return "unit";
                    }
                })(Data_Eq.eqString))()({
                    reflectSymbol: function () {
                        return "locDescription";
                    }
                })(Data_Eq.eqString))()({
                    reflectSymbol: function () {
                        return "key";
                    }
                })(Data_Eq.eqString))()({
                    reflectSymbol: function () {
                        return "description";
                    }
                })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "orig";
                    }
                })(Data_Eq.eqString))()({
                    reflectSymbol: function () {
                        return "edited";
                    }
                })(Data_Maybe.eqMaybe(Data_Eq.eqString)))))()({
                    reflectSymbol: function () {
                        return "code";
                    }
                })(Data_Eq.eqString))()({
                    reflectSymbol: function () {
                        return "cell";
                    }
                })(eqLocationsMatrixCell(dictEq))))(locationsMatrixTable)({
                    sortProperty: Data_Maybe.Nothing.value,
                    onSort: Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit))),
                    sortingOrder: TacoTable_Types.Ascending.value,
                    rows: rows,
                    columns: columns,
                    showHeader: true,
                    styleSet: TacoTable_Styles.foldingTableStyleSet,
                    onResize: Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit)))),
                    columnSizeOffsets: Data_Maybe.Nothing.value
                });
                var modalBody = TacoModal.modalBody({
                    contents: [ table ]
                });
                return TacoModal.component()()({
                    isActive: true,
                    onClose: props.setShowLocationsMatrixModal(function (v2) {
                        return false;
                    }),
                    heading: "Lis\xe4\xe4 tai muokkaa montaa sijaintirivi\xe4",
                    contents: [ modalBody, modalActions ]
                });
            };
        };
    };
};
var locationsMatrix = function (dictShow) {
    return function (dictEq) {
        return TofuHooks.mkHookComponent("EstimationLocationsMatrix")(_locationsMatrixImpl(dictShow)(dictEq));
    };
};
module.exports = {
    getDescriptionString: getDescriptionString,
    ExistingLocation: ExistingLocation,
    NewLocation: NewLocation,
    getLocationsMatrixCellParams: getLocationsMatrixCellParams,
    updateLocationsMatrixCellAmount: updateLocationsMatrixCellAmount,
    updateLocationsMatrixCellDescription: updateLocationsMatrixCellDescription,
    initLocationsMatrixCells: initLocationsMatrixCells,
    locationsMatrix: locationsMatrix,
    "_locationsMatrixImpl": _locationsMatrixImpl,
    locationsMatrixTable: locationsMatrixTable,
    fromWasmElementLocation: fromWasmElementLocation,
    fromWasmComponentLocation: fromWasmComponentLocation,
    eqLocationsMatrixCell: eqLocationsMatrixCell
};
