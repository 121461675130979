import { OrganizationPricelistStatus, PricelistStatus } from "@prisma/client";
import { OrgWithAdminetTenants } from "src/server-ts/super-admin/organizations/SuperAdminOrganizationQueries";
import { useGetOrganizations } from "../Tabs/useSuperAdminOrganizations";
import { useQueryPricingUpdateStatus } from "../usePricingUpdate";

export type OrganizationStatus = {
  organizationName: string;
  status: PricelistStatus | "NO_UPDATES_DONE";
  successfulAt: Date | null;
};

/**
 * Hook to get organizations that have pricelist updates enabled
 * @param updateStarted boolean to determine if we need to start polling for ongoing updates
 * @returns OrganizationStatus[] organizations with pricelist updates enabled
 */
export function usePricelistOrganizations(
  updateStarted: boolean
): OrganizationStatus[] {
  const organizations = useGetOrganizations();
  const orgsUpdateStatus = useQueryPricingUpdateStatus(updateStarted);
  return getPricelistUpdateOrgs(
    organizations ?? [],
    orgsUpdateStatus.data ?? []
  );
}

/**
 * Filter and map organizations that have pricelist updates enabled
 */
export function getPricelistUpdateOrgs(
  organizations: OrgWithAdminetTenants[],
  orgStatus: OrganizationPricelistStatus[]
): OrganizationStatus[] {
  const pricelistUpdateOrgs = organizations?.filter(
    (org) => org.priceListUpdate
  );
  // Organizations that have pricelist updates enabled but have not been updated have no db entries for status
  const noUpdatesDoneOrgs = pricelistUpdateOrgs?.filter((org) => {
    return !orgStatus.find((status) => status.organizationName === org.name);
  });
  const noUpdatesDoneOrgsStatus: OrganizationStatus[] =
    noUpdatesDoneOrgs?.map((org) => {
      return {
        organizationName: org.name,
        status: "NO_UPDATES_DONE",
        successfulAt: null,
      };
    }) ?? [];

  const organizationsWithStatus: OrganizationStatus[] =
    orgStatus.map((status) => {
      return {
        organizationName: status.organizationName,
        status: status.status,
        successfulAt: status.successfulAt,
      };
    }) ?? [];

  return [...organizationsWithStatus, ...noUpdatesDoneOrgsStatus];
}
