// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var CostEstimationLayout_Types = require("../CostEstimationLayout.Types/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Show = require("../Data.Show/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var StyleProperties = require("../StyleProperties/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var selectionControlsStyles = Box.boxStyle([ Box.FlexRow.value, Box.AlignCenter.value ]);
var resourceTableHeaderStyleSet = (function () {
    var headerStyles = Box.boxStyle([ Box.FlexRow.value, new Box.Position(Box.Sticky.value), new Box.ZIndex(TacoConstants.zIndexTableHeader), Box.PosTop.create(new TacoConstants.CustomSize("0")), Box.PaddingRight.create(new TacoConstants.CustomSize("1px")) ]);
    var headerCellStyles = function (colWidth) {
        return Box.boxStyle([ Box.Style.create(TacoTable_Styles.defaultHeaderCellStyles(colWidth)), new Box.Selector(".__TacoButton", [ new Box.Padding(TacoConstants.XS.value) ]) ]);
    };
    return {
        headerStyles: headerStyles,
        headerCellStyles: headerCellStyles
    };
})();
var resourceCountWrapperStyles = Box.boxStyle([ Box.FlexRow.value, Box.AlignCenter.value, new Box.FlexGrow(1), new Box.PaddingRight(TacoConstants.L.value) ]);
var indicatorOffset = 16;
var estimationResourcesListStyles = Box.boxStyle([ Box.Height.create(new TacoConstants.CustomSize("100%")), Box.FlexColumn.value ]);
var costEstimationTableStyleSet = function (colors) {
    var tableStyles = React_Basic_Emotion.merge([ TacoTable_Styles.optimizedTableStyles, Box.boxStyle([ new Box.Selector(".table-rows", [ Box.MinHeight.create(new TacoConstants.CustomSize("auto")) ]) ]) ]);
    var rowStyles = function (v) {
        return function (mfs) {
            return function (rowHeight) {
                var styles = Box.boxStyle([ new Box.PaddingX(TacoConstants.S.value), new Box.Selector("&.selected > .row-cells > .cell", [ new Box.BackgroundColor(TacoConstants.GrayLight.value) ]), new Box.Selector("&.topic-component > .row-cells > .cell", [ new Box.FontWeight("bold"), new Box.BackgroundColor(v.background) ]), new Box.Selector("&.dixon-link > .row-cells > .cell", [ new Box.BackgroundColor(v.background) ]), new Box.Selector("&.selected", [ new Box.BackgroundColor(TacoConstants.GrayLight.value), new Box.ZIndex(TacoConstants.zIndexSelectedRow), new Box.Position(Box.Relative.value), new Box.Selector("&:after", [ new Box.BackgroundColor(v.border), new Box.Position(Box.Absolute.value), Box.PosTop.create(new TacoConstants.CustomSize("0px")), Box.PosLeft.create(new TacoConstants.CustomSize("0px")), Box.Height.create(new TacoConstants.CustomSize("100%")), new Box.Width(TacoConstants.XS.value), Box.Style.create(React_Basic_Emotion.css()({
                    content: React_Basic_Emotion.str("' '")
                })) ]) ]), new Box.Selector("&:not(.selected) > .row-cells > .cell", [ new Box.Hover([ new Box.BackgroundColor(TacoConstants.GrayLight.value) ]) ]), new Box.Selector("&:not(.selected)", [ new Box.Hover([ new Box.BackgroundColor(TacoConstants.GrayLight.value) ]), new Box.Selector("&:not(.selected) .__TacoInput .input-container", [ new Box.Border(TacoConstants.BorderTransparent.value), new Box.BackgroundColor(TacoConstants.Transparent.value), new Box.Selector("input", [ new Box.Disabled([ new Box.BackgroundColor(TacoConstants.Transparent.value) ]) ]) ]), new Box.Selector("&:not(.selected) .__TacoSelect .input-container", [ new Box.Border(TacoConstants.BorderTransparent.value), new Box.BackgroundColor(TacoConstants.Transparent.value) ]), new Box.Selector("&:not(.selected) .__TacoSelect .input-container .arrow", [ new Box.Display(Box.None.value) ]) ]) ]);
                return React_Basic_Emotion.merge([ TacoTable_Styles.optimizedTableRowStyles(mfs)(rowHeight), styles ]);
            };
        };
    };
    var headerCellStyles = function (colWidth) {
        return Box.boxStyle([ Box.Style.create(TacoTable_Styles.defaultHeaderCellStyles(colWidth)), new Box.Selector(".__TacoButton", [ new Box.Padding(TacoConstants.XS.value) ]) ]);
    };
    var cellStyleSet = function (v) {
        var cellValueStyles = function (cell) {
            return function (rowHeight) {
                return Box.boxStyle([ Box.Style.create(TacoTable_Styles.optimizedTableCellValueStyles(cell)(rowHeight)), Box.PaddingX.create((function () {
                    if (cell instanceof TacoTable_Types.JSXCell && Data_Foldable.elem(Data_Foldable.foldableArray)(TacoTable_Types.eqJSXCellProp)(TacoTable_Types.Input.value)(cell.value0)) {
                        return new TacoConstants.CustomSize("0");
                    };
                    return TacoConstants.XS.value;
                })()) ]);
            };
        };
        return {
            cellStyles: TacoTable_Styles.optimizedTableCellStyles,
            cellValueStyles: cellValueStyles
        };
    };
    var rowStyleSet = function (c) {
        return {
            rowStyles: rowStyles(c),
            rowCellsContainerStyles: TacoTable_Styles.optimizedTableRowCellsContainerStyles,
            cellStyleSet: cellStyleSet(c)
        };
    };
    var ceHeaderStyles = Box.boxStyle([ new Box.PaddingX(TacoConstants.S.value) ]);
    var headerStyles = React_Basic_Emotion.merge([ TacoTable_Styles.defaultHeaderStyles, ceHeaderStyles ]);
    var headerStyleSet = {
        headerStyles: headerStyles,
        headerCellStyles: headerCellStyles
    };
    return {
        tableStyles: tableStyles,
        headerStyleSet: headerStyleSet,
        rowStyleSet: rowStyleSet(colors)
    };
};
var estimationResourceListContentsStyles = (function (v) {
    return v.tableStyles;
})(costEstimationTableStyleSet(TacoConstants.resourceColors));
var estimationResourceListHeaderStyles = React_Basic_Emotion.merge([ (function (v) {
    return v.headerStyleSet.headerStyles;
})(costEstimationTableStyleSet(TacoConstants.resourceColors)), Box.boxStyle([ Box.MinHeight.create(new TacoConstants.CustomSize("auto")), Box.FlexColumn.value, Box.PaddingLeft.create(new TacoConstants.CustomSize("0px")) ]) ]);
var costEstimationDetailsContainerStyles = function (v) {
    return Box.boxStyle([ Data_Monoid.guard(Data_Monoid.monoidFn(Box.monoidBoxProp))(Data_Eq.notEq(CostEstimationLayout_Types.eqPanelMode)(v.panelMode)(CostEstimationLayout_Types.MinimizedMode.value))(Box.FlexGrow.create)((function () {
        if (v.panelMode instanceof CostEstimationLayout_Types.MinimizedMode) {
            return 0;
        };
        if (v.panelMode instanceof CostEstimationLayout_Types.SmallMode) {
            return 1;
        };
        if (v.panelMode instanceof CostEstimationLayout_Types.NormalMode) {
            return 1;
        };
        if (v.panelMode instanceof CostEstimationLayout_Types.MaximizedMode) {
            return 3;
        };
        throw new Error("Failed pattern match at CostEstimation.Styles (line 293, column 49 - line 297, column 25): " + [ v.panelMode.constructor.name ]);
    })()), Box.Width.create(new TacoConstants.CustomSize("100%")), Box.FlexColumn.value ]);
};
var costClassResourceTableStyles = function (v) {
    return Box.boxStyle([ Data_Monoid.guard(Box.monoidBoxProp)(v.hasItems)(new Box.MarginBottom(TacoConstants.M.value)), Box.PaddingRight.create(new TacoConstants.CustomSize("1px")), Box.MinHeight.create(new TacoConstants.CustomSize("auto")), Box.Style.create(React_Basic_Emotion.css()({
        borderLeft: StyleProperties.border(TacoConstants.BorderTransparent.value),
        borderLeftWidth: React_Basic_Emotion.str("6px")
    })), Data_Monoid.guard(Box.monoidBoxProp)(!v.unused)(Box.Style.create(React_Basic_Emotion.css()({
        borderLeft: StyleProperties.border(TacoConstants.BorderCustom.create(TacoConstants.resourceColors.border)),
        borderLeftWidth: React_Basic_Emotion.str("6px")
    }))) ]);
};
var costClassResourceRowsStyles = Box.boxStyle([ new Box.Selector(".row:hover + &", [ new Box.BackgroundColor(TacoConstants.GrayLight.value) ]) ]);
var costClassResourceCellStyleSet = function (v) {
    return function (v1) {
        var cellValueStyles = function (cell) {
            return function (v2) {
                var padding = (function () {
                    if (cell instanceof TacoTable_Types.JSXCell && Data_Foldable.elem(Data_Foldable.foldableArray)(TacoTable_Types.eqJSXCellProp)(TacoTable_Types.Input.value)(cell.value0)) {
                        return new TacoConstants.CustomSize("0.2rem 0 0 0");
                    };
                    return TacoConstants.XS.value;
                })();
                var numeric = TacoTable_Styles.isCellNumeric(cell);
                return Box.boxStyle([ new Box.Padding(padding), Box.Height.create(new TacoConstants.CustomSize("100%")), Box.FlexRow.value, Box.AlignCenter.value, Box.Width.create(new TacoConstants.CustomSize("100%")), new Box.Overflow(Box.Hidden.value, Box.Hidden.value), new Box.Display(Box.Block.value), Box.Style.create(React_Basic_Emotion.css()({
                    whiteSpace: React_Basic_Emotion.str("nowrap"),
                    textOverflow: React_Basic_Emotion.str("ellipsis")
                })), Data_Monoid.guard(Box.monoidBoxProp)(numeric)(Box.Style.create(React_Basic_Emotion.css()({
                    justifyContent: React_Basic_Emotion.str("flex-end"),
                    textAlign: React_Basic_Emotion.str("right")
                }))) ]);
            };
        };
        var cellStyles = function (colWidth) {
            return React_Basic_Emotion.merge([ TacoTable_Styles.defaultCellStyles(colWidth), Box.boxStyle([ Box.Height.create(TacoConstants.CustomSize.create(Data_Show.show(Data_Show.showInt)(TacoTable_Types.rowHeightPixels(TacoTable_Types.RowMedium.value)) + "px")) ]) ]);
        };
        return {
            cellStyles: cellStyles,
            cellValueStyles: cellValueStyles
        };
    };
};
var costClassResourceRowStyleSet = function (props) {
    var rowStyles = function (v) {
        return function (rowHeight) {
            return Box.boxStyle([ Box.FlexColumn.value, Box.JustifyCenter.value, Box.MinHeight.create(TacoTable_Types.rowHeightToSize(rowHeight)), Box.Style.create(React_Basic_Emotion.css()({
                boxSizing: React_Basic_Emotion.str("content-box")
            })), Box.Style.create(React_Basic_Emotion.css()({
                borderTop: React_Basic_Emotion["int"](0)
            })), new Box.Hover([ new Box.BackgroundColor(TacoConstants.GrayLight.value) ]) ]);
        };
    };
    var rowCellsContainerStyles = function (foldingState) {
        return React_Basic_Emotion.merge([ TacoTable_Styles.defaultRowCellsContainerStyles(foldingState), Box.boxStyle([ new Box.MarginRight(TacoConstants.S.value), Box.AlignCenter.value ]) ]);
    };
    return {
        rowStyles: rowStyles,
        rowCellsContainerStyles: rowCellsContainerStyles,
        cellStyleSet: costClassResourceCellStyleSet(false)(props)
    };
};
var costClassSummaryRowStyleSet = function (props) {
    var rowStyles = function (mfs) {
        return function (rowHeight) {
            return React_Basic_Emotion.merge([ TacoTable_Styles.defaultRowStyles(mfs)(rowHeight), Box.boxStyle([ Box.Style.create(React_Basic_Emotion.css()({
                borderTop: React_Basic_Emotion["int"](0),
                borderLeft: React_Basic_Emotion["int"](0)
            })), Box.Style.create(React_Basic_Emotion.css()({
                boxSizing: React_Basic_Emotion.str("content-box")
            })), new Box.Hover([ new Box.BackgroundColor(TacoConstants.GrayLight.value) ]) ]) ]);
        };
    };
    var rowCellsContainerStyles = function (foldingState) {
        return React_Basic_Emotion.merge([ TacoTable_Styles.defaultRowCellsContainerStyles(foldingState), Box.boxStyle([ new Box.MarginRight(TacoConstants.S.value), Box.AlignCenter.value ]) ]);
    };
    return {
        rowStyles: rowStyles,
        rowCellsContainerStyles: rowCellsContainerStyles,
        cellStyleSet: costClassResourceCellStyleSet(true)(props)
    };
};
var changeCostClassSelectStyles = Box.boxStyle([ new Box.Width(new TacoConstants.CustomSize("12rem")) ]);
var borderSize = 1;
module.exports = {
    borderSize: borderSize,
    indicatorOffset: indicatorOffset,
    costEstimationTableStyleSet: costEstimationTableStyleSet,
    estimationResourcesListStyles: estimationResourcesListStyles,
    resourceTableHeaderStyleSet: resourceTableHeaderStyleSet,
    resourceCountWrapperStyles: resourceCountWrapperStyles,
    costClassSummaryRowStyleSet: costClassSummaryRowStyleSet,
    costClassResourceTableStyles: costClassResourceTableStyles,
    estimationResourceListContentsStyles: estimationResourceListContentsStyles,
    estimationResourceListHeaderStyles: estimationResourceListHeaderStyles,
    costClassResourceRowsStyles: costClassResourceRowsStyles,
    costClassResourceRowStyleSet: costClassResourceRowStyleSet,
    costClassResourceCellStyleSet: costClassResourceCellStyleSet,
    selectionControlsStyles: selectionControlsStyles,
    changeCostClassSelectStyles: changeCostClassSelectStyles,
    costEstimationDetailsContainerStyles: costEstimationDetailsContainerStyles
};
