// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var ButtonWithConfirmation = require("../ButtonWithConfirmation/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Show = require("../Data.Show/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoModal = require("../TacoModal/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var component = TofuHooks.mkHookComponent("DeleteUsers")(function (v) {
    return function __do() {
        var doDelete = TofuHooks.useCallback([ v.selectedUsers ])(function __do() {
            v.appDispatch(new Actions.DeleteSuperAdminUsersRequest({
                userIds: Data_Functor.map(Data_Functor.functorArray)(function (v1) {
                    return v1.id;
                })(v.selectedUsers)
            }))();
            return v.setSelectedUsers(function (v1) {
                return [  ];
            })();
        })();
        var showDeleteButton = Data_Array.length(v.selectedUsers) > 0;
        var modalHeading = "Haluatko varmasti poistaa " + (Data_Show.show(Data_Show.showInt)(Data_Array.length(v.selectedUsers)) + " k\xe4ytt\xe4j\xe4\xe4?");
        var modalBody = TacoModal.modalBody({
            contents: [ Box.box("deleteConfirmation")([ new Box.MarginBottom(TacoConstants.M.value) ])([ React_Basic_DOM.text("Olet poistamassa seuraavat k\xe4ytt\xe4j\xe4t:") ]), Box.box("selectedUsers")([  ])([ React_Basic_DOM.text(Data_Array.intercalate(Data_Monoid.monoidString)(", ")(Data_Functor.map(Data_Functor.functorArray)(function (v1) {
                return v1.userData.email;
            })(v.selectedUsers))) ]) ]
        });
        return Box.box("deleteUsersWrapper")([ new Box.Display(Box.Inline.value) ])([ Data_Monoid.guard(React_Basic.monoidJSX)(showDeleteButton)(ButtonWithConfirmation.component()()({
            buttonText: "Poista valitut k\xe4ytt\xe4j\xe4t",
            confirmHeadingText: modalHeading,
            confirmBody: modalBody,
            confirmButtonText: "Poista k\xe4ytt\xe4j\xe4t",
            testIdPrefix: "delete-users",
            onAction: doDelete
        })) ]);
    };
});
module.exports = {
    component: component
};
