// Generated by purs version 0.14.5
"use strict";
var Calculation_Portfolio = require("../Calculation.Portfolio/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_JSDate = require("../Data.JSDate/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Locale = require("../Locale/index.js");
var PaginationControls = require("../PaginationControls/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var Record = require("../Record/index.js");
var TacoLoader = require("../TacoLoader/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Classification = require("../Types.Classification/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Project = require("../Types.Project/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var Utils = require("../Utils/index.js");
var ByCode = (function () {
    function ByCode() {

    };
    ByCode.value = new ByCode();
    return ByCode;
})();
var ByName = (function () {
    function ByName() {

    };
    ByName.value = new ByName();
    return ByName;
})();
var ByVersion = (function () {
    function ByVersion() {

    };
    ByVersion.value = new ByVersion();
    return ByVersion;
})();
var ByProjectManager = (function () {
    function ByProjectManager() {

    };
    ByProjectManager.value = new ByProjectManager();
    return ByProjectManager;
})();
var ByTargetCosts = (function () {
    function ByTargetCosts() {

    };
    ByTargetCosts.value = new ByTargetCosts();
    return ByTargetCosts;
})();
var ByEstimatedCosts = (function () {
    function ByEstimatedCosts() {

    };
    ByEstimatedCosts.value = new ByEstimatedCosts();
    return ByEstimatedCosts;
})();
var ByActualCosts = (function () {
    function ByActualCosts() {

    };
    ByActualCosts.value = new ByActualCosts();
    return ByActualCosts;
})();
var ByTargetIncome = (function () {
    function ByTargetIncome() {

    };
    ByTargetIncome.value = new ByTargetIncome();
    return ByTargetIncome;
})();
var ByEstimatedIncome = (function () {
    function ByEstimatedIncome() {

    };
    ByEstimatedIncome.value = new ByEstimatedIncome();
    return ByEstimatedIncome;
})();
var ByActualIncome = (function () {
    function ByActualIncome() {

    };
    ByActualIncome.value = new ByActualIncome();
    return ByActualIncome;
})();
var ByActualIncomeApproved = (function () {
    function ByActualIncomeApproved() {

    };
    ByActualIncomeApproved.value = new ByActualIncomeApproved();
    return ByActualIncomeApproved;
})();
var ByTargetDiffAndMargin = (function () {
    function ByTargetDiffAndMargin() {

    };
    ByTargetDiffAndMargin.value = new ByTargetDiffAndMargin();
    return ByTargetDiffAndMargin;
})();
var ByActualDiffAndMargin = (function () {
    function ByActualDiffAndMargin() {

    };
    ByActualDiffAndMargin.value = new ByActualDiffAndMargin();
    return ByActualDiffAndMargin;
})();
var ByActualDiffAndMarginApproved = (function () {
    function ByActualDiffAndMarginApproved() {

    };
    ByActualDiffAndMarginApproved.value = new ByActualDiffAndMarginApproved();
    return ByActualDiffAndMarginApproved;
})();
var ByEstimatedDiffAndMargin = (function () {
    function ByEstimatedDiffAndMargin() {

    };
    ByEstimatedDiffAndMargin.value = new ByEstimatedDiffAndMargin();
    return ByEstimatedDiffAndMargin;
})();
var ByStartDate = (function () {
    function ByStartDate() {

    };
    ByStartDate.value = new ByStartDate();
    return ByStartDate;
})();
var ByEndDate = (function () {
    function ByEndDate() {

    };
    ByEndDate.value = new ByEndDate();
    return ByEndDate;
})();
var ByContractType = (function () {
    function ByContractType() {

    };
    ByContractType.value = new ByContractType();
    return ByContractType;
})();
var ByState = (function () {
    function ByState() {

    };
    ByState.value = new ByState();
    return ByState;
})();
var ByProjectType = (function () {
    function ByProjectType() {

    };
    ByProjectType.value = new ByProjectType();
    return ByProjectType;
})();
var ByFinancingType = (function () {
    function ByFinancingType() {

    };
    ByFinancingType.value = new ByFinancingType();
    return ByFinancingType;
})();
var ByConstructionType = (function () {
    function ByConstructionType() {

    };
    ByConstructionType.value = new ByConstructionType();
    return ByConstructionType;
})();
var ByContractPrice = (function () {
    function ByContractPrice() {

    };
    ByContractPrice.value = new ByContractPrice();
    return ByContractPrice;
})();
var ByTaxRate = (function () {
    function ByTaxRate() {

    };
    ByTaxRate.value = new ByTaxRate();
    return ByTaxRate;
})();
var ByOwnProject = (function () {
    function ByOwnProject() {

    };
    ByOwnProject.value = new ByOwnProject();
    return ByOwnProject;
})();
var ByProjectGroup = (function () {
    function ByProjectGroup() {

    };
    ByProjectGroup.value = new ByProjectGroup();
    return ByProjectGroup;
})();
var ColCode = (function () {
    function ColCode() {

    };
    ColCode.value = new ColCode();
    return ColCode;
})();
var ColName = (function () {
    function ColName() {

    };
    ColName.value = new ColName();
    return ColName;
})();
var ColVersion = (function () {
    function ColVersion() {

    };
    ColVersion.value = new ColVersion();
    return ColVersion;
})();
var ColProjectManager = (function () {
    function ColProjectManager() {

    };
    ColProjectManager.value = new ColProjectManager();
    return ColProjectManager;
})();
var ColTargetCosts = (function () {
    function ColTargetCosts() {

    };
    ColTargetCosts.value = new ColTargetCosts();
    return ColTargetCosts;
})();
var ColEstimatedCosts = (function () {
    function ColEstimatedCosts() {

    };
    ColEstimatedCosts.value = new ColEstimatedCosts();
    return ColEstimatedCosts;
})();
var ColActualCosts = (function () {
    function ColActualCosts() {

    };
    ColActualCosts.value = new ColActualCosts();
    return ColActualCosts;
})();
var ColTargetIncome = (function () {
    function ColTargetIncome() {

    };
    ColTargetIncome.value = new ColTargetIncome();
    return ColTargetIncome;
})();
var ColEstimatedIncome = (function () {
    function ColEstimatedIncome() {

    };
    ColEstimatedIncome.value = new ColEstimatedIncome();
    return ColEstimatedIncome;
})();
var ColActualIncome = (function () {
    function ColActualIncome() {

    };
    ColActualIncome.value = new ColActualIncome();
    return ColActualIncome;
})();
var ColActualIncomeApproved = (function () {
    function ColActualIncomeApproved() {

    };
    ColActualIncomeApproved.value = new ColActualIncomeApproved();
    return ColActualIncomeApproved;
})();
var ColTargetDiffAndMargin = (function () {
    function ColTargetDiffAndMargin() {

    };
    ColTargetDiffAndMargin.value = new ColTargetDiffAndMargin();
    return ColTargetDiffAndMargin;
})();
var ColActualDiffAndMargin = (function () {
    function ColActualDiffAndMargin() {

    };
    ColActualDiffAndMargin.value = new ColActualDiffAndMargin();
    return ColActualDiffAndMargin;
})();
var ColActualDiffAndMarginApproved = (function () {
    function ColActualDiffAndMarginApproved() {

    };
    ColActualDiffAndMarginApproved.value = new ColActualDiffAndMarginApproved();
    return ColActualDiffAndMarginApproved;
})();
var ColEstimatedDiffAndMargin = (function () {
    function ColEstimatedDiffAndMargin() {

    };
    ColEstimatedDiffAndMargin.value = new ColEstimatedDiffAndMargin();
    return ColEstimatedDiffAndMargin;
})();
var ColStartDate = (function () {
    function ColStartDate() {

    };
    ColStartDate.value = new ColStartDate();
    return ColStartDate;
})();
var ColEndDate = (function () {
    function ColEndDate() {

    };
    ColEndDate.value = new ColEndDate();
    return ColEndDate;
})();
var ColContractType = (function () {
    function ColContractType() {

    };
    ColContractType.value = new ColContractType();
    return ColContractType;
})();
var ColState = (function () {
    function ColState() {

    };
    ColState.value = new ColState();
    return ColState;
})();
var ColProjectType = (function () {
    function ColProjectType() {

    };
    ColProjectType.value = new ColProjectType();
    return ColProjectType;
})();
var ColFinancingType = (function () {
    function ColFinancingType() {

    };
    ColFinancingType.value = new ColFinancingType();
    return ColFinancingType;
})();
var ColConstructionType = (function () {
    function ColConstructionType() {

    };
    ColConstructionType.value = new ColConstructionType();
    return ColConstructionType;
})();
var ColContractPrice = (function () {
    function ColContractPrice() {

    };
    ColContractPrice.value = new ColContractPrice();
    return ColContractPrice;
})();
var ColTaxRate = (function () {
    function ColTaxRate() {

    };
    ColTaxRate.value = new ColTaxRate();
    return ColTaxRate;
})();
var ColOwnProject = (function () {
    function ColOwnProject() {

    };
    ColOwnProject.value = new ColOwnProject();
    return ColOwnProject;
})();
var ColProjectGroup = (function () {
    function ColProjectGroup() {

    };
    ColProjectGroup.value = new ColProjectGroup();
    return ColProjectGroup;
})();
var styleSet = TacoTable_Styles.foldingTableStyleSet;
var startDateS = Data_Symbol.SProxy.value;
var portfolioPaginationCount = 1000;
var mkColumn = function (spec) {
    return function (fn) {
        return {
            key: spec.key,
            label: spec.label,
            width: spec.width,
            sortProperty: spec.sortProperty,
            cell: fn(spec.col),
            headerJSX: Data_Maybe.Nothing.value
        };
    };
};
var getRow = function (props) {
    return function (columns) {
        return function (r) {
            return {
                rowData: r,
                className: "__PortfolioTableRow",
                foldingElement: Data_Maybe.Nothing.value,
                rowKey: Data_Show.show(Types_Project.showProjectId)(r.id),
                onClick: function (v) {
                    return props.navigateToProject(r.id);
                },
                columns: columns
            };
        };
    };
};
var getNameSortProperty = {
    getName: function (v) {
        return "SortProperty";
    }
};
var portfolioSummaryTableInstance = TacoTable.mkTableStateless(getNameSortProperty)("PortfolioSummaryTable");
var portfolioTableInstance = TacoTable.mkTableStateless(getNameSortProperty)("PortfolioTable");
var getDisplayColumns = function (props) {
    var filter = function (v) {
        return props.isColumnVisible(v.col);
    };
    return Data_Array.filter(filter)([ {
        col: ColCode.value,
        width: new TacoTable_Types.Flex(16),
        key: "code",
        label: React_Basic_DOM.text(Locale.lookup_("project_code")),
        sortProperty: new Data_Maybe.Just(ByCode.value)
    }, {
        col: ColName.value,
        width: new TacoTable_Types.Flex(30),
        key: "name",
        label: React_Basic_DOM.text(Locale.lookup_("project_name")),
        sortProperty: new Data_Maybe.Just(ByName.value)
    }, {
        col: ColVersion.value,
        width: new TacoTable_Types.Flex(8),
        key: "version",
        label: React_Basic_DOM.text(Locale.lookup_("project_version")),
        sortProperty: new Data_Maybe.Just(ByVersion.value)
    }, {
        col: ColProjectManager.value,
        width: new TacoTable_Types.Flex(20),
        key: "projectManager",
        label: React_Basic_DOM.text(Locale.lookup_("common_project_manager")),
        sortProperty: new Data_Maybe.Just(ByProjectManager.value)
    }, {
        col: ColTargetCosts.value,
        width: new TacoTable_Types.Flex(20),
        key: "targetCosts",
        label: React_Basic_DOM.text(Locale.lookup_("target_costs")),
        sortProperty: new Data_Maybe.Just(ByTargetCosts.value)
    }, {
        col: ColEstimatedCosts.value,
        width: new TacoTable_Types.Flex(20),
        key: "estimatedCosts",
        label: React_Basic_DOM.text(Locale.lookup_("estimate_costs")),
        sortProperty: new Data_Maybe.Just(ByEstimatedCosts.value)
    }, {
        col: ColActualCosts.value,
        width: new TacoTable_Types.Flex(20),
        key: "actualCosts",
        label: React_Basic_DOM.text(Locale.lookup_("actual_costs")),
        sortProperty: new Data_Maybe.Just(ByActualCosts.value)
    }, {
        col: ColTargetIncome.value,
        width: new TacoTable_Types.Flex(20),
        key: "targetIncome",
        label: React_Basic_DOM.text(Locale.lookup_("target_income")),
        sortProperty: new Data_Maybe.Just(ByTargetIncome.value)
    }, {
        col: ColEstimatedIncome.value,
        width: new TacoTable_Types.Flex(20),
        key: "estimatedIncome",
        label: React_Basic_DOM.text(Locale.lookup_("estimate_income")),
        sortProperty: new Data_Maybe.Just(ByEstimatedIncome.value)
    }, {
        col: ColActualIncome.value,
        width: new TacoTable_Types.Flex(20),
        key: "actualIncome",
        label: React_Basic_DOM.text(Locale.lookup_("actual_income")),
        sortProperty: new Data_Maybe.Just(ByActualIncome.value)
    }, {
        col: ColActualIncomeApproved.value,
        width: new TacoTable_Types.Flex(20),
        key: "actualIncomeApproved",
        label: React_Basic_DOM.text(Locale.lookup_("actual_income_approved")),
        sortProperty: new Data_Maybe.Just(ByActualIncomeApproved.value)
    }, {
        col: ColTargetDiffAndMargin.value,
        width: new TacoTable_Types.Flex(32),
        key: "targetDiffAndMargin",
        label: React_Basic_DOM.text(Locale.lookup_("target_diff_and_margin")),
        sortProperty: new Data_Maybe.Just(ByTargetDiffAndMargin.value)
    }, {
        col: ColEstimatedDiffAndMargin.value,
        width: new TacoTable_Types.Flex(32),
        key: "estimatedDiffAndMargin",
        label: React_Basic_DOM.text(Locale.lookup_("estimated_diff_and_margin")),
        sortProperty: new Data_Maybe.Just(ByEstimatedDiffAndMargin.value)
    }, {
        col: ColActualDiffAndMargin.value,
        width: new TacoTable_Types.Flex(32),
        key: "actualDiffAndMargin",
        label: React_Basic_DOM.text(Locale.lookup_("actual_diff_and_margin")),
        sortProperty: new Data_Maybe.Just(ByActualDiffAndMargin.value)
    }, {
        col: ColActualDiffAndMarginApproved.value,
        width: new TacoTable_Types.Flex(32),
        key: "actualDiffAndMarginApproved",
        label: React_Basic_DOM.text(Locale.lookup_("actual_diff_and_margin_approved")),
        sortProperty: new Data_Maybe.Just(ByActualDiffAndMarginApproved.value)
    }, {
        col: ColStartDate.value,
        width: new TacoTable_Types.Flex(18),
        key: "startDate",
        label: React_Basic_DOM.text(Locale.lookup_("common_start_date")),
        sortProperty: new Data_Maybe.Just(ByStartDate.value)
    }, {
        col: ColEndDate.value,
        width: new TacoTable_Types.Flex(18),
        key: "endDate",
        label: React_Basic_DOM.text(Locale.lookup_("common_end_date")),
        sortProperty: new Data_Maybe.Just(ByEndDate.value)
    }, {
        col: ColContractType.value,
        width: new TacoTable_Types.Flex(32),
        key: "contractType",
        label: React_Basic_DOM.text(Locale.lookup_("common_contract_type")),
        sortProperty: new Data_Maybe.Just(ByContractType.value)
    }, {
        col: ColState.value,
        width: new TacoTable_Types.Flex(32),
        key: "state",
        label: React_Basic_DOM.text(Locale.lookup_("common_state")),
        sortProperty: new Data_Maybe.Just(ByState.value)
    }, {
        col: ColProjectType.value,
        width: new TacoTable_Types.Flex(24),
        key: "projectType",
        label: React_Basic_DOM.text(Locale.lookup_("common_project_type")),
        sortProperty: new Data_Maybe.Just(ByProjectType.value)
    }, {
        col: ColFinancingType.value,
        width: new TacoTable_Types.Flex(24),
        key: "financingType",
        label: React_Basic_DOM.text(Locale.lookup_("common_financing_type")),
        sortProperty: new Data_Maybe.Just(ByFinancingType.value)
    }, {
        col: ColConstructionType.value,
        width: new TacoTable_Types.Flex(30),
        key: "constructionType",
        label: React_Basic_DOM.text(Locale.lookup_("common_construction_type")),
        sortProperty: new Data_Maybe.Just(ByConstructionType.value)
    }, {
        col: ColContractPrice.value,
        width: new TacoTable_Types.Flex(20),
        key: "contractPrice",
        label: React_Basic_DOM.text(Locale.lookup_("common_contract_price")),
        sortProperty: new Data_Maybe.Just(ByContractPrice.value)
    }, {
        col: ColTaxRate.value,
        width: new TacoTable_Types.Flex(10),
        key: "taxRate",
        label: React_Basic_DOM.text("ALV"),
        sortProperty: new Data_Maybe.Just(ByTaxRate.value)
    }, {
        col: ColOwnProject.value,
        width: new TacoTable_Types.Flex(8),
        key: "ownProject",
        label: React_Basic_DOM.text(Locale.lookup_("common_own_project")),
        sortProperty: new Data_Maybe.Just(ByOwnProject.value)
    }, {
        col: ColProjectGroup.value,
        width: new TacoTable_Types.Flex(20),
        key: "projectGroup",
        label: React_Basic_DOM.text(Locale.lookup_("project_group")),
        sortProperty: new Data_Maybe.Just(ByProjectGroup.value)
    } ]);
};
var getPortfolioTableColumns = function (props) {
    var getColumn = function (spec) {
        return mkColumn(spec)(function (v) {
            if (v instanceof ColCode) {
                return TacoTable_Types.PlainTextCell.create((function () {
                    var $107 = Data_Newtype.un()(Types_Project.ProjectCode);
                    return function ($108) {
                        return $107((function (v1) {
                            return v1.code;
                        })($108));
                    };
                })());
            };
            if (v instanceof ColName) {
                return new TacoTable_Types.PlainTextCell(function (v1) {
                    return v1.name;
                });
            };
            if (v instanceof ColVersion) {
                return new TacoTable_Types.PlainTextCell(function (v1) {
                    return v1.version;
                });
            };
            if (v instanceof ColProjectManager) {
                return new TacoTable_Types.PlainTextCell(function (v1) {
                    return v1.projectManager;
                });
            };
            if (v instanceof ColTargetCosts) {
                return TacoTable_Types.PlainEuroCell.create(function ($109) {
                    return Calculation_Portfolio.nullableNumber((function (v1) {
                        return v1.targetCosts;
                    })($109));
                });
            };
            if (v instanceof ColEstimatedCosts) {
                return TacoTable_Types.PlainEuroCell.create(function ($110) {
                    return Calculation_Portfolio.nullableNumber((function (v1) {
                        return v1.estimatedCosts;
                    })($110));
                });
            };
            if (v instanceof ColActualCosts) {
                return TacoTable_Types.PlainEuroCell.create(function ($111) {
                    return Calculation_Portfolio.nullableNumber((function (v1) {
                        return v1.actualCosts;
                    })($111));
                });
            };
            if (v instanceof ColTargetIncome) {
                return TacoTable_Types.PlainEuroCell.create(function ($112) {
                    return Calculation_Portfolio.nullableNumber((function (v1) {
                        return v1.targetIncome;
                    })($112));
                });
            };
            if (v instanceof ColEstimatedIncome) {
                return TacoTable_Types.PlainEuroCell.create(function ($113) {
                    return Calculation_Portfolio.nullableNumber((function (v1) {
                        return v1.estimatedIncome;
                    })($113));
                });
            };
            if (v instanceof ColActualIncome) {
                return TacoTable_Types.PlainEuroCell.create(function ($114) {
                    return Calculation_Portfolio.nullableNumber((function (v1) {
                        return v1.actualIncome;
                    })($114));
                });
            };
            if (v instanceof ColActualIncomeApproved) {
                return TacoTable_Types.PlainEuroCell.create(function ($115) {
                    return Calculation_Portfolio.nullableNumber((function (v1) {
                        return v1.actualIncomeApproved;
                    })($115));
                });
            };
            if (v instanceof ColTargetDiffAndMargin) {
                return new TacoTable_Types.ColoredEuroAndPercentCell(Calculation_Portfolio.decimalPrecision1, Calculation_Portfolio.targetDiffAndMargin);
            };
            if (v instanceof ColEstimatedDiffAndMargin) {
                return new TacoTable_Types.ColoredEuroAndPercentCell(Calculation_Portfolio.decimalPrecision1, Calculation_Portfolio.estimatedDiffAndMargin);
            };
            if (v instanceof ColActualDiffAndMargin) {
                return new TacoTable_Types.ColoredEuroAndPercentCell(Calculation_Portfolio.decimalPrecision1, Calculation_Portfolio.actualDiffAndMargin);
            };
            if (v instanceof ColActualDiffAndMarginApproved) {
                return new TacoTable_Types.ColoredEuroAndPercentCell(Calculation_Portfolio.decimalPrecision1, Calculation_Portfolio.actualDiffAndMarginApproved);
            };
            if (v instanceof ColStartDate) {
                return TacoTable_Types.PlainDateStringCell.create(function ($116) {
                    return Calculation_Portfolio.nullableString((function (v1) {
                        return v1.startDate;
                    })($116));
                });
            };
            if (v instanceof ColEndDate) {
                return TacoTable_Types.PlainDateStringCell.create(function ($117) {
                    return Calculation_Portfolio.nullableString((function (v1) {
                        return v1.endDate;
                    })($117));
                });
            };
            if (v instanceof ColContractType) {
                return new TacoTable_Types.PlainTextCell(function (v1) {
                    return v1.contractType;
                });
            };
            if (v instanceof ColState) {
                return new TacoTable_Types.PlainTextCell(function (v1) {
                    return v1.state;
                });
            };
            if (v instanceof ColProjectType) {
                return new TacoTable_Types.PlainTextCell(function (v1) {
                    return v1.projectType;
                });
            };
            if (v instanceof ColFinancingType) {
                return new TacoTable_Types.PlainTextCell(function (v1) {
                    return v1.financingType;
                });
            };
            if (v instanceof ColConstructionType) {
                return new TacoTable_Types.PlainTextCell(function (v1) {
                    return v1.constructionType;
                });
            };
            if (v instanceof ColContractPrice) {
                return new TacoTable_Types.PlainEuroCell(function (v1) {
                    return v1.contractPrice;
                });
            };
            if (v instanceof ColTaxRate) {
                return new TacoTable_Types.PlainPercentageCell(Calculation_Portfolio.decimalPrecision1, (function () {
                    var $118 = Data_Maybe.maybe(Calculation_Portfolio.zeroPercent)((function () {
                        var $120 = Data_Newtype.unwrap();
                        return function ($121) {
                            return Types_Unit.PercentageOfZeroToOne($120($121));
                        };
                    })());
                    return function ($119) {
                        return $118((function (v1) {
                            return v1.taxRate;
                        })($119));
                    };
                })());
            };
            if (v instanceof ColOwnProject) {
                return new TacoTable_Types.PlainTextCell(function (r) {
                    if (r.ownProject) {
                        return "Kyll\xe4";
                    };
                    return "Ei";
                });
            };
            if (v instanceof ColProjectGroup) {
                return new TacoTable_Types.PlainTextCell(function (v1) {
                    return v1.projectGroup;
                });
            };
            throw new Error("Failed pattern match at PortfolioTable (line 476, column 38 - line 502, column 57): " + [ v.constructor.name ]);
        });
    };
    return Data_Functor.map(Data_Functor.functorArray)(getColumn)(getDisplayColumns(props));
};
var getSummaryTableColumns = function (props) {
    var blank = new TacoTable_Types.PlainTextCell(function (v) {
        return "";
    });
    var getColumn = function (spec) {
        return mkColumn(spec)(function (v) {
            if (v instanceof ColCode) {
                return blank;
            };
            if (v instanceof ColName) {
                return new TacoTable_Types.PlainTextCell(function (v1) {
                    return v1.name;
                });
            };
            if (v instanceof ColVersion) {
                return blank;
            };
            if (v instanceof ColProjectManager) {
                return blank;
            };
            if (v instanceof ColTargetCosts) {
                return TacoTable_Types.PlainEuroCell.create(function ($122) {
                    return Calculation_Portfolio.nullableNumber((function (v1) {
                        return v1.targetCosts;
                    })($122));
                });
            };
            if (v instanceof ColEstimatedCosts) {
                return TacoTable_Types.PlainEuroCell.create(function ($123) {
                    return Calculation_Portfolio.nullableNumber((function (v1) {
                        return v1.estimatedCosts;
                    })($123));
                });
            };
            if (v instanceof ColActualCosts) {
                return TacoTable_Types.PlainEuroCell.create(function ($124) {
                    return Calculation_Portfolio.nullableNumber((function (v1) {
                        return v1.actualCosts;
                    })($124));
                });
            };
            if (v instanceof ColTargetIncome) {
                return TacoTable_Types.PlainEuroCell.create(function ($125) {
                    return Calculation_Portfolio.nullableNumber((function (v1) {
                        return v1.targetIncome;
                    })($125));
                });
            };
            if (v instanceof ColEstimatedIncome) {
                return TacoTable_Types.PlainEuroCell.create(function ($126) {
                    return Calculation_Portfolio.nullableNumber((function (v1) {
                        return v1.estimatedIncome;
                    })($126));
                });
            };
            if (v instanceof ColActualIncome) {
                return TacoTable_Types.PlainEuroCell.create(function ($127) {
                    return Calculation_Portfolio.nullableNumber((function (v1) {
                        return v1.actualIncome;
                    })($127));
                });
            };
            if (v instanceof ColActualIncomeApproved) {
                return TacoTable_Types.PlainEuroCell.create(function ($128) {
                    return Calculation_Portfolio.nullableNumber((function (v1) {
                        return v1.actualIncomeApproved;
                    })($128));
                });
            };
            if (v instanceof ColTargetDiffAndMargin) {
                return new TacoTable_Types.ColoredEuroAndPercentCell(Calculation_Portfolio.decimalPrecision1, Calculation_Portfolio.targetDiffAndMargin);
            };
            if (v instanceof ColEstimatedDiffAndMargin) {
                return new TacoTable_Types.ColoredEuroAndPercentCell(Calculation_Portfolio.decimalPrecision1, Calculation_Portfolio.estimatedDiffAndMargin);
            };
            if (v instanceof ColActualDiffAndMargin) {
                return new TacoTable_Types.ColoredEuroAndPercentCell(Calculation_Portfolio.decimalPrecision1, Calculation_Portfolio.actualDiffAndMargin);
            };
            if (v instanceof ColActualDiffAndMarginApproved) {
                return new TacoTable_Types.ColoredEuroAndPercentCell(Calculation_Portfolio.decimalPrecision1, Calculation_Portfolio.actualDiffAndMarginApproved);
            };
            if (v instanceof ColStartDate) {
                return blank;
            };
            if (v instanceof ColEndDate) {
                return blank;
            };
            if (v instanceof ColContractType) {
                return blank;
            };
            if (v instanceof ColState) {
                return blank;
            };
            if (v instanceof ColProjectType) {
                return blank;
            };
            if (v instanceof ColFinancingType) {
                return blank;
            };
            if (v instanceof ColConstructionType) {
                return blank;
            };
            if (v instanceof ColContractPrice) {
                return new TacoTable_Types.PlainEuroCell(function (v1) {
                    return v1.contractPrice;
                });
            };
            if (v instanceof ColTaxRate) {
                return blank;
            };
            if (v instanceof ColOwnProject) {
                return blank;
            };
            if (v instanceof ColProjectGroup) {
                return blank;
            };
            throw new Error("Failed pattern match at PortfolioTable (line 445, column 38 - line 471, column 31): " + [ v.constructor.name ]);
        });
    };
    return Data_Functor.map(Data_Functor.functorArray)(getColumn)(getDisplayColumns(props));
};
var eqSortProperty = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ByCode && y instanceof ByCode) {
                return true;
            };
            if (x instanceof ByName && y instanceof ByName) {
                return true;
            };
            if (x instanceof ByVersion && y instanceof ByVersion) {
                return true;
            };
            if (x instanceof ByProjectManager && y instanceof ByProjectManager) {
                return true;
            };
            if (x instanceof ByTargetCosts && y instanceof ByTargetCosts) {
                return true;
            };
            if (x instanceof ByEstimatedCosts && y instanceof ByEstimatedCosts) {
                return true;
            };
            if (x instanceof ByActualCosts && y instanceof ByActualCosts) {
                return true;
            };
            if (x instanceof ByTargetIncome && y instanceof ByTargetIncome) {
                return true;
            };
            if (x instanceof ByEstimatedIncome && y instanceof ByEstimatedIncome) {
                return true;
            };
            if (x instanceof ByActualIncome && y instanceof ByActualIncome) {
                return true;
            };
            if (x instanceof ByActualIncomeApproved && y instanceof ByActualIncomeApproved) {
                return true;
            };
            if (x instanceof ByTargetDiffAndMargin && y instanceof ByTargetDiffAndMargin) {
                return true;
            };
            if (x instanceof ByActualDiffAndMargin && y instanceof ByActualDiffAndMargin) {
                return true;
            };
            if (x instanceof ByActualDiffAndMarginApproved && y instanceof ByActualDiffAndMarginApproved) {
                return true;
            };
            if (x instanceof ByEstimatedDiffAndMargin && y instanceof ByEstimatedDiffAndMargin) {
                return true;
            };
            if (x instanceof ByStartDate && y instanceof ByStartDate) {
                return true;
            };
            if (x instanceof ByEndDate && y instanceof ByEndDate) {
                return true;
            };
            if (x instanceof ByContractType && y instanceof ByContractType) {
                return true;
            };
            if (x instanceof ByState && y instanceof ByState) {
                return true;
            };
            if (x instanceof ByProjectType && y instanceof ByProjectType) {
                return true;
            };
            if (x instanceof ByFinancingType && y instanceof ByFinancingType) {
                return true;
            };
            if (x instanceof ByConstructionType && y instanceof ByConstructionType) {
                return true;
            };
            if (x instanceof ByContractPrice && y instanceof ByContractPrice) {
                return true;
            };
            if (x instanceof ByTaxRate && y instanceof ByTaxRate) {
                return true;
            };
            if (x instanceof ByOwnProject && y instanceof ByOwnProject) {
                return true;
            };
            if (x instanceof ByProjectGroup && y instanceof ByProjectGroup) {
                return true;
            };
            return false;
        };
    }
};
var endDateS = Data_Symbol.SProxy.value;
var compareOnStringDateProperty = function (dictCons) {
    return function (dictIsSymbol) {
        return function (k) {
            return function (sortingOrder) {
                return function (a) {
                    return function (b) {
                        var getStartDate = (function () {
                            var $129 = Record.get(dictIsSymbol)()(k);
                            return function ($130) {
                                return Data_Nullable.toMaybe($129($130));
                            };
                        })();
                        var compare$prime = (function () {
                            if (sortingOrder instanceof TacoTable_Types.Ascending) {
                                return Data_Ord.compare(Data_JSDate.ordJSDate);
                            };
                            if (sortingOrder instanceof TacoTable_Types.Descending) {
                                return Data_Function.flip(Data_Ord.compare(Data_JSDate.ordJSDate));
                            };
                            throw new Error("Failed pattern match at PortfolioTable (line 178, column 16 - line 180, column 36): " + [ sortingOrder.constructor.name ]);
                        })();
                        var v = Data_Functor.map(Data_Maybe.functorMaybe)(Utils.lossyParseJSDate)(getStartDate(b));
                        var v1 = Data_Functor.map(Data_Maybe.functorMaybe)(Utils.lossyParseJSDate)(getStartDate(a));
                        if (v1 instanceof Data_Maybe.Just && v instanceof Data_Maybe.Just) {
                            return compare$prime(v1.value0)(v.value0);
                        };
                        if (v1 instanceof Data_Maybe.Just && v instanceof Data_Maybe.Nothing) {
                            return Data_Ordering.LT.value;
                        };
                        if (v1 instanceof Data_Maybe.Nothing && v instanceof Data_Maybe.Just) {
                            return Data_Ordering.GT.value;
                        };
                        if (v1 instanceof Data_Maybe.Nothing && v instanceof Data_Maybe.Nothing) {
                            return Data_Ordering.EQ.value;
                        };
                        throw new Error("Failed pattern match at PortfolioTable (line 170, column 3 - line 175, column 27): " + [ v1.constructor.name, v.constructor.name ]);
                    };
                };
            };
        };
    };
};
var portfolioTable = TofuHooks.mkHookComponent("PortfolioTable")(function (props) {
    return function __do() {
        var v = TofuHooks.useState(ByCode.value)();
        var v1 = TofuHooks.useState(TacoTable_Types.Descending.value)();
        var v2 = TofuHooks.useState(0)();
        var projectCount = Data_Array.length(props.projects);
        var v3 = Data_Int.ceil(Data_Int.toNumber(projectCount) / Data_Int.toNumber(portfolioPaginationCount));
        var v4 = v2.value0 * portfolioPaginationCount | 0;
        var summaryColumns = getSummaryTableColumns(props);
        var sortedProjects = (function () {
            var sortByFn = function (fn) {
                var sorted = Data_Array.sortBy(fn)(props.projects);
                if (v1.value0 instanceof TacoTable_Types.Descending) {
                    return Data_Array.reverse(sorted);
                };
                if (v1.value0 instanceof TacoTable_Types.Ascending) {
                    return sorted;
                };
                throw new Error("Failed pattern match at PortfolioTable (line 115, column 14 - line 117, column 35): " + [ v1.value0.constructor.name ]);
            };
            if (v.value0 instanceof ByStartDate) {
                return Data_Array.sortBy(compareOnStringDateProperty()({
                    reflectSymbol: function () {
                        return "startDate";
                    }
                })(startDateS)(v1.value0))(props.projects);
            };
            if (v.value0 instanceof ByEndDate) {
                return Data_Array.sortBy(compareOnStringDateProperty()({
                    reflectSymbol: function () {
                        return "endDate";
                    }
                })(endDateS)(v1.value0))(props.projects);
            };
            if (v.value0 instanceof ByCode) {
                return sortByFn(Data_Function.on(Data_Ord.compare(Types_Project.ordProjectCode))(function (v5) {
                    return v5.code;
                }));
            };
            if (v.value0 instanceof ByName) {
                return sortByFn(Data_Function.on(Data_Ord.compare(Data_Ord.ordString))(function (v5) {
                    return v5.name;
                }));
            };
            if (v.value0 instanceof ByVersion) {
                return sortByFn(Data_Function.on(Data_Ord.compare(Data_Ord.ordString))(function (v5) {
                    return v5.version;
                }));
            };
            if (v.value0 instanceof ByProjectManager) {
                return sortByFn(Data_Function.on(Data_Ord.compare(Data_Ord.ordString))(function (v5) {
                    return v5.projectManager;
                }));
            };
            if (v.value0 instanceof ByTargetCosts) {
                return sortByFn(Data_Function.on(Data_Ord.compare(Data_Nullable.ordNullable(Data_Ord.ordNumber)))(function (v5) {
                    return v5.targetCosts;
                }));
            };
            if (v.value0 instanceof ByEstimatedCosts) {
                return sortByFn(Data_Function.on(Data_Ord.compare(Data_Nullable.ordNullable(Data_Ord.ordNumber)))(function (v5) {
                    return v5.estimatedCosts;
                }));
            };
            if (v.value0 instanceof ByActualCosts) {
                return sortByFn(Data_Function.on(Data_Ord.compare(Data_Nullable.ordNullable(Data_Ord.ordNumber)))(function (v5) {
                    return v5.actualCosts;
                }));
            };
            if (v.value0 instanceof ByTargetIncome) {
                return sortByFn(Data_Function.on(Data_Ord.compare(Data_Nullable.ordNullable(Data_Ord.ordNumber)))(function (v5) {
                    return v5.targetIncome;
                }));
            };
            if (v.value0 instanceof ByEstimatedIncome) {
                return sortByFn(Data_Function.on(Data_Ord.compare(Data_Nullable.ordNullable(Data_Ord.ordNumber)))(function (v5) {
                    return v5.estimatedIncome;
                }));
            };
            if (v.value0 instanceof ByActualIncome) {
                return sortByFn(Data_Function.on(Data_Ord.compare(Data_Nullable.ordNullable(Data_Ord.ordNumber)))(function (v5) {
                    return v5.actualIncome;
                }));
            };
            if (v.value0 instanceof ByActualIncomeApproved) {
                return sortByFn(Data_Function.on(Data_Ord.compare(Data_Nullable.ordNullable(Data_Ord.ordNumber)))(function (v5) {
                    return v5.actualIncomeApproved;
                }));
            };
            if (v.value0 instanceof ByTargetDiffAndMargin) {
                return sortByFn(Data_Function.on(Data_Ord.compare(Data_Nullable.ordNullable(Data_Ord.ordNumber)))(function (v5) {
                    return v5.targetDiff;
                }));
            };
            if (v.value0 instanceof ByActualDiffAndMargin) {
                return sortByFn(Data_Function.on(Data_Ord.compare(Data_Nullable.ordNullable(Data_Ord.ordNumber)))(function (v5) {
                    return v5.actualDiff;
                }));
            };
            if (v.value0 instanceof ByActualDiffAndMarginApproved) {
                return sortByFn(Data_Function.on(Data_Ord.compare(Data_Nullable.ordNullable(Data_Ord.ordNumber)))(function (v5) {
                    return v5.actualDiffApproved;
                }));
            };
            if (v.value0 instanceof ByEstimatedDiffAndMargin) {
                return sortByFn(Data_Function.on(Data_Ord.compare(Data_Nullable.ordNullable(Data_Ord.ordNumber)))(function (v5) {
                    return v5.estimatedDiff;
                }));
            };
            if (v.value0 instanceof ByContractType) {
                return sortByFn(Data_Function.on(Data_Ord.compare(Data_Ord.ordString))(function (v5) {
                    return v5.contractType;
                }));
            };
            if (v.value0 instanceof ByState) {
                return sortByFn(Data_Function.on(Data_Ord.compare(Data_Ord.ordString))(function (v5) {
                    return v5.state;
                }));
            };
            if (v.value0 instanceof ByProjectType) {
                return sortByFn(Data_Function.on(Data_Ord.compare(Data_Ord.ordString))(function (v5) {
                    return v5.projectType;
                }));
            };
            if (v.value0 instanceof ByFinancingType) {
                return sortByFn(Data_Function.on(Data_Ord.compare(Data_Ord.ordString))(function (v5) {
                    return v5.financingType;
                }));
            };
            if (v.value0 instanceof ByConstructionType) {
                return sortByFn(Data_Function.on(Data_Ord.compare(Data_Ord.ordString))(function (v5) {
                    return v5.constructionType;
                }));
            };
            if (v.value0 instanceof ByContractPrice) {
                return sortByFn(Data_Function.on(Data_Ord.compare(Data_Ord.ordNumber))(function (v5) {
                    return v5.contractPrice;
                }));
            };
            if (v.value0 instanceof ByTaxRate) {
                return sortByFn(Data_Function.on(Data_Ord.compare(Data_Maybe.ordMaybe(Types_Unit.ordTaxrate)))(function (v5) {
                    return v5.taxRate;
                }));
            };
            if (v.value0 instanceof ByOwnProject) {
                return sortByFn(Data_Function.on(Data_Ord.compare(Data_Ord.ordBoolean))(function (v5) {
                    return v5.ownProject;
                }));
            };
            if (v.value0 instanceof ByProjectGroup) {
                return sortByFn(Data_Function.on(Data_Ord.compare(Data_Ord.ordString))(function (v5) {
                    return v5.projectGroup;
                }));
            };
            throw new Error("Failed pattern match at PortfolioTable (line 119, column 10 - line 145, column 63): " + [ v.value0.constructor.name ]);
        })();
        var summaryRow = {
            rowData: Calculation_Portfolio.mkSummaryData(sortedProjects),
            className: "__PortfolioSummaryRow",
            foldingElement: Data_Maybe.Nothing.value,
            rowKey: "portfolio-summary-row",
            onClick: function (v5) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            },
            columns: summaryColumns
        };
        var summaryTable = TacoTable.tableStateless(getNameSortProperty)(eqSortProperty)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "targetMargin";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
            reflectSymbol: function () {
                return "targetIncome";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
            reflectSymbol: function () {
                return "targetDiff";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
            reflectSymbol: function () {
                return "targetCosts";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
            reflectSymbol: function () {
                return "name";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "estimatedMargin";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
            reflectSymbol: function () {
                return "estimatedIncome";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
            reflectSymbol: function () {
                return "estimatedDiff";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
            reflectSymbol: function () {
                return "estimatedCosts";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
            reflectSymbol: function () {
                return "contractPrice";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "actualMarginApproved";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
            reflectSymbol: function () {
                return "actualMargin";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
            reflectSymbol: function () {
                return "actualIncomeApproved";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
            reflectSymbol: function () {
                return "actualIncome";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
            reflectSymbol: function () {
                return "actualDiffApproved";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
            reflectSymbol: function () {
                return "actualDiff";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
            reflectSymbol: function () {
                return "actualCosts";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqNumber))))(portfolioSummaryTableInstance)({
            sortProperty: Data_Maybe.Nothing.value,
            onSort: function (v5) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            },
            sortingOrder: TacoTable_Types.Ascending.value,
            columns: summaryColumns,
            showHeader: false,
            styleSet: TacoTable_Styles.summaryTableStyleSet,
            rows: [ summaryRow ],
            onResize: function (v5) {
                return function (v6) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                };
            },
            columnSizeOffsets: Data_Maybe.Nothing.value
        });
        var renderPaginationControls = PaginationControls.mkPaginationControls({
            itemCount: projectCount,
            pageSize: portfolioPaginationCount,
            pageIdx: v2.value0,
            onPageChange: function (idx) {
                return v2.value1(function (v5) {
                    return idx;
                });
            }
        });
        var paginatedProjects = Data_Array.take(portfolioPaginationCount)(Data_Array.drop(portfolioPaginationCount * v2.value0 | 0)(sortedProjects));
        var columns = getPortfolioTableColumns(props);
        var table = function (projects) {
            return TacoTable.tableStateless(getNameSortProperty)(eqSortProperty)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "version";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "taxRate";
                }
            })(Data_Maybe.eqMaybe(Types_Unit.eqTaxrate)))()({
                reflectSymbol: function () {
                    return "targetMargin";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                reflectSymbol: function () {
                    return "targetIncome";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                reflectSymbol: function () {
                    return "targetDiff";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                reflectSymbol: function () {
                    return "targetCosts";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                reflectSymbol: function () {
                    return "state";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "startDate";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "projectType";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "projectManager";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "projectGroup";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "programType";
                }
            })(Data_Eq.eqInt))()({
                reflectSymbol: function () {
                    return "postalCode";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "ownTaxRate";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                reflectSymbol: function () {
                    return "ownProject";
                }
            })(Data_Eq.eqBoolean))()({
                reflectSymbol: function () {
                    return "offerDate";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "name";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "memo";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "isReportingProject";
                }
            })(Data_Eq.eqBoolean))()({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Project.eqProjectId))()({
                reflectSymbol: function () {
                    return "financingType";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "estimatedMargin";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                reflectSymbol: function () {
                    return "estimatedIncome";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                reflectSymbol: function () {
                    return "estimatedDiff";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                reflectSymbol: function () {
                    return "estimatedCosts";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                reflectSymbol: function () {
                    return "endDate";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "defaultSalaryCostClass";
                }
            })(Data_Maybe.eqMaybe(Types_Newtypes.eqCostClassCode)))()({
                reflectSymbol: function () {
                    return "defaultInvoiceCostClass";
                }
            })(Data_Maybe.eqMaybe(Types_Newtypes.eqCostClassCode)))()({
                reflectSymbol: function () {
                    return "currency";
                }
            })(Types_Project.eqCurrency))()({
                reflectSymbol: function () {
                    return "contractType";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "contractPrice";
                }
            })(Data_Eq.eqNumber))()({
                reflectSymbol: function () {
                    return "constructionType";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "code";
                }
            })(Types_Project.eqProjectCode))()({
                reflectSymbol: function () {
                    return "classification";
                }
            })(Types_Classification.eqOrgClassificationCode))()({
                reflectSymbol: function () {
                    return "city";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "area";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "address";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "actualMarginApproved";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                reflectSymbol: function () {
                    return "actualMargin";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                reflectSymbol: function () {
                    return "actualIncomeApproved";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                reflectSymbol: function () {
                    return "actualIncome";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                reflectSymbol: function () {
                    return "actualDiffApproved";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                reflectSymbol: function () {
                    return "actualDiff";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                reflectSymbol: function () {
                    return "actualCosts";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqNumber))))(portfolioTableInstance)({
                sortProperty: new Data_Maybe.Just(v.value0),
                onSort: function (property) {
                    var $99 = Data_Eq.eq(eqSortProperty)(v.value0)(property);
                    if ($99) {
                        return v1.value1(function (v5) {
                            return TacoTable_Types.reverse(v1.value0);
                        });
                    };
                    return v.value1(function (v5) {
                        return property;
                    });
                },
                sortingOrder: v1.value0,
                rows: Data_Functor.map(Data_Functor.functorArray)(getRow(props)(columns))(projects),
                columns: columns,
                showHeader: true,
                styleSet: styleSet,
                onResize: function (v5) {
                    return function (v6) {
                        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                    };
                },
                columnSizeOffsets: Data_Maybe.Nothing.value
            });
        };
        return ReactBasicUtils["div'"]("__PortfolioTable")(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ renderPaginationControls ])((function () {
            if (props.loading) {
                return [ TacoLoader.component()()({}) ];
            };
            return Data_Semigroup.append(Data_Semigroup.semigroupArray)([ table(paginatedProjects), summaryTable ])([ renderPaginationControls ]);
        })()));
    };
});
module.exports = {
    portfolioTable: portfolioTable,
    ColCode: ColCode,
    ColName: ColName,
    ColVersion: ColVersion,
    ColProjectManager: ColProjectManager,
    ColTargetCosts: ColTargetCosts,
    ColEstimatedCosts: ColEstimatedCosts,
    ColActualCosts: ColActualCosts,
    ColTargetIncome: ColTargetIncome,
    ColEstimatedIncome: ColEstimatedIncome,
    ColActualIncome: ColActualIncome,
    ColActualIncomeApproved: ColActualIncomeApproved,
    ColTargetDiffAndMargin: ColTargetDiffAndMargin,
    ColActualDiffAndMargin: ColActualDiffAndMargin,
    ColActualDiffAndMarginApproved: ColActualDiffAndMarginApproved,
    ColEstimatedDiffAndMargin: ColEstimatedDiffAndMargin,
    ColStartDate: ColStartDate,
    ColEndDate: ColEndDate,
    ColContractType: ColContractType,
    ColState: ColState,
    ColProjectType: ColProjectType,
    ColFinancingType: ColFinancingType,
    ColConstructionType: ColConstructionType,
    ColContractPrice: ColContractPrice,
    ColTaxRate: ColTaxRate,
    ColOwnProject: ColOwnProject,
    ColProjectGroup: ColProjectGroup
};
