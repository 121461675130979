import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";
import axiosClient from "src/client-ts/axiosConfig";
import { useLocalize } from "src/client-ts/localize/useLocalize";
import { reportError } from "src/client-ts/utils/error";
import { useTranslation } from "react-i18next";

const baseUrl = "/users/me/language";

export function useUserLanguageMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (newLanguage: string) => {
      return await axiosClient.put(baseUrl, { language: newLanguage });
    },
    onSettled: () => {
      queryClient.invalidateQueries("/users/me");
    },
    onError: (error) => {
      reportError("Error while changing user's language", error as AxiosError);
    },
  });
}

export function useChangeUserLanguage() {
  const { i18n } = useTranslation();
  const mutation = useUserLanguageMutation();
  const changeLocalizeLanguage = useLocalize();

  return async (newLanguage: string) => {
    await mutation.mutateAsync(newLanguage);
    await i18n.changeLanguage(newLanguage);
    changeLocalizeLanguage(newLanguage);
  };
}
