// Generated by purs version 0.14.5
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Show = require("../Data.Show/index.js");
var ReadParam = require("../ReadParam/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var ProjectClassificationClassId = function (x) {
    return x;
};
var MeasurementValueId = function (x) {
    return x;
};
var MeasurementUnitId = function (x) {
    return x;
};
var ElementSpecId = function (x) {
    return x;
};
var CostClassCode = function (x) {
    return x;
};
var writeParamProjectClassificationClassId = ReadParam.writeParamInt;
var writeParamMeasurementValueId = ReadParam.writeParamInt;
var writeParamMeasurementUnitId = ReadParam.writeParamInt;
var writeParamCostClassCode = ReadParam.writeParamString;
var tofuJSONProjectClassificationClassId = TofuJSON.readInt;
var tofuJSONMeasurementValueId = TofuJSON.readInt;
var tofuJSONMeasurementUnitId = TofuJSON.readInt;
var tofuJSONElementSpecId = TofuJSON.readInt;
var tofuJSONCostClassCode = TofuJSON.readString;
var showProjectClassificationClassId = Data_Show.showInt;
var showMeasurementValueId = Data_Show.showInt;
var showMeasurementUnitId = Data_Show.showInt;
var showElementSpecId = Data_Show.showInt;
var showCostClassCode = Data_Show.showString;
var readParamProjectClassificationClassId = ReadParam.intReadParam;
var readParamMeasurementValueId = ReadParam.intReadParam;
var readParamMeasurementUnitId = ReadParam.intReadParam;
var readParamCostClassCode = ReadParam.stringReadParam;
var ordProjectClassificationClassId = Data_Ord.ordInt;
var ordMeasurementValueId = Data_Ord.ordInt;
var ordMeasurementUnitId = Data_Ord.ordInt;
var ordElementSpecId = Data_Ord.ordInt;
var ordCostClassCode = Data_Ord.ordString;
var newtypeProjectClassificationClassId = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeMeasurementValueId = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeMeasurementUnitId = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeElementSpecId = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeCostClassCode = {
    Coercible0: function () {
        return undefined;
    }
};
var eqProjectClassificationClassId = Data_Eq.eqInt;
var eqMeasurementValueId = Data_Eq.eqInt;
var eqMeasurementUnitId = Data_Eq.eqInt;
var eqElementSpecId = Data_Eq.eqInt;
var eqCostClassCode = Data_Eq.eqString;
module.exports = {
    CostClassCode: CostClassCode,
    MeasurementValueId: MeasurementValueId,
    MeasurementUnitId: MeasurementUnitId,
    ElementSpecId: ElementSpecId,
    ProjectClassificationClassId: ProjectClassificationClassId,
    newtypeElementSpecId: newtypeElementSpecId,
    eqElementSpecId: eqElementSpecId,
    ordElementSpecId: ordElementSpecId,
    showElementSpecId: showElementSpecId,
    tofuJSONElementSpecId: tofuJSONElementSpecId,
    newtypeCostClassCode: newtypeCostClassCode,
    readParamCostClassCode: readParamCostClassCode,
    writeParamCostClassCode: writeParamCostClassCode,
    tofuJSONCostClassCode: tofuJSONCostClassCode,
    showCostClassCode: showCostClassCode,
    eqCostClassCode: eqCostClassCode,
    ordCostClassCode: ordCostClassCode,
    newtypeMeasurementUnitId: newtypeMeasurementUnitId,
    readParamMeasurementUnitId: readParamMeasurementUnitId,
    writeParamMeasurementUnitId: writeParamMeasurementUnitId,
    tofuJSONMeasurementUnitId: tofuJSONMeasurementUnitId,
    showMeasurementUnitId: showMeasurementUnitId,
    eqMeasurementUnitId: eqMeasurementUnitId,
    ordMeasurementUnitId: ordMeasurementUnitId,
    newtypeMeasurementValueId: newtypeMeasurementValueId,
    readParamMeasurementValueId: readParamMeasurementValueId,
    writeParamMeasurementValueId: writeParamMeasurementValueId,
    tofuJSONMeasurementValueId: tofuJSONMeasurementValueId,
    showMeasurementValueId: showMeasurementValueId,
    eqMeasurementValueId: eqMeasurementValueId,
    ordMeasurementValueId: ordMeasurementValueId,
    newtypeProjectClassificationClassId: newtypeProjectClassificationClassId,
    readParamProjectClassificationClassId: readParamProjectClassificationClassId,
    writeParamProjectClassificationClassId: writeParamProjectClassificationClassId,
    tofuJSONProjectClassificationClassId: tofuJSONProjectClassificationClassId,
    showProjectClassificationClassId: showProjectClassificationClassId,
    eqProjectClassificationClassId: eqProjectClassificationClassId,
    ordProjectClassificationClassId: ordProjectClassificationClassId
};
