// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var Types_ReasonableCoerce = require("../Types.ReasonableCoerce/index.js");
var SelectorClassName = function (x) {
    return x;
};
var unsafeCloneElement = $foreign.cloneElement_;
var span$prime = function (className) {
    return function (children) {
        return React_Basic_DOM_Generated.span()({
            className: className,
            children: children
        });
    };
};
var pre$prime = function (className) {
    return function (children) {
        return React_Basic_DOM_Generated.pre()({
            className: className,
            children: children
        });
    };
};
var noopHandler = React_Basic_Events.handler(Control_Category.identity(React_Basic_Events.categoryBuilder))(function (v) {
    return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
});
var newtypeSelectorClassName = {
    Coercible0: function () {
        return undefined;
    }
};
var maybeJSX = function (dictMonoid) {
    return function (jsx) {
        return Data_Maybe.fromMaybe(Data_Monoid.mempty(dictMonoid))(jsx);
    };
};
var domEvent = Types_ReasonableCoerce.reasonableCoerce("dangerously reading React synthetic events")(Control_Category.identity(Control_Category.categoryFn));
var div$prime = function (className) {
    return function (children) {
        return React_Basic_DOM_Generated.div()({
            className: className,
            children: children
        });
    };
};
module.exports = {
    domEvent: domEvent,
    SelectorClassName: SelectorClassName,
    "div'": div$prime,
    "span'": span$prime,
    "pre'": pre$prime,
    maybeJSX: maybeJSX,
    noopHandler: noopHandler,
    unsafeCloneElement: unsafeCloneElement,
    newtypeSelectorClassName: newtypeSelectorClassName,
    unsafeRecordEq: $foreign.unsafeRecordEq,
    unsafeGetStateFromInstance: $foreign.unsafeGetStateFromInstance,
    getSelectionsFromInstance: $foreign.getSelectionsFromInstance,
    setSelectionsFromInstance: $foreign.setSelectionsFromInstance,
    getSelectionsFromRef: $foreign.getSelectionsFromRef,
    setSelectionsFromRef: $foreign.setSelectionsFromRef,
    cloneElement_: $foreign.cloneElement_
};
