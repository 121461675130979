// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var StyleProperties = require("../StyleProperties/index.js");
var TacoButton_Styles = require("../TacoButton.Styles/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoTabs_Types = require("../TacoTabs.Types/index.js");
var tabsContainerStyles = function (v) {
    return Box.boxStyle([ (function () {
        if (v.orientation instanceof TacoTabs_Types.Vertical) {
            return Box.FlexColumn.value;
        };
        if (v.orientation instanceof TacoTabs_Types.Horizontal) {
            return Box.FlexRow.value;
        };
        throw new Error("Failed pattern match at TacoTabs.Styles (line 32, column 5 - line 34, column 28): " + [ v.orientation.constructor.name ]);
    })(), new Box.FlexShrink(0), Data_Monoid.guard(Data_Monoid.monoidFn(Box.monoidBoxProp))(Data_Eq.eq(TacoTabs_Types.eqTabsOrientation)(v.orientation)(TacoTabs_Types.Horizontal.value))(Box.Style.create)(React_Basic_Emotion.css()({
        borderBottom: StyleProperties.border(TacoConstants.BorderLight.value)
    })), new Box.Overflow(Box.Auto.value, Box.Hidden.value) ]);
};
var tabStyles = function (v) {
    var buttonStyleProps = {
        align: TacoButton_Types.Left.value,
        buttonStyle: TacoButton_Types.Link.value,
        children: Data_Maybe.Nothing.value,
        color: (function () {
            if (v.active) {
                return v.color;
            };
            return TacoConstants.TextSecondary.value;
        })(),
        disabled: false,
        text: "",
        size: TacoConstants.M.value,
        vertical: false,
        styles: [  ]
    };
    return Box.boxStyle([ Box.Style.create(TacoButton_Styles.buttonStyles(buttonStyleProps)), new Box.PaddingBottom(TacoConstants.S.value), new Box.PaddingTop(TacoConstants.M.value), Box.PaddingX.create(new TacoConstants.CustomSize("0px")), new Box.MarginX(TacoConstants.M.value), Box.FlexRow.value, Box.JustifySpaceBetween.value, Data_Monoid.guard(Box.monoidBoxProp)(Data_Eq.eq(TacoTabs_Types.eqTabsOrientation)(v.orientation)(TacoTabs_Types.Vertical.value))(Box.JustifyEnd.value), Box.Style.create(React_Basic_Emotion.css()({
        border: StyleProperties.none,
        borderBottom: React_Basic_Emotion.str("3px solid " + TacoConstants.colorSchemeToStr((function () {
            if (v.active) {
                return v.color;
            };
            return TacoConstants.Transparent.value;
        })()))
    })) ]);
};
var quantityStyles = Box.boxStyle([ new Box.PaddingLeft(TacoConstants.M.value), Box.Style.create(React_Basic_Emotion.css()({
    alignSelf: React_Basic_Emotion.str("flex-end")
})) ]);
var contentsStyles = function (v) {
    return Box.boxStyle([ new Box.FlexGrow(1), Box.FlexColumn.value, Box.MinHeight.create(new TacoConstants.CustomSize("0px")) ]);
};
var containerStyles = function (v) {
    return Box.boxStyle([ (function () {
        if (v.orientation instanceof TacoTabs_Types.Vertical) {
            return Box.FlexRow.value;
        };
        if (v.orientation instanceof TacoTabs_Types.Horizontal) {
            return Box.FlexColumn.value;
        };
        throw new Error("Failed pattern match at TacoTabs.Styles (line 22, column 5 - line 24, column 31): " + [ v.orientation.constructor.name ]);
    })(), Box.Width.create(new TacoConstants.CustomSize("100%")), Box.Height.create(new TacoConstants.CustomSize("100%")), Box.MinHeight.create(new TacoConstants.CustomSize("0px")) ]);
};
module.exports = {
    contentsStyles: contentsStyles,
    containerStyles: containerStyles,
    tabsContainerStyles: tabsContainerStyles,
    tabStyles: tabStyles,
    quantityStyles: quantityStyles
};
