import { Button, IconAddPlusV1 } from "@tocoman/ui";
import { useTranslation } from "react-i18next";
type AddUsersButtonProps = {
  handleEditUserClick: () => void;
};
export const AddUsersButton = ({
  handleEditUserClick,
}: AddUsersButtonProps) => {
  const { t } = useTranslation("admin", { keyPrefix: "users" });

  return (
    <div className={"flex flex-row gap-2 py-3"}>
      <Button
        variant="text"
        label={t`addUser`}
        onClick={handleEditUserClick}
        icon={IconAddPlusV1}
      />
    </div>
  );
};
