// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Effect = require("../Effect/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Utils_Keyboard = require("../Utils.Keyboard/index.js");
var useKeydownListener = function (reactKey) {
    return function (isActive) {
        return function (keyboardEventCodes) {
            return function (key) {
                return function (handler) {
                    var deps = Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_Functor.map(Data_Functor.functorArray)(ReactHooksUtils.utf)(reactKey))([ ReactHooksUtils.utf(isActive) ]);
                    return TofuHooks.useEffect(deps)((function () {
                        if (isActive) {
                            return function __do() {
                                Utils_Keyboard.registerKeyHandler(keyboardEventCodes)(key)(handler)();
                                return Utils_Keyboard.deregisterKeyHandler(key);
                            };
                        };
                        if (!isActive) {
                            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)));
                        };
                        throw new Error("Failed pattern match at UseKeydownListener (line 20, column 5 - line 24, column 27): " + [ isActive.constructor.name ]);
                    })());
                };
            };
        };
    };
};
module.exports = {
    useKeydownListener: useKeydownListener
};
