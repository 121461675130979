import { Popover } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { Button, IconChevronDownV1 } from "@tocoman/ui";
import { useTranslation } from "react-i18next";
import { AdditionalRow } from "./PremiumOfferPageTable";

type PremiumOfferButtonsProps = {
  data: AdditionalRow[] | null;
  handleVisibleChange: (code: string, visible: boolean) => void;
  handleSpecialRowChange: Dispatch<SetStateAction<AdditionalRow[]>>;
};

export const PremiumOfferButtons = ({
  data,
  handleVisibleChange,
  handleSpecialRowChange,
}: PremiumOfferButtonsProps) => {
  const { t } = useTranslation("estimation", { keyPrefix: "offerPage" });

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isAddRowPopoverOpen, setIsAddRowPopoverOpen] = useState(false);
  const handleOpenAddRowPopover = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
    setIsAddRowPopoverOpen(true);
  };

  const handleAddNewRowClicked = (type: string) => {
    if (type !== "specialContract") {
      handleVisibleChange(type, true);
    } else {
      handleSpecialRowChange((prev) => {
        // Find the maximum index of existing special rows
        const maxIndex = prev.reduce((max, row) => {
          const match = row.code.match(/specialContract\.(\d+)/);
          if (match) {
            return Math.max(max, parseInt(match[1], 10));
          }
          return max;
        }, -1);

        return [
          ...prev,
          {
            code: `specialContract.${maxIndex + 1}`,
            description: t("specialContract"),
            baseTotal: null,
            additionalTotal: 0,
            totalWithAdditionalCosts: null,
            visible: true,
            costClasses: [],
            measurements: [],
            costItems: [],
          },
        ];
      });
    }
    setIsAddRowPopoverOpen(false);
  };

  const addNewRowItems = (
    <div className={"flex flex-col justify-start"}>
      <Button
        label={t("risk")}
        disabled={data?.some((row) => row?.code === "risk")}
        onClick={() => handleAddNewRowClicked("risk")}
        color={"gray"}
        variant={"text"}
      />
      <Button
        label={t("general")}
        disabled={data?.some((row) => row?.code === "general")}
        onClick={() => handleAddNewRowClicked("general")}
        color={"gray"}
        variant={"text"}
      />
      <Button
        label={t("change")}
        disabled={data?.some((row) => row?.code === "change")}
        onClick={() => handleAddNewRowClicked("change")}
        color={"gray"}
        variant={"text"}
      />
      <Button
        label={t("specialContract")}
        onClick={() => handleAddNewRowClicked("specialContract")}
        color={"gray"}
        variant={"text"}
      />
      <Button
        label={t("margin")}
        disabled={data?.some((row) => row?.code === "margin")}
        onClick={() => handleAddNewRowClicked("margin")}
        color={"gray"}
        variant={"text"}
      />
    </div>
  );
  return (
    <div className={"float-right mr-5"}>
      <Button
        label={t("addNewRow")}
        onClick={handleOpenAddRowPopover}
        icon={IconChevronDownV1}
      />
      <Popover
        open={isAddRowPopoverOpen}
        anchorEl={anchorEl}
        onClose={() => setIsAddRowPopoverOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{
          paper: "flex flex-col p-[5px]",
        }}
      >
        {addNewRowItems}
      </Popover>
    </div>
  );
};
