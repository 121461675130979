// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var TacoButton_Styles = require("../TacoButton.Styles/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var sharedStyles = Box.boxStyle([ new Box.Margin(TacoConstants.M.value), new Box.Padding(TacoConstants.S.value), new Box.Border(TacoConstants.BorderLight.value), new Box.Position(Box.Absolute.value), Box.MarginTop.create(new TacoConstants.CustomSize("4.5rem")), Box.MaxHeight.create(new TacoConstants.CustomSize("calc(100% - 5.5rem)")), new Box.Media(Box.TabletL.value, [ new Box.Margin(TacoConstants.M.value), Box.MaxHeight.create(new TacoConstants.CustomSize("calc(100% - 2rem)")) ]) ]);
var sidepanelContainerStyles = Box.boxStyle([ new Box.FlexGrow(1), new Box.FlexBasis("0"), Box.FlexColumn.value, Box.Width.create(new TacoConstants.CustomSize("24rem")), new Box.BorderRadius(TacoConstants.XS.value), new Box.BackgroundColor(TacoConstants.White.value), Box.Style.create(React_Basic_Emotion.css()({
    boxShadow: React_Basic_Emotion.str("0px 5px 10px rgba(0, 0, 0, 0.06)")
})), new Box.Style(sharedStyles), Box.Padding.create(new TacoConstants.CustomSize("0")) ]);
var smallContainerStyles = Box.boxStyle([ Box.Style.create(TacoButton_Styles.buttonStyles({
    align: TacoButton_Types.Left.value,
    buttonStyle: TacoButton_Types.Filled.value,
    children: Data_Maybe.Nothing.value,
    color: TacoConstants.GrayLightest.value,
    disabled: false,
    text: "",
    size: TacoConstants.L.value,
    vertical: false,
    styles: [  ]
})), Box.Width.create(new TacoConstants.CustomSize("16rem")), new Box.Style(sharedStyles) ]);
module.exports = {
    sharedStyles: sharedStyles,
    smallContainerStyles: smallContainerStyles,
    sidepanelContainerStyles: sidepanelContainerStyles
};
