// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Actions = require("../Actions/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var NarrowLayout = require("../NarrowLayout/index.js");
var OrganizationUsersTable = require("../OrganizationUsersTable/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoLoader = require("../TacoLoader/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_Organization = require("../Types.Organization/index.js");
var UseFetch = require("../UseFetch/index.js");
var UseLocalStorage = require("../UseLocalStorage/index.js");
var UserRolesTable = require("../UserRolesTable/index.js");
var component = TofuHooks.mkHookComponent("OrganizationManagementContainer")(function (v) {
    return function __do() {
        var appDispatch = StateHooks.useDispatch();
        var v1 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)("new-admin-view")(true)(false)();
        var v2 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v2) {
            return v2.currentOrganization;
        })(Actions.GetCurrentOrganizationRequest.value)();
        var currentOrganization = Data_Functor.map(Data_Maybe.functorMaybe)(function (v3) {
            return v3.organization;
        })(v2.value0);
        var v3 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v3) {
            return v3.organizationAdminInfo;
        })(Actions.GetOrganizationAdminInfoRequest.value)();
        var v4 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v4) {
            return v4.organizationUsers;
        })(Actions.GetOrganizationUsersRequest.value)();
        var v5 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v5) {
            return v5.userRoles;
        })(Actions.GetUserRolesRequest.value)();
        var v6 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v6) {
            return v6.roleAssignments;
        })(Actions.GetRoleAssignmentsRequest.value)();
        var tableSection = function (jsx) {
            return React_Basic_DOM_Generated.div()({
                className: "table-section",
                children: Control_Applicative.pure(Control_Applicative.applicativeArray)(jsx)
            });
        };
        var newAdminComponent = React_Basic.element($foreign.newTypescriptAdmin)({});
        var changeViewButtonText = (function () {
            if (v1.value0) {
                return "Palaa vanhaan k\xe4ytt\xe4j\xe4nhallinnan k\xe4ytt\xf6liittym\xe4\xe4n painamalla t\xe4st\xe4!";
            };
            return "Kokeile uutta k\xe4ytt\xe4j\xe4hallinnan k\xe4ytt\xf6liittym\xe4\xe4 painamalla t\xe4st\xe4!";
        })();
        return NarrowLayout.component()()({
            children: (function () {
                if (currentOrganization instanceof Data_Maybe.Just && v3.value0 instanceof Data_Maybe.Just) {
                    return [ TacoButton.component()()({
                        buttonStyle: TacoButton_Types.Outlined.value,
                        className: "flex flex-grow items-center justify-center p-4 w-full max-w-screen-2xl mb-16 border border-light bg-slate-100",
                        children: new Data_Maybe.Just([ TacoText.component()()({
                            text: changeViewButtonText,
                            variant: new TacoText_Types.Heading(3)
                        }) ]),
                        onClick: Data_Maybe.Just.create(v1.value1(function (old) {
                            return !old;
                        }))
                    }), Data_Monoid.guard(Data_Monoid.monoidFn(React_Basic.monoidJSX))(v1.value0)(React_Basic_DOM_Generated.div())({
                        className: "flex flex-col h-full w-full max-w-screen-2xl justify-center",
                        children: [ newAdminComponent ]
                    }), Data_Monoid.guard(Data_Monoid.monoidFn(React_Basic.monoidJSX))(!v1.value0)(React_Basic_DOM_Generated.div())({
                        children: [ TacoText.component()()({
                            text: "Hallinnoitava organisaatio: " + Data_Newtype.un()(Types_Organization.Organization)(currentOrganization.value0),
                            variant: new TacoText_Types.Heading(1),
                            weight: TacoText_Types.Bold.value,
                            gutterBottom: true
                        }) ],
                        className: "org-selection-container"
                    }), Data_Monoid.guard(Data_Monoid.monoidFn(React_Basic.monoidJSX))(!v1.value0)(tableSection)(OrganizationUsersTable.component({
                        dispatch: appDispatch,
                        users: Data_Maybe.fromMaybe([  ])(v4.value0),
                        organization: currentOrganization.value0,
                        roles: Data_Maybe.fromMaybe([  ])(v5.value0),
                        roleAssignments: Data_Maybe.fromMaybe([  ])(v6.value0),
                        organizationAdminInfo: v3.value0.value0
                    })), Data_Monoid.guard(Data_Monoid.monoidFn(React_Basic.monoidJSX))(!v1.value0)(tableSection)(UserRolesTable.component({
                        dispatch: appDispatch,
                        userRoles: Data_Maybe.fromMaybe([  ])(v5.value0),
                        organization: currentOrganization.value0
                    })) ];
                };
                return [ TacoLoader.component()()({}) ];
            })(),
            verticalPadding: true,
            fullWidth: (function () {
                if (v1.value0) {
                    return true;
                };
                return false;
            })(),
            typescriptChildComponent: (function () {
                if (v1.value0) {
                    return true;
                };
                return false;
            })()
        });
    };
});
module.exports = {
    component: component
};
