// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Effect = require("../Effect/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var component = TofuHooks.mkHookComponent("TsFromPurs")(function (v) {
    return Control_Applicative.pure(Effect.applicativeEffect)($foreign.tsFromPurs({
        initValue: Data_Nullable.notNull(42.0)
    }));
});
module.exports = {
    component: component,
    tsFromPurs: $foreign.tsFromPurs
};
