// Generated by purs version 0.14.5
"use strict";
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var DixonMeasurements_Types = require("../DixonMeasurements.Types/index.js");
var Kishimen = require("../Kishimen/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_Project = require("../Types.Project/index.js");
var DixonGetEstimationComponentsRequest = (function () {
    function DixonGetEstimationComponentsRequest(value0) {
        this.value0 = value0;
    };
    DixonGetEstimationComponentsRequest.create = function (value0) {
        return new DixonGetEstimationComponentsRequest(value0);
    };
    return DixonGetEstimationComponentsRequest;
})();
var DixonGetEstimationElementsRequest = (function () {
    function DixonGetEstimationElementsRequest(value0) {
        this.value0 = value0;
    };
    DixonGetEstimationElementsRequest.create = function (value0) {
        return new DixonGetEstimationElementsRequest(value0);
    };
    return DixonGetEstimationElementsRequest;
})();
var DixonGetEstimationElementSpecsRequest = (function () {
    function DixonGetEstimationElementSpecsRequest(value0) {
        this.value0 = value0;
    };
    DixonGetEstimationElementSpecsRequest.create = function (value0) {
        return new DixonGetEstimationElementSpecsRequest(value0);
    };
    return DixonGetEstimationElementSpecsRequest;
})();
var DixonGetEstimationDataRequest = (function () {
    function DixonGetEstimationDataRequest(value0) {
        this.value0 = value0;
    };
    DixonGetEstimationDataRequest.create = function (value0) {
        return new DixonGetEstimationDataRequest(value0);
    };
    return DixonGetEstimationDataRequest;
})();
var DixonGetDeferredEstimationDataRequest = (function () {
    function DixonGetDeferredEstimationDataRequest(value0) {
        this.value0 = value0;
    };
    DixonGetDeferredEstimationDataRequest.create = function (value0) {
        return new DixonGetDeferredEstimationDataRequest(value0);
    };
    return DixonGetDeferredEstimationDataRequest;
})();
var DixonPromptActiveDrawingScale = (function () {
    function DixonPromptActiveDrawingScale(value0) {
        this.value0 = value0;
    };
    DixonPromptActiveDrawingScale.create = function (value0) {
        return new DixonPromptActiveDrawingScale(value0);
    };
    return DixonPromptActiveDrawingScale;
})();
var ForceRedraw = (function () {
    function ForceRedraw() {

    };
    ForceRedraw.value = new ForceRedraw();
    return ForceRedraw;
})();
var SetLoading = (function () {
    function SetLoading(value0) {
        this.value0 = value0;
    };
    SetLoading.create = function (value0) {
        return new SetLoading(value0);
    };
    return SetLoading;
})();
var DixonAddAlertNotification = (function () {
    function DixonAddAlertNotification(value0) {
        this.value0 = value0;
    };
    DixonAddAlertNotification.create = function (value0) {
        return new DixonAddAlertNotification(value0);
    };
    return DixonAddAlertNotification;
})();
var DixonAddInfoNotification = (function () {
    function DixonAddInfoNotification(value0) {
        this.value0 = value0;
    };
    DixonAddInfoNotification.create = function (value0) {
        return new DixonAddInfoNotification(value0);
    };
    return DixonAddInfoNotification;
})();
var supportedUnits = [ {
    text: "kpl",
    value: "kpl"
}, {
    text: "m",
    value: "m"
}, {
    text: "m\xb2",
    value: "m\xb2"
} ];
var genericDixonAction = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new DixonGetEstimationComponentsRequest(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new DixonGetEstimationElementsRequest(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return new DixonGetEstimationElementSpecsRequest(x.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return new DixonGetEstimationDataRequest(x.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
            return new DixonGetDeferredEstimationDataRequest(x.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))) {
            return new DixonPromptActiveDrawingScale(x.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))) {
            return ForceRedraw.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))) {
            return new SetLoading(x.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))))) {
            return new DixonAddAlertNotification(x.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr)))))))) {
            return new DixonAddInfoNotification(x.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        throw new Error("Failed pattern match at DixonContainer.Actions (line 26, column 1 - line 26, column 60): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof DixonGetEstimationComponentsRequest) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof DixonGetEstimationElementsRequest) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof DixonGetEstimationElementSpecsRequest) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
        };
        if (x instanceof DixonGetEstimationDataRequest) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))));
        };
        if (x instanceof DixonGetDeferredEstimationDataRequest) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))));
        };
        if (x instanceof DixonPromptActiveDrawingScale) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))));
        };
        if (x instanceof ForceRedraw) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))))));
        };
        if (x instanceof SetLoading) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))))));
        };
        if (x instanceof DixonAddAlertNotification) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))))))));
        };
        if (x instanceof DixonAddInfoNotification) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0)))))))));
        };
        throw new Error("Failed pattern match at DixonContainer.Actions (line 26, column 1 - line 26, column 60): " + [ x.constructor.name ]);
    }
};
var writeDixonAction = {
    writeImpl: (function () {
        var $115 = TofuJSON.writeImpl(TofuJSON.tofuJSONVariant()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "DixonAddAlertNotification";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "msg";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "msg";
            }
        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "DixonAddInfoNotification";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "msg";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "msg";
            }
        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "DixonGetDeferredEstimationDataRequest";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "DixonGetEstimationComponentsRequest";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "DixonGetEstimationDataRequest";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "DixonGetEstimationElementSpecsRequest";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "DixonGetEstimationElementsRequest";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "DixonPromptActiveDrawingScale";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "shape";
            }
        })(DixonMeasurements_Types.tofuJSONShape)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "unscaledMeasurementValuePx";
            }
        })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "shape";
            }
        })(DixonMeasurements_Types.tofuJSONShape)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "unscaledMeasurementValuePx";
            }
        })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "ForceRedraw";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "SetLoading";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "b";
            }
        })(TofuJSON.readBoolean)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "b";
            }
        })(TofuJSON.readBoolean)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantNil)))))))))))(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "DixonAddAlertNotification";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "msg";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "msg";
            }
        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "DixonAddInfoNotification";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "msg";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "msg";
            }
        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "DixonGetDeferredEstimationDataRequest";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "DixonGetEstimationComponentsRequest";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "DixonGetEstimationDataRequest";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "DixonGetEstimationElementSpecsRequest";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "DixonGetEstimationElementsRequest";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "DixonPromptActiveDrawingScale";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "shape";
            }
        })(DixonMeasurements_Types.tofuJSONShape)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "unscaledMeasurementValuePx";
            }
        })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "shape";
            }
        })(DixonMeasurements_Types.tofuJSONShape)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "unscaledMeasurementValuePx";
            }
        })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "ForceRedraw";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "SetLoading";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "b";
            }
        })(TofuJSON.readBoolean)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "b";
            }
        })(TofuJSON.readBoolean)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.nilWriteForeignVariant))))))))))));
        var $116 = Kishimen.genericSumToVariant(genericDixonAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "DixonGetEstimationComponentsRequest";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "DixonGetEstimationElementsRequest";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "DixonGetEstimationElementSpecsRequest";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "DixonGetEstimationDataRequest";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "DixonGetDeferredEstimationDataRequest";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "DixonPromptActiveDrawingScale";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "ForceRedraw";
            }
        })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "SetLoading";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "DixonAddAlertNotification";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "DixonAddInfoNotification";
            }
        })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "DixonAddAlertNotification";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "DixonAddInfoNotification";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SetLoading";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "DixonAddInfoNotification";
            }
        }))({
            reflectSymbol: function () {
                return "DixonAddAlertNotification";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "ForceRedraw";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SetLoading";
            }
        }))({
            reflectSymbol: function () {
                return "DixonAddInfoNotification";
            }
        }))({
            reflectSymbol: function () {
                return "DixonAddAlertNotification";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "DixonPromptActiveDrawingScale";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SetLoading";
            }
        }))({
            reflectSymbol: function () {
                return "ForceRedraw";
            }
        }))({
            reflectSymbol: function () {
                return "DixonAddInfoNotification";
            }
        }))({
            reflectSymbol: function () {
                return "DixonAddAlertNotification";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "DixonGetDeferredEstimationDataRequest";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SetLoading";
            }
        }))({
            reflectSymbol: function () {
                return "ForceRedraw";
            }
        }))({
            reflectSymbol: function () {
                return "DixonPromptActiveDrawingScale";
            }
        }))({
            reflectSymbol: function () {
                return "DixonAddInfoNotification";
            }
        }))({
            reflectSymbol: function () {
                return "DixonAddAlertNotification";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "DixonGetEstimationDataRequest";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SetLoading";
            }
        }))({
            reflectSymbol: function () {
                return "ForceRedraw";
            }
        }))({
            reflectSymbol: function () {
                return "DixonPromptActiveDrawingScale";
            }
        }))({
            reflectSymbol: function () {
                return "DixonGetDeferredEstimationDataRequest";
            }
        }))({
            reflectSymbol: function () {
                return "DixonAddInfoNotification";
            }
        }))({
            reflectSymbol: function () {
                return "DixonAddAlertNotification";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "DixonGetEstimationElementSpecsRequest";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SetLoading";
            }
        }))({
            reflectSymbol: function () {
                return "ForceRedraw";
            }
        }))({
            reflectSymbol: function () {
                return "DixonPromptActiveDrawingScale";
            }
        }))({
            reflectSymbol: function () {
                return "DixonGetEstimationDataRequest";
            }
        }))({
            reflectSymbol: function () {
                return "DixonGetDeferredEstimationDataRequest";
            }
        }))({
            reflectSymbol: function () {
                return "DixonAddInfoNotification";
            }
        }))({
            reflectSymbol: function () {
                return "DixonAddAlertNotification";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "DixonGetEstimationElementsRequest";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SetLoading";
            }
        }))({
            reflectSymbol: function () {
                return "ForceRedraw";
            }
        }))({
            reflectSymbol: function () {
                return "DixonPromptActiveDrawingScale";
            }
        }))({
            reflectSymbol: function () {
                return "DixonGetEstimationElementSpecsRequest";
            }
        }))({
            reflectSymbol: function () {
                return "DixonGetEstimationDataRequest";
            }
        }))({
            reflectSymbol: function () {
                return "DixonGetDeferredEstimationDataRequest";
            }
        }))({
            reflectSymbol: function () {
                return "DixonAddInfoNotification";
            }
        }))({
            reflectSymbol: function () {
                return "DixonAddAlertNotification";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "DixonGetEstimationComponentsRequest";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SetLoading";
            }
        }))({
            reflectSymbol: function () {
                return "ForceRedraw";
            }
        }))({
            reflectSymbol: function () {
                return "DixonPromptActiveDrawingScale";
            }
        }))({
            reflectSymbol: function () {
                return "DixonGetEstimationElementsRequest";
            }
        }))({
            reflectSymbol: function () {
                return "DixonGetEstimationElementSpecsRequest";
            }
        }))({
            reflectSymbol: function () {
                return "DixonGetEstimationDataRequest";
            }
        }))({
            reflectSymbol: function () {
                return "DixonGetDeferredEstimationDataRequest";
            }
        }))({
            reflectSymbol: function () {
                return "DixonAddInfoNotification";
            }
        }))({
            reflectSymbol: function () {
                return "DixonAddAlertNotification";
            }
        })));
        return function ($117) {
            return $115($116($117));
        };
    })(),
    readImpl: (function () {
        var $118 = Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Kishimen.variantToGenericSum(genericDixonAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "DixonGetEstimationComponentsRequest";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "DixonGetEstimationElementsRequest";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "DixonGetEstimationElementSpecsRequest";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "DixonGetEstimationDataRequest";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "DixonGetDeferredEstimationDataRequest";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "DixonPromptActiveDrawingScale";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "ForceRedraw";
            }
        })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "SetLoading";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "DixonAddAlertNotification";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "DixonAddInfoNotification";
            }
        })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "DixonAddAlertNotification";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "DixonAddInfoNotification";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SetLoading";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "DixonAddInfoNotification";
            }
        }))({
            reflectSymbol: function () {
                return "DixonAddAlertNotification";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "ForceRedraw";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SetLoading";
            }
        }))({
            reflectSymbol: function () {
                return "DixonAddInfoNotification";
            }
        }))({
            reflectSymbol: function () {
                return "DixonAddAlertNotification";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "DixonPromptActiveDrawingScale";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SetLoading";
            }
        }))({
            reflectSymbol: function () {
                return "ForceRedraw";
            }
        }))({
            reflectSymbol: function () {
                return "DixonAddInfoNotification";
            }
        }))({
            reflectSymbol: function () {
                return "DixonAddAlertNotification";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "DixonGetDeferredEstimationDataRequest";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SetLoading";
            }
        }))({
            reflectSymbol: function () {
                return "ForceRedraw";
            }
        }))({
            reflectSymbol: function () {
                return "DixonPromptActiveDrawingScale";
            }
        }))({
            reflectSymbol: function () {
                return "DixonAddInfoNotification";
            }
        }))({
            reflectSymbol: function () {
                return "DixonAddAlertNotification";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "DixonGetEstimationDataRequest";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SetLoading";
            }
        }))({
            reflectSymbol: function () {
                return "ForceRedraw";
            }
        }))({
            reflectSymbol: function () {
                return "DixonPromptActiveDrawingScale";
            }
        }))({
            reflectSymbol: function () {
                return "DixonGetDeferredEstimationDataRequest";
            }
        }))({
            reflectSymbol: function () {
                return "DixonAddInfoNotification";
            }
        }))({
            reflectSymbol: function () {
                return "DixonAddAlertNotification";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "DixonGetEstimationElementSpecsRequest";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SetLoading";
            }
        }))({
            reflectSymbol: function () {
                return "ForceRedraw";
            }
        }))({
            reflectSymbol: function () {
                return "DixonPromptActiveDrawingScale";
            }
        }))({
            reflectSymbol: function () {
                return "DixonGetEstimationDataRequest";
            }
        }))({
            reflectSymbol: function () {
                return "DixonGetDeferredEstimationDataRequest";
            }
        }))({
            reflectSymbol: function () {
                return "DixonAddInfoNotification";
            }
        }))({
            reflectSymbol: function () {
                return "DixonAddAlertNotification";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "DixonGetEstimationElementsRequest";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SetLoading";
            }
        }))({
            reflectSymbol: function () {
                return "ForceRedraw";
            }
        }))({
            reflectSymbol: function () {
                return "DixonPromptActiveDrawingScale";
            }
        }))({
            reflectSymbol: function () {
                return "DixonGetEstimationElementSpecsRequest";
            }
        }))({
            reflectSymbol: function () {
                return "DixonGetEstimationDataRequest";
            }
        }))({
            reflectSymbol: function () {
                return "DixonGetDeferredEstimationDataRequest";
            }
        }))({
            reflectSymbol: function () {
                return "DixonAddInfoNotification";
            }
        }))({
            reflectSymbol: function () {
                return "DixonAddAlertNotification";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "DixonGetEstimationComponentsRequest";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SetLoading";
            }
        }))({
            reflectSymbol: function () {
                return "ForceRedraw";
            }
        }))({
            reflectSymbol: function () {
                return "DixonPromptActiveDrawingScale";
            }
        }))({
            reflectSymbol: function () {
                return "DixonGetEstimationElementsRequest";
            }
        }))({
            reflectSymbol: function () {
                return "DixonGetEstimationElementSpecsRequest";
            }
        }))({
            reflectSymbol: function () {
                return "DixonGetEstimationDataRequest";
            }
        }))({
            reflectSymbol: function () {
                return "DixonGetDeferredEstimationDataRequest";
            }
        }))({
            reflectSymbol: function () {
                return "DixonAddInfoNotification";
            }
        }))({
            reflectSymbol: function () {
                return "DixonAddAlertNotification";
            }
        }))));
        var $119 = TofuJSON.readImpl(TofuJSON.tofuJSONVariant()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "DixonAddAlertNotification";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "msg";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "msg";
            }
        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "DixonAddInfoNotification";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "msg";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "msg";
            }
        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "DixonGetDeferredEstimationDataRequest";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "DixonGetEstimationComponentsRequest";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "DixonGetEstimationDataRequest";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "DixonGetEstimationElementSpecsRequest";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "DixonGetEstimationElementsRequest";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "DixonPromptActiveDrawingScale";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "shape";
            }
        })(DixonMeasurements_Types.tofuJSONShape)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "unscaledMeasurementValuePx";
            }
        })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "shape";
            }
        })(DixonMeasurements_Types.tofuJSONShape)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "unscaledMeasurementValuePx";
            }
        })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "ForceRedraw";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "SetLoading";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "b";
            }
        })(TofuJSON.readBoolean)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "b";
            }
        })(TofuJSON.readBoolean)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantNil)))))))))))(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "DixonAddAlertNotification";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "msg";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "msg";
            }
        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "DixonAddInfoNotification";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "msg";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "msg";
            }
        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "DixonGetDeferredEstimationDataRequest";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "DixonGetEstimationComponentsRequest";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "DixonGetEstimationDataRequest";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "DixonGetEstimationElementSpecsRequest";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "DixonGetEstimationElementsRequest";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "DixonPromptActiveDrawingScale";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "shape";
            }
        })(DixonMeasurements_Types.tofuJSONShape)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "unscaledMeasurementValuePx";
            }
        })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "shape";
            }
        })(DixonMeasurements_Types.tofuJSONShape)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "unscaledMeasurementValuePx";
            }
        })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "ForceRedraw";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "SetLoading";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "b";
            }
        })(TofuJSON.readBoolean)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "b";
            }
        })(TofuJSON.readBoolean)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.nilWriteForeignVariant))))))))))));
        return function ($120) {
            return $118($119($120));
        };
    })()
};
var eqDixonAction = {
    eq: function (x) {
        return function (y) {
            if (x instanceof DixonGetEstimationComponentsRequest && y instanceof DixonGetEstimationComponentsRequest) {
                return Data_Eq.eq(Types_Project.eqProjectId)(x.value0.projectId)(y.value0.projectId);
            };
            if (x instanceof DixonGetEstimationElementsRequest && y instanceof DixonGetEstimationElementsRequest) {
                return Data_Eq.eq(Types_Project.eqProjectId)(x.value0.projectId)(y.value0.projectId);
            };
            if (x instanceof DixonGetEstimationElementSpecsRequest && y instanceof DixonGetEstimationElementSpecsRequest) {
                return Data_Eq.eq(Types_Project.eqProjectId)(x.value0.projectId)(y.value0.projectId);
            };
            if (x instanceof DixonGetEstimationDataRequest && y instanceof DixonGetEstimationDataRequest) {
                return Data_Eq.eq(Types_Project.eqProjectId)(x.value0.projectId)(y.value0.projectId);
            };
            if (x instanceof DixonGetDeferredEstimationDataRequest && y instanceof DixonGetDeferredEstimationDataRequest) {
                return Data_Eq.eq(Types_Project.eqProjectId)(x.value0.projectId)(y.value0.projectId);
            };
            if (x instanceof DixonPromptActiveDrawingScale && y instanceof DixonPromptActiveDrawingScale) {
                return Data_Eq.eq(DixonMeasurements_Types.eqShape)(x.value0.shape)(y.value0.shape) && x.value0.unscaledMeasurementValuePx === y.value0.unscaledMeasurementValuePx;
            };
            if (x instanceof ForceRedraw && y instanceof ForceRedraw) {
                return true;
            };
            if (x instanceof SetLoading && y instanceof SetLoading) {
                return x.value0.b === y.value0.b;
            };
            if (x instanceof DixonAddAlertNotification && y instanceof DixonAddAlertNotification) {
                return x.value0.msg === y.value0.msg;
            };
            if (x instanceof DixonAddInfoNotification && y instanceof DixonAddInfoNotification) {
                return x.value0.msg === y.value0.msg;
            };
            return false;
        };
    }
};
module.exports = {
    DixonGetEstimationComponentsRequest: DixonGetEstimationComponentsRequest,
    DixonGetEstimationElementsRequest: DixonGetEstimationElementsRequest,
    DixonGetEstimationElementSpecsRequest: DixonGetEstimationElementSpecsRequest,
    DixonGetEstimationDataRequest: DixonGetEstimationDataRequest,
    DixonGetDeferredEstimationDataRequest: DixonGetDeferredEstimationDataRequest,
    DixonPromptActiveDrawingScale: DixonPromptActiveDrawingScale,
    ForceRedraw: ForceRedraw,
    SetLoading: SetLoading,
    DixonAddAlertNotification: DixonAddAlertNotification,
    DixonAddInfoNotification: DixonAddInfoNotification,
    supportedUnits: supportedUnits,
    genericDixonAction: genericDixonAction,
    eqDixonAction: eqDixonAction,
    writeDixonAction: writeDixonAction
};
