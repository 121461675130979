// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Controls = require("../Controls/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var InvoiceTypeFilter = require("../InvoiceTypeFilter/index.js");
var InvoiceUtils = require("../InvoiceUtils/index.js");
var InvoicesTable = require("../InvoicesTable/index.js");
var React_Basic = require("../React.Basic/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var invoicesTab = TofuHooks.mkHookComponent("InvoicesTab")(function (props) {
    return function __do() {
        var v = TofuHooks.useState(true)();
        var v1 = TofuHooks.useState(true)();
        StateHooks.useDispatch();
        var state = {
            showSalaryItems: v.value0,
            showInvoicesItems: v1.value0
        };
        var setState = {
            setShowSalaryItems: v.value1,
            setShowInvoicesItems: v1.value1
        };
        var groupFilters = InvoiceTypeFilter.mkInvoiceTypeFilters({
            state: state,
            setState: setState
        });
        var filteredInvoices = InvoiceTypeFilter.filterInvoiceItemsByType(state)(props.invoiceLinesWithSocialExpenses);
        var v2 = TofuHooks.useState({
            sortingOrder: TacoTable_Types.Descending.value,
            sortProperty: InvoiceUtils.ByDate.value
        })();
        var setSortingCriteria = function (property) {
            return v2.value1(function (s) {
                var $10 = Data_Eq.eq(InvoiceUtils.eqInvoiceLineSortProperty)(s.sortProperty)(property);
                if ($10) {
                    return {
                        sortingOrder: TacoTable_Types.reverse(s.sortingOrder),
                        sortProperty: property
                    };
                };
                return {
                    sortingOrder: s.sortingOrder,
                    sortProperty: property
                };
            });
        };
        var filteredPurchaseInvoices = InvoiceUtils.createInvoiceResults({
            sortingOrder: v2.value0.sortingOrder,
            sortProperty: v2.value0.sortProperty
        })(filteredInvoices);
        var invoiceData = new InvoiceUtils.InvoiceLinesByInvoice(filteredPurchaseInvoices);
        return React_Basic.fragment([ Controls.component()()({
            leftControls: [ groupFilters ],
            bottomGutter: true
        }), InvoicesTable.invoicesTable({
            invoiceData: invoiceData,
            projectId: props.projectId,
            selectedInvoiceLineIds: [  ],
            onInvoiceLinesSelect: function (v3) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            },
            onInvoiceLinesDeselect: function (v3) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            },
            sortingOrder: v2.value0.sortingOrder,
            sortProperty: v2.value0.sortProperty,
            onSort: setSortingCriteria,
            showInvoiceBunchIds: false,
            showSupplierInvoiceNumbers: false,
            showQuantityColumn: false,
            showUnitColumn: false,
            showUnitPriceColumn: false,
            showCheckboxes: false,
            filteredRows: filteredPurchaseInvoices
        }) ]);
    };
});
module.exports = {
    invoicesTab: invoicesTab
};
