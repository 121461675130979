/* eslint-disable i18next/no-literal-string */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { ForgotPassword } from "aws-amplify-react";
import { getBackBtn } from "./Utils";
import bg from "../../../assets/Admicom-login-bg.svg";
import logo from "../../../assets/estima-logo-light.svg";

export class CustomForgotPassword extends ForgotPassword {
  constructor(props) {
    super(props);
    this.state = { delivery: null };
    this._validAuthStates = ["forgotPassword"];
    this.onSend = this.onSend.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getBackBtn = getBackBtn.bind(this);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  showComponent(theme) {
    const { authData = {} } = this.props;
    return (
      <div
        className="login login-container"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <img alt="logo" src={logo} className="logo" />
        {this.state.delivery || authData.username
          ? this.submitView()
          : this.sendView()}
      </div>
    );
  }

  onSend(event) {
    event.preventDefault();
    this.send();
  }

  onSubmit(event) {
    event.preventDefault();
    this.submit();
  }

  sendView() {
    return (
      <div
        className={"login-page-wrap"}
        style={{ backgroundImage: `url(${bg})` }}
      >
        <form className="login-panel">
          <h1 className="header">Unohtunut salasana</h1>
          <p>
            Anna sähköpostiosoitteesi, niin lähetämme sinulle koodin salasanasi
            vaihtamiseen.
          </p>

          <div className="label-container">
            <label htmlFor="name">Sähköpostiosoite</label>
          </div>
          <div className="text-field">
            <input
              autoFocus
              // type="email"
              key="username"
              name="username"
              id="reset-email"
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-actions">
            <button onClick={this.onSend} className="btn login-btn">
              Lähetä
            </button>
          </div>
          {this.getBackBtn()}
        </form>
      </div>
    );
  }

  submitView() {
    return (
      <div className={"login-page-wrap"}>
        <form className="login-panel">
          <h1 className="header">Salasanan palautus</h1>
          <p>
            Lähetimme sinulle juuri sähköpostin. Syötä siinä oleva koodi ja
            haluamasi uusi salasana.
          </p>

          <div className="label-container">
            <label htmlFor="name">Koodi</label>
          </div>
          <div className="text-field">
            <input
              key="code"
              name="code"
              autoComplete="off"
              onChange={this.handleInputChange}
            />
          </div>

          <label htmlFor="name">Uusi salasana</label>
          <div className="text-field">
            <input
              type="password"
              key="password"
              name="password"
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-actions">
            <button onClick={this.onSubmit} className="btn login-btn">
              Lähetä
            </button>
            <button onClick={this.onSend} className="btn login-btn">
              Tilaa uusi koodi
            </button>
          </div>
          {this.getBackBtn()}
        </form>
      </div>
    );
  }
}
