// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var StyleProperties = require("../StyleProperties/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var verticalButtonGroupStyles = Box.boxStyle([ Box.FlexColumn.value, Box.Width.create(new TacoConstants.CustomSize("16rem")) ]);
var summaryStyle = Box.boxStyle([ Box.GridColumn.value, new Box.Gap(TacoConstants.S.value), new Box.PaddingRight(TacoConstants.XS.value) ]);
var summaryContainerStyles = Box.boxStyle([ Box.GridColumn.value, new Box.Gap(TacoConstants.XL.value), new Box.PaddingLeft(TacoConstants.XL.value) ]);
var searchFieldStyles = Box.boxStyle([ Box.Width.create(new TacoConstants.CustomSize("16rem")) ]);
var resultCountStyles = Box.boxStyle([ new Box.MarginY(TacoConstants.L.value) ]);
var invoicesSummaryStyles = Box.boxStyle([ new Box.Position(Box.Sticky.value), Box.PosBottom.create(new TacoConstants.CustomSize("0")), new Box.BackgroundColor(TacoConstants.White.value), Box.FlexRow.value, Box.AlignCenter.value, Box.Style.create(React_Basic_Emotion.css()({
    justifyContent: React_Basic_Emotion.str("flex-end")
})), new Box.Height(TacoConstants.XL.value), Box.Style.create(React_Basic_Emotion.css()({
    borderTop: StyleProperties.border(TacoConstants.BorderLight.value)
})) ]);
var invoiceDetailsStyles = Box.boxStyle([ Box.GridRow.value, new Box.Gap(TacoConstants.M.value), new Box.MarginLeft(TacoConstants.XXL.value), new Box.MarginRight(TacoConstants.M.value), new Box.MarginBottom(TacoConstants.M.value), new Box.Padding(TacoConstants.M.value), new Box.Border(TacoConstants.BorderLight.value), new Box.BackgroundColor(TacoConstants.White.value) ]);
var invoiceDetailsRowStyles = Box.boxStyle([ Box.GridColumn.value, Box.Style.create(React_Basic_Emotion.css()({
    gridTemplateColumns: React_Basic_Emotion.str("1fr 5fr")
})) ]);
var editInvoiceBunchControlStyles = Box.boxStyle([ new Box.Height(TacoConstants.L.value), Box.Style.create(React_Basic_Emotion.css()({
    alignSelf: React_Basic_Emotion.str("center")
})) ]);
var descriptionContainerStyles = Box.boxStyle([ new Box.MarginLeft(TacoConstants.M.value) ]);
var dateFieldStyles = Box.boxStyle([ Box.Width.create(new TacoConstants.CustomSize("10rem")) ]);
var costGroupSearchFieldStyles = Box.boxStyle([ Box.MinWidth.create(new TacoConstants.CustomSize("24rem")), new Box.FlexGrow(1) ]);
module.exports = {
    verticalButtonGroupStyles: verticalButtonGroupStyles,
    editInvoiceBunchControlStyles: editInvoiceBunchControlStyles,
    invoicesSummaryStyles: invoicesSummaryStyles,
    summaryContainerStyles: summaryContainerStyles,
    summaryStyle: summaryStyle,
    resultCountStyles: resultCountStyles,
    descriptionContainerStyles: descriptionContainerStyles,
    invoiceDetailsStyles: invoiceDetailsStyles,
    invoiceDetailsRowStyles: invoiceDetailsRowStyles,
    searchFieldStyles: searchFieldStyles,
    costGroupSearchFieldStyles: costGroupSearchFieldStyles,
    dateFieldStyles: dateFieldStyles
};
