import { createContext, ReactNode, useEffect, useState } from "react";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { getClientConfigVar } from "../../client/config";
import { useWorkerState } from "../hooks/useWorkerState";

export interface AuthenticatedUser {
  userId: string;
  email: string;
  organization?: string;
}

const anonymousUser: AuthenticatedUser = {
  userId: "anonymous",
  email: "anonymous",
};
export const CurrentUserContext = createContext<AuthenticatedUser>(
  anonymousUser
);
export function CurrentUserProvider({ children }: { children: ReactNode }) {
  const currentOrganization = useWorkerState("GetCurrentOrganizationState");

  const [currentUser, setCurrentUser] = useState<AuthenticatedUser | undefined>(
    undefined
  );
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((cognitoUser: CognitoUser) => {
        setCurrentUser({
          userId: cognitoUser.getUsername(),
          email: cognitoUser.getSignInUserSession()?.getIdToken().payload.email,
          organization: currentOrganization?.organization,
        });
      })
      .catch((err) => {
        // If auth is disabled, just use the anonymous user
        if (getClientConfigVar("CLIENT_AUTH_DISABLE") === "true") {
          setCurrentUser(anonymousUser);
          return;
        }
        throw err;
      });
  }, [currentOrganization]);

  // Don't load the UI until we know the current user
  if (currentUser === undefined) return <></>;

  return (
    <CurrentUserContext.Provider value={currentUser}>
      {children}
    </CurrentUserContext.Provider>
  );
}
