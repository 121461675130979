// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var HtmlUtils = require("../HtmlUtils/index.js");
var Locale = require("../Locale/index.js");
var Portal = require("../Portal/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoDropdown_Styles = require("../TacoDropdown.Styles/index.js");
var TacoDropdown_Types = require("../TacoDropdown.Types/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var UseCallbackRef = require("../UseCallbackRef/index.js");
var UseDismissOverlay = require("../UseDismissOverlay/index.js");
var UseMeasure = require("../UseMeasure/index.js");
var separatorLine = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
    children: [  ],
    className: "dropdown-section-heading h-px bg-light",
    css: TacoDropdown_Styles.tacoDropdownSeparatorStyles
});
var component = TofuHooks.mkHookComponent("TacoDropdown")(function (v) {
    return function __do() {
        var v1 = UseDismissOverlay.useDismissOverlay(v.toggled)(v.setToggled(function (v1) {
            return false;
        }))(ReactHooksUtils.utf("TacoDropdown"))();
        var v2 = UseMeasure.useMeasure();
        var anchorDimensions = UseMeasure["useMeasure'"](v.anchorEl)();
        var item = function (jsx) {
            return React_Basic_DOM_Generated.div()({
                children: Control_Applicative.pure(Control_Applicative.applicativeArray)(jsx),
                className: "dropdown-section-item"
            });
        };
        var heading = function (mTitle) {
            if (mTitle instanceof Data_Maybe.Just) {
                return Data_Maybe.Just.create(React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                    children: [ TacoText.component()()({
                        color: TacoConstants.GrayMedium.value,
                        text: mTitle.value0
                    }) ],
                    className: "dropdown-section-heading",
                    css: TacoDropdown_Styles.tacoDropdownSectionHeadingStyles
                }));
            };
            if (mTitle instanceof Data_Maybe.Nothing) {
                return Data_Maybe.Nothing.value;
            };
            throw new Error("Failed pattern match at TacoDropdown (line 89, column 22 - line 95, column 25): " + [ mTitle.constructor.name ]);
        };
        var section = function (index) {
            return function (r) {
                return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                    children: Data_Semigroup.append(Data_Semigroup.semigroupArray)([ ReactBasicUtils.maybeJSX(React_Basic.monoidJSX)(heading(r.title)) ])(Data_Functor.map(Data_Functor.functorArray)(item)(r.items)),
                    className: "dropdown-section",
                    css: TacoDropdown_Styles.tacoDropdownSectionItemStyles({
                        lastItem: index === (Data_Array.length(v.sections) - 1 | 0)
                    })
                });
            };
        };
        var dropdown = React_Basic_DOM_Generated.div()({
            ref: UseCallbackRef.toNodeRef(v1.callbackRef),
            children: [ React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                ref: UseCallbackRef.toNodeRef(v2.callbackRef),
                className: "dropdown-sections",
                css: TacoDropdown_Styles.tacoDropdownSectionsStyles({
                    align: v.align,
                    anchorDimensions: anchorDimensions,
                    dropdownDimensions: v2.dimensions,
                    toggled: v.toggled
                }),
                children: Data_Semigroup.append(Data_Semigroup.semigroupArray)([ React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                    className: "callout",
                    css: TacoDropdown_Styles.tacoDropdownCalloutTriangleStyles({
                        align: v.align
                    })
                }) ])(Data_Array.mapWithIndex(section)(v.sections))
            }) ]
        });
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "__TacoDropdown",
            css: TacoDropdown_Styles.tacoDropdownContainerStyles,
            children: [ Portal.component({
                contents: dropdown,
                id: "TacoDropdown"
            }) ]
        });
    };
});
var uncontrolled = TofuHooks.mkHookComponent("TacoDropdownUncontrolled")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState(false)();
        var v2 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        return React_Basic.fragment([ component({
            sections: v.sections,
            align: v.align,
            toggled: v1.value0,
            setToggled: v1.value1,
            anchorEl: v2.value0
        }), TacoButton.component()()({
            onClickEventHandler: Data_Maybe.Just.create(React_Basic_Events.handler(React_Basic_DOM_Events.currentTarget)(function (target) {
                return function __do() {
                    v1.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))();
                    return v2.value1(function (v3) {
                        return Data_Maybe.Just.create(HtmlUtils.coerceEventTargetToHTMLElement(target));
                    })();
                };
            })),
            text: Locale.lookup_("actions"),
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconMenuV2.value),
            testId: v.testId
        }) ]);
    };
});
module.exports = {
    component: component,
    uncontrolled: uncontrolled,
    separatorLine: separatorLine,
    AlignLeft: TacoDropdown_Types.AlignLeft,
    AlignRight: TacoDropdown_Types.AlignRight
};
