import { Button, Input } from "@tocoman/ui";
import { FormProvider, RegisterOptions, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ErrorMessage } from "src/client-ts/components/ErrorMessage";
import {
  NewComponentGroup,
  useComponentGroupMutation,
} from "./useComponentGroups";

type ComponentGroupCreateFormProps = {
  projectId: number;
  toggleGroupsEdited: () => void;
};

export function ComponentGroupCreateForm({
  projectId,
  toggleGroupsEdited,
}: ComponentGroupCreateFormProps) {
  const { t } = useTranslation("estimation", {
    keyPrefix: "componentGroupEdit",
  });

  const methods = useForm<NewComponentGroup>({
    defaultValues: { code: "", description: "" },
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const { mutate: addNewGroup, isLoading } = useComponentGroupMutation<
    "AddClassificationGroup"
  >(projectId);

  const handleCreate = (data: NewComponentGroup) => {
    addNewGroup({
      type: "AddClassificationGroup",
      value: {
        code: data.code,
        description: data.description,
        projectId: projectId,
      },
    });
    reset();
    toggleGroupsEdited();
  };

  const mkInputProps = (
    field: keyof NewComponentGroup,
    registerOptions?: RegisterOptions<NewComponentGroup, typeof field>
  ) => ({
    label: t(`${field}`),
    ...register(field, registerOptions),
  });

  return (
    <div>
      <FormProvider {...methods}>
        <div className="flex flex-row gap-3 w-full mt-4">
          <Input
            {...mkInputProps("code", {
              maxLength: {
                value: 20,
                message: t(`errors.maxLength`, {
                  maxLength: "20",
                  field: `${t`codeLowerCase`}`,
                }),
              },
              required: { value: true, message: t`errors.required` },
            })}
            className={"w-2/5"}
          />
          <Input
            {...mkInputProps("description", {
              maxLength: {
                value: 50,
                message: t(`errors.maxLength`, {
                  maxLength: "50",
                  field: `${t`descriptionLowerCase`}`,
                }),
              },
            })}
            className={"w-3/5"}
          />
          <Button
            onClick={handleSubmit(handleCreate)}
            className={"mt-7 w-[100px]"}
            disabled={!!errors.code || !!errors.description}
            loading={isLoading}
          >{t`add`}</Button>
        </div>
      </FormProvider>

      <div className={"flex flex-row gap-3 mt-2"}>
        {errors.code && <ErrorMessage errorMessage={errors.code?.message} />}
        {errors.description && (
          <ErrorMessage errorMessage={errors.description?.message} />
        )}
      </div>
    </div>
  );
}
