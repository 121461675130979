// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var AdminProjectListReducer = require("../AdminProjectListReducer/index.js");
var AlertsReducer = require("../AlertsReducer/index.js");
var AllInvoiceBunchesReducer = require("../AllInvoiceBunchesReducer/index.js");
var AllOrganizationConfigurationsReducer = require("../AllOrganizationConfigurationsReducer/index.js");
var AllOrganizationsReducer = require("../AllOrganizationsReducer/index.js");
var ClassificationGroupsReducer = require("../ClassificationGroupsReducer/index.js");
var ClassificationsReducer = require("../ClassificationsReducer/index.js");
var ClipboardReducer = require("../ClipboardReducer/index.js");
var ConstructionTypesReducer = require("../ConstructionTypesReducer/index.js");
var ContractTypesReducer = require("../ContractTypesReducer/index.js");
var CostClassesReducer = require("../CostClassesReducer/index.js");
var CostControlExcludeActualCostSocialExpensesReducer = require("../CostControlExcludeActualCostSocialExpensesReducer/index.js");
var CostGroupCategoriesReducer = require("../CostGroupCategoriesReducer/index.js");
var CostGroupInfosReducer = require("../CostGroupInfosReducer/index.js");
var CostGroupInvoicesReducer = require("../CostGroupInvoicesReducer/index.js");
var CostGroupsReducer = require("../CostGroupsReducer/index.js");
var CurrentOrganizationReducer = require("../CurrentOrganizationReducer/index.js");
var ElementMeasuresReducer = require("../ElementMeasuresReducer/index.js");
var EstimateItemsReducer = require("../EstimateItemsReducer/index.js");
var EstimationPriceListReducer = require("../EstimationPriceListReducer/index.js");
var EstimationResourcePriceMatchReducer = require("../EstimationResourcePriceMatchReducer/index.js");
var EstimationUiStateReducer = require("../EstimationUiStateReducer/index.js");
var FinancingTypesReducer = require("../FinancingTypesReducer/index.js");
var GetProjectManagersReducer = require("../GetProjectManagersReducer/index.js");
var ImportInvoicesReducer = require("../ImportInvoicesReducer/index.js");
var ImportSalesInvoicesReducer = require("../ImportSalesInvoicesReducer/index.js");
var IncomeGroupsReducer = require("../IncomeGroupsReducer/index.js");
var IncomeItemsReducer = require("../IncomeItemsReducer/index.js");
var InvoiceBunchesReducer = require("../InvoiceBunchesReducer/index.js");
var InvoicesReducer = require("../InvoicesReducer/index.js");
var LogsReducer = require("../LogsReducer/index.js");
var MainCostGroupsReducer = require("../MainCostGroupsReducer/index.js");
var MeasurementsReducer = require("../MeasurementsReducer/index.js");
var OfferPageParamsReducer = require("../OfferPageParamsReducer/index.js");
var OrgAddressAreaReducer = require("../OrgAddressAreaReducer/index.js");
var OrgClassificationClassesReducer = require("../OrgClassificationClassesReducer/index.js");
var OrgCostClassReducer = require("../OrgCostClassReducer/index.js");
var OrgPriceListReducer = require("../OrgPriceListReducer/index.js");
var OrgPriceListTreeReducer = require("../OrgPriceListTreeReducer/index.js");
var OrganizationAdminInfoReducer = require("../OrganizationAdminInfoReducer/index.js");
var OrganizationUsersReducer = require("../OrganizationUsersReducer/index.js");
var PortfolioReportReducer = require("../PortfolioReportReducer/index.js");
var ProjectClassificationClassesReducer = require("../ProjectClassificationClassesReducer/index.js");
var ProjectCostGroupClassificationInfoReducer = require("../ProjectCostGroupClassificationInfoReducer/index.js");
var ProjectGroupCacheLastUpdateReducer = require("../ProjectGroupCacheLastUpdateReducer/index.js");
var ProjectGroupsReducer = require("../ProjectGroupsReducer/index.js");
var ProjectListReducer = require("../ProjectListReducer/index.js");
var ProjectLocationsReducer = require("../ProjectLocationsReducer/index.js");
var ProjectReducer = require("../ProjectReducer/index.js");
var ProjectResponsibilityInfoReducer = require("../ProjectResponsibilityInfoReducer/index.js");
var ProjectTypesReducer = require("../ProjectTypesReducer/index.js");
var ReducerUtils = require("../ReducerUtils/index.js");
var ReferenceElementMeasuresReducer = require("../ReferenceElementMeasuresReducer/index.js");
var ReferenceProjectCostClassesReducer = require("../ReferenceProjectCostClassesReducer/index.js");
var ReportingComponentsReducer = require("../ReportingComponentsReducer/index.js");
var ReportingResourcesReducer = require("../ReportingResourcesReducer/index.js");
var ResponsibilityCompaniesReducer = require("../ResponsibilityCompaniesReducer/index.js");
var RoleAssignmentsReducer = require("../RoleAssignmentsReducer/index.js");
var RouteReducer = require("../RouteReducer/index.js");
var SalesInvoicesReducer = require("../SalesInvoicesReducer/index.js");
var ServerStatusReducer = require("../ServerStatusReducer/index.js");
var SocialExpensesReducer = require("../SocialExpensesReducer/index.js");
var SuperAdminUsersReducer = require("../SuperAdminUsersReducer/index.js");
var SuppliersReducer = require("../SuppliersReducer/index.js");
var TargetItemsReducer = require("../TargetItemsReducer/index.js");
var UpdateStatusesReducer = require("../UpdateStatusesReducer/index.js");
var UserAppAccessRightsReducer = require("../UserAppAccessRightsReducer/index.js");
var UserOrganizationsReducer = require("../UserOrganizationsReducer/index.js");
var UserRolesReducer = require("../UserRolesReducer/index.js");
var VATExclusionCostGroupsReducer = require("../VATExclusionCostGroupsReducer/index.js");
var rootReducer = (function () {
    var reducers = {
        alerts: AlertsReducer.reducer,
        clipboard: ClipboardReducer.reducer,
        allInvoiceBunches: AllInvoiceBunchesReducer.reducer,
        allOrganizations: AllOrganizationsReducer.reducer,
        allOrganizationConfigurations: AllOrganizationConfigurationsReducer.reducer,
        appAccessRights: UserAppAccessRightsReducer.reducer,
        classificationGroups: ClassificationGroupsReducer.reducer,
        classifications: ClassificationsReducer.reducer,
        constructionTypes: ConstructionTypesReducer.reducer,
        contractTypes: ContractTypesReducer.reducer,
        costClasses: CostClassesReducer.reducer,
        costControlExcludeActualCostSocialExpenses: CostControlExcludeActualCostSocialExpensesReducer.reducer,
        costGroupCategories: CostGroupCategoriesReducer.reducer,
        costGroupInfos: CostGroupInfosReducer.reducer,
        costGroupInvoices: CostGroupInvoicesReducer.reducer,
        costGroups: CostGroupsReducer.reducer,
        currentOrganization: CurrentOrganizationReducer.reducer,
        elementMeasures: ElementMeasuresReducer.reducer,
        estimateItems: EstimateItemsReducer.reducer,
        estimationUiState: EstimationUiStateReducer.reducer,
        estimationResourcePriceList: EstimationPriceListReducer.reducer,
        financingTypes: FinancingTypesReducer.reducer,
        importInvoices: ImportInvoicesReducer.reducer,
        importSalesInvoices: ImportSalesInvoicesReducer.reducer,
        serverStatus: ServerStatusReducer.reducer,
        incomeGroups: IncomeGroupsReducer.reducer,
        incomeItems: IncomeItemsReducer.reducer,
        invoiceBunches: InvoiceBunchesReducer.reducer,
        invoices: InvoicesReducer.reducer,
        logs: LogsReducer.reducer,
        mainCostGroups: MainCostGroupsReducer.reducer,
        measurements: MeasurementsReducer.reducer,
        orgClassificationClasses: OrgClassificationClassesReducer.reducer,
        projectClassificationClasses: ProjectClassificationClassesReducer.reducer,
        orgPriceListTree: OrgPriceListTreeReducer.reducer,
        orgPriceLists: OrgPriceListReducer.reducer,
        estimationResourcePriceMatches: EstimationResourcePriceMatchReducer.reducer,
        offerPageParams: OfferPageParamsReducer.reducer,
        organizationUsers: OrganizationUsersReducer.reducer,
        organizationAdminInfo: OrganizationAdminInfoReducer.reducer,
        portfolioReport: PortfolioReportReducer.reducer,
        projectGroups: ProjectGroupsReducer.reducer,
        projectGroupCacheLastUpdate: ProjectGroupCacheLastUpdateReducer.reducer,
        projectList: ProjectListReducer.reducer,
        adminProjectList: AdminProjectListReducer.reducer,
        projectLocations: ProjectLocationsReducer.reducer,
        projectManagers: GetProjectManagersReducer.reducer,
        projectTypes: ProjectTypesReducer.reducer,
        referenceProjectCostClasses: ReferenceProjectCostClassesReducer.reducer,
        reportingComponents: ReportingComponentsReducer.reducer,
        referenceElementMeasures: ReferenceElementMeasuresReducer.reducer,
        roleAssignments: RoleAssignmentsReducer.reducer,
        orgAddressArea: OrgAddressAreaReducer.reducer,
        orgCostClass: OrgCostClassReducer.reducer,
        projectCostGroupClassificationInfo: ProjectCostGroupClassificationInfoReducer.reducer,
        reportingResources: ReportingResourcesReducer.reducer,
        responsibilityCompanies: ResponsibilityCompaniesReducer.reducer,
        responsibilityInfo: ProjectResponsibilityInfoReducer.reducer,
        route: RouteReducer.reducer,
        salesInvoices: SalesInvoicesReducer.reducer,
        selectedProject: ProjectReducer.reducer,
        socialExpenses: SocialExpensesReducer.reducer,
        suppliers: SuppliersReducer.reducer,
        targetItems: TargetItemsReducer.reducer,
        updateStatuses: UpdateStatusesReducer.reducer,
        userOrganizations: UserOrganizationsReducer.reducer,
        userRoles: UserRolesReducer.reducer,
        users: SuperAdminUsersReducer.reducer,
        vatExclusionCostGroups: VATExclusionCostGroupsReducer.reducer,
        workerEstimationProjects: ReducerUtils.mkWasmWorkerReducer("EstimationProjectsState"),
        workerEstimationComponents: ReducerUtils.mkWasmWorkerReducer("EstimationComponentsState"),
        workerEstimationRefComponents: ReducerUtils.mkWasmWorkerReducer("EstimationRefComponentsState"),
        workerEstimationElements: ReducerUtils.mkWasmWorkerReducer("EstimationElementsState"),
        workerEstimationRefElements: ReducerUtils.mkWasmWorkerReducer("EstimationRefElementsState"),
        workerEstimationResources: ReducerUtils.mkWasmWorkerReducer("EstimationResourcesState"),
        workerEstimationRefResources: ReducerUtils.mkWasmWorkerReducer("EstimationRefResourcesState"),
        workerEstimationLocations: ReducerUtils.mkWasmWorkerReducer("EstimationLocationsState"),
        workerEstimationRefLocations: ReducerUtils.mkWasmWorkerReducer("EstimationRefLocationsState"),
        workerEstimationElementLocations: ReducerUtils.mkWasmWorkerReducer("EstimationElementLocationsState"),
        workerEstimationRefElementLocations: ReducerUtils.mkWasmWorkerReducer("EstimationRefElementLocationsState"),
        workerEstimationElementSpecs: ReducerUtils.mkWasmWorkerReducer("EstimationElementSpecsState"),
        workerEstimationRefElementSpecs: ReducerUtils.mkWasmWorkerReducer("EstimationRefElementSpecsState"),
        workerEstimationSubprojects: ReducerUtils.mkWasmWorkerReducer("EstimationSubprojectsState"),
        workerEstimationRefSubprojects: ReducerUtils.mkWasmWorkerReducer("EstimationRefSubprojectsState"),
        workerEstimationClassificationGroups: ReducerUtils.mkWasmWorkerReducer("EstimationClassificationGroupsState"),
        workerPlanningCostGroups: ReducerUtils.mkWasmWorkerReducer("PlanningCostGroupsState"),
        workerProjectLocations: ReducerUtils.mkWasmWorkerReducer("ProjectLocationsState"),
        workerClassificationClasses: ReducerUtils.mkWasmWorkerReducer("ProjectClassificationClassesState"),
        workerCostClasses: ReducerUtils.mkWasmWorkerReducer("EstimationCostClassesState"),
        workerAdminetIntegration: ReducerUtils.mkWasmWorkerReducer("AdminetIntegrationActiveState"),
        workerInvalidResourceCurrencies: ReducerUtils.mkWasmWorkerReducer("InvalidResourceCurrenciesState"),
        workerEstimationComponentsTotals: ReducerUtils.mkWasmWorkerReducer("EstimationComponentsTotalsState"),
        workerElementsTotals: ReducerUtils.mkWasmWorkerReducer("EstimationElementsTotalsState"),
        workerProjectClassificationDataState: ReducerUtils.mkWasmWorkerReducer("ProjectClassificationDataState"),
        workerUnfilteredSepProjectClassificationDataState: ReducerUtils.mkWasmWorkerReducer("UnfilteredSepProjectClassificationDataState"),
        workerComponentWithResources: ReducerUtils.mkWasmWorkerReducer("ComponentsWithResourcesState"),
        workerRefComponentWithResources: ReducerUtils.mkWasmWorkerReducer("RefComponentsWithResourcesState"),
        workerElementsData: ReducerUtils.mkWasmWorkerReducer("ElementsDataState"),
        workerRefElementsData: ReducerUtils.mkWasmWorkerReducer("RefElementsDataState"),
        workerComponentCostGroupsData: ReducerUtils.mkWasmWorkerReducer("ComponentCostGroupsDataState"),
        workerNonMatchingGroups: ReducerUtils.mkWasmWorkerReducer("NonMatchingCostGroupsState"),
        workerUnfilteredSEPComponentCostGroupsData: ReducerUtils.mkWasmWorkerReducer("UnfilteredSocExpPercentagesComponentCostGroupsDataState"),
        workerTotalPricesByCostClasses: ReducerUtils.mkWasmWorkerReducer("TotalPricesByCostClassesState"),
        workerUnfilteredSEPTotalPricesByCostClasses: ReducerUtils.mkWasmWorkerReducer("UnfilteredSepTotalPricesByCostClassesState"),
        workerComponentGroupsData: ReducerUtils.mkWasmWorkerReducer("ComponentGroupsDataState"),
        workerUnfilteredSEPComponentGroupsData: ReducerUtils.mkWasmWorkerReducer("UnfilteredSepComponentGroupsDataState"),
        workerFilteredComponentIds: ReducerUtils.mkWasmWorkerReducer("FilteredComponentIdsState"),
        workerFilteredRefComponentIds: ReducerUtils.mkWasmWorkerReducer("FilteredRefComponentIdsState"),
        workerFilteredElementIds: ReducerUtils.mkWasmWorkerReducer("FilteredElementIdsState"),
        workerFilteredRefElementIds: ReducerUtils.mkWasmWorkerReducer("FilteredRefElementIdsState"),
        workerProjectLocationsData: ReducerUtils.mkWasmWorkerReducer("ProjectLocationsDataState"),
        workerUnfilteredSEPProjectLocationsData: ReducerUtils.mkWasmWorkerReducer("UnfilteredSepProjectLocationsDataState"),
        workerActiveRefComponents: ReducerUtils.mkWasmWorkerReducer("ActiveRefComponentsState"),
        workerActiveRefElements: ReducerUtils.mkWasmWorkerReducer("ActiveRefElementsState"),
        workerActiveComponentFilters: ReducerUtils.mkWasmWorkerReducer("ActiveComponentFiltersState"),
        workerFilteredDixonMeasurementGroups: ReducerUtils.mkWasmWorkerReducer("FilteredDixonMeasurementGroupsState"),
        workerDixonMeasurementGroups: ReducerUtils.mkWasmWorkerReducer("DixonMeasurementGroupsState"),
        workerDixonMeasurementFollowFiltering: ReducerUtils.mkWasmWorkerReducer("DixonMeasurementFollowFilteringState")
    };
    return $foreign.prepareRootReducer(reducers);
})();
module.exports = {
    rootReducer: rootReducer,
    prepareRootReducer: $foreign.prepareRootReducer
};
