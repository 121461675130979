// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Control_Promise = require("../Control.Promise/index.js");
var createFileDownload = function (res) {
    return function (name) {
        return Control_Promise.toAffE($foreign["_createFileDownload"](res, name));
    };
};
module.exports = {
    createFileDownload: createFileDownload,
    "_createFileDownload": $foreign["_createFileDownload"]
};
