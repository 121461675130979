// Generated by purs version 0.14.5
"use strict";
var Calculation_Utils = require("../Calculation.Utils/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Kishimen = require("../Kishimen/index.js");
var Locale = require("../Locale/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_Income = require("../Types.Income/index.js");
var Types_R200 = require("../Types.R200/index.js");
var ShowUnallocatedSalesInvoices = (function () {
    function ShowUnallocatedSalesInvoices() {

    };
    ShowUnallocatedSalesInvoices.value = new ShowUnallocatedSalesInvoices();
    return ShowUnallocatedSalesInvoices;
})();
var HideUnallocatedSalesInvoices = (function () {
    function HideUnallocatedSalesInvoices() {

    };
    HideUnallocatedSalesInvoices.value = new HideUnallocatedSalesInvoices();
    return HideUnallocatedSalesInvoices;
})();
var AllowSocialCostToActual = (function () {
    function AllowSocialCostToActual() {

    };
    AllowSocialCostToActual.value = new AllowSocialCostToActual();
    return AllowSocialCostToActual;
})();
var DisableSocialCostToActual = (function () {
    function DisableSocialCostToActual() {

    };
    DisableSocialCostToActual.value = new DisableSocialCostToActual();
    return DisableSocialCostToActual;
})();
var UseOnlyApprovedIncomeItemsForActualFlag = (function () {
    function UseOnlyApprovedIncomeItemsForActualFlag() {

    };
    UseOnlyApprovedIncomeItemsForActualFlag.value = new UseOnlyApprovedIncomeItemsForActualFlag();
    return UseOnlyApprovedIncomeItemsForActualFlag;
})();
var UseAnyIncomeItemsforActual = (function () {
    function UseAnyIncomeItemsforActual() {

    };
    UseAnyIncomeItemsforActual.value = new UseAnyIncomeItemsforActual();
    return UseAnyIncomeItemsforActual;
})();
var mkR200IncomeInformationResults = function (approvedIncomeItemsForActualFlag) {
    return function (v) {
        return function (showUnallocatedSalesInvoices) {
            return function (incomeItems$prime) {
                return function (salesInvoices$prime) {
                    return function (upperDateLimit) {
                        var salesInvoices = Calculation_Utils.filterSalesInvoices(upperDateLimit)(salesInvoices$prime);
                        var isInstallmentIncomeGroup = function (x) {
                            return Data_Eq.eq(Types_Income.eqIncomeGroupName)(x.groupName)("Maksuer\xe4t");
                        };
                        var incomeItems = Calculation_Utils.filterIncomeItems(upperDateLimit)(incomeItems$prime);
                        var installmentItems = Data_Array.filter(isInstallmentIncomeGroup)(incomeItems);
                        var isInstallmentSalesInvoice = function (x) {
                            if (x.code instanceof Data_Maybe.Just) {
                                return Data_Maybe.isJust(Data_Array.find(function (y) {
                                    return y.code === x.code.value0;
                                })(installmentItems));
                            };
                            if (x.code instanceof Data_Maybe.Nothing) {
                                return false;
                            };
                            throw new Error("Failed pattern match at Calculation.Income (line 76, column 35 - line 79, column 23): " + [ x.code.constructor.name ]);
                        };
                        var installmentSalesInvoices = Data_Array.filter(isInstallmentSalesInvoice)(salesInvoices);
                        var itemsForAGroup = function (groupName) {
                            return Data_Array.filter((function () {
                                var $44 = Data_Eq.eq(Types_Income.eqIncomeGroupName)(groupName);
                                return function ($45) {
                                    return $44((function (v1) {
                                        return v1.groupName;
                                    })($45));
                                };
                            })())(incomeItems);
                        };
                        var isSalesInvoiceOfAGroup = function (groupName) {
                            return function (x) {
                                if (x.code instanceof Data_Maybe.Just) {
                                    return Data_Maybe.isJust(Data_Array.find(function (y) {
                                        return y.code === x.code.value0;
                                    })(itemsForAGroup(groupName)));
                                };
                                if (x.code instanceof Data_Maybe.Nothing) {
                                    return false;
                                };
                                throw new Error("Failed pattern match at Calculation.Income (line 84, column 42 - line 87, column 23): " + [ x.code.constructor.name ]);
                            };
                        };
                        var salesInvoicesForAGroup = function (groupName) {
                            return Data_Array.filter(isSalesInvoiceOfAGroup(groupName))(salesInvoices);
                        };
                        var incomeGroupNames = Data_Array.nub(Types_Income.ordIncomeGroupName)(Data_Functor.map(Data_Functor.functorArray)(function (v1) {
                            return v1.groupName;
                        })(incomeItems));
                        var isAllocatedSalesInvoice = function (si) {
                            return Data_Foldable.any(Data_Foldable.foldableArray)(Data_HeytingAlgebra.heytingAlgebraBoolean)(Data_Function.flip(isSalesInvoiceOfAGroup)(si))(incomeGroupNames);
                        };
                        var unallocatedSalesInvoices = Data_Array.filter(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(isAllocatedSalesInvoice))(salesInvoices);
                        var unallocated = (function () {
                            if (showUnallocatedSalesInvoices instanceof ShowUnallocatedSalesInvoices) {
                                return Data_Maybe.Just.create({
                                    name: Locale.lookup_("unallocated_sales_invoices"),
                                    target: 0.0,
                                    estimate: 0.0,
                                    actual: Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v1) {
                                        return v1.valueWithoutVAT;
                                    })(unallocatedSalesInvoices))
                                });
                            };
                            if (showUnallocatedSalesInvoices instanceof HideUnallocatedSalesInvoices) {
                                return Data_Maybe.Nothing.value;
                            };
                            throw new Error("Failed pattern match at Calculation.Income (line 131, column 19 - line 138, column 46): " + [ showUnallocatedSalesInvoices.constructor.name ]);
                        })();
                        var filterTargetItems = Data_Array.filter(function (v1) {
                            return v1.isIncludedInTarget;
                        });
                        var installmentsSum = {
                            name: Data_Newtype.un()(Types_Income.IncomeGroupName)("Maksuer\xe4t"),
                            target: Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v1) {
                                return v1.valueWithoutVAT;
                            })(filterTargetItems(installmentItems))),
                            estimate: Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v1) {
                                return v1.valueWithoutVAT;
                            })(installmentItems)),
                            actual: (function () {
                                if (approvedIncomeItemsForActualFlag instanceof UseOnlyApprovedIncomeItemsForActualFlag) {
                                    return Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v1) {
                                        return v1.valueWithoutVAT;
                                    })(Data_Array.filter(function (v1) {
                                        return v1.isApproved;
                                    })(installmentItems)));
                                };
                                if (approvedIncomeItemsForActualFlag instanceof UseAnyIncomeItemsforActual) {
                                    return Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v1) {
                                        return v1.valueWithoutVAT;
                                    })(installmentSalesInvoices));
                                };
                                throw new Error("Failed pattern match at Calculation.Income (line 102, column 17 - line 104, column 93): " + [ approvedIncomeItemsForActualFlag.constructor.name ]);
                            })()
                        };
                        var prepareOtherSum = function (groupName) {
                            return {
                                name: Data_Newtype.un()(Types_Income.IncomeGroupName)(groupName),
                                target: Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v1) {
                                    return v1.valueWithoutVAT;
                                })(filterTargetItems(itemsForAGroup(groupName)))),
                                estimate: Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v1) {
                                    return v1.valueWithoutVAT;
                                })(itemsForAGroup(groupName))),
                                actual: (function () {
                                    if (approvedIncomeItemsForActualFlag instanceof UseOnlyApprovedIncomeItemsForActualFlag) {
                                        return Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v1) {
                                            return v1.valueWithoutVAT;
                                        })(Data_Array.filter(function (v1) {
                                            return v1.isApproved;
                                        })(itemsForAGroup(groupName))));
                                    };
                                    if (approvedIncomeItemsForActualFlag instanceof UseAnyIncomeItemsforActual) {
                                        return Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v1) {
                                            return v1.valueWithoutVAT;
                                        })(salesInvoicesForAGroup(groupName)));
                                    };
                                    throw new Error("Failed pattern match at Calculation.Income (line 123, column 19 - line 125, column 103): " + [ approvedIncomeItemsForActualFlag.constructor.name ]);
                                })()
                            };
                        };
                        var isAdditionalWorkIncomeGroup = function (x) {
                            return Data_Eq.eq(Types_Income.eqIncomeGroupName)(x.groupName)("Lis\xe4ty\xf6t");
                        };
                        var additionalWorkItems = Data_Array.filter(isAdditionalWorkIncomeGroup)(incomeItems);
                        var isAdditionalWorkSalesInvoice = function (x) {
                            if (x.code instanceof Data_Maybe.Just) {
                                return Data_Maybe.isJust(Data_Array.find(function (y) {
                                    return y.code === x.code.value0;
                                })(additionalWorkItems));
                            };
                            if (x.code instanceof Data_Maybe.Nothing) {
                                return false;
                            };
                            throw new Error("Failed pattern match at Calculation.Income (line 80, column 38 - line 83, column 23): " + [ x.code.constructor.name ]);
                        };
                        var additionalWorkSalesInvoices = Data_Array.filter(isAdditionalWorkSalesInvoice)(salesInvoices);
                        var additionalWorkSum = {
                            name: Data_Newtype.un()(Types_Income.IncomeGroupName)("Lis\xe4ty\xf6t"),
                            target: Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v1) {
                                return v1.valueWithoutVAT;
                            })(filterTargetItems(additionalWorkItems))),
                            estimate: Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v1) {
                                return v1.valueWithoutVAT;
                            })(additionalWorkItems)),
                            actual: (function () {
                                if (approvedIncomeItemsForActualFlag instanceof UseOnlyApprovedIncomeItemsForActualFlag) {
                                    return Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v1) {
                                        return v1.valueWithoutVAT;
                                    })(Data_Array.filter(function (v1) {
                                        return v1.isApproved;
                                    })(additionalWorkItems)));
                                };
                                if (approvedIncomeItemsForActualFlag instanceof UseAnyIncomeItemsforActual) {
                                    return Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v1) {
                                        return v1.valueWithoutVAT;
                                    })(additionalWorkSalesInvoices));
                                };
                                throw new Error("Failed pattern match at Calculation.Income (line 111, column 17 - line 113, column 96): " + [ approvedIncomeItemsForActualFlag.constructor.name ]);
                            })()
                        };
                        var additionalWorkSumItems = (function () {
                            var $35 = Data_Array["null"](additionalWorkItems);
                            if ($35) {
                                return [  ];
                            };
                            return [ additionalWorkSum ];
                        })();
                        var otherIncomeGroupNames = Data_Array.filter(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_HeytingAlgebra.disj(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Eq.eq(Types_Income.eqIncomeGroupName)("Maksuer\xe4t"))(Data_Eq.eq(Types_Income.eqIncomeGroupName)("Lis\xe4ty\xf6t"))))(incomeGroupNames);
                        var otherSums = Data_Functor.map(Data_Functor.functorArray)(prepareOtherSum)(otherIncomeGroupNames);
                        var totalItems = Data_Semigroup.append(Data_Semigroup.semigroupArray)([ installmentsSum ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(additionalWorkSumItems)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(otherSums)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_Maybe.maybe([  ])(Control_Applicative.pure(Control_Applicative.applicativeArray))(unallocated))(Data_Maybe.maybe([  ])(Control_Applicative.pure(Control_Applicative.applicativeArray))(Data_Maybe.Nothing.value)))));
                        var totals = {
                            name: "Yhteens\xe4",
                            target: Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)((function () {
                                var $46 = Data_Newtype.un()(Types_R200.R200IncomeInformationResult);
                                return function ($47) {
                                    return (function (v1) {
                                        return v1.target;
                                    })($46($47));
                                };
                            })())(totalItems)),
                            estimate: Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)((function () {
                                var $48 = Data_Newtype.un()(Types_R200.R200IncomeInformationResult);
                                return function ($49) {
                                    return (function (v1) {
                                        return v1.estimate;
                                    })($48($49));
                                };
                            })())(totalItems)),
                            actual: Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)((function () {
                                var $50 = Data_Newtype.un()(Types_R200.R200IncomeInformationResult);
                                return function ($51) {
                                    return (function (v1) {
                                        return v1.actual;
                                    })($50($51));
                                };
                            })())(totalItems))
                        };
                        return {
                            installments: installmentsSum,
                            additionalWork: additionalWorkSum,
                            additionalWorkItemsLength: Data_Array.length(additionalWorkItems),
                            others: otherSums,
                            taxes: Data_Maybe.Nothing.value,
                            unallocated: unallocated,
                            totals: totals
                        };
                    };
                };
            };
        };
    };
};
var genericShowUnallocatedSalesInvoices = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return ShowUnallocatedSalesInvoices.value;
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return HideUnallocatedSalesInvoices.value;
        };
        throw new Error("Failed pattern match at Calculation.Income (line 34, column 1 - line 34, column 94): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof ShowUnallocatedSalesInvoices) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof HideUnallocatedSalesInvoices) {
            return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
        };
        throw new Error("Failed pattern match at Calculation.Income (line 34, column 1 - line 34, column 94): " + [ x.constructor.name ]);
    }
};
var tofuJSONShowUnallocatedSalesInvoices = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "HideUnallocatedSalesInvoices";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ShowUnallocatedSalesInvoices";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "HideUnallocatedSalesInvoices";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ShowUnallocatedSalesInvoices";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))(genericShowUnallocatedSalesInvoices)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ShowUnallocatedSalesInvoices";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "HideUnallocatedSalesInvoices";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ShowUnallocatedSalesInvoices";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "HideUnallocatedSalesInvoices";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericShowUnallocatedSalesInvoices)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ShowUnallocatedSalesInvoices";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "HideUnallocatedSalesInvoices";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ShowUnallocatedSalesInvoices";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "HideUnallocatedSalesInvoices";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "HideUnallocatedSalesInvoices";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ShowUnallocatedSalesInvoices";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "HideUnallocatedSalesInvoices";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ShowUnallocatedSalesInvoices";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))
};
var genericDisableSocialCostToActual = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return AllowSocialCostToActual.value;
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return DisableSocialCostToActual.value;
        };
        throw new Error("Failed pattern match at Calculation.Income (line 25, column 1 - line 25, column 88): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof AllowSocialCostToActual) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof DisableSocialCostToActual) {
            return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
        };
        throw new Error("Failed pattern match at Calculation.Income (line 25, column 1 - line 25, column 88): " + [ x.constructor.name ]);
    }
};
var tofuJSONDisableSocialCostToActual = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AllowSocialCostToActual";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DisableSocialCostToActual";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AllowSocialCostToActual";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DisableSocialCostToActual";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))(genericDisableSocialCostToActual)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AllowSocialCostToActual";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DisableSocialCostToActual";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AllowSocialCostToActual";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DisableSocialCostToActual";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericDisableSocialCostToActual)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AllowSocialCostToActual";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DisableSocialCostToActual";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AllowSocialCostToActual";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DisableSocialCostToActual";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AllowSocialCostToActual";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DisableSocialCostToActual";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AllowSocialCostToActual";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DisableSocialCostToActual";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))
};
module.exports = {
    UseOnlyApprovedIncomeItemsForActualFlag: UseOnlyApprovedIncomeItemsForActualFlag,
    UseAnyIncomeItemsforActual: UseAnyIncomeItemsforActual,
    AllowSocialCostToActual: AllowSocialCostToActual,
    DisableSocialCostToActual: DisableSocialCostToActual,
    ShowUnallocatedSalesInvoices: ShowUnallocatedSalesInvoices,
    HideUnallocatedSalesInvoices: HideUnallocatedSalesInvoices,
    mkR200IncomeInformationResults: mkR200IncomeInformationResults,
    genericDisableSocialCostToActual: genericDisableSocialCostToActual,
    tofuJSONDisableSocialCostToActual: tofuJSONDisableSocialCostToActual,
    genericShowUnallocatedSalesInvoices: genericShowUnallocatedSalesInvoices,
    tofuJSONShowUnallocatedSalesInvoices: tofuJSONShowUnallocatedSalesInvoices
};
