// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Client_WASM = require("../Client.WASM/index.js");
var Common = require("../Common/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var EstimationUtils = require("../EstimationUtils/index.js");
var Formatters = require("../Formatters/index.js");
var Gap = require("../Gap/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_DOM_Internal = require("../React.Basic.DOM.Internal/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoList = require("../TacoList/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoTable_Header = require("../TacoTable.Header/index.js");
var TacoTable_Row = require("../TacoTable.Row/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Element = require("../Types.Element/index.js");
var Types_Location = require("../Types.Location/index.js");
var ColLocationCode = (function () {
    function ColLocationCode() {

    };
    ColLocationCode.value = new ColLocationCode();
    return ColLocationCode;
})();
var ColLocationDescription = (function () {
    function ColLocationDescription() {

    };
    ColLocationDescription.value = new ColLocationDescription();
    return ColLocationDescription;
})();
var ColLocationActionButton = (function () {
    function ColLocationActionButton() {

    };
    ColLocationActionButton.value = new ColLocationActionButton();
    return ColLocationActionButton;
})();
var locationTreeModalStyleSet = TacoTable_Styles.foldingTableStyleSetWithoutSticky;
var locationTreeModalColumns = function (fn) {
    return [ {
        key: "code",
        width: new TacoTable_Types.Flex(1),
        label: React_Basic_DOM.text("Koodi"),
        sortProperty: TacoTable_Types.noSortProperty,
        cell: fn(ColLocationCode.value),
        headerJSX: Data_Maybe.Nothing.value
    }, {
        key: "description",
        width: new TacoTable_Types.Flex(1),
        label: React_Basic_DOM.text("Selite"),
        sortProperty: TacoTable_Types.noSortProperty,
        cell: fn(ColLocationDescription.value),
        headerJSX: Data_Maybe.Nothing.value
    }, {
        key: "actionButton",
        width: new TacoTable_Types.Flex(1),
        label: React_Basic_DOM.text(""),
        sortProperty: TacoTable_Types.noSortProperty,
        cell: fn(ColLocationActionButton.value),
        headerJSX: Data_Maybe.Nothing.value
    } ];
};
var locationsTreeModal = TofuHooks.mkMemoHookComponent("LocationsTreeModal")(function (props) {
    var defaultNewLocationInputs = {
        code: "",
        description: ""
    };
    return function __do() {
        var dispatch = StateHooks.useDispatch();
        var v = TofuHooks.useState(defaultNewLocationInputs)();
        var v1 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var newLocationsubmitButton = TacoButton.component()()({
            text: "Luo uusi sijainti",
            disabled: Data_String_Common["null"](v.value0.code),
            onClick: new Data_Maybe.Just(dispatch(new Actions.LocationActionRequest({
                handler: function (v2) {
                    return v.value1(function (v3) {
                        return defaultNewLocationInputs;
                    });
                },
                projectId: props.projectId,
                params: new Types_Location.AddLocation({
                    code: v.value0.code,
                    description: v.value0.description,
                    projectId: props.projectId,
                    sortingNumber: 0,
                    scale: 0
                })
            }))),
            testId: "create-new-location-button"
        });
        var newLocationDescription = TacoInput.stringInput()()(Data_Eq.eqString)({
            value: v.value0.description,
            onSave: function (value) {
                return v.value1(function (r) {
                    return {
                        code: r.code,
                        description: value
                    };
                });
            },
            validator: function (s) {
                var $47 = Data_String_CodePoints.length(s) <= 50;
                if ($47) {
                    return TacoInput.Valid.value;
                };
                return new TacoInput.Invalid("");
            },
            testId: "new-location-description-input"
        });
        var newLocationCode = TacoInput.stringInput()()(Data_Eq.eqString)({
            value: v.value0.code,
            onSave: function (value) {
                return v.value1(function (r) {
                    return {
                        code: value,
                        description: r.description
                    };
                });
            },
            validator: function (s) {
                var $48 = Data_String_CodePoints.length(s) <= 50;
                if ($48) {
                    return TacoInput.Valid.value;
                };
                return new TacoInput.Invalid("");
            },
            testId: "new-location-code-input"
        });
        var newLocation = React_Basic.keyed("new-location-row")(TacoTable_Row.row(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "description";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "code";
            }
        })(Data_Eq.eqString)))({
            rowData: v.value0,
            css: React_Basic_Emotion.css()({}),
            rowHeight: TacoTable_Types.RowMedium.value,
            sortProperty: TacoTable_Types.noSortProperty,
            onClick: function (v2) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            },
            className: "",
            foldingElement: Data_Maybe.Nothing.value,
            styleSet: locationTreeModalStyleSet.rowStyleSet,
            style: React_Basic_DOM_Internal.css({}),
            columns: locationTreeModalColumns(function (v2) {
                if (v2 instanceof ColLocationCode) {
                    return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (v3) {
                        return newLocationCode;
                    });
                };
                if (v2 instanceof ColLocationDescription) {
                    return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (v3) {
                        return newLocationDescription;
                    });
                };
                if (v2 instanceof ColLocationActionButton) {
                    return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (v3) {
                        return newLocationsubmitButton;
                    });
                };
                throw new Error("Failed pattern match at ProjectLocationsTree (line 333, column 43 - line 336, column 85): " + [ v2.constructor.name ]);
            }),
            rowKey: "new-location-row"
        }));
        var modalActions = TacoModal.modalActions({
            contents: [ TacoButton.component()()({
                onClick: Data_Maybe.Just.create(props.onClose),
                text: "Peruuta",
                testId: "project-locations-tree-cancel-button"
            }) ]
        });
        var mkAction$prime = function (fn) {
            return function (value) {
                return function (handler) {
                    return new Actions.LocationActionRequest({
                        handler: handler,
                        projectId: props.projectId,
                        params: Types_Location.EditLocation.create(fn(value))
                    });
                };
            };
        };
        var locationColumns = locationTreeModalColumns(function (v2) {
            if (v2 instanceof ColLocationCode) {
                return TacoTable_Types.JSXCell.create([ TacoTable_Types.Input.value ])(function (v3) {
                    return TacoInput.remoteStringField()()({
                        value: v3.location.code,
                        mkAction: mkAction$prime(function (value) {
                            return {
                                id: v3.location.id,
                                code: value,
                                description: v3.location.description,
                                sortingNumber: v3.location.sortingNumber,
                                scale: v3.location.scale,
                                projectId: v3.location.projectId
                            };
                        }),
                        disabled: v3.isNonEmpty,
                        testId: "project-location-" + (Data_Show.show(Data_Show.showInt)(Data_Newtype.unwrap()(v3.location.id)) + "-code-input")
                    });
                });
            };
            if (v2 instanceof ColLocationDescription) {
                return TacoTable_Types.JSXCell.create([ TacoTable_Types.Input.value ])(function (v3) {
                    return TacoInput.remoteStringField()()({
                        value: v3.location.description,
                        mkAction: mkAction$prime(function (value) {
                            return {
                                id: v3.location.id,
                                code: v3.location.code,
                                description: value,
                                sortingNumber: v3.location.sortingNumber,
                                scale: v3.location.scale,
                                projectId: v3.location.projectId
                            };
                        }),
                        testId: "project-location-" + (Data_Show.show(Data_Show.showInt)(Data_Newtype.unwrap()(v3.location.id)) + "-description-input")
                    });
                });
            };
            if (v2 instanceof ColLocationActionButton) {
                return TacoTable_Types.JSXCell.create([ TacoTable_Types.Input.value ])(function (v3) {
                    return TacoButton.component()()({
                        text: "Poista",
                        color: TacoConstants.SecondaryRed.value,
                        disabled: v3.isNonEmpty,
                        title: "Vain sijainnin, jolla ei ole m\xe4\xe4r\xe4rivej\xe4 suoritteilla tai rakenteilla, voi poistaa.",
                        onClick: new Data_Maybe.Just(v1.value1(function (v4) {
                            return new Data_Maybe.Just(v3.location);
                        })),
                        testId: "project-location-" + (Data_Show.show(Data_Show.showInt)(Data_Newtype.unwrap()(v3.location.id)) + "-action-button")
                    });
                });
            };
            throw new Error("Failed pattern match at ProjectLocationsTree (line 250, column 48 - line 270, column 12): " + [ v2.constructor.name ]);
        });
        var mkLocation = function (v2) {
            var rowKey = "location-" + Data_Show.show(Types_Location.showProjectLocationId)(v2.id);
            return React_Basic.keyed(rowKey)(TacoTable_Row.row(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "location";
                }
            })(Types_Location.eqProjectLocation))()({
                reflectSymbol: function () {
                    return "isNonEmpty";
                }
            })(Data_Eq.eqBoolean)))({
                rowData: {
                    location: v2,
                    isNonEmpty: Data_Set.member(Data_Ord.ordString)(v2.code)(props.nonEmptyLocations)
                },
                css: React_Basic_Emotion.css()({}),
                rowHeight: TacoTable_Types.RowLarge.value,
                sortProperty: TacoTable_Types.noSortProperty,
                onClick: function (v3) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                },
                className: "",
                foldingElement: Data_Maybe.Nothing.value,
                styleSet: locationTreeModalStyleSet.rowStyleSet,
                style: React_Basic_DOM_Internal.css({}),
                columns: locationColumns,
                rowKey: rowKey
            }));
        };
        var locations = Data_Functor.map(Data_Functor.functorArray)(mkLocation)(props.locations);
        var newHeader = TacoTable_Header.header(TofuHooks.getNameVoid)(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "location";
            }
        })(Types_Location.eqProjectLocation))()({
            reflectSymbol: function () {
                return "isNonEmpty";
            }
        })(Data_Eq.eqBoolean)))({
            sortProperty: TacoTable_Types.noSortProperty,
            sortingOrder: TacoTable_Types.Ascending.value,
            styleSet: locationTreeModalStyleSet.headerStyleSet,
            onSort: function (v2) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            },
            foldingElement: Data_Maybe.Nothing.value,
            columns: locationColumns,
            onResize: function (v2) {
                return function (v3) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                };
            },
            columnSizeOffsets: Data_Maybe.Nothing.value,
            scrollLeft: 0.0
        });
        var deletePromptModalBody = TacoModal.modalBody({
            contents: (function () {
                if (v1.value0 instanceof Data_Maybe.Nothing) {
                    return [  ];
                };
                if (v1.value0 instanceof Data_Maybe.Just) {
                    var locDescriptionStr = (function () {
                        var $59 = Data_String_Common["null"](v1.value0.value0.description);
                        if ($59) {
                            return "";
                        };
                        return " \"" + (v1.value0.value0.description + "\"");
                    })();
                    return Control_Applicative.pure(Control_Applicative.applicativeArray)(TacoText.component()()({
                        text: "Haluatko varmasti poistaa sijainnin " + (v1.value0.value0.code + (locDescriptionStr + "?"))
                    }));
                };
                throw new Error("Failed pattern match at ProjectLocationsTree (line 196, column 19 - line 203, column 56): " + [ v1.value0.constructor.name ]);
            })()
        });
        var deletePromptModalActions = TacoModal.modalActions({
            contents: [ TacoButton.component()()({
                onClick: Data_Maybe.Just.create(v1.value1(function (v2) {
                    return Data_Maybe.Nothing.value;
                })),
                text: "Peruuta",
                testId: "cancel-delete-location-button"
            }), TacoButton.component()()({
                onClick: (function () {
                    if (v1.value0 instanceof Data_Maybe.Nothing) {
                        return Data_Maybe.Nothing.value;
                    };
                    if (v1.value0 instanceof Data_Maybe.Just) {
                        return new Data_Maybe.Just(dispatch(new Actions.LocationActionRequest({
                            handler: function (v2) {
                                return v1.value1(function (v3) {
                                    return Data_Maybe.Nothing.value;
                                });
                            },
                            projectId: props.projectId,
                            params: new Types_Location.DeleteLocation({
                                projectLocationId: v1.value0.value0.id
                            })
                        })));
                    };
                    throw new Error("Failed pattern match at ProjectLocationsTree (line 214, column 26 - line 223, column 26): " + [ v1.value0.constructor.name ]);
                })(),
                text: "Poista",
                color: TacoConstants.SecondaryRed.value,
                testId: "submit-delete-location-button"
            }) ]
        });
        var deletePromptModal = TacoModal.component()()({
            onClose: v1.value1(function (v2) {
                return Data_Maybe.Nothing.value;
            }),
            isActive: Data_Maybe.isJust(v1.value0),
            heading: "Poista sijainti",
            contents: [ deletePromptModalBody, deletePromptModalActions ]
        });
        var modalBody = TacoModal.modalBody({
            contents: Control_Applicative.pure(Control_Applicative.applicativeArray)(Box.box("__TacoTable")([ Box.Width.create(new TacoConstants.CustomSize("100%")) ])([ React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                className: "table-rows",
                css: Box.boxStyle([ Box.Width.create(new TacoConstants.CustomSize("100%")) ]),
                children: Data_Semigroup.append(Data_Semigroup.semigroupArray)([ newHeader ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(locations)([ newLocation ]))
            }), deletePromptModal ]))
        });
        return TacoModal.component()()({
            onClose: props.onClose,
            isActive: props.isActive,
            heading: "Muokkaa sijainteja",
            contents: [ modalBody, modalActions ]
        });
    };
});
var projectLocationsTree = TofuHooks.mkMemoHookComponent("ProjectLocationsTree")(function (props) {
    return function __do() {
        var v = Client_WASM.useProjectLocations(props.projectId)();
        var v1 = Client_WASM.useElementLocations(props.projectId)();
        var v2 = Client_WASM.useProjectLocationsData(props.projectId)();
        var v3 = Client_WASM.useUnfilteredSEPProjectLocationsData(props.projectId)();
        var v4 = TofuHooks.useState(false)();
        var v5 = ReactHooksUtils.usePersistMemo("project-locations-tree-non-empty-location")([ ReactHooksUtils.utf(props.estimationLocations), ReactHooksUtils.utf(v1.value0) ])(function (v5) {
            var componentLocationCodes = Data_Set.fromFoldable(Data_Foldable.foldableArray)(Data_Ord.ordString)(Data_Functor.map(Data_Functor.functorArray)(function (v6) {
                return v6.locationCode;
            })(props.estimationLocations));
            var elementLocationCodes = Data_Set.fromFoldable(Data_Foldable.foldableArray)(Data_Ord.ordString)(Data_Functor.map(Data_Functor.functorArray)((function () {
                var $92 = Data_Newtype.un()(Types_Element.WasmElementLocation);
                return function ($93) {
                    return (function (v6) {
                        return v6.locationCode;
                    })($92($93));
                };
            })())(v1.value0));
            return Data_Semigroup.append(Data_Set.semigroupSet(Data_Ord.ordString))(componentLocationCodes)(elementLocationCodes);
        })();
        var title$prime = function (node) {
            return node.parsedCode + (function () {
                var $74 = node.description === "";
                if ($74) {
                    return "";
                };
                return " \u2013 " + node.description;
            })();
        };
        var currencySymbol = EstimationUtils.getCurrencySymbol(props.projectCurrency);
        var prepareSubItem = function (v6) {
            var title = title$prime(v6);
            return {
                title: "",
                titleJSX: Data_Maybe.Just.create(React_Basic_DOM_Generated.var_([ React_Basic_DOM.text(title) ])),
                id: title,
                value: TacoList.TextValue.create(Formatters.formatDecimalValue(2)(v6.totalSum) + (" " + currencySymbol)),
                onClick: new Data_Maybe.Just(props.toggleLocationFilter(v6.code)),
                disabled: v6.totalSum === 0.0,
                getItems: function (v7) {
                    return Data_Functor.map(Data_Functor.functorArray)(function ($94) {
                        return TacoList.ExpandableItemWrapper(prepareSubItem($94));
                    })(v6.children);
                },
                hasItems: Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Array["null"])(v6.children),
                initExpanded: false
            };
        };
        var prepareItem = function (v6) {
            var title = title$prime(v6);
            return {
                title: "",
                titleJSX: Data_Maybe.Just.create(React_Basic_DOM_Generated.var_([ React_Basic_DOM.text(title) ])),
                id: title,
                value: TacoList.TextValue.create(Formatters.formatCurrencyValue(props.projectCurrency)(v6.totalSum)),
                onClick: Data_Maybe.Just.create(props.toggleLocationFilter(v6.code)),
                initExpanded: false,
                disabled: v6.totalSum === 0.0,
                getItems: function (v7) {
                    return Data_Functor.map(Data_Functor.functorArray)(function ($95) {
                        return TacoList.ExpandableItemWrapper(prepareSubItem($95));
                    })(v6.children);
                },
                hasItems: Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Array["null"])(v6.children)
            };
        };
        var locationItems = TofuHooks.useMemo([ ReactHooksUtils.utf(v2.value0), ReactHooksUtils.utf(v3.value0), ReactHooksUtils.utf(props.toggleLocationFilter) ])(function (v6) {
            var socialExpenseText = function (text) {
                return TacoText.component()()({
                    text: text,
                    color: TacoConstants.GrayDark.value,
                    testId: "project-locations-total-social-expense-price"
                });
            };
            var locations = Data_Functor.map(Data_Functor.functorArray)(prepareItem)(v2.value0);
            var _socialExpensePrice = function (v7) {
                return v7.socialExpensePrice;
            };
            var totalSocialExpensePrice = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(_socialExpensePrice)(v3.value0));
            var socialExpenseRowItem = {
                title: "Sosiaalikulut (ei mukana summissa)",
                titleJSX: Data_Maybe.Just.create(socialExpenseText("Sosiaalikulut (ei mukana summissa)")),
                value: TacoList.JSXValue.create(socialExpenseText(Formatters.formatCurrencyValue(props.projectCurrency)(totalSocialExpensePrice))),
                disabled: false,
                onClick: Data_Maybe.Nothing.value,
                getItems: function (v7) {
                    return [  ];
                },
                hasItems: false,
                initExpanded: false,
                id: "Sosiaalikulut (ei mukana summissa)"
            };
            var socialExpenseRow = (function () {
                if (props.showSocialExpensesInCosts) {
                    return Common.emptyArray;
                };
                return [ socialExpenseRowItem ];
            })();
            return Data_Semigroup.append(Data_Semigroup.semigroupArray)(locations)(socialExpenseRow);
        })();
        var loading = v.value1 || (v1.value1 || v2.value1);
        props.setLoading("ProjectLocationsTree")(loading)();
        return ReactBasicUtils["div'"]("__ProjectLocationsTree")([ Gap.gapX(TacoConstants.M.value)([ React_Basic_DOM_Generated.h3()({
            className: "heading",
            children: Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM.text("Sijainnit"))
        }), TacoButton.component()()({
            buttonStyle: TacoButton_Types.Link.value,
            text: "Muokkaa",
            onClick: Data_Maybe.Just.create(v4.value1(function (v6) {
                return true;
            })),
            testId: "edit-project-locations-button"
        }) ]), TacoList.component({
            items: locationItems
        }), locationsTreeModal({
            isActive: v4.value0,
            onClose: v4.value1(function (v6) {
                return false;
            }),
            locations: v.value0,
            nonEmptyLocations: v5,
            projectId: props.projectId
        }) ]);
    };
});
module.exports = {
    projectLocationsTree: projectLocationsTree
};
