// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Data_Functor = require("../Data.Functor/index.js");
var ArrowDown = (function () {
    function ArrowDown() {

    };
    ArrowDown.value = new ArrowDown();
    return ArrowDown;
})();
var ArrowLeft = (function () {
    function ArrowLeft() {

    };
    ArrowLeft.value = new ArrowLeft();
    return ArrowLeft;
})();
var ArrowRight = (function () {
    function ArrowRight() {

    };
    ArrowRight.value = new ArrowRight();
    return ArrowRight;
})();
var ArrowUp = (function () {
    function ArrowUp() {

    };
    ArrowUp.value = new ArrowUp();
    return ArrowUp;
})();
var Escape = (function () {
    function Escape() {

    };
    Escape.value = new Escape();
    return Escape;
})();
var Enter = (function () {
    function Enter() {

    };
    Enter.value = new Enter();
    return Enter;
})();
var UnknownKey = (function () {
    function UnknownKey() {

    };
    UnknownKey.value = new UnknownKey();
    return UnknownKey;
})();
var AllKeys = (function () {
    function AllKeys() {

    };
    AllKeys.value = new AllKeys();
    return AllKeys;
})();
var toKeyboardKey = function (v) {
    if (v instanceof ArrowDown) {
        return "ArrowDown";
    };
    if (v instanceof ArrowLeft) {
        return "ArrowLeft";
    };
    if (v instanceof ArrowRight) {
        return "ArrowRight";
    };
    if (v instanceof ArrowUp) {
        return "ArrowUp";
    };
    if (v instanceof Escape) {
        return "Escape";
    };
    if (v instanceof Enter) {
        return "Enter";
    };
    if (v instanceof UnknownKey) {
        return "Unknown";
    };
    if (v instanceof AllKeys) {
        return "All";
    };
    throw new Error("Failed pattern match at Utils.Keyboard (line 86, column 1 - line 86, column 39): " + [ v.constructor.name ]);
};
var preventDefault = function (event) {
    return $foreign["_preventDefault"](event);
};
var getKeyboardKey = function (v) {
    if (v === "ArrowDown") {
        return ArrowDown.value;
    };
    if (v === "ArrowLeft") {
        return ArrowLeft.value;
    };
    if (v === "ArrowRight") {
        return ArrowRight.value;
    };
    if (v === "ArrowUp") {
        return ArrowUp.value;
    };
    if (v === "Escape") {
        return Escape.value;
    };
    if (v === "Enter") {
        return Enter.value;
    };
    return UnknownKey.value;
};
var toKeyboardEvent = function (event) {
    return {
        altKey: event.altKey,
        ctrlKey: event.ctrlKey,
        metaKey: event.metaKey,
        shiftKey: event.shiftKey,
        key: getKeyboardKey(event.key),
        event: event
    };
};
var registerKeyHandler = function (keyboardEventCodes) {
    return function (key) {
        return function (handler) {
            return function () {
                return $foreign["_registerKeyHandler"](function ($8) {
                    return handler(toKeyboardEvent($8))();
                })(Data_Functor.map(Data_Functor.functorArray)(toKeyboardKey)(keyboardEventCodes))(key);
            };
        };
    };
};
var eqKeyboardKey = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ArrowDown && y instanceof ArrowDown) {
                return true;
            };
            if (x instanceof ArrowLeft && y instanceof ArrowLeft) {
                return true;
            };
            if (x instanceof ArrowRight && y instanceof ArrowRight) {
                return true;
            };
            if (x instanceof ArrowUp && y instanceof ArrowUp) {
                return true;
            };
            if (x instanceof Escape && y instanceof Escape) {
                return true;
            };
            if (x instanceof Enter && y instanceof Enter) {
                return true;
            };
            if (x instanceof UnknownKey && y instanceof UnknownKey) {
                return true;
            };
            if (x instanceof AllKeys && y instanceof AllKeys) {
                return true;
            };
            return false;
        };
    }
};
var deregisterKeyHandler = function (key) {
    return function () {
        return $foreign["_deregisterKeyHandler"](key);
    };
};
module.exports = {
    ArrowDown: ArrowDown,
    ArrowLeft: ArrowLeft,
    ArrowRight: ArrowRight,
    ArrowUp: ArrowUp,
    Escape: Escape,
    Enter: Enter,
    UnknownKey: UnknownKey,
    AllKeys: AllKeys,
    registerKeyHandler: registerKeyHandler,
    deregisterKeyHandler: deregisterKeyHandler,
    preventDefault: preventDefault,
    eqKeyboardKey: eqKeyboardKey
};
