// Generated by purs version 0.14.5
"use strict";
var Data_Int = require("../Data.Int/index.js");
var Data_Number_Format = require("../Data.Number.Format/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");
var toString = Unsafe_Coerce.unsafeCoerce;
var size = function ($0) {
    return React_Basic_Emotion.str(TacoConstants.sizeStr($0));
};
var rem = function (units) {
    return React_Basic_Emotion.str(Data_Number_Format.toString(units) + "rem");
};
var px = function (units) {
    return React_Basic_Emotion.str(Data_Int.toStringAs(Data_Int.decimal)(units) + "px");
};
var none = React_Basic_Emotion.none;
var colorScheme = function ($1) {
    return React_Basic_Emotion.color(TacoConstants.color($1));
};
var border = function ($2) {
    return React_Basic_Emotion.str(TacoConstants.borderStr($2));
};
module.exports = {
    colorScheme: colorScheme,
    px: px,
    rem: rem,
    border: border,
    size: size,
    toString: toString,
    none: none,
    "int": React_Basic_Emotion["int"],
    num: React_Basic_Emotion.num,
    str: React_Basic_Emotion.str
};
