import { useMemo } from "react";
import { useWorkerState } from "../../../../hooks/useWorkerState";
import { FullProjectData } from "../../../Estimation/Report/Report";

export const c200Data = (projectId: number) => {
  if (!projectId) {
    return null;
  }

  const componentsClassData = useWorkerState(
    "ProjectClassificationClassesState",
    projectId
  );
  const componentsWithResourcesData = useWorkerState(
    "ComponentsWithResourcesState",
    projectId
  );
  const subProjects = useWorkerState("EstimationSubprojectsState", projectId);
  const projectData = useWorkerState("ProjectDetailsDataState", projectId);

  const locations = useWorkerState("ProjectLocationsState", projectId);
  const costClasses = useWorkerState("EstimationCostClassesState", projectId);

  const elements = useWorkerState("ElementsDataState", projectId);

  const measurements = useWorkerState("MeasurementsState", projectId);

  const allDataReady =
    componentsWithResourcesData !== null &&
    componentsClassData !== null &&
    projectData !== null &&
    subProjects !== null &&
    locations !== null &&
    costClasses !== null &&
    elements !== null &&
    measurements !== null;

  return useMemo(() => {
    if (!allDataReady) {
      return null;
    }

    const fullReportData: FullProjectData = {
      componentsClassData,
      componentsWithResourcesData,
      projectData,
      subProjects,
      locations,
      costClasses,
      elements,
      measurements,
    };
    return fullReportData;
  }, [
    allDataReady,
    componentsClassData,
    componentsWithResourcesData,
    projectData,
    subProjects,
    locations,
    costClasses,
    elements,
    measurements,
  ]);
};
export const mapC200Data = (fullReportData: FullProjectData) => {
  return fullReportData?.componentsWithResourcesData?.map((data) => {
    const codeStartsWith = data.component.code?.slice(0, 2);
    const componentsClass = fullReportData?.componentsClassData?.find(
      (c) => c.code === codeStartsWith
    );
    const componentsCostClasses = data.totalPriceByCostClasses
      .filter((cc) => cc.totalPrice !== 0)
      .map((cc) => {
        const costClass = fullReportData?.costClasses?.find(
          (c) => c.costClassCode === cc.costClassCode
        );
        return {
          ...cc,
          hourlyPricing: costClass?.hourlyPricing,
          multiplier: costClass?.multiplier,
          socialPercentage: costClass?.socialExpensePercentageInCostEstimation,
        };
      });
    const hours = componentsCostClasses
      .map((cc) => cc.hours)
      .reduce((a, b) => a + b, 0);
    const hoursPerUnit = hours / data.component.amount;
    const pricePerHour = data.totalPrice / hours;
    const pricePerUnit = data.totalPrice / data.component.amount;
    const locationsCodes = data.locations.map((l) => l.locationCode);
    const costClassesWithSocial = componentsCostClasses.map((cc) => {
      return {
        ...cc,
        socialExpenses:
          cc.hourlyPricing && cc.socialPercentage
            ? cc.totalPriceWithoutSocialExpenses * cc.socialPercentage
            : 0,
      };
    });

    const totalSocialExpenses = costClassesWithSocial.reduce(
      (acc, cc) => acc + cc.socialExpenses,
      0
    );

    const componentsElements = fullReportData.elements
      ?.filter((e) => {
        return e.components.find(
          (c) => c.resourcesByComponent.component.id === data.component.id
        );
      })
      .flatMap((e) => {
        return {
          elementClass: e.element.code,
          elementDescription: e.element.description,
          elementAmount: e.amount,
          elementUnit: e.element.unit,
          elementTotalPrice: e.totalPrice,
          elementTotalPriceByCostClasses: e.totalPriceByCostClasses,
          elementMemo: e.element.memo,
          elementResources: e.components.find(
            (c) => c.resourcesByComponent.component.id === data.component.id
          ),
          elementRtCode: e.element.rtCode,
          elementRtDescription: e.element.rtDescription,
        };
      });

    return {
      componentCode: data.component.code,
      componentDescription: data.component.description,
      subProject: data.component.subProjectCode,
      quantity: data.component.amount,
      unit: data.component.unit,
      hours: hours,
      hoursPerUnit: hoursPerUnit,
      pricePerHour: pricePerHour,
      pricePerUnit: pricePerUnit,
      totalSocialExpenses,
      totalPrice: data.totalPriceWithoutSocialExpenses + totalSocialExpenses,
      totalPriceWithoutSocial: data.totalPriceWithoutSocialExpenses,
      costGroupCode: componentsClass?.code,
      costGroupDescription: componentsClass?.description,
      locations: locationsCodes,
      flagged: data.component.flagged,
      checked: data.component.checked,
      memo: data.component.memo,
      costClasses: costClassesWithSocial,
      componentResources: data.resources,
      elements: componentsElements,
      measurements: fullReportData.measurements,
    };
  });
};
