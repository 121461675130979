// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Locale = require("../Locale/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoTableUtils = require("../TacoTableUtils/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_JSONString = require("../Types.JSONString/index.js");
var UseFetch = require("../UseFetch/index.js");
var UseNullableRef = require("../UseNullableRef/index.js");
var ByProjectId = (function () {
    function ByProjectId() {

    };
    ByProjectId.value = new ByProjectId();
    return ByProjectId;
})();
var ByEmail = (function () {
    function ByEmail() {

    };
    ByEmail.value = new ByEmail();
    return ByEmail;
})();
var ByText = (function () {
    function ByText() {

    };
    ByText.value = new ByText();
    return ByText;
})();
var ByBody = (function () {
    function ByBody() {

    };
    ByBody.value = new ByBody();
    return ByBody;
})();
var ByCreatedAt = (function () {
    function ByCreatedAt() {

    };
    ByCreatedAt.value = new ByCreatedAt();
    return ByCreatedAt;
})();
var styleSet = TacoTable_Styles.optimizedTableStyleSet;
var getNameLogSortProperty = {
    getName: function (v) {
        return "LogSortProperty";
    }
};
var getLogSortingValue = function (v) {
    return function (v1) {
        if (v instanceof ByProjectId) {
            return TacoTable_Types.IntValue.create(Data_Maybe.fromMaybe(-1 | 0)(v1.projectId));
        };
        if (v instanceof ByEmail) {
            return new TacoTable_Types.StringValue(v1.email);
        };
        if (v instanceof ByText) {
            return new TacoTable_Types.StringValue(v1.text);
        };
        if (v instanceof ByBody) {
            return TacoTable_Types.StringValue.create(Data_Newtype.unwrap()(v1.body));
        };
        if (v instanceof ByCreatedAt) {
            return new TacoTable_Types.StringValue(v1.createdAt);
        };
        throw new Error("Failed pattern match at LogsContainer (line 126, column 1 - line 126, column 69): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var eqLogSortProperty = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ByProjectId && y instanceof ByProjectId) {
                return true;
            };
            if (x instanceof ByEmail && y instanceof ByEmail) {
                return true;
            };
            if (x instanceof ByText && y instanceof ByText) {
                return true;
            };
            if (x instanceof ByBody && y instanceof ByBody) {
                return true;
            };
            if (x instanceof ByCreatedAt && y instanceof ByCreatedAt) {
                return true;
            };
            return false;
        };
    }
};
var logTableOptimized = function (dictEq) {
    return TacoTable.tableOptimized(getNameLogSortProperty)(eqLogSortProperty)(dictEq);
};
var ordLogSortProperty = {
    compare: function (x) {
        return function (y) {
            if (x instanceof ByProjectId && y instanceof ByProjectId) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByProjectId) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByProjectId) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByEmail && y instanceof ByEmail) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByEmail) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByEmail) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByText && y instanceof ByText) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByText) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByText) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByBody && y instanceof ByBody) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByBody) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByBody) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByCreatedAt && y instanceof ByCreatedAt) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at LogsContainer (line 144, column 1 - line 144, column 58): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqLogSortProperty;
    }
};
var columns = [ {
    key: "projectId",
    label: React_Basic_DOM.text(Locale.lookup_("project")),
    cell: TacoTable_Types.PlainTextCell.create((function () {
        var $42 = Data_Maybe.maybe("")(Data_Show.show(Data_Show.showInt));
        return function ($43) {
            return $42((function (v) {
                return v.projectId;
            })($43));
        };
    })()),
    sortProperty: new Data_Maybe.Just(ByProjectId.value),
    width: new TacoTable_Types.Flex(1),
    headerJSX: Data_Maybe.Nothing.value
}, {
    key: "email",
    label: React_Basic_DOM.text(Locale.lookup_("user")),
    cell: TacoTable_Types.PlainTextCell.create((function () {
        var $44 = Data_Show.show(Data_Show.showString);
        return function ($45) {
            return $44((function (v) {
                return v.email;
            })($45));
        };
    })()),
    sortProperty: new Data_Maybe.Just(ByEmail.value),
    width: new TacoTable_Types.Flex(2),
    headerJSX: Data_Maybe.Nothing.value
}, {
    key: "text",
    label: React_Basic_DOM.text(Locale.lookup_("action")),
    cell: TacoTable_Types.PlainTextCell.create((function () {
        var $46 = Data_Show.show(Data_Show.showString);
        return function ($47) {
            return $46((function (v) {
                return v.text;
            })($47));
        };
    })()),
    sortProperty: new Data_Maybe.Just(ByText.value),
    width: new TacoTable_Types.Flex(3),
    headerJSX: Data_Maybe.Nothing.value
}, {
    key: "body",
    label: React_Basic_DOM.text(Locale.lookup_("log_body")),
    cell: TacoTable_Types.PlainTextCell.create((function () {
        var $48 = Data_Newtype.un()(Types_JSONString.JSONString);
        return function ($49) {
            return $48((function (v) {
                return v.body;
            })($49));
        };
    })()),
    sortProperty: new Data_Maybe.Just(ByBody.value),
    width: new TacoTable_Types.Fixed("40vw"),
    headerJSX: Data_Maybe.Nothing.value
}, {
    key: "createdAt",
    label: React_Basic_DOM.text(Locale.lookup_("createdAt")),
    cell: new TacoTable_Types.PlainDateTimeStringCell(function (v) {
        return v.createdAt;
    }),
    sortProperty: new Data_Maybe.Just(ByCreatedAt.value),
    width: new TacoTable_Types.Flex(1),
    headerJSX: Data_Maybe.Nothing.value
} ];
var logToRow = function (v) {
    return function (log) {
        return {
            rowData: log,
            rowKey: Data_Show.show(Data_Show.showInt)(log.id),
            className: "",
            onClick: Data_Function["const"](Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit)),
            foldingElement: Data_Maybe.Nothing.value,
            columns: columns
        };
    };
};
var logTable = TofuHooks.mkHookComponent("LogTable")(function (props) {
    return function __do() {
        var tableRef = UseNullableRef.useNullableRef();
        var v = TofuHooks.useState(new TacoTable_Types.SortingCriteria(TacoTable_Types.Ascending.value, new Data_Maybe.Just(ByCreatedAt.value), getLogSortingValue))();
        var sortedRows = TofuHooks.useMemo([ ReactHooksUtils.utf(props.logs), ReactHooksUtils.utf(v.value0) ])(function (v1) {
            return TacoTableUtils.sortByCriteria(v.value0)(props.logs);
        })();
        var table = logTableOptimized(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "text";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Data_Maybe.eqMaybe(Data_Eq.eqInt)))()({
            reflectSymbol: function () {
                return "organization";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "id";
            }
        })(Data_Eq.eqInt))()({
            reflectSymbol: function () {
                return "email";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "createdAt";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "body";
            }
        })(Types_JSONString.eqJSONString))()({
            reflectSymbol: function () {
                return "action";
            }
        })(Data_Eq.eqString)))({
            rows: sortedRows,
            mkRow: logToRow,
            columns: [  ],
            showHeader: true,
            sortProperty: v.value0.value1,
            sortingOrder: v.value0.value0,
            onSort: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit))),
            styleSet: styleSet,
            containerRef: tableRef,
            rowHeight: TacoTable_Types.RowSmall.value,
            initialScrollRow: Data_Maybe.Nothing.value,
            onResize: function (v1) {
                return function (v2) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                };
            },
            columnSizeOffsets: Data_Maybe.Nothing.value
        });
        return table;
    };
});
var component = TofuHooks.mkHookComponent("LogsContainer")(function (v) {
    return function __do() {
        var v1 = UseFetch.useFetchItems(Data_Eq.eqUnit)(Data_Unit.unit)(function (v1) {
            return v1.logs;
        })(Actions.GetLogsRequest.value)();
        return logTable({
            logs: v1.value0
        });
    };
});
module.exports = {
    component: component
};
