// Generated by purs version 0.14.5
"use strict";
var PortfolioReportRequest = (function () {
    function PortfolioReportRequest() {

    };
    PortfolioReportRequest.value = new PortfolioReportRequest();
    return PortfolioReportRequest;
})();
var PortfolioReportSuccess = (function () {
    function PortfolioReportSuccess(value0) {
        this.value0 = value0;
    };
    PortfolioReportSuccess.create = function (value0) {
        return new PortfolioReportSuccess(value0);
    };
    return PortfolioReportSuccess;
})();
var PortfolioReportError = (function () {
    function PortfolioReportError() {

    };
    PortfolioReportError.value = new PortfolioReportError();
    return PortfolioReportError;
})();
var UpdatePortfolioReportRequest = (function () {
    function UpdatePortfolioReportRequest() {

    };
    UpdatePortfolioReportRequest.value = new UpdatePortfolioReportRequest();
    return UpdatePortfolioReportRequest;
})();
var UpdatePortfolioReportSuccess = (function () {
    function UpdatePortfolioReportSuccess(value0) {
        this.value0 = value0;
    };
    UpdatePortfolioReportSuccess.create = function (value0) {
        return new UpdatePortfolioReportSuccess(value0);
    };
    return UpdatePortfolioReportSuccess;
})();
var UpdatePortfolioReportError = (function () {
    function UpdatePortfolioReportError() {

    };
    UpdatePortfolioReportError.value = new UpdatePortfolioReportError();
    return UpdatePortfolioReportError;
})();
var UpdateProjectGroupCacheRequest = (function () {
    function UpdateProjectGroupCacheRequest() {

    };
    UpdateProjectGroupCacheRequest.value = new UpdateProjectGroupCacheRequest();
    return UpdateProjectGroupCacheRequest;
})();
var UpdateProjectGroupCacheSuccess = (function () {
    function UpdateProjectGroupCacheSuccess() {

    };
    UpdateProjectGroupCacheSuccess.value = new UpdateProjectGroupCacheSuccess();
    return UpdateProjectGroupCacheSuccess;
})();
var UpdateProjectGroupCacheError = (function () {
    function UpdateProjectGroupCacheError() {

    };
    UpdateProjectGroupCacheError.value = new UpdateProjectGroupCacheError();
    return UpdateProjectGroupCacheError;
})();
var GetProjectGroupCacheLastUpdateRequest = (function () {
    function GetProjectGroupCacheLastUpdateRequest() {

    };
    GetProjectGroupCacheLastUpdateRequest.value = new GetProjectGroupCacheLastUpdateRequest();
    return GetProjectGroupCacheLastUpdateRequest;
})();
var GetProjectGroupCacheLastUpdateSuccess = (function () {
    function GetProjectGroupCacheLastUpdateSuccess(value0) {
        this.value0 = value0;
    };
    GetProjectGroupCacheLastUpdateSuccess.create = function (value0) {
        return new GetProjectGroupCacheLastUpdateSuccess(value0);
    };
    return GetProjectGroupCacheLastUpdateSuccess;
})();
var GetProjectGroupCacheLastUpdateError = (function () {
    function GetProjectGroupCacheLastUpdateError() {

    };
    GetProjectGroupCacheLastUpdateError.value = new GetProjectGroupCacheLastUpdateError();
    return GetProjectGroupCacheLastUpdateError;
})();
var AllProjectsRequest = (function () {
    function AllProjectsRequest() {

    };
    AllProjectsRequest.value = new AllProjectsRequest();
    return AllProjectsRequest;
})();
var AllProjectsSuccess = (function () {
    function AllProjectsSuccess(value0) {
        this.value0 = value0;
    };
    AllProjectsSuccess.create = function (value0) {
        return new AllProjectsSuccess(value0);
    };
    return AllProjectsSuccess;
})();
var AllProjectsError = (function () {
    function AllProjectsError() {

    };
    AllProjectsError.value = new AllProjectsError();
    return AllProjectsError;
})();
var CostControlProjectsRequest = (function () {
    function CostControlProjectsRequest() {

    };
    CostControlProjectsRequest.value = new CostControlProjectsRequest();
    return CostControlProjectsRequest;
})();
var CostControlProjectsSuccess = (function () {
    function CostControlProjectsSuccess(value0) {
        this.value0 = value0;
    };
    CostControlProjectsSuccess.create = function (value0) {
        return new CostControlProjectsSuccess(value0);
    };
    return CostControlProjectsSuccess;
})();
var CostControlProjectsError = (function () {
    function CostControlProjectsError() {

    };
    CostControlProjectsError.value = new CostControlProjectsError();
    return CostControlProjectsError;
})();
var EstimationProjectsRequest = (function () {
    function EstimationProjectsRequest() {

    };
    EstimationProjectsRequest.value = new EstimationProjectsRequest();
    return EstimationProjectsRequest;
})();
var EstimationProjectsSuccess = (function () {
    function EstimationProjectsSuccess(value0) {
        this.value0 = value0;
    };
    EstimationProjectsSuccess.create = function (value0) {
        return new EstimationProjectsSuccess(value0);
    };
    return EstimationProjectsSuccess;
})();
var EstimationProjectsError = (function () {
    function EstimationProjectsError() {

    };
    EstimationProjectsError.value = new EstimationProjectsError();
    return EstimationProjectsError;
})();
var GetProjectRequest = (function () {
    function GetProjectRequest(value0) {
        this.value0 = value0;
    };
    GetProjectRequest.create = function (value0) {
        return new GetProjectRequest(value0);
    };
    return GetProjectRequest;
})();
var GetProjectSuccess = (function () {
    function GetProjectSuccess(value0) {
        this.value0 = value0;
    };
    GetProjectSuccess.create = function (value0) {
        return new GetProjectSuccess(value0);
    };
    return GetProjectSuccess;
})();
var GetProjectError = (function () {
    function GetProjectError() {

    };
    GetProjectError.value = new GetProjectError();
    return GetProjectError;
})();
var GetProjectReset = (function () {
    function GetProjectReset() {

    };
    GetProjectReset.value = new GetProjectReset();
    return GetProjectReset;
})();
var GetReferenceSubprojectsRequest = (function () {
    function GetReferenceSubprojectsRequest(value0) {
        this.value0 = value0;
    };
    GetReferenceSubprojectsRequest.create = function (value0) {
        return new GetReferenceSubprojectsRequest(value0);
    };
    return GetReferenceSubprojectsRequest;
})();
var GetReferenceSubprojectsSuccess = (function () {
    function GetReferenceSubprojectsSuccess(value0) {
        this.value0 = value0;
    };
    GetReferenceSubprojectsSuccess.create = function (value0) {
        return new GetReferenceSubprojectsSuccess(value0);
    };
    return GetReferenceSubprojectsSuccess;
})();
var GetReferenceSubprojectsError = (function () {
    function GetReferenceSubprojectsError(value0) {
        this.value0 = value0;
    };
    GetReferenceSubprojectsError.create = function (value0) {
        return new GetReferenceSubprojectsError(value0);
    };
    return GetReferenceSubprojectsError;
})();
var AddReferenceProjectRequest = (function () {
    function AddReferenceProjectRequest(value0) {
        this.value0 = value0;
    };
    AddReferenceProjectRequest.create = function (value0) {
        return new AddReferenceProjectRequest(value0);
    };
    return AddReferenceProjectRequest;
})();
var AddReferenceProjectSuccess = (function () {
    function AddReferenceProjectSuccess() {

    };
    AddReferenceProjectSuccess.value = new AddReferenceProjectSuccess();
    return AddReferenceProjectSuccess;
})();
var AddReferenceProjectError = (function () {
    function AddReferenceProjectError() {

    };
    AddReferenceProjectError.value = new AddReferenceProjectError();
    return AddReferenceProjectError;
})();
var DeleteReferenceProjectRequest = (function () {
    function DeleteReferenceProjectRequest(value0) {
        this.value0 = value0;
    };
    DeleteReferenceProjectRequest.create = function (value0) {
        return new DeleteReferenceProjectRequest(value0);
    };
    return DeleteReferenceProjectRequest;
})();
var DeleteReferenceProjectSuccess = (function () {
    function DeleteReferenceProjectSuccess() {

    };
    DeleteReferenceProjectSuccess.value = new DeleteReferenceProjectSuccess();
    return DeleteReferenceProjectSuccess;
})();
var DeleteReferenceProjectError = (function () {
    function DeleteReferenceProjectError() {

    };
    DeleteReferenceProjectError.value = new DeleteReferenceProjectError();
    return DeleteReferenceProjectError;
})();
var InvoiceImgRequest = (function () {
    function InvoiceImgRequest(value0) {
        this.value0 = value0;
    };
    InvoiceImgRequest.create = function (value0) {
        return new InvoiceImgRequest(value0);
    };
    return InvoiceImgRequest;
})();
var ZipInvoiceImgsRequest = (function () {
    function ZipInvoiceImgsRequest(value0) {
        this.value0 = value0;
    };
    ZipInvoiceImgsRequest.create = function (value0) {
        return new ZipInvoiceImgsRequest(value0);
    };
    return ZipInvoiceImgsRequest;
})();
var ClassificationsRequest = (function () {
    function ClassificationsRequest() {

    };
    ClassificationsRequest.value = new ClassificationsRequest();
    return ClassificationsRequest;
})();
var ClassificationsSuccess = (function () {
    function ClassificationsSuccess(value0) {
        this.value0 = value0;
    };
    ClassificationsSuccess.create = function (value0) {
        return new ClassificationsSuccess(value0);
    };
    return ClassificationsSuccess;
})();
var ClassificationsError = (function () {
    function ClassificationsError() {

    };
    ClassificationsError.value = new ClassificationsError();
    return ClassificationsError;
})();
var GetProjectManagersRequest = (function () {
    function GetProjectManagersRequest() {

    };
    GetProjectManagersRequest.value = new GetProjectManagersRequest();
    return GetProjectManagersRequest;
})();
var GetProjectManagersSuccess = (function () {
    function GetProjectManagersSuccess(value0) {
        this.value0 = value0;
    };
    GetProjectManagersSuccess.create = function (value0) {
        return new GetProjectManagersSuccess(value0);
    };
    return GetProjectManagersSuccess;
})();
var GetProjectManagersError = (function () {
    function GetProjectManagersError() {

    };
    GetProjectManagersError.value = new GetProjectManagersError();
    return GetProjectManagersError;
})();
var AddProjectManagerRequest = (function () {
    function AddProjectManagerRequest(value0) {
        this.value0 = value0;
    };
    AddProjectManagerRequest.create = function (value0) {
        return new AddProjectManagerRequest(value0);
    };
    return AddProjectManagerRequest;
})();
var DeleteProjectManagerRequest = (function () {
    function DeleteProjectManagerRequest(value0) {
        this.value0 = value0;
    };
    DeleteProjectManagerRequest.create = function (value0) {
        return new DeleteProjectManagerRequest(value0);
    };
    return DeleteProjectManagerRequest;
})();
var EditProjectManagerRequest = (function () {
    function EditProjectManagerRequest(value0) {
        this.value0 = value0;
    };
    EditProjectManagerRequest.create = function (value0) {
        return new EditProjectManagerRequest(value0);
    };
    return EditProjectManagerRequest;
})();
var ProjectGroupsRequest = (function () {
    function ProjectGroupsRequest() {

    };
    ProjectGroupsRequest.value = new ProjectGroupsRequest();
    return ProjectGroupsRequest;
})();
var ProjectGroupsSuccess = (function () {
    function ProjectGroupsSuccess(value0) {
        this.value0 = value0;
    };
    ProjectGroupsSuccess.create = function (value0) {
        return new ProjectGroupsSuccess(value0);
    };
    return ProjectGroupsSuccess;
})();
var ProjectGroupsError = (function () {
    function ProjectGroupsError() {

    };
    ProjectGroupsError.value = new ProjectGroupsError();
    return ProjectGroupsError;
})();
var ConstructionTypesRequest = (function () {
    function ConstructionTypesRequest() {

    };
    ConstructionTypesRequest.value = new ConstructionTypesRequest();
    return ConstructionTypesRequest;
})();
var ConstructionTypesSuccess = (function () {
    function ConstructionTypesSuccess(value0) {
        this.value0 = value0;
    };
    ConstructionTypesSuccess.create = function (value0) {
        return new ConstructionTypesSuccess(value0);
    };
    return ConstructionTypesSuccess;
})();
var ConstructionTypesError = (function () {
    function ConstructionTypesError() {

    };
    ConstructionTypesError.value = new ConstructionTypesError();
    return ConstructionTypesError;
})();
var FinancingTypesRequest = (function () {
    function FinancingTypesRequest() {

    };
    FinancingTypesRequest.value = new FinancingTypesRequest();
    return FinancingTypesRequest;
})();
var FinancingTypesSuccess = (function () {
    function FinancingTypesSuccess(value0) {
        this.value0 = value0;
    };
    FinancingTypesSuccess.create = function (value0) {
        return new FinancingTypesSuccess(value0);
    };
    return FinancingTypesSuccess;
})();
var FinancingTypesError = (function () {
    function FinancingTypesError() {

    };
    FinancingTypesError.value = new FinancingTypesError();
    return FinancingTypesError;
})();
var ProjectTypesRequest = (function () {
    function ProjectTypesRequest() {

    };
    ProjectTypesRequest.value = new ProjectTypesRequest();
    return ProjectTypesRequest;
})();
var ProjectTypesSuccess = (function () {
    function ProjectTypesSuccess(value0) {
        this.value0 = value0;
    };
    ProjectTypesSuccess.create = function (value0) {
        return new ProjectTypesSuccess(value0);
    };
    return ProjectTypesSuccess;
})();
var ProjectTypesError = (function () {
    function ProjectTypesError() {

    };
    ProjectTypesError.value = new ProjectTypesError();
    return ProjectTypesError;
})();
var ContractTypesRequest = (function () {
    function ContractTypesRequest() {

    };
    ContractTypesRequest.value = new ContractTypesRequest();
    return ContractTypesRequest;
})();
var ContractTypesSuccess = (function () {
    function ContractTypesSuccess(value0) {
        this.value0 = value0;
    };
    ContractTypesSuccess.create = function (value0) {
        return new ContractTypesSuccess(value0);
    };
    return ContractTypesSuccess;
})();
var ContractTypesError = (function () {
    function ContractTypesError() {

    };
    ContractTypesError.value = new ContractTypesError();
    return ContractTypesError;
})();
var ResponsibilityCompaniesRequest = (function () {
    function ResponsibilityCompaniesRequest() {

    };
    ResponsibilityCompaniesRequest.value = new ResponsibilityCompaniesRequest();
    return ResponsibilityCompaniesRequest;
})();
var ResponsibilityCompaniesSuccess = (function () {
    function ResponsibilityCompaniesSuccess(value0) {
        this.value0 = value0;
    };
    ResponsibilityCompaniesSuccess.create = function (value0) {
        return new ResponsibilityCompaniesSuccess(value0);
    };
    return ResponsibilityCompaniesSuccess;
})();
var ResponsibilityCompaniesFailure = (function () {
    function ResponsibilityCompaniesFailure() {

    };
    ResponsibilityCompaniesFailure.value = new ResponsibilityCompaniesFailure();
    return ResponsibilityCompaniesFailure;
})();
var ProjectResponsibilityInfoRequest = (function () {
    function ProjectResponsibilityInfoRequest(value0) {
        this.value0 = value0;
    };
    ProjectResponsibilityInfoRequest.create = function (value0) {
        return new ProjectResponsibilityInfoRequest(value0);
    };
    return ProjectResponsibilityInfoRequest;
})();
var ProjectResponsibilityInfoSuccess = (function () {
    function ProjectResponsibilityInfoSuccess(value0) {
        this.value0 = value0;
    };
    ProjectResponsibilityInfoSuccess.create = function (value0) {
        return new ProjectResponsibilityInfoSuccess(value0);
    };
    return ProjectResponsibilityInfoSuccess;
})();
var ProjectResponsibilityInfoFailure = (function () {
    function ProjectResponsibilityInfoFailure() {

    };
    ProjectResponsibilityInfoFailure.value = new ProjectResponsibilityInfoFailure();
    return ProjectResponsibilityInfoFailure;
})();
var UpdateProjectResponsibilityInfoAction = (function () {
    function UpdateProjectResponsibilityInfoAction(value0) {
        this.value0 = value0;
    };
    UpdateProjectResponsibilityInfoAction.create = function (value0) {
        return new UpdateProjectResponsibilityInfoAction(value0);
    };
    return UpdateProjectResponsibilityInfoAction;
})();
var UpdateProjectResponsibilityInfoSuccess = (function () {
    function UpdateProjectResponsibilityInfoSuccess() {

    };
    UpdateProjectResponsibilityInfoSuccess.value = new UpdateProjectResponsibilityInfoSuccess();
    return UpdateProjectResponsibilityInfoSuccess;
})();
var UpdateProjectResponsibilityInfoFailure = (function () {
    function UpdateProjectResponsibilityInfoFailure() {

    };
    UpdateProjectResponsibilityInfoFailure.value = new UpdateProjectResponsibilityInfoFailure();
    return UpdateProjectResponsibilityInfoFailure;
})();
var MainCostGroupsRequest = (function () {
    function MainCostGroupsRequest(value0) {
        this.value0 = value0;
    };
    MainCostGroupsRequest.create = function (value0) {
        return new MainCostGroupsRequest(value0);
    };
    return MainCostGroupsRequest;
})();
var MainCostGroupsSuccess = (function () {
    function MainCostGroupsSuccess(value0) {
        this.value0 = value0;
    };
    MainCostGroupsSuccess.create = function (value0) {
        return new MainCostGroupsSuccess(value0);
    };
    return MainCostGroupsSuccess;
})();
var MainCostGroupsError = (function () {
    function MainCostGroupsError() {

    };
    MainCostGroupsError.value = new MainCostGroupsError();
    return MainCostGroupsError;
})();
var InvoiceBunchesRequest = (function () {
    function InvoiceBunchesRequest(value0) {
        this.value0 = value0;
    };
    InvoiceBunchesRequest.create = function (value0) {
        return new InvoiceBunchesRequest(value0);
    };
    return InvoiceBunchesRequest;
})();
var InvoiceBunchesSuccess = (function () {
    function InvoiceBunchesSuccess(value0) {
        this.value0 = value0;
    };
    InvoiceBunchesSuccess.create = function (value0) {
        return new InvoiceBunchesSuccess(value0);
    };
    return InvoiceBunchesSuccess;
})();
var InvoiceBunchesError = (function () {
    function InvoiceBunchesError() {

    };
    InvoiceBunchesError.value = new InvoiceBunchesError();
    return InvoiceBunchesError;
})();
var AllInvoiceBunchesRequest = (function () {
    function AllInvoiceBunchesRequest() {

    };
    AllInvoiceBunchesRequest.value = new AllInvoiceBunchesRequest();
    return AllInvoiceBunchesRequest;
})();
var AllInvoiceBunchesSuccess = (function () {
    function AllInvoiceBunchesSuccess(value0) {
        this.value0 = value0;
    };
    AllInvoiceBunchesSuccess.create = function (value0) {
        return new AllInvoiceBunchesSuccess(value0);
    };
    return AllInvoiceBunchesSuccess;
})();
var AllInvoiceBunchesError = (function () {
    function AllInvoiceBunchesError() {

    };
    AllInvoiceBunchesError.value = new AllInvoiceBunchesError();
    return AllInvoiceBunchesError;
})();
var CostGroupInfosRequest = (function () {
    function CostGroupInfosRequest(value0) {
        this.value0 = value0;
    };
    CostGroupInfosRequest.create = function (value0) {
        return new CostGroupInfosRequest(value0);
    };
    return CostGroupInfosRequest;
})();
var CostGroupInfosSuccess = (function () {
    function CostGroupInfosSuccess(value0) {
        this.value0 = value0;
    };
    CostGroupInfosSuccess.create = function (value0) {
        return new CostGroupInfosSuccess(value0);
    };
    return CostGroupInfosSuccess;
})();
var CostGroupInfosError = (function () {
    function CostGroupInfosError() {

    };
    CostGroupInfosError.value = new CostGroupInfosError();
    return CostGroupInfosError;
})();
var ProjectClassificationClassesRequest = (function () {
    function ProjectClassificationClassesRequest(value0) {
        this.value0 = value0;
    };
    ProjectClassificationClassesRequest.create = function (value0) {
        return new ProjectClassificationClassesRequest(value0);
    };
    return ProjectClassificationClassesRequest;
})();
var ProjectClassificationClassesSuccess = (function () {
    function ProjectClassificationClassesSuccess(value0) {
        this.value0 = value0;
    };
    ProjectClassificationClassesSuccess.create = function (value0) {
        return new ProjectClassificationClassesSuccess(value0);
    };
    return ProjectClassificationClassesSuccess;
})();
var ProjectClassificationClassesError = (function () {
    function ProjectClassificationClassesError() {

    };
    ProjectClassificationClassesError.value = new ProjectClassificationClassesError();
    return ProjectClassificationClassesError;
})();
var OrgClassificationClassesRequest = (function () {
    function OrgClassificationClassesRequest(value0) {
        this.value0 = value0;
    };
    OrgClassificationClassesRequest.create = function (value0) {
        return new OrgClassificationClassesRequest(value0);
    };
    return OrgClassificationClassesRequest;
})();
var OrgClassificationClassesSuccess = (function () {
    function OrgClassificationClassesSuccess(value0) {
        this.value0 = value0;
    };
    OrgClassificationClassesSuccess.create = function (value0) {
        return new OrgClassificationClassesSuccess(value0);
    };
    return OrgClassificationClassesSuccess;
})();
var OrgClassificationClassesError = (function () {
    function OrgClassificationClassesError() {

    };
    OrgClassificationClassesError.value = new OrgClassificationClassesError();
    return OrgClassificationClassesError;
})();
var GetClassificationGroupsRequest = (function () {
    function GetClassificationGroupsRequest(value0) {
        this.value0 = value0;
    };
    GetClassificationGroupsRequest.create = function (value0) {
        return new GetClassificationGroupsRequest(value0);
    };
    return GetClassificationGroupsRequest;
})();
var GetClassificationGroupsSuccess = (function () {
    function GetClassificationGroupsSuccess(value0) {
        this.value0 = value0;
    };
    GetClassificationGroupsSuccess.create = function (value0) {
        return new GetClassificationGroupsSuccess(value0);
    };
    return GetClassificationGroupsSuccess;
})();
var GetClassificationGroupsError = (function () {
    function GetClassificationGroupsError(value0) {
        this.value0 = value0;
    };
    GetClassificationGroupsError.create = function (value0) {
        return new GetClassificationGroupsError(value0);
    };
    return GetClassificationGroupsError;
})();
var ProjectCostGroupsRequest = (function () {
    function ProjectCostGroupsRequest(value0) {
        this.value0 = value0;
    };
    ProjectCostGroupsRequest.create = function (value0) {
        return new ProjectCostGroupsRequest(value0);
    };
    return ProjectCostGroupsRequest;
})();
var ProjectCostGroupsSuccess = (function () {
    function ProjectCostGroupsSuccess(value0) {
        this.value0 = value0;
    };
    ProjectCostGroupsSuccess.create = function (value0) {
        return new ProjectCostGroupsSuccess(value0);
    };
    return ProjectCostGroupsSuccess;
})();
var ProjectCostGroupsError = (function () {
    function ProjectCostGroupsError() {

    };
    ProjectCostGroupsError.value = new ProjectCostGroupsError();
    return ProjectCostGroupsError;
})();
var ProjectCostGroupCategoriesRequest = (function () {
    function ProjectCostGroupCategoriesRequest(value0) {
        this.value0 = value0;
    };
    ProjectCostGroupCategoriesRequest.create = function (value0) {
        return new ProjectCostGroupCategoriesRequest(value0);
    };
    return ProjectCostGroupCategoriesRequest;
})();
var ProjectCostGroupCategoriesSuccess = (function () {
    function ProjectCostGroupCategoriesSuccess(value0) {
        this.value0 = value0;
    };
    ProjectCostGroupCategoriesSuccess.create = function (value0) {
        return new ProjectCostGroupCategoriesSuccess(value0);
    };
    return ProjectCostGroupCategoriesSuccess;
})();
var ProjectCostGroupCategoriesError = (function () {
    function ProjectCostGroupCategoriesError() {

    };
    ProjectCostGroupCategoriesError.value = new ProjectCostGroupCategoriesError();
    return ProjectCostGroupCategoriesError;
})();
var UpdateCostGroupCategoryRequest = (function () {
    function UpdateCostGroupCategoryRequest(value0) {
        this.value0 = value0;
    };
    UpdateCostGroupCategoryRequest.create = function (value0) {
        return new UpdateCostGroupCategoryRequest(value0);
    };
    return UpdateCostGroupCategoryRequest;
})();
var ProjectCostGroupInvoicesRequest = (function () {
    function ProjectCostGroupInvoicesRequest(value0) {
        this.value0 = value0;
    };
    ProjectCostGroupInvoicesRequest.create = function (value0) {
        return new ProjectCostGroupInvoicesRequest(value0);
    };
    return ProjectCostGroupInvoicesRequest;
})();
var ProjectCostGroupInvoicesSuccess = (function () {
    function ProjectCostGroupInvoicesSuccess(value0) {
        this.value0 = value0;
    };
    ProjectCostGroupInvoicesSuccess.create = function (value0) {
        return new ProjectCostGroupInvoicesSuccess(value0);
    };
    return ProjectCostGroupInvoicesSuccess;
})();
var ProjectCostGroupInvoicesError = (function () {
    function ProjectCostGroupInvoicesError(value0) {
        this.value0 = value0;
    };
    ProjectCostGroupInvoicesError.create = function (value0) {
        return new ProjectCostGroupInvoicesError(value0);
    };
    return ProjectCostGroupInvoicesError;
})();
var ProjectCostClassesRequest = (function () {
    function ProjectCostClassesRequest(value0) {
        this.value0 = value0;
    };
    ProjectCostClassesRequest.create = function (value0) {
        return new ProjectCostClassesRequest(value0);
    };
    return ProjectCostClassesRequest;
})();
var ProjectCostClassesSuccess = (function () {
    function ProjectCostClassesSuccess(value0) {
        this.value0 = value0;
    };
    ProjectCostClassesSuccess.create = function (value0) {
        return new ProjectCostClassesSuccess(value0);
    };
    return ProjectCostClassesSuccess;
})();
var ProjectCostClassesError = (function () {
    function ProjectCostClassesError(value0) {
        this.value0 = value0;
    };
    ProjectCostClassesError.create = function (value0) {
        return new ProjectCostClassesError(value0);
    };
    return ProjectCostClassesError;
})();
var CostClassActionRequest = (function () {
    function CostClassActionRequest(value0) {
        this.value0 = value0;
    };
    CostClassActionRequest.create = function (value0) {
        return new CostClassActionRequest(value0);
    };
    return CostClassActionRequest;
})();
var CostClassActionSuccess = (function () {
    function CostClassActionSuccess(value0) {
        this.value0 = value0;
    };
    CostClassActionSuccess.create = function (value0) {
        return new CostClassActionSuccess(value0);
    };
    return CostClassActionSuccess;
})();
var CostClassActionError = (function () {
    function CostClassActionError(value0) {
        this.value0 = value0;
    };
    CostClassActionError.create = function (value0) {
        return new CostClassActionError(value0);
    };
    return CostClassActionError;
})();
var GetReferenceProjectCostClassesRequest = (function () {
    function GetReferenceProjectCostClassesRequest(value0) {
        this.value0 = value0;
    };
    GetReferenceProjectCostClassesRequest.create = function (value0) {
        return new GetReferenceProjectCostClassesRequest(value0);
    };
    return GetReferenceProjectCostClassesRequest;
})();
var GetReferenceProjectCostClassesSuccess = (function () {
    function GetReferenceProjectCostClassesSuccess(value0) {
        this.value0 = value0;
    };
    GetReferenceProjectCostClassesSuccess.create = function (value0) {
        return new GetReferenceProjectCostClassesSuccess(value0);
    };
    return GetReferenceProjectCostClassesSuccess;
})();
var GetReferenceProjectCostClassesError = (function () {
    function GetReferenceProjectCostClassesError() {

    };
    GetReferenceProjectCostClassesError.value = new GetReferenceProjectCostClassesError();
    return GetReferenceProjectCostClassesError;
})();
var SocialExpensesRequest = (function () {
    function SocialExpensesRequest(value0) {
        this.value0 = value0;
    };
    SocialExpensesRequest.create = function (value0) {
        return new SocialExpensesRequest(value0);
    };
    return SocialExpensesRequest;
})();
var SocialExpensesSuccess = (function () {
    function SocialExpensesSuccess(value0) {
        this.value0 = value0;
    };
    SocialExpensesSuccess.create = function (value0) {
        return new SocialExpensesSuccess(value0);
    };
    return SocialExpensesSuccess;
})();
var SocialExpensesError = (function () {
    function SocialExpensesError(value0) {
        this.value0 = value0;
    };
    SocialExpensesError.create = function (value0) {
        return new SocialExpensesError(value0);
    };
    return SocialExpensesError;
})();
var ProjectEstimateItemsRequest = (function () {
    function ProjectEstimateItemsRequest(value0) {
        this.value0 = value0;
    };
    ProjectEstimateItemsRequest.create = function (value0) {
        return new ProjectEstimateItemsRequest(value0);
    };
    return ProjectEstimateItemsRequest;
})();
var ProjectEstimateItemsSuccess = (function () {
    function ProjectEstimateItemsSuccess(value0) {
        this.value0 = value0;
    };
    ProjectEstimateItemsSuccess.create = function (value0) {
        return new ProjectEstimateItemsSuccess(value0);
    };
    return ProjectEstimateItemsSuccess;
})();
var ProjectEstimateItemsError = (function () {
    function ProjectEstimateItemsError(value0) {
        this.value0 = value0;
    };
    ProjectEstimateItemsError.create = function (value0) {
        return new ProjectEstimateItemsError(value0);
    };
    return ProjectEstimateItemsError;
})();
var GetEstimationUiState = (function () {
    function GetEstimationUiState() {

    };
    GetEstimationUiState.value = new GetEstimationUiState();
    return GetEstimationUiState;
})();
var SetEstimationUiState = (function () {
    function SetEstimationUiState(value0) {
        this.value0 = value0;
    };
    SetEstimationUiState.create = function (value0) {
        return new SetEstimationUiState(value0);
    };
    return SetEstimationUiState;
})();
var ProjectTargetItemsRequest = (function () {
    function ProjectTargetItemsRequest(value0) {
        this.value0 = value0;
    };
    ProjectTargetItemsRequest.create = function (value0) {
        return new ProjectTargetItemsRequest(value0);
    };
    return ProjectTargetItemsRequest;
})();
var ProjectTargetItemsSuccess = (function () {
    function ProjectTargetItemsSuccess(value0) {
        this.value0 = value0;
    };
    ProjectTargetItemsSuccess.create = function (value0) {
        return new ProjectTargetItemsSuccess(value0);
    };
    return ProjectTargetItemsSuccess;
})();
var ProjectTargetItemsError = (function () {
    function ProjectTargetItemsError(value0) {
        this.value0 = value0;
    };
    ProjectTargetItemsError.create = function (value0) {
        return new ProjectTargetItemsError(value0);
    };
    return ProjectTargetItemsError;
})();
var CostGroupItemActionRequest = (function () {
    function CostGroupItemActionRequest(value0) {
        this.value0 = value0;
    };
    CostGroupItemActionRequest.create = function (value0) {
        return new CostGroupItemActionRequest(value0);
    };
    return CostGroupItemActionRequest;
})();
var CostGroupItemActionSuccess = (function () {
    function CostGroupItemActionSuccess(value0) {
        this.value0 = value0;
    };
    CostGroupItemActionSuccess.create = function (value0) {
        return new CostGroupItemActionSuccess(value0);
    };
    return CostGroupItemActionSuccess;
})();
var CostGroupItemActionError = (function () {
    function CostGroupItemActionError(value0) {
        this.value0 = value0;
    };
    CostGroupItemActionError.create = function (value0) {
        return new CostGroupItemActionError(value0);
    };
    return CostGroupItemActionError;
})();
var EstimationElementActionRequest = (function () {
    function EstimationElementActionRequest(value0) {
        this.value0 = value0;
    };
    EstimationElementActionRequest.create = function (value0) {
        return new EstimationElementActionRequest(value0);
    };
    return EstimationElementActionRequest;
})();
var EstimationElementActionSuccess = (function () {
    function EstimationElementActionSuccess(value0) {
        this.value0 = value0;
    };
    EstimationElementActionSuccess.create = function (value0) {
        return new EstimationElementActionSuccess(value0);
    };
    return EstimationElementActionSuccess;
})();
var EstimationElementActionError = (function () {
    function EstimationElementActionError(value0) {
        this.value0 = value0;
    };
    EstimationElementActionError.create = function (value0) {
        return new EstimationElementActionError(value0);
    };
    return EstimationElementActionError;
})();
var ElementMeasureActionRequest = (function () {
    function ElementMeasureActionRequest(value0) {
        this.value0 = value0;
    };
    ElementMeasureActionRequest.create = function (value0) {
        return new ElementMeasureActionRequest(value0);
    };
    return ElementMeasureActionRequest;
})();
var ElementMeasureActionSuccess = (function () {
    function ElementMeasureActionSuccess(value0) {
        this.value0 = value0;
    };
    ElementMeasureActionSuccess.create = function (value0) {
        return new ElementMeasureActionSuccess(value0);
    };
    return ElementMeasureActionSuccess;
})();
var ElementMeasureActionError = (function () {
    function ElementMeasureActionError(value0) {
        this.value0 = value0;
    };
    ElementMeasureActionError.create = function (value0) {
        return new ElementMeasureActionError(value0);
    };
    return ElementMeasureActionError;
})();
var EstimationResourcePriceMatchActionRequest = (function () {
    function EstimationResourcePriceMatchActionRequest(value0) {
        this.value0 = value0;
    };
    EstimationResourcePriceMatchActionRequest.create = function (value0) {
        return new EstimationResourcePriceMatchActionRequest(value0);
    };
    return EstimationResourcePriceMatchActionRequest;
})();
var EstimationResourcePriceMatchActionSuccess = (function () {
    function EstimationResourcePriceMatchActionSuccess(value0) {
        this.value0 = value0;
    };
    EstimationResourcePriceMatchActionSuccess.create = function (value0) {
        return new EstimationResourcePriceMatchActionSuccess(value0);
    };
    return EstimationResourcePriceMatchActionSuccess;
})();
var EstimationResourcePriceMatchActionError = (function () {
    function EstimationResourcePriceMatchActionError() {

    };
    EstimationResourcePriceMatchActionError.value = new EstimationResourcePriceMatchActionError();
    return EstimationResourcePriceMatchActionError;
})();
var EstimationResourcePriceUpdateActionRequest = (function () {
    function EstimationResourcePriceUpdateActionRequest(value0) {
        this.value0 = value0;
    };
    EstimationResourcePriceUpdateActionRequest.create = function (value0) {
        return new EstimationResourcePriceUpdateActionRequest(value0);
    };
    return EstimationResourcePriceUpdateActionRequest;
})();
var EstimationResourcePriceUpdateActionSuccess = (function () {
    function EstimationResourcePriceUpdateActionSuccess() {

    };
    EstimationResourcePriceUpdateActionSuccess.value = new EstimationResourcePriceUpdateActionSuccess();
    return EstimationResourcePriceUpdateActionSuccess;
})();
var EstimationResourcePriceUpdateActionError = (function () {
    function EstimationResourcePriceUpdateActionError() {

    };
    EstimationResourcePriceUpdateActionError.value = new EstimationResourcePriceUpdateActionError();
    return EstimationResourcePriceUpdateActionError;
})();
var EstimationComponentActionRequest = (function () {
    function EstimationComponentActionRequest(value0) {
        this.value0 = value0;
    };
    EstimationComponentActionRequest.create = function (value0) {
        return new EstimationComponentActionRequest(value0);
    };
    return EstimationComponentActionRequest;
})();
var EstimationComponentActionSuccess = (function () {
    function EstimationComponentActionSuccess(value0) {
        this.value0 = value0;
    };
    EstimationComponentActionSuccess.create = function (value0) {
        return new EstimationComponentActionSuccess(value0);
    };
    return EstimationComponentActionSuccess;
})();
var EstimationComponentActionError = (function () {
    function EstimationComponentActionError(value0) {
        this.value0 = value0;
    };
    EstimationComponentActionError.create = function (value0) {
        return new EstimationComponentActionError(value0);
    };
    return EstimationComponentActionError;
})();
var EstimationResourceActionRequest = (function () {
    function EstimationResourceActionRequest(value0) {
        this.value0 = value0;
    };
    EstimationResourceActionRequest.create = function (value0) {
        return new EstimationResourceActionRequest(value0);
    };
    return EstimationResourceActionRequest;
})();
var EstimationResourceActionSuccess = (function () {
    function EstimationResourceActionSuccess(value0) {
        this.value0 = value0;
    };
    EstimationResourceActionSuccess.create = function (value0) {
        return new EstimationResourceActionSuccess(value0);
    };
    return EstimationResourceActionSuccess;
})();
var EstimationResourceActionError = (function () {
    function EstimationResourceActionError(value0) {
        this.value0 = value0;
    };
    EstimationResourceActionError.create = function (value0) {
        return new EstimationResourceActionError(value0);
    };
    return EstimationResourceActionError;
})();
var AddProjectRequest = (function () {
    function AddProjectRequest(value0) {
        this.value0 = value0;
    };
    AddProjectRequest.create = function (value0) {
        return new AddProjectRequest(value0);
    };
    return AddProjectRequest;
})();
var CopyProjectRequest = (function () {
    function CopyProjectRequest(value0) {
        this.value0 = value0;
    };
    CopyProjectRequest.create = function (value0) {
        return new CopyProjectRequest(value0);
    };
    return CopyProjectRequest;
})();
var UpdateProjectRequest = (function () {
    function UpdateProjectRequest(value0) {
        this.value0 = value0;
    };
    UpdateProjectRequest.create = function (value0) {
        return new UpdateProjectRequest(value0);
    };
    return UpdateProjectRequest;
})();
var UpdateProjectArchiveStatusRequest = (function () {
    function UpdateProjectArchiveStatusRequest(value0) {
        this.value0 = value0;
    };
    UpdateProjectArchiveStatusRequest.create = function (value0) {
        return new UpdateProjectArchiveStatusRequest(value0);
    };
    return UpdateProjectArchiveStatusRequest;
})();
var UpdateProjectCostGroupMemoRequest = (function () {
    function UpdateProjectCostGroupMemoRequest() {

    };
    UpdateProjectCostGroupMemoRequest.value = new UpdateProjectCostGroupMemoRequest();
    return UpdateProjectCostGroupMemoRequest;
})();
var AddCostGroupRequest = (function () {
    function AddCostGroupRequest(value0) {
        this.value0 = value0;
    };
    AddCostGroupRequest.create = function (value0) {
        return new AddCostGroupRequest(value0);
    };
    return AddCostGroupRequest;
})();
var AddCostGroupSuccess = (function () {
    function AddCostGroupSuccess() {

    };
    AddCostGroupSuccess.value = new AddCostGroupSuccess();
    return AddCostGroupSuccess;
})();
var DeleteCostGroupsRequest = (function () {
    function DeleteCostGroupsRequest(value0) {
        this.value0 = value0;
    };
    DeleteCostGroupsRequest.create = function (value0) {
        return new DeleteCostGroupsRequest(value0);
    };
    return DeleteCostGroupsRequest;
})();
var GetSalesInvoicesRequest = (function () {
    function GetSalesInvoicesRequest(value0) {
        this.value0 = value0;
    };
    GetSalesInvoicesRequest.create = function (value0) {
        return new GetSalesInvoicesRequest(value0);
    };
    return GetSalesInvoicesRequest;
})();
var DeleteSalesInvoicesRequest = (function () {
    function DeleteSalesInvoicesRequest(value0) {
        this.value0 = value0;
    };
    DeleteSalesInvoicesRequest.create = function (value0) {
        return new DeleteSalesInvoicesRequest(value0);
    };
    return DeleteSalesInvoicesRequest;
})();
var GetSalesInvoicesSuccess = (function () {
    function GetSalesInvoicesSuccess(value0) {
        this.value0 = value0;
    };
    GetSalesInvoicesSuccess.create = function (value0) {
        return new GetSalesInvoicesSuccess(value0);
    };
    return GetSalesInvoicesSuccess;
})();
var GetSalesInvoicesError = (function () {
    function GetSalesInvoicesError() {

    };
    GetSalesInvoicesError.value = new GetSalesInvoicesError();
    return GetSalesInvoicesError;
})();
var UpdateCodeForSalesInvoiceRequest = (function () {
    function UpdateCodeForSalesInvoiceRequest(value0) {
        this.value0 = value0;
    };
    UpdateCodeForSalesInvoiceRequest.create = function (value0) {
        return new UpdateCodeForSalesInvoiceRequest(value0);
    };
    return UpdateCodeForSalesInvoiceRequest;
})();
var UpdateCodeForSalesInvoiceSuccess = (function () {
    function UpdateCodeForSalesInvoiceSuccess() {

    };
    UpdateCodeForSalesInvoiceSuccess.value = new UpdateCodeForSalesInvoiceSuccess();
    return UpdateCodeForSalesInvoiceSuccess;
})();
var UpdateCodeForSalesInvoiceFailure = (function () {
    function UpdateCodeForSalesInvoiceFailure() {

    };
    UpdateCodeForSalesInvoiceFailure.value = new UpdateCodeForSalesInvoiceFailure();
    return UpdateCodeForSalesInvoiceFailure;
})();
var GetIncomeItemsRequest = (function () {
    function GetIncomeItemsRequest(value0) {
        this.value0 = value0;
    };
    GetIncomeItemsRequest.create = function (value0) {
        return new GetIncomeItemsRequest(value0);
    };
    return GetIncomeItemsRequest;
})();
var GetIncomeItemsSuccess = (function () {
    function GetIncomeItemsSuccess(value0) {
        this.value0 = value0;
    };
    GetIncomeItemsSuccess.create = function (value0) {
        return new GetIncomeItemsSuccess(value0);
    };
    return GetIncomeItemsSuccess;
})();
var GetIncomeItemsError = (function () {
    function GetIncomeItemsError() {

    };
    GetIncomeItemsError.value = new GetIncomeItemsError();
    return GetIncomeItemsError;
})();
var GetProjectLocationsRequest = (function () {
    function GetProjectLocationsRequest(value0) {
        this.value0 = value0;
    };
    GetProjectLocationsRequest.create = function (value0) {
        return new GetProjectLocationsRequest(value0);
    };
    return GetProjectLocationsRequest;
})();
var GetProjectLocationsSuccess = (function () {
    function GetProjectLocationsSuccess(value0) {
        this.value0 = value0;
    };
    GetProjectLocationsSuccess.create = function (value0) {
        return new GetProjectLocationsSuccess(value0);
    };
    return GetProjectLocationsSuccess;
})();
var GetProjectLocationsError = (function () {
    function GetProjectLocationsError() {

    };
    GetProjectLocationsError.value = new GetProjectLocationsError();
    return GetProjectLocationsError;
})();
var GetIncomeGroupsRequest = (function () {
    function GetIncomeGroupsRequest(value0) {
        this.value0 = value0;
    };
    GetIncomeGroupsRequest.create = function (value0) {
        return new GetIncomeGroupsRequest(value0);
    };
    return GetIncomeGroupsRequest;
})();
var GetIncomeGroupsSuccess = (function () {
    function GetIncomeGroupsSuccess(value0) {
        this.value0 = value0;
    };
    GetIncomeGroupsSuccess.create = function (value0) {
        return new GetIncomeGroupsSuccess(value0);
    };
    return GetIncomeGroupsSuccess;
})();
var GetIncomeGroupsFailure = (function () {
    function GetIncomeGroupsFailure() {

    };
    GetIncomeGroupsFailure.value = new GetIncomeGroupsFailure();
    return GetIncomeGroupsFailure;
})();
var AddIncomeGroupRequest = (function () {
    function AddIncomeGroupRequest(value0) {
        this.value0 = value0;
    };
    AddIncomeGroupRequest.create = function (value0) {
        return new AddIncomeGroupRequest(value0);
    };
    return AddIncomeGroupRequest;
})();
var SetIncomeItemApprovalStatusesRequest = (function () {
    function SetIncomeItemApprovalStatusesRequest(value0) {
        this.value0 = value0;
    };
    SetIncomeItemApprovalStatusesRequest.create = function (value0) {
        return new SetIncomeItemApprovalStatusesRequest(value0);
    };
    return SetIncomeItemApprovalStatusesRequest;
})();
var SetIncomeItemApprovalStatusesSuccess = (function () {
    function SetIncomeItemApprovalStatusesSuccess(value0) {
        this.value0 = value0;
    };
    SetIncomeItemApprovalStatusesSuccess.create = function (value0) {
        return new SetIncomeItemApprovalStatusesSuccess(value0);
    };
    return SetIncomeItemApprovalStatusesSuccess;
})();
var SetIncomeItemApprovalStatusesError = (function () {
    function SetIncomeItemApprovalStatusesError() {

    };
    SetIncomeItemApprovalStatusesError.value = new SetIncomeItemApprovalStatusesError();
    return SetIncomeItemApprovalStatusesError;
})();
var SetIncomeItemApprovalDatesRequest = (function () {
    function SetIncomeItemApprovalDatesRequest(value0) {
        this.value0 = value0;
    };
    SetIncomeItemApprovalDatesRequest.create = function (value0) {
        return new SetIncomeItemApprovalDatesRequest(value0);
    };
    return SetIncomeItemApprovalDatesRequest;
})();
var SetIncomeItemApprovalDatesSuccess = (function () {
    function SetIncomeItemApprovalDatesSuccess(value0) {
        this.value0 = value0;
    };
    SetIncomeItemApprovalDatesSuccess.create = function (value0) {
        return new SetIncomeItemApprovalDatesSuccess(value0);
    };
    return SetIncomeItemApprovalDatesSuccess;
})();
var SetIncomeItemApprovalDatesError = (function () {
    function SetIncomeItemApprovalDatesError() {

    };
    SetIncomeItemApprovalDatesError.value = new SetIncomeItemApprovalDatesError();
    return SetIncomeItemApprovalDatesError;
})();
var SetIncomeItemPlannedInvoiceDatesRequest = (function () {
    function SetIncomeItemPlannedInvoiceDatesRequest(value0) {
        this.value0 = value0;
    };
    SetIncomeItemPlannedInvoiceDatesRequest.create = function (value0) {
        return new SetIncomeItemPlannedInvoiceDatesRequest(value0);
    };
    return SetIncomeItemPlannedInvoiceDatesRequest;
})();
var SetIncomeItemPlannedInvoiceDatesSuccess = (function () {
    function SetIncomeItemPlannedInvoiceDatesSuccess(value0) {
        this.value0 = value0;
    };
    SetIncomeItemPlannedInvoiceDatesSuccess.create = function (value0) {
        return new SetIncomeItemPlannedInvoiceDatesSuccess(value0);
    };
    return SetIncomeItemPlannedInvoiceDatesSuccess;
})();
var SetIncomeItemPlannedInvoiceDatesError = (function () {
    function SetIncomeItemPlannedInvoiceDatesError() {

    };
    SetIncomeItemPlannedInvoiceDatesError.value = new SetIncomeItemPlannedInvoiceDatesError();
    return SetIncomeItemPlannedInvoiceDatesError;
})();
var SetIncomeItemsInclusionInTargetRequest = (function () {
    function SetIncomeItemsInclusionInTargetRequest(value0) {
        this.value0 = value0;
    };
    SetIncomeItemsInclusionInTargetRequest.create = function (value0) {
        return new SetIncomeItemsInclusionInTargetRequest(value0);
    };
    return SetIncomeItemsInclusionInTargetRequest;
})();
var SetIncomeItemsInclusionInTargetSuccess = (function () {
    function SetIncomeItemsInclusionInTargetSuccess(value0) {
        this.value0 = value0;
    };
    SetIncomeItemsInclusionInTargetSuccess.create = function (value0) {
        return new SetIncomeItemsInclusionInTargetSuccess(value0);
    };
    return SetIncomeItemsInclusionInTargetSuccess;
})();
var SetIncomeItemsInclusionInTargetError = (function () {
    function SetIncomeItemsInclusionInTargetError() {

    };
    SetIncomeItemsInclusionInTargetError.value = new SetIncomeItemsInclusionInTargetError();
    return SetIncomeItemsInclusionInTargetError;
})();
var InsertInvoiceRequest = (function () {
    function InsertInvoiceRequest(value0) {
        this.value0 = value0;
    };
    InsertInvoiceRequest.create = function (value0) {
        return new InsertInvoiceRequest(value0);
    };
    return InsertInvoiceRequest;
})();
var GetInvoicesRequest = (function () {
    function GetInvoicesRequest(value0) {
        this.value0 = value0;
    };
    GetInvoicesRequest.create = function (value0) {
        return new GetInvoicesRequest(value0);
    };
    return GetInvoicesRequest;
})();
var GetInvoicesSuccess = (function () {
    function GetInvoicesSuccess(value0) {
        this.value0 = value0;
    };
    GetInvoicesSuccess.create = function (value0) {
        return new GetInvoicesSuccess(value0);
    };
    return GetInvoicesSuccess;
})();
var GetInvoicesError = (function () {
    function GetInvoicesError() {

    };
    GetInvoicesError.value = new GetInvoicesError();
    return GetInvoicesError;
})();
var DeleteInvoicesRequest = (function () {
    function DeleteInvoicesRequest(value0) {
        this.value0 = value0;
    };
    DeleteInvoicesRequest.create = function (value0) {
        return new DeleteInvoicesRequest(value0);
    };
    return DeleteInvoicesRequest;
})();
var DeleteInvoicesSuccess = (function () {
    function DeleteInvoicesSuccess(value0) {
        this.value0 = value0;
    };
    DeleteInvoicesSuccess.create = function (value0) {
        return new DeleteInvoicesSuccess(value0);
    };
    return DeleteInvoicesSuccess;
})();
var DeleteInvoicesFailure = (function () {
    function DeleteInvoicesFailure() {

    };
    DeleteInvoicesFailure.value = new DeleteInvoicesFailure();
    return DeleteInvoicesFailure;
})();
var UpdateInvoiceLineBunchesRequest = (function () {
    function UpdateInvoiceLineBunchesRequest(value0) {
        this.value0 = value0;
    };
    UpdateInvoiceLineBunchesRequest.create = function (value0) {
        return new UpdateInvoiceLineBunchesRequest(value0);
    };
    return UpdateInvoiceLineBunchesRequest;
})();
var UpdateInvoiceLineBunchesSuccess = (function () {
    function UpdateInvoiceLineBunchesSuccess() {

    };
    UpdateInvoiceLineBunchesSuccess.value = new UpdateInvoiceLineBunchesSuccess();
    return UpdateInvoiceLineBunchesSuccess;
})();
var UpdateInvoiceLineBunchesError = (function () {
    function UpdateInvoiceLineBunchesError() {

    };
    UpdateInvoiceLineBunchesError.value = new UpdateInvoiceLineBunchesError();
    return UpdateInvoiceLineBunchesError;
})();
var UpdateInvoiceLinesCostGroupCodeRequest = (function () {
    function UpdateInvoiceLinesCostGroupCodeRequest(value0) {
        this.value0 = value0;
    };
    UpdateInvoiceLinesCostGroupCodeRequest.create = function (value0) {
        return new UpdateInvoiceLinesCostGroupCodeRequest(value0);
    };
    return UpdateInvoiceLinesCostGroupCodeRequest;
})();
var UpdateInvoiceLinesCostGroupCodeSuccess = (function () {
    function UpdateInvoiceLinesCostGroupCodeSuccess() {

    };
    UpdateInvoiceLinesCostGroupCodeSuccess.value = new UpdateInvoiceLinesCostGroupCodeSuccess();
    return UpdateInvoiceLinesCostGroupCodeSuccess;
})();
var UpdateInvoiceLinesCostGroupCodeError = (function () {
    function UpdateInvoiceLinesCostGroupCodeError() {

    };
    UpdateInvoiceLinesCostGroupCodeError.value = new UpdateInvoiceLinesCostGroupCodeError();
    return UpdateInvoiceLinesCostGroupCodeError;
})();
var UpdateInvoiceLinesCostClassCodeRequest = (function () {
    function UpdateInvoiceLinesCostClassCodeRequest(value0) {
        this.value0 = value0;
    };
    UpdateInvoiceLinesCostClassCodeRequest.create = function (value0) {
        return new UpdateInvoiceLinesCostClassCodeRequest(value0);
    };
    return UpdateInvoiceLinesCostClassCodeRequest;
})();
var UpdateInvoiceLinesCostClassCodeSuccess = (function () {
    function UpdateInvoiceLinesCostClassCodeSuccess() {

    };
    UpdateInvoiceLinesCostClassCodeSuccess.value = new UpdateInvoiceLinesCostClassCodeSuccess();
    return UpdateInvoiceLinesCostClassCodeSuccess;
})();
var UpdateInvoiceLinesCostClassCodeError = (function () {
    function UpdateInvoiceLinesCostClassCodeError() {

    };
    UpdateInvoiceLinesCostClassCodeError.value = new UpdateInvoiceLinesCostClassCodeError();
    return UpdateInvoiceLinesCostClassCodeError;
})();
var SuppliersRequest = (function () {
    function SuppliersRequest() {

    };
    SuppliersRequest.value = new SuppliersRequest();
    return SuppliersRequest;
})();
var SuppliersSuccess = (function () {
    function SuppliersSuccess(value0) {
        this.value0 = value0;
    };
    SuppliersSuccess.create = function (value0) {
        return new SuppliersSuccess(value0);
    };
    return SuppliersSuccess;
})();
var SuppliersError = (function () {
    function SuppliersError() {

    };
    SuppliersError.value = new SuppliersError();
    return SuppliersError;
})();
var UpdateSupplierBusinessIdRequest = (function () {
    function UpdateSupplierBusinessIdRequest(value0) {
        this.value0 = value0;
    };
    UpdateSupplierBusinessIdRequest.create = function (value0) {
        return new UpdateSupplierBusinessIdRequest(value0);
    };
    return UpdateSupplierBusinessIdRequest;
})();
var UpdateSupplierBusinessIdSuccess = (function () {
    function UpdateSupplierBusinessIdSuccess() {

    };
    UpdateSupplierBusinessIdSuccess.value = new UpdateSupplierBusinessIdSuccess();
    return UpdateSupplierBusinessIdSuccess;
})();
var UpdateSupplierBusinessIdError = (function () {
    function UpdateSupplierBusinessIdError() {

    };
    UpdateSupplierBusinessIdError.value = new UpdateSupplierBusinessIdError();
    return UpdateSupplierBusinessIdError;
})();
var NavigateRoute = (function () {
    function NavigateRoute(value0) {
        this.value0 = value0;
    };
    NavigateRoute.create = function (value0) {
        return new NavigateRoute(value0);
    };
    return NavigateRoute;
})();
var SetRoute = (function () {
    function SetRoute(value0) {
        this.value0 = value0;
    };
    SetRoute.create = function (value0) {
        return new SetRoute(value0);
    };
    return SetRoute;
})();
var GetReportExcelRequest = (function () {
    function GetReportExcelRequest(value0) {
        this.value0 = value0;
    };
    GetReportExcelRequest.create = function (value0) {
        return new GetReportExcelRequest(value0);
    };
    return GetReportExcelRequest;
})();
var GetPlanningCostGroupsExport = (function () {
    function GetPlanningCostGroupsExport(value0) {
        this.value0 = value0;
    };
    GetPlanningCostGroupsExport.create = function (value0) {
        return new GetPlanningCostGroupsExport(value0);
    };
    return GetPlanningCostGroupsExport;
})();
var GetPlanningProcumentExport = (function () {
    function GetPlanningProcumentExport(value0) {
        this.value0 = value0;
    };
    GetPlanningProcumentExport.create = function (value0) {
        return new GetPlanningProcumentExport(value0);
    };
    return GetPlanningProcumentExport;
})();
var GetEstimationExcelExportRequest = (function () {
    function GetEstimationExcelExportRequest(value0) {
        this.value0 = value0;
    };
    GetEstimationExcelExportRequest.create = function (value0) {
        return new GetEstimationExcelExportRequest(value0);
    };
    return GetEstimationExcelExportRequest;
})();
var GetEstimationQuantityExcelExportRequest = (function () {
    function GetEstimationQuantityExcelExportRequest(value0) {
        this.value0 = value0;
    };
    GetEstimationQuantityExcelExportRequest.create = function (value0) {
        return new GetEstimationQuantityExcelExportRequest(value0);
    };
    return GetEstimationQuantityExcelExportRequest;
})();
var GetEstimationQuantityByCostClassExcelExportRequest = (function () {
    function GetEstimationQuantityByCostClassExcelExportRequest(value0) {
        this.value0 = value0;
    };
    GetEstimationQuantityByCostClassExcelExportRequest.create = function (value0) {
        return new GetEstimationQuantityByCostClassExcelExportRequest(value0);
    };
    return GetEstimationQuantityByCostClassExcelExportRequest;
})();
var GetEstimationQuantityByLocationExcelExportRequest = (function () {
    function GetEstimationQuantityByLocationExcelExportRequest(value0) {
        this.value0 = value0;
    };
    GetEstimationQuantityByLocationExcelExportRequest.create = function (value0) {
        return new GetEstimationQuantityByLocationExcelExportRequest(value0);
    };
    return GetEstimationQuantityByLocationExcelExportRequest;
})();
var GetEstimationComponentsExcelExportRequest = (function () {
    function GetEstimationComponentsExcelExportRequest(value0) {
        this.value0 = value0;
    };
    GetEstimationComponentsExcelExportRequest.create = function (value0) {
        return new GetEstimationComponentsExcelExportRequest(value0);
    };
    return GetEstimationComponentsExcelExportRequest;
})();
var GetEstimationResourcesExcelExportRequest = (function () {
    function GetEstimationResourcesExcelExportRequest(value0) {
        this.value0 = value0;
    };
    GetEstimationResourcesExcelExportRequest.create = function (value0) {
        return new GetEstimationResourcesExcelExportRequest(value0);
    };
    return GetEstimationResourcesExcelExportRequest;
})();
var GetEstimationElementsExcelExportRequest = (function () {
    function GetEstimationElementsExcelExportRequest(value0) {
        this.value0 = value0;
    };
    GetEstimationElementsExcelExportRequest.create = function (value0) {
        return new GetEstimationElementsExcelExportRequest(value0);
    };
    return GetEstimationElementsExcelExportRequest;
})();
var GetOfferPageExcelExportRequest = (function () {
    function GetOfferPageExcelExportRequest(value0) {
        this.value0 = value0;
    };
    GetOfferPageExcelExportRequest.create = function (value0) {
        return new GetOfferPageExcelExportRequest(value0);
    };
    return GetOfferPageExcelExportRequest;
})();
var GetEstimationProjectExportRequest = (function () {
    function GetEstimationProjectExportRequest(value0) {
        this.value0 = value0;
    };
    GetEstimationProjectExportRequest.create = function (value0) {
        return new GetEstimationProjectExportRequest(value0);
    };
    return GetEstimationProjectExportRequest;
})();
var PortfolioReportExcelExportRequest = (function () {
    function PortfolioReportExcelExportRequest(value0) {
        this.value0 = value0;
    };
    PortfolioReportExcelExportRequest.create = function (value0) {
        return new PortfolioReportExcelExportRequest(value0);
    };
    return PortfolioReportExcelExportRequest;
})();
var GetOrgResourceExcelExportRequest = (function () {
    function GetOrgResourceExcelExportRequest(value0) {
        this.value0 = value0;
    };
    GetOrgResourceExcelExportRequest.create = function (value0) {
        return new GetOrgResourceExcelExportRequest(value0);
    };
    return GetOrgResourceExcelExportRequest;
})();
var GetOrgPriceListExcelExportRequest = (function () {
    function GetOrgPriceListExcelExportRequest(value0) {
        this.value0 = value0;
    };
    GetOrgPriceListExcelExportRequest.create = function (value0) {
        return new GetOrgPriceListExcelExportRequest(value0);
    };
    return GetOrgPriceListExcelExportRequest;
})();
var GetOrgResourceSubGroupingExcelExportRequest = (function () {
    function GetOrgResourceSubGroupingExcelExportRequest(value0) {
        this.value0 = value0;
    };
    GetOrgResourceSubGroupingExcelExportRequest.create = function (value0) {
        return new GetOrgResourceSubGroupingExcelExportRequest(value0);
    };
    return GetOrgResourceSubGroupingExcelExportRequest;
})();
var GetInvoiceLinesExportRequest = (function () {
    function GetInvoiceLinesExportRequest(value0) {
        this.value0 = value0;
    };
    GetInvoiceLinesExportRequest.create = function (value0) {
        return new GetInvoiceLinesExportRequest(value0);
    };
    return GetInvoiceLinesExportRequest;
})();
var GetCostGroupsExportRequest = (function () {
    function GetCostGroupsExportRequest(value0) {
        this.value0 = value0;
    };
    GetCostGroupsExportRequest.create = function (value0) {
        return new GetCostGroupsExportRequest(value0);
    };
    return GetCostGroupsExportRequest;
})();
var UpdateCostGroupMemoRequest = (function () {
    function UpdateCostGroupMemoRequest(value0) {
        this.value0 = value0;
    };
    UpdateCostGroupMemoRequest.create = function (value0) {
        return new UpdateCostGroupMemoRequest(value0);
    };
    return UpdateCostGroupMemoRequest;
})();
var UpdateCostGroupMemoSuccess = (function () {
    function UpdateCostGroupMemoSuccess(value0) {
        this.value0 = value0;
    };
    UpdateCostGroupMemoSuccess.create = function (value0) {
        return new UpdateCostGroupMemoSuccess(value0);
    };
    return UpdateCostGroupMemoSuccess;
})();
var UpdateCostGroupMemoError = (function () {
    function UpdateCostGroupMemoError(value0) {
        this.value0 = value0;
    };
    UpdateCostGroupMemoError.create = function (value0) {
        return new UpdateCostGroupMemoError(value0);
    };
    return UpdateCostGroupMemoError;
})();
var UpdateCostGroupCompletionPercentageRequest = (function () {
    function UpdateCostGroupCompletionPercentageRequest(value0) {
        this.value0 = value0;
    };
    UpdateCostGroupCompletionPercentageRequest.create = function (value0) {
        return new UpdateCostGroupCompletionPercentageRequest(value0);
    };
    return UpdateCostGroupCompletionPercentageRequest;
})();
var UpdateCostGroupCompletionPercentageSuccess = (function () {
    function UpdateCostGroupCompletionPercentageSuccess(value0) {
        this.value0 = value0;
    };
    UpdateCostGroupCompletionPercentageSuccess.create = function (value0) {
        return new UpdateCostGroupCompletionPercentageSuccess(value0);
    };
    return UpdateCostGroupCompletionPercentageSuccess;
})();
var UpdateCostGroupCompletionPercentageError = (function () {
    function UpdateCostGroupCompletionPercentageError(value0) {
        this.value0 = value0;
    };
    UpdateCostGroupCompletionPercentageError.create = function (value0) {
        return new UpdateCostGroupCompletionPercentageError(value0);
    };
    return UpdateCostGroupCompletionPercentageError;
})();
var AddAlert = (function () {
    function AddAlert(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    AddAlert.create = function (value0) {
        return function (value1) {
            return new AddAlert(value0, value1);
        };
    };
    return AddAlert;
})();
var DismissAlert = (function () {
    function DismissAlert(value0) {
        this.value0 = value0;
    };
    DismissAlert.create = function (value0) {
        return new DismissAlert(value0);
    };
    return DismissAlert;
})();
var AddIncomeItemsRequest = (function () {
    function AddIncomeItemsRequest(value0) {
        this.value0 = value0;
    };
    AddIncomeItemsRequest.create = function (value0) {
        return new AddIncomeItemsRequest(value0);
    };
    return AddIncomeItemsRequest;
})();
var AddIncomeItemsSuccess = (function () {
    function AddIncomeItemsSuccess() {

    };
    AddIncomeItemsSuccess.value = new AddIncomeItemsSuccess();
    return AddIncomeItemsSuccess;
})();
var AddIncomeItemsFailure = (function () {
    function AddIncomeItemsFailure() {

    };
    AddIncomeItemsFailure.value = new AddIncomeItemsFailure();
    return AddIncomeItemsFailure;
})();
var RemoveIncomeItemsRequest = (function () {
    function RemoveIncomeItemsRequest(value0) {
        this.value0 = value0;
    };
    RemoveIncomeItemsRequest.create = function (value0) {
        return new RemoveIncomeItemsRequest(value0);
    };
    return RemoveIncomeItemsRequest;
})();
var RemoveIncomeItemsSuccess = (function () {
    function RemoveIncomeItemsSuccess(value0) {
        this.value0 = value0;
    };
    RemoveIncomeItemsSuccess.create = function (value0) {
        return new RemoveIncomeItemsSuccess(value0);
    };
    return RemoveIncomeItemsSuccess;
})();
var RemoveIncomeItemsFailure = (function () {
    function RemoveIncomeItemsFailure() {

    };
    RemoveIncomeItemsFailure.value = new RemoveIncomeItemsFailure();
    return RemoveIncomeItemsFailure;
})();
var EditCostGroupNamesRequest = (function () {
    function EditCostGroupNamesRequest(value0) {
        this.value0 = value0;
    };
    EditCostGroupNamesRequest.create = function (value0) {
        return new EditCostGroupNamesRequest(value0);
    };
    return EditCostGroupNamesRequest;
})();
var EditCostGroupNamesSuccess = (function () {
    function EditCostGroupNamesSuccess(value0) {
        this.value0 = value0;
    };
    EditCostGroupNamesSuccess.create = function (value0) {
        return new EditCostGroupNamesSuccess(value0);
    };
    return EditCostGroupNamesSuccess;
})();
var EditCostGroupNamesFailure = (function () {
    function EditCostGroupNamesFailure() {

    };
    EditCostGroupNamesFailure.value = new EditCostGroupNamesFailure();
    return EditCostGroupNamesFailure;
})();
var UpdateIncomeItemRequest = (function () {
    function UpdateIncomeItemRequest(value0) {
        this.value0 = value0;
    };
    UpdateIncomeItemRequest.create = function (value0) {
        return new UpdateIncomeItemRequest(value0);
    };
    return UpdateIncomeItemRequest;
})();
var UpdateIncomeItemSuccess = (function () {
    function UpdateIncomeItemSuccess(value0) {
        this.value0 = value0;
    };
    UpdateIncomeItemSuccess.create = function (value0) {
        return new UpdateIncomeItemSuccess(value0);
    };
    return UpdateIncomeItemSuccess;
})();
var UpdateIncomeItemFailure = (function () {
    function UpdateIncomeItemFailure(value0) {
        this.value0 = value0;
    };
    UpdateIncomeItemFailure.create = function (value0) {
        return new UpdateIncomeItemFailure(value0);
    };
    return UpdateIncomeItemFailure;
})();
var EditMainCostGroupNamesRequest = (function () {
    function EditMainCostGroupNamesRequest(value0) {
        this.value0 = value0;
    };
    EditMainCostGroupNamesRequest.create = function (value0) {
        return new EditMainCostGroupNamesRequest(value0);
    };
    return EditMainCostGroupNamesRequest;
})();
var EditMainCostGroupNamesSuccess = (function () {
    function EditMainCostGroupNamesSuccess(value0) {
        this.value0 = value0;
    };
    EditMainCostGroupNamesSuccess.create = function (value0) {
        return new EditMainCostGroupNamesSuccess(value0);
    };
    return EditMainCostGroupNamesSuccess;
})();
var EditMainCostGroupNamesFailure = (function () {
    function EditMainCostGroupNamesFailure() {

    };
    EditMainCostGroupNamesFailure.value = new EditMainCostGroupNamesFailure();
    return EditMainCostGroupNamesFailure;
})();
var UpdateReportingResourceCostGroupCodeRequest = (function () {
    function UpdateReportingResourceCostGroupCodeRequest(value0) {
        this.value0 = value0;
    };
    UpdateReportingResourceCostGroupCodeRequest.create = function (value0) {
        return new UpdateReportingResourceCostGroupCodeRequest(value0);
    };
    return UpdateReportingResourceCostGroupCodeRequest;
})();
var UpdateReportingResourceCostGroupCodeSuccess = (function () {
    function UpdateReportingResourceCostGroupCodeSuccess(value0) {
        this.value0 = value0;
    };
    UpdateReportingResourceCostGroupCodeSuccess.create = function (value0) {
        return new UpdateReportingResourceCostGroupCodeSuccess(value0);
    };
    return UpdateReportingResourceCostGroupCodeSuccess;
})();
var UpdateReportingResourceCostGroupCodeFailure = (function () {
    function UpdateReportingResourceCostGroupCodeFailure() {

    };
    UpdateReportingResourceCostGroupCodeFailure.value = new UpdateReportingResourceCostGroupCodeFailure();
    return UpdateReportingResourceCostGroupCodeFailure;
})();
var GetMeasurementsRequest = (function () {
    function GetMeasurementsRequest(value0) {
        this.value0 = value0;
    };
    GetMeasurementsRequest.create = function (value0) {
        return new GetMeasurementsRequest(value0);
    };
    return GetMeasurementsRequest;
})();
var GetMeasurementsSuccess = (function () {
    function GetMeasurementsSuccess(value0) {
        this.value0 = value0;
    };
    GetMeasurementsSuccess.create = function (value0) {
        return new GetMeasurementsSuccess(value0);
    };
    return GetMeasurementsSuccess;
})();
var GetMeasurementsFailure = (function () {
    function GetMeasurementsFailure() {

    };
    GetMeasurementsFailure.value = new GetMeasurementsFailure();
    return GetMeasurementsFailure;
})();
var UpdateMeasurementValue = (function () {
    function UpdateMeasurementValue(value0) {
        this.value0 = value0;
    };
    UpdateMeasurementValue.create = function (value0) {
        return new UpdateMeasurementValue(value0);
    };
    return UpdateMeasurementValue;
})();
var MeasurementUnitAction = (function () {
    function MeasurementUnitAction(value0) {
        this.value0 = value0;
    };
    MeasurementUnitAction.create = function (value0) {
        return new MeasurementUnitAction(value0);
    };
    return MeasurementUnitAction;
})();
var GetServerStatusAction = (function () {
    function GetServerStatusAction() {

    };
    GetServerStatusAction.value = new GetServerStatusAction();
    return GetServerStatusAction;
})();
var GetServerStatusSuccess = (function () {
    function GetServerStatusSuccess(value0) {
        this.value0 = value0;
    };
    GetServerStatusSuccess.create = function (value0) {
        return new GetServerStatusSuccess(value0);
    };
    return GetServerStatusSuccess;
})();
var GetServerStatusFailure = (function () {
    function GetServerStatusFailure() {

    };
    GetServerStatusFailure.value = new GetServerStatusFailure();
    return GetServerStatusFailure;
})();
var SetOutageMessageAction = (function () {
    function SetOutageMessageAction(value0) {
        this.value0 = value0;
    };
    SetOutageMessageAction.create = function (value0) {
        return new SetOutageMessageAction(value0);
    };
    return SetOutageMessageAction;
})();
var SetOutageMessageSuccess = (function () {
    function SetOutageMessageSuccess() {

    };
    SetOutageMessageSuccess.value = new SetOutageMessageSuccess();
    return SetOutageMessageSuccess;
})();
var SetOutageMessageFailure = (function () {
    function SetOutageMessageFailure() {

    };
    SetOutageMessageFailure.value = new SetOutageMessageFailure();
    return SetOutageMessageFailure;
})();
var GetSubprojectsRequest = (function () {
    function GetSubprojectsRequest(value0) {
        this.value0 = value0;
    };
    GetSubprojectsRequest.create = function (value0) {
        return new GetSubprojectsRequest(value0);
    };
    return GetSubprojectsRequest;
})();
var GetSubprojectsSuccess = (function () {
    function GetSubprojectsSuccess(value0) {
        this.value0 = value0;
    };
    GetSubprojectsSuccess.create = function (value0) {
        return new GetSubprojectsSuccess(value0);
    };
    return GetSubprojectsSuccess;
})();
var GetSubprojectsFailure = (function () {
    function GetSubprojectsFailure(value0) {
        this.value0 = value0;
    };
    GetSubprojectsFailure.create = function (value0) {
        return new GetSubprojectsFailure(value0);
    };
    return GetSubprojectsFailure;
})();
var GetReportingResourcesRequest = (function () {
    function GetReportingResourcesRequest(value0) {
        this.value0 = value0;
    };
    GetReportingResourcesRequest.create = function (value0) {
        return new GetReportingResourcesRequest(value0);
    };
    return GetReportingResourcesRequest;
})();
var GetReportingResourcesSuccess = (function () {
    function GetReportingResourcesSuccess(value0) {
        this.value0 = value0;
    };
    GetReportingResourcesSuccess.create = function (value0) {
        return new GetReportingResourcesSuccess(value0);
    };
    return GetReportingResourcesSuccess;
})();
var GetReportingResourcesFailure = (function () {
    function GetReportingResourcesFailure(value0) {
        this.value0 = value0;
    };
    GetReportingResourcesFailure.create = function (value0) {
        return new GetReportingResourcesFailure(value0);
    };
    return GetReportingResourcesFailure;
})();
var GetReportingComponentsRequest = (function () {
    function GetReportingComponentsRequest(value0) {
        this.value0 = value0;
    };
    GetReportingComponentsRequest.create = function (value0) {
        return new GetReportingComponentsRequest(value0);
    };
    return GetReportingComponentsRequest;
})();
var GetReportingComponentsSuccess = (function () {
    function GetReportingComponentsSuccess(value0) {
        this.value0 = value0;
    };
    GetReportingComponentsSuccess.create = function (value0) {
        return new GetReportingComponentsSuccess(value0);
    };
    return GetReportingComponentsSuccess;
})();
var GetReportingComponentsFailure = (function () {
    function GetReportingComponentsFailure(value0) {
        this.value0 = value0;
    };
    GetReportingComponentsFailure.create = function (value0) {
        return new GetReportingComponentsFailure(value0);
    };
    return GetReportingComponentsFailure;
})();
var UpdateEstimationComponentMemoRequest = (function () {
    function UpdateEstimationComponentMemoRequest(value0) {
        this.value0 = value0;
    };
    UpdateEstimationComponentMemoRequest.create = function (value0) {
        return new UpdateEstimationComponentMemoRequest(value0);
    };
    return UpdateEstimationComponentMemoRequest;
})();
var UpdateEstimationComponentMemoSuccess = (function () {
    function UpdateEstimationComponentMemoSuccess(value0) {
        this.value0 = value0;
    };
    UpdateEstimationComponentMemoSuccess.create = function (value0) {
        return new UpdateEstimationComponentMemoSuccess(value0);
    };
    return UpdateEstimationComponentMemoSuccess;
})();
var UpdateEstimationComponentMemoError = (function () {
    function UpdateEstimationComponentMemoError(value0) {
        this.value0 = value0;
    };
    UpdateEstimationComponentMemoError.create = function (value0) {
        return new UpdateEstimationComponentMemoError(value0);
    };
    return UpdateEstimationComponentMemoError;
})();
var GetEstimationComponentsRequest = (function () {
    function GetEstimationComponentsRequest(value0) {
        this.value0 = value0;
    };
    GetEstimationComponentsRequest.create = function (value0) {
        return new GetEstimationComponentsRequest(value0);
    };
    return GetEstimationComponentsRequest;
})();
var GetEstimationComponentsSuccess = (function () {
    function GetEstimationComponentsSuccess(value0) {
        this.value0 = value0;
    };
    GetEstimationComponentsSuccess.create = function (value0) {
        return new GetEstimationComponentsSuccess(value0);
    };
    return GetEstimationComponentsSuccess;
})();
var GetEstimationComponentsFailure = (function () {
    function GetEstimationComponentsFailure(value0) {
        this.value0 = value0;
    };
    GetEstimationComponentsFailure.create = function (value0) {
        return new GetEstimationComponentsFailure(value0);
    };
    return GetEstimationComponentsFailure;
})();
var GetEstimationElementsRequest = (function () {
    function GetEstimationElementsRequest(value0) {
        this.value0 = value0;
    };
    GetEstimationElementsRequest.create = function (value0) {
        return new GetEstimationElementsRequest(value0);
    };
    return GetEstimationElementsRequest;
})();
var GetEstimationElementsSuccess = (function () {
    function GetEstimationElementsSuccess(value0) {
        this.value0 = value0;
    };
    GetEstimationElementsSuccess.create = function (value0) {
        return new GetEstimationElementsSuccess(value0);
    };
    return GetEstimationElementsSuccess;
})();
var GetEstimationElementsFailure = (function () {
    function GetEstimationElementsFailure(value0) {
        this.value0 = value0;
    };
    GetEstimationElementsFailure.create = function (value0) {
        return new GetEstimationElementsFailure(value0);
    };
    return GetEstimationElementsFailure;
})();
var GetElementMeasuresRequest = (function () {
    function GetElementMeasuresRequest(value0) {
        this.value0 = value0;
    };
    GetElementMeasuresRequest.create = function (value0) {
        return new GetElementMeasuresRequest(value0);
    };
    return GetElementMeasuresRequest;
})();
var GetElementMeasuresSuccess = (function () {
    function GetElementMeasuresSuccess(value0) {
        this.value0 = value0;
    };
    GetElementMeasuresSuccess.create = function (value0) {
        return new GetElementMeasuresSuccess(value0);
    };
    return GetElementMeasuresSuccess;
})();
var GetElementMeasuresFailure = (function () {
    function GetElementMeasuresFailure(value0) {
        this.value0 = value0;
    };
    GetElementMeasuresFailure.create = function (value0) {
        return new GetElementMeasuresFailure(value0);
    };
    return GetElementMeasuresFailure;
})();
var GetReferenceElementMeasuresRequest = (function () {
    function GetReferenceElementMeasuresRequest(value0) {
        this.value0 = value0;
    };
    GetReferenceElementMeasuresRequest.create = function (value0) {
        return new GetReferenceElementMeasuresRequest(value0);
    };
    return GetReferenceElementMeasuresRequest;
})();
var GetReferenceElementMeasuresSuccess = (function () {
    function GetReferenceElementMeasuresSuccess(value0) {
        this.value0 = value0;
    };
    GetReferenceElementMeasuresSuccess.create = function (value0) {
        return new GetReferenceElementMeasuresSuccess(value0);
    };
    return GetReferenceElementMeasuresSuccess;
})();
var GetReferenceElementMeasuresFailure = (function () {
    function GetReferenceElementMeasuresFailure(value0) {
        this.value0 = value0;
    };
    GetReferenceElementMeasuresFailure.create = function (value0) {
        return new GetReferenceElementMeasuresFailure(value0);
    };
    return GetReferenceElementMeasuresFailure;
})();
var GetElementLocationsRequest = (function () {
    function GetElementLocationsRequest(value0) {
        this.value0 = value0;
    };
    GetElementLocationsRequest.create = function (value0) {
        return new GetElementLocationsRequest(value0);
    };
    return GetElementLocationsRequest;
})();
var GetElementLocationsSuccess = (function () {
    function GetElementLocationsSuccess(value0) {
        this.value0 = value0;
    };
    GetElementLocationsSuccess.create = function (value0) {
        return new GetElementLocationsSuccess(value0);
    };
    return GetElementLocationsSuccess;
})();
var GetElementLocationsFailure = (function () {
    function GetElementLocationsFailure(value0) {
        this.value0 = value0;
    };
    GetElementLocationsFailure.create = function (value0) {
        return new GetElementLocationsFailure(value0);
    };
    return GetElementLocationsFailure;
})();
var GetElementSpecsRequest = (function () {
    function GetElementSpecsRequest(value0) {
        this.value0 = value0;
    };
    GetElementSpecsRequest.create = function (value0) {
        return new GetElementSpecsRequest(value0);
    };
    return GetElementSpecsRequest;
})();
var GetElementSpecsSuccess = (function () {
    function GetElementSpecsSuccess(value0) {
        this.value0 = value0;
    };
    GetElementSpecsSuccess.create = function (value0) {
        return new GetElementSpecsSuccess(value0);
    };
    return GetElementSpecsSuccess;
})();
var GetElementSpecsFailure = (function () {
    function GetElementSpecsFailure(value0) {
        this.value0 = value0;
    };
    GetElementSpecsFailure.create = function (value0) {
        return new GetElementSpecsFailure(value0);
    };
    return GetElementSpecsFailure;
})();
var GetReferenceEstimationComponentsRequest = (function () {
    function GetReferenceEstimationComponentsRequest(value0) {
        this.value0 = value0;
    };
    GetReferenceEstimationComponentsRequest.create = function (value0) {
        return new GetReferenceEstimationComponentsRequest(value0);
    };
    return GetReferenceEstimationComponentsRequest;
})();
var GetReferenceEstimationComponentsSuccess = (function () {
    function GetReferenceEstimationComponentsSuccess(value0) {
        this.value0 = value0;
    };
    GetReferenceEstimationComponentsSuccess.create = function (value0) {
        return new GetReferenceEstimationComponentsSuccess(value0);
    };
    return GetReferenceEstimationComponentsSuccess;
})();
var GetReferenceEstimationComponentsFailure = (function () {
    function GetReferenceEstimationComponentsFailure(value0) {
        this.value0 = value0;
    };
    GetReferenceEstimationComponentsFailure.create = function (value0) {
        return new GetReferenceEstimationComponentsFailure(value0);
    };
    return GetReferenceEstimationComponentsFailure;
})();
var GetReferenceEstimationElementsRequest = (function () {
    function GetReferenceEstimationElementsRequest(value0) {
        this.value0 = value0;
    };
    GetReferenceEstimationElementsRequest.create = function (value0) {
        return new GetReferenceEstimationElementsRequest(value0);
    };
    return GetReferenceEstimationElementsRequest;
})();
var GetReferenceEstimationElementsSuccess = (function () {
    function GetReferenceEstimationElementsSuccess(value0) {
        this.value0 = value0;
    };
    GetReferenceEstimationElementsSuccess.create = function (value0) {
        return new GetReferenceEstimationElementsSuccess(value0);
    };
    return GetReferenceEstimationElementsSuccess;
})();
var GetReferenceEstimationElementsFailure = (function () {
    function GetReferenceEstimationElementsFailure(value0) {
        this.value0 = value0;
    };
    GetReferenceEstimationElementsFailure.create = function (value0) {
        return new GetReferenceEstimationElementsFailure(value0);
    };
    return GetReferenceEstimationElementsFailure;
})();
var GetReferenceElementLocationsRequest = (function () {
    function GetReferenceElementLocationsRequest(value0) {
        this.value0 = value0;
    };
    GetReferenceElementLocationsRequest.create = function (value0) {
        return new GetReferenceElementLocationsRequest(value0);
    };
    return GetReferenceElementLocationsRequest;
})();
var GetReferenceElementLocationsSuccess = (function () {
    function GetReferenceElementLocationsSuccess(value0) {
        this.value0 = value0;
    };
    GetReferenceElementLocationsSuccess.create = function (value0) {
        return new GetReferenceElementLocationsSuccess(value0);
    };
    return GetReferenceElementLocationsSuccess;
})();
var GetReferenceElementLocationsFailure = (function () {
    function GetReferenceElementLocationsFailure(value0) {
        this.value0 = value0;
    };
    GetReferenceElementLocationsFailure.create = function (value0) {
        return new GetReferenceElementLocationsFailure(value0);
    };
    return GetReferenceElementLocationsFailure;
})();
var GetReferenceElementSpecsRequest = (function () {
    function GetReferenceElementSpecsRequest(value0) {
        this.value0 = value0;
    };
    GetReferenceElementSpecsRequest.create = function (value0) {
        return new GetReferenceElementSpecsRequest(value0);
    };
    return GetReferenceElementSpecsRequest;
})();
var GetReferenceElementSpecsSuccess = (function () {
    function GetReferenceElementSpecsSuccess(value0) {
        this.value0 = value0;
    };
    GetReferenceElementSpecsSuccess.create = function (value0) {
        return new GetReferenceElementSpecsSuccess(value0);
    };
    return GetReferenceElementSpecsSuccess;
})();
var GetReferenceElementSpecsFailure = (function () {
    function GetReferenceElementSpecsFailure(value0) {
        this.value0 = value0;
    };
    GetReferenceElementSpecsFailure.create = function (value0) {
        return new GetReferenceElementSpecsFailure(value0);
    };
    return GetReferenceElementSpecsFailure;
})();
var GetEstimationResourcesRequest = (function () {
    function GetEstimationResourcesRequest(value0) {
        this.value0 = value0;
    };
    GetEstimationResourcesRequest.create = function (value0) {
        return new GetEstimationResourcesRequest(value0);
    };
    return GetEstimationResourcesRequest;
})();
var GetEstimationResourcesSuccess = (function () {
    function GetEstimationResourcesSuccess(value0) {
        this.value0 = value0;
    };
    GetEstimationResourcesSuccess.create = function (value0) {
        return new GetEstimationResourcesSuccess(value0);
    };
    return GetEstimationResourcesSuccess;
})();
var GetEstimationResourcesFailure = (function () {
    function GetEstimationResourcesFailure(value0) {
        this.value0 = value0;
    };
    GetEstimationResourcesFailure.create = function (value0) {
        return new GetEstimationResourcesFailure(value0);
    };
    return GetEstimationResourcesFailure;
})();
var GetEstimationResourcePriceListRequest = (function () {
    function GetEstimationResourcePriceListRequest(value0) {
        this.value0 = value0;
    };
    GetEstimationResourcePriceListRequest.create = function (value0) {
        return new GetEstimationResourcePriceListRequest(value0);
    };
    return GetEstimationResourcePriceListRequest;
})();
var GetEstimationResourcePriceListSuccess = (function () {
    function GetEstimationResourcePriceListSuccess(value0) {
        this.value0 = value0;
    };
    GetEstimationResourcePriceListSuccess.create = function (value0) {
        return new GetEstimationResourcePriceListSuccess(value0);
    };
    return GetEstimationResourcePriceListSuccess;
})();
var GetEstimationResourcePriceListError = (function () {
    function GetEstimationResourcePriceListError(value0) {
        this.value0 = value0;
    };
    GetEstimationResourcePriceListError.create = function (value0) {
        return new GetEstimationResourcePriceListError(value0);
    };
    return GetEstimationResourcePriceListError;
})();
var GetOfferPageParamsRequest = (function () {
    function GetOfferPageParamsRequest(value0) {
        this.value0 = value0;
    };
    GetOfferPageParamsRequest.create = function (value0) {
        return new GetOfferPageParamsRequest(value0);
    };
    return GetOfferPageParamsRequest;
})();
var GetOfferPageParamsSuccess = (function () {
    function GetOfferPageParamsSuccess(value0) {
        this.value0 = value0;
    };
    GetOfferPageParamsSuccess.create = function (value0) {
        return new GetOfferPageParamsSuccess(value0);
    };
    return GetOfferPageParamsSuccess;
})();
var GetOfferPageParamsFailure = (function () {
    function GetOfferPageParamsFailure(value0) {
        this.value0 = value0;
    };
    GetOfferPageParamsFailure.create = function (value0) {
        return new GetOfferPageParamsFailure(value0);
    };
    return GetOfferPageParamsFailure;
})();
var SetOfferPageParamsRequest = (function () {
    function SetOfferPageParamsRequest(value0) {
        this.value0 = value0;
    };
    SetOfferPageParamsRequest.create = function (value0) {
        return new SetOfferPageParamsRequest(value0);
    };
    return SetOfferPageParamsRequest;
})();
var SetOfferPageParamsSuccess = (function () {
    function SetOfferPageParamsSuccess(value0) {
        this.value0 = value0;
    };
    SetOfferPageParamsSuccess.create = function (value0) {
        return new SetOfferPageParamsSuccess(value0);
    };
    return SetOfferPageParamsSuccess;
})();
var SetOfferPageParamsFailure = (function () {
    function SetOfferPageParamsFailure(value0) {
        this.value0 = value0;
    };
    SetOfferPageParamsFailure.create = function (value0) {
        return new SetOfferPageParamsFailure(value0);
    };
    return SetOfferPageParamsFailure;
})();
var GetPlanningCostGroupsRequest = (function () {
    function GetPlanningCostGroupsRequest(value0) {
        this.value0 = value0;
    };
    GetPlanningCostGroupsRequest.create = function (value0) {
        return new GetPlanningCostGroupsRequest(value0);
    };
    return GetPlanningCostGroupsRequest;
})();
var GetPlanningCostGroupsSuccess = (function () {
    function GetPlanningCostGroupsSuccess(value0) {
        this.value0 = value0;
    };
    GetPlanningCostGroupsSuccess.create = function (value0) {
        return new GetPlanningCostGroupsSuccess(value0);
    };
    return GetPlanningCostGroupsSuccess;
})();
var GetPlanningCostGroupsFailure = (function () {
    function GetPlanningCostGroupsFailure(value0) {
        this.value0 = value0;
    };
    GetPlanningCostGroupsFailure.create = function (value0) {
        return new GetPlanningCostGroupsFailure(value0);
    };
    return GetPlanningCostGroupsFailure;
})();
var PlanningActionRequest = (function () {
    function PlanningActionRequest(value0) {
        this.value0 = value0;
    };
    PlanningActionRequest.create = function (value0) {
        return new PlanningActionRequest(value0);
    };
    return PlanningActionRequest;
})();
var PlanningActionSuccess = (function () {
    function PlanningActionSuccess(value0) {
        this.value0 = value0;
    };
    PlanningActionSuccess.create = function (value0) {
        return new PlanningActionSuccess(value0);
    };
    return PlanningActionSuccess;
})();
var PlanningActionFailure = (function () {
    function PlanningActionFailure(value0) {
        this.value0 = value0;
    };
    PlanningActionFailure.create = function (value0) {
        return new PlanningActionFailure(value0);
    };
    return PlanningActionFailure;
})();
var ConvertToTopicComponentRequest = (function () {
    function ConvertToTopicComponentRequest(value0) {
        this.value0 = value0;
    };
    ConvertToTopicComponentRequest.create = function (value0) {
        return new ConvertToTopicComponentRequest(value0);
    };
    return ConvertToTopicComponentRequest;
})();
var ConvertToTopicComponentSuccess = (function () {
    function ConvertToTopicComponentSuccess(value0) {
        this.value0 = value0;
    };
    ConvertToTopicComponentSuccess.create = function (value0) {
        return new ConvertToTopicComponentSuccess(value0);
    };
    return ConvertToTopicComponentSuccess;
})();
var ConvertToTopicComponentFailure = (function () {
    function ConvertToTopicComponentFailure(value0) {
        this.value0 = value0;
    };
    ConvertToTopicComponentFailure.create = function (value0) {
        return new ConvertToTopicComponentFailure(value0);
    };
    return ConvertToTopicComponentFailure;
})();
var ConvertToRegularComponentRequest = (function () {
    function ConvertToRegularComponentRequest(value0) {
        this.value0 = value0;
    };
    ConvertToRegularComponentRequest.create = function (value0) {
        return new ConvertToRegularComponentRequest(value0);
    };
    return ConvertToRegularComponentRequest;
})();
var ConvertToRegularComponentSuccess = (function () {
    function ConvertToRegularComponentSuccess(value0) {
        this.value0 = value0;
    };
    ConvertToRegularComponentSuccess.create = function (value0) {
        return new ConvertToRegularComponentSuccess(value0);
    };
    return ConvertToRegularComponentSuccess;
})();
var ConvertToRegularComponentFailure = (function () {
    function ConvertToRegularComponentFailure(value0) {
        this.value0 = value0;
    };
    ConvertToRegularComponentFailure.create = function (value0) {
        return new ConvertToRegularComponentFailure(value0);
    };
    return ConvertToRegularComponentFailure;
})();
var GetOrgPriceListTreeRequest = (function () {
    function GetOrgPriceListTreeRequest() {

    };
    GetOrgPriceListTreeRequest.value = new GetOrgPriceListTreeRequest();
    return GetOrgPriceListTreeRequest;
})();
var GetOrgPriceListTreeSuccess = (function () {
    function GetOrgPriceListTreeSuccess(value0) {
        this.value0 = value0;
    };
    GetOrgPriceListTreeSuccess.create = function (value0) {
        return new GetOrgPriceListTreeSuccess(value0);
    };
    return GetOrgPriceListTreeSuccess;
})();
var GetOrgPriceListTreeError = (function () {
    function GetOrgPriceListTreeError() {

    };
    GetOrgPriceListTreeError.value = new GetOrgPriceListTreeError();
    return GetOrgPriceListTreeError;
})();
var GetOrgPriceLists = (function () {
    function GetOrgPriceLists() {

    };
    GetOrgPriceLists.value = new GetOrgPriceLists();
    return GetOrgPriceLists;
})();
var GetOrgPriceListsSuccess = (function () {
    function GetOrgPriceListsSuccess(value0) {
        this.value0 = value0;
    };
    GetOrgPriceListsSuccess.create = function (value0) {
        return new GetOrgPriceListsSuccess(value0);
    };
    return GetOrgPriceListsSuccess;
})();
var GetOrgPriceListsError = (function () {
    function GetOrgPriceListsError() {

    };
    GetOrgPriceListsError.value = new GetOrgPriceListsError();
    return GetOrgPriceListsError;
})();
var GetProjectCostGroupClassificationInfoRequest = (function () {
    function GetProjectCostGroupClassificationInfoRequest(value0) {
        this.value0 = value0;
    };
    GetProjectCostGroupClassificationInfoRequest.create = function (value0) {
        return new GetProjectCostGroupClassificationInfoRequest(value0);
    };
    return GetProjectCostGroupClassificationInfoRequest;
})();
var GetProjectCostGroupClassificationInfoSuccess = (function () {
    function GetProjectCostGroupClassificationInfoSuccess(value0) {
        this.value0 = value0;
    };
    GetProjectCostGroupClassificationInfoSuccess.create = function (value0) {
        return new GetProjectCostGroupClassificationInfoSuccess(value0);
    };
    return GetProjectCostGroupClassificationInfoSuccess;
})();
var GetProjectCostGroupClassificationInfoError = (function () {
    function GetProjectCostGroupClassificationInfoError() {

    };
    GetProjectCostGroupClassificationInfoError.value = new GetProjectCostGroupClassificationInfoError();
    return GetProjectCostGroupClassificationInfoError;
})();
var GetReferenceEstimationResourcesRequest = (function () {
    function GetReferenceEstimationResourcesRequest(value0) {
        this.value0 = value0;
    };
    GetReferenceEstimationResourcesRequest.create = function (value0) {
        return new GetReferenceEstimationResourcesRequest(value0);
    };
    return GetReferenceEstimationResourcesRequest;
})();
var GetReferenceEstimationResourcesSuccess = (function () {
    function GetReferenceEstimationResourcesSuccess(value0) {
        this.value0 = value0;
    };
    GetReferenceEstimationResourcesSuccess.create = function (value0) {
        return new GetReferenceEstimationResourcesSuccess(value0);
    };
    return GetReferenceEstimationResourcesSuccess;
})();
var GetReferenceEstimationResourcesFailure = (function () {
    function GetReferenceEstimationResourcesFailure(value0) {
        this.value0 = value0;
    };
    GetReferenceEstimationResourcesFailure.create = function (value0) {
        return new GetReferenceEstimationResourcesFailure(value0);
    };
    return GetReferenceEstimationResourcesFailure;
})();
var GetEstimationLocationsRequest = (function () {
    function GetEstimationLocationsRequest(value0) {
        this.value0 = value0;
    };
    GetEstimationLocationsRequest.create = function (value0) {
        return new GetEstimationLocationsRequest(value0);
    };
    return GetEstimationLocationsRequest;
})();
var GetEstimationLocationsSuccess = (function () {
    function GetEstimationLocationsSuccess(value0) {
        this.value0 = value0;
    };
    GetEstimationLocationsSuccess.create = function (value0) {
        return new GetEstimationLocationsSuccess(value0);
    };
    return GetEstimationLocationsSuccess;
})();
var GetEstimationLocationsFailure = (function () {
    function GetEstimationLocationsFailure(value0) {
        this.value0 = value0;
    };
    GetEstimationLocationsFailure.create = function (value0) {
        return new GetEstimationLocationsFailure(value0);
    };
    return GetEstimationLocationsFailure;
})();
var EstimationLocationActionRequest = (function () {
    function EstimationLocationActionRequest(value0) {
        this.value0 = value0;
    };
    EstimationLocationActionRequest.create = function (value0) {
        return new EstimationLocationActionRequest(value0);
    };
    return EstimationLocationActionRequest;
})();
var EstimationLocationActionSuccess = (function () {
    function EstimationLocationActionSuccess(value0) {
        this.value0 = value0;
    };
    EstimationLocationActionSuccess.create = function (value0) {
        return new EstimationLocationActionSuccess(value0);
    };
    return EstimationLocationActionSuccess;
})();
var EstimationLocationActionFailure = (function () {
    function EstimationLocationActionFailure(value0) {
        this.value0 = value0;
    };
    EstimationLocationActionFailure.create = function (value0) {
        return new EstimationLocationActionFailure(value0);
    };
    return EstimationLocationActionFailure;
})();
var EstimationResourcePriceListActionRequest = (function () {
    function EstimationResourcePriceListActionRequest(value0) {
        this.value0 = value0;
    };
    EstimationResourcePriceListActionRequest.create = function (value0) {
        return new EstimationResourcePriceListActionRequest(value0);
    };
    return EstimationResourcePriceListActionRequest;
})();
var EstimationResourcePriceListActionSuccess = (function () {
    function EstimationResourcePriceListActionSuccess(value0) {
        this.value0 = value0;
    };
    EstimationResourcePriceListActionSuccess.create = function (value0) {
        return new EstimationResourcePriceListActionSuccess(value0);
    };
    return EstimationResourcePriceListActionSuccess;
})();
var EstimationResourcePriceListActionFailure = (function () {
    function EstimationResourcePriceListActionFailure(value0) {
        this.value0 = value0;
    };
    EstimationResourcePriceListActionFailure.create = function (value0) {
        return new EstimationResourcePriceListActionFailure(value0);
    };
    return EstimationResourcePriceListActionFailure;
})();
var SetEstimationComponentCostClassPriceRequest = (function () {
    function SetEstimationComponentCostClassPriceRequest(value0) {
        this.value0 = value0;
    };
    SetEstimationComponentCostClassPriceRequest.create = function (value0) {
        return new SetEstimationComponentCostClassPriceRequest(value0);
    };
    return SetEstimationComponentCostClassPriceRequest;
})();
var SetEstimationComponentCostClassPriceSuccess = (function () {
    function SetEstimationComponentCostClassPriceSuccess(value0) {
        this.value0 = value0;
    };
    SetEstimationComponentCostClassPriceSuccess.create = function (value0) {
        return new SetEstimationComponentCostClassPriceSuccess(value0);
    };
    return SetEstimationComponentCostClassPriceSuccess;
})();
var SetEstimationComponentCostClassPriceFailure = (function () {
    function SetEstimationComponentCostClassPriceFailure() {

    };
    SetEstimationComponentCostClassPriceFailure.value = new SetEstimationComponentCostClassPriceFailure();
    return SetEstimationComponentCostClassPriceFailure;
})();
var GetUserOrganizationsRequest = (function () {
    function GetUserOrganizationsRequest() {

    };
    GetUserOrganizationsRequest.value = new GetUserOrganizationsRequest();
    return GetUserOrganizationsRequest;
})();
var GetUserOrganizationsSuccess = (function () {
    function GetUserOrganizationsSuccess(value0) {
        this.value0 = value0;
    };
    GetUserOrganizationsSuccess.create = function (value0) {
        return new GetUserOrganizationsSuccess(value0);
    };
    return GetUserOrganizationsSuccess;
})();
var GetUserOrganizationsFailure = (function () {
    function GetUserOrganizationsFailure() {

    };
    GetUserOrganizationsFailure.value = new GetUserOrganizationsFailure();
    return GetUserOrganizationsFailure;
})();
var GetUserAppAccessRightsRequest = (function () {
    function GetUserAppAccessRightsRequest(value0) {
        this.value0 = value0;
    };
    GetUserAppAccessRightsRequest.create = function (value0) {
        return new GetUserAppAccessRightsRequest(value0);
    };
    return GetUserAppAccessRightsRequest;
})();
var GetUserAppAccessRightsSuccess = (function () {
    function GetUserAppAccessRightsSuccess(value0) {
        this.value0 = value0;
    };
    GetUserAppAccessRightsSuccess.create = function (value0) {
        return new GetUserAppAccessRightsSuccess(value0);
    };
    return GetUserAppAccessRightsSuccess;
})();
var GetUserAppAccessRightsFailure = (function () {
    function GetUserAppAccessRightsFailure() {

    };
    GetUserAppAccessRightsFailure.value = new GetUserAppAccessRightsFailure();
    return GetUserAppAccessRightsFailure;
})();
var GetAllOrganizationsRequest = (function () {
    function GetAllOrganizationsRequest() {

    };
    GetAllOrganizationsRequest.value = new GetAllOrganizationsRequest();
    return GetAllOrganizationsRequest;
})();
var GetAllOrganizationsSuccess = (function () {
    function GetAllOrganizationsSuccess(value0) {
        this.value0 = value0;
    };
    GetAllOrganizationsSuccess.create = function (value0) {
        return new GetAllOrganizationsSuccess(value0);
    };
    return GetAllOrganizationsSuccess;
})();
var GetAllOrganizationsFailure = (function () {
    function GetAllOrganizationsFailure() {

    };
    GetAllOrganizationsFailure.value = new GetAllOrganizationsFailure();
    return GetAllOrganizationsFailure;
})();
var GetAllOrganizationConfigurationsRequest = (function () {
    function GetAllOrganizationConfigurationsRequest() {

    };
    GetAllOrganizationConfigurationsRequest.value = new GetAllOrganizationConfigurationsRequest();
    return GetAllOrganizationConfigurationsRequest;
})();
var GetAllOrganizationConfigurationsSuccess = (function () {
    function GetAllOrganizationConfigurationsSuccess(value0) {
        this.value0 = value0;
    };
    GetAllOrganizationConfigurationsSuccess.create = function (value0) {
        return new GetAllOrganizationConfigurationsSuccess(value0);
    };
    return GetAllOrganizationConfigurationsSuccess;
})();
var GetAllOrganizationConfigurationsFailure = (function () {
    function GetAllOrganizationConfigurationsFailure() {

    };
    GetAllOrganizationConfigurationsFailure.value = new GetAllOrganizationConfigurationsFailure();
    return GetAllOrganizationConfigurationsFailure;
})();
var SetOrganizationConfigurationRequest = (function () {
    function SetOrganizationConfigurationRequest(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    SetOrganizationConfigurationRequest.create = function (value0) {
        return function (value1) {
            return new SetOrganizationConfigurationRequest(value0, value1);
        };
    };
    return SetOrganizationConfigurationRequest;
})();
var SetOrganizationConfigurationSuccess = (function () {
    function SetOrganizationConfigurationSuccess() {

    };
    SetOrganizationConfigurationSuccess.value = new SetOrganizationConfigurationSuccess();
    return SetOrganizationConfigurationSuccess;
})();
var SetOrganizationConfigurationFailure = (function () {
    function SetOrganizationConfigurationFailure() {

    };
    SetOrganizationConfigurationFailure.value = new SetOrganizationConfigurationFailure();
    return SetOrganizationConfigurationFailure;
})();
var ChangeOrganizationsRequest = (function () {
    function ChangeOrganizationsRequest(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    ChangeOrganizationsRequest.create = function (value0) {
        return function (value1) {
            return new ChangeOrganizationsRequest(value0, value1);
        };
    };
    return ChangeOrganizationsRequest;
})();
var GetCurrentOrganizationRequest = (function () {
    function GetCurrentOrganizationRequest() {

    };
    GetCurrentOrganizationRequest.value = new GetCurrentOrganizationRequest();
    return GetCurrentOrganizationRequest;
})();
var GetCurrentOrganizationSuccess = (function () {
    function GetCurrentOrganizationSuccess(value0) {
        this.value0 = value0;
    };
    GetCurrentOrganizationSuccess.create = function (value0) {
        return new GetCurrentOrganizationSuccess(value0);
    };
    return GetCurrentOrganizationSuccess;
})();
var GetCurrentOrganizationFailure = (function () {
    function GetCurrentOrganizationFailure(value0) {
        this.value0 = value0;
    };
    GetCurrentOrganizationFailure.create = function (value0) {
        return new GetCurrentOrganizationFailure(value0);
    };
    return GetCurrentOrganizationFailure;
})();
var GetOrganizationAdminInfoRequest = (function () {
    function GetOrganizationAdminInfoRequest() {

    };
    GetOrganizationAdminInfoRequest.value = new GetOrganizationAdminInfoRequest();
    return GetOrganizationAdminInfoRequest;
})();
var GetOrganizationAdminInfoSuccess = (function () {
    function GetOrganizationAdminInfoSuccess(value0) {
        this.value0 = value0;
    };
    GetOrganizationAdminInfoSuccess.create = function (value0) {
        return new GetOrganizationAdminInfoSuccess(value0);
    };
    return GetOrganizationAdminInfoSuccess;
})();
var GetOrganizationAdminInfoFailure = (function () {
    function GetOrganizationAdminInfoFailure() {

    };
    GetOrganizationAdminInfoFailure.value = new GetOrganizationAdminInfoFailure();
    return GetOrganizationAdminInfoFailure;
})();
var GetCostControlExcludeActualCostSocialExpensesRequest = (function () {
    function GetCostControlExcludeActualCostSocialExpensesRequest() {

    };
    GetCostControlExcludeActualCostSocialExpensesRequest.value = new GetCostControlExcludeActualCostSocialExpensesRequest();
    return GetCostControlExcludeActualCostSocialExpensesRequest;
})();
var GetCostControlExcludeActualCostSocialExpensesSuccess = (function () {
    function GetCostControlExcludeActualCostSocialExpensesSuccess(value0) {
        this.value0 = value0;
    };
    GetCostControlExcludeActualCostSocialExpensesSuccess.create = function (value0) {
        return new GetCostControlExcludeActualCostSocialExpensesSuccess(value0);
    };
    return GetCostControlExcludeActualCostSocialExpensesSuccess;
})();
var GetCostControlExcludeActualCostSocialExpensesFailure = (function () {
    function GetCostControlExcludeActualCostSocialExpensesFailure() {

    };
    GetCostControlExcludeActualCostSocialExpensesFailure.value = new GetCostControlExcludeActualCostSocialExpensesFailure();
    return GetCostControlExcludeActualCostSocialExpensesFailure;
})();
var SetCostControlExcludeActualCostSocialExpenses = (function () {
    function SetCostControlExcludeActualCostSocialExpenses(value0) {
        this.value0 = value0;
    };
    SetCostControlExcludeActualCostSocialExpenses.create = function (value0) {
        return new SetCostControlExcludeActualCostSocialExpenses(value0);
    };
    return SetCostControlExcludeActualCostSocialExpenses;
})();
var GetLogsRequest = (function () {
    function GetLogsRequest() {

    };
    GetLogsRequest.value = new GetLogsRequest();
    return GetLogsRequest;
})();
var GetLogsSuccess = (function () {
    function GetLogsSuccess(value0) {
        this.value0 = value0;
    };
    GetLogsSuccess.create = function (value0) {
        return new GetLogsSuccess(value0);
    };
    return GetLogsSuccess;
})();
var GetLogsFailure = (function () {
    function GetLogsFailure() {

    };
    GetLogsFailure.value = new GetLogsFailure();
    return GetLogsFailure;
})();
var GetImportInvoicesRequest = (function () {
    function GetImportInvoicesRequest() {

    };
    GetImportInvoicesRequest.value = new GetImportInvoicesRequest();
    return GetImportInvoicesRequest;
})();
var GetImportInvoicesSuccess = (function () {
    function GetImportInvoicesSuccess(value0) {
        this.value0 = value0;
    };
    GetImportInvoicesSuccess.create = function (value0) {
        return new GetImportInvoicesSuccess(value0);
    };
    return GetImportInvoicesSuccess;
})();
var GetImportInvoicesError = (function () {
    function GetImportInvoicesError() {

    };
    GetImportInvoicesError.value = new GetImportInvoicesError();
    return GetImportInvoicesError;
})();
var GetImportSalesInvoicesRequest = (function () {
    function GetImportSalesInvoicesRequest() {

    };
    GetImportSalesInvoicesRequest.value = new GetImportSalesInvoicesRequest();
    return GetImportSalesInvoicesRequest;
})();
var GetImportSalesInvoicesSuccess = (function () {
    function GetImportSalesInvoicesSuccess(value0) {
        this.value0 = value0;
    };
    GetImportSalesInvoicesSuccess.create = function (value0) {
        return new GetImportSalesInvoicesSuccess(value0);
    };
    return GetImportSalesInvoicesSuccess;
})();
var GetImportSalesInvoicesError = (function () {
    function GetImportSalesInvoicesError() {

    };
    GetImportSalesInvoicesError.value = new GetImportSalesInvoicesError();
    return GetImportSalesInvoicesError;
})();
var AllocateToProjectRequest = (function () {
    function AllocateToProjectRequest(value0) {
        this.value0 = value0;
    };
    AllocateToProjectRequest.create = function (value0) {
        return new AllocateToProjectRequest(value0);
    };
    return AllocateToProjectRequest;
})();
var FetchDataFromIntegrationRequest = (function () {
    function FetchDataFromIntegrationRequest(value0) {
        this.value0 = value0;
    };
    FetchDataFromIntegrationRequest.create = function (value0) {
        return new FetchDataFromIntegrationRequest(value0);
    };
    return FetchDataFromIntegrationRequest;
})();
var FetchDataFromIntegrationError = (function () {
    function FetchDataFromIntegrationError() {

    };
    FetchDataFromIntegrationError.value = new FetchDataFromIntegrationError();
    return FetchDataFromIntegrationError;
})();
var MigrateSelectedInvoicesRequest = (function () {
    function MigrateSelectedInvoicesRequest(value0) {
        this.value0 = value0;
    };
    MigrateSelectedInvoicesRequest.create = function (value0) {
        return new MigrateSelectedInvoicesRequest(value0);
    };
    return MigrateSelectedInvoicesRequest;
})();
var MigrateSelectedInvoicesSuccess = (function () {
    function MigrateSelectedInvoicesSuccess() {

    };
    MigrateSelectedInvoicesSuccess.value = new MigrateSelectedInvoicesSuccess();
    return MigrateSelectedInvoicesSuccess;
})();
var MigrateSelectedInvoicesError = (function () {
    function MigrateSelectedInvoicesError() {

    };
    MigrateSelectedInvoicesError.value = new MigrateSelectedInvoicesError();
    return MigrateSelectedInvoicesError;
})();
var AddUsersRequest = (function () {
    function AddUsersRequest(value0) {
        this.value0 = value0;
    };
    AddUsersRequest.create = function (value0) {
        return new AddUsersRequest(value0);
    };
    return AddUsersRequest;
})();
var AddUsersSuccess = (function () {
    function AddUsersSuccess() {

    };
    AddUsersSuccess.value = new AddUsersSuccess();
    return AddUsersSuccess;
})();
var AddUsersError = (function () {
    function AddUsersError() {

    };
    AddUsersError.value = new AddUsersError();
    return AddUsersError;
})();
var SetUserSSOLogin = (function () {
    function SetUserSSOLogin(value0) {
        this.value0 = value0;
    };
    SetUserSSOLogin.create = function (value0) {
        return new SetUserSSOLogin(value0);
    };
    return SetUserSSOLogin;
})();
var OrgAddUsersRequest = (function () {
    function OrgAddUsersRequest(value0) {
        this.value0 = value0;
    };
    OrgAddUsersRequest.create = function (value0) {
        return new OrgAddUsersRequest(value0);
    };
    return OrgAddUsersRequest;
})();
var OrgAddUsersSuccess = (function () {
    function OrgAddUsersSuccess() {

    };
    OrgAddUsersSuccess.value = new OrgAddUsersSuccess();
    return OrgAddUsersSuccess;
})();
var OrgAddUsersError = (function () {
    function OrgAddUsersError() {

    };
    OrgAddUsersError.value = new OrgAddUsersError();
    return OrgAddUsersError;
})();
var SetUserOrganizationsRequest = (function () {
    function SetUserOrganizationsRequest(value0) {
        this.value0 = value0;
    };
    SetUserOrganizationsRequest.create = function (value0) {
        return new SetUserOrganizationsRequest(value0);
    };
    return SetUserOrganizationsRequest;
})();
var SetUserRolesRequest = (function () {
    function SetUserRolesRequest(value0) {
        this.value0 = value0;
    };
    SetUserRolesRequest.create = function (value0) {
        return new SetUserRolesRequest(value0);
    };
    return SetUserRolesRequest;
})();
var SetUserRolesSuccess = (function () {
    function SetUserRolesSuccess() {

    };
    SetUserRolesSuccess.value = new SetUserRolesSuccess();
    return SetUserRolesSuccess;
})();
var SetUserRolesError = (function () {
    function SetUserRolesError() {

    };
    SetUserRolesError.value = new SetUserRolesError();
    return SetUserRolesError;
})();
var GetSuperAdminUsersRequest = (function () {
    function GetSuperAdminUsersRequest() {

    };
    GetSuperAdminUsersRequest.value = new GetSuperAdminUsersRequest();
    return GetSuperAdminUsersRequest;
})();
var GetSuperAdminUsersSuccess = (function () {
    function GetSuperAdminUsersSuccess(value0) {
        this.value0 = value0;
    };
    GetSuperAdminUsersSuccess.create = function (value0) {
        return new GetSuperAdminUsersSuccess(value0);
    };
    return GetSuperAdminUsersSuccess;
})();
var GetSuperAdminUsersError = (function () {
    function GetSuperAdminUsersError() {

    };
    GetSuperAdminUsersError.value = new GetSuperAdminUsersError();
    return GetSuperAdminUsersError;
})();
var SetSuperadmin = (function () {
    function SetSuperadmin(value0) {
        this.value0 = value0;
    };
    SetSuperadmin.create = function (value0) {
        return new SetSuperadmin(value0);
    };
    return SetSuperadmin;
})();
var SetSuperadminSuccess = (function () {
    function SetSuperadminSuccess() {

    };
    SetSuperadminSuccess.value = new SetSuperadminSuccess();
    return SetSuperadminSuccess;
})();
var SetSuperadminFailure = (function () {
    function SetSuperadminFailure() {

    };
    SetSuperadminFailure.value = new SetSuperadminFailure();
    return SetSuperadminFailure;
})();
var GetRoleAssignmentsRequest = (function () {
    function GetRoleAssignmentsRequest() {

    };
    GetRoleAssignmentsRequest.value = new GetRoleAssignmentsRequest();
    return GetRoleAssignmentsRequest;
})();
var GetRoleAssignmentsSuccess = (function () {
    function GetRoleAssignmentsSuccess(value0) {
        this.value0 = value0;
    };
    GetRoleAssignmentsSuccess.create = function (value0) {
        return new GetRoleAssignmentsSuccess(value0);
    };
    return GetRoleAssignmentsSuccess;
})();
var GetRoleAssignmentsError = (function () {
    function GetRoleAssignmentsError() {

    };
    GetRoleAssignmentsError.value = new GetRoleAssignmentsError();
    return GetRoleAssignmentsError;
})();
var GetOrganizationUsersRequest = (function () {
    function GetOrganizationUsersRequest() {

    };
    GetOrganizationUsersRequest.value = new GetOrganizationUsersRequest();
    return GetOrganizationUsersRequest;
})();
var GetOrganizationUsersSuccess = (function () {
    function GetOrganizationUsersSuccess(value0) {
        this.value0 = value0;
    };
    GetOrganizationUsersSuccess.create = function (value0) {
        return new GetOrganizationUsersSuccess(value0);
    };
    return GetOrganizationUsersSuccess;
})();
var GetOrganizationUsersError = (function () {
    function GetOrganizationUsersError() {

    };
    GetOrganizationUsersError.value = new GetOrganizationUsersError();
    return GetOrganizationUsersError;
})();
var OrgRemoveUsersRequest = (function () {
    function OrgRemoveUsersRequest(value0) {
        this.value0 = value0;
    };
    OrgRemoveUsersRequest.create = function (value0) {
        return new OrgRemoveUsersRequest(value0);
    };
    return OrgRemoveUsersRequest;
})();
var OrgRemoveUsersSuccess = (function () {
    function OrgRemoveUsersSuccess() {

    };
    OrgRemoveUsersSuccess.value = new OrgRemoveUsersSuccess();
    return OrgRemoveUsersSuccess;
})();
var OrgRemoveUsersError = (function () {
    function OrgRemoveUsersError() {

    };
    OrgRemoveUsersError.value = new OrgRemoveUsersError();
    return OrgRemoveUsersError;
})();
var DeleteSuperAdminUsersRequest = (function () {
    function DeleteSuperAdminUsersRequest(value0) {
        this.value0 = value0;
    };
    DeleteSuperAdminUsersRequest.create = function (value0) {
        return new DeleteSuperAdminUsersRequest(value0);
    };
    return DeleteSuperAdminUsersRequest;
})();
var DeleteUsersSuccess = (function () {
    function DeleteUsersSuccess() {

    };
    DeleteUsersSuccess.value = new DeleteUsersSuccess();
    return DeleteUsersSuccess;
})();
var DeleteUsersError = (function () {
    function DeleteUsersError() {

    };
    DeleteUsersError.value = new DeleteUsersError();
    return DeleteUsersError;
})();
var UpdateCostControlSocialExpensesAction = (function () {
    function UpdateCostControlSocialExpensesAction(value0) {
        this.value0 = value0;
    };
    UpdateCostControlSocialExpensesAction.create = function (value0) {
        return new UpdateCostControlSocialExpensesAction(value0);
    };
    return UpdateCostControlSocialExpensesAction;
})();
var UpdateCostControlSocialExpensesSuccess = (function () {
    function UpdateCostControlSocialExpensesSuccess() {

    };
    UpdateCostControlSocialExpensesSuccess.value = new UpdateCostControlSocialExpensesSuccess();
    return UpdateCostControlSocialExpensesSuccess;
})();
var UpdateCostControlSocialExpensesFailure = (function () {
    function UpdateCostControlSocialExpensesFailure() {

    };
    UpdateCostControlSocialExpensesFailure.value = new UpdateCostControlSocialExpensesFailure();
    return UpdateCostControlSocialExpensesFailure;
})();
var GetUserRolesRequest = (function () {
    function GetUserRolesRequest() {

    };
    GetUserRolesRequest.value = new GetUserRolesRequest();
    return GetUserRolesRequest;
})();
var GetUserRolesSuccess = (function () {
    function GetUserRolesSuccess(value0) {
        this.value0 = value0;
    };
    GetUserRolesSuccess.create = function (value0) {
        return new GetUserRolesSuccess(value0);
    };
    return GetUserRolesSuccess;
})();
var GetUserRolesError = (function () {
    function GetUserRolesError() {

    };
    GetUserRolesError.value = new GetUserRolesError();
    return GetUserRolesError;
})();
var UpdateUserRoleRequest = (function () {
    function UpdateUserRoleRequest(value0) {
        this.value0 = value0;
    };
    UpdateUserRoleRequest.create = function (value0) {
        return new UpdateUserRoleRequest(value0);
    };
    return UpdateUserRoleRequest;
})();
var UpdateUserRoleSuccess = (function () {
    function UpdateUserRoleSuccess() {

    };
    UpdateUserRoleSuccess.value = new UpdateUserRoleSuccess();
    return UpdateUserRoleSuccess;
})();
var UpdateUserRoleError = (function () {
    function UpdateUserRoleError() {

    };
    UpdateUserRoleError.value = new UpdateUserRoleError();
    return UpdateUserRoleError;
})();
var AddUserRoleRequest = (function () {
    function AddUserRoleRequest(value0) {
        this.value0 = value0;
    };
    AddUserRoleRequest.create = function (value0) {
        return new AddUserRoleRequest(value0);
    };
    return AddUserRoleRequest;
})();
var AddUserRoleSuccess = (function () {
    function AddUserRoleSuccess() {

    };
    AddUserRoleSuccess.value = new AddUserRoleSuccess();
    return AddUserRoleSuccess;
})();
var AddUserRoleError = (function () {
    function AddUserRoleError() {

    };
    AddUserRoleError.value = new AddUserRoleError();
    return AddUserRoleError;
})();
var DeleteUserRoleRequest = (function () {
    function DeleteUserRoleRequest(value0) {
        this.value0 = value0;
    };
    DeleteUserRoleRequest.create = function (value0) {
        return new DeleteUserRoleRequest(value0);
    };
    return DeleteUserRoleRequest;
})();
var DeleteUserRoleSuccess = (function () {
    function DeleteUserRoleSuccess() {

    };
    DeleteUserRoleSuccess.value = new DeleteUserRoleSuccess();
    return DeleteUserRoleSuccess;
})();
var DeleteUserRoleError = (function () {
    function DeleteUserRoleError() {

    };
    DeleteUserRoleError.value = new DeleteUserRoleError();
    return DeleteUserRoleError;
})();
var AddOrganizationRequest = (function () {
    function AddOrganizationRequest(value0) {
        this.value0 = value0;
    };
    AddOrganizationRequest.create = function (value0) {
        return new AddOrganizationRequest(value0);
    };
    return AddOrganizationRequest;
})();
var AddOrganizationSuccess = (function () {
    function AddOrganizationSuccess() {

    };
    AddOrganizationSuccess.value = new AddOrganizationSuccess();
    return AddOrganizationSuccess;
})();
var AddOrganizationError = (function () {
    function AddOrganizationError() {

    };
    AddOrganizationError.value = new AddOrganizationError();
    return AddOrganizationError;
})();
var ElementActionRequest = (function () {
    function ElementActionRequest(value0) {
        this.value0 = value0;
    };
    ElementActionRequest.create = function (value0) {
        return new ElementActionRequest(value0);
    };
    return ElementActionRequest;
})();
var ElementActionSuccess = (function () {
    function ElementActionSuccess(value0) {
        this.value0 = value0;
    };
    ElementActionSuccess.create = function (value0) {
        return new ElementActionSuccess(value0);
    };
    return ElementActionSuccess;
})();
var ElementActionFailure = (function () {
    function ElementActionFailure(value0) {
        this.value0 = value0;
    };
    ElementActionFailure.create = function (value0) {
        return new ElementActionFailure(value0);
    };
    return ElementActionFailure;
})();
var SubprojectActionRequest = (function () {
    function SubprojectActionRequest(value0) {
        this.value0 = value0;
    };
    SubprojectActionRequest.create = function (value0) {
        return new SubprojectActionRequest(value0);
    };
    return SubprojectActionRequest;
})();
var SubprojectActionSuccess = (function () {
    function SubprojectActionSuccess(value0) {
        this.value0 = value0;
    };
    SubprojectActionSuccess.create = function (value0) {
        return new SubprojectActionSuccess(value0);
    };
    return SubprojectActionSuccess;
})();
var SubprojectActionFailure = (function () {
    function SubprojectActionFailure(value0) {
        this.value0 = value0;
    };
    SubprojectActionFailure.create = function (value0) {
        return new SubprojectActionFailure(value0);
    };
    return SubprojectActionFailure;
})();
var ProjectClassificationClassActionRequest = (function () {
    function ProjectClassificationClassActionRequest(value0) {
        this.value0 = value0;
    };
    ProjectClassificationClassActionRequest.create = function (value0) {
        return new ProjectClassificationClassActionRequest(value0);
    };
    return ProjectClassificationClassActionRequest;
})();
var ProjectClassificationClassActionSuccess = (function () {
    function ProjectClassificationClassActionSuccess(value0) {
        this.value0 = value0;
    };
    ProjectClassificationClassActionSuccess.create = function (value0) {
        return new ProjectClassificationClassActionSuccess(value0);
    };
    return ProjectClassificationClassActionSuccess;
})();
var ProjectClassificationClassActionFailure = (function () {
    function ProjectClassificationClassActionFailure(value0) {
        this.value0 = value0;
    };
    ProjectClassificationClassActionFailure.create = function (value0) {
        return new ProjectClassificationClassActionFailure(value0);
    };
    return ProjectClassificationClassActionFailure;
})();
var LocationActionRequest = (function () {
    function LocationActionRequest(value0) {
        this.value0 = value0;
    };
    LocationActionRequest.create = function (value0) {
        return new LocationActionRequest(value0);
    };
    return LocationActionRequest;
})();
var LocationActionSuccess = (function () {
    function LocationActionSuccess(value0) {
        this.value0 = value0;
    };
    LocationActionSuccess.create = function (value0) {
        return new LocationActionSuccess(value0);
    };
    return LocationActionSuccess;
})();
var LocationActionFailure = (function () {
    function LocationActionFailure(value0) {
        this.value0 = value0;
    };
    LocationActionFailure.create = function (value0) {
        return new LocationActionFailure(value0);
    };
    return LocationActionFailure;
})();
var PublishToReportingRequest = (function () {
    function PublishToReportingRequest(value0) {
        this.value0 = value0;
    };
    PublishToReportingRequest.create = function (value0) {
        return new PublishToReportingRequest(value0);
    };
    return PublishToReportingRequest;
})();
var PublishToReportingActionSuccess = (function () {
    function PublishToReportingActionSuccess() {

    };
    PublishToReportingActionSuccess.value = new PublishToReportingActionSuccess();
    return PublishToReportingActionSuccess;
})();
var PublishToReportingActionFailure = (function () {
    function PublishToReportingActionFailure() {

    };
    PublishToReportingActionFailure.value = new PublishToReportingActionFailure();
    return PublishToReportingActionFailure;
})();
var UnpublishReportingRequest = (function () {
    function UnpublishReportingRequest(value0) {
        this.value0 = value0;
    };
    UnpublishReportingRequest.create = function (value0) {
        return new UnpublishReportingRequest(value0);
    };
    return UnpublishReportingRequest;
})();
var UnpublishReportingActionSuccess = (function () {
    function UnpublishReportingActionSuccess() {

    };
    UnpublishReportingActionSuccess.value = new UnpublishReportingActionSuccess();
    return UnpublishReportingActionSuccess;
})();
var UnpublishReportingActionFailure = (function () {
    function UnpublishReportingActionFailure() {

    };
    UnpublishReportingActionFailure.value = new UnpublishReportingActionFailure();
    return UnpublishReportingActionFailure;
})();
var ResourcePriceAdjustmentRequest = (function () {
    function ResourcePriceAdjustmentRequest(value0) {
        this.value0 = value0;
    };
    ResourcePriceAdjustmentRequest.create = function (value0) {
        return new ResourcePriceAdjustmentRequest(value0);
    };
    return ResourcePriceAdjustmentRequest;
})();
var ResourcePriceAdjustmentSuccess = (function () {
    function ResourcePriceAdjustmentSuccess() {

    };
    ResourcePriceAdjustmentSuccess.value = new ResourcePriceAdjustmentSuccess();
    return ResourcePriceAdjustmentSuccess;
})();
var ResourcePriceAdjustmentFailure = (function () {
    function ResourcePriceAdjustmentFailure() {

    };
    ResourcePriceAdjustmentFailure.value = new ResourcePriceAdjustmentFailure();
    return ResourcePriceAdjustmentFailure;
})();
var ClassificationGroupActionRequest = (function () {
    function ClassificationGroupActionRequest(value0) {
        this.value0 = value0;
    };
    ClassificationGroupActionRequest.create = function (value0) {
        return new ClassificationGroupActionRequest(value0);
    };
    return ClassificationGroupActionRequest;
})();
var ClassificationGroupActionSuccess = (function () {
    function ClassificationGroupActionSuccess(value0) {
        this.value0 = value0;
    };
    ClassificationGroupActionSuccess.create = function (value0) {
        return new ClassificationGroupActionSuccess(value0);
    };
    return ClassificationGroupActionSuccess;
})();
var ClassificationGroupActionFailure = (function () {
    function ClassificationGroupActionFailure(value0) {
        this.value0 = value0;
    };
    ClassificationGroupActionFailure.create = function (value0) {
        return new ClassificationGroupActionFailure(value0);
    };
    return ClassificationGroupActionFailure;
})();
var GetVATExclusionCostGroupsRequest = (function () {
    function GetVATExclusionCostGroupsRequest(value0) {
        this.value0 = value0;
    };
    GetVATExclusionCostGroupsRequest.create = function (value0) {
        return new GetVATExclusionCostGroupsRequest(value0);
    };
    return GetVATExclusionCostGroupsRequest;
})();
var GetVATExclusionCostGroupsSuccess = (function () {
    function GetVATExclusionCostGroupsSuccess(value0) {
        this.value0 = value0;
    };
    GetVATExclusionCostGroupsSuccess.create = function (value0) {
        return new GetVATExclusionCostGroupsSuccess(value0);
    };
    return GetVATExclusionCostGroupsSuccess;
})();
var GetVATExclusionCostGroupsFailure = (function () {
    function GetVATExclusionCostGroupsFailure(value0) {
        this.value0 = value0;
    };
    GetVATExclusionCostGroupsFailure.create = function (value0) {
        return new GetVATExclusionCostGroupsFailure(value0);
    };
    return GetVATExclusionCostGroupsFailure;
})();
var VATExclusionCostGroupsActionRequest = (function () {
    function VATExclusionCostGroupsActionRequest(value0) {
        this.value0 = value0;
    };
    VATExclusionCostGroupsActionRequest.create = function (value0) {
        return new VATExclusionCostGroupsActionRequest(value0);
    };
    return VATExclusionCostGroupsActionRequest;
})();
var VATExclusionCostGroupsActionSuccess = (function () {
    function VATExclusionCostGroupsActionSuccess(value0) {
        this.value0 = value0;
    };
    VATExclusionCostGroupsActionSuccess.create = function (value0) {
        return new VATExclusionCostGroupsActionSuccess(value0);
    };
    return VATExclusionCostGroupsActionSuccess;
})();
var VATExclusionCostGroupsActionFailure = (function () {
    function VATExclusionCostGroupsActionFailure(value0) {
        this.value0 = value0;
    };
    VATExclusionCostGroupsActionFailure.create = function (value0) {
        return new VATExclusionCostGroupsActionFailure(value0);
    };
    return VATExclusionCostGroupsActionFailure;
})();
var ResetCostGroupTargetItemsRequest = (function () {
    function ResetCostGroupTargetItemsRequest(value0) {
        this.value0 = value0;
    };
    ResetCostGroupTargetItemsRequest.create = function (value0) {
        return new ResetCostGroupTargetItemsRequest(value0);
    };
    return ResetCostGroupTargetItemsRequest;
})();
var ResetCostGroupTargetItemsSuccess = (function () {
    function ResetCostGroupTargetItemsSuccess(value0) {
        this.value0 = value0;
    };
    ResetCostGroupTargetItemsSuccess.create = function (value0) {
        return new ResetCostGroupTargetItemsSuccess(value0);
    };
    return ResetCostGroupTargetItemsSuccess;
})();
var ResetCostGroupTargetItemsFailure = (function () {
    function ResetCostGroupTargetItemsFailure(value0) {
        this.value0 = value0;
    };
    ResetCostGroupTargetItemsFailure.create = function (value0) {
        return new ResetCostGroupTargetItemsFailure(value0);
    };
    return ResetCostGroupTargetItemsFailure;
})();
var OrgAddressAreaRequest = (function () {
    function OrgAddressAreaRequest() {

    };
    OrgAddressAreaRequest.value = new OrgAddressAreaRequest();
    return OrgAddressAreaRequest;
})();
var OrgAddressAreaSuccess = (function () {
    function OrgAddressAreaSuccess(value0) {
        this.value0 = value0;
    };
    OrgAddressAreaSuccess.create = function (value0) {
        return new OrgAddressAreaSuccess(value0);
    };
    return OrgAddressAreaSuccess;
})();
var OrgAddressAreaFailure = (function () {
    function OrgAddressAreaFailure() {

    };
    OrgAddressAreaFailure.value = new OrgAddressAreaFailure();
    return OrgAddressAreaFailure;
})();
var OrgCostClassRequest = (function () {
    function OrgCostClassRequest() {

    };
    OrgCostClassRequest.value = new OrgCostClassRequest();
    return OrgCostClassRequest;
})();
var OrgCostClassSuccess = (function () {
    function OrgCostClassSuccess(value0) {
        this.value0 = value0;
    };
    OrgCostClassSuccess.create = function (value0) {
        return new OrgCostClassSuccess(value0);
    };
    return OrgCostClassSuccess;
})();
var OrgCostClassFailure = (function () {
    function OrgCostClassFailure() {

    };
    OrgCostClassFailure.value = new OrgCostClassFailure();
    return OrgCostClassFailure;
})();
var CopyResourcesToClipboard = (function () {
    function CopyResourcesToClipboard(value0) {
        this.value0 = value0;
    };
    CopyResourcesToClipboard.create = function (value0) {
        return new CopyResourcesToClipboard(value0);
    };
    return CopyResourcesToClipboard;
})();
var CopyComponentsToClipboard = (function () {
    function CopyComponentsToClipboard(value0) {
        this.value0 = value0;
    };
    CopyComponentsToClipboard.create = function (value0) {
        return new CopyComponentsToClipboard(value0);
    };
    return CopyComponentsToClipboard;
})();
var TickCounter = (function () {
    function TickCounter() {

    };
    TickCounter.value = new TickCounter();
    return TickCounter;
})();
var WorkerRequestAction = (function () {
    function WorkerRequestAction(value0) {
        this.value0 = value0;
    };
    WorkerRequestAction.create = function (value0) {
        return new WorkerRequestAction(value0);
    };
    return WorkerRequestAction;
})();
var WorkerResponseAction = (function () {
    function WorkerResponseAction(value0) {
        this.value0 = value0;
    };
    WorkerResponseAction.create = function (value0) {
        return new WorkerResponseAction(value0);
    };
    return WorkerResponseAction;
})();
module.exports = {
    PortfolioReportRequest: PortfolioReportRequest,
    PortfolioReportSuccess: PortfolioReportSuccess,
    PortfolioReportError: PortfolioReportError,
    UpdatePortfolioReportRequest: UpdatePortfolioReportRequest,
    UpdatePortfolioReportSuccess: UpdatePortfolioReportSuccess,
    UpdatePortfolioReportError: UpdatePortfolioReportError,
    UpdateProjectGroupCacheRequest: UpdateProjectGroupCacheRequest,
    UpdateProjectGroupCacheSuccess: UpdateProjectGroupCacheSuccess,
    UpdateProjectGroupCacheError: UpdateProjectGroupCacheError,
    GetProjectGroupCacheLastUpdateRequest: GetProjectGroupCacheLastUpdateRequest,
    GetProjectGroupCacheLastUpdateSuccess: GetProjectGroupCacheLastUpdateSuccess,
    GetProjectGroupCacheLastUpdateError: GetProjectGroupCacheLastUpdateError,
    AllProjectsRequest: AllProjectsRequest,
    AllProjectsSuccess: AllProjectsSuccess,
    AllProjectsError: AllProjectsError,
    CostControlProjectsRequest: CostControlProjectsRequest,
    CostControlProjectsSuccess: CostControlProjectsSuccess,
    CostControlProjectsError: CostControlProjectsError,
    EstimationProjectsRequest: EstimationProjectsRequest,
    EstimationProjectsSuccess: EstimationProjectsSuccess,
    EstimationProjectsError: EstimationProjectsError,
    GetProjectRequest: GetProjectRequest,
    GetProjectSuccess: GetProjectSuccess,
    GetProjectError: GetProjectError,
    GetProjectReset: GetProjectReset,
    GetReferenceSubprojectsRequest: GetReferenceSubprojectsRequest,
    GetReferenceSubprojectsSuccess: GetReferenceSubprojectsSuccess,
    GetReferenceSubprojectsError: GetReferenceSubprojectsError,
    AddReferenceProjectRequest: AddReferenceProjectRequest,
    AddReferenceProjectSuccess: AddReferenceProjectSuccess,
    AddReferenceProjectError: AddReferenceProjectError,
    DeleteReferenceProjectRequest: DeleteReferenceProjectRequest,
    DeleteReferenceProjectSuccess: DeleteReferenceProjectSuccess,
    DeleteReferenceProjectError: DeleteReferenceProjectError,
    InvoiceImgRequest: InvoiceImgRequest,
    ZipInvoiceImgsRequest: ZipInvoiceImgsRequest,
    ClassificationsRequest: ClassificationsRequest,
    ClassificationsSuccess: ClassificationsSuccess,
    ClassificationsError: ClassificationsError,
    GetProjectManagersRequest: GetProjectManagersRequest,
    GetProjectManagersSuccess: GetProjectManagersSuccess,
    GetProjectManagersError: GetProjectManagersError,
    AddProjectManagerRequest: AddProjectManagerRequest,
    DeleteProjectManagerRequest: DeleteProjectManagerRequest,
    EditProjectManagerRequest: EditProjectManagerRequest,
    ProjectGroupsRequest: ProjectGroupsRequest,
    ProjectGroupsSuccess: ProjectGroupsSuccess,
    ProjectGroupsError: ProjectGroupsError,
    ConstructionTypesRequest: ConstructionTypesRequest,
    ConstructionTypesSuccess: ConstructionTypesSuccess,
    ConstructionTypesError: ConstructionTypesError,
    FinancingTypesRequest: FinancingTypesRequest,
    FinancingTypesSuccess: FinancingTypesSuccess,
    FinancingTypesError: FinancingTypesError,
    ProjectTypesRequest: ProjectTypesRequest,
    ProjectTypesSuccess: ProjectTypesSuccess,
    ProjectTypesError: ProjectTypesError,
    ContractTypesRequest: ContractTypesRequest,
    ContractTypesSuccess: ContractTypesSuccess,
    ContractTypesError: ContractTypesError,
    ResponsibilityCompaniesRequest: ResponsibilityCompaniesRequest,
    ResponsibilityCompaniesSuccess: ResponsibilityCompaniesSuccess,
    ResponsibilityCompaniesFailure: ResponsibilityCompaniesFailure,
    ProjectResponsibilityInfoRequest: ProjectResponsibilityInfoRequest,
    ProjectResponsibilityInfoSuccess: ProjectResponsibilityInfoSuccess,
    ProjectResponsibilityInfoFailure: ProjectResponsibilityInfoFailure,
    UpdateProjectResponsibilityInfoAction: UpdateProjectResponsibilityInfoAction,
    UpdateProjectResponsibilityInfoSuccess: UpdateProjectResponsibilityInfoSuccess,
    UpdateProjectResponsibilityInfoFailure: UpdateProjectResponsibilityInfoFailure,
    MainCostGroupsRequest: MainCostGroupsRequest,
    MainCostGroupsSuccess: MainCostGroupsSuccess,
    MainCostGroupsError: MainCostGroupsError,
    InvoiceBunchesRequest: InvoiceBunchesRequest,
    InvoiceBunchesSuccess: InvoiceBunchesSuccess,
    InvoiceBunchesError: InvoiceBunchesError,
    AllInvoiceBunchesRequest: AllInvoiceBunchesRequest,
    AllInvoiceBunchesSuccess: AllInvoiceBunchesSuccess,
    AllInvoiceBunchesError: AllInvoiceBunchesError,
    CostGroupInfosRequest: CostGroupInfosRequest,
    CostGroupInfosSuccess: CostGroupInfosSuccess,
    CostGroupInfosError: CostGroupInfosError,
    ProjectClassificationClassesRequest: ProjectClassificationClassesRequest,
    ProjectClassificationClassesSuccess: ProjectClassificationClassesSuccess,
    ProjectClassificationClassesError: ProjectClassificationClassesError,
    OrgClassificationClassesRequest: OrgClassificationClassesRequest,
    OrgClassificationClassesSuccess: OrgClassificationClassesSuccess,
    OrgClassificationClassesError: OrgClassificationClassesError,
    GetClassificationGroupsRequest: GetClassificationGroupsRequest,
    GetClassificationGroupsSuccess: GetClassificationGroupsSuccess,
    GetClassificationGroupsError: GetClassificationGroupsError,
    ProjectCostGroupsRequest: ProjectCostGroupsRequest,
    ProjectCostGroupsSuccess: ProjectCostGroupsSuccess,
    ProjectCostGroupsError: ProjectCostGroupsError,
    ProjectCostGroupCategoriesRequest: ProjectCostGroupCategoriesRequest,
    ProjectCostGroupCategoriesSuccess: ProjectCostGroupCategoriesSuccess,
    ProjectCostGroupCategoriesError: ProjectCostGroupCategoriesError,
    UpdateCostGroupCategoryRequest: UpdateCostGroupCategoryRequest,
    ProjectCostGroupInvoicesRequest: ProjectCostGroupInvoicesRequest,
    ProjectCostGroupInvoicesSuccess: ProjectCostGroupInvoicesSuccess,
    ProjectCostGroupInvoicesError: ProjectCostGroupInvoicesError,
    ProjectCostClassesRequest: ProjectCostClassesRequest,
    ProjectCostClassesSuccess: ProjectCostClassesSuccess,
    ProjectCostClassesError: ProjectCostClassesError,
    CostClassActionRequest: CostClassActionRequest,
    CostClassActionSuccess: CostClassActionSuccess,
    CostClassActionError: CostClassActionError,
    GetReferenceProjectCostClassesRequest: GetReferenceProjectCostClassesRequest,
    GetReferenceProjectCostClassesSuccess: GetReferenceProjectCostClassesSuccess,
    GetReferenceProjectCostClassesError: GetReferenceProjectCostClassesError,
    SocialExpensesRequest: SocialExpensesRequest,
    SocialExpensesSuccess: SocialExpensesSuccess,
    SocialExpensesError: SocialExpensesError,
    ProjectEstimateItemsRequest: ProjectEstimateItemsRequest,
    ProjectEstimateItemsSuccess: ProjectEstimateItemsSuccess,
    ProjectEstimateItemsError: ProjectEstimateItemsError,
    GetEstimationUiState: GetEstimationUiState,
    SetEstimationUiState: SetEstimationUiState,
    ProjectTargetItemsRequest: ProjectTargetItemsRequest,
    ProjectTargetItemsSuccess: ProjectTargetItemsSuccess,
    ProjectTargetItemsError: ProjectTargetItemsError,
    CostGroupItemActionRequest: CostGroupItemActionRequest,
    CostGroupItemActionSuccess: CostGroupItemActionSuccess,
    CostGroupItemActionError: CostGroupItemActionError,
    EstimationElementActionRequest: EstimationElementActionRequest,
    EstimationElementActionSuccess: EstimationElementActionSuccess,
    EstimationElementActionError: EstimationElementActionError,
    ElementMeasureActionRequest: ElementMeasureActionRequest,
    ElementMeasureActionSuccess: ElementMeasureActionSuccess,
    ElementMeasureActionError: ElementMeasureActionError,
    EstimationResourcePriceMatchActionRequest: EstimationResourcePriceMatchActionRequest,
    EstimationResourcePriceMatchActionSuccess: EstimationResourcePriceMatchActionSuccess,
    EstimationResourcePriceMatchActionError: EstimationResourcePriceMatchActionError,
    EstimationResourcePriceUpdateActionRequest: EstimationResourcePriceUpdateActionRequest,
    EstimationResourcePriceUpdateActionSuccess: EstimationResourcePriceUpdateActionSuccess,
    EstimationResourcePriceUpdateActionError: EstimationResourcePriceUpdateActionError,
    EstimationComponentActionRequest: EstimationComponentActionRequest,
    EstimationComponentActionSuccess: EstimationComponentActionSuccess,
    EstimationComponentActionError: EstimationComponentActionError,
    EstimationResourceActionRequest: EstimationResourceActionRequest,
    EstimationResourceActionSuccess: EstimationResourceActionSuccess,
    EstimationResourceActionError: EstimationResourceActionError,
    AddProjectRequest: AddProjectRequest,
    CopyProjectRequest: CopyProjectRequest,
    UpdateProjectRequest: UpdateProjectRequest,
    UpdateProjectArchiveStatusRequest: UpdateProjectArchiveStatusRequest,
    UpdateProjectCostGroupMemoRequest: UpdateProjectCostGroupMemoRequest,
    AddCostGroupRequest: AddCostGroupRequest,
    AddCostGroupSuccess: AddCostGroupSuccess,
    DeleteCostGroupsRequest: DeleteCostGroupsRequest,
    GetSalesInvoicesRequest: GetSalesInvoicesRequest,
    DeleteSalesInvoicesRequest: DeleteSalesInvoicesRequest,
    GetSalesInvoicesSuccess: GetSalesInvoicesSuccess,
    GetSalesInvoicesError: GetSalesInvoicesError,
    UpdateCodeForSalesInvoiceRequest: UpdateCodeForSalesInvoiceRequest,
    UpdateCodeForSalesInvoiceSuccess: UpdateCodeForSalesInvoiceSuccess,
    UpdateCodeForSalesInvoiceFailure: UpdateCodeForSalesInvoiceFailure,
    GetIncomeItemsRequest: GetIncomeItemsRequest,
    GetIncomeItemsSuccess: GetIncomeItemsSuccess,
    GetIncomeItemsError: GetIncomeItemsError,
    GetProjectLocationsRequest: GetProjectLocationsRequest,
    GetProjectLocationsSuccess: GetProjectLocationsSuccess,
    GetProjectLocationsError: GetProjectLocationsError,
    GetIncomeGroupsRequest: GetIncomeGroupsRequest,
    GetIncomeGroupsSuccess: GetIncomeGroupsSuccess,
    GetIncomeGroupsFailure: GetIncomeGroupsFailure,
    AddIncomeGroupRequest: AddIncomeGroupRequest,
    SetIncomeItemApprovalStatusesRequest: SetIncomeItemApprovalStatusesRequest,
    SetIncomeItemApprovalStatusesSuccess: SetIncomeItemApprovalStatusesSuccess,
    SetIncomeItemApprovalStatusesError: SetIncomeItemApprovalStatusesError,
    SetIncomeItemApprovalDatesRequest: SetIncomeItemApprovalDatesRequest,
    SetIncomeItemApprovalDatesSuccess: SetIncomeItemApprovalDatesSuccess,
    SetIncomeItemApprovalDatesError: SetIncomeItemApprovalDatesError,
    SetIncomeItemPlannedInvoiceDatesRequest: SetIncomeItemPlannedInvoiceDatesRequest,
    SetIncomeItemPlannedInvoiceDatesSuccess: SetIncomeItemPlannedInvoiceDatesSuccess,
    SetIncomeItemPlannedInvoiceDatesError: SetIncomeItemPlannedInvoiceDatesError,
    SetIncomeItemsInclusionInTargetRequest: SetIncomeItemsInclusionInTargetRequest,
    SetIncomeItemsInclusionInTargetSuccess: SetIncomeItemsInclusionInTargetSuccess,
    SetIncomeItemsInclusionInTargetError: SetIncomeItemsInclusionInTargetError,
    InsertInvoiceRequest: InsertInvoiceRequest,
    GetInvoicesRequest: GetInvoicesRequest,
    GetInvoicesSuccess: GetInvoicesSuccess,
    GetInvoicesError: GetInvoicesError,
    DeleteInvoicesRequest: DeleteInvoicesRequest,
    DeleteInvoicesSuccess: DeleteInvoicesSuccess,
    DeleteInvoicesFailure: DeleteInvoicesFailure,
    UpdateInvoiceLineBunchesRequest: UpdateInvoiceLineBunchesRequest,
    UpdateInvoiceLineBunchesSuccess: UpdateInvoiceLineBunchesSuccess,
    UpdateInvoiceLineBunchesError: UpdateInvoiceLineBunchesError,
    UpdateInvoiceLinesCostGroupCodeRequest: UpdateInvoiceLinesCostGroupCodeRequest,
    UpdateInvoiceLinesCostGroupCodeSuccess: UpdateInvoiceLinesCostGroupCodeSuccess,
    UpdateInvoiceLinesCostGroupCodeError: UpdateInvoiceLinesCostGroupCodeError,
    UpdateInvoiceLinesCostClassCodeRequest: UpdateInvoiceLinesCostClassCodeRequest,
    UpdateInvoiceLinesCostClassCodeSuccess: UpdateInvoiceLinesCostClassCodeSuccess,
    UpdateInvoiceLinesCostClassCodeError: UpdateInvoiceLinesCostClassCodeError,
    SuppliersRequest: SuppliersRequest,
    SuppliersSuccess: SuppliersSuccess,
    SuppliersError: SuppliersError,
    UpdateSupplierBusinessIdRequest: UpdateSupplierBusinessIdRequest,
    UpdateSupplierBusinessIdSuccess: UpdateSupplierBusinessIdSuccess,
    UpdateSupplierBusinessIdError: UpdateSupplierBusinessIdError,
    NavigateRoute: NavigateRoute,
    SetRoute: SetRoute,
    GetReportExcelRequest: GetReportExcelRequest,
    GetPlanningCostGroupsExport: GetPlanningCostGroupsExport,
    GetPlanningProcumentExport: GetPlanningProcumentExport,
    GetEstimationExcelExportRequest: GetEstimationExcelExportRequest,
    GetEstimationQuantityExcelExportRequest: GetEstimationQuantityExcelExportRequest,
    GetEstimationQuantityByCostClassExcelExportRequest: GetEstimationQuantityByCostClassExcelExportRequest,
    GetEstimationQuantityByLocationExcelExportRequest: GetEstimationQuantityByLocationExcelExportRequest,
    GetEstimationComponentsExcelExportRequest: GetEstimationComponentsExcelExportRequest,
    GetEstimationResourcesExcelExportRequest: GetEstimationResourcesExcelExportRequest,
    GetEstimationElementsExcelExportRequest: GetEstimationElementsExcelExportRequest,
    GetOfferPageExcelExportRequest: GetOfferPageExcelExportRequest,
    GetEstimationProjectExportRequest: GetEstimationProjectExportRequest,
    PortfolioReportExcelExportRequest: PortfolioReportExcelExportRequest,
    GetOrgResourceExcelExportRequest: GetOrgResourceExcelExportRequest,
    GetOrgPriceListExcelExportRequest: GetOrgPriceListExcelExportRequest,
    GetOrgResourceSubGroupingExcelExportRequest: GetOrgResourceSubGroupingExcelExportRequest,
    GetInvoiceLinesExportRequest: GetInvoiceLinesExportRequest,
    GetCostGroupsExportRequest: GetCostGroupsExportRequest,
    UpdateCostGroupMemoRequest: UpdateCostGroupMemoRequest,
    UpdateCostGroupMemoSuccess: UpdateCostGroupMemoSuccess,
    UpdateCostGroupMemoError: UpdateCostGroupMemoError,
    UpdateCostGroupCompletionPercentageRequest: UpdateCostGroupCompletionPercentageRequest,
    UpdateCostGroupCompletionPercentageSuccess: UpdateCostGroupCompletionPercentageSuccess,
    UpdateCostGroupCompletionPercentageError: UpdateCostGroupCompletionPercentageError,
    AddAlert: AddAlert,
    DismissAlert: DismissAlert,
    AddIncomeItemsRequest: AddIncomeItemsRequest,
    AddIncomeItemsSuccess: AddIncomeItemsSuccess,
    AddIncomeItemsFailure: AddIncomeItemsFailure,
    RemoveIncomeItemsRequest: RemoveIncomeItemsRequest,
    RemoveIncomeItemsSuccess: RemoveIncomeItemsSuccess,
    RemoveIncomeItemsFailure: RemoveIncomeItemsFailure,
    EditCostGroupNamesRequest: EditCostGroupNamesRequest,
    EditCostGroupNamesSuccess: EditCostGroupNamesSuccess,
    EditCostGroupNamesFailure: EditCostGroupNamesFailure,
    UpdateIncomeItemRequest: UpdateIncomeItemRequest,
    UpdateIncomeItemSuccess: UpdateIncomeItemSuccess,
    UpdateIncomeItemFailure: UpdateIncomeItemFailure,
    EditMainCostGroupNamesRequest: EditMainCostGroupNamesRequest,
    EditMainCostGroupNamesSuccess: EditMainCostGroupNamesSuccess,
    EditMainCostGroupNamesFailure: EditMainCostGroupNamesFailure,
    UpdateReportingResourceCostGroupCodeRequest: UpdateReportingResourceCostGroupCodeRequest,
    UpdateReportingResourceCostGroupCodeSuccess: UpdateReportingResourceCostGroupCodeSuccess,
    UpdateReportingResourceCostGroupCodeFailure: UpdateReportingResourceCostGroupCodeFailure,
    GetMeasurementsRequest: GetMeasurementsRequest,
    GetMeasurementsSuccess: GetMeasurementsSuccess,
    GetMeasurementsFailure: GetMeasurementsFailure,
    UpdateMeasurementValue: UpdateMeasurementValue,
    MeasurementUnitAction: MeasurementUnitAction,
    GetServerStatusAction: GetServerStatusAction,
    GetServerStatusSuccess: GetServerStatusSuccess,
    GetServerStatusFailure: GetServerStatusFailure,
    SetOutageMessageAction: SetOutageMessageAction,
    SetOutageMessageSuccess: SetOutageMessageSuccess,
    SetOutageMessageFailure: SetOutageMessageFailure,
    GetSubprojectsRequest: GetSubprojectsRequest,
    GetSubprojectsSuccess: GetSubprojectsSuccess,
    GetSubprojectsFailure: GetSubprojectsFailure,
    GetReportingResourcesRequest: GetReportingResourcesRequest,
    GetReportingResourcesSuccess: GetReportingResourcesSuccess,
    GetReportingResourcesFailure: GetReportingResourcesFailure,
    GetReportingComponentsRequest: GetReportingComponentsRequest,
    GetReportingComponentsSuccess: GetReportingComponentsSuccess,
    GetReportingComponentsFailure: GetReportingComponentsFailure,
    UpdateEstimationComponentMemoRequest: UpdateEstimationComponentMemoRequest,
    UpdateEstimationComponentMemoSuccess: UpdateEstimationComponentMemoSuccess,
    UpdateEstimationComponentMemoError: UpdateEstimationComponentMemoError,
    GetEstimationComponentsRequest: GetEstimationComponentsRequest,
    GetEstimationComponentsSuccess: GetEstimationComponentsSuccess,
    GetEstimationComponentsFailure: GetEstimationComponentsFailure,
    GetEstimationElementsRequest: GetEstimationElementsRequest,
    GetEstimationElementsSuccess: GetEstimationElementsSuccess,
    GetEstimationElementsFailure: GetEstimationElementsFailure,
    GetElementMeasuresRequest: GetElementMeasuresRequest,
    GetElementMeasuresSuccess: GetElementMeasuresSuccess,
    GetElementMeasuresFailure: GetElementMeasuresFailure,
    GetReferenceElementMeasuresRequest: GetReferenceElementMeasuresRequest,
    GetReferenceElementMeasuresSuccess: GetReferenceElementMeasuresSuccess,
    GetReferenceElementMeasuresFailure: GetReferenceElementMeasuresFailure,
    GetElementLocationsRequest: GetElementLocationsRequest,
    GetElementLocationsSuccess: GetElementLocationsSuccess,
    GetElementLocationsFailure: GetElementLocationsFailure,
    GetElementSpecsRequest: GetElementSpecsRequest,
    GetElementSpecsSuccess: GetElementSpecsSuccess,
    GetElementSpecsFailure: GetElementSpecsFailure,
    GetReferenceEstimationComponentsRequest: GetReferenceEstimationComponentsRequest,
    GetReferenceEstimationComponentsSuccess: GetReferenceEstimationComponentsSuccess,
    GetReferenceEstimationComponentsFailure: GetReferenceEstimationComponentsFailure,
    GetReferenceEstimationElementsRequest: GetReferenceEstimationElementsRequest,
    GetReferenceEstimationElementsSuccess: GetReferenceEstimationElementsSuccess,
    GetReferenceEstimationElementsFailure: GetReferenceEstimationElementsFailure,
    GetReferenceElementLocationsRequest: GetReferenceElementLocationsRequest,
    GetReferenceElementLocationsSuccess: GetReferenceElementLocationsSuccess,
    GetReferenceElementLocationsFailure: GetReferenceElementLocationsFailure,
    GetReferenceElementSpecsRequest: GetReferenceElementSpecsRequest,
    GetReferenceElementSpecsSuccess: GetReferenceElementSpecsSuccess,
    GetReferenceElementSpecsFailure: GetReferenceElementSpecsFailure,
    GetEstimationResourcesRequest: GetEstimationResourcesRequest,
    GetEstimationResourcesSuccess: GetEstimationResourcesSuccess,
    GetEstimationResourcesFailure: GetEstimationResourcesFailure,
    GetEstimationResourcePriceListRequest: GetEstimationResourcePriceListRequest,
    GetEstimationResourcePriceListSuccess: GetEstimationResourcePriceListSuccess,
    GetEstimationResourcePriceListError: GetEstimationResourcePriceListError,
    GetOfferPageParamsRequest: GetOfferPageParamsRequest,
    GetOfferPageParamsSuccess: GetOfferPageParamsSuccess,
    GetOfferPageParamsFailure: GetOfferPageParamsFailure,
    SetOfferPageParamsRequest: SetOfferPageParamsRequest,
    SetOfferPageParamsSuccess: SetOfferPageParamsSuccess,
    SetOfferPageParamsFailure: SetOfferPageParamsFailure,
    GetPlanningCostGroupsRequest: GetPlanningCostGroupsRequest,
    GetPlanningCostGroupsSuccess: GetPlanningCostGroupsSuccess,
    GetPlanningCostGroupsFailure: GetPlanningCostGroupsFailure,
    PlanningActionRequest: PlanningActionRequest,
    PlanningActionSuccess: PlanningActionSuccess,
    PlanningActionFailure: PlanningActionFailure,
    ConvertToTopicComponentRequest: ConvertToTopicComponentRequest,
    ConvertToTopicComponentSuccess: ConvertToTopicComponentSuccess,
    ConvertToTopicComponentFailure: ConvertToTopicComponentFailure,
    ConvertToRegularComponentRequest: ConvertToRegularComponentRequest,
    ConvertToRegularComponentSuccess: ConvertToRegularComponentSuccess,
    ConvertToRegularComponentFailure: ConvertToRegularComponentFailure,
    GetOrgPriceListTreeRequest: GetOrgPriceListTreeRequest,
    GetOrgPriceListTreeSuccess: GetOrgPriceListTreeSuccess,
    GetOrgPriceListTreeError: GetOrgPriceListTreeError,
    GetOrgPriceLists: GetOrgPriceLists,
    GetOrgPriceListsSuccess: GetOrgPriceListsSuccess,
    GetOrgPriceListsError: GetOrgPriceListsError,
    GetProjectCostGroupClassificationInfoRequest: GetProjectCostGroupClassificationInfoRequest,
    GetProjectCostGroupClassificationInfoSuccess: GetProjectCostGroupClassificationInfoSuccess,
    GetProjectCostGroupClassificationInfoError: GetProjectCostGroupClassificationInfoError,
    GetReferenceEstimationResourcesRequest: GetReferenceEstimationResourcesRequest,
    GetReferenceEstimationResourcesSuccess: GetReferenceEstimationResourcesSuccess,
    GetReferenceEstimationResourcesFailure: GetReferenceEstimationResourcesFailure,
    GetEstimationLocationsRequest: GetEstimationLocationsRequest,
    GetEstimationLocationsSuccess: GetEstimationLocationsSuccess,
    GetEstimationLocationsFailure: GetEstimationLocationsFailure,
    EstimationLocationActionRequest: EstimationLocationActionRequest,
    EstimationLocationActionSuccess: EstimationLocationActionSuccess,
    EstimationLocationActionFailure: EstimationLocationActionFailure,
    EstimationResourcePriceListActionRequest: EstimationResourcePriceListActionRequest,
    EstimationResourcePriceListActionSuccess: EstimationResourcePriceListActionSuccess,
    EstimationResourcePriceListActionFailure: EstimationResourcePriceListActionFailure,
    SetEstimationComponentCostClassPriceRequest: SetEstimationComponentCostClassPriceRequest,
    SetEstimationComponentCostClassPriceSuccess: SetEstimationComponentCostClassPriceSuccess,
    SetEstimationComponentCostClassPriceFailure: SetEstimationComponentCostClassPriceFailure,
    GetUserOrganizationsRequest: GetUserOrganizationsRequest,
    GetUserOrganizationsSuccess: GetUserOrganizationsSuccess,
    GetUserOrganizationsFailure: GetUserOrganizationsFailure,
    GetUserAppAccessRightsRequest: GetUserAppAccessRightsRequest,
    GetUserAppAccessRightsSuccess: GetUserAppAccessRightsSuccess,
    GetUserAppAccessRightsFailure: GetUserAppAccessRightsFailure,
    GetAllOrganizationsRequest: GetAllOrganizationsRequest,
    GetAllOrganizationsSuccess: GetAllOrganizationsSuccess,
    GetAllOrganizationsFailure: GetAllOrganizationsFailure,
    GetAllOrganizationConfigurationsRequest: GetAllOrganizationConfigurationsRequest,
    GetAllOrganizationConfigurationsSuccess: GetAllOrganizationConfigurationsSuccess,
    GetAllOrganizationConfigurationsFailure: GetAllOrganizationConfigurationsFailure,
    SetOrganizationConfigurationRequest: SetOrganizationConfigurationRequest,
    SetOrganizationConfigurationSuccess: SetOrganizationConfigurationSuccess,
    SetOrganizationConfigurationFailure: SetOrganizationConfigurationFailure,
    ChangeOrganizationsRequest: ChangeOrganizationsRequest,
    GetCurrentOrganizationRequest: GetCurrentOrganizationRequest,
    GetCurrentOrganizationSuccess: GetCurrentOrganizationSuccess,
    GetCurrentOrganizationFailure: GetCurrentOrganizationFailure,
    GetOrganizationAdminInfoRequest: GetOrganizationAdminInfoRequest,
    GetOrganizationAdminInfoSuccess: GetOrganizationAdminInfoSuccess,
    GetOrganizationAdminInfoFailure: GetOrganizationAdminInfoFailure,
    GetCostControlExcludeActualCostSocialExpensesRequest: GetCostControlExcludeActualCostSocialExpensesRequest,
    GetCostControlExcludeActualCostSocialExpensesSuccess: GetCostControlExcludeActualCostSocialExpensesSuccess,
    GetCostControlExcludeActualCostSocialExpensesFailure: GetCostControlExcludeActualCostSocialExpensesFailure,
    SetCostControlExcludeActualCostSocialExpenses: SetCostControlExcludeActualCostSocialExpenses,
    GetLogsRequest: GetLogsRequest,
    GetLogsSuccess: GetLogsSuccess,
    GetLogsFailure: GetLogsFailure,
    GetImportInvoicesRequest: GetImportInvoicesRequest,
    GetImportInvoicesSuccess: GetImportInvoicesSuccess,
    GetImportInvoicesError: GetImportInvoicesError,
    GetImportSalesInvoicesRequest: GetImportSalesInvoicesRequest,
    GetImportSalesInvoicesSuccess: GetImportSalesInvoicesSuccess,
    GetImportSalesInvoicesError: GetImportSalesInvoicesError,
    AllocateToProjectRequest: AllocateToProjectRequest,
    FetchDataFromIntegrationRequest: FetchDataFromIntegrationRequest,
    FetchDataFromIntegrationError: FetchDataFromIntegrationError,
    MigrateSelectedInvoicesRequest: MigrateSelectedInvoicesRequest,
    MigrateSelectedInvoicesSuccess: MigrateSelectedInvoicesSuccess,
    MigrateSelectedInvoicesError: MigrateSelectedInvoicesError,
    AddUsersRequest: AddUsersRequest,
    AddUsersSuccess: AddUsersSuccess,
    AddUsersError: AddUsersError,
    SetUserSSOLogin: SetUserSSOLogin,
    OrgAddUsersRequest: OrgAddUsersRequest,
    OrgAddUsersSuccess: OrgAddUsersSuccess,
    OrgAddUsersError: OrgAddUsersError,
    SetUserOrganizationsRequest: SetUserOrganizationsRequest,
    SetUserRolesRequest: SetUserRolesRequest,
    SetUserRolesSuccess: SetUserRolesSuccess,
    SetUserRolesError: SetUserRolesError,
    GetSuperAdminUsersRequest: GetSuperAdminUsersRequest,
    GetSuperAdminUsersSuccess: GetSuperAdminUsersSuccess,
    GetSuperAdminUsersError: GetSuperAdminUsersError,
    SetSuperadmin: SetSuperadmin,
    SetSuperadminSuccess: SetSuperadminSuccess,
    SetSuperadminFailure: SetSuperadminFailure,
    GetRoleAssignmentsRequest: GetRoleAssignmentsRequest,
    GetRoleAssignmentsSuccess: GetRoleAssignmentsSuccess,
    GetRoleAssignmentsError: GetRoleAssignmentsError,
    GetOrganizationUsersRequest: GetOrganizationUsersRequest,
    GetOrganizationUsersSuccess: GetOrganizationUsersSuccess,
    GetOrganizationUsersError: GetOrganizationUsersError,
    OrgRemoveUsersRequest: OrgRemoveUsersRequest,
    OrgRemoveUsersSuccess: OrgRemoveUsersSuccess,
    OrgRemoveUsersError: OrgRemoveUsersError,
    DeleteSuperAdminUsersRequest: DeleteSuperAdminUsersRequest,
    DeleteUsersSuccess: DeleteUsersSuccess,
    DeleteUsersError: DeleteUsersError,
    UpdateCostControlSocialExpensesAction: UpdateCostControlSocialExpensesAction,
    UpdateCostControlSocialExpensesSuccess: UpdateCostControlSocialExpensesSuccess,
    UpdateCostControlSocialExpensesFailure: UpdateCostControlSocialExpensesFailure,
    GetUserRolesRequest: GetUserRolesRequest,
    GetUserRolesSuccess: GetUserRolesSuccess,
    GetUserRolesError: GetUserRolesError,
    UpdateUserRoleRequest: UpdateUserRoleRequest,
    UpdateUserRoleSuccess: UpdateUserRoleSuccess,
    UpdateUserRoleError: UpdateUserRoleError,
    AddUserRoleRequest: AddUserRoleRequest,
    AddUserRoleSuccess: AddUserRoleSuccess,
    AddUserRoleError: AddUserRoleError,
    DeleteUserRoleRequest: DeleteUserRoleRequest,
    DeleteUserRoleSuccess: DeleteUserRoleSuccess,
    DeleteUserRoleError: DeleteUserRoleError,
    AddOrganizationRequest: AddOrganizationRequest,
    AddOrganizationSuccess: AddOrganizationSuccess,
    AddOrganizationError: AddOrganizationError,
    ElementActionRequest: ElementActionRequest,
    ElementActionSuccess: ElementActionSuccess,
    ElementActionFailure: ElementActionFailure,
    SubprojectActionRequest: SubprojectActionRequest,
    SubprojectActionSuccess: SubprojectActionSuccess,
    SubprojectActionFailure: SubprojectActionFailure,
    ProjectClassificationClassActionRequest: ProjectClassificationClassActionRequest,
    ProjectClassificationClassActionSuccess: ProjectClassificationClassActionSuccess,
    ProjectClassificationClassActionFailure: ProjectClassificationClassActionFailure,
    LocationActionRequest: LocationActionRequest,
    LocationActionSuccess: LocationActionSuccess,
    LocationActionFailure: LocationActionFailure,
    PublishToReportingRequest: PublishToReportingRequest,
    PublishToReportingActionSuccess: PublishToReportingActionSuccess,
    PublishToReportingActionFailure: PublishToReportingActionFailure,
    UnpublishReportingRequest: UnpublishReportingRequest,
    UnpublishReportingActionSuccess: UnpublishReportingActionSuccess,
    UnpublishReportingActionFailure: UnpublishReportingActionFailure,
    ResourcePriceAdjustmentRequest: ResourcePriceAdjustmentRequest,
    ResourcePriceAdjustmentSuccess: ResourcePriceAdjustmentSuccess,
    ResourcePriceAdjustmentFailure: ResourcePriceAdjustmentFailure,
    ClassificationGroupActionRequest: ClassificationGroupActionRequest,
    ClassificationGroupActionSuccess: ClassificationGroupActionSuccess,
    ClassificationGroupActionFailure: ClassificationGroupActionFailure,
    GetVATExclusionCostGroupsRequest: GetVATExclusionCostGroupsRequest,
    GetVATExclusionCostGroupsSuccess: GetVATExclusionCostGroupsSuccess,
    GetVATExclusionCostGroupsFailure: GetVATExclusionCostGroupsFailure,
    VATExclusionCostGroupsActionRequest: VATExclusionCostGroupsActionRequest,
    VATExclusionCostGroupsActionSuccess: VATExclusionCostGroupsActionSuccess,
    VATExclusionCostGroupsActionFailure: VATExclusionCostGroupsActionFailure,
    ResetCostGroupTargetItemsRequest: ResetCostGroupTargetItemsRequest,
    ResetCostGroupTargetItemsSuccess: ResetCostGroupTargetItemsSuccess,
    ResetCostGroupTargetItemsFailure: ResetCostGroupTargetItemsFailure,
    OrgAddressAreaRequest: OrgAddressAreaRequest,
    OrgAddressAreaSuccess: OrgAddressAreaSuccess,
    OrgAddressAreaFailure: OrgAddressAreaFailure,
    OrgCostClassRequest: OrgCostClassRequest,
    OrgCostClassSuccess: OrgCostClassSuccess,
    OrgCostClassFailure: OrgCostClassFailure,
    CopyResourcesToClipboard: CopyResourcesToClipboard,
    CopyComponentsToClipboard: CopyComponentsToClipboard,
    TickCounter: TickCounter,
    WorkerRequestAction: WorkerRequestAction,
    WorkerResponseAction: WorkerResponseAction
};
