import { useAtom } from "jotai";
import { useEffect } from "react";
import { ProjectId } from "ts-bindings/ProjectId";
import {
  workerStateRequestParams,
  stateNodeAtoms,
  UnAtom,
} from "../utils/state";
import { sendWorkerRequest } from "../utils/stateUtils";

/**
 * React hook for extracting worker data from the app state by key.
 *
 * In addition to reading from state, this hook will ask to set the project_id
 * for any dependencies of the requested state.
 *
 * @param key Key of the state node we wish to access
 * @param projectId Optionally update ProjectId for dependencies. Usually
 * you want to pass this.
 * @returns Type of data that was requested
 */
export const useWorkerState = <K extends keyof typeof stateNodeAtoms>(
  key: K,
  projectId?: ProjectId
): UnAtom<typeof stateNodeAtoms[K]> => {
  const atom = stateNodeAtoms[key];

  const [state] = useAtom(atom);

  // During first invocation of this hook (component mount) we set project_id
  // for any parameter dependencies higher up in the state tree. This causes any
  // dependencies to be fetched with the correct project_id, and eventually
  // causes the state node we are interested in to be calculated and available
  // for consumption.
  useEffect(() => {
    if (projectId) {
      sendWorkerRequest({
        type: "SetRequestParamsOfDeps",
        value: { state_key: key, request_params: projectId, strategy: null },
      });
    }
  }, [key, projectId]);

  const prevProjectId = workerStateRequestParams[key];
  const isInvalidated = projectId !== undefined && prevProjectId !== projectId;

  if (isInvalidated) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return null as any;
  }

  return state;
};
