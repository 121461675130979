import { Organizations } from "./Organizations";
import "./style.css";
import { Icon, IconLogoutV2, IconRocket, IconSettings } from "@tocoman/ui";
import { useTranslation } from "react-i18next";
import { UserDataWithPermissions } from "../../../server-ts/user/UserRouter";
import { Auth } from "aws-amplify";
import { LanguageSelector } from "../../components/LanguageSelector";
import { useFeatureEnabled } from "../../split/useFeatureEnabled";
import { FeatureFlags } from "../../split/FeatureFlags";

type UserMenuProps = {
  userData: UserDataWithPermissions | undefined;
};

export const extractNameFromEmail = (email: string) => {
  const [firstPart, _] = email.split("@");
  if (firstPart.includes(".")) {
    const [firstName, lastName] = firstPart.split(".");
    const initials = `${firstName.charAt(0)}${lastName.charAt(
      0
    )}`.toUpperCase();

    return [`${firstName} ${lastName}`, initials];
  }
  const initials = `${email.charAt(0)}${email.charAt(1)}`.toUpperCase();

  return [email, initials];
};
export const UserMenu = ({ userData }: UserMenuProps) => {
  const { t } = useTranslation("navigation", { keyPrefix: "user" });

  const languageSelectionEnabled = useFeatureEnabled(
    FeatureFlags.LANGUAGE_SELECTION
  );
  const [username, initials] = extractNameFromEmail(
    userData?.userData.email ?? ""
  );

  const isAdmin =
    (userData?.permissions.isAdmin || userData?.userData.isSuperAdmin) ?? false;

  const logout = async () => {
    return Auth.signOut().then(function () {
      window.location.href = "/";
    });
  };

  return (
    <div className={"flex flex-col gap-2"}>
      <div className={"flex items-center gap-4"}>
        <div className="avatar">
          <div className="round-icon">{initials}</div>
        </div>
        <div>
          <p className={"text-[16px] font-[600]"}>{username}</p>
          <p className={"text-[12px] font-[400]"}>{userData?.userData.email}</p>
        </div>
      </div>
      <hr className={"border-grey-hover mt-2"} />
      <Organizations userData={userData} />
      <hr className={"border-grey-hover mb-2"} />
      <div className={"flex flex-col"}>
        {languageSelectionEnabled && <LanguageSelector t={t} />}
        {isAdmin && (
          <button
            className={"flex gap-4 text-[16px] nav-popover-button"}
            onClick={() => (window.location.href = "/new/settings/users")}
          >
            <Icon
              icon={IconSettings}
              scale={1.5}
              className={"text-[rgba(25, 36, 52, 0.5)]"}
            />
            <p>{t("settings")}</p>
          </button>
        )}
        <button
          className={"flex gap-4 text-[16px] nav-popover-button"}
          onClick={() =>
            window.open(
              "https://www.tocoman.fi/laskenta/julkaisutiedotteet",
              "_blank"
            )
          }
        >
          <Icon
            icon={IconRocket}
            scale={1.5}
            className={"text-[rgba(25, 36, 52, 0.5)]"}
          />
          <p>{t("announcements")}</p>
        </button>
        <button
          className={"flex gap-4 text-[16px] nav-popover-button"}
          onClick={logout}
        >
          <Icon
            icon={IconLogoutV2}
            scale={1.5}
            className={"text-[rgba(25, 36, 52, 0.5)]"}
          />
          <p>{t("logout")}</p>
        </button>
      </div>
    </div>
  );
};
