import { Checkbox, Chip, Input } from "@tocoman/ui";
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";

type SelectOrganizationProps = {
  organizations: string[];
  value?: string[];
  onChange?: (selectedOrganizations: string[]) => void;
};

export function SelectOrganization({
  organizations,
  value,
  onChange,
}: SelectOrganizationProps) {
  const { t } = useTranslation("superAdmin");
  const [modalSearch, setModalSearch] = useState("");

  const toggleUserOrganization = useCallback(
    (org: string) => {
      if (!value) {
        return [org];
      }

      if (value.includes(org)) {
        return value.filter((item) => item !== org);
      }

      return [...value, org];
    },
    [value]
  );

  return (
    <>
      <div>
        <Input
          placeholder={t`searchOrganization`}
          className={"pb-2"}
          onChange={(e) => setModalSearch(e.target.value)}
        />
        <div className={"max-h-[120px] flex flex-col overflow-auto"}>
          {organizations
            .filter((org) =>
              org.toLocaleLowerCase().includes(modalSearch.toLocaleLowerCase())
            )
            .map((org) => {
              return (
                <Checkbox
                  className={"mb-1"}
                  key={org}
                  checked={value?.includes(org)}
                  label={org}
                  setChecked={() => onChange?.(toggleUserOrganization(org))}
                  testId={`${org}-checkbox`}
                />
              );
            })}
        </div>
      </div>
      <div className={"max-h-[150px] overflow-auto"}>
        {value?.map((org) => {
          return (
            org !== "" && (
              <Chip
                label={`${org} x`}
                key={org}
                onClick={() => onChange?.(toggleUserOrganization(org))}
                className={"m-2"}
                testId={`${org}-chip`}
              />
            )
          );
        })}
      </div>
    </>
  );
}
