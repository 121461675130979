// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Actions = require("../Actions/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Effect = require("../Effect/index.js");
var FRP_Event = require("../FRP.Event/index.js");
var keyboardDriver = function (dispatch) {
    return function (v) {
        return Data_Functor.voidRight(Effect.functorEffect)(Data_Monoid.mempty(FRP_Event.monoidEvent(Data_Monoid.monoidUnit)))($foreign.onWindowKeyboard(function (keyCode) {
            return Control_Applicative.when(Effect.applicativeEffect)(keyCode === 27)(dispatch(new Actions.DismissAlert({
                mId: Data_Maybe.Nothing.value
            })));
        }));
    };
};
module.exports = {
    keyboardDriver: keyboardDriver
};
