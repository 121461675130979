// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Kishimen = require("../Kishimen/index.js");
var ReadParam = require("../ReadParam/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var ProjectClassificationClass = function (x) {
    return x;
};
var OrgClassificationData = function (x) {
    return x;
};
var OrgClassificationCode = function (x) {
    return x;
};
var OrgClassificationClassId = function (x) {
    return x;
};
var OrgClassificationClass = function (x) {
    return x;
};
var EditProjectClassificationClass = (function () {
    function EditProjectClassificationClass(value0) {
        this.value0 = value0;
    };
    EditProjectClassificationClass.create = function (value0) {
        return new EditProjectClassificationClass(value0);
    };
    return EditProjectClassificationClass;
})();
var AddProjectClassificationClass = (function () {
    function AddProjectClassificationClass(value0) {
        this.value0 = value0;
    };
    AddProjectClassificationClass.create = function (value0) {
        return new AddProjectClassificationClass(value0);
    };
    return AddProjectClassificationClass;
})();
var DeleteProjectClassificationClass = (function () {
    function DeleteProjectClassificationClass(value0) {
        this.value0 = value0;
    };
    DeleteProjectClassificationClass.create = function (value0) {
        return new DeleteProjectClassificationClass(value0);
    };
    return DeleteProjectClassificationClass;
})();
var writeParamOrgClassificationId = ReadParam.writeParamInt;
var writeParamOrgClassificationCode = ReadParam.writeParamString;
var tofuJSONProjectClassificationClass = TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "code";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "description";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Newtypes.tofuJSONProjectClassificationClassId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "code";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "description";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Newtypes.tofuJSONProjectClassificationClassId)(TofuJSON.nilWriteForeignFields)()()())()()())()()());
var tofuJSONOrgClassificationId = TofuJSON.readInt;
var tofuJSONOrgClassificationData = {
    writeImpl: function (x) {
        return TofuJSON.writeImpl(tofuJSONOrgClassificationData)(x);
    },
    readImpl: function (x) {
        return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(TofuJSON.readImpl(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "children";
            }
        })(TofuJSON.readArray(TofuJSON.tofuJSONForeign))(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "code";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "description";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "total";
            }
        })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "children";
            }
        })(TofuJSON.readArray(TofuJSON.tofuJSONForeign))(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "code";
            }
        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "description";
            }
        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "total";
            }
        })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))(x))(function (f) {
            return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))((function () {
                var $7 = Data_Array["null"](f.children);
                if ($7) {
                    return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))([  ]);
                };
                return Data_Traversable.traverse(Data_Traversable.traversableArray)(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(TofuJSON.readImpl(tofuJSONOrgClassificationData))(f.children);
            })())(function (children) {
                return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))({
                    code: f.code,
                    description: f.description,
                    total: f.total,
                    children: children
                });
            });
        });
    }
};
var tofuJSONOrgClassificationCode = TofuJSON.readString;
var tofuJSONOrgClassificationClass = TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "code";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "description";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "id";
    }
})(tofuJSONOrgClassificationId)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "orgClassificationCode";
    }
})(tofuJSONOrgClassificationCode)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "code";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "description";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "id";
    }
})(tofuJSONOrgClassificationId)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "orgClassificationCode";
    }
})(tofuJSONOrgClassificationCode)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()());
var showProjectClassificationClass = Data_Show.showRecord()(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "code";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "description";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "id";
    }
})(Data_Show.showRecordFieldsNil)(Types_Newtypes.showProjectClassificationClassId))(Data_Show.showString))(Data_Show.showString));
var showOrgClassificationId = Data_Show.showInt;
var showOrgClassificationCode = Data_Show.showString;
var showOrgClassificationClass = Data_Show.showRecord()(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "code";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "description";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "id";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "orgClassificationCode";
    }
})(Data_Show.showRecordFieldsNil)(showOrgClassificationCode))(showOrgClassificationId))(Data_Show.showString))(Data_Show.showString));
var readParamOrgClassificationId = ReadParam.intReadParam;
var readParamOrgClassificationCode = ReadParam.stringReadParam;
var ordOrgClassificationId = Data_Ord.ordInt;
var newtypeOrgClassificationId = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeOrgClassificationData = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeOrgClassificationCode = {
    Coercible0: function () {
        return undefined;
    }
};
var genericProjectClassificationClassAction = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new EditProjectClassificationClass(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new AddProjectClassificationClass(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
            return new DeleteProjectClassificationClass(x.value0.value0);
        };
        throw new Error("Failed pattern match at Types.Classification (line 88, column 1 - line 88, column 102): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof EditProjectClassificationClass) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof AddProjectClassificationClass) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof DeleteProjectClassificationClass) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0));
        };
        throw new Error("Failed pattern match at Types.Classification (line 88, column 1 - line 88, column 102): " + [ x.constructor.name ]);
    }
};
var tofuJSONProjectClassificationClassAction = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddProjectClassificationClass";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DeleteProjectClassificationClass";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "projectClassificationClassId";
        }
    })(Types_Newtypes.tofuJSONProjectClassificationClassId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "projectClassificationClassId";
        }
    })(Types_Newtypes.tofuJSONProjectClassificationClassId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditProjectClassificationClass";
        }
    })(tofuJSONProjectClassificationClass)()(TofuJSON.readVariantNil))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddProjectClassificationClass";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DeleteProjectClassificationClass";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "projectClassificationClassId";
        }
    })(Types_Newtypes.tofuJSONProjectClassificationClassId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "projectClassificationClassId";
        }
    })(Types_Newtypes.tofuJSONProjectClassificationClassId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditProjectClassificationClass";
        }
    })(tofuJSONProjectClassificationClass)()(TofuJSON.nilWriteForeignVariant))))(genericProjectClassificationClassAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditProjectClassificationClass";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddProjectClassificationClass";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DeleteProjectClassificationClass";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddProjectClassificationClass";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DeleteProjectClassificationClass";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditProjectClassificationClass";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DeleteProjectClassificationClass";
        }
    }))({
        reflectSymbol: function () {
            return "AddProjectClassificationClass";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericProjectClassificationClassAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditProjectClassificationClass";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddProjectClassificationClass";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DeleteProjectClassificationClass";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddProjectClassificationClass";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DeleteProjectClassificationClass";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditProjectClassificationClass";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DeleteProjectClassificationClass";
        }
    }))({
        reflectSymbol: function () {
            return "AddProjectClassificationClass";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddProjectClassificationClass";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DeleteProjectClassificationClass";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "projectClassificationClassId";
        }
    })(Types_Newtypes.tofuJSONProjectClassificationClassId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "projectClassificationClassId";
        }
    })(Types_Newtypes.tofuJSONProjectClassificationClassId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditProjectClassificationClass";
        }
    })(tofuJSONProjectClassificationClass)()(TofuJSON.readVariantNil))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddProjectClassificationClass";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DeleteProjectClassificationClass";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "projectClassificationClassId";
        }
    })(Types_Newtypes.tofuJSONProjectClassificationClassId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "projectClassificationClassId";
        }
    })(Types_Newtypes.tofuJSONProjectClassificationClassId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditProjectClassificationClass";
        }
    })(tofuJSONProjectClassificationClass)()(TofuJSON.nilWriteForeignVariant))))
};
var eqProjectClassificationClass = Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Newtypes.eqProjectClassificationClassId))()({
    reflectSymbol: function () {
        return "description";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "code";
    }
})(Data_Eq.eqString));
var eqOrgClassificationId = Data_Eq.eqInt;
var eqOrgClassificationData = {
    eq: function (v) {
        return function (v1) {
            return Data_Eq.eq(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "total";
                }
            })(Data_Eq.eqNumber))()({
                reflectSymbol: function () {
                    return "description";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "code";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "children";
                }
            })(Data_Eq.eqArray(eqOrgClassificationData))))(v)(v1);
        };
    }
};
var eqOrgClassificationCode = Data_Eq.eqString;
var eqOrgClassificationClass = Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "orgClassificationCode";
    }
})(eqOrgClassificationCode))()({
    reflectSymbol: function () {
        return "id";
    }
})(eqOrgClassificationId))()({
    reflectSymbol: function () {
        return "description";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "code";
    }
})(Data_Eq.eqString));
module.exports = {
    OrgClassificationClassId: OrgClassificationClassId,
    OrgClassificationCode: OrgClassificationCode,
    OrgClassificationClass: OrgClassificationClass,
    OrgClassificationData: OrgClassificationData,
    ProjectClassificationClass: ProjectClassificationClass,
    EditProjectClassificationClass: EditProjectClassificationClass,
    AddProjectClassificationClass: AddProjectClassificationClass,
    DeleteProjectClassificationClass: DeleteProjectClassificationClass,
    newtypeOrgClassificationData: newtypeOrgClassificationData,
    eqOrgClassificationData: eqOrgClassificationData,
    tofuJSONOrgClassificationData: tofuJSONOrgClassificationData,
    newtypeOrgClassificationCode: newtypeOrgClassificationCode,
    readParamOrgClassificationCode: readParamOrgClassificationCode,
    writeParamOrgClassificationCode: writeParamOrgClassificationCode,
    tofuJSONOrgClassificationCode: tofuJSONOrgClassificationCode,
    showOrgClassificationCode: showOrgClassificationCode,
    eqOrgClassificationCode: eqOrgClassificationCode,
    newtypeOrgClassificationId: newtypeOrgClassificationId,
    eqOrgClassificationId: eqOrgClassificationId,
    ordOrgClassificationId: ordOrgClassificationId,
    readParamOrgClassificationId: readParamOrgClassificationId,
    writeParamOrgClassificationId: writeParamOrgClassificationId,
    tofuJSONOrgClassificationId: tofuJSONOrgClassificationId,
    showOrgClassificationId: showOrgClassificationId,
    tofuJSONOrgClassificationClass: tofuJSONOrgClassificationClass,
    showOrgClassificationClass: showOrgClassificationClass,
    eqOrgClassificationClass: eqOrgClassificationClass,
    tofuJSONProjectClassificationClass: tofuJSONProjectClassificationClass,
    showProjectClassificationClass: showProjectClassificationClass,
    eqProjectClassificationClass: eqProjectClassificationClass,
    genericProjectClassificationClassAction: genericProjectClassificationClassAction,
    tofuJSONProjectClassificationClassAction: tofuJSONProjectClassificationClassAction
};
