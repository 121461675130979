// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Effect = require("../Effect/index.js");
var ReactQuery = require("../ReactQuery/index.js");
var Types_Organization = require("../Types.Organization/index.js");
var UseCurrentOrganization = require("../UseCurrentOrganization/index.js");
var Elements = (function () {
    function Elements() {

    };
    Elements.value = new Elements();
    return Elements;
})();
var Subprojects = (function () {
    function Subprojects() {

    };
    Subprojects.value = new Subprojects();
    return Subprojects;
})();
var Locations = (function () {
    function Locations() {

    };
    Locations.value = new Locations();
    return Locations;
})();
var ComponentGroups = (function () {
    function ComponentGroups() {

    };
    ComponentGroups.value = new ComponentGroups();
    return ComponentGroups;
})();
var ProjectGroups = (function () {
    function ProjectGroups() {

    };
    ProjectGroups.value = new ProjectGroups();
    return ProjectGroups;
})();
var CostGroups = (function () {
    function CostGroups() {

    };
    CostGroups.value = new CostGroups();
    return CostGroups;
})();
var Printing = (function () {
    function Printing() {

    };
    Printing.value = new Printing();
    return Printing;
})();
var UserRoles = (function () {
    function UserRoles() {

    };
    UserRoles.value = new UserRoles();
    return UserRoles;
})();
var ProductionPlanning = (function () {
    function ProductionPlanning() {

    };
    ProductionPlanning.value = new ProductionPlanning();
    return ProductionPlanning;
})();
var PublishToReporting = (function () {
    function PublishToReporting() {

    };
    PublishToReporting.value = new PublishToReporting();
    return PublishToReporting;
})();
var Admin = (function () {
    function Admin() {

    };
    Admin.value = new Admin();
    return Admin;
})();
var PremiumOfferPage = (function () {
    function PremiumOfferPage() {

    };
    PremiumOfferPage.value = new PremiumOfferPage();
    return PremiumOfferPage;
})();
var readLicenseFeature = function (v) {
    if (v === "elements") {
        return new Data_Maybe.Just(Elements.value);
    };
    if (v === "subprojects") {
        return new Data_Maybe.Just(Subprojects.value);
    };
    if (v === "locations") {
        return new Data_Maybe.Just(Locations.value);
    };
    if (v === "componentGroups") {
        return new Data_Maybe.Just(ComponentGroups.value);
    };
    if (v === "projectGroups") {
        return new Data_Maybe.Just(ProjectGroups.value);
    };
    if (v === "costGroups") {
        return new Data_Maybe.Just(CostGroups.value);
    };
    if (v === "printing") {
        return new Data_Maybe.Just(Printing.value);
    };
    if (v === "userRoles") {
        return new Data_Maybe.Just(UserRoles.value);
    };
    if (v === "productionPlanning") {
        return new Data_Maybe.Just(ProductionPlanning.value);
    };
    if (v === "publishToReporting") {
        return new Data_Maybe.Just(PublishToReporting.value);
    };
    if (v === "admin") {
        return new Data_Maybe.Just(Admin.value);
    };
    if (v === "premiumOfferPage") {
        return new Data_Maybe.Just(PremiumOfferPage.value);
    };
    return Data_Maybe.Nothing.value;
};
var canUseFeature = function (v) {
    return function (v1) {
        if (v1 instanceof Types_Organization.Premium) {
            return true;
        };
        if (v1 instanceof Types_Organization.Pro) {
            return false;
        };
        throw new Error("Failed pattern match at UseLicenseFeature (line 32, column 1 - line 32, column 51): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var useLicenseFeature = function (feature) {
    return function __do() {
        var planResult = UseCurrentOrganization.useOrganizationLicensePlan();
        var maybePlan = ReactQuery.toMaybe(planResult);
        var maybeFeatureAvailable = Data_Functor.map(Data_Maybe.functorMaybe)(canUseFeature(feature))(maybePlan);
        return Data_Maybe.fromMaybe(false)(maybeFeatureAvailable);
    };
};
var useLicenseFeatureExt = function (feature) {
    return Data_Maybe.fromMaybe(Control_Applicative.pure(Effect.applicativeEffect)(false))(Data_Functor.map(Data_Maybe.functorMaybe)(useLicenseFeature)(readLicenseFeature(feature)));
};
module.exports = {
    Elements: Elements,
    Subprojects: Subprojects,
    Locations: Locations,
    ComponentGroups: ComponentGroups,
    ProjectGroups: ProjectGroups,
    CostGroups: CostGroups,
    Printing: Printing,
    UserRoles: UserRoles,
    ProductionPlanning: ProductionPlanning,
    PublishToReporting: PublishToReporting,
    Admin: Admin,
    PremiumOfferPage: PremiumOfferPage,
    canUseFeature: canUseFeature,
    useLicenseFeature: useLicenseFeature,
    readLicenseFeature: readLicenseFeature,
    useLicenseFeatureExt: useLicenseFeatureExt
};
