// Generated by purs version 0.14.5
"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var RemoteDataUtils = require("../RemoteDataUtils/index.js");
var Types_Project = require("../Types.Project/index.js");
var getFetchedItems = function (v) {
    if (v instanceof RemoteDataUtils.Fetched) {
        return v.value0;
    };
    return [  ];
};
var getFetchedItemsProps = function (stateProperty) {
    return {
        items: getFetchedItems(stateProperty),
        loading: RemoteDataUtils.isLoading(stateProperty),
        needsFetching: RemoteDataUtils.needsFetchingGeneral(stateProperty)
    };
};
var getFetchedItem = function (v) {
    if (v instanceof RemoteDataUtils.Fetched) {
        return new Data_Maybe.Just(v.value0);
    };
    return Data_Maybe.Nothing.value;
};
var getFetchedValueProps = function (stateProperty) {
    return {
        value: getFetchedItem(stateProperty),
        loading: RemoteDataUtils.isLoading(stateProperty),
        needsFetching: RemoteDataUtils.needsFetchingGeneral(stateProperty)
    };
};
var getFetchedValueWithDefault = function (defaultValue) {
    return function (stateProperty) {
        return {
            value: Data_Maybe.fromMaybe(defaultValue)(getFetchedItem(stateProperty)),
            loading: RemoteDataUtils.isLoading(stateProperty),
            needsFetching: RemoteDataUtils.needsFetchingGeneral(stateProperty)
        };
    };
};
var getRemoteItemsPropsByProjectId = function (defaultValue) {
    return function (projectId) {
        return function (stateProperty) {
            var items = (function () {
                var v = getFetchedItem(stateProperty);
                if (v instanceof Data_Maybe.Just && Data_Eq.eq(Types_Project.eqProjectId)(v.value0.projectId)(projectId)) {
                    return v.value0.items;
                };
                if (v instanceof Data_Maybe.Just) {
                    return defaultValue;
                };
                if (v instanceof Data_Maybe.Nothing) {
                    return defaultValue;
                };
                throw new Error("Failed pattern match at StateUtils.Utils (line 54, column 13 - line 57, column 30): " + [ v.constructor.name ]);
            })();
            return {
                items: items,
                loading: RemoteDataUtils.isLoading(stateProperty),
                needsFetching: RemoteDataUtils.needsFetchingByProjectId(projectId)(stateProperty)
            };
        };
    };
};
var findProject = function (mId) {
    return function (projects) {
        return Control_Bind.bind(Data_Maybe.bindMaybe)(mId)(function (id) {
            return Control_Bind.bind(Data_Maybe.bindMaybe)(getFetchedItem(projects))(function (fetched) {
                return Data_Array.find(function (x) {
                    return Data_Eq.eq(Types_Project.eqProjectId)(x.id)(id);
                })(fetched);
            });
        });
    };
};
var getCurrentProject = function (mProjectId) {
    return function (state) {
        var $11 = RemoteDataUtils.isFetched(state.portfolioReport);
        if ($11) {
            return findProject(mProjectId)(state.portfolioReport);
        };
        return Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
            return v.project;
        })((getFetchedValueProps(state.selectedProject)).value);
    };
};
module.exports = {
    getFetchedItems: getFetchedItems,
    getFetchedItem: getFetchedItem,
    getFetchedItemsProps: getFetchedItemsProps,
    getFetchedValueProps: getFetchedValueProps,
    getFetchedValueWithDefault: getFetchedValueWithDefault,
    getRemoteItemsPropsByProjectId: getRemoteItemsPropsByProjectId,
    findProject: findProject,
    getCurrentProject: getCurrentProject
};
