// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Calculation_Income = require("../Calculation.Income/index.js");
var Calculation_Utils = require("../Calculation.Utils/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Controls = require("../Controls/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_JSDate = require("../Data.JSDate/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Formatters = require("../Formatters/index.js");
var Locale = require("../Locale/index.js");
var NarrowLayout = require("../NarrowLayout/index.js");
var R200PrepareTableData = require("../R200PrepareTableData/index.js");
var R200Table = require("../R200Table/index.js");
var R200TableGroup_Styles = require("../R200TableGroup.Styles/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoDropdown = require("../TacoDropdown/index.js");
var TacoDropdown_Types = require("../TacoDropdown.Types/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoLoader = require("../TacoLoader/index.js");
var TacoTable_Cells = require("../TacoTable.Cells/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_FrontEndRoutes = require("../Types.FrontEndRoutes/index.js");
var Types_JSDateString = require("../Types.JSDateString/index.js");
var UseFetch = require("../UseFetch/index.js");
var UseLocalStorage = require("../UseLocalStorage/index.js");
var Utils = require("../Utils/index.js");
var toBoolean = function (x) {
    if (x instanceof Calculation_Income.ShowUnallocatedSalesInvoices) {
        return true;
    };
    if (x instanceof Calculation_Income.HideUnallocatedSalesInvoices) {
        return false;
    };
    throw new Error("Failed pattern match at R200Container (line 352, column 15 - line 354, column 40): " + [ x.constructor.name ]);
};
var localStorageKeys = {
    showCostGroupCodeTwoLevelNesting: "ui:json:r200:show_cost_group_code_two_level_nesting",
    showActualIncomeBasedOnApprovedIncomeItemsOnly: "ui:json:r200:show_actual_income_based_on_approved_incomeItems_only",
    showCompletionPercentageColumn: "ui:json:r200:show_completion_percentage_column",
    showEstimateRemainingColumn: "ui:json:r200:show_estimate_remaining_column",
    hideEmptyCostGroups: "ui:json:r200:hide_empty_cost_groups",
    includeSocialExpensesInCostGroups: "ui:json:r200:includeSocialExpensesInCostGroups",
    showSumOfUnallocatedSalesInvoices: "ui:json:r200:showSumOfUnallocatedSalesInvoices",
    disableSocialCostToActual: "ui:json:cg:disable_social_cost_to_actual"
};
var component = TofuHooks.mkHookComponent("R200Container")(function (v) {
    return function __do() {
        var v1 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)(localStorageKeys.showActualIncomeBasedOnApprovedIncomeItemsOnly)(false)(false)();
        var v2 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)(localStorageKeys.showCompletionPercentageColumn)(false)(false)();
        var v3 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)(localStorageKeys.showEstimateRemainingColumn)(false)(false)();
        var v4 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)(localStorageKeys.hideEmptyCostGroups)(false)(false)();
        var v5 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)(localStorageKeys.includeSocialExpensesInCostGroups)(false)(false)();
        var v6 = UseLocalStorage.useLocalStorageJSON(Calculation_Income.tofuJSONShowUnallocatedSalesInvoices)(localStorageKeys.showSumOfUnallocatedSalesInvoices)(Calculation_Income.ShowUnallocatedSalesInvoices.value)(false)();
        var v7 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v7) {
            return v7.costControlExcludeActualCostSocialExpenses;
        })(Actions.GetCostControlExcludeActualCostSocialExpensesRequest.value)();
        var v8 = TofuHooks.useState(R200PrepareTableData.DontNest.value)();
        var v9 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var v10 = UseFetch.useFetchItemsByProjectId(v.projectId)(function (v10) {
            return v10.invoices;
        })(Actions.GetInvoicesRequest.create)();
        var v11 = UseFetch.useFetchItemsByProjectId(v.projectId)(function (v11) {
            return v11.incomeItems;
        })(Actions.GetIncomeItemsRequest.create)();
        var v12 = UseFetch.useFetchItemsByProjectId(v.projectId)(function (v12) {
            return v12.salesInvoices;
        })(Actions.GetSalesInvoicesRequest.create)();
        var v13 = UseFetch.useFetchItemsByProjectId(v.projectId)(function (v13) {
            return v13.costGroupInfos;
        })(Actions.CostGroupInfosRequest.create)();
        var v14 = UseFetch.useFetchValueByProjectId(v.projectId)(function (v14) {
            return v14.mainCostGroups;
        })(Actions.MainCostGroupsRequest.create)();
        var v15 = UseFetch.useFetchItemsByProjectId(v.projectId)(function (v15) {
            return v15.socialExpenses;
        })(Actions.SocialExpensesRequest.create)();
        var v16 = UseFetch.useFetchItemsByProjectId(v.projectId)(function (v16) {
            return v16.estimateItems;
        })(Actions.ProjectEstimateItemsRequest.create)();
        var v17 = UseFetch.useFetchItemsByProjectId(v.projectId)(function (v17) {
            return v17.targetItems;
        })(Actions.ProjectTargetItemsRequest.create)();
        var v18 = UseFetch.useFetchItemsByProjectId(v.projectId)(function (v18) {
            return v18.costClasses;
        })(Actions.ProjectCostClassesRequest.create)();
        var v19 = UseFetch.useFetchValueByProjectId(v.projectId)(function (v19) {
            return v19.vatExclusionCostGroups;
        })(Actions.GetVATExclusionCostGroupsRequest.create)();
        var v20 = UseFetch.useFetchValueByProjectId(v.projectId)(function (v20) {
            return v20.selectedProject;
        })(Actions.GetProjectRequest.create)();
        var appDispatch = StateHooks.useDispatch();
        var upperDateLimitInput = ReactBasicUtils["div'"]("dropdown-section-control upper-date-limit")([ TacoInput.stringInput()()(Data_Eq.eqString)({
            value: Data_Maybe.maybe("")(Types_JSDateString.extractJSDateString)(v9.value0),
            type: "date",
            clickEventHandling: TacoInput.NoHandling.value,
            required: true,
            onChange: TacoInput.changeHandler(TacoInput.inputValueString)(function (value) {
                return v9.value1(function (v21) {
                    return Data_Either.hush(Types_JSDateString.makeJSDateString(value));
                });
            })
        }) ]);
        var upperDateLimit = (function () {
            if (v9.value0 instanceof Data_Maybe.Just) {
                return Calculation_Utils.UpperDateLimit.create(Utils.lossyParseJSDateString(v9.value0.value0));
            };
            return Calculation_Utils.NoDateLimit.value;
        })();
        var upperDateLimitStatus = ReactBasicUtils["div'"]("upper-date-limit-status")(Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM.text((function () {
            if (upperDateLimit instanceof Calculation_Utils.UpperDateLimit) {
                return "Rajattu: " + Formatters.formatDateValue(upperDateLimit.value0);
            };
            if (upperDateLimit instanceof Calculation_Utils.NoDateLimit) {
                return "";
            };
            throw new Error("Failed pattern match at R200Container (line 140, column 7 - line 142, column 26): " + [ upperDateLimit.constructor.name ]);
        })())));
        var toggleUseOnlyApprovedIncomeItemsForIncome = TacoCheckbox.component()()({
            className: "use-only-approved-incomeItems-toggle",
            isChecked: v1.value0,
            onToggle: new TacoCheckbox.NoEvent(function __do() {
                v1.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))();
                return v6.value1(function (v21) {
                    if (v1.value0) {
                        return v6.value0;
                    };
                    if (!v1.value0) {
                        return Calculation_Income.HideUnallocatedSalesInvoices.value;
                    };
                    throw new Error("Failed pattern match at R200Container (line 201, column 13 - line 204, column 52): " + [ v1.value0.constructor.name ]);
                })();
            }),
            labelText: Data_Maybe.Just.create(TacoCheckbox.StringLabel.create(Locale.lookup_("report_action_toggle_actual_income_based_on_approved_incomeItems_only"))),
            testId: "use-only-approved-incomeItems-toggle"
        });
        var toggleSumOfUnallocatedSalesInvoices = TacoCheckbox.component()()({
            className: "sumOfUnallocatedSalesInvoices-toggle",
            isChecked: toBoolean(v6.value0),
            onToggle: (function () {
                if (v1.value0) {
                    return new TacoCheckbox.Disabled("Aseta 'Toteuma hyv\xe4ksyttyjen maksuerien perusteella' pois p\xe4\xe4lt\xe4 k\xe4ytt\xe4\xe4ksesi t\xe4t\xe4");
                };
                return new TacoCheckbox.NoEvent(v6.value1(function (v21) {
                    if (v6.value0 instanceof Calculation_Income.ShowUnallocatedSalesInvoices) {
                        return Calculation_Income.HideUnallocatedSalesInvoices.value;
                    };
                    if (v6.value0 instanceof Calculation_Income.HideUnallocatedSalesInvoices) {
                        return Calculation_Income.ShowUnallocatedSalesInvoices.value;
                    };
                    throw new Error("Failed pattern match at R200Container (line 262, column 66 - line 264, column 85): " + [ v6.value0.constructor.name ]);
                }));
            })(),
            labelText: Data_Maybe.Just.create(TacoCheckbox.StringLabel.create(Locale.lookup_("toggle_show_sum_of_unallocated_sales_invoices"))),
            testId: "sumOfUnallocatedSalesInvoices-toggle"
        });
        var toggleNestItemsBy3CodeDigits = TacoCheckbox.component()()({
            className: "nest-items-toggle",
            isChecked: Data_Eq.eq(R200PrepareTableData.eqTwoLevelNesting)(v8.value0)(R200PrepareTableData.NestBy3Digits.value),
            onToggle: new TacoCheckbox.NoEvent(v8.value1(function (v21) {
                if (v8.value0 instanceof R200PrepareTableData.DontNest) {
                    return R200PrepareTableData.NestBy3Digits.value;
                };
                if (v8.value0 instanceof R200PrepareTableData.NestBy3Digits) {
                    return R200PrepareTableData.DontNest.value;
                };
                throw new Error("Failed pattern match at R200Container (line 213, column 53 - line 215, column 38): " + [ v8.value0.constructor.name ]);
            })),
            labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("K\xe4yt\xe4 kaksitasoista ryhmittely\xe4")),
            testId: "nest-items-toggle"
        });
        var toggleIncludeSocialExpensesInCostGroups = TacoCheckbox.component()()({
            className: "includeSocialExpensesInCostGroups-toggle",
            isChecked: v5.value0,
            onToggle: new TacoCheckbox.NoEvent(v5.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))),
            labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("N\xe4yt\xe4 sosiaalikulut litteroilla")),
            testId: "includeSocialExpensesInCostGroups-toggle"
        });
        var toggleHideEmptyCostGroups = TacoCheckbox.component()()({
            className: "HideEmptyCostGroups-toggle",
            isChecked: v4.value0,
            onToggle: new TacoCheckbox.NoEvent(v4.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))),
            labelText: Data_Maybe.Just.create(TacoCheckbox.StringLabel.create(Locale.lookup_("toggle_hide_empty_cost_groups"))),
            testId: "HideEmptyCostGroups-toggle"
        });
        var toggleEstimateRemainingColumn = TacoCheckbox.component()()({
            className: "estimateRemaining-column-toggle",
            isChecked: v3.value0,
            onToggle: new TacoCheckbox.NoEvent(v3.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))),
            labelText: Data_Maybe.Just.create(TacoCheckbox.StringLabel.create(Locale.lookup_("toggle_estimate_remaining_column"))),
            testId: "estimateRemaining-column-toggle"
        });
        var toggleCompletionPercentageColumn = TacoCheckbox.component()()({
            className: "completionPercentage-column-toggle",
            isChecked: v2.value0,
            onToggle: new TacoCheckbox.NoEvent(v2.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))),
            labelText: Data_Maybe.Just.create(TacoCheckbox.StringLabel.create(Locale.lookup_("report_action_toggle_completion_percentage_column"))),
            testId: "completionPercentage-column-toggle"
        });
        var renameMainCostGroupsBtn = TacoButton.component()()({
            onClick: Data_Maybe.Just.create(appDispatch(new Actions.NavigateRoute(new Types_FrontEndRoutes.RenameMainCostGroupsFERoute({
                projectId: v.projectId
            })))),
            text: Locale.lookup_("rename_main_cost_groups"),
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconEditV1.value),
            align: TacoButton_Types.Left.value
        });
        var r200TableFlags = {
            displayCompletionPercentage: v2.value0,
            displayEstimateRemaining: v3.value0
        };
        var pageLoading = v11.value1 || (v10.value1 || (v13.value1 || (v14.value1 || (v18.value1 || (v16.value1 || v17.value1)))));
        var mkTableGroup = function (shouldMarginLeft) {
            return function (children) {
                var className = TacoTable_Cells.classNames([ "__R200TableGroup" ]);
                return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                    className: className,
                    css: R200TableGroup_Styles.r200TableGroupContainerStyles({
                        shouldMarginLeft: shouldMarginLeft
                    }),
                    children: children
                });
            };
        };
        var r200CostClassTables = function (r200) {
            return mkTableGroup(true)([ R200Table.mkR200CostClassTable(r200TableFlags)(r200.costClassRows) ]);
        };
        var r200CostGroupsTables = function (r200) {
            return mkTableGroup(false)([ R200Table.mkR200CostGroupUnitTable({
                displayFlags: r200TableFlags,
                rows: Data_Semigroup.append(Data_Semigroup.semigroupArray)(r200.missingCostGroupItems)(r200.costGroupsItems)
            }), React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                className: "__R200CostGroupSummaries",
                css: R200TableGroup_Styles.r200CostGroupSummariesStyles,
                children: [ R200Table.mkR200SummedItemTable(r200TableFlags)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(r200.optionalEntries)(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ r200.totalWithoutTaxes ])(r200.taxRelatedEntries))) ]
            }) ]);
        };
        var r200IncomeTable = function (r200) {
            return mkTableGroup(true)([ R200Table.mkR200IncomeTable(r200TableFlags)(r200.incomeInfoItemsRows) ]);
        };
        var r200MarginsTables = function (r200) {
            return mkTableGroup(true)([ R200Table.mkR200IncomeTotalTable(r200TableFlags)([ r200.incomeTotal ]), R200Table.mkR200SummedItemTable(r200TableFlags)([ r200.costTotal ]), R200Table.mkR200ResultMarginTable(r200TableFlags)([ r200.resultMargin ]), R200Table.mkR200ResultMarginPercentageTable(r200TableFlags)([ r200.resultMarginPercentages ]) ]);
        };
        var mProject = Data_Functor.map(Data_Maybe.functorMaybe)(function (v21) {
            return v21.project;
        })(v20.value0);
        var mR200Data = Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(mProject)(function (project) {
            return R200PrepareTableData.prepareR200Data({
                projectId: v.projectId,
                showActualIncomeBasedOnApprovedIncomeItemsOnly: v1.value0,
                showCompletionPercentageColumn: v2.value0,
                showEstimateRemainingColumn: v3.value0,
                hideEmptyCostGroups: v4.value0,
                includeSocialExpensesInCostGroups: v5.value0,
                showSumOfUnallocatedSalesInvoices: v6.value0,
                showCostGroupCodeTwoLevelNesting: v8.value0,
                upperDateLimitDate: v9.value0,
                project: project,
                costControlExcludeActualCostSocialExpenses: v7.value0,
                invoiceLines: v10.value0,
                incomeItems: v11.value0,
                salesInvoices: v12.value0,
                costGroupInfos: v13.value0,
                "mainCostGroups'": v14.value0,
                socialExpenses: v15.value0,
                estimateItems: v16.value0,
                targetItems: v17.value0,
                costClasses: v18.value0,
                "vatExclusionCostGroups'": v19.value0
            });
        });
        var downloadReportBtn = function (r200) {
            return TacoButton.component()()({
                onClick: new Data_Maybe.Just(function __do() {
                    var dateTimeString = Data_Functor.map(Effect.functorEffect)(Formatters.formatDateTimeValue)(Data_JSDate.now)();
                    var report = {
                        code: r200.project.code,
                        name: r200.project.name,
                        dateTimeString: dateTimeString,
                        incomeInfoItems: r200.incomeInfoItems,
                        costGroupsItems: r200.flattenedCostGroupsItems,
                        optionalEntries: r200.optionalEntries,
                        totalWithoutTaxes: r200.totalWithoutTaxes,
                        taxRelatedEntries: r200.taxRelatedEntries,
                        incomeTotal: r200.incomeTotal,
                        costTotal: r200.costTotal,
                        resultMargin: r200.resultMargin,
                        resultMarginPercentages: r200.resultMarginPercentages,
                        costClassResults: r200.costClassResults
                    };
                    return appDispatch(new Actions.GetReportExcelRequest({
                        report: report
                    }))();
                }),
                text: Locale.lookup_("report_download_report_excel"),
                icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconFileDownloadV1.value),
                align: TacoButton_Types.Left.value
            });
        };
        var tableControlsDropdown = TacoDropdown.uncontrolled({
            sections: [ {
                title: Data_Maybe.Just.create(Locale.lookup_("report_dropdown_actions_category__view")),
                items: [ toggleUseOnlyApprovedIncomeItemsForIncome, toggleNestItemsBy3CodeDigits, toggleCompletionPercentageColumn, toggleEstimateRemainingColumn, toggleHideEmptyCostGroups, toggleIncludeSocialExpensesInCostGroups, toggleSumOfUnallocatedSalesInvoices ]
            }, {
                title: new Data_Maybe.Just("Rajaa ennen pvm."),
                items: [ upperDateLimitInput ]
            }, {
                title: Data_Maybe.Just.create(Locale.lookup_("report_dropdown_actions_category__report")),
                items: [ renameMainCostGroupsBtn, ReactBasicUtils.maybeJSX(React_Basic.monoidJSX)(Data_Functor.map(Data_Maybe.functorMaybe)(downloadReportBtn)(mR200Data)) ]
            } ],
            align: TacoDropdown_Types.AlignRight.value,
            testId: "r200-actions-dropdown"
        });
        var tableControls = Controls.component()()({
            leftControls: [ upperDateLimitStatus ],
            rightControls: [ tableControlsDropdown ],
            bottomGutter: true
        });
        return NarrowLayout.component()()({
            children: [ tableControls, (function () {
                if (pageLoading) {
                    return TacoLoader.component()()({});
                };
                return React_Basic_DOM_Generated.div_([ ReactBasicUtils.maybeJSX(React_Basic.monoidJSX)(Data_Functor.map(Data_Maybe.functorMaybe)(r200IncomeTable)(mR200Data)), ReactBasicUtils.maybeJSX(React_Basic.monoidJSX)(Data_Functor.map(Data_Maybe.functorMaybe)(r200CostGroupsTables)(mR200Data)), ReactBasicUtils.maybeJSX(React_Basic.monoidJSX)(Data_Functor.map(Data_Maybe.functorMaybe)(r200MarginsTables)(mR200Data)), ReactBasicUtils.maybeJSX(React_Basic.monoidJSX)(Data_Functor.map(Data_Maybe.functorMaybe)(r200CostClassTables)(mR200Data)) ]);
            })() ],
            verticalPadding: true
        });
    };
});
module.exports = {
    component: component,
    localStorageKeys: localStorageKeys
};
