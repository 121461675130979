import { Button, Input, Modal } from "@tocoman/ui";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useState } from "react";
import { useWorkerAction } from "../../../hooks/useWorkerAction";
import { mkSetErrorMessage } from "../../../actions/superadmin";
import { useOnEq } from "../../../hooks/useOnEq";

type Props = {
  isOpen: boolean;
  errorMsg: string;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export const ErrorMessage = ({ isOpen, errorMsg, setOpen }: Props) => {
  const [newError, setNewError] = useState(errorMsg);

  const [status, handleSetErrorMsg] = useWorkerAction(
    "SuperAdminUpdateActionState",
    () => {
      return mkSetErrorMessage({
        outageMessage: newError,
      });
    }
  );

  const { t } = useTranslation("superAdmin");
  const closeErrorModal = () => {
    setOpen(false);
  };

  useOnEq(status, "Resolved", () => setOpen(false));

  const errorTitle = t`errorTitle`;
  const errorChildren = (
    <div>
      <Input
        className="w-full"
        label={t`errorLabel`}
        value={newError}
        onChange={(m) => setNewError(m.target.value)}
      />
    </div>
  );
  const errorActions = (
    <>
      <div className={"flex justify-between w-full"}>
        <div>
          {newError !== "" && (
            <Button
              color="danger"
              variant="secondary"
              size="large"
              onClick={() => setNewError("")}
            >
              {t`empty`}
            </Button>
          )}
        </div>
        <div className={"flex justify-end"}>
          <Button
            color="danger"
            variant="text"
            size="large"
            onClick={() => {
              setOpen(false);
            }}
          >
            {t`cancel`}
          </Button>
          <Button
            color="danger"
            size="large"
            loading={status === "Pending"}
            disabled={status === "Pending"}
            onClick={() => {
              handleSetErrorMsg();
            }}
          >
            {t`save`}
          </Button>
        </div>
      </div>
    </>
  );
  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeErrorModal}
      title={errorTitle}
      actions={errorActions}
    >
      {errorChildren}
    </Modal>
  );
};
