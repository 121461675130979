const {
  reportErrorDatadog,
  infoLogDatadog,
  setDatadogUser,
} = require("../../src/client-ts/datadog/utils");
const { getClientConfigVar } = require("../../src/client/config");

exports._reportError = function (err) {
  return function (pairs) {
    return function () {
      if (getClientConfigVar("CLIENT_LOGGING_ENABLED") === "true") {
        reportErrorDatadog(err, pairs);
      }
    };
  };
};

exports._logError = function (err) {
  return function () {
    if (getClientConfigVar("CLIENT_LOGGING_ENABLED") === "true") {
      reportErrorDatadog(err);
    }
  };
};

exports._log = (msg) => {
  return () => {
    if (getClientConfigVar("CLIENT_LOGGING_ENABLED") === "true") {
      infoLogDatadog(msg);
    }
  };
};

exports.setUser = function (user) {
  return function () {
    setDatadogUser(user);
  };
};
