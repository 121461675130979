// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var scrollContainerStyles = Box.boxStyle([ new Box.Overflow(Box.Auto.value, Box.Scroll.value), Box.Style.create(React_Basic_Emotion.css()({
    outline: React_Basic_Emotion.str("none")
})), Box.MinHeight.create(TacoConstants.CustomSize.create("0")), new Box.FlexGrow(1) ]);
module.exports = {
    scrollContainerStyles: scrollContainerStyles
};
