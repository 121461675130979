// Generated by purs version 0.14.5
"use strict";
var Components_Utils = require("../Components.Utils/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var TacoTabs_Styles = require("../TacoTabs.Styles/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var UseUnfocusOnClick = require("../UseUnfocusOnClick/index.js");
var component = TofuHooks.mkHookComponent("NormalTab")(function (props) {
    return function __do() {
        var v = UseUnfocusOnClick.useUnfocusOnClick();
        var titleJSX = ReactBasicUtils["div'"]("title")([ TacoText.component()()({
            text: props.title,
            inheritColor: true,
            weight: TacoText_Types.Bold.value
        }) ]);
        var onClick = React_Basic_DOM_Events.capture_(function __do() {
            v.unfocus();
            return props.setActiveTabId(function (v1) {
                return new Data_Maybe.Just(props.id);
            })();
        });
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["button'"]())({
            className: "__Tab",
            css: TacoTabs_Styles.tabStyles({
                active: props.active,
                color: props.color,
                orientation: props.orientation
            }),
            children: [ titleJSX ],
            onClick: onClick,
            ref: v.ref,
            "_data": Components_Utils.mkTestIdAttrs("tab-" + props.id)
        });
    };
});
module.exports = {
    component: component
};
