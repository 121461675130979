// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Set = require("../Data.Set/index.js");
var DixonFileItem = require("../DixonFileItem/index.js");
var DixonFileList_Styles = require("../DixonFileList.Styles/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon = require("../TacoIcon/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var component = TofuHooks.mkHookComponent("DixonFileList")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState(Data_Set.empty)();
        var visibleFiles = Data_Array.sortWith(Data_Ord.ordString)(function (v2) {
            return v2.folder;
        })(Data_Array.filter(function (file) {
            return file.archived === v.showArchived;
        })(v.editorState.files));
        var highlightedMGDrawingIds = Data_Array.concatMap(function (v2) {
            return v2.drawing_ids;
        })(Data_Array.filter(function (v2) {
            return v2.active;
        })(v.editorState.measurement_groups));
        var listFiles = function (files) {
            return React_Basic_DOM_Generated.div()({
                children: Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Data_Functor.functorFn)(DixonFileItem.component)(function (v2) {
                    return {
                        editor: v.editor,
                        editorState: v.editorState,
                        highlightedMGDrawingIds: highlightedMGDrawingIds,
                        projectId: v.projectId,
                        file: v2
                    };
                }))(files)
            });
        };
        var folders = Data_Array.groupBy(function (a) {
            return function (b) {
                return a.folder === b.folder;
            };
        })(visibleFiles);
        var folderHeader = function (folderName) {
            var chevronRotation = (function () {
                var $9 = Data_Set.member(Data_Ord.ordString)(folderName)(v1.value0);
                if ($9) {
                    return 90;
                };
                return 0;
            })();
            return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                onClick: React_Basic_Events.handler_(v1.value1(Data_Set.toggle(Data_Ord.ordString)(folderName))),
                children: [ TacoIcon.component()()()()({
                    icon: TacoIcon_Icons_Types.IconGtV1.value,
                    rotation: chevronRotation,
                    size: TacoConstants.XS.value
                }), TacoText.component()()({
                    text: folderName,
                    weight: TacoText_Types.Bold.value
                }) ],
                css: DixonFileList_Styles.dixonFileFolderHeaderStyles,
                className: "DixonFileListHeader"
            });
        };
        var folderListing = function (files) {
            var folderName = (function (v2) {
                return v2.folder;
            })(Data_Array_NonEmpty.head(files));
            var noFolder = folderName === "";
            return React_Basic_DOM_Generated.div()({
                children: [ Data_Monoid.guard(Data_Monoid.monoidFn(React_Basic.monoidJSX))(!noFolder)(folderHeader)(folderName), Data_Monoid.guard(React_Basic.monoidJSX)(noFolder || Data_Set.member(Data_Ord.ordString)(folderName)(v1.value0))(listFiles(Data_Array_NonEmpty.toArray(files))) ]
            });
        };
        var $10 = v.showArchived && Data_Array["null"](visibleFiles);
        if ($10) {
            return Box.box("EmptyTextContainer")([ new Box.Padding(TacoConstants.M.value) ])([ TacoText.component()()({
                text: "Arkisto on tyhj\xe4.",
                color: TacoConstants.GrayDarkest.value
            }) ]);
        };
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "DixonFileList",
            css: DixonFileList_Styles.dixonFileListContainerStyles,
            children: Data_Functor.map(Data_Functor.functorArray)(folderListing)(folders)
        });
    };
});
module.exports = {
    component: component
};
