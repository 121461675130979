import { Button, IconDelete24Px } from "@tocoman/ui";
import { IStatusPanelParams } from "ag-grid-enterprise";
import { useTranslation } from "react-i18next";
import { useSelectedRows } from "src/client-ts/components/Table/useSelectedRows";
import { ComponentGroup, useDeleteGroupsMutation } from "./useComponentGroups";

export function DeleteSelectedGroupsButton({
  projectId,
  api,
  groupsEdited,
}: IStatusPanelParams<ComponentGroup> & {
  projectId: number;
  groupsEdited: () => void;
}) {
  const { t } = useTranslation("estimation", {
    keyPrefix: "componentGroupEdit",
  });

  const selectedRows = useSelectedRows(api);

  const { mutate: deleteGroups, isLoading } = useDeleteGroupsMutation(
    projectId
  );

  const handleDelete = (data: ComponentGroup[]) => {
    const groupCodes = {
      groupCodes: data.map((g) => g.code),
    };
    deleteGroups(groupCodes);
    groupsEdited();
  };

  return (
    <div className={"flex flex-row justify-end py-3"}>
      <Button
        variant="text"
        color="danger"
        onClick={() => {
          handleDelete(selectedRows);
        }}
        icon={IconDelete24Px}
        iconPosition={"right"}
        disabled={selectedRows.length === 0 || isLoading}
        loading={isLoading}
        className={"w-[126px]"}
      >{t`deleteSelected`}</Button>
    </div>
  );
}
