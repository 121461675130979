import { Button, IconFetch, Loader } from "@tocoman/ui";
import { ColDef } from "ag-grid-enterprise";
import { useTranslation } from "react-i18next";
import { Table } from "src/client-ts/components/Table";
import { DatabaseChangelog } from "src/server-ts/super-admin/organizations/SuperAdminOrganizationQueries";
import {
  useOrganizationMigrationMutation,
  useOrganizationMigrationQuery,
} from "./useOrganizationMigrationQuery";

export const OrganizationMigrationDetail = ({ org }: { org: string }) => {
  const { t } = useTranslation("superAdmin", { keyPrefix: "migrations" });
  const { data, isLoading, refetch } = useOrganizationMigrationQuery(org);

  const migrate = useOrganizationMigrationMutation(org);

  if (isLoading) {
    return <Loader />;
  }

  if (!data) {
    return (
      <span>
        {org} {t("no_migration_data")}
      </span>
    );
  }

  const columnDefs: ColDef<DatabaseChangelog>[] = [
    {
      headerName: t("id"),
      field: "ID",
    },
    {
      headerName: t("author"),
      field: "AUTHOR",
    },
    {
      headerName: t("filename"),
      field: "FILENAME",
    },
    {
      headerName: t("dateexecuted"),
      field: "DATEEXECUTED",
    },
    {
      headerName: t("orderExecuted"),
      field: "ORDEREXECUTED",
    },
    {
      headerName: t("md5sum"),
      field: "MD5SUM",
    },
    {
      headerName: t("description"),
      field: "DESCRIPTION",
    },
    {
      headerName: t("comments"),
      field: "COMMENTS",
    },
    {
      headerName: t("tag"),
      field: "TAG",
    },
    {
      headerName: t("liquibase"),
      field: "LIQUIBASE",
    },
    {
      headerName: t("contexts"),
      field: "CONTEXTS",
    },
    {
      headerName: t("labels"),
      field: "LABELS",
    },
    {
      headerName: t("deploymentId"),
      field: "DEPLOYMENT_ID",
    },
  ];

  return (
    <>
      <div className="mx-2 my-3 flex">
        <Button onClick={() => migrate.mutate()} loading={isLoading}>
          {t("migrate")}
        </Button>
        <Button
          className="ml-3"
          onClick={() => refetch()}
          icon={IconFetch}
          loading={isLoading}
        />
        <span className="ml-3">{t`migrationInfo`}</span>
      </div>
      <Table<DatabaseChangelog>
        className="w-full h-full"
        rowData={data}
        columnDefs={columnDefs}
        defaultColDef={{
          resizable: true,
        }}
      />
    </>
  );
};
