// Generated by purs version 0.14.5
"use strict";
var AllSet = require("../AllSet/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Roles_Types = require("../Roles.Types/index.js");
var superAdminCapabilities = {
    createProjectsPermission: Roles_Types.CreateProjectsAllowed.value,
    isAdmin: Roles_Types.IsOrganizationAdmin.value,
    costControlPermission: Roles_Types.UsingCostControlAllowed.value,
    costGroupTargetWritePermission: Roles_Types.WriteCostGroupTargetAllowed.value,
    ccReadableProjects: AllSet.All.value,
    ccWritableProjects: AllSet.All.value,
    costEstimationPermission: Roles_Types.UsingCostEstimationAllowed.value,
    ceReadableProjects: AllSet.All.value,
    ceWritableProjects: AllSet.All.value
};
var noAppAccessRights = {
    costControl: false,
    estimation: false,
    organization: false,
    integration: false,
    superAdmin: false,
    admin: false
};
var emptyUserRole = {
    name: "",
    createProjectsPermission: false,
    isAdmin: false,
    costControlPermission: false,
    costGroupTargetWritePermission: false,
    ccReadPermission: false,
    ccWritePermission: false,
    ccReadAllProjectsPermission: false,
    ccWriteAllProjectsPermission: false,
    costEstimationPermission: false,
    ceReadPermission: false,
    ceWritePermission: false,
    ceReadAllProjectsPermission: false,
    ceWriteAllProjectsPermission: false
};
var protectedRoles = Data_Functor.map(Data_Functor.functorArray)(Data_Function.flip(Data_Newtype.over()()(Roles_Types.UserRole))(emptyUserRole))([ function (v) {
    return {
        name: "projektien luonti",
        createProjectsPermission: true,
        ccReadAllProjectsPermission: v.ccReadAllProjectsPermission,
        ccReadPermission: v.ccReadPermission,
        ccWriteAllProjectsPermission: v.ccWriteAllProjectsPermission,
        ccWritePermission: v.ccWritePermission,
        ceReadAllProjectsPermission: v.ceReadAllProjectsPermission,
        ceReadPermission: v.ceReadPermission,
        ceWriteAllProjectsPermission: v.ceWriteAllProjectsPermission,
        ceWritePermission: v.ceWritePermission,
        costControlPermission: v.costControlPermission,
        costEstimationPermission: v.costEstimationPermission,
        costGroupTargetWritePermission: v.costGroupTargetWritePermission,
        isAdmin: v.isAdmin
    };
}, function (v) {
    return {
        name: "p\xe4\xe4k\xe4ytt\xe4j\xe4",
        isAdmin: true,
        ccReadAllProjectsPermission: v.ccReadAllProjectsPermission,
        ccReadPermission: v.ccReadPermission,
        ccWriteAllProjectsPermission: v.ccWriteAllProjectsPermission,
        ccWritePermission: v.ccWritePermission,
        ceReadAllProjectsPermission: v.ceReadAllProjectsPermission,
        ceReadPermission: v.ceReadPermission,
        ceWriteAllProjectsPermission: v.ceWriteAllProjectsPermission,
        ceWritePermission: v.ceWritePermission,
        costControlPermission: v.costControlPermission,
        costEstimationPermission: v.costEstimationPermission,
        costGroupTargetWritePermission: v.costGroupTargetWritePermission,
        createProjectsPermission: v.createProjectsPermission
    };
}, function (v) {
    return {
        name: "raportointi",
        costControlPermission: true,
        ccReadAllProjectsPermission: v.ccReadAllProjectsPermission,
        ccReadPermission: v.ccReadPermission,
        ccWriteAllProjectsPermission: v.ccWriteAllProjectsPermission,
        ccWritePermission: v.ccWritePermission,
        ceReadAllProjectsPermission: v.ceReadAllProjectsPermission,
        ceReadPermission: v.ceReadPermission,
        ceWriteAllProjectsPermission: v.ceWriteAllProjectsPermission,
        ceWritePermission: v.ceWritePermission,
        costEstimationPermission: v.costEstimationPermission,
        costGroupTargetWritePermission: v.costGroupTargetWritePermission,
        createProjectsPermission: v.createProjectsPermission,
        isAdmin: v.isAdmin
    };
}, function (v) {
    return {
        name: "litterakohtaisten tavoitteiden muokkaus",
        costGroupTargetWritePermission: true,
        ccReadAllProjectsPermission: v.ccReadAllProjectsPermission,
        ccReadPermission: v.ccReadPermission,
        ccWriteAllProjectsPermission: v.ccWriteAllProjectsPermission,
        ccWritePermission: v.ccWritePermission,
        ceReadAllProjectsPermission: v.ceReadAllProjectsPermission,
        ceReadPermission: v.ceReadPermission,
        ceWriteAllProjectsPermission: v.ceWriteAllProjectsPermission,
        ceWritePermission: v.ceWritePermission,
        costControlPermission: v.costControlPermission,
        costEstimationPermission: v.costEstimationPermission,
        createProjectsPermission: v.createProjectsPermission,
        isAdmin: v.isAdmin
    };
}, function (v) {
    return {
        name: "raportointi: projektin luku",
        ccReadPermission: true,
        ccReadAllProjectsPermission: v.ccReadAllProjectsPermission,
        ccWriteAllProjectsPermission: v.ccWriteAllProjectsPermission,
        ccWritePermission: v.ccWritePermission,
        ceReadAllProjectsPermission: v.ceReadAllProjectsPermission,
        ceReadPermission: v.ceReadPermission,
        ceWriteAllProjectsPermission: v.ceWriteAllProjectsPermission,
        ceWritePermission: v.ceWritePermission,
        costControlPermission: v.costControlPermission,
        costEstimationPermission: v.costEstimationPermission,
        costGroupTargetWritePermission: v.costGroupTargetWritePermission,
        createProjectsPermission: v.createProjectsPermission,
        isAdmin: v.isAdmin
    };
}, function (v) {
    return {
        name: "raportointi: projektin kirjoitus",
        ccWritePermission: true,
        ccReadAllProjectsPermission: v.ccReadAllProjectsPermission,
        ccReadPermission: v.ccReadPermission,
        ccWriteAllProjectsPermission: v.ccWriteAllProjectsPermission,
        ceReadAllProjectsPermission: v.ceReadAllProjectsPermission,
        ceReadPermission: v.ceReadPermission,
        ceWriteAllProjectsPermission: v.ceWriteAllProjectsPermission,
        ceWritePermission: v.ceWritePermission,
        costControlPermission: v.costControlPermission,
        costEstimationPermission: v.costEstimationPermission,
        costGroupTargetWritePermission: v.costGroupTargetWritePermission,
        createProjectsPermission: v.createProjectsPermission,
        isAdmin: v.isAdmin
    };
}, function (v) {
    return {
        name: "raportointi: kaikkien projektien luku",
        ccReadAllProjectsPermission: true,
        ccReadPermission: v.ccReadPermission,
        ccWriteAllProjectsPermission: v.ccWriteAllProjectsPermission,
        ccWritePermission: v.ccWritePermission,
        ceReadAllProjectsPermission: v.ceReadAllProjectsPermission,
        ceReadPermission: v.ceReadPermission,
        ceWriteAllProjectsPermission: v.ceWriteAllProjectsPermission,
        ceWritePermission: v.ceWritePermission,
        costControlPermission: v.costControlPermission,
        costEstimationPermission: v.costEstimationPermission,
        costGroupTargetWritePermission: v.costGroupTargetWritePermission,
        createProjectsPermission: v.createProjectsPermission,
        isAdmin: v.isAdmin
    };
}, function (v) {
    return {
        name: "raportointi: kaikkien projektien kirjoitus",
        ccWriteAllProjectsPermission: true,
        ccReadAllProjectsPermission: v.ccReadAllProjectsPermission,
        ccReadPermission: v.ccReadPermission,
        ccWritePermission: v.ccWritePermission,
        ceReadAllProjectsPermission: v.ceReadAllProjectsPermission,
        ceReadPermission: v.ceReadPermission,
        ceWriteAllProjectsPermission: v.ceWriteAllProjectsPermission,
        ceWritePermission: v.ceWritePermission,
        costControlPermission: v.costControlPermission,
        costEstimationPermission: v.costEstimationPermission,
        costGroupTargetWritePermission: v.costGroupTargetWritePermission,
        createProjectsPermission: v.createProjectsPermission,
        isAdmin: v.isAdmin
    };
}, function (v) {
    return {
        name: "kustannuslaskenta",
        costEstimationPermission: true,
        ccReadAllProjectsPermission: v.ccReadAllProjectsPermission,
        ccReadPermission: v.ccReadPermission,
        ccWriteAllProjectsPermission: v.ccWriteAllProjectsPermission,
        ccWritePermission: v.ccWritePermission,
        ceReadAllProjectsPermission: v.ceReadAllProjectsPermission,
        ceReadPermission: v.ceReadPermission,
        ceWriteAllProjectsPermission: v.ceWriteAllProjectsPermission,
        ceWritePermission: v.ceWritePermission,
        costControlPermission: v.costControlPermission,
        costGroupTargetWritePermission: v.costGroupTargetWritePermission,
        createProjectsPermission: v.createProjectsPermission,
        isAdmin: v.isAdmin
    };
}, function (v) {
    return {
        name: "kustannuslaskenta: projektin luku",
        ceReadPermission: true,
        ccReadAllProjectsPermission: v.ccReadAllProjectsPermission,
        ccReadPermission: v.ccReadPermission,
        ccWriteAllProjectsPermission: v.ccWriteAllProjectsPermission,
        ccWritePermission: v.ccWritePermission,
        ceReadAllProjectsPermission: v.ceReadAllProjectsPermission,
        ceWriteAllProjectsPermission: v.ceWriteAllProjectsPermission,
        ceWritePermission: v.ceWritePermission,
        costControlPermission: v.costControlPermission,
        costEstimationPermission: v.costEstimationPermission,
        costGroupTargetWritePermission: v.costGroupTargetWritePermission,
        createProjectsPermission: v.createProjectsPermission,
        isAdmin: v.isAdmin
    };
}, function (v) {
    return {
        name: "kustannuslaskenta: projektin kirjoitus",
        ceWritePermission: true,
        ccReadAllProjectsPermission: v.ccReadAllProjectsPermission,
        ccReadPermission: v.ccReadPermission,
        ccWriteAllProjectsPermission: v.ccWriteAllProjectsPermission,
        ccWritePermission: v.ccWritePermission,
        ceReadAllProjectsPermission: v.ceReadAllProjectsPermission,
        ceReadPermission: v.ceReadPermission,
        ceWriteAllProjectsPermission: v.ceWriteAllProjectsPermission,
        costControlPermission: v.costControlPermission,
        costEstimationPermission: v.costEstimationPermission,
        costGroupTargetWritePermission: v.costGroupTargetWritePermission,
        createProjectsPermission: v.createProjectsPermission,
        isAdmin: v.isAdmin
    };
}, function (v) {
    return {
        name: "kustannuslaskenta: kaikkien projektien luku",
        ceReadAllProjectsPermission: true,
        ccReadAllProjectsPermission: v.ccReadAllProjectsPermission,
        ccReadPermission: v.ccReadPermission,
        ccWriteAllProjectsPermission: v.ccWriteAllProjectsPermission,
        ccWritePermission: v.ccWritePermission,
        ceReadPermission: v.ceReadPermission,
        ceWriteAllProjectsPermission: v.ceWriteAllProjectsPermission,
        ceWritePermission: v.ceWritePermission,
        costControlPermission: v.costControlPermission,
        costEstimationPermission: v.costEstimationPermission,
        costGroupTargetWritePermission: v.costGroupTargetWritePermission,
        createProjectsPermission: v.createProjectsPermission,
        isAdmin: v.isAdmin
    };
}, function (v) {
    return {
        name: "kustannuslaskenta: kaikkien projektien kirjoitus",
        ceWriteAllProjectsPermission: true,
        ccReadAllProjectsPermission: v.ccReadAllProjectsPermission,
        ccReadPermission: v.ccReadPermission,
        ccWriteAllProjectsPermission: v.ccWriteAllProjectsPermission,
        ccWritePermission: v.ccWritePermission,
        ceReadAllProjectsPermission: v.ceReadAllProjectsPermission,
        ceReadPermission: v.ceReadPermission,
        ceWritePermission: v.ceWritePermission,
        costControlPermission: v.costControlPermission,
        costEstimationPermission: v.costEstimationPermission,
        costGroupTargetWritePermission: v.costGroupTargetWritePermission,
        createProjectsPermission: v.createProjectsPermission,
        isAdmin: v.isAdmin
    };
} ]);
var emptyUserCapabilities = {
    createProjectsPermission: Roles_Types.CreateProjectsDisallowed.value,
    isAdmin: Roles_Types.NotOrganizationAdmin.value,
    costControlPermission: Roles_Types.UsingCostControlDisallowed.value,
    costGroupTargetWritePermission: Roles_Types.WriteCostGroupTargetDisallowed.value,
    ccReadableProjects: AllSet.empty,
    ccWritableProjects: AllSet.empty,
    costEstimationPermission: Roles_Types.UsingCostEstimationDisallowed.value,
    ceReadableProjects: AllSet.empty,
    ceWritableProjects: AllSet.empty
};
module.exports = {
    emptyUserRole: emptyUserRole,
    emptyUserCapabilities: emptyUserCapabilities,
    superAdminCapabilities: superAdminCapabilities,
    noAppAccessRights: noAppAccessRights,
    protectedRoles: protectedRoles
};
