// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var React_Basic = require("../React.Basic/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoSelect = require("../TacoSelect/index.js");
var TacoText = require("../TacoText/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var values = [ {
    text: "Apple",
    value: "apple"
}, {
    text: "Banana",
    value: "banana"
}, {
    text: "Coconut",
    value: "coconut"
} ];
var imports = "import TacoSelect as TacoSelect\x0a";
var example1_jsx = TofuHooks.mkHookComponent("SelectExample1")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState("apple")();
        return React_Basic.fragment([ TacoSelect.component()()({
            items: values,
            value: v1.value0,
            label: "Select a fruit:",
            onChange: v1.value1
        }), Box.box("resultContainer")([ new Box.MarginTop(TacoConstants.M.value) ])([ TacoText.component()()({
            text: "You have selected: " + v1.value0
        }) ]) ]);
    };
});
var example1 = imports + "\x0aTuple value setValue <- useState \"apple\"\x0a\x0apure $ RB.fragment\x0a  [ TacoSelect.component { items: values, value, label: R.text \"Select a fruit:\", onChange: setValue }\x0a  , Box.box [ Box.MarginTop M ] [ TacoText.component { text: \"You have selected: \" <> value } ]\x0a  ]\x0a";
var sections = [ {
    title: "Default",
    view: [ example1_jsx({}) ],
    code: example1
} ];
var demoView = {
    sections: sections,
    title: "TacoSelect",
    description: "Item selection dropdown"
};
module.exports = {
    demoView: demoView
};
