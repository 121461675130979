// Generated by purs version 0.14.5
"use strict";
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Effect = require("../Effect/index.js");
var Gap = require("../Gap/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var TacoChip = require("../TacoChip/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var example1 = "import TacoChip as TacoChip\x0aimport Gap (gapX)\x0aimport TacoConstants (Size(..))\x0a\x0a[ gapX XS\x0a    [ TacoChip.component\x0a      { contents: [ R.text \"Chip 1\" ]\x0a      , testId: \"demo-chip-1\"\x0a      }\x0a    , TacoChip.component\x0a      { contents: [ R.text \"Chip 2\" ]\x0a      , onRemove: Just mempty\x0a      , testId: \"demo-chip-2\"\x0a      }\x0a    , TacoChip.component\x0a      { contents: [ R.text \"Chip 3\" ]\x0a      , onRemove: Just mempty\x0a      , testId: \"demo-chip-3\"\x0a      }\x0a    ]\x0a]";
var sections = [ {
    title: "Default",
    view: [ Gap.gapX(TacoConstants.XS.value)([ TacoChip.component()()({
        contents: [ React_Basic_DOM.text("Chip 1") ],
        testId: "demo-chip-1"
    }), TacoChip.component()()({
        contents: [ React_Basic_DOM.text("Chip 2") ],
        onRemove: new Data_Maybe.Just(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit))),
        testId: "demo-chip-2"
    }), TacoChip.component()()({
        contents: [ React_Basic_DOM.text("Chip 3") ],
        onRemove: new Data_Maybe.Just(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit))),
        testId: "demo-chip-3"
    }) ]) ],
    code: example1
} ];
var demoView = {
    sections: sections,
    title: "TacoChip",
    description: "Chip component which represents a selection, can be toggled"
};
module.exports = {
    demoView: demoView
};
