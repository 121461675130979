import { useEffect } from "react";
import { AgGridEvent, GridApi } from "ag-grid-community";

export function useGridEventListener<T extends AgGridEvent>(
  api: GridApi | null | undefined,
  eventName: string,
  callback: (event: T) => void
) {
  useEffect(() => {
    if (!api) return;
    const listener = (params: T) => {
      callback(params);
    };

    // Register selection listener
    // Check that grid is not destroyed before registering
    !api.isDestroyed() && api.addEventListener(eventName, listener);

    // Unregister when unmounting
    // Check that grid is not destroyed before unregistering
    return () =>
      !api.isDestroyed()
        ? api.removeEventListener(eventName, listener)
        : undefined;
  }, [api, callback, eventName]);
}
