import { Button, Modal } from "@tocoman/ui";
import { useTranslation } from "react-i18next";
import {
  UserData,
  useUpdateUserInSuperAdmin,
} from "../Tabs/useSuperAdminUsers";

type Props = {
  editSuperAdminOpen: boolean;
  setEditSuperAdmin: (data: UserData | null) => void;
  editSuperAdmin: UserData;
};

export const ConfirmEditSuperAdmin = ({
  editSuperAdminOpen,
  setEditSuperAdmin,
  editSuperAdmin,
}: Props) => {
  const { t } = useTranslation("superAdmin");

  const updateUser = useUpdateUserInSuperAdmin();

  const handleUpdateSuperAdminState = async (userData: UserData) => {
    const updateData: UserData = {
      id: userData.id,
      email: userData.email,
      currentOrganization: userData.currentOrganization,
      createdAt: userData.createdAt,
      organizations: userData.organizations,
      expiresAt: userData.expiresAt,
      isSuperAdmin: !userData.isSuperAdmin,
      hasSSOLogin: userData.hasSSOLogin,
    };
    await updateUser.mutateAsync(updateData);
  };

  const superAdminTitle = t`superAdminTitle`;
  const superAdminChildren = (
    <div>
      <span>
        {t`superAdminChildrenStart`}{" "}
        {editSuperAdmin !== null ? editSuperAdmin.email : ""}{" "}
        {t`superAdminChildrenEnd`}
      </span>
    </div>
  );
  const superAdminActions = (
    <>
      <div className={"flex justify-between w-full"}>
        <div>
          <Button
            color="danger"
            variant="text"
            size="large"
            onClick={() => setEditSuperAdmin(null)}
          >
            {t`cancel`}
          </Button>
        </div>
        <div className={"flex justify-end"}>
          <Button
            color="danger"
            size="large"
            onClick={() => {
              handleUpdateSuperAdminState(editSuperAdmin).then(() =>
                setEditSuperAdmin(null)
              );
            }}
          >
            {t`yes`}
          </Button>
        </div>
      </div>
    </>
  );

  return (
    <Modal
      isOpen={editSuperAdminOpen}
      closeModal={() => setEditSuperAdmin(null)}
      title={superAdminTitle}
      actions={superAdminActions}
      width={960}
    >
      {superAdminChildren}
    </Modal>
  );
};
