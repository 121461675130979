import { useMutation, UseMutationResult } from "react-query";
import axios from "../axiosConfig";

export function useGetAvailableVersionQuery(): UseMutationResult<{
  nextVersion: number;
}> {
  return useMutation(["nextVersion"], async (code) => {
    const response = await axios.get<{ nextVersion: number }>(
      `/projects/nextVersion`,
      {
        params: { code },
      }
    );
    return response.data;
  });
}
