import { useComboboxFilter } from "./useComboboxFilter";
import { FieldValues, UseControllerProps } from "react-hook-form";
import {
  ControlledCombobox,
  ControlledComboboxProps,
} from "./ControlledCombobox";

export function LabelFilteredCombobox<F extends FieldValues, T>(
  props: UseControllerProps<F> &
    Omit<ControlledComboboxProps<F, T>, "filterHandler">
) {
  const { items, ...rest } = props;
  const newGroupFilterProps = useComboboxFilter(items);
  return <ControlledCombobox {...newGroupFilterProps} {...rest} />;
}
