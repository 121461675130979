// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Data_Ord = require("../Data.Ord/index.js");
var TimeoutId = function (x) {
    return x;
};
var IntervalId = function (x) {
    return x;
};
var eqTimeoutId = {
    eq: function (x) {
        return function (y) {
            return x === y;
        };
    }
};
var ordTimeoutId = {
    compare: function (x) {
        return function (y) {
            return Data_Ord.compare(Data_Ord.ordInt)(x)(y);
        };
    },
    Eq0: function () {
        return eqTimeoutId;
    }
};
var eqIntervalId = {
    eq: function (x) {
        return function (y) {
            return x === y;
        };
    }
};
var ordIntervalId = {
    compare: function (x) {
        return function (y) {
            return Data_Ord.compare(Data_Ord.ordInt)(x)(y);
        };
    },
    Eq0: function () {
        return eqIntervalId;
    }
};
module.exports = {
    eqTimeoutId: eqTimeoutId,
    ordTimeoutId: ordTimeoutId,
    eqIntervalId: eqIntervalId,
    ordIntervalId: ordIntervalId,
    setTimeout: $foreign.setTimeout,
    clearTimeout: $foreign.clearTimeout,
    setInterval: $foreign.setInterval,
    clearInterval: $foreign.clearInterval
};
