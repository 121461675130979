import { CostControlCostGroup } from "../../../../../ts-bindings/CostControlCostGroup";
import { CostClass } from "../../../../../ts-bindings/CostClass";
import { CostControlResources } from "../../../../../ts-bindings/CostControlResources";
import { CostControlComponents } from "../../../../../ts-bindings/CostControlComponents";
import { CostControlPrintingCostGroup } from "./Printing";
import { MainCostGroup } from "../../../../../ts-bindings/MainCostGroup";
import { CostControlItem } from "../../../../../ts-bindings/CostControlItem";
import { CostControlInvoices } from "../../../../../ts-bindings/CostControlInvoices";
import {
  countSocialExpenses,
  countValueWithSocialExpenses,
} from "./printingFunctions";
import { TFunction } from "react-i18next";

type ComponentsWithCostGroup = {
  components?: CostControlComponents[];
  costGroup: CostControlPrintingCostGroup;
};

type ComponentWithCostGroup = {
  component?: CostControlComponents | null;
  costGroup: CostControlPrintingCostGroup;
};

export type CostControlPrintingItem = {
  id?: number;
  socialExpenses?: number;
  value?: number;
  projectId?: number;
  costClassCode?: string | null;
  costGroupCode?: string | null;
};

type ComponentWithCostGroupCode = {
  costGroupCode?: string | null;
} & CostControlComponents;

type CostControlValueItems = {
  targets?: CostControlItem[];
  estimates?: CostControlItem[];
  invoices?: CostControlInvoices[];
};

type InvoiceAsCostGroup = {
  name: string;
  code: string;
  projectId: number;
  targets: undefined;
  estimates: undefined;
  invoices: CostControlInvoices | undefined;
};

type Groups = {
  costGroups: CostControlCostGroup[];
  mainCostGroups: MainCostGroup[];
};
export function mapCostControlPrintingRowData(
  costClasses: CostClass[],
  components: CostControlComponents[],
  resources: CostControlResources[],
  values: CostControlValueItems,
  groups: Groups,
  t: TFunction<"costControl", "printing">
) {
  const costGroupsWithValues = mapCostGroupsWithValues(
    groups.costGroups,
    values
  );
  const costGroupsWithSocialExpenses = mapCostGroupsWithSocialExpenses(
    costGroupsWithValues,
    costClasses
  );
  const costGroupWithSummedValues = mapCostGroupWithSummedValues(
    costGroupsWithSocialExpenses
  );
  const componentsWithCostGroup = mapComponentsWithCostGroup(
    components,
    resources
  );
  const costGroupsWithComponents = mapCostGroupWithComponents(
    costGroupWithSummedValues,
    componentsWithCostGroup
  );
  const componentWithCostGroup = mapComponentWithCostGroup(
    costGroupsWithComponents
  );
  const componentWithCostClasses = mapComponentWithCostClasses(
    componentWithCostGroup,
    resources,
    costClasses
  );
  const componentWithMainCostGroup = mapComponentWithMainCostGroup(
    componentWithCostClasses,
    groups.mainCostGroups
  );

  const invoicesWithUndefinedCostGroup = mapUndefinedInvoices(
    values.invoices,
    costGroupsWithValues
  );

  const invoiceAsCostGroup: InvoiceAsCostGroup[] = mapInvoicesToCostGroups(
    invoicesWithUndefinedCostGroup,
    t
  );

  const invoicesWithSocialExpenses = mapInvoicesWithSocialExpenses(
    invoiceAsCostGroup,
    costClasses
  );

  const invoicesWithComponents = mapCostGroupWithComponents(
    invoicesWithSocialExpenses,
    componentsWithCostGroup
  );
  const invoiceComponentWithCostGroup = mapComponentWithCostGroup(
    invoicesWithComponents
  );
  const invoiceComponentWithCostClasses = mapComponentWithCostClasses(
    invoiceComponentWithCostGroup,
    resources,
    costClasses
  );
  const invoiceComponentWithMainCostGroup = mapComponentWithMainCostGroup(
    invoiceComponentWithCostClasses,
    groups.mainCostGroups
  );

  return componentWithMainCostGroup.concat(invoiceComponentWithMainCostGroup);
}

export const mapCostGroupsWithValues = (
  costGroups: CostControlCostGroup[],
  values: CostControlValueItems
) => {
  return costGroups
    .map((group) => {
      const groupTargets: CostControlPrintingItem[] =
        values.targets
          ?.filter((target) => target.costGroupCode === group.code)
          .map((target) => {
            return {
              value: target.value,
              projectId: target.projectId,
              costClassCode: target.costClassCode,
              costGroupCode: target.costGroupCode,
            };
          }) ?? [];
      const groupEstimates: CostControlPrintingItem[] =
        values.estimates
          ?.filter((estimate) => estimate.costGroupCode === group.code)
          .map((estimate) => {
            return {
              value: estimate.value,
              projectId: estimate.projectId,
              costClassCode: estimate.costClassCode,
              costGroupCode: estimate.costGroupCode,
            };
          }) ?? [];
      const groupInvoices: CostControlPrintingItem[] =
        values.invoices
          ?.filter((invoice) => invoice.costGroupCode === group.code)
          .map((invoice) => {
            return {
              id: invoice.id,
              value: invoice.value,
              costClassCode: invoice.costClassCode,
              costGroupCode: invoice.costGroupCode,
            };
          }) ?? [];
      return {
        name: group.name,
        code: group.code,
        projectId: group.projectId,
        targets: groupTargets,
        estimates: groupEstimates,
        invoices: groupInvoices,
      };
    })
    .filter(
      (group) =>
        group.targets.length > 0 ||
        group.estimates.length > 0 ||
        group.invoices.length > 0
    );
};

export const mapCostGroupsWithSocialExpenses = (
  costGroupsWithValues: CostControlPrintingCostGroup[],
  costClasses: CostClass[]
) => {
  return costGroupsWithValues.map((group: CostControlPrintingCostGroup) => {
    const costClassesWithSocialExpenses = costClasses.filter(
      (costClass: CostClass) => {
        if (costClass.socialExpensePercentageInCostControlForTargets) {
          return costClass.socialExpensePercentageInCostControlForTargets > 0;
        }
        if (
          costClass.socialExpensePercentageInCostControlForEstimatesAndActuals
        ) {
          return (
            costClass.socialExpensePercentageInCostControlForEstimatesAndActuals >
            0
          );
        }
      }
    );
    const targetsWithSocial = countSocialExpenses(
      group,
      costClassesWithSocialExpenses,
      "targets"
    );
    const estimatesWithSocial = countSocialExpenses(
      group,
      costClassesWithSocialExpenses,
      "estimates"
    );
    const invoicesWithSocial = countSocialExpenses(
      group,
      costClassesWithSocialExpenses,
      "invoices"
    );

    return {
      ...group,
      targets: targetsWithSocial,
      estimates: estimatesWithSocial,
      invoices: invoicesWithSocial,
    };
  });
};

export const mapCostGroupWithSummedValues = (
  costGroupsWithSocialExpenses: CostControlPrintingCostGroup[]
) => {
  return costGroupsWithSocialExpenses.map((group: CostControlPrintingItem) => {
    const targetTotal = countValueWithSocialExpenses(group, "targets");
    const estimateTotal = countValueWithSocialExpenses(group, "estimates");
    const invoiceTotal = countValueWithSocialExpenses(group, "invoices");
    return {
      ...group,
      target: targetTotal,
      estimate: estimateTotal,
      actual: invoiceTotal,
    };
  });
};

export const mapComponentsWithCostGroup = (
  components: CostControlComponents[],
  resources: CostControlResources[]
): ComponentWithCostGroupCode[] => {
  return components.map((component) => {
    const componentResources = resources.filter(
      (resource) => resource.componentId === component.componentId
    );
    return {
      ...component,
      costGroupCode: componentResources[0]?.costGroupCode,
    };
  });
};

export const mapCostGroupWithComponents = (
  costGroupWithSummedValues: CostControlPrintingCostGroup[],
  componentsWithCostGroup: ComponentWithCostGroupCode[]
): ComponentsWithCostGroup[] => {
  return costGroupWithSummedValues.map(
    (group: CostControlPrintingCostGroup) => {
      const groupComponents: CostControlComponents[] = componentsWithCostGroup.filter(
        (component) => component.costGroupCode === group.code
      );
      return { components: groupComponents, costGroup: group };
    }
  );
};

export const mapComponentWithCostGroup = (
  costGroupsWithComponents: ComponentsWithCostGroup[]
) => {
  return costGroupsWithComponents.flatMap((group) => {
    if (group.components && group.components.length > 0) {
      return group.components.map((component) => ({
        component: component,
        costGroup: group.costGroup,
      }));
    }
    return { costGroup: group.costGroup };
  });
};

export const mapComponentWithCostClasses = (
  componentWithCostGroup: ComponentWithCostGroup[],
  resources: CostControlResources[],
  costClasses: CostClass[]
) => {
  return componentWithCostGroup.map((component) => {
    const componentResources = resources.filter(
      (resource) => resource.componentId === component.component?.componentId
    );
    const costClassesWithResourceValues = mapCostClassesWithResourceValues(
      componentResources,
      costClasses
    );
    return {
      ...component,
      costClasses: costClassesWithResourceValues,
    };
  });
};

export const mapComponentWithMainCostGroup = (
  componentWithCostClasses: ComponentWithCostGroup[],
  mainCostGroups: MainCostGroup[]
) => {
  return componentWithCostClasses.map((component) => {
    const mainGroup = findMainCostGroup(component.costGroup, mainCostGroups);
    return { ...component, mainCostGroup: mainGroup };
  });
};

export const mapCostClassesWithResourceValues = (
  componentResources: CostControlResources[],
  costClasses: CostClass[]
) => {
  return costClasses.map((costClass) => {
    const resourcesValues = componentResources
      .filter((resource) => resource.costClassCode === costClass.costClassCode)
      .map((resource) => resource.value ?? 0);
    const costClassValue = resourcesValues.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    if (costClass.hourlyPricing) {
      const amounts = componentResources.map((resource) =>
        resource.costClassCode === costClass.costClassCode
          ? resource.amount ?? 0
          : 0
      );
      const hours = amounts.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );

      return { ...costClass, value: costClassValue, hours: hours };
    }
    return { ...costClass, value: costClassValue, hours: 0 };
  });
};

export const findMainCostGroup = (
  costGroup: CostControlPrintingCostGroup,
  mainCostGroups: MainCostGroup[]
) => {
  const firstOfCostGroup = costGroup?.code?.charAt(0) ?? "?";
  return (
    mainCostGroups.find((obj) => obj.code === firstOfCostGroup) ?? {
      code: firstOfCostGroup.toString(),
      name: null,
      id: null,
    }
  );
};

export const mapUndefinedInvoices = (
  invoices: CostControlInvoices[] | undefined,
  costGroupsWithValues: {
    name: string;
    code: string;
    projectId: number;
    targets: CostControlPrintingItem[];
    estimates: CostControlPrintingItem[];
    invoices: CostControlPrintingItem[];
  }[]
) => {
  return invoices?.filter((invoice) => {
    const invoicesWithCostGroupIds = costGroupsWithValues.flatMap((group) => {
      return group.invoices.map((groupInvoice) => groupInvoice.id);
    });
    return !invoicesWithCostGroupIds.includes(invoice.id);
  });
};

export const mapInvoicesToCostGroups = (
  invoicesWithUndefinedCostGroup: CostControlInvoices[] | undefined,
  t: TFunction<"costControl", "printing">
) => {
  return (
    invoicesWithUndefinedCostGroup?.map((invoice) => {
      return {
        name: invoice.costGroupCode?.startsWith("?")
          ? t`undefinedCostGroup`
          : t`missingCostGroup`,
        code: invoice.costGroupCode ? invoice.costGroupCode : "?????",
        projectId: 0,
        targets: undefined,
        estimates: undefined,
        invoices: invoice,
      };
    }) ?? []
  );
};

export const mapInvoicesWithSocialExpenses = (
  invoiceAsCostGroup: InvoiceAsCostGroup[],
  costClasses: CostClass[]
) => {
  const invoicesWithCostClass = invoiceAsCostGroup.map((invoice) => {
    const costClass = costClasses.find(
      (costClass) => costClass.costClassCode === invoice.invoices?.costClassCode
    );
    return { ...invoice, costClass: costClass };
  });
  return invoicesWithCostClass.map((invoice) => {
    const socialExpensePercentageInCostControlForEstimatesAndActuals =
      invoice.costClass
        ?.socialExpensePercentageInCostControlForEstimatesAndActuals ?? 0;
    const actual =
      (invoice.invoices?.value ?? 0) *
      (1 + socialExpensePercentageInCostControlForEstimatesAndActuals);
    return { ...invoice, actual: actual, target: 0, estimate: 0 };
  });
};
