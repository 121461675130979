import { safeDivide } from "../../../utils/safeDivide";
import { round } from "lodash";

function arrayContainsValue(array: Array<string>, value: string) {
  return array.includes(value);
}

function arrayContainsItemsOfArray(
  array: Array<string>,
  filter: Array<string>
) {
  return array.some((value) => filter.includes(value));
}

function rowFilter(checked: boolean, flagged: boolean, filter: Array<string>) {
  if (filter.includes("checked") && filter.includes("flagged")) {
    if (checked && flagged) {
      return true;
    }
  }
  if (filter.includes("checked") && filter.includes("other")) {
    if (checked && !flagged) {
      return true;
    }
  }
  if (filter.includes("flagged") && filter.includes("other")) {
    if (!checked && flagged) {
      return true;
    }
  }
  if (filter.includes("checked")) {
    if (checked) {
      return true;
    }
  }
  if (filter.includes("flagged")) {
    if (flagged) {
      return true;
    }
  }
  if (filter.includes("other")) {
    if (!checked && !flagged) {
      return true;
    }
  }
  return false;
}

function filterCode(code: string, range: string) {
  const cleanedRange = range.replace("%", "").replace("*", "");

  if (range.includes(",")) {
    const ranges = range.replace(" ", "").split(",");
    return ranges.some((r) => {
      const cleanedR = r.replace("%", "").replace("*", "");
      if (cleanedR.includes("-")) {
        const [start, end] = cleanedR.split("-");

        return code >= start && code <= end;
      } else {
        return code.startsWith(cleanedR) || code.endsWith(cleanedR);
      }
    });
  }
  if (range.includes("-")) {
    const [start, end] = cleanedRange.split("-");

    return code >= start && code <= end;
  } else {
    return code.startsWith(cleanedRange) || code.endsWith(cleanedRange);
  }
}

function calculateTotalPrice(
  social: boolean,
  totalPrice: number,
  totalPriceWithoutSocialExpenses: number
) {
  return social ? totalPrice : totalPriceWithoutSocialExpenses;
}

function filterTotalPrice(totalPrice: number, filter: boolean) {
  if (filter) {
    return totalPrice !== 0;
  }
  return true;
}

function filterMemo(memo: string, showMemo: boolean) {
  return !(showMemo && memo !== "");
}

function filterRowsWithMemo(memo: string, showMemo: boolean) {
  if (showMemo && memo !== "") {
    return true;
  }
  return !showMemo;
}

function zeroDivide(numerator: number, denominator: number) {
  return denominator === 0 ? 0 : numerator / denominator;
}

function getCostClassCodes(costClassCode: Array<{ costclasscode: string }>) {
  return costClassCode.map((costClass) => costClass.costclasscode).join("\n");
}

function filterElementsByClass(
  element: Array<{ elementclass: string }>,
  eClass: string
) {
  if (eClass === "") {
    return true;
  }
  const cleanedEClass = eClass.replace("%", "").replace("*", "");
  const elementClasses = element.map((element) => element.elementclass);
  if (eClass.includes(",")) {
    const eClasses = eClass.replace(" ", "").split(",");
    return eClasses.some((e) => {
      const cleanedE = e.replace("%", "").replace("*", "");
      if (cleanedE.includes("-")) {
        const [start, end] = cleanedE.split("-");
        return elementClasses.some(
          (elementClass) => elementClass >= start && elementClass <= end
        );
      } else {
        return elementClasses.some(
          (elementClass) =>
            elementClass.startsWith(cleanedE) || elementClass.endsWith(cleanedE)
        );
      }
    });
  }
  if (eClass.includes("-")) {
    const [start, end] = cleanedEClass.split("-");
    return elementClasses.some(
      (elementClass) => elementClass >= start && elementClass <= end
    );
  } else {
    return elementClasses.some(
      (elementClass) =>
        elementClass.startsWith(cleanedEClass) ||
        elementClass.endsWith(cleanedEClass)
    );
  }
}

function filterElementsByCode(
  element: Array<{ elementrtcode: string }>,
  code: string
) {
  if (code === "") {
    return true;
  }
  const cleanedCode = code.replace("%", "").replace("*", "");
  const elementCodes = element.map((element) => element.elementrtcode);
  if (code.includes(",")) {
    const codes = code.replace(" ", "").split(",");
    return codes.some((c) => {
      const cleanedC = c.replace("%", "").replace("*", "");
      if (cleanedC.includes("-")) {
        const [start, end] = cleanedC.split("-");
        return elementCodes.some(
          (elementCode) => elementCode >= start && elementCode <= end
        );
      }
      if (c.includes("*") || c.includes("%")) {
        return elementCodes.some(
          (elementCode) =>
            elementCode.startsWith(cleanedC) || elementCode.endsWith(cleanedC)
        );
      } else {
        return elementCodes.includes(c);
      }
    });
  }
  if (code.includes("-")) {
    const [start, end] = cleanedCode.split("-");
    return elementCodes.some(
      (elementCode) => elementCode >= start && elementCode <= end
    );
  }
  if (code.includes("*") || code.includes("%")) {
    return elementCodes.some(
      (elementCode) =>
        elementCode.startsWith(cleanedCode) || elementCode.endsWith(cleanedCode)
    );
  } else {
    return elementCodes.includes(cleanedCode);
  }
}

function getMeasurementValue(
  measurements: Array<{
    unit: { id: number; unit: string };
    values: Array<{ value: number }>;
  }>,
  selectedMeasurementIds: string[],
  measurementIndex: number,
  mDecimals: number,
  totalValue: number,
  currency: string
) {
  if (!selectedMeasurementIds) {
    return "";
  }
  const selectedMeasurementId = parseInt(
    selectedMeasurementIds[measurementIndex]
  );
  const selectedMeasurementData = measurements.find(
    (measurement) => measurement.unit.id === selectedMeasurementId
  );
  if (!selectedMeasurementData) {
    return "";
  }
  const value =
    safeDivide(totalValue, selectedMeasurementData.values[0].value) ?? 0;
  return `${round(value, mDecimals)} ${currency}/${
    selectedMeasurementData.unit.unit
  }`;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function log(value: any) {
  console.log(value);
  return true;
}

export const reportCustomCodes = [
  {
    name: "ArrayContainsValue",
    body: arrayContainsValue,
    info: {
      description: "Check if array contains value, return boolean",
      example: "Code.arrayContainsValue([1, 2, 3, 4, 5], 2)",
      syntax: "Code.arrayContainsValue(<Array>, <Array>)",
    },
  },
  {
    name: "ArrayContainsItemsOfArray",
    body: arrayContainsItemsOfArray,
    info: {
      description:
        "Check if other array contains items from first, return boolean",
      example: "Code.arrayContainsItemsOfArray([1, 2, 3, 4, 5], [2, 4])",
      syntax: "Code.arrayContainsItemsOfArray(<Array>, <Array>)",
    },
  },
  {
    name: "RowFilter",
    body: rowFilter,
    info: {
      description: "Filter rows based on checked and flagged columns",
      example: "Code.rowFilter(true, false, ['checked'])",
      syntax: "Code.rowFilter(<boolean>, <boolean>, <Array>)",
    },
  },
  {
    name: "FilterCode",
    body: filterCode,
    info: {
      description: "Filter code based on a range",
      example: "Code.filterCode('123', '100-200')",
      syntax: "Code.filterCode(<string>, <string>)",
    },
  },
  {
    name: "CalculateTotalPrice",
    body: calculateTotalPrice,
    info: {
      description: "Calculate total price based on social expenses",
      example: "Code.calculateTotalPrice(true, 100, 200)",
      syntax: "Code.calculateTotalPrice(<boolean>, <number>, <number>)",
    },
  },
  {
    name: "FilterTotalPrice",
    body: filterTotalPrice,
    info: {
      description: "Filter total price",
      example: "Code.filterTotalPrice(100)",
      syntax: "Code.filterTotalPrice(<number>)",
    },
  },
  {
    name: "FilterMemo",
    body: filterMemo,
    info: {
      description: "Filter memo",
      example: "Code.filterMemo('memo', true)",
      syntax: "Code.filterMemo(<string>, <boolean>)",
    },
  },
  {
    name: "FilterRowsWithMemo",
    body: filterRowsWithMemo,
    info: {
      description: "Filter rows with memo",
      example: "Code.filterRowsWithMemo('memo', true)",
      syntax: "Code.filterRowsWithMemo(<string>, <boolean>)",
    },
  },
  {
    name: "ZeroDivide",
    body: zeroDivide,
    info: {
      description: "Divide two numbers, return 0 if denominator is 0",
      example: "Code.zeroDivide(10, 0)",
      syntax: "Code.zeroDivide(<number>, <number>)",
    },
  },
  {
    name: "GetCostClassCodes",
    body: getCostClassCodes,
    info: {
      description: "Get cost class codes",
      example: "Code.getCostClassCodes(Fields!costClasses.Value)",
      syntax: "Code.getCostClassCodes(<Array>)",
    },
  },
  {
    name: "FilterElementsByClass",
    body: filterElementsByClass,
    info: {
      description: "Filter elements by class",
      example: "Code.filterElementsByClass(Fields!elements.Value, 'class')",
      syntax: "Code.filterElementsByClass(<Array>, <string>)",
    },
  },
  {
    name: "FilterElementsByCode",
    body: filterElementsByCode,
    info: {
      description: "Filter elements by code",
      example: "Code.filterElementsByCode(Fields!elements.Value, 'code')",
      syntax: "Code.filterElementsByCode(<Array>, <string>)",
    },
  },
  {
    name: "GetMeasurementValue",
    body: getMeasurementValue,
    info: {
      description: "Get measurement value",
      example:
        "Code.getMeasurementValue(Fields!measurements.Value, 1, 100, '€')",
      syntax: "Code.getMeasurementValue(<Array>, <number>, <number>, <string>)",
    },
  },
  {
    name: "Log",
    body: log,
    info: {
      description: "Log value",
      example: "Code.log('value')",
      syntax: "Code.log(<any>)",
    },
  },
];
