// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var filterUsersStyles = Box.boxStyle([ Box.Width.create(new TacoConstants.CustomSize("16rem")) ]);
var emailInputsStyles = Box.boxStyle([ Box.FlexColumn.value, Box.AlignStart.value ]);
var emailInputStyles = Box.boxStyle([ new Box.PaddingBottom(TacoConstants.M.value), Box.Width.create(new TacoConstants.CustomSize("16rem")) ]);
module.exports = {
    emailInputsStyles: emailInputsStyles,
    emailInputStyles: emailInputStyles,
    filterUsersStyles: filterUsersStyles
};
