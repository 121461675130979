import { useQuery } from "react-query";
import axiosClient from "../../../../axiosConfig";
import { PlanningCostGroup } from "../../../../../../ts-bindings/PlanningCostGroup";

export function useGetPlanningCostGroups(
  projectId: number
): PlanningCostGroup[] {
  const { data } = useQuery({
    queryKey: ["planningCostGroups", projectId],
    queryFn: async () => {
      const { data } = await axiosClient.get<PlanningCostGroup[]>(
        `/project/${projectId}/planningCostGroups`
      );
      return data;
    },
  });
  return data ?? [];
}
