import { GridApi, RowDataUpdatedEvent } from "ag-grid-community";
import { useGridEventListener } from "./useGridEventListener";
import { useState } from "react";
import { difference } from "lodash";

export function useSelectRowsAsync(api: GridApi | null | undefined) {
  const [ids, setIds] = useState<string[] | undefined>();

  useGridEventListener<RowDataUpdatedEvent>(
    api,
    "rowDataUpdated",
    ({ api }) => {
      if (!ids) return;

      const selectedIds: string[] = [];

      api.forEachNode((rowNode) => {
        const selected = ids.find((id) => id === rowNode.id);
        if (!selected) {
          return;
        }
        rowNode.setSelected(true);
        api.ensureNodeVisible(rowNode);
        selectedIds.push(selected);
      });

      if (selectedIds.length > 0) {
        const leftForSelection = difference(ids, selectedIds);

        if (leftForSelection.length === 0) {
          setIds(undefined);
        } else {
          setIds(leftForSelection);
        }
      }
    }
  );

  return (ids: string[]) => setIds(ids);
}
