// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Locale = require("../Locale/index.js");
var NarrowLayout = require("../NarrowLayout/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_FrontEndRoutes = require("../Types.FrontEndRoutes/index.js");
var component = TofuHooks.mkHookComponent("NotFound")(function (props) {
    return function __do() {
        var appDispatch = StateHooks.useDispatch();
        return NarrowLayout.component()()({
            verticalPadding: true,
            children: [ TacoText.component()()({
                size: TacoConstants.L.value,
                weight: TacoText_Types.Bold.value,
                variant: new TacoText_Types.Heading(2),
                text: Locale.lookup_("common_not_found"),
                gutterBottom: true
            }), TacoText.component()()({
                text: props.message,
                variant: TacoText_Types.Paragraph.value,
                gutterBottom: true
            }), TacoButton.component()()({
                href: new Data_Maybe.Just("/"),
                text: Locale.lookup_("not_found__navigate_to_projects"),
                buttonStyle: TacoButton_Types.Filled.value,
                onClick: Data_Maybe.Just.create(appDispatch(new Actions.NavigateRoute(Types_FrontEndRoutes.ReportingProjectListRoute.value)))
            }) ]
        });
    };
});
module.exports = {
    component: component
};
