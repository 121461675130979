// Generated by purs version 0.14.5
"use strict";
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Kishimen = require("../Kishimen/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_Project = require("../Types.Project/index.js");
var Standby = (function () {
    function Standby() {

    };
    Standby.value = new Standby();
    return Standby;
})();
var Fetching = (function () {
    function Fetching() {

    };
    Fetching.value = new Fetching();
    return Fetching;
})();
var $$Error = (function () {
    function $$Error(value0) {
        this.value0 = value0;
    };
    $$Error.create = function (value0) {
        return new $$Error(value0);
    };
    return $$Error;
})();
var Fetched = (function () {
    function Fetched(value0) {
        this.value0 = value0;
    };
    Fetched.create = function (value0) {
        return new Fetched(value0);
    };
    return Fetched;
})();
var needsFetchingGeneral = function (rd) {
    if (rd instanceof Standby) {
        return true;
    };
    return false;
};
var needsFetchingByProjectId = function (projectId) {
    return function (rd) {
        if (rd instanceof Standby) {
            return true;
        };
        if (rd instanceof Fetched) {
            return Data_Eq.notEq(Types_Project.eqProjectId)(rd.value0.projectId)(projectId);
        };
        return false;
    };
};
var isStandby = function (v) {
    if (v instanceof Standby) {
        return true;
    };
    return false;
};
var isLoading = function (v) {
    if (v instanceof Standby) {
        return true;
    };
    if (v instanceof Fetching) {
        return true;
    };
    return false;
};
var isFetching = function (v) {
    if (v instanceof Fetching) {
        return true;
    };
    return false;
};
var isFetched = function (v) {
    if (v instanceof Fetched) {
        return true;
    };
    return false;
};
var isError = function (v) {
    if (v instanceof $$Error) {
        return true;
    };
    return false;
};
var getFetched = function (v) {
    if (v instanceof Fetched) {
        return new Data_Maybe.Just(v.value0);
    };
    return Data_Maybe.Nothing.value;
};
var genericRemoteData = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return Standby.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return Fetching.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return new $$Error(x.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr)) {
            return new Fetched(x.value0.value0.value0);
        };
        throw new Error("Failed pattern match at RemoteDataUtils (line 17, column 1 - line 17, column 66): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof Standby) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof Fetching) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
        };
        if (x instanceof $$Error) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
        };
        if (x instanceof Fetched) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0)));
        };
        throw new Error("Failed pattern match at RemoteDataUtils (line 17, column 1 - line 17, column 66): " + [ x.constructor.name ]);
    }
};
var tofuJSONRemoteData = function (dictTofuJSON) {
    return function (dictTofuJSON1) {
        return {
            readImpl: function (f) {
                return Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Kishimen.variantToGenericSum(genericRemoteData)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
                    reflectSymbol: function () {
                        return "Standby";
                    }
                })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
                    reflectSymbol: function () {
                        return "Fetching";
                    }
                })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
                    reflectSymbol: function () {
                        return "Error";
                    }
                })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
                    reflectSymbol: function () {
                        return "Fetched";
                    }
                })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
                    reflectSymbol: function () {
                        return "Error";
                    }
                }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
                    reflectSymbol: function () {
                        return "Fetched";
                    }
                })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
                    reflectSymbol: function () {
                        return "Fetching";
                    }
                }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
                    reflectSymbol: function () {
                        return "Fetched";
                    }
                }))({
                    reflectSymbol: function () {
                        return "Error";
                    }
                })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
                    reflectSymbol: function () {
                        return "Standby";
                    }
                }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
                    reflectSymbol: function () {
                        return "Fetching";
                    }
                }))({
                    reflectSymbol: function () {
                        return "Fetched";
                    }
                }))({
                    reflectSymbol: function () {
                        return "Error";
                    }
                }))))(TofuJSON.readImpl(TofuJSON.tofuJSONVariant()(TofuJSON.readVariantCons({
                    reflectSymbol: function () {
                        return "Error";
                    }
                })(dictTofuJSON)()(TofuJSON.readVariantCons({
                    reflectSymbol: function () {
                        return "Fetched";
                    }
                })(dictTofuJSON1)()(TofuJSON.readVariantCons({
                    reflectSymbol: function () {
                        return "Fetching";
                    }
                })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
                    reflectSymbol: function () {
                        return "Standby";
                    }
                })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))))(TofuJSON.consWriteForeignVariant({
                    reflectSymbol: function () {
                        return "Error";
                    }
                })(dictTofuJSON)()(TofuJSON.consWriteForeignVariant({
                    reflectSymbol: function () {
                        return "Fetched";
                    }
                })(dictTofuJSON1)()(TofuJSON.consWriteForeignVariant({
                    reflectSymbol: function () {
                        return "Fetching";
                    }
                })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
                    reflectSymbol: function () {
                        return "Standby";
                    }
                })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant))))))(f));
            },
            writeImpl: function (v) {
                return TofuJSON.writeImpl(TofuJSON.tofuJSONVariant()(TofuJSON.readVariantCons({
                    reflectSymbol: function () {
                        return "Error";
                    }
                })(dictTofuJSON)()(TofuJSON.readVariantCons({
                    reflectSymbol: function () {
                        return "Fetched";
                    }
                })(dictTofuJSON1)()(TofuJSON.readVariantCons({
                    reflectSymbol: function () {
                        return "Fetching";
                    }
                })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
                    reflectSymbol: function () {
                        return "Standby";
                    }
                })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))))(TofuJSON.consWriteForeignVariant({
                    reflectSymbol: function () {
                        return "Error";
                    }
                })(dictTofuJSON)()(TofuJSON.consWriteForeignVariant({
                    reflectSymbol: function () {
                        return "Fetched";
                    }
                })(dictTofuJSON1)()(TofuJSON.consWriteForeignVariant({
                    reflectSymbol: function () {
                        return "Fetching";
                    }
                })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
                    reflectSymbol: function () {
                        return "Standby";
                    }
                })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant))))))(Kishimen.genericSumToVariant(genericRemoteData)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
                    reflectSymbol: function () {
                        return "Standby";
                    }
                })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
                    reflectSymbol: function () {
                        return "Fetching";
                    }
                })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
                    reflectSymbol: function () {
                        return "Error";
                    }
                })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
                    reflectSymbol: function () {
                        return "Fetched";
                    }
                })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
                    reflectSymbol: function () {
                        return "Error";
                    }
                }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
                    reflectSymbol: function () {
                        return "Fetched";
                    }
                })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
                    reflectSymbol: function () {
                        return "Fetching";
                    }
                }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
                    reflectSymbol: function () {
                        return "Fetched";
                    }
                }))({
                    reflectSymbol: function () {
                        return "Error";
                    }
                })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
                    reflectSymbol: function () {
                        return "Standby";
                    }
                }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
                    reflectSymbol: function () {
                        return "Fetching";
                    }
                }))({
                    reflectSymbol: function () {
                        return "Fetched";
                    }
                }))({
                    reflectSymbol: function () {
                        return "Error";
                    }
                })))(v));
            }
        };
    };
};
var eqRemoteData = function (dictEq) {
    return function (dictEq1) {
        return {
            eq: function (x) {
                return function (y) {
                    if (x instanceof Standby && y instanceof Standby) {
                        return true;
                    };
                    if (x instanceof Fetching && y instanceof Fetching) {
                        return true;
                    };
                    if (x instanceof $$Error && y instanceof $$Error) {
                        return Data_Eq.eq(dictEq)(x.value0)(y.value0);
                    };
                    if (x instanceof Fetched && y instanceof Fetched) {
                        return Data_Eq.eq(dictEq1)(x.value0)(y.value0);
                    };
                    return false;
                };
            }
        };
    };
};
module.exports = {
    Standby: Standby,
    Fetching: Fetching,
    "Error": $$Error,
    Fetched: Fetched,
    isLoading: isLoading,
    isStandby: isStandby,
    isFetching: isFetching,
    isFetched: isFetched,
    isError: isError,
    getFetched: getFetched,
    needsFetchingGeneral: needsFetchingGeneral,
    needsFetchingByProjectId: needsFetchingByProjectId,
    eqRemoteData: eqRemoteData,
    genericRemoteData: genericRemoteData,
    tofuJSONRemoteData: tofuJSONRemoteData
};
