exports.compareUnknownImpl = function (ords) {
  return function (a) {
    return function (b) {
      if (a == null && b == null) {
        return ords.eq;
      }
      if (a == null && b != null) {
        return ords.lt;
      }
      if (a != null && b == null) {
        return ords.gt;
      }
      if (a < b) {
        return ords.lt;
      }
      if (a > b) {
        return ords.gt;
      }
      if (a === b) {
        return ords.eq;
      }
      return ords.eq;
    };
  };
};
