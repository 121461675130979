// Generated by purs version 0.14.5
"use strict";
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Kishimen = require("../Kishimen/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Project = require("../Types.Project/index.js");
var AddMeasurementUnit = (function () {
    function AddMeasurementUnit() {

    };
    AddMeasurementUnit.value = new AddMeasurementUnit();
    return AddMeasurementUnit;
})();
var RemoveMeasurementUnit = (function () {
    function RemoveMeasurementUnit(value0) {
        this.value0 = value0;
    };
    RemoveMeasurementUnit.create = function (value0) {
        return new RemoveMeasurementUnit(value0);
    };
    return RemoveMeasurementUnit;
})();
var EditMeasurementUnitName = (function () {
    function EditMeasurementUnitName(value0) {
        this.value0 = value0;
    };
    EditMeasurementUnitName.create = function (value0) {
        return new EditMeasurementUnitName(value0);
    };
    return EditMeasurementUnitName;
})();
var EditMeasurementUnitCode = (function () {
    function EditMeasurementUnitCode(value0) {
        this.value0 = value0;
    };
    EditMeasurementUnitCode.create = function (value0) {
        return new EditMeasurementUnitCode(value0);
    };
    return EditMeasurementUnitCode;
})();
var UpdateMeasurementValue = (function () {
    function UpdateMeasurementValue(value0) {
        this.value0 = value0;
    };
    UpdateMeasurementValue.create = function (value0) {
        return new UpdateMeasurementValue(value0);
    };
    return UpdateMeasurementValue;
})();
var genericMeasurementUnitAction = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return AddMeasurementUnit.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new RemoveMeasurementUnit(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return new EditMeasurementUnitName(x.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return new EditMeasurementUnitCode(x.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inr))) {
            return new UpdateMeasurementValue(x.value0.value0.value0.value0);
        };
        throw new Error("Failed pattern match at Types.Measurement (line 49, column 1 - line 49, column 80): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof AddMeasurementUnit) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof RemoveMeasurementUnit) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof EditMeasurementUnitName) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
        };
        if (x instanceof EditMeasurementUnitCode) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))));
        };
        if (x instanceof UpdateMeasurementValue) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0))));
        };
        throw new Error("Failed pattern match at Types.Measurement (line 49, column 1 - line 49, column 80): " + [ x.constructor.name ]);
    }
};
var tofuJSONMeasurementUnitAction = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddMeasurementUnit";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditMeasurementUnitCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "measurementUnitId";
        }
    })(Types_Newtypes.tofuJSONMeasurementUnitId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "measurementUnitId";
        }
    })(Types_Newtypes.tofuJSONMeasurementUnitId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditMeasurementUnitName";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "measurementUnitId";
        }
    })(Types_Newtypes.tofuJSONMeasurementUnitId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "measurementUnitId";
        }
    })(Types_Newtypes.tofuJSONMeasurementUnitId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "RemoveMeasurementUnit";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "measurementUnitId";
        }
    })(Types_Newtypes.tofuJSONMeasurementUnitId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "measurementUnitId";
        }
    })(Types_Newtypes.tofuJSONMeasurementUnitId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UpdateMeasurementValue";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "measurementUnitId";
        }
    })(Types_Newtypes.tofuJSONMeasurementUnitId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONSubprojectId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "value";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "measurementUnitId";
        }
    })(Types_Newtypes.tofuJSONMeasurementUnitId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONSubprojectId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "value";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.readVariantNil))))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddMeasurementUnit";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditMeasurementUnitCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "measurementUnitId";
        }
    })(Types_Newtypes.tofuJSONMeasurementUnitId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "measurementUnitId";
        }
    })(Types_Newtypes.tofuJSONMeasurementUnitId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditMeasurementUnitName";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "measurementUnitId";
        }
    })(Types_Newtypes.tofuJSONMeasurementUnitId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "measurementUnitId";
        }
    })(Types_Newtypes.tofuJSONMeasurementUnitId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "RemoveMeasurementUnit";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "measurementUnitId";
        }
    })(Types_Newtypes.tofuJSONMeasurementUnitId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "measurementUnitId";
        }
    })(Types_Newtypes.tofuJSONMeasurementUnitId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UpdateMeasurementValue";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "measurementUnitId";
        }
    })(Types_Newtypes.tofuJSONMeasurementUnitId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONSubprojectId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "value";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "measurementUnitId";
        }
    })(Types_Newtypes.tofuJSONMeasurementUnitId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONSubprojectId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "value";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.nilWriteForeignVariant))))))(genericMeasurementUnitAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddMeasurementUnit";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "RemoveMeasurementUnit";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditMeasurementUnitName";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditMeasurementUnitCode";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UpdateMeasurementValue";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditMeasurementUnitCode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateMeasurementValue";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditMeasurementUnitName";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateMeasurementValue";
        }
    }))({
        reflectSymbol: function () {
            return "EditMeasurementUnitCode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "RemoveMeasurementUnit";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateMeasurementValue";
        }
    }))({
        reflectSymbol: function () {
            return "EditMeasurementUnitName";
        }
    }))({
        reflectSymbol: function () {
            return "EditMeasurementUnitCode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddMeasurementUnit";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateMeasurementValue";
        }
    }))({
        reflectSymbol: function () {
            return "RemoveMeasurementUnit";
        }
    }))({
        reflectSymbol: function () {
            return "EditMeasurementUnitName";
        }
    }))({
        reflectSymbol: function () {
            return "EditMeasurementUnitCode";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericMeasurementUnitAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddMeasurementUnit";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "RemoveMeasurementUnit";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditMeasurementUnitName";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditMeasurementUnitCode";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UpdateMeasurementValue";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditMeasurementUnitCode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateMeasurementValue";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditMeasurementUnitName";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateMeasurementValue";
        }
    }))({
        reflectSymbol: function () {
            return "EditMeasurementUnitCode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "RemoveMeasurementUnit";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateMeasurementValue";
        }
    }))({
        reflectSymbol: function () {
            return "EditMeasurementUnitName";
        }
    }))({
        reflectSymbol: function () {
            return "EditMeasurementUnitCode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddMeasurementUnit";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateMeasurementValue";
        }
    }))({
        reflectSymbol: function () {
            return "RemoveMeasurementUnit";
        }
    }))({
        reflectSymbol: function () {
            return "EditMeasurementUnitName";
        }
    }))({
        reflectSymbol: function () {
            return "EditMeasurementUnitCode";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddMeasurementUnit";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditMeasurementUnitCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "measurementUnitId";
        }
    })(Types_Newtypes.tofuJSONMeasurementUnitId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "measurementUnitId";
        }
    })(Types_Newtypes.tofuJSONMeasurementUnitId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditMeasurementUnitName";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "measurementUnitId";
        }
    })(Types_Newtypes.tofuJSONMeasurementUnitId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "measurementUnitId";
        }
    })(Types_Newtypes.tofuJSONMeasurementUnitId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "RemoveMeasurementUnit";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "measurementUnitId";
        }
    })(Types_Newtypes.tofuJSONMeasurementUnitId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "measurementUnitId";
        }
    })(Types_Newtypes.tofuJSONMeasurementUnitId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UpdateMeasurementValue";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "measurementUnitId";
        }
    })(Types_Newtypes.tofuJSONMeasurementUnitId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONSubprojectId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "value";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "measurementUnitId";
        }
    })(Types_Newtypes.tofuJSONMeasurementUnitId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONSubprojectId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "value";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.readVariantNil))))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddMeasurementUnit";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditMeasurementUnitCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "measurementUnitId";
        }
    })(Types_Newtypes.tofuJSONMeasurementUnitId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "measurementUnitId";
        }
    })(Types_Newtypes.tofuJSONMeasurementUnitId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditMeasurementUnitName";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "measurementUnitId";
        }
    })(Types_Newtypes.tofuJSONMeasurementUnitId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "measurementUnitId";
        }
    })(Types_Newtypes.tofuJSONMeasurementUnitId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "RemoveMeasurementUnit";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "measurementUnitId";
        }
    })(Types_Newtypes.tofuJSONMeasurementUnitId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "measurementUnitId";
        }
    })(Types_Newtypes.tofuJSONMeasurementUnitId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UpdateMeasurementValue";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "measurementUnitId";
        }
    })(Types_Newtypes.tofuJSONMeasurementUnitId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONSubprojectId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "value";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "measurementUnitId";
        }
    })(Types_Newtypes.tofuJSONMeasurementUnitId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "subprojectId";
        }
    })(TofuJSON.readMaybe(Types_Project.tofuJSONSubprojectId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "value";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.nilWriteForeignVariant))))))
};
module.exports = {
    AddMeasurementUnit: AddMeasurementUnit,
    RemoveMeasurementUnit: RemoveMeasurementUnit,
    EditMeasurementUnitName: EditMeasurementUnitName,
    EditMeasurementUnitCode: EditMeasurementUnitCode,
    UpdateMeasurementValue: UpdateMeasurementValue,
    genericMeasurementUnitAction: genericMeasurementUnitAction,
    tofuJSONMeasurementUnitAction: tofuJSONMeasurementUnitAction
};
