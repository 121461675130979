// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var AppLayout_Styles = require("../AppLayout.Styles/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var FeatureHooks = require("../FeatureHooks/index.js");
var Header = require("../Header/index.js");
var Navbar = require("../Navbar/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var RootNavbar = require("../RootNavbar/index.js");
var RouteUtils = require("../RouteUtils/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_FrontEndRoutes = require("../Types.FrontEndRoutes/index.js");
var Types_Project = require("../Types.Project/index.js");
var Unauthorized = require("../Unauthorized/index.js");
var appLayout = TofuHooks.mkHookComponent("AppLayout")(function (props) {
    return function __do() {
        var newNavbarEnabled = FeatureHooks.useFeatureEnabled("new-navigation-bar")();
        var view = React_Basic_Emotion.element(React_Basic_DOM_Generated["main'"]())({
            className: (function () {
                if (props.applicationMode instanceof Types_Project.EstimationMode) {
                    return "cost-estimation-main";
                };
                return "";
            })(),
            css: AppLayout_Styles.mainViewStyles,
            children: props.children
        });
        var reportingNavbar = (function () {
            if (props.applicationMode instanceof Types_Project.ReportingMode && props.projectId instanceof Data_Maybe.Just) {
                return Navbar.navbar({
                    projectId: props.projectId.value0,
                    currentRoute: props.route
                });
            };
            return React_Basic.empty;
        })();
        var projectIdToString = function (maybeProjectId) {
            if (maybeProjectId instanceof Data_Maybe.Just) {
                return Data_Int.fromString(RouteUtils.fromProjectId(maybeProjectId.value0));
            };
            if (maybeProjectId instanceof Data_Maybe.Nothing) {
                return Data_Maybe.Nothing.value;
            };
            throw new Error("Failed pattern match at AppLayout (line 89, column 9 - line 91, column 31): " + [ maybeProjectId.constructor.name ]);
        };
        var mkRootNavbar = function (currentRoute) {
            return RootNavbar.rootNavbar({
                currentRoute: currentRoute
            });
        };
        var rootNavbarElement = (function () {
            if (props.route instanceof Types_FrontEndRoutes.PortfolioReportFERoute) {
                return Data_Maybe.Just.create(mkRootNavbar(props.route));
            };
            if (props.route instanceof Types_FrontEndRoutes.ReportingProjectListRoute) {
                return Data_Maybe.Just.create(mkRootNavbar(props.route));
            };
            return Data_Maybe.Nothing.value;
        })();
        var estimationModeHeader = Header.header({
            projectId: props.projectId,
            applicationMode: props.applicationMode,
            currentRoute: props.route,
            subNavigation: rootNavbarElement
        });
        var header = (function () {
            if (props.applicationMode instanceof Types_Project.EstimationMode && props.route instanceof Types_FrontEndRoutes.EstimationProjectsRoute) {
                return new Data_Maybe.Just(estimationModeHeader);
            };
            if (props.applicationMode instanceof Types_Project.EstimationMode && props.route instanceof Types_FrontEndRoutes.AddEstimationProjectFERoute) {
                return new Data_Maybe.Just(estimationModeHeader);
            };
            if (props.applicationMode instanceof Types_Project.EstimationMode && props.route instanceof Types_FrontEndRoutes.ImportEstimationProjectFERoute) {
                return new Data_Maybe.Just(estimationModeHeader);
            };
            if (props.applicationMode instanceof Types_Project.EstimationMode) {
                return Data_Maybe.Nothing.value;
            };
            return Data_Maybe.Just.create(Header.header({
                projectId: props.projectId,
                applicationMode: props.applicationMode,
                currentRoute: props.route,
                subNavigation: Data_Maybe.Just.create(React_Basic.fragment([ ReactBasicUtils.maybeJSX(React_Basic.monoidJSX)(rootNavbarElement), reportingNavbar ]))
            }));
        })();
        var oldNavbar = [ ReactBasicUtils.maybeJSX(React_Basic.monoidJSX)(header), view ];
        var currentAppName = Types_FrontEndRoutes.getRouteAppName(props.route);
        var appModeToString = function (maybeStr) {
            return Data_Maybe.maybe("Nothing")(Control_Category.identity(Control_Category.categoryFn))(maybeStr);
        };
        var newNavbar = [ React_Basic.element($foreign.navBar)({
            projectId: Data_Nullable.toNullable(projectIdToString(props.projectId)),
            applicationMode: appModeToString(currentAppName)
        }), ReactBasicUtils.maybeJSX(React_Basic.monoidJSX)(header), view ];
        var content = (function () {
            if (newNavbarEnabled) {
                return newNavbar;
            };
            return oldNavbar;
        })();
        var unauthorizedContent = [ React_Basic.element($foreign.navBar)({
            projectId: Data_Nullable.toNullable(projectIdToString(props.projectId)),
            applicationMode: appModeToString(currentAppName)
        }), ReactBasicUtils.maybeJSX(React_Basic.monoidJSX)(header), Unauthorized.component({
            applicationMode: props.applicationMode
        }) ];
        var children = (function () {
            if (props.applicationMode instanceof Types_Project.ReportingMode && props.appAccessRights.costControl) {
                return content;
            };
            if (props.applicationMode instanceof Types_Project.EstimationMode && props.appAccessRights.estimation) {
                return content;
            };
            if (props.applicationMode instanceof Types_Project.IntegrationMode && props.appAccessRights.integration) {
                return content;
            };
            if (props.applicationMode instanceof Types_Project.OrganizationMode && props.appAccessRights.organization) {
                return content;
            };
            if (props.applicationMode instanceof Types_Project.AdminMode && props.appAccessRights.admin) {
                return content;
            };
            if (props.applicationMode instanceof Types_Project.SuperAdminMode && props.appAccessRights.superAdmin) {
                return content;
            };
            if (props.applicationMode instanceof Types_Project.EtcMode) {
                return content;
            };
            return unauthorizedContent;
        })();
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "layout",
            css: AppLayout_Styles.appLayoutContainerStyles,
            children: children
        });
    };
});
module.exports = {
    appLayout: appLayout
};
