// Generated by purs version 0.14.5
"use strict";
var Data_String_Common = require("../Data.String.Common/index.js");
var TacoInput = require("../TacoInput/index.js");
var emptyValidator = function (value) {
    var $0 = Data_String_Common["null"](value);
    if ($0) {
        return TacoInput.Valid.value;
    };
    return new TacoInput.Invalid("");
};
module.exports = {
    emptyValidator: emptyValidator
};
