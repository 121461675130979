// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Containers_DixonContainer_Sidepanel_Styles = require("../Containers.DixonContainer.Sidepanel.Styles/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var DixonFileInput = require("../DixonFileInput/index.js");
var DixonFileList = require("../DixonFileList/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon = require("../TacoIcon/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoTabs = require("../TacoTabs/index.js");
var TacoTabs_Types = require("../TacoTabs.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var component = TofuHooks.mkHookComponent("DixonFiles")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState(Data_Maybe.isNothing(v.mFileId))();
        var v2 = TofuHooks.useState(new Data_Maybe.Just("files"))();
        var showArchived = Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(v2.value0)(new Data_Maybe.Just("archive"));
        var filesContainer = Box.box("FilesContainer")([ new Box.FlexGrow(1), Box.MinHeight.create(new TacoConstants.CustomSize("0")), Box.FlexColumn.value, new Box.Position(Box.Relative.value) ]);
        var fileList = Box.box("FileListContainer")([ new Box.FlexGrow(1), new Box.Overflow(Box.Auto.value, Box.Auto.value), Box.MinHeight.create(new TacoConstants.CustomSize("10rem")) ])(Control_Applicative.pure(Control_Applicative.applicativeArray)(DixonFileList.component({
            editor: v.editor,
            editorState: v.editorState,
            projectId: v.projectId,
            showArchived: showArchived
        })));
        var fileInput = Box.box("FileInputContainer")([ new Box.Position(Box.Absolute.value), Box.PosBottom.create(new TacoConstants.CustomSize("0")), Box.PosLeft.create(new TacoConstants.CustomSize("0")) ])(Control_Applicative.pure(Control_Applicative.applicativeArray)(DixonFileInput.component({
            editor: v.editor,
            tutorial: Data_Array.length(v.editorState.files) === 0,
            updateFileId: Data_Maybe.Nothing.value,
            onClick: Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit)
        })));
        var expandedJsx = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "DixonFiles",
            css: Containers_DixonContainer_Sidepanel_Styles.sidepanelContainerStyles,
            children: Control_Applicative.pure(Control_Applicative.applicativeArray)(TacoTabs.controlled({
                orientation: TacoTabs_Types.Horizontal.value,
                hideContents: false,
                activeTabId: v2.value0,
                setActiveTabId: v2.value1,
                tabs: [ new TacoTabs_Types.NormalTab({
                    title: "Tiedostot",
                    quantity: Data_Maybe.Nothing.value,
                    children: [ filesContainer([ fileList, fileInput ]) ],
                    id: "files",
                    color: TacoConstants.PrimaryBlue.value
                }), new TacoTabs_Types.NormalTab({
                    title: "Arkisto",
                    quantity: Data_Maybe.Nothing.value,
                    children: [ filesContainer([ fileList ]) ],
                    id: "archive",
                    color: TacoConstants.PrimaryBlue.value
                }), TacoTabs_Types.JsxTab.create(Box.box("CloseButton")([ new Box.FlexGrow(1), Box.JustifyEnd.value ])([ TacoButton.component()()({
                    icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconClose24Px.value),
                    onClick: Data_Maybe.Just.create(v1.value1(function (v3) {
                        return false;
                    })),
                    color: TacoConstants.GrayDarkest.value
                }) ])) ]
            }))
        });
        var activeFiles = Data_Array.filter(function (f) {
            return f.active;
        })(v.editorState.files);
        var activeFile = Data_Array.head(activeFiles);
        var smallJsx = React_Basic_Emotion.element(React_Basic_DOM_Generated["button'"]())({
            className: "DixonFiles",
            css: Containers_DixonContainer_Sidepanel_Styles.smallContainerStyles,
            onClick: React_Basic_DOM_Events.capture_(v1.value1(function (v3) {
                return true;
            })),
            children: [ Box.box("DixonFilesHeader")([ new Box.FlexGrow(1), Box.FlexRow.value, Box.JustifySpaceBetween.value, Box.AlignCenter.value, new Box.Gap(TacoConstants.M.value) ])([ TacoText.component()()({
                text: Data_Maybe.fromMaybe("Ei avattua tiedostoa")(Data_Functor.map(Data_Maybe.functorMaybe)(function (v3) {
                    return v3.name;
                })(activeFile)),
                wrap: TacoText_Types.NormalWrap.value,
                overflow: TacoText_Types.TextOverflowVisible.value
            }), TacoIcon.component()()()()({
                icon: TacoIcon_Icons_Types.IconChevronDownV1.value,
                size: TacoConstants.XS.value
            }) ]) ]
        });
        if (v1.value0) {
            return expandedJsx;
        };
        return smallJsx;
    };
});
module.exports = {
    component: component
};
