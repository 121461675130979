import { Button } from "@tocoman/ui";
import { useTranslation } from "react-i18next";
import { UseMutationResult } from "react-query";
import { TargetAssessment } from "@tocoman/adminet-openapi-client";
import { AdminetParams } from "../../../../../../ts-bindings/AdminetParams";
import { mapAdminetParamsToTargetAmount } from "../mappers/AdminetParamsToInvalidTargetAmountMapper";
import { mapMissingCostGroupCodesFromTargetAmount } from "../mappers/InvalidCostGroupCodeMapper";
import { useState } from "react";
import { useAdminetCostGroupsQuery } from "../http/AdminetHttpHandler";
import { SetStateFn } from "../../../../utils/react";
import { useFeatureEnabled } from "../../../../split/useFeatureEnabled";
import { FeatureFlags } from "../../../../split/FeatureFlags";

type CostCalculationActionsProps = {
  closeModal: () => void;
  transferCalculation: UseMutationResult<
    void | Response,
    unknown,
    TargetAssessment
  >;
  selectedAdminetProjectId: string | undefined;
  resetTransferalStates: () => void;
  adminetParams: AdminetParams | undefined;
  setNoTransferableCostGroupsPresent: SetStateFn<boolean>;
  setMissingCostGroupCodes: SetStateFn<(string | undefined)[]>;
  adminetTenantId: string | null;
};

export const CostCalculationActions = ({
  closeModal,
  transferCalculation,
  selectedAdminetProjectId,
  resetTransferalStates,
  adminetParams,
  setNoTransferableCostGroupsPresent,
  setMissingCostGroupCodes,
  adminetTenantId,
}: CostCalculationActionsProps) => {
  const { t } = useTranslation("integration");

  const [validatingCostGroupCodes, setValidatingCostGroupCodes] = useState(
    false
  );

  const adminetItemsEnabled = useFeatureEnabled(
    FeatureFlags.ADMINET_INTEGRATION_RESOURCES
  );

  const adminetCostGroups = useAdminetCostGroupsQuery(adminetTenantId);

  const transferCalculationLoading = transferCalculation.isLoading;
  const transferCalculationSuccess = transferCalculation.isSuccess;

  const calculationsLoading =
    validatingCostGroupCodes || transferCalculationLoading;

  const canTransfer =
    selectedAdminetProjectId &&
    !calculationsLoading &&
    !validatingCostGroupCodes;

  if (transferCalculationSuccess) {
    return <Button onClick={closeModal}>{t`ok`}</Button>;
  }

  const validateCostGroups = (targetAmount: TargetAssessment): boolean => {
    setValidatingCostGroupCodes(true);

    if (targetAmount.letters && targetAmount.letters.length === 0) {
      setNoTransferableCostGroupsPresent(true);
      setValidatingCostGroupCodes(false);
      return false;
    }

    const fetchedCostGroups = adminetCostGroups.data;
    if (!fetchedCostGroups) {
      return false;
    }

    const missingCostGroups = mapMissingCostGroupCodesFromTargetAmount(
      targetAmount,
      fetchedCostGroups
    );

    setValidatingCostGroupCodes(false);

    if (missingCostGroups.length > 0) {
      setMissingCostGroupCodes(missingCostGroups);
      return false;
    }
    return true;
  };

  const onTransferCalculation = (): void => {
    resetTransferalStates();

    if (!adminetParams) {
      throw new Error("adminetParams are not defined");
    }

    const targetAmount = mapAdminetParamsToTargetAmount(
      adminetParams,
      adminetItemsEnabled
    );

    if (validateCostGroups(targetAmount)) {
      transferCalculation.mutate(targetAmount);
    }
  };

  return (
    <div className="ml-auto flex gap-2">
      <Button variant="text" onClick={closeModal}>
        {t`cancel`}
      </Button>
      <Button
        onClick={onTransferCalculation}
        disabled={!canTransfer}
        loading={calculationsLoading}
      >
        {validatingCostGroupCodes &&
          t`adminet.transferCalculation.validatingCostGroupsInProgress`}
        {transferCalculationLoading &&
          t`adminet.transferCalculation.transferringCalculationInProgress`}
        {!calculationsLoading && t`adminet.transferCalculation.doTransfer`}
      </Button>
    </div>
  );
};
