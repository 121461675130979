// Generated by purs version 0.14.5
"use strict";
var ArrayUtils = require("../ArrayUtils/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var CostEstimation_Filters = require("../CostEstimation.Filters/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Data_Variant = require("../Data.Variant/index.js");
var Effect = require("../Effect/index.js");
var Gap = require("../Gap/index.js");
var Kishimen = require("../Kishimen/index.js");
var React_Basic = require("../React.Basic/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoFilter = require("../TacoFilter/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoSelect = require("../TacoSelect/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_CostEstimation = require("../Types.CostEstimation/index.js");
var mkUtils = function (props) {
    var updateFilterAt = function (idx) {
        return function (filter) {
            return Data_Maybe.fromMaybe(props.activeFilters)(Data_Array.updateAt(idx)(filter)(props.activeFilters));
        };
    };
    var deleteFilterAt = function (idx) {
        return Data_Maybe.fromMaybe(props.activeFilters)(Data_Array.deleteAt(idx)(props.activeFilters));
    };
    var addActiveFilter = function (filterType) {
        return props.onSetActiveFilters(Data_Array.snoc(props.activeFilters)(filterType));
    };
    return {
        updateFilterAt: updateFilterAt,
        deleteFilterAt: deleteFilterAt,
        addActiveFilter: addActiveFilter
    };
};
var mkToggleFilter = function (dictGeneric) {
    return function (dictGenericSumToVariant) {
        return function (filters) {
            return function (setFilters) {
                return function (dictIsSymbol) {
                    return function (dictCons) {
                        return function (value) {
                            return function (cnameP) {
                                var mkFilter = (function () {
                                    var $62 = Kishimen.variantToGenericSum(dictGeneric)(dictGenericSumToVariant);
                                    var $63 = Data_Variant.inj()(dictIsSymbol)(cnameP);
                                    return function ($64) {
                                        return $62($63($64));
                                    };
                                })();
                                var extractFilter = (function () {
                                    var $65 = Data_Variant.prj()(dictIsSymbol)(Data_Maybe.alternativeMaybe)(cnameP);
                                    var $66 = Kishimen.genericSumToVariant(dictGeneric)(dictGenericSumToVariant);
                                    return function ($67) {
                                        return $65($66($67));
                                    };
                                })();
                                var matchesFilter = function ($68) {
                                    return Data_Maybe.isJust(extractFilter($68));
                                };
                                var mMatchIndex = Data_Array.findIndex(matchesFilter)(filters);
                                var mMatch = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Data_Array.index(filters))(mMatchIndex);
                                var v = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(extractFilter)(mMatch);
                                if (mMatchIndex instanceof Data_Maybe.Just && v instanceof Data_Maybe.Just) {
                                    var newValues = ArrayUtils.toggleInArray(Data_Eq.eqString)(value)(v.value0.values);
                                    var newGroupFilter = mkFilter({
                                        state: TacoFilter.FVSPreviouslySet.value,
                                        values: newValues
                                    });
                                    var mNewFilters = (function () {
                                        var $13 = Data_Array["null"](newValues);
                                        if ($13) {
                                            return Data_Array.deleteAt(mMatchIndex.value0)(filters);
                                        };
                                        return Data_Array.updateAt(mMatchIndex.value0)(newGroupFilter)(filters);
                                    })();
                                    return Data_Foldable.sequence_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(setFilters)(mNewFilters));
                                };
                                return setFilters(Data_Array.snoc(filters)(mkFilter({
                                    state: TacoFilter.FVSPreviouslySet.value,
                                    values: [ value ]
                                })));
                            };
                        };
                    };
                };
            };
        };
    };
};
var mkTacoFilter = function (utils) {
    return function (onSetActiveFilters) {
        return function (useTacoFilterText) {
            return function (label) {
                return function (ctr) {
                    return function (idx) {
                        return function (testId) {
                            return function (filterValues) {
                                return TacoFilter.tacoFilter({
                                    filterValues: filterValues,
                                    label: label,
                                    id: Data_Show.show(Data_Show.showInt)(idx),
                                    onFilterUpdate: (function () {
                                        var $69 = utils.updateFilterAt(idx);
                                        return function ($70) {
                                            return onSetActiveFilters($69(ctr($70)));
                                        };
                                    })(),
                                    onFilterRemove: onSetActiveFilters(utils.deleteFilterAt(idx)),
                                    testId: testId,
                                    useTacoFilterText: useTacoFilterText
                                });
                            };
                        };
                    };
                };
            };
        };
    };
};
var mkEstimationFilter = function (name) {
    return function (mkMethods) {
        return TofuHooks.mkHookComponent(name)(function (props) {
            var methods = mkMethods(props);
            var filterInputs = Gap.gapX(TacoConstants.S.value)(Data_Array.mapWithIndex(methods.renderFilterInput)(props.activeFilters));
            var addFilterBtn = TacoSelect.component()()({
                value: "",
                onChange: methods.onAddFilterBtnChange,
                items: methods.selectionItems,
                testId: name + "-add-filter"
            });
            return Control_Applicative.pure(Effect.applicativeEffect)(Gap.gapX(TacoConstants.S.value)([ filterInputs, addFilterBtn ]));
        });
    };
};
var orgResourcePriceFilter = mkEstimationFilter("OrgResourcePriceFilter")(function (props) {
    var utils = mkUtils(props);
    var selectionItems = Data_Array.catMaybes([ new Data_Maybe.Just({
        value: "",
        text: "+ Lis\xe4\xe4 rajaus"
    }), new Data_Maybe.Just({
        value: "name",
        text: "Nimi"
    }), new Data_Maybe.Just({
        value: "priceListName",
        text: "Hinnasto"
    }), new Data_Maybe.Just({
        value: "group",
        text: "Ryhm\xe4"
    }), new Data_Maybe.Just({
        value: "vendor",
        text: "Toimittaja"
    }), new Data_Maybe.Just({
        value: "costClassCode",
        text: "PL"
    }) ]);
    var onAddFilterBtnChange = TacoInput.changeHandler(TacoInput.inputValueString)(function (value) {
        if (value === "name") {
            return utils.addActiveFilter(new CostEstimation_Filters.FilterByOrgResourcePriceName(TacoFilter.emptyFilterValues));
        };
        if (value === "priceListName") {
            return utils.addActiveFilter(new CostEstimation_Filters.FilterByOrgResourcePricePriceListName(TacoFilter.emptyFilterValues));
        };
        if (value === "group") {
            return utils.addActiveFilter(new CostEstimation_Filters.FilterByOrgResourcePriceGroup(TacoFilter.emptyFilterValues));
        };
        if (value === "vendor") {
            return utils.addActiveFilter(new CostEstimation_Filters.FilterByOrgResourcePriceVendor(TacoFilter.emptyFilterValues));
        };
        if (value === "costClassCode") {
            return utils.addActiveFilter(new CostEstimation_Filters.FilterByOrgResourcePriceCostClassCode(TacoFilter.emptyFilterValues));
        };
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    });
    var mkTacoFilter$prime = mkTacoFilter(utils)(props.onSetActiveFilters)(TacoFilter.UseTacoFilterText.value);
    var renderFilterInput = function (idx) {
        return function (v) {
            if (v instanceof CostEstimation_Filters.FilterByOrgResourcePriceName) {
                return mkTacoFilter$prime("Nimi")(CostEstimation_Filters.FilterByOrgResourcePriceName.create)(idx)("org-resource-price-name")(v.value0);
            };
            if (v instanceof CostEstimation_Filters.FilterByOrgResourcePricePriceListName) {
                return mkTacoFilter$prime("Hinnasto")(CostEstimation_Filters.FilterByOrgResourcePricePriceListName.create)(idx)("org-resource-price-list-name")(v.value0);
            };
            if (v instanceof CostEstimation_Filters.FilterByOrgResourcePriceGroup) {
                return mkTacoFilter$prime("Ryhm\xe4")(CostEstimation_Filters.FilterByOrgResourcePriceGroup.create)(idx)("org-resource-price-group")(v.value0);
            };
            if (v instanceof CostEstimation_Filters.FilterByOrgResourcePriceVendor) {
                return mkTacoFilter$prime("Toimittaja")(CostEstimation_Filters.FilterByOrgResourcePriceVendor.create)(idx)("org-resource-price-vendor")(v.value0);
            };
            if (v instanceof CostEstimation_Filters.FilterByOrgResourcePriceCostClassCode) {
                return mkTacoFilter$prime("PL")(CostEstimation_Filters.FilterByOrgResourcePriceCostClassCode.create)(idx)("org-resource-price-cost-class-code")(v.value0);
            };
            throw new Error("Failed pattern match at EstimationComponentFilter (line 204, column 5 - line 204, column 153): " + [ idx.constructor.name, v.constructor.name ]);
        };
    };
    return {
        renderFilterInput: renderFilterInput,
        onAddFilterBtnChange: onAddFilterBtnChange,
        selectionItems: selectionItems
    };
});
var estimationElementsFilter = mkEstimationFilter("EstimationElementsFilter")(function (props) {
    var utils = mkUtils(props);
    var selectionItems = Data_Array.catMaybes([ new Data_Maybe.Just({
        value: "",
        text: "+ Lis\xe4\xe4 rajaus"
    }), new Data_Maybe.Just({
        value: "subProject",
        text: "Alakohde"
    }), new Data_Maybe.Just({
        value: "location",
        text: "Sijainti"
    }), new Data_Maybe.Just({
        value: "group",
        text: "Ryhm\xe4"
    }), new Data_Maybe.Just({
        value: "code",
        text: "Luokka"
    }), new Data_Maybe.Just({
        value: "rtCode",
        text: "Koodi"
    }), new Data_Maybe.Just({
        value: "description",
        text: "Luokan nimi"
    }), new Data_Maybe.Just({
        value: "rtDescription",
        text: "Selite"
    }) ]);
    var onAddFilterBtnChange = TacoInput.changeHandler(TacoInput.inputValueString)(function (value) {
        if (value === "subProject") {
            return utils.addActiveFilter(new CostEstimation_Filters.FilterByElementsDataSubProjectCode(TacoFilter.emptyFilterValues));
        };
        if (value === "location") {
            return utils.addActiveFilter(new CostEstimation_Filters.FilterByElementsDataLocationCode(TacoFilter.emptyFilterValues));
        };
        if (value === "group") {
            return utils.addActiveFilter(new CostEstimation_Filters.FilterByElementsDataGroup(TacoFilter.emptyFilterValues));
        };
        if (value === "code") {
            return utils.addActiveFilter(new CostEstimation_Filters.FilterByElementsDataElementCode(TacoFilter.emptyFilterValues));
        };
        if (value === "no-code-match") {
            return utils.addActiveFilter(new CostEstimation_Filters.FilterByElementsDataElementNoCodeMatch(TacoFilter.emptyNoFocusState));
        };
        if (value === "rtCode") {
            return utils.addActiveFilter(new CostEstimation_Filters.FilterByElementsDataElementRtCode(TacoFilter.emptyFilterValues));
        };
        if (value === "description") {
            return utils.addActiveFilter(new CostEstimation_Filters.FilterByElementsDataDescription(TacoFilter.emptyFilterValues));
        };
        if (value === "rtDescription") {
            return utils.addActiveFilter(new CostEstimation_Filters.FilterByElementsDataRtDescription(TacoFilter.emptyFilterValues));
        };
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    });
    var mkTacoFilter$prime = mkTacoFilter(utils)(props.onSetActiveFilters);
    var renderFilterInput = function (v) {
        return function (v1) {
            if (v1 instanceof CostEstimation_Filters.FilterByElementsDataSubProjectCode) {
                return mkTacoFilter$prime(TacoFilter.UseTacoFilterText.value)("Alakohde")(CostEstimation_Filters.FilterByElementsDataSubProjectCode.create)(v)("sub-project-code")(v1.value0);
            };
            if (v1 instanceof CostEstimation_Filters.FilterByElementsDataLocationCode) {
                return mkTacoFilter$prime(TacoFilter.UseTacoFilterText.value)("Sijainti")(CostEstimation_Filters.FilterByElementsDataLocationCode.create)(v)("location")(v1.value0);
            };
            if (v1 instanceof CostEstimation_Filters.FilterByElementsDataGroup) {
                return mkTacoFilter$prime(TacoFilter.UseTacoFilterText.value)("Ryhm\xe4")(CostEstimation_Filters.FilterByElementsDataGroup.create)(v)("group")(v1.value0);
            };
            if (v1 instanceof CostEstimation_Filters.FilterByElementsWithoutDataGroup) {
                return mkTacoFilter$prime(TacoFilter.DontUseTacoFilterText.value)("Ryhmittelem\xe4tt\xf6m\xe4t")(CostEstimation_Filters.FilterByElementsWithoutDataGroup.create)(v)("no-group")(TacoFilter.emptyNoFocusState);
            };
            if (v1 instanceof CostEstimation_Filters.FilterByElementsDataElementCode) {
                return mkTacoFilter$prime(TacoFilter.UseTacoFilterText.value)("Luokka")(CostEstimation_Filters.FilterByElementsDataElementCode.create)(v)("code")(v1.value0);
            };
            if (v1 instanceof CostEstimation_Filters.FilterByElementsDataElementNoCodeMatch) {
                return mkTacoFilter$prime(TacoFilter.DontUseTacoFilterText.value)("Muu koodi")(CostEstimation_Filters.FilterByElementsDataElementNoCodeMatch.create)(v)("no-code-match")(TacoFilter.emptyNoFocusState);
            };
            if (v1 instanceof CostEstimation_Filters.FilterByElementsDataElementRtCode) {
                return mkTacoFilter$prime(TacoFilter.UseTacoFilterText.value)("Koodi")(CostEstimation_Filters.FilterByElementsDataElementRtCode.create)(v)("rt-code")(v1.value0);
            };
            if (v1 instanceof CostEstimation_Filters.FilterByElementsDataDescription) {
                return mkTacoFilter$prime(TacoFilter.UseTacoFilterText.value)("Luokan nimi")(CostEstimation_Filters.FilterByElementsDataDescription.create)(v)("description")(v1.value0);
            };
            if (v1 instanceof CostEstimation_Filters.FilterByElementsDataRtDescription) {
                return mkTacoFilter$prime(TacoFilter.UseTacoFilterText.value)("Selite")(CostEstimation_Filters.FilterByElementsDataRtDescription.create)(v)("rt-description")(v1.value0);
            };
            if (v1 instanceof CostEstimation_Filters.FilterByElementsDataProjectOfOrigin) {
                return React_Basic.empty;
            };
            if (v1 instanceof CostEstimation_Filters.FilterByElementsDataSearch) {
                return React_Basic.empty;
            };
            throw new Error("Failed pattern match at EstimationComponentFilter (line 80, column 5 - line 80, column 198): " + [ v.constructor.name, v1.constructor.name ]);
        };
    };
    return {
        renderFilterInput: renderFilterInput,
        onAddFilterBtnChange: onAddFilterBtnChange,
        selectionItems: selectionItems
    };
});
var estimationComponentFilter = mkEstimationFilter("EstimationComponentFilter")(function (props) {
    var utils = mkUtils(props);
    var selectionItems = Data_Array.catMaybes([ new Data_Maybe.Just({
        value: "",
        text: "+ Lis\xe4\xe4 rajaus"
    }), (function () {
        if (props.viewMode instanceof Types_CostEstimation.EditMode) {
            return new Data_Maybe.Just({
                value: "subProject",
                text: "Alakohde"
            });
        };
        if (props.viewMode instanceof Types_CostEstimation.ReferenceMode) {
            return Data_Maybe.Nothing.value;
        };
        throw new Error("Failed pattern match at EstimationComponentFilter (line 171, column 9 - line 173, column 35): " + [ props.viewMode.constructor.name ]);
    })(), new Data_Maybe.Just({
        value: "location",
        text: "Sijainti"
    }), new Data_Maybe.Just({
        value: "group",
        text: "Ryhm\xe4"
    }), (function () {
        if (props.showTargetCostGroup) {
            return new Data_Maybe.Just({
                value: "costGroupCode",
                text: "Littera"
            });
        };
        return Data_Maybe.Nothing.value;
    })(), new Data_Maybe.Just({
        value: "code",
        text: "Koodi"
    }), new Data_Maybe.Just({
        value: "description",
        text: "Selite"
    }), new Data_Maybe.Just({
        value: "has-memo",
        text: "On muistio"
    }), new Data_Maybe.Just({
        value: "no-memo",
        text: "Ei muistiota"
    }), new Data_Maybe.Just({
        value: "has-flag",
        text: "On lippu"
    }), new Data_Maybe.Just({
        value: "no-flag",
        text: "Ei lippua"
    }), new Data_Maybe.Just({
        value: "has-check",
        text: "On merkki"
    }), new Data_Maybe.Just({
        value: "no-check",
        text: "Ei merkki\xe4"
    }), new Data_Maybe.Just({
        value: "no-group",
        text: "Ryhmittelem\xe4tt\xf6m\xe4t"
    }), new Data_Maybe.Just({
        value: "no-cost-group",
        text: "Litteroimattomat"
    }), new Data_Maybe.Just({
        value: "no-matching-cost-group",
        text: "Litterat, joita ei ole litteranimikkeist\xf6ss\xe4"
    }) ]);
    var onAddFilterBtnChange = TacoInput.changeHandler(TacoInput.inputValueString)(function (value) {
        if (value === "subProject") {
            return utils.addActiveFilter(new CostEstimation_Filters.FilterByComponentSubProjectCode(TacoFilter.emptyFilterValues));
        };
        if (value === "location") {
            return utils.addActiveFilter(new CostEstimation_Filters.FilterByComponentLocationCode(TacoFilter.emptyFilterValues));
        };
        if (value === "group") {
            return utils.addActiveFilter(new CostEstimation_Filters.FilterByComponentGroup(TacoFilter.emptyFilterValues));
        };
        if (value === "costGroupCode") {
            return utils.addActiveFilter(new CostEstimation_Filters.FilterByComponentCostGroupCode(TacoFilter.emptyFilterValues));
        };
        if (value === "code") {
            return utils.addActiveFilter(new CostEstimation_Filters.FilterByComponentCode(TacoFilter.emptyFilterValues));
        };
        if (value === "description") {
            return utils.addActiveFilter(new CostEstimation_Filters.FilterByComponentDescription(TacoFilter.emptyFilterValues));
        };
        if (value === "has-memo") {
            return utils.addActiveFilter(new CostEstimation_Filters.FilterByComponentHasMemo(TacoFilter.emptyNoFocusState));
        };
        if (value === "no-memo") {
            return utils.addActiveFilter(new CostEstimation_Filters.FilterByComponentNoMemo(TacoFilter.emptyNoFocusState));
        };
        if (value === "has-flag") {
            return utils.addActiveFilter(new CostEstimation_Filters.FilterByComponentHasFlag(TacoFilter.emptyNoFocusState));
        };
        if (value === "no-flag") {
            return utils.addActiveFilter(new CostEstimation_Filters.FilterByComponentNoFlag(TacoFilter.emptyNoFocusState));
        };
        if (value === "has-check") {
            return utils.addActiveFilter(new CostEstimation_Filters.FilterByComponentHasCheck(TacoFilter.emptyNoFocusState));
        };
        if (value === "no-check") {
            return utils.addActiveFilter(new CostEstimation_Filters.FilterByComponentNoCheck(TacoFilter.emptyNoFocusState));
        };
        if (value === "no-cost-group") {
            return utils.addActiveFilter(new CostEstimation_Filters.FilterByComponentWithoutCostGroup(TacoFilter.emptyNoFocusState));
        };
        if (value === "no-code-match") {
            return utils.addActiveFilter(new CostEstimation_Filters.FilterByComponentNoCodeMatch(TacoFilter.emptyNoFocusState));
        };
        if (value === "no-group") {
            return utils.addActiveFilter(new CostEstimation_Filters.FilterByComponentWithoutGroup(TacoFilter.emptyNoFocusState));
        };
        if (value === "no-code-match") {
            return utils.addActiveFilter(new CostEstimation_Filters.FilterByComponentNoCodeMatch(TacoFilter.emptyNoFocusState));
        };
        if (value === "no-matching-cost-group") {
            return utils.addActiveFilter(new CostEstimation_Filters.FilterByComponentNonMatchingCostGroup(TacoFilter.emptyNoFocusState));
        };
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    });
    var mkTacoFilter$prime = mkTacoFilter(utils)(props.onSetActiveFilters);
    var renderFilterInput = function (v) {
        return function (v1) {
            if (v1 instanceof CostEstimation_Filters.FilterByComponentGroup) {
                return mkTacoFilter$prime(TacoFilter.UseTacoFilterText.value)("Ryhm\xe4")(CostEstimation_Filters.FilterByComponentGroup.create)(v)("group")(v1.value0);
            };
            if (v1 instanceof CostEstimation_Filters.FilterByComponentCostGroupCode) {
                return mkTacoFilter$prime(TacoFilter.UseTacoFilterText.value)("Littera")(CostEstimation_Filters.FilterByComponentCostGroupCode.create)(v)("cost-group-code")(v1.value0);
            };
            if (v1 instanceof CostEstimation_Filters.FilterByComponentCode) {
                return mkTacoFilter$prime(TacoFilter.UseTacoFilterText.value)("Koodi")(CostEstimation_Filters.FilterByComponentCode.create)(v)("code")(v1.value0);
            };
            if (v1 instanceof CostEstimation_Filters.FilterByComponentDescription) {
                return mkTacoFilter$prime(TacoFilter.UseTacoFilterText.value)("Selite")(CostEstimation_Filters.FilterByComponentDescription.create)(v)("description")(v1.value0);
            };
            if (v1 instanceof CostEstimation_Filters.FilterByComponentSubProjectCode) {
                return mkTacoFilter$prime(TacoFilter.UseTacoFilterText.value)("Alakohde")(CostEstimation_Filters.FilterByComponentSubProjectCode.create)(v)("sub-project-code")(v1.value0);
            };
            if (v1 instanceof CostEstimation_Filters.FilterByComponentProjectOfOrigin) {
                return React_Basic.empty;
            };
            if (v1 instanceof CostEstimation_Filters.FilterByComponentLocationCode) {
                return mkTacoFilter$prime(TacoFilter.UseTacoFilterText.value)("Sijainti")(CostEstimation_Filters.FilterByComponentLocationCode.create)(v)("location-code")(v1.value0);
            };
            if (v1 instanceof CostEstimation_Filters.FilterByComponentHasMemo) {
                return mkTacoFilter$prime(TacoFilter.DontUseTacoFilterText.value)("On muistio")(CostEstimation_Filters.FilterByComponentHasMemo.create)(v)("has-memo")(TacoFilter.emptyNoFocusState);
            };
            if (v1 instanceof CostEstimation_Filters.FilterByComponentNoMemo) {
                return mkTacoFilter$prime(TacoFilter.DontUseTacoFilterText.value)("Ei muistiota")(CostEstimation_Filters.FilterByComponentNoMemo.create)(v)("no-memo")(TacoFilter.emptyNoFocusState);
            };
            if (v1 instanceof CostEstimation_Filters.FilterByComponentHasFlag) {
                return mkTacoFilter$prime(TacoFilter.DontUseTacoFilterText.value)("On lippu")(CostEstimation_Filters.FilterByComponentHasFlag.create)(v)("has-flag")(TacoFilter.emptyNoFocusState);
            };
            if (v1 instanceof CostEstimation_Filters.FilterByComponentNoFlag) {
                return mkTacoFilter$prime(TacoFilter.DontUseTacoFilterText.value)("Ei lippua")(CostEstimation_Filters.FilterByComponentNoFlag.create)(v)("no-flag")(TacoFilter.emptyNoFocusState);
            };
            if (v1 instanceof CostEstimation_Filters.FilterByComponentHasCheck) {
                return mkTacoFilter$prime(TacoFilter.DontUseTacoFilterText.value)("On merkki")(CostEstimation_Filters.FilterByComponentHasCheck.create)(v)("has-check")(TacoFilter.emptyNoFocusState);
            };
            if (v1 instanceof CostEstimation_Filters.FilterByComponentNoCheck) {
                return mkTacoFilter$prime(TacoFilter.DontUseTacoFilterText.value)("Ei merkki\xe4")(CostEstimation_Filters.FilterByComponentNoCheck.create)(v)("no-check")(TacoFilter.emptyNoFocusState);
            };
            if (v1 instanceof CostEstimation_Filters.FilterByComponentWithoutGroup) {
                return mkTacoFilter$prime(TacoFilter.DontUseTacoFilterText.value)("Ryhmittelem\xe4tt\xf6m\xe4t")(CostEstimation_Filters.FilterByComponentWithoutGroup.create)(v)("no-group")(TacoFilter.emptyNoFocusState);
            };
            if (v1 instanceof CostEstimation_Filters.FilterByComponentWithoutCostGroup) {
                return mkTacoFilter$prime(TacoFilter.DontUseTacoFilterText.value)("Litteroimattomat")(CostEstimation_Filters.FilterByComponentWithoutCostGroup.create)(v)("no-cost-group")(TacoFilter.emptyNoFocusState);
            };
            if (v1 instanceof CostEstimation_Filters.FilterByComponentNoCodeMatch) {
                return mkTacoFilter$prime(TacoFilter.DontUseTacoFilterText.value)("Muu koodi")(CostEstimation_Filters.FilterByComponentNoCodeMatch.create)(v)("no-code-match")(TacoFilter.emptyNoFocusState);
            };
            if (v1 instanceof CostEstimation_Filters.FilterByComponentNonMatchingCostGroup) {
                return mkTacoFilter$prime(TacoFilter.DontUseTacoFilterText.value)("Litterat, joita ei ole litteranimikkeist\xf6ss\xe4")(CostEstimation_Filters.FilterByComponentNonMatchingCostGroup.create)(v)("no-matching-cost-group")(TacoFilter.emptyNoFocusState);
            };
            if (v1 instanceof CostEstimation_Filters.FilterByComponentSelected) {
                return React_Basic.empty;
            };
            return React_Basic.empty;
        };
    };
    return {
        renderFilterInput: renderFilterInput,
        onAddFilterBtnChange: onAddFilterBtnChange,
        selectionItems: selectionItems
    };
});
module.exports = {
    estimationComponentFilter: estimationComponentFilter,
    estimationElementsFilter: estimationElementsFilter,
    mkToggleFilter: mkToggleFilter,
    orgResourcePriceFilter: orgResourcePriceFilter
};
