// Generated by purs version 0.14.5
"use strict";
var Client_WASM = require("../Client.WASM/index.js");
var ComponentSummaries = require("../ComponentSummaries/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Show = require("../Data.Show/index.js");
var Formatters = require("../Formatters/index.js");
var Gap = require("../Gap/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var SocialExpenseIndicator = require("../SocialExpenseIndicator/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoList = require("../TacoList/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var mkElementSummaryItem = function (props) {
    var pbccToItem = function (pbcc) {
        var title = Data_Newtype.un()(Types_Newtypes.CostClassCode)(pbcc.costClass.costClassCode) + (". " + pbcc.costClass.name);
        return {
            title: title,
            titleJSX: props.mkTitleJSX(React_Basic_DOM.text(title)),
            id: title,
            value: TacoList.TextValue.create(Formatters.formatCurrencyValue(props.projectCurrency)(pbcc.totalPrice)),
            disabled: false,
            onClick: Data_Maybe.Nothing.value,
            getItems: function (v) {
                return [  ];
            },
            hasItems: false,
            initExpanded: false
        };
    };
    var countStrValues = (function () {
        if (props.elementsSummary instanceof Data_Maybe.Just) {
            return {
                count: " (" + Data_Show.show(Data_Show.showInt)(props.elementsSummary.value0.count),
                countStrEnding: (function () {
                    var $9 = props.elementsSummary.value0.count === 1;
                    if ($9) {
                        return " rakenne)";
                    };
                    return " rakennetta)";
                })()
            };
        };
        if (props.elementsSummary instanceof Data_Maybe.Nothing) {
            return {
                count: "",
                countStrEnding: ""
            };
        };
        throw new Error("Failed pattern match at CostEstimation.BuildingElementsView.ElementSummaries (line 101, column 22 - line 103, column 51): " + [ props.elementsSummary.constructor.name ]);
    })();
    var title = (function () {
        var $11 = Data_Maybe.isJust(props.elementsSummary);
        if ($11) {
            return props.label + (countStrValues.count + countStrValues.countStrEnding);
        };
        return props.label + " (loading)";
    })();
    return {
        title: title,
        titleJSX: (function () {
            var $12 = Data_Maybe.isJust(props.elementsSummary);
            if ($12) {
                return props.mkTitleJSX(React_Basic_DOM_Generated.div_([ React_Basic_DOM.text(props.label), React_Basic_DOM_Generated.var_([ React_Basic_DOM.text(countStrValues.count) ]), React_Basic_DOM.text(countStrValues.countStrEnding) ]));
            };
            return props.mkTitleJSX(React_Basic_DOM.text(props.label + " (loading)"));
        })(),
        id: props.label,
        value: (function () {
            if (props.elementsSummary instanceof Data_Maybe.Just) {
                return TacoList.TextValue.create(Formatters.formatCurrencyValue(props.projectCurrency)(props.elementsSummary.value0.totalPrice));
            };
            if (props.elementsSummary instanceof Data_Maybe.Nothing) {
                return new TacoList.TextValue("(loading)");
            };
            throw new Error("Failed pattern match at CostEstimation.BuildingElementsView.ElementSummaries (line 127, column 12 - line 129, column 48): " + [ props.elementsSummary.constructor.name ]);
        })(),
        disabled: false,
        onClick: Data_Maybe.Nothing.value,
        initExpanded: false,
        getItems: (function () {
            if (props.elementsSummary instanceof Data_Maybe.Just) {
                return function (v) {
                    return Data_Functor.map(Data_Functor.functorArray)(function ($31) {
                        return TacoList.ExpandableItemWrapper(pbccToItem($31));
                    })(props.elementsSummary.value0.costClasses);
                };
            };
            if (props.elementsSummary instanceof Data_Maybe.Nothing) {
                return function (v) {
                    return [  ];
                };
            };
            throw new Error("Failed pattern match at CostEstimation.BuildingElementsView.ElementSummaries (line 133, column 15 - line 135, column 26): " + [ props.elementsSummary.constructor.name ]);
        })(),
        hasItems: (function () {
            if (props.elementsSummary instanceof Data_Maybe.Just) {
                return Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Array["null"])(props.elementsSummary.value0.costClasses);
            };
            if (props.elementsSummary instanceof Data_Maybe.Nothing) {
                return false;
            };
            throw new Error("Failed pattern match at CostEstimation.BuildingElementsView.ElementSummaries (line 136, column 15 - line 138, column 23): " + [ props.elementsSummary.constructor.name ]);
        })()
    };
};
var component = TofuHooks.mkMemoHookComponent("ElementSummaries")(function (props) {
    return function __do() {
        var v = Client_WASM.useEstimationElementsTotals(props.projectId)();
        var mkTitleJSX = function (jsx) {
            if (props.showSocialExpensesInCosts) {
                return Data_Maybe.Just.create(Gap.gapX(TacoConstants.XS.value)([ SocialExpenseIndicator.socialExpenseIndicator, jsx ]));
            };
            return new Data_Maybe.Just(jsx);
        };
        var v1 = Client_WASM.useEstimationComponentsTotals(props.projectId)();
        var totalSummary = ComponentSummaries.mkComponentSummaryItem({
            label: "Koko hanke",
            mkTitleJSX: mkTitleJSX,
            componentsSummary: Data_Functor.map(Data_Maybe.functorMaybe)(function (v2) {
                return v2.totalComponentsSummary;
            })(v1.value0),
            showSocialExpensesInCosts: props.showSocialExpensesInCosts,
            currency: props.projectCurrency
        });
        var filteredSummary = mkElementSummaryItem({
            label: "Listatut rakenteet",
            mkTitleJSX: mkTitleJSX,
            elementsSummary: Data_Functor.map(Data_Maybe.functorMaybe)(function (v2) {
                return v2.filteredElementsSummary;
            })(v.value0),
            projectCurrency: props.projectCurrency
        });
        var checkedSummary = mkElementSummaryItem({
            label: "Valinta",
            mkTitleJSX: mkTitleJSX,
            elementsSummary: Data_Functor.map(Data_Maybe.functorMaybe)(function (v2) {
                return v2.selectedElementsSummary;
            })(v.value0),
            projectCurrency: props.projectCurrency
        });
        var loading = v.value1 || v1.value1;
        props.setLoading("ElementSummaries")(loading)();
        return ReactBasicUtils["div'"]("__ElementSummaries")([ TacoList.component({
            items: [ totalSummary, filteredSummary, checkedSummary ]
        }) ]);
    };
});
module.exports = {
    component: component
};
