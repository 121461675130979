// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Effect = require("../Effect/index.js");
var Effect_Console = require("../Effect.Console/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");
var Web_DOM_NonElementParentNode = require("../Web.DOM.NonElementParentNode/index.js");
var Web_HTML = require("../Web.HTML/index.js");
var Web_HTML_HTMLDocument = require("../Web.HTML.HTMLDocument/index.js");
var Web_HTML_HTMLElement = require("../Web.HTML.HTMLElement/index.js");
var Web_HTML_Window = require("../Web.HTML.Window/index.js");
var window = Effect_Unsafe.unsafePerformEffect(Web_HTML.window);
var scrollIntoView = $foreign.scrollIntoView_;
var readRefMaybeElement = function (ref) {
    return Data_Functor.map(Effect.functorEffect)(function (v) {
        return Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Web_HTML_HTMLElement.fromNode)(v);
    })(React_Basic_Hooks.readRefMaybe(ref));
};
var onChange$prime = function (f) {
    return React_Basic_Events.handler(React_Basic_DOM_Events.targetValue)(f);
};
var document = Effect_Unsafe.unsafePerformEffect(Web_HTML_Window.document(window));
var runEffectOnHTMLElementById = function (elId) {
    return function (effect) {
        return function __do() {
            var mEl = Web_DOM_NonElementParentNode.getElementById(elId)(Web_HTML_HTMLDocument.toNonElementParentNode(document))();
            var v = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Web_HTML_HTMLElement.fromElement)(mEl);
            if (v instanceof Data_Maybe.Just) {
                return effect(v.value0)();
            };
            if (v instanceof Data_Maybe.Nothing) {
                return Effect_Console.warn("Unable to run an effect on HTML element with id \"" + (elId + "\": element not found."))();
            };
            throw new Error("Failed pattern match at HtmlUtils (line 35, column 3 - line 37, column 119): " + [ v.constructor.name ]);
        };
    };
};
var coerceEventTargetToHTMLElement = Unsafe_Coerce.unsafeCoerce;
module.exports = {
    window: window,
    document: document,
    runEffectOnHTMLElementById: runEffectOnHTMLElementById,
    "onChange'": onChange$prime,
    scrollIntoView: scrollIntoView,
    readRefMaybeElement: readRefMaybeElement,
    coerceEventTargetToHTMLElement: coerceEventTargetToHTMLElement,
    scrollIntoView_: $foreign.scrollIntoView_,
    setTimeout: $foreign.setTimeout,
    clearTimeout: $foreign.clearTimeout
};
