// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Utils = require("../Utils/index.js");
var openS3Link = function (props) {
    return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(props.dispatch(new Actions.InvoiceImgRequest({
        projectId: props.projectId,
        file: props.invoiceImg
    })));
};
var invoiceImgLinkComponent = TofuHooks.mkHookComponent("InvoiceImgLinkComponent")(function (props) {
    return function __do() {
        var v = TofuHooks.useState("")();
        var v1 = TofuHooks.useState("")();
        var isLink = Utils.isLink(props.invoiceImg);
        var s3ClickHandler = Effect_Aff.launchAff_(openS3Link(props));
        var linkClickHandler = Control_Applicative.pure(Effect.applicativeEffect)(Data_Monoid.mempty(Data_Monoid.monoidUnit));
        var onClick = (function () {
            if (isLink) {
                return linkClickHandler;
            };
            return s3ClickHandler;
        })();
        TofuHooks.useEffect([ props.invoiceImg ])(function __do() {
            v1.value1(function (v2) {
                return props.invoiceImg;
            })();
            v.value1(function (v2) {
                if (isLink) {
                    return props.invoiceImg;
                };
                return "";
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        })();
        return TacoButton.component()()({
            className: "invoice-img-link",
            text: props.invoiceNumber,
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconInvoiceImageV1.value),
            iconAlign: TacoButton_Types.IconRight.value,
            href: new Data_Maybe.Just(v.value0),
            buttonStyle: TacoButton_Types.Link.value,
            newTab: true,
            onClick: (function () {
                if (isLink) {
                    return Data_Maybe.Nothing.value;
                };
                return new Data_Maybe.Just(onClick);
            })()
        });
    };
});
var createInvoiceImgLinkJSX = function (dispatch) {
    return function (projectId) {
        return function (invoice) {
            if (invoice.invoiceImg instanceof Data_Maybe.Just) {
                return invoiceImgLinkComponent({
                    dispatch: dispatch,
                    projectId: projectId,
                    invoiceImg: invoice.invoiceImg.value0,
                    invoiceNumber: invoice.invoiceNumber
                });
            };
            if (invoice.invoiceImg instanceof Data_Maybe.Nothing) {
                return React_Basic_DOM_Generated.span()({
                    children: [ React_Basic_DOM.text(invoice.invoiceNumber) ]
                });
            };
            throw new Error("Failed pattern match at InvoiceImgLink (line 20, column 3 - line 29, column 62): " + [ invoice.invoiceImg.constructor.name ]);
        };
    };
};
module.exports = {
    createInvoiceImgLinkJSX: createInvoiceImgLinkJSX,
    invoiceImgLinkComponent: invoiceImgLinkComponent,
    openS3Link: openS3Link
};
