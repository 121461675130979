// Generated by purs version 0.14.5
"use strict";
var Calculation_CostClass = require("../Calculation.CostClass/index.js");
var Calculation_Utils = require("../Calculation.Utils/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Monoid_Additive = require("../Data.Monoid.Additive/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var OwnProjectTaxes = require("../OwnProjectTaxes/index.js");
var Types_CostGroup = require("../Types.CostGroup/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var Utils = require("../Utils/index.js");
var WithSocialExpenses = (function () {
    function WithSocialExpenses() {

    };
    WithSocialExpenses.value = new WithSocialExpenses();
    return WithSocialExpenses;
})();
var WithoutSocialExpenses = (function () {
    function WithoutSocialExpenses() {

    };
    WithoutSocialExpenses.value = new WithoutSocialExpenses();
    return WithoutSocialExpenses;
})();
var R200CostGroupsResult = function (x) {
    return x;
};
var showR200CostGroupsResult = Data_Show.showRecord()(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "actual";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "code";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "estimate";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "name";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "target";
    }
})(Data_Show.showRecordFieldsNil)(Data_Show.showNumber))(Data_Show.showString))(Data_Show.showNumber))(Data_Show.showString))(Data_Show.showNumber));
var newtypeR200CostGroupsResult = {
    Coercible0: function () {
        return undefined;
    }
};
var mkCostGroupWithCostClasses = function (disableSocialCostToActual) {
    return function (costGroupItems_) {
        return function (invoiceLines_) {
            return function (costClasses) {
                return function (withOrWithoutSocialExpenses) {
                    return function (upperDateLimit) {
                        return function (v) {
                            var $39 = Data_Array.filter(function (x) {
                                return Data_Eq.eq(Types_CostGroup.eqCostGroupCode)(x.costGroupCode)(v.code);
                            })(costGroupItems_);
                            var $40 = Data_Array.filter(function (x) {
                                return Data_Eq.eq(Data_Maybe.eqMaybe(Types_CostGroup.eqCostGroupCode))(Data_Nullable.toMaybe(x.costGroupCode))(new Data_Maybe.Just(v.code));
                            })(invoiceLines_);
                            var v1 = Calculation_CostClass.getR200CostGroupCostClassesAndSocialCostTotals(disableSocialCostToActual)($39)($40)(costClasses)(upperDateLimit);
                            var socialExpenses = (function () {
                                if (withOrWithoutSocialExpenses instanceof WithSocialExpenses) {
                                    return v1.socialExpenses;
                                };
                                if (withOrWithoutSocialExpenses instanceof WithoutSocialExpenses) {
                                    return {
                                        name: "Sosiaalikulut",
                                        costClassCode: "Sosiaalikulut",
                                        target: 0.0,
                                        estimate: 0.0,
                                        actual: 0.0
                                    };
                                };
                                throw new Error("Failed pattern match at Calculation.CostGroup (line 293, column 22 - line 301, column 10): " + [ withOrWithoutSocialExpenses.constructor.name ]);
                            })();
                            var mkAdditive = function (v2) {
                                return {
                                    actual: v2.actual,
                                    target: v2.target,
                                    estimate: v2.estimate
                                };
                            };
                            var sumItems = Data_Array.foldMap(Data_Monoid.monoidRecord()(Data_Monoid.monoidRecordCons({
                                reflectSymbol: function () {
                                    return "actual";
                                }
                            })(Data_Monoid_Additive.monoidAdditive(Data_Semiring.semiringNumber))()(Data_Monoid.monoidRecordCons({
                                reflectSymbol: function () {
                                    return "estimate";
                                }
                            })(Data_Monoid_Additive.monoidAdditive(Data_Semiring.semiringNumber))()(Data_Monoid.monoidRecordCons({
                                reflectSymbol: function () {
                                    return "target";
                                }
                            })(Data_Monoid_Additive.monoidAdditive(Data_Semiring.semiringNumber))()(Data_Monoid.monoidRecordNil)))))(mkAdditive)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(v1.costClassResults)([ socialExpenses ]));
                            var costGroupUnit = {
                                id: v.id,
                                actualCosts: Data_Newtype.un()(Data_Monoid_Additive.Additive)(sumItems.actual),
                                category: v.category,
                                code: v.code,
                                completionPercentage: v.completionPercentage,
                                estimateCosts: Data_Newtype.un()(Data_Monoid_Additive.Additive)(sumItems.estimate),
                                memo: v.memo,
                                name: v.name,
                                projectId: v.projectId,
                                targetCosts: Data_Newtype.un()(Data_Monoid_Additive.Additive)(sumItems.target)
                            };
                            return {
                                costGroupUnit: costGroupUnit,
                                socialExpenses: socialExpenses,
                                costClassResults: v1.costClassResults
                            };
                        };
                    };
                };
            };
        };
    };
};
var mkCostGroupUnit = function (costGroupItems) {
    return function (invoiceLines) {
        return function (v) {
            var filteredILs = Data_Array.filter(function (x) {
                return Data_Eq.eq(Data_Maybe.eqMaybe(Types_CostGroup.eqCostGroupCode))(new Data_Maybe.Just(v.code))(Data_Nullable.toMaybe(x.costGroupCode));
            })(invoiceLines);
            var filteredCGI = Data_Array.filter(function (x) {
                return Data_Eq.eq(Types_CostGroup.eqCostGroupCode)(x.costGroupCode)(v.code);
            })(costGroupItems);
            var targetCostItems = Data_Array.filter(Types_CostGroup.isCostGroupTargetItemId)(filteredCGI);
            var estimateCostItems = Data_Array.filter(Types_CostGroup.isCostGroupEstimateItemId)(filteredCGI);
            return {
                id: v.id,
                actualCosts: Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v1) {
                    return v1.value;
                })(filteredILs)),
                category: v.category,
                code: v.code,
                completionPercentage: v.completionPercentage,
                estimateCosts: Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v1) {
                    return v1.value;
                })(estimateCostItems)),
                memo: v.memo,
                name: v.name,
                projectId: v.projectId,
                targetCosts: Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v1) {
                    return v1.value;
                })(targetCostItems))
            };
        };
    };
};
var mkCostGroupUnits = function (costGroupItems) {
    return function (invoiceLines) {
        return function (costGroupInfos) {
            return Data_Functor.map(Data_Functor.functorArray)(mkCostGroupUnit(costGroupItems)(invoiceLines))(costGroupInfos);
        };
    };
};
var getCostGroupUnitSums = function (dictFoldable) {
    return function (dictFunctor) {
        return function (xs) {
            var mkAdditive = function (v) {
                return {
                    actual: v.actualCosts,
                    estimate: v.estimateCosts,
                    target: v.targetCosts
                };
            };
            var r = Data_Foldable.foldMap(dictFoldable)(Data_Monoid.monoidRecord()(Data_Monoid.monoidRecordCons({
                reflectSymbol: function () {
                    return "actual";
                }
            })(Data_Monoid_Additive.monoidAdditive(Data_Semiring.semiringNumber))()(Data_Monoid.monoidRecordCons({
                reflectSymbol: function () {
                    return "estimate";
                }
            })(Data_Monoid_Additive.monoidAdditive(Data_Semiring.semiringNumber))()(Data_Monoid.monoidRecordCons({
                reflectSymbol: function () {
                    return "target";
                }
            })(Data_Monoid_Additive.monoidAdditive(Data_Semiring.semiringNumber))()(Data_Monoid.monoidRecordNil)))))(mkAdditive)(xs);
            return {
                actual: Data_Newtype.un()(Data_Monoid_Additive.Additive)(r.actual),
                estimate: Data_Newtype.un()(Data_Monoid_Additive.Additive)(r.estimate),
                target: Data_Newtype.un()(Data_Monoid_Additive.Additive)(r.target)
            };
        };
    };
};
var getCompletionPercentageOfCostGroupUnits = function (costGroups) {
    var sumProductEstimateCompletionPercentage = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
        return v.estimateCosts * Data_Newtype.un()(Types_Unit.PercentageOfHundred)(v.completionPercentage) * 1.0e-2;
    })(costGroups));
    var sumEstimate = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)((function () {
        var $74 = Data_Newtype.un()(Types_CostGroup.CostGroupUnit);
        return function ($75) {
            return (function (v) {
                return v.estimateCosts;
            })($74($75));
        };
    })())(costGroups));
    var totalCompletionPercentage = Types_Unit.PercentageOfZeroToOne(Utils.defaultZeroDiv(sumProductEstimateCompletionPercentage)(sumEstimate));
    return totalCompletionPercentage;
};
var getCGUCode = (function () {
    var $76 = Data_Newtype.un()(Types_CostGroup.CostGroupUnit);
    return function ($77) {
        return (function (v) {
            return v.code;
        })($76($77));
    };
})();
var getCGResultSums = function (dictFoldable) {
    return function (dictFunctor) {
        return function (xs) {
            var mkAdditive = function (v) {
                return {
                    actual: v.actual,
                    estimate: v.estimate,
                    target: v.target
                };
            };
            var r = Data_Foldable.foldMap(dictFoldable)(Data_Monoid.monoidRecord()(Data_Monoid.monoidRecordCons({
                reflectSymbol: function () {
                    return "actual";
                }
            })(Data_Monoid_Additive.monoidAdditive(Data_Semiring.semiringNumber))()(Data_Monoid.monoidRecordCons({
                reflectSymbol: function () {
                    return "estimate";
                }
            })(Data_Monoid_Additive.monoidAdditive(Data_Semiring.semiringNumber))()(Data_Monoid.monoidRecordCons({
                reflectSymbol: function () {
                    return "target";
                }
            })(Data_Monoid_Additive.monoidAdditive(Data_Semiring.semiringNumber))()(Data_Monoid.monoidRecordNil)))))(mkAdditive)(xs);
            return {
                actual: Data_Newtype.un()(Data_Monoid_Additive.Additive)(r.actual),
                estimate: Data_Newtype.un()(Data_Monoid_Additive.Additive)(r.estimate),
                target: Data_Newtype.un()(Data_Monoid_Additive.Additive)(r.target)
            };
        };
    };
};
var getTotal = function (dictFoldable) {
    return function (dictFunctor) {
        return function (name) {
            return function (results) {
                var resultSums = getCGResultSums(dictFoldable)(dictFunctor)(results);
                var totalResult = {
                    name: name,
                    code: "",
                    target: resultSums.target,
                    estimate: resultSums.estimate,
                    actual: resultSums.actual
                };
                return totalResult;
            };
        };
    };
};
var fromR200CostClassResult = function (v) {
    return {
        name: v.name,
        code: "",
        actual: v.actual,
        estimate: v.estimate,
        target: v.target
    };
};
var fromCostGroupUnit = function (v) {
    return {
        name: v.name,
        code: Data_Newtype.un()(Types_CostGroup.CostGroupCode)(v.code),
        actual: v.actualCosts,
        estimate: v.estimateCosts,
        target: v.targetCosts
    };
};
var mkR200CostGroupResults = function (disableSocialCostToActual) {
    return function (v) {
        return function (projectTaxStatus) {
            return function (invoiceLines$prime) {
                return function (costGroupInfo) {
                    return function (costGroupItems$prime) {
                        return function (costClasses) {
                            return function (upperDateLimit) {
                                var vatExclusionCostGroups$prime = Data_Set.fromFoldable(Data_Foldable.foldableArray)(Types_CostGroup.ordCostGroupCode)(v);
                                var invoiceLines = Calculation_Utils.filterInvoiceLines(upperDateLimit)(invoiceLines$prime);
                                var costGroupItems = Calculation_Utils.filterCostGroupItems(upperDateLimit)(costGroupItems$prime);
                                var costGroupUnits = mkCostGroupUnits(costGroupItems)(invoiceLines)(costGroupInfo);
                                var costIncreasesRCGs = Data_Array.filter(function (v1) {
                                    return Data_Eq.eq(Types_CostGroup.eqCostGroupCategoryCode)(Types_CostGroup.CostIncreasesCGCategory.value)(v1.category);
                                })(costGroupUnits);
                                var costIncreasesSums = getCostGroupUnitSums(Data_Foldable.foldableArray)(Data_Functor.functorArray)(costIncreasesRCGs);
                                var costIncreasesResult = {
                                    name: "Kustannusten nousuvaraus",
                                    code: "",
                                    target: costIncreasesSums.target,
                                    estimate: costIncreasesSums.estimate,
                                    actual: costIncreasesSums.actual
                                };
                                var defaultOrUnspecifiedCostGroups = Data_Array.sortWith(Types_CostGroup.ordCostGroupCode)(getCGUCode)(Data_Array.filter(function (v1) {
                                    return Data_Eq.eq(Types_CostGroup.eqCostGroupCategoryCode)(v1.category)(Types_CostGroup.DefaultCGCategory.value) || Data_Eq.eq(Types_CostGroup.eqCostGroupCategoryCode)(v1.category)(Types_CostGroup.UnspecifiedCGCategory.value);
                                })(costGroupUnits));
                                var costGroupResults = Data_Functor.map(Data_Functor.functorArray)(fromCostGroupUnit)(defaultOrUnspecifiedCostGroups);
                                var totalCompletionPercentage = getCompletionPercentageOfCostGroupUnits(defaultOrUnspecifiedCostGroups);
                                var existingCostGroupCodes = Data_Set.fromFoldable(Data_Foldable.foldableArray)(Types_CostGroup.ordCostGroupCode)(Data_Functor.map(Data_Functor.functorArray)(getCGUCode)(costGroupUnits));
                                var costGroupNotFound = function (il) {
                                    var v1 = Data_Nullable.toMaybe(il.costGroupCode);
                                    if (v1 instanceof Data_Maybe.Just) {
                                        return Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean)))(Data_Set.member(Types_CostGroup.ordCostGroupCode))(v1.value0)(existingCostGroupCodes);
                                    };
                                    if (v1 instanceof Data_Maybe.Nothing) {
                                        return false;
                                    };
                                    throw new Error("Failed pattern match at Calculation.CostGroup (line 166, column 28 - line 168, column 23): " + [ v1.constructor.name ]);
                                };
                                var missingCostGroupCodeInvoiceLines = Data_Array.filter(costGroupNotFound)(invoiceLines$prime);
                                var groupedMissingCostGroupCodeInvoiceLines = Data_Array.groupBy(function (x) {
                                    return function (y) {
                                        return Data_Eq.eq(Data_Nullable.eqNullable(Types_CostGroup.eqCostGroupCode))(x.costGroupCode)(y.costGroupCode);
                                    };
                                })(missingCostGroupCodeInvoiceLines);
                                var missingCostGroups = Data_Functor.mapFlipped(Data_Functor.functorArray)(groupedMissingCostGroupCodeInvoiceLines)(function (group) {
                                    var code = Utils.defaultNull("")((function (v1) {
                                        return v1.costGroupCode;
                                    })(Data_Array_NonEmpty.head(group)));
                                    return mkCostGroupUnit([  ])(Data_Array_NonEmpty.toArray(group))({
                                        id: 0,
                                        category: Types_CostGroup.DefaultCGCategory.value,
                                        code: code,
                                        completionPercentage: 0.0,
                                        memo: "",
                                        name: "Puuttuva littera",
                                        projectId: 0
                                    });
                                });
                                var missingCostGroupResults = Data_Functor.map(Data_Functor.functorArray)(fromCostGroupUnit)(missingCostGroups);
                                var vatExcludedCostGroups = Data_Functor.map(Data_Functor.functorArray)(Data_Newtype.un()(Types_CostGroup.CostGroupUnit))(Data_Array.filter(function (v1) {
                                    return Data_Set.member(Types_CostGroup.ordCostGroupCode)(v1.code)(vatExclusionCostGroups$prime);
                                })(costGroupUnits));
                                var vatExclusionTotals = {
                                    target: Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v1) {
                                        return v1.targetCosts;
                                    })(vatExcludedCostGroups)),
                                    actual: Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v1) {
                                        return v1.actualCosts;
                                    })(vatExcludedCostGroups)),
                                    estimate: Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v1) {
                                        return v1.estimateCosts;
                                    })(vatExcludedCostGroups))
                                };
                                var socialCostTotals$prime = (function (v1) {
                                    return v1.socialExpenses;
                                })(Calculation_CostClass.getR200CostGroupCostClassesAndSocialCostTotals(disableSocialCostToActual)(costGroupItems)(invoiceLines)(costClasses)(upperDateLimit));
                                var socialCostTotals = fromR200CostClassResult(socialCostTotals$prime);
                                var additionalWorkRCGs = Data_Array.filter(function (v1) {
                                    return Data_Eq.eq(Types_CostGroup.eqCostGroupCategoryCode)(Types_CostGroup.AdditionalWorkCGCategory.value)(v1.category);
                                })(costGroupUnits);
                                var additionalWorkSums = getCostGroupUnitSums(Data_Foldable.foldableArray)(Data_Functor.functorArray)(additionalWorkRCGs);
                                var additionalWorkResult = {
                                    name: "Lis\xe4ty\xf6t",
                                    code: "",
                                    target: additionalWorkSums.target,
                                    estimate: additionalWorkSums.estimate,
                                    actual: additionalWorkSums.actual
                                };
                                var taxableResults = Data_Semigroup.append(Data_Semigroup.semigroupArray)(missingCostGroupResults)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(costGroupResults)([ socialCostTotals, additionalWorkResult, costIncreasesResult ]));
                                var optionalTaxes = (function () {
                                    if (projectTaxStatus instanceof OwnProjectTaxes.NotOwnProject) {
                                        return Data_Maybe.Nothing.value;
                                    };
                                    if (projectTaxStatus instanceof OwnProjectTaxes.OwnProject) {
                                        var $70 = (function () {
                                            var $78 = OwnProjectTaxes.applyTaxesForCosts(projectTaxStatus);
                                            return function ($79) {
                                                return (function (v1) {
                                                    return v1.taxes;
                                                })($78($79));
                                            };
                                        })();
                                        var $71 = getCGResultSums(Data_Foldable.foldableArray)(Data_Functor.functorArray)(taxableResults);
                                        return Data_Maybe.Just.create({
                                            name: "Omien kohteiden Alv",
                                            code: "",
                                            target: $70($71.target - vatExclusionTotals.target),
                                            estimate: $70($71.estimate - vatExclusionTotals.estimate),
                                            actual: $70($71.actual - vatExclusionTotals.actual)
                                        });
                                    };
                                    throw new Error("Failed pattern match at Calculation.CostGroup (line 223, column 21 - line 236, column 12): " + [ projectTaxStatus.constructor.name ]);
                                })();
                                var totalResult = getTotal(Data_Foldable.foldableArray)(Data_Functor.functorArray)("Kustannukset yhteens\xe4")(Data_Semigroup.append(Data_Semigroup.semigroupArray)(taxableResults)(Data_Maybe.maybe([  ])(Control_Applicative.pure(Control_Applicative.applicativeArray))(optionalTaxes)));
                                var totalWithoutTaxes = getTotal(Data_Foldable.foldableArray)(Data_Functor.functorArray)("Yhteens\xe4")(taxableResults);
                                return {
                                    costGroups: defaultOrUnspecifiedCostGroups,
                                    missingCostGroups: missingCostGroups,
                                    socialExpenses: socialCostTotals,
                                    costIncreases: costIncreasesResult,
                                    additionalWork: additionalWorkResult,
                                    taxes: optionalTaxes,
                                    totalWithoutTaxes: totalWithoutTaxes,
                                    total: totalResult,
                                    totalCompletionPercentage: totalCompletionPercentage
                                };
                            };
                        };
                    };
                };
            };
        };
    };
};
var extractMCGCodeFromCGCode = function (s) {
    return Data_String_CodePoints.take(1)(Data_Newtype.un()(Types_CostGroup.CostGroupCode)(s));
};
var eqR200CostGroupsResult = Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "target";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "name";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "estimate";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "code";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "actual";
    }
})(Data_Eq.eqNumber));
module.exports = {
    extractMCGCodeFromCGCode: extractMCGCodeFromCGCode,
    getCGUCode: getCGUCode,
    getCostGroupUnitSums: getCostGroupUnitSums,
    mkCostGroupUnits: mkCostGroupUnits,
    mkCostGroupUnit: mkCostGroupUnit,
    R200CostGroupsResult: R200CostGroupsResult,
    fromCostGroupUnit: fromCostGroupUnit,
    fromR200CostClassResult: fromR200CostClassResult,
    getCGResultSums: getCGResultSums,
    mkR200CostGroupResults: mkR200CostGroupResults,
    getCompletionPercentageOfCostGroupUnits: getCompletionPercentageOfCostGroupUnits,
    getTotal: getTotal,
    WithSocialExpenses: WithSocialExpenses,
    WithoutSocialExpenses: WithoutSocialExpenses,
    mkCostGroupWithCostClasses: mkCostGroupWithCostClasses,
    newtypeR200CostGroupsResult: newtypeR200CostGroupsResult,
    eqR200CostGroupsResult: eqR200CostGroupsResult,
    showR200CostGroupsResult: showR200CostGroupsResult
};
