import { useQuery } from "react-query";
import axiosClient from "../../../../axiosConfig";
import { EstimationLocation } from "../../../../../../ts-bindings/EstimationLocation";

export function useGetEstimationLocations(
  projectId: number
): EstimationLocation[] {
  const { data } = useQuery({
    queryKey: ["estimationLocations", projectId],
    queryFn: async () => {
      const { data } = await axiosClient.get<EstimationLocation[]>(
        `/project/${projectId}/estimationLocations`
      );
      return data;
    },
  });
  return data ?? [];
}
