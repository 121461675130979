import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { Button } from "@tocoman/ui";

/**
 * Hook to set action buttons for modal, using action buttons in this way lets us handle the save button behaviour inside the components in modals,
 * e.g. disabling the save button when there is no data to save, handling the http requests
 * @param setButtons
 * @param onSaveClick submit form handler
 * @param closeModal
 * @returns {(disabled: boolean) => void} callback to set action buttons, takes disabled state of save button as parameter
 */
export function useModalActionButtons(
  setButtons: (buttons: JSX.Element) => void,
  onSaveClick: () => void,
  closeModal: () => void
): (disabled: boolean) => void {
  const { t } = useTranslation("estimation", {
    keyPrefix: "componentView.editMultipleComponentsModal",
  });
  const actions = useCallback(
    (disabled: boolean) => {
      return (
        <div className="ml-auto flex justify-between w-full">
          <Button variant="text" onClick={closeModal}>{t`cancel`}</Button>
          <Button
            onClick={onSaveClick}
            testId={"project-save-btn"}
            disabled={disabled}
          >{t`save`}</Button>
        </div>
      );
    },
    [closeModal, onSaveClick, t]
  );

  return useCallback((disabled: boolean) => setButtons(actions(disabled)), [
    actions,
    setButtons,
  ]);
}
