// Generated by purs version 0.14.5
"use strict";
var Components_Utils = require("../Components.Utils/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Effect = require("../Effect/index.js");
var Gap = require("../Gap/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_DOM_Internal = require("../React.Basic.DOM.Internal/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var Record = require("../Record/index.js");
var TacoButton_Styles = require("../TacoButton.Styles/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon = require("../TacoIcon/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var UseUnfocusOnClick = require("../UseUnfocusOnClick/index.js");
var Utils_ClassNames = require("../Utils.ClassNames/index.js");
var mkIcon = function (v) {
    return function (icon) {
        return TacoIcon.component()()()()({
            icon: icon,
            size: v.iconSize,
            rotation: v.rotation,
            color: Data_Maybe.fromMaybe(TacoConstants.Transparent.value)(v.iconColor),
            inheritColor: Data_Maybe.isNothing(v.iconColor)
        });
    };
};
var defaultProps = {
    text: "",
    title: "",
    onClick: Data_Maybe.Nothing.value,
    onClickEventHandler: Data_Maybe.Nothing.value,
    href: Data_Maybe.Nothing.value,
    newTab: false,
    color: TacoConstants.PrimaryBlue.value,
    disabled: false,
    buttonStyle: TacoButton_Types.Text.value,
    submit: false,
    children: Data_Maybe.Nothing.value,
    className: "",
    testId: "",
    icon: Data_Maybe.Nothing.value,
    iconSize: TacoConstants.XS.value,
    iconAlign: TacoButton_Types.IconLeft.value,
    iconRotation: 0,
    iconColor: Data_Maybe.Nothing.value,
    align: TacoButton_Types.Center.value,
    vertical: false,
    buttonOverflow: false,
    size: TacoConstants.M.value,
    styles: [  ],
    disableFocusHack: true
};
var component = function (dictUnion) {
    return function (dictNub) {
        return TofuHooks.mkHookComponent("TacoButton")(function (props$prime) {
            var v = Record.merge()()(props$prime)(defaultProps);
            return function __do() {
                var v1 = UseUnfocusOnClick.useUnfocusOnClick();
                var iconJSX = Data_Maybe.fromMaybe(React_Basic.empty)(Data_Functor.map(Data_Maybe.functorMaybe)(mkIcon({
                    iconSize: v.iconSize,
                    rotation: v.iconRotation,
                    iconColor: v.iconColor
                }))(v.icon));
                var handler = (function () {
                    var $16 = v.submit || Data_Maybe.isJust(v.href) && Data_Maybe.isNothing(v.onClick);
                    if ($16) {
                        return React_Basic_Events.handler_;
                    };
                    return React_Basic_DOM_Events.capture_;
                })();
                var v2 = (function () {
                    if (v.vertical) {
                        return Gap.gapY;
                    };
                    return Gap.gapX;
                })();
                var separator = Data_Monoid.guard(React_Basic.monoidJSX)(Data_Eq.eq(TacoButton_Types.eqButtonStyle)(v.buttonStyle)(TacoButton_Types.Filled.value) && Data_Maybe.isJust(v.icon))(React_Basic_Emotion.element(React_Basic_DOM_Generated["span'"]())({
                    css: TacoButton_Styles.separatorStyles,
                    className: "__TacoButton__Separator"
                }));
                var mkLabel = function (text1) {
                    if (v.vertical) {
                        return React_Basic_DOM_Generated.span()({
                            style: React_Basic_DOM_Internal.css({
                                writingMode: "vertical-lr"
                            }),
                            children: [ React_Basic_DOM.text(text1) ]
                        });
                    };
                    return React_Basic_DOM.text(text1);
                };
                var gap = function (contents) {
                    return Gap.component({
                        spacing: TacoConstants.S.value,
                        contents: contents,
                        direction: (function () {
                            if (v.vertical) {
                                return Gap.Column.value;
                            };
                            return Gap.Row.value;
                        })(),
                        overflow: v.buttonOverflow,
                        css: React_Basic_Emotion.css()({})
                    });
                };
                var commonProps = {
                    css: TacoButton_Styles.buttonStyles(v),
                    className: Utils_ClassNames.mkClassName([ new Data_Maybe.Just("__TacoButton"), new Data_Maybe.Just(v.className) ]),
                    children: Control_Applicative.pure(Control_Applicative.applicativeArray)(gap([ Data_Monoid.guard(Data_Monoid.monoidFn(React_Basic.monoidJSX))(Data_Eq.eq(TacoButton_Types.eqIconAlign)(v.iconAlign)(TacoButton_Types.IconLeft.value))(React_Basic.fragment)([ iconJSX, separator ]), Data_Maybe.fromMaybe(React_Basic.empty)(Data_Functor.map(Data_Maybe.functorMaybe)(React_Basic.fragment)(v.children)), Data_Monoid.guard(React_Basic.monoidJSX)(v.text !== "")(mkLabel(v.text)), Data_Monoid.guard(Data_Monoid.monoidFn(React_Basic.monoidJSX))(Data_Eq.eq(TacoButton_Types.eqIconAlign)(v.iconAlign)(TacoButton_Types.IconRight.value))(React_Basic.fragment)([ separator, iconJSX ]) ])),
                    onClick: (function () {
                        if (v.onClickEventHandler instanceof Data_Maybe.Just) {
                            return v.onClickEventHandler.value0;
                        };
                        if (v.onClickEventHandler instanceof Data_Maybe.Nothing) {
                            return handler(function __do() {
                                Data_Maybe.fromMaybe(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)))(v.onClick)();
                                return Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(!v.disableFocusHack)(v1.unfocus)();
                            });
                        };
                        throw new Error("Failed pattern match at TacoButton (line 141, column 18 - line 145, column 49): " + [ v.onClickEventHandler.constructor.name ]);
                    })(),
                    type: (function () {
                        if (v.submit) {
                            return "submit";
                        };
                        return "button";
                    })(),
                    title: v.title,
                    "_data": Components_Utils.mkTestIdAttrs(v.testId),
                    ref: v1.ref
                };
                var element = (function () {
                    if (v.href instanceof Data_Maybe.Just) {
                        return React_Basic_Emotion.element(React_Basic_DOM_Generated["a'"]())(Record.merge()()(commonProps)({
                            href: v.href.value0,
                            target: (function () {
                                if (v.newTab) {
                                    return "_blank";
                                };
                                return "";
                            })(),
                            rel: (function () {
                                if (v.newTab) {
                                    return "noopener noreferrer";
                                };
                                return "";
                            })()
                        }));
                    };
                    if (v.href instanceof Data_Maybe.Nothing) {
                        return React_Basic_Emotion.element(React_Basic_DOM_Generated["button'"]())(Record.merge()()(commonProps)({
                            disabled: v.disabled
                        }));
                    };
                    throw new Error("Failed pattern match at TacoButton (line 152, column 15 - line 160, column 10): " + [ v.href.constructor.name ]);
                })();
                return element;
            };
        });
    };
};
var actionToggleButton = TofuHooks.mkHookComponent("RemoteDataToggleButton")(function (props) {
    return function __do() {
        var v = TofuHooks.useState(props.value)();
        TofuHooks.useEffect([ props.value ])(function __do() {
            v.value1(function (v1) {
                return props.value;
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Effect.monoidEffect(Data_Monoid.monoidUnit)))();
        })();
        return component()()({
            icon: new Data_Maybe.Just(props.icon),
            color: (function () {
                if (v.value0) {
                    return props.colors.active;
                };
                return props.colors.inactive;
            })(),
            onClick: Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(props.action)(function (action) {
                return function __do() {
                    v.value1(function (v1) {
                        return !v.value0;
                    })();
                    return action();
                };
            }),
            title: props.title,
            testId: props.testId
        });
    };
});
module.exports = {
    component: component,
    defaultProps: defaultProps,
    actionToggleButton: actionToggleButton,
    Center: TacoButton_Types.Center,
    Left: TacoButton_Types.Left,
    Right: TacoButton_Types.Right,
    Filled: TacoButton_Types.Filled,
    Link: TacoButton_Types.Link,
    Outlined: TacoButton_Types.Outlined,
    Sidebar: TacoButton_Types.Sidebar,
    Text: TacoButton_Types.Text,
    IconLeft: TacoButton_Types.IconLeft,
    IconRight: TacoButton_Types.IconRight
};
