// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var CostEstimationLayout_Styles = require("../CostEstimationLayout.Styles/index.js");
var CostEstimationLayout_Types = require("../CostEstimationLayout.Types/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Show = require("../Data.Show/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoLoader = require("../TacoLoader/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var UseLocalStorage = require("../UseLocalStorage/index.js");
var useCostEstimationLayout = function (v) {
    return function __do() {
        var v1 = UseLocalStorage.useLocalStorageJSON(CostEstimationLayout_Types.tofuJSONPanelMode)(v.localStorageKey + "-leftPanelMode")(v.leftPanelInitMode)(false)();
        var v2 = UseLocalStorage.useLocalStorageJSON(CostEstimationLayout_Types.tofuJSONPanelMode)(v.localStorageKey + "-rightPanelMode")(v.rightPanelInitMode)(false)();
        var v3 = TofuHooks.useState(v.detailsPanelInitMode)();
        return {
            leftPanelMode: v1.value0,
            setLeftPanelMode: v1.value1,
            rightPanelMode: v2.value0,
            setRightPanelMode: v2.value1,
            detailsPanelMode: v3.value0,
            setDetailsPanelMode: v3.value1
        };
    };
};
var panelModeButton = TofuHooks.mkHookComponent("PanelModeButton")(function (v) {
    return Control_Applicative.pure(Effect.applicativeEffect)(TacoButton.component()()({
        icon: Data_Maybe.Just.create(TacoIcon_Icons_Types.IconCustom.create(Box.box("__PanelModeButtonIconContainer")([ Box.FlexRow.value, Box.AlignEnd.value, new Box.Height(TacoConstants.M.value) ])([ React_Basic_Emotion.element(React_Basic_DOM_Generated["span'"]())({
            className: "__PanelModeButtonIcon",
            css: CostEstimationLayout_Styles.panelModeButtonStyles({
                panelMode: v.panelMode,
                vertical: v.vertical,
                active: v.active,
                smallMode: v.smallMode
            })
        }) ]))),
        buttonStyle: TacoButton_Types.Link.value,
        color: (function () {
            if (v.active) {
                return TacoConstants.PrimaryBlue.value;
            };
            return TacoConstants.GrayLight.value;
        })(),
        onClick: Data_Maybe.Just.create(v.setPanelMode(function (v1) {
            return v.panelMode;
        })),
        disableFocusHack: false,
        testId: v.testId + ("-" + Data_Show.show(CostEstimationLayout_Types.showPanelMode)(v.panelMode))
    }));
});
var panelModeButtons = TofuHooks.mkHookComponent("PanelModeButtons")(function (v) {
    var mkPanelModeButton = function (mode) {
        return panelModeButton({
            panelMode: mode,
            setPanelMode: v.setPanelMode,
            vertical: v.vertical,
            active: Data_Eq.eq(CostEstimationLayout_Types.eqPanelMode)(v.panelMode)(mode),
            smallMode: v.smallMode,
            testId: v.testId
        });
    };
    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_Emotion.element(React_Basic_DOM_Generated["span'"]())({
        className: "__PanelModeButtons",
        css: CostEstimationLayout_Styles.panelModeButtonsStyles({
            vertical: v.vertical
        }),
        children: [ mkPanelModeButton(CostEstimationLayout_Types.MinimizedMode.value), mkPanelModeButton((function () {
            if (v.smallMode) {
                return CostEstimationLayout_Types.SmallMode.value;
            };
            return CostEstimationLayout_Types.NormalMode.value;
        })()), mkPanelModeButton((function () {
            if (v.smallMode) {
                return CostEstimationLayout_Types.NormalMode.value;
            };
            return CostEstimationLayout_Types.MaximizedMode.value;
        })()) ]
    }));
});
var estimationLayoutLoader = TofuHooks.mkHookComponent("EstimationLayoutLoader")(function (v) {
    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        className: "loaderContainer",
        css: CostEstimationLayout_Styles.loaderContainerStyles,
        children: [ TacoLoader.progressBar({
            color: TacoConstants.Gray.value,
            isLoading: Data_Eq.notEq(CostEstimationLayout_Types.eqPanelLoadingIndicator)(v.status)(CostEstimationLayout_Types.LoadingFinished.value),
            testId: "cost-estimation-layout"
        }) ]
    }));
});
var component = TofuHooks.mkHookComponent("CostEstimationLayout")(function (props) {
    var isPanel = Data_Maybe.isJust(props.mRefPanelMode);
    return function __do() {
        var controlsStyles$prime = TofuHooks.useMemo([ ReactHooksUtils.utf(props.mRefPanelMode) ])(function (v) {
            return CostEstimationLayout_Styles.controlsStyles(props.mRefPanelMode);
        })();
        var costEstimationLayoutContainerStyles$prime = TofuHooks.useMemo([ ReactHooksUtils.utf(props.leftPanel), ReactHooksUtils.utf(props.details), ReactHooksUtils.utf(props.rightPanel), ReactHooksUtils.utf(props.panelProps), ReactHooksUtils.utf(props.mRefPanelMode) ])(function (v) {
            return CostEstimationLayout_Styles.costEstimationLayoutContainerStyles({
                hasLeftPanel: Data_Maybe.isJust(props.leftPanel),
                hasDetailsPanel: Data_Maybe.isJust(props.details),
                hasRightPanel: Data_Maybe.isJust(props.rightPanel),
                panelProps: props.panelProps,
                isPanel: isPanel
            });
        })();
        var controlsDimmerStyles = TofuHooks.useMemo([ ReactHooksUtils.utf(props.controlsLoading) ])(function (v) {
            return CostEstimationLayout_Styles.loadingDimmerContainerStyles({
                loadingIndicator: props.controlsLoading
            });
        })();
        var leftPanelDimmerStyles = TofuHooks.useMemo([ ReactHooksUtils.utf(props.leftPanelLoading) ])(function (v) {
            return CostEstimationLayout_Styles.loadingDimmerContainerStyles({
                loadingIndicator: props.leftPanelLoading
            });
        })();
        var rightSidebarJsx = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "right-sidebar",
            css: CostEstimationLayout_Styles.rightSidebarStyles,
            children: ReactBasicUtils.maybeJSX(Data_Monoid.monoidArray)(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Array.singleton)(props.rightSidebar))
        });
        var rightPanelJsx = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "right-panel",
            css: CostEstimationLayout_Styles.rightPanelStyles,
            children: ReactBasicUtils.maybeJSX(Data_Monoid.monoidArray)(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Array.singleton)(props.rightPanel))
        });
        var mainJsx = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "main",
            css: CostEstimationLayout_Styles.mainStyles,
            children: [ props.main ]
        });
        var loadingDimmerContainer = function (children) {
            return function (styles) {
                return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                    className: "loadingDimmerContainer",
                    css: styles,
                    children: children
                });
            };
        };
        var leftSidebarJsx = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "left-sidebar",
            css: CostEstimationLayout_Styles.leftSidebarStyles,
            children: ReactBasicUtils.maybeJSX(Data_Monoid.monoidArray)(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Array.singleton)(props.leftSidebar))
        });
        var leftPanelJsx = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "left-panel",
            css: CostEstimationLayout_Styles.leftPanelStyles,
            children: (function () {
                if (props.leftPanel instanceof Data_Maybe.Just) {
                    return [ estimationLayoutLoader({
                        status: props.leftPanelLoading
                    }), loadingDimmerContainer([ props.leftPanel.value0 ])(leftPanelDimmerStyles) ];
                };
                if (props.leftPanel instanceof Data_Maybe.Nothing) {
                    return [  ];
                };
                throw new Error("Failed pattern match at CostEstimation.Layout (line 124, column 19 - line 129, column 24): " + [ props.leftPanel.constructor.name ]);
            })()
        });
        var detailsJsx = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "details",
            css: CostEstimationLayout_Styles.detailsStyles,
            children: ReactBasicUtils.maybeJSX(Data_Monoid.monoidArray)(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Array.singleton)(props.details))
        });
        var controlsJsx = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "controls",
            css: controlsStyles$prime,
            children: (function () {
                if (props.controls instanceof Data_Maybe.Just) {
                    return [ estimationLayoutLoader({
                        status: props.controlsLoading
                    }), loadingDimmerContainer([ props.controls.value0 ])(controlsDimmerStyles) ];
                };
                if (props.controls instanceof Data_Maybe.Nothing) {
                    return [  ];
                };
                throw new Error("Failed pattern match at CostEstimation.Layout (line 113, column 19 - line 118, column 24): " + [ props.controls.constructor.name ]);
            })()
        });
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "__CostEstimationLayout",
            css: costEstimationLayoutContainerStyles$prime,
            children: [ Data_Monoid.guard(React_Basic.monoidJSX)(Data_Maybe.isJust(props.controls))(controlsJsx), Data_Monoid.guard(React_Basic.monoidJSX)(Data_Maybe.isJust(props.leftPanel) && Data_Eq.notEq(CostEstimationLayout_Types.eqPanelMode)(props.panelProps.leftPanelMode)(CostEstimationLayout_Types.MinimizedMode.value))(leftPanelJsx), Data_Monoid.guard(React_Basic.monoidJSX)(Data_Maybe.isJust(props.leftSidebar))(leftSidebarJsx), mainJsx, Data_Monoid.guard(React_Basic.monoidJSX)(Data_Maybe.isJust(props.rightPanel) && Data_Eq.notEq(CostEstimationLayout_Types.eqPanelMode)(props.panelProps.rightPanelMode)(CostEstimationLayout_Types.MinimizedMode.value))(rightPanelJsx), Data_Monoid.guard(React_Basic.monoidJSX)(Data_Maybe.isJust(props.rightSidebar))(rightSidebarJsx), detailsJsx ]
        });
    };
});
module.exports = {
    estimationLayoutLoader: estimationLayoutLoader,
    component: component,
    useCostEstimationLayout: useCostEstimationLayout,
    panelModeButtons: panelModeButtons,
    panelModeButton: panelModeButton
};
