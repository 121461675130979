// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Effect_Exception = require("../Effect.Exception/index.js");
var ReducerUtils = require("../ReducerUtils/index.js");
var reducer = ReducerUtils.mkRemoteDataReducer(function (v) {
    if (v instanceof Actions.GetCurrentOrganizationRequest) {
        return new Data_Maybe.Just(ReducerUtils.RemoteDataFetching.value);
    };
    if (v instanceof Actions.GetCurrentOrganizationSuccess) {
        return Data_Maybe.Just.create(new ReducerUtils.RemoteDataSuccess(v.value0));
    };
    if (v instanceof Actions.GetCurrentOrganizationFailure) {
        return Data_Maybe.Just.create(new ReducerUtils.RemoteDataFailure({
            error: v.value0,
            userIdNotInDatabase: Data_String_CodeUnits.contains("userIdNotInDatabase")(Effect_Exception.message(v.value0))
        }));
    };
    return Data_Maybe.Nothing.value;
});
module.exports = {
    reducer: reducer
};
