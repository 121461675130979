import { useTranslation } from "react-i18next";
import { Text } from "@tocoman/ui";
import { useWorkerState } from "../../../hooks/useWorkerState";
import { ErrorMessage } from "../../../components/ErrorMessage";
import { useModalActionButtons } from "./hooks/useModalActionButtons";
import { useRunOnce } from "../../../hooks/useRunOnce";
import { useForm } from "react-hook-form";
import {
  useConvertToRegular,
  useConvertToTopic,
} from "./hooks/useToggleTopicRow";

export type ConvertToTopicProps = {
  projectId: number;
  componentIds: number[];
  onSave: () => void;
  closeModal: () => void;
  setActionButtons: (actions: JSX.Element) => void;
};

export type ConvertToParams = {
  projectId: number;
  componentId: number;
};

export type ConvertToResponse = {
  componentType: {
    type: string;
  };
  id: number;
};
export const ConvertToTopic = ({
  projectId,
  componentIds,
  onSave,
  closeModal,
  setActionButtons,
}: ConvertToTopicProps) => {
  const { t } = useTranslation("estimation", {
    keyPrefix: "componentView.editMultipleComponentsModal",
  });

  const allComponentsWithResources = useWorkerState(
    "ComponentsWithResourcesState"
  );

  const { handleSubmit } = useForm();

  const { mutate: convertToTopic } = useConvertToTopic(onSave);
  const { mutate: convertToRegular } = useConvertToRegular(onSave);

  const component = allComponentsWithResources?.find(
    (component) => component.component.id === componentIds[0]
  );
  const validComponent =
    componentIds.length === 1 &&
    component?.component.amount === 0 &&
    component?.resources.length === 0;

  const allComponents = useWorkerState("EstimationComponentsState");
  const convertComponent = allComponents?.find(
    (component) => component.id === componentIds[0]
  );

  const handleSave = () => {
    const convertData = {
      projectId: projectId,
      componentId: convertComponent?.id ?? 0,
    };
    if (convertComponent?.componentType === 0) {
      convertToTopic(convertData);
    }
    if (convertComponent?.componentType === 3) {
      convertToRegular(convertData);
    }
  };

  const setActions = useModalActionButtons(
    setActionButtons,
    handleSubmit(handleSave),
    closeModal
  );
  useRunOnce(() => {
    setActions(!validComponent);
  });

  return (
    <>
      {componentIds.length > 1 && (
        <ErrorMessage errorMessage={t`tooManySelectedComponents`} />
      )}
      {componentIds.length === 1 &&
        component?.component.amount !== 0 &&
        component?.resources.length !== 0 && (
          <ErrorMessage
            errorMessage={t`selectedComponentNotAllowedToHaveAmountOrResources`}
          />
        )}
      {componentIds.length === 1 &&
        component?.component.amount !== 0 &&
        component?.resources.length === 0 && (
          <ErrorMessage
            errorMessage={t`selectedComponentNotAllowedToHaveAmount`}
          />
        )}
      {componentIds.length === 1 &&
        component?.component.amount === 0 &&
        component?.resources.length !== 0 && (
          <ErrorMessage
            errorMessage={t`selectedComponentNotAllowedToHaveResources`}
          />
        )}
      {validComponent && component?.component.componentType === 0 && (
        <>
          <Text text={t`convertToTopicRowDescription`} />
          <Text
            text={`${component?.component.code} - ${component?.component.description}`}
          />
        </>
      )}
      {validComponent && component?.component.componentType === 3 && (
        <>
          <Text text={t`convertToRegularRowDescription`} />
          <Text
            text={`${component?.component.code} - ${component?.component.description}`}
          />
        </>
      )}
    </>
  );
};
