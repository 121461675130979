import { ValueFormatterParams, ValueParserParams } from "ag-grid-community";
import i18n from "i18next";

const defaultLanguage = "fi-FI";

export const percentageWithZeroValueColDef = {
  valueParser: percentageParser,
  valueFormatter: percentageFormatter,
  cellEditorParams: {
    useFormatter: true,
  },
  cellClass: "percentageFormat",
  cellStyle: { textAlign: "right" },
};

function percentageParser(cellData: ValueParserParams) {
  const fixedNumberStr = Number(cellData.newValue.replace(",", "."));
  if (isNaN(fixedNumberStr)) {
    return cellData.oldValue;
  }

  return fixedNumberStr / 100;
}

function percentageFormatter(params: ValueFormatterParams) {
  if (params.value === 0) {
    return (0).toLocaleString(i18n?.language ?? defaultLanguage, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  if (!params.value) {
    return "";
  }
  return (params.value * 100).toLocaleString(
    i18n?.language ?? defaultLanguage,
    {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
  );
}
