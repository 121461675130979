// Generated by purs version 0.14.5
"use strict";
var Data_Show = require("../Data.Show/index.js");
var LocalStorageKeys = require("../LocalStorageKeys/index.js");
var ReactQuery_Internal = require("../ReactQuery.Internal/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Organization = require("../Types.Organization/index.js");
var UseCurrentOrganization = require("../UseCurrentOrganization/index.js");
var UseLocalStorage = require("../UseLocalStorage/index.js");
var useTargetCostGroup = function (projectId) {
    return function __do() {
        var plan = UseCurrentOrganization.useOrganizationLicensePlan();
        var showTargetCostGroupKey = LocalStorageKeys.getLocalStorageKeyStr(new LocalStorageKeys.UIEstimationShowTargetCostGroups(projectId));
        var defaultValue = (function () {
            if (plan instanceof ReactQuery_Internal.Success && plan.value0 instanceof Types_Organization.Pro) {
                return "true";
            };
            return "false";
        })();
        var v = UseLocalStorage.useLocalStorage(showTargetCostGroupKey)(defaultValue)(false)();
        var showTargetCostGroup = v.value0 === Data_Show.show(Data_Show.showBoolean)(true);
        var onTargetCostGroupToggle = TofuHooks.useMemo([  ])(function (v1) {
            return v.value1(function (value) {
                return Data_Show.show(Data_Show.showBoolean)(!(value === Data_Show.show(Data_Show.showBoolean)(true)));
            });
        })();
        return {
            showTargetCostGroup: showTargetCostGroup,
            onTargetCostGroupToggle: onTargetCostGroupToggle
        };
    };
};
module.exports = {
    useTargetCostGroup: useTargetCostGroup
};
