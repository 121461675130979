const uuid = require("uuid");
const lodash = require("lodash");

exports.genv4UUID = () => uuid.v4();

exports.baseUrl = process.env.SERVER_URL;

exports.unsafeLog2 = (a) => (b) => (_) => console.log(a, b);

exports._memoizeWithJSON = function (writeJSON) {
  return function (fn) {
    return lodash.memoize(fn, writeJSON);
  };
};

exports.encodeURIComponent = function (s) {
  return encodeURIComponent(s);
};

exports.encodeURI = function (s) {
  return encodeURI(s);
};

exports.decodeURIComponent = function (s) {
  return decodeURIComponent(s);
};

exports.spyTime = function () {
  return new Date().getTime();
};

exports.arrayMapNullable = (f) => (xs) => {
  let out = [];
  for (let i = 0; i < xs.length; i++) {
    let item = xs[i];
    let res = f(item);
    if (res != null) {
      out.push(res);
    }
  }
  return out;
};

exports.scoreMatch = (ref) => (str) => {
  if (ref.length !== 0 && str.length !== 0) {
    var score = 0;
    for (var i = 0; i < ref.length; i++) {
      var refChar = ref[i];
      var strChar = str[i];
      if (refChar === strChar) {
        score++;
      } else {
        return score;
      }
    }
    return score;
  }
  return 0;
};
