import { Divider } from "@mui/material";
import {
  Button,
  Combobox,
  Header,
  IconAddPlusV1,
  SelectItem,
  Text,
} from "@tocoman/ui";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "src/client-ts/components/Table";
import { AddAllowedCurrencyModal } from "./AddAllowedCurrencyModal";
import { DeleteSelectedCurrencyModal } from "./DeleteSelectedCurrencyModal";
import {
  useAllowedCurrenciesQuery,
  useDefaultCurrencyMutation,
  useDefaultCurrencyQuery,
} from "./useOrganizationCurrencySettings";

export type CurrencyTableData = {
  currency: string;
  default: boolean;
};

export function OrganizationCurrencySettings() {
  const { t } = useTranslation("settings", { keyPrefix: "currencies" });

  const { data: currentDefault } = useDefaultCurrencyQuery();
  const { data: allowedCurrencies } = useAllowedCurrenciesQuery();

  const { mutate: changeDefaultCurrency } = useDefaultCurrencyMutation();

  const [addCurrencyModalOpen, setAddCurrencyModalOpen] = useState<boolean>(
    false
  );

  const currencyTableData = useMemo(() => {
    return mapCurrencyTableData(
      allowedCurrencies,
      currentDefault?.defaultCurrency
    );
  }, [allowedCurrencies, currentDefault]);

  const currencySelections: SelectItem<string>[] = useMemo(() => {
    if (!allowedCurrencies) {
      return [];
    }
    return allowedCurrencies?.map((currency) => {
      return {
        label: currency,
        value: currency,
        key: currency,
      };
    });
  }, [allowedCurrencies]);

  const onDefaultCurrencyChange = (newDefault: SelectItem<string> | null) => {
    if (!newDefault) {
      return;
    }
    changeDefaultCurrency(newDefault.value);
  };

  const columnDefs: ColDef<CurrencyTableData>[] = useMemo(
    () => [
      {
        headerName: t`currency`,
        field: "currency",
      },
      {
        headerName: t`default`,
        field: "default",
        sort: "desc",
      },
      {
        colId: "delete",
        headerName: "",
        width: 89,
        sortable: false,
        lockVisible: true,
        lockPinned: true,
        pinned: "right" as const,
        resizable: false,
        cellRenderer: (cellData: ICellRendererParams<CurrencyTableData>) => {
          return cellData.data ? (
            <DeleteSelectedCurrencyModal
              currency={cellData.data.currency}
              isDefault={cellData.data.default}
            />
          ) : null;
        },
      },
    ],
    []
  );

  const defaultColDef: ColDef<CurrencyTableData> = useMemo(() => {
    return {
      flex: 1,
    };
  }, []);

  return (
    <div className="h-max w-[500px]">
      <Header title={t`currencyHeader`} titleSize={"medium"} />
      <Text text={t`currencyInfo`} className={"mb-4"} />
      <Divider className={"mt-4 mb-4"} />

      <div>
        <Header
          title={t`defaultCurrencyHeader`}
          titleSize={"small"}
          className={"mt-4 mb-2"}
        />
        <Combobox
          label={t`defaultCurrencyInfo`}
          items={currencySelections}
          selected={
            currencySelections.find(
              (item) => item.value === currentDefault?.defaultCurrency
            ) ?? null
          }
          onValueChange={onDefaultCurrencyChange}
        />
      </div>

      <div>
        <Header
          title={t`currencyTableHeader`}
          titleSize={"small"}
          className={"mt-4"}
        />
        <Text text={t`currencyTableInfo`} className={"mb-2 mt-4"} />
        <Table<CurrencyTableData>
          className="h-[248px] w-full mt-4"
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowData={currencyTableData}
          getRowId={(row) => row.data.currency}
        />
      </div>

      <Button
        label={t`addAllowedButton`}
        onClick={() => setAddCurrencyModalOpen(true)}
        className={"mt-4"}
        icon={IconAddPlusV1}
        iconPosition={"left"}
      />
      {addCurrencyModalOpen ? (
        <AddAllowedCurrencyModal
          orgCurrencies={allowedCurrencies ?? []}
          onClose={() => setAddCurrencyModalOpen(false)}
        />
      ) : null}
    </div>
  );
}

export function mapCurrencyTableData(
  currencies: string[] | undefined,
  defaultCurrency: string | undefined
): CurrencyTableData[] | null {
  if (!currencies) {
    return null;
  }
  return currencies.map((currency) => {
    return {
      currency,
      default: currency === defaultCurrency,
    };
  });
}
