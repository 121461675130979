import { RadioGroup } from "@tocoman/ui";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { useRunOnce } from "../../../hooks/useRunOnce";
import {
  useDeleteComponentLocationAmounts,
  useDeleteComponentPrices,
  useDeleteComponentGroups,
} from "./hooks/useDeleteComponentProperties";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useModalActionButtons } from "./hooks/useModalActionButtons";
import {
  useToggleComponentChecks,
  useToggleComponentFlags,
} from "./hooks/useToggleComponentProperties";
import { useEditCostGroup } from "./hooks/useEditCostGroup";
import {
  LicenseFeature,
  useLicenseFeatureEnabled,
} from "../../../LicenseFeatures/useLicenseFeature";

type DeleteComponentPropertiesProps = {
  projectId: number;
  componentIds: number[];
  closeModal: () => void;
  onSave: () => void;
  setActionButtons: (actions: JSX.Element) => void;
};

export function DeleteComponentProperties({
  projectId,
  componentIds,
  closeModal,
  onSave,
  setActionButtons,
}: DeleteComponentPropertiesProps) {
  const { t } = useTranslation("estimation", {
    keyPrefix: "componentView.editMultipleComponentsModal",
  });

  const methods = useForm<{ selectedAction: string }>({
    defaultValues: { selectedAction: "amounts" },
  });
  const { handleSubmit, watch } = methods;
  const watchAction = watch("selectedAction");

  const groupsEnabled = useLicenseFeatureEnabled(
    LicenseFeature.COMPONENT_GROUPS
  );

  const radioItems = [
    {
      value: "amounts",
      label: t`delete.amounts`,
    },
    {
      value: "checked",
      label: t`delete.marks`,
    },
    {
      value: "flags",
      label: t`delete.flags`,
    },
    {
      value: "costGroups",
      label: t`delete.costGroups`,
    },
    {
      value: "prices",
      label: t`delete.prices`,
    },
  ];
  if (groupsEnabled) {
    radioItems.push({
      value: "groups",
      label: t`delete.groups`,
    });
  }

  const { mutate: deleteAmounts } = useDeleteComponentLocationAmounts(
    projectId,
    onSave
  );
  const { mutate: deletePrices } = useDeleteComponentPrices(projectId, onSave);
  const { mutate: deleteGroups } = useDeleteComponentGroups(projectId, onSave);
  const { mutate: deleteFlags } = useToggleComponentFlags(projectId, onSave);
  const { mutate: deleteChecks } = useToggleComponentChecks(projectId, onSave);
  const { mutate: deleteCostGroups } = useEditCostGroup(projectId, onSave);

  const handleSave = useCallback(
    // eslint-disable-next-line
    (form: any) => {
      const { selectedAction } = form;
      if (selectedAction === "amounts") {
        deleteAmounts({
          type: "ResetEstimationLocations",
          value: componentIds.map((comp) => {
            return { componentId: comp };
          }),
        });
      }
      if (selectedAction === "flags") {
        deleteFlags({
          components: componentIds,
          flagged: false,
        });
      }
      if (selectedAction === "checked") {
        deleteChecks({
          components: componentIds,
          checked: false,
        });
      }
      if (selectedAction === "costGroups") {
        deleteCostGroups({
          type: "PlanningSetComponentCostGroups",
          value: componentIds.map((comp) => {
            return { componentId: comp, costGroupCode: "" };
          }),
        });
      }
      if (selectedAction === "groups") {
        deleteGroups({
          components: componentIds,
        });
      }
      if (selectedAction === "prices") {
        deletePrices({
          components: componentIds,
        });
      }
    },
    [
      deleteAmounts,
      componentIds,
      deleteFlags,
      deleteChecks,
      deleteCostGroups,
      deletePrices,
      deleteGroups,
    ]
  );

  const setActions = useModalActionButtons(
    setActionButtons,
    handleSubmit(handleSave),
    closeModal
  );

  useRunOnce(() => {
    setActions(false);
  });

  return (
    <div>
      <p>{t`delete.info`}</p>
      <FormProvider {...methods}>
        <Controller
          name={"selectedAction"}
          render={({ field: { onChange, value } }) => (
            <RadioGroup
              selected={
                radioItems.find((item) => item.value === value) ?? radioItems[0]
              }
              onValueChange={(value) => onChange(value.value)}
              items={radioItems}
              horizontal={true}
            />
          )}
        />
      </FormProvider>
      {watchAction === "amounts" && <p>{t`delete.amountsInfo`}</p>}
      {watchAction === "groups" && <p>{t`delete.groupsInfo`}</p>}
    </div>
  );
}
