import { OrganizationPricelistStatus } from "@prisma/client";
import { useMutation, useQuery } from "react-query";
import axiosClient from "../../axiosConfig";

const baseUrl = "/super-admin/pricelist-update";

export function useUpdatePricing() {
  return useMutation({
    mutationFn: async (pricing: FormData) => {
      const { data } = await axiosClient.post<FormData>(`${baseUrl}`, pricing);
      return data;
    },
  });
}

export function useQueryPricingUpdateStatus(updateStarted: boolean) {
  return useQuery<OrganizationPricelistStatus[]>(
    [baseUrl],
    async () => {
      const { data } = await axiosClient.get(`${baseUrl}/organizations-status`);
      return data;
    },
    { refetchInterval: updateStarted ? 2000 : false }
  );
}
