// Generated by purs version 0.14.5
"use strict";
var Common = require("../Common/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var CostEstimation_BuildingElementsView_Types = require("../CostEstimation.BuildingElementsView.Types/index.js");
var CostEstimation_Calculations = require("../CostEstimation.Calculations/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Array_NonEmpty_Internal = require("../Data.Array.NonEmpty.Internal/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Hierarchy = require("../Hierarchy/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var StringUtils = require("../StringUtils/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_BuildingElementsView = require("../Types.BuildingElementsView/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_Element = require("../Types.Element/index.js");
var Types_Project = require("../Types.Project/index.js");
var UseKeydownListener = require("../UseKeydownListener/index.js");
var Utils = require("../Utils/index.js");
var Utils_Keyboard = require("../Utils.Keyboard/index.js");
var useSelectedComponentIds = function (props) {
    var ed = Data_Functor.map(Data_Maybe.functorMaybe)(Data_Newtype.un()(Types_BuildingElementsView.WasmElementsData))(props.selectedElement);
    var firstSpecId = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v) {
        return Data_Functor.map(Data_Maybe.functorMaybe)(Data_Tuple.Tuple.create(v.elementSpecId))(Data_Nullable.toMaybe(v.componentId));
    })(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Data_Array.head)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
        return v.specs;
    })(ed)));
    return function __do() {
        var v = TofuHooks.useState(firstSpecId)();
        TofuHooks.useLayoutEffect([ Data_Maybe.fromMaybe(-1 | 0)(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Functor.map(Data_Functor.functorFn)(Data_Newtype.un()(Types_Element.ElementId))(CostEstimation_BuildingElementsView_Types["_elementId"]))(props.selectedElement)) ])(function __do() {
            v.value1(function (v1) {
                return firstSpecId;
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        })();
        var selectedComponentId = Data_Functor.map(Data_Maybe.functorMaybe)(Data_Tuple.snd)(v.value0);
        var selectedDetailsComponent = TofuHooks.useMemo([ ReactHooksUtils.utf(props.components), ReactHooksUtils.utf(selectedComponentId) ])(function (v1) {
            return Data_Array.find(function (v2) {
                return Data_Eq.eq(Data_Maybe.eqMaybe(Types_Component.eqComponentId))(new Data_Maybe.Just(v2.component.id))(selectedComponentId);
            })(props.components);
        })();
        return {
            selectedComponentIds: v.value0,
            setSelectedComponentIds: v.value1,
            selectedDetailsComponent: selectedDetailsComponent
        };
    };
};
var tableRowHeight = TacoTable_Types.RowMedium.value;
var sortElements = function (criteria) {
    if (criteria.value1 instanceof Data_Maybe.Just) {
        var withOrder = (function () {
            var $46 = Data_Eq.eq(TacoTable_Types.eqSortingOrder)(criteria.value0)(TacoTable_Types.Descending.value);
            if ($46) {
                return Data_Function.flip;
            };
            return Control_Category.identity(Control_Category.categoryFn);
        })();
        var sortableValue = function (x) {
            return criteria.value2(criteria.value1.value0)(x);
        };
        return Data_Array.sortBy(function (a) {
            return function (b) {
                return withOrder(Data_Ord.compare(TacoTable_Types.ordComparableValue))(sortableValue(a))(sortableValue(b));
            };
        });
    };
    return Control_Category.identity(Control_Category.categoryFn);
};
var mkTopicRow = function (getName) {
    return function (nea) {
        var unWED = function (v) {
            return v;
        };
        var v = Data_Array_NonEmpty.head(nea);
        var totalPrice = Data_Foldable.sum(Data_Array_NonEmpty_Internal.foldableNonEmptyArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)(function ($110) {
            return (function (v1) {
                return v1.totalPrice;
            })(unWED($110));
        })(nea));
        var topicElement = {
            elementId: -1 | 0,
            subprojectId: Utils.nullValue,
            code: "",
            description: "",
            unit: "",
            groupFlag: 0,
            memo: "",
            rtCode: "",
            rtDescription: getName(v)
        };
        return {
            element: topicElement,
            subprojectCode: v.subprojectCode,
            projectOfOrigin: Utils.nullValue,
            specs: Common.emptyArray,
            locations: Common.emptyArray,
            components: Common.emptyArray,
            amount: 0.0,
            unitPrice: 0.0,
            totalPrice: totalPrice,
            totalPriceByCostClasses: Common.emptyArray,
            isTopicRow: true
        };
    };
};
var mkComponentWithResources = function (estimationComponents) {
    return function (estimationResources) {
        var bundledResources = CostEstimation_Calculations.bundleEstimationResources(estimationResources);
        return Data_Functor.mapFlipped(Data_Functor.functorArray)(estimationComponents)(function (c) {
            return {
                resources: CostEstimation_Calculations.filterEstimationResources(c)(bundledResources),
                totalPrice: 0.0,
                totalPriceWithoutSocialExpenses: 0.0,
                totalPriceByCostClasses: Common.emptyArray,
                hours: 0.0,
                hourlyRate: 0.0,
                component: c,
                locations: Common.emptyArray,
                checkboxState: false,
                projectOfOrigin: Data_Maybe.Nothing.value,
                isSplitComponentWithMissingResources: false
            };
        });
    };
};
var handleCursorNavigation = function (v) {
    return function (v1) {
        return function (deps) {
            return UseKeydownListener.useKeydownListener([ deps ])(v.isActive)([ Utils_Keyboard.ArrowUp.value, Utils_Keyboard.ArrowDown.value, Utils_Keyboard.Escape.value ])(ReactHooksUtils.utf("BuildingElementsViewDetailsNavigation"))(function (keyDownEvent) {
                if (keyDownEvent.key instanceof Utils_Keyboard.ArrowUp) {
                    return function __do() {
                        Utils_Keyboard.preventDefault(keyDownEvent.event)();
                        return v.selectElementRelative(-1 | 0)();
                    };
                };
                if (keyDownEvent.key instanceof Utils_Keyboard.ArrowDown) {
                    return function __do() {
                        Utils_Keyboard.preventDefault(keyDownEvent.event)();
                        return v.selectElementRelative(1)();
                    };
                };
                if (keyDownEvent.key instanceof Utils_Keyboard.Escape) {
                    return v.closeDetails;
                };
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            });
        };
    };
};
var groupElements = function (v) {
    return function (classifications) {
        return function (projectLocations) {
            return function (v1) {
                return function (sortingCriteria) {
                    return function (groupBy) {
                        return function (xs) {
                            var groupByLocation = function (elements) {
                                var transformMatch = function (matchCriteriaFn) {
                                    return function (v2) {
                                        var unWEL = function (v3) {
                                            return v3;
                                        };
                                        var filteredLocations = Data_Array.filter(matchCriteriaFn)(v2.locations);
                                        var filteredLocationsAmount = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function ($111) {
                                            return (function (v3) {
                                                return v3.amount;
                                            })(unWEL($111));
                                        })(filteredLocations));
                                        var ratio = Utils.defaultZeroDiv(filteredLocationsAmount)(v2.amount);
                                        var filteredRatioTotalPrice = ratio * v2.totalPrice;
                                        var alterComponentSummary = function (r) {
                                            return {
                                                amount: r.amount * ratio,
                                                totalPrice: r.totalPrice * ratio,
                                                componentReferenceCount: r.componentReferenceCount,
                                                consumptionRate: r.consumptionRate,
                                                description: r.description,
                                                elementUnitPrice: r.elementUnitPrice,
                                                formula: r.formula,
                                                group: r.group,
                                                resourcesByComponent: r.resourcesByComponent,
                                                spec: r.spec,
                                                unit: r.unit,
                                                unitPrice: r.unitPrice
                                            };
                                        };
                                        var alteredComponents = Data_Functor.map(Data_Functor.functorArray)(alterComponentSummary)(v2.components);
                                        var $63 = Data_Array.length(filteredLocations) === 0 || filteredLocationsAmount === 0.0;
                                        if ($63) {
                                            return Data_Maybe.Nothing.value;
                                        };
                                        return Data_Maybe.Just.create(Types_BuildingElementsView.WasmElementsData({
                                            element: v2.element,
                                            subprojectCode: v2.subprojectCode,
                                            projectOfOrigin: v2.projectOfOrigin,
                                            specs: v2.specs,
                                            locations: filteredLocations,
                                            components: alteredComponents,
                                            amount: filteredLocationsAmount,
                                            unitPrice: v2.unitPrice,
                                            totalPrice: filteredRatioTotalPrice,
                                            totalPriceByCostClasses: v2.totalPriceByCostClasses,
                                            isTopicRow: v2.isTopicRow
                                        }));
                                    };
                                };
                                var split = function (code) {
                                    return Data_String_Common.split(">")(code);
                                };
                                var matchCode = function (code) {
                                    return function (v2) {
                                        return v2.code === code;
                                    };
                                };
                                var isPrefix = function (pattern) {
                                    return function (str) {
                                        return Data_Maybe.isJust(Data_String_CodeUnits.stripPrefix(pattern)(str));
                                    };
                                };
                                var isPrefixedByLocation = function (prefix) {
                                    var matchPrefix = function (v2) {
                                        return isPrefix(prefix)(v2.locationCode);
                                    };
                                    return transformMatch(matchPrefix);
                                };
                                var getName = function (code) {
                                    return Data_Maybe.fromMaybe(code)(Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.find(matchCode(code))(projectLocations))(function (v2) {
                                        return Data_Maybe.Just.create(code + (" - " + v2.description));
                                    }));
                                };
                                var exactPrefixMatch = function (prefix) {
                                    var matchPrefix = function (v2) {
                                        return prefix === v2.locationCode;
                                    };
                                    return transformMatch(matchPrefix);
                                };
                                var depth = function (code) {
                                    return Data_Array.length(split(code));
                                };
                                var isDepth = function (n) {
                                    return function (str) {
                                        return depth(str) === n;
                                    };
                                };
                                var filterAndSummarize = function (level) {
                                    return function (codes) {
                                        return function (prefix) {
                                            var filtered = sortElements(sortingCriteria)(Data_Array.mapMaybe(isPrefixedByLocation(prefix))(elements));
                                            var v2 = Data_Array_NonEmpty.fromArray(filtered);
                                            if (v2 instanceof Data_Maybe.Nothing) {
                                                return Common.emptyArray;
                                            };
                                            if (v2 instanceof Data_Maybe.Just) {
                                                var topicRow = mkTopicRow(function (v3) {
                                                    return getName(prefix);
                                                })(v2.value0);
                                                var childCodes = Data_Array.filter(isPrefix(prefix))(codes);
                                                var nextLevelCodes = Data_Array.filter(isDepth(level + 1 | 0))(childCodes);
                                                var children = (function () {
                                                    var $70 = depth(prefix) >= 5 || Data_Array["null"](nextLevelCodes);
                                                    if ($70) {
                                                        return filtered;
                                                    };
                                                    var nextLevels = Control_Bind.bindFlipped(Control_Bind.bindArray)(filterAndSummarize(level + 1 | 0)(childCodes))(nextLevelCodes);
                                                    var exact = sortElements(sortingCriteria)(Data_Array.mapMaybe(exactPrefixMatch(prefix))(filtered));
                                                    return Data_Semigroup.append(Data_Semigroup.semigroupArray)(exact)(nextLevels);
                                                })();
                                                return Data_Semigroup.append(Data_Semigroup.semigroupArray)([ topicRow ])(children);
                                            };
                                            throw new Error("Failed pattern match at CostEstimation.BuildingElementsView (line 227, column 11 - line 243, column 39): " + [ v2.constructor.name ]);
                                        };
                                    };
                                };
                                var _locationCode = function (v2) {
                                    return v2.code;
                                };
                                var locationCodes = Data_Array.sort(Data_Ord.ordString)(Data_Array.nub(Data_Ord.ordString)(Data_Array.filter(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_String_Common["null"]))(Data_Functor.map(Data_Functor.functorArray)(_locationCode)(projectLocations))));
                                var mainLocationCodes = Data_Array.nub(Data_Ord.ordString)(Data_Array.sort(Data_Ord.ordString)(Data_Array.mapMaybe(function ($112) {
                                    return Data_Array.head(split($112));
                                })(locationCodes)));
                                return Control_Bind.bindFlipped(Control_Bind.bindArray)(filterAndSummarize(1)(locationCodes))(mainLocationCodes);
                            };
                            var groupByClassificationClass = function (ys) {
                                var sortedElements = sortElements(sortingCriteria)(ys);
                                var matchCode = function (code) {
                                    return function (v2) {
                                        return v2.code === code;
                                    };
                                };
                                var getName = function (code) {
                                    return Data_Maybe.fromMaybe(code)(Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.find(matchCode(code))(classifications))(function (v2) {
                                        return Data_Maybe.Just.create(code + (" - " + v2.description));
                                    }));
                                };
                                var mkTopicRow$prime = function (label) {
                                    return function (es) {
                                        var v2 = Data_Array_NonEmpty.fromArray(es);
                                        if (v2 instanceof Data_Maybe.Nothing) {
                                            return Data_Maybe.Nothing.value;
                                        };
                                        if (v2 instanceof Data_Maybe.Just) {
                                            return Data_Maybe.Just.create(mkTopicRow(function (v3) {
                                                return getName(label);
                                            })(v2.value0));
                                        };
                                        throw new Error("Failed pattern match at CostEstimation.BuildingElementsView (line 172, column 11 - line 174, column 68): " + [ v2.constructor.name ]);
                                    };
                                };
                                var _code = function (v2) {
                                    return Data_Newtype.unwrap()(v2.element.code);
                                };
                                var valTuples = Data_Functor.map(Data_Functor.functorArray)(function (c) {
                                    return new Data_Tuple.Tuple(_code(c), c);
                                })(sortedElements);
                                var _classificationCode = function (v2) {
                                    return v2.code;
                                };
                                var classificationClassCodes = Data_Array.sort(Data_Ord.ordString)(Data_Array.nub(Data_Ord.ordString)(Data_Array.filter(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_String_Common["null"]))(Data_Functor.map(Data_Functor.functorArray)(_classificationCode)(classifications))));
                                var hierarchy = Hierarchy.addValuesToHierarchy(valTuples)(Hierarchy.mkHierarchy(classificationClassCodes));
                                var elementsWithTopicRows = Data_Array.catMaybes(Hierarchy.listHierarchy(mkTopicRow$prime)(function (x) {
                                    return new Data_Maybe.Just(x);
                                })(hierarchy));
                                return elementsWithTopicRows;
                            };
                            var addSummary = function (getName) {
                                return function (ys) {
                                    var ys$prime = Data_Array_NonEmpty.toArray(ys);
                                    return Data_Semigroup.append(Data_Semigroup.semigroupArray)([ mkTopicRow(getName)(ys) ])(sortElements(sortingCriteria)(ys$prime));
                                };
                            };
                            var groupElementsBy = function (getProperty) {
                                return function (getName) {
                                    var pregroup = Data_Array.sortWith(Data_Ord.ordString)(getProperty)(xs);
                                    var grouped = Data_Array.groupBy(Data_Function.on(Data_Eq.eq(Data_Eq.eqString))(getProperty))(pregroup);
                                    var withSummary = Data_Functor.map(Data_Functor.functorArray)(addSummary(getName))(grouped);
                                    return Data_Array.concat(withSummary);
                                };
                            };
                            if (groupBy instanceof Types_BuildingElementsView.GroupBySubproject) {
                                var _subprojectCode = function (v2) {
                                    return v2.subprojectCode;
                                };
                                return groupElementsBy(_subprojectCode)(_subprojectCode);
                            };
                            if (groupBy instanceof Types_BuildingElementsView.GroupByClassification) {
                                return groupByClassificationClass(xs);
                            };
                            if (groupBy instanceof Types_BuildingElementsView.GroupByLocation) {
                                return groupByLocation(xs);
                            };
                            throw new Error("Failed pattern match at CostEstimation.BuildingElementsView (line 134, column 3 - line 141, column 25): " + [ groupBy.constructor.name ]);
                        };
                    };
                };
            };
        };
    };
};
var getElementId = function (estimationElements) {
    return function (clCode) {
        var sortedElements = Data_Array.sortBy(Data_Function.on(Data_Ord.compare(Types_Element.ordElementCode))(function (v) {
            return v.code;
        }))(estimationElements);
        var matchElemCode = function (v) {
            var $86 = StringUtils.startsWith(clCode)(v.code);
            if ($86) {
                return new Data_Maybe.Just(v.elementId);
            };
            return Data_Maybe.Nothing.value;
        };
        return Data_Array.findMap(matchElemCode)(sortedElements);
    };
};
var elementIdOfRow = function ($113) {
    return CostEstimation_BuildingElementsView_Types["_elementId"]((function (v) {
        return v.elementsData;
    })($113));
};
var associateProject = function (projects) {
    return function (v) {
        var matchProject = function (p) {
            return Data_Eq.eq(Data_Maybe.eqMaybe(Types_Project.eqProjectId))(new Data_Maybe.Just(p.id))(Data_Nullable.toMaybe(v.projectOfOrigin));
        };
        return Data_Array.find(matchProject)(projects);
    };
};
var mkElementRow = function (estimationProjects) {
    return function (checkedElements) {
        return function (shortenReferenceProjectDetails) {
            return function (detailsElementId) {
                return function (selectedInputColumn) {
                    return function (dixonOptions) {
                        return function (columnSizeOffsets) {
                            return function (idx) {
                                return function (v) {
                                    var selectedRowColumn = (function () {
                                        var $102 = Data_Eq.eq(Data_Maybe.eqMaybe(Types_Element.eqElementId))(detailsElementId)(new Data_Maybe.Just(v.element.elementId));
                                        if ($102) {
                                            return selectedInputColumn;
                                        };
                                        return Data_Maybe.Nothing.value;
                                    })();
                                    var dixonElementLinks = (function () {
                                        if (dixonOptions instanceof Data_Maybe.Just) {
                                            return Data_Map_Internal.lookup(Types_Element.ordElementId)(v.element.elementId)(dixonOptions.value0.measurement_group_links_by_element);
                                        };
                                        if (dixonOptions instanceof Data_Maybe.Nothing) {
                                            return Data_Maybe.Nothing.value;
                                        };
                                        throw new Error("Failed pattern match at CostEstimation.BuildingElementsView (line 93, column 25 - line 95, column 25): " + [ dixonOptions.constructor.name ]);
                                    })();
                                    var active_measurement_groups = Data_Functor.map(Data_Maybe.functorMaybe)(function (v1) {
                                        return v1.active_measurement_groups;
                                    })(dixonOptions);
                                    var activeDixonElementLinks = (function () {
                                        if (dixonOptions instanceof Data_Maybe.Just && dixonElementLinks instanceof Data_Maybe.Just) {
                                            return Data_Maybe.Just.create(Data_Array.filter(function (mgl) {
                                                return Data_Array.elem(Data_Eq.eqInt)(mgl.measurement_group_id)(Data_Functor.map(Data_Functor.functorArray)(function (v1) {
                                                    return v1.id;
                                                })(dixonOptions.value0.active_measurement_groups));
                                            })(dixonElementLinks.value0));
                                        };
                                        return Data_Maybe.Nothing.value;
                                    })();
                                    return {
                                        elementsData: v,
                                        associatedProject: associateProject(estimationProjects)(v),
                                        checked: Data_Set.member(Types_Element.ordElementId)(v.element.elementId)(checkedElements),
                                        shortenReferenceProjectDetails: shortenReferenceProjectDetails,
                                        selectedRowColumn: selectedRowColumn,
                                        dixonElementLinks: dixonElementLinks,
                                        activeDixonElementLinks: activeDixonElementLinks,
                                        active_measurement_groups: active_measurement_groups,
                                        columnSizeOffsets: columnSizeOffsets,
                                        idx: idx
                                    };
                                };
                            };
                        };
                    };
                };
            };
        };
    };
};
module.exports = {
    tableRowHeight: tableRowHeight,
    getElementId: getElementId,
    mkElementRow: mkElementRow,
    associateProject: associateProject,
    sortElements: sortElements,
    groupElements: groupElements,
    mkTopicRow: mkTopicRow,
    mkComponentWithResources: mkComponentWithResources,
    elementIdOfRow: elementIdOfRow,
    handleCursorNavigation: handleCursorNavigation,
    useSelectedComponentIds: useSelectedComponentIds
};
