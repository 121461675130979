// Generated by purs version 0.14.5
"use strict";
var Control_Apply_Indexed = require("../Control.Apply.Indexed/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Function = require("../Data.Function/index.js");
var idiscard = function (dict) {
    return dict.idiscard;
};
var ibind = function (dict) {
    return dict.ibind;
};
var ibindFlipped = function (dictIxBind) {
    return Data_Function.flip(ibind(dictIxBind));
};
var ijoin = function (dictIxBind) {
    return function (m) {
        return ibind(dictIxBind)(m)(Control_Category.identity(Control_Category.categoryFn));
    };
};
var ixDiscardUnit = {
    idiscard: function (dictIxBind) {
        return ibind(dictIxBind);
    }
};
var composeiKleisliFlipped = function (dictIxBind) {
    return function (f) {
        return function (g) {
            return function (a) {
                return ibindFlipped(dictIxBind)(f)(g(a));
            };
        };
    };
};
var composeiKleisli = function (dictIxBind) {
    return function (f) {
        return function (g) {
            return function (a) {
                return ibind(dictIxBind)(f(a))(g);
            };
        };
    };
};
module.exports = {
    ibind: ibind,
    ibindFlipped: ibindFlipped,
    composeiKleisli: composeiKleisli,
    composeiKleisliFlipped: composeiKleisliFlipped,
    idiscard: idiscard,
    ixDiscardUnit: ixDiscardUnit,
    iapply: Control_Apply_Indexed.iapply,
    iapplyFirst: Control_Apply_Indexed.iapplyFirst,
    iapplySecond: Control_Apply_Indexed.iapplySecond,
    imap: Control_Apply_Indexed.imap,
    ivoid: Control_Apply_Indexed.ivoid,
    ivoidLeft: Control_Apply_Indexed.ivoidLeft,
    ivoidRight: Control_Apply_Indexed.ivoidRight
};
