import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import axiosClient from "../../../axiosConfig";
const baseUrl = "/super-admin/users";

export interface UserData {
  id: string;
  email: string;
  currentOrganization: string;
  organizations: string[];
  createdAt: Date;
  expiresAt: Date | null;
  isSuperAdmin: boolean;
  hasSSOLogin: boolean;
}

export function useGetUsersInSuperAdmin(): UserData[] | undefined {
  const { data } = useQuery({
    queryKey: [baseUrl],
    queryFn: async () => {
      const { data } = await axiosClient.get<UserData[]>(baseUrl);
      return data;
    },
  });
  return data;
}
export function useUpdateUserInSuperAdmin() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (userData: UserData) => {
      const { data } = await axiosClient.put<UserData>(
        `${baseUrl}/${userData.id}`,
        userData
      );
      return data;
    },
    onSettled: () => {
      return invalidateUsers(queryClient);
    },
  });
}

export function useCreateUserInSuperAdmin() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (userData: UserData) => {
      const { data } = await axiosClient.post<UserData>(baseUrl, userData);
      return data;
    },

    onSettled: () => {
      return invalidateUsers(queryClient);
    },
  });
}

export function useDeleteUserInSuperAdmin() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: string) => {
      const { data } = await axiosClient.delete(`${baseUrl}/${id}`);
      return data;
    },

    onSettled: () => {
      return invalidateUsers(queryClient);
    },
  });
}

function invalidateUsers(queryClient: QueryClient) {
  return queryClient.invalidateQueries({
    queryKey: [baseUrl],
  });
}
