// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Controls = require("../Controls/index.js");
var CostControl_Styles = require("../CostControl.Styles/index.js");
var CostGroupDetails_ComponentsTab = require("../CostGroupDetails.ComponentsTab/index.js");
var CostGroupDetails_CostGroupItems = require("../CostGroupDetails.CostGroupItems/index.js");
var CostGroupDetails_EstimateItemsTab = require("../CostGroupDetails.EstimateItemsTab/index.js");
var CostGroupDetails_Types = require("../CostGroupDetails.Types/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_String_Regex = require("../Data.String.Regex/index.js");
var Data_String_Regex_Flags = require("../Data.String.Regex.Flags/index.js");
var Data_String_Regex_Unsafe = require("../Data.String.Regex.Unsafe/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Data_Validation_Semigroup = require("../Data.Validation.Semigroup/index.js");
var Effect = require("../Effect/index.js");
var Effect_Timer = require("../Effect.Timer/index.js");
var Formatters = require("../Formatters/index.js");
var InvoicesTab = require("../InvoicesTab/index.js");
var Locale = require("../Locale/index.js");
var MemoCalculator = require("../MemoCalculator/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var StateHooks = require("../StateHooks/index.js");
var StringUtils = require("../StringUtils/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoSelect = require("../TacoSelect/index.js");
var TacoTabs = require("../TacoTabs/index.js");
var TacoTabs_Types = require("../TacoTabs.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_CostGroup = require("../Types.CostGroup/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var UseFetch = require("../UseFetch/index.js");
var UseNullableRef = require("../UseNullableRef/index.js");
var Utils = require("../Utils/index.js");
var WindowUtils = require("../WindowUtils/index.js");
var urlRegex = Data_String_Regex_Unsafe.unsafeRegex("\\S*(:\\/\\/)\\S*")(Data_String_Regex_Flags.global);
var mkInitMemoState = {
    modifiedValue: Data_Maybe.Nothing.value,
    evalTimeout: Data_Maybe.Nothing.value,
    autoSaveTimeout: Data_Maybe.Nothing.value
};
var component = TofuHooks.mkHookComponent("CostGroupDetails")(function (props) {
    return function __do() {
        var v = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var v1 = TofuHooks.useState(Data_Set.empty)();
        var v2 = TofuHooks.useState(Data_Set.empty)();
        var v3 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var v4 = UseFetch.useFetchItemsByProjectId(props.projectId)(function (v4) {
            return v4.costGroupInfos;
        })(Actions.CostGroupInfosRequest.create)();
        var v5 = UseFetch.useFetchItemsByProjectId(props.projectId)(function (v5) {
            return v5.invoices;
        })(Actions.GetInvoicesRequest.create)();
        var v6 = UseFetch.useFetchItemsByProjectId(props.projectId)(function (v6) {
            return v6.reportingComponents;
        })(Actions.GetReportingComponentsRequest.create)();
        var v7 = UseFetch.useFetchItemsByProjectId(props.projectId)(function (v7) {
            return v7.costClasses;
        })(Actions.ProjectCostClassesRequest.create)();
        var v8 = TofuHooks.useState(mkInitMemoState)();
        var memoValue = Data_Maybe.fromMaybe(props.costGroupUnitAndCostClasses.costGroupUnit.memo)(v8.value0.modifiedValue);
        var updateStatuses = StateHooks.useSelector(function (v9) {
            return v9.updateStatuses;
        })();
        var appDispatch = StateHooks.useDispatch();
        var textareaRef = UseNullableRef.useNullableRef();
        TofuHooks.useEffect([  ])(Control_Applicative.pure(Effect.applicativeEffect)(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(Effect_Timer.clearTimeout)(v8.value0.evalTimeout)))();
        TofuHooks.useEffect([ v.value0 ])(function __do() {
            Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(v.value0)(function (pos) {
                return function __do() {
                    ReactBasicUtils.setSelectionsFromRef(textareaRef)(pos)();
                    return v.value1(function (v9) {
                        return Data_Maybe.Nothing.value;
                    })();
                };
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        })();
        var targetItems = Utils.matchCostGroupCode(props.costGroupUnitAndCostClasses.costGroupUnit)(props.rawTargetItems);
        var targetItemsTab = React_Basic_DOM_Generated.div_([ CostGroupDetails_CostGroupItems.targetItemsListingComponent({
            targetItems: targetItems,
            costClassResults: props.costClassResults,
            costGroupUnit: props.costGroupUnitAndCostClasses.costGroupUnit,
            costClasses: v7.value0,
            socialExpenses: props.socialExpenses
        }) ]);
        var estimateItems = Utils.matchCostGroupCode(props.costGroupUnitAndCostClasses.costGroupUnit)(props.rawEstimateItems);
        var estimateItemsTabProperties = CostGroupDetails_EstimateItemsTab.prepareEstimateItemsTabProperties({
            appDispatch: appDispatch,
            costClasses: v7.value0,
            costGroupUnit: props.costGroupUnitAndCostClasses.costGroupUnit,
            estimateItems: estimateItems,
            costClassResults: props.costClassResults,
            socialExpenses: props.socialExpenses,
            loadingAddCostGroupItems: updateStatuses.loadingAddCostGroupItems
        });
        var categoryView = (function () {
            var categoryText = function (maybeCategory) {
                if (maybeCategory instanceof Data_Maybe.Just) {
                    return Types_CostGroup.costGroupCategoryCodeToRomanNumeral(maybeCategory.value0.code) + (" " + maybeCategory.value0.name);
                };
                if (maybeCategory instanceof Data_Maybe.Nothing) {
                    return Locale.lookup_("unknown");
                };
                throw new Error("Failed pattern match at CostGroupDetails (line 328, column 13 - line 330, column 50): " + [ maybeCategory.constructor.name ]);
            };
            var options = (function () {
                var getOption = function (opt) {
                    return {
                        value: Types_CostGroup.costGroupCategoryCodeToRomanNumeral(opt.code),
                        text: categoryText(new Data_Maybe.Just(opt))
                    };
                };
                return Data_Functor.map(Data_Functor.functorArray)(getOption)(Data_Array.sortBy(function (a) {
                    return function (b) {
                        return Data_Ord.compare(Types_CostGroup.ordCostGroupCategoryCode)(a.code)(b.code);
                    };
                })(props.costGroupCategories));
            })();
            var currentCategory = Data_Array.find(function (cgc) {
                return Data_Eq.eq(Types_CostGroup.eqCostGroupCategoryCode)(cgc.code)(props.costGroupUnitAndCostClasses.costGroupUnit.category);
            })(props.costGroupCategories);
            return React_Basic.fragment([ Box.box("categoryContainer")([ Box.AlignCenter.value, new Box.PaddingLeft(TacoConstants.M.value) ])(Control_Applicative.pure(Control_Applicative.applicativeArray)(TacoText.component()()({
                text: Locale.lookup_("category") + ": ",
                weight: TacoText_Types.Bold.value
            }))), Box.box("selectContainer")([ new Box.PaddingX(TacoConstants.M.value) ])(Control_Applicative.pure(Control_Applicative.applicativeArray)(TacoSelect.component()()({
                items: options,
                value: Types_CostGroup.costGroupCategoryCodeToRomanNumeral(Data_Maybe.fromMaybe(Types_CostGroup.DefaultCGCategory.value)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v9) {
                    return v9.code;
                })(currentCategory))),
                onChange: TacoInput.changeHandler(TacoInput.inputValueString)(function (value) {
                    return appDispatch(new Actions.UpdateCostGroupCategoryRequest({
                        projectId: props.costGroupUnitAndCostClasses.costGroupUnit.projectId,
                        costGroupId: props.costGroupUnitAndCostClasses.costGroupUnit.id,
                        costGroupCategory: Data_Maybe.fromMaybe(props.costGroupUnitAndCostClasses.costGroupUnit.category)(Types_CostGroup.fromRomanNumeralToCostGroupCategoryCode(value))
                    }));
                })
            }))) ]);
        })();
        var byCostGroup$prime = function (v9) {
            return Data_Eq.eq(Data_Maybe.eqMaybe(Types_CostGroup.eqCostGroupCode))(v9.costGroupCode)(new Data_Maybe.Just(props.costGroupUnitAndCostClasses.costGroupUnit.code));
        };
        var componentResources = Data_Array.filter(byCostGroup$prime)(props.resources);
        var componentsTabProperties = CostGroupDetails_ComponentsTab.prepareComponentTabProperties({
            appDispatch: appDispatch,
            costGroupInfos: v4.value0,
            costGroupUnit: props.costGroupUnitAndCostClasses.costGroupUnit,
            reportingComponents: v6.value0,
            resources: componentResources,
            selectedResources: v1.value0,
            setSelectedResources: v1.value1,
            toggledComponents: v2.value0,
            setToggledComponents: v2.value1,
            costClasses: v7.value0
        });
        var byCostGroup = function (item) {
            var v9 = Data_Nullable.toMaybe(item.costGroupCode);
            if (v9 instanceof Data_Maybe.Nothing) {
                return false;
            };
            if (v9 instanceof Data_Maybe.Just) {
                return Data_Eq.eq(Types_CostGroup.eqCostGroupCode)(v9.value0)(props.costGroupUnitAndCostClasses.costGroupUnit.code);
            };
            throw new Error("Failed pattern match at CostGroupDetails (line 131, column 24 - line 133, column 36): " + [ v9.constructor.name ]);
        };
        var invoiceLines = Data_Array.filter(byCostGroup)(v5.value0);
        var costGroupActions = (function () {
            var modalBody = function (r) {
                return TacoModal.modalBody({
                    contents: [ React_Basic_DOM.text("Haluatko varmasti poistaa litteran?: " + Data_Newtype.un()(Types_CostGroup.CostGroupCode)(r.code)) ]
                });
            };
            var handleUpdateCompletionPercentage = function ($copy_defaultValue) {
                return function __do() {
                    var $tco_var_defaultValue = $copy_defaultValue;
                    var $tco_done = false;
                    var $tco_result;
                    function $tco_loop(defaultValue) {
                        var input = WindowUtils.prompt(Locale.lookup_("prompt_input_costGroup_completion_percentage") + (" \"" + (props.costGroupUnitAndCostClasses.costGroupUnit.name + "\": ")))(Formatters.formatDecimalValue(2)(defaultValue))();
                        if (input instanceof Data_Maybe.Nothing) {
                            $tco_done = true;
                            return Data_Unit.unit;
                        };
                        if (input instanceof Data_Maybe.Just) {
                            var v9 = StringUtils.readStrToNumber(input.value0);
                            if (v9 instanceof Data_Maybe.Nothing) {
                                $tco_var_defaultValue = -1.0;
                                return;
                            };
                            if (v9 instanceof Data_Maybe.Just && (v9.value0 > 100.0 || v9.value0 < 0.0)) {
                                $tco_var_defaultValue = v9.value0;
                                return;
                            };
                            if (v9 instanceof Data_Maybe.Just) {
                                $tco_done = true;
                                return appDispatch(new Actions.UpdateCostGroupCompletionPercentageRequest({
                                    projectId: props.costGroupUnitAndCostClasses.costGroupUnit.projectId,
                                    costGroupId: props.costGroupUnitAndCostClasses.costGroupUnit.id,
                                    value: v9.value0
                                }))();
                            };
                            throw new Error("Failed pattern match at CostGroupDetails (line 169, column 23 - line 176, column 18): " + [ v9.constructor.name ]);
                        };
                        throw new Error("Failed pattern match at CostGroupDetails (line 167, column 11 - line 176, column 18): " + [ input.constructor.name ]);
                    };
                    while (!$tco_done) {
                        $tco_result = $tco_loop($tco_var_defaultValue);
                    };
                    return $tco_result;
                };
            };
            var updateCostGroupCompletionPercentageInput = TacoButton.component()()({
                onClick: Data_Maybe.Just.create(handleUpdateCompletionPercentage(Data_Newtype.un()(Types_Unit.PercentageOfHundred)(props.costGroupUnitAndCostClasses.costGroupUnit.completionPercentage))),
                icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconEditV1.value),
                text: "Ty\xf6maan valmiusaste: " + Formatters.formatPercentageOfHundredAsStringWithPercent(props.costGroupUnitAndCostClasses.costGroupUnit.completionPercentage)
            });
            var handleRenameCostGroup = function (defaultValue) {
                var optionalWarning = Utils.getNameValidationWarning(defaultValue);
                return function __do() {
                    var m = WindowUtils.prompt("Anna uusi nimi litteralle " + (props.costGroupUnitAndCostClasses.costGroupUnit.name + (":" + optionalWarning)))(props.costGroupUnitAndCostClasses.costGroupUnit.name)();
                    if (m instanceof Data_Maybe.Nothing) {
                        return Data_Unit.unit;
                    };
                    if (m instanceof Data_Maybe.Just && Data_Validation_Semigroup.isValid(Utils.nameValidation(m.value0)) === false) {
                        return handleRenameCostGroup(m.value0)();
                    };
                    if (m instanceof Data_Maybe.Just) {
                        return appDispatch(new Actions.EditCostGroupNamesRequest({
                            projectId: props.costGroupUnitAndCostClasses.costGroupUnit.projectId,
                            params: [ {
                                costGroupCode: props.costGroupUnitAndCostClasses.costGroupUnit.code,
                                name: m.value0
                            } ]
                        }))();
                    };
                    throw new Error("Failed pattern match at CostGroupDetails (line 155, column 11 - line 161, column 16): " + [ m.constructor.name ]);
                };
            };
            var renameCostGroupButton = TacoButton.component()()({
                text: Locale.lookup_("action_rename_cost_group"),
                onClick: Data_Maybe.Just.create(handleRenameCostGroup(""))
            });
            var deleteEmptyCostGroupBtn = (function () {
                var costGroupIsEmpty = Data_Array["null"](componentResources) && (Data_Array["null"](estimateItems) && Data_Array["null"](invoiceLines));
                return TacoButton.component()()({
                    color: TacoConstants.SecondaryRed.value,
                    disabled: !costGroupIsEmpty,
                    title: "Vain tyhj\xe4n litteran voi poistaa",
                    text: "Poista littera",
                    onClick: Data_Maybe.Just.create(v3.value1(function (v9) {
                        return new Data_Maybe.Just(props.costGroupUnitAndCostClasses.costGroupUnit);
                    }))
                });
            })();
            var closeDeleteEmptyCostGroupModal = v3.value1(function (v9) {
                return Data_Maybe.Nothing.value;
            });
            var modalActions = function (r) {
                return TacoModal.modalActions({
                    contents: [ TacoButton.component()()({
                        onClick: new Data_Maybe.Just(closeDeleteEmptyCostGroupModal),
                        text: "Peruuta"
                    }), TacoButton.component()()({
                        text: "Poista",
                        color: TacoConstants.SecondaryRed.value,
                        onClick: new Data_Maybe.Just(function __do() {
                            appDispatch(new Actions.DeleteCostGroupsRequest({
                                projectId: r.projectId,
                                value: [ r.code ]
                            }))();
                            return closeDeleteEmptyCostGroupModal();
                        })
                    }) ]
                });
            };
            var deleteEmptyCostGroupModal = (function () {
                if (v3.value0 instanceof Data_Maybe.Nothing) {
                    return React_Basic.empty;
                };
                if (v3.value0 instanceof Data_Maybe.Just) {
                    return TacoModal.component()()({
                        isActive: true,
                        onClose: closeDeleteEmptyCostGroupModal,
                        heading: "Poista tyhj\xe4 littera",
                        contents: [ modalBody(v3.value0.value0), modalActions(v3.value0.value0) ]
                    });
                };
                throw new Error("Failed pattern match at CostGroupDetails (line 213, column 37 - line 223, column 14): " + [ v3.value0.constructor.name ]);
            })();
            return Controls.component()()({
                leftControls: [ categoryView ],
                rightControls: [ renameCostGroupButton, deleteEmptyCostGroupModal, deleteEmptyCostGroupBtn, updateCostGroupCompletionPercentageInput ],
                bottomGutter: true,
                topGutter: true
            });
        })();
        var tableTabsView = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "TableTabsView",
            css: CostControl_Styles.tableTabsViewStyles,
            children: [ TacoTabs.component({
                orientation: TacoTabs_Types.Vertical.value,
                hideContents: false,
                tabs: [ new TacoTabs_Types.NormalTab({
                    id: "invoices",
                    quantity: Data_Maybe.Just.create(Data_Array.length(invoiceLines)),
                    title: Locale.lookup_("cost_group_detail_tab_invoices"),
                    children: [ InvoicesTab.invoicesTab({
                        projectId: props.costGroupUnitAndCostClasses.costGroupUnit.projectId,
                        invoiceLinesWithSocialExpenses: Utils.addSocialExpenses(v7.value0)(invoiceLines),
                        descriptionDisplay: props.descriptionDisplay
                    }) ],
                    color: TacoConstants.PrimaryBlue.value
                }), new TacoTabs_Types.NormalTab({
                    id: "components",
                    quantity: new Data_Maybe.Just(componentsTabProperties.tabItemsCount),
                    title: Locale.lookup_("cost_group_detail_tab_components"),
                    children: [ componentsTabProperties.componentsTab ],
                    color: TacoConstants.PrimaryBlue.value
                }), new TacoTabs_Types.NormalTab({
                    id: "estimate-items",
                    quantity: new Data_Maybe.Just(estimateItemsTabProperties.tabItemsCount),
                    title: Locale.lookup_("cost_group_detail_tab_estimateItems"),
                    children: [ estimateItemsTabProperties.estimateItemsTab ],
                    color: TacoConstants.PrimaryBlue.value
                }), new TacoTabs_Types.NormalTab({
                    id: "target-items",
                    quantity: Data_Maybe.Just.create(Data_Array.length(targetItems)),
                    title: "Tavoite",
                    children: [ targetItemsTab ],
                    color: TacoConstants.PrimaryBlue.value
                }) ]
            }) ]
        });
        var autoSaveMemoWithTimeout = function (value) {
            return function __do() {
                Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(Effect_Timer.clearTimeout)(v8.value0.autoSaveTimeout)();
                var memoAutoSaveTimeout$prime = Effect_Timer.setTimeout(1000)(appDispatch(new Actions.UpdateCostGroupMemoRequest({
                    projectId: props.costGroupUnitAndCostClasses.costGroupUnit.projectId,
                    costGroupId: props.costGroupUnitAndCostClasses.costGroupUnit.id,
                    value: value
                })))();
                return v8.value1(function (v9) {
                    return {
                        modifiedValue: v9.modifiedValue,
                        evalTimeout: v9.evalTimeout,
                        autoSaveTimeout: new Data_Maybe.Just(memoAutoSaveTimeout$prime)
                    };
                })();
            };
        };
        var updateMemoWithEvalAndSaveTimeout = function (v9) {
            if (v9 instanceof Data_Maybe.Nothing) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            };
            if (v9 instanceof Data_Maybe.Just) {
                return function __do() {
                    Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(Effect_Timer.clearTimeout)(v8.value0.evalTimeout)();
                    Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(Effect_Timer.clearTimeout)(v8.value0.autoSaveTimeout)();
                    var memoEvalTimeout$prime = Effect_Timer.setTimeout(1000)(function __do() {
                        var prevCursorPos$prime = Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)(ReactBasicUtils.getSelectionsFromRef(textareaRef))();
                        var v10 = Data_Either.hush(MemoCalculator.parseMemo(v9.value0));
                        if (v10 instanceof Data_Maybe.Just) {
                            var evaluated = MemoCalculator.evaluateMemo(v10.value0);
                            var printed = MemoCalculator.printMemo(evaluated);
                            v8.value1(function (v11) {
                                return {
                                    modifiedValue: new Data_Maybe.Just(printed),
                                    evalTimeout: v11.evalTimeout,
                                    autoSaveTimeout: v11.autoSaveTimeout
                                };
                            })();
                            v.value1(function (v11) {
                                return prevCursorPos$prime;
                            })();
                            return autoSaveMemoWithTimeout(printed)();
                        };
                        if (v10 instanceof Data_Maybe.Nothing) {
                            return autoSaveMemoWithTimeout(v9.value0)();
                        };
                        throw new Error("Failed pattern match at CostGroupDetails (line 255, column 9 - line 264, column 51): " + [ v10.constructor.name ]);
                    })();
                    return v8.value1(function (v10) {
                        return {
                            modifiedValue: new Data_Maybe.Just(v9.value0),
                            evalTimeout: new Data_Maybe.Just(memoEvalTimeout$prime),
                            autoSaveTimeout: v10.autoSaveTimeout
                        };
                    })();
                };
            };
            throw new Error("Failed pattern match at CostGroupDetails (line 247, column 5 - line 247, column 57): " + [ v9.constructor.name ]);
        };
        var memoView = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "memo",
            css: CostControl_Styles.memoContainerStyles,
            children: [ React_Basic_DOM_Generated.div()({
                className: "memo-text",
                children: [ React_Basic_DOM_Generated.textarea()({
                    value: memoValue,
                    className: Data_Newtype.unwrap()(CostGroupDetails_Types.textareaClassName),
                    placeholder: "Muistio",
                    rows: 5,
                    onChange: React_Basic_Events.handler(React_Basic_DOM_Events.targetValue)(updateMemoWithEvalAndSaveTimeout),
                    ref: textareaRef
                }) ]
            }), React_Basic_DOM_Generated.div()({
                className: "memo-links",
                children: (function () {
                    var v9 = Data_String_Regex.match(urlRegex)(memoValue);
                    if (v9 instanceof Data_Maybe.Just) {
                        return Data_Functor.map(Data_Functor.functorArray)(function (url) {
                            return React_Basic_DOM_Generated.a()({
                                className: "memo-link",
                                href: url,
                                children: Data_Array.singleton(React_Basic_DOM.text(url)),
                                target: "_blank"
                            });
                        })(Data_Array_NonEmpty.catMaybes(v9.value0));
                    };
                    if (v9 instanceof Data_Maybe.Nothing) {
                        return [ React_Basic_DOM.text("") ];
                    };
                    throw new Error("Failed pattern match at CostGroupDetails (line 292, column 17 - line 301, column 42): " + [ v9.constructor.name ]);
                })()
            }) ]
        });
        return React_Basic_DOM_Generated.div()({
            className: "cost-group-details",
            children: [ costGroupActions, memoView, tableTabsView ]
        });
    };
});
module.exports = {
    component: component
};
