// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Components_Utils = require("../Components.Utils/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var StateHooks = require("../StateHooks/index.js");
var SubNavigation_Styles = require("../SubNavigation.Styles/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoTabs_Styles = require("../TacoTabs.Styles/index.js");
var TacoTabs_Types = require("../TacoTabs.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var UseUnfocusOnClick = require("../UseUnfocusOnClick/index.js");
var Utils = require("../Utils/index.js");
var Separator = (function () {
    function Separator() {

    };
    Separator.value = new Separator();
    return Separator;
})();
var Spacing = (function () {
    function Spacing() {

    };
    Spacing.value = new Spacing();
    return Spacing;
})();
var Disabled = (function () {
    function Disabled(value0) {
        this.value0 = value0;
    };
    Disabled.create = function (value0) {
        return new Disabled(value0);
    };
    return Disabled;
})();
var Link = (function () {
    function Link(value0) {
        this.value0 = value0;
    };
    Link.create = function (value0) {
        return new Link(value0);
    };
    return Link;
})();
var Button = (function () {
    function Button(value0) {
        this.value0 = value0;
    };
    Button.create = function (value0) {
        return new Button(value0);
    };
    return Button;
})();
var navigationItem = TofuHooks.mkHookComponent("NavigationItem")(function (props) {
    var highlightColor = (function () {
        if (props.item instanceof Link) {
            return props.item.value0.color;
        };
        return TacoConstants.Transparent.value;
    })();
    return function __do() {
        var dispatch = StateHooks.useDispatch();
        var v = UseUnfocusOnClick.useUnfocusOnClick();
        var titleJSX = function (title) {
            return ReactBasicUtils["div'"]("title")([ TacoText.component()()({
                text: title,
                inheritColor: true,
                weight: TacoText_Types.Bold.value
            }) ]);
        };
        var onClick = function (route) {
            return React_Basic_Events.handler(Utils.condPreventDefault)(function (v1) {
                return Control_Applicative.when(Effect.applicativeEffect)(!v1.openTabOrWindow)(function __do() {
                    v.unfocus();
                    return dispatch(new Actions.NavigateRoute(route))();
                });
            });
        };
        if (props.item instanceof Separator) {
            return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                css: SubNavigation_Styles.separatorStyles,
                className: "separator"
            });
        };
        if (props.item instanceof Spacing) {
            return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                css: SubNavigation_Styles.spacingStyles,
                className: "spacing"
            });
        };
        if (props.item instanceof Disabled) {
            return React_Basic_Emotion.element(React_Basic_DOM_Generated["a'"]())({
                children: [ TacoText.component()()({
                    text: props.item.value0.title,
                    weight: TacoText_Types.Bold.value,
                    inheritColor: true
                }) ],
                css: SubNavigation_Styles.navigationItemStyles({
                    highlightColor: highlightColor,
                    active: false,
                    disabled: true
                }),
                className: "navigation-item-disabled",
                "_data": Components_Utils.mkTestIdAttrs(props.item.value0.id)
            });
        };
        if (props.item instanceof Link) {
            return React_Basic_Emotion.element(React_Basic_DOM_Generated["a'"]())({
                className: "navigation-item",
                css: TacoTabs_Styles.tabStyles({
                    active: props.item.value0.active,
                    color: props.item.value0.color,
                    orientation: TacoTabs_Types.Horizontal.value
                }),
                children: [ titleJSX(props.item.value0.title) ],
                onClick: onClick(props.item.value0.route),
                href: props.item.value0.href,
                ref: v.ref,
                "_data": Components_Utils.mkTestIdAttrs(props.item.value0.id)
            });
        };
        if (props.item instanceof Button) {
            return TacoButton.component()()({
                className: "navigation-button",
                text: props.item.value0.title,
                onClick: Data_Maybe.Just.create(props.item.value0.onClickFn),
                buttonStyle: TacoButton_Types.Link.value,
                color: TacoConstants.TextSecondary.value,
                styles: [ new Box.MarginBottom(TacoConstants.S.value), new Box.MarginTop(TacoConstants.S.value), Box.PaddingX.create(new TacoConstants.CustomSize("6px")), new Box.FontWeight("bold") ]
            });
        };
        throw new Error("Failed pattern match at Components.SubNavigation (line 90, column 8 - line 125, column 10): " + [ props.item.constructor.name ]);
    };
});
var component = TofuHooks.mkMemoHookComponent("SubNavigation")(function (props) {
    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        className: "__SubNavigation",
        css: SubNavigation_Styles.subNavigationStyles,
        children: Data_Functor.map(Data_Functor.functorArray)(function (item) {
            return navigationItem({
                item: item
            });
        })(props.items)
    }));
});
module.exports = {
    component: component,
    Separator: Separator,
    Spacing: Spacing,
    Disabled: Disabled,
    Link: Link,
    Button: Button
};
