import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import { useMemo, useRef } from "react";
import { Button, IconLtV1, ToggleVisibilityButton } from "@tocoman/ui";
import {
  AllReportParametersData,
  ComponentPropsMap,
} from "../../SuperAdmin/ReportDesigner/AllReportParameters";
import { StringCombobox } from "../../../components/StringCombobox";
import { FullProjectData } from "./Report";
import { setReportParameters } from "./SetReportParameters";

interface ReportParametersFormProps {
  projectData: FullProjectData;
  reportParameters: (keyof ComponentPropsMap)[];
  setParametersData: (parameters: ParamsForARJS[]) => void;
  setParametersSet: (parametersSet: boolean) => void;
}

export type ParamsForARJS = {
  Name: string;
  Value:
    | string
    | string[]
    | boolean
    | number
    | { key: string; value: string; label: string }[]
    | null
    | undefined;
};

export const ReportParametersForm = ({
  projectData,
  reportParameters,
  setParametersData,
  setParametersSet,
}: ReportParametersFormProps) => {
  const { t } = useTranslation("reports");
  const targetRef = useRef<HTMLDivElement>(null);

  const { handleSubmit, watch } = useFormContext<AllReportParametersData>();

  const reportName = watch("report");
  const reportNames = ["C200"];

  const handlePreviewSubmit = (data: AllReportParametersData) => {
    const dataArray = Object.keys(data).map((key) => {
      if (key === "rowType") {
        return {
          Name: key,
          Value: data.rowType?.map((item) => item.value),
        };
      }
      if (key === "measurements") {
        return {
          Name: key,
          Value: data.measurements?.map((item) => item.value),
        };
      }
      return {
        Name: key,
        Value: data[key as keyof AllReportParametersData],
      };
    }) as ParamsForARJS[];
    setParametersData(dataArray);
    setParametersSet(true);
  };

  const parameters = reportName
    ? setReportParameters(reportParameters, projectData)
    : [];

  const parameterComponents = useMemo(() => {
    if (!parameters) {
      return null;
    }
    return parameters?.map(({ component, key }) => (
      <div key={key}>{component}</div>
    ));
  }, [parameters]);

  return (
    <div className="flex flex-row items-start gap-5 min-h-full max-h-full ml-5 overflow-y-auto">
      <div ref={targetRef}>
        <div className="flex flex-col gap-5 w-[250px] m-3 h-full">
          <>
            <Controller
              name="report"
              defaultValue={reportNames[0]}
              render={({ field: { onChange, value } }) => (
                <StringCombobox
                  label={t("report")}
                  initialValue={value}
                  items={reportNames}
                  onValueChange={onChange}
                />
              )}
            />
            {parameterComponents}
            {reportName && (
              <Button
                className="w-48 mt-3 mb-5"
                onClick={handleSubmit(handlePreviewSubmit)}
                testId="submit-pdf-form"
              >
                {t("preview")}
              </Button>
            )}
          </>
        </div>
      </div>
      <div className="border-l border-l-light min-h-full ml-2">
        <ToggleVisibilityButton
          icon={IconLtV1}
          targetRef={targetRef}
          className="ml-[-12px] mt-3"
        />
      </div>
    </div>
  );
};
