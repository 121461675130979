import {
  Button,
  IconAddPlusV1,
  IconCloseCrossV1,
  Input,
  RadioGroup,
  Text,
} from "@tocoman/ui";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import axiosClient from "src/client-ts/axiosConfig";
import { AllOrganizationData } from "./OrganizationInformation";

const ultimaInstanceBaseUrl = "super-admin/organizations/ultima-instance-types";
function useUltimaInstances() {
  return useQuery(ultimaInstanceBaseUrl, async () => {
    const response = await axiosClient.get<{ instanceType: string }[]>(
      ultimaInstanceBaseUrl
    );
    return response.data;
  });
}

export function UltimaSettings() {
  const { t } = useTranslation("superAdmin");

  const { register, control } = useFormContext<AllOrganizationData>();

  const { data } = useUltimaInstances();
  const radioInstances =
    data?.map((item) => ({
      value: item.instanceType,
      label: item.instanceType,
    })) ?? [];
  const { fields, append, remove } = useFieldArray({
    name: "adminetTenants",
  });

  return (
    <div>
      {fields.map((field, index) => (
        <div key={field.id} className={"flex flex-row mt-1"}>
          <Input
            label={t(`form.adminetTenantId`)}
            className="w-full mr-4"
            testId="adminetTenantIdInput"
            {...register(`adminetTenants.${index}.tenantId`)}
          />
          <Input
            label={t(`form.adminetName`)}
            className="w-full"
            testId="adminetTenantNameInput"
            {...register(`adminetTenants.${index}.tenantName`)}
          />
          <Button
            className={"ml-1 mt-6"}
            testId="remove-tenant-button"
            variant="secondary"
            color="normal"
            onClick={() => remove(index)}
            icon={IconCloseCrossV1}
          ></Button>
        </div>
      ))}
      <div className={"flex flex-row mt-1"}>
        <Button
          className={"mt-2"}
          title="add-tenant-button"
          variant="primary"
          color="normal"
          size="small"
          onClick={() => append({ tenantId: "", tenantName: null })}
          icon={IconAddPlusV1}
        ></Button>
        <Text className={"ml-2 mt-3"} text={t`form.addAdminetTenant`} />
      </div>

      {data ? (
        <>
          <Text text={t`form.ultimaInstanceDesc`} className={"mt-3"} />
          <Controller
            name={"ultimaInstanceType"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioGroup
                onValueChange={(value) => {
                  onChange(
                    radioInstances.find((item) => item.value === value.value)
                      ?.value ?? radioInstances[0].value
                  );
                }}
                items={radioInstances}
                selected={
                  radioInstances.find((item) => item.value === value) ??
                  radioInstances[0]
                }
              />
            )}
          />
        </>
      ) : null}
    </div>
  );
}
