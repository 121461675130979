import {
  IRowNode,
  ProcessRowGroupForExportParams,
  ValueGetterParams,
} from "ag-grid-community";
import { EstimationResource } from "../../../../../../ts-bindings/EstimationResource";
import { CostClass } from "../../../../../../ts-bindings/CostClass";
import { ComponentWithResources } from "../../../../../../ts-bindings/ComponentWithResources";
import { ResourceProcessRowGroupForExportParams } from "./ResourceDetails";

export function roundWithOneDecimal(num: number): string {
  const rounded = Math.round((num + Number.EPSILON) * 100) / 100;
  return rounded.toFixed(1);
}

export function roundWithTwoDecimals(num: number): string {
  const rounded = Math.round((num + Number.EPSILON) * 100) / 100;
  return rounded.toFixed(2);
}

export const isEstimationResource = (
  params: ValueGetterParams<EstimationResource> | EstimationResource
): params is EstimationResource => {
  return (params as EstimationResource).costClassCode !== undefined;
};

export const getCostClass = (
  params: EstimationResource | ValueGetterParams<EstimationResource>,
  detailsParams: ProcessRowGroupForExportParams
) => {
  const costClassCode = isEstimationResource(params)
    ? params.costClassCode
    : params.data?.costClassCode;
  return detailsParams.context.costClasses?.find(
    (item: CostClass) => item.costClassCode === costClassCode
  );
};

export const getComponent = (
  params: EstimationResource | ValueGetterParams<EstimationResource>,
  detailsParams: ProcessRowGroupForExportParams
) => {
  const estimationComponentId = isEstimationResource(params)
    ? params.estimationComponentId
    : params.data?.estimationComponentId;
  return detailsParams.context.components?.find(
    (item: ComponentWithResources) =>
      item.component.id === estimationComponentId
  );
};

export const getPriceWithDiscountData = (
  params: EstimationResource | ValueGetterParams<EstimationResource>
) => {
  return isEstimationResource(params)
    ? params.priceWithDiscount
    : params.data?.priceWithDiscount ?? 0;
};

export const getWastePercentageData = (
  params: EstimationResource | ValueGetterParams<EstimationResource>
) => {
  return isEstimationResource(params)
    ? params.wastePercentage
    : params.data?.wastePercentage ?? 0;
};

export const getUnitConsumptionRateData = (
  params: EstimationResource | ValueGetterParams<EstimationResource>
) => {
  return isEstimationResource(params)
    ? params.unitConsumptionRate
    : params.data?.unitConsumptionRate ?? 0;
};

export const getResourceCostClass = (
  params: ValueGetterParams<EstimationResource> | EstimationResource,
  detailsParams: ProcessRowGroupForExportParams
) => {
  const costClass = getCostClass(params, detailsParams);
  return `${costClass.costClassCode} - ${costClass.name}`;
};

export const resourcesTotalPerUnitHeader = (params: IRowNode) => {
  return `€ / ${params.data.component.unit}`;
};

export function getPriceWithDiscount(
  params: ValueGetterParams<EstimationResource>
) {
  if (!params.data) {
    return null;
  }
  const total = params.data.priceWithDiscount;
  return roundWithTwoDecimals(total);
}

export function getPriceWithDiscountWithSocialExpenses(
  params: ValueGetterParams<EstimationResource> | EstimationResource,
  detailsParams:
    | ResourceProcessRowGroupForExportParams
    | ProcessRowGroupForExportParams
) {
  if (!isEstimationResource(params) && !params.data) {
    return null;
  }
  const costClass = getCostClass(params, detailsParams);
  const costClassSocialExpenses = costClass?.socialExpensePercentageInCostEstimation
    ? costClass.socialExpensePercentageInCostEstimation
    : 0;
  const priceWithDiscount = getPriceWithDiscountData(params);
  const total = priceWithDiscount * (1 + costClassSocialExpenses);
  return roundWithTwoDecimals(total);
}

export function getResourceAmount(
  params: ValueGetterParams<EstimationResource> | EstimationResource,
  detailsParams:
    | ResourceProcessRowGroupForExportParams
    | ProcessRowGroupForExportParams
) {
  if (!isEstimationResource(params) && !params.data) {
    return null;
  }
  const component = getComponent(params, detailsParams);
  const componentAmount = component ? component.component.amount : 0;
  const unitConsumptionRate = getUnitConsumptionRateData(params);
  const wastePercentage = getWastePercentageData(params);
  const amount =
    unitConsumptionRate * componentAmount * (1 + wastePercentage * 0.01);
  return roundWithOneDecimal(amount);
}

export function getResourceTotalPerUnit(
  params: ValueGetterParams<EstimationResource> | EstimationResource
) {
  if (!isEstimationResource(params) && !params?.data) {
    return null;
  }
  const priceWithDiscount = getPriceWithDiscountData(params);
  const wastePercentage = getWastePercentageData(params);
  const unitConsumptionRate = getUnitConsumptionRateData(params);
  const total =
    priceWithDiscount * (1 + wastePercentage * 0.01) * unitConsumptionRate;
  return roundWithTwoDecimals(total);
}

export function getResourceTotalPerUnitWithSocialExpenses(
  params: ValueGetterParams<EstimationResource> | EstimationResource,
  detailsParams:
    | ProcessRowGroupForExportParams
    | ResourceProcessRowGroupForExportParams
) {
  if (!isEstimationResource(params) && !params.data) {
    return null;
  }
  const costClass = getCostClass(params, detailsParams);
  const costClassSocialExpenses = costClass?.socialExpensePercentageInCostEstimation
    ? costClass.socialExpensePercentageInCostEstimation
    : 0;
  const priceWithDiscount = getPriceWithDiscountData(params);
  const wastePercentage = getWastePercentageData(params);
  const unitConsumptionRate = getUnitConsumptionRateData(params);
  const total =
    priceWithDiscount *
    (1 + wastePercentage * 0.01) *
    unitConsumptionRate *
    (1 + costClassSocialExpenses);
  return roundWithTwoDecimals(total);
}

export function getResourceTotal(
  params: ValueGetterParams<EstimationResource> | EstimationResource,
  detailsParams:
    | ProcessRowGroupForExportParams
    | ResourceProcessRowGroupForExportParams
) {
  if (!isEstimationResource(params) && !params.data) {
    return null;
  }
  const component = getComponent(params, detailsParams);
  const componentAmount = component ? component.component.amount : 0;
  const priceWithDiscount = getPriceWithDiscountData(params);
  const wastePercentage = getWastePercentageData(params);
  const unitConsumptionRate = getUnitConsumptionRateData(params);
  const totalPerUnit =
    priceWithDiscount * (1 + wastePercentage * 0.01) * unitConsumptionRate;
  const total = componentAmount * totalPerUnit;
  return roundWithTwoDecimals(total);
}

export function getResourceTotalWithSocialExpenses(
  params: ValueGetterParams<EstimationResource> | EstimationResource,
  detailsParams:
    | ProcessRowGroupForExportParams
    | ResourceProcessRowGroupForExportParams
) {
  if (!isEstimationResource(params) && !params.data) {
    return null;
  }
  const component = getComponent(params, detailsParams);
  const componentAmount = component ? component.component.amount : 0;
  const costClass = getCostClass(params, detailsParams);
  const costClassSocialExpenses = costClass?.socialExpensePercentageInCostEstimation
    ? costClass.socialExpensePercentageInCostEstimation
    : 0;
  const priceWithDiscount = getPriceWithDiscountData(params);
  const wastePercentage = getWastePercentageData(params);
  const unitConsumptionRate = getUnitConsumptionRateData(params);
  const totalPerUnit =
    priceWithDiscount *
    (1 + wastePercentage * 0.01) *
    unitConsumptionRate *
    (1 + costClassSocialExpenses);
  const total = componentAmount * totalPerUnit;
  return roundWithTwoDecimals(total);
}
