import { AdminAction } from "ts-bindings/AdminAction";
import { CreateUserParams } from "ts-bindings/CreateUserParams";
import { DeleteUserParams } from "ts-bindings/DeleteUserParams";
import { SetSsoParams } from "ts-bindings/SetSsoParams";
import { SetUserRolesParams } from "ts-bindings/SetUserRolesParams";
import { WorkerRequest } from "ts-bindings/WorkerRequest";
import { mkWorkerAction } from "./state";

// Note: { type: "ComponentUpdate", value: ... } comes from staying compatible
// with PureScript's generics. We could make this more ergonomic to work with
// e.g. { "ComponentUpdate": ... }
export const mkAdminUpdateAction = (action: AdminAction): WorkerRequest =>
  mkWorkerAction({
    type: "AdminUpdate",
    value: {
      action,
    },
  });

export const mkSetUserRolesAction = (
  params: SetUserRolesParams
): WorkerRequest =>
  mkAdminUpdateAction({
    type: "SetUserRoles",
    value: params,
  });

export const mkCreateUserAction = (params: CreateUserParams): WorkerRequest =>
  mkAdminUpdateAction({
    type: "CreateUser",
    value: params,
  });

export const mkDeleteUserAction = (params: DeleteUserParams): WorkerRequest =>
  mkAdminUpdateAction({
    type: "DeleteUser",
    value: params,
  });

export const mkSetSsoAction = (params: SetSsoParams): WorkerRequest =>
  mkAdminUpdateAction({
    type: "SetSso",
    value: params,
  });
