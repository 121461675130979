// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var UseNullableRef = require("../UseNullableRef/index.js");
var Web_HTML_HTMLElement = require("../Web.HTML.HTMLElement/index.js");
var useUnfocusOnClick = function __do() {
    var ref = UseNullableRef.useNullableRef();
    var unfocus = function __do() {
        var maybeElement = Data_Functor.map(Effect.functorEffect)(function (v) {
            return Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Web_HTML_HTMLElement.fromNode)(v);
        })(React_Basic_Hooks.readRefMaybe(ref))();
        return Data_Maybe.maybe(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit))(Web_HTML_HTMLElement.blur)(maybeElement)();
    };
    return {
        ref: ref,
        unfocus: unfocus
    };
};
module.exports = {
    useUnfocusOnClick: useUnfocusOnClick
};
