const {
  formatCurrencyValue,
} = require("../../src/client-ts/utils/currency.ts");
const {
  formatCurrencyValueWithoutDecimals,
} = require("../../src/client-ts/utils/currency.ts");

exports._formatCurrencyValue = (currency) => (value) =>
  formatCurrencyValue(currency, value);
exports._formatCurrencyValueWithoutDecimals = (currency) => (value) =>
  formatCurrencyValueWithoutDecimals(currency, value);

const decimal0Formatter = new Intl.NumberFormat("fi-FI", {
  style: "decimal",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const decimal1Formatter = new Intl.NumberFormat("fi-FI", {
  style: "decimal",
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
});

const decimal2Formatter = new Intl.NumberFormat("fi-FI", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const decimal3Formatter = new Intl.NumberFormat("fi-FI", {
  style: "decimal",
  minimumFractionDigits: 3,
  maximumFractionDigits: 3,
});

const dateFormatter = new Intl.DateTimeFormat("fi-FI", {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
});

const dateTimeFormatter = new Intl.DateTimeFormat("fi-FI", {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
});

exports._formatDecimal0Value = (value) => decimal0Formatter.format(value);

exports._formatDecimal1Value = (value) => decimal1Formatter.format(value);

exports._formatDecimal2Value = (value) => decimal2Formatter.format(value);

exports._formatDecimal3Value = (value) => decimal3Formatter.format(value);

exports._formatDecimalNValue = (precision, value) => {
  switch (precision) {
    case 0:
      return decimal0Formatter.format(value);
    case 1:
      return decimal1Formatter.format(value);
    case 2:
      return decimal2Formatter.format(value);
    case 3:
      return decimal3Formatter.format(value);
    default:
      console.error("Error: could not format unknown precision", precision);
      return decimal3Formatter.format(value);
  }
};

exports._formatDateValue = (date) => {
  if (isFinite(date)) {
    return dateFormatter.format(date);
  } else {
    return "";
  }
};

exports._formatDateStringValue = (str) => {
  if (str) {
    return dateFormatter.format(new Date(str));
  } else {
    return "";
  }
};

exports._formatDateTimeValue = (date) => {
  if (isFinite(date)) {
    return dateTimeFormatter.format(date);
  } else {
    return "";
  }
};

exports._formatDateTimeStringValue = (str) => {
  if (str) {
    return dateTimeFormatter.format(new Date(str));
  } else {
    return "";
  }
};

const longDateTimeFormatter = new Intl.DateTimeFormat("fi-FI", {
  dateStyle: "full",
  timeStyle: "long",
});

exports.formatDateTime = (x) => longDateTimeFormatter.format(x);
