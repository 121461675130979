// Generated by purs version 0.14.5
"use strict";
var Box_Demo = require("../Box.Demo/index.js");
var ButtonWithConfirmation_Demo = require("../ButtonWithConfirmation.Demo/index.js");
var Control_Alt = require("../Control.Alt/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Controls_Demo = require("../Controls.Demo/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Effect = require("../Effect/index.js");
var Gap = require("../Gap/index.js");
var Gap_Demo = require("../Gap.Demo/index.js");
var Grid_Demo = require("../Grid.Demo/index.js");
var InteropExample_Demo = require("../InteropExample.Demo/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var ReactQuery_Demo = require("../ReactQuery.Demo/index.js");
var ScrollContainer_Demo = require("../ScrollContainer.Demo/index.js");
var SeparatorHeading_Demo = require("../SeparatorHeading.Demo/index.js");
var SeparatorSubHeading_Demo = require("../SeparatorSubHeading.Demo/index.js");
var Sidebar = require("../Sidebar/index.js");
var SubNavigation_Demo = require("../SubNavigation.Demo/index.js");
var TSComponentDemo = require("../TSComponentDemo/index.js");
var Table_Demo = require("../Table.Demo/index.js");
var TacoAutocomplete_Demo = require("../TacoAutocomplete.Demo/index.js");
var TacoButton_Demo = require("../TacoButton.Demo/index.js");
var TacoCheckbox_Demo = require("../TacoCheckbox.Demo/index.js");
var TacoChip_Demo = require("../TacoChip.Demo/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoDropdown_Demo = require("../TacoDropdown.Demo/index.js");
var TacoFilter_Demo = require("../TacoFilter.Demo/index.js");
var TacoGroupToggle_Demo = require("../TacoGroupToggle.Demo/index.js");
var TacoIcon_Demo = require("../TacoIcon.Demo/index.js");
var TacoInput_Demo = require("../TacoInput.Demo/index.js");
var TacoList_Demo = require("../TacoList.Demo/index.js");
var TacoLoader_Demo = require("../TacoLoader.Demo/index.js");
var TacoModal_Demo = require("../TacoModal.Demo/index.js");
var TacoSelect_Demo = require("../TacoSelect.Demo/index.js");
var TacoTable_Demo = require("../TacoTable.Demo/index.js");
var TacoTabs_Demo = require("../TacoTabs.Demo/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Demo = require("../TacoText.Demo/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TacoTooltip_Demo = require("../TacoTooltip.Demo/index.js");
var Test_Demo = require("../Test.Demo/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var UIDemo_Styles = require("../UIDemo.Styles/index.js");
var viewContainer = TofuHooks.mkHookComponent("ViewContainer")(function (props) {
    var sectionView = function (view) {
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "view",
            css: UIDemo_Styles.uiDemoViewStyles,
            children: view
        });
    };
    var sectionCode = function (code) {
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["pre'"]())({
            className: "code",
            css: UIDemo_Styles.uiDemoCodeStyles,
            children: [ React_Basic_DOM.text(code) ]
        });
    };
    var sectionContainer = function (section) {
        return React_Basic_DOM_Generated.div()({
            className: "section-container",
            children: [ TacoText.component()()({
                text: section.title,
                variant: new TacoText_Types.Heading(3),
                weight: TacoText_Types.Bold.value,
                gutterBottom: true
            }), React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                className: "section",
                css: UIDemo_Styles.uiDemoSectionStyles,
                children: [ sectionView(section.view), sectionCode(section.code) ]
            }) ]
        });
    };
    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        className: "view-container",
        css: UIDemo_Styles.uiDemoViewContainerStyles,
        children: (function () {
            if (props.activeView instanceof Data_Maybe.Just) {
                return [ TacoText.component()()({
                    text: props.activeView.value0.title,
                    variant: new TacoText_Types.Heading(1),
                    weight: TacoText_Types.Bold.value,
                    gutterBottom: true,
                    size: TacoConstants.L.value
                }), TacoText.component()()({
                    text: props.activeView.value0.description,
                    variant: new TacoText_Types.Heading(2),
                    weight: TacoText_Types.Bold.value,
                    gutterBottom: true,
                    color: TacoConstants.GrayMedium.value
                }), React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                    className: "sections",
                    css: UIDemo_Styles.uiDemoSectionsStyles,
                    children: [ Gap.gapY(TacoConstants.XL.value)(Data_Functor.map(Data_Functor.functorArray)(sectionContainer)(props.activeView.value0.sections)) ]
                }) ];
            };
            if (props.activeView instanceof Data_Maybe.Nothing) {
                return Data_Monoid.mempty(Data_Monoid.monoidArray);
            };
            throw new Error("Failed pattern match at UIDemoContainer (line 149, column 17 - line 159, column 26): " + [ props.activeView.constructor.name ]);
        })()
    }));
});
var demoViews = [ Box_Demo.demoView, Controls_Demo.demoView, Gap_Demo.demoView, Grid_Demo.demoView, InteropExample_Demo.demoView, ScrollContainer_Demo.demoView, SeparatorHeading_Demo.demoView, SeparatorSubHeading_Demo.demoView, SubNavigation_Demo.demoView, TacoAutocomplete_Demo.demoView, TacoButton_Demo.demoView, TacoCheckbox_Demo.demoView, TacoChip_Demo.demoView, TacoDropdown_Demo.demoView, TacoFilter_Demo.demoView, TacoGroupToggle_Demo.demoView, TacoList_Demo.demoView, TacoIcon_Demo.demoView, TacoInput_Demo.demoView, TacoLoader_Demo.demoView, TacoModal_Demo.demoView, TacoSelect_Demo.demoView, TacoTable_Demo.demoView, TacoTabs_Demo.demoView, TacoText_Demo.demoView, TacoTooltip_Demo.demoView, Test_Demo.demoView, ButtonWithConfirmation_Demo.demoView, TSComponentDemo.demoView, Table_Demo.demoView, ReactQuery_Demo.demoView ];
var component = TofuHooks.mkHookComponent("UIDemo")(function (props) {
    var splitComponentId = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)((function () {
        var $6 = Data_String_Common.split("#");
        return function ($7) {
            return Data_Array.head($6($7));
        };
    })())(props.componentId);
    var activeView = Control_Alt.alt(Data_Maybe.altMaybe)(Data_Array.find(function (view) {
        return Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(new Data_Maybe.Just(view.title))(splitComponentId);
    })(demoViews))(Data_Array.head(demoViews));
    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        className: "__UIDemo",
        css: UIDemo_Styles.uiDemoLayoutStyles,
        children: [ Sidebar.component({
            activeView: activeView,
            demoViews: demoViews
        }), viewContainer({
            activeView: activeView
        }) ]
    }));
});
module.exports = {
    component: component
};
