import { PropsWithChildren } from "react";
import { useLicenseFeatureEnabled, LicenseFeature } from "./useLicenseFeature";

export type LicenseFeatureContainerProps = {
  feature: LicenseFeature;
};

export function LicenseFeatureContainer({
  feature,
  children,
}: PropsWithChildren<LicenseFeatureContainerProps>) {
  const enabled = useLicenseFeatureEnabled(feature);
  if (enabled) {
    return <>{children}</>;
  }

  return <></>;
}
