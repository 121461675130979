// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Constants = require("../Constants/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Controls = require("../Controls/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Effect = require("../Effect/index.js");
var HtmlUtils = require("../HtmlUtils/index.js");
var Locale = require("../Locale/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoDropdown = require("../TacoDropdown/index.js");
var TacoDropdown_Types = require("../TacoDropdown.Types/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoInput = require("../TacoInput/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var templateDownloadLink = TacoButton.component()()({
    text: "Lataa litterakarttapohja",
    icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconFileDownloadV1.value),
    align: TacoButton_Types.Left.value,
    href: new Data_Maybe.Just(Constants.costGroupsUploadTemplate)
});
var exportDownloadLink = function (onClick) {
    return TacoButton.component()()({
        text: Locale.lookup_("report_download_report_excel"),
        icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconFileDownloadV1.value),
        align: TacoButton_Types.Left.value,
        onClick: new Data_Maybe.Just(onClick)
    });
};
var addBtn = TofuHooks.mkHookComponent("AddBtn")(function (props) {
    return Control_Applicative.pure(Effect.applicativeEffect)(TacoButton.component()()({
        onClick: new Data_Maybe.Just(props.handleShowAddCostGroup),
        text: Locale.lookup_("project_new_cost_group"),
        icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconAddPlusV1.value),
        align: TacoButton_Types.Left.value
    }));
});
var costControlTableControls = TofuHooks.mkHookComponent("CostControlTableControls")(function (props) {
    return function __do() {
        var v = TofuHooks.useState(false)();
        var toggleNestItemsBy3CodeDigits = TacoCheckbox.component()()({
            className: "nest-items-toggle",
            isChecked: props.nestCostGroupItemsBy3CodeDigits,
            onToggle: new TacoCheckbox.NoEvent(props.toggleNestCostGroupItemsBy3CodeDigits),
            labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("K\xe4yt\xe4 kaksitasoista ryhmittely\xe4")),
            testId: "nest-items-toggle"
        });
        var sections = [ {
            title: new Data_Maybe.Just("Litterat"),
            items: [ addBtn({
                handleShowAddCostGroup: props.handleShowAddCostGroup
            }), templateDownloadLink, props.fileUpload, exportDownloadLink(props.exportCostGroupsAction) ]
        }, {
            title: new Data_Maybe.Just("N\xe4yt\xe4"),
            items: [ toggleNestItemsBy3CodeDigits, TacoCheckbox.component()()({
                onToggle: new TacoCheckbox.Disabled("Poissa k\xe4yt\xf6st\xe4"),
                isChecked: props.withSocialExpenses,
                className: "social-expenses-toggle",
                labelText: Data_Maybe.Just.create(TacoCheckbox.StringLabel.create(Locale.lookup_("toggle_social_expenses_cost_group"))),
                testId: "social-expenses-toggle"
            }), TacoCheckbox.component()()({
                onToggle: new TacoCheckbox.NoEvent(props.toggleTargetDisplay),
                isChecked: props.displayTarget,
                className: "display-target-toggle",
                labelText: Data_Maybe.Just.create(TacoCheckbox.StringLabel.create(Locale.lookup_("toggle_target_cost_group"))),
                testId: "display-target-toggle"
            }), TacoCheckbox.component()()({
                onToggle: new TacoCheckbox.NoEvent(props.toggleEstimateDisplay),
                isChecked: props.displayEstimate,
                className: "display-estimate-toggle",
                labelText: Data_Maybe.Just.create(TacoCheckbox.StringLabel.create(Locale.lookup_("toggle_estimate_cost_group"))),
                testId: "display-estimate-toggle"
            }), TacoCheckbox.component()()({
                onToggle: new TacoCheckbox.NoEvent(props.toggleTargetToEstimateDisplay),
                isChecked: props.displayTargetToEstimate,
                className: "display-targetToEstimate-toggle",
                labelText: Data_Maybe.Just.create(TacoCheckbox.StringLabel.create(Locale.lookup_("toggle_targetToEstimate_cost_group"))),
                testId: "display-targetToEstimate-toggle"
            }), TacoCheckbox.component()()({
                onToggle: new TacoCheckbox.NoEvent(props.toggleActualDisplay),
                isChecked: props.displayActual,
                className: "display-actual-toggle",
                labelText: Data_Maybe.Just.create(TacoCheckbox.StringLabel.create(Locale.lookup_("toggle_actual_cost_group"))),
                testId: "display-actual-toggle"
            }), TacoCheckbox.component()()({
                onToggle: new TacoCheckbox.NoEvent(props.toggleActualToEstimateRatioDisplay),
                isChecked: props.displayActualToEstimateRatio,
                className: "display-actualToEstimateRatio-toggle",
                labelText: Data_Maybe.Just.create(TacoCheckbox.StringLabel.create(Locale.lookup_("toggle_actualToEstimate_cost_group"))),
                testId: "display-actualToEstimateRatio-toggle"
            }), TacoCheckbox.component()()({
                onToggle: new TacoCheckbox.NoEvent(props.toggleMemoDisplay),
                isChecked: props.displayMemo,
                className: "display-memo-toggle",
                labelText: Data_Maybe.Just.create(TacoCheckbox.StringLabel.create(Locale.lookup_("toggle_memo_cost_group"))),
                testId: "display-memo-toggle"
            }), TacoCheckbox.component()()({
                onToggle: new TacoCheckbox.NoEvent(props.toggleCompletionPercentage),
                isChecked: props.displayCompletionPercentage,
                className: "display-completion-percentage-toggle",
                labelText: Data_Maybe.Just.create(TacoCheckbox.StringLabel.create(Locale.lookup_("toggle_completion_percentage_cost_group"))),
                testId: "display-completion-percentage-toggle"
            }), TacoCheckbox.component()()({
                onToggle: new TacoCheckbox.NoEvent(props.toggleHideEmptyCostGroups),
                isChecked: props.hideEmptyCostGroups,
                className: "hide-empty-cgs-toggle",
                labelText: Data_Maybe.Just.create(TacoCheckbox.StringLabel.create(Locale.lookup_("toggle_hide_empty_cost_groups"))),
                testId: "hide-empty-cgs-toggle"
            }), TacoCheckbox.component()()({
                onToggle: new TacoCheckbox.NoEvent(props.toggleEstimateRemaining),
                isChecked: props.displayEstimateRemaining,
                className: "display-estimate-remaining-toggle",
                labelText: Data_Maybe.Just.create(TacoCheckbox.StringLabel.create(Locale.lookup_("toggle_estimate_remaining_column"))),
                testId: "display-estimate-remaining-toggle"
            }), TacoCheckbox.component()()({
                onToggle: new TacoCheckbox.NoEvent(props.toggleDescriptionDisplay),
                isChecked: props.displayLineDescriptions,
                className: "display-invoice-line-descriptions-toggle",
                labelText: Data_Maybe.Just.create(TacoCheckbox.StringLabel.create(Locale.lookup_("toggle_invoice_line_descriptions"))),
                testId: "display-invoice-line-descriptions-toggle"
            }), TacoCheckbox.component()()({
                onToggle: new TacoCheckbox.NoEvent(props.toggleToggleDisplayFullWidth),
                isChecked: props.displayFullWidth,
                className: "display-fullWidth-toggle",
                labelText: Data_Maybe.Just.create(TacoCheckbox.StringLabel.create(Locale.lookup_("toggle_fullWidth_cost_group"))),
                testId: "display-fullWidth-toggle"
            }) ]
        } ];
        var v1 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        return Controls.component()()({
            bottomGutter: true,
            leftControls: [ Box.box("filterInputWrapper")([ Box.Width.create(new TacoConstants.CustomSize("12rem")) ])([ TacoInput.stringInput()()(Data_Eq.eqString)({
                value: props.filterString,
                clearButton: true,
                onChange: props.setFilterString,
                placeholder: "Hae"
            }) ]), TacoButton.component()()({
                onClick: Data_Maybe.Just.create(props.setFilterString(function (v2) {
                    return Data_String_Common.joinWith(",")(props.selectedCostGroupCodes);
                })),
                text: "N\xe4yt\xe4 valitut" + (" (" + (Data_Show.show(Data_Show.showInt)(Data_Array.length(props.selectedCostGroupCodes)) + ")"))
            }), TacoButton.component()()({
                onClick: Data_Maybe.Just.create(props.resetFilterAndSelection),
                text: "Poista valinnat"
            }) ],
            rightControls: [ TacoButton.component()()({
                onClickEventHandler: Data_Maybe.Just.create(React_Basic_Events.handler(React_Basic_DOM_Events.currentTarget)(function (target) {
                    return function __do() {
                        v.value1(function (s) {
                            return !s;
                        })();
                        return v1.value1(function (v2) {
                            return Data_Maybe.Just.create(HtmlUtils.coerceEventTargetToHTMLElement(target));
                        })();
                    };
                })),
                text: Locale.lookup_("actions"),
                icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconMenuV2.value)
            }), TacoDropdown.component({
                sections: sections,
                toggled: v.value0,
                setToggled: v.value1,
                align: TacoDropdown_Types.AlignRight.value,
                anchorEl: v1.value0
            }) ]
        });
    };
});
module.exports = {
    costControlTableControls: costControlTableControls
};
