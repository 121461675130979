// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoTooltip = require("../TacoTooltip/index.js");
var TacoTooltip_Styles = require("../TacoTooltip.Styles/index.js");
var example1 = "import TacoTooltip as TacoTooltip\x0a\x0a[ box \"tacoTooltipExampleWrapper\"\x0a    [ MaxWidth (CustomSize \"600px\")\x0a    , JustifySpaceBetween\x0a    ]\x0a    [ TacoTooltip.component\x0a        { anchor: [ R.div_ [ R.text \"Hover me!\" ] ]\x0a        , justify: TacoTooltip.BottomLeft\x0a        , tip: [ R.text \"Bottom-left justified\" ]\x0a        }\x0a    , TacoTooltip.component\x0a        { anchor: [ R.div_ [ R.text \"Hover me!\" ] ]\x0a        , justify: TacoTooltip.Bottom\x0a        , tip: [ R.text \"Bottom justified\" ]\x0a        }\x0a    , TacoTooltip.component\x0a        { anchor: [ R.div_ [ R.text \"Hover me!\" ] ]\x0a        , justify: TacoTooltip.BottomRight\x0a        , tip: [ R.text \"Bottom-right justified\" ]\x0a        }\x0a    , TacoTooltip.component\x0a        { anchor: [ R.div_ [ R.text \"Hover me!\" ] ]\x0a        , justify: TacoTooltip.TopLeft\x0a        , tip: [ R.text \"Top-left justified\" ]\x0a        }\x0a    , TacoTooltip.component\x0a        { anchor: [ R.div_ [ R.text \"Hover me!\" ] ]\x0a        , justify: TacoTooltip.Top\x0a        , tip: [ R.text \"Top justified\" ]\x0a        }\x0a    , TacoTooltip.component\x0a        { anchor: [ R.div_ [ R.text \"Hover me!\" ] ]\x0a        , justify: TacoTooltip.TopRight\x0a        , tip: [ R.text \"Top-right justified\" ]\x0a        }\x0a    ]\x0a]\x0a";
var sections = [ {
    title: "Default",
    view: [ Box.box("tacoTooltipExampleWrapper")([ new Box.MaxWidth(new TacoConstants.CustomSize("600px")), Box.JustifySpaceBetween.value ])([ TacoTooltip.component()()({
        anchor: [ React_Basic_DOM_Generated.div_([ React_Basic_DOM.text("Hover me!") ]) ],
        justify: TacoTooltip_Styles.BottomLeft.value,
        tip: [ React_Basic_DOM.text("Bottom-left justified") ]
    }), TacoTooltip.component()()({
        anchor: [ React_Basic_DOM_Generated.div_([ React_Basic_DOM.text("Hover me!") ]) ],
        justify: TacoTooltip_Styles.Bottom.value,
        tip: [ React_Basic_DOM.text("Bottom justified") ]
    }), TacoTooltip.component()()({
        anchor: [ React_Basic_DOM_Generated.div_([ React_Basic_DOM.text("Hover me!") ]) ],
        justify: TacoTooltip_Styles.BottomRight.value,
        tip: [ React_Basic_DOM.text("Bottom-right justified") ]
    }), TacoTooltip.component()()({
        anchor: [ React_Basic_DOM_Generated.div_([ React_Basic_DOM.text("Hover me!") ]) ],
        justify: TacoTooltip_Styles.TopLeft.value,
        tip: [ React_Basic_DOM.text("Top-left justified") ]
    }), TacoTooltip.component()()({
        anchor: [ React_Basic_DOM_Generated.div_([ React_Basic_DOM.text("Hover me!") ]) ],
        justify: TacoTooltip_Styles.Top.value,
        tip: [ React_Basic_DOM.text("Top justified") ]
    }), TacoTooltip.component()()({
        anchor: [ React_Basic_DOM_Generated.div_([ React_Basic_DOM.text("Hover me!") ]) ],
        justify: TacoTooltip_Styles.TopRight.value,
        tip: [ React_Basic_DOM.text("Top-right justified") ]
    }) ]) ],
    code: example1
} ];
var demoView = {
    sections: sections,
    title: "TacoTooltip",
    description: "Tooltip shown when user hovers on anchor"
};
module.exports = {
    demoView: demoView
};
