import { Button } from "@tocoman/ui";
import { useCallback } from "react";
import { IStatusPanelParams } from "ag-grid-community";
import { useDefault } from "../../hooks/useDefault";
import { useWorkerState } from "../../hooks/useWorkerState";
import { useCurrentUser } from "../../auth/useCurrentUser";
import { useTranslation } from "react-i18next";

export function ExportToExcelStatusPanelButton(
  props: IStatusPanelParams,
  type: string
) {
  const { api } = props;
  const currentUser = useCurrentUser();
  const currentEmail = currentUser.email;
  const { t } = useTranslation(type, { keyPrefix: "printing" });

  const currentOrg = useDefault(useWorkerState("GetCurrentOrganizationState"), {
    organization: "",
  });

  const excelExport = useCallback(() => {
    api.exportDataAsExcel({
      headerFooterConfig: {
        all: {
          header: [
            {
              value: "\n&[Date]\n&[Page]/&[Pages]",
              position: "Right",
            },
          ],
          footer: [
            {
              value: currentOrg.organization,
              position: "Left",
            },
            {
              value: currentEmail,
              position: "Right",
            },
          ],
        },
      },
    });
  }, [api, currentEmail, currentOrg.organization]);

  return (
    <div className={"flex items-center h-16"}>
      <Button
        className={"my-1"}
        variant="primary"
        label={t`export.exportToExcel`}
        onClick={excelExport}
      />
    </div>
  );
}
