import { useCallback, useMemo, useState } from "react";
import { Select, SelectItem } from "@tocoman/ui";

type SelectFieldProps = {
  items: string[];
  label: string;
  onValueChange: (value: string) => void;
  initialValue: string;
};
export function SelectField({
  items,
  label,
  onValueChange,
  initialValue,
}: SelectFieldProps) {
  const mappedItems = useMemo(
    () => items.map((item) => ({ key: item, value: item, label: item })),
    [items]
  );

  const initialSelected = useMemo(() => {
    const foundSelected = mappedItems.find(
      (item) => item.value === initialValue
    );
    if (!foundSelected) {
      return mappedItems[0];
    }
    return foundSelected;
  }, [initialValue, mappedItems]);

  const [selectedItem, setSelectedItem] = useState<SelectItem<string>>(
    initialSelected
  );

  const onChange = useCallback(
    (value: SelectItem<string>) => {
      setSelectedItem(value);
      onValueChange(value.value);
    },
    [onValueChange]
  );

  return (
    <Select
      label={label}
      items={mappedItems}
      onValueChange={onChange}
      selected={initialSelected ?? selectedItem}
    />
  );
}
