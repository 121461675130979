// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoModal = require("../TacoModal/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var component = TofuHooks.mkHookComponent("AddOrganizations")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState("")();
        var v2 = TofuHooks.useState("")();
        var v3 = TofuHooks.useState("")();
        var v4 = TofuHooks.useState("")();
        var v5 = TofuHooks.useState(false)();
        var resetForm = function __do() {
            v1.value1(function (v6) {
                return "";
            })();
            v2.value1(function (v6) {
                return "";
            })();
            v3.value1(function (v6) {
                return "";
            })();
            return v4.value1(function (v6) {
                return "";
            })();
        };
        var modalBody = TacoModal.modalBody({
            contents: Control_Applicative.pure(Control_Applicative.applicativeArray)(Box.box("fields")([ Box.GridColumn.value, new Box.Gap(TacoConstants.M.value), Box.AlignBaseline.value ])([ TacoInput.stringInput()()(Data_Eq.eqString)({
                label: "Organisaation nimi",
                value: v1.value0,
                onChange: TacoInput.changeHandler(TacoInput.inputValueString)(function (value) {
                    return v1.value1(function (v6) {
                        return Data_String_Common.toLower(value);
                    });
                }),
                testId: "add-organization-name",
                validator: function (s) {
                    var v6 = $foreign.testNameRegex(s);
                    var v7 = Data_String_CodePoints.length(s);
                    if (v7 === 0) {
                        return TacoInput.Valid.value;
                    };
                    if (!v6) {
                        return new TacoInput.Invalid("Alphanumeric and .-_");
                    };
                    if (v6) {
                        return TacoInput.Valid.value;
                    };
                    throw new Error("Failed pattern match at AddOrganizations (line 55, column 19 - line 58, column 47): " + [ v7.constructor.name, v6.constructor.name ]);
                }
            }), TacoInput.stringInput()()(Data_Eq.eqString)({
                label: "MSSQL DB username",
                value: v2.value0,
                onChange: v2.value1,
                testId: "add-organization-db-user"
            }), TacoInput.stringInput()()(Data_Eq.eqString)({
                label: "MSSQL DB password",
                value: v3.value0,
                onChange: v3.value1,
                type: "password",
                testId: "add-organization-db-password"
            }), TacoInput.stringInput()()(Data_Eq.eqString)({
                label: "MSSQL DB name",
                value: v4.value0,
                onChange: v4.value1,
                testId: "add-organization-db-name"
            }) ]))
        });
        var createNewOrganization = function __do() {
            v.appDispatch(new Actions.AddOrganizationRequest({
                organization: v1.value0,
                dbInfo: {
                    user: v2.value0,
                    password: v3.value0,
                    database: v4.value0
                }
            }))();
            resetForm();
            return v5.value1(function (v6) {
                return false;
            })();
        };
        var modalActions = TacoModal.modalActions({
            contents: [ TacoButton.component()()({
                onClick: Data_Maybe.Just.create(v5.value1(function (v6) {
                    return false;
                })),
                text: "Peruuta",
                testId: "add-organization-cancel"
            }), TacoButton.component()()({
                onClick: Data_Maybe.Just.create(createNewOrganization),
                buttonStyle: TacoButton_Types.Filled.value,
                submit: true,
                text: "Luo organisaatio",
                testId: "add-organization-submit"
            }) ]
        });
        return React_Basic_DOM_Generated.div_([ TacoButton.component()()({
            onClick: Data_Maybe.Just.create(v5.value1(function (v6) {
                return true;
            })),
            buttonStyle: TacoButton_Types.Outlined.value,
            text: "+ Luo uusi organisaatio",
            testId: "add-organization-button"
        }), TacoModal.component()()({
            onClose: v5.value1(function (v6) {
                return false;
            }),
            isActive: v5.value0,
            heading: "Luo uusi organisaatio",
            contents: [ React_Basic_DOM_Generated.form()({
                className: "add-organizations-form",
                onSubmit: React_Basic_DOM_Events.capture_(createNewOrganization),
                children: [ modalBody, modalActions ]
            }) ]
        }) ]);
    };
});
module.exports = {
    component: component
};
