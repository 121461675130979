// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var AddInvoice = require("../AddInvoice/index.js");
var Calculation_Income = require("../Calculation.Income/index.js");
var Client_WASM = require("../Client.WASM/index.js");
var Constants = require("../Constants/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Controls = require("../Controls/index.js");
var DOMUtils = require("../DOMUtils/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_JSDate = require("../Data.JSDate/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var FeatureHooks = require("../FeatureHooks/index.js");
var FileUpload = require("../FileUpload/index.js");
var Formatters = require("../Formatters/index.js");
var InvoiceFilterControls = require("../InvoiceFilterControls/index.js");
var InvoiceMonthlyFilterChart = require("../InvoiceMonthlyFilterChart/index.js");
var InvoiceUtils = require("../InvoiceUtils/index.js");
var Invoices_Styles = require("../Invoices.Styles/index.js");
var InvoicesTable = require("../InvoicesTable/index.js");
var Locale = require("../Locale/index.js");
var NarrowLayout = require("../NarrowLayout/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoAutocomplete = require("../TacoAutocomplete/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoDropdown = require("../TacoDropdown/index.js");
var TacoDropdown_Types = require("../TacoDropdown.Types/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoLoader = require("../TacoLoader/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Alert = require("../Types.Alert/index.js");
var Types_CostGroup = require("../Types.CostGroup/index.js");
var Types_Invoice = require("../Types.Invoice/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_UploadStatus = require("../Types.UploadStatus/index.js");
var UseFetch = require("../UseFetch/index.js");
var Utils = require("../Utils/index.js");
var WindowUtils = require("../WindowUtils/index.js");
var All = (function () {
    function All() {

    };
    All.value = new All();
    return All;
})();
var Limited = (function () {
    function Limited(value0) {
        this.value0 = value0;
    };
    Limited.create = function (value0) {
        return new Limited(value0);
    };
    return Limited;
})();
var visibleRowsIncrement = 100;
var uploadSalaries = TofuHooks.mkHookComponent("UploadSalaries")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState(Types_UploadStatus.Standby.value)();
        var v2 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var appDispatch = StateHooks.useDispatch();
        var uploadSalariesInput = FileUpload.mkFileUpload({
            projectId: new Data_Maybe.Just(v.projectId),
            uploadType: FileUpload.SalariesUpload.value,
            endpointUrl: "/uploadSalaries",
            uniqueId: "salaries-upload-input",
            uploadStatus: v1.value0,
            setUploadStatus: v1.value1,
            filename: v2.value0,
            setFilename: v2.value1,
            onSuccess: Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(appDispatch(new Actions.GetInvoicesRequest({
                projectId: v.projectId
            })))
        });
        var uploadSalariesLabel = FileUpload.uploadFileLabel({
            filename: v2.value0,
            className: "upload-invoices",
            uniqueId: "salaries-upload-input",
            label: "Tuo uusia palkkoja"
        });
        return React_Basic_DOM_Generated.div()({
            className: "flex-centered",
            children: [ uploadSalariesInput, uploadSalariesLabel, FileUpload.uploadStatusToJSX(v1.value0) ]
        });
    };
});
var templateDownloadLink = TacoButton.component()()({
    href: new Data_Maybe.Just(Constants.salariesUploadTemplate),
    text: "Lataa palkkataulukkopohja",
    icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconFileDownloadV1.value),
    align: TacoButton_Types.Left.value
});
var deleteInvoicesBtn = TofuHooks.mkHookComponent("DeleteInvoicesBtn")(function (v) {
    return function __do() {
        var appDispatch = StateHooks.useDispatch();
        var modal = (function () {
            if (!v.confirmDeleteInvoices) {
                return React_Basic.empty;
            };
            if (v.confirmDeleteInvoices) {
                var msg = Locale.lookup_((function () {
                    var $101 = Data_Array.length(v.ids) === 1;
                    if ($101) {
                        return "invoice_confirm_delete";
                    };
                    return "invoices_confirm_delete";
                })());
                var modalBody = TacoModal.modalBody({
                    contents: [ React_Basic_DOM.text(msg) ]
                });
                var closeModal = v.setConfirmDeleteInvoices(function (v1) {
                    return false;
                });
                var modalActions = TacoModal.modalActions({
                    contents: [ TacoButton.component()()({
                        onClick: Data_Maybe.Just.create(closeModal),
                        text: "Peruuta"
                    }), TacoButton.component()()({
                        onClick: new Data_Maybe.Just(function __do() {
                            appDispatch(new Actions.DeleteInvoicesRequest({
                                projectId: v.projectId,
                                params: v.ids
                            }))();
                            v.clearSelectedInvoiceLines();
                            return closeModal();
                        }),
                        buttonStyle: TacoButton_Types.Filled.value,
                        color: TacoConstants.SecondaryRed.value,
                        text: "Poista"
                    }) ]
                });
                return TacoModal.component()()({
                    onClose: closeModal,
                    isActive: true,
                    heading: msg,
                    contents: [ modalBody, modalActions ]
                });
            };
            throw new Error("Failed pattern match at InvoicesContainer (line 864, column 13 - line 907, column 14): " + [ v.confirmDeleteInvoices.constructor.name ]);
        })();
        return React_Basic.fragment([ modal, TacoButton.component()()({
            text: "Poista rivit",
            onClick: Data_Maybe.Just.create(v.setConfirmDeleteInvoices(function (v1) {
                return true;
            })),
            color: TacoConstants.SecondaryRed.value
        }) ]);
    };
});
var defaultVisibleRows = new Limited(100);
var component = TofuHooks.mkHookComponent("InvoicesContainer")(function (v) {
    return function __do() {
        var groupWithInvoiceBunchIdEnabled = FeatureHooks.useFeatureEnabled("group-with-invoice-bunch-id")();
        var v1 = TofuHooks.useState({
            selectedInvoiceLineIds: [  ],
            selectedInvoiceBunchId: Data_Maybe.Nothing.value,
            groupBySupplier: false,
            groupByInvoiceBunchId: false,
            filterString: "",
            filterCostGroupCode: Data_Maybe.Nothing.value,
            isAddInvoiceViewVisible: false,
            showSalaryItems: true,
            showInvoicesItems: true,
            showInvoiceBunchIds: false,
            showSupplierInvoiceNumbers: false,
            showQuantityColumn: false,
            showUnitColumn: false,
            showUnitPriceColumn: false,
            sortingOrder: TacoTable_Types.Descending.value,
            sortProperty: InvoiceUtils.ByDate.value,
            visibleRows: defaultVisibleRows,
            filteredMonths: Data_Set.empty,
            upperDateLimit: Data_Maybe.Nothing.value,
            lowerDateLimit: Data_Maybe.Nothing.value
        })();
        var toggleVisibleInvoiceType = function (invoiceType) {
            return v1.value1(function (s) {
                if (invoiceType instanceof Types_Invoice.Invoice) {
                    return {
                        selectedInvoiceLineIds: s.selectedInvoiceLineIds,
                        selectedInvoiceBunchId: s.selectedInvoiceBunchId,
                        groupBySupplier: s.groupBySupplier,
                        groupByInvoiceBunchId: s.groupByInvoiceBunchId,
                        filterString: s.filterString,
                        filterCostGroupCode: s.filterCostGroupCode,
                        isAddInvoiceViewVisible: s.isAddInvoiceViewVisible,
                        showSalaryItems: s.showSalaryItems,
                        showInvoicesItems: !s.showInvoicesItems,
                        showInvoiceBunchIds: s.showInvoiceBunchIds,
                        showSupplierInvoiceNumbers: s.showSupplierInvoiceNumbers,
                        showQuantityColumn: s.showQuantityColumn,
                        showUnitColumn: s.showUnitColumn,
                        showUnitPriceColumn: s.showUnitPriceColumn,
                        sortingOrder: s.sortingOrder,
                        sortProperty: s.sortProperty,
                        visibleRows: s.visibleRows,
                        filteredMonths: s.filteredMonths,
                        upperDateLimit: s.upperDateLimit,
                        lowerDateLimit: s.lowerDateLimit
                    };
                };
                if (invoiceType instanceof Types_Invoice.Salary) {
                    return {
                        selectedInvoiceLineIds: s.selectedInvoiceLineIds,
                        selectedInvoiceBunchId: s.selectedInvoiceBunchId,
                        groupBySupplier: s.groupBySupplier,
                        groupByInvoiceBunchId: s.groupByInvoiceBunchId,
                        filterString: s.filterString,
                        filterCostGroupCode: s.filterCostGroupCode,
                        isAddInvoiceViewVisible: s.isAddInvoiceViewVisible,
                        showSalaryItems: !s.showSalaryItems,
                        showInvoicesItems: s.showInvoicesItems,
                        showInvoiceBunchIds: s.showInvoiceBunchIds,
                        showSupplierInvoiceNumbers: s.showSupplierInvoiceNumbers,
                        showQuantityColumn: s.showQuantityColumn,
                        showUnitColumn: s.showUnitColumn,
                        showUnitPriceColumn: s.showUnitPriceColumn,
                        sortingOrder: s.sortingOrder,
                        sortProperty: s.sortProperty,
                        visibleRows: s.visibleRows,
                        filteredMonths: s.filteredMonths,
                        upperDateLimit: s.upperDateLimit,
                        lowerDateLimit: s.lowerDateLimit
                    };
                };
                return s;
            });
        };
        var toggleShowUnitPriceColumn = v1.value1(function (s) {
            return {
                selectedInvoiceLineIds: s.selectedInvoiceLineIds,
                selectedInvoiceBunchId: s.selectedInvoiceBunchId,
                groupBySupplier: s.groupBySupplier,
                groupByInvoiceBunchId: s.groupByInvoiceBunchId,
                filterString: s.filterString,
                filterCostGroupCode: s.filterCostGroupCode,
                isAddInvoiceViewVisible: s.isAddInvoiceViewVisible,
                showSalaryItems: s.showSalaryItems,
                showInvoicesItems: s.showInvoicesItems,
                showInvoiceBunchIds: s.showInvoiceBunchIds,
                showSupplierInvoiceNumbers: s.showSupplierInvoiceNumbers,
                showQuantityColumn: s.showQuantityColumn,
                showUnitColumn: s.showUnitColumn,
                showUnitPriceColumn: !s.showUnitPriceColumn,
                sortingOrder: s.sortingOrder,
                sortProperty: s.sortProperty,
                visibleRows: s.visibleRows,
                filteredMonths: s.filteredMonths,
                upperDateLimit: s.upperDateLimit,
                lowerDateLimit: s.lowerDateLimit
            };
        });
        var toggleShowUnitColumn = v1.value1(function (s) {
            return {
                selectedInvoiceLineIds: s.selectedInvoiceLineIds,
                selectedInvoiceBunchId: s.selectedInvoiceBunchId,
                groupBySupplier: s.groupBySupplier,
                groupByInvoiceBunchId: s.groupByInvoiceBunchId,
                filterString: s.filterString,
                filterCostGroupCode: s.filterCostGroupCode,
                isAddInvoiceViewVisible: s.isAddInvoiceViewVisible,
                showSalaryItems: s.showSalaryItems,
                showInvoicesItems: s.showInvoicesItems,
                showInvoiceBunchIds: s.showInvoiceBunchIds,
                showSupplierInvoiceNumbers: s.showSupplierInvoiceNumbers,
                showQuantityColumn: s.showQuantityColumn,
                showUnitColumn: !s.showUnitColumn,
                showUnitPriceColumn: s.showUnitPriceColumn,
                sortingOrder: s.sortingOrder,
                sortProperty: s.sortProperty,
                visibleRows: s.visibleRows,
                filteredMonths: s.filteredMonths,
                upperDateLimit: s.upperDateLimit,
                lowerDateLimit: s.lowerDateLimit
            };
        });
        var toggleShowSupplierInvoiceNumbers = v1.value1(function (s) {
            return {
                selectedInvoiceLineIds: s.selectedInvoiceLineIds,
                selectedInvoiceBunchId: s.selectedInvoiceBunchId,
                groupBySupplier: s.groupBySupplier,
                groupByInvoiceBunchId: s.groupByInvoiceBunchId,
                filterString: s.filterString,
                filterCostGroupCode: s.filterCostGroupCode,
                isAddInvoiceViewVisible: s.isAddInvoiceViewVisible,
                showSalaryItems: s.showSalaryItems,
                showInvoicesItems: s.showInvoicesItems,
                showInvoiceBunchIds: s.showInvoiceBunchIds,
                showSupplierInvoiceNumbers: !s.showSupplierInvoiceNumbers,
                showQuantityColumn: s.showQuantityColumn,
                showUnitColumn: s.showUnitColumn,
                showUnitPriceColumn: s.showUnitPriceColumn,
                sortingOrder: s.sortingOrder,
                sortProperty: s.sortProperty,
                visibleRows: s.visibleRows,
                filteredMonths: s.filteredMonths,
                upperDateLimit: s.upperDateLimit,
                lowerDateLimit: s.lowerDateLimit
            };
        });
        var toggleShowQuantityColumn = v1.value1(function (s) {
            return {
                selectedInvoiceLineIds: s.selectedInvoiceLineIds,
                selectedInvoiceBunchId: s.selectedInvoiceBunchId,
                groupBySupplier: s.groupBySupplier,
                groupByInvoiceBunchId: s.groupByInvoiceBunchId,
                filterString: s.filterString,
                filterCostGroupCode: s.filterCostGroupCode,
                isAddInvoiceViewVisible: s.isAddInvoiceViewVisible,
                showSalaryItems: s.showSalaryItems,
                showInvoicesItems: s.showInvoicesItems,
                showInvoiceBunchIds: s.showInvoiceBunchIds,
                showSupplierInvoiceNumbers: s.showSupplierInvoiceNumbers,
                showQuantityColumn: !s.showQuantityColumn,
                showUnitColumn: s.showUnitColumn,
                showUnitPriceColumn: s.showUnitPriceColumn,
                sortingOrder: s.sortingOrder,
                sortProperty: s.sortProperty,
                visibleRows: s.visibleRows,
                filteredMonths: s.filteredMonths,
                upperDateLimit: s.upperDateLimit,
                lowerDateLimit: s.lowerDateLimit
            };
        });
        var toggleShowInvoiceBunchIds = v1.value1(function (s) {
            return {
                selectedInvoiceLineIds: s.selectedInvoiceLineIds,
                selectedInvoiceBunchId: s.selectedInvoiceBunchId,
                groupBySupplier: s.groupBySupplier,
                groupByInvoiceBunchId: s.groupByInvoiceBunchId,
                filterString: s.filterString,
                filterCostGroupCode: s.filterCostGroupCode,
                isAddInvoiceViewVisible: s.isAddInvoiceViewVisible,
                showSalaryItems: s.showSalaryItems,
                showInvoicesItems: s.showInvoicesItems,
                showInvoiceBunchIds: !s.showInvoiceBunchIds,
                showSupplierInvoiceNumbers: s.showSupplierInvoiceNumbers,
                showQuantityColumn: s.showQuantityColumn,
                showUnitColumn: s.showUnitColumn,
                showUnitPriceColumn: s.showUnitPriceColumn,
                sortingOrder: s.sortingOrder,
                sortProperty: s.sortProperty,
                visibleRows: s.visibleRows,
                filteredMonths: s.filteredMonths,
                upperDateLimit: s.upperDateLimit,
                lowerDateLimit: s.lowerDateLimit
            };
        });
        var toggleGroupBySupplier = v1.value1(function (s) {
            return {
                selectedInvoiceLineIds: s.selectedInvoiceLineIds,
                selectedInvoiceBunchId: s.selectedInvoiceBunchId,
                groupBySupplier: !s.groupBySupplier,
                groupByInvoiceBunchId: s.groupByInvoiceBunchId,
                filterString: s.filterString,
                filterCostGroupCode: s.filterCostGroupCode,
                isAddInvoiceViewVisible: s.isAddInvoiceViewVisible,
                showSalaryItems: s.showSalaryItems,
                showInvoicesItems: s.showInvoicesItems,
                showInvoiceBunchIds: s.showInvoiceBunchIds,
                showSupplierInvoiceNumbers: s.showSupplierInvoiceNumbers,
                showQuantityColumn: s.showQuantityColumn,
                showUnitColumn: s.showUnitColumn,
                showUnitPriceColumn: s.showUnitPriceColumn,
                sortingOrder: s.sortingOrder,
                sortProperty: s.sortProperty,
                visibleRows: s.visibleRows,
                filteredMonths: s.filteredMonths,
                upperDateLimit: s.upperDateLimit,
                lowerDateLimit: s.lowerDateLimit
            };
        });
        var toggleGroupByInvoiceBunchId = v1.value1(function (s) {
            return {
                selectedInvoiceLineIds: s.selectedInvoiceLineIds,
                selectedInvoiceBunchId: s.selectedInvoiceBunchId,
                groupBySupplier: s.groupBySupplier,
                groupByInvoiceBunchId: !s.groupByInvoiceBunchId,
                filterString: s.filterString,
                filterCostGroupCode: s.filterCostGroupCode,
                isAddInvoiceViewVisible: s.isAddInvoiceViewVisible,
                showSalaryItems: s.showSalaryItems,
                showInvoicesItems: s.showInvoicesItems,
                showInvoiceBunchIds: s.showInvoiceBunchIds,
                showSupplierInvoiceNumbers: s.showSupplierInvoiceNumbers,
                showQuantityColumn: s.showQuantityColumn,
                showUnitColumn: s.showUnitColumn,
                showUnitPriceColumn: s.showUnitPriceColumn,
                sortingOrder: s.sortingOrder,
                sortProperty: s.sortProperty,
                visibleRows: s.visibleRows,
                filteredMonths: s.filteredMonths,
                upperDateLimit: s.upperDateLimit,
                lowerDateLimit: s.lowerDateLimit
            };
        });
        var toggleAddInvoiceView = v1.value1(function (s) {
            return {
                selectedInvoiceLineIds: s.selectedInvoiceLineIds,
                selectedInvoiceBunchId: s.selectedInvoiceBunchId,
                groupBySupplier: s.groupBySupplier,
                groupByInvoiceBunchId: s.groupByInvoiceBunchId,
                filterString: s.filterString,
                filterCostGroupCode: s.filterCostGroupCode,
                isAddInvoiceViewVisible: !s.isAddInvoiceViewVisible,
                showSalaryItems: s.showSalaryItems,
                showInvoicesItems: s.showInvoicesItems,
                showInvoiceBunchIds: s.showInvoiceBunchIds,
                showSupplierInvoiceNumbers: s.showSupplierInvoiceNumbers,
                showQuantityColumn: s.showQuantityColumn,
                showUnitColumn: s.showUnitColumn,
                showUnitPriceColumn: s.showUnitPriceColumn,
                sortingOrder: s.sortingOrder,
                sortProperty: s.sortProperty,
                visibleRows: s.visibleRows,
                filteredMonths: s.filteredMonths,
                upperDateLimit: s.upperDateLimit,
                lowerDateLimit: s.lowerDateLimit
            };
        });
        var setVisibleRows = function (setting) {
            return v1.value1(function (s) {
                return {
                    selectedInvoiceLineIds: s.selectedInvoiceLineIds,
                    selectedInvoiceBunchId: s.selectedInvoiceBunchId,
                    groupBySupplier: s.groupBySupplier,
                    groupByInvoiceBunchId: s.groupByInvoiceBunchId,
                    filterString: s.filterString,
                    filterCostGroupCode: s.filterCostGroupCode,
                    isAddInvoiceViewVisible: s.isAddInvoiceViewVisible,
                    showSalaryItems: s.showSalaryItems,
                    showInvoicesItems: s.showInvoicesItems,
                    showInvoiceBunchIds: s.showInvoiceBunchIds,
                    showSupplierInvoiceNumbers: s.showSupplierInvoiceNumbers,
                    showQuantityColumn: s.showQuantityColumn,
                    showUnitColumn: s.showUnitColumn,
                    showUnitPriceColumn: s.showUnitPriceColumn,
                    sortingOrder: s.sortingOrder,
                    sortProperty: s.sortProperty,
                    visibleRows: setting,
                    filteredMonths: s.filteredMonths,
                    upperDateLimit: s.upperDateLimit,
                    lowerDateLimit: s.lowerDateLimit
                };
            });
        };
        var setUpperDateLimit = function (value) {
            return v1.value1(function (s) {
                return {
                    selectedInvoiceLineIds: s.selectedInvoiceLineIds,
                    selectedInvoiceBunchId: s.selectedInvoiceBunchId,
                    groupBySupplier: s.groupBySupplier,
                    groupByInvoiceBunchId: s.groupByInvoiceBunchId,
                    filterString: s.filterString,
                    filterCostGroupCode: s.filterCostGroupCode,
                    isAddInvoiceViewVisible: s.isAddInvoiceViewVisible,
                    showSalaryItems: s.showSalaryItems,
                    showInvoicesItems: s.showInvoicesItems,
                    showInvoiceBunchIds: s.showInvoiceBunchIds,
                    showSupplierInvoiceNumbers: s.showSupplierInvoiceNumbers,
                    showQuantityColumn: s.showQuantityColumn,
                    showUnitColumn: s.showUnitColumn,
                    showUnitPriceColumn: s.showUnitPriceColumn,
                    sortingOrder: s.sortingOrder,
                    sortProperty: s.sortProperty,
                    visibleRows: s.visibleRows,
                    filteredMonths: s.filteredMonths,
                    upperDateLimit: value,
                    lowerDateLimit: s.lowerDateLimit
                };
            });
        };
        var setSortingCriteria = function (property) {
            return v1.value1(function (s) {
                var $110 = Data_Eq.eq(InvoiceUtils.eqInvoiceLineSortProperty)(s.sortProperty)(property);
                if ($110) {
                    return {
                        selectedInvoiceLineIds: s.selectedInvoiceLineIds,
                        selectedInvoiceBunchId: s.selectedInvoiceBunchId,
                        groupBySupplier: s.groupBySupplier,
                        groupByInvoiceBunchId: s.groupByInvoiceBunchId,
                        filterString: s.filterString,
                        filterCostGroupCode: s.filterCostGroupCode,
                        isAddInvoiceViewVisible: s.isAddInvoiceViewVisible,
                        showSalaryItems: s.showSalaryItems,
                        showInvoicesItems: s.showInvoicesItems,
                        showInvoiceBunchIds: s.showInvoiceBunchIds,
                        showSupplierInvoiceNumbers: s.showSupplierInvoiceNumbers,
                        showQuantityColumn: s.showQuantityColumn,
                        showUnitColumn: s.showUnitColumn,
                        showUnitPriceColumn: s.showUnitPriceColumn,
                        sortingOrder: TacoTable_Types.reverse(s.sortingOrder),
                        sortProperty: property,
                        visibleRows: s.visibleRows,
                        filteredMonths: s.filteredMonths,
                        upperDateLimit: s.upperDateLimit,
                        lowerDateLimit: s.lowerDateLimit
                    };
                };
                return {
                    selectedInvoiceLineIds: s.selectedInvoiceLineIds,
                    selectedInvoiceBunchId: s.selectedInvoiceBunchId,
                    groupBySupplier: s.groupBySupplier,
                    groupByInvoiceBunchId: s.groupByInvoiceBunchId,
                    filterString: s.filterString,
                    filterCostGroupCode: s.filterCostGroupCode,
                    isAddInvoiceViewVisible: s.isAddInvoiceViewVisible,
                    showSalaryItems: s.showSalaryItems,
                    showInvoicesItems: s.showInvoicesItems,
                    showInvoiceBunchIds: s.showInvoiceBunchIds,
                    showSupplierInvoiceNumbers: s.showSupplierInvoiceNumbers,
                    showQuantityColumn: s.showQuantityColumn,
                    showUnitColumn: s.showUnitColumn,
                    showUnitPriceColumn: s.showUnitPriceColumn,
                    sortingOrder: s.sortingOrder,
                    sortProperty: property,
                    visibleRows: s.visibleRows,
                    filteredMonths: s.filteredMonths,
                    upperDateLimit: s.upperDateLimit,
                    lowerDateLimit: s.lowerDateLimit
                };
            });
        };
        var setLowerDateLimit = function (value) {
            return v1.value1(function (s) {
                return {
                    selectedInvoiceLineIds: s.selectedInvoiceLineIds,
                    selectedInvoiceBunchId: s.selectedInvoiceBunchId,
                    groupBySupplier: s.groupBySupplier,
                    groupByInvoiceBunchId: s.groupByInvoiceBunchId,
                    filterString: s.filterString,
                    filterCostGroupCode: s.filterCostGroupCode,
                    isAddInvoiceViewVisible: s.isAddInvoiceViewVisible,
                    showSalaryItems: s.showSalaryItems,
                    showInvoicesItems: s.showInvoicesItems,
                    showInvoiceBunchIds: s.showInvoiceBunchIds,
                    showSupplierInvoiceNumbers: s.showSupplierInvoiceNumbers,
                    showQuantityColumn: s.showQuantityColumn,
                    showUnitColumn: s.showUnitColumn,
                    showUnitPriceColumn: s.showUnitPriceColumn,
                    sortingOrder: s.sortingOrder,
                    sortProperty: s.sortProperty,
                    visibleRows: s.visibleRows,
                    filteredMonths: s.filteredMonths,
                    upperDateLimit: s.upperDateLimit,
                    lowerDateLimit: value
                };
            });
        };
        var setFilteredMonths = function (mDates) {
            return v1.value1(function (s) {
                return {
                    selectedInvoiceLineIds: s.selectedInvoiceLineIds,
                    selectedInvoiceBunchId: s.selectedInvoiceBunchId,
                    groupBySupplier: s.groupBySupplier,
                    groupByInvoiceBunchId: s.groupByInvoiceBunchId,
                    filterString: s.filterString,
                    filterCostGroupCode: s.filterCostGroupCode,
                    isAddInvoiceViewVisible: s.isAddInvoiceViewVisible,
                    showSalaryItems: s.showSalaryItems,
                    showInvoicesItems: s.showInvoicesItems,
                    showInvoiceBunchIds: s.showInvoiceBunchIds,
                    showSupplierInvoiceNumbers: s.showSupplierInvoiceNumbers,
                    showQuantityColumn: s.showQuantityColumn,
                    showUnitColumn: s.showUnitColumn,
                    showUnitPriceColumn: s.showUnitPriceColumn,
                    sortingOrder: s.sortingOrder,
                    sortProperty: s.sortProperty,
                    visibleRows: s.visibleRows,
                    filteredMonths: mDates,
                    upperDateLimit: s.upperDateLimit,
                    lowerDateLimit: s.lowerDateLimit
                };
            });
        };
        var setFilterString = function (value) {
            return v1.value1(function (s) {
                return {
                    selectedInvoiceLineIds: [  ],
                    selectedInvoiceBunchId: Data_Maybe.Nothing.value,
                    groupBySupplier: s.groupBySupplier,
                    groupByInvoiceBunchId: s.groupByInvoiceBunchId,
                    filterString: value,
                    filterCostGroupCode: s.filterCostGroupCode,
                    isAddInvoiceViewVisible: s.isAddInvoiceViewVisible,
                    showSalaryItems: s.showSalaryItems,
                    showInvoicesItems: s.showInvoicesItems,
                    showInvoiceBunchIds: s.showInvoiceBunchIds,
                    showSupplierInvoiceNumbers: s.showSupplierInvoiceNumbers,
                    showQuantityColumn: s.showQuantityColumn,
                    showUnitColumn: s.showUnitColumn,
                    showUnitPriceColumn: s.showUnitPriceColumn,
                    sortingOrder: s.sortingOrder,
                    sortProperty: s.sortProperty,
                    visibleRows: defaultVisibleRows,
                    filteredMonths: s.filteredMonths,
                    upperDateLimit: s.upperDateLimit,
                    lowerDateLimit: s.lowerDateLimit
                };
            });
        };
        var setFilterCostGroupCode = function (value) {
            return v1.value1(function (s) {
                return {
                    selectedInvoiceLineIds: s.selectedInvoiceLineIds,
                    selectedInvoiceBunchId: s.selectedInvoiceBunchId,
                    groupBySupplier: s.groupBySupplier,
                    groupByInvoiceBunchId: s.groupByInvoiceBunchId,
                    filterString: s.filterString,
                    filterCostGroupCode: value,
                    isAddInvoiceViewVisible: s.isAddInvoiceViewVisible,
                    showSalaryItems: s.showSalaryItems,
                    showInvoicesItems: s.showInvoicesItems,
                    showInvoiceBunchIds: s.showInvoiceBunchIds,
                    showSupplierInvoiceNumbers: s.showSupplierInvoiceNumbers,
                    showQuantityColumn: s.showQuantityColumn,
                    showUnitColumn: s.showUnitColumn,
                    showUnitPriceColumn: s.showUnitPriceColumn,
                    sortingOrder: s.sortingOrder,
                    sortProperty: s.sortProperty,
                    visibleRows: s.visibleRows,
                    filteredMonths: s.filteredMonths,
                    upperDateLimit: s.upperDateLimit,
                    lowerDateLimit: s.lowerDateLimit
                };
            });
        };
        var removeInvoiceLineIds = function (ids) {
            return v1.value1(function (s) {
                return {
                    selectedInvoiceLineIds: Data_Array.difference(Types_Invoice.eqInvoiceRowId)(s.selectedInvoiceLineIds)(ids),
                    selectedInvoiceBunchId: s.selectedInvoiceBunchId,
                    groupBySupplier: s.groupBySupplier,
                    groupByInvoiceBunchId: s.groupByInvoiceBunchId,
                    filterString: s.filterString,
                    filterCostGroupCode: s.filterCostGroupCode,
                    isAddInvoiceViewVisible: s.isAddInvoiceViewVisible,
                    showSalaryItems: s.showSalaryItems,
                    showInvoicesItems: s.showInvoicesItems,
                    showInvoiceBunchIds: s.showInvoiceBunchIds,
                    showSupplierInvoiceNumbers: s.showSupplierInvoiceNumbers,
                    showQuantityColumn: s.showQuantityColumn,
                    showUnitColumn: s.showUnitColumn,
                    showUnitPriceColumn: s.showUnitPriceColumn,
                    sortingOrder: s.sortingOrder,
                    sortProperty: s.sortProperty,
                    visibleRows: s.visibleRows,
                    filteredMonths: s.filteredMonths,
                    upperDateLimit: s.upperDateLimit,
                    lowerDateLimit: s.lowerDateLimit
                };
            });
        };
        var clearSelections = v1.value1(function (s) {
            return {
                selectedInvoiceLineIds: [  ],
                selectedInvoiceBunchId: Data_Maybe.Nothing.value,
                groupBySupplier: s.groupBySupplier,
                groupByInvoiceBunchId: s.groupByInvoiceBunchId,
                filterString: s.filterString,
                filterCostGroupCode: s.filterCostGroupCode,
                isAddInvoiceViewVisible: s.isAddInvoiceViewVisible,
                showSalaryItems: s.showSalaryItems,
                showInvoicesItems: s.showInvoicesItems,
                showInvoiceBunchIds: s.showInvoiceBunchIds,
                showSupplierInvoiceNumbers: s.showSupplierInvoiceNumbers,
                showQuantityColumn: s.showQuantityColumn,
                showUnitColumn: s.showUnitColumn,
                showUnitPriceColumn: s.showUnitPriceColumn,
                sortingOrder: s.sortingOrder,
                sortProperty: s.sortProperty,
                visibleRows: s.visibleRows,
                filteredMonths: s.filteredMonths,
                upperDateLimit: s.upperDateLimit,
                lowerDateLimit: s.lowerDateLimit
            };
        });
        var clearSelectedInvoiceLineIds = v1.value1(function (s) {
            return {
                selectedInvoiceLineIds: [  ],
                selectedInvoiceBunchId: s.selectedInvoiceBunchId,
                groupBySupplier: s.groupBySupplier,
                groupByInvoiceBunchId: s.groupByInvoiceBunchId,
                filterString: s.filterString,
                filterCostGroupCode: s.filterCostGroupCode,
                isAddInvoiceViewVisible: s.isAddInvoiceViewVisible,
                showSalaryItems: s.showSalaryItems,
                showInvoicesItems: s.showInvoicesItems,
                showInvoiceBunchIds: s.showInvoiceBunchIds,
                showSupplierInvoiceNumbers: s.showSupplierInvoiceNumbers,
                showQuantityColumn: s.showQuantityColumn,
                showUnitColumn: s.showUnitColumn,
                showUnitPriceColumn: s.showUnitPriceColumn,
                sortingOrder: s.sortingOrder,
                sortProperty: s.sortProperty,
                visibleRows: s.visibleRows,
                filteredMonths: s.filteredMonths,
                upperDateLimit: s.upperDateLimit,
                lowerDateLimit: s.lowerDateLimit
            };
        });
        var addInvoiceLineIds = function (ids) {
            return v1.value1(function (s) {
                return {
                    selectedInvoiceLineIds: Data_Array.union(Types_Invoice.eqInvoiceRowId)(s.selectedInvoiceLineIds)(ids),
                    selectedInvoiceBunchId: s.selectedInvoiceBunchId,
                    groupBySupplier: s.groupBySupplier,
                    groupByInvoiceBunchId: s.groupByInvoiceBunchId,
                    filterString: s.filterString,
                    filterCostGroupCode: s.filterCostGroupCode,
                    isAddInvoiceViewVisible: s.isAddInvoiceViewVisible,
                    showSalaryItems: s.showSalaryItems,
                    showInvoicesItems: s.showInvoicesItems,
                    showInvoiceBunchIds: s.showInvoiceBunchIds,
                    showSupplierInvoiceNumbers: s.showSupplierInvoiceNumbers,
                    showQuantityColumn: s.showQuantityColumn,
                    showUnitColumn: s.showUnitColumn,
                    showUnitPriceColumn: s.showUnitPriceColumn,
                    sortingOrder: s.sortingOrder,
                    sortProperty: s.sortProperty,
                    visibleRows: s.visibleRows,
                    filteredMonths: s.filteredMonths,
                    upperDateLimit: s.upperDateLimit,
                    lowerDateLimit: s.lowerDateLimit
                };
            });
        };
        var v2 = UseFetch.useFetchValueByProjectId(v.projectId)(function (v2) {
            return v2.selectedProject;
        })(Actions.GetProjectRequest.create)();
        var v3 = UseFetch.useFetchItemsByProjectId(v.projectId)(function (v3) {
            return v3.invoices;
        })(Actions.GetInvoicesRequest.create)();
        var v4 = UseFetch.useFetchItemsByProjectId(v.projectId)(function (v4) {
            return v4.invoiceBunches;
        })(Actions.InvoiceBunchesRequest.create)();
        var v5 = UseFetch.useFetchItems(Data_Eq.eqUnit)(Data_Unit.unit)(function (v5) {
            return v5.allInvoiceBunches;
        })(Actions.AllInvoiceBunchesRequest.value)();
        var v6 = UseFetch.useFetchItemsByProjectId(v.projectId)(function (v6) {
            return v6.costGroupInfos;
        })(Actions.CostGroupInfosRequest.create)();
        StateHooks.useSelector(function (v7) {
            return v7.updateStatuses;
        })();
        var v7 = UseFetch.useFetchItems(Data_Eq.eqUnit)(Data_Unit.unit)(function (v7) {
            return v7.suppliers;
        })(Actions.SuppliersRequest.value)();
        var v8 = Client_WASM.useCostClasses(v.projectId)();
        var v9 = TofuHooks.useState(false)();
        var v10 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v10) {
            return v10.costControlExcludeActualCostSocialExpenses;
        })(Actions.GetCostControlExcludeActualCostSocialExpensesRequest.value)();
        var disableSocialCostToActual = (function () {
            if (v10.value0 instanceof Data_Maybe.Just && v10.value0.value0.costControlExcludeActualCostSocialExpenses) {
                return Calculation_Income.DisableSocialCostToActual.value;
            };
            return Calculation_Income.AllowSocialCostToActual.value;
        })();
        var invoiceLinesWithSocialExpenses = (function () {
            if (disableSocialCostToActual instanceof Calculation_Income.AllowSocialCostToActual) {
                return Utils.addSocialExpenses(v8.value0)(v3.value0);
            };
            return v3.value0;
        })();
        var maybeProject = Data_Functor.map(Data_Maybe.functorMaybe)(function (v11) {
            return v11.project;
        })(v2.value0);
        var appDispatch = StateHooks.useDispatch();
        var visibleInvoiceTypes = (function () {
            if (v1.value0.showInvoicesItems && v1.value0.showSalaryItems) {
                return [ Types_Invoice.Invoice.value, Types_Invoice.Salary.value ];
            };
            if (v1.value0.showInvoicesItems && !v1.value0.showSalaryItems) {
                return [ Types_Invoice.Invoice.value ];
            };
            if (!v1.value0.showInvoicesItems && v1.value0.showSalaryItems) {
                return [ Types_Invoice.Salary.value ];
            };
            return [  ];
        })();
        var sortedCostGroupInfos = Data_Array.sortWith(Types_CostGroup.ordCostGroupCode)((function () {
            var $233 = Data_Newtype.un()(Types_CostGroup.CostGroupInfo);
            return function ($234) {
                return (function (v11) {
                    return v11.code;
                })($233($234));
            };
        })())(v6.value0);
        var selectedInvoiceLinesCount = Data_Array.length(v1.value0.selectedInvoiceLineIds);
        var selectedInvoiceLines = Data_Array.catMaybes(Data_Functor.map(Data_Functor.functorArray)(function (id) {
            return Data_Array.find(function (x) {
                return Data_Eq.eq(Types_Invoice.eqInvoiceRowId)(x.id)(id);
            })(invoiceLinesWithSocialExpenses);
        })(v1.value0.selectedInvoiceLineIds));
        var selectedInvoices = Data_Array.groupBy(Data_Function.on(Data_Eq.eq(Types_Invoice.eqInvoiceId))(function (v11) {
            return v11.invoiceId;
        }))(Data_Array.sortBy(Data_Function.on(Data_Ord.compare(Types_Invoice.ordInvoiceId))(function (v11) {
            return v11.invoiceId;
        }))(selectedInvoiceLines));
        var selectedInvoicesCount = Data_Array.length(selectedInvoices);
        var selectedInvoicesToExcelButton = (function () {
            if (maybeProject instanceof Data_Maybe.Just) {
                return TacoButton.component()()({
                    text: "Vie valitut laskut Exceliin",
                    align: TacoButton_Types.Left.value,
                    onClick: Data_Maybe.Just.create(appDispatch(new Actions.GetInvoiceLinesExportRequest({
                        invoiceLines: selectedInvoiceLines,
                        projectCode: maybeProject.value0.code
                    }))),
                    icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconFileDownloadV1.value)
                });
            };
            return React_Basic.empty;
        })();
        var prepareCostGroupDropdownListItem = function (v11) {
            return {
                id: v11.code,
                text: v11.code + (" " + v11.name)
            };
        };
        var prepareCostClassDropdownListItem = function (i) {
            var code = Data_Newtype.un()(Types_Newtypes.CostClassCode)(i.costClassCode);
            return {
                id: code,
                text: code + (": " + i.name)
            };
        };
        var loading = v3.value1 || (v4.value1 || v6.value1);
        var limitResults = (function () {
            if (v1.value0.visibleRows instanceof All) {
                return Control_Category.identity(Control_Category.categoryFn);
            };
            if (v1.value0.visibleRows instanceof Limited) {
                return Data_Array.take(v1.value0.visibleRows.value0);
            };
            throw new Error("Failed pattern match at InvoicesContainer (line 288, column 20 - line 290, column 36): " + [ v1.value0.visibleRows.constructor.name ]);
        })();
        var invoiceLinesFilteredByMonthGroupedBySupplier = InvoiceUtils.createInvoiceResultsGroupedBySupplier({
            sortingOrder: v1.value0.sortingOrder,
            sortProperty: v1.value0.sortProperty
        })((function () {
            var v11 = Data_Set.isEmpty(v1.value0.filteredMonths);
            if (v11) {
                return Control_Category.identity(Control_Category.categoryFn);
            };
            if (!v11) {
                return Data_Array.filter(InvoiceUtils.matchesInvoiceMonth(Data_Array.fromFoldable(Data_Set.foldableSet)(v1.value0.filteredMonths)));
            };
            throw new Error("Failed pattern match at InvoicesContainer (line 337, column 11 - line 339, column 99): " + [ v11.constructor.name ]);
        })()(invoiceLinesWithSocialExpenses));
        var invoiceLinesFilteredByMonthGroupedByInvoiceBunchId = InvoiceUtils.createInvoiceResultsGroupedByInvoiceBunchId({
            sortingOrder: v1.value0.sortingOrder,
            sortProperty: v1.value0.sortProperty
        })((function () {
            var v11 = Data_Set.isEmpty(v1.value0.filteredMonths);
            if (v11) {
                return Control_Category.identity(Control_Category.categoryFn);
            };
            if (!v11) {
                return Data_Array.filter(InvoiceUtils.matchesInvoiceMonth(Data_Array.fromFoldable(Data_Set.foldableSet)(v1.value0.filteredMonths)));
            };
            throw new Error("Failed pattern match at InvoicesContainer (line 351, column 11 - line 353, column 99): " + [ v11.constructor.name ]);
        })()(invoiceLinesWithSocialExpenses));
        var handleInvoiceBunchUpdate = function (eitherExistingCustomEntry) {
            var handleEmpty = function (v11) {
                if (v11 === "") {
                    return Data_Maybe.Nothing.value;
                };
                return Data_Maybe.Just.create(v11);
            };
            var eitherExistingCustomInvoiceBunchId = Data_Functor.map(Data_Either.functorEither)(Data_String_Common.trim)(eitherExistingCustomEntry);
            var optionalInvoiceBunchId = Data_Either.either(Data_Maybe.Just.create)(handleEmpty)(eitherExistingCustomInvoiceBunchId);
            var optionalNewInvoiceBunchId = Data_Either.either(Data_Function["const"](Data_Maybe.Nothing.value))(handleEmpty)(eitherExistingCustomInvoiceBunchId);
            var params = {
                optionalNewInvoiceBunchId: optionalNewInvoiceBunchId,
                updates: Data_Functor.map(Data_Functor.functorArray)(function (v11) {
                    return {
                        invoiceLineId: v11,
                        optionalInvoiceBunchId: optionalInvoiceBunchId
                    };
                })(v1.value0.selectedInvoiceLineIds)
            };
            var request = new Actions.UpdateInvoiceLineBunchesRequest({
                projectId: v.projectId,
                params: params
            });
            return function __do() {
                appDispatch(request)();
                return clearSelections();
            };
        };
        var getInvoiceImgInfo = function (invoiceLines) {
            var invoiceLine = Data_Array_NonEmpty.head(invoiceLines);
            return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Nullable.toMaybe(invoiceLine.invoiceImg))(function (invoiceImg) {
                return new Data_Maybe.Just({
                    invoiceImg: invoiceImg,
                    supplier: Data_Nullable.toMaybe(invoiceLine.supplier),
                    invoiceNumber: Data_Nullable.toMaybe(invoiceLine.invoiceNumber)
                });
            });
        };
        var selectedInvoiceImages = Data_Array.mapMaybe(getInvoiceImgInfo)(selectedInvoices);
        var editInvoiceBunchControl = (function () {
            var readInvoiceBunchName = function (defaultValue) {
                var testAlreadyExists = function (input) {
                    return Data_Maybe.isJust(Data_Array.find(function (x) {
                        return Data_Newtype.un()(Types_Invoice.InvoiceBunchId)(x.invoiceBunchId) === input;
                    })(v5.value0));
                };
                var nameExceedsMaxByCharsAmount = Data_String_CodePoints.length(defaultValue) - 25 | 0;
                var warning = (function () {
                    var v11 = testAlreadyExists(defaultValue);
                    var v12 = nameExceedsMaxByCharsAmount > 0;
                    if (v12) {
                        return ". Sy\xf6tt\xe4m\xe4ss\xe4si nimess\xe4 on " + (Data_Show.show(Data_Show.showInt)(nameExceedsMaxByCharsAmount) + " merkki\xe4 liikaa.");
                    };
                    if (v11) {
                        return ". Samanniminen koontilasku on jo olemassa (ehk\xe4 toisella hankkeella): " + defaultValue;
                    };
                    if (!v12 && !v11) {
                        return "";
                    };
                    throw new Error("Failed pattern match at InvoicesContainer (line 575, column 23 - line 578, column 35): " + [ v12.constructor.name, v11.constructor.name ]);
                })();
                var message = "Sy\xf6t\xe4 koontilaskun nimi (max. " + (Data_Show.show(Data_Show.showInt)(25) + (" merkki\xe4)" + warning));
                return function __do() {
                    var input = WindowUtils.prompt(message)(defaultValue)();
                    if (input instanceof Data_Maybe.Nothing) {
                        return Data_Unit.unit;
                    };
                    if (input instanceof Data_Maybe.Just) {
                        var v11 = testAlreadyExists(input.value0);
                        var v12 = Data_String_CodePoints.length(input.value0) > 25;
                        if (!v12 && !v11) {
                            return handleInvoiceBunchUpdate(new Data_Either.Right(input.value0))();
                        };
                        return readInvoiceBunchName(input.value0)();
                    };
                    throw new Error("Failed pattern match at InvoicesContainer (line 567, column 11 - line 572, column 47): " + [ input.constructor.name ]);
                };
            };
            var getEditInvoiceBunchSelectOption = function (v11) {
                return React_Basic_DOM_Generated.option()({
                    value: v11.invoiceBunchId,
                    children: [ React_Basic_DOM.text(v11.invoiceBunchId) ]
                });
            };
            return React_Basic_Emotion.element(React_Basic_DOM_Generated["select'"]())({
                value: Data_Maybe.fromMaybe("")(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Newtype.un()(Types_Invoice.InvoiceBunchId))(v1.value0.selectedInvoiceBunchId)),
                className: "edit-invoice-bunch-control",
                css: Invoices_Styles.editInvoiceBunchControlStyles,
                children: Data_Semigroup.append(Data_Semigroup.semigroupArray)([ React_Basic_DOM_Generated.option()({
                    value: "",
                    disabled: true,
                    children: [ React_Basic_DOM.text(Locale.lookup_("invoice_associate_with_bunch")) ]
                }), React_Basic_DOM_Generated.option()({
                    value: "",
                    disabled: true,
                    children: [ React_Basic_DOM.text("--") ]
                }), React_Basic_DOM_Generated.option()({
                    value: "__newInvoiceBunch",
                    children: [ React_Basic_DOM.text("Luo uusi koontilasku") ]
                }), React_Basic_DOM_Generated.option()({
                    value: "",
                    disabled: true,
                    children: [ React_Basic_DOM.text("--") ]
                }) ])(Data_Functor.map(Data_Functor.functorArray)(getEditInvoiceBunchSelectOption)(v4.value0)),
                onChange: React_Basic_Events.handler(React_Basic_DOM_Events.targetValue)(function (value) {
                    if (value instanceof Data_Maybe.Just && value.value0 === "__newInvoiceBunch") {
                        return readInvoiceBunchName("");
                    };
                    var v11 = function (v12) {
                        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                    };
                    if (value instanceof Data_Maybe.Just) {
                        var $146 = Data_Array.find(function (x) {
                            return Data_Eq.eq(Types_Invoice.eqInvoiceBunchId)(x.invoiceBunchId)(value.value0);
                        })(v4.value0);
                        if ($146 instanceof Data_Maybe.Just) {
                            return handleInvoiceBunchUpdate(new Data_Either.Left(value.value0));
                        };
                        return v11(true);
                    };
                    return v11(true);
                })
            });
        })();
        var downloadImagesButton = (function () {
            var noImageFoundForAnySelectedInvoice = Data_Array.length(selectedInvoiceImages) === 0;
            var isOnlyOneInvoiceSelected = selectedInvoicesCount === 1;
            var downloadText = function (oneInvoice) {
                return function (noImages) {
                    var text = function (v11) {
                        return function (v12) {
                            if (v11 && v12) {
                                return Locale.lookup_("invoice_download_single_image_not_available");
                            };
                            if (v11 && !v12) {
                                return Locale.lookup_("invoice_download_single_image");
                            };
                            if (!v11 && v12) {
                                return Locale.lookup_("invoice_download_multiple_images_not_available");
                            };
                            if (!v11 && !v12) {
                                return Locale.lookup_("invoice_download_multiple_images") + (" (" + (Data_Show.show(Data_Show.showInt)(Data_Array.length(selectedInvoiceImages)) + (" " + (Locale.lookup_("pcs") + ")"))));
                            };
                            throw new Error("Failed pattern match at InvoicesContainer (line 706, column 13 - line 706, column 90): " + [ v11.constructor.name, v12.constructor.name ]);
                        };
                    };
                    return text(oneInvoice)(noImages);
                };
            };
            return TacoButton.component()()({
                text: downloadText(isOnlyOneInvoiceSelected)(noImageFoundForAnySelectedInvoice),
                align: TacoButton_Types.Left.value,
                disabled: noImageFoundForAnySelectedInvoice,
                icon: (function () {
                    if (noImageFoundForAnySelectedInvoice) {
                        return Data_Maybe.Nothing.value;
                    };
                    return new Data_Maybe.Just(TacoIcon_Icons_Types.IconFileDownloadV1.value);
                })(),
                onClick: Data_Maybe.Just.create((function () {
                    var mkFile = function (imgInfo) {
                        return {
                            name: (function () {
                                if (imgInfo.supplier instanceof Data_Maybe.Just && imgInfo.invoiceNumber instanceof Data_Maybe.Just) {
                                    return Data_Array.intercalate(Data_Monoid.monoidString)("-")([ imgInfo.supplier.value0, imgInfo.invoiceNumber.value0, imgInfo.invoiceImg ]);
                                };
                                return imgInfo.invoiceImg;
                            })(),
                            filename: imgInfo.invoiceImg
                        };
                    };
                    var v11 = Data_Array.partition(function ($235) {
                        return Utils.isLink((function (v12) {
                            return v12.invoiceImg;
                        })($235));
                    })(selectedInvoiceImages);
                    return function __do() {
                        Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(Data_Array.length(v11.yes) > 0)(function __do() {
                            appDispatch(new Actions.AddAlert(Types_Alert.AlertWarning.value, "Ulkopuolisissa j\xe4rjestelmiss\xe4 olevia laskuja ei voida paketoida zip-tiedostoksi, joten ne avataan erillisiss\xe4 ikkunoissa.."))();
                            return Effect_Aff.launchAff_(Data_Foldable.for_(Effect_Aff.applicativeAff)(Data_Foldable.foldableArray)(v11.yes)(function (imgInfo) {
                                return DOMUtils.openInvoiceInNewTab(imgInfo.invoiceImg)("_blank")((function () {
                                    var $236 = Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
                                    return function ($237) {
                                        return $236(appDispatch($237));
                                    };
                                })());
                            }))();
                        })();
                        appDispatch(new Actions.ZipInvoiceImgsRequest({
                            projectId: new Data_Maybe.Just(v.projectId),
                            files: Data_Functor.map(Data_Functor.functorArray)(mkFile)(v11.no)
                        }))();
                        return clearSelectedInvoiceLineIds();
                    };
                })())
            });
        })();
        var downloadButtons = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "download-buttons",
            css: Invoices_Styles.verticalButtonGroupStyles,
            children: [ selectedInvoicesToExcelButton, downloadImagesButton ]
        });
        var costGroupsDropdownItems = Data_Functor.map(Data_Functor.functorArray)(prepareCostGroupDropdownListItem)(sortedCostGroupInfos);
        var editCostGroupControl = (function () {
            var costGroupSelector = React_Basic_DOM_Generated.div()({
                className: "edit-invoice-selection-form",
                children: Control_Applicative.pure(Control_Applicative.applicativeArray)(TacoAutocomplete.component()()({
                    sections: [ {
                        text: Data_Maybe.Nothing.value,
                        list: costGroupsDropdownItems
                    } ],
                    clearButton: false,
                    onSelect: function (item) {
                        if (item instanceof Data_Maybe.Just) {
                            return function __do() {
                                appDispatch(new Actions.UpdateInvoiceLinesCostGroupCodeRequest({
                                    projectId: v.projectId,
                                    invoiceLineIds: v1.value0.selectedInvoiceLineIds,
                                    costGroupCode: item.value0.id
                                }))();
                                return clearSelections();
                            };
                        };
                        if (item instanceof Data_Maybe.Nothing) {
                            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                        };
                        throw new Error("Failed pattern match at InvoicesContainer (line 619, column 19 - line 627, column 41): " + [ item.constructor.name ]);
                    },
                    selected: Data_Maybe.Nothing.value,
                    placeholder: "Kohdista litteralle",
                    testId: "insert-cost-group-dropdown"
                }))
            });
            var costClassSelector = React_Basic_DOM_Generated.div()({
                className: "edit-invoice-selection-form cc",
                children: Control_Applicative.pure(Control_Applicative.applicativeArray)(TacoAutocomplete.component()()({
                    sections: [ {
                        text: Data_Maybe.Nothing.value,
                        list: Data_Functor.map(Data_Functor.functorArray)(prepareCostClassDropdownListItem)(v8.value0)
                    } ],
                    clearButton: false,
                    onSelect: function (item) {
                        if (item instanceof Data_Maybe.Just) {
                            return function __do() {
                                appDispatch(new Actions.UpdateInvoiceLinesCostClassCodeRequest({
                                    projectId: v.projectId,
                                    invoiceLineIds: v1.value0.selectedInvoiceLineIds,
                                    costClassCode: item.value0.id
                                }))();
                                return Data_Unit.unit;
                            };
                        };
                        if (item instanceof Data_Maybe.Nothing) {
                            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                        };
                        throw new Error("Failed pattern match at InvoicesContainer (line 640, column 19 - line 648, column 41): " + [ item.constructor.name ]);
                    },
                    selected: Data_Maybe.Nothing.value,
                    placeholder: "Kohdista kustannuslajille",
                    testId: "insert-cost-class-dropdown"
                }))
            });
            return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                className: "edit-cost-group-control",
                css: Invoices_Styles.verticalButtonGroupStyles,
                children: [ costGroupSelector, costClassSelector ]
            });
        })();
        var checkboxShowUnitPriceColumn = TacoCheckbox.component()()({
            className: "show-invoice-unitprice invoice-checkboxes",
            isChecked: v1.value0.showUnitPriceColumn,
            onToggle: new TacoCheckbox.NoEvent(toggleShowUnitPriceColumn),
            labelText: Data_Maybe.Just.create(TacoCheckbox.StringLabel.create(Locale.lookup_("invoice_unitprice_column"))),
            testId: "show-invoice-unitprice"
        });
        var checkboxShowUnitColumn = TacoCheckbox.component()()({
            className: "show-invoice-unit invoice-checkboxes",
            isChecked: v1.value0.showUnitColumn,
            onToggle: new TacoCheckbox.NoEvent(toggleShowUnitColumn),
            labelText: Data_Maybe.Just.create(TacoCheckbox.StringLabel.create(Locale.lookup_("invoice_unit_column"))),
            testId: "show-invoice-unit"
        });
        var checkboxShowSupplierInvoiceNumbers = TacoCheckbox.component()()({
            className: "show-invoice-numbers invoice-checkboxes",
            isChecked: v1.value0.showSupplierInvoiceNumbers,
            onToggle: new TacoCheckbox.NoEvent(toggleShowSupplierInvoiceNumbers),
            labelText: Data_Maybe.Just.create(TacoCheckbox.StringLabel.create(Locale.lookup_("invoice_supplier_invoice_numbers"))),
            testId: "show-invoice-numbers"
        });
        var checkboxShowQuantityColumn = TacoCheckbox.component()()({
            className: "show-invoice-quantity invoice-checkboxes",
            isChecked: v1.value0.showQuantityColumn,
            onToggle: new TacoCheckbox.NoEvent(toggleShowQuantityColumn),
            labelText: Data_Maybe.Just.create(TacoCheckbox.StringLabel.create(Locale.lookup_("invoice_quantity_column"))),
            testId: "show-invoice-quantity"
        });
        var checkboxShowInvoiceBunchIds = TacoCheckbox.component()()({
            className: "show-invoice-bunch-ids invoice-checkboxes",
            isChecked: v1.value0.showInvoiceBunchIds,
            onToggle: new TacoCheckbox.NoEvent(toggleShowInvoiceBunchIds),
            labelText: Data_Maybe.Just.create(TacoCheckbox.StringLabel.create(Locale.lookup_("invoice_bunch_ids"))),
            testId: "show-invoice-bunch-ids"
        });
        var checkboxBySupplier = TacoCheckbox.component()()({
            className: "group-by-supplier-control invoice-checkboxes",
            isChecked: v1.value0.groupBySupplier,
            onToggle: (function () {
                if (v1.value0.groupByInvoiceBunchId) {
                    return new TacoCheckbox.Disabled("Ryhmitelty koontilaskun mukaan");
                };
                return new TacoCheckbox.NoEvent(toggleGroupBySupplier);
            })(),
            labelText: Data_Maybe.Just.create(TacoCheckbox.StringLabel.create(Locale.lookup_("groupBySupplier"))),
            testId: "group-by-supplier-toggle"
        });
        var checkboxByInvoiceBunchId = TacoCheckbox.component()()({
            className: "group-by-invoice-bunch-id invoice-checkboxes",
            isChecked: v1.value0.groupByInvoiceBunchId,
            onToggle: (function () {
                if (v1.value0.groupBySupplier) {
                    return new TacoCheckbox.Disabled("Ryhmitelty toimittajan mukaan");
                };
                return new TacoCheckbox.NoEvent(toggleGroupByInvoiceBunchId);
            })(),
            labelText: Data_Maybe.Just.create(TacoCheckbox.StringLabel.create(Locale.lookup_("groupByInvoiceBunchId"))),
            testId: "group-by-invoice-bunch-id-toggle"
        });
        var applyInvoiceLinesFilters = (function () {
            var matchUpperDate = function (filter) {
                return function (r) {
                    var v11 = Data_Nullable.toMaybe(r.date);
                    if (v11 instanceof Data_Maybe.Nothing) {
                        return false;
                    };
                    if (v11 instanceof Data_Maybe.Just) {
                        return Data_Ord.lessThanOrEq(Data_JSDate.ordJSDate)(Utils.lossyParseJSDate(v11.value0))(Utils.lossyParseJSDateString(filter));
                    };
                    throw new Error("Failed pattern match at InvoicesContainer (line 320, column 11 - line 322, column 92): " + [ v11.constructor.name ]);
                };
            };
            var matchLowerDate = function (filter) {
                return function (r) {
                    var v11 = Data_Nullable.toMaybe(r.date);
                    if (v11 instanceof Data_Maybe.Nothing) {
                        return false;
                    };
                    if (v11 instanceof Data_Maybe.Just) {
                        return Data_Ord.greaterThanOrEq(Data_JSDate.ordJSDate)(Utils.lossyParseJSDate(v11.value0))(Utils.lossyParseJSDateString(filter));
                    };
                    throw new Error("Failed pattern match at InvoicesContainer (line 324, column 11 - line 326, column 92): " + [ v11.constructor.name ]);
                };
            };
            var matchDateRange = (function () {
                if (v1.value0.upperDateLimit instanceof Data_Maybe.Just && v1.value0.lowerDateLimit instanceof Data_Maybe.Nothing) {
                    return Data_Array.filter((function () {
                        var $238 = Data_Foldable.any(Data_Foldable.foldableArray)(Data_HeytingAlgebra.heytingAlgebraBoolean)(matchUpperDate(v1.value0.upperDateLimit.value0));
                        return function ($239) {
                            return $238((function (v11) {
                                return v11.invoiceLines;
                            })($239));
                        };
                    })());
                };
                if (v1.value0.upperDateLimit instanceof Data_Maybe.Nothing && v1.value0.lowerDateLimit instanceof Data_Maybe.Just) {
                    return Data_Array.filter((function () {
                        var $240 = Data_Foldable.any(Data_Foldable.foldableArray)(Data_HeytingAlgebra.heytingAlgebraBoolean)(matchLowerDate(v1.value0.lowerDateLimit.value0));
                        return function ($241) {
                            return $240((function (v11) {
                                return v11.invoiceLines;
                            })($241));
                        };
                    })());
                };
                if (v1.value0.upperDateLimit instanceof Data_Maybe.Just && v1.value0.lowerDateLimit instanceof Data_Maybe.Just) {
                    return Data_Array.filter((function () {
                        var $242 = Data_Foldable.any(Data_Foldable.foldableArray)(Data_HeytingAlgebra.heytingAlgebraBoolean)(Data_HeytingAlgebra.conj(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(matchLowerDate(v1.value0.lowerDateLimit.value0))(matchUpperDate(v1.value0.upperDateLimit.value0)));
                        return function ($243) {
                            return $242((function (v11) {
                                return v11.invoiceLines;
                            })($243));
                        };
                    })());
                };
                if (v1.value0.upperDateLimit instanceof Data_Maybe.Nothing && v1.value0.lowerDateLimit instanceof Data_Maybe.Nothing) {
                    return Control_Category.identity(Control_Category.categoryFn);
                };
                throw new Error("Failed pattern match at InvoicesContainer (line 314, column 26 - line 318, column 39): " + [ v1.value0.upperDateLimit.constructor.name, v1.value0.lowerDateLimit.constructor.name ]);
            })();
            var matchCostGroupCode = function (filter) {
                return function (r) {
                    var v11 = Data_Nullable.toMaybe(r.costGroupCode);
                    if (v11 instanceof Data_Maybe.Nothing) {
                        return false;
                    };
                    if (v11 instanceof Data_Maybe.Just) {
                        return v11.value0 === filter;
                    };
                    throw new Error("Failed pattern match at InvoicesContainer (line 311, column 11 - line 313, column 50): " + [ v11.constructor.name ]);
                };
            };
            var applyStringFilter = Data_Array.filter((function () {
                var $244 = Data_Foldable.any(Data_Foldable.foldableArray)(Data_HeytingAlgebra.heytingAlgebraBoolean)(InvoiceUtils.matchesByTypeAndPattern(visibleInvoiceTypes)(v1.value0.filterString));
                return function ($245) {
                    return $244((function (v11) {
                        return v11.invoiceLines;
                    })($245));
                };
            })());
            var applyCostGroupCodeFilter = (function () {
                if (v1.value0.filterCostGroupCode instanceof Data_Maybe.Just) {
                    return Data_Array.filter((function () {
                        var $246 = Data_Foldable.any(Data_Foldable.foldableArray)(Data_HeytingAlgebra.heytingAlgebraBoolean)(matchCostGroupCode(v1.value0.filterCostGroupCode.value0));
                        return function ($247) {
                            return $246((function (v11) {
                                return v11.invoiceLines;
                            })($247));
                        };
                    })());
                };
                if (v1.value0.filterCostGroupCode instanceof Data_Maybe.Nothing) {
                    return Control_Category.identity(Control_Category.categoryFn);
                };
                throw new Error("Failed pattern match at InvoicesContainer (line 307, column 36 - line 309, column 30): " + [ v1.value0.filterCostGroupCode.constructor.name ]);
            })();
            return function ($248) {
                return applyCostGroupCodeFilter(applyStringFilter(matchDateRange($248)));
            };
        })();
        var filteredInvoiceLinesGroupedByInvoiceBunchId = applyInvoiceLinesFilters(invoiceLinesFilteredByMonthGroupedByInvoiceBunchId);
        var filteredInvoiceLinesGroupedBySupplier = applyInvoiceLinesFilters(invoiceLinesFilteredByMonthGroupedBySupplier);
        var allPurchaseInvoices = InvoiceUtils.createInvoiceResults({
            sortingOrder: v1.value0.sortingOrder,
            sortProperty: v1.value0.sortProperty
        })(invoiceLinesWithSocialExpenses);
        var filterControls = InvoiceFilterControls.invoiceFilterControls({
            filterString: v1.value0.filterString,
            onFilterStringChange: (function () {
                var $249 = Data_Maybe.fromMaybe("");
                return function ($250) {
                    return setFilterString($249($250));
                };
            })(),
            filterCostGroupCode: v1.value0.filterCostGroupCode,
            setFilterCostGroupCode: setFilterCostGroupCode,
            setUpperDateLimit: setUpperDateLimit,
            upperDateLimit: v1.value0.upperDateLimit,
            setLowerDateLimit: setLowerDateLimit,
            lowerDateLimit: v1.value0.lowerDateLimit,
            showInvoiceItems: v1.value0.showInvoicesItems,
            showSalaryItems: v1.value0.showSalaryItems,
            invoicesCount: Data_Array.length(Data_Array.filter((function () {
                var $251 = Data_Foldable.any(Data_Foldable.foldableArray)(Data_HeytingAlgebra.heytingAlgebraBoolean)(InvoiceUtils.matchesByTypeAndPattern([ Types_Invoice.Invoice.value ])(v1.value0.filterString));
                return function ($252) {
                    return $251((function (v11) {
                        return v11.invoiceLines;
                    })($252));
                };
            })())(allPurchaseInvoices)),
            salariesCount: Data_Array.length(Data_Array.filter((function () {
                var $253 = Data_Foldable.any(Data_Foldable.foldableArray)(Data_HeytingAlgebra.heytingAlgebraBoolean)(InvoiceUtils.matchesByTypeAndPattern([ Types_Invoice.Salary.value ])(v1.value0.filterString));
                return function ($254) {
                    return $253((function (v11) {
                        return v11.invoiceLines;
                    })($254));
                };
            })())(allPurchaseInvoices)),
            totalCount: Data_Array.length(allPurchaseInvoices),
            onInvoiceTypeToggle: toggleVisibleInvoiceType,
            costGroupsDropdownItems: costGroupsDropdownItems
        });
        var textFilteredPurchaseInvoices = applyInvoiceLinesFilters(allPurchaseInvoices);
        var filteredPurchaseInvoices = (function () {
            var filterBySelectedMonths = (function () {
                var v11 = Data_Set.isEmpty(v1.value0.filteredMonths);
                if (v11) {
                    return Control_Category.identity(Control_Category.categoryFn);
                };
                if (!v11) {
                    return Data_Array.filter((function () {
                        var $255 = Data_Foldable.any(Data_Foldable.foldableArray)(Data_HeytingAlgebra.heytingAlgebraBoolean)(InvoiceUtils.matchesInvoiceMonth(Data_Array.fromFoldable(Data_Set.foldableSet)(v1.value0.filteredMonths)));
                        return function ($256) {
                            return $255((function (v12) {
                                return v12.invoiceLines;
                            })($256));
                        };
                    })());
                };
                throw new Error("Failed pattern match at InvoicesContainer (line 358, column 34 - line 360, column 124): " + [ v11.constructor.name ]);
            })();
            return filterBySelectedMonths(textFilteredPurchaseInvoices);
        })();
        var filteredItemsCount = (function () {
            if (v1.value0.groupBySupplier && !v1.value0.groupByInvoiceBunchId) {
                return Data_Array.length(filteredInvoiceLinesGroupedBySupplier);
            };
            if (!v1.value0.groupBySupplier && v1.value0.groupByInvoiceBunchId) {
                return Data_Array.length(filteredInvoiceLinesGroupedByInvoiceBunchId);
            };
            if (v1.value0.groupBySupplier && v1.value0.groupByInvoiceBunchId) {
                return Data_Array.length(filteredInvoiceLinesGroupedBySupplier) + Data_Array.length(filteredInvoiceLinesGroupedByInvoiceBunchId) | 0;
            };
            if (!v1.value0.groupBySupplier && !v1.value0.groupByInvoiceBunchId) {
                return Data_Array.length(filteredPurchaseInvoices);
            };
            throw new Error("Failed pattern match at InvoicesContainer (line 297, column 11 - line 301, column 66): " + [ v1.value0.groupBySupplier.constructor.name, v1.value0.groupByInvoiceBunchId.constructor.name ]);
        })();
        var visibleRowsCount = (function () {
            if (v1.value0.visibleRows instanceof Limited) {
                return Data_Ord.min(Data_Ord.ordInt)(v1.value0.visibleRows.value0)(filteredItemsCount);
            };
            if (v1.value0.visibleRows instanceof All) {
                return filteredItemsCount;
            };
            throw new Error("Failed pattern match at InvoicesContainer (line 292, column 24 - line 294, column 32): " + [ v1.value0.visibleRows.constructor.name ]);
        })();
        var visibleResultsControl = (function () {
            var showAllBtn = (function () {
                if (v1.value0.visibleRows instanceof Limited && v1.value0.visibleRows.value0 < filteredItemsCount) {
                    return TacoButton.component()()({
                        text: "N\xe4yt\xe4 kaikki " + Data_Show.show(Data_Show.showInt)(filteredItemsCount),
                        onClick: Data_Maybe.Just.create(setVisibleRows(All.value)),
                        testId: "show-all-invoice-results-button"
                    });
                };
                return React_Basic.empty;
            })();
            var visibleRowsAfterShowingMore = (function () {
                if (v1.value0.visibleRows instanceof Limited && (v1.value0.visibleRows.value0 + visibleRowsIncrement | 0) < filteredItemsCount) {
                    return new Limited(v1.value0.visibleRows.value0 + visibleRowsIncrement | 0);
                };
                return All.value;
            })();
            var showMoreBtn = (function () {
                if (v1.value0.visibleRows instanceof Limited && (v1.value0.visibleRows.value0 + visibleRowsIncrement | 0) < filteredItemsCount) {
                    return TacoButton.component()()({
                        text: "N\xe4yt\xe4 " + (Data_Show.show(Data_Show.showInt)(visibleRowsIncrement) + " lis\xe4\xe4"),
                        onClick: Data_Maybe.Just.create(setVisibleRows(visibleRowsAfterShowingMore)),
                        buttonStyle: TacoButton_Types.Outlined.value
                    });
                };
                return React_Basic.empty;
            })();
            var currentResultsCountLabel = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                className: "result-count",
                css: Invoices_Styles.resultCountStyles,
                children: [ TacoText.component()()({
                    text: "N\xe4ytet\xe4\xe4n " + (Data_Show.show(Data_Show.showInt)(visibleRowsCount) + " tulosta"),
                    color: TacoConstants.Gray.value,
                    align: TacoText_Types.Center.value,
                    variant: new TacoText_Types.Heading(5)
                }) ]
            });
            return React_Basic_DOM_Generated.div()({
                className: "visible-results-control",
                children: [ currentResultsCountLabel, showMoreBtn, showAllBtn ]
            });
        })();
        var invoiceData = (function () {
            if (v1.value0.groupBySupplier && !v1.value0.groupByInvoiceBunchId) {
                return InvoiceUtils.InvoiceLinesBySupplier.create(limitResults(filteredInvoiceLinesGroupedBySupplier));
            };
            if (!v1.value0.groupBySupplier && v1.value0.groupByInvoiceBunchId) {
                return InvoiceUtils.InvoiceLinesByInvoiceBunchId.create(limitResults(filteredInvoiceLinesGroupedByInvoiceBunchId));
            };
            if (v1.value0.groupBySupplier && v1.value0.groupByInvoiceBunchId) {
                return InvoiceUtils.InvoiceLinesByInvoice.create(limitResults(filteredPurchaseInvoices));
            };
            if (!v1.value0.groupBySupplier && !v1.value0.groupByInvoiceBunchId) {
                return InvoiceUtils.InvoiceLinesByInvoice.create(limitResults(filteredPurchaseInvoices));
            };
            throw new Error("Failed pattern match at InvoicesContainer (line 281, column 10 - line 285, column 101): " + [ v1.value0.groupBySupplier.constructor.name, v1.value0.groupByInvoiceBunchId.constructor.name ]);
        })();
        var invoiceSelectionCheckbox = (function () {
            var toggleAll = (function () {
                if (selectedInvoiceLinesCount === 0) {
                    return addInvoiceLineIds(Data_Array.concatMap(Data_Functor.map(Data_Functor.functorArray)(function (v11) {
                        return v11.id;
                    }))((function () {
                        if (invoiceData instanceof InvoiceUtils.InvoiceLinesBySupplier) {
                            return Data_Functor.map(Data_Functor.functorArray)(function (v11) {
                                return v11.invoiceLines;
                            })(invoiceData.value0);
                        };
                        if (invoiceData instanceof InvoiceUtils.InvoiceLinesByInvoice) {
                            return Data_Functor.map(Data_Functor.functorArray)(function (v11) {
                                return v11.invoiceLines;
                            })(invoiceData.value0);
                        };
                        if (invoiceData instanceof InvoiceUtils.InvoiceLinesByInvoiceBunchId) {
                            return Data_Functor.map(Data_Functor.functorArray)(function (v11) {
                                return v11.invoiceLines;
                            })(invoiceData.value0);
                        };
                        throw new Error("Failed pattern match at InvoicesContainer (line 419, column 44 - line 422, column 128): " + [ invoiceData.constructor.name ]);
                    })()));
                };
                return clearSelectedInvoiceLineIds;
            })();
            var labelText = Data_Maybe.Just.create(TacoCheckbox.StringLabel.create((function () {
                if (selectedInvoicesCount === 0 && selectedInvoiceLinesCount === 0) {
                    return "Valitse";
                };
                if (selectedInvoicesCount === 1 && selectedInvoiceLinesCount === 1) {
                    return "1 lasku (1 rivi) valittuna";
                };
                if (selectedInvoicesCount === 1) {
                    return "1 lasku (" + (Data_Show.show(Data_Show.showInt)(selectedInvoiceLinesCount) + " rivi\xe4) valittuna");
                };
                return Data_Show.show(Data_Show.showInt)(selectedInvoicesCount) + (" laskua (" + (Data_Show.show(Data_Show.showInt)(selectedInvoiceLinesCount) + " rivi\xe4) valittuna"));
            })()));
            return TacoCheckbox.component()()({
                className: "toggle-all-selected-invoices",
                isChecked: selectedInvoiceLinesCount > 0,
                onToggle: new TacoCheckbox.NoEvent(toggleAll),
                labelText: labelText,
                testId: "toggle-all-selected-invoices"
            });
        })();
        var summaryRow = (function () {
            var totalItemsCount = (function () {
                if (v1.value0.groupBySupplier) {
                    return Data_Array.length(invoiceLinesFilteredByMonthGroupedBySupplier);
                };
                return Data_Array.length(allPurchaseInvoices);
            })();
            var renderSummary = function (label) {
                return function (value) {
                    return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                        className: "summary",
                        css: Invoices_Styles.summaryStyle,
                        children: [ TacoText.component()()({
                            text: label
                        }), TacoText.component()()({
                            weight: TacoText_Types.Bold.value,
                            text: value
                        }) ]
                    });
                };
            };
            var totalItemsSummary = (function () {
                var value = Formatters.formatCurrencyValue("EUR")(Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v11) {
                    return v11.value;
                })(invoiceLinesWithSocialExpenses)));
                var label = "Kaikki " + (Data_Show.show(Data_Show.showInt)(totalItemsCount) + " yhteens\xe4:");
                return React_Basic_DOM_Generated.div()({
                    className: "total-summary",
                    children: Control_Applicative.pure(Control_Applicative.applicativeArray)(renderSummary(label)(value))
                });
            })();
            var filteredItemsValue = (function () {
                if (v1.value0.groupBySupplier) {
                    if (v1.value0.groupByInvoiceBunchId) {
                        return Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v11) {
                            return v11.value;
                        })(Data_Array.concatMap(function (v11) {
                            return v11.invoiceLines;
                        })(filteredInvoiceLinesGroupedByInvoiceBunchId)));
                    };
                    return Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v11) {
                        return v11.value;
                    })(Data_Array.concatMap(function (v11) {
                        return v11.invoiceLines;
                    })(filteredInvoiceLinesGroupedBySupplier)));
                };
                return Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v11) {
                    return v11.value;
                })(Data_Array.concatMap(function (v11) {
                    return v11.invoiceLines;
                })(filteredPurchaseInvoices)));
            })();
            var filteredItemsSummary = (function () {
                var value = Formatters.formatCurrencyValue("EUR")(filteredItemsValue);
                var label = "Haetut " + (Data_Show.show(Data_Show.showInt)(filteredItemsCount) + " yhteens\xe4:");
                return React_Basic_DOM_Generated.div()({
                    className: "filtered-summary",
                    children: Control_Applicative.pure(Control_Applicative.applicativeArray)(renderSummary(label)(value))
                });
            })();
            var summaryContainer = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                className: "summary-container",
                css: Invoices_Styles.summaryContainerStyles,
                children: (function () {
                    var $207 = filteredItemsCount < totalItemsCount;
                    if ($207) {
                        return [ totalItemsSummary, filteredItemsSummary ];
                    };
                    return [ totalItemsSummary ];
                })()
            });
            return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                className: "invoices-summary",
                css: Invoices_Styles.invoicesSummaryStyles,
                children: [ summaryContainer ]
            });
        })();
        var addInvoiceBtn = function (v11) {
            return TacoButton.component()()({
                icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconAddPlusV1.value),
                onClick: new Data_Maybe.Just(toggleAddInvoiceView),
                text: Locale.lookup_("new_invoice")
            });
        };
        var addInvoice = (function () {
            if (maybeProject instanceof Data_Maybe.Just) {
                return AddInvoice.component({
                    dispatch: appDispatch,
                    projectId: v.projectId,
                    projectCode: maybeProject.value0.code,
                    suppliers: v7.value0,
                    costClasses: v8.value0,
                    toggleView: toggleAddInvoiceView,
                    isActive: v1.value0.isAddInvoiceViewVisible
                });
            };
            if (maybeProject instanceof Data_Maybe.Nothing) {
                return React_Basic.empty;
            };
            throw new Error("Failed pattern match at InvoicesContainer (line 805, column 18 - line 815, column 26): " + [ maybeProject.constructor.name ]);
        })();
        var actionsItems = [ checkboxBySupplier, Data_Monoid.guard(React_Basic.monoidJSX)(groupWithInvoiceBunchIdEnabled)(checkboxByInvoiceBunchId) ];
        var sections = [ {
            title: new Data_Maybe.Just("Ryhmittele"),
            items: actionsItems
        }, {
            title: new Data_Maybe.Just("N\xe4yt\xe4"),
            items: [ checkboxShowInvoiceBunchIds, checkboxShowSupplierInvoiceNumbers, checkboxShowQuantityColumn, checkboxShowUnitColumn, checkboxShowUnitPriceColumn ]
        }, {
            title: new Data_Maybe.Just("Tuonti"),
            items: [ uploadSalaries({
                projectId: v.projectId
            }), templateDownloadLink ]
        } ];
        var actions = React_Basic_DOM_Generated.div()({
            className: "action-buttons",
            children: Control_Applicative.pure(Control_Applicative.applicativeArray)(TacoDropdown.uncontrolled({
                sections: sections,
                align: TacoDropdown_Types.AlignRight.value,
                testId: "invoices-actions-dropdown"
            }))
        });
        var invoiceSelectionActions = (function () {
            if (selectedInvoiceLinesCount === 0) {
                return [ addInvoiceBtn({
                    handleShowAddInvoice: Data_Unit.unit
                }), actions ];
            };
            return [ downloadButtons, editCostGroupControl, editInvoiceBunchControl, deleteInvoicesBtn({
                clearSelectedInvoiceLines: clearSelectedInvoiceLineIds,
                projectId: v.projectId,
                ids: v1.value0.selectedInvoiceLineIds,
                confirmDeleteInvoices: v9.value0,
                setConfirmDeleteInvoices: v9.value1
            }) ];
        })();
        var invoiceSelectionControls = Controls.component()()({
            leftControls: [ invoiceSelectionCheckbox ],
            rightControls: invoiceSelectionActions,
            bottomGutter: true
        });
        return NarrowLayout.component()()({
            children: [ filterControls, InvoiceMonthlyFilterChart.invoiceMonthlyFilterChart({
                allInvoices: allPurchaseInvoices,
                filteredInvoices: textFilteredPurchaseInvoices,
                selectedMonths: v1.value0.filteredMonths,
                onMonthsSelect: setFilteredMonths
            }), invoiceSelectionControls, addInvoice, (function () {
                if (loading) {
                    return TacoLoader.component()()({});
                };
                return InvoicesTable.invoicesTable({
                    invoiceData: invoiceData,
                    projectId: v.projectId,
                    selectedInvoiceLineIds: v1.value0.selectedInvoiceLineIds,
                    onInvoiceLinesSelect: addInvoiceLineIds,
                    onInvoiceLinesDeselect: removeInvoiceLineIds,
                    sortingOrder: v1.value0.sortingOrder,
                    sortProperty: v1.value0.sortProperty,
                    onSort: setSortingCriteria,
                    showInvoiceBunchIds: v1.value0.showInvoiceBunchIds,
                    showSupplierInvoiceNumbers: v1.value0.showSupplierInvoiceNumbers,
                    showCheckboxes: true,
                    showQuantityColumn: v1.value0.showQuantityColumn,
                    showUnitColumn: v1.value0.showUnitColumn,
                    showUnitPriceColumn: v1.value0.showUnitPriceColumn,
                    filteredRows: filteredPurchaseInvoices
                });
            })(), visibleResultsControl, summaryRow ],
            verticalPadding: true
        });
    };
});
module.exports = {
    component: component
};
