// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var TacoButton_Styles = require("../TacoButton.Styles/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var Checkbox = (function () {
    function Checkbox() {

    };
    Checkbox.value = new Checkbox();
    return Checkbox;
})();
var RadioButton = (function () {
    function RadioButton() {

    };
    RadioButton.value = new RadioButton();
    return RadioButton;
})();
var labelStyles = function (v) {
    return Box.boxStyle([ new Box.PaddingLeft(TacoConstants.XS.value), new Box.Cursor((function () {
        if (v.isDisabled) {
            return Box.NotAllowed.value;
        };
        return Box.Pointer.value;
    })()), Box.Style.create(React_Basic_Emotion.css()({
        lineHeight: React_Basic_Emotion.str("1.2rem")
    })) ]);
};
var checkboxStyles = function (v) {
    var styleDependentStyles = (function () {
        if (v.style instanceof Checkbox) {
            return [ Box.BorderRadius.create(new TacoConstants.CustomSize("3px")), Data_Monoid.guard(Data_Monoid.monoidFn(Box.monoidBoxProp))(!v.isChecked)(Box.Border.create)(new TacoConstants.BorderCustom(TacoConstants.GrayDark.value)), Data_Monoid.guard(Data_Monoid.monoidFn(Box.monoidBoxProp))(!v.isChecked)(Box.Hover.create)([ Box.Border.create(new TacoConstants.BorderCustom(TacoConstants.PrimaryBlue.value)) ]) ];
        };
        if (v.style instanceof RadioButton) {
            return [ Box.BorderRadius.create(new TacoConstants.CustomSize("100%")), Data_Monoid.guard(Data_Monoid.monoidFn(Box.monoidBoxProp))(!v.isChecked)(Box.Border.create)(new TacoConstants.BorderCustom(TacoConstants.GrayDark.value)), Data_Monoid.guard(Data_Monoid.monoidFn(Box.monoidBoxProp))(!v.isChecked)(Box.Hover.create)([ Box.Border.create(new TacoConstants.BorderCustom(TacoConstants.PrimaryBlue.value)) ]), Data_Monoid.guard(Data_Monoid.monoidFn(Box.monoidBoxProp))(v.isChecked)(Box.BackgroundColor.create)(TacoConstants.PrimaryBlue.value) ];
        };
        throw new Error("Failed pattern match at TacoCheckbox.Styles (line 64, column 28 - line 75, column 10): " + [ v.style.constructor.name ]);
    })();
    return Box.boxStyle(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ new Box.Width(TacoConstants.M.value), new Box.Height(TacoConstants.M.value), new Box.Transition([ "border-color" ]), Data_Monoid.guard(Data_Monoid.monoidFn(Box.monoidBoxProp))(!v.isChecked)(Box.Border.create)(new TacoConstants.BorderCustom(TacoConstants.GrayDark.value)), Data_Monoid.guard(Data_Monoid.monoidFn(Box.monoidBoxProp))(!v.isChecked)(Box.Hover.create)([ Box.Border.create(new TacoConstants.BorderCustom(TacoConstants.PrimaryBlue.value)) ]) ])(styleDependentStyles));
};
var checkboxContainerStyles = function (v) {
    var styleDependentStyles = (function () {
        if (v.style instanceof Checkbox) {
            return [  ];
        };
        if (v.style instanceof RadioButton) {
            return [ Data_Monoid.guard(Box.monoidBoxProp)(!v.isDisabled && v.isChecked)(Box.Selector.create(".checkbox")([ new Box.BackgroundColor(v.color) ])) ];
        };
        throw new Error("Failed pattern match at TacoCheckbox.Styles (line 47, column 28 - line 52, column 10): " + [ v.style.constructor.name ]);
    })();
    var buttonStyleProps = {
        align: TacoButton_Types.Left.value,
        buttonStyle: TacoButton_Types.Text.value,
        children: Data_Maybe.Nothing.value,
        color: v.color,
        disabled: v.isDisabled,
        text: "",
        size: TacoConstants.M.value,
        vertical: false,
        styles: [  ]
    };
    return Box.boxStyle(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ Box.Style.create(TacoButton_Styles.buttonStyles(buttonStyleProps)), Box.Style.create(React_Basic_Emotion.css()({
        border: React_Basic_Emotion.str("0")
    })), Box.Height.create(new TacoConstants.CustomSize("100%")), new Box.BackgroundColor(TacoConstants.Transparent.value), Data_Monoid.guard(Data_Monoid.monoidFn(Box.monoidBoxProp))(v.noPadding)(Box.Padding.create)(new TacoConstants.CustomSize("0")), Data_Monoid.guard(Data_Monoid.monoidFn(Data_Monoid.monoidFn(Box.monoidBoxProp)))(!v.isDisabled && !v.isChecked)(Box.Selector.create)("&:hover .checkbox")([ Box.Border.create(new TacoConstants.BorderCustom(v.color)) ]) ])(styleDependentStyles));
};
module.exports = {
    Checkbox: Checkbox,
    RadioButton: RadioButton,
    checkboxContainerStyles: checkboxContainerStyles,
    checkboxStyles: checkboxStyles,
    labelStyles: labelStyles
};
