// Generated by purs version 0.14.5
"use strict";
var Components_Utils = require("../Components.Utils/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var Record = require("../Record/index.js");
var TacoChip_Styles = require("../TacoChip.Styles/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon = require("../TacoIcon/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var UseUnfocusOnClick = require("../UseUnfocusOnClick/index.js");
var defaultProps = {
    contents: [  ],
    onRemove: Data_Maybe.Nothing.value,
    color: TacoConstants.PrimaryBlue.value,
    testId: "TacoChip"
};
var component = function (dictUnion) {
    return function (dictNub) {
        return TofuHooks.mkHookComponent("TacoChip")(function (props) {
            var v = Record.merge()()(props)(defaultProps);
            return function __do() {
                var v1 = UseUnfocusOnClick.useUnfocusOnClick();
                var removable = Data_Maybe.isJust(v.onRemove);
                var cross = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                    className: "cross",
                    css: TacoChip_Styles.crossStyles,
                    children: [ TacoIcon.component()()()()({
                        icon: TacoIcon_Icons_Types.IconCloseCrossV1.value,
                        size: TacoConstants.XS.value,
                        color: TacoConstants.PrimaryBlue.value
                    }) ]
                });
                var children = Data_Array.snoc(v.contents)(Data_Monoid.guard(React_Basic.monoidJSX)(removable)(cross));
                var commonProps = {
                    children: children,
                    className: "__TacoChip",
                    css: TacoChip_Styles.tacoChipStyles(v),
                    "_data": Components_Utils.mkTestIdAttrs(v.testId),
                    ref: v1.ref
                };
                var container = (function () {
                    if (v.onRemove instanceof Data_Maybe.Just) {
                        return React_Basic_Emotion.element(React_Basic_DOM_Generated["button'"]())(Record.merge()()(commonProps)({
                            onClick: React_Basic_DOM_Events.capture_(function __do() {
                                v.onRemove.value0();
                                return v1.unfocus();
                            }),
                            type: "button"
                        }));
                    };
                    if (v.onRemove instanceof Data_Maybe.Nothing) {
                        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())(commonProps);
                    };
                    throw new Error("Failed pattern match at TacoChip (line 68, column 17 - line 77, column 37): " + [ v.onRemove.constructor.name ]);
                })();
                return container;
            };
        });
    };
};
module.exports = {
    component: component
};
