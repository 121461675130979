// Generated by purs version 0.14.5
"use strict";
var Control_Apply = require("../Control.Apply/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Effect = require("../Effect/index.js");
var HtmlUtils = require("../HtmlUtils/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Web_DOM_Element = require("../Web.DOM.Element/index.js");
var Web_HTML_HTMLElement = require("../Web.HTML.HTMLElement/index.js");
var useSelectedComponentScrollPosition = function (shouldScrollToSelection) {
    return function (rowHeight$prime) {
        return function (selectedComponentIdx) {
            return function (tableRef) {
                return TofuHooks.useLayoutEffect([ ReactHooksUtils.utf(selectedComponentIdx), ReactHooksUtils.utf(shouldScrollToSelection) ])(Data_Monoid.guard(Effect.monoidEffect(Effect.monoidEffect(Data_Monoid.monoidUnit)))(shouldScrollToSelection)(function __do() {
                    var mElement = HtmlUtils.readRefMaybeElement(tableRef)();
                    var maybeRect = Data_Functor.map(Data_Maybe.functorMaybe)(Web_HTML_HTMLElement.getBoundingClientRect)(mElement);
                    var maybeOffset = Data_Functor.map(Data_Maybe.functorMaybe)(Data_Functor.map(Data_Functor.functorFn)(Data_Int.toNumber)(function (v) {
                        return v * rowHeight$prime | 0;
                    }))(selectedComponentIdx);
                    var r = Control_Apply.apply(Data_Maybe.applyMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
                        return function (v1) {
                            return {
                                offset: v,
                                domRect: v1
                            };
                        };
                    })(maybeOffset))(maybeRect);
                    ReactHooksUtils.performRefElementEffect(tableRef)(function (element) {
                        return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(r)(function (v) {
                            return function __do() {
                                var dim = v.domRect();
                                var currentScrollTop = Web_DOM_Element.scrollTop(Web_HTML_HTMLElement.toElement(element))();
                                var scrollPadding = Data_Int.toNumber(rowHeight$prime) * 2.0;
                                var clampLow = (v.offset - dim.height) + 40.0 + Data_Int.toNumber(rowHeight$prime) + scrollPadding;
                                var clampHigh = v.offset - scrollPadding;
                                var st = Data_Ord.clamp(Data_Ord.ordNumber)(clampLow)(clampHigh)(currentScrollTop);
                                return Web_DOM_Element.setScrollTop(st)(Web_HTML_HTMLElement.toElement(element))();
                            };
                        });
                    })();
                    return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                }));
            };
        };
    };
};
module.exports = {
    useSelectedComponentScrollPosition: useSelectedComponentScrollPosition
};
