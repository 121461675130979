// Generated by purs version 0.14.5
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Kishimen = require("../Kishimen/index.js");
var TacoFilter = require("../TacoFilter/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_Project = require("../Types.Project/index.js");
var FilterByOrgResourcePriceName = (function () {
    function FilterByOrgResourcePriceName(value0) {
        this.value0 = value0;
    };
    FilterByOrgResourcePriceName.create = function (value0) {
        return new FilterByOrgResourcePriceName(value0);
    };
    return FilterByOrgResourcePriceName;
})();
var FilterByOrgResourcePricePriceListName = (function () {
    function FilterByOrgResourcePricePriceListName(value0) {
        this.value0 = value0;
    };
    FilterByOrgResourcePricePriceListName.create = function (value0) {
        return new FilterByOrgResourcePricePriceListName(value0);
    };
    return FilterByOrgResourcePricePriceListName;
})();
var FilterByOrgResourcePriceGroup = (function () {
    function FilterByOrgResourcePriceGroup(value0) {
        this.value0 = value0;
    };
    FilterByOrgResourcePriceGroup.create = function (value0) {
        return new FilterByOrgResourcePriceGroup(value0);
    };
    return FilterByOrgResourcePriceGroup;
})();
var FilterByOrgResourcePriceVendor = (function () {
    function FilterByOrgResourcePriceVendor(value0) {
        this.value0 = value0;
    };
    FilterByOrgResourcePriceVendor.create = function (value0) {
        return new FilterByOrgResourcePriceVendor(value0);
    };
    return FilterByOrgResourcePriceVendor;
})();
var FilterByOrgResourcePriceCostClassCode = (function () {
    function FilterByOrgResourcePriceCostClassCode(value0) {
        this.value0 = value0;
    };
    FilterByOrgResourcePriceCostClassCode.create = function (value0) {
        return new FilterByOrgResourcePriceCostClassCode(value0);
    };
    return FilterByOrgResourcePriceCostClassCode;
})();
var FilterByComponentGroup = (function () {
    function FilterByComponentGroup(value0) {
        this.value0 = value0;
    };
    FilterByComponentGroup.create = function (value0) {
        return new FilterByComponentGroup(value0);
    };
    return FilterByComponentGroup;
})();
var FilterByComponentWithoutGroup = (function () {
    function FilterByComponentWithoutGroup(value0) {
        this.value0 = value0;
    };
    FilterByComponentWithoutGroup.create = function (value0) {
        return new FilterByComponentWithoutGroup(value0);
    };
    return FilterByComponentWithoutGroup;
})();
var FilterByComponentCostGroupCode = (function () {
    function FilterByComponentCostGroupCode(value0) {
        this.value0 = value0;
    };
    FilterByComponentCostGroupCode.create = function (value0) {
        return new FilterByComponentCostGroupCode(value0);
    };
    return FilterByComponentCostGroupCode;
})();
var FilterByComponentCode = (function () {
    function FilterByComponentCode(value0) {
        this.value0 = value0;
    };
    FilterByComponentCode.create = function (value0) {
        return new FilterByComponentCode(value0);
    };
    return FilterByComponentCode;
})();
var FilterByComponentDescription = (function () {
    function FilterByComponentDescription(value0) {
        this.value0 = value0;
    };
    FilterByComponentDescription.create = function (value0) {
        return new FilterByComponentDescription(value0);
    };
    return FilterByComponentDescription;
})();
var FilterByComponentElement = (function () {
    function FilterByComponentElement(value0) {
        this.value0 = value0;
    };
    FilterByComponentElement.create = function (value0) {
        return new FilterByComponentElement(value0);
    };
    return FilterByComponentElement;
})();
var FilterByComponentSubProjectCode = (function () {
    function FilterByComponentSubProjectCode(value0) {
        this.value0 = value0;
    };
    FilterByComponentSubProjectCode.create = function (value0) {
        return new FilterByComponentSubProjectCode(value0);
    };
    return FilterByComponentSubProjectCode;
})();
var FilterByComponentProjectOfOrigin = (function () {
    function FilterByComponentProjectOfOrigin(value0) {
        this.value0 = value0;
    };
    FilterByComponentProjectOfOrigin.create = function (value0) {
        return new FilterByComponentProjectOfOrigin(value0);
    };
    return FilterByComponentProjectOfOrigin;
})();
var FilterByComponentLocationCode = (function () {
    function FilterByComponentLocationCode(value0) {
        this.value0 = value0;
    };
    FilterByComponentLocationCode.create = function (value0) {
        return new FilterByComponentLocationCode(value0);
    };
    return FilterByComponentLocationCode;
})();
var FilterByComponentSelected = (function () {
    function FilterByComponentSelected(value0) {
        this.value0 = value0;
    };
    FilterByComponentSelected.create = function (value0) {
        return new FilterByComponentSelected(value0);
    };
    return FilterByComponentSelected;
})();
var FilterByComponentSearch = (function () {
    function FilterByComponentSearch(value0) {
        this.value0 = value0;
    };
    FilterByComponentSearch.create = function (value0) {
        return new FilterByComponentSearch(value0);
    };
    return FilterByComponentSearch;
})();
var FilterByComponentHasFlag = (function () {
    function FilterByComponentHasFlag(value0) {
        this.value0 = value0;
    };
    FilterByComponentHasFlag.create = function (value0) {
        return new FilterByComponentHasFlag(value0);
    };
    return FilterByComponentHasFlag;
})();
var FilterByComponentHasCheck = (function () {
    function FilterByComponentHasCheck(value0) {
        this.value0 = value0;
    };
    FilterByComponentHasCheck.create = function (value0) {
        return new FilterByComponentHasCheck(value0);
    };
    return FilterByComponentHasCheck;
})();
var FilterByComponentHasMemo = (function () {
    function FilterByComponentHasMemo(value0) {
        this.value0 = value0;
    };
    FilterByComponentHasMemo.create = function (value0) {
        return new FilterByComponentHasMemo(value0);
    };
    return FilterByComponentHasMemo;
})();
var FilterByComponentNoFlag = (function () {
    function FilterByComponentNoFlag(value0) {
        this.value0 = value0;
    };
    FilterByComponentNoFlag.create = function (value0) {
        return new FilterByComponentNoFlag(value0);
    };
    return FilterByComponentNoFlag;
})();
var FilterByComponentNoCheck = (function () {
    function FilterByComponentNoCheck(value0) {
        this.value0 = value0;
    };
    FilterByComponentNoCheck.create = function (value0) {
        return new FilterByComponentNoCheck(value0);
    };
    return FilterByComponentNoCheck;
})();
var FilterByComponentNoMemo = (function () {
    function FilterByComponentNoMemo(value0) {
        this.value0 = value0;
    };
    FilterByComponentNoMemo.create = function (value0) {
        return new FilterByComponentNoMemo(value0);
    };
    return FilterByComponentNoMemo;
})();
var FilterByComponentWithoutCostGroup = (function () {
    function FilterByComponentWithoutCostGroup(value0) {
        this.value0 = value0;
    };
    FilterByComponentWithoutCostGroup.create = function (value0) {
        return new FilterByComponentWithoutCostGroup(value0);
    };
    return FilterByComponentWithoutCostGroup;
})();
var FilterByComponentNoCodeMatch = (function () {
    function FilterByComponentNoCodeMatch(value0) {
        this.value0 = value0;
    };
    FilterByComponentNoCodeMatch.create = function (value0) {
        return new FilterByComponentNoCodeMatch(value0);
    };
    return FilterByComponentNoCodeMatch;
})();
var FilterByComponentNonMatchingCostGroup = (function () {
    function FilterByComponentNonMatchingCostGroup(value0) {
        this.value0 = value0;
    };
    FilterByComponentNonMatchingCostGroup.create = function (value0) {
        return new FilterByComponentNonMatchingCostGroup(value0);
    };
    return FilterByComponentNonMatchingCostGroup;
})();
var FilterByElementsDataSubProjectCode = (function () {
    function FilterByElementsDataSubProjectCode(value0) {
        this.value0 = value0;
    };
    FilterByElementsDataSubProjectCode.create = function (value0) {
        return new FilterByElementsDataSubProjectCode(value0);
    };
    return FilterByElementsDataSubProjectCode;
})();
var FilterByElementsDataLocationCode = (function () {
    function FilterByElementsDataLocationCode(value0) {
        this.value0 = value0;
    };
    FilterByElementsDataLocationCode.create = function (value0) {
        return new FilterByElementsDataLocationCode(value0);
    };
    return FilterByElementsDataLocationCode;
})();
var FilterByElementsDataGroup = (function () {
    function FilterByElementsDataGroup(value0) {
        this.value0 = value0;
    };
    FilterByElementsDataGroup.create = function (value0) {
        return new FilterByElementsDataGroup(value0);
    };
    return FilterByElementsDataGroup;
})();
var FilterByElementsWithoutDataGroup = (function () {
    function FilterByElementsWithoutDataGroup(value0) {
        this.value0 = value0;
    };
    FilterByElementsWithoutDataGroup.create = function (value0) {
        return new FilterByElementsWithoutDataGroup(value0);
    };
    return FilterByElementsWithoutDataGroup;
})();
var FilterByElementsDataElementCode = (function () {
    function FilterByElementsDataElementCode(value0) {
        this.value0 = value0;
    };
    FilterByElementsDataElementCode.create = function (value0) {
        return new FilterByElementsDataElementCode(value0);
    };
    return FilterByElementsDataElementCode;
})();
var FilterByElementsDataElementNoCodeMatch = (function () {
    function FilterByElementsDataElementNoCodeMatch(value0) {
        this.value0 = value0;
    };
    FilterByElementsDataElementNoCodeMatch.create = function (value0) {
        return new FilterByElementsDataElementNoCodeMatch(value0);
    };
    return FilterByElementsDataElementNoCodeMatch;
})();
var FilterByElementsDataElementRtCode = (function () {
    function FilterByElementsDataElementRtCode(value0) {
        this.value0 = value0;
    };
    FilterByElementsDataElementRtCode.create = function (value0) {
        return new FilterByElementsDataElementRtCode(value0);
    };
    return FilterByElementsDataElementRtCode;
})();
var FilterByElementsDataDescription = (function () {
    function FilterByElementsDataDescription(value0) {
        this.value0 = value0;
    };
    FilterByElementsDataDescription.create = function (value0) {
        return new FilterByElementsDataDescription(value0);
    };
    return FilterByElementsDataDescription;
})();
var FilterByElementsDataRtDescription = (function () {
    function FilterByElementsDataRtDescription(value0) {
        this.value0 = value0;
    };
    FilterByElementsDataRtDescription.create = function (value0) {
        return new FilterByElementsDataRtDescription(value0);
    };
    return FilterByElementsDataRtDescription;
})();
var FilterByElementsDataProjectOfOrigin = (function () {
    function FilterByElementsDataProjectOfOrigin(value0) {
        this.value0 = value0;
    };
    FilterByElementsDataProjectOfOrigin.create = function (value0) {
        return new FilterByElementsDataProjectOfOrigin(value0);
    };
    return FilterByElementsDataProjectOfOrigin;
})();
var FilterByElementsDataSearch = (function () {
    function FilterByElementsDataSearch(value0) {
        this.value0 = value0;
    };
    FilterByElementsDataSearch.create = function (value0) {
        return new FilterByElementsDataSearch(value0);
    };
    return FilterByElementsDataSearch;
})();
var mkEmptyEstimationUiState = function (projectId) {
    return {
        project: projectId,
        componentFilters: [  ],
        componentGroupProperty: Data_Maybe.Nothing.value,
        selectedComponentIdx: Data_Maybe.Nothing.value,
        referenceComponentFilters: [  ],
        selectedReferenceComponentIdx: Data_Maybe.Nothing.value,
        elementFilters: [  ],
        elementGroupProperty: Data_Maybe.Nothing.value,
        selectedElementIdx: Data_Maybe.Nothing.value,
        referenceElementFilters: [  ],
        selectedReferenceElementIdx: Data_Maybe.Nothing.value,
        referenceResourceFilters: [  ],
        selectedResourceId: Data_Maybe.Nothing.value
    };
};
var genericOrgResourcePricePropertyFilter = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new FilterByOrgResourcePriceName(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new FilterByOrgResourcePricePriceListName(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return new FilterByOrgResourcePriceGroup(x.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return new FilterByOrgResourcePriceVendor(x.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inr))) {
            return new FilterByOrgResourcePriceCostClassCode(x.value0.value0.value0.value0);
        };
        throw new Error("Failed pattern match at CostEstimation.Filters (line 65, column 1 - line 65, column 99): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof FilterByOrgResourcePriceName) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof FilterByOrgResourcePricePriceListName) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof FilterByOrgResourcePriceGroup) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
        };
        if (x instanceof FilterByOrgResourcePriceVendor) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))));
        };
        if (x instanceof FilterByOrgResourcePriceCostClassCode) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0))));
        };
        throw new Error("Failed pattern match at CostEstimation.Filters (line 65, column 1 - line 65, column 99): " + [ x.constructor.name ]);
    }
};
var genericEstimationComponentPropertyFilter = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new FilterByComponentGroup(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new FilterByComponentWithoutGroup(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return new FilterByComponentCostGroupCode(x.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return new FilterByComponentCode(x.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
            return new FilterByComponentDescription(x.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))) {
            return new FilterByComponentElement(x.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))) {
            return new FilterByComponentSubProjectCode(x.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))) {
            return new FilterByComponentProjectOfOrigin(x.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))))) {
            return new FilterByComponentLocationCode(x.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))))) {
            return new FilterByComponentSelected(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))))))) {
            return new FilterByComponentSearch(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))))))) {
            return new FilterByComponentHasFlag(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))))))))) {
            return new FilterByComponentHasCheck(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))))))))) {
            return new FilterByComponentHasMemo(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))))))))))) {
            return new FilterByComponentNoFlag(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))))))))))) {
            return new FilterByComponentNoCheck(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))))))))))))) {
            return new FilterByComponentNoMemo(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))))))))))))) {
            return new FilterByComponentWithoutCostGroup(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))))))))))))))) {
            return new FilterByComponentNoCodeMatch(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr)))))))))))))))))) {
            return new FilterByComponentNonMatchingCostGroup(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        throw new Error("Failed pattern match at CostEstimation.Filters (line 35, column 1 - line 35, column 105): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof FilterByComponentGroup) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof FilterByComponentWithoutGroup) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof FilterByComponentCostGroupCode) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
        };
        if (x instanceof FilterByComponentCode) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))));
        };
        if (x instanceof FilterByComponentDescription) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))));
        };
        if (x instanceof FilterByComponentElement) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))));
        };
        if (x instanceof FilterByComponentSubProjectCode) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))))));
        };
        if (x instanceof FilterByComponentProjectOfOrigin) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))))));
        };
        if (x instanceof FilterByComponentLocationCode) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))))))));
        };
        if (x instanceof FilterByComponentSelected) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))))))));
        };
        if (x instanceof FilterByComponentSearch) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))))))))));
        };
        if (x instanceof FilterByComponentHasFlag) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))))))))));
        };
        if (x instanceof FilterByComponentHasCheck) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))))))))))));
        };
        if (x instanceof FilterByComponentHasMemo) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))))))))))));
        };
        if (x instanceof FilterByComponentNoFlag) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))))))))))))));
        };
        if (x instanceof FilterByComponentNoCheck) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))))))))))))));
        };
        if (x instanceof FilterByComponentNoMemo) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))))))))))))))));
        };
        if (x instanceof FilterByComponentWithoutCostGroup) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))))))))))))))));
        };
        if (x instanceof FilterByComponentNoCodeMatch) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))))))))))))))))));
        };
        if (x instanceof FilterByComponentNonMatchingCostGroup) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0)))))))))))))))))));
        };
        throw new Error("Failed pattern match at CostEstimation.Filters (line 35, column 1 - line 35, column 105): " + [ x.constructor.name ]);
    }
};
var tofuJSONEstimationComponentPropertyFilter = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentCostGroupCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentDescription";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentElement";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentHasCheck";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentHasFlag";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentHasMemo";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentLocationCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentNoFlag";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentProjectOfOrigin";
        }
    })(TofuJSON.readArray(Types_Project.tofuJSONProjectId))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentSearch";
        }
    })(TofuJSON.readString)()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentSelected";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentSubProjectCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentWithoutGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantNil)))))))))))))))))))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentCostGroupCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentDescription";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentElement";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentHasCheck";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentHasFlag";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentHasMemo";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentLocationCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentNoFlag";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentProjectOfOrigin";
        }
    })(TofuJSON.readArray(Types_Project.tofuJSONProjectId))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentSearch";
        }
    })(TofuJSON.readString)()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentSelected";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentSubProjectCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentWithoutGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.nilWriteForeignVariant)))))))))))))))))))))(genericEstimationComponentPropertyFilter)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentGroup";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentWithoutGroup";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentCostGroupCode";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentCode";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentDescription";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentElement";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentSubProjectCode";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentProjectOfOrigin";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentLocationCode";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentSelected";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentSearch";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentHasFlag";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentHasCheck";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentHasMemo";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentNoFlag";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentNoFlag";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentHasMemo";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentHasCheck";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasMemo";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentHasFlag";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasCheck";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentSearch";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasCheck";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentSelected";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasCheck";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentLocationCode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSelected";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasCheck";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentProjectOfOrigin";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSelected";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasCheck";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentSubProjectCode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSelected";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentProjectOfOrigin";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasCheck";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentElement";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSubProjectCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSelected";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentProjectOfOrigin";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasCheck";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentDescription";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSubProjectCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSelected";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentProjectOfOrigin";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasCheck";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentElement";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentCode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSubProjectCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSelected";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentProjectOfOrigin";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasCheck";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentElement";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentDescription";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentCostGroupCode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSubProjectCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSelected";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentProjectOfOrigin";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasCheck";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentElement";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentDescription";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentCode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutGroup";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSubProjectCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSelected";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentProjectOfOrigin";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasCheck";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentElement";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentDescription";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentCostGroupCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentCode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentGroup";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSubProjectCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSelected";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentProjectOfOrigin";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasCheck";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentElement";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentDescription";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentCostGroupCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentCode";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericEstimationComponentPropertyFilter)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentGroup";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentWithoutGroup";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentCostGroupCode";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentCode";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentDescription";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentElement";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentSubProjectCode";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentProjectOfOrigin";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentLocationCode";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentSelected";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentSearch";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentHasFlag";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentHasCheck";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentHasMemo";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentNoFlag";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentNoFlag";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentHasMemo";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentHasCheck";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasMemo";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentHasFlag";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasCheck";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentSearch";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasCheck";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentSelected";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasCheck";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentLocationCode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSelected";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasCheck";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentProjectOfOrigin";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSelected";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasCheck";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentSubProjectCode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSelected";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentProjectOfOrigin";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasCheck";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentElement";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSubProjectCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSelected";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentProjectOfOrigin";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasCheck";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentDescription";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSubProjectCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSelected";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentProjectOfOrigin";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasCheck";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentElement";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentCode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSubProjectCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSelected";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentProjectOfOrigin";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasCheck";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentElement";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentDescription";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentCostGroupCode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSubProjectCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSelected";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentProjectOfOrigin";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasCheck";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentElement";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentDescription";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentCode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutGroup";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSubProjectCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSelected";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentProjectOfOrigin";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasCheck";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentElement";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentDescription";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentCostGroupCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentCode";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentGroup";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByComponentWithoutGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSubProjectCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSelected";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentProjectOfOrigin";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasMemo";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasFlag";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentHasCheck";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentElement";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentDescription";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentCostGroupCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByComponentCode";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentCostGroupCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentDescription";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentElement";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentHasCheck";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentHasFlag";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentHasMemo";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentLocationCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentNoFlag";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentProjectOfOrigin";
        }
    })(TofuJSON.readArray(Types_Project.tofuJSONProjectId))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentSearch";
        }
    })(TofuJSON.readString)()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentSelected";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentSubProjectCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByComponentWithoutGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantNil)))))))))))))))))))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentCostGroupCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentDescription";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentElement";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentHasCheck";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentHasFlag";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentHasMemo";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentLocationCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentNoCheck";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentNoCodeMatch";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentNoFlag";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentNoMemo";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentNonMatchingCostGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentProjectOfOrigin";
        }
    })(TofuJSON.readArray(Types_Project.tofuJSONProjectId))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentSearch";
        }
    })(TofuJSON.readString)()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentSelected";
        }
    })(TofuJSON.readArray(TofuJSON.readInt))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentSubProjectCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentWithoutCostGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByComponentWithoutGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.nilWriteForeignVariant)))))))))))))))))))))
};
var genericElementsDataPropertyFilter = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new FilterByElementsDataSubProjectCode(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new FilterByElementsDataLocationCode(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return new FilterByElementsDataGroup(x.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return new FilterByElementsWithoutDataGroup(x.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
            return new FilterByElementsDataElementCode(x.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))) {
            return new FilterByElementsDataElementNoCodeMatch(x.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))) {
            return new FilterByElementsDataElementRtCode(x.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))) {
            return new FilterByElementsDataDescription(x.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))))) {
            return new FilterByElementsDataRtDescription(x.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))))) {
            return new FilterByElementsDataProjectOfOrigin(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr))))))))) {
            return new FilterByElementsDataSearch(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        throw new Error("Failed pattern match at CostEstimation.Filters (line 53, column 1 - line 53, column 91): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof FilterByElementsDataSubProjectCode) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof FilterByElementsDataLocationCode) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof FilterByElementsDataGroup) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
        };
        if (x instanceof FilterByElementsWithoutDataGroup) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))));
        };
        if (x instanceof FilterByElementsDataElementCode) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))));
        };
        if (x instanceof FilterByElementsDataElementNoCodeMatch) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))));
        };
        if (x instanceof FilterByElementsDataElementRtCode) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))))));
        };
        if (x instanceof FilterByElementsDataDescription) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))))));
        };
        if (x instanceof FilterByElementsDataRtDescription) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))))))));
        };
        if (x instanceof FilterByElementsDataProjectOfOrigin) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))))))));
        };
        if (x instanceof FilterByElementsDataSearch) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0))))))))));
        };
        throw new Error("Failed pattern match at CostEstimation.Filters (line 53, column 1 - line 53, column 91): " + [ x.constructor.name ]);
    }
};
var tofuJSONElementsDataPropertyFilter = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByElementsDataDescription";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByElementsDataElementCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByElementsDataElementNoCodeMatch";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByElementsDataElementRtCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByElementsDataGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByElementsDataLocationCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByElementsDataProjectOfOrigin";
        }
    })(TofuJSON.readArray(Types_Project.tofuJSONProjectId))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByElementsDataRtDescription";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByElementsDataSearch";
        }
    })(TofuJSON.readString)()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByElementsDataSubProjectCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByElementsWithoutDataGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantNil))))))))))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByElementsDataDescription";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByElementsDataElementCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByElementsDataElementNoCodeMatch";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByElementsDataElementRtCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByElementsDataGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByElementsDataLocationCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByElementsDataProjectOfOrigin";
        }
    })(TofuJSON.readArray(Types_Project.tofuJSONProjectId))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByElementsDataRtDescription";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByElementsDataSearch";
        }
    })(TofuJSON.readString)()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByElementsDataSubProjectCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByElementsWithoutDataGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.nilWriteForeignVariant))))))))))))(genericElementsDataPropertyFilter)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByElementsDataSubProjectCode";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByElementsDataLocationCode";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByElementsDataGroup";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByElementsWithoutDataGroup";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByElementsDataElementCode";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByElementsDataElementNoCodeMatch";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByElementsDataElementRtCode";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByElementsDataDescription";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByElementsDataRtDescription";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByElementsDataProjectOfOrigin";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByElementsDataSearch";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsDataProjectOfOrigin";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsDataSearch";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsDataRtDescription";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsDataSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataProjectOfOrigin";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsDataDescription";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsDataSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataRtDescription";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataProjectOfOrigin";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsDataElementRtCode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsDataSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataRtDescription";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataProjectOfOrigin";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataDescription";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsDataElementNoCodeMatch";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsDataSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataRtDescription";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataProjectOfOrigin";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataElementRtCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataDescription";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsDataElementCode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsDataSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataRtDescription";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataProjectOfOrigin";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataElementRtCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataElementNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataDescription";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsWithoutDataGroup";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsDataSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataRtDescription";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataProjectOfOrigin";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataElementRtCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataElementNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataElementCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataDescription";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsDataGroup";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsWithoutDataGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataRtDescription";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataProjectOfOrigin";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataElementRtCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataElementNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataElementCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataDescription";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsDataLocationCode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsWithoutDataGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataRtDescription";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataProjectOfOrigin";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataElementRtCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataElementNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataElementCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataDescription";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsDataSubProjectCode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsWithoutDataGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataRtDescription";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataProjectOfOrigin";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataElementRtCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataElementNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataElementCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataDescription";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericElementsDataPropertyFilter)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByElementsDataSubProjectCode";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByElementsDataLocationCode";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByElementsDataGroup";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByElementsWithoutDataGroup";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByElementsDataElementCode";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByElementsDataElementNoCodeMatch";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByElementsDataElementRtCode";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByElementsDataDescription";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByElementsDataRtDescription";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByElementsDataProjectOfOrigin";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "FilterByElementsDataSearch";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsDataProjectOfOrigin";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsDataSearch";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsDataRtDescription";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsDataSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataProjectOfOrigin";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsDataDescription";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsDataSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataRtDescription";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataProjectOfOrigin";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsDataElementRtCode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsDataSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataRtDescription";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataProjectOfOrigin";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataDescription";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsDataElementNoCodeMatch";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsDataSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataRtDescription";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataProjectOfOrigin";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataElementRtCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataDescription";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsDataElementCode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsDataSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataRtDescription";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataProjectOfOrigin";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataElementRtCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataElementNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataDescription";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsWithoutDataGroup";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsDataSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataRtDescription";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataProjectOfOrigin";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataElementRtCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataElementNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataElementCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataDescription";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsDataGroup";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsWithoutDataGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataRtDescription";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataProjectOfOrigin";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataElementRtCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataElementNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataElementCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataDescription";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsDataLocationCode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsWithoutDataGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataRtDescription";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataProjectOfOrigin";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataElementRtCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataElementNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataElementCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataDescription";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsDataSubProjectCode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "FilterByElementsWithoutDataGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataSearch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataRtDescription";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataProjectOfOrigin";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataLocationCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataGroup";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataElementRtCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataElementNoCodeMatch";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataElementCode";
        }
    }))({
        reflectSymbol: function () {
            return "FilterByElementsDataDescription";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByElementsDataDescription";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByElementsDataElementCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByElementsDataElementNoCodeMatch";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByElementsDataElementRtCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByElementsDataGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByElementsDataLocationCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByElementsDataProjectOfOrigin";
        }
    })(TofuJSON.readArray(Types_Project.tofuJSONProjectId))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByElementsDataRtDescription";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByElementsDataSearch";
        }
    })(TofuJSON.readString)()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByElementsDataSubProjectCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "FilterByElementsWithoutDataGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantNil))))))))))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByElementsDataDescription";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByElementsDataElementCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByElementsDataElementNoCodeMatch";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByElementsDataElementRtCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByElementsDataGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByElementsDataLocationCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByElementsDataProjectOfOrigin";
        }
    })(TofuJSON.readArray(Types_Project.tofuJSONProjectId))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByElementsDataRtDescription";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByElementsDataSearch";
        }
    })(TofuJSON.readString)()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByElementsDataSubProjectCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "FilterByElementsWithoutDataGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "state";
        }
    })(TacoFilter.tofuJSONFilterValuesState)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "values";
        }
    })(TofuJSON.readArray(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.nilWriteForeignVariant))))))))))))
};
var eqOrgResourcePricePropertyFilter = {
    eq: function (x) {
        return function (y) {
            if (x instanceof FilterByOrgResourcePriceName && y instanceof FilterByOrgResourcePriceName) {
                return Data_Eq.eq(TacoFilter.eqFilterValuesState)(x.value0.state)(y.value0.state) && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value0.values)(y.value0.values);
            };
            if (x instanceof FilterByOrgResourcePricePriceListName && y instanceof FilterByOrgResourcePricePriceListName) {
                return Data_Eq.eq(TacoFilter.eqFilterValuesState)(x.value0.state)(y.value0.state) && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value0.values)(y.value0.values);
            };
            if (x instanceof FilterByOrgResourcePriceGroup && y instanceof FilterByOrgResourcePriceGroup) {
                return Data_Eq.eq(TacoFilter.eqFilterValuesState)(x.value0.state)(y.value0.state) && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value0.values)(y.value0.values);
            };
            if (x instanceof FilterByOrgResourcePriceVendor && y instanceof FilterByOrgResourcePriceVendor) {
                return Data_Eq.eq(TacoFilter.eqFilterValuesState)(x.value0.state)(y.value0.state) && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value0.values)(y.value0.values);
            };
            if (x instanceof FilterByOrgResourcePriceCostClassCode && y instanceof FilterByOrgResourcePriceCostClassCode) {
                return Data_Eq.eq(TacoFilter.eqFilterValuesState)(x.value0.state)(y.value0.state) && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value0.values)(y.value0.values);
            };
            return false;
        };
    }
};
var eqEstimationComponentPropertyFilter = {
    eq: function (x) {
        return function (y) {
            if (x instanceof FilterByComponentGroup && y instanceof FilterByComponentGroup) {
                return Data_Eq.eq(TacoFilter.eqFilterValuesState)(x.value0.state)(y.value0.state) && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value0.values)(y.value0.values);
            };
            if (x instanceof FilterByComponentWithoutGroup && y instanceof FilterByComponentWithoutGroup) {
                return Data_Eq.eq(TacoFilter.eqFilterValuesState)(x.value0.state)(y.value0.state) && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value0.values)(y.value0.values);
            };
            if (x instanceof FilterByComponentCostGroupCode && y instanceof FilterByComponentCostGroupCode) {
                return Data_Eq.eq(TacoFilter.eqFilterValuesState)(x.value0.state)(y.value0.state) && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value0.values)(y.value0.values);
            };
            if (x instanceof FilterByComponentCode && y instanceof FilterByComponentCode) {
                return Data_Eq.eq(TacoFilter.eqFilterValuesState)(x.value0.state)(y.value0.state) && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value0.values)(y.value0.values);
            };
            if (x instanceof FilterByComponentDescription && y instanceof FilterByComponentDescription) {
                return Data_Eq.eq(TacoFilter.eqFilterValuesState)(x.value0.state)(y.value0.state) && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value0.values)(y.value0.values);
            };
            if (x instanceof FilterByComponentElement && y instanceof FilterByComponentElement) {
                return Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqInt))(x.value0)(y.value0);
            };
            if (x instanceof FilterByComponentSubProjectCode && y instanceof FilterByComponentSubProjectCode) {
                return Data_Eq.eq(TacoFilter.eqFilterValuesState)(x.value0.state)(y.value0.state) && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value0.values)(y.value0.values);
            };
            if (x instanceof FilterByComponentProjectOfOrigin && y instanceof FilterByComponentProjectOfOrigin) {
                return Data_Eq.eq(Data_Eq.eqArray(Types_Project.eqProjectId))(x.value0)(y.value0);
            };
            if (x instanceof FilterByComponentLocationCode && y instanceof FilterByComponentLocationCode) {
                return Data_Eq.eq(TacoFilter.eqFilterValuesState)(x.value0.state)(y.value0.state) && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value0.values)(y.value0.values);
            };
            if (x instanceof FilterByComponentSelected && y instanceof FilterByComponentSelected) {
                return Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqInt))(x.value0)(y.value0);
            };
            if (x instanceof FilterByComponentSearch && y instanceof FilterByComponentSearch) {
                return x.value0 === y.value0;
            };
            if (x instanceof FilterByComponentHasFlag && y instanceof FilterByComponentHasFlag) {
                return Data_Eq.eq(TacoFilter.eqFilterValuesState)(x.value0.state)(y.value0.state) && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value0.values)(y.value0.values);
            };
            if (x instanceof FilterByComponentHasCheck && y instanceof FilterByComponentHasCheck) {
                return Data_Eq.eq(TacoFilter.eqFilterValuesState)(x.value0.state)(y.value0.state) && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value0.values)(y.value0.values);
            };
            if (x instanceof FilterByComponentHasMemo && y instanceof FilterByComponentHasMemo) {
                return Data_Eq.eq(TacoFilter.eqFilterValuesState)(x.value0.state)(y.value0.state) && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value0.values)(y.value0.values);
            };
            if (x instanceof FilterByComponentNoFlag && y instanceof FilterByComponentNoFlag) {
                return Data_Eq.eq(TacoFilter.eqFilterValuesState)(x.value0.state)(y.value0.state) && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value0.values)(y.value0.values);
            };
            if (x instanceof FilterByComponentNoCheck && y instanceof FilterByComponentNoCheck) {
                return Data_Eq.eq(TacoFilter.eqFilterValuesState)(x.value0.state)(y.value0.state) && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value0.values)(y.value0.values);
            };
            if (x instanceof FilterByComponentNoMemo && y instanceof FilterByComponentNoMemo) {
                return Data_Eq.eq(TacoFilter.eqFilterValuesState)(x.value0.state)(y.value0.state) && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value0.values)(y.value0.values);
            };
            if (x instanceof FilterByComponentWithoutCostGroup && y instanceof FilterByComponentWithoutCostGroup) {
                return Data_Eq.eq(TacoFilter.eqFilterValuesState)(x.value0.state)(y.value0.state) && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value0.values)(y.value0.values);
            };
            if (x instanceof FilterByComponentNoCodeMatch && y instanceof FilterByComponentNoCodeMatch) {
                return Data_Eq.eq(TacoFilter.eqFilterValuesState)(x.value0.state)(y.value0.state) && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value0.values)(y.value0.values);
            };
            if (x instanceof FilterByComponentNonMatchingCostGroup && y instanceof FilterByComponentNonMatchingCostGroup) {
                return Data_Eq.eq(TacoFilter.eqFilterValuesState)(x.value0.state)(y.value0.state) && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value0.values)(y.value0.values);
            };
            return false;
        };
    }
};
var eqElementsDataPropertyFilter = {
    eq: function (x) {
        return function (y) {
            if (x instanceof FilterByElementsDataSubProjectCode && y instanceof FilterByElementsDataSubProjectCode) {
                return Data_Eq.eq(TacoFilter.eqFilterValuesState)(x.value0.state)(y.value0.state) && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value0.values)(y.value0.values);
            };
            if (x instanceof FilterByElementsDataLocationCode && y instanceof FilterByElementsDataLocationCode) {
                return Data_Eq.eq(TacoFilter.eqFilterValuesState)(x.value0.state)(y.value0.state) && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value0.values)(y.value0.values);
            };
            if (x instanceof FilterByElementsDataGroup && y instanceof FilterByElementsDataGroup) {
                return Data_Eq.eq(TacoFilter.eqFilterValuesState)(x.value0.state)(y.value0.state) && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value0.values)(y.value0.values);
            };
            if (x instanceof FilterByElementsWithoutDataGroup && y instanceof FilterByElementsWithoutDataGroup) {
                return Data_Eq.eq(TacoFilter.eqFilterValuesState)(x.value0.state)(y.value0.state) && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value0.values)(y.value0.values);
            };
            if (x instanceof FilterByElementsDataElementCode && y instanceof FilterByElementsDataElementCode) {
                return Data_Eq.eq(TacoFilter.eqFilterValuesState)(x.value0.state)(y.value0.state) && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value0.values)(y.value0.values);
            };
            if (x instanceof FilterByElementsDataElementNoCodeMatch && y instanceof FilterByElementsDataElementNoCodeMatch) {
                return Data_Eq.eq(TacoFilter.eqFilterValuesState)(x.value0.state)(y.value0.state) && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value0.values)(y.value0.values);
            };
            if (x instanceof FilterByElementsDataElementRtCode && y instanceof FilterByElementsDataElementRtCode) {
                return Data_Eq.eq(TacoFilter.eqFilterValuesState)(x.value0.state)(y.value0.state) && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value0.values)(y.value0.values);
            };
            if (x instanceof FilterByElementsDataDescription && y instanceof FilterByElementsDataDescription) {
                return Data_Eq.eq(TacoFilter.eqFilterValuesState)(x.value0.state)(y.value0.state) && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value0.values)(y.value0.values);
            };
            if (x instanceof FilterByElementsDataRtDescription && y instanceof FilterByElementsDataRtDescription) {
                return Data_Eq.eq(TacoFilter.eqFilterValuesState)(x.value0.state)(y.value0.state) && Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqString))(x.value0.values)(y.value0.values);
            };
            if (x instanceof FilterByElementsDataProjectOfOrigin && y instanceof FilterByElementsDataProjectOfOrigin) {
                return Data_Eq.eq(Data_Eq.eqArray(Types_Project.eqProjectId))(x.value0)(y.value0);
            };
            if (x instanceof FilterByElementsDataSearch && y instanceof FilterByElementsDataSearch) {
                return x.value0 === y.value0;
            };
            return false;
        };
    }
};
module.exports = {
    FilterByComponentGroup: FilterByComponentGroup,
    FilterByComponentWithoutGroup: FilterByComponentWithoutGroup,
    FilterByComponentCostGroupCode: FilterByComponentCostGroupCode,
    FilterByComponentCode: FilterByComponentCode,
    FilterByComponentDescription: FilterByComponentDescription,
    FilterByComponentElement: FilterByComponentElement,
    FilterByComponentSubProjectCode: FilterByComponentSubProjectCode,
    FilterByComponentProjectOfOrigin: FilterByComponentProjectOfOrigin,
    FilterByComponentLocationCode: FilterByComponentLocationCode,
    FilterByComponentSelected: FilterByComponentSelected,
    FilterByComponentSearch: FilterByComponentSearch,
    FilterByComponentHasFlag: FilterByComponentHasFlag,
    FilterByComponentHasCheck: FilterByComponentHasCheck,
    FilterByComponentHasMemo: FilterByComponentHasMemo,
    FilterByComponentNoFlag: FilterByComponentNoFlag,
    FilterByComponentNoCheck: FilterByComponentNoCheck,
    FilterByComponentNoMemo: FilterByComponentNoMemo,
    FilterByComponentWithoutCostGroup: FilterByComponentWithoutCostGroup,
    FilterByComponentNoCodeMatch: FilterByComponentNoCodeMatch,
    FilterByComponentNonMatchingCostGroup: FilterByComponentNonMatchingCostGroup,
    FilterByElementsDataSubProjectCode: FilterByElementsDataSubProjectCode,
    FilterByElementsDataLocationCode: FilterByElementsDataLocationCode,
    FilterByElementsDataGroup: FilterByElementsDataGroup,
    FilterByElementsWithoutDataGroup: FilterByElementsWithoutDataGroup,
    FilterByElementsDataElementCode: FilterByElementsDataElementCode,
    FilterByElementsDataElementNoCodeMatch: FilterByElementsDataElementNoCodeMatch,
    FilterByElementsDataElementRtCode: FilterByElementsDataElementRtCode,
    FilterByElementsDataDescription: FilterByElementsDataDescription,
    FilterByElementsDataRtDescription: FilterByElementsDataRtDescription,
    FilterByElementsDataProjectOfOrigin: FilterByElementsDataProjectOfOrigin,
    FilterByElementsDataSearch: FilterByElementsDataSearch,
    FilterByOrgResourcePriceName: FilterByOrgResourcePriceName,
    FilterByOrgResourcePricePriceListName: FilterByOrgResourcePricePriceListName,
    FilterByOrgResourcePriceGroup: FilterByOrgResourcePriceGroup,
    FilterByOrgResourcePriceVendor: FilterByOrgResourcePriceVendor,
    FilterByOrgResourcePriceCostClassCode: FilterByOrgResourcePriceCostClassCode,
    mkEmptyEstimationUiState: mkEmptyEstimationUiState,
    eqEstimationComponentPropertyFilter: eqEstimationComponentPropertyFilter,
    genericEstimationComponentPropertyFilter: genericEstimationComponentPropertyFilter,
    tofuJSONEstimationComponentPropertyFilter: tofuJSONEstimationComponentPropertyFilter,
    eqElementsDataPropertyFilter: eqElementsDataPropertyFilter,
    genericElementsDataPropertyFilter: genericElementsDataPropertyFilter,
    tofuJSONElementsDataPropertyFilter: tofuJSONElementsDataPropertyFilter,
    eqOrgResourcePricePropertyFilter: eqOrgResourcePricePropertyFilter,
    genericOrgResourcePricePropertyFilter: genericOrgResourcePricePropertyFilter
};
