// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var StyleProperties = require("../StyleProperties/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var tacoFilterContainerStyles = Box.boxStyle([ Box.FlexRow.value, new Box.Display(Box.InlineFlex.value), Box.AlignCenter.value, new Box.PaddingLeft(TacoConstants.S.value), new Box.BorderRadius(TacoConstants.XXS.value), new Box.Border(TacoConstants.BorderLight.value), new Box.BackgroundColor(TacoConstants.White.value), new Box.Transition([ "background-color" ]), new Box.Selector("&:hover, &:focus-within", [ new Box.BackgroundColor(TacoConstants.LightBlue.value), Box.Border.create(new TacoConstants.BorderCustom(TacoConstants.PrimaryBlue.value)), new Box.Selector(".filter-label", [ new Box.ForegroundColor(TacoConstants.PrimaryBlue.value) ]) ]) ]);
var inputStyles = Box.boxStyle([ new Box.Height(TacoConstants.L.value), Box.Width.create(new TacoConstants.CustomSize("100%")), new Box.Transition([ "width" ]), new Box.ForegroundColor(TacoConstants.Transparent.value), new Box.BackgroundColor(TacoConstants.Transparent.value), new Box.BorderRadius(TacoConstants.XXS.value), Box.Style.create(React_Basic_Emotion.css()({
    outline: StyleProperties.none,
    border: StyleProperties.none
})), new Box.Focus([ new Box.BackgroundColor(TacoConstants.White.value), Box.Border.create(new TacoConstants.BorderCustom(TacoConstants.PrimaryBlue.value)), new Box.BoxShadow(TacoConstants.M.value), Box.Style.create(React_Basic_Emotion.css()({
    color: React_Basic_Emotion.str("inherit")
})) ]) ]);
var inputContainerStyles = Box.boxStyle([ new Box.Position(Box.Absolute.value), Box.PosTop.create(new TacoConstants.CustomSize("0")), Box.PosLeft.create(new TacoConstants.CustomSize("0")), Box.PosRight.create(new TacoConstants.CustomSize("0")), new Box.MarginX(TacoConstants.XS.value) ]);
var filterValueStyles = Box.boxStyle([ new Box.PaddingX(TacoConstants.XS.value) ]);
var filterValueContainerStyles = Box.boxStyle([ Box.FlexRow.value, Box.AlignCenter.value, new Box.Position(Box.Relative.value), new Box.Height(TacoConstants.L.value), new Box.Selector("&:focus-within", [ Box.MinWidth.create(new TacoConstants.CustomSize("6rem")) ]), Box.Style.create(React_Basic_Emotion.css()({
    borderRight: StyleProperties.border(TacoConstants.BorderLight.value)
})) ]);
var filterLabelStyles = Box.boxStyle([  ]);
module.exports = {
    tacoFilterContainerStyles: tacoFilterContainerStyles,
    filterValueContainerStyles: filterValueContainerStyles,
    filterLabelStyles: filterLabelStyles,
    filterValueStyles: filterValueStyles,
    inputContainerStyles: inputContainerStyles,
    inputStyles: inputStyles
};
