// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Options = require("../Data.Options/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var HeaderName = (function () {
    function HeaderName(value0) {
        this.value0 = value0;
    };
    HeaderName.create = function (value0) {
        return new HeaderName(value0);
    };
    return HeaderName;
})();
var Field = (function () {
    function Field(value0) {
        this.value0 = value0;
    };
    Field.create = function (value0) {
        return new Field(value0);
    };
    return Field;
})();
var Width = (function () {
    function Width(value0) {
        this.value0 = value0;
    };
    Width.create = function (value0) {
        return new Width(value0);
    };
    return Width;
})();
var Editable = (function () {
    function Editable(value0) {
        this.value0 = value0;
    };
    Editable.create = function (value0) {
        return new Editable(value0);
    };
    return Editable;
})();
var ClassName = (function () {
    function ClassName(value0) {
        this.value0 = value0;
    };
    ClassName.create = function (value0) {
        return new ClassName(value0);
    };
    return ClassName;
})();
var Theme = (function () {
    function Theme(value0) {
        this.value0 = value0;
    };
    Theme.create = function (value0) {
        return new Theme(value0);
    };
    return Theme;
})();
var TField = (function () {
    function TField(value0) {
        this.value0 = value0;
    };
    TField.create = function (value0) {
        return new TField(value0);
    };
    return TField;
})();
var ColumnDefs = (function () {
    function ColumnDefs(value0) {
        this.value0 = value0;
    };
    ColumnDefs.create = function (value0) {
        return new ColumnDefs(value0);
    };
    return ColumnDefs;
})();
var RowData = (function () {
    function RowData(value0) {
        this.value0 = value0;
    };
    RowData.create = function (value0) {
        return new RowData(value0);
    };
    return RowData;
})();
var OnCellEditRequest = (function () {
    function OnCellEditRequest(value0) {
        this.value0 = value0;
    };
    OnCellEditRequest.create = function (value0) {
        return new OnCellEditRequest(value0);
    };
    return OnCellEditRequest;
})();
var ReadOnlyEdit = (function () {
    function ReadOnlyEdit(value0) {
        this.value0 = value0;
    };
    ReadOnlyEdit.create = function (value0) {
        return new ReadOnlyEdit(value0);
    };
    return ReadOnlyEdit;
})();
var GetRowId = (function () {
    function GetRowId(value0) {
        this.value0 = value0;
    };
    GetRowId.create = function (value0) {
        return new GetRowId(value0);
    };
    return GetRowId;
})();
var OnGridReady = (function () {
    function OnGridReady(value0) {
        this.value0 = value0;
    };
    OnGridReady.create = function (value0) {
        return new OnGridReady(value0);
    };
    return OnGridReady;
})();
var newRowFromEditRequest = function (dictTofuJSON) {
    return function (request) {
        var foreignNewRow = Data_Nullable.toMaybe($foreign["_newRequestFromEditRequestEvent"](request));
        return Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Data_Either.hush)(Data_Functor.map(Data_Maybe.functorMaybe)(TofuJSON.read(dictTofuJSON))(foreignNewRow));
    };
};
var wrapOnCellEditRequestHandler = function (dictTofuJSON) {
    return function (handler) {
        return function (request) {
            var v = newRowFromEditRequest(TofuJSON.tofuJSONForeign)(request);
            if (v instanceof Data_Maybe.Nothing) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            };
            if (v instanceof Data_Maybe.Just) {
                return $foreign.mkForeignHandler(handler)(v.value0);
            };
            throw new Error("Failed pattern match at Table.Internal (line 81, column 3 - line 83, column 45): " + [ v.constructor.name ]);
        };
    };
};
var columnDefToOptions = function (v) {
    if (v instanceof HeaderName) {
        return Data_Options.assoc(Data_Options.opt("headerName"))(v.value0);
    };
    if (v instanceof Field) {
        return Data_Options.assoc(Data_Options.opt("field"))(v.value0);
    };
    if (v instanceof Width) {
        return Data_Options.assoc(Data_Options.opt("width"))(v.value0);
    };
    if (v instanceof Editable) {
        return Data_Options.assoc(Data_Options.opt("editable"))(v.value0);
    };
    throw new Error("Failed pattern match at Table.Internal (line 30, column 1 - line 30, column 60): " + [ v.constructor.name ]);
};
var columnDefsToForeign = function (cds) {
    return Data_Options.options(Data_Foldable.fold(Data_Foldable.foldableArray)(Data_Options.monoidOptions)(Data_Functor.map(Data_Functor.functorArray)(columnDefToOptions)(cds)));
};
var tPropToOptions = function (dictTofuJSON) {
    return function (v) {
        if (v instanceof ClassName) {
            return Data_Options.assoc(Data_Options.opt("className"))(v.value0);
        };
        if (v instanceof Theme) {
            return Data_Options.assoc(Data_Options.opt("theme"))(v.value0);
        };
        if (v instanceof TField) {
            return Data_Options.assoc(Data_Options.opt("field"))(v.value0);
        };
        if (v instanceof ColumnDefs) {
            return Data_Options.assoc(Data_Options.opt("columnDefs"))(Data_Functor.map(Data_Functor.functorArray)(columnDefsToForeign)(v.value0));
        };
        if (v instanceof RowData) {
            return Data_Options.assoc(Data_Options.opt("rowData"))(v.value0);
        };
        if (v instanceof OnCellEditRequest) {
            return Data_Options.assoc(Data_Options.opt("onCellEditRequest"))(wrapOnCellEditRequestHandler(dictTofuJSON)(v.value0));
        };
        if (v instanceof ReadOnlyEdit) {
            return Data_Options.assoc(Data_Options.opt("readOnlyEdit"))(v.value0);
        };
        if (v instanceof GetRowId) {
            return Data_Options.assoc(Data_Options.opt("getRowId"))(v.value0);
        };
        if (v instanceof OnGridReady) {
            return Data_Options.assoc(Data_Options.opt("onGridReady"))(v.value0);
        };
        throw new Error("Failed pattern match at Table.Internal (line 58, column 1 - line 58, column 86): " + [ v.constructor.name ]);
    };
};
var tPropsToForeignOptions = function (dictTofuJSON) {
    return function (tps) {
        return Data_Options.options(Data_Foldable.fold(Data_Foldable.foldableArray)(Data_Options.monoidOptions)(Data_Functor.map(Data_Functor.functorArray)(tPropToOptions(dictTofuJSON))(tps)));
    };
};
module.exports = {
    HeaderName: HeaderName,
    Field: Field,
    Width: Width,
    Editable: Editable,
    columnDefToOptions: columnDefToOptions,
    columnDefsToForeign: columnDefsToForeign,
    ClassName: ClassName,
    Theme: Theme,
    TField: TField,
    ColumnDefs: ColumnDefs,
    RowData: RowData,
    OnCellEditRequest: OnCellEditRequest,
    ReadOnlyEdit: ReadOnlyEdit,
    GetRowId: GetRowId,
    OnGridReady: OnGridReady,
    tPropToOptions: tPropToOptions,
    tPropsToForeignOptions: tPropsToForeignOptions,
    newRowFromEditRequest: newRowFromEditRequest,
    wrapOnCellEditRequestHandler: wrapOnCellEditRequestHandler,
    "_newRequestFromEditRequestEvent": $foreign["_newRequestFromEditRequestEvent"],
    mkForeignHandler: $foreign.mkForeignHandler,
    "_Table": $foreign["_Table"]
};
