import { useQuery } from "react-query";
import axiosClient from "../../../axiosConfig";

export function useProjectGroups() {
  const endpoint = "/projectGroups";

  return useQuery(["getProjectGroups"], async () => {
    const { data } = await axiosClient.get<string[]>(endpoint);

    return data;
  });
}
