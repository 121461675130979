// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var dixonMeasurementsContainerStyles = function (v) {
    return Box.boxStyle([ new Box.FlexGrow(1), Box.FlexRow.value, new Box.Overflow(Box.Auto.value, Box.Auto.value), new Box.Selector(".__TacoSelect", [ Box.Width.create(new TacoConstants.CustomSize("100%")) ]), new Box.Selector(".DixonMeasurementsTableMGroupRow", [ new Box.BackgroundColor(TacoConstants.GrayLightest.value), Box.Style.create(React_Basic_Emotion.css()({
        borderTop: React_Basic_Emotion.str("2px solid " + TacoConstants.colorSchemeToStr(TacoConstants.Gray.value)),
        borderBottom: React_Basic_Emotion.str("2px solid " + TacoConstants.colorSchemeToStr(TacoConstants.Gray.value)),
        fontWeight: React_Basic_Emotion.str("bold")
    })) ]), new Box.Selector(".DixonMeasurementsTableRow-highlighted", [ Box.Style.create(React_Basic_Emotion.css()({
        backgroundColor: React_Basic_Emotion.str(v.highlightColor)
    })) ]) ]);
};
module.exports = {
    dixonMeasurementsContainerStyles: dixonMeasurementsContainerStyles
};
