import { createTable, Table, Accordion, Text } from "@tocoman/ui";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PlanningCostGroupData } from "../../../../../../ts-bindings/PlanningCostGroupData";

type LettersToMoveTableProps = {
  letters: PlanningCostGroupData[] | null;
};

export const LettersToBeMovedTable = ({ letters }: LettersToMoveTableProps) => {
  const { t } = useTranslation("integration", {
    keyPrefix: "adminet.adminetLetters",
  });
  const lettersTable = createTable().setRowType<PlanningCostGroupData>();

  const filteredLetters = mapLettersToBeMoved(letters);

  const formatPrice = (total: number): string => {
    return (
      total.toLocaleString("fi-FI", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + " €"
    );
  };

  const columns = useMemo(
    () => [
      lettersTable.createDataColumn((row) => row.costGroup.code, {
        id: "code",
        header: t`table.code`,
      }),
      lettersTable.createDataColumn((row) => row.costGroup.description, {
        id: "description",
        header: t`table.description`,
      }),
      // Properties amount and unit to be added in future
      lettersTable.createDataColumn((row) => row.costGroup.amount, {
        cell: (props) =>
          props.value ? <span>{props.value.toFixed(2)}</span> : "",
        id: "amount",
        header: t`table.amount`,
        size: 85,
      }),
      lettersTable.createDataColumn((row) => row.costGroup.unit, {
        id: "unit",
        header: t`table.unit`,
        size: 75,
      }),
      lettersTable.createDataColumn((row) => row.total, {
        cell: (props) => <span>{formatPrice(props.value)}</span>,
        id: "total",
        header: t`table.totalPrice`,
      }),
    ],
    [lettersTable, t]
  );

  return (
    <>
      <Accordion
        fullWidth={true}
        testId="LettersToBeMovedAccordion"
        items={[
          {
            title: t`show`,
            defaultOpen: true,
            contents: (
              <>
                <Text text={t`warning`} />
                <Table
                  className="h-128"
                  table={lettersTable}
                  data={filteredLetters}
                  columns={columns}
                  testId="LettersToBeMovedTable"
                />
              </>
            ),
          },
        ]}
      />
    </>
  );
};

// Filters out empty letters that won't be moved
const mapLettersToBeMoved = (
  letters: PlanningCostGroupData[] | null
): PlanningCostGroupData[] => {
  if (letters) {
    return letters.filter((letter) => letter.total !== 0);
  }
  return [];
};
