// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var ReducerUtils = require("../ReducerUtils/index.js");
var reducer = ReducerUtils.mkRemoteDataReducer(function (v) {
    if (v instanceof Actions.OrgAddressAreaRequest) {
        return new Data_Maybe.Just(ReducerUtils.RemoteDataFetching.value);
    };
    if (v instanceof Actions.OrgAddressAreaSuccess) {
        return Data_Maybe.Just.create(new ReducerUtils.RemoteDataSuccess(v.value0));
    };
    if (v instanceof Actions.OrgAddressAreaFailure) {
        return Data_Maybe.Just.create(new ReducerUtils.RemoteDataFailure({}));
    };
    return Data_Maybe.Nothing.value;
});
module.exports = {
    reducer: reducer
};
