// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var ComponentUtils = require("../ComponentUtils/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var CostEstimation_Calculations = require("../CostEstimation.Calculations/index.js");
var CostEstimation_Types = require("../CostEstimation.Types/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Show = require("../Data.Show/index.js");
var Formatters = require("../Formatters/index.js");
var React_Basic = require("../React.Basic/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoInput_Styles = require("../TacoInput.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoText = require("../TacoText/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_CostEstimation = require("../Types.CostEstimation/index.js");
var Types_Estimation = require("../Types.Estimation/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var Utils = require("../Utils/index.js");
var unitPrice = function (v) {
    return function (newPrice) {
        return newPrice;
    };
};
var totalPriceToUnitPrice = function (v) {
    return function (newPrice) {
        return Utils.defaultZeroDiv(newPrice)(v.component.amount);
    };
};
var testIdPrefix = function (viewMode) {
    return function (row) {
        return function (id) {
            return Data_Show.show(Types_CostEstimation.showECViewMode)(viewMode) + ("-component-" + (Data_Show.show(Types_Component.showComponentId)(row.estimationComponentWithResources.component.id) + ("-" + id)));
        };
    };
};
var renderUnitPrice = function (costClass) {
    return function (calculatedQuantity) {
        return function (viewMode) {
            return function (row) {
                var testId = testIdPrefix(viewMode)(row)("cost-class-unit-price");
                var ccUnitPrice = Utils.defaultZeroDiv(costClass.totalPrice)(calculatedQuantity);
                var text = Formatters.formatDecimalValue(2)(ccUnitPrice);
                return TacoText.component()()({
                    text: text,
                    testId: testId
                });
            };
        };
    };
};
var renderTotalPrice = function (costClass) {
    return function (_calculatedQuantity) {
        return function (viewMode) {
            return function (row) {
                var text = Formatters.formatDecimalValue(2)(costClass.totalPrice);
                var testId = testIdPrefix(viewMode)(row)("cost-class");
                var $15 = costClass.totalPrice !== 0.0;
                if ($15) {
                    return TacoText.component()()({
                        text: text,
                        testId: testId
                    });
                };
                return React_Basic.empty;
            };
        };
    };
};
var renderHoursPerUnit = function (costClass) {
    return function (calculatedQuantity) {
        return function (viewMode) {
            return function (row) {
                var testId = testIdPrefix(viewMode)(row)("cost-class-hours-per-unit");
                var ccHoursPerUnit = Utils.defaultZeroDiv(costClass.hours)(calculatedQuantity);
                var text = Formatters.formatDecimalValue(2)(ccHoursPerUnit);
                return TacoText.component()()({
                    text: text,
                    testId: testId
                });
            };
        };
    };
};
var mkSetResourcePriceAction = function (projectId) {
    return function (componentId) {
        return function (resourceId) {
            return function (costClassCode) {
                return function (pricePerUnit) {
                    return function (handler) {
                        var params = {
                            projectId: projectId,
                            params: {
                                actionType: Types_Estimation.InPlaceResourceUpdate.value,
                                actionParams: new Types_Estimation.EditEstimationCostClassPrice({
                                    resourceId: resourceId,
                                    componentId: componentId,
                                    costClassCode: costClassCode,
                                    pricePerUnit: pricePerUnit
                                })
                            },
                            handler: handler
                        };
                        return new Actions.EstimationResourceActionRequest(params);
                    };
                };
            };
        };
    };
};
var inputWrapper = function (flexGrow) {
    return function (minWidth) {
        return function (input) {
            return Box.box("inputWrapper")([ new Box.FlexGrow(flexGrow), Box.MinWidth.create(new TacoConstants.CustomSize(minWidth)) ])([ input ]);
        };
    };
};
var mkCostClassPriceInput = function (props) {
    return function (testId) {
        return function (valueFromRow) {
            return function (valueToUnitPrice) {
                return function (costClassCode) {
                    return function (row) {
                        var socExpPercentageForCostClass = Data_Maybe.maybe(0.0)((function () {
                            var $25 = Data_Newtype.un()(Types_Unit.PercentageOfZeroToOne);
                            var $26 = Data_Newtype.un()(Types_Unit.SocialExpensePercentage);
                            return function ($27) {
                                return $25($26($27));
                            };
                        })())(Data_Map_Internal.lookup(Types_Newtypes.ordCostClassCode)(costClassCode)(props.socialExpensePercentagesByCostClass));
                        var priceWithoutSocialExpenses = function (price) {
                            return price / (1.0 + socExpPercentageForCostClass);
                        };
                        var mkPriceUpdateAction = function (newPrice) {
                            return function (handler) {
                                var resourceId = Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
                                    return v.id;
                                })(ComponentUtils.getCostClassPriceResourceForCode(row.estimationComponentWithResources)(costClassCode));
                                var pricePerUnit = valueToUnitPrice(row.estimationComponentWithResources)(newPrice);
                                return mkSetResourcePriceAction(props.projectId)(row.estimationComponentWithResources.component.id)(resourceId)(costClassCode)(pricePerUnit)(handler);
                            };
                        };
                        var isCostClassPrice = !ComponentUtils.hasNonCostClassPriceResourceForCode(row.estimationComponentWithResources)(costClassCode);
                        var input = TacoInput.remoteNumberField()()({
                            value: valueFromRow(costClassCode)(row),
                            mkAction: function ($28) {
                                return mkPriceUpdateAction(priceWithoutSocialExpenses($28));
                            },
                            align: TacoInput.RightAlign.value,
                            precision: 2,
                            rendering: new TacoInput_Styles.TableCellRendering(TacoTable_Types.RowSmall.value),
                            disabled: !isCostClassPrice,
                            onFocus: props.mkOnFocus(props.column)(row),
                            onBlur: props.onInputBlur,
                            autoFocus: Data_Eq.eq(Data_Maybe.eqMaybe(CostEstimation_Types.eqECColumn))(row.selectedRowColumn)(new Data_Maybe.Just(props.column)),
                            title: Data_Monoid.guard(Data_Monoid.monoidString)(!isCostClassPrice)("Panoslajilla on panoksia, et voi muokata panoslajin hintaa. Poistamalla panokset p\xe4\xe4set muokkaamaan panoslajin hintaa"),
                            testId: testId
                        });
                        return inputWrapper(props.flexGrow)(props.minWidth)(input);
                    };
                };
            };
        };
    };
};
var getUnitPriceOfCostClass = function (code) {
    return function (r) {
        return Data_Maybe.fromMaybe(0.0)((function () {
            var calculatedQuantity = CostEstimation_Calculations.toAmountPrecision(CostEstimation_Calculations.componentCalculationAmount(r.estimationComponentWithResources.component));
            return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.find((function () {
                var $29 = Data_Eq.eq(Types_Newtypes.eqCostClassCode)(code);
                return function ($30) {
                    return $29((function (v) {
                        return v.costClassCode;
                    })($30));
                };
            })())(r.estimationComponentWithResources.totalPriceByCostClasses))(function (match) {
                return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(Utils.defaultZeroDiv(match.totalPrice)(calculatedQuantity));
            });
        })());
    };
};
var mkCostClassUnitPriceInput = function (props) {
    return function (costClassCode) {
        return function (row) {
            var testId = testIdPrefix(props.viewMode)(row)("cost-class-unit-price-input") + costClassCode;
            return mkCostClassPriceInput(props)(testId)(getUnitPriceOfCostClass)(unitPrice)(costClassCode)(row);
        };
    };
};
var getTotalPriceOfCostClass = function (code) {
    return function (r) {
        return Data_Maybe.fromMaybe(0.0)(Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.find((function () {
            var $31 = Data_Eq.eq(Types_Newtypes.eqCostClassCode)(code);
            return function ($32) {
                return $31((function (v) {
                    return v.costClassCode;
                })($32));
            };
        })())(r.estimationComponentWithResources.totalPriceByCostClasses))(function (match) {
            return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(match.totalPrice);
        }));
    };
};
var mkCostClassTotalPriceInput = function (props) {
    return function (costClassCode) {
        return function (row) {
            var testId = testIdPrefix(props.viewMode)(row)("cost-class-total-price-input") + costClassCode;
            return mkCostClassPriceInput(props)(testId)(getTotalPriceOfCostClass)(totalPriceToUnitPrice)(costClassCode)(row);
        };
    };
};
var getHoursPerUnitOfCostClass = function (code) {
    return function (r) {
        return Data_Maybe.fromMaybe(0.0)((function () {
            var calculatedQuantity = CostEstimation_Calculations.toAmountPrecision(CostEstimation_Calculations.componentCalculationAmount(r.estimationComponentWithResources.component));
            return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.find((function () {
                var $33 = Data_Eq.eq(Types_Newtypes.eqCostClassCode)(code);
                return function ($34) {
                    return $33((function (v) {
                        return v.costClassCode;
                    })($34));
                };
            })())(r.estimationComponentWithResources.totalPriceByCostClasses))(function (match) {
                return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(Utils.defaultZeroDiv(match.hours)(calculatedQuantity));
            });
        })());
    };
};
var mkCostClassHoursPerUnitInput = function (props) {
    return function (code) {
        return function (r) {
            var disabled = ComponentUtils.hasNonCostClassPriceResourceForCode(r.estimationComponentWithResources)(code);
            var componentId = ComponentUtils.getECWRId(r.estimationComponentWithResources);
            var mkCostClassAction = function (value) {
                return function (handler) {
                    var costClassPricingResource = ComponentUtils.getCostClassPriceResourceForCode(r.estimationComponentWithResources)(code);
                    var resourceId = Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
                        return v.id;
                    })(costClassPricingResource);
                    var params = {
                        actionType: Types_Estimation.InPlaceResourceUpdate.value,
                        actionParams: new Types_Estimation.EditEstimationCostClassPriceConsumptionRate({
                            resourceId: resourceId,
                            componentId: componentId,
                            costClassCode: code,
                            unitConsumptionRate: value
                        })
                    };
                    return new Actions.EstimationResourceActionRequest({
                        projectId: props.projectId,
                        handler: handler,
                        params: params
                    });
                };
            };
            return TacoInput.remoteNumberField()()({
                value: getHoursPerUnitOfCostClass(code)(r),
                onBlur: props.onInputBlur,
                disabled: disabled,
                title: Data_Monoid.guard(Data_Monoid.monoidString)(disabled)("Panoslajilla on panoksia, et voi muokata panoslajin menekki\xe4. Poistamalla panokset p\xe4\xe4set muokkaamaan panoslajin menekki\xe4"),
                mkAction: mkCostClassAction,
                align: TacoInput.RightAlign.value,
                precision: 2,
                stateKey: Data_Show.show(Types_Component.showComponentId)(componentId),
                rendering: new TacoInput_Styles.TableCellRendering(TacoTable_Types.RowSmall.value),
                testId: testIdPrefix(props.viewMode)(r)("cost-class-hours-per-unit")
            });
        };
    };
};
var costClassMaybeCell = function (renderCell) {
    return function (viewMode) {
        return function (costClassCode) {
            return new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value ], function (row) {
                var match = Data_Array.find((function () {
                    var $35 = Data_Eq.eq(Types_Newtypes.eqCostClassCode)(costClassCode);
                    return function ($36) {
                        return $35((function (v) {
                            return v.costClassCode;
                        })($36));
                    };
                })())(row.estimationComponentWithResources.totalPriceByCostClasses);
                var calculatedQuantity = CostEstimation_Calculations.toAmountPrecision(CostEstimation_Calculations.componentCalculationAmount(row.estimationComponentWithResources.component));
                if (match instanceof Data_Maybe.Nothing) {
                    return React_Basic.empty;
                };
                if (match instanceof Data_Maybe.Just) {
                    return renderCell(match.value0)(calculatedQuantity)(viewMode)(row);
                };
                throw new Error("Failed pattern match at EstimationComponentsTableHelpers (line 96, column 7 - line 98, column 95): " + [ match.constructor.name ]);
            });
        };
    };
};
var costClassTotalPriceMaybeCell = costClassMaybeCell(renderTotalPrice);
var costClassUnitPriceMaybeCell = costClassMaybeCell(renderUnitPrice);
var costClassHoursPerUnitMaybeCell = costClassMaybeCell(renderHoursPerUnit);
module.exports = {
    costClassTotalPriceMaybeCell: costClassTotalPriceMaybeCell,
    costClassUnitPriceMaybeCell: costClassUnitPriceMaybeCell,
    costClassHoursPerUnitMaybeCell: costClassHoursPerUnitMaybeCell,
    testIdPrefix: testIdPrefix,
    getTotalPriceOfCostClass: getTotalPriceOfCostClass,
    getUnitPriceOfCostClass: getUnitPriceOfCostClass,
    getHoursPerUnitOfCostClass: getHoursPerUnitOfCostClass,
    inputWrapper: inputWrapper,
    mkCostClassUnitPriceInput: mkCostClassUnitPriceInput,
    mkCostClassTotalPriceInput: mkCostClassTotalPriceInput,
    mkCostClassHoursPerUnitInput: mkCostClassHoursPerUnitInput
};
