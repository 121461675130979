// Generated by purs version 0.14.5
"use strict";
var Data_Show = require("../Data.Show/index.js");
var Types_Project = require("../Types.Project/index.js");
var UIEstimationShowPricesWithSocialExpenses = (function () {
    function UIEstimationShowPricesWithSocialExpenses(value0) {
        this.value0 = value0;
    };
    UIEstimationShowPricesWithSocialExpenses.create = function (value0) {
        return new UIEstimationShowPricesWithSocialExpenses(value0);
    };
    return UIEstimationShowPricesWithSocialExpenses;
})();
var UIEstimationShowTargetCostGroups = (function () {
    function UIEstimationShowTargetCostGroups(value0) {
        this.value0 = value0;
    };
    UIEstimationShowTargetCostGroups.create = function (value0) {
        return new UIEstimationShowTargetCostGroups(value0);
    };
    return UIEstimationShowTargetCostGroups;
})();
var UIEstimationWorkAchievement = (function () {
    function UIEstimationWorkAchievement(value0) {
        this.value0 = value0;
    };
    UIEstimationWorkAchievement.create = function (value0) {
        return new UIEstimationWorkAchievement(value0);
    };
    return UIEstimationWorkAchievement;
})();
var getLocalStorageKeyStr = function (v) {
    if (v instanceof UIEstimationShowPricesWithSocialExpenses) {
        return "ui:ce:show_costs_with_social_expenses:" + Data_Show.show(Types_Project.showProjectId)(v.value0);
    };
    if (v instanceof UIEstimationShowTargetCostGroups) {
        return "ui:ce:show_target_cost_groups:" + Data_Show.show(Types_Project.showProjectId)(v.value0);
    };
    if (v instanceof UIEstimationWorkAchievement) {
        return "ui:ce:work_achievement:" + Data_Show.show(Types_Project.showProjectId)(v.value0);
    };
    throw new Error("Failed pattern match at LocalStorageKeys (line 11, column 1 - line 11, column 51): " + [ v.constructor.name ]);
};
module.exports = {
    UIEstimationShowPricesWithSocialExpenses: UIEstimationShowPricesWithSocialExpenses,
    UIEstimationShowTargetCostGroups: UIEstimationShowTargetCostGroups,
    UIEstimationWorkAchievement: UIEstimationWorkAchievement,
    getLocalStorageKeyStr: getLocalStorageKeyStr
};
