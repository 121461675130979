const TsApp = require("../../src/client-ts/containers/App").App;
const AppWrapper = require("../../src/client-ts/containers/App").AppWrapper;
const CostControlPrinting = require("../../src/client-ts/containers/CostControl/Printing/Printing")
  .Printing;

exports.tsApp = TsApp;
exports.tsAppWrapper = AppWrapper;

exports.setDocumentTitle = (string) => () => {
  document.title = string;
};

exports.costControlPrinting = CostControlPrinting;
