import { Input } from "@tocoman/ui";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { StringCombobox } from "src/client-ts/components/StringCombobox";
import { useWorkerState } from "src/client-ts/hooks/useWorkerState";
import { isNumber, isPositive } from "src/client-ts/utils/validations";
import { ProjectDetailsData } from "ts-bindings/ProjectDetailsData";
import { ErrorMessage } from "../../../components/ErrorMessage";
import { useMemo } from "react";

export const OptionalInfoTab = () => {
  const { t } = useTranslation("projects", { keyPrefix: "details" });
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<ProjectDetailsData>();

  const mkInputProps = (
    field: keyof ProjectDetailsData,
    registerOptions?: RegisterOptions<ProjectDetailsData, typeof field>
  ) => ({
    label: t(`form.${field}`),
    ...register(field, registerOptions),
  });

  const projectTypes = useWorkerState("ProjectTypesState") ?? [];
  const projectManagers = useWorkerState("ProjectManagersState") ?? [];
  const constructionTypes = useWorkerState("ConstructionTypesState") ?? [];
  const financingTypes = useWorkerState("FinancingTypesState") ?? [];
  const contractTypes = useWorkerState("ContractTypesState") ?? [];
  const projectStates = useMemo(() => {
    return [
      t`form.stateTypes.construction`,
      t`form.stateTypes.completed`,
      t`form.stateTypes.planning`,
      t`form.stateTypes.warranty`,
    ];
  }, [t]);

  const parseDate = (date: string | null) =>
    date ? date.substring(0, 10) : "";

  return (
    <div>
      <div className="flex">
        <div className="grow grid gap-y-3 pr-4">
          <Controller
            name={"startDate"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input
                label={t`form.startDate`}
                type="date"
                value={parseDate(value)}
                onChange={(e) =>
                  onChange(new Date(e.target.value).toISOString())
                }
              />
            )}
          />

          <Controller
            control={control}
            name={"projectManager"}
            render={({ field: { onChange, value } }) => (
              <StringCombobox
                label={t`form.projectManager`}
                items={projectManagers}
                onValueChange={onChange}
                initialValue={value}
              />
            )}
          />
          <Controller
            control={control}
            name={"state"}
            render={({ field: { onChange, value } }) => (
              <StringCombobox
                label={t`form.state`}
                items={projectStates}
                onValueChange={onChange}
                initialValue={value}
              />
            )}
          />
          <Controller
            control={control}
            name={"constructionType"}
            render={({ field: { onChange, value } }) => (
              <StringCombobox
                label={t`form.constructionType`}
                items={constructionTypes}
                onValueChange={onChange}
                initialValue={value}
              />
            )}
          />
          <Controller
            control={control}
            name={"projectType"}
            render={({ field: { onChange, value } }) => (
              <StringCombobox
                label={t`form.projectType`}
                items={projectTypes}
                onValueChange={onChange}
                initialValue={value}
              />
            )}
          />
          <Input
            {...mkInputProps("address", {
              maxLength: {
                value: 50,
                message: t(`errors.tooLong`, { maxLength: "50" }),
              },
            })}
          />
          {errors.address && (
            // Have to use String() with useFormContext for now
            // https://github.com/react-hook-form/react-hook-form/issues/8653
            <ErrorMessage errorMessage={String(errors.address.message)} />
          )}
          <Input
            {...mkInputProps("postalCode", {
              maxLength: {
                value: 6,
                message: t(`errors.tooLong`, { maxLength: "6" }),
              },
            })}
          />
          {errors.postalCode && (
            <ErrorMessage errorMessage={String(errors.postalCode.message)} />
          )}
        </div>

        <div className="grow grid gap-y-3 pr-4">
          <Controller
            name={"endDate"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input
                label={t`form.endDate`}
                type="date"
                value={parseDate(value)}
                onChange={(e) =>
                  onChange(new Date(e.target.value).toISOString())
                }
              />
            )}
          />
          <Controller
            name={"offerDate"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input
                label={t`form.offerDate`}
                type="date"
                value={parseDate(value)}
                onChange={(e) =>
                  onChange(new Date(e.target.value).toISOString())
                }
              />
            )}
          />
          <Input
            {...mkInputProps("contractPrice", {
              valueAsNumber: true,
              max: {
                value: 10_000_000_000,
                message: t(`errors.maxValue`, { max: "10 000 000 000" }),
              },
              validate: {
                isNumber: (value) => isNumber(value) || t`errors.notANumber`,
                isPositive: (value) =>
                  isPositive(value) || t`errors.notPositive`,
              },
            })}
          />
          {errors.contractPrice && (
            <ErrorMessage errorMessage={String(errors.contractPrice.message)} />
          )}
          <Controller
            control={control}
            name={"financingType"}
            render={({ field: { onChange, value } }) => (
              <StringCombobox
                label={t`form.financingType`}
                items={financingTypes}
                onValueChange={onChange}
                initialValue={value}
              />
            )}
          />
          <Controller
            control={control}
            name={"contractType"}
            render={({ field: { onChange, value } }) => (
              <StringCombobox
                label={t`form.contractType`}
                items={contractTypes}
                onValueChange={onChange}
                initialValue={value}
              />
            )}
          />
          <div className="h-[45px]" />
          <Input
            {...mkInputProps("city", {
              maxLength: {
                value: 30,
                message: t(`errors.tooLong`, { maxLength: "30" }),
              },
            })}
          />
          {errors.city && (
            <ErrorMessage errorMessage={String(errors.city.message)} />
          )}
        </div>
      </div>
    </div>
  );
};
