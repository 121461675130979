// Generated by purs version 0.14.5
"use strict";
var Control_Alt = require("../Control.Alt/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Variant = require("../Data.Variant/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Partial_Unsafe = require("../Partial.Unsafe/index.js");
var variantImplToGenericSumImpl = function (dict) {
    return dict.variantImplToGenericSumImpl;
};
var variantToGenericSum = function (dictGeneric) {
    return function (dictGenericSumToVariant) {
        return function (v) {
            var v1 = Data_Functor.map(Data_Maybe.functorMaybe)(Data_Generic_Rep.to(dictGeneric))(variantImplToGenericSumImpl(dictGenericSumToVariant)(v));
            if (v1 instanceof Data_Maybe.Just) {
                return v1.value0;
            };
            if (v1 instanceof Data_Maybe.Nothing) {
                return Partial_Unsafe.unsafeCrashWith("the impossible occurred: the input Variant was not a valid representation of the generic sum.");
            };
            throw new Error("Failed pattern match at Kishimen (line 30, column 3 - line 33, column 102): " + [ v1.constructor.name ]);
        };
    };
};
var variantArgImplToGenericSumImpl = function (dict) {
    return dict.variantArgImplToGenericSumImpl;
};
var genericSumToVariantImpl = function (dict) {
    return dict.genericSumToVariantImpl;
};
var genericSumToVariantSum = function (dictGenericSumToVariant) {
    return function (dictGenericSumToVariant1) {
        return function (dictUnion) {
            return function (dictUnion1) {
                return function (dictUnion2) {
                    return function (dictRowToList) {
                        return function (dictVariantTags) {
                            return function (dictRowToList1) {
                                return function (dictVariantTags1) {
                                    return {
                                        genericSumToVariantImpl: function (v) {
                                            if (v instanceof Data_Generic_Rep.Inl) {
                                                return Data_Variant.expand()(genericSumToVariantImpl(dictGenericSumToVariant)(v.value0));
                                            };
                                            if (v instanceof Data_Generic_Rep.Inr) {
                                                return Data_Variant.expand()(genericSumToVariantImpl(dictGenericSumToVariant1)(v.value0));
                                            };
                                            throw new Error("Failed pattern match at Kishimen (line 56, column 1 - line 80, column 25): " + [ v.constructor.name ]);
                                        },
                                        variantImplToGenericSumImpl: function (v) {
                                            var readRight = Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Variant.contract(Data_Maybe.alternativeMaybe)(Data_Variant_Internal.contractWithInstance()()(dictVariantTags1))(v))(function (v1) {
                                                return Control_Bind.bind(Data_Maybe.bindMaybe)(variantImplToGenericSumImpl(dictGenericSumToVariant1)(v1))(function (v2) {
                                                    return new Data_Maybe.Just(new Data_Generic_Rep.Inr(v2));
                                                });
                                            });
                                            var readLeft = Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Variant.contract(Data_Maybe.alternativeMaybe)(Data_Variant_Internal.contractWithInstance()()(dictVariantTags))(v))(function (v1) {
                                                return Control_Bind.bind(Data_Maybe.bindMaybe)(variantImplToGenericSumImpl(dictGenericSumToVariant)(v1))(function (v2) {
                                                    return new Data_Maybe.Just(new Data_Generic_Rep.Inl(v2));
                                                });
                                            });
                                            return Control_Alt.alt(Data_Maybe.altMaybe)(readLeft)(readRight);
                                        }
                                    };
                                };
                            };
                        };
                    };
                };
            };
        };
    };
};
var genericSumToVariantArgNoArguments = {
    genericSumToVariantArgImpl: function (v) {
        return {};
    },
    variantArgImplToGenericSumImpl: function (v) {
        return Data_Generic_Rep.NoArguments.value;
    }
};
var genericSumToVariantArgImpl = function (dict) {
    return dict.genericSumToVariantArgImpl;
};
var genericSumToVariantConstructor = function (dictIsSymbol) {
    return function (dictCons) {
        return function (dictGenericSumToVariantArg) {
            return {
                genericSumToVariantImpl: function (v) {
                    var value = genericSumToVariantArgImpl(dictGenericSumToVariantArg)(v);
                    return Data_Variant.inj()(dictIsSymbol)(Data_Symbol.SProxy.value)(value);
                },
                variantImplToGenericSumImpl: function (v) {
                    return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Variant.prj()(dictIsSymbol)(Data_Maybe.alternativeMaybe)(Data_Symbol.SProxy.value)(v))(function (v1) {
                        return Data_Maybe.Just.create(Data_Generic_Rep.Constructor(variantArgImplToGenericSumImpl(dictGenericSumToVariantArg)(v1)));
                    });
                }
            };
        };
    };
};
var genericSumToVariantArgArguments = {
    genericSumToVariantArgImpl: function (v) {
        return v;
    },
    variantArgImplToGenericSumImpl: function (x) {
        return x;
    }
};
var genericSumToVariant = function (dictGeneric) {
    return function (dictGenericSumToVariant) {
        return function (a) {
            return genericSumToVariantImpl(dictGenericSumToVariant)(Data_Generic_Rep.from(dictGeneric)(a));
        };
    };
};
module.exports = {
    genericSumToVariantArgImpl: genericSumToVariantArgImpl,
    genericSumToVariantImpl: genericSumToVariantImpl,
    variantArgImplToGenericSumImpl: variantArgImplToGenericSumImpl,
    variantImplToGenericSumImpl: variantImplToGenericSumImpl,
    genericSumToVariant: genericSumToVariant,
    variantToGenericSum: variantToGenericSum,
    genericSumToVariantConstructor: genericSumToVariantConstructor,
    genericSumToVariantSum: genericSumToVariantSum,
    genericSumToVariantArgNoArguments: genericSumToVariantArgNoArguments,
    genericSumToVariantArgArguments: genericSumToVariantArgArguments
};
