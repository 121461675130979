import { useMutation } from "react-query";
import axiosClient from "../../../axiosConfig";
import { OptionalOrganizationData } from "./OrganizationSettings";

const baseUrl = "/users/me/organizations";

export function useUpdateCurrentOrganization() {
  return useMutation({
    mutationFn: async (organization: OptionalOrganizationData) => {
      const { data } = await axiosClient.put<OptionalOrganizationData>(
        `${baseUrl}/${organization.name}`,
        organization
      );
      return data;
    },
  });
}
