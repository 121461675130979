import { ConfirmationModal } from "@tocoman/ui";
import { Trans, useTranslation } from "react-i18next";
import {
  useAllowedCurrencyDeleteMutation,
  useGetCurrencyIsInUseQuery,
} from "./useOrganizationCurrencySettings";

export function DeleteSelectedCurrencyModal({
  currency,
  isDefault,
}: {
  currency: string;
  isDefault: boolean;
}) {
  const { t } = useTranslation("settings", { keyPrefix: "currencies" });
  const { mutate: deleteAllowedCurrency } = useAllowedCurrencyDeleteMutation();
  const { data: currencyInUse } = useGetCurrencyIsInUseQuery(currency);

  return (
    <ConfirmationModal
      buttonProps={{
        variant: "primary",
        color: "danger",
        disabled: isDefault || currencyInUse,
      }}
      buttonText={t`delete`}
      confirmText={t`delete`}
      cancelText={t`cancel`}
      onConfirm={() => {
        deleteAllowedCurrency(currency);
      }}
      title={t`deleteCurrencyTitle`}
      prompt={
        <Trans
          ns="settings"
          i18nKey={"currencies.deleteCurrencyPrompt"}
          values={{ currency: currency }}
        />
      }
    />
  );
}
