// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var dixonDrawingItemContainerStyles = function (v) {
    return Box.boxStyle([ Box.FlexRow.value, Box.AlignCenter.value, Data_Monoid.guard(Box.monoidBoxProp)(v.highlighted)(new Box.BackgroundColor(TacoConstants.ComponentBlueLight.value)) ]);
};
module.exports = {
    dixonDrawingItemContainerStyles: dixonDrawingItemContainerStyles
};
