import { useTranslation } from "react-i18next";
import { Header } from "@tocoman/ui";
import { NavBar } from "../../NavBar/NavBar";

export const AdminUnauthorized = () => {
  const { t } = useTranslation("settings");

  return (
    <>
      <NavBar />
      <div className={"p-4"}>
        <Header title={t("unauthorized")} titleSize={"medium"} />
      </div>
    </>
  );
};
