// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var UseKeydownListener = require("../UseKeydownListener/index.js");
var Utils_Keyboard = require("../Utils.Keyboard/index.js");
var useSuggestionsNavigation = function (v) {
    return function __do() {
        var v1 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var selectItemRelative = TofuHooks.useMemo([  ])(function (v2) {
            return function (diff) {
                return function (components) {
                    return function (filterActive) {
                        return v1.value1(function (current) {
                            var targetIdx = diff + Data_Maybe.fromMaybe(-diff | 0)(current) | 0;
                            var arraySize = Data_Array.length(components);
                            var $5 = !filterActive || arraySize === 0;
                            if ($5) {
                                return Data_Maybe.Nothing.value;
                            };
                            var $6 = targetIdx >= 0 && targetIdx < arraySize;
                            if ($6) {
                                return new Data_Maybe.Just(targetIdx);
                            };
                            var $7 = targetIdx <= 0;
                            if ($7) {
                                return new Data_Maybe.Just(arraySize - 1 | 0);
                            };
                            return new Data_Maybe.Just(0);
                        });
                    };
                };
            };
        })();
        UseKeydownListener.useKeydownListener([ Data_Show.show(Data_Show.showBoolean)(v.modalOpen), v.itemIds, Data_Show.show(Data_Maybe.showMaybe(Data_Show.showInt))(v1.value0), Data_Show.show(Data_Show.showBoolean)(v.activeFilter) ])(v.modalOpen)([ Utils_Keyboard.ArrowUp.value, Utils_Keyboard.ArrowDown.value, Utils_Keyboard.Enter.value ])(ReactHooksUtils.utf("UseSuggestionsNavigation"))(function (keyDownEvent) {
            if (keyDownEvent.key instanceof Utils_Keyboard.ArrowUp) {
                return function __do() {
                    Utils_Keyboard.preventDefault(keyDownEvent.event)();
                    return selectItemRelative(-1 | 0)(v.items)(v.activeFilter)();
                };
            };
            if (keyDownEvent.key instanceof Utils_Keyboard.ArrowDown) {
                return function __do() {
                    Utils_Keyboard.preventDefault(keyDownEvent.event)();
                    return selectItemRelative(1)(v.items)(v.activeFilter)();
                };
            };
            if (keyDownEvent.key instanceof Utils_Keyboard.Enter) {
                return Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(v.activeFilter)((function () {
                    var selectedItem = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Data_Array.index(v.items))(v1.value0);
                    if (selectedItem instanceof Data_Maybe.Nothing) {
                        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                    };
                    if (selectedItem instanceof Data_Maybe.Just) {
                        return v.onSelect(selectedItem.value0);
                    };
                    throw new Error("Failed pattern match at UseSuggestionsNavigation (line 54, column 16 - line 56, column 35): " + [ selectedItem.constructor.name ]);
                })());
            };
            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
        })();
        return {
            selectedItemIdx: v1.value0,
            setSelectedItemIdx: v1.value1
        };
    };
};
module.exports = {
    useSuggestionsNavigation: useSuggestionsNavigation
};
