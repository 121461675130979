// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoModal = require("../TacoModal/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var component = TofuHooks.mkHookComponent("DeleteSalesInvoiceModal")(function (props) {
    return function __do() {
        var appDispatch = StateHooks.useDispatch();
        StateHooks.useSelector(function (v) {
            return v.updateStatuses;
        })();
        var idsToDelete = Data_Functor.map(Data_Functor.functorArray)(function (it) {
            return it.id;
        })(props.itemsToDelete);
        var closeModal = props.setModalOpen(function (v) {
            return false;
        });
        var modalActions = [ TacoButton.component()()({
            onClick: new Data_Maybe.Just(closeModal),
            text: "Peruuta"
        }), TacoButton.component()()({
            onClick: new Data_Maybe.Just(function __do() {
                appDispatch(Actions.DeleteSalesInvoicesRequest.create({
                    projectId: props.projectId,
                    value: {
                        ids: idsToDelete
                    }
                }))();
                return closeModal();
            }),
            buttonStyle: TacoButton_Types.Filled.value,
            color: TacoConstants.SecondaryRed.value,
            text: "Poista"
        }) ];
        return React_Basic_DOM_Generated.div_([ TacoModal.component()()({
            onClose: closeModal,
            isActive: props.modalOpen,
            heading: "Seuraavat kassatapahtumat tullaan poistamaan:",
            contents: [ TacoModal.modalBody({
                contents: Data_Functor.map(Data_Functor.functorArray)(function (r) {
                    return React_Basic_DOM_Generated.div_([ React_Basic_DOM.text(r.description) ]);
                })(props.itemsToDelete)
            }), TacoModal.modalActions({
                contents: modalActions
            }) ]
        }) ]);
    };
});
module.exports = {
    component: component
};
