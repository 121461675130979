import { useTranslation } from "react-i18next";
import { lazy, Suspense, useCallback, useState } from "react";
import { Button, Loader, Modal } from "@tocoman/ui";
import { OfferClassificationSummary, OfferProject } from "./OfferPage";
import { PdfFrame } from "src/client-ts/components/PdfFrame";

const OfferPrintForm = lazy(() => import("./OfferPrintForm"));

type OfferPdfSelectionProps = {
  isOpen: boolean;
  closeModal: () => void;
  data: OfferClassificationSummary[] | null;
  projectData: OfferProject;
  marginType: string;
};
export function OfferPdfSelection({
  data,
  isOpen,
  closeModal,
  projectData,
  marginType,
}: OfferPdfSelectionProps) {
  const { t } = useTranslation("estimation", { keyPrefix: "offerPage" });

  const pdfAdditionalInfo = {
    name: projectData.name,
    address: projectData.address
      ? `${projectData.address}, ${projectData.postalCode} ${projectData.city}`
      : "",
    constructionType: projectData.constructionType ?? "",
    offerDate: projectData.offerDate
      ? new Date(projectData.offerDate).toLocaleDateString("fi-FI")
      : new Date().toLocaleDateString("fi-FI"),
    builder: "",
    planning: "",
    elements: "",
    constructionTime:
      projectData.startDate && projectData.endDate
        ? `${new Date(projectData.startDate).toLocaleDateString(
            "fi-FI"
          )} - ${new Date(projectData.endDate).toLocaleDateString("fi-FI")}`
        : "",
    index: "",
  };

  const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  const resetAndClosePdf = useCallback(() => {
    if (pdfUrl) {
      URL.revokeObjectURL(pdfUrl);
    }
    setPdfUrl(null);
    closeModal();
  }, [closeModal, pdfUrl]);

  const closeButton = (
    <Button
      key="close"
      onClick={resetAndClosePdf}
      variant="secondary"
      label={t`cancel`}
    />
  );

  return (
    <>
      <Modal
        width={1400}
        isOpen={isOpen}
        closeModal={resetAndClosePdf}
        loading={!data}
        title={t`print`}
        actions={closeButton}
      >
        <div className={"flex flex-row w-full"}>
          <div className={"flex flex-col gap-4 pr-3 w-auto"}>
            <Suspense fallback={<Loader isLoading kind="spinner" />}>
              <OfferPrintForm
                projectId={projectData.id}
                offerData={data}
                projectInfo={pdfAdditionalInfo}
                closeModal={resetAndClosePdf}
                changePdfUrl={setPdfUrl}
                marginType={marginType}
              />
            </Suspense>
          </div>

          <PdfFrame
            pdfUrl={pdfUrl}
            width={"100%"}
            height={"1000px"}
            className={"w-full"}
          />
        </div>
      </Modal>
    </>
  );
}
