import { FullProjectData } from "./Report";
import {
  componentMap,
  ComponentPropsMap,
} from "../../SuperAdmin/ReportDesigner/AllReportParameters";
import { Measurement } from "../../../../../ts-bindings/Measurement";

type ReportComponentProps = {
  [key: string]: string[] | string | Measurement[];
};

export const setReportParameters = (
  reportParameters: (keyof ComponentPropsMap)[],
  fullReportData: FullProjectData | undefined
): { component: JSX.Element; key: keyof ComponentPropsMap }[] => {
  const projectName = fullReportData?.projectData?.name ?? "";

  const subProjects = fullReportData?.subProjects?.map(
    (subProject) => subProject.code
  );

  const locations = fullReportData?.locations?.map((location) => location.code);

  const measurements = fullReportData?.measurements;

  return reportParameters
    .map((p) => {
      const Component = componentMap[p];
      if (!Component) {
        return null;
      }
      const props: ReportComponentProps = {};

      switch (p) {
        case "reportHeader":
          props.reportHeader = "Kustannuslaskelma";
          break;
        case "reportSubHeader":
          props.projectName = projectName;
          break;
        case "subProjects":
          props.subProjects = subProjects ?? [];
          break;
        case "locations":
          props.locations = locations ?? [];
          break;
        case "measurements":
          props.measurements = measurements ?? [];
          break;
        default:
          break;
      }
      return { key: p, component: <Component {...props} /> };
    })
    .filter(
      (c): c is { key: keyof ComponentPropsMap; component: JSX.Element } => !!c
    );
};
