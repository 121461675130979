// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var estimationResourcesExcelExportButton = TofuHooks.mkHookComponent("EstimationResourcesExcelExportButton")(function (props) {
    return function __do() {
        var dispatch = StateHooks.useDispatch();
        var button = TacoButton.component()()({
            onClick: new Data_Maybe.Just(dispatch(new Actions.GetEstimationResourcesExcelExportRequest(props.mkExport(Data_Unit.unit)))),
            text: "Panosluettelo",
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconFileDownloadV1.value),
            align: TacoButton_Types.Left.value,
            testId: "resources-excel-export-button"
        });
        return button;
    };
});
var estimationQuantityExcelExportButton = TofuHooks.mkHookComponent("EstimationQuantityExcelExportButton")(function (props) {
    return function __do() {
        var dispatch = StateHooks.useDispatch();
        var button = TacoButton.component()()({
            onClick: new Data_Maybe.Just(dispatch(new Actions.GetEstimationQuantityExcelExportRequest(props.mkExport(Data_Unit.unit)))),
            text: "Suoritteet, vain m\xe4\xe4r\xe4tieto",
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconFileDownloadV1.value),
            align: TacoButton_Types.Left.value,
            testId: "quantity-excel-export-button"
        });
        return button;
    };
});
var estimationQuantityByLocationExcelExportButton = TofuHooks.mkHookComponent("EstimationQuantityByLocationExcelExportButton")(function (props) {
    return function __do() {
        var dispatch = StateHooks.useDispatch();
        var button = TacoButton.component()()({
            onClick: new Data_Maybe.Just(dispatch(new Actions.GetEstimationQuantityByLocationExcelExportRequest(props.mkExport(Data_Unit.unit)))),
            text: "Vie m\xe4\xe4r\xe4luettelo, m\xe4\xe4r\xe4t sijainneittain",
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconFileDownloadV1.value),
            align: TacoButton_Types.Left.value,
            testId: "quantity-by-location-excel-export-button"
        });
        return button;
    };
});
var estimationQuantityByCostClassExcelExportButton = TofuHooks.mkHookComponent("EstimationQuantityByCostClassExcelExportButton")(function (props) {
    return function __do() {
        var dispatch = StateHooks.useDispatch();
        var button = TacoButton.component()()({
            onClick: new Data_Maybe.Just(dispatch(new Actions.GetEstimationQuantityByCostClassExcelExportRequest(props.mkExport(Data_Unit.unit)))),
            text: "Vie suoritteen panoslajien kustannukset",
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconFileDownloadV1.value),
            align: TacoButton_Types.Left.value,
            testId: "quantity-by-costClass-excel-export-button"
        });
        return button;
    };
});
var estimationExcelExportButton = TofuHooks.mkHookComponent("EstimationExcelExportButton")(function (props) {
    return function __do() {
        var dispatch = StateHooks.useDispatch();
        var button = TacoButton.component()()({
            onClick: new Data_Maybe.Just(dispatch(new Actions.GetEstimationExcelExportRequest(props.mkExport(Data_Unit.unit)))),
            text: "Suoritteet ja panokset",
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconFileDownloadV1.value),
            align: TacoButton_Types.Left.value,
            testId: "estimation-excel-export-button"
        });
        return button;
    };
});
var estimationElementsExcelExportButton = TofuHooks.mkHookComponent("EstimationElementsExcelExportButton")(function (props) {
    return function __do() {
        var dispatch = StateHooks.useDispatch();
        var button = TacoButton.component()()({
            onClick: new Data_Maybe.Just(dispatch(new Actions.GetEstimationElementsExcelExportRequest(props.mkExport(Data_Unit.unit)))),
            text: "Rakenneluettelo",
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconFileDownloadV1.value),
            align: TacoButton_Types.Left.value,
            testId: "elements-excel-export-button"
        });
        return button;
    };
});
var estimationComponentsExcelExportButton = TofuHooks.mkHookComponent("EstimationComponentsExcelExportButton")(function (props) {
    return function __do() {
        var dispatch = StateHooks.useDispatch();
        var button = TacoButton.component()()({
            onClick: new Data_Maybe.Just(dispatch(new Actions.GetEstimationComponentsExcelExportRequest(props.mkExport(Data_Unit.unit)))),
            text: "Suoriterivi ja sarakkeet, kuten n\xe4yt\xf6lle on valittu ",
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconFileDownloadV1.value),
            align: TacoButton_Types.Left.value,
            testId: "components-excel-export-button"
        });
        return button;
    };
});
module.exports = {
    estimationExcelExportButton: estimationExcelExportButton,
    estimationQuantityExcelExportButton: estimationQuantityExcelExportButton,
    estimationQuantityByLocationExcelExportButton: estimationQuantityByLocationExcelExportButton,
    estimationQuantityByCostClassExcelExportButton: estimationQuantityByCostClassExcelExportButton,
    estimationComponentsExcelExportButton: estimationComponentsExcelExportButton,
    estimationResourcesExcelExportButton: estimationResourcesExcelExportButton,
    estimationElementsExcelExportButton: estimationElementsExcelExportButton
};
