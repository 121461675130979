// Generated by purs version 0.14.5
"use strict";
var Data_Array = require("../Data.Array/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var DateFnsFFI = require("../DateFnsFFI/index.js");
var Utils = require("../Utils/index.js");
var NoDateLimit = (function () {
    function NoDateLimit() {

    };
    NoDateLimit.value = new NoDateLimit();
    return NoDateLimit;
})();
var UpperDateLimit = (function () {
    function UpperDateLimit(value0) {
        this.value0 = value0;
    };
    UpperDateLimit.create = function (value0) {
        return new UpperDateLimit(value0);
    };
    return UpperDateLimit;
})();
var filterSalesInvoices = function (v) {
    return function (xs) {
        if (v instanceof NoDateLimit) {
            return xs;
        };
        if (v instanceof UpperDateLimit) {
            var isBeforeLimit = function (si) {
                var v1 = Data_Nullable.toMaybe(si.date);
                var v2 = function (v3) {
                    return false;
                };
                if (v1 instanceof Data_Maybe.Just) {
                    var $19 = Utils.lossyParseJSDate(v1.value0);
                    var $20 = DateFnsFFI.isBefore($19)(v.value0);
                    if ($20) {
                        return true;
                    };
                    return v2(true);
                };
                return v2(true);
            };
            return Data_Array.filter(isBeforeLimit)(xs);
        };
        throw new Error("Failed pattern match at Calculation.Utils (line 20, column 1 - line 20, column 96): " + [ v.constructor.name, xs.constructor.name ]);
    };
};
var filterInvoiceLines = function (v) {
    return function (xs) {
        if (v instanceof NoDateLimit) {
            return xs;
        };
        if (v instanceof UpperDateLimit) {
            var isBeforeLimit = function (si) {
                var v1 = Data_Nullable.toMaybe(si.date);
                var v2 = function (v3) {
                    return false;
                };
                if (v1 instanceof Data_Maybe.Just) {
                    var $26 = Utils.lossyParseJSDate(v1.value0);
                    var $27 = DateFnsFFI.isBefore($26)(v.value0);
                    if ($27) {
                        return true;
                    };
                    return v2(true);
                };
                return v2(true);
            };
            return Data_Array.filter(isBeforeLimit)(xs);
        };
        throw new Error("Failed pattern match at Calculation.Utils (line 45, column 1 - line 45, column 95): " + [ v.constructor.name, xs.constructor.name ]);
    };
};
var filterIncomeItems = function (v) {
    return function (xs) {
        if (v instanceof NoDateLimit) {
            return xs;
        };
        if (v instanceof UpperDateLimit) {
            var isBeforeLimit = function (si) {
                var v1 = Data_Nullable.toMaybe(si.importDate);
                var v2 = function (v3) {
                    if (v1 instanceof Data_Maybe.Nothing) {
                        return true;
                    };
                    throw new Error("Failed pattern match at Calculation.Utils (line 31, column 1 - line 31, column 90): " + [ v1.constructor.name ]);
                };
                if (v1 instanceof Data_Maybe.Just) {
                    var $34 = Utils.lossyParseJSDate(v1.value0);
                    return DateFnsFFI.isBefore($34)(v.value0);
                };
                return v2(true);
            };
            return Data_Array.filter(isBeforeLimit)(xs);
        };
        throw new Error("Failed pattern match at Calculation.Utils (line 31, column 1 - line 31, column 90): " + [ v.constructor.name, xs.constructor.name ]);
    };
};
var filterCostGroupItems = function (v) {
    return function (xs) {
        if (v instanceof NoDateLimit) {
            return xs;
        };
        if (v instanceof UpperDateLimit) {
            var isBeforeLimitOrNull = function (cgi) {
                var v1 = Data_Nullable.toMaybe(cgi.modifyDate);
                var v2 = function (v3) {
                    if (v1 instanceof Data_Maybe.Nothing) {
                        return true;
                    };
                    throw new Error("Failed pattern match at Calculation.Utils (line 56, column 1 - line 56, column 91): " + [ v1.constructor.name ]);
                };
                if (v1 instanceof Data_Maybe.Just) {
                    var $41 = Utils.lossyParseJSDate(v1.value0);
                    return DateFnsFFI.isBefore($41)(v.value0);
                };
                return v2(true);
            };
            return Data_Array.filter(isBeforeLimitOrNull)(xs);
        };
        throw new Error("Failed pattern match at Calculation.Utils (line 56, column 1 - line 56, column 91): " + [ v.constructor.name, xs.constructor.name ]);
    };
};
module.exports = {
    NoDateLimit: NoDateLimit,
    UpperDateLimit: UpperDateLimit,
    filterSalesInvoices: filterSalesInvoices,
    filterIncomeItems: filterIncomeItems,
    filterInvoiceLines: filterInvoiceLines,
    filterCostGroupItems: filterCostGroupItems
};
