// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var StyleProperties = require("../StyleProperties/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var userDropdownContainerStyles = Box.boxStyle([ new Box.ForegroundColor(TacoConstants.GrayDarkest.value), Box.Style.create(React_Basic_Emotion.css()({
    borderLeft: StyleProperties.border(TacoConstants.BorderLight.value)
})) ]);
module.exports = {
    userDropdownContainerStyles: userDropdownContainerStyles
};
