// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var AddOrganizations = require("../AddOrganizations/index.js");
var AddUsers = require("../AddUsers/index.js");
var Controls = require("../Controls/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var DeleteUsers = require("../DeleteUsers/index.js");
var Effect = require("../Effect/index.js");
var FilterUsers = require("../FilterUsers/index.js");
var ListOrganizations = require("../ListOrganizations/index.js");
var ListUsers = require("../ListUsers/index.js");
var NarrowLayout = require("../NarrowLayout/index.js");
var OutageMessageModal = require("../OutageMessageModal/index.js");
var StateHooks = require("../StateHooks/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var UseFetch = require("../UseFetch/index.js");
var component = TofuHooks.mkHookComponent("UserManagementContainer")(function (v) {
    return function __do() {
        var appDispatch = StateHooks.useDispatch();
        var v1 = UseFetch.useFetchItems(Data_Eq.eqUnit)(Data_Unit.unit)(function (v1) {
            return v1.users;
        })(Actions.GetSuperAdminUsersRequest.value)();
        var v2 = UseFetch.useFetchItems(Data_Eq.eqUnit)(Data_Unit.unit)(function (v2) {
            return v2.allOrganizations;
        })(Actions.GetAllOrganizationsRequest.value)();
        var v3 = TofuHooks.useState("")();
        var v4 = TofuHooks.useState([  ])();
        TofuHooks.useEffect([ v3.value0 ])(function __do() {
            v4.value1(function (v5) {
                return [  ];
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        })();
        return NarrowLayout.component()()({
            children: [ Controls.component()()({
                leftControls: [ AddUsers.component({
                    appDispatch: appDispatch,
                    organizations: v2.value0
                }), AddOrganizations.component({
                    appDispatch: appDispatch
                }) ],
                rightControls: [ OutageMessageModal.component({}) ],
                bottomGutter: true,
                gap: true
            }), Controls.component()()({
                leftControls: [ FilterUsers.component({
                    filter: v3.value0,
                    setFilter: v3.value1
                }), DeleteUsers.component({
                    appDispatch: appDispatch,
                    selectedUsers: v4.value0,
                    setSelectedUsers: v4.value1
                }) ],
                bottomGutter: true,
                gap: true
            }), ListUsers.component({
                appDispatch: appDispatch,
                users: v1.value0,
                filter: v3.value0,
                selectedUsers: v4.value0,
                organizations: v2.value0,
                setSelectedUsers: v4.value1
            }), ListOrganizations.component({
                dispatch: appDispatch
            }) ],
            verticalPadding: true
        });
    };
});
module.exports = {
    component: component
};
