// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Controls = require("../Controls/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Measurements = require("../Measurements/index.js");
var NarrowLayout = require("../NarrowLayout/index.js");
var ProjectComponents = require("../ProjectComponents/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoLoader = require("../TacoLoader/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Project = require("../Types.Project/index.js");
var UseFetch = require("../UseFetch/index.js");
var loader = TacoLoader.component()()({
    size: "1rem"
});
var component = TofuHooks.mkHookComponent("EstismationEditProject")(function (props) {
    return function __do() {
        var appDispatch = StateHooks.useDispatch();
        var v = UseFetch.useFetchValueByProjectId(props.projectId)(function (v) {
            return v.selectedProject;
        })(Actions.GetProjectRequest.create)();
        var v1 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v1) {
            return v1.projectGroups;
        })(Actions.ProjectGroupsRequest.value)();
        var v2 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v2) {
            return v2.projectManagers;
        })(Actions.GetProjectManagersRequest.value)();
        var v3 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v3) {
            return v3.constructionTypes;
        })(Actions.ConstructionTypesRequest.value)();
        var v4 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v4) {
            return v4.financingTypes;
        })(Actions.FinancingTypesRequest.value)();
        var v5 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v5) {
            return v5.contractTypes;
        })(Actions.ContractTypesRequest.value)();
        var v6 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v6) {
            return v6.projectTypes;
        })(Actions.ProjectTypesRequest.value)();
        var v7 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var mProject = Data_Functor.map(Data_Maybe.functorMaybe)(function (v8) {
            return v8.project;
        })(v.value0);
        var projectExportButton = TacoButton.component()()({
            text: "Tee siirtotiedosto",
            onClick: new Data_Maybe.Just(Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(mProject)(function (project) {
                return appDispatch(new Actions.GetEstimationProjectExportRequest({
                    projectId: props.projectId,
                    projectName: project.name
                }));
            })),
            testId: "project-export-button"
        });
        var closeArchiveModal = v7.value1(function (v8) {
            return Data_Maybe.Nothing.value;
        });
        var archiveModalBody = TacoModal.modalBody({
            contents: [ React_Basic_DOM.text("Haluatko varmasti poistaa t\xe4m\xe4n hankkeen?") ]
        });
        var archiveModalActions = function (params) {
            return TacoModal.modalActions({
                contents: [ TacoButton.component()()({
                    onClick: new Data_Maybe.Just(closeArchiveModal),
                    text: "Peruuta"
                }), TacoButton.component()()({
                    text: "Poista",
                    color: TacoConstants.SecondaryRed.value,
                    onClick: new Data_Maybe.Just(function __do() {
                        appDispatch(new Actions.UpdateProjectArchiveStatusRequest(params))();
                        return closeArchiveModal();
                    })
                }) ]
            });
        };
        var archiveModal = (function () {
            if (v7.value0 instanceof Data_Maybe.Nothing) {
                return React_Basic.empty;
            };
            if (v7.value0 instanceof Data_Maybe.Just) {
                return TacoModal.component()()({
                    isActive: true,
                    onClose: closeArchiveModal,
                    heading: "Poista hanke",
                    contents: [ archiveModalBody, archiveModalActions(v7.value0.value0) ]
                });
            };
            throw new Error("Failed pattern match at EstimationEditProject (line 70, column 20 - line 80, column 10): " + [ v7.value0.constructor.name ]);
        })();
        var archiveButton = TacoButton.component()()({
            text: "Poista hanke",
            title: "Poistettuihin hankkeisiin ei p\xe4\xe4se en\xe4\xe4 k\xe4siksi",
            color: TacoConstants.SecondaryRed.value,
            onClick: Data_Maybe.Just.create(v7.value1(function (v8) {
                return Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(mProject)(function (project) {
                    return {
                        projectId: props.projectId,
                        value: {
                            setArchived: true,
                            applicationMode: Types_Project.EstimationMode.value,
                            projectCode: project.code,
                            projectVersion: project.version
                        }
                    };
                });
            })),
            testId: "archive-project-button"
        });
        var bottomButtons = Controls.component()()({
            leftControls: [ projectExportButton ],
            rightControls: [ archiveButton, archiveModal ],
            topGutter: true
        });
        var content = (function () {
            if (mProject instanceof Data_Maybe.Just) {
                return React_Basic_DOM_Generated.div()({
                    className: "edit-project-page",
                    children: [ TacoText.component()()({
                        text: "Hankkeen tiedot",
                        weight: TacoText_Types.Bold.value,
                        gutterBottom: true,
                        variant: new TacoText_Types.Heading(1)
                    }), ProjectComponents.component({
                        mProjectId: new Data_Maybe.Just(props.projectId),
                        mProject: new Data_Maybe.Just(mProject.value0),
                        mVATExclusionCostGroupsString: Data_Maybe.Nothing.value,
                        mConstructionTypes: v3.value0,
                        projectComponentsMode: ProjectComponents.EditEstimationProjectMode.value,
                        disableClassifications: true,
                        mProjectManagers: v2.value0,
                        mProjectTypes: v6.value0,
                        mOrgAddressArea: Data_Maybe.Nothing.value,
                        mClassifications: Data_Maybe.Nothing.value,
                        mProjectGroups: v1.value0,
                        mFinancingTypes: v4.value0,
                        mContractTypes: v5.value0,
                        mProjectList: Data_Maybe.Nothing.value
                    }), React_Basic_DOM_Generated.div()({
                        className: "measurement-units-container",
                        children: [ Measurements.component({
                            projectId: props.projectId,
                            applicationMode: Types_Project.EstimationMode.value
                        }) ]
                    }), bottomButtons ]
                });
            };
            return loader;
        })();
        return NarrowLayout.component()()({
            verticalPadding: true,
            children: [ content ]
        });
    };
});
module.exports = {
    component: component
};
