import { useWorkerState } from "../../../hooks/useWorkerState";
import { Table } from "../../../components/Table";
import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  GridApi,
  GridReadyEvent,
  IAggFunc,
  ICellRendererParams,
  IStatusPanelParams,
  SideBarDef,
  StatusPanelDef,
  ValueGetterParams,
} from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { ComponentWithResources } from "../../../../../ts-bindings/ComponentWithResources";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { TFunction, useTranslation } from "react-i18next";
import { PrintingControls } from "./PrintingControls";
import { AgGridReact } from "ag-grid-react";
import { useDefault } from "../../../hooks/useDefault";
import "../../../components/Table/alpineTheme.css";
import { ProjectClassificationClass } from "../../../../../ts-bindings/ProjectClassificationClass";
import { RadioProps } from "@tocoman/ui/dist/components/Radio";
import { useFeatureEnabled } from "../../../split/useFeatureEnabled";
import { FeatureFlags } from "../../../split/FeatureFlags";
import {
  avgHourlyRate,
  costClassColumnDefinitions,
} from "./costClassColumnDefinitions";
import {
  aggMeasurementRate,
  measurementColumnDefinitions,
} from "./measurementColumnDefinitions";
import { TotalPriceByCostClassCode } from "ts-bindings/TotalPriceByCostClassCode";
import { CostClass } from "ts-bindings/CostClass";
import { ExportToExcelStatusPanelButton } from "../../../utils/Printing/ExportToExcelStatusPanelButton";
import { Measurement } from "ts-bindings/Measurement";
import { usePrintingConfigurations } from "./usePrintingConfigurations";
import { PrintingConfiguration } from "@prisma/client";
import { Subproject } from "../../../../../ts-bindings/Subproject";
import {
  ResourceDetails,
  ResourceProcessRowGroupForExportParams,
} from "./ResourceDetails/ResourceDetails";
import { usePrintingExcelExportSettings } from "./excel/usePrintingExcelExportSettings";
import {
  AgGridConfigurationData,
  useAgGridConfiguration,
} from "./useAgGridConfiguration";
import { AdvancedSettingsPopover } from "./AdvancedSettingsPopover";
import { pinnedTotalRowRenderer, useSetPinnedRow } from "./useSetPinnedRow";
import { handleModelChange, handlePivotChange } from "./handleModelChanges";
import { ClassificationGroup } from "../../../../../ts-bindings/ClassificationGroup";
import { SaveSettingsPopover } from "./SaveSettingsPopover";
import { getCurrencyToLocale } from "src/client-ts/utils/currency";

type PrintingProps = {
  projectId: number;
};

type TotalPriceCostClasses = {
  [costClassCode: string]: TotalPriceByCostClassCode;
};

export interface ComponentSummary
  extends Omit<ComponentWithResources, "totalPriceByCostClasses"> {
  costClasses: TotalPriceCostClasses;
  measurements: {
    [name: string]: Measurement;
  };
  id?: string;
}

export type PrintingTableContext = {
  totalSum: number;
  t: TFunction<"estimation", "printing">;
  classifications: ProjectClassificationClass[] | null;
  measurements: Measurement[];
  components: ComponentWithResources[] | null;
  costClasses: CostClass[];
  subprojects: Subproject[];
  applyConfiguration: (configuration: AgGridConfigurationData | string) => void;
  classificationGroups: ClassificationGroup[];
  currency: string;
};
export function Printing({ projectId }: PrintingProps) {
  const { t } = useTranslation("estimation", { keyPrefix: "printing" });

  const printoutViewManagementEnabled = useFeatureEnabled(
    FeatureFlags.PRINTOUT_VIEW_MANAGEMENT
  );

  const userAppRights = useDefault(useWorkerState("UserAppAccessRightsState"), {
    costControl: false,
    estimation: false,
    organization: false,
    integration: false,
    superAdmin: false,
    admin: false,
  });

  const printingConfigurations = usePrintingConfigurations();

  const components = useWorkerState("ComponentsWithResourcesState", projectId);
  const costClasses = useDefault(
    useWorkerState("EstimationCostClassesState", projectId),
    []
  );
  const classifications = useWorkerState(
    "ProjectClassificationClassesState",
    projectId
  );
  const measurements = useDefault(
    useWorkerState("MeasurementsState", projectId),
    []
  );
  const subprojects = useDefault(
    useWorkerState("EstimationSubprojectsState", projectId),
    []
  );

  const classificationGroups = useDefault(
    useWorkerState("EstimationClassificationGroupsState", projectId),
    []
  );

  const groupTypeOptions: RadioProps[] = useMemo(
    () => [
      { value: "singleColumn", label: t`advancedSettings.singleColumn` },
      { value: "multipleColumns", label: t`advancedSettings.multipleColumns` },
      { value: "groupRows", label: t`advancedSettings.groupRows` },
    ],
    [t]
  );

  const project = useWorkerState("ProjectDetailsDataState", projectId);

  const [
    selectedConfiguration,
    setSelectedConfiguration,
  ] = useState<PrintingConfiguration | null>(null);
  const gridRef = useRef<AgGridReact<ComponentSummary>>(null);
  const [gridReady, setGridReady] = useState(false);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const {
    includeTotalFooter,
    groupDisplayType,
    groupIncludeFooter,
    includeResources,
    includeTotalFooterWithSocial,
    setGroupDisplayType,
    setGroupIncludeFooter,
    setIncludeTotalFooter,
    setIncludeResources,
    setIncludeTotalFooterWithSocial,
    applyConfiguration,
    initialState,
    gridVisible,
  } = useAgGridConfiguration(gridApi);

  const [detailsRendered, setDetailsRendered] = useState(false);

  const onGridReady = (event: GridReadyEvent) => {
    setGridReady(true);
    setGridApi(event.api);
  };

  const calculateTotalSum = useMemo(() => {
    if (!components) {
      return 0;
    }
    return components.reduce(
      (previousValue, currentValue) =>
        previousValue + currentValue.totalPriceWithoutSocialExpenses,
      0
    );
  }, [components]);

  const data: ComponentSummary[] | null = useMemo(() => {
    return (
      components?.map((component) => {
        const { totalPriceByCostClasses, ...restOfComponent } = component;
        return {
          ...restOfComponent,
          costClasses: totalPriceByCostClasses.reduce(
            (previousValue, currentValue) => ({
              ...previousValue,
              [currentValue.costClassCode]: currentValue,
            }),
            {}
          ),
          measurements: measurements.reduce(
            (previousValue, currentValue) => ({
              ...previousValue,
              [currentValue.unit.name]: currentValue,
            }),
            {}
          ),
        };
      }) ?? null
    );
  }, [components, measurements]);

  const [rowData, setRowData] = useState(data);

  useEffect(() => {
    setRowData(data);
  }, [data]);

  const groupCellRendererSelector = useCallback(
    (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return {
          component: (p: ICellRendererParams) => pinnedTotalRowRenderer(p, t),
          params: {
            style: { fontWeight: "bold" },
          },
        };
      } else {
        return { component: "agGroupCellRenderer" };
      }
    },
    [t]
  );

  const columnDefs: (
    | ColDef<ComponentSummary>
    | ColGroupDef<ComponentSummary>
  )[] = useMemo(() => {
    const columns: (
      | ColDef<ComponentSummary>
      | ColGroupDef<ComponentSummary>
    )[] = [
      {
        colId: "classificationGroup",
        headerName: t`tableColumns.classificationGroup`,
        valueGetter: getClassificationGroup,
        filter: "agSetColumnFilter",
        initialRowGroup: true,
        initialHide: true,
        aggFunc: null,
      },
      {
        colId: "classification",
        headerName: t`tableColumns.classification`,
        minWidth: 125,
        valueGetter: getClassification,
        filter: "agSetColumnFilter",
        initialRowGroup: true,
        initialHide: true,
        aggFunc: null,
      },
      {
        field: "component.group",
        headerName: t`tableColumns.group`,
        aggFunc: null,
        valueGetter: getComponentsGroup,
      },
      {
        field: "component.code",
        headerName: t`tableColumns.code`,
        aggFunc: null,
      },
      {
        field: "component.description",
        headerName: t`tableColumns.description`,
        aggFunc: null,
      },
      {
        field: "component.subProjectName",
        headerName: t`tableColumns.subProjectName`,
        aggFunc: null,
      },
      {
        field: "component.subProjectCode",
        headerName: t`tableColumns.subProjectCode`,
        initialWidth: 70,
        aggFunc: null,
      },
      {
        field: "component.costGroupCode",
        headerName: t`tableColumns.costGroupCode`,
        initialWidth: 115,
        aggFunc: null,
      },
      {
        field: "component.costGroupDescription",
        headerName: t`tableColumns.costGroupDescription`,
        initialWidth: 115,
        aggFunc: null,
      },

      {
        colId: "costGroupWithDescription",
        headerName: t`tableColumns.costGroupWithDescription`,
        initialWidth: 115,
        aggFunc: null,
        valueGetter: getCostGroupWithDescription,
      },
      {
        field: "component.amount",
        headerName: t`tableColumns.amount`,
        initialWidth: 105,
        type: ["twoDecimal"],
        aggFunc: null,
      },
      {
        field: "component.unit",
        headerName: t`tableColumns.unit`,
        initialWidth: 105,
        aggFunc: null,
      },
      {
        headerName: t`tableColumns.total`,
        groupId: "totals",
        children: [
          {
            colId: "component.hoursPerUnit",
            headerName: t`tableColumns.hoursPerUnit`,
            initialWidth: 120,
            valueGetter: getTotalHoursPerUnit,
            type: ["twoDecimal"],
            aggFunc: null,
          },
          {
            field: "hours",
            headerName: t`tableColumns.hours`,
            initialWidth: 120,
            type: ["twoDecimal"],
            valueGetter: (params) => getHours(params, gridRef),
            initialAggFunc: "sum",
          },
          {
            colId: "pricePerUnit",
            headerName: t("tableColumns.pricePerUnit", {
              currency: getCurrencyToLocale(project?.currency),
            }),
            initialWidth: 125,
            type: ["money"],
            valueGetter: getPricePerUnit,
            aggFunc: null,
          },
          {
            colId: "pricePerUnitWithSocial",
            headerName: t`tableColumns.pricePerUnitWithSocial`,
            initialWidth: 250,
            type: ["money"],
            valueGetter: getPricePerUnitWithSocial,
            aggFunc: null,
          },
          {
            field: "totalPrice",
            headerName: t`tableColumns.totalPrice`,
            type: ["money"],
            valueGetter: (params) =>
              getPriceWithSocialExpenses(params, gridRef),
            initialWidth: 250,
            initialAggFunc: "sum",
          },
          {
            colId: "percentageOfTotal",
            headerName: t`tableColumns.percentageOfTotal`,
            initialWidth: 105,
            valueGetter: getPercentageOfTotal,
            type: ["percentage"],
            initialAggFunc: "sum",
          },
          {
            field: "totalPriceWithoutSocialExpenses",
            headerName: t`tableColumns.totalPriceWithoutSocialExpenses`,
            initialWidth: 175,
            valueGetter: (params) =>
              getTotalPriceWithoutSocialExpenses(params, gridRef),
            type: ["money"],
            initialAggFunc: "sum",
          },
          {
            colId: "totalSocialExpenses",
            headerName: t`tableColumns.totalSocialExpenses`,
            valueGetter: (params) => getSocialExpenses(params, gridRef),
            type: ["money"],
            initialWidth: 150,
            initialAggFunc: "sum",
          },
          {
            colId: "socialCostPercentage",
            headerName: t`tableColumns.socialCostPercentage`,
            valueGetter: getSocialCostPercentage,
            type: ["percentage"],
            initialWidth: 175,
            aggFunc: null,
          },
        ],
      },
    ];

    return columns.concat(
      costClassColumnDefinitions(costClasses, gridRef, t),
      measurementColumnDefinitions(measurements, project?.currency ?? "EUR")
    );
  }, [t, costClasses, measurements, groupCellRendererSelector]);

  const sideBar: SideBarDef = useMemo(() => {
    return {
      toolPanels: [
        {
          id: "columns",
          labelDefault: t`columns`,
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressSyncLayoutWithGrid: false,
          },
        },
      ],
    };
  }, [t]);

  const statusBar = useMemo<{ statusPanels: StatusPanelDef[] }>(() => {
    return {
      statusPanels: [
        {
          statusPanel: (params: IStatusPanelParams) =>
            ExportToExcelStatusPanelButton(params, "estimation"),
        },
      ],
    };
  }, []);

  const defaultColDef: ColDef<ComponentSummary> = useMemo(() => {
    return {
      cellClassRules: {
        bold: (params) => {
          const pivotMode = params.api.getGridOption("pivotMode");
          return params.node.group && !pivotMode ? params.node.group : false;
        },
      },
      resizable: true,
      sortable: true,
      enableRowGroup: true,
      enableValue: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      filterParams: {
        defaultOption: "startsWith",
      },
      cellClass: cellClasses,
    };
  }, []);

  const aggFuncs: Record<string, IAggFunc<ComponentSummary>> = {
    avgHourlyRate: avgHourlyRate,
    measurementPriceRate: aggMeasurementRate,
  };

  const autoGroupColumnDefs: ColDef<ComponentSummary> = useMemo(() => {
    return {
      filter: "agGroupColumnFilter",
      headerName: t`groupColumn.headerName`,
      colId: "autoGroupColumn",
      cellRendererParams: {
        suppressCount: true,
        suppressPadding: true,
        footerValueGetter: (params: {
          node: { level: number };
          value: string;
        }) => {
          if (params.node.level !== -1) {
            return `${t("subtotal")} ${params.value}`;
          }
        },
      },
      cellRendererSelector: groupCellRendererSelector,
    };
  }, [groupCellRendererSelector, t]);

  const groupRowRendererParams = {
    suppressCount: true,
  };
  useEffect(() => {
    if (data && gridReady && gridVisible && gridApi && !gridApi.isDestroyed()) {
      const newRowData = data.map((data) => ({
        ...data,
        isMaster: includeResources ? data.resources.length > 0 : false,
      }));
      setRowData(newRowData);
      gridApi.setGridOption("rowData", newRowData);
    }
  }, [data, gridReady, includeResources, gridVisible, gridApi]);

  // eslint-disable-next-line
  const isRowMaster = useCallback((data: any) => {
    return data.isMaster ? data.isMaster : false;
  }, []);

  const detailCellRenderer = useMemo(
    () => (detailParams: ResourceProcessRowGroupForExportParams) => {
      return ResourceDetails(detailParams, setDetailsRendered);
    },
    []
  );

  const printingExcelSettings = usePrintingExcelExportSettings(
    selectedConfiguration?.name ?? "",
    project,
    includeResources,
    gridApi
  );

  const tableContext: PrintingTableContext = {
    totalSum: calculateTotalSum,
    t,
    classifications,
    measurements,
    components,
    costClasses,
    subprojects,
    applyConfiguration,
    classificationGroups,
    currency: project?.currency ?? "EUR",
  };

  useSetPinnedRow(
    includeTotalFooter,
    includeTotalFooterWithSocial,
    gridReady,
    gridApi
  );

  return (
    <div className={"flex flex-col h-full"}>
      <div className={"flex justify-between items-center"}>
        <PrintingControls
          onModelChange={(printingConfiguration) => {
            setSelectedConfiguration(printingConfiguration);
            if (printingConfiguration?.agGridConfiguration) {
              applyConfiguration(printingConfiguration.agGridConfiguration);
            }
          }}
          selectedModel={selectedConfiguration}
          printingConfigurations={printingConfigurations}
        />
        {gridRef.current?.api && gridVisible && gridReady && (
          <div className={"flex justify-end gap-3"}>
            {printoutViewManagementEnabled && userAppRights.admin && (
              <SaveSettingsPopover
                api={gridApi ?? gridRef.current.api}
                groupFooter={groupIncludeFooter}
                totalFooter={includeTotalFooter}
                totalFooterWithSocial={includeTotalFooterWithSocial}
                includeResources={includeResources}
                allConfigurations={printingConfigurations}
                selectedConfiguration={selectedConfiguration}
                changeSelectedConfiguration={setSelectedConfiguration}
                userAppRights={userAppRights}
                // @ts-ignore untyped in AgGrid
                context={gridRef.current.context}
                applyConfiguration={applyConfiguration}
                groupDisplayType={groupDisplayType}
              />
            )}
            <AdvancedSettingsPopover
              groupIncludeFooter={groupIncludeFooter}
              onGroupIncludeFooterChange={setGroupIncludeFooter}
              groupIncludeTotalFooter={includeTotalFooter}
              onGroupIncludeTotalFooterChange={setIncludeTotalFooter}
              groupDisplayType={groupDisplayType}
              onGroupDisplayTypeChange={setGroupDisplayType}
              groupTypeOptions={groupTypeOptions}
              includeResources={includeResources}
              onIncludeResourcesChange={setIncludeResources}
              includeSocialTotal={includeTotalFooterWithSocial}
              onIncludeSocialTotalChange={setIncludeTotalFooterWithSocial}
              api={gridApi ?? gridRef.current.api}
              // @ts-ignore untyped in AgGrid
              context={gridRef.current.context}
            />
          </div>
        )}
      </div>

      {gridVisible && (
        <Table<ComponentSummary>
          gridRef={gridRef}
          className={"h-full m-4 p-4"}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          {...printingExcelSettings}
          rowGroupPanelShow={"always"}
          autoGroupColumnDef={autoGroupColumnDefs}
          suppressAggFuncInHeader={true}
          groupIncludeFooter={groupIncludeFooter}
          // Group should expand by default unless it is a master detail row
          isGroupOpenByDefault={({ rowNode: { master } }) => !master}
          aggFuncs={aggFuncs}
          tooltipShowDelay={500}
          context={tableContext}
          statusBar={statusBar}
          masterDetail
          detailCellRenderer={detailCellRenderer}
          isRowMaster={isRowMaster}
          onGridReady={onGridReady}
          detailRowAutoHeight={detailsRendered}
          maintainColumnOrder={true}
          groupMaintainOrder={true}
          onColumnPivotModeChanged={handlePivotChange}
          onModelUpdated={handleModelChange}
          pagination={false}
          initialState={initialState}
          sideBar={sideBar}
          groupRowRendererParams={groupRowRendererParams}
        />
      )}
    </div>
  );
}

export function getTotalHoursPerUnit(
  params: ValueGetterParams<ComponentSummary>
) {
  if (!params.data || params.node?.rowPinned) {
    return null;
  }
  const { amount } = params.data.component;
  return amount !== 0 ? params.data.hours / amount : 0;
}

function getHours(
  params: ValueGetterParams<ComponentSummary>,
  gridRef: React.RefObject<AgGridReact<ComponentSummary>>
) {
  if (params.node?.rowPinned) {
    return calculateTotalPinned(gridRef, "hours");
  }
  return params.data?.hours;
}

function getPricePerUnit(params: ValueGetterParams<ComponentSummary>) {
  if (params.data && !params.node?.rowPinned) {
    return params.data.component.amount !== 0
      ? params.data.totalPriceWithoutSocialExpenses /
          params.data.component.amount
      : 0;
  }
  return null;
}

export function getPricePerUnitWithSocial(
  params: ValueGetterParams<ComponentSummary>
) {
  if (params.data && !params.node?.rowPinned) {
    const pricePerUnit =
      params.data.component.amount !== 0
        ? params.data.totalPriceWithoutSocialExpenses /
          params.data.component.amount
        : 0;

    const socialPercentCostClass:
      | CostClass
      | undefined = params.context.costClasses.find((costClass: CostClass) => {
      return costClass.hourlyPricing;
    });
    const socialPercent =
      socialPercentCostClass?.socialExpensePercentageInCostEstimation ?? 0;
    return pricePerUnit * (1 + socialPercent);
  }
  return null;
}

export function cellClasses(params: CellClassParams) {
  const pivotMode = params.api.getGridOption("pivotMode");
  if (pivotMode) {
    return params.node.isRowPinned() ? "bold" : "normal";
  }
  if (params.node.footer || params.node.group || params.node.rowPinned) {
    return "bold";
  }
}

export function getClassificationGroup(
  params: ValueGetterParams<ComponentSummary>
) {
  const { classifications, t } = params.context;
  if (!classifications || !params.data || params.node?.rowPinned) {
    return null;
  }
  const classification = classifications.find(
    (classification: ProjectClassificationClass) =>
      params.data?.component.code &&
      classification.code === params.data.component.code.slice(0, 1)
  );
  return classification
    ? `${classification.code} - ${classification.description}`
    : t`tableColumns.otherCode`;
}

export function getClassification(params: ValueGetterParams<ComponentSummary>) {
  const { classifications, t } = params.context;
  const data = params.data;
  if (!classifications || !data || params.node?.rowPinned) {
    return null;
  }

  const classification = classifications.find(
    (classification: ProjectClassificationClass) =>
      data.component.code &&
      classification.code === data.component.code.slice(0, 2)
  );
  return classification
    ? `${classification.code} - ${classification.description}`
    : t`tableColumns.otherCode`;
}

export function getPercentageOfTotal(
  params: ValueGetterParams<ComponentSummary>
) {
  if (!params.data || params.node?.rowPinned) {
    return null;
  }
  const { totalSum }: PrintingTableContext = params.context;
  if (totalSum === 0) {
    return 0;
  }
  return params.data.totalPriceWithoutSocialExpenses / totalSum;
}

export function getSocialCostPercentage(
  params: ValueGetterParams<ComponentSummary>
) {
  const socialExpenses = params.getValue("totalSocialExpenses");
  if (!params.data || socialExpenses === null) {
    return null;
  }

  return socialExpenses / params.data.totalPriceWithoutSocialExpenses;
}

export function getPriceWithSocialExpenses(
  params: ValueGetterParams<ComponentSummary>,
  gridRef: React.RefObject<AgGridReact<ComponentSummary>>
) {
  if (!params.data) {
    return null;
  }
  if (params.node?.rowPinned) {
    return calculateTotalPriceSumPinned(gridRef);
  }
  const socialExpenses = params.getValue("totalSocialExpenses");
  return socialExpenses
    ? params.data.totalPriceWithoutSocialExpenses + socialExpenses
    : params.data.totalPriceWithoutSocialExpenses;
}

export function getTotalPriceWithoutSocialExpenses(
  params: ValueGetterParams<ComponentSummary>,
  gridRef: React.RefObject<AgGridReact<ComponentSummary>>
) {
  if (!params.data) {
    return null;
  }
  if (params.node?.rowPinned) {
    return calculateCorrectPinnedRow(params.node.data?.id, gridRef);
  }
  return params.data.totalPriceWithoutSocialExpenses;
}

function calculateCorrectPinnedRow(
  pinnedRowId: string | undefined,
  gridRef: React.RefObject<AgGridReact<ComponentSummary>>
) {
  if (pinnedRowId === "totalRow") {
    return calculateTotalPriceWithoutSocialExpensesSumPinned(gridRef);
  }
  if (pinnedRowId === "totalRowWithSocial") {
    return calculateTotalPriceSumPinned(gridRef);
  }
}

function getSocialExpenses(
  params: ValueGetterParams<ComponentSummary>,
  gridRef: React.RefObject<AgGridReact<ComponentSummary>>
) {
  if (!params.data) {
    return null;
  }
  if (params.node?.rowPinned) {
    return calculateTotalPinned(gridRef, "totalSocialExpenses");
  }

  return calculateTotalSocialExpenses(
    params.data.costClasses,
    params.context.costClasses
  );
}

export function calculateTotalPinned(
  gridRef: React.RefObject<AgGridReact<ComponentSummary>>,
  column: string
) {
  let total = 0;
  if (gridRef.current) {
    gridRef.current.api.forEachNodeAfterFilter((node) => {
      if (!node.group) {
        const nodeValue = gridRef.current?.api.getValue(column, node);
        total += nodeValue;
      }
    });
  }
  return total;
}

export function calculateTotalSocialExpenses(
  costClassPrices: TotalPriceCostClasses,
  costClasses: CostClass[]
) {
  const costClassPriceArray = Object.values(costClassPrices);
  return costClassPriceArray.reduce(
    (previousValue: number, currentValue: TotalPriceByCostClassCode) => {
      const currentCostClass = costClasses.find(
        (cc) => cc.costClassCode === currentValue.costClassCode
      );
      if (
        currentCostClass &&
        currentCostClass.socialExpensePercentageInCostEstimation
      ) {
        return (
          previousValue +
          currentCostClass.socialExpensePercentageInCostEstimation *
            currentValue.totalPriceWithoutSocialExpenses
        );
      }
      return previousValue;
    },
    0
  );
}

export const calculateTotalPriceWithoutSocialExpensesSumPinned = (
  gridRef: React.RefObject<AgGridReact<ComponentSummary>>
) => {
  if (gridRef.current) {
    let sum = 0;
    gridRef.current.api.forEachNodeAfterFilter((node) => {
      const total = node.data?.totalPriceWithoutSocialExpenses ?? 0;
      sum += total;
    });
    return sum;
  }
  return null;
};

export const calculateTotalPriceSumPinned = (
  gridRef: React.RefObject<AgGridReact<ComponentSummary>>
) => {
  if (gridRef.current) {
    let totalPrice = 0;
    let socialExpenses = 0;
    gridRef.current.api.forEachNodeAfterFilter((node) => {
      const total = node.data?.totalPriceWithoutSocialExpenses ?? 0;
      totalPrice += total;
      if (!node.group) {
        const social = gridRef.current?.api.getValue(
          "totalSocialExpenses",
          node
        );
        socialExpenses += social;
      }
    });
    const total = totalPrice + socialExpenses;
    return total ?? null;
  }
  return null;
};

function getCostGroupWithDescription(params: ValueGetterParams) {
  if (
    !params.data?.component.costGroupCode ||
    !params.data?.component.costGroupDescription
  ) {
    return null;
  }
  return `${params.data?.component.costGroupCode} - ${params.data?.component.costGroupDescription}`;
}

function getComponentsGroup(params: ValueGetterParams) {
  if (!params.data?.component.group) {
    return null;
  }
  const groupDescription = params.context.classificationGroups.find(
    (group: ClassificationGroup) => group.code === params.data?.component.group
  )?.description;
  return groupDescription
    ? `${params.data?.component.group} - ${groupDescription}`
    : params.data?.component.group;
}
