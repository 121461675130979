// Generated by purs version 0.14.5
"use strict";
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var TacoInput = require("../TacoInput/index.js");
var lengthValidator = function (minLength) {
    return function (maxLength) {
        return function (value) {
            var v = Data_String_CodeUnits.length(value);
            if (v > maxLength) {
                return TacoInput.Invalid.create("Sy\xf6tetty arvo ei saa olla yli " + (Data_Show.show(Data_Show.showInt)(maxLength) + " merkki\xe4 pitk\xe4."));
            };
            if (v < minLength) {
                if (minLength === 1) {
                    return TacoInput.Invalid.create("Sy\xf6tetty arvo ei saa olla tyhj\xe4.");
                };
                return TacoInput.Invalid.create("Sy\xf6tetty arvo ei saa olla alle " + (Data_Show.show(Data_Show.showInt)(minLength) + " merkki\xe4 pitk\xe4."));
            };
            return TacoInput.Valid.value;
        };
    };
};
module.exports = {
    lengthValidator: lengthValidator
};
