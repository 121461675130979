import { useMutation, useQuery } from "react-query";
import axiosClient from "src/client-ts/axiosConfig";
import { DatabaseChangelog } from "src/server-ts/super-admin/organizations/SuperAdminOrganizationQueries";

const baseUrl = "/super-admin/organizations/migrate";
export function useOrganizationMigrationQuery(org: string) {
  return useQuery<DatabaseChangelog[]>([baseUrl], async () => {
    const { data } = await axiosClient.get(`${baseUrl}/${org}/`);
    return data;
  });
}

export function useOrganizationMigrationMutation(org: string) {
  return useMutation<DatabaseChangelog[]>({
    mutationFn: async () => {
      const { data } = await axiosClient.post(`${baseUrl}/${org}/`);
      return data;
    },
  });
}
