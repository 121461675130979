import { useMutation } from "react-query";
import { ModifiedFields } from "src/server-ts/import-file/transportFile";
import axiosClient from "../../../../axiosConfig";
import { useWorkerAction } from "../../../../hooks/useWorkerAction";
import { mkWorkerAction } from "../../../../actions/state";

type ImportModifiedProjectRequest = {
  name: string;
  code: string;
  version: string;
  currency: string;
  file: string;
  modifiedFields: ModifiedFields;
};

const baseUrl = "/importModifiedProject";
export function useUploadTransportFile() {
  const [_, refetchProjectListData] = useWorkerAction(
    "UpdateEstimationProjectsState",
    () => {
      return mkWorkerAction({
        type: "UpdateEstimationProjectsState",
        value: { type: "UpdateEstimationProjects", value: true },
      });
    }
  );

  return useMutation({
    mutationFn: async (value: ImportModifiedProjectRequest) => {
      return await axiosClient.post(baseUrl, value);
    },
    onSuccess: () => {
      refetchProjectListData();
    },
  });
}
