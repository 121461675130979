// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoCheckbox_Styles = require("../TacoCheckbox.Styles/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var imports = "import TacoCheckbox as TacoCheckbox\x0a";
var example4_jsx = TofuHooks.mkHookComponent("TacoCheckbox_Example4")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        return Box.box("checkboxExample1Container")([ Box.FlexColumn.value ])([ TacoCheckbox.component()()({
            className: "checkbox",
            isChecked: Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqInt))(v1.value0)(new Data_Maybe.Just(1)),
            onToggle: TacoCheckbox.NoEvent.create(v1.value1(function (v2) {
                return new Data_Maybe.Just(1);
            })),
            labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Demo radiobutton 1")),
            style: TacoCheckbox_Styles.RadioButton.value
        }), TacoCheckbox.component()()({
            className: "checkbox",
            isChecked: Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqInt))(v1.value0)(new Data_Maybe.Just(2)),
            onToggle: TacoCheckbox.NoEvent.create(v1.value1(function (v2) {
                return new Data_Maybe.Just(2);
            })),
            labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Demo radiobutton 2")),
            color: TacoConstants.PrimaryBlue.value,
            style: TacoCheckbox_Styles.RadioButton.value
        }), TacoCheckbox.component()()({
            className: "checkbox",
            isChecked: false,
            onToggle: new TacoCheckbox.Disabled("Disabled"),
            labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Demo radiobutton 3")),
            color: TacoConstants.PrimaryBlue.value,
            style: TacoCheckbox_Styles.RadioButton.value
        }) ]);
    };
});
var example4 = "\x0aexample4_jsx :: {} -> RB.JSX\x0aexample4_jsx = mkHookComponent \"TacoCheckbox_Example4\" \\_ -> do\x0a  Tuple selected updateSelected <- useState Nothing\x0a\x0a  pure $ box \"checkboxExample1Container\" [ FlexColumn ]\x0a    [ TacoCheckbox.component\x0a        { className: \"checkbox\"\x0a        , isChecked: selected == Just 1\x0a        , onToggle: TacoCheckbox.NoEvent $ updateSelected $ \\_ -> Just 1\x0a        , labelText: Just $ TacoCheckbox.StringLabel \"Demo radiobutton 1\"\x0a        , style: RadioButton\x0a        }\x0a    , TacoCheckbox.component\x0a        { className: \"checkbox\"\x0a        , isChecked: selected == Just 2\x0a        , onToggle: TacoCheckbox.NoEvent $ updateSelected $ \\_ -> Just 3\x0a        , labelText: Just $ TacoCheckbox.StringLabel \"Demo radiobutton 2\"\x0a        , color: PrimaryBlue\x0a        , style: RadioButton\x0a        }\x0a    , TacoCheckbox.component\x0a        { className: \"checkbox\"\x0a        , isChecked: false\x0a        , onToggle: TacoCheckbox.Disabled \"Disabled\"\x0a        , labelText: Just $ TacoCheckbox.StringLabel \"Demo radiobutton 2\"\x0a        , color: PrimaryBlue\x0a        , style: RadioButton\x0a        }\x0a    ]\x0a  ";
var example3_jsx = TofuHooks.mkHookComponent("TacoCheckbox_Example3")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState(true)();
        return Box.box("checkboxExample3Container")([ Box.FlexColumn.value, Box.AlignStart.value ])([ TacoCheckbox.component()()({
            className: "checkbox",
            isChecked: v1.value0,
            onToggle: TacoCheckbox.NoEvent.create(v1.value1(function (old) {
                return !old;
            })),
            color: TacoConstants.PrimaryBlue.value,
            noPadding: true
        }), TacoCheckbox.component()()({
            className: "checkbox",
            isChecked: true,
            onToggle: new TacoCheckbox.Disabled("Disabled"),
            color: TacoConstants.PrimaryBlue.value,
            noPadding: true
        }) ]);
    };
});
var example3 = imports + "\x0aTuple checked setChecked <- useState true\x0a\x0apure $ box \"checkboxExample3Container\" [ FlexColumn, AlignStart ]\x0a  [ TacoCheckbox.component\x0a      { className: \"checkbox\"\x0a      , isChecked: checked\x0a      , onToggle: TacoCheckbox.NoEvent $ setChecked \\old -> not old\x0a      , color: PrimaryBlue\x0a      , noPadding: true\x0a      }\x0a  , TacoCheckbox.component\x0a      { className: \"checkbox\"\x0a      , isChecked: true\x0a      , onToggle: TacoCheckbox.Disabled \"Disabled\"\x0a      , color: PrimaryBlue\x0a      , noPadding: true\x0a      }\x0a  ]\x0a";
var example2_jsx = TofuHooks.mkHookComponent("TacoCheckbox_Example2")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState(true)();
        return Box.box("checkboxExample2Container")([ Box.FlexColumn.value, Box.AlignStart.value ])([ TacoCheckbox.component()()({
            className: "checkbox",
            isChecked: v1.value0,
            onToggle: TacoCheckbox.NoEvent.create(v1.value1(function (old) {
                return !old;
            })),
            color: TacoConstants.PrimaryBlue.value
        }), TacoCheckbox.component()()({
            className: "checkbox",
            isChecked: true,
            onToggle: new TacoCheckbox.Disabled("Disabled"),
            color: TacoConstants.PrimaryBlue.value
        }) ]);
    };
});
var example2 = imports + "\x0aTuple checked setChecked <- useState true\x0a\x0apure $ box \"checkboxExample2Container\" [ FlexColumn, AlignStart ]\x0a  [ TacoCheckbox.component\x0a      { className: \"checkbox\"\x0a      , isChecked: checked\x0a      , onToggle: TacoCheckbox.NoEvent $ setChecked \\old -> not old\x0a      , color: PrimaryBlue\x0a      }\x0a  , TacoCheckbox.component\x0a      { className: \"checkbox\"\x0a      , isChecked: true\x0a      , onToggle: TacoCheckbox.Disabled \"Disabled\"\x0a      , color: PrimaryBlue\x0a      }\x0a  ]\x0a";
var example1_jsx = TofuHooks.mkHookComponent("TacoCheckbox_Example1")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState(true)();
        return Box.box("checkboxExample1Container")([ Box.FlexColumn.value ])([ TacoCheckbox.component()()({
            className: "checkbox",
            isChecked: v1.value0,
            onToggle: TacoCheckbox.NoEvent.create(v1.value1(function (old) {
                return !old;
            })),
            labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Demo checkbox"))
        }), TacoCheckbox.component()()({
            className: "checkbox",
            isChecked: true,
            onToggle: new TacoCheckbox.Disabled("Disabled"),
            labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Demo checkbox")),
            color: TacoConstants.PrimaryBlue.value
        }) ]);
    };
});
var example1 = imports + "\x0aTuple checked setChecked <- useState true\x0a\x0apure $ box \"checkboxExample1Container\" [ FlexColumn ]\x0a  [ TacoCheckbox.component\x0a      { className: \"checkbox\"\x0a      , isChecked: checked\x0a      , onToggle: TacoCheckbox.NoEvent $ setChecked \\old -> not old\x0a      , labelText: Just $ TacoCheckbox.StringLabel \"Demo checkbox\"\x0a      , color: PrimaryBlue\x0a      }\x0a  , TacoCheckbox.component\x0a      { className: \"checkbox\"\x0a      , isChecked: true\x0a      , onToggle: TacoCheckbox.Disabled \"Disabled\"\x0a      , labelText: Just $ TacoCheckbox.StringLabel \"Demo checkbox\"\x0a      , color: PrimaryBlue\x0a      }\x0a  ]\x0a";
var sections = [ {
    title: "Default",
    view: [ example1_jsx({}) ],
    code: example1
}, {
    title: "Without title",
    view: [ example2_jsx({}) ],
    code: example2
}, {
    title: "Without padding",
    view: [ example3_jsx({}) ],
    code: example3
}, {
    title: "Radio button",
    view: [ example4_jsx({}) ],
    code: example4
} ];
var demoView = {
    sections: sections,
    title: "TacoCheckbox",
    description: "Checkbox component"
};
module.exports = {
    demoView: demoView
};
