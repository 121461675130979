import { RadioProps } from "@tocoman/ui/dist/components/Radio";
import { useTranslation } from "react-i18next";
import React, { useCallback, useState } from "react";
import { Popover } from "@mui/material";
import {
  Button,
  Checkbox,
  Header,
  IconBurgerMenuV1,
  RadioGroup,
} from "@tocoman/ui";
import { GridApi, RowGroupingDisplayType } from "ag-grid-community";
import { useFeatureEnabled } from "../../../split/useFeatureEnabled";
import { FeatureFlags } from "../../../split/FeatureFlags";

type AdvancedSettingsPopoverProps = {
  groupIncludeFooter: boolean;
  onGroupIncludeFooterChange: (value: boolean) => void;
  groupIncludeTotalFooter: boolean;
  onGroupIncludeTotalFooterChange: (value: boolean) => void;
  groupDisplayType: RowGroupingDisplayType;
  onGroupDisplayTypeChange: (groupDisplayType: RowGroupingDisplayType) => void;
  groupTypeOptions: RadioProps[];
  includeResources: boolean;
  onIncludeResourcesChange: (value: boolean) => void;
  includeSocialTotal: boolean;
  onIncludeSocialTotalChange: (value: boolean) => void;
  api: GridApi;
};
export const AdvancedSettingsPopover = ({
  groupIncludeFooter,
  onGroupIncludeFooterChange,
  groupIncludeTotalFooter,
  onGroupIncludeTotalFooterChange,
  groupDisplayType,
  onGroupDisplayTypeChange,
  groupTypeOptions,
  includeResources,
  onIncludeResourcesChange,
  includeSocialTotal,
  onIncludeSocialTotalChange,
  api,
}: AdvancedSettingsPopoverProps) => {
  const { t } = useTranslation("estimation", {
    keyPrefix: "printing.advancedSettings",
  });

  const resourcesEnabled = useFeatureEnabled(
    FeatureFlags.ESTIMATION_PRINTING_RESOURCES
  );

  const [groupDisplay, setGroupDisplay] = useState(
    groupTypeOptions.find((option) => option.value === groupDisplayType) ||
      groupTypeOptions[0]
  );

  const [isOpen, setIsOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onChangeGroupDisplayType = useCallback(
    (selected: RadioProps) => {
      if (api) {
        const { value } = selected;
        value === "singleColumn" &&
          api.setGridOption("groupDisplayType", "singleColumn");
        value === "multipleColumns" &&
          api.setGridOption("groupDisplayType", "multipleColumns");
        value === "groupRows" &&
          api.setGridOption("groupDisplayType", "groupRows");
        setGroupDisplay(selected);
        onGroupDisplayTypeChange(selected.value as RowGroupingDisplayType);
      }
    },
    [onGroupDisplayTypeChange, api]
  );
  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(!isOpen);
  };

  const [expandResources, onExpandResourcesChanged] = useExpandResources(api);

  const id = isOpen ? "advanced-settings-popover" : undefined;
  if (api.isDestroyed()) {
    return null;
  }
  return (
    <>
      <Button
        aria-describedby={id}
        label={t`selections`}
        icon={IconBurgerMenuV1}
        iconPosition={"left"}
        color={"normal"}
        variant={"secondary"}
        className={"h-[30px] mr-3"}
        onClick={handleButtonClick}
        testId={"toggle-tool-panel"}
      />
      <Popover
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          zIndex: 50,
          border: 1,
          borderColor: "lightgrey",
        }}
        disablePortal={true}
      >
        <div className={"flex-col max-w-[280px] p-4"}>
          <Header title={t`selections`} titleSize={"medium"} />

          <Header title={t`groupType`} titleSize={"small"} />
          <RadioGroup
            selected={groupDisplay}
            onValueChange={onChangeGroupDisplayType}
            label=""
            items={groupTypeOptions}
          />

          <Header title={t`totalRow`} titleSize={"small"} className={"mt-5"} />
          <Checkbox
            className={"mb-3"}
            checked={groupIncludeFooter}
            setChecked={onGroupIncludeFooterChange}
            label={t`group`}
          />
          <Checkbox
            className={"mb-3"}
            checked={groupIncludeTotalFooter}
            setChecked={onGroupIncludeTotalFooterChange}
            label={t`totalWithoutSocial`}
          />
          <Checkbox
            checked={includeSocialTotal}
            setChecked={onIncludeSocialTotalChange}
            label={t`totalWithSocial`}
          />
          {resourcesEnabled && (
            <>
              <Header
                title={t`resources`}
                titleSize={"small"}
                className={"mt-5"}
              />
              <Checkbox
                className={"mb-3"}
                checked={includeResources}
                setChecked={onIncludeResourcesChange}
                label={t`showResources`}
              />

              <Checkbox
                checked={expandResources}
                setChecked={onExpandResourcesChanged}
                label={t`expandResources`}
                disabled={!includeResources}
              />
            </>
          )}
        </div>
      </Popover>
    </>
  );
};

export function useExpandResources(api: GridApi): [boolean, () => void] {
  const [expandResources, setExpandResources] = useState(false);

  const onExpandResourcesChanged = () => {
    setExpandResources(!expandResources);
    api.forEachNode((node) => {
      if (node.master) {
        node.setExpanded(!expandResources);
      }
    });
  };

  return [expandResources, onExpandResourcesChanged];
}
