import { useMemo } from "react";
import { useProjectGroups } from "./ProjectDetailsModal/useProjectGroups";
import { useUserDataQuery } from "./useUserData";

/**
 * Returns the project groups that the user has access to.
 * @returns projectGroups - List of project groups that the user has access to.
 * @returns projectGroupsLoading - Boolean indicating if the project groups are still loading.
 */
export function useUsersProjectGroups() {
  const {
    data: projectGroupsData,
    isLoading: projectGroupsLoading,
  } = useProjectGroups();

  const user = useUserDataQuery();

  const projectGroups = useMemo(() => {
    if (!user || !projectGroupsData) {
      return [];
    }
    if (user.permissions.ceReadAllProjects) {
      return projectGroupsData;
    } else {
      return projectGroupsData.filter((group) =>
        user.permissions.ceReadableProjects.projectGroups.includes(group)
      );
    }
  }, [projectGroupsData, user]);
  return { projectGroups, projectGroupsLoading };
}
