// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var dixonMeasurementGroupElementsContainerStyles = Box.boxStyle([ new Box.FlexGrow(1), Box.FlexColumn.value, new Box.Overflow(Box.Auto.value, Box.Auto.value), new Box.Selector(".__TacoSelect", [ Box.Width.create(new TacoConstants.CustomSize("100%")) ]), new Box.Selector(".DixonMeasurementGroupElementsTableRow-active", [ new Box.BackgroundColor(TacoConstants.ComponentBlueLight.value) ]), new Box.Selector(".DixonMeasurementGroupElementsTableRow-error", [ new Box.BackgroundColor(TacoConstants.Red.value), new Box.Opacity(0.0), Box.Style.create(React_Basic_Emotion.css()({
    animation: React_Basic_Emotion.str("fade-in 0.1s ease-in 0.1s forwards")
})) ]) ]);
module.exports = {
    dixonMeasurementGroupElementsContainerStyles: dixonMeasurementGroupElementsContainerStyles
};
