import {
  QueryClient,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from "react-query";
import { PrintingConfiguration } from "@prisma/client";
import axiosClient from "../../../axiosConfig";

export interface PrintingConfigurationsParams {
  id: number | null;
  name: string;
  isPublic: boolean;
  agGridConfiguration: string;
}

const baseUrl = "/printing-configurations";

export function usePrintingConfigurations(): PrintingConfiguration[] {
  const { data } = useQuery({
    queryKey: [baseUrl],
    queryFn: async () => {
      const { data } = await axiosClient.get<PrintingConfiguration[]>(baseUrl);
      return data;
    },
  });
  return data ?? [];
}

export function useCreatePrintingConfiguration(): UseMutationResult<
  PrintingConfiguration,
  unknown,
  PrintingConfigurationsParams
> {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (value: PrintingConfigurationsParams) => {
      const { data } = await axiosClient.post(baseUrl, value);
      return data;
    },
    // Can be made into a generic function
    onMutate: async (newConfiguration: PrintingConfigurationsParams) => {
      await queryClient.cancelQueries({
        queryKey: [baseUrl],
      });
      const previousConfigurations = queryClient.getQueryData<
        PrintingConfigurationsParams[]
      >([baseUrl]);

      if (previousConfigurations) {
        queryClient.setQueryData<PrintingConfigurationsParams[]>(
          [baseUrl],
          (oldConfigurations) => [
            ...(oldConfigurations ?? []),
            newConfiguration,
          ]
        );
      }

      return { previousConfigurations };
    },

    onError: (err_, variables_, context) => {
      if (context?.previousConfigurations) {
        queryClient.setQueryData<PrintingConfigurationsParams[]>(
          [baseUrl],
          context.previousConfigurations
        );
      }
    },

    onSettled: () => invalidatePrintingConfigurations(queryClient),
  });
}

export function useUpdatePrintingConfiguration(): UseMutationResult<
  PrintingConfiguration,
  unknown,
  PrintingConfigurationsParams
> {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (value: PrintingConfigurationsParams) => {
      const { data } = await axiosClient.put(`${baseUrl}/${value.id}`, value);
      return data;
    },

    onSettled: () => invalidatePrintingConfigurations(queryClient),
  });
}
export function useDeletePrintingConfiguration(): UseMutationResult<
  PrintingConfiguration,
  unknown,
  number
> {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: number) => {
      const { data } = await axiosClient.delete(`${baseUrl}/${id}`);
      return data;
    },

    onMutate: async (deletedConfigurationId: number) => {
      await queryClient.cancelQueries({
        queryKey: [baseUrl],
      });
      const previousConfigurations = queryClient.getQueryData<
        PrintingConfigurationsParams[]
      >([baseUrl]);

      if (previousConfigurations) {
        queryClient.setQueryData<PrintingConfigurationsParams[]>(
          [baseUrl],
          (oldConfigurations) => [
            ...(oldConfigurations?.filter(
              (value) => value.id !== deletedConfigurationId
            ) ?? []),
          ]
        );
      }

      return { previousConfigurations };
    },

    onError: (_err, _variables, context) => {
      if (context?.previousConfigurations) {
        queryClient.setQueryData<PrintingConfigurationsParams[]>(
          [baseUrl],
          context.previousConfigurations
        );
      }
    },

    onSettled: () => invalidatePrintingConfigurations(queryClient),
  });
}

export function useUpdatePublicPrintingConfiguration(): UseMutationResult<
  PrintingConfiguration,
  unknown,
  { id: number; isPublic: boolean }
> {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (value: { id: number; isPublic: boolean }) => {
      const { data } = await axiosClient.put(
        `${baseUrl}/${value.id}/publicity`,
        value
      );
      return data;
    },

    onSettled: () => invalidatePrintingConfigurations(queryClient),
  });
}

function invalidatePrintingConfigurations(queryClient: QueryClient) {
  return queryClient.invalidateQueries({
    queryKey: [baseUrl],
  });
}
