// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_List_NonEmpty = require("../Data.List.NonEmpty/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Show = require("../Data.Show/index.js");
var Foreign = require("../Foreign/index.js");
var translatePropertyKey = function (v) {
    if (v === "date") {
        return "P\xe4iv\xe4m\xe4\xe4r\xe4";
    };
    if (v === "sum") {
        return "Summa";
    };
    if (v === "code") {
        return "Tunnus";
    };
    if (v === "description") {
        return "Tunnus";
    };
    if (v === "costClassCode") {
        return "Kustannuslaji ID";
    };
    if (v === "amount") {
        return "Summa";
    };
    return v;
};
var toHumanError = function (v) {
    if (v instanceof Foreign.ForeignError) {
        return v.value0;
    };
    if (v instanceof Foreign.TypeMismatch) {
        return "solun tyyppi oli v\xe4\xe4r\xe4: pit\xe4isi olla " + (v.value0 + (", nyt oli " + v.value1));
    };
    if (v instanceof Foreign.ErrorAtIndex) {
        return "rivill\xe4 " + (Data_Show.show(Data_Show.showInt)(v.value0 + 1 | 0) + (" " + toHumanError(v.value1)));
    };
    if (v instanceof Foreign.ErrorAtProperty) {
        return "arvolla " + (Data_Show.show(Data_Show.showString)(translatePropertyKey(v.value0)) + (": " + toHumanError(v.value1)));
    };
    throw new Error("Failed pattern match at MultipleErrorsPrinter (line 26, column 1 - line 26, column 39): " + [ v.constructor.name ]);
};
var printHumanErrors = function (me) {
    var prependLabel = function (s) {
        return "Virhe " + s;
    };
    var entries = Data_Array.take(10)(Data_Array.reverse(Data_Array.fromFoldable(Data_List_Types.foldableNonEmptyList)(me)));
    return Data_Array.intercalate(Data_Monoid.monoidString)("\x0a")(Data_Functor.map(Data_Functor.functorArray)(function ($22) {
        return prependLabel(toHumanError($22));
    })(entries));
};
var indent = function (i) {
    return Data_Array.intercalate(Data_Monoid.monoidString)("")(Data_Array.replicate(i)("  "));
};
var toDebugErrorMsg = function (v) {
    return function (v1) {
        if (v1 instanceof Foreign.ForeignError) {
            return v1.value0;
        };
        if (v1 instanceof Foreign.TypeMismatch) {
            return "Expected type " + (v1.value0 + (", actual was " + v1.value1));
        };
        if (v1 instanceof Foreign.ErrorAtIndex) {
            return "Error at index " + (Data_Show.show(Data_Show.showInt)(v1.value0 + 1 | 0) + (":\x0a" + (indent(v) + toDebugErrorMsg(v + 1 | 0)(v1.value1))));
        };
        if (v1 instanceof Foreign.ErrorAtProperty) {
            return "Error at property " + (Data_Show.show(Data_Show.showString)(v1.value0) + (":\x0a" + (indent(v) + toDebugErrorMsg(v + 1 | 0)(v1.value1))));
        };
        throw new Error("Failed pattern match at MultipleErrorsPrinter (line 16, column 1 - line 16, column 49): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var printMultipleErrors = function (me) {
    return "Validation Error:\x0a" + Data_Foldable.intercalate(Data_List_Types.foldableNonEmptyList)(Data_Monoid.monoidString)("\x0a")(Data_Functor.map(Data_List_Types.functorNonEmptyList)(toDebugErrorMsg(1))(Data_List_NonEmpty.reverse(me)));
};
module.exports = {
    printMultipleErrors: printMultipleErrors,
    toDebugErrorMsg: toDebugErrorMsg,
    indent: indent,
    toHumanError: toHumanError,
    translatePropertyKey: translatePropertyKey,
    printHumanErrors: printHumanErrors,
    unsafeJSONStringify: $foreign.unsafeJSONStringify
};
