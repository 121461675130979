// Generated by purs version 0.14.5
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Kishimen = require("../Kishimen/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Some = (function () {
    function Some(value0) {
        this.value0 = value0;
    };
    Some.create = function (value0) {
        return new Some(value0);
    };
    return Some;
})();
var All = (function () {
    function All() {

    };
    All.value = new All();
    return All;
})();
var union = function (dictOrd) {
    return function (v) {
        return function (v1) {
            if (v instanceof All) {
                return All.value;
            };
            if (v1 instanceof All) {
                return All.value;
            };
            if (v instanceof Some && v1 instanceof Some) {
                return Some.create(Data_Set.union(dictOrd)(v.value0)(v1.value0));
            };
            throw new Error("Failed pattern match at AllSet (line 74, column 1 - line 74, column 61): " + [ v.constructor.name, v1.constructor.name ]);
        };
    };
};
var subset = function (dictOrd) {
    return function (v) {
        return function (v1) {
            if (v1 instanceof All) {
                return true;
            };
            if (v instanceof All && v1 instanceof Some) {
                return false;
            };
            if (v instanceof Some && v1 instanceof Some) {
                return Data_Set.subset(dictOrd)(v.value0)(v1.value0);
            };
            throw new Error("Failed pattern match at AllSet (line 84, column 1 - line 84, column 61): " + [ v.constructor.name, v1.constructor.name ]);
        };
    };
};
var singleton = function (a) {
    return new Some(Data_Set.singleton(a));
};
var showAllSet = function (dictShow) {
    return {
        show: function (v) {
            if (v instanceof All) {
                return "All";
            };
            if (v instanceof Some) {
                return "Some (" + (Data_Show.show(Data_Set.showSet(dictShow))(v.value0) + ")");
            };
            throw new Error("Failed pattern match at AllSet (line 30, column 1 - line 32, column 44): " + [ v.constructor.name ]);
        }
    };
};
var semigroupAllSet = function (dictOrd) {
    return {
        append: union(dictOrd)
    };
};
var properSubset = function (dictOrd) {
    return function (v) {
        return function (v1) {
            if (v instanceof Some && v1 instanceof All) {
                return true;
            };
            if (v instanceof All) {
                return false;
            };
            if (v instanceof Some && v1 instanceof Some) {
                return Data_Set.properSubset(dictOrd)(v.value0)(v1.value0);
            };
            throw new Error("Failed pattern match at AllSet (line 89, column 1 - line 89, column 67): " + [ v.constructor.name, v1.constructor.name ]);
        };
    };
};
var member = function (dictOrd) {
    return function (v) {
        return function (v1) {
            if (v1 instanceof All) {
                return true;
            };
            if (v1 instanceof Some) {
                return Data_Set.member(dictOrd)(v)(v1.value0);
            };
            throw new Error("Failed pattern match at AllSet (line 58, column 1 - line 58, column 54): " + [ v.constructor.name, v1.constructor.name ]);
        };
    };
};
var isEmpty = function (v) {
    if (v instanceof All) {
        return false;
    };
    if (v instanceof Some) {
        return Data_Set.isEmpty(v.value0);
    };
    throw new Error("Failed pattern match at AllSet (line 48, column 1 - line 48, column 41): " + [ v.constructor.name ]);
};
var intersection = function (dictOrd) {
    return function (v) {
        return function (v1) {
            if (v instanceof All) {
                return v1;
            };
            if (v1 instanceof All) {
                return v;
            };
            if (v instanceof Some && v1 instanceof Some) {
                return Some.create(Data_Set.intersection(dictOrd)(v.value0)(v1.value0));
            };
            throw new Error("Failed pattern match at AllSet (line 79, column 1 - line 79, column 68): " + [ v.constructor.name, v1.constructor.name ]);
        };
    };
};
var insert = function (dictOrd) {
    return function (v) {
        return function (v1) {
            if (v1 instanceof All) {
                return All.value;
            };
            if (v1 instanceof Some) {
                return Some.create(Data_Set.insert(dictOrd)(v)(v1.value0));
            };
            throw new Error("Failed pattern match at AllSet (line 62, column 1 - line 62, column 55): " + [ v.constructor.name, v1.constructor.name ]);
        };
    };
};
var genericAllSet = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new Some(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return All.value;
        };
        throw new Error("Failed pattern match at AllSet (line 40, column 1 - line 40, column 54): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof Some) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof All) {
            return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
        };
        throw new Error("Failed pattern match at AllSet (line 40, column 1 - line 40, column 54): " + [ x.constructor.name ]);
    }
};
var tofujsonAllSLet = function (dictOrd) {
    return function (dictTofuJSON) {
        return {
            writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
                reflectSymbol: function () {
                    return "All";
                }
            })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
                reflectSymbol: function () {
                    return "Some";
                }
            })(TofuJSON.tofuJSONSet(dictOrd)(dictTofuJSON))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
                reflectSymbol: function () {
                    return "All";
                }
            })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
                reflectSymbol: function () {
                    return "Some";
                }
            })(TofuJSON.tofuJSONSet(dictOrd)(dictTofuJSON))()(TofuJSON.nilWriteForeignVariant)))(genericAllSet)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
                reflectSymbol: function () {
                    return "Some";
                }
            })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
                reflectSymbol: function () {
                    return "All";
                }
            })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
                reflectSymbol: function () {
                    return "Some";
                }
            }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
                reflectSymbol: function () {
                    return "All";
                }
            }))),
            readImpl: TofuJSON.readGenericSum(genericAllSet)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
                reflectSymbol: function () {
                    return "Some";
                }
            })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
                reflectSymbol: function () {
                    return "All";
                }
            })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
                reflectSymbol: function () {
                    return "Some";
                }
            }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
                reflectSymbol: function () {
                    return "All";
                }
            })))()(TofuJSON.readVariantCons({
                reflectSymbol: function () {
                    return "All";
                }
            })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
                reflectSymbol: function () {
                    return "Some";
                }
            })(TofuJSON.tofuJSONSet(dictOrd)(dictTofuJSON))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
                reflectSymbol: function () {
                    return "All";
                }
            })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
                reflectSymbol: function () {
                    return "Some";
                }
            })(TofuJSON.tofuJSONSet(dictOrd)(dictTofuJSON))()(TofuJSON.nilWriteForeignVariant)))
        };
    };
};
var fromFoldable = function (dictFoldable) {
    return function (dictOrd) {
        return function (f) {
            return Some.create(Data_Set.fromFoldable(dictFoldable)(dictOrd)(f));
        };
    };
};
var findMin = function (dictOrd) {
    return function (v) {
        if (v instanceof All) {
            return Data_Maybe.Nothing.value;
        };
        if (v instanceof Some) {
            return Data_Set.findMin(v.value0);
        };
        throw new Error("Failed pattern match at AllSet (line 66, column 1 - line 66, column 50): " + [ v.constructor.name ]);
    };
};
var findMax = function (dictOrd) {
    return function (v) {
        if (v instanceof All) {
            return Data_Maybe.Nothing.value;
        };
        if (v instanceof Some) {
            return Data_Set.findMax(v.value0);
        };
        throw new Error("Failed pattern match at AllSet (line 70, column 1 - line 70, column 50): " + [ v.constructor.name ]);
    };
};
var eqAllSet = function (dictEq) {
    return {
        eq: function (x) {
            return function (y) {
                if (x instanceof Some && y instanceof Some) {
                    return Data_Eq.eq(Data_Set.eqSet(dictEq))(x.value0)(y.value0);
                };
                if (x instanceof All && y instanceof All) {
                    return true;
                };
                return false;
            };
        }
    };
};
var ordAllSet = function (dictOrd) {
    return {
        compare: function (x) {
            return function (y) {
                if (x instanceof Some && y instanceof Some) {
                    return Data_Ord.compare(Data_Set.ordSet(dictOrd))(x.value0)(y.value0);
                };
                if (x instanceof Some) {
                    return Data_Ordering.LT.value;
                };
                if (y instanceof Some) {
                    return Data_Ordering.GT.value;
                };
                if (x instanceof All && y instanceof All) {
                    return Data_Ordering.EQ.value;
                };
                throw new Error("Failed pattern match at AllSet (line 28, column 1 - line 28, column 53): " + [ x.constructor.name, y.constructor.name ]);
            };
        },
        Eq0: function () {
            return eqAllSet(dictOrd.Eq0());
        }
    };
};
var empty = new Some(Data_Set.empty);
var monoidAllSet = function (dictOrd) {
    return {
        mempty: empty,
        Semigroup0: function () {
            return semigroupAllSet(dictOrd);
        }
    };
};
module.exports = {
    Some: Some,
    All: All,
    empty: empty,
    isEmpty: isEmpty,
    singleton: singleton,
    fromFoldable: fromFoldable,
    member: member,
    insert: insert,
    findMin: findMin,
    findMax: findMax,
    union: union,
    intersection: intersection,
    subset: subset,
    properSubset: properSubset,
    eqAllSet: eqAllSet,
    ordAllSet: ordAllSet,
    showAllSet: showAllSet,
    monoidAllSet: monoidAllSet,
    semigroupAllSet: semigroupAllSet,
    genericAllSet: genericAllSet,
    tofujsonAllSLet: tofujsonAllSLet
};
