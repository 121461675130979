// Generated by purs version 0.14.5
"use strict";
var Control_Alt = require("../Control.Alt/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Char = require("../Data.Char/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Enum = require("../Data.Enum/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_String_Regex = require("../Data.String.Regex/index.js");
var Data_String_Regex_Flags = require("../Data.String.Regex.Flags/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Text_Parsing_StringParser = require("../Text.Parsing.StringParser/index.js");
var Text_Parsing_StringParser_Combinators = require("../Text.Parsing.StringParser.Combinators/index.js");
var string = function (nt) {
    return function (s) {
        if (Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqInt))(Data_String_CodePoints["indexOf'"](nt)(s.pos)(s.str))(new Data_Maybe.Just(s.pos))) {
            return new Data_Either.Right({
                result: nt,
                suffix: {
                    str: s.str,
                    pos: s.pos + Data_String_CodePoints.length(nt) | 0
                }
            });
        };
        return new Data_Either.Left({
            pos: s.pos,
            error: "Expected '" + (nt + "'.")
        });
    };
};
var regex = function (pat) {
    var pattern = (function () {
        var v = Data_String_CodeUnits.stripPrefix("^")(pat);
        if (v instanceof Data_Maybe.Nothing) {
            return "^" + pat;
        };
        return pat;
    })();
    var matchRegex = function (r) {
        return function (v) {
            var remainder = Data_String_CodePoints.drop(v.pos)(v.str);
            var v1 = Data_Functor.map(Data_Maybe.functorMaybe)(Data_Array_NonEmpty.head)(Data_String_Regex.match(r)(remainder));
            if (v1 instanceof Data_Maybe.Just && v1.value0 instanceof Data_Maybe.Just) {
                return new Data_Either.Right({
                    result: v1.value0.value0,
                    suffix: {
                        str: v.str,
                        pos: v.pos + Data_String_CodePoints.length(v1.value0.value0) | 0
                    }
                });
            };
            return new Data_Either.Left({
                pos: v.pos,
                error: "no match"
            });
        };
    };
    var v = Data_String_Regex.regex(pattern)(Data_String_Regex_Flags.noFlags);
    if (v instanceof Data_Either.Left) {
        return Text_Parsing_StringParser.fail("Text.Parsing.StringParser.String.regex': illegal regex " + pat);
    };
    if (v instanceof Data_Either.Right) {
        return matchRegex(v.value0);
    };
    throw new Error("Failed pattern match at Text.Parsing.StringParser.CodePoints (line 132, column 3 - line 136, column 19): " + [ v.constructor.name ]);
};
var eof = function (s) {
    if (s.pos < Data_String_CodePoints.length(s.str)) {
        return new Data_Either.Left({
            pos: s.pos,
            error: "Expected EOF"
        });
    };
    return new Data_Either.Right({
        result: Data_Unit.unit,
        suffix: s
    });
};
var anyChar = (function () {
    var toChar = (function () {
        var $38 = Data_Enum.fromEnum(Data_String_CodePoints.boundedEnumCodePoint);
        return function ($39) {
            return Data_Char.fromCharCode($38($39));
        };
    })();
    return function (v) {
        var v1 = Data_String_CodePoints.codePointAt(v.pos)(v.str);
        if (v1 instanceof Data_Maybe.Just) {
            var v2 = toChar(v1.value0);
            if (v2 instanceof Data_Maybe.Just) {
                return new Data_Either.Right({
                    result: v2.value0,
                    suffix: {
                        str: v.str,
                        pos: v.pos + 1 | 0
                    }
                });
            };
            if (v2 instanceof Data_Maybe.Nothing) {
                return new Data_Either.Left({
                    pos: v.pos,
                    error: "CodePoint " + (Data_Show.show(Data_String_CodePoints.showCodePoint)(v1.value0) + " is not a character")
                });
            };
            throw new Error("Failed pattern match at Text.Parsing.StringParser.CodePoints (line 53, column 16 - line 55, column 87): " + [ v2.constructor.name ]);
        };
        if (v1 instanceof Data_Maybe.Nothing) {
            return new Data_Either.Left({
                pos: v.pos,
                error: "Unexpected EOF"
            });
        };
        throw new Error("Failed pattern match at Text.Parsing.StringParser.CodePoints (line 52, column 3 - line 56, column 53): " + [ v1.constructor.name ]);
    };
})();
var anyDigit = Text_Parsing_StringParser["try"](Control_Bind.bind(Text_Parsing_StringParser.bindParser)(anyChar)(function (c) {
    var $34 = c >= "0" && c <= "9";
    if ($34) {
        return Control_Applicative.pure(Text_Parsing_StringParser.applicativeParser)(c);
    };
    return Text_Parsing_StringParser.fail("Character " + (Data_Show.show(Data_Show.showChar)(c) + " is not a digit"));
}));
var lowerCaseChar = Text_Parsing_StringParser["try"](Control_Bind.bind(Text_Parsing_StringParser.bindParser)(anyChar)(function (c) {
    var $35 = Data_Foldable.elem(Data_Foldable.foldableArray)(Data_Eq.eqInt)(Data_Char.toCharCode(c))(Data_Array.range(97)(122));
    if ($35) {
        return Control_Applicative.pure(Text_Parsing_StringParser.applicativeParser)(c);
    };
    return Text_Parsing_StringParser.fail("Expected a lower case character but found " + Data_Show.show(Data_Show.showChar)(c));
}));
var satisfy = function (f) {
    return Text_Parsing_StringParser["try"](Control_Bind.bind(Text_Parsing_StringParser.bindParser)(anyChar)(function (c) {
        var $36 = f(c);
        if ($36) {
            return Control_Applicative.pure(Text_Parsing_StringParser.applicativeParser)(c);
        };
        return Text_Parsing_StringParser.fail("Character " + (Data_Show.show(Data_Show.showChar)(c) + " did not satisfy predicate"));
    }));
};
var $$char = function (c) {
    return Text_Parsing_StringParser_Combinators.withError(satisfy(function (v) {
        return v === c;
    }))("Could not match character " + Data_Show.show(Data_Show.showChar)(c));
};
var noneOf = function (dictFoldable) {
    var $40 = Data_Function.flip(Data_Foldable.notElem(dictFoldable)(Data_Eq.eqChar));
    return function ($41) {
        return satisfy($40($41));
    };
};
var oneOf = function (dictFoldable) {
    var $42 = Data_Function.flip(Data_Foldable.elem(dictFoldable)(Data_Eq.eqChar));
    return function ($43) {
        return satisfy($42($43));
    };
};
var whiteSpace = Control_Bind.bind(Text_Parsing_StringParser.bindParser)(Text_Parsing_StringParser_Combinators.many(satisfy(function (c) {
    return c === "\x0a" || (c === "\x0d" || (c === " " || c === "\x09"));
})))(function (cs) {
    return Control_Applicative.pure(Text_Parsing_StringParser.applicativeParser)(Data_Foldable.foldMap(Data_List_Types.foldableList)(Data_Monoid.monoidString)(Data_String_CodeUnits.singleton)(cs));
});
var skipSpaces = Data_Functor["void"](Text_Parsing_StringParser.functorParser)(whiteSpace);
var upperCaseChar = Text_Parsing_StringParser["try"](Control_Bind.bind(Text_Parsing_StringParser.bindParser)(anyChar)(function (c) {
    var $37 = Data_Foldable.elem(Data_Foldable.foldableArray)(Data_Eq.eqInt)(Data_Char.toCharCode(c))(Data_Array.range(65)(90));
    if ($37) {
        return Control_Applicative.pure(Text_Parsing_StringParser.applicativeParser)(c);
    };
    return Text_Parsing_StringParser.fail("Expected an upper case character but found " + Data_Show.show(Data_Show.showChar)(c));
}));
var anyLetter = Text_Parsing_StringParser_Combinators.withError(Control_Alt.alt(Text_Parsing_StringParser.altParser)(lowerCaseChar)(upperCaseChar))("Expected a letter");
var alphaNum = Text_Parsing_StringParser_Combinators.withError(Control_Alt.alt(Text_Parsing_StringParser.altParser)(anyLetter)(anyDigit))("Expected a letter or a number");
module.exports = {
    eof: eof,
    anyChar: anyChar,
    anyDigit: anyDigit,
    string: string,
    satisfy: satisfy,
    "char": $$char,
    whiteSpace: whiteSpace,
    skipSpaces: skipSpaces,
    oneOf: oneOf,
    noneOf: noneOf,
    lowerCaseChar: lowerCaseChar,
    upperCaseChar: upperCaseChar,
    anyLetter: anyLetter,
    alphaNum: alphaNum,
    regex: regex
};
