// Generated by purs version 0.14.5
"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Compactable = require("../Data.Compactable/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_List = require("../Data.List/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var partitionMapDefault = function (dictFilterable) {
    return function (p) {
        var $89 = Data_Compactable.separate(dictFilterable.Compactable0());
        var $90 = Data_Functor.map(dictFilterable.Functor1())(p);
        return function ($91) {
            return $89($90($91));
        };
    };
};
var partitionMap = function (dict) {
    return dict.partitionMap;
};
var partition = function (dict) {
    return dict.partition;
};
var maybeBool = function (p) {
    return function (x) {
        var $38 = p(x);
        if ($38) {
            return new Data_Maybe.Just(x);
        };
        return Data_Maybe.Nothing.value;
    };
};
var filterableList = {
    partitionMap: function (p) {
        return function (xs) {
            var select = function (x) {
                return function (v) {
                    var v1 = p(x);
                    if (v1 instanceof Data_Either.Left) {
                        return {
                            left: new Data_List_Types.Cons(v1.value0, v.left),
                            right: v.right
                        };
                    };
                    if (v1 instanceof Data_Either.Right) {
                        return {
                            left: v.left,
                            right: new Data_List_Types.Cons(v1.value0, v.right)
                        };
                    };
                    throw new Error("Failed pattern match at Data.Filterable (line 190, column 36 - line 192, column 83): " + [ v1.constructor.name ]);
                };
            };
            return Data_Foldable.foldr(Data_List_Types.foldableList)(select)({
                left: Data_List_Types.Nil.value,
                right: Data_List_Types.Nil.value
            })(xs);
        };
    },
    partition: function (p) {
        return function (xs) {
            var select = function (x) {
                return function (v) {
                    var $48 = p(x);
                    if ($48) {
                        return {
                            no: v.no,
                            yes: new Data_List_Types.Cons(x, v.yes)
                        };
                    };
                    return {
                        no: new Data_List_Types.Cons(x, v.no),
                        yes: v.yes
                    };
                };
            };
            return Data_Foldable.foldr(Data_List_Types.foldableList)(select)({
                no: Data_List_Types.Nil.value,
                yes: Data_List_Types.Nil.value
            })(xs);
        };
    },
    filterMap: function (p) {
        return Data_List.mapMaybe(p);
    },
    filter: Data_List.filter,
    Compactable0: function () {
        return Data_Compactable.compactableList;
    },
    Functor1: function () {
        return Data_List_Types.functorList;
    }
};
var filterableArray = {
    partitionMap: function (p) {
        var go = function (acc) {
            return function (x) {
                var v = p(x);
                if (v instanceof Data_Either.Left) {
                    return {
                        left: Data_Semigroup.append(Data_Semigroup.semigroupArray)(acc.left)([ v.value0 ]),
                        right: acc.right
                    };
                };
                if (v instanceof Data_Either.Right) {
                    return {
                        right: Data_Semigroup.append(Data_Semigroup.semigroupArray)(acc.right)([ v.value0 ]),
                        left: acc.left
                    };
                };
                throw new Error("Failed pattern match at Data.Filterable (line 149, column 16 - line 151, column 50): " + [ v.constructor.name ]);
            };
        };
        return Data_Foldable.foldl(Data_Foldable.foldableArray)(go)({
            left: [  ],
            right: [  ]
        });
    },
    partition: Data_Array.partition,
    filterMap: Data_Array.mapMaybe,
    filter: Data_Array.filter,
    Compactable0: function () {
        return Data_Compactable.compactableArray;
    },
    Functor1: function () {
        return Data_Functor.functorArray;
    }
};
var filterMapDefault = function (dictFilterable) {
    return function (p) {
        var $92 = Data_Compactable.compact(dictFilterable.Compactable0());
        var $93 = Data_Functor.map(dictFilterable.Functor1())(p);
        return function ($94) {
            return $92($93($94));
        };
    };
};
var filterMap = function (dict) {
    return dict.filterMap;
};
var partitionDefaultFilterMap = function (dictFilterable) {
    return function (p) {
        return function (xs) {
            return {
                yes: filterMap(dictFilterable)(maybeBool(p))(xs),
                no: filterMap(dictFilterable)(maybeBool(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(p)))(xs)
            };
        };
    };
};
var filterDefaultPartition = function (dictFilterable) {
    return function (p) {
        return function (xs) {
            return (partition(dictFilterable)(p)(xs)).yes;
        };
    };
};
var filterDefault = function (dictFilterable) {
    var $95 = filterMap(dictFilterable);
    return function ($96) {
        return $95(maybeBool($96));
    };
};
var filter = function (dict) {
    return dict.filter;
};
var partitionDefaultFilter = function (dictFilterable) {
    return function (p) {
        return function (xs) {
            return {
                yes: filter(dictFilterable)(p)(xs),
                no: filter(dictFilterable)(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(p))(xs)
            };
        };
    };
};
var eitherBool = function (p) {
    return function (x) {
        var $56 = p(x);
        if ($56) {
            return new Data_Either.Right(x);
        };
        return new Data_Either.Left(x);
    };
};
var filterDefaultPartitionMap = function (dictFilterable) {
    return function (p) {
        return function (xs) {
            return (partitionMap(dictFilterable)(eitherBool(p))(xs)).right;
        };
    };
};
var partitionDefault = function (dictFilterable) {
    return function (p) {
        return function (xs) {
            var o = partitionMap(dictFilterable)(eitherBool(p))(xs);
            return {
                no: o.left,
                yes: o.right
            };
        };
    };
};
var filterableEither = function (dictMonoid) {
    return {
        partitionMap: function (v) {
            return function (v1) {
                if (v1 instanceof Data_Either.Left) {
                    return {
                        left: new Data_Either.Left(v1.value0),
                        right: new Data_Either.Left(v1.value0)
                    };
                };
                if (v1 instanceof Data_Either.Right) {
                    var v2 = v(v1.value0);
                    if (v2 instanceof Data_Either.Left) {
                        return {
                            left: new Data_Either.Right(v2.value0),
                            right: new Data_Either.Left(Data_Monoid.mempty(dictMonoid))
                        };
                    };
                    if (v2 instanceof Data_Either.Right) {
                        return {
                            left: new Data_Either.Left(Data_Monoid.mempty(dictMonoid)),
                            right: new Data_Either.Right(v2.value0)
                        };
                    };
                    throw new Error("Failed pattern match at Data.Filterable (line 173, column 30 - line 175, column 53): " + [ v2.constructor.name ]);
                };
                throw new Error("Failed pattern match at Data.Filterable (line 171, column 1 - line 184, column 29): " + [ v.constructor.name, v1.constructor.name ]);
            };
        },
        partition: function (p) {
            return partitionDefault(filterableEither(dictMonoid))(p);
        },
        filterMap: function (v) {
            return function (v1) {
                if (v1 instanceof Data_Either.Left) {
                    return new Data_Either.Left(v1.value0);
                };
                if (v1 instanceof Data_Either.Right) {
                    var v2 = v(v1.value0);
                    if (v2 instanceof Data_Maybe.Nothing) {
                        return new Data_Either.Left(Data_Monoid.mempty(dictMonoid));
                    };
                    if (v2 instanceof Data_Maybe.Just) {
                        return new Data_Either.Right(v2.value0);
                    };
                    throw new Error("Failed pattern match at Data.Filterable (line 180, column 27 - line 182, column 22): " + [ v2.constructor.name ]);
                };
                throw new Error("Failed pattern match at Data.Filterable (line 171, column 1 - line 184, column 29): " + [ v.constructor.name, v1.constructor.name ]);
            };
        },
        filter: function (p) {
            return filterDefault(filterableEither(dictMonoid))(p);
        },
        Compactable0: function () {
            return Data_Compactable.compactableEither(dictMonoid);
        },
        Functor1: function () {
            return Data_Either.functorEither;
        }
    };
};
var filterableMap = function (dictOrd) {
    return {
        partitionMap: function (p) {
            return function (xs) {
                var toList = Data_Map_Internal.toUnfoldable(Data_List_Types.unfoldableList);
                var select = function (v) {
                    return function (v1) {
                        var v2 = p(v.value1);
                        if (v2 instanceof Data_Either.Left) {
                            return {
                                left: Data_Map_Internal.insert(dictOrd)(v.value0)(v2.value0)(v1.left),
                                right: v1.right
                            };
                        };
                        if (v2 instanceof Data_Either.Right) {
                            return {
                                left: v1.left,
                                right: Data_Map_Internal.insert(dictOrd)(v.value0)(v2.value0)(v1.right)
                            };
                        };
                        throw new Error("Failed pattern match at Data.Filterable (line 215, column 44 - line 217, column 57): " + [ v2.constructor.name ]);
                    };
                };
                return Data_Foldable.foldr(Data_List_Types.foldableList)(select)({
                    left: Data_Map_Internal.empty,
                    right: Data_Map_Internal.empty
                })(toList(xs));
            };
        },
        partition: function (p) {
            return partitionDefault(filterableMap(dictOrd))(p);
        },
        filterMap: function (p) {
            return function (xs) {
                var toList = Data_Map_Internal.toUnfoldable(Data_List_Types.unfoldableList);
                var select = function (v) {
                    return function (m) {
                        return Data_Map_Internal.alter(dictOrd)(Data_Function["const"](p(v.value1)))(v.value0)(m);
                    };
                };
                return Data_Foldable.foldr(Data_List_Types.foldableList)(select)(Data_Map_Internal.empty)(toList(xs));
            };
        },
        filter: function (p) {
            return filterDefault(filterableMap(dictOrd))(p);
        },
        Compactable0: function () {
            return Data_Compactable.compactableMap(dictOrd);
        },
        Functor1: function () {
            return Data_Map_Internal.functorMap;
        }
    };
};
var filterableMaybe = {
    partitionMap: function (v) {
        return function (v1) {
            if (v1 instanceof Data_Maybe.Nothing) {
                return {
                    left: Data_Maybe.Nothing.value,
                    right: Data_Maybe.Nothing.value
                };
            };
            if (v1 instanceof Data_Maybe.Just) {
                var v2 = v(v1.value0);
                if (v2 instanceof Data_Either.Left) {
                    return {
                        left: new Data_Maybe.Just(v2.value0),
                        right: Data_Maybe.Nothing.value
                    };
                };
                if (v2 instanceof Data_Either.Right) {
                    return {
                        left: Data_Maybe.Nothing.value,
                        right: new Data_Maybe.Just(v2.value0)
                    };
                };
                throw new Error("Failed pattern match at Data.Filterable (line 161, column 29 - line 163, column 48): " + [ v2.constructor.name ]);
            };
            throw new Error("Failed pattern match at Data.Filterable (line 159, column 1 - line 169, column 29): " + [ v.constructor.name, v1.constructor.name ]);
        };
    },
    partition: function (p) {
        return partitionDefault(filterableMaybe)(p);
    },
    filterMap: Control_Bind.bindFlipped(Data_Maybe.bindMaybe),
    filter: function (p) {
        return filterDefault(filterableMaybe)(p);
    },
    Compactable0: function () {
        return Data_Compactable.compactableMaybe;
    },
    Functor1: function () {
        return Data_Maybe.functorMaybe;
    }
};
var cleared = function (dictFilterable) {
    return filterMap(dictFilterable)(Data_Function["const"](Data_Maybe.Nothing.value));
};
module.exports = {
    partitionMap: partitionMap,
    partition: partition,
    filterMap: filterMap,
    filter: filter,
    eitherBool: eitherBool,
    partitionDefault: partitionDefault,
    partitionDefaultFilter: partitionDefaultFilter,
    partitionDefaultFilterMap: partitionDefaultFilterMap,
    partitionMapDefault: partitionMapDefault,
    maybeBool: maybeBool,
    filterDefault: filterDefault,
    filterDefaultPartition: filterDefaultPartition,
    filterDefaultPartitionMap: filterDefaultPartitionMap,
    filterMapDefault: filterMapDefault,
    cleared: cleared,
    filterableArray: filterableArray,
    filterableMaybe: filterableMaybe,
    filterableEither: filterableEither,
    filterableList: filterableList,
    filterableMap: filterableMap,
    compact: Data_Compactable.compact,
    separate: Data_Compactable.separate
};
