// Generated by purs version 0.14.5
"use strict";
var BuildingElementsReferenceMode = (function () {
    function BuildingElementsReferenceMode() {

    };
    BuildingElementsReferenceMode.value = new BuildingElementsReferenceMode();
    return BuildingElementsReferenceMode;
})();
var ComponentsReferenceMode = (function () {
    function ComponentsReferenceMode() {

    };
    ComponentsReferenceMode.value = new ComponentsReferenceMode();
    return ComponentsReferenceMode;
})();
var ResourcesReferenceMode = (function () {
    function ResourcesReferenceMode() {

    };
    ResourcesReferenceMode.value = new ResourcesReferenceMode();
    return ResourcesReferenceMode;
})();
var eqActiveReferenceMode = {
    eq: function (x) {
        return function (y) {
            if (x instanceof BuildingElementsReferenceMode && y instanceof BuildingElementsReferenceMode) {
                return true;
            };
            if (x instanceof ComponentsReferenceMode && y instanceof ComponentsReferenceMode) {
                return true;
            };
            if (x instanceof ResourcesReferenceMode && y instanceof ResourcesReferenceMode) {
                return true;
            };
            return false;
        };
    }
};
module.exports = {
    BuildingElementsReferenceMode: BuildingElementsReferenceMode,
    ComponentsReferenceMode: ComponentsReferenceMode,
    ResourcesReferenceMode: ResourcesReferenceMode,
    eqActiveReferenceMode: eqActiveReferenceMode
};
