import { AdminetMessageContainer } from "./AdminetMessageContainer";
import { IconFlagV1 } from "@tocoman/ui";
import { AdminetProjectsFetchStatus } from "./ImportCostCalculationToAdminetModal";
import { AdminetHttpErrors } from "../http/AdminetHttpErrorHandler";

type CostCalculationErrorMessageProps = {
  adminetProjectsStatus: AdminetProjectsFetchStatus;
  transferCalculationErrors: unknown;
  noTransferableCostGroupsPresent: boolean;
};

enum CostCalculationError {
  ProjectsNotFound = "ProjectsNotFound",
  NoTransferableCostGroupsPresent = "NoTransferableCostGroupsPresent",
  MissingCostGroups = "MissingCostGroups",
  TargetAmountsLocked = "TargetAmountsLocked",
  BudgetAlreadyCreated = "BudgetAlreadyCreated",
  AdminetDefault = "AdminetDefault",
  TransferCalculationDefault = "TransferCalculationDefault",
}

export const CostCalculationErrorMessage = ({
  adminetProjectsStatus,
  transferCalculationErrors,
  noTransferableCostGroupsPresent,
}: CostCalculationErrorMessageProps) => {
  const parseSimpleErrors = (): CostCalculationError | null => {
    if (adminetProjectsStatus === AdminetProjectsFetchStatus.Empty) {
      return CostCalculationError.ProjectsNotFound;
    } else if (noTransferableCostGroupsPresent) {
      return CostCalculationError.NoTransferableCostGroupsPresent;
    } else if (
      transferCalculationErrors instanceof Error &&
      transferCalculationErrors.name ==
        AdminetHttpErrors.TargetAmountsLockedError
    ) {
      return CostCalculationError.TargetAmountsLocked;
    } else if (
      transferCalculationErrors instanceof Error &&
      transferCalculationErrors.name ==
        AdminetHttpErrors.BudgetAlreadyCreatedError
    ) {
      return CostCalculationError.BudgetAlreadyCreated;
    } else if (adminetProjectsStatus === AdminetProjectsFetchStatus.Error) {
      return CostCalculationError.AdminetDefault;
    } else if (transferCalculationErrors) {
      return CostCalculationError.TransferCalculationDefault;
    }
    return null;
  };

  const error = parseSimpleErrors();

  return (
    <>
      {error === CostCalculationError.ProjectsNotFound && (
        <AdminetMessageContainer
          title={"adminet.getAdminetProjects.notFound.title"}
          message={"adminet.getAdminetProjects.notFound.message"}
          icon={IconFlagV1}
        ></AdminetMessageContainer>
      )}

      {error === CostCalculationError.AdminetDefault && (
        <AdminetMessageContainer
          title={"adminet.getAdminetProjects.error.title"}
          message={"adminet.getAdminetProjects.error.message"}
          icon={IconFlagV1}
        ></AdminetMessageContainer>
      )}

      {error === CostCalculationError.TransferCalculationDefault && (
        <AdminetMessageContainer
          title={"adminet.transferCalculation.error.title"}
          message={"adminet.transferCalculation.error.message"}
          icon={IconFlagV1}
        ></AdminetMessageContainer>
      )}

      {error === CostCalculationError.TargetAmountsLocked && (
        <AdminetMessageContainer
          title={"adminet.transferCalculation.targetAmountsLocked.title"}
          message={"adminet.transferCalculation.targetAmountsLocked.message"}
          icon={IconFlagV1}
        ></AdminetMessageContainer>
      )}

      {error === CostCalculationError.BudgetAlreadyCreated && (
        <AdminetMessageContainer
          title={"adminet.transferCalculation.budgetAlreadyCreated.title"}
          message={"adminet.transferCalculation.budgetAlreadyCreated.message"}
          icon={IconFlagV1}
        ></AdminetMessageContainer>
      )}

      {error === CostCalculationError.NoTransferableCostGroupsPresent && (
        <AdminetMessageContainer
          title={
            "adminet.transferCalculation.noTransferableCostGroupsPresent.title"
          }
          message={
            "adminet.transferCalculation.noTransferableCostGroupsPresent.message"
          }
          icon={IconFlagV1}
        ></AdminetMessageContainer>
      )}
    </>
  );
};
