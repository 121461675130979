// Generated by purs version 0.14.5
"use strict";
var Calculation_Income = require("../Calculation.Income/index.js");
var Calculation_Utils = require("../Calculation.Utils/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Monoid_Additive = require("../Data.Monoid.Additive/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Set = require("../Data.Set/index.js");
var OwnProjectTaxes = require("../OwnProjectTaxes/index.js");
var Types_CostGroup = require("../Types.CostGroup/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_R200 = require("../Types.R200/index.js");
var Utils = require("../Utils/index.js");
var R200CostClassResultItem = function (x) {
    return x;
};
var newtypeR200CostClassResultItem = {
    Coercible0: function () {
        return undefined;
    }
};
var mkR200CostClassResultItem = function (costGroupItems_) {
    return function (invoiceLines_) {
        return function (upperDateLimit) {
            return function (costClass) {
                var targetSEP = Utils.fromNullable(0.0)(costClass.socialExpensePercentageInCostControlForTargets);
                var filteredILs = Data_Array.filter(function (x) {
                    return Data_Eq.eq(Types_Newtypes.eqCostClassCode)(x.costClassCode)(costClass.costClassCode);
                })(invoiceLines_);
                var filteredCGI = Data_Array.filter(function (x) {
                    return Data_Eq.eq(Types_Newtypes.eqCostClassCode)(x.costClassCode)(costClass.costClassCode);
                })(Calculation_Utils.filterCostGroupItems(upperDateLimit)(costGroupItems_));
                var targetCostItems = Data_Array.filter(Types_CostGroup.isCostGroupTargetItemId)(filteredCGI);
                var target = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
                    return v.value;
                })(targetCostItems));
                var targetSocialExpenses = target * targetSEP;
                var estimateCostItems = Data_Array.filter(Types_CostGroup.isCostGroupEstimateItemId)(filteredCGI);
                var estimateActualSEP = Utils.fromNullable(0.0)(costClass.socialExpensePercentageInCostControlForEstimatesAndActuals);
                var estimate = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
                    return v.value;
                })(estimateCostItems));
                var estimateSocialExpenses = estimate * estimateActualSEP;
                var actual = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
                    return v.value;
                })(filteredILs));
                var actualSocialExpenses = actual * estimateActualSEP;
                return {
                    name: costClass.name,
                    costClassCode: costClass.costClassCode,
                    target: target,
                    targetSocialExpenses: targetSocialExpenses,
                    estimate: estimate,
                    estimateSocialExpenses: estimateSocialExpenses,
                    actual: actual,
                    actualSocialExpenses: actualSocialExpenses
                };
            };
        };
    };
};
var mkR200CostClassResultForCostGroupCode = function (costGroupCode) {
    return function (costGroupItems_) {
        return function (invoiceLines_) {
            return function (upperDateLimit) {
                return function (costClass) {
                    var invoiceLines = Data_Array.filter((function () {
                        var $57 = Data_Eq.eq(Data_Maybe.eqMaybe(Types_CostGroup.eqCostGroupCode))(new Data_Maybe.Just(costGroupCode));
                        return function ($58) {
                            return $57(Data_Nullable.toMaybe((function (v) {
                                return v.costGroupCode;
                            })($58)));
                        };
                    })())(invoiceLines_);
                    var costGroupItems = Data_Array.filter((function () {
                        var $59 = Data_Eq.eq(Types_CostGroup.eqCostGroupCode)(costGroupCode);
                        return function ($60) {
                            return $59((function (v) {
                                return v.costGroupCode;
                            })($60));
                        };
                    })())(Calculation_Utils.filterCostGroupItems(upperDateLimit)(costGroupItems_));
                    return mkR200CostClassResultItem(costGroupItems)(invoiceLines)(upperDateLimit)(costClass);
                };
            };
        };
    };
};
var getSocialExpensesForCostGroup = function (disableSocialCostToActual) {
    return function (costGroupCode) {
        return function (costGroupItems_) {
            return function (invoiceLines) {
                return function (costClasses) {
                    return function (upperDateLimit) {
                        var results = Data_Functor.map(Data_Functor.functorArray)(mkR200CostClassResultForCostGroupCode(costGroupCode)(costGroupItems_)(invoiceLines)(upperDateLimit))(costClasses);
                        var prepareAdditive = function (v) {
                            return {
                                targetSocialExpenses: v.targetSocialExpenses,
                                actualSocialExpenses: v.actualSocialExpenses,
                                estimateSocialExpenses: v.estimateSocialExpenses
                            };
                        };
                        var sums = Data_Array.foldMap(Data_Monoid.monoidRecord()(Data_Monoid.monoidRecordCons({
                            reflectSymbol: function () {
                                return "actualSocialExpenses";
                            }
                        })(Data_Monoid_Additive.monoidAdditive(Data_Semiring.semiringNumber))()(Data_Monoid.monoidRecordCons({
                            reflectSymbol: function () {
                                return "estimateSocialExpenses";
                            }
                        })(Data_Monoid_Additive.monoidAdditive(Data_Semiring.semiringNumber))()(Data_Monoid.monoidRecordCons({
                            reflectSymbol: function () {
                                return "targetSocialExpenses";
                            }
                        })(Data_Monoid_Additive.monoidAdditive(Data_Semiring.semiringNumber))()(Data_Monoid.monoidRecordNil)))))(prepareAdditive)(results);
                        return {
                            costGroupCode: costGroupCode,
                            targetSocialExpenses: Data_Newtype.un()(Data_Monoid_Additive.Additive)(sums.targetSocialExpenses),
                            estimateSocialExpenses: Data_Newtype.un()(Data_Monoid_Additive.Additive)(sums.estimateSocialExpenses),
                            actualSocialExpenses: (function () {
                                if (disableSocialCostToActual instanceof Calculation_Income.AllowSocialCostToActual) {
                                    return Data_Newtype.un()(Data_Monoid_Additive.Additive)(sums.actualSocialExpenses);
                                };
                                if (disableSocialCostToActual instanceof Calculation_Income.DisableSocialCostToActual) {
                                    return 0.0;
                                };
                                throw new Error("Failed pattern match at Calculation.CostClass (line 228, column 27 - line 230, column 39): " + [ disableSocialCostToActual.constructor.name ]);
                            })()
                        };
                    };
                };
            };
        };
    };
};
var fromR200CostClassResultItem = function (v) {
    return {
        name: v.name,
        costClassCode: v.costClassCode,
        target: v.target,
        estimate: v.estimate,
        actual: v.actual
    };
};
var getR200CostGroupCostClassesAndSocialCostTotals = function (disableSocialCostToActual) {
    return function (costGroupItems_) {
        return function (invoiceLines) {
            return function (costClasses) {
                return function (upperDateLimit) {
                    var costGroupItems = Calculation_Utils.filterCostGroupItems(upperDateLimit)(costGroupItems_);
                    var r200CostGroupClassResultItems_ = Data_Functor.map(Data_Functor.functorArray)(mkR200CostClassResultItem(costGroupItems)(invoiceLines)(upperDateLimit))(costClasses);
                    var unR200CostGroupClassResultItems = Utils.coerceNewtypeArray()(R200CostClassResultItem)(r200CostGroupClassResultItems_);
                    var socialExpenses = {
                        name: "Sosiaalikulut",
                        costClassCode: "Sosiaalikulut",
                        target: Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
                            return v.targetSocialExpenses;
                        })(unR200CostGroupClassResultItems)),
                        estimate: Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
                            return v.estimateSocialExpenses;
                        })(unR200CostGroupClassResultItems)),
                        actual: (function () {
                            if (disableSocialCostToActual instanceof Calculation_Income.AllowSocialCostToActual) {
                                return Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
                                    return v.actualSocialExpenses;
                                })(unR200CostGroupClassResultItems));
                            };
                            if (disableSocialCostToActual instanceof Calculation_Income.DisableSocialCostToActual) {
                                return 0.0;
                            };
                            throw new Error("Failed pattern match at Calculation.CostClass (line 44, column 17 - line 46, column 43): " + [ disableSocialCostToActual.constructor.name ]);
                        })()
                    };
                    var costClassResults = Data_Functor.map(Data_Functor.functorArray)(fromR200CostClassResultItem)(r200CostGroupClassResultItems_);
                    return {
                        socialExpenses: socialExpenses,
                        costClassResults: costClassResults
                    };
                };
            };
        };
    };
};
var mkR200CostClassResults$prime = function (disableSocialCostToActual) {
    return function (v) {
        return function (projectTaxStatus) {
            return function (costGroupItems) {
                return function (invoiceLines) {
                    return function (costClasses) {
                        return function (upperDateLimit) {
                            var vatExcluded$prime = Data_Set.fromFoldable(Data_Foldable.foldableArray)(Types_CostGroup.ordCostGroupCode)(v);
                            var taxableCostGroupItems = Data_Array.filter(function (cgi) {
                                return Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean)))(Data_Set.member(Types_CostGroup.ordCostGroupCode))(cgi.costGroupCode)(vatExcluded$prime);
                            })(costGroupItems);
                            var getR200CostGroupCostClassesAndSocialCostTotals$prime = function (cgItems) {
                                return function (iLines) {
                                    return getR200CostGroupCostClassesAndSocialCostTotals(disableSocialCostToActual)(cgItems)(iLines)(costClasses)(upperDateLimit);
                                };
                            };
                            var filterInvoiceLine = function (i) {
                                var v1 = Data_Nullable.toMaybe(i.costGroupCode);
                                if (v1 instanceof Data_Maybe.Just) {
                                    return Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean)))(Data_Set.member(Types_CostGroup.ordCostGroupCode))(v1.value0)(vatExcluded$prime);
                                };
                                if (v1 instanceof Data_Maybe.Nothing) {
                                    return true;
                                };
                                throw new Error("Failed pattern match at Calculation.CostClass (line 83, column 27 - line 85, column 22): " + [ v1.constructor.name ]);
                            };
                            var taxableInvoiceLines = Data_Array.filter(filterInvoiceLine)(invoiceLines);
                            var v1 = getR200CostGroupCostClassesAndSocialCostTotals$prime(costGroupItems)(invoiceLines);
                            var v2 = getR200CostGroupCostClassesAndSocialCostTotals$prime(taxableCostGroupItems)(taxableInvoiceLines);
                            var taxableItems = Data_Semigroup.append(Data_Semigroup.semigroupArray)(v2.costClassResults)([ v2.socialExpenses ]);
                            var rowsWithoutTaxes = Data_Semigroup.append(Data_Semigroup.semigroupArray)(v1.costClassResults)([ v1.socialExpenses ]);
                            var unRowsWithoutTaxes = Utils.coerceNewtypeArray()(Types_R200.R200CostClassResult)(rowsWithoutTaxes);
                            var totalsWithoutTaxes = {
                                name: "Yhteens\xe4",
                                costClassCode: "Yhteens\xe4",
                                target: Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v3) {
                                    return v3.target;
                                })(unRowsWithoutTaxes)),
                                estimate: Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v3) {
                                    return v3.estimate;
                                })(unRowsWithoutTaxes)),
                                actual: Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v3) {
                                    return v3.actual;
                                })(unRowsWithoutTaxes))
                            };
                            var optionalTaxes = (function () {
                                if (projectTaxStatus instanceof OwnProjectTaxes.NotOwnProject) {
                                    return Data_Maybe.Nothing.value;
                                };
                                if (projectTaxStatus instanceof OwnProjectTaxes.OwnProject) {
                                    var $42 = (function () {
                                        var $61 = OwnProjectTaxes.applyTaxesForCosts(projectTaxStatus);
                                        return function ($62) {
                                            return (function (v3) {
                                                return v3.taxes;
                                            })($61($62));
                                        };
                                    })();
                                    var $43 = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)((function () {
                                        var $63 = Data_Newtype.un()(Types_R200.R200CostClassResult);
                                        return function ($64) {
                                            return (function (v3) {
                                                return v3.target;
                                            })($63($64));
                                        };
                                    })())(taxableItems));
                                    var $44 = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)((function () {
                                        var $65 = Data_Newtype.un()(Types_R200.R200CostClassResult);
                                        return function ($66) {
                                            return (function (v3) {
                                                return v3.estimate;
                                            })($65($66));
                                        };
                                    })())(taxableItems));
                                    var $45 = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)((function () {
                                        var $67 = Data_Newtype.un()(Types_R200.R200CostClassResult);
                                        return function ($68) {
                                            return (function (v3) {
                                                return v3.actual;
                                            })($67($68));
                                        };
                                    })())(taxableItems));
                                    return Control_Applicative.pure(Data_Maybe.applicativeMaybe)({
                                        name: "ALV",
                                        costClassCode: "ALV",
                                        target: $42($43),
                                        estimate: $42($44),
                                        actual: $42($45)
                                    });
                                };
                                throw new Error("Failed pattern match at Calculation.CostClass (line 100, column 21 - line 113, column 10): " + [ projectTaxStatus.constructor.name ]);
                            })();
                            var rows = Data_Semigroup.append(Data_Semigroup.semigroupArray)(rowsWithoutTaxes)(Data_Maybe.maybe([  ])(Control_Applicative.pure(Control_Applicative.applicativeArray))(optionalTaxes));
                            var unRows = Utils.coerceNewtypeArray()(Types_R200.R200CostClassResult)(rows);
                            var totals = {
                                name: "Yhteens\xe4",
                                costClassCode: "Yhteens\xe4",
                                target: Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v3) {
                                    return v3.target;
                                })(unRows)),
                                estimate: Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v3) {
                                    return v3.estimate;
                                })(unRows)),
                                actual: Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v3) {
                                    return v3.actual;
                                })(unRows))
                            };
                            return {
                                costClassResults: v1.costClassResults,
                                socialExpenses: v1.socialExpenses,
                                optionalTaxes: optionalTaxes,
                                totalsWithoutTaxes: totalsWithoutTaxes,
                                totals: totals
                            };
                        };
                    };
                };
            };
        };
    };
};
var mkR200CostClassResults = function (disableSocialCostToActual) {
    return function (vatExclusionCostGroups) {
        return function (projectTaxStatus) {
            return function (costGroupItems) {
                return function (invoiceLines) {
                    return function (costClasses) {
                        return function (upperDateLimit) {
                            var v = mkR200CostClassResults$prime(disableSocialCostToActual)(vatExclusionCostGroups)(projectTaxStatus)(costGroupItems)(invoiceLines)(costClasses)(upperDateLimit);
                            return Data_Semigroup.append(Data_Semigroup.semigroupArray)(v.costClassResults)(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ v.socialExpenses ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_Maybe.maybe([  ])(Control_Applicative.pure(Control_Applicative.applicativeArray))(v.optionalTaxes))([ v.totals ])));
                        };
                    };
                };
            };
        };
    };
};
module.exports = {
    getR200CostGroupCostClassesAndSocialCostTotals: getR200CostGroupCostClassesAndSocialCostTotals,
    mkR200CostClassResults: mkR200CostClassResults,
    "mkR200CostClassResults'": mkR200CostClassResults$prime,
    fromR200CostClassResultItem: fromR200CostClassResultItem,
    R200CostClassResultItem: R200CostClassResultItem,
    mkR200CostClassResultItem: mkR200CostClassResultItem,
    mkR200CostClassResultForCostGroupCode: mkR200CostClassResultForCostGroupCode,
    getSocialExpensesForCostGroup: getSocialExpensesForCostGroup,
    newtypeR200CostClassResultItem: newtypeR200CostClassResultItem
};
