// Generated by purs version 0.14.5
"use strict";
var Data_Array = require("../Data.Array/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Show = require("../Data.Show/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var TacoAutocomplete = require("../TacoAutocomplete/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Project = require("../Types.Project/index.js");
var mkSubprojectTacoAutocomplete = function (v) {
    var mkSubprojectListOption = function (subproject) {
        return {
            id: subproject.code,
            text: subproject.code
        };
    };
    return TacoAutocomplete.component()()({
        sections: [ {
            text: Data_Maybe.Nothing.value,
            list: Data_Functor.map(Data_Functor.functorArray)(mkSubprojectListOption)(v.subprojects)
        } ],
        clearButton: false,
        selected: Data_Functor.map(Data_Maybe.functorMaybe)(mkSubprojectListOption)(v.targetSubproject),
        onSelect: function (value) {
            return v.setTargetSubproject(function (v1) {
                if (value instanceof Data_Maybe.Just) {
                    var subproject = Data_Array.find(function (i) {
                        return i.code === value.value0.id;
                    })(v.subprojects);
                    return subproject;
                };
                if (value instanceof Data_Maybe.Nothing) {
                    return Data_Maybe.Nothing.value;
                };
                throw new Error("Failed pattern match at CostEstimation.ProjectAutoComplete (line 71, column 9 - line 75, column 29): " + [ value.constructor.name ]);
            });
        },
        placeholder: Data_Maybe.maybe("Valitse alakohde")(function (v1) {
            return v1.code;
        })(v.targetSubproject),
        testId: "subproject-filtering-select"
    });
};
var mkProjectTacoAutocomplete = (function () {
    var projectFullName = function (project) {
        return Data_Newtype.un()(Types_Project.ProjectCode)(project.code) + (" (Ver. " + (project.version + ("): " + project.name)));
    };
    var mkProjectListOption = function (project) {
        var id = Data_Show.show(Data_Show.showInt)(Data_Newtype.un()(Types_Project.ProjectId)(project.id));
        return {
            id: id,
            text: projectFullName(project)
        };
    };
    return TofuHooks.mkHookComponent("ProjectTacoAutoComplete")(function (props) {
        return function __do() {
            var sortedProjectList = TofuHooks.useMemo([ ReactHooksUtils.utf(props.projectList) ])(function (v) {
                var grouped = Data_Array.groupBy(Data_Function.on(Data_Eq.eq(Types_Project.eqProjectCode))(function (v1) {
                    return v1.code;
                }))(Data_Array.sortWith(Types_Project.ordProjectCode)(function (v1) {
                    return v1.code;
                })(props.projectList));
                var sortedGroups = Data_Functor.map(Data_Functor.functorArray)((function () {
                    var $27 = Data_Array.sortWith(Data_Ord.ordString)(function (v1) {
                        return v1.version;
                    });
                    return function ($28) {
                        return $27(Data_Array_NonEmpty.toArray($28));
                    };
                })())(grouped);
                return Data_Array.concat(sortedGroups);
            })();
            return TacoAutocomplete.component()()({
                sections: [ {
                    text: Data_Maybe.Nothing.value,
                    list: Data_Functor.map(Data_Functor.functorArray)(mkProjectListOption)(sortedProjectList)
                } ],
                clearButton: false,
                selected: Data_Functor.map(Data_Maybe.functorMaybe)(mkProjectListOption)(props.targetProject),
                onSelect: function (value$prime) {
                    if (value$prime instanceof Data_Maybe.Just) {
                        var v = Data_Int.fromString(value$prime.value0.id);
                        if (v instanceof Data_Maybe.Just) {
                            return props.setTargetProject(function (v1) {
                                return Data_Array.find((function () {
                                    var $29 = Data_Eq.eq(Data_Eq.eqInt)(v.value0);
                                    var $30 = Data_Newtype.un()(Types_Project.ProjectId);
                                    return function ($31) {
                                        return $29($30((function (v2) {
                                            return v2.id;
                                        })($31)));
                                    };
                                })())(sortedProjectList);
                            });
                        };
                        return props.setTargetProject(function (v1) {
                            return Data_Array.head(sortedProjectList);
                        });
                    };
                    if (value$prime instanceof Data_Maybe.Nothing) {
                        return props.setTargetProject(function (v) {
                            return Data_Maybe.Nothing.value;
                        });
                    };
                    throw new Error("Failed pattern match at CostEstimation.ProjectAutoComplete (line 41, column 9 - line 46, column 52): " + [ value$prime.constructor.name ]);
                },
                placeholder: Data_Maybe.maybe("Valitse hanke")(projectFullName)(props.targetProject),
                testId: "project-filtering-select"
            });
        };
    });
})();
module.exports = {
    mkProjectTacoAutocomplete: mkProjectTacoAutocomplete,
    mkSubprojectTacoAutocomplete: mkSubprojectTacoAutocomplete
};
