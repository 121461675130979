import { Loader } from "@tocoman/ui";
import { ColDef, IDetailCellRendererParams } from "ag-grid-enterprise";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "src/client-ts/components/Table";
import { useGetOrganizations } from "../Tabs/useSuperAdminOrganizations";
import { OrganizationMigrationDetail } from "./OrganizationMigrations";

type MigrationTableProps = {
  organization: string;
};

export function MigrationTab() {
  const { t } = useTranslation("superAdmin", {
    keyPrefix: "migrations",
  });

  const organizations = useGetOrganizations();

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: t`organization`,
        field: "organization",
        filter: "agTextColumnFilter",
        floatingFilter: true,
        cellRenderer: "agGroupCellRenderer",
        sortable: true,
        resizable: true,
        flex: 1,
      },
    ],
    [t]
  );

  const migrationInfoRenderer = (
    params: IDetailCellRendererParams<MigrationTableProps>
  ) => {
    if (!params.data?.organization) {
      return <Loader />;
    }
    return <OrganizationMigrationDetail org={params.data.organization} />;
  };

  return (
    <Table<MigrationTableProps>
      className="w-full h-full"
      columnDefs={columnDefs}
      rowData={organizations?.map((org) => ({ organization: org.name }))}
      getRowId={({ data }) => data.organization}
      masterDetail={true}
      detailCellRenderer={migrationInfoRenderer}
    />
  );
}
