// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var StateHooks = require("../StateHooks/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_Element = require("../Types.Element/index.js");
var Types_FrontEndRoutes = require("../Types.FrontEndRoutes/index.js");
var tsToFERoute = function (routeName) {
    return function (foreignOptions) {
        var v = TofuJSON.read(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "initialSelectedComponent";
            }
        })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "initialSelectedElement";
            }
        })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "mDrawingId";
            }
        })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "mFileId";
            }
        })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "mMeasurementGroupId";
            }
        })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "projectId";
            }
        })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.readFieldsNil)()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "initialSelectedComponent";
            }
        })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "initialSelectedElement";
            }
        })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "mDrawingId";
            }
        })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "mFileId";
            }
        })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "mMeasurementGroupId";
            }
        })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "projectId";
            }
        })(TofuJSON.readMaybe(TofuJSON.readInt))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()()))(foreignOptions);
        var v1 = (function () {
            if (v instanceof Data_Either.Left) {
                return {
                    projectId: Data_Maybe.Nothing.value,
                    mFileId: Data_Maybe.Nothing.value,
                    mDrawingId: Data_Maybe.Nothing.value,
                    mMeasurementGroupId: Data_Maybe.Nothing.value,
                    initialSelectedComponent: Data_Maybe.Nothing.value,
                    initialSelectedElement: Data_Maybe.Nothing.value
                };
            };
            if (v instanceof Data_Either.Right) {
                return v.value0;
            };
            throw new Error("Failed pattern match at FrontEndRouteHelper (line 79, column 7 - line 81, column 19): " + [ v.constructor.name ]);
        })();
        if (routeName === "RootRoute") {
            return Types_FrontEndRoutes.RootRoute.value;
        };
        if (routeName === "TsRoute") {
            return Types_FrontEndRoutes.TsRoute.value;
        };
        if (routeName === "ReportingProjectListRoute") {
            return Types_FrontEndRoutes.ReportingProjectListRoute.value;
        };
        if (routeName === "EstimationProjectsRoute") {
            return Types_FrontEndRoutes.EstimationProjectsRoute.value;
        };
        if (routeName === "EstimationProjectDetailsFERoute" && v1.projectId instanceof Data_Maybe.Just) {
            return new Types_FrontEndRoutes.EstimationProjectDetailsFERoute({
                projectId: v1.projectId.value0
            });
        };
        if (routeName === "PortfolioReportFERoute") {
            return Types_FrontEndRoutes.PortfolioReportFERoute.value;
        };
        if (routeName === "AddCostControlProjectFERoute") {
            return Types_FrontEndRoutes.AddCostControlProjectFERoute.value;
        };
        if (routeName === "AddEstimationProjectFERoute") {
            return Types_FrontEndRoutes.AddEstimationProjectFERoute.value;
        };
        if (routeName === "ImportEstimationProjectFERoute") {
            return Types_FrontEndRoutes.ImportEstimationProjectFERoute.value;
        };
        if (routeName === "ReportsFERoute" && v1.projectId instanceof Data_Maybe.Just) {
            return new Types_FrontEndRoutes.ReportsFERoute({
                projectId: v1.projectId.value0
            });
        };
        if (routeName === "IncomeFERoute" && v1.projectId instanceof Data_Maybe.Just) {
            return new Types_FrontEndRoutes.IncomeFERoute({
                projectId: v1.projectId.value0
            });
        };
        if (routeName === "CostControlFERoute" && v1.projectId instanceof Data_Maybe.Just) {
            return new Types_FrontEndRoutes.CostControlFERoute({
                projectId: v1.projectId.value0
            });
        };
        if (routeName === "InvoicesFERoute" && v1.projectId instanceof Data_Maybe.Just) {
            return new Types_FrontEndRoutes.InvoicesFERoute({
                projectId: v1.projectId.value0
            });
        };
        if (routeName === "MonthlyReportFERoute" && v1.projectId instanceof Data_Maybe.Just) {
            return new Types_FrontEndRoutes.MonthlyReportFERoute({
                projectId: v1.projectId.value0
            });
        };
        if (routeName === "CostControlPrintingFERoute" && v1.projectId instanceof Data_Maybe.Just) {
            return new Types_FrontEndRoutes.CostControlPrintingFERoute({
                projectId: v1.projectId.value0
            });
        };
        if (routeName === "RenameMainCostGroupsFERoute" && v1.projectId instanceof Data_Maybe.Just) {
            return new Types_FrontEndRoutes.RenameMainCostGroupsFERoute({
                projectId: v1.projectId.value0
            });
        };
        if (routeName === "ProjectDetailsFERoute" && v1.projectId instanceof Data_Maybe.Just) {
            return new Types_FrontEndRoutes.ProjectDetailsFERoute({
                projectId: v1.projectId.value0
            });
        };
        if (routeName === "PrintingFERoute" && v1.projectId instanceof Data_Maybe.Just) {
            return new Types_FrontEndRoutes.PrintingFERoute({
                projectId: v1.projectId.value0
            });
        };
        if (routeName === "OrganizationFERoute") {
            return Types_FrontEndRoutes.OrganizationFERoute.value;
        };
        if (routeName === "EstimationFERoute" && v1.projectId instanceof Data_Maybe.Just) {
            return new Types_FrontEndRoutes.EstimationFERoute({
                projectId: v1.projectId.value0,
                initialSelectedComponent: Data_Functor.map(Data_Maybe.functorMaybe)(Types_Component.ComponentId)(v1.initialSelectedComponent)
            });
        };
        if (routeName === "EstimationReferenceRoute" && v1.projectId instanceof Data_Maybe.Just) {
            return new Types_FrontEndRoutes.EstimationReferenceRoute({
                projectId: v1.projectId.value0
            });
        };
        if (routeName === "ReferenceEstimationElementsRoute" && v1.projectId instanceof Data_Maybe.Just) {
            return new Types_FrontEndRoutes.ReferenceEstimationElementsRoute({
                projectId: v1.projectId.value0
            });
        };
        if (routeName === "EstimationBuildingElementsRoute" && v1.projectId instanceof Data_Maybe.Just) {
            return new Types_FrontEndRoutes.EstimationBuildingElementsRoute({
                projectId: v1.projectId.value0,
                initialSelectedElement: Data_Functor.map(Data_Maybe.functorMaybe)(Types_Element.ElementId)(v1.initialSelectedElement)
            });
        };
        if (routeName === "EstimationResourcesRoute" && v1.projectId instanceof Data_Maybe.Just) {
            return new Types_FrontEndRoutes.EstimationResourcesRoute({
                projectId: v1.projectId.value0
            });
        };
        if (routeName === "NewEstimationResourcesRoute" && v1.projectId instanceof Data_Maybe.Just) {
            return new Types_FrontEndRoutes.NewEstimationResourcesRoute({
                projectId: v1.projectId.value0
            });
        };
        if (routeName === "EstimationResourcePriceListRoute" && v1.projectId instanceof Data_Maybe.Just) {
            return new Types_FrontEndRoutes.EstimationResourcePriceListRoute({
                projectId: v1.projectId.value0
            });
        };
        if (routeName === "EstimationReportsRoute" && v1.projectId instanceof Data_Maybe.Just) {
            return new Types_FrontEndRoutes.EstimationReportsRoute({
                projectId: v1.projectId.value0
            });
        };
        if (routeName === "OfferPageRoute" && v1.projectId instanceof Data_Maybe.Just) {
            return new Types_FrontEndRoutes.OfferPageRoute({
                projectId: v1.projectId.value0
            });
        };
        if (routeName === "LogsFERoute") {
            return Types_FrontEndRoutes.LogsFERoute.value;
        };
        if (routeName === "NotificationsFERoute") {
            return Types_FrontEndRoutes.NotificationsFERoute.value;
        };
        if (routeName === "AdminFERoute") {
            return Types_FrontEndRoutes.AdminFERoute.value;
        };
        if (routeName === "SuperAdminFERoute") {
            return Types_FrontEndRoutes.SuperAdminFERoute.value;
        };
        if (routeName === "IntegrationFERoute") {
            return Types_FrontEndRoutes.IntegrationFERoute.value;
        };
        if (routeName === "ReleaseNotesFERoute") {
            return Types_FrontEndRoutes.ReleaseNotesFERoute.value;
        };
        if (routeName === "UIDemoFERoute") {
            return new Types_FrontEndRoutes.UIDemoFERoute({
                componentId: Data_Maybe.Nothing.value
            });
        };
        if (routeName === "DixonFERoute" && v1.projectId instanceof Data_Maybe.Just) {
            return new Types_FrontEndRoutes.DixonFERoute({
                projectId: v1.projectId.value0,
                mFileId: v1.mFileId,
                mDrawingId: v1.mDrawingId,
                mMeasurementGroupId: v1.mMeasurementGroupId
            });
        };
        if (routeName === "OauthCode") {
            return Types_FrontEndRoutes.OauthCode.value;
        };
        return Types_FrontEndRoutes.NotFoundFERoute.value;
    };
};
var useNavigateToRoute = function __do() {
    var dispatch = StateHooks.useDispatch();
    return function (url) {
        return function (params) {
            return dispatch(Actions.NavigateRoute.create(tsToFERoute(url)(params)));
        };
    };
};
module.exports = {
    tsToFERoute: tsToFERoute,
    useNavigateToRoute: useNavigateToRoute
};
