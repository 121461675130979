// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var React_Basic = require("../React.Basic/index.js");
var TacoAutocomplete = require("../TacoAutocomplete/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoText = require("../TacoText/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var imports = "import TacoAutocomplete as TacoAutocomplete\x0a";
var example1 = imports + "\x0aTuple value setValue <- useState Nothing\x0a\x0apure $ RB.fragment\x0a  [ TacoAutocomplete.component\x0a      { sections\x0a      , placeholder: \"Select a fruit\"\x0a      , testId: \"taco-autocomplete-demo\"\x0a      , onSelect: \\id -> setValue \\_ -> id\x0a      , selected: value\x0a      , clearButton: true\x0a      }\x0a  , box \"resultContainer\" [ MarginTop M ] [ TacoText.component { text: \"You have selected: \" <> value } ]\x0a  ]\x0a";
var autocompleteSections = [ {
    list: [ {
        text: "Apple",
        id: "apple"
    }, {
        text: "Banana",
        id: "banana"
    }, {
        text: "Coconut",
        id: "coconut"
    }, {
        text: "Dragonfruit",
        id: "dragonfruit"
    }, {
        text: "Elderberry",
        id: "elderberry"
    } ],
    text: Data_Maybe.Nothing.value
} ];
var example1_jsx = TofuHooks.mkHookComponent("AutoCompleteExample1")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        return React_Basic.fragment([ TacoAutocomplete.component()()({
            sections: autocompleteSections,
            placeholder: "Select a fruit",
            testId: "taco-autocomplete-demo",
            onSelect: function (id) {
                return v1.value1(function (v2) {
                    return id;
                });
            },
            selected: v1.value0,
            clearButton: true
        }), (function () {
            if (v1.value0 instanceof Data_Maybe.Just) {
                return Box.box("resultContainer")([ new Box.MarginTop(TacoConstants.M.value) ])([ TacoText.component()()({
                    text: "You have selected: " + v1.value0.value0.text
                }) ]);
            };
            if (v1.value0 instanceof Data_Maybe.Nothing) {
                return Data_Monoid.mempty(React_Basic.monoidJSX);
            };
            throw new Error("Failed pattern match at TacoAutocomplete.Demo (line 70, column 7 - line 74, column 17): " + [ v1.value0.constructor.name ]);
        })() ]);
    };
});
var sections = [ {
    title: "Default",
    view: [ example1_jsx({}) ],
    code: example1
} ];
var demoView = {
    sections: sections,
    title: "TacoAutocomplete",
    description: "Item selection dropdown"
};
module.exports = {
    demoView: demoView
};
