// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var StyleProperties = require("../StyleProperties/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var subNavigationStyles = Box.boxStyle([ Box.FlexRow.value, new Box.FlexGrow(1), new Box.Height(TacoConstants.subNavigationHeight), Box.MinHeight.create(TacoConstants.subNavigationHeight), new Box.ZIndex(TacoConstants.zIndexSubNavigation), Box.Style.create(React_Basic_Emotion.css()({
    borderLeft: StyleProperties.border(TacoConstants.BorderLight.value)
})) ]);
var spacingStyles = Box.boxStyle([ new Box.FlexGrow(1) ]);
var separatorStyles = Box.boxStyle([ Box.Height.create(new TacoConstants.CustomSize("100%")), Box.Style.create(React_Basic_Emotion.css()({
    borderLeft: StyleProperties.border(TacoConstants.BorderLight.value)
})) ]);
var navigationItemStyles = function (v) {
    return Box.boxStyle([ new Box.Display(Box.Flex.value), new Box.ForegroundColor((function () {
        if (v.active) {
            return v.highlightColor;
        };
        return TacoConstants.GrayMedium.value;
    })()), Box.Height.create(new TacoConstants.CustomSize("100%")), Box.AlignCenter.value, new Box.PaddingX(TacoConstants.M.value), new Box.Hover([ new Box.ForegroundColor(v.highlightColor) ]), Box.Style.create(React_Basic_Emotion.merge(Data_Array.catMaybes([ Data_Maybe.Just.create(React_Basic_Emotion.css()({
        borderBottomWidth: React_Basic_Emotion.str("2px"),
        borderBottomStyle: React_Basic_Emotion.str("solid"),
        borderBottomColor: StyleProperties.colorScheme((function () {
            if (v.active) {
                return v.highlightColor;
            };
            return TacoConstants.Transparent.value;
        })()),
        paddingTop: React_Basic_Emotion.str("2px")
    })), Data_Monoid.guard(Data_Monoid.monoidFn(Data_Maybe.monoidMaybe(React_Basic_Emotion.semigroupStyle)))(v.disabled)(Data_Maybe.Just.create)(Box.boxStyle([ new Box.ForegroundColor(TacoConstants.GrayLight.value), new Box.Hover([ new Box.ForegroundColor(TacoConstants.GrayLight.value), new Box.Cursor(Box.NotAllowed.value) ]) ])) ]))) ]);
};
module.exports = {
    subNavigationStyles: subNavigationStyles,
    separatorStyles: separatorStyles,
    spacingStyles: spacingStyles,
    navigationItemStyles: navigationItemStyles
};
