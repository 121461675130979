// Generated by purs version 0.14.5
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Kishimen = require("../Kishimen/index.js");
var ReadParam = require("../ReadParam/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_Project = require("../Types.Project/index.js");
var ClassificationGroupId = function (x) {
    return x;
};
var ClassificationGroupCode = function (x) {
    return x;
};
var WasmClassificationGroup = function (x) {
    return x;
};
var AddClassificationGroup = (function () {
    function AddClassificationGroup(value0) {
        this.value0 = value0;
    };
    AddClassificationGroup.create = function (value0) {
        return new AddClassificationGroup(value0);
    };
    return AddClassificationGroup;
})();
var UpdateClassificationGroup = (function () {
    function UpdateClassificationGroup(value0) {
        this.value0 = value0;
    };
    UpdateClassificationGroup.create = function (value0) {
        return new UpdateClassificationGroup(value0);
    };
    return UpdateClassificationGroup;
})();
var DeleteClassificationGroup = (function () {
    function DeleteClassificationGroup(value0) {
        this.value0 = value0;
    };
    DeleteClassificationGroup.create = function (value0) {
        return new DeleteClassificationGroup(value0);
    };
    return DeleteClassificationGroup;
})();
var writeParamClassificationGroupId = ReadParam.writeParamInt;
var writeParamClassificationGroupCode = ReadParam.writeParamString;
var tofuJSONClassificationGroupId = TofuJSON.readInt;
var tofuJSONClassificationGroupCode = TofuJSON.readString;
var tofuJSONClassificationGroup = TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "code";
    }
})(tofuJSONClassificationGroupCode)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "description";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "id";
    }
})(tofuJSONClassificationGroupId)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "memo";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "projectId";
    }
})(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "code";
    }
})(tofuJSONClassificationGroupCode)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "description";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "id";
    }
})(tofuJSONClassificationGroupId)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "memo";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "projectId";
    }
})(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()());
var showClassificationGroupId = Data_Show.showInt;
var showClassificationGroupCode = Data_Show.showString;
var showWasmClassificationGroup = Data_Show.showRecord()(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "code";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "description";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "id";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "memo";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "projectId";
    }
})(Data_Show.showRecordFieldsNil)(Types_Project.showProjectId))(Data_Nullable.showNullable(Data_Show.showString)))(showClassificationGroupId))(Data_Nullable.showNullable(Data_Show.showString)))(showClassificationGroupCode));
var readParamClassificationGroupId = ReadParam.intReadParam;
var readParamClassificationGroupCode = ReadParam.stringReadParam;
var ordClassificationGroupId = Data_Ord.ordInt;
var ordClassificationGroupCode = Data_Ord.ordString;
var newtypeWasmClassificationGroup = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeClassificationGroupId = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeClassificationGroupCode = {
    Coercible0: function () {
        return undefined;
    }
};
var genericClassificationGroupAction = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new AddClassificationGroup(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new UpdateClassificationGroup(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
            return new DeleteClassificationGroup(x.value0.value0);
        };
        throw new Error("Failed pattern match at Types.ClassificationGroup (line 64, column 1 - line 64, column 88): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof AddClassificationGroup) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof UpdateClassificationGroup) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof DeleteClassificationGroup) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0));
        };
        throw new Error("Failed pattern match at Types.ClassificationGroup (line 64, column 1 - line 64, column 88): " + [ x.constructor.name ]);
    }
};
var tofuJSONClassificationGroupAction = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddClassificationGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(tofuJSONClassificationGroupCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "memo";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(tofuJSONClassificationGroupCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "memo";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DeleteClassificationGroup";
        }
    })(tofuJSONClassificationGroupId)()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UpdateClassificationGroup";
        }
    })(tofuJSONClassificationGroup)()(TofuJSON.readVariantNil))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddClassificationGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(tofuJSONClassificationGroupCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "memo";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(tofuJSONClassificationGroupCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "memo";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DeleteClassificationGroup";
        }
    })(tofuJSONClassificationGroupId)()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UpdateClassificationGroup";
        }
    })(tofuJSONClassificationGroup)()(TofuJSON.nilWriteForeignVariant))))(genericClassificationGroupAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddClassificationGroup";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UpdateClassificationGroup";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DeleteClassificationGroup";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateClassificationGroup";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DeleteClassificationGroup";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddClassificationGroup";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateClassificationGroup";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteClassificationGroup";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericClassificationGroupAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddClassificationGroup";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UpdateClassificationGroup";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DeleteClassificationGroup";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateClassificationGroup";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DeleteClassificationGroup";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddClassificationGroup";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UpdateClassificationGroup";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteClassificationGroup";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddClassificationGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(tofuJSONClassificationGroupCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "memo";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(tofuJSONClassificationGroupCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "memo";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DeleteClassificationGroup";
        }
    })(tofuJSONClassificationGroupId)()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UpdateClassificationGroup";
        }
    })(tofuJSONClassificationGroup)()(TofuJSON.readVariantNil))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddClassificationGroup";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(tofuJSONClassificationGroupCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "memo";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(tofuJSONClassificationGroupCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "memo";
        }
    })(TofuJSON.readMaybe(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DeleteClassificationGroup";
        }
    })(tofuJSONClassificationGroupId)()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UpdateClassificationGroup";
        }
    })(tofuJSONClassificationGroup)()(TofuJSON.nilWriteForeignVariant))))
};
var eqClassificationGroupId = Data_Eq.eqInt;
var eqClassificationGroupCode = Data_Eq.eqString;
var eqClassificationGroup = {
    eq: function (x) {
        return function (y) {
            return Data_Eq.eq(eqClassificationGroupCode)(x.code)(y.code) && Data_Eq.eq(Data_Nullable.eqNullable(Data_Eq.eqString))(x.description)(y.description) && Data_Eq.eq(eqClassificationGroupId)(x.id)(y.id) && Data_Eq.eq(Data_Nullable.eqNullable(Data_Eq.eqString))(x.memo)(y.memo) && Data_Eq.eq(Types_Project.eqProjectId)(x.projectId)(y.projectId);
        };
    }
};
module.exports = {
    ClassificationGroupId: ClassificationGroupId,
    AddClassificationGroup: AddClassificationGroup,
    UpdateClassificationGroup: UpdateClassificationGroup,
    DeleteClassificationGroup: DeleteClassificationGroup,
    ClassificationGroupCode: ClassificationGroupCode,
    WasmClassificationGroup: WasmClassificationGroup,
    newtypeWasmClassificationGroup: newtypeWasmClassificationGroup,
    showWasmClassificationGroup: showWasmClassificationGroup,
    eqClassificationGroup: eqClassificationGroup,
    newtypeClassificationGroupCode: newtypeClassificationGroupCode,
    eqClassificationGroupCode: eqClassificationGroupCode,
    ordClassificationGroupCode: ordClassificationGroupCode,
    readParamClassificationGroupCode: readParamClassificationGroupCode,
    writeParamClassificationGroupCode: writeParamClassificationGroupCode,
    tofuJSONClassificationGroupCode: tofuJSONClassificationGroupCode,
    showClassificationGroupCode: showClassificationGroupCode,
    newtypeClassificationGroupId: newtypeClassificationGroupId,
    eqClassificationGroupId: eqClassificationGroupId,
    ordClassificationGroupId: ordClassificationGroupId,
    readParamClassificationGroupId: readParamClassificationGroupId,
    writeParamClassificationGroupId: writeParamClassificationGroupId,
    tofuJSONClassificationGroupId: tofuJSONClassificationGroupId,
    showClassificationGroupId: showClassificationGroupId,
    tofuJSONClassificationGroup: tofuJSONClassificationGroup,
    genericClassificationGroupAction: genericClassificationGroupAction,
    tofuJSONClassificationGroupAction: tofuJSONClassificationGroupAction
};
