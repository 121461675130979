// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var viewportContainerStyles = Box.boxStyle([ new Box.FlexGrow(1), Box.Style.create(React_Basic_Emotion.css()({
    gridArea: React_Basic_Emotion.str("viewport")
})), new Box.Overflow(Box.Hidden.value, Box.Hidden.value) ]);
module.exports = {
    viewportContainerStyles: viewportContainerStyles
};
