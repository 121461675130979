import { IStatusPanelParams } from "ag-grid-community";
import { ClassificationGroup } from "../../../../../ts-bindings/ClassificationGroup";
import { useTranslation } from "react-i18next";
import { useSelectedRows } from "../../../components/Table/useSelectedRows";
import { useCreateComponentGroupsMutation } from "./useComponentGroups";
import { Button } from "@tocoman/ui";

export function ImportSelectedGroupsButton({
  api,
  projectId,
  groupsEdited,
}: IStatusPanelParams<ClassificationGroup> & {
  projectId: number;
  groupsEdited: () => void;
}) {
  const { t } = useTranslation("estimation", {
    keyPrefix: "componentGroupEdit",
  });

  const selectedRows = useSelectedRows(api);

  const {
    mutate: importGroups,
    isLoading,
    isSuccess,
  } = useCreateComponentGroupsMutation(projectId);

  const handleImport = (data: ClassificationGroup[]) => {
    importGroups({
      groupData: data.map((g) => {
        return { code: g.code, description: g.description };
      }),
    });
    groupsEdited();
  };

  return (
    <div className={"flex flex-row"}>
      {isSuccess && <div className={"mt-3 mr-10"}>{t`groupsImported`}</div>}
      <Button
        onClick={() => {
          handleImport(selectedRows);
        }}
        loading={isLoading}
        disabled={selectedRows.length === 0 || isLoading}
        className={"my-2"}
      >{t`form.import`}</Button>
    </div>
  );
}
