// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var LengthValidator = require("../LengthValidator/index.js");
var Locale = require("../Locale/index.js");
var NarrowLayout = require("../NarrowLayout/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var StateHooks = require("../StateHooks/index.js");
var StringUtils = require("../StringUtils/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Organization = require("../Types.Organization/index.js");
var Types_Project = require("../Types.Project/index.js");
var UseFetch = require("../UseFetch/index.js");
var WindowUtils = require("../WindowUtils/index.js");
var ErrorTooLong = (function () {
    function ErrorTooLong() {

    };
    ErrorTooLong.value = new ErrorTooLong();
    return ErrorTooLong;
})();
var ErrorEmpty = (function () {
    function ErrorEmpty() {

    };
    ErrorEmpty.value = new ErrorEmpty();
    return ErrorEmpty;
})();
var Normal = (function () {
    function Normal() {

    };
    Normal.value = new Normal();
    return Normal;
})();
var pmTableInstance = TacoTable.mkTableStateless(TofuHooks.getNameVoid)("pmTable");
var component = TofuHooks.mkHookComponent("OrganizationContainer")(function (v) {
    return function __do() {
        var appDispatch = StateHooks.useDispatch();
        var v1 = UseFetch.useFetchItems(Data_Eq.eqUnit)(Data_Unit.unit)(function (v1) {
            return v1.projectManagers;
        })(Actions.GetProjectManagersRequest.value)();
        var v2 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v2) {
            return v2.currentOrganization;
        })(Actions.GetCurrentOrganizationRequest.value)();
        var v3 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var v4 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var org = React_Basic_DOM_Generated.h1()({
            className: "org-name",
            children: Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM.text(StringUtils.capitalize(Data_Newtype.un()(Types_Organization.Organization)(Data_Maybe.maybe("")(function (v5) {
                return v5.organization;
            })(v2.value0)))))
        });
        var modalBody = function (pm) {
            return TacoModal.modalBody({
                contents: [ React_Basic_DOM.text(Locale.lookup_("confirm_project_manager_deletion") + (Data_Newtype.un()(Types_Project.ProjectManager)(pm) + "?")) ]
            });
        };
        var headingPMs = React_Basic_DOM_Generated.h1_(Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM.text(Locale.lookup_("common_project_managers"))));
        var headings = ReactBasicUtils["div'"]("headings")([ org, headingPMs ]);
        var handlePrompt = function (pm) {
            return function (msgType) {
                var normalMsg = Locale.lookup_("confirm_project_manager_edit") + (Data_Newtype.un()(Types_Project.ProjectManager)(pm) + ": ");
                var msg = (function () {
                    if (msgType instanceof ErrorTooLong) {
                        return Locale.lookup_("too_long_name_error") + ("\x0a\x0a" + normalMsg);
                    };
                    if (msgType instanceof ErrorEmpty) {
                        return Locale.lookup_("empty_name_error") + ("\x0a\x0a" + normalMsg);
                    };
                    if (msgType instanceof Normal) {
                        return normalMsg;
                    };
                    throw new Error("Failed pattern match at OrganizationContainer (line 148, column 15 - line 151, column 30): " + [ msgType.constructor.name ]);
                })();
                return function __do() {
                    var m = WindowUtils.prompt(msg)("")();
                    if (m instanceof Data_Maybe.Nothing) {
                        return Data_Unit.unit;
                    };
                    if (m instanceof Data_Maybe.Just && Data_String_CodePoints.length(m.value0) > 50) {
                        return handlePrompt(pm)(ErrorTooLong.value)();
                    };
                    if (m instanceof Data_Maybe.Just && Data_String_CodePoints.length(m.value0) < 1) {
                        return handlePrompt(pm)(ErrorEmpty.value)();
                    };
                    if (m instanceof Data_Maybe.Just) {
                        return appDispatch(new Actions.EditProjectManagerRequest({
                            newPM: m.value0,
                            oldPM: pm
                        }))();
                    };
                    throw new Error("Failed pattern match at OrganizationContainer (line 153, column 7 - line 161, column 57): " + [ m.constructor.name ]);
                };
            };
        };
        var editButton = function (pm) {
            return TacoButton.component()()({
                text: Locale.lookup_("edit"),
                onClick: Data_Maybe.Just.create(handlePrompt(pm)(Normal.value)),
                icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconEditV1.value),
                testId: "edit-pm-" + (Data_Show.show(Types_Project.showProjectManager)(pm) + "-button")
            });
        };
        var deleteButton = function (pm) {
            return TacoButton.component()()({
                text: Locale.lookup_("common_delete"),
                onClick: new Data_Maybe.Just((function () {
                    var v5 = Locale.lookup_("confirm_project_manager_deletion") + (Data_Newtype.un()(Types_Project.ProjectManager)(pm) + "?");
                    return v4.value1(function (v6) {
                        return new Data_Maybe.Just(pm);
                    });
                })()),
                color: TacoConstants.SecondaryRed.value,
                icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconDelete24Px.value),
                testId: "delete-pm-" + (Data_Show.show(Types_Project.showProjectManager)(pm) + "-button")
            });
        };
        var columns = [ {
            cell: TacoTable_Types.PlainTextCell.create(Data_Newtype.un()(Types_Project.ProjectManager)),
            width: new TacoTable_Types.Flex(20),
            key: "name",
            label: React_Basic_DOM.text(""),
            sortProperty: Data_Maybe.Nothing.value,
            headerJSX: Data_Maybe.Nothing.value
        }, {
            cell: TacoTable_Types.JSXCell.create([  ])(editButton),
            width: new TacoTable_Types.Fixed("9rem"),
            key: "editButton",
            label: React_Basic_DOM.text(""),
            sortProperty: Data_Maybe.Nothing.value,
            headerJSX: Data_Maybe.Nothing.value
        }, {
            cell: TacoTable_Types.JSXCell.create([  ])(deleteButton),
            width: new TacoTable_Types.Fixed("8rem"),
            key: "deleteButton",
            label: React_Basic_DOM.text(""),
            sortProperty: Data_Maybe.Nothing.value,
            headerJSX: Data_Maybe.Nothing.value
        } ];
        var pmRow = function (pm) {
            return {
                rowData: pm,
                columns: columns,
                onClick: Data_Function["const"](Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit))),
                rowKey: Data_Show.show(Types_Project.showProjectManager)(pm),
                foldingElement: Data_Maybe.Nothing.value,
                className: ""
            };
        };
        var pmList = TacoTable["tableStateless'"](TofuHooks.getNameVoid)(Data_Eq.eqVoid)(Types_Project.eqProjectManager)(true)(pmTableInstance)(Data_Functor.map(Data_Functor.functorArray)(pmRow)(v1.value0))(columns);
        var closeModal = v4.value1(function (v5) {
            return Data_Maybe.Nothing.value;
        });
        var modalActions = function (pm) {
            return TacoModal.modalActions({
                contents: [ TacoButton.component()()({
                    onClick: Data_Maybe.Just.create(closeModal),
                    text: "Ei"
                }), TacoButton.component()()({
                    onClick: new Data_Maybe.Just(function __do() {
                        appDispatch(new Actions.DeleteProjectManagerRequest({
                            pm: pm
                        }))();
                        return closeModal();
                    }),
                    buttonStyle: TacoButton_Types.Filled.value,
                    color: TacoConstants.SecondaryRed.value,
                    text: "Kyll\xe4"
                }) ]
            });
        };
        var confirmModal = (function () {
            if (v4.value0 instanceof Data_Maybe.Nothing) {
                return React_Basic.empty;
            };
            if (v4.value0 instanceof Data_Maybe.Just) {
                return TacoModal.component()()({
                    onClose: closeModal,
                    isActive: true,
                    heading: "Poista projektip\xe4\xe4llikk\xf6",
                    contents: [ modalBody(v4.value0.value0), modalActions(v4.value0.value0) ]
                });
            };
            throw new Error("Failed pattern match at OrganizationContainer (line 68, column 20 - line 79, column 12): " + [ v4.value0.constructor.name ]);
        })();
        var additionForm = React_Basic_DOM_Generated.div()({
            className: "addition-form",
            children: [ React_Basic_DOM.text(Locale.lookup_("create_new")), React_Basic_DOM_Generated.form_([ TacoInput.stringInput()()(Data_Eq.eqString)({
                validator: LengthValidator.lengthValidator(0)(50),
                onChange: TacoInput.changeHandler(TacoInput.inputValueString)(function (val) {
                    return v3.value1(function (v5) {
                        return new Data_Maybe.Just(val);
                    });
                }),
                value: Data_Maybe.fromMaybe("")(v3.value0),
                required: true,
                testId: "new-project-manager-name"
            }), TacoButton.component()()({
                text: Locale.lookup_("add_project_manager"),
                submit: true,
                onClick: Data_Maybe.Just.create((function () {
                    if (v3.value0 instanceof Data_Maybe.Just) {
                        var $25 = Data_String_Common["null"](v3.value0.value0);
                        if ($25) {
                            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                        };
                        return function __do() {
                            appDispatch(new Actions.AddProjectManagerRequest({
                                pm: v3.value0.value0
                            }))();
                            return v3.value1(function (v5) {
                                return Data_Maybe.Nothing.value;
                            })();
                        };
                    };
                    if (v3.value0 instanceof Data_Maybe.Nothing) {
                        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                    };
                    throw new Error("Failed pattern match at OrganizationContainer (line 110, column 19 - line 118, column 41): " + [ v3.value0.constructor.name ]);
                })()),
                testId: "add-project-manager-button"
            }) ]) ]
        });
        var formAndTable = React_Basic_DOM_Generated.div_([ additionForm, pmList ]);
        var organizationContent = React_Basic_DOM_Generated.div_([ headings, formAndTable ]);
        var view = React_Basic_DOM_Generated.main()({
            className: "organization-main",
            children: [ organizationContent ]
        });
        return NarrowLayout.component()()({
            children: [ view, confirmModal ]
        });
    };
});
module.exports = {
    component: component
};
