// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var CostEstimation_Calculations = require("../CostEstimation.Calculations/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var EditCostGroupItemsTable = require("../EditCostGroupItemsTable/index.js");
var Effect = require("../Effect/index.js");
var LengthValidator = require("../LengthValidator/index.js");
var React_Basic = require("../React.Basic/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoModal = require("../TacoModal/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_CostGroup = require("../Types.CostGroup/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var Utils = require("../Utils/index.js");
var toTargetItems = function (targetItems) {
    return function (cc) {
        var socialExpensePercentage = Utils.nullable(0.0)(Types_Unit.PercentageOfZeroToOne)(cc.socialExpensePercentageInCostControlForTargets);
        var socialExpensePercentageValue = Data_Newtype.un()(Types_Unit.PercentageOfZeroToOne)(socialExpensePercentage);
        var filtered = Data_Array.filter((function () {
            var $53 = Data_Eq.eq(Types_Newtypes.eqCostClassCode)(cc.costClassCode);
            return function ($54) {
                return $53((function (v) {
                    return v.costClassCode;
                })($54));
            };
        })())(targetItems);
        var value = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.value;
        })(filtered));
        return {
            costClassCode: cc.costClassCode,
            costClassLabel: Data_Newtype.unwrap()(cc.costClassCode) + (": " + cc.name),
            socialExpensePercentage: socialExpensePercentage,
            socialExpenses: socialExpensePercentageValue * value,
            targetItemValue: value,
            targetItemValueWithSocialExpenses: value * (1.0 + socialExpensePercentageValue)
        };
    };
};
var extractTargetItemSum = function (v) {
    return {
        costClassCode: v.costClassCode,
        diff: 0.0,
        initSum: v.targetItemValue,
        sum: v.targetItemValue,
        sumWithSE: CostEstimation_Calculations.toCurrencyPrecision((1.0 + v.socialExpensePercentage) * v.targetItemValue),
        socialExpenses: v.socialExpensePercentage * v.targetItemValue,
        socialExpensePercentage: v.socialExpensePercentage
    };
};
var calculateTotalWithSE = function (v) {
    return function (inputValueWithSE) {
        return (1.0 + v) * inputValueWithSE;
    };
};
var component = TofuHooks.mkHookComponent("EditCostGroupItem")(function (props) {
    var v = Data_Newtype.un()(Types_CostGroup.CostGroupUnit)(props.costGroupUnit);
    var targetItemRows = Data_Functor.map(Data_Functor.functorArray)(toTargetItems(props.costGroupItems))(props.costClasses);
    var initInputs = Data_Functor.map(Data_Functor.functorArray)(extractTargetItemSum)(targetItemRows);
    return function __do() {
        var appDispatch = StateHooks.useDispatch();
        var v1 = TofuHooks.useState(initInputs)();
        var v2 = TofuHooks.useState("")();
        TofuHooks.useLayoutEffect([ props.modalOpen ])(function __do() {
            v1.value1(function (v3) {
                return initInputs;
            })();
            v2.value1(function (v3) {
                return "";
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        })();
        var table = EditCostGroupItemsTable.component({
            costClasses: props.costClasses,
            socialExpenses: props.socialExpenses,
            targetItems: props.costGroupItems,
            inputs: v1.value0,
            setInputs: v1.value1,
            targetItemRows: targetItemRows
        });
        var submit = (function () {
            var mkParams = function (input) {
                return {
                    costGroupCode: v.code,
                    amount: input.diff,
                    costClassCode: input.costClassCode,
                    description: v2.value0
                };
            };
            var inputsWithDiff = Data_Array.filter(function (input) {
                return input.diff !== 0.0;
            })(v1.value0);
            var addCostGroupItemsAction = (function () {
                if (props.addCostGroupItemType instanceof Types_CostGroup.AddCostGroupItemTypeEstimateItem) {
                    return Types_CostGroup.AddCostGroupEstimateItemsAction.create;
                };
                if (props.addCostGroupItemType instanceof Types_CostGroup.AddCostGroupItemTypeTargetItem) {
                    return Types_CostGroup.AddCostGroupTargetItemsAction.create;
                };
                throw new Error("Failed pattern match at EditCostGroupItems (line 81, column 35 - line 83, column 74): " + [ props.addCostGroupItemType.constructor.name ]);
            })();
            var submitInputs = appDispatch(new Actions.CostGroupItemActionRequest({
                projectId: props.projectId,
                params: addCostGroupItemsAction(Data_Functor.map(Data_Functor.functorArray)(mkParams)(inputsWithDiff))
            }));
            return function __do() {
                submitInputs();
                return props.closeModal();
            };
        })();
        var reasonInput = TacoInput.stringInput()()(Data_Eq.eqString)({
            textarea: true,
            label: "Muutoksen syy",
            value: v2.value0,
            onChange: v2.value1,
            validator: LengthValidator.lengthValidator(0)(8000)
        });
        var modalBody = TacoModal.modalBody({
            contents: [ table, reasonInput ]
        });
        var itemTypeEditName = (function () {
            if (props.addCostGroupItemType instanceof Types_CostGroup.AddCostGroupItemTypeEstimateItem) {
                return "ennustetta";
            };
            if (props.addCostGroupItemType instanceof Types_CostGroup.AddCostGroupItemTypeTargetItem) {
                return "tavoitetta";
            };
            throw new Error("Failed pattern match at EditCostGroupItems (line 150, column 24 - line 152, column 53): " + [ props.addCostGroupItemType.constructor.name ]);
        })();
        var heading = "Muokkaa " + (itemTypeEditName + (" litteralle " + (Data_Newtype.un()(Types_CostGroup.CostGroupCode)(v.code) + (" " + v.name))));
        var copyTargetToEstimateButton = (function () {
            if (props.addCostGroupItemType instanceof Types_CostGroup.AddCostGroupItemTypeTargetItem) {
                return React_Basic.empty;
            };
            if (props.addCostGroupItemType instanceof Types_CostGroup.AddCostGroupItemTypeEstimateItem) {
                return TacoButton.component()()({
                    onClick: new Data_Maybe.Just(v1.value1(function (v3) {
                        return Data_Functor.mapFlipped(Data_Functor.functorArray)(initInputs)(function (input) {
                            var matchCostClassCode = function (code1) {
                                return function (v4) {
                                    return Data_Eq.eq(Types_Newtypes.eqCostClassCode)(v4.costClassCode)(code1);
                                };
                            };
                            var v4 = Data_Array.find(matchCostClassCode(input.costClassCode))(props.costClassResults);
                            if (v4 instanceof Data_Maybe.Just) {
                                var diff = v4.value0.target - input.sum;
                                var sum = input.sum + diff;
                                var sumWithSE = calculateTotalWithSE(input.socialExpensePercentage)(sum);
                                return {
                                    diff: diff,
                                    sum: sum,
                                    sumWithSE: sumWithSE,
                                    costClassCode: input.costClassCode,
                                    initSum: input.initSum,
                                    socialExpensePercentage: input.socialExpensePercentage,
                                    socialExpenses: input.socialExpenses
                                };
                            };
                            if (v4 instanceof Data_Maybe.Nothing) {
                                return input;
                            };
                            throw new Error("Failed pattern match at EditCostGroupItems (line 99, column 17 - line 106, column 35): " + [ v4.constructor.name ]);
                        });
                    })),
                    text: "Tasaa tavoitteeseen",
                    testId: "copy-target-to-estimate"
                });
            };
            throw new Error("Failed pattern match at EditCostGroupItems (line 92, column 34 - line 109, column 12): " + [ props.addCostGroupItemType.constructor.name ]);
        })();
        var copyActualToEstimateButton = (function () {
            if (props.addCostGroupItemType instanceof Types_CostGroup.AddCostGroupItemTypeTargetItem) {
                return React_Basic.empty;
            };
            if (props.addCostGroupItemType instanceof Types_CostGroup.AddCostGroupItemTypeEstimateItem) {
                return TacoButton.component()()({
                    onClick: new Data_Maybe.Just(v1.value1(function (v3) {
                        return Data_Functor.mapFlipped(Data_Functor.functorArray)(initInputs)(function (input) {
                            var matchCostClassCode = function (code1) {
                                return function (v4) {
                                    return Data_Eq.eq(Types_Newtypes.eqCostClassCode)(v4.costClassCode)(code1);
                                };
                            };
                            var v4 = Data_Array.find(matchCostClassCode(input.costClassCode))(props.costClassResults);
                            if (v4 instanceof Data_Maybe.Just) {
                                var diff = v4.value0.actual - input.sum;
                                var sum = input.sum + diff;
                                var sumWithSE = calculateTotalWithSE(input.socialExpensePercentage)(sum);
                                return {
                                    diff: diff,
                                    sum: sum,
                                    sumWithSE: sumWithSE,
                                    costClassCode: input.costClassCode,
                                    initSum: input.initSum,
                                    socialExpensePercentage: input.socialExpensePercentage,
                                    socialExpenses: input.socialExpenses
                                };
                            };
                            if (v4 instanceof Data_Maybe.Nothing) {
                                return input;
                            };
                            throw new Error("Failed pattern match at EditCostGroupItems (line 118, column 17 - line 125, column 35): " + [ v4.constructor.name ]);
                        });
                    })),
                    text: "Tasaa toteumaan",
                    testId: "copy-actual-to-estimate"
                });
            };
            throw new Error("Failed pattern match at EditCostGroupItems (line 111, column 34 - line 128, column 12): " + [ props.addCostGroupItemType.constructor.name ]);
        })();
        var modalActions = TacoModal.modalActions({
            contents: [ TacoButton.component()()({
                onClick: Data_Maybe.Just.create(props.closeModal),
                text: "Peruuta",
                testId: "cancel-modal"
            }), Box.box("_EditCostGroupItemsModalActionButtons")([ new Box.Gap(TacoConstants.S.value) ])([ copyTargetToEstimateButton, copyActualToEstimateButton, TacoButton.component()()({
                onClick: Data_Maybe.Just.create(submit),
                buttonStyle: TacoButton_Types.Filled.value,
                text: "Tallenna",
                testId: "confirm-modal"
            }) ]) ]
        });
        return ReactBasicUtils["div'"]("__AddCostGroupItem")([ TacoModal.component()()({
            onClose: props.closeModal,
            isActive: props.modalOpen,
            heading: heading,
            contents: [ modalBody, modalActions ]
        }) ]);
    };
});
module.exports = {
    component: component
};
