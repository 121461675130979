// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var DOMUtils = require("../DOMUtils/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var useLocalStorageJSON = function (dictTofuJSON) {
    return function (key) {
        return function (initialValue) {
            return function (initialValueLoading) {
                return function __do() {
                    var v = TofuHooks["useState'"](initialValue)();
                    var setStateAndLocalStorage = function (dictTofuJSON1) {
                        return function (f) {
                            return v.value1(function (s) {
                                return function __do() {
                                    DOMUtils.setLocalStorageItem({
                                        key: key,
                                        value: TofuJSON.writeJSON(dictTofuJSON)(f(s))
                                    })();
                                    return f(s);
                                };
                            });
                        };
                    };
                    TofuHooks.useLayoutEffect([ initialValueLoading ])(function __do() {
                        var initialStorageValue = DOMUtils.getLocalStorageItem(key)();
                        (function () {
                            if (initialValueLoading) {
                                return Data_Unit.unit;
                            };
                            if (!initialValueLoading && initialStorageValue instanceof Data_Maybe.Just) {
                                return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableEither)(TofuJSON.readJSON(dictTofuJSON)(initialStorageValue.value0))(function (y) {
                                    return v.value1(Data_Function["const"](Control_Applicative.pure(Effect.applicativeEffect)(y)));
                                })();
                            };
                            if (!initialValueLoading && initialStorageValue instanceof Data_Maybe.Nothing) {
                                return setStateAndLocalStorage(dictTofuJSON)(Data_Function["const"](initialValue))();
                            };
                            throw new Error("Failed pattern match at UseLocalStorage (line 30, column 5 - line 33, column 69): " + [ initialValueLoading.constructor.name, initialStorageValue.constructor.name ]);
                        })();
                        return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                    })();
                    return new Data_Tuple.Tuple(v.value0, setStateAndLocalStorage(dictTofuJSON));
                };
            };
        };
    };
};
var useLocalStorage = function (key) {
    return function (initialValue) {
        return function (initialValueLoading) {
            return function __do() {
                var v = TofuHooks["useState'"](initialValue)();
                var setStateAndLocalStorage = function (f) {
                    return v.value1(function (s) {
                        return function __do() {
                            DOMUtils.setLocalStorageItem({
                                key: key,
                                value: f(s)
                            })();
                            return f(s);
                        };
                    });
                };
                TofuHooks.useLayoutEffect([ initialValueLoading ])(function __do() {
                    var initialStorageValue = DOMUtils.getLocalStorageItem(key)();
                    (function () {
                        if (initialValueLoading) {
                            return Data_Unit.unit;
                        };
                        if (!initialValueLoading && initialStorageValue instanceof Data_Maybe.Just) {
                            return v.value1(Data_Function["const"](Control_Applicative.pure(Effect.applicativeEffect)(initialStorageValue.value0)))();
                        };
                        if (!initialValueLoading && initialStorageValue instanceof Data_Maybe.Nothing) {
                            return setStateAndLocalStorage(Data_Function["const"](initialValue))();
                        };
                        throw new Error("Failed pattern match at UseLocalStorage (line 47, column 5 - line 50, column 69): " + [ initialValueLoading.constructor.name, initialStorageValue.constructor.name ]);
                    })();
                    return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                })();
                return new Data_Tuple.Tuple(v.value0, setStateAndLocalStorage);
            };
        };
    };
};
module.exports = {
    useLocalStorage: useLocalStorage,
    useLocalStorageJSON: useLocalStorageJSON
};
