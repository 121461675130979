// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var ClientLogger = require("../ClientLogger/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var DOMUtils = require("../DOMUtils/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var DixonDrawingItem = require("../DixonDrawingItem/index.js");
var DixonFileInput = require("../DixonFileInput/index.js");
var DixonFileItem_Styles = require("../DixonFileItem.Styles/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var HtmlUtils = require("../HtmlUtils/index.js");
var Milkis = require("../Milkis/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var RequestUtils = require("../RequestUtils/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoCheckbox_Styles = require("../TacoCheckbox.Styles/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoDropdown = require("../TacoDropdown/index.js");
var TacoDropdown_Types = require("../TacoDropdown.Types/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoModal = require("../TacoModal/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Project = require("../Types.Project/index.js");
var UseUpdatingState = require("../UseUpdatingState/index.js");
var Utils = require("../Utils/index.js");
var component = TofuHooks.mkHookComponent("DixonFileItem")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState(false)();
        var v2 = TofuHooks.useState(false)();
        var v3 = UseUpdatingState.useUpdatingState(Data_Eq.eqString)(v.file.name)();
        var v4 = UseUpdatingState.useUpdatingState(Data_Eq.eqString)(v.file.folder)();
        var v5 = TofuHooks.useState(false)();
        var v6 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var appDispatch = StateHooks.useDispatch();
        var updateButton = DixonFileInput.component({
            editor: v.editor,
            tutorial: false,
            updateFileId: new Data_Maybe.Just(v.file.id),
            onClick: v5.value1(function (v7) {
                return false;
            })
        });
        var mkDixonDrawingItemProps = function (v7) {
            return function (drawing) {
                return {
                    file: v.file,
                    drawing: drawing,
                    editor: v.editor,
                    highlightedMGDrawingIds: v.highlightedMGDrawingIds,
                    projectId: v.projectId
                };
            };
        };
        var highlighted = !Data_Array["null"](Data_Array.intersect(Data_Eq.eqInt)(v.file.drawing_ids)(v.highlightedMGDrawingIds));
        var editModal = TacoModal.component()()({
            onClose: v2.value1(function (v7) {
                return false;
            }),
            isActive: v2.value0,
            heading: "Muokkaa tiedostoa",
            contents: [ TacoModal.modalBody({
                contents: [ React_Basic_DOM_Generated.div_([ TacoInput.stringInput()()(Data_Eq.eqString)({
                    onChange: v3.value1,
                    value: v3.value0,
                    label: "Tiedostonimi"
                }), TacoInput.stringInput()()(Data_Eq.eqString)({
                    onChange: v4.value1,
                    value: v4.value0,
                    label: "Kansio",
                    clearButton: true
                }) ]) ]
            }), TacoModal.modalActions({
                contents: [ TacoButton.component()()({
                    onClick: Data_Maybe.Just.create(v2.value1(function (v7) {
                        return false;
                    })),
                    text: "Peruuta"
                }), TacoButton.component()()({
                    onClick: Data_Maybe.Just.create(function __do() {
                        v.editor.set_file_name(v.file.id, v3.value0);
                        v.editor.set_file_folder(v.file.id, v4.value0);
                        return v2.value1(function (v7) {
                            return false;
                        })();
                    }),
                    buttonStyle: TacoButton_Types.Filled.value,
                    color: TacoConstants.PrimaryBlue.value,
                    text: "Tallenna"
                }) ]
            }) ]
        });
        var editButton = TacoButton.component()()({
            onClick: Data_Maybe.Just.create(function __do() {
                v5.value1(function (v7) {
                    return false;
                })();
                return v2.value1(function (v7) {
                    return true;
                })();
            }),
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconEditV1.value),
            text: "Muokkaa",
            align: TacoButton_Types.Left.value
        });
        var downloadButton = TacoButton.component()()({
            text: "Lataa",
            title: "Lataa piirustus omalle tietokoneelle.",
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconFileDownloadV1.value),
            align: TacoButton_Types.Left.value,
            onClick: Data_Maybe.Just.create(Effect_Aff.launchAff_(Control_Bind.bind(Effect_Aff.bindAff)(RequestUtils.mkHeaders)(function (headers) {
                return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.fetch()(Milkis.URL("/api/project/" + (Data_Show.show(Types_Project.showProjectId)(v.projectId) + ("/dixon/files/" + (Data_Show.show(Data_Show.showInt)(v.file.id) + "/url")))))({
                    method: Milkis.getMethod,
                    headers: headers
                })))(function (response) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)((function () {
                        if (response instanceof Data_Either.Right) {
                            return Control_Bind.bind(Effect_Aff.bindAff)(Milkis.json(response.value0))(function (json) {
                                var url = Simple_JSON.read(Simple_JSON.readRecord()(Simple_JSON.readFieldsCons({
                                    reflectSymbol: function () {
                                        return "url";
                                    }
                                })(Simple_JSON.readString)(Simple_JSON.readFieldsNil)()()))(json);
                                if (url instanceof Data_Either.Right) {
                                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(DOMUtils.openInvoiceInNewTab(url.value0.url)("_blank")((function () {
                                        var $52 = Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
                                        return function ($53) {
                                            return $52(appDispatch($53));
                                        };
                                    })()))(function () {
                                        return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Unit.unit);
                                    });
                                };
                                return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Unit.unit);
                            });
                        };
                        if (response instanceof Data_Either.Left) {
                            return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Utils.printError(Effect_Aff.monadEffectAff)(response.value0))(function () {
                                return ClientLogger.logError(Effect_Aff.monadEffectAff)(response.value0);
                            });
                        };
                        throw new Error("Failed pattern match at DixonFileItem (line 206, column 13 - line 218, column 40): " + [ response.constructor.name ]);
                    })())(function () {
                        return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Unit.unit);
                    });
                });
            })))
        });
        var dixonDrawingItemProps = Data_Array.mapWithIndex(mkDixonDrawingItemProps)(v.file.drawings);
        var drawings = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "DixonDrawingItemContainer",
            css: DixonFileItem_Styles.dixonDrawingItemContainerStyles,
            children: Data_Functor.map(Data_Functor.functorArray)(DixonDrawingItem.component)(dixonDrawingItemProps)
        });
        var deleteButton = TacoButton.component()()({
            onClick: Data_Maybe.Just.create(function __do() {
                v5.value1(function (v7) {
                    return false;
                })();
                return v1.value1(function (v7) {
                    return true;
                })();
            }),
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconDelete24Px.value),
            text: "Poista",
            align: TacoButton_Types.Left.value
        });
        var checkbox = TacoCheckbox.component()()({
            isChecked: v.file.active,
            onToggle: TacoCheckbox.NoEvent.create(function () {
                return v.editor.toggle_file(v.file.id);
            }),
            labelText: Data_Maybe.Nothing.value,
            color: TacoConstants.PrimaryBlue.value,
            style: TacoCheckbox_Styles.RadioButton.value
        });
        var button = Box.box("ButtonWrapper")([ new Box.FlexGrow(1) ])(Control_Applicative.pure(Control_Applicative.applicativeArray)(TacoButton.component()()({
            text: v.file.name,
            onClick: Data_Maybe.Just.create(function () {
                return v.editor.toggle_file(v.file.id);
            }),
            align: TacoButton_Types.Left.value
        })));
        var archiveButton = TacoButton.component()()({
            text: (function () {
                if (v.file.archived) {
                    return "Palauta";
                };
                return "Arkistoi";
            })(),
            title: "- Piirustus siirret\xe4\xe4n arkistoon\x0a- Arkistoidun piirustuksen m\xe4\xe4r\xe4t eiv\xe4t en\xe4\xe4 siirry linkitetyille suoritteille ja rakenteille\x0a- Voit palauttaa piirustuksen arkistosta",
            icon: new Data_Maybe.Just((function () {
                if (v.file.archived) {
                    return TacoIcon_Icons_Types.IconUnarchive.value;
                };
                return TacoIcon_Icons_Types.IconArchive.value;
            })()),
            onClick: Data_Maybe.Just.create(function __do() {
                v5.value1(function (v7) {
                    return false;
                })();
                return v.editor.toggle_file_archived(v.file.id);
            }),
            align: TacoButton_Types.Left.value
        });
        var activeDrawingScaleModalActions = [ TacoButton.component()()({
            onClick: Data_Maybe.Just.create(v1.value1(function (v7) {
                return false;
            })),
            text: "Peruuta"
        }), TacoButton.component()()({
            onClick: Data_Maybe.Just.create(function __do() {
                v.editor.remove_file(v.file.id);
                return v1.value1(function (v7) {
                    return false;
                })();
            }),
            buttonStyle: TacoButton_Types.Filled.value,
            color: TacoConstants.SecondaryRed.value,
            text: "Poista",
            title: "Kaikki tiedoston piirustukset ja mittaukset poistetaan pysyv\xe4sti."
        }) ];
        var deleteModal = TacoModal.component()()({
            onClose: v1.value1(function (v7) {
                return false;
            }),
            isActive: v1.value0,
            heading: "Oletko varma?",
            contents: [ TacoModal.modalBody({
                contents: [ React_Basic_DOM_Generated.div_([ React_Basic_DOM.text("Haluatko varmasti poistaa tiedoston "), React_Basic_DOM_Generated.var_([ React_Basic_DOM.text(v.file.name + "?") ]) ]), React_Basic_DOM_Generated.div_([ React_Basic_DOM.text("HUOM: T\xe4m\xe4 poistaa kaikki tiedoston alla olevat mittaukset!") ]) ]
            }), TacoModal.modalActions({
                contents: activeDrawingScaleModalActions
            }) ]
        });
        var actionsDropdownBtn = TacoButton.component()()({
            onClickEventHandler: Data_Maybe.Just.create(React_Basic_Events.handler(React_Basic_DOM_Events.currentTarget)(function (target) {
                return function __do() {
                    v5.value1(function (s) {
                        return !s;
                    })();
                    return v6.value1(function (v7) {
                        return Data_Maybe.Just.create(HtmlUtils.coerceEventTargetToHTMLElement(target));
                    })();
                };
            })),
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconMenuV2.value)
        });
        var actionsDropdown = TacoDropdown.component({
            sections: [ {
                title: Data_Maybe.Nothing.value,
                items: [ editButton, downloadButton, updateButton, archiveButton, deleteButton ]
            } ],
            toggled: v5.value0,
            setToggled: v5.value1,
            anchorEl: v6.value0,
            align: TacoDropdown_Types.AlignLeft.value
        });
        return React_Basic.fragment([ React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "DixonFileItem",
            css: DixonFileItem_Styles.dixonFileItemContainerStyles({
                highlighted: highlighted
            }),
            children: [ checkbox, button, actionsDropdownBtn ]
        }), Data_Monoid.guard(React_Basic.monoidJSX)(v.file.active)(drawings), Data_Monoid.guard(React_Basic.monoidJSX)(v2.value0)(editModal), Data_Monoid.guard(React_Basic.monoidJSX)(v1.value0)(deleteModal), actionsDropdown ]);
    };
});
module.exports = {
    component: component
};
