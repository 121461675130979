import {
  TargetAssessment,
  TargetAssessmentLetter,
} from "@tocoman/adminet-openapi-client";
import { InvalidTargetAmountCause } from "./ToInvalidTargetAmountCauseMapper";

export const mapMissingCostGroupCodesFromTargetAmount = (
  targetAmount: TargetAssessment,
  bareCostGroups: Array<TargetAssessmentLetter>
): Array<string | undefined> => {
  const existingAdminetCostGroupCodes = bareCostGroups.map(
    (bareCostGroup) => bareCostGroup.letterCode
  );

  if (targetAmount.letters === undefined) {
    return [];
  }
  return targetAmount.letters
    .map((costGroup) => costGroup.letterCode)
    .filter(
      (letterCode) => !existingAdminetCostGroupCodes.includes(letterCode)
    );
};

export const tryMapInvalidCostGroupCodesFromTransferCalculationError = (
  transferCalculationsErrors: unknown
): string[] => {
  if (!transferCalculationsErrors) {
    return [];
  }
  const invalidTargetAmountCause = transferCalculationsErrors as InvalidTargetAmountCause;
  return invalidTargetAmountCause.invalidCostGroupCodes ?? [];
};
