// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var Record = require("../Record/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoModal = require("../TacoModal/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var defaultProps = {
    onAction: Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit),
    onCancel: Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit),
    confirmHeadingText: "",
    confirmBody: React_Basic.empty,
    buttonText: "",
    confirmButtonText: "",
    testIdPrefix: "",
    disabled: false
};
var component = function (dictUnion) {
    return function (dictNub) {
        return TofuHooks.mkHookComponent("buttonWithConfirm")(function (props$prime) {
            var v = Record.merge()()(props$prime)(defaultProps);
            return function __do() {
                var v1 = TofuHooks.useState(false)();
                var openModal = v1.value1(function (v2) {
                    return true;
                });
                var modalBody = TacoModal.modalBody({
                    contents: [ v.confirmBody ]
                });
                var closeModal = v1.value1(function (v2) {
                    return false;
                });
                var modalActions = TacoModal.modalActions({
                    contents: [ TacoButton.component()()({
                        onClick: Data_Maybe.Just.create(closeModal),
                        text: "Peruuta",
                        testId: v.testIdPrefix + "-cancel"
                    }), TacoButton.component()()({
                        onClick: Data_Maybe.Just.create(function __do() {
                            v.onAction();
                            return closeModal();
                        }),
                        buttonStyle: TacoButton_Types.Filled.value,
                        color: TacoConstants.SecondaryRed.value,
                        text: v.confirmButtonText,
                        testId: v.testIdPrefix + "-confirm"
                    }) ]
                });
                return Box.box("confirmationButtonWrapper")([ new Box.Display(Box.Inline.value) ])([ TacoButton.component()()({
                    onClick: Data_Maybe.Just.create(openModal),
                    buttonStyle: TacoButton_Types.Outlined.value,
                    color: TacoConstants.SecondaryRed.value,
                    text: v.buttonText,
                    testId: v.testIdPrefix + "-button",
                    disabled: v.disabled
                }), TacoModal.component()()({
                    onClose: closeModal,
                    isActive: v1.value0,
                    heading: v.confirmHeadingText,
                    contents: [ modalBody, modalActions ]
                }) ]);
            };
        });
    };
};
module.exports = {
    component: component
};
