// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Data_Either = require("../Data.Either/index.js");
var Data_String_Regex = require("../Data.String.Regex/index.js");
var Data_String_Regex_Flags = require("../Data.String.Regex.Flags/index.js");
var Foreign = require("../Foreign/index.js");
var hasOnlyValidCostAmountChars = (function () {
    var v = Data_String_Regex.regex("^-?[0-9]*[,.]{0,1}[0-9]*$")(Data_String_Regex_Flags.noFlags);
    if (v instanceof Data_Either.Left) {
        return function (v1) {
            return false;
        };
    };
    if (v instanceof Data_Either.Right) {
        return Data_String_Regex.test(v.value0);
    };
    throw new Error("Failed pattern match at FormattingHelpers (line 28, column 31 - line 30, column 22): " + [ v.constructor.name ]);
})();
var formatPercentageDifferenceValueJSX = function (v) {
    return $foreign.formatHelper.formatPercentageDifferenceValueJSX2(v.value, v.precision);
};
var formatCurrencyDifferenceValue = function (n) {
    return $foreign.formatHelper.formatCurrencyDifferenceValue(Foreign.unsafeToForeign(n));
};
var formatCurrencyDifferenceAndPercentageValue = function (r) {
    return $foreign.formatHelper.formatCurrencyDifferenceAndPercentageValue(Foreign.unsafeToForeign(r));
};
module.exports = {
    hasOnlyValidCostAmountChars: hasOnlyValidCostAmountChars,
    formatCurrencyDifferenceValue: formatCurrencyDifferenceValue,
    formatPercentageDifferenceValueJSX: formatPercentageDifferenceValueJSX,
    formatCurrencyDifferenceAndPercentageValue: formatCurrencyDifferenceAndPercentageValue,
    formatHelper: $foreign.formatHelper
};
