// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Client_WASM = require("../Client.WASM/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var DixonContainer_Utils = require("../DixonContainer.Utils/index.js");
var DixonDrawingItem_Styles = require("../DixonDrawingItem.Styles/index.js");
var QuantityFormula_Interpreter = require("../QuantityFormula.Interpreter/index.js");
var QuantityFormula_Parser = require("../QuantityFormula.Parser/index.js");
var QuantityFormula_Types = require("../QuantityFormula.Types/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var TacoAutocomplete = require("../TacoAutocomplete/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoCheckbox_Styles = require("../TacoCheckbox.Styles/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoModal = require("../TacoModal/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var UseUpdatingState = require("../UseUpdatingState/index.js");
var Wasm_FFI = require("../Wasm.FFI/index.js");
var component = TofuHooks.mkHookComponent("DixonDrawingItem")(function (v) {
    return function __do() {
        var v1 = Client_WASM.useProjectLocations(v.projectId)();
        var v2 = TofuHooks.useState(false)();
        var v3 = UseUpdatingState.useUpdatingState(Data_Eq.eqString)(v.drawing.name)();
        var v4 = UseUpdatingState.useUpdatingState(Data_Eq.eqString)(v.drawing.default_location)();
        var v5 = UseUpdatingState.useUpdatingState(Data_Eq.eqString)(DixonContainer_Utils.toUserFormulaStr(v.drawing.default_formula))();
        var toggleDrawing = function () {
            return v.editor.toggle_drawing(v.file.id, v.drawing.id);
        };
        var settingsModalButton = TacoButton.component()()({
            onClick: Data_Maybe.Just.create(v2.value1(function (v6) {
                return true;
            })),
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconMenuV2.value)
        });
        var settingsModalActions = [ TacoButton.component()()({
            onClick: Data_Maybe.Just.create(v2.value1(function (v6) {
                return false;
            })),
            text: "Peruuta"
        }), TacoButton.component()()({
            onClick: Data_Maybe.Just.create(function __do() {
                v.editor.set_drawing_options(v.file.id, v.drawing.id, v3.value0, v4.value0, DixonContainer_Utils.toInternalFormulaStr(v5.value0));
                return v2.value1(function (v6) {
                    return false;
                })();
            }),
            buttonStyle: TacoButton_Types.Filled.value,
            color: TacoConstants.SecondaryRed.value,
            text: "Tallenna"
        }) ];
        var mkAutocompleteItem = {
            text: v4.value0,
            id: v4.value0
        };
        var locationSelectChoices = Data_Functor.mapFlipped(Data_Functor.functorArray)(v1.value0)(function (v6) {
            return {
                text: v6.code,
                id: v6.code
            };
        });
        var highlighted = Data_Array.elem(Data_Eq.eqInt)(v.drawing.id)(v.highlightedMGDrawingIds);
        var formulaValidator = function (formula) {
            var validatorContext = Wasm_FFI.dixonFormulaContext({
                numVertices: 0.0,
                perimeter: 0.0,
                area: 0.0,
                formula: v5.value0
            });
            var parsedFormula = QuantityFormula_Parser.convertToInternalFormula(formula);
            var v6 = QuantityFormula_Interpreter.evalQuantityFormula(validatorContext)(parsedFormula);
            if (v6 instanceof Data_Either.Right && v6.value0 instanceof QuantityFormula_Types.QNum) {
                return TacoInput.Valid.value;
            };
            if (v6 instanceof Data_Either.Right) {
                return new TacoInput.Invalid("");
            };
            if (v6 instanceof Data_Either.Left) {
                return new TacoInput.Invalid("");
            };
            throw new Error("Failed pattern match at DixonDrawingItem (line 106, column 10 - line 110, column 29): " + [ v6.constructor.name ]);
        };
        var valid = v5.value0 === "" || Data_Eq.eq(TacoInput.eqValueValidity)(formulaValidator(v5.value0))(TacoInput.Valid.value);
        var settingsModal = TacoModal.component()()({
            onClose: v2.value1(function (v6) {
                return false;
            }),
            isActive: v2.value0,
            heading: "Muokkaa piirustusta",
            contents: [ TacoModal.modalBody({
                contents: [ React_Basic_DOM_Generated.div_([ TacoInput.stringInput()()(Data_Eq.eqString)({
                    onChange: v3.value1,
                    value: v3.value0,
                    label: "Piirustuksen nimi"
                }) ]), React_Basic_DOM_Generated.div_([ TacoAutocomplete.component()()({
                    sections: [ {
                        text: Data_Maybe.Nothing.value,
                        list: locationSelectChoices
                    } ],
                    clearButton: true,
                    label: "Oletussijainti",
                    onSelect: function (item$prime) {
                        if (item$prime instanceof Data_Maybe.Just) {
                            return v4.value1(function (v6) {
                                return item$prime.value0.id;
                            });
                        };
                        if (item$prime instanceof Data_Maybe.Nothing) {
                            return v4.value1(function (v6) {
                                return "";
                            });
                        };
                        throw new Error("Failed pattern match at DixonDrawingItem (line 135, column 31 - line 138, column 71): " + [ item$prime.constructor.name ]);
                    },
                    selected: (function () {
                        var $29 = v4.value0 === "";
                        if ($29) {
                            return Data_Maybe.Nothing.value;
                        };
                        return Data_Maybe.Just.create(mkAutocompleteItem);
                    })(),
                    testId: "location-code-select"
                }) ]), React_Basic_DOM_Generated.div_([ TacoInput.stringInput()()(Data_Eq.eqString)({
                    onChange: v5.value1,
                    value: v5.value0,
                    label: "Oletuskaava",
                    error: !valid,
                    errorMessage: "Virheellinen kaava",
                    clearButton: true
                }) ]), Box.box("ModalPadding")([ Box.Height.create(new TacoConstants.CustomSize("16rem")) ])([  ]) ]
            }), TacoModal.modalActions({
                contents: settingsModalActions
            }) ]
        });
        var checkbox = TacoCheckbox.component()()({
            isChecked: v.drawing.active,
            onToggle: new TacoCheckbox.NoEvent(toggleDrawing),
            labelText: Data_Maybe.Nothing.value,
            style: TacoCheckbox_Styles.RadioButton.value,
            color: TacoConstants.PrimaryBlue.value
        });
        var button = Box.box("ButtonWrapper")([ new Box.FlexGrow(1) ])(Control_Applicative.pure(Control_Applicative.applicativeArray)(TacoButton.component()()({
            text: v.drawing.name,
            onClick: Data_Maybe.Just.create(toggleDrawing)
        })));
        return React_Basic.fragment([ React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "DixonDrawingItem",
            css: DixonDrawingItem_Styles.dixonDrawingItemContainerStyles({
                highlighted: highlighted
            }),
            children: [ checkbox, button, settingsModalButton ]
        }), Data_Monoid.guard(React_Basic.monoidJSX)(v2.value0)(settingsModal) ]);
    };
});
module.exports = {
    component: component
};
