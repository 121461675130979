import { createTable, Table } from "@tocoman/ui";
import { Project, ProjectType } from "@tocoman/adminet-openapi-client";
import { UseQueryResult } from "react-query";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

type AdminetProjectTableProps = {
  adminetProjects: UseQueryResult<Array<Project>>;
  value: string | undefined;
  onChange: (projectId: string | undefined) => void;
};

export const AdminetProjectTable = ({
  adminetProjects,
  value,
  onChange,
}: AdminetProjectTableProps) => {
  const { t } = useTranslation("integration");
  const adminetProjectsTable = createTable().setRowType<Project>();

  const mapProjectType = useCallback(
    (projectType?: ProjectType): string => {
      switch (projectType) {
        case ProjectType.NUMBER_0:
          return t`adminet.projectTypes.billedWork`;
        case ProjectType.NUMBER_1:
          return t`adminet.projectTypes.contractWork`;
        case ProjectType.NUMBER_2:
          return t`adminet.projectTypes.gigWork`;
        case ProjectType.NUMBER_3:
          return t`adminet.projectTypes.ownWork`;
        case ProjectType.NUMBER_4:
          return t`adminet.projectTypes.warrantyWork`;
        case ProjectType.NUMBER_5:
          return t`adminet.projectTypes.warehouseSales`;
        case ProjectType.NUMBER_6:
          return t`adminet.projectTypes.companySpecific`;
        default:
          return "";
      }
    },
    [t]
  );

  const adminetProjectColumns = useMemo(
    () => [
      adminetProjectsTable.createDataColumn("projectNumber", {
        header: t`adminet.getAdminetProjects.table.number`,
      }),
      adminetProjectsTable.createDataColumn("description", {
        header: t`adminet.getAdminetProjects.table.projectName`,
      }),
      adminetProjectsTable.createDataColumn("type", {
        header: t`adminet.getAdminetProjects.table.type`,
        cell: (props) => mapProjectType(props.value),
      }),
    ],
    [adminetProjectsTable, t, mapProjectType]
  );

  /**
   * Special handler for row selection, which enables the possibility to
   * select only one row, which isn't supported by the Tocoman UI-library
   * at this moment
   */
  const onSelectedRowsChange = (selectedRows: string[]): void => {
    if (selectedRows.length === 0) {
      onChange(undefined);
    } else {
      const newSelectedRow: string | undefined = selectedRows.find(
        (rowId) => value !== rowId
      );
      onChange(newSelectedRow);
    }
  };

  return (
    <Table
      className="h-128"
      table={adminetProjectsTable}
      data={adminetProjects.data ?? []}
      rowId={(row: Project) => String(row.id)}
      columns={adminetProjectColumns}
      selectedRows={value ? [value] : []}
      onSelectedRowsChange={onSelectedRowsChange}
    ></Table>
  );
};
