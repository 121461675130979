// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Actions = require("../Actions/index.js");
var AppState = require("../AppState/index.js");
var ChocoPie = require("../ChocoPie/index.js");
var Context = require("../Context/index.js");
var Control_Alt = require("../Control.Alt/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Class_Console = require("../Effect.Class.Console/index.js");
var FRP_Event = require("../FRP.Event/index.js");
var FRP_Event_Class = require("../FRP.Event.Class/index.js");
var FetchMiddleware = require("../FetchMiddleware/index.js");
var KeyboardMiddleware = require("../KeyboardMiddleware/index.js");
var MultipleErrorsPrinter = require("../MultipleErrorsPrinter/index.js");
var NotificationsMiddleware = require("../NotificationsMiddleware/index.js");
var React_Basic = require("../React.Basic/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var RouterMiddleware = require("../RouterMiddleware/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Utils = require("../Utils/index.js");
var WasmWorker_WorkerResponse = require("../WasmWorker.WorkerResponse/index.js");
var update = function (action) {
    return function (state) {
        return AppState.rootReducer.update(state)(action);
    };
};
var fold$prime = function (fn) {
    return function (event) {
        return function (init) {
            return Control_Alt.alt(FRP_Event.altEvent)(Control_Applicative.pure(FRP_Event.applicativeEvent)(init))(FRP_Event_Class.fold(FRP_Event.eventIsEvent)(fn)(event)(init));
        };
    };
};
var cacheLatestAppStateEvent = function (initialState) {
    return function (states) {
        return function __do() {
            $foreign.setAppState(initialState)();
            var unsubscribe = FRP_Event.subscribe(states)(function (state) {
                return function __do() {
                    $foreign.setAppState(state)();
                    return Data_Unit.unit;
                };
            })();
            return new Data_Tuple.Tuple($foreign.getAppState, unsubscribe);
        };
    };
};
var initApp = function (appComponent) {
    var viewDriver = function (dispatch) {
        return function (states) {
            return Data_Functor.voidRight(Effect.functorEffect)(Data_Monoid.mempty(FRP_Event.monoidEvent(Data_Monoid.monoidUnit)))(function __do() {
                var v = cacheLatestAppStateEvent(AppState.rootReducer.initialState)(states)();
                var monthlyReport = $foreign.getMonthlyReportComponent();
                var wasmWorkerEventHandler = function (event) {
                    $foreign.warnSize(ReactHooksUtils.utf(event.data))();
                    var v1 = TofuJSON.read(WasmWorker_WorkerResponse.tofuJSONWorkerResponse)(event.data);
                    if (v1 instanceof Data_Either.Right) {
                        dispatch(new Actions.WorkerResponseAction(v1.value0))();
                        if (v1.value0 instanceof WasmWorker_WorkerResponse.UiNotification) {
                            return dispatch(new Actions.AddAlert(v1.value0.value0.alert_level, v1.value0.value0.msg))();
                        };
                        return Data_Unit.unit;
                    };
                    if (v1 instanceof Data_Either.Left) {
                        var errors = "Deserializing wasmWorkerEventHandler failed" + ("\x0a" + MultipleErrorsPrinter.printMultipleErrors(v1.value0));
                        Utils.unsafeLog2("event.data")(event.data)();
                        return Effect_Class_Console.error(Effect_Class.monadEffectEffect)(errors)();
                    };
                    throw new Error("Failed pattern match at FrontEnd (line 91, column 11 - line 102, column 35): " + [ v1.constructor.name ]);
                };
                var wasmWorker = $foreign.initWasmWorker(wasmWorkerEventHandler)();
                var value = {
                    getState: v.value0,
                    subscribe: FRP_Event.subscribe(states),
                    dispatch: dispatch,
                    wasmWorker: wasmWorker
                };
                var provider = React_Basic.contextProvider(Context.context);
                var children = [ React_Basic.element(appComponent)({
                    monthlyReport: monthlyReport
                }) ];
                return $foreign.renderJSX(React_Basic.element(provider)({
                    value: value,
                    children: children
                }))();
            });
        };
    };
    return function __do() {
        var v = FRP_Event.create();
        $foreign.setDispatch(v.push)();
        var mkSink = function (v1) {
            var appState = fold$prime(update)(v.event)(AppState.rootReducer.initialState);
            return {
                view: appState,
                router: v.event,
                fetch: v.event,
                keyboard: v.event,
                notifications: v.event
            };
        };
        var drivers = {
            view: viewDriver(v.push),
            router: RouterMiddleware.routerDriver(v.push),
            fetch: FetchMiddleware.fetchDriver(v.push),
            keyboard: KeyboardMiddleware.keyboardDriver(v.push),
            notifications: NotificationsMiddleware.notificationsDriver(v.push)
        };
        Effect_Class.liftEffect(Effect_Class.monadEffectEffect)(ChocoPie.runChocoPie(ChocoPie.chocoPieRecord()()(ChocoPie.makeSinkProxiesCons({
            reflectSymbol: function () {
                return "fetch";
            }
        })(ChocoPie.makeSinkProxiesCons({
            reflectSymbol: function () {
                return "keyboard";
            }
        })(ChocoPie.makeSinkProxiesCons({
            reflectSymbol: function () {
                return "notifications";
            }
        })(ChocoPie.makeSinkProxiesCons({
            reflectSymbol: function () {
                return "router";
            }
        })(ChocoPie.makeSinkProxiesCons({
            reflectSymbol: function () {
                return "view";
            }
        })(ChocoPie.makeSinkProxiesNil)()())()())()())()())()())(ChocoPie.callDriversCons({
            reflectSymbol: function () {
                return "fetch";
            }
        })(ChocoPie.callDriversCons({
            reflectSymbol: function () {
                return "keyboard";
            }
        })(ChocoPie.callDriversCons({
            reflectSymbol: function () {
                return "notifications";
            }
        })(ChocoPie.callDriversCons({
            reflectSymbol: function () {
                return "router";
            }
        })(ChocoPie.callDriversCons({
            reflectSymbol: function () {
                return "view";
            }
        })(ChocoPie.callDriversNil)()()()())()()()())()()()())()()()())()()()())(ChocoPie.replicateManyCons({
            reflectSymbol: function () {
                return "fetch";
            }
        })()()(ChocoPie.replicateManyCons({
            reflectSymbol: function () {
                return "keyboard";
            }
        })()()(ChocoPie.replicateManyCons({
            reflectSymbol: function () {
                return "notifications";
            }
        })()()(ChocoPie.replicateManyCons({
            reflectSymbol: function () {
                return "router";
            }
        })()()(ChocoPie.replicateManyCons({
            reflectSymbol: function () {
                return "view";
            }
        })()()(ChocoPie.replicateManyNil)))))))(mkSink)(drivers))();
        return Effect_Class_Console.log(Effect_Class.monadEffectEffect)("Started application")();
    };
};
module.exports = {
    "fold'": fold$prime,
    update: update,
    initApp: initApp,
    cacheLatestAppStateEvent: cacheLatestAppStateEvent,
    getMonthlyReportComponent: $foreign.getMonthlyReportComponent,
    initWasmWorker: $foreign.initWasmWorker,
    warnSize: $foreign.warnSize,
    renderJSX: $foreign.renderJSX,
    setAppState: $foreign.setAppState,
    getAppState: $foreign.getAppState,
    setDispatch: $foreign.setDispatch
};
