// Generated by purs version 0.14.5
"use strict";
var ExpensesView = (function () {
    function ExpensesView() {

    };
    ExpensesView.value = new ExpensesView();
    return ExpensesView;
})();
var IncomeView = (function () {
    function IncomeView() {

    };
    IncomeView.value = new IncomeView();
    return IncomeView;
})();
module.exports = {
    ExpensesView: ExpensesView,
    IncomeView: IncomeView
};
