// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Client_WASM = require("../Client.WASM/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Hash = require("../Hash/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var UseFetch = require("../UseFetch/index.js");
var useReferenceElementMeasures = function (projectId) {
    return UseFetch.useFetchItemsByProjectId(projectId)(function (v) {
        return v.referenceElementMeasures;
    })(Actions.GetReferenceElementMeasuresRequest.create);
};
var useProjectList = UseFetch.useFetchItems(Data_Eq.eqUnit)(Data_Unit.unit)(function (v) {
    return v.projectList;
})(Actions.CostControlProjectsRequest.value);
var useProjectCostGroupClassificationInfos = function (projectId) {
    return function __do() {
        var v = UseFetch.useFetchValueByProjectId(projectId)(function (v) {
            return v.projectCostGroupClassificationInfo;
        })(Actions.GetProjectCostGroupClassificationInfoRequest.create)();
        return new Data_Tuple.Tuple(Data_Functor.map(Data_Maybe.functorMaybe)(function (v1) {
            return v1.items;
        })(v.value0), v.value1);
    };
};
var useProjectClassificationClasses = function (projectId) {
    return UseFetch.useFetchItemsByProjectId(projectId)(function (v) {
        return v.projectClassificationClasses;
    })(Actions.ProjectClassificationClassesRequest.create);
};
var useOrgPriceListTree = UseFetch.useFetchItems(Data_Eq.eqUnit)(Data_Unit.unit)(function (v) {
    return v.orgPriceListTree;
})(Actions.GetOrgPriceListTreeRequest.value);
var useOrgCostClasses = UseFetch.useFetchItems(Data_Eq.eqUnit)(Data_Unit.unit)(function (v) {
    return v.orgCostClass;
})(Actions.OrgCostClassRequest.value);
var useOfferPageParams = function (projectId) {
    return function __do() {
        var v = UseFetch.useFetchValueByProjectId(projectId)(function (v) {
            return v.offerPageParams;
        })(Actions.GetOfferPageParamsRequest.create)();
        return new Data_Tuple.Tuple(Data_Functor.map(Data_Maybe.functorMaybe)(function (v1) {
            return v1.items;
        })(v.value0), v.value1);
    };
};
var useImportSalesInvoices = UseFetch.useFetchItems(Data_Eq.eqUnit)(Data_Unit.unit)(function (v) {
    return v.importSalesInvoices;
})(Actions.GetImportSalesInvoicesRequest.value);
var useImportInvoices = UseFetch.useFetchItems(Data_Eq.eqUnit)(Data_Unit.unit)(function (v) {
    return v.importInvoices;
})(Actions.GetImportInvoicesRequest.value);
var useEstimationResourcePriceList = function (projectId) {
    return UseFetch.useFetchItemsByProjectId(projectId)(function (v) {
        return v.estimationResourcePriceList;
    })(Actions.GetEstimationResourcePriceListRequest.create);
};
var useElementMeasures = function (projectId) {
    return UseFetch.useFetchItemsByProjectId(projectId)(function (v) {
        return v.elementMeasures;
    })(Actions.GetElementMeasuresRequest.create);
};
var useCostClassesWithHash = function (projectId) {
    return function __do() {
        var v = Client_WASM.useCostClasses(projectId)();
        return TofuHooks.useMemo([ ReactHooksUtils.utf(v.value0) ])(function (v1) {
            var hash = Hash.sha1(v.value0);
            return new Data_Tuple.Tuple({
                value: v.value0,
                hash: hash
            }, v.value1);
        })();
    };
};
module.exports = {
    useImportInvoices: useImportInvoices,
    useImportSalesInvoices: useImportSalesInvoices,
    useProjectList: useProjectList,
    useCostClassesWithHash: useCostClassesWithHash,
    useElementMeasures: useElementMeasures,
    useReferenceElementMeasures: useReferenceElementMeasures,
    useEstimationResourcePriceList: useEstimationResourcePriceList,
    useOrgPriceListTree: useOrgPriceListTree,
    useOrgCostClasses: useOrgCostClasses,
    useProjectCostGroupClassificationInfos: useProjectCostGroupClassificationInfos,
    useProjectClassificationClasses: useProjectClassificationClasses,
    useOfferPageParams: useOfferPageParams
};
