// Generated by purs version 0.14.5
"use strict";
var Client_WASM = require("../Client.WASM/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var CostEstimation_Layout = require("../CostEstimation.Layout/index.js");
var CostEstimation_Styles = require("../CostEstimation.Styles/index.js");
var CostEstimationLayout_Styles = require("../CostEstimationLayout.Styles/index.js");
var CostEstimationLayout_Types = require("../CostEstimationLayout.Types/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Set = require("../Data.Set/index.js");
var Effect = require("../Effect/index.js");
var EstimationResourcesList = require("../EstimationResourcesList/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoTabs = require("../TacoTabs/index.js");
var TacoTabs_Types = require("../TacoTabs.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var component = TofuHooks.mkHookComponent("ResourcesTable")(function (props) {
    return function __do() {
        var v = Client_WASM.useCostClasses(props.projectId)();
        var v1 = TofuHooks.useState(Data_Set.empty)();
        TofuHooks.useEffect([ ReactHooksUtils.utf(v1.value0) ])((function () {
            if (props.setParentSelectedResourceIds instanceof Data_Maybe.Just) {
                return function __do() {
                    props.setParentSelectedResourceIds.value0(function (v2) {
                        return v1.value0;
                    })();
                    return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                };
            };
            if (props.setParentSelectedResourceIds instanceof Data_Maybe.Nothing) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)));
            };
            throw new Error("Failed pattern match at CostEstimation.BuildingElementsView.ResourcesTable (line 74, column 5 - line 78, column 29): " + [ props.setParentSelectedResourceIds.constructor.name ]);
        })())();
        var tabs = [ new TacoTabs_Types.NormalTab({
            id: "resources",
            title: "Panokset",
            quantity: Data_Maybe.Just.create(Data_Array.length(props.componentSummary.resourcesByComponent.resources)),
            children: [ EstimationResourcesList.estimationResourcesList({
                componentId: props.componentSummary.resourcesByComponent.component.id,
                componentAmount: props.componentSummary.amount,
                componentUnit: Data_Nullable.toMaybe(props.componentSummary.unit),
                componentGroupCode: Data_Nullable.toMaybe(props.componentSummary.group),
                resourcesForComponent: props.componentSummary.resourcesByComponent.resources,
                socialExpensePercentagesByCostClass: props.socialExpensePercentagesByCostClass,
                showSocialExpensesInCosts: props.showSocialExpensesInCosts,
                showWorkAchievement: props.showWorkAchievement,
                costClasses: v.value0,
                projectId: props.projectId,
                viewMode: props.viewMode,
                programType: props.programType,
                isReportingProject: props.isReportingProject,
                border: true,
                selectedResourceIds: v1.value0,
                setSelectedResourceIds: v1.value1,
                extraControls: props.extraResourceControls,
                componentSplitParams: props.componentSplitParams,
                projectCurrency: props.projectCurrency
            }) ],
            color: TacoConstants.resourceColors.border
        }), TacoTabs_Types.SeparatorTab.value, TacoTabs_Types.JsxTab.create(CostEstimation_Layout.panelModeButtons({
            panelMode: props.panelMode,
            setPanelMode: props.setPanelMode,
            vertical: false,
            smallMode: false,
            testId: "resources-panel-size"
        })) ];
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "__ResourcesTable",
            css: React_Basic_Emotion.merge([ CostEstimation_Styles.costEstimationDetailsContainerStyles({
                panelMode: props.panelMode
            }), CostEstimationLayout_Styles.detailsStyles ]),
            children: [ TacoTabs.component({
                orientation: TacoTabs_Types.Horizontal.value,
                tabs: tabs,
                hideContents: Data_Eq.eq(CostEstimationLayout_Types.eqPanelMode)(props.panelMode)(CostEstimationLayout_Types.MinimizedMode.value)
            }) ]
        });
    };
});
module.exports = {
    component: component
};
