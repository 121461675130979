// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Controls = require("../Controls/index.js");
var CostEstimation_Filters = require("../CostEstimation.Filters/index.js");
var CostEstimation_Layout = require("../CostEstimation.Layout/index.js");
var CostEstimation_SummariesPanel = require("../CostEstimation.SummariesPanel/index.js");
var CostEstimation_SummariesSidebar = require("../CostEstimation.SummariesSidebar/index.js");
var CostEstimationLayout_Types = require("../CostEstimationLayout.Types/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Array_NonEmpty_Internal = require("../Data.Array.NonEmpty.Internal/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var EditResourcePriceModal = require("../EditResourcePriceModal/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var EstimationComponentFilter = require("../EstimationComponentFilter/index.js");
var EstimationUtils = require("../EstimationUtils/index.js");
var FetchHooks = require("../FetchHooks/index.js");
var FetchMiddleware = require("../FetchMiddleware/index.js");
var FileUpload = require("../FileUpload/index.js");
var Gap = require("../Gap/index.js");
var LengthValidator = require("../LengthValidator/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var ResourcePriceListView_PriceListTable = require("../ResourcePriceListView.PriceListTable/index.js");
var SocialExpensesControls = require("../SocialExpensesControls/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoColumnSelector = require("../TacoColumnSelector/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoDropdown = require("../TacoDropdown/index.js");
var TacoDropdown_Types = require("../TacoDropdown.Types/index.js");
var TacoFilter = require("../TacoFilter/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoList = require("../TacoList/index.js");
var TacoLoader = require("../TacoLoader/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoSelect = require("../TacoSelect/index.js");
var TacoTable_Header = require("../TacoTable.Header/index.js");
var TacoTable_Row = require("../TacoTable.Row/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_Alert = require("../Types.Alert/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_CostEstimation = require("../Types.CostEstimation/index.js");
var Types_Estimation = require("../Types.Estimation/index.js");
var Types_UploadStatus = require("../Types.UploadStatus/index.js");
var UseEstimationUiState = require("../UseEstimationUiState/index.js");
var UseLocalStorage = require("../UseLocalStorage/index.js");
var UseNullableRef = require("../UseNullableRef/index.js");
var UseSocialExpensesInCosts = require("../UseSocialExpensesInCosts/index.js");
var Utils = require("../Utils/index.js");
var rowHeight = TacoTable_Types.RowMedium.value;
var mkResourcePriceListUploadControlWithOptionalValidation = function (props) {
    return TofuHooks.mkHookComponent(props.componentName)(function (v) {
        return function __do() {
            var v1 = TofuHooks.useState(Types_UploadStatus.Standby.value)();
            var v2 = TofuHooks.useState(Data_Maybe.Nothing.value)();
            var v3 = TofuHooks.useState(true)();
            var appDispatch = StateHooks.useDispatch();
            var id = props.id + "-input";
            var uploadOrgResourcesInput = FileUpload.mkFileUpload({
                projectId: new Data_Maybe.Just(v.projectId),
                uploadType: props.uploadType,
                endpointUrl: (function () {
                    if (v3.value0) {
                        return props.endpointUrl;
                    };
                    return props.endpointUrl + "/noUniquenessValidation";
                })(),
                uniqueId: id,
                uploadStatus: v1.value0,
                setUploadStatus: v1.value1,
                filename: v2.value0,
                setFilename: v2.value1,
                onSuccess: Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(props.onSuccess(appDispatch)(v.projectId))
            });
            var uploadOrgResourcesLabel = FileUpload.uploadFileLabel({
                filename: v2.value0,
                className: "upload-invoices",
                uniqueId: id,
                label: "Tuo excelist\xe4"
            });
            var enableValidationCheckbox = TacoCheckbox.component()()({
                className: "pricelist-item-uniqueness-validation",
                isChecked: v3.value0,
                onToggle: TacoCheckbox.NoEvent.create(v3.value1(function (current) {
                    return !current;
                })),
                labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Varmista PL + Pryhm\xe4 + J\xe4rjestys -yhdistelm\xe4n uniikkius")),
                testId: "pricelist-item-uniqueness-validation-test"
            });
            return React_Basic_DOM_Generated.div()({
                className: "flex-centered",
                children: [ uploadOrgResourcesInput, uploadOrgResourcesLabel, enableValidationCheckbox, FileUpload.uploadStatusToJSX(v1.value0) ]
            });
        };
    });
};
var uploadOrgResourcesControl = mkResourcePriceListUploadControlWithOptionalValidation({
    componentName: "UploadOrgResourcesControl",
    id: "org-resources-upload",
    uploadType: FileUpload.OrgResourcesUpload.value,
    endpointUrl: "/uploadOrgResources",
    onSuccess: function (dispatch) {
        return function (projectId) {
            return function __do() {
                dispatch(new Actions.GetEstimationResourcesRequest({
                    projectId: projectId
                }))();
                dispatch(new Actions.GetEstimationResourcePriceListRequest({
                    projectId: projectId
                }))();
                return FetchMiddleware["refreshEstimationProjectsData'"](dispatch)(projectId)();
            };
        };
    }
});
var mkResourcePriceListUploadControl = function (props) {
    return TofuHooks.mkHookComponent(props.componentName)(function (v) {
        return function __do() {
            var v1 = TofuHooks.useState(Types_UploadStatus.Standby.value)();
            var v2 = TofuHooks.useState(Data_Maybe.Nothing.value)();
            var appDispatch = StateHooks.useDispatch();
            var id = props.id + "-input";
            var uploadOrgResourcesInput = FileUpload.mkFileUpload({
                projectId: new Data_Maybe.Just(v.projectId),
                uploadType: props.uploadType,
                endpointUrl: props.endpointUrl,
                uniqueId: id,
                uploadStatus: v1.value0,
                setUploadStatus: v1.value1,
                filename: v2.value0,
                setFilename: v2.value1,
                onSuccess: Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(props.onSuccess(appDispatch)(v.projectId))
            });
            var uploadOrgResourcesLabel = FileUpload.uploadFileLabel({
                filename: v2.value0,
                className: "upload-invoices",
                uniqueId: id,
                label: "Tuo excelist\xe4"
            });
            return React_Basic_DOM_Generated.div()({
                className: "flex-centered",
                children: [ uploadOrgResourcesInput, uploadOrgResourcesLabel, FileUpload.uploadStatusToJSX(v1.value0) ]
            });
        };
    });
};
var uploadOrgResourceSubGroupingControl = mkResourcePriceListUploadControl({
    componentName: "UploadOrgResourceSubGroupingControl",
    id: "org-resourceSubGrouping-upload",
    uploadType: FileUpload.OrgResourceSubGroupingUpload.value,
    endpointUrl: "/uploadOrgResourceSubGroupings",
    onSuccess: function (dispatch) {
        return function (projectId) {
            return function __do() {
                dispatch(Actions.GetOrgPriceListTreeRequest.value)();
                return FetchMiddleware["refreshEstimationProjectsData'"](dispatch)(projectId)();
            };
        };
    }
});
var mkOrgResourceSubGroupingNode = function (addFilter) {
    return function (isFirstLevel) {
        return function (subgroupings) {
            return function (r) {
                var matchingSubGroupings = Data_Array.filter(function (x) {
                    return Data_Eq.eq(Data_Nullable.eqNullable(Data_Eq.eqString))(x.orgResourceSubGroupingParentCode)(Utils.pureNullable(r.code));
                })(subgroupings);
                var title = r.code + (". " + r.description);
                return {
                    title: title,
                    titleJSX: Data_Maybe.Nothing.value,
                    id: title,
                    value: new TacoList.TextValue(""),
                    onClick: Data_Maybe.Just.create(addFilter(r.orgPriceListId)(r.orgResourceGroupingId)(r.code)),
                    disabled: false,
                    getItems: function (v) {
                        var getCode = function (x) {
                            return x.orgResourceSubGroupingId;
                        };
                        return Data_Functor.map(Data_Functor.functorArray)((function () {
                            var $222 = mkOrgResourceSubGroupingNode(addFilter)(false)(subgroupings);
                            return function ($223) {
                                return TacoList.ExpandableItemWrapper($222($223));
                            };
                        })())(Data_Array.sortWith(Data_Ord.ordInt)(getCode)(matchingSubGroupings));
                    },
                    hasItems: Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Array["null"])(matchingSubGroupings),
                    initExpanded: isFirstLevel
                };
            };
        };
    };
};
var mkOrgResourceGroupingItem = function (addFilter) {
    return function (v) {
        var matchingSubGroupings = Data_Array.filter(function (x) {
            return Data_Eq.eq(Data_Nullable.eqNullable(Data_Eq.eqString))(x.orgResourceGroupingId)(Utils.pureNullable(v.orgResourceGrouping.orgResourceGroupingId));
        })(v.subgroupings);
        var topLevelSubgroupings = Data_Array.filter(function (x) {
            return Utils.isNull(x.orgResourceSubGroupingParentCode);
        })(matchingSubGroupings);
        var title = v.orgResourceGrouping.orgResourceGroupingId + (". " + v.orgResourceGrouping.description);
        return {
            title: title,
            titleJSX: Data_Maybe.Nothing.value,
            id: title,
            value: new TacoList.TextValue(""),
            onClick: Data_Maybe.Nothing.value,
            disabled: false,
            getItems: function (v1) {
                var getCode = function (x) {
                    return x.orgResourceGroupingId;
                };
                return Data_Functor.map(Data_Functor.functorArray)((function () {
                    var $224 = mkOrgResourceSubGroupingNode(addFilter)(true)(matchingSubGroupings);
                    return function ($225) {
                        return TacoList.ExpandableItemWrapper($224($225));
                    };
                })())(Data_Array.sortWith(Data_Nullable.ordNullable(Data_Ord.ordString))(getCode)(topLevelSubgroupings));
            },
            hasItems: Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Array["null"])(topLevelSubgroupings),
            initExpanded: true
        };
    };
};
var mkOrgPriceListTreeItem = function (addFilter) {
    return function (setShowPriceListEditModal) {
        return function (v) {
            var title = Data_Newtype.unwrap()(v.orgPriceList.orgPriceListId) + (". " + v.orgPriceList.name);
            return {
                title: title,
                titleJSX: Data_Maybe.Nothing.value,
                id: title,
                value: TacoList.JSXValue.create(TacoButton.component()()({
                    buttonStyle: TacoButton_Types.Link.value,
                    text: "Muokkaa",
                    onClick: Data_Maybe.Just.create(setShowPriceListEditModal(function (v1) {
                        return new Data_Maybe.Just(v.orgPriceList.orgPriceListId);
                    }))
                })),
                onClick: Data_Maybe.Nothing.value,
                initExpanded: true,
                disabled: false,
                getItems: function (v1) {
                    var getCode = function (x) {
                        return x.orgResourceGrouping.orgResourceGroupingId;
                    };
                    return Data_Functor.map(Data_Functor.functorArray)((function () {
                        var $226 = mkOrgResourceGroupingItem(addFilter);
                        return function ($227) {
                            return TacoList.ExpandableItemWrapper($226($227));
                        };
                    })())(Data_Array.sortWith(Data_Ord.ordString)(getCode)(v.groupings));
                },
                hasItems: Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Array["null"])(v.groupings)
            };
        };
    };
};
var mkCostClassesByClassification = function (xs) {
    var match = function (key) {
        return function (r) {
            return r.orgClassificationId === key;
        };
    };
    var mkItem = function (key) {
        return {
            orgClassificationId: key,
            orgCostClasses: Data_Array.filter(match(key))(xs)
        };
    };
    var keys = Data_Array.nub(Data_Ord.ordString)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
        return v.orgClassificationId;
    })(xs));
    return Data_Functor.map(Data_Functor.functorArray)(mkItem)(keys);
};
var mkCol = function (spec) {
    return {
        key: spec.key,
        width: spec.width,
        label: spec.label,
        cell: spec.cell,
        sortProperty: TacoTable_Types.noSortProperty,
        headerJSX: Data_Maybe.Nothing.value
    };
};
var mkEditGroupingAssociationModalColumns = function (dispatch) {
    return function (projectId) {
        return function (classificationCostClasses) {
            var mkDropdown = function (v) {
                return {
                    key: v.orgClassificationId,
                    width: new TacoTable_Types.FlexMinWidth(1, "8rem"),
                    label: React_Basic_DOM.text(v.orgClassificationId),
                    cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (r) {
                        var orgCostClassIds = Data_Functor.map(Data_Functor.functorArray)(function (v1) {
                            return v1.orgCostClassId;
                        })(v.orgCostClasses);
                        var mkItem = function (t) {
                            return {
                                text: t.code + (": " + t.description),
                                value: Data_Show.show(Data_Show.showInt)(t.orgCostClassId)
                            };
                        };
                        var hasMatchingCostClassId = function (t) {
                            return Data_Array.elem(Data_Eq.eqInt)(t.orgCostClassId)(orgCostClassIds);
                        };
                        var match = Data_Array.find(hasMatchingCostClassId)(r.associatedOrgCostClassIds);
                        var value = Data_Maybe.maybe("")((function () {
                            var $228 = Data_Show.show(Data_Show.showInt);
                            return function ($229) {
                                return $228((function (v1) {
                                    return v1.orgCostClassId;
                                })($229));
                            };
                        })())(match);
                        var emptyItem = {
                            text: "",
                            value: ""
                        };
                        var items = Data_Semigroup.append(Data_Semigroup.semigroupArray)([ emptyItem ])(Data_Functor.map(Data_Functor.functorArray)(mkItem)(v.orgCostClasses));
                        return TacoSelect.component()()({
                            items: items,
                            value: value,
                            onChange: function (fn) {
                                var $$new = fn(value);
                                var mkAction = function (orgCostClassId) {
                                    if (match instanceof Data_Maybe.Nothing) {
                                        return new Types_Estimation.AddOrgResourceGroupingClassification({
                                            orgResourceGroupingId: r.orgResourceGroupingId,
                                            orgPriceListId: r.orgPriceListId,
                                            orgCostClassId: orgCostClassId,
                                            orgClassificationId: v.orgClassificationId
                                        });
                                    };
                                    if (match instanceof Data_Maybe.Just) {
                                        return new Types_Estimation.EditOrgResourceGroupingClassification({
                                            orgResourceGroupingId: r.orgResourceGroupingId,
                                            orgPriceListId: r.orgPriceListId,
                                            orgResourceGroupingAssociationId: match.value0.id,
                                            orgClassificationId: v.orgClassificationId,
                                            newOrgCostClassId: orgCostClassId
                                        });
                                    };
                                    throw new Error("Failed pattern match at ResourcePriceListView (line 715, column 21 - line 728, column 26): " + [ match.constructor.name ]);
                                };
                                var v1 = Data_Int.fromString($$new);
                                if (v1 instanceof Data_Maybe.Nothing) {
                                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                                };
                                if (v1 instanceof Data_Maybe.Just) {
                                    return dispatch(new Actions.EstimationResourcePriceListActionRequest({
                                        handler: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit))),
                                        params: mkAction(v1.value0),
                                        projectId: projectId
                                    }));
                                };
                                throw new Error("Failed pattern match at ResourcePriceListView (line 729, column 17 - line 736, column 24): " + [ v1.constructor.name ]);
                            }
                        });
                    })
                };
            };
            return Data_Functor.map(Data_Functor.functorArray)(mkCol)(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ {
                key: "priceListId",
                width: new TacoTable_Types.Fixed("8rem"),
                label: React_Basic_DOM.text("Hinnasto"),
                cell: new TacoTable_Types.PlainTextCell(function (r) {
                    return Data_Newtype.unwrap()(r.orgPriceListId);
                })
            }, {
                key: "id",
                width: new TacoTable_Types.Fixed("8rem"),
                label: React_Basic_DOM.text("Tunnus"),
                cell: new TacoTable_Types.PlainTextCell(function (r) {
                    return r.orgResourceGroupingId;
                })
            }, {
                key: "description",
                width: new TacoTable_Types.Fixed("8rem"),
                label: React_Basic_DOM.text("Selite"),
                cell: new TacoTable_Types.PlainTextCell(function (r) {
                    return r.description;
                })
            } ])(Data_Functor.map(Data_Functor.functorArray)(mkDropdown)(classificationCostClasses)));
        };
    };
};
var exportExcelExportButton = function (props) {
    var button = TacoButton.component()()({
        onClick: Data_Maybe.Just.create(props.onClick),
        text: props.text,
        icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconFileDownloadV1.value),
        align: TacoButton_Types.Left.value
    });
    return button;
};
var editGroupingAssociationRowStyleSet = (function () {
    var rowStyles = function (foldingState) {
        return function (rowHeight$prime) {
            return React_Basic_Emotion.merge([ TacoTable_Styles.defaultRowStyles(foldingState)(rowHeight$prime), Box.boxStyle([ Box.Width.create(new TacoConstants.CustomSize("100%")) ]) ]);
        };
    };
    return {
        rowStyles: rowStyles,
        rowCellsContainerStyles: TacoTable_Styles.defaultRowStyleSet.rowCellsContainerStyles,
        cellStyleSet: TacoTable_Styles.defaultRowStyleSet.cellStyleSet
    };
})();
var editGroupingAssociationHeaderStyleSet = (function () {
    var headerStyles = Box.boxStyle([ Box.Width.create(new TacoConstants.CustomSize("100%")) ]);
    return {
        headerStyles: React_Basic_Emotion.merge([ TacoTable_Styles.defaultHeaderStyles, headerStyles ]),
        headerCellStyles: TacoTable_Styles.defaultHeaderStyleSet.headerCellStyles
    };
})();
var editGroupingAssociationModal = TofuHooks.mkHookComponent("EditGroupingAssociationModal")(function (props) {
    return function __do() {
        var dispatch = StateHooks.useDispatch();
        var v = TofuHooks.useState("")();
        var v1 = TofuHooks.useState("")();
        var v2 = TofuHooks.useMemo([ ReactHooksUtils.utf(props.orgPriceListTree) ])(function (v2) {
            return Data_Array.sortWith(Data_Ord.ordString)(function (v3) {
                return v3.orgResourceGroupingId;
            })(Data_Functor.map(Data_Functor.functorArray)(function (v3) {
                return v3.orgResourceGrouping;
            })(props.orgPriceListTree.groupings));
        })();
        var costClassesByClassification = TofuHooks.useMemo([ ReactHooksUtils.utf(props.orgCostClasses) ])(function (v3) {
            return mkCostClassesByClassification(props.orgCostClasses);
        })();
        var v3 = TofuHooks.useMemo([ ReactHooksUtils.utf(props.orgPriceListTree), ReactHooksUtils.utf(props.allResourcePrices) ])(function (v3) {
            var matchPriceListId = function (x) {
                return Data_Eq.eq(Types_Component.eqOrgEstimationResourceId)(x.priceListId)(props.orgPriceListTree.orgPriceList.orgPriceListId);
            };
            return Data_Array.filter(matchPriceListId)(props.allResourcePrices);
        })();
        UseNullableRef.useNullableRef();
        var orgResourcesExcelButtons = Box.box("__OrgResourcesExcelButtons")([ new Box.Width(new TacoConstants.CustomSize("80rem")) ])([ exportExcelExportButton({
            onClick: dispatch(new Actions.GetOrgResourceExcelExportRequest(v3)),
            text: "Vie exceliin"
        }), uploadOrgResourcesControl({
            projectId: props.projectId
        }) ]);
        var onClose = props.setShowPriceListEditModal(function (v4) {
            return Data_Maybe.Nothing.value;
        });
        var modalActions = TacoModal.modalActions({
            contents: [ TacoButton.component()()({
                onClick: new Data_Maybe.Just(onClose),
                text: "Peruuta"
            }) ]
        });
        var mkExcelImportOrgResourceSubGrouping = function (groupings) {
            var mkItem = function (v4) {
                return function (r) {
                    return {
                        orgPriceListId: r.orgPriceListId,
                        orgResourceGroupingId: Utils.defaultNull("")(r.orgResourceGroupingId),
                        code: r.code,
                        description: r.description,
                        orgResourceSubGroupingParentCode: Data_Nullable.toMaybe(r.orgResourceSubGroupingParentCode)
                    };
                };
            };
            var mkItems = function (v4) {
                return Data_Functor.map(Data_Functor.functorArray)(mkItem(v4.orgResourceGrouping))(v4.subgroupings);
            };
            return Control_Bind.bindFlipped(Control_Bind.bindArray)(mkItems)(groupings);
        };
        var subGroupExcelButtons = Box.box("__SubGroupExcelButtons")([ new Box.Width(new TacoConstants.CustomSize("40rem")) ])([ exportExcelExportButton({
            onClick: dispatch(Actions.GetOrgResourceSubGroupingExcelExportRequest.create(mkExcelImportOrgResourceSubGrouping(props.orgPriceListTree.groupings))),
            text: "Vie exceliin"
        }), uploadOrgResourceSubGroupingControl({
            projectId: props.projectId
        }) ]);
        var mkAction = function (handler) {
            return function (params) {
                return new Actions.EstimationResourcePriceListActionRequest({
                    projectId: props.projectId,
                    handler: handler,
                    params: params
                });
            };
        };
        var editPriceListFields = Gap.gapX(TacoConstants.M.value)([ TacoInput.remoteStringField()()({
            value: Data_Newtype.unwrap()(props.orgPriceListTree.orgPriceList.orgPriceListId),
            label: "Tunnus",
            validator: LengthValidator.lengthValidator(1)(7),
            mkAction: function (newId) {
                return function (handler) {
                    var handler$prime = function (x) {
                        return function __do() {
                            props.setShowPriceListEditModal(function (v4) {
                                return new Data_Maybe.Just(newId);
                            })();
                            return handler(x)();
                        };
                    };
                    return mkAction(handler$prime)(new Types_Estimation.EditOrgPriceList({
                        orgPriceListId: props.orgPriceListTree.orgPriceList.orgPriceListId,
                        newOrgPriceList: {
                            orgPriceListId: newId,
                            name: props.orgPriceListTree.orgPriceList.name
                        }
                    }));
                };
            }
        }), TacoInput.remoteStringField()()({
            value: props.orgPriceListTree.orgPriceList.name,
            label: "Nimi",
            validator: LengthValidator.lengthValidator(0)(30),
            mkAction: function (name) {
                return function (handler) {
                    return mkAction(handler)(new Types_Estimation.EditOrgPriceList({
                        orgPriceListId: props.orgPriceListTree.orgPriceList.orgPriceListId,
                        newOrgPriceList: {
                            orgPriceListId: props.orgPriceListTree.orgPriceList.orgPriceListId,
                            name: name
                        }
                    }));
                };
            }
        }) ]);
        var editGroupingAssociationColumns = mkEditGroupingAssociationModalColumns(dispatch)(props.projectId)(costClassesByClassification);
        var mkRow = function (r) {
            return React_Basic.keyed(r.orgResourceGroupingId)(TacoTable_Row.simpleRow(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "orgResourceGroupingId";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "orgPriceListId";
                }
            })(Types_Component.eqOrgEstimationResourceId))()({
                reflectSymbol: function () {
                    return "description";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "associatedOrgCostClassIds";
                }
            })(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "orgResourceGroupingId";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "orgPriceListId";
                }
            })(Types_Component.eqOrgEstimationResourceId))()({
                reflectSymbol: function () {
                    return "orgCostClassId";
                }
            })(Data_Eq.eqInt))()({
                reflectSymbol: function () {
                    return "id";
                }
            })(Data_Eq.eqInt))))))("edit-grouping-association-columns-row")(editGroupingAssociationColumns)(editGroupingAssociationRowStyleSet)(r));
        };
        var tableRows = Data_Functor.map(Data_Functor.functorArray)(mkRow)(v2);
        var tableHeader = TacoTable_Header.simpleHeader(TofuHooks.getNameVoid)(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "orgResourceGroupingId";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "orgPriceListId";
            }
        })(Types_Component.eqOrgEstimationResourceId))()({
            reflectSymbol: function () {
                return "description";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "associatedOrgCostClassIds";
            }
        })(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "orgResourceGroupingId";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "orgPriceListId";
            }
        })(Types_Component.eqOrgEstimationResourceId))()({
            reflectSymbol: function () {
                return "orgCostClassId";
            }
        })(Data_Eq.eqInt))()({
            reflectSymbol: function () {
                return "id";
            }
        })(Data_Eq.eqInt))))))(editGroupingAssociationColumns)(editGroupingAssociationHeaderStyleSet);
        var addOrgResourceGroupingColumns = Data_Functor.map(Data_Functor.functorArray)(mkCol)([ {
            key: "priceListId",
            width: new TacoTable_Types.Fixed("8rem"),
            label: React_Basic_DOM.text("Hinnasto"),
            cell: new TacoTable_Types.JSXCell([  ], function (r) {
                return TacoButton.component()()({
                    onClick: new Data_Maybe.Just(Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_String_Common["null"])(r.id))(dispatch(new Actions.EstimationResourcePriceListActionRequest({
                        projectId: props.projectId,
                        handler: function (v4) {
                            return function __do() {
                                v.value1(function (v5) {
                                    return "";
                                })();
                                return v1.value1(function (v5) {
                                    return "";
                                })();
                            };
                        },
                        params: new Types_Estimation.AddOrgResourceGrouping({
                            orgPriceListId: props.orgPriceListTree.orgPriceList.orgPriceListId,
                            orgResourceGroupingId: r.id,
                            description: r.description,
                            associatedOrgCostClassIds: [  ]
                        })
                    })))),
                    buttonStyle: TacoButton_Types.Filled.value,
                    text: "Lis\xe4\xe4"
                });
            })
        }, {
            key: "id",
            width: new TacoTable_Types.Fixed("8rem"),
            label: React_Basic_DOM.text("Tunnus"),
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (r) {
                return TacoInput.stringInput()()(Data_Eq.eqString)({
                    value: r.id,
                    onChange: v.value1
                });
            })
        }, {
            key: "description",
            width: new TacoTable_Types.Fixed("8rem"),
            label: React_Basic_DOM.text("Selite"),
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (r) {
                return TacoInput.stringInput()()(Data_Eq.eqString)({
                    value: r.description,
                    onChange: v1.value1
                });
            })
        } ]);
        var addRow = TacoTable_Row.simpleRow(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "id";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "description";
            }
        })(Data_Eq.eqString)))("add-org-resource-grouping-columns-columns-row")(addOrgResourceGroupingColumns)(editGroupingAssociationRowStyleSet)({
            id: v.value0,
            description: v1.value0
        });
        var table = Box.box("__EditGroupingAssociationModalTableBox")([ Box.Width.create(new TacoConstants.CustomSize("100%")) ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ tableHeader ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(tableRows)([ addRow ])));
        var modalBody = TacoModal.modalBody({
            contents: [ TacoText.component()()({
                text: "Panoshinnaston tiedot:",
                weight: TacoText_Types.Bold.value
            }), editPriceListFields, TacoText.component()()({
                text: "Lis\xe4\xe4 ja yhdist\xe4 panoshinnaston panoslajit nimikkeist\xf6jen panoslajeihin:",
                weight: TacoText_Types.Bold.value
            }), table, TacoText.component()()({
                text: "Panoshinnaston panosryhm\xe4tunnukset",
                weight: TacoText_Types.Bold.value
            }), subGroupExcelButtons, TacoText.component()()({
                text: "Panoshinnaston panokset:",
                weight: TacoText_Types.Bold.value
            }), orgResourcesExcelButtons ]
        });
        return TacoModal.component()()({
            onClose: onClose,
            isActive: props.isActive,
            headingTextJsx: [ React_Basic_DOM.text("Muokkaa panoshinnastoa: "), React_Basic_DOM_Generated.var_([ React_Basic_DOM.text(Data_Newtype.unwrap()(props.orgPriceListTree.orgPriceList.orgPriceListId) + (", " + props.orgPriceListTree.orgPriceList.name)) ]) ],
            contents: [ modalBody, modalActions ],
            containerStyles: [ new Box.Width(new TacoConstants.CustomSize("90vw")) ]
        });
    };
});
var addPriceListModal = TofuHooks.mkHookComponent("AddPriceListModal")(function (props) {
    return function __do() {
        var dispatch = StateHooks.useDispatch();
        var v = TofuHooks.useState("")();
        var v1 = TofuHooks.useState("")();
        var fields = Gap.gapX(TacoConstants.M.value)([ TacoInput.stringInput()()(Data_Eq.eqString)({
            value: v.value0,
            label: "Tunnus",
            onChange: v.value1,
            validator: LengthValidator.lengthValidator(1)(7)
        }), TacoInput.stringInput()()(Data_Eq.eqString)({
            value: v1.value0,
            label: "Nimi",
            onChange: v1.value1,
            validator: LengthValidator.lengthValidator(0)(30)
        }) ]);
        var closeModal = props.setShowAddPriceListModal(function (v2) {
            return false;
        });
        var modalActions = [ TacoButton.component()()({
            onClick: Data_Maybe.Just.create(closeModal),
            text: "Peruuta"
        }), TacoButton.component()()({
            onClick: new Data_Maybe.Just(Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_String_Common["null"])(v.value0))(dispatch(new Actions.EstimationResourcePriceListActionRequest({
                projectId: props.projectId,
                handler: function (v2) {
                    return closeModal;
                },
                params: new Types_Estimation.AddOrgPriceList({
                    orgPriceListId: v.value0,
                    name: v1.value0
                })
            })))),
            buttonStyle: TacoButton_Types.Filled.value,
            text: "Lis\xe4\xe4"
        }) ];
        var modal = TacoModal.component()()({
            onClose: closeModal,
            isActive: true,
            heading: "Uusi panoshinnasto",
            contents: [ TacoModal.modalBody({
                contents: [ fields ]
            }), TacoModal.modalActions({
                contents: modalActions
            }) ],
            containerStyles: [ new Box.MinHeight(new TacoConstants.CustomSize("25vh")) ]
        });
        return modal;
    };
});
var component = TofuHooks.mkHookComponent("ResourcePriceListView")(function (props) {
    return function __do() {
        var dispatch = StateHooks.useDispatch();
        var v = FetchHooks.useEstimationResourcePriceList(props.projectId)();
        var v1 = FetchHooks.useOrgPriceListTree();
        var v2 = FetchHooks.useOrgCostClasses();
        var v3 = UseEstimationUiState.useEstimationUiState(props.projectId)();
        var v4 = TofuHooks.useState(v3.value0.referenceResourceFilters)();
        var setActiveFilters$prime = TofuHooks.useMemo([ v4.value1 ])(function (v5) {
            return function (filters) {
                return function __do() {
                    v3.value1(function (v6) {
                        return {
                            project: v6.project,
                            elementFilters: v6.elementFilters,
                            elementGroupProperty: v6.elementGroupProperty,
                            selectedElementIdx: v6.selectedElementIdx,
                            referenceElementFilters: v6.referenceElementFilters,
                            selectedReferenceElementIdx: v6.selectedReferenceElementIdx,
                            componentFilters: v6.componentFilters,
                            componentGroupProperty: v6.componentGroupProperty,
                            selectedComponentIdx: v6.selectedComponentIdx,
                            referenceComponentFilters: v6.referenceComponentFilters,
                            selectedReferenceComponentIdx: v6.selectedReferenceComponentIdx,
                            referenceResourceFilters: filters,
                            selectedResourceId: v6.selectedResourceId
                        };
                    })();
                    return v4.value1(function (v6) {
                        return filters;
                    })();
                };
            };
        })();
        var filters = TofuHooks.useMemo([ ReactHooksUtils.utf(v4.value0) ])(function (v5) {
            return Data_Functor.map(Data_Functor.functorArray)(EstimationUtils.filterOrgResourcePrice)(v4.value0);
        })();
        var addFilter = TofuHooks.useMemo([ ReactHooksUtils.utf(v4.value0) ])(function (v5) {
            return function (orgPriceListId) {
                return function (nullableCostClassCode) {
                    return function (groupCode) {
                        var mkFilterByOrgResourcePriceCostClassCode = function (code) {
                            return new CostEstimation_Filters.FilterByOrgResourcePriceCostClassCode({
                                state: TacoFilter.FVSPreviouslySet.value,
                                values: [ code ]
                            });
                        };
                        return setActiveFilters$prime(Data_Array.catMaybes([ Data_Maybe.Just.create(new CostEstimation_Filters.FilterByOrgResourcePricePriceListName({
                            state: TacoFilter.FVSPreviouslySet.value,
                            values: [ Data_Newtype.unwrap()(orgPriceListId) ]
                        })), Data_Functor.map(Data_Maybe.functorMaybe)(mkFilterByOrgResourcePriceCostClassCode)(Data_Nullable.toMaybe(nullableCostClassCode)), Data_Maybe.Just.create(new CostEstimation_Filters.FilterByOrgResourcePriceGroup({
                            state: TacoFilter.FVSPreviouslySet.value,
                            values: [ groupCode ]
                        })) ]));
                    };
                };
            };
        })();
        var componentResourceActionType = (function () {
            var v5 = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v8) {
                return v8.selectedEditViewComponentId;
            })(props.panelOptions);
            var v6 = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v8) {
                return v8.selectedEditViewElementSpecId;
            })(props.panelOptions);
            var v7 = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v8) {
                return v8.selectedEditViewElementId;
            })(props.panelOptions);
            if (v7 instanceof Data_Maybe.Just && (v6 instanceof Data_Maybe.Just && v5 instanceof Data_Maybe.Just)) {
                return new Types_Estimation.SplitElementComponent({
                    elementId: v7.value0,
                    elementSpecId: v6.value0,
                    componentId: v5.value0
                });
            };
            return Types_Estimation.InPlaceResourceUpdate.value;
        })();
        var v5 = TofuHooks.useState(Data_Set.empty)();
        TofuHooks.useEffect([ ReactHooksUtils.utf(filters) ])(function __do() {
            v5.value1(function (v6) {
                return Data_Set.empty;
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Effect.monoidEffect(Data_Monoid.monoidUnit)))();
        })();
        var replaceWithCheckedResources = (function () {
            var resourceIds = Data_Functor.map(Data_Maybe.functorMaybe)(Data_Functor.map(Data_Functor.functorFn)(Data_Array.fromFoldable(Data_Set.foldableSet))(function (v6) {
                return v6.selectedEditViewResourceIds;
            }))(props.panelOptions);
            var v6 = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Data_Array.head)(resourceIds);
            var v7 = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v8) {
                return v8.selectedEditViewComponentId;
            })(props.panelOptions);
            if (v7 instanceof Data_Maybe.Nothing) {
                return dispatch(Actions.AddAlert.create(Types_Alert.AlertError.value)("Suoritetta ei ole valittu."));
            };
            if (v6 instanceof Data_Maybe.Nothing) {
                return dispatch(Actions.AddAlert.create(Types_Alert.AlertError.value)("Kohdepanosta ei ole valittu."));
            };
            if (v6 instanceof Data_Maybe.Just && Data_Ord.greaterThan(Data_Maybe.ordMaybe(Data_Ord.ordInt))(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Array.length)(resourceIds))(new Data_Maybe.Just(1))) {
                return dispatch(Actions.AddAlert.create(Types_Alert.AlertError.value)("Valittuja kohdepanoksia voi olla vain yksi."));
            };
            if (v7 instanceof Data_Maybe.Just && v6 instanceof Data_Maybe.Just) {
                return dispatch(Actions.EstimationResourceActionRequest.create({
                    projectId: props.projectId,
                    handler: function (v8) {
                        return v5.value1(function (v9) {
                            return Data_Set.empty;
                        });
                    },
                    params: {
                        actionType: componentResourceActionType,
                        actionParams: new Types_Estimation.ReplaceComponentResourceFromOrgPriceList([ {
                            componentId: v7.value0,
                            orgResourcePriceIds: Data_Array.fromFoldable(Data_Set.foldableSet)(v5.value0),
                            replaceTargetResourceId: v6.value0
                        } ])
                    }
                }));
            };
            throw new Error("Failed pattern match at ResourcePriceListView (line 263, column 10 - line 287, column 14): " + [ v7.constructor.name, v6.constructor.name ]);
        })();
        var replaceSelectedResourceFromPricelist = function (res) {
            var v6 = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v7) {
                return v7.selectedEditViewResource;
            })(props.panelOptions);
            if (v6 instanceof Data_Maybe.Nothing) {
                return dispatch(Actions.AddAlert.create(Types_Alert.AlertError.value)("Panosta ei ole valittu."));
            };
            if (v6 instanceof Data_Maybe.Just) {
                var resourcesInMergedEstimationResource = Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)(Data_Tuple.fst)(v6.value0.estimationResources);
                var hasComponent = function (resource) {
                    if (resource.componentId instanceof Data_Maybe.Nothing) {
                        return Data_Maybe.Nothing.value;
                    };
                    if (resource.componentId instanceof Data_Maybe.Just) {
                        return new Data_Maybe.Just({
                            componentId: resource.componentId.value0,
                            resourceId: resource.resourceId
                        });
                    };
                    throw new Error("Failed pattern match at ResourcePriceListView (line 246, column 37 - line 248, column 109): " + [ resource.componentId.constructor.name ]);
                };
                var groupedResources = Data_Array_NonEmpty.toArray(Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)(function (r) {
                    return {
                        resourceId: r.id,
                        componentId: Data_Nullable.toMaybe(r.estimationComponentId)
                    };
                })(resourcesInMergedEstimationResource));
                var groupedResourcesWithComponents = Data_Array.catMaybes(Data_Functor.map(Data_Functor.functorArray)(hasComponent)(groupedResources));
                var replaceParams = Data_Functor.map(Data_Functor.functorArray)(function (r) {
                    return {
                        componentId: r.componentId,
                        replaceTargetResourceId: r.resourceId,
                        orgResourcePriceIds: [ res.orgResourcePriceId ]
                    };
                })(groupedResourcesWithComponents);
                return dispatch(new Actions.EstimationResourceActionRequest({
                    projectId: props.projectId,
                    params: {
                        actionType: Types_Estimation.InPlaceResourceUpdate.value,
                        actionParams: new Types_Estimation.ReplaceComponentResourceFromOrgPriceList(replaceParams)
                    },
                    handler: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit)))
                }));
            };
            throw new Error("Failed pattern match at ResourcePriceListView (line 236, column 7 - line 259, column 14): " + [ v6.constructor.name ]);
        };
        var deleteCheckedResources = dispatch(Actions.EstimationResourcePriceListActionRequest.create({
            projectId: props.projectId,
            handler: function (v6) {
                return v5.value1(function (v7) {
                    return Data_Set.empty;
                });
            },
            params: new Types_Estimation.DeleteOrgResourcePrices({
                orgResourcePriceIds: Data_Array.fromFoldable(Data_Set.foldableSet)(v5.value0)
            })
        }));
        var addCheckedResourcesToComponent = (function () {
            var v6 = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v7) {
                return v7.selectedEditViewComponentId;
            })(props.panelOptions);
            if (v6 instanceof Data_Maybe.Nothing) {
                return dispatch(Actions.AddAlert.create(Types_Alert.AlertError.value)("Suoritetta ei ole valittu."));
            };
            if (v6 instanceof Data_Maybe.Just) {
                return dispatch(Actions.EstimationResourceActionRequest.create({
                    projectId: props.projectId,
                    handler: function (v7) {
                        return v5.value1(function (v8) {
                            return Data_Set.empty;
                        });
                    },
                    params: {
                        actionType: componentResourceActionType,
                        actionParams: new Types_Estimation.AddResourcesToComponentFromOrgPriceList({
                            componentId: v6.value0,
                            orgResourcePriceIds: Data_Array.fromFoldable(Data_Set.foldableSet)(v5.value0)
                        })
                    }
                }));
            };
            throw new Error("Failed pattern match at ResourcePriceListView (line 209, column 7 - line 225, column 10): " + [ v6.constructor.name ]);
        })();
        var mRefPanelMode = Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(props.panelOptions)(function (v6) {
            return CostEstimationLayout_Types.ReferenceResourcePanel.value;
        });
        var isPanel = Data_Maybe.isJust(mRefPanelMode);
        var v6 = TacoLoader.useLoading(false)();
        var v7 = TofuHooks.useState(false)();
        var v8 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var orgPriceListTreeItems = ReactHooksUtils.usePersistMemo("OrgPriceListTreeItems")([ v1.value0 ])(function (v9) {
            return Data_Functor.map(Data_Functor.functorArray)(mkOrgPriceListTreeItem(addFilter)(v8.value1))(v1.value0);
        })();
        v6.setLoading("ResourcePrices")(v.value1)();
        v6.setLoading("OrgPriceListTree")(v1.value1)();
        v6.setLoading("OrgCostClasses")(v2.value1)();
        var orgPriceListTreeView = TofuHooks.useMemo([ orgPriceListTreeItems ])(function (v9) {
            return TacoList.component({
                items: orgPriceListTreeItems
            });
        })();
        var tableRef = UseNullableRef.useNullableRef();
        var v9 = TofuHooks.useState(new TacoTable_Types.SortingCriteria(TacoTable_Types.Ascending.value, new Data_Maybe.Just(ResourcePriceListView_PriceListTable.ByPLPriceListId.value), ResourcePriceListView_PriceListTable.getPLSortingValue))();
        var v10 = TofuHooks.useMemo([ ReactHooksUtils.utf(v.value0), ReactHooksUtils.utf(filters) ])(function (v10) {
            return Data_Array.filter((function () {
                var $230 = Data_Foldable.and(Data_Foldable.foldableArray)(Data_HeytingAlgebra.heytingAlgebraBoolean);
                var $231 = Data_Functor.flap(Data_Functor.functorArray)(filters);
                return function ($232) {
                    return $230($231($232));
                };
            })())(v.value0);
        })();
        var setSortingCriteria = TofuHooks.useMemo([  ])(function (v11) {
            return function (newProperty) {
                return v9.value1(function (v12) {
                    var $175 = Data_Eq.eq(Data_Maybe.eqMaybe(ResourcePriceListView_PriceListTable.eqPLSortProperty))(v12.value1)(new Data_Maybe.Just(newProperty));
                    if ($175) {
                        return new TacoTable_Types.SortingCriteria(TacoTable_Types.reverse(v12.value0), v12.value1, v12.value2);
                    };
                    return new TacoTable_Types.SortingCriteria(v12.value0, new Data_Maybe.Just(newProperty), v12.value2);
                });
            };
        })();
        var v11 = UseSocialExpensesInCosts.useSocialExpensesInCosts(props.projectId)();
        var sortedResourcePrices = TofuHooks.useMemo([ ReactHooksUtils.utf(v9.value0), ReactHooksUtils.utf(v10), ReactHooksUtils.utf(v5.value0), ReactHooksUtils.utf(v11.socialExpensePercentagesByCostClass), ReactHooksUtils.utf(v11.showSocialExpensesInCosts) ])(function (v12) {
            var withOrder = (function () {
                var $181 = Data_Eq.eq(TacoTable_Types.eqSortingOrder)(v9.value0.value0)(TacoTable_Types.Descending.value);
                if ($181) {
                    return Data_Function.flip;
                };
                return Control_Category.identity(Control_Category.categoryFn);
            })();
            var sortableValue = function (r) {
                if (v9.value0.value1 instanceof Data_Maybe.Just) {
                    return v9.value0.value2(v9.value0.value1.value0)(r);
                };
                return TacoTable_Types.StringValue.create(Data_Newtype.unwrap()(r.res.priceListId));
            };
            var sortFunction = Data_Array.sortBy(function (a) {
                return function (b) {
                    return withOrder(Data_Ord.compare(TacoTable_Types.ordComparableValue))(sortableValue(a))(sortableValue(b));
                };
            });
            var mkRow = function (res) {
                return {
                    res: res,
                    checked: Data_Set.member(Data_Ord.ordInt)(res.orgResourcePriceId)(v5.value0),
                    socialExpensePercentagesByCostClass: v11.socialExpensePercentagesByCostClass,
                    showSocialExpensesInCosts: v11.showSocialExpensesInCosts
                };
            };
            return sortFunction(Data_Functor.map(Data_Functor.functorArray)(mkRow)(v10));
        })();
        var v12 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var v13 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readArray(ResourcePriceListView_PriceListTable.tofuJSONPLColumn))("ui:ce:visible_price_list_columns")(ResourcePriceListView_PriceListTable.allPLColumns)(false)();
        var table = ResourcePriceListView_PriceListTable.priceListTable({
            priceListRows: sortedResourcePrices,
            showSocialExpensesInCosts: v11.showSocialExpensesInCosts,
            rowHeight: rowHeight,
            sortingCriteria: v9.value0,
            setSortingCriteria: setSortingCriteria,
            containerRef: tableRef,
            setSelectedResourcePrice: v12.value1,
            checkedResources: v5.value0,
            setCheckedResources: v5.value1,
            deleteCheckedResources: deleteCheckedResources,
            addCheckedResourcesToComponent: addCheckedResourcesToComponent,
            replaceWithCheckedResources: replaceWithCheckedResources,
            replaceSelectedResourceFromPricelist: replaceSelectedResourceFromPricelist,
            isPanel: isPanel,
            visibleColumns: v13.value0,
            panelOptions: props.panelOptions,
            projectCurrency: props.project.currency
        });
        var rightDropdown = TacoDropdown.uncontrolled({
            sections: [ {
                title: Data_Maybe.Nothing.value,
                items: [ SocialExpensesControls.component({
                    isSocialExpensesToggled: v11.showSocialExpensesInCosts,
                    onToggle: v11.onSocialExpensesToggle,
                    projectId: props.projectId,
                    disabled: Data_Maybe.Nothing.value
                }) ]
            }, {
                title: Data_Maybe.Nothing.value,
                items: [ TacoColumnSelector.columnSelector(ResourcePriceListView_PriceListTable.eqPLColumn)(ResourcePriceListView_PriceListTable.allPLColumns)(ResourcePriceListView_PriceListTable.getPLColumnLabelText)(v13.value0)(v13.value1) ]
            }, {
                title: new Data_Maybe.Just("Muut"),
                items: [ TacoButton.component()()({
                    text: "P\xe4ivit\xe4 panoshinnaston kustannuslajit",
                    icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconFetch.value),
                    onClick: Data_Maybe.Just.create(dispatch(new Actions.EstimationResourcePriceListActionRequest({
                        projectId: props.projectId,
                        handler: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit))),
                        params: Types_Estimation.UpdateAllOrgResourceCostClasses.value
                    }))),
                    testId: "update-all-org-resource-cost-classes-button"
                }) ]
            } ],
            align: TacoDropdown_Types.AlignRight.value,
            testId: "resources-pricelist-view-actions-dropdown"
        });
        var leftControls = [ TacoText.component()()({
            text: "Panoskirjasto",
            variant: new TacoText_Types.Heading(2),
            wrap: TacoText_Types.NoWrap.value,
            overflow: TacoText_Types.TextOverflowEllipsis.value
        }), EstimationComponentFilter.orgResourcePriceFilter({
            activeFilters: v4.value0,
            onSetActiveFilters: setActiveFilters$prime,
            viewMode: Types_CostEstimation.EditMode.value,
            showTargetCostGroup: false
        }) ];
        var controls = Box.box("__ResourcePriceListViewControls")([ new Box.Padding(TacoConstants.S.value), new Box.FlexGrow(1), Box.FlexColumn.value, Box.AlignStretch.value ])([ Controls.component()()({
            leftControls: leftControls,
            rightControls: [ rightDropdown ],
            gap: true,
            bottomGutter: false
        }) ]);
        var panelProps = CostEstimation_Layout.useCostEstimationLayout({
            leftPanelInitMode: CostEstimationLayout_Types.MinimizedMode.value,
            rightPanelInitMode: CostEstimationLayout_Types.MinimizedMode.value,
            detailsPanelInitMode: CostEstimationLayout_Types.NormalMode.value,
            localStorageKey: "ResourcePriceListView"
        })();
        return React_Basic.fragment([ CostEstimation_Layout.component({
            controls: new Data_Maybe.Just(controls),
            controlsLoading: CostEstimationLayout_Types.LoadingFinished.value,
            leftPanel: Data_Monoid.guard(Data_Monoid.monoidFn(Data_Maybe.monoidMaybe(React_Basic.semigroupJSX)))(!isPanel)(Data_Maybe.Just.create)(CostEstimation_SummariesPanel.component({
                panelMode: panelProps.leftPanelMode,
                getContents: function (v14) {
                    var findMatchingOrgPriceListTree = function (id) {
                        return Data_Array.find(function (r) {
                            return Data_Eq.eq(Types_Component.eqOrgEstimationResourceId)(r.orgPriceList.orgPriceListId)(id);
                        })(v1.value0);
                    };
                    var priceList = ReactBasicUtils["div'"]("__PriceList")([ Box.box("__PriceListHeader")([ Box.FlexRow.value, Box.JustifySpaceBetween.value ])([ React_Basic_DOM_Generated.h3()({
                        className: "heading",
                        children: Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM.text("Panoshinnastot"))
                    }), TacoButton.component()()({
                        buttonStyle: TacoButton_Types.Link.value,
                        text: "Lis\xe4\xe4 panoshinnasto",
                        onClick: Data_Maybe.Just.create(v7.value1(function (v15) {
                            return true;
                        }))
                    }) ]), orgPriceListTreeView, (function () {
                        var v15 = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(findMatchingOrgPriceListTree)(v8.value0);
                        if (v15 instanceof Data_Maybe.Nothing) {
                            return React_Basic.empty;
                        };
                        if (v15 instanceof Data_Maybe.Just) {
                            return editGroupingAssociationModal({
                                isActive: true,
                                setShowPriceListEditModal: v8.value1,
                                projectId: props.projectId,
                                orgPriceListTree: v15.value0,
                                orgCostClasses: v2.value0,
                                allResourcePrices: v.value0
                            });
                        };
                        throw new Error("Failed pattern match at ResourcePriceListView (line 476, column 21 - line 485, column 26): " + [ v15.constructor.name ]);
                    })(), (function () {
                        if (v7.value0) {
                            return addPriceListModal({
                                projectId: props.projectId,
                                setShowAddPriceListModal: v7.value1
                            });
                        };
                        return React_Basic.empty;
                    })() ]);
                    return React_Basic.fragment([ priceList ]);
                }
            })),
            leftSidebar: Data_Monoid.guard(Data_Monoid.monoidFn(Data_Maybe.monoidMaybe(React_Basic.semigroupJSX)))(!isPanel)(Data_Maybe.Just.create)(CostEstimation_SummariesSidebar.component({
                panelMode: panelProps.leftPanelMode,
                setPanelMode: panelProps.setLeftPanelMode
            })),
            rightPanel: Data_Maybe.Nothing.value,
            rightSidebar: Data_Maybe.Nothing.value,
            main: table,
            details: Data_Maybe.Nothing.value,
            panelProps: panelProps,
            leftPanelLoading: (function () {
                if (v6.isLoading) {
                    return CostEstimationLayout_Types.LoadingBarDimmedContents.value;
                };
                return CostEstimationLayout_Types.LoadingFinished.value;
            })(),
            mRefPanelMode: mRefPanelMode
        }), (function () {
            if (v12.value0 instanceof Data_Maybe.Nothing) {
                return React_Basic.empty;
            };
            if (v12.value0 instanceof Data_Maybe.Just) {
                return EditResourcePriceModal.editResourcePriceModal({
                    projectId: props.projectId,
                    orgResourcePrice: v12.value0.value0,
                    onClose: v12.value1(function (v14) {
                        return Data_Maybe.Nothing.value;
                    })
                });
            };
            throw new Error("Failed pattern match at ResourcePriceListView (line 507, column 7 - line 513, column 12): " + [ v12.value0.constructor.name ]);
        })() ]);
    };
});
module.exports = {
    component: component
};
