// Generated by purs version 0.14.5
"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Utils = require("../Utils/index.js");
var Hierarchy = (function () {
    function Hierarchy(value0, value1, value2) {
        this.value0 = value0;
        this.value1 = value1;
        this.value2 = value2;
    };
    Hierarchy.create = function (value0) {
        return function (value1) {
            return function (value2) {
                return new Hierarchy(value0, value1, value2);
            };
        };
    };
    return Hierarchy;
})();
var showHierarchy = function (dictShow) {
    return {
        show: function (v) {
            return "Hierarchy " + (Data_Show.show(Data_Show.showString)(v.value0) + (" " + (Data_Show.show(Data_Show.showArray(Data_Tuple.showTuple(Data_Show.showString)(dictShow)))(v.value1) + (" " + Data_Show.show(Data_Show.showArray(showHierarchy(dictShow)))(v.value2)))));
        }
    };
};
var prettyPrint = function (dictShow) {
    return function (hierarchy) {
        var go = function (level) {
            return function (v) {
                var valLabels = Data_Functor.map(Data_Functor.functorArray)(Data_Tuple.fst)(v.value1);
                var indent = Data_String_CodeUnits.fromCharArray(Data_Array.replicate(level * 2 | 0)(" "));
                return indent + ("\"" + (v.value0 + ("\": " + (Data_Show.show(Data_Show.showArray(Data_Show.showString))(valLabels) + ("\x0a" + Data_String_Common.joinWith("\x0a")(Data_Functor.map(Data_Functor.functorArray)(go(level + 1 | 0))(v.value2)))))));
            };
        };
        return go(0)(hierarchy);
    };
};
var listHierarchy = function (mkTopicRow) {
    return function (mkValRow) {
        return function (hierarchy) {
            var go = function (v) {
                var vals = Data_Functor.map(Data_Functor.functorArray)(Data_Tuple.snd)(v.value1);
                var valRows = Data_Functor.map(Data_Functor.functorArray)(mkValRow)(vals);
                var rec = Data_Functor.map(Data_Functor.functorArray)(go)(v.value2);
                var topicVals = Data_Semigroup.append(Data_Semigroup.semigroupArray)(vals)(Data_Array.concat(Data_Functor.map(Data_Functor.functorArray)(Data_Tuple.snd)(rec)));
                var topicRow = (function () {
                    if (v.value0 === "") {
                        return [  ];
                    };
                    return [ mkTopicRow(v.value0)(topicVals) ];
                })();
                var childList = Data_Array.concat(Data_Functor.map(Data_Functor.functorArray)(Data_Tuple.fst)(rec));
                if (topicVals.length === 0) {
                    return new Data_Tuple.Tuple([  ], [  ]);
                };
                return new Data_Tuple.Tuple(Data_Semigroup.append(Data_Semigroup.semigroupArray)(topicRow)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(valRows)(childList)), topicVals);
            };
            var rows = Data_Tuple.fst(go(hierarchy));
            return rows;
        };
    };
};
var eqHierarchy = function (dictEq) {
    return {
        eq: function (x) {
            return function (y) {
                return x.value0 === y.value0 && Data_Eq.eq(Data_Eq.eqArray(Data_Tuple.eqTuple(Data_Eq.eqString)(dictEq)))(x.value1)(y.value1) && Data_Eq.eq(Data_Eq.eqArray(eqHierarchy(dictEq)))(x.value2)(y.value2);
            };
        }
    };
};
var addValueToHierarchy = function (v) {
    return function (v1) {
        var belongsTo = function (v2) {
            return function (v3) {
                return Utils.isPrefix(v3.value0)(v2.value0);
            };
        };
        var putUnderSubhierarchy = function (val$prime) {
            return function (hierarchies) {
                return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.findIndex(belongsTo(val$prime))(hierarchies))(function (i) {
                    return Data_Array.modifyAt(i)(addValueToHierarchy(val$prime))(hierarchies);
                });
            };
        };
        var v2 = putUnderSubhierarchy(v)(v1.value2);
        if (v2 instanceof Data_Maybe.Just) {
            return new Hierarchy(v1.value0, v1.value1, v2.value0);
        };
        if (v2 instanceof Data_Maybe.Nothing) {
            var $56 = Utils.isPrefix(v1.value0)(v.value0);
            if ($56) {
                return new Hierarchy(v1.value0, Data_Semigroup.append(Data_Semigroup.semigroupArray)(v1.value1)([ v ]), v1.value2);
            };
            return v1;
        };
        throw new Error("Failed pattern match at Hierarchy (line 38, column 3 - line 43, column 13): " + [ v2.constructor.name ]);
    };
};
var addValuesToHierarchy = function (vals) {
    return function (h) {
        return Data_Array.foldr(addValueToHierarchy)(h)(vals);
    };
};
var addLabelToHierarchy = function (label) {
    return function (v) {
        var belongsTo = function (label$prime) {
            return function (v1) {
                return Utils.isPrefix(v1.value0)(label$prime);
            };
        };
        var putUnderSubhierarchy = function (label$prime) {
            return function (hierarchies) {
                return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.findIndex(belongsTo(label$prime))(hierarchies))(function (i) {
                    return Data_Array.modifyAt(i)(addLabelToHierarchy(label$prime))(hierarchies);
                });
            };
        };
        var $69 = label === v.value0;
        if ($69) {
            return v;
        };
        var v1 = putUnderSubhierarchy(label)(v.value2);
        if (v1 instanceof Data_Maybe.Just) {
            return new Hierarchy(v.value0, v.value1, v1.value0);
        };
        if (v1 instanceof Data_Maybe.Nothing) {
            return Hierarchy.create(v.value0)(v.value1)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(v.value2)([ new Hierarchy(label, [  ], [  ]) ]));
        };
        throw new Error("Failed pattern match at Hierarchy (line 23, column 5 - line 25, column 78): " + [ v1.constructor.name ]);
    };
};
var mkHierarchy = function (labels) {
    return Data_Array.foldl(Data_Function.flip(addLabelToHierarchy))(new Hierarchy("", [  ], [  ]))(Data_Array.sort(Data_Ord.ordString)(labels));
};
module.exports = {
    Hierarchy: Hierarchy,
    addLabelToHierarchy: addLabelToHierarchy,
    mkHierarchy: mkHierarchy,
    addValueToHierarchy: addValueToHierarchy,
    addValuesToHierarchy: addValuesToHierarchy,
    listHierarchy: listHierarchy,
    prettyPrint: prettyPrint,
    showHierarchy: showHierarchy,
    eqHierarchy: eqHierarchy
};
