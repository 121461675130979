// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var CostEstimation_Filters = require("../CostEstimation.Filters/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var StringUtils = require("../StringUtils/index.js");
var Types_CostEstimation = require("../Types.CostEstimation/index.js");
var Utils = require("../Utils/index.js");
var projectToTSProject = function (project) {
    return {
        id: project.id,
        code: project.code,
        version: project.version,
        name: project.name,
        startDate: project.startDate,
        endDate: project.endDate,
        ownProject: project.ownProject,
        taxRate: Data_Nullable.toNullable(project.taxRate),
        ownTaxRate: project.ownTaxRate,
        projectManager: project.projectManager,
        contractType: project.contractType,
        projectType: project.projectType,
        financingType: project.financingType,
        constructionType: project.constructionType,
        contractPrice: project.contractPrice,
        state: project.state,
        classification: project.classification,
        address: project.address,
        postalCode: project.postalCode,
        city: project.city,
        memo: project.memo,
        area: project.area,
        projectGroup: project.projectGroup,
        defaultInvoiceCostClass: Data_Nullable.toNullable(project.defaultInvoiceCostClass),
        defaultSalaryCostClass: Data_Nullable.toNullable(project.defaultSalaryCostClass),
        isReportingProject: project.isReportingProject,
        programType: project.programType,
        estimatedCosts: project.estimatedCosts,
        targetCosts: project.targetCosts,
        actualCosts: project.actualCosts,
        estimatedIncome: project.estimatedIncome,
        targetIncome: project.targetIncome,
        actualIncome: project.actualIncome,
        actualIncomeApproved: project.actualIncomeApproved,
        targetDiff: project.targetDiff,
        targetMargin: project.targetMargin,
        estimatedDiff: project.estimatedDiff,
        estimatedMargin: project.estimatedMargin,
        actualDiff: project.actualDiff,
        actualMargin: project.actualMargin,
        actualDiffApproved: project.actualDiffApproved,
        actualMarginApproved: project.actualMarginApproved,
        offerDate: project.offerDate,
        currency: Data_Newtype.unwrap()(project.currency)
    };
};
var programTypeIsProductionPlanning = function (x) {
    return x === 4;
};
var isReporting = function (p) {
    return p.isReportingProject;
};
var isProductionPlanning = function (p) {
    return programTypeIsProductionPlanning(p.programType);
};
var getCurrencySymbol = function (currency) {
    return $foreign["_getCurrencySymbol"](currency);
};
var filterOrgResourcePrice = function (v) {
    return function (r) {
        if (v instanceof CostEstimation_Filters.FilterByOrgResourcePriceName) {
            return StringUtils.containsAnyPatternCaseIgnored(v.value0.values)(Utils.defaultNull("")(r.name));
        };
        if (v instanceof CostEstimation_Filters.FilterByOrgResourcePricePriceListName) {
            return StringUtils.startsWithAnyPatternCaseIgnored(v.value0.values)(Data_Newtype.unwrap()(r.priceListId));
        };
        if (v instanceof CostEstimation_Filters.FilterByOrgResourcePriceGroup) {
            return StringUtils.startsWithAnyPatternCaseIgnored(v.value0.values)(Utils.defaultNull("")(r.resourceClassificationGroup));
        };
        if (v instanceof CostEstimation_Filters.FilterByOrgResourcePriceVendor) {
            return StringUtils.startsWithAnyPatternCaseIgnored(v.value0.values)(Utils.defaultNull("")(r.vendor));
        };
        if (v instanceof CostEstimation_Filters.FilterByOrgResourcePriceCostClassCode) {
            return StringUtils.startsWithAnyPatternCaseIgnored(v.value0.values)(Utils.foldNullable("")(Data_Newtype.unwrap())(r.costClassCode));
        };
        throw new Error("Failed pattern match at EstimationUtils (line 14, column 1 - line 14, column 88): " + [ v.constructor.name, r.constructor.name ]);
    };
};
var canEditView = function (props) {
    var notReporting = !isReporting(props);
    var notProductionPlanning = !isProductionPlanning(props);
    var isEditMode = Data_Eq.eq(Types_CostEstimation.eqECViewMode)(props.viewMode)(Types_CostEstimation.EditMode.value);
    return isEditMode && (notProductionPlanning && notReporting);
};
module.exports = {
    filterOrgResourcePrice: filterOrgResourcePrice,
    programTypeIsProductionPlanning: programTypeIsProductionPlanning,
    isProductionPlanning: isProductionPlanning,
    isReporting: isReporting,
    canEditView: canEditView,
    projectToTSProject: projectToTSProject,
    getCurrencySymbol: getCurrencySymbol,
    "_getCurrencySymbol": $foreign["_getCurrencySymbol"]
};
