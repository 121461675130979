import { useEffect, useMemo, useState } from "react";
import { TFunction } from "react-i18next";
import { GridApi, ICellRendererParams } from "ag-grid-community";

type PinnedRow = {
  id: string;
  component: {
    amount: null;
  };
};

export function useSetPinnedRow(
  includeTotalFooter: boolean,
  includeSocialTotal: boolean,
  gridReady: boolean,
  gridApi: GridApi | null
) {
  const pinnedBottomRowDataWithoutSocial = useMemo(() => {
    return {
      id: "totalRow",
      component: { amount: null },
    };
  }, []);

  const pinnedBottomRowDataWithSocial = useMemo(() => {
    return {
      id: "totalRowWithSocial",
      component: { amount: null },
    };
  }, []);

  const [pinnedBottomRows, setPinnedBottomRows] = useState<PinnedRow[]>([]);

  useEffect(() => {
    const includesTotal = pinnedBottomRows.find(
      (item) => item.id === "totalRow"
    );
    const filteredWithoutTotal = pinnedBottomRows.filter(
      (item) => item.id !== "totalRow"
    );
    const includesTotalWithSocial = pinnedBottomRows.find(
      (item) => item.id === "totalRowWithSocial"
    );
    const filteredWithoutTotalWithSocial = pinnedBottomRows.filter(
      (item) => item.id !== "totalRowWithSocial"
    );

    includeTotalFooter &&
      !includesTotal &&
      setPinnedBottomRows([
        pinnedBottomRowDataWithoutSocial,
        ...pinnedBottomRows,
      ]);
    includesTotal &&
      !includeTotalFooter &&
      setPinnedBottomRows(filteredWithoutTotal);
    includeSocialTotal &&
      !includesTotalWithSocial &&
      setPinnedBottomRows([...pinnedBottomRows, pinnedBottomRowDataWithSocial]);
    includesTotalWithSocial &&
      !includeSocialTotal &&
      setPinnedBottomRows(filteredWithoutTotalWithSocial);
  }, [
    includeTotalFooter,
    includeSocialTotal,
    pinnedBottomRows,
    pinnedBottomRowDataWithSocial,
    pinnedBottomRowDataWithoutSocial,
  ]);

  useEffect(() => {
    if (gridReady && gridApi && !gridApi.isDestroyed()) {
      gridApi.setGridOption("pinnedBottomRowData", pinnedBottomRows);
    }
  }, [gridApi, gridReady, pinnedBottomRows]);
}

export const pinnedTotalRowRenderer = (
  params: ICellRendererParams,
  t: TFunction<"estimation", "printing">
) => {
  if (params.data.id === "totalRow") {
    return t`grandTotalWithoutSocial`;
  }
  if (params.data.id === "totalRowWithSocial") {
    return t`grandTotalWithSocial`;
  }
  return params.value ? params.value : null;
};
