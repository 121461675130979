// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Control_Alt = require("../Control.Alt/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Number_Format = require("../Data.Number.Format/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Class_Console = require("../Effect.Class.Console/index.js");
var EstimationUtils = require("../EstimationUtils/index.js");
var FeatureHooks = require("../FeatureHooks/index.js");
var Grid = require("../Grid/index.js");
var LengthValidator = require("../LengthValidator/index.js");
var Locale = require("../Locale/index.js");
var NumberValidator = require("../NumberValidator/index.js");
var Project_EditCostClasses = require("../Project.EditCostClasses/index.js");
var ProjectComponents_Styles = require("../ProjectComponents.Styles/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var StateHooks = require("../StateHooks/index.js");
var StringUtils = require("../StringUtils/index.js");
var TacoAutocomplete = require("../TacoAutocomplete/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoLoader = require("../TacoLoader/index.js");
var TacoSelect = require("../TacoSelect/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_Classification = require("../Types.Classification/index.js");
var Types_CostGroup = require("../Types.CostGroup/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Project = require("../Types.Project/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var UseForm = require("../UseForm/index.js");
var UseUpdatingState = require("../UseUpdatingState/index.js");
var ReportingNoChange = (function () {
    function ReportingNoChange() {

    };
    ReportingNoChange.value = new ReportingNoChange();
    return ReportingNoChange;
})();
var ReportingUnpublish = (function () {
    function ReportingUnpublish() {

    };
    ReportingUnpublish.value = new ReportingUnpublish();
    return ReportingUnpublish;
})();
var ReportingPublish = (function () {
    function ReportingPublish() {

    };
    ReportingPublish.value = new ReportingPublish();
    return ReportingPublish;
})();
var AddCostControlProjectMode = (function () {
    function AddCostControlProjectMode() {

    };
    AddCostControlProjectMode.value = new AddCostControlProjectMode();
    return AddCostControlProjectMode;
})();
var EditCostControlProjectMode = (function () {
    function EditCostControlProjectMode() {

    };
    EditCostControlProjectMode.value = new EditCostControlProjectMode();
    return EditCostControlProjectMode;
})();
var AddEstimationProjectMode = (function () {
    function AddEstimationProjectMode() {

    };
    AddEstimationProjectMode.value = new AddEstimationProjectMode();
    return AddEstimationProjectMode;
})();
var EditEstimationProjectMode = (function () {
    function EditEstimationProjectMode() {

    };
    EditEstimationProjectMode.value = new EditEstimationProjectMode();
    return EditEstimationProjectMode;
})();
var projectStateValue = function (v) {
    if (v === "Rakenteilla") {
        return "0";
    };
    if (v === "Valmis") {
        return "1";
    };
    if (v === "Suunnitteilla") {
        return "2";
    };
    if (v === "Takuuaika") {
        return "3";
    };
    return "0";
};
var projectStateOptions = [ {
    value: "0",
    text: Locale.lookup_("project_state_0")
}, {
    value: "1",
    text: Locale.lookup_("project_state_1")
}, {
    value: "2",
    text: Locale.lookup_("project_state_2")
}, {
    value: "3",
    text: Locale.lookup_("project_state_3")
} ];
var loader = TacoLoader.component()()({
    size: "1rem"
});
var getLatestVersionForCode = function (code) {
    return function (existingCodesAndVersions) {
        var matchCode = function (r) {
            return Data_String_Common.toLower(r.code) === Data_String_Common.toLower(code);
        };
        var existingVersions = Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.version;
        })(Data_Array.filter(matchCode)(existingCodesAndVersions));
        return Data_Foldable.maximum(Data_Ord.ordInt)(Data_Foldable.foldableArray)(existingVersions);
    };
};
var getExistingCodesAndVersions = function (mProjectList) {
    return TofuHooks.useMemo([ ReactHooksUtils.utf(mProjectList) ])(function (v) {
        var mkCodeVersionRec = function (r) {
            return Data_Functor.map(Data_Maybe.functorMaybe)(function (v1) {
                return {
                    code: Data_String_Common.toLower(Data_Newtype.un()(Types_Project.ProjectCode)(r.code)),
                    version: v1
                };
            })(StringUtils.readStrToInt(r.version));
        };
        if (mProjectList instanceof Data_Maybe.Nothing) {
            return [  ];
        };
        if (mProjectList instanceof Data_Maybe.Just) {
            return Data_Array.mapMaybe(mkCodeVersionRec)(mProjectList.value0);
        };
        throw new Error("Failed pattern match at ProjectComponents (line 748, column 5 - line 750, column 52): " + [ mProjectList.constructor.name ]);
    });
};
var eqProjectComponentsMode = {
    eq: function (x) {
        return function (y) {
            if (x instanceof AddCostControlProjectMode && y instanceof AddCostControlProjectMode) {
                return true;
            };
            if (x instanceof EditCostControlProjectMode && y instanceof EditCostControlProjectMode) {
                return true;
            };
            if (x instanceof AddEstimationProjectMode && y instanceof AddEstimationProjectMode) {
                return true;
            };
            if (x instanceof EditEstimationProjectMode && y instanceof EditEstimationProjectMode) {
                return true;
            };
            return false;
        };
    }
};
var copyCostGroupsFromProjectControl = TofuHooks.mkHookComponent("CopyCostGroupsFromProjectControl")(function (props) {
    var mkProjectLabel = function (r) {
        return Data_Newtype.unwrap()(r.code) + (", v." + (r.version + (" - " + r.name)));
    };
    var prepareProject = function (r) {
        return {
            id: Data_Show.show(Types_Project.showProjectId)(r.id),
            text: mkProjectLabel(r)
        };
    };
    var selected = (function () {
        if (props.copyCostGroupsFromProject instanceof Types_Project.DontCopyCostGroups) {
            return Data_Maybe.Nothing.value;
        };
        if (props.copyCostGroupsFromProject instanceof Types_Project.CopyCostGroupsFrom) {
            var project = Data_Array.find(function (p) {
                return Data_Eq.eq(Types_Project.eqProjectId)(p.id)(props.copyCostGroupsFromProject.value0);
            })(props.projects);
            return Data_Functor.map(Data_Maybe.functorMaybe)(prepareProject)(project);
        };
        throw new Error("Failed pattern match at ProjectComponents (line 719, column 7 - line 724, column 37): " + [ props.copyCostGroupsFromProject.constructor.name ]);
    })();
    return Control_Applicative.pure(Effect.applicativeEffect)(TacoAutocomplete.component()()({
        sections: [ {
            text: Data_Maybe.Nothing.value,
            list: Data_Functor.map(Data_Functor.functorArray)(prepareProject)(props.projects)
        } ],
        clearButton: true,
        onSelect: function (selected$prime) {
            if (selected$prime instanceof Data_Maybe.Nothing) {
                return props.setCopyCostGroupsFromProject(Types_Project.DontCopyCostGroups.value);
            };
            var v = function (v1) {
                if (selected$prime instanceof Data_Maybe.Just) {
                    return Effect_Class_Console.error(Effect_Class.monadEffectEffect)("Could not handle input: " + selected$prime.value0.id);
                };
                throw new Error("Failed pattern match at ProjectComponents (line 707, column 1 - line 707, column 84): " + [ selected$prime.constructor.name ]);
            };
            if (selected$prime instanceof Data_Maybe.Just) {
                var $82 = TofuJSON.readJSON_(TofuJSON.readInt)(selected$prime.value0.id);
                if ($82 instanceof Data_Maybe.Just) {
                    return props.setCopyCostGroupsFromProject(new Types_Project.CopyCostGroupsFrom($82.value0));
                };
                return v(true);
            };
            return v(true);
        },
        placeholder: "Valinnainen: valitse hanke",
        selected: selected,
        testId: "project-components-insert-cost-group"
    }));
});
var component = TofuHooks.mkHookComponent("ProjectComponents")(function (props) {
    var addForm = (function () {
        if (props.projectComponentsMode instanceof AddCostControlProjectMode) {
            return true;
        };
        if (props.projectComponentsMode instanceof AddEstimationProjectMode) {
            return true;
        };
        return false;
    })();
    var getNullable = function (getter) {
        return Control_Bind.join(Data_Maybe.bindMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Functor.map(Data_Functor.functorFn)(Data_Nullable.toMaybe)(getter))(props.mProject));
    };
    return function __do() {
        var publishFeatureEnabled = FeatureHooks.useFeatureEnabled("project-publish-report")();
        var v = UseUpdatingState.useUpdatingState(Data_Eq.eqString)(Data_Maybe.fromMaybe("")(Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
            return v.name;
        })(props.mProject)))();
        var v1 = UseUpdatingState.useUpdatingState(Data_Eq.eqString)(Data_Maybe.fromMaybe("")(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Functor.map(Data_Functor.functorFn)(Data_Newtype.un()(Types_Project.ProjectCode))(function (v1) {
            return v1.code;
        }))(props.mProject)))();
        var v2 = UseUpdatingState.useUpdatingState(Data_Eq.eqString)(Data_Maybe.fromMaybe("")(Data_Functor.map(Data_Maybe.functorMaybe)(function (v2) {
            return v2.version;
        })(props.mProject)))();
        var v3 = UseUpdatingState.useUpdatingState(Data_Eq.eqBoolean)(Data_Maybe.fromMaybe(false)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v3) {
            return v3.ownProject;
        })(props.mProject)))();
        var v4 = UseUpdatingState.useUpdatingState(Data_Eq.eqNumber)(Data_Maybe.fromMaybe(25.5)(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Newtype.un()(Types_Unit.Taxrate))(Control_Bind.join(Data_Maybe.bindMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v4) {
            return v4.taxRate;
        })(props.mProject)))))();
        var v5 = UseUpdatingState.useUpdatingState(Types_Classification.eqOrgClassificationCode)(Data_Maybe.fromMaybe("Talo 80")(Data_Functor.map(Data_Maybe.functorMaybe)(function (v5) {
            return v5.classification;
        })(props.mProject)))();
        var v6 = UseUpdatingState["useUpdatingState'"](Data_Maybe.eqMaybe(Data_Eq.eqString))([ props.mProject ])(getNullable(function (v6) {
            return v6.startDate;
        }))();
        var v7 = UseUpdatingState["useUpdatingState'"](Data_Maybe.eqMaybe(Data_Eq.eqString))([ props.mProject ])(getNullable(function (v7) {
            return v7.endDate;
        }))();
        var v8 = UseUpdatingState["useUpdatingState'"](Data_Maybe.eqMaybe(Data_Eq.eqString))([ props.mProject ])(Data_Functor.map(Data_Maybe.functorMaybe)(function (v8) {
            return v8.projectManager;
        })(props.mProject))();
        var v9 = UseUpdatingState["useUpdatingState'"](Data_Maybe.eqMaybe(Data_Eq.eqString))([ props.mProject ])(Control_Bind.join(Data_Maybe.bindMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(function (p) {
            var $96 = Data_String_Common["null"](p.projectGroup);
            if ($96) {
                return Data_Maybe.Nothing.value;
            };
            return new Data_Maybe.Just(p.projectGroup);
        })(props.mProject)))();
        var v10 = UseUpdatingState.useUpdatingState(Data_Eq.eqNumber)(Data_Maybe.fromMaybe(0.0)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v10) {
            return v10.contractPrice;
        })(props.mProject)))();
        var v11 = UseUpdatingState["useUpdatingState'"](Data_Maybe.eqMaybe(Data_Eq.eqString))([ props.mProject ])(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Functor.map(Data_Functor.functorFn)(projectStateValue)(function (v11) {
            return v11.state;
        }))(props.mProject))();
        var v12 = UseUpdatingState["useUpdatingState'"](Data_Maybe.eqMaybe(Data_Eq.eqString))([ props.mProject ])(Data_Functor.map(Data_Maybe.functorMaybe)(function (v12) {
            return v12.constructionType;
        })(props.mProject))();
        var v13 = UseUpdatingState["useUpdatingState'"](Data_Maybe.eqMaybe(Data_Eq.eqString))([ props.mProject ])(Data_Functor.map(Data_Maybe.functorMaybe)(function (v13) {
            return v13.financingType;
        })(props.mProject))();
        var v14 = UseUpdatingState["useUpdatingState'"](Data_Maybe.eqMaybe(Data_Eq.eqString))([ props.mProject ])(Data_Functor.map(Data_Maybe.functorMaybe)(function (v14) {
            return v14.projectType;
        })(props.mProject))();
        var v15 = UseUpdatingState["useUpdatingState'"](Data_Maybe.eqMaybe(Data_Eq.eqString))([ props.mProject ])(Data_Functor.map(Data_Maybe.functorMaybe)(function (v15) {
            return v15.contractType;
        })(props.mProject))();
        var v16 = UseUpdatingState["useUpdatingState'"](Data_Maybe.eqMaybe(Data_Eq.eqString))([ props.mProject ])(getNullable(function (v16) {
            return v16.address;
        }))();
        var v17 = UseUpdatingState["useUpdatingState'"](Data_Maybe.eqMaybe(Data_Eq.eqString))([ props.mProject ])(getNullable(function (v17) {
            return v17.postalCode;
        }))();
        var v18 = UseUpdatingState["useUpdatingState'"](Data_Maybe.eqMaybe(Data_Eq.eqString))([ props.mProject ])(getNullable(function (v18) {
            return v18.city;
        }))();
        var v19 = UseUpdatingState["useUpdatingState'"](Data_Maybe.eqMaybe(Data_Eq.eqString))([ props.mProject ])(Control_Alt.alt(Data_Maybe.altMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(StringUtils.decodeRTF)(getNullable(function (v19) {
            return v19.memo;
        })))(new Data_Maybe.Just("")))();
        var v20 = UseUpdatingState.useUpdatingState(Data_Eq.eqString)(Data_Maybe.fromMaybe("")(Data_Functor.map(Data_Maybe.functorMaybe)(function (v20) {
            return v20.area;
        })(props.mProject)))();
        var v21 = UseUpdatingState["useUpdatingState'"](Data_Maybe.eqMaybe(Types_Newtypes.eqCostClassCode))([ props.mProject ])(Data_Maybe.fromMaybe(Data_Maybe.Nothing.value)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v21) {
            return v21.defaultInvoiceCostClass;
        })(props.mProject)))();
        var v22 = UseUpdatingState["useUpdatingState'"](Data_Maybe.eqMaybe(Types_Newtypes.eqCostClassCode))([ props.mProject ])(Data_Maybe.fromMaybe(Data_Maybe.Nothing.value)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v22) {
            return v22.defaultSalaryCostClass;
        })(props.mProject)))();
        var v23 = UseUpdatingState["useUpdatingState'"](Data_Eq.eqInt)([ props.mProject ])(Data_Maybe.fromMaybe(0)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v23) {
            return v23.programType;
        })(props.mProject)))();
        var v24 = UseUpdatingState["useUpdatingState'"](Data_Eq.eqBoolean)([ props.mProject ])(Data_Maybe.fromMaybe(false)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v24) {
            return v24.isReportingProject;
        })(props.mProject)))();
        var v25 = UseUpdatingState["useUpdatingState'"](Data_Maybe.eqMaybe(Data_Eq.eqString))([ props.mProject ])(getNullable(function (v25) {
            return v25.offerDate;
        }))();
        var v26 = TofuHooks.useState(Types_Project.DontCopyCostGroups.value)();
        var v27 = UseUpdatingState.useUpdatingState(Data_Eq.eqString)(Data_Maybe.fromMaybe("")(props.mVATExclusionCostGroupsString))();
        var existingCodesAndVersions = getExistingCodesAndVersions(props.mProjectList)();
        TofuHooks.useEffect([ ReactHooksUtils.utf(v1.value0), ReactHooksUtils.utf(existingCodesAndVersions), ReactHooksUtils.utf(addForm) ])((function () {
            var latestVersion = getLatestVersionForCode(v1.value0)(existingCodesAndVersions);
            return function __do() {
                Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(addForm)((function () {
                    if (latestVersion instanceof Data_Maybe.Just) {
                        return v2.value1(function (v28) {
                            return Data_Show.show(Data_Show.showInt)(latestVersion.value0 + 1 | 0);
                        });
                    };
                    if (latestVersion instanceof Data_Maybe.Nothing) {
                        return v2.value1(function (v28) {
                            return Data_Show.show(Data_Show.showInt)(1);
                        });
                    };
                    throw new Error("Failed pattern match at ProjectComponents (line 175, column 7 - line 177, column 43): " + [ latestVersion.constructor.name ]);
                })())();
                return Data_Monoid.mempty(Effect.monoidEffect(Effect.monoidEffect(Data_Monoid.monoidUnit)))();
            };
        })())();
        var appDispatch = StateHooks.useDispatch();
        var prepareParams = (function () {
            var state = (function () {
                var v28 = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Data_Int.fromString)(v11.value0);
                if (v28 instanceof Data_Maybe.Just) {
                    return Data_Nullable.toNullable(new Data_Maybe.Just(v28.value0));
                };
                if (v28 instanceof Data_Maybe.Nothing) {
                    return Data_Nullable.toNullable(new Data_Maybe.Just(0));
                };
                throw new Error("Failed pattern match at ProjectComponents (line 212, column 11 - line 216, column 34): " + [ v28.constructor.name ]);
            })();
            return {
                name: v.value0,
                code: v1.value0,
                version: v2.value0,
                classification: v5.value0,
                ownProject: v3.value0,
                programType: v23.value0,
                taxRate: new Data_Maybe.Just(v4.value0),
                startDate: Data_Nullable.toNullable(v6.value0),
                endDate: Data_Nullable.toNullable(v7.value0),
                projectManager: Data_Nullable.toNullable(v8.value0),
                projectGroup: Data_Nullable.toNullable(v9.value0),
                contractPrice: v10.value0,
                state: state,
                constructionType: Data_Nullable.toNullable(v12.value0),
                financingType: Data_Nullable.toNullable(v13.value0),
                projectType: Data_Nullable.toNullable(v14.value0),
                contractType: Data_Nullable.toNullable(v15.value0),
                address: Data_Nullable.toNullable(v16.value0),
                postalCode: Data_Nullable.toNullable(v17.value0),
                memo: Data_Nullable.toNullable(v19.value0),
                area: v20.value0,
                city: Data_Nullable.toNullable(v18.value0),
                offerDate: Data_Nullable.toNullable(v25.value0)
            };
        })();
        var v28 = UseForm.useForm((function () {
            if (addForm) {
                var value = {
                    body: prepareParams,
                    copyCostGroupsFromProject: v26.value0,
                    enableCostControl: Data_Eq.eq(eqProjectComponentsMode)(props.projectComponentsMode)(AddCostControlProjectMode.value),
                    enableEstimation: Data_Eq.eq(eqProjectComponentsMode)(props.projectComponentsMode)(AddEstimationProjectMode.value),
                    currency: "EUR"
                };
                var action = new Actions.AddProjectRequest({
                    value: value
                });
                return appDispatch(action);
            };
            if (props.mProjectId instanceof Data_Maybe.Just) {
                var value = {
                    body: prepareParams,
                    defaultInvoiceCostClass: v21.value0,
                    defaultSalaryCostClass: v22.value0
                };
                var setVATExclusionsCostGroup = new Actions.VATExclusionCostGroupsActionRequest({
                    projectId: props.mProjectId.value0,
                    params: Types_CostGroup.SetVATExclusionCostGroupsAction.create(Types_CostGroup.fromCostGroupsListString(v27.value0))
                });
                var isProductionPlanningProject = Data_Maybe.fromMaybe(false)(Data_Functor.map(Data_Maybe.functorMaybe)(EstimationUtils.isProductionPlanning)(props.mProject));
                var initialIsReportingProject = Data_Maybe.fromMaybe(false)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v28) {
                    return v28.isReportingProject;
                })(props.mProject));
                var reportingAction = (function () {
                    if (isProductionPlanningProject) {
                        return ReportingNoChange.value;
                    };
                    if (!isProductionPlanningProject && (initialIsReportingProject && v24.value0)) {
                        return ReportingNoChange.value;
                    };
                    if (!isProductionPlanningProject && (!initialIsReportingProject && !v24.value0)) {
                        return ReportingNoChange.value;
                    };
                    if (!isProductionPlanningProject && (initialIsReportingProject && !v24.value0)) {
                        return ReportingUnpublish.value;
                    };
                    if (!isProductionPlanningProject && (!initialIsReportingProject && v24.value0)) {
                        return ReportingPublish.value;
                    };
                    throw new Error("Failed pattern match at ProjectComponents (line 246, column 33 - line 251, column 73): " + [ isProductionPlanningProject.constructor.name, initialIsReportingProject.constructor.name, v24.value0.constructor.name ]);
                })();
                var fromCostControl = Data_Eq.eq(eqProjectComponentsMode)(props.projectComponentsMode)(AddCostControlProjectMode.value) || Data_Eq.eq(eqProjectComponentsMode)(props.projectComponentsMode)(EditCostControlProjectMode.value);
                var action = new Actions.UpdateProjectRequest({
                    projectId: props.mProjectId.value0,
                    value: value,
                    fromCostControl: fromCostControl
                });
                return function __do() {
                    appDispatch(action)();
                    Data_Monoid.guard(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit)))(fromCostControl)(appDispatch)(setVATExclusionsCostGroup)();
                    if (reportingAction instanceof ReportingNoChange) {
                        return Data_Unit.unit;
                    };
                    if (reportingAction instanceof ReportingPublish) {
                        return appDispatch(new Actions.PublishToReportingRequest({
                            projectId: props.mProjectId.value0,
                            params: {},
                            handler: Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)))
                        }))();
                    };
                    if (reportingAction instanceof ReportingUnpublish) {
                        return appDispatch(new Actions.UnpublishReportingRequest({
                            projectId: props.mProjectId.value0,
                            params: {},
                            handler: Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)))
                        }))();
                    };
                    throw new Error("Failed pattern match at ProjectComponents (line 261, column 13 - line 274, column 20): " + [ reportingAction.constructor.name ]);
                };
            };
            if (props.mProjectId instanceof Data_Maybe.Nothing) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            };
            throw new Error("Failed pattern match at ProjectComponents (line 233, column 9 - line 276, column 31): " + [ props.mProjectId.constructor.name ]);
        })())();
        var versionField = TacoInput.stringInput()()(Data_Eq.eqString)({
            label: "Versio",
            validator: LengthValidator.lengthValidator(1)(3),
            required: true,
            value: v2.value0,
            onValidationChange: v28.updateFieldValidity("version"),
            error: v28.isFieldError("version"),
            onChange: v2.value1,
            testId: "project-version"
        });
        var vatExclusionCostGroupsField = (function () {
            if (props.projectComponentsMode instanceof EditCostControlProjectMode) {
                return TacoInput.stringInput()()(Data_Eq.eqString)({
                    label: "Litterat ilman ALV",
                    value: v27.value0,
                    onValidationChange: v28.updateFieldValidity("vatExclusionCostGroups"),
                    error: v28.isFieldError("vatExclusionCostGroups"),
                    onChange: v27.value1,
                    textarea: true,
                    testId: "project-vat-exclusion-cost-groups"
                });
            };
            return React_Basic.empty;
        })();
        var useReportingField = TacoCheckbox.component()()({
            labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Raportointi")),
            isChecked: v24.value0,
            onToggle: TacoCheckbox.NoEvent.create(v24.value1(function (v29) {
                return !v24.value0;
            })),
            testId: "project-details-checkbox-reporting"
        });
        var useReportingFieldFeatureFlag = Data_Monoid.guard(React_Basic.monoidJSX)(publishFeatureEnabled)(useReportingField);
        var useProductionPlanningField = TacoCheckbox.component()()({
            labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Tuotannonsuunnittelu")),
            isChecked: EstimationUtils.programTypeIsProductionPlanning(v23.value0),
            onToggle: TacoCheckbox.NoEvent.create(v23.value1(function (v29) {
                var $129 = v23.value0 === 4;
                if ($129) {
                    return 0;
                };
                return 4;
            })),
            testId: "project-details-checkbox-production-planning"
        });
        var taxRateField = TacoInput.numberInput()()(Data_Eq.eqNumber)({
            label: "ALV-%",
            value: v4.value0,
            validator: NumberValidator.numberRangeValidator(0.0)(100.0),
            onValidationChange: v28.updateFieldValidity("taxRate"),
            error: v28.isFieldError("taxRate"),
            precision: 2,
            onChange: v4.value1,
            testId: "project-tax-rate"
        });
        var submitBtn = TacoButton.component()()({
            text: (function () {
                if (props.projectComponentsMode instanceof EditCostControlProjectMode) {
                    return "Tallenna muutokset";
                };
                if (props.projectComponentsMode instanceof EditEstimationProjectMode) {
                    return "Tallenna muutokset";
                };
                if (props.projectComponentsMode instanceof AddCostControlProjectMode) {
                    return "Lis\xe4\xe4 hanke";
                };
                if (props.projectComponentsMode instanceof AddEstimationProjectMode) {
                    return "Lis\xe4\xe4 hanke";
                };
                throw new Error("Failed pattern match at ProjectComponents (line 283, column 17 - line 287, column 54): " + [ props.projectComponentsMode.constructor.name ]);
            })(),
            buttonStyle: TacoButton_Types.Outlined.value,
            submit: true,
            testId: "submit-project-button"
        });
        var ownTaxRate = function (rate) {
            var ownTaxRateField = TacoInput.stringInput()()(Data_Eq.eqString)({
                value: Data_Maybe.fromMaybe("-")(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Number_Format.toString)(rate)),
                label: Locale.lookup_("own_tax_rate"),
                disabled: true,
                testId: "project-own-tax-rate"
            });
            return Grid.grid([ new Box.Gap(TacoConstants.S.value), Box.AlignStart.value ])([ [ new Grid.Auto(ownTaxRateField) ] ]);
        };
        var ownProjectCheckbox = TacoCheckbox.component()()({
            className: "project-details-checkbox",
            labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Oma kohde")),
            isChecked: v3.value0,
            onToggle: TacoCheckbox.NoEvent.create(v3.value1(function (state) {
                return !state;
            })),
            testId: "project-own-project-checkbox"
        });
        var ownProjectCheckbox$prime = (function () {
            if (props.projectComponentsMode instanceof AddCostControlProjectMode) {
                return ownProjectCheckbox;
            };
            if (props.projectComponentsMode instanceof EditCostControlProjectMode) {
                return ownProjectCheckbox;
            };
            if (props.projectComponentsMode instanceof AddEstimationProjectMode) {
                return React_Basic.empty;
            };
            if (props.projectComponentsMode instanceof EditEstimationProjectMode) {
                return React_Basic.empty;
            };
            throw new Error("Failed pattern match at ProjectComponents (line 293, column 27 - line 297, column 44): " + [ props.projectComponentsMode.constructor.name ]);
        })();
        var optionalInfoTitle = function (v29) {
            return TacoText.component()()({
                text: "Valinnaiset tiedot",
                weight: TacoText_Types.Bold.value,
                gutterBottom: v29.gutterBottom,
                variant: new TacoText_Types.Heading(2)
            });
        };
        var nameField = TacoInput.stringInput()()(Data_Eq.eqString)({
            label: "Nimi",
            validator: LengthValidator.lengthValidator(3)(50),
            value: v.value0,
            onValidationChange: v28.updateFieldValidity("name"),
            error: v28.isFieldError("name"),
            onChange: v.value1,
            testId: "project-name"
        });
        var mkItems = function (values) {
            var mkNullItem = {
                value: "",
                text: Locale.lookup_("not_chosen")
            };
            var mkItem = function (value) {
                return {
                    value: value,
                    text: value
                };
            };
            return Data_Array.cons(mkNullItem)(Data_Functor.map(Data_Functor.functorArray)(mkItem)(values));
        };
        var projectGroupField = (function () {
            if (props.mProjectGroups instanceof Data_Maybe.Just) {
                return TacoSelect.component()()({
                    label: "Hankeryhm\xe4",
                    value: Data_Maybe.fromMaybe("")(v9.value0),
                    items: mkItems(Data_Functor.map(Data_Functor.functorArray)(Data_Newtype.unwrap())(props.mProjectGroups.value0)),
                    onChange: TacoInput.transformInput(TacoInput.inputValueString)(Data_Maybe.Just.create)(v9.value1),
                    testId: "project-group"
                });
            };
            if (props.mProjectGroups instanceof Data_Maybe.Nothing) {
                return loader;
            };
            throw new Error("Failed pattern match at ProjectComponents (line 405, column 25 - line 413, column 24): " + [ props.mProjectGroups.constructor.name ]);
        })();
        var formOptional = (function () {
            var startDateField = TacoInput.stringInput()()(Data_Eq.eqString)({
                label: "Aloitusp\xe4iv\xe4",
                type: "date",
                clickEventHandling: TacoInput.NoHandling.value,
                value: Data_String_CodePoints.take(10)(Data_Maybe.fromMaybe("")(v6.value0)),
                onChange: TacoInput.transformInput(TacoInput.inputValueString)(Data_Maybe.Just.create)(v6.value1),
                testId: "project-start-date"
            });
            var selectProjectStateField = TacoSelect.component()()({
                label: "Hankkeen tila",
                value: Data_Maybe.fromMaybe("")(v11.value0),
                items: projectStateOptions,
                onChange: TacoInput.transformInput(TacoInput.inputValueString)(Data_Maybe.Just.create)(v11.value1),
                testId: "project-state"
            });
            var projectTypeField = (function () {
                if (props.mProjectTypes instanceof Data_Maybe.Just) {
                    return TacoSelect.component()()({
                        label: Locale.lookup_("common_project_type"),
                        value: Data_Maybe.fromMaybe("")(v14.value0),
                        items: mkItems(Data_Functor.map(Data_Functor.functorArray)(Data_Newtype.unwrap())(props.mProjectTypes.value0)),
                        onChange: TacoInput.transformInput(TacoInput.inputValueString)(Data_Maybe.Just.create)(v14.value1),
                        testId: "project-type"
                    });
                };
                if (props.mProjectTypes instanceof Data_Maybe.Nothing) {
                    return loader;
                };
                throw new Error("Failed pattern match at ProjectComponents (line 500, column 28 - line 508, column 28): " + [ props.mProjectTypes.constructor.name ]);
            })();
            var projectManagerField = (function () {
                if (props.mProjectManagers instanceof Data_Maybe.Just) {
                    return TacoSelect.component()()({
                        label: "Projektip\xe4\xe4likk\xf6",
                        value: Data_Maybe.fromMaybe("")(v8.value0),
                        items: mkItems(Data_Functor.map(Data_Functor.functorArray)(Data_Newtype.unwrap())(props.mProjectManagers.value0)),
                        onChange: TacoInput.transformInput(TacoInput.inputValueString)(Data_Maybe.Just.create)(v8.value1),
                        testId: "project-manager"
                    });
                };
                if (props.mProjectManagers instanceof Data_Maybe.Nothing) {
                    return loader;
                };
                throw new Error("Failed pattern match at ProjectComponents (line 454, column 31 - line 462, column 28): " + [ props.mProjectManagers.constructor.name ]);
            })();
            var postalCodeField = TacoInput.stringInput()()(Data_Eq.eqString)({
                label: Locale.lookup_("postal_code"),
                value: Data_Maybe.fromMaybe("")(v17.value0),
                validator: LengthValidator.lengthValidator(0)(6),
                error: v28.isFieldError("postalCode"),
                onValidationChange: v28.updateFieldValidity("postalCode"),
                onChange: TacoInput.transformInput(TacoInput.inputValueString)(Data_Maybe.Just.create)(v17.value1),
                testId: "project-postal-code"
            });
            var memoField = TacoInput.stringInput()()(Data_Eq.eqString)({
                label: Locale.lookup_("memo"),
                textarea: true,
                value: Data_Maybe.fromMaybe("")(v19.value0),
                onChange: TacoInput.transformInput(TacoInput.inputValueString)(Data_Maybe.Just.create)(v19.value1),
                testId: "project-memo"
            });
            var financingTypeField = (function () {
                if (props.mFinancingTypes instanceof Data_Maybe.Just) {
                    return TacoSelect.component()()({
                        label: Locale.lookup_("common_financing_type"),
                        value: Data_Maybe.fromMaybe("")(v13.value0),
                        items: mkItems(Data_Functor.map(Data_Functor.functorArray)(Data_Newtype.unwrap())(props.mFinancingTypes.value0)),
                        onChange: TacoInput.transformInput(TacoInput.inputValueString)(Data_Maybe.Just.create)(v13.value1),
                        testId: "project-financing-type"
                    });
                };
                if (props.mFinancingTypes instanceof Data_Maybe.Nothing) {
                    return loader;
                };
                throw new Error("Failed pattern match at ProjectComponents (line 490, column 30 - line 498, column 28): " + [ props.mFinancingTypes.constructor.name ]);
            })();
            var endDateField = TacoInput.stringInput()()(Data_Eq.eqString)({
                label: "Lopetusp\xe4iv\xe4",
                type: "date",
                clickEventHandling: TacoInput.NoHandling.value,
                value: Data_String_CodePoints.take(10)(Data_Maybe.fromMaybe("")(v7.value0)),
                onChange: TacoInput.transformInput(TacoInput.inputValueString)(Data_Maybe.Just.create)(v7.value1),
                testId: "project-end-date"
            });
            var defaultSalaryCostClassField = (function () {
                if (props.projectComponentsMode instanceof EditCostControlProjectMode) {
                    return TacoInput.stringInput()()(Data_Eq.eqString)({
                        label: "Palkan oletus kustannuslaji",
                        value: Data_Maybe.maybe("")(Data_Newtype.unwrap())(v22.value0),
                        validator: LengthValidator.lengthValidator(0)(2),
                        onChange: TacoInput.transformInput(TacoInput.inputValueString)((function () {
                            var $232 = Data_Newtype.wrap();
                            return function ($233) {
                                return Data_Maybe.Just.create($232($233));
                            };
                        })())(v22.value1),
                        testId: "project-default-salary-cost-class"
                    });
                };
                return React_Basic.empty;
            })();
            var defaultInvoiceCostClassField = (function () {
                if (props.projectComponentsMode instanceof EditCostControlProjectMode) {
                    return TacoInput.stringInput()()(Data_Eq.eqString)({
                        label: "Laskun oletus kustannuslaji",
                        value: Data_Maybe.maybe("")(Data_Newtype.unwrap())(v21.value0),
                        validator: LengthValidator.lengthValidator(0)(2),
                        onChange: TacoInput.transformInput(TacoInput.inputValueString)((function () {
                            var $234 = Data_Newtype.wrap();
                            return function ($235) {
                                return Data_Maybe.Just.create($234($235));
                            };
                        })())(v21.value1),
                        testId: "project-default-invoice-cost-class"
                    });
                };
                return React_Basic.empty;
            })();
            var copyCostGroupsField = (function () {
                if (props.projectComponentsMode instanceof EditCostControlProjectMode) {
                    return React_Basic.empty;
                };
                if (props.projectComponentsMode instanceof EditEstimationProjectMode) {
                    return React_Basic.empty;
                };
                if (props.projectComponentsMode instanceof AddEstimationProjectMode) {
                    return React_Basic.empty;
                };
                if (props.projectComponentsMode instanceof AddCostControlProjectMode && props.mProjectList instanceof Data_Maybe.Just) {
                    return ReactBasicUtils["div'"]("form-row")([ React_Basic_DOM_Generated.label_([ React_Basic_DOM.text("Kopioi litterat hankkeelta") ]), copyCostGroupsFromProjectControl({
                        copyCostGroupsFromProject: v26.value0,
                        projects: props.mProjectList.value0,
                        setCopyCostGroupsFromProject: function (value) {
                            return v26.value1(function (v29) {
                                return value;
                            });
                        }
                    }) ]);
                };
                return loader;
            })();
            var contractTypeField = (function () {
                if (props.mContractTypes instanceof Data_Maybe.Just) {
                    return TacoSelect.component()()({
                        label: Locale.lookup_("common_contract_type"),
                        value: Data_Maybe.fromMaybe("")(v15.value0),
                        items: mkItems(Data_Functor.map(Data_Functor.functorArray)(Data_Newtype.unwrap())(props.mContractTypes.value0)),
                        onChange: TacoInput.transformInput(TacoInput.inputValueString)(Data_Maybe.Just.create)(v15.value1),
                        testId: "project-contract-type"
                    });
                };
                if (props.mContractTypes instanceof Data_Maybe.Nothing) {
                    return loader;
                };
                throw new Error("Failed pattern match at ProjectComponents (line 510, column 29 - line 518, column 28): " + [ props.mContractTypes.constructor.name ]);
            })();
            var contractPriceField = TacoInput.numberInput()()(Data_Eq.eqNumber)({
                label: Locale.lookup_("common_contract_price"),
                value: v10.value0,
                onChange: v10.value1,
                precision: 2,
                testId: "project-contract-price"
            });
            var constructionTypeField = (function () {
                if (props.mConstructionTypes instanceof Data_Maybe.Just) {
                    return TacoSelect.component()()({
                        label: Locale.lookup_("common_construction_type"),
                        value: Data_Maybe.fromMaybe("")(v12.value0),
                        items: mkItems(Data_Functor.map(Data_Functor.functorArray)(Data_Newtype.unwrap())(props.mConstructionTypes.value0)),
                        onChange: TacoInput.transformInput(TacoInput.inputValueString)(Data_Maybe.Just.create)(v12.value1),
                        testId: "project-construction-type"
                    });
                };
                if (props.mConstructionTypes instanceof Data_Maybe.Nothing) {
                    return loader;
                };
                throw new Error("Failed pattern match at ProjectComponents (line 480, column 33 - line 488, column 28): " + [ props.mConstructionTypes.constructor.name ]);
            })();
            var cityField = TacoInput.stringInput()()(Data_Eq.eqString)({
                label: Locale.lookup_("city"),
                value: Data_Maybe.fromMaybe("")(v18.value0),
                validator: LengthValidator.lengthValidator(0)(30),
                error: v28.isFieldError("city"),
                onValidationChange: v28.updateFieldValidity("city"),
                onChange: TacoInput.transformInput(TacoInput.inputValueString)(Data_Maybe.Just.create)(v18.value1),
                testId: "project-city"
            });
            var areaField = (function () {
                if (props.mOrgAddressArea instanceof Data_Maybe.Just && Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Array["null"])(props.mOrgAddressArea.value0)) {
                    return TacoSelect.component()()({
                        label: "Alue",
                        value: v20.value0,
                        items: Data_Functor.mapFlipped(Data_Functor.functorArray)(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ "" ])(props.mOrgAddressArea.value0))(function (value) {
                            return {
                                value: value,
                                text: value
                            };
                        }),
                        onChange: v20.value1,
                        testId: "project-area"
                    });
                };
                return React_Basic.empty;
            })();
            var addressField = TacoInput.stringInput()()(Data_Eq.eqString)({
                label: Locale.lookup_("address"),
                value: Data_Maybe.fromMaybe("")(v16.value0),
                validator: LengthValidator.lengthValidator(0)(50),
                error: v28.isFieldError("address"),
                onValidationChange: v28.updateFieldValidity("address"),
                onChange: TacoInput.transformInput(TacoInput.inputValueString)(Data_Maybe.Just.create)(v16.value1),
                testId: "project-address"
            });
            return Grid.grid([ new Box.Gap(TacoConstants.S.value), Box.AlignStart.value ])([ [ new Grid.Auto(React_Basic.empty) ], [ new Grid.Auto(startDateField), new Grid.Auto(endDateField) ], [ new Grid.Auto(projectManagerField), new Grid.Auto(projectGroupField) ], [ new Grid.Auto(selectProjectStateField) ], [ new Grid.Auto(contractPriceField) ], [ new Grid.Auto(constructionTypeField) ], [ new Grid.Auto(financingTypeField) ], [ new Grid.Auto(projectTypeField) ], [ new Grid.Auto(contractTypeField) ], [ new Grid.Auto(addressField) ], [ new Grid.Auto(postalCodeField), new Grid.Auto(cityField) ], [ new Grid.Auto(memoField) ], [ new Grid.Auto(copyCostGroupsField) ], [ new Grid.Auto(defaultInvoiceCostClassField) ], [ new Grid.Auto(defaultSalaryCostClassField) ], [ new Grid.Auto(areaField) ] ]);
        })();
        var codeField = TacoInput.stringInput()()(Data_Eq.eqString)({
            label: "Tunnus",
            validator: LengthValidator.lengthValidator(1)(12),
            required: true,
            value: v1.value0,
            onValidationChange: v28.updateFieldValidity("code"),
            error: v28.isFieldError("code"),
            onChange: v1.value1,
            testId: "project-code"
        });
        var estimationFormChilds = function (projectId) {
            return Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic.fragment([ Grid.grid([ new Box.Gap(TacoConstants.S.value), Box.AlignStart.value ])([ [ new Grid.Auto(nameField) ], [ new Grid.Flex(6, codeField), Grid.Flex.create(3)(React_Basic_DOM_Generated.div_([  ])), new Grid.Flex(3, versionField) ], [ new Grid.Auto(projectGroupField) ], [ new Grid.Flex(3, taxRateField), Grid.Flex.create(9)(React_Basic_DOM_Generated.div_([  ])) ], [ new Grid.Flex(3, useProductionPlanningField), new Grid.Flex(3, useReportingFieldFeatureFlag) ], [ Grid.Auto.create(optionalInfoTitle({
                gutterBottom: false
            })) ], [ new Grid.Auto(formOptional) ], [ new Grid.Auto(submitBtn) ], [ Grid.Auto.create(Project_EditCostClasses.component({
                projectId: projectId
            })) ] ]) ]));
        };
        var classificationItems = function (classifications) {
            var getItem = function (item) {
                return {
                    value: item.name,
                    text: item.name
                };
            };
            return Data_Functor.map(Data_Functor.functorArray)(getItem)(classifications);
        };
        var classificationField = (function () {
            if (props.mClassifications instanceof Data_Maybe.Just) {
                return TacoSelect.component()()({
                    label: "Nimikkeist\xf6",
                    value: Data_Newtype.un()(Types_Classification.OrgClassificationCode)(v5.value0),
                    items: classificationItems(props.mClassifications.value0),
                    disabled: props.disableClassifications,
                    onChange: TacoInput.transformInput(TacoInput.inputValueString)(Types_Classification.OrgClassificationCode)(v5.value1),
                    testId: "project-classifications"
                });
            };
            if (props.mClassifications instanceof Data_Maybe.Nothing) {
                return loader;
            };
            throw new Error("Failed pattern match at ProjectComponents (line 350, column 27 - line 359, column 24): " + [ props.mClassifications.constructor.name ]);
        })();
        var formMandatory = Grid.grid([ new Box.Gap(TacoConstants.S.value), Box.AlignStart.value ])([ [ new Grid.Auto(nameField) ], [ new Grid.Flex(6, codeField), Grid.Flex.create(3)(React_Basic_DOM_Generated.div_([  ])), new Grid.Flex(3, versionField) ], [ new Grid.Auto(classificationField) ], [ new Grid.Auto(ownProjectCheckbox$prime) ], [ new Grid.Auto(React_Basic.empty) ], [ new Grid.Flex(3, taxRateField), Grid.Flex.create(9)(React_Basic_DOM_Generated.div_([  ])) ], [ new Grid.Auto(vatExclusionCostGroupsField) ] ]);
        var editFormChilds = Control_Applicative.pure(Control_Applicative.applicativeArray)(Box.box("__EditFormChildren")([ new Box.Gap(TacoConstants.M.value), Box.GridRow.value ])([ formMandatory, ownTaxRate(Control_Bind.join(Data_Maybe.bindMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Functor.map(Data_Functor.functorFn)(Data_Nullable.toMaybe)(function (v29) {
            return v29.ownTaxRate;
        }))(props.mProject))), formOptional, submitBtn ]));
        var addFormChilds = [ TacoText.component()()({
            text: "Pakolliset tiedot",
            weight: TacoText_Types.Bold.value,
            gutterBottom: true,
            variant: new TacoText_Types.Heading(2)
        }), formMandatory, React_Basic_DOM_Generated.br()({}), React_Basic_DOM_Generated.br()({}), optionalInfoTitle({
            gutterBottom: true
        }), formOptional, React_Basic_DOM_Generated.br()({}), submitBtn ];
        var formChilds = (function () {
            if (props.projectComponentsMode instanceof AddCostControlProjectMode) {
                return addFormChilds;
            };
            if (props.projectComponentsMode instanceof AddEstimationProjectMode) {
                return addFormChilds;
            };
            if (props.projectComponentsMode instanceof EditCostControlProjectMode) {
                return editFormChilds;
            };
            if (props.projectComponentsMode instanceof EditEstimationProjectMode && props.mProjectId instanceof Data_Maybe.Just) {
                return estimationFormChilds(props.mProjectId.value0);
            };
            if (props.projectComponentsMode instanceof EditEstimationProjectMode && props.mProjectId instanceof Data_Maybe.Nothing) {
                return [  ];
            };
            throw new Error("Failed pattern match at ProjectComponents (line 686, column 18 - line 691, column 47): " + [ props.projectComponentsMode.constructor.name, props.mProjectId.constructor.name ]);
        })();
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["form'"]())({
            className: "__ProjectComponents",
            css: ProjectComponents_Styles.projectComponentsContainerStyles,
            children: formChilds,
            onSubmit: v28.onSubmit,
            ref: v28.formRef
        });
    };
});
module.exports = {
    AddCostControlProjectMode: AddCostControlProjectMode,
    EditCostControlProjectMode: EditCostControlProjectMode,
    AddEstimationProjectMode: AddEstimationProjectMode,
    EditEstimationProjectMode: EditEstimationProjectMode,
    component: component,
    getExistingCodesAndVersions: getExistingCodesAndVersions,
    getLatestVersionForCode: getLatestVersionForCode,
    eqProjectComponentsMode: eqProjectComponentsMode
};
