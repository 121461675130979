// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Effect = require("../Effect/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var UseCallbackRef = require("../UseCallbackRef/index.js");
var Utils_Keyboard = require("../Utils.Keyboard/index.js");
var Utils_Listeners = require("../Utils.Listeners/index.js");
var Web_HTML_HTMLElement = require("../Web.HTML.HTMLElement/index.js");
var useDismissOverlay = function (isActive) {
    return function (onClose) {
        return function (key) {
            return function __do() {
                var v = TofuHooks.useState(Data_Maybe.Nothing.value)();
                var callbackRef = UseCallbackRef.useCallbackRef([  ])(function (node$prime) {
                    return v.value1(function (v1) {
                        return node$prime;
                    });
                })();
                TofuHooks.useEffect([ ReactHooksUtils.utf(isActive), ReactHooksUtils.utf(v.value0) ])((function () {
                    var maybeElement = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Web_HTML_HTMLElement.fromNode)(v.value0);
                    if (maybeElement instanceof Data_Maybe.Just && isActive) {
                        return function __do() {
                            var listener = Utils_Listeners.addMouseClickEventListenerToDocument_(maybeElement.value0)(onClose)();
                            return Utils_Listeners.removeMouseClickEventListenerFromDocument(listener);
                        };
                    };
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)));
                })())();
                TofuHooks.useEffect([ isActive ])((function () {
                    if (isActive) {
                        return function __do() {
                            Utils_Keyboard.registerKeyHandler([ Utils_Keyboard.AllKeys.value ])(key)(function (event) {
                                return Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(Data_Eq.eq(Utils_Keyboard.eqKeyboardKey)(event.key)(Utils_Keyboard.Escape.value))(onClose);
                            })();
                            return Utils_Keyboard.deregisterKeyHandler(key);
                        };
                    };
                    if (!isActive) {
                        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)));
                    };
                    throw new Error("Failed pattern match at UseDismissOverlay (line 37, column 5 - line 44, column 27): " + [ isActive.constructor.name ]);
                })())();
                return {
                    callbackRef: callbackRef
                };
            };
        };
    };
};
module.exports = {
    useDismissOverlay: useDismissOverlay
};
