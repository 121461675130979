import { Atom, atom } from "jotai";
import { AdminOrganizationInfo } from "ts-bindings/AdminOrganizationInfo";
import { AdminUserTableRow } from "ts-bindings/AdminUserTableRow";
import { ComponentWithResources } from "ts-bindings/ComponentWithResources";
import { CostClass } from "ts-bindings/CostClass";
import { EstimationComponent } from "ts-bindings/EstimationComponent";
import { EstimationProjectSummary } from "ts-bindings/EstimationProjectSummary";
import { PaginatedResult } from "ts-bindings/PaginatedResult";
import { PlanningCostGroupData } from "ts-bindings/PlanningCostGroupData";
import { ProjectDetailsData } from "ts-bindings/ProjectDetailsData";
import { RoleIdByName } from "ts-bindings/RoleIdByName";
import { StateNode } from "ts-bindings/StateNode";
import { UpdateActionState } from "ts-bindings/UpdateActionState";
import { User } from "ts-bindings/User";
import { UserAppAccessRights } from "ts-bindings/UserAppAccessRights";
import { UserOrganizations } from "ts-bindings/UserOrganizations";
import { UserRoleAssignmentWithUserId } from "ts-bindings/UserRoleAssignmentWithUserId";
import { UserRoleWithId } from "ts-bindings/UserRoleWithId";
import { NoExtraProperties } from "./typescript";
import { SuperAdminUser } from "../../../ts-bindings/SuperAdminUser";
import { SuperAdminStatus } from "../../../ts-bindings/SuperAdminStatus";
import { Measurement } from "ts-bindings/Measurement";
import { Subproject } from "../../../ts-bindings/Subproject";
import { ProjectClassificationClass } from "../../../ts-bindings/ProjectClassificationClass";
import { OrgClassification } from "../../../ts-bindings/OrgClassification";
import { OrgCostClass } from "../../../ts-bindings/OrgCostClass";
import { OrgMeasurement } from "../../../ts-bindings/OrgMeasurement";
import { CurrentOrganization } from "../../../ts-bindings/CurrentOrganization";
import { CostGroupClassification } from "../../../ts-bindings/CostGroupClassification";
import { CostControlCostGroup } from "../../../ts-bindings/CostControlCostGroup";
import { CostControlComponents } from "../../../ts-bindings/CostControlComponents";
import { CostControlResources } from "../../../ts-bindings/CostControlResources";
import { CostControlItem } from "../../../ts-bindings/CostControlItem";
import { CostControlInvoices } from "../../../ts-bindings/CostControlInvoices";
import { SuperAdminOrganizationsQuotas } from "../../../ts-bindings/SuperAdminOrganizationsQuotas";
import { MainCostGroup } from "../../../ts-bindings/MainCostGroup";
import { ProjectClassificationData } from "../../../ts-bindings/ProjectClassificationData";
import { ElementsData } from "../../../ts-bindings/ElementsData";
import { ElementSpec } from "../../../ts-bindings/ElementSpec";
import { ClassificationGroup } from "../../../ts-bindings/ClassificationGroup";
import { ProjectLocation } from "../../../ts-bindings/ProjectLocation";

/**
 * Contains every possible StateNode in a `{ key?: Atom<StateNode> }` dictionary.
 *
 * Basic idea from here: https://stackoverflow.com/a/61540223
 */
type StateNodeAtoms = Partial<
  {
    [P in StateNode["type"]]: Atom<Extract<StateNode, { type: P }>["value"]>;
  }
>;

/**
 * Initializes a jotai atom of given type.
 *
 * @returns the created Atom object.
 */
const mkAtom = <T>(): Atom<T | null> => atom<T | null>(null);

/**
 * List of configured StateNodes.
 *
 * Each listed node will be automatically subscribed to and available for
 * consumption with the useWorkerState hook. Note that the key must match an
 * existing StateNode's key in `wasm-worker/src/state.rs` or you will get a
 * type error below at compile time.
 */
export const stateNodeAtoms = {
  AccessTokenState: mkAtom<string>(),

  // App access rights
  UserAppAccessRightsState: mkAtom<UserAppAccessRights>(),

  // Navigation
  NavigationActionState: mkAtom<UpdateActionState>(),
  ChangeOrganizationState: mkAtom<UpdateActionState>(),

  // Current action status
  AdminUpdateActionState: mkAtom<UpdateActionState>(),
  ComponentUpdateActionState: mkAtom<UpdateActionState>(),
  ProjectUpdateActionState: mkAtom<UpdateActionState>(),
  IntegrationUpdateActionState: mkAtom<UpdateActionState>(),
  ProjectFormUpdateActionState: mkAtom<UpdateActionState>(),
  PublishToReportingActionState: mkAtom<UpdateActionState>(),
  PostTransportFileActionState: mkAtom<UpdateActionState>(),
  UpdateEstimationProjectsState: mkAtom<UpdateActionState>(),

  // Admin
  AdminUsersState: mkAtom<User[]>(),
  AdminOrganizationInfoState: mkAtom<AdminOrganizationInfo>(),
  AdminRolesState: mkAtom<UserRoleWithId[]>(),
  AdminRoleAssignmentsState: mkAtom<UserRoleAssignmentWithUserId[]>(),
  AdminRoleIdByNameState: mkAtom<RoleIdByName>(),
  // AdminRoleNameByIdSate: mkAtom<RoleNameById>(),
  AdminUserTableState: mkAtom<AdminUserTableRow[]>(),
  AdminProjectGroupsState: mkAtom<string[]>(),

  // SuperAdmin
  SuperAdminUsersState: mkAtom<SuperAdminUser[]>(),
  SuperAdminOrganizations: mkAtom<SuperAdminOrganizationsQuotas[]>(),
  SuperAdminUpdateActionState: mkAtom<UpdateActionState>(),
  SuperAdminGetErrorMsg: mkAtom<SuperAdminStatus>(),

  // Project independent HTTP endpoint nodes
  EstimationProjectsState: mkAtom<EstimationProjectSummary[]>(),
  CostControlProjectsState: mkAtom<EstimationProjectSummary[]>(),
  UserOrganizationsState: mkAtom<UserOrganizations>(),
  GetCurrentOrganizationState: mkAtom<CurrentOrganization>(),
  CopyProjectUpdateActionState: mkAtom<UpdateActionState>(),

  // Project HTTP endpoint nodes
  EstimationComponentsState: mkAtom<EstimationComponent[]>(),
  ProjectDetailsDataState: mkAtom<ProjectDetailsData>(),
  ProjectLocationsState: mkAtom<ProjectLocation[]>(),
  EstimationCostClassesState: mkAtom<CostClass[]>(),
  EstimationSubprojectsState: mkAtom<Subproject[]>(),
  MeasurementsState: mkAtom<Measurement[]>(),
  ProjectClassificationClassesState: mkAtom<ProjectClassificationClass[]>(),
  ProjectClassificationDataState: mkAtom<ProjectClassificationData[]>(),
  ProjectClassificationDataWithoutSocialExpensesState: mkAtom<
    ProjectClassificationData[]
  >(),
  UnfilteredSepProjectClassificationDataState: mkAtom<
    ProjectClassificationData[]
  >(),
  ProjectTypesState: mkAtom<string[]>(),
  ProjectManagersState: mkAtom<string[]>(),
  ConstructionTypesState: mkAtom<string[]>(),
  FinancingTypesState: mkAtom<string[]>(),
  ContractTypesState: mkAtom<string[]>(),
  ClassificationsState: mkAtom<OrgClassification[]>(),
  DefaultClassificationState: mkAtom<OrgClassification[]>(),
  OrgCostClassesState: mkAtom<OrgCostClass[]>(),
  OrgMeasurementsState: mkAtom<OrgMeasurement[]>(),
  CostGroupClassificationsState: mkAtom<CostGroupClassification[]>(),
  CostControlCostGroupState: mkAtom<CostControlCostGroup[]>(),
  CostControlCostClassesState: mkAtom<CostClass[]>(),
  CostControlTargetItemsState: mkAtom<CostControlItem[]>(),
  CostControlEstimateItemsState: mkAtom<CostControlItem[]>(),
  CostControlComponentsState: mkAtom<CostControlComponents[]>(),
  CostControlResourcesState: mkAtom<CostControlResources[]>(),
  CostControlInvoicesState: mkAtom<CostControlInvoices[]>(),
  MainCostGroupsState: mkAtom<Record<string, MainCostGroup>>(),
  ElementsDataState: mkAtom<ElementsData[]>(),
  EstimationElementSpecsState: mkAtom<ElementSpec[]>(),
  EstimationClassificationGroupsState: mkAtom<ClassificationGroup[]>(),

  // Computed state nodes
  ComponentsWithResourcesState: mkAtom<ComponentWithResources[]>(),
  ComponentCostGroupsDataState: mkAtom<PlanningCostGroupData[]>(),

  // Paginated data nodes
  PaginatedEstimationComponentsState: mkAtom<
    PaginatedResult<EstimationComponent>
  >(),
  PaginatedComponentsWithResourcesState: mkAtom<
    PaginatedResult<ComponentWithResources>
  >(),
};

/**
 * Emit some (pretty terrible) compilation errors if above stateNodeAtoms
 * contains incorrect types.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const checkStateNodeAtoms: NoExtraProperties<
  typeof stateNodeAtoms,
  StateNodeAtoms
> = stateNodeAtoms;

/**
 * Request params that were used to compute current state.
 */
export const workerStateRequestParams: {
  [key: string]: unknown;
} = Object.fromEntries(
  Object.keys(stateNodeAtoms).map((key) => {
    return [key, null];
  })
);

/**
 * Unpack T from an Atom<T>
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type UnAtom<T> = T extends Atom<infer T> ? T : never;

/**
 * Contains each configured StateNode in a `{ key: StateNode }` dictionary.
 */
export type StateNodes = {
  [K in keyof typeof stateNodeAtoms]: UnAtom<typeof stateNodeAtoms[K]>;
};
