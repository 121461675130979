// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var ArrayUtils = require("../ArrayUtils/index.js");
var ClientLogger = require("../ClientLogger/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Control_Parallel = require("../Control.Parallel/index.js");
var DOMUtils = require("../DOMUtils/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Class_Console = require("../Effect.Class.Console/index.js");
var Effect_Exception = require("../Effect.Exception/index.js");
var FRP_Event = require("../FRP.Event/index.js");
var FetchEstimationProjectExport = require("../FetchEstimationProjectExport/index.js");
var FetchExcel = require("../FetchExcel/index.js");
var FetchZip = require("../FetchZip/index.js");
var Locale = require("../Locale/index.js");
var RequestUtils = require("../RequestUtils/index.js");
var RouteUtils = require("../RouteUtils/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_Alert = require("../Types.Alert/index.js");
var Types_FrontEndRoutes = require("../Types.FrontEndRoutes/index.js");
var Types_Project = require("../Types.Project/index.js");
var Types_ServerRoutes = require("../Types.ServerRoutes/index.js");
var Types_TypeString = require("../Types.TypeString/index.js");
var Utils = require("../Utils/index.js");
var WasmWorker = require("../WasmWorker/index.js");
var WasmWorker_WorkerRequest = require("../WasmWorker.WorkerRequest/index.js");
var Web_HTML = require("../Web.HTML/index.js");
var Web_HTML_Location = require("../Web.HTML.Location/index.js");
var Web_HTML_Window = require("../Web.HTML.Window/index.js");
var refreshReferenceProjectsData = function (dispatch) {
    return function (projectId) {
        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshReferenceProjectsData({
            request_params: projectId
        }))))(function () {
            return dispatch(new Actions.GetReferenceElementMeasuresRequest({
                projectId: projectId
            }));
        });
    };
};
var refreshEstimationProjectsData$prime = function (dispatch) {
    return function (projectId) {
        return function __do() {
            WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshEstimationProjectsData({
                request_params: projectId
            }))();
            return dispatch(new Actions.GetElementMeasuresRequest({
                projectId: projectId
            }))();
        };
    };
};
var refreshEstimationProjectsData = function (dispatch) {
    return function (projectId) {
        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshEstimationProjectsData({
            request_params: projectId
        }))))(function () {
            return dispatch(new Actions.GetElementMeasuresRequest({
                projectId: projectId
            }));
        });
    };
};
var dispatchAlertInfo = function (dispatch) {
    return function (lookupKey) {
        return dispatch(new Actions.AddAlert(Types_Alert.AlertInfo.value, Locale.lookup_(lookupKey)));
    };
};
var dispatchAlertError = function (dispatch) {
    return function (lookupKey) {
        return dispatch(new Actions.AddAlert(Types_Alert.AlertError.value, Locale.lookup_(lookupKey)));
    };
};
var genericFetchError = function (dispatch) {
    return function (e) {
        var userQuotaExceeded = function (errMsg) {
            var v = TofuJSON.readJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "msg";
                }
            })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_TypeString.tofuJSON({
                reflectSymbol: function () {
                    return "UserQuotaExceededError";
                }
            }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_TypeString.tofuJSON({
                reflectSymbol: function () {
                    return "UserQuotaExceededError";
                }
            }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "msg";
                }
            })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_TypeString.tofuJSON({
                reflectSymbol: function () {
                    return "UserQuotaExceededError";
                }
            }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_TypeString.tofuJSON({
                reflectSymbol: function () {
                    return "UserQuotaExceededError";
                }
            }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.nilWriteForeignFields)()()()))(errMsg);
            if (v instanceof Data_Either.Right) {
                return Data_Maybe.Just.create(dispatchAlertError(dispatch)("error_user_quota_exceeded"));
            };
            return Data_Maybe.Nothing.value;
        };
        var rwUserQuotaExceeded = function (errMsg) {
            var v = TofuJSON.readJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "msg";
                }
            })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_TypeString.tofuJSON({
                reflectSymbol: function () {
                    return "ReadWriteUserQuotaExceededError";
                }
            }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_TypeString.tofuJSON({
                reflectSymbol: function () {
                    return "ReadWriteUserQuotaExceededError";
                }
            }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "msg";
                }
            })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_TypeString.tofuJSON({
                reflectSymbol: function () {
                    return "ReadWriteUserQuotaExceededError";
                }
            }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_TypeString.tofuJSON({
                reflectSymbol: function () {
                    return "ReadWriteUserQuotaExceededError";
                }
            }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.nilWriteForeignFields)()()()))(errMsg);
            if (v instanceof Data_Either.Right) {
                return Data_Maybe.Just.create(dispatchAlertError(dispatch)("error_read_write_user_quota_exceeded"));
            };
            return Data_Maybe.Nothing.value;
        };
        var quotaExceeded = function (errMsg) {
            var v = TofuJSON.readJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "msg";
                }
            })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_TypeString.tofuJSON({
                reflectSymbol: function () {
                    return "QuotaExceededError";
                }
            }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_TypeString.tofuJSON({
                reflectSymbol: function () {
                    return "QuotaExceededError";
                }
            }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "msg";
                }
            })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_TypeString.tofuJSON({
                reflectSymbol: function () {
                    return "QuotaExceededError";
                }
            }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_TypeString.tofuJSON({
                reflectSymbol: function () {
                    return "QuotaExceededError";
                }
            }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.nilWriteForeignFields)()()()))(errMsg);
            if (v instanceof Data_Either.Right) {
                return Data_Maybe.Just.create(dispatchAlertError(dispatch)("error_quota_exceeded"));
            };
            return Data_Maybe.Nothing.value;
        };
        var formulaEvaluationFailed = function (errMsg) {
            var v = TofuJSON.readJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "msg";
                }
            })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_TypeString.tofuJSON({
                reflectSymbol: function () {
                    return "FormulaEvaluationError";
                }
            }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_TypeString.tofuJSON({
                reflectSymbol: function () {
                    return "FormulaEvaluationError";
                }
            }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "msg";
                }
            })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_TypeString.tofuJSON({
                reflectSymbol: function () {
                    return "FormulaEvaluationError";
                }
            }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_TypeString.tofuJSON({
                reflectSymbol: function () {
                    return "FormulaEvaluationError";
                }
            }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.nilWriteForeignFields)()()()))(errMsg);
            if (v instanceof Data_Either.Right) {
                return Data_Maybe.Just.create(dispatchAlertError(dispatch)("error_formula_evaluation_failed"));
            };
            return Data_Maybe.Nothing.value;
        };
        var customServerError = function (errMsg) {
            var v = TofuJSON.readJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "msg";
                }
            })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "msg";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_TypeString.tofuJSON({
                reflectSymbol: function () {
                    return "CustomServerError";
                }
            }))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "msg";
                }
            })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_TypeString.tofuJSON({
                reflectSymbol: function () {
                    return "CustomServerError";
                }
            }))(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "msg";
                }
            })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "msg";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_TypeString.tofuJSON({
                reflectSymbol: function () {
                    return "CustomServerError";
                }
            }))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "msg";
                }
            })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_TypeString.tofuJSON({
                reflectSymbol: function () {
                    return "CustomServerError";
                }
            }))(TofuJSON.nilWriteForeignFields)()()())()()()))(TofuJSON.nilWriteForeignFields)()()()))(errMsg);
            if (v instanceof Data_Either.Right) {
                return Data_Maybe.Just.create(dispatchAlertError(dispatch)(v.value0.msg.msg));
            };
            return Data_Maybe.Nothing.value;
        };
        var copyProjectDuplicateCodeAndVersionError = function (errMsg) {
            var v = TofuJSON.readJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "msg";
                }
            })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_TypeString.tofuJSON({
                reflectSymbol: function () {
                    return "CopyProjectDuplicateCodeAndVersionError";
                }
            }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_TypeString.tofuJSON({
                reflectSymbol: function () {
                    return "CopyProjectDuplicateCodeAndVersionError";
                }
            }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "msg";
                }
            })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_TypeString.tofuJSON({
                reflectSymbol: function () {
                    return "CopyProjectDuplicateCodeAndVersionError";
                }
            }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_TypeString.tofuJSON({
                reflectSymbol: function () {
                    return "CopyProjectDuplicateCodeAndVersionError";
                }
            }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.nilWriteForeignFields)()()()))(errMsg);
            if (v instanceof Data_Either.Right) {
                return Data_Maybe.Just.create(dispatchAlertError(dispatch)("error_copy_project_duplicate_code_and_version_error"));
            };
            return Data_Maybe.Nothing.value;
        };
        var convertToTopicComponentError = function (errMsg) {
            var v = TofuJSON.readJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "msg";
                }
            })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_TypeString.tofuJSON({
                reflectSymbol: function () {
                    return "CantConvertToTopicComponent";
                }
            }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_TypeString.tofuJSON({
                reflectSymbol: function () {
                    return "CantConvertToTopicComponent";
                }
            }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "msg";
                }
            })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_TypeString.tofuJSON({
                reflectSymbol: function () {
                    return "CantConvertToTopicComponent";
                }
            }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_TypeString.tofuJSON({
                reflectSymbol: function () {
                    return "CantConvertToTopicComponent";
                }
            }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.nilWriteForeignFields)()()()))(errMsg);
            if (v instanceof Data_Either.Right) {
                return Data_Maybe.Just.create(dispatchAlertError(dispatch)("error_cant_convert_to_topic_component"));
            };
            return Data_Maybe.Nothing.value;
        };
        var componentCodeReserved = function (errMsg) {
            var v = TofuJSON.readJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "msg";
                }
            })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_TypeString.tofuJSON({
                reflectSymbol: function () {
                    return "ComponentCodeAlreadyReserved";
                }
            }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_TypeString.tofuJSON({
                reflectSymbol: function () {
                    return "ComponentCodeAlreadyReserved";
                }
            }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "msg";
                }
            })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_TypeString.tofuJSON({
                reflectSymbol: function () {
                    return "ComponentCodeAlreadyReserved";
                }
            }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "type";
                }
            })(Types_TypeString.tofuJSON({
                reflectSymbol: function () {
                    return "ComponentCodeAlreadyReserved";
                }
            }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.nilWriteForeignFields)()()()))(errMsg);
            if (v instanceof Data_Either.Right) {
                return Data_Maybe.Just.create(dispatchAlertError(dispatch)("error_component_code_reserved"));
            };
            return Data_Maybe.Nothing.value;
        };
        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(ClientLogger.logError(Effect_Aff.monadEffectAff)(e))(function () {
            var errMsg = Effect_Exception.message(e);
            return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class_Console.error(Effect_Aff.monadEffectAff)(errMsg))(function () {
                var unknownErrorAff = dispatchAlertError(dispatch)("error_unknown_please_refresh");
                var errorAff = ArrayUtils.oneOf([ function (v) {
                    return userQuotaExceeded(errMsg);
                }, function (v) {
                    return quotaExceeded(errMsg);
                }, function (v) {
                    return rwUserQuotaExceeded(errMsg);
                }, function (v) {
                    return componentCodeReserved(errMsg);
                }, function (v) {
                    return formulaEvaluationFailed(errMsg);
                }, function (v) {
                    return copyProjectDuplicateCodeAndVersionError(errMsg);
                }, function (v) {
                    return convertToTopicComponentError(errMsg);
                }, function (v) {
                    return customServerError(errMsg);
                } ]);
                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Data_Maybe.fromMaybe(unknownErrorAff)(errorAff))(function () {
                    return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(e);
                });
            });
        });
    };
};
var handleGenericAttempt = function (dispatch) {
    return function (attempt) {
        return function (mkSuccess) {
            return function (mkFailure) {
                if (attempt instanceof Data_Either.Right) {
                    return dispatch(mkSuccess(attempt.value0));
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(dispatch(mkFailure(attempt.value0)))(function () {
                        return genericFetchError(dispatch)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 144, column 3 - line 148, column 35): " + [ attempt.constructor.name ]);
            };
        };
    };
};
var refreshCostControlData = function (dispatch) {
    return function (projectId) {
        return Control_Parallel.parSequence_(Effect_Aff.parallelAff)(Data_Foldable.foldableArray)([ handleAction(dispatch)(new Actions.CostGroupInfosRequest({
            projectId: projectId
        })), handleAction(dispatch)(new Actions.GetInvoicesRequest({
            projectId: projectId
        })), handleAction(dispatch)(new Actions.ProjectEstimateItemsRequest({
            projectId: projectId
        })), handleAction(dispatch)(new Actions.ProjectTargetItemsRequest({
            projectId: projectId
        })) ]);
    };
};
var handleAction = function (v) {
    return function (v1) {
        if (v1 instanceof Actions.PortfolioReportRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.serverRoutes.portfolioReport)({})(function (attempt) {
                return handleGenericAttempt(v)(attempt)(Actions.PortfolioReportSuccess.create)(Data_Function["const"](Actions.PortfolioReportError.value));
            });
        };
        if (v1 instanceof Actions.UpdatePortfolioReportRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.serverRoutes.updatePortfolioReport)({})(function (attempt) {
                return handleGenericAttempt(v)(attempt)(Actions.UpdatePortfolioReportSuccess.create)(Data_Function["const"](Actions.UpdatePortfolioReportError.value));
            });
        };
        if (v1 instanceof Actions.InvoiceImgRequest) {
            var body = {
                projectId: v1.value0.projectId,
                file: v1.value0.file
            };
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.invoiceRoutes.invoiceImg)({})(body)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return DOMUtils.openInvoiceInNewTab(attempt.value0)("_blank")(v);
                };
                if (attempt instanceof Data_Either.Left) {
                    return genericFetchError(v)(attempt.value0);
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 167, column 3 - line 170, column 43): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.AllProjectsRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.projectRoutes.allProjects)({})(function (attempt) {
                return handleGenericAttempt(v)(attempt)(Actions.AllProjectsSuccess.create)(Data_Function["const"](Actions.AllProjectsError.value));
            });
        };
        if (v1 instanceof Actions.CostControlProjectsRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.projectRoutes.costControlProjects)({})(function (attempt) {
                return handleGenericAttempt(v)(attempt)(Actions.CostControlProjectsSuccess.create)(Data_Function["const"](Actions.CostControlProjectsError.value));
            });
        };
        if (v1 instanceof Actions.EstimationProjectsRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.estimationProjectSummariesRouteForeign)({})(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.EstimationProjectsSuccess({
                        items: items
                    });
                })(Data_Function["const"](Actions.EstimationProjectsError.value));
            });
        };
        if (v1 instanceof Actions.GetProjectCostGroupClassificationInfoRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.planningRoutes.projectCostGroupClassificationInfo)({
                projectId: v1.value0.projectId
            })(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.GetProjectCostGroupClassificationInfoSuccess({
                        projectId: v1.value0.projectId,
                        items: items
                    });
                })(Data_Function["const"](Actions.GetProjectCostGroupClassificationInfoError.value));
            });
        };
        if (v1 instanceof Actions.GetUserOrganizationsRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.serverRoutes.userOrganizations)({})(function (attempt) {
                return handleGenericAttempt(v)(attempt)(Actions.GetUserOrganizationsSuccess.create)(Data_Function["const"](Actions.GetUserOrganizationsFailure.value));
            });
        };
        if (v1 instanceof Actions.GetOrganizationAdminInfoRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.organizationAdminInfoRoute)({})(function (attempt) {
                return handleGenericAttempt(v)(attempt)(Actions.GetOrganizationAdminInfoSuccess.create)(Data_Function["const"](Actions.GetOrganizationAdminInfoFailure.value));
            });
        };
        if (v1 instanceof Actions.GetAllOrganizationsRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.serverRoutes.allOrganizations)({})(function (attempt) {
                return handleGenericAttempt(v)(attempt)(Actions.GetAllOrganizationsSuccess.create)(Data_Function["const"](Actions.GetAllOrganizationsFailure.value));
            });
        };
        if (v1 instanceof Actions.GetAllOrganizationConfigurationsRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.serverRoutes.getAllOrganizationQuotas)({})(function (attempt) {
                return handleGenericAttempt(v)(attempt)(Actions.GetAllOrganizationConfigurationsSuccess.create)(Data_Function["const"](Actions.GetAllOrganizationConfigurationsFailure.value));
            });
        };
        if (v1 instanceof Actions.PublishToReportingRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.projectRoutes.publishEstimationProjectToReporting)({
                projectId: v1.value0.projectId
            })({})))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return v(Actions.PublishToReportingActionSuccess.value);
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.PublishToReportingActionFailure.value))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 222, column 3 - line 227, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.UnpublishReportingRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.projectRoutes.unpublishReportingEstimationProject)({
                projectId: v1.value0.projectId
            })({})))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return v(Actions.PublishToReportingActionSuccess.value);
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.PublishToReportingActionFailure.value))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 231, column 3 - line 236, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.ResourcePriceAdjustmentRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.projectRoutes.resourcePriceAdjustment)({
                projectId: v1.value0.projectId
            })(v1.value0.params)))(function (attempt) {
                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(v1.value0.handler(attempt)))(function () {
                    if (attempt instanceof Data_Either.Right) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshEstimationResourcesData({
                            request_params: v1.value0.projectId
                        }))))(function () {
                            return v(Actions.ResourcePriceAdjustmentSuccess.value);
                        });
                    };
                    if (attempt instanceof Data_Either.Left) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.ResourcePriceAdjustmentFailure.value))(function () {
                            return genericFetchError(v)(attempt.value0);
                        });
                    };
                    throw new Error("Failed pattern match at FetchMiddleware (line 241, column 3 - line 247, column 35): " + [ attempt.constructor.name ]);
                });
            });
        };
        if (v1 instanceof Actions.SetOrganizationConfigurationRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.serverRoutes.setOrganizationQuotas)({
                organization: v1.value0
            })(v1.value1)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(handleAction(v)(Actions.GetAllOrganizationConfigurationsRequest.value))(function () {
                        return v(Actions.SetOrganizationConfigurationSuccess.value);
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.SetOrganizationConfigurationFailure.value))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 251, column 3 - line 257, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.GetCurrentOrganizationRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.serverRoutes.currentOrganization)({})(function (attempt) {
                return handleGenericAttempt(v)(attempt)(Actions.GetCurrentOrganizationSuccess.create)(Actions.GetCurrentOrganizationFailure.create);
            });
        };
        if (v1 instanceof Actions.GetCostControlExcludeActualCostSocialExpensesRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.costControlExcludeActualCostSocialExpensesRoute)({})(function (attempt) {
                return handleGenericAttempt(v)(attempt)(Actions.GetCostControlExcludeActualCostSocialExpensesSuccess.create)(Data_Function["const"](Actions.GetCostControlExcludeActualCostSocialExpensesFailure.value));
            });
        };
        if (v1 instanceof Actions.GetProjectRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.projectRoutes.project)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (project) {
                    return new Actions.GetProjectSuccess({
                        project: project,
                        projectId: v1.value0.projectId
                    });
                })(Data_Function["const"](Actions.GetProjectError.value));
            });
        };
        if (v1 instanceof Actions.GetReferenceSubprojectsRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.referenceSubprojectsRouteForeign)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.GetReferenceSubprojectsSuccess({
                        items: items,
                        projectId: v1.value0.projectId
                    });
                })(Data_Function["const"](new Actions.GetReferenceSubprojectsError({
                    projectId: v1.value0.projectId
                })));
            });
        };
        if (v1 instanceof Actions.GetEstimationResourcePriceListRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.estimationResourcePriceListRoute)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.GetEstimationResourcePriceListSuccess({
                        items: items,
                        projectId: v1.value0.projectId
                    });
                })(Data_Function["const"](new Actions.GetEstimationResourcePriceListError({
                    projectId: v1.value0.projectId
                })));
            });
        };
        if (v1 instanceof Actions.GetOrgPriceListTreeRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.estimationRoutes.orgPriceListTree)({})(function (attempt) {
                return handleGenericAttempt(v)(attempt)(Actions.GetOrgPriceListTreeSuccess.create)(function (v2) {
                    return Actions.GetOrgPriceListTreeError.value;
                });
            });
        };
        if (v1 instanceof Actions.GetOrgPriceLists) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.estimationRoutes.orgPriceLists)({})(function (attempt) {
                return handleGenericAttempt(v)(attempt)(Actions.GetOrgPriceListsSuccess.create)(function (v2) {
                    return Actions.GetOrgPriceListsError.value;
                });
            });
        };
        if (v1 instanceof Actions.AddReferenceProjectRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.projectRoutes.addReferenceProject)({
                projectId: v1.value0.projectId
            })({
                refProjectId: v1.value0.refProjectId
            })))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.AddReferenceProjectSuccess.value))(function () {
                        return refreshReferenceProjectsData(v)(v1.value0.projectId);
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.AddReferenceProjectError.value))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 303, column 3 - line 309, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.DeleteReferenceProjectRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.projectRoutes.deleteReferenceProject)({
                projectId: v1.value0.projectId
            })({
                refProjectId: v1.value0.refProjectId
            })))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.DeleteReferenceProjectSuccess.value))(function () {
                        return refreshReferenceProjectsData(v)(v1.value0.projectId);
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.DeleteReferenceProjectError.value))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 313, column 3 - line 319, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.GetReferenceProjectCostClassesRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.projectRoutes.referenceProjectCostClasses)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.GetReferenceProjectCostClassesSuccess({
                        items: items,
                        projectId: v1.value0.projectId
                    });
                })(Data_Function["const"](Actions.GetReferenceProjectCostClassesError.value));
            });
        };
        if (v1 instanceof Actions.ClassificationsRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.serverRoutes.classifications)({})(function (attempt) {
                return handleGenericAttempt(v)(attempt)(Actions.ClassificationsSuccess.create)(Data_Function["const"](Actions.ClassificationsError.value));
            });
        };
        if (v1 instanceof Actions.GetProjectManagersRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.projectRoutes.getProjectManagers)({})(function (attempt) {
                return handleGenericAttempt(v)(attempt)(Actions.GetProjectManagersSuccess.create)(Data_Function["const"](Actions.GetProjectManagersError.value));
            });
        };
        if (v1 instanceof Actions.ProjectGroupsRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.projectRoutes.projectGroups)({})(function (attempt) {
                return handleGenericAttempt(v)(attempt)(Actions.ProjectGroupsSuccess.create)(Data_Function["const"](Actions.ProjectGroupsError.value));
            });
        };
        if (v1 instanceof Actions.UpdateProjectGroupCacheRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.serverRoutes.updateProjectGroupCache)({})({})))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.GetProjectGroupCacheLastUpdateRequest.value))(function () {
                        return v(Actions.UpdateProjectGroupCacheSuccess.value);
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.UpdateProjectGroupCacheError.value))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 348, column 3 - line 354, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.GetProjectGroupCacheLastUpdateRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.serverRoutes.projectGroupCacheLastUpdate)({})(function (attempt) {
                return handleGenericAttempt(v)(attempt)(Actions.GetProjectGroupCacheLastUpdateSuccess.create)(Data_Function["const"](Actions.GetProjectGroupCacheLastUpdateError.value));
            });
        };
        if (v1 instanceof Actions.ConstructionTypesRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.serverRoutes.constructionTypes)({})(function (attempt) {
                return handleGenericAttempt(v)(attempt)(Actions.ConstructionTypesSuccess.create)(Data_Function["const"](Actions.ConstructionTypesError.value));
            });
        };
        if (v1 instanceof Actions.FinancingTypesRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.serverRoutes.financingTypes)({})(function (attempt) {
                return handleGenericAttempt(v)(attempt)(Actions.FinancingTypesSuccess.create)(Data_Function["const"](Actions.FinancingTypesError.value));
            });
        };
        if (v1 instanceof Actions.ProjectTypesRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.projectRoutes.projectTypes)({})(function (attempt) {
                return handleGenericAttempt(v)(attempt)(Actions.ProjectTypesSuccess.create)(Data_Function["const"](Actions.ProjectTypesError.value));
            });
        };
        if (v1 instanceof Actions.ContractTypesRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.serverRoutes.contractTypes)({})(function (attempt) {
                return handleGenericAttempt(v)(attempt)(Actions.ContractTypesSuccess.create)(Data_Function["const"](Actions.ContractTypesError.value));
            });
        };
        if (v1 instanceof Actions.ResponsibilityCompaniesRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.serverRoutes.responsibilityCompanies)({})(function (attempt) {
                return handleGenericAttempt(v)(attempt)(Actions.ResponsibilityCompaniesSuccess.create)(Data_Function["const"](Actions.ResponsibilityCompaniesFailure.value));
            });
        };
        if (v1 instanceof Actions.ProjectResponsibilityInfoRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.projectRoutes.projectResponsibilityInfo)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.ProjectResponsibilityInfoSuccess({
                        items: items,
                        projectId: v1.value0.projectId
                    });
                })(Data_Function["const"](Actions.ProjectResponsibilityInfoFailure.value));
            });
        };
        if (v1 instanceof Actions.GetLogsRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.serverRoutes.logs)({})(function (attempt) {
                return handleGenericAttempt(v)(attempt)(Actions.GetLogsSuccess.create)(Data_Function["const"](Actions.GetLogsFailure.value));
            });
        };
        if (v1 instanceof Actions.SuppliersRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.serverRoutes.suppliers)({})(function (attempt) {
                return handleGenericAttempt(v)(attempt)(Actions.SuppliersSuccess.create)(Data_Function["const"](Actions.SuppliersError.value));
            });
        };
        if (v1 instanceof Actions.GetServerStatusAction) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.serverRoutes.serverStatus)({})(function (attempt) {
                return handleGenericAttempt(v)(attempt)(Actions.GetServerStatusSuccess.create)(Data_Function["const"](Actions.GetServerStatusFailure.value));
            });
        };
        if (v1 instanceof Actions.SetSuperadmin) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.serverRoutes.setSuperadmin)({})(v1.value0)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return v(Actions.GetSuperAdminUsersRequest.value);
                };
                if (attempt instanceof Data_Either.Left) {
                    return v(Actions.SetOutageMessageFailure.value);
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 418, column 3 - line 421, column 55): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.SetOutageMessageAction) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.serverRoutes.setOutageMessage)({})(v1.value0)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.GetServerStatusAction.value))(function () {
                        return v(Actions.SetOutageMessageSuccess.value);
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return v(Actions.SetOutageMessageFailure.value);
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 425, column 3 - line 429, column 55): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.GetSuperAdminUsersRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.serverRoutes.users)({})(function (attempt) {
                return handleGenericAttempt(v)(attempt)(Actions.GetSuperAdminUsersSuccess.create)(Data_Function["const"](Actions.GetSuperAdminUsersError.value));
            });
        };
        if (v1 instanceof Actions.GetRoleAssignmentsRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.serverRoutes.roleAssignments)({})(function (attempt) {
                return handleGenericAttempt(v)(attempt)(Actions.GetRoleAssignmentsSuccess.create)(Data_Function["const"](Actions.GetRoleAssignmentsError.value));
            });
        };
        if (v1 instanceof Actions.GetOrganizationUsersRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.serverRoutes.organizationUsers)({})(function (attempt) {
                return handleGenericAttempt(v)(attempt)(Actions.GetOrganizationUsersSuccess.create)(Data_Function["const"](Actions.GetOrganizationUsersError.value));
            });
        };
        if (v1 instanceof Actions.MainCostGroupsRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.costGroupRoutes.mainCostGroups)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.MainCostGroupsSuccess({
                        items: items,
                        projectId: v1.value0.projectId
                    });
                })(Data_Function["const"](Actions.MainCostGroupsError.value));
            });
        };
        if (v1 instanceof Actions.CostGroupInfosRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.costGroupRoutes.costGroupInfos)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.CostGroupInfosSuccess({
                        items: items,
                        projectId: v1.value0.projectId
                    });
                })(Data_Function["const"](Actions.CostGroupInfosError.value));
            });
        };
        if (v1 instanceof Actions.OrgClassificationClassesRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.projectRoutes.orgClassificationClasses)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.OrgClassificationClassesSuccess({
                        items: items,
                        projectId: v1.value0.projectId
                    });
                })(Data_Function["const"](Actions.OrgClassificationClassesError.value));
            });
        };
        if (v1 instanceof Actions.ProjectClassificationClassesRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.projectRoutes.projectClassificationClasses)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.ProjectClassificationClassesSuccess({
                        items: items,
                        projectId: v1.value0.projectId
                    });
                })(Data_Function["const"](Actions.ProjectClassificationClassesError.value));
            });
        };
        if (v1 instanceof Actions.GetClassificationGroupsRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.projectRoutes.classificationGroups)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.GetClassificationGroupsSuccess({
                        items: items,
                        projectId: v1.value0.projectId
                    });
                })(function (v2) {
                    return new Actions.GetClassificationGroupsError({
                        projectId: v1.value0.projectId
                    });
                });
            });
        };
        if (v1 instanceof Actions.ProjectCostGroupsRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.costGroupRoutes.costGroups)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.ProjectCostGroupsSuccess({
                        items: items,
                        projectId: v1.value0.projectId
                    });
                })(Data_Function["const"](Actions.ProjectCostGroupsError.value));
            });
        };
        if (v1 instanceof Actions.ProjectCostGroupCategoriesRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.costGroupRoutes.costGroupCategories)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.ProjectCostGroupCategoriesSuccess({
                        items: items,
                        projectId: v1.value0.projectId
                    });
                })(Data_Function["const"](Actions.ProjectCostGroupCategoriesError.value));
            });
        };
        if (v1 instanceof Actions.GetIncomeItemsRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.incomeRoutes.incomeItems)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.GetIncomeItemsSuccess({
                        items: items,
                        projectId: v1.value0.projectId
                    });
                })(Data_Function["const"](Actions.GetIncomeItemsError.value));
            });
        };
        if (v1 instanceof Actions.GetProjectLocationsRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.projectRoutes.projectLocations)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.GetProjectLocationsSuccess({
                        items: items,
                        projectId: v1.value0.projectId
                    });
                })(Data_Function["const"](Actions.GetProjectLocationsError.value));
            });
        };
        if (v1 instanceof Actions.GetSalesInvoicesRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.invoiceRoutes.salesInvoices)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.GetSalesInvoicesSuccess({
                        items: items,
                        projectId: v1.value0.projectId
                    });
                })(Data_Function["const"](Actions.GetSalesInvoicesError.value));
            });
        };
        if (v1 instanceof Actions.GetIncomeGroupsRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.incomeRoutes.incomeGroups)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.GetIncomeGroupsSuccess({
                        items: items,
                        projectId: v1.value0.projectId
                    });
                })(Data_Function["const"](Actions.GetIncomeGroupsFailure.value));
            });
        };
        if (v1 instanceof Actions.GetMeasurementsRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.serverRoutes.measurements)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.GetMeasurementsSuccess({
                        items: items,
                        projectId: v1.value0.projectId
                    });
                })(Data_Function["const"](Actions.GetMeasurementsFailure.value));
            });
        };
        if (v1 instanceof Actions.GetSubprojectsRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.subprojectsRouteForeign)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.GetSubprojectsSuccess({
                        items: items,
                        projectId: v1.value0.projectId
                    });
                })(Data_Function["const"](new Actions.GetSubprojectsFailure({
                    projectId: v1.value0.projectId
                })));
            });
        };
        if (v1 instanceof Actions.GetInvoicesRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.invoicesRoute)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.GetInvoicesSuccess({
                        items: items,
                        projectId: v1.value0.projectId
                    });
                })(Data_Function["const"](Actions.GetInvoicesError.value));
            });
        };
        if (v1 instanceof Actions.InvoiceBunchesRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.invoiceRoutes.invoiceBunches)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.InvoiceBunchesSuccess({
                        items: items,
                        projectId: v1.value0.projectId
                    });
                })(Data_Function["const"](Actions.InvoiceBunchesError.value));
            });
        };
        if (v1 instanceof Actions.AllInvoiceBunchesRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.invoiceRoutes.allInvoiceBunches)({})(function (attempt) {
                return handleGenericAttempt(v)(attempt)(Actions.AllInvoiceBunchesSuccess.create)(Data_Function["const"](Actions.AllInvoiceBunchesError.value));
            });
        };
        if (v1 instanceof Actions.ProjectEstimateItemsRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.serverRoutes.estimateItems)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.ProjectEstimateItemsSuccess({
                        items: items,
                        projectId: v1.value0.projectId
                    });
                })(Data_Function["const"](new Actions.ProjectEstimateItemsError({
                    projectId: v1.value0.projectId
                })));
            });
        };
        if (v1 instanceof Actions.ProjectTargetItemsRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.serverRoutes.targetItems)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.ProjectTargetItemsSuccess({
                        items: items,
                        projectId: v1.value0.projectId
                    });
                })(Data_Function["const"](new Actions.ProjectTargetItemsError({
                    projectId: v1.value0.projectId
                })));
            });
        };
        if (v1 instanceof Actions.ProjectCostClassesRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.serverRoutes.costClasses)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.ProjectCostClassesSuccess({
                        items: items,
                        projectId: v1.value0.projectId
                    });
                })(Data_Function["const"](new Actions.ProjectCostClassesError({
                    projectId: v1.value0.projectId
                })));
            });
        };
        if (v1 instanceof Actions.CostClassActionRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.serverRoutes.costClassAction)({
                projectId: v1.value0.projectId
            })(v1.value0.params)))(function (attempt) {
                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(v1.value0.handler(attempt)))(function () {
                    if (attempt instanceof Data_Either.Right) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(handleAction(v)(new Actions.ProjectCostClassesRequest({
                            projectId: v1.value0.projectId
                        })))(function () {
                            return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshEstimationCostClasses({
                                request_params: v1.value0.projectId
                            }))))(function () {
                                return v(new Actions.CostClassActionSuccess(v1.value0));
                            });
                        });
                    };
                    if (attempt instanceof Data_Either.Left) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.CostClassActionError(v1.value0)))(function () {
                            return genericFetchError(v)(attempt.value0);
                        });
                    };
                    throw new Error("Failed pattern match at FetchMiddleware (line 567, column 3 - line 574, column 35): " + [ attempt.constructor.name ]);
                });
            });
        };
        if (v1 instanceof Actions.GetOfferPageParamsRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.offerRoutes.getOfferPageParams)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.GetOfferPageParamsSuccess({
                        items: items,
                        projectId: v1.value0.projectId
                    });
                })(Data_Function["const"](new Actions.SetOfferPageParamsFailure({
                    projectId: v1.value0.projectId
                })));
            });
        };
        if (v1 instanceof Actions.SetOfferPageParamsRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.offerRoutes.setOfferPageParams)({
                projectId: v1.value0.projectId
            })(v1.value0.items)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.SetOfferPageParamsSuccess({
                        projectId: v1.value0.projectId
                    })))(function () {
                        return handleAction(v)(new Actions.GetOfferPageParamsRequest({
                            projectId: v1.value0.projectId
                        }));
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.SetOfferPageParamsFailure({
                        projectId: v1.value0.projectId
                    })))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 584, column 3 - line 590, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.SocialExpensesRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.serverRoutes.socialExpenses)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.SocialExpensesSuccess({
                        items: items,
                        projectId: v1.value0.projectId
                    });
                })(Data_Function["const"](new Actions.SocialExpensesError({
                    projectId: v1.value0.projectId
                })));
            });
        };
        if (v1 instanceof Actions.ProjectCostGroupInvoicesRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.invoiceRoutes.costGroupInvoices)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.ProjectCostGroupInvoicesSuccess({
                        items: items,
                        projectId: v1.value0.projectId,
                        costGroupId: v1.value0.costGroupId
                    });
                })(Data_Function["const"](new Actions.ProjectCostGroupInvoicesError({
                    projectId: v1.value0.projectId,
                    costGroupId: v1.value0.costGroupId
                })));
            });
        };
        if (v1 instanceof Actions.GetReportingResourcesRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.serverRoutes.reportingResources)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.GetReportingResourcesSuccess({
                        items: items,
                        projectId: v1.value0.projectId
                    });
                })(Data_Function["const"](new Actions.GetReportingResourcesFailure({
                    projectId: v1.value0.projectId
                })));
            });
        };
        if (v1 instanceof Actions.GetReportingComponentsRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.serverRoutes.reportingComponents)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.GetReportingComponentsSuccess({
                        items: items,
                        projectId: v1.value0.projectId
                    });
                })(Data_Function["const"](new Actions.GetReportingComponentsFailure({
                    projectId: v1.value0.projectId
                })));
            });
        };
        if (v1 instanceof Actions.GetEstimationElementsRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.estimationElementsRouteForeign)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.GetEstimationElementsSuccess({
                        items: items,
                        projectId: v1.value0.projectId
                    });
                })(Data_Function["const"](new Actions.GetEstimationElementsFailure({
                    projectId: v1.value0.projectId
                })));
            });
        };
        if (v1 instanceof Actions.GetElementLocationsRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.elementLocationsRouteForeign)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.GetElementLocationsSuccess({
                        items: items,
                        projectId: v1.value0.projectId
                    });
                })(Data_Function["const"](new Actions.GetElementLocationsFailure({
                    projectId: v1.value0.projectId
                })));
            });
        };
        if (v1 instanceof Actions.GetReferenceElementLocationsRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.referenceElementLocationsRouteForeign)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.GetReferenceElementLocationsSuccess({
                        items: items,
                        projectId: v1.value0.projectId
                    });
                })(Data_Function["const"](new Actions.GetReferenceElementLocationsFailure({
                    projectId: v1.value0.projectId
                })));
            });
        };
        if (v1 instanceof Actions.GetElementMeasuresRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.elementMeasuresRoute)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.GetElementMeasuresSuccess({
                        items: items,
                        projectId: v1.value0.projectId
                    });
                })(Data_Function["const"](new Actions.GetElementMeasuresFailure({
                    projectId: v1.value0.projectId
                })));
            });
        };
        if (v1 instanceof Actions.GetReferenceElementMeasuresRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.elementMeasuresRoute)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.GetReferenceElementMeasuresSuccess({
                        items: items,
                        projectId: v1.value0.projectId
                    });
                })(Data_Function["const"](new Actions.GetReferenceElementMeasuresFailure({
                    projectId: v1.value0.projectId
                })));
            });
        };
        if (v1 instanceof Actions.GetElementSpecsRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.elementSpecsRouteForeign)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.GetElementSpecsSuccess({
                        items: items,
                        projectId: v1.value0.projectId
                    });
                })(Data_Function["const"](new Actions.GetElementSpecsFailure({
                    projectId: v1.value0.projectId
                })));
            });
        };
        if (v1 instanceof Actions.GetReferenceElementSpecsRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.referenceElementSpecsRouteForeign)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.GetReferenceElementSpecsSuccess({
                        items: items,
                        projectId: v1.value0.projectId
                    });
                })(Data_Function["const"](new Actions.GetReferenceElementSpecsFailure({
                    projectId: v1.value0.projectId
                })));
            });
        };
        if (v1 instanceof Actions.GetEstimationComponentsRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.estimationComponentsRouteForeign)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.GetEstimationComponentsSuccess({
                        items: items,
                        projectId: v1.value0.projectId
                    });
                })(Data_Function["const"](new Actions.GetEstimationComponentsFailure({
                    projectId: v1.value0.projectId
                })));
            });
        };
        if (v1 instanceof Actions.GetPlanningCostGroupsRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.planningCostGroupsRouteForeign)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.GetPlanningCostGroupsSuccess({
                        items: items,
                        projectId: v1.value0.projectId
                    });
                })(Data_Function["const"](new Actions.GetPlanningCostGroupsFailure({
                    projectId: v1.value0.projectId
                })));
            });
        };
        if (v1 instanceof Actions.GetReferenceEstimationComponentsRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.referenceEstimationComponentsRouteForeign)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.GetReferenceEstimationComponentsSuccess({
                        projectId: v1.value0.projectId,
                        items: items
                    });
                })(Data_Function["const"](new Actions.GetReferenceEstimationComponentsFailure(v1.value0)));
            });
        };
        if (v1 instanceof Actions.GetEstimationResourcesRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.estimationResourcesRouteForeign)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.GetEstimationResourcesSuccess({
                        items: items,
                        projectId: v1.value0.projectId
                    });
                })(Data_Function["const"](new Actions.GetEstimationResourcesFailure({
                    projectId: v1.value0.projectId
                })));
            });
        };
        if (v1 instanceof Actions.GetReferenceEstimationElementsRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.referenceEstimationElementsRouteForeign)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.GetReferenceEstimationElementsSuccess({
                        projectId: v1.value0.projectId,
                        items: items
                    });
                })(Data_Function["const"](new Actions.GetReferenceEstimationElementsFailure(v1.value0)));
            });
        };
        if (v1 instanceof Actions.GetReferenceEstimationResourcesRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.referenceEstimationResourcesRouteForeign)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.GetReferenceEstimationResourcesSuccess({
                        projectId: v1.value0.projectId,
                        items: items
                    });
                })(Data_Function["const"](new Actions.GetReferenceEstimationResourcesFailure(v1.value0)));
            });
        };
        if (v1 instanceof Actions.GetEstimationLocationsRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.estimationLocationsRouteForeign)(v1.value0)(function (attempt) {
                return handleGenericAttempt(v)(attempt)(function (items) {
                    return new Actions.GetEstimationLocationsSuccess({
                        items: items,
                        projectId: v1.value0.projectId
                    });
                })(Data_Function["const"](new Actions.GetEstimationLocationsFailure({
                    projectId: v1.value0.projectId
                })));
            });
        };
        if (v1 instanceof Actions.EstimationLocationActionRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.estimationRoutes.estimationLocationAction)({
                projectId: v1.value0.projectId
            })(v1.value0.params)))(function (attempt) {
                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(v1.value0.handler(attempt)))(function () {
                    if (attempt instanceof Data_Either.Right) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.EstimationLocationActionSuccess(v1.value0)))(function () {
                            return refreshEstimationProjectsData(v)(v1.value0.projectId);
                        });
                    };
                    if (attempt instanceof Data_Either.Left) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.EstimationLocationActionFailure(v1.value0)))(function () {
                            return genericFetchError(v)(attempt.value0);
                        });
                    };
                    throw new Error("Failed pattern match at FetchMiddleware (line 706, column 3 - line 713, column 35): " + [ attempt.constructor.name ]);
                });
            });
        };
        if (v1 instanceof Actions.PlanningActionRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.planningRoutes.planningAction)({
                projectId: v1.value0.projectId
            })(v1.value0.params)))(function (attempt) {
                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(v1.value0.handler(attempt)))(function () {
                    if (attempt instanceof Data_Either.Right) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.PlanningActionSuccess(v1.value0)))(function () {
                            return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshPlanningCostGroups({
                                request_params: v1.value0.projectId
                            }))))(function () {
                                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.GetProjectCostGroupClassificationInfoRequest({
                                    projectId: v1.value0.projectId
                                })))(function () {
                                    return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshEstimationProjectsData({
                                        request_params: v1.value0.projectId
                                    })));
                                });
                            });
                        });
                    };
                    if (attempt instanceof Data_Either.Left) {
                        var v2 = TofuJSON.readJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "msg";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "type";
                            }
                        })(Types_TypeString.tofuJSON({
                            reflectSymbol: function () {
                                return "AddDuplicateCostGroupError";
                            }
                        }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "type";
                            }
                        })(Types_TypeString.tofuJSON({
                            reflectSymbol: function () {
                                return "AddDuplicateCostGroupError";
                            }
                        }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "msg";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "type";
                            }
                        })(Types_TypeString.tofuJSON({
                            reflectSymbol: function () {
                                return "AddDuplicateCostGroupError";
                            }
                        }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "type";
                            }
                        })(Types_TypeString.tofuJSON({
                            reflectSymbol: function () {
                                return "AddDuplicateCostGroupError";
                            }
                        }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.nilWriteForeignFields)()()()))(Effect_Exception.message(attempt.value0));
                        if (v2 instanceof Data_Either.Right) {
                            return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.PlanningActionFailure(v1.value0)))(function () {
                                return dispatchAlertError(v)("error_add_cost_group_duplicate_row");
                            });
                        };
                        if (v2 instanceof Data_Either.Left) {
                            return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.PlanningActionFailure(v1.value0)))(function () {
                                return genericFetchError(v)(attempt.value0);
                            });
                        };
                        throw new Error("Failed pattern match at FetchMiddleware (line 725, column 7 - line 731, column 39): " + [ v2.constructor.name ]);
                    };
                    throw new Error("Failed pattern match at FetchMiddleware (line 718, column 3 - line 731, column 39): " + [ attempt.constructor.name ]);
                });
            });
        };
        if (v1 instanceof Actions.ConvertToRegularComponentRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.estimationRoutes.convertToRegularComponent)({
                projectId: v1.value0.projectId
            })(v1.value0.params.value0)))(function (attempt) {
                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(v1.value0.handler(attempt)))(function () {
                    if (attempt instanceof Data_Either.Right) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.ConvertToRegularComponentSuccess(v1.value0)))(function () {
                            return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.GetEstimationComponentsRequest({
                                projectId: v1.value0.projectId
                            })))(function () {
                                return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshEstimationProjectsData({
                                    request_params: v1.value0.projectId
                                })));
                            });
                        });
                    };
                    if (attempt instanceof Data_Either.Left) {
                        return genericFetchError(v)(attempt.value0);
                    };
                    throw new Error("Failed pattern match at FetchMiddleware (line 738, column 3 - line 744, column 35): " + [ attempt.constructor.name ]);
                });
            });
        };
        if (v1 instanceof Actions.ConvertToTopicComponentRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.estimationRoutes.convertToTopicComponent)({
                projectId: v1.value0.projectId
            })(v1.value0.params.value0)))(function (attempt) {
                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(v1.value0.handler(attempt)))(function () {
                    if (attempt instanceof Data_Either.Right) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.ConvertToTopicComponentSuccess(v1.value0)))(function () {
                            return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.GetEstimationComponentsRequest({
                                projectId: v1.value0.projectId
                            })))(function () {
                                return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshEstimationProjectsData({
                                    request_params: v1.value0.projectId
                                })));
                            });
                        });
                    };
                    if (attempt instanceof Data_Either.Left) {
                        return genericFetchError(v)(attempt.value0);
                    };
                    throw new Error("Failed pattern match at FetchMiddleware (line 751, column 3 - line 757, column 35): " + [ attempt.constructor.name ]);
                });
            });
        };
        if (v1 instanceof Actions.EstimationResourcePriceListActionRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.estimationRoutes.estimationResourcePriceListAction)({
                projectId: v1.value0.projectId
            })(v1.value0.params)))(function (attempt) {
                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(v1.value0.handler(attempt)))(function () {
                    if (attempt instanceof Data_Either.Right) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(handleAction(v)(new Actions.GetEstimationResourcePriceListRequest({
                            projectId: v1.value0.projectId
                        })))(function () {
                            return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(handleAction(v)(Actions.GetOrgPriceListTreeRequest.value))(function () {
                                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.EstimationResourcePriceListActionSuccess(v1.value0)))(function () {
                                    return refreshEstimationProjectsData(v)(v1.value0.projectId);
                                });
                            });
                        });
                    };
                    if (attempt instanceof Data_Either.Left) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.EstimationResourcePriceListActionFailure(v1.value0)))(function () {
                            return genericFetchError(v)(attempt.value0);
                        });
                    };
                    throw new Error("Failed pattern match at FetchMiddleware (line 763, column 3 - line 772, column 35): " + [ attempt.constructor.name ]);
                });
            });
        };
        if (v1 instanceof Actions.GetUserRolesRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.serverRoutes.roles)({})(function (attempt) {
                return handleGenericAttempt(v)(attempt)(Actions.GetUserRolesSuccess.create)(Data_Function["const"](Actions.GetUserRolesError.value));
            });
        };
        if (v1 instanceof Actions.SetEstimationComponentCostClassPriceRequest) {
            var updateBody = {
                pricePerUnit: v1.value0.pricePerUnit
            };
            var handleAttempt = function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.SetEstimationComponentCostClassPriceSuccess({
                        projectId: v1.value0.projectId,
                        wasmEstimationResource: attempt.value0.estimationResource
                    })))(function () {
                        return refreshEstimationProjectsData(v)(v1.value0.projectId);
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.SetEstimationComponentCostClassPriceFailure.value))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 784, column 7 - line 791, column 39): " + [ attempt.constructor.name ]);
            };
            return Control_Bind.bind(Effect_Aff.bindAff)((function () {
                if (v1.value0.estimationResourceId instanceof Data_Maybe.Just) {
                    var updateParams = {
                        projectId: v1.value0.projectId,
                        estimationResourceId: v1.value0.estimationResourceId.value0
                    };
                    return Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.estimationRoutes.updateEstimationResourcePrice)(updateParams)(updateBody));
                };
                if (v1.value0.estimationResourceId instanceof Data_Maybe.Nothing) {
                    var updateParams = {
                        projectId: v1.value0.projectId,
                        estimationComponentId: v1.value0.estimationComponentId,
                        costClassCode: v1.value0.costClassCode
                    };
                    return Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.estimationRoutes.insertEstimationComponentCostClassPrice)(updateParams)(updateBody));
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 793, column 14 - line 799, column 111): " + [ v1.value0.estimationResourceId.constructor.name ]);
            })())(function (attempt) {
                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(v1.value0.handler(attempt)))(function () {
                    return handleAttempt(attempt);
                });
            });
        };
        if (v1 instanceof Actions.AddProjectManagerRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.projectRoutes.addProjectManager)({})(v1.value0)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    if (attempt.value0 instanceof Types_Project.EmptyPMName) {
                        return dispatchAlertError(v)("error_empty_pm_name");
                    };
                    if (attempt.value0 instanceof Types_Project.TooLongPMName) {
                        return dispatchAlertError(v)("error_too_long_pm_name");
                    };
                    if (attempt.value0 instanceof Types_Project.DuplicatePM) {
                        return dispatchAlertError(v)("error_duplicate_project_managers");
                    };
                    if (attempt.value0 instanceof Types_Project.AddPMSuccess) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(dispatchAlertInfo(v)("new_project_manager_has_been_added"))(function () {
                            return v(Actions.GetProjectManagersRequest.value);
                        });
                    };
                    throw new Error("Failed pattern match at FetchMiddleware (line 807, column 7 - line 813, column 53): " + [ attempt.value0.constructor.name ]);
                };
                if (attempt instanceof Data_Either.Left) {
                    return genericFetchError(v)(attempt.value0);
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 805, column 3 - line 814, column 43): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.DeleteProjectManagerRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.projectRoutes.deleteProjectManager)({})(v1.value0)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    if (attempt.value0 instanceof Types_Project.DoesntExist) {
                        return dispatchAlertError(v)("error_pm_doesnt_exist");
                    };
                    if (attempt.value0 instanceof Types_Project.DeletePMSuccess) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(dispatchAlertInfo(v)("project_manager_has_been_deleted"))(function () {
                            return v(Actions.GetProjectManagersRequest.value);
                        });
                    };
                    throw new Error("Failed pattern match at FetchMiddleware (line 820, column 7 - line 825, column 53): " + [ attempt.value0.constructor.name ]);
                };
                if (attempt instanceof Data_Either.Left) {
                    return genericFetchError(v)(attempt.value0);
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 818, column 3 - line 826, column 43): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.EditProjectManagerRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.projectRoutes.editProjectManager)({})(v1.value0)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    if (attempt.value0 instanceof Types_Project.EmptyPMName) {
                        return dispatchAlertError(v)("error_empty_pm_name");
                    };
                    if (attempt.value0 instanceof Types_Project.TooLongPMName) {
                        return dispatchAlertError(v)("error_too_long_pm_name");
                    };
                    if (attempt.value0 instanceof Types_Project.DuplicatePM) {
                        return dispatchAlertError(v)("error_duplicate_project_managers");
                    };
                    if (attempt.value0 instanceof Types_Project.AddPMSuccess) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(dispatchAlertInfo(v)("project_manager_has_been_edited"))(function () {
                            return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.GetProjectManagersRequest.value))(function () {
                                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.GetProjectReset.value))(function () {
                                    return v(Actions.PortfolioReportRequest.value);
                                });
                            });
                        });
                    };
                    throw new Error("Failed pattern match at FetchMiddleware (line 832, column 7 - line 843, column 50): " + [ attempt.value0.constructor.name ]);
                };
                if (attempt instanceof Data_Either.Left) {
                    return genericFetchError(v)(attempt.value0);
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 830, column 3 - line 844, column 43): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.UpdateProjectResponsibilityInfoAction) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.projectRoutes.updateProjectResponsibilityInfo)({
                projectId: v1.value0.projectId
            })(v1.value0.params)))(function (attempt) {
                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(v1.value0.handler(attempt)))(function () {
                    if (attempt instanceof Data_Either.Right) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.UpdateProjectResponsibilityInfoSuccess.value))(function () {
                            return handleAction(v)(new Actions.ProjectResponsibilityInfoRequest({
                                projectId: v1.value0.projectId
                            }));
                        });
                    };
                    if (attempt instanceof Data_Either.Left) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.UpdateProjectResponsibilityInfoFailure.value))(function () {
                            return genericFetchError(v)(attempt.value0);
                        });
                    };
                    throw new Error("Failed pattern match at FetchMiddleware (line 850, column 3 - line 856, column 35): " + [ attempt.constructor.name ]);
                });
            });
        };
        if (v1 instanceof Actions.UpdateCostControlSocialExpensesAction) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.serverRoutes.updateCostControlSocialExpenses)({
                projectId: v1.value0.projectId
            })(v1.value0.params)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.UpdateCostControlSocialExpensesSuccess.value))(function () {
                        return v(new Actions.ProjectCostClassesRequest({
                            projectId: v1.value0.projectId
                        }));
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.UpdateCostControlSocialExpensesFailure.value))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 860, column 3 - line 866, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.EditCostGroupNamesRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.costGroupRoutes.editCostGroupNames)({
                projectId: v1.value0.projectId
            })(v1.value0.params)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(refreshCostControlData(v)(v1.value0.projectId))(function () {
                        var successAction = new Actions.EditCostGroupNamesSuccess({
                            projectId: v1.value0.projectId,
                            params: v1.value0.params
                        });
                        return v(successAction);
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.EditCostGroupNamesFailure.value))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 870, column 3 - line 877, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.EditMainCostGroupNamesRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.costGroupRoutes.editMainCostGroupNames)({
                projectId: v1.value0.projectId
            })(v1.value0.params)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(handleAction(v)(new Actions.MainCostGroupsRequest({
                        projectId: v1.value0.projectId
                    })))(function () {
                        var successAction = new Actions.EditMainCostGroupNamesSuccess({
                            projectId: v1.value0.projectId,
                            params: v1.value0.params
                        });
                        return v(successAction);
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.EditMainCostGroupNamesFailure.value))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 881, column 3 - line 888, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.AddCostGroupRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.costGroupRoutes.addCostGroup)({
                projectId: v1.value0.projectId
            })(v1.value0.value)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(refreshCostControlData(v)(v1.value0.projectId))(function () {
                        return v(Actions.AddCostGroupSuccess.value);
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    var v2 = TofuJSON.readJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                        reflectSymbol: function () {
                            return "msg";
                        }
                    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                        reflectSymbol: function () {
                            return "type";
                        }
                    })(Types_TypeString.tofuJSON({
                        reflectSymbol: function () {
                            return "AddCostGroupDuplicateRowError";
                        }
                    }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                        reflectSymbol: function () {
                            return "type";
                        }
                    })(Types_TypeString.tofuJSON({
                        reflectSymbol: function () {
                            return "AddCostGroupDuplicateRowError";
                        }
                    }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                        reflectSymbol: function () {
                            return "msg";
                        }
                    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                        reflectSymbol: function () {
                            return "type";
                        }
                    })(Types_TypeString.tofuJSON({
                        reflectSymbol: function () {
                            return "AddCostGroupDuplicateRowError";
                        }
                    }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                        reflectSymbol: function () {
                            return "type";
                        }
                    })(Types_TypeString.tofuJSON({
                        reflectSymbol: function () {
                            return "AddCostGroupDuplicateRowError";
                        }
                    }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.nilWriteForeignFields)()()()))(Effect_Exception.message(attempt.value0));
                    if (v2 instanceof Data_Either.Right) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Utils.printError(Effect_Aff.monadEffectAff)(attempt.value0))(function () {
                            return dispatchAlertError(v)("error_add_cost_group_duplicate_row");
                        });
                    };
                    if (v2 instanceof Data_Either.Left) {
                        return genericFetchError(v)(attempt.value0);
                    };
                    throw new Error("Failed pattern match at FetchMiddleware (line 897, column 7 - line 902, column 39): " + [ v2.constructor.name ]);
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 892, column 3 - line 902, column 39): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.UpdateEstimationComponentMemoRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.estimationRoutes.updateEstimationComponentMemo)({
                projectId: v1.value0.projectId
            })(v1.value0.params)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(refreshEstimationProjectsData(v)(v1.value0.projectId))(function () {
                        return v(new Actions.UpdateEstimationComponentMemoSuccess(v1.value0));
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.UpdateEstimationComponentMemoError(v1.value0)))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 906, column 3 - line 912, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.UpdateCostGroupMemoRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.costGroupRoutes.updateCostGroupMemo)({
                projectId: v1.value0.projectId,
                costGroupId: v1.value0.costGroupId
            })({
                value: v1.value0.value
            })))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(handleAction(v)(new Actions.CostGroupInfosRequest({
                        projectId: v1.value0.projectId
                    })))(function () {
                        return v(new Actions.UpdateCostGroupMemoSuccess({
                            costGroupId: v1.value0.costGroupId
                        }));
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.UpdateCostGroupMemoError({
                        costGroupId: v1.value0.costGroupId
                    })))(function () {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.ProjectCostGroupsError.value))(function () {
                            return genericFetchError(v)(attempt.value0);
                        });
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 916, column 3 - line 923, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.UpdateCostGroupCompletionPercentageRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.costGroupRoutes.updateCostGroupCompletionPercentage)({
                projectId: v1.value0.projectId,
                costGroupId: v1.value0.costGroupId
            })({
                value: v1.value0.value
            })))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(refreshCostControlData(v)(v1.value0.projectId))(function () {
                        return v(new Actions.UpdateCostGroupCompletionPercentageSuccess({
                            costGroupId: v1.value0.costGroupId
                        }));
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.UpdateCostGroupCompletionPercentageError({
                        costGroupId: v1.value0.costGroupId
                    })))(function () {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.ProjectCostGroupsError.value))(function () {
                            return genericFetchError(v)(attempt.value0);
                        });
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 927, column 3 - line 934, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.DeleteCostGroupsRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.costGroupRoutes.deleteCostGroups)({
                projectId: v1.value0.projectId
            })(v1.value0.value)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return handleAction(v)(new Actions.CostGroupInfosRequest({
                        projectId: v1.value0.projectId
                    }));
                };
                if (attempt instanceof Data_Either.Left) {
                    return genericFetchError(v)(attempt.value0);
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 938, column 3 - line 941, column 43): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.DeleteSalesInvoicesRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.invoiceRoutes.deleteSalesInvoices)({
                projectId: v1.value0.projectId
            })(v1.value0.value)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return handleAction(v)(new Actions.GetSalesInvoicesRequest({
                        projectId: v1.value0.projectId
                    }));
                };
                if (attempt instanceof Data_Either.Left) {
                    return genericFetchError(v)(attempt.value0);
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 945, column 3 - line 948, column 43): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.UpdateCostGroupCategoryRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.costGroupRoutes.updateCostGroupCategory)({
                projectId: v1.value0.projectId,
                costGroupId: v1.value0.costGroupId
            })({
                costGroupCategory: v1.value0.costGroupCategory
            })))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return handleAction(v)(new Actions.CostGroupInfosRequest({
                        projectId: v1.value0.projectId
                    }));
                };
                if (attempt instanceof Data_Either.Left) {
                    return genericFetchError(v)(attempt.value0);
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 952, column 3 - line 955, column 43): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.UpdateCodeForSalesInvoiceRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.invoiceRoutes.updateCodeForSalesInvoice)({
                projectId: v1.value0.projectId
            })(v1.value0.params)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return handleAction(v)(new Actions.GetSalesInvoicesRequest({
                        projectId: v1.value0.projectId
                    }));
                };
                if (attempt instanceof Data_Either.Left) {
                    return genericFetchError(v)(attempt.value0);
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 959, column 3 - line 962, column 43): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.UpdateMeasurementValue) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.serverRoutes.updateMeasurementValue)({
                projectId: v1.value0.projectId
            })(v1.value0.params)))(function (attempt) {
                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(v1.value0.handler(attempt)))(function () {
                    if (attempt instanceof Data_Either.Right) {
                        return handleAction(v)(new Actions.GetMeasurementsRequest({
                            projectId: v1.value0.projectId
                        }));
                    };
                    if (attempt instanceof Data_Either.Left) {
                        return genericFetchError(v)(attempt.value0);
                    };
                    throw new Error("Failed pattern match at FetchMiddleware (line 968, column 3 - line 971, column 43): " + [ attempt.constructor.name ]);
                });
            });
        };
        if (v1 instanceof Actions.MeasurementUnitAction) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.serverRoutes.measurementUnitAction)({
                projectId: v1.value0.projectId
            })(v1.value0.params)))(function (attempt) {
                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(v1.value0.handler(attempt)))(function () {
                    if (attempt instanceof Data_Either.Right) {
                        return handleAction(v)(new Actions.GetMeasurementsRequest({
                            projectId: v1.value0.projectId
                        }));
                    };
                    if (attempt instanceof Data_Either.Left) {
                        return genericFetchError(v)(attempt.value0);
                    };
                    throw new Error("Failed pattern match at FetchMiddleware (line 976, column 3 - line 979, column 43): " + [ attempt.constructor.name ]);
                });
            });
        };
        if (v1 instanceof Actions.ChangeOrganizationsRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.serverRoutes.changeOrganization)({})({
                organization: v1.value0
            })))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    var url = RouteUtils.routeToUrl(RouteUtils.removeProjectFromRoute(v1.value1));
                    return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Web_HTML.window))(function (window) {
                        return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Web_HTML_Window.location(window)))(function (location) {
                            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Web_HTML_Location.setHref(url)(location)))(function () {
                                return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Unit.unit);
                            });
                        });
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)((function () {
                        var v2 = TofuJSON.readJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "msg";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "type";
                            }
                        })(Types_TypeString.tofuJSON({
                            reflectSymbol: function () {
                                return "UserNotAllowedToChangeOrgError";
                            }
                        }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "type";
                            }
                        })(Types_TypeString.tofuJSON({
                            reflectSymbol: function () {
                                return "UserNotAllowedToChangeOrgError";
                            }
                        }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "msg";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "type";
                            }
                        })(Types_TypeString.tofuJSON({
                            reflectSymbol: function () {
                                return "UserNotAllowedToChangeOrgError";
                            }
                        }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "type";
                            }
                        })(Types_TypeString.tofuJSON({
                            reflectSymbol: function () {
                                return "UserNotAllowedToChangeOrgError";
                            }
                        }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.nilWriteForeignFields)()()()))(Effect_Exception.message(attempt.value0));
                        if (v2 instanceof Data_Either.Right) {
                            return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Utils.printError(Effect_Aff.monadEffectAff)(attempt.value0))(function () {
                                return dispatchAlertError(v)("Sinulla ei ole oikeutta vaihtaa organisaatiota. Ota yhteys tukeen.");
                            });
                        };
                        if (v2 instanceof Data_Either.Left) {
                            return genericFetchError(v)(attempt.value0);
                        };
                        throw new Error("Failed pattern match at FetchMiddleware (line 991, column 7 - line 996, column 39): " + [ v2.constructor.name ]);
                    })())(function () {
                        return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Unit.unit);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 983, column 3 - line 997, column 16): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.AddIncomeItemsRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.incomeRoutes.addIncomeItems)({
                projectId: v1.value0.projectId
            })(v1.value0.params)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(handleAction(v)(new Actions.GetIncomeItemsRequest({
                        projectId: v1.value0.projectId
                    })))(function () {
                        return v(Actions.AddIncomeItemsSuccess.value);
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.AddIncomeItemsFailure.value))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1001, column 3 - line 1007, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.RemoveIncomeItemsRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.incomeRoutes.removeIncomeItems)({
                projectId: v1.value0.projectId
            })(v1.value0.params)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.RemoveIncomeItemsSuccess(v1.value0)))(function () {
                        return handleAction(v)(new Actions.GetIncomeItemsRequest({
                            projectId: v1.value0.projectId
                        }));
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.RemoveIncomeItemsFailure.value))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1011, column 3 - line 1017, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.UpdateIncomeItemRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.incomeRoutes.updateIncomeItem)({
                projectId: v1.value0.projectId
            })(v1.value0.params)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.UpdateIncomeItemSuccess(v1.value0)))(function () {
                        return handleAction(v)(new Actions.GetIncomeItemsRequest({
                            projectId: v1.value0.projectId
                        }));
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.UpdateIncomeItemFailure(v1.value0)))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1021, column 3 - line 1027, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.SetIncomeItemApprovalStatusesRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.incomeRoutes.setIncomeItemApprovalStatuses)({
                projectId: v1.value0.projectId
            })(v1.value0.statuses)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(handleAction(v)(new Actions.GetIncomeItemsRequest({
                        projectId: v1.value0.projectId
                    })))(function () {
                        return v(new Actions.SetIncomeItemApprovalStatusesSuccess({
                            statuses: v1.value0.statuses
                        }));
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.SetIncomeItemApprovalStatusesError.value))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1031, column 3 - line 1037, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.SetIncomeItemApprovalDatesRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.incomeRoutes.setIncomeItemApprovalDates)({
                projectId: v1.value0.projectId
            })(v1.value0.dates)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(handleAction(v)(new Actions.GetIncomeItemsRequest({
                        projectId: v1.value0.projectId
                    })))(function () {
                        return v(new Actions.SetIncomeItemApprovalDatesSuccess({
                            dates: v1.value0.dates
                        }));
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.SetIncomeItemApprovalDatesError.value))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1041, column 3 - line 1047, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.SetIncomeItemPlannedInvoiceDatesRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.invoiceRoutes.setIncomeItemPlannedInvoiceDates)({
                projectId: v1.value0.projectId
            })(v1.value0.dates)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(handleAction(v)(new Actions.GetIncomeItemsRequest({
                        projectId: v1.value0.projectId
                    })))(function () {
                        return v(new Actions.SetIncomeItemPlannedInvoiceDatesSuccess(v1.value0.dates));
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.SetIncomeItemPlannedInvoiceDatesError.value))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1051, column 3 - line 1057, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.SetIncomeItemsInclusionInTargetRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.incomeRoutes.setIncomeItemsInclusionInTarget)({
                projectId: v1.value0.projectId
            })(v1.value0.inclusionUpdates)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(handleAction(v)(new Actions.GetIncomeItemsRequest({
                        projectId: v1.value0.projectId
                    })))(function () {
                        return v(new Actions.SetIncomeItemsInclusionInTargetSuccess(v1.value0.inclusionUpdates));
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.SetIncomeItemsInclusionInTargetError.value))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1061, column 3 - line 1067, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.AddProjectRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.projectRoutes.addProject)({})(v1.value0.value)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(dispatchAlertInfo(v)("new_project_added"))(function () {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Data_Monoid.guard(Effect_Aff.monoidAff(Data_Monoid.monoidUnit))(v1.value0.value.enableCostControl)(Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.NavigateRoute(Types_FrontEndRoutes.ReportingProjectListRoute.value)))(function () {
                            return v(Actions.CostControlProjectsRequest.value);
                        })))(function () {
                            return Data_Monoid.guard(Effect_Aff.monoidAff(Data_Monoid.monoidUnit))(v1.value0.value.enableEstimation)(Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.NavigateRoute(Types_FrontEndRoutes.EstimationProjectsRoute.value)))(function () {
                                return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshEstimationProjects({})));
                            }));
                        });
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    var v2 = TofuJSON.readJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                        reflectSymbol: function () {
                            return "msg";
                        }
                    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                        reflectSymbol: function () {
                            return "type";
                        }
                    })(Types_TypeString.tofuJSON({
                        reflectSymbol: function () {
                            return "AddProjectDuplicateCodeAndVersionError";
                        }
                    }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                        reflectSymbol: function () {
                            return "type";
                        }
                    })(Types_TypeString.tofuJSON({
                        reflectSymbol: function () {
                            return "AddProjectDuplicateCodeAndVersionError";
                        }
                    }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                        reflectSymbol: function () {
                            return "msg";
                        }
                    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                        reflectSymbol: function () {
                            return "type";
                        }
                    })(Types_TypeString.tofuJSON({
                        reflectSymbol: function () {
                            return "AddProjectDuplicateCodeAndVersionError";
                        }
                    }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                        reflectSymbol: function () {
                            return "type";
                        }
                    })(Types_TypeString.tofuJSON({
                        reflectSymbol: function () {
                            return "AddProjectDuplicateCodeAndVersionError";
                        }
                    }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.nilWriteForeignFields)()()()))(Effect_Exception.message(attempt.value0));
                    if (v2 instanceof Data_Either.Right) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Utils.printError(Effect_Aff.monadEffectAff)(attempt.value0))(function () {
                            return dispatchAlertError(v)("error_add_project_duplicate_code_and_version");
                        });
                    };
                    if (v2 instanceof Data_Either.Left) {
                        return genericFetchError(v)(attempt.value0);
                    };
                    throw new Error("Failed pattern match at FetchMiddleware (line 1081, column 7 - line 1086, column 39): " + [ v2.constructor.name ]);
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1071, column 3 - line 1086, column 39): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.CopyProjectRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.projectRoutes.copyProject)({})(v1.value0.value)))(function (attempt) {
                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(v1.value0.handler(attempt)))(function () {
                    if (attempt instanceof Data_Either.Right) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(dispatchAlertInfo(v)("new_project_added"))(function () {
                            return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.NavigateRoute(Types_FrontEndRoutes.EstimationProjectsRoute.value)))(function () {
                                return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshEstimationProjects({})));
                            });
                        });
                    };
                    if (attempt instanceof Data_Either.Left) {
                        return genericFetchError(v)(attempt.value0);
                    };
                    throw new Error("Failed pattern match at FetchMiddleware (line 1091, column 3 - line 1097, column 35): " + [ attempt.constructor.name ]);
                });
            });
        };
        if (v1 instanceof Actions.UpdateProjectRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.projectRoutes.updateProject)({
                projectId: v1.value0.projectId
            })(v1.value0.value)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(dispatchAlertInfo(v)("project_info_updated"))(function () {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.GetProjectRequest({
                            projectId: v1.value0.projectId
                        })))(function () {
                            if (v1.value0.fromCostControl) {
                                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.PortfolioReportRequest.value))(function () {
                                    return v(Actions.CostControlProjectsRequest.value);
                                });
                            };
                            return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.EstimationProjectsRequest.value))(function () {
                                return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshEstimationProjects({})));
                            });
                        });
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    var v2 = TofuJSON.readJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                        reflectSymbol: function () {
                            return "msg";
                        }
                    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                        reflectSymbol: function () {
                            return "type";
                        }
                    })(Types_TypeString.tofuJSON({
                        reflectSymbol: function () {
                            return "AddProjectDuplicateCodeAndVersionError";
                        }
                    }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                        reflectSymbol: function () {
                            return "type";
                        }
                    })(Types_TypeString.tofuJSON({
                        reflectSymbol: function () {
                            return "AddProjectDuplicateCodeAndVersionError";
                        }
                    }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                        reflectSymbol: function () {
                            return "msg";
                        }
                    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                        reflectSymbol: function () {
                            return "type";
                        }
                    })(Types_TypeString.tofuJSON({
                        reflectSymbol: function () {
                            return "AddProjectDuplicateCodeAndVersionError";
                        }
                    }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                        reflectSymbol: function () {
                            return "type";
                        }
                    })(Types_TypeString.tofuJSON({
                        reflectSymbol: function () {
                            return "AddProjectDuplicateCodeAndVersionError";
                        }
                    }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.nilWriteForeignFields)()()()))(Effect_Exception.message(attempt.value0));
                    if (v2 instanceof Data_Either.Right) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Utils.printError(Effect_Aff.monadEffectAff)(attempt.value0))(function () {
                            return dispatchAlertError(v)("error_add_project_duplicate_code_and_version");
                        });
                    };
                    if (v2 instanceof Data_Either.Left) {
                        return genericFetchError(v)(attempt.value0);
                    };
                    throw new Error("Failed pattern match at FetchMiddleware (line 1113, column 8 - line 1118, column 40): " + [ v2.constructor.name ]);
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1101, column 3 - line 1118, column 40): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.UpdateProjectArchiveStatusRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.projectRoutes.updateProjectArchiveStatus)({
                projectId: v1.value0.projectId
            })(v1.value0.value)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.AddAlert(Types_Alert.AlertWarning.value, "Hanke poistettu")))(function () {
                        if (v1.value0.value.applicationMode instanceof Types_Project.ReportingMode) {
                            return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.CostControlProjectsRequest.value))(function () {
                                return v(new Actions.NavigateRoute(Types_FrontEndRoutes.ReportingProjectListRoute.value));
                            });
                        };
                        if (v1.value0.value.applicationMode instanceof Types_Project.EstimationMode) {
                            return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshEstimationProjects({}))))(function () {
                                return v(new Actions.NavigateRoute(Types_FrontEndRoutes.EstimationProjectsRoute.value));
                            });
                        };
                        return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Unit.unit);
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return genericFetchError(v)(attempt.value0);
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1123, column 3 - line 1135, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.AddIncomeGroupRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.incomeRoutes.addIncomeGroup)({
                projectId: v1.value0.projectId
            })(v1.value0.value)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(dispatchAlertInfo(v)("new_income_group_added"))(function () {
                        return v(new Actions.GetIncomeGroupsRequest({
                            projectId: v1.value0.projectId
                        }));
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    var v2 = TofuJSON.readJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                        reflectSymbol: function () {
                            return "msg";
                        }
                    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                        reflectSymbol: function () {
                            return "type";
                        }
                    })(Types_TypeString.tofuJSON({
                        reflectSymbol: function () {
                            return "AddIncomeGroupDuplicateProjectIdAndGroupError";
                        }
                    }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                        reflectSymbol: function () {
                            return "type";
                        }
                    })(Types_TypeString.tofuJSON({
                        reflectSymbol: function () {
                            return "AddIncomeGroupDuplicateProjectIdAndGroupError";
                        }
                    }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                        reflectSymbol: function () {
                            return "msg";
                        }
                    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                        reflectSymbol: function () {
                            return "type";
                        }
                    })(Types_TypeString.tofuJSON({
                        reflectSymbol: function () {
                            return "AddIncomeGroupDuplicateProjectIdAndGroupError";
                        }
                    }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                        reflectSymbol: function () {
                            return "type";
                        }
                    })(Types_TypeString.tofuJSON({
                        reflectSymbol: function () {
                            return "AddIncomeGroupDuplicateProjectIdAndGroupError";
                        }
                    }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.nilWriteForeignFields)()()()))(Effect_Exception.message(attempt.value0));
                    if (v2 instanceof Data_Either.Right) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Utils.printError(Effect_Aff.monadEffectAff)(attempt.value0))(function () {
                            return dispatchAlertError(v)("error_add_incomeItem_duplicate_project_and_group");
                        });
                    };
                    if (v2 instanceof Data_Either.Left) {
                        return genericFetchError(v)(attempt.value0);
                    };
                    throw new Error("Failed pattern match at FetchMiddleware (line 1144, column 7 - line 1149, column 39): " + [ v2.constructor.name ]);
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1139, column 3 - line 1149, column 39): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.UpdateInvoiceLineBunchesRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.invoiceRoutes.updateInvoiceLineBunches)({
                projectId: v1.value0.projectId
            })(v1.value0.params)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(handleAction(v)(new Actions.GetInvoicesRequest({
                        projectId: v1.value0.projectId
                    })))(function () {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(handleAction(v)(new Actions.InvoiceBunchesRequest({
                            projectId: v1.value0.projectId
                        })))(function () {
                            return v(Actions.UpdateInvoiceLineBunchesSuccess.value);
                        });
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class_Console.error(Effect_Aff.monadEffectAff)("error on edit invoice cost group code request: " + Effect_Exception.message(attempt.value0)))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1153, column 3 - line 1160, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.UpdateInvoiceLinesCostGroupCodeRequest) {
            var encodedCostGroupCode = Utils["encodeURIComponent'"]()(v1.value0.costGroupCode);
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.invoiceRoutes.updateInvoiceLinesCostGroup)({
                projectId: v1.value0.projectId,
                encodedCostGroupCode: encodedCostGroupCode
            })({
                invoiceLineIds: v1.value0.invoiceLineIds
            })))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(handleAction(v)(new Actions.GetInvoicesRequest({
                        projectId: v1.value0.projectId
                    })))(function () {
                        return v(Actions.UpdateInvoiceLinesCostGroupCodeSuccess.value);
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class_Console.error(Effect_Aff.monadEffectAff)("error on edit invoice line cost group code request: " + Effect_Exception.message(attempt.value0)))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1165, column 3 - line 1171, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.UpdateInvoiceLinesCostClassCodeRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.invoiceRoutes.updateInvoiceLinesCostClass)({
                projectId: v1.value0.projectId,
                costClassCode: v1.value0.costClassCode
            })({
                invoiceLineIds: v1.value0.invoiceLineIds
            })))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(handleAction(v)(new Actions.GetInvoicesRequest({
                        projectId: v1.value0.projectId
                    })))(function () {
                        return v(Actions.UpdateInvoiceLinesCostClassCodeSuccess.value);
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class_Console.error(Effect_Aff.monadEffectAff)("error on edit invoice line cost class code request: " + Effect_Exception.message(attempt.value0)))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1175, column 3 - line 1181, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.UpdateReportingResourceCostGroupCodeRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.costGroupRoutes.updateReportingResourceCostGroupCode)({
                projectId: v1.value0.projectId
            })(v1.value0.params)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(handleAction(v)(new Actions.GetReportingResourcesRequest({
                        projectId: v1.value0.projectId
                    })))(function () {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(handleAction(v)(new Actions.GetReportingComponentsRequest({
                            projectId: v1.value0.projectId
                        })))(function () {
                            return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(handleAction(v)(new Actions.CostGroupInfosRequest({
                                projectId: v1.value0.projectId
                            })))(function () {
                                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(handleAction(v)(new Actions.ProjectTargetItemsRequest({
                                    projectId: v1.value0.projectId
                                })))(function () {
                                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(handleAction(v)(new Actions.GetIncomeItemsRequest({
                                        projectId: v1.value0.projectId
                                    })))(function () {
                                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(handleAction(v)(new Actions.ProjectEstimateItemsRequest({
                                            projectId: v1.value0.projectId
                                        })))(function () {
                                            return v(new Actions.UpdateReportingResourceCostGroupCodeSuccess(v1.value0));
                                        });
                                    });
                                });
                            });
                        });
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.UpdateReportingResourceCostGroupCodeFailure.value))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1185, column 3 - line 1196, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.CostGroupItemActionRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.costGroupRoutes.costGroupItemAction)({
                projectId: v1.value0.projectId
            })(v1.value0.params)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(refreshCostControlData(v)(v1.value0.projectId))(function () {
                        return v(new Actions.CostGroupItemActionSuccess(v1.value0));
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.CostGroupItemActionError(v1.value0)))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1200, column 3 - line 1206, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.EstimationElementActionRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.estimationRoutes.estimationElementAction)({
                projectId: v1.value0.projectId
            })(v1.value0.params)))(function (attempt) {
                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(v1.value0.handler(attempt)))(function () {
                    if (attempt instanceof Data_Either.Right) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.EstimationElementActionSuccess(v1.value0)))(function () {
                            return refreshEstimationProjectsData(v)(v1.value0.projectId);
                        });
                    };
                    if (attempt instanceof Data_Either.Left) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.EstimationElementActionError({
                            projectId: v1.value0.projectId,
                            params: v1.value0.params
                        })))(function () {
                            return genericFetchError(v)(attempt.value0);
                        });
                    };
                    throw new Error("Failed pattern match at FetchMiddleware (line 1212, column 3 - line 1218, column 35): " + [ attempt.constructor.name ]);
                });
            });
        };
        if (v1 instanceof Actions.SubprojectActionRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.projectRoutes.subprojectAction)({
                projectId: v1.value0.projectId
            })(v1.value0.params)))(function (attempt) {
                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(v1.value0.handler(attempt)))(function () {
                    if (attempt instanceof Data_Either.Right) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.SubprojectActionSuccess(v1.value0)))(function () {
                            return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshSubprojects({
                                request_params: v1.value0.projectId
                            }))))(function () {
                                return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshEstimationProjectsData({
                                    request_params: v1.value0.projectId
                                })));
                            });
                        });
                    };
                    if (attempt instanceof Data_Either.Left) {
                        var v2 = TofuJSON.readJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "msg";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "type";
                            }
                        })(Types_TypeString.tofuJSON({
                            reflectSymbol: function () {
                                return "AddAlakohdeDuplicateRowError";
                            }
                        }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "type";
                            }
                        })(Types_TypeString.tofuJSON({
                            reflectSymbol: function () {
                                return "AddAlakohdeDuplicateRowError";
                            }
                        }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "msg";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "type";
                            }
                        })(Types_TypeString.tofuJSON({
                            reflectSymbol: function () {
                                return "AddAlakohdeDuplicateRowError";
                            }
                        }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "type";
                            }
                        })(Types_TypeString.tofuJSON({
                            reflectSymbol: function () {
                                return "AddAlakohdeDuplicateRowError";
                            }
                        }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.nilWriteForeignFields)()()()))(Effect_Exception.message(attempt.value0));
                        if (v2 instanceof Data_Either.Right) {
                            return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.SubprojectActionFailure(v1.value0)))(function () {
                                return dispatchAlertError(v)("error_add_alakohde_duplicate_row");
                            });
                        };
                        if (v2 instanceof Data_Either.Left) {
                            return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.SubprojectActionFailure(v1.value0)))(function () {
                                return genericFetchError(v)(attempt.value0);
                            });
                        };
                        throw new Error("Failed pattern match at FetchMiddleware (line 1229, column 7 - line 1235, column 39): " + [ v2.constructor.name ]);
                    };
                    throw new Error("Failed pattern match at FetchMiddleware (line 1223, column 3 - line 1235, column 39): " + [ attempt.constructor.name ]);
                });
            });
        };
        if (v1 instanceof Actions.ProjectClassificationClassActionRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.projectRoutes.projectClassificationClassAction)({
                projectId: v1.value0.projectId
            })(v1.value0.params)))(function (attempt) {
                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(v1.value0.handler(attempt)))(function () {
                    if (attempt instanceof Data_Either.Right) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.ProjectClassificationClassActionSuccess(v1.value0)))(function () {
                            return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshClassificationClasses({
                                request_params: v1.value0.projectId
                            })));
                        });
                    };
                    if (attempt instanceof Data_Either.Left) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.ProjectClassificationClassActionFailure(v1.value0)))(function () {
                            return genericFetchError(v)(attempt.value0);
                        });
                    };
                    throw new Error("Failed pattern match at FetchMiddleware (line 1240, column 3 - line 1246, column 35): " + [ attempt.constructor.name ]);
                });
            });
        };
        if (v1 instanceof Actions.ClassificationGroupActionRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.projectRoutes.classificationGroupAction)({
                projectId: v1.value0.projectId
            })(v1.value0.params)))(function (attempt) {
                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(v1.value0.handler(attempt)))(function () {
                    if (attempt instanceof Data_Either.Right) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.ClassificationGroupActionSuccess(v1.value0)))(function () {
                            return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshClassificationGroups({
                                request_params: v1.value0.projectId
                            }))))(function () {
                                return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshEstimationProjectsData({
                                    request_params: v1.value0.projectId
                                })));
                            });
                        });
                    };
                    if (attempt instanceof Data_Either.Left) {
                        var v2 = TofuJSON.readJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "msg";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "type";
                            }
                        })(Types_TypeString.tofuJSON({
                            reflectSymbol: function () {
                                return "AddGroupDuplicateRowError";
                            }
                        }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "type";
                            }
                        })(Types_TypeString.tofuJSON({
                            reflectSymbol: function () {
                                return "AddGroupDuplicateRowError";
                            }
                        }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "msg";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "type";
                            }
                        })(Types_TypeString.tofuJSON({
                            reflectSymbol: function () {
                                return "AddGroupDuplicateRowError";
                            }
                        }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "type";
                            }
                        })(Types_TypeString.tofuJSON({
                            reflectSymbol: function () {
                                return "AddGroupDuplicateRowError";
                            }
                        }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.nilWriteForeignFields)()()()))(Effect_Exception.message(attempt.value0));
                        if (v2 instanceof Data_Either.Right) {
                            return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.ClassificationGroupActionFailure(v1.value0)))(function () {
                                return dispatchAlertError(v)("error_add_cost_duplicate_row");
                            });
                        };
                        if (v2 instanceof Data_Either.Left) {
                            return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.ClassificationGroupActionFailure(v1.value0)))(function () {
                                return genericFetchError(v)(attempt.value0);
                            });
                        };
                        throw new Error("Failed pattern match at FetchMiddleware (line 1257, column 7 - line 1263, column 39): " + [ v2.constructor.name ]);
                    };
                    throw new Error("Failed pattern match at FetchMiddleware (line 1251, column 3 - line 1263, column 39): " + [ attempt.constructor.name ]);
                });
            });
        };
        if (v1 instanceof Actions.LocationActionRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.projectRoutes.locationAction)({
                projectId: v1.value0.projectId
            })(v1.value0.params)))(function (attempt) {
                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(v1.value0.handler(attempt)))(function () {
                    if (attempt instanceof Data_Either.Right) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.LocationActionSuccess(v1.value0)))(function () {
                            return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshProjectLocations({
                                request_params: v1.value0.projectId
                            })));
                        });
                    };
                    if (attempt instanceof Data_Either.Left) {
                        return v(new Actions.LocationActionFailure(v1.value0));
                    };
                    throw new Error("Failed pattern match at FetchMiddleware (line 1269, column 3 - line 1274, column 54): " + [ attempt.constructor.name ]);
                });
            });
        };
        if (v1 instanceof Actions.ElementMeasureActionRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.projectRoutes.elementMeasureAction)({
                projectId: v1.value0.projectId
            })(v1.value0.params)))(function (attempt) {
                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(v1.value0.handler(attempt)))(function () {
                    if (attempt instanceof Data_Either.Right) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.ElementMeasureActionSuccess(v1.value0)))(function () {
                            return refreshEstimationProjectsData(v)(v1.value0.projectId);
                        });
                    };
                    if (attempt instanceof Data_Either.Left) {
                        var v2 = TofuJSON.readJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "msg";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "type";
                            }
                        })(Types_TypeString.tofuJSON({
                            reflectSymbol: function () {
                                return "AddElementMeasureDuplicateCodeError";
                            }
                        }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "type";
                            }
                        })(Types_TypeString.tofuJSON({
                            reflectSymbol: function () {
                                return "AddElementMeasureDuplicateCodeError";
                            }
                        }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "msg";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "type";
                            }
                        })(Types_TypeString.tofuJSON({
                            reflectSymbol: function () {
                                return "AddElementMeasureDuplicateCodeError";
                            }
                        }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "type";
                            }
                        })(Types_TypeString.tofuJSON({
                            reflectSymbol: function () {
                                return "AddElementMeasureDuplicateCodeError";
                            }
                        }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.nilWriteForeignFields)()()()))(Effect_Exception.message(attempt.value0));
                        if (v2 instanceof Data_Either.Right) {
                            return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Utils.printError(Effect_Aff.monadEffectAff)(attempt.value0))(function () {
                                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(dispatchAlertError(v)("error_add_element_measure_duplicate_code"))(function () {
                                    return v(new Actions.ElementMeasureActionError({
                                        projectId: v1.value0.projectId,
                                        params: v1.value0.params
                                    }));
                                });
                            });
                        };
                        if (v2 instanceof Data_Either.Left) {
                            return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.ElementMeasureActionError({
                                projectId: v1.value0.projectId,
                                params: v1.value0.params
                            })))(function () {
                                return genericFetchError(v)(attempt.value0);
                            });
                        };
                        throw new Error("Failed pattern match at FetchMiddleware (line 1285, column 7 - line 1292, column 39): " + [ v2.constructor.name ]);
                    };
                    throw new Error("Failed pattern match at FetchMiddleware (line 1280, column 3 - line 1292, column 39): " + [ attempt.constructor.name ]);
                });
            });
        };
        if (v1 instanceof Actions.EstimationComponentActionRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.estimationRoutes.estimationComponentAction)({
                projectId: v1.value0.projectId
            })(v1.value0.params)))(function (attempt) {
                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(v1.value0.handler(attempt)))(function () {
                    if (attempt instanceof Data_Either.Right) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.EstimationComponentActionSuccess(v1.value0)))(function () {
                            return refreshEstimationProjectsData(v)(v1.value0.projectId);
                        });
                    };
                    if (attempt instanceof Data_Either.Left) {
                        var v2 = TofuJSON.readJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "msg";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "type";
                            }
                        })(Types_TypeString.tofuJSON({
                            reflectSymbol: function () {
                                return "CostGroupClassificationNotSetError";
                            }
                        }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "type";
                            }
                        })(Types_TypeString.tofuJSON({
                            reflectSymbol: function () {
                                return "CostGroupClassificationNotSetError";
                            }
                        }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "msg";
                            }
                        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                            reflectSymbol: function () {
                                return "type";
                            }
                        })(Types_TypeString.tofuJSON({
                            reflectSymbol: function () {
                                return "CostGroupClassificationNotSetError";
                            }
                        }))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                            reflectSymbol: function () {
                                return "type";
                            }
                        })(Types_TypeString.tofuJSON({
                            reflectSymbol: function () {
                                return "CostGroupClassificationNotSetError";
                            }
                        }))(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.nilWriteForeignFields)()()()))(Effect_Exception.message(attempt.value0));
                        if (v2 instanceof Data_Either.Right) {
                            return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Utils.printError(Effect_Aff.monadEffectAff)(attempt.value0))(function () {
                                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.AddAlert(Types_Alert.AlertError.value, "Valitse tavoitenimikkeist\xf6 ennen tavoitelitteran sy\xf6tt\xf6\xe4")))(function () {
                                    return v(new Actions.EstimationComponentActionError({
                                        projectId: v1.value0.projectId,
                                        params: v1.value0.params
                                    }));
                                });
                            });
                        };
                        if (v2 instanceof Data_Either.Left) {
                            return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.EstimationComponentActionError({
                                projectId: v1.value0.projectId,
                                params: v1.value0.params
                            })))(function () {
                                return genericFetchError(v)(attempt.value0);
                            });
                        };
                        throw new Error("Failed pattern match at FetchMiddleware (line 1303, column 7 - line 1311, column 39): " + [ v2.constructor.name ]);
                    };
                    throw new Error("Failed pattern match at FetchMiddleware (line 1298, column 3 - line 1311, column 39): " + [ attempt.constructor.name ]);
                });
            });
        };
        if (v1 instanceof Actions.EstimationResourceActionRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.estimationRoutes.estimationResourceAction)({
                projectId: v1.value0.projectId
            })(v1.value0.params)))(function (attempt) {
                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(v1.value0.handler(attempt)))(function () {
                    if (attempt instanceof Data_Either.Right) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.EstimationResourceActionSuccess(v1.value0)))(function () {
                            return refreshEstimationProjectsData(v)(v1.value0.projectId);
                        });
                    };
                    if (attempt instanceof Data_Either.Left) {
                        return genericFetchError(v)(attempt.value0);
                    };
                    throw new Error("Failed pattern match at FetchMiddleware (line 1317, column 3 - line 1322, column 35): " + [ attempt.constructor.name ]);
                });
            });
        };
        if (v1 instanceof Actions.UpdateSupplierBusinessIdRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.serverRoutes.updateSupplier)({})({
                supplier: v1.value0.supplier,
                businessId: v1.value0.businessId
            })))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.GetInvoicesRequest({
                        projectId: v1.value0.projectId
                    })))(function () {
                        return v(Actions.UpdateSupplierBusinessIdSuccess.value);
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class_Console.error(Effect_Aff.monadEffectAff)("error on update supplier business id request: " + Effect_Exception.message(attempt.value0)))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1326, column 3 - line 1332, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.InsertInvoiceRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.invoiceRoutes.insertInvoice)({
                projectId: v1.value0.projectId
            })(v1.value0.value)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(dispatchAlertInfo(v)("new_invoice_added"))(function () {
                        return v(new Actions.GetInvoicesRequest({
                            projectId: v1.value0.projectId
                        }));
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class_Console.error(Effect_Aff.monadEffectAff)("error on insert invoice request: " + Effect_Exception.message(attempt.value0)))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1336, column 3 - line 1342, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.DeleteInvoicesRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.invoiceRoutes.deleteInvoices)({
                projectId: v1.value0.projectId
            })(v1.value0.params)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(dispatchAlertInfo(v)("invoices_have_been_deleted"))(function () {
                        return v(new Actions.GetInvoicesRequest({
                            projectId: v1.value0.projectId
                        }));
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class_Console.error(Effect_Aff.monadEffectAff)("error on deleting invoice request: " + Effect_Exception.message(attempt.value0)))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1346, column 3 - line 1352, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.AddUsersRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.serverRoutes.addUsers)({})(v1.value0)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(handleAction(v)(Actions.GetSuperAdminUsersRequest.value))(function () {
                        return Effect_Class_Console.log(Effect_Aff.monadEffectAff)("Succesfully added users");
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class_Console.error(Effect_Aff.monadEffectAff)("Error while adding users: " + Data_Show.show(Effect_Exception.showError)(attempt.value0)))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1356, column 3 - line 1362, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.OrgAddUsersRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.serverRoutes.orgAddUsers)({})(v1.value0)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(handleAction(v)(Actions.GetOrganizationUsersRequest.value))(function () {
                        return Effect_Class_Console.log(Effect_Aff.monadEffectAff)("Succesfully added users");
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class_Console.error(Effect_Aff.monadEffectAff)("Error while adding users: " + Data_Show.show(Effect_Exception.showError)(attempt.value0)))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1366, column 3 - line 1372, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.SetUserOrganizationsRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.serverRoutes.updateUserOrganizations)({})(v1.value0)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(handleAction(v)(Actions.GetSuperAdminUsersRequest.value))(function () {
                        return Effect_Class_Console.log(Effect_Aff.monadEffectAff)("Succesfully updated user organizations");
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class_Console.error(Effect_Aff.monadEffectAff)("Error while updating user organizations: " + Data_Show.show(Effect_Exception.showError)(attempt.value0)))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1376, column 3 - line 1383, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.SetUserSSOLogin) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.setUserSSOLoginRoute)({})(v1.value0.params)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(handleAction(v)(Actions.GetOrganizationUsersRequest.value))(function () {
                        return Effect_Class_Console.log(Effect_Aff.monadEffectAff)("Succesfully set user SSO login");
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class_Console.error(Effect_Aff.monadEffectAff)("Error while trying to set SSO login for user: " + Data_Show.show(Effect_Exception.showError)(attempt.value0)))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1387, column 3 - line 1393, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.SetUserRolesRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.serverRoutes.setUserRoles)({})(v1.value0)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(handleAction(v)(Actions.GetRoleAssignmentsRequest.value))(function () {
                        return Effect_Class_Console.log(Effect_Aff.monadEffectAff)("Succesfully setuser roles");
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class_Console.error(Effect_Aff.monadEffectAff)("Error while updating user roles: " + Data_Show.show(Effect_Exception.showError)(attempt.value0)))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1397, column 3 - line 1404, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.DeleteSuperAdminUsersRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.serverRoutes.deleteUsers)({})(v1.value0)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(handleAction(v)(Actions.GetSuperAdminUsersRequest.value))(function () {
                        return v(Actions.DeleteUsersSuccess.value);
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.DeleteUsersError.value))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1408, column 3 - line 1414, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.OrgRemoveUsersRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.serverRoutes.orgRemoveUsers)({})(v1.value0)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(handleAction(v)(Actions.GetOrganizationUsersRequest.value))(function () {
                        return v(Actions.OrgRemoveUsersSuccess.value);
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.OrgRemoveUsersError.value))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1418, column 3 - line 1424, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.GetImportInvoicesRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.invoiceRoutes.importInvoices)({})(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return v(new Actions.GetImportInvoicesSuccess(attempt.value0));
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.GetImportInvoicesError.value))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1428, column 5 - line 1433, column 37): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.GetImportSalesInvoicesRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.invoiceRoutes.importSalesInvoices)({})(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return v(new Actions.GetImportSalesInvoicesSuccess(attempt.value0));
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.GetImportSalesInvoicesError.value))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1437, column 5 - line 1442, column 37): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.AllocateToProjectRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.projectRoutes.allocateToProject)({})(v1.value0)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.GetImportInvoicesRequest.value))(function () {
                        return v(Actions.GetImportSalesInvoicesRequest.value);
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class_Console.error(Effect_Aff.monadEffectAff)("error on allocate to project code request: " + Effect_Exception.message(attempt.value0)))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1446, column 3 - line 1452, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.MigrateSelectedInvoicesRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.invoiceRoutes.migrateSelectedInvoices)({})(v1.value0)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.MigrateSelectedInvoicesSuccess.value))(function () {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.GetImportInvoicesRequest.value))(function () {
                            return v(Actions.GetImportSalesInvoicesRequest.value);
                        });
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.MigrateSelectedInvoicesError.value))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1456, column 3 - line 1463, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.FetchDataFromIntegrationRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.serverRoutes.fetchDataFromIntegration)({})(v1.value0)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return v(Actions.GetImportInvoicesRequest.value);
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.FetchDataFromIntegrationError.value))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1467, column 3 - line 1472, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.UpdateUserRoleRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.serverRoutes.updateRole)({
                roleId: v1.value0.id
            })(v1.value0.role)))(function (attempt) {
                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(v1.value0.handler(attempt)))(function () {
                    if (attempt instanceof Data_Either.Right) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(handleAction(v)(Actions.GetUserRolesRequest.value))(function () {
                            return v(Actions.UpdateUserRoleSuccess.value);
                        });
                    };
                    if (attempt instanceof Data_Either.Left) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.UpdateUserRoleError.value))(function () {
                            return genericFetchError(v)(attempt.value0);
                        });
                    };
                    throw new Error("Failed pattern match at FetchMiddleware (line 1477, column 3 - line 1483, column 35): " + [ attempt.constructor.name ]);
                });
            });
        };
        if (v1 instanceof Actions.DeleteUserRoleRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.serverRoutes.deleteRole)({
                roleId: v1.value0.id
            })({})))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(handleAction(v)(Actions.GetUserRolesRequest.value))(function () {
                        return v(Actions.DeleteUserRoleSuccess.value);
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.DeleteUserRoleError.value))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1487, column 3 - line 1493, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.AddUserRoleRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.serverRoutes.addRole)({})(v1.value0.role)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(handleAction(v)(Actions.GetUserRolesRequest.value))(function () {
                        return v(Actions.AddUserRoleSuccess.value);
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.AddUserRoleError.value))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1497, column 3 - line 1503, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.AddOrganizationRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.serverRoutes.addOrganization)({
                organization: v1.value0.organization
            })(v1.value0.dbInfo)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(handleAction(v)(Actions.GetAllOrganizationsRequest.value))(function () {
                        return v(Actions.AddOrganizationSuccess.value);
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.AddOrganizationError.value))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1507, column 3 - line 1513, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.GetUserAppAccessRightsRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.serverRoutes.getUserAppAccessRights)({})(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return v(new Actions.GetUserAppAccessRightsSuccess(attempt.value0));
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.GetUserAppAccessRightsFailure.value))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1517, column 5 - line 1522, column 37): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.ElementActionRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.projectRoutes.elementAction)({
                projectId: v1.value0.projectId
            })(v1.value0.params)))(function (attempt) {
                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(v1.value0.handler(attempt)))(function () {
                    if (attempt instanceof Data_Either.Right) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.ElementActionSuccess(v1.value0)))(function () {
                            return refreshEstimationProjectsData(v)(v1.value0.projectId);
                        });
                    };
                    if (attempt instanceof Data_Either.Left) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.ElementActionFailure(v1.value0)))(function () {
                            return genericFetchError(v)(attempt.value0);
                        });
                    };
                    throw new Error("Failed pattern match at FetchMiddleware (line 1527, column 3 - line 1533, column 35): " + [ attempt.constructor.name ]);
                });
            });
        };
        if (v1 instanceof Actions.GetVATExclusionCostGroupsRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.costGroupRoutes.vatExclusionCostGroups)({
                projectId: v1.value0.projectId
            })(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return v(new Actions.GetVATExclusionCostGroupsSuccess({
                        projectId: v1.value0.projectId,
                        items: attempt.value0
                    }));
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.GetVATExclusionCostGroupsFailure({
                        projectId: v1.value0.projectId
                    })))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1537, column 5 - line 1542, column 37): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.VATExclusionCostGroupsActionRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.costGroupRoutes.vatExclusionCostGroupsAction)({
                projectId: v1.value0.projectId
            })(v1.value0.params)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.VATExclusionCostGroupsActionSuccess(v1.value0)))(function () {
                        return handleAction(v)(new Actions.GetVATExclusionCostGroupsRequest({
                            projectId: v1.value0.projectId
                        }));
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.VATExclusionCostGroupsActionFailure(v1.value0)))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1546, column 3 - line 1556, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.ResetCostGroupTargetItemsRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.projectRoutes.resetCostGroupTargetItems)({
                projectId: v1.value0.projectId
            })({})))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.ResetCostGroupTargetItemsSuccess(v1.value0)))(function () {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(dispatchAlertInfo(v)("cost_group_target_items_have_been_reset"))(function () {
                            return refreshCostControlData(v)(v1.value0.projectId);
                        });
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.ResetCostGroupTargetItemsFailure(v1.value0)))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1560, column 3 - line 1568, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.SetCostControlExcludeActualCostSocialExpenses) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.serverRoutes.setCostControlExcludeActualCostSocialExpenses)({})(v1.value0)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return v(Actions.GetCostControlExcludeActualCostSocialExpensesRequest.value);
                };
                if (attempt instanceof Data_Either.Left) {
                    return genericFetchError(v)(attempt.value0);
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1572, column 3 - line 1576, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.OrgAddressAreaRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.serverRoutes.orgAddressArea)({})(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return v(new Actions.OrgAddressAreaSuccess(attempt.value0));
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.OrgAddressAreaFailure.value))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1580, column 5 - line 1585, column 37): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.OrgCostClassRequest) {
            return RequestUtils.withGetRoute(Types_ServerRoutes.serverRoutes.orgCostClasses)({})(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return v(new Actions.OrgCostClassSuccess(attempt.value0));
                };
                if (attempt instanceof Data_Either.Left) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(Actions.OrgCostClassFailure.value))(function () {
                        return genericFetchError(v)(attempt.value0);
                    });
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1589, column 5 - line 1594, column 37): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.EstimationResourcePriceMatchActionRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.estimationRoutes.projectResourcePriceMatch)({
                projectId: v1.value0.projectId
            })(v1.value0)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return v(new Actions.EstimationResourcePriceMatchActionSuccess(attempt.value0));
                };
                if (attempt instanceof Data_Either.Left) {
                    return genericFetchError(v)(attempt.value0);
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1598, column 3 - line 1602, column 35): " + [ attempt.constructor.name ]);
            });
        };
        if (v1 instanceof Actions.EstimationResourcePriceUpdateActionRequest) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.bodyRoute(Types_ServerRoutes.estimationRoutes.projectResourcePriceUpdateFromPricelist)({
                projectId: v1.value0.projectId
            })(v1.value0)))(function (attempt) {
                if (attempt instanceof Data_Either.Right) {
                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshEstimationResourcesData({
                        request_params: v1.value0.projectId
                    }))))(function () {
                        return v(Actions.EstimationResourcePriceUpdateActionSuccess.value);
                    });
                };
                if (attempt instanceof Data_Either.Left) {
                    return genericFetchError(v)(attempt.value0);
                };
                throw new Error("Failed pattern match at FetchMiddleware (line 1606, column 3 - line 1611, column 35): " + [ attempt.constructor.name ]);
            });
        };
        return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Unit.unit);
    };
};
var fetchDriver = function (dispatch) {
    return function (actions) {
        var v = (function () {
            var $643 = Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
            return function ($644) {
                return $643(dispatch($644));
            };
        })();
        return function __do() {
            FRP_Event.subscribe(actions)(function (appAction) {
                return function __do() {
                    Effect_Aff.launchAff_(handleAction(v)(appAction))();
                    FetchExcel.downloadReportExcel(v)(appAction)();
                    FetchExcel.downloadPortfolioReportExcelExport(v)(appAction)();
                    FetchExcel.downloadEstimationExcelExport(v)(appAction)();
                    FetchExcel.downloadEstimationQuantityExcelExport(v)(appAction)();
                    FetchExcel.downloadEstimationQuantityByLocationExcelExport(v)(appAction)();
                    FetchExcel.downloadEstimationQuantityByCostClassExcelExport(v)(appAction)();
                    FetchExcel.downloadEstimationComponentsExcelExport(v)(appAction)();
                    FetchExcel.downloadEstimationElementsExcelExport(v)(appAction)();
                    FetchExcel.downloadEstimationResourcesExcelExport(v)(appAction)();
                    FetchExcel.downloadOfferPageExcelExport(v)(appAction)();
                    FetchExcel.downloadOrgResourceExcelExport(v)(appAction)();
                    FetchExcel.downloadOrgPriceListExcelExport(v)(appAction)();
                    FetchExcel.downloadOrgResourceSubGroupingExcelExport(v)(appAction)();
                    FetchExcel.downloadCostGroupsExport(v)(appAction)();
                    FetchExcel.downloadInvoiceLinesExport(v)(appAction)();
                    FetchZip.downloadZipInvoices(v)(appAction)();
                    FetchExcel.downloadPlanningCostGroupsExportXML(v)(appAction)();
                    FetchExcel.downloadPlanningProcumentExport(v)(appAction)();
                    return FetchEstimationProjectExport.downloadEstimationProjectExport(v)(appAction)();
                };
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(FRP_Event.monoidEvent(Data_Monoid.monoidUnit)))();
        };
    };
};
module.exports = {
    fetchDriver: fetchDriver,
    refreshEstimationProjectsData: refreshEstimationProjectsData,
    "refreshEstimationProjectsData'": refreshEstimationProjectsData$prime
};
