export enum FeatureFlags {
  PROJECT_MANAGEMENT_AGGRID = "project-management-aggrid",
  PROJECT_NEW_EDIT_DIALOG = "project-new-edit-dialog",
  PROJECT_PUBLISH_REPORT = "project-publish-report",
  PRINTOUT_VIEW_MANAGEMENT = "printout-view-management",
  ADMINET_INTEGRATION_RESOURCES = "adminet-integration-resources",
  TOPIC_COMPONENT_EDITING = "topic-component-editing",
  KNOWLEDGE_BASE_NEW = "knowledge-base-new",
  COST_CONTROL_PRINTING = "cost-control-printing",
  ADVANCED_IMPORT_MODAL = "advanced-project-import-modal",
  ESTIMATION_PRINTING_RESOURCES = "estimation-printing-resources",
  COMPONENT_COSTCLASS_PRICE_ADJUSTMENT = "component-costclass-price-adjustment",
  MULTI_COMPONENT_EDIT_MODAL_NEW = "multi-component-edit-modal-new",
  COMPONENT_GROUP_EDIT_MODAL_NEW = "component-group-edit-modal-new",
  NEW_NAVIGATION_BAR = "new-navigation-bar",
  INTEGRATION_USER_CREATION = "integration-user-creation",
  GROUP_WITH_INVOICE_BUNCH_ID = "group-with-invoice-bunch-id",
  NEW_COMPONENT_LOCATIONS = "new-component-locations",
  LOCALIZE = "localize",
  PREMIUM_OFFER_PAGE = "premium-offer-page",
  LANGUAGE_SELECTION = "language-selection",
  LANGUAGE_SWEDISH = "language-swedish",
  CROWDIN = "crowdin",
  ORGANIZATION_CURRENCY = "organization-currency",
  COMPONENT_CLASS_COLUMN = "component-class-column",
  COMPONENT_EXACT_SEARCH = "component-exact-search",
  ESTIMATION_REPORTS = "estimation-reports",
  PUBLISH_TO_TEMPO = "publish-to-tempo",
}

const {
  PROJECT_MANAGEMENT_AGGRID,
  PROJECT_NEW_EDIT_DIALOG,
  PROJECT_PUBLISH_REPORT,
  PRINTOUT_VIEW_MANAGEMENT,
  ADMINET_INTEGRATION_RESOURCES,
  TOPIC_COMPONENT_EDITING,
  KNOWLEDGE_BASE_NEW,
  COST_CONTROL_PRINTING,
  ADVANCED_IMPORT_MODAL,
  ESTIMATION_PRINTING_RESOURCES,
  COMPONENT_COSTCLASS_PRICE_ADJUSTMENT,
  MULTI_COMPONENT_EDIT_MODAL_NEW,
  COMPONENT_GROUP_EDIT_MODAL_NEW,
  NEW_NAVIGATION_BAR,
  INTEGRATION_USER_CREATION,
  GROUP_WITH_INVOICE_BUNCH_ID,
  NEW_COMPONENT_LOCATIONS,
  LOCALIZE,
  PREMIUM_OFFER_PAGE,
  LANGUAGE_SELECTION,
  LANGUAGE_SWEDISH,
  CROWDIN,
  ORGANIZATION_CURRENCY,
  COMPONENT_CLASS_COLUMN,
  COMPONENT_EXACT_SEARCH,
  ESTIMATION_REPORTS,
  PUBLISH_TO_TEMPO,
} = FeatureFlags;

/**
 * Used in local development to mock feature flags.
 */
type MockFeatures = {
  [key in FeatureFlags]?: "on" | "off";
};
export const mockedFeatures: MockFeatures = {
  [PROJECT_MANAGEMENT_AGGRID]: "on",
  [PROJECT_NEW_EDIT_DIALOG]: "on",
  [PROJECT_PUBLISH_REPORT]: "on",
  [PRINTOUT_VIEW_MANAGEMENT]: "on",
  [ADMINET_INTEGRATION_RESOURCES]: "on",
  [TOPIC_COMPONENT_EDITING]: "on",
  [COST_CONTROL_PRINTING]: "on",
  [KNOWLEDGE_BASE_NEW]: "on",
  [ADVANCED_IMPORT_MODAL]: "on",
  [ESTIMATION_PRINTING_RESOURCES]: "on",
  [COMPONENT_COSTCLASS_PRICE_ADJUSTMENT]: "on",
  [MULTI_COMPONENT_EDIT_MODAL_NEW]: "on",
  [COMPONENT_GROUP_EDIT_MODAL_NEW]: "on",
  [NEW_NAVIGATION_BAR]: "on",
  [INTEGRATION_USER_CREATION]: "on",
  [GROUP_WITH_INVOICE_BUNCH_ID]: "on",
  [NEW_COMPONENT_LOCATIONS]: "on",
  [LOCALIZE]: "on",
  [PREMIUM_OFFER_PAGE]: "on",
  [LANGUAGE_SELECTION]: "on",
  [LANGUAGE_SWEDISH]: "on",
  [CROWDIN]: "on",
  [ORGANIZATION_CURRENCY]: "on",
  [COMPONENT_CLASS_COLUMN]: "on",
  [COMPONENT_EXACT_SEARCH]: "on",
  [ESTIMATION_REPORTS]: "on",
  [PUBLISH_TO_TEMPO]: "on",
};
