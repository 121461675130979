// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var separatorSubHeadingStyles = function (props) {
    return Box.boxStyle([ new Box.PaddingX(TacoConstants.XL.value), new Box.PaddingY(TacoConstants.XS.value), Box.FlexRow.value, Box.AlignEnd.value, Box.Style.create(React_Basic_Emotion.css()({
        borderTop: React_Basic_Emotion.str(TacoConstants.borderStr(TacoConstants.BorderCustom.create(Data_Maybe.fromMaybe(TacoConstants.Transparent.value)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
            return v.border;
        })(props.colorSet))))),
        borderLeft: React_Basic_Emotion.str(TacoConstants.borderStr(TacoConstants.BorderCustom.create(Data_Maybe.fromMaybe(TacoConstants.Transparent.value)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
            return v.border;
        })(props.colorSet)))))
    })) ]);
};
module.exports = {
    separatorSubHeadingStyles: separatorSubHeadingStyles
};
