// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Control_Alt = require("../Control.Alt/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Bifunctor = require("../Data.Bifunctor/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_JSDate = require("../Data.JSDate/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Number = require("../Data.Number/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Data_Validation_Semigroup = require("../Data.Validation.Semigroup/index.js");
var Data_Variant = require("../Data.Variant/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Class_Console = require("../Effect.Class.Console/index.js");
var Effect_Exception = require("../Effect.Exception/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var Locale = require("../Locale/index.js");
var $$Math = require("../Math/index.js");
var MultipleErrorsPrinter = require("../MultipleErrorsPrinter/index.js");
var Partial_Unsafe = require("../Partial.Unsafe/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var ReadParam = require("../ReadParam/index.js");
var StringUtils = require("../StringUtils/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_CostGroup = require("../Types.CostGroup/index.js");
var Types_Invoice = require("../Types.Invoice/index.js");
var Types_JSDateString = require("../Types.JSDateString/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_ReasonableCoerce = require("../Types.ReasonableCoerce/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var VariantError = require("../VariantError/index.js");
var unsafeTODO = function (dictWarn) {
    return Partial_Unsafe.unsafeCrashWith("unsafeTodo: todo item has not been completed.");
};
var unsafeLog2$prime = function (dictMonadEffect) {
    return function (x) {
        return function (y) {
            return Effect_Class.liftEffect(dictMonadEffect)($foreign.unsafeLog2(x)(y));
        };
    };
};
var unknownIncomeGroup = "Muu laskutus";
var unknownCostGroupString = "?????";
var toggleInSet = function (dictOrd) {
    return function (x) {
        return function (set) {
            var $76 = Data_Set.member(dictOrd)(x)(set);
            if ($76) {
                return Data_Set["delete"](dictOrd)(x)(set);
            };
            return Data_Set.insert(dictOrd)(x)(set);
        };
    };
};
var toSBDTBoolean = function (v) {
    if (v) {
        return -1 | 0;
    };
    if (!v) {
        return 0;
    };
    throw new Error("Failed pattern match at Utils (line 493, column 17 - line 495, column 13): " + [ v.constructor.name ]);
};
var toNullable = Data_Nullable.toNullable;
var throwEither = function (dictMonadThrow) {
    return Data_Either.either(Control_Monad_Error_Class.throwError(dictMonadThrow))(Control_Applicative.pure((dictMonadThrow.Monad0()).Applicative0()));
};
var sumWithoutVATValidation = function (s) {
    var v = StringUtils.readStrToNumber(s);
    if (v instanceof Data_Maybe.Just && Data_Number["isFinite"](v.value0)) {
        return Control_Applicative.pure(Data_Validation_Semigroup.applicativeV(Data_Semigroup.semigroupArray))(v.value0);
    };
    return Data_Validation_Semigroup.invalid(Control_Applicative.pure(Control_Applicative.applicativeArray)("\x0a" + Locale.lookup_("non_numeric_sumWithoutVAT_error")));
};
var stringMaybeEmpty = function (s) {
    var $80 = Data_String_Common["null"](s);
    if ($80) {
        return Data_Maybe.Nothing.value;
    };
    return new Data_Maybe.Just(s);
};
var sbtBooleanToBoolean = function (b) {
    return b === (-1 | 0);
};
var recordsToObjects = Types_ReasonableCoerce.reasonableCoerce("array of heterogeneous records can be safely used as a array of Foreign Object of Foreign, which can be decoded later");
var recordToObject = Types_ReasonableCoerce.reasonableCoerce("any record can be converted to its runtime representation");
var readStringOrNumberAsString = function (f) {
    var readAsString = TofuJSON.read(TofuJSON.readString)(f);
    var readAsNumber = Control_Bind.bind(Data_Either.bindEither)(TofuJSON.read(TofuJSON.readNumber)(f))(function (v) {
        return Control_Applicative.pure(Data_Either.applicativeEither)(Data_Show.show(Data_Show.showInt)(Data_Int.floor(v)));
    });
    return Control_Alt.alt(Data_Either.altEither)(readAsString)(readAsNumber);
};
var readNullableStringOrNumberAsMaybeString = function (f) {
    var readAsString = TofuJSON.read(TofuJSON.readString)(f);
    var readAsNumber = Control_Bind.bind(Data_Either.bindEither)(TofuJSON.read(TofuJSON.readNumber)(f))(function (v) {
        return Control_Applicative.pure(Data_Either.applicativeEither)(Data_Show.show(Data_Show.showInt)(Data_Int.floor(v)));
    });
    var readAsMaybeString = TofuJSON.read(TofuJSON.readMaybe(TofuJSON.readString))(f);
    return Control_Alt.alt(Data_Either.altEither)(Control_Alt.alt(Data_Either.altEither)(Data_Functor.map(Data_Either.functorEither)(Data_Maybe.Just.create)(readAsString))(Data_Functor.map(Data_Either.functorEither)(Data_Maybe.Just.create)(readAsNumber)))(readAsMaybeString);
};
var readNullableStringAsString = function (f) {
    var nullableString = TofuJSON.read(TofuJSON.readMaybe(TofuJSON.readString))(f);
    return Data_Functor.map(Data_Either.functorEither)(Data_Maybe.fromMaybe(""))(nullableString);
};
var readNullableStringAsMaybe = function (f) {
    return TofuJSON.read(TofuJSON.readMaybe(TofuJSON.readString))(f);
};
var readMaybe = function (dictTofuJSON) {
    var $145 = TofuJSON.read(dictTofuJSON);
    return function ($146) {
        return Data_Either.hush($145($146));
    };
};
var readJSONFail$prime = function (dictTofuJSON) {
    return function (dictMonadThrow) {
        return function (tag) {
            return function (jsonString) {
                var v = TofuJSON.readJSON(dictTofuJSON)(jsonString);
                if (v instanceof Data_Either.Right) {
                    return Control_Applicative.pure((dictMonadThrow.Monad0()).Applicative0())(v.value0);
                };
                if (v instanceof Data_Either.Left) {
                    return Control_Monad_Error_Class.throwError(dictMonadThrow)(Effect_Exception.error(TofuJSON.writeJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                        reflectSymbol: function () {
                            return "e";
                        }
                    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                        reflectSymbol: function () {
                            return "type";
                        }
                    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
                        reflectSymbol: function () {
                            return "e";
                        }
                    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                        reflectSymbol: function () {
                            return "type";
                        }
                    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()()))({
                        type: tag,
                        e: MultipleErrorsPrinter.printMultipleErrors(v.value0)
                    })));
                };
                throw new Error("Failed pattern match at Utils (line 292, column 3 - line 297, column 8): " + [ v.constructor.name ]);
            };
        };
    };
};
var readJSON$prime = function (dictTofuJSON) {
    return function (ctr) {
        var $147 = Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)(function ($150) {
            return ctr(MultipleErrorsPrinter.printMultipleErrors($150));
        });
        var $148 = TofuJSON.readJSON(dictTofuJSON);
        return function ($149) {
            return $147($148($149));
        };
    };
};
var readFail$prime = function (dictTofuJSON) {
    return function (dictMonadThrow) {
        return function (tag) {
            return function (f) {
                var v = TofuJSON.read(dictTofuJSON)(f);
                if (v instanceof Data_Either.Right) {
                    return Control_Applicative.pure((dictMonadThrow.Monad0()).Applicative0())(v.value0);
                };
                if (v instanceof Data_Either.Left) {
                    return Control_Monad_Error_Class.throwError(dictMonadThrow)(Effect_Exception.error(TofuJSON.writeJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                        reflectSymbol: function () {
                            return "e";
                        }
                    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                        reflectSymbol: function () {
                            return "type";
                        }
                    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
                        reflectSymbol: function () {
                            return "e";
                        }
                    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                        reflectSymbol: function () {
                            return "type";
                        }
                    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()()))({
                        type: tag,
                        e: MultipleErrorsPrinter.printMultipleErrors(v.value0)
                    })));
                };
                throw new Error("Failed pattern match at Utils (line 282, column 3 - line 287, column 8): " + [ v.constructor.name ]);
            };
        };
    };
};
var readFail = function (dictTofuJSON) {
    return function (dictMonadThrow) {
        return function (f) {
            var v = TofuJSON.read(dictTofuJSON)(f);
            if (v instanceof Data_Either.Right) {
                return Control_Applicative.pure((dictMonadThrow.Monad0()).Applicative0())(v.value0);
            };
            if (v instanceof Data_Either.Left) {
                return Control_Monad_Error_Class.throwError(dictMonadThrow)(Effect_Exception.error(TofuJSON.writeJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "e";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "type";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "e";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "type";
                    }
                })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()()))({
                    type: "JSON.read",
                    e: MultipleErrorsPrinter.printMultipleErrors(v.value0)
                })));
            };
            throw new Error("Failed pattern match at Utils (line 269, column 3 - line 274, column 8): " + [ v.constructor.name ]);
        };
    };
};
var readAppError = VariantError.readVariant(VariantError.matchKeyInst(VariantError.consMatchKey(VariantError.consMatchKey(VariantError.consMatchKey(VariantError.nilMatchKey)({
    reflectSymbol: function () {
        return "userError";
    }
}))({
    reflectSymbol: function () {
        return "serverError";
    }
}))({
    reflectSymbol: function () {
        return "customError";
    }
}))());
var read$prime = function (dictTofuJSON) {
    return function (ctr) {
        var $151 = Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)(function ($154) {
            return ctr(MultipleErrorsPrinter.printMultipleErrors($154));
        });
        var $152 = TofuJSON.read(dictTofuJSON);
        return function ($153) {
            return $151($152($153));
        };
    };
};
var pureNullable = function (a) {
    return toNullable(new Data_Maybe.Just(a));
};
var printError = function (dictMonadEffect) {
    var $155 = Effect_Class_Console.error(dictMonadEffect);
    var $156 = Types_ReasonableCoerce.reasonableCoerce("We can print errors");
    return function ($157) {
        return $155($156($157));
    };
};
var nullValue = toNullable(Data_Maybe.Nothing.value);
var stringNullableEmpty = function (s) {
    var $92 = Data_String_Common["null"](s);
    if ($92) {
        return nullValue;
    };
    return pureNullable(s);
};
var nothing = Data_Maybe.Nothing.value;
var newtypeRecordsToObjects = function (dictNewtype) {
    return Types_ReasonableCoerce.reasonableCoerce("same as recordsToObjects, but with newtypes of records");
};
var nearZero = function (x) {
    return x <= 1.0e-2 && x >= -1.0e-2;
};
var nameValidation = function (s) {
    var $93 = Data_String_CodePoints.length(s) > 50;
    if ($93) {
        return Data_Validation_Semigroup.invalid(Control_Applicative.pure(Control_Applicative.applicativeArray)("\x0a" + Locale.lookup_("too_long_name_error")));
    };
    return Control_Applicative.pure(Data_Validation_Semigroup.applicativeV(Data_Semigroup.semigroupArray))(Data_Unit.unit);
};
var memoizeWithJSON = function (dictTofuJSON) {
    return function (fn) {
        return $foreign["_memoizeWithJSON"](TofuJSON.writeJSON(dictTofuJSON))(fn);
    };
};
var matchSalesInvoices = function (incomeItem) {
    return function (salesInvoice) {
        var v = function (v1) {
            if (Data_Boolean.otherwise) {
                return false;
            };
            throw new Error("Failed pattern match at Utils (line 418, column 1 - line 418, column 60): " + [ incomeItem.constructor.name, salesInvoice.constructor.name ]);
        };
        if (salesInvoice.code instanceof Data_Maybe.Just) {
            var $99 = salesInvoice.code.value0 === incomeItem.code;
            if ($99) {
                return true;
            };
            return v(true);
        };
        return v(true);
    };
};
var matchCostGroupCode = function (v) {
    return function (xs) {
        var match = function (x) {
            var $103 = Data_Eq.eq(Types_CostGroup.eqCostGroupCode)(x.costGroupCode)(v.code);
            if ($103) {
                return new Data_Maybe.Just(x);
            };
            return Data_Maybe.Nothing.value;
        };
        return Data_Array.mapMaybe(match)(xs);
    };
};
var mapNullable = function (f) {
    return function (n) {
        return toNullable(Data_Functor.map(Data_Maybe.functorMaybe)(f)(Data_Nullable.toMaybe(n)));
    };
};
var lossyToISOString = function ($158) {
    return Effect_Unsafe.unsafePerformEffect(Data_JSDate.toISOString($158));
};
var lossyToHtmlDateString = (function () {
    var $159 = Data_String_CodeUnits.take(10);
    return function ($160) {
        return $159(lossyToISOString($160));
    };
})();
var lossyParseJSDate = function ($161) {
    return Effect_Unsafe.unsafePerformEffect(Data_JSDate.parse($161));
};
var lossyParseJSDateString = function ($162) {
    return lossyParseJSDate(Types_JSDateString.extractJSDateString($162));
};
var lookupCostClassName = function (id) {
    return function (xs) {
        return Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
            return v.name;
        })(Data_Array.find((function () {
            var $163 = Data_Eq.eq(Types_Newtypes.eqCostClassCode)(id);
            return function ($164) {
                return $163((function (v) {
                    return v.costClassCode;
                })($164));
            };
        })())(xs));
    };
};
var logError = function (s) {
    return Effect_Unsafe.unsafePerformEffect(Effect_Class_Console.error(Effect_Class.monadEffectEffect)(s));
};
var just = Data_Maybe.Just.create;
var isUnknownSalesInvoice = function (incomeItems) {
    return function (si) {
        var v = function (v1) {
            var v2 = function (v3) {
                if (Data_Boolean.otherwise) {
                    return false;
                };
                throw new Error("Failed pattern match at Utils (line 407, column 1 - line 407, column 69): " + [ incomeItems.constructor.name, si.constructor.name ]);
            };
            if (si.code instanceof Data_Maybe.Just) {
                var $110 = !Data_Array.any(function (incomeItem) {
                    return si.code.value0 === incomeItem.code;
                })(incomeItems);
                if ($110) {
                    return true;
                };
                return v2(true);
            };
            return v2(true);
        };
        if (si.code instanceof Data_Maybe.Nothing) {
            return true;
        };
        return v(true);
    };
};
var isSalary = function (invLine) {
    if (invLine.type instanceof Types_Invoice.Salary) {
        return true;
    };
    return false;
};
var isPrefix = function (pattern) {
    return function (str) {
        return Data_Maybe.isJust(Data_String_CodeUnits.stripPrefix(pattern)(str));
    };
};
var isNull = function (x) {
    return Data_Maybe.isNothing(Data_Nullable.toMaybe(x));
};
var isNothing = Data_Maybe.isNothing;
var isLink = function (str) {
    return StringUtils.startsWith("https://")(str) || StringUtils.startsWith("http://")(str);
};
var homogeneousToObject = function (dictHomogeneous) {
    return Types_ReasonableCoerce.reasonableCoerce("homogeneous record of a is a Foreign Object of a in runtime");
};
var groupMap = function (dictEq) {
    return function (dictOrd) {
        return function (groupProperty) {
            return function (xs) {
                var toTuple = function (group) {
                    return new Data_Tuple.Tuple(groupProperty(Data_Array_NonEmpty.head(group)), Data_Array_NonEmpty.toArray(group));
                };
                return Data_Map_Internal.fromFoldable(dictOrd)(Data_Foldable.foldableArray)(Data_Functor.map(Data_Functor.functorArray)(toTuple)(Data_Array.groupBy(Data_Function.on(Data_Eq.eq(dictEq))(groupProperty))(Data_Array.sortWith(dictOrd)(groupProperty)(xs))));
            };
        };
    };
};
var getSumWithoutVATValidationWarning = function (s) {
    return Data_Validation_Semigroup.validation(Data_Array.intercalate(Data_Monoid.monoidString)(", "))(function (v) {
        return "";
    })(sumWithoutVATValidation(s));
};
var getNameValidationWarning = function (s) {
    return Data_Validation_Semigroup.validation(Data_Array.intercalate(Data_Monoid.monoidString)(", "))(function (v) {
        return "";
    })(nameValidation(s));
};
var fromSBDTBoolean = function (v) {
    if (v === -1) {
        return true;
    };
    return false;
};
var encodeURIComponent$prime = function (dictNewtype) {
    return Data_Newtype.over()()(Data_Newtype.wrap())($foreign["encodeURIComponent"]);
};
var eitherToMaybe = function (v) {
    if (v instanceof Data_Either.Right) {
        return new Data_Maybe.Just(v.value0);
    };
    return Data_Maybe.Nothing.value;
};
var defaultZero = function (number) {
    var $119 = Data_Number["isFinite"](number);
    if ($119) {
        return number;
    };
    return 0.0;
};
var defaultZeroDiv = function (x) {
    return function (y) {
        return defaultZero(x / y);
    };
};
var defaultZeroInv = function (x) {
    return defaultZero($$Math.pow(x)(-1.0));
};
var defaultNull = function (x) {
    return function (n) {
        return Data_Maybe.fromMaybe(x)(Data_Nullable.toMaybe(n));
    };
};
var foldNullable = function (x) {
    return function (f) {
        return function (n) {
            return defaultNull(x)(mapNullable(f)(n));
        };
    };
};
var nullable = foldNullable;
var fromNullable = defaultNull;
var defaultNothingDiv = function (v) {
    return function (v1) {
        if (v instanceof Data_Maybe.Just && v1 instanceof Data_Maybe.Just) {
            return Data_Maybe.Just.create(defaultZeroDiv(v.value0)(v1.value0));
        };
        if (v instanceof Data_Maybe.Nothing) {
            return Data_Maybe.Nothing.value;
        };
        if (v1 instanceof Data_Maybe.Nothing) {
            return Data_Maybe.Nothing.value;
        };
        throw new Error("Failed pattern match at Utils (line 435, column 1 - line 435, column 66): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var decodeURIComponent$prime = function (dictNewtype) {
    return Data_Newtype.over()()(Data_Newtype.wrap())($foreign["decodeURIComponent"]);
};
var count = function (dictOrd) {
    return function (xs) {
        var incCnt = function (k) {
            return function (m) {
                var f = function (mCnt) {
                    if (mCnt instanceof Data_Maybe.Just) {
                        return Data_Maybe.Just.create(mCnt.value0 + 1 | 0);
                    };
                    if (mCnt instanceof Data_Maybe.Nothing) {
                        return new Data_Maybe.Just(1);
                    };
                    throw new Error("Failed pattern match at Utils (line 570, column 11 - line 572, column 30): " + [ mCnt.constructor.name ]);
                };
                return Data_Map_Internal.alter(dictOrd)(f)(k)(m);
            };
        };
        return Data_Foldable.foldr(Data_Foldable.foldableArray)(incCnt)(Data_Map_Internal.empty)(xs);
    };
};
var convertPercentage100to0 = Data_Newtype.over()()(Types_Unit.PercentageOfHundred)(Data_Semiring.mul(Data_Semiring.semiringNumber)(1.0e-2));
var condPreventDefault = React_Basic_Events.unsafeEventFn(function (e) {
    var $126 = e.metaKey || (e.ctrlKey || e.shiftKey);
    if ($126) {
        return {
            e: e,
            openTabOrWindow: true
        };
    };
    return Effect_Unsafe.unsafePerformEffect(function __do() {
        e.preventDefault();
        return {
            e: e,
            openTabOrWindow: false
        };
    });
});
var coerceNewtypeArray = function (dictNewtype) {
    return function (v) {
        return Types_ReasonableCoerce.reasonableCoerce("newtypes are the same as their underlying type");
    };
};
var codeValidation = function (s) {
    var $127 = Data_String_CodePoints.length(s) > 10;
    if ($127) {
        return Data_Validation_Semigroup.invalid(Control_Applicative.pure(Control_Applicative.applicativeArray)("\x0a" + Locale.lookup_("too_long_code_error")));
    };
    return Control_Applicative.pure(Data_Validation_Semigroup.applicativeV(Data_Semigroup.semigroupArray))(Data_Unit.unit);
};
var getCodeValidationWarning = function (s) {
    return Data_Validation_Semigroup.validation(Data_Array.intercalate(Data_Monoid.monoidString)(", "))(function (v) {
        return "";
    })(codeValidation(s));
};
var blush = function (v) {
    if (v instanceof Data_Either.Left) {
        return new Data_Maybe.Just(v.value0);
    };
    return Data_Maybe.Nothing.value;
};
var bindMaybeNullable = function (f) {
    return function (n) {
        return toNullable(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(f)(Data_Nullable.toMaybe(n)));
    };
};
var appErrorKeys = {
    userError: Data_Symbol.SProxy.value,
    serverError: Data_Symbol.SProxy.value,
    customError: Data_Symbol.SProxy.value
};
var mkCustomError = function (v) {
    return VariantError.mkVariantError(v.internalMsg)(Data_Variant.inj()({
        reflectSymbol: function () {
            return "customError";
        }
    })(appErrorKeys.customError)({
        tag: v.tag,
        response: v.response,
        internalMsg: "[" + (v.tag + ("] Custom error: " + v.internalMsg))
    }));
};
var mkServerError = function (v) {
    return VariantError.mkVariantError(v.internalMsg)(Data_Variant.inj()({
        reflectSymbol: function () {
            return "serverError";
        }
    })(appErrorKeys.serverError)({
        tag: v.tag,
        response: v.response,
        internalMsg: "[" + (v.tag + ("] Server error: " + v.internalMsg))
    }));
};
var mapForeignErrors = (function () {
    var prepareError = function (e) {
        var msg = MultipleErrorsPrinter.printMultipleErrors(e);
        return mkServerError({
            tag: "mapForeignErrors",
            internalMsg: msg,
            response: msg
        });
    };
    return Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)(prepareError);
})();
var mapForeignErrors$prime = function (dictMonadThrow) {
    var $165 = throwEither(dictMonadThrow);
    return function ($166) {
        return $165(mapForeignErrors($166));
    };
};
var traverseThrowJSONE = function (dictTraversable) {
    return function (dictApplicative) {
        return function (dictMonadThrow) {
            return Data_Traversable.traverse(dictTraversable)(dictApplicative)(mapForeignErrors$prime(dictMonadThrow));
        };
    };
};
var readServerForeign = function (dictTofuJSON) {
    var prepareError = function (msg) {
        return mkServerError({
            tag: "readServerForeign",
            internalMsg: msg,
            response: msg
        });
    };
    return read$prime(dictTofuJSON)(prepareError);
};
var readServerForeign$prime = function (dictMonadThrow) {
    return function (dictTofuJSON) {
        var $167 = throwEither(dictMonadThrow);
        var $168 = readServerForeign(dictTofuJSON);
        return function ($169) {
            return $167($168($169));
        };
    };
};
var readServerJSON = function (dictTofuJSON) {
    var prepareError = function (msg) {
        return mkServerError({
            tag: "readServerJSON",
            internalMsg: msg,
            response: msg
        });
    };
    return readJSON$prime(dictTofuJSON)(prepareError);
};
var readServerJSON$prime = function (dictMonadThrow) {
    return function (dictTofuJSON) {
        var $170 = throwEither(dictMonadThrow);
        var $171 = readServerJSON(dictTofuJSON);
        return function ($172) {
            return $170($171($172));
        };
    };
};
var fromJSONOf = function (dictMonadThrow) {
    return function (dictTofuJSON) {
        return readServerJSON$prime(dictMonadThrow)(dictTofuJSON);
    };
};
var mkUserError = function (dictTofuJSON) {
    return function (v) {
        return VariantError.mkVariantError(v.internalMsg)(Data_Variant.inj()({
            reflectSymbol: function () {
                return "userError";
            }
        })(appErrorKeys.userError)({
            tag: v.tag,
            response: TofuJSON.write(dictTofuJSON)(v.response),
            internalMsg: "[" + (v.tag + ("] User error: " + v.internalMsg))
        }));
    };
};
var readParamsUserForeign = function (dictReadParam) {
    return function (input) {
        var prepareError = function (e) {
            var msg = MultipleErrorsPrinter.printMultipleErrors(e);
            return mkUserError(TofuJSON.readString)({
                tag: "readParamsUserForeign",
                internalMsg: msg,
                response: msg
            });
        };
        return Data_Bifunctor.lmap(Data_Bifunctor.bifunctorEither)(prepareError)(ReadParam.readParam(dictReadParam)(input));
    };
};
var readParamsUserForeign$prime = function (dictMonadThrow) {
    return function (dictReadParam) {
        var $173 = throwEither(dictMonadThrow);
        var $174 = readParamsUserForeign(dictReadParam);
        return function ($175) {
            return $173($174($175));
        };
    };
};
var readUserForeign = function (dictTofuJSON) {
    var prepareError = function (msg) {
        return mkUserError(TofuJSON.readString)({
            tag: "readUserForeign",
            internalMsg: msg,
            response: msg
        });
    };
    return read$prime(dictTofuJSON)(prepareError);
};
var readUserForeign$prime = function (dictMonadThrow) {
    return function (dictTofuJSON) {
        var $176 = throwEither(dictMonadThrow);
        var $177 = readUserForeign(dictTofuJSON);
        return function ($178) {
            return $176($177($178));
        };
    };
};
var readUserJSON = function (dictTofuJSON) {
    var prepareError = function (msg) {
        return mkUserError(TofuJSON.readString)({
            tag: "readUserJSON",
            internalMsg: msg,
            response: msg
        });
    };
    return readJSON$prime(dictTofuJSON)(prepareError);
};
var readUserJSON$prime = function (dictMonadThrow) {
    return function (dictTofuJSON) {
        var $179 = throwEither(dictMonadThrow);
        var $180 = readUserJSON(dictTofuJSON);
        return function ($181) {
            return $179($180($181));
        };
    };
};
var addSocialExpenses = function (costClasses) {
    var factor = function (v) {
        return 1.0 + v;
    };
    var updateValue = function (il$prime) {
        var matchingCC = Data_Array.find(function (x) {
            return Data_Eq.eq(Types_Newtypes.eqCostClassCode)(x.costClassCode)(il$prime.costClassCode);
        })(costClasses);
        var mSEP = Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
            return v.socialExpensePercentageInCostControlForEstimatesAndActuals;
        })(matchingCC);
        if (mSEP instanceof Data_Maybe.Just) {
            return factor(nullable(0.0)(Types_Unit.PercentageOfZeroToOne)(mSEP.value0)) * il$prime.value;
        };
        if (mSEP instanceof Data_Maybe.Nothing) {
            return il$prime.value;
        };
        throw new Error("Failed pattern match at Utils (line 521, column 7 - line 523, column 29): " + [ mSEP.constructor.name ]);
    };
    return Data_Functor.map(Data_Functor.functorArray)(function (il) {
        return {
            value: updateValue(il),
            costClass: il.costClass,
            costClassCode: il.costClassCode,
            costGroupCode: il.costGroupCode,
            date: il.date,
            description: il.description,
            hours: il.hours,
            id: il.id,
            invoiceBunchId: il.invoiceBunchId,
            invoiceId: il.invoiceId,
            invoiceImg: il.invoiceImg,
            invoiceNumber: il.invoiceNumber,
            lineDescription: il.lineDescription,
            quantity: il.quantity,
            socialExpensesPercentage: il.socialExpensesPercentage,
            supplier: il.supplier,
            supplierBusinessId: il.supplierBusinessId,
            supplierInvoiceNumber: il.supplierInvoiceNumber,
            type: il.type,
            unit: il.unit
        };
    });
};
module.exports = {
    just: just,
    nothing: nothing,
    isNothing: isNothing,
    toNullable: toNullable,
    printError: printError,
    readMaybe: readMaybe,
    readStringOrNumberAsString: readStringOrNumberAsString,
    readNullableStringOrNumberAsMaybeString: readNullableStringOrNumberAsMaybeString,
    readNullableStringAsMaybe: readNullableStringAsMaybe,
    readNullableStringAsString: readNullableStringAsString,
    stringMaybeEmpty: stringMaybeEmpty,
    stringNullableEmpty: stringNullableEmpty,
    appErrorKeys: appErrorKeys,
    mkUserError: mkUserError,
    mkServerError: mkServerError,
    mkCustomError: mkCustomError,
    throwEither: throwEither,
    readAppError: readAppError,
    "readUserJSON'": readUserJSON$prime,
    readUserJSON: readUserJSON,
    "readUserForeign'": readUserForeign$prime,
    readUserForeign: readUserForeign,
    "readParamsUserForeign'": readParamsUserForeign$prime,
    readParamsUserForeign: readParamsUserForeign,
    "readServerJSON'": readServerJSON$prime,
    readServerJSON: readServerJSON,
    "readServerForeign'": readServerForeign$prime,
    readServerForeign: readServerForeign,
    traverseThrowJSONE: traverseThrowJSONE,
    "mapForeignErrors'": mapForeignErrors$prime,
    mapForeignErrors: mapForeignErrors,
    "readJSON'": readJSON$prime,
    "read'": read$prime,
    readFail: readFail,
    fromJSONOf: fromJSONOf,
    "readFail'": readFail$prime,
    "readJSONFail'": readJSONFail$prime,
    homogeneousToObject: homogeneousToObject,
    recordToObject: recordToObject,
    recordsToObjects: recordsToObjects,
    newtypeRecordsToObjects: newtypeRecordsToObjects,
    defaultNull: defaultNull,
    fromNullable: fromNullable,
    pureNullable: pureNullable,
    isNull: isNull,
    mapNullable: mapNullable,
    bindMaybeNullable: bindMaybeNullable,
    foldNullable: foldNullable,
    nullable: nullable,
    nullValue: nullValue,
    lossyToISOString: lossyToISOString,
    lossyToHtmlDateString: lossyToHtmlDateString,
    lossyParseJSDate: lossyParseJSDate,
    lossyParseJSDateString: lossyParseJSDateString,
    getSumWithoutVATValidationWarning: getSumWithoutVATValidationWarning,
    sumWithoutVATValidation: sumWithoutVATValidation,
    getNameValidationWarning: getNameValidationWarning,
    nameValidation: nameValidation,
    getCodeValidationWarning: getCodeValidationWarning,
    codeValidation: codeValidation,
    unknownCostGroupString: unknownCostGroupString,
    unknownIncomeGroup: unknownIncomeGroup,
    condPreventDefault: condPreventDefault,
    isUnknownSalesInvoice: isUnknownSalesInvoice,
    matchSalesInvoices: matchSalesInvoices,
    lookupCostClassName: lookupCostClassName,
    matchCostGroupCode: matchCostGroupCode,
    defaultNothingDiv: defaultNothingDiv,
    defaultZeroDiv: defaultZeroDiv,
    defaultZeroInv: defaultZeroInv,
    defaultZero: defaultZero,
    isLink: isLink,
    nearZero: nearZero,
    unsafeTODO: unsafeTODO,
    sbtBooleanToBoolean: sbtBooleanToBoolean,
    coerceNewtypeArray: coerceNewtypeArray,
    convertPercentage100to0: convertPercentage100to0,
    toggleInSet: toggleInSet,
    fromSBDTBoolean: fromSBDTBoolean,
    toSBDTBoolean: toSBDTBoolean,
    groupMap: groupMap,
    addSocialExpenses: addSocialExpenses,
    isSalary: isSalary,
    logError: logError,
    "unsafeLog2'": unsafeLog2$prime,
    memoizeWithJSON: memoizeWithJSON,
    "encodeURIComponent'": encodeURIComponent$prime,
    "decodeURIComponent'": decodeURIComponent$prime,
    count: count,
    eitherToMaybe: eitherToMaybe,
    isPrefix: isPrefix,
    blush: blush,
    arrayMapNullable: $foreign.arrayMapNullable,
    genv4UUID: $foreign.genv4UUID,
    baseUrl: $foreign.baseUrl,
    unsafeLog2: $foreign.unsafeLog2,
    spyTime: $foreign.spyTime,
    "_memoizeWithJSON": $foreign["_memoizeWithJSON"],
    "encodeURIComponent": $foreign["encodeURIComponent"],
    "decodeURIComponent": $foreign["decodeURIComponent"],
    "encodeURI": $foreign["encodeURI"],
    scoreMatch: $foreign.scoreMatch
};
