// Generated by purs version 0.14.5
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Ring = require("../Data.Ring/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Show = require("../Data.Show/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var Utils = require("../Utils/index.js");
var TotalWithTaxes = function (x) {
    return x;
};
var ProjectTaxRate = function (x) {
    return x;
};
var OwnTaxRate = function (x) {
    return x;
};
var NotOwnProject = (function () {
    function NotOwnProject() {

    };
    NotOwnProject.value = new NotOwnProject();
    return NotOwnProject;
})();
var OwnProject = (function () {
    function OwnProject(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    OwnProject.create = function (value0) {
        return function (value1) {
            return new OwnProject(value0, value1);
        };
    };
    return OwnProject;
})();
var showProjectTaxStatus = {
    show: function (v) {
        if (v instanceof NotOwnProject) {
            return "NotOwnProject";
        };
        if (v instanceof OwnProject) {
            return "OwnProject (ProjectTaxRate " + (Data_Show.show(Data_Show.showNumber)(v.value0) + (") (OwnTaxRate " + (Data_Show.show(Data_Show.showNumber)(v.value1) + ")")));
        };
        throw new Error("Failed pattern match at OwnProjectTaxes (line 28, column 1 - line 31, column 85): " + [ v.constructor.name ]);
    }
};
var semiringTotalWithTaxes = Data_Semiring.semiringNumber;
var ringTotalWithTaxes = Data_Ring.ringNumber;
var newtypeTotalWithTaxes = {
    Coercible0: function () {
        return undefined;
    }
};
var getProjectTaxRate = function (project) {
    if (project.ownProject) {
        var projectTaxRate = ProjectTaxRate(Utils.defaultZeroDiv(Data_Maybe.fromMaybe(0.0)(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Newtype.un()(Types_Unit.Taxrate))(project.taxRate)))(100.0));
        var ownTaxRate = OwnTaxRate(Utils.defaultZeroDiv(Utils.defaultNull(0.0)(project.ownTaxRate))(100.0));
        return new OwnProject(projectTaxRate, ownTaxRate);
    };
    return NotOwnProject.value;
};
var eqProjectTaxRate = Data_Eq.eqNumber;
var eqOwnTaxRate = Data_Eq.eqNumber;
var eqProjectTaxStatus = {
    eq: function (x) {
        return function (y) {
            if (x instanceof NotOwnProject && y instanceof NotOwnProject) {
                return true;
            };
            if (x instanceof OwnProject && y instanceof OwnProject) {
                return Data_Eq.eq(eqProjectTaxRate)(x.value0)(y.value0) && Data_Eq.eq(eqOwnTaxRate)(x.value1)(y.value1);
            };
            return false;
        };
    }
};
var applyTaxesForItemWithTaxRate = function (v) {
    return function (selector) {
        return function (item) {
            if (v instanceof NotOwnProject) {
                return {
                    taxes: 0.0,
                    total: TotalWithTaxes(selector(item))
                };
            };
            if (v instanceof OwnProject) {
                var value = selector(item);
                var taxes = Utils.defaultZeroDiv(item.taxRate)(100.0) * value;
                return {
                    taxes: taxes,
                    total: TotalWithTaxes(value + taxes)
                };
            };
            throw new Error("Failed pattern match at OwnProjectTaxes (line 81, column 1 - line 87, column 50): " + [ v.constructor.name, selector.constructor.name, item.constructor.name ]);
        };
    };
};
var applyTaxesForItemWithTaxRate$prime = function (projectTaxStatus) {
    return function (selector) {
        return function (item) {
            return Data_Newtype.un()(TotalWithTaxes)((function (v) {
                return v.total;
            })(applyTaxesForItemWithTaxRate(projectTaxStatus)(selector)(item)));
        };
    };
};
var applyTaxesForIncomeItems = function (v) {
    return function (selector) {
        return function (incomeItem) {
            if (v instanceof NotOwnProject) {
                return {
                    taxes: 0.0,
                    total: TotalWithTaxes(selector(incomeItem))
                };
            };
            if (v instanceof OwnProject) {
                var value = selector(incomeItem);
                var taxRate = Utils.foldNullable(0.0)(Data_Newtype.un()(Types_Unit.Taxrate))(incomeItem.taxRate);
                var taxes = Utils.defaultZeroDiv(taxRate)(100.0) * value;
                return {
                    taxes: taxes,
                    total: TotalWithTaxes(value + taxes)
                };
            };
            throw new Error("Failed pattern match at OwnProjectTaxes (line 67, column 1 - line 72, column 50): " + [ v.constructor.name, selector.constructor.name, incomeItem.constructor.name ]);
        };
    };
};
var applyTaxesForCosts = function (v) {
    return function (cost) {
        if (v instanceof NotOwnProject) {
            return {
                taxes: 0.0,
                total: cost
            };
        };
        if (v instanceof OwnProject) {
            var projectTaxes = cost * v.value0;
            var taxes = projectTaxes * v.value1 + projectTaxes;
            return {
                taxes: taxes,
                total: TotalWithTaxes(cost + taxes)
            };
        };
        throw new Error("Failed pattern match at OwnProjectTaxes (line 58, column 1 - line 58, column 97): " + [ v.constructor.name, cost.constructor.name ]);
    };
};
module.exports = {
    NotOwnProject: NotOwnProject,
    OwnProject: OwnProject,
    ProjectTaxRate: ProjectTaxRate,
    OwnTaxRate: OwnTaxRate,
    getProjectTaxRate: getProjectTaxRate,
    TotalWithTaxes: TotalWithTaxes,
    applyTaxesForCosts: applyTaxesForCosts,
    applyTaxesForIncomeItems: applyTaxesForIncomeItems,
    applyTaxesForItemWithTaxRate: applyTaxesForItemWithTaxRate,
    "applyTaxesForItemWithTaxRate'": applyTaxesForItemWithTaxRate$prime,
    eqProjectTaxStatus: eqProjectTaxStatus,
    showProjectTaxStatus: showProjectTaxStatus,
    eqProjectTaxRate: eqProjectTaxRate,
    eqOwnTaxRate: eqOwnTaxRate,
    newtypeTotalWithTaxes: newtypeTotalWithTaxes,
    semiringTotalWithTaxes: semiringTotalWithTaxes,
    ringTotalWithTaxes: ringTotalWithTaxes
};
