// Generated by purs version 0.14.5
"use strict";
var Client_WASM = require("../Client.WASM/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Show = require("../Data.Show/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var TacoList = require("../TacoList/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var summarizeResourcesForCostClass = function (resources) {
    return function (costClass) {
        var isCostClass = function (cc) {
            return function (resource) {
                var v = Data_Nullable.toMaybe(resource.costClassCode);
                if (v instanceof Data_Maybe.Nothing) {
                    return false;
                };
                if (v instanceof Data_Maybe.Just) {
                    return Data_Eq.eq(Types_Newtypes.eqCostClassCode)(cc.costClassCode)(v.value0);
                };
                throw new Error("Failed pattern match at ResourceSummaries (line 42, column 7 - line 44, column 46): " + [ v.constructor.name ]);
            };
        };
        var resourceCount = Data_Array.length(Data_Array.filter(isCostClass(costClass))(resources));
        return {
            costClass: costClass,
            resourceCount: resourceCount
        };
    };
};
var mkItem = function (props) {
    var toItem = function (cc) {
        var title = Data_Newtype.un()(Types_Newtypes.CostClassCode)(cc.costClass.costClassCode) + (". " + cc.costClass.name);
        return {
            title: title,
            titleJSX: Data_Maybe.Nothing.value,
            id: title,
            value: TacoList.TextValue.create(Data_Show.show(Data_Show.showInt)(cc.resourceCount) + " kpl"),
            disabled: false,
            onClick: Data_Maybe.Nothing.value,
            getItems: function (v) {
                return [  ];
            },
            hasItems: false,
            initExpanded: false
        };
    };
    var resourceCount = Data_Array.length(props.resources);
    return {
        title: "Hankkeen panokset",
        titleJSX: Data_Maybe.Nothing.value,
        id: "Hankkeen panokset",
        value: TacoList.TextValue.create(Data_Show.show(Data_Show.showInt)(resourceCount) + " kpl"),
        disabled: false,
        onClick: Data_Maybe.Nothing.value,
        initExpanded: true,
        getItems: function (v) {
            return Data_Functor.map(Data_Functor.functorArray)(function ($9) {
                return TacoList.ExpandableItemWrapper(toItem($9));
            })(props.costClasses);
        },
        hasItems: Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Array["null"])(props.costClasses)
    };
};
var component = TofuHooks.mkHookComponent("ResourceSummaries")(function (props) {
    return function __do() {
        var v = Client_WASM.useCostClasses(props.projectId)();
        var summaries = Data_Functor.map(Data_Functor.functorArray)(summarizeResourcesForCostClass(props.resources))(v.value0);
        return ReactBasicUtils["div'"]("__ResourceSummaries")([ TacoList.component({
            items: [ mkItem({
                costClasses: summaries,
                resources: props.resources
            }) ]
        }) ]);
    };
});
module.exports = {
    component: component,
    summarizeResourcesForCostClass: summarizeResourcesForCostClass,
    mkItem: mkItem
};
