import { Combobox, SelectItem, Text } from "@tocoman/ui";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PrintingConfiguration } from "@prisma/client";
import { retryRun, useRunOnce } from "../../../hooks/useRunOnce";

type PrintingControlsProps = {
  onModelChange: (value: PrintingConfiguration | null) => void;
  selectedModel?: PrintingConfiguration | null;
  printingConfigurations: PrintingConfiguration[];
};

export const PrintingControls = ({
  onModelChange,
  selectedModel,
  printingConfigurations,
}: PrintingControlsProps) => {
  const { t } = useTranslation("estimation", { keyPrefix: "printing" });

  const printingConfigurationItems = useMemo<
    SelectItem<PrintingConfiguration>[]
  >(() => {
    return printingConfigurations.map((printingConfiguration) => ({
      key: printingConfiguration.id ? printingConfiguration.id.toString() : "",
      label: printingConfiguration.name,
      value: printingConfiguration,
    }));
  }, [printingConfigurations]);

  const [value, setValue] = useState<SelectItem<PrintingConfiguration> | null>(
    null
  );

  // Selected printing configuration changed from outside
  useEffect(() => {
    if (selectedModel === undefined || value?.value === selectedModel) {
      return;
    }
    setValue(
      selectedModel
        ? {
            key: selectedModel?.id ? selectedModel.id.toString() : "",
            label: selectedModel?.name || "",
            value: selectedModel,
          }
        : null
    );
  }, [selectedModel, setValue, value]);

  const [filterString, setFilterString] = useState<string>("");
  const filteredItems = useMemo<SelectItem<PrintingConfiguration>[]>(() => {
    return filterString === ""
      ? printingConfigurationItems
      : printingConfigurationItems.filter((item) =>
          item.label
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(filterString.toLowerCase().replace(/\s+/g, ""))
        );
  }, [filterString, printingConfigurationItems]);

  const onConfigurationChange = useCallback(
    (value: SelectItem<PrintingConfiguration> | null) => {
      if (!value) {
        setValue(null);
        return;
      }
      onModelChange(value.value);
      setValue(value);
      setFilterString("");
    },
    [onModelChange]
  );

  const activateFirstConfiguration = useCallback(() => {
    if (printingConfigurationItems.length === 0) {
      return retryRun;
    }
    if (!value) {
      onConfigurationChange(printingConfigurationItems[0]);
    }
  }, [printingConfigurationItems, onConfigurationChange, value]);

  useRunOnce(activateFirstConfiguration);

  return (
    <div className={"flex justify-between p-4"}>
      <div className={"flex gap-5 align-middle"}>
        <Combobox
          selected={value}
          items={filteredItems}
          onValueChange={onConfigurationChange}
          filterHandler={setFilterString}
          width={220}
        />
        <Text text={t`restOfConfigurations`} className={"self-center"} />
      </div>
    </div>
  );
};
