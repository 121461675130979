import {
  IconGrid,
  IconHelp,
  IconNotifications,
  Logo,
  LogoEstima,
  LogoInsite,
  NavigationBar,
} from "@tocoman/ui";
import { useTranslation } from "react-i18next";
import { NavigationPopoverProps } from "@tocoman/ui/dist/components/NavigationBar/NavigationPopover";
import { useWorkerState } from "../../hooks/useWorkerState";
import { AppSelection } from "./AppSelection";
import { useNotifications } from "../../hooks/queries/useNotifications";
import React, { useEffect } from "react";
import { useReadReleaseNoteIds } from "../../hooks/useReadNotifications";
import { Notifications } from "./Notifications";
import { extractNameFromEmail, UserMenu } from "./UserMenu";
import { useFrontEndNavigation } from "../../FrontEndNavigation/useFrontEndNavigation";
import { useUserDataQuery } from "../Projects/useUserData";

type NavBarProps = {
  projectId?: number;
  applicationMode?: string;
};

export const NavBar = ({ projectId, applicationMode }: NavBarProps) => {
  const { t } = useTranslation("navigation");
  const navigate = useFrontEndNavigation();

  const userData = useUserDataQuery();
  const [_, initials] = extractNameFromEmail(userData?.userData.email ?? "");
  const projectData = useWorkerState("ProjectDetailsDataState", projectId);
  const projectName = projectData?.name;
  const projectVersion = projectData?.version;

  let currentApp: JSX.Element | undefined;
  let project: string | undefined;
  switch (applicationMode) {
    case "Laskenta":
      currentApp = (
        <button
          onClick={() => navigate("EstimationProjectsRoute")}
          key={"estimation"}
        >
          {t("routes.projects")}
        </button>
      );
      project = `${projectName} - v${projectVersion}`;
      break;
    case "Määrälaskenta":
      currentApp = (
        <button
          onClick={() => navigate("EstimationProjectsRoute")}
          key={"estimation"}
        >
          {t("routes.projects")}
        </button>
      );
      project = `${projectName} - v${projectVersion}`;
      break;
    case "Raportointi":
      currentApp = (
        <button
          onClick={() => navigate("ReportingProjectListRoute")}
          key={"costControl"}
        >
          {t("routes.projects")}
        </button>
      );
      project = `${projectName} - v${projectVersion}`;
      break;
    default:
      currentApp = undefined;
      project = undefined;
  }

  const notifications = useNotifications();
  const releaseNotes = React.useMemo(
    () => notifications.data?.releaseNotes ?? [],
    [notifications]
  );

  const [readReleaseNoteIds, setReadReleaseNoteIds] = useReadReleaseNoteIds();

  const newNotifications = releaseNotes.find(
    (releaseNote) => !readReleaseNoteIds.includes(releaseNote.id)
  );

  const handleClose = React.useCallback(() => {
    setReadReleaseNoteIds(releaseNotes.map((releaseNote) => releaseNote.id));
  }, [setReadReleaseNoteIds, releaseNotes]);

  let logo;
  switch (applicationMode) {
    case "Laskenta":
      logo = (
        <Logo
          logo={LogoEstima}
          onClick={() => navigate("EstimationProjectsRoute")}
          className={"cursor-pointer"}
        />
      );
      break;
    case "Määrälaskenta":
      logo = (
        <Logo
          logo={LogoEstima}
          onClick={() => navigate("EstimationProjectsRoute")}
          className={"cursor-pointer"}
        />
      );
      break;
    case "Raportointi":
      logo = (
        <Logo
          logo={LogoInsite}
          onClick={() => navigate("ReportingProjectListRoute")}
          className={"cursor-pointer"}
        />
      );
      break;
    default:
      switch (userData?.permissions.costEstimationPermission) {
        case false:
          logo = (
            <Logo
              logo={LogoInsite}
              onClick={() => navigate("ReportingProjectListRoute")}
            />
          );
          break;
        default:
          logo = (
            <Logo
              logo={LogoEstima}
              onClick={() => navigate("EstimationProjectsRoute")}
            />
          );
      }
  }

  const breadcrumbs = projectName ? [currentApp, project] : [];

  const [closePopover, setClosePopover] = React.useState(false);

  useEffect(() => {
    if (closePopover) {
      // Set to false after closing the popover to close the popover more than once
      setClosePopover(false);
    }
  }, [closePopover]);

  const switchAppAction = [
    {
      popoverTitle: t("switchApp"),
      icon: { icon: IconGrid, scale: 1.2 },
      content: (
        <AppSelection
          userData={userData}
          currentProgram={applicationMode}
          onNavigate={() => setClosePopover(true)}
        />
      ),
      width: 300,
      closePopover: closePopover,
    },
  ] as (NavigationPopoverProps & { link: string })[];

  const otherActions = [
    {
      popoverTitle: t("notifications"),
      icon: { icon: IconNotifications, scale: 1.2 },
      content: (
        <Notifications
          releaseNotes={releaseNotes}
          readReleaseNoteIds={readReleaseNoteIds}
        />
      ),
      width: 500,
      onClose: handleClose,
      unreadIndicator: newNotifications,
    },
    {
      icon: { icon: IconHelp, scale: 1.2 },
      link: "https://www.tocoman.fi/laskenta/ohjeet",
    },
    {
      popoverTitle: t("account"),
      customIcon: (
        <div className="avatar">
          <div className="round-icon">{initials}</div>
        </div>
      ),
      content: <UserMenu userData={userData} />,
      width: 300,
    },
  ] as (NavigationPopoverProps & { link: string })[];

  const actions =
    userData?.permissions.costControlPermission &&
    userData.permissions.costEstimationPermission
      ? [...switchAppAction, ...otherActions]
      : otherActions;
  return (
    <NavigationBar logo={logo} breadcrumbs={breadcrumbs} actions={actions} />
  );
};
