import { StateNode } from "ts-bindings/StateNode";
import { WorkerRequest } from "ts-bindings/WorkerRequest";
import { WorkerAction } from "ts-bindings/WorkerAction";

export const mkSingletonStateUpdateAction = (
  stateUpdate: StateNode
): WorkerRequest => ({
  type: "StateUpdate",
  value: {
    state: [stateUpdate],
    strategy: null,
  },
});

/**
 * Creates action for dispatching backend data updates via the worker.
 *
 * Doing this via the worker thread makes it possible to:
 * - Update worker state while fetching
 * - Update worker state after fetching succeeds / fails
 * - Trigger refetching of data if needed
 */
export const mkWorkerAction = (update: WorkerAction): WorkerRequest => ({
  type: "WorkerAction",
  value: { update },
});

export const mkStateUpdate = (update: StateNode): WorkerRequest => ({
  type: "StateUpdate",
  value: { state: [update], strategy: null },
});

export const mkRefreshLocations = (projectId: number): WorkerRequest => ({
  type: "RefreshProjectLocations",
  value: { request_params: projectId },
});

export const mkRefreshComponents = (projectId: number): WorkerRequest => ({
  type: "RefreshEstimationComponentsData",
  value: { request_params: projectId },
});
