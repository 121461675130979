import { ReactNode } from "react";
import { SettingsNav } from "../containers/Admin/Settings/SettingsNav";
import { AdminUnauthorized } from "../containers/Admin/Settings/AdminUnauthorized";
import { Loader } from "@tocoman/ui";
import { useUserDataQuery } from "../containers/Projects/useUserData";

type Props = {
  children: ReactNode;
};

export const SettingsContainer = ({ children }: Props) => {
  const userData = useUserDataQuery();
  const isAdmin =
    userData?.permissions.isAdmin || userData?.userData.isSuperAdmin;

  if (!userData) {
    return <Loader isLoading={true} className={"m-10"} />;
  }
  if (!isAdmin) {
    return <AdminUnauthorized />;
  }
  return (
    <div className={"h-full w-full flex"}>
      <div>
        <SettingsNav />
      </div>
      <div className={"flex flex-col w-full p-4"}>{children}</div>
    </div>
  );
};
