// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var CostEstimation_ProjectAutoComplete = require("../CostEstimation.ProjectAutoComplete/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Effect = require("../Effect/index.js");
var Grid = require("../Grid/index.js");
var LengthValidator = require("../LengthValidator/index.js");
var ProjectComponents = require("../ProjectComponents/index.js");
var React_Basic = require("../React.Basic/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Utils = require("../Utils/index.js");
var copyEstimationProjectButton = TofuHooks.mkHookComponent("CopyEstimationComponentsToProject")(function (props) {
    var defaultInputs = {
        code: "",
        version: "",
        name: ""
    };
    return function __do() {
        var dispatch = StateHooks.useDispatch();
        var v = TofuHooks.useState(false)();
        var v1 = TofuHooks.useState(defaultInputs)();
        var v2 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var v3 = TofuHooks.useState(false)();
        var existingCodesAndVersions = ProjectComponents.getExistingCodesAndVersions(new Data_Maybe.Just(props.projectList))();
        TofuHooks.useEffect([ v2.value0 ])(function __do() {
            Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(v2.value0)(function (p) {
                var latestVersion = ProjectComponents.getLatestVersionForCode(Data_Newtype.unwrap()(p.code))(existingCodesAndVersions);
                return v1.value1(function (v4) {
                    return {
                        code: Data_Newtype.unwrap()(p.code),
                        version: Data_Maybe.maybe("")(function (v5) {
                            return Data_Show.show(Data_Show.showInt)(v5 + 1 | 0);
                        })(latestVersion),
                        name: p.name
                    };
                });
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Effect.monoidEffect(Data_Monoid.monoidUnit)))();
        })();
        TofuHooks.useEffect([ v1.value0.code ])(function __do() {
            Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(v2.value0)(function (p) {
                return Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(v1.value0.code !== Data_Newtype.unwrap()(p.code))((function () {
                    var mLatestVersion = ProjectComponents.getLatestVersionForCode(v1.value0.code)(existingCodesAndVersions);
                    return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(mLatestVersion)(function (v4) {
                        return v1.value1(function (v5) {
                            return {
                                code: v5.code,
                                version: Data_Show.show(Data_Show.showInt)(v4 + 1 | 0),
                                name: v5.name
                            };
                        });
                    });
                })());
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Effect.monoidEffect(Data_Monoid.monoidUnit)))();
        })();
        var versionField = TacoInput.stringInput()()(Data_Eq.eqString)({
            label: "Versio",
            validator: LengthValidator.lengthValidator(1)(3),
            required: true,
            value: v1.value0.version,
            onChange: function (updateValue) {
                return v1.value1(function (s) {
                    return {
                        code: s.code,
                        version: updateValue(s.version),
                        name: s.name
                    };
                });
            },
            testId: "new-project-version-input"
        });
        var projectTacoAutocomplete = CostEstimation_ProjectAutoComplete.mkProjectTacoAutocomplete({
            projectList: props.projectList,
            setTargetProject: v2.value1,
            targetProject: v2.value0
        });
        var openModalButton = TacoButton.component()()({
            text: "Kopioi hanke",
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconCopyToProjectV1.value),
            onClick: Data_Maybe.Just.create(v.value1(function (v4) {
                return true;
            })),
            testId: "copy-project-button"
        });
        var nameField = TacoInput.stringInput()()(Data_Eq.eqString)({
            label: "Nimi",
            validator: LengthValidator.lengthValidator(3)(50),
            required: true,
            value: v1.value0.name,
            onChange: function (updateValue) {
                return v1.value1(function (s) {
                    return {
                        code: s.code,
                        version: s.version,
                        name: updateValue(s.name)
                    };
                });
            },
            testId: "new-project-name-input"
        });
        var modalActions = TacoModal.modalActions({
            contents: [ TacoButton.component()()({
                onClick: Data_Maybe.Just.create(v.value1(function (v4) {
                    return false;
                })),
                text: "Peruuta",
                testId: "cancel-copy-project-button"
            }), TacoButton.component()()({
                onClick: new Data_Maybe.Just(function __do() {
                    v3.value1(function (v4) {
                        return true;
                    })();
                    return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(v2.value0)(function (p) {
                        var params = {
                            name: v1.value0.name,
                            code: v1.value0.code,
                            version: v1.value0.version,
                            sourceProjectId: p.id
                        };
                        return dispatch(new Actions.CopyProjectRequest({
                            value: params,
                            handler: function (result) {
                                return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableEither)(result)(function (v4) {
                                    return function __do() {
                                        v1.value1(function (v5) {
                                            return defaultInputs;
                                        })();
                                        v.value1(function (v5) {
                                            return false;
                                        })();
                                        return v3.value1(function (v5) {
                                            return false;
                                        })();
                                    };
                                });
                            }
                        }));
                    })();
                }),
                title: (function () {
                    if (v3.value0) {
                        return "Lataa...";
                    };
                    return "";
                })(),
                icon: (function () {
                    if (v3.value0) {
                        return new Data_Maybe.Just(TacoIcon_Icons_Types.IconFetch.value);
                    };
                    return Data_Maybe.Nothing.value;
                })(),
                disabled: Data_String_Common["null"](v1.value0.name) || (Data_String_Common["null"](v1.value0.code) || (Data_String_Common["null"](v1.value0.version) || (Utils.isNothing(v2.value0) || v3.value0))),
                text: "Kopioi",
                testId: "copy-project-button"
            }) ]
        });
        var codeField = TacoInput.stringInput()()(Data_Eq.eqString)({
            label: "Tunnus",
            validator: LengthValidator.lengthValidator(1)(12),
            required: true,
            value: v1.value0.code,
            onChange: function (updateValue) {
                return v1.value1(function (s) {
                    return {
                        code: updateValue(s.code),
                        version: s.version,
                        name: s.name
                    };
                });
            },
            testId: "new-project-code-input"
        });
        var form = Grid.grid([ new Box.Gap(TacoConstants.S.value), Box.AlignStart.value ])([ [ Grid.Auto.create(TacoText.component()()({
            text: "Valitse kopioitava hanke",
            weight: TacoText_Types.Bold.value,
            variant: new TacoText_Types.Heading(4)
        })) ], [ new Grid.Auto(projectTacoAutocomplete) ], [ Grid.Auto.create(TacoText.component()()({
            text: "Uuden hankkeen tiedot",
            weight: TacoText_Types.Bold.value,
            variant: new TacoText_Types.Heading(4)
        })) ], [ new Grid.Auto(nameField) ], [ new Grid.Auto(codeField) ], [ new Grid.Auto(versionField) ] ]);
        var modalBody = TacoModal.modalBody({
            contents: [ form ]
        });
        var modal = TacoModal.component()()({
            onClose: v.value1(function (v4) {
                return false;
            }),
            isActive: v.value0,
            heading: "Kopioi hanke",
            contents: [ modalBody, modalActions ]
        });
        return React_Basic.fragment([ modal, openModalButton ]);
    };
});
module.exports = {
    copyEstimationProjectButton: copyEstimationProjectButton
};
