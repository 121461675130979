// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Components_Utils = require("../Components.Utils/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var HeaderTitle_Styles = require("../HeaderTitle.Styles/index.js");
var HtmlUtils = require("../HtmlUtils/index.js");
var Locale = require("../Locale/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var Roles_Constants = require("../Roles.Constants/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoDropdown = require("../TacoDropdown/index.js");
var TacoDropdown_Types = require("../TacoDropdown.Types/index.js");
var TacoLoader = require("../TacoLoader/index.js");
var TacoText = require("../TacoText/index.js");
var TacoTooltip = require("../TacoTooltip/index.js");
var TacoTooltip_Styles = require("../TacoTooltip.Styles/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_FrontEndRoutes = require("../Types.FrontEndRoutes/index.js");
var Types_Organization = require("../Types.Organization/index.js");
var Types_Project = require("../Types.Project/index.js");
var UseFetch = require("../UseFetch/index.js");
var component = TofuHooks.mkHookComponent("HeaderTitle")(function (v) {
    return function __do() {
        var v1 = UseFetch.useConditionalFetchValue(Data_Maybe.eqMaybe(Types_Project.eqProjectId))(v.projectId)(function (v1) {
            return v1.selectedProject;
        })(function (key) {
            return Data_Functor.map(Data_Maybe.functorMaybe)(Data_Functor.map(Data_Functor.functorFn)(Actions.GetProjectRequest.create)(function (v1) {
                return {
                    projectId: v1
                };
            }))(key);
        })();
        var v2 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v2) {
            return v2.currentOrganization;
        })(Actions.GetCurrentOrganizationRequest.value)();
        var v3 = Data_Functor.map(Effect.functorEffect)(UseFetch.defaultFetchValue(Roles_Constants.noAppAccessRights))(UseFetch.useConditionalFetchValue(Data_Maybe.eqMaybe(Types_Organization.eqOrganization))(Data_Functor.map(Data_Maybe.functorMaybe)(function (v3) {
            return v3.organization;
        })(v2.value0))(function (v3) {
            return v3.appAccessRights;
        })(function (key) {
            return Data_Functor.map(Data_Maybe.functorMaybe)(Actions.GetUserAppAccessRightsRequest.create)(key);
        }))();
        var appDispatch = StateHooks.useDispatch();
        var v4 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var projectNameStr = function (project) {
            var ellipsis = (function () {
                var $17 = Data_String_CodeUnits.length(project.name) > 9;
                if ($17) {
                    return "...";
                };
                return "";
            })();
            return Data_Newtype.un()(Types_Project.ProjectCode)(project.code) + (" " + (Data_Maybe.fromMaybe("")(Data_String_CodeUnits.slice(0)(9)(project.name)) + (ellipsis + (" " + project.version))));
        };
        var mkModeLink = function (id) {
            return function (href) {
                return function (action) {
                    return function (text) {
                        return function (enabled) {
                            if (enabled) {
                                return TacoButton.component()()({
                                    href: new Data_Maybe.Just(href),
                                    onClick: Data_Maybe.Just.create(appDispatch(action)),
                                    text: text,
                                    align: TacoButton_Types.Left.value,
                                    testId: "navigation-" + id
                                });
                            };
                            return React_Basic_DOM_Generated.div()({
                                className: "disabled",
                                children: [ TacoTooltip.component()()({
                                    anchor: [ React_Basic_DOM_Generated.div()({
                                        className: "app-link",
                                        children: [ React_Basic_DOM.text(text) ]
                                    }) ],
                                    justify: TacoTooltip_Styles.BottomLeft.value,
                                    tip: [ React_Basic_DOM.text("Sinulla ei ole oikeuksia t\xe4m\xe4n sovelluksen k\xe4ytt\xf6\xf6n. Ole yhteydess\xe4 tukeen.") ]
                                }) ],
                                "_data": Components_Utils.mkTestIdAttrs("navigation-" + id)
                            });
                        };
                    };
                };
            };
        };
        var modes = (function () {
            if (v3.value1) {
                return [ {
                    title: Data_Maybe.Nothing.value,
                    items: [ TacoLoader.component()()({
                        size: "32px"
                    }) ]
                } ];
            };
            return [ {
                title: new Data_Maybe.Just("Valitse sovellus"),
                items: [ mkModeLink("cost-control")(Types_FrontEndRoutes["fragments"]["cost-control"])(new Actions.NavigateRoute(Types_FrontEndRoutes.ReportingProjectListRoute.value))(Locale.lookup_("mode_name_reporting"))(v3.value0.costControl), mkModeLink("cost-estimation")(Types_FrontEndRoutes.fragments.estimation)(new Actions.NavigateRoute(Types_FrontEndRoutes.EstimationProjectsRoute.value))(Locale.lookup_("mode_name_estimation"))(v3.value0.estimation), mkModeLink("admin")(Types_FrontEndRoutes.fragments.admin)(new Actions.NavigateRoute(Types_FrontEndRoutes.AdminFERoute.value))("K\xe4ytt\xe4j\xe4hallinta")(v3.value0.admin) ]
            } ];
        })();
        var modeSelection = TacoDropdown.component({
            sections: modes,
            toggled: v.showModeSelection,
            setToggled: v.setShowModeSelection,
            anchorEl: v4.value0,
            align: TacoDropdown_Types.AlignLeft.value
        });
        var divider = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "divider",
            css: HeaderTitle_Styles.dividerStyles,
            children: [ React_Basic_DOM_Generated.img()({
                src: "/icon-gt-light-24px.svg"
            }) ]
        });
        var portfolio = TacoButton.component()()({
            buttonStyle: TacoButton_Types.Link.value,
            color: TacoConstants.TextPrimary.value,
            href: new Data_Maybe.Just((function () {
                if (v.applicationMode instanceof Types_Project.EstimationMode) {
                    return "/" + Types_FrontEndRoutes.fragments.estimation;
                };
                return "/";
            })()),
            children: new Data_Maybe.Just([ divider, React_Basic_DOM.text(Locale.lookup_("portfolio_header_projects")) ]),
            onClick: Data_Maybe.Just.create(appDispatch(new Actions.NavigateRoute((function () {
                if (v.applicationMode instanceof Types_Project.EstimationMode) {
                    return Types_FrontEndRoutes.EstimationProjectsRoute.value;
                };
                return Types_FrontEndRoutes.ReportingProjectListRoute.value;
            })()))),
            testId: "navigation-portfolio"
        });
        var projectName = function (project) {
            return React_Basic_Emotion.element(React_Basic_DOM_Generated["h2'"]())({
                className: "project-name",
                css: HeaderTitle_Styles.projectNameStyles,
                children: [ divider, Box.box("project-code")([ new Box.PaddingLeft(TacoConstants.XS.value) ])([ TacoText.component()()({
                    text: projectNameStr(project),
                    testId: "project-name",
                    title: Data_Newtype.un()(Types_Project.ProjectCode)(project.code) + (" " + (project.name + (" " + project.version)))
                }) ]) ]
            });
        };
        var appSection = function (children) {
            return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                className: "app-section",
                css: HeaderTitle_Styles.appSectionStyles,
                children: children
            });
        };
        var appName = TacoButton.component()()({
            buttonStyle: TacoButton_Types.Link.value,
            onClickEventHandler: Data_Maybe.Just.create(React_Basic_Events.handler(React_Basic_DOM_Events.currentTarget)(function (target) {
                return function __do() {
                    v.setShowModeSelection(function (v5) {
                        return true;
                    })();
                    return v4.value1(function (v5) {
                        return Data_Maybe.Just.create(HtmlUtils.coerceEventTargetToHTMLElement(target));
                    })();
                };
            })),
            color: TacoConstants.TextPrimary.value,
            testId: "navigation-app-mode",
            children: new Data_Maybe.Just([ TacoText.component()()({
                inheritColor: true,
                text: (function () {
                    if (v.applicationMode instanceof Types_Project.ReportingMode) {
                        return Locale.lookup_("mode_name_reporting");
                    };
                    if (v.applicationMode instanceof Types_Project.EstimationMode) {
                        return Locale.lookup_("mode_name_estimation");
                    };
                    if (v.applicationMode instanceof Types_Project.OrganizationMode) {
                        return Locale.lookup_("mode_name_organization");
                    };
                    if (v.applicationMode instanceof Types_Project.IntegrationMode) {
                        return Locale.lookup_("mode_name_integration");
                    };
                    if (v.applicationMode instanceof Types_Project.AdminMode) {
                        return Locale.lookup_("mode_name_admin");
                    };
                    if (v.applicationMode instanceof Types_Project.SuperAdminMode) {
                        return Locale.lookup_("mode_name_superadmin");
                    };
                    if (v.applicationMode instanceof Types_Project.EtcMode) {
                        return Locale.lookup_("mode_name_etc");
                    };
                    throw new Error("Failed pattern match at HeaderTitle (line 77, column 17 - line 84, column 60): " + [ v.applicationMode.constructor.name ]);
                })()
            }) ])
        });
        var appWrapper = React_Basic.fragment([ modeSelection, appName, appSection([ portfolio, (function () {
            if (v.projectId instanceof Data_Maybe.Just && v1.value0 instanceof Data_Maybe.Just) {
                return projectName(v1.value0.value0.project);
            };
            return React_Basic.empty;
        })() ]) ]);
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "header-title",
            css: HeaderTitle_Styles.headerTitleContainerStyles,
            children: [ appWrapper ]
        });
    };
});
module.exports = {
    component: component
};
