import { Button, Input, TabProps, Tabs, Text } from "@tocoman/ui";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { OrganizationRow } from "../Tabs/OrganizationsTab";
import { FormProvider, RegisterOptions, useForm } from "react-hook-form";
import { ErrorMessage } from "../../../components/ErrorMessage";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
} from "@mui/material";
import {
  useCreateOrganization,
  useUpdateOrganization,
} from "../Tabs/useSuperAdminOrganizations";
import { useIsMounted } from "usehooks-ts";
import { OrganizationAdditionalInformationForm } from "./OrganizationAdditionalInformationForm";
import { UltimaSettings } from "./UltimaSettings";

type Props = {
  organizationInformation: OrganizationRow | null;
  setOpen: (data: OrganizationRow | null) => void;
  isOpen: boolean;
  onNewOrganization: (organization: AllOrganizationData) => void;
};

export type AllOrganizationData = {
  organization: string;
  cognitoIdentityProviderName: string | null;
  costControlUsers: number;
  costControlRWUsers: number;
  estimationUsers: number;
  estimationRWUsers: number;
  dbName: string | null;
  dbUsername: string | null;
  dbPassword: string | null;
  plan: string;
  priceListUpdate: boolean;
  adminetTenants: {
    tenantId: string;
    tenantName: string | null;
  }[];
  companyName: string | null;
  description: string | null;
  notes: string | null;
  sendInvitation: boolean;
  expiresAt: Date | null;
  ultimaInstanceType: string;
  defaultVAT: number;
};

export const OrganizationInformation = ({
  organizationInformation,
  setOpen,
  isOpen,
  onNewOrganization,
}: Props) => {
  const { t } = useTranslation("superAdmin");

  const methods = useForm<AllOrganizationData>({
    mode: "onBlur",
  });
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = methods;

  const mkInputProps = (
    field: keyof AllOrganizationData,
    registerOptions?: RegisterOptions<AllOrganizationData, typeof field>
  ) => ({
    label: t(`form.${field}`),
    ...register(field, registerOptions),
  });

  const [isNew, setIsNew] = useState(false);

  const closeOrgModal = () => {
    reset();
    setOpen(null);
  };

  const createOrganization = useCreateOrganization();
  const updateOrganization = useUpdateOrganization();

  // FIXME: Yet another workaround that will not be needed in newer versions of
  // React.
  const isMounted = useIsMounted();

  const onSubmit = async (data: AllOrganizationData) => {
    const companyName = data.companyName === "" ? null : data.companyName;
    const description = data.description === "" ? null : data.description;
    const orgData = {
      name: data.organization,
      costControlUserQuota: data.costControlUsers,
      costControlRWUserQuota: data.costControlRWUsers,
      estimationUserQuota: data.estimationUsers,
      estimationRWUserQuota: data.estimationRWUsers,
      cognitoIdentityProviderName: data.cognitoIdentityProviderName,
      adminetTenants: data.adminetTenants,
      plan: data.plan,
      priceListUpdate: data.priceListUpdate,
      companyName: companyName,
      description: description,
      notes: data.notes,
      sendInvitation: data.sendInvitation,
      expiresAt: data.expiresAt,
      ultimaInstanceType: data.ultimaInstanceType,
      defaultVAT: Number(data.defaultVAT) ?? 25.5,
    };
    const mssqlInfo = {
      mssql: {
        dbName: data.dbName,
        dbUsername: data.dbUsername,
        dbPassword: data.dbPassword,
      },
    };
    if (isNew) {
      try {
        await createOrganization.mutateAsync({ ...orgData, ...mssqlInfo });
      } catch (e) {
        setOpen(null);
      }
    } else await updateOrganization.mutateAsync(orgData);

    if (isMounted()) {
      onNewOrganization(data);
      setOpen(null);
    }

    reset();
  };

  type TabIds = "MSSQL" | "rajat" | "muut" | "notes" | "ultima";

  const tabs: TabProps<TabIds>[] = [
    { id: "MSSQL", color: "components", label: t`MSSQL` },
    { id: "rajat", color: "components", label: t`limits` },
    { id: "muut", color: "components", label: t`others` },
    { id: "notes", color: "components", label: t`notes` },
    { id: "ultima", color: "components", label: t`ultima` },
  ];

  const [selectedTab, setSelectedTab] = useState<TabIds>("MSSQL");

  const addOrgTitle = isNew
    ? t`addOrgTitle`
    : t`editOrgTitle` + " " + organizationInformation?.organization;
  const addOrgChildren = (
    <form>
      <Input
        testId="orgNameInput"
        className="w-full"
        disabled={!isNew}
        {...mkInputProps("organization", {
          required: { value: true, message: t`errors.required` },
          pattern: {
            value: /^[A-Za-z0-9.\-_]+$/,
            message: t`errors.invalidChar`,
          },
        })}
      />
      {errors.organization && (
        <ErrorMessage
          errorMessage={String(errors.organization.message)}
          testId={"org-name-error"}
        />
      )}
      <Tabs
        tabs={tabs}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        className={"mt-5 mb-3"}
      />
      <div className="w-[390px] h-auto">
        {selectedTab === "MSSQL" && (
          <>
            {!isNew && <Text text={t`MSSQLDisabledInfo`} className={"pb-5"} />}
            <Input
              className="w-full"
              disabled={!isNew}
              {...mkInputProps("dbUsername")}
            />
            <Input
              className="w-full"
              disabled={!isNew}
              type="password"
              {...mkInputProps("dbPassword")}
            />
            <Input
              className="w-full"
              disabled={!isNew}
              {...mkInputProps("dbName")}
            />
          </>
        )}
        {selectedTab === "rajat" && (
          <>
            <Input
              testId="cCLimit"
              className="w-full"
              type="number"
              min={0}
              {...mkInputProps("costControlUsers", { valueAsNumber: true })}
            />
            <Input
              className="w-full"
              type="number"
              min={0}
              {...mkInputProps("costControlRWUsers", { valueAsNumber: true })}
            />
            <Input
              className="w-full"
              type="number"
              min={0}
              {...mkInputProps("estimationUsers", { valueAsNumber: true })}
            />
            <Input
              className="w-full"
              type="number"
              min={0}
              {...mkInputProps("estimationRWUsers", { valueAsNumber: true })}
            />
          </>
        )}
        {selectedTab === "muut" && (
          <FormProvider {...methods}>
            <OrganizationAdditionalInformationForm
              expiresAt={organizationInformation?.expiresAt}
            />
          </FormProvider>
        )}
        {selectedTab === "notes" && (
          <TextareaAutosize
            className="w-full border-light border rounded-md p-2"
            {...mkInputProps("notes")}
          />
        )}
        {selectedTab === "ultima" && (
          <FormProvider {...methods}>
            <UltimaSettings />
          </FormProvider>
        )}
      </div>
    </form>
  );

  const addOrgActions = (
    <>
      <div className={"flex justify-between w-full"}>
        <div>
          <Button
            color="normal"
            variant="text"
            size="large"
            onClick={() => closeOrgModal()}
          >
            {t`cancel`}
          </Button>
        </div>
        <div className={"flex justify-end"}>
          <Button
            color="normal"
            size="large"
            loading={
              updateOrganization.isLoading || createOrganization.isLoading
            }
            disabled={
              updateOrganization.isLoading || createOrganization.isLoading
            }
            onClick={handleSubmit(onSubmit)}
            testId={"save-org-button"}
          >
            {isNew ? t`addOrganization` : t`save`}
          </Button>
        </div>
      </div>
    </>
  );

  useEffect(() => {
    if (organizationInformation) {
      setIsNew(organizationInformation.organization === "");
      reset(organizationInformation);
    }
  }, [organizationInformation, reset]);

  return (
    <Dialog open={isOpen} onClose={closeOrgModal} maxWidth={"sm"}>
      <DialogTitle>{addOrgTitle}</DialogTitle>
      <DialogContent>{addOrgChildren}</DialogContent>
      <DialogActions>{addOrgActions}</DialogActions>
    </Dialog>
  );
};
