import { useMemo } from "react";
import {
  ColGroupDef,
  ExcelExportParams,
  ExcelRow,
  GridApi,
} from "ag-grid-community";
import { useTranslation } from "react-i18next";
import { ProjectDetailsData } from "../../../../../../ts-bindings/ProjectDetailsData";
import { useGridFilterModel } from "../../../../utils/Printing/useGridFilterModel";
import { ComponentSummary } from "../Printing";
import { getResourceRows } from "../ResourceDetails/ResourceDetailExportRows";
import { excelStyles } from "../../../../utils/Printing/excelStyles";

export function usePrintingExcelExportSettings(
  printoutName: string,
  project: ProjectDetailsData | null,
  includeResources: boolean,
  api?: GridApi | null
) {
  const defaultExcelExportParams = usePrintingExcelExportParams(
    printoutName,
    project,
    includeResources,
    api
  );
  return {
    defaultExcelExportParams,
    excelStyles,
  };
}

export function usePrintingExcelExportParams(
  printoutName: string,
  project: ProjectDetailsData | null,
  includeResources: boolean,
  api?: GridApi | null
) {
  const { t } = useTranslation("estimation", { keyPrefix: "printing" });

  const columnFilterRows = useColumnFilterExcelRows(api);

  return useMemo<ExcelExportParams>(() => {
    return {
      pageSetup: {
        orientation: "Landscape",
      },
      sheetName: printoutName,
      fileName: `${project?.name}-${printoutName}.xlsx`,
      getCustomContentBelowRow: (params) =>
        getResourceRows(params, t, includeResources),
      processRowGroupCallback(params) {
        if (params.node.footer) {
          return t`advancedSettings.total` + " " + `${params.node.key}`;
        }
        return `${params.node.key}`;
      },
      prependContent: [
        {
          cells: [
            {
              data: {
                value: printoutName,
                type: "String",
              },
              styleId: "size20bold",
              mergeAcross: 5,
            },
          ],
        },
        {
          cells: [
            {
              data: {
                value: project ? project.name : "",
                type: "String",
              },
              styleId: "size15",
              mergeAcross: 5,
            },
          ],
        },
        ...columnFilterRows,
      ],
    };
  }, [columnFilterRows, includeResources, printoutName, project, t]);
}

export function useColumnFilterExcelRows(api?: GridApi | null): ExcelRow[] {
  const { t } = useTranslation("estimation", { keyPrefix: "printing" });

  const gridFilterModel = useGridFilterModel<ComponentSummary>(api) ?? {};
  const filteredColumns = useMemo<string[]>(() => {
    const filtersNames = Object.getOwnPropertyNames(gridFilterModel);
    const mainColumNames = filtersNames
      .filter((item) => !item.includes("costClasses"))
      .filter((item) => !item.includes("measurements"))
      .map((item) => {
        const split = item.includes(".") ? item.split(".")[1] : item;
        return t(`tableColumns.${split}`);
      });
    const costClassColumnNames = filtersNames
      .filter((item) => item.includes("costClasses"))
      .map((item) => {
        const costClassSplitColumnName = item.includes(".")
          ? item.split(".")[2]
          : item;
        const costClassColumnName = `tableColumns.${costClassSplitColumnName}`;
        const costClassSplitCostClass = item.includes(".")
          ? item.split(".")[1]
          : item;
        const colDefs = api?.getColumnDefs();
        const columnGroup = colDefs
          ?.filter((item) => (item as ColGroupDef).children !== undefined)
          .filter((item) => item.headerName?.includes(costClassSplitCostClass))
          .map((item) => item.headerName);
        const translatedCostClassColumn = t(`${costClassColumnName}`);
        return `${columnGroup}: ${translatedCostClassColumn}`;
      });
    const measurementColumNames = filtersNames
      .filter((item) => item.includes("measurements"))
      .map((item) => {
        return item.includes(".") ? item.split(".")[1] : item;
      });
    return mainColumNames
      .concat(costClassColumnNames)
      .concat(measurementColumNames);
  }, [api, gridFilterModel, t]);
  return [
    {
      cells: [
        {
          data: {
            value: filteredColumns.toString()
              ? "Suodatus käytössä seuraavissa sarakkeissa:"
              : "",
            type: "String",
          },
          styleId: "size12",
          mergeAcross: 5,
        },
      ],
    },
    {
      cells: [
        {
          data: {
            value: filteredColumns.toString(),
            type: "String",
          },
          styleId: "size12",
          mergeAcross: 5,
        },
      ],
    },
  ];
}
