// Generated by purs version 0.14.5
"use strict";
var Data_Maybe = require("../Data.Maybe/index.js");
var Gap = require("../Gap/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var example8 = "import Button as Button\x0aimport Gap (gapX)\x0a\x0a[ gapX M\x0a    [ TacoButton.component { text: \"Large variant\", size: L, icon: Just IconCheckCircleV1, buttonStyle: TacoButton.Filled }\x0a    ]\x0a]";
var example7 = "import Button as Button\x0aimport Gap (gapX)\x0a\x0a[ gapX M\x0a    [ TacoButton.component { text: \"Vertical button\", icon: Just IconCheckCircleV1, vertical: true }\x0a    ]\x0a]";
var example6 = "import Button as Button\x0aimport Gap (gapX)\x0a\x0a[ gapX M\x0a    [ TacoButton.component { icon: Just IconFetch }\x0a    , TacoButton.component { color: SecondaryRed, icon: Just IconFlagV1 }\x0a    , TacoButton.component { disabled: true, icon: Just IconEditV1 }\x0a    ]\x0a]";
var example5 = "import TacoButton as TacoButton\x0aimport Gap (gapX)\x0a\x0a[ gapX M\x0a    [ TacoButton.component { text: \"Primary\", icon: Just IconFetch }\x0a    , TacoButton.component { text: \"Secondary\", color: SecondaryRed, icon: Just IconFlagV1 }\x0a    , TacoButton.component { text: \"Disabled\", disabled: true, icon: Just IconEditV1 }\x0a    , TacoButton.component { text: \"Right aligned\", disabled: true, icon: Just IconEditV1, iconAlign: TacoButton.IconRight }\x0a    , TacoButton.component { text: \"Primary Filled\", icon: Just IconFetch, buttonStyle: TacoButton.Filled }\x0a    , TacoButton.component { text: \"Secondary Filled\", color: SecondaryRed, icon: Just IconFlagV1, buttonStyle: TacoButton.Filled }\x0a    , TacoButton.component { text: \"Disabled Filled\", disabled: true, icon: Just IconEditV1, buttonStyle: TacoButton.Filled }\x0a    , TacoButton.component { text: \"Right aligned Filled\", icon: Just IconFetch, iconAlign: TacoButton.IconRight, buttonStyle: TacoButton.Filled }\x0a    ]\x0a]";
var example4 = "import TacoButton as TacoButton\x0aimport Gap (gapX)\x0a\x0a[ gapX M\x0a    [ TacoButton.component { text: \"Primary Link\", buttonStyle: TacoButton.Link }\x0a    , TacoButton.component { text: \"Secondary Link\", color: SecondaryRed, buttonStyle: TacoButton.Link }\x0a    , TacoButton.component { text: \"Disabled Link\", disabled: true, buttonStyle: TacoButton.Link }\x0a    ]\x0a]";
var example3 = "import TacoButton as TacoButton\x0aimport Gap (gapX)\x0a\x0a[ gapX M\x0a    [ TacoButton.component { text: \"Primary Filled\", buttonStyle: TacoButton.Filled }\x0a    , TacoButton.component { text: \"Secondary Filled\", color: SecondaryRed, buttonStyle: TacoButton.Filled }\x0a    , TacoButton.component { text: \"Disabled Filled\", disabled: true, buttonStyle: TacoButton.Filled }\x0a    ]\x0a]";
var example2 = "import TacoButton as TacoButton\x0aimport Gap (gapX)\x0a\x0a[ gapX M\x0a    [ TacoButton.component { text: \"Primary Outlined\", buttonStyle: TacoButton.Outlined }\x0a    , TacoButton.component { text: \"Secondary Outlined\", color: SecondaryRed, buttonStyle: TacoButton.Outlined }\x0a    , TacoButton.component { text: \"Disabled Outlined\", disabled: true, buttonStyle: TacoButton.Outlined }\x0a    ]\x0a]";
var example1 = "import TacoButton as TacoButton\x0aimport Gap (gapX)\x0a\x0a[ gapX M\x0a    [ TacoButton.component { text: \"Primary\" }\x0a    , TacoButton.component { text: \"Secondary\", color: SecondaryRed }\x0a    , TacoButton.component { text: \"Disabled\", disabled: true }\x0a    ]\x0a]";
var sections = [ {
    title: "Text style",
    view: [ Gap.gapX(TacoConstants.M.value)([ TacoButton.component()()({
        text: "Primary"
    }), TacoButton.component()()({
        text: "Secondary",
        color: TacoConstants.SecondaryRed.value
    }), TacoButton.component()()({
        text: "Disabled",
        disabled: true
    }) ]) ],
    code: example1
}, {
    title: "Outlined style",
    view: [ Gap.gapX(TacoConstants.M.value)([ TacoButton.component()()({
        text: "Primary Outlined",
        buttonStyle: TacoButton_Types.Outlined.value
    }), TacoButton.component()()({
        text: "Secondary Outlined",
        color: TacoConstants.SecondaryRed.value,
        buttonStyle: TacoButton_Types.Outlined.value
    }), TacoButton.component()()({
        text: "Disabled Outlined",
        disabled: true,
        buttonStyle: TacoButton_Types.Outlined.value
    }) ]) ],
    code: example2
}, {
    title: "Filled style",
    view: [ Gap.gapX(TacoConstants.M.value)([ TacoButton.component()()({
        text: "Primary Filled",
        buttonStyle: TacoButton_Types.Filled.value
    }), TacoButton.component()()({
        text: "Secondary Filled",
        color: TacoConstants.SecondaryRed.value,
        buttonStyle: TacoButton_Types.Filled.value
    }), TacoButton.component()()({
        text: "Disabled Filled",
        disabled: true,
        buttonStyle: TacoButton_Types.Filled.value
    }) ]) ],
    code: example3
}, {
    title: "Link style",
    view: [ Gap.gapX(TacoConstants.M.value)([ TacoButton.component()()({
        text: "Primary Link",
        buttonStyle: TacoButton_Types.Link.value
    }), TacoButton.component()()({
        text: "Secondary Link",
        color: TacoConstants.SecondaryRed.value,
        buttonStyle: TacoButton_Types.Link.value
    }), TacoButton.component()()({
        text: "Disabled Link",
        disabled: true,
        buttonStyle: TacoButton_Types.Link.value
    }) ]) ],
    code: example4
}, {
    title: "With icon",
    view: [ Gap.gapX(TacoConstants.M.value)([ TacoButton.component()()({
        text: "Primary",
        icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconFetch.value)
    }), TacoButton.component()()({
        text: "Secondary",
        color: TacoConstants.SecondaryRed.value,
        icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconFlagV1.value)
    }), TacoButton.component()()({
        text: "Disabled",
        disabled: true,
        icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconEditV1.value)
    }), TacoButton.component()()({
        text: "Right aligned",
        icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconFetch.value),
        iconAlign: TacoButton_Types.IconRight.value
    }), TacoButton.component()()({
        text: "Primary Filled",
        icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconFetch.value),
        buttonStyle: TacoButton_Types.Filled.value
    }), TacoButton.component()()({
        text: "Secondary Filled",
        color: TacoConstants.SecondaryRed.value,
        icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconFlagV1.value),
        buttonStyle: TacoButton_Types.Filled.value
    }), TacoButton.component()()({
        text: "Disabled Filled",
        disabled: true,
        icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconEditV1.value),
        buttonStyle: TacoButton_Types.Filled.value
    }), TacoButton.component()()({
        text: "Right aligned Filled",
        icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconFetch.value),
        iconAlign: TacoButton_Types.IconRight.value,
        buttonStyle: TacoButton_Types.Filled.value
    }) ]) ],
    code: example5
}, {
    title: "Icon button",
    view: [ Gap.gapX(TacoConstants.M.value)([ TacoButton.component()()({
        icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconFetch.value)
    }), TacoButton.component()()({
        color: TacoConstants.SecondaryRed.value,
        icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconFlagV1.value)
    }), TacoButton.component()()({
        disabled: true,
        icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconEditV1.value)
    }) ]) ],
    code: example6
}, {
    title: "Vertical button",
    view: [ Gap.gapX(TacoConstants.M.value)([ TacoButton.component()()({
        text: "Vertical button",
        icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconCheckCircleV1.value),
        vertical: true
    }) ]) ],
    code: example7
}, {
    title: "Large button",
    view: [ Gap.gapX(TacoConstants.M.value)([ TacoButton.component()()({
        text: "Large variant",
        size: TacoConstants.L.value,
        icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconCheckCircleV1.value),
        buttonStyle: TacoButton_Types.Filled.value
    }) ]) ],
    code: example8
} ];
var demoView = {
    sections: sections,
    title: "TacoButton",
    description: "Basic button"
};
module.exports = {
    demoView: demoView
};
