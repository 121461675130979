// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var InvoiceUtils = require("../InvoiceUtils/index.js");
var Invoices_Styles = require("../Invoices.Styles/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_CostGroup = require("../Types.CostGroup/index.js");
var Types_Invoice = require("../Types.Invoice/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var Utils = require("../Utils/index.js");
var styleSet = TacoTable_Styles.foldingTableStyleSet;
var getColumns = function (props) {
    var unitPriceColumn = (function () {
        if (props.showUnitPriceColumn) {
            return [ {
                key: "pricePerUnit",
                label: React_Basic_DOM.text(""),
                cell: TacoTable_Types.MaybePlainNumberCell.create(2)(function (r) {
                    return Utils.defaultNothingDiv(new Data_Maybe.Just(r.value))(Data_Nullable.toMaybe(r.quantity));
                }),
                sortProperty: new Data_Maybe.Just(InvoiceUtils.ByHours.value),
                width: new TacoTable_Types.Flex(7),
                headerJSX: Data_Maybe.Nothing.value
            }, {
                key: "pricePerUnitUnit",
                label: React_Basic_DOM.text("\u20ac/Yks."),
                cell: TacoTable_Types.PlainTextCell.create(function (r) {
                    return "\u20ac/" + Utils.defaultNull("-")(r.unit);
                }),
                sortProperty: new Data_Maybe.Just(InvoiceUtils.ByHours.value),
                width: new TacoTable_Types.Flex(7),
                headerJSX: Data_Maybe.Nothing.value
            } ];
        };
        return [  ];
    })();
    var unitColumn = (function () {
        if (props.showUnitColumn) {
            return [ {
                key: "unit",
                label: React_Basic_DOM.text("Yks."),
                cell: TacoTable_Types.PlainTextCell.create((function () {
                    var $26 = Utils.defaultNull("-");
                    return function ($27) {
                        return $26((function (v) {
                            return v.unit;
                        })($27));
                    };
                })()),
                sortProperty: new Data_Maybe.Just(InvoiceUtils.ByHours.value),
                width: new TacoTable_Types.Flex(7),
                headerJSX: Data_Maybe.Nothing.value
            } ];
        };
        return [  ];
    })();
    var quantityColumn = (function () {
        if (props.showQuantityColumn) {
            return [ {
                key: "quantity",
                label: React_Basic_DOM.text("M\xe4\xe4r\xe4"),
                cell: TacoTable_Types.MaybePlainNumberCell.create(1)(function ($28) {
                    return Data_Nullable.toMaybe((function (v) {
                        return v.quantity;
                    })($28));
                }),
                sortProperty: new Data_Maybe.Just(InvoiceUtils.ByHours.value),
                width: new TacoTable_Types.Flex(7),
                headerJSX: Data_Maybe.Nothing.value
            } ];
        };
        return [  ];
    })();
    var invoiceSupplerInvoiceNumberColumn = (function () {
        if (props.showSupplierInvoiceNumbers) {
            return [ {
                key: "invoiceSupplerInvoiceNumber",
                label: React_Basic_DOM.text("Supplier no."),
                cell: TacoTable_Types.PlainTextCell.create((function () {
                    var $29 = Utils.defaultNull("");
                    return function ($30) {
                        return $29((function (v) {
                            return v.supplierInvoiceNumber;
                        })($30));
                    };
                })()),
                sortProperty: new Data_Maybe.Just(InvoiceUtils.ByInvoiceBunchId.value),
                width: new TacoTable_Types.Flex(5),
                headerJSX: Data_Maybe.Nothing.value
            } ];
        };
        return [  ];
    })();
    var invoiceBunchIdColumn = (function () {
        if (props.showInvoiceBunchIds) {
            return [ {
                key: "invoiceBunchId",
                label: React_Basic_DOM.text("Tunnus"),
                cell: TacoTable_Types.PlainTextCell.create((function () {
                    var $31 = Data_Newtype.un()(Types_Invoice.InvoiceBunchId);
                    var $32 = Utils.defaultNull("");
                    return function ($33) {
                        return $31($32((function (v) {
                            return v.invoiceBunchId;
                        })($33)));
                    };
                })()),
                sortProperty: new Data_Maybe.Just(InvoiceUtils.ByInvoiceBunchId.value),
                width: new TacoTable_Types.Flex(5),
                headerJSX: Data_Maybe.Nothing.value
            } ];
        };
        return [  ];
    })();
    var hoursColumn = (function () {
        var $17 = Data_Foldable.any(Data_Foldable.foldableArray)(Data_HeytingAlgebra.heytingAlgebraBoolean)(Utils.isSalary)(props.invoice.invoiceLines);
        if ($17) {
            return [ {
                key: "hours",
                label: React_Basic_DOM.text("Tunnit"),
                cell: TacoTable_Types.PlainNumberCell.create(1)((function () {
                    var $34 = Utils.defaultNull(0.0);
                    return function ($35) {
                        return $34((function (v) {
                            return v.hours;
                        })($35));
                    };
                })()),
                sortProperty: new Data_Maybe.Just(InvoiceUtils.ByHours.value),
                width: new TacoTable_Types.Flex(7),
                headerJSX: Data_Maybe.Nothing.value
            } ];
        };
        return [  ];
    })();
    var costClassElement = function (invoiceLine) {
        return Utils.defaultNull(Data_Newtype.un()(Types_Newtypes.CostClassCode)(invoiceLine.costClassCode))(invoiceLine.costClass);
    };
    var checkBoxElement = function (invoiceLine) {
        var isChecked = Data_Foldable.elem(Data_Foldable.foldableArray)(Types_Invoice.eqInvoiceRowId)(invoiceLine.id)(props.selectedInvoiceLineIds);
        var toggleF = (function () {
            if (isChecked) {
                return props.onInvoiceLinesDeselect;
            };
            return props.onInvoiceLinesSelect;
        })();
        return TacoCheckbox.component()()({
            className: "",
            isChecked: isChecked,
            onToggle: TacoCheckbox.NoEvent.create(toggleF(Control_Applicative.pure(Control_Applicative.applicativeArray)(invoiceLine.id))),
            labelText: Data_Maybe.Nothing.value,
            testId: "toggle-invoice-line-" + Data_Show.show(Types_Invoice.showInvoiceRowId)(invoiceLine.id)
        });
    };
    var optionalCheckbox = (function () {
        if (props.showCheckboxes) {
            return [ {
                key: "checkbox",
                label: React_Basic_DOM.text(""),
                cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], checkBoxElement),
                sortProperty: new Data_Maybe.Just(InvoiceUtils.ByCostGroupCode.value),
                width: new TacoTable_Types.Flex(2),
                headerJSX: Data_Maybe.Nothing.value
            } ];
        };
        return [  ];
    })();
    return Data_Semigroup.append(Data_Semigroup.semigroupArray)(optionalCheckbox)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(invoiceBunchIdColumn)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(invoiceSupplerInvoiceNumberColumn)(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ {
        key: "costGroupCode",
        label: React_Basic_DOM.text("Littera"),
        cell: TacoTable_Types.PlainTextCell.create((function () {
            var $36 = Data_Newtype.un()(Types_CostGroup.CostGroupCode);
            var $37 = Utils.defaultNull("");
            return function ($38) {
                return $36($37((function (v) {
                    return v.costGroupCode;
                })($38)));
            };
        })()),
        sortProperty: new Data_Maybe.Just(InvoiceUtils.ByCostGroupCode.value),
        width: new TacoTable_Types.Flex(6),
        headerJSX: Data_Maybe.Nothing.value
    }, {
        key: "costClass",
        label: React_Basic_DOM.text("KL"),
        cell: new TacoTable_Types.PlainTextCell(costClassElement),
        sortProperty: new Data_Maybe.Just(InvoiceUtils.ByCostClassCode.value),
        width: new TacoTable_Types.Flex(6),
        headerJSX: Data_Maybe.Nothing.value
    }, {
        key: "lineDescription",
        label: React_Basic_DOM.text("Riviselite"),
        cell: TacoTable_Types.PlainTextCell.create((function () {
            var $39 = Utils.defaultNull("");
            return function ($40) {
                return $39((function (v) {
                    return v.lineDescription;
                })($40));
            };
        })()),
        sortProperty: new Data_Maybe.Just(InvoiceUtils.ByLineDescription.value),
        width: new TacoTable_Types.Flex(18),
        headerJSX: Data_Maybe.Nothing.value
    } ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(hoursColumn)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(quantityColumn)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(unitColumn)(Data_Semigroup.append(Data_Semigroup.semigroupArray)(unitPriceColumn)([ {
        key: "value",
        label: React_Basic_DOM.text("Summa"),
        cell: new TacoTable_Types.PlainEuroCell(function (v) {
            return v.value;
        }),
        sortProperty: new Data_Maybe.Just(InvoiceUtils.ByValue.value),
        width: new TacoTable_Types.Flex(8),
        headerJSX: Data_Maybe.Nothing.value
    } ]))))))));
};
var invoiceDetails = TofuHooks.mkHookComponent("InvoiceDetails")(function (props) {
    var supplierBusinessId = function (v) {
        if (v === "") {
            return React_Basic.empty;
        };
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "supplier-business-id",
            css: Invoices_Styles.invoiceDetailsRowStyles,
            children: [ TacoText.component()()({
                text: "Y-tunnus",
                weight: TacoText_Types.Bold.value
            }), React_Basic_DOM_Generated.span()({
                className: "value",
                children: Control_Applicative.pure(Control_Applicative.applicativeArray)(props.supplierBusinessIdComponent)
            }) ]
        });
    };
    var descriptionValue = (function () {
        var v = Data_Nullable.toMaybe(props.invoice.description);
        if (v instanceof Data_Maybe.Just) {
            return React_Basic_DOM_Generated.span()({
                className: "value",
                children: Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM.text(v.value0))
            });
        };
        if (v instanceof Data_Maybe.Nothing) {
            return React_Basic_DOM_Generated.span()({
                className: "value empty",
                children: Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM.text("ei selitett\xe4"))
            });
        };
        throw new Error("Failed pattern match at InvoiceDetails (line 57, column 24 - line 59, column 93): " + [ v.constructor.name ]);
    })();
    var description = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        className: "description",
        css: Invoices_Styles.invoiceDetailsRowStyles,
        children: [ TacoText.component()()({
            text: "Selite",
            weight: TacoText_Types.Bold.value
        }), descriptionValue ]
    });
    var columns = getColumns(props);
    var invoiceLinesTable = function (v) {
        var getInvoiceLineRow = function (invoiceLine) {
            var isChecked = Data_Foldable.elem(Data_Foldable.foldableArray)(Types_Invoice.eqInvoiceRowId)(invoiceLine.id)(props.selectedInvoiceLineIds);
            return {
                rowData: invoiceLine,
                rowKey: Data_Show.show(Types_Invoice.showInvoiceRowId)(invoiceLine.id),
                onClick: function (v1) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                },
                className: (function () {
                    if (isChecked) {
                        return "checked";
                    };
                    return "";
                })(),
                foldingElement: Data_Maybe.Nothing.value,
                columns: columns
            };
        };
        return TacoTable.tableSortable(InvoiceUtils.getNameInvoiceLineSortProperty)(InvoiceUtils.eqInvoiceLineSortProperty)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "value";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "unit";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "type";
            }
        })(Types_Invoice.eqInvoiceType))()({
            reflectSymbol: function () {
                return "supplierInvoiceNumber";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "supplierBusinessId";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "supplier";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "socialExpensesPercentage";
            }
        })(Types_Unit.eqPercentageOfHundred))()({
            reflectSymbol: function () {
                return "quantity";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
            reflectSymbol: function () {
                return "lineDescription";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "invoiceNumber";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "invoiceImg";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "invoiceId";
            }
        })(Types_Invoice.eqInvoiceId))()({
            reflectSymbol: function () {
                return "invoiceBunchId";
            }
        })(Data_Nullable.eqNullable(Types_Invoice.eqInvoiceBunchId)))()({
            reflectSymbol: function () {
                return "id";
            }
        })(Types_Invoice.eqInvoiceRowId))()({
            reflectSymbol: function () {
                return "hours";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
            reflectSymbol: function () {
                return "description";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "date";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "costGroupCode";
            }
        })(Data_Nullable.eqNullable(Types_CostGroup.eqCostGroupCode)))()({
            reflectSymbol: function () {
                return "costClassCode";
            }
        })(Types_Newtypes.eqCostClassCode))()({
            reflectSymbol: function () {
                return "costClass";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString))))(InvoiceUtils.invoiceLinesTableInstance)(new TacoTable_Types.SortingCriteria(TacoTable_Types.Ascending.value, new Data_Maybe.Just(InvoiceUtils.ByCostGroupCode.value), InvoiceUtils.getInvoiceLineSortingValue))(Data_Maybe.Nothing.value)({
            rows: Data_Functor.map(Data_Functor.functorArray)(getInvoiceLineRow)(v.invoiceLines),
            columns: columns,
            showHeader: true,
            styleSet: styleSet
        });
    };
    var linesTable = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        className: "lines-table",
        css: Invoices_Styles.invoiceDetailsRowStyles,
        children: [ TacoText.component()()({
            text: "Rivitiedot",
            weight: TacoText_Types.Bold.value
        }), invoiceLinesTable({
            invoiceLines: props.invoice.invoiceLines,
            selectedInvoiceLineIds: props.selectedInvoiceLineIds,
            onInvoiceLinesSelect: props.onInvoiceLinesSelect,
            onInvoiceLinesDeselect: props.onInvoiceLinesDeselect
        }) ]
    });
    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        className: "invoice-details",
        css: Invoices_Styles.invoiceDetailsStyles,
        children: [ supplierBusinessId(props.invoice.supplier), description, linesTable ]
    }));
});
module.exports = {
    invoiceDetails: invoiceDetails
};
