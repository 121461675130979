import { Checkbox, Input } from "@tocoman/ui";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import { Measurement } from "../../../../../ts-bindings/Measurement";
import { MultivalueStringCombobox } from "../../../components/MultivalueStringCombobox";
import { TranslatedMultivalueCombobox } from "../../../components/TranslatedMultivalueCombobox";
import React from "react";

export type ParamsForARJS = {
  Name: string;
  Value:
    | string
    | string[]
    | boolean
    | number
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | { key: string | number; value: any; label: string }[]
    | null
    | undefined;
};

export type AllReportParametersData = {
  report?: string;
  reportHeader?: string;
  reportSubHeader?: string;
  subProjects?: string[];
  locations?: string[];
  codeRange?: string;
  rowType?: { key: string; value: string; label: string }[];
  social?: boolean;
  showMemo?: boolean;
  showOnlyRowsWithValue?: boolean;
  showOnlyRowsWithMemo?: boolean;
  elementClass?: string;
  elementCode?: string;
  measurementDecimals?: number;
  measurements?: {
    label: string;
    key: number | string;
    value: number | string;
  }[];
};

export type ComponentPropsMap = {
  reportHeader: { reportHeader?: string };
  reportSubHeader: { projectName?: string };
  subProjects: { subProjects?: string[] };
  locations: { locations?: string[] };
  codeRange: { codeRange?: string };
  rowType: { rowType?: string };
  social: { social?: boolean };
  showMemo: { showMemo?: boolean };
  showOnlyRowsWithValue: { showOnlyRowsWithValue?: boolean };
  showOnlyRowsWithMemo: { showOnlyRowsWithMemo?: boolean };
  elementClass: { elementClass?: string };
  elementCode: { elementCode?: string };
  measurementDecimals: { measurementDecimals?: number };
  measurements: { measurements?: Measurement[] };
};

export const ReportHeaderInput = ({
  reportHeader,
}: {
  reportHeader?: string;
}) => {
  const { t } = useTranslation("reports", {
    keyPrefix: "activeReportsParameters",
  });
  const { register, setValue } = useFormContext<AllReportParametersData>();

  useEffect(() => {
    if (reportHeader) {
      setValue("reportHeader", reportHeader);
    }
  }, [reportHeader]);

  const mkInputProps = (
    field: keyof AllReportParametersData,
    registerOptions?: RegisterOptions<AllReportParametersData, typeof field>
  ) => ({
    label: t(`${field}`),
    ...register(field, registerOptions),
  });
  return <Input {...mkInputProps("reportHeader")} className={"w-full"} />;
};

export const ReportSubHeaderInput = ({
  projectName,
}: {
  projectName?: string;
}) => {
  const { t } = useTranslation("reports", {
    keyPrefix: "activeReportsParameters",
  });
  const { register, setValue } = useFormContext<AllReportParametersData>();

  useEffect(() => {
    if (projectName) {
      setValue("reportSubHeader", projectName);
    }
  }, [projectName]);

  const mkInputProps = (
    field: keyof AllReportParametersData,
    registerOptions?: RegisterOptions<AllReportParametersData, typeof field>
  ) => ({
    label: t(`${field}`),
    ...register(field, registerOptions),
  });
  return (
    <Input
      {...mkInputProps("reportSubHeader")}
      defaultValue={projectName}
      className={"w-full"}
    />
  );
};

export const SubProjectsCombobox = ({
  subProjects,
}: {
  subProjects?: string[];
}) => {
  const { t } = useTranslation("reports", {
    keyPrefix: "activeReportsParameters",
  });
  if (!subProjects) {
    return null;
  }
  return (
    <Controller
      name="subProjects"
      defaultValue={subProjects}
      render={({ field: { onChange, value = [] } }) => (
        <MultivalueStringCombobox
          label={t("subProjects")}
          initialValue={value}
          items={subProjects}
          onValueChange={onChange}
        />
      )}
    />
  );
};

export const LocationsCombobox = ({ locations }: { locations?: string[] }) => {
  const { t } = useTranslation("reports", {
    keyPrefix: "activeReportsParameters",
  });
  if (!locations) {
    return null;
  }
  return (
    <Controller
      name="locations"
      defaultValue={locations}
      render={({ field: { onChange, value } }) => (
        <MultivalueStringCombobox
          label={t("locations")}
          initialValue={value}
          items={locations}
          onValueChange={onChange}
        />
      )}
    />
  );
};

export const CodeInput = () => {
  const { t } = useTranslation("reports", {
    keyPrefix: "activeReportsParameters",
  });
  const { register } = useFormContext<AllReportParametersData>();

  const mkInputProps = (
    field: keyof AllReportParametersData,
    registerOptions?: RegisterOptions<AllReportParametersData, typeof field>
  ) => ({
    label: t(`${field}`),
    ...register(field, registerOptions),
  });

  return <Input {...mkInputProps("codeRange")} className={"w-full"} />;
};

export const RowTypeCombobox = () => {
  const { t } = useTranslation("reports", {
    keyPrefix: "activeReportsParameters",
  });

  const rowTypes = [
    { label: t`checked`, value: "checked", key: "checked" },
    { label: t`flagged`, value: "flagged", key: "flagged" },
    { label: t`other`, value: "other", key: "other" },
  ];

  return (
    <Controller
      name="rowType"
      defaultValue={rowTypes}
      render={({ field: { onChange, value = rowTypes } }) => (
        <TranslatedMultivalueCombobox
          label={t`rowType`}
          selectedItems={value}
          items={rowTypes}
          onValueChange={onChange}
        />
      )}
    />
  );
};

export const SocialCheckbox = () => {
  const { t } = useTranslation("reports", {
    keyPrefix: "activeReportsParameters",
  });

  return (
    <Controller
      name="social"
      defaultValue={false}
      render={({ field: { onChange, value } }) => (
        <Checkbox label={t`social`} checked={value} onChange={onChange} />
      )}
    />
  );
};

export const ShowMemoCheckbox = () => {
  const { t } = useTranslation("reports", {
    keyPrefix: "activeReportsParameters",
  });

  return (
    <Controller
      name="showMemo"
      defaultValue={false}
      render={({ field: { onChange, value } }) => (
        <Checkbox label={t`showMemo`} checked={value} onChange={onChange} />
      )}
    />
  );
};

export const ShowOnlyRowsWithValueCheckbox = () => {
  const { t } = useTranslation("reports", {
    keyPrefix: "activeReportsParameters",
  });

  return (
    <Controller
      name="showOnlyRowsWithValue"
      defaultValue={false}
      render={({ field: { onChange, value } }) => (
        <Checkbox
          label={t`showOnlyRowsWithValue`}
          checked={value}
          onChange={onChange}
        />
      )}
    />
  );
};

export const ShowOnlyRowsWithMemoCheckbox = () => {
  const { t } = useTranslation("reports", {
    keyPrefix: "activeReportsParameters",
  });

  return (
    <Controller
      name="showOnlyRowsWithMemo"
      defaultValue={false}
      render={({ field: { onChange, value } }) => (
        <Checkbox
          label={t`showOnlyRowsWithMemo`}
          checked={value}
          onChange={onChange}
        />
      )}
    />
  );
};

export const ElementClassInput = () => {
  const { t } = useTranslation("reports", {
    keyPrefix: "activeReportsParameters",
  });
  const { register } = useFormContext<AllReportParametersData>();

  const mkInputProps = (
    field: keyof AllReportParametersData,
    registerOptions?: RegisterOptions<AllReportParametersData, typeof field>
  ) => ({
    label: t(`${field}`),
    ...register(field, registerOptions),
  });

  return <Input {...mkInputProps("elementClass")} className={"w-full"} />;
};

export const ElementCodeInput = () => {
  const { t } = useTranslation("reports", {
    keyPrefix: "activeReportsParameters",
  });
  const { register } = useFormContext<AllReportParametersData>();

  const mkInputProps = (
    field: keyof AllReportParametersData,
    registerOptions?: RegisterOptions<AllReportParametersData, typeof field>
  ) => ({
    label: t(`${field}`),
    ...register(field, registerOptions),
  });

  return <Input {...mkInputProps("elementCode")} className={"w-full"} />;
};

export const MeasurementDecimalsInput = () => {
  const { t } = useTranslation("reports", {
    keyPrefix: "activeReportsParameters",
  });
  const { register } = useFormContext<AllReportParametersData>();

  const mkInputProps = (
    field: keyof AllReportParametersData,
    registerOptions?: RegisterOptions<AllReportParametersData, typeof field>
  ) => ({
    label: t(`${field}`),
    ...register(field, registerOptions),
  });

  return (
    <Input
      {...mkInputProps("measurementDecimals")}
      defaultValue={2}
      type={"number"}
      className={"w-full"}
    />
  );
};

export const MeasurementsCombobox = ({
  measurements,
}: {
  measurements?: Measurement[] | undefined;
}) => {
  const { t } = useTranslation("reports", {
    keyPrefix: "activeReportsParameters",
  });

  if (!measurements) {
    return null;
  }

  const measurementItems = measurements.map((measurement) => ({
    label: measurement.unit.name,
    value: measurement.unit.id ?? measurement.unit.name,
    key: measurement.unit.id ?? measurement.unit.name,
  }));

  return (
    <div className="flex flex-col">
      <Controller
        name="measurements"
        defaultValue={[]}
        render={({ field: { onChange, value } }) => (
          <TranslatedMultivalueCombobox
            label={t("measurements")}
            selectedItems={value}
            items={measurementItems}
            onValueChange={onChange}
            limit={3}
          />
        )}
      />
    </div>
  );
};

export const componentMap: {
  [K in keyof ComponentPropsMap]: React.ComponentType<ComponentPropsMap[K]>;
} = {
  reportHeader: ReportHeaderInput,
  reportSubHeader: ReportSubHeaderInput,
  subProjects: SubProjectsCombobox,
  locations: LocationsCombobox,
  codeRange: CodeInput,
  rowType: RowTypeCombobox,
  social: SocialCheckbox,
  showMemo: ShowMemoCheckbox,
  showOnlyRowsWithValue: ShowOnlyRowsWithValueCheckbox,
  showOnlyRowsWithMemo: ShowOnlyRowsWithMemoCheckbox,
  elementClass: ElementClassInput,
  elementCode: ElementCodeInput,
  measurementDecimals: MeasurementDecimalsInput,
  measurements: MeasurementsCombobox,
};
