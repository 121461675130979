import { Button, Header, Modal, Switch } from "@tocoman/ui";
import { Trans, useTranslation } from "react-i18next";
import { User } from "../../../../../ts-bindings/User";
import { useWorkerAction } from "../../../hooks/useWorkerAction";
import { mkSetSsoAction } from "../../../actions/admin";
import { useFormContext } from "react-hook-form";
import { useState } from "react";

type UserSSOProps = {
  user: User | null;
  orgSSO: boolean;
};

export const UserSSO = ({ user, orgSSO }: UserSSOProps) => {
  const { t } = useTranslation("admin", { keyPrefix: "users.edit" });
  const { setValue } = useFormContext();
  const [hasSSO, setHasSSO] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [setSsoStatus, setSso] = useWorkerAction(
    "AdminUpdateActionState",
    () => {
      if (user !== null) {
        return mkSetSsoAction({
          userId: user.id,
          userEmail: user.userData.email,
        });
      }
    }
  );

  const handleSetSSO = () => {
    if (user !== null) {
      setSso();
    }
    setHasSSO(true);
    setValue("hasSSO", true);
    setValue("sendInvitation", false);
    setConfirmationModalOpen(false);
  };

  const modalActions = (
    <div className={"flex justify-between"}>
      <Button
        variant={"text"}
        size={"small"}
        onClick={() => setConfirmationModalOpen(false)}
        label={t`sso.cancel`}
      />
      <Button
        variant="primary"
        size="small"
        loading={setSsoStatus === "Pending"}
        onClick={handleSetSSO}
        label={t("sso.confirm")}
      />
    </div>
  );

  const handleSetChecked = (checked: boolean) => {
    if (checked) {
      setValue("sendInvitation", false);
      setConfirmationModalOpen(true);
    } else {
      setHasSSO(false);
      setValue("hasSSO", false);
    }
  };

  return (
    <>
      <Header title={t`SSO`} titleSize="small" />
      <p>{t`SSODescription`}</p>
      {!orgSSO && <p>{t`orgHasNoSSO`}</p>}
      <div>
        <Switch
          checked={user?.userData.hasSSOLogin ?? hasSSO}
          setChecked={handleSetChecked}
          disabled={user?.userData.hasSSOLogin || !orgSSO}
        />

        {confirmationModalOpen && (
          <Modal
            isOpen={confirmationModalOpen}
            closeModal={() => setConfirmationModalOpen(false)}
            actions={modalActions}
            title={t`sso.title`}
          >
            <div className="flex flex-col gap-4">
              <div>
                <Trans ns="admin" i18nKey="users.edit.sso.description" />
              </div>
              <div>
                <Trans
                  ns="admin"
                  i18nKey="users.edit.sso.disclaimer"
                  values={{ user: user?.userData.email }}
                />
              </div>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};
