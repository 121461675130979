// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Record = require("../Record/index.js");
var ReducerUtils = require("../ReducerUtils/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_CostGroup = require("../Types.CostGroup/index.js");
var Types_Estimation = require("../Types.Estimation/index.js");
var Types_Income = require("../Types.Income/index.js");
var Types_Project = require("../Types.Project/index.js");
var Saving = (function () {
    function Saving() {

    };
    Saving.value = new Saving();
    return Saving;
})();
var Saved = (function () {
    function Saved() {

    };
    Saved.value = new Saved();
    return Saved;
})();
var SaveFailed = (function () {
    function SaveFailed() {

    };
    SaveFailed.value = new SaveFailed();
    return SaveFailed;
})();
var Add = (function () {
    function Add() {

    };
    Add.value = new Add();
    return Add;
})();
var Remove = (function () {
    function Remove() {

    };
    Remove.value = new Remove();
    return Remove;
})();
var ChangeLoadingIncomeItemApprovalDates = (function () {
    function ChangeLoadingIncomeItemApprovalDates(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    ChangeLoadingIncomeItemApprovalDates.create = function (value0) {
        return function (value1) {
            return new ChangeLoadingIncomeItemApprovalDates(value0, value1);
        };
    };
    return ChangeLoadingIncomeItemApprovalDates;
})();
var ChangeLoadingIncomeItemPlannedInvoiceDates = (function () {
    function ChangeLoadingIncomeItemPlannedInvoiceDates(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    ChangeLoadingIncomeItemPlannedInvoiceDates.create = function (value0) {
        return function (value1) {
            return new ChangeLoadingIncomeItemPlannedInvoiceDates(value0, value1);
        };
    };
    return ChangeLoadingIncomeItemPlannedInvoiceDates;
})();
var ChangeLoadingIncomeItemApprovalStatuses = (function () {
    function ChangeLoadingIncomeItemApprovalStatuses(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    ChangeLoadingIncomeItemApprovalStatuses.create = function (value0) {
        return function (value1) {
            return new ChangeLoadingIncomeItemApprovalStatuses(value0, value1);
        };
    };
    return ChangeLoadingIncomeItemApprovalStatuses;
})();
var ChangeLoadingIncomeItemTargetInclusionStatuses = (function () {
    function ChangeLoadingIncomeItemTargetInclusionStatuses(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    ChangeLoadingIncomeItemTargetInclusionStatuses.create = function (value0) {
        return function (value1) {
            return new ChangeLoadingIncomeItemTargetInclusionStatuses(value0, value1);
        };
    };
    return ChangeLoadingIncomeItemTargetInclusionStatuses;
})();
var ChangeLoadingRemoveIncomeItems = (function () {
    function ChangeLoadingRemoveIncomeItems(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    ChangeLoadingRemoveIncomeItems.create = function (value0) {
        return function (value1) {
            return new ChangeLoadingRemoveIncomeItems(value0, value1);
        };
    };
    return ChangeLoadingRemoveIncomeItems;
})();
var UpdateLoadingAddNewRowComponentStatus = (function () {
    function UpdateLoadingAddNewRowComponentStatus(value0) {
        this.value0 = value0;
    };
    UpdateLoadingAddNewRowComponentStatus.create = function (value0) {
        return new UpdateLoadingAddNewRowComponentStatus(value0);
    };
    return UpdateLoadingAddNewRowComponentStatus;
})();
var ChangeLoadingAddCostGroupItems = (function () {
    function ChangeLoadingAddCostGroupItems(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    ChangeLoadingAddCostGroupItems.create = function (value0) {
        return function (value1) {
            return new ChangeLoadingAddCostGroupItems(value0, value1);
        };
    };
    return ChangeLoadingAddCostGroupItems;
})();
var ChangeCostGroupSaveStatus = (function () {
    function ChangeCostGroupSaveStatus(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    ChangeCostGroupSaveStatus.create = function (value0) {
        return function (value1) {
            return new ChangeCostGroupSaveStatus(value0, value1);
        };
    };
    return ChangeCostGroupSaveStatus;
})();
var ChangeLoadingUpdateTargetItems = (function () {
    function ChangeLoadingUpdateTargetItems(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    ChangeLoadingUpdateTargetItems.create = function (value0) {
        return function (value1) {
            return new ChangeLoadingUpdateTargetItems(value0, value1);
        };
    };
    return ChangeLoadingUpdateTargetItems;
})();
var ChangeEstimationComponentLoadingStatus = (function () {
    function ChangeEstimationComponentLoadingStatus(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    ChangeEstimationComponentLoadingStatus.create = function (value0) {
        return function (value1) {
            return new ChangeEstimationComponentLoadingStatus(value0, value1);
        };
    };
    return ChangeEstimationComponentLoadingStatus;
})();
var ChangeEstimationComponentCopyOperationLoadingStatus = (function () {
    function ChangeEstimationComponentCopyOperationLoadingStatus(value0, value1) {
        this.value0 = value0;
        this.value1 = value1;
    };
    ChangeEstimationComponentCopyOperationLoadingStatus.create = function (value0) {
        return function (value1) {
            return new ChangeEstimationComponentCopyOperationLoadingStatus(value0, value1);
        };
    };
    return ChangeEstimationComponentCopyOperationLoadingStatus;
})();
var UpdateLoadingEstimationLocationAction = (function () {
    function UpdateLoadingEstimationLocationAction(value0) {
        this.value0 = value0;
    };
    UpdateLoadingEstimationLocationAction.create = function (value0) {
        return new UpdateLoadingEstimationLocationAction(value0);
    };
    return UpdateLoadingEstimationLocationAction;
})();
var toAction = function (appAction) {
    if (appAction instanceof Actions.SetIncomeItemApprovalDatesRequest) {
        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(new ChangeLoadingIncomeItemApprovalDates(Add.value, Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.incomeItemId;
        })(appAction.value0.dates)));
    };
    if (appAction instanceof Actions.SetIncomeItemApprovalDatesSuccess) {
        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(new ChangeLoadingIncomeItemApprovalDates(Remove.value, Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.incomeItemId;
        })(appAction.value0.dates)));
    };
    if (appAction instanceof Actions.SetIncomeItemPlannedInvoiceDatesRequest) {
        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(new ChangeLoadingIncomeItemPlannedInvoiceDates(Add.value, Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.incomeItemId;
        })(appAction.value0.dates)));
    };
    if (appAction instanceof Actions.SetIncomeItemPlannedInvoiceDatesSuccess) {
        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(new ChangeLoadingIncomeItemPlannedInvoiceDates(Remove.value, Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.incomeItemId;
        })(appAction.value0)));
    };
    if (appAction instanceof Actions.SetIncomeItemApprovalStatusesRequest) {
        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(new ChangeLoadingIncomeItemApprovalStatuses(Add.value, Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.incomeItemId;
        })(appAction.value0.statuses)));
    };
    if (appAction instanceof Actions.SetIncomeItemApprovalStatusesSuccess) {
        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(new ChangeLoadingIncomeItemApprovalStatuses(Remove.value, Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.incomeItemId;
        })(appAction.value0.statuses)));
    };
    if (appAction instanceof Actions.SetIncomeItemsInclusionInTargetRequest) {
        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(new ChangeLoadingIncomeItemTargetInclusionStatuses(Add.value, Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.incomeItemId;
        })(appAction.value0.inclusionUpdates)));
    };
    if (appAction instanceof Actions.SetIncomeItemsInclusionInTargetSuccess) {
        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(new ChangeLoadingIncomeItemTargetInclusionStatuses(Remove.value, Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.incomeItemId;
        })(appAction.value0)));
    };
    if (appAction instanceof Actions.RemoveIncomeItemsRequest) {
        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(new ChangeLoadingRemoveIncomeItems(Add.value, Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.incomeItemId;
        })(appAction.value0.params)));
    };
    if (appAction instanceof Actions.RemoveIncomeItemsSuccess) {
        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(new ChangeLoadingRemoveIncomeItems(Remove.value, Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.incomeItemId;
        })(appAction.value0.params)));
    };
    if (appAction instanceof Actions.AddCostGroupRequest) {
        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(new UpdateLoadingAddNewRowComponentStatus(true));
    };
    if (appAction instanceof Actions.AddCostGroupSuccess) {
        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(new UpdateLoadingAddNewRowComponentStatus(false));
    };
    if (appAction instanceof Actions.UpdateCostGroupMemoRequest) {
        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(new ChangeCostGroupSaveStatus(Saving.value, appAction.value0.costGroupId));
    };
    if (appAction instanceof Actions.UpdateCostGroupMemoSuccess) {
        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(new ChangeCostGroupSaveStatus(Saved.value, appAction.value0.costGroupId));
    };
    if (appAction instanceof Actions.UpdateCostGroupMemoError) {
        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(new ChangeCostGroupSaveStatus(SaveFailed.value, appAction.value0.costGroupId));
    };
    if (appAction instanceof Actions.CostGroupItemActionRequest && appAction.value0.params instanceof Types_CostGroup.AddCostGroupEstimateItemsAction) {
        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(new ChangeLoadingAddCostGroupItems(Add.value, Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.costGroupCode;
        })(appAction.value0.params.value0)));
    };
    if (appAction instanceof Actions.CostGroupItemActionRequest && appAction.value0.params instanceof Types_CostGroup.AddCostGroupTargetItemsAction) {
        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(new ChangeLoadingAddCostGroupItems(Add.value, Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.costGroupCode;
        })(appAction.value0.params.value0)));
    };
    if (appAction instanceof Actions.CostGroupItemActionSuccess && appAction.value0.params instanceof Types_CostGroup.AddCostGroupEstimateItemsAction) {
        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(new ChangeLoadingAddCostGroupItems(Remove.value, Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.costGroupCode;
        })(appAction.value0.params.value0)));
    };
    if (appAction instanceof Actions.CostGroupItemActionRequest && appAction.value0.params instanceof Types_CostGroup.UpdateTargetItemAction) {
        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(new ChangeLoadingUpdateTargetItems(Add.value, [ appAction.value0.projectId ]));
    };
    if (appAction instanceof Actions.CostGroupItemActionSuccess && appAction.value0.params instanceof Types_CostGroup.UpdateTargetItemAction) {
        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(new ChangeLoadingUpdateTargetItems(Remove.value, [ appAction.value0.projectId ]));
    };
    if (appAction instanceof Actions.UpdateEstimationComponentMemoRequest) {
        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(new ChangeEstimationComponentLoadingStatus(Saving.value, appAction.value0.params.componentId));
    };
    if (appAction instanceof Actions.UpdateEstimationComponentMemoSuccess) {
        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(new ChangeEstimationComponentLoadingStatus(Saved.value, appAction.value0.params.componentId));
    };
    if (appAction instanceof Actions.UpdateEstimationComponentMemoError) {
        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(new ChangeEstimationComponentLoadingStatus(SaveFailed.value, appAction.value0.params.componentId));
    };
    if (appAction instanceof Actions.EstimationComponentActionRequest) {
        if (appAction.value0.params instanceof Types_Estimation.CopyEstimationComponentsToProject) {
            return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(new ChangeEstimationComponentCopyOperationLoadingStatus(Add.value, [ appAction.value0.projectId ]));
        };
        return Data_Maybe.Nothing.value;
    };
    if (appAction instanceof Actions.EstimationComponentActionSuccess) {
        if (appAction.value0.params instanceof Types_Estimation.CopyEstimationComponentsToProject) {
            return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(new ChangeEstimationComponentCopyOperationLoadingStatus(Remove.value, [ appAction.value0.projectId ]));
        };
        return Data_Maybe.Nothing.value;
    };
    if (appAction instanceof Actions.EstimationLocationActionRequest) {
        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(new UpdateLoadingEstimationLocationAction(true));
    };
    if (appAction instanceof Actions.EstimationLocationActionSuccess) {
        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(new UpdateLoadingEstimationLocationAction(false));
    };
    return Data_Maybe.Nothing.value;
};
var initialState = {
    loadingIncomeItemApprovalDates: [  ],
    loadingIncomeItemPlannedInvoiceDates: [  ],
    loadingIncomeItemApprovalStatuses: [  ],
    loadingIncomeItemTargetInclusionStatuses: [  ],
    loadingRemoveIncomeItems: [  ],
    loadingAddNewCostGroup: false,
    loadingAddCostGroupItems: [  ],
    costGroupSaveStatus: Data_Map_Internal.empty,
    loadingUpdateTargetItems: [  ],
    loadingEstimateComponents: Data_Map_Internal.empty,
    loadingEstimationComponentCopyOperation: [  ],
    loadingEstimationLocationAction: false
};
var handleModify = function (dictOrd) {
    return function (dictIsSymbol) {
        return function (dictCons) {
            return function (propP) {
                return function (modification) {
                    return function (items) {
                        return function (r) {
                            var isElem = function (xs) {
                                return function (x) {
                                    return Data_Maybe.maybe(true)(Data_Function["const"](false))(Data_Array.find(Data_Eq.eq(dictOrd.Eq0())(x))(xs));
                                };
                            };
                            var fn = (function () {
                                if (modification instanceof Add) {
                                    return Data_Array.union(dictOrd.Eq0())(items);
                                };
                                if (modification instanceof Remove) {
                                    return Data_Array.filter(isElem(items));
                                };
                                throw new Error("Failed pattern match at UpdateStatusesReducer (line 174, column 10 - line 176, column 44): " + [ modification.constructor.name ]);
                            })();
                            return Record.modify(dictIsSymbol)()()(propP)(fn)(r);
                        };
                    };
                };
            };
        };
    };
};
var update = function (s) {
    return function (v) {
        if (v instanceof ChangeLoadingIncomeItemApprovalDates) {
            return handleModify(Types_Income.ordIncomeItemId)({
                reflectSymbol: function () {
                    return "loadingIncomeItemApprovalDates";
                }
            })()(Data_Symbol.SProxy.value)(v.value0)(v.value1)(s);
        };
        if (v instanceof ChangeLoadingIncomeItemPlannedInvoiceDates) {
            return handleModify(Types_Income.ordIncomeItemId)({
                reflectSymbol: function () {
                    return "loadingIncomeItemPlannedInvoiceDates";
                }
            })()(Data_Symbol.SProxy.value)(v.value0)(v.value1)(s);
        };
        if (v instanceof ChangeLoadingIncomeItemApprovalStatuses) {
            return handleModify(Types_Income.ordIncomeItemId)({
                reflectSymbol: function () {
                    return "loadingIncomeItemApprovalStatuses";
                }
            })()(Data_Symbol.SProxy.value)(v.value0)(v.value1)(s);
        };
        if (v instanceof ChangeLoadingIncomeItemTargetInclusionStatuses) {
            return handleModify(Types_Income.ordIncomeItemId)({
                reflectSymbol: function () {
                    return "loadingIncomeItemTargetInclusionStatuses";
                }
            })()(Data_Symbol.SProxy.value)(v.value0)(v.value1)(s);
        };
        if (v instanceof ChangeLoadingRemoveIncomeItems) {
            return handleModify(Types_Income.ordIncomeItemId)({
                reflectSymbol: function () {
                    return "loadingRemoveIncomeItems";
                }
            })()(Data_Symbol.SProxy.value)(v.value0)(v.value1)(s);
        };
        if (v instanceof UpdateLoadingAddNewRowComponentStatus) {
            return {
                loadingIncomeItemApprovalDates: s.loadingIncomeItemApprovalDates,
                loadingIncomeItemPlannedInvoiceDates: s.loadingIncomeItemPlannedInvoiceDates,
                loadingIncomeItemApprovalStatuses: s.loadingIncomeItemApprovalStatuses,
                loadingIncomeItemTargetInclusionStatuses: s.loadingIncomeItemTargetInclusionStatuses,
                loadingRemoveIncomeItems: s.loadingRemoveIncomeItems,
                loadingAddCostGroupItems: s.loadingAddCostGroupItems,
                loadingAddNewCostGroup: v.value0,
                costGroupSaveStatus: s.costGroupSaveStatus,
                loadingUpdateTargetItems: s.loadingUpdateTargetItems,
                loadingEstimateComponents: s.loadingEstimateComponents,
                loadingEstimationComponentCopyOperation: s.loadingEstimationComponentCopyOperation,
                loadingEstimationLocationAction: s.loadingEstimationLocationAction
            };
        };
        if (v instanceof ChangeLoadingAddCostGroupItems) {
            return handleModify(Types_CostGroup.ordCostGroupCode)({
                reflectSymbol: function () {
                    return "loadingAddCostGroupItems";
                }
            })()(Data_Symbol.SProxy.value)(v.value0)(v.value1)(s);
        };
        if (v instanceof ChangeCostGroupSaveStatus) {
            return {
                loadingIncomeItemApprovalDates: s.loadingIncomeItemApprovalDates,
                loadingIncomeItemPlannedInvoiceDates: s.loadingIncomeItemPlannedInvoiceDates,
                loadingIncomeItemApprovalStatuses: s.loadingIncomeItemApprovalStatuses,
                loadingIncomeItemTargetInclusionStatuses: s.loadingIncomeItemTargetInclusionStatuses,
                loadingRemoveIncomeItems: s.loadingRemoveIncomeItems,
                loadingAddCostGroupItems: s.loadingAddCostGroupItems,
                loadingAddNewCostGroup: s.loadingAddNewCostGroup,
                costGroupSaveStatus: Data_Map_Internal.alter(Types_CostGroup.ordCostGroupId)(Data_Function["const"](new Data_Maybe.Just(v.value0)))(v.value1)(s.costGroupSaveStatus),
                loadingUpdateTargetItems: s.loadingUpdateTargetItems,
                loadingEstimateComponents: s.loadingEstimateComponents,
                loadingEstimationComponentCopyOperation: s.loadingEstimationComponentCopyOperation,
                loadingEstimationLocationAction: s.loadingEstimationLocationAction
            };
        };
        if (v instanceof ChangeLoadingUpdateTargetItems) {
            return handleModify(Types_Project.ordProjectId)({
                reflectSymbol: function () {
                    return "loadingUpdateTargetItems";
                }
            })()(Data_Symbol.SProxy.value)(v.value0)(v.value1)(s);
        };
        if (v instanceof ChangeEstimationComponentLoadingStatus) {
            return {
                loadingIncomeItemApprovalDates: s.loadingIncomeItemApprovalDates,
                loadingIncomeItemPlannedInvoiceDates: s.loadingIncomeItemPlannedInvoiceDates,
                loadingIncomeItemApprovalStatuses: s.loadingIncomeItemApprovalStatuses,
                loadingIncomeItemTargetInclusionStatuses: s.loadingIncomeItemTargetInclusionStatuses,
                loadingRemoveIncomeItems: s.loadingRemoveIncomeItems,
                loadingAddCostGroupItems: s.loadingAddCostGroupItems,
                loadingAddNewCostGroup: s.loadingAddNewCostGroup,
                costGroupSaveStatus: s.costGroupSaveStatus,
                loadingUpdateTargetItems: s.loadingUpdateTargetItems,
                loadingEstimateComponents: Data_Map_Internal.alter(Types_Component.ordComponentId)(Data_Function["const"](new Data_Maybe.Just(v.value0)))(v.value1)(s.loadingEstimateComponents),
                loadingEstimationComponentCopyOperation: s.loadingEstimationComponentCopyOperation,
                loadingEstimationLocationAction: s.loadingEstimationLocationAction
            };
        };
        if (v instanceof ChangeEstimationComponentCopyOperationLoadingStatus) {
            return handleModify(Types_Project.ordProjectId)({
                reflectSymbol: function () {
                    return "loadingEstimationComponentCopyOperation";
                }
            })()(Data_Symbol.SProxy.value)(v.value0)(v.value1)(s);
        };
        if (v instanceof UpdateLoadingEstimationLocationAction) {
            return {
                loadingIncomeItemApprovalDates: s.loadingIncomeItemApprovalDates,
                loadingIncomeItemPlannedInvoiceDates: s.loadingIncomeItemPlannedInvoiceDates,
                loadingIncomeItemApprovalStatuses: s.loadingIncomeItemApprovalStatuses,
                loadingIncomeItemTargetInclusionStatuses: s.loadingIncomeItemTargetInclusionStatuses,
                loadingRemoveIncomeItems: s.loadingRemoveIncomeItems,
                loadingAddCostGroupItems: s.loadingAddCostGroupItems,
                loadingAddNewCostGroup: s.loadingAddNewCostGroup,
                costGroupSaveStatus: s.costGroupSaveStatus,
                loadingUpdateTargetItems: s.loadingUpdateTargetItems,
                loadingEstimateComponents: s.loadingEstimateComponents,
                loadingEstimationComponentCopyOperation: s.loadingEstimationComponentCopyOperation,
                loadingEstimationLocationAction: v.value0
            };
        };
        throw new Error("Failed pattern match at UpdateStatusesReducer (line 130, column 1 - line 130, column 35): " + [ s.constructor.name, v.constructor.name ]);
    };
};
var reducer = (function () {
    var update$prime = function (state) {
        return function (appAction) {
            var v = toAction(appAction);
            if (v instanceof Data_Maybe.Just) {
                return update(state)(v.value0);
            };
            if (v instanceof Data_Maybe.Nothing) {
                return state;
            };
            throw new Error("Failed pattern match at UpdateStatusesReducer (line 185, column 31 - line 187, column 23): " + [ v.constructor.name ]);
        };
    };
    return ReducerUtils.mkReducer(initialState)(update$prime);
})();
var eqSaveState = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Saving && y instanceof Saving) {
                return true;
            };
            if (x instanceof Saved && y instanceof Saved) {
                return true;
            };
            if (x instanceof SaveFailed && y instanceof SaveFailed) {
                return true;
            };
            return false;
        };
    }
};
module.exports = {
    Add: Add,
    Remove: Remove,
    Saving: Saving,
    Saved: Saved,
    SaveFailed: SaveFailed,
    ChangeLoadingIncomeItemApprovalDates: ChangeLoadingIncomeItemApprovalDates,
    ChangeLoadingIncomeItemPlannedInvoiceDates: ChangeLoadingIncomeItemPlannedInvoiceDates,
    ChangeLoadingIncomeItemApprovalStatuses: ChangeLoadingIncomeItemApprovalStatuses,
    ChangeLoadingIncomeItemTargetInclusionStatuses: ChangeLoadingIncomeItemTargetInclusionStatuses,
    ChangeLoadingRemoveIncomeItems: ChangeLoadingRemoveIncomeItems,
    UpdateLoadingAddNewRowComponentStatus: UpdateLoadingAddNewRowComponentStatus,
    ChangeLoadingAddCostGroupItems: ChangeLoadingAddCostGroupItems,
    ChangeCostGroupSaveStatus: ChangeCostGroupSaveStatus,
    ChangeLoadingUpdateTargetItems: ChangeLoadingUpdateTargetItems,
    ChangeEstimationComponentLoadingStatus: ChangeEstimationComponentLoadingStatus,
    ChangeEstimationComponentCopyOperationLoadingStatus: ChangeEstimationComponentCopyOperationLoadingStatus,
    UpdateLoadingEstimationLocationAction: UpdateLoadingEstimationLocationAction,
    toAction: toAction,
    initialState: initialState,
    update: update,
    handleModify: handleModify,
    reducer: reducer,
    eqSaveState: eqSaveState
};
