// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Client_WASM = require("../Client.WASM/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Controls = require("../Controls/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Formatters = require("../Formatters/index.js");
var Locale = require("../Locale/index.js");
var ProjectComponents_Styles = require("../ProjectComponents.Styles/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoLoader = require("../TacoLoader/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Measurement = require("../Types.Measurement/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Project = require("../Types.Project/index.js");
var UseFetch = require("../UseFetch/index.js");
var Utils = require("../Utils/index.js");
var WindowUtils = require("../WindowUtils/index.js");
var TotalEditable = (function () {
    function TotalEditable(value0) {
        this.value0 = value0;
    };
    TotalEditable.create = function (value0) {
        return new TotalEditable(value0);
    };
    return TotalEditable;
})();
var TotalBasedOnSubprojects = (function () {
    function TotalBasedOnSubprojects(value0) {
        this.value0 = value0;
    };
    TotalBasedOnSubprojects.create = function (value0) {
        return new TotalBasedOnSubprojects(value0);
    };
    return TotalBasedOnSubprojects;
})();
var mkRowData = function (subprojects) {
    return function (measurements) {
        var mapValueJustSubprojectId = function (v) {
            return Data_Functor.map(Data_Maybe.functorMaybe)(Data_Functor.flap(Data_Functor.functorFn)(Data_Tuple.Tuple.create)(v))(v.subprojectId);
        };
        var findMatchingValue = function (subprojectId) {
            return function (v) {
                var $22 = Data_Eq.eq(Types_Project.eqSubprojectId)(v.value0)(subprojectId);
                if ($22) {
                    return new Data_Maybe.Just(v.value1);
                };
                return Data_Maybe.Nothing.value;
            };
        };
        var containsCountInMeasurementTotal = Data_Maybe.isJust(Data_Array.find(function (v) {
            return v.countInMeasurementTotal;
        })(subprojects));
        var mkData = function (measurement) {
            var valuesWithoutSubprojectId = Data_Array.filter(function ($58) {
                return Data_Maybe.isNothing((function (v) {
                    return v.subprojectId;
                })($58));
            })(measurement.values);
            var valuesWithSubprojectId = Data_Array.mapMaybe(mapValueJustSubprojectId)(measurement.values);
            var getSum = function (xs) {
                return Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
                    return v.value;
                })(xs));
            };
            var sumWithSubprojectId = getSum(Data_Functor.map(Data_Functor.functorArray)(Data_Tuple.snd)(valuesWithSubprojectId));
            var sumWithoutSubprojectId = getSum(valuesWithoutSubprojectId);
            var total = (function () {
                var $25 = !containsCountInMeasurementTotal || Utils.nearZero(sumWithSubprojectId);
                if ($25) {
                    return new TotalEditable(sumWithoutSubprojectId);
                };
                return new TotalBasedOnSubprojects(sumWithSubprojectId);
            })();
            var findMatchesForSubproject = function (subproject) {
                return Data_Functor.map(Data_Maybe.functorMaybe)(Data_Tuple.Tuple.create(subproject))(Data_Array.findMap(findMatchingValue(subproject.id))(valuesWithSubprojectId));
            };
            var joinedSubprojectValues = Data_Array.catMaybes(Data_Functor.mapFlipped(Data_Functor.functorArray)(subprojects)(findMatchesForSubproject));
            return {
                name: measurement.unit.name,
                unit: measurement.unit.unit,
                unitId: measurement.unit.id,
                total: total,
                mandatory: measurement.unit.mandatory,
                subprojectValues: joinedSubprojectValues
            };
        };
        return Data_Functor.map(Data_Functor.functorArray)(mkData)(measurements);
    };
};
var mkMeasurementUnitAction = function (handler) {
    return function (projectId) {
        return function (action) {
            return new Actions.MeasurementUnitAction({
                projectId: projectId,
                handler: handler,
                params: action
            });
        };
    };
};
var measurementsTableInstance = TacoTable.mkTableStateless(TofuHooks.getNameVoid)("MeasurementsTableInstance");
var getRow = function (columns) {
    return function (r) {
        return {
            className: "__ProjectMeasurementsRow",
            rowData: r,
            foldingElement: Data_Maybe.Nothing.value,
            onClick: function (v) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            },
            rowKey: Data_Show.show(Types_Newtypes.showMeasurementUnitId)(r.unitId),
            columns: columns
        };
    };
};
var getMeasurementColumns = function (v) {
    var unitCell = (function () {
        if (v.applicationMode instanceof Types_Project.EstimationMode) {
            return new TacoTable_Types.JSXCell([  ], function (r) {
                return TacoInput.remoteStringField()()({
                    value: r.unit,
                    disabled: r.mandatory,
                    mkAction: function (code) {
                        return function (handler) {
                            return mkMeasurementUnitAction(handler)(v.projectId)(new Types_Measurement.EditMeasurementUnitCode({
                                measurementUnitId: r.unitId,
                                code: code
                            }));
                        };
                    },
                    testId: "measurement-unit-" + (Data_Show.show(Data_Show.showInt)(Data_Newtype.unwrap()(r.unitId)) + "-unit-input")
                });
            });
        };
        return new TacoTable_Types.PlainTextCell(function (v1) {
            return v1.unit;
        });
    })();
    var nameCell = (function () {
        if (v.applicationMode instanceof Types_Project.EstimationMode) {
            return new TacoTable_Types.JSXCell([  ], function (r) {
                return TacoInput.remoteStringField()()({
                    value: r.name,
                    disabled: r.mandatory,
                    mkAction: function (name) {
                        return function (handler) {
                            return mkMeasurementUnitAction(handler)(v.projectId)(new Types_Measurement.EditMeasurementUnitName({
                                measurementUnitId: r.unitId,
                                name: name
                            }));
                        };
                    },
                    testId: "measurement-unit-" + (Data_Show.show(Data_Show.showInt)(Data_Newtype.unwrap()(r.unitId)) + "-name-input")
                });
            });
        };
        return new TacoTable_Types.PlainTextCell(function (v1) {
            return v1.name;
        });
    })();
    var mkCell = function (width) {
        return function (spec) {
            return {
                width: width,
                sortProperty: TacoTable_Types.noSortProperty,
                key: spec.key,
                label: spec.label,
                cell: spec.cell,
                headerJSX: Data_Maybe.Nothing.value
            };
        };
    };
    var deleteRowButton = function (r) {
        return TacoButton.component()()({
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconDelete24Px.value),
            disabled: r.mandatory,
            color: TacoConstants.Red.value,
            onClick: Data_Maybe.Just.create(WindowUtils.confirm("Haluatko varmasti poistaa laajuustiedon '" + (r.name + "'?"))(v.dispatch(new Actions.MeasurementUnitAction({
                handler: function (v1) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                },
                projectId: v.projectId,
                params: new Types_Measurement.RemoveMeasurementUnit({
                    measurementUnitId: r.unitId
                })
            })))),
            testId: "measurement-unit-" + (Data_Show.show(Data_Show.showInt)(Data_Newtype.unwrap()(r.unitId)) + "-delete-button")
        });
    };
    var deleteColumn = (function () {
        if (v.applicationMode instanceof Types_Project.EstimationMode) {
            return Control_Applicative.pure(Control_Applicative.applicativeArray)(mkCell(new TacoTable_Types.Fixed("2rem"))({
                key: "delete",
                label: React_Basic_DOM.text(""),
                cell: new TacoTable_Types.JSXCell([  ], deleteRowButton)
            }));
        };
        return [  ];
    })();
    var measurementValueInput = function (subproject) {
        return function (r) {
            var mValue = Data_Foldable.lookup(Data_Foldable.foldableArray)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "projectId";
                }
            })(Types_Project.eqProjectId))()({
                reflectSymbol: function () {
                    return "name";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Project.eqSubprojectId))()({
                reflectSymbol: function () {
                    return "countInMeasurementTotal";
                }
            })(Data_Eq.eqBoolean))()({
                reflectSymbol: function () {
                    return "code";
                }
            })(Data_Eq.eqString)))(subproject)(r.subprojectValues);
            return TacoInput.remoteNumberField()()({
                precision: 1,
                value: Data_Maybe.maybe(0.0)(function (v1) {
                    return v1.value;
                })(mValue),
                mkAction: v.mkUpdateAction(new Data_Maybe.Just(subproject.id))(r.unitId),
                testId: "measurement-unit-" + (Data_Show.show(Data_Show.showInt)(Data_Newtype.unwrap()(r.unitId)) + "-value-input")
            });
        };
    };
    var mkSubprojectColumn = function (subproject) {
        return mkCell(new TacoTable_Types.Flex(1))({
            key: Data_Show.show(Types_Project.showSubprojectId)(subproject.id),
            label: React_Basic_DOM.text(subproject.code),
            cell: TacoTable_Types.JSXCell.create([  ])(measurementValueInput(subproject))
        });
    };
    var totalCell = function (r) {
        if (r.total instanceof TotalEditable) {
            return TacoInput.remoteNumberField()()({
                precision: 1,
                value: r.total.value0,
                mkAction: v.mkUpdateAction(Data_Maybe.Nothing.value)(r.unitId),
                testId: "measurement-unit-" + (Data_Show.show(Data_Show.showInt)(Data_Newtype.unwrap()(r.unitId)) + "-total-input")
            });
        };
        if (r.total instanceof TotalBasedOnSubprojects) {
            return React_Basic_DOM.text(Formatters.formatDecimalValue(1)(r.total.value0));
        };
        throw new Error("Failed pattern match at Measurements (line 253, column 19 - line 260, column 84): " + [ r.total.constructor.name ]);
    };
    return Data_Semigroup.append(Data_Semigroup.semigroupArray)([ mkCell(new TacoTable_Types.Flex(1))({
        key: "name",
        label: React_Basic_DOM.text(Locale.lookup_("measurement_unit_name")),
        cell: nameCell
    }), mkCell(new TacoTable_Types.Flex(1))({
        key: "unit",
        label: React_Basic_DOM.text(Locale.lookup_("measurement_unit_id")),
        cell: unitCell
    }), mkCell(new TacoTable_Types.Flex(1))({
        key: "total",
        label: React_Basic_DOM.text(Locale.lookup_("measurement_total")),
        cell: new TacoTable_Types.JSXCell([  ], totalCell)
    }) ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_Functor.map(Data_Functor.functorArray)(mkSubprojectColumn)(v.subprojects))(deleteColumn));
};
var eqMeasurementTotal = {
    eq: function (x) {
        return function (y) {
            if (x instanceof TotalEditable && y instanceof TotalEditable) {
                return x.value0 === y.value0;
            };
            if (x instanceof TotalBasedOnSubprojects && y instanceof TotalBasedOnSubprojects) {
                return x.value0 === y.value0;
            };
            return false;
        };
    }
};
var addRowControl = function (projectId) {
    return function (dispatch) {
        return Controls.component()()({
            leftControls: [ TacoButton.component()()({
                text: "Lis\xe4\xe4 rivi",
                icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconAddPlusV1.value),
                onClick: new Data_Maybe.Just(dispatch(new Actions.MeasurementUnitAction({
                    handler: function (v) {
                        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                    },
                    projectId: projectId,
                    params: Types_Measurement.AddMeasurementUnit.value
                }))),
                testId: "add-measurement-unit-row-button"
            }) ],
            topGutter: true
        });
    };
};
var component = TofuHooks.mkHookComponent("Measurements")(function (props) {
    return function __do() {
        var v = Client_WASM.useSubprojects(props.projectId)();
        var v1 = UseFetch.useFetchValueByProjectId(props.projectId)(function (v1) {
            return v1.measurements;
        })(Actions.GetMeasurementsRequest.create)();
        var appDispatch = StateHooks.useDispatch();
        var updateValueAction = function (projectId1) {
            return function (subprojectId) {
                return function (measurementUnitId) {
                    return function (value) {
                        return function (handler) {
                            return new Actions.UpdateMeasurementValue({
                                projectId: projectId1,
                                handler: handler,
                                params: {
                                    measurementUnitId: measurementUnitId,
                                    subprojectId: subprojectId,
                                    value: value
                                }
                            });
                        };
                    };
                };
            };
        };
        var valueTable = function (projectId1) {
            return function (measurements) {
                var rowData = mkRowData(v.value0)(measurements);
                var measurementColumns = getMeasurementColumns({
                    subprojects: v.value0,
                    mkUpdateAction: updateValueAction(projectId1),
                    projectId: projectId1,
                    applicationMode: props.applicationMode,
                    dispatch: appDispatch
                });
                return TacoTable["tableStateless'"](TofuHooks.getNameVoid)(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "unitId";
                    }
                })(Types_Newtypes.eqMeasurementUnitId))()({
                    reflectSymbol: function () {
                        return "unit";
                    }
                })(Data_Eq.eqString))()({
                    reflectSymbol: function () {
                        return "total";
                    }
                })(eqMeasurementTotal))()({
                    reflectSymbol: function () {
                        return "subprojectValues";
                    }
                })(Data_Eq.eqArray(Data_Tuple.eqTuple(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "projectId";
                    }
                })(Types_Project.eqProjectId))()({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Project.eqSubprojectId))()({
                    reflectSymbol: function () {
                        return "countInMeasurementTotal";
                    }
                })(Data_Eq.eqBoolean))()({
                    reflectSymbol: function () {
                        return "code";
                    }
                })(Data_Eq.eqString)))(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "value";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "subprojectId";
                    }
                })(Data_Maybe.eqMaybe(Types_Project.eqSubprojectId)))()({
                    reflectSymbol: function () {
                        return "measurementId";
                    }
                })(Types_Newtypes.eqMeasurementUnitId))()({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Newtypes.eqMeasurementValueId))))))()({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(Data_Eq.eqString))()({
                    reflectSymbol: function () {
                        return "mandatory";
                    }
                })(Data_Eq.eqBoolean)))(true)(measurementsTableInstance)(Data_Functor.map(Data_Functor.functorArray)(getRow(measurementColumns))(rowData))(measurementColumns);
            };
        };
        var loading = React_Basic_DOM_Generated.div()({
            className: "__MeasurementUnitsLoading",
            children: [ TacoLoader.component()()({
                size: "1rem"
            }) ]
        });
        var child = (function () {
            if (!v.value1 && (v1.value0 instanceof Data_Maybe.Just && !v1.value1)) {
                return React_Basic.fragment([ valueTable(props.projectId)(v1.value0.value0.items), addRowControl(props.projectId)(appDispatch) ]);
            };
            return loading;
        })();
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "__MeasurementUnits",
            css: ProjectComponents_Styles.projectDetailsSectionStyles,
            children: [ TacoText.component()()({
                text: Locale.lookup_("measurement_units"),
                weight: TacoText_Types.Bold.value,
                gutterBottom: true,
                variant: TacoText_Types.Paragraph.value
            }), child ]
        });
    };
});
module.exports = {
    component: component
};
