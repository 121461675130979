import { SelectItem } from "@tocoman/ui";
import { useMemo, useState } from "react";

/**
 * Hook to filter items in a combobox. Uses label-includes-filter by default.
 * @param items
 * @param filterAlgorithm Optional filtering algorithm. Defaults to label-includes-filter.
 */
export function useComboboxFilter<T>(
  items: SelectItem<T>[],
  filterAlgorithm?: (
    item: SelectItem<T>,
    query?: string | null | undefined
  ) => boolean
): {
  items: SelectItem<T>[];
  filterHandler: (query: string) => void;
} {
  const [filterText, setFilterText] = useState<string | undefined | null>(
    undefined
  );
  const filteredItems = useMemo(() => {
    const filter = filterAlgorithm ?? createLabelIncludesFilter(filterText);
    return items.filter((item) => {
      return filter(item, filterText);
    });
  }, [items, filterText, filterAlgorithm]);

  return {
    items: filteredItems,
    filterHandler: setFilterText,
  };
}

function createLabelIncludesFilter<T>(query?: string | null | undefined) {
  return (item: SelectItem<T>) => {
    if (!query) {
      return true;
    }
    if (!item.label) {
      return false;
    }
    return item.label.toLowerCase().includes(query.toLowerCase());
  };
}
