// Generated by purs version 0.14.5
"use strict";
var Data_Functor = require("../Data.Functor/index.js");
var Effect = require("../Effect/index.js");
var ReactQuery = require("../ReactQuery/index.js");
var ReactQuery_Internal = require("../ReactQuery.Internal/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_Organization = require("../Types.Organization/index.js");
var Types_ServerRoutes = require("../Types.ServerRoutes/index.js");
var useCurrentOrganization = ReactQuery.useGetRoute(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "organization";
    }
})(Types_Organization.tofuJSONOrganization)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "plan";
    }
})(Types_Organization.tofuJSONPlan)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "organization";
    }
})(Types_Organization.tofuJSONOrganization)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "plan";
    }
})(Types_Organization.tofuJSONPlan)(TofuJSON.nilWriteForeignFields)()()())()()()))("serverRoutes.currentOrganization")(Types_ServerRoutes.serverRoutes.currentOrganization)({});
var useOrganizationLicensePlan = Data_Functor.map(Effect.functorEffect)(Data_Functor.map(ReactQuery_Internal.functorQueryResult)(function (v) {
    return v.plan;
}))(useCurrentOrganization);
var successPro = new ReactQuery_Internal.Success(Types_Organization.Pro.value);
var successPremium = new ReactQuery_Internal.Success(Types_Organization.Premium.value);
module.exports = {
    useCurrentOrganization: useCurrentOrganization,
    useOrganizationLicensePlan: useOrganizationLicensePlan,
    successPro: successPro,
    successPremium: successPremium
};
