// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var warn = function (dictWarn) {
    return {};
};
var trace = function (dictDebugWarning) {
    return function (a) {
        return function (k) {
            return $foreign["_trace"](a, k);
        };
    };
};
var traceM = function (dictDebugWarning) {
    return function (dictMonad) {
        return function (s) {
            return Control_Bind.discard(Control_Bind.discardUnit)(dictMonad.Bind1())(Control_Applicative.pure(dictMonad.Applicative0())(Data_Unit.unit))(function () {
                return trace()(s)(function (v) {
                    return Control_Applicative.pure(dictMonad.Applicative0())(Data_Unit.unit);
                });
            });
        };
    };
};
var spy = function (dictDebugWarning) {
    return function (tag) {
        return function (a) {
            return $foreign["_spy"](tag, a);
        };
    };
};
var spyWith = function (dictDebugWarning) {
    return function (msg) {
        return function (f) {
            return function (a) {
                return Data_Function["const"](a)(spy()(msg)(f(a)));
            };
        };
    };
};
var $$debugger = function (dictDebugWarning) {
    return function (f) {
        return $foreign["_debugger"](f);
    };
};
module.exports = {
    trace: trace,
    traceM: traceM,
    spy: spy,
    spyWith: spyWith,
    "debugger": $$debugger,
    warn: warn
};
