// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Data_Function_Uncurried = require("../Data.Function.Uncurried/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_JSDate = require("../Data.JSDate/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Effect = require("../Effect/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var formatPercentageValue = function (v) {
    return $foreign["_formatDecimalNValue"](v.precision, v.value * 100.0) + " %";
};
var formatPercentageValue$prime = function (precision) {
    return function (value) {
        return formatPercentageValue({
            precision: precision,
            value: value
        });
    };
};
var formatDecimalValue = function (v) {
    if (v === 1) {
        return Data_Function_Uncurried.runFn1($foreign["_formatDecimal1Value"]);
    };
    if (v === 2) {
        return Data_Function_Uncurried.runFn1($foreign["_formatDecimal2Value"]);
    };
    if (v === 3) {
        return Data_Function_Uncurried.runFn1($foreign["_formatDecimal3Value"]);
    };
    return Data_Function_Uncurried.runFn2($foreign["_formatDecimalNValue"])(v);
};
var formatPercentageOfHundredAsString = (function () {
    var $13 = formatDecimalValue(2);
    var $14 = Data_Newtype.un()(Types_Unit.PercentageOfHundred);
    return function ($15) {
        return $13($14($15));
    };
})();
var formatPercentageOfHundredAsStringWithPercent = function (x) {
    return formatPercentageOfHundredAsString(x) + " %";
};
var formatDateValue = $foreign["_formatDateValue"];
var getFormattedCurrentDate = Data_Functor.map(Effect.functorEffect)(formatDateValue)(Data_JSDate.now);
var formatDateTimeValue = $foreign["_formatDateTimeValue"];
var formatDateTimeStringValue = $foreign["_formatDateTimeStringValue"];
var formatDateStringValue = function (str) {
    return $foreign["_formatDateStringValue"](str);
};
var formatCurrencyValueWithoutDecimals = function (v) {
    return function (value) {
        return $foreign["_formatCurrencyValueWithoutDecimals"](v)(value);
    };
};
var formatCurrencyValue = function (v) {
    return function (value) {
        return $foreign["_formatCurrencyValue"](v)(value);
    };
};
module.exports = {
    formatCurrencyValue: formatCurrencyValue,
    formatCurrencyValueWithoutDecimals: formatCurrencyValueWithoutDecimals,
    formatDecimalValue: formatDecimalValue,
    formatPercentageOfHundredAsString: formatPercentageOfHundredAsString,
    formatPercentageOfHundredAsStringWithPercent: formatPercentageOfHundredAsStringWithPercent,
    formatDateValue: formatDateValue,
    formatDateStringValue: formatDateStringValue,
    formatDateTimeValue: formatDateTimeValue,
    getFormattedCurrentDate: getFormattedCurrentDate,
    formatDateTimeStringValue: formatDateTimeStringValue,
    formatPercentageValue: formatPercentageValue,
    "formatPercentageValue'": formatPercentageValue$prime,
    "_formatCurrencyValue": $foreign["_formatCurrencyValue"],
    "_formatCurrencyValueWithoutDecimals": $foreign["_formatCurrencyValueWithoutDecimals"],
    "_formatDecimal0Value": $foreign["_formatDecimal0Value"],
    "_formatDecimal1Value": $foreign["_formatDecimal1Value"],
    "_formatDecimal2Value": $foreign["_formatDecimal2Value"],
    "_formatDecimal3Value": $foreign["_formatDecimal3Value"],
    "_formatDecimalNValue": $foreign["_formatDecimalNValue"],
    "_formatDateValue": $foreign["_formatDateValue"],
    "_formatDateStringValue": $foreign["_formatDateStringValue"],
    "_formatDateTimeValue": $foreign["_formatDateTimeValue"],
    "_formatDateTimeStringValue": $foreign["_formatDateTimeStringValue"],
    formatDateTime: $foreign.formatDateTime
};
