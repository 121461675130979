// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var StyleProperties = require("../StyleProperties/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var separatorHeadingStyles = function (v) {
    return Box.boxStyle([ Box.PaddingLeft.create(new TacoConstants.CustomSize("1rem")), new Box.BackgroundColor(TacoConstants.GrayLightest.value), Box.PaddingRight.create(new TacoConstants.CustomSize("1px")), new Box.Overflow(Box.Auto.value, Box.Scroll.value), new Box.PaddingY(TacoConstants.XS.value), Box.FlexRow.value, Box.JustifySpaceBetween.value, Box.AlignCenter.value, Box.Style.create(React_Basic_Emotion.css()({
        borderBottom: StyleProperties.border(new TacoConstants.BorderCustom(TacoConstants.Gray.value))
    })) ]);
};
var columnsStyles = Box.boxStyle([ new Box.FlexGrow(1), Box.FlexRow.value, Box.AlignStart.value ]);
module.exports = {
    separatorHeadingStyles: separatorHeadingStyles,
    columnsStyles: columnsStyles
};
