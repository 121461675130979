import { ValueFormatterParams } from "ag-grid-community";
import i18n from "i18next";

const defaultLanguage = "fi-FI";
const formatParamsToTwoDecimals = (params: ValueFormatterParams) =>
  params.value || params.value === 0
    ? parseFloat(params.value).toLocaleString(
        i18n?.language ?? defaultLanguage,
        {
          style: "decimal",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      )
    : "";

export const twoDecimalColDef = {
  valueFormatter: formatParamsToTwoDecimals,
  cellEditorParams: {
    useFormatter: true,
  },
  cellClass: "decimalType",
  cellStyle: { textAlign: "right" },
};
