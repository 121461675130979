// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var CostEstimationLayout_Types = require("../CostEstimationLayout.Types/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var SummariesPanel_Styles = require("../SummariesPanel.Styles/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var component = TofuHooks.mkMemoHookComponent("CostEstimation.SummariesPanel")(function (v) {
    var active = Data_Eq.notEq(CostEstimationLayout_Types.eqPanelMode)(v.panelMode)(CostEstimationLayout_Types.MinimizedMode.value);
    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        children: (function () {
            if (active) {
                var contents = v.getContents(Data_Unit.unit);
                var contentsContainer = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                    children: [ contents ],
                    className: "contents-container",
                    css: SummariesPanel_Styles.contentsContainerStyles
                });
                return [ contentsContainer ];
            };
            return [  ];
        })(),
        className: "__SummariesPanel",
        css: SummariesPanel_Styles.containerStyles
    }));
});
module.exports = {
    component: component
};
