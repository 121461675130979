import { Button, Input, Modal } from "@tocoman/ui";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useRef, useState } from "react";
import { Table } from "../../../../components/Table";
import {
  OfferParams,
  useOfferParamsQuery,
  useSetOfferParamsQuery,
} from "../useOfferParamsQuery";
import { PremiumOfferData } from "./PremiumOfferPage";
import { ColDef, ColGroupDef } from "ag-grid-community";
import {
  getPercentageToAdd,
  getAdditionalTotal,
  setPercentageToAdd,
  setAdditionInCurrency,
  getCostItemsPinnedTotalRow,
} from "./PremiumRowModalFunctions";
import { AgGridReact } from "ag-grid-react";
import { AdditionalRow } from "./PremiumOfferPageTable";

type EditRowsRowData = {
  code: string;
  description: string;
  baseTotal: number;
  total: number;
  percentageToAdd: number | null;
  additionalTotal: number;
};

type EditRowModalProps = {
  projectId: number;
  code: string;
  open: boolean;
  updateOfferParams: (params: OfferParams) => void;
  rowData: PremiumOfferData[] | null;
  onClose: () => void;
  deleteRow: (code: string) => void;
  additionalRows: AdditionalRow[];
  currency: string;
};

export type PercentageType =
  | "riskPercentages"
  | "generalPercentages"
  | "changePercentages"
  | "specialPercentages";

export const EditRowModal = ({
  projectId,
  code,
  open,
  updateOfferParams,
  rowData,
  onClose,
  deleteRow,
  additionalRows,
  currency,
}: EditRowModalProps) => {
  const { t } = useTranslation("estimation", { keyPrefix: "offerPage" });
  const gridRef = useRef<AgGridReact>(null);

  const { data: offerParams, refetch } = useOfferParamsQuery(Number(projectId));
  const editOfferValue = useSetOfferParamsQuery(projectId);

  const currentSpecialContract =
    offerParams?.specialPercentages.find((row) => row.key === code)?.value ?? 0;

  const [newSpecialContract, setNewSpecialContract] = useState<number | null>(
    null
  );

  const newSpecialContractObject = {
    value: newSpecialContract ?? currentSpecialContract,
    key: code ?? "specialContract",
  };

  const otherSpecialContractRows =
    offerParams?.specialPercentages.filter((row) => row.key !== code) ?? [];

  const specialContractRowsWithNewValue = [
    ...otherSpecialContractRows,
    newSpecialContractObject,
  ];

  const savedRowDescription =
    offerParams?.rowDescriptions.find((row) => row.key === code)?.value ?? null;
  const handleDelete = () => {
    deleteRow(code);
    const resetRowDescriptions = offerParams?.rowDescriptions.filter(
      (row) => row.key !== code
    );

    const otherSpecialContractRows = offerParams?.specialPercentages.filter(
      (row) => row.key !== code
    );
    const resetValues = {
      showEconomicMargin: offerParams?.showEconomicMargin ?? true,
      values: {
        riskPercentages:
          code === "risk" ? [] : offerParams?.riskPercentages ?? [],
        marginPercentages: offerParams?.marginPercentages ?? [],
        offerOverrides: offerParams?.offerOverrides ?? [],
        rowDescriptions: resetRowDescriptions ?? [],
        generalPercentages:
          code === "general" ? [] : offerParams?.generalPercentages ?? [],
        changePercentages:
          code === "change" ? [] : offerParams?.changePercentages ?? [],
        specialPercentages: code?.startsWith("specialContract")
          ? otherSpecialContractRows ?? []
          : [],
      },
    };
    editOfferValue.mutate(resetValues);
    handleClose();
  };

  const [editedRowDescription, setEditedRowDescription] = useState<
    string | null
  >(null);

  const selectedRowData = useMemo(() => {
    let percentageType: PercentageType = "riskPercentages";
    if (code?.startsWith("specialContract.")) {
      percentageType = "specialPercentages";
    }
    switch (code) {
      case "risk":
        percentageType = "riskPercentages";
        break;
      case "general":
        percentageType = "generalPercentages";
        break;
      case "change":
        percentageType = "changePercentages";
        break;
      default:
        break;
    }
    return (
      additionalRows
        ?.filter((data) => data?.code === code)
        .flatMap((data) => {
          const costClasses: EditRowsRowData[] = [];
          data?.costClasses?.forEach((costClass) => {
            const generalPercentage =
              offerParams?.[percentageType]?.find(
                (row) => row.key === "summary"
              )?.value ??
              offerParams?.[percentageType]?.find(
                (row) => row.key === costClass.costClassCode
              )?.value ??
              0;
            const additionalTotal = costClass.baseTotal * generalPercentage;
            costClasses.push({
              code: costClass.costClassCode,
              description: costClass.name,
              baseTotal: costClass.baseTotal,
              total: costClass.baseTotal + additionalTotal,
              percentageToAdd: generalPercentage,
              additionalTotal,
            });
          });
          const costItems =
            data?.costItems?.map((costItem) => {
              const generalPercentage =
                offerParams?.[percentageType]?.find(
                  (row) => row.key === "summary"
                )?.value ??
                offerParams?.[percentageType]?.find(
                  (row) => row.key === costItem.code
                )?.value ??
                0;
              const additionalTotal =
                (costItem.baseTotal ?? 0) * generalPercentage;
              const total = (costItem.baseTotal ?? 0) + additionalTotal;
              return {
                code: costItem.code,
                description: costItem.description ?? t(costItem.code),
                baseTotal: costItem.baseTotal ?? costItem.additionalTotal,
                total,
                percentageToAdd: generalPercentage,
                additionalTotal,
                socialExpensePrice: 0,
              };
            }) ?? [];
          const costItemsWithoutGeneral = costItems.filter(
            (row) => row.code !== "general"
          );
          return [...costClasses, ...costItemsWithoutGeneral];
        }) ?? []
    );
  }, [rowData, offerParams, code]);

  const handleSave = () => {
    const newPercentages: { value: number; key: string }[] = [];
    gridRef.current?.api.forEachNode((node) => {
      newPercentages.push({
        value: node.data.percentageToAdd,
        key: node.data.code,
      });
    });
    const totalRow = gridRef.current?.api?.getPinnedBottomRow(0);
    if (totalRow) {
      newPercentages.push({
        value: totalRow.data.percentageToAdd,
        key: "total",
      });
    }
    const firstPercentage = newPercentages[0]?.value;
    const allHaveSamePercentage = newPercentages.every(
      (value) => value.value === firstPercentage
    );
    const summaryPercentage = allHaveSamePercentage
      ? [{ value: firstPercentage, key: "summary" }]
      : undefined;
    const currentRowDescriptions = offerParams?.rowDescriptions;
    const hasValue = currentRowDescriptions?.find((row) => row.key === code);
    let rowDescriptions = currentRowDescriptions || [];

    if (editedRowDescription && code) {
      if (hasValue) {
        rowDescriptions = rowDescriptions.map((row) =>
          row.key === code ? { key: code, value: editedRowDescription } : row
        );
      } else {
        rowDescriptions = [
          ...rowDescriptions,
          { key: code, value: editedRowDescription },
        ];
      }
    } else if (hasValue) {
      rowDescriptions = rowDescriptions.filter((row) => row.key !== code);
    }

    const newPercentageValues = summaryPercentage ?? newPercentages;
    const newValues = {
      showEconomicMargin: offerParams?.showEconomicMargin ?? true,
      values: {
        riskPercentages:
          code === "risk"
            ? newPercentageValues
            : offerParams?.riskPercentages ?? [],
        marginPercentages: offerParams?.marginPercentages ?? [],
        offerOverrides: offerParams?.offerOverrides ?? [],
        rowDescriptions: rowDescriptions ?? [],
        generalPercentages:
          code === "general"
            ? newPercentageValues
            : offerParams?.generalPercentages ?? [],
        changePercentages:
          code === "change"
            ? newPercentageValues
            : offerParams?.changePercentages ?? [],
        specialPercentages: code?.startsWith("specialContract.")
          ? specialContractRowsWithNewValue
          : offerParams?.specialPercentages ?? [],
      },
    };
    editOfferValue.mutate(newValues, {
      onSuccess: async () => {
        await refetch();
      },
    });
    handleClose();
  };

  useEffect(() => {
    if (offerParams) {
      updateOfferParams(offerParams);
    }
  }, [offerParams]);

  const colDefs: (ColDef | ColGroupDef)[] = useMemo(
    () => [
      {
        field: "description",
        headerName: t("costClass"),
        flex: 1,
      },
      {
        field: "baseTotal",
        headerName: t("baseTotal"),
        type: ["money"],
        flex: 1,
      },
      {
        field: "percentageToAdd",
        headerName: t("columns.additionalPercentage"),
        type: ["percentageWithZeroValue", "editable"],
        flex: 1,
        valueGetter: getPercentageToAdd,
        valueSetter: setPercentageToAdd,
      },
      {
        field: "additionalTotal",
        headerName: t("columns.additionalTotal"),
        type: ["money", "editable"],
        flex: 1,
        valueGetter: getAdditionalTotal,
        valueSetter: setAdditionInCurrency,
      },
    ],
    []
  );

  const actions = [
    <div className={"w-full flex justify-between"} key={"btn-wrapper"}>
      <Button
        key={"delete"}
        label={t("delete")}
        onClick={handleDelete}
        color={"danger"}
      />
      <div>
        <Button
          key="cancel"
          label={t("cancel")}
          onClick={onClose}
          variant="text"
          color="gray"
        />
        <Button
          key="save"
          label={t("save")}
          onClick={handleSave}
          color="normal"
        />
      </div>
    </div>,
  ];

  const handleClose = () => {
    setEditedRowDescription(null);
    onClose();
  };

  const [pinnedTotalRow, setPinnedTotalRow] = useState<
    EditRowsRowData[] | undefined
  >();

  const onFirstDataRendered = () => {
    const pinnedRow = getCostItemsPinnedTotalRow(gridRef, t);
    setPinnedTotalRow(pinnedRow);
  };

  const onCellValueChanged = () => {
    const updatedPinnedRow = getCostItemsPinnedTotalRow(gridRef, t);
    setPinnedTotalRow(updatedPinnedRow);
  };

  const defaultRowDescription = code?.startsWith("specialContract.")
    ? t("specialContract")
    : t(code ?? "");

  return (
    <Modal isOpen={open} closeModal={handleClose} width={900} actions={actions}>
      <div className="w-full">
        <Input
          label={t("rowDescription")}
          value={
            editedRowDescription ?? savedRowDescription ?? defaultRowDescription
          }
          onChange={(e) => setEditedRowDescription(e.target.value)}
        />
        {code?.startsWith("specialContract.") && (
          <>
            <hr className={"my-5 border-gray"} />

            <Input
              type={"number"}
              label={t("specialContractAdditionalPrice")}
              defaultValue={currentSpecialContract}
              onChange={(e) => setNewSpecialContract(Number(e.target.value))}
            />
          </>
        )}
        {!code?.startsWith("specialContract.") && (
          <>
            <hr className={"my-5 border-gray"} />
            <Table
              gridRef={gridRef}
              rowData={selectedRowData}
              columnDefs={colDefs}
              domLayout={"autoHeight"}
              context={{
                currency,
              }}
              onFirstDataRendered={onFirstDataRendered}
              onCellValueChanged={onCellValueChanged}
              pinnedBottomRowData={pinnedTotalRow}
            />
          </>
        )}
      </div>
    </Modal>
  );
};
