import { ValueFormatterParams } from "ag-grid-community";
import { has } from "lodash";
import {
  currencyToLocale,
  defaultCurrency,
} from "src/client-ts/utils/currency";

// These are AG grids untyped params, extended with context
// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface ExtendedValueFormatterParams<TData = any, TValue = any>
  extends ValueFormatterParams<TData, TValue> {
  context: {
    currency?: keyof typeof currencyToLocale;
  };
}

function formatMoney(params: ExtendedValueFormatterParams) {
  const value = has(params.value, "toString")
    ? parseFloat(params.value.toString())
    : params.value;
  const { currency } = params.context ?? defaultCurrency;
  const locale = currency
    ? currencyToLocale[currency]
    : currencyToLocale[defaultCurrency];
  return value || value === 0 || Number(value)
    ? parseFloat(value).toLocaleString(locale, {
        style: "currency",
        currency: currency ?? "EUR",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : "";
}

export const moneyColDef = {
  valueFormatter: formatMoney,
  cellEditorParams: {
    useFormatter: true,
  },
  cellClass: "moneyFormat",
  cellStyle: { textAlign: "right" },
};
