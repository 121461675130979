import { ProjectFormData } from "ts-bindings/ProjectFormData";
import { ProjectId } from "ts-bindings/ProjectId";

import { WorkerRequest } from "ts-bindings/WorkerRequest";
import { mkWorkerAction } from "./state";
import { PostTransportFileAction } from "../../../ts-bindings/PostTransportFileAction";
import { TransportFileParams } from "../../../ts-bindings/TransportFileParams";

export const mkProjectFormUpdateAction = (
  projectId: ProjectId,
  projectFormData: ProjectFormData
): WorkerRequest => {
  return mkWorkerAction({
    type: "ProjectFormUpdate",
    value: {
      projectId,
      action: { type: "EditProjectForm", value: { projectFormData } },
    },
  });
};

export const mkProjectFormCreateAction = (
  projectFormData: ProjectFormData
): WorkerRequest => {
  projectFormData = {
    ...projectFormData,
    projectDetails: {
      ...projectFormData.projectDetails,
      ownProject: false,
      id: 0,
      area: "",
      ownTaxRate: projectFormData.projectDetails.taxRate ?? 25.5,
    },
  };
  return mkWorkerAction({
    type: "ProjectFormUpdate",
    value: {
      projectId: null,
      action: { type: "CreateProjectForm", value: { projectFormData } },
    },
  });
};

export const mkProjectDeleteAction = (
  projectId: ProjectId,
  projectVersion: string,
  projectCode: string
): WorkerRequest => {
  return mkWorkerAction({
    type: "ProjectFormUpdate",
    value: {
      projectId,
      action: {
        type: "DeleteProject",
        value: { projectId, projectVersion, projectCode },
      },
    },
  });
};

export const mkPublishToReportingAction = (
  projectId: ProjectId
): WorkerRequest => {
  return mkWorkerAction({
    type: "ReportingProjectUpdate",
    value: {
      projectId,
      action: {
        type: "PublishToReporting",
      },
    },
  });
};
export const mkUnpublishFromReportingAction = (
  projectId: ProjectId
): WorkerRequest => {
  return mkWorkerAction({
    type: "ReportingProjectUpdate",
    value: {
      projectId,
      action: {
        type: "UnpublishFromReporting",
      },
    },
  });
};

export const mkTransportFileAction = (
  action: PostTransportFileAction
): WorkerRequest =>
  mkWorkerAction({
    type: "TransportFileUpdate",
    value: {
      action,
    },
  });

export const mkPostTransportFileAction = (
  params: TransportFileParams
): WorkerRequest =>
  mkTransportFileAction({
    type: "TransportFile",
    value: params,
  });
