import { Button, Header, Input } from "@tocoman/ui";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { SingleFileSelect } from "../../../components/SingleFileSelect";
import { useUpdatePricing } from "../usePricingUpdate";

type UpdateModalProps = {
  selectedOrgs: string[];
  onUpdateStarted: (value: boolean) => void;
};
export const PricelistUpdateForm = ({
  selectedOrgs,
  onUpdateStarted,
}: UpdateModalProps) => {
  const { t } = useTranslation("superAdmin");

  const [resourceGroupFile, setResourceGroupFile] = useState<File | null>(null);
  const [priceListExcelFile, setPriceListExcelFile] = useState<File | null>(
    null
  );
  const [newConstructionFile, setNewConstructionFile] = useState<File | null>(
    null
  );
  const [renovationFile, setRenovationFile] = useState<File | null>(null);
  const [repairFile, setRepairFile] = useState<File | null>(null);
  const allFiles = useMemo(
    () => [
      {
        resourceGroupFile,
        priceListExcelFile,
        newConstructionFile,
        renovationFile,
        repairFile,
      },
    ],
    [
      resourceGroupFile,
      priceListExcelFile,
      newConstructionFile,
      renovationFile,
      repairFile,
    ]
  );
  const [emptyFiles, setEmptyFiles] = useState<string[]>([]);
  const [priceListCode, setPriceListCode] = useState<string>("");
  const updatePricing = useUpdatePricing();

  const handleUpdate = useCallback(() => {
    const emptyKeys = allFiles.flatMap((file: Record<string, File | null>) => {
      return Object.keys(file).filter((key) => {
        return !file[key];
      });
    });
    setEmptyFiles(emptyKeys);
    if (emptyKeys.length === 0) {
      if (
        resourceGroupFile &&
        priceListExcelFile &&
        newConstructionFile &&
        renovationFile &&
        repairFile
      ) {
        const fileData = new FormData();
        fileData.append("pricelistCode", priceListCode);
        fileData.append("organizations", selectedOrgs.join(","));
        fileData.append("resourceGroup", resourceGroupFile);
        fileData.append("pricelist", priceListExcelFile);
        fileData.append("newBuildingProject", newConstructionFile);
        fileData.append("repairProject", repairFile);
        fileData.append("smallBuildingProject", renovationFile);

        updatePricing.mutate(fileData);
      }
    }
  }, [
    allFiles,
    selectedOrgs,
    priceListCode,
    resourceGroupFile,
    priceListExcelFile,
    newConstructionFile,
    renovationFile,
    repairFile,
  ]);

  useEffect(() => {
    if (updatePricing.isSuccess) {
      onUpdateStarted(true);
    }
  }, [updatePricing]);

  return (
    <div>
      <Header title={t("priceListCode")} titleSize={"small"} />
      <Input
        value={priceListCode}
        onChange={(e) => setPriceListCode(e.target.value)}
        className={"w-full"}
      />
      <FileLoader header={t("resourceGroup")} setFile={setResourceGroupFile} />
      {emptyFiles.includes("resourceGroupFile") && (
        <p className={"text-red-600"}>{t("fileRequired")}</p>
      )}
      <FileLoader
        header={t("priceListExcel")}
        setFile={setPriceListExcelFile}
      />
      {emptyFiles.includes("priceListExcelFile") && (
        <p className={"text-red-600"}>{t("fileRequired")}</p>
      )}
      <FileLoader
        header={t("newConstruction")}
        setFile={setNewConstructionFile}
      />
      {emptyFiles.includes("newConstructionFile") && (
        <p className={"text-red-600"}>{t("fileRequired")}</p>
      )}
      <FileLoader
        header={t("renovationConstruction")}
        setFile={setRenovationFile}
      />
      {emptyFiles.includes("renovationFile") && (
        <p className={"text-red-600"}>{t("fileRequired")}</p>
      )}
      <FileLoader header={t("minorRepairs")} setFile={setRepairFile} />
      {emptyFiles.includes("repairFile") && (
        <p className={"text-red-600"}>{t("fileRequired")}</p>
      )}

      <div className={"flex justify-between w-full mt-3 ml-3"}>
        <div className={"flex justify-end"}>
          <Button
            label={t("update")}
            size="large"
            onClick={handleUpdate}
            loading={updatePricing.isLoading}
          />
        </div>
      </div>
    </div>
  );
};

type FileLoaderProps = {
  header: string;
  setFile: Dispatch<SetStateAction<File | null>>;
};

function FileLoader({ header, setFile }: FileLoaderProps) {
  const { t } = useTranslation("superAdmin");

  return (
    <div>
      <Header title={header} titleSize={"small"} className={"mt-3"} />
      <SingleFileSelect
        onFileSelect={setFile}
        buttonLabel={t("selectFile")}
        dragDropLabel={t("dragDrop")}
      />
    </div>
  );
}
