import { Button, Modal } from "@tocoman/ui";
import { useTranslation } from "react-i18next";
import {
  useDeleteUserInSuperAdmin,
  UserData,
} from "../Tabs/useSuperAdminUsers";

type Props = {
  users: UserData[];
  onClose?: () => void;
};
export const ConfirmUserDelete = ({ users, onClose }: Props) => {
  const { t } = useTranslation("superAdmin");

  const deleteUser = useDeleteUserInSuperAdmin();

  const handleDeleteUsers = () => {
    users?.map(async (user) => await deleteUser.mutateAsync(user.id));
    closeConfirmModal();
  };

  const closeConfirmModal = () => {
    onClose && onClose();
  };

  const confirmTitle = t`confirmDeleteTitle`;
  const confirmChildren = (
    <div>
      <ul>
        {users !== null &&
          users.map((user) => <li key={user.id}>{user.email}</li>)}
      </ul>
    </div>
  );
  const confirmActions = (
    <>
      <div className={"flex justify-between w-full"}>
        <Button
          color="danger"
          variant="text"
          size="large"
          onClick={closeConfirmModal}
        >
          {t`cancel`}
        </Button>
        <Button
          testId="confirmDeleteBtn"
          color="danger"
          size="large"
          onClick={handleDeleteUsers}
        >
          {t`delete`}
        </Button>
      </div>
    </>
  );

  return (
    <Modal
      isOpen={true}
      closeModal={closeConfirmModal}
      title={confirmTitle}
      actions={confirmActions}
    >
      {confirmChildren}
    </Modal>
  );
};
