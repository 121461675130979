// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Show = require("../Data.Show/index.js");
var Effect = require("../Effect/index.js");
var Gap = require("../Gap/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var SeparatorSubHeading_Styles = require("../SeparatorSubHeading.Styles/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var component = TofuHooks.mkHookComponent("SeparatorSubHeading")(function (props) {
    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        className: "__SeparatorSubHeading",
        css: SeparatorSubHeading_Styles.separatorSubHeadingStyles(props),
        children: [ Gap.gapX(TacoConstants.S.value)([ TacoText.component()()({
            text: props.title,
            weight: TacoText_Types.Bold.value
        }), TacoText.component()()({
            text: Data_Show.show(Data_Show.showInt)(props.quantity) + " kpl",
            weight: TacoText_Types.Bold.value
        }) ]) ]
    }));
});
module.exports = {
    component: component
};
