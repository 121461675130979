// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var ClientLogger = require("../ClientLogger/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_JSDate = require("../Data.JSDate/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Exception = require("../Effect.Exception/index.js");
var FetchFile = require("../FetchFile/index.js");
var Milkis = require("../Milkis/index.js");
var RequestUtils = require("../RequestUtils/index.js");
var Types_Alert = require("../Types.Alert/index.js");
var Types_Project = require("../Types.Project/index.js");
var Utils = require("../Utils/index.js");
var downloadEstimationProjectExport = function (v) {
    return function (v1) {
        if (v1 instanceof Actions.GetEstimationProjectExportRequest) {
            return Effect_Aff.launchAff_(Control_Bind.bind(Effect_Aff.bindAff)(RequestUtils.mkHeaders)(function (headers) {
                var fetch = RequestUtils.fetch()(Milkis.URL("/api/project/" + (Data_Show.show(Types_Project.showProjectId)(v1.value0.projectId) + "/exportProjectToFile")))({
                    method: Milkis.getMethod,
                    headers: headers
                });
                return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(fetch))(function (response) {
                    if (response instanceof Data_Either.Right) {
                        var $6 = Milkis.statusCode(response.value0) === 200;
                        if ($6) {
                            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Data_JSDate.now))(function (date) {
                                var dateString = Utils.lossyToHtmlDateString(date);
                                var _filename = "viety-hanke-" + (v1.value0.projectName + ("-" + (dateString + ".tcm")));
                                var filename = Data_String_Common.replaceAll(" ")("_")(_filename);
                                return Control_Monad_Error_Class.catchError(Effect_Aff.monadErrorAff)(FetchFile.createFileDownload(response.value0)(filename))(function (v2) {
                                    return v(new Actions.AddAlert(Types_Alert.AlertError.value, "Lataus ep\xe4onnistui"));
                                });
                            });
                        };
                        return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(Effect_Exception.error("Failed to download project export"));
                    };
                    if (response instanceof Data_Either.Left) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(v(new Actions.AddAlert(Types_Alert.AlertError.value, "Lataus ep\xe4onnistui")))(function () {
                            return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Utils.printError(Effect_Aff.monadEffectAff)(response.value0))(function () {
                                return ClientLogger.logError(Effect_Aff.monadEffectAff)(response.value0);
                            });
                        });
                    };
                    throw new Error("Failed pattern match at FetchEstimationProjectExport (line 32, column 5 - line 48, column 32): " + [ response.constructor.name ]);
                });
            }));
        };
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    };
};
module.exports = {
    downloadEstimationProjectExport: downloadEstimationProjectExport
};
