// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var dixonMeasurementGroupsContainerStyles = Box.boxStyle([ new Box.FlexGrow(1), new Box.FlexBasis("0"), Box.FlexRow.value, new Box.Selector(".__TacoSelect", [ Box.Width.create(new TacoConstants.CustomSize("100%")) ]), new Box.Selector(".DixonMeasurementGroupsTableRow-active", [ new Box.BackgroundColor(TacoConstants.ComponentBlueLight.value) ]), new Box.Selector(".row", [ new Box.Hover([ new Box.BackgroundColor(TacoConstants.GrayLightest.value) ]) ]) ]);
var dixonMeasurementGroupColorContainerStyles = Box.boxStyle([ Box.Width.create(new TacoConstants.CustomSize("100%")), Box.FlexRow.value, Box.AlignCenter.value, Box.JustifyCenter.value ]);
var colorSwatchStyles = function (v) {
    return Box.boxStyle([ new Box.Selector(".__TacoButton", [ Box.Style.create(React_Basic_Emotion.css()({
        backgroundColor: React_Basic_Emotion.str(v.color)
    })), new Box.BorderRadius(TacoConstants.XXS.value) ]), Box.FlexRow.value ]);
};
module.exports = {
    dixonMeasurementGroupsContainerStyles: dixonMeasurementGroupsContainerStyles,
    dixonMeasurementGroupColorContainerStyles: dixonMeasurementGroupColorContainerStyles,
    colorSwatchStyles: colorSwatchStyles
};
