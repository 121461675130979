// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var DixonMeasurements_Types = require("../DixonMeasurements.Types/index.js");
var DixonToolbar_Styles = require("../DixonToolbar.Styles/index.js");
var Effect = require("../Effect/index.js");
var $$Math = require("../Math/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var TutorialContainer = require("../TutorialContainer/index.js");
var component = TofuHooks.mkHookComponent("DixonToolbar")(function (v) {
    var zoomOut = function () {
        return v.editor.set_zoom_relative(0.8);
    };
    var zoomIn = function () {
        return v.editor.set_zoom_relative(1.2);
    };
    var setDixonEditorMode = function (mode) {
        return function () {
            return v.editor.set_mode(TofuJSON.write(DixonMeasurements_Types.tofuJSONDixonEditorMode)(mode));
        };
    };
    var setScale = setDixonEditorMode(DixonMeasurements_Types.SetScaleMode.value);
    var separator = Box.box("Separator")([ new Box.Height(TacoConstants.L.value), Box.Width.create(new TacoConstants.CustomSize("1px")), new Box.BackgroundColor(TacoConstants.GrayLight.value), new Box.MarginX(TacoConstants.XS.value) ])([  ]);
    var scaleNotSet = Data_Maybe.isNothing(v.editorState.active_drawing_scale_measurement_meters);
    var rotate = (function () {
        var $$new = (function () {
            var result = $$Math.remainder(v.editorState.active_drawing_rotation - 90.0)(360.0);
            var $4 = result < 0.0;
            if ($4) {
                return result + 360.0;
            };
            return result;
        })();
        return function () {
            return v.editor.set_active_drawing_rotation($$new);
        };
    })();
    var numActiveMGroups = Data_Array.length(Data_Array.filter(function (v1) {
        return v1.active;
    })(v.editorState.measurement_groups));
    var numActiveFiles = Data_Array.length(Data_Array.filter(function (v1) {
        return v1.active;
    })(v.editorState.files));
    var fitToView = function () {
        return v.editor.fit_to_view(Data_Unit.unit);
    };
    var mkModeButton = function (tooltip) {
        return function (icon) {
            return function (mode) {
                return function (newMeasurementMode) {
                    return TacoButton.component()()({
                        icon: new Data_Maybe.Just(icon),
                        onClick: Data_Maybe.Just.create(setDixonEditorMode(mode)),
                        buttonStyle: (function () {
                            var $5 = Data_Eq.eq(DixonMeasurements_Types.eqDixonEditorMode)(v.editorState.mode)(mode);
                            if ($5) {
                                return TacoButton_Types.Outlined.value;
                            };
                            return TacoButton_Types.Text.value;
                        })(),
                        size: TacoConstants.L.value,
                        color: (function () {
                            var $6 = Data_Eq.eq(DixonMeasurements_Types.eqDixonEditorMode)(v.editorState.mode)(mode);
                            if ($6) {
                                return TacoConstants.PrimaryBlue.value;
                            };
                            return TacoConstants.TextPrimary.value;
                        })(),
                        disabled: newMeasurementMode && (numActiveMGroups !== 1 || scaleNotSet),
                        title: (function () {
                            var $7 = newMeasurementMode && scaleNotSet;
                            if ($7) {
                                return "Aseta mittakaava luodaksesi uuden mittauksen";
                            };
                            var $8 = newMeasurementMode && numActiveMGroups !== 1;
                            if ($8) {
                                return "Valitse yksi mittausryhm\xe4 luodaksesi uuden mittauksen";
                            };
                            return tooltip;
                        })()
                    });
                };
            };
        };
    };
    var setScaleTutorialActive = scaleNotSet && (Data_Eq.notEq(DixonMeasurements_Types.eqDixonEditorMode)(v.editorState.mode)(DixonMeasurements_Types.SetScaleMode.value) && numActiveFiles > 0);
    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        className: "__DixonToolbar",
        css: DixonToolbar_Styles.dixonToolbarContainerStyles,
        children: [ React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "Toolbar",
            css: DixonToolbar_Styles.dixonToolbarStyles,
            children: [ mkModeButton("Mittauspisteiden siirto")(TacoIcon_Icons_Types.IconArrow.value)(DixonMeasurements_Types.SelectMode.value)(false), mkModeButton("Mittausten valinta")(TacoIcon_Icons_Types.IconBoxSelect24Px.value)(DixonMeasurements_Types.BoxSelectMode.value)(false), separator, mkModeButton("Kpl mittaus")(TacoIcon_Icons_Types.IconDot.value)(DixonMeasurements_Types.PointMode.value)(true), mkModeButton("Jana")(TacoIcon_Icons_Types.IconLine.value)(DixonMeasurements_Types.LineMode.value)(true), mkModeButton("Murtoviiva")(TacoIcon_Icons_Types.IconPolyline.value)(DixonMeasurements_Types.PathMode.value)(true), mkModeButton("Suorakulmio")(TacoIcon_Icons_Types.IconRectangle.value)(DixonMeasurements_Types.RectMode.value)(true), mkModeButton("Ala")(TacoIcon_Icons_Types.IconArea.value)(DixonMeasurements_Types.AreaMode.value)(true), separator, TutorialContainer.component({
                active: setScaleTutorialActive,
                contents: Control_Applicative.pure(Control_Applicative.applicativeArray)(TacoButton.component()()({
                    text: "Aseta mittakaava",
                    onClick: Data_Maybe.Just.create(setScale),
                    buttonStyle: (function () {
                        if (setScaleTutorialActive) {
                            return TacoButton_Types.Filled.value;
                        };
                        var $10 = Data_Eq.eq(DixonMeasurements_Types.eqDixonEditorMode)(v.editorState.mode)(DixonMeasurements_Types.SetScaleMode.value);
                        if ($10) {
                            return TacoButton_Types.Outlined.value;
                        };
                        return TacoButton_Types.Text.value;
                    })(),
                    size: TacoConstants.L.value,
                    color: (function () {
                        if (setScaleTutorialActive) {
                            return TacoConstants.PrimaryBlue.value;
                        };
                        var $12 = Data_Eq.eq(DixonMeasurements_Types.eqDixonEditorMode)(v.editorState.mode)(DixonMeasurements_Types.SetScaleMode.value);
                        if ($12) {
                            return TacoConstants.PrimaryBlue.value;
                        };
                        return TacoConstants.TextPrimary.value;
                    })()
                }))
            }) ]
        }), React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "Toolbar",
            css: DixonToolbar_Styles.dixonToolbarStyles,
            children: [ TacoButton.component()()({
                icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconRotate90.value),
                onClick: Data_Maybe.Just.create(rotate),
                buttonStyle: TacoButton_Types.Text.value,
                size: TacoConstants.L.value,
                color: TacoConstants.TextPrimary.value
            }), separator, TacoButton.component()()({
                icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconAddPlusV1.value),
                onClick: Data_Maybe.Just.create(zoomIn),
                buttonStyle: TacoButton_Types.Text.value,
                size: TacoConstants.L.value,
                color: TacoConstants.TextPrimary.value
            }), TacoButton.component()()({
                icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconSubtractV1.value),
                onClick: Data_Maybe.Just.create(zoomOut),
                buttonStyle: TacoButton_Types.Text.value,
                size: TacoConstants.L.value,
                color: TacoConstants.TextPrimary.value
            }), separator, TacoButton.component()()({
                text: "Sovita n\xe4ytt\xf6\xf6n",
                onClick: Data_Maybe.Just.create(fitToView),
                buttonStyle: TacoButton_Types.Text.value,
                size: TacoConstants.L.value,
                color: TacoConstants.TextPrimary.value
            }) ]
        }) ]
    }));
});
module.exports = {
    component: component
};
