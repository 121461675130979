// Generated by purs version 0.14.5
"use strict";
var AllSet = require("../AllSet/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Roles_Types = require("../Roles.Types/index.js");
var Types_Project = require("../Types.Project/index.js");
var writeCostGroupTargetAllowed = function (v) {
    var accessOk = Data_Eq.eq(Roles_Types.writeCostGroupTargetPermissionEq)(v.costGroupTargetWritePermission)(Roles_Types.WriteCostGroupTargetAllowed.value);
    if (accessOk) {
        return new Data_Either.Right(Roles_Types.UnsafeCanWriteCostGroupTarget.value);
    };
    return Data_Either.Left.create(new Roles_Types.AccessError("error_user_does_not_have_write_cost_group_targets_perm"));
};
var usingOrgAdminAllowed = function (v) {
    var $20 = Data_Eq.eq(Roles_Types.isOrganizationAdminEq)(v.isAdmin)(Roles_Types.IsOrganizationAdmin.value);
    if ($20) {
        return new Data_Either.Right(Roles_Types.UnsafeVerifiedOrganizationAdmin.value);
    };
    return Data_Either.Left.create(new Roles_Types.AccessError("error_user_is_not_admin"));
};
var usingCostEstimationAllowed = function (v) {
    var $23 = Data_Eq.eq(Roles_Types.costEstimationPermissionEq)(v.costEstimationPermission)(Roles_Types.UsingCostEstimationAllowed.value);
    if ($23) {
        return new Data_Either.Right(Roles_Types.UnsafeCanUseCostEstimation.value);
    };
    return Data_Either.Left.create(new Roles_Types.AccessError("error_user_does_not_have_ce_perm"));
};
var usingCostControlAllowed = function (v) {
    var $26 = Data_Eq.eq(Roles_Types.costControlPermissionEq)(v.costControlPermission)(Roles_Types.UsingCostControlAllowed.value);
    if ($26) {
        return new Data_Either.Right(Roles_Types.UnsafeCanUseCostControl.value);
    };
    return Data_Either.Left.create(new Roles_Types.AccessError("error_user_does_not_have_cc_perm"));
};
var usingAnyAppAllowed = function (v) {
    var cePermission = Data_Eq.eq(Roles_Types.costEstimationPermissionEq)(v.costEstimationPermission)(Roles_Types.UsingCostEstimationAllowed.value);
    var ccPermission = Data_Eq.eq(Roles_Types.costControlPermissionEq)(v.costControlPermission)(Roles_Types.UsingCostControlAllowed.value);
    var $29 = ccPermission || cePermission;
    if ($29) {
        return new Data_Either.Right(Roles_Types.UnsafeCanUseAnyApp.value);
    };
    return Data_Either.Left.create(new Roles_Types.AccessError("error_user_does_not_have_any_app_perm"));
};
var projectCreationAllowed = function (v) {
    var $33 = Data_Eq.eq(Roles_Types.createProjectsPermissionEq)(v.createProjectsPermission)(Roles_Types.CreateProjectsAllowed.value);
    if ($33) {
        return new Data_Either.Right(Roles_Types.UnsafeCanCreateProject.value);
    };
    return Data_Either.Left.create(new Roles_Types.AccessError("error_user_does_not_have_project_create_rights"));
};
var ceWritingAllowed = function (projectId) {
    return function (v) {
        var $37 = AllSet.member(Types_Project.ordProjectId)(projectId)(v.ceWritableProjects);
        if ($37) {
            return Data_Either.Right.create(new Roles_Types.UnsafeCECanWriteProject(projectId));
        };
        return Data_Either.Left.create(new Roles_Types.AccessError("error_user_does_not_have_project_write_perm"));
    };
};
var ceWriteAllProjectsAllowed = function (v) {
    var $40 = Data_Eq.eq(AllSet.eqAllSet(Types_Project.eqProjectId))(v.ceWritableProjects)(AllSet.All.value);
    if ($40) {
        return new Data_Either.Right(Roles_Types.UnsafeCECanWriteAllProjects.value);
    };
    return Data_Either.Left.create(new Roles_Types.AccessError("error_user_does_not_have_write_all_projects_perm"));
};
var ceReadingAllowed = function (projectId) {
    return function (v) {
        var $44 = AllSet.member(Types_Project.ordProjectId)(projectId)(v.ceReadableProjects);
        if ($44) {
            return Data_Either.Right.create(new Roles_Types.UnsafeCECanReadProject(projectId));
        };
        return Data_Either.Left.create(new Roles_Types.AccessError("error_user_does_not_have_project_read_perm"));
    };
};
var ceReadAllProjectsAllowed = function (v) {
    var $47 = Data_Eq.eq(AllSet.eqAllSet(Types_Project.eqProjectId))(v.ceReadableProjects)(AllSet.All.value);
    if ($47) {
        return new Data_Either.Right(Roles_Types.UnsafeCECanReadAllProjects.value);
    };
    return Data_Either.Left.create(new Roles_Types.AccessError("error_user_does_not_have_read_all_projects_perm"));
};
var ccWritingAllowed = function (projectId) {
    return function (v) {
        var $51 = AllSet.member(Types_Project.ordProjectId)(projectId)(v.ccWritableProjects);
        if ($51) {
            return Data_Either.Right.create(new Roles_Types.UnsafeCCCanWriteProject(projectId));
        };
        return Data_Either.Left.create(new Roles_Types.AccessError("error_user_does_not_have_project_write_perm"));
    };
};
var ccWriteAllProjectsAllowed = function (v) {
    var $54 = Data_Eq.eq(AllSet.eqAllSet(Types_Project.eqProjectId))(v.ccWritableProjects)(AllSet.All.value);
    if ($54) {
        return new Data_Either.Right(Roles_Types.UnsafeCCCanWriteAllProjects.value);
    };
    return Data_Either.Left.create(new Roles_Types.AccessError("error_user_does_not_have_write_all_projects_perm"));
};
var ccReadingAllowed = function (projectId) {
    return function (v) {
        var $58 = AllSet.member(Types_Project.ordProjectId)(projectId)(v.ccReadableProjects);
        if ($58) {
            return Data_Either.Right.create(new Roles_Types.UnsafeCCCanReadProject(projectId));
        };
        return Data_Either.Left.create(new Roles_Types.AccessError("error_user_does_not_have_project_read_perm"));
    };
};
var ccReadAllProjectsAllowed = function (v) {
    var $61 = Data_Eq.eq(AllSet.eqAllSet(Types_Project.eqProjectId))(v.ccReadableProjects)(AllSet.All.value);
    if ($61) {
        return new Data_Either.Right(Roles_Types.UnsafeCCCanReadAllProjects.value);
    };
    return Data_Either.Left.create(new Roles_Types.AccessError("error_user_does_not_have_read_all_projects_perm"));
};
var anyWritingAllowed = function (projectId) {
    return function (v) {
        var $65 = AllSet.member(Types_Project.ordProjectId)(projectId)(AllSet.union(Types_Project.ordProjectId)(v.ccWritableProjects)(v.ceWritableProjects));
        if ($65) {
            return Data_Either.Right.create(new Roles_Types.UnsafeAnyCanWriteProject(projectId));
        };
        return Data_Either.Left.create(new Roles_Types.AccessError("error_user_does_not_have_project_write_perm"));
    };
};
var anyReadingAllowed = function (projectId) {
    return function (v) {
        var $70 = AllSet.member(Types_Project.ordProjectId)(projectId)(AllSet.union(Types_Project.ordProjectId)(v.ccReadableProjects)(v.ceReadableProjects));
        if ($70) {
            return Data_Either.Right.create(new Roles_Types.UnsafeAnyCanReadProject(projectId));
        };
        return Data_Either.Left.create(new Roles_Types.AccessError("error_user_does_not_have_project_read_perm"));
    };
};
module.exports = {
    ccReadingAllowed: ccReadingAllowed,
    ceReadingAllowed: ceReadingAllowed,
    anyReadingAllowed: anyReadingAllowed,
    anyWritingAllowed: anyWritingAllowed,
    ccWritingAllowed: ccWritingAllowed,
    ceWritingAllowed: ceWritingAllowed,
    projectCreationAllowed: projectCreationAllowed,
    ccReadAllProjectsAllowed: ccReadAllProjectsAllowed,
    ceReadAllProjectsAllowed: ceReadAllProjectsAllowed,
    ccWriteAllProjectsAllowed: ccWriteAllProjectsAllowed,
    ceWriteAllProjectsAllowed: ceWriteAllProjectsAllowed,
    usingCostControlAllowed: usingCostControlAllowed,
    usingCostEstimationAllowed: usingCostEstimationAllowed,
    writeCostGroupTargetAllowed: writeCostGroupTargetAllowed,
    usingOrgAdminAllowed: usingOrgAdminAllowed,
    usingAnyAppAllowed: usingAnyAppAllowed
};
