import { createContext, ReactNode, useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { User } from "@datadog/browser-core";
import { getClientConfigVar } from "src/client/config";
import packageJson from "../../../package.json";
import { useCurrentUser } from "../auth/useCurrentUser";
import { datadogUserMetadata } from "./utils";

export const datadogRumInstance = createContext(datadogRum);

export function DatadogRumProvider({ children }: { children: ReactNode }) {
  useEffect(() => {
    if (getClientConfigVar("CLIENT_LOGGING_ENABLED")) {
      datadogRum.init({
        applicationId: getClientConfigVar("CLIENT_DATADOG_APPLICATION_TOKEN"),
        clientToken: getClientConfigVar("CLIENT_DATADOG_CLIENT_TOKEN"),
        site: getClientConfigVar("CLIENT_DATADOG_SITE"),
        service: getClientConfigVar("CLIENT_DATADOG_SERVICE"),
        env: getClientConfigVar("CLIENT_STAGE"),
        version: packageJson.version,
        sessionSampleRate:
          Number(getClientConfigVar("CLIENT_DATADOG_SAMPLE_RATE")) || 0,
        sessionReplaySampleRate: 100,
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: true,
        defaultPrivacyLevel: "mask-user-input",
        enableExperimentalFeatures: ["feature_flags"],
        allowedTracingUrls: [
          { match: /^https?:\/\/localhost.*/, propagatorTypes: ["datadog"] },
          {
            match: /^https?:\/\/(dev|app)\.tocoman\.fi.*/,
            propagatorTypes: ["datadog"],
          },
        ],
      });
    }
  }, []);

  const user = useCurrentUser();
  useEffect(() => {
    if (user === undefined) return;
    datadogRum.setUser(datadogUserMetadata(user) as User);
  }, [user]);

  return (
    <datadogRumInstance.Provider value={datadogRum}>
      {children}
    </datadogRumInstance.Provider>
  );
}
