// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var projectRightsStyles = Box.boxStyle([ new Box.PaddingY(TacoConstants.XL.value) ]);
var filterStyles = Box.boxStyle([ Box.Width.create(new TacoConstants.CustomSize("16rem")), new Box.PaddingBottom(TacoConstants.M.value) ]);
module.exports = {
    filterStyles: filterStyles,
    projectRightsStyles: projectRightsStyles
};
