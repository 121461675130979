// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var Record = require("../Record/index.js");
var TacoGroupToggle_Styles = require("../TacoGroupToggle.Styles/index.js");
var TacoText = require("../TacoText/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var UseUnfocusOnClick = require("../UseUnfocusOnClick/index.js");
var groupToggle = TofuHooks.mkHookComponent("GroupToggle")(function (props) {
    return function __do() {
        var v = UseUnfocusOnClick.useUnfocusOnClick();
        var isActive = props.testActive(props.group);
        var onClick = React_Basic_DOM_Events.capture_(function __do() {
            v.unfocus();
            return props.handleToggleGroup(props.group)(isActive)();
        });
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["button'"]())({
            className: "group-toggle",
            css: TacoGroupToggle_Styles.groupToggleStyles({
                isActive: isActive
            }),
            onClick: onClick,
            ref: v.ref,
            children: [ TacoText.component()()({
                text: props.getName(props.group),
                inheritColor: true
            }) ]
        });
    };
});
var component = TofuHooks.mkHookComponent("TacoGroupToggle")(function (props) {
    var mkGroupToggle = function (group) {
        return groupToggle(Record.merge()()(props)({
            group: group
        }));
    };
    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        className: "group-toggles",
        css: TacoGroupToggle_Styles.groupToggleContainerStyles,
        children: Data_Functor.map(Data_Functor.functorArray)(mkGroupToggle)(props.groups)
    }));
});
module.exports = {
    component: component
};
