// Generated by purs version 0.14.5
"use strict";
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var CostEstimation_Filters = require("../CostEstimation.Filters/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Kishimen = require("../Kishimen/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_Element = require("../Types.Element/index.js");
var Types_Project = require("../Types.Project/index.js");
var Normal = (function () {
    function Normal() {

    };
    Normal.value = new Normal();
    return Normal;
})();
var SkipRecompute = (function () {
    function SkipRecompute() {

    };
    SkipRecompute.value = new SkipRecompute();
    return SkipRecompute;
})();
var ForceRecompute = (function () {
    function ForceRecompute() {

    };
    ForceRecompute.value = new ForceRecompute();
    return ForceRecompute;
})();
var ForceRecomputeSkipInvalidate = (function () {
    function ForceRecomputeSkipInvalidate() {

    };
    ForceRecomputeSkipInvalidate.value = new ForceRecomputeSkipInvalidate();
    return ForceRecomputeSkipInvalidate;
})();
var ForceRecomputeAll = (function () {
    function ForceRecomputeAll() {

    };
    ForceRecomputeAll.value = new ForceRecomputeAll();
    return ForceRecomputeAll;
})();
var AccessTokenState = (function () {
    function AccessTokenState(value0) {
        this.value0 = value0;
    };
    AccessTokenState.create = function (value0) {
        return new AccessTokenState(value0);
    };
    return AccessTokenState;
})();
var ShowSocExpPercentagesInCostsState = (function () {
    function ShowSocExpPercentagesInCostsState(value0) {
        this.value0 = value0;
    };
    ShowSocExpPercentagesInCostsState.create = function (value0) {
        return new ShowSocExpPercentagesInCostsState(value0);
    };
    return ShowSocExpPercentagesInCostsState;
})();
var SelectedComponentsState = (function () {
    function SelectedComponentsState(value0) {
        this.value0 = value0;
    };
    SelectedComponentsState.create = function (value0) {
        return new SelectedComponentsState(value0);
    };
    return SelectedComponentsState;
})();
var ActiveComponentFiltersState = (function () {
    function ActiveComponentFiltersState(value0) {
        this.value0 = value0;
    };
    ActiveComponentFiltersState.create = function (value0) {
        return new ActiveComponentFiltersState(value0);
    };
    return ActiveComponentFiltersState;
})();
var ActiveRefComponentFiltersState = (function () {
    function ActiveRefComponentFiltersState(value0) {
        this.value0 = value0;
    };
    ActiveRefComponentFiltersState.create = function (value0) {
        return new ActiveRefComponentFiltersState(value0);
    };
    return ActiveRefComponentFiltersState;
})();
var SelectedElementsState = (function () {
    function SelectedElementsState(value0) {
        this.value0 = value0;
    };
    SelectedElementsState.create = function (value0) {
        return new SelectedElementsState(value0);
    };
    return SelectedElementsState;
})();
var ActiveElementFiltersState = (function () {
    function ActiveElementFiltersState(value0) {
        this.value0 = value0;
    };
    ActiveElementFiltersState.create = function (value0) {
        return new ActiveElementFiltersState(value0);
    };
    return ActiveElementFiltersState;
})();
var ActiveRefElementFiltersState = (function () {
    function ActiveRefElementFiltersState(value0) {
        this.value0 = value0;
    };
    ActiveRefElementFiltersState.create = function (value0) {
        return new ActiveRefElementFiltersState(value0);
    };
    return ActiveRefElementFiltersState;
})();
var ActiveRefProjectsState = (function () {
    function ActiveRefProjectsState(value0) {
        this.value0 = value0;
    };
    ActiveRefProjectsState.create = function (value0) {
        return new ActiveRefProjectsState(value0);
    };
    return ActiveRefProjectsState;
})();
var AdminetIntegrationActiveState = (function () {
    function AdminetIntegrationActiveState(value0) {
        this.value0 = value0;
    };
    AdminetIntegrationActiveState.create = function (value0) {
        return new AdminetIntegrationActiveState(value0);
    };
    return AdminetIntegrationActiveState;
})();
var ActivateLibraryTabState = (function () {
    function ActivateLibraryTabState(value0) {
        this.value0 = value0;
    };
    ActivateLibraryTabState.create = function (value0) {
        return new ActivateLibraryTabState(value0);
    };
    return ActivateLibraryTabState;
})();
var DixonMeasurementFollowFilteringState = (function () {
    function DixonMeasurementFollowFilteringState(value0) {
        this.value0 = value0;
    };
    DixonMeasurementFollowFilteringState.create = function (value0) {
        return new DixonMeasurementFollowFilteringState(value0);
    };
    return DixonMeasurementFollowFilteringState;
})();
var RefreshEstimationProjectsData = (function () {
    function RefreshEstimationProjectsData(value0) {
        this.value0 = value0;
    };
    RefreshEstimationProjectsData.create = function (value0) {
        return new RefreshEstimationProjectsData(value0);
    };
    return RefreshEstimationProjectsData;
})();
var RefreshReferenceProjectsData = (function () {
    function RefreshReferenceProjectsData(value0) {
        this.value0 = value0;
    };
    RefreshReferenceProjectsData.create = function (value0) {
        return new RefreshReferenceProjectsData(value0);
    };
    return RefreshReferenceProjectsData;
})();
var RefreshClassificationClasses = (function () {
    function RefreshClassificationClasses(value0) {
        this.value0 = value0;
    };
    RefreshClassificationClasses.create = function (value0) {
        return new RefreshClassificationClasses(value0);
    };
    return RefreshClassificationClasses;
})();
var RefreshClassificationGroups = (function () {
    function RefreshClassificationGroups(value0) {
        this.value0 = value0;
    };
    RefreshClassificationGroups.create = function (value0) {
        return new RefreshClassificationGroups(value0);
    };
    return RefreshClassificationGroups;
})();
var RefreshSubprojects = (function () {
    function RefreshSubprojects(value0) {
        this.value0 = value0;
    };
    RefreshSubprojects.create = function (value0) {
        return new RefreshSubprojects(value0);
    };
    return RefreshSubprojects;
})();
var RefreshProjectLocations = (function () {
    function RefreshProjectLocations(value0) {
        this.value0 = value0;
    };
    RefreshProjectLocations.create = function (value0) {
        return new RefreshProjectLocations(value0);
    };
    return RefreshProjectLocations;
})();
var RefreshPlanningCostGroups = (function () {
    function RefreshPlanningCostGroups(value0) {
        this.value0 = value0;
    };
    RefreshPlanningCostGroups.create = function (value0) {
        return new RefreshPlanningCostGroups(value0);
    };
    return RefreshPlanningCostGroups;
})();
var RefreshEstimationComponentsData = (function () {
    function RefreshEstimationComponentsData(value0) {
        this.value0 = value0;
    };
    RefreshEstimationComponentsData.create = function (value0) {
        return new RefreshEstimationComponentsData(value0);
    };
    return RefreshEstimationComponentsData;
})();
var RefreshEstimationElementsData = (function () {
    function RefreshEstimationElementsData(value0) {
        this.value0 = value0;
    };
    RefreshEstimationElementsData.create = function (value0) {
        return new RefreshEstimationElementsData(value0);
    };
    return RefreshEstimationElementsData;
})();
var RefreshEstimationElementSpecsData = (function () {
    function RefreshEstimationElementSpecsData(value0) {
        this.value0 = value0;
    };
    RefreshEstimationElementSpecsData.create = function (value0) {
        return new RefreshEstimationElementSpecsData(value0);
    };
    return RefreshEstimationElementSpecsData;
})();
var RefreshEstimationResourcesData = (function () {
    function RefreshEstimationResourcesData(value0) {
        this.value0 = value0;
    };
    RefreshEstimationResourcesData.create = function (value0) {
        return new RefreshEstimationResourcesData(value0);
    };
    return RefreshEstimationResourcesData;
})();
var RefreshEstimationCostClasses = (function () {
    function RefreshEstimationCostClasses(value0) {
        this.value0 = value0;
    };
    RefreshEstimationCostClasses.create = function (value0) {
        return new RefreshEstimationCostClasses(value0);
    };
    return RefreshEstimationCostClasses;
})();
var RefreshEstimationProjects = (function () {
    function RefreshEstimationProjects(value0) {
        this.value0 = value0;
    };
    RefreshEstimationProjects.create = function (value0) {
        return new RefreshEstimationProjects(value0);
    };
    return RefreshEstimationProjects;
})();
var SetRequestParamsOfDeps = (function () {
    function SetRequestParamsOfDeps(value0) {
        this.value0 = value0;
    };
    SetRequestParamsOfDeps.create = function (value0) {
        return new SetRequestParamsOfDeps(value0);
    };
    return SetRequestParamsOfDeps;
})();
var SubscribeWorkerState = (function () {
    function SubscribeWorkerState(value0) {
        this.value0 = value0;
    };
    SubscribeWorkerState.create = function (value0) {
        return new SubscribeWorkerState(value0);
    };
    return SubscribeWorkerState;
})();
var StateUpdate = (function () {
    function StateUpdate(value0) {
        this.value0 = value0;
    };
    StateUpdate.create = function (value0) {
        return new StateUpdate(value0);
    };
    return StateUpdate;
})();
var genericWorkerRequest = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new RefreshEstimationProjectsData(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new RefreshReferenceProjectsData(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return new RefreshClassificationClasses(x.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return new RefreshClassificationGroups(x.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
            return new RefreshSubprojects(x.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))) {
            return new RefreshProjectLocations(x.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))) {
            return new RefreshPlanningCostGroups(x.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))) {
            return new RefreshEstimationComponentsData(x.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))))) {
            return new RefreshEstimationElementsData(x.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))))) {
            return new RefreshEstimationElementSpecsData(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))))))) {
            return new RefreshEstimationResourcesData(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))))))) {
            return new RefreshEstimationCostClasses(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))))))))) {
            return new RefreshEstimationProjects(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))))))))) {
            return new SetRequestParamsOfDeps(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))))))))))) {
            return new SubscribeWorkerState(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr)))))))))))))) {
            return new StateUpdate(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        throw new Error("Failed pattern match at WasmWorker.WorkerRequest (line 45, column 1 - line 45, column 64): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof RefreshEstimationProjectsData) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof RefreshReferenceProjectsData) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof RefreshClassificationClasses) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
        };
        if (x instanceof RefreshClassificationGroups) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))));
        };
        if (x instanceof RefreshSubprojects) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))));
        };
        if (x instanceof RefreshProjectLocations) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))));
        };
        if (x instanceof RefreshPlanningCostGroups) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))))));
        };
        if (x instanceof RefreshEstimationComponentsData) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))))));
        };
        if (x instanceof RefreshEstimationElementsData) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))))))));
        };
        if (x instanceof RefreshEstimationElementSpecsData) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))))))));
        };
        if (x instanceof RefreshEstimationResourcesData) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))))))))));
        };
        if (x instanceof RefreshEstimationCostClasses) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))))))))));
        };
        if (x instanceof RefreshEstimationProjects) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))))))))))));
        };
        if (x instanceof SetRequestParamsOfDeps) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))))))))))));
        };
        if (x instanceof SubscribeWorkerState) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))))))))))))));
        };
        if (x instanceof StateUpdate) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0)))))))))))))));
        };
        throw new Error("Failed pattern match at WasmWorker.WorkerRequest (line 45, column 1 - line 45, column 64): " + [ x.constructor.name ]);
    }
};
var genericStateUpdateStrategy = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return Normal.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return SkipRecompute.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return ForceRecompute.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return ForceRecomputeSkipInvalidate.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inr))) {
            return ForceRecomputeAll.value;
        };
        throw new Error("Failed pattern match at WasmWorker.WorkerRequest (line 20, column 1 - line 20, column 76): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof Normal) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof SkipRecompute) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
        };
        if (x instanceof ForceRecompute) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
        };
        if (x instanceof ForceRecomputeSkipInvalidate) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))));
        };
        if (x instanceof ForceRecomputeAll) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value))));
        };
        throw new Error("Failed pattern match at WasmWorker.WorkerRequest (line 20, column 1 - line 20, column 76): " + [ x.constructor.name ]);
    }
};
var tofuJSONStateUpdateStrategy = {
    writeImpl: (function () {
        var $415 = TofuJSON.writeImpl(TofuJSON.tofuJSONVariant()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "ForceRecompute";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "ForceRecomputeAll";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "ForceRecomputeSkipInvalidate";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "Normal";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "SkipRecompute";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil))))))(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "ForceRecompute";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "ForceRecomputeAll";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "ForceRecomputeSkipInvalidate";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "Normal";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "SkipRecompute";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))))));
        var $416 = Kishimen.genericSumToVariant(genericStateUpdateStrategy)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "Normal";
            }
        })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "SkipRecompute";
            }
        })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "ForceRecompute";
            }
        })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "ForceRecomputeSkipInvalidate";
            }
        })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "ForceRecomputeAll";
            }
        })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "ForceRecomputeSkipInvalidate";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "ForceRecomputeAll";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "ForceRecompute";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "ForceRecomputeSkipInvalidate";
            }
        }))({
            reflectSymbol: function () {
                return "ForceRecomputeAll";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SkipRecompute";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "ForceRecomputeSkipInvalidate";
            }
        }))({
            reflectSymbol: function () {
                return "ForceRecomputeAll";
            }
        }))({
            reflectSymbol: function () {
                return "ForceRecompute";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "Normal";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SkipRecompute";
            }
        }))({
            reflectSymbol: function () {
                return "ForceRecomputeSkipInvalidate";
            }
        }))({
            reflectSymbol: function () {
                return "ForceRecomputeAll";
            }
        }))({
            reflectSymbol: function () {
                return "ForceRecompute";
            }
        })));
        return function ($417) {
            return $415($416($417));
        };
    })(),
    readImpl: (function () {
        var $418 = Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Kishimen.variantToGenericSum(genericStateUpdateStrategy)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "Normal";
            }
        })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "SkipRecompute";
            }
        })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "ForceRecompute";
            }
        })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "ForceRecomputeSkipInvalidate";
            }
        })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "ForceRecomputeAll";
            }
        })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "ForceRecomputeSkipInvalidate";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "ForceRecomputeAll";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "ForceRecompute";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "ForceRecomputeSkipInvalidate";
            }
        }))({
            reflectSymbol: function () {
                return "ForceRecomputeAll";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SkipRecompute";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "ForceRecomputeSkipInvalidate";
            }
        }))({
            reflectSymbol: function () {
                return "ForceRecomputeAll";
            }
        }))({
            reflectSymbol: function () {
                return "ForceRecompute";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "Normal";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SkipRecompute";
            }
        }))({
            reflectSymbol: function () {
                return "ForceRecomputeSkipInvalidate";
            }
        }))({
            reflectSymbol: function () {
                return "ForceRecomputeAll";
            }
        }))({
            reflectSymbol: function () {
                return "ForceRecompute";
            }
        }))));
        var $419 = TofuJSON.readImpl(TofuJSON.tofuJSONVariant()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "ForceRecompute";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "ForceRecomputeAll";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "ForceRecomputeSkipInvalidate";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "Normal";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "SkipRecompute";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil))))))(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "ForceRecompute";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "ForceRecomputeAll";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "ForceRecomputeSkipInvalidate";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "Normal";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "SkipRecompute";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))))));
        return function ($420) {
            return $418($419($420));
        };
    })()
};
var genericStateUpdate = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new AccessTokenState(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new ShowSocExpPercentagesInCostsState(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return new SelectedComponentsState(x.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return new ActiveComponentFiltersState(x.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
            return new ActiveRefComponentFiltersState(x.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))) {
            return new SelectedElementsState(x.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))) {
            return new ActiveElementFiltersState(x.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))) {
            return new ActiveRefElementFiltersState(x.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))))) {
            return new ActiveRefProjectsState(x.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))))) {
            return new AdminetIntegrationActiveState(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))))))) {
            return new ActivateLibraryTabState(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr)))))))))) {
            return new DixonMeasurementFollowFilteringState(x.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        throw new Error("Failed pattern match at WasmWorker.WorkerRequest (line 67, column 1 - line 67, column 60): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof AccessTokenState) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof ShowSocExpPercentagesInCostsState) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof SelectedComponentsState) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
        };
        if (x instanceof ActiveComponentFiltersState) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))));
        };
        if (x instanceof ActiveRefComponentFiltersState) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))));
        };
        if (x instanceof SelectedElementsState) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))));
        };
        if (x instanceof ActiveElementFiltersState) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))))));
        };
        if (x instanceof ActiveRefElementFiltersState) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))))));
        };
        if (x instanceof ActiveRefProjectsState) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))))))));
        };
        if (x instanceof AdminetIntegrationActiveState) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))))))));
        };
        if (x instanceof ActivateLibraryTabState) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))))))))));
        };
        if (x instanceof DixonMeasurementFollowFilteringState) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0)))))))))));
        };
        throw new Error("Failed pattern match at WasmWorker.WorkerRequest (line 67, column 1 - line 67, column 60): " + [ x.constructor.name ]);
    }
};
var tofuJSONStateUpdate = {
    writeImpl: (function () {
        var $421 = TofuJSON.writeImpl(TofuJSON.tofuJSONVariant()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "AccessTokenState";
            }
        })(TofuJSON.readString)()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "ActivateLibraryTabState";
            }
        })(TofuJSON.readMaybe(TofuJSON.readString))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "ActiveComponentFiltersState";
            }
        })(TofuJSON.readArray(CostEstimation_Filters.tofuJSONEstimationComponentPropertyFilter))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "ActiveElementFiltersState";
            }
        })(TofuJSON.readArray(CostEstimation_Filters.tofuJSONElementsDataPropertyFilter))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "ActiveRefComponentFiltersState";
            }
        })(TofuJSON.readArray(CostEstimation_Filters.tofuJSONEstimationComponentPropertyFilter))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "ActiveRefElementFiltersState";
            }
        })(TofuJSON.readArray(CostEstimation_Filters.tofuJSONElementsDataPropertyFilter))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "ActiveRefProjectsState";
            }
        })(TofuJSON.readArray(Types_Project.tofuJSONProjectId))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "AdminetIntegrationActiveState";
            }
        })(TofuJSON.readBoolean)()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "DixonMeasurementFollowFilteringState";
            }
        })(TofuJSON.readMaybe(TofuJSON.readBoolean))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "SelectedComponentsState";
            }
        })(TofuJSON.readArray(Types_Component.tofuJSONComponentId))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "SelectedElementsState";
            }
        })(TofuJSON.readArray(Types_Element.tofuJSONElementId))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "ShowSocExpPercentagesInCostsState";
            }
        })(TofuJSON.readBoolean)()(TofuJSON.readVariantNil)))))))))))))(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "AccessTokenState";
            }
        })(TofuJSON.readString)()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "ActivateLibraryTabState";
            }
        })(TofuJSON.readMaybe(TofuJSON.readString))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "ActiveComponentFiltersState";
            }
        })(TofuJSON.readArray(CostEstimation_Filters.tofuJSONEstimationComponentPropertyFilter))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "ActiveElementFiltersState";
            }
        })(TofuJSON.readArray(CostEstimation_Filters.tofuJSONElementsDataPropertyFilter))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "ActiveRefComponentFiltersState";
            }
        })(TofuJSON.readArray(CostEstimation_Filters.tofuJSONEstimationComponentPropertyFilter))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "ActiveRefElementFiltersState";
            }
        })(TofuJSON.readArray(CostEstimation_Filters.tofuJSONElementsDataPropertyFilter))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "ActiveRefProjectsState";
            }
        })(TofuJSON.readArray(Types_Project.tofuJSONProjectId))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "AdminetIntegrationActiveState";
            }
        })(TofuJSON.readBoolean)()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "DixonMeasurementFollowFilteringState";
            }
        })(TofuJSON.readMaybe(TofuJSON.readBoolean))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "SelectedComponentsState";
            }
        })(TofuJSON.readArray(Types_Component.tofuJSONComponentId))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "SelectedElementsState";
            }
        })(TofuJSON.readArray(Types_Element.tofuJSONElementId))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "ShowSocExpPercentagesInCostsState";
            }
        })(TofuJSON.readBoolean)()(TofuJSON.nilWriteForeignVariant))))))))))))));
        var $422 = Kishimen.genericSumToVariant(genericStateUpdate)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "AccessTokenState";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "ShowSocExpPercentagesInCostsState";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "SelectedComponentsState";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "ActiveComponentFiltersState";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "ActiveRefComponentFiltersState";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "SelectedElementsState";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "ActiveElementFiltersState";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "ActiveRefElementFiltersState";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "ActiveRefProjectsState";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "AdminetIntegrationActiveState";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "ActivateLibraryTabState";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "DixonMeasurementFollowFilteringState";
            }
        })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "ActivateLibraryTabState";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "DixonMeasurementFollowFilteringState";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "AdminetIntegrationActiveState";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "DixonMeasurementFollowFilteringState";
            }
        }))({
            reflectSymbol: function () {
                return "ActivateLibraryTabState";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "ActiveRefProjectsState";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "DixonMeasurementFollowFilteringState";
            }
        }))({
            reflectSymbol: function () {
                return "AdminetIntegrationActiveState";
            }
        }))({
            reflectSymbol: function () {
                return "ActivateLibraryTabState";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "ActiveRefElementFiltersState";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "DixonMeasurementFollowFilteringState";
            }
        }))({
            reflectSymbol: function () {
                return "AdminetIntegrationActiveState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefProjectsState";
            }
        }))({
            reflectSymbol: function () {
                return "ActivateLibraryTabState";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "ActiveElementFiltersState";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "DixonMeasurementFollowFilteringState";
            }
        }))({
            reflectSymbol: function () {
                return "AdminetIntegrationActiveState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefProjectsState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefElementFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActivateLibraryTabState";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SelectedElementsState";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "DixonMeasurementFollowFilteringState";
            }
        }))({
            reflectSymbol: function () {
                return "AdminetIntegrationActiveState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefProjectsState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefElementFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveElementFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActivateLibraryTabState";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "ActiveRefComponentFiltersState";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SelectedElementsState";
            }
        }))({
            reflectSymbol: function () {
                return "DixonMeasurementFollowFilteringState";
            }
        }))({
            reflectSymbol: function () {
                return "AdminetIntegrationActiveState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefProjectsState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefElementFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveElementFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActivateLibraryTabState";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "ActiveComponentFiltersState";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SelectedElementsState";
            }
        }))({
            reflectSymbol: function () {
                return "DixonMeasurementFollowFilteringState";
            }
        }))({
            reflectSymbol: function () {
                return "AdminetIntegrationActiveState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefProjectsState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefElementFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefComponentFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveElementFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActivateLibraryTabState";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SelectedComponentsState";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SelectedElementsState";
            }
        }))({
            reflectSymbol: function () {
                return "DixonMeasurementFollowFilteringState";
            }
        }))({
            reflectSymbol: function () {
                return "AdminetIntegrationActiveState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefProjectsState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefElementFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefComponentFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveElementFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveComponentFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActivateLibraryTabState";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "ShowSocExpPercentagesInCostsState";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SelectedElementsState";
            }
        }))({
            reflectSymbol: function () {
                return "SelectedComponentsState";
            }
        }))({
            reflectSymbol: function () {
                return "DixonMeasurementFollowFilteringState";
            }
        }))({
            reflectSymbol: function () {
                return "AdminetIntegrationActiveState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefProjectsState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefElementFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefComponentFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveElementFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveComponentFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActivateLibraryTabState";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "AccessTokenState";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "ShowSocExpPercentagesInCostsState";
            }
        }))({
            reflectSymbol: function () {
                return "SelectedElementsState";
            }
        }))({
            reflectSymbol: function () {
                return "SelectedComponentsState";
            }
        }))({
            reflectSymbol: function () {
                return "DixonMeasurementFollowFilteringState";
            }
        }))({
            reflectSymbol: function () {
                return "AdminetIntegrationActiveState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefProjectsState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefElementFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefComponentFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveElementFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveComponentFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActivateLibraryTabState";
            }
        })));
        return function ($423) {
            return $421($422($423));
        };
    })(),
    readImpl: (function () {
        var $424 = Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Kishimen.variantToGenericSum(genericStateUpdate)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "AccessTokenState";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "ShowSocExpPercentagesInCostsState";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "SelectedComponentsState";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "ActiveComponentFiltersState";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "ActiveRefComponentFiltersState";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "SelectedElementsState";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "ActiveElementFiltersState";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "ActiveRefElementFiltersState";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "ActiveRefProjectsState";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "AdminetIntegrationActiveState";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "ActivateLibraryTabState";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "DixonMeasurementFollowFilteringState";
            }
        })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "ActivateLibraryTabState";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "DixonMeasurementFollowFilteringState";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "AdminetIntegrationActiveState";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "DixonMeasurementFollowFilteringState";
            }
        }))({
            reflectSymbol: function () {
                return "ActivateLibraryTabState";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "ActiveRefProjectsState";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "DixonMeasurementFollowFilteringState";
            }
        }))({
            reflectSymbol: function () {
                return "AdminetIntegrationActiveState";
            }
        }))({
            reflectSymbol: function () {
                return "ActivateLibraryTabState";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "ActiveRefElementFiltersState";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "DixonMeasurementFollowFilteringState";
            }
        }))({
            reflectSymbol: function () {
                return "AdminetIntegrationActiveState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefProjectsState";
            }
        }))({
            reflectSymbol: function () {
                return "ActivateLibraryTabState";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "ActiveElementFiltersState";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "DixonMeasurementFollowFilteringState";
            }
        }))({
            reflectSymbol: function () {
                return "AdminetIntegrationActiveState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefProjectsState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefElementFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActivateLibraryTabState";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SelectedElementsState";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "DixonMeasurementFollowFilteringState";
            }
        }))({
            reflectSymbol: function () {
                return "AdminetIntegrationActiveState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefProjectsState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefElementFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveElementFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActivateLibraryTabState";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "ActiveRefComponentFiltersState";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SelectedElementsState";
            }
        }))({
            reflectSymbol: function () {
                return "DixonMeasurementFollowFilteringState";
            }
        }))({
            reflectSymbol: function () {
                return "AdminetIntegrationActiveState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefProjectsState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefElementFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveElementFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActivateLibraryTabState";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "ActiveComponentFiltersState";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SelectedElementsState";
            }
        }))({
            reflectSymbol: function () {
                return "DixonMeasurementFollowFilteringState";
            }
        }))({
            reflectSymbol: function () {
                return "AdminetIntegrationActiveState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefProjectsState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefElementFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefComponentFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveElementFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActivateLibraryTabState";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SelectedComponentsState";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SelectedElementsState";
            }
        }))({
            reflectSymbol: function () {
                return "DixonMeasurementFollowFilteringState";
            }
        }))({
            reflectSymbol: function () {
                return "AdminetIntegrationActiveState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefProjectsState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefElementFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefComponentFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveElementFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveComponentFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActivateLibraryTabState";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "ShowSocExpPercentagesInCostsState";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SelectedElementsState";
            }
        }))({
            reflectSymbol: function () {
                return "SelectedComponentsState";
            }
        }))({
            reflectSymbol: function () {
                return "DixonMeasurementFollowFilteringState";
            }
        }))({
            reflectSymbol: function () {
                return "AdminetIntegrationActiveState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefProjectsState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefElementFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefComponentFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveElementFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveComponentFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActivateLibraryTabState";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "AccessTokenState";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "ShowSocExpPercentagesInCostsState";
            }
        }))({
            reflectSymbol: function () {
                return "SelectedElementsState";
            }
        }))({
            reflectSymbol: function () {
                return "SelectedComponentsState";
            }
        }))({
            reflectSymbol: function () {
                return "DixonMeasurementFollowFilteringState";
            }
        }))({
            reflectSymbol: function () {
                return "AdminetIntegrationActiveState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefProjectsState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefElementFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveRefComponentFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveElementFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActiveComponentFiltersState";
            }
        }))({
            reflectSymbol: function () {
                return "ActivateLibraryTabState";
            }
        }))));
        var $425 = TofuJSON.readImpl(TofuJSON.tofuJSONVariant()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "AccessTokenState";
            }
        })(TofuJSON.readString)()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "ActivateLibraryTabState";
            }
        })(TofuJSON.readMaybe(TofuJSON.readString))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "ActiveComponentFiltersState";
            }
        })(TofuJSON.readArray(CostEstimation_Filters.tofuJSONEstimationComponentPropertyFilter))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "ActiveElementFiltersState";
            }
        })(TofuJSON.readArray(CostEstimation_Filters.tofuJSONElementsDataPropertyFilter))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "ActiveRefComponentFiltersState";
            }
        })(TofuJSON.readArray(CostEstimation_Filters.tofuJSONEstimationComponentPropertyFilter))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "ActiveRefElementFiltersState";
            }
        })(TofuJSON.readArray(CostEstimation_Filters.tofuJSONElementsDataPropertyFilter))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "ActiveRefProjectsState";
            }
        })(TofuJSON.readArray(Types_Project.tofuJSONProjectId))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "AdminetIntegrationActiveState";
            }
        })(TofuJSON.readBoolean)()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "DixonMeasurementFollowFilteringState";
            }
        })(TofuJSON.readMaybe(TofuJSON.readBoolean))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "SelectedComponentsState";
            }
        })(TofuJSON.readArray(Types_Component.tofuJSONComponentId))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "SelectedElementsState";
            }
        })(TofuJSON.readArray(Types_Element.tofuJSONElementId))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "ShowSocExpPercentagesInCostsState";
            }
        })(TofuJSON.readBoolean)()(TofuJSON.readVariantNil)))))))))))))(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "AccessTokenState";
            }
        })(TofuJSON.readString)()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "ActivateLibraryTabState";
            }
        })(TofuJSON.readMaybe(TofuJSON.readString))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "ActiveComponentFiltersState";
            }
        })(TofuJSON.readArray(CostEstimation_Filters.tofuJSONEstimationComponentPropertyFilter))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "ActiveElementFiltersState";
            }
        })(TofuJSON.readArray(CostEstimation_Filters.tofuJSONElementsDataPropertyFilter))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "ActiveRefComponentFiltersState";
            }
        })(TofuJSON.readArray(CostEstimation_Filters.tofuJSONEstimationComponentPropertyFilter))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "ActiveRefElementFiltersState";
            }
        })(TofuJSON.readArray(CostEstimation_Filters.tofuJSONElementsDataPropertyFilter))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "ActiveRefProjectsState";
            }
        })(TofuJSON.readArray(Types_Project.tofuJSONProjectId))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "AdminetIntegrationActiveState";
            }
        })(TofuJSON.readBoolean)()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "DixonMeasurementFollowFilteringState";
            }
        })(TofuJSON.readMaybe(TofuJSON.readBoolean))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "SelectedComponentsState";
            }
        })(TofuJSON.readArray(Types_Component.tofuJSONComponentId))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "SelectedElementsState";
            }
        })(TofuJSON.readArray(Types_Element.tofuJSONElementId))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "ShowSocExpPercentagesInCostsState";
            }
        })(TofuJSON.readBoolean)()(TofuJSON.nilWriteForeignVariant))))))))))))));
        return function ($426) {
            return $424($425($426));
        };
    })()
};
var tofuJSONWorkerRequest = {
    writeImpl: (function () {
        var $427 = TofuJSON.writeImpl(TofuJSON.tofuJSONVariant()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "RefreshClassificationClasses";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "RefreshClassificationGroups";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "RefreshEstimationComponentsData";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "RefreshEstimationCostClasses";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "RefreshEstimationElementSpecsData";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "RefreshEstimationElementsData";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "RefreshEstimationProjects";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "RefreshEstimationProjectsData";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "RefreshEstimationResourcesData";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "RefreshPlanningCostGroups";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "RefreshProjectLocations";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "RefreshReferenceProjectsData";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "RefreshSubprojects";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "SetRequestParamsOfDeps";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "state_key";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "state_key";
            }
        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "StateUpdate";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "state";
            }
        })(TofuJSON.readArray(tofuJSONStateUpdate))(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "strategy";
            }
        })(TofuJSON.readMaybe(tofuJSONStateUpdateStrategy))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "state";
            }
        })(TofuJSON.readArray(tofuJSONStateUpdate))(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "strategy";
            }
        })(TofuJSON.readMaybe(tofuJSONStateUpdateStrategy))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "SubscribeWorkerState";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "state_key";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "state_key";
            }
        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantNil)))))))))))))))))(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "RefreshClassificationClasses";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "RefreshClassificationGroups";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "RefreshEstimationComponentsData";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "RefreshEstimationCostClasses";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "RefreshEstimationElementSpecsData";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "RefreshEstimationElementsData";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "RefreshEstimationProjects";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "RefreshEstimationProjectsData";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "RefreshEstimationResourcesData";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "RefreshPlanningCostGroups";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "RefreshProjectLocations";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "RefreshReferenceProjectsData";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "RefreshSubprojects";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "SetRequestParamsOfDeps";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "state_key";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "state_key";
            }
        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "StateUpdate";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "state";
            }
        })(TofuJSON.readArray(tofuJSONStateUpdate))(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "strategy";
            }
        })(TofuJSON.readMaybe(tofuJSONStateUpdateStrategy))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "state";
            }
        })(TofuJSON.readArray(tofuJSONStateUpdate))(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "strategy";
            }
        })(TofuJSON.readMaybe(tofuJSONStateUpdateStrategy))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "SubscribeWorkerState";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "state_key";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "state_key";
            }
        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.nilWriteForeignVariant))))))))))))))))));
        var $428 = Kishimen.genericSumToVariant(genericWorkerRequest)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "RefreshEstimationProjectsData";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "RefreshReferenceProjectsData";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "RefreshClassificationClasses";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "RefreshClassificationGroups";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "RefreshSubprojects";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "RefreshProjectLocations";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "RefreshPlanningCostGroups";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "RefreshEstimationComponentsData";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "RefreshEstimationElementsData";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "RefreshEstimationElementSpecsData";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "RefreshEstimationResourcesData";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "RefreshEstimationCostClasses";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "RefreshEstimationProjects";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "SetRequestParamsOfDeps";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "SubscribeWorkerState";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "StateUpdate";
            }
        })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SubscribeWorkerState";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "StateUpdate";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SetRequestParamsOfDeps";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SubscribeWorkerState";
            }
        }))({
            reflectSymbol: function () {
                return "StateUpdate";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "RefreshEstimationProjects";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SubscribeWorkerState";
            }
        }))({
            reflectSymbol: function () {
                return "StateUpdate";
            }
        }))({
            reflectSymbol: function () {
                return "SetRequestParamsOfDeps";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "RefreshEstimationCostClasses";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SubscribeWorkerState";
            }
        }))({
            reflectSymbol: function () {
                return "StateUpdate";
            }
        }))({
            reflectSymbol: function () {
                return "SetRequestParamsOfDeps";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationProjects";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "RefreshEstimationResourcesData";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SubscribeWorkerState";
            }
        }))({
            reflectSymbol: function () {
                return "StateUpdate";
            }
        }))({
            reflectSymbol: function () {
                return "SetRequestParamsOfDeps";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationProjects";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationCostClasses";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "RefreshEstimationElementSpecsData";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SubscribeWorkerState";
            }
        }))({
            reflectSymbol: function () {
                return "StateUpdate";
            }
        }))({
            reflectSymbol: function () {
                return "SetRequestParamsOfDeps";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationResourcesData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationProjects";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationCostClasses";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "RefreshEstimationElementsData";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SubscribeWorkerState";
            }
        }))({
            reflectSymbol: function () {
                return "StateUpdate";
            }
        }))({
            reflectSymbol: function () {
                return "SetRequestParamsOfDeps";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationResourcesData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationProjects";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationElementSpecsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationCostClasses";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "RefreshEstimationComponentsData";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SubscribeWorkerState";
            }
        }))({
            reflectSymbol: function () {
                return "StateUpdate";
            }
        }))({
            reflectSymbol: function () {
                return "SetRequestParamsOfDeps";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationResourcesData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationProjects";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationElementsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationElementSpecsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationCostClasses";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "RefreshPlanningCostGroups";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SubscribeWorkerState";
            }
        }))({
            reflectSymbol: function () {
                return "StateUpdate";
            }
        }))({
            reflectSymbol: function () {
                return "SetRequestParamsOfDeps";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationResourcesData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationProjects";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationElementsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationElementSpecsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationCostClasses";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationComponentsData";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "RefreshProjectLocations";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SubscribeWorkerState";
            }
        }))({
            reflectSymbol: function () {
                return "StateUpdate";
            }
        }))({
            reflectSymbol: function () {
                return "SetRequestParamsOfDeps";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshPlanningCostGroups";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationResourcesData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationProjects";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationElementsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationElementSpecsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationCostClasses";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationComponentsData";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "RefreshSubprojects";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SubscribeWorkerState";
            }
        }))({
            reflectSymbol: function () {
                return "StateUpdate";
            }
        }))({
            reflectSymbol: function () {
                return "SetRequestParamsOfDeps";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshProjectLocations";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshPlanningCostGroups";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationResourcesData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationProjects";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationElementsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationElementSpecsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationCostClasses";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationComponentsData";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "RefreshClassificationGroups";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SubscribeWorkerState";
            }
        }))({
            reflectSymbol: function () {
                return "StateUpdate";
            }
        }))({
            reflectSymbol: function () {
                return "SetRequestParamsOfDeps";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshSubprojects";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshProjectLocations";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshPlanningCostGroups";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationResourcesData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationProjects";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationElementsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationElementSpecsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationCostClasses";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationComponentsData";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "RefreshClassificationClasses";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SubscribeWorkerState";
            }
        }))({
            reflectSymbol: function () {
                return "StateUpdate";
            }
        }))({
            reflectSymbol: function () {
                return "SetRequestParamsOfDeps";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshSubprojects";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshProjectLocations";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshPlanningCostGroups";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationResourcesData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationProjects";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationElementsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationElementSpecsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationCostClasses";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationComponentsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshClassificationGroups";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "RefreshReferenceProjectsData";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SubscribeWorkerState";
            }
        }))({
            reflectSymbol: function () {
                return "StateUpdate";
            }
        }))({
            reflectSymbol: function () {
                return "SetRequestParamsOfDeps";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshSubprojects";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshProjectLocations";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshPlanningCostGroups";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationResourcesData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationProjects";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationElementsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationElementSpecsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationCostClasses";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationComponentsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshClassificationGroups";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshClassificationClasses";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "RefreshEstimationProjectsData";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SubscribeWorkerState";
            }
        }))({
            reflectSymbol: function () {
                return "StateUpdate";
            }
        }))({
            reflectSymbol: function () {
                return "SetRequestParamsOfDeps";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshSubprojects";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshReferenceProjectsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshProjectLocations";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshPlanningCostGroups";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationResourcesData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationProjects";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationElementsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationElementSpecsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationCostClasses";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationComponentsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshClassificationGroups";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshClassificationClasses";
            }
        })));
        return function ($429) {
            return $427($428($429));
        };
    })(),
    readImpl: (function () {
        var $430 = Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Kishimen.variantToGenericSum(genericWorkerRequest)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "RefreshEstimationProjectsData";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "RefreshReferenceProjectsData";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "RefreshClassificationClasses";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "RefreshClassificationGroups";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "RefreshSubprojects";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "RefreshProjectLocations";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "RefreshPlanningCostGroups";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "RefreshEstimationComponentsData";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "RefreshEstimationElementsData";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "RefreshEstimationElementSpecsData";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "RefreshEstimationResourcesData";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "RefreshEstimationCostClasses";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "RefreshEstimationProjects";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "SetRequestParamsOfDeps";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "SubscribeWorkerState";
            }
        })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "StateUpdate";
            }
        })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SubscribeWorkerState";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "StateUpdate";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SetRequestParamsOfDeps";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SubscribeWorkerState";
            }
        }))({
            reflectSymbol: function () {
                return "StateUpdate";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "RefreshEstimationProjects";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SubscribeWorkerState";
            }
        }))({
            reflectSymbol: function () {
                return "StateUpdate";
            }
        }))({
            reflectSymbol: function () {
                return "SetRequestParamsOfDeps";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "RefreshEstimationCostClasses";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SubscribeWorkerState";
            }
        }))({
            reflectSymbol: function () {
                return "StateUpdate";
            }
        }))({
            reflectSymbol: function () {
                return "SetRequestParamsOfDeps";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationProjects";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "RefreshEstimationResourcesData";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SubscribeWorkerState";
            }
        }))({
            reflectSymbol: function () {
                return "StateUpdate";
            }
        }))({
            reflectSymbol: function () {
                return "SetRequestParamsOfDeps";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationProjects";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationCostClasses";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "RefreshEstimationElementSpecsData";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SubscribeWorkerState";
            }
        }))({
            reflectSymbol: function () {
                return "StateUpdate";
            }
        }))({
            reflectSymbol: function () {
                return "SetRequestParamsOfDeps";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationResourcesData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationProjects";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationCostClasses";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "RefreshEstimationElementsData";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SubscribeWorkerState";
            }
        }))({
            reflectSymbol: function () {
                return "StateUpdate";
            }
        }))({
            reflectSymbol: function () {
                return "SetRequestParamsOfDeps";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationResourcesData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationProjects";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationElementSpecsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationCostClasses";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "RefreshEstimationComponentsData";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SubscribeWorkerState";
            }
        }))({
            reflectSymbol: function () {
                return "StateUpdate";
            }
        }))({
            reflectSymbol: function () {
                return "SetRequestParamsOfDeps";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationResourcesData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationProjects";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationElementsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationElementSpecsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationCostClasses";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "RefreshPlanningCostGroups";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SubscribeWorkerState";
            }
        }))({
            reflectSymbol: function () {
                return "StateUpdate";
            }
        }))({
            reflectSymbol: function () {
                return "SetRequestParamsOfDeps";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationResourcesData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationProjects";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationElementsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationElementSpecsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationCostClasses";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationComponentsData";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "RefreshProjectLocations";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SubscribeWorkerState";
            }
        }))({
            reflectSymbol: function () {
                return "StateUpdate";
            }
        }))({
            reflectSymbol: function () {
                return "SetRequestParamsOfDeps";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshPlanningCostGroups";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationResourcesData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationProjects";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationElementsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationElementSpecsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationCostClasses";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationComponentsData";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "RefreshSubprojects";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SubscribeWorkerState";
            }
        }))({
            reflectSymbol: function () {
                return "StateUpdate";
            }
        }))({
            reflectSymbol: function () {
                return "SetRequestParamsOfDeps";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshProjectLocations";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshPlanningCostGroups";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationResourcesData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationProjects";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationElementsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationElementSpecsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationCostClasses";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationComponentsData";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "RefreshClassificationGroups";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SubscribeWorkerState";
            }
        }))({
            reflectSymbol: function () {
                return "StateUpdate";
            }
        }))({
            reflectSymbol: function () {
                return "SetRequestParamsOfDeps";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshSubprojects";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshProjectLocations";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshPlanningCostGroups";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationResourcesData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationProjects";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationElementsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationElementSpecsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationCostClasses";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationComponentsData";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "RefreshClassificationClasses";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SubscribeWorkerState";
            }
        }))({
            reflectSymbol: function () {
                return "StateUpdate";
            }
        }))({
            reflectSymbol: function () {
                return "SetRequestParamsOfDeps";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshSubprojects";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshProjectLocations";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshPlanningCostGroups";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationResourcesData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationProjects";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationElementsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationElementSpecsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationCostClasses";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationComponentsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshClassificationGroups";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "RefreshReferenceProjectsData";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SubscribeWorkerState";
            }
        }))({
            reflectSymbol: function () {
                return "StateUpdate";
            }
        }))({
            reflectSymbol: function () {
                return "SetRequestParamsOfDeps";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshSubprojects";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshProjectLocations";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshPlanningCostGroups";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationResourcesData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationProjects";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationElementsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationElementSpecsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationCostClasses";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationComponentsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshClassificationGroups";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshClassificationClasses";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "RefreshEstimationProjectsData";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "SubscribeWorkerState";
            }
        }))({
            reflectSymbol: function () {
                return "StateUpdate";
            }
        }))({
            reflectSymbol: function () {
                return "SetRequestParamsOfDeps";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshSubprojects";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshReferenceProjectsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshProjectLocations";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshPlanningCostGroups";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationResourcesData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationProjects";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationElementsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationElementSpecsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationCostClasses";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshEstimationComponentsData";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshClassificationGroups";
            }
        }))({
            reflectSymbol: function () {
                return "RefreshClassificationClasses";
            }
        }))));
        var $431 = TofuJSON.readImpl(TofuJSON.tofuJSONVariant()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "RefreshClassificationClasses";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "RefreshClassificationGroups";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "RefreshEstimationComponentsData";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "RefreshEstimationCostClasses";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "RefreshEstimationElementSpecsData";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "RefreshEstimationElementsData";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "RefreshEstimationProjects";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "RefreshEstimationProjectsData";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "RefreshEstimationResourcesData";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "RefreshPlanningCostGroups";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "RefreshProjectLocations";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "RefreshReferenceProjectsData";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "RefreshSubprojects";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "SetRequestParamsOfDeps";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "state_key";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "state_key";
            }
        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "StateUpdate";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "state";
            }
        })(TofuJSON.readArray(tofuJSONStateUpdate))(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "strategy";
            }
        })(TofuJSON.readMaybe(tofuJSONStateUpdateStrategy))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "state";
            }
        })(TofuJSON.readArray(tofuJSONStateUpdate))(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "strategy";
            }
        })(TofuJSON.readMaybe(tofuJSONStateUpdateStrategy))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "SubscribeWorkerState";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "state_key";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "state_key";
            }
        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantNil)))))))))))))))))(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "RefreshClassificationClasses";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "RefreshClassificationGroups";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "RefreshEstimationComponentsData";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "RefreshEstimationCostClasses";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "RefreshEstimationElementSpecsData";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "RefreshEstimationElementsData";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "RefreshEstimationProjects";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "RefreshEstimationProjectsData";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "RefreshEstimationResourcesData";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "RefreshPlanningCostGroups";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "RefreshProjectLocations";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "RefreshReferenceProjectsData";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "RefreshSubprojects";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "SetRequestParamsOfDeps";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "state_key";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "request_params";
            }
        })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "state_key";
            }
        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "StateUpdate";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "state";
            }
        })(TofuJSON.readArray(tofuJSONStateUpdate))(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "strategy";
            }
        })(TofuJSON.readMaybe(tofuJSONStateUpdateStrategy))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "state";
            }
        })(TofuJSON.readArray(tofuJSONStateUpdate))(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "strategy";
            }
        })(TofuJSON.readMaybe(tofuJSONStateUpdateStrategy))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "SubscribeWorkerState";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "state_key";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "state_key";
            }
        })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.nilWriteForeignVariant))))))))))))))))));
        return function ($432) {
            return $430($431($432));
        };
    })()
};
var eqStateUpdateStrategy = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Normal && y instanceof Normal) {
                return true;
            };
            if (x instanceof SkipRecompute && y instanceof SkipRecompute) {
                return true;
            };
            if (x instanceof ForceRecompute && y instanceof ForceRecompute) {
                return true;
            };
            if (x instanceof ForceRecomputeSkipInvalidate && y instanceof ForceRecomputeSkipInvalidate) {
                return true;
            };
            if (x instanceof ForceRecomputeAll && y instanceof ForceRecomputeAll) {
                return true;
            };
            return false;
        };
    }
};
var eqStateUpdate = {
    eq: function (x) {
        return function (y) {
            if (x instanceof AccessTokenState && y instanceof AccessTokenState) {
                return x.value0 === y.value0;
            };
            if (x instanceof ShowSocExpPercentagesInCostsState && y instanceof ShowSocExpPercentagesInCostsState) {
                return x.value0 === y.value0;
            };
            if (x instanceof SelectedComponentsState && y instanceof SelectedComponentsState) {
                return Data_Eq.eq(Data_Eq.eqArray(Types_Component.eqComponentId))(x.value0)(y.value0);
            };
            if (x instanceof ActiveComponentFiltersState && y instanceof ActiveComponentFiltersState) {
                return Data_Eq.eq(Data_Eq.eqArray(CostEstimation_Filters.eqEstimationComponentPropertyFilter))(x.value0)(y.value0);
            };
            if (x instanceof ActiveRefComponentFiltersState && y instanceof ActiveRefComponentFiltersState) {
                return Data_Eq.eq(Data_Eq.eqArray(CostEstimation_Filters.eqEstimationComponentPropertyFilter))(x.value0)(y.value0);
            };
            if (x instanceof SelectedElementsState && y instanceof SelectedElementsState) {
                return Data_Eq.eq(Data_Eq.eqArray(Types_Element.eqElementId))(x.value0)(y.value0);
            };
            if (x instanceof ActiveElementFiltersState && y instanceof ActiveElementFiltersState) {
                return Data_Eq.eq(Data_Eq.eqArray(CostEstimation_Filters.eqElementsDataPropertyFilter))(x.value0)(y.value0);
            };
            if (x instanceof ActiveRefElementFiltersState && y instanceof ActiveRefElementFiltersState) {
                return Data_Eq.eq(Data_Eq.eqArray(CostEstimation_Filters.eqElementsDataPropertyFilter))(x.value0)(y.value0);
            };
            if (x instanceof ActiveRefProjectsState && y instanceof ActiveRefProjectsState) {
                return Data_Eq.eq(Data_Eq.eqArray(Types_Project.eqProjectId))(x.value0)(y.value0);
            };
            if (x instanceof AdminetIntegrationActiveState && y instanceof AdminetIntegrationActiveState) {
                return x.value0 === y.value0;
            };
            if (x instanceof ActivateLibraryTabState && y instanceof ActivateLibraryTabState) {
                return Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(x.value0)(y.value0);
            };
            if (x instanceof DixonMeasurementFollowFilteringState && y instanceof DixonMeasurementFollowFilteringState) {
                return Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqBoolean))(x.value0)(y.value0);
            };
            return false;
        };
    }
};
var eqWorkerRequest = {
    eq: function (x) {
        return function (y) {
            if (x instanceof RefreshEstimationProjectsData && y instanceof RefreshEstimationProjectsData) {
                return Data_Eq.eq(Types_Project.eqProjectId)(x.value0.request_params)(y.value0.request_params);
            };
            if (x instanceof RefreshReferenceProjectsData && y instanceof RefreshReferenceProjectsData) {
                return Data_Eq.eq(Types_Project.eqProjectId)(x.value0.request_params)(y.value0.request_params);
            };
            if (x instanceof RefreshClassificationClasses && y instanceof RefreshClassificationClasses) {
                return Data_Eq.eq(Types_Project.eqProjectId)(x.value0.request_params)(y.value0.request_params);
            };
            if (x instanceof RefreshClassificationGroups && y instanceof RefreshClassificationGroups) {
                return Data_Eq.eq(Types_Project.eqProjectId)(x.value0.request_params)(y.value0.request_params);
            };
            if (x instanceof RefreshSubprojects && y instanceof RefreshSubprojects) {
                return Data_Eq.eq(Types_Project.eqProjectId)(x.value0.request_params)(y.value0.request_params);
            };
            if (x instanceof RefreshProjectLocations && y instanceof RefreshProjectLocations) {
                return Data_Eq.eq(Types_Project.eqProjectId)(x.value0.request_params)(y.value0.request_params);
            };
            if (x instanceof RefreshPlanningCostGroups && y instanceof RefreshPlanningCostGroups) {
                return Data_Eq.eq(Types_Project.eqProjectId)(x.value0.request_params)(y.value0.request_params);
            };
            if (x instanceof RefreshEstimationComponentsData && y instanceof RefreshEstimationComponentsData) {
                return Data_Eq.eq(Types_Project.eqProjectId)(x.value0.request_params)(y.value0.request_params);
            };
            if (x instanceof RefreshEstimationElementsData && y instanceof RefreshEstimationElementsData) {
                return Data_Eq.eq(Types_Project.eqProjectId)(x.value0.request_params)(y.value0.request_params);
            };
            if (x instanceof RefreshEstimationElementSpecsData && y instanceof RefreshEstimationElementSpecsData) {
                return Data_Eq.eq(Types_Project.eqProjectId)(x.value0.request_params)(y.value0.request_params);
            };
            if (x instanceof RefreshEstimationResourcesData && y instanceof RefreshEstimationResourcesData) {
                return Data_Eq.eq(Types_Project.eqProjectId)(x.value0.request_params)(y.value0.request_params);
            };
            if (x instanceof RefreshEstimationCostClasses && y instanceof RefreshEstimationCostClasses) {
                return Data_Eq.eq(Types_Project.eqProjectId)(x.value0.request_params)(y.value0.request_params);
            };
            if (x instanceof RefreshEstimationProjects && y instanceof RefreshEstimationProjects) {
                return true;
            };
            if (x instanceof SetRequestParamsOfDeps && y instanceof SetRequestParamsOfDeps) {
                return Data_Eq.eq(Types_Project.eqProjectId)(x.value0.request_params)(y.value0.request_params) && x.value0.state_key === y.value0.state_key;
            };
            if (x instanceof SubscribeWorkerState && y instanceof SubscribeWorkerState) {
                return x.value0.state_key === y.value0.state_key;
            };
            if (x instanceof StateUpdate && y instanceof StateUpdate) {
                return Data_Eq.eq(Data_Eq.eqArray(eqStateUpdate))(x.value0.state)(y.value0.state) && Data_Eq.eq(Data_Maybe.eqMaybe(eqStateUpdateStrategy))(x.value0.strategy)(y.value0.strategy);
            };
            return false;
        };
    }
};
module.exports = {
    Normal: Normal,
    SkipRecompute: SkipRecompute,
    ForceRecompute: ForceRecompute,
    ForceRecomputeSkipInvalidate: ForceRecomputeSkipInvalidate,
    ForceRecomputeAll: ForceRecomputeAll,
    RefreshEstimationProjectsData: RefreshEstimationProjectsData,
    RefreshReferenceProjectsData: RefreshReferenceProjectsData,
    RefreshClassificationClasses: RefreshClassificationClasses,
    RefreshClassificationGroups: RefreshClassificationGroups,
    RefreshSubprojects: RefreshSubprojects,
    RefreshProjectLocations: RefreshProjectLocations,
    RefreshPlanningCostGroups: RefreshPlanningCostGroups,
    RefreshEstimationComponentsData: RefreshEstimationComponentsData,
    RefreshEstimationElementsData: RefreshEstimationElementsData,
    RefreshEstimationElementSpecsData: RefreshEstimationElementSpecsData,
    RefreshEstimationResourcesData: RefreshEstimationResourcesData,
    RefreshEstimationCostClasses: RefreshEstimationCostClasses,
    RefreshEstimationProjects: RefreshEstimationProjects,
    SetRequestParamsOfDeps: SetRequestParamsOfDeps,
    SubscribeWorkerState: SubscribeWorkerState,
    StateUpdate: StateUpdate,
    AccessTokenState: AccessTokenState,
    ShowSocExpPercentagesInCostsState: ShowSocExpPercentagesInCostsState,
    SelectedComponentsState: SelectedComponentsState,
    ActiveComponentFiltersState: ActiveComponentFiltersState,
    ActiveRefComponentFiltersState: ActiveRefComponentFiltersState,
    SelectedElementsState: SelectedElementsState,
    ActiveElementFiltersState: ActiveElementFiltersState,
    ActiveRefElementFiltersState: ActiveRefElementFiltersState,
    ActiveRefProjectsState: ActiveRefProjectsState,
    AdminetIntegrationActiveState: AdminetIntegrationActiveState,
    ActivateLibraryTabState: ActivateLibraryTabState,
    DixonMeasurementFollowFilteringState: DixonMeasurementFollowFilteringState,
    genericStateUpdateStrategy: genericStateUpdateStrategy,
    tofuJSONStateUpdateStrategy: tofuJSONStateUpdateStrategy,
    eqStateUpdateStrategy: eqStateUpdateStrategy,
    genericWorkerRequest: genericWorkerRequest,
    tofuJSONWorkerRequest: tofuJSONWorkerRequest,
    eqWorkerRequest: eqWorkerRequest,
    genericStateUpdate: genericStateUpdate,
    tofuJSONStateUpdate: tofuJSONStateUpdate,
    eqStateUpdate: eqStateUpdate
};
