// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var projectsTableStyles = Box.boxStyle([ new Box.Width(TacoConstants.pageContentWidth), Box.Style.create(TacoTable_Styles.tableHoverStyles), new Box.Selector(".__TacoTable .table-header", [ Box.PosTop.create(new TacoConstants.CustomSize("3rem")) ]) ]);
var newProjectTableStyles = Box.boxStyle([ Box.FlexColumn.value, Box.Width.create(new TacoConstants.CustomSize("100%")), Box.MaxWidth.create(new TacoConstants.CustomSize("1536px")), Box.Height.create(new TacoConstants.CustomSize("100%")) ]);
var newEstimationProjectsContainerStyles = Box.boxStyle([ Box.FlexColumn.value, Box.AlignCenter.value, Box.Width.create(new TacoConstants.CustomSize("100%")), Box.Height.create(new TacoConstants.CustomSize("100%")) ]);
var filterStyles = Box.boxStyle([ Box.Width.create(new TacoConstants.CustomSize("16rem")), new Box.PaddingBottom(TacoConstants.M.value) ]);
var estimationProjectsContainerStyles = Box.boxStyle([ Box.FlexColumn.value, Box.AlignCenter.value, Box.Width.create(new TacoConstants.CustomSize("100%")) ]);
module.exports = {
    estimationProjectsContainerStyles: estimationProjectsContainerStyles,
    newEstimationProjectsContainerStyles: newEstimationProjectsContainerStyles,
    projectsTableStyles: projectsTableStyles,
    filterStyles: filterStyles,
    newProjectTableStyles: newProjectTableStyles
};
