// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Control_Alt = require("../Control.Alt/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Lazy = require("../Control.Lazy/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Effect_Exception = require("../Effect.Exception/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var Text_Parsing_StringParser = require("../Text.Parsing.StringParser/index.js");
var Text_Parsing_StringParser_CodeUnits = require("../Text.Parsing.StringParser.CodeUnits/index.js");
var Text_Parsing_StringParser_Combinators = require("../Text.Parsing.StringParser.Combinators/index.js");
var MemoText = (function () {
    function MemoText(value0) {
        this.value0 = value0;
    };
    MemoText.create = function (value0) {
        return new MemoText(value0);
    };
    return MemoText;
})();
var MemoCalc = (function () {
    function MemoCalc(value0) {
        this.value0 = value0;
    };
    MemoCalc.create = function (value0) {
        return new MemoCalc(value0);
    };
    return MemoCalc;
})();
var textExpr = Control_Bind.bind(Text_Parsing_StringParser.bindParser)(Text_Parsing_StringParser_CodeUnits.regex("[^\\d\\(]+"))(function (content) {
    return Control_Applicative.pure(Text_Parsing_StringParser.applicativeParser)(new MemoText(content));
});
var showMemoData = {
    show: function (v) {
        if (v instanceof MemoText) {
            return "(MemoText " + (v.value0 + ")");
        };
        if (v instanceof MemoCalc) {
            return "(MemoCalc " + (v.value0 + ")");
        };
        throw new Error("Failed pattern match at MemoCalculator (line 27, column 1 - line 29, column 47): " + [ v.constructor.name ]);
    }
};
var printMemoData = function (v) {
    if (v instanceof MemoText) {
        return v.value0;
    };
    if (v instanceof MemoCalc) {
        return v.value0;
    };
    throw new Error("Failed pattern match at MemoCalculator (line 84, column 1 - line 84, column 36): " + [ v.constructor.name ]);
};
var printMemo = (function () {
    var $33 = Data_Foldable.fold(Data_List_Types.foldableList)(Data_Monoid.monoidString);
    var $34 = Data_Functor.map(Data_List_Types.functorList)(printMemoData);
    return function ($35) {
        return $33($34($35));
    };
})();
var evaluateMemoData = function (v) {
    if (v instanceof MemoText) {
        return v;
    };
    if (v instanceof MemoCalc) {
        var split = Data_String_Common.split("=")(v.value0);
        var v1 = Data_Array.head(split);
        if (v1 instanceof Data_Maybe.Just) {
            return Effect_Unsafe.unsafePerformEffect(function __do() {
                var mResult = Effect_Exception["try"](function () {
                    return $foreign["_unsafeEvalExpr"](v1.value0);
                })();
                if (mResult instanceof Data_Either.Left) {
                    return new MemoCalc(v.value0);
                };
                if (mResult instanceof Data_Either.Right) {
                    var $22 = Data_String_CodeUnits.contains(" ")(v.value0);
                    if ($22) {
                        return MemoCalc.create(v1.value0 + ("= " + mResult.value0));
                    };
                    return MemoCalc.create(v1.value0 + ("=" + mResult.value0));
                };
                throw new Error("Failed pattern match at MemoCalculator (line 64, column 12 - line 75, column 52): " + [ mResult.constructor.name ]);
            });
        };
        if (v1 instanceof Data_Maybe.Nothing) {
            return new MemoCalc(v.value0);
        };
        throw new Error("Failed pattern match at MemoCalculator (line 59, column 3 - line 79, column 20): " + [ v1.constructor.name ]);
    };
    throw new Error("Failed pattern match at MemoCalculator (line 53, column 1 - line 53, column 41): " + [ v.constructor.name ]);
};
var evaluateMemo = Data_Functor.map(Data_List_Types.functorList)(evaluateMemoData);
var eqMemoData = {
    eq: function (x) {
        return function (y) {
            if (x instanceof MemoText && y instanceof MemoText) {
                return x.value0 === y.value0;
            };
            if (x instanceof MemoCalc && y instanceof MemoCalc) {
                return x.value0 === y.value0;
            };
            return false;
        };
    }
};
var calcExpr = Control_Bind.bind(Text_Parsing_StringParser.bindParser)(Text_Parsing_StringParser_CodeUnits.regex("[\\d +*\\(\\)-/=.]+"))(function (content) {
    var $32 = Data_String_CodeUnits.contains("=")(content);
    if ($32) {
        return Control_Applicative.pure(Text_Parsing_StringParser.applicativeParser)(new MemoCalc(content));
    };
    return Control_Applicative.pure(Text_Parsing_StringParser.applicativeParser)(new MemoText(content));
});
var memoExpr = Control_Lazy.fix(Text_Parsing_StringParser.lazyParser)(function (v) {
    return Control_Alt.alt(Text_Parsing_StringParser.altParser)(Data_Functor.voidLeft(Text_Parsing_StringParser.functorParser)(Text_Parsing_StringParser_CodeUnits.eof)(Data_List_Types.Nil.value))(Control_Bind.bind(Text_Parsing_StringParser.bindParser)(Text_Parsing_StringParser_Combinators.many(textExpr))(function (texts) {
        return Control_Bind.bind(Text_Parsing_StringParser.bindParser)(Text_Parsing_StringParser_Combinators.many(calcExpr))(function (calc) {
            return Control_Bind.bind(Text_Parsing_StringParser.bindParser)(memoExpr)(function (rest) {
                return Control_Applicative.pure(Text_Parsing_StringParser.applicativeParser)(Data_Semigroup.append(Data_List_Types.semigroupList)(texts)(Data_Semigroup.append(Data_List_Types.semigroupList)(calc)(rest)));
            });
        });
    }));
});
var parseMemo = Text_Parsing_StringParser.runParser(memoExpr);
module.exports = {
    MemoText: MemoText,
    MemoCalc: MemoCalc,
    calcExpr: calcExpr,
    textExpr: textExpr,
    memoExpr: memoExpr,
    parseMemo: parseMemo,
    evaluateMemoData: evaluateMemoData,
    evaluateMemo: evaluateMemo,
    printMemoData: printMemoData,
    printMemo: printMemo,
    eqMemoData: eqMemoData,
    showMemoData: showMemoData,
    "_unsafeEvalExpr": $foreign["_unsafeEvalExpr"]
};
