const dateFns = require("date-fns");

exports.startOfMonth = dateFns.startOfMonth;
exports.endOfMonth = dateFns.endOfMonth;
exports._format = dateFns.format;
exports._subMonths = dateFns.subMonths;
exports.getMonth = dateFns.getMonth;
exports.getYear = dateFns.getYear;
exports._differenceInCalendarMonths = dateFns.differenceInCalendarMonths;
exports._differenceInCalendarDays = dateFns.differenceInCalendarDays;
exports._addMonths = dateFns.addMonths;
exports._min = dateFns.min;
exports._isBefore = dateFns.isBefore;
exports._isAfter = dateFns.isAfter;
exports._isSameDay = dateFns.isSameDay;

exports.getTime = () => new Date().getTime();
