// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var StyleProperties = require("../StyleProperties/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoInput_Styles = require("../TacoInput.Styles/index.js");
var selectWrapperStyles = Box.boxStyle([ Box.Padding.create(new TacoConstants.CustomSize("1px")) ]);
var selectStyles = function (props) {
    return Box.boxStyle([ Box.Style.create(TacoInput_Styles.inputStyles({
        align: "left",
        currency: false,
        error: false,
        loading: false,
        textarea: false,
        expandWhenActive: false,
        rendering: props.rendering,
        hasCustomComponent: false,
        height: Data_Maybe.Nothing.value,
        color: Data_Maybe.Nothing.value,
        fontWeight: Data_Maybe.Nothing.value
    })), new Box.PaddingRight(TacoConstants.L.value), Box.Style.create(React_Basic_Emotion.css()({
        appearance: StyleProperties.none
    })), new Box.Selector("&:-moz-focusring", [ new Box.ForegroundColor(TacoConstants.Transparent.value), Box.Style.create(React_Basic_Emotion.css()({
        textShadow: React_Basic_Emotion.str("0 0 0 " + TacoConstants.colorSchemeToStr(TacoConstants.GrayDarkest.value)),
        transition: React_Basic_Emotion.str("color 0s")
    })) ]) ]);
};
var selectContainerStyles = Box.boxStyle([ Box.Style.create(TacoInput_Styles.inputContainerStyles({
    rendering: TacoInput_Styles.DefaultRendering.value,
    focused: false,
    loading: false,
    error: false,
    hasCustomComponent: false,
    textarea: false
})), Box.Width.create(new TacoConstants.CustomSize("100%")), new Box.Position(Box.Relative.value) ]);
var arrowStyles = Box.boxStyle([ new Box.Position(Box.Absolute.value), new Box.PosTop(TacoConstants.XXS.value), new Box.PosRight(TacoConstants.XS.value), Box.Style.create(React_Basic_Emotion.css()({
    pointerEvents: StyleProperties.none
})) ]);
module.exports = {
    selectWrapperStyles: selectWrapperStyles,
    selectContainerStyles: selectContainerStyles,
    selectStyles: selectStyles,
    arrowStyles: arrowStyles
};
