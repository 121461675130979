import { useRef, useEffect } from "react";
import { Viewer } from "@mescius/activereportsjs-react";
import { Rdl as ARJS } from "@mescius/activereportsjs/core";
import { ReportProps } from "../types";

export const C200Report = ({ report, params }: ReportProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const viewerRef = useRef<any>(null);

  const renderReport = (report: ARJS.Report) => {
    viewerRef.current.Viewer.open(report, {
      ReportParams: params,
    });
  };

  useEffect(() => {
    if (report) {
      renderReport(report);
    }
  }, [renderReport, report]);

  return (
    <div className={"w-full h-full"}>
      <Viewer theme="ActiveReports" ref={viewerRef} />
    </div>
  );
};
