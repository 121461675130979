// Generated by purs version 0.14.5
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var SidebarActive = (function () {
    function SidebarActive() {

    };
    SidebarActive.value = new SidebarActive();
    return SidebarActive;
})();
var SidebarInactive = (function () {
    function SidebarInactive() {

    };
    SidebarInactive.value = new SidebarInactive();
    return SidebarInactive;
})();
var IconLeft = (function () {
    function IconLeft() {

    };
    IconLeft.value = new IconLeft();
    return IconLeft;
})();
var IconRight = (function () {
    function IconRight() {

    };
    IconRight.value = new IconRight();
    return IconRight;
})();
var Text = (function () {
    function Text() {

    };
    Text.value = new Text();
    return Text;
})();
var Outlined = (function () {
    function Outlined() {

    };
    Outlined.value = new Outlined();
    return Outlined;
})();
var Filled = (function () {
    function Filled() {

    };
    Filled.value = new Filled();
    return Filled;
})();
var Link = (function () {
    function Link() {

    };
    Link.value = new Link();
    return Link;
})();
var Sidebar = (function () {
    function Sidebar(value0) {
        this.value0 = value0;
    };
    Sidebar.create = function (value0) {
        return new Sidebar(value0);
    };
    return Sidebar;
})();
var Left = (function () {
    function Left() {

    };
    Left.value = new Left();
    return Left;
})();
var Center = (function () {
    function Center() {

    };
    Center.value = new Center();
    return Center;
})();
var Right = (function () {
    function Right() {

    };
    Right.value = new Right();
    return Right;
})();
var eqSidebarStatus = {
    eq: function (x) {
        return function (y) {
            if (x instanceof SidebarActive && y instanceof SidebarActive) {
                return true;
            };
            if (x instanceof SidebarInactive && y instanceof SidebarInactive) {
                return true;
            };
            return false;
        };
    }
};
var eqIconAlign = {
    eq: function (x) {
        return function (y) {
            if (x instanceof IconLeft && y instanceof IconLeft) {
                return true;
            };
            if (x instanceof IconRight && y instanceof IconRight) {
                return true;
            };
            return false;
        };
    }
};
var eqButtonStyle = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Text && y instanceof Text) {
                return true;
            };
            if (x instanceof Outlined && y instanceof Outlined) {
                return true;
            };
            if (x instanceof Filled && y instanceof Filled) {
                return true;
            };
            if (x instanceof Link && y instanceof Link) {
                return true;
            };
            if (x instanceof Sidebar && y instanceof Sidebar) {
                return Data_Eq.eq(eqSidebarStatus)(x.value0)(y.value0);
            };
            return false;
        };
    }
};
var alignToStr = function (v) {
    if (v instanceof Left) {
        return "flex-start";
    };
    if (v instanceof Center) {
        return "center";
    };
    if (v instanceof Right) {
        return "flex-end";
    };
    throw new Error("Failed pattern match at TacoButton.Types (line 64, column 1 - line 64, column 36): " + [ v.constructor.name ]);
};
module.exports = {
    SidebarActive: SidebarActive,
    SidebarInactive: SidebarInactive,
    Text: Text,
    Outlined: Outlined,
    Filled: Filled,
    Link: Link,
    Sidebar: Sidebar,
    Left: Left,
    Center: Center,
    Right: Right,
    IconLeft: IconLeft,
    IconRight: IconRight,
    alignToStr: alignToStr,
    eqSidebarStatus: eqSidebarStatus,
    eqButtonStyle: eqButtonStyle,
    eqIconAlign: eqIconAlign
};
