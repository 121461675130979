// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoAutocomplete = require("../TacoAutocomplete/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Alert = require("../Types.Alert/index.js");
var Types_Project = require("../Types.Project/index.js");
var styleSet = TacoTable_Styles.foldingTableStyleSet;
var refProjectsTableInstance = TacoTable.mkTableStateless(TofuHooks.getNameVoid)("RefProjectsTableInstance");
var mkRefProjectRowData = function (v) {
    return function (columns) {
        return function (v1) {
            return {
                rowData: {
                    selectedRefProjects: v.selectedRefProjects,
                    project: v1
                },
                rowKey: Data_Show.show(Types_Project.showProjectId)(v1.id),
                onClick: function (v2) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                },
                className: "",
                foldingElement: Data_Maybe.Nothing.value,
                columns: columns
            };
        };
    };
};
var refProjectsTable = TofuHooks.mkHookComponent("RefProjectsTable")(function (v) {
    return function __do() {
        var appDispatch = StateHooks.useDispatch();
        var projectCurrency = (function () {
            var v1 = Data_Array.find(function (p) {
                return Data_Eq.eq(Types_Project.eqProjectId)(p.id)(v.projectId);
            })(v.estimationProjects);
            if (v1 instanceof Data_Maybe.Just) {
                return v1.value0.currency;
            };
            if (v1 instanceof Data_Maybe.Nothing) {
                return "EUR";
            };
            throw new Error("Failed pattern match at RefProjectsTable (line 61, column 23 - line 63, column 25): " + [ v1.constructor.name ]);
        })();
        var sameCurrencyProjects = Data_Array.filter(function (p) {
            return p.currency === projectCurrency;
        })(v.estimationProjects);
        var filteredProjects = Data_Array.filter(function (p) {
            return !Data_Array.elem(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "version";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "subprojects";
                }
            })(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "projectId";
                }
            })(Types_Project.eqProjectId))()({
                reflectSymbol: function () {
                    return "name";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Project.eqSubprojectId))()({
                reflectSymbol: function () {
                    return "countInMeasurementTotal";
                }
            })(Data_Eq.eqBoolean))()({
                reflectSymbol: function () {
                    return "code";
                }
            })(Data_Eq.eqString)))))()({
                reflectSymbol: function () {
                    return "state";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "projectGroup";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "programType";
                }
            })(Data_Eq.eqInt))()({
                reflectSymbol: function () {
                    return "name";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "isReportingProject";
                }
            })(Data_Eq.eqBoolean))()({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Project.eqProjectId))()({
                reflectSymbol: function () {
                    return "currency";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "createdDate";
                }
            })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                reflectSymbol: function () {
                    return "code";
                }
            })(Types_Project.eqProjectCode))()({
                reflectSymbol: function () {
                    return "applicationMode";
                }
            })(Data_Eq.eqString)))(p)(v.refProjects);
        })(sameCurrencyProjects);
        var deleteButton = function (v1) {
            return TacoButton.component()()({
                icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconCloseCrossV1.value),
                onClick: new Data_Maybe.Just(function __do() {
                    Control_Applicative.when(Effect.applicativeEffect)(Data_Array.elem(Types_Project.eqProjectId)(v1.project.id)(v.selectedRefProjects))(v.setSelectedRefProjects(v1.project.id))();
                    return appDispatch(new Actions.DeleteReferenceProjectRequest({
                        projectId: v.projectId,
                        refProjectId: v1.project.id
                    }))();
                })
            });
        };
        var checkbox = function (v1) {
            return TacoCheckbox.component()()({
                className: "ref-project-filter-checkbox",
                isChecked: Data_Array.elem(Types_Project.eqProjectId)(v1.project.id)(v.selectedRefProjects),
                onToggle: TacoCheckbox.NoEvent.create(v.setSelectedRefProjects(v1.project.id)),
                labelText: Data_Maybe.Nothing.value,
                testId: "ref-project-filter-checkbox-" + Data_Show.show(Types_Project.showProjectId)(v1.project.id)
            });
        };
        var refProjectsTableColumns = [ {
            key: "checkbox",
            label: React_Basic_DOM.text(""),
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], checkbox),
            sortProperty: Data_Maybe.Nothing.value,
            width: new TacoTable_Types.Flex(1),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "code",
            label: React_Basic_DOM.text("Tunnus"),
            cell: TacoTable_Types.PlainTextCell.create((function () {
                var $37 = Data_Newtype.un()(Types_Project.ProjectCode);
                return function ($38) {
                    return $37((function (v1) {
                        return v1.project.code;
                    })($38));
                };
            })()),
            sortProperty: Data_Maybe.Nothing.value,
            width: new TacoTable_Types.Flex(3),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "name",
            label: React_Basic_DOM.text("Nimi"),
            cell: new TacoTable_Types.PlainTextCell(function (v1) {
                return v1.project.name;
            }),
            sortProperty: Data_Maybe.Nothing.value,
            width: new TacoTable_Types.Flex(7),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "version",
            label: React_Basic_DOM.text("Versio"),
            cell: TacoTable_Types.JSXCell.create([ TacoTable_Types.Numeric.value ])(function ($39) {
                return React_Basic_DOM.text((function (v1) {
                    return v1.project.version;
                })($39));
            }),
            sortProperty: Data_Maybe.Nothing.value,
            width: new TacoTable_Types.Flex(2),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "delete",
            label: React_Basic_DOM.text(""),
            cell: new TacoTable_Types.JSXCell([  ], deleteButton),
            sortProperty: Data_Maybe.Nothing.value,
            width: new TacoTable_Types.Flex(1),
            headerJSX: Data_Maybe.Nothing.value
        } ];
        var heading = Box.box("headingWrapper")([ new Box.PaddingBottom(TacoConstants.M.value) ])(Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM_Generated.h3_(Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM.text("Viitehankkeet")))));
        var container = function (children) {
            return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                className: "__RefProjectsTable",
                css: Box.boxStyle((function () {
                    if (v.containerFlex) {
                        return [ new Box.FlexGrow(1) ];
                    };
                    return [  ];
                })()),
                children: children
            });
        };
        var autocomplete = TacoAutocomplete.component()()({
            sections: [ {
                text: Data_Maybe.Nothing.value,
                list: Data_Functor.mapFlipped(Data_Functor.functorArray)(filteredProjects)(function (p) {
                    return {
                        id: Data_Show.show(Types_Project.showProjectId)(p.id),
                        text: Data_Newtype.un()(Types_Project.ProjectCode)(p.code) + (" " + (p.name + (" (Versio " + (p.version + ")"))))
                    };
                })
            } ],
            clearButton: false,
            onSelect: function (selected$prime) {
                if (selected$prime instanceof Data_Maybe.Just) {
                    var v1 = Data_Int.fromString(selected$prime.value0.id);
                    if (v1 instanceof Data_Maybe.Just) {
                        return function __do() {
                            appDispatch(new Actions.AddReferenceProjectRequest({
                                projectId: v.projectId,
                                refProjectId: v1.value0
                            }))();
                            return v.setSelectedRefProjects(v1.value0)();
                        };
                    };
                    if (v1 instanceof Data_Maybe.Nothing) {
                        return appDispatch(new Actions.AddAlert(Types_Alert.AlertError.value, "Sis\xe4inen virhe lis\xe4tt\xe4ess\xe4 viitekohdetta. Ongelman toistuessa ole yhteydess\xe4 tukeen."));
                    };
                    throw new Error("Failed pattern match at RefProjectsTable (line 106, column 13 - line 113, column 105): " + [ v1.constructor.name ]);
                };
                if (selected$prime instanceof Data_Maybe.Nothing) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                };
                throw new Error("Failed pattern match at RefProjectsTable (line 104, column 9 - line 114, column 31): " + [ selected$prime.constructor.name ]);
            },
            selected: Data_Maybe.Nothing.value,
            placeholder: "Lis\xe4\xe4 hanke",
            testId: "add-ref-projects"
        });
        return container([ (function () {
            if (v.hideHeader) {
                return React_Basic.empty;
            };
            return heading;
        })(), (function () {
            if (v.autoCompleteFirst) {
                return autocomplete;
            };
            return React_Basic.empty;
        })(), TacoTable.tableStateless(TofuHooks.getNameVoid)(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "selectedRefProjects";
            }
        })(Data_Eq.eqArray(Types_Project.eqProjectId)))()({
            reflectSymbol: function () {
                return "project";
            }
        })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "version";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "subprojects";
            }
        })(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.eqProjectId))()({
            reflectSymbol: function () {
                return "name";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "id";
            }
        })(Types_Project.eqSubprojectId))()({
            reflectSymbol: function () {
                return "countInMeasurementTotal";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "code";
            }
        })(Data_Eq.eqString)))))()({
            reflectSymbol: function () {
                return "state";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "projectGroup";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "programType";
            }
        })(Data_Eq.eqInt))()({
            reflectSymbol: function () {
                return "name";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "isReportingProject";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "id";
            }
        })(Types_Project.eqProjectId))()({
            reflectSymbol: function () {
                return "currency";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "createdDate";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "code";
            }
        })(Types_Project.eqProjectCode))()({
            reflectSymbol: function () {
                return "applicationMode";
            }
        })(Data_Eq.eqString)))))(refProjectsTableInstance)({
            rows: Data_Functor.map(Data_Functor.functorArray)(mkRefProjectRowData(v)(refProjectsTableColumns))(v.refProjects),
            sortProperty: Data_Maybe.Nothing.value,
            sortingOrder: TacoTable_Types.Ascending.value,
            onSort: function (v1) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            },
            columns: refProjectsTableColumns,
            showHeader: true,
            styleSet: styleSet,
            onResize: function (v1) {
                return function (v2) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                };
            },
            columnSizeOffsets: Data_Maybe.Nothing.value
        }), (function () {
            var $35 = !v.autoCompleteFirst;
            if ($35) {
                return autocomplete;
            };
            return React_Basic.empty;
        })() ]);
    };
});
module.exports = {
    refProjectsTable: refProjectsTable
};
