// Generated by purs version 0.14.5
"use strict";
var Control_Alt = require("../Control.Alt/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_ST_Internal = require("../Control.Monad.ST.Internal/index.js");
var Control_Plus = require("../Control.Plus/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Array_ST = require("../Data.Array.ST/index.js");
var Data_Array_ST_Iterator = require("../Data.Array.ST.Iterator/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_List = require("../Data.List/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var separate = function (dict) {
    return dict.separate;
};
var mapToList = function (dictOrd) {
    return Data_Map_Internal.toUnfoldable(Data_List_Types.unfoldableList);
};
var compactableMaybe = {
    compact: Control_Bind.join(Data_Maybe.bindMaybe),
    separate: function (v) {
        if (v instanceof Data_Maybe.Nothing) {
            return {
                left: Data_Maybe.Nothing.value,
                right: Data_Maybe.Nothing.value
            };
        };
        if (v instanceof Data_Maybe.Just) {
            if (v.value0 instanceof Data_Either.Left) {
                return {
                    left: new Data_Maybe.Just(v.value0.value0),
                    right: Data_Maybe.Nothing.value
                };
            };
            if (v.value0 instanceof Data_Either.Right) {
                return {
                    left: Data_Maybe.Nothing.value,
                    right: new Data_Maybe.Just(v.value0.value0)
                };
            };
            throw new Error("Failed pattern match at Data.Compactable (line 91, column 23 - line 93, column 48): " + [ v.value0.constructor.name ]);
        };
        throw new Error("Failed pattern match at Data.Compactable (line 87, column 1 - line 93, column 48): " + [ v.constructor.name ]);
    }
};
var compactableMap = function (dictOrd) {
    return {
        compact: (function () {
            var select = function (v) {
                return function (m) {
                    return Data_Map_Internal.alter(dictOrd)(Data_Function["const"](v.value1))(v.value0)(m);
                };
            };
            var $73 = Data_Foldable.foldr(Data_List_Types.foldableList)(select)(Data_Map_Internal.empty);
            var $74 = mapToList(dictOrd);
            return function ($75) {
                return $73($74($75));
            };
        })(),
        separate: (function () {
            var select = function (v) {
                return function (v1) {
                    if (v.value1 instanceof Data_Either.Left) {
                        return {
                            left: Data_Map_Internal.insert(dictOrd)(v.value0)(v.value1.value0)(v1.left),
                            right: v1.right
                        };
                    };
                    if (v.value1 instanceof Data_Either.Right) {
                        return {
                            left: v1.left,
                            right: Data_Map_Internal.insert(dictOrd)(v.value0)(v.value1.value0)(v1.right)
                        };
                    };
                    throw new Error("Failed pattern match at Data.Compactable (line 142, column 44 - line 144, column 63): " + [ v.value1.constructor.name ]);
                };
            };
            var $76 = Data_Foldable.foldr(Data_List_Types.foldableList)(select)({
                left: Data_Map_Internal.empty,
                right: Data_Map_Internal.empty
            });
            var $77 = mapToList(dictOrd);
            return function ($78) {
                return $76($77($78));
            };
        })()
    };
};
var compactableList = {
    compact: Data_List.catMaybes,
    separate: (function () {
        var go = function (acc) {
            return function (v) {
                if (v instanceof Data_Either.Left) {
                    return {
                        left: Control_Alt.alt(Data_List_Types.altList)(acc.left)(Control_Applicative.pure(Data_List_Types.applicativeList)(v.value0)),
                        right: acc.right
                    };
                };
                if (v instanceof Data_Either.Right) {
                    return {
                        right: Control_Alt.alt(Data_List_Types.altList)(acc.right)(Control_Applicative.pure(Data_List_Types.applicativeList)(v.value0)),
                        left: acc.left
                    };
                };
                throw new Error("Failed pattern match at Data.Compactable (line 131, column 14 - line 133, column 54): " + [ v.constructor.name ]);
            };
        };
        return Data_Foldable.foldl(Data_List_Types.foldableList)(go)({
            left: Control_Plus.empty(Data_List_Types.plusList),
            right: Control_Plus.empty(Data_List_Types.plusList)
        });
    })()
};
var compactableEither = function (dictMonoid) {
    return {
        compact: function (v) {
            if (v instanceof Data_Either.Left) {
                return new Data_Either.Left(v.value0);
            };
            if (v instanceof Data_Either.Right) {
                if (v.value0 instanceof Data_Maybe.Just) {
                    return new Data_Either.Right(v.value0.value0);
                };
                if (v.value0 instanceof Data_Maybe.Nothing) {
                    return new Data_Either.Left(Data_Monoid.mempty(dictMonoid));
                };
                throw new Error("Failed pattern match at Data.Compactable (line 97, column 23 - line 99, column 27): " + [ v.value0.constructor.name ]);
            };
            throw new Error("Failed pattern match at Data.Compactable (line 95, column 1 - line 104, column 53): " + [ v.constructor.name ]);
        },
        separate: function (v) {
            if (v instanceof Data_Either.Left) {
                return {
                    left: new Data_Either.Left(v.value0),
                    right: new Data_Either.Left(v.value0)
                };
            };
            if (v instanceof Data_Either.Right) {
                if (v.value0 instanceof Data_Either.Left) {
                    return {
                        left: new Data_Either.Right(v.value0.value0),
                        right: new Data_Either.Left(Data_Monoid.mempty(dictMonoid))
                    };
                };
                if (v.value0 instanceof Data_Either.Right) {
                    return {
                        left: new Data_Either.Left(Data_Monoid.mempty(dictMonoid)),
                        right: new Data_Either.Right(v.value0.value0)
                    };
                };
                throw new Error("Failed pattern match at Data.Compactable (line 102, column 24 - line 104, column 53): " + [ v.value0.constructor.name ]);
            };
            throw new Error("Failed pattern match at Data.Compactable (line 95, column 1 - line 104, column 53): " + [ v.constructor.name ]);
        }
    };
};
var compactableArray = {
    compact: function (xs) {
        return (function __do() {
            var result = Data_Array_ST["new"]();
            var iter = Data_Array_ST_Iterator.iterator(function (v) {
                return Data_Array.index(xs)(v);
            })();
            Data_Array_ST_Iterator.iterate(iter)((function () {
                var $79 = Data_Functor["void"](Control_Monad_ST_Internal.functorST);
                return function ($80) {
                    return $79((function (v) {
                        if (v instanceof Data_Maybe.Nothing) {
                            return Control_Applicative.pure(Control_Monad_ST_Internal.applicativeST)(0);
                        };
                        if (v instanceof Data_Maybe.Just) {
                            return Data_Array_ST.push(v.value0)(result);
                        };
                        throw new Error("Failed pattern match at Data.Compactable (line 111, column 34 - line 113, column 35): " + [ v.constructor.name ]);
                    })($80));
                };
            })())();
            return Data_Array_ST.unsafeFreeze(result)();
        })();
    },
    separate: function (xs) {
        return (function __do() {
            var ls = Data_Array_ST["new"]();
            var rs = Data_Array_ST["new"]();
            var iter = Data_Array_ST_Iterator.iterator(function (v) {
                return Data_Array.index(xs)(v);
            })();
            Data_Array_ST_Iterator.iterate(iter)((function () {
                var $81 = Data_Functor["void"](Control_Monad_ST_Internal.functorST);
                return function ($82) {
                    return $81((function (v) {
                        if (v instanceof Data_Either.Left) {
                            return Data_Array_ST.push(v.value0)(ls);
                        };
                        if (v instanceof Data_Either.Right) {
                            return Data_Array_ST.push(v.value0)(rs);
                        };
                        throw new Error("Failed pattern match at Data.Compactable (line 122, column 34 - line 124, column 31): " + [ v.constructor.name ]);
                    })($82));
                };
            })())();
            return Control_Apply.apply(Control_Monad_ST_Internal.applyST)(Data_Functor.map(Control_Monad_ST_Internal.functorST)(function (v) {
                return function (v1) {
                    return {
                        left: v,
                        right: v1
                    };
                };
            })(Data_Array_ST.unsafeFreeze(ls)))(Data_Array_ST.unsafeFreeze(rs))();
        })();
    }
};
var compactDefault = function (dictFunctor) {
    return function (dictCompactable) {
        var $83 = separate(dictCompactable);
        var $84 = Data_Functor.map(dictFunctor)(Data_Either.note(Data_Unit.unit));
        return function ($85) {
            return (function (v) {
                return v.right;
            })($83($84($85)));
        };
    };
};
var compact = function (dict) {
    return dict.compact;
};
var separateDefault = function (dictFunctor) {
    return function (dictCompactable) {
        return function (xs) {
            var swapEither = function (e) {
                if (e instanceof Data_Either.Left) {
                    return new Data_Either.Right(e.value0);
                };
                if (e instanceof Data_Either.Right) {
                    return new Data_Either.Left(e.value0);
                };
                throw new Error("Failed pattern match at Data.Compactable (line 83, column 20 - line 85, column 24): " + [ e.constructor.name ]);
            };
            return {
                left: compact(dictCompactable)(Data_Functor.map(dictFunctor)(function ($86) {
                    return Data_Either.hush(swapEither($86));
                })(xs)),
                right: compact(dictCompactable)(Data_Functor.map(dictFunctor)(Data_Either.hush)(xs))
            };
        };
    };
};
var bindMaybe = function (dictBind) {
    return function (dictCompactable) {
        return function (x) {
            var $87 = compact(dictCompactable);
            var $88 = Control_Bind.bind(dictBind)(x);
            return function ($89) {
                return $87($88($89));
            };
        };
    };
};
var bindEither = function (dictBind) {
    return function (dictCompactable) {
        return function (x) {
            var $90 = separate(dictCompactable);
            var $91 = Control_Bind.bind(dictBind)(x);
            return function ($92) {
                return $90($91($92));
            };
        };
    };
};
var applyMaybe = function (dictApply) {
    return function (dictCompactable) {
        return function (p) {
            var $93 = compact(dictCompactable);
            var $94 = Control_Apply.apply(dictApply)(p);
            return function ($95) {
                return $93($94($95));
            };
        };
    };
};
var applyEither = function (dictApply) {
    return function (dictCompactable) {
        return function (p) {
            var $96 = separate(dictCompactable);
            var $97 = Control_Apply.apply(dictApply)(p);
            return function ($98) {
                return $96($97($98));
            };
        };
    };
};
module.exports = {
    compact: compact,
    separate: separate,
    compactDefault: compactDefault,
    separateDefault: separateDefault,
    applyMaybe: applyMaybe,
    applyEither: applyEither,
    bindMaybe: bindMaybe,
    bindEither: bindEither,
    compactableMaybe: compactableMaybe,
    compactableEither: compactableEither,
    compactableArray: compactableArray,
    compactableList: compactableList,
    compactableMap: compactableMap
};
