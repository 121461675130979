import { Control, Controller, UseFieldArrayRemove } from "react-hook-form";
import { Button, Checkbox, IconCloseCrossV1, Input } from "@tocoman/ui";
import { AllUserData } from "./UserInformation";
import { TFunction } from "i18next";

type NewUserLineProps = {
  control: Control<AllUserData, object>;
  index: number;
  remove: UseFieldArrayRemove;
  watchEmail: string;
  t: TFunction;
};

export const NewUserLine = ({
  control,
  index,
  remove,
  watchEmail,
  t,
}: NewUserLineProps) => {
  return (
    <>
      <div className={"flex justify-between my-2 items-end"}>
        <Controller
          control={control}
          name={`newUsers.${index}.email`}
          rules={{ required: true }}
          render={({ field }) => <Input {...field} />}
          defaultValue={""}
        />
        <Controller
          control={control}
          name={`newUsers.${index}.superAdmin`}
          defaultValue={false}
          render={({ field: { value, onChange } }) => (
            <Checkbox
              className={"pb-1"}
              disabled={
                watchEmail !== undefined
                  ? !["@tocoman.com", "@admicom.fi"].some((email) =>
                      watchEmail.endsWith(email)
                    )
                  : true
              }
              checked={value}
              setChecked={onChange}
              label={t`form.superAdmin`}
            />
          )}
        />
        <Button
          className={"pb-1"}
          testId="removeLineIcon"
          variant="primary"
          color="normal"
          onClick={() => remove(index)}
          icon={IconCloseCrossV1}
        ></Button>
      </div>
    </>
  );
};
