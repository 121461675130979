// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Effect = require("../Effect/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var Grid = require("../Grid/index.js");
var Locale = require("../Locale/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var RequiredValidator = require("../RequiredValidator/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoSelect = require("../TacoSelect/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Income = require("../Types.Income/index.js");
var Types_JSDateString = require("../Types.JSDateString/index.js");
var UseForm = require("../UseForm/index.js");
var component = TofuHooks.mkHookComponent("AddIncomeItem")(function (props) {
    return function __do() {
        var v = TofuHooks.useState("")();
        var v1 = TofuHooks.useState("")();
        var v2 = TofuHooks.useState(0.0)();
        var v3 = TofuHooks.useState(Effect_Unsafe.unsafePerformEffect(Types_JSDateString.getJSDateStringToday))();
        var v4 = TofuHooks.useState(Data_Maybe.fromMaybe("")(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Functor.map(Data_Functor.functorFn)(Data_Newtype.un()(Types_Income.IncomeGroupCode))(function (v4) {
            return v4.code;
        }))(Data_Array.head(props.groups))))();
        var appDispatch = StateHooks.useDispatch();
        var v5 = TofuHooks.useState(false)();
        var resetForm = function __do() {
            v.value1(function (v6) {
                return "";
            })();
            v1.value1(function (v6) {
                return "";
            })();
            v2.value1(function (v6) {
                return 0.0;
            })();
            v3.value1(function (v6) {
                return Effect_Unsafe.unsafePerformEffect(Types_JSDateString.getJSDateStringToday);
            })();
            v4.value1(function (v6) {
                return Data_Maybe.fromMaybe("")(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Functor.map(Data_Functor.functorFn)(Data_Newtype.un()(Types_Income.IncomeGroupCode))(function (v7) {
                    return v7.code;
                }))(Data_Array.head(props.groups)));
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        };
        var openModal = v5.value1(function (v6) {
            return true;
        });
        var closeModal = v5.value1(function (v6) {
            return false;
        });
        TofuHooks.useEffect([ v5.value0 ])(resetForm)();
        var v6 = UseForm.useForm((function () {
            var params = {
                incomeItemCode: v.value0,
                description: v1.value0,
                sumWithoutVAT: v2.value0,
                dueDate: v3.value0,
                group: v4.value0
            };
            return function __do() {
                appDispatch(new Actions.AddIncomeItemsRequest({
                    projectId: props.projectId,
                    params: [ params ]
                }))();
                return closeModal();
            };
        })())();
        var sumWithoutVATInput = TacoInput.numberInput()()(Data_Eq.eqNumber)({
            value: v2.value0,
            label: "Summa (ilman ALV)",
            onChange: v2.value1,
            precision: 2,
            validator: RequiredValidator.requiredValidator,
            onValidationChange: v6.updateFieldValidity("sumWithoutVAT"),
            error: v6.isFieldError("sumWithoutVAT")
        });
        var submitBtn = TacoButton.component()()({
            submit: true,
            text: "Tallenna"
        });
        var mkGroupItem = function (group$prime) {
            return {
                value: Data_Newtype.un()(Types_Income.IncomeGroupCode)(group$prime.code),
                text: Data_Newtype.un()(Types_Income.IncomeGroupName)(group$prime.name)
            };
        };
        var groupInput = TacoSelect.component()()({
            value: v4.value0,
            label: "Ryhm\xe4",
            onChange: v4.value1,
            items: Data_Functor.map(Data_Functor.functorArray)(mkGroupItem)(props.groups)
        });
        var dueDateInput = TacoInput.dateInput()()(Types_JSDateString.eqJSDateString)({
            value: v3.value0,
            label: "Suun. pvm",
            type: "date",
            clickEventHandling: TacoInput.NoHandling.value,
            onChange: v3.value1,
            validator: RequiredValidator.requiredValidator,
            onValidationChange: v6.updateFieldValidity("dueDate"),
            error: v6.isFieldError("dueDate")
        });
        var descriptionInput = TacoInput.stringInput()()(Data_Eq.eqString)({
            value: v1.value0,
            label: "Selite",
            onChange: v1.value1,
            validator: RequiredValidator.requiredValidator,
            onValidationChange: v6.updateFieldValidity("description"),
            error: v6.isFieldError("description")
        });
        var codeInput = TacoInput.stringInput()()(Data_Eq.eqString)({
            value: v.value0,
            label: "Tunnus",
            onChange: v.value1,
            validator: RequiredValidator.requiredValidator,
            onValidationChange: v6.updateFieldValidity("code"),
            error: v6.isFieldError("code")
        });
        var form = Box.box("formWrapper")([ Box.MaxWidth.create(new TacoConstants.CustomSize("800px")) ])(Control_Applicative.pure(Control_Applicative.applicativeArray)(Grid.grid([ new Box.Gap(TacoConstants.S.value), Box.AlignStart.value ])([ [ new Grid.Flex(1, groupInput), new Grid.Flex(1, codeInput), new Grid.Flex(2, descriptionInput), new Grid.Flex(1, sumWithoutVATInput), new Grid.Flex(1, dueDateInput) ] ])));
        var modalBody = TacoModal.modalBody({
            contents: [ form ]
        });
        var cancelBtn = TacoButton.component()()({
            onClick: new Data_Maybe.Just(closeModal),
            text: "Peruuta",
            color: TacoConstants.SecondaryRed.value
        });
        var modalActions = TacoModal.modalActions({
            contents: [ cancelBtn, submitBtn ]
        });
        return React_Basic.fragment([ TacoModal.component()()({
            heading: Locale.lookup_("incomeItems_add_new"),
            isActive: v5.value0,
            onClose: closeModal,
            contents: [ React_Basic_DOM_Generated.form()({
                children: [ modalBody, modalActions ],
                onSubmit: v6.onSubmit
            }) ]
        }), TacoButton.component()()({
            onClick: new Data_Maybe.Just(openModal),
            text: Locale.lookup_("incomeItems_add_new"),
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconAddPlusV1.value)
        }) ]);
    };
});
module.exports = {
    component: component
};
