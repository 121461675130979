import { ValueSetterParams } from "ag-grid-community";

export function formatOfferValueToNumber(params: ValueSetterParams) {
  const newValueAsString = params.newValue.toString().toLowerCase();

  let formattedValue: string;
  // Remove spaces
  formattedValue = newValueAsString.replace(/\s/g, "");
  // Remove any other characters that are not numbers, commas, dots or minus signs
  formattedValue = formattedValue.replace(/[^\d,.-]/g, "");
  // Replace last comma with dot
  formattedValue = formattedValue.replace(/,([^,]*)$/, ".$1");
  // Remove remaining commas
  formattedValue = formattedValue.replace(/,/g, "");
  // Remove any other dots then the last one
  if (formattedValue.includes(".")) {
    const lastDotIndex = formattedValue.lastIndexOf(".");
    formattedValue =
      formattedValue.slice(0, lastDotIndex).replace(/\./g, "") +
      formattedValue.slice(lastDotIndex);
  }

  const newValue = parseFloat(formattedValue);

  if (isNaN(newValue)) {
    return params.oldValue;
  }

  return newValue;
}
