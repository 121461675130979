// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Promise = require("../Control.Promise/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Foreign = require("../Foreign/index.js");
var ReactQuery_Internal = require("../ReactQuery.Internal/index.js");
var RequestUtils = require("../RequestUtils/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var useQuery = function (dictTofuJSON) {
    return function (keyStr) {
        return function (fetchAff) {
            return function __do() {
                var foreignRes = ReactQuery_Internal["_useQuery"](keyStr)(Control_Promise.fromAff(fetchAff))();
                var showMultipleErrors = function (me) {
                    return Data_String_Common.joinWith(", ")(Data_Functor.map(Data_Functor.functorArray)(Data_Show.show(Foreign.showForeignError))(Data_Array.fromFoldable(Data_List_Types.foldableNonEmptyList)(me)));
                };
                var res = TofuJSON.read(ReactQuery_Internal.tofuJSON(dictTofuJSON))(foreignRes);
                var qr = (function () {
                    if (res instanceof Data_Either.Left) {
                        return {
                            data: Data_Maybe.Nothing.value,
                            isSuccess: false,
                            isLoading: false,
                            isError: true,
                            error: new Data_Maybe.Just({
                                message: showMultipleErrors(res.value0)
                            })
                        };
                    };
                    if (res instanceof Data_Either.Right) {
                        return res.value0;
                    };
                    throw new Error("Failed pattern match at ReactQuery (line 34, column 10 - line 36, column 25): " + [ res.constructor.name ]);
                })();
                var result = ReactQuery_Internal.mkQueryResult(dictTofuJSON)(qr);
                return result;
            };
        };
    };
};
var useGetRoute = function (dictTofuJSON) {
    return function (keyStr) {
        return function (route) {
            return function (params) {
                var url = RequestUtils.getRouteUrl(route)(params);
                var queryCallback = Control_Bind.bind(Effect_Aff.bindAff)(RequestUtils.getRoute(route)(params)(url))(function (res) {
                    return Control_Applicative.pure(Effect_Aff.applicativeAff)(res);
                });
                return useQuery(dictTofuJSON)(keyStr)(queryCallback);
            };
        };
    };
};
var toMaybe = function (v) {
    if (v instanceof ReactQuery_Internal.Success) {
        return new Data_Maybe.Just(v.value0);
    };
    return Data_Maybe.Nothing.value;
};
module.exports = {
    useQuery: useQuery,
    useGetRoute: useGetRoute,
    toMaybe: toMaybe,
    "Error": ReactQuery_Internal["Error"],
    Loading: ReactQuery_Internal.Loading,
    Success: ReactQuery_Internal.Success
};
