// Generated by purs version 0.14.5
"use strict";
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_DOM_Internal = require("../React.Basic.DOM.Internal/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoLoader = require("../TacoLoader/index.js");
var fixedContainer = function (size) {
    return function (children) {
        return React_Basic_DOM_Generated.div()({
            style: React_Basic_DOM_Internal.css({
                width: size,
                height: size,
                border: "1px solid pink"
            }),
            children: children
        });
    };
};
var example3 = "import TacoLoader as TacoLoader\x0a\x0aTacoLoader.progressBar { color: GrayLight, isLoading: true }";
var example2 = "import TacoLoader as TacoLoader\x0a\x0aTacoLoader.component { size: \"10em\" }";
var example1 = "import TacoLoader as TacoLoader\x0a\x0aTacoLoader.component { size: \"100%\" }";
var sections = [ {
    title: "TacoLoader growing to container size",
    view: [ fixedContainer("5em")([ TacoLoader.component()()({
        size: "100%"
    }) ]) ],
    code: example1
}, {
    title: "TacoLoader size 10em",
    view: [ TacoLoader.component()()({
        size: "10em"
    }) ],
    code: example2
}, {
    title: "TacoLoader progressBar",
    view: [ TacoLoader.progressBar({
        color: TacoConstants.Gray.value,
        isLoading: true,
        testId: "demo"
    }) ],
    code: example3
} ];
var demoView = {
    sections: sections,
    title: "TacoLoader",
    description: "Indicates that something is loading"
};
module.exports = {
    demoView: demoView
};
