/* eslint-disable @typescript-eslint/no-unused-vars */
const React = require("react");
const createReactClass = require("create-react-class");

const echarts = window.echarts;

const chart = createReactClass({
  render: function () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    return React.createElement("div", {
      ref: function (node) {
        self.node = node;
      },
      style: {
        height: this.props.height,
      },
      className: "taco-chart",
    });
  },

  componentDidUpdate: function () {
    this.updateChartOptions();
    this.chart.resize();
  },

  componentDidMount: function () {
    this.chart = echarts.init(this.node, "light");
    this.bindListeners();
    this.updateChartOptions();
  },

  bindListeners: function () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    if (self.props.onClick) {
      self.chart.on("click", function (event) {
        // If chart element is clicked, 'name' refers to value label
        self.props.onClick({
          type: event.type,
          value: event.componentType === "series" ? event.name : event.value,
        })();
      });
    }
  },

  updateChartOptions: function () {
    this.chart.setOption(
      {
        title: {
          show: false,
        },
        color: "#2284f0",
        tooltip: {
          backgroundColor: "#ffffff",
          borderWidth: 1,
          borderColor: "#e0e0e0",
          extraCssText:
            "box-shadow: 0 1px 3px rgba(250, 250, 250, 0.8); padding: 0.6em 0.8em;",
          formatter: formatTooltip(this.props.valueFormatter),
          textStyle: {
            color: "#606060",
            fontSize: 12,
          },
        },
        xAxis: {
          type: "category",
          data: this.props.groups,
          triggerEvent: true,
          axisLabel: {
            fontSize: 12,
            interval: 0,
            rotate: getXAxisLabelRotation(this.props.groups),
          },
        },
        yAxis: {
          show: true,
          type: "value",
          axisLabel: {
            color: "#a0a0a0",
            formatter: this.props.valueFormatter,
          },
          boundaryGap: [0.0, 0.1],
        },
        series: this.props.series,
      },
      true
    );
  },
});

const maxAmountLabelsBeforeRotation = 15;

function getXAxisLabelRotation(labels) {
  if (!labels) {
    return 0;
  }
  return labels.length > maxAmountLabelsBeforeRotation ? 45 : 0;
}

function formatTooltip(valueFormatter) {
  return function (params) {
    const name = params.name;
    const value = params.value;
    return name + ": " + valueFormatter(value);
  };
}

exports.echart = chart;
