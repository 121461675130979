// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_JSDate = require("../Data.JSDate/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Foreign = require("../Foreign/index.js");
var Text_Parsing_StringParser = require("../Text.Parsing.StringParser/index.js");
var Text_Parsing_StringParser_CodePoints = require("../Text.Parsing.StringParser.CodePoints/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_ReasonableCoerce = require("../Types.ReasonableCoerce/index.js");
var jsDateStringParser = Control_Bind.bind(Text_Parsing_StringParser.bindParser)(Control_Apply.applySecond(Text_Parsing_StringParser.applyParser)(Control_Apply.applySecond(Text_Parsing_StringParser.applyParser)(Control_Apply.applySecond(Text_Parsing_StringParser.applyParser)(Control_Apply.applySecond(Text_Parsing_StringParser.applyParser)(Text_Parsing_StringParser_CodePoints.anyDigit)(Text_Parsing_StringParser_CodePoints.anyDigit))(Text_Parsing_StringParser_CodePoints.anyDigit))(Text_Parsing_StringParser_CodePoints.anyDigit))(Text_Parsing_StringParser_CodePoints["char"]("-")))(function () {
    return Control_Bind.bind(Text_Parsing_StringParser.bindParser)(Control_Apply.applySecond(Text_Parsing_StringParser.applyParser)(Control_Apply.applySecond(Text_Parsing_StringParser.applyParser)(Text_Parsing_StringParser_CodePoints.anyDigit)(Text_Parsing_StringParser_CodePoints.anyDigit))(Text_Parsing_StringParser_CodePoints["char"]("-")))(function () {
        return Control_Bind.bind(Text_Parsing_StringParser.bindParser)(Control_Apply.applySecond(Text_Parsing_StringParser.applyParser)(Text_Parsing_StringParser_CodePoints.anyDigit)(Text_Parsing_StringParser_CodePoints.anyDigit))(function () {
            return Control_Applicative.pure(Text_Parsing_StringParser.applicativeParser)(Data_Unit.unit);
        });
    });
});
var makeJSDateString = function (str) {
    return Data_Functor.voidRight(Data_Either.functorEither)(Types_ReasonableCoerce.reasonableCoerce("parse check passed")(Data_String_CodePoints.take(10)(str)))(Text_Parsing_StringParser.runParser(jsDateStringParser)(str));
};
var tofuJSONJSDateString = {
    readImpl: function (f) {
        return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(TofuJSON["read'"](TofuJSON.readString)(f))(function (v) {
            var v1 = Text_Parsing_StringParser.runParser(jsDateStringParser)(v);
            if (v1 instanceof Data_Either.Left) {
                return Control_Monad_Error_Class.throwError(Control_Monad_Except_Trans.monadThrowExceptT(Data_Identity.monadIdentity))(Control_Applicative.pure(Data_List_Types.applicativeNonEmptyList)(Foreign.ForeignError.create("could not parse JSDateString from " + v)));
            };
            if (v1 instanceof Data_Either.Right) {
                return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Types_ReasonableCoerce.reasonableCoerce("JSDateString")(v));
            };
            throw new Error("Failed pattern match at Types.JSDateString (line 30, column 5 - line 35, column 51): " + [ v1.constructor.name ]);
        });
    },
    writeImpl: Types_ReasonableCoerce.reasonableCoerce("JSDateString")
};
var getJSDateStringToday = (function () {
    var unsafeStrToJSDateString = Types_ReasonableCoerce.reasonableCoerce("first 10 characters in an ISO date string correspond to JSDateString");
    return function __do() {
        var now = Data_JSDate.now();
        var isoStr = Data_JSDate.toISOString(now)();
        return unsafeStrToJSDateString(Data_String_CodePoints.take(10)(isoStr));
    };
})();
var extractJSDateString = Types_ReasonableCoerce.reasonableCoerce("JSDateString is already a string");
var extractMaybeJSDateString = function (date) {
    return Data_Maybe.fromMaybe("-")(Data_Functor.map(Data_Maybe.functorMaybe)(extractJSDateString)(date));
};
var showJSDateString = {
    show: (function () {
        var $7 = Data_Show.show(Data_Show.showString);
        return function ($8) {
            return $7(extractJSDateString($8));
        };
    })()
};
var eqJSDateString = {
    eq: Data_Function.on(Data_Eq.eq(Data_Eq.eqString))(extractJSDateString)
};
var ordJSDateString = {
    compare: function (a) {
        return function (b) {
            return Data_Function.on(Data_Ord.compare(Data_Ord.ordString))(extractJSDateString)(a)(b);
        };
    },
    Eq0: function () {
        return eqJSDateString;
    }
};
module.exports = {
    jsDateStringParser: jsDateStringParser,
    extractJSDateString: extractJSDateString,
    extractMaybeJSDateString: extractMaybeJSDateString,
    makeJSDateString: makeJSDateString,
    getJSDateStringToday: getJSDateStringToday,
    showJSDateString: showJSDateString,
    eqJSDateString: eqJSDateString,
    ordJSDateString: ordJSDateString,
    tofuJSONJSDateString: tofuJSONJSDateString
};
