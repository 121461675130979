import { AxiosError } from "axios";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import axiosClient from "src/client-ts/axiosConfig";
import { reportError } from "src/client-ts/utils/error";

export type ComponentGroup = {
  code: string;
  description: string;
  id: number;
  memo: string;
  projectId: number;
};
export type NewComponentGroup = Omit<ComponentGroup, "id" | "memo">;

// Classifications don't have anything to do with these groups, this route naming was a mistake
const url = "/classificationGroups";

export function useComponentGroupsQuery(projectId: number) {
  return useQuery<ComponentGroup[]>([url, projectId], async () => {
    const res = await axiosClient.get(`/project/${projectId}${url}`);
    return res.data;
  });
}

export type ComponentGroupMutationType =
  | "AddClassificationGroup"
  | "UpdateClassificationGroup";
export type ComponentGroupMutationValues<
  T extends ComponentGroupMutationType
> = {
  type: T;
  value: T extends "AddClassificationGroup"
    ? NewComponentGroup
    : ComponentGroup;
};

export function useComponentGroupMutation<T extends ComponentGroupMutationType>(
  projectId: number
) {
  const client = useQueryClient();
  return useMutation({
    mutationFn: async (group: ComponentGroupMutationValues<T>) => {
      return axiosClient.post(
        `/project/${projectId}/classificationGroupAction`,
        group
      );
    },

    onError: (e) => {
      reportError("Error while updating component groups", e as AxiosError);
    },
    onSettled: async () => {
      await invalidateGroups(client, projectId);
    },
  });
}

type DeleteGroupsMutationProps = {
  groupCodes: string[];
};
export function useDeleteGroupsMutation(projectId: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (groupCodesToDelete: DeleteGroupsMutationProps) => {
      return axiosClient.delete(`/project/${projectId}${url}`, {
        data: groupCodesToDelete,
      });
    },
    onSettled: async () => {
      await invalidateGroups(queryClient, projectId);
    },
    onError: (e) => {
      reportError("Error while deleting component groups", e as AxiosError);
    },
  });
}

type CreateGroupsMutationProps = {
  groupData: { code: string; description: string | null }[];
};
export function useCreateComponentGroupsMutation(projectId: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (groupData: CreateGroupsMutationProps) => {
      return axiosClient.post(`/project/${projectId}${url}`, groupData);
    },
    onSettled: async () => {
      await invalidateGroups(queryClient, projectId);
    },
    onError: (e) => {
      reportError("Error while creating component groups", e as AxiosError);
    },
  });
}

function invalidateGroups(queryClient: QueryClient, projectId: number) {
  return queryClient.invalidateQueries({
    queryKey: [url, projectId],
  });
}
