// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Auth = require("../Auth/index.js");
var Common = require("../Common/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Promise = require("../Control.Promise/index.js");
var DOMUtils = require("../DOMUtils/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var FeatureHooks = require("../FeatureHooks/index.js");
var HtmlUtils = require("../HtmlUtils/index.js");
var Locale = require("../Locale/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var ReactQuery_Internal = require("../ReactQuery.Internal/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoDropdown = require("../TacoDropdown/index.js");
var TacoDropdown_Types = require("../TacoDropdown.Types/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoLoader = require("../TacoLoader/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_FrontEndRoutes = require("../Types.FrontEndRoutes/index.js");
var Types_Organization = require("../Types.Organization/index.js");
var Types_Project = require("../Types.Project/index.js");
var UseCurrentOrganization = require("../UseCurrentOrganization/index.js");
var UseFetch = require("../UseFetch/index.js");
var UserDropdown_Styles = require("../UserDropdown.Styles/index.js");
var component = TofuHooks.mkHookComponent("UserDropdown")(function (props) {
    return function __do() {
        var v = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v) {
            return v.currentOrganization;
        })(Actions.GetCurrentOrganizationRequest.value)();
        var v1 = UseFetch.useFetchItems(Data_Eq.eqUnit)(Data_Unit.unit)(function (v1) {
            return v1.userOrganizations;
        })(Actions.GetUserOrganizationsRequest.value)();
        var orgPlanResult = UseCurrentOrganization.useOrganizationLicensePlan();
        var orgPlanText = (function () {
            if (orgPlanResult instanceof ReactQuery_Internal.Success) {
                return " - " + Data_Show.show(Types_Organization.showPlan)(orgPlanResult.value0);
            };
            return "";
        })();
        var v2 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var appDispatch = StateHooks.useDispatch();
        var newKnowledgeBaseEnabled = FeatureHooks.useFeatureEnabled("knowledge-base-new")();
        var user = TacoButton.component()()({
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconAccountCircle24Px.value),
            iconSize: TacoConstants.M.value,
            color: TacoConstants.TextPrimary.value,
            onClickEventHandler: Data_Maybe.Just.create(React_Basic_Events.handler(React_Basic_DOM_Events.currentTarget)(function (target) {
                return function __do() {
                    props.setShowUserActions(function (v3) {
                        return true;
                    })();
                    return v2.value1(function (v3) {
                        return Data_Maybe.Just.create(HtmlUtils.coerceEventTargetToHTMLElement(target));
                    })();
                };
            })),
            buttonStyle: TacoButton_Types.Link.value,
            testId: "user-dropdown-button"
        });
        var releaseNotesOnClick = DOMUtils.navigateToUrlInNewTab("https://www.tocoman.fi/laskenta/julkaisutiedotteet");
        var releaseNotes = TacoButton.component()()({
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconInfoWhite.value),
            text: "Julkaisutiedotteet",
            align: TacoButton_Types.Left.value,
            onClick: Data_Maybe.Just.create(releaseNotesOnClick),
            testId: "navigate-release-notes-button"
        });
        var notifications = TacoButton.component()()({
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconFlagV1.value),
            text: Locale.lookup_("notifications"),
            align: TacoButton_Types.Left.value,
            onClick: Data_Maybe.Just.create(appDispatch(new Actions.NavigateRoute(Types_FrontEndRoutes.NotificationsFERoute.value))),
            testId: "navigate-notifications-button"
        });
        var manual = (function () {
            if (props.applicationMode instanceof Types_Project.EstimationMode) {
                return Control_Applicative.pure(Control_Applicative.applicativeArray)(TacoButton.component()()({
                    icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconInvoiceImageV1.value),
                    text: "Ohje",
                    align: TacoButton_Types.Left.value,
                    href: (function () {
                        if (newKnowledgeBaseEnabled) {
                            return new Data_Maybe.Just("https://www.tocoman.fi/laskenta/ohjeet");
                        };
                        return new Data_Maybe.Just("https://www.tocoman.fi/laskenta-ohjevideot");
                    })(),
                    newTab: true,
                    testId: "navigate-manual-button"
                }));
            };
            return Common.emptyArray;
        })();
        var logout = TacoButton.component()()({
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconLogout.value),
            text: "Kirjaudu ulos",
            align: TacoButton_Types.Left.value,
            onClick: new Data_Maybe.Just(Effect_Aff.launchAff_(Control_Promise.toAffE(Auth.signOut))),
            testId: "sign-out-button"
        });
        var userActions = (function () {
            if (v.value0 instanceof Data_Maybe.Nothing) {
                return [ {
                    title: Data_Maybe.Nothing.value,
                    items: [ TacoLoader.component()()({
                        size: "2rem"
                    }) ]
                } ];
            };
            if (v.value0 instanceof Data_Maybe.Just) {
                var orgsWithoutCurrent = Data_Array.sort(Types_Organization.ordOrganization)(Data_Array.filter(function (i) {
                    return Data_Eq.notEq(Types_Organization.eqOrganization)(i)(v.value0.value0.organization);
                })(v1.value0));
                var notificationsection = {
                    title: Data_Maybe.Nothing.value,
                    items: Data_Semigroup.append(Data_Semigroup.semigroupArray)([ releaseNotes, notifications ])(manual)
                };
                var mkOrg = function (i) {
                    return TacoButton.component()()({
                        text: Data_Newtype.unwrap()(i),
                        align: TacoButton_Types.Left.value,
                        onClick: Data_Maybe.Just.create(appDispatch(new Actions.ChangeOrganizationsRequest(i, props.currentRoute)))
                    });
                };
                var orgs = Data_Functor.map(Data_Functor.functorArray)(mkOrg)(orgsWithoutCurrent);
                var options = React_Basic_DOM_Generated.div()({
                    className: "organizations",
                    children: orgs
                });
                var orgsWithoutCurrentSection = (function () {
                    var $16 = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Array["null"])(orgsWithoutCurrent);
                    if ($16) {
                        return new Data_Maybe.Just({
                            title: new Data_Maybe.Just("Vaihda organisaatio"),
                            items: [ options ]
                        });
                    };
                    return Data_Maybe.Nothing.value;
                })();
                var logoutSection = {
                    title: Data_Maybe.Nothing.value,
                    items: [ logout ]
                };
                var currentOrg = TacoText.component()()({
                    text: Data_Newtype.unwrap()(v.value0.value0.organization) + orgPlanText,
                    weight: TacoText_Types.Bold.value
                });
                var changeOrg = TacoButton.component()()({
                    onClick: Data_Maybe.Just.create(appDispatch(new Actions.NavigateRoute(Types_FrontEndRoutes.OrganizationFERoute.value))),
                    align: TacoButton_Types.Left.value,
                    text: "Muokkaa organisaatiota",
                    testId: "navigate-organization-button"
                });
                var currentAndChangeOrgSection = {
                    title: new Data_Maybe.Just("Valittu organisaatio"),
                    items: [ currentOrg, changeOrg ]
                };
                return Data_Array.catMaybes([ new Data_Maybe.Just(currentAndChangeOrgSection), orgsWithoutCurrentSection, new Data_Maybe.Just(notificationsection), new Data_Maybe.Just(logoutSection) ]);
            };
            throw new Error("Failed pattern match at UserDropdown (line 119, column 19 - line 176, column 12): " + [ v.value0.constructor.name ]);
        })();
        var callout = TacoDropdown.component({
            sections: userActions,
            toggled: props.showUserActions,
            setToggled: props.setShowUserActions,
            anchorEl: v2.value0,
            align: TacoDropdown_Types.AlignRight.value
        });
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "UserDropdown",
            css: UserDropdown_Styles.userDropdownContainerStyles,
            children: [ user, callout ]
        });
    };
});
module.exports = {
    component: component
};
