var MonthlyReport = require("../../src/client/containers/Reports/MonthToMonth/MonthlyReport");
var ReactDOM = require("react-dom");

exports.renderJSX = function (jsx) {
  return function () {
    ReactDOM.render(jsx, document.getElementById("root"));
  };
};

window.appState = {};

exports.setAppState = (appState) => () => {
  window.appState = appState;
};

exports.setDispatch = (dispatch) => () => {
  window.dispatch = dispatch;
};

exports.getAppState = () => window.appState;

exports.getMonthlyReportComponent = () => MonthlyReport.default;

exports.initWasmWorker = (eventListener) => () => {
  window.wasmWorker.addEventListener("message", eventListener);

  window.subscribedWasmState.forEach((stateKey) => {
    window.wasmWorker.postMessage({
      type: "SubscribeWorkerState",
      value: {
        state_key: stateKey,
      },
    });
  });

  return window.wasmWorker;
};

exports.warnSize = (obj) => () => {
  let debugMode = sessionStorage.getItem("wasmWorkerDebugging") === "true";

  if (debugMode) {
    // let timestamp = Date.now();

    let str = JSON.stringify(obj);
    let size = str.length;

    if (size > window.wasmSizeWarning) {
      let sizeStr = `${(size / 1000).toFixed(1)} kB`;
      if (obj.type === "SubscribedWorkerStateUpdate") {
        console.log(
          `UI received large ${obj.type} message from worker, consider refactoring. Size: ${sizeStr}, StateKey: ${obj.value.type}`
        );
        // console.log(`Receiving message took ${timestamp - obj.timestamp} ms.`);
      } else {
        console.log(
          `UI received large ${obj.type} message from worker, consider refactoring. Size: ${sizeStr}`
        );
      }
    }
  }
};
