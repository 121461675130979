// Generated by purs version 0.14.5
"use strict";
var React_Basic = require("../React.Basic/index.js");
var Table_Internal = require("../Table.Internal/index.js");
var Types_ReasonableCoerce = require("../Types.ReasonableCoerce/index.js");
var table = function (dictTofuJSON) {
    return function (props) {
        return React_Basic.element(Table_Internal["_Table"])(Types_ReasonableCoerce.reasonableCoerce("Coercing Foreign to Record")(Table_Internal.tPropsToForeignOptions(dictTofuJSON)(props)));
    };
};
module.exports = {
    table: table,
    Editable: Table_Internal.Editable,
    Field: Table_Internal.Field,
    HeaderName: Table_Internal.HeaderName,
    Width: Table_Internal.Width,
    ClassName: Table_Internal.ClassName,
    ColumnDefs: Table_Internal.ColumnDefs,
    GetRowId: Table_Internal.GetRowId,
    OnCellEditRequest: Table_Internal.OnCellEditRequest,
    OnGridReady: Table_Internal.OnGridReady,
    ReadOnlyEdit: Table_Internal.ReadOnlyEdit,
    RowData: Table_Internal.RowData,
    TField: Table_Internal.TField,
    Theme: Table_Internal.Theme
};
