// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Client_WASM = require("../Client.WASM/index.js");
var Common = require("../Common/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Controls = require("../Controls/index.js");
var CostEstimation_Layout = require("../CostEstimation.Layout/index.js");
var CostEstimation_ProductionPlanningWarning = require("../CostEstimation.ProductionPlanningWarning/index.js");
var CostEstimation_ResourcesView_SelectedResourceDetails = require("../CostEstimation.ResourcesView.SelectedResourceDetails/index.js");
var CostEstimation_ResourcesView_Table = require("../CostEstimation.ResourcesView.Table/index.js");
var CostEstimation_ResourcesView_Types = require("../CostEstimation.ResourcesView.Types/index.js");
var CostEstimation_ResourcesView_UpdateResources = require("../CostEstimation.ResourcesView.UpdateResources/index.js");
var CostEstimation_SummariesPanel = require("../CostEstimation.SummariesPanel/index.js");
var CostEstimation_SummariesSidebar = require("../CostEstimation.SummariesSidebar/index.js");
var CostEstimationLayout_Types = require("../CostEstimationLayout.Types/index.js");
var CostEstimationTableWrapper = require("../CostEstimationTableWrapper/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Array_NonEmpty_Internal = require("../Data.Array.NonEmpty.Internal/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_JSDate = require("../Data.JSDate/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var EstimationExcelExportButton = require("../EstimationExcelExportButton/index.js");
var EstimationUtils = require("../EstimationUtils/index.js");
var Formatters = require("../Formatters/index.js");
var MergedEstimationResources = require("../MergedEstimationResources/index.js");
var React_Basic = require("../React.Basic/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var ReferenceLibrarySidebar = require("../ReferenceLibrarySidebar/index.js");
var ReferenceLibrarySidepanel = require("../ReferenceLibrarySidepanel/index.js");
var ResourceSummaries = require("../ResourceSummaries/index.js");
var SocialExpensesControls = require("../SocialExpensesControls/index.js");
var StateHooks = require("../StateHooks/index.js");
var StringUtils = require("../StringUtils/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoColumnSelector = require("../TacoColumnSelector/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoDropdown = require("../TacoDropdown/index.js");
var TacoDropdown_Types = require("../TacoDropdown.Types/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoLoader = require("../TacoLoader/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoTableUtils = require("../TacoTableUtils/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_ClassificationGroup = require("../Types.ClassificationGroup/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_CostEstimation = require("../Types.CostEstimation/index.js");
var Types_MergedEstimationResource = require("../Types.MergedEstimationResource/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Project = require("../Types.Project/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var UseEstimationUiState = require("../UseEstimationUiState/index.js");
var UseKeydownListener = require("../UseKeydownListener/index.js");
var UseLocalStorage = require("../UseLocalStorage/index.js");
var UseNullableRef = require("../UseNullableRef/index.js");
var UseSelectedComponentScrollPosition = require("../UseSelectedComponentScrollPosition/index.js");
var UseSocialExpensesInCosts = require("../UseSocialExpensesInCosts/index.js");
var Utils_Keyboard = require("../Utils.Keyboard/index.js");
var tableRowHeight = TacoTable_Types.RowSmall.value;
var handleCursorNavigation = function (v) {
    return UseKeydownListener.useKeydownListener([  ])(true)([ Utils_Keyboard.ArrowUp.value, Utils_Keyboard.ArrowDown.value, Utils_Keyboard.Escape.value ])(ReactHooksUtils.utf("ResourcesViewDetailsNavigation"))(function (keyDownEvent) {
        if (keyDownEvent.key instanceof Utils_Keyboard.ArrowUp) {
            return function __do() {
                Utils_Keyboard.preventDefault(keyDownEvent.event)();
                return v.selectResourceRelative(-1 | 0)();
            };
        };
        if (keyDownEvent.key instanceof Utils_Keyboard.ArrowDown) {
            return function __do() {
                Utils_Keyboard.preventDefault(keyDownEvent.event)();
                return v.selectResourceRelative(1)();
            };
        };
        if (keyDownEvent.key instanceof Utils_Keyboard.Escape) {
            return v.selectResource(Data_Maybe.Nothing.value);
        };
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    });
};
var findOfSelectedResource = function (mId) {
    return function (xs) {
        return Control_Bind.bind(Data_Maybe.bindMaybe)(mId)(function (id) {
            return Data_Array.findIndex((function () {
                var $108 = Data_Eq.eq(Types_Component.eqEstimationResourceId)(id);
                return function ($109) {
                    return $108((function (v) {
                        return v.id;
                    })($109));
                };
            })())(xs);
        });
    };
};
var findNewIdxOfSelectedResource = function (prevIdx) {
    return function (currentResources) {
        return function (newResources) {
            var findIdxOfComponent = function (comp) {
                return Data_Array.elemIndex(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "vendorUnit";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "vendorCode";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "vendorBatchSize";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                    reflectSymbol: function () {
                        return "vendor";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "validDate";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "usagesCount";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "unit";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "taxPercentage";
                    }
                })(Types_Unit.eqTaxrate))()({
                    reflectSymbol: function () {
                        return "resourceClassificationGroup";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "priceWithDiscount";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "pricePerUnit";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                    reflectSymbol: function () {
                        return "orgResourcePriceListId";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "orgResourcePriceId";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqInt)))()({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(Data_Eq.eqString))()({
                    reflectSymbol: function () {
                        return "key";
                    }
                })(Data_Eq.eqString))()({
                    reflectSymbol: function () {
                        return "index";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Component.eqEstimationResourceId))()({
                    reflectSymbol: function () {
                        return "estimationResources";
                    }
                })(Data_Array_NonEmpty_Internal.eqNonEmptyArray(Data_Tuple.eqTuple(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "wastePercentage";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "vendorUnit";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "vendorCode";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "vendorBatchSize";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                    reflectSymbol: function () {
                        return "vendor";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "validDate";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "usagesCount";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "unitConsumptionRate";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "unit";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "taxPercentage";
                    }
                })(Types_Unit.eqTaxrate))()({
                    reflectSymbol: function () {
                        return "sortingNumber";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "resourceClassificationGroup";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "projectId";
                    }
                })(Data_Nullable.eqNullable(Types_Project.eqProjectId)))()({
                    reflectSymbol: function () {
                        return "priceWithDiscount";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "pricePerUnit";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                    reflectSymbol: function () {
                        return "orgResourcePriceListId";
                    }
                })(Data_Nullable.eqNullable(Types_Component.eqOrgEstimationResourceId)))()({
                    reflectSymbol: function () {
                        return "orgResourcePriceId";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqInt)))()({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(Data_Eq.eqString))()({
                    reflectSymbol: function () {
                        return "multiplier";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "isCostClassPrice";
                    }
                })(Data_Eq.eqBoolean))()({
                    reflectSymbol: function () {
                        return "index";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Component.eqEstimationResourceId))()({
                    reflectSymbol: function () {
                        return "group";
                    }
                })(Data_Nullable.eqNullable(Types_ClassificationGroup.eqClassificationGroupCode)))()({
                    reflectSymbol: function () {
                        return "estimationComponentId";
                    }
                })(Data_Nullable.eqNullable(Types_Component.eqComponentId)))()({
                    reflectSymbol: function () {
                        return "eanCode";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "discountPercentage";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "discountGroupCode";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "currencyId";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "costClassCode";
                    }
                })(Data_Nullable.eqNullable(Types_Newtypes.eqCostClassCode)))()({
                    reflectSymbol: function () {
                        return "contract";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "changeDate";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString))))(Data_Maybe.eqMaybe(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "unit";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "subProjectName";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "subProjectId";
                    }
                })(Data_Nullable.eqNullable(Types_Project.eqSubprojectId)))()({
                    reflectSymbol: function () {
                        return "subProjectCode";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "memo";
                    }
                })(Data_Eq.eqString))()({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Component.eqComponentId))()({
                    reflectSymbol: function () {
                        return "group";
                    }
                })(Data_Nullable.eqNullable(Types_ClassificationGroup.eqClassificationGroupCode)))()({
                    reflectSymbol: function () {
                        return "flagged";
                    }
                })(Data_Eq.eqBoolean))()({
                    reflectSymbol: function () {
                        return "description";
                    }
                })(Data_Eq.eqString))()({
                    reflectSymbol: function () {
                        return "costGroupDescription";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "costGroupCode";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "componentType";
                    }
                })(Data_Eq.eqInt))()({
                    reflectSymbol: function () {
                        return "code";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "classCode";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "checked";
                    }
                })(Data_Eq.eqBoolean))()({
                    reflectSymbol: function () {
                        return "amount";
                    }
                })(Data_Eq.eqNumber)))))))()({
                    reflectSymbol: function () {
                        return "eanCode";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "discountPercentage";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "discountGroupCode";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "currencyId";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "costClassCode";
                    }
                })(Data_Nullable.eqNullable(Types_Newtypes.eqCostClassCode)))()({
                    reflectSymbol: function () {
                        return "contract";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "changeDate";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString))))(comp)(newResources);
            };
            var idxInNewComponentSet = function (curIdx) {
                return Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(findIdxOfComponent)(Data_Array.index(currentResources)(curIdx));
            };
            return Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(idxInNewComponentSet)(prevIdx);
        };
    };
};
var filterWithValue = function (value) {
    return function (mer) {
        var v = Data_Array_NonEmpty.head(mer.estimationResources);
        return StringUtils.stringContainsCaseIgnored(value)([ v.value0.name ]);
    };
};
var sortAndFilterResources = function (filterValue) {
    return function (criteria) {
        return function (resources) {
            var filteredResources = Data_Array.filter(filterWithValue(filterValue))(resources);
            return TacoTableUtils.sortByCriteria(criteria)(filteredResources);
        };
    };
};
var component = TofuHooks.mkHookComponent("BuildingResourcesView")(function (props) {
    return function __do() {
        var appDispatch = StateHooks.useDispatch();
        var defaultColumns = [ Types_MergedEstimationResource.ColCostClassCode.value, Types_MergedEstimationResource.ColResourceClassificationGroup.value, Types_MergedEstimationResource.ColName.value, Types_MergedEstimationResource.ColTotalAmountSum.value, Types_MergedEstimationResource.ColUnit.value, Types_MergedEstimationResource.ColPricePerUnitUnit.value, Types_MergedEstimationResource.ColTotalPriceSum.value ];
        var v = UseEstimationUiState.useEstimationUiState(props.projectId)();
        var initialState = {
            selectedResourceId: v.value0.selectedResourceId,
            shouldScrollToSelection: true,
            filterValue: "",
            sortedResources: [  ],
            selectedInputColumn: Data_Maybe.Nothing.value
        };
        var v1 = TofuHooks.useState(initialState)();
        var v2 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readArray(Types_MergedEstimationResource.tofuJSONEstimationResourceColumn))("ui:ce:visible_resource_columns")(defaultColumns)(false)();
        var v3 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)("ui:ce:use_visible_resource_columns")(false)(false)();
        var v4 = UseLocalStorage.useLocalStorageJSON(TofuJSON.tofuJSONMap(Data_Ord.ordString)(TofuJSON.readString)(TofuJSON.readNumber))("ui:ce:resource_column_size_offsets")(Data_Map_Internal.empty)(false)();
        var v5 = Client_WASM.useProjectLocations(props.projectId)();
        var v6 = Client_WASM.useEstimationLocations(props.projectId)();
        var mergedEstimationResources = MergedEstimationResources.useMergedEstimationResources(props.projectId)();
        var v7 = Client_WASM.useInvalidResourceCurrencies(props.projectId)();
        var invalidCurrencyWarningEnabled = !Data_Maybe.fromMaybe(true)(v7.value0);
        var resourceIds = Data_Functor.map(Data_Functor.functorArray)(function (v8) {
            return v8.id;
        })(v1.value0.sortedResources);
        var v8 = TacoTableUtils.useRangeSelection(Types_Component.ordEstimationResourceId)(resourceIds)(Data_Set.empty)();
        var v9 = TacoLoader.useLoading(false)();
        var v10 = UseSocialExpensesInCosts.useSocialExpensesInCosts(props.projectId)();
        var v11 = TofuHooks.useState(new TacoTable_Types.SortingCriteria(TacoTable_Types.Ascending.value, new Data_Maybe.Just(CostEstimation_ResourcesView_Types.ByCostClass.value), CostEstimation_ResourcesView_Table.getSortingValue(Data_Map_Internal.empty)))();
        TofuHooks.useEffect([ ReactHooksUtils.utf(v10.showSocialExpensesInCosts), ReactHooksUtils.utf(v10.socialExpensePercentagesByCostClass) ])(function __do() {
            v11.value1(function (v12) {
                return TacoTable_Types.SortingCriteria.create(v12.value0)(v12.value1)(CostEstimation_ResourcesView_Table.getSortingValue(v10.socialExpensePercentagesByCostClass));
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Effect.monoidEffect(Data_Monoid.monoidUnit)))();
        })();
        var selectedResourceIdx = TofuHooks.useMemo([ ReactHooksUtils.utf(v1.value0.selectedResourceId), ReactHooksUtils.utf(v1.value0.sortedResources) ])(function (v12) {
            return findOfSelectedResource(v1.value0.selectedResourceId)(v1.value0.sortedResources);
        })();
        TofuHooks.useEffect([ v1.value0.selectedResourceId ])(function __do() {
            v.value1(function (v12) {
                return {
                    project: v12.project,
                    elementFilters: v12.elementFilters,
                    elementGroupProperty: v12.elementGroupProperty,
                    selectedElementIdx: v12.selectedElementIdx,
                    referenceElementFilters: v12.referenceElementFilters,
                    selectedReferenceElementIdx: v12.selectedReferenceElementIdx,
                    componentFilters: v12.componentFilters,
                    componentGroupProperty: v12.componentGroupProperty,
                    selectedComponentIdx: v12.selectedComponentIdx,
                    referenceComponentFilters: v12.referenceComponentFilters,
                    selectedReferenceComponentIdx: v12.selectedReferenceComponentIdx,
                    referenceResourceFilters: v12.referenceResourceFilters,
                    selectedResourceId: v1.value0.selectedResourceId
                };
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        })();
        var setSortingCriteria = function (newProperty) {
            return v11.value1(function (s) {
                var criteria = (function () {
                    var $61 = Data_Eq.eq(Data_Maybe.eqMaybe(CostEstimation_ResourcesView_Types.eqResourcesTableSortProperty))(s.value1)(new Data_Maybe.Just(newProperty));
                    if ($61) {
                        return new TacoTable_Types.SortingCriteria(TacoTable_Types.reverse(s.value0), s.value1, s.value2);
                    };
                    return new TacoTable_Types.SortingCriteria(s.value0, new Data_Maybe.Just(newProperty), s.value2);
                })();
                return criteria;
            });
        };
        var selectResourceRelative = function (diff) {
            return v1.value1(function (s) {
                var currIdx = findOfSelectedResource(s.selectedResourceId)(s.sortedResources);
                var targetIdx = diff + Data_Maybe.fromMaybe(-diff | 0)(currIdx) | 0;
                var newId = Data_Functor.map(Data_Maybe.functorMaybe)(function (v12) {
                    return v12.id;
                })(Data_Array.index(s.sortedResources)(targetIdx));
                return {
                    selectedResourceId: newId,
                    shouldScrollToSelection: s.shouldScrollToSelection,
                    filterValue: s.filterValue,
                    sortedResources: s.sortedResources,
                    selectedInputColumn: s.selectedInputColumn
                };
            });
        };
        var selectResource = function (res) {
            return v1.value1(function (s) {
                var newId = Data_Functor.map(Data_Maybe.functorMaybe)(function (v12) {
                    return v12.id;
                })(res);
                return {
                    selectedResourceId: (function () {
                        var $65 = Data_Eq.eq(Data_Maybe.eqMaybe(Types_Component.eqEstimationResourceId))(s.selectedResourceId)(newId);
                        if ($65) {
                            return Data_Maybe.Nothing.value;
                        };
                        return newId;
                    })(),
                    shouldScrollToSelection: s.shouldScrollToSelection,
                    filterValue: s.filterValue,
                    sortedResources: s.sortedResources,
                    selectedInputColumn: s.selectedInputColumn
                };
            });
        };
        var v12 = TofuHooks.useMemo([ ReactHooksUtils.utf(v1.value0.sortedResources), ReactHooksUtils.utf(v1.value0.selectedResourceId) ])(function (v12) {
            return Control_Bind.bind(Data_Maybe.bindMaybe)(v1.value0.selectedResourceId)(function (id) {
                return Data_Array.find((function () {
                    var $110 = Data_Eq.eq(Types_Component.eqEstimationResourceId)(id);
                    return function ($111) {
                        return $110((function (v13) {
                            return v13.id;
                        })($111));
                    };
                })())(v1.value0.sortedResources);
            });
        })();
        TofuHooks.useEffectTimeout([ ReactHooksUtils.utf(mergedEstimationResources), ReactHooksUtils.utf(v11.value0), ReactHooksUtils.utf(v1.value0.filterValue) ])(500)(function __do() {
            v1.value1(function (s) {
                var newResources = sortAndFilterResources(s.filterValue)(v11.value0)(mergedEstimationResources);
                return {
                    selectedResourceId: s.selectedResourceId,
                    shouldScrollToSelection: s.shouldScrollToSelection,
                    filterValue: s.filterValue,
                    sortedResources: newResources,
                    selectedInputColumn: s.selectedInputColumn
                };
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        })();
        handleCursorNavigation({
            selectResourceRelative: selectResourceRelative,
            selectResource: selectResource
        })();
        var tableRef = UseNullableRef.useNullableRef();
        UseSelectedComponentScrollPosition.useSelectedComponentScrollPosition(v1.value0.shouldScrollToSelection)(TacoTable_Types.rowHeightPixels(tableRowHeight))(selectedResourceIdx)(tableRef)();
        var visibleColumns$prime = Data_Semigroup.append(Data_Semigroup.semigroupArray)([ Types_MergedEstimationResource.ColCheckbox.value ])(v2.value0);
        var titleText = TacoText.component()()({
            text: "Panokset",
            variant: new TacoText_Types.Heading(2),
            wrap: TacoText_Types.NoWrap.value,
            overflow: TacoText_Types.TextOverflowEllipsis.value
        });
        var selectedResources = Data_Set.fromFoldable(Data_Foldable.foldableArray)(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()({
            reflectSymbol: function () {
                return "vendorUnit";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "vendorCode";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "vendorBatchSize";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
            reflectSymbol: function () {
                return "vendor";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "validDate";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "usagesCount";
            }
        })(Data_Ord.ordNumber))()({
            reflectSymbol: function () {
                return "unit";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "taxPercentage";
            }
        })(Types_Unit.ordTaxrate))()({
            reflectSymbol: function () {
                return "resourceClassificationGroup";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "priceWithDiscount";
            }
        })(Data_Ord.ordNumber))()({
            reflectSymbol: function () {
                return "pricePerUnit";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
            reflectSymbol: function () {
                return "orgResourcePriceListId";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "orgResourcePriceId";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordInt)))()({
            reflectSymbol: function () {
                return "name";
            }
        })(Data_Ord.ordString))()({
            reflectSymbol: function () {
                return "key";
            }
        })(Data_Ord.ordString))()({
            reflectSymbol: function () {
                return "index";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
            reflectSymbol: function () {
                return "id";
            }
        })(Types_Component.ordEstimationResourceId))()({
            reflectSymbol: function () {
                return "estimationResources";
            }
        })(Data_Array_NonEmpty_Internal.ordNonEmptyArray(Data_Tuple.ordTuple(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()({
            reflectSymbol: function () {
                return "wastePercentage";
            }
        })(Data_Ord.ordNumber))()({
            reflectSymbol: function () {
                return "vendorUnit";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "vendorCode";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "vendorBatchSize";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
            reflectSymbol: function () {
                return "vendor";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "validDate";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "usagesCount";
            }
        })(Data_Ord.ordNumber))()({
            reflectSymbol: function () {
                return "unitConsumptionRate";
            }
        })(Data_Ord.ordNumber))()({
            reflectSymbol: function () {
                return "unit";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "taxPercentage";
            }
        })(Types_Unit.ordTaxrate))()({
            reflectSymbol: function () {
                return "sortingNumber";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "resourceClassificationGroup";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Data_Nullable.ordNullable(Types_Project.ordProjectId)))()({
            reflectSymbol: function () {
                return "priceWithDiscount";
            }
        })(Data_Ord.ordNumber))()({
            reflectSymbol: function () {
                return "pricePerUnit";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
            reflectSymbol: function () {
                return "orgResourcePriceListId";
            }
        })(Data_Nullable.ordNullable(Types_Component.ordOrgEstimationResourceId)))()({
            reflectSymbol: function () {
                return "orgResourcePriceId";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordInt)))()({
            reflectSymbol: function () {
                return "name";
            }
        })(Data_Ord.ordString))()({
            reflectSymbol: function () {
                return "multiplier";
            }
        })(Data_Ord.ordNumber))()({
            reflectSymbol: function () {
                return "isCostClassPrice";
            }
        })(Data_Ord.ordBoolean))()({
            reflectSymbol: function () {
                return "index";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
            reflectSymbol: function () {
                return "id";
            }
        })(Types_Component.ordEstimationResourceId))()({
            reflectSymbol: function () {
                return "group";
            }
        })(Data_Nullable.ordNullable(Types_ClassificationGroup.ordClassificationGroupCode)))()({
            reflectSymbol: function () {
                return "estimationComponentId";
            }
        })(Data_Nullable.ordNullable(Types_Component.ordComponentId)))()({
            reflectSymbol: function () {
                return "eanCode";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "discountPercentage";
            }
        })(Data_Ord.ordNumber))()({
            reflectSymbol: function () {
                return "discountGroupCode";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "currencyId";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "costClassCode";
            }
        })(Data_Nullable.ordNullable(Types_Newtypes.ordCostClassCode)))()({
            reflectSymbol: function () {
                return "contract";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "changeDate";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString))))(Data_Maybe.ordMaybe(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()({
            reflectSymbol: function () {
                return "unit";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "subProjectName";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "subProjectId";
            }
        })(Data_Nullable.ordNullable(Types_Project.ordSubprojectId)))()({
            reflectSymbol: function () {
                return "subProjectCode";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "memo";
            }
        })(Data_Ord.ordString))()({
            reflectSymbol: function () {
                return "id";
            }
        })(Types_Component.ordComponentId))()({
            reflectSymbol: function () {
                return "group";
            }
        })(Data_Nullable.ordNullable(Types_ClassificationGroup.ordClassificationGroupCode)))()({
            reflectSymbol: function () {
                return "flagged";
            }
        })(Data_Ord.ordBoolean))()({
            reflectSymbol: function () {
                return "description";
            }
        })(Data_Ord.ordString))()({
            reflectSymbol: function () {
                return "costGroupDescription";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "costGroupCode";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "componentType";
            }
        })(Data_Ord.ordInt))()({
            reflectSymbol: function () {
                return "code";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "classCode";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "checked";
            }
        })(Data_Ord.ordBoolean))()({
            reflectSymbol: function () {
                return "amount";
            }
        })(Data_Ord.ordNumber)))))))()({
            reflectSymbol: function () {
                return "eanCode";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "discountPercentage";
            }
        })(Data_Ord.ordNumber))()({
            reflectSymbol: function () {
                return "discountGroupCode";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "currencyId";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "costClassCode";
            }
        })(Data_Nullable.ordNullable(Types_Newtypes.ordCostClassCode)))()({
            reflectSymbol: function () {
                return "contract";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "changeDate";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString))))(Data_Array.filter((function () {
            var $112 = Data_Function.flip(Data_Set.member(Types_Component.ordEstimationResourceId))(v8.selectedIds);
            return function ($113) {
                return $112((function (v13) {
                    return v13.id;
                })($113));
            };
        })())(v1.value0.sortedResources));
        var resourceProps = {
            selectedMergedResourceKey: Data_Functor.map(Data_Maybe.functorMaybe)(function (v13) {
                return v13.key;
            })(v12),
            setMergedResourceSelection: function ($114) {
                return selectResource(Data_Maybe.Just.create($114));
            }
        };
        var tableProps = {
            resourceProps: resourceProps,
            projectId: props.projectId,
            projectCurrency: props.project.currency,
            viewMode: props.viewMode,
            programType: props.project.programType,
            isReportingProject: props.project.isReportingProject,
            selectedInputColumn: v1.value0.selectedInputColumn,
            setSelectedInputColumn: function (mCol) {
                return v1.value1(function (v13) {
                    return {
                        selectedResourceId: v13.selectedResourceId,
                        shouldScrollToSelection: v13.shouldScrollToSelection,
                        filterValue: v13.filterValue,
                        sortedResources: v13.sortedResources,
                        selectedInputColumn: mCol
                    };
                });
            },
            visibleColumns: visibleColumns$prime,
            showSocialExpensesInCosts: v10.showSocialExpensesInCosts,
            socialExpensePercentagesByCostClass: v10.socialExpensePercentagesByCostClass,
            componentSplitParams: Data_Maybe.Nothing.value,
            visibleResources: Data_Set.fromFoldable(Data_Foldable.foldableArray)(Types_Component.ordEstimationResourceId)(Data_Functor.map(Data_Functor.functorArray)(function (v13) {
                return v13.id;
            })(v1.value0.sortedResources)),
            selectedResources: v8.selectedIds,
            onClickSelectResource: v8.onClickSelection,
            toggleSelectAllResources: v8.toggleSelectAll,
            appDispatch: appDispatch,
            columnSizeOffsets: v4.value0
        };
        var publishingWarning = CostEstimation_ProductionPlanningWarning.projectPublishingStateWarning(props.project);
        var mkResourcesExport = function (v13) {
            return {
                project: props.project,
                dateString: Effect_Unsafe.unsafePerformEffect(function __do() {
                    var jsdate = Data_JSDate.now();
                    return Formatters.formatDateValue(jsdate);
                }),
                mergedResources: Data_Functor.map(Data_Functor.functorArray)(Types_MergedEstimationResource.fromMergedEstimationResource)(v1.value0.sortedResources),
                filterStrings: (function () {
                    var $67 = Data_String_Common["null"](v1.value0.filterValue);
                    if ($67) {
                        return Common.emptyArray;
                    };
                    return [ "Hae: " + v1.value0.filterValue ];
                })(),
                socialExpensePercentagesByCostClass: v10.socialExpensePercentagesByCostClass,
                showSocialExpensesInCosts: v10.showSocialExpensesInCosts,
                visibleColumns: v2.value0,
                useVisibleColumns: v3.value0
            };
        };
        var invalidResourcesWarning = Data_Monoid.guard(Data_Monoid.monoidFn(React_Basic.monoidJSX))(invalidCurrencyWarningEnabled)(TacoText.component()())({
            text: "Huom! Jotkin panokset k\xe4ytt\xe4v\xe4t virheellist\xe4 valuuttaa.",
            wrap: TacoText_Types.NoWrap.value,
            overflow: TacoText_Types.TextOverflowEllipsis.value
        });
        var initialScrollRow = (function () {
            if (initialState.selectedResourceId instanceof Data_Maybe.Nothing) {
                return Data_Maybe.Nothing.value;
            };
            if (initialState.selectedResourceId instanceof Data_Maybe.Just) {
                return Data_Array.findIndex(function (r) {
                    return Data_Eq.eq(Types_Component.eqEstimationResourceId)(r.id)(initialState.selectedResourceId.value0);
                })(v1.value0.sortedResources);
            };
            throw new Error("Failed pattern match at CostEstimation.ResourcesView (line 315, column 24 - line 317, column 86): " + [ initialState.selectedResourceId.constructor.name ]);
        })();
        var filterInput$prime = TacoInput.stringInput()()(Data_Eq.eqString)({
            value: v1.value0.filterValue,
            placeholder: "Hae",
            onChange: function (update) {
                return v1.value1(function (s) {
                    var filterValue = update(s.filterValue);
                    return {
                        selectedResourceId: Data_Maybe.Nothing.value,
                        shouldScrollToSelection: s.shouldScrollToSelection,
                        filterValue: filterValue,
                        sortedResources: s.sortedResources,
                        selectedInputColumn: s.selectedInputColumn
                    };
                });
            },
            clearButton: true,
            testId: "resource-filter-input"
        });
        var filterInput = Box.box("EstimationResourceFilterInput")([ Box.Width.create(new TacoConstants.CustomSize("16rem")) ])([ filterInput$prime ]);
        var editingProps = {
            viewMode: Types_CostEstimation.EditMode.value,
            programType: props.project.programType,
            isReportingProject: props.project.isReportingProject
        };
        var currencySymbol = EstimationUtils.getCurrencySymbol(props.project.currency);
        var resourceColumnSelector = TacoColumnSelector.columnSelector(Types_MergedEstimationResource.eqEstimationResourceColumn)(CostEstimation_ResourcesView_Table.controllableColumns)(CostEstimation_ResourcesView_Table.resourceColumnLabelText(currencySymbol))(v2.value0)(v2.value1);
        var columns = CostEstimation_ResourcesView_Table.getColumns(tableProps);
        var tableWhenReady = Data_Monoid.guard(React_Basic.monoidJSX)(!Data_Array["null"](v1.value0.sortedResources))(CostEstimation_ResourcesView_Table.resourcesTableOptimized({
            containerRef: tableRef,
            rows: Data_Functor.map(Data_Functor.functorArray)(CostEstimation_ResourcesView_Table.mkRowData(tableProps))(v1.value0.sortedResources),
            columns: columns,
            mkRow: CostEstimation_ResourcesView_Table.getRow(columns)(tableProps),
            sortProperty: v11.value0.value1,
            sortingOrder: v11.value0.value0,
            rowHeight: tableRowHeight,
            onSort: setSortingCriteria,
            showHeader: true,
            styleSet: CostEstimation_ResourcesView_Table.resourcesTableStyleSet,
            initialScrollRow: initialScrollRow,
            onResize: function (key) {
                return function (val) {
                    return v4.value1(function (offsets) {
                        return Data_Map_Internal.insert(Data_Ord.ordString)(key)(val)(offsets);
                    });
                };
            },
            columnSizeOffsets: new Data_Maybe.Just(v4.value0)
        }));
        var resourcesTable = CostEstimationTableWrapper.component({
            colorSet: TacoConstants.resourceColors,
            children: [ invalidResourcesWarning, tableWhenReady ],
            floatingActionButtons: Data_Monoid.guard(Data_Monoid.monoidFn(React_Basic.monoidJSX))(EstimationUtils.canEditView(editingProps))(CostEstimation_ResourcesView_UpdateResources.component)({
                projectId: props.projectId,
                selectedResources: selectedResources
            })
        });
        var actionsDropdown = TacoDropdown.uncontrolled({
            sections: [ {
                title: Data_Maybe.Nothing.value,
                items: [ SocialExpensesControls.component({
                    isSocialExpensesToggled: v10.showSocialExpensesInCosts,
                    onToggle: v10.onSocialExpensesToggle,
                    projectId: props.projectId,
                    disabled: Data_Maybe.Nothing.value
                }), EstimationExcelExportButton.estimationResourcesExcelExportButton({
                    mkExport: mkResourcesExport
                }), TacoCheckbox.component()()({
                    isChecked: v3.value0,
                    onToggle: TacoCheckbox.NoEvent.create(v3.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))),
                    labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Panosrivi ja sarakkeet, kuten n\xe4yt\xf6lle on valittu"))
                }) ]
            }, {
                title: Data_Maybe.Nothing.value,
                items: [ resourceColumnSelector ]
            } ],
            align: TacoDropdown_Types.AlignRight.value,
            testId: "resources-view-actions-dropdown"
        });
        var controls = Box.box("resourcesControlsContainer")([ new Box.FlexGrow(1), Box.FlexColumn.value, Box.AlignStretch.value ])([ Controls.component()()({
            leftControls: [ titleText, filterInput, publishingWarning ],
            rightControls: [ actionsDropdown ],
            gap: true,
            bottomGutter: false
        }) ]);
        var componentControls = Box.box("controlsWrapper")([ new Box.Padding(TacoConstants.S.value), new Box.FlexGrow(1) ])([ controls ]);
        var panelProps = CostEstimation_Layout.useCostEstimationLayout({
            leftPanelInitMode: CostEstimationLayout_Types.MinimizedMode.value,
            rightPanelInitMode: CostEstimationLayout_Types.MinimizedMode.value,
            detailsPanelInitMode: CostEstimationLayout_Types.SmallMode.value,
            localStorageKey: "ResourcesView"
        })();
        var mkResourceDetails = function (selectedResource$prime) {
            return CostEstimation_ResourcesView_SelectedResourceDetails.selectedResourceDetails({
                selectedMergedResource: selectedResource$prime,
                panelMode: panelProps.detailsPanelMode,
                setPanelMode: panelProps.setDetailsPanelMode,
                projectId: props.projectId,
                projectLocations: v5.value0,
                estimationLocations: v6.value0,
                socialExpensePercentages: v10.socialExpensePercentagesByCostClass
            });
        };
        var resourceDetails = Data_Functor.map(Data_Maybe.functorMaybe)(mkResourceDetails)(v12);
        var v13 = ReferenceLibrarySidepanel.useActiveRefMode();
        return CostEstimation_Layout.component({
            controls: Data_Maybe.Just.create(React_Basic.fragment([ componentControls ])),
            controlsLoading: CostEstimationLayout_Types.LoadingFinished.value,
            leftPanel: Data_Maybe.Just.create(CostEstimation_SummariesPanel.component({
                panelMode: panelProps.leftPanelMode,
                getContents: function (v14) {
                    return ResourceSummaries.component({
                        resources: v1.value0.sortedResources,
                        projectId: props.projectId
                    });
                }
            })),
            leftPanelLoading: (function () {
                if (v9.isLoading) {
                    return CostEstimationLayout_Types.LoadingBarDimmedContents.value;
                };
                return CostEstimationLayout_Types.LoadingFinished.value;
            })(),
            leftSidebar: Data_Maybe.Just.create(CostEstimation_SummariesSidebar.component({
                panelMode: panelProps.leftPanelMode,
                setPanelMode: panelProps.setLeftPanelMode
            })),
            main: resourcesTable,
            rightPanel: Data_Maybe.Just.create(ReferenceLibrarySidepanel.component({
                selectedEditViewElement: Data_Maybe.Nothing.value,
                selectedEditViewElementMeasures: Data_Maybe.Nothing.value,
                selectedEditViewElementSpecId: Data_Maybe.Nothing.value,
                selectedEditViewComponent: Data_Maybe.Nothing.value,
                selectedEditViewResource: v12,
                selectedEditViewResourceIds: v8.selectedIds,
                projectId: props.projectId,
                project: props.project,
                activeRefMode: v13.activeRefMode
            })),
            rightSidebar: Data_Maybe.Just.create(ReferenceLibrarySidebar.component({
                activeRefMode: v13.activeRefMode,
                setActiveRefMode: v13.setActiveRefMode
            })),
            details: resourceDetails,
            panelProps: {
                leftPanelMode: panelProps.leftPanelMode,
                setLeftPanelMode: panelProps.setLeftPanelMode,
                rightPanelMode: (function () {
                    if (v13.hasActiveRefMode) {
                        return CostEstimationLayout_Types.NormalMode.value;
                    };
                    return CostEstimationLayout_Types.MinimizedMode.value;
                })(),
                setRightPanelMode: panelProps.setRightPanelMode,
                detailsPanelMode: panelProps.detailsPanelMode,
                setDetailsPanelMode: panelProps.setDetailsPanelMode
            },
            mRefPanelMode: Data_Maybe.Nothing.value
        });
    };
});
module.exports = {
    tableRowHeight: tableRowHeight,
    findOfSelectedResource: findOfSelectedResource,
    findNewIdxOfSelectedResource: findNewIdxOfSelectedResource,
    component: component,
    filterWithValue: filterWithValue,
    sortAndFilterResources: sortAndFilterResources,
    handleCursorNavigation: handleCursorNavigation
};
