// Generated by purs version 0.14.5
"use strict";
var Data_Newtype = require("../Data.Newtype/index.js");
var QuantityFormula_Parser = require("../QuantityFormula.Parser/index.js");
var QuantityFormula_Types = require("../QuantityFormula.Types/index.js");
var toUserFormulaStr = function (str) {
    return Data_Newtype.un()(QuantityFormula_Types.UserFormula)(QuantityFormula_Parser.convertToUserFormula(str));
};
var toInternalFormulaStr = function (str) {
    return Data_Newtype.un()(QuantityFormula_Types.InternalFormula)(QuantityFormula_Parser.convertToInternalFormula(str));
};
module.exports = {
    toUserFormulaStr: toUserFormulaStr,
    toInternalFormulaStr: toInternalFormulaStr
};
