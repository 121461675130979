import { useQuery } from "react-query";
import axiosClient from "../../axiosConfig";
import { UserOrganizationsData } from "../../../server-ts/users-organizations/UsersOrganizationsRouter";

export function useGetUsersOrgsData(): UserOrganizationsData | undefined {
  const { data } = useQuery({
    queryKey: ["/users/me/organizations"],
    queryFn: async () => {
      const { data } = await axiosClient.get<UserOrganizationsData>(
        "/users/me/organizations"
      );
      return data;
    },
  });
  return data;
}
