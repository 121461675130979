// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var projectDetailsSectionStyles = Box.boxStyle([ new Box.MarginTop(TacoConstants.XXL.value) ]);
var projectComponentsContainerStyles = Box.boxStyle([ Box.MaxWidth.create(new TacoConstants.CustomSize("64rem")) ]);
module.exports = {
    projectComponentsContainerStyles: projectComponentsContainerStyles,
    projectDetailsSectionStyles: projectDetailsSectionStyles
};
