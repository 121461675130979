// Generated by purs version 0.14.5
"use strict";
var CostGroupDetails_CostGroupItems = require("../CostGroupDetails.CostGroupItems/index.js");
var Data_Array = require("../Data.Array/index.js");
var prepareEstimateItemsTabProperties = function (props) {
    return {
        tabItemsCount: Data_Array.length(props.estimateItems),
        estimateItemsTab: CostGroupDetails_CostGroupItems.estimateItemsListingComponent({
            estimateItems: props.estimateItems,
            costClassResults: props.costClassResults,
            costGroupUnit: props.costGroupUnit,
            costClasses: props.costClasses,
            socialExpenses: props.socialExpenses
        })
    };
};
module.exports = {
    prepareEstimateItemsTabProperties: prepareEstimateItemsTabProperties
};
