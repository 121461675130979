// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var releaseNotesContainer = TofuHooks.mkHookComponent("ReleaseNotesContainer")(function (v) {
    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_DOM_Generated.div()({
        className: "markdown-body",
        dangerouslySetInnerHTML: {
            "__html": $foreign.renderHtml($foreign.releaseNotes)
        }
    }));
});
module.exports = {
    releaseNotesContainer: releaseNotesContainer
};
