// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Controls = require("../Controls/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var LengthValidator = require("../LengthValidator/index.js");
var ProjectComponents_Styles = require("../ProjectComponents.Styles/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var StateHooks = require("../StateHooks/index.js");
var StateUtils_Utils = require("../StateUtils.Utils/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoLoader = require("../TacoLoader/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var UseFetch = require("../UseFetch/index.js");
var component = TofuHooks.mkHookComponent("AddIncomeGroup")(function (props) {
    return function __do() {
        var v = TofuHooks.useState("")();
        var v1 = TofuHooks.useState("")();
        var appDispatch = StateHooks.useDispatch();
        StateHooks.useSelector(StateUtils_Utils.getCurrentProject(new Data_Maybe.Just(props.projectId)))();
        var v2 = UseFetch.useFetchValueByProjectId(props.projectId)(function (v2) {
            return v2.incomeGroups;
        })(Actions.GetIncomeGroupsRequest.create)();
        var submitBtn = Controls.component()()({
            leftControls: [ TacoButton.component()()({
                align: TacoButton.defaultProps.align,
                buttonOverflow: TacoButton.defaultProps.buttonOverflow,
                buttonStyle: TacoButton_Types.Outlined.value,
                children: TacoButton.defaultProps.children,
                className: TacoButton.defaultProps.className,
                color: TacoButton.defaultProps.color,
                disableFocusHack: TacoButton.defaultProps.disableFocusHack,
                disabled: TacoButton.defaultProps.disabled,
                href: TacoButton.defaultProps.href,
                icon: TacoButton.defaultProps.icon,
                iconAlign: TacoButton.defaultProps.iconAlign,
                iconColor: TacoButton.defaultProps.iconColor,
                iconRotation: TacoButton.defaultProps.iconRotation,
                iconSize: TacoButton.defaultProps.iconSize,
                newTab: TacoButton.defaultProps.newTab,
                onClick: TacoButton.defaultProps.onClick,
                onClickEventHandler: TacoButton.defaultProps.onClickEventHandler,
                size: TacoButton.defaultProps.size,
                styles: TacoButton.defaultProps.styles,
                submit: true,
                testId: TacoButton.defaultProps.testId,
                text: "Lis\xe4\xe4 maksuryhm\xe4",
                title: TacoButton.defaultProps.title,
                vertical: TacoButton.defaultProps.vertical
            }) ],
            topGutter: true
        });
        var onSubmit = React_Basic_Events.handler(React_Basic_DOM_Events.preventDefault)(function (v3) {
            return appDispatch(new Actions.AddIncomeGroupRequest({
                projectId: props.projectId,
                value: {
                    code: v.value0,
                    name: v1.value0
                }
            }));
        });
        var incomeGroups$prime = (function () {
            var loading = React_Basic_DOM_Generated.div()({
                className: "incomeItem-groups-loading",
                children: [ TacoLoader.component()()({
                    size: "1rem"
                }) ]
            });
            var group = function (i) {
                return React_Basic_DOM_Generated.li()({
                    children: [ React_Basic_DOM.text(Data_Newtype.unwrap()(i.code) + (" " + Data_Newtype.unwrap()(i.name))) ]
                });
            };
            var groups$prime = function (xs) {
                return React_Basic_DOM_Generated.ol()({
                    children: Data_Functor.map(Data_Functor.functorArray)(group)(xs)
                });
            };
            var groups = (function () {
                if (v2.value0 instanceof Data_Maybe.Just) {
                    return groups$prime(v2.value0.value0.items);
                };
                if (v2.value0 instanceof Data_Maybe.Nothing) {
                    return loading;
                };
                throw new Error("Failed pattern match at AddIncomeGroup (line 59, column 18 - line 61, column 29): " + [ v2.value0.constructor.name ]);
            })();
            return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                className: "incomeItem-groups",
                css: ProjectComponents_Styles.projectDetailsSectionStyles,
                children: [ TacoText.component()()({
                    text: "Maksuer\xe4ryhm\xe4t",
                    weight: TacoText_Types.Bold.value,
                    gutterBottom: true,
                    variant: TacoText_Types.Paragraph.value
                }), groups ]
            });
        })();
        return React_Basic_DOM_Generated.form()({
            className: "incomeItem-groups-form",
            children: [ incomeGroups$prime, Box.box("fields")([ new Box.MarginLeft(TacoConstants.XS.value), new Box.MaxWidth(new TacoConstants.CustomSize("16rem")) ])([ TacoInput.stringInput()()(Data_Eq.eqString)({
                label: "Tunnus",
                validator: LengthValidator.lengthValidator(0)(10),
                value: Data_Newtype.unwrap()(v.value0),
                required: true,
                onChange: TacoInput.transformInput(TacoInput.inputValueString)(Data_Newtype.wrap())(v.value1)
            }), TacoInput.stringInput()()(Data_Eq.eqString)({
                label: "Nimi",
                validator: LengthValidator.lengthValidator(0)(50),
                value: Data_Newtype.unwrap()(v1.value0),
                required: true,
                onChange: TacoInput.transformInput(TacoInput.inputValueString)(Data_Newtype.wrap())(v1.value1)
            }) ]), submitBtn ],
            onSubmit: onSubmit
        });
    };
});
module.exports = {
    component: component
};
