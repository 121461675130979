import { TableProps } from "@tocoman/ui";
import { useTranslation } from "react-i18next";

export const useCommonTableProps = <T>(): Partial<TableProps<T>> => {
  const { t } = useTranslation("app", { keyPrefix: "table" });

  return {
    tableResetLabel: t`tableResetLabel`,
    tableColumnVisibilityLabel: t`tableColumnVisibilityLabel`,
  };
};
