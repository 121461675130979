import {
  Button,
  ConfirmationModal,
  Header,
  Modal,
  TabProps,
  Tabs,
} from "@tocoman/ui";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useWorkerState } from "../../../hooks/useWorkerState";
import { Trans, useTranslation } from "react-i18next";
import { CostClassesTab } from "./CostClassesTab";
import { useForm, FormProvider } from "react-hook-form";
import { ProjectDetailsData } from "ts-bindings/ProjectDetailsData";
import { BasicInfoTab } from "./BasicInfoTab";
import { OptionalInfoTab } from "./OptionalInfoTab";
import { MeasurementUnitsTab } from "./MeasurementUnitsTab";
import { MemoTab } from "./MemoTab";
import {
  useDeleteProject,
  useReportingAction,
  useSaveProject,
  useTogglePublishedToTempo,
} from "./useProjectAction";
import { useCostClasses, useMeasurements } from "./useProjectData";
import { useCloseModalOnSaveComplete } from "../useChooseNewProjectOnModalClose";
import { useProjectExportQuery } from "./useProjectExport";
import { reportError } from "src/client-ts/utils/error";
import { downloadBlob } from "src/client-ts/utils/downloadBlob";
import { useDefault } from "../../../hooks/useDefault";

type TabIds =
  | "required"
  | "optional"
  | "costClasses"
  | "measurementUnits"
  | "memo";

type Props = {
  isOpen: boolean;
  projectId?: number;
  closeModal: () => void;
  newProject: boolean;
  onSave: (savedProjectId: number) => void;
};

export type ProjectFormValues = ProjectDetailsData & {
  publishedToTempo: boolean;
};

export const ProjectDetailsModal = ({
  isOpen,
  closeModal,
  projectId,
  newProject,
  onSave,
}: Props) => {
  const projectDetailsData = useWorkerState(
    "ProjectDetailsDataState",
    projectId
  );

  const [selectedTab, setSelectedTab] = useState<TabIds>("required");

  const { t } = useTranslation("projects", { keyPrefix: "details" });

  const methods = useForm<ProjectFormValues>({
    defaultValues: {
      taxRate: 25.5,
      state: t`form.stateTypes.construction`,
      contractPrice: 0,
      projectGroup: null,
      isReportingProject: false,
      version: newProject ? "1" : "",
    },
    mode: "onBlur",
  });
  const {
    handleSubmit,
    reset,
    watch,
    formState: { errors },
    setError,
  } = methods;

  const existingValidationErrors = Object.keys(errors).length !== 0;

  const watchClassification = watch("classification");
  const watchName = watch("name");
  const watchCode = watch("code");

  const [measurementUnits, setMeasurementUnits] = useMeasurements(
    watchClassification,
    newProject,
    projectId
  );

  const [costClasses, setCostClasses] = useCostClasses(
    watchClassification,
    newProject,
    projectId
  );

  const subprojects = useDefault(
    useWorkerState("EstimationSubprojectsState", projectId),
    []
  );

  const [saveStatus, saveProjectForm] = useSaveProject(newProject);
  const [_, deleteProject] = useDeleteProject();
  useCloseModalOnSaveComplete(onSave, saveStatus, projectId);

  const [handleReportingAction] = useReportingAction();

  const publishToTempo = useTogglePublishedToTempo(projectId);

  // If projectDetailsData changes (i.e. projectDetailsData of another project
  // has just been loaded ), populate the form with values from the existing
  // project
  useEffect(() => {
    if (projectDetailsData && !newProject) {
      reset({
        ...projectDetailsData,
      });
    }
  }, [projectDetailsData, newProject, reset]);

  const handleSaveOnClick = useMemo(
    () => (value: ProjectFormValues) => {
      const nameMissing = watchName === "";
      const codeMissing = watchCode === "";
      if (nameMissing || codeMissing) {
        setSelectedTab("required");
        nameMissing &&
          setError("name", { type: "required", message: t`errors.required` });
        codeMissing &&
          setError("code", { type: "required", message: t`errors.required` });
        return;
      }
      if (projectId || newProject) {
        saveProjectForm(projectId, value, costClasses, measurementUnits);
      }
      if (
        projectId &&
        projectDetailsData &&
        projectDetailsData.isReportingProject !== value.isReportingProject
      ) {
        handleReportingAction(
          projectId,
          projectDetailsData.isReportingProject,
          value.isReportingProject
        );
      }
      if (
        projectId &&
        projectDetailsData &&
        projectDetailsData.publishedToTempo !== value.publishedToTempo
      ) {
        publishToTempo.mutate(value.publishedToTempo);
      }
    },
    [
      watchName,
      setError,
      t,
      watchCode,
      projectId,
      newProject,
      projectDetailsData,
      saveProjectForm,
      costClasses,
      measurementUnits,
      handleReportingAction,
    ]
  );

  const {
    refetch: fetchExportedProject,
    isLoading: projectExportLoading,
  } = useProjectExportQuery(projectId);

  const handleExportProject = useCallback(async () => {
    const result = await fetchExportedProject();
    const dateString = new Date().toISOString().split("T")[0];

    if (
      !result.isError &&
      projectDetailsData !== null &&
      result.data !== undefined
    ) {
      downloadBlob(
        result.data,
        `viety-hanke-${projectDetailsData.name}-${dateString}.tcm`
      );
    }

    if (result.isError) {
      reportError(t`edit.projectExportError`, result.error as Error);
    }
  }, [fetchExportedProject, projectDetailsData, t]);

  const handleDeleteOnClick = useMemo(
    () => (id: number | undefined) => {
      if (id && projectDetailsData) {
        deleteProject(id, projectDetailsData.version, projectDetailsData.code);
        if (window.location.pathname !== "/estimation") {
          window.location.href = "/estimation";
        }
      }
    },
    [projectDetailsData, deleteProject]
  );

  const actions = useMemo(
    () => (
      <>
        {!newProject && projectDetailsData && (
          <ConfirmationModal
            title={t`deleteProjectModal.title`}
            prompt={
              <Trans
                ns="projects"
                i18nKey={"details.deleteProjectModal.description"}
                values={{ name: projectDetailsData.name }}
              />
            }
            buttonText={t`deleteProject`}
            buttonProps={{ variant: "text", color: "danger" }}
            confirmText={t`deleteProjectModal.confirm`}
            cancelText={t`deleteProjectModal.cancel`}
            onConfirm={() => handleDeleteOnClick(projectId)}
          />
        )}
        <div className="ml-auto gap-4 flex">
          <Button
            variant="text"
            onClick={() => closeModal()}
          >{t`cancel`}</Button>
          <Button
            onClick={handleSubmit(handleSaveOnClick)}
            loading={saveStatus === "Pending"}
            disabled={saveStatus === "Pending" || existingValidationErrors}
            testId={"project-save-btn"}
          >{t`save`}</Button>
        </div>
      </>
    ),
    [
      newProject,
      projectDetailsData,
      t,
      closeModal,
      handleSubmit,
      handleSaveOnClick,
      handleDeleteOnClick,
      projectId,
      saveStatus,
      existingValidationErrors,
    ]
  );

  const modalTitle = useMemo(() => {
    if (newProject) {
      return t`new.title`;
    } else {
      return (
        <Trans
          ns="projects"
          i18nKey="details.edit.title"
          values={{ name: projectDetailsData?.name ?? "" }}
        />
      );
    }
  }, [projectDetailsData, newProject, t]);

  const tabs: TabProps<TabIds>[] = useMemo(
    () => [
      { id: "required", color: "components", label: t`tabs.required` },
      { id: "optional", color: "components", label: t`tabs.optional` },
      { id: "costClasses", color: "components", label: t`tabs.costClasses` },
      {
        id: "measurementUnits",
        color: "components",
        label: t`tabs.measurementUnits`,
      },

      { id: "memo", color: "components", label: t`tabs.memo` },
    ],
    [t]
  );

  return (
    <FormProvider {...methods}>
      <Modal
        closeModal={() => closeModal()}
        isOpen={isOpen}
        width={960}
        title={modalTitle}
        actions={actions}
        loading={!newProject && projectDetailsData === null}
      >
        <Tabs
          tabs={tabs}
          selectedTab={selectedTab}
          onTabChange={setSelectedTab}
          matchParentWidth
        />
        {selectedTab === "required" && (
          <>
            <Header title={t`tabs.required`}>
              <Trans
                ns="projects"
                i18nKey={"details.tabDescriptions.required"}
              />
            </Header>
            <BasicInfoTab newProject={newProject} projectId={projectId} />
          </>
        )}
        {selectedTab === "optional" && (
          <>
            <Header
              title={t`tabs.optional`}
            >{t`tabDescriptions.optional`}</Header>
            <OptionalInfoTab />
          </>
        )}
        {selectedTab === "costClasses" && (
          <CostClassesTab
            projectId={projectId}
            costClasses={costClasses}
            onChange={setCostClasses}
          />
        )}
        {selectedTab === "measurementUnits" && (
          <MeasurementUnitsTab
            projectId={projectId}
            onChange={setMeasurementUnits}
            measurementUnits={measurementUnits}
            subprojects={subprojects}
          />
        )}
        {selectedTab === "memo" && (
          <>
            <Header title={t`tabs.memo`}>{t`tabDescriptions.memo`}</Header>
            <MemoTab />
          </>
        )}
        <div>
          <Button
            variant="secondary"
            icon={() => <ImportExportIcon className="rotate-90" />}
            iconPosition="left"
            label={t`edit.projectExport`}
            onClick={handleExportProject}
            loading={projectExportLoading}
            disabled={
              projectExportLoading || projectDetailsData === null || newProject
            }
          />
        </div>
      </Modal>
    </FormProvider>
  );
};
