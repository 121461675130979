import { useEffect, useState } from "react";

export const useReadReleaseNoteIds = () => {
  const [state, setState] = useState<number[]>([]);

  useEffect(() => {
    const ids = JSON.parse(localStorage.getItem("readReleaseNoteIds") ?? "[]");
    setState(ids);
  }, []);

  const setReadReleaseNoteIds = (ids: Array<number>) => {
    localStorage.setItem("readReleaseNoteIds", JSON.stringify(ids));
    setState(ids);
  };

  return [state, setReadReleaseNoteIds] as const;
};
