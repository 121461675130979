import { AxiosError } from "axios";
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import axiosClient from "src/client-ts/axiosConfig";
import { reportError } from "src/client-ts/utils/error";
import { DefaultCurrency } from "src/server-ts/organizations/OrganizationCurrencyRouter";

type AxiosErrorWithMessage = AxiosError<{ message: string }>;

const baseQueryUrl = "/organization/currencies";
const baseDefaultQueryUrl = `${baseQueryUrl}/default`;
const baseAllowedQueryUrl = `${baseQueryUrl}/allowed`;

export function useDefaultCurrencyQuery(): UseQueryResult<DefaultCurrency> {
  return useQuery(`${baseQueryUrl}/default`, async () => {
    const res = await axiosClient.get(baseDefaultQueryUrl);
    return res.data;
  });
}

export function useAllowedCurrenciesQuery(): UseQueryResult<string[]> {
  return useQuery(`${baseQueryUrl}/allowed`, async () => {
    const res = await axiosClient.get<string[]>(baseAllowedQueryUrl);
    return res.data;
  });
}

const baseManagementUrl = "/organization/management/currencies";
const defaultRouteUrl = `${baseManagementUrl}/default`;
const allowedRouteUrl = `${baseManagementUrl}/allowed`;

export function useDefaultCurrencyMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (newLanguage: string) => {
      return await axiosClient.put(defaultRouteUrl, {
        defaultCurrency: newLanguage,
      });
    },
    onMutate: async (newLanguage: string) => {
      await queryClient.cancelQueries(baseDefaultQueryUrl);
      const previousData = queryClient.getQueryData<DefaultCurrency>(
        baseDefaultQueryUrl
      );
      queryClient.setQueryData<DefaultCurrency>(baseDefaultQueryUrl, {
        defaultCurrency: newLanguage,
      });
      return { previousData };
    },
    onError: (err: AxiosErrorWithMessage, newLanguage, context) => {
      reportError(
        err.response?.data?.message ??
          `Error while trying to change default currency to ${newLanguage}`,
        err as AxiosError
      );
      queryClient.setQueryData(baseDefaultQueryUrl, context?.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries(baseDefaultQueryUrl);
    },
  });
}

export function useAllowedCurrencyMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (newLanguage: string) => {
      return await axiosClient.post(allowedRouteUrl, {
        currency: newLanguage,
      });
    },
    onMutate: async (newLanguage: string) => {
      await queryClient.cancelQueries(baseAllowedQueryUrl);
      const previousData = queryClient.getQueryData<string[]>(
        baseAllowedQueryUrl
      );
      queryClient.setQueryData<string[]>(baseAllowedQueryUrl, (oldData) => {
        return [...(oldData ?? []), newLanguage];
      });
      return { previousData };
    },
    onError: (err: AxiosErrorWithMessage, newLanguage, context) => {
      reportError(
        err.response?.data?.message ??
          `Error while trying to add currency ${newLanguage}`,
        err as AxiosError
      );
      queryClient.setQueryData(baseAllowedQueryUrl, context?.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries(baseAllowedQueryUrl);
    },
  });
}

export function useAllowedCurrencyDeleteMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (currency: string) => {
      return await axiosClient.delete(allowedRouteUrl, {
        data: { currency },
      });
    },
    onMutate: async (currency: string) => {
      await queryClient.cancelQueries(baseAllowedQueryUrl);
      const previousData = queryClient.getQueryData<string[]>(
        baseAllowedQueryUrl
      );
      queryClient.setQueryData<string[]>(baseAllowedQueryUrl, (oldData) => {
        return oldData?.filter((c) => c !== currency) ?? [];
      });
      return { previousData };
    },
    onError: (err: AxiosErrorWithMessage, currency, context) => {
      reportError(
        err.response?.data?.message ??
          `Error while trying to delete currency ${currency}`,
        err
      );
      queryClient.setQueryData(baseAllowedQueryUrl, context?.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries(baseAllowedQueryUrl);
    },
  });
}

export function useGetCurrencyIsInUseQuery(currency: string) {
  return useQuery([`${allowedRouteUrl}/in-use`, currency], async () => {
    const res = await axiosClient.get<boolean>(`${allowedRouteUrl}/in-use`, {
      params: {
        code: currency,
      },
    });
    return res.data;
  });
}
