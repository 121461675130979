import { Button, Input } from "@tocoman/ui";
import { CSSProperties, SyntheticEvent, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";

const modalInputStyle: CSSProperties = {
  marginBottom: ".5em",
};

const searchButtonStyle: CSSProperties = {
  alignSelf: "flex-end",
  marginBottom: "6px",
};

type AdminetProjectNameSearchProps = {
  disabled: boolean;
  adminetProjectsLoading: boolean;
  onChange: (searchWord: string) => void;
};

export const AdminetProjectNameSearch = ({
  disabled,
  adminetProjectsLoading,
  onChange,
}: AdminetProjectNameSearchProps) => {
  const { t } = useTranslation("integration");
  const searchWord = useRef("");

  const onSubmit = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();
      onChange(searchWord.current);
    },
    [searchWord, onChange]
  );

  return (
    <form onSubmit={onSubmit} className="flex gap-2">
      <Input
        label={t`adminet.getAdminetProjects.searchProjectsLabel`}
        style={modalInputStyle}
        onChange={(event) => (searchWord.current = event.target.value)}
        placeholder={t`adminet.getAdminetProjects.searchProjectsPlaceHolder`}
      />

      <Button
        style={searchButtonStyle}
        type="submit"
        disabled={disabled}
        loading={adminetProjectsLoading}
      >
        {t`adminet.getAdminetProjects.search`}
      </Button>
    </form>
  );
};
