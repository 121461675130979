var Table = require("src/client-ts/components/Table");

exports._Table = Table.Table;

// Given an edit request from ag grid, create a the new row
exports._newRequestFromEditRequestEvent = (event) => {
  console.log("in newRequestFromEditRequestEvent", event);
  const data = event.data;
  const field = event.colDef.field;
  const newValue = event.newValue;
  if (!field) {
    return;
  }
  const newItem = Object.assign({}, data);

  newItem[field] = newValue;
  console.log("newItem", newItem);
  return newItem;
};

// This seems silly but since an Effect Unit in purescript is a function,
// we want to add an "extra" () to actually call it.
exports.mkForeignHandler = (updateFN) => (event) => {
  updateFN(event)();
};
