// Generated by purs version 0.14.5
"use strict";
var Data_Array = require("../Data.Array/index.js");
var Gap = require("../Gap/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_DOM_Internal = require("../React.Basic.DOM.Internal/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var example3 = "import Gap (gapY)\x0aimport TacoConstants (Size(..))\x0a\x0a[ gapX M $ Array.replicate 3 $ gapY M $ Array.replicate 3 child ]";
var example2 = "import Gap (gapY)\x0aimport TacoConstants (Size(..))\x0a\x0a[ R.div_ [ gapY XL $ Array.replicate 5 child ] ]";
var example1 = "import Gap (gapX)\x0aimport TacoConstants (Size(..))\x0a\x0a[ R.div_ [ gapX XS $ Array.replicate 5 child ]\x0a, R.div_ [ gapX M  $ Array.replicate 5 child ]\x0a, R.div_ [ gapX XL $ Array.replicate 5 child ]\x0a]";
var child = React_Basic_DOM_Generated.div()({
    style: React_Basic_DOM_Internal.css({
        width: "4rem",
        height: "4rem",
        backgroundColor: "#fa4"
    })
});
var sections = [ {
    title: "gapX",
    view: [ React_Basic_DOM_Generated.div_([ Gap.gapX(TacoConstants.XS.value)(Data_Array.replicate(5)(child)) ]), React_Basic_DOM_Generated.div_([ Gap.gapX(TacoConstants.M.value)(Data_Array.replicate(5)(child)) ]), React_Basic_DOM_Generated.div_([ Gap.gapX(TacoConstants.XL.value)(Data_Array.replicate(5)(child)) ]) ],
    code: example1
}, {
    title: "gapY",
    view: [ React_Basic_DOM_Generated.div_([ Gap.gapY(TacoConstants.XL.value)(Data_Array.replicate(3)(child)) ]) ],
    code: example2
}, {
    title: "Nested",
    view: [ Gap.gapX(TacoConstants.M.value)(Data_Array.replicate(3)(Gap.gapY(TacoConstants.M.value)(Data_Array.replicate(3)(child)))) ],
    code: example3
} ];
var demoView = {
    sections: sections,
    title: "Gap",
    description: "Flex container with a gap between children, flows in either x or y direction"
};
module.exports = {
    demoView: demoView
};
