// Generated by purs version 0.14.5
"use strict";
var Components_SubNavigation = require("../Components.SubNavigation/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Effect = require("../Effect/index.js");
var Locale = require("../Locale/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_FrontEndRoutes = require("../Types.FrontEndRoutes/index.js");
var rootNavbar = TofuHooks.mkHookComponent("RootNavbar")(function (props) {
    var items = [ new Components_SubNavigation.Link({
        title: Locale.lookup_("project_list"),
        color: TacoConstants.PrimaryBlue.value,
        active: Data_Eq.eq(Types_FrontEndRoutes.eqFERoute)(Types_FrontEndRoutes.ReportingProjectListRoute.value)(props.currentRoute),
        href: Types_FrontEndRoutes["fragments"]["project-list"],
        route: Types_FrontEndRoutes.ReportingProjectListRoute.value,
        id: "navigation-project-list"
    }), new Components_SubNavigation.Link({
        title: Locale.lookup_("portfolio_report"),
        color: TacoConstants.PrimaryBlue.value,
        active: Data_Eq.eq(Types_FrontEndRoutes.eqFERoute)(Types_FrontEndRoutes.PortfolioReportFERoute.value)(props.currentRoute),
        href: Types_FrontEndRoutes["fragments"]["portfolio-report"],
        route: Types_FrontEndRoutes.PortfolioReportFERoute.value,
        id: "navigation-portfolio-report"
    }) ];
    return Control_Applicative.pure(Effect.applicativeEffect)(Components_SubNavigation.component({
        items: items
    }));
});
module.exports = {
    rootNavbar: rootNavbar
};
