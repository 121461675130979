import React, { useEffect } from "react";
import { AgGridReact } from "ag-grid-react";

export function useRowDataUpdater<T, RT>(
  rowData: T[] | null,
  gridRef: React.RefObject<AgGridReact>,
  newData: RT[]
) {
  useEffect(() => {
    if (rowData && newData && gridRef.current?.api) {
      gridRef.current.api.setGridOption("rowData", [...rowData, ...newData]);
    }
  }, [newData, rowData]);
}
