// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var ComponentsView_Styles = require("../ComponentsView.Styles/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var CostEstimation_Layout = require("../CostEstimation.Layout/index.js");
var CostEstimation_Styles = require("../CostEstimation.Styles/index.js");
var CostEstimationLayout_Types = require("../CostEstimationLayout.Types/index.js");
var CostEstimationTableWrapper = require("../CostEstimationTableWrapper/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unfoldable = require("../Data.Unfoldable/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Edited = require("../Edited/index.js");
var Effect = require("../Effect/index.js");
var EstimationResourcesList = require("../EstimationResourcesList/index.js");
var EstimationUtils = require("../EstimationUtils/index.js");
var FeatureHooks = require("../FeatureHooks/index.js");
var Formatters = require("../Formatters/index.js");
var LocationEditor = require("../LocationEditor/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var ScrollContainer = require("../ScrollContainer/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoAutocomplete = require("../TacoAutocomplete/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoLoader = require("../TacoLoader/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoTabs = require("../TacoTabs/index.js");
var TacoTabs_Types = require("../TacoTabs.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_ClassificationGroup = require("../Types.ClassificationGroup/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_CostEstimation = require("../Types.CostEstimation/index.js");
var Types_Element = require("../Types.Element/index.js");
var Types_Estimation = require("../Types.Estimation/index.js");
var Types_FrontEndRoutes = require("../Types.FrontEndRoutes/index.js");
var Types_JSDateString = require("../Types.JSDateString/index.js");
var Types_Location = require("../Types.Location/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var UseLicenseFeature = require("../UseLicenseFeature/index.js");
var Utils = require("../Utils/index.js");
var Code = (function () {
    function Code() {

    };
    Code.value = new Code();
    return Code;
})();
var Description = (function () {
    function Description() {

    };
    Description.value = new Description();
    return Description;
})();
var Amount = (function () {
    function Amount() {

    };
    Amount.value = new Amount();
    return Amount;
})();
var Unit = (function () {
    function Unit() {

    };
    Unit.value = new Unit();
    return Unit;
})();
var AmountSource = (function () {
    function AmountSource() {

    };
    AmountSource.value = new AmountSource();
    return AmountSource;
})();
var CreateTime = (function () {
    function CreateTime() {

    };
    CreateTime.value = new CreateTime();
    return CreateTime;
})();
var prepareLocationsMatrixParams = function (componentId) {
    return function (m) {
        var xs = Data_Map_Internal.toUnfoldable(Data_Unfoldable.unfoldableArray)(m);
        var newAmounts = Data_Function.flip(Data_Array.mapMaybe)(xs)(function (v) {
            if (v.value1.cell instanceof LocationEditor.NewLocation && v.value1.cell.value0.amount instanceof Data_Maybe.Just) {
                return new Data_Maybe.Just({
                    locationCode: v.value1.cell.value0.code,
                    amount: v.value1.cell.value0.amount.value0,
                    description: LocationEditor.getDescriptionString(v.value1.cell.value0.description)
                });
            };
            if (v.value1.cell instanceof LocationEditor.NewLocation && v.value1.cell.value0.description.edited instanceof Data_Maybe.Just) {
                return new Data_Maybe.Just({
                    locationCode: v.value1.cell.value0.code,
                    amount: 0.0,
                    description: v.value1.cell.value0.description.edited.value0
                });
            };
            return Data_Maybe.Nothing.value;
        });
        var modifiedAmounts = Data_Function.flip(Data_Array.mapMaybe)(xs)(function (v) {
            if (v.value1.cell instanceof LocationEditor.ExistingLocation && v.value1.cell.value1.amount instanceof Edited.Edited) {
                return new Data_Maybe.Just({
                    estimationLocationId: v.value1.cell.value0.id,
                    amount: v.value1.cell.value1.amount.value0,
                    description: LocationEditor.getDescriptionString(v.value1.cell.value1.description)
                });
            };
            if (v.value1.cell instanceof LocationEditor.ExistingLocation && v.value1.cell.value1.description.edited instanceof Data_Maybe.Just) {
                return new Data_Maybe.Just({
                    estimationLocationId: v.value1.cell.value0.id,
                    amount: v.value1.cell.value0.amount,
                    description: v.value1.cell.value1.description.edited.value0
                });
            };
            return Data_Maybe.Nothing.value;
        });
        return {
            newAmounts: newAmounts,
            modifiedAmounts: modifiedAmounts,
            componentId: componentId
        };
    };
};
var mkSaveChangesAction = function (projectId) {
    return function (componentId) {
        return function (setRequestInProgress) {
            return function (appDispatch) {
                return function (cells) {
                    var params = prepareLocationsMatrixParams(componentId)(cells);
                    var action = new Types_Estimation.EstimationLocationsMatrixAction(params);
                    return function __do() {
                        setRequestInProgress(function (v) {
                            return true;
                        })();
                        return appDispatch(new Actions.EstimationLocationActionRequest({
                            projectId: projectId,
                            params: action,
                            handler: function (v) {
                                return setRequestInProgress(function (v1) {
                                    return false;
                                });
                            }
                        }))();
                    };
                };
            };
        };
    };
};
var locationsTableStyleSet = TacoTable_Styles.foldingTableStyleSet;
var getNameLocationSortProperty = {
    getName: function (v) {
        return "LocationSortProperty";
    }
};
var locationsTableInstance = TacoTable.mkTableSortable(getNameLocationSortProperty)("Locations");
var eqLocationSortProperty = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Code && y instanceof Code) {
                return true;
            };
            if (x instanceof Description && y instanceof Description) {
                return true;
            };
            if (x instanceof Amount && y instanceof Amount) {
                return true;
            };
            if (x instanceof Unit && y instanceof Unit) {
                return true;
            };
            if (x instanceof AmountSource && y instanceof AmountSource) {
                return true;
            };
            if (x instanceof CreateTime && y instanceof CreateTime) {
                return true;
            };
            return false;
        };
    }
};
var ordLocationSortProperty = {
    compare: function (x) {
        return function (y) {
            if (x instanceof Code && y instanceof Code) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Code) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Code) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Description && y instanceof Description) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Description) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Description) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Amount && y instanceof Amount) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Amount) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Amount) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Unit && y instanceof Unit) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Unit) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Unit) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AmountSource && y instanceof AmountSource) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AmountSource) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AmountSource) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof CreateTime && y instanceof CreateTime) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at EstimationComponentDetails (line 612, column 1 - line 612, column 68): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqLocationSortProperty;
    }
};
var _estimationComponentDetailsImpl = function (props) {
    return function __do() {
        var appDispatch = StateHooks.useDispatch();
        var v = TofuHooks.useState(Data_Set.empty)();
        var v1 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var v2 = TofuHooks.useState(false)();
        var elementsEnabled = UseLicenseFeature.useLicenseFeature(UseLicenseFeature.Elements.value)();
        var locationsEnabled = UseLicenseFeature.useLicenseFeature(UseLicenseFeature.Locations.value)();
        var newLocationsEnabled = FeatureHooks.useFeatureEnabled("new-component-locations")();
        var projectLocationCodes = Data_Functor.map(Data_Functor.functorArray)((function () {
            var $146 = Data_Newtype.un()(Types_Location.ProjectLocation);
            return function ($147) {
                return (function (v3) {
                    return v3.code;
                })($146($147));
            };
        })())(props.projectLocations);
        var mkEstimationLocationAction = function (action) {
            return function (handler) {
                return new Actions.EstimationLocationActionRequest({
                    projectId: props.projectId,
                    params: action,
                    handler: handler
                });
            };
        };
        var mkComponentAction = function (c) {
            return function (mkEditAction) {
                return function (value) {
                    return function (handler) {
                        return new Actions.EstimationComponentActionRequest({
                            projectId: props.projectId,
                            params: new Types_Estimation.EditEstimationComponent({
                                componentId: c.id,
                                editAction: mkEditAction(value)
                            }),
                            handler: handler
                        });
                    };
                };
            };
        };
        var locationsLoading = (function () {
            if (props.loadingEstimationLocationAction) {
                return TacoLoader.component()()({
                    size: "1.5rem"
                });
            };
            return React_Basic.empty;
        })();
        var locationSelectChoices = Data_Functor.mapFlipped(Data_Functor.functorArray)(projectLocationCodes)(function (code) {
            return {
                text: code,
                id: code
            };
        });
        var ecwr = Data_Functor.map(Data_Maybe.functorMaybe)(Data_Newtype.un()(Types_CostEstimation.EstimationComponentWithResources))(props.estimationComponent);
        var resources = Data_Maybe.fromMaybe([  ])(Data_Functor.map(Data_Maybe.functorMaybe)(function (v3) {
            return v3.resources;
        })(ecwr));
        var dispatchEstimationLocationAction = function (action) {
            return appDispatch(mkEstimationLocationAction(action)(Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)))));
        };
        var component = Data_Functor.map(Data_Maybe.functorMaybe)(function (v3) {
            return v3.component;
        })(ecwr);
        var testIdPrefix = function (location) {
            return function (id) {
                return Data_Show.show(Types_CostEstimation.showECViewMode)(props.viewMode) + ("-location-" + (Data_Show.show(Types_Component.showEstimationLocationId)(location.id) + ("-" + id)));
            };
        };
        var selected = function (locationId) {
            return Data_Set.member(Types_Component.ordEstimationLocationId)(locationId)(v.value0);
        };
        var resetSelectedLocations = v.value1(function (v3) {
            return Data_Set.empty;
        });
        var newLocationsTable = React_Basic.element($foreign.locationsTable)({
            projectId: props.projectId,
            estimationLocations: props.estimationLocations,
            isReportingProject: props.isReportingProject
        });
        var modalBody = TacoModal.modalBody({
            contents: [ React_Basic_DOM.text("Oletko varma?") ]
        });
        var mkBuildingDescription = function (loc) {
            return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Nullable.toMaybe(loc.elementSpecId))(function (id) {
                return Data_Map_Internal.lookup(Types_Newtypes.ordElementSpecId)(id)(props.buildingDescriptionBySpecId);
            });
        };
        var locationsMatrixButton = TacoButton.component()()({
            text: "Lis\xe4\xe4 monta sijaintirivi\xe4",
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconEditV1.value),
            onClick: Data_Maybe.Just.create(v2.value1(function (v3) {
                return true;
            })),
            color: TacoConstants.componentColors.border,
            buttonStyle: TacoButton_Types.Filled.value,
            size: TacoConstants.L.value,
            testId: "locations-matrix"
        });
        var getLocationSortingValue = function (v3) {
            if (v3 instanceof Code) {
                return function ($148) {
                    return TacoTable_Types.StringValue.create((function (v4) {
                        return v4.locationCode;
                    })((function (v4) {
                        return v4.loc;
                    })($148)));
                };
            };
            if (v3 instanceof Description) {
                return function ($149) {
                    return TacoTable_Types.StringValue.create((function (v4) {
                        return v4.description;
                    })((function (v4) {
                        return v4.loc;
                    })($149)));
                };
            };
            if (v3 instanceof Amount) {
                return function ($150) {
                    return TacoTable_Types.NumberValue.create((function (v4) {
                        return v4.amount;
                    })((function (v4) {
                        return v4.loc;
                    })($150)));
                };
            };
            if (v3 instanceof AmountSource) {
                return function ($151) {
                    return TacoTable_Types.IntValue.create((function (v4) {
                        return v4.amountSource;
                    })((function (v4) {
                        return v4.loc;
                    })($151)));
                };
            };
            if (v3 instanceof Unit) {
                return function (v4) {
                    return TacoTable_Types.StringValue.create(Data_Maybe.fromMaybe("")(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function ($152) {
                        return Data_Nullable.toMaybe((function (v5) {
                            return v5.unit;
                        })($152));
                    })(component)));
                };
            };
            if (v3 instanceof CreateTime) {
                var $153 = Utils.nullable("")(Types_JSDateString.extractJSDateString);
                return function ($154) {
                    return TacoTable_Types.StringValue.create($153((function (v4) {
                        return v4.createTime;
                    })((function (v4) {
                        return v4.loc;
                    })($154))));
                };
            };
            throw new Error("Failed pattern match at EstimationComponentDetails (line 479, column 5 - line 479, column 86): " + [ v3.constructor.name ]);
        };
        var deleteLocationsButton$prime = TacoButton.component()()({
            text: "Poista valitut sijaintirivit",
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconDelete24Px.value),
            buttonStyle: TacoButton_Types.Filled.value,
            size: TacoConstants.L.value,
            onClick: new Data_Maybe.Just((function () {
                if (component instanceof Data_Maybe.Just) {
                    return v1.value1(function (v3) {
                        return new Data_Maybe.Just(component.value0.id);
                    });
                };
                if (component instanceof Data_Maybe.Nothing) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                };
                throw new Error("Failed pattern match at EstimationComponentDetails (line 290, column 11 - line 292, column 33): " + [ component.constructor.name ]);
            })()),
            testId: "delete-selected-locations"
        });
        var deleteLocationsButton = (function () {
            var $90 = Data_Set.isEmpty(v.value0);
            if ($90) {
                return React_Basic.empty;
            };
            return deleteLocationsButton$prime;
        })();
        var closeDeleteLocationsModal = v1.value1(function (v3) {
            return Data_Maybe.Nothing.value;
        });
        var modalActions = function (componentId) {
            return TacoModal.modalActions({
                contents: [ TacoButton.component()()({
                    onClick: new Data_Maybe.Just(closeDeleteLocationsModal),
                    text: "Peruuta"
                }), TacoButton.component()()({
                    text: "Poista",
                    color: TacoConstants.SecondaryRed.value,
                    onClick: new Data_Maybe.Just(function __do() {
                        dispatchEstimationLocationAction(new Types_Estimation.DeleteEstimationLocations({
                            componentId: componentId,
                            estimationLocationIds: Data_Array.fromFoldable(Data_Set.foldableSet)(v.value0)
                        }))();
                        resetSelectedLocations();
                        return closeDeleteLocationsModal();
                    })
                }) ]
            });
        };
        var deleteLocationsModal = (function () {
            if (v1.value0 instanceof Data_Maybe.Nothing) {
                return React_Basic.empty;
            };
            if (v1.value0 instanceof Data_Maybe.Just) {
                return TacoModal.component()()({
                    isActive: true,
                    onClose: closeDeleteLocationsModal,
                    heading: "Poista valitut sijaintirivit",
                    contents: [ modalBody, modalActions(v1.value0.value0) ]
                });
            };
            throw new Error("Failed pattern match at EstimationComponentDetails (line 268, column 28 - line 278, column 10): " + [ v1.value0.constructor.name ]);
        })();
        var canEdit = EstimationUtils.canEditView(props);
        var buildingDescription = function (r) {
            var mkElementLink = function (desc) {
                return TacoButton.component()()({
                    buttonStyle: TacoButton_Types.Link.value,
                    text: desc.text,
                    onClick: Data_Maybe.Just.create(appDispatch(Actions.NavigateRoute.create(new Types_FrontEndRoutes.EstimationBuildingElementsRoute({
                        initialSelectedElement: new Data_Maybe.Just(desc.elementId),
                        projectId: props.projectId
                    })))),
                    testId: testIdPrefix(r.loc)("element-link")
                });
            };
            return Data_Maybe.maybe(React_Basic.empty)(mkElementLink)(r.buildingDescription);
        };
        var locationColumns = (function () {
            var unitCell = function (v3) {
                return TacoText.component()()({
                    text: Data_Maybe.fromMaybe("")(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function ($155) {
                        return Data_Nullable.toMaybe((function (v4) {
                            return v4.unit;
                        })($155));
                    })(component)),
                    testId: testIdPrefix(v3.loc)("unit")
                });
            };
            var timeAndUser = function (v3) {
                return React_Basic_DOM_Generated.div_([ React_Basic_DOM_Generated.p_([ TacoText.component()()({
                    text: Utils.nullable("")(Types_JSDateString.extractJSDateString)(v3.loc.createTime),
                    testId: testIdPrefix(v3.loc)("modify-date")
                }) ]), React_Basic_DOM_Generated.p_([ TacoText.component()()({
                    text: v3.loc.userName,
                    testId: testIdPrefix(v3.loc)("username")
                }) ]) ]);
            };
            var mkReadOnlyCell = function (title) {
                return function (children) {
                    return React_Basic_DOM_Generated.div()({
                        className: "readonly-quantity",
                        title: title,
                        children: children
                    });
                };
            };
            var mkAutocompleteItem = function (loc) {
                return {
                    text: loc.locationCode,
                    id: loc.locationCode
                };
            };
            var locationCodeSelect = function (v3) {
                var isDixon = v3.loc.amountSource === 1;
                var isElementLocation = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Utils.isNull)(v3.loc.elementSpecId);
                var isInvalidCode = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean)))(Data_Array.elem(Data_Eq.eqString))(v3.loc.locationCode)(projectLocationCodes);
                var $98 = isDixon || isElementLocation;
                if ($98) {
                    return TacoInput.stringInput()()(Data_Eq.eqString)({
                        disabled: true,
                        title: (function () {
                            if (isDixon) {
                                return "Voit muokata digitoituja m\xe4\xe4ri\xe4 vain digitoinnissa.";
                            };
                            return "";
                        })(),
                        value: v3.loc.locationCode,
                        testId: testIdPrefix(v3.loc)("location-code-select")
                    });
                };
                return TacoAutocomplete.component()()({
                    sections: [ {
                        text: Data_Maybe.Nothing.value,
                        list: locationSelectChoices
                    } ],
                    clearButton: false,
                    placeholder: "",
                    onSelect: function (item) {
                        if (item instanceof Data_Maybe.Just) {
                            return dispatchEstimationLocationAction(new Types_Estimation.UpdateEstimationLocationCode({
                                code: item.value0.id,
                                componentId: v3.loc.estimationComponentId,
                                estimationLocationId: v3.loc.id
                            }));
                        };
                        if (item instanceof Data_Maybe.Nothing) {
                            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                        };
                        throw new Error("Failed pattern match at EstimationComponentDetails (line 437, column 17 - line 444, column 39): " + [ item.constructor.name ]);
                    },
                    selected: Data_Maybe.Just.create(mkAutocompleteItem(v3.loc)),
                    testId: testIdPrefix(v3.loc)("location-code-select"),
                    expanded: new Data_Maybe.Just(true),
                    title: Data_Monoid.guard(Data_Monoid.monoidString)(isInvalidCode)("Tuntematon sijaintitunnus. Vaihda sijaintitunnus joksikin hankkeella k\xe4ytetyksi sijaintitunnukseksi korjataksesi laskelma."),
                    error: isInvalidCode
                });
            };
            var locationCode = (function () {
                if (canEdit) {
                    return new TacoTable_Types.JSXCell([  ], locationCodeSelect);
                };
                return new TacoTable_Types.JSXCell([  ], function (r) {
                    return TacoText.component()()({
                        text: r.loc.locationCode,
                        testId: testIdPrefix(r.loc)("location-code")
                    });
                });
            })();
            var descriptionInput$prime = function (v3) {
                return TacoInput.remoteStringField()()({
                    value: v3.loc.description,
                    mkAction: function (description) {
                        return function (handler) {
                            var action = new Types_Estimation.UpdateEstimationLocationDescription({
                                description: description,
                                componentId: v3.loc.estimationComponentId,
                                estimationLocationId: v3.loc.id
                            });
                            return mkEstimationLocationAction(action)(handler);
                        };
                    },
                    testId: testIdPrefix(v3.loc)("description-input")
                });
            };
            var descriptionInput = function (r) {
                var isElementLocation = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Utils.isNull)(r.loc.elementSpecId);
                if (isElementLocation) {
                    return TacoInput.stringInput()()(Data_Eq.eqString)({
                        disabled: true,
                        title: "",
                        value: r.loc.description,
                        testId: testIdPrefix(r.loc)("description-input")
                    });
                };
                return descriptionInput$prime(r);
            };
            var descriptionCell = (function () {
                if (canEdit) {
                    return new TacoTable_Types.JSXCell([  ], descriptionInput);
                };
                return new TacoTable_Types.JSXCell([  ], function (r) {
                    return TacoText.component()()({
                        text: r.loc.description,
                        testId: testIdPrefix(r.loc)("description")
                    });
                });
            })();
            var checkbox = function (v3) {
                return TacoCheckbox.component()()({
                    className: "location-checkbox",
                    isChecked: selected(v3.loc.id),
                    labelText: Data_Maybe.Nothing.value,
                    onToggle: (function () {
                        var $111 = v3.loc.amountSource === 1;
                        if ($111) {
                            return new TacoCheckbox.Disabled("Voit muokata digitoituja m\xe4\xe4ri\xe4 vain digitoinnissa.");
                        };
                        return new TacoCheckbox.NoEvent(v.value1(Utils.toggleInSet(Types_Component.ordEstimationLocationId)(v3.loc.id)));
                    })(),
                    testId: testIdPrefix(v3.loc)("checkbox")
                });
            };
            var amountSource = function (r) {
                var $113 = r.loc.amountSource === 1;
                if ($113) {
                    var v3 = TofuJSON.readJSON_(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                        reflectSymbol: function () {
                            return "drawing_id";
                        }
                    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                        reflectSymbol: function () {
                            return "file_id";
                        }
                    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                        reflectSymbol: function () {
                            return "measurement_group_id";
                        }
                    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                        reflectSymbol: function () {
                            return "text";
                        }
                    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
                        reflectSymbol: function () {
                            return "drawing_id";
                        }
                    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                        reflectSymbol: function () {
                            return "file_id";
                        }
                    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                        reflectSymbol: function () {
                            return "measurement_group_id";
                        }
                    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                        reflectSymbol: function () {
                            return "text";
                        }
                    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))(r.loc.sourceApplication);
                    if (v3 instanceof Data_Maybe.Just) {
                        return TacoButton.component()()({
                            buttonStyle: TacoButton_Types.Link.value,
                            text: v3.value0.text,
                            onClick: Data_Maybe.Just.create(appDispatch(Actions.NavigateRoute.create(new Types_FrontEndRoutes.DixonFERoute({
                                projectId: props.projectId,
                                mFileId: new Data_Maybe.Just(v3.value0.file_id),
                                mDrawingId: new Data_Maybe.Just(v3.value0.drawing_id),
                                mMeasurementGroupId: new Data_Maybe.Just(v3.value0.measurement_group_id)
                            })))),
                            testId: testIdPrefix(r.loc)("dixon-link")
                        });
                    };
                    if (v3 instanceof Data_Maybe.Nothing) {
                        return TacoText.component()()({
                            text: Types_Component.amountSourceIntToDescription(r.loc.amountSource),
                            testId: testIdPrefix(r.loc)("amount-source")
                        });
                    };
                    throw new Error("Failed pattern match at EstimationComponentDetails (line 358, column 15 - line 371, column 148): " + [ v3.constructor.name ]);
                };
                return TacoText.component()()({
                    text: Types_Component.amountSourceIntToDescription(r.loc.amountSource),
                    testId: testIdPrefix(r.loc)("amount-source")
                });
            };
            var amountCell = function (r) {
                var onAmountUpdate = function (newAmount) {
                    return function (handler) {
                        var estimationLocationAction = new Types_Estimation.UpdateEstimationLocationAmount({
                            amount: newAmount,
                            componentId: r.loc.estimationComponentId,
                            estimationLocationId: r.loc.id
                        });
                        return mkEstimationLocationAction(estimationLocationAction)(handler);
                    };
                };
                var v4 = Utils.isNothing(r.buildingDescription);
                if (canEdit && (v4 && r.loc.amountSource === 0)) {
                    return TacoInput.remoteNumberField()()({
                        value: r.loc.amount,
                        mkAction: onAmountUpdate,
                        precision: 2,
                        testId: testIdPrefix(r.loc)("amount-input")
                    });
                };
                if (canEdit) {
                    return mkReadOnlyCell("M\xe4\xe4r\xe4 lukittu, per\xe4isin toisesta l\xe4hteest\xe4.")([ TacoText.component()()({
                        text: Formatters.formatDecimalValue(1)(r.loc.amount),
                        testId: testIdPrefix(r.loc)("amount-input")
                    }) ]);
                };
                if (!canEdit) {
                    return mkReadOnlyCell("")([ TacoText.component()()({
                        text: Formatters.formatDecimalValue(1)(r.loc.amount),
                        testId: testIdPrefix(r.loc)("amount-input")
                    }) ]);
                };
                throw new Error("Failed pattern match at EstimationComponentDetails (line 454, column 24 - line 462, column 143): " + [ canEdit.constructor.name, v4.constructor.name, r.loc.amountSource.constructor.name ]);
            };
            return Data_Semigroup.append(Data_Semigroup.semigroupArray)((function () {
                if (canEdit) {
                    return [ {
                        key: "checkbox",
                        label: React_Basic_DOM.text(""),
                        cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], checkbox),
                        sortProperty: Data_Maybe.Nothing.value,
                        width: new TacoTable_Types.Fixed("24px"),
                        headerJSX: Data_Maybe.Nothing.value
                    } ];
                };
                return [  ];
            })())(Data_Semigroup.append(Data_Semigroup.semigroupArray)((function () {
                if (locationsEnabled) {
                    return [ {
                        key: "locationCode",
                        label: React_Basic_DOM.text("Sijainti"),
                        cell: locationCode,
                        sortProperty: new Data_Maybe.Just(Code.value),
                        width: new TacoTable_Types.Flex(14),
                        headerJSX: Data_Maybe.Nothing.value
                    } ];
                };
                return [  ];
            })())(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ {
                key: "description",
                label: React_Basic_DOM.text("Selite"),
                cell: descriptionCell,
                sortProperty: new Data_Maybe.Just(Description.value),
                width: new TacoTable_Types.Flex(30),
                headerJSX: Data_Maybe.Nothing.value
            }, {
                key: "amount",
                label: React_Basic_DOM.text("M\xe4\xe4r\xe4"),
                cell: new TacoTable_Types.JSXCell([  ], amountCell),
                sortProperty: new Data_Maybe.Just(Amount.value),
                width: new TacoTable_Types.Flex(14),
                headerJSX: Data_Maybe.Nothing.value
            }, {
                key: "unit",
                label: React_Basic_DOM.text("Yks."),
                cell: new TacoTable_Types.JSXCell([  ], unitCell),
                sortProperty: new Data_Maybe.Just(Unit.value),
                width: new TacoTable_Types.Flex(8),
                headerJSX: Data_Maybe.Nothing.value
            }, {
                key: "amountSource",
                label: React_Basic_DOM.text("M\xe4\xe4r\xe4l\xe4hde"),
                cell: new TacoTable_Types.JSXCell([  ], amountSource),
                sortProperty: new Data_Maybe.Just(AmountSource.value),
                width: new TacoTable_Types.Flex(40),
                headerJSX: Data_Maybe.Nothing.value
            } ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)((function () {
                if (elementsEnabled) {
                    return [ {
                        key: "building",
                        label: React_Basic_DOM.text("Rakenne"),
                        cell: new TacoTable_Types.JSXCell([  ], buildingDescription),
                        sortProperty: Data_Maybe.Nothing.value,
                        width: new TacoTable_Types.Flex(25),
                        headerJSX: Data_Maybe.Nothing.value
                    } ];
                };
                return [  ];
            })())([ {
                key: "createdTimeAndUser",
                label: React_Basic_DOM.text("Luontiaika ja k\xe4ytt\xe4j\xe4"),
                cell: new TacoTable_Types.JSXCell([  ], timeAndUser),
                sortProperty: new Data_Maybe.Just(CreateTime.value),
                width: new TacoTable_Types.Flex(25),
                headerJSX: Data_Maybe.Nothing.value
            } ]))));
        })();
        var getLocationRow = function (loc) {
            var isSelected = selected(loc.id);
            return {
                rowData: {
                    isSelected: isSelected,
                    loc: loc,
                    buildingDescription: mkBuildingDescription(loc)
                },
                rowKey: Data_Show.show(Types_Component.showEstimationLocationId)(loc.id),
                onClick: function (v3) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                },
                className: "",
                foldingElement: Data_Maybe.Nothing.value,
                columns: locationColumns
            };
        };
        var locationRows = Data_Functor.map(Data_Functor.functorArray)(getLocationRow)(props.estimationLocations);
        var tableContents = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "table-contents",
            css: (function (v3) {
                return v3.tableStyles;
            })(CostEstimation_Styles.costEstimationTableStyleSet(TacoConstants.componentColors)),
            children: [ TacoTable.tableSortable(getNameLocationSortProperty)(eqLocationSortProperty)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "loc";
                }
            })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "userName";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "sourceApplication";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "locationCode";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Component.eqEstimationLocationId))()({
                reflectSymbol: function () {
                    return "group";
                }
            })(Data_Nullable.eqNullable(Types_ClassificationGroup.eqClassificationGroupCode)))()({
                reflectSymbol: function () {
                    return "estimationComponentId";
                }
            })(Types_Component.eqComponentId))()({
                reflectSymbol: function () {
                    return "elementSpecId";
                }
            })(Data_Nullable.eqNullable(Types_Newtypes.eqElementSpecId)))()({
                reflectSymbol: function () {
                    return "description";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "createTime";
                }
            })(Data_Nullable.eqNullable(Types_JSDateString.eqJSDateString)))()({
                reflectSymbol: function () {
                    return "amountSource";
                }
            })(Data_Eq.eqInt))()({
                reflectSymbol: function () {
                    return "amount";
                }
            })(Data_Eq.eqNumber))))()({
                reflectSymbol: function () {
                    return "isSelected";
                }
            })(Data_Eq.eqBoolean))()({
                reflectSymbol: function () {
                    return "buildingDescription";
                }
            })(Data_Maybe.eqMaybe(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "text";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "elementId";
                }
            })(Types_Element.eqElementId))))))(locationsTableInstance)(new TacoTable_Types.SortingCriteria(TacoTable_Types.Ascending.value, new Data_Maybe.Just(Code.value), getLocationSortingValue))(Data_Maybe.Nothing.value)({
                rows: locationRows,
                columns: locationColumns,
                showHeader: true,
                styleSet: locationsTableStyleSet
            }) ]
        });
        var addLocationButton = TacoButton.component()()({
            text: (function () {
                if (locationsEnabled) {
                    return "Lis\xe4\xe4 sijaintirivi";
                };
                return "Lis\xe4\xe4 m\xe4\xe4r\xe4rivi";
            })(),
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconAddPlusV1.value),
            buttonStyle: TacoButton_Types.Filled.value,
            size: TacoConstants.L.value,
            onClick: new Data_Maybe.Just((function () {
                if (component instanceof Data_Maybe.Just) {
                    return dispatchEstimationLocationAction(new Types_Estimation.AddEstimationLocation({
                        componentId: component.value0.id
                    }));
                };
                if (component instanceof Data_Maybe.Nothing) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                };
                throw new Error("Failed pattern match at EstimationComponentDetails (line 228, column 13 - line 230, column 35): " + [ component.constructor.name ]);
            })()),
            testId: "add-component-location-button"
        });
        var locationsList = Box.box("LocationsList")([ Box.FlexColumn.value, Box.Height.create(new TacoConstants.CustomSize("100%")) ])([ CostEstimationTableWrapper.component({
            colorSet: TacoConstants.componentColors,
            children: [ tableContents ],
            floatingActionButtons: React_Basic.fragment([ Data_Monoid.guard(React_Basic.monoidJSX)(canEdit)(addLocationButton), deleteLocationsButton, deleteLocationsModal, Data_Monoid.guard(React_Basic.monoidJSX)(locationsEnabled)(locationsMatrixButton), (function () {
                if (v2.value0 && component instanceof Data_Maybe.Just) {
                    return LocationEditor.locationsMatrix(Types_Component.showEstimationLocationId)(Types_Component.eqEstimationLocationId)({
                        locations: Data_Functor.map(Data_Functor.functorArray)(LocationEditor.fromWasmComponentLocation)(props.estimationLocations),
                        projectLocations: props.projectLocations,
                        projectId: props.projectId,
                        unit: Utils.fromNullable("")(component.value0.unit),
                        setShowLocationsMatrixModal: v2.value1,
                        saveLocationMatrixChanges: mkSaveChangesAction(props.projectId)(component.value0.id)
                    });
                };
                return React_Basic.empty;
            })(), locationsLoading ])
        }) ]);
        var resourcesList = (function () {
            if (component instanceof Data_Maybe.Just) {
                return EstimationResourcesList.estimationResourcesList({
                    componentId: component.value0.id,
                    componentAmount: component.value0.amount,
                    componentUnit: Data_Nullable.toMaybe(component.value0.unit),
                    componentGroupCode: Data_Nullable.toMaybe(component.value0.group),
                    resourcesForComponent: resources,
                    socialExpensePercentagesByCostClass: props.socialExpensePercentagesByCostClass,
                    showSocialExpensesInCosts: props.showSocialExpensesInCosts,
                    showWorkAchievement: props.showWorkAchievement,
                    costClasses: props.costClasses,
                    projectId: props.projectId,
                    viewMode: props.viewMode,
                    programType: props.programType,
                    isReportingProject: props.isReportingProject,
                    border: false,
                    selectedResourceIds: props.selectedResourceIds,
                    setSelectedResourceIds: props.setSelectedResourceIds,
                    extraControls: props.extraControls,
                    componentSplitParams: Data_Maybe.Nothing.value,
                    projectCurrency: props.projectCurrency
                });
            };
            if (component instanceof Data_Maybe.Nothing) {
                return TacoLoader.component()()({
                    size: "2rem"
                });
            };
            throw new Error("Failed pattern match at EstimationComponentDetails (line 488, column 21 - line 510, column 55): " + [ component.constructor.name ]);
        })();
        var memoInput = (function () {
            if (canEdit && component instanceof Data_Maybe.Just) {
                return TacoInput.remoteStringField()()({
                    textarea: true,
                    mkAction: mkComponentAction(component.value0)(function (memo) {
                        return new Types_Estimation.EditEstimationComponentMemo({
                            memo: memo
                        });
                    }),
                    value: component.value0.memo,
                    stateKey: Data_Show.show(Types_Component.showComponentId)(component.value0.id),
                    height: Data_Maybe.Just.create(new TacoConstants.CustomSize("200px")),
                    testId: "component-memo-input"
                });
            };
            if (!canEdit && component instanceof Data_Maybe.Just) {
                return React_Basic_DOM_Generated.div()({
                    className: "memo-textarea",
                    children: [ React_Basic_DOM.text(component.value0.memo) ]
                });
            };
            return TacoLoader.component()()({
                size: "2rem"
            });
        })();
        TofuHooks.useEffect([ Data_Nullable.toNullable(Data_Functor.map(Data_Maybe.functorMaybe)(function (v3) {
            return v3.id;
        })(component)) ])(function __do() {
            v.value1(function (v3) {
                return Data_Set.empty;
            })();
            v1.value1(function (v3) {
                return Data_Maybe.Nothing.value;
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        })();
        var v3 = TofuHooks.useState(new Data_Maybe.Just((function () {
            if (props.hideResources) {
                return "locations";
            };
            return "resources";
        })()))();
        var memoContainer = Box.box("MemoContainer")([ new Box.Margin(TacoConstants.M.value), Box.Width.create(new TacoConstants.CustomSize("100%")), Box.MaxWidth.create(new TacoConstants.CustomSize("48rem")) ])([ memoInput ]);
        var main = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "__EstimationComponentDetailsMain",
            css: ComponentsView_Styles.componentDetailsContainerTabsStyles,
            children: [ TacoTabs.controlled({
                orientation: TacoTabs_Types.Horizontal.value,
                hideContents: Data_Eq.eq(CostEstimationLayout_Types.eqPanelMode)(props.panelMode)(CostEstimationLayout_Types.MinimizedMode.value),
                activeTabId: v3.value0,
                setActiveTabId: v3.value1,
                tabs: (function () {
                    var newLocationsTab = (function () {
                        if (newLocationsEnabled) {
                            return [ new TacoTabs_Types.NormalTab({
                                children: [ newLocationsTable ],
                                quantity: Data_Maybe.Just.create(Data_Array.length(locationRows)),
                                title: "Sijainnit (uudistettu)",
                                id: "newLocations",
                                color: TacoConstants.PrimaryBlue.value
                            }) ];
                        };
                        return [  ];
                    })();
                    return Data_Semigroup.append(Data_Semigroup.semigroupArray)([ new TacoTabs_Types.NormalTab({
                        children: [ resourcesList ],
                        quantity: Data_Maybe.Just.create(Data_Array.length(resources)),
                        title: "Panokset",
                        id: "resources",
                        color: TacoConstants.resourceColors.border
                    }), new TacoTabs_Types.NormalTab({
                        children: [ locationsList ],
                        quantity: Data_Maybe.Just.create(Data_Array.length(locationRows)),
                        title: (function () {
                            if (locationsEnabled) {
                                return "Sijainnit";
                            };
                            return "M\xe4\xe4r\xe4t";
                        })(),
                        id: "locations",
                        color: TacoConstants.PrimaryBlue.value
                    }) ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(newLocationsTab)([ new TacoTabs_Types.NormalTab({
                        children: [ ScrollContainer.component({
                            children: [ memoContainer ]
                        }) ],
                        quantity: Data_Maybe.Nothing.value,
                        title: "Muistio",
                        id: "memo",
                        color: TacoConstants.PrimaryBlue.value
                    }), TacoTabs_Types.SeparatorTab.value, TacoTabs_Types.JsxTab.create(CostEstimation_Layout.panelModeButtons({
                        panelMode: props.panelMode,
                        setPanelMode: props.setPanelMode,
                        vertical: false,
                        smallMode: true,
                        testId: "component-details-panel-size"
                    })) ]));
                })()
            }) ]
        });
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "__EstimationComponentDetails",
            css: ComponentsView_Styles.estimationComponentDetailsStyles,
            children: [ main ]
        });
    };
};
var estimationComponentDetails = TofuHooks.mkHookComponent("EstimationComponentDetails")(_estimationComponentDetailsImpl);
module.exports = {
    estimationComponentDetails: estimationComponentDetails
};
