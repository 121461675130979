// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Common = require("../Common/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var DOMUtils = require("../DOMUtils/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var FRP_Event = require("../FRP.Event/index.js");
var HtmlUtils = require("../HtmlUtils/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_Alert = require("../Types.Alert/index.js");
var notificationsLSKey = "ui:json:notifications";
var setAlerts = function (xs) {
    return DOMUtils.setLocalStorageItem({
        key: notificationsLSKey,
        value: TofuJSON.writeJSON(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "formattedTime";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "id";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "level";
            }
        })(Types_Alert.tofuJSONAlertLevel)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "text";
            }
        })(TofuJSON.readString)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "time";
            }
        })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "formattedTime";
            }
        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "id";
            }
        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "level";
            }
        })(Types_Alert.tofuJSONAlertLevel)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "text";
            }
        })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "time";
            }
        })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())))(Data_Array.take(200)(xs))
    });
};
var getAlerts = function __do() {
    var mJSON = DOMUtils.getLocalStorageItem(notificationsLSKey)();
    var v = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(TofuJSON.readJSON_(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "formattedTime";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "id";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "level";
        }
    })(Types_Alert.tofuJSONAlertLevel)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "text";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "time";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "formattedTime";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "id";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "level";
        }
    })(Types_Alert.tofuJSONAlertLevel)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "text";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "time";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))))(mJSON);
    if (v instanceof Data_Maybe.Nothing) {
        return Common.emptyArray;
    };
    if (v instanceof Data_Maybe.Just) {
        return v.value0;
    };
    throw new Error("Failed pattern match at NotificationsMiddleware (line 21, column 3 - line 24, column 14): " + [ v.constructor.name ]);
};
var updateAlerts = function (level) {
    return function (msg) {
        var $3 = Data_String_Common["null"](msg);
        if ($3) {
            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
        };
        return function __do() {
            var alerts = getAlerts();
            return setAlerts(Data_Array.cons(Types_Alert.mkAlert(level)(msg))(alerts))();
        };
    };
};
var notificationsDriver = function (dispatch) {
    return function (actions) {
        return Data_Functor.voidRight(Effect.functorEffect)(Data_Monoid.mempty(FRP_Event.monoidEvent(Data_Monoid.monoidUnit)))(Data_Functor["void"](Effect.functorEffect)(FRP_Event.subscribe(actions)(function (action) {
            if (action instanceof Actions.AddAlert) {
                return function __do() {
                    updateAlerts(action.value0)(action.value1)();
                    return Data_Functor["void"](Effect.functorEffect)(HtmlUtils.setTimeout(10000)(dispatch(new Actions.DismissAlert({
                        mId: Data_Maybe.Nothing.value
                    }))))();
                };
            };
            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
        })));
    };
};
module.exports = {
    notificationsLSKey: notificationsLSKey,
    getAlerts: getAlerts,
    setAlerts: setAlerts,
    updateAlerts: updateAlerts,
    notificationsDriver: notificationsDriver
};
