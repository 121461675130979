import {
  Icon,
  IconLtDisabledV1,
  IconSettings,
  IconUser,
  Text,
} from "@tocoman/ui";
import { useTranslation } from "react-i18next";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFeatureEnabled } from "src/client-ts/split/useFeatureEnabled";
import { FeatureFlags } from "src/client-ts/split/FeatureFlags";

export const SettingsNav = () => {
  const { t } = useTranslation("settings", { keyPrefix: "nav" });
  const location = useLocation();
  const navigate = useNavigate();
  const [history, setHistory] = useState<string[]>([]);

  const organizationCurrencyEnabled = useFeatureEnabled(
    FeatureFlags.ORGANIZATION_CURRENCY
  );

  // Save history of visited pages in new settings
  useEffect(() => {
    setHistory((prevHistory) => [...prevHistory, location.pathname]);
  }, [location]);

  // Skip the pages that are already visited in settings that you can go back to the page you came from
  const pagesToSkip = history.length;
  const isActiveLink = (pathname: string) => pathname === location.pathname;

  return (
    <div className={"w-[225px] border-r border-r-light h-full p-5"}>
      <button onClick={() => navigate(-pagesToSkip)} className={"mb-8"}>
        <Icon icon={IconLtDisabledV1} />
      </button>
      <button
        className={
          "text-black flex w-full text-left items-center text-2xl gap-3 mb-8"
        }
        style={
          isActiveLink("/new/settings/organization") ? { color: "#2845CE" } : {}
        }
      >
        <Icon icon={IconSettings} />
        <Link to={"/new/settings/organization"}>{t("settings")}</Link>
      </button>
      <button
        className={
          "text-black flex w-full text-left items-center text-xl gap-3"
        }
        style={isActiveLink("/new/settings/users") ? { color: "#2845CE" } : {}}
      >
        <Icon icon={IconUser} />
        <Link to={"/new/settings/users"}>{t("users")}</Link>
      </button>

      {organizationCurrencyEnabled ? (
        <button
          className={
            "text-black flex w-full text-left items-center text-xl gap-3 pt-3"
          }
          style={
            isActiveLink("/new/settings/currency") ? { color: "#2845CE" } : {}
          }
        >
          <Text text={"€"} className={"pl-[3px] pr-[4.5px]"}></Text>
          <Link to={"/new/settings/currency"}>{t("currencies")}</Link>
        </button>
      ) : null}
    </div>
  );
};
