// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Show = require("../Data.Show/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoInput = require("../TacoInput/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Element = require("../Types.Element/index.js");
var Types_Estimation = require("../Types.Estimation/index.js");
var component = TofuHooks.mkHookComponent("ElementMemo")(function (props) {
    return function __do() {
        StateHooks.useDispatch();
        var mkAction = function (mkEditAction) {
            return function (value1) {
                return function (handler) {
                    return new Actions.EstimationElementActionRequest({
                        projectId: props.projectId,
                        params: new Types_Estimation.EditEstimationElement({
                            elementId: props.elementId,
                            editAction: mkEditAction(value1)
                        }),
                        handler: handler
                    });
                };
            };
        };
        return Box.box("__ElementMemo")([ Box.Height.create(new TacoConstants.CustomSize("100%")), Box.Width.create(new TacoConstants.CustomSize("100%")) ])([ TacoInput.remoteStringField()()({
            textarea: true,
            mkAction: mkAction(function (memo) {
                return new Types_Estimation.EditEstimationElementMemo({
                    memo: memo
                });
            }),
            value: props.value,
            stateKey: Data_Show.show(Types_Element.showElementId)(props.elementId),
            height: Data_Maybe.Just.create(new TacoConstants.CustomSize("200px")),
            testId: "element-memo-input"
        }) ]);
    };
});
module.exports = {
    component: component
};
