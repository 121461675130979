// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var CostEstimationTableWrapper_Styles = require("../CostEstimationTableWrapper.Styles/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var component = TofuHooks.mkHookComponent("CostEstimationTableWrapper")(function (props) {
    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        className: "__CostEstimationTableWrapper",
        css: CostEstimationTableWrapper_Styles.costEstimationTableWrapperStyles({
            border: props.colorSet.border,
            background: props.colorSet.background
        }),
        children: Data_Semigroup.append(Data_Semigroup.semigroupArray)(props.children)([ React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "__FloatingActionButtons",
            css: CostEstimationTableWrapper_Styles.floatingActionButtonsStyles,
            children: [ props.floatingActionButtons ]
        }) ])
    }));
});
module.exports = {
    component: component
};
