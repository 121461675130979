// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var prompt = function (msg) {
    return function (defaultValue) {
        return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_prompt"](msg)(defaultValue));
    };
};
var confirm = function (msg) {
    return function (cb) {
        return function __do() {
            var confirmed = $foreign["_confirm"](msg)();
            if (confirmed) {
                return cb();
            };
            return Data_Unit.unit;
        };
    };
};
module.exports = {
    confirm: confirm,
    prompt: prompt,
    "_confirm": $foreign["_confirm"],
    "_prompt": $foreign["_prompt"],
    setWindow: $foreign.setWindow,
    selectionRemoveAllRanges: $foreign.selectionRemoveAllRanges
};
