import { Button, Checkbox, IconAddPlusV1, Input } from "@tocoman/ui";
import { Controller, RegisterOptions, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isNumber, isPositive } from "src/client-ts/utils/validations";
import { CostClass } from "ts-bindings/CostClass";
import { ProjectId } from "ts-bindings/ProjectId";
import { ErrorMessage } from "../../../components/ErrorMessage";
import {
  costClassError,
  validateCostClassCode,
  validateCostClassCodeLength,
} from "./CostClassesTab";

type Props = {
  costClasses: CostClass[];
  projectId?: ProjectId;
  onSubmit: (value: CostClass) => void;
};

export const NewCostClassForm = ({
  costClasses,
  projectId,
  onSubmit,
}: Props) => {
  const { t } = useTranslation("projects", { keyPrefix: "details" });

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CostClass>({
    defaultValues: {
      id: 0,
      costClassCode: "",
      name: "",
      socialExpensePercentageInCostEstimation: 0,
      multiplier: 1,
      hourlyPricing: false,
      projectId: projectId ?? 0,
    },
  });

  const mkInputProps = (
    field: keyof CostClass,
    registerOptions?: RegisterOptions<CostClass, typeof field>
  ) => ({
    label: t(`costClasses.newCostClass.dialog.fields.${field}`),
    ...register(field, registerOptions),
  });

  const onHandleSubmit = (formValue: CostClass) => {
    const newCostClass =
      projectId !== undefined ? { ...formValue, projectId } : { ...formValue };
    const newCostClassWithPercentageValue = {
      ...newCostClass,
      socialExpensePercentageInCostEstimation: newCostClass.socialExpensePercentageInCostEstimation
        ? newCostClass.socialExpensePercentageInCostEstimation / 100
        : 0,
    };
    onSubmit(newCostClassWithPercentageValue);
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onHandleSubmit)} className="flex flex-row">
      <div className="flex-1 flex-col mx-3">
        <Input
          testId={"newCostClass.codeInput"}
          {...mkInputProps("costClassCode", {
            validate: {
              unique: (value) => validateCostClassCode(value, costClasses),
              length: (value) => validateCostClassCodeLength(value),
            },
            required: true,
          })}
        />
        {errors.costClassCode && (
          <ErrorMessage
            errorMessage={costClassError(errors.costClassCode?.type, t)}
          />
        )}
      </div>
      <div className="flex-1 flex-col mx-3">
        <Input testId={"newCostClass.nameInput"} {...mkInputProps("name")} />
      </div>

      <div className="flex-1 flex-col mx-3">
        <Input
          testId={"newCostClass.socialExpenseInput"}
          {...mkInputProps("socialExpensePercentageInCostEstimation", {
            setValueAs: (value) =>
              value !== 0 ? Number(value.replace(",", ".")) : value,
            max: {
              value: 100,
              message: t(`errors.maxValue`, { max: "100" }),
            },
            validate: {
              isNumber: (value) =>
                isNumber(value) || t`costClasses.errors.notANumber`,
              isPositive: (value) =>
                isPositive(value) || t`costClasses.errors.notPositive`,
            },
          })}
        />
        {errors.socialExpensePercentageInCostEstimation && (
          <ErrorMessage
            errorMessage={String(
              errors.socialExpensePercentageInCostEstimation.message
            )}
          />
        )}
      </div>
      <div className={"flex-1 flex-col mx-3 gap-1"}>
        {t`costClasses.newCostClass.dialog.fields.hourlyPricing`}
        <div className={"flex self-middle pt-1"}>
          <Controller
            control={control}
            defaultValue={false}
            name={"hourlyPricing"}
            render={({ field: { value, onChange } }) => (
              <Checkbox
                testId={"newCostClass.hourlyPricingInput"}
                checked={value}
                setChecked={onChange}
              />
            )}
          />
        </div>
      </div>
      <div className={"w-[100px] ml-3 h-[45px] pt-6 align-bottom"}>
        <Button
          className={"w-full"}
          icon={IconAddPlusV1}
          label={t`costClasses.newCostClass.addCostClass`}
          color={"normal"}
          variant={"secondary"}
          type={"submit"}
          testId={"newCostClass.submitButton"}
        />
      </div>
    </form>
  );
};
