import { useCallback, useState } from "react";

/**
 * React hook helper for modals where you need to pass some data to the modal
 * when open.
 *
 * The catch is, we don't want to set the data to null immediately when the
 * modal closes, as that would break close animations.
 * @returns
 */
export const useModalData = <T>() => {
  const [data, setData] = useState<T | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const openModalWithData = useCallback((data: T | null) => {
    if (data === null) {
      setIsOpen(false);
    } else {
      setData(data);
      setIsOpen(true);
    }
  }, []);

  return [isOpen, data, openModalWithData] as const;
};
