import { Button, IconAddPlusV1, IconDelete24Px, Modal } from "@tocoman/ui";
import { Table } from "../../../components/Table";
import { useTranslation } from "react-i18next";
import {
  CellClassParams,
  ColDef,
  ProcessDataFromClipboardParams,
  StatusPanelDef,
} from "ag-grid-community";
import { useCallback, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { useSelectedRows } from "../../../components/Table/useSelectedRows";
import { ProjectLocation } from "./LocationsTable";
import { EstimationComponent } from "../../../../../ts-bindings/EstimationComponent";
import {
  AddComponentLocationBody,
  useAddComponentLocation,
  useAddNewLocation,
} from "./useComponentLocations";
import { useWorkerRequest } from "../../../hooks/useWorkerRequest";
import {
  mkRefreshComponents,
  mkRefreshLocations,
} from "../../../actions/state";

type AddLocationsModalProps = {
  isModalOpen: boolean;
  closeModal: () => void;
  currentComponent: EstimationComponent | undefined;
  projectLocations: ProjectLocation[];
  projectId: number;
};

type NewLocationsRowData = {
  locationCode: string;
  description: string;
  amount: number;
};
export const AddLocationsModal = ({
  isModalOpen,
  closeModal,
  currentComponent,
  projectLocations,
  projectId,
}: AddLocationsModalProps) => {
  const { t } = useTranslation("estimation", {
    keyPrefix: "componentLocations.locationsTable",
  });

  const gridRef = useRef<AgGridReact<NewLocationsRowData>>(null);

  const refreshLocations = useWorkerRequest(() =>
    mkRefreshLocations(projectId)
  );
  const refreshComponents = useWorkerRequest(() =>
    mkRefreshComponents(projectId)
  );

  const processDataFromClipboard = useCallback(
    (params: ProcessDataFromClipboardParams): string[][] | null => {
      const data = params.data;
      // Add empty string as the first item of every row that checkbox column doesn't get data
      if (data[0].length < 4) {
        data.forEach((row) => {
          row.unshift("");
        });
      }
      const emptyLastRow =
        data[data.length - 1][0] === "" && data[data.length - 1].length === 1;
      if (emptyLastRow) {
        data.splice(data.length - 1, 1);
      }
      if (!params.api) {
        return data;
      }
      const lastIndex = params.api.getDisplayedRowCount() - 1;
      const focusedCell = params.api.getFocusedCell();
      if (!focusedCell) {
        return data;
      }
      const focusedIndex = focusedCell.rowIndex;
      const lastRow = data.length - 1;
      if (focusedIndex + lastRow > lastIndex) {
        const resultLastIndex = focusedIndex + lastRow;
        const numRowsToAdd = resultLastIndex - lastIndex;
        for (let i = 0; i < numRowsToAdd; i++) {
          const index = lastRow;
          const row = data.slice(index, index + 1)[0];
          //eslint-disable-next-line @typescript-eslint/no-explicit-any
          const rowObject: any = {};
          //eslint-disable-next-line @typescript-eslint/no-explicit-any
          let currentColumn: any = focusedCell.column;
          row.forEach((item) => {
            if (!currentColumn) {
              return;
            }
            rowObject[currentColumn.colDef.field] = item;
            currentColumn = params.api.getDisplayedColAfter(currentColumn);
          });
          setRowData((prev) => [...prev, rowObject]);
        }
      }
      return data;
    },
    []
  );

  const locationCodes = projectLocations.map((location) =>
    location.code.toLowerCase()
  );

  const colDefs: ColDef[] = [
    {
      field: "selected",
      headerName: "",
      checkboxSelection: true,
      initialWidth: 50,
      editable: false,
      suppressPaste: true,
    },
    {
      field: "locationCode",
      headerName: t("locationCode"),
      type: ["editable"],
      cellStyle: (params: CellClassParams) => {
        if (
          params.value &&
          !locationCodes.includes(params.value?.toLowerCase())
        ) {
          return { backgroundColor: "red" };
        }
        if (
          params.value &&
          locationCodes.includes(params.value?.toLowerCase())
        ) {
          return { backgroundColor: "transparent" };
        }
        return null;
      },
      tooltipValueGetter: (params) => {
        if (!locationCodes.includes(params.value?.toLowerCase())) {
          return t("invalidLocationCode");
        }
        return "";
      },
    },
    {
      field: "description",
      headerName: t("description"),
      type: ["editable"],
    },
    {
      field: "amount",
      headerName: t("amount"),
      type: ["editable"],
    },
  ];

  const [rowData, setRowData] = useState<NewLocationsRowData[]>([
    {
      locationCode: "",
      description: "",
      amount: 0,
    },
  ]);

  const AddRowButton = () => {
    return (
      <Button
        label={t("addRow")}
        onClick={() => {
          const newRow: NewLocationsRowData = {
            locationCode: "",
            description: "",
            amount: 0,
          };
          setRowData((prev) => [...prev, newRow]);
        }}
        variant="text"
        className={"my-3"}
        icon={IconAddPlusV1}
        iconPosition={"left"}
      />
    );
  };

  const DeleteRowButton = () => {
    return (
      <Button
        label={t("deleteRow")}
        onClick={() => {
          const selectedRows = gridRef.current?.api.getSelectedRows();
          const currentRowData = gridRef.current?.api.getGridOption("rowData");
          const updatedData =
            currentRowData?.filter((row) => !selectedRows?.includes(row)) ??
            rowData;
          setRowData(updatedData);
        }}
        disabled={useSelectedRows(gridRef.current?.api).length === 0}
        variant="text"
        className={"my-3"}
        icon={IconDelete24Px}
        iconPosition={"left"}
      />
    );
  };

  const statusBar = useMemo<{ statusPanels: StatusPanelDef[] }>(() => {
    return {
      statusPanels: [
        {
          statusPanel: AddRowButton,
        },
        {
          statusPanel: DeleteRowButton,
        },
      ],
    };
  }, []);

  const resetAndClose = () => {
    setRowData([
      {
        locationCode: "",
        description: "",
        amount: 0,
      },
    ]);
    setIsErrorVisible(false);
    closeModal();
  };

  const addComponentLocation = useAddComponentLocation(projectId);
  const addNewLocation = useAddNewLocation();

  const saveAndReset = async () => {
    const currentRowData = gridRef.current?.api.getGridOption("rowData");
    const newData: AddComponentLocationBody[] =
      currentRowData?.map((row) => {
        return {
          locationCode: row.locationCode ?? "",
          description: row.description ?? "",
          amount: row.amount ?? 0,
          unit: currentComponent?.unit ?? "",
          componentId: currentComponent?.id,
        };
      }) ?? [];
    for (const data of newData) {
      if (locationCodes.includes(data.locationCode?.toLowerCase())) {
        try {
          addComponentLocation.mutate(data, {
            onSuccess: () => {
              refreshLocations();
              refreshComponents();
            },
          });
        } catch (error) {
          reportError(error);
        }
      } else {
        try {
          await addNewLocation.mutateAsync(
            {
              code: data.locationCode,
              description: data.description ?? "",
              projectId,
            },
            {
              onSuccess: () => {
                addComponentLocation.mutate(data, {
                  onSuccess: () => {
                    refreshLocations();
                    refreshComponents();
                  },
                });
              },
            }
          );
        } catch (error) {
          reportError(error);
        }
      }
    }
    resetAndClose();
  };

  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const actions = (
    <>
      <Button label={t("cancel")} onClick={resetAndClose} variant="text" />
      <Button label={t("save")} onClick={saveAndReset} />
    </>
  );

  const onCellValueChanged = useCallback(() => {
    const currentRowData = gridRef.current?.api.getGridOption("rowData");
    const invalidLocationCode = currentRowData?.some(
      (row) => !locationCodes.includes(row.locationCode.toLowerCase())
    );
    setIsErrorVisible(invalidLocationCode ?? false);
  }, []);

  return (
    <Modal
      isOpen={isModalOpen}
      closeModal={resetAndClose}
      actions={actions}
      width={700}
      title={t("addNewLocationRow")}
    >
      <>
        <div className={"text-[#192434] mb-3"}>
          {t("addNewLocationRowInfoText")}
        </div>
        <div className={"w-full h-[400px]"}>
          <Table<NewLocationsRowData>
            gridRef={gridRef}
            rowData={rowData}
            columnDefs={colDefs}
            className={"w-full h-full"}
            processDataFromClipboard={processDataFromClipboard}
            rowSelection={"multiple"}
            statusBar={statusBar}
            reactiveCustomComponents={true}
            onCellValueChanged={onCellValueChanged}
          />
        </div>
        <div className={"mt-[-10px] h-[5px]"}>
          {isErrorVisible && (
            <div className={"text-red-500 text-sm"}>
              {t("invalidLocationCodeInfoText")}
            </div>
          )}
        </div>
      </>
    </Modal>
  );
};
