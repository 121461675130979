import {
  QueryClient,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from "react-query";
import axios from "../../../axiosConfig";
import { LocationsRowData, ProjectLocation } from "./LocationsTable";

export type AddComponentLocationBody = {
  locationCode: string;
  description: string;
  amount: number;
  unit: string;
  componentId: number | undefined;
};

export type EditComponentLocationBody = {
  locationCode: string;
  description: string;
  amount: number;
  id: number;
};

export type NewProjectLocation = {
  code: string;
  description: string;
  projectId: number;
};

const baseUrl = "component-location";

export function useGetComponentLocation(
  componentId: number
): LocationsRowData[] {
  const { data } = useQuery({
    queryKey: [baseUrl, componentId],
    queryFn: async () => {
      const { data } = await axios.get(`/${baseUrl}/${componentId}`);
      return data;
    },
  });
  return data ?? [];
}

export function useAddNewLocation(): UseMutationResult<
  unknown,
  unknown,
  NewProjectLocation
> {
  return useMutation({
    mutationFn: async (value: NewProjectLocation) => {
      await axios.post(`/${baseUrl}/${value.projectId}/new-location`, value);
    },
  });
}

export function useAddComponentLocation(
  projectId: number
): UseMutationResult<unknown, unknown, AddComponentLocationBody> {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (value: AddComponentLocationBody) => {
      await axios.post(`/${baseUrl}/${projectId}`, value);
    },
    onSettled: () => invalidateComponentLocation(queryClient),
  });
}

export function useDeleteComponentLocation(
  projectId: number
): UseMutationResult<unknown, unknown, number> {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (value: number) => {
      await axios.delete(`/${baseUrl}/${projectId}/${value}`);
    },
    onSettled: () => invalidateComponentLocation(queryClient),
  });
}

export function useUpdateComponentLocation(
  projectId: number
): UseMutationResult<unknown, unknown, EditComponentLocationBody> {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (value: EditComponentLocationBody) => {
      await axios.put(`/${baseUrl}/${projectId}/${value.id}`, value);
    },
    onSettled: () => invalidateComponentLocation(queryClient),
  });
}

export function useGetProjectLocations(projectId: number): ProjectLocation[] {
  const { data } = useQuery({
    queryKey: ["projectLocations"],
    queryFn: async () => {
      const { data } = await axios.get(
        `/project/${projectId}/projectLocations`
      );
      return data;
    },
  });
  return data ?? [];
}

function invalidateComponentLocation(queryClient: QueryClient) {
  return queryClient.invalidateQueries({
    queryKey: [baseUrl],
  });
}
