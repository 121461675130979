// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Effect = require("../Effect/index.js");
var Gap = require("../Gap/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon = require("../TacoIcon/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TacoTooltip = require("../TacoTooltip/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var component = TofuHooks.mkHookComponent("TacoSearchInfo")(function (v) {
    return Control_Applicative.pure(Effect.applicativeEffect)(TacoTooltip.component()()({
        anchor: [ TacoIcon.component()()()()({
            icon: TacoIcon_Icons_Types.IconInfoWhite.value,
            size: TacoConstants.M.value,
            color: TacoConstants.PrimaryBlue.value,
            title: ""
        }) ],
        tip: [ Gap.gapY(TacoConstants.S.value)([ TacoText.component()()({
            text: "Haku toiminnot",
            variant: new TacoText_Types.Heading(2)
        }), TacoText.component()()({
            text: "xyz",
            weight: TacoText_Types.Bold.value
        }), TacoText.component()()({
            text: "Haetaan xyz-merkki jonoa kaikkialta"
        }), TacoText.component()()({
            text: "\"xyz\"",
            weight: TacoText_Types.Bold.value
        }), TacoText.component()()({
            text: "Haetaan vain xyz-merkki jonoa"
        }), TacoText.component()()({
            text: "x?z",
            weight: TacoText_Types.Bold.value
        }), TacoText.component()()({
            text: "Alkaa x, mik\xe4 tahansa merkki, loppuu z"
        }), TacoText.component()()({
            text: "x*z tai x%z",
            weight: TacoText_Types.Bold.value
        }), TacoText.component()()({
            text: "Alkaa x, mik\xe4 tahansa merkki jono, loppuu z"
        }), TacoText.component()()({
            text: "*xyz tai %xyz",
            weight: TacoText_Types.Bold.value
        }), TacoText.component()()({
            text: "Loppuu merkkijonoon xyz"
        }), TacoText.component()()({
            text: "xyz* tai xyz%",
            weight: TacoText_Types.Bold.value
        }), TacoText.component()()({
            text: "Alkaa merkkijonolla xyz"
        }) ]) ]
    }));
});
module.exports = {
    component: component
};
