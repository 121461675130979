// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Client_WASM = require("../Client.WASM/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var CostEstimation_Styles = require("../CostEstimation.Styles/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var EstimationComponentDetails = require("../EstimationComponentDetails/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_CostEstimation = require("../Types.CostEstimation/index.js");
var Types_Estimation = require("../Types.Estimation/index.js");
var Types_Project = require("../Types.Project/index.js");
var UseFetch = require("../UseFetch/index.js");
var Utils = require("../Utils/index.js");
var selectedComponentDetails = TofuHooks.mkHookComponent("SelectedComponentDetails")(function (v) {
    var selectedComponent$prime = (function (v1) {
        return v1.component;
    })(Data_Newtype.un()(Types_CostEstimation.EstimationComponentWithResources)(v.selectedComponent));
    var fetch = UseFetch.useFetchItemsByProjectId(v.projectId);
    return function __do() {
        var appDispatch = StateHooks.useDispatch();
        var v1 = Client_WASM.useSubprojects(v.projectId)();
        var v2 = Client_WASM.useCostClasses(v.projectId)();
        var v3 = Client_WASM.useProjectLocations(v.projectId)();
        var v4 = Client_WASM.useReferenceSubprojects(v.projectId)();
        var v5 = fetch(function (v5) {
            return v5.referenceProjectCostClasses;
        })(Actions.GetReferenceProjectCostClassesRequest.create)();
        var v6 = TofuHooks.useState(Data_Set.empty)();
        TofuHooks.useEffect([ ReactHooksUtils.utf(v6.value0) ])((function () {
            if (v.setParentSelectedResourceIds instanceof Data_Maybe.Just) {
                return function __do() {
                    v.setParentSelectedResourceIds.value0(function (v7) {
                        return v6.value0;
                    })();
                    return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                };
            };
            if (v.setParentSelectedResourceIds instanceof Data_Maybe.Nothing) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)));
            };
            throw new Error("Failed pattern match at CostEstimation.SelectedComponentDetails (line 110, column 7 - line 114, column 31): " + [ v.setParentSelectedResourceIds.constructor.name ]);
        })())();
        var isCostClassInComponentProject = function (_component) {
            return function (costClass) {
                var projectIdForSubproject = function (subprojectId) {
                    return Data_Functor.map(Data_Maybe.functorMaybe)(function (v7) {
                        return v7.projectId;
                    })(Data_Array.find((function () {
                        var $66 = Data_Eq.eq(Types_Project.eqSubprojectId)(subprojectId);
                        return function ($67) {
                            return $66((function (v7) {
                                return v7.id;
                            })($67));
                        };
                    })())(v4.value0));
                };
                return Data_Maybe.maybe(false)(Data_Eq.eq(Types_Project.eqProjectId)(costClass.projectId))(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(projectIdForSubproject)(Data_Nullable.toMaybe(_component.subProjectId)));
            };
        };
        var costClasses = function (_component) {
            return Data_Array.filter(isCostClassInComponentProject(_component))(v5.value0);
        };
        var resourceActionType = (function () {
            if (v.componentSplitParams instanceof Data_Maybe.Nothing) {
                return Types_Estimation.InPlaceResourceUpdate.value;
            };
            if (v.componentSplitParams instanceof Data_Maybe.Just) {
                return new Types_Estimation.SplitElementComponent(v.componentSplitParams.value0);
            };
            throw new Error("Failed pattern match at CostEstimation.SelectedComponentDetails (line 126, column 28 - line 128, column 70): " + [ v.componentSplitParams.constructor.name ]);
        })();
        var mSelectedEditViewComponentId = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v7) {
            return v7.selectedEditViewComponentId;
        })(v.referencePanelOptions);
        var referenceModeControls = Data_Monoid.guard(Data_Monoid.monoidArray)(Data_Eq.eq(Types_CostEstimation.eqECViewMode)(v.viewMode)(Types_CostEstimation.ReferenceMode.value))([ Data_Monoid.guard(Data_Monoid.monoidFn(React_Basic.monoidJSX))(Data_Maybe.isJust(mSelectedEditViewComponentId))(TacoButton.component()())({
            text: (function () {
                var $28 = Data_Set.isEmpty(v6.value0);
                if ($28) {
                    return "Lis\xe4\xe4 kaikki panokset";
                };
                return "Lis\xe4\xe4 valitut panokset";
            })(),
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconArrowLeft.value),
            disabled: Utils.isNothing(mSelectedEditViewComponentId),
            onClick: Data_Maybe.Just.create((function () {
                if (mSelectedEditViewComponentId instanceof Data_Maybe.Nothing) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                };
                if (mSelectedEditViewComponentId instanceof Data_Maybe.Just) {
                    return appDispatch(new Actions.EstimationResourceActionRequest({
                        projectId: v.projectId,
                        params: {
                            actionType: resourceActionType,
                            actionParams: new Types_Estimation.CopyResourcesToComponent({
                                componentId: mSelectedEditViewComponentId.value0,
                                resourceIds: (function () {
                                    var $30 = Data_Set.isEmpty(v6.value0);
                                    if ($30) {
                                        return Data_Functor.map(Data_Functor.functorArray)(function (v7) {
                                            return v7.id;
                                        })((Data_Newtype.unwrap()(v.selectedComponent)).resources);
                                    };
                                    return Data_Array.fromFoldable(Data_Set.foldableSet)(v6.value0);
                                })()
                            })
                        },
                        handler: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit)))
                    }));
                };
                throw new Error("Failed pattern match at CostEstimation.SelectedComponentDetails (line 141, column 19 - line 157, column 26): " + [ mSelectedEditViewComponentId.constructor.name ]);
            })()),
            buttonStyle: TacoButton_Types.Filled.value,
            size: TacoConstants.L.value,
            color: TacoConstants.resourceColors.border
        }), Data_Monoid.guard(Data_Monoid.monoidFn(React_Basic.monoidJSX))(Data_Maybe.isJust(mSelectedEditViewComponentId))(TacoButton.component()())({
            text: "Vaihda panokset",
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconArrowLeft.value),
            onClick: Data_Maybe.Just.create((function () {
                if (mSelectedEditViewComponentId instanceof Data_Maybe.Nothing) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                };
                if (mSelectedEditViewComponentId instanceof Data_Maybe.Just) {
                    return appDispatch(new Actions.EstimationResourceActionRequest({
                        projectId: v.projectId,
                        params: {
                            actionType: resourceActionType,
                            actionParams: new Types_Estimation.ReplaceComponentResources({
                                componentId: mSelectedEditViewComponentId.value0,
                                referenceComponentId: selectedComponent$prime.id
                            })
                        },
                        handler: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit)))
                    }));
                };
                throw new Error("Failed pattern match at CostEstimation.SelectedComponentDetails (line 166, column 19 - line 179, column 26): " + [ mSelectedEditViewComponentId.constructor.name ]);
            })()),
            buttonStyle: TacoButton_Types.Filled.value,
            size: TacoConstants.L.value,
            color: TacoConstants.resourceColors.border
        }), Data_Monoid.guard(Data_Monoid.monoidFn(React_Basic.monoidJSX))(Data_Maybe.isJust(mSelectedEditViewComponentId))(TacoButton.component()())({
            text: "Vaihda suorite",
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconArrowLeft.value),
            onClick: Data_Maybe.Just.create(Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(mSelectedEditViewComponentId)(function (selectedEditViewComponentId) {
                return appDispatch(new Actions.EstimationComponentActionRequest({
                    projectId: v.projectId,
                    params: new Types_Estimation.ReplaceEstimationComponent({
                        projectId: v.projectId,
                        targetElementId: Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v7) {
                            return v7.selectedEditViewElementId;
                        })(v.referencePanelOptions),
                        targetElementSpecId: Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v7) {
                            return v7.selectedEditViewElementSpecId;
                        })(v.referencePanelOptions),
                        targetComponentId: selectedEditViewComponentId,
                        referenceComponentId: selectedComponent$prime.id
                    }),
                    handler: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit)))
                }));
            })),
            buttonStyle: TacoButton_Types.Filled.value,
            size: TacoConstants.L.value
        }) ]);
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "__SelectedComponentDetails",
            css: CostEstimation_Styles.costEstimationDetailsContainerStyles({
                panelMode: v.panelMode
            }),
            children: [ EstimationComponentDetails.estimationComponentDetails({
                estimationComponent: new Data_Maybe.Just(v.selectedComponent),
                costClasses: (function () {
                    if (v.viewMode instanceof Types_CostEstimation.EditMode) {
                        return v2.value0;
                    };
                    if (v.viewMode instanceof Types_CostEstimation.ReferenceMode) {
                        return costClasses(selectedComponent$prime);
                    };
                    throw new Error("Failed pattern match at CostEstimation.SelectedComponentDetails (line 211, column 30 - line 213, column 66): " + [ v.viewMode.constructor.name ]);
                })(),
                estimationLocations: (Types_CostEstimation.unECWR(v.selectedComponent)).locations,
                projectLocations: v3.value0,
                onClose: v.selectComponent(true)(Data_Maybe.Nothing.value),
                projectId: v.projectId,
                loadingEstimateComponents: v.updateStatuses.loadingEstimateComponents,
                loadingEstimationLocationAction: v.updateStatuses.loadingEstimationLocationAction,
                toggleCheckbox: function (cId) {
                    return function (v7) {
                        return v.toggleCheckbox(cId);
                    };
                },
                subprojects: v1.value0,
                viewMode: v.viewMode,
                programType: v.programType,
                isReportingProject: v.isReportingProject,
                buildingDescriptionBySpecId: v.buildingDescriptionBySpecId,
                socialExpensePercentagesByCostClass: v.socialExpensePercentagesByCostClass,
                showSocialExpensesInCosts: v.showSocialExpensesInCosts,
                showWorkAchievement: v.showWorkAchievement,
                extraControls: referenceModeControls,
                selectedResourceIds: v6.value0,
                setSelectedResourceIds: v6.value1,
                panelMode: v.panelMode,
                setPanelMode: v.setPanelMode,
                hideResources: v.hideResources,
                projectCurrency: v.project.currency
            }) ]
        });
    };
});
module.exports = {
    selectedComponentDetails: selectedComponentDetails
};
