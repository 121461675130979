// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var projectNameStyles = Box.boxStyle([ Box.FlexRow.value, Box.AlignCenter.value, Box.Style.create(React_Basic_Emotion.css()({
    whiteSpace: React_Basic_Emotion.str("nowrap")
})) ]);
var headerTitleContainerStyles = Box.boxStyle([ Box.FlexRow.value, Box.AlignCenter.value, new Box.PaddingRight(TacoConstants.M.value) ]);
var dividerStyles = Box.boxStyle([ new Box.PaddingLeft(TacoConstants.S.value) ]);
var appSectionStyles = Box.boxStyle([ Box.FlexRow.value ]);
module.exports = {
    headerTitleContainerStyles: headerTitleContainerStyles,
    appSectionStyles: appSectionStyles,
    projectNameStyles: projectNameStyles,
    dividerStyles: dividerStyles
};
