// Generated by purs version 0.14.5
"use strict";
var CostEstimation_Calculations = require("../CostEstimation.Calculations/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Kishimen = require("../Kishimen/index.js");
var QuantityFormula_Types = require("../QuantityFormula.Types/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_ClassificationGroup = require("../Types.ClassificationGroup/index.js");
var Types_ComponentsView = require("../Types.ComponentsView/index.js");
var Types_CostClass = require("../Types.CostClass/index.js");
var Types_Element = require("../Types.Element/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Project = require("../Types.Project/index.js");
var WasmElementsData = function (x) {
    return x;
};
var GroupBySubproject = (function () {
    function GroupBySubproject() {

    };
    GroupBySubproject.value = new GroupBySubproject();
    return GroupBySubproject;
})();
var GroupByClassification = (function () {
    function GroupByClassification() {

    };
    GroupByClassification.value = new GroupByClassification();
    return GroupByClassification;
})();
var GroupByLocation = (function () {
    function GroupByLocation() {

    };
    GroupByLocation.value = new GroupByLocation();
    return GroupByLocation;
})();
var ordWasmElementsData = Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()({
    reflectSymbol: function () {
        return "unitPrice";
    }
})(Data_Ord.ordNumber))()({
    reflectSymbol: function () {
        return "totalPriceByCostClasses";
    }
})(Data_Ord.ordArray(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()({
    reflectSymbol: function () {
        return "totalPrice";
    }
})(Data_Ord.ordNumber))()({
    reflectSymbol: function () {
        return "socialExpensePrice";
    }
})(Data_Ord.ordNumber))()({
    reflectSymbol: function () {
        return "costClass";
    }
})(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostEstimation";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostControlForTargets";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostControlForEstimatesAndActuals";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostControlForComponents";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
    reflectSymbol: function () {
        return "projectId";
    }
})(Types_Project.ordProjectId))()({
    reflectSymbol: function () {
        return "name";
    }
})(Data_Ord.ordString))()({
    reflectSymbol: function () {
        return "multiplier";
    }
})(Data_Ord.ordNumber))()({
    reflectSymbol: function () {
        return "id";
    }
})(Types_CostClass.ordCostClassId))()({
    reflectSymbol: function () {
        return "hourlyPricing";
    }
})(Data_Ord.ordBoolean))()({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(Types_Newtypes.ordCostClassCode)))))))()({
    reflectSymbol: function () {
        return "totalPrice";
    }
})(Data_Ord.ordNumber))()({
    reflectSymbol: function () {
        return "subprojectCode";
    }
})(Data_Ord.ordString))()({
    reflectSymbol: function () {
        return "specs";
    }
})(Data_Ord.ordArray(Types_Element.ordWasmElementSpec)))()({
    reflectSymbol: function () {
        return "projectOfOrigin";
    }
})(Data_Nullable.ordNullable(Types_Project.ordProjectId)))()({
    reflectSymbol: function () {
        return "locations";
    }
})(Data_Ord.ordArray(Types_Element.ordWasmElementLocation)))()({
    reflectSymbol: function () {
        return "isTopicRow";
    }
})(Data_Ord.ordBoolean))()({
    reflectSymbol: function () {
        return "element";
    }
})(Types_Element.ordWasmElement))()({
    reflectSymbol: function () {
        return "components";
    }
})(Data_Ord.ordArray(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()({
    reflectSymbol: function () {
        return "unitPrice";
    }
})(Data_Ord.ordNumber))()({
    reflectSymbol: function () {
        return "unit";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
    reflectSymbol: function () {
        return "totalPrice";
    }
})(Data_Ord.ordNumber))()({
    reflectSymbol: function () {
        return "spec";
    }
})(Types_Element.ordWasmElementSpec))()({
    reflectSymbol: function () {
        return "resourcesByComponent";
    }
})(Types_ComponentsView.ordWasmResourcesByComponent))()({
    reflectSymbol: function () {
        return "group";
    }
})(Data_Nullable.ordNullable(Types_ClassificationGroup.ordClassificationGroupCode)))()({
    reflectSymbol: function () {
        return "formula";
    }
})(QuantityFormula_Types.ordInternalFormula))()({
    reflectSymbol: function () {
        return "elementUnitPrice";
    }
})(Data_Ord.ordNumber))()({
    reflectSymbol: function () {
        return "description";
    }
})(Data_Ord.ordString))()({
    reflectSymbol: function () {
        return "consumptionRate";
    }
})(Data_Ord.ordNumber))()({
    reflectSymbol: function () {
        return "componentReferenceCount";
    }
})(Data_Ord.ordInt))()({
    reflectSymbol: function () {
        return "amount";
    }
})(Data_Ord.ordNumber)))))()({
    reflectSymbol: function () {
        return "amount";
    }
})(Data_Ord.ordNumber));
var newtypeWasmElementsData = {
    Coercible0: function () {
        return undefined;
    }
};
var jsonWasmElementsData = TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "amount";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "components";
    }
})(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "amount";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "componentReferenceCount";
    }
})(TofuJSON.readInt)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "consumptionRate";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "description";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "elementUnitPrice";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "formula";
    }
})(QuantityFormula_Types.tofuJSONInternalFormula)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "group";
    }
})(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "resourcesByComponent";
    }
})(Types_ComponentsView.jsonWasmResourcesByComponent)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "spec";
    }
})(Types_Element.tofuJSONWasmElementSpec)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "totalPrice";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "unit";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "unitPrice";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "amount";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "componentReferenceCount";
    }
})(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "consumptionRate";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "description";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "elementUnitPrice";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "formula";
    }
})(QuantityFormula_Types.tofuJSONInternalFormula)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "group";
    }
})(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "resourcesByComponent";
    }
})(Types_ComponentsView.jsonWasmResourcesByComponent)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "spec";
    }
})(Types_Element.tofuJSONWasmElementSpec)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "totalPrice";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "unit";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "unitPrice";
    }
})(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "element";
    }
})(Types_Element.tofuJSONWasmElement)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "isTopicRow";
    }
})(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "locations";
    }
})(TofuJSON.readArray(Types_Element.tofuJSONWasmElementLocation))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "projectOfOrigin";
    }
})(TofuJSON.readNullable(Types_Project.tofuJSONProjectId))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "specs";
    }
})(TofuJSON.readArray(Types_Element.tofuJSONWasmElementSpec))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "subprojectCode";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "totalPrice";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "totalPriceByCostClasses";
    }
})(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "costClass";
    }
})(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "hourlyPricing";
    }
})(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "id";
    }
})(Types_CostClass.tofuJSONCostClassId)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "multiplier";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "name";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "projectId";
    }
})(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostControlForComponents";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostControlForEstimatesAndActuals";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostControlForTargets";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostEstimation";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "hourlyPricing";
    }
})(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "id";
    }
})(Types_CostClass.tofuJSONCostClassId)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "multiplier";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "name";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "projectId";
    }
})(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostControlForComponents";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostControlForEstimatesAndActuals";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostControlForTargets";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostEstimation";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "socialExpensePrice";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "totalPrice";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "costClass";
    }
})(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "hourlyPricing";
    }
})(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "id";
    }
})(Types_CostClass.tofuJSONCostClassId)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "multiplier";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "name";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "projectId";
    }
})(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostControlForComponents";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostControlForEstimatesAndActuals";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostControlForTargets";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostEstimation";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "hourlyPricing";
    }
})(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "id";
    }
})(Types_CostClass.tofuJSONCostClassId)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "multiplier";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "name";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "projectId";
    }
})(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostControlForComponents";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostControlForEstimatesAndActuals";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostControlForTargets";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostEstimation";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "socialExpensePrice";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "totalPrice";
    }
})(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "unitPrice";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "amount";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "components";
    }
})(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "amount";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "componentReferenceCount";
    }
})(TofuJSON.readInt)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "consumptionRate";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "description";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "elementUnitPrice";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "formula";
    }
})(QuantityFormula_Types.tofuJSONInternalFormula)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "group";
    }
})(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "resourcesByComponent";
    }
})(Types_ComponentsView.jsonWasmResourcesByComponent)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "spec";
    }
})(Types_Element.tofuJSONWasmElementSpec)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "totalPrice";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "unit";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "unitPrice";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "amount";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "componentReferenceCount";
    }
})(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "consumptionRate";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "description";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "elementUnitPrice";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "formula";
    }
})(QuantityFormula_Types.tofuJSONInternalFormula)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "group";
    }
})(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "resourcesByComponent";
    }
})(Types_ComponentsView.jsonWasmResourcesByComponent)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "spec";
    }
})(Types_Element.tofuJSONWasmElementSpec)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "totalPrice";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "unit";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "unitPrice";
    }
})(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "element";
    }
})(Types_Element.tofuJSONWasmElement)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "isTopicRow";
    }
})(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "locations";
    }
})(TofuJSON.readArray(Types_Element.tofuJSONWasmElementLocation))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "projectOfOrigin";
    }
})(TofuJSON.readNullable(Types_Project.tofuJSONProjectId))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "specs";
    }
})(TofuJSON.readArray(Types_Element.tofuJSONWasmElementSpec))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "subprojectCode";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "totalPrice";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "totalPriceByCostClasses";
    }
})(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "costClass";
    }
})(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "hourlyPricing";
    }
})(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "id";
    }
})(Types_CostClass.tofuJSONCostClassId)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "multiplier";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "name";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "projectId";
    }
})(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostControlForComponents";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostControlForEstimatesAndActuals";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostControlForTargets";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostEstimation";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "hourlyPricing";
    }
})(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "id";
    }
})(Types_CostClass.tofuJSONCostClassId)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "multiplier";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "name";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "projectId";
    }
})(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostControlForComponents";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostControlForEstimatesAndActuals";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostControlForTargets";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostEstimation";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "socialExpensePrice";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "totalPrice";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "costClass";
    }
})(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "hourlyPricing";
    }
})(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "id";
    }
})(Types_CostClass.tofuJSONCostClassId)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "multiplier";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "name";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "projectId";
    }
})(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostControlForComponents";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostControlForEstimatesAndActuals";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostControlForTargets";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostEstimation";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "hourlyPricing";
    }
})(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "id";
    }
})(Types_CostClass.tofuJSONCostClassId)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "multiplier";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "name";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "projectId";
    }
})(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostControlForComponents";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostControlForEstimatesAndActuals";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostControlForTargets";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostEstimation";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "socialExpensePrice";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "totalPrice";
    }
})(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "unitPrice";
    }
})(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()());
var genericElementsTableGroupBy = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return GroupBySubproject.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return GroupByClassification.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
            return GroupByLocation.value;
        };
        throw new Error("Failed pattern match at Types.BuildingElementsView (line 69, column 1 - line 69, column 78): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof GroupBySubproject) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof GroupByClassification) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
        };
        if (x instanceof GroupByLocation) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value));
        };
        throw new Error("Failed pattern match at Types.BuildingElementsView (line 69, column 1 - line 69, column 78): " + [ x.constructor.name ]);
    }
};
var tofuJSONElementsTableGroupBy = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "GroupByClassification";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "GroupByLocation";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "GroupBySubproject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "GroupByClassification";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "GroupByLocation";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "GroupBySubproject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant))))(genericElementsTableGroupBy)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "GroupBySubproject";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "GroupByClassification";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "GroupByLocation";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "GroupByClassification";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "GroupByLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "GroupBySubproject";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "GroupByLocation";
        }
    }))({
        reflectSymbol: function () {
            return "GroupByClassification";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericElementsTableGroupBy)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "GroupBySubproject";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "GroupByClassification";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "GroupByLocation";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "GroupByClassification";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "GroupByLocation";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "GroupBySubproject";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "GroupByLocation";
        }
    }))({
        reflectSymbol: function () {
            return "GroupByClassification";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "GroupByClassification";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "GroupByLocation";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "GroupBySubproject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "GroupByClassification";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "GroupByLocation";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "GroupBySubproject";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant))))
};
var eqWasmElementsData = Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "unitPrice";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "totalPriceByCostClasses";
    }
})(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "totalPrice";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "socialExpensePrice";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "costClass";
    }
})(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostEstimation";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostControlForTargets";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostControlForEstimatesAndActuals";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "socialExpensePercentageInCostControlForComponents";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "projectId";
    }
})(Types_Project.eqProjectId))()({
    reflectSymbol: function () {
        return "name";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "multiplier";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "id";
    }
})(Types_CostClass.eqCostClassId))()({
    reflectSymbol: function () {
        return "hourlyPricing";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(Types_Newtypes.eqCostClassCode)))))))()({
    reflectSymbol: function () {
        return "totalPrice";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "subprojectCode";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "specs";
    }
})(Data_Eq.eqArray(Types_Element.eqWasmElementSpec)))()({
    reflectSymbol: function () {
        return "projectOfOrigin";
    }
})(Data_Nullable.eqNullable(Types_Project.eqProjectId)))()({
    reflectSymbol: function () {
        return "locations";
    }
})(Data_Eq.eqArray(Types_Element.eqWasmElementLocation)))()({
    reflectSymbol: function () {
        return "isTopicRow";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "element";
    }
})(Types_Element.eqWasmElement))()({
    reflectSymbol: function () {
        return "components";
    }
})(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "unitPrice";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "unit";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "totalPrice";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "spec";
    }
})(Types_Element.eqWasmElementSpec))()({
    reflectSymbol: function () {
        return "resourcesByComponent";
    }
})(Types_ComponentsView.eqWasmResourcesByComponent))()({
    reflectSymbol: function () {
        return "group";
    }
})(Data_Nullable.eqNullable(Types_ClassificationGroup.eqClassificationGroupCode)))()({
    reflectSymbol: function () {
        return "formula";
    }
})(QuantityFormula_Types.eqInternalFormula))()({
    reflectSymbol: function () {
        return "elementUnitPrice";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "description";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "consumptionRate";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "componentReferenceCount";
    }
})(Data_Eq.eqInt))()({
    reflectSymbol: function () {
        return "amount";
    }
})(Data_Eq.eqNumber)))))()({
    reflectSymbol: function () {
        return "amount";
    }
})(Data_Eq.eqNumber));
var eqElementsTableGroupBy = {
    eq: function (x) {
        return function (y) {
            if (x instanceof GroupBySubproject && y instanceof GroupBySubproject) {
                return true;
            };
            if (x instanceof GroupByClassification && y instanceof GroupByClassification) {
                return true;
            };
            if (x instanceof GroupByLocation && y instanceof GroupByLocation) {
                return true;
            };
            return false;
        };
    }
};
var elementsDataComponentGroupCodes = function (v) {
    return Data_Array.nub(Types_ClassificationGroup.ordClassificationGroupCode)(Data_Array.sort(Types_ClassificationGroup.ordClassificationGroupCode)(Data_Array.mapMaybe(function ($15) {
        return CostEstimation_Calculations.extractClassificationGroupCodes((function (v1) {
            return v1.resourcesByComponent;
        })($15));
    })(v.components)));
};
module.exports = {
    WasmElementsData: WasmElementsData,
    elementsDataComponentGroupCodes: elementsDataComponentGroupCodes,
    GroupBySubproject: GroupBySubproject,
    GroupByClassification: GroupByClassification,
    GroupByLocation: GroupByLocation,
    newtypeWasmElementsData: newtypeWasmElementsData,
    eqWasmElementsData: eqWasmElementsData,
    ordWasmElementsData: ordWasmElementsData,
    jsonWasmElementsData: jsonWasmElementsData,
    eqElementsTableGroupBy: eqElementsTableGroupBy,
    genericElementsTableGroupBy: genericElementsTableGroupBy,
    tofuJSONElementsTableGroupBy: tofuJSONElementsTableGroupBy
};
