// Generated by purs version 0.14.5
"use strict";
var AlignLeft = (function () {
    function AlignLeft() {

    };
    AlignLeft.value = new AlignLeft();
    return AlignLeft;
})();
var AlignRight = (function () {
    function AlignRight() {

    };
    AlignRight.value = new AlignRight();
    return AlignRight;
})();
module.exports = {
    AlignLeft: AlignLeft,
    AlignRight: AlignRight
};
