// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Actions = require("../Actions/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var Locale = require("../Locale/index.js");
var React_Basic_DOM_Internal = require("../React.Basic.DOM.Internal/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_Alert = require("../Types.Alert/index.js");
var Web_HTML = require("../Web.HTML/index.js");
var Web_HTML_Window = require("../Web.HTML.Window/index.js");
var Web_Storage_Storage = require("../Web.Storage.Storage/index.js");
var LocalStorageParam = function (x) {
    return x;
};
var setLocalStorageItem = function (v) {
    return function __do() {
        var window = Web_HTML.window();
        var localStorage = Web_HTML_Window.localStorage(window)();
        return Web_Storage_Storage.setItem(v.key)(v.value)(localStorage)();
    };
};
var setLocalStorageParam = function (dictIsSymbol) {
    return function (dictTofuJSON) {
        return function (v) {
            var key = Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value);
            var value = TofuJSON.writeJSON(dictTofuJSON)(v);
            return setLocalStorageItem({
                key: key,
                value: value
            });
        };
    };
};
var setLocalStorageParam$prime = function (dictIsSymbol) {
    return function (dictTofuJSON) {
        return function (v) {
            return function (value) {
                return setLocalStorageParam(dictIsSymbol)(dictTofuJSON)(value);
            };
        };
    };
};
var openInvoiceInNewTab = function (url) {
    return function (name) {
        return function (dispatch) {
            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Web_HTML.window))(function (window) {
                return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Data_Functor.map(Effect.functorEffect)(Data_Maybe.isJust)(Web_HTML_Window.open(url)(name)("")(window))))(function (success) {
                    if (success) {
                        return Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Unit.unit);
                    };
                    return dispatch(Actions.AddAlert.create(Types_Alert.AlertError.value)(Locale.lookup_("invoice_error_image_download_blocked_by_popup")));
                });
            });
        };
    };
};
var newtypeLocalStorageParam = {
    Coercible0: function () {
        return undefined;
    }
};
var getLocalStorageItem = function (key) {
    return function __do() {
        var window = Web_HTML.window();
        var localStorage = Web_HTML_Window.localStorage(window)();
        return Web_Storage_Storage.getItem(key)(localStorage)();
    };
};
var circle = function (dictUnion) {
    return Effect_Unsafe.unsafePerformEffect(React_Basic_DOM_Internal.unsafeCreateDOMComponent("circle"));
};
module.exports = {
    circle: circle,
    openInvoiceInNewTab: openInvoiceInNewTab,
    getLocalStorageItem: getLocalStorageItem,
    setLocalStorageItem: setLocalStorageItem,
    LocalStorageParam: LocalStorageParam,
    setLocalStorageParam: setLocalStorageParam,
    "setLocalStorageParam'": setLocalStorageParam$prime,
    newtypeLocalStorageParam: newtypeLocalStorageParam,
    triggerClick: $foreign.triggerClick,
    getLocationPathName: $foreign.getLocationPathName,
    isFocusEventTargetContained: $foreign.isFocusEventTargetContained,
    getDOMElementScrollTop: $foreign.getDOMElementScrollTop,
    blurActiveElement: $foreign.blurActiveElement,
    navigateToUrl: $foreign.navigateToUrl,
    navigateToUrlInNewTab: $foreign.navigateToUrlInNewTab
};
