// Generated by purs version 0.14.5
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Show_Generic = require("../Data.Show.Generic/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Kishimen = require("../Kishimen/index.js");
var QuantityFormula_Types = require("../QuantityFormula.Types/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_JSDateString = require("../Types.JSDateString/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Project = require("../Types.Project/index.js");
var GroupFlagNone = (function () {
    function GroupFlagNone() {

    };
    GroupFlagNone.value = new GroupFlagNone();
    return GroupFlagNone;
})();
var GroupFlagFlag = (function () {
    function GroupFlagFlag() {

    };
    GroupFlagFlag.value = new GroupFlagFlag();
    return GroupFlagFlag;
})();
var GroupFlagCheck = (function () {
    function GroupFlagCheck() {

    };
    GroupFlagCheck.value = new GroupFlagCheck();
    return GroupFlagCheck;
})();
var GroupFlagBoth = (function () {
    function GroupFlagBoth() {

    };
    GroupFlagBoth.value = new GroupFlagBoth();
    return GroupFlagBoth;
})();
var ElementMeasureId = function (x) {
    return x;
};
var ElementLocationId = function (x) {
    return x;
};
var ElementId = function (x) {
    return x;
};
var ElementMeasure = function (x) {
    return x;
};
var WasmElementLocation = function (x) {
    return x;
};
var WasmElementSpec = function (x) {
    return x;
};
var ElementCode = function (x) {
    return x;
};
var WasmEstimationElement = function (x) {
    return x;
};
var DeleteElementSpecs = (function () {
    function DeleteElementSpecs(value0) {
        this.value0 = value0;
    };
    DeleteElementSpecs.create = function (value0) {
        return new DeleteElementSpecs(value0);
    };
    return DeleteElementSpecs;
})();
var DeleteElements = (function () {
    function DeleteElements(value0) {
        this.value0 = value0;
    };
    DeleteElements.create = function (value0) {
        return new DeleteElements(value0);
    };
    return DeleteElements;
})();
var EditElementComponentConsumptionRate = (function () {
    function EditElementComponentConsumptionRate(value0) {
        this.value0 = value0;
    };
    EditElementComponentConsumptionRate.create = function (value0) {
        return new EditElementComponentConsumptionRate(value0);
    };
    return EditElementComponentConsumptionRate;
})();
var EditElementComponentTotalQuantity = (function () {
    function EditElementComponentTotalQuantity(value0) {
        this.value0 = value0;
    };
    EditElementComponentTotalQuantity.create = function (value0) {
        return new EditElementComponentTotalQuantity(value0);
    };
    return EditElementComponentTotalQuantity;
})();
var DuplicateComponentsWithElementSpecs = (function () {
    function DuplicateComponentsWithElementSpecs(value0) {
        this.value0 = value0;
    };
    DuplicateComponentsWithElementSpecs.create = function (value0) {
        return new DuplicateComponentsWithElementSpecs(value0);
    };
    return DuplicateComponentsWithElementSpecs;
})();
var EditElementComponentFormula = (function () {
    function EditElementComponentFormula(value0) {
        this.value0 = value0;
    };
    EditElementComponentFormula.create = function (value0) {
        return new EditElementComponentFormula(value0);
    };
    return EditElementComponentFormula;
})();
var EditElementComponentAmountSource = (function () {
    function EditElementComponentAmountSource(value0) {
        this.value0 = value0;
    };
    EditElementComponentAmountSource.create = function (value0) {
        return new EditElementComponentAmountSource(value0);
    };
    return EditElementComponentAmountSource;
})();
var ResetElementAmounts = (function () {
    function ResetElementAmounts(value0) {
        this.value0 = value0;
    };
    ResetElementAmounts.create = function (value0) {
        return new ResetElementAmounts(value0);
    };
    return ResetElementAmounts;
})();
var RefreshFormulas = (function () {
    function RefreshFormulas() {

    };
    RefreshFormulas.value = new RefreshFormulas();
    return RefreshFormulas;
})();
var EditElementMeasureCode = (function () {
    function EditElementMeasureCode(value0) {
        this.value0 = value0;
    };
    EditElementMeasureCode.create = function (value0) {
        return new EditElementMeasureCode(value0);
    };
    return EditElementMeasureCode;
})();
var EditElementMeasureDescription = (function () {
    function EditElementMeasureDescription(value0) {
        this.value0 = value0;
    };
    EditElementMeasureDescription.create = function (value0) {
        return new EditElementMeasureDescription(value0);
    };
    return EditElementMeasureDescription;
})();
var EditElementMeasureValue = (function () {
    function EditElementMeasureValue(value0) {
        this.value0 = value0;
    };
    EditElementMeasureValue.create = function (value0) {
        return new EditElementMeasureValue(value0);
    };
    return EditElementMeasureValue;
})();
var AddElementMeasure = (function () {
    function AddElementMeasure(value0) {
        this.value0 = value0;
    };
    AddElementMeasure.create = function (value0) {
        return new AddElementMeasure(value0);
    };
    return AddElementMeasure;
})();
var tofuJSONElementMeasureId = TofuJSON.readInt;
var tofuJSONElementLocationId = TofuJSON.readInt;
var tofuJSONElementId = TofuJSON.readInt;
var tofuJSONElementMeasure = TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "code";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "description";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "elementId";
    }
})(TofuJSON.readMaybe(tofuJSONElementId))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "elementMeasureId";
    }
})(tofuJSONElementMeasureId)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "value";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "code";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "description";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "elementId";
    }
})(TofuJSON.readMaybe(tofuJSONElementId))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "elementMeasureId";
    }
})(tofuJSONElementMeasureId)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "value";
    }
})(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()());
var tofuJSONWasmElementLocation = TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "amount";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "amountSourceCode";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "description";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "elementId";
    }
})(TofuJSON.readNullable(tofuJSONElementId))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "elementLocationId";
    }
})(tofuJSONElementLocationId)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "group";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "locationCode";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "sourceApplication";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "time";
    }
})(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "user";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "amount";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "amountSourceCode";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "description";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "elementId";
    }
})(TofuJSON.readNullable(tofuJSONElementId))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "elementLocationId";
    }
})(tofuJSONElementLocationId)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "group";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "locationCode";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "sourceApplication";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "time";
    }
})(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "user";
    }
})(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()());
var tofuJSONWasmElementSpec = TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "amountSource";
    }
})(TofuJSON.readInt)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "componentId";
    }
})(TofuJSON.readNullable(Types_Component.tofuJSONComponentId))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "elementId";
    }
})(TofuJSON.readNullable(tofuJSONElementId))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "elementSpecId";
    }
})(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "formula";
    }
})(QuantityFormula_Types.tofuJSONInternalFormula)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "unitConsumptionRate";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "amountSource";
    }
})(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "componentId";
    }
})(TofuJSON.readNullable(Types_Component.tofuJSONComponentId))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "elementId";
    }
})(TofuJSON.readNullable(tofuJSONElementId))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "elementSpecId";
    }
})(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "formula";
    }
})(QuantityFormula_Types.tofuJSONInternalFormula)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "unitConsumptionRate";
    }
})(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()());
var tofuJSONElementCode = TofuJSON.readString;
var tofuJSONWasmElement = TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "code";
    }
})(tofuJSONElementCode)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "description";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "elementId";
    }
})(tofuJSONElementId)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "groupFlag";
    }
})(TofuJSON.readInt)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "memo";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "rtCode";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "rtDescription";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "subprojectId";
    }
})(TofuJSON.readNullable(Types_Project.tofuJSONSubprojectId))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "unit";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "code";
    }
})(tofuJSONElementCode)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "description";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "elementId";
    }
})(tofuJSONElementId)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "groupFlag";
    }
})(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "memo";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "rtCode";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "rtDescription";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "subprojectId";
    }
})(TofuJSON.readNullable(Types_Project.tofuJSONSubprojectId))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "unit";
    }
})(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()());
var showElementMeasureId = Data_Show.showInt;
var showElementLocationId = Data_Show.showInt;
var showElementId = Data_Show.showInt;
var showElementMeasure = Data_Show.showRecord()(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "code";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "description";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "elementId";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "elementMeasureId";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "value";
    }
})(Data_Show.showRecordFieldsNil)(Data_Show.showNumber))(showElementMeasureId))(Data_Maybe.showMaybe(showElementId)))(Data_Show.showString))(Data_Show.showString));
var showWasmElementLocation = Data_Show.showRecord()(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "amount";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "amountSourceCode";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "description";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "elementId";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "elementLocationId";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "group";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "locationCode";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "sourceApplication";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "time";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "user";
    }
})(Data_Show.showRecordFieldsNil)(Data_Show.showString))(Data_Nullable.showNullable(Types_JSDateString.showJSDateString)))(Data_Show.showString))(Data_Show.showString))(Data_Show.showString))(showElementLocationId))(Data_Nullable.showNullable(showElementId)))(Data_Show.showString))(Data_Show.showNumber))(Data_Show.showNumber));
var showWasmElementSpec = Data_Show.showRecord()(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "amountSource";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "componentId";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "elementId";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "elementSpecId";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "formula";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "unitConsumptionRate";
    }
})(Data_Show.showRecordFieldsNil)(Data_Show.showNumber))(QuantityFormula_Types.showInternalFormula))(Types_Newtypes.showElementSpecId))(Data_Nullable.showNullable(showElementId)))(Data_Nullable.showNullable(Types_Component.showComponentId)))(Data_Show.showInt));
var showElementCode = Data_Show.showString;
var showWasmElement = Data_Show.showRecord()(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "code";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "description";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "elementId";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "groupFlag";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "memo";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "rtCode";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "rtDescription";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "subprojectId";
    }
})(Data_Show.showRecordFieldsCons({
    reflectSymbol: function () {
        return "unit";
    }
})(Data_Show.showRecordFieldsNil)(Data_Show.showString))(Data_Nullable.showNullable(Types_Project.showSubprojectId)))(Data_Show.showString))(Data_Show.showString))(Data_Show.showString))(Data_Show.showInt))(showElementId))(Data_Show.showString))(showElementCode));
var ordElementMeasureId = Data_Ord.ordInt;
var ordElementLocationId = Data_Ord.ordInt;
var ordElementId = Data_Ord.ordInt;
var ordWasmElementLocation = Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()({
    reflectSymbol: function () {
        return "user";
    }
})(Data_Ord.ordString))()({
    reflectSymbol: function () {
        return "time";
    }
})(Data_Nullable.ordNullable(Types_JSDateString.ordJSDateString)))()({
    reflectSymbol: function () {
        return "sourceApplication";
    }
})(Data_Ord.ordString))()({
    reflectSymbol: function () {
        return "locationCode";
    }
})(Data_Ord.ordString))()({
    reflectSymbol: function () {
        return "group";
    }
})(Data_Ord.ordString))()({
    reflectSymbol: function () {
        return "elementLocationId";
    }
})(ordElementLocationId))()({
    reflectSymbol: function () {
        return "elementId";
    }
})(Data_Nullable.ordNullable(ordElementId)))()({
    reflectSymbol: function () {
        return "description";
    }
})(Data_Ord.ordString))()({
    reflectSymbol: function () {
        return "amountSourceCode";
    }
})(Data_Ord.ordNumber))()({
    reflectSymbol: function () {
        return "amount";
    }
})(Data_Ord.ordNumber));
var ordWasmElementSpec = Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()({
    reflectSymbol: function () {
        return "unitConsumptionRate";
    }
})(Data_Ord.ordNumber))()({
    reflectSymbol: function () {
        return "formula";
    }
})(QuantityFormula_Types.ordInternalFormula))()({
    reflectSymbol: function () {
        return "elementSpecId";
    }
})(Types_Newtypes.ordElementSpecId))()({
    reflectSymbol: function () {
        return "elementId";
    }
})(Data_Nullable.ordNullable(ordElementId)))()({
    reflectSymbol: function () {
        return "componentId";
    }
})(Data_Nullable.ordNullable(Types_Component.ordComponentId)))()({
    reflectSymbol: function () {
        return "amountSource";
    }
})(Data_Ord.ordInt));
var ordElementCode = Data_Ord.ordString;
var ordWasmElement = Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()({
    reflectSymbol: function () {
        return "unit";
    }
})(Data_Ord.ordString))()({
    reflectSymbol: function () {
        return "subprojectId";
    }
})(Data_Nullable.ordNullable(Types_Project.ordSubprojectId)))()({
    reflectSymbol: function () {
        return "rtDescription";
    }
})(Data_Ord.ordString))()({
    reflectSymbol: function () {
        return "rtCode";
    }
})(Data_Ord.ordString))()({
    reflectSymbol: function () {
        return "memo";
    }
})(Data_Ord.ordString))()({
    reflectSymbol: function () {
        return "groupFlag";
    }
})(Data_Ord.ordInt))()({
    reflectSymbol: function () {
        return "elementId";
    }
})(ordElementId))()({
    reflectSymbol: function () {
        return "description";
    }
})(Data_Ord.ordString))()({
    reflectSymbol: function () {
        return "code";
    }
})(ordElementCode));
var newtypeWasmElementSpec = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeWasmElementLocation = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeWasmElement = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeElementMeasureId = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeElementMeasure = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeElementLocationId = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeElementId = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeElementCode = {
    Coercible0: function () {
        return undefined;
    }
};
var groupFlagToInt = function (x) {
    if (x instanceof GroupFlagCheck) {
        return 1;
    };
    if (x instanceof GroupFlagFlag) {
        return 2;
    };
    if (x instanceof GroupFlagBoth) {
        return 3;
    };
    if (x instanceof GroupFlagNone) {
        return 0;
    };
    throw new Error("Failed pattern match at Types.Element (line 39, column 20 - line 43, column 21): " + [ x.constructor.name ]);
};
var groupFlagFromInt = function (x) {
    if (x === 1) {
        return GroupFlagCheck.value;
    };
    if (x === 2) {
        return GroupFlagFlag.value;
    };
    if (x === 3) {
        return GroupFlagBoth.value;
    };
    return GroupFlagNone.value;
};
var genericGroupFlag = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return GroupFlagNone.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return GroupFlagFlag.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return GroupFlagCheck.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr)) {
            return GroupFlagBoth.value;
        };
        throw new Error("Failed pattern match at Types.Element (line 214, column 1 - line 214, column 56): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof GroupFlagNone) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof GroupFlagFlag) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
        };
        if (x instanceof GroupFlagCheck) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
        };
        if (x instanceof GroupFlagBoth) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)));
        };
        throw new Error("Failed pattern match at Types.Element (line 214, column 1 - line 214, column 56): " + [ x.constructor.name ]);
    }
};
var showGroupFlag = {
    show: Data_Show_Generic.genericShow(genericGroupFlag)(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsNoArguments)({
        reflectSymbol: function () {
            return "GroupFlagNone";
        }
    }))(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsNoArguments)({
        reflectSymbol: function () {
            return "GroupFlagFlag";
        }
    }))(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsNoArguments)({
        reflectSymbol: function () {
            return "GroupFlagCheck";
        }
    }))(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsNoArguments)({
        reflectSymbol: function () {
            return "GroupFlagBoth";
        }
    })))))
};
var tofuJSONGroupFlag = {
    readImpl: TofuJSON.readGenericSum(genericGroupFlag)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "GroupFlagNone";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "GroupFlagFlag";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "GroupFlagCheck";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "GroupFlagBoth";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "GroupFlagCheck";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "GroupFlagBoth";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "GroupFlagFlag";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "GroupFlagCheck";
        }
    }))({
        reflectSymbol: function () {
            return "GroupFlagBoth";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "GroupFlagNone";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "GroupFlagFlag";
        }
    }))({
        reflectSymbol: function () {
            return "GroupFlagCheck";
        }
    }))({
        reflectSymbol: function () {
            return "GroupFlagBoth";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "GroupFlagBoth";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "GroupFlagCheck";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "GroupFlagFlag";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "GroupFlagNone";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "GroupFlagBoth";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "GroupFlagCheck";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "GroupFlagFlag";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "GroupFlagNone";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant))))),
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "GroupFlagBoth";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "GroupFlagCheck";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "GroupFlagFlag";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "GroupFlagNone";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "GroupFlagBoth";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "GroupFlagCheck";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "GroupFlagFlag";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "GroupFlagNone";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))))(genericGroupFlag)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "GroupFlagNone";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "GroupFlagFlag";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "GroupFlagCheck";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "GroupFlagBoth";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "GroupFlagCheck";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "GroupFlagBoth";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "GroupFlagFlag";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "GroupFlagCheck";
        }
    }))({
        reflectSymbol: function () {
            return "GroupFlagBoth";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "GroupFlagNone";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "GroupFlagFlag";
        }
    }))({
        reflectSymbol: function () {
            return "GroupFlagCheck";
        }
    }))({
        reflectSymbol: function () {
            return "GroupFlagBoth";
        }
    })))
};
var genericElementMeasureAction = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new EditElementMeasureCode(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new EditElementMeasureDescription(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return new EditElementMeasureValue(x.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr)) {
            return new AddElementMeasure(x.value0.value0.value0);
        };
        throw new Error("Failed pattern match at Types.Element (line 243, column 1 - line 243, column 78): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof EditElementMeasureCode) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof EditElementMeasureDescription) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof EditElementMeasureValue) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
        };
        if (x instanceof AddElementMeasure) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0)));
        };
        throw new Error("Failed pattern match at Types.Element (line 243, column 1 - line 243, column 78): " + [ x.constructor.name ]);
    }
};
var tofuJSONElementMeasureAction = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddElementMeasure";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditElementMeasureCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementMeasureId";
        }
    })(tofuJSONElementMeasureId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementMeasureId";
        }
    })(tofuJSONElementMeasureId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditElementMeasureDescription";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementMeasureId";
        }
    })(tofuJSONElementMeasureId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementMeasureId";
        }
    })(tofuJSONElementMeasureId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditElementMeasureValue";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementMeasureId";
        }
    })(tofuJSONElementMeasureId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "value";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementMeasureId";
        }
    })(tofuJSONElementMeasureId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "value";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantNil)))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddElementMeasure";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditElementMeasureCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementMeasureId";
        }
    })(tofuJSONElementMeasureId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementMeasureId";
        }
    })(tofuJSONElementMeasureId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditElementMeasureDescription";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementMeasureId";
        }
    })(tofuJSONElementMeasureId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementMeasureId";
        }
    })(tofuJSONElementMeasureId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditElementMeasureValue";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementMeasureId";
        }
    })(tofuJSONElementMeasureId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "value";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementMeasureId";
        }
    })(tofuJSONElementMeasureId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "value";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.nilWriteForeignVariant)))))(genericElementMeasureAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditElementMeasureCode";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditElementMeasureDescription";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditElementMeasureValue";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddElementMeasure";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditElementMeasureValue";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddElementMeasure";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditElementMeasureDescription";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditElementMeasureValue";
        }
    }))({
        reflectSymbol: function () {
            return "AddElementMeasure";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditElementMeasureCode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditElementMeasureValue";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementMeasureDescription";
        }
    }))({
        reflectSymbol: function () {
            return "AddElementMeasure";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericElementMeasureAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditElementMeasureCode";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditElementMeasureDescription";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditElementMeasureValue";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddElementMeasure";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditElementMeasureValue";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddElementMeasure";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditElementMeasureDescription";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditElementMeasureValue";
        }
    }))({
        reflectSymbol: function () {
            return "AddElementMeasure";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditElementMeasureCode";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditElementMeasureValue";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementMeasureDescription";
        }
    }))({
        reflectSymbol: function () {
            return "AddElementMeasure";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddElementMeasure";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditElementMeasureCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementMeasureId";
        }
    })(tofuJSONElementMeasureId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementMeasureId";
        }
    })(tofuJSONElementMeasureId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditElementMeasureDescription";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementMeasureId";
        }
    })(tofuJSONElementMeasureId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementMeasureId";
        }
    })(tofuJSONElementMeasureId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditElementMeasureValue";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementMeasureId";
        }
    })(tofuJSONElementMeasureId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "value";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementMeasureId";
        }
    })(tofuJSONElementMeasureId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "value";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantNil)))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddElementMeasure";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditElementMeasureCode";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementMeasureId";
        }
    })(tofuJSONElementMeasureId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementMeasureId";
        }
    })(tofuJSONElementMeasureId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditElementMeasureDescription";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementMeasureId";
        }
    })(tofuJSONElementMeasureId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "description";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementMeasureId";
        }
    })(tofuJSONElementMeasureId)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditElementMeasureValue";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementMeasureId";
        }
    })(tofuJSONElementMeasureId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "value";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementMeasureId";
        }
    })(tofuJSONElementMeasureId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "value";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.nilWriteForeignVariant)))))
};
var genericElementAction = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new DeleteElementSpecs(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new DeleteElements(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return new EditElementComponentConsumptionRate(x.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl))) {
            return new EditElementComponentTotalQuantity(x.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))) {
            return new DuplicateComponentsWithElementSpecs(x.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))) {
            return new EditElementComponentFormula(x.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl)))))) {
            return new EditElementComponentAmountSource(x.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl))))))) {
            return new ResetElementAmounts(x.value0.value0.value0.value0.value0.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && (x.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr))))))) {
            return RefreshFormulas.value;
        };
        throw new Error("Failed pattern match at Types.Element (line 239, column 1 - line 239, column 64): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof DeleteElementSpecs) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof DeleteElements) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof EditElementComponentConsumptionRate) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
        };
        if (x instanceof EditElementComponentTotalQuantity) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))));
        };
        if (x instanceof DuplicateComponentsWithElementSpecs) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))));
        };
        if (x instanceof EditElementComponentFormula) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))));
        };
        if (x instanceof EditElementComponentAmountSource) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)))))));
        };
        if (x instanceof ResetElementAmounts) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0))))))));
        };
        if (x instanceof RefreshFormulas) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value))))))));
        };
        throw new Error("Failed pattern match at Types.Element (line 239, column 1 - line 239, column 64): " + [ x.constructor.name ]);
    }
};
var tofuJSONElementAction = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DeleteElementSpecs";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "deleteComponents";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "deleteComponents";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DeleteElements";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "deleteComponents";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementIds";
        }
    })(TofuJSON.readArray(tofuJSONElementId))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "deleteComponents";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementIds";
        }
    })(TofuJSON.readArray(tofuJSONElementId))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DuplicateComponentsWithElementSpecs";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "allowDuplicateComponents";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "allowDuplicateComponents";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditElementComponentAmountSource";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amountSource";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amountSource";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditElementComponentConsumptionRate";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditElementComponentFormula";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newFormula";
        }
    })(QuantityFormula_Types.tofuJSONInternalFormula)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newFormula";
        }
    })(QuantityFormula_Types.tofuJSONInternalFormula)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditElementComponentTotalQuantity";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newTotalQuantity";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newTotalQuantity";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "RefreshFormulas";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ResetElementAmounts";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementIds";
        }
    })(TofuJSON.readArray(tofuJSONElementId))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementIds";
        }
    })(TofuJSON.readArray(tofuJSONElementId))(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantNil))))))))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DeleteElementSpecs";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "deleteComponents";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "deleteComponents";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DeleteElements";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "deleteComponents";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementIds";
        }
    })(TofuJSON.readArray(tofuJSONElementId))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "deleteComponents";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementIds";
        }
    })(TofuJSON.readArray(tofuJSONElementId))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DuplicateComponentsWithElementSpecs";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "allowDuplicateComponents";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "allowDuplicateComponents";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditElementComponentAmountSource";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amountSource";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amountSource";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditElementComponentConsumptionRate";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditElementComponentFormula";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newFormula";
        }
    })(QuantityFormula_Types.tofuJSONInternalFormula)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newFormula";
        }
    })(QuantityFormula_Types.tofuJSONInternalFormula)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditElementComponentTotalQuantity";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newTotalQuantity";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newTotalQuantity";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "RefreshFormulas";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ResetElementAmounts";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementIds";
        }
    })(TofuJSON.readArray(tofuJSONElementId))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementIds";
        }
    })(TofuJSON.readArray(tofuJSONElementId))(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.nilWriteForeignVariant))))))))))(genericElementAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DeleteElementSpecs";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DeleteElements";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditElementComponentConsumptionRate";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditElementComponentTotalQuantity";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DuplicateComponentsWithElementSpecs";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditElementComponentFormula";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditElementComponentAmountSource";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ResetElementAmounts";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "RefreshFormulas";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ResetElementAmounts";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "RefreshFormulas";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditElementComponentAmountSource";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ResetElementAmounts";
        }
    }))({
        reflectSymbol: function () {
            return "RefreshFormulas";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditElementComponentFormula";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ResetElementAmounts";
        }
    }))({
        reflectSymbol: function () {
            return "RefreshFormulas";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementComponentAmountSource";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DuplicateComponentsWithElementSpecs";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ResetElementAmounts";
        }
    }))({
        reflectSymbol: function () {
            return "RefreshFormulas";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementComponentFormula";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementComponentAmountSource";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditElementComponentTotalQuantity";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ResetElementAmounts";
        }
    }))({
        reflectSymbol: function () {
            return "RefreshFormulas";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementComponentFormula";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementComponentAmountSource";
        }
    }))({
        reflectSymbol: function () {
            return "DuplicateComponentsWithElementSpecs";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditElementComponentConsumptionRate";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ResetElementAmounts";
        }
    }))({
        reflectSymbol: function () {
            return "RefreshFormulas";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementComponentTotalQuantity";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementComponentFormula";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementComponentAmountSource";
        }
    }))({
        reflectSymbol: function () {
            return "DuplicateComponentsWithElementSpecs";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DeleteElements";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ResetElementAmounts";
        }
    }))({
        reflectSymbol: function () {
            return "RefreshFormulas";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementComponentTotalQuantity";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementComponentFormula";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementComponentConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementComponentAmountSource";
        }
    }))({
        reflectSymbol: function () {
            return "DuplicateComponentsWithElementSpecs";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DeleteElementSpecs";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ResetElementAmounts";
        }
    }))({
        reflectSymbol: function () {
            return "RefreshFormulas";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementComponentTotalQuantity";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementComponentFormula";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementComponentConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementComponentAmountSource";
        }
    }))({
        reflectSymbol: function () {
            return "DuplicateComponentsWithElementSpecs";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteElements";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericElementAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DeleteElementSpecs";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DeleteElements";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditElementComponentConsumptionRate";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditElementComponentTotalQuantity";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DuplicateComponentsWithElementSpecs";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditElementComponentFormula";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditElementComponentAmountSource";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ResetElementAmounts";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "RefreshFormulas";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ResetElementAmounts";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "RefreshFormulas";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditElementComponentAmountSource";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ResetElementAmounts";
        }
    }))({
        reflectSymbol: function () {
            return "RefreshFormulas";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditElementComponentFormula";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ResetElementAmounts";
        }
    }))({
        reflectSymbol: function () {
            return "RefreshFormulas";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementComponentAmountSource";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DuplicateComponentsWithElementSpecs";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ResetElementAmounts";
        }
    }))({
        reflectSymbol: function () {
            return "RefreshFormulas";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementComponentFormula";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementComponentAmountSource";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditElementComponentTotalQuantity";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ResetElementAmounts";
        }
    }))({
        reflectSymbol: function () {
            return "RefreshFormulas";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementComponentFormula";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementComponentAmountSource";
        }
    }))({
        reflectSymbol: function () {
            return "DuplicateComponentsWithElementSpecs";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditElementComponentConsumptionRate";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ResetElementAmounts";
        }
    }))({
        reflectSymbol: function () {
            return "RefreshFormulas";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementComponentTotalQuantity";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementComponentFormula";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementComponentAmountSource";
        }
    }))({
        reflectSymbol: function () {
            return "DuplicateComponentsWithElementSpecs";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DeleteElements";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ResetElementAmounts";
        }
    }))({
        reflectSymbol: function () {
            return "RefreshFormulas";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementComponentTotalQuantity";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementComponentFormula";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementComponentConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementComponentAmountSource";
        }
    }))({
        reflectSymbol: function () {
            return "DuplicateComponentsWithElementSpecs";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DeleteElementSpecs";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ResetElementAmounts";
        }
    }))({
        reflectSymbol: function () {
            return "RefreshFormulas";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementComponentTotalQuantity";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementComponentFormula";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementComponentConsumptionRate";
        }
    }))({
        reflectSymbol: function () {
            return "EditElementComponentAmountSource";
        }
    }))({
        reflectSymbol: function () {
            return "DuplicateComponentsWithElementSpecs";
        }
    }))({
        reflectSymbol: function () {
            return "DeleteElements";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DeleteElementSpecs";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "deleteComponents";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "deleteComponents";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DeleteElements";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "deleteComponents";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementIds";
        }
    })(TofuJSON.readArray(tofuJSONElementId))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "deleteComponents";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementIds";
        }
    })(TofuJSON.readArray(tofuJSONElementId))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DuplicateComponentsWithElementSpecs";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "allowDuplicateComponents";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "allowDuplicateComponents";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditElementComponentAmountSource";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amountSource";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amountSource";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditElementComponentConsumptionRate";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditElementComponentFormula";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newFormula";
        }
    })(QuantityFormula_Types.tofuJSONInternalFormula)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newFormula";
        }
    })(QuantityFormula_Types.tofuJSONInternalFormula)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditElementComponentTotalQuantity";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newTotalQuantity";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newTotalQuantity";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "RefreshFormulas";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ResetElementAmounts";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementIds";
        }
    })(TofuJSON.readArray(tofuJSONElementId))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementIds";
        }
    })(TofuJSON.readArray(tofuJSONElementId))(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantNil))))))))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DeleteElementSpecs";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "deleteComponents";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "deleteComponents";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DeleteElements";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "deleteComponents";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementIds";
        }
    })(TofuJSON.readArray(tofuJSONElementId))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "deleteComponents";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementIds";
        }
    })(TofuJSON.readArray(tofuJSONElementId))(TofuJSON.nilWriteForeignFields)()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DuplicateComponentsWithElementSpecs";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "allowDuplicateComponents";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "allowDuplicateComponents";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecIds";
        }
    })(TofuJSON.readArray(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetProjectId";
        }
    })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "targetSubprojectId";
        }
    })(Types_Project.tofuJSONSubprojectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditElementComponentAmountSource";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "amountSource";
        }
    })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "amountSource";
        }
    })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditElementComponentConsumptionRate";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newConsumptionRate";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditElementComponentFormula";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newFormula";
        }
    })(QuantityFormula_Types.tofuJSONInternalFormula)(TofuJSON.readFieldsNil)()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newFormula";
        }
    })(QuantityFormula_Types.tofuJSONInternalFormula)(TofuJSON.nilWriteForeignFields)()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditElementComponentTotalQuantity";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "newTotalQuantity";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementId";
        }
    })(tofuJSONElementId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementSpecId";
        }
    })(Types_Newtypes.tofuJSONElementSpecId)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "newTotalQuantity";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "RefreshFormulas";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ResetElementAmounts";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "elementIds";
        }
    })(TofuJSON.readArray(tofuJSONElementId))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "elementIds";
        }
    })(TofuJSON.readArray(tofuJSONElementId))(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.nilWriteForeignVariant))))))))))
};
var eqGroupFlag = {
    eq: function (x) {
        return function (y) {
            if (x instanceof GroupFlagNone && y instanceof GroupFlagNone) {
                return true;
            };
            if (x instanceof GroupFlagFlag && y instanceof GroupFlagFlag) {
                return true;
            };
            if (x instanceof GroupFlagCheck && y instanceof GroupFlagCheck) {
                return true;
            };
            if (x instanceof GroupFlagBoth && y instanceof GroupFlagBoth) {
                return true;
            };
            return false;
        };
    }
};
var ordGroupFlag = {
    compare: function (x) {
        return function (y) {
            if (x instanceof GroupFlagNone && y instanceof GroupFlagNone) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof GroupFlagNone) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof GroupFlagNone) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof GroupFlagFlag && y instanceof GroupFlagFlag) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof GroupFlagFlag) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof GroupFlagFlag) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof GroupFlagCheck && y instanceof GroupFlagCheck) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof GroupFlagCheck) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof GroupFlagCheck) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof GroupFlagBoth && y instanceof GroupFlagBoth) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at Types.Element (line 213, column 1 - line 213, column 46): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqGroupFlag;
    }
};
var eqElementMeasureId = Data_Eq.eqInt;
var eqElementLocationId = Data_Eq.eqInt;
var eqElementId = Data_Eq.eqInt;
var eqElementMeasure = Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "value";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "elementMeasureId";
    }
})(eqElementMeasureId))()({
    reflectSymbol: function () {
        return "elementId";
    }
})(Data_Maybe.eqMaybe(eqElementId)))()({
    reflectSymbol: function () {
        return "description";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "code";
    }
})(Data_Eq.eqString));
var eqWasmElementLocation = Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "user";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "time";
    }
})(Data_Nullable.eqNullable(Types_JSDateString.eqJSDateString)))()({
    reflectSymbol: function () {
        return "sourceApplication";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "locationCode";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "group";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "elementLocationId";
    }
})(eqElementLocationId))()({
    reflectSymbol: function () {
        return "elementId";
    }
})(Data_Nullable.eqNullable(eqElementId)))()({
    reflectSymbol: function () {
        return "description";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "amountSourceCode";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "amount";
    }
})(Data_Eq.eqNumber));
var eqWasmElementSpec = Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "unitConsumptionRate";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "formula";
    }
})(QuantityFormula_Types.eqInternalFormula))()({
    reflectSymbol: function () {
        return "elementSpecId";
    }
})(Types_Newtypes.eqElementSpecId))()({
    reflectSymbol: function () {
        return "elementId";
    }
})(Data_Nullable.eqNullable(eqElementId)))()({
    reflectSymbol: function () {
        return "componentId";
    }
})(Data_Nullable.eqNullable(Types_Component.eqComponentId)))()({
    reflectSymbol: function () {
        return "amountSource";
    }
})(Data_Eq.eqInt));
var eqElementCode = Data_Eq.eqString;
var eqWasmElement = Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "unit";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "subprojectId";
    }
})(Data_Nullable.eqNullable(Types_Project.eqSubprojectId)))()({
    reflectSymbol: function () {
        return "rtDescription";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "rtCode";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "memo";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "groupFlag";
    }
})(Data_Eq.eqInt))()({
    reflectSymbol: function () {
        return "elementId";
    }
})(eqElementId))()({
    reflectSymbol: function () {
        return "description";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "code";
    }
})(eqElementCode));
module.exports = {
    ElementId: ElementId,
    ElementMeasureId: ElementMeasureId,
    ElementCode: ElementCode,
    ElementLocationId: ElementLocationId,
    GroupFlagNone: GroupFlagNone,
    GroupFlagFlag: GroupFlagFlag,
    GroupFlagCheck: GroupFlagCheck,
    GroupFlagBoth: GroupFlagBoth,
    groupFlagFromInt: groupFlagFromInt,
    groupFlagToInt: groupFlagToInt,
    WasmEstimationElement: WasmEstimationElement,
    WasmElementSpec: WasmElementSpec,
    WasmElementLocation: WasmElementLocation,
    ElementMeasure: ElementMeasure,
    DeleteElementSpecs: DeleteElementSpecs,
    DeleteElements: DeleteElements,
    EditElementComponentConsumptionRate: EditElementComponentConsumptionRate,
    EditElementComponentTotalQuantity: EditElementComponentTotalQuantity,
    DuplicateComponentsWithElementSpecs: DuplicateComponentsWithElementSpecs,
    EditElementComponentFormula: EditElementComponentFormula,
    EditElementComponentAmountSource: EditElementComponentAmountSource,
    ResetElementAmounts: ResetElementAmounts,
    RefreshFormulas: RefreshFormulas,
    EditElementMeasureCode: EditElementMeasureCode,
    EditElementMeasureDescription: EditElementMeasureDescription,
    EditElementMeasureValue: EditElementMeasureValue,
    AddElementMeasure: AddElementMeasure,
    newtypeElementId: newtypeElementId,
    eqElementId: eqElementId,
    ordElementId: ordElementId,
    showElementId: showElementId,
    tofuJSONElementId: tofuJSONElementId,
    newtypeElementLocationId: newtypeElementLocationId,
    eqElementLocationId: eqElementLocationId,
    ordElementLocationId: ordElementLocationId,
    showElementLocationId: showElementLocationId,
    tofuJSONElementLocationId: tofuJSONElementLocationId,
    newtypeElementMeasureId: newtypeElementMeasureId,
    eqElementMeasureId: eqElementMeasureId,
    ordElementMeasureId: ordElementMeasureId,
    showElementMeasureId: showElementMeasureId,
    tofuJSONElementMeasureId: tofuJSONElementMeasureId,
    newtypeElementCode: newtypeElementCode,
    eqElementCode: eqElementCode,
    ordElementCode: ordElementCode,
    showElementCode: showElementCode,
    tofuJSONElementCode: tofuJSONElementCode,
    eqGroupFlag: eqGroupFlag,
    ordGroupFlag: ordGroupFlag,
    genericGroupFlag: genericGroupFlag,
    showGroupFlag: showGroupFlag,
    tofuJSONGroupFlag: tofuJSONGroupFlag,
    newtypeWasmElement: newtypeWasmElement,
    eqWasmElement: eqWasmElement,
    ordWasmElement: ordWasmElement,
    showWasmElement: showWasmElement,
    tofuJSONWasmElement: tofuJSONWasmElement,
    newtypeWasmElementSpec: newtypeWasmElementSpec,
    eqWasmElementSpec: eqWasmElementSpec,
    ordWasmElementSpec: ordWasmElementSpec,
    showWasmElementSpec: showWasmElementSpec,
    tofuJSONWasmElementSpec: tofuJSONWasmElementSpec,
    newtypeElementMeasure: newtypeElementMeasure,
    eqElementMeasure: eqElementMeasure,
    showElementMeasure: showElementMeasure,
    tofuJSONElementMeasure: tofuJSONElementMeasure,
    newtypeWasmElementLocation: newtypeWasmElementLocation,
    eqWasmElementLocation: eqWasmElementLocation,
    ordWasmElementLocation: ordWasmElementLocation,
    showWasmElementLocation: showWasmElementLocation,
    tofuJSONWasmElementLocation: tofuJSONWasmElementLocation,
    genericElementAction: genericElementAction,
    tofuJSONElementAction: tofuJSONElementAction,
    genericElementMeasureAction: genericElementMeasureAction,
    tofuJSONElementMeasureAction: tofuJSONElementMeasureAction
};
