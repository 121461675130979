// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var AddComponentTable = require("../AddComponentTable/index.js");
var Box = require("../Box/index.js");
var Client_WASM = require("../Client.WASM/index.js");
var ClientLogger = require("../ClientLogger/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_String_Pattern = require("../Data.String.Pattern/index.js");
var Effect = require("../Effect/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Class_Console = require("../Effect.Class.Console/index.js");
var Effect_Exception = require("../Effect.Exception/index.js");
var Grid = require("../Grid/index.js");
var LengthValidator = require("../LengthValidator/index.js");
var Locale = require("../Locale/index.js");
var NumberValidator = require("../NumberValidator/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_DOM_Internal = require("../React.Basic.DOM.Internal/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var Record = require("../Record/index.js");
var StateHooks = require("../StateHooks/index.js");
var StringUtils = require("../StringUtils/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoInput_Styles = require("../TacoInput.Styles/index.js");
var TacoLoader = require("../TacoLoader/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoSelect = require("../TacoSelect/index.js");
var TacoTabs = require("../TacoTabs/index.js");
var TacoTabs_Types = require("../TacoTabs.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Alert = require("../Types.Alert/index.js");
var Types_BuildingElementsView = require("../Types.BuildingElementsView/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_Element = require("../Types.Element/index.js");
var Types_Estimation = require("../Types.Estimation/index.js");
var Types_Project = require("../Types.Project/index.js");
var UseForm = require("../UseForm/index.js");
var UseLicenseFeature = require("../UseLicenseFeature/index.js");
var UseRefProjects = require("../UseRefProjects/index.js");
var UseSuggestionsNavigation = require("../UseSuggestionsNavigation/index.js");
var UseUpdateOnChange = require("../UseUpdateOnChange/index.js");
var Utils = require("../Utils/index.js");
var WasmWorker = require("../WasmWorker/index.js");
var WasmWorker_WorkerRequest = require("../WasmWorker.WorkerRequest/index.js");
var renderSubprojectOption = function (v) {
    return {
        value: v.code,
        text: v.code
    };
};
var mkSubprojectInput$prime = function (v) {
    var currentCode = (function () {
        var v1 = function (v2) {
            return "";
        };
        if (v.currentId instanceof Data_Maybe.Just) {
            var $52 = Data_Array.find((function () {
                var $143 = Data_Eq.eq(Types_Project.eqSubprojectId)(v.currentId.value0);
                return function ($144) {
                    return $143((function (v2) {
                        return v2.id;
                    })($144));
                };
            })())(v.subprojects);
            if ($52 instanceof Data_Maybe.Just) {
                return $52.value0.code;
            };
            return v1(true);
        };
        return v1(true);
    })();
    return Box.box("subprojectInput")([ new Box.FlexGrow(1), new Box.Selector(".__TacoSelect", [ new Box.FlexGrow(1) ]) ])([ TacoSelect.component()()({
        value: currentCode,
        disabled: v.disabled,
        onFocus: v.onFocus,
        onBlur: v.onBlur,
        autoFocus: v.autoFocus,
        title: Data_Maybe.fromMaybe("")(v.title),
        label: Data_Maybe.fromMaybe("")(v.label),
        onChange: TacoInput.changeHandler(TacoInput.inputValueString)(function (value) {
            var v1 = function (v2) {
                return function __do() {
                    Effect_Class_Console.error(Effect_Class.monadEffectEffect)("could not find subproject for subproject input")();
                    return ClientLogger.logError(Effect_Class.monadEffectEffect)(Effect_Exception.error("could not find subproject for subproject input"))();
                };
            };
            var $56 = Data_Array.find((function () {
                var $145 = Data_Eq.eq(Data_Eq.eqString)(value);
                return function ($146) {
                    return $145((function (v2) {
                        return v2.code;
                    })($146));
                };
            })())(v.subprojects);
            if ($56 instanceof Data_Maybe.Just) {
                return v.onChange($56.value0.id);
            };
            return v1(true);
        }),
        items: Data_Functor.map(Data_Functor.functorArray)(renderSubprojectOption)(v.subprojects),
        rendering: v.rendering,
        testId: v.testId + "-subproject-input"
    }) ]);
};
var mkSubprojectInput = function (props) {
    return function (tooltipProps) {
        if (tooltipProps instanceof Data_Maybe.Nothing) {
            return mkSubprojectInput$prime(Record.merge()()(props)({
                title: Data_Maybe.Nothing.value
            }));
        };
        if (tooltipProps instanceof Data_Maybe.Just) {
            var tooltipText = (function () {
                if (props.disabled) {
                    return tooltipProps.value0.disabledTooltip;
                };
                return tooltipProps.value0.enabledTooltip;
            })();
            return mkSubprojectInput$prime(Record.merge()()(props)({
                title: new Data_Maybe.Just(tooltipText)
            }));
        };
        throw new Error("Failed pattern match at CostEstimation.AddComponent (line 475, column 3 - line 482, column 77): " + [ tooltipProps.constructor.name ]);
    };
};
var addComponentModal = TofuHooks.mkHookComponent("AddComponentModal")(function (props) {
    return function __do() {
        var dispatch = StateHooks.useDispatch();
        var v = TofuHooks.useState(false)();
        var dispatch$prime = function (action) {
            return function __do() {
                v.value1(function (v1) {
                    return true;
                })();
                return dispatch(action)();
            };
        };
        var clearActionInProgress = v.value1(function (v1) {
            return false;
        });
        var mEd = Data_Functor.map(Data_Maybe.functorMaybe)(Data_Newtype.un()(Types_BuildingElementsView.WasmElementsData))(props.detailsElement);
        var mElement = Data_Functor.map(Data_Maybe.functorMaybe)((function () {
            var $147 = Data_Newtype.un()(Types_Element.WasmEstimationElement);
            return function ($148) {
                return $147((function (v1) {
                    return v1.element;
                })($148));
            };
        })())(mEd);
        var withElementSubprojectId = function (subprojectIdAction) {
            var v1 = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function ($149) {
                return Data_Nullable.toMaybe((function (v2) {
                    return v2.subprojectId;
                })($149));
            })(mElement);
            if (v1 instanceof Data_Maybe.Nothing) {
                return function __do() {
                    dispatch(Actions.AddAlert.create(Types_Alert.AlertError.value)("Suoritetta ei voida lis\xe4t\xe4 rakenteelle, koska alakohdetta ei ole m\xe4\xe4ritelty. " + ("ProjectId: " + (Data_Show.show(Types_Project.showProjectId)(props.projectId) + (", " + ("ElementId: " + Data_Maybe.maybe("")(Data_Show.show(Types_Element.showElementId))(Data_Functor.map(Data_Maybe.functorMaybe)(function (v2) {
                        return v2.elementId;
                    })(mElement))))))))();
                    return ClientLogger.logError(Effect_Class.monadEffectEffect)(Effect_Exception.error("User wanted to perform a component action for an element, but the subprojectId was Nothing for the element used. " + ("ProjectId: " + (Data_Show.show(Types_Project.showProjectId)(props.projectId) + (", " + ("ElementId: " + Data_Maybe.maybe("")(Data_Show.show(Types_Element.showElementId))(Data_Functor.map(Data_Maybe.functorMaybe)(function (v2) {
                        return v2.elementId;
                    })(mElement))))))))();
                };
            };
            if (v1 instanceof Data_Maybe.Just) {
                return subprojectIdAction(v1.value0);
            };
            throw new Error("Failed pattern match at CostEstimation.AddComponent (line 109, column 7 - line 120, column 48): " + [ v1.constructor.name ]);
        };
        var v1 = Client_WASM.useEstimationComponents(props.projectId)();
        var projectComponentCodes = TofuHooks.useMemo([ ReactHooksUtils.utf(v1.value0) ])(function (v2) {
            return Data_Set.fromFoldable(Data_Foldable.foldableArray)(Data_Ord.ordString)(Data_Array.catMaybes(Data_Functor.map(Data_Functor.functorArray)(function ($150) {
                return Data_Nullable.toMaybe((function (v3) {
                    return v3.code;
                })($150));
            })(v1.value0)));
        })();
        var v2 = Client_WASM.useSubprojects(props.projectId)();
        UseRefProjects.useRefProjects(props.projectId)();
        var v3 = Client_WASM.useActiveRefComponents(props.projectId)();
        var activeRefComponentIds = Data_Set.fromFoldable(Data_Foldable.foldableArray)(Types_Component.ordComponentId)(v3.value0);
        var v4 = Client_WASM.useReferenceEstimationComponents(props.projectId)();
        var subprojectsEnabled = UseLicenseFeature.useLicenseFeature(UseLicenseFeature.Subprojects.value)();
        var mFirstSubprojectId = Data_Functor.map(Data_Maybe.functorMaybe)(function (v5) {
            return v5.id;
        })(Data_Array.head(v2.value0));
        var closeModal = props.onShowAddComponentModal(false);
        var activeRefComponents = Data_Array.filter(function (c) {
            return Data_Set.member(Types_Component.ordComponentId)(c.id)(activeRefComponentIds);
        })(v4.value0);
        var v5 = TofuHooks.useState("")();
        var v6 = TofuHooks.useState("")();
        var v7 = TofuHooks.useState(0.0)();
        var v8 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var matchesDescription = function (c) {
            var condition = Data_String_CodeUnits.contains(Data_String_Pattern.Pattern(Data_String_Common.toLower(v6.value0)))(Data_String_Common.toLower(c.description));
            return condition;
        };
        var matchesCode = function (c) {
            var condition = StringUtils.startsWith(Data_String_Pattern.Pattern(Data_String_Common.toLower(v5.value0)))(Data_String_Common.toLower(Utils.fromNullable("")(c.code)));
            return condition;
        };
        var loading = v1.value1 || (v2.value1 || (v4.value1 || v.value0));
        var isRegularComponent = function (c) {
            var v9 = Types_Component.intToComponentType(c.componentType);
            if (v9 instanceof Types_Component.RegularComponent) {
                return true;
            };
            return false;
        };
        var regularRefComponents = Data_Array.filter(isRegularComponent)(activeRefComponents);
        var getMatchingComponents = function (xs) {
            var predicate = function (c) {
                return Data_Foldable.and(Data_Foldable.foldableArray)(Data_HeytingAlgebra.heytingAlgebraBoolean)([ matchesCode(c), matchesDescription(c) ]);
            };
            return Data_Array.filter(predicate)(xs);
        };
        var matchingComponents = getMatchingComponents(regularRefComponents);
        var componentIncludedInProject = function (c) {
            var v9 = Data_Nullable.toMaybe(c.code);
            if (v9 instanceof Data_Maybe.Just) {
                return Data_Set.member(Data_Ord.ordString)(v9.value0)(projectComponentCodes);
            };
            if (v9 instanceof Data_Maybe.Nothing) {
                return false;
            };
            throw new Error("Failed pattern match at CostEstimation.AddComponent (line 184, column 7 - line 186, column 25): " + [ v9.constructor.name ]);
        };
        var activeFilter = v5.value0 !== "" || v6.value0 !== "";
        var v9 = TofuHooks.useState(mFirstSubprojectId)();
        UseUpdateOnChange.useUpdateOnChange(Data_Maybe.eqMaybe(Types_Project.eqSubprojectId))(mFirstSubprojectId)(v9.value1)();
        var matchingComponentIds = Data_String_Common.joinWith(",")(Data_Functor.map(Data_Functor.functorArray)(function (c) {
            return Data_Show.show(Types_Component.showComponentId)(c.id);
        })(matchingComponents));
        var addRefComponentToProject = function (c) {
            var actionE = Control_Bind.bind(Data_Either.bindEither)(Data_Either.note("Subproject must be defined")(v9.value0))(function (subProjectId) {
                return Control_Applicative.pure(Data_Either.applicativeEither)(new Actions.EstimationComponentActionRequest({
                    projectId: props.projectId,
                    params: new Types_Estimation.CopyEstimationComponentsToProject({
                        estimationComponentIds: [ c.id ],
                        targetSubprojectId: subProjectId,
                        targetProjectId: props.projectId,
                        copyOperationType: Types_Estimation.CopyComponentWithResources.value,
                        allowDuplicates: true
                    }),
                    handler: function (res) {
                        var v10 = function (v11) {
                            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Monoid.mempty(Data_Monoid.monoidUnit));
                        };
                        if (res instanceof Data_Either.Right) {
                            var $89 = Data_Array.head(res.value0.componentIds);
                            if ($89 instanceof Data_Maybe.Just) {
                                return props.setNewComponentId(function (v11) {
                                    return new Data_Maybe.Just($89.value0);
                                });
                            };
                            return v10(true);
                        };
                        return v10(true);
                    }
                }));
            });
            if (actionE instanceof Data_Either.Right) {
                return function __do() {
                    dispatch(actionE.value0)();
                    return closeModal();
                };
            };
            if (actionE instanceof Data_Either.Left) {
                return Effect_Class_Console.error(Effect_Class.monadEffectEffect)(actionE.value0);
            };
            throw new Error("Failed pattern match at CostEstimation.AddComponent (line 230, column 7 - line 234, column 26): " + [ actionE.constructor.name ]);
        };
        var addRefComponentToElement = function (element) {
            return function (c) {
                return withElementSubprojectId(function (targetSubprojectId) {
                    return dispatch$prime(new Actions.EstimationElementActionRequest({
                        handler: function (v10) {
                            return clearActionInProgress;
                        },
                        projectId: props.projectId,
                        params: new Types_Estimation.AddReferenceProjectComponentToElement({
                            componentId: c.id,
                            elementId: element.elementId,
                            targetProjectId: props.projectId,
                            targetSubprojectId: targetSubprojectId
                        })
                    }));
                });
            };
        };
        var addRefComponent = (function () {
            if (mElement instanceof Data_Maybe.Nothing) {
                return addRefComponentToProject;
            };
            if (mElement instanceof Data_Maybe.Just) {
                return addRefComponentToElement(mElement.value0);
            };
            throw new Error("Failed pattern match at CostEstimation.AddComponent (line 194, column 23 - line 196, column 55): " + [ mElement.constructor.name ]);
        })();
        var v10 = UseSuggestionsNavigation.useSuggestionsNavigation({
            itemIds: matchingComponentIds,
            items: matchingComponents,
            modalOpen: props.addComponentModalOpen,
            activeFilter: activeFilter,
            onSelect: addRefComponent
        })();
        TofuHooks.useLayoutEffect([ matchingComponentIds, Data_Show.show(Data_Show.showString)(v5.value0), Data_Show.show(Data_Show.showString)(v6.value0), Data_Show.show(Data_Maybe.showMaybe(Data_Show.showString))(v8.value0), Data_Show.show(Data_Show.showNumber)(v7.value0) ])(function __do() {
            v10.setSelectedItemIdx(function (v11) {
                return Data_Maybe.Nothing.value;
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        })();
        TofuHooks.useLayoutEffect([ props.addComponentModalOpen ])(function __do() {
            Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(!props.addComponentModalOpen)(function __do() {
                v10.setSelectedItemIdx(function (v11) {
                    return Data_Maybe.Nothing.value;
                })();
                v5.value1(function (v11) {
                    return "";
                })();
                v6.value1(function (v11) {
                    return "";
                })();
                v9.value1(function (v11) {
                    return mFirstSubprojectId;
                })();
                v7.value1(function (v11) {
                    return 0.0;
                })();
                return v8.value1(function (v11) {
                    return Data_Maybe.Nothing.value;
                })();
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        })();
        var submitAddNewComponentToProject = (function () {
            var actionE = Control_Bind.bind(Data_Either.bindEither)(Data_Either.note("Subproject must be defined")(v9.value0))(function (subProjectId) {
                return Control_Applicative.pure(Data_Either.applicativeEither)(new Actions.EstimationComponentActionRequest({
                    projectId: props.projectId,
                    params: new Types_Estimation.AddEstimationComponent({
                        code: v5.value0,
                        componentType: Types_Component.RegularComponent.value,
                        subProjectId: subProjectId,
                        description: v6.value0,
                        unit: v8.value0,
                        quantity: v7.value0,
                        memo: ""
                    }),
                    handler: function (res) {
                        var v11 = function (v12) {
                            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Monoid.mempty(Data_Monoid.monoidUnit));
                        };
                        if (res instanceof Data_Either.Right) {
                            var $100 = Data_Array.head(res.value0.componentIds);
                            if ($100 instanceof Data_Maybe.Just) {
                                return props.setNewComponentId(function (v12) {
                                    return new Data_Maybe.Just($100.value0);
                                });
                            };
                            return v11(true);
                        };
                        return v11(true);
                    }
                }));
            });
            if (actionE instanceof Data_Either.Right) {
                return function __do() {
                    dispatch(actionE.value0)();
                    return closeModal();
                };
            };
            if (actionE instanceof Data_Either.Left) {
                return Effect_Class_Console.error(Effect_Class.monadEffectEffect)(actionE.value0);
            };
            throw new Error("Failed pattern match at CostEstimation.AddComponent (line 311, column 7 - line 315, column 26): " + [ actionE.constructor.name ]);
        })();
        var submitAddNewComponentToElement = function (element) {
            return withElementSubprojectId(function (subProjectId) {
                return dispatch$prime(new Actions.EstimationElementActionRequest({
                    handler: function (v11) {
                        return function __do() {
                            clearActionInProgress();
                            return WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshEstimationComponentsData({
                                request_params: props.projectId
                            }))();
                        };
                    },
                    projectId: props.projectId,
                    params: new Types_Estimation.AddNewProjectComponentToElement({
                        code: v5.value0,
                        description: v6.value0,
                        subprojectId: subProjectId,
                        projectId: props.projectId,
                        elementId: element.elementId,
                        quantity: v7.value0,
                        unit: v8.value0,
                        memo: ""
                    })
                }));
            });
        };
        var submitAddNewComponent = (function () {
            if (mElement instanceof Data_Maybe.Nothing) {
                return submitAddNewComponentToProject;
            };
            if (mElement instanceof Data_Maybe.Just) {
                return submitAddNewComponentToElement(mElement.value0);
            };
            throw new Error("Failed pattern match at CostEstimation.AddComponent (line 266, column 29 - line 268, column 61): " + [ mElement.constructor.name ]);
        })();
        var v11 = UseForm.useForm(submitAddNewComponent)();
        var projectComponentsTable = AddComponentTable.addComponentTable({
            components: getMatchingComponents(v1.value0),
            addRefComponent: addRefComponent,
            selectedRefComponentIdx: v10.selectedItemIdx
        });
        var newComponentInputs = Grid.grid([ new Box.Gap(TacoConstants.M.value), Box.AlignStart.value, Box.MaxWidth.create(new TacoConstants.CustomSize("800px")) ])([ [ Grid.Flex.create(1)(Data_Monoid.guard(React_Basic.monoidJSX)(subprojectsEnabled)(mkSubprojectInput({
            subprojects: v2.value0,
            label: new Data_Maybe.Just("Alakohde"),
            currentId: v9.value0,
            onChange: function (id) {
                return v9.value1(function (v12) {
                    return new Data_Maybe.Just(id);
                });
            },
            onFocus: Control_Applicative.pure(Effect.applicativeEffect)(Data_Monoid.mempty(Data_Monoid.monoidUnit)),
            onBlur: Control_Applicative.pure(Effect.applicativeEffect)(Data_Monoid.mempty(Data_Monoid.monoidUnit)),
            autoFocus: false,
            disabled: false,
            rendering: TacoInput_Styles.DefaultRendering.value,
            testId: "new-component"
        })(Data_Maybe.Nothing.value))), Grid.Flex.create(2)(TacoInput.stringInput()()(Data_Eq.eqString)({
            value: v5.value0,
            label: "Koodi",
            validator: LengthValidator.lengthValidator(1)(50),
            onValidationChange: v11.updateFieldValidity("code"),
            error: v11.isFieldError("code"),
            onChange: v5.value1,
            testId: "new-component-code"
        })), Grid.Flex.create(4)(TacoInput.stringInput()()(Data_Eq.eqString)({
            value: v6.value0,
            label: "Selite",
            validator: LengthValidator.lengthValidator(0)(250),
            onValidationChange: v11.updateFieldValidity("description"),
            error: v11.isFieldError("description"),
            onChange: v6.value1,
            testId: "new-component-description"
        })), Grid.Flex.create(4)(TacoInput.numberInput()()(Data_Eq.eqNumber)({
            value: v7.value0,
            label: "M\xe4\xe4r\xe4",
            precision: 2,
            validator: NumberValidator.numberValidator,
            onValidationChange: v11.updateFieldValidity("quantity"),
            error: v11.isFieldError("quantity"),
            onChange: v7.value1,
            testId: "new-component-quantity"
        })), Grid.Flex.create(2)(TacoInput.stringInput()()(Data_Eq.eqString)({
            value: Data_Maybe.fromMaybe("")(v8.value0),
            label: "Yksikk\xf6",
            validator: LengthValidator.lengthValidator(0)(10),
            onValidationChange: v11.updateFieldValidity("unit"),
            error: v11.isFieldError("unit"),
            onChange: TacoInput.transformInput(TacoInput.inputValueString)(Data_Maybe.Just.create)(v8.value1),
            testId: "new-component-unit"
        })) ] ]);
        var modalActions = TacoModal.modalActions({
            contents: [ TacoButton.component()()({
                onClick: new Data_Maybe.Just(closeModal),
                text: "Peruuta",
                color: TacoConstants.SecondaryRed.value,
                testId: "add-component-cancel-button"
            }), TacoButton.component()()({
                submit: true,
                text: "Tallenna",
                testId: "add-component-submit-button"
            }) ]
        });
        var matchingComponentsByIncluded = Data_Array.partition(componentIncludedInProject)(matchingComponents);
        var notIncludedRefComponentsTable = AddComponentTable.addComponentTable({
            components: matchingComponentsByIncluded.no,
            addRefComponent: addRefComponent,
            selectedRefComponentIdx: v10.selectedItemIdx
        });
        var modalBody = TacoModal.modalBody({
            contents: [ TacoText.component()()({
                text: "Suoritteen tiedot",
                weight: TacoText_Types.Bold.value
            }), newComponentInputs, TacoTabs.component({
                orientation: TacoTabs_Types.Horizontal.value,
                hideContents: false,
                tabs: [ new TacoTabs_Types.NormalTab({
                    quantity: Data_Maybe.Nothing.value,
                    title: "Hankkeen suoritteet",
                    id: "projectComponents",
                    color: TacoConstants.componentColors.border,
                    children: [ projectComponentsTable ]
                }), new TacoTabs_Types.NormalTab({
                    quantity: Data_Maybe.Nothing.value,
                    title: "Viitehankkeiden suoritteet",
                    id: "referenceComponents",
                    color: TacoConstants.componentColors.border,
                    children: [ notIncludedRefComponentsTable ]
                }) ]
            }) ]
        });
        var modal = TacoModal.component()()({
            contents: [ React_Basic_DOM_Generated.form()({
                onSubmit: v11.onSubmit,
                ref: v11.formRef,
                style: React_Basic_DOM_Internal.css({
                    height: React_Basic_Emotion.str("100%")
                }),
                children: [ TacoLoader.progressBar({
                    color: TacoConstants.PrimaryBlue.value,
                    isLoading: loading,
                    testId: "add-component"
                }), modalBody, modalActions ]
            }) ],
            onClose: closeModal,
            isActive: props.addComponentModalOpen,
            headingTextJsx: (function () {
                if (mElement instanceof Data_Maybe.Nothing) {
                    return [ React_Basic_DOM.text("Lis\xe4\xe4 suorite hankkeelle") ];
                };
                if (mElement instanceof Data_Maybe.Just) {
                    return [ React_Basic_DOM.text("Lis\xe4\xe4 suorite rakenteelle "), React_Basic_DOM_Generated.var_([ React_Basic_DOM.text(mElement.value0.rtCode + (" " + mElement.value0.rtDescription)) ]) ];
                };
                throw new Error("Failed pattern match at CostEstimation.AddComponent (line 449, column 25 - line 451, column 136): " + [ mElement.constructor.name ]);
            })(),
            containerStyles: [ new Box.Width(new TacoConstants.CustomSize("90vw")), new Box.Height(new TacoConstants.CustomSize("90vh")) ]
        });
        return React_Basic_DOM_Generated.span_([ modal ]);
    };
});
var component = TofuHooks.mkHookComponent("AddComponent")(function (props) {
    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_DOM_Generated.span_([ TacoButton.component()()({
        onClick: new Data_Maybe.Just(props.onShowAddComponentModal(true)),
        text: Locale.lookup_("new_component"),
        icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconAddPlusV1.value),
        buttonStyle: TacoButton_Types.Filled.value,
        size: TacoConstants.L.value,
        testId: "add-component-button"
    }), (function () {
        if (props.addComponentModalOpen) {
            return addComponentModal(props);
        };
        return React_Basic.empty;
    })() ]));
});
module.exports = {
    component: component,
    addComponentModal: addComponentModal,
    mkSubprojectInput: mkSubprojectInput,
    "mkSubprojectInput'": mkSubprojectInput$prime,
    renderSubprojectOption: renderSubprojectOption
};
