// Generated by purs version 0.14.5
"use strict";
var Control_Category = require("../Control.Category/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var TacoGroupToggle = require("../TacoGroupToggle/index.js");
var Types_Invoice = require("../Types.Invoice/index.js");
var ByInvoices = (function () {
    function ByInvoices() {

    };
    ByInvoices.value = new ByInvoices();
    return ByInvoices;
})();
var BySalary = (function () {
    function BySalary() {

    };
    BySalary.value = new BySalary();
    return BySalary;
})();
var mkInvoiceTypeFilters = function (v) {
    return TacoGroupToggle.component({
        groups: [ ByInvoices.value, BySalary.value ],
        testActive: function (v1) {
            if (v1 instanceof ByInvoices) {
                return v.state.showInvoicesItems;
            };
            if (v1 instanceof BySalary) {
                return v.state.showSalaryItems;
            };
            throw new Error("Failed pattern match at InvoiceTypeFilter (line 29, column 17 - line 31, column 40): " + [ v1.constructor.name ]);
        },
        getName: function (v1) {
            if (v1 instanceof ByInvoices) {
                return "Laskut";
            };
            if (v1 instanceof BySalary) {
                return "Palkat";
            };
            throw new Error("Failed pattern match at InvoiceTypeFilter (line 32, column 14 - line 34, column 27): " + [ v1.constructor.name ]);
        },
        handleToggleGroup: function (x) {
            return function (v1) {
                if (x instanceof ByInvoices) {
                    return v.setState.setShowInvoicesItems(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean));
                };
                if (x instanceof BySalary) {
                    return v.setState.setShowSalaryItems(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean));
                };
                throw new Error("Failed pattern match at InvoiceTypeFilter (line 35, column 32 - line 39, column 40): " + [ x.constructor.name ]);
            };
        }
    });
};
var filterInvoiceItemsByType = function (state) {
    return function (invoiceLines) {
        var filterSalaryItems = (function () {
            if (state.showSalaryItems) {
                return Control_Category.identity(Control_Category.categoryFn);
            };
            return Data_Array.filter(function (x) {
                return Data_Eq.notEq(Types_Invoice.eqInvoiceType)(x.type)(Types_Invoice.Salary.value);
            });
        })();
        var filterInvoiceItems = (function () {
            if (state.showInvoicesItems) {
                return Control_Category.identity(Control_Category.categoryFn);
            };
            return Data_Array.filter(function (x) {
                return Data_Eq.notEq(Types_Invoice.eqInvoiceType)(x.type)(Types_Invoice.Invoice.value);
            });
        })();
        return filterInvoiceItems(filterSalaryItems(invoiceLines));
    };
};
module.exports = {
    mkInvoiceTypeFilters: mkInvoiceTypeFilters,
    filterInvoiceItemsByType: filterInvoiceItemsByType
};
