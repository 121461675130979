import "./milkisDatadogFetchInstrumentationWorkaround.ts";
import "babel-polyfill";
import TextEncodingPolyfill from "text-encoding";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { render } from "react-dom";
// @ts-ignore: Missing typings
import getAppComponent from "./containers/App/App";
import packageJson from "../../package.json";
// @ts-ignore: Missing typings
import { initApp } from "../../output/FrontEnd";
// @ts-ignore: Missing typings
import { configureAmplify } from "../../output/Auth";
// In order to have the linter running early in CI we need to tell the compiler to ignore this import.
// The import is not available until the worker is built, which happens take quite a long time.
// @ts-ignore
import initWasm from "../../wasm/pkg";
// @ts-ignore
import * as wasmModule from "../../wasm/pkg";

// eslint-disable-next-line no-prototype-builtins
if (!window.hasOwnProperty("TextEncoder")) {
  window.TextEncoder = TextEncodingPolyfill.TextEncoder;
}
// eslint-disable-next-line no-prototype-builtins
if (!window.hasOwnProperty("TextDecoder")) {
  window.TextDecoder = TextEncodingPolyfill.TextDecoder;
}

import "./styles/styles.css";

import { ensureClientConfig } from "./config";
import { origFetch } from "./milkisDatadogFetchInstrumentationWorkaround.ts";

(async () => {
  try {
    window.fetch = origFetch;
    await ensureClientConfig();
    configureAmplify();

    console.log("client-version: ", packageJson.version);

    await initWasm();
    window.wasmModule = wasmModule;
    wasmModule.init_wasm_console_error();

    console.log("loaded wasm module.", wasmModule);

    const wasmWorker = new Worker(new URL("./wasm-worker.js", import.meta.url));
    window.wasmWorker = wasmWorker;
    window.wasmSizeWarning = 100000;
    window.wasmWorkerDebug = (key, deep, show_computed) => {
      sessionStorage.setItem("wasmWorkerDebugging", "true");
      wasmWorker.postMessage({
        type: "Debug",
        value: { key, deep, show_computed },
      });
    };

    window.wasmWorkerJson = (key, deep, show_computed) => {
      sessionStorage.setItem("wasmWorkerDebugging", "true");
      wasmWorker.postMessage({
        type: "Debug",
        value: { key, deep, show_computed, json: true },
      });
    };

    window.wasmWorkerBenchmark = (num_passes) => {
      wasmWorker.postMessage({
        type: "Benchmark",
        value: { num_passes },
      });
    };

    // Wait for wasmWorker to initialize
    await new Promise<void>((resolve) => {
      wasmWorker.onmessage = () => {
        wasmWorker.onmessage = null;
        window.wasmWorkerInitialized = true;

        resolve();
      };
    });

    const App = getAppComponent();

    initApp(App)();
  } catch (e) {
    console.error(e);
  }
})();
