import { GridApi, SelectionChangedEvent } from "ag-grid-community";
import { useCallback, useState } from "react";
import { useGridEventListener } from "./useGridEventListener";

export function useSelectedRows<TData>(
  api: GridApi<TData> | undefined | null
): TData[] {
  const [selectedRows, setSelectedRows] = useState<TData[] | undefined>();
  const listener = useCallback(
    (event: SelectionChangedEvent<TData>) => {
      setSelectedRows(event.api?.getSelectedRows() || []);
    },
    [setSelectedRows]
  );

  useGridEventListener<SelectionChangedEvent<TData>>(
    api,
    "selectionChanged",
    listener
  );

  // If listener not fired yet, return selected rows from api
  if (!selectedRows) {
    return api?.getSelectedRows() ?? [];
  }

  return selectedRows;
}
