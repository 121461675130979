// Generated by purs version 0.14.5
"use strict";
var ButtonWithConfirmation = require("../ButtonWithConfirmation/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var example1Src = "\x0a  ButtonWithConfirmation.component\x0a    { buttonText: \"Dangerous action\"\x0a    , confirmHeadingText: \"Are you sure you want to do this?\"\x0a    , confirmBody: R.text \"If you do this, bad things might happen\"\x0a    , confirmButtonText: \"Do it\"\x0a    }\x0a";
var example1 = ButtonWithConfirmation.component()()({
    buttonText: "Dangerous action",
    confirmHeadingText: "Are you sure you want to do this?",
    confirmBody: React_Basic_DOM.text("If you do this, bad things might happen"),
    confirmButtonText: "Do it"
});
var sections = [ {
    title: "Simple usage",
    view: [ example1 ],
    code: example1Src
} ];
var demoView = {
    sections: sections,
    title: "ButtonWithConfirmation",
    description: "Button that opens a confirmatino modal"
};
module.exports = {
    demoView: demoView
};
