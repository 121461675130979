import { ColDef, ValueFormatterParams } from "ag-grid-community";
import { DateTime } from "luxon";

export const isoDateWithTimeColDef: ColDef = {
  filter: "agDateColumnFilter",
  filterParams: {
    buttons: ["apply", "reset"],
    comparator: isoDateWithTimeComparator,
  },
  valueFormatter: isoDateWithTimeFormatter,
};

function isoDateWithTimeComparator(
  filterLocalDateAtMidnight: Date,
  cellValue: string
) {
  const cellDate = DateTime.fromISO(cellValue);
  const filterDate = DateTime.fromJSDate(filterLocalDateAtMidnight);
  if (cellDate > filterDate) {
    return 1;
  }
  if (cellDate.hasSame(filterDate, "day")) {
    return 0;
  }
  return -1;
}

export function isoDateWithTimeFormatter(data: ValueFormatterParams) {
  return data.value
    ? DateTime.fromISO(data.value).toFormat("dd'.'MM'.'yyyy" + " HH:mm")
    : "";
}
