// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Effect = require("../Effect/index.js");
var Portal = require("../Portal/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var Record = require("../Record/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoModal_Styles = require("../TacoModal.Styles/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var UseCallbackRef = require("../UseCallbackRef/index.js");
var UseDismissOverlay = require("../UseDismissOverlay/index.js");
var modalBody = TofuHooks.mkHookComponent("ModalBody")(function (v) {
    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        className: "content-container",
        css: TacoModal_Styles.contentContainerStyles,
        children: v.contents
    }));
});
var modalActions = TofuHooks.mkHookComponent("ModalActions")(function (v) {
    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        className: "actions",
        css: TacoModal_Styles.actionsContainerStyles,
        children: v.contents
    }));
});
var defaultProps = {
    onClose: Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)),
    isActive: false,
    heading: "",
    headingTextJsx: [  ],
    contents: [  ],
    containerStyles: [  ]
};
var component$prime = TofuHooks.mkHookComponent("TacoModal")(function (props) {
    return function __do() {
        var v = UseDismissOverlay.useDismissOverlay(props.isActive)(props.onClose)(ReactHooksUtils.utf("TacoModal"))();
        var overlay = function (children) {
            return Data_Monoid.guard(React_Basic.monoidJSX)(props.isActive)(React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                className: "overlay",
                css: TacoModal_Styles.overlayStyles({
                    isActive: props.isActive
                }),
                children: children
            }));
        };
        var headingText = (function () {
            if (props.headingTextJsx.length === 0) {
                return [ React_Basic_DOM.text(props.heading) ];
            };
            return props.headingTextJsx;
        })();
        var headingJsx = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "heading",
            css: TacoModal_Styles.headingStyles,
            children: [ React_Basic_DOM_Generated.h3_(headingText), TacoButton.component()()({
                onClick: new Data_Maybe.Just(props.onClose),
                icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconCloseCrossV1.value),
                testId: "taco-modal-close-button"
            }) ]
        });
        return ReactBasicUtils["div'"]("__TacoModal")([ Portal.component({
            contents: overlay([ React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                className: "container",
                css: TacoModal_Styles.containerStyles(props.containerStyles),
                ref: UseCallbackRef.toNodeRef(v.callbackRef),
                children: [ headingJsx, Box.box("ModalContentsWrapper")([ new Box.Overflow(Box.Auto.value, Box.Auto.value), new Box.FlexGrow(1), Box.FlexColumn.value, new Box.Selector("& > *", [ new Box.FlexGrow(1), Box.FlexColumn.value ]), Box.Style.create(React_Basic_Emotion.css()({
                    flexWrap: React_Basic_Emotion.str("nowrap")
                })) ])(props.contents) ]
            }) ]),
            id: "TacoModal"
        }) ]);
    };
});
var component = function (dictUnion) {
    return function (dictNub) {
        return function (sub) {
            var props = Record.merge()()(sub)(defaultProps);
            return component$prime(props);
        };
    };
};
module.exports = {
    defaultProps: defaultProps,
    component: component,
    "component'": component$prime,
    modalBody: modalBody,
    modalActions: modalActions
};
