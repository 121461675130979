import {
  Button,
  ConfirmationModal,
  IconCopyToProjectV1,
  Input,
  Modal,
} from "@tocoman/ui";
import { useTranslation } from "react-i18next";
import {
  useDeleteIntegrationUserMutation,
  useIntegrationUserMutation,
  useIntegrationUserQuery,
} from "./useIntegrationQueries";

type IntegrationUserDialogProps = {
  open: boolean;
  onClose: () => void;
};

export function IntegrationUserDialog({
  open,
  onClose,
}: IntegrationUserDialogProps) {
  const { t } = useTranslation("settings", { keyPrefix: "integration" });

  const {
    mutate: createIntegrationUser,
    isLoading: integrationUserCreationLoading,
  } = useIntegrationUserMutation();
  const { data, isLoading: integrationDataLoading } = useIntegrationUserQuery();
  const {
    mutate: deleteIntegrationUser,
    isLoading: integrationDeleteLoading,
  } = useDeleteIntegrationUserMutation();

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <Modal
      isOpen={open}
      closeModal={() => onClose()}
      width={350}
      loading={
        integrationDataLoading ||
        integrationUserCreationLoading ||
        integrationDeleteLoading
      }
    >
      <div
        className={
          "my-3 mx-3 h-[128px] flex flex-col justify-around align-bottom"
        }
      >
        {!integrationDataLoading && data.clientId && data.secret && (
          <div className={"flex flex-col justify-around align-bottom"}>
            <div className={"flex flex-row w-full"}>
              <Input
                label={t`clientId`}
                defaultValue={data?.clientId}
                disabled={true}
                className={"w-full"}
              />
              <Button
                icon={IconCopyToProjectV1}
                color={"normal"}
                onClick={() => copyToClipboard(data.clientId)}
                className={"mt-6 ml-2"}
              ></Button>
            </div>
            <div className={"flex flex-row w-full"}>
              <Input
                label={t`clientSecret`}
                defaultValue={data?.secret}
                disabled={true}
                className={"w-full"}
              />
              <Button
                icon={IconCopyToProjectV1}
                color={"normal"}
                onClick={() => copyToClipboard(data.secret)}
                className={"mt-6 ml-2"}
              ></Button>
            </div>

            <div className={"mt-[24px]"}>
              <ConfirmationModal
                onConfirm={() => deleteIntegrationUser()}
                title={t("deleteIntegrationTitle")}
                confirmText={t("delete")}
                cancelText={t("cancel")}
                buttonText={t("deleteIntegration")}
                buttonProps={{
                  color: "danger",
                }}
              />
            </div>
          </div>
        )}
        {!integrationDataLoading && !data.clientId && (
          <Button
            onClick={() => createIntegrationUser()}
            loading={integrationUserCreationLoading}
            disabled={integrationUserCreationLoading}
          >
            {t("createIntegration")}
          </Button>
        )}
      </div>
    </Modal>
  );
}
