// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var ExpensesTable = require("../ExpensesTable/index.js");
var FetchHooks = require("../FetchHooks/index.js");
var IncomeTable = require("../IncomeTable/index.js");
var IntegrationContainer_Types = require("../IntegrationContainer.Types/index.js");
var InvoiceImgLink = require("../InvoiceImgLink/index.js");
var Locale = require("../Locale/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var StateHooks = require("../StateHooks/index.js");
var StringUtils = require("../StringUtils/index.js");
var TacoAutocomplete = require("../TacoAutocomplete/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoDropdown = require("../TacoDropdown/index.js");
var TacoDropdown_Types = require("../TacoDropdown.Types/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoLoader = require("../TacoLoader/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Alert = require("../Types.Alert/index.js");
var Types_Integration = require("../Types.Integration/index.js");
var Types_Invoice = require("../Types.Invoice/index.js");
var Types_Project = require("../Types.Project/index.js");
var UploadComponents = require("../UploadComponents/index.js");
var Utils_ClassNames = require("../Utils.ClassNames/index.js");
var WindowUtils = require("../WindowUtils/index.js");
var filterSalaries = function (showSalaries) {
    return function (salaries) {
        if (showSalaries) {
            return salaries;
        };
        return Data_Array.filter(function (i) {
            return !i.isSalary;
        })(salaries);
    };
};
var filterInvoicesByString = function (filterString) {
    return Data_Array.filter(function (i) {
        return StringUtils.matchStringCaseIgnored(filterString)([ Data_Maybe.fromMaybe("")(i.invoiceNumber), Data_Maybe.fromMaybe("")(i.voucherNumber), i.externalId, i.supplier, Data_Maybe.fromMaybe("")(i.supplierBusinessId), i.description ]);
    });
};
var filterInvoices = function (showinvoices) {
    return function (invoices) {
        if (showinvoices) {
            return invoices;
        };
        return Data_Array.filter(function (i) {
            return i.isSalary;
        })(invoices);
    };
};
var filterExportedInvoiceLines = function (showExported) {
    return function (lines) {
        if (showExported) {
            return lines;
        };
        return Data_Array.filter(function (line) {
            return Data_Maybe.isNothing(line.exportDate);
        })(lines);
    };
};
var filterAllocatedSalesInvoiceLines = function (showAllocated) {
    return function (lines) {
        if (showAllocated) {
            return lines;
        };
        return Data_Array.filter(function (line) {
            return Data_Maybe.isNothing(line.projectId);
        })(lines);
    };
};
var filterAllocatedInvoiceLines = function (showAllocated) {
    return function (lines) {
        if (showAllocated) {
            return lines;
        };
        return Data_Array.filter(function (line) {
            return Data_Maybe.isNothing(line.projectCode);
        })(lines);
    };
};
var component = TofuHooks.mkHookComponent("IntegrationsContainer")(function (v) {
    return function __do() {
        var v1 = FetchHooks.useImportInvoices();
        var v2 = FetchHooks.useImportSalesInvoices();
        var v3 = FetchHooks.useProjectList();
        var v4 = TofuHooks.useState([  ])();
        var v5 = TofuHooks.useState([  ])();
        var v6 = TofuHooks.useState([  ])();
        var v7 = TofuHooks.useState(IntegrationContainer_Types.ExpensesView.value)();
        var v8 = TofuHooks.useState(false)();
        var v9 = TofuHooks.useState(true)();
        var v10 = TofuHooks.useState(true)();
        var v11 = TofuHooks.useState(true)();
        var v12 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var appDispatch = StateHooks.useDispatch();
        var v13 = TofuHooks.useState("")();
        var updateView = function (view$prime) {
            return function __do() {
                v7.value1(function (v14) {
                    return view$prime;
                })();
                v4.value1(function (v14) {
                    return [  ];
                })();
                return v12.value1(function (v14) {
                    return Data_Maybe.Nothing.value;
                })();
            };
        };
        var updateSalesInvoiceLinesOrder = function (ids) {
            return v6.value1(function (v14) {
                return ids;
            });
        };
        var updatePurchaseInvoiceLinesOrder = function (ids) {
            return v5.value1(function (v14) {
                return ids;
            });
        };
        var toggleSalaries = v11.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean));
        var toggleInvoices = v10.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean));
        var toggleExported = v8.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean));
        var toggleCheckbox$prime = function (id) {
            var isSelected = Data_Array.elem(Data_Eq.eqInt)(id)(v4.value0);
            if (isSelected) {
                return v4.value1(function (selected$prime) {
                    return Data_Array.filter(function (i) {
                        return i !== id;
                    })(selected$prime);
                });
            };
            return function __do() {
                v4.value1(function (selected$prime) {
                    return Data_Array.union(Data_Eq.eqInt)([ id ])(selected$prime);
                })();
                return v12.value1(function (v14) {
                    return new Data_Maybe.Just(id);
                })();
            };
        };
        var toggleAreaOfCheckboxes = function (thisId) {
            var ids = (function () {
                if (v12.value0 instanceof Data_Maybe.Just) {
                    var linesOrder = (function () {
                        if (v7.value0 instanceof IntegrationContainer_Types.ExpensesView) {
                            return v5.value0;
                        };
                        if (v7.value0 instanceof IntegrationContainer_Types.IncomeView) {
                            return v6.value0;
                        };
                        throw new Error("Failed pattern match at IntegrationContainer (line 92, column 28 - line 94, column 53): " + [ v7.value0.constructor.name ]);
                    })();
                    var idToIndex = function (id) {
                        return Data_Array.findIndex(function (lineId) {
                            return id === lineId;
                        })(linesOrder);
                    };
                    var areaInIds = function (fstIdx) {
                        return function (sndIdx) {
                            return Data_Array.catMaybes(Data_Functor.map(Data_Functor.functorArray)(Data_Array.index(linesOrder))(Data_Array.range(fstIdx)(sndIdx)));
                        };
                    };
                    var v14 = idToIndex(v12.value0.value0);
                    var v15 = idToIndex(thisId);
                    if (v15 instanceof Data_Maybe.Just && v14 instanceof Data_Maybe.Just) {
                        return areaInIds(v15.value0)(v14.value0);
                    };
                    return v4.value0;
                };
                if (v12.value0 instanceof Data_Maybe.Nothing) {
                    return v4.value0;
                };
                throw new Error("Failed pattern match at IntegrationContainer (line 89, column 15 - line 102, column 30): " + [ v12.value0.constructor.name ]);
            })();
            return v4.value1(function (selected$prime) {
                return Data_Array.union(Data_Eq.eqInt)(ids)(selected$prime);
            });
        };
        var toggleCheckbox = function (id) {
            return function (mShift) {
                if (mShift instanceof Data_Maybe.Just) {
                    if (mShift.value0) {
                        return toggleAreaOfCheckboxes(id);
                    };
                    return toggleCheckbox$prime(id);
                };
                if (mShift instanceof Data_Maybe.Nothing) {
                    return toggleCheckbox$prime(id);
                };
                throw new Error("Failed pattern match at IntegrationContainer (line 116, column 32 - line 121, column 36): " + [ mShift.constructor.name ]);
            };
        };
        var toggleAllocated = v9.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean));
        var salesInvoiceLines = Data_Array.concatMap(function (v14) {
            return v14.lines;
        })(v2.value0);
        var prepareDropdownListItemWithIds = function (v14) {
            return {
                id: Data_Show.show(Data_Show.showInt)(v14.id),
                text: v14.code + (" " + v14.name)
            };
        };
        var prepareDropdownListItemWithCodes = function (v14) {
            return {
                id: v14.code,
                text: v14.code + (" " + v14.name)
            };
        };
        var projectAllocationDropdown = ReactBasicUtils["div'"]("project-allocation-dropdown")(Control_Applicative.pure(Control_Applicative.applicativeArray)(TacoAutocomplete.component()()({
            sections: (function () {
                var f = (function () {
                    if (v7.value0 instanceof IntegrationContainer_Types.ExpensesView) {
                        return prepareDropdownListItemWithCodes;
                    };
                    if (v7.value0 instanceof IntegrationContainer_Types.IncomeView) {
                        return prepareDropdownListItemWithIds;
                    };
                    throw new Error("Failed pattern match at IntegrationContainer (line 311, column 21 - line 313, column 63): " + [ v7.value0.constructor.name ]);
                })();
                return [ {
                    text: Data_Maybe.Nothing.value,
                    list: Data_Functor.map(Data_Functor.functorArray)(f)(v3.value0)
                } ];
            })(),
            clearButton: false,
            onSelect: function (item) {
                if (item instanceof Data_Maybe.Just) {
                    if (v7.value0 instanceof IntegrationContainer_Types.ExpensesView) {
                        return appDispatch(Actions.AllocateToProjectRequest.create({
                            ids: v4.value0,
                            property: Types_Project.AllocateToProjectCode.create(item.value0.id)
                        }));
                    };
                    var v14 = function (v15) {
                        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                    };
                    if (v7.value0 instanceof IntegrationContainer_Types.IncomeView) {
                        var $76 = Data_Int.fromString(item.value0.id);
                        if ($76 instanceof Data_Maybe.Just) {
                            return appDispatch(Actions.AllocateToProjectRequest.create({
                                ids: v4.value0,
                                property: Types_Project.AllocateToProjectId.create($76.value0)
                            }));
                        };
                        return v14(true);
                    };
                    return v14(true);
                };
                if (item instanceof Data_Maybe.Nothing) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                };
                throw new Error("Failed pattern match at IntegrationContainer (line 317, column 15 - line 331, column 37): " + [ item.constructor.name ]);
            },
            selected: Data_Maybe.Nothing.value,
            placeholder: "Kohdista projektille",
            testId: "project-allocation-dropdown"
        })));
        var navLink = function (handler) {
            return function (text) {
                return function (isActive) {
                    return React_Basic_DOM_Generated.div()({
                        className: Utils_ClassNames.mkClassName([ new Data_Maybe.Just("navbar-link"), Data_Monoid.guard(Data_Maybe.monoidMaybe(Data_Semigroup.semigroupString))(isActive)(new Data_Maybe.Just("active")) ]),
                        children: Data_Array.singleton(React_Basic_DOM.text(text)),
                        onClick: React_Basic_Events.handler_(handler)
                    });
                };
            };
        };
        var isIncomeView = function (v14) {
            if (v14 instanceof IntegrationContainer_Types.IncomeView) {
                return true;
            };
            if (v14 instanceof IntegrationContainer_Types.ExpensesView) {
                return false;
            };
            throw new Error("Failed pattern match at IntegrationContainer (line 130, column 5 - line 130, column 35): " + [ v14.constructor.name ]);
        };
        var navbar = React_Basic_DOM_Generated.div()({
            className: "integration-navbar",
            children: [ navLink(updateView(IntegrationContainer_Types.ExpensesView.value))("Menot")(!isIncomeView(v7.value0)) ]
        });
        var invoiceImgLinkComponent = InvoiceImgLink.createInvoiceImgLinkJSX(appDispatch)(Data_Maybe.Nothing.value);
        var invoiceFilterInput = Box.box("InvoiceFilterInput")([ new Box.Width(new TacoConstants.CustomSize("15rem")), new Box.PaddingTop(TacoConstants.S.value), new Box.PaddingBottom(TacoConstants.S.value) ])([ TacoInput.stringInput()()(Data_Eq.eqString)({
            value: v13.value0,
            onChange: v13.value1,
            placeholder: "Hae"
        }) ]);
        var hasNoProjectId = Data_Array.any(function (l) {
            return Data_Maybe.isNothing(l.projectId);
        });
        var hasNoProjectCode = function (lines) {
            return Data_Array.any(function (l) {
                return Data_Maybe.isNothing(l.projectCode);
            })(lines);
        };
        var migrateDataBtn = (function () {
            var purchaseInvoiceLines = Data_Array.concatMap(function (v14) {
                return v14.lines;
            })(v1.value0);
            var isDisabled = (function () {
                if (v7.value0 instanceof IntegrationContainer_Types.ExpensesView) {
                    return hasNoProjectCode(Data_Array.filter(function (i) {
                        return Data_Array.elem(Data_Eq.eqInt)(i.id)(v4.value0);
                    })(purchaseInvoiceLines));
                };
                if (v7.value0 instanceof IntegrationContainer_Types.IncomeView) {
                    return hasNoProjectId(Data_Array.filter(function (i) {
                        return Data_Array.elem(Data_Eq.eqInt)(i.id)(v4.value0);
                    })(salesInvoiceLines));
                };
                throw new Error("Failed pattern match at IntegrationContainer (line 271, column 22 - line 273, column 100): " + [ v7.value0.constructor.name ]);
            })();
            var title = (function () {
                if (isDisabled) {
                    return "Kohdista rivi" + ((function () {
                        var $83 = Data_Array.length(v4.value0) > 1;
                        if ($83) {
                            return "t";
                        };
                        return "";
                    })() + " ensin projektille");
                };
                return "";
            })();
            return TacoButton.component()()({
                text: "Vie valitut laskut",
                disabled: isDisabled,
                title: title,
                onClick: Data_Maybe.Just.create((function () {
                    var property = (function () {
                        if (v7.value0 instanceof IntegrationContainer_Types.ExpensesView) {
                            return Types_Invoice.MigratePurchaseInvoices.value;
                        };
                        if (v7.value0 instanceof IntegrationContainer_Types.IncomeView) {
                            return Types_Invoice.MigrateSalesInvoices.value;
                        };
                        throw new Error("Failed pattern match at IntegrationContainer (line 255, column 28 - line 257, column 53): " + [ v7.value0.constructor.name ]);
                    })();
                    var $85 = Data_Array["null"](v4.value0);
                    if ($85) {
                        return appDispatch(Actions.AddAlert.create(Types_Alert.AlertWarning.value)(Locale.lookup_("select_some_invoices")));
                    };
                    return appDispatch(Actions.MigrateSelectedInvoicesRequest.create({
                        ids: v4.value0,
                        property: property
                    }));
                })())
            });
        })();
        var filters = React_Basic_DOM_Generated.div()({
            className: "filters",
            children: [ TacoButton.component()()({
                text: "Viedyt",
                buttonStyle: TacoButton_Types.Outlined.value,
                onClick: new Data_Maybe.Just(toggleExported)
            }), TacoButton.component()()({
                text: "Kohdistetut",
                buttonStyle: TacoButton_Types.Outlined.value,
                onClick: new Data_Maybe.Just(toggleAllocated)
            }), TacoButton.component()()({
                text: "Laskut",
                buttonStyle: TacoButton_Types.Outlined.value,
                onClick: new Data_Maybe.Just(toggleInvoices)
            }), TacoButton.component()()({
                text: "Palkat",
                buttonStyle: TacoButton_Types.Outlined.value,
                onClick: new Data_Maybe.Just(toggleSalaries)
            }) ]
        });
        var filteredSalesInvoiceLines = filterAllocatedSalesInvoiceLines(v9.value0)(filterExportedInvoiceLines(v8.value0)(salesInvoiceLines));
        var filteredInvoices = filterInvoicesByString(v13.value0)(filterInvoices(v10.value0)(filterSalaries(v11.value0)(v1.value0)));
        var filteredInvoiceLines = filterAllocatedInvoiceLines(v9.value0)(filterExportedInvoiceLines(v8.value0)(Data_Array.concatMap(function (v14) {
            return v14.lines;
        })(filteredInvoices)));
        var fetchDataBtn = function (text) {
            return function (property) {
                var fetchData = function (defaultValue) {
                    return function __do() {
                        var input = WindowUtils.prompt("Muokattu alkaen. Sy\xf6t\xe4 p\xe4iv\xe4m\xe4\xe4r\xe4 muodossa YYYY-MM-DD (esim. 2019-05-26)")(defaultValue)();
                        if (input instanceof Data_Maybe.Nothing) {
                            return Data_Unit.unit;
                        };
                        if (input instanceof Data_Maybe.Just) {
                            return appDispatch(Actions.FetchDataFromIntegrationRequest.create({
                                date: input.value0,
                                property: property
                            }))();
                        };
                        throw new Error("Failed pattern match at IntegrationContainer (line 242, column 13 - line 245, column 116): " + [ input.constructor.name ]);
                    };
                };
                return TacoButton.component()()({
                    text: text,
                    onClick: Data_Maybe.Just.create(fetchData("2019-03-28")),
                    className: "fetch-data-btn",
                    icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconFetch.value),
                    align: TacoButton_Types.Left.value
                });
            };
        };
        var sections = [ {
            title: new Data_Maybe.Just("API"),
            items: [ fetchDataBtn("Hae uudet ostolaskut")(Types_Integration.FetchPurchaseInvoices.value) ]
        }, {
            title: new Data_Maybe.Just("SAP Excel"),
            items: [ UploadComponents.uploadInvoices({}), UploadComponents.uploadSAPSalaries({}) ]
        } ];
        var clearSelected = v4.value1(function (v14) {
            return [  ];
        });
        var allocationButtons = ReactBasicUtils["div'"]("allocation-buttons")([ ReactBasicUtils["div'"]("allocation-buttons-container")([ projectAllocationDropdown ]), migrateDataBtn ]);
        var addIds = function (ids) {
            return v4.value1(function (v14) {
                return ids;
            });
        };
        var invoiceSelectionCheckbox = (function () {
            var selectedItemsCount = Data_Array.length(v4.value0);
            var labelText = Data_Maybe.Just.create(TacoCheckbox.StringLabel.create((function () {
                if (selectedItemsCount === 0) {
                    return "Valitse";
                };
                if (selectedItemsCount === 1) {
                    return "1 lasku valittuna";
                };
                return Data_Show.show(Data_Show.showInt)(selectedItemsCount) + " laskua valittuna";
            })()));
            var filteredSalesInvoiceLines$prime = filterAllocatedSalesInvoiceLines(v9.value0)(filterExportedInvoiceLines(v8.value0)(salesInvoiceLines));
            var filteredInvoices$prime = filterInvoicesByString(v13.value0)(filterInvoices(v10.value0)(filterSalaries(v11.value0)(v1.value0)));
            var filteredInvoiceLines$prime = (function () {
                var $123 = filterAllocatedInvoiceLines(v9.value0);
                var $124 = filterExportedInvoiceLines(v8.value0);
                return function ($125) {
                    return $123($124($125));
                };
            })();
            var toggleAll = (function () {
                if (selectedItemsCount === 0) {
                    if (v7.value0 instanceof IntegrationContainer_Types.ExpensesView) {
                        return addIds(Data_Array.concatMap(Data_Functor.map(Data_Functor.functorArray)(function (v14) {
                            return v14.id;
                        }))(Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Data_Functor.functorFn)(filteredInvoiceLines$prime)(function (v14) {
                            return v14.lines;
                        }))(filteredInvoices$prime)));
                    };
                    if (v7.value0 instanceof IntegrationContainer_Types.IncomeView) {
                        return addIds(Data_Functor.map(Data_Functor.functorArray)(function (v14) {
                            return v14.id;
                        })(filteredSalesInvoiceLines$prime));
                    };
                    throw new Error("Failed pattern match at IntegrationContainer (line 296, column 16 - line 298, column 71): " + [ v7.value0.constructor.name ]);
                };
                return clearSelected;
            })();
            return TacoCheckbox.component()()({
                className: "toggle-all-selected-invoices",
                isChecked: selectedItemsCount > 0,
                onToggle: new TacoCheckbox.NoEvent(toggleAll),
                labelText: labelText,
                testId: "toggle-all-selected-invoices"
            });
        })();
        var actionsDropdown = React_Basic_DOM_Generated.div()({
            className: "action-buttons",
            children: Control_Applicative.pure(Control_Applicative.applicativeArray)(TacoDropdown.uncontrolled({
                sections: sections,
                align: TacoDropdown_Types.AlignRight.value,
                testId: "integration-actions-dropdown"
            }))
        });
        var tableControls = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "table-controls",
            css: Box.boxStyle([ Box.FlexRow.value, Box.JustifySpaceBetween.value, Box.AlignCenter.value ]),
            children: [ invoiceSelectionCheckbox, invoiceFilterInput, (function () {
                var $91 = Data_Array.length(v4.value0) === 0;
                if ($91) {
                    return filters;
                };
                return React_Basic.empty;
            })(), (function () {
                var $92 = Data_Array.length(v4.value0) === 0;
                if ($92) {
                    return actionsDropdown;
                };
                return React_Basic.empty;
            })(), (function () {
                var $93 = Data_Array.length(v4.value0) > 0;
                if ($93) {
                    return allocationButtons;
                };
                return React_Basic.empty;
            })() ]
        });
        return Box.box("IntegrationContainer")([ Box.FlexColumn.value, new Box.Overflow(Box.Visible.value, Box.Hidden.value), new Box.Height(new TacoConstants.CustomSize("100%")) ])([ navbar, tableControls, React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "imported-invoices-view Invoices page fixed-width",
            css: Box.boxStyle([ new Box.FlexGrow(1), Box.FlexColumn.value, new Box.Overflow(Box.Visible.value, Box.Hidden.value), new Box.Display(Box.Flex.value), new Box.MinWidth(new TacoConstants.CustomSize("1400px")) ]),
            children: [ (function () {
                var $94 = isIncomeView(v7.value0);
                if ($94) {
                    if (v2.value1) {
                        return TacoLoader.component()()({});
                    };
                    return IncomeTable.mkImportSalesInvoicesTable({
                        selected: v4.value0,
                        lines: filteredSalesInvoiceLines,
                        invoices: v2.value0,
                        toggleCheckbox: toggleCheckbox,
                        updateOrder: updateSalesInvoiceLinesOrder
                    });
                };
                if (v1.value1) {
                    return TacoLoader.component()()({});
                };
                return ExpensesTable.mkImportInvoicesTable({
                    selected: v4.value0,
                    invoiceImgLinkComponent: invoiceImgLinkComponent,
                    lines: filteredInvoiceLines,
                    invoices: v1.value0,
                    toggleCheckbox: toggleCheckbox,
                    updateOrder: updatePurchaseInvoiceLinesOrder
                });
            })() ]
        }) ]);
    };
});
module.exports = {
    component: component
};
