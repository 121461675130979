// Generated by purs version 0.14.5
"use strict";
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Effect_Exception = require("../Effect.Exception/index.js");
var QuantityFormula_Interpreter = require("../QuantityFormula.Interpreter/index.js");
var QuantityFormula_Types = require("../QuantityFormula.Types/index.js");
var dixonFormulaContext = function (measurement) {
    return QuantityFormula_Interpreter.mkDefaultContext(Data_Map_Internal.fromFoldable(Data_Ord.ordString)(Data_Foldable.foldableArray)([ new Data_Tuple.Tuple("X", new QuantityFormula_Types.QNum(measurement.numVertices)), new Data_Tuple.Tuple("x", new QuantityFormula_Types.QNum(measurement.numVertices)), new Data_Tuple.Tuple("N", new QuantityFormula_Types.QNum(measurement.numVertices)), new Data_Tuple.Tuple("n", new QuantityFormula_Types.QNum(measurement.numVertices)), new Data_Tuple.Tuple("P", new QuantityFormula_Types.QNum(measurement.perimeter)), new Data_Tuple.Tuple("p", new QuantityFormula_Types.QNum(measurement.perimeter)), new Data_Tuple.Tuple("A", new QuantityFormula_Types.QNum(measurement.area)), new Data_Tuple.Tuple("a", new QuantityFormula_Types.QNum(measurement.area)) ]));
};
var wasmEvalDixonMeasurementFormula = function (measurement) {
    var c = dixonFormulaContext(measurement);
    var result = QuantityFormula_Interpreter.evalQuantityFormula(c)(measurement.formula);
    if (result instanceof Data_Either.Left) {
        return Control_Monad_Error_Class.throwError(Control_Monad_Error_Class.monadThrowEffect)(Effect_Exception.error(result.value0))();
    };
    if (result instanceof Data_Either.Right && result.value0 instanceof QuantityFormula_Types.QNum) {
        return result.value0.value0;
    };
    if (result instanceof Data_Either.Right) {
        return Control_Monad_Error_Class.throwError(Control_Monad_Error_Class.monadThrowEffect)(Effect_Exception.error("Unsupported result type from formula expression"))();
    };
    throw new Error("Failed pattern match at Wasm.FFI (line 38, column 3 - line 41, column 84): " + [ result.constructor.name ]);
};
module.exports = {
    dixonFormulaContext: dixonFormulaContext,
    wasmEvalDixonMeasurementFormula: wasmEvalDixonMeasurementFormula
};
