import {
  GridApi,
  ValueGetterParams,
  ValueSetterParams,
} from "ag-grid-community";
import { OfferClassificationSummary } from "./OfferPage";
import { TFunction } from "i18next";
import { safeDivide } from "../../../../utils/safeDivide";
import { Measurement } from "../../../../../../ts-bindings/Measurement";
import { AgGridReact } from "ag-grid-react";
import { RefObject } from "react";
import { formatOfferValueToNumber } from "../OfferPageCommonFunctions";

export function getCodeAndDescription(
  params: ValueGetterParams<OfferClassificationSummary>,
  t: TFunction
) {
  if (!params.data) {
    return null;
  }
  if (params.node?.rowPinned) {
    return t`grandTotal`;
  }
  const { data } = params;
  return data.description ? `${data.code} - ${data.description}` : data.code;
}

export function getHourlyRate(
  params: ValueGetterParams<OfferClassificationSummary>
) {
  if (!params.data) {
    return null;
  }
  return params.data.hourlyRate;
}

export function getHours(params: ValueGetterParams) {
  if (!params.data) {
    return null;
  }
  return params.data.hours;
}

export function getTotal(
  params: ValueGetterParams<OfferClassificationSummary>
) {
  if (!params.data) {
    return null;
  }
  const percentage =
    params.data.generalMarginPercentage ?? params.data.marginPercentage;
  params.data.economicTotal = safeDivide(params.data.total, 1 - percentage);
  return params.data.total;
}

export function getMarginPercentage(
  params: ValueGetterParams<OfferClassificationSummary>
) {
  if (!params.data) {
    return null;
  }
  if (
    params.data.generalMarginPercentage ||
    params.data.generalMarginPercentage === 0
  ) {
    return params.data.generalMarginPercentage;
  }
  return params.data.marginPercentage;
}

export function getMargin(
  params: ValueGetterParams<OfferClassificationSummary>,
  marginType: string | undefined
) {
  if (!params.data || !params.node) {
    return null;
  }
  const percentage =
    params.data.generalMarginPercentage ?? params.data.marginPercentage;
  params.data.economicMargin = params.data.economicTotal * percentage;
  params.data.technicalMargin = params.data.total * percentage;
  if (marginType === "economicMargin") {
    return params.data.economicMargin;
  }
  return params.data.technicalMargin;
}

export function getTotalWithMargin(
  params: ValueGetterParams<OfferClassificationSummary>,
  marginType: string | undefined
) {
  if (!params.data || !params.node) {
    return null;
  }
  const percentage =
    params.data.generalMarginPercentage ?? params.data.marginPercentage;
  if (marginType === "economicMargin") {
    params.data.economicMargin = params.data.economicTotal * percentage;
    params.data.totalWithEconomicMargin =
      params.data.total + params.data.economicMargin;
    return params.data.totalWithEconomicMargin;
  }
  params.data.technicalMargin = params.data.total * percentage;
  params.data.totalWithTechnicalMargin =
    params.data.total + params.data.technicalMargin;
  return params.data.totalWithTechnicalMargin;
}

export function getMarginPercentageOfTotal(
  params: ValueGetterParams<OfferClassificationSummary>
) {
  if (!params.data) {
    return null;
  }
  if (params.node?.data) {
    const grandTotal = getGrandTotalValues(params.api, "totalWithMargin");
    const totalWithMargin = params.api.getValue("totalWithMargin", params.node);
    params.node.data.marginPercentageOfTotal = safeDivide(
      totalWithMargin,
      grandTotal
    );
    return params.node.data.marginPercentageOfTotal;
  }
}

export function getVATWithTotal(
  params: ValueGetterParams<OfferClassificationSummary>,
  marginType: string | undefined
) {
  if (!params.data) {
    return null;
  }
  const { taxRate } = params.context;
  const currentTotal =
    marginType === "economicMargin"
      ? params.data.totalWithEconomicMargin
      : params.data.totalWithTechnicalMargin;

  if (!taxRate || !currentTotal) {
    return 0;
  }
  if (params.data) {
    params.data.totalWithVAT = (taxRate / 100) * currentTotal + currentTotal;
  }
  return params.data?.totalWithVAT;
}

export function getVATOfTotal(
  params: ValueGetterParams<OfferClassificationSummary>,
  marginType: string | undefined
) {
  if (!params.data) {
    return null;
  }
  const { taxRate } = params.context;
  const currentTotal =
    marginType === "economicMargin"
      ? params.data.totalWithEconomicMargin
      : params.data.totalWithTechnicalMargin;
  if (!taxRate || !currentTotal) {
    return 0;
  }
  if (params.data) {
    params.data.VAT = (taxRate / 100) * currentTotal;
  }
  return params.data?.VAT;
}

export function getMeasurementValue(
  params: ValueGetterParams<OfferClassificationSummary>,
  unit: Measurement,
  marginType: string | undefined
) {
  if (!params.node || !params.data) {
    return null;
  }
  if (unit.values.length === 0 || unit.values[0].value === 0) {
    return 0;
  }
  const totalValue =
    marginType === "economicMargin"
      ? params.data.economicTotal
      : params.data.total;
  return totalValue / unit.values[0].value;
}

export function setMarginPercentage(
  params: ValueSetterParams<OfferClassificationSummary>
) {
  const newValue = formatOfferValueToNumber(params);
  if (!params.data || params.newValue === params.oldValue || !params.node) {
    return false;
  }
  params.api.forEachNodeAfterFilter((node) => {
    if (node.data?.generalMarginPercentage) {
      node.data.marginPercentage = node.data.generalMarginPercentage;
      node.data.generalMarginPercentage = undefined;
    }
    params.api.refreshCells({ rowNodes: [node], force: true });
  });
  if (params.node.rowPinned) {
    params.data.generalMarginPercentage = newValue;
    params.api.forEachNode((node) => {
      if (node.data) {
        node.data.generalMarginPercentage = newValue;
      }
      params.api.refreshCells({ rowNodes: [node], force: true });
    });
    return true;
  }
  params.data.marginPercentage = newValue;
  return true;
}

export function setMargin(
  params: ValueSetterParams<OfferClassificationSummary>,
  marginType: string | undefined
) {
  const newValue = formatOfferValueToNumber(params);
  if (!params.data || params.newValue === params.oldValue || !params.node) {
    return false;
  }
  const { total } = params.data;
  if (params.node.rowPinned) {
    params.api.forEachNodeAfterFilter((node) => {
      const grandTotal = getGrandTotalValues(params.api, "total");
      const newEconomicPercentage = safeDivide(newValue, grandTotal + newValue);
      const newTechnicalPercentage = safeDivide(newValue, grandTotal);
      if (node.data) {
        if (marginType === "economicMargin") {
          node.data.generalMarginPercentage = newEconomicPercentage;
        } else {
          node.data.generalMarginPercentage = newTechnicalPercentage;
        }
        if (node.data.code === "grandTotal") {
          params.api.refreshCells({ rowNodes: [node], force: true });
        }
      }
      params.api.refreshCells({ rowNodes: [node], force: true });
    });
    return true;
  }
  params.api.forEachNodeAfterFilter((node) => {
    if (node.data?.generalMarginPercentage) {
      node.data.marginPercentage = node.data.generalMarginPercentage;
      node.data.generalMarginPercentage = undefined;
    }
    params.api.refreshCells({ rowNodes: [node], force: true });
  });
  if (marginType === "economicMargin") {
    params.data.marginPercentage = safeDivide(newValue, total + newValue);
  } else {
    params.data.marginPercentage = safeDivide(total + newValue, total) - 1;
  }
  return true;
}

export function setTotalWithMargin(
  params: ValueSetterParams<OfferClassificationSummary>,
  marginType: string | undefined
) {
  const newValue = formatOfferValueToNumber(params);
  if (!params.data || params.newValue === params.oldValue || !params.node) {
    return false;
  }
  params.api.forEachNodeAfterFilter((node) => {
    if (node.data?.generalMarginPercentage) {
      node.data.marginPercentage = node.data.generalMarginPercentage;
      node.data.generalMarginPercentage = undefined;
    }
    params.api.refreshCells({ rowNodes: [node], force: true });
  });
  if (marginType === "economicMargin") {
    params.data.marginPercentage = safeDivide(
      newValue - params.data.total,
      newValue
    );
  } else {
    params.data.marginPercentage = safeDivide(newValue, params.data.total) - 1;
  }
  return true;
}

export function getGrandTotalValues(
  api: GridApi<OfferClassificationSummary>,
  column: string
) {
  let sum = 0;
  api.forEachNodeAfterFilter((row) => {
    if (!row.group) {
      const rowValue = api.getValue(column, row);
      sum += rowValue;
    }
  });
  return sum;
}

export const getPinnedEconomicTotal = (
  gridRef: RefObject<AgGridReact<OfferClassificationSummary>>,
  marginType: string | undefined
) => {
  if (gridRef.current) {
    let economicTotal = 0;
    let total = 0;
    let totalWithEconomicMargin = 0;
    let totalWithTechnicalMargin = 0;
    let economicMargin = 0;
    let technicalMargin = 0;
    let hours = 0;
    let totalHourlyPrice = 0;
    let marginPercentageOfTotal = 0;
    let VAT = 0;
    let totalWithVAT = 0;
    gridRef.current.api?.forEachNodeAfterFilter((node) => {
      if (node.data) {
        economicTotal += node.data.economicTotal;
        total += node.data.total;
        totalWithEconomicMargin += node.data.totalWithEconomicMargin;
        totalWithTechnicalMargin += node.data.totalWithTechnicalMargin;
        economicMargin += node.data.economicMargin;
        technicalMargin += node.data.technicalMargin;
        hours += node.data.hours;
        totalHourlyPrice += node.data.hourlyPrice;
        marginPercentageOfTotal += node.data.marginPercentageOfTotal ?? 0;
        VAT += node.data.VAT ?? 0;
        totalWithVAT += node.data.totalWithVAT ?? 0;
      }
    });
    const marginPercentage =
      marginType === "economicMargin"
        ? safeDivide(economicMargin, economicTotal)
        : safeDivide(technicalMargin, total);
    const hourlyRate = safeDivide(totalHourlyPrice, hours);
    return [
      {
        code: "grandTotal",
        economicTotal,
        total,
        marginPercentage,
        totalWithEconomicMargin,
        totalWithTechnicalMargin,
        economicMargin,
        technicalMargin,
        hours,
        hourlyRate,
        marginPercentageOfTotal,
        VAT,
        totalWithVAT,
      },
    ];
  }
};
