// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Locale = require("../Locale/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_FrontEndRoutes = require("../Types.FrontEndRoutes/index.js");
var AddProjectBtnCostControl = (function () {
    function AddProjectBtnCostControl() {

    };
    AddProjectBtnCostControl.value = new AddProjectBtnCostControl();
    return AddProjectBtnCostControl;
})();
var AddProjectBtnEstimation = (function () {
    function AddProjectBtnEstimation() {

    };
    AddProjectBtnEstimation.value = new AddProjectBtnEstimation();
    return AddProjectBtnEstimation;
})();
var component = TofuHooks.mkHookComponent("AddProjectBtn")(function (props) {
    return function __do() {
        var appDispatch = StateHooks.useDispatch();
        return TacoButton.component()()({
            onClick: Data_Maybe.Just.create(appDispatch((function () {
                if (props.mode instanceof AddProjectBtnCostControl) {
                    return new Actions.NavigateRoute(Types_FrontEndRoutes.AddCostControlProjectFERoute.value);
                };
                if (props.mode instanceof AddProjectBtnEstimation) {
                    return new Actions.NavigateRoute(Types_FrontEndRoutes.AddEstimationProjectFERoute.value);
                };
                throw new Error("Failed pattern match at AddProjectBtn (line 26, column 35 - line 28, column 85): " + [ props.mode.constructor.name ]);
            })())),
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconAddPlusV1.value),
            text: Locale.lookup_("add_new_project"),
            testId: "add-new-project-button"
        });
    };
});
module.exports = {
    component: component,
    AddProjectBtnCostControl: AddProjectBtnCostControl,
    AddProjectBtnEstimation: AddProjectBtnEstimation
};
