// Generated by purs version 0.14.5
"use strict";
var Calcuation_Margin = require("../Calcuation.Margin/index.js");
var Calculation_CostClass = require("../Calculation.CostClass/index.js");
var Calculation_CostGroup = require("../Calculation.CostGroup/index.js");
var Calculation_Income = require("../Calculation.Income/index.js");
var Calculation_Utils = require("../Calculation.Utils/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Foreign_Object = require("../Foreign.Object/index.js");
var OwnProjectTaxes = require("../OwnProjectTaxes/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_CostGroup = require("../Types.CostGroup/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_R200 = require("../Types.R200/index.js");
var Utils = require("../Utils/index.js");
var DontNest = (function () {
    function DontNest() {

    };
    DontNest.value = new DontNest();
    return DontNest;
})();
var NestBy3Digits = (function () {
    function NestBy3Digits() {

    };
    NestBy3Digits.value = new NestBy3Digits();
    return NestBy3Digits;
})();
var tofuJSONTwoLevelNesting = {
    readImpl: function (f) {
        return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(TofuJSON.readImpl(TofuJSON.readBoolean)(f))(function (v) {
            return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))((function () {
                if (v) {
                    return NestBy3Digits.value;
                };
                return DontNest.value;
            })());
        });
    },
    writeImpl: function (v) {
        if (v instanceof NestBy3Digits) {
            return TofuJSON.writeImpl(TofuJSON.readBoolean)(true);
        };
        if (v instanceof DontNest) {
            return TofuJSON.writeImpl(TofuJSON.readBoolean)(false);
        };
        throw new Error("Failed pattern match at R200PrepareTableData (line 76, column 1 - line 81, column 44): " + [ v.constructor.name ]);
    }
};
var toCostGroupColumnSpecRow = function (v) {
    var diffOfTargetToEstimate = v.targetCosts - v.estimateCosts;
    return {
        displayName: Data_Newtype.un()(Types_CostGroup.CostGroupCode)(v.code) + (" " + v.name),
        targetCosts: v.targetCosts,
        estimatedCosts: v.estimateCosts,
        estimateRemaining: v.estimateCosts - v.actualCosts,
        diffOfTargetToEstimate: diffOfTargetToEstimate,
        diffOfTargetToEstimateRatio: Utils.defaultZeroDiv(diffOfTargetToEstimate)(v.targetCosts),
        actualCosts: v.actualCosts,
        actualToEstimateRatio: Utils.defaultZeroDiv(v.actualCosts)(v.estimateCosts),
        items: Data_Maybe.Nothing.value,
        completionPercentage: Utils.convertPercentage100to0(v.completionPercentage)
    };
};
var takeThreeDigitCode = function (v) {
    var v1 = function (v2) {
        if (Data_Boolean.otherwise) {
            return Data_Newtype.un()(Types_CostGroup.CostGroupCode)(v.code);
        };
        throw new Error("Failed pattern match at R200PrepareTableData (line 197, column 1 - line 197, column 46): " + [ v.constructor.name ]);
    };
    var $43 = Data_String_CodePoints.take(3)(v.code);
    var $44 = Data_String_CodePoints.length($43) === 3;
    if ($44) {
        return $43;
    };
    return v1(true);
};
var takeSingleDigitCode = function (v) {
    var v1 = function (v2) {
        if (Data_Boolean.otherwise) {
            return Data_Maybe.Nothing.value;
        };
        throw new Error("Failed pattern match at R200PrepareTableData (line 185, column 1 - line 185, column 53): " + [ v.constructor.name ]);
    };
    var $48 = Data_String_CodePoints.take(1)(v.code);
    var $49 = Data_String_CodePoints.length($48) === 1;
    if ($49) {
        return new Data_Maybe.Just($48);
    };
    return v1(true);
};
var r200IncomeInformationResultsToIncomeColumnSpecRow = function (v) {
    return {
        name: v.name,
        target: v.target,
        estimate: v.estimate,
        actual: v.actual,
        actualToEstimate: Utils.defaultZeroDiv(v.actual)(v.estimate)
    };
};
var r200CostGroupsResultToSummedItemColumnSpecRow = function (v) {
    var diffOfTargetToEstimate = v.target - v.estimate;
    return {
        name: v.name,
        targetCosts: v.target,
        estimatedCosts: v.estimate,
        estimateRemaining: v.estimate - v.actual,
        actualCosts: v.actual,
        diffOfTargetToEstimate: diffOfTargetToEstimate,
        diffOfTargetToEstimateRatio: Utils.defaultZeroDiv(diffOfTargetToEstimate)(v.target),
        actualToEstimateRatio: Utils.defaultZeroDiv(v.actual)(v.estimate),
        completionPercentage: Data_Maybe.Nothing.value
    };
};
var r200CostClassResultToColumns = function (mkDisplayName) {
    return function (v) {
        var diffOfTargetToEstimate = v.target - v.estimate;
        return {
            displayName: mkDisplayName(v),
            targetCosts: v.target,
            estimatedCosts: v.estimate,
            estimateRemaining: v.estimate - v.actual,
            actualCosts: v.actual,
            diffOfTargetToEstimate: diffOfTargetToEstimate,
            diffOfTargetToEstimateRatio: Utils.defaultZeroDiv(diffOfTargetToEstimate)(v.target),
            actualToEstimateRatio: Utils.defaultZeroDiv(v.actual)(v.estimate)
        };
    };
};
var prepareFlattenedR200CostGroupColumnSpecRow = function (twoLevelNesting) {
    return function (xs) {
        var prepare = function (r) {
            return {
                displayName: r.displayName,
                targetCosts: r.targetCosts,
                estimatedCosts: r.estimatedCosts,
                diffOfTargetToEstimate: r.diffOfTargetToEstimate,
                diffOfTargetToEstimateRatio: r.diffOfTargetToEstimateRatio,
                actualCosts: r.actualCosts,
                actualToEstimateRatio: r.actualToEstimateRatio,
                completionPercentage: r.completionPercentage
            };
        };
        var flatten = function (r) {
            return Data_Semigroup.append(Data_Semigroup.semigroupArray)([ prepare(r) ])((function () {
                if (twoLevelNesting instanceof DontNest) {
                    return [  ];
                };
                if (twoLevelNesting instanceof NestBy3Digits) {
                    return Data_Maybe.maybe([  ])((function () {
                        var $111 = Data_Functor.map(Data_Functor.functorArray)(prepare);
                        var $112 = Data_Newtype.un()(Types_R200.CostGroupColumnSpecRowItems);
                        return function ($113) {
                            return $111($112($113));
                        };
                    })())(r.items);
                };
                throw new Error("Failed pattern match at R200PrepareTableData (line 244, column 10 - line 246, column 94): " + [ twoLevelNesting.constructor.name ]);
            })());
        };
        return Control_Bind.bindFlipped(Control_Bind.bindArray)(flatten)(xs);
    };
};
var nothingOnEmptyR200CostGroupResults = function (v) {
    var v1 = function (v2) {
        if (Data_Boolean.otherwise) {
            return new Data_Maybe.Just(v);
        };
        throw new Error("Failed pattern match at R200PrepareTableData (line 98, column 1 - line 98, column 89): " + [ v.constructor.name ]);
    };
    var $57 = Utils.nearZero(v.target);
    if ($57) {
        var $58 = Utils.nearZero(v.estimate);
        if ($58) {
            var $59 = Utils.nearZero(v.actual);
            if ($59) {
                return Data_Maybe.Nothing.value;
            };
            return v1(true);
        };
        return v1(true);
    };
    return v1(true);
};
var mkCostGroupColumnSpecRowItems = (function () {
    var $114 = Control_Applicative.pure(Data_Maybe.applicativeMaybe);
    return function ($115) {
        return $114(Types_R200.CostGroupColumnSpecRowItems($115));
    };
})();
var mkCostClassDisplayName = function (v) {
    return Data_Newtype.un()(Types_Newtypes.CostClassCode)(v.costClassCode) + (". " + v.name);
};
var getThreeDigitGroupingIdentifiers = function (xs) {
    return Data_Array.nub(Data_Ord.ordString)(Data_Functor.map(Data_Functor.functorArray)(takeThreeDigitCode)(xs));
};
var getR200CostClassResultName = function (v) {
    return v.name;
};
var getMainCostGroupDigits = function (xs) {
    return Data_Array.nub(Data_Ord.ordString)(Data_Array.mapMaybe(takeSingleDigitCode)(xs));
};
var getGroupedByCriteria = function (v) {
    return function (matchTest) {
        return function (prepareItems) {
            return function (costGroupUnits_) {
                var costGroupUnits = Data_Array.filter(matchTest(v.key))(costGroupUnits_);
                var countLabel = "(" + (Data_Show.show(Data_Show.showInt)(Data_Array.length(costGroupUnits)) + ")");
                var costGroupUnitSums = Calculation_CostGroup.getCostGroupUnitSums(Data_Foldable.foldableArray)(Data_Functor.functorArray)(costGroupUnits);
                var diffOfTargetToEstimate = costGroupUnitSums.target - costGroupUnitSums.estimate;
                var completionPercentage = Calculation_CostGroup.getCompletionPercentageOfCostGroupUnits(costGroupUnits);
                return {
                    displayName: v.key + (" " + Data_Maybe.fromMaybe(countLabel)(v.name)),
                    targetCosts: costGroupUnitSums.target,
                    actualCosts: costGroupUnitSums.actual,
                    estimatedCosts: costGroupUnitSums.estimate,
                    estimateRemaining: costGroupUnitSums.estimate - costGroupUnitSums.actual,
                    diffOfTargetToEstimate: diffOfTargetToEstimate,
                    diffOfTargetToEstimateRatio: Utils.defaultZeroDiv(diffOfTargetToEstimate)(costGroupUnitSums.target),
                    actualToEstimateRatio: Utils.defaultZeroDiv(costGroupUnitSums.actual)(costGroupUnitSums.estimate),
                    items: prepareItems(costGroupUnits),
                    completionPercentage: completionPercentage
                };
            };
        };
    };
};
var nestCostGroupUnitsByTwoLevels = function (costGroupUnits_) {
    var threeDigitCodes = getThreeDigitGroupingIdentifiers(costGroupUnits_);
    var groupCGUs = function (digits) {
        var isPrefixedByDigits = function (key) {
            return function (v) {
                return Data_Maybe.isJust(Data_String_CodeUnits.stripPrefix(key)(v.code));
            };
        };
        var isLabel = function (v) {
            return v.code === digits;
        };
        var labelCostGroup = Data_Array.find(isLabel)(costGroupUnits_);
        var prepareItems = function (xs) {
            return mkCostGroupColumnSpecRowItems(Data_Functor.map(Data_Functor.functorArray)(toCostGroupColumnSpecRow)(Data_Array.filter(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(isLabel))(xs)));
        };
        return getGroupedByCriteria({
            key: digits,
            name: Data_Functor.map(Data_Maybe.functorMaybe)((function () {
                var $116 = Data_Newtype.un()(Types_CostGroup.CostGroupUnit);
                return function ($117) {
                    return (function (v) {
                        return v.name;
                    })($116($117));
                };
            })())(labelCostGroup)
        })(isPrefixedByDigits)(prepareItems)(costGroupUnits_);
    };
    return Data_Functor.map(Data_Functor.functorArray)(groupCGUs)(threeDigitCodes);
};
var nestCostGroupUnits = function (nestBy3DigitsToggle) {
    return function (mainCostGroups_) {
        return function (costGroupUnits_) {
            var mainCostGroupDigits = getMainCostGroupDigits(costGroupUnits_);
            var groupCGUs = function (digit) {
                var prepareItems = function (v) {
                    return function (xs) {
                        if (v instanceof DontNest) {
                            return mkCostGroupColumnSpecRowItems(Data_Functor.map(Data_Functor.functorArray)(toCostGroupColumnSpecRow)(xs));
                        };
                        if (v instanceof NestBy3Digits) {
                            return mkCostGroupColumnSpecRowItems(nestCostGroupUnitsByTwoLevels(xs));
                        };
                        throw new Error("Failed pattern match at R200PrepareTableData (line 138, column 9 - line 138, column 99): " + [ v.constructor.name, xs.constructor.name ]);
                    };
                };
                var matchMCGCode = function (key) {
                    return function (cgu) {
                        return key === Calculation_CostGroup.extractMCGCodeFromCGCode(Calculation_CostGroup.getCGUCode(cgu));
                    };
                };
                var mainCostGroup = Data_Foldable.find(Foreign_Object.foldableObject)(function (x) {
                    return x.code === digit;
                })(mainCostGroups_);
                return getGroupedByCriteria({
                    key: digit,
                    name: Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
                        return v.name;
                    })(mainCostGroup)
                })(matchMCGCode)(prepareItems(nestBy3DigitsToggle))(costGroupUnits_);
            };
            return Data_Functor.map(Data_Functor.functorArray)(groupCGUs)(mainCostGroupDigits);
        };
    };
};
var eqTwoLevelNesting = {
    eq: function (x) {
        return function (y) {
            if (x instanceof DontNest && y instanceof DontNest) {
                return true;
            };
            if (x instanceof NestBy3Digits && y instanceof NestBy3Digits) {
                return true;
            };
            return false;
        };
    }
};
var costGroupUnitNotEmpty = function (v) {
    return !(Utils.nearZero(v.targetCosts) && (Utils.nearZero(v.actualCosts) && Utils.nearZero(v.estimateCosts)));
};
var prepareR200Data = function (props) {
    var vatExclusionCostGroups = Data_Maybe.fromMaybe([  ])(Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
        return v.items;
    })(props["vatExclusionCostGroups'"]));
    var upperDateLimit = (function () {
        if (props.upperDateLimitDate instanceof Data_Maybe.Just) {
            return Calculation_Utils.UpperDateLimit.create(Utils.lossyParseJSDateString(props.upperDateLimitDate.value0));
        };
        return Calculation_Utils.NoDateLimit.value;
    })();
    var projectTaxStatus = OwnProjectTaxes.getProjectTaxRate(props.project);
    var prepareOptionalRows = function (xs) {
        return Data_Functor.map(Data_Functor.functorArray)(r200CostGroupsResultToSummedItemColumnSpecRow)(Data_Array.catMaybes(xs));
    };
    var mainCostGroups = Data_Maybe.fromMaybe(Foreign_Object.empty)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
        return v.items;
    })(props["mainCostGroups'"]));
    var filterEmptyCostGroups = (function () {
        if (props.hideEmptyCostGroups) {
            return Data_Array.filter(costGroupUnitNotEmpty);
        };
        return Control_Category.identity(Control_Category.categoryFn);
    })();
    var disableSocialCostToActual = (function () {
        if (props.costControlExcludeActualCostSocialExpenses instanceof Data_Maybe.Just && props.costControlExcludeActualCostSocialExpenses.value0.costControlExcludeActualCostSocialExpenses) {
            return Calculation_Income.DisableSocialCostToActual.value;
        };
        return Calculation_Income.AllowSocialCostToActual.value;
    })();
    var approvedIncomeItemsForActualFlag = (function () {
        if (props.showActualIncomeBasedOnApprovedIncomeItemsOnly) {
            return Calculation_Income.UseOnlyApprovedIncomeItemsForActualFlag.value;
        };
        return Calculation_Income.UseAnyIncomeItemsforActual.value;
    })();
    var incomeInfo = Calculation_Income.mkR200IncomeInformationResults(approvedIncomeItemsForActualFlag)(projectTaxStatus)(props.showSumOfUnallocatedSalesInvoices)(props.incomeItems)(props.salesInvoices)(upperDateLimit);
    var incomeInfoItems = Data_Array.catMaybes(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ new Data_Maybe.Just(incomeInfo.installments), (function () {
        var $85 = incomeInfo.additionalWorkItemsLength > 0;
        if ($85) {
            return new Data_Maybe.Just(incomeInfo.additionalWork);
        };
        return Data_Maybe.Nothing.value;
    })() ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_Functor.map(Data_Functor.functorArray)(Data_Maybe.Just.create)(incomeInfo.others))([ incomeInfo.taxes, incomeInfo.unallocated, new Data_Maybe.Just(incomeInfo.totals) ])));
    var incomeInfoItemsRows = Data_Functor.map(Data_Functor.functorArray)(r200IncomeInformationResultsToIncomeColumnSpecRow)(incomeInfoItems);
    var incomeTotal = Data_Newtype.over()()(Types_R200.R200IncomeInformationResult)(function (v) {
        return {
            name: "Tulot yhteens\xe4",
            actual: v.actual,
            estimate: v.estimate,
            target: v.target
        };
    })(incomeInfo.totals);
    var _costGroupItems = Data_Semigroup.append(Data_Semigroup.semigroupArray)(props.targetItems)(props.estimateItems);
    var costClassResults = Calculation_CostClass["mkR200CostClassResults'"](disableSocialCostToActual)(vatExclusionCostGroups)(projectTaxStatus)(_costGroupItems)(props.invoiceLines)(props.costClasses)(upperDateLimit);
    var costClassRows = (function () {
        var totalsWithoutTaxes = (function () {
            var v = r200CostClassResultToColumns(getR200CostClassResultName)(costClassResults.totalsWithoutTaxes);
            return {
                displayName: "Yhteens\xe4",
                actualCosts: v.actualCosts,
                actualToEstimateRatio: v.actualToEstimateRatio,
                diffOfTargetToEstimate: v.diffOfTargetToEstimate,
                diffOfTargetToEstimateRatio: v.diffOfTargetToEstimateRatio,
                estimateRemaining: v.estimateRemaining,
                estimatedCosts: v.estimatedCosts,
                targetCosts: v.targetCosts
            };
        })();
        var totals = (function () {
            var v = r200CostClassResultToColumns(getR200CostClassResultName)(costClassResults.totals);
            return {
                displayName: "Kustannukset yhteens\xe4",
                actualCosts: v.actualCosts,
                actualToEstimateRatio: v.actualToEstimateRatio,
                diffOfTargetToEstimate: v.diffOfTargetToEstimate,
                diffOfTargetToEstimateRatio: v.diffOfTargetToEstimateRatio,
                estimateRemaining: v.estimateRemaining,
                estimatedCosts: v.estimatedCosts,
                targetCosts: v.targetCosts
            };
        })();
        var socialExpenses$prime = r200CostClassResultToColumns(getR200CostClassResultName)(costClassResults.socialExpenses);
        var optionalTaxes = Data_Functor.map(Data_Maybe.functorMaybe)(r200CostClassResultToColumns(getR200CostClassResultName))(costClassResults.optionalTaxes);
        var costClasses$prime = Data_Functor.map(Data_Functor.functorArray)(r200CostClassResultToColumns(mkCostClassDisplayName))(costClassResults.costClassResults);
        return Data_Semigroup.append(Data_Semigroup.semigroupArray)(costClasses$prime)(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ socialExpenses$prime ])((function () {
            if (optionalTaxes instanceof Data_Maybe.Just) {
                return [ totalsWithoutTaxes, optionalTaxes.value0, totals ];
            };
            return [ totals ];
        })()));
    })();
    var includeSocialExpenses = function (v) {
        var socialExpensesForCostGroup = Calculation_CostClass.getSocialExpensesForCostGroup(disableSocialCostToActual)(v.code)(_costGroupItems)(props.invoiceLines)(props.costClasses)(upperDateLimit);
        return {
            id: v.id,
            actualCosts: v.actualCosts + socialExpensesForCostGroup.actualSocialExpenses,
            category: v.category,
            code: socialExpensesForCostGroup.costGroupCode,
            completionPercentage: v.completionPercentage,
            estimateCosts: v.estimateCosts + socialExpensesForCostGroup.estimateSocialExpenses,
            memo: v.memo,
            name: v.name,
            projectId: v.projectId,
            targetCosts: v.targetCosts + socialExpensesForCostGroup.targetSocialExpenses
        };
    };
    var r200CostGroupResults = Calculation_CostGroup.mkR200CostGroupResults(disableSocialCostToActual)(vatExclusionCostGroups)(projectTaxStatus)(props.invoiceLines)(props.costGroupInfos)(_costGroupItems)(props.costClasses)(upperDateLimit);
    var costGroupUnits_ = filterEmptyCostGroups(r200CostGroupResults.costGroups);
    var costGroupUnits = (function () {
        if (props.includeSocialExpensesInCostGroups) {
            return Data_Functor.map(Data_Functor.functorArray)(includeSocialExpenses);
        };
        return Control_Category.identity(Control_Category.categoryFn);
    })()(costGroupUnits_);
    var costGroupsItems = nestCostGroupUnits(props.showCostGroupCodeTwoLevelNesting)(mainCostGroups)(costGroupUnits);
    var flattenedCostGroupsItems = prepareFlattenedR200CostGroupColumnSpecRow(props.showCostGroupCodeTwoLevelNesting)(costGroupsItems);
    var costTotal = r200CostGroupsResultToSummedItemColumnSpecRow(r200CostGroupResults.total);
    var missingCostGroupUnits_ = filterEmptyCostGroups(r200CostGroupResults.missingCostGroups);
    var missingCostGroupUnits = (function () {
        if (props.includeSocialExpensesInCostGroups) {
            return Data_Functor.map(Data_Functor.functorArray)(includeSocialExpenses)(missingCostGroupUnits_);
        };
        return missingCostGroupUnits_;
    })();
    var missingCostGroupItems = (function () {
        var $91 = Data_Array["null"](missingCostGroupUnits);
        if ($91) {
            return [  ];
        };
        return Control_Applicative.pure(Control_Applicative.applicativeArray)(getGroupedByCriteria({
            key: "",
            name: new Data_Maybe.Just("Puuttuvat litterat")
        })(function (v) {
            return function (v1) {
                return true;
            };
        })((function () {
            var $118 = Data_Functor.map(Data_Functor.functorArray)(toCostGroupColumnSpecRow);
            return function ($119) {
                return mkCostGroupColumnSpecRowItems($118($119));
            };
        })())(missingCostGroupUnits));
    })();
    var optionalEntries = prepareOptionalRows([ (function () {
        if (props.includeSocialExpensesInCostGroups) {
            return Data_Maybe.Nothing.value;
        };
        return nothingOnEmptyR200CostGroupResults(r200CostGroupResults.socialExpenses);
    })(), nothingOnEmptyR200CostGroupResults(r200CostGroupResults.costIncreases), nothingOnEmptyR200CostGroupResults(r200CostGroupResults.additionalWork) ]);
    var resultMargin_ = Calcuation_Margin.mkR200ResultMargin(incomeInfo)(r200CostGroupResults);
    var resultMargin = Data_Newtype.over()()(Types_R200.R200ResultMargin)(function (v) {
        return {
            name: "Kate",
            actual: v.actual,
            estimate: v.estimate,
            target: v.target
        };
    })(resultMargin_);
    var resultMarginPercentages = Calcuation_Margin.mkResultMarginPercentages(incomeInfo)(resultMargin_);
    var taxRelatedEntries = prepareOptionalRows([ r200CostGroupResults.taxes, (function () {
        var $93 = Data_Maybe.isJust(r200CostGroupResults.taxes);
        if ($93) {
            return new Data_Maybe.Just(r200CostGroupResults.total);
        };
        return Data_Maybe.Nothing.value;
    })() ]);
    var totalWithoutTaxes_ = r200CostGroupsResultToSummedItemColumnSpecRow(r200CostGroupResults.totalWithoutTaxes);
    var totalWithoutTaxes = {
        name: totalWithoutTaxes_.name,
        targetCosts: totalWithoutTaxes_.targetCosts,
        estimatedCosts: totalWithoutTaxes_.estimatedCosts,
        estimateRemaining: totalWithoutTaxes_.estimateRemaining,
        diffOfTargetToEstimate: totalWithoutTaxes_.diffOfTargetToEstimate,
        diffOfTargetToEstimateRatio: totalWithoutTaxes_.diffOfTargetToEstimateRatio,
        actualCosts: totalWithoutTaxes_.actualCosts,
        actualToEstimateRatio: totalWithoutTaxes_.actualToEstimateRatio,
        completionPercentage: new Data_Maybe.Just(r200CostGroupResults.totalCompletionPercentage)
    };
    return {
        project: props.project,
        resultMarginPercentages: resultMarginPercentages,
        incomeInfoItems: incomeInfoItems,
        incomeInfoItemsRows: incomeInfoItemsRows,
        costGroupsItems: costGroupsItems,
        flattenedCostGroupsItems: flattenedCostGroupsItems,
        missingCostGroupItems: missingCostGroupItems,
        optionalEntries: optionalEntries,
        totalWithoutTaxes: totalWithoutTaxes,
        taxRelatedEntries: taxRelatedEntries,
        incomeTotal: incomeTotal,
        costTotal: costTotal,
        resultMargin: resultMargin,
        costClassResults: costClassResults,
        costClassRows: costClassRows
    };
};
module.exports = {
    DontNest: DontNest,
    NestBy3Digits: NestBy3Digits,
    r200CostGroupsResultToSummedItemColumnSpecRow: r200CostGroupsResultToSummedItemColumnSpecRow,
    nothingOnEmptyR200CostGroupResults: nothingOnEmptyR200CostGroupResults,
    mkCostGroupColumnSpecRowItems: mkCostGroupColumnSpecRowItems,
    toCostGroupColumnSpecRow: toCostGroupColumnSpecRow,
    nestCostGroupUnits: nestCostGroupUnits,
    nestCostGroupUnitsByTwoLevels: nestCostGroupUnitsByTwoLevels,
    getGroupedByCriteria: getGroupedByCriteria,
    getMainCostGroupDigits: getMainCostGroupDigits,
    takeSingleDigitCode: takeSingleDigitCode,
    getThreeDigitGroupingIdentifiers: getThreeDigitGroupingIdentifiers,
    takeThreeDigitCode: takeThreeDigitCode,
    mkCostClassDisplayName: mkCostClassDisplayName,
    getR200CostClassResultName: getR200CostClassResultName,
    r200IncomeInformationResultsToIncomeColumnSpecRow: r200IncomeInformationResultsToIncomeColumnSpecRow,
    r200CostClassResultToColumns: r200CostClassResultToColumns,
    prepareFlattenedR200CostGroupColumnSpecRow: prepareFlattenedR200CostGroupColumnSpecRow,
    costGroupUnitNotEmpty: costGroupUnitNotEmpty,
    prepareR200Data: prepareR200Data,
    eqTwoLevelNesting: eqTwoLevelNesting,
    tofuJSONTwoLevelNesting: tofuJSONTwoLevelNesting
};
