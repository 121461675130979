import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import axios from "../../../axiosConfig";

const queryUrl = "/offer-page-params";

export type ProOfferParams = {
  showEconomicMargin: boolean;
  marginPercentages: Array<{ value: number; key: string }>;
  riskPercentages: Array<{ value: number; key: string }>;
  offerOverrides: Array<{ value: number; key: string }>;
};

export type OfferParams = ProOfferParams & {
  rowDescriptions: Array<{ value: string; key: string }>;
  generalPercentages: Array<{ value: number; key: string }>;
  changePercentages: Array<{ value: number; key: string }>;
  specialPercentages: Array<{ value: number; key: string }>;
  organizationId?: string;
  projectId?: number;
};

export type OfferParamsWithValue = {
  showEconomicMargin: boolean;
  values: {
    marginPercentages: Array<{ value: number; key: string }>;
    riskPercentages: Array<{ value: number; key: string }>;
    offerOverrides: Array<{ value: number; key: string }>;
    rowDescriptions: Array<{ value: string; key: string }>;
    generalPercentages: Array<{ value: number; key: string }>;
    changePercentages: Array<{ value: number; key: string }>;
    specialPercentages: Array<{ value: number; key: string }>;
  };
  organizationId?: string;
  projectId?: number;
};

export function useOfferParamsQuery(
  projectId: number
): UseQueryResult<OfferParams> {
  return useQuery<OfferParams>([queryUrl], async () => {
    const res = await axios.get<OfferParams>(`${queryUrl}/${projectId}`);
    return res.data;
  });
}

export function useSetOfferParamsQuery(
  projectId: number
): UseMutationResult<unknown, unknown, OfferParamsWithValue> {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (value: OfferParamsWithValue) => {
      const { data } = await axios.post(`${queryUrl}/${projectId}`, value);
      return data;
    },
    onSettled: () => queryClient.invalidateQueries([queryUrl]),
  });
}

const proQueryUrl = "/setOfferPageParams";
export function useProOfferParamsQuery(
  projectId: number
): UseQueryResult<OfferParams> {
  return useQuery<OfferParams>([proQueryUrl], async () => {
    const res = await axios.get<OfferParams>(
      `/project/${projectId}${proQueryUrl}`
    );
    return res.data;
  });
}

export function useSetProOfferParamsQuery(
  projectId: number
): UseMutationResult<unknown, unknown, ProOfferParams> {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (value: ProOfferParams) => {
      const { data } = await axios.post(
        `/project/${projectId}${proQueryUrl}`,
        value
      );
      return data;
    },
    onSettled: () => queryClient.invalidateQueries([proQueryUrl]),
  });
}
