import { useMutation, UseMutationResult } from "react-query";
import axiosClient from "../../../../axiosConfig";

type EditQuantityParams = {
  type: string;
  value: {
    componentId: number;
    editAction: {
      type: string;
      value: {
        quantity: number;
      };
    };
  }[];
};

export function useEditQuantity(
  projectId: number,
  onSaved: () => void
): UseMutationResult<{ componentIds: number[] }, unknown, EditQuantityParams> {
  return useMutation({
    mutationFn: async (value: EditQuantityParams) => {
      const { data } = await axiosClient.post(
        `project/${projectId}/estimationComponentAction`,
        value
      );
      return data;
    },
    onSuccess: () => {
      onSaved();
    },
  });
}
