export const AllDataSets = [
  {
    DataSourceName: "Components",
    DataSets: [
      {
        Name: "Components",
        Fields: [
          { Name: "componentCode", DataField: "componentCode" },
          { Name: "componentDescription", DataField: "componentDescription" },
          { Name: "subProject", DataField: "subProject" },
          { Name: "quantity", DataField: "quantity" },
          { Name: "unit", DataField: "unit" },
          { Name: "hours", DataField: "hours" },
          { Name: "hoursPerUnit", DataField: "hoursPerUnit" },
          { Name: "pricePerHour", DataField: "pricePerHour" },
          { Name: "pricePerUnit", DataField: "pricePerUnit" },
          { Name: "totalPrice", DataField: "totalPrice" },
          {
            Name: "totalPriceWithoutSocial",
            DataField: "totalPriceWithoutSocial",
          },
          { Name: "costGroupCode", DataField: "costGroupCode" },
          { Name: "costGroupDescription", DataField: "costGroupDescription" },
          { Name: "locations", DataField: "locations" },
          { Name: "flagged", DataField: "flagged" },
          { Name: "checked", DataField: "checked" },
          { Name: "memo", DataField: "memo" },
          { Name: "costClasses", DataField: "costClasses" },
          { Name: "totalSocialExpenses", DataField: "totalSocialExpenses" },
          { Name: "componentResources", DataField: "componentResources" },
          { Name: "elements", DataField: "elements" },
          { Name: "measurements", DataField: "measurements" },
          {
            Name: "CalculatedTotal",
            Value:
              "=IIF(Parameters!social.Value, Fields!totalPrice.Value, Fields!totalPriceWithoutSocial.Value)",
          },
          {
            Name: "CalculatedPricePerHour",
            Value:
              "=Code.zeroDivide(Fields!CalculatedTotal.Value, Fields!hours.Value)",
          },
          {
            Name: "CalculatedPricePerUnit",
            Value:
              "=Code.zeroDivide(Fields!CalculatedTotal.Value, Fields!quantity.Value)",
          },
        ],
        Query: {
          DataSourceName: "ComponentsJSON",
          CommandText: "jpath=$.[*]",
        },
        CaseSensitivity: "Auto",
        KanatypeSensitivity: "Auto",
        AccentSensitivity: "Auto",
        WidthSensitivity: "Auto",
      },
      {
        Name: "locations",
        Fields: [{ Name: "Field1", DataField: "Field1" }],
        Query: { DataSourceName: "$dataset:Components/locations" },
        CaseSensitivity: "Auto",
        KanatypeSensitivity: "Auto",
        AccentSensitivity: "Auto",
        WidthSensitivity: "Auto",
      },
      {
        Name: "costClasses",
        Fields: [
          { Name: "ccCode", DataField: "costClassCode" },
          { Name: "ccHours", DataField: "hours" },
          { Name: "ccTotalPrice", DataField: "totalPrice" },
          {
            Name: "ccTotalPriceWithoutSocialExpenses",
            DataField: "totalPriceWithoutSocialExpenses",
          },
          { Name: "ccHourlyPricing", DataField: "hourlyPricing" },
          { Name: "ccHultiplier", DataField: "multiplier" },
          { Name: "ccSocialPercentage", DataField: "socialPercentage" },
          { Name: "ccSocialExpenses", DataField: "socialExpenses" },
        ],
        Query: { DataSourceName: "$dataset:Components/costClasses" },
        CaseSensitivity: "Auto",
        KanatypeSensitivity: "Auto",
        AccentSensitivity: "Auto",
        WidthSensitivity: "Auto",
      },
      {
        Name: "componentResources",
        Fields: [
          { Name: "id", DataField: "id" },
          { Name: "orgResourcePriceId", DataField: "orgResourcePriceId" },
          {
            Name: "orgResourcePriceListId",
            DataField: "orgResourcePriceListId",
          },
          {
            Name: "estimationComponentId",
            DataField: "estimationComponentId",
          },
          { Name: "costClassCode", DataField: "costClassCode" },
          { Name: "name", DataField: "name" },
          { Name: "unitConsumptionRate", DataField: "unitConsumptionRate" },
          { Name: "wastePercentage", DataField: "wastePercentage" },
          { Name: "discountPercentage", DataField: "discountPercentage" },
          { Name: "taxPercentage", DataField: "taxPercentage" },
          { Name: "multiplier", DataField: "multiplier" },
          { Name: "isCostClassPrice", DataField: "isCostClassPrice" },
          { Name: "unit", DataField: "unit" },
          { Name: "pricePerUnit", DataField: "pricePerUnit" },
          { Name: "group", DataField: "group" },
          { Name: "usagesCount", DataField: "usagesCount" },
          { Name: "projectId", DataField: "projectId" },
          {
            Name: "resourceClassificationGroup",
            DataField: "resourceClassificationGroup",
          },
          { Name: "sortingNumber", DataField: "sortingNumber" },
          { Name: "priceWithDiscount", DataField: "priceWithDiscount" },
          { Name: "currencyId", DataField: "currencyId" },
          { Name: "discountGroupCode", DataField: "discountGroupCode" },
          { Name: "contract", DataField: "contract" },
          { Name: "validDate", DataField: "validDate" },
          { Name: "changeDate", DataField: "changeDate" },
          { Name: "eanCode", DataField: "eanCode" },
          { Name: "index", DataField: "index" },
          { Name: "vendor", DataField: "vendor" },
          { Name: "vendorCode", DataField: "vendorCode" },
          { Name: "vendorUnit", DataField: "vendorUnit" },
          { Name: "vendorBatchSize", DataField: "vendorBatchSize" },
        ],
        Query: { DataSourceName: "$dataset:Components/componentResources" },
        CaseSensitivity: "Auto",
        KanatypeSensitivity: "Auto",
        AccentSensitivity: "Auto",
        WidthSensitivity: "Auto",
      },
      {
        Name: "elements",
        Fields: [
          { Name: "elementClass", DataField: "elementClass" },
          { Name: "elementDescription", DataField: "elementDescription" },
          { Name: "elementAmount", DataField: "elementAmount" },
          { Name: "elementUnit", DataField: "elementUnit" },
          { Name: "elementTotalPrice", DataField: "elementTotalPrice" },
          {
            Name: "elementTotalPriceByCostClasses",
            DataField: "elementTotalPriceByCostClasses",
          },
          { Name: "elementMemo", DataField: "elementMemo" },
          {
            Name: "elementResources.componentReferenceCount",
            DataField: "elementResources.componentReferenceCount",
          },
          {
            Name: "elementResources.resourcesByComponent.resources",
            DataField: "elementResources.resourcesByComponent.resources",
          },
          {
            Name: "elementResources.resourcesByComponent.component.id",
            DataField: "elementResources.resourcesByComponent.component.id",
          },
          {
            Name:
              "elementResources.resourcesByComponent.component.componentType",
            DataField:
              "elementResources.resourcesByComponent.component.componentType",
          },
          {
            Name:
              "elementResources.resourcesByComponent.component.subProjectId",
            DataField:
              "elementResources.resourcesByComponent.component.subProjectId",
          },
          {
            Name:
              "elementResources.resourcesByComponent.component.subProjectCode",
            DataField:
              "elementResources.resourcesByComponent.component.subProjectCode",
          },
          {
            Name:
              "elementResources.resourcesByComponent.component.subProjectName",
            DataField:
              "elementResources.resourcesByComponent.component.subProjectName",
          },
          {
            Name: "elementResources.resourcesByComponent.component.checked",
            DataField:
              "elementResources.resourcesByComponent.component.checked",
          },
          {
            Name: "elementResources.resourcesByComponent.component.flagged",
            DataField:
              "elementResources.resourcesByComponent.component.flagged",
          },
          {
            Name: "elementResources.resourcesByComponent.component.group",
            DataField: "elementResources.resourcesByComponent.component.group",
          },
          {
            Name:
              "elementResources.resourcesByComponent.component.costGroupCode",
            DataField:
              "elementResources.resourcesByComponent.component.costGroupCode",
          },
          {
            Name:
              "elementResources.resourcesByComponent.component.costGroupDescription",
            DataField:
              "elementResources.resourcesByComponent.component.costGroupDescription",
          },
          {
            Name: "elementResources.resourcesByComponent.component.code",
            DataField: "elementResources.resourcesByComponent.component.code",
          },
          {
            Name: "elementResources.resourcesByComponent.component.classCode",
            DataField:
              "elementResources.resourcesByComponent.component.classCode",
          },
          {
            Name: "elementResources.resourcesByComponent.component.description",
            DataField:
              "elementResources.resourcesByComponent.component.description",
          },
          {
            Name: "elementResources.resourcesByComponent.component.unit",
            DataField: "elementResources.resourcesByComponent.component.unit",
          },
          {
            Name: "elementResources.resourcesByComponent.component.amount",
            DataField: "elementResources.resourcesByComponent.component.amount",
          },
          {
            Name: "elementResources.resourcesByComponent.component.memo",
            DataField: "elementResources.resourcesByComponent.component.memo",
          },
          {
            Name: "elementResources.resourcesByComponent.projectOfOrigin",
            DataField: "elementResources.resourcesByComponent.projectOfOrigin",
          },
          {
            Name: "elementResources.unit",
            DataField: "elementResources.unit",
          },
          {
            Name: "elementResources.formula",
            DataField: "elementResources.formula",
          },
          {
            Name: "elementResources.spec.elementSpecId",
            DataField: "elementResources.spec.elementSpecId",
          },
          {
            Name: "elementResources.spec.elementId",
            DataField: "elementResources.spec.elementId",
          },
          {
            Name: "elementResources.spec.componentId",
            DataField: "elementResources.spec.componentId",
          },
          {
            Name: "elementResources.spec.formula",
            DataField: "elementResources.spec.formula",
          },
          {
            Name: "elementResources.spec.unitConsumptionRate",
            DataField: "elementResources.spec.unitConsumptionRate",
          },
          {
            Name: "elementResources.spec.amountSource",
            DataField: "elementResources.spec.amountSource",
          },
          {
            Name: "elementResources.group",
            DataField: "elementResources.group",
          },
          {
            Name: "elementResources.description",
            DataField: "elementResources.description",
          },
          {
            Name: "elementResources.unitPrice",
            DataField: "elementResources.unitPrice",
          },
          {
            Name: "elementResources.elementUnitPrice",
            DataField: "elementResources.elementUnitPrice",
          },
          {
            Name: "elementResources.consumptionRate",
            DataField: "elementResources.consumptionRate",
          },
          {
            Name: "elementResources.amount",
            DataField: "elementResources.amount",
          },
          {
            Name: "elementResources.totalPrice",
            DataField: "elementResources.totalPrice",
          },
          { Name: "elementRtCode", DataField: "elementRtCode" },
          { Name: "elementRtDescription", DataField: "elementRtDescription" },
        ],
        Query: { DataSourceName: "$dataset:Components/elements" },
        CaseSensitivity: "Auto",
        KanatypeSensitivity: "Auto",
        AccentSensitivity: "Auto",
        WidthSensitivity: "Auto",
      },
      {
        Name: "measurements",
        Fields: [
          { Name: "values", DataField: "values" },
          { Name: "unit.unit", DataField: "unit.unit" },
          { Name: "unit.name", DataField: "unit.name" },
          { Name: "unit.projectId", DataField: "unit.projectId" },
          { Name: "unit.mandatory", DataField: "unit.mandatory" },
          { Name: "unit.id", DataField: "unit.id" },
        ],
        Query: { DataSourceName: "$dataset:Components/measurements" },
        CaseSensitivity: "Auto",
        KanatypeSensitivity: "Auto",
        AccentSensitivity: "Auto",
        WidthSensitivity: "Auto",
      },
      {
        Name: "measurementValues",
        Fields: [
          { Name: "value", DataField: "value" },
          { Name: "measurementId", DataField: "measurementId" },
          { Name: "subprojectId", DataField: "subprojectId" },
          { Name: "id", DataField: "id" },
        ],
        Query: { DataSourceName: "$dataset:measurements/values" },
        CaseSensitivity: "Auto",
        KanatypeSensitivity: "Auto",
        AccentSensitivity: "Auto",
        WidthSensitivity: "Auto",
      },
    ],
  },
  {
    DataSourceName: "ProjectDetails",
    DataSets: [
      {
        Name: "ProjectDetails",
        Fields: [{ Name: "projectName", DataField: "name" }],
        Query: {
          DataSourceName: "ProjectDetailsJSON",
          CommandText: "jpath=$",
        },
        CaseSensitivity: "Auto",
        KanatypeSensitivity: "Auto",
        AccentSensitivity: "Auto",
        WidthSensitivity: "Auto",
      },
    ],
  },
  {
    DataSourceName: "SubProjects",
    DataSets: [
      {
        Name: "SubProjects",
        Fields: [
          { Name: "subProjectCode", DataField: "code" },
          { Name: "subProjectName", DataField: "name" },
        ],
        Query: {
          DataSourceName: "SubProjectsJSON",
          CommandText: "jpath=$.[*]",
        },
        CaseSensitivity: "Auto",
        KanatypeSensitivity: "Auto",
        AccentSensitivity: "Auto",
        WidthSensitivity: "Auto",
      },
    ],
  },
  {
    DataSourceName: "Locations",
    DataSets: [
      {
        Name: "Locations",
        Fields: [
          { Name: "id", DataField: "id" },
          { Name: "code", DataField: "code" },
          { Name: "description", DataField: "description" },
          { Name: "sortingNumber", DataField: "sortingNumber" },
          { Name: "scale", DataField: "scale" },
          { Name: "projectId", DataField: "projectId" },
        ],
        Query: {
          DataSourceName: "LocationsJSON",
          CommandText: "jpath=$.[*]",
        },
        CaseSensitivity: "Auto",
        KanatypeSensitivity: "Auto",
        AccentSensitivity: "Auto",
        WidthSensitivity: "Auto",
      },
    ],
  },
  {
    DataSourceName: "CostClasses",
    DataSets: [
      {
        Name: "CostClasses",
        Query: {
          DataSourceName: "CostClassesJSON",
          CommandText: "jpath=$.[*]",
        },
        Fields: [
          { Name: "id", DataField: "id" },
          { Name: "name", DataField: "name" },
          { Name: "costClassCode", DataField: "costClassCode" },
          { Name: "projectId", DataField: "projectId" },
          {
            Name: "socialExpensePercentageInCostEstimation",
            DataField: "socialExpensePercentageInCostEstimation",
          },
          {
            Name: "socialExpensePercentageInCostControlForEstimatesAndActuals",
            DataField:
              "socialExpensePercentageInCostControlForEstimatesAndActuals",
          },
          {
            Name: "socialExpensePercentageInCostControlForComponents",
            DataField: "socialExpensePercentageInCostControlForComponents",
          },
          {
            Name: "socialExpensePercentageInCostControlForTargets",
            DataField: "socialExpensePercentageInCostControlForTargets",
          },
          { Name: "multiplier", DataField: "multiplier" },
          { Name: "hourlyPricing", DataField: "hourlyPricing" },
        ],
      },
    ],
  },
  {
    DataSourceName: "Elements",
    DataSets: [
      {
        Name: "Elements",
        Query: { DataSourceName: "ElementsJSON", CommandText: "jpath=$.[*]" },
        Fields: [
          { Name: "element.elementId", DataField: "element.elementId" },
          { Name: "element.subprojectId", DataField: "element.subprojectId" },
          { Name: "element.code", DataField: "element.code" },
          { Name: "element.description", DataField: "element.description" },
          { Name: "element.unit", DataField: "element.unit" },
          { Name: "element.groupFlag", DataField: "element.groupFlag" },
          { Name: "element.memo", DataField: "element.memo" },
          { Name: "element.rtCode", DataField: "element.rtCode" },
          { Name: "element.rtDescription", DataField: "element.rtDescription" },
          { Name: "subprojectCode", DataField: "subprojectCode" },
          { Name: "projectOfOrigin", DataField: "projectOfOrigin" },
          { Name: "specs", DataField: "specs" },
          { Name: "locations", DataField: "locations" },
          { Name: "components", DataField: "components" },
          { Name: "amount", DataField: "amount" },
          { Name: "unitPrice", DataField: "unitPrice" },
          { Name: "totalPrice", DataField: "totalPrice" },
          {
            Name: "totalPriceByCostClasses",
            DataField: "totalPriceByCostClasses",
          },
          { Name: "isTopicRow", DataField: "isTopicRow" },
        ],
      },
    ],
  },
  {
    DataSourceName: "Measurements",
    DataSets: [
      {
        Name: "Measurements",
        Query: {
          DataSourceName: "MeasurementsJSON",
          CommandText: "jpath=$.[*]",
        },
        Fields: [
          { Name: "values", DataField: "values" },
          { Name: "unit.unit", DataField: "unit.unit" },
          { Name: "unit.name", DataField: "unit.name" },
          { Name: "unit.projectId", DataField: "unit.projectId" },
          { Name: "unit.mandatory", DataField: "unit.mandatory" },
          { Name: "unit.id", DataField: "unit.id" },
        ],
      },
      {
        Name: "values",
        Fields: [
          { Name: "value", DataField: "value" },
          { Name: "measurementId", DataField: "measurementId" },
          { Name: "subprojectId", DataField: "subprojectId" },
          { Name: "id", DataField: "id" },
        ],
        Query: { DataSourceName: "$dataset:Measurements/values" },
        CaseSensitivity: "Auto",
        KanatypeSensitivity: "Auto",
        AccentSensitivity: "Auto",
        WidthSensitivity: "Auto",
      },
    ],
  },
];
