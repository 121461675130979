// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoTable_CellFormatting = require("../TacoTable.CellFormatting/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Type_Proxy = require("../Type.Proxy/index.js");
var Utils_ClassNames = require("../Utils.ClassNames/index.js");
var numericClassName = function (v) {
    if (v instanceof TacoTable_Types.PlainNumberCell) {
        return new Data_Maybe.Just("numeric");
    };
    if (v instanceof TacoTable_Types.MaybePlainNumberCell) {
        return new Data_Maybe.Just("numeric");
    };
    if (v instanceof TacoTable_Types.PlainEuroCell) {
        return new Data_Maybe.Just("numeric");
    };
    if (v instanceof TacoTable_Types.MaybePlainEuroCell) {
        return new Data_Maybe.Just("numeric");
    };
    if (v instanceof TacoTable_Types.ColoredEuroCell) {
        return new Data_Maybe.Just("numeric");
    };
    if (v instanceof TacoTable_Types.PlainCurrencyCell) {
        return new Data_Maybe.Just("numeric");
    };
    if (v instanceof TacoTable_Types.MaybePlainCurrencyCell) {
        return new Data_Maybe.Just("numeric");
    };
    if (v instanceof TacoTable_Types.JSXCell) {
        var $18 = Data_Foldable.elem(Data_Foldable.foldableArray)(TacoTable_Types.eqJSXCellProp)(TacoTable_Types.Numeric.value)(v.value0);
        if ($18) {
            return new Data_Maybe.Just("numeric");
        };
        return Data_Maybe.Nothing.value;
    };
    if (v instanceof TacoTable_Types.PlainPercentageCell) {
        return new Data_Maybe.Just("numeric");
    };
    if (v instanceof TacoTable_Types.MaybePlainPercentageCell) {
        return new Data_Maybe.Just("numeric");
    };
    if (v instanceof TacoTable_Types.ColoredPercentageCell) {
        return new Data_Maybe.Just("numeric");
    };
    if (v instanceof TacoTable_Types.ColoredEuroAndPercentCell) {
        return new Data_Maybe.Just("numeric");
    };
    if (v instanceof TacoTable_Types.PlainTextCell) {
        return Data_Maybe.Nothing.value;
    };
    if (v instanceof TacoTable_Types.PlainDateStringCell) {
        return Data_Maybe.Nothing.value;
    };
    if (v instanceof TacoTable_Types.PlainDateTimeStringCell) {
        return Data_Maybe.Nothing.value;
    };
    if (v instanceof TacoTable_Types.PlainDateCell) {
        return Data_Maybe.Nothing.value;
    };
    if (v instanceof TacoTable_Types.FoldingIndicatorPlaceholder) {
        return Data_Maybe.Nothing.value;
    };
    throw new Error("Failed pattern match at TacoTable.Cells (line 109, column 1 - line 109, column 70): " + [ v.constructor.name ]);
};
var headerCell = function (dictGetName) {
    return function (dictEq) {
        return TofuHooks.mkHookComponent("HeaderCell" + TofuHooks.getName(dictGetName)(Type_Proxy["Proxy"].value))(function (v) {
            var sortable = Data_Maybe.isJust(v.column.sortProperty);
            var numeric = Data_Maybe.isJust(numericClassName(v.column.cell));
            var isCellSortedBy = sortable && Data_Eq.eq(Data_Maybe.eqMaybe(dictEq))(v.sortProperty)(v.column.sortProperty);
            var icon = (function () {
                var $34 = !sortable;
                if ($34) {
                    return Data_Maybe.Nothing.value;
                };
                if (isCellSortedBy) {
                    return new Data_Maybe.Just((function () {
                        var $36 = Data_Eq.eq(TacoTable_Types.eqSortingOrder)(v.sortingOrder)(TacoTable_Types.Descending.value);
                        if ($36) {
                            return TacoIcon_Icons_Types.IconSortIndicatorActiveV1.value;
                        };
                        return TacoIcon_Icons_Types.IconSortIndicatorActiveReversedV1.value;
                    })());
                };
                return Data_Maybe.Nothing.value;
            })();
            return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_Emotion.element(React_Basic_DOM_Generated["span'"]())({
                className: "header-cell",
                css: v.styleSet(v.column.width),
                children: [ (function () {
                    if (v.column.headerJSX instanceof Data_Maybe.Just) {
                        return v.column.headerJSX.value0;
                    };
                    if (v.column.headerJSX instanceof Data_Maybe.Nothing) {
                        return TacoButton.component()()({
                            icon: icon,
                            iconAlign: (function () {
                                if (numeric) {
                                    return TacoButton_Types.IconLeft.value;
                                };
                                return TacoButton_Types.IconRight.value;
                            })(),
                            iconColor: new Data_Maybe.Just(TacoConstants.ButtonTextPrimary.value),
                            buttonStyle: TacoButton_Types.Link.value,
                            color: (function () {
                                if (isCellSortedBy) {
                                    return TacoConstants.ButtonTextPrimary.value;
                                };
                                return TacoConstants.TextSecondary.value;
                            })(),
                            align: (function () {
                                if (numeric) {
                                    return TacoButton_Types.Right.value;
                                };
                                return TacoButton_Types.Left.value;
                            })(),
                            text: "",
                            children: new Data_Maybe.Just([ v.column.label ]),
                            onClick: new Data_Maybe.Just(Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(v.onClick)(v.column.sortProperty)),
                            buttonOverflow: true,
                            testId: "header-" + (TofuHooks.getName(dictGetName)(Type_Proxy["Proxy"].value) + ("-" + v.column.key))
                        });
                    };
                    throw new Error("Failed pattern match at TacoTable.Cells (line 64, column 11 - line 80, column 16): " + [ v.column.headerJSX.constructor.name ]);
                })(), v.separator ]
            }));
        });
    };
};
var dataCell = function (dictEq) {
    return TofuHooks.mkHookComponent("DataCell")(function (v) {
        var mkCell = (function () {
            if (v.column.cell instanceof TacoTable_Types.FoldingIndicatorPlaceholder) {
                return React_Basic_Emotion.element(React_Basic_DOM_Generated["span'"]())({
                    className: "folding-indicator",
                    css: TacoTable_Styles.foldingIndicatorStyles
                });
            };
            return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                className: "cell-value",
                css: v.styleSet.cellValueStyles(v.column.cell)(v.rowHeight),
                title: TacoTable_CellFormatting.extractCellText(v.column.cell)(v.rowData),
                children: [ TacoTable_CellFormatting.formatCellValue(v.column.cell)(v.rowData) ]
            });
        })();
        return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: Utils_ClassNames.mkClassName([ new Data_Maybe.Just("cell"), numericClassName(v.column.cell), Data_Monoid.guard(Data_Maybe.monoidMaybe(Data_Semigroup.semigroupString))(Data_Eq.eq(Data_Maybe.eqMaybe(dictEq))(v.sortProperty)(v.column.sortProperty))(new Data_Maybe.Just("sorted-by")) ]),
            css: v.styleSet.cellStyles(v.column.width),
            children: [ mkCell ]
        }));
    });
};
var classNames = (function () {
    var $60 = Data_String_Common.joinWith(" ");
    return function ($61) {
        return Data_String_Common.trim($60($61));
    };
})();
module.exports = {
    headerCell: headerCell,
    dataCell: dataCell,
    classNames: classNames,
    numericClassName: numericClassName
};
