// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoText = require("../TacoText/index.js");
var elementControl = function (dictEq) {
    return function (getLabel) {
        return function (state) {
            return function (setState) {
                return function (value) {
                    var checked = Data_Array.elem(dictEq)(value)(state);
                    var checkbox = TacoCheckbox.component()()({
                        className: "toggle",
                        isChecked: checked,
                        onToggle: TacoCheckbox.NoEvent.create((function () {
                            if (checked) {
                                return setState(Data_Array["delete"](dictEq)(value));
                            };
                            return setState(Data_Array.cons(value));
                        })()),
                        labelText: Data_Maybe.Just.create(TacoCheckbox.StringLabel.create(getLabel(value))),
                        testId: "column-selector-" + getLabel(value)
                    });
                    return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                        className: "elementControl",
                        css: Box.boxStyle([  ]),
                        children: [ checkbox ]
                    });
                };
            };
        };
    };
};
var columnSelector = function (dictEq) {
    return function (allValues) {
        return function (getLabel) {
            return function (state) {
                return function (setState) {
                    var description = TacoText.component()()({
                        text: "Valitse n\xe4kyvill\xe4 olevat sarakkeet:"
                    });
                    var controls = Data_Functor.map(Data_Functor.functorArray)(elementControl(dictEq)(getLabel)(state)(setState))(allValues);
                    return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                        className: "columnSelector",
                        children: Data_Semigroup.append(Data_Semigroup.semigroupArray)([ description ])(controls),
                        css: React_Basic_Emotion.css()({})
                    });
                };
            };
        };
    };
};
module.exports = {
    columnSelector: columnSelector,
    elementControl: elementControl
};
