// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var CostGroupDetails_Styles = require("../CostGroupDetails.Styles/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Array_NonEmpty_Internal = require("../Data.Array.NonEmpty.Internal/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Formatters = require("../Formatters/index.js");
var Gap = require("../Gap/index.js");
var Locale = require("../Locale/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_DOM_Internal = require("../React.Basic.DOM.Internal/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var TacoAutocomplete = require("../TacoAutocomplete/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Row = require("../TacoTable.Row/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_CostGroup = require("../Types.CostGroup/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Utils = require("../Utils/index.js");
var ByCostClass = (function () {
    function ByCostClass() {

    };
    ByCostClass.value = new ByCostClass();
    return ByCostClass;
})();
var ByResourceChecked = (function () {
    function ByResourceChecked() {

    };
    ByResourceChecked.value = new ByResourceChecked();
    return ByResourceChecked;
})();
var ByName = (function () {
    function ByName() {

    };
    ByName.value = new ByName();
    return ByName;
})();
var ByValue = (function () {
    function ByValue() {

    };
    ByValue.value = new ByValue();
    return ByValue;
})();
var ByResourceUnit = (function () {
    function ByResourceUnit() {

    };
    ByResourceUnit.value = new ByResourceUnit();
    return ByResourceUnit;
})();
var ByResourcePricePerUnit = (function () {
    function ByResourcePricePerUnit() {

    };
    ByResourcePricePerUnit.value = new ByResourcePricePerUnit();
    return ByResourcePricePerUnit;
})();
var ByAmountResource = (function () {
    function ByAmountResource() {

    };
    ByAmountResource.value = new ByAmountResource();
    return ByAmountResource;
})();
var ByCode = (function () {
    function ByCode() {

    };
    ByCode.value = new ByCode();
    return ByCode;
})();
var ByComponentChecked = (function () {
    function ByComponentChecked() {

    };
    ByComponentChecked.value = new ByComponentChecked();
    return ByComponentChecked;
})();
var ByDesc = (function () {
    function ByDesc() {

    };
    ByDesc.value = new ByDesc();
    return ByDesc;
})();
var ByAmount = (function () {
    function ByAmount() {

    };
    ByAmount.value = new ByAmount();
    return ByAmount;
})();
var ByUnit = (function () {
    function ByUnit() {

    };
    ByUnit.value = new ByUnit();
    return ByUnit;
})();
var ByPricePerUnit = (function () {
    function ByPricePerUnit() {

    };
    ByPricePerUnit.value = new ByPricePerUnit();
    return ByPricePerUnit;
})();
var BySum = (function () {
    function BySum() {

    };
    BySum.value = new BySum();
    return BySum;
})();
var BySubprojectCode = (function () {
    function BySubprojectCode() {

    };
    BySubprojectCode.value = new BySubprojectCode();
    return BySubprojectCode;
})();
var sortResources = function (v) {
    return function (r) {
        if (v instanceof ByCostClass) {
            return TacoTable_Types.StringValue.create(Data_Maybe.maybe("")(Data_Newtype.unwrap())(r.costClassCode));
        };
        if (v instanceof ByResourceChecked) {
            return new TacoTable_Types.BooleanValue(r.checked);
        };
        if (v instanceof ByName) {
            return TacoTable_Types.StringValue.create(r.name);
        };
        if (v instanceof ByAmountResource) {
            return TacoTable_Types.NumberValue.create(Data_Maybe.fromMaybe(0.0)(r.amount));
        };
        if (v instanceof ByResourceUnit) {
            return TacoTable_Types.StringValue.create(r.unit);
        };
        if (v instanceof ByResourcePricePerUnit) {
            return TacoTable_Types.NumberValue.create(Data_Maybe.fromMaybe(0.0)(r.pricePerUnit));
        };
        if (v instanceof ByValue) {
            return TacoTable_Types.NumberValue.create(Data_Maybe.fromMaybe(0.0)(r.value));
        };
        throw new Error("Failed pattern match at CostGroupDetails.ComponentsTab (line 109, column 1 - line 109, column 84): " + [ v.constructor.name, r.constructor.name ]);
    };
};
var sortComponentTabTableData = function (v) {
    return function (r) {
        if (v instanceof ByCode) {
            return new TacoTable_Types.StringValue(r.code);
        };
        if (v instanceof ByComponentChecked) {
            return new TacoTable_Types.BooleanValue(r.checked);
        };
        if (v instanceof ByDesc) {
            return new TacoTable_Types.StringValue(r.description);
        };
        if (v instanceof BySum) {
            return new TacoTable_Types.NumberValue(r.sum);
        };
        if (v instanceof ByAmount) {
            return new TacoTable_Types.NumberValue(r.amount);
        };
        if (v instanceof ByUnit) {
            return new TacoTable_Types.StringValue(r.unit);
        };
        if (v instanceof ByPricePerUnit) {
            return new TacoTable_Types.NumberValue(r.pricePerUnit);
        };
        if (v instanceof BySubprojectCode) {
            return new TacoTable_Types.StringValue(r.subprojectCode);
        };
        throw new Error("Failed pattern match at CostGroupDetails.ComponentsTab (line 69, column 1 - line 69, column 100): " + [ v.constructor.name, r.constructor.name ]);
    };
};
var mkTableResourceData = function (checked) {
    return function (v) {
        return {
            resourceId: v.id,
            costClassCode: v.costClassCode,
            componentId: v.componentId,
            checked: Data_Set.member(Types_CostGroup.ordResourceId)(v.id)(checked),
            name: v.name,
            value: v.value,
            amount: v.amount,
            unit: Data_Maybe.fromMaybe("")(v.unit),
            pricePerUnit: v.pricePerUnit
        };
    };
};
var mkComponentTabTableData = function (components) {
    return function (resources) {
        var head = Data_Array_NonEmpty.head(resources);
        return Control_Bind.bind(Data_Maybe.bindMaybe)(head.componentId)(function (componentId) {
            return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.find((function () {
                var $63 = Data_Eq.eq(Data_Maybe.eqMaybe(Types_Component.eqComponentId))(new Data_Maybe.Just(componentId));
                var $64 = Data_Newtype.un()(Types_Component.ReportingComponent);
                return function ($65) {
                    return $63((function (v) {
                        return v.componentId;
                    })($64($65)));
                };
            })())(components))(function (v) {
                return Control_Bind.bind(Data_Maybe.bindMaybe)(v.code)(function (code) {
                    return Control_Bind.bind(Data_Maybe.bindMaybe)(v.unit)(function (unit) {
                        return Control_Bind.bind(Data_Maybe.bindMaybe)(v.amount)(function (amount) {
                            var sumCost = Data_Foldable.sum(Data_Array_NonEmpty_Internal.foldableNonEmptyArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)((function () {
                                var $66 = Data_Maybe.fromMaybe(0.0);
                                return function ($67) {
                                    return $66((function (v1) {
                                        return v1.value;
                                    })($67));
                                };
                            })())(resources));
                            var pricePerUnit = Utils.defaultZeroDiv(sumCost)(amount);
                            return Control_Applicative.pure(Data_Maybe.applicativeMaybe)({
                                code: code,
                                checked: Data_Foldable.all(Data_Array_NonEmpty_Internal.foldableNonEmptyArray)(Data_HeytingAlgebra.heytingAlgebraBoolean)(function (v1) {
                                    return v1.checked;
                                })(resources),
                                componentId: componentId,
                                description: v.description,
                                sum: sumCost,
                                amount: amount,
                                unit: unit,
                                pricePerUnit: pricePerUnit,
                                resourcesData: resources,
                                subprojectCode: v.subprojectCode
                            });
                        });
                    });
                });
            });
        });
    };
};
var groupResources = function (checkedResources) {
    return function (components) {
        return function (resources) {
            var groupedResources = Data_Array.groupBy(Data_Function.on(Data_Eq.eq(Data_Maybe.eqMaybe(Types_Component.eqComponentId)))((function () {
                var $68 = Data_Newtype.unwrap();
                return function ($69) {
                    return (function (v) {
                        return v.componentId;
                    })($68($69));
                };
            })()))(Data_Array.sortWith(Data_Maybe.ordMaybe(Types_Component.ordComponentId))((function () {
                var $70 = Data_Newtype.unwrap();
                return function ($71) {
                    return (function (v) {
                        return v.componentId;
                    })($70($71));
                };
            })())(resources));
            return Data_Array.mapMaybe(mkComponentTabTableData(components))(Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)(mkTableResourceData(checkedResources)))(groupedResources));
        };
    };
};
var getNameResourceTabTableSortProps = {
    getName: function (v) {
        return "ResourceTabTableSortProps";
    }
};
var getNameComponentTabTableSortProps = {
    getName: function (v) {
        return "ComponentTabTableSortProps";
    }
};
var eqResourceTabTableSortProps = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ByCostClass && y instanceof ByCostClass) {
                return true;
            };
            if (x instanceof ByResourceChecked && y instanceof ByResourceChecked) {
                return true;
            };
            if (x instanceof ByName && y instanceof ByName) {
                return true;
            };
            if (x instanceof ByValue && y instanceof ByValue) {
                return true;
            };
            if (x instanceof ByResourceUnit && y instanceof ByResourceUnit) {
                return true;
            };
            if (x instanceof ByResourcePricePerUnit && y instanceof ByResourcePricePerUnit) {
                return true;
            };
            if (x instanceof ByAmountResource && y instanceof ByAmountResource) {
                return true;
            };
            return false;
        };
    }
};
var eqComponentTabTableSortProps = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ByCode && y instanceof ByCode) {
                return true;
            };
            if (x instanceof ByComponentChecked && y instanceof ByComponentChecked) {
                return true;
            };
            if (x instanceof ByDesc && y instanceof ByDesc) {
                return true;
            };
            if (x instanceof ByAmount && y instanceof ByAmount) {
                return true;
            };
            if (x instanceof ByUnit && y instanceof ByUnit) {
                return true;
            };
            if (x instanceof ByPricePerUnit && y instanceof ByPricePerUnit) {
                return true;
            };
            if (x instanceof BySum && y instanceof BySum) {
                return true;
            };
            if (x instanceof BySubprojectCode && y instanceof BySubprojectCode) {
                return true;
            };
            return false;
        };
    }
};
var componentsTabTableInstance = TacoTable.mkTableSortable(getNameComponentTabTableSortProps)("ComponentsTab");
var componentTabTableStyleSet = TacoTable_Styles.foldingTableStyleSet;
var prepareComponentTabProperties = function (props) {
    var sumOfSelectedComponent = (function () {
        var sumOfSelected = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)((function () {
            var $72 = Data_Maybe.fromMaybe(0.0);
            var $73 = Data_Newtype.unwrap();
            return function ($74) {
                return $72((function (v) {
                    return v.value;
                })($73($74)));
            };
        })())(Data_Array.filter(function (x) {
            return Data_Set.member(Types_CostGroup.ordResourceId)((Data_Newtype.unwrap()(x)).id)(props.selectedResources);
        })(props.resources)));
        var sumOfAllResources = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)((function () {
            var $75 = Data_Maybe.fromMaybe(0.0);
            var $76 = Data_Newtype.unwrap();
            return function ($77) {
                return $75((function (v) {
                    return v.value;
                })($76($77)));
            };
        })())(props.resources));
        var formattedSum = Formatters.formatCurrencyValue("EUR")(sumOfAllResources);
        var sumDiv = React_Basic_DOM_Generated.div()({
            className: "sum-of-targets",
            children: [ TacoText.component()()({
                text: Locale.lookup_("cost_control_sum_of_targets") + ": "
            }), TacoText.component()()({
                text: formattedSum,
                weight: TacoText_Types.Bold.value
            }) ]
        });
        var formattedSelectedSum = Formatters.formatCurrencyValue("EUR")(sumOfSelected);
        var selectedDiv = React_Basic_DOM_Generated.div()({
            className: "sum-of-selected-targets",
            children: [ TacoText.component()()({
                text: Locale.lookup_("cost_control_sum_of_selected") + ": "
            }), TacoText.component()()({
                text: formattedSelectedSum,
                weight: TacoText_Types.Bold.value
            }) ]
        });
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "target-sums",
            css: CostGroupDetails_Styles.targetSumsStyles,
            children: [ Gap.gapX(TacoConstants.XL.value)([ selectedDiv, sumDiv ]) ]
        });
    })();
    var foldingIndicatorPlaceholderColumn = {
        key: "placeholder",
        label: React_Basic_DOM.text(""),
        cell: TacoTable_Types.FoldingIndicatorPlaceholder.value,
        sortProperty: new Data_Maybe.Just(ByCostClass.value),
        width: new TacoTable_Types.Flex(0),
        headerJSX: Data_Maybe.Nothing.value
    };
    var resourceColumns = (function () {
        var resourceCheckBox = function (r) {
            return TacoCheckbox.component()()({
                className: "",
                isChecked: r.checked,
                onToggle: TacoCheckbox.NoEvent.create(props.setSelectedResources(function (selectedResources) {
                    return Utils.toggleInSet(Types_CostGroup.ordResourceId)(r.resourceId)(selectedResources);
                })),
                labelText: Data_Maybe.Nothing.value,
                testId: "resource-" + Data_Show.show(Types_CostGroup.showResourceId)(r.resourceId)
            });
        };
        var costClassCell = function (r) {
            var v = function (v1) {
                if (r.costClassCode instanceof Data_Maybe.Just) {
                    return Data_Newtype.un()(Types_Newtypes.CostClassCode)(r.costClassCode.value0);
                };
                return "";
            };
            if (r.costClassCode instanceof Data_Maybe.Just) {
                var $53 = Data_Array.find((function () {
                    var $78 = Data_Eq.eq(Types_Newtypes.eqCostClassCode)(r.costClassCode.value0);
                    return function ($79) {
                        return $78((function (v1) {
                            return v1.costClassCode;
                        })($79));
                    };
                })())(props.costClasses);
                if ($53 instanceof Data_Maybe.Just) {
                    return Data_Newtype.un()(Types_Newtypes.CostClassCode)(r.costClassCode.value0) + (": " + $53.value0.name);
                };
                return v(true);
            };
            return v(true);
        };
        return [ foldingIndicatorPlaceholderColumn, {
            key: "checkbox",
            label: React_Basic_DOM.text(""),
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], resourceCheckBox),
            sortProperty: new Data_Maybe.Just(ByResourceChecked.value),
            width: new TacoTable_Types.Fixed("26px"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "costclass",
            label: React_Basic_DOM.text(""),
            cell: new TacoTable_Types.PlainTextCell(costClassCell),
            sortProperty: new Data_Maybe.Just(ByCostClass.value),
            width: new TacoTable_Types.Flex(3),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "name",
            label: React_Basic_DOM.text(""),
            cell: new TacoTable_Types.PlainTextCell(function (v) {
                return v.name;
            }),
            sortProperty: new Data_Maybe.Just(ByName.value),
            width: new TacoTable_Types.Flex(8),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "amount",
            label: React_Basic_DOM.text(""),
            cell: TacoTable_Types.PlainNumberCell.create(2)((function () {
                var $80 = Data_Maybe.fromMaybe(0.0);
                return function ($81) {
                    return $80((function (v) {
                        return v.amount;
                    })($81));
                };
            })()),
            sortProperty: new Data_Maybe.Just(ByAmountResource.value),
            width: new TacoTable_Types.Flex(2),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "unit",
            label: React_Basic_DOM.text(""),
            cell: new TacoTable_Types.PlainTextCell(function (v) {
                return v.unit;
            }),
            sortProperty: new Data_Maybe.Just(ByAmountResource.value),
            width: new TacoTable_Types.Fixed("80px"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "pricePerUnit",
            label: React_Basic_DOM.text(""),
            cell: TacoTable_Types.PlainEuroCell.create((function () {
                var $82 = Data_Maybe.fromMaybe(0.0);
                return function ($83) {
                    return $82((function (v) {
                        return v.pricePerUnit;
                    })($83));
                };
            })()),
            sortProperty: new Data_Maybe.Just(ByResourcePricePerUnit.value),
            width: new TacoTable_Types.Fixed("80px"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "value",
            label: React_Basic_DOM.text(""),
            cell: TacoTable_Types.PlainEuroCell.create((function () {
                var $84 = Data_Maybe.fromMaybe(0.0);
                return function ($85) {
                    return $84((function (v) {
                        return v.value;
                    })($85));
                };
            })()),
            sortProperty: new Data_Maybe.Just(ByValue.value),
            width: new TacoTable_Types.Flex(2),
            headerJSX: Data_Maybe.Nothing.value
        } ];
    })();
    var mkResourceTableRow = function (r) {
        return {
            rowData: r,
            css: React_Basic_Emotion.css()({}),
            rowHeight: TacoTable_Types.RowMedium.value,
            onClick: function (v) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            },
            className: "",
            foldingElement: Data_Maybe.Nothing.value,
            sortProperty: new Data_Maybe.Just(ByCostClass.value),
            columns: resourceColumns,
            styleSet: TacoTable_Styles.defaultRowStyleSet,
            style: React_Basic_DOM_Internal.css({}),
            rowKey: "resource-" + Data_Show.show(Types_CostGroup.showResourceId)(r.resourceId)
        };
    };
    var mkComponentTabTableFoldingElement = function (folded) {
        var folded$prime = (function () {
            if (folded) {
                return TacoTable_Types.Unfolded.value;
            };
            return TacoTable_Types.Folded.value;
        })();
        return new TacoTable_Types.FoldingElement(folded$prime, function (r) {
            return React_Basic_DOM_Generated.div()({
                className: "table-row",
                children: Data_Array.fromFoldable(Data_Array_NonEmpty_Internal.foldableNonEmptyArray)(Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)((function () {
                    var $86 = TacoTable_Row.row(eqResourceTabTableSortProps)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                        reflectSymbol: function () {
                            return "value";
                        }
                    })(Data_Maybe.eqMaybe(Data_Eq.eqNumber)))()({
                        reflectSymbol: function () {
                            return "unit";
                        }
                    })(Data_Eq.eqString))()({
                        reflectSymbol: function () {
                            return "resourceId";
                        }
                    })(Types_CostGroup.eqResourceId))()({
                        reflectSymbol: function () {
                            return "pricePerUnit";
                        }
                    })(Data_Maybe.eqMaybe(Data_Eq.eqNumber)))()({
                        reflectSymbol: function () {
                            return "name";
                        }
                    })(Data_Eq.eqString))()({
                        reflectSymbol: function () {
                            return "costClassCode";
                        }
                    })(Data_Maybe.eqMaybe(Types_Newtypes.eqCostClassCode)))()({
                        reflectSymbol: function () {
                            return "componentId";
                        }
                    })(Data_Maybe.eqMaybe(Types_Component.eqComponentId)))()({
                        reflectSymbol: function () {
                            return "checked";
                        }
                    })(Data_Eq.eqBoolean))()({
                        reflectSymbol: function () {
                            return "amount";
                        }
                    })(Data_Maybe.eqMaybe(Data_Eq.eqNumber))));
                    return function ($87) {
                        return $86(mkResourceTableRow($87));
                    };
                })())(r.resourcesData))
            });
        });
    };
    var extractCode = (function () {
        var $88 = Data_Newtype.un()(Types_CostGroup.CostGroupCode);
        return function ($89) {
            return $88((function (v) {
                return v.code;
            })($89));
        };
    })();
    var componentsData = groupResources(props.selectedResources)(props.reportingComponents)(props.resources);
    var componentColumns = (function () {
        var componentCheckbox = function (r) {
            return TacoCheckbox.component()()({
                className: "",
                isChecked: r.checked,
                onToggle: new TacoCheckbox.NoEvent((function () {
                    if (r.checked) {
                        return props.setSelectedResources(function (v) {
                            return Data_Set.empty;
                        });
                    };
                    return props.setSelectedResources(function (selectedResources) {
                        return Data_Set.union(Types_CostGroup.ordResourceId)(Data_Set.fromFoldable(Data_Array_NonEmpty_Internal.foldableNonEmptyArray)(Types_CostGroup.ordResourceId)(Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)(function (v) {
                            return v.resourceId;
                        })(r.resourcesData)))(selectedResources);
                    });
                })()),
                labelText: Data_Maybe.Nothing.value,
                testId: "component-" + Data_Show.show(Types_Component.showComponentId)(r.componentId)
            });
        };
        return [ {
            key: "checkbox",
            label: React_Basic_DOM.text(""),
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], componentCheckbox),
            sortProperty: new Data_Maybe.Just(ByComponentChecked.value),
            width: new TacoTable_Types.Fixed("26px"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "subprojectCode",
            label: React_Basic_DOM.text("AK"),
            cell: new TacoTable_Types.PlainTextCell(function (v) {
                return v.subprojectCode;
            }),
            sortProperty: new Data_Maybe.Just(BySubprojectCode.value),
            width: new TacoTable_Types.Flex(1),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "code",
            label: React_Basic_DOM.text("Koodi"),
            cell: new TacoTable_Types.PlainTextCell(function (v) {
                return v.code;
            }),
            sortProperty: new Data_Maybe.Just(ByCode.value),
            width: new TacoTable_Types.Flex(2),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "description",
            label: React_Basic_DOM.text("Selite"),
            cell: new TacoTable_Types.PlainTextCell(function (v) {
                return v.description;
            }),
            sortProperty: new Data_Maybe.Just(ByDesc.value),
            width: new TacoTable_Types.Flex(8),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "amount",
            label: React_Basic_DOM.text("M\xe4\xe4r\xe4"),
            cell: new TacoTable_Types.PlainNumberCell(2, function (v) {
                return v.amount;
            }),
            sortProperty: new Data_Maybe.Just(ByAmount.value),
            width: new TacoTable_Types.Flex(2),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "unit",
            label: React_Basic_DOM.text("Yksikk\xf6"),
            cell: new TacoTable_Types.PlainTextCell(function (v) {
                return v.unit;
            }),
            sortProperty: new Data_Maybe.Just(ByUnit.value),
            width: new TacoTable_Types.Fixed("80px"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "pricePerUnit",
            label: React_Basic_DOM.text("Yks. Hinta"),
            cell: new TacoTable_Types.PlainEuroCell(function (v) {
                return v.pricePerUnit;
            }),
            sortProperty: new Data_Maybe.Just(ByPricePerUnit.value),
            width: new TacoTable_Types.Fixed("80px"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "sum",
            label: React_Basic_DOM.text("Summa"),
            cell: new TacoTable_Types.PlainEuroCell(function (v) {
                return v.sum;
            }),
            sortProperty: new Data_Maybe.Just(BySum.value),
            width: new TacoTable_Types.Flex(2),
            headerJSX: Data_Maybe.Nothing.value
        } ];
    })();
    var mkComponentTabTableRow = function (r) {
        return {
            rowData: r,
            rowKey: Data_Show.show(Data_Show.showInt)(Data_Newtype.unwrap()(r.componentId)),
            onClick: function (v) {
                return props.setToggledComponents(function (toggledComponents) {
                    return Utils.toggleInSet(Types_Component.ordComponentId)(r.componentId)(toggledComponents);
                });
            },
            className: "",
            foldingElement: Data_Maybe.Just.create(mkComponentTabTableFoldingElement(Data_Set.member(Types_Component.ordComponentId)(r.componentId)(props.toggledComponents))),
            columns: componentColumns
        };
    };
    var componentRows = Data_Functor.map(Data_Functor.functorArray)(mkComponentTabTableRow)(componentsData);
    var componentsTabTable = TacoTable.tableSortable(getNameComponentTabTableSortProps)(eqComponentTabTableSortProps)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
        reflectSymbol: function () {
            return "unit";
        }
    })(Data_Eq.eqString))()({
        reflectSymbol: function () {
            return "sum";
        }
    })(Data_Eq.eqNumber))()({
        reflectSymbol: function () {
            return "subprojectCode";
        }
    })(Data_Eq.eqString))()({
        reflectSymbol: function () {
            return "resourcesData";
        }
    })(Data_Array_NonEmpty_Internal.eqNonEmptyArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
        reflectSymbol: function () {
            return "value";
        }
    })(Data_Maybe.eqMaybe(Data_Eq.eqNumber)))()({
        reflectSymbol: function () {
            return "unit";
        }
    })(Data_Eq.eqString))()({
        reflectSymbol: function () {
            return "resourceId";
        }
    })(Types_CostGroup.eqResourceId))()({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(Data_Maybe.eqMaybe(Data_Eq.eqNumber)))()({
        reflectSymbol: function () {
            return "name";
        }
    })(Data_Eq.eqString))()({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Data_Maybe.eqMaybe(Types_Newtypes.eqCostClassCode)))()({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Data_Maybe.eqMaybe(Types_Component.eqComponentId)))()({
        reflectSymbol: function () {
            return "checked";
        }
    })(Data_Eq.eqBoolean))()({
        reflectSymbol: function () {
            return "amount";
        }
    })(Data_Maybe.eqMaybe(Data_Eq.eqNumber))))))()({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(Data_Eq.eqNumber))()({
        reflectSymbol: function () {
            return "description";
        }
    })(Data_Eq.eqString))()({
        reflectSymbol: function () {
            return "componentId";
        }
    })(Types_Component.eqComponentId))()({
        reflectSymbol: function () {
            return "code";
        }
    })(Data_Eq.eqString))()({
        reflectSymbol: function () {
            return "checked";
        }
    })(Data_Eq.eqBoolean))()({
        reflectSymbol: function () {
            return "amount";
        }
    })(Data_Eq.eqNumber)))(componentsTabTableInstance)(new TacoTable_Types.SortingCriteria(TacoTable_Types.Ascending.value, new Data_Maybe.Just(ByCode.value), sortComponentTabTableData))(Data_Maybe.Nothing.value)({
        rows: componentRows,
        columns: componentColumns,
        showHeader: true,
        styleSet: componentTabTableStyleSet
    });
    var cgu = Data_Newtype.un()(Types_CostGroup.CostGroupUnit)(props.costGroupUnit);
    var moveSelectedResourcesDropdown = (function () {
        var list = Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return {
                id: extractCode(v),
                text: extractCode(v) + (" " + v.name)
            };
        })(props.costGroupInfos);
        return TacoAutocomplete.component()()({
            selected: Data_Maybe.Nothing.value,
            sections: [ {
                text: Data_Maybe.Nothing.value,
                list: list
            } ],
            onSelect: function (item) {
                if (item instanceof Data_Maybe.Just) {
                    return function __do() {
                        props.appDispatch(new Actions.UpdateReportingResourceCostGroupCodeRequest({
                            projectId: cgu.projectId,
                            params: {
                                resourceIds: Data_Array.fromFoldable(Data_Set.foldableSet)(props.selectedResources),
                                costGroupCode: item.value0.id,
                                oldCostGroupCode: cgu.code
                            }
                        }))();
                        return props.setSelectedResources(function (v) {
                            return Data_Set.empty;
                        })();
                    };
                };
                if (item instanceof Data_Maybe.Nothing) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                };
                throw new Error("Failed pattern match at CostGroupDetails.ComponentsTab (line 345, column 13 - line 356, column 35): " + [ item.constructor.name ]);
            },
            placeholder: Locale.lookup_("change_selected_cost_group"),
            testId: "cost-group-details-move-resources",
            clearButton: false
        });
    })();
    var componentsTabActions = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        className: "componentsTabActions",
        css: CostGroupDetails_Styles.componentsTabActionsStyles,
        children: [ (function () {
            var $62 = Data_Set.isEmpty(props.selectedResources);
            if ($62) {
                return React_Basic.empty;
            };
            return moveSelectedResourcesDropdown;
        })() ]
    });
    var componentsTab = React_Basic_DOM_Generated.div()({
        className: "components-table-container",
        children: [ componentsTabActions, componentsTabTable, sumOfSelectedComponent ]
    });
    return {
        tabItemsCount: Data_Array.length(componentRows),
        componentsTab: componentsTab
    };
};
module.exports = {
    ByCode: ByCode,
    ByComponentChecked: ByComponentChecked,
    ByDesc: ByDesc,
    ByAmount: ByAmount,
    ByUnit: ByUnit,
    ByPricePerUnit: ByPricePerUnit,
    BySum: BySum,
    BySubprojectCode: BySubprojectCode,
    sortComponentTabTableData: sortComponentTabTableData,
    componentsTabTableInstance: componentsTabTableInstance,
    componentTabTableStyleSet: componentTabTableStyleSet,
    ByCostClass: ByCostClass,
    ByResourceChecked: ByResourceChecked,
    ByName: ByName,
    ByValue: ByValue,
    ByResourceUnit: ByResourceUnit,
    ByResourcePricePerUnit: ByResourcePricePerUnit,
    ByAmountResource: ByAmountResource,
    sortResources: sortResources,
    groupResources: groupResources,
    mkComponentTabTableData: mkComponentTabTableData,
    mkTableResourceData: mkTableResourceData,
    prepareComponentTabProperties: prepareComponentTabProperties,
    eqComponentTabTableSortProps: eqComponentTabTableSortProps,
    getNameComponentTabTableSortProps: getNameComponentTabTableSortProps,
    eqResourceTabTableSortProps: eqResourceTabTableSortProps,
    getNameResourceTabTableSortProps: getNameResourceTabTableSortProps
};
