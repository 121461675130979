// Generated by purs version 0.14.5
"use strict";
var Data_Show = require("../Data.Show/index.js");
var LocalStorageKeys = require("../LocalStorageKeys/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var UseLocalStorage = require("../UseLocalStorage/index.js");
var useWorkAchievement = function (projectId) {
    var showWorkAchievementKey = LocalStorageKeys.getLocalStorageKeyStr(new LocalStorageKeys.UIEstimationWorkAchievement(projectId));
    return function __do() {
        var v = UseLocalStorage.useLocalStorage(showWorkAchievementKey)("false")(false)();
        var showWorkAchievement = v.value0 === Data_Show.show(Data_Show.showBoolean)(true);
        var onWorkAchievementToggle = TofuHooks.useMemo([  ])(function (v1) {
            return v.value1(function (value) {
                return Data_Show.show(Data_Show.showBoolean)(!(value === Data_Show.show(Data_Show.showBoolean)(true)));
            });
        })();
        return {
            showWorkAchievement: showWorkAchievement,
            onWorkAchievementToggle: onWorkAchievementToggle
        };
    };
};
module.exports = {
    useWorkAchievement: useWorkAchievement
};
