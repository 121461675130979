// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
module.exports = {
    getAccessToken: $foreign.getAccessToken,
    getEmail: $foreign.getEmail,
    getUserId: $foreign.getUserId,
    signOut: $foreign.signOut,
    oAuthLogin: $foreign.oAuthLogin,
    configureAmplify: $foreign.configureAmplify
};
