import { Popover } from "@mui/material";
import React, { useState } from "react";
import { Icon, IconGlobe } from "@tocoman/ui";
import { TFunction } from "react-i18next";
import { useChangeUserLanguage } from "../hooks/queries/useUserLanguage";
import { useUserDataQuery } from "../containers/Projects/useUserData";
import { useFeatureEnabled } from "../split/useFeatureEnabled";
import { FeatureFlags } from "../split/FeatureFlags";

type LanguageSelectorProps = {
  t: TFunction;
};

export const LanguageSelector = ({ t }: LanguageSelectorProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setIsOpen(true);
    },
    []
  );

  const changeLanguage = useChangeUserLanguage();

  const user = useUserDataQuery();
  const currentLanguage = user?.userData.language;

  const languages = ["fi", "en"];

  const swedishEnabled = useFeatureEnabled(FeatureFlags.LANGUAGE_SWEDISH);
  swedishEnabled && languages.push("sv");

  const crowdInEnabled = useFeatureEnabled(FeatureFlags.CROWDIN);
  if (crowdInEnabled) {
    if (user?.userData.isSuperAdmin) {
      languages.push("zu");
    }
  }

  return (
    <>
      <button
        className={"flex gap-4 text-[16px] nav-popover-button notranslate"}
        onClick={handleOpen}
      >
        <Icon
          icon={IconGlobe}
          scale={1.5}
          className={"text-[rgba(25, 36, 52, 0.5)]"}
        />
        <p>{t(`${currentLanguage ?? `fi`}`)}</p>
      </button>
      <Popover
        open={isOpen}
        id={"popover"}
        anchorEl={anchorEl}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        classes={{
          paper: "flex flex-col p-[16px]",
        }}
        className={"min-w-[300px] popover"}
      >
        {languages.map((language) => {
          return (
            <button
              key={language}
              className={
                "flex gap-4 text-[16px] nav-popover-button notranslate"
              }
              onClick={() => {
                changeLanguage(language);
                setIsOpen(false);
              }}
            >
              <p>{t(language)}</p>
            </button>
          );
        })}
      </Popover>
    </>
  );
};
