// Generated by purs version 0.14.5
"use strict";
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Effect = require("../Effect/index.js");
var SeparatorHeading = require("../SeparatorHeading/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var example2 = "import SeparatorHeading as SeparatorHeading\x0aimport TacoConstants (componentColors)\x0a\x0aSeparatorHeading.component\x0a  { colorSet: componentColors\x0a  , onClose: Nothing\x0a  , title: \"Components\"\x0a  }";
var example1 = "import SeparatorHeading as SeparatorHeading\x0aimport TacoConstants (elementColors)\x0a\x0aSeparatorHeading.component\x0a  { colorSet: elementColors\x0a  , onClose: Just mempty\x0a  , title: \"Building elements\"\x0a  }";
var sections = [ {
    title: "Default",
    view: [ SeparatorHeading.component({
        colorSet: TacoConstants.elementColors,
        onClose: new Data_Maybe.Just(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit))),
        title: "Rakenne",
        titleWidth: "10rem",
        columns: Data_Maybe.Nothing.value
    }) ],
    code: example1
}, {
    title: "Hidden close button",
    view: [ SeparatorHeading.component({
        colorSet: TacoConstants.componentColors,
        onClose: Data_Maybe.Nothing.value,
        title: "Suorite",
        titleWidth: "10rem",
        columns: Data_Maybe.Nothing.value
    }) ],
    code: example2
} ];
var demoView = {
    sections: sections,
    title: "SeparatorHeading",
    description: "Separator between top-level sections"
};
module.exports = {
    demoView: demoView
};
