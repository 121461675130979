/**
 * Reports a user facing error message to the user and a developer facing error
 * to Datadog
 */
export const reportError = (
  userMessage: string,
  e: Error,
  context?: Record<string, unknown>
) => {
  if (context !== undefined) {
    console.error(e, context);
  } else {
    console.error(e);
  }

  /* istanbul ignore else: Avoid PureScript output being a dependency of the
   * client-ts test suite */
  if (process.env.NODE_ENV === "test") return;
  else {
    // This method of showing notifications is temporary until we

    // HACK: Show user facing error as a PureScript app notification.
    // We use require() in order to avoid needing to compile PureScript modules
    // before running Jest tests.
    const PureScriptAppActions = require("output/Actions/index.js");
    const { AlertError } = require("output/Types.Alert/index.js");

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).dispatch(
      PureScriptAppActions.AddAlert.create(AlertError.value)(userMessage)
    )();
  }
};
