// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var DixonFileInput_Styles = require("../DixonFileInput.Styles/index.js");
var Effect = require("../Effect/index.js");
var HtmlUtils = require("../HtmlUtils/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon = require("../TacoIcon/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var TutorialContainer = require("../TutorialContainer/index.js");
var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");
var UseNullableRef = require("../UseNullableRef/index.js");
var UseUUID = require("../UseUUID/index.js");
var Web_File_FileList = require("../Web.File.FileList/index.js");
var Web_HTML_HTMLInputElement = require("../Web.HTML.HTMLInputElement/index.js");
var coerceHTMLElementToHTMLInputElement = Unsafe_Coerce.unsafeCoerce;
var component = TofuHooks.mkHookComponent("DixonFileInput")(function (v) {
    return function __do() {
        var fileInputRef = UseNullableRef.useNullableRef();
        var id = UseUUID.useUUID();
        var update = Data_Maybe.isJust(v.updateFileId);
        var onChangeFile = React_Basic_Events.handler(React_Basic_DOM_Events.targetFiles)(function (mFileList) {
            return function __do() {
                v.onClick();
                (function () {
                    if (v.updateFileId instanceof Data_Maybe.Just) {
                        var mFirstFile = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Web_File_FileList.item(0))(mFileList);
                        if (mFirstFile instanceof Data_Maybe.Just) {
                            return v.editor.upload_file_revision(mFirstFile.value0, v.updateFileId.value0);
                        };
                        return Data_Unit.unit;
                    };
                    if (v.updateFileId instanceof Data_Maybe.Nothing) {
                        if (mFileList instanceof Data_Maybe.Just) {
                            return v.editor.upload_files(mFileList.value0);
                        };
                        return Data_Unit.unit;
                    };
                    throw new Error("Failed pattern match at DixonFileInput (line 52, column 7 - line 60, column 25): " + [ v.updateFileId.constructor.name ]);
                })();
                var fileInputElement = HtmlUtils.readRefMaybeElement(fileInputRef)();
                return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(fileInputElement)(function (elem) {
                    return Web_HTML_HTMLInputElement.setValue("")(coerceHTMLElementToHTMLInputElement(elem));
                })();
            };
        });
        var label = TutorialContainer.component({
            active: v.tutorial,
            contents: Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_Emotion.element(React_Basic_DOM_Generated["label'"]())({
                htmlFor: id,
                className: "DixonFileInputLabel",
                css: DixonFileInput_Styles.dixonFileLabelStyles({
                    update: update
                }),
                title: (function () {
                    if (update) {
                        return "- Vanha piirustus siirret\xe4\xe4n arkistoon\x0a- Uusi piirustus ladataan vanhan tilalle\x0a- Mittaukset siirtyv\xe4t uuden piirustuksen p\xe4\xe4lle\x0a- Tarkasta ett\xe4 mittaukset ja mittakaava ovat oikein\x0a          ";
                    };
                    return "- Valitse ladattava pdf-tiedosto omalta tietokoneelta\x0a- Muokkaa tarvittaessa tiedoston nime\xe4 ja oletussijaintia ladatun tiedon per\xe4ss\xe4 olevista toimintovalikoista\x0a- Aseta piirustukselle mittakaava\x0a- Jos mittauksia kopioidaan piirustuksesta toiseen, pit\xe4\xe4 mittakaava asettaa eri piirustuksiin samaan kohtaan ja mitattuna saman suuntaisesti esim vasemmalta oikealla tai ylh\xe4\xe4lt\xe4 alas. Kopioitavat mittaukset liitet\xe4\xe4n uuteen piirustukseen n\xe4iden mittausten perusteella.\x0a              ";
                })(),
                children: Control_Applicative.pure(Control_Applicative.applicativeArray)(Box.box("LabelStylesContainer")([ Box.PaddingX.create(new TacoConstants.CustomSize("0.6rem")), Box.Gap.create(new TacoConstants.CustomSize("0.7rem")) ])([ TacoIcon.component()()()()({
                    icon: TacoIcon_Icons_Types.IconFileUploadV1.value,
                    size: TacoConstants.XS.value,
                    color: (function () {
                        if (update) {
                            return TacoConstants.PrimaryBlue.value;
                        };
                        return TacoConstants.White.value;
                    })()
                }), TacoText.component()()({
                    text: (function () {
                        var $10 = Data_Maybe.isJust(v.updateFileId);
                        if ($10) {
                            return "Lis\xe4\xe4 uusi revisio";
                        };
                        return "Lis\xe4\xe4 tiedosto";
                    })(),
                    color: (function () {
                        if (update) {
                            return TacoConstants.PrimaryBlue.value;
                        };
                        return TacoConstants.White.value;
                    })()
                }) ]))
            }))
        });
        var fileInput = React_Basic_Emotion.element(React_Basic_DOM_Generated["input'"]())({
            type: "file",
            className: "DixonFileInput",
            onChange: onChangeFile,
            css: DixonFileInput_Styles.dixonFileInputStyles,
            id: id,
            ref: fileInputRef,
            multiple: !update
        });
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "DixonFileInputContainer",
            css: DixonFileInput_Styles.dixonFileInputContainerStyles({
                update: Data_Maybe.isJust(v.updateFileId)
            }),
            children: [ fileInput, label ]
        });
    };
});
module.exports = {
    coerceHTMLElementToHTMLInputElement: coerceHTMLElementToHTMLInputElement,
    component: component
};
