// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var LengthValidator = require("../LengthValidator/index.js");
var Locale = require("../Locale/index.js");
var ProjectComponents_Styles = require("../ProjectComponents.Styles/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoLoader = require("../TacoLoader/index.js");
var TacoSelect = require("../TacoSelect/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Project = require("../Types.Project/index.js");
var UseFetch = require("../UseFetch/index.js");
var responsibilityInfoTableInstance = TacoTable.mkTableStateless(TofuHooks.getNameVoid)("ResponsibilityInfoTable");
var mkRowData = function (r) {
    return {
        projectRole: r.projectRole,
        companyName: r.companyName,
        businessId: r.businessId
    };
};
var componentImpl = function (props) {
    var rowData = Data_Functor.map(Data_Functor.functorArray)(mkRowData)(props.responsibilityInfo);
    var projectRoleString = (function () {
        var $25 = Data_Newtype.un()(Types_Project.ProjectRoleId);
        return function ($26) {
            return $25((function (v) {
                return v.projectRole;
            })($26));
        };
    })();
    var columns = (function () {
        var mkItem = function (v) {
            return {
                value: v,
                text: v
            };
        };
        var makeBusinessId = function (s) {
            if (s === "") {
                return Data_Maybe.Nothing.value;
            };
            return Data_Maybe.Just.create(s);
        };
        var companyDropdownCell = function (r) {
            return TacoSelect.component()()({
                items: Data_Semigroup.append(Data_Semigroup.semigroupArray)([ {
                    value: "",
                    text: ""
                } ])(Data_Functor.map(Data_Functor.functorArray)(mkItem)(props.responsibilityCompanies)),
                value: Data_Maybe.maybe("")(Data_Newtype.un()(Types_Project.CompanyName))(r.companyName),
                onChange: TacoInput.changeHandler(TacoInput.inputValueString)(function (value) {
                    return props.appDispatch(new Actions.UpdateProjectResponsibilityInfoAction({
                        projectId: props.projectId,
                        params: {
                            projectRole: r.projectRole,
                            companyName: (function () {
                                var $9 = value === "";
                                if ($9) {
                                    return Data_Maybe.Nothing.value;
                                };
                                return new Data_Maybe.Just(value);
                            })(),
                            businessId: Types_Project.NoOpShouldUpdateBusinessId.value
                        },
                        handler: Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)))
                    }));
                })
            });
        };
        var businessIdInput = function (r) {
            return TacoInput.remoteStringField()()({
                value: (function () {
                    if (r.companyName instanceof Data_Maybe.Just) {
                        return Data_Maybe.maybe("")(Data_Newtype.un()(Types_Project.BusinessId))(r.businessId);
                    };
                    if (r.companyName instanceof Data_Maybe.Nothing) {
                        return "Valitse yritys";
                    };
                    throw new Error("Failed pattern match at ResponsibilityInfoContainer (line 113, column 20 - line 115, column 42): " + [ r.companyName.constructor.name ]);
                })(),
                disabled: Data_Maybe.isNothing(r.companyName),
                validator: function (bidString) {
                    return LengthValidator.lengthValidator(0)(50)(bidString);
                },
                mkAction: function (idString) {
                    return function (handler) {
                        return new Actions.UpdateProjectResponsibilityInfoAction({
                            projectId: props.projectId,
                            params: {
                                projectRole: r.projectRole,
                                companyName: r.companyName,
                                businessId: Types_Project.ShouldUpdateBusinessId.create(makeBusinessId(idString))
                            },
                            handler: handler
                        });
                    };
                }
            });
        };
        return [ {
            key: "role",
            label: React_Basic_DOM.text(Locale.lookup_("project_role")),
            cell: new TacoTable_Types.PlainTextCell(projectRoleString),
            sortProperty: TacoTable_Types.noSortProperty,
            width: new TacoTable_Types.Flex(1),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "name",
            label: React_Basic_DOM.text(Locale.lookup_("company")),
            cell: new TacoTable_Types.JSXCell([  ], companyDropdownCell),
            sortProperty: TacoTable_Types.noSortProperty,
            width: new TacoTable_Types.Flex(1),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "businessId",
            label: React_Basic_DOM.text(Locale.lookup_("table_invoices__supplier_business_id")),
            cell: new TacoTable_Types.JSXCell([  ], businessIdInput),
            sortProperty: TacoTable_Types.noSortProperty,
            width: new TacoTable_Types.Flex(1),
            headerJSX: Data_Maybe.Nothing.value
        } ];
    })();
    var getRow = function (r) {
        return {
            className: "__ProjectResponsibilityInfoRow",
            rowData: r,
            foldingElement: Data_Maybe.Nothing.value,
            onClick: function (v) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            },
            rowKey: projectRoleString(r),
            columns: columns
        };
    };
    return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        className: "__BusinessInfo",
        css: ProjectComponents_Styles.projectDetailsSectionStyles,
        children: [ TacoText.component()()({
            text: "Osapuolet",
            weight: TacoText_Types.Bold.value,
            gutterBottom: true,
            variant: TacoText_Types.Paragraph.value
        }), TacoTable["tableStateless'"](TofuHooks.getNameVoid)(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "projectRole";
            }
        })(Types_Project.eqProjectRoleId))()({
            reflectSymbol: function () {
                return "companyName";
            }
        })(Data_Maybe.eqMaybe(Types_Project.eqCompanyName)))()({
            reflectSymbol: function () {
                return "businessId";
            }
        })(Data_Maybe.eqMaybe(Types_Project.eqBusinessId))))(true)(responsibilityInfoTableInstance)(Data_Functor.map(Data_Functor.functorArray)(getRow)(rowData))(columns) ]
    });
};
var component = TofuHooks.mkHookComponent("ResponsibilityInfoContainer")(function (props) {
    return function __do() {
        var v = UseFetch.useFetchValueByProjectId(props.projectId)(function (v) {
            return v.responsibilityInfo;
        })(Actions.ProjectResponsibilityInfoRequest.create)();
        var v1 = UseFetch.useFetchItems(Data_Eq.eqUnit)(Data_Unit.unit)(function (v1) {
            return v1.responsibilityCompanies;
        })(Actions.ResponsibilityCompaniesRequest.value)();
        var appDispatch = StateHooks.useDispatch();
        var loading = TacoLoader.component()()({
            size: "1rem"
        });
        var content = (function () {
            if (!v.value1 && (!v1.value1 && v.value0 instanceof Data_Maybe.Just)) {
                return componentImpl({
                    responsibilityInfo: v.value0.value0.items,
                    responsibilityCompanies: v1.value0,
                    projectId: props.projectId,
                    appDispatch: appDispatch
                });
            };
            return loading;
        })();
        return React_Basic_DOM_Generated.div()({
            className: "__ResponsibilityInfoContainer",
            children: [ content ]
        });
    };
});
module.exports = {
    component: component
};
