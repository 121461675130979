// Generated by purs version 0.14.5
"use strict";
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_EuclideanRing = require("../Data.EuclideanRing/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ring = require("../Data.Ring/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unfoldable = require("../Data.Unfoldable/index.js");
var Foreign_Object = require("../Foreign.Object/index.js");
var $$Math = require("../Math/index.js");
var QuantityFormula_Parser = require("../QuantityFormula.Parser/index.js");
var QuantityFormula_Types = require("../QuantityFormula.Types/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var Variables = function (x) {
    return x;
};
var varNameLength = function (v) {
    if (v instanceof QuantityFormula_Types.QVar) {
        return Data_Maybe.Just.create(Data_String_CodePoints.length(v.value0));
    };
    return Data_Maybe.Nothing.value;
};
var unVariables = function (v) {
    return v;
};
var transformKey = function (v) {
    return new Data_Tuple.Tuple(Data_String_Common.toLower(v.value0), v.value1);
};
var showVariables = {
    show: function (v) {
        var toStringTuple = function (v1) {
            return new Data_Tuple.Tuple(v1.value0, Data_Show.show(QuantityFormula_Types.showQExp)(v1.value1));
        };
        var mkJSObjectString = function (xs) {
            return Simple_JSON.writeJSON(Simple_JSON.writeForeignObject(Simple_JSON.writeForeignString))(Foreign_Object.fromFoldable(Data_Foldable.foldableArray)(Data_Functor.map(Data_Functor.functorArray)(toStringTuple)(xs)));
        };
        return mkJSObjectString(Data_Map_Internal.toUnfoldable(Data_Unfoldable.unfoldableArray)(v));
    }
};
var lookupVariable = function (key) {
    return function (v) {
        return Data_Map_Internal.lookup(Data_Ord.ordString)(Data_String_Common.toLower(key))(v);
    };
};
var intDiv = function (a) {
    return function (b) {
        return Data_Int.toNumber(Data_Int.floor(a / b));
    };
};
var insertVariable$prime = function (key) {
    return function (exp) {
        return function (v) {
            return Variables(Data_Map_Internal.insert(Data_Ord.ordString)(Data_String_Common.toLower(key))(exp)(v));
        };
    };
};
var insertVariable = function (key) {
    return function (exp) {
        return function (ctx) {
            return {
                defaultZero: ctx.defaultZero,
                variables: insertVariable$prime(key)(exp)(ctx.variables)
            };
        };
    };
};
var fromArray = function (xs) {
    return Variables(Data_Map_Internal.fromFoldable(Data_Ord.ordString)(Data_Foldable.foldableArray)(Data_Functor.map(Data_Functor.functorArray)(transformKey)(xs)));
};
var mkVariables = (function () {
    var $211 = Data_Map_Internal.toUnfoldableUnordered(Data_Unfoldable.unfoldableArray);
    return function ($212) {
        return fromArray($211($212));
    };
})();
var mkDefaultContext = function (variables) {
    return {
        defaultZero: false,
        variables: mkVariables(variables)
    };
};
var collect = function (f) {
    return function (exp) {
        var $42 = f(exp);
        if ($42) {
            return [ exp ];
        };
        return [  ];
    };
};
var collectMatchingTerms = function (f) {
    return function (ast) {
        var walk = function (v) {
            if (v instanceof QuantityFormula_Types.QNum) {
                return collect(f)(v);
            };
            if (v instanceof QuantityFormula_Types.QBool) {
                return collect(f)(v);
            };
            if (v instanceof QuantityFormula_Types.QVar) {
                return collect(f)(v);
            };
            if (v instanceof QuantityFormula_Types.QParens) {
                return Data_Semigroup.append(Data_Semigroup.semigroupArray)(collect(f)(v))(walk(v.value0));
            };
            if (v instanceof QuantityFormula_Types.QPlus) {
                return collectBin(v)(v.value0)(v.value1);
            };
            if (v instanceof QuantityFormula_Types.QMinus) {
                return collectBin(v)(v.value0)(v.value1);
            };
            if (v instanceof QuantityFormula_Types.QTimes) {
                return collectBin(v)(v.value0)(v.value1);
            };
            if (v instanceof QuantityFormula_Types.QPow) {
                return collectBin(v)(v.value0)(v.value1);
            };
            if (v instanceof QuantityFormula_Types.QDiv) {
                return collectBin(v)(v.value0)(v.value1);
            };
            if (v instanceof QuantityFormula_Types.QIntDiv) {
                return collectBin(v)(v.value0)(v.value1);
            };
            if (v instanceof QuantityFormula_Types.QMod) {
                return collectBin(v)(v.value0)(v.value1);
            };
            if (v instanceof QuantityFormula_Types.QEqual) {
                return collectBin(v)(v.value0)(v.value1);
            };
            if (v instanceof QuantityFormula_Types.QGT) {
                return collectBin(v)(v.value0)(v.value1);
            };
            if (v instanceof QuantityFormula_Types.QGTEQ) {
                return collectBin(v)(v.value0)(v.value1);
            };
            if (v instanceof QuantityFormula_Types.QLT) {
                return collectBin(v)(v.value0)(v.value1);
            };
            if (v instanceof QuantityFormula_Types.QLTEQ) {
                return collectBin(v)(v.value0)(v.value1);
            };
            if (v instanceof QuantityFormula_Types.QNEQ) {
                return collectBin(v)(v.value0)(v.value1);
            };
            if (v instanceof QuantityFormula_Types.QNot) {
                return Data_Semigroup.append(Data_Semigroup.semigroupArray)(collect(f)(v))(walk(v.value0));
            };
            if (v instanceof QuantityFormula_Types.QOr) {
                return collectBin(v)(v.value0)(v.value1);
            };
            if (v instanceof QuantityFormula_Types.QAnd) {
                return collectBin(v)(v.value0)(v.value1);
            };
            if (v instanceof QuantityFormula_Types.QXor) {
                return collectBin(v)(v.value0)(v.value1);
            };
            if (v instanceof QuantityFormula_Types.QFnPi) {
                return collect(f)(v);
            };
            if (v instanceof QuantityFormula_Types.QFnAbs) {
                return Data_Semigroup.append(Data_Semigroup.semigroupArray)(collect(f)(v))(walk(v.value0));
            };
            if (v instanceof QuantityFormula_Types.QFnInt) {
                return Data_Semigroup.append(Data_Semigroup.semigroupArray)(collect(f)(v))(walk(v.value0));
            };
            if (v instanceof QuantityFormula_Types.QFnFix) {
                return Data_Semigroup.append(Data_Semigroup.semigroupArray)(collect(f)(v))(walk(v.value0));
            };
            if (v instanceof QuantityFormula_Types.QFnSgn) {
                return Data_Semigroup.append(Data_Semigroup.semigroupArray)(collect(f)(v))(walk(v.value0));
            };
            if (v instanceof QuantityFormula_Types.QFnSqr) {
                return Data_Semigroup.append(Data_Semigroup.semigroupArray)(collect(f)(v))(walk(v.value0));
            };
            if (v instanceof QuantityFormula_Types.QFnLog) {
                return Data_Semigroup.append(Data_Semigroup.semigroupArray)(collect(f)(v))(walk(v.value0));
            };
            if (v instanceof QuantityFormula_Types.QFnExp) {
                return Data_Semigroup.append(Data_Semigroup.semigroupArray)(collect(f)(v))(walk(v.value0));
            };
            if (v instanceof QuantityFormula_Types.QFnSin) {
                return Data_Semigroup.append(Data_Semigroup.semigroupArray)(collect(f)(v))(walk(v.value0));
            };
            if (v instanceof QuantityFormula_Types.QFnCos) {
                return Data_Semigroup.append(Data_Semigroup.semigroupArray)(collect(f)(v))(walk(v.value0));
            };
            if (v instanceof QuantityFormula_Types.QFnTan) {
                return Data_Semigroup.append(Data_Semigroup.semigroupArray)(collect(f)(v))(walk(v.value0));
            };
            if (v instanceof QuantityFormula_Types.QFnMin) {
                return collectBin(v)(v.value0)(v.value1);
            };
            if (v instanceof QuantityFormula_Types.QFnMax) {
                return collectBin(v)(v.value0)(v.value1);
            };
            if (v instanceof QuantityFormula_Types.QFnIf) {
                return Data_Semigroup.append(Data_Semigroup.semigroupArray)(collect(f)(v))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(walk(v.value0))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(walk(v.value1))(walk(v.value2))));
            };
            throw new Error("Failed pattern match at QuantityFormula.Interpreter (line 206, column 5 - line 206, column 31): " + [ v.constructor.name ]);
        };
        var collectBin = function (val) {
            return function (exp1) {
                return function (exp2) {
                    return Data_Semigroup.append(Data_Semigroup.semigroupArray)(collect(f)(val))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(walk(exp1))(walk(exp2)));
                };
            };
        };
        return walk(ast);
    };
};
var collectNewMeasures = function (context) {
    return function (exp) {
        var isNewVariable = function (v) {
            if (v instanceof QuantityFormula_Types.QVar) {
                return Data_Maybe.isNothing(lookupVariable(v.value0)(context.variables));
            };
            return false;
        };
        return collectMatchingTerms(isNewVariable)(exp);
    };
};
var collectVars = function (exp) {
    var isQVar = function (v) {
        if (v instanceof QuantityFormula_Types.QVar) {
            return true;
        };
        return false;
    };
    return collectMatchingTerms(isQVar)(exp);
};
var varNamesShorterThan = function (maxLength) {
    return function (v) {
        var vars = (function () {
            var v1 = QuantityFormula_Parser.parseQF(v);
            if (v1 instanceof Data_Either.Left) {
                return [  ];
            };
            if (v1 instanceof Data_Either.Right) {
                return collectVars(v1.value0);
            };
            throw new Error("Failed pattern match at QuantityFormula.Interpreter (line 256, column 12 - line 258, column 44): " + [ v1.constructor.name ]);
        })();
        var varNameLengths = Data_Functor.map(Data_Functor.functorArray)(Data_Maybe.fromMaybe(0))(Data_Array.filter(Data_Maybe.isJust)(Data_Functor.map(Data_Functor.functorArray)(varNameLength)(vars)));
        var checkFn = function (n) {
            return n <= maxLength;
        };
        return Data_Array.all(checkFn)(varNameLengths);
    };
};
var numberUnaryFunc = function (c) {
    return function (f) {
        return function (e1) {
            var v = evalQExp(c)(e1);
            if (v instanceof Data_Either.Right && v.value0 instanceof QuantityFormula_Types.QNum) {
                return Data_Either.Right.create(QuantityFormula_Types.QNum.create(f(v.value0.value0)));
            };
            if (v instanceof Data_Either.Left) {
                return new Data_Either.Left(v.value0);
            };
            return new Data_Either.Left("type error");
        };
    };
};
var numberBoolBinFunc = function (c) {
    return function (f) {
        return function (e1) {
            return function (e2) {
                var v = evalQExp(c)(e2);
                var v1 = evalQExp(c)(e1);
                if (v1 instanceof Data_Either.Right && (v1.value0 instanceof QuantityFormula_Types.QNum && (v instanceof Data_Either.Right && v.value0 instanceof QuantityFormula_Types.QNum))) {
                    return Data_Either.Right.create(QuantityFormula_Types.QBool.create(f(v1.value0.value0)(v.value0.value0)));
                };
                if (v1 instanceof Data_Either.Left) {
                    return new Data_Either.Left(v1.value0);
                };
                if (v instanceof Data_Either.Left) {
                    return new Data_Either.Left(v.value0);
                };
                return new Data_Either.Left("type error");
            };
        };
    };
};
var numberBinFunc = function (c) {
    return function (f) {
        return function (e1) {
            return function (e2) {
                var v = evalQExp(c)(e2);
                var v1 = evalQExp(c)(e1);
                if (v1 instanceof Data_Either.Right && (v1.value0 instanceof QuantityFormula_Types.QNum && (v instanceof Data_Either.Right && v.value0 instanceof QuantityFormula_Types.QNum))) {
                    return Data_Either.Right.create(QuantityFormula_Types.QNum.create(f(v1.value0.value0)(v.value0.value0)));
                };
                if (v1 instanceof Data_Either.Left) {
                    return new Data_Either.Left(v1.value0);
                };
                if (v instanceof Data_Either.Left) {
                    return new Data_Either.Left(v.value0);
                };
                return new Data_Either.Left("type error");
            };
        };
    };
};
var ifFunc = function (c) {
    return function (e1) {
        return function (e2) {
            return function (e3) {
                var v = evalQExp(c)(e1);
                if (v instanceof Data_Either.Right && v.value0 instanceof QuantityFormula_Types.QBool) {
                    if (v.value0.value0) {
                        return evalQExp(c)(e2);
                    };
                    return evalQExp(c)(e3);
                };
                if (v instanceof Data_Either.Right) {
                    return new Data_Either.Left("type error");
                };
                if (v instanceof Data_Either.Left) {
                    return new Data_Either.Left(v.value0);
                };
                throw new Error("Failed pattern match at QuantityFormula.Interpreter (line 172, column 3 - line 178, column 21): " + [ v.constructor.name ]);
            };
        };
    };
};
var evalQExp = function ($copy_v) {
    return function ($copy_v1) {
        var $tco_var_v = $copy_v;
        var $tco_done = false;
        var $tco_result;
        function $tco_loop(v, v1) {
            if (v1 instanceof QuantityFormula_Types.QNum) {
                $tco_done = true;
                return new Data_Either.Right(v1);
            };
            if (v1 instanceof QuantityFormula_Types.QBool) {
                $tco_done = true;
                return new Data_Either.Right(v1);
            };
            if (v1 instanceof QuantityFormula_Types.QVar) {
                var v3 = lookupVariable(v1.value0)(v.variables);
                if (v3 instanceof Data_Maybe.Just) {
                    $tco_done = true;
                    return new Data_Either.Right(v3.value0);
                };
                if (v3 instanceof Data_Maybe.Nothing && v.defaultZero) {
                    $tco_done = true;
                    return new Data_Either.Right(new QuantityFormula_Types.QNum(0.0));
                };
                if (v3 instanceof Data_Maybe.Nothing && !v.defaultZero) {
                    $tco_done = true;
                    return Data_Either.Left.create("Variable " + (v1.value0 + " undefined."));
                };
                throw new Error("Failed pattern match at QuantityFormula.Interpreter (line 86, column 3 - line 89, column 69): " + [ v3.constructor.name, v.defaultZero.constructor.name ]);
            };
            if (v1 instanceof QuantityFormula_Types.QParens) {
                $tco_var_v = v;
                $copy_v1 = v1.value0;
                return;
            };
            if (v1 instanceof QuantityFormula_Types.QPlus) {
                $tco_done = true;
                return numberBinFunc(v)(Data_Semiring.add(Data_Semiring.semiringNumber))(v1.value0)(v1.value1);
            };
            if (v1 instanceof QuantityFormula_Types.QMinus) {
                $tco_done = true;
                return numberBinFunc(v)(Data_Ring.sub(Data_Ring.ringNumber))(v1.value0)(v1.value1);
            };
            if (v1 instanceof QuantityFormula_Types.QTimes) {
                $tco_done = true;
                return numberBinFunc(v)(Data_Semiring.mul(Data_Semiring.semiringNumber))(v1.value0)(v1.value1);
            };
            if (v1 instanceof QuantityFormula_Types.QDiv) {
                $tco_done = true;
                return numberBinFunc(v)(Data_EuclideanRing.div(Data_EuclideanRing.euclideanRingNumber))(v1.value0)(v1.value1);
            };
            if (v1 instanceof QuantityFormula_Types.QIntDiv) {
                $tco_done = true;
                return numberBinFunc(v)(intDiv)(v1.value0)(v1.value1);
            };
            if (v1 instanceof QuantityFormula_Types.QPow) {
                $tco_done = true;
                return numberBinFunc(v)($$Math.pow)(v1.value0)(v1.value1);
            };
            if (v1 instanceof QuantityFormula_Types.QMod) {
                $tco_done = true;
                return numberBinFunc(v)($$Math.remainder)(v1.value0)(v1.value1);
            };
            if (v1 instanceof QuantityFormula_Types.QEqual) {
                $tco_done = true;
                return numberBoolBinFunc(v)(Data_Eq.eq(Data_Eq.eqNumber))(v1.value0)(v1.value1);
            };
            if (v1 instanceof QuantityFormula_Types.QGT) {
                $tco_done = true;
                return numberBoolBinFunc(v)(Data_Ord.greaterThan(Data_Ord.ordNumber))(v1.value0)(v1.value1);
            };
            if (v1 instanceof QuantityFormula_Types.QGTEQ) {
                $tco_done = true;
                return numberBoolBinFunc(v)(Data_Ord.greaterThan(Data_Ord.ordNumber))(v1.value0)(v1.value1);
            };
            if (v1 instanceof QuantityFormula_Types.QLT) {
                $tco_done = true;
                return numberBoolBinFunc(v)(Data_Ord.lessThan(Data_Ord.ordNumber))(v1.value0)(v1.value1);
            };
            if (v1 instanceof QuantityFormula_Types.QLTEQ) {
                $tco_done = true;
                return numberBoolBinFunc(v)(Data_Ord.lessThanOrEq(Data_Ord.ordNumber))(v1.value0)(v1.value1);
            };
            if (v1 instanceof QuantityFormula_Types.QNEQ) {
                $tco_done = true;
                return numberBoolBinFunc(v)(Data_Eq.notEq(Data_Eq.eqNumber))(v1.value0)(v1.value1);
            };
            if (v1 instanceof QuantityFormula_Types.QNot) {
                $tco_done = true;
                return boolUnaryFunc(v)(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))(v1.value0);
            };
            if (v1 instanceof QuantityFormula_Types.QOr) {
                $tco_done = true;
                return boolBinFunc(v)(Data_HeytingAlgebra.disj(Data_HeytingAlgebra.heytingAlgebraBoolean))(v1.value0)(v1.value1);
            };
            if (v1 instanceof QuantityFormula_Types.QAnd) {
                $tco_done = true;
                return boolBinFunc(v)(Data_HeytingAlgebra.conj(Data_HeytingAlgebra.heytingAlgebraBoolean))(v1.value0)(v1.value1);
            };
            if (v1 instanceof QuantityFormula_Types.QXor) {
                $tco_done = true;
                return boolBinFunc(v)(function (a) {
                    return function (b) {
                        return (a || b) && !(a && b);
                    };
                })(v1.value0)(v1.value1);
            };
            if (v1 instanceof QuantityFormula_Types.QFnPi) {
                $tco_done = true;
                return Data_Either.Right.create(new QuantityFormula_Types.QNum($$Math.pi));
            };
            if (v1 instanceof QuantityFormula_Types.QFnAbs) {
                $tco_done = true;
                return numberUnaryFunc(v)($$Math.abs)(v1.value0);
            };
            if (v1 instanceof QuantityFormula_Types.QFnInt) {
                $tco_done = true;
                return numberUnaryFunc(v)(function ($213) {
                    return Data_Int.toNumber(Data_Int.floor($213));
                })(v1.value0);
            };
            if (v1 instanceof QuantityFormula_Types.QFnFix) {
                $tco_done = true;
                return numberUnaryFunc(v)(function ($214) {
                    return Data_Int.toNumber(Data_Int.floor($214));
                })(v1.value0);
            };
            if (v1 instanceof QuantityFormula_Types.QFnSgn) {
                $tco_done = true;
                return numberUnaryFunc(v)(function (n) {
                    var $178 = n < 0.0;
                    if ($178) {
                        return -1.0;
                    };
                    return 1.0;
                })(v1.value0);
            };
            if (v1 instanceof QuantityFormula_Types.QFnSqr) {
                $tco_done = true;
                return numberUnaryFunc(v)($$Math.sqrt)(v1.value0);
            };
            if (v1 instanceof QuantityFormula_Types.QFnLog) {
                $tco_done = true;
                return numberUnaryFunc(v)($$Math.log)(v1.value0);
            };
            if (v1 instanceof QuantityFormula_Types.QFnExp) {
                $tco_done = true;
                return numberUnaryFunc(v)($$Math.exp)(v1.value0);
            };
            if (v1 instanceof QuantityFormula_Types.QFnSin) {
                $tco_done = true;
                return numberUnaryFunc(v)($$Math.sin)(v1.value0);
            };
            if (v1 instanceof QuantityFormula_Types.QFnCos) {
                $tco_done = true;
                return numberUnaryFunc(v)($$Math.cos)(v1.value0);
            };
            if (v1 instanceof QuantityFormula_Types.QFnTan) {
                $tco_done = true;
                return numberUnaryFunc(v)($$Math.tan)(v1.value0);
            };
            if (v1 instanceof QuantityFormula_Types.QFnMin) {
                $tco_done = true;
                return numberBinFunc(v)($$Math.min)(v1.value0)(v1.value1);
            };
            if (v1 instanceof QuantityFormula_Types.QFnMax) {
                $tco_done = true;
                return numberBinFunc(v)($$Math.max)(v1.value0)(v1.value1);
            };
            if (v1 instanceof QuantityFormula_Types.QFnIf) {
                $tco_done = true;
                return ifFunc(v)(v1.value0)(v1.value1)(v1.value2);
            };
            throw new Error("Failed pattern match at QuantityFormula.Interpreter (line 82, column 1 - line 82, column 53): " + [ v.constructor.name, v1.constructor.name ]);
        };
        while (!$tco_done) {
            $tco_result = $tco_loop($tco_var_v, $copy_v1);
        };
        return $tco_result;
    };
};
var boolUnaryFunc = function (c) {
    return function (f) {
        return function (e1) {
            var v = evalQExp(c)(e1);
            if (v instanceof Data_Either.Right && v.value0 instanceof QuantityFormula_Types.QBool) {
                return Data_Either.Right.create(QuantityFormula_Types.QBool.create(f(v.value0.value0)));
            };
            if (v instanceof Data_Either.Left) {
                return new Data_Either.Left(v.value0);
            };
            return new Data_Either.Left("type error");
        };
    };
};
var boolBinFunc = function (c) {
    return function (f) {
        return function (e1) {
            return function (e2) {
                var v = evalQExp(c)(e2);
                var v1 = evalQExp(c)(e1);
                if (v1 instanceof Data_Either.Right && (v1.value0 instanceof QuantityFormula_Types.QBool && (v instanceof Data_Either.Right && v.value0 instanceof QuantityFormula_Types.QBool))) {
                    return Data_Either.Right.create(QuantityFormula_Types.QBool.create(f(v1.value0.value0)(v.value0.value0)));
                };
                if (v1 instanceof Data_Either.Left) {
                    return new Data_Either.Left(v1.value0);
                };
                if (v instanceof Data_Either.Left) {
                    return new Data_Either.Left(v.value0);
                };
                return new Data_Either.Left("type error");
            };
        };
    };
};
var evalQuantityFormula = function (c) {
    return function (v) {
        var $207 = Data_String_Common["null"](v);
        if ($207) {
            return new Data_Either.Right(new QuantityFormula_Types.QNum(0.0));
        };
        var v1 = QuantityFormula_Parser.parseQF(v);
        if (v1 instanceof Data_Either.Right) {
            return evalQExp(c)(v1.value0);
        };
        if (v1 instanceof Data_Either.Left) {
            return Data_Either.Left.create("Parse error: " + Data_Show.show(Data_Show.showRecord()(Data_Show.showRecordFieldsCons({
                reflectSymbol: function () {
                    return "error";
                }
            })(Data_Show.showRecordFieldsCons({
                reflectSymbol: function () {
                    return "pos";
                }
            })(Data_Show.showRecordFieldsNil)(Data_Show.showInt))(Data_Show.showString)))(v1.value0));
        };
        throw new Error("Failed pattern match at QuantityFormula.Interpreter (line 186, column 7 - line 188, column 51): " + [ v1.constructor.name ]);
    };
};
module.exports = {
    evalQExp: evalQExp,
    evalQuantityFormula: evalQuantityFormula,
    unVariables: unVariables,
    mkVariables: mkVariables,
    fromArray: fromArray,
    mkDefaultContext: mkDefaultContext,
    insertVariable: insertVariable,
    collectNewMeasures: collectNewMeasures,
    collectMatchingTerms: collectMatchingTerms,
    varNamesShorterThan: varNamesShorterThan,
    showVariables: showVariables
};
