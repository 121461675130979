// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var roleProjectRightsTableStyles = Box.boxStyle([ new Box.Selector("& > *", [ Box.Height.create(new TacoConstants.CustomSize("400px")), Box.MaxHeight.create(new TacoConstants.CustomSize("400px")) ]) ]);
var chipListStyles = Box.boxStyle([ Box.FlexRow.value, new Box.Selector("& > *", [ new Box.MarginRight(TacoConstants.XS.value) ]) ]);
module.exports = {
    roleProjectRightsTableStyles: roleProjectRightsTableStyles,
    chipListStyles: chipListStyles
};
