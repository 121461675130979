// Generated by purs version 0.14.5
"use strict";
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var TacoList = require("../TacoList/index.js");
var example1_jsx = TacoList.component({
    items: [ {
        title: "A Kiinteist\xf6hallinto",
        titleJSX: Data_Maybe.Nothing.value,
        id: "A Kiinteist\xf6hallinto",
        value: new TacoList.TextValue("500 \u20ac"),
        onClick: Data_Maybe.Nothing.value,
        initExpanded: true,
        disabled: false,
        getItems: function (v) {
            return Data_Functor.map(Data_Functor.functorArray)(TacoList.ExpandableItemWrapper)([ {
                disabled: false,
                onClick: Data_Maybe.Nothing.value,
                title: "asdasd",
                titleJSX: Data_Maybe.Nothing.value,
                id: "asdasd",
                value: new TacoList.TextValue("500 \u20ac"),
                getItems: function (v1) {
                    return [  ];
                },
                hasItems: false,
                initExpanded: false
            }, {
                disabled: true,
                onClick: Data_Maybe.Nothing.value,
                title: "asdasd",
                titleJSX: Data_Maybe.Nothing.value,
                id: "asdasd",
                value: new TacoList.TextValue("0 \u20ac"),
                getItems: function (v1) {
                    return [  ];
                },
                hasItems: false,
                initExpanded: false
            } ]);
        },
        hasItems: true
    }, {
        title: "B Rakennuttaminen",
        titleJSX: Data_Maybe.Nothing.value,
        id: "B Rakennuttaminen",
        value: new TacoList.TextValue("10 000 \u20ac"),
        onClick: Data_Maybe.Nothing.value,
        initExpanded: false,
        disabled: false,
        getItems: function (v) {
            return Data_Functor.map(Data_Functor.functorArray)(TacoList.ExpandableItemWrapper)([ {
                disabled: false,
                onClick: Data_Maybe.Nothing.value,
                title: "B1 Rakennuttajan hallinto",
                titleJSX: Data_Maybe.Nothing.value,
                id: "B1 Rakennuttajan hallinto",
                value: new TacoList.TextValue("5 000 \u20ac"),
                getItems: function (v1) {
                    return [  ];
                },
                hasItems: false,
                initExpanded: false
            }, {
                disabled: false,
                onClick: Data_Maybe.Nothing.value,
                title: "B2 Suunnittelu",
                titleJSX: Data_Maybe.Nothing.value,
                id: "B2 Suunnittelu",
                value: new TacoList.TextValue("5 000 \u20ac"),
                getItems: function (v1) {
                    return [  ];
                },
                hasItems: false,
                initExpanded: false
            } ]);
        },
        hasItems: true
    }, {
        title: "C Ty\xf6maatekniikka",
        titleJSX: Data_Maybe.Nothing.value,
        id: "C Ty\xf6maatekniikka",
        value: new TacoList.TextValue("0 \u20ac"),
        onClick: Data_Maybe.Nothing.value,
        initExpanded: false,
        disabled: true,
        getItems: function (v) {
            return [  ];
        },
        hasItems: false
    } ]
});
var example1_code = "import Box (box)\x0aimport Box.CSS (alignItems, justifyContent)\x0aimport React.Basic.DOM as R\x0aimport TacoConstants (Size(..))\x0a";
var sections = [ {
    title: "Default",
    view: [ example1_jsx ],
    code: example1_code
} ];
var demoView = {
    sections: sections,
    title: "TacoList",
    description: "A list component with expandable items"
};
module.exports = {
    demoView: demoView
};
