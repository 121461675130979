// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Show_Generic = require("../Data.Show.Generic/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_ReasonableCoerce = require("../Types.ReasonableCoerce/index.js");
var Success = (function () {
    function Success(value0) {
        this.value0 = value0;
    };
    Success.create = function (value0) {
        return new Success(value0);
    };
    return Success;
})();
var Loading = (function () {
    function Loading() {

    };
    Loading.value = new Loading();
    return Loading;
})();
var $$Error = (function () {
    function $$Error(value0) {
        this.value0 = value0;
    };
    $$Error.create = function (value0) {
        return new $$Error(value0);
    };
    return $$Error;
})();
var QueryClient = (function () {
    function QueryClient() {

    };
    QueryClient.value = new QueryClient();
    return QueryClient;
})();
var QR = function (x) {
    return x;
};
var tofuJSON = function (dictTofuJSON) {
    return TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "data";
        }
    })(TofuJSON.readMaybe(TofuJSON.tofuJSONForeign))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "error";
        }
    })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "message";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "message";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())))(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "isError";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "isLoading";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "isSuccess";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "data";
        }
    })(TofuJSON.readMaybe(TofuJSON.tofuJSONForeign))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "error";
        }
    })(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "message";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "message";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())))(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "isError";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "isLoading";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "isSuccess";
        }
    })(TofuJSON.readBoolean)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()());
};
var newtypeQR = {
    Coercible0: function () {
        return undefined;
    }
};
var mkQueryResult = function (dictTofuJSON) {
    return function (v) {
        if (v.isSuccess && v.data instanceof Data_Maybe.Just) {
            return Success.create(Types_ReasonableCoerce.reasonableCoerce("react-query has fetched our data with an Aff a function so the result is already of type a")(v.data.value0));
        };
        if (v.isLoading) {
            return Loading.value;
        };
        if (v.error instanceof Data_Maybe.Just) {
            return new $$Error(v.error.value0.message);
        };
        return new $$Error("Query in unknown state (no error but no success)");
    };
};
var genericQueryResult = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new Success(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return Loading.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
            return new $$Error(x.value0.value0);
        };
        throw new Error("Failed pattern match at ReactQuery.Internal (line 20, column 1 - line 20, column 64): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof Success) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof Loading) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
        };
        if (x instanceof $$Error) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0));
        };
        throw new Error("Failed pattern match at ReactQuery.Internal (line 20, column 1 - line 20, column 64): " + [ x.constructor.name ]);
    }
};
var showQueryResult = function (dictShow) {
    return {
        show: Data_Show_Generic.genericShow(genericQueryResult)(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsArgument(dictShow))({
            reflectSymbol: function () {
                return "Success";
            }
        }))(Data_Show_Generic.genericShowSum(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsNoArguments)({
            reflectSymbol: function () {
                return "Loading";
            }
        }))(Data_Show_Generic.genericShowConstructor(Data_Show_Generic.genericShowArgsArgument(Data_Show.showString))({
            reflectSymbol: function () {
                return "Error";
            }
        }))))
    };
};
var functorQueryResult = {
    map: function (v) {
        return function (v1) {
            if (v1 instanceof Success) {
                return Success.create(v(v1.value0));
            };
            if (v1 instanceof Loading) {
                return Loading.value;
            };
            if (v1 instanceof $$Error) {
                return new $$Error(v1.value0);
            };
            throw new Error("Failed pattern match at ReactQuery.Internal (line 23, column 1 - line 26, column 28): " + [ v.constructor.name, v1.constructor.name ]);
        };
    }
};
module.exports = {
    QueryClient: QueryClient,
    Success: Success,
    Loading: Loading,
    "Error": $$Error,
    QR: QR,
    mkQueryResult: mkQueryResult,
    genericQueryResult: genericQueryResult,
    showQueryResult: showQueryResult,
    functorQueryResult: functorQueryResult,
    newtypeQR: newtypeQR,
    tofuJSON: tofuJSON,
    "_useQuery": $foreign["_useQuery"],
    "_useQueryClient": $foreign["_useQueryClient"]
};
