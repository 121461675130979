import { UserRolesData } from "./EditUserRolesModal";
import { RoleId } from "../../../../../ts-bindings/RoleId";
import { UserRoleAssignment } from "../../../../../ts-bindings/UserRoleAssignment";
import { RoleIds, useRoleIds } from "./useRoleIds";
import { useCallback } from "react";

export function useFormDataMapper() {
  const roleIds = useRoleIds();
  const mapFormToRoleAssignments = useCallback(
    (data: UserRolesData) => mapToRoleAssignments(data, roleIds),
    [roleIds]
  );
  return {
    mapFormToRoleAssignments,
  };
}

function mapToRoleAssignments(
  data: UserRolesData,
  roleIds: RoleIds
): UserRoleAssignment[] {
  const mkRoleAssignment = (roleId: RoleId): UserRoleAssignment => ({
    roleId,
    projects: [],
    projectGroups: [],
  });

  const mkProjectRoleAssignment = (
    roleId: RoleId,
    projectIds: number[],
    projectGroupIds: string[]
  ): UserRoleAssignment[] =>
    projectIds.length === 0 && projectGroupIds.length === 0
      ? []
      : [
          {
            roleId,
            projects: projectIds,
            projectGroups: projectGroupIds,
          },
        ];

  const idsWithReadPerms = <U, T extends { id: U; readPermission: boolean }>(
    rows: T[]
  ): U[] => rows.filter((row) => row.readPermission).map((row) => row.id);

  const idsWithWritePerms = <U, T extends { id: U; writePermission: boolean }>(
    rows: T[]
  ): U[] => rows.filter((row) => row.writePermission).map((row) => row.id);

  const newRoles = [
    ...mkProjectRoleAssignment(
      roleIds.ccRead,
      idsWithReadPerms(data.costControl.project),
      idsWithReadPerms(data.costControl.projectGroup)
    ),
    ...mkProjectRoleAssignment(
      roleIds.ccWrite,
      idsWithWritePerms(data.costControl.project),
      idsWithWritePerms(data.costControl.projectGroup)
    ),
    ...mkProjectRoleAssignment(
      roleIds.ceRead,
      idsWithReadPerms(data.estimation.project),
      idsWithReadPerms(data.estimation.projectGroup)
    ),
    ...mkProjectRoleAssignment(
      roleIds.ceWrite,
      idsWithWritePerms(data.estimation.project),
      idsWithWritePerms(data.estimation.projectGroup)
    ),
  ];

  if (data.createProjectsPermission) {
    newRoles.push(mkRoleAssignment(roleIds.createProject));
  }
  if (data.costClassTargetEdit) {
    newRoles.push(mkRoleAssignment(roleIds.costClassTargetEdit));
  }
  if (data.mainUser) {
    newRoles.push(mkRoleAssignment(roleIds.mainUser));
  }

  if (data.costControl.appPermission) {
    newRoles.push(mkRoleAssignment(roleIds.cc));
  }
  if (data.estimation.appPermission) {
    newRoles.push(mkRoleAssignment(roleIds.ce));
  }

  if (data.costControl.readAll) {
    newRoles.push(mkRoleAssignment(roleIds.ccReadAll));
  }
  if (data.estimation.readAll) {
    newRoles.push(mkRoleAssignment(roleIds.ceReadAll));
  }

  if (data.costControl.writeAll) {
    newRoles.push(mkRoleAssignment(roleIds.ccWriteAll));
  }
  if (data.estimation.writeAll) {
    newRoles.push(mkRoleAssignment(roleIds.ceWriteAll));
  }
  return newRoles;
}
