// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Controls = require("../Controls/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_JSDate = require("../Data.JSDate/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var EditCostGroupItems = require("../EditCostGroupItems/index.js");
var Effect = require("../Effect/index.js");
var Formatters = require("../Formatters/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_CostGroup = require("../Types.CostGroup/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Utils = require("../Utils/index.js");
var ByDate = (function () {
    function ByDate() {

    };
    ByDate.value = new ByDate();
    return ByDate;
})();
var ByValue = (function () {
    function ByValue() {

    };
    ByValue.value = new ByValue();
    return ByValue;
})();
var ByName = (function () {
    function ByName() {

    };
    ByName.value = new ByName();
    return ByName;
})();
var ByCostClassLabel = (function () {
    function ByCostClassLabel() {

    };
    ByCostClassLabel.value = new ByCostClassLabel();
    return ByCostClassLabel;
})();
var ByModifierEmail = (function () {
    function ByModifierEmail() {

    };
    ByModifierEmail.value = new ByModifierEmail();
    return ByModifierEmail;
})();
var styleSet = TacoTable_Styles.foldingTableStyleSet;
var sortCostGroupItemData = function (v) {
    return function (r) {
        if (v instanceof ByDate) {
            return new TacoTable_Types.JSDateValue(r.modifyDate);
        };
        if (v instanceof ByName) {
            return new TacoTable_Types.StringValue(r.name);
        };
        if (v instanceof ByValue) {
            return new TacoTable_Types.NumberValue(r.value);
        };
        if (v instanceof ByCostClassLabel) {
            return new TacoTable_Types.StringValue(r.costClassLabel);
        };
        if (v instanceof ByModifierEmail) {
            return new TacoTable_Types.StringValue(r.modifierEmail);
        };
        throw new Error("Failed pattern match at CostGroupDetails.CostGroupItems (line 231, column 1 - line 231, column 93): " + [ v.constructor.name, r.constructor.name ]);
    };
};
var getNameCostGroupItemTableSortProps = {
    getName: function (v) {
        return "CostGroupItemTableSortProps";
    }
};
var eqCostGroupItemTableSortProps = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ByDate && y instanceof ByDate) {
                return true;
            };
            if (x instanceof ByValue && y instanceof ByValue) {
                return true;
            };
            if (x instanceof ByName && y instanceof ByName) {
                return true;
            };
            if (x instanceof ByCostClassLabel && y instanceof ByCostClassLabel) {
                return true;
            };
            if (x instanceof ByModifierEmail && y instanceof ByModifierEmail) {
                return true;
            };
            return false;
        };
    }
};
var costGroupItemsTableInstance = TacoTable.mkTableSortable(getNameCostGroupItemTableSortProps)("CostGroupItems");
var costGroupItems = function (dictOrd) {
    return TofuHooks.mkHookComponent("CostGroupItems")(function (props) {
        return function __do() {
            var v = TofuHooks.useState(false)();
            var openModal = v.value1(function (v1) {
                return true;
            });
            var mkCostGroupItemData = function (item) {
                var costClassName = (function () {
                    var v1 = Utils.lookupCostClassName(item.costClassCode)(props.costClasses);
                    if (v1 instanceof Data_Maybe.Just) {
                        return ": " + v1.value0;
                    };
                    if (v1 instanceof Data_Maybe.Nothing) {
                        return "";
                    };
                    throw new Error("Failed pattern match at CostGroupDetails.CostGroupItems (line 172, column 25 - line 174, column 24): " + [ v1.constructor.name ]);
                })();
                return {
                    costClassLabel: Data_Newtype.un()(Types_Newtypes.CostClassCode)(item.costClassCode) + costClassName,
                    modifyDate: Utils.lossyParseJSDate(Utils.defaultNull("")(item.modifyDate)),
                    value: item.value,
                    name: item.name,
                    id: item.id,
                    rowKey: Types_CostGroup.costGroupItemIdToString(item.id),
                    modifierEmail: Data_Maybe.fromMaybe("")(item.modifierEmail)
                };
            };
            var itemTypeEditName = (function () {
                if (props.addCostGroupItemType instanceof Types_CostGroup.AddCostGroupItemTypeEstimateItem) {
                    return "ennustetta";
                };
                if (props.addCostGroupItemType instanceof Types_CostGroup.AddCostGroupItemTypeTargetItem) {
                    return "tavoitetta";
                };
                throw new Error("Failed pattern match at CostGroupDetails.CostGroupItems (line 176, column 24 - line 178, column 53): " + [ props.addCostGroupItemType.constructor.name ]);
            })();
            var editCostGroupItemsButton = TacoButton.component()()({
                text: "Muokkaa " + itemTypeEditName,
                onClick: Data_Maybe.Just.create(openModal),
                buttonStyle: TacoButton_Types.Outlined.value
            });
            var costGroupItemColumns = [ {
                key: "date",
                label: React_Basic_DOM.text("P\xe4iv\xe4m\xe4\xe4r\xe4"),
                width: new TacoTable_Types.Flex(1),
                sortProperty: new Data_Maybe.Just(ByDate.value),
                cell: TacoTable_Types.PlainTextCell.create(function ($31) {
                    return Formatters.formatDateValue((function (v1) {
                        return v1.modifyDate;
                    })($31));
                }),
                headerJSX: Data_Maybe.Nothing.value
            }, {
                key: "costClassLabel",
                label: React_Basic_DOM.text("Panoslaji"),
                width: new TacoTable_Types.Flex(1),
                sortProperty: new Data_Maybe.Just(ByCostClassLabel.value),
                cell: new TacoTable_Types.PlainTextCell(function (v1) {
                    return v1.costClassLabel;
                }),
                headerJSX: Data_Maybe.Nothing.value
            }, {
                key: "value",
                label: React_Basic_DOM.text("Muutos"),
                width: new TacoTable_Types.Flex(1),
                sortProperty: new Data_Maybe.Just(ByValue.value),
                cell: new TacoTable_Types.PlainEuroCell(function (v1) {
                    return v1.value;
                }),
                headerJSX: Data_Maybe.Nothing.value
            }, {
                key: "name",
                label: React_Basic_DOM.text("Muutoksen syy"),
                width: new TacoTable_Types.Flex(6),
                sortProperty: new Data_Maybe.Just(ByName.value),
                cell: new TacoTable_Types.PlainTextCell(function (v1) {
                    return v1.name;
                }),
                headerJSX: Data_Maybe.Nothing.value
            }, {
                key: "modifierEmail",
                label: React_Basic_DOM.text("K\xe4sittelij\xe4"),
                width: new TacoTable_Types.Flex(3),
                sortProperty: new Data_Maybe.Just(ByModifierEmail.value),
                cell: new TacoTable_Types.PlainTextCell(function (v1) {
                    return v1.modifierEmail;
                }),
                headerJSX: Data_Maybe.Nothing.value
            } ];
            var mkCostGroupItemRow = function (r) {
                return {
                    rowData: r,
                    rowKey: r.rowKey,
                    onClick: function (v1) {
                        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                    },
                    className: "",
                    foldingElement: Data_Maybe.Nothing.value,
                    columns: costGroupItemColumns
                };
            };
            var costGroupItemData = Data_Functor.map(Data_Functor.functorArray)(function ($32) {
                return mkCostGroupItemRow(mkCostGroupItemData($32));
            })(props.costGroupItems);
            var costGroupItemsTable = TacoTable.tableSortable(getNameCostGroupItemTableSortProps)(eqCostGroupItemTableSortProps)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "value";
                }
            })(Data_Eq.eqNumber))()({
                reflectSymbol: function () {
                    return "rowKey";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "name";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "modifyDate";
                }
            })(Data_JSDate.eqJSDate))()({
                reflectSymbol: function () {
                    return "modifierEmail";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_CostGroup.eqCostGroupItemId))()({
                reflectSymbol: function () {
                    return "costClassLabel";
                }
            })(Data_Eq.eqString)))(costGroupItemsTableInstance)(new TacoTable_Types.SortingCriteria(TacoTable_Types.Ascending.value, new Data_Maybe.Just(ByDate.value), sortCostGroupItemData))(Data_Maybe.Nothing.value)({
                rows: costGroupItemData,
                columns: costGroupItemColumns,
                showHeader: true,
                styleSet: styleSet
            });
            var controls = Controls.component()()({
                leftControls: [ editCostGroupItemsButton ],
                bottomGutter: true
            });
            var closeModal = v.value1(function (v1) {
                return false;
            });
            var cgu = Data_Newtype.un()(Types_CostGroup.CostGroupUnit)(props.costGroupUnit);
            var editCostGroupItems = EditCostGroupItems.component({
                projectId: cgu.projectId,
                costGroupUnit: props.costGroupUnit,
                costClasses: props.costClasses,
                addCostGroupItemType: props.addCostGroupItemType,
                socialExpenses: props.socialExpenses,
                costGroupItems: props.costGroupItems,
                costClassResults: props.costClassResults,
                modalOpen: v.value0,
                closeModal: closeModal
            });
            return ReactBasicUtils["div'"]("costGroupItems-table-container")([ controls, editCostGroupItems, costGroupItemsTable ]);
        };
    });
};
var estimateItemsListingComponent = TofuHooks.mkHookComponent("EstimateItemsListingComponent")(function (props) {
    return Control_Applicative.pure(Effect.applicativeEffect)(costGroupItems(Types_CostGroup.ordEstimateItemId)({
        costGroupItems: props.estimateItems,
        costClassResults: props.costClassResults,
        costGroupUnit: props.costGroupUnit,
        costClasses: props.costClasses,
        addCostGroupItemType: Types_CostGroup.AddCostGroupItemTypeEstimateItem.value,
        fromCostGroupItemId: function (v) {
            if (v instanceof Types_CostGroup.CostGroupEstimateItemId) {
                return new Data_Maybe.Just(v.value0);
            };
            return Data_Maybe.Nothing.value;
        },
        toCostGroupItemId: Types_CostGroup.CostGroupEstimateItemId.create,
        socialExpenses: props.socialExpenses
    }));
});
var targetItemsListingComponent = TofuHooks.mkHookComponent("TargetItemsListingComponent")(function (props) {
    return Control_Applicative.pure(Effect.applicativeEffect)(costGroupItems(Types_CostGroup.ordTargetItemId)({
        costGroupItems: props.targetItems,
        costClassResults: props.costClassResults,
        costGroupUnit: props.costGroupUnit,
        costClasses: props.costClasses,
        addCostGroupItemType: Types_CostGroup.AddCostGroupItemTypeTargetItem.value,
        fromCostGroupItemId: function (v) {
            if (v instanceof Types_CostGroup.CostGroupTargetItemId) {
                return new Data_Maybe.Just(v.value0);
            };
            return Data_Maybe.Nothing.value;
        },
        toCostGroupItemId: Types_CostGroup.CostGroupTargetItemId.create,
        socialExpenses: props.socialExpenses
    }));
});
module.exports = {
    estimateItemsListingComponent: estimateItemsListingComponent,
    targetItemsListingComponent: targetItemsListingComponent
};
