// Generated by purs version 0.14.5
"use strict";
var Data_Maybe = require("../Data.Maybe/index.js");
var DixonMeasurements_Types = require("../DixonMeasurements.Types/index.js");
var defaultEditorState = {
    mode: DixonMeasurements_Types.SelectMode.value,
    files: [  ],
    measurement_groups: [  ],
    measurements: [  ],
    measurement_group_links: [  ],
    active_drawing_rotation: 0.0,
    active_drawing_scale_measurement_meters: Data_Maybe.Nothing.value,
    active_drawing_print_dimensions: Data_Maybe.Nothing.value,
    active_drawing_preview_resolution: Data_Maybe.Nothing.value,
    loading: Data_Maybe.Nothing.value
};
module.exports = {
    defaultEditorState: defaultEditorState
};
