// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Effect = require("../Effect/index.js");
var FoldingTable_Filter = require("../FoldingTable.Filter/index.js");
var Locale = require("../Locale/index.js");
var PortfolioFilterSettings_Styles = require("../PortfolioFilterSettings.Styles/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoSelect = require("../TacoSelect/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Utils = require("../Utils/index.js");
var stringFilterControl = function (curVal) {
    return function (onChange) {
        return TacoInput.stringInput()()(Data_Eq.eqString)({
            value: Data_Maybe.fromMaybe("")(curVal.string),
            onChange: TacoInput.changeHandler(TacoInput.inputValueString)(function (value) {
                return onChange({
                    string: new Data_Maybe.Just(value)
                });
            })
        });
    };
};
var selectionFilter = function (v) {
    return function (options) {
        return function (setter) {
            var selectOptions = Data_Functor.map(Data_Functor.functorArray)(function (option) {
                return {
                    value: option,
                    text: option
                };
            })(options);
            var mkSelection = function (value) {
                var $89 = value === "";
                if ($89) {
                    return Data_Maybe.Nothing.value;
                };
                return new Data_Maybe.Just({
                    value: value,
                    display: Data_Maybe.Nothing.value
                });
            };
            var onChange = function (value) {
                return setter({
                    selection: mkSelection(value)
                });
            };
            var v1 = Data_Array.length(options);
            if (v1 === 0) {
                return React_Basic.empty;
            };
            return TacoSelect.component()()({
                onChange: TacoInput.changeHandler(TacoInput.inputValueString)(onChange),
                value: Data_Maybe.maybe("")(function (v2) {
                    return v2.value;
                })(v.selection),
                items: selectOptions,
                showEmpty: true
            });
        };
    };
};
var rangeSeparator = React_Basic_Emotion.element(React_Basic_DOM_Generated["span'"]())({
    className: "range-separator",
    css: PortfolioFilterSettings_Styles.rangeSeparatorStyles,
    children: [ React_Basic_DOM.text("\u2013") ]
});
var numericValueFieldWithLabelText = function (v) {
    return function (name) {
        return function (defaultValue) {
            return function (setter) {
                return TacoInput.numberInput()()(Data_Eq.eqNumber)({
                    type: "number",
                    value: Data_Int.toNumber(Data_Maybe.fromMaybe(0)(defaultValue)),
                    precision: 2,
                    placeholder: name + ".",
                    onChange: TacoInput.changeHandler(TacoInput.inputValueNumber)(function (input) {
                        return setter(Data_Maybe.Just.create(Data_Int.round(input)));
                    })
                });
            };
        };
    };
};
var mkInputControl = function (v) {
    return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        className: "input-control-row",
        css: PortfolioFilterSettings_Styles.inputControlRowStyles,
        children: [ TacoCheckbox.component()()({
            className: "toggle",
            isChecked: Data_Eq.eq(FoldingTable_Filter.eqVisibility)(v.visibility)(FoldingTable_Filter.Visible.value),
            onToggle: TacoCheckbox.NoEvent.create(v.onColumnVisibilityChange((function () {
                if (v.visibility instanceof FoldingTable_Filter.Visible) {
                    return FoldingTable_Filter.Hidden.value;
                };
                if (v.visibility instanceof FoldingTable_Filter.Hidden) {
                    return FoldingTable_Filter.Visible.value;
                };
                throw new Error("Failed pattern match at PortfolioFilterSettings (line 430, column 15 - line 432, column 34): " + [ v.visibility.constructor.name ]);
            })())),
            labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel(v.label)),
            testId: "toggle-" + v.label
        }), v.filterControl, Data_Monoid.guard(React_Basic.monoidJSX)(v.filterEnabled)(TacoButton.component()()({
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconCloseCrossV1.value),
            text: Locale.lookup_("remove_filter"),
            onClick: new Data_Maybe.Just(v.clearFilter)
        })) ]
    });
};
var mkDateField = function (v) {
    return function (defaultValue) {
        return function (setter) {
            var handleDateValueChange = function (value) {
                if (value === "") {
                    return setter(Data_Maybe.Nothing.value);
                };
                var parsedDate = Utils.lossyParseJSDate(value);
                return setter(new Data_Maybe.Just(parsedDate));
            };
            return ReactBasicUtils["div'"]((function () {
                var $101 = Data_Maybe.isJust(defaultValue);
                if ($101) {
                    return "hasValue";
                };
                return "";
            })())(Control_Applicative.pure(Control_Applicative.applicativeArray)(TacoInput.stringInput()()(Data_Eq.eqString)({
                type: "date",
                clickEventHandling: TacoInput.NoHandling.value,
                value: Data_Maybe.fromMaybe("")(Data_Functor.map(Data_Maybe.functorMaybe)(Utils.lossyToHtmlDateString)(defaultValue)),
                onChange: TacoInput.changeHandler(TacoInput.inputValueString)(handleDateValueChange)
            })));
        };
    };
};
var mkDateFilter = function (v) {
    return function (setter) {
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "date-filter",
            css: PortfolioFilterSettings_Styles.inputRangeContainerStyles,
            children: [ mkDateField("min")(v.min)(function (minValue) {
                return setter({
                    min: minValue,
                    max: v.max
                });
            }), rangeSeparator, mkDateField("max")(v.max)(function (maxValue) {
                return setter({
                    min: v.min,
                    max: maxValue
                });
            }) ]
        });
    };
};
var mkCurrencyFilter = function (v) {
    return function (setter) {
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "currency-filter",
            css: PortfolioFilterSettings_Styles.inputRangeContainerStyles,
            children: [ numericValueFieldWithLabelText("\u20ac")("min")(v.min)(function (minValue) {
                return setter({
                    min: minValue,
                    max: v.max
                });
            }), rangeSeparator, numericValueFieldWithLabelText("\u20ac")("max")(v.max)(function (maxValue) {
                return setter({
                    min: v.min,
                    max: maxValue
                });
            }) ]
        });
    };
};
var portfolioFilterSettings = TofuHooks.mkHookComponent("PortfolioFilterSettings")(function (props) {
    var stateOptions = [ {
        value: "0",
        text: Locale.lookup_("project_state_0")
    }, {
        value: "1",
        text: Locale.lookup_("project_state_1")
    }, {
        value: "2",
        text: Locale.lookup_("project_state_2")
    }, {
        value: "3",
        text: Locale.lookup_("project_state_3")
    } ];
    var projectStates = Data_Functor.map(Data_Functor.functorArray)(function (v) {
        return v.text;
    })(stateOptions);
    var headings = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        className: "headings",
        css: PortfolioFilterSettings_Styles.inputControlRowStyles,
        children: [ TacoText.component()()({
            text: "N\xe4yt\xe4 sarake",
            weight: TacoText_Types.Bold.value
        }), TacoText.component()()({
            text: "Rajaa Tulokset",
            weight: TacoText_Types.Bold.value
        }), TacoButton.component()()({
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconCloseCrossV1.value),
            text: Locale.lookup_("remove_all_filters"),
            onClick: new Data_Maybe.Just(props.clearFilters)
        }) ]
    });
    var filterControls = [ mkInputControl({
        label: Locale.lookup_("project_code"),
        filterControl: stringFilterControl(props.filterConfig.code)(function ($$new) {
            return props.onColumnFilterChange({
                code: $$new,
                name: props.filterConfig.name,
                version: props.filterConfig.version,
                projectManager: props.filterConfig.projectManager,
                targetCosts: props.filterConfig.targetCosts,
                estimatedCosts: props.filterConfig.estimatedCosts,
                actualCosts: props.filterConfig.actualCosts,
                targetIncome: props.filterConfig.targetIncome,
                estimatedIncome: props.filterConfig.estimatedIncome,
                actualIncome: props.filterConfig.actualIncome,
                actualIncomeApproved: props.filterConfig.actualIncomeApproved,
                targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
                estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
                actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
                startDate: props.filterConfig.startDate,
                endDate: props.filterConfig.endDate,
                contractType: props.filterConfig.contractType,
                state: props.filterConfig.state,
                projectType: props.filterConfig.projectType,
                contractPrice: props.filterConfig.contractPrice,
                taxRate: props.filterConfig.taxRate,
                ownProject: props.filterConfig.ownProject,
                projectGroup: props.filterConfig.projectGroup,
                constructionType: props.filterConfig.constructionType,
                financingType: props.filterConfig.financingType
            });
        }),
        visibility: props.columnVisibility.code,
        onColumnVisibilityChange: function (v) {
            return props.onColumnVisibilityChange({
                code: v,
                name: props.columnVisibility.name,
                version: props.columnVisibility.version,
                projectManager: props.columnVisibility.projectManager,
                targetCosts: props.columnVisibility.targetCosts,
                estimatedCosts: props.columnVisibility.estimatedCosts,
                actualCosts: props.columnVisibility.actualCosts,
                targetIncome: props.columnVisibility.targetIncome,
                estimatedIncome: props.columnVisibility.estimatedIncome,
                actualIncome: props.columnVisibility.actualIncome,
                actualIncomeApproved: props.columnVisibility.actualIncomeApproved,
                targetDiffAndMargin: props.columnVisibility.targetDiffAndMargin,
                estimatedDiffAndMargin: props.columnVisibility.estimatedDiffAndMargin,
                actualDiffAndMargin: props.columnVisibility.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.columnVisibility.actualDiffAndMarginApproved,
                startDate: props.columnVisibility.startDate,
                endDate: props.columnVisibility.endDate,
                contractType: props.columnVisibility.contractType,
                state: props.columnVisibility.state,
                projectType: props.columnVisibility.projectType,
                contractPrice: props.columnVisibility.contractPrice,
                taxRate: props.columnVisibility.taxRate,
                ownProject: props.columnVisibility.ownProject,
                projectGroup: props.columnVisibility.projectGroup,
                constructionType: props.columnVisibility.constructionType,
                financingType: props.columnVisibility.financingType
            });
        },
        filterEnabled: FoldingTable_Filter.isStringFilterEnabled(props.filterConfig.code),
        clearFilter: props.onColumnFilterChange({
            code: {
                string: Data_Maybe.Nothing.value
            },
            name: props.filterConfig.name,
            version: props.filterConfig.version,
            projectManager: props.filterConfig.projectManager,
            targetCosts: props.filterConfig.targetCosts,
            estimatedCosts: props.filterConfig.estimatedCosts,
            actualCosts: props.filterConfig.actualCosts,
            targetIncome: props.filterConfig.targetIncome,
            estimatedIncome: props.filterConfig.estimatedIncome,
            actualIncome: props.filterConfig.actualIncome,
            actualIncomeApproved: props.filterConfig.actualIncomeApproved,
            targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
            estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
            actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
            actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
            startDate: props.filterConfig.startDate,
            endDate: props.filterConfig.endDate,
            contractType: props.filterConfig.contractType,
            state: props.filterConfig.state,
            projectType: props.filterConfig.projectType,
            contractPrice: props.filterConfig.contractPrice,
            taxRate: props.filterConfig.taxRate,
            ownProject: props.filterConfig.ownProject,
            projectGroup: props.filterConfig.projectGroup,
            constructionType: props.filterConfig.constructionType,
            financingType: props.filterConfig.financingType
        })
    }), mkInputControl({
        label: Locale.lookup_("project_name"),
        filterControl: stringFilterControl(props.filterConfig.name)(function ($$new) {
            return props.onColumnFilterChange({
                code: props.filterConfig.code,
                name: $$new,
                version: props.filterConfig.version,
                projectManager: props.filterConfig.projectManager,
                targetCosts: props.filterConfig.targetCosts,
                estimatedCosts: props.filterConfig.estimatedCosts,
                actualCosts: props.filterConfig.actualCosts,
                targetIncome: props.filterConfig.targetIncome,
                estimatedIncome: props.filterConfig.estimatedIncome,
                actualIncome: props.filterConfig.actualIncome,
                actualIncomeApproved: props.filterConfig.actualIncomeApproved,
                targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
                estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
                actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
                startDate: props.filterConfig.startDate,
                endDate: props.filterConfig.endDate,
                contractType: props.filterConfig.contractType,
                state: props.filterConfig.state,
                projectType: props.filterConfig.projectType,
                contractPrice: props.filterConfig.contractPrice,
                taxRate: props.filterConfig.taxRate,
                ownProject: props.filterConfig.ownProject,
                projectGroup: props.filterConfig.projectGroup,
                constructionType: props.filterConfig.constructionType,
                financingType: props.filterConfig.financingType
            });
        }),
        visibility: props.columnVisibility.name,
        onColumnVisibilityChange: function (v) {
            return props.onColumnVisibilityChange({
                code: props.columnVisibility.code,
                name: v,
                version: props.columnVisibility.version,
                projectManager: props.columnVisibility.projectManager,
                targetCosts: props.columnVisibility.targetCosts,
                estimatedCosts: props.columnVisibility.estimatedCosts,
                actualCosts: props.columnVisibility.actualCosts,
                targetIncome: props.columnVisibility.targetIncome,
                estimatedIncome: props.columnVisibility.estimatedIncome,
                actualIncome: props.columnVisibility.actualIncome,
                actualIncomeApproved: props.columnVisibility.actualIncomeApproved,
                targetDiffAndMargin: props.columnVisibility.targetDiffAndMargin,
                estimatedDiffAndMargin: props.columnVisibility.estimatedDiffAndMargin,
                actualDiffAndMargin: props.columnVisibility.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.columnVisibility.actualDiffAndMarginApproved,
                startDate: props.columnVisibility.startDate,
                endDate: props.columnVisibility.endDate,
                contractType: props.columnVisibility.contractType,
                state: props.columnVisibility.state,
                projectType: props.columnVisibility.projectType,
                contractPrice: props.columnVisibility.contractPrice,
                taxRate: props.columnVisibility.taxRate,
                ownProject: props.columnVisibility.ownProject,
                projectGroup: props.columnVisibility.projectGroup,
                constructionType: props.columnVisibility.constructionType,
                financingType: props.columnVisibility.financingType
            });
        },
        filterEnabled: FoldingTable_Filter.isStringFilterEnabled(props.filterConfig.name),
        clearFilter: props.onColumnFilterChange({
            code: props.filterConfig.code,
            name: {
                string: Data_Maybe.Nothing.value
            },
            version: props.filterConfig.version,
            projectManager: props.filterConfig.projectManager,
            targetCosts: props.filterConfig.targetCosts,
            estimatedCosts: props.filterConfig.estimatedCosts,
            actualCosts: props.filterConfig.actualCosts,
            targetIncome: props.filterConfig.targetIncome,
            estimatedIncome: props.filterConfig.estimatedIncome,
            actualIncome: props.filterConfig.actualIncome,
            actualIncomeApproved: props.filterConfig.actualIncomeApproved,
            targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
            estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
            actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
            actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
            startDate: props.filterConfig.startDate,
            endDate: props.filterConfig.endDate,
            contractType: props.filterConfig.contractType,
            state: props.filterConfig.state,
            projectType: props.filterConfig.projectType,
            contractPrice: props.filterConfig.contractPrice,
            taxRate: props.filterConfig.taxRate,
            ownProject: props.filterConfig.ownProject,
            projectGroup: props.filterConfig.projectGroup,
            constructionType: props.filterConfig.constructionType,
            financingType: props.filterConfig.financingType
        })
    }), mkInputControl({
        label: Locale.lookup_("project_version"),
        filterControl: stringFilterControl(props.filterConfig.version)(function ($$new) {
            return props.onColumnFilterChange({
                code: props.filterConfig.code,
                name: props.filterConfig.name,
                version: $$new,
                projectManager: props.filterConfig.projectManager,
                targetCosts: props.filterConfig.targetCosts,
                estimatedCosts: props.filterConfig.estimatedCosts,
                actualCosts: props.filterConfig.actualCosts,
                targetIncome: props.filterConfig.targetIncome,
                estimatedIncome: props.filterConfig.estimatedIncome,
                actualIncome: props.filterConfig.actualIncome,
                actualIncomeApproved: props.filterConfig.actualIncomeApproved,
                targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
                estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
                actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
                startDate: props.filterConfig.startDate,
                endDate: props.filterConfig.endDate,
                contractType: props.filterConfig.contractType,
                state: props.filterConfig.state,
                projectType: props.filterConfig.projectType,
                contractPrice: props.filterConfig.contractPrice,
                taxRate: props.filterConfig.taxRate,
                ownProject: props.filterConfig.ownProject,
                projectGroup: props.filterConfig.projectGroup,
                constructionType: props.filterConfig.constructionType,
                financingType: props.filterConfig.financingType
            });
        }),
        visibility: props.columnVisibility.version,
        onColumnVisibilityChange: function (v) {
            return props.onColumnVisibilityChange({
                code: props.columnVisibility.code,
                name: props.columnVisibility.name,
                version: v,
                projectManager: props.columnVisibility.projectManager,
                targetCosts: props.columnVisibility.targetCosts,
                estimatedCosts: props.columnVisibility.estimatedCosts,
                actualCosts: props.columnVisibility.actualCosts,
                targetIncome: props.columnVisibility.targetIncome,
                estimatedIncome: props.columnVisibility.estimatedIncome,
                actualIncome: props.columnVisibility.actualIncome,
                actualIncomeApproved: props.columnVisibility.actualIncomeApproved,
                targetDiffAndMargin: props.columnVisibility.targetDiffAndMargin,
                estimatedDiffAndMargin: props.columnVisibility.estimatedDiffAndMargin,
                actualDiffAndMargin: props.columnVisibility.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.columnVisibility.actualDiffAndMarginApproved,
                startDate: props.columnVisibility.startDate,
                endDate: props.columnVisibility.endDate,
                contractType: props.columnVisibility.contractType,
                state: props.columnVisibility.state,
                projectType: props.columnVisibility.projectType,
                contractPrice: props.columnVisibility.contractPrice,
                taxRate: props.columnVisibility.taxRate,
                ownProject: props.columnVisibility.ownProject,
                projectGroup: props.columnVisibility.projectGroup,
                constructionType: props.columnVisibility.constructionType,
                financingType: props.columnVisibility.financingType
            });
        },
        filterEnabled: FoldingTable_Filter.isStringFilterEnabled(props.filterConfig.version),
        clearFilter: props.onColumnFilterChange({
            code: props.filterConfig.code,
            name: props.filterConfig.name,
            version: {
                string: Data_Maybe.Nothing.value
            },
            projectManager: props.filterConfig.projectManager,
            targetCosts: props.filterConfig.targetCosts,
            estimatedCosts: props.filterConfig.estimatedCosts,
            actualCosts: props.filterConfig.actualCosts,
            targetIncome: props.filterConfig.targetIncome,
            estimatedIncome: props.filterConfig.estimatedIncome,
            actualIncome: props.filterConfig.actualIncome,
            actualIncomeApproved: props.filterConfig.actualIncomeApproved,
            targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
            estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
            actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
            actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
            startDate: props.filterConfig.startDate,
            endDate: props.filterConfig.endDate,
            contractType: props.filterConfig.contractType,
            state: props.filterConfig.state,
            projectType: props.filterConfig.projectType,
            contractPrice: props.filterConfig.contractPrice,
            taxRate: props.filterConfig.taxRate,
            ownProject: props.filterConfig.ownProject,
            projectGroup: props.filterConfig.projectGroup,
            constructionType: props.filterConfig.constructionType,
            financingType: props.filterConfig.financingType
        })
    }), mkInputControl({
        label: Locale.lookup_("common_project_manager"),
        filterControl: selectionFilter(props.filterConfig.projectManager)(Data_Functor.map(Data_Functor.functorArray)(Data_Newtype.unwrap())(props.selectOptions.projectManagers))(function ($$new) {
            return props.onColumnFilterChange({
                code: props.filterConfig.code,
                name: props.filterConfig.name,
                version: props.filterConfig.version,
                projectManager: $$new,
                targetCosts: props.filterConfig.targetCosts,
                estimatedCosts: props.filterConfig.estimatedCosts,
                actualCosts: props.filterConfig.actualCosts,
                targetIncome: props.filterConfig.targetIncome,
                estimatedIncome: props.filterConfig.estimatedIncome,
                actualIncome: props.filterConfig.actualIncome,
                actualIncomeApproved: props.filterConfig.actualIncomeApproved,
                targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
                estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
                actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
                startDate: props.filterConfig.startDate,
                endDate: props.filterConfig.endDate,
                contractType: props.filterConfig.contractType,
                state: props.filterConfig.state,
                projectType: props.filterConfig.projectType,
                contractPrice: props.filterConfig.contractPrice,
                taxRate: props.filterConfig.taxRate,
                ownProject: props.filterConfig.ownProject,
                projectGroup: props.filterConfig.projectGroup,
                constructionType: props.filterConfig.constructionType,
                financingType: props.filterConfig.financingType
            });
        }),
        visibility: props.columnVisibility.projectManager,
        onColumnVisibilityChange: function (v) {
            return props.onColumnVisibilityChange({
                code: props.columnVisibility.code,
                name: props.columnVisibility.name,
                version: props.columnVisibility.version,
                projectManager: v,
                targetCosts: props.columnVisibility.targetCosts,
                estimatedCosts: props.columnVisibility.estimatedCosts,
                actualCosts: props.columnVisibility.actualCosts,
                targetIncome: props.columnVisibility.targetIncome,
                estimatedIncome: props.columnVisibility.estimatedIncome,
                actualIncome: props.columnVisibility.actualIncome,
                actualIncomeApproved: props.columnVisibility.actualIncomeApproved,
                targetDiffAndMargin: props.columnVisibility.targetDiffAndMargin,
                estimatedDiffAndMargin: props.columnVisibility.estimatedDiffAndMargin,
                actualDiffAndMargin: props.columnVisibility.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.columnVisibility.actualDiffAndMarginApproved,
                startDate: props.columnVisibility.startDate,
                endDate: props.columnVisibility.endDate,
                contractType: props.columnVisibility.contractType,
                state: props.columnVisibility.state,
                projectType: props.columnVisibility.projectType,
                contractPrice: props.columnVisibility.contractPrice,
                taxRate: props.columnVisibility.taxRate,
                ownProject: props.columnVisibility.ownProject,
                projectGroup: props.columnVisibility.projectGroup,
                constructionType: props.columnVisibility.constructionType,
                financingType: props.columnVisibility.financingType
            });
        },
        filterEnabled: FoldingTable_Filter.isSelectionFilterEnabled(props.filterConfig.projectManager),
        clearFilter: props.onColumnFilterChange({
            code: props.filterConfig.code,
            name: props.filterConfig.name,
            version: props.filterConfig.version,
            projectManager: {
                selection: Data_Maybe.Nothing.value
            },
            targetCosts: props.filterConfig.targetCosts,
            estimatedCosts: props.filterConfig.estimatedCosts,
            actualCosts: props.filterConfig.actualCosts,
            targetIncome: props.filterConfig.targetIncome,
            estimatedIncome: props.filterConfig.estimatedIncome,
            actualIncome: props.filterConfig.actualIncome,
            actualIncomeApproved: props.filterConfig.actualIncomeApproved,
            targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
            estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
            actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
            actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
            startDate: props.filterConfig.startDate,
            endDate: props.filterConfig.endDate,
            contractType: props.filterConfig.contractType,
            state: props.filterConfig.state,
            projectType: props.filterConfig.projectType,
            contractPrice: props.filterConfig.contractPrice,
            taxRate: props.filterConfig.taxRate,
            ownProject: props.filterConfig.ownProject,
            projectGroup: props.filterConfig.projectGroup,
            constructionType: props.filterConfig.constructionType,
            financingType: props.filterConfig.financingType
        })
    }), mkInputControl({
        label: Locale.lookup_("target_costs"),
        filterControl: mkCurrencyFilter(props.filterConfig.targetCosts)(function ($$new) {
            return props.onColumnFilterChange({
                code: props.filterConfig.code,
                name: props.filterConfig.name,
                version: props.filterConfig.version,
                projectManager: props.filterConfig.projectManager,
                targetCosts: $$new,
                estimatedCosts: props.filterConfig.estimatedCosts,
                actualCosts: props.filterConfig.actualCosts,
                targetIncome: props.filterConfig.targetIncome,
                estimatedIncome: props.filterConfig.estimatedIncome,
                actualIncome: props.filterConfig.actualIncome,
                actualIncomeApproved: props.filterConfig.actualIncomeApproved,
                targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
                estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
                actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
                startDate: props.filterConfig.startDate,
                endDate: props.filterConfig.endDate,
                contractType: props.filterConfig.contractType,
                state: props.filterConfig.state,
                projectType: props.filterConfig.projectType,
                contractPrice: props.filterConfig.contractPrice,
                taxRate: props.filterConfig.taxRate,
                ownProject: props.filterConfig.ownProject,
                projectGroup: props.filterConfig.projectGroup,
                constructionType: props.filterConfig.constructionType,
                financingType: props.filterConfig.financingType
            });
        }),
        visibility: props.columnVisibility.targetCosts,
        onColumnVisibilityChange: function (v) {
            return props.onColumnVisibilityChange({
                code: props.columnVisibility.code,
                name: props.columnVisibility.name,
                version: props.columnVisibility.version,
                projectManager: props.columnVisibility.projectManager,
                targetCosts: v,
                estimatedCosts: props.columnVisibility.estimatedCosts,
                actualCosts: props.columnVisibility.actualCosts,
                targetIncome: props.columnVisibility.targetIncome,
                estimatedIncome: props.columnVisibility.estimatedIncome,
                actualIncome: props.columnVisibility.actualIncome,
                actualIncomeApproved: props.columnVisibility.actualIncomeApproved,
                targetDiffAndMargin: props.columnVisibility.targetDiffAndMargin,
                estimatedDiffAndMargin: props.columnVisibility.estimatedDiffAndMargin,
                actualDiffAndMargin: props.columnVisibility.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.columnVisibility.actualDiffAndMarginApproved,
                startDate: props.columnVisibility.startDate,
                endDate: props.columnVisibility.endDate,
                contractType: props.columnVisibility.contractType,
                state: props.columnVisibility.state,
                projectType: props.columnVisibility.projectType,
                contractPrice: props.columnVisibility.contractPrice,
                taxRate: props.columnVisibility.taxRate,
                ownProject: props.columnVisibility.ownProject,
                projectGroup: props.columnVisibility.projectGroup,
                constructionType: props.columnVisibility.constructionType,
                financingType: props.columnVisibility.financingType
            });
        },
        filterEnabled: FoldingTable_Filter.isNumericFilterEnabled(props.filterConfig.targetCosts),
        clearFilter: props.onColumnFilterChange({
            code: props.filterConfig.code,
            name: props.filterConfig.name,
            version: props.filterConfig.version,
            projectManager: props.filterConfig.projectManager,
            targetCosts: {
                min: Data_Maybe.Nothing.value,
                max: Data_Maybe.Nothing.value
            },
            estimatedCosts: props.filterConfig.estimatedCosts,
            actualCosts: props.filterConfig.actualCosts,
            targetIncome: props.filterConfig.targetIncome,
            estimatedIncome: props.filterConfig.estimatedIncome,
            actualIncome: props.filterConfig.actualIncome,
            actualIncomeApproved: props.filterConfig.actualIncomeApproved,
            targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
            estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
            actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
            actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
            startDate: props.filterConfig.startDate,
            endDate: props.filterConfig.endDate,
            contractType: props.filterConfig.contractType,
            state: props.filterConfig.state,
            projectType: props.filterConfig.projectType,
            contractPrice: props.filterConfig.contractPrice,
            taxRate: props.filterConfig.taxRate,
            ownProject: props.filterConfig.ownProject,
            projectGroup: props.filterConfig.projectGroup,
            constructionType: props.filterConfig.constructionType,
            financingType: props.filterConfig.financingType
        })
    }), mkInputControl({
        label: Locale.lookup_("estimate_costs"),
        filterControl: mkCurrencyFilter(props.filterConfig.estimatedCosts)(function ($$new) {
            return props.onColumnFilterChange({
                code: props.filterConfig.code,
                name: props.filterConfig.name,
                version: props.filterConfig.version,
                projectManager: props.filterConfig.projectManager,
                targetCosts: props.filterConfig.targetCosts,
                estimatedCosts: $$new,
                actualCosts: props.filterConfig.actualCosts,
                targetIncome: props.filterConfig.targetIncome,
                estimatedIncome: props.filterConfig.estimatedIncome,
                actualIncome: props.filterConfig.actualIncome,
                actualIncomeApproved: props.filterConfig.actualIncomeApproved,
                targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
                estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
                actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
                startDate: props.filterConfig.startDate,
                endDate: props.filterConfig.endDate,
                contractType: props.filterConfig.contractType,
                state: props.filterConfig.state,
                projectType: props.filterConfig.projectType,
                contractPrice: props.filterConfig.contractPrice,
                taxRate: props.filterConfig.taxRate,
                ownProject: props.filterConfig.ownProject,
                projectGroup: props.filterConfig.projectGroup,
                constructionType: props.filterConfig.constructionType,
                financingType: props.filterConfig.financingType
            });
        }),
        visibility: props.columnVisibility.estimatedCosts,
        onColumnVisibilityChange: function (v) {
            return props.onColumnVisibilityChange({
                code: props.columnVisibility.code,
                name: props.columnVisibility.name,
                version: props.columnVisibility.version,
                projectManager: props.columnVisibility.projectManager,
                targetCosts: props.columnVisibility.targetCosts,
                estimatedCosts: v,
                actualCosts: props.columnVisibility.actualCosts,
                targetIncome: props.columnVisibility.targetIncome,
                estimatedIncome: props.columnVisibility.estimatedIncome,
                actualIncome: props.columnVisibility.actualIncome,
                actualIncomeApproved: props.columnVisibility.actualIncomeApproved,
                targetDiffAndMargin: props.columnVisibility.targetDiffAndMargin,
                estimatedDiffAndMargin: props.columnVisibility.estimatedDiffAndMargin,
                actualDiffAndMargin: props.columnVisibility.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.columnVisibility.actualDiffAndMarginApproved,
                startDate: props.columnVisibility.startDate,
                endDate: props.columnVisibility.endDate,
                contractType: props.columnVisibility.contractType,
                state: props.columnVisibility.state,
                projectType: props.columnVisibility.projectType,
                contractPrice: props.columnVisibility.contractPrice,
                taxRate: props.columnVisibility.taxRate,
                ownProject: props.columnVisibility.ownProject,
                projectGroup: props.columnVisibility.projectGroup,
                constructionType: props.columnVisibility.constructionType,
                financingType: props.columnVisibility.financingType
            });
        },
        filterEnabled: FoldingTable_Filter.isNumericFilterEnabled(props.filterConfig.estimatedCosts),
        clearFilter: props.onColumnFilterChange({
            code: props.filterConfig.code,
            name: props.filterConfig.name,
            version: props.filterConfig.version,
            projectManager: props.filterConfig.projectManager,
            targetCosts: props.filterConfig.targetCosts,
            estimatedCosts: {
                min: Data_Maybe.Nothing.value,
                max: Data_Maybe.Nothing.value
            },
            actualCosts: props.filterConfig.actualCosts,
            targetIncome: props.filterConfig.targetIncome,
            estimatedIncome: props.filterConfig.estimatedIncome,
            actualIncome: props.filterConfig.actualIncome,
            actualIncomeApproved: props.filterConfig.actualIncomeApproved,
            targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
            estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
            actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
            actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
            startDate: props.filterConfig.startDate,
            endDate: props.filterConfig.endDate,
            contractType: props.filterConfig.contractType,
            state: props.filterConfig.state,
            projectType: props.filterConfig.projectType,
            contractPrice: props.filterConfig.contractPrice,
            taxRate: props.filterConfig.taxRate,
            ownProject: props.filterConfig.ownProject,
            projectGroup: props.filterConfig.projectGroup,
            constructionType: props.filterConfig.constructionType,
            financingType: props.filterConfig.financingType
        })
    }), mkInputControl({
        label: Locale.lookup_("actual_costs"),
        filterControl: mkCurrencyFilter(props.filterConfig.actualCosts)(function ($$new) {
            return props.onColumnFilterChange({
                code: props.filterConfig.code,
                name: props.filterConfig.name,
                version: props.filterConfig.version,
                projectManager: props.filterConfig.projectManager,
                targetCosts: props.filterConfig.targetCosts,
                estimatedCosts: props.filterConfig.estimatedCosts,
                actualCosts: $$new,
                targetIncome: props.filterConfig.targetIncome,
                estimatedIncome: props.filterConfig.estimatedIncome,
                actualIncome: props.filterConfig.actualIncome,
                actualIncomeApproved: props.filterConfig.actualIncomeApproved,
                targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
                estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
                actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
                startDate: props.filterConfig.startDate,
                endDate: props.filterConfig.endDate,
                contractType: props.filterConfig.contractType,
                state: props.filterConfig.state,
                projectType: props.filterConfig.projectType,
                contractPrice: props.filterConfig.contractPrice,
                taxRate: props.filterConfig.taxRate,
                ownProject: props.filterConfig.ownProject,
                projectGroup: props.filterConfig.projectGroup,
                constructionType: props.filterConfig.constructionType,
                financingType: props.filterConfig.financingType
            });
        }),
        visibility: props.columnVisibility.actualCosts,
        onColumnVisibilityChange: function (v) {
            return props.onColumnVisibilityChange({
                code: props.columnVisibility.code,
                name: props.columnVisibility.name,
                version: props.columnVisibility.version,
                projectManager: props.columnVisibility.projectManager,
                targetCosts: props.columnVisibility.targetCosts,
                estimatedCosts: props.columnVisibility.estimatedCosts,
                actualCosts: v,
                targetIncome: props.columnVisibility.targetIncome,
                estimatedIncome: props.columnVisibility.estimatedIncome,
                actualIncome: props.columnVisibility.actualIncome,
                actualIncomeApproved: props.columnVisibility.actualIncomeApproved,
                targetDiffAndMargin: props.columnVisibility.targetDiffAndMargin,
                estimatedDiffAndMargin: props.columnVisibility.estimatedDiffAndMargin,
                actualDiffAndMargin: props.columnVisibility.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.columnVisibility.actualDiffAndMarginApproved,
                startDate: props.columnVisibility.startDate,
                endDate: props.columnVisibility.endDate,
                contractType: props.columnVisibility.contractType,
                state: props.columnVisibility.state,
                projectType: props.columnVisibility.projectType,
                contractPrice: props.columnVisibility.contractPrice,
                taxRate: props.columnVisibility.taxRate,
                ownProject: props.columnVisibility.ownProject,
                projectGroup: props.columnVisibility.projectGroup,
                constructionType: props.columnVisibility.constructionType,
                financingType: props.columnVisibility.financingType
            });
        },
        filterEnabled: FoldingTable_Filter.isNumericFilterEnabled(props.filterConfig.actualCosts),
        clearFilter: props.onColumnFilterChange({
            code: props.filterConfig.code,
            name: props.filterConfig.name,
            version: props.filterConfig.version,
            projectManager: props.filterConfig.projectManager,
            targetCosts: props.filterConfig.targetCosts,
            estimatedCosts: props.filterConfig.estimatedCosts,
            actualCosts: {
                min: Data_Maybe.Nothing.value,
                max: Data_Maybe.Nothing.value
            },
            targetIncome: props.filterConfig.targetIncome,
            estimatedIncome: props.filterConfig.estimatedIncome,
            actualIncome: props.filterConfig.actualIncome,
            actualIncomeApproved: props.filterConfig.actualIncomeApproved,
            targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
            estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
            actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
            actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
            startDate: props.filterConfig.startDate,
            endDate: props.filterConfig.endDate,
            contractType: props.filterConfig.contractType,
            state: props.filterConfig.state,
            projectType: props.filterConfig.projectType,
            contractPrice: props.filterConfig.contractPrice,
            taxRate: props.filterConfig.taxRate,
            ownProject: props.filterConfig.ownProject,
            projectGroup: props.filterConfig.projectGroup,
            constructionType: props.filterConfig.constructionType,
            financingType: props.filterConfig.financingType
        })
    }), mkInputControl({
        label: Locale.lookup_("target_income"),
        filterControl: mkCurrencyFilter(props.filterConfig.targetIncome)(function ($$new) {
            return props.onColumnFilterChange({
                code: props.filterConfig.code,
                name: props.filterConfig.name,
                version: props.filterConfig.version,
                projectManager: props.filterConfig.projectManager,
                targetCosts: props.filterConfig.targetCosts,
                estimatedCosts: props.filterConfig.estimatedCosts,
                actualCosts: props.filterConfig.actualCosts,
                targetIncome: $$new,
                estimatedIncome: props.filterConfig.estimatedIncome,
                actualIncome: props.filterConfig.actualIncome,
                actualIncomeApproved: props.filterConfig.actualIncomeApproved,
                targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
                estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
                actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
                startDate: props.filterConfig.startDate,
                endDate: props.filterConfig.endDate,
                contractType: props.filterConfig.contractType,
                state: props.filterConfig.state,
                projectType: props.filterConfig.projectType,
                contractPrice: props.filterConfig.contractPrice,
                taxRate: props.filterConfig.taxRate,
                ownProject: props.filterConfig.ownProject,
                projectGroup: props.filterConfig.projectGroup,
                constructionType: props.filterConfig.constructionType,
                financingType: props.filterConfig.financingType
            });
        }),
        visibility: props.columnVisibility.targetIncome,
        onColumnVisibilityChange: function (v) {
            return props.onColumnVisibilityChange({
                code: props.columnVisibility.code,
                name: props.columnVisibility.name,
                version: props.columnVisibility.version,
                projectManager: props.columnVisibility.projectManager,
                targetCosts: props.columnVisibility.targetCosts,
                estimatedCosts: props.columnVisibility.estimatedCosts,
                actualCosts: props.columnVisibility.actualCosts,
                targetIncome: v,
                estimatedIncome: props.columnVisibility.estimatedIncome,
                actualIncome: props.columnVisibility.actualIncome,
                actualIncomeApproved: props.columnVisibility.actualIncomeApproved,
                targetDiffAndMargin: props.columnVisibility.targetDiffAndMargin,
                estimatedDiffAndMargin: props.columnVisibility.estimatedDiffAndMargin,
                actualDiffAndMargin: props.columnVisibility.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.columnVisibility.actualDiffAndMarginApproved,
                startDate: props.columnVisibility.startDate,
                endDate: props.columnVisibility.endDate,
                contractType: props.columnVisibility.contractType,
                state: props.columnVisibility.state,
                projectType: props.columnVisibility.projectType,
                contractPrice: props.columnVisibility.contractPrice,
                taxRate: props.columnVisibility.taxRate,
                ownProject: props.columnVisibility.ownProject,
                projectGroup: props.columnVisibility.projectGroup,
                constructionType: props.columnVisibility.constructionType,
                financingType: props.columnVisibility.financingType
            });
        },
        filterEnabled: FoldingTable_Filter.isNumericFilterEnabled(props.filterConfig.targetIncome),
        clearFilter: props.onColumnFilterChange({
            code: props.filterConfig.code,
            name: props.filterConfig.name,
            version: props.filterConfig.version,
            projectManager: props.filterConfig.projectManager,
            targetCosts: props.filterConfig.targetCosts,
            estimatedCosts: props.filterConfig.estimatedCosts,
            actualCosts: props.filterConfig.actualCosts,
            targetIncome: {
                min: Data_Maybe.Nothing.value,
                max: Data_Maybe.Nothing.value
            },
            estimatedIncome: props.filterConfig.estimatedIncome,
            actualIncome: props.filterConfig.actualIncome,
            actualIncomeApproved: props.filterConfig.actualIncomeApproved,
            targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
            estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
            actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
            actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
            startDate: props.filterConfig.startDate,
            endDate: props.filterConfig.endDate,
            contractType: props.filterConfig.contractType,
            state: props.filterConfig.state,
            projectType: props.filterConfig.projectType,
            contractPrice: props.filterConfig.contractPrice,
            taxRate: props.filterConfig.taxRate,
            ownProject: props.filterConfig.ownProject,
            projectGroup: props.filterConfig.projectGroup,
            constructionType: props.filterConfig.constructionType,
            financingType: props.filterConfig.financingType
        })
    }), mkInputControl({
        label: Locale.lookup_("estimate_income"),
        filterControl: mkCurrencyFilter(props.filterConfig.estimatedIncome)(function ($$new) {
            return props.onColumnFilterChange({
                code: props.filterConfig.code,
                name: props.filterConfig.name,
                version: props.filterConfig.version,
                projectManager: props.filterConfig.projectManager,
                targetCosts: props.filterConfig.targetCosts,
                estimatedCosts: props.filterConfig.estimatedCosts,
                actualCosts: props.filterConfig.actualCosts,
                targetIncome: props.filterConfig.targetIncome,
                estimatedIncome: $$new,
                actualIncome: props.filterConfig.actualIncome,
                actualIncomeApproved: props.filterConfig.actualIncomeApproved,
                targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
                estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
                actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
                startDate: props.filterConfig.startDate,
                endDate: props.filterConfig.endDate,
                contractType: props.filterConfig.contractType,
                state: props.filterConfig.state,
                projectType: props.filterConfig.projectType,
                contractPrice: props.filterConfig.contractPrice,
                taxRate: props.filterConfig.taxRate,
                ownProject: props.filterConfig.ownProject,
                projectGroup: props.filterConfig.projectGroup,
                constructionType: props.filterConfig.constructionType,
                financingType: props.filterConfig.financingType
            });
        }),
        visibility: props.columnVisibility.estimatedIncome,
        onColumnVisibilityChange: function (v) {
            return props.onColumnVisibilityChange({
                code: props.columnVisibility.code,
                name: props.columnVisibility.name,
                version: props.columnVisibility.version,
                projectManager: props.columnVisibility.projectManager,
                targetCosts: props.columnVisibility.targetCosts,
                estimatedCosts: props.columnVisibility.estimatedCosts,
                actualCosts: props.columnVisibility.actualCosts,
                targetIncome: props.columnVisibility.targetIncome,
                estimatedIncome: v,
                actualIncome: props.columnVisibility.actualIncome,
                actualIncomeApproved: props.columnVisibility.actualIncomeApproved,
                targetDiffAndMargin: props.columnVisibility.targetDiffAndMargin,
                estimatedDiffAndMargin: props.columnVisibility.estimatedDiffAndMargin,
                actualDiffAndMargin: props.columnVisibility.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.columnVisibility.actualDiffAndMarginApproved,
                startDate: props.columnVisibility.startDate,
                endDate: props.columnVisibility.endDate,
                contractType: props.columnVisibility.contractType,
                state: props.columnVisibility.state,
                projectType: props.columnVisibility.projectType,
                contractPrice: props.columnVisibility.contractPrice,
                taxRate: props.columnVisibility.taxRate,
                ownProject: props.columnVisibility.ownProject,
                projectGroup: props.columnVisibility.projectGroup,
                constructionType: props.columnVisibility.constructionType,
                financingType: props.columnVisibility.financingType
            });
        },
        filterEnabled: FoldingTable_Filter.isNumericFilterEnabled(props.filterConfig.estimatedIncome),
        clearFilter: props.onColumnFilterChange({
            code: props.filterConfig.code,
            name: props.filterConfig.name,
            version: props.filterConfig.version,
            projectManager: props.filterConfig.projectManager,
            targetCosts: props.filterConfig.targetCosts,
            estimatedCosts: props.filterConfig.estimatedCosts,
            actualCosts: props.filterConfig.actualCosts,
            targetIncome: props.filterConfig.targetIncome,
            estimatedIncome: {
                min: Data_Maybe.Nothing.value,
                max: Data_Maybe.Nothing.value
            },
            actualIncome: props.filterConfig.actualIncome,
            actualIncomeApproved: props.filterConfig.actualIncomeApproved,
            targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
            estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
            actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
            actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
            startDate: props.filterConfig.startDate,
            endDate: props.filterConfig.endDate,
            contractType: props.filterConfig.contractType,
            state: props.filterConfig.state,
            projectType: props.filterConfig.projectType,
            contractPrice: props.filterConfig.contractPrice,
            taxRate: props.filterConfig.taxRate,
            ownProject: props.filterConfig.ownProject,
            projectGroup: props.filterConfig.projectGroup,
            constructionType: props.filterConfig.constructionType,
            financingType: props.filterConfig.financingType
        })
    }), mkInputControl({
        label: Locale.lookup_("actual_income"),
        filterControl: mkCurrencyFilter(props.filterConfig.actualIncome)(function ($$new) {
            return props.onColumnFilterChange({
                code: props.filterConfig.code,
                name: props.filterConfig.name,
                version: props.filterConfig.version,
                projectManager: props.filterConfig.projectManager,
                targetCosts: props.filterConfig.targetCosts,
                estimatedCosts: props.filterConfig.estimatedCosts,
                actualCosts: props.filterConfig.actualCosts,
                targetIncome: props.filterConfig.targetIncome,
                estimatedIncome: props.filterConfig.estimatedIncome,
                actualIncome: $$new,
                actualIncomeApproved: props.filterConfig.actualIncomeApproved,
                targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
                estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
                actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
                startDate: props.filterConfig.startDate,
                endDate: props.filterConfig.endDate,
                contractType: props.filterConfig.contractType,
                state: props.filterConfig.state,
                projectType: props.filterConfig.projectType,
                contractPrice: props.filterConfig.contractPrice,
                taxRate: props.filterConfig.taxRate,
                ownProject: props.filterConfig.ownProject,
                projectGroup: props.filterConfig.projectGroup,
                constructionType: props.filterConfig.constructionType,
                financingType: props.filterConfig.financingType
            });
        }),
        visibility: props.columnVisibility.actualIncome,
        onColumnVisibilityChange: function (v) {
            return props.onColumnVisibilityChange({
                code: props.columnVisibility.code,
                name: props.columnVisibility.name,
                version: props.columnVisibility.version,
                projectManager: props.columnVisibility.projectManager,
                targetCosts: props.columnVisibility.targetCosts,
                estimatedCosts: props.columnVisibility.estimatedCosts,
                actualCosts: props.columnVisibility.actualCosts,
                targetIncome: props.columnVisibility.targetIncome,
                estimatedIncome: props.columnVisibility.estimatedIncome,
                actualIncome: v,
                actualIncomeApproved: props.columnVisibility.actualIncomeApproved,
                targetDiffAndMargin: props.columnVisibility.targetDiffAndMargin,
                estimatedDiffAndMargin: props.columnVisibility.estimatedDiffAndMargin,
                actualDiffAndMargin: props.columnVisibility.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.columnVisibility.actualDiffAndMarginApproved,
                startDate: props.columnVisibility.startDate,
                endDate: props.columnVisibility.endDate,
                contractType: props.columnVisibility.contractType,
                state: props.columnVisibility.state,
                projectType: props.columnVisibility.projectType,
                contractPrice: props.columnVisibility.contractPrice,
                taxRate: props.columnVisibility.taxRate,
                ownProject: props.columnVisibility.ownProject,
                projectGroup: props.columnVisibility.projectGroup,
                constructionType: props.columnVisibility.constructionType,
                financingType: props.columnVisibility.financingType
            });
        },
        filterEnabled: FoldingTable_Filter.isNumericFilterEnabled(props.filterConfig.actualIncome),
        clearFilter: props.onColumnFilterChange({
            code: props.filterConfig.code,
            name: props.filterConfig.name,
            version: props.filterConfig.version,
            projectManager: props.filterConfig.projectManager,
            targetCosts: props.filterConfig.targetCosts,
            estimatedCosts: props.filterConfig.estimatedCosts,
            actualCosts: props.filterConfig.actualCosts,
            targetIncome: props.filterConfig.targetIncome,
            estimatedIncome: props.filterConfig.estimatedIncome,
            actualIncome: {
                min: Data_Maybe.Nothing.value,
                max: Data_Maybe.Nothing.value
            },
            actualIncomeApproved: props.filterConfig.actualIncomeApproved,
            targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
            estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
            actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
            actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
            startDate: props.filterConfig.startDate,
            endDate: props.filterConfig.endDate,
            contractType: props.filterConfig.contractType,
            state: props.filterConfig.state,
            projectType: props.filterConfig.projectType,
            contractPrice: props.filterConfig.contractPrice,
            taxRate: props.filterConfig.taxRate,
            ownProject: props.filterConfig.ownProject,
            projectGroup: props.filterConfig.projectGroup,
            constructionType: props.filterConfig.constructionType,
            financingType: props.filterConfig.financingType
        })
    }), mkInputControl({
        label: Locale.lookup_("actual_income_approved"),
        filterControl: mkCurrencyFilter(props.filterConfig.actualIncomeApproved)(function ($$new) {
            return props.onColumnFilterChange({
                code: props.filterConfig.code,
                name: props.filterConfig.name,
                version: props.filterConfig.version,
                projectManager: props.filterConfig.projectManager,
                targetCosts: props.filterConfig.targetCosts,
                estimatedCosts: props.filterConfig.estimatedCosts,
                actualCosts: props.filterConfig.actualCosts,
                targetIncome: props.filterConfig.targetIncome,
                estimatedIncome: props.filterConfig.estimatedIncome,
                actualIncome: props.filterConfig.actualIncome,
                actualIncomeApproved: $$new,
                targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
                estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
                actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
                startDate: props.filterConfig.startDate,
                endDate: props.filterConfig.endDate,
                contractType: props.filterConfig.contractType,
                state: props.filterConfig.state,
                projectType: props.filterConfig.projectType,
                contractPrice: props.filterConfig.contractPrice,
                taxRate: props.filterConfig.taxRate,
                ownProject: props.filterConfig.ownProject,
                projectGroup: props.filterConfig.projectGroup,
                constructionType: props.filterConfig.constructionType,
                financingType: props.filterConfig.financingType
            });
        }),
        visibility: props.columnVisibility.actualIncomeApproved,
        onColumnVisibilityChange: function (v) {
            return props.onColumnVisibilityChange({
                code: props.columnVisibility.code,
                name: props.columnVisibility.name,
                version: props.columnVisibility.version,
                projectManager: props.columnVisibility.projectManager,
                targetCosts: props.columnVisibility.targetCosts,
                estimatedCosts: props.columnVisibility.estimatedCosts,
                actualCosts: props.columnVisibility.actualCosts,
                targetIncome: props.columnVisibility.targetIncome,
                estimatedIncome: props.columnVisibility.estimatedIncome,
                actualIncome: props.columnVisibility.actualIncome,
                actualIncomeApproved: v,
                targetDiffAndMargin: props.columnVisibility.targetDiffAndMargin,
                estimatedDiffAndMargin: props.columnVisibility.estimatedDiffAndMargin,
                actualDiffAndMargin: props.columnVisibility.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.columnVisibility.actualDiffAndMarginApproved,
                startDate: props.columnVisibility.startDate,
                endDate: props.columnVisibility.endDate,
                contractType: props.columnVisibility.contractType,
                state: props.columnVisibility.state,
                projectType: props.columnVisibility.projectType,
                contractPrice: props.columnVisibility.contractPrice,
                taxRate: props.columnVisibility.taxRate,
                ownProject: props.columnVisibility.ownProject,
                projectGroup: props.columnVisibility.projectGroup,
                constructionType: props.columnVisibility.constructionType,
                financingType: props.columnVisibility.financingType
            });
        },
        filterEnabled: FoldingTable_Filter.isNumericFilterEnabled(props.filterConfig.actualIncomeApproved),
        clearFilter: props.onColumnFilterChange({
            code: props.filterConfig.code,
            name: props.filterConfig.name,
            version: props.filterConfig.version,
            projectManager: props.filterConfig.projectManager,
            targetCosts: props.filterConfig.targetCosts,
            estimatedCosts: props.filterConfig.estimatedCosts,
            actualCosts: props.filterConfig.actualCosts,
            targetIncome: props.filterConfig.targetIncome,
            estimatedIncome: props.filterConfig.estimatedIncome,
            actualIncome: props.filterConfig.actualIncome,
            actualIncomeApproved: {
                min: Data_Maybe.Nothing.value,
                max: Data_Maybe.Nothing.value
            },
            targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
            estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
            actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
            actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
            startDate: props.filterConfig.startDate,
            endDate: props.filterConfig.endDate,
            contractType: props.filterConfig.contractType,
            state: props.filterConfig.state,
            projectType: props.filterConfig.projectType,
            contractPrice: props.filterConfig.contractPrice,
            taxRate: props.filterConfig.taxRate,
            ownProject: props.filterConfig.ownProject,
            projectGroup: props.filterConfig.projectGroup,
            constructionType: props.filterConfig.constructionType,
            financingType: props.filterConfig.financingType
        })
    }), mkInputControl({
        label: Locale.lookup_("target_diff_and_margin"),
        filterControl: mkCurrencyFilter(props.filterConfig.targetDiffAndMargin)(function ($$new) {
            return props.onColumnFilterChange({
                code: props.filterConfig.code,
                name: props.filterConfig.name,
                version: props.filterConfig.version,
                projectManager: props.filterConfig.projectManager,
                targetCosts: props.filterConfig.targetCosts,
                estimatedCosts: props.filterConfig.estimatedCosts,
                actualCosts: props.filterConfig.actualCosts,
                targetIncome: props.filterConfig.targetIncome,
                estimatedIncome: props.filterConfig.estimatedIncome,
                actualIncome: props.filterConfig.actualIncome,
                actualIncomeApproved: props.filterConfig.actualIncomeApproved,
                targetDiffAndMargin: $$new,
                estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
                actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
                startDate: props.filterConfig.startDate,
                endDate: props.filterConfig.endDate,
                contractType: props.filterConfig.contractType,
                state: props.filterConfig.state,
                projectType: props.filterConfig.projectType,
                contractPrice: props.filterConfig.contractPrice,
                taxRate: props.filterConfig.taxRate,
                ownProject: props.filterConfig.ownProject,
                projectGroup: props.filterConfig.projectGroup,
                constructionType: props.filterConfig.constructionType,
                financingType: props.filterConfig.financingType
            });
        }),
        visibility: props.columnVisibility.targetDiffAndMargin,
        onColumnVisibilityChange: function (v) {
            return props.onColumnVisibilityChange({
                code: props.columnVisibility.code,
                name: props.columnVisibility.name,
                version: props.columnVisibility.version,
                projectManager: props.columnVisibility.projectManager,
                targetCosts: props.columnVisibility.targetCosts,
                estimatedCosts: props.columnVisibility.estimatedCosts,
                actualCosts: props.columnVisibility.actualCosts,
                targetIncome: props.columnVisibility.targetIncome,
                estimatedIncome: props.columnVisibility.estimatedIncome,
                actualIncome: props.columnVisibility.actualIncome,
                actualIncomeApproved: props.columnVisibility.actualIncomeApproved,
                targetDiffAndMargin: v,
                estimatedDiffAndMargin: props.columnVisibility.estimatedDiffAndMargin,
                actualDiffAndMargin: props.columnVisibility.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.columnVisibility.actualDiffAndMarginApproved,
                startDate: props.columnVisibility.startDate,
                endDate: props.columnVisibility.endDate,
                contractType: props.columnVisibility.contractType,
                state: props.columnVisibility.state,
                projectType: props.columnVisibility.projectType,
                contractPrice: props.columnVisibility.contractPrice,
                taxRate: props.columnVisibility.taxRate,
                ownProject: props.columnVisibility.ownProject,
                projectGroup: props.columnVisibility.projectGroup,
                constructionType: props.columnVisibility.constructionType,
                financingType: props.columnVisibility.financingType
            });
        },
        filterEnabled: FoldingTable_Filter.isNumericFilterEnabled(props.filterConfig.targetDiffAndMargin),
        clearFilter: props.onColumnFilterChange({
            code: props.filterConfig.code,
            name: props.filterConfig.name,
            version: props.filterConfig.version,
            projectManager: props.filterConfig.projectManager,
            targetCosts: props.filterConfig.targetCosts,
            estimatedCosts: props.filterConfig.estimatedCosts,
            actualCosts: props.filterConfig.actualCosts,
            targetIncome: props.filterConfig.targetIncome,
            estimatedIncome: props.filterConfig.estimatedIncome,
            actualIncome: props.filterConfig.actualIncome,
            actualIncomeApproved: props.filterConfig.actualIncomeApproved,
            targetDiffAndMargin: {
                min: Data_Maybe.Nothing.value,
                max: Data_Maybe.Nothing.value
            },
            estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
            actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
            actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
            startDate: props.filterConfig.startDate,
            endDate: props.filterConfig.endDate,
            contractType: props.filterConfig.contractType,
            state: props.filterConfig.state,
            projectType: props.filterConfig.projectType,
            contractPrice: props.filterConfig.contractPrice,
            taxRate: props.filterConfig.taxRate,
            ownProject: props.filterConfig.ownProject,
            projectGroup: props.filterConfig.projectGroup,
            constructionType: props.filterConfig.constructionType,
            financingType: props.filterConfig.financingType
        })
    }), mkInputControl({
        label: Locale.lookup_("estimated_diff_and_margin"),
        filterControl: mkCurrencyFilter(props.filterConfig.estimatedDiffAndMargin)(function ($$new) {
            return props.onColumnFilterChange({
                code: props.filterConfig.code,
                name: props.filterConfig.name,
                version: props.filterConfig.version,
                projectManager: props.filterConfig.projectManager,
                targetCosts: props.filterConfig.targetCosts,
                estimatedCosts: props.filterConfig.estimatedCosts,
                actualCosts: props.filterConfig.actualCosts,
                targetIncome: props.filterConfig.targetIncome,
                estimatedIncome: props.filterConfig.estimatedIncome,
                actualIncome: props.filterConfig.actualIncome,
                actualIncomeApproved: props.filterConfig.actualIncomeApproved,
                targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
                estimatedDiffAndMargin: $$new,
                actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
                startDate: props.filterConfig.startDate,
                endDate: props.filterConfig.endDate,
                contractType: props.filterConfig.contractType,
                state: props.filterConfig.state,
                projectType: props.filterConfig.projectType,
                contractPrice: props.filterConfig.contractPrice,
                taxRate: props.filterConfig.taxRate,
                ownProject: props.filterConfig.ownProject,
                projectGroup: props.filterConfig.projectGroup,
                constructionType: props.filterConfig.constructionType,
                financingType: props.filterConfig.financingType
            });
        }),
        visibility: props.columnVisibility.estimatedDiffAndMargin,
        onColumnVisibilityChange: function (v) {
            return props.onColumnVisibilityChange({
                code: props.columnVisibility.code,
                name: props.columnVisibility.name,
                version: props.columnVisibility.version,
                projectManager: props.columnVisibility.projectManager,
                targetCosts: props.columnVisibility.targetCosts,
                estimatedCosts: props.columnVisibility.estimatedCosts,
                actualCosts: props.columnVisibility.actualCosts,
                targetIncome: props.columnVisibility.targetIncome,
                estimatedIncome: props.columnVisibility.estimatedIncome,
                actualIncome: props.columnVisibility.actualIncome,
                actualIncomeApproved: props.columnVisibility.actualIncomeApproved,
                targetDiffAndMargin: props.columnVisibility.targetDiffAndMargin,
                estimatedDiffAndMargin: v,
                actualDiffAndMargin: props.columnVisibility.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.columnVisibility.actualDiffAndMarginApproved,
                startDate: props.columnVisibility.startDate,
                endDate: props.columnVisibility.endDate,
                contractType: props.columnVisibility.contractType,
                state: props.columnVisibility.state,
                projectType: props.columnVisibility.projectType,
                contractPrice: props.columnVisibility.contractPrice,
                taxRate: props.columnVisibility.taxRate,
                ownProject: props.columnVisibility.ownProject,
                projectGroup: props.columnVisibility.projectGroup,
                constructionType: props.columnVisibility.constructionType,
                financingType: props.columnVisibility.financingType
            });
        },
        filterEnabled: FoldingTable_Filter.isNumericFilterEnabled(props.filterConfig.estimatedDiffAndMargin),
        clearFilter: props.onColumnFilterChange({
            code: props.filterConfig.code,
            name: props.filterConfig.name,
            version: props.filterConfig.version,
            projectManager: props.filterConfig.projectManager,
            targetCosts: props.filterConfig.targetCosts,
            estimatedCosts: props.filterConfig.estimatedCosts,
            actualCosts: props.filterConfig.actualCosts,
            targetIncome: props.filterConfig.targetIncome,
            estimatedIncome: props.filterConfig.estimatedIncome,
            actualIncome: props.filterConfig.actualIncome,
            actualIncomeApproved: props.filterConfig.actualIncomeApproved,
            targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
            estimatedDiffAndMargin: {
                min: Data_Maybe.Nothing.value,
                max: Data_Maybe.Nothing.value
            },
            actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
            actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
            startDate: props.filterConfig.startDate,
            endDate: props.filterConfig.endDate,
            contractType: props.filterConfig.contractType,
            state: props.filterConfig.state,
            projectType: props.filterConfig.projectType,
            contractPrice: props.filterConfig.contractPrice,
            taxRate: props.filterConfig.taxRate,
            ownProject: props.filterConfig.ownProject,
            projectGroup: props.filterConfig.projectGroup,
            constructionType: props.filterConfig.constructionType,
            financingType: props.filterConfig.financingType
        })
    }), mkInputControl({
        label: Locale.lookup_("actual_diff_and_margin"),
        filterControl: mkCurrencyFilter(props.filterConfig.actualDiffAndMargin)(function ($$new) {
            return props.onColumnFilterChange({
                code: props.filterConfig.code,
                name: props.filterConfig.name,
                version: props.filterConfig.version,
                projectManager: props.filterConfig.projectManager,
                targetCosts: props.filterConfig.targetCosts,
                estimatedCosts: props.filterConfig.estimatedCosts,
                actualCosts: props.filterConfig.actualCosts,
                targetIncome: props.filterConfig.targetIncome,
                estimatedIncome: props.filterConfig.estimatedIncome,
                actualIncome: props.filterConfig.actualIncome,
                actualIncomeApproved: props.filterConfig.actualIncomeApproved,
                targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
                estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
                actualDiffAndMargin: $$new,
                actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
                startDate: props.filterConfig.startDate,
                endDate: props.filterConfig.endDate,
                contractType: props.filterConfig.contractType,
                state: props.filterConfig.state,
                projectType: props.filterConfig.projectType,
                contractPrice: props.filterConfig.contractPrice,
                taxRate: props.filterConfig.taxRate,
                ownProject: props.filterConfig.ownProject,
                projectGroup: props.filterConfig.projectGroup,
                constructionType: props.filterConfig.constructionType,
                financingType: props.filterConfig.financingType
            });
        }),
        visibility: props.columnVisibility.actualDiffAndMargin,
        onColumnVisibilityChange: function (v) {
            return props.onColumnVisibilityChange({
                code: props.columnVisibility.code,
                name: props.columnVisibility.name,
                version: props.columnVisibility.version,
                projectManager: props.columnVisibility.projectManager,
                targetCosts: props.columnVisibility.targetCosts,
                estimatedCosts: props.columnVisibility.estimatedCosts,
                actualCosts: props.columnVisibility.actualCosts,
                targetIncome: props.columnVisibility.targetIncome,
                estimatedIncome: props.columnVisibility.estimatedIncome,
                actualIncome: props.columnVisibility.actualIncome,
                actualIncomeApproved: props.columnVisibility.actualIncomeApproved,
                targetDiffAndMargin: props.columnVisibility.targetDiffAndMargin,
                estimatedDiffAndMargin: props.columnVisibility.estimatedDiffAndMargin,
                actualDiffAndMargin: v,
                actualDiffAndMarginApproved: props.columnVisibility.actualDiffAndMarginApproved,
                startDate: props.columnVisibility.startDate,
                endDate: props.columnVisibility.endDate,
                contractType: props.columnVisibility.contractType,
                state: props.columnVisibility.state,
                projectType: props.columnVisibility.projectType,
                contractPrice: props.columnVisibility.contractPrice,
                taxRate: props.columnVisibility.taxRate,
                ownProject: props.columnVisibility.ownProject,
                projectGroup: props.columnVisibility.projectGroup,
                constructionType: props.columnVisibility.constructionType,
                financingType: props.columnVisibility.financingType
            });
        },
        filterEnabled: FoldingTable_Filter.isNumericFilterEnabled(props.filterConfig.actualDiffAndMargin),
        clearFilter: props.onColumnFilterChange({
            code: props.filterConfig.code,
            name: props.filterConfig.name,
            version: props.filterConfig.version,
            projectManager: props.filterConfig.projectManager,
            targetCosts: props.filterConfig.targetCosts,
            estimatedCosts: props.filterConfig.estimatedCosts,
            actualCosts: props.filterConfig.actualCosts,
            targetIncome: props.filterConfig.targetIncome,
            estimatedIncome: props.filterConfig.estimatedIncome,
            actualIncome: props.filterConfig.actualIncome,
            actualIncomeApproved: props.filterConfig.actualIncomeApproved,
            targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
            estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
            actualDiffAndMargin: {
                min: Data_Maybe.Nothing.value,
                max: Data_Maybe.Nothing.value
            },
            actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
            startDate: props.filterConfig.startDate,
            endDate: props.filterConfig.endDate,
            contractType: props.filterConfig.contractType,
            state: props.filterConfig.state,
            projectType: props.filterConfig.projectType,
            contractPrice: props.filterConfig.contractPrice,
            taxRate: props.filterConfig.taxRate,
            ownProject: props.filterConfig.ownProject,
            projectGroup: props.filterConfig.projectGroup,
            constructionType: props.filterConfig.constructionType,
            financingType: props.filterConfig.financingType
        })
    }), mkInputControl({
        label: Locale.lookup_("actual_diff_and_margin_approved"),
        filterControl: mkCurrencyFilter(props.filterConfig.actualDiffAndMarginApproved)(function ($$new) {
            return props.onColumnFilterChange({
                code: props.filterConfig.code,
                name: props.filterConfig.name,
                version: props.filterConfig.version,
                projectManager: props.filterConfig.projectManager,
                targetCosts: props.filterConfig.targetCosts,
                estimatedCosts: props.filterConfig.estimatedCosts,
                actualCosts: props.filterConfig.actualCosts,
                targetIncome: props.filterConfig.targetIncome,
                estimatedIncome: props.filterConfig.estimatedIncome,
                actualIncome: props.filterConfig.actualIncome,
                actualIncomeApproved: props.filterConfig.actualIncomeApproved,
                targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
                estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
                actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
                actualDiffAndMarginApproved: $$new,
                startDate: props.filterConfig.startDate,
                endDate: props.filterConfig.endDate,
                contractType: props.filterConfig.contractType,
                state: props.filterConfig.state,
                projectType: props.filterConfig.projectType,
                contractPrice: props.filterConfig.contractPrice,
                taxRate: props.filterConfig.taxRate,
                ownProject: props.filterConfig.ownProject,
                projectGroup: props.filterConfig.projectGroup,
                constructionType: props.filterConfig.constructionType,
                financingType: props.filterConfig.financingType
            });
        }),
        visibility: props.columnVisibility.actualDiffAndMarginApproved,
        onColumnVisibilityChange: function (v) {
            return props.onColumnVisibilityChange({
                code: props.columnVisibility.code,
                name: props.columnVisibility.name,
                version: props.columnVisibility.version,
                projectManager: props.columnVisibility.projectManager,
                targetCosts: props.columnVisibility.targetCosts,
                estimatedCosts: props.columnVisibility.estimatedCosts,
                actualCosts: props.columnVisibility.actualCosts,
                targetIncome: props.columnVisibility.targetIncome,
                estimatedIncome: props.columnVisibility.estimatedIncome,
                actualIncome: props.columnVisibility.actualIncome,
                actualIncomeApproved: props.columnVisibility.actualIncomeApproved,
                targetDiffAndMargin: props.columnVisibility.targetDiffAndMargin,
                estimatedDiffAndMargin: props.columnVisibility.estimatedDiffAndMargin,
                actualDiffAndMargin: props.columnVisibility.actualDiffAndMargin,
                actualDiffAndMarginApproved: v,
                startDate: props.columnVisibility.startDate,
                endDate: props.columnVisibility.endDate,
                contractType: props.columnVisibility.contractType,
                state: props.columnVisibility.state,
                projectType: props.columnVisibility.projectType,
                contractPrice: props.columnVisibility.contractPrice,
                taxRate: props.columnVisibility.taxRate,
                ownProject: props.columnVisibility.ownProject,
                projectGroup: props.columnVisibility.projectGroup,
                constructionType: props.columnVisibility.constructionType,
                financingType: props.columnVisibility.financingType
            });
        },
        filterEnabled: FoldingTable_Filter.isNumericFilterEnabled(props.filterConfig.actualDiffAndMarginApproved),
        clearFilter: props.onColumnFilterChange({
            code: props.filterConfig.code,
            name: props.filterConfig.name,
            version: props.filterConfig.version,
            projectManager: props.filterConfig.projectManager,
            targetCosts: props.filterConfig.targetCosts,
            estimatedCosts: props.filterConfig.estimatedCosts,
            actualCosts: props.filterConfig.actualCosts,
            targetIncome: props.filterConfig.targetIncome,
            estimatedIncome: props.filterConfig.estimatedIncome,
            actualIncome: props.filterConfig.actualIncome,
            actualIncomeApproved: props.filterConfig.actualIncomeApproved,
            targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
            estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
            actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
            actualDiffAndMarginApproved: {
                min: Data_Maybe.Nothing.value,
                max: Data_Maybe.Nothing.value
            },
            startDate: props.filterConfig.startDate,
            endDate: props.filterConfig.endDate,
            contractType: props.filterConfig.contractType,
            state: props.filterConfig.state,
            projectType: props.filterConfig.projectType,
            contractPrice: props.filterConfig.contractPrice,
            taxRate: props.filterConfig.taxRate,
            ownProject: props.filterConfig.ownProject,
            projectGroup: props.filterConfig.projectGroup,
            constructionType: props.filterConfig.constructionType,
            financingType: props.filterConfig.financingType
        })
    }), mkInputControl({
        label: Locale.lookup_("common_start_date"),
        filterControl: mkDateFilter(props.filterConfig.startDate)(function ($$new) {
            return props.onColumnFilterChange({
                code: props.filterConfig.code,
                name: props.filterConfig.name,
                version: props.filterConfig.version,
                projectManager: props.filterConfig.projectManager,
                targetCosts: props.filterConfig.targetCosts,
                estimatedCosts: props.filterConfig.estimatedCosts,
                actualCosts: props.filterConfig.actualCosts,
                targetIncome: props.filterConfig.targetIncome,
                estimatedIncome: props.filterConfig.estimatedIncome,
                actualIncome: props.filterConfig.actualIncome,
                actualIncomeApproved: props.filterConfig.actualIncomeApproved,
                targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
                estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
                actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
                startDate: $$new,
                endDate: props.filterConfig.endDate,
                contractType: props.filterConfig.contractType,
                state: props.filterConfig.state,
                projectType: props.filterConfig.projectType,
                contractPrice: props.filterConfig.contractPrice,
                taxRate: props.filterConfig.taxRate,
                ownProject: props.filterConfig.ownProject,
                projectGroup: props.filterConfig.projectGroup,
                constructionType: props.filterConfig.constructionType,
                financingType: props.filterConfig.financingType
            });
        }),
        visibility: props.columnVisibility.startDate,
        onColumnVisibilityChange: function (v) {
            return props.onColumnVisibilityChange({
                code: props.columnVisibility.code,
                name: props.columnVisibility.name,
                version: props.columnVisibility.version,
                projectManager: props.columnVisibility.projectManager,
                targetCosts: props.columnVisibility.targetCosts,
                estimatedCosts: props.columnVisibility.estimatedCosts,
                actualCosts: props.columnVisibility.actualCosts,
                targetIncome: props.columnVisibility.targetIncome,
                estimatedIncome: props.columnVisibility.estimatedIncome,
                actualIncome: props.columnVisibility.actualIncome,
                actualIncomeApproved: props.columnVisibility.actualIncomeApproved,
                targetDiffAndMargin: props.columnVisibility.targetDiffAndMargin,
                estimatedDiffAndMargin: props.columnVisibility.estimatedDiffAndMargin,
                actualDiffAndMargin: props.columnVisibility.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.columnVisibility.actualDiffAndMarginApproved,
                startDate: v,
                endDate: props.columnVisibility.endDate,
                contractType: props.columnVisibility.contractType,
                state: props.columnVisibility.state,
                projectType: props.columnVisibility.projectType,
                contractPrice: props.columnVisibility.contractPrice,
                taxRate: props.columnVisibility.taxRate,
                ownProject: props.columnVisibility.ownProject,
                projectGroup: props.columnVisibility.projectGroup,
                constructionType: props.columnVisibility.constructionType,
                financingType: props.columnVisibility.financingType
            });
        },
        filterEnabled: FoldingTable_Filter.isDateFilterEnabled(props.filterConfig.startDate),
        clearFilter: props.onColumnFilterChange({
            code: props.filterConfig.code,
            name: props.filterConfig.name,
            version: props.filterConfig.version,
            projectManager: props.filterConfig.projectManager,
            targetCosts: props.filterConfig.targetCosts,
            estimatedCosts: props.filterConfig.estimatedCosts,
            actualCosts: props.filterConfig.actualCosts,
            targetIncome: props.filterConfig.targetIncome,
            estimatedIncome: props.filterConfig.estimatedIncome,
            actualIncome: props.filterConfig.actualIncome,
            actualIncomeApproved: props.filterConfig.actualIncomeApproved,
            targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
            estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
            actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
            actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
            startDate: {
                min: Data_Maybe.Nothing.value,
                max: Data_Maybe.Nothing.value
            },
            endDate: props.filterConfig.endDate,
            contractType: props.filterConfig.contractType,
            state: props.filterConfig.state,
            projectType: props.filterConfig.projectType,
            contractPrice: props.filterConfig.contractPrice,
            taxRate: props.filterConfig.taxRate,
            ownProject: props.filterConfig.ownProject,
            projectGroup: props.filterConfig.projectGroup,
            constructionType: props.filterConfig.constructionType,
            financingType: props.filterConfig.financingType
        })
    }), mkInputControl({
        label: Locale.lookup_("common_end_date"),
        filterControl: mkDateFilter(props.filterConfig.endDate)(function ($$new) {
            return props.onColumnFilterChange({
                code: props.filterConfig.code,
                name: props.filterConfig.name,
                version: props.filterConfig.version,
                projectManager: props.filterConfig.projectManager,
                targetCosts: props.filterConfig.targetCosts,
                estimatedCosts: props.filterConfig.estimatedCosts,
                actualCosts: props.filterConfig.actualCosts,
                targetIncome: props.filterConfig.targetIncome,
                estimatedIncome: props.filterConfig.estimatedIncome,
                actualIncome: props.filterConfig.actualIncome,
                actualIncomeApproved: props.filterConfig.actualIncomeApproved,
                targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
                estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
                actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
                startDate: props.filterConfig.startDate,
                endDate: $$new,
                contractType: props.filterConfig.contractType,
                state: props.filterConfig.state,
                projectType: props.filterConfig.projectType,
                contractPrice: props.filterConfig.contractPrice,
                taxRate: props.filterConfig.taxRate,
                ownProject: props.filterConfig.ownProject,
                projectGroup: props.filterConfig.projectGroup,
                constructionType: props.filterConfig.constructionType,
                financingType: props.filterConfig.financingType
            });
        }),
        visibility: props.columnVisibility.endDate,
        onColumnVisibilityChange: function (v) {
            return props.onColumnVisibilityChange({
                code: props.columnVisibility.code,
                name: props.columnVisibility.name,
                version: props.columnVisibility.version,
                projectManager: props.columnVisibility.projectManager,
                targetCosts: props.columnVisibility.targetCosts,
                estimatedCosts: props.columnVisibility.estimatedCosts,
                actualCosts: props.columnVisibility.actualCosts,
                targetIncome: props.columnVisibility.targetIncome,
                estimatedIncome: props.columnVisibility.estimatedIncome,
                actualIncome: props.columnVisibility.actualIncome,
                actualIncomeApproved: props.columnVisibility.actualIncomeApproved,
                targetDiffAndMargin: props.columnVisibility.targetDiffAndMargin,
                estimatedDiffAndMargin: props.columnVisibility.estimatedDiffAndMargin,
                actualDiffAndMargin: props.columnVisibility.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.columnVisibility.actualDiffAndMarginApproved,
                startDate: props.columnVisibility.startDate,
                endDate: v,
                contractType: props.columnVisibility.contractType,
                state: props.columnVisibility.state,
                projectType: props.columnVisibility.projectType,
                contractPrice: props.columnVisibility.contractPrice,
                taxRate: props.columnVisibility.taxRate,
                ownProject: props.columnVisibility.ownProject,
                projectGroup: props.columnVisibility.projectGroup,
                constructionType: props.columnVisibility.constructionType,
                financingType: props.columnVisibility.financingType
            });
        },
        filterEnabled: FoldingTable_Filter.isDateFilterEnabled(props.filterConfig.endDate),
        clearFilter: props.onColumnFilterChange({
            code: props.filterConfig.code,
            name: props.filterConfig.name,
            version: props.filterConfig.version,
            projectManager: props.filterConfig.projectManager,
            targetCosts: props.filterConfig.targetCosts,
            estimatedCosts: props.filterConfig.estimatedCosts,
            actualCosts: props.filterConfig.actualCosts,
            targetIncome: props.filterConfig.targetIncome,
            estimatedIncome: props.filterConfig.estimatedIncome,
            actualIncome: props.filterConfig.actualIncome,
            actualIncomeApproved: props.filterConfig.actualIncomeApproved,
            targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
            estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
            actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
            actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
            startDate: props.filterConfig.startDate,
            endDate: {
                min: Data_Maybe.Nothing.value,
                max: Data_Maybe.Nothing.value
            },
            contractType: props.filterConfig.contractType,
            state: props.filterConfig.state,
            projectType: props.filterConfig.projectType,
            contractPrice: props.filterConfig.contractPrice,
            taxRate: props.filterConfig.taxRate,
            ownProject: props.filterConfig.ownProject,
            projectGroup: props.filterConfig.projectGroup,
            constructionType: props.filterConfig.constructionType,
            financingType: props.filterConfig.financingType
        })
    }), mkInputControl({
        label: Locale.lookup_("common_contract_type"),
        filterControl: selectionFilter(props.filterConfig.contractType)(Data_Functor.map(Data_Functor.functorArray)(Data_Newtype.unwrap())(props.selectOptions.contractTypes))(function ($$new) {
            return props.onColumnFilterChange({
                code: props.filterConfig.code,
                name: props.filterConfig.name,
                version: props.filterConfig.version,
                projectManager: props.filterConfig.projectManager,
                targetCosts: props.filterConfig.targetCosts,
                estimatedCosts: props.filterConfig.estimatedCosts,
                actualCosts: props.filterConfig.actualCosts,
                targetIncome: props.filterConfig.targetIncome,
                estimatedIncome: props.filterConfig.estimatedIncome,
                actualIncome: props.filterConfig.actualIncome,
                actualIncomeApproved: props.filterConfig.actualIncomeApproved,
                targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
                estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
                actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
                startDate: props.filterConfig.startDate,
                endDate: props.filterConfig.endDate,
                contractType: $$new,
                state: props.filterConfig.state,
                projectType: props.filterConfig.projectType,
                contractPrice: props.filterConfig.contractPrice,
                taxRate: props.filterConfig.taxRate,
                ownProject: props.filterConfig.ownProject,
                projectGroup: props.filterConfig.projectGroup,
                constructionType: props.filterConfig.constructionType,
                financingType: props.filterConfig.financingType
            });
        }),
        visibility: props.columnVisibility.contractType,
        onColumnVisibilityChange: function (v) {
            return props.onColumnVisibilityChange({
                code: props.columnVisibility.code,
                name: props.columnVisibility.name,
                version: props.columnVisibility.version,
                projectManager: props.columnVisibility.projectManager,
                targetCosts: props.columnVisibility.targetCosts,
                estimatedCosts: props.columnVisibility.estimatedCosts,
                actualCosts: props.columnVisibility.actualCosts,
                targetIncome: props.columnVisibility.targetIncome,
                estimatedIncome: props.columnVisibility.estimatedIncome,
                actualIncome: props.columnVisibility.actualIncome,
                actualIncomeApproved: props.columnVisibility.actualIncomeApproved,
                targetDiffAndMargin: props.columnVisibility.targetDiffAndMargin,
                estimatedDiffAndMargin: props.columnVisibility.estimatedDiffAndMargin,
                actualDiffAndMargin: props.columnVisibility.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.columnVisibility.actualDiffAndMarginApproved,
                startDate: props.columnVisibility.startDate,
                endDate: props.columnVisibility.endDate,
                contractType: v,
                state: props.columnVisibility.state,
                projectType: props.columnVisibility.projectType,
                contractPrice: props.columnVisibility.contractPrice,
                taxRate: props.columnVisibility.taxRate,
                ownProject: props.columnVisibility.ownProject,
                projectGroup: props.columnVisibility.projectGroup,
                constructionType: props.columnVisibility.constructionType,
                financingType: props.columnVisibility.financingType
            });
        },
        filterEnabled: FoldingTable_Filter.isSelectionFilterEnabled(props.filterConfig.contractType),
        clearFilter: props.onColumnFilterChange({
            code: props.filterConfig.code,
            name: props.filterConfig.name,
            version: props.filterConfig.version,
            projectManager: props.filterConfig.projectManager,
            targetCosts: props.filterConfig.targetCosts,
            estimatedCosts: props.filterConfig.estimatedCosts,
            actualCosts: props.filterConfig.actualCosts,
            targetIncome: props.filterConfig.targetIncome,
            estimatedIncome: props.filterConfig.estimatedIncome,
            actualIncome: props.filterConfig.actualIncome,
            actualIncomeApproved: props.filterConfig.actualIncomeApproved,
            targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
            estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
            actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
            actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
            startDate: props.filterConfig.startDate,
            endDate: props.filterConfig.endDate,
            contractType: {
                selection: Data_Maybe.Nothing.value
            },
            state: props.filterConfig.state,
            projectType: props.filterConfig.projectType,
            contractPrice: props.filterConfig.contractPrice,
            taxRate: props.filterConfig.taxRate,
            ownProject: props.filterConfig.ownProject,
            projectGroup: props.filterConfig.projectGroup,
            constructionType: props.filterConfig.constructionType,
            financingType: props.filterConfig.financingType
        })
    }), mkInputControl({
        label: Locale.lookup_("common_state"),
        filterControl: selectionFilter(props.filterConfig.state)(projectStates)(function ($$new) {
            return props.onColumnFilterChange({
                code: props.filterConfig.code,
                name: props.filterConfig.name,
                version: props.filterConfig.version,
                projectManager: props.filterConfig.projectManager,
                targetCosts: props.filterConfig.targetCosts,
                estimatedCosts: props.filterConfig.estimatedCosts,
                actualCosts: props.filterConfig.actualCosts,
                targetIncome: props.filterConfig.targetIncome,
                estimatedIncome: props.filterConfig.estimatedIncome,
                actualIncome: props.filterConfig.actualIncome,
                actualIncomeApproved: props.filterConfig.actualIncomeApproved,
                targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
                estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
                actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
                startDate: props.filterConfig.startDate,
                endDate: props.filterConfig.endDate,
                contractType: props.filterConfig.contractType,
                state: $$new,
                projectType: props.filterConfig.projectType,
                contractPrice: props.filterConfig.contractPrice,
                taxRate: props.filterConfig.taxRate,
                ownProject: props.filterConfig.ownProject,
                projectGroup: props.filterConfig.projectGroup,
                constructionType: props.filterConfig.constructionType,
                financingType: props.filterConfig.financingType
            });
        }),
        visibility: props.columnVisibility.state,
        onColumnVisibilityChange: function (v) {
            return props.onColumnVisibilityChange({
                code: props.columnVisibility.code,
                name: props.columnVisibility.name,
                version: props.columnVisibility.version,
                projectManager: props.columnVisibility.projectManager,
                targetCosts: props.columnVisibility.targetCosts,
                estimatedCosts: props.columnVisibility.estimatedCosts,
                actualCosts: props.columnVisibility.actualCosts,
                targetIncome: props.columnVisibility.targetIncome,
                estimatedIncome: props.columnVisibility.estimatedIncome,
                actualIncome: props.columnVisibility.actualIncome,
                actualIncomeApproved: props.columnVisibility.actualIncomeApproved,
                targetDiffAndMargin: props.columnVisibility.targetDiffAndMargin,
                estimatedDiffAndMargin: props.columnVisibility.estimatedDiffAndMargin,
                actualDiffAndMargin: props.columnVisibility.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.columnVisibility.actualDiffAndMarginApproved,
                startDate: props.columnVisibility.startDate,
                endDate: props.columnVisibility.endDate,
                contractType: props.columnVisibility.contractType,
                state: v,
                projectType: props.columnVisibility.projectType,
                contractPrice: props.columnVisibility.contractPrice,
                taxRate: props.columnVisibility.taxRate,
                ownProject: props.columnVisibility.ownProject,
                projectGroup: props.columnVisibility.projectGroup,
                constructionType: props.columnVisibility.constructionType,
                financingType: props.columnVisibility.financingType
            });
        },
        filterEnabled: FoldingTable_Filter.isSelectionFilterEnabled(props.filterConfig.state),
        clearFilter: props.onColumnFilterChange({
            code: props.filterConfig.code,
            name: props.filterConfig.name,
            version: props.filterConfig.version,
            projectManager: props.filterConfig.projectManager,
            targetCosts: props.filterConfig.targetCosts,
            estimatedCosts: props.filterConfig.estimatedCosts,
            actualCosts: props.filterConfig.actualCosts,
            targetIncome: props.filterConfig.targetIncome,
            estimatedIncome: props.filterConfig.estimatedIncome,
            actualIncome: props.filterConfig.actualIncome,
            actualIncomeApproved: props.filterConfig.actualIncomeApproved,
            targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
            estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
            actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
            actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
            startDate: props.filterConfig.startDate,
            endDate: props.filterConfig.endDate,
            contractType: props.filterConfig.contractType,
            state: {
                selection: Data_Maybe.Nothing.value
            },
            projectType: props.filterConfig.projectType,
            contractPrice: props.filterConfig.contractPrice,
            taxRate: props.filterConfig.taxRate,
            ownProject: props.filterConfig.ownProject,
            projectGroup: props.filterConfig.projectGroup,
            constructionType: props.filterConfig.constructionType,
            financingType: props.filterConfig.financingType
        })
    }), mkInputControl({
        label: Locale.lookup_("common_project_type"),
        filterControl: selectionFilter(props.filterConfig.projectType)(Data_Functor.map(Data_Functor.functorArray)(Data_Newtype.unwrap())(props.selectOptions.projectTypes))(function ($$new) {
            return props.onColumnFilterChange({
                code: props.filterConfig.code,
                name: props.filterConfig.name,
                version: props.filterConfig.version,
                projectManager: props.filterConfig.projectManager,
                targetCosts: props.filterConfig.targetCosts,
                estimatedCosts: props.filterConfig.estimatedCosts,
                actualCosts: props.filterConfig.actualCosts,
                targetIncome: props.filterConfig.targetIncome,
                estimatedIncome: props.filterConfig.estimatedIncome,
                actualIncome: props.filterConfig.actualIncome,
                actualIncomeApproved: props.filterConfig.actualIncomeApproved,
                targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
                estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
                actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
                startDate: props.filterConfig.startDate,
                endDate: props.filterConfig.endDate,
                contractType: props.filterConfig.contractType,
                state: props.filterConfig.state,
                projectType: $$new,
                contractPrice: props.filterConfig.contractPrice,
                taxRate: props.filterConfig.taxRate,
                ownProject: props.filterConfig.ownProject,
                projectGroup: props.filterConfig.projectGroup,
                constructionType: props.filterConfig.constructionType,
                financingType: props.filterConfig.financingType
            });
        }),
        visibility: props.columnVisibility.projectType,
        onColumnVisibilityChange: function (v) {
            return props.onColumnVisibilityChange({
                code: props.columnVisibility.code,
                name: props.columnVisibility.name,
                version: props.columnVisibility.version,
                projectManager: props.columnVisibility.projectManager,
                targetCosts: props.columnVisibility.targetCosts,
                estimatedCosts: props.columnVisibility.estimatedCosts,
                actualCosts: props.columnVisibility.actualCosts,
                targetIncome: props.columnVisibility.targetIncome,
                estimatedIncome: props.columnVisibility.estimatedIncome,
                actualIncome: props.columnVisibility.actualIncome,
                actualIncomeApproved: props.columnVisibility.actualIncomeApproved,
                targetDiffAndMargin: props.columnVisibility.targetDiffAndMargin,
                estimatedDiffAndMargin: props.columnVisibility.estimatedDiffAndMargin,
                actualDiffAndMargin: props.columnVisibility.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.columnVisibility.actualDiffAndMarginApproved,
                startDate: props.columnVisibility.startDate,
                endDate: props.columnVisibility.endDate,
                contractType: props.columnVisibility.contractType,
                state: props.columnVisibility.state,
                projectType: v,
                contractPrice: props.columnVisibility.contractPrice,
                taxRate: props.columnVisibility.taxRate,
                ownProject: props.columnVisibility.ownProject,
                projectGroup: props.columnVisibility.projectGroup,
                constructionType: props.columnVisibility.constructionType,
                financingType: props.columnVisibility.financingType
            });
        },
        filterEnabled: FoldingTable_Filter.isSelectionFilterEnabled(props.filterConfig.projectType),
        clearFilter: props.onColumnFilterChange({
            code: props.filterConfig.code,
            name: props.filterConfig.name,
            version: props.filterConfig.version,
            projectManager: props.filterConfig.projectManager,
            targetCosts: props.filterConfig.targetCosts,
            estimatedCosts: props.filterConfig.estimatedCosts,
            actualCosts: props.filterConfig.actualCosts,
            targetIncome: props.filterConfig.targetIncome,
            estimatedIncome: props.filterConfig.estimatedIncome,
            actualIncome: props.filterConfig.actualIncome,
            actualIncomeApproved: props.filterConfig.actualIncomeApproved,
            targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
            estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
            actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
            actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
            startDate: props.filterConfig.startDate,
            endDate: props.filterConfig.endDate,
            contractType: props.filterConfig.contractType,
            state: props.filterConfig.state,
            projectType: {
                selection: Data_Maybe.Nothing.value
            },
            contractPrice: props.filterConfig.contractPrice,
            taxRate: props.filterConfig.taxRate,
            ownProject: props.filterConfig.ownProject,
            projectGroup: props.filterConfig.projectGroup,
            constructionType: props.filterConfig.constructionType,
            financingType: props.filterConfig.financingType
        })
    }), mkInputControl({
        label: Locale.lookup_("common_contract_price"),
        filterControl: mkCurrencyFilter(props.filterConfig.contractPrice)(function ($$new) {
            return props.onColumnFilterChange({
                code: props.filterConfig.code,
                name: props.filterConfig.name,
                version: props.filterConfig.version,
                projectManager: props.filterConfig.projectManager,
                targetCosts: props.filterConfig.targetCosts,
                estimatedCosts: props.filterConfig.estimatedCosts,
                actualCosts: props.filterConfig.actualCosts,
                targetIncome: props.filterConfig.targetIncome,
                estimatedIncome: props.filterConfig.estimatedIncome,
                actualIncome: props.filterConfig.actualIncome,
                actualIncomeApproved: props.filterConfig.actualIncomeApproved,
                targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
                estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
                actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
                startDate: props.filterConfig.startDate,
                endDate: props.filterConfig.endDate,
                contractType: props.filterConfig.contractType,
                state: props.filterConfig.state,
                projectType: props.filterConfig.projectType,
                contractPrice: $$new,
                taxRate: props.filterConfig.taxRate,
                ownProject: props.filterConfig.ownProject,
                projectGroup: props.filterConfig.projectGroup,
                constructionType: props.filterConfig.constructionType,
                financingType: props.filterConfig.financingType
            });
        }),
        visibility: props.columnVisibility.contractPrice,
        onColumnVisibilityChange: function (v) {
            return props.onColumnVisibilityChange({
                code: props.columnVisibility.code,
                name: props.columnVisibility.name,
                version: props.columnVisibility.version,
                projectManager: props.columnVisibility.projectManager,
                targetCosts: props.columnVisibility.targetCosts,
                estimatedCosts: props.columnVisibility.estimatedCosts,
                actualCosts: props.columnVisibility.actualCosts,
                targetIncome: props.columnVisibility.targetIncome,
                estimatedIncome: props.columnVisibility.estimatedIncome,
                actualIncome: props.columnVisibility.actualIncome,
                actualIncomeApproved: props.columnVisibility.actualIncomeApproved,
                targetDiffAndMargin: props.columnVisibility.targetDiffAndMargin,
                estimatedDiffAndMargin: props.columnVisibility.estimatedDiffAndMargin,
                actualDiffAndMargin: props.columnVisibility.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.columnVisibility.actualDiffAndMarginApproved,
                startDate: props.columnVisibility.startDate,
                endDate: props.columnVisibility.endDate,
                contractType: props.columnVisibility.contractType,
                state: props.columnVisibility.state,
                projectType: props.columnVisibility.projectType,
                contractPrice: v,
                taxRate: props.columnVisibility.taxRate,
                ownProject: props.columnVisibility.ownProject,
                projectGroup: props.columnVisibility.projectGroup,
                constructionType: props.columnVisibility.constructionType,
                financingType: props.columnVisibility.financingType
            });
        },
        filterEnabled: FoldingTable_Filter.isNumericFilterEnabled(props.filterConfig.contractPrice),
        clearFilter: props.onColumnFilterChange({
            code: props.filterConfig.code,
            name: props.filterConfig.name,
            version: props.filterConfig.version,
            projectManager: props.filterConfig.projectManager,
            targetCosts: props.filterConfig.targetCosts,
            estimatedCosts: props.filterConfig.estimatedCosts,
            actualCosts: props.filterConfig.actualCosts,
            targetIncome: props.filterConfig.targetIncome,
            estimatedIncome: props.filterConfig.estimatedIncome,
            actualIncome: props.filterConfig.actualIncome,
            actualIncomeApproved: props.filterConfig.actualIncomeApproved,
            targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
            estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
            actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
            actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
            startDate: props.filterConfig.startDate,
            endDate: props.filterConfig.endDate,
            contractType: props.filterConfig.contractType,
            state: props.filterConfig.state,
            projectType: props.filterConfig.projectType,
            contractPrice: {
                min: Data_Maybe.Nothing.value,
                max: Data_Maybe.Nothing.value
            },
            taxRate: props.filterConfig.taxRate,
            ownProject: props.filterConfig.ownProject,
            projectGroup: props.filterConfig.projectGroup,
            constructionType: props.filterConfig.constructionType,
            financingType: props.filterConfig.financingType
        })
    }), mkInputControl({
        label: Locale.lookup_("common_tax_rate"),
        filterControl: mkCurrencyFilter(props.filterConfig.taxRate)(function ($$new) {
            return props.onColumnFilterChange({
                code: props.filterConfig.code,
                name: props.filterConfig.name,
                version: props.filterConfig.version,
                projectManager: props.filterConfig.projectManager,
                targetCosts: props.filterConfig.targetCosts,
                estimatedCosts: props.filterConfig.estimatedCosts,
                actualCosts: props.filterConfig.actualCosts,
                targetIncome: props.filterConfig.targetIncome,
                estimatedIncome: props.filterConfig.estimatedIncome,
                actualIncome: props.filterConfig.actualIncome,
                actualIncomeApproved: props.filterConfig.actualIncomeApproved,
                targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
                estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
                actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
                startDate: props.filterConfig.startDate,
                endDate: props.filterConfig.endDate,
                contractType: props.filterConfig.contractType,
                state: props.filterConfig.state,
                projectType: props.filterConfig.projectType,
                contractPrice: props.filterConfig.contractPrice,
                taxRate: $$new,
                ownProject: props.filterConfig.ownProject,
                projectGroup: props.filterConfig.projectGroup,
                constructionType: props.filterConfig.constructionType,
                financingType: props.filterConfig.financingType
            });
        }),
        visibility: props.columnVisibility.taxRate,
        onColumnVisibilityChange: function (v) {
            return props.onColumnVisibilityChange({
                code: props.columnVisibility.code,
                name: props.columnVisibility.name,
                version: props.columnVisibility.version,
                projectManager: props.columnVisibility.projectManager,
                targetCosts: props.columnVisibility.targetCosts,
                estimatedCosts: props.columnVisibility.estimatedCosts,
                actualCosts: props.columnVisibility.actualCosts,
                targetIncome: props.columnVisibility.targetIncome,
                estimatedIncome: props.columnVisibility.estimatedIncome,
                actualIncome: props.columnVisibility.actualIncome,
                actualIncomeApproved: props.columnVisibility.actualIncomeApproved,
                targetDiffAndMargin: props.columnVisibility.targetDiffAndMargin,
                estimatedDiffAndMargin: props.columnVisibility.estimatedDiffAndMargin,
                actualDiffAndMargin: props.columnVisibility.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.columnVisibility.actualDiffAndMarginApproved,
                startDate: props.columnVisibility.startDate,
                endDate: props.columnVisibility.endDate,
                contractType: props.columnVisibility.contractType,
                state: props.columnVisibility.state,
                projectType: props.columnVisibility.projectType,
                contractPrice: props.columnVisibility.contractPrice,
                taxRate: v,
                ownProject: props.columnVisibility.ownProject,
                projectGroup: props.columnVisibility.projectGroup,
                constructionType: props.columnVisibility.constructionType,
                financingType: props.columnVisibility.financingType
            });
        },
        filterEnabled: FoldingTable_Filter.isNumericFilterEnabled(props.filterConfig.taxRate),
        clearFilter: props.onColumnFilterChange({
            code: props.filterConfig.code,
            name: props.filterConfig.name,
            version: props.filterConfig.version,
            projectManager: props.filterConfig.projectManager,
            targetCosts: props.filterConfig.targetCosts,
            estimatedCosts: props.filterConfig.estimatedCosts,
            actualCosts: props.filterConfig.actualCosts,
            targetIncome: props.filterConfig.targetIncome,
            estimatedIncome: props.filterConfig.estimatedIncome,
            actualIncome: props.filterConfig.actualIncome,
            actualIncomeApproved: props.filterConfig.actualIncomeApproved,
            targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
            estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
            actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
            actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
            startDate: props.filterConfig.startDate,
            endDate: props.filterConfig.endDate,
            contractType: props.filterConfig.contractType,
            state: props.filterConfig.state,
            projectType: props.filterConfig.projectType,
            contractPrice: props.filterConfig.contractPrice,
            taxRate: {
                min: Data_Maybe.Nothing.value,
                max: Data_Maybe.Nothing.value
            },
            ownProject: props.filterConfig.ownProject,
            projectGroup: props.filterConfig.projectGroup,
            constructionType: props.filterConfig.constructionType,
            financingType: props.filterConfig.financingType
        })
    }), mkInputControl({
        label: Locale.lookup_("common_own_project"),
        filterControl: selectionFilter(props.filterConfig.ownProject)([ "Kyll\xe4", "Ei" ])(function ($$new) {
            return props.onColumnFilterChange({
                code: props.filterConfig.code,
                name: props.filterConfig.name,
                version: props.filterConfig.version,
                projectManager: props.filterConfig.projectManager,
                targetCosts: props.filterConfig.targetCosts,
                estimatedCosts: props.filterConfig.estimatedCosts,
                actualCosts: props.filterConfig.actualCosts,
                targetIncome: props.filterConfig.targetIncome,
                estimatedIncome: props.filterConfig.estimatedIncome,
                actualIncome: props.filterConfig.actualIncome,
                actualIncomeApproved: props.filterConfig.actualIncomeApproved,
                targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
                estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
                actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
                startDate: props.filterConfig.startDate,
                endDate: props.filterConfig.endDate,
                contractType: props.filterConfig.contractType,
                state: props.filterConfig.state,
                projectType: props.filterConfig.projectType,
                contractPrice: props.filterConfig.contractPrice,
                taxRate: props.filterConfig.taxRate,
                ownProject: $$new,
                projectGroup: props.filterConfig.projectGroup,
                constructionType: props.filterConfig.constructionType,
                financingType: props.filterConfig.financingType
            });
        }),
        visibility: props.columnVisibility.ownProject,
        onColumnVisibilityChange: function (v) {
            return props.onColumnVisibilityChange({
                code: props.columnVisibility.code,
                name: props.columnVisibility.name,
                version: props.columnVisibility.version,
                projectManager: props.columnVisibility.projectManager,
                targetCosts: props.columnVisibility.targetCosts,
                estimatedCosts: props.columnVisibility.estimatedCosts,
                actualCosts: props.columnVisibility.actualCosts,
                targetIncome: props.columnVisibility.targetIncome,
                estimatedIncome: props.columnVisibility.estimatedIncome,
                actualIncome: props.columnVisibility.actualIncome,
                actualIncomeApproved: props.columnVisibility.actualIncomeApproved,
                targetDiffAndMargin: props.columnVisibility.targetDiffAndMargin,
                estimatedDiffAndMargin: props.columnVisibility.estimatedDiffAndMargin,
                actualDiffAndMargin: props.columnVisibility.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.columnVisibility.actualDiffAndMarginApproved,
                startDate: props.columnVisibility.startDate,
                endDate: props.columnVisibility.endDate,
                contractType: props.columnVisibility.contractType,
                state: props.columnVisibility.state,
                projectType: props.columnVisibility.projectType,
                contractPrice: props.columnVisibility.contractPrice,
                taxRate: props.columnVisibility.taxRate,
                ownProject: v,
                projectGroup: props.columnVisibility.projectGroup,
                constructionType: props.columnVisibility.constructionType,
                financingType: props.columnVisibility.financingType
            });
        },
        filterEnabled: FoldingTable_Filter.isSelectionFilterEnabled(props.filterConfig.ownProject),
        clearFilter: props.onColumnFilterChange({
            code: props.filterConfig.code,
            name: props.filterConfig.name,
            version: props.filterConfig.version,
            projectManager: props.filterConfig.projectManager,
            targetCosts: props.filterConfig.targetCosts,
            estimatedCosts: props.filterConfig.estimatedCosts,
            actualCosts: props.filterConfig.actualCosts,
            targetIncome: props.filterConfig.targetIncome,
            estimatedIncome: props.filterConfig.estimatedIncome,
            actualIncome: props.filterConfig.actualIncome,
            actualIncomeApproved: props.filterConfig.actualIncomeApproved,
            targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
            estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
            actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
            actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
            startDate: props.filterConfig.startDate,
            endDate: props.filterConfig.endDate,
            contractType: props.filterConfig.contractType,
            state: props.filterConfig.state,
            projectType: props.filterConfig.projectType,
            contractPrice: props.filterConfig.contractPrice,
            taxRate: props.filterConfig.taxRate,
            ownProject: {
                selection: Data_Maybe.Nothing.value
            },
            projectGroup: props.filterConfig.projectGroup,
            constructionType: props.filterConfig.constructionType,
            financingType: props.filterConfig.financingType
        })
    }), mkInputControl({
        label: Locale.lookup_("project_group"),
        filterControl: stringFilterControl(props.filterConfig.projectGroup)(function ($$new) {
            return props.onColumnFilterChange({
                code: props.filterConfig.code,
                name: props.filterConfig.name,
                version: props.filterConfig.version,
                projectManager: props.filterConfig.projectManager,
                targetCosts: props.filterConfig.targetCosts,
                estimatedCosts: props.filterConfig.estimatedCosts,
                actualCosts: props.filterConfig.actualCosts,
                targetIncome: props.filterConfig.targetIncome,
                estimatedIncome: props.filterConfig.estimatedIncome,
                actualIncome: props.filterConfig.actualIncome,
                actualIncomeApproved: props.filterConfig.actualIncomeApproved,
                targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
                estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
                actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
                startDate: props.filterConfig.startDate,
                endDate: props.filterConfig.endDate,
                contractType: props.filterConfig.contractType,
                state: props.filterConfig.state,
                projectType: props.filterConfig.projectType,
                contractPrice: props.filterConfig.contractPrice,
                taxRate: props.filterConfig.taxRate,
                ownProject: props.filterConfig.ownProject,
                projectGroup: $$new,
                constructionType: props.filterConfig.constructionType,
                financingType: props.filterConfig.financingType
            });
        }),
        visibility: props.columnVisibility.projectGroup,
        onColumnVisibilityChange: function (v) {
            return props.onColumnVisibilityChange({
                code: props.columnVisibility.code,
                name: props.columnVisibility.name,
                version: props.columnVisibility.version,
                projectManager: props.columnVisibility.projectManager,
                targetCosts: props.columnVisibility.targetCosts,
                estimatedCosts: props.columnVisibility.estimatedCosts,
                actualCosts: props.columnVisibility.actualCosts,
                targetIncome: props.columnVisibility.targetIncome,
                estimatedIncome: props.columnVisibility.estimatedIncome,
                actualIncome: props.columnVisibility.actualIncome,
                actualIncomeApproved: props.columnVisibility.actualIncomeApproved,
                targetDiffAndMargin: props.columnVisibility.targetDiffAndMargin,
                estimatedDiffAndMargin: props.columnVisibility.estimatedDiffAndMargin,
                actualDiffAndMargin: props.columnVisibility.actualDiffAndMargin,
                actualDiffAndMarginApproved: props.columnVisibility.actualDiffAndMarginApproved,
                startDate: props.columnVisibility.startDate,
                endDate: props.columnVisibility.endDate,
                contractType: props.columnVisibility.contractType,
                state: props.columnVisibility.state,
                projectType: props.columnVisibility.projectType,
                contractPrice: props.columnVisibility.contractPrice,
                taxRate: props.columnVisibility.taxRate,
                ownProject: props.columnVisibility.ownProject,
                projectGroup: v,
                constructionType: props.columnVisibility.constructionType,
                financingType: props.columnVisibility.financingType
            });
        },
        filterEnabled: FoldingTable_Filter.isStringFilterEnabled(props.filterConfig.projectGroup),
        clearFilter: props.onColumnFilterChange({
            code: props.filterConfig.code,
            name: props.filterConfig.name,
            version: props.filterConfig.version,
            projectManager: props.filterConfig.projectManager,
            targetCosts: props.filterConfig.targetCosts,
            estimatedCosts: props.filterConfig.estimatedCosts,
            actualCosts: props.filterConfig.actualCosts,
            targetIncome: props.filterConfig.targetIncome,
            estimatedIncome: props.filterConfig.estimatedIncome,
            actualIncome: props.filterConfig.actualIncome,
            actualIncomeApproved: props.filterConfig.actualIncomeApproved,
            targetDiffAndMargin: props.filterConfig.targetDiffAndMargin,
            estimatedDiffAndMargin: props.filterConfig.estimatedDiffAndMargin,
            actualDiffAndMargin: props.filterConfig.actualDiffAndMargin,
            actualDiffAndMarginApproved: props.filterConfig.actualDiffAndMarginApproved,
            startDate: props.filterConfig.startDate,
            endDate: props.filterConfig.endDate,
            contractType: props.filterConfig.contractType,
            state: props.filterConfig.state,
            projectType: props.filterConfig.projectType,
            contractPrice: props.filterConfig.contractPrice,
            taxRate: props.filterConfig.taxRate,
            ownProject: props.filterConfig.ownProject,
            projectGroup: {
                string: Data_Maybe.Nothing.value
            },
            constructionType: props.filterConfig.constructionType,
            financingType: props.filterConfig.financingType
        })
    }) ];
    var closeBtn = TacoButton.component()()({
        icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconChevronUpV1.value),
        text: Locale.lookup_("close"),
        onClick: new Data_Maybe.Just(props.onClose)
    });
    var actionsRow = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        className: "close-button",
        css: PortfolioFilterSettings_Styles.closeButtonStyles,
        children: [ closeBtn ]
    });
    var filterSettings = React_Basic_DOM_Generated.div()({
        className: "settings",
        children: Data_Semigroup.append(Data_Semigroup.semigroupArray)([ headings ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(filterControls)([ actionsRow ]))
    });
    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_DOM_Generated.div()({
        className: "filter-settings-view",
        children: [ filterSettings ]
    }));
});
module.exports = {
    portfolioFilterSettings: portfolioFilterSettings
};
