// Generated by purs version 0.14.5
"use strict";
var Components_Utils = require("../Components.Utils/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var DOMUtils = require("../DOMUtils/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_DOM_SVG = require("../React.Basic.DOM.SVG/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var Record = require("../Record/index.js");
var TacoLoader_Styles = require("../TacoLoader.Styles/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var UseHasMounted = require("../UseHasMounted/index.js");
var useLoadingInitKey = "__useLoading__init";
var useLoading = function (initialState) {
    return function __do() {
        var v = TofuHooks.useState((function () {
            if (initialState) {
                return Data_Map_Internal.insert(Data_Ord.ordString)(useLoadingInitKey)(initialState)(Data_Map_Internal.empty);
            };
            return Data_Map_Internal.empty;
        })())();
        var setLoading = TofuHooks.useMemo([ v.value0 ])(function (v1) {
            return function (key) {
                return function (value) {
                    var state$prime = (function () {
                        var $9 = Data_Map_Internal.member(Data_Ord.ordString)(useLoadingInitKey)(v.value0);
                        if ($9) {
                            return Data_Map_Internal["delete"](Data_Ord.ordString)(useLoadingInitKey)(v.value0);
                        };
                        return v.value0;
                    })();
                    var state$prime$prime = (function () {
                        if (value) {
                            return Data_Map_Internal.insert(Data_Ord.ordString)(key)(value)(state$prime);
                        };
                        return Data_Map_Internal["delete"](Data_Ord.ordString)(key)(state$prime);
                    })();
                    var $11 = Data_Eq.notEq(Data_Map_Internal.eqMap(Data_Eq.eqString)(Data_Eq.eqBoolean))(v.value0)(state$prime$prime);
                    if ($11) {
                        return v.value1(function (v2) {
                            return state$prime$prime;
                        });
                    };
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                };
            };
        })();
        var isLoading = !Data_Map_Internal.isEmpty(v.value0);
        return {
            isLoading: isLoading,
            setLoading: setLoading
        };
    };
};
var progressBar = TofuHooks.mkHookComponent("TacoLoader__ProgressBar")(function (v) {
    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        className: "__TacoLoader__ProgressBarContainer",
        css: TacoLoader_Styles.tacoLoaderProgressBarContainerStyles({
            color: v.color,
            isLoading: v.isLoading
        }),
        "_data": Components_Utils.mkTestIdAttrs("taco-loader-" + (v.testId + ("-" + (function () {
            if (v.isLoading) {
                return "loading";
            };
            return "done";
        })()))),
        children: Data_Monoid.guard(Data_Monoid.monoidArray)(v.isLoading)([ React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "__TacoLoader__ProgressBar_Primary",
            css: TacoLoader_Styles.tacoLoaderProgressBarStyles({
                color: v.color,
                secondary: false
            })
        }), React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "__TacoLoader__ProgressBar_Secondary",
            css: TacoLoader_Styles.tacoLoaderProgressBarStyles({
                color: v.color,
                secondary: true
            })
        }), Data_Monoid.guard(React_Basic.monoidJSX)(v.isLoading)(React_Basic_DOM_Generated.span()({
            "_data": Components_Utils.mkTestIdAttrs("loading-in-progress")
        })) ])
    }));
});
var defaultProps = {
    size: "4rem"
};
var component = function (dictUnion) {
    return function (dictNub) {
        var svg = React_Basic_Emotion.element(React_Basic_DOM_SVG["svg'"]())({
            className: "loading-spinner__svg",
            css: TacoLoader_Styles.loadingSpinnerSvgStyles,
            width: "100%",
            height: "100%",
            viewBox: "0 0 66 66",
            xmlns: "http://www.w3.org/2000/svg",
            preserveAspectRatio: "xMidYMid meet",
            children: Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_Emotion.element(DOMUtils.circle())({
                className: "loading-spinner__path",
                css: TacoLoader_Styles.loadingSpinnerPathStyles,
                fill: "none",
                strokeWidth: "6px",
                strokeLinecap: "round",
                cx: "33",
                cy: "33",
                r: "30"
            }))
        });
        return TofuHooks.mkHookComponent("TacoLoader")(function (props) {
            var v = Record.merge()()(props)(defaultProps);
            return function __do() {
                var hasMounted = UseHasMounted.useHasMounted();
                return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                    className: "loading-spinner",
                    css: TacoLoader_Styles.tacoLoaderContainerStyles({
                        hasMounted: hasMounted,
                        size: v.size
                    }),
                    children: Control_Applicative.pure(Control_Applicative.applicativeArray)(svg)
                });
            };
        });
    };
};
module.exports = {
    defaultProps: defaultProps,
    component: component,
    progressBar: progressBar,
    useLoadingInitKey: useLoadingInitKey,
    useLoading: useLoading
};
