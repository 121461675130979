import { useQuery } from "react-query";
import axiosClient from "../../../../axiosConfig";
import { ClassificationGroup } from "../../../../../../ts-bindings/ClassificationGroup";

export function useGetGroupsQuery(projectId: number) {
  return useQuery({
    queryKey: ["classificationGroups", projectId],
    queryFn: async () => {
      const { data } = await axiosClient.get<ClassificationGroup[]>(
        `/project/${projectId}/classificationGroups`
      );
      return data;
    },

    enabled: projectId !== undefined,
  });
}
