import i18n from "i18next";

const defaultLanguage = "FI-fi";
const defaultDecimals = 1;

/**
 * Formats number to a localized float value.
 * e.g. calling the function with value 120.000 and 2 decimal will result in a
 * string "120,00" when the users chosen localization is finnish.
 * Decimal value is optional, defaults to 1 decimal if not called with decimals param.
 * Language defaults to finnish if i18n is undefined
 */
export const formatNumberToLocaleString = (
  value: number,
  decimals?: number
): string =>
  value.toLocaleString(i18n?.language ?? defaultLanguage, {
    maximumFractionDigits: decimals ?? defaultDecimals,
    minimumFractionDigits: decimals ?? defaultDecimals,
  });

/**
 * Format number to a localized percentage value.
 */
export const formatNumberToLocalePercent = (
  value: number,
  decimals?: number
): string =>
  value.toLocaleString(i18n?.language ?? defaultLanguage, {
    style: "percent",
    maximumFractionDigits: decimals ?? defaultDecimals,
    minimumFractionDigits: decimals ?? defaultDecimals,
  });
