import { RefObject, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";

export const usePinnedBottomRowUpdater = (
  gridRef: RefObject<AgGridReact>,
  dataRendered: boolean,
  // eslint-disable-next-line
  pinnedBottomRowData: any
) => {
  useEffect(() => {
    if (dataRendered && gridRef.current) {
      gridRef.current.api.setGridOption(
        "pinnedBottomRowData",
        pinnedBottomRowData
      );
    }
  }, [dataRendered, gridRef, pinnedBottomRowData]);
};
