// Generated by purs version 0.14.5
"use strict";
var Data_Int = require("../Data.Int/index.js");
var Portal = require("../Portal/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var Record = require("../Record/index.js");
var TacoTooltip_Styles = require("../TacoTooltip.Styles/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var UseCallbackRef = require("../UseCallbackRef/index.js");
var UseMeasure = require("../UseMeasure/index.js");
var defaultProps = {
    tip: [  ],
    anchor: [  ],
    justify: TacoTooltip_Styles.Bottom.value,
    active: TacoTooltip_Styles.OnHover.value
};
var component = function (dictUnion) {
    return function (dictNub) {
        return TofuHooks.mkHookComponent("TacoTooltip")(function (partialProps) {
            var v = Record.merge()()(partialProps)(defaultProps);
            return function __do() {
                var v1 = TofuHooks.useState(false)();
                var v2 = UseMeasure.useMeasure();
                var v3 = UseMeasure.useMeasure();
                var tooltip = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                    ref: UseCallbackRef.toNodeRef(v3.callbackRef),
                    className: "tooltip",
                    css: TacoTooltip_Styles.tooltipStyles({
                        containerHeight: Data_Int.round(v2.dimensions.height),
                        containerWidth: Data_Int.round(v2.dimensions.width),
                        tipWidth: Data_Int.round(v3.dimensions.width),
                        justify: v.justify
                    }),
                    children: v.tip
                });
                var hoverWrapper = React_Basic_DOM_Generated.div()({
                    ref: UseCallbackRef.toNodeRef(v2.callbackRef),
                    children: v.anchor,
                    className: "hover-wrapper",
                    onMouseEnter: React_Basic_Events.handler_(v1.value1(function (v4) {
                        return true;
                    })),
                    onMouseLeave: React_Basic_Events.handler_(v1.value1(function (v4) {
                        return false;
                    }))
                });
                var arrow = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                    className: "arrow",
                    css: TacoTooltip_Styles.arrowStyles({
                        containerHeight: Data_Int.round(v2.dimensions.height),
                        containerWidth: Data_Int.round(v2.dimensions.width),
                        justify: v.justify
                    })
                });
                var tooltipWrapper = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                    className: "tooltip-wrapper",
                    css: TacoTooltip_Styles.tooltipWrapperStyles({
                        active: v.active,
                        hovered: v1.value0,
                        containerRect: v2.dimensions
                    }),
                    children: [ tooltip, arrow ]
                });
                return React_Basic_Emotion.element(React_Basic_DOM_Generated["span'"]())({
                    className: "__TacoTooltip",
                    css: TacoTooltip_Styles.tooltipContainerStyles,
                    children: [ hoverWrapper, Portal.component({
                        contents: tooltipWrapper,
                        id: "TacoTooltip"
                    }) ]
                });
            };
        });
    };
};
module.exports = {
    component: component,
    Always: TacoTooltip_Styles.Always,
    Never: TacoTooltip_Styles.Never,
    OnHover: TacoTooltip_Styles.OnHover,
    Bottom: TacoTooltip_Styles.Bottom,
    BottomLeft: TacoTooltip_Styles.BottomLeft,
    BottomRight: TacoTooltip_Styles.BottomRight,
    Top: TacoTooltip_Styles.Top,
    TopLeft: TacoTooltip_Styles.TopLeft,
    TopRight: TacoTooltip_Styles.TopRight,
    arrowStyles: TacoTooltip_Styles.arrowStyles,
    tooltipContainerStyles: TacoTooltip_Styles.tooltipContainerStyles,
    tooltipStyles: TacoTooltip_Styles.tooltipStyles,
    tooltipWrapperStyles: TacoTooltip_Styles.tooltipWrapperStyles
};
