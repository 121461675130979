// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Category = require("../Control.Category/index.js");
var Controls = require("../Controls/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Map = require("../Data.Map/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unfoldable = require("../Data.Unfoldable/index.js");
var Effect = require("../Effect/index.js");
var TacoGroupToggle = require("../TacoGroupToggle/index.js");
var TacoInput = require("../TacoInput/index.js");
var groups = Data_Map_Internal.fromFoldable(Data_Ord.ordString)(Data_Foldable.foldableArray)([ new Data_Tuple.Tuple("Group 1", true), new Data_Tuple.Tuple("Group 2", false) ]);
var example1 = "import Controls as Controls\x0a\x0aControls.component\x0a  { leftControls:\x0a      [ TacoInput.stringInput\x0a          { value: \"Hello world\"\x0a          }\x0a      ]\x0a  , rightControls:\x0a      [ TacoGroupToggle.component\x0a          { groups: Set.toUnfoldable $ Map.keys groups\x0a          , handleToggleGroup: pure mempty\x0a          , testActive: \\group -> fromMaybe false $ Map.lookup group groups\x0a          , getName: identity\x0a          }\x0a      ]\x0a\x0a  }\x0a";
var sections = [ {
    title: "Default",
    view: [ Controls.component()()({
        leftControls: [ TacoInput.stringInput()()(Data_Eq.eqString)({
            value: "Hello world"
        }) ],
        rightControls: [ TacoGroupToggle.component({
            groups: Data_Set.toUnfoldable(Data_Unfoldable.unfoldableArray)(Data_Map.keys(groups)),
            handleToggleGroup: Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit)))),
            testActive: function (group) {
                return Data_Maybe.fromMaybe(false)(Data_Map_Internal.lookup(Data_Ord.ordString)(group)(groups));
            },
            getName: Control_Category.identity(Control_Category.categoryFn)
        }) ]
    }) ],
    code: example1
} ];
var demoView = {
    sections: sections,
    title: "Controls",
    description: "Component for placing left & right aligned controls on a row"
};
module.exports = {
    demoView: demoView
};
