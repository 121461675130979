import { CostClass } from "../../../../../../ts-bindings/CostClass";
import { useMemo } from "react";

export function useEvaluateCcDiffs(
  origCostClasses: CostClass[] | undefined,
  newCostClasses: CostClass[]
): [boolean, Record<string, CcDiff>] {
  const ccDiffs = useMemo(() => evalCcDiffs(origCostClasses, newCostClasses), [
    origCostClasses,
    newCostClasses,
  ]);

  // These error types will disable the import button
  const criticalCcErrors = useMemo(() => {
    return Object.values(ccDiffs).some(
      (ccDiff) => ccDiff.missing || ccDiff.changedSocExp !== undefined
    );
  }, [ccDiffs]);

  return [criticalCcErrors, ccDiffs];
}

export type CcDiff = {
  origSocExp: number | null;
  changedSocExp?: number | null;
  origHourlyPricing: boolean;
  changedHourlyPricing?: boolean;
  missing: boolean;
};

function evalCcDiffs(
  origCostClasses: CostClass[] | undefined,
  newCostClasses: CostClass[]
): Record<string, CcDiff> {
  const entries = (origCostClasses ?? [])
    .map((origCc) => {
      const ccDiff: CcDiff = {
        origSocExp: origCc.socialExpensePercentageInCostEstimation,
        changedSocExp: undefined,
        origHourlyPricing: origCc.hourlyPricing,
        changedHourlyPricing: undefined,
        missing: true,
      };

      const cc = newCostClasses.find(
        (cc) => cc.costClassCode === origCc.costClassCode
      );

      if (cc) {
        ccDiff.missing = false;

        if (
          (origCc.socialExpensePercentageInCostEstimation ?? 0) !==
          (cc.socialExpensePercentageInCostEstimation ?? 0)
        ) {
          ccDiff.changedSocExp = cc.socialExpensePercentageInCostEstimation;
        }
        if (origCc.hourlyPricing !== cc.hourlyPricing) {
          ccDiff.changedHourlyPricing = cc.hourlyPricing;
        }
      }

      if (
        !ccDiff.missing &&
        ccDiff.changedSocExp === undefined &&
        ccDiff.changedHourlyPricing === undefined
      ) {
        return undefined;
      }

      return [origCc.costClassCode, ccDiff];
    })
    .filter((entry) => entry !== undefined) as [string, CcDiff][];

  return Object.fromEntries(entries);
}
