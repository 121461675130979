import { useAtomsDevtools } from "jotai-devtools";
import { useAtom } from "jotai";
import { SetAtom } from "jotai/core/atom";
import { useCallback, useEffect, useRef } from "react";
import { stateNodeAtoms, workerStateRequestParams } from "../utils/state";
import { WorkerRequest } from "ts-bindings/WorkerRequest";
import { WorkerResponse } from "ts-bindings/WorkerResponse";
import { PaginatedResult } from "ts-bindings/PaginatedResult";
import { WorkerStateUpdate } from "ts-bindings/WorkerStateUpdate";

/**
 * Attaches debug labels to each node (visible in Redux DevTools).
 */
export const attachStateDebugLabels = () => {
  Object.entries(stateNodeAtoms).forEach(([key, value]) => {
    value.debugLabel = key;
  });
};

/**
 * Subscribe to each state node that the TypeScript app is aware of.
 */
export const subscribeStateNodes = () => {
  Object.entries(stateNodeAtoms).forEach(([key]) => {
    const request: WorkerRequest = {
      type: "SubscribeWorkerState",
      value: {
        state_key: key,
        // Since we're running inside the old app, this subscription might not
        // count as the initial subscription which means we would not receive
        // initial state. Force wasm-worker to send us initial state.
        force_new_sub: true,
      },
    };

    sendWorkerRequest(request);
  });
};

/**
 * React hook that sets up our app's state and subscribes it to worker state
 * updates.
 */
export const useProvideAppState = () => {
  // Create an array of [key, setter] primitives that can
  // be called to update state.

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setters = useRef<(readonly [string, SetAtom<any, void>])[]>();

  setters.current = Object.entries(stateNodeAtoms).map(([key, atom]) => {
    // DANGER: React hooks lints don't like what we're doing here, but this is
    // safe as long as stateNodeAtoms does not change at runtime (which it never
    // should!)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [, setter] = useAtom(atom as any);
    return [key, setter] as const;
  });

  // Create event listener that will update state for matching keys
  const eventListener = useCallback(
    (event: MessageEvent<WorkerResponse>) => {
      if (event.data.type === "BundledWorkerStateUpdate") {
        const { changed } = event.data.value;

        // Update state for each changed state node
        changed.forEach((stateUpdate: WorkerStateUpdate) => {
          const node = stateUpdate.node;
          const requestParams = stateUpdate.request_params;

          const setter = setters.current?.find(([key]) => key === node.type);

          if (setter) {
            setter[1](node.value);
            workerStateRequestParams[node.type] = requestParams;
          }
        });
      } else if (event.data.type === "UiNotification") {
        const alert = event.data.value;
        const level = alert.alert_level.type;
        const msg = `UI notification: ${alert.msg}`;

        if (level === "AlertError") {
          console.error(msg);
        } else if (level === "AlertWarning") {
          console.warn(msg);
        } else if (level === "AlertInfo") {
          console.info(msg);
        }
      } else if (event.data.type === "GetEmptyNodes") {
        const { nodes } = event.data.value;
        console.log("GetEmptyNodes result: ");
        console.log(JSON.stringify(nodes));
        if (nodes.length === 0) {
          console.log("AssertNoEmptyNodes test passed!");
        } else {
          console.log(
            "The StateNodes listed above are still empty, and so the AssertNoEmptyNodes test failed."
          );
          console.log(
            "Make sure this list does not contain any root/parameter nodes. If it does, you need to set their values in the test."
          );
        }
      }
    },
    [setters]
  );

  // Subscribe to wasm worker events while component is mounted
  useEffect(() => {
    window.wasmWorker.addEventListener("message", eventListener);

    () => {
      window.wasmWorker.removeEventListener("message", eventListener);
    };
  }, [eventListener]);

  // Subscribe to state nodes at initial render
  useEffect(() => {
    subscribeStateNodes();
  }, []);

  // Attach debug labels to each state node at initial render
  useEffect(() => {
    attachStateDebugLabels();
  }, []);

  // Makes it possible to use Redux DevTools browser extension to inspect app
  // state
  useAtomsDevtools("tofuman");
};

/**
 * Sends WorkerRequest to the worker thread.
 */
export const sendWorkerRequest = (request: WorkerRequest) => {
  window.wasmWorker.postMessage(request);
};

export const mkEmptyPaginatedResult = <T>(): PaginatedResult<T> => ({
  count: 0,
  data: [],
  hash: BigInt(0),
  params: {
    startIndex: 0,
    stopIndex: -1,
    columnFilters: [],
    sorting: [],
  },
});
