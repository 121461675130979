// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoModal = require("../TacoModal/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_CostEstimation = require("../Types.CostEstimation/index.js");
var editMultiplePricesButton = TofuHooks.mkHookComponent("EditMultiplePricesButton")(function (props) {
    var components = Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Data_Functor.functorFn)(function (v) {
        return v.component;
    })(Data_Newtype.un()(Types_CostEstimation.EstimationComponentWithResources)))(props.selectedComponentsECWR);
    var componentIds = Data_Functor.map(Data_Functor.functorArray)(function (v) {
        return v.id;
    })(components);
    return function __do() {
        var v = TofuHooks.useState(false)();
        var v1 = TofuHooks.useState("1")();
        var v2 = TofuHooks.useState(1.0)();
        var dispatch = StateHooks.useDispatch();
        var resetInputFields = function __do() {
            v1.value1(function (v3) {
                return "1";
            })();
            v2.value1(function (v3) {
                return 1.0;
            })();
            return Data_Unit.unit;
        };
        TofuHooks.useEffect([ ReactHooksUtils.utf(props.selectedComponentsECWR) ])(function __do() {
            resetInputFields();
            return Data_Monoid.mempty(Effect.monoidEffect(Effect.monoidEffect(Data_Monoid.monoidUnit)))();
        })();
        var modalBody = TacoModal.modalBody({
            contents: [ TacoInput.stringInput()()(Data_Eq.eqString)({
                label: "Panoslaji",
                value: v1.value0,
                onChange: function (newCode) {
                    return v1.value1(newCode);
                }
            }), TacoInput.numberInput()()(Data_Eq.eqNumber)({
                label: "Kerroin",
                value: v2.value0,
                onChange: function (newMultiplier) {
                    return v2.value1(newMultiplier);
                },
                precision: 2
            }) ]
        });
        var closeModal = function __do() {
            resetInputFields();
            return v.value1(function (v3) {
                return false;
            })();
        };
        var saveAndCloseModal = dispatch(new Actions.ResourcePriceAdjustmentRequest({
            handler: function (v3) {
                return closeModal;
            },
            projectId: props.projectId,
            params: {
                componentIds: componentIds,
                costClassCode: v1.value0,
                priceMultiplier: v2.value0,
                dryRun: false
            }
        }));
        var modalActions = TacoModal.modalActions({
            contents: [ TacoButton.component()()({
                onClick: new Data_Maybe.Just(closeModal),
                text: "Peruuta",
                testId: "cancel-multi-price-edit"
            }), TacoButton.component()()({
                onClick: new Data_Maybe.Just(saveAndCloseModal),
                text: "Tallenna",
                testId: "submit-multi-price-edit"
            }) ]
        });
        var modal = TacoModal.component()()({
            isActive: v.value0,
            onClose: closeModal,
            heading: "S\xe4\xe4d\xe4 hintoja",
            contents: [ modalBody, modalActions ]
        });
        var button = TacoButton.component()()({
            text: "S\xe4\xe4d\xe4 hintoja",
            onClick: Data_Maybe.Just.create(v.value1(function (v3) {
                return true;
            })),
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconEditV1.value),
            buttonStyle: TacoButton_Types.Filled.value,
            size: TacoConstants.L.value,
            testId: "edit-multiple-prices-button"
        });
        return React_Basic.fragment([ button, modal ]);
    };
});
module.exports = {
    editMultiplePricesButton: editMultiplePricesButton
};
