import { AdminUsers } from "./AdminUsers/AdminUsers";

/**
 * Admin UI
 */
export const Admin = () => {
  return (
    <>
      <AdminUsers />
    </>
  );
};
