import { Text } from "@tocoman/ui";
import { Trans } from "react-i18next";

type ErrorMessageProps = {
  errorMessage?: string;
  ns?: string;
  i18nKey?: string;
  values?: Record<string, unknown>;
  testId?: string;
};

export const ErrorMessage = ({
  errorMessage,
  ns,
  i18nKey,
  values,
  testId,
}: ErrorMessageProps) => {
  if (ns && i18nKey && values) {
    return (
      <div className={"text-red-500"} data-testid={testId}>
        <Trans ns={ns} i18nKey={i18nKey} values={values} />
      </div>
    );
  } else {
    return (
      <Text className={"text-red-500"} text={errorMessage} testId={testId} />
    );
  }
};
