// Generated by purs version 0.14.5
"use strict";
var Effect_Timer = require("../Effect.Timer/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var useHasMounted = function __do() {
    var v = TofuHooks.useState(false)();
    TofuHooks.useEffect([  ])(function __do() {
        var timeout = Effect_Timer.setTimeout(0)(v.value1(function (v1) {
            return true;
        }))();
        return Effect_Timer.clearTimeout(timeout);
    })();
    return v.value0;
};
module.exports = {
    useHasMounted: useHasMounted
};
