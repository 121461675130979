import { TextareaAutosize } from "@mui/material";
import { Button, Input, Modal } from "@tocoman/ui";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useCreateReportTemplate } from "./useReportTemplates";

type CreateReportTemplateFormProps = {
  name: string;
  template: string;
};

export default function CreateReportTemplateDialog() {
  const { t } = useTranslation("superAdmin", {
    keyPrefix: "reportDesigner.createTemplateDialog",
  });

  const methods = useForm<CreateReportTemplateFormProps>({
    defaultValues: {
      name: "",
      template: JSON.stringify({
        Name: "Report",
        Body: {
          ReportItems: [
            {
              Type: "textbox",
              Name: "TextBox1",
              Value: "Hello, ActiveReportsJS Designer",
              Style: {
                FontSize: "18pt",
              },
              Width: "8.5in",
              Height: "0.5in",
            },
          ],
        },
      }),
    },
  });

  const [open, setOpen] = useState(false);
  const { mutate: createReportTemplate } = useCreateReportTemplate();

  const handleSubmit = async (data: CreateReportTemplateFormProps) => {
    await createReportTemplate(data);
    methods.reset();
    setOpen(false);
  };

  return (
    <>
      <Button onClick={() => setOpen(true)}>{t("create")}</Button>
      <Modal
        isOpen={open}
        closeModal={() => setOpen(false)}
        title={t("create")}
        width={700}
        actions={
          <>
            <Button
              variant={"text"}
              onClick={() => setOpen(false) > setOpen(false)}
            >
              {t("cancel")}
            </Button>
            <Button type="submit" form="create-report-template-form">
              {t("save")}
            </Button>
          </>
        }
      >
        <FormProvider {...methods}>
          <form
            id="create-report-template-form"
            onSubmit={methods.handleSubmit(handleSubmit)}
            className="flex flex-col gap-2 w-full"
          >
            <Input label={t("name")} {...methods.register("name")} required />
            <label htmlFor="template">{t("template")}</label>
            <TextareaAutosize
              id="template"
              {...methods.register("template")}
              className="w-full border-light border rounded-md p-2"
            />
          </form>
        </FormProvider>
      </Modal>
    </>
  );
}
