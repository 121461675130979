// Generated by purs version 0.14.5
"use strict";
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Formatters = require("../Formatters/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_DOM_Internal = require("../React.Basic.DOM.Internal/index.js");
var Utils_ClassNames = require("../Utils.ClassNames/index.js");
var mkProgressBar = function (value) {
    return React_Basic_DOM_Generated.div()({
        className: Utils_ClassNames.mkClassName([ new Data_Maybe.Just("progress-bar-container"), Data_Monoid.guard(Data_Maybe.monoidMaybe(Data_Semigroup.semigroupString))(value >= 100.0)(new Data_Maybe.Just("complete")) ]),
        children: [ React_Basic_DOM_Generated.div()({
            className: "progress-bar-value",
            style: React_Basic_DOM_Internal.css({
                width: Data_Show.show(Data_Show.showNumber)(value) + "%"
            })
        }) ]
    });
};
var mkCompletionPercentageComponent = function (v) {
    return React_Basic_DOM_Generated.div()({
        className: "completion-percentage-bar",
        children: [ React_Basic_DOM_Generated.span()({
            className: "value",
            children: [ React_Basic_DOM.text(Formatters.formatDecimalValue(2)(v) + " %") ]
        }), mkProgressBar(v) ]
    });
};
module.exports = {
    mkProgressBar: mkProgressBar,
    mkCompletionPercentageComponent: mkCompletionPercentageComponent
};
