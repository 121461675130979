// Generated by purs version 0.14.5
"use strict";
var Components_Utils = require("../Components.Utils/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var Record = require("../Record/index.js");
var TacoCheckbox_Styles = require("../TacoCheckbox.Styles/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon = require("../TacoIcon/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Utils_ClassNames = require("../Utils.ClassNames/index.js");
var WithShift = (function () {
    function WithShift(value0) {
        this.value0 = value0;
    };
    WithShift.create = function (value0) {
        return new WithShift(value0);
    };
    return WithShift;
})();
var NoEvent = (function () {
    function NoEvent(value0) {
        this.value0 = value0;
    };
    NoEvent.create = function (value0) {
        return new NoEvent(value0);
    };
    return NoEvent;
})();
var NoEffect = (function () {
    function NoEffect() {

    };
    NoEffect.value = new NoEffect();
    return NoEffect;
})();
var Disabled = (function () {
    function Disabled(value0) {
        this.value0 = value0;
    };
    Disabled.create = function (value0) {
        return new Disabled(value0);
    };
    return Disabled;
})();
var StringLabel = (function () {
    function StringLabel(value0) {
        this.value0 = value0;
    };
    StringLabel.create = function (value0) {
        return new StringLabel(value0);
    };
    return StringLabel;
})();
var JSXLabel = (function () {
    function JSXLabel(value0) {
        this.value0 = value0;
    };
    JSXLabel.create = function (value0) {
        return new JSXLabel(value0);
    };
    return JSXLabel;
})();
var defaultProps = {
    className: "",
    isChecked: true,
    onToggle: NoEffect.value,
    labelText: Data_Maybe.Nothing.value,
    labelTextWeight: TacoText_Types.Normal.value,
    testId: "",
    color: TacoConstants.PrimaryBlue.value,
    noPadding: false,
    title: "",
    style: TacoCheckbox_Styles.Checkbox.value
};
var component = function (dictUnion) {
    return function (dictNub) {
        return TofuHooks.mkHookComponent("TacoCheckbox")(function (props$prime) {
            var v = Record.merge()()(props$prime)(defaultProps);
            var isDisabled = (function () {
                if (v.onToggle instanceof Disabled) {
                    return true;
                };
                return false;
            })();
            var checkedIcon = (function () {
                if (v.style instanceof TacoCheckbox_Styles.Checkbox) {
                    return TacoIcon.component()()()()({
                        icon: TacoIcon_Icons_Types.IconCheckboxSelectedV1.value,
                        inheritColor: true,
                        size: TacoConstants.XS.value
                    });
                };
                if (v.style instanceof TacoCheckbox_Styles.RadioButton) {
                    return TacoIcon.component()()()()({
                        icon: TacoIcon_Icons_Types.IconDot.value,
                        color: TacoConstants.White.value,
                        size: TacoConstants.XS.value
                    });
                };
                throw new Error("Failed pattern match at TacoCheckbox (line 83, column 19 - line 85, column 82): " + [ v.style.constructor.name ]);
            })();
            return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                className: Utils_ClassNames.mkClassName([ new Data_Maybe.Just("__TacoCheckbox"), new Data_Maybe.Just(v.className), Data_Monoid.guard(Data_Maybe.monoidMaybe(Data_Semigroup.semigroupString))(isDisabled)(new Data_Maybe.Just("disabled")), Data_Monoid.guard(Data_Maybe.monoidMaybe(Data_Semigroup.semigroupString))(Data_Maybe.isJust(v.labelText))(new Data_Maybe.Just("has-label")) ]),
                css: TacoCheckbox_Styles.checkboxContainerStyles({
                    color: v.color,
                    isChecked: v.isChecked,
                    isDisabled: isDisabled,
                    noPadding: v.noPadding,
                    style: v.style
                }),
                title: (function () {
                    if (v.onToggle instanceof Disabled) {
                        return v.onToggle.value0;
                    };
                    return "";
                })(),
                onClick: (function () {
                    if (v.onToggle instanceof WithShift) {
                        return React_Basic_Events.handler(Control_Semigroupoid.composeFlipped(React_Basic_Events.semigroupoidBuilder)(React_Basic_DOM_Events.stopPropagation)(React_Basic_DOM_Events.shiftKey))(v.onToggle.value0);
                    };
                    if (v.onToggle instanceof NoEvent) {
                        return React_Basic_Events.handler(React_Basic_DOM_Events.stopPropagation)(function (v1) {
                            return v.onToggle.value0;
                        });
                    };
                    if (v.onToggle instanceof NoEffect) {
                        return React_Basic_Events.handler(React_Basic_DOM_Events.preventDefault)(Control_Applicative.pure(Control_Applicative.applicativeFn)(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit)));
                    };
                    if (v.onToggle instanceof Disabled) {
                        return React_Basic_Events.handler(React_Basic_DOM_Events.preventDefault)(Control_Applicative.pure(Control_Applicative.applicativeFn)(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit)));
                    };
                    throw new Error("Failed pattern match at TacoCheckbox (line 99, column 7 - line 103, column 74): " + [ v.onToggle.constructor.name ]);
                })(),
                children: [ React_Basic_Emotion.element(React_Basic_DOM_Generated["span'"]())({
                    className: "checkbox",
                    css: TacoCheckbox_Styles.checkboxStyles({
                        isChecked: v.isChecked,
                        style: v.style
                    }),
                    title: v.title,
                    children: Data_Monoid.guard(Data_Monoid.monoidArray)(v.isChecked)(Control_Applicative.pure(Control_Applicative.applicativeArray)(checkedIcon))
                }), (function () {
                    if (v.labelText instanceof Data_Maybe.Nothing) {
                        return React_Basic.empty;
                    };
                    if (v.labelText instanceof Data_Maybe.Just) {
                        return React_Basic_Emotion.element(React_Basic_DOM_Generated["label'"]())({
                            className: "label",
                            css: TacoCheckbox_Styles.labelStyles({
                                isDisabled: isDisabled,
                                style: v.style
                            }),
                            title: v.title,
                            children: [ (function () {
                                if (v.labelText.value0 instanceof StringLabel) {
                                    return React_Basic_DOM_Generated.span()({
                                        className: "checkbox-label-text",
                                        children: [ TacoText.component()()({
                                            text: v.labelText.value0.value0,
                                            weight: v.labelTextWeight,
                                            inheritColor: true
                                        }) ]
                                    });
                                };
                                if (v.labelText.value0 instanceof JSXLabel) {
                                    return v.labelText.value0.value0;
                                };
                                throw new Error("Failed pattern match at TacoCheckbox (line 119, column 19 - line 130, column 40): " + [ v.labelText.value0.constructor.name ]);
                            })() ]
                        });
                    };
                    throw new Error("Failed pattern match at TacoCheckbox (line 111, column 9 - line 132, column 16): " + [ v.labelText.constructor.name ]);
                })() ],
                "_data": Components_Utils.mkTestIdAttrs(v.testId)
            }));
        });
    };
};
module.exports = {
    component: component,
    StringLabel: StringLabel,
    JSXLabel: JSXLabel,
    WithShift: WithShift,
    NoEvent: NoEvent,
    NoEffect: NoEffect,
    Disabled: Disabled
};
