// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Client_WASM = require("../Client.WASM/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var CostEstimationTableWrapper = require("../CostEstimationTableWrapper/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var DixonMeasurementGroups_Styles = require("../DixonMeasurementGroups.Styles/index.js");
var DixonMeasurements_Types = require("../DixonMeasurements.Types/index.js");
var Effect = require("../Effect/index.js");
var Effect_Exception = require("../Effect.Exception/index.js");
var Formatters = require("../Formatters/index.js");
var HtmlUtils = require("../HtmlUtils/index.js");
var QuantityFormula_Interpreter = require("../QuantityFormula.Interpreter/index.js");
var QuantityFormula_Parser = require("../QuantityFormula.Parser/index.js");
var QuantityFormula_Types = require("../QuantityFormula.Types/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var StringUtils = require("../StringUtils/index.js");
var TacoAutocomplete = require("../TacoAutocomplete/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoDropdown = require("../TacoDropdown/index.js");
var TacoDropdown_Types = require("../TacoDropdown.Types/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var TutorialContainer = require("../TutorialContainer/index.js");
var Types_BuildingElementsView = require("../Types.BuildingElementsView/index.js");
var Types_ClassificationGroup = require("../Types.ClassificationGroup/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_Element = require("../Types.Element/index.js");
var Types_JSDateString = require("../Types.JSDateString/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Project = require("../Types.Project/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var UseLicenseFeature = require("../UseLicenseFeature/index.js");
var UseWorkerData = require("../UseWorkerData/index.js");
var Wasm_FFI = require("../Wasm.FFI/index.js");
var WasmWorker_WorkerRequest = require("../WasmWorker.WorkerRequest/index.js");
var ByCode = (function () {
    function ByCode() {

    };
    ByCode.value = new ByCode();
    return ByCode;
})();
var ByName = (function () {
    function ByName() {

    };
    ByName.value = new ByName();
    return ByName;
})();
var styleSet = TacoTable_Styles.foldingTableStyleSet;
var getSortingValue = function (v) {
    return function (r) {
        if (v instanceof ByCode) {
            return TacoTable_Types.StringValue.create(StringUtils.rightPad(40)(" ")(r.measurement_group.code) + r.measurement_group.name);
        };
        if (v instanceof ByName) {
            return TacoTable_Types.StringValue.create(r.measurement_group.name);
        };
        throw new Error("Failed pattern match at DixonMeasurementGroups (line 601, column 1 - line 601, column 126): " + [ v.constructor.name, r.constructor.name ]);
    };
};
var getNameDixonMeasurementGroupsSortProps = {
    getName: function (v) {
        return "DixonMeasurementGroupsSortProps";
    }
};
var measurementsTableInstance = TacoTable.mkTableStateless(getNameDixonMeasurementGroupsSortProps)("MeasurementsTableInstance");
var eqDixonMeasurementGroupsSortProps = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ByCode && y instanceof ByCode) {
                return true;
            };
            if (x instanceof ByName && y instanceof ByName) {
                return true;
            };
            return false;
        };
    }
};
var colorInput = TofuHooks.mkHookComponent("DixonMGColorInput")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState(false)();
        var v2 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var applyColor = function (color) {
            return function __do() {
                var mEditor = React_Basic_Hooks.readRef(v.props.editorRef)();
                return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(mEditor)(function (editor) {
                    return function __do() {
                        editor.set_measurement_group_color(v.item.measurement_group.id, color);
                        return v1.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))();
                    };
                })();
            };
        };
        var mkColorSwatch = function (color) {
            return React_Basic_Emotion.element(React_Basic_DOM_Generated["span'"]())({
                css: DixonMeasurementGroups_Styles.colorSwatchStyles({
                    color: color
                }),
                className: "CurrentColorSwatch",
                children: [ TacoButton.component()()({
                    onClick: Data_Maybe.Just.create(applyColor(color))
                }) ]
            });
        };
        var colors = Box.box("ColorSwatches")([ new Box.Gap(TacoConstants.M.value), Box.FlexRow.value ])([ mkColorSwatch("hsl(231, 62%, 43%)"), mkColorSwatch("hsl(207, 90%, 54%)"), mkColorSwatch("hsl(291, 64%, 52%)"), mkColorSwatch("hsl(339, 100%, 48%)"), mkColorSwatch("hsl(4, 90%, 58%)"), mkColorSwatch("hsl(36, 100%, 50%)"), mkColorSwatch("hsl(54, 100%, 62%)"), mkColorSwatch("hsl(122, 39%, 49%)"), mkColorSwatch("hsl(93, 100%, 51%)"), mkColorSwatch("hsl(0, 0%, 40%)"), mkColorSwatch("hsl(0, 0%, 62%)") ]);
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["span'"]())({
            css: DixonMeasurementGroups_Styles.dixonMeasurementGroupColorContainerStyles,
            className: "ColorInput",
            children: [ TacoDropdown.component({
                sections: [ {
                    title: new Data_Maybe.Just("Aseta mittausryhm\xe4n v\xe4ri"),
                    items: [ colors ]
                } ],
                toggled: v1.value0,
                setToggled: v1.value1,
                anchorEl: v2.value0,
                align: TacoDropdown_Types.AlignLeft.value
            }), React_Basic_Emotion.element(React_Basic_DOM_Generated["span'"]())({
                css: DixonMeasurementGroups_Styles.colorSwatchStyles({
                    color: v.item.measurement_group.color
                }),
                className: "CurrentColorSwatch",
                children: [ TacoButton.component()()({
                    onClickEventHandler: Data_Maybe.Just.create(React_Basic_Events.handler(React_Basic_DOM_Events.currentTarget)(function (target) {
                        return function __do() {
                            v1.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))();
                            return v2.value1(function (v3) {
                                return Data_Maybe.Just.create(HtmlUtils.coerceEventTargetToHTMLElement(target));
                            })();
                        };
                    })),
                    disabled: Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqBoolean))(v.props.maybeLockedProject)(new Data_Maybe.Just(true))
                }) ]
            }) ]
        });
    };
});
var getColumns = function (locationsEnabled) {
    return function (props) {
        var unitInput = function (v) {
            return TacoInput.stringInput()()(Data_Eq.eqString)({
                onSave: props.setValue(v.item.measurement_group)("unit"),
                value: v.item.measurement_group.unit,
                disabled: Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqBoolean))(props.maybeLockedProject)(new Data_Maybe.Just(true))
            });
        };
        var sum = function (v) {
            return React_Basic_DOM.text(Formatters.formatDecimalValue(3)(v.item.measurement_group.formula_sum));
        };
        var showMeasurements = function (v) {
            return TacoButton.component()()({
                icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconArrowRight.value),
                color: TacoConstants.Gray.value,
                onClick: new Data_Maybe.Just(function __do() {
                    var mEditor = React_Basic_Hooks.readRef(props.editorRef)();
                    return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(mEditor)(function (editor) {
                        return function __do() {
                            editor.set_active_measurement_group(v.item.measurement_group.id);
                            return props.setActiveMeasurementsTabId(function (v1) {
                                return new Data_Maybe.Just("measurements");
                            })();
                        };
                    })();
                })
            });
        };
        var selectAllCheckbox = TacoCheckbox.component()()({
            className: "select-all-checkbox",
            isChecked: Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Array["null"])(props.selectedItemIds),
            onToggle: TacoCheckbox.WithShift.create(function (v) {
                return function __do() {
                    var mEditor = React_Basic_Hooks.readRef(props.editorRef)();
                    return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(mEditor)(function (editor) {
                        return function () {
                            return editor.toggle_all_measurement_groups(Data_Unit.unit);
                        };
                    })();
                };
            }),
            testId: "select-all-checkbox"
        });
        var perimeter = function (v) {
            return React_Basic_DOM.text(Formatters.formatDecimalValue(3)(v.item.measurement_group.perimeter) + " m");
        };
        var numVertices = function (v) {
            return React_Basic_DOM.text(Formatters.formatDecimalValue(0)(v.item.measurement_group.num_vertices) + " kpl");
        };
        var nameInput = function (v) {
            return TacoInput.stringInput()()(Data_Eq.eqString)({
                onSave: props.setValue(v.item.measurement_group)("name"),
                value: v.item.measurement_group.name,
                disabled: Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqBoolean))(props.maybeLockedProject)(new Data_Maybe.Just(true))
            });
        };
        var mkAutocompleteItem = function (location) {
            return {
                text: location,
                id: location
            };
        };
        var locationSelectChoices = Data_Functor.mapFlipped(Data_Functor.functorArray)(props.projectLocations)(function (v) {
            return {
                text: v.code,
                id: v.code
            };
        });
        var locationCodeSelect = function (v) {
            return TacoAutocomplete.component()()({
                sections: [ {
                    text: Data_Maybe.Nothing.value,
                    list: locationSelectChoices
                } ],
                clearButton: false,
                placeholder: "",
                onSelect: function (item$prime$prime) {
                    if (item$prime$prime instanceof Data_Maybe.Just) {
                        return props.setValue(v.item.measurement_group)("default_location")(item$prime$prime.value0.id);
                    };
                    if (item$prime$prime instanceof Data_Maybe.Nothing) {
                        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                    };
                    throw new Error("Failed pattern match at DixonMeasurementGroups (line 659, column 11 - line 662, column 33): " + [ item$prime$prime.constructor.name ]);
                },
                selected: Data_Maybe.Just.create(mkAutocompleteItem(v.item.measurement_group.default_location)),
                testId: "location-code-select",
                disabled: Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqBoolean))(props.maybeLockedProject)(new Data_Maybe.Just(true))
            });
        };
        var defaultFormulaValidator = function (formula) {
            var validatorContext = Wasm_FFI.dixonFormulaContext({
                numVertices: 0.0,
                perimeter: 0.0,
                area: 0.0,
                formula: formula
            });
            var parsedFormula = QuantityFormula_Parser.convertToInternalFormula(formula);
            var v = QuantityFormula_Interpreter.evalQuantityFormula(validatorContext)(parsedFormula);
            if (v instanceof Data_Either.Right && v.value0 instanceof QuantityFormula_Types.QNum) {
                return TacoInput.Valid.value;
            };
            if (v instanceof Data_Either.Right) {
                return new TacoInput.Invalid("");
            };
            if (v instanceof Data_Either.Left) {
                return new TacoInput.Invalid("");
            };
            throw new Error("Failed pattern match at DixonMeasurementGroups (line 687, column 10 - line 691, column 29): " + [ v.constructor.name ]);
        };
        var formulaInput = function (v) {
            return TacoInput.stringInput()()(Data_Eq.eqString)({
                onSave: props.setValue(v.item.measurement_group)("default_formula"),
                value: Data_Newtype.un()(QuantityFormula_Types.UserFormula)(QuantityFormula_Parser.convertToUserFormula(v.item.measurement_group.default_formula)),
                align: TacoInput.RightAlign.value,
                validator: function (formula) {
                    var $113 = formula === "" || Data_Eq.eq(TacoInput.eqValueValidity)(defaultFormulaValidator(formula))(TacoInput.Valid.value);
                    if ($113) {
                        return TacoInput.Valid.value;
                    };
                    return new TacoInput.Invalid("Virheellinen kaava");
                },
                disabled: Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqBoolean))(props.maybeLockedProject)(new Data_Maybe.Just(true))
            });
        };
        var colorInput$prime = function (v) {
            return colorInput({
                item: v.item,
                props: props
            });
        };
        var codeInput = function (v) {
            return TacoInput.stringInput()()(Data_Eq.eqString)({
                onSave: props.setValue(v.item.measurement_group)("code"),
                value: v.item.measurement_group.code,
                disabled: Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqBoolean))(props.maybeLockedProject)(new Data_Maybe.Just(true))
            });
        };
        var checkbox = function (v) {
            return TacoCheckbox.component()()({
                className: "item-select-checkbox",
                isChecked: v.checked,
                onToggle: TacoCheckbox.WithShift.create(function (v1) {
                    return function __do() {
                        var mEditor = React_Basic_Hooks.readRef(props.editorRef)();
                        return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(mEditor)(function (editor) {
                            return function () {
                                return editor.toggle_measurement_group(v.item.measurement_group.id);
                            };
                        })();
                    };
                }),
                labelText: Data_Maybe.Nothing.value,
                testId: "item-select-checkbox" + Data_Show.show(Data_Show.showInt)(v.item.measurement_group.id)
            });
        };
        var area = function (v) {
            return React_Basic_DOM.text(Formatters.formatDecimalValue(2)(v.item.measurement_group.area) + " m\xb2");
        };
        return Data_Semigroup.append(Data_Semigroup.semigroupArray)([ {
            key: "show-measurements",
            label: React_Basic_DOM.text(""),
            width: new TacoTable_Types.Fixed("36px"),
            sortProperty: Data_Maybe.Nothing.value,
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], showMeasurements),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "checkbox",
            label: React_Basic_DOM.text(""),
            width: new TacoTable_Types.Fixed("26px"),
            sortProperty: Data_Maybe.Nothing.value,
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], checkbox),
            headerJSX: new Data_Maybe.Just(selectAllCheckbox)
        } ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_Monoid.guard(Data_Monoid.monoidArray)(locationsEnabled)([ {
            key: "location",
            label: React_Basic_DOM.text("Oletussijainti"),
            width: new TacoTable_Types.FlexMinWidth(1, "80px"),
            sortProperty: Data_Maybe.Nothing.value,
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], locationCodeSelect),
            headerJSX: Data_Maybe.Nothing.value
        } ]))([ {
            key: "code",
            label: React_Basic_DOM.text("Luokka"),
            width: new TacoTable_Types.FlexMinWidth(1, "80px"),
            sortProperty: new Data_Maybe.Just(ByCode.value),
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], codeInput),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "name",
            label: React_Basic_DOM.text("Nimi"),
            width: new TacoTable_Types.FlexMinWidth(5, "140px"),
            sortProperty: new Data_Maybe.Just(ByName.value),
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], nameInput),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "color",
            label: React_Basic_DOM.text("V\xe4ri"),
            width: new TacoTable_Types.Fixed("36px"),
            sortProperty: Data_Maybe.Nothing.value,
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], colorInput$prime),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "num_vertices",
            label: React_Basic_DOM.text("Kpl (N)"),
            width: new TacoTable_Types.FlexMinWidth(1, "40px"),
            sortProperty: Data_Maybe.Nothing.value,
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value ], numVertices),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "perimeter",
            label: React_Basic_DOM.text("Pituus / Piiri (P)"),
            width: new TacoTable_Types.FlexMinWidth(2, "80px"),
            sortProperty: Data_Maybe.Nothing.value,
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value ], perimeter),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "area",
            label: React_Basic_DOM.text("Pinta-ala (A)"),
            width: new TacoTable_Types.FlexMinWidth(2, "60px"),
            sortProperty: Data_Maybe.Nothing.value,
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value ], area),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "formula",
            label: React_Basic_DOM.text("Oletuskaava (\u0192)"),
            width: new TacoTable_Types.FlexMinWidth(1, "100px"),
            sortProperty: Data_Maybe.Nothing.value,
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value, TacoTable_Types.Numeric.value ], formulaInput),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "sum",
            label: React_Basic_DOM.text("Summa (\u0192)"),
            width: new TacoTable_Types.FlexMinWidth(2, "80px"),
            sortProperty: Data_Maybe.Nothing.value,
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value ], sum),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "unit",
            label: React_Basic_DOM.text("Yksikk\xf6"),
            width: new TacoTable_Types.FlexMinWidth(1, "80px"),
            sortProperty: Data_Maybe.Nothing.value,
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], unitInput),
            headerJSX: Data_Maybe.Nothing.value
        } ]));
    };
};
var mkRow = function (locationsEnabled) {
    return function (props) {
        return function (v) {
            return {
                className: (function () {
                    var $127 = Data_Array["null"](Data_Array.intersect(Data_Eq.eqInt)(v.rowData.item.measurement_group.drawing_ids)(props.activeDrawingIds));
                    if ($127) {
                        return v.className;
                    };
                    return v.className + "-active";
                })(),
                rowData: v.rowData,
                foldingElement: Data_Maybe.Nothing.value,
                onClick: function (v1) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                },
                rowKey: v.rowKey,
                columns: getColumns(locationsEnabled)(props)
            };
        };
    };
};
var getRow = function (locationsEnabled) {
    return function (props) {
        return function (r) {
            return mkRow(locationsEnabled)(props)({
                className: "DixonMeasurementGroupsTableRow",
                rowData: r,
                rowKey: Data_Show.show(Data_Show.showInt)(r.item.measurement_group.id)
            });
        };
    };
};
var component = TofuHooks.mkHookComponent("DixonMeasurementGroups")(function (v) {
    return function __do() {
        var v1 = Client_WASM.useComponentWithResources(v.projectId)();
        var v2 = UseWorkerData.useWorkerItems(function (v2) {
            return v2.workerFilteredDixonMeasurementGroups;
        })();
        var v3 = UseWorkerData.useWorkerItems(function (v3) {
            return v3.workerDixonMeasurementGroups;
        })();
        TofuHooks.useEffect([ v2.value1, v3.value1 ])(function __do() {
            v.setLoading(function (v4) {
                return v2.value1 || v3.value1;
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        })();
        var v4 = TofuHooks.useState(false)();
        Client_WASM.useSetWorkerState(WasmWorker_WorkerRequest.DixonMeasurementFollowFilteringState.create)(new Data_Maybe.Just(v4.value0))();
        var v5 = Client_WASM.useElementsData(v.projectId)();
        var v6 = Client_WASM.useElementSpecs(v.projectId)();
        var v7 = TofuHooks.useState(false)();
        var v8 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var v9 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var v10 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var v11 = TofuHooks.useState("component")();
        var v12 = TofuHooks.useState(false)();
        var v13 = TofuHooks.useState(ByCode.value)();
        var v14 = TofuHooks.useState(TacoTable_Types.Ascending.value)();
        var locationsEnabled = UseLicenseFeature.useLicenseFeature(UseLicenseFeature.Locations.value)();
        var getElementSpecById = function (specId) {
            var mElementSpec = Data_Array.find(function (v15) {
                return Data_Eq.eq(Types_Newtypes.eqElementSpecId)(v15.elementSpecId)(specId);
            })(v6.value0);
            if (mElementSpec instanceof Data_Maybe.Just) {
                return Control_Applicative.pure(Effect.applicativeEffect)(mElementSpec.value0);
            };
            return Control_Monad_Error_Class.throwError(Control_Monad_Error_Class.monadThrowEffect)(Effect_Exception.error("Unable to find matching ElementSpec with id " + Data_Show.show(Types_Newtypes.showElementSpecId)(specId)));
        };
        var v15 = TofuHooks.useState(false)();
        var v16 = Client_WASM.useProjectLocations(v.projectId)();
        var openDeleteModal = v7.value1(function (v17) {
            return true;
        });
        var numMGroups = Data_Array.length(v2.value0);
        var closeLinkTypeModal = v12.value1(function (v17) {
            return false;
        });
        var closeDeleteModal = v7.value1(function (v17) {
            return false;
        });
        var activeItems = Data_Array.filter(function (v17) {
            return v17.active;
        })(v2.value0);
        var selectedItemIds = Data_Functor.map(Data_Functor.functorArray)(function (v17) {
            return v17.id;
        })(activeItems);
        var activeDrawingIds = Data_Functor.map(Data_Functor.functorArray)(function (v17) {
            return v17.id;
        })(Data_Array.filter(function (v17) {
            return v17.active;
        })(Data_Array.concatMap(function (v17) {
            return v17.drawings;
        })(Data_Array.filter(function (v17) {
            return v17.active;
        })(v.editorState.files))));
        var withOrder = (function () {
            var $151 = Data_Eq.eq(TacoTable_Types.eqSortingOrder)(v14.value0)(TacoTable_Types.Descending.value);
            if ($151) {
                return Data_Function.flip;
            };
            return Control_Category.identity(Control_Category.categoryFn);
        })();
        var sortableValue = function (x) {
            return getSortingValue(v13.value0)(x);
        };
        var setValue = function (measurementGroup) {
            return function (id) {
                return function (value) {
                    return function __do() {
                        var mEditor = React_Basic_Hooks.readRef(v.editorRef)();
                        return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(mEditor)(function (editor$prime) {
                            if (id === "name") {
                                return function () {
                                    return editor$prime.set_measurement_group_name(measurementGroup.id, value);
                                };
                            };
                            if (id === "code") {
                                return function () {
                                    return editor$prime.set_measurement_group_code(measurementGroup.id, value);
                                };
                            };
                            if (id === "unit") {
                                return function () {
                                    return editor$prime.set_measurement_group_unit(measurementGroup.id, value);
                                };
                            };
                            if (id === "default_location") {
                                return function () {
                                    return editor$prime.set_measurement_group_defaults(measurementGroup.id, value, measurementGroup.default_formula);
                                };
                            };
                            if (id === "default_formula") {
                                var parsedFormula = QuantityFormula_Parser.convertToInternalFormula(value);
                                var formula = Data_Newtype.un()(QuantityFormula_Types.InternalFormula)(parsedFormula);
                                return function () {
                                    return editor$prime.set_measurement_group_defaults(measurementGroup.id, measurementGroup.default_location, formula);
                                };
                            };
                            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                        })();
                    };
                };
            };
        };
        var setNumberValue = function (v17) {
            return function (v18) {
                return function (v19) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                };
            };
        };
        var tableProps = {
            selectedItemIds: selectedItemIds,
            setValue: setValue,
            setNumberValue: setNumberValue,
            editorRef: v.editorRef,
            activeDrawingIds: activeDrawingIds,
            setActiveDropdownMGroupId: v8.value1,
            setActiveModalMGroupId: v9.value1,
            anchorEl: v10.value0,
            setAnchorEl: v10.value1,
            projectId: v.projectId,
            setActiveMeasurementsTabId: v.setActiveMeasurementsTabId,
            projectLocations: v16.value0,
            maybeLockedProject: v.maybeLockedProject
        };
        var setDixonEditorMode = function (mode) {
            return function () {
                return v.editor.set_mode(TofuJSON.write(DixonMeasurements_Types.tofuJSONDixonEditorMode)(mode));
            };
        };
        var reCalc = function () {
            return v.editor.re_calc_all_measurements(Data_Unit.unit);
        };
        var reCalculateAllMeasurements = React_Basic.fragment([ TacoButton.component()()({
            text: "P\xe4ivit\xe4 mittaukset laskentaan",
            buttonStyle: TacoButton_Types.Filled.value,
            color: TacoConstants.componentColors.border,
            onClick: new Data_Maybe.Just(reCalc),
            size: TacoConstants.L.value,
            disabled: Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqBoolean))(v.maybeLockedProject)(new Data_Maybe.Just(true))
        }) ]);
        var newMeasurementGroup = function __do() {
            v.editor.new_measurement_group(Data_Unit.unit);
            return Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(numMGroups === 0 && Data_Eq.eq(DixonMeasurements_Types.eqDixonEditorMode)(v.editorState.mode)(DixonMeasurements_Types.SetScaleMode.value))(setDixonEditorMode(DixonMeasurements_Types.LineMode.value))();
        };
        var newMeasurementGroupButton = React_Basic.fragment([ TacoButton.component()()({
            text: "Uusi mittausryhm\xe4",
            buttonStyle: TacoButton_Types.Filled.value,
            color: TacoConstants.componentColors.border,
            onClick: new Data_Maybe.Just(newMeasurementGroup),
            size: TacoConstants.L.value,
            disabled: Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqBoolean))(v.maybeLockedProject)(new Data_Maybe.Just(true))
        }) ]);
        var newMGroupTutorialActive = numMGroups === 0 && (!v.showUploadFileHint && (!v.showSelectFileHint && (!v.showSetScaleHint && Data_Array["null"](v.copiedMeasurements))));
        var measurementGroupItems = Data_Functor.map(Data_Functor.functorArray)(function (measurement_group) {
            return {
                measurement_group: measurement_group
            };
        })(v2.value0);
        var sortedMeasurementGroups = Data_Array.sortBy(function (a) {
            return function (b) {
                return withOrder(Data_Ord.compare(TacoTable_Types.ordComparableValue))(sortableValue(a))(sortableValue(b));
            };
        })(measurementGroupItems);
        var linkTypeModalActions = [ TacoButton.component()()({
            onClick: Data_Maybe.Just.create(closeLinkTypeModal),
            text: "Peruuta"
        }) ];
        var linkTypeModal = TacoModal.component()()({
            onClose: closeLinkTypeModal,
            isActive: v12.value0,
            heading: "Valitse linkityksen kohde",
            contents: [ TacoModal.modalBody({
                contents: [ TacoButton.component()()({
                    text: "Suorite",
                    buttonStyle: TacoButton_Types.Filled.value,
                    color: TacoConstants.componentColors.border,
                    onClick: new Data_Maybe.Just(function __do() {
                        v11.value1(function (v17) {
                            return "component";
                        })();
                        return closeLinkTypeModal();
                    }),
                    icon: Data_Maybe.Nothing.value,
                    size: TacoConstants.L.value
                }), TacoText.component()()({
                    text: "M\xe4\xe4r\xe4 linkitet\xe4\xe4n suoritteelle. Linkitys tapahtuu kuten suoritelistassa, rakennetta ei oteta huomioon ja linkitettyjen m\xe4\xe4rien sijainnit n\xe4kyv\xe4t suoritteella."
                }), TacoButton.component()()({
                    text: "Rakenteen suoritteen menekki",
                    buttonStyle: TacoButton_Types.Filled.value,
                    color: TacoConstants.elementColors.border,
                    onClick: new Data_Maybe.Just(function __do() {
                        v11.value1(function (v17) {
                            return "element_spec_consumption_rate";
                        })();
                        return closeLinkTypeModal();
                    }),
                    icon: Data_Maybe.Nothing.value,
                    size: TacoConstants.L.value
                }), TacoText.component()()({
                    text: "Mitattu m\xe4\xe4r\xe4 linkitet\xe4\xe4n rakenteen suoritteen Menekki -kentt\xe4\xe4n. Mitattu m\xe4\xe4r\xe4 kerrotaan rakenteen m\xe4\xe4r\xe4ll\xe4. Rakenteelle annetut m\xe4\xe4r\xe4t ja sijainnit m\xe4\xe4ritt\xe4v\xe4t mihin sijaintiin suoritteen m\xe4\xe4r\xe4t kohdistuvat."
                }), TacoButton.component()()({
                    text: "Rakenteen suoritteen m\xe4\xe4r\xe4",
                    buttonStyle: TacoButton_Types.Filled.value,
                    color: TacoConstants.elementColors.border,
                    onClick: new Data_Maybe.Just(function __do() {
                        v11.value1(function (v17) {
                            return "element_spec_quantity";
                        })();
                        return closeLinkTypeModal();
                    }),
                    icon: Data_Maybe.Nothing.value,
                    size: TacoConstants.L.value
                }), TacoText.component()()({
                    text: "Mitattu m\xe4\xe4r\xe4 linkitet\xe4\xe4n rakenteen suoritteelle M\xe4\xe4r\xe4 -kentt\xe4\xe4n. M\xe4\xe4r\xe4 pysyy vakiona rakenteen m\xe4\xe4r\xe4st\xe4 riippumatta. Suoritteen m\xe4\xe4r\xe4 jakautuu sijainteihin Rakenteelle annettujen m\xe4\xe4rien ja sijaintien suhteessa."
                }) ]
            }), TacoModal.modalActions({
                contents: linkTypeModalActions
            }) ]
        });
        var linkTypeBtn = React_Basic.fragment([ TacoButton.component()()({
            text: "Kohde: " + (function () {
                var $153 = v11.value0 === "component";
                if ($153) {
                    return "Suorite";
                };
                var $154 = v11.value0 === "element_spec_quantity";
                if ($154) {
                    return "Rakenteen suoritteen m\xe4\xe4r\xe4";
                };
                return "Rakenteen suoritteen menekki";
            })(),
            buttonStyle: TacoButton_Types.Filled.value,
            color: TacoConstants.componentColors.border,
            onClick: Data_Maybe.Just.create(v12.value1(function (v17) {
                return true;
            })),
            icon: Data_Maybe.Nothing.value,
            size: TacoConstants.L.value,
            disabled: Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqBoolean))(v.maybeLockedProject)(new Data_Maybe.Just(true))
        }) ]);
        var linkOtherQuantityBtn = React_Basic.fragment([ TacoButton.component()()({
            text: "Linkit\xe4 muu m\xe4\xe4r\xe4",
            buttonStyle: TacoButton_Types.Filled.value,
            color: TacoConstants.componentColors.border,
            onClick: Data_Maybe.Just.create(v15.value1(function (v17) {
                return true;
            })),
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconArrowRight.value),
            size: TacoConstants.L.value,
            disabled: Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqBoolean))(v.maybeLockedProject)(new Data_Maybe.Just(true))
        }) ]);
        var linkMeasurement = function (fromType) {
            if (v.activeLibraryTabId instanceof Data_Maybe.Just && v.activeLibraryTabId.value0 === "elements") {
                var $156 = Data_Array["null"](v.checkedSpecs);
                if ($156) {
                    return function __do() {
                        v.editor.link_measurement_groups_to_element(v.checkedElements, fromType);
                        Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(fromType !== "DeleteLink")(function () {
                            return v.editor.toggle_all_measurement_groups(Data_Unit.unit);
                        })();
                        return v.setCheckedElements(function (v17) {
                            return Data_Set.empty;
                        })();
                    };
                };
                return function __do() {
                    var matchingElementSpecs = Data_Traversable.traverse(Data_Traversable.traversableArray)(Effect.applicativeEffect)(getElementSpecById)(v.checkedSpecs)();
                    var json = TofuJSON.write(TofuJSON.readArray(Types_Element.tofuJSONWasmElementSpec))(matchingElementSpecs);
                    v.editor.link_measurement_groups_to_element_spec(json, fromType, v11.value0);
                    Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(fromType !== "DeleteLink")(function () {
                        return v.editor.toggle_all_measurement_groups(Data_Unit.unit);
                    })();
                    return v.setCheckedSpecs(function (v17) {
                        return Data_Set.empty;
                    })();
                };
            };
            if (v.activeLibraryTabId instanceof Data_Maybe.Just && v.activeLibraryTabId.value0 === "components") {
                return function __do() {
                    v.editor.link_measurement_groups_to_component(v.checkedComponents, fromType);
                    Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(fromType !== "DeleteLink")(function () {
                        return v.editor.toggle_all_measurement_groups(Data_Unit.unit);
                    })();
                    return v.setCheckedComponents(function (v17) {
                        return Data_Set.empty;
                    })();
                };
            };
            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
        };
        var linkMeasurementButton = React_Basic.fragment([ TacoButton.component()()({
            text: "Linkit\xe4 summa",
            buttonStyle: TacoButton_Types.Filled.value,
            color: TacoConstants.componentColors.border,
            onClick: Data_Maybe.Just.create(linkMeasurement("Formula")),
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconArrowRight.value),
            size: TacoConstants.L.value,
            disabled: Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqBoolean))(v.maybeLockedProject)(new Data_Maybe.Just(true))
        }) ]);
        var linkButtonsVisible = !Data_Array["null"](selectedItemIds) && (Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(v.activeLibraryTabId)(new Data_Maybe.Just("components")) && !Data_Array["null"](v.checkedComponents) || Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(v.activeLibraryTabId)(new Data_Maybe.Just("elements")) && (!Data_Array["null"](v.checkedElements) || !Data_Array["null"](v.checkedSpecs)));
        var deleteMeasurementGroupsButton = TacoButton.component()()({
            text: "Poista valitut mittausryhm\xe4t",
            buttonStyle: TacoButton_Types.Filled.value,
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconDelete24Px.value),
            color: TacoConstants.componentColors.border,
            onClick: new Data_Maybe.Just(openDeleteModal),
            size: TacoConstants.L.value,
            disabled: Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqBoolean))(v.maybeLockedProject)(new Data_Maybe.Just(true))
        });
        var deleteMeasurementGroups = function () {
            return v.editor.delete_measurement_groups(selectedItemIds);
        };
        var deleteModalActions = [ TacoButton.component()()({
            onClick: Data_Maybe.Just.create(closeDeleteModal),
            text: "Peruuta"
        }), TacoButton.component()()({
            onClick: Data_Maybe.Just.create(function __do() {
                deleteMeasurementGroups();
                return closeDeleteModal();
            }),
            buttonStyle: TacoButton_Types.Filled.value,
            color: TacoConstants.SecondaryRed.value,
            text: "Poista"
        }) ];
        var deleteMeasurementGroupsModal = TacoModal.component()()({
            onClose: closeDeleteModal,
            isActive: v7.value0,
            heading: "Poista valitut mittausryhm\xe4t",
            contents: [ TacoModal.modalBody({
                contents: [ React_Basic_DOM_Generated.div_([ React_Basic_DOM.text("Haluatko varmasti poistaa valitut mittausryhm\xe4t sek\xe4 kaikki ryhmiin kuuluvat mittaukset ja n\xe4ist\xe4 linkitetyt m\xe4\xe4r\xe4t?") ]) ]
            }), TacoModal.modalActions({
                contents: deleteModalActions
            }) ]
        });
        var deleteLinkButtonVisible = !Data_Array["null"](selectedItemIds) && (Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(v.activeLibraryTabId)(new Data_Maybe.Just("components")) && !Data_Array["null"](v.checkedComponents) || Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(v.activeLibraryTabId)(new Data_Maybe.Just("elements")) && (!Data_Array["null"](v.checkedElements) || !Data_Array["null"](v.checkedSpecs)));
        var deleteLinkButton = React_Basic.fragment([ TacoButton.component()()({
            text: "Poista linkitys",
            buttonStyle: TacoButton_Types.Filled.value,
            color: TacoConstants.componentColors.border,
            onClick: Data_Maybe.Just.create(linkMeasurement("DeleteLink")),
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconCloseCrossV1.value),
            size: TacoConstants.L.value,
            disabled: Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqBoolean))(v.maybeLockedProject)(new Data_Maybe.Just(true))
        }) ]);
        var columns = getColumns(locationsEnabled)(tableProps);
        var table = TacoTable.tableStateless(getNameDixonMeasurementGroupsSortProps)(eqDixonMeasurementGroupsSortProps)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "item";
            }
        })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "measurement_group";
            }
        })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "unit";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "perimeter";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "num_vertices";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "name";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "linked_component_ids";
            }
        })(Data_Eq.eqArray(Types_Component.eqComponentId)))()({
            reflectSymbol: function () {
                return "linked_building_element_ids";
            }
        })(Data_Eq.eqArray(Types_Element.eqElementId)))()({
            reflectSymbol: function () {
                return "id";
            }
        })(Data_Eq.eqInt))()({
            reflectSymbol: function () {
                return "formula_sum";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "drawing_ids";
            }
        })(Data_Eq.eqArray(Data_Eq.eqInt)))()({
            reflectSymbol: function () {
                return "default_location";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "default_formula";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "color";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "code";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "area";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "active";
            }
        })(Data_Eq.eqBoolean))))))()({
            reflectSymbol: function () {
                return "checked";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "activeModalMGroupId";
            }
        })(Data_Maybe.eqMaybe(Data_Eq.eqInt)))()({
            reflectSymbol: function () {
                return "activeDropdownMGroupId";
            }
        })(Data_Maybe.eqMaybe(Data_Eq.eqInt))))(measurementsTableInstance)({
            sortProperty: new Data_Maybe.Just(v13.value0),
            onSort: function (value) {
                return function __do() {
                    Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(Data_Eq.eq(eqDixonMeasurementGroupsSortProps)(v13.value0)(value))(v14.value1(TacoTable_Types.reverse))();
                    Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(Data_Eq.notEq(eqDixonMeasurementGroupsSortProps)(v13.value0)(value))(v14.value1(function (v17) {
                        return TacoTable_Types.Ascending.value;
                    }))();
                    return v13.value1(function (v17) {
                        return value;
                    })();
                };
            },
            sortingOrder: v14.value0,
            rows: Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Data_Functor.functorFn)(getRow(locationsEnabled)(tableProps))(function (item) {
                return {
                    item: item,
                    checked: Data_Foldable.elem(Data_Foldable.foldableArray)(Data_Eq.eqInt)(item.measurement_group.id)(selectedItemIds),
                    activeDropdownMGroupId: v8.value0,
                    activeModalMGroupId: v9.value0
                };
            }))(sortedMeasurementGroups),
            showHeader: true,
            styleSet: styleSet,
            columns: columns,
            onResize: function (v17) {
                return function (v18) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                };
            },
            columnSizeOffsets: Data_Maybe.Nothing.value
        });
        var closeLinkOtherQtyModal = v15.value1(function (v17) {
            return false;
        });
        var linkOtherQtyModalActions = [ TacoButton.component()()({
            onClick: Data_Maybe.Just.create(closeLinkOtherQtyModal),
            text: "Peruuta"
        }) ];
        var linkOtherQtyModal = TacoModal.component()()({
            onClose: closeLinkOtherQtyModal,
            isActive: v15.value0,
            heading: "Linkit\xe4 muu m\xe4\xe4r\xe4",
            contents: [ TacoModal.modalBody({
                contents: [ TacoButton.component()()({
                    text: "Linkit\xe4 kpl m\xe4\xe4r\xe4",
                    buttonStyle: TacoButton_Types.Filled.value,
                    color: TacoConstants.componentColors.border,
                    onClick: new Data_Maybe.Just(function __do() {
                        linkMeasurement("NumVertices")();
                        return closeLinkOtherQtyModal();
                    }),
                    icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconArrowRight.value),
                    size: TacoConstants.L.value
                }), TacoButton.component()()({
                    text: "Linkit\xe4 pituus / piiri",
                    buttonStyle: TacoButton_Types.Filled.value,
                    color: TacoConstants.componentColors.border,
                    onClick: new Data_Maybe.Just(function __do() {
                        linkMeasurement("Perimeter")();
                        return closeLinkOtherQtyModal();
                    }),
                    icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconArrowRight.value),
                    size: TacoConstants.L.value
                }), TacoButton.component()()({
                    text: "Linkit\xe4 pinta-ala",
                    buttonStyle: TacoButton_Types.Filled.value,
                    color: TacoConstants.componentColors.border,
                    onClick: new Data_Maybe.Just(function __do() {
                        linkMeasurement("Area")();
                        return closeLinkOtherQtyModal();
                    }),
                    icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconArrowRight.value),
                    size: TacoConstants.L.value
                }) ]
            }), TacoModal.modalActions({
                contents: linkOtherQtyModalActions
            }) ]
        });
        var checkBox = TacoCheckbox.component()()({
            isChecked: v4.value0,
            onToggle: TacoCheckbox.NoEvent.create(v4.value1(function (old) {
                return !old;
            })),
            labelText: Data_Maybe.Just.create((function () {
                var $159 = !v4.value0;
                if ($159) {
                    return new TacoCheckbox.StringLabel("Seuraa laskentaikkunan rajausta");
                };
                return new TacoCheckbox.JSXLabel(TacoText.component()()({
                    text: "N\xe4ytet\xe4\xe4n ",
                    children: [ React_Basic_DOM_Generated.var_([ React_Basic_DOM.text(Data_Show.show(Data_Show.showInt)(Data_Array.length(v2.value0)) + ("/" + Data_Show.show(Data_Show.showInt)(Data_Array.length(v3.value0)))) ]) ]
                }));
            })()),
            noPadding: true
        });
        var checkBoxComp = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "__checkBoxCompDixon",
            css: Box.boxStyle([ Box.Height.create(new TacoConstants.CustomSize("20px")), Box.PaddingLeft.create(new TacoConstants.CustomSize("10px")) ]),
            children: [ checkBox ]
        });
        var canCreateFromElementSpec = Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(v.activeLibraryTabId)(new Data_Maybe.Just("elements")) && !Data_Array["null"](v.checkedSpecs);
        var canCreateFromElement = Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(v.activeLibraryTabId)(new Data_Maybe.Just("elements")) && !Data_Array["null"](v.checkedElements);
        var canCreateFromComponent = Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(v.activeLibraryTabId)(new Data_Maybe.Just("components")) && !Data_Array["null"](v.checkedComponents);
        var newMeasurementGroupFromEstimationData = (function () {
            if (canCreateFromComponent) {
                var components = Data_Array.filter(function (cwr) {
                    return Data_Array.elem(Types_Component.eqComponentId)(cwr.component.id)(v.checkedComponents);
                })(v1.value0);
                var json = TofuJSON.write(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "component";
                    }
                })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "amount";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "checked";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "classCode";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "code";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "componentType";
                    }
                })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "costGroupCode";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "costGroupDescription";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "description";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "flagged";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "group";
                    }
                })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "memo";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "subProjectCode";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "subProjectId";
                    }
                })(TofuJSON.readNullable(Types_Project.tofuJSONSubprojectId))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "subProjectName";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "unit";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "amount";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "checked";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "classCode";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "code";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "componentType";
                    }
                })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "costGroupCode";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "costGroupDescription";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "description";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "flagged";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "group";
                    }
                })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "memo";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "subProjectCode";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "subProjectId";
                    }
                })(TofuJSON.readNullable(Types_Project.tofuJSONSubprojectId))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "subProjectName";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "unit";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "hourlyRate";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "hours";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "isSplitComponentWithMissingResources";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "locations";
                    }
                })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "amount";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "amountSource";
                    }
                })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "createTime";
                    }
                })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "description";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "elementSpecId";
                    }
                })(TofuJSON.readNullable(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "estimationComponentId";
                    }
                })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "group";
                    }
                })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "locationCode";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "sourceApplication";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "userName";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "amount";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "amountSource";
                    }
                })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "createTime";
                    }
                })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "description";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "elementSpecId";
                    }
                })(TofuJSON.readNullable(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "estimationComponentId";
                    }
                })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "group";
                    }
                })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "locationCode";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "sourceApplication";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "userName";
                    }
                })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "projectOfOrigin";
                    }
                })(TofuJSON.readNullable(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "applicationMode";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "code";
                    }
                })(Types_Project.tofuJSONProjectCode)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "createdDate";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "currency";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "isReportingProject";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "programType";
                    }
                })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "projectGroup";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "state";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "subprojects";
                    }
                })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "code";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "countInMeasurementTotal";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "projectId";
                    }
                })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "code";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "countInMeasurementTotal";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Project.tofuJSONSubprojectId)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "projectId";
                    }
                })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "version";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "applicationMode";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "code";
                    }
                })(Types_Project.tofuJSONProjectCode)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "createdDate";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "currency";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "isReportingProject";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "programType";
                    }
                })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "projectGroup";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "state";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "subprojects";
                    }
                })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "code";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "countInMeasurementTotal";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "projectId";
                    }
                })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "code";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "countInMeasurementTotal";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Project.tofuJSONSubprojectId)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "projectId";
                    }
                })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "version";
                    }
                })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "resources";
                    }
                })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "changeDate";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "contract";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "costClassCode";
                    }
                })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "currencyId";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "discountGroupCode";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "discountPercentage";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "eanCode";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "estimationComponentId";
                    }
                })(TofuJSON.readNullable(Types_Component.tofuJSONComponentId))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "group";
                    }
                })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "index";
                    }
                })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "isCostClassPrice";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "multiplier";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "orgResourcePriceId";
                    }
                })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "orgResourcePriceListId";
                    }
                })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "pricePerUnit";
                    }
                })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "priceWithDiscount";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "projectId";
                    }
                })(TofuJSON.readNullable(Types_Project.tofuJSONProjectId))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "resourceClassificationGroup";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "sortingNumber";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "taxPercentage";
                    }
                })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "unit";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "unitConsumptionRate";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "usagesCount";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "validDate";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "vendor";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "vendorBatchSize";
                    }
                })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "vendorCode";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "vendorUnit";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "wastePercentage";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "changeDate";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "contract";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "costClassCode";
                    }
                })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "currencyId";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "discountGroupCode";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "discountPercentage";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "eanCode";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "estimationComponentId";
                    }
                })(TofuJSON.readNullable(Types_Component.tofuJSONComponentId))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "group";
                    }
                })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "index";
                    }
                })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "isCostClassPrice";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "multiplier";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "orgResourcePriceId";
                    }
                })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "orgResourcePriceListId";
                    }
                })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "pricePerUnit";
                    }
                })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "priceWithDiscount";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "projectId";
                    }
                })(TofuJSON.readNullable(Types_Project.tofuJSONProjectId))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "resourceClassificationGroup";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "sortingNumber";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "taxPercentage";
                    }
                })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "unit";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "unitConsumptionRate";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "usagesCount";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "validDate";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "vendor";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "vendorBatchSize";
                    }
                })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "vendorCode";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "vendorUnit";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "wastePercentage";
                    }
                })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "totalPrice";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "totalPriceByCostClasses";
                    }
                })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "costClassCode";
                    }
                })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "hours";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "totalPrice";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "totalPriceWithoutSocialExpenses";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "costClassCode";
                    }
                })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "hours";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "totalPrice";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "totalPriceWithoutSocialExpenses";
                    }
                })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "totalPriceWithoutSocialExpenses";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "component";
                    }
                })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "amount";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "checked";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "classCode";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "code";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "componentType";
                    }
                })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "costGroupCode";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "costGroupDescription";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "description";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "flagged";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "group";
                    }
                })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "memo";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "subProjectCode";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "subProjectId";
                    }
                })(TofuJSON.readNullable(Types_Project.tofuJSONSubprojectId))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "subProjectName";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "unit";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "amount";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "checked";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "classCode";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "code";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "componentType";
                    }
                })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "costGroupCode";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "costGroupDescription";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "description";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "flagged";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "group";
                    }
                })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "memo";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "subProjectCode";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "subProjectId";
                    }
                })(TofuJSON.readNullable(Types_Project.tofuJSONSubprojectId))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "subProjectName";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "unit";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "hourlyRate";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "hours";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "isSplitComponentWithMissingResources";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "locations";
                    }
                })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "amount";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "amountSource";
                    }
                })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "createTime";
                    }
                })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "description";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "elementSpecId";
                    }
                })(TofuJSON.readNullable(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "estimationComponentId";
                    }
                })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "group";
                    }
                })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "locationCode";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "sourceApplication";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "userName";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "amount";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "amountSource";
                    }
                })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "createTime";
                    }
                })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "description";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "elementSpecId";
                    }
                })(TofuJSON.readNullable(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "estimationComponentId";
                    }
                })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "group";
                    }
                })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "locationCode";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "sourceApplication";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "userName";
                    }
                })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "projectOfOrigin";
                    }
                })(TofuJSON.readNullable(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "applicationMode";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "code";
                    }
                })(Types_Project.tofuJSONProjectCode)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "createdDate";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "currency";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "isReportingProject";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "programType";
                    }
                })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "projectGroup";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "state";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "subprojects";
                    }
                })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "code";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "countInMeasurementTotal";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "projectId";
                    }
                })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "code";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "countInMeasurementTotal";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Project.tofuJSONSubprojectId)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "projectId";
                    }
                })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "version";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "applicationMode";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "code";
                    }
                })(Types_Project.tofuJSONProjectCode)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "createdDate";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "currency";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "isReportingProject";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "programType";
                    }
                })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "projectGroup";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "state";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "subprojects";
                    }
                })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "code";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "countInMeasurementTotal";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "projectId";
                    }
                })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "code";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "countInMeasurementTotal";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Project.tofuJSONSubprojectId)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "projectId";
                    }
                })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "version";
                    }
                })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "resources";
                    }
                })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "changeDate";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "contract";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "costClassCode";
                    }
                })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "currencyId";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "discountGroupCode";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "discountPercentage";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "eanCode";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "estimationComponentId";
                    }
                })(TofuJSON.readNullable(Types_Component.tofuJSONComponentId))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "group";
                    }
                })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "index";
                    }
                })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "isCostClassPrice";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "multiplier";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "orgResourcePriceId";
                    }
                })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "orgResourcePriceListId";
                    }
                })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "pricePerUnit";
                    }
                })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "priceWithDiscount";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "projectId";
                    }
                })(TofuJSON.readNullable(Types_Project.tofuJSONProjectId))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "resourceClassificationGroup";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "sortingNumber";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "taxPercentage";
                    }
                })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "unit";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "unitConsumptionRate";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "usagesCount";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "validDate";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "vendor";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "vendorBatchSize";
                    }
                })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "vendorCode";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "vendorUnit";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "wastePercentage";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "changeDate";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "contract";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "costClassCode";
                    }
                })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "currencyId";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "discountGroupCode";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "discountPercentage";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "eanCode";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "estimationComponentId";
                    }
                })(TofuJSON.readNullable(Types_Component.tofuJSONComponentId))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "group";
                    }
                })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "index";
                    }
                })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "isCostClassPrice";
                    }
                })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "multiplier";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "name";
                    }
                })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "orgResourcePriceId";
                    }
                })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "orgResourcePriceListId";
                    }
                })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "pricePerUnit";
                    }
                })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "priceWithDiscount";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "projectId";
                    }
                })(TofuJSON.readNullable(Types_Project.tofuJSONProjectId))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "resourceClassificationGroup";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "sortingNumber";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "taxPercentage";
                    }
                })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "unit";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "unitConsumptionRate";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "usagesCount";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "validDate";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "vendor";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "vendorBatchSize";
                    }
                })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "vendorCode";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "vendorUnit";
                    }
                })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "wastePercentage";
                    }
                })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "totalPrice";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "totalPriceByCostClasses";
                    }
                })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "costClassCode";
                    }
                })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "hours";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "totalPrice";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                    reflectSymbol: function () {
                        return "totalPriceWithoutSocialExpenses";
                    }
                })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "costClassCode";
                    }
                })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "hours";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "totalPrice";
                    }
                })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "totalPriceWithoutSocialExpenses";
                    }
                })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
                    reflectSymbol: function () {
                        return "totalPriceWithoutSocialExpenses";
                    }
                })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(components);
                return function () {
                    return v.editor.new_measurement_group_from_component(json);
                };
            };
            if (canCreateFromElement) {
                var elements = Data_Array.filter(function (v17) {
                    return Data_Array.elem(Types_Element.eqElementId)((Data_Newtype.unwrap()(v17.element)).elementId)(v.checkedElements);
                })(v5.value0);
                var json = TofuJSON.write(TofuJSON.readArray(Types_BuildingElementsView.jsonWasmElementsData))(elements);
                return function () {
                    return v.editor.new_measurement_group_from_element(json);
                };
            };
            var matchingElementSpecs = Data_Array.filter(function (v17) {
                return Data_Array.elem(Types_Newtypes.eqElementSpecId)(v17.elementSpecId)(v.checkedSpecs);
            })(v6.value0);
            var matchingComponents = Data_Array.mapMaybe(function (v17) {
                return Data_Array.find(function (cwr) {
                    return Data_Eq.eq(Data_Nullable.eqNullable(Types_Component.eqComponentId))(v17.componentId)(Data_Nullable.notNull(cwr.component.id));
                })(v1.value0);
            })(matchingElementSpecs);
            var jsonElementSpecs = TofuJSON.write(TofuJSON.readArray(Types_Element.tofuJSONWasmElementSpec))(matchingElementSpecs);
            var jsonComponents = TofuJSON.write(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "component";
                }
            })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "amount";
                }
            })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "checked";
                }
            })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "classCode";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "code";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "componentType";
                }
            })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "costGroupCode";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "costGroupDescription";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "description";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "flagged";
                }
            })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "group";
                }
            })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "memo";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "subProjectCode";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "subProjectId";
                }
            })(TofuJSON.readNullable(Types_Project.tofuJSONSubprojectId))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "subProjectName";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "unit";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "amount";
                }
            })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "checked";
                }
            })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "classCode";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "code";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "componentType";
                }
            })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "costGroupCode";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "costGroupDescription";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "description";
                }
            })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "flagged";
                }
            })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "group";
                }
            })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "memo";
                }
            })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "subProjectCode";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "subProjectId";
                }
            })(TofuJSON.readNullable(Types_Project.tofuJSONSubprojectId))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "subProjectName";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "unit";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "hourlyRate";
                }
            })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "hours";
                }
            })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "isSplitComponentWithMissingResources";
                }
            })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "locations";
                }
            })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "amount";
                }
            })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "amountSource";
                }
            })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "createTime";
                }
            })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "description";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "elementSpecId";
                }
            })(TofuJSON.readNullable(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "estimationComponentId";
                }
            })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "group";
                }
            })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "locationCode";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "sourceApplication";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "userName";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "amount";
                }
            })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "amountSource";
                }
            })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "createTime";
                }
            })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "description";
                }
            })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "elementSpecId";
                }
            })(TofuJSON.readNullable(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "estimationComponentId";
                }
            })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "group";
                }
            })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "locationCode";
                }
            })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "sourceApplication";
                }
            })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "userName";
                }
            })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "projectOfOrigin";
                }
            })(TofuJSON.readNullable(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "applicationMode";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "code";
                }
            })(Types_Project.tofuJSONProjectCode)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "createdDate";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "currency";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "isReportingProject";
                }
            })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "name";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "programType";
                }
            })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "projectGroup";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "state";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "subprojects";
                }
            })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "code";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "countInMeasurementTotal";
                }
            })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "name";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "projectId";
                }
            })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "code";
                }
            })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "countInMeasurementTotal";
                }
            })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Project.tofuJSONSubprojectId)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "name";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "projectId";
                }
            })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "version";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "applicationMode";
                }
            })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "code";
                }
            })(Types_Project.tofuJSONProjectCode)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "createdDate";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "currency";
                }
            })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "isReportingProject";
                }
            })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "name";
                }
            })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "programType";
                }
            })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "projectGroup";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "state";
                }
            })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "subprojects";
                }
            })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "code";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "countInMeasurementTotal";
                }
            })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "name";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "projectId";
                }
            })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "code";
                }
            })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "countInMeasurementTotal";
                }
            })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Project.tofuJSONSubprojectId)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "name";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "projectId";
                }
            })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "version";
                }
            })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "resources";
                }
            })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "changeDate";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "contract";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "costClassCode";
                }
            })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "currencyId";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "discountGroupCode";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "discountPercentage";
                }
            })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "eanCode";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "estimationComponentId";
                }
            })(TofuJSON.readNullable(Types_Component.tofuJSONComponentId))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "group";
                }
            })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "index";
                }
            })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "isCostClassPrice";
                }
            })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "multiplier";
                }
            })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "name";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "orgResourcePriceId";
                }
            })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "orgResourcePriceListId";
                }
            })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "pricePerUnit";
                }
            })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "priceWithDiscount";
                }
            })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "projectId";
                }
            })(TofuJSON.readNullable(Types_Project.tofuJSONProjectId))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "resourceClassificationGroup";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "sortingNumber";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "taxPercentage";
                }
            })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "unit";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "unitConsumptionRate";
                }
            })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "usagesCount";
                }
            })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "validDate";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "vendor";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "vendorBatchSize";
                }
            })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "vendorCode";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "vendorUnit";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "wastePercentage";
                }
            })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "changeDate";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "contract";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "costClassCode";
                }
            })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "currencyId";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "discountGroupCode";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "discountPercentage";
                }
            })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "eanCode";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "estimationComponentId";
                }
            })(TofuJSON.readNullable(Types_Component.tofuJSONComponentId))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "group";
                }
            })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "index";
                }
            })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "isCostClassPrice";
                }
            })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "multiplier";
                }
            })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "name";
                }
            })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "orgResourcePriceId";
                }
            })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "orgResourcePriceListId";
                }
            })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "pricePerUnit";
                }
            })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "priceWithDiscount";
                }
            })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "projectId";
                }
            })(TofuJSON.readNullable(Types_Project.tofuJSONProjectId))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "resourceClassificationGroup";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "sortingNumber";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "taxPercentage";
                }
            })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "unit";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "unitConsumptionRate";
                }
            })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "usagesCount";
                }
            })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "validDate";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "vendor";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "vendorBatchSize";
                }
            })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "vendorCode";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "vendorUnit";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "wastePercentage";
                }
            })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "totalPrice";
                }
            })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "totalPriceByCostClasses";
                }
            })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "costClassCode";
                }
            })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "hours";
                }
            })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "totalPrice";
                }
            })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "totalPriceWithoutSocialExpenses";
                }
            })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "costClassCode";
                }
            })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "hours";
                }
            })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "totalPrice";
                }
            })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "totalPriceWithoutSocialExpenses";
                }
            })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "totalPriceWithoutSocialExpenses";
                }
            })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "component";
                }
            })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "amount";
                }
            })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "checked";
                }
            })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "classCode";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "code";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "componentType";
                }
            })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "costGroupCode";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "costGroupDescription";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "description";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "flagged";
                }
            })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "group";
                }
            })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "memo";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "subProjectCode";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "subProjectId";
                }
            })(TofuJSON.readNullable(Types_Project.tofuJSONSubprojectId))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "subProjectName";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "unit";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "amount";
                }
            })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "checked";
                }
            })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "classCode";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "code";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "componentType";
                }
            })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "costGroupCode";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "costGroupDescription";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "description";
                }
            })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "flagged";
                }
            })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "group";
                }
            })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "memo";
                }
            })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "subProjectCode";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "subProjectId";
                }
            })(TofuJSON.readNullable(Types_Project.tofuJSONSubprojectId))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "subProjectName";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "unit";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "hourlyRate";
                }
            })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "hours";
                }
            })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "isSplitComponentWithMissingResources";
                }
            })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "locations";
                }
            })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "amount";
                }
            })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "amountSource";
                }
            })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "createTime";
                }
            })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "description";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "elementSpecId";
                }
            })(TofuJSON.readNullable(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "estimationComponentId";
                }
            })(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "group";
                }
            })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "locationCode";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "sourceApplication";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "userName";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "amount";
                }
            })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "amountSource";
                }
            })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "createTime";
                }
            })(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "description";
                }
            })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "elementSpecId";
                }
            })(TofuJSON.readNullable(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "estimationComponentId";
                }
            })(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "group";
                }
            })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "locationCode";
                }
            })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "sourceApplication";
                }
            })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "userName";
                }
            })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "projectOfOrigin";
                }
            })(TofuJSON.readNullable(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "applicationMode";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "code";
                }
            })(Types_Project.tofuJSONProjectCode)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "createdDate";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "currency";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "isReportingProject";
                }
            })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "name";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "programType";
                }
            })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "projectGroup";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "state";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "subprojects";
                }
            })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "code";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "countInMeasurementTotal";
                }
            })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "name";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "projectId";
                }
            })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "code";
                }
            })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "countInMeasurementTotal";
                }
            })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Project.tofuJSONSubprojectId)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "name";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "projectId";
                }
            })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "version";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "applicationMode";
                }
            })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "code";
                }
            })(Types_Project.tofuJSONProjectCode)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "createdDate";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "currency";
                }
            })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "isReportingProject";
                }
            })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "name";
                }
            })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "programType";
                }
            })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "projectGroup";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "state";
                }
            })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "subprojects";
                }
            })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "code";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "countInMeasurementTotal";
                }
            })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "name";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "projectId";
                }
            })(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "code";
                }
            })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "countInMeasurementTotal";
                }
            })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Project.tofuJSONSubprojectId)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "name";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "projectId";
                }
            })(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "version";
                }
            })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "resources";
                }
            })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "changeDate";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "contract";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "costClassCode";
                }
            })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "currencyId";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "discountGroupCode";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "discountPercentage";
                }
            })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "eanCode";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "estimationComponentId";
                }
            })(TofuJSON.readNullable(Types_Component.tofuJSONComponentId))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "group";
                }
            })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "index";
                }
            })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "isCostClassPrice";
                }
            })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "multiplier";
                }
            })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "name";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "orgResourcePriceId";
                }
            })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "orgResourcePriceListId";
                }
            })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "pricePerUnit";
                }
            })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "priceWithDiscount";
                }
            })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "projectId";
                }
            })(TofuJSON.readNullable(Types_Project.tofuJSONProjectId))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "resourceClassificationGroup";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "sortingNumber";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "taxPercentage";
                }
            })(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "unit";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "unitConsumptionRate";
                }
            })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "usagesCount";
                }
            })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "validDate";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "vendor";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "vendorBatchSize";
                }
            })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "vendorCode";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "vendorUnit";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "wastePercentage";
                }
            })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "changeDate";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "contract";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "costClassCode";
                }
            })(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "currencyId";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "discountGroupCode";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "discountPercentage";
                }
            })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "eanCode";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "estimationComponentId";
                }
            })(TofuJSON.readNullable(Types_Component.tofuJSONComponentId))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "group";
                }
            })(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "id";
                }
            })(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "index";
                }
            })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "isCostClassPrice";
                }
            })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "multiplier";
                }
            })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "name";
                }
            })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "orgResourcePriceId";
                }
            })(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "orgResourcePriceListId";
                }
            })(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "pricePerUnit";
                }
            })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "priceWithDiscount";
                }
            })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "projectId";
                }
            })(TofuJSON.readNullable(Types_Project.tofuJSONProjectId))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "resourceClassificationGroup";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "sortingNumber";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "taxPercentage";
                }
            })(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "unit";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "unitConsumptionRate";
                }
            })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "usagesCount";
                }
            })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "validDate";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "vendor";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "vendorBatchSize";
                }
            })(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "vendorCode";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "vendorUnit";
                }
            })(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "wastePercentage";
                }
            })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "totalPrice";
                }
            })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "totalPriceByCostClasses";
                }
            })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "costClassCode";
                }
            })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "hours";
                }
            })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "totalPrice";
                }
            })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "totalPriceWithoutSocialExpenses";
                }
            })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "costClassCode";
                }
            })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "hours";
                }
            })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "totalPrice";
                }
            })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "totalPriceWithoutSocialExpenses";
                }
            })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "totalPriceWithoutSocialExpenses";
                }
            })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(matchingComponents);
            return function () {
                return v.editor.new_measurement_group_from_element_spec(jsonElementSpecs, jsonComponents);
            };
        })();
        var newMgFromEstimationDataButton = React_Basic.fragment([ TacoButton.component()()({
            text: "Luo mittausryhm\xe4" + (function () {
                if (canCreateFromComponent) {
                    return " suoritteesta";
                };
                if (canCreateFromElement) {
                    return " rakenteesta";
                };
                return " rakenteen suoritteesta";
            })(),
            buttonStyle: TacoButton_Types.Filled.value,
            color: TacoConstants.componentColors.border,
            onClick: new Data_Maybe.Just(newMeasurementGroupFromEstimationData),
            size: TacoConstants.L.value,
            disabled: Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqBoolean))(v.maybeLockedProject)(new Data_Maybe.Just(true))
        }) ]);
        var showNewMgFromEstimationData = canCreateFromComponent || (canCreateFromElement || canCreateFromElementSpec);
        var floatingActionButtons = React_Basic.fragment([ TutorialContainer.component({
            active: newMGroupTutorialActive,
            contents: Control_Applicative.pure(Control_Applicative.applicativeArray)(newMeasurementGroupButton)
        }), Data_Monoid.guard(React_Basic.monoidJSX)(showNewMgFromEstimationData)(newMgFromEstimationDataButton), Data_Monoid.guard(React_Basic.monoidJSX)(!Data_Array["null"](selectedItemIds))(deleteMeasurementGroupsButton), Data_Monoid.guard(React_Basic.monoidJSX)(linkButtonsVisible)(React_Basic.fragment([ linkOtherQuantityBtn, linkMeasurementButton ])), Data_Monoid.guard(React_Basic.monoidJSX)(deleteLinkButtonVisible)(React_Basic.fragment([ deleteLinkButton ])), Data_Monoid.guard(React_Basic.monoidJSX)(!Data_Array["null"](selectedItemIds) && !Data_Array["null"](v.checkedSpecs))(linkTypeBtn), React_Basic.fragment([ reCalculateAllMeasurements ]) ]);
        var measurementsTable = CostEstimationTableWrapper.component({
            colorSet: TacoConstants.componentColors,
            children: (function () {
                var $167 = v.showUploadFileHint && numMGroups === 0;
                if ($167) {
                    return [ Box.box("HintTextWrapper")([ new Box.Padding(TacoConstants.M.value) ])(Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM.text("Aloita lis\xe4\xe4m\xe4ll\xe4 piirustuksen tiedosto"))) ];
                };
                var $168 = v.showSelectFileHint && numMGroups === 0;
                if ($168) {
                    return [ Box.box("HintTextWrapper")([ new Box.Padding(TacoConstants.M.value) ])(Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM.text("Valitse seuraavaksi piirustuksen tiedosto"))) ];
                };
                var $169 = v.showSetScaleHint && numMGroups === 0;
                if ($169) {
                    return [ Box.box("HintTextWrapper")([ new Box.Padding(TacoConstants.M.value) ])(Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM.text("Aseta seuraavaksi piirustuksen mittakaava. Klikkaa toimintovalikosta Aseta mittakaava -ty\xf6kalua, mittaa piirustuksesta jana, jonka pituuden tied\xe4t ja merkkaa janan pituus avautuvaan valikkoon metrein\xe4."))) ];
                };
                if (v.showCreateMGroupHint) {
                    return [ Box.box("HintTextWrapper")([ new Box.Padding(TacoConstants.M.value) ])(Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM.text("Aloita mittaaminen luomalla uusi mittausryhm\xe4"))) ];
                };
                return [ checkBoxComp, table ];
            })(),
            floatingActionButtons: floatingActionButtons
        });
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "DixonMeasurementGroups",
            css: DixonMeasurementGroups_Styles.dixonMeasurementGroupsContainerStyles,
            children: [ measurementsTable, deleteMeasurementGroupsModal, linkOtherQtyModal, linkTypeModal ]
        });
    };
});
module.exports = {
    ByCode: ByCode,
    ByName: ByName,
    colorInput: colorInput,
    component: component,
    getRow: getRow,
    getSortingValue: getSortingValue,
    measurementsTableInstance: measurementsTableInstance,
    mkRow: mkRow,
    styleSet: styleSet,
    eqDixonMeasurementGroupsSortProps: eqDixonMeasurementGroupsSortProps,
    getNameDixonMeasurementGroupsSortProps: getNameDixonMeasurementGroupsSortProps
};
