import { useMutation, useQuery, UseQueryResult } from "react-query";
import {
  fetchUltimaCostGroups,
  fetchUltimaProjects,
  transferCalculationToUltima,
} from "./AdminetHttp";
import {
  Project,
  TargetAssessment,
  TargetAssessmentLetter,
} from "@tocoman/adminet-openapi-client";
import { AdminetParams } from "../../../../../../ts-bindings/AdminetParams";
import axios from "../../../../axiosConfig";
import { UltimaTenant } from "../../../../../server-ts/super-admin/organizations/SuperAdminOrganizationQueries";
import { useWorkerState } from "../../../../hooks/useWorkerState";

export const useAdminetProjectsQuery = (
  submittedSearchWord: string,
  adminetTenantId: string | null
): UseQueryResult<Array<Project>> => {
  return useQuery(
    ["getAdminetProjects", submittedSearchWord, adminetTenantId],
    async () => {
      return await fetchUltimaProjects({
        searchWord: submittedSearchWord,
        adminetTenantId,
      });
    },
    {
      enabled: submittedSearchWord !== "",
    }
  );
};

export const useAdminetCostGroupsQuery = (
  adminetTenantId: string | null
): UseQueryResult<Array<TargetAssessmentLetter>> => {
  return useQuery(
    ["getAdminetCostGroups", adminetTenantId],
    async () =>
      await fetchUltimaCostGroups({
        adminetTenantId,
      })
  );
};

export const useTransferCalculation = (
  adminetParams: AdminetParams | undefined,
  selectedAdminetProjectId: string | undefined,
  adminetTenantId: string | null
) => {
  const accessToken: string = useWorkerState("AccessTokenState") ?? "";
  return useMutation(async (targetAmount: TargetAssessment) => {
    if (!adminetParams || !selectedAdminetProjectId) {
      throw new Error("adminetParams or selectedAdminetProjectId not defined!");
    }
    return await transferCalculationToUltima({
      targetAmount,
      adminetProjectId: selectedAdminetProjectId,
      accessToken,
      adminetTenantId,
    });
  });
};

export function useAdminetTenants(
  changeMultipleTenantState: (value: boolean) => void
): { data: UltimaTenant[]; isLoading: boolean } {
  const { data, isLoading } = useQuery(["getAdminetTenants"], async () => {
    const { data } = await axios.get("integration/ultima/tenants");

    changeMultipleTenantState(data.length > 1);
    return data;
  });

  return { data, isLoading };
}
