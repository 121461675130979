// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var chart = TofuHooks.mkHookComponent("Chart")(function (props) {
    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_DOM_Generated.div()({
        className: "chart-container",
        children: [ React_Basic.element($foreign.echart)({
            groups: props.groups,
            series: props.series,
            height: "200px",
            valueFormatter: props.valueFormatter,
            onClick: props.onClick
        }) ]
    }));
});
module.exports = {
    chart: chart
};
