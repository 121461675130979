// Generated by purs version 0.14.5
"use strict";
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Show = require("../Data.Show/index.js");
var StringUtils = require("../StringUtils/index.js");
var TacoInput = require("../TacoInput/index.js");
var numberValidator = function (value) {
    var number = StringUtils.readStrToNumber(value);
    if (number instanceof Data_Maybe.Nothing) {
        return TacoInput.Invalid.create("Sy\xf6tetty arvo t\xe4ytyy olla numero.");
    };
    return TacoInput.Valid.value;
};
var numberRangeValidator = function (min) {
    return function (max) {
        return function (value) {
            var number = StringUtils.readStrToNumber(value);
            if (number instanceof Data_Maybe.Nothing) {
                return TacoInput.Invalid.create("Sy\xf6tetty arvo t\xe4ytyy olla numero.");
            };
            if (number instanceof Data_Maybe.Just && number.value0 > max) {
                return TacoInput.Invalid.create("Sy\xf6tetty arvo ei saa olla suurempi kuin " + (Data_Show.show(Data_Show.showNumber)(max) + "."));
            };
            if (number instanceof Data_Maybe.Just && number.value0 < min) {
                return TacoInput.Invalid.create("Sy\xf6tetty arvo ei saa olla pienempi kuin " + (Data_Show.show(Data_Show.showNumber)(min) + "."));
            };
            return TacoInput.Valid.value;
        };
    };
};
module.exports = {
    numberValidator: numberValidator,
    numberRangeValidator: numberRangeValidator
};
