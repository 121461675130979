// Generated by purs version 0.14.5
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var ReferenceLibrarySidepanel_Styles = require("../ReferenceLibrarySidepanel.Styles/index.js");
var ReferenceLibrarySidepanel_Types = require("../ReferenceLibrarySidepanel.Types/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var UseLicenseFeature = require("../UseLicenseFeature/index.js");
var component = TofuHooks.mkMemoHookComponent("ReferenceLibrarySidebar")(function (props) {
    return function __do() {
        var elementsEnabled = UseLicenseFeature.useLicenseFeature(UseLicenseFeature.Elements.value)();
        var toggleMode = function (mode) {
            var $3 = Data_Eq.eq(Data_Maybe.eqMaybe(ReferenceLibrarySidepanel_Types.eqActiveReferenceMode))(props.activeRefMode)(new Data_Maybe.Just(mode));
            if ($3) {
                return props.setActiveRefMode(function (v) {
                    return Data_Maybe.Nothing.value;
                });
            };
            return props.setActiveRefMode(function (v) {
                return new Data_Maybe.Just(mode);
            });
        };
        var toggleResourcesBtn = (function () {
            var active = Data_Eq.eq(Data_Maybe.eqMaybe(ReferenceLibrarySidepanel_Types.eqActiveReferenceMode))(props.activeRefMode)(new Data_Maybe.Just(ReferenceLibrarySidepanel_Types.ResourcesReferenceMode.value));
            return TacoButton.component()()({
                text: "Panoskirjasto",
                buttonStyle: new TacoButton_Types.Sidebar((function () {
                    if (active) {
                        return TacoButton_Types.SidebarActive.value;
                    };
                    return TacoButton_Types.SidebarInactive.value;
                })()),
                onClick: Data_Maybe.Just.create(toggleMode(ReferenceLibrarySidepanel_Types.ResourcesReferenceMode.value)),
                vertical: true,
                color: (function () {
                    if (active) {
                        return TacoConstants.resourcePriceListColors.border;
                    };
                    return TacoConstants.TextSecondary.value;
                })(),
                testId: "sidebar-navigation-ref-resources"
            });
        })();
        var toggleElementsBtn = (function () {
            var active = Data_Eq.eq(Data_Maybe.eqMaybe(ReferenceLibrarySidepanel_Types.eqActiveReferenceMode))(props.activeRefMode)(new Data_Maybe.Just(ReferenceLibrarySidepanel_Types.BuildingElementsReferenceMode.value));
            return Data_Monoid.guard(React_Basic.monoidJSX)(elementsEnabled)(TacoButton.component()()({
                text: "Rakennekirjasto",
                buttonStyle: new TacoButton_Types.Sidebar((function () {
                    if (active) {
                        return TacoButton_Types.SidebarActive.value;
                    };
                    return TacoButton_Types.SidebarInactive.value;
                })()),
                onClick: Data_Maybe.Just.create(toggleMode(ReferenceLibrarySidepanel_Types.BuildingElementsReferenceMode.value)),
                vertical: true,
                color: (function () {
                    if (active) {
                        return TacoConstants.elementColors.border;
                    };
                    return TacoConstants.TextSecondary.value;
                })(),
                testId: "sidebar-navigation-ref-elements"
            }));
        })();
        var toggleComponentsBtn = (function () {
            var active = Data_Eq.eq(Data_Maybe.eqMaybe(ReferenceLibrarySidepanel_Types.eqActiveReferenceMode))(props.activeRefMode)(new Data_Maybe.Just(ReferenceLibrarySidepanel_Types.ComponentsReferenceMode.value));
            return TacoButton.component()()({
                text: "Suoritekirjasto",
                buttonStyle: new TacoButton_Types.Sidebar((function () {
                    if (active) {
                        return TacoButton_Types.SidebarActive.value;
                    };
                    return TacoButton_Types.SidebarInactive.value;
                })()),
                onClick: Data_Maybe.Just.create(toggleMode(ReferenceLibrarySidepanel_Types.ComponentsReferenceMode.value)),
                vertical: true,
                color: (function () {
                    if (active) {
                        return TacoConstants.componentColors.border;
                    };
                    return TacoConstants.TextSecondary.value;
                })(),
                testId: "sidebar-navigation-ref-components"
            });
        })();
        var sidebar = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            children: [ toggleElementsBtn, toggleComponentsBtn, toggleResourcesBtn ],
            className: "__ReferenceLibrarySidebar",
            css: ReferenceLibrarySidepanel_Styles.sidebarStyles
        });
        return sidebar;
    };
});
module.exports = {
    component: component
};
