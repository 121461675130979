// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var useLayoutEffectAlways = $foreign.useLayoutEffectAlways_;
var useEffectAlways = $foreign.useEffectAlways_;
module.exports = {
    useEffectAlways: useEffectAlways,
    useLayoutEffectAlways: useLayoutEffectAlways,
    useEffectAlways_: $foreign.useEffectAlways_,
    useLayoutEffectAlways_: $foreign.useLayoutEffectAlways_
};
