import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Checkbox } from "@tocoman/ui";
import { ICellRendererParams } from "ag-grid-community";

export const HourlyPricingEditor = forwardRef(
  (props: ICellRendererParams, ref) => {
    const [checked, setChecked] = useState(props.value);
    const refContainer = useRef(null);
    const refFocus = useRef(null);

    useImperativeHandle(ref, () => {
      return {
        getValue: function () {
          return checked;
        },
      };
    });

    return (
      <div className={"flex h-full pl-5"} ref={refContainer} tabIndex={1}>
        <Checkbox
          ref={refFocus}
          className={"self-center"}
          checked={checked}
          setChecked={() => {
            setChecked(!checked);
          }}
        />
      </div>
    );
  }
);

HourlyPricingEditor.displayName = "HourlyPricingEditor";
