// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Foreign = require("../Foreign/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_ReasonableCoerce = require("../Types.ReasonableCoerce/index.js");
var tofuJSON = function (dictIsSymbol) {
    return {
        writeImpl: Foreign.unsafeToForeign,
        readImpl: function (f) {
            var s = Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value);
            return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(TofuJSON["read'"](TofuJSON.readString)(f))(function (v) {
                var $4 = v === s;
                if ($4) {
                    return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Types_ReasonableCoerce.reasonableCoerce("TypeString has same runtime representation as str value")(v));
                };
                return Foreign.fail(Data_Identity.monadIdentity)(new Foreign.TypeMismatch("TypeString literal " + s, v));
            });
        }
    };
};
var mkTypeString = function (dictIsSymbol) {
    return function (p) {
        return Types_ReasonableCoerce.reasonableCoerce("TypeString")(Data_Symbol.reflectSymbol(dictIsSymbol)(p));
    };
};
var extractString = function (typeString) {
    return Types_ReasonableCoerce.reasonableCoerce("TypeString")(typeString);
};
var showTypeString = {
    show: function (ts) {
        return extractString(ts);
    }
};
module.exports = {
    mkTypeString: mkTypeString,
    extractString: extractString,
    showTypeString: showTypeString,
    tofuJSON: tofuJSON
};
