import "@mescius/activereportsjs/pdfexport";
import "@mescius/activereportsjs/htmlexport";
import "@mescius/activereportsjs/tabulardataexport";
import "@mescius/activereportsjs/xlsxexport";
import "@mescius/activereportsjs/styles/ar-js-ui.css";
import "@mescius/activereportsjs/styles/ar-js-viewer.css";
import { Rdl as ARJS } from "@mescius/activereportsjs/core";
import { ReportViewProps, ReportName, ReportDictionary } from "./types";
import { Core } from "@mescius/activereportsjs";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AllReportParametersData } from "../../SuperAdmin/ReportDesigner/AllReportParameters";
import { ParamsForARJS, ReportParametersForm } from "./ReportParametersForm";
import { ProjectClassificationClass } from "../../../../../ts-bindings/ProjectClassificationClass";
import { ComponentWithResources } from "../../../../../ts-bindings/ComponentWithResources";
import { ProjectDetailsData } from "../../../../../ts-bindings/ProjectDetailsData";
import { Subproject } from "../../../../../ts-bindings/Subproject";
import { ProjectLocation } from "../../../../../ts-bindings/ProjectLocation";
import { CostClass } from "../../../../../ts-bindings/CostClass";
import { useProjectFullData } from "./useProjectFullData";
import { ElementsData } from "../../../../../ts-bindings/ElementsData";
import { Measurement } from "../../../../../ts-bindings/Measurement";
import { reportCustomCodes } from "../../SuperAdmin/ReportDesigner/CustomFunctions";
import * as C200Template from "../../SuperAdmin/ReportDesigner/C200/C200Template.json";
import { C200Report } from "./C200/C200";
import { c200WithProjectData } from "./C200/C200WithProjectData";
Core.CustomCode.registerFunctions(reportCustomCodes);

export type FullProjectData = {
  componentsClassData?: ProjectClassificationClass[];
  componentsWithResourcesData?: ComponentWithResources[];
  projectData?: ProjectDetailsData;
  subProjects?: Subproject[];
  locations?: ProjectLocation[];
  costClasses?: CostClass[];
  measurements?: Measurement[];
  elements?: ElementsData[];
} | null;

const reports: ReportDictionary = {
  // @ts-ignore
  C200: C200Report,
};

const fetchReport = (_reportTitle: ReportName): ARJS.Report | undefined => {
  const loadTemplate = (): ARJS.Report => {
    const storedTemplate = localStorage.getItem("C200Template");
    return storedTemplate ? JSON.parse(storedTemplate) : C200Template;
  };

  switch (_reportTitle) {
    case "C200":
      return loadTemplate();
    default:
      return loadTemplate();
  }
};

export const Report = ({ projectId }: ReportViewProps) => {
  const methods = useForm<AllReportParametersData>();
  const { watch } = methods;

  const selectedReport: ReportName = (watch("report") as ReportName) ?? "C200";

  const [parametersData, setParametersData] = useState<ParamsForARJS[]>([]);
  const [parametersSet, setParametersSet] = useState(false);
  const [report, setReport] = useState<ARJS.Report | undefined>();

  const fullProjectData = useProjectFullData(projectId);

  useEffect(() => {
    setReport(fetchReport(selectedReport));
  }, [selectedReport]);

  const { report: reportWithData, parameters } = report
    ? c200WithProjectData(fullProjectData, report)
    : { report: undefined, parameters: undefined };

  const ChosenReport = reports[selectedReport];

  return (
    <div className={"flex h-full w-full"}>
      <div className={"flex flex-row w-full"}>
        <div className={"flex flex-col gap-4 pr-3 w-auto"}>
          {parameters && (
            <FormProvider {...methods}>
              <ReportParametersForm
                projectData={fullProjectData}
                reportParameters={parameters}
                setParametersData={setParametersData}
                setParametersSet={setParametersSet}
              />
            </FormProvider>
          )}
        </div>

        <div className={"w-full h-full"}>
          {parametersSet && reportWithData && (
            <ChosenReport report={reportWithData} params={parametersData} />
          )}
        </div>
      </div>
    </div>
  );
};
