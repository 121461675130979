import { useEffect, useState } from "react";
import { AdminetProjectNameSearch } from "./AdminetProjectNameSearch";
import { AdminetProjectTable } from "./AdminetProjectTable";
import { useAdminetProjectsQuery } from "../http/AdminetHttpHandler";
import { AdminetProjectsFetchStatus } from "./ImportCostCalculationToAdminetModal";

type SelectAdminetProjectProps = {
  disabled: boolean;
  resetTransferalStates: () => void;
  value: string | undefined;
  onChange: (projectId: string | undefined) => void;
  onFetchStatusChange: (fetchStatus: AdminetProjectsFetchStatus) => void;
  adminetTenantId: string | null;
};

export const SelectAdminetProject = ({
  disabled,
  resetTransferalStates,
  value,
  onChange,
  onFetchStatusChange,
  adminetTenantId,
}: SelectAdminetProjectProps) => {
  const [submittedSearchWord, setSubmittedSearchWord] = useState("");

  const adminetProjects = useAdminetProjectsQuery(
    submittedSearchWord,
    adminetTenantId
  );

  useEffect(() => {
    if (
      adminetProjects.data &&
      adminetProjects.isSuccess &&
      adminetProjects.data.length === 0
    ) {
      onFetchStatusChange(AdminetProjectsFetchStatus.Empty);
    } else if (adminetProjects.error) {
      onFetchStatusChange(AdminetProjectsFetchStatus.Error);
    } else {
      onFetchStatusChange(AdminetProjectsFetchStatus.NoError);
    }
  }, [adminetProjects, onFetchStatusChange]);

  return (
    <>
      <AdminetProjectNameSearch
        disabled={disabled}
        adminetProjectsLoading={adminetProjects.isLoading}
        onChange={(newSearchWord: string) => {
          resetTransferalStates();
          setSubmittedSearchWord(newSearchWord);
        }}
      ></AdminetProjectNameSearch>

      <AdminetProjectTable
        adminetProjects={adminetProjects}
        value={value}
        onChange={onChange}
      ></AdminetProjectTable>
    </>
  );
};
