import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useGetGroupsQuery } from "./hooks/useGetGroups";
import { useModalActionButtons } from "./hooks/useModalActionButtons";
import { useRunOnce } from "../../../hooks/useRunOnce";
import { useEditGroup } from "./hooks/useEditGroup";
import { Button, Header, Input, SelectItem } from "@tocoman/ui";
import { LabelFilteredCombobox } from "../../../components/LabelFilteredCombobox";
import { useState } from "react";
import { useCreateNewGroup } from "./hooks/useCreateGroup";

type EditGroupParams = {
  projectId: number;
  componentIds: number[];
  onSave: () => void;
  closeModal: () => void;
  setActionButtons: (actions: JSX.Element) => void;
};
type EditGroupForm = {
  newGroup: string;
};

export const EditGroup = ({
  projectId,
  onSave,
  setActionButtons,
  closeModal,
  componentIds,
}: EditGroupParams) => {
  const { t } = useTranslation("estimation", {
    keyPrefix: "componentView.editMultipleComponentsModal",
  });
  const { control, handleSubmit, setValue } = useForm<EditGroupForm>();
  const { data, refetch } = useGetGroupsQuery(projectId);
  const createNewGroup = useCreateNewGroup(projectId);
  const groups = data ?? [];
  const groupItems: SelectItem<string>[] = groups
    ?.map((group) => ({
      key: group.code,
      value: group.code,
      label: `${group.code} - ${group.description}`,
    }))
    .concat({
      key: "addNewGroup",
      value: "addNewGroup",
      label: t`form.addNewGroup`,
    });

  const { mutate: editGroup } = useEditGroup(projectId, onSave);

  const handleSave = (data: EditGroupForm) => {
    const values = componentIds.map((component) => {
      return {
        componentId: component,
        editAction: {
          type: "EditEstimationComponentGroup",
          value: {
            group: data.newGroup,
          },
        },
      };
    });
    const editGroupData = {
      type: "EditEstimationComponents",
      value: values,
    };
    editGroup(editGroupData);
  };

  const setActions = useModalActionButtons(
    setActionButtons,
    handleSubmit(handleSave),
    closeModal
  );
  useRunOnce(() => {
    setActions(false);
  });

  const [addGroupFieldsVisible, setAddGroupFieldsVisible] = useState(false);

  const [newGroup, setNewGroup] = useState({ code: "", description: "" });

  const handleCreateGroup = async () => {
    await createNewGroup.mutateAsync(
      {
        type: "AddClassificationGroup",
        value: {
          projectId,
          code: newGroup.code,
          description: newGroup.description,
        },
      },
      {
        onSuccess: () => {
          refetch();
          toggleAddGroupFieldsVisible(false);
          setNewGroup({ code: "", description: "" });
          setValue("newGroup", newGroup.code);
        },
      }
    );
  };

  const codeExists = groups.some((group) => group.code === newGroup.code);

  const toggleAddGroupFieldsVisible = (visible: boolean) => {
    setAddGroupFieldsVisible(visible);
    setActions(visible);
  };

  return (
    <>
      {t`form.editGroupDescription`}
      <div className={"w-[300px]"}>
        <LabelFilteredCombobox
          label={t`form.group`}
          name={"newGroup"}
          items={groupItems}
          control={control}
          onChange={(value) =>
            toggleAddGroupFieldsVisible(value?.key === "addNewGroup")
          }
        />
        {addGroupFieldsVisible && (
          <div className={"flex flex-col"}>
            <Header
              title={t`form.addNewGroup`}
              titleSize={"small"}
              className={"mt-5"}
            />
            <Input
              label={t("form.code")}
              value={newGroup.code}
              className={"mt-3"}
              onChange={(e) =>
                setNewGroup({
                  code: e.target.value,
                  description: newGroup.description,
                })
              }
            />
            {codeExists && (
              <div className={"text-red-500"}>{t("form.codeExists")}</div>
            )}
            <Input
              label={t("form.description")}
              value={newGroup.description}
              className={"mt-3"}
              onChange={(e) =>
                setNewGroup({
                  code: newGroup.code,
                  description: e.target.value,
                })
              }
            />
            <Button
              label={t("form.addNewGroup")}
              className={"mt-3"}
              onClick={handleCreateGroup}
              disabled={codeExists}
            />
          </div>
        )}
      </div>
    </>
  );
};
