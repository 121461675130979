// Generated by purs version 0.14.5
"use strict";
var sapSalariesUploadTemplate = "https://s3.eu-central-1.amazonaws.com/tcm-public-assets/palkkojentuonti_SAP_esim.xlsx";
var salariesUploadTemplate = "https://s3.eu-central-1.amazonaws.com/tcm-public-assets/palkkojentuonti_esim.xlsx";
var orgResourcesUploadTemplate = "TODOMAKETEMPLATE";
var orgResourceSubGroupingUploadTemplate = "TODOMAKETEMPLATE";
var orgPriceListUploadTemplate = "TODOMAKETEMPLATE";
var invoicesUploadTemplate = "https://s3.eu-central-1.amazonaws.com/tcm-public-assets/laskujentuonti_SAP_esim.xlsx";
var installmentsUploadTemplate = "https://tcm-public-assets.s3.eu-central-1.amazonaws.com/maksuera%CC%88taulukko_esim.xlsx";
var estimationQuantityByLocationUploadTemplate = "TODOMAKETEMPLATE";
var estimationQuantityByCostClassUploadTemplate = "TODOMAKETEMPLATE";
var estimationComponentsUploadTemplateFi = "https://tcm-public-assets.s3.eu-central-1.amazonaws.com/suoritetuonti_esim.xlsx";
var estimationComponentsUploadTemplateEn = "https://tcm-public-assets.s3.eu-central-1.amazonaws.com/bill_of_quantities_template.xlsx";
var costGroupsUploadTemplate = "https://tcm-public-assets.s3.eu-central-1.amazonaws.com/litteratuonti_esim_2.xlsx";
module.exports = {
    costGroupsUploadTemplate: costGroupsUploadTemplate,
    installmentsUploadTemplate: installmentsUploadTemplate,
    invoicesUploadTemplate: invoicesUploadTemplate,
    sapSalariesUploadTemplate: sapSalariesUploadTemplate,
    salariesUploadTemplate: salariesUploadTemplate,
    estimationComponentsUploadTemplateFi: estimationComponentsUploadTemplateFi,
    estimationComponentsUploadTemplateEn: estimationComponentsUploadTemplateEn,
    estimationQuantityByCostClassUploadTemplate: estimationQuantityByCostClassUploadTemplate,
    estimationQuantityByLocationUploadTemplate: estimationQuantityByLocationUploadTemplate,
    orgResourcesUploadTemplate: orgResourcesUploadTemplate,
    orgPriceListUploadTemplate: orgPriceListUploadTemplate,
    orgResourceSubGroupingUploadTemplate: orgResourceSubGroupingUploadTemplate
};
