// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Function_Uncurried = require("../Data.Function.Uncurried/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Types_JSDateString = require("../Types.JSDateString/index.js");
var Types_ReasonableCoerce = require("../Types.ReasonableCoerce/index.js");
var subMonths = Data_Function_Uncurried.runFn2($foreign["_subMonths"]);
var startOfMonth$prime = Types_ReasonableCoerce.reasonableCoerce("DateFns can take either string or jsdate input")($foreign.startOfMonth);
var min = Data_Function_Uncurried.runFn2($foreign["_min"]);
var isSameMonth = function (x) {
    return function (y) {
        return $foreign.getMonth(x) === $foreign.getMonth(y) && $foreign.getYear(x) === $foreign.getYear(y);
    };
};
var isSameDay = Data_Function_Uncurried.runFn2($foreign["_isSameDay"]);
var isBefore = Data_Function_Uncurried.runFn2($foreign["_isBefore"]);
var isAfter = Data_Function_Uncurried.runFn2($foreign["_isAfter"]);
var format = Data_Function_Uncurried.runFn2($foreign["_format"]);
var formatJSDateString = function (date) {
    return Data_Either.hush(Types_JSDateString.makeJSDateString(format(date)("YYYY-MM-DD")));
};
var endOfMonth$prime = Types_ReasonableCoerce.reasonableCoerce("DateFns can take either string or jsdate input")($foreign.endOfMonth);
var differenceInCalendarMonths = Data_Function_Uncurried.runFn2($foreign["_differenceInCalendarMonths"]);
var differenceInCalendarDays = Data_Function_Uncurried.runFn2($foreign["_differenceInCalendarDays"]);
var addMonths = Data_Function_Uncurried.runFn2($foreign["_addMonths"]);
var monthsBetween = function (start) {
    return function (_end) {
        if (_end instanceof Data_Maybe.Just) {
            var prepareMonth = function (i) {
                return endOfMonth$prime(addMonths(start)(i));
            };
            var diff = Data_Int.round(differenceInCalendarMonths(_end.value0)(start));
            return Data_Functor.map(Data_Functor.functorArray)(prepareMonth)(Data_Array.range(0)(diff));
        };
        if (_end instanceof Data_Maybe.Nothing) {
            return [ start ];
        };
        throw new Error("Failed pattern match at DateFnsFFI (line 61, column 3 - line 67, column 23): " + [ _end.constructor.name ]);
    };
};
module.exports = {
    "endOfMonth'": endOfMonth$prime,
    "startOfMonth'": startOfMonth$prime,
    format: format,
    formatJSDateString: formatJSDateString,
    subMonths: subMonths,
    differenceInCalendarMonths: differenceInCalendarMonths,
    differenceInCalendarDays: differenceInCalendarDays,
    addMonths: addMonths,
    isSameMonth: isSameMonth,
    monthsBetween: monthsBetween,
    min: min,
    isBefore: isBefore,
    isAfter: isAfter,
    isSameDay: isSameDay,
    getTime: $foreign.getTime,
    startOfMonth: $foreign.startOfMonth,
    endOfMonth: $foreign.endOfMonth,
    "_format": $foreign["_format"],
    "_subMonths": $foreign["_subMonths"],
    getMonth: $foreign.getMonth,
    getYear: $foreign.getYear,
    "_differenceInCalendarMonths": $foreign["_differenceInCalendarMonths"],
    "_differenceInCalendarDays": $foreign["_differenceInCalendarDays"],
    "_addMonths": $foreign["_addMonths"],
    "_min": $foreign["_min"],
    "_isBefore": $foreign["_isBefore"],
    "_isAfter": $foreign["_isAfter"],
    "_isSameDay": $foreign["_isSameDay"]
};
