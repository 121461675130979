// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var CostEstimation_Calculations = require("../CostEstimation.Calculations/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Locale = require("../Locale/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_DOM_Internal = require("../React.Basic.DOM.Internal/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Row = require("../TacoTable.Row/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var ByCostClassLabel = (function () {
    function ByCostClassLabel() {

    };
    ByCostClassLabel.value = new ByCostClassLabel();
    return ByCostClassLabel;
})();
var ByTargetItemValue = (function () {
    function ByTargetItemValue() {

    };
    ByTargetItemValue.value = new ByTargetItemValue();
    return ByTargetItemValue;
})();
var BySocialExpensePercentage = (function () {
    function BySocialExpensePercentage() {

    };
    BySocialExpensePercentage.value = new BySocialExpensePercentage();
    return BySocialExpensePercentage;
})();
var BySocialExpenses = (function () {
    function BySocialExpenses() {

    };
    BySocialExpenses.value = new BySocialExpenses();
    return BySocialExpenses;
})();
var ByTargetItemValueWithSocialExpenses = (function () {
    function ByTargetItemValueWithSocialExpenses() {

    };
    ByTargetItemValueWithSocialExpenses.value = new ByTargetItemValueWithSocialExpenses();
    return ByTargetItemValueWithSocialExpenses;
})();
var ByNewSocialExpenses = (function () {
    function ByNewSocialExpenses() {

    };
    ByNewSocialExpenses.value = new ByNewSocialExpenses();
    return ByNewSocialExpenses;
})();
var ByNewSum = (function () {
    function ByNewSum() {

    };
    ByNewSum.value = new ByNewSum();
    return ByNewSum;
})();
var targetItemStyleSet = TacoTable_Styles.foldingTableStyleSetWithoutSticky;
var summaryColumns = [ {
    key: "name",
    width: new TacoTable_Types.Flex(1),
    label: React_Basic_DOM.text(""),
    sortProperty: TacoTable_Types.noSortProperty,
    cell: new TacoTable_Types.PlainTextCell(function (v) {
        return v.title;
    }),
    headerJSX: Data_Maybe.Nothing.value
}, {
    key: "sum",
    width: new TacoTable_Types.Flex(1),
    label: React_Basic_DOM.text(""),
    sortProperty: TacoTable_Types.noSortProperty,
    cell: new TacoTable_Types.PlainEuroCell(function (v) {
        return v.sum;
    }),
    headerJSX: Data_Maybe.Nothing.value
}, {
    key: "diff",
    width: new TacoTable_Types.Flex(1),
    label: React_Basic_DOM.text(""),
    sortProperty: TacoTable_Types.noSortProperty,
    cell: new TacoTable_Types.PlainEuroCell(function (v) {
        return v.diff;
    }),
    headerJSX: Data_Maybe.Nothing.value
}, {
    key: "sumWithSE",
    width: new TacoTable_Types.Flex(1),
    label: React_Basic_DOM.text(""),
    sortProperty: TacoTable_Types.noSortProperty,
    cell: new TacoTable_Types.PlainEuroCell(function (v) {
        return v.sumWithSE;
    }),
    headerJSX: Data_Maybe.Nothing.value
} ];
var summaryRow = function (styleSet) {
    return function (summaryData) {
        return TacoTable_Row.row(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "title";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "sumWithSE";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "sum";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "diff";
            }
        })(Data_Eq.eqNumber)))({
            rowData: summaryData,
            css: React_Basic_Emotion.css()({}),
            rowHeight: TacoTable_Types.RowMedium.value,
            sortProperty: TacoTable_Types.noSortProperty,
            onClick: function (v) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            },
            className: "",
            foldingElement: Data_Maybe.Nothing.value,
            columns: summaryColumns,
            styleSet: styleSet,
            style: React_Basic_DOM_Internal.css({}),
            rowKey: "summary-" + summaryData.title
        });
    };
};
var sortTargetItemsData = function (v) {
    return function (r) {
        if (v instanceof ByCostClassLabel) {
            return new TacoTable_Types.StringValue(r.costClassLabel);
        };
        if (v instanceof ByTargetItemValue) {
            return new TacoTable_Types.NumberValue(r.targetItemValue);
        };
        if (v instanceof BySocialExpensePercentage) {
            return TacoTable_Types.NumberValue.create(Data_Newtype.un()(Types_Unit.PercentageOfZeroToOne)(r.socialExpensePercentage));
        };
        if (v instanceof BySocialExpenses) {
            return new TacoTable_Types.NumberValue(r.socialExpenses);
        };
        if (v instanceof ByTargetItemValueWithSocialExpenses) {
            return new TacoTable_Types.NumberValue(r.targetItemValueWithSocialExpenses);
        };
        if (v instanceof ByNewSocialExpenses) {
            return new TacoTable_Types.NumberValue(r.newSocialExpenses);
        };
        if (v instanceof ByNewSum) {
            return new TacoTable_Types.NumberValue(r.newSum);
        };
        throw new Error("Failed pattern match at EditCostGroupItemsTable (line 182, column 1 - line 182, column 87): " + [ v.constructor.name, r.constructor.name ]);
    };
};
var mkTargetItemsRow = function (columns) {
    return function (v) {
        return function (r) {
            return {
                rowData: r,
                rowKey: Data_Show.show(Data_Show.showInt)(r.idx),
                onClick: function (v1) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                },
                className: "",
                foldingElement: Data_Maybe.Nothing.value,
                columns: columns
            };
        };
    };
};
var mkTargetItemsData = function (inputs) {
    return function (idx) {
        return function (targetItemRow) {
            var targetItemValueWithSE = targetItemRow.targetItemValue + targetItemRow.socialExpenses;
            var socialExpenses = (function () {
                var v = Data_Array.index(inputs)(idx);
                if (v instanceof Data_Maybe.Just) {
                    return v.value0.socialExpenses;
                };
                if (v instanceof Data_Maybe.Nothing) {
                    return 0.0;
                };
                throw new Error("Failed pattern match at EditCostGroupItemsTable (line 301, column 22 - line 303, column 21): " + [ v.constructor.name ]);
            })();
            var currentInputValues = (function () {
                var v = Data_Array.find(function (v1) {
                    return Data_Eq.eq(Types_Newtypes.eqCostClassCode)(v1.costClassCode)(targetItemRow.costClassCode);
                })(inputs);
                if (v instanceof Data_Maybe.Just) {
                    return {
                        diff: v.value0.diff,
                        sum: v.value0.sum,
                        sumWithSE: v.value0.sumWithSE
                    };
                };
                if (v instanceof Data_Maybe.Nothing) {
                    return {
                        diff: 0.0,
                        sum: 0.0,
                        sumWithSE: targetItemValueWithSE
                    };
                };
                throw new Error("Failed pattern match at EditCostGroupItemsTable (line 306, column 7 - line 308, column 77): " + [ v.constructor.name ]);
            })();
            return {
                costClassLabel: targetItemRow.costClassLabel,
                costClassCode: targetItemRow.costClassCode,
                targetItemValue: targetItemRow.targetItemValue,
                socialExpensePercentage: targetItemRow.socialExpensePercentage,
                socialExpenses: targetItemRow.socialExpenses,
                targetItemValueWithSocialExpenses: targetItemRow.targetItemValueWithSocialExpenses,
                newSocialExpenses: socialExpenses,
                newSum: -1.0 * (socialExpenses - currentInputValues.sumWithSE),
                idx: idx,
                targetItemValueWithSE: targetItemValueWithSE,
                diff: currentInputValues.diff,
                sum: currentInputValues.sum,
                sumWithSE: currentInputValues.sumWithSE
            };
        };
    };
};
var mappedSumWithPrecision = function (f) {
    var $42 = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber);
    var $43 = Data_Functor.map(Data_Functor.functorArray)(CostEstimation_Calculations.toCurrencyPrecision);
    var $44 = Data_Functor.map(Data_Functor.functorArray)(f);
    return function ($45) {
        return $42($43($44($45)));
    };
};
var getNameTargetItemsTableSortProps = {
    getName: function (v) {
        return "TargetItemsTableSortProps";
    }
};
var targetItemsTableInstance = TacoTable.mkTableSortable(getNameTargetItemsTableSortProps)("TargetItems");
var eqTargetItemsTableSortProps = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ByCostClassLabel && y instanceof ByCostClassLabel) {
                return true;
            };
            if (x instanceof ByTargetItemValue && y instanceof ByTargetItemValue) {
                return true;
            };
            if (x instanceof BySocialExpensePercentage && y instanceof BySocialExpensePercentage) {
                return true;
            };
            if (x instanceof BySocialExpenses && y instanceof BySocialExpenses) {
                return true;
            };
            if (x instanceof ByTargetItemValueWithSocialExpenses && y instanceof ByTargetItemValueWithSocialExpenses) {
                return true;
            };
            if (x instanceof ByNewSocialExpenses && y instanceof ByNewSocialExpenses) {
                return true;
            };
            if (x instanceof ByNewSum && y instanceof ByNewSum) {
                return true;
            };
            return false;
        };
    }
};
var calculateTotalWithoutSE = function (v) {
    return function (inputValueWithoutSE) {
        return inputValueWithoutSE / (1.0 + v);
    };
};
var calculateTotalWithSE = function (v) {
    return function (inputValueWithSE) {
        return (1.0 + v) * inputValueWithSE;
    };
};
var getTargetItemColumns = function (props) {
    var modifyInputs = function (r) {
        return function (value) {
            return function (inputUpdater) {
                return function (inputs) {
                    return Data_Maybe.fromMaybe(inputs)(Data_Array.modifyAt(r.idx)(inputUpdater(value))(inputs));
                };
            };
        };
    };
    var handler = function (r) {
        return function (inputUpdater) {
            return function (value) {
                return props.setInputs(modifyInputs(r)(value)(inputUpdater));
            };
        };
    };
    return [ {
        key: "costClassLabel",
        width: new TacoTable_Types.Flex(1),
        label: React_Basic_DOM.text(Locale.lookup_("cost_control_targetItemCostClass")),
        sortProperty: new Data_Maybe.Just(ByCostClassLabel.value),
        cell: new TacoTable_Types.PlainTextCell(function (v) {
            return v.costClassLabel;
        }),
        headerJSX: Data_Maybe.Nothing.value
    }, {
        key: "sumInput",
        width: new TacoTable_Types.Flex(1),
        label: React_Basic_DOM.text(Locale.lookup_("cost_control_targetItemValue")),
        sortProperty: new Data_Maybe.Just(ByTargetItemValue.value),
        cell: TacoTable_Types.JSXCell.create([  ])(function (r) {
            return TacoInput.numberInput()()(Data_Eq.eqNumber)({
                value: CostEstimation_Calculations.toCurrencyPrecision(r.sum),
                precision: 2,
                onSave: handler(r)(function (value) {
                    return function (v) {
                        return {
                            costClassCode: v.costClassCode,
                            diff: value - r.targetItemValue,
                            initSum: v.initSum,
                            sum: value,
                            sumWithSE: calculateTotalWithSE(r.socialExpensePercentage)(value),
                            socialExpenses: v.socialExpenses,
                            socialExpensePercentage: v.socialExpensePercentage
                        };
                    };
                }),
                align: TacoInput.RightAlign.value,
                currency: true
            });
        }),
        headerJSX: Data_Maybe.Nothing.value
    }, {
        key: "diffInput",
        label: React_Basic_DOM.text("Muutos"),
        width: new TacoTable_Types.Flex(1),
        sortProperty: Data_Maybe.Nothing.value,
        cell: TacoTable_Types.JSXCell.create([  ])(function (r) {
            return TacoInput.numberInput()()(Data_Eq.eqNumber)({
                value: CostEstimation_Calculations.toCurrencyPrecision(r.diff),
                precision: 2,
                onSave: handler(r)(function (value) {
                    return function (v) {
                        return {
                            costClassCode: v.costClassCode,
                            diff: value,
                            initSum: v.initSum,
                            sum: r.targetItemValue + value,
                            sumWithSE: calculateTotalWithSE(r.socialExpensePercentage)(r.targetItemValue + value),
                            socialExpenses: v.socialExpenses,
                            socialExpensePercentage: v.socialExpensePercentage
                        };
                    };
                }),
                align: TacoInput.RightAlign.value,
                currency: true
            });
        }),
        headerJSX: Data_Maybe.Nothing.value
    }, {
        key: "sumWithSEInput",
        label: React_Basic_DOM.text("Summa Sos.kul."),
        width: new TacoTable_Types.Flex(1),
        sortProperty: Data_Maybe.Nothing.value,
        cell: TacoTable_Types.JSXCell.create([  ])(function (r) {
            return TacoInput.numberInput()()(Data_Eq.eqNumber)({
                value: CostEstimation_Calculations.toCurrencyPrecision(r.sumWithSE),
                precision: 2,
                onSave: handler(r)(function (value) {
                    return function (v) {
                        return {
                            costClassCode: v.costClassCode,
                            diff: calculateTotalWithoutSE(r.socialExpensePercentage)(value) - r.targetItemValue,
                            initSum: v.initSum,
                            sum: calculateTotalWithoutSE(r.socialExpensePercentage)(value),
                            sumWithSE: value,
                            socialExpenses: v.socialExpenses,
                            socialExpensePercentage: v.socialExpensePercentage
                        };
                    };
                }),
                align: TacoInput.RightAlign.value,
                currency: true
            });
        }),
        headerJSX: Data_Maybe.Nothing.value
    } ];
};
var component = TofuHooks.mkHookComponent("EditCostGroupItemsTable")(function (props) {
    var summaryRowStyleSet = (function (v) {
        return v.rowStyleSet;
    })(TacoTable_Styles.foldingTableStyleSet);
    var sumWithSE = mappedSumWithPrecision(function (v) {
        return v.sumWithSE;
    })(props.inputs);
    var sum = mappedSumWithPrecision(function (v) {
        return v.sum;
    })(props.inputs);
    var diff = mappedSumWithPrecision(function (v) {
        return v.diff;
    })(props.inputs);
    var summaryData = {
        title: "Yhteens\xe4",
        diff: diff,
        sum: sum,
        sumWithSE: sumWithSE
    };
    var summaryTable = ReactBasicUtils["div'"]("summary-row")([ ReactBasicUtils["div'"]("__TacoTable")([ ReactBasicUtils["div'"]("table-rows")([ summaryRow(summaryRowStyleSet)(summaryData) ]) ]) ]);
    var columns = getTargetItemColumns(props);
    var targetItemsTable = TacoTable.tableSortable(getNameTargetItemsTableSortProps)(eqTargetItemsTableSortProps)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
        reflectSymbol: function () {
            return "targetItemValueWithSocialExpenses";
        }
    })(Data_Eq.eqNumber))()({
        reflectSymbol: function () {
            return "targetItemValueWithSE";
        }
    })(Data_Eq.eqNumber))()({
        reflectSymbol: function () {
            return "targetItemValue";
        }
    })(Data_Eq.eqNumber))()({
        reflectSymbol: function () {
            return "sumWithSE";
        }
    })(Data_Eq.eqNumber))()({
        reflectSymbol: function () {
            return "sum";
        }
    })(Data_Eq.eqNumber))()({
        reflectSymbol: function () {
            return "socialExpenses";
        }
    })(Data_Eq.eqNumber))()({
        reflectSymbol: function () {
            return "socialExpensePercentage";
        }
    })(Types_Unit.eqPercentageOfZeroToOne))()({
        reflectSymbol: function () {
            return "newSum";
        }
    })(Data_Eq.eqNumber))()({
        reflectSymbol: function () {
            return "newSocialExpenses";
        }
    })(Data_Eq.eqNumber))()({
        reflectSymbol: function () {
            return "idx";
        }
    })(Data_Eq.eqInt))()({
        reflectSymbol: function () {
            return "diff";
        }
    })(Data_Eq.eqNumber))()({
        reflectSymbol: function () {
            return "costClassLabel";
        }
    })(Data_Eq.eqString))()({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Types_Newtypes.eqCostClassCode)))(targetItemsTableInstance)(new TacoTable_Types.SortingCriteria(TacoTable_Types.Ascending.value, new Data_Maybe.Just(ByCostClassLabel.value), sortTargetItemsData))(Data_Maybe.Nothing.value)({
        rows: Data_Functor.map(Data_Functor.functorArray)(mkTargetItemsRow(columns)(props.setInputs))(Data_Array.mapWithIndex(mkTargetItemsData(props.inputs))(props.targetItemRows)),
        columns: columns,
        showHeader: true,
        styleSet: targetItemStyleSet
    });
    var targetItemsTableContainer = React_Basic_DOM_Generated.div()({
        children: [ targetItemsTable, summaryTable ]
    });
    var tables = React_Basic_DOM_Generated.div()({
        children: [ targetItemsTableContainer ]
    });
    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_DOM_Generated.div()({
        className: "__EditCostGroupItemsTable",
        children: [ tables ]
    }));
});
module.exports = {
    component: component
};
