import { Button, Combobox, Modal, SelectItem } from "@tocoman/ui";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ComponentGroupsEdit } from "./ComponentGroupsEdit";
import { ImportComponentGroups } from "./ImportComponentGroups";

type ComponentGroupEditModalProps = {
  onClose: () => void;
  onSave: () => void;
  isOpen: boolean;
  projectId: number;
};

export function ComponentGroupEditModal({
  projectId,
  onClose,
  onSave,
  isOpen,
}: ComponentGroupEditModalProps) {
  const { t } = useTranslation("estimation", {
    keyPrefix: "componentGroupEdit",
  });
  const [selectedAction, setSelectedAction] = useState<string>("groupEdit");

  const selections: SelectItem<string>[] = [
    { label: t`groupsEdit`, key: "groupEdit", value: "groupEdit" },
    { label: t`groupsImport`, key: "groupImport", value: "groupImport" },
  ];
  // Determines if we need to use the onSave to reset purescript state or onClose if nothing changed
  const [groupsEdited, setGroupsEdited] = useState<boolean>(false);

  const closeUneditedModal = useCallback(() => {
    onClose();
  }, [onClose]);
  const closeEditedModal = useCallback(() => {
    onSave();
  }, [onSave]);
  const closeModal = useCallback(
    () => (groupsEdited ? closeEditedModal() : closeUneditedModal()),
    [closeEditedModal, closeUneditedModal, groupsEdited]
  );

  const modalActions = useMemo(() => {
    return (
      <div className="ml-auto flex justify-between w-full">
        <Button variant="text" onClick={closeModal}>{t`close`}</Button>
      </div>
    );
  }, [closeModal, t]);

  return (
    <>
      <Modal
        width={750}
        isOpen={isOpen}
        closeModal={closeModal}
        title={t`title`}
        actions={modalActions}
      >
        <Combobox
          items={selections}
          selected={selections.find((s) => s.value === selectedAction) ?? null}
          onValueChange={(value) =>
            setSelectedAction((value?.key as string) ?? "")
          }
        />
        {selectedAction === "groupEdit" && (
          <ComponentGroupsEdit
            projectId={projectId}
            groupsEdited={() => setGroupsEdited(true)}
          />
        )}
        {selectedAction === "groupImport" && (
          <ImportComponentGroups
            projectId={projectId}
            groupsEdited={() => setGroupsEdited(true)}
          />
        )}
      </Modal>
    </>
  );
}
