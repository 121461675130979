// Generated by purs version 0.14.5
"use strict";
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Kishimen = require("../Kishimen/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var InvoicesTab = (function () {
    function InvoicesTab() {

    };
    InvoicesTab.value = new InvoicesTab();
    return InvoicesTab;
})();
var EstimateItemsTab = (function () {
    function EstimateItemsTab() {

    };
    EstimateItemsTab.value = new EstimateItemsTab();
    return EstimateItemsTab;
})();
var ComponentsTab = (function () {
    function ComponentsTab() {

    };
    ComponentsTab.value = new ComponentsTab();
    return ComponentsTab;
})();
var TargetItemsTab = (function () {
    function TargetItemsTab() {

    };
    TargetItemsTab.value = new TargetItemsTab();
    return TargetItemsTab;
})();
var ShowLineDescriptions = (function () {
    function ShowLineDescriptions() {

    };
    ShowLineDescriptions.value = new ShowLineDescriptions();
    return ShowLineDescriptions;
})();
var ShowInvoiceDescriptions = (function () {
    function ShowInvoiceDescriptions() {

    };
    ShowInvoiceDescriptions.value = new ShowInvoiceDescriptions();
    return ShowInvoiceDescriptions;
})();
var AddSelectedEstimateItem = (function () {
    function AddSelectedEstimateItem(value0) {
        this.value0 = value0;
    };
    AddSelectedEstimateItem.create = function (value0) {
        return new AddSelectedEstimateItem(value0);
    };
    return AddSelectedEstimateItem;
})();
var RemoveSelectedEstimateItem = (function () {
    function RemoveSelectedEstimateItem(value0) {
        this.value0 = value0;
    };
    RemoveSelectedEstimateItem.create = function (value0) {
        return new RemoveSelectedEstimateItem(value0);
    };
    return RemoveSelectedEstimateItem;
})();
var ClearSelectedEstimateItems = (function () {
    function ClearSelectedEstimateItems() {

    };
    ClearSelectedEstimateItems.value = new ClearSelectedEstimateItems();
    return ClearSelectedEstimateItems;
})();
var ClearPrevCursorPos = (function () {
    function ClearPrevCursorPos() {

    };
    ClearPrevCursorPos.value = new ClearPrevCursorPos();
    return ClearPrevCursorPos;
})();
var SetMemo = (function () {
    function SetMemo(value0) {
        this.value0 = value0;
    };
    SetMemo.create = function (value0) {
        return new SetMemo(value0);
    };
    return SetMemo;
})();
var AutoSaveMemoWithTimeout = (function () {
    function AutoSaveMemoWithTimeout() {

    };
    AutoSaveMemoWithTimeout.value = new AutoSaveMemoWithTimeout();
    return AutoSaveMemoWithTimeout;
})();
var SetMemoAutoSaveTimeout = (function () {
    function SetMemoAutoSaveTimeout(value0) {
        this.value0 = value0;
    };
    SetMemoAutoSaveTimeout.create = function (value0) {
        return new SetMemoAutoSaveTimeout(value0);
    };
    return SetMemoAutoSaveTimeout;
})();
var UpdateMemoWithEvalAndSaveTimeout = (function () {
    function UpdateMemoWithEvalAndSaveTimeout(value0) {
        this.value0 = value0;
    };
    UpdateMemoWithEvalAndSaveTimeout.create = function (value0) {
        return new UpdateMemoWithEvalAndSaveTimeout(value0);
    };
    return UpdateMemoWithEvalAndSaveTimeout;
})();
var SetMemoAndPrevCursorPos = (function () {
    function SetMemoAndPrevCursorPos(value0) {
        this.value0 = value0;
    };
    SetMemoAndPrevCursorPos.create = function (value0) {
        return new SetMemoAndPrevCursorPos(value0);
    };
    return SetMemoAndPrevCursorPos;
})();
var SetMemoAndMemoEvalTimeout = (function () {
    function SetMemoAndMemoEvalTimeout(value0) {
        this.value0 = value0;
    };
    SetMemoAndMemoEvalTimeout.create = function (value0) {
        return new SetMemoAndMemoEvalTimeout(value0);
    };
    return SetMemoAndMemoEvalTimeout;
})();
var SetCurrentTab = (function () {
    function SetCurrentTab(value0) {
        this.value0 = value0;
    };
    SetCurrentTab.create = function (value0) {
        return new SetCurrentTab(value0);
    };
    return SetCurrentTab;
})();
var ResetSelectedResources = (function () {
    function ResetSelectedResources() {

    };
    ResetSelectedResources.value = new ResetSelectedResources();
    return ResetSelectedResources;
})();
var ToggleSelectedResources = (function () {
    function ToggleSelectedResources(value0) {
        this.value0 = value0;
    };
    ToggleSelectedResources.create = function (value0) {
        return new ToggleSelectedResources(value0);
    };
    return ToggleSelectedResources;
})();
var AddSelectedResources = (function () {
    function AddSelectedResources(value0) {
        this.value0 = value0;
    };
    AddSelectedResources.create = function (value0) {
        return new AddSelectedResources(value0);
    };
    return AddSelectedResources;
})();
var ToggleComponentsFolded = (function () {
    function ToggleComponentsFolded(value0) {
        this.value0 = value0;
    };
    ToggleComponentsFolded.create = function (value0) {
        return new ToggleComponentsFolded(value0);
    };
    return ToggleComponentsFolded;
})();
var SetMoveSelectedResourcesDropdown = (function () {
    function SetMoveSelectedResourcesDropdown(value0) {
        this.value0 = value0;
    };
    SetMoveSelectedResourcesDropdown.create = function (value0) {
        return new SetMoveSelectedResourcesDropdown(value0);
    };
    return SetMoveSelectedResourcesDropdown;
})();
var textareaClassName = "text-area-class-name";
var genericDescriptionDisplay = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return ShowLineDescriptions.value;
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return ShowInvoiceDescriptions.value;
        };
        throw new Error("Failed pattern match at CostGroupDetails.Types (line 103, column 1 - line 103, column 74): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof ShowLineDescriptions) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof ShowInvoiceDescriptions) {
            return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
        };
        throw new Error("Failed pattern match at CostGroupDetails.Types (line 103, column 1 - line 103, column 74): " + [ x.constructor.name ]);
    }
};
var tofuJSONDescriptionDisplay = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ShowInvoiceDescriptions";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ShowLineDescriptions";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ShowInvoiceDescriptions";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ShowLineDescriptions";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))(genericDescriptionDisplay)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ShowLineDescriptions";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ShowInvoiceDescriptions";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ShowLineDescriptions";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ShowInvoiceDescriptions";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericDescriptionDisplay)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ShowLineDescriptions";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ShowInvoiceDescriptions";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ShowLineDescriptions";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ShowInvoiceDescriptions";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ShowInvoiceDescriptions";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ShowLineDescriptions";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ShowInvoiceDescriptions";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ShowLineDescriptions";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))
};
var eqTabView = {
    eq: function (x) {
        return function (y) {
            if (x instanceof InvoicesTab && y instanceof InvoicesTab) {
                return true;
            };
            if (x instanceof EstimateItemsTab && y instanceof EstimateItemsTab) {
                return true;
            };
            if (x instanceof ComponentsTab && y instanceof ComponentsTab) {
                return true;
            };
            if (x instanceof TargetItemsTab && y instanceof TargetItemsTab) {
                return true;
            };
            return false;
        };
    }
};
var eqDescriptionDisplay = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ShowLineDescriptions && y instanceof ShowLineDescriptions) {
                return true;
            };
            if (x instanceof ShowInvoiceDescriptions && y instanceof ShowInvoiceDescriptions) {
                return true;
            };
            return false;
        };
    }
};
module.exports = {
    AddSelectedEstimateItem: AddSelectedEstimateItem,
    RemoveSelectedEstimateItem: RemoveSelectedEstimateItem,
    ClearSelectedEstimateItems: ClearSelectedEstimateItems,
    ClearPrevCursorPos: ClearPrevCursorPos,
    SetMemo: SetMemo,
    AutoSaveMemoWithTimeout: AutoSaveMemoWithTimeout,
    SetMemoAutoSaveTimeout: SetMemoAutoSaveTimeout,
    UpdateMemoWithEvalAndSaveTimeout: UpdateMemoWithEvalAndSaveTimeout,
    SetMemoAndPrevCursorPos: SetMemoAndPrevCursorPos,
    SetMemoAndMemoEvalTimeout: SetMemoAndMemoEvalTimeout,
    SetCurrentTab: SetCurrentTab,
    ResetSelectedResources: ResetSelectedResources,
    ToggleSelectedResources: ToggleSelectedResources,
    AddSelectedResources: AddSelectedResources,
    ToggleComponentsFolded: ToggleComponentsFolded,
    SetMoveSelectedResourcesDropdown: SetMoveSelectedResourcesDropdown,
    textareaClassName: textareaClassName,
    InvoicesTab: InvoicesTab,
    EstimateItemsTab: EstimateItemsTab,
    ComponentsTab: ComponentsTab,
    TargetItemsTab: TargetItemsTab,
    ShowLineDescriptions: ShowLineDescriptions,
    ShowInvoiceDescriptions: ShowInvoiceDescriptions,
    eqTabView: eqTabView,
    eqDescriptionDisplay: eqDescriptionDisplay,
    genericDescriptionDisplay: genericDescriptionDisplay,
    tofuJSONDescriptionDisplay: tofuJSONDescriptionDisplay
};
