// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Components_TsFromPurs = require("../Components.TsFromPurs/index.js");
var sections = [ {
    title: "TypeScript from PureScript",
    view: [ Components_TsFromPurs.component({}) ],
    code: "tsFromPurs { initValue: notNull 42.0 }"
}, {
    title: "PureScript from TypeScript",
    view: [ $foreign.pursFromTs({}) ],
    code: "<PursFromTs initValue={42} />"
} ];
var demoView = {
    sections: sections,
    title: "InteropExample",
    description: "TypeScript / PureScript interop examples. Check the PursFromTs / TsFromPurs directories for more information."
};
module.exports = {
    demoView: demoView
};
