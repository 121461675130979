// Generated by purs version 0.14.5
"use strict";
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Op = require("../Data.Op/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Foreign = require("../Foreign/index.js");
var Foreign_Object = require("../Foreign.Object/index.js");
var Options = function (x) {
    return x;
};
var semigroupOptions = Data_Semigroup.semigroupArray;
var options = function (v) {
    return Foreign.unsafeToForeign(Foreign_Object.fromFoldable(Data_Foldable.foldableArray)(v));
};
var newtypeOptions = {
    Coercible0: function () {
        return undefined;
    }
};
var monoidOptions = Data_Monoid.monoidArray;
var defaultToOptions = function (k) {
    return function (v) {
        return [ new Data_Tuple.Tuple(k, Foreign.unsafeToForeign(v)) ];
    };
};
var opt = function ($4) {
    return Data_Op.Op(defaultToOptions($4));
};
var assoc = Data_Newtype.unwrap();
var optional = function (option) {
    return Data_Op.Op(Data_Maybe.maybe(Data_Monoid.mempty(monoidOptions))(function (v) {
        return assoc(option)(v);
    }));
};
var tag = function (o) {
    return function (value) {
        return function (v) {
            return assoc(o)(value);
        };
    };
};
module.exports = {
    Options: Options,
    options: options,
    assoc: assoc,
    optional: optional,
    opt: opt,
    tag: tag,
    defaultToOptions: defaultToOptions,
    newtypeOptions: newtypeOptions,
    semigroupOptions: semigroupOptions,
    monoidOptions: monoidOptions
};
