// Generated by purs version 0.14.5
"use strict";
var AddProjectBtn = require("../AddProjectBtn/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var noProjectsFoundMsg = function (v) {
    return React_Basic_DOM_Generated.div()({
        className: "no-projects-found",
        children: [ React_Basic_DOM_Generated.h3_([ React_Basic_DOM.text("Ei l\xf6ydetty hankkeita. \ud83d\ude15") ]), React_Basic_DOM_Generated.p_([ React_Basic_DOM.text("Sinulla ei ehk\xe4 ole riitt\xe4vi\xe4 oikeuksia.") ]), AddProjectBtn.component({
            mode: AddProjectBtn.AddProjectBtnCostControl.value
        }) ]
    });
};
module.exports = {
    noProjectsFoundMsg: noProjectsFoundMsg
};
