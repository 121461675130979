import { UpdateActionStatus } from "../../../../ts-bindings/UpdateActionStatus";
import { useCallback, useState } from "react";
import { useOnEq } from "../../hooks/useOnEq";
import { has } from "lodash";

export const useCloseModalOnSaveComplete = (
  closeModal: (savedProjectId: number) => void,
  status: UpdateActionStatus,
  currentProjectId?: number
) => {
  const [projectId, setProjectId] = useState<number | null>(null);

  if (
    typeof status === "object" &&
    "ResolvedValue" in status &&
    projectId === null
  ) {
    const projectId = status.ResolvedValue;
    setProjectId(projectId);
  }

  const createComplete = useCallback(() => {
    projectId && closeModal(projectId);
  }, [closeModal, projectId]);

  useOnEq(has(status, "ResolvedValue"), true, createComplete);
  useOnEq(
    status,
    "Resolved",
    () => currentProjectId && closeModal(currentProjectId)
  );
};
