import { Controller, useFormContext } from "react-hook-form";
import { Button, Header, IconLtV1, ToggleVisibilityButton } from "@tocoman/ui";
import React, { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { componentMap, ParamsForARJS } from "./AllReportParameters";
import { ReportDesignerForm } from "./ReportDesigner";
import { MultivalueStringCombobox } from "../../../components/MultivalueStringCombobox";
import { FullProjectData } from "../../Estimation/Report/Report";
import { TranslatedMultivalueCombobox } from "../../../components/TranslatedMultivalueCombobox";

export type ReportParameter = {
  AllowBlank: boolean;
  DataType: string;
  Name: string;
  Hidden: boolean;
  MultiValue?: undefined;
  DefaultValue?: undefined;
};

export type ReportDataSet = {
  FilterExpression: string;
  Operator: string;
  FilterValues: string[];
  ParameterName: string;
};

export type DesignerFormProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  savedReportTemplate: any;
  setParametersData: (parameters: ParamsForARJS[]) => void;
  setDataSource: (dataSource: string[]) => void;
  reportName?: string;
  projectData?: FullProjectData;
  setTemplateEdited: (edited: boolean) => void;
};

export const DesignerParameterForm = ({
  savedReportTemplate,
  setDataSource,
  setParametersData,
  reportName,
  projectData,
  setTemplateEdited,
}: DesignerFormProps) => {
  const { t } = useTranslation("reports", {
    keyPrefix: "activeReportsParameters",
  });
  const targetRef = useRef<HTMLDivElement | null>(null);

  const { handleSubmit, watch, setValue } = useFormContext<
    ReportDesignerForm
  >();

  const initialDataSets = savedReportTemplate?.DataSets ?? [];
  const initialParameters = savedReportTemplate?.ReportParameters ?? [];

  const savedDataSources = useMemo(() => {
    return initialDataSets
      .map((ds: { Query: { DataSourceName: string } }) => {
        return ds.Query.DataSourceName.replace(/JSON$/, "");
      })
      .filter((ds: string) => !ds.startsWith("$dataset:"));
  }, [initialDataSets]);

  const savedParameters = useMemo(() => {
    return initialParameters.map((param: ReportParameter) => {
      return {
        key: param.Name,
        label: t(param.Name),
        value: param.Name,
      };
    });
  }, [initialParameters]);

  const dataSources = [
    "Components",
    "ProjectDetails",
    "SubProjects",
    "Locations",
    "CostClasses",
    "Elements",
    "Measurements",
  ];

  const selectedParameters = watch("parameters");

  useEffect(() => {
    if (savedDataSources) {
      setValue("dataSources", savedDataSources);
    }
    if (savedParameters) {
      setValue(
        "parameters",
        savedParameters.filter(
          (param: { key: string }) => param.key !== "currency"
        )
      );
    }
  }, [savedParameters, savedDataSources, setValue]);

  const allParameters: {
    label: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: React.ComponentType<any>;
    key: string;
  }[] = useMemo(
    () =>
      Object.entries(componentMap).map(([key, value]) => ({
        label: t(key),
        value,
        key,
      })),
    [selectedParameters]
  );

  const parameters = useMemo(() => {
    return allParameters.filter((param) =>
      selectedParameters?.some((savedParam) => savedParam.key === param.key)
    );
  }, [allParameters]);

  const parameterComponents = useMemo(() => {
    return projectData
      ? parameters.map((param, index) => {
          const Component = param.value;
          const componentParams = {
            locations: projectData.locations?.map((location) => location.code),
            subProjects: projectData.subProjects?.map(
              (subProject) => subProject.code
            ),
            projectName: projectData.projectData?.name,
            reportHeader: "Kustannuslaskelma",
            measurements: projectData.measurements,
          };
          return <Component key={index} {...componentParams} />;
        })
      : null;
  }, [parameters, projectData, savedParameters]);

  const handlePreviewSubmit = (data: ReportDesignerForm, toggle: boolean) => {
    if (toggle && targetRef.current?.style.display === "block") {
      return;
    }
    const dataArray = allParameters
      .filter((param) => data.parameters?.some((v) => v.key === param.key))
      .map((param) => {
        const key = param.key;

        if (key === "rowType") {
          return {
            Name: key,
            Value: data.rowType?.map((item) => item.value),
          };
        }

        if (key === "measurements") {
          return {
            Name: key,
            Value: data.measurements?.map((item) => item.value),
          };
        }

        return {
          Name: key,
          Value: data[key as keyof ReportDesignerForm],
        };
      });

    setParametersData(dataArray as ParamsForARJS[]);
    if (data.dataSources) {
      setDataSource(data.dataSources);
    }
    setTemplateEdited(true);
  };

  return (
    <div className="flex w-full h-full">
      <div
        ref={targetRef}
        className={"min-h-full max-h-full h-full overflow-y-auto"}
      >
        <div className="flex flex-row gap-3 w-[600px] p-3 min-h-full ">
          <div className="flex flex-col gap-3 w-1/2 min-h-full pt-3">
            <>
              <Header titleSize={"small"} title={reportName} />
              <>
                <Controller
                  name={"dataSources"}
                  render={({ field: { onChange, value = [] } }) => (
                    <MultivalueStringCombobox
                      label={t("dataSources")}
                      initialValue={value}
                      items={dataSources}
                      onValueChange={onChange}
                    />
                  )}
                />
                <Controller
                  name={"parameters"}
                  render={({ field: { onChange, value = [] } }) => (
                    <TranslatedMultivalueCombobox
                      label={t("parameters")}
                      items={allParameters}
                      onValueChange={onChange}
                      selectedItems={value}
                    />
                  )}
                />

                <Button
                  className="w-48 mt-3"
                  onClick={handleSubmit((d) => handlePreviewSubmit(d, false))}
                  label={t("setSettings")}
                />
              </>
            </>
          </div>
          <div className="flex flex-col gap-5 w-1/2 m-3 h-full">
            <div className="flex flex-col gap-3 m-3 pr-[12px] w-full h-full">
              <div className="flex flex-col gap-3 p-3 w-full h-full border-light rounded border">
                {parameterComponents}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"border-l border-l-light h-full z-50"}>
        <ToggleVisibilityButton
          icon={IconLtV1}
          targetRef={targetRef}
          className={"ml-[-12px] mt-3"}
          onClick={handleSubmit((d) => handlePreviewSubmit(d, true))}
        />
      </div>
    </div>
  );
};
