import { ExcelDataType } from "ag-grid-community";

export const excelStyles = [
  {
    id: "headerGroup",
    font: {
      bold: true,
    },
  },
  {
    id: "header",
    font: {
      bold: true,
    },
  },
  {
    id: "size15",
    font: { size: 15 },
  },
  {
    id: "size20bold",
    font: { size: 20, bold: true },
  },
  {
    id: "size12",
    font: { size: 12 },
  },
  {
    id: "bold",
    font: { bold: true },
  },
  {
    id: "normal",
    font: { bold: false },
  },
  {
    id: "numberType",
    numberFormat: {
      format: "0",
    },
  },
  {
    id: "decimalType",
    numberFormat: {
      format: "### ### ### ##0.00",
    },
  },
  {
    id: "moneyFormat",
    numberFormat: {
      format: '### ### ### ##0.00 "€"',
    },
  },
  {
    id: "percentageFormat",
    numberFormat: {
      format: "##0.00 %",
    },
  },
  {
    id: "dateType",
    dataType: "DateTime" as ExcelDataType,
    numberFormat: {
      format: "dd.mm.yyyy",
    },
  },
];
