import {
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";
import { Combobox, SelectItem } from "@tocoman/ui";

export type ControlledComboboxProps<
  F extends FieldValues,
  T
> = UseControllerProps<F> & {
  /**
   * Label for combobox. Doesn't get drawn if not set
   */
  label?: string;

  /**
   * List of options for the combobox component
   */
  items: SelectItem<T>[];

  /**
   * Callback function to handle filtering. This callback should be used to filter the combobox items.
   */
  filterHandler?: (query: string) => void;

  /**
   * Callback function to handle value change.
   */
  onChange?: (value: SelectItem<T> | null) => void;
};

/**
 * React Hook Forms wrapper for Combobox component.
 * Usage:
 * ```tsx
 * const { control } = useForm();
 * const items = [
 *  { key: "1", value: "1", label: "One" },
 *  { key: "2", value: "2", label: "Two" },
 *  ];
 *  return (
 *  <ControlledCombobox
 *  control={control}
 *  name="combobox"
 *  label="Combobox"
 *  items={items}
 *  />
 * @param props
 * @constructor
 */
export function ControlledCombobox<F extends FieldValues, T>(
  props: ControlledComboboxProps<F, T>
) {
  const { field } = useController(props);
  const { label, items, filterHandler, onChange } = props;

  const selected = items.find((item) => item.value === field.value) ?? null;

  return (
    <Combobox
      label={label}
      items={items}
      filterHandler={filterHandler}
      onValueChange={(selectedItem) => {
        field.onChange(selectedItem?.value);
        onChange?.(selectedItem);
      }}
      selected={selected}
    />
  );
}
