import { useGetPlanningCostGroups } from "./hooks/useGetPlanningCostGroups";
import { StringCombobox } from "../../../components/StringCombobox";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { useEditCostGroup } from "./hooks/useEditCostGroup";
import { useModalActionButtons } from "./hooks/useModalActionButtons";
import { useRunOnce } from "../../../hooks/useRunOnce";

type EditCostGroupParams = {
  projectId: number;
  onSave: () => void;
  closeModal: () => void;
  setActionButtons: (actions: JSX.Element) => void;
  componentIds: number[];
};
export const EditCostGroup = ({
  projectId,
  onSave,
  setActionButtons,
  closeModal,
  componentIds,
}: EditCostGroupParams) => {
  const { t } = useTranslation("estimation", {
    keyPrefix: "componentView.editMultipleComponentsModal",
  });

  const editCostGroup = useEditCostGroup(projectId, onSave);

  const costGroups = useGetPlanningCostGroups(projectId);

  const methods = useForm<{ newCostGroup: string }>();
  const { control, handleSubmit } = methods;

  const costGroupCodes = costGroups?.map(
    (group) => `${group.code} - ${group.description}`
  );

  const handleSave = (data: { newCostGroup: string }) => {
    const values = componentIds.map((component) => {
      const newCostGroupCode = data.newCostGroup.split(" - ")[0];
      return {
        componentId: component,
        costGroupCode: newCostGroupCode,
      };
    });
    const editCostGroupData = {
      type: "PlanningSetComponentCostGroups",
      value: values,
    };
    editCostGroup.mutate(editCostGroupData);
  };

  const setActions = useModalActionButtons(
    setActionButtons,
    handleSubmit(handleSave),
    closeModal
  );

  useRunOnce(() => {
    setActions(true);
  });

  return (
    <>
      {t`form.editCostGroupDescription`}

      <div className={"w-[300px]"}>
        <Controller
          control={control}
          name={"newCostGroup"}
          render={({ field: { onChange, value } }) => (
            <StringCombobox
              label={t`form.costGroup`}
              items={costGroupCodes}
              onValueChange={(value) => {
                setActions(value === null);
                onChange(value);
              }}
              initialValue={value}
            />
          )}
        />
      </div>
    </>
  );
};
