// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Effect_Class = require("../Effect.Class/index.js");
var Foreign_Object = require("../Foreign.Object/index.js");
var logError$prime = function (dictMonadEffect) {
    return function (dictHomogeneous) {
        return function (r) {
            return Effect_Class.liftEffect(dictMonadEffect)($foreign["_reportError"](r.error)(Foreign_Object.fromHomogeneous()(r.values)));
        };
    };
};
var logError = function (dictMonadEffect) {
    var $4 = Effect_Class.liftEffect(dictMonadEffect);
    return function ($5) {
        return $4($foreign["_logError"]($5));
    };
};
var log = function (dictMonadEffect) {
    var $6 = Effect_Class.liftEffect(dictMonadEffect);
    return function ($7) {
        return $6($foreign["_log"]($7));
    };
};
module.exports = {
    log: log,
    logError: logError,
    "logError'": logError$prime,
    setUser: $foreign.setUser
};
