import { Header } from "@tocoman/ui";
import { ChangeEvent } from "react";
import { NavbarRadioGroup } from "./components/NavbarRadioGroup";
import { useWorkerAction } from "../../hooks/useWorkerAction";
import { mkChangeOrganizationAction } from "../../actions/navigation";
import { useTranslation } from "react-i18next";
import { useGetUsersOrgsData } from "./useGetUsersOrgsDataQuery";
import "./style.css";
import { UserDataWithPermissions } from "../../../server-ts/user/UserRouter";

type OrganizationsProps = {
  userData: UserDataWithPermissions | undefined;
};

export const Organizations = ({ userData }: OrganizationsProps) => {
  const { t } = useTranslation("navigation", { keyPrefix: "organization" });
  const isAdmin = userData?.permissions.isAdmin ?? false;
  const usersOrganizationsData = useGetUsersOrgsData();
  const items =
    usersOrganizationsData?.organizations.map((organization, index) => {
      return {
        value: organization?.name,
        label: organization?.companyName ?? organization?.name,
        description: organization?.description ?? organization?.plan,
        key: `${organization?.name}-${index}`,
        checked:
          organization?.name === usersOrganizationsData?.currentOrganization,
      };
    }) ?? [];
  const [_, changeOrganization] = useWorkerAction(
    "NavigationActionState",
    (organization: string) => {
      return mkChangeOrganizationAction({ organization });
    }
  );

  const onValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const selected = items.find((item) => item.value === value);
    if (selected?.value) {
      changeOrganization(selected.value);
      // Not using useFrontEndNavigation here so the navigation bar will update that the switch app icon visibility is updated
      if (userData?.permissions.costControlPermission) {
        window.location.href = "/cost-control";
      } else {
        window.location.href = "/estimation";
      }
    }
  };

  return (
    <div className={"w-full"}>
      <Header
        titleSize={"small"}
        className={"uppercase text-[#192434] p-3"}
      >{t`selectOrganization`}</Header>
      <NavbarRadioGroup
        items={items}
        onValueChange={onValueChange}
        isAdmin={isAdmin}
      />
    </div>
  );
};
