/* eslint-disable i18next/no-literal-string */
import { useCallback, useState } from "react";

type Props = {
  initValue?: number;
};

export const TsFromPurs = (props: Props) => {
  const [counter, setCounter] = useState(props.initValue ?? 0);

  const increment = useCallback(() => {
    setCounter((counter) => counter + 1);
  }, []);

  const text = `Counter value: ${counter}`;
  return (
    <>
      <div style={{ backgroundColor: "greenyellow" }}>{text}</div>

      <button onClick={increment}>Increment</button>
    </>
  );
};
