exports._confirm = function (msg) {
  return function () {
    return window.confirm(msg);
  };
};

exports._prompt = function (msg) {
  return function (defaultValue) {
    return function () {
      return window.prompt(msg, defaultValue);
    };
  };
};

exports.setWindow = function (name) {
  return function (value) {
    return function () {
      window[name] = value;
    };
  };
};

exports.selectionRemoveAllRanges = function () {
  try {
    document.getSelection().removeAllRanges();
  } catch (e) {
    console.error(e);
  }
};
