// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Control_Monad_Except = require("../Control.Monad.Except/index.js");
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Foreign = require("../Foreign/index.js");
var Foreign_Index = require("../Foreign.Index/index.js");
var Record_Builder = require("../Record.Builder/index.js");
var Type_Data_RowList = require("../Type.Data.RowList/index.js");
var writeParamString = {
    writeParam: function (a) {
        return a;
    }
};
var writeParamNumber = {
    writeParam: Data_Show.show(Data_Show.showNumber)
};
var writeParamInt = {
    writeParam: Data_Show.show(Data_Show.showInt)
};
var writeParam = function (dict) {
    return dict.writeParam;
};
var stringReadParam = {
    "readParam'": Foreign.readString(Data_Identity.monadIdentity)
};
var readParamFields = function (dict) {
    return dict.readParamFields;
};
var recordReadParam = function (dictRowToList) {
    return function (dictReadParamFields) {
        return {
            "readParam'": function (f) {
                return Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Data_Functor.flap(Data_Functor.functorFn)(Record_Builder.build)({}))(readParamFields(dictReadParamFields)(Type_Data_RowList.RLProxy.value)(f));
            }
        };
    };
};
var readParam$prime = function (dict) {
    return dict["readParam'"];
};
var readParam = function (dictReadParam) {
    var $25 = readParam$prime(dictReadParam);
    return function ($26) {
        return Control_Monad_Except.runExcept($25($26));
    };
};
var readMaybe = function (dictReadParam) {
    return {
        "readParam'": (function () {
            var readNullOrUndefined = function (v) {
                return function (value) {
                    if (Foreign.isNull(value) || Foreign.isUndefined(value)) {
                        return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Data_Maybe.Nothing.value);
                    };
                    return Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Data_Maybe.Just.create)(v(value));
                };
            };
            return readNullOrUndefined(readParam$prime(dictReadParam));
        })()
    };
};
var numberReadParam = {
    "readParam'": Foreign.readNumber(Data_Identity.monadIdentity)
};
var nilReadLevel = {
    readParamFields: function (v) {
        return function (v1) {
            return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Control_Category.identity(Record_Builder.categoryBuilder));
        };
    }
};
var intReadParam = {
    "readParam'": function (f) {
        return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(Foreign.readString(Data_Identity.monadIdentity)(f))(function (s) {
            var v = Data_Int.fromString(s);
            if (v instanceof Data_Maybe.Just) {
                return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(v.value0);
            };
            if (v instanceof Data_Maybe.Nothing) {
                return Control_Monad_Error_Class.throwError(Control_Monad_Except_Trans.monadThrowExceptT(Data_Identity.monadIdentity))(Control_Applicative.pure(Data_List_Types.applicativeNonEmptyList)(Foreign.ForeignError.create("could not parse " + (s + " into integer"))));
            };
            throw new Error("Failed pattern match at ReadParam (line 31, column 5 - line 34, column 86): " + [ v.constructor.name ]);
        });
    }
};
var consReadLevelSection = function (dictIsSymbol) {
    return function (dictCons) {
        return function (dictLacks) {
            return function (dictReadParam) {
                return function (dictReadParamFields) {
                    return {
                        readParamFields: function (v) {
                            return function (f) {
                                var name = Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value);
                                return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(Control_Bind.bindFlipped(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(readParam$prime(dictReadParam))(Foreign_Index.readProp(Data_Identity.monadIdentity)(name)(f)))(function (v1) {
                                    return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(readParamFields(dictReadParamFields)(Type_Data_RowList.RLProxy.value)(f))(function (rest) {
                                        var first = Record_Builder.insert()()(dictIsSymbol)(Data_Symbol.SProxy.value)(v1);
                                        return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Control_Semigroupoid.compose(Record_Builder.semigroupoidBuilder)(first)(rest));
                                    });
                                });
                            };
                        }
                    };
                };
            };
        };
    };
};
var booleanReadParam = {
    "readParam'": function (f) {
        return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(Foreign.readString(Data_Identity.monadIdentity)(f))(function (s) {
            if (s === "true") {
                return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(true);
            };
            if (s === "false") {
                return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(false);
            };
            return Control_Monad_Error_Class.throwError(Control_Monad_Except_Trans.monadThrowExceptT(Data_Identity.monadIdentity))(Control_Applicative.pure(Data_List_Types.applicativeNonEmptyList)(new Foreign.TypeMismatch("Boolean", s)));
        });
    }
};
module.exports = {
    "readParam'": readParam$prime,
    readParamFields: readParamFields,
    writeParam: writeParam,
    readParam: readParam,
    stringReadParam: stringReadParam,
    intReadParam: intReadParam,
    numberReadParam: numberReadParam,
    booleanReadParam: booleanReadParam,
    readMaybe: readMaybe,
    recordReadParam: recordReadParam,
    nilReadLevel: nilReadLevel,
    consReadLevelSection: consReadLevelSection,
    writeParamString: writeParamString,
    writeParamInt: writeParamInt,
    writeParamNumber: writeParamNumber
};
