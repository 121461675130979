// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var Roles_Types = require("../Roles.Types/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var form = TofuHooks.mkHookComponent("EditUserRoleForm")(function (props) {
    var roleDeleteSection = (function () {
        if (props.roleId instanceof Data_Maybe.Just && props.onRoleDelete instanceof Data_Maybe.Just) {
            return Box.box("form-section")([ Box.FlexColumn.value ])([ React_Basic_DOM_Generated.h2_(Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM.text("Roolin poistaminen"))), React_Basic_DOM_Generated.p_(Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM.text("Kun rooli poistetaan, se poistuu my\xf6s kaikilta k\xe4ytt\xe4jilt\xe4, joilla rooli on. Poistamisen j\xe4lkeen et voi en\xe4\xe4 palauttaa poistettua roolia."))), TacoButton.component()()({
                text: "Poista rooli",
                buttonStyle: TacoButton_Types.Outlined.value,
                color: TacoConstants.SecondaryRed.value,
                onClick: Data_Maybe.Just.create(props.onRoleDelete.value0(props.roleId.value0))
            }) ]);
        };
        return React_Basic.empty;
    })();
    var role = Data_Newtype.un()(Roles_Types.UserRole)(props.role);
    var roleNameSection = (function () {
        if (props.displayRoleName) {
            return ReactBasicUtils["div'"]("form-section")([ TacoText.component()()({
                text: "Roolin nimi",
                variant: new TacoText_Types.Heading(2),
                weight: TacoText_Types.Bold.value,
                gutterBottom: true
            }), TacoInput.stringInput()()(Data_Eq.eqString)({
                value: role.name,
                onChange: function (getName) {
                    return props.onRoleUpdate(function (v) {
                        return Roles_Types.UserRole({
                            name: getName(role.name),
                            createProjectsPermission: role.createProjectsPermission,
                            isAdmin: role.isAdmin,
                            costControlPermission: role.costControlPermission,
                            costGroupTargetWritePermission: role.costGroupTargetWritePermission,
                            ccReadPermission: role.ccReadPermission,
                            ccWritePermission: role.ccWritePermission,
                            ccReadAllProjectsPermission: role.ccReadAllProjectsPermission,
                            ccWriteAllProjectsPermission: role.ccWriteAllProjectsPermission,
                            costEstimationPermission: role.costEstimationPermission,
                            ceReadPermission: role.ceReadPermission,
                            ceWritePermission: role.ceWritePermission,
                            ceReadAllProjectsPermission: role.ceReadAllProjectsPermission,
                            ceWriteAllProjectsPermission: role.ceWriteAllProjectsPermission
                        });
                    });
                }
            }) ]);
        };
        return React_Basic.empty;
    })();
    var mkOnToggle = function (f) {
        return TacoCheckbox.NoEvent.create(props.onRoleUpdate(Data_Newtype.over()()(Roles_Types.UserRole)(f)));
    };
    var projectReadAndWritePermissionsSection = React_Basic.fragment([ TacoCheckbox.component()()({
        className: "list-checkbox",
        isChecked: role.ceReadPermission,
        onToggle: mkOnToggle(function (v) {
            return {
                ceReadPermission: !role.ceReadPermission,
                ccReadAllProjectsPermission: v.ccReadAllProjectsPermission,
                ccReadPermission: v.ccReadPermission,
                ccWriteAllProjectsPermission: v.ccWriteAllProjectsPermission,
                ccWritePermission: v.ccWritePermission,
                ceReadAllProjectsPermission: v.ceReadAllProjectsPermission,
                ceWriteAllProjectsPermission: v.ceWriteAllProjectsPermission,
                ceWritePermission: v.ceWritePermission,
                costControlPermission: v.costControlPermission,
                costEstimationPermission: v.costEstimationPermission,
                costGroupTargetWritePermission: v.costGroupTargetWritePermission,
                createProjectsPermission: v.createProjectsPermission,
                isAdmin: v.isAdmin,
                name: v.name
            };
        }),
        labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Kustannuslaskenta: Hankkeen luku sallittu")),
        testId: ""
    }), TacoCheckbox.component()()({
        className: "list-checkbox",
        isChecked: role.ceWritePermission,
        onToggle: mkOnToggle(function (v) {
            return {
                ceReadPermission: v.ceReadPermission,
                ccReadAllProjectsPermission: v.ccReadAllProjectsPermission,
                ccReadPermission: v.ccReadPermission,
                ccWriteAllProjectsPermission: v.ccWriteAllProjectsPermission,
                ccWritePermission: v.ccWritePermission,
                ceReadAllProjectsPermission: v.ceReadAllProjectsPermission,
                ceWriteAllProjectsPermission: v.ceWriteAllProjectsPermission,
                ceWritePermission: !role.ceWritePermission,
                costControlPermission: v.costControlPermission,
                costEstimationPermission: v.costEstimationPermission,
                costGroupTargetWritePermission: v.costGroupTargetWritePermission,
                createProjectsPermission: v.createProjectsPermission,
                isAdmin: v.isAdmin,
                name: v.name
            };
        }),
        labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Kustannuslaskenta: Hankkeen muokkaus sallittu")),
        testId: ""
    }), TacoCheckbox.component()()({
        className: "list-checkbox",
        isChecked: role.ceReadAllProjectsPermission,
        onToggle: mkOnToggle(function (v) {
            return {
                ceReadPermission: v.ceReadPermission,
                ccReadAllProjectsPermission: v.ccReadAllProjectsPermission,
                ccReadPermission: v.ccReadPermission,
                ccWriteAllProjectsPermission: v.ccWriteAllProjectsPermission,
                ccWritePermission: v.ccWritePermission,
                ceReadAllProjectsPermission: !role.ceReadAllProjectsPermission,
                ceWriteAllProjectsPermission: v.ceWriteAllProjectsPermission,
                ceWritePermission: v.ceWritePermission,
                costControlPermission: v.costControlPermission,
                costEstimationPermission: v.costEstimationPermission,
                costGroupTargetWritePermission: v.costGroupTargetWritePermission,
                createProjectsPermission: v.createProjectsPermission,
                isAdmin: v.isAdmin,
                name: v.name
            };
        }),
        labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Kustannuslaskenta: Kaikkien hankkeiden luku sallittu")),
        testId: "read-all-projects-permission-checkbox"
    }), TacoCheckbox.component()()({
        className: "list-checkbox",
        isChecked: role.ceWriteAllProjectsPermission,
        onToggle: mkOnToggle(function (v) {
            return {
                ceReadPermission: v.ceReadPermission,
                ccReadAllProjectsPermission: v.ccReadAllProjectsPermission,
                ccReadPermission: v.ccReadPermission,
                ccWriteAllProjectsPermission: v.ccWriteAllProjectsPermission,
                ccWritePermission: v.ccWritePermission,
                ceReadAllProjectsPermission: v.ceReadAllProjectsPermission,
                ceWriteAllProjectsPermission: !role.ceWriteAllProjectsPermission,
                ceWritePermission: v.ceWritePermission,
                costControlPermission: v.costControlPermission,
                costEstimationPermission: v.costEstimationPermission,
                costGroupTargetWritePermission: v.costGroupTargetWritePermission,
                createProjectsPermission: v.createProjectsPermission,
                isAdmin: v.isAdmin,
                name: v.name
            };
        }),
        labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Kustannuslaskenta: Kaikkien hankkeiden muokkaus sallittu")),
        testId: "write-all-projects-permission-checkbox"
    }), TacoCheckbox.component()()({
        className: "list-checkbox",
        isChecked: role.ccReadPermission,
        onToggle: mkOnToggle(function (v) {
            return {
                ceReadPermission: v.ceReadPermission,
                ccReadAllProjectsPermission: v.ccReadAllProjectsPermission,
                ccReadPermission: !role.ccReadPermission,
                ccWriteAllProjectsPermission: v.ccWriteAllProjectsPermission,
                ccWritePermission: v.ccWritePermission,
                ceReadAllProjectsPermission: v.ceReadAllProjectsPermission,
                ceWriteAllProjectsPermission: v.ceWriteAllProjectsPermission,
                ceWritePermission: v.ceWritePermission,
                costControlPermission: v.costControlPermission,
                costEstimationPermission: v.costEstimationPermission,
                costGroupTargetWritePermission: v.costGroupTargetWritePermission,
                createProjectsPermission: v.createProjectsPermission,
                isAdmin: v.isAdmin,
                name: v.name
            };
        }),
        labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Raportointi: Hankkeen luku sallittu")),
        testId: ""
    }), TacoCheckbox.component()()({
        className: "list-checkbox",
        isChecked: role.ccWritePermission,
        onToggle: mkOnToggle(function (v) {
            return {
                ceReadPermission: v.ceReadPermission,
                ccReadAllProjectsPermission: v.ccReadAllProjectsPermission,
                ccReadPermission: v.ccReadPermission,
                ccWriteAllProjectsPermission: v.ccWriteAllProjectsPermission,
                ccWritePermission: !role.ccWritePermission,
                ceReadAllProjectsPermission: v.ceReadAllProjectsPermission,
                ceWriteAllProjectsPermission: v.ceWriteAllProjectsPermission,
                ceWritePermission: v.ceWritePermission,
                costControlPermission: v.costControlPermission,
                costEstimationPermission: v.costEstimationPermission,
                costGroupTargetWritePermission: v.costGroupTargetWritePermission,
                createProjectsPermission: v.createProjectsPermission,
                isAdmin: v.isAdmin,
                name: v.name
            };
        }),
        labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Raportointi: Hankkeen muokkaus sallittu")),
        testId: ""
    }), TacoCheckbox.component()()({
        className: "list-checkbox",
        isChecked: role.ccReadAllProjectsPermission,
        onToggle: mkOnToggle(function (v) {
            return {
                ceReadPermission: v.ceReadPermission,
                ccReadAllProjectsPermission: !role.ccReadAllProjectsPermission,
                ccReadPermission: v.ccReadPermission,
                ccWriteAllProjectsPermission: v.ccWriteAllProjectsPermission,
                ccWritePermission: v.ccWritePermission,
                ceReadAllProjectsPermission: v.ceReadAllProjectsPermission,
                ceWriteAllProjectsPermission: v.ceWriteAllProjectsPermission,
                ceWritePermission: v.ceWritePermission,
                costControlPermission: v.costControlPermission,
                costEstimationPermission: v.costEstimationPermission,
                costGroupTargetWritePermission: v.costGroupTargetWritePermission,
                createProjectsPermission: v.createProjectsPermission,
                isAdmin: v.isAdmin,
                name: v.name
            };
        }),
        labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Raportointi: Kaikkien hankkeiden luku sallittu")),
        testId: "read-all-projects-permission-checkbox"
    }), TacoCheckbox.component()()({
        className: "list-checkbox",
        isChecked: role.ccWriteAllProjectsPermission,
        onToggle: mkOnToggle(function (v) {
            return {
                ceReadPermission: v.ceReadPermission,
                ccReadAllProjectsPermission: v.ccReadAllProjectsPermission,
                ccReadPermission: v.ccReadPermission,
                ccWriteAllProjectsPermission: !role.ccWriteAllProjectsPermission,
                ccWritePermission: v.ccWritePermission,
                ceReadAllProjectsPermission: v.ceReadAllProjectsPermission,
                ceWriteAllProjectsPermission: v.ceWriteAllProjectsPermission,
                ceWritePermission: v.ceWritePermission,
                costControlPermission: v.costControlPermission,
                costEstimationPermission: v.costEstimationPermission,
                costGroupTargetWritePermission: v.costGroupTargetWritePermission,
                createProjectsPermission: v.createProjectsPermission,
                isAdmin: v.isAdmin,
                name: v.name
            };
        }),
        labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Raportointi: Kaikkien hankkeiden muokkaus sallittu")),
        testId: "write-all-projects-permission-checkbox"
    }), TacoCheckbox.component()()({
        className: "list-checkbox",
        isChecked: role.costGroupTargetWritePermission,
        onToggle: mkOnToggle(function (v) {
            return {
                ceReadPermission: v.ceReadPermission,
                ccReadAllProjectsPermission: v.ccReadAllProjectsPermission,
                ccReadPermission: v.ccReadPermission,
                ccWriteAllProjectsPermission: v.ccWriteAllProjectsPermission,
                ccWritePermission: v.ccWritePermission,
                ceReadAllProjectsPermission: v.ceReadAllProjectsPermission,
                ceWriteAllProjectsPermission: v.ceWriteAllProjectsPermission,
                ceWritePermission: v.ceWritePermission,
                costControlPermission: v.costControlPermission,
                costEstimationPermission: v.costEstimationPermission,
                costGroupTargetWritePermission: !role.costGroupTargetWritePermission,
                createProjectsPermission: v.createProjectsPermission,
                isAdmin: v.isAdmin,
                name: v.name
            };
        }),
        labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Litterakohtaisten tavoitteiden muokkaus sallittu")),
        testId: "write-cost-group-targets-permission-checkbox"
    }) ]);
    var isAdminSection = ReactBasicUtils["div'"]("form-section")([ React_Basic_DOM_Generated.h2_(Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM.text("P\xe4\xe4k\xe4ytt\xe4j\xe4oikeudet"))), React_Basic_DOM_Generated.p_(Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM.text("P\xe4\xe4k\xe4ytt\xe4j\xe4ll\xe4 on kaikki oikeudet projekteihin ja k\xe4ytt\xe4jien oikeuksien muokkaamiseen."))), TacoCheckbox.component()()({
        className: "list-checkbox",
        isChecked: role.isAdmin,
        onToggle: mkOnToggle(function (v) {
            return {
                ceReadPermission: v.ceReadPermission,
                ccReadAllProjectsPermission: v.ccReadAllProjectsPermission,
                ccReadPermission: v.ccReadPermission,
                ccWriteAllProjectsPermission: v.ccWriteAllProjectsPermission,
                ccWritePermission: v.ccWritePermission,
                ceReadAllProjectsPermission: v.ceReadAllProjectsPermission,
                ceWriteAllProjectsPermission: v.ceWriteAllProjectsPermission,
                ceWritePermission: v.ceWritePermission,
                costControlPermission: v.costControlPermission,
                costEstimationPermission: v.costEstimationPermission,
                costGroupTargetWritePermission: v.costGroupTargetWritePermission,
                createProjectsPermission: v.createProjectsPermission,
                isAdmin: !role.isAdmin,
                name: v.name
            };
        }),
        labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("p\xe4\xe4k\xe4ytt\xe4j\xe4oikeudet")),
        testId: "is-admin-checkbox"
    }) ]);
    var createNewProjectPermissionSection = ReactBasicUtils["div'"]("form-section")([ TacoText.component()()({
        text: "Uusien hankkeiden luonti",
        variant: new TacoText_Types.Heading(2),
        weight: TacoText_Types.Bold.value,
        gutterBottom: true
    }), TacoCheckbox.component()()({
        className: "list-checkbox",
        isChecked: role.createProjectsPermission,
        onToggle: mkOnToggle(function (v) {
            return {
                ceReadPermission: v.ceReadPermission,
                ccReadAllProjectsPermission: v.ccReadAllProjectsPermission,
                ccReadPermission: v.ccReadPermission,
                ccWriteAllProjectsPermission: v.ccWriteAllProjectsPermission,
                ccWritePermission: v.ccWritePermission,
                ceReadAllProjectsPermission: v.ceReadAllProjectsPermission,
                ceWriteAllProjectsPermission: v.ceWriteAllProjectsPermission,
                ceWritePermission: v.ceWritePermission,
                costControlPermission: v.costControlPermission,
                costEstimationPermission: v.costEstimationPermission,
                costGroupTargetWritePermission: v.costGroupTargetWritePermission,
                createProjectsPermission: !role.createProjectsPermission,
                isAdmin: v.isAdmin,
                name: v.name
            };
        }),
        labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Hankkeiden luonti sallittu")),
        testId: "create-project-permission-checkbox"
    }) ]);
    var appRightsSection = ReactBasicUtils["div'"]("form-section")([ TacoText.component()()({
        text: "Sovellusten k\xe4ytt\xf6oikeudet",
        variant: new TacoText_Types.Heading(2),
        weight: TacoText_Types.Bold.value,
        gutterBottom: true
    }), TacoCheckbox.component()()({
        className: "list-checkbox",
        isChecked: role.costControlPermission,
        onToggle: mkOnToggle(function (v) {
            return {
                ceReadPermission: v.ceReadPermission,
                ccReadAllProjectsPermission: v.ccReadAllProjectsPermission,
                ccReadPermission: v.ccReadPermission,
                ccWriteAllProjectsPermission: v.ccWriteAllProjectsPermission,
                ccWritePermission: v.ccWritePermission,
                ceReadAllProjectsPermission: v.ceReadAllProjectsPermission,
                ceWriteAllProjectsPermission: v.ceWriteAllProjectsPermission,
                ceWritePermission: v.ceWritePermission,
                costControlPermission: !role.costControlPermission,
                costEstimationPermission: v.costEstimationPermission,
                costGroupTargetWritePermission: v.costGroupTargetWritePermission,
                createProjectsPermission: v.createProjectsPermission,
                isAdmin: v.isAdmin,
                name: v.name
            };
        }),
        labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Raportointi")),
        testId: "cost-control-permission-checkbox"
    }), TacoCheckbox.component()()({
        className: "list-checkbox",
        isChecked: role.costEstimationPermission,
        onToggle: mkOnToggle(function (v) {
            return {
                ceReadPermission: v.ceReadPermission,
                ccReadAllProjectsPermission: v.ccReadAllProjectsPermission,
                ccReadPermission: v.ccReadPermission,
                ccWriteAllProjectsPermission: v.ccWriteAllProjectsPermission,
                ccWritePermission: v.ccWritePermission,
                ceReadAllProjectsPermission: v.ceReadAllProjectsPermission,
                ceWriteAllProjectsPermission: v.ceWriteAllProjectsPermission,
                ceWritePermission: v.ceWritePermission,
                costControlPermission: v.costControlPermission,
                costEstimationPermission: !role.costEstimationPermission,
                costGroupTargetWritePermission: v.costGroupTargetWritePermission,
                createProjectsPermission: v.createProjectsPermission,
                isAdmin: v.isAdmin,
                name: v.name
            };
        }),
        labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Laskenta")),
        testId: "cost-estimation-permission-checkbox"
    }) ]);
    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic.fragment([ roleNameSection, appRightsSection, createNewProjectPermissionSection, TacoText.component()()({
        text: "Hankeoikeudet",
        variant: new TacoText_Types.Heading(2),
        weight: TacoText_Types.Bold.value,
        gutterBottom: true
    }), projectReadAndWritePermissionsSection, isAdminSection, roleDeleteSection ]));
});
var deleteRoleConfirmationModal = function (role) {
    return function (onClose) {
        return function (onDelete) {
            var modalBody = TacoModal.modalBody({
                contents: [ React_Basic_DOM.text("T\xe4t\xe4 toimintoa ei voi peruuttaa. Haluatko varmasti poistaa roolin \"" + ((function (v) {
                    return v.name;
                })(Data_Newtype.un()(Roles_Types.UserRole)(role)) + "\"?")) ]
            });
            var modalActions = TacoModal.modalActions({
                contents: [ TacoButton.component()()({
                    onClick: new Data_Maybe.Just(onClose),
                    text: "Peruuta"
                }), TacoButton.component()()({
                    text: "Poista",
                    color: TacoConstants.SecondaryRed.value,
                    onClick: new Data_Maybe.Just(function __do() {
                        onDelete();
                        return onClose();
                    })
                }) ]
            });
            return TacoModal.component()()({
                isActive: true,
                onClose: onClose,
                heading: "Poista rooli",
                contents: [ modalBody, modalActions ]
            });
        };
    };
};
var modal = TofuHooks.mkHookComponent("EditUserRoleModal")(function (props) {
    return function __do() {
        var v = TofuHooks.useState(props.role)();
        var v1 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var closeDeleteModal = v1.value1(function (v2) {
            return Data_Maybe.Nothing.value;
        });
        var modalActions = TacoModal.modalActions({
            contents: [ TacoButton.component()()({
                onClick: new Data_Maybe.Just(props.onClose),
                text: "Peruuta"
            }), TacoButton.component()()({
                text: "Tallenna",
                buttonStyle: TacoButton_Types.Outlined.value,
                onClick: new Data_Maybe.Just(function __do() {
                    props.onSave(props.roleId)(v.value0)();
                    return props.onClose();
                })
            }) ]
        });
        var editRoleForm = form({
            roleId: props.roleId,
            role: v.value0,
            updateProjectGroupCache: props.dispatch(Actions.UpdateProjectGroupCacheRequest.value),
            onRoleUpdate: v.value1,
            onRoleDelete: Data_Maybe.Just.create(function (id) {
                return v1.value1(function (v2) {
                    return new Data_Maybe.Just(id);
                });
            }),
            displayRoleName: true
        });
        var dispatchRoleDelete = function (id) {
            return function __do() {
                props.dispatch(new Actions.DeleteUserRoleRequest({
                    id: id,
                    organization: props.organization
                }))();
                return props.onClose();
            };
        };
        var deleteModal = (function () {
            if (v1.value0 instanceof Data_Maybe.Nothing) {
                return React_Basic.empty;
            };
            if (v1.value0 instanceof Data_Maybe.Just) {
                return deleteRoleConfirmationModal(v.value0)(closeDeleteModal)(dispatchRoleDelete(v1.value0.value0));
            };
            throw new Error("Failed pattern match at EditUserRole (line 59, column 19 - line 62, column 88): " + [ v1.value0.constructor.name ]);
        })();
        var modalBody = TacoModal.modalBody({
            contents: [ editRoleForm, deleteModal ]
        });
        var _role = Data_Newtype.un()(Roles_Types.UserRole)(props.role);
        return TacoModal.component()()({
            isActive: true,
            onClose: props.onClose,
            heading: (function () {
                if (props.roleId instanceof Data_Maybe.Just) {
                    return "Muokkaa roolia " + _role.name;
                };
                if (props.roleId instanceof Data_Maybe.Nothing) {
                    return "Luo uusi rooli";
                };
                throw new Error("Failed pattern match at EditUserRole (line 89, column 11 - line 91, column 40): " + [ props.roleId.constructor.name ]);
            })(),
            contents: [ modalBody, modalActions ]
        });
    };
});
module.exports = {
    modal: modal,
    form: form,
    deleteRoleConfirmationModal: deleteRoleConfirmationModal
};
