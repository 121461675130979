import { useNotifications } from "../hooks/queries/useNotifications";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Badge,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Popover,
} from "@mui/material";
import React from "react";
import { useReadReleaseNoteIds } from "../hooks/useReadNotifications";
import { useTranslation } from "react-i18next";

export const NotificationPanel = () => {
  const { t } = useTranslation("component", { keyPrefix: "notificationPanel" });

  const notifications = useNotifications();
  const releaseNotes = React.useMemo(
    () => notifications.data?.releaseNotes ?? [],
    [notifications]
  );

  const [readReleaseNoteIds, setReadReleaseNoteIds] = useReadReleaseNoteIds();

  const newNotifications = releaseNotes.find(
    (releaseNote) => !readReleaseNoteIds.includes(releaseNote.id)
  );

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [open, setOpen] = React.useState(false);

  const handleOpen = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen(true);
    },
    []
  );

  const handleClose = React.useCallback(() => {
    setOpen(false);
    setReadReleaseNoteIds(releaseNotes.map((releaseNote) => releaseNote.id));
  }, [setReadReleaseNoteIds, releaseNotes]);

  return (
    <div className="h-full flex flex-row">
      <div className="border-l border-neutral-200 h-full" />
      <IconButton aria-describedby="notification-popover" onClick={handleOpen}>
        <Badge color="primary" variant="dot" invisible={!newNotifications}>
          <CircleNotificationsIcon
            // Match old Header buttons
            sx={{ fontSize: 24, color: "rgb(64, 64, 64)" }}
          />
        </Badge>
      </IconButton>

      <Popover
        open={open}
        id="notification-popover"
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        classes={{
          paper: "flex flex-col",
        }}
      >
        <div className="flex flex-row items-center justify-between mr-4">
          <DialogTitle>{t`announcements`}</DialogTitle>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <List className="flex-1 max-w-3xl overflow-y-auto">
          {releaseNotes.map((releaseNote) => (
            <ListItem key={releaseNote.id}>
              <ListItemButton href={releaseNote.url} target="_blank">
                <ListItemAvatar>
                  <Badge
                    color="primary"
                    variant="dot"
                    invisible={readReleaseNoteIds.includes(releaseNote.id)}
                  >
                    {releaseNote.featuredImageUrl && (
                      <Avatar>
                        <img
                          src={releaseNote.featuredImageUrl}
                          alt={"article-image"}
                        />
                      </Avatar>
                    )}
                  </Badge>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <span
                      dangerouslySetInnerHTML={{ __html: releaseNote.title }}
                    />
                  }
                  secondary={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: releaseNote.description,
                      }}
                    />
                  }
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Popover>
    </div>
  );
};
