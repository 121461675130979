// Generated by purs version 0.14.5
"use strict";
var AppLayout_Styles = require("../AppLayout.Styles/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var Record = require("../Record/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var defaultProps = {
    children: [  ],
    fullWidth: false,
    verticalPadding: false,
    minWidth: Data_Maybe.Nothing.value,
    typescriptChildComponent: false
};
var component = function (dictUnion) {
    return function (dictNub) {
        return TofuHooks.mkHookComponent("NarrowLayout")(function (props) {
            var v = Record.merge()()(props)(defaultProps);
            return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                className: "__NarrowLayout",
                css: AppLayout_Styles.narrowLayoutContainerStyles({
                    fullWidth: v.fullWidth,
                    verticalPadding: v.verticalPadding,
                    minWidth: v.minWidth,
                    typescriptChildComponent: v.typescriptChildComponent
                }),
                children: v.children
            }));
        });
    };
};
module.exports = {
    component: component
};
