// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Controls = require("../Controls/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var FoldingTable_Filter = require("../FoldingTable.Filter/index.js");
var Locale = require("../Locale/index.js");
var NarrowLayout = require("../NarrowLayout/index.js");
var NoProjectsFoundMsg = require("../NoProjectsFoundMsg/index.js");
var PortfolioContainer_Styles = require("../PortfolioContainer.Styles/index.js");
var PortfolioFilterSettings = require("../PortfolioFilterSettings/index.js");
var PortfolioTable = require("../PortfolioTable/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoAlert = require("../TacoAlert/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoLoader = require("../TacoLoader/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_FrontEndRoutes = require("../Types.FrontEndRoutes/index.js");
var UseFetch = require("../UseFetch/index.js");
var UseLocalStorage = require("../UseLocalStorage/index.js");
var portfolioActionFromKey = function (v) {
    if (v === 0) {
        return new Data_Maybe.Just(Actions.PortfolioReportRequest.value);
    };
    return new Data_Maybe.Just(Actions.UpdatePortfolioReportRequest.value);
};
var defaultVisibility = {
    code: FoldingTable_Filter.Visible.value,
    name: FoldingTable_Filter.Visible.value,
    version: FoldingTable_Filter.Hidden.value,
    projectManager: FoldingTable_Filter.Hidden.value,
    targetCosts: FoldingTable_Filter.Visible.value,
    estimatedCosts: FoldingTable_Filter.Visible.value,
    actualCosts: FoldingTable_Filter.Visible.value,
    targetIncome: FoldingTable_Filter.Hidden.value,
    estimatedIncome: FoldingTable_Filter.Hidden.value,
    actualIncome: FoldingTable_Filter.Hidden.value,
    actualIncomeApproved: FoldingTable_Filter.Hidden.value,
    targetDiffAndMargin: FoldingTable_Filter.Hidden.value,
    actualDiffAndMargin: FoldingTable_Filter.Hidden.value,
    actualDiffAndMarginApproved: FoldingTable_Filter.Hidden.value,
    estimatedDiffAndMargin: FoldingTable_Filter.Hidden.value,
    startDate: FoldingTable_Filter.Hidden.value,
    endDate: FoldingTable_Filter.Hidden.value,
    contractType: FoldingTable_Filter.Hidden.value,
    state: FoldingTable_Filter.Hidden.value,
    projectType: FoldingTable_Filter.Hidden.value,
    financingType: FoldingTable_Filter.Hidden.value,
    constructionType: FoldingTable_Filter.Hidden.value,
    contractPrice: FoldingTable_Filter.Hidden.value,
    taxRate: FoldingTable_Filter.Hidden.value,
    ownProject: FoldingTable_Filter.Hidden.value,
    projectGroup: FoldingTable_Filter.Hidden.value
};
var component = TofuHooks.mkHookComponent("PortfolioContainer")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState(FoldingTable_Filter.emptyPortfolioFilterConfig)();
        var v2 = TofuHooks.useState(false)();
        var v3 = TofuHooks.useState("")();
        var v4 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "actualCosts";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "actualDiffAndMargin";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "actualDiffAndMarginApproved";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "actualIncome";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "actualIncomeApproved";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "code";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "constructionType";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "contractPrice";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "contractType";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "endDate";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "estimatedCosts";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "estimatedDiffAndMargin";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "estimatedIncome";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "financingType";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "name";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "ownProject";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "projectGroup";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "projectManager";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "projectType";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "startDate";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "state";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "targetCosts";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "targetDiffAndMargin";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "targetIncome";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "taxRate";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "version";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "actualCosts";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "actualDiffAndMargin";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "actualDiffAndMarginApproved";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "actualIncome";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "actualIncomeApproved";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "code";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "constructionType";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "contractPrice";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "contractType";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "endDate";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "estimatedCosts";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "estimatedDiffAndMargin";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "estimatedIncome";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "financingType";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "name";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "ownProject";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "projectGroup";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "projectManager";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "projectType";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "startDate";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "state";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "targetCosts";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "targetDiffAndMargin";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "targetIncome";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "taxRate";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "version";
            }
        })(FoldingTable_Filter.writeVisibility)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))("ui:json:portfolio:column_visibility")(Data_Maybe.Nothing.value)(false)();
        var v5 = TofuHooks.useState(0)();
        var v6 = UseFetch.useConditionalFetchValue(Data_Eq.eqInt)(v5.value0)(function (v6) {
            return v6.portfolioReport;
        })(portfolioActionFromKey)();
        var v7 = UseFetch.useFetchItems(Data_Eq.eqUnit)(Data_Unit.unit)(function (v7) {
            return v7.projectManagers;
        })(Actions.GetProjectManagersRequest.value)();
        var v8 = UseFetch.useFetchItems(Data_Eq.eqUnit)(Data_Unit.unit)(function (v8) {
            return v8.projectTypes;
        })(Actions.ProjectTypesRequest.value)();
        var v9 = UseFetch.useFetchItems(Data_Eq.eqUnit)(Data_Unit.unit)(function (v9) {
            return v9.constructionTypes;
        })(Actions.ConstructionTypesRequest.value)();
        var v10 = UseFetch.useFetchItems(Data_Eq.eqUnit)(Data_Unit.unit)(function (v10) {
            return v10.financingTypes;
        })(Actions.FinancingTypesRequest.value)();
        var v11 = UseFetch.useFetchItems(Data_Eq.eqUnit)(Data_Unit.unit)(function (v11) {
            return v11.contractTypes;
        })(Actions.ContractTypesRequest.value)();
        var appDispatch = StateHooks.useDispatch();
        var selectOptions = {
            projectManagers: v7.value0,
            contractTypes: v11.value0,
            projectTypes: v8.value0,
            constructionTypes: v9.value0,
            financingTypes: v10.value0
        };
        var projects = (function () {
            if (v6.value0 instanceof Data_Maybe.Nothing) {
                return [  ];
            };
            if (v6.value0 instanceof Data_Maybe.Just) {
                return v6.value0.value0;
            };
            throw new Error("Failed pattern match at PortfolioContainer (line 95, column 16 - line 97, column 18): " + [ v6.value0.constructor.name ]);
        })();
        var filteredProjects = FoldingTable_Filter.filterPortfolioProjects(v3.value0)(v1.value0)(projects);
        var summaryHeader = (function () {
            var statusText = (function () {
                var filteredProjectsCount = Data_Array.length(filteredProjects);
                var allProjectsCount = Data_Array.length(projects);
                var projectText = (function () {
                    var $42 = filteredProjectsCount < allProjectsCount;
                    if ($42) {
                        return Data_Show.show(Data_Show.showInt)(filteredProjectsCount) + (" / " + Data_Show.show(Data_Show.showInt)(allProjectsCount));
                    };
                    return Data_Show.show(Data_Show.showInt)(allProjectsCount);
                })();
                return React_Basic_DOM_Generated.span()({
                    className: "status-text",
                    children: [ React_Basic_DOM.text(projectText + " hanketta") ]
                });
            })();
            var header = React_Basic_DOM_Generated.h2_([ React_Basic_DOM.text(Locale.lookup_("portfolio_summary__heading")) ]);
            return React_Basic_DOM_Generated.div()({
                className: "portfolio-summary-heading fixed-width",
                children: [ header, statusText ]
            });
        })();
        var filterInput = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "projects-filter",
            css: PortfolioContainer_Styles.filterStyles,
            children: [ TacoInput.stringInput()()(Data_Eq.eqString)({
                label: "Hae",
                value: v3.value0,
                onChange: v3.value1,
                clearButton: true
            }) ]
        });
        var controls = Controls.component()()({
            leftControls: [ React_Basic_DOM_Generated.div()({
                className: "filter-controls",
                children: [ filterInput ]
            }) ],
            rightControls: [ React_Basic_DOM_Generated.div()({
                className: "action-buttons",
                children: [ TacoButton.component()()({
                    onClick: Data_Maybe.Just.create(appDispatch(new Actions.PortfolioReportExcelExportRequest({
                        projects: filteredProjects
                    }))),
                    text: "Vie exceliin",
                    icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconFileDownloadV1.value),
                    align: TacoButton_Types.Left.value
                }), TacoButton.component()()({
                    onClick: Data_Maybe.Just.create(v5.value1(function (v12) {
                        return v12 + 1 | 0;
                    })),
                    text: "P\xe4ivit\xe4"
                }), TacoButton.component()()({
                    onClick: Data_Maybe.Just.create(v2.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean))),
                    text: "N\xe4kym\xe4",
                    icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconFilterListV1.value)
                }) ]
            }) ],
            bottomGutter: true
        });
        var columnVisibility = Data_Maybe.fromMaybe(defaultVisibility)(v4.value0);
        var isColumnVisible = function (col) {
            var propVisibility = (function () {
                if (col instanceof PortfolioTable.ColCode) {
                    return columnVisibility.code;
                };
                if (col instanceof PortfolioTable.ColName) {
                    return columnVisibility.name;
                };
                if (col instanceof PortfolioTable.ColVersion) {
                    return columnVisibility.version;
                };
                if (col instanceof PortfolioTable.ColProjectManager) {
                    return columnVisibility.projectManager;
                };
                if (col instanceof PortfolioTable.ColTargetCosts) {
                    return columnVisibility.targetCosts;
                };
                if (col instanceof PortfolioTable.ColEstimatedCosts) {
                    return columnVisibility.estimatedCosts;
                };
                if (col instanceof PortfolioTable.ColActualCosts) {
                    return columnVisibility.actualCosts;
                };
                if (col instanceof PortfolioTable.ColTargetIncome) {
                    return columnVisibility.targetIncome;
                };
                if (col instanceof PortfolioTable.ColEstimatedIncome) {
                    return columnVisibility.estimatedIncome;
                };
                if (col instanceof PortfolioTable.ColActualIncome) {
                    return columnVisibility.actualIncome;
                };
                if (col instanceof PortfolioTable.ColActualIncomeApproved) {
                    return columnVisibility.actualIncomeApproved;
                };
                if (col instanceof PortfolioTable.ColTargetDiffAndMargin) {
                    return columnVisibility.targetDiffAndMargin;
                };
                if (col instanceof PortfolioTable.ColActualDiffAndMargin) {
                    return columnVisibility.actualDiffAndMargin;
                };
                if (col instanceof PortfolioTable.ColActualDiffAndMarginApproved) {
                    return columnVisibility.actualDiffAndMarginApproved;
                };
                if (col instanceof PortfolioTable.ColEstimatedDiffAndMargin) {
                    return columnVisibility.estimatedDiffAndMargin;
                };
                if (col instanceof PortfolioTable.ColStartDate) {
                    return columnVisibility.startDate;
                };
                if (col instanceof PortfolioTable.ColEndDate) {
                    return columnVisibility.endDate;
                };
                if (col instanceof PortfolioTable.ColContractType) {
                    return columnVisibility.contractType;
                };
                if (col instanceof PortfolioTable.ColState) {
                    return columnVisibility.state;
                };
                if (col instanceof PortfolioTable.ColProjectType) {
                    return columnVisibility.projectType;
                };
                if (col instanceof PortfolioTable.ColFinancingType) {
                    return columnVisibility.financingType;
                };
                if (col instanceof PortfolioTable.ColConstructionType) {
                    return columnVisibility.constructionType;
                };
                if (col instanceof PortfolioTable.ColContractPrice) {
                    return columnVisibility.contractPrice;
                };
                if (col instanceof PortfolioTable.ColTaxRate) {
                    return columnVisibility.taxRate;
                };
                if (col instanceof PortfolioTable.ColOwnProject) {
                    return columnVisibility.ownProject;
                };
                if (col instanceof PortfolioTable.ColProjectGroup) {
                    return columnVisibility.projectGroup;
                };
                throw new Error("Failed pattern match at PortfolioContainer (line 243, column 26 - line 269, column 59): " + [ col.constructor.name ]);
            })();
            return Data_Eq.eq(FoldingTable_Filter.eqVisibility)(propVisibility)(FoldingTable_Filter.Visible.value);
        };
        var settings = (function () {
            if (v2.value0) {
                return PortfolioFilterSettings.portfolioFilterSettings({
                    filterConfig: v1.value0,
                    columnVisibility: columnVisibility,
                    onColumnVisibilityChange: function (newVisible) {
                        return v4.value1(function (v12) {
                            return new Data_Maybe.Just(newVisible);
                        });
                    },
                    onColumnFilterChange: function (value) {
                        return v1.value1(function (v12) {
                            return value;
                        });
                    },
                    clearFilters: v1.value1(function (v12) {
                        return FoldingTable_Filter.emptyPortfolioFilterConfig;
                    }),
                    onClose: v2.value1(function (v12) {
                        return false;
                    }),
                    selectOptions: selectOptions
                });
            };
            return React_Basic.empty;
        })();
        var table = (function () {
            var v13 = Data_Array.length(projects);
            if (false) {
                return TacoAlert.component({
                    alert: TacoAlert.fetchProjectErrorMsg,
                    fragment: React_Basic.empty
                });
            };
            if (v6.value1) {
                return TacoLoader.component()()({});
            };
            if (!v6.value1 && v13 === 0) {
                return NoProjectsFoundMsg.noProjectsFoundMsg(appDispatch);
            };
            if (!v6.value1) {
                return React_Basic_DOM_Generated.div()({
                    className: "min-width",
                    children: [ (function () {
                        var $49 = !FoldingTable_Filter.portfolioAreAllHidden(columnVisibility);
                        if ($49) {
                            return PortfolioTable.portfolioTable({
                                loading: false,
                                projects: filteredProjects,
                                isColumnVisible: isColumnVisible,
                                navigateToProject: function (id) {
                                    return appDispatch(new Actions.NavigateRoute(new Types_FrontEndRoutes.CostControlFERoute({
                                        projectId: id
                                    })));
                                }
                            });
                        };
                        return React_Basic_DOM_Generated.div()({
                            className: "no-columns-selected",
                            children: [ React_Basic_DOM_Generated.h3()({
                                children: Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM.text("Kaikki sarakkeet piilotettu"))
                            }), React_Basic_DOM_Generated.p()({
                                children: Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM.text("Valitse ainakin yksi sarake n\xe4hd\xe4ksesi taulukon tietoja."))
                            }) ]
                        });
                    })(), React_Basic_Emotion.element(React_Basic_DOM_Generated["span'"]())({
                        className: "project-rights-info",
                        css: PortfolioContainer_Styles.projectRightsStyles,
                        children: [ TacoText.component()()({
                            text: Locale.lookup_("project_rights_info"),
                            color: TacoConstants.Gray.value,
                            align: TacoText_Types.Center.value,
                            variant: new TacoText_Types.Heading(5)
                        }) ]
                    }) ]
                });
            };
            throw new Error("Failed pattern match at PortfolioContainer (line 172, column 7 - line 212, column 14): " + [ false.constructor.name, v6.value1.constructor.name, v13.constructor.name, columnVisibility.constructor.name ]);
        })();
        return NarrowLayout.component()()({
            children: [ controls, settings, summaryHeader, table ],
            verticalPadding: true,
            minWidth: new Data_Maybe.Just(new TacoConstants.CustomSize("1920px")),
            fullWidth: true
        });
    };
});
module.exports = {
    component: component
};
