import { useMemo } from "react";
import { User } from "../../../../../ts-bindings/User";
import { useDefault } from "../../../hooks/useDefault";
import { useWorkerState } from "../../../hooks/useWorkerState";
import { RoleIds, useRoleIds } from "./useRoleIds";
import { UserRoleAssignmentWithUserId } from "../../../../../ts-bindings/UserRoleAssignmentWithUserId";
import { EstimationProjectSummary } from "../../../../../ts-bindings/EstimationProjectSummary";
import { DefaultValues } from "react-hook-form";
import {
  ProjectGroupPermission,
  ProjectPermission,
  UserRolesData,
} from "./EditUserRolesModal";

export function useGetUserRoleData() {
  const roleIds = useRoleIds();
  const roleAssignments = useDefault(
    useWorkerState("AdminRoleAssignmentsState"),
    []
  );
  const costControlProjects = useDefault(
    useWorkerState("CostControlProjectsState"),
    []
  );

  const adminProjectGroups = useDefault(
    useWorkerState("AdminProjectGroupsState"),
    []
  );

  const projectGroups = useMemo(() => {
    return adminProjectGroups.map((group) => ({ id: group, name: group }));
  }, [adminProjectGroups]);
  const estimationProjects = useDefault(
    useWorkerState("EstimationProjectsState"),
    []
  );

  return [
    roleIds,
    roleAssignments,
    costControlProjects,
    projectGroups,
    estimationProjects,
  ] as const;
}

export function useUserFormDefaultValues(
  user: User | null
): DefaultValues<UserRolesData> {
  const [
    roleIds,
    roleAssignments,
    costControlProjects,
    projectGroups,
    estimationProjects,
  ] = useGetUserRoleData();

  return useMemo(() => {
    if (!user) {
      return getUserRoleDefaultValues(
        user,
        roleIds,
        roleAssignments,
        costControlProjects,
        projectGroups,
        estimationProjects
      );
    }
    return getUserRolesData(
      user,
      roleIds,
      roleAssignments,
      costControlProjects,
      projectGroups,
      estimationProjects
    );
  }, [
    user,
    roleAssignments,
    roleIds,
    costControlProjects,
    projectGroups,
    estimationProjects,
  ]);
}

export function getUserRoleDefaultValues(
  user: User | null,
  roleIds: RoleIds,
  roleAssignments: UserRoleAssignmentWithUserId[],
  costControlProjects: EstimationProjectSummary[],
  projectGroups: { id: string; name: string }[],
  estimationProjects: EstimationProjectSummary[]
): DefaultValues<UserRolesData> {
  const userRoles = roleAssignments.filter((ra) => {
    return ra.userId === user?.id;
  });

  const hasRole = (roleId: number) =>
    userRoles.find((r) => r.roleId === roleId) !== undefined;

  const [
    ccPerms,
    ccGroupPerms,
    cePerms,
    ceGroupPerms,
  ] = getProjectAndProjectGroupPerms(
    userRoles,
    roleIds,
    hasRole,
    costControlProjects,
    estimationProjects,
    projectGroups
  );

  if (!user) {
    return {
      costControl: {
        project: ccPerms,
        projectGroup: ccGroupPerms,
      },
      estimation: {
        project: cePerms,
        projectGroup: ceGroupPerms,
      },
    };
  } else {
    return getUserRolesData(
      user,
      roleIds,
      roleAssignments,
      costControlProjects,
      projectGroups,
      estimationProjects
    );
  }
}

const getRoleAssignment = (
  roleAssignments: UserRoleAssignmentWithUserId[],
  userId: string
) => roleAssignments.filter((ra) => ra.userId === userId);

const userHasRole = (
  roleId: number,
  userRoles: UserRoleAssignmentWithUserId[]
) => userRoles.find((r) => r.roleId === roleId) !== undefined;

function getProjectAndProjectGroupPerms(
  userRoles: UserRoleAssignmentWithUserId[],
  roleIds: RoleIds,
  hasRole: (roleId: number) => boolean,
  costControlProjects: EstimationProjectSummary[],
  estimationProjects: EstimationProjectSummary[],
  projectGroups: { id: string; name: string }[]
) {
  const ccPerms = getProjectPermissions(
    userRoles,
    roleIds,
    hasRole,
    costControlProjects,
    roleIds.ccReadAll,
    roleIds.ccWriteAll,
    roleIds.ccRead,
    roleIds.ccWrite
  );

  const ccGroupPerms = getProjectGroupPermissions(
    userRoles,
    roleIds,
    hasRole,
    projectGroups,
    roleIds.ccReadAll,
    roleIds.ccWriteAll,
    roleIds.ccRead,
    roleIds.ccWrite
  );

  const cePerms = getProjectPermissions(
    userRoles,
    roleIds,
    hasRole,
    estimationProjects,
    roleIds.ceReadAll,
    roleIds.ceWriteAll,
    roleIds.ceRead,
    roleIds.ceWrite
  );

  const ceGroupPerms = getProjectGroupPermissions(
    userRoles,
    roleIds,
    hasRole,
    projectGroups,
    roleIds.ceReadAll,
    roleIds.ceWriteAll,
    roleIds.ceRead,
    roleIds.ceWrite
  );

  return [ccPerms, ccGroupPerms, cePerms, ceGroupPerms] as const;
}

export function getUserRolesData(
  user: User,
  roleIds: RoleIds,
  roleAssignments: UserRoleAssignmentWithUserId[],
  costControlProjects: EstimationProjectSummary[],
  projectGroups: { id: string; name: string }[],
  estimationProjects: EstimationProjectSummary[]
): UserRolesData {
  const userRoles = getRoleAssignment(roleAssignments, user.id);

  const hasRole = (roleId: number) => userHasRole(roleId, userRoles);

  const [
    ccPerms,
    ccGroupPerms,
    cePerms,
    ceGroupPerms,
  ] = getProjectAndProjectGroupPerms(
    userRoles,
    roleIds,
    hasRole,
    costControlProjects,
    estimationProjects,
    projectGroups
  );

  return {
    userId: user?.id,
    allPermissions: hasRole(roleIds.all),
    mainUser: hasRole(roleIds.all) || hasRole(roleIds.mainUser),
    costClassTargetEdit:
      hasRole(roleIds.all) || hasRole(roleIds.costClassTargetEdit),
    createProjectsPermission:
      hasRole(roleIds.all) || hasRole(roleIds.createProject),
    costControl: {
      appPermission: hasRole(roleIds.all) || hasRole(roleIds.cc),
      writeAll: hasRole(roleIds.all) || hasRole(roleIds.ccWriteAll),
      readAll: hasRole(roleIds.all) || hasRole(roleIds.ccReadAll),
      project: ccPerms,
      projectGroup: ccGroupPerms,
    },
    estimation: {
      appPermission: hasRole(roleIds.all) || hasRole(roleIds.ce),
      writeAll: hasRole(roleIds.all) || hasRole(roleIds.ceWriteAll),
      readAll: hasRole(roleIds.all) || hasRole(roleIds.ceReadAll),
      project: cePerms,
      projectGroup: ceGroupPerms,
    },
  };
}

function getProjectPermissions(
  userRoles: UserRoleAssignmentWithUserId[],
  roleIds: RoleIds,
  hasRole: (roleId: number) => boolean,
  projectType: EstimationProjectSummary[],
  readAllPerm: number,
  writeAllPerm: number,
  readPerm: number,
  writePerms: number
): ProjectPermission[] {
  return projectType.map((item) => {
    return {
      readPermission:
        hasRole(roleIds.all) ||
        hasRole(readAllPerm) ||
        userRoles.some(
          (role) =>
            role.roleId === readPerm &&
            role.projects.some((id) => id === item.id)
        ),
      writePermission:
        hasRole(roleIds.all) ||
        hasRole(writeAllPerm) ||
        userRoles.some(
          (role) =>
            role.roleId === writePerms &&
            role.projects.some((id) => id === item.id)
        ),
      ...item,
    };
  });
}

function getProjectGroupPermissions(
  userRoles: UserRoleAssignmentWithUserId[],
  roleIds: RoleIds,
  hasRole: (roleId: number) => boolean,
  projectGroups: { id: string; name: string }[],
  readAllPerm: number,
  writeAllPerm: number,
  readPerm: number,
  writePerms: number
): ProjectGroupPermission[] {
  return projectGroups.map((item) => {
    return {
      readPermission:
        hasRole(roleIds.all) ||
        hasRole(readAllPerm) ||
        userRoles.some(
          (role) =>
            role.roleId === readPerm &&
            role.projectGroups.some((id) => id === item.id)
        ),
      writePermission:
        hasRole(roleIds.all) ||
        hasRole(writeAllPerm) ||
        userRoles.some(
          (role) =>
            role.roleId === writePerms &&
            role.projectGroups.some((id) => id === item.id)
        ),
      ...item,
    };
  });
}
