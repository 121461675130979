import { ICellRendererParams } from "ag-grid-community";
import "./editableCells.css";
// This cell renderer is used in columns that are editable, but some specific cells are not editable.
export const nonEditableCellRenderer = (params: ICellRendererParams) => {
  const defaultClasses = "non-editable-cell";
  const numericClasses = "text-right" + " " + defaultClasses;
  const inputClasses = params.colDef?.type?.includes("numericColumn")
    ? numericClasses
    : defaultClasses;
  return (
    <div className={inputClasses}>{params.valueFormatted ?? params.value}</div>
  );
};
