const showdown = require("showdown");
const converter = new showdown.Converter();

// Import the release notes file as a string
const RELEASE_NOTES = require("./../../ReleaseNotes.md");

exports.renderHtml = function (md) {
  return converter.makeHtml(md);
};

exports.releaseNotes = RELEASE_NOTES;
