// Generated by purs version 0.14.5
"use strict";
var Data_Array = require("../Data.Array/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Set = require("../Data.Set/index.js");
var Types_Element = require("../Types.Element/index.js");
var _subprojectId = function (v) {
    return Data_Nullable.toMaybe(v.element.subprojectId);
};
var _specs = function (v) {
    return v.specs;
};
var _elementId = function (v) {
    return v.element.elementId;
};
var mkCheckedElementsData = function (set) {
    return function (xs) {
        var isInSet = function (x) {
            return Data_Set.member(Types_Element.ordElementId)(_elementId(x))(set);
        };
        return Data_Array.filter(isInSet)(xs);
    };
};
var _elementCode = function (v) {
    return v.element.code;
};
var _element = function (v) {
    return v.element;
};
module.exports = {
    "_element": _element,
    "_elementId": _elementId,
    "_subprojectId": _subprojectId,
    "_elementCode": _elementCode,
    "_specs": _specs,
    mkCheckedElementsData: mkCheckedElementsData
};
