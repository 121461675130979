import { useWorkerState } from "../../../hooks/useWorkerState";
import { useEffect, useState } from "react";
import { Measurement } from "../../../../../ts-bindings/Measurement";
import { useDefault } from "../../../hooks/useDefault";
import { CostClass } from "../../../../../ts-bindings/CostClass";
import { OrgCostClass } from "../../../../../ts-bindings/OrgCostClass";
import { OrgMeasurement } from "../../../../../ts-bindings/OrgMeasurement";

type ProjectDataHook<T> = (
  classification: string,
  defaultValues: boolean,
  projectId?: number
) => [T[], (values: T[]) => void];

export const useMeasurements: ProjectDataHook<Measurement> = (
  classification,
  defaultValues,
  projectId
) => {
  const measurementsState = useWorkerState("MeasurementsState", projectId);
  const [measurementUnits, setMeasurementUnits] = useState<Measurement[]>([]);
  const orgMeasurements = useDefault(
    useWorkerState("OrgMeasurementsState"),
    []
  );

  useEffect(() => {
    const measurementUnitsByClassification = findOrgClassification(
      orgMeasurements,
      classification
    );
    if (defaultValues) {
      const defaultMeasurementUnits = measurementUnitsByClassification.map(
        (item) => {
          return {
            unit: {
              id: null,
              mandatory: false,
              projectId: null,
              name: item.description,
              unit: item.code,
            },
            values: [],
          };
        }
      );
      setMeasurementUnits(defaultMeasurementUnits);
    } else {
      setMeasurementUnits(measurementsState ?? []);
    }
  }, [defaultValues, measurementsState, orgMeasurements, classification]);

  return [measurementUnits, setMeasurementUnits];
};

export const useCostClasses: ProjectDataHook<CostClass> = (
  classification,
  defaultValues,
  projectId
) => {
  const costClassesState = useWorkerState(
    "EstimationCostClassesState",
    projectId
  );
  const [costClasses, setCostClasses] = useState<CostClass[]>([]);
  const orgCostClasses = useDefault(useWorkerState("OrgCostClassesState"), []);

  useEffect(() => {
    const costClassesByClassification = findOrgClassification(
      orgCostClasses,
      classification
    );
    if (defaultValues) {
      const defaultCostClasses = costClassesByClassification.map((item) => {
        return {
          id: 0,
          costClassCode: item.code,
          name: item.description,
          projectId: 0,
          socialExpensePercentageInCostEstimation: item.socialExpensePercentage
            ? item.socialExpensePercentage / 100
            : 0,
          socialExpensePercentageInCostControlForEstimatesAndActuals: 0,
          socialExpensePercentageInCostControlForComponents: 0,
          socialExpensePercentageInCostControlForTargets: 0,
          multiplier: 1,
          hourlyPricing: item.hourlyPricing === -1,
        };
      });
      setCostClasses(defaultCostClasses);
    } else {
      setCostClasses(costClassesState ?? []);
    }
  }, [defaultValues, costClassesState, orgCostClasses, classification]);

  return [costClasses, setCostClasses];
};

function findOrgClassification<T extends OrgCostClass | OrgMeasurement>(
  orgValues: T[],
  classification: string
) {
  return orgValues.filter(
    (item: T) => item.orgClassificationId === classification
  );
}
