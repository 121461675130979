// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Effect = require("../Effect/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var targetCostGroupsControls = TofuHooks.mkHookComponent("TargetCostGroupsControls")(function (props) {
    return Control_Applicative.pure(Effect.applicativeEffect)(ReactBasicUtils["div'"]("__TargetCostGroupsControls")([ TacoCheckbox.component()()({
        className: "toggle-show-target-cost-groups",
        isChecked: props.showTargetCostGroup,
        onToggle: new TacoCheckbox.NoEvent(props.onTargetCostGroupToggle),
        labelText: Data_Maybe.Just.create(TacoCheckbox.StringLabel.create("N\xe4yt\xe4 tavoitelitterat")),
        labelTextWeight: (function () {
            if (props.showTargetCostGroup) {
                return TacoText_Types.Bold.value;
            };
            return TacoText_Types.Normal.value;
        })(),
        testId: "toggle-show-target-cost-groups-checkbox"
    }) ]));
});
module.exports = {
    targetCostGroupsControls: targetCostGroupsControls
};
