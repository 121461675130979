import * as React from "react";
import { SVGProps } from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { Button, Icon, IconChevronDownV1, Link, tw } from "@tocoman/ui";

type SplitButtonProps = {
  buttonLabel: string;
  options: {
    icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
    label: string;
    disabled: boolean;
    color: string;
  }[];
  onButtonClick?: () => void;
  onMenuItemClick: (selectedItem: number) => void;
  link?: string;
};

const groupStyle = {
  boxShadow: "none",
};
const paperStyle = {
  background: "#FFFFFF",
  boxShadow: "0px 8px 24px -4px rgba(28, 50, 79, 0.38)",
  borderRadius: 4,
};

/***
 * @param buttonLabel The label of the main button
 * @param options The options to show in the dropdown
 * @param onButtonClick The callback to call when the main button is clicked
 * @param onMenuItemClick The callback to call when a menu item is clicked
 * @param link Provide link if you'd like to have the main button have link behaviour. onButtonClick will be ignored if link is provided
 */
export const SplitButton = ({
  options,
  onButtonClick,
  buttonLabel,
  onMenuItemClick,
  link,
}: SplitButtonProps) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    onMenuItemClick(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const onEscKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.code === "Escape" && setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        style={groupStyle}
      >
        {link ? (
          <LinkButton link={link} label={buttonLabel} />
        ) : (
          <DefaultButton label={buttonLabel} onClick={onButtonClick} />
        )}
        <Button
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          testId={"split-button-chevron"}
          onClick={handleToggle}
          className={tw`rounded-l-none min-h-full`}
        >
          <Icon icon={IconChevronDownV1} />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        onKeyDown={(e) => onEscKeyDown(e)}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "center top",
            }}
          >
            <Paper style={paperStyle}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.label}
                      disabled={option.disabled}
                      onClick={(event) => handleMenuItemClick(event, index)}
                      style={
                        option.color === "red"
                          ? { color: "#FF0000" }
                          : { color: "#2745CE" }
                      }
                    >
                      <Icon icon={option.icon} className={"mr-2"} />{" "}
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};

type ButtonProps = { label: string };
type LinkButtonProps = {
  link: string;
} & ButtonProps;
const LinkButton = ({ link, label }: LinkButtonProps) => {
  return (
    <Link href={link} className={"rounded-r-none py-1.5 px-2.5 text-base"}>
      <DefaultButton label={label} />
    </Link>
  );
};

type DefaultButtonProps = {
  onClick?: () => void;
} & ButtonProps;
const DefaultButton = ({ label, onClick }: DefaultButtonProps) => {
  return (
    <Button
      className={
        "rounded-r-none border-r border-[rgb(255,255,255,0.5)] py-1.5 px-2.5 text-base"
      }
      onClick={onClick}
    >
      {label}
    </Button>
  );
};
