import { useMemo } from "react";
import {
  ColGroupDef,
  ExcelExportParams,
  ExcelRow,
  GridApi,
  ShouldRowBeSkippedParams,
} from "ag-grid-community";
import { useTranslation } from "react-i18next";
import { ProjectDetailsData } from "../../../../../../ts-bindings/ProjectDetailsData";
import { excelStyles } from "../../../../utils/Printing/excelStyles";
import { useGridFilterModel } from "../../../../utils/Printing/useGridFilterModel";

export function usePrintingExcelExportSettings(
  project: ProjectDetailsData | null,
  api?: GridApi | null
) {
  const defaultExcelExportParams = usePrintingExcelExportParams(project, api);
  return {
    defaultExcelExportParams,
    excelStyles,
  };
}

export function usePrintingExcelExportParams(
  project: ProjectDetailsData | null,
  api?: GridApi | null
) {
  const { t } = useTranslation("costControl", { keyPrefix: "printing" });

  const columnFilterRows = useColumnFilterExcelRows(api);

  return useMemo<ExcelExportParams>(() => {
    return {
      pageSetup: {
        orientation: "Landscape",
      },
      sheetName: project?.name ?? "Tarjouslaskenta",
      fileName: `${project?.name}.xlsx`,
      shouldRowBeSkipped(params: ShouldRowBeSkippedParams): boolean {
        if (params.node.data) {
          return params.node.data.component === undefined
            ? !params.node.isRowPinned()
            : false;
        }
        return false;
      },
      processRowGroupCallback(params) {
        return `${params.node.key}`;
      },
      prependContent: [
        {
          cells: [
            {
              data: {
                value: project ? project.name : "",
                type: "String",
              },
              styleId: "size15",
              mergeAcross: 5,
            },
          ],
        },
        ...columnFilterRows,
      ],
    };
  }, [columnFilterRows, project, t]);
}

export function useColumnFilterExcelRows(api?: GridApi | null): ExcelRow[] {
  const { t } = useTranslation("costControl", { keyPrefix: "printing" });

  const gridFilterModel = useGridFilterModel<ProjectDetailsData>(api);
  const filteredColumns = useMemo<string[]>(() => {
    const filtersNames = Object.getOwnPropertyNames(gridFilterModel);
    const mainColumNames = filtersNames
      .filter((item) => !item.includes("costClasses"))
      .map((item) => {
        const componentSplit = item.includes("component")
          ? item.split("component.")[1]
          : item;
        return t(`tableColumns.${componentSplit}`);
      });
    const costClassColumnNames = filtersNames
      .filter((item) => item.includes("costClasses"))
      .map((item) => {
        const costClassSplitColumnName = item.includes(".")
          ? item.split(".")[2]
          : item;
        const costClassColumnName = `tableColumns.${costClassSplitColumnName}`;
        const costClassSplitIndex = item.includes(".")
          ? item.split(".")[1]
          : item;
        const costClassCode = (parseInt(costClassSplitIndex) + 1).toString();
        const colDefs = api?.getColumnDefs();
        const columnGroup = colDefs
          ?.filter((item) => (item as ColGroupDef).children !== undefined)
          .filter((item) => item.headerName?.includes(costClassCode))
          .map((item) => item.headerName);
        const translatedCostClassColumn = t(`${costClassColumnName}`);
        return `${columnGroup}: ${translatedCostClassColumn}`;
      });
    return mainColumNames.concat(costClassColumnNames);
  }, [api, gridFilterModel, t]);

  return [
    {
      cells: [
        {
          data: {
            value: filteredColumns.toString()
              ? "Suodatus käytössä seuraavissa sarakkeissa:"
              : "",
            type: "String",
          },
          styleId: "size12",
          mergeAcross: 5,
        },
      ],
    },
    {
      cells: [
        {
          data: {
            value: filteredColumns.toString(),
            type: "String",
          },
          styleId: "size12",
          mergeAcross: 5,
        },
      ],
    },
  ];
}
