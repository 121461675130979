// Generated by purs version 0.14.5
"use strict";
var Data_Array_NonEmpty_Internal = require("../Data.Array.NonEmpty.Internal/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Types_ClassificationGroup = require("../Types.ClassificationGroup/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_MergedEstimationResource = require("../Types.MergedEstimationResource/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Project = require("../Types.Project/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var Closed = (function () {
    function Closed() {

    };
    Closed.value = new Closed();
    return Closed;
})();
var SelectUpdateCriteria = (function () {
    function SelectUpdateCriteria() {

    };
    SelectUpdateCriteria.value = new SelectUpdateCriteria();
    return SelectUpdateCriteria;
})();
var MatchResources = (function () {
    function MatchResources() {

    };
    MatchResources.value = new MatchResources();
    return MatchResources;
})();
var ByCostClass = (function () {
    function ByCostClass() {

    };
    ByCostClass.value = new ByCostClass();
    return ByCostClass;
})();
var ByChangeDate = (function () {
    function ByChangeDate() {

    };
    ByChangeDate.value = new ByChangeDate();
    return ByChangeDate;
})();
var ByResourceName = (function () {
    function ByResourceName() {

    };
    ByResourceName.value = new ByResourceName();
    return ByResourceName;
})();
var ByUnit = (function () {
    function ByUnit() {

    };
    ByUnit.value = new ByUnit();
    return ByUnit;
})();
var ByUnitPrice = (function () {
    function ByUnitPrice() {

    };
    ByUnitPrice.value = new ByUnitPrice();
    return ByUnitPrice;
})();
var ByTotalAmountSum = (function () {
    function ByTotalAmountSum() {

    };
    ByTotalAmountSum.value = new ByTotalAmountSum();
    return ByTotalAmountSum;
})();
var ByTotalPriceSum = (function () {
    function ByTotalPriceSum() {

    };
    ByTotalPriceSum.value = new ByTotalPriceSum();
    return ByTotalPriceSum;
})();
var ByResourceClassificationGroup = (function () {
    function ByResourceClassificationGroup() {

    };
    ByResourceClassificationGroup.value = new ByResourceClassificationGroup();
    return ByResourceClassificationGroup;
})();
var BySortingNumber = (function () {
    function BySortingNumber() {

    };
    BySortingNumber.value = new BySortingNumber();
    return BySortingNumber;
})();
var ByName = (function () {
    function ByName() {

    };
    ByName.value = new ByName();
    return ByName;
})();
var ByDiscountPercentage = (function () {
    function ByDiscountPercentage() {

    };
    ByDiscountPercentage.value = new ByDiscountPercentage();
    return ByDiscountPercentage;
})();
var ByPriceListId = (function () {
    function ByPriceListId() {

    };
    ByPriceListId.value = new ByPriceListId();
    return ByPriceListId;
})();
var ByPriceWithDiscount = (function () {
    function ByPriceWithDiscount() {

    };
    ByPriceWithDiscount.value = new ByPriceWithDiscount();
    return ByPriceWithDiscount;
})();
var ByCurrencyId = (function () {
    function ByCurrencyId() {

    };
    ByCurrencyId.value = new ByCurrencyId();
    return ByCurrencyId;
})();
var ByDiscountGroupCode = (function () {
    function ByDiscountGroupCode() {

    };
    ByDiscountGroupCode.value = new ByDiscountGroupCode();
    return ByDiscountGroupCode;
})();
var ByInternalCode = (function () {
    function ByInternalCode() {

    };
    ByInternalCode.value = new ByInternalCode();
    return ByInternalCode;
})();
var ByContract = (function () {
    function ByContract() {

    };
    ByContract.value = new ByContract();
    return ByContract;
})();
var ByEanCode = (function () {
    function ByEanCode() {

    };
    ByEanCode.value = new ByEanCode();
    return ByEanCode;
})();
var ByTaxRate = (function () {
    function ByTaxRate() {

    };
    ByTaxRate.value = new ByTaxRate();
    return ByTaxRate;
})();
var ByIndex = (function () {
    function ByIndex() {

    };
    ByIndex.value = new ByIndex();
    return ByIndex;
})();
var ByValidDate = (function () {
    function ByValidDate() {

    };
    ByValidDate.value = new ByValidDate();
    return ByValidDate;
})();
var ByVendor = (function () {
    function ByVendor() {

    };
    ByVendor.value = new ByVendor();
    return ByVendor;
})();
var ByVendorCode = (function () {
    function ByVendorCode() {

    };
    ByVendorCode.value = new ByVendorCode();
    return ByVendorCode;
})();
var ByVendorUnit = (function () {
    function ByVendorUnit() {

    };
    ByVendorUnit.value = new ByVendorUnit();
    return ByVendorUnit;
})();
var ByVendorBatchSize = (function () {
    function ByVendorBatchSize() {

    };
    ByVendorBatchSize.value = new ByVendorBatchSize();
    return ByVendorBatchSize;
})();
var ResourcesData = function (x) {
    return x;
};
var SelectResource = (function () {
    function SelectResource(value0) {
        this.value0 = value0;
    };
    SelectResource.create = function (value0) {
        return new SelectResource(value0);
    };
    return SelectResource;
})();
var SelectResourceRelative = (function () {
    function SelectResourceRelative(value0) {
        this.value0 = value0;
    };
    SelectResourceRelative.create = function (value0) {
        return new SelectResourceRelative(value0);
    };
    return SelectResourceRelative;
})();
var SetResourcesList = (function () {
    function SetResourcesList(value0) {
        this.value0 = value0;
    };
    SetResourcesList.create = function (value0) {
        return new SetResourcesList(value0);
    };
    return SetResourcesList;
})();
var SetSortingCriteria = (function () {
    function SetSortingCriteria(value0) {
        this.value0 = value0;
    };
    SetSortingCriteria.create = function (value0) {
        return new SetSortingCriteria(value0);
    };
    return SetSortingCriteria;
})();
var ordResourcesData = Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()({
    reflectSymbol: function () {
        return "resource";
    }
})(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()({
    reflectSymbol: function () {
        return "vendorUnit";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
    reflectSymbol: function () {
        return "vendorCode";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
    reflectSymbol: function () {
        return "vendorBatchSize";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
    reflectSymbol: function () {
        return "vendor";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
    reflectSymbol: function () {
        return "validDate";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
    reflectSymbol: function () {
        return "usagesCount";
    }
})(Data_Ord.ordNumber))()({
    reflectSymbol: function () {
        return "unit";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
    reflectSymbol: function () {
        return "taxPercentage";
    }
})(Types_Unit.ordTaxrate))()({
    reflectSymbol: function () {
        return "resourceClassificationGroup";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
    reflectSymbol: function () {
        return "priceWithDiscount";
    }
})(Data_Ord.ordNumber))()({
    reflectSymbol: function () {
        return "pricePerUnit";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
    reflectSymbol: function () {
        return "orgResourcePriceListId";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
    reflectSymbol: function () {
        return "orgResourcePriceId";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordInt)))()({
    reflectSymbol: function () {
        return "name";
    }
})(Data_Ord.ordString))()({
    reflectSymbol: function () {
        return "key";
    }
})(Data_Ord.ordString))()({
    reflectSymbol: function () {
        return "index";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Component.ordEstimationResourceId))()({
    reflectSymbol: function () {
        return "estimationResources";
    }
})(Data_Array_NonEmpty_Internal.ordNonEmptyArray(Data_Tuple.ordTuple(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()({
    reflectSymbol: function () {
        return "wastePercentage";
    }
})(Data_Ord.ordNumber))()({
    reflectSymbol: function () {
        return "vendorUnit";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
    reflectSymbol: function () {
        return "vendorCode";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
    reflectSymbol: function () {
        return "vendorBatchSize";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
    reflectSymbol: function () {
        return "vendor";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
    reflectSymbol: function () {
        return "validDate";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
    reflectSymbol: function () {
        return "usagesCount";
    }
})(Data_Ord.ordNumber))()({
    reflectSymbol: function () {
        return "unitConsumptionRate";
    }
})(Data_Ord.ordNumber))()({
    reflectSymbol: function () {
        return "unit";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
    reflectSymbol: function () {
        return "taxPercentage";
    }
})(Types_Unit.ordTaxrate))()({
    reflectSymbol: function () {
        return "sortingNumber";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
    reflectSymbol: function () {
        return "resourceClassificationGroup";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
    reflectSymbol: function () {
        return "projectId";
    }
})(Data_Nullable.ordNullable(Types_Project.ordProjectId)))()({
    reflectSymbol: function () {
        return "priceWithDiscount";
    }
})(Data_Ord.ordNumber))()({
    reflectSymbol: function () {
        return "pricePerUnit";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
    reflectSymbol: function () {
        return "orgResourcePriceListId";
    }
})(Data_Nullable.ordNullable(Types_Component.ordOrgEstimationResourceId)))()({
    reflectSymbol: function () {
        return "orgResourcePriceId";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordInt)))()({
    reflectSymbol: function () {
        return "name";
    }
})(Data_Ord.ordString))()({
    reflectSymbol: function () {
        return "multiplier";
    }
})(Data_Ord.ordNumber))()({
    reflectSymbol: function () {
        return "isCostClassPrice";
    }
})(Data_Ord.ordBoolean))()({
    reflectSymbol: function () {
        return "index";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Component.ordEstimationResourceId))()({
    reflectSymbol: function () {
        return "group";
    }
})(Data_Nullable.ordNullable(Types_ClassificationGroup.ordClassificationGroupCode)))()({
    reflectSymbol: function () {
        return "estimationComponentId";
    }
})(Data_Nullable.ordNullable(Types_Component.ordComponentId)))()({
    reflectSymbol: function () {
        return "eanCode";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
    reflectSymbol: function () {
        return "discountPercentage";
    }
})(Data_Ord.ordNumber))()({
    reflectSymbol: function () {
        return "discountGroupCode";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
    reflectSymbol: function () {
        return "currencyId";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(Data_Nullable.ordNullable(Types_Newtypes.ordCostClassCode)))()({
    reflectSymbol: function () {
        return "contract";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
    reflectSymbol: function () {
        return "changeDate";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordString))))(Data_Maybe.ordMaybe(Data_Ord.ordRecord()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()({
    reflectSymbol: function () {
        return "unit";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
    reflectSymbol: function () {
        return "subProjectName";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
    reflectSymbol: function () {
        return "subProjectId";
    }
})(Data_Nullable.ordNullable(Types_Project.ordSubprojectId)))()({
    reflectSymbol: function () {
        return "subProjectCode";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
    reflectSymbol: function () {
        return "memo";
    }
})(Data_Ord.ordString))()({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Component.ordComponentId))()({
    reflectSymbol: function () {
        return "group";
    }
})(Data_Nullable.ordNullable(Types_ClassificationGroup.ordClassificationGroupCode)))()({
    reflectSymbol: function () {
        return "flagged";
    }
})(Data_Ord.ordBoolean))()({
    reflectSymbol: function () {
        return "description";
    }
})(Data_Ord.ordString))()({
    reflectSymbol: function () {
        return "costGroupDescription";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
    reflectSymbol: function () {
        return "costGroupCode";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
    reflectSymbol: function () {
        return "componentType";
    }
})(Data_Ord.ordInt))()({
    reflectSymbol: function () {
        return "code";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
    reflectSymbol: function () {
        return "classCode";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
    reflectSymbol: function () {
        return "checked";
    }
})(Data_Ord.ordBoolean))()({
    reflectSymbol: function () {
        return "amount";
    }
})(Data_Ord.ordNumber)))))))()({
    reflectSymbol: function () {
        return "eanCode";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
    reflectSymbol: function () {
        return "discountPercentage";
    }
})(Data_Ord.ordNumber))()({
    reflectSymbol: function () {
        return "discountGroupCode";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
    reflectSymbol: function () {
        return "currencyId";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(Data_Nullable.ordNullable(Types_Newtypes.ordCostClassCode)))()({
    reflectSymbol: function () {
        return "contract";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
    reflectSymbol: function () {
        return "changeDate";
    }
})(Data_Nullable.ordNullable(Data_Ord.ordString)))));
var newtypeResourcesData = {
    Coercible0: function () {
        return undefined;
    }
};
var getNameResourcesTableSortProperty = {
    getName: function (v) {
        return "ResourcesTableSortProperty";
    }
};
var eqUpdateResourcesModalState = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Closed && y instanceof Closed) {
                return true;
            };
            if (x instanceof SelectUpdateCriteria && y instanceof SelectUpdateCriteria) {
                return true;
            };
            if (x instanceof MatchResources && y instanceof MatchResources) {
                return true;
            };
            return false;
        };
    }
};
var eqResourcesTableSortProperty = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ByCostClass && y instanceof ByCostClass) {
                return true;
            };
            if (x instanceof ByChangeDate && y instanceof ByChangeDate) {
                return true;
            };
            if (x instanceof ByResourceName && y instanceof ByResourceName) {
                return true;
            };
            if (x instanceof ByUnit && y instanceof ByUnit) {
                return true;
            };
            if (x instanceof ByUnitPrice && y instanceof ByUnitPrice) {
                return true;
            };
            if (x instanceof ByTotalAmountSum && y instanceof ByTotalAmountSum) {
                return true;
            };
            if (x instanceof ByTotalPriceSum && y instanceof ByTotalPriceSum) {
                return true;
            };
            if (x instanceof ByResourceClassificationGroup && y instanceof ByResourceClassificationGroup) {
                return true;
            };
            if (x instanceof BySortingNumber && y instanceof BySortingNumber) {
                return true;
            };
            if (x instanceof ByName && y instanceof ByName) {
                return true;
            };
            if (x instanceof ByDiscountPercentage && y instanceof ByDiscountPercentage) {
                return true;
            };
            if (x instanceof ByPriceListId && y instanceof ByPriceListId) {
                return true;
            };
            if (x instanceof ByPriceWithDiscount && y instanceof ByPriceWithDiscount) {
                return true;
            };
            if (x instanceof ByCurrencyId && y instanceof ByCurrencyId) {
                return true;
            };
            if (x instanceof ByDiscountGroupCode && y instanceof ByDiscountGroupCode) {
                return true;
            };
            if (x instanceof ByInternalCode && y instanceof ByInternalCode) {
                return true;
            };
            if (x instanceof ByContract && y instanceof ByContract) {
                return true;
            };
            if (x instanceof ByEanCode && y instanceof ByEanCode) {
                return true;
            };
            if (x instanceof ByTaxRate && y instanceof ByTaxRate) {
                return true;
            };
            if (x instanceof ByIndex && y instanceof ByIndex) {
                return true;
            };
            if (x instanceof ByValidDate && y instanceof ByValidDate) {
                return true;
            };
            if (x instanceof ByVendor && y instanceof ByVendor) {
                return true;
            };
            if (x instanceof ByVendorCode && y instanceof ByVendorCode) {
                return true;
            };
            if (x instanceof ByVendorUnit && y instanceof ByVendorUnit) {
                return true;
            };
            if (x instanceof ByVendorBatchSize && y instanceof ByVendorBatchSize) {
                return true;
            };
            return false;
        };
    }
};
var ordResourcesTableSortProperty = {
    compare: function (x) {
        return function (y) {
            if (x instanceof ByCostClass && y instanceof ByCostClass) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByCostClass) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByCostClass) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByChangeDate && y instanceof ByChangeDate) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByChangeDate) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByChangeDate) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByResourceName && y instanceof ByResourceName) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByResourceName) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByResourceName) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByUnit && y instanceof ByUnit) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByUnit) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByUnit) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByUnitPrice && y instanceof ByUnitPrice) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByUnitPrice) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByUnitPrice) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByTotalAmountSum && y instanceof ByTotalAmountSum) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByTotalAmountSum) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByTotalAmountSum) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByTotalPriceSum && y instanceof ByTotalPriceSum) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByTotalPriceSum) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByTotalPriceSum) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByResourceClassificationGroup && y instanceof ByResourceClassificationGroup) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByResourceClassificationGroup) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByResourceClassificationGroup) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof BySortingNumber && y instanceof BySortingNumber) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof BySortingNumber) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof BySortingNumber) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByName && y instanceof ByName) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByName) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByName) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByDiscountPercentage && y instanceof ByDiscountPercentage) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByDiscountPercentage) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByDiscountPercentage) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByPriceListId && y instanceof ByPriceListId) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByPriceListId) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByPriceListId) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByPriceWithDiscount && y instanceof ByPriceWithDiscount) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByPriceWithDiscount) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByPriceWithDiscount) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByCurrencyId && y instanceof ByCurrencyId) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByCurrencyId) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByCurrencyId) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByDiscountGroupCode && y instanceof ByDiscountGroupCode) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByDiscountGroupCode) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByDiscountGroupCode) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByInternalCode && y instanceof ByInternalCode) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByInternalCode) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByInternalCode) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByContract && y instanceof ByContract) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByContract) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByContract) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByEanCode && y instanceof ByEanCode) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByEanCode) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByEanCode) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByTaxRate && y instanceof ByTaxRate) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByTaxRate) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByTaxRate) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByIndex && y instanceof ByIndex) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByIndex) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByIndex) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByValidDate && y instanceof ByValidDate) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByValidDate) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByValidDate) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByVendor && y instanceof ByVendor) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByVendor) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByVendor) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByVendorCode && y instanceof ByVendorCode) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByVendorCode) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByVendorCode) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByVendorUnit && y instanceof ByVendorUnit) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByVendorUnit) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByVendorUnit) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByVendorBatchSize && y instanceof ByVendorBatchSize) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at CostEstimation.ResourcesView.Types (line 75, column 1 - line 75, column 80): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqResourcesTableSortProperty;
    }
};
var eqResourcesData = Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "resource";
    }
})(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "vendorUnit";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "vendorCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "vendorBatchSize";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "vendor";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "validDate";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "usagesCount";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "unit";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "taxPercentage";
    }
})(Types_Unit.eqTaxrate))()({
    reflectSymbol: function () {
        return "resourceClassificationGroup";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "priceWithDiscount";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "pricePerUnit";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "orgResourcePriceListId";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "orgResourcePriceId";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqInt)))()({
    reflectSymbol: function () {
        return "name";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "key";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "index";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Component.eqEstimationResourceId))()({
    reflectSymbol: function () {
        return "estimationResources";
    }
})(Data_Array_NonEmpty_Internal.eqNonEmptyArray(Data_Tuple.eqTuple(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "wastePercentage";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "vendorUnit";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "vendorCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "vendorBatchSize";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "vendor";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "validDate";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "usagesCount";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "unitConsumptionRate";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "unit";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "taxPercentage";
    }
})(Types_Unit.eqTaxrate))()({
    reflectSymbol: function () {
        return "sortingNumber";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "resourceClassificationGroup";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "projectId";
    }
})(Data_Nullable.eqNullable(Types_Project.eqProjectId)))()({
    reflectSymbol: function () {
        return "priceWithDiscount";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "pricePerUnit";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "orgResourcePriceListId";
    }
})(Data_Nullable.eqNullable(Types_Component.eqOrgEstimationResourceId)))()({
    reflectSymbol: function () {
        return "orgResourcePriceId";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqInt)))()({
    reflectSymbol: function () {
        return "name";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "multiplier";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "isCostClassPrice";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "index";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Component.eqEstimationResourceId))()({
    reflectSymbol: function () {
        return "group";
    }
})(Data_Nullable.eqNullable(Types_ClassificationGroup.eqClassificationGroupCode)))()({
    reflectSymbol: function () {
        return "estimationComponentId";
    }
})(Data_Nullable.eqNullable(Types_Component.eqComponentId)))()({
    reflectSymbol: function () {
        return "eanCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "discountPercentage";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "discountGroupCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "currencyId";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(Data_Nullable.eqNullable(Types_Newtypes.eqCostClassCode)))()({
    reflectSymbol: function () {
        return "contract";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "changeDate";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString))))(Data_Maybe.eqMaybe(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "unit";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "subProjectName";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "subProjectId";
    }
})(Data_Nullable.eqNullable(Types_Project.eqSubprojectId)))()({
    reflectSymbol: function () {
        return "subProjectCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "memo";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Component.eqComponentId))()({
    reflectSymbol: function () {
        return "group";
    }
})(Data_Nullable.eqNullable(Types_ClassificationGroup.eqClassificationGroupCode)))()({
    reflectSymbol: function () {
        return "flagged";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "description";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "costGroupDescription";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "costGroupCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "componentType";
    }
})(Data_Eq.eqInt))()({
    reflectSymbol: function () {
        return "code";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "classCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "checked";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "amount";
    }
})(Data_Eq.eqNumber)))))))()({
    reflectSymbol: function () {
        return "eanCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "discountPercentage";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "discountGroupCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "currencyId";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(Data_Nullable.eqNullable(Types_Newtypes.eqCostClassCode)))()({
    reflectSymbol: function () {
        return "contract";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "changeDate";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))));
module.exports = {
    ResourcesData: ResourcesData,
    ByCostClass: ByCostClass,
    ByChangeDate: ByChangeDate,
    ByResourceName: ByResourceName,
    ByUnit: ByUnit,
    ByUnitPrice: ByUnitPrice,
    ByTotalAmountSum: ByTotalAmountSum,
    ByTotalPriceSum: ByTotalPriceSum,
    ByResourceClassificationGroup: ByResourceClassificationGroup,
    BySortingNumber: BySortingNumber,
    ByName: ByName,
    ByDiscountPercentage: ByDiscountPercentage,
    ByPriceListId: ByPriceListId,
    ByPriceWithDiscount: ByPriceWithDiscount,
    ByCurrencyId: ByCurrencyId,
    ByDiscountGroupCode: ByDiscountGroupCode,
    ByInternalCode: ByInternalCode,
    ByContract: ByContract,
    ByEanCode: ByEanCode,
    ByTaxRate: ByTaxRate,
    ByIndex: ByIndex,
    ByValidDate: ByValidDate,
    ByVendor: ByVendor,
    ByVendorCode: ByVendorCode,
    ByVendorUnit: ByVendorUnit,
    ByVendorBatchSize: ByVendorBatchSize,
    SelectResource: SelectResource,
    SelectResourceRelative: SelectResourceRelative,
    SetResourcesList: SetResourcesList,
    SetSortingCriteria: SetSortingCriteria,
    Closed: Closed,
    SelectUpdateCriteria: SelectUpdateCriteria,
    MatchResources: MatchResources,
    newtypeResourcesData: newtypeResourcesData,
    eqResourcesData: eqResourcesData,
    ordResourcesData: ordResourcesData,
    eqResourcesTableSortProperty: eqResourcesTableSortProperty,
    ordResourcesTableSortProperty: ordResourcesTableSortProperty,
    getNameResourcesTableSortProperty: getNameResourcesTableSortProperty,
    eqUpdateResourcesModalState: eqUpdateResourcesModalState
};
