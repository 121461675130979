// Generated by purs version 0.14.5
"use strict";
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Types_Project = require("../Types.Project/index.js");
var projectCodeAndName = function (projectOfOrigin) {
    var mName = Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
        return v.name;
    })(projectOfOrigin);
    var mCode = Data_Functor.map(Data_Maybe.functorMaybe)(Data_Functor.map(Data_Functor.functorFn)(Data_Newtype.un()(Types_Project.ProjectCode))(function (v) {
        return v.code;
    }))(projectOfOrigin);
    return Data_Maybe.fromMaybe("")(Data_Functor.map(Data_Maybe.functorMaybe)(Data_String_Common.joinWith(" "))(Data_Traversable.sequence(Data_Traversable.traversableArray)(Data_Maybe.applicativeMaybe)([ mCode, mName ])));
};
module.exports = {
    projectCodeAndName: projectCodeAndName
};
