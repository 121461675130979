// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Admin_ProjectGroupSelector = require("../Admin.ProjectGroupSelector/index.js");
var Admin_ProjectSelector = require("../Admin.ProjectSelector/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var Roles_Types = require("../Roles.Types/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoSelect = require("../TacoSelect/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Project = require("../Types.Project/index.js");
var UseFetch = require("../UseFetch/index.js");
var WindowUtils = require("../WindowUtils/index.js");
var AssignmentsModal = (function () {
    function AssignmentsModal() {

    };
    AssignmentsModal.value = new AssignmentsModal();
    return AssignmentsModal;
})();
var AddAssignmentModal = (function () {
    function AddAssignmentModal(value0) {
        this.value0 = value0;
    };
    AddAssignmentModal.create = function (value0) {
        return new AddAssignmentModal(value0);
    };
    return AddAssignmentModal;
})();
var EditAssignmentModal = (function () {
    function EditAssignmentModal(value0) {
        this.value0 = value0;
    };
    EditAssignmentModal.create = function (value0) {
        return new EditAssignmentModal(value0);
    };
    return EditAssignmentModal;
})();
var tableInstance = TacoTable.mkTableStateless(TofuHooks.getNameVoid)("assignedRoleTable");
var editAssignmentModal = TofuHooks.mkHookComponent("editAssignationModal")(function (props) {
    return function __do() {
        var v = TofuHooks.useState(props.projects)();
        var v1 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v1) {
            return v1.adminProjectList;
        })(Actions.AllProjectsRequest.value)();
        var v2 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v2) {
            return v2.projectGroups;
        })(Actions.ProjectGroupsRequest.value)();
        var v3 = TofuHooks.useState(props.assignment.projectGroups)();
        var projectSelector = Admin_ProjectSelector.component({
            projects: Data_Maybe.fromMaybe([  ])(v1.value0),
            selectedProjects: v.value0,
            updateSelectedProjects: v.value1
        });
        var projectGroups = Data_Maybe.fromMaybe([  ])(v2.value0);
        var projectGroupSelector = Admin_ProjectGroupSelector.component({
            groups: projectGroups,
            selectedGroups: v3.value0,
            updateSelectedGroups: v3.value1
        });
        var modalBody = TacoModal.modalBody({
            contents: [ React_Basic_DOM.text("add assignnation"), projectSelector, projectGroupSelector ]
        });
        var editRoleButton = TacoButton.component()()({
            text: "Muuta",
            buttonStyle: TacoButton_Types.Outlined.value,
            onClick: new Data_Maybe.Just(function __do() {
                props.saveAssignment({
                    roleId: props.assignment.roleId,
                    role: props.assignment.role,
                    projects: v.value0,
                    projectGroups: v3.value0
                })();
                return props.onClose();
            })
        });
        var modalActions = TacoModal.modalActions({
            contents: [ TacoButton.component()()({
                onClick: new Data_Maybe.Just(props.onClose),
                text: "Peruuta"
            }), editRoleButton ]
        });
        return TacoModal.component()()({
            isActive: true,
            onClose: props.onClose,
            heading: "Muuta roolin projekteja",
            contents: [ modalBody, modalActions ]
        });
    };
});
var addAssignmentModal = TofuHooks.mkHookComponent("addAssignationModal")(function (props) {
    return function __do() {
        var v = TofuHooks.useState("")();
        var v1 = TofuHooks.useState([  ])();
        var v2 = TofuHooks.useState([  ])();
        var v3 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v3) {
            return v3.projectGroups;
        })(Actions.ProjectGroupsRequest.value)();
        var v4 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v4) {
            return v4.adminProjectList;
        })(Actions.AllProjectsRequest.value)();
        var selectedRole = Data_Array.find(function (r) {
            return Data_Show.show(Data_Show.showInt)(Data_Newtype.unwrap()(r.id)) === v.value0;
        })(props.roles);
        var projectSelector = Admin_ProjectSelector.component({
            projects: Data_Maybe.fromMaybe([  ])(v4.value0),
            selectedProjects: v1.value0,
            updateSelectedProjects: v1.value1
        });
        var projectGroups = Data_Maybe.fromMaybe([  ])(v3.value0);
        var projectGroupSelector = Admin_ProjectGroupSelector.component({
            groups: projectGroups,
            selectedGroups: v2.value0,
            updateSelectedGroups: v2.value1
        });
        var mkSelectItem = function (roleWithId) {
            return {
                text: (Data_Newtype.unwrap()(roleWithId.role)).name,
                value: Data_Show.show(Data_Show.showInt)(Data_Newtype.unwrap()(roleWithId.id))
            };
        };
        var roleSelect = TacoSelect.component()()({
            items: Data_Functor.map(Data_Functor.functorArray)(mkSelectItem)(props.roles),
            label: "Valitse rooli",
            showEmpty: true,
            value: v.value0,
            onChange: v.value1
        });
        var modalBody = TacoModal.modalBody({
            contents: [ TacoText.component()()({
                text: "Anna k\xe4ytt\xe4j\xe4lle rooli",
                variant: new TacoText_Types.Heading(1),
                weight: TacoText_Types.Bold.value,
                gutterBottom: true
            }), roleSelect, TacoText.component()()({
                text: "Roolin projektit",
                variant: new TacoText_Types.Heading(1),
                weight: TacoText_Types.Bold.value,
                gutterBottom: true
            }), projectSelector, TacoText.component()()({
                text: "Roolin projektiryhm\xe4t",
                variant: new TacoText_Types.Heading(1),
                weight: TacoText_Types.Bold.value,
                gutterBottom: true
            }), projectGroupSelector ]
        });
        var addRoleButton = (function () {
            if (selectedRole instanceof Data_Maybe.Nothing) {
                return TacoButton.component()()({
                    text: "Lis\xe4\xe4 k\xe4ytt\xe4j\xe4lle rooli",
                    buttonStyle: TacoButton_Types.Outlined.value,
                    disabled: true
                });
            };
            if (selectedRole instanceof Data_Maybe.Just) {
                return TacoButton.component()()({
                    text: "Lis\xe4\xe4 k\xe4ytt\xe4j\xe4lle rooli",
                    buttonStyle: TacoButton_Types.Outlined.value,
                    onClick: new Data_Maybe.Just(function __do() {
                        props.saveAssignment({
                            roleId: selectedRole.value0.id,
                            role: selectedRole.value0.role,
                            projects: v1.value0,
                            projectGroups: v2.value0
                        })();
                        return props.onClose();
                    })
                });
            };
            throw new Error("Failed pattern match at AssignRolesModal (line 216, column 21 - line 233, column 10): " + [ selectedRole.constructor.name ]);
        })();
        var modalActions = TacoModal.modalActions({
            contents: [ TacoButton.component()()({
                onClick: new Data_Maybe.Just(props.onClose),
                text: "Peruuta"
            }), addRoleButton ]
        });
        return TacoModal.component()()({
            isActive: true,
            onClose: props.onClose,
            heading: "Anna rooli",
            contents: [ modalBody, modalActions ]
        });
    };
});
var component = TofuHooks.mkHookComponent("UserRolesModal")(function (props) {
    return function __do() {
        var v = TofuHooks.useState(props.userRoles)();
        var v1 = TofuHooks.useState(AssignmentsModal.value)();
        var setSSOButton = React_Basic_DOM_Generated.div_([ TacoButton.component()()({
            text: "Aseta SSO-kirjautuminen k\xe4ytt\xe4j\xe4lle",
            disabled: props.user.hasSSOLogin,
            title: Data_Monoid.guard(Data_Monoid.monoidString)(props.user.hasSSOLogin)("K\xe4ytt\xe4j\xe4ll\xe4 vain SSO-kirjautuminen"),
            buttonStyle: TacoButton_Types.Outlined.value,
            color: TacoConstants.DangerRedDark.value,
            onClick: new Data_Maybe.Just(WindowUtils.confirm("T\xe4t\xe4 muutosta ei voi perua. T\xe4m\xe4n j\xe4lkeen k\xe4ytt\xe4j\xe4 ei voi en\xe4\xe4 kirjautua salasanalla. Oletko varma?")(props.dispatch(new Actions.SetUserSSOLogin({
                params: {
                    userId: props.userId,
                    userEmail: props.user.email
                },
                organization: props.organization
            }))))
        }) ]);
        var saveAssignments = (function () {
            var roleAssignments = Data_Functor.map(Data_Functor.functorArray)(function (v2) {
                return {
                    roleId: v2.roleId,
                    projects: v2.projects,
                    projectGroups: v2.projectGroups
                };
            })(v.value0);
            return function __do() {
                props.dispatch(new Actions.SetUserRolesRequest({
                    userId: props.userId,
                    organization: props.organization,
                    roleAssignments: roleAssignments
                }))();
                return props.dispatch(Actions.GetOrganizationUsersRequest.value)();
            };
        })();
        var modalActions = TacoModal.modalActions({
            contents: [ TacoButton.component()()({
                onClick: new Data_Maybe.Just(props.onClose),
                text: "Peruuta"
            }), TacoButton.component()()({
                text: "Tallenna k\xe4ytt\xe4j\xe4n roolit",
                buttonStyle: TacoButton_Types.Outlined.value,
                onClick: new Data_Maybe.Just(function __do() {
                    saveAssignments();
                    return props.onClose();
                })
            }) ]
        });
        var editAssignmentButton = function (row) {
            return TacoButton.component()()({
                text: "Muokkaa",
                buttonStyle: TacoButton_Types.Outlined.value,
                onClick: Data_Maybe.Just.create(v1.value1(function (v2) {
                    return new EditAssignmentModal({
                        onClose: v1.value1(function (v3) {
                            return AssignmentsModal.value;
                        }),
                        saveAssignment: function (newAssignment) {
                            return function __do() {
                                v.value1((function () {
                                    var $72 = Data_Array.cons(newAssignment);
                                    var $73 = Data_Array["delete"](Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                                        reflectSymbol: function () {
                                            return "roleId";
                                        }
                                    })(Roles_Types.eqUserRoleId))()({
                                        reflectSymbol: function () {
                                            return "role";
                                        }
                                    })(Roles_Types.userRoleEq))()({
                                        reflectSymbol: function () {
                                            return "projects";
                                        }
                                    })(Data_Eq.eqArray(Types_Project.eqProjectId)))()({
                                        reflectSymbol: function () {
                                            return "projectGroups";
                                        }
                                    })(Data_Eq.eqArray(Types_Project.eqProjectGroup))))(row);
                                    return function ($74) {
                                        return $72($73($74));
                                    };
                                })())();
                                return v1.value1(function (v3) {
                                    return AssignmentsModal.value;
                                })();
                            };
                        },
                        roles: props.orgRoles,
                        assignment: {
                            projects: row.projects,
                            projectGroups: row.projectGroups,
                            roleId: row.roleId,
                            role: row.role
                        },
                        projects: row.projects
                    });
                }))
            });
        };
        var deleteButton = function (row) {
            return TacoButton.component()()({
                text: "Poista",
                buttonStyle: TacoButton_Types.Outlined.value,
                color: TacoConstants.SecondaryRed.value,
                onClick: Data_Maybe.Just.create(v.value1(Data_Array["delete"](Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "roleId";
                    }
                })(Roles_Types.eqUserRoleId))()({
                    reflectSymbol: function () {
                        return "role";
                    }
                })(Roles_Types.userRoleEq))()({
                    reflectSymbol: function () {
                        return "projects";
                    }
                })(Data_Eq.eqArray(Types_Project.eqProjectId)))()({
                    reflectSymbol: function () {
                        return "projectGroups";
                    }
                })(Data_Eq.eqArray(Types_Project.eqProjectGroup))))(row)))
            });
        };
        var cols = [ {
            key: "roleName",
            label: React_Basic_DOM.text("Rooli"),
            cell: new TacoTable_Types.PlainTextCell(function (row) {
                return (Data_Newtype.unwrap()(row.role)).name;
            }),
            sortProperty: Data_Maybe.Nothing.value,
            width: new TacoTable_Types.Flex(1),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "assignedProjects",
            label: React_Basic_DOM.text("Hankkeet"),
            cell: new TacoTable_Types.PlainTextCell(function (row) {
                return Data_Show.show(Data_Show.showArray(Data_Show.showInt))(Data_Functor.map(Data_Functor.functorArray)(Data_Newtype.unwrap())(row.projects));
            }),
            sortProperty: Data_Maybe.Nothing.value,
            width: new TacoTable_Types.Flex(1),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "assignedGroups",
            label: React_Basic_DOM.text("Hankeryhm\xe4t"),
            cell: new TacoTable_Types.PlainTextCell(function (row) {
                return Data_Show.show(Data_Show.showArray(Data_Show.showString))(Data_Functor.map(Data_Functor.functorArray)(Data_Newtype.unwrap())(row.projectGroups));
            }),
            sortProperty: Data_Maybe.Nothing.value,
            width: new TacoTable_Types.Flex(1),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "editAssignment",
            label: React_Basic_DOM.text(""),
            cell: new TacoTable_Types.JSXCell([  ], editAssignmentButton),
            width: new TacoTable_Types.Flex(1),
            sortProperty: Data_Maybe.Nothing.value,
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "removeButton",
            label: React_Basic_DOM.text("Poista"),
            cell: new TacoTable_Types.JSXCell([  ], deleteButton),
            sortProperty: Data_Maybe.Nothing.value,
            width: new TacoTable_Types.Flex(1),
            headerJSX: Data_Maybe.Nothing.value
        } ];
        var rows = (function () {
            var mkRow = function (roleWithAssignment) {
                return {
                    columns: cols,
                    rowData: roleWithAssignment,
                    rowKey: Data_Show.show(Data_Show.showInt)(Data_Newtype.unwrap()(roleWithAssignment.roleId)),
                    onClick: function (v2) {
                        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                    },
                    className: "",
                    foldingElement: Data_Maybe.Nothing.value
                };
            };
            return Data_Functor.map(Data_Functor.functorArray)(mkRow)(v.value0);
        })();
        var table = TacoTable["tableStateless'"](TofuHooks.getNameVoid)(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "roleId";
            }
        })(Roles_Types.eqUserRoleId))()({
            reflectSymbol: function () {
                return "role";
            }
        })(Roles_Types.userRoleEq))()({
            reflectSymbol: function () {
                return "projects";
            }
        })(Data_Eq.eqArray(Types_Project.eqProjectId)))()({
            reflectSymbol: function () {
                return "projectGroups";
            }
        })(Data_Eq.eqArray(Types_Project.eqProjectGroup))))(true)(tableInstance)(rows)(cols);
        var addButton = TacoButton.component()()({
            text: "+ Lis\xe4\xe4",
            buttonStyle: TacoButton_Types.Outlined.value,
            onClick: Data_Maybe.Just.create(v1.value1(function (v2) {
                return new AddAssignmentModal({
                    onClose: v1.value1(function (v3) {
                        return AssignmentsModal.value;
                    }),
                    saveAssignment: function (assignment) {
                        return function __do() {
                            v.value1(Data_Array.cons(assignment))();
                            return v1.value1(function (v3) {
                                return AssignmentsModal.value;
                            })();
                        };
                    },
                    roles: props.orgRoles
                });
            }))
        });
        var modalBody = TacoModal.modalBody({
            contents: [ setSSOButton, table, addButton ]
        });
        var assignmentsModal = TacoModal.component()()({
            isActive: true,
            onClose: props.onClose,
            heading: "Muokkaa k\xe4ytt\xe4j\xe4n " + (props.user.email + " oikeuksia"),
            contents: [ modalBody, modalActions ]
        });
        var modal = (function () {
            if (v1.value0 instanceof AssignmentsModal) {
                return assignmentsModal;
            };
            if (v1.value0 instanceof AddAssignmentModal) {
                return addAssignmentModal(v1.value0.value0);
            };
            if (v1.value0 instanceof EditAssignmentModal) {
                return editAssignmentModal(v1.value0.value0);
            };
            throw new Error("Failed pattern match at AssignRolesModal (line 192, column 13 - line 195, column 53): " + [ v1.value0.constructor.name ]);
        })();
        return modal;
    };
});
module.exports = {
    component: component
};
