// Generated by purs version 0.14.5
"use strict";
var Common = require("../Common/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Effect = require("../Effect/index.js");
var StateHooks = require("../StateHooks/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Utils = require("../Utils/index.js");
var WasmWorker_WorkerRequest = require("../WasmWorker.WorkerRequest/index.js");
var useWorkerItemsByKey = function (projectId) {
    return function (selector) {
        return function (stateKey) {
            return function __do() {
                var wasmWorker = StateHooks.useWasmWorker();
                TofuHooks.useEffect([ stateKey ])((function () {
                    var request = TofuJSON.write(WasmWorker_WorkerRequest.tofuJSONWorkerRequest)(new WasmWorker_WorkerRequest.SetRequestParamsOfDeps({
                        state_key: stateKey,
                        request_params: projectId
                    }));
                    return function __do() {
                        wasmWorker.postMessage(request);
                        return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                    };
                })())();
                var value = StateHooks.useSelector(selector)();
                var value$prime = (function () {
                    var v = Data_Nullable.toMaybe(value);
                    if (v instanceof Data_Maybe.Just) {
                        return v.value0;
                    };
                    if (v instanceof Data_Maybe.Nothing) {
                        return Common.emptyArray;
                    };
                    throw new Error("Failed pattern match at UseWorkerData (line 65, column 14 - line 67, column 28): " + [ v.constructor.name ]);
                })();
                return new Data_Tuple.Tuple(value$prime, Utils.isNull(value));
            };
        };
    };
};
var useWorkerItems = function (selector) {
    return function __do() {
        var value = StateHooks.useSelector(selector)();
        var value$prime = (function () {
            var v = Data_Nullable.toMaybe(value);
            if (v instanceof Data_Maybe.Just) {
                return v.value0;
            };
            if (v instanceof Data_Maybe.Nothing) {
                return Common.emptyArray;
            };
            throw new Error("Failed pattern match at UseWorkerData (line 28, column 14 - line 30, column 28): " + [ v.constructor.name ]);
        })();
        return new Data_Tuple.Tuple(value$prime, Utils.isNull(value));
    };
};
var useWorkerDataByKey = function (projectId) {
    return function (selector) {
        return function (stateKey) {
            return function __do() {
                var wasmWorker = StateHooks.useWasmWorker();
                TofuHooks.useEffect([ stateKey ])((function () {
                    var request = TofuJSON.write(WasmWorker_WorkerRequest.tofuJSONWorkerRequest)(new WasmWorker_WorkerRequest.SetRequestParamsOfDeps({
                        state_key: stateKey,
                        request_params: projectId
                    }));
                    return function __do() {
                        wasmWorker.postMessage(request);
                        return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                    };
                })())();
                var value = StateHooks.useSelector(selector)();
                return new Data_Tuple.Tuple(Data_Nullable.toMaybe(value), Utils.isNull(value));
            };
        };
    };
};
var useWorkerData = function (selector) {
    return function __do() {
        var value = StateHooks.useSelector(selector)();
        var value$prime = Data_Nullable.toMaybe(value);
        return new Data_Tuple.Tuple(value$prime, Utils.isNull(value));
    };
};
module.exports = {
    useWorkerData: useWorkerData,
    useWorkerItems: useWorkerItems,
    useWorkerDataByKey: useWorkerDataByKey,
    useWorkerItemsByKey: useWorkerItemsByKey
};
