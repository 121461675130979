import { useTranslation } from "react-i18next";
import { Accordion, Icon, IconCheck24Px, IconFlagV1 } from "@tocoman/ui";
import { tryMapInvalidCostGroupCodesFromTransferCalculationError } from "../mappers/InvalidCostGroupCodeMapper";
import { CSSProperties } from "react";
import { AdminetMessageContainer } from "./AdminetMessageContainer";
import { AdminetProjectsFetchStatus } from "./ImportCostCalculationToAdminetModal";
import { CostCalculationErrorMessage } from "./CostCalculationErrorMessage";

const messageContainerStyles: CSSProperties = {
  display: "flex",
  alignItems: "center",
  alignContent: "center",
  gap: "20px",
};

type CostCalculationResultsProps = {
  adminetProjectsStatus: AdminetProjectsFetchStatus;
  transferCalculationErrors: unknown;
  transferCalculationSuccess: boolean;
  foundMissingCostGroupCodes: Array<string | undefined>;
  noTransferableCostGroupsPresent: boolean;
};

export const CostCalculationResults = ({
  adminetProjectsStatus,
  transferCalculationErrors,
  transferCalculationSuccess,
  foundMissingCostGroupCodes,
  noTransferableCostGroupsPresent,
}: CostCalculationResultsProps) => {
  const { t } = useTranslation("integration");

  let missingCostGroupCodes: Array<string | undefined> = [
    ...foundMissingCostGroupCodes,
  ];
  if (missingCostGroupCodes.length === 0 && transferCalculationErrors) {
    missingCostGroupCodes = tryMapInvalidCostGroupCodesFromTransferCalculationError(
      transferCalculationErrors
    );
  }

  const missingCostGroupsAmount = missingCostGroupCodes.length;

  if (transferCalculationSuccess) {
    return (
      <AdminetMessageContainer
        title={"adminet.transferCalculation.success.title"}
        message={"adminet.transferCalculation.success.message"}
        icon={IconCheck24Px}
      ></AdminetMessageContainer>
    );
  }

  return (
    <>
      <CostCalculationErrorMessage
        adminetProjectsStatus={adminetProjectsStatus}
        transferCalculationErrors={transferCalculationErrors}
        noTransferableCostGroupsPresent={noTransferableCostGroupsPresent}
      ></CostCalculationErrorMessage>

      {missingCostGroupCodes.length > 0 && (
        <div style={messageContainerStyles}>
          <Icon scale={2} icon={IconFlagV1}></Icon>
          <Accordion
            fullWidth={true}
            items={[
              {
                title: t`adminet.transferCalculation.missingCostGroups.title`,
                subtitle: t(
                  `adminet.transferCalculation.missingCostGroups.message`,
                  {
                    amountInvalid: missingCostGroupsAmount,
                  }
                ),
                contents: missingCostGroupCodes.join(", "),
                defaultOpen: true,
              },
            ]}
          ></Accordion>
        </div>
      )}
    </>
  );
};
