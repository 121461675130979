// Generated by purs version 0.14.5
"use strict";
var Client_WASM = require("../Client.WASM/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Show = require("../Data.Show/index.js");
var Effect = require("../Effect/index.js");
var LocalStorageKeys = require("../LocalStorageKeys/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_CostClass = require("../Types.CostClass/index.js");
var UseLocalStorage = require("../UseLocalStorage/index.js");
var WasmWorker = require("../WasmWorker/index.js");
var WasmWorker_WorkerRequest = require("../WasmWorker.WorkerRequest/index.js");
var useSocialExpensesInCosts = function (projectId) {
    return function __do() {
        var v = Client_WASM.useCostClasses(projectId)();
        var showSocialExpensesInCostsKey = LocalStorageKeys.getLocalStorageKeyStr(new LocalStorageKeys.UIEstimationShowPricesWithSocialExpenses(projectId));
        var v1 = UseLocalStorage.useLocalStorage(showSocialExpensesInCostsKey)("false")(false)();
        var showSocialExpensesInCosts = v1.value0 === Data_Show.show(Data_Show.showBoolean)(true);
        TofuHooks.useEffect([ showSocialExpensesInCosts ])(function __do() {
            WasmWorker.setWasmWorkerState(new WasmWorker_WorkerRequest.ShowSocExpPercentagesInCostsState(showSocialExpensesInCosts))(Data_Maybe.Nothing.value)();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        })();
        var onSocialExpensesToggle = TofuHooks.useMemo([  ])(function (v2) {
            return v1.value1(function (value) {
                return Data_Show.show(Data_Show.showBoolean)(!(value === Data_Show.show(Data_Show.showBoolean)(true)));
            });
        })();
        var socialExpensePercentagesByCostClass$prime = ReactHooksUtils.usePersistMemo("useSocialExpensesInCosts")([ ReactHooksUtils.utf(v.value0) ])(function (v2) {
            return Types_CostClass.getEstimationSocialExpensePercentagesByCostClassWithHash(v.value0);
        })();
        var socialExpensePercentagesByCostClassWithHash = (function () {
            if (showSocialExpensesInCosts) {
                return socialExpensePercentagesByCostClass$prime;
            };
            return Types_CostClass.emptySocialExpensePercentagesByCostClassWithHash;
        })();
        return {
            socialExpensePercentagesByCostClass: socialExpensePercentagesByCostClassWithHash.value,
            unfilteredSocialExpensePercentagesByCostClass: socialExpensePercentagesByCostClass$prime,
            socialExpensePercentagesByCostClassWithHash: socialExpensePercentagesByCostClassWithHash,
            showSocialExpensesInCosts: showSocialExpensesInCosts,
            onSocialExpensesToggle: onSocialExpensesToggle
        };
    };
};
var emptyMap = Data_Map_Internal.empty;
module.exports = {
    emptyMap: emptyMap,
    useSocialExpensesInCosts: useSocialExpensesInCosts
};
