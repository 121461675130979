exports.prepareRootReducer = function (reducers) {
  let rootState = {};
  let keys = Object.keys(reducers);

  keys.forEach((key) => {
    let reducer = reducers[key];
    rootState[key] = reducer.initialState;
  });

  const update = (currRootState) => (action) => {
    let newRootState = {};

    keys.forEach((key) => {
      let currState = currRootState[key];
      let newState = reducers[key].update(currState)(action);
      newRootState[key] = newState;
    });

    return newRootState;
  };

  return {
    initialState: rootState,
    update,
  };
};
