import { Button } from "@tocoman/ui";
import { useTranslation } from "react-i18next";
import { OfferClassificationSummary } from "./OfferPage";
import { AgGridReact } from "ag-grid-react";

type UndoRedoButtonsProps = {
  gridRef: React.RefObject<AgGridReact<OfferClassificationSummary>>;
  undoRedoStack: { undo: number; redo: number };
  resetClicked: (value: boolean) => void;
};

export const UndoRedoButtons = ({
  gridRef,
  undoRedoStack,
  resetClicked,
}: UndoRedoButtonsProps) => {
  const { t } = useTranslation("estimation", { keyPrefix: "offerPage" });

  const handleResetClicked = () => {
    resetClicked(true);
    const undoSize = gridRef.current?.api.getCurrentUndoSize() ?? 0;
    for (let i = 0; i < undoSize; i++) {
      gridRef.current?.api.undoCellEditing();
    }
  };

  return (
    <div className={"flex gap-3"}>
      <Button
        variant={"secondary"}
        label={t`reset`}
        onClick={handleResetClicked}
      />
      <Button
        variant={"secondary"}
        label={t`redo` + " " + `(${undoRedoStack.redo})`}
        onClick={() => gridRef.current?.api.redoCellEditing()}
        disabled={undoRedoStack.redo === 0}
      />
      <Button
        variant={"secondary"}
        label={t`undo` + " " + `(${undoRedoStack.undo})`}
        onClick={() => gridRef.current?.api.undoCellEditing()}
        disabled={undoRedoStack.undo === 0}
      />
    </div>
  );
};
