// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var dixonToolbarStyles = Box.boxStyle([ Box.BorderRadius.create(new TacoConstants.CustomSize("10px")), new Box.BackgroundColor(TacoConstants.GrayLightest.value), Box.FlexRow.value, new Box.Border(TacoConstants.BorderLight.value), new Box.Overflow(Box.Hidden.value, Box.Hidden.value), Box.AlignCenter.value ]);
var dixonToolbarContainerStyles = Box.boxStyle([ new Box.Position(Box.Absolute.value), Box.PosTop.create(new TacoConstants.CustomSize("0")), Box.PosLeft.create(new TacoConstants.CustomSize("0")), Box.PosRight.create(new TacoConstants.CustomSize("0")), Box.FlexRow.value, Box.JustifyCenter.value, new Box.PaddingTop(TacoConstants.M.value), new Box.Gap(TacoConstants.M.value) ]);
module.exports = {
    dixonToolbarContainerStyles: dixonToolbarContainerStyles,
    dixonToolbarStyles: dixonToolbarStyles
};
