// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind_Indexed = require("../Control.Bind.Indexed/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Effect = require("../Effect/index.js");
var Render = function (x) {
    return x;
};
var unsafeRenderEffect = Render;
var unsafeHook = Render;
var semigroupRender = function (dictTypeEquals) {
    return function (dictSemigroup) {
        return {
            append: function (v) {
                return function (v1) {
                    return Data_Semigroup.append(Effect.semigroupEffect(dictSemigroup))(v)(v1);
                };
            }
        };
    };
};
var monoidRender = function (dictTypeEquals) {
    return function (dictMonoid) {
        return {
            mempty: Data_Monoid.mempty(Effect.monoidEffect(dictMonoid)),
            Semigroup0: function () {
                return semigroupRender(dictTypeEquals)(dictMonoid.Semigroup0());
            }
        };
    };
};
var ixFunctorRender = {
    imap: function (f) {
        return function (v) {
            return Data_Functor.map(Effect.functorEffect)(f)(v);
        };
    }
};
var ixApplyRender = {
    iapply: function (v) {
        return function (v1) {
            return Control_Apply.apply(Effect.applyEffect)(v)(v1);
        };
    },
    IxFunctor0: function () {
        return ixFunctorRender;
    }
};
var ixBindRender = {
    ibind: function (v) {
        return function (f) {
            return function __do() {
                var a = v();
                var v1 = f(a);
                return v1();
            };
        };
    },
    IxApply0: function () {
        return ixApplyRender;
    }
};
var ixApplicativeRender = {
    ipure: function (a) {
        return Control_Applicative.pure(Effect.applicativeEffect)(a);
    },
    IxApply0: function () {
        return ixApplyRender;
    }
};
var ixMonadRender = {
    IxApplicative0: function () {
        return ixApplicativeRender;
    },
    IxBind1: function () {
        return ixBindRender;
    }
};
var functorRender = {
    map: function (f) {
        return function (v) {
            return Data_Functor.map(Effect.functorEffect)(f)(v);
        };
    }
};
var discard = function (dictIxBind) {
    return Control_Bind_Indexed.ibind(dictIxBind);
};
var coerceHook = function (dictNewtype) {
    return function (v) {
        return v;
    };
};
var bind = function (dictIxBind) {
    return Control_Bind_Indexed.ibind(dictIxBind);
};
var applyRender = function (dictTypeEquals) {
    return {
        apply: function (v) {
            return function (v1) {
                return Control_Apply.apply(Effect.applyEffect)(v)(v1);
            };
        },
        Functor0: function () {
            return functorRender;
        }
    };
};
var bindRender = function (dictTypeEquals) {
    return {
        bind: function (v) {
            return function (f) {
                return function __do() {
                    var a = v();
                    var v1 = f(a);
                    return v1();
                };
            };
        },
        Apply0: function () {
            return applyRender(dictTypeEquals);
        }
    };
};
var applicativeRender = function (dictTypeEquals) {
    return {
        pure: function (a) {
            return Control_Applicative.pure(Effect.applicativeEffect)(a);
        },
        Apply0: function () {
            return applyRender(dictTypeEquals);
        }
    };
};
var monadRender = function (dictTypeEquals) {
    return {
        Applicative0: function () {
            return applicativeRender(dictTypeEquals);
        },
        Bind1: function () {
            return bindRender(dictTypeEquals);
        }
    };
};
module.exports = {
    coerceHook: coerceHook,
    unsafeHook: unsafeHook,
    unsafeRenderEffect: unsafeRenderEffect,
    bind: bind,
    discard: discard,
    ixFunctorRender: ixFunctorRender,
    ixApplyRender: ixApplyRender,
    ixApplicativeRender: ixApplicativeRender,
    ixBindRender: ixBindRender,
    ixMonadRender: ixMonadRender,
    functorRender: functorRender,
    applyRender: applyRender,
    applicativeRender: applicativeRender,
    bindRender: bindRender,
    monadRender: monadRender,
    semigroupRender: semigroupRender,
    monoidRender: monoidRender
};
