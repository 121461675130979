// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Components_Utils = require("../Components.Utils/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Category = require("../Control.Category/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var Record = require("../Record/index.js");
var TacoAutocomplete_Styles = require("../TacoAutocomplete.Styles/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoInput_Styles = require("../TacoInput.Styles/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var UseKeydownListener = require("../UseKeydownListener/index.js");
var Utils_Keyboard = require("../Utils.Keyboard/index.js");
var defaultProps = {
    sections: [  ],
    selected: Data_Maybe.Nothing.value,
    onSelect: Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit))),
    onCreateNew: Data_Maybe.Nothing.value,
    placeholder: "",
    testId: "TacoAutocomplete",
    clearButton: false,
    inputRendering: TacoInput_Styles.DefaultRendering.value,
    autoFocus: false,
    showAbove: false,
    label: "",
    expanded: Data_Maybe.Nothing.value,
    title: "",
    error: false,
    disabled: false
};
var autocompleteMatch = TofuHooks.mkHookComponent("TacoAutocomplete__Match")(function (props) {
    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        className: "__TacoAutocomplete__Match",
        css: TacoAutocomplete_Styles.autocompleteMatchStyles({
            selected: props.selected,
            focused: props.focused
        }),
        children: [ React_Basic_DOM.text(props.item.text) ],
        onClick: React_Basic_Events.handler_(function __do() {
            props.closeInput();
            return props.onSelect(new Data_Maybe.Just(props.item))();
        }),
        onMouseEnter: React_Basic_Events.handler_(props.focus),
        "_data": Components_Utils.mkTestIdAttrs(props.testId + ("-item-" + props.item.id))
    }));
});
var autocompleteMatches = TofuHooks.mkHookComponent("TacoAutocomplete__Matches")(function (props) {
    return function __do() {
        var v = TofuHooks.useState(false)();
        var toggleExpanded = TofuHooks.useMemo([  ])(function (v1) {
            return v.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean));
        })();
        var shortenIfExpanded = function (list) {
            var $29 = Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqBoolean))(props.expanded)(new Data_Maybe.Just(true)) || v.value0;
            if ($29) {
                return list;
            };
            return Data_Array.take(5)(list);
        };
        var separator = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "__TacoAutocomplete__Separator",
            css: TacoAutocomplete_Styles.tacoAutocompleteSeparatorStyles
        });
        var renderMatch = function (index) {
            return function (item) {
                return autocompleteMatch({
                    closeInput: props.closeInput,
                    item: item,
                    onSelect: props.onSelect,
                    selected: Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                        reflectSymbol: function () {
                            return "text";
                        }
                    })(Data_Eq.eqString))()({
                        reflectSymbol: function () {
                            return "id";
                        }
                    })(Data_Eq.eqString))))(props.selected)(new Data_Maybe.Just(item)),
                    focused: props.focusIdx === index,
                    focus: props.setFocusIdx(function (v1) {
                        return index;
                    }),
                    testId: props.testId
                });
            };
        };
        var maxIdx = props.filteredItemsCount - 1 | 0;
        var heading = function (text) {
            return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                className: "__TacoAutocomplete__Heading",
                css: TacoAutocomplete_Styles.tacoAutocompleteHeadingStyles,
                children: [ React_Basic_DOM.text(text) ]
            });
        };
        var renderSection = function (acc) {
            return function (section) {
                var list = shortenIfExpanded(section.list);
                var result = Data_Semigroup.append(Data_Semigroup.semigroupArray)(acc.result)([ React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                    className: "__TacoAutocomplete__Section",
                    css: TacoAutocomplete_Styles.tacoAutocompleteSectionStyles,
                    children: Data_Semigroup.append(Data_Semigroup.semigroupArray)([ separator, ReactBasicUtils.maybeJSX(React_Basic.monoidJSX)(Data_Functor.map(Data_Maybe.functorMaybe)(heading)(section.text)) ])(Data_Array.mapWithIndex(function ($79) {
                        return renderMatch((function (v1) {
                            return v1 + acc.indexOffset | 0;
                        })($79));
                    })(shortenIfExpanded(list)))
                }) ]);
                var indexOffset = Data_Array.length(list);
                return {
                    indexOffset: acc.indexOffset + indexOffset | 0,
                    result: result
                };
            };
        };
        UseKeydownListener.useKeydownListener([ ReactHooksUtils.utf(props.focusIdx), ReactHooksUtils.utf(props.filteredSections), ReactHooksUtils.utf(maxIdx) ])(true)([ Utils_Keyboard.ArrowDown.value, Utils_Keyboard.ArrowUp.value, Utils_Keyboard.Enter.value ])(ReactHooksUtils.utf("TacoAutocompleteSuggestionsInput"))(function (event) {
            if (event.key instanceof Utils_Keyboard.ArrowDown) {
                return function __do() {
                    Utils_Keyboard.preventDefault(event.event)();
                    return props.setFocusIdx(function (idx) {
                        return Data_Ord.min(Data_Ord.ordInt)(maxIdx)(idx + 1 | 0);
                    })();
                };
            };
            if (event.key instanceof Utils_Keyboard.ArrowUp) {
                return function __do() {
                    Utils_Keyboard.preventDefault(event.event)();
                    return props.setFocusIdx(function (idx) {
                        return Data_Ord.max(Data_Ord.ordInt)(0)(idx - 1 | 0);
                    })();
                };
            };
            if (event.key instanceof Utils_Keyboard.Enter) {
                var item = Data_Array.index(props.filteredItems)(props.focusIdx);
                if (item instanceof Data_Maybe.Just) {
                    return function __do() {
                        props.onSelect(new Data_Maybe.Just(item.value0))();
                        return props.closeInput();
                    };
                };
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            };
            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
        })();
        var sections = (function (v1) {
            return v1.result;
        })(Data_Array.foldl(renderSection)({
            indexOffset: 0,
            result: [  ]
        })(props.filteredSections));
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "__TacoAutocomplete__Matches",
            css: TacoAutocomplete_Styles.autocompleteMatchesStyles,
            children: Data_Semigroup.append(Data_Semigroup.semigroupArray)(sections)([ Data_Monoid.guard(Data_Monoid.monoidFn(React_Basic.monoidJSX))(Data_Maybe.isNothing(props.expanded))(TacoButton.component()())({
                onClick: new Data_Maybe.Just(toggleExpanded),
                text: (function () {
                    if (v.value0) {
                        return "N\xe4yt\xe4 v\xe4hemm\xe4n";
                    };
                    return "N\xe4yt\xe4 kaikki " + Data_Show.show(Data_Show.showInt)(Data_Array.length(props.filteredItems));
                })(),
                testId: props.testId + "-show-all-results-button"
            }), (function () {
                if (props.onCreateNew instanceof Data_Maybe.Just) {
                    return TacoButton.component()()({
                        onClick: new Data_Maybe.Just(function __do() {
                            props.onCreateNew.value0();
                            return props.closeInput();
                        }),
                        text: "Luo uusi"
                    });
                };
                if (props.onCreateNew instanceof Data_Maybe.Nothing) {
                    return React_Basic.empty;
                };
                throw new Error("Failed pattern match at TacoAutocomplete (line 317, column 11 - line 324, column 32): " + [ props.onCreateNew.constructor.name ]);
            })() ])
        });
    };
});
var component = function (dictUnion) {
    return function (dictNub) {
        return TofuHooks.mkHookComponent("TacoAutocomplete")(function (props$prime) {
            var v = Record.merge()()(props$prime)(defaultProps);
            return function __do() {
                var v1 = TofuHooks.useState(Data_Maybe.Nothing.value)();
                var filterStrRef = TofuHooks.useRef(v1.value0)();
                TofuHooks.useEffect([ Data_Nullable.toNullable(v.selected) ])(function __do() {
                    (function () {
                        if (v.selected instanceof Data_Maybe.Just) {
                            return v1.value1(function (v2) {
                                return new Data_Maybe.Just(v.selected.value0.text);
                            })();
                        };
                        if (v.selected instanceof Data_Maybe.Nothing) {
                            return Data_Unit.unit;
                        };
                        throw new Error("Failed pattern match at TacoAutocomplete (line 106, column 5 - line 108, column 27): " + [ v.selected.constructor.name ]);
                    })();
                    return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                })();
                TofuHooks.useEffect([ v1.value0 ])(function __do() {
                    React_Basic_Hooks.writeRef(filterStrRef)(v1.value0)();
                    return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                })();
                var resetFilterStr = function __do() {
                    var filterStr$prime = React_Basic_Hooks.readRef(filterStrRef)();
                    return Data_Monoid.guard(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit)))(Data_Maybe.isJust(filterStr$prime))(v1.value1)(function (v2) {
                        return Data_Functor.map(Data_Maybe.functorMaybe)(function (v3) {
                            return v3.text;
                        })(v.selected);
                    })();
                };
                var filterForDisplay = function (s) {
                    return function (item) {
                        var displayStr = Data_String_Common.toLower(item.text);
                        return Data_String_CodeUnits.contains(Data_String_Common.toLower(s))(displayStr);
                    };
                };
                var filterFn = (function () {
                    if (v1.value0 instanceof Data_Maybe.Just) {
                        return Data_Array.filter(filterForDisplay(v1.value0.value0));
                    };
                    if (v1.value0 instanceof Data_Maybe.Nothing) {
                        return Control_Category.identity(Control_Category.categoryFn);
                    };
                    throw new Error("Failed pattern match at TacoAutocomplete (line 131, column 16 - line 133, column 26): " + [ v1.value0.constructor.name ]);
                })();
                var filteredSections = Data_Functor.map(Data_Functor.functorArray)(function (section) {
                    return {
                        text: section.text,
                        list: filterFn(section.list)
                    };
                })(v.sections);
                var filteredItems = Data_Array.concat(Data_Functor.map(Data_Functor.functorArray)(function (v2) {
                    return v2.list;
                })(filteredSections));
                var filteredItemsCount = Data_Array.length(filteredItems);
                var selectedIdx = Data_Array.findIndex(function (item) {
                    return Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                        reflectSymbol: function () {
                            return "text";
                        }
                    })(Data_Eq.eqString))()({
                        reflectSymbol: function () {
                            return "id";
                        }
                    })(Data_Eq.eqString))))(new Data_Maybe.Just(item))(v.selected);
                })(filteredItems);
                var v2 = TofuHooks.useState(Data_Maybe.fromMaybe(0)(selectedIdx))();
                TofuHooks.useEffect([ v1.value0 ])(function __do() {
                    Data_Monoid.guard(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit)))(Data_Maybe.isJust(v1.value0))(v2.value1)(function (v3) {
                        return 0;
                    })();
                    return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                })();
                var handleFocus = function __do() {
                    v1.value1(function (v3) {
                        return Data_Maybe.Nothing.value;
                    })();
                    var selectedIdx$prime = Data_Array.findIndex(function (item) {
                        return Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                            reflectSymbol: function () {
                                return "text";
                            }
                        })(Data_Eq.eqString))()({
                            reflectSymbol: function () {
                                return "id";
                            }
                        })(Data_Eq.eqString))))(new Data_Maybe.Just(item))(v.selected);
                    })(filteredItems);
                    return v2.value1(function (v3) {
                        return Data_Maybe.fromMaybe(0)(selectedIdx$prime);
                    })();
                };
                var handleClear = function __do() {
                    v.onSelect(Data_Maybe.Nothing.value)();
                    v1.value1(function (v3) {
                        return Data_Maybe.Nothing.value;
                    })();
                    return React_Basic_Hooks.writeRef(filterStrRef)(Data_Maybe.Nothing.value)();
                };
                return TacoInput.stringInput()()(Data_Eq.eqString)({
                    testId: v.testId,
                    value: (function () {
                        var $55 = Data_Maybe.isJust(v1.value0);
                        if ($55) {
                            return Data_Maybe.fromMaybe("")(v1.value0);
                        };
                        return Data_Maybe.fromMaybe("")(Data_Functor.map(Data_Maybe.functorMaybe)(function (v3) {
                            return v3.text;
                        })(v.selected));
                    })(),
                    onChange: TacoInput.transformInput(TacoInput.inputValueString)(Data_Maybe.Just.create)(v1.value1),
                    onBlur: resetFilterStr,
                    onFocus: handleFocus,
                    clearButton: v.clearButton,
                    onClear: handleClear,
                    placeholder: v.placeholder,
                    rendering: v.inputRendering,
                    customComponent: Data_Maybe.Just.create(autocompleteMatches({
                        closeInput: Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit),
                        filteredSections: filteredSections,
                        filteredItems: filteredItems,
                        filteredItemsCount: filteredItemsCount,
                        onSelect: v.onSelect,
                        selected: v.selected,
                        focusIdx: v2.value0,
                        setFocusIdx: v2.value1,
                        testId: v.testId,
                        onCreateNew: (function () {
                            if (v.onCreateNew instanceof Data_Maybe.Just && v1.value0 instanceof Data_Maybe.Just) {
                                return Data_Maybe.Just.create(v.onCreateNew.value0(v1.value0.value0));
                            };
                            return Data_Maybe.Nothing.value;
                        })(),
                        expanded: v.expanded
                    })),
                    customComponentWrapperStyles: Data_Monoid.guard(Data_Monoid.monoidArray)(v.showAbove)([ new Box.Position(Box.Absolute.value), new Box.PosBottom(TacoConstants.XL.value), new Box.Width(new TacoConstants.CustomSize("100%")) ]),
                    autoFocus: v.autoFocus,
                    label: v.label,
                    title: v.title,
                    error: v.error,
                    disabled: v.disabled
                });
            };
        });
    };
};
module.exports = {
    component: component
};
