import { ICellRendererParams } from "ag-grid-community";
import "./editableCells.css";

export const editableCellRenderer = (params: ICellRendererParams) => {
  const defaultClasses = "editable-input hover:shadow";
  const numericClasses = "text-right" + " " + defaultClasses;
  const inputClasses = params.colDef?.type?.includes("numericColumn")
    ? numericClasses
    : defaultClasses;
  return (
    <div className={inputClasses}>{params.valueFormatted ?? params.value}</div>
  );
};
export const editableCellColDef = {
  cellRenderer: editableCellRenderer,
  cellClass: "editable-cell",
  singleClickEdit: true,
  editable: true,
};
