import { Checkbox, Header } from "@tocoman/ui";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import { User } from "ts-bindings/User";
import { UserSSO } from "./UserSSO";

type MainUserRolesProps = {
  disabled: boolean;
  user: User | null;
  orgSSO: boolean;
};

export const MainUserRoles = ({
  disabled,
  user,
  orgSSO,
}: MainUserRolesProps) => {
  const { t } = useTranslation("admin", { keyPrefix: "users.edit" });
  const { control } = useFormContext();

  return (
    <div className="flex flex-col gap-5">
      <Header title={t`mainUser`} titleSize="small">
        <p>{t`mainUserDescriptions`}</p>
      </Header>
      <div className="flex-1 flex flex-row gap-64">
        <Controller
          control={control}
          name={"mainUser"}
          render={({ field: { value, onChange } }) => (
            <Checkbox
              disabled={disabled}
              checked={value}
              setChecked={onChange}
              label={t`form.mainUser`}
            />
          )}
        />
        <Controller
          control={control}
          name={"costClassTargetEdit"}
          render={({ field: { value, onChange } }) => (
            <Checkbox
              disabled={disabled}
              checked={value}
              setChecked={onChange}
              label={t`form.costClassTargetEdit`}
            />
          )}
        />
      </div>
      <Header title={t`newProjects`} titleSize="small">
        <p>{t`newProjectsDescription`}</p>
      </Header>

      <Controller
        control={control}
        name={"createProjectsPermission"}
        render={({ field: { value, onChange } }) => (
          <Checkbox
            disabled={disabled}
            checked={value}
            setChecked={onChange}
            label={t`form.createProjectsPermission`}
          />
        )}
      />
      <UserSSO user={user} orgSSO={orgSSO} />
    </div>
  );
};
