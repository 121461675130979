import { Measurement } from "../../../../../ts-bindings/Measurement";
import { useTranslation } from "react-i18next";

export function useMeasurementValidations() {
  const { t } = useTranslation("projects", { keyPrefix: "details" });

  function validateMeasurementName(
    value: string | number | boolean | null,
    measurementUnits: Measurement[]
  ): [string, boolean] {
    if (!value) {
      return ["", false];
    }
    const validName = validateUniqueMeasurement(value, measurementUnits);
    const validLength = validateMeasurementNameLength(value.toString());

    if (validName && validLength) {
      return ["", true];
    } else if (!validName) {
      return ["unique", false];
    } else if (!validLength) {
      return ["length", false];
    }
    return ["all", false];
  }

  const validateMeasurementNameLength = (value: string) => value.length <= 50;

  function validateUniqueMeasurement(
    value: string | number | boolean,
    measurements: Measurement[]
  ) {
    const valueToCaseInsensitive = (value: string | number | boolean) =>
      value.toString().toLowerCase();
    const foundMeasurement = measurements.find(
      (measurement) =>
        valueToCaseInsensitive(measurement.unit.name) ===
        valueToCaseInsensitive(value)
    );
    return foundMeasurement === undefined;
  }

  function measurementError(errorType: string) {
    if (errorType === "unique") {
      return t`measurementUnits.errors.uniqueName`;
    } else if (errorType === "length") {
      return t`measurementUnits.errors.nameLength`;
    } else if (errorType === "required") {
      return t`measurementUnits.errors.required`;
    }
    return "";
  }

  return {
    validateMeasurementName,
    validateUniqueMeasurement,
    validateMeasurementNameLength,
    measurementError,
  };
}
