// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Data_Show = require("../Data.Show/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Exception = require("../Effect.Exception/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var ReactQuery = require("../ReactQuery/index.js");
var ReactQuery_Internal = require("../ReactQuery.Internal/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_Organization = require("../Types.Organization/index.js");
var Types_ServerRoutes = require("../Types.ServerRoutes/index.js");
var tsqueryDemo = TofuHooks.mkHookComponent("tsqueryDemo")(function (v) {
    return function __do() {
        var succeeds = ReactQuery.useQuery(TofuJSON.readString)("succeedsDemoKey")(Control_Applicative.pure(Effect_Aff.applicativeAff)("succeeds value"))();
        var fails = ReactQuery.useQuery(TofuJSON.readString)("failsDemoKey")(Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(Effect_Exception.error("failure")))();
        var succeedsEventually = ReactQuery.useQuery(TofuJSON.readString)("succeedsEventuallyDemoKey")(Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Aff.delay(10000.0))(function () {
            return Control_Applicative.pure(Effect_Aff.applicativeAff)("succeeds eventually value");
        }))();
        var orgInfo = ReactQuery.useGetRoute(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "organization";
            }
        })(Types_Organization.tofuJSONOrganization)(TofuJSON.readFieldsCons({
            reflectSymbol: function () {
                return "plan";
            }
        })(Types_Organization.tofuJSONPlan)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "organization";
            }
        })(Types_Organization.tofuJSONOrganization)(TofuJSON.consWriteForeignFields({
            reflectSymbol: function () {
                return "plan";
            }
        })(Types_Organization.tofuJSONPlan)(TofuJSON.nilWriteForeignFields)()()())()()()))("currentOrgInfo")(Types_ServerRoutes.serverRoutes.currentOrganization)({})();
        return React_Basic_DOM_Generated.div_([ React_Basic_DOM_Generated.div_([ React_Basic_DOM.text("succeeds: " + Data_Show.show(ReactQuery_Internal.showQueryResult(Data_Show.showString))(succeeds)) ]), React_Basic_DOM_Generated.div_([ React_Basic_DOM.text("fails: " + Data_Show.show(ReactQuery_Internal.showQueryResult(Data_Show.showString))(fails)) ]), React_Basic_DOM_Generated.div_([ React_Basic_DOM.text("succeedsEventually: " + Data_Show.show(ReactQuery_Internal.showQueryResult(Data_Show.showString))(succeedsEventually)) ]), React_Basic_DOM_Generated.div_([ React_Basic_DOM.text("orgInfo: " + Data_Show.show(ReactQuery_Internal.showQueryResult(Data_Show.showRecord()(Data_Show.showRecordFieldsCons({
            reflectSymbol: function () {
                return "organization";
            }
        })(Data_Show.showRecordFieldsCons({
            reflectSymbol: function () {
                return "plan";
            }
        })(Data_Show.showRecordFieldsNil)(Types_Organization.showPlan))(Types_Organization.showOrganization))))(orgInfo)) ]) ]);
    };
});
var sections = [ {
    title: "ReactQuery",
    view: [ tsqueryDemo({}) ],
    code: "\x0atsqueryDemo :: {} -> RB.JSX\x0atsqueryDemo = mkHookComponent \"tsqueryDemo\" \\_ -> do\x0a  succeeds <- useQuery \"succeedsDemoKey\" $ pure \"succeeds value\"\x0a  fails <- useQuery \"failsDemoKey\" $ (throwError $ error \"failure\" :: Aff String)\x0a  succeedsEventually <- useQuery \"succeedsEventuallyDemoKey\" do\x0a    delay $ Milliseconds 10000.0\x0a    pure \"succeeds eventually value\"\x0a  orgInfo <- useGetRoute \"currentOrgInfo\" serverRoutes.currentOrganization {}\x0a\x0a\x0a  pure $ R.div_\x0a    [ R.div_ [R.text $ \"succeeds: \" <> show succeeds]\x0a    , R.div_ [R.text $ \"fails: \" <> show fails]\x0a    , R.div_ [R.text $ \"succeedsEventually: \" <> show succeedsEventually]\x0a    , R.div_ [R.text $ \"orgInfo: \" <> show orgInfo]\x0a    ]\x0a    "
} ];
var demoView = {
    sections: sections,
    title: "ReactQuery",
    description: "ReactQuery wrapper for purescript"
};
module.exports = {
    tsqueryDemo: tsqueryDemo,
    sections: sections,
    demoView: demoView
};
