// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Show = require("../Data.Show/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var Record = require("../Record/index.js");
var StyleProperties = require("../StyleProperties/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var svgStyles = function (rotating) {
    return function (rotation) {
        var rotationDegStr = Data_Show.show(Data_Show.showInt)(rotation);
        return React_Basic_Emotion.css()({
            width: React_Basic_Emotion.str("100%"),
            height: React_Basic_Emotion.str("100%"),
            fill: React_Basic_Emotion.str("currentColor"),
            display: React_Basic_Emotion.str("block"),
            transform: React_Basic_Emotion.str("rotate(" + (rotationDegStr + "deg)")),
            transition: (function () {
                if (rotating) {
                    return React_Basic_Emotion.str("all 0.2s ease-out");
                };
                return React_Basic_Emotion.str("");
            })()
        });
    };
};
var iconSize = function (size) {
    if (size instanceof TacoConstants.XXS) {
        return StyleProperties.size(TacoConstants.S.value);
    };
    if (size instanceof TacoConstants.XS) {
        return StyleProperties.size(TacoConstants.M.value);
    };
    if (size instanceof TacoConstants.S) {
        return StyleProperties.size(TacoConstants.L.value);
    };
    if (size instanceof TacoConstants.M) {
        return StyleProperties.size(TacoConstants.XL.value);
    };
    if (size instanceof TacoConstants.L) {
        return StyleProperties.size(TacoConstants.XXL.value);
    };
    if (size instanceof TacoConstants.XL) {
        return React_Basic_Emotion.str("4rem");
    };
    if (size instanceof TacoConstants.CustomSize) {
        return React_Basic_Emotion.str(size.value0);
    };
    return StyleProperties.size(TacoConstants.XL.value);
};
var styles = function (props) {
    var size = iconSize(props.size);
    var color = StyleProperties.colorScheme(props.color);
    return React_Basic_Emotion.css()({
        display: React_Basic_Emotion.str("inline-block"),
        height: size,
        minHeight: size,
        width: size,
        minWidth: size,
        color: (function () {
            if (props.inheritColor) {
                return React_Basic_Emotion.inherit;
            };
            return color;
        })(),
        svg: React_Basic_Emotion.nested(svgStyles(props.rotating)(props.rotation))
    });
};
var defaultProps = {
    size: TacoConstants.M.value,
    color: TacoConstants.GrayDarkest.value,
    inheritColor: false,
    icon: TacoIcon_Icons_Types.IconArrowLeft.value,
    rotation: 0,
    rotating: false,
    title: ""
};
var component = function (dictUnion) {
    return function (dictNub) {
        return function (dictLacks) {
            return function (dictLacks1) {
                return TofuHooks.mkHookComponent("TacoIcon")(function (props$prime) {
                    var v = Record.merge()()(props$prime)(defaultProps);
                    return Control_Applicative.pure(Effect.applicativeEffect)((function () {
                        var $10 = v.title === "";
                        if ($10) {
                            return React_Basic_Emotion.element(React_Basic_DOM_Generated["span'"]())({
                                css: styles(v),
                                className: "__TacoIcon",
                                children: [ TacoIcon_Icons_Types.iconJSX(v.icon) ]
                            });
                        };
                        return React_Basic_Emotion.element(React_Basic_DOM_Generated["span'"]())({
                            css: styles(v),
                            className: "__TacoIcon",
                            children: [ TacoIcon_Icons_Types.iconJSX(v.icon) ],
                            title: v.title
                        });
                    })());
                });
            };
        };
    };
};
module.exports = {
    iconSize: iconSize,
    svgStyles: svgStyles,
    styles: styles,
    defaultProps: defaultProps,
    component: component
};
