type PdfFrameProps = {
  pdfUrl: string | null;
  width?: string;
  height?: string;
  className?: string;
};
export function PdfFrame({ pdfUrl, width, height, className }: PdfFrameProps) {
  return (
    <div className={`m-3 ${className}`}>
      {!pdfUrl ? null : (
        <object
          style={{
            width: width ?? "880px",
            height: height ?? "70vh",
          }}
          data={pdfUrl}
          type={"application/pdf"}
        >
          <embed src={pdfUrl} type={"application/pdf"} />
        </object>
      )}
    </div>
  );
}
