// Generated by purs version 0.14.5
"use strict";
var Components_Utils = require("../Components.Utils/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var HtmlUtils = require("../HtmlUtils/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_Classic = require("../React.Basic.Classic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_DOM_Internal = require("../React.Basic.DOM.Internal/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var TacoTable_Cells = require("../TacoTable.Cells/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var Utils_ClassNames = require("../Utils.ClassNames/index.js");
var _row = React_Basic_Classic.createComponent("TableRow");
var row = function (dictEq) {
    return function (dictEq1) {
        var willUnmount = function (v) {
            return HtmlUtils.clearTimeout(v.state.timeoutId);
        };
        var shouldUpdate = function (v) {
            return function (v1) {
                var eqFoldingElement = (function () {
                    if (v.props.foldingElement instanceof Data_Maybe.Just && v1.nextProps.foldingElement instanceof Data_Maybe.Just) {
                        return Data_Eq.eq(TacoTable_Types.eqFoldingState)(v.props.foldingElement.value0.value0)(v1.nextProps.foldingElement.value0.value0);
                    };
                    if (v.props.foldingElement instanceof Data_Maybe.Nothing && v1.nextProps.foldingElement instanceof Data_Maybe.Just) {
                        return false;
                    };
                    if (v.props.foldingElement instanceof Data_Maybe.Just && v1.nextProps.foldingElement instanceof Data_Maybe.Nothing) {
                        return false;
                    };
                    return true;
                })();
                var eqColumns = Data_Array.length(v.props.columns) === Data_Array.length(v1.nextProps.columns);
                return Data_Foldable.any(Data_Foldable.foldableArray)(Data_HeytingAlgebra.heytingAlgebraBoolean)(Data_Eq.eq(Data_Eq.eqBoolean)(true))([ !Data_Eq.eq(dictEq1)(v.props.rowData)(v1.nextProps.rowData), !(v.props.className === v1.nextProps.className), !eqFoldingElement, !eqColumns, v.state.shouldRender !== v1.nextState.shouldRender ]);
            };
        };
        var render$prime = function (v) {
            return function (foldedState) {
                var mkCell = function (column) {
                    return React_Basic.keyed((function (v1) {
                        return v1.key;
                    })(Data_Newtype.un()(TacoTable_Types.Column)(column)))(TacoTable_Cells.dataCell(dictEq)({
                        rowData: v.props.rowData,
                        rowHeight: v.props.rowHeight,
                        column: column,
                        sortProperty: v.props.sortProperty,
                        styleSet: v.props.styleSet.cellStyleSet
                    }));
                };
                var foldingIndicator = Control_Applicative.pure(Control_Applicative.applicativeArray)((function () {
                    if (v.props.foldingElement instanceof Data_Maybe.Just && v.props.foldingElement.value0.value0 instanceof TacoTable_Types.Folded) {
                        return React_Basic_Emotion.element(React_Basic_DOM_Generated["span'"]())({
                            className: "folding-indicator",
                            css: TacoTable_Styles.foldingIndicatorStyles,
                            children: Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM.text("+"))
                        });
                    };
                    if (v.props.foldingElement instanceof Data_Maybe.Just && v.props.foldingElement.value0.value0 instanceof TacoTable_Types.Unfolded) {
                        return React_Basic_Emotion.element(React_Basic_DOM_Generated["span'"]())({
                            className: "folding-indicator",
                            css: TacoTable_Styles.foldingIndicatorStyles,
                            children: Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM.text("\u2013"))
                        });
                    };
                    return React_Basic.empty;
                })());
                var rowElement = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                    className: "row-cells",
                    css: v.props.styleSet.rowCellsContainerStyles(foldedState),
                    "_data": Components_Utils.mkTestIdAttrs("taco-table-row-" + v.props.rowKey),
                    children: Data_Semigroup.append(Data_Semigroup.semigroupArray)(foldingIndicator)(Data_Functor.map(Data_Functor.functorArray)(mkCell)(v.props.columns)),
                    onClick: React_Basic_Events.handler_(v.props.onClick(v.props.rowData))
                });
                var foldingElement = (function () {
                    if (v.props.foldingElement instanceof Data_Maybe.Just && v.props.foldingElement.value0.value0 instanceof TacoTable_Types.Unfolded) {
                        return v.props.foldingElement.value0.value1(v.props.rowData);
                    };
                    return React_Basic.empty;
                })();
                return {
                    rowElement: rowElement,
                    foldingElement: foldingElement
                };
            };
        };
        var render = function (v) {
            var getFoldedState = function (v1) {
                return v1.value0;
            };
            var foldedState = Data_Functor.map(Data_Maybe.functorMaybe)(getFoldedState)(v.props.foldingElement);
            var className = (function () {
                var toFoldingClassName = function (v1) {
                    if (v1.value0 instanceof TacoTable_Types.Folded) {
                        return "folding-row folded";
                    };
                    if (v1.value0 instanceof TacoTable_Types.Unfolded) {
                        return "folding-row unfolded";
                    };
                    throw new Error("Failed pattern match at TacoTable.Row (line 104, column 13 - line 104, column 80): " + [ v1.constructor.name ]);
                };
                return Utils_ClassNames.mkClassName([ new Data_Maybe.Just("row"), Data_Functor.map(Data_Maybe.functorMaybe)(toFoldingClassName)(v.props.foldingElement), new Data_Maybe.Just(v.props.className) ]);
            })();
            return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                className: className,
                css: React_Basic_Emotion.merge([ v.props.styleSet.rowStyles(foldedState)(v.props.rowHeight), v.props.css ]),
                style: v.props.style,
                children: (function () {
                    var $58 = !v.state.shouldRender;
                    if ($58) {
                        return [  ];
                    };
                    var v1 = render$prime(v)(foldedState);
                    return [ v1.rowElement, v1.foldingElement ];
                })()
            });
        };
        var didMount = function (v) {
            return Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(!v.state.shouldRender)(function __do() {
                var timeoutId = HtmlUtils.setTimeout(1)(v.setState(function (v1) {
                    var $65 = {};
                    for (var $66 in v1) {
                        if ({}.hasOwnProperty.call(v1, $66)) {
                            $65[$66] = v1[$66];
                        };
                    };
                    $65.shouldRender = true;
                    return $65;
                }))();
                return v.setState(function (v1) {
                    var $68 = {};
                    for (var $69 in v1) {
                        if ({}.hasOwnProperty.call(v1, $69)) {
                            $68[$69] = v1[$69];
                        };
                    };
                    $68.timeoutId = timeoutId;
                    return $68;
                })();
            });
        };
        return React_Basic_Classic.make()(_row)({
            initialState: {
                shouldRender: false,
                timeoutId: -1 | 0
            },
            render: render,
            shouldUpdate: shouldUpdate,
            didMount: didMount,
            willUnmount: willUnmount
        });
    };
};
var simpleRow = function (dictEq) {
    return function (dictEq1) {
        return function (rowKey) {
            return function (columns) {
                return function (styleSet) {
                    return function (rowData) {
                        return row(dictEq)(dictEq1)({
                            rowData: rowData,
                            css: React_Basic_Emotion.css()({}),
                            rowHeight: TacoTable_Types.RowMedium.value,
                            foldingElement: Data_Maybe.Nothing.value,
                            onClick: function (v) {
                                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                            },
                            className: "",
                            sortProperty: Data_Maybe.Nothing.value,
                            columns: columns,
                            styleSet: styleSet,
                            rowKey: rowKey,
                            style: React_Basic_DOM_Internal.css({})
                        });
                    };
                };
            };
        };
    };
};
module.exports = {
    "_row": _row,
    row: row,
    simpleRow: simpleRow
};
