import { Button, Modal } from "@tocoman/ui";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { mkDeleteUserAction } from "src/client-ts/actions/admin";
import { useOnEq } from "src/client-ts/hooks/useOnEq";
import { useWorkerAction } from "src/client-ts/hooks/useWorkerAction";
import { AdminUserTableRow } from "ts-bindings/AdminUserTableRow";

type BulkUserDeleteModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  usersToDelete: AdminUserTableRow[];
};

export const BulkUserDeleteModal = ({
  isOpen,
  closeModal,
  usersToDelete,
}: BulkUserDeleteModalProps) => {
  const { t } = useTranslation("admin", {
    keyPrefix: "users.deleteSelectedUsersModal",
  });

  const [deleteStatus, onConfirmBulkUserDelete] = useWorkerAction(
    "AdminUpdateActionState",
    () => {
      if (usersToDelete.length > 0) {
        return mkDeleteUserAction({
          userIds: usersToDelete.map((user) => user.user.id),
        });
      }
    }
  );

  useOnEq(deleteStatus, "Resolved", closeModal);

  const actions = useMemo(
    () => (
      <>
        <Button onClick={closeModal} variant="text" label={t`cancel`} />
        <Button
          onClick={onConfirmBulkUserDelete}
          color="danger"
          label={t("confirm", { count: usersToDelete.length })}
        />
      </>
    ),
    [usersToDelete, t, onConfirmBulkUserDelete, closeModal]
  );

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      actions={actions}
      title={
        <Trans
          ns={"admin"}
          i18nKey={"users.deleteSelectedUsersModal.title"}
          values={{ userAmount: usersToDelete.length }}
          count={usersToDelete.length}
        />
      }
    >
      <div>
        <span className="text-xl">{t`description`}</span>
        <ul className="list-disc ml-6">
          {usersToDelete.map((user) => (
            <li key={user.user.id}>{user.user.userData.email}</li>
          ))}
        </ul>
      </div>
    </Modal>
  );
};
