// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var AddIncomeItem = require("../AddIncomeItem/index.js");
var Constants = require("../Constants/index.js");
var Controls = require("../Controls/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var FileUpload = require("../FileUpload/index.js");
var Locale = require("../Locale/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoDropdown = require("../TacoDropdown/index.js");
var TacoDropdown_Types = require("../TacoDropdown.Types/index.js");
var TacoGroupToggle = require("../TacoGroupToggle/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Income = require("../Types.Income/index.js");
var Types_UploadStatus = require("../Types.UploadStatus/index.js");
var uniqueId = "incomeItems-upload-input";
var templateDownloadLink = TacoButton.component()()({
    href: new Data_Maybe.Just(Constants.installmentsUploadTemplate),
    text: "Lataa maksuer\xe4taulukkopohja",
    icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconFileDownloadV1.value),
    align: TacoButton_Types.Left.value
});
var component = TofuHooks.mkHookComponent("IncomeControls")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState(Types_UploadStatus.Standby.value)();
        var v2 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var appDispatch = StateHooks.useDispatch();
        var uploadFileLabel = FileUpload.uploadFileLabel({
            filename: v2.value0,
            className: "",
            uniqueId: uniqueId,
            label: Locale.lookup_("upload_incomeItems_excel")
        });
        var filters = TacoGroupToggle.component({
            testActive: function (group) {
                return Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean)))(Data_Foldable.elem(Data_Foldable.foldableArray)(Types_Income.eqIncomeGroupCode))(group.code)(v.disabledGroups);
            },
            getName: (function () {
                var $15 = Data_Newtype.unwrap();
                return function ($16) {
                    return $15((function (v3) {
                        return v3.name;
                    })($16));
                };
            })(),
            groups: v.groups,
            handleToggleGroup: function (group) {
                return function (flag) {
                    return v.handleToggleGroup(group.code)(!flag);
                };
            }
        });
        var fileUploadInput = FileUpload.mkFileUpload({
            projectId: new Data_Maybe.Just(v.projectId),
            uploadType: FileUpload.InstallmentsUpload.value,
            endpointUrl: "/uploadIncomeItems",
            uniqueId: uniqueId,
            uploadStatus: v1.value0,
            setUploadStatus: v1.value1,
            filename: v2.value0,
            setFilename: v2.value1,
            onSuccess: Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(function __do() {
                appDispatch(new Actions.GetIncomeItemsRequest({
                    projectId: v.projectId
                }))();
                return appDispatch(new Actions.GetIncomeGroupsRequest({
                    projectId: v.projectId
                }))();
            })
        });
        var fileUpload = React_Basic_DOM_Generated.div()({
            className: "flex-centered",
            children: [ fileUploadInput, uploadFileLabel, FileUpload.uploadStatusToJSX(v1.value0) ]
        });
        var addIncomeItemButton = AddIncomeItem.component({
            projectId: v.projectId,
            groups: v.groups
        });
        var actionsItems = [ templateDownloadLink, fileUpload ];
        var actions = [ addIncomeItemButton, TacoDropdown.uncontrolled({
            sections: [ {
                title: new Data_Maybe.Just("Maksuer\xe4t"),
                items: actionsItems
            } ],
            align: TacoDropdown_Types.AlignRight.value,
            testId: "income-controls-actions-dropdown"
        }) ];
        return Controls.component()()({
            leftControls: [ filters ],
            rightControls: actions,
            bottomGutter: true
        });
    };
});
module.exports = {
    component: component
};
