import { AdminetParams } from "../../../../../../ts-bindings/AdminetParams";
import {
  Component,
  ComponentItemTypeCode,
  ComponentItem,
  TargetAssessmentLetter,
  TargetAssessment,
  ClassAmount,
  ClassIdentifierTypeCode,
} from "@tocoman/adminet-openapi-client";
import { PlanningCostGroupData } from "../../../../../../ts-bindings/PlanningCostGroupData";
import { ComponentWithResources } from "../../../../../../ts-bindings/ComponentWithResources";
import { TotalPriceByCostClassCode } from "../../../../../../ts-bindings/TotalPriceByCostClassCode";
import { EstimationResource } from "../../../../../../ts-bindings/EstimationResource";

export const DEFAULT_COST_CLASS_IDENTIFIER: ComponentItemTypeCode =
  ComponentItemTypeCode._5;

type ComponentsByCostGroup = {
  [costGroupCode: string]: {
    componentCostGroup: PlanningCostGroupData;
    componentsWithResources: Array<ComponentWithResources>;
  };
};

export const mapAdminetParamsToTargetAmount = (
  adminetParams: AdminetParams,
  adminetItemsEnabled: boolean
): TargetAssessment => {
  return {
    externalId: String(adminetParams.projectId),
    letters: mapCostGroups(adminetParams, adminetItemsEnabled),
    overWrite: true,
  };
};

const mapCostGroups = (
  adminetParams: AdminetParams,
  adminetItemsEnabled: boolean
): Array<TargetAssessmentLetter> => {
  const componentsByCostGroup: ComponentsByCostGroup = mapComponentsByCostGroupCode(
    adminetParams
  );

  const costGroups: Array<TargetAssessmentLetter> = [];

  for (const [letterCode, component] of Object.entries(componentsByCostGroup)) {
    const quantity = component.componentCostGroup.costGroup.amount ?? undefined;
    const unit = component.componentCostGroup.costGroup.unit ?? undefined;
    const componentsWithResources = component.componentsWithResources;
    if (componentsWithResources.length === 0) {
      continue;
    }

    const components = mapComponents(
      componentsWithResources,
      adminetItemsEnabled
    );
    if (components.length === 0) {
      continue;
    }

    costGroups.push({
      letterCode,
      quantity,
      unit,
      components,
    });
  }

  return costGroups;
};

const mapComponentsByCostGroupCode = ({
  componentCostGroups,
  componentsWithResources,
}: AdminetParams): ComponentsByCostGroup => {
  const componentsByCostGroupCode: ComponentsByCostGroup = {};

  for (const componentCostGroup of componentCostGroups) {
    componentsByCostGroupCode[componentCostGroup.costGroup.code] = {
      componentCostGroup,
      componentsWithResources: [],
    };
  }

  for (const componentsWithResource of componentsWithResources) {
    const costGroupCode: string | null =
      componentsWithResource.component.costGroupCode;
    if (
      costGroupCode !== null &&
      componentsByCostGroupCode[costGroupCode] !== undefined
    ) {
      componentsByCostGroupCode[costGroupCode].componentsWithResources.push(
        componentsWithResource
      );
    }
  }

  return componentsByCostGroupCode;
};

const mapComponents = (
  componentsWithResources: Array<ComponentWithResources>,
  adminetItemsEnabled: boolean
): Array<Component> => {
  return componentsWithResources
    .filter((componentWithResources) =>
      isValidComponent(componentWithResources)
    )
    .map((componentWithResources) => {
      const component = componentWithResources.component;
      const componentDescription =
        component.description.length > 200
          ? component.description.substring(0, 200)
          : component.description;

      // classAmounts field is deprecated and will be removed in the future, currently it is used by the old adminet integration schema
      if (adminetItemsEnabled) {
        return {
          code: component.code ?? "",
          description: componentDescription,
          quantity: component.amount,
          unit: component.unit ?? "",
          items: mapResourcesToItems(componentWithResources),
        };
      } else {
        return {
          code: component.code ?? "",
          description: componentDescription,
          quantity: component.amount,
          unit: component.unit ?? "",
          classAmount: mapCostClassAmounts(componentWithResources),
        };
      }
    });
};

const isValidComponent = (
  componentWithResources: ComponentWithResources
): boolean => {
  const component = componentWithResources.component;
  return component.amount !== 0;
};

const mapCostClassAmounts = (
  componentWithResources: ComponentWithResources
): Array<ClassAmount> => {
  return componentWithResources.totalPriceByCostClasses
    .filter((costClass: TotalPriceByCostClassCode) =>
      isValidCostClass(costClass)
    )
    .map((costClass: TotalPriceByCostClassCode) => {
      return {
        typeCode: mapCostClassCodeToIdentifier(costClass.costClassCode),
        amount: costClass.totalPrice,
        quantity: getCostClassQuantity(costClass, componentWithResources),
      };
    });
};

export function mapResourcesToItems(
  componentWithResources: ComponentWithResources
): Array<ComponentItem> {
  return componentWithResources.resources
    .sort((a, b) =>
      a.costClassCode === b.costClassCode
        ? a.id - b.id
        : Number(a.costClassCode) - Number(b.costClassCode)
    )
    .map((resource) => {
      return {
        typeCode: mapResourceTypeCode(resource.costClassCode),
        amount: resource.isCostClassPrice
          ? getCostClassResourcePrice(resource, componentWithResources)
          : getResourcePrice(resource, componentWithResources),
        quantity: getResourceQuantity(resource, componentWithResources),
        description: resource.name,
        unit: resource.unit ?? undefined,
      };
    });
}

export function getResourcePrice(
  resource: EstimationResource,
  component: ComponentWithResources
) {
  return resource.priceWithDiscount * getResourceQuantity(resource, component);
}

export function getCostClassResourcePrice(
  resource: EstimationResource,
  component: ComponentWithResources
) {
  return (resource.pricePerUnit ?? 0) * component.component.amount;
}

export function getResourceQuantity(
  resource: EstimationResource,
  component: ComponentWithResources
) {
  const { unitConsumptionRate, wastePercentage } = resource;
  return (
    component.component.amount *
    unitConsumptionRate *
    (1 + wastePercentage / 100)
  );
}

const getCostClassQuantity = (
  costClass: TotalPriceByCostClassCode,
  componentWithResources: ComponentWithResources
) => {
  // We can determine if the costclass is hourly priced by checking if it has a price when hours are 0
  if (costClass.hours === 0 && costClass.totalPrice !== 0) {
    const costClassResources = componentWithResources.resources.filter(
      (cc) => cc.costClassCode === costClass.costClassCode
    );

    return (
      100 *
      costClassResources.reduce((previousValue, currentValue) => {
        const { unitConsumptionRate, wastePercentage } = currentValue;
        const quantityWithWastePercentage =
          unitConsumptionRate + unitConsumptionRate * (wastePercentage / 100);
        return previousValue + quantityWithWastePercentage;
      }, 0)
    );
  }
  return costClass.hours;
};

const isValidCostClass = (costClass: TotalPriceByCostClassCode): boolean => {
  return [
    costClass.hours,
    costClass.totalPrice,
    costClass.totalPriceWithoutSocialExpenses,
  ].some((value: number) => value !== 0);
};

const mapResourceTypeCode = (
  costClassCode: string | null
): ComponentItemTypeCode => {
  const foundValue: ComponentItemTypeCode | undefined = Object.values(
    ComponentItemTypeCode
  ).find((identifier: string) => identifier === costClassCode);

  return foundValue ?? DEFAULT_COST_CLASS_IDENTIFIER;
};

const mapCostClassCodeToIdentifier = (
  costClassCode: string
): ClassIdentifierTypeCode => {
  const foundValue: ClassIdentifierTypeCode | undefined = Object.values(
    ClassIdentifierTypeCode
  ).find((identifier: string) => identifier === costClassCode);

  return foundValue ?? DEFAULT_COST_CLASS_IDENTIFIER;
};
