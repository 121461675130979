// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var listContainerStyles = Box.boxStyle([ Box.FlexColumn.value, new Box.MarginTop(TacoConstants.XXS.value) ]);
var itemStyles = function (v) {
    return Box.boxStyle([ new Box.FlexGrow(1), new Box.Display(Box.Flex.value), Box.JustifySpaceBetween.value, new Box.PaddingY(TacoConstants.XXS.value), new Box.PaddingX(TacoConstants.XXS.value), Box.Style.create(React_Basic_Emotion.css()({
        transition: React_Basic_Emotion.str("all 0.2s ease-out")
    })), Data_Monoid.guard(Box.monoidBoxProp)(v.disabled)(new Box.ForegroundColor(TacoConstants.Gray.value)), Data_Monoid.guard(Box.monoidBoxProp)(v.hasOnclick)(new Box.Cursor(Box.Pointer.value)), Data_Monoid.guard(Box.monoidBoxProp)(v.hasOnclick)(new Box.Hover([ new Box.BackgroundColor(TacoConstants.LightBlue.value), new Box.ForegroundColor(TacoConstants.PrimaryBlue.value) ])) ]);
};
var iconContainerStyles = function (v) {
    return Box.boxStyle([ new Box.PaddingY(TacoConstants.XXS.value), new Box.PaddingX(TacoConstants.XS.value), Box.Style.create(React_Basic_Emotion.css()({
        transition: React_Basic_Emotion.str("all 0.2s ease-out")
    })), Data_Monoid.guard(Box.monoidBoxProp)(v.hasChildren)(new Box.Hover([ new Box.BackgroundColor(TacoConstants.GrayLight.value), new Box.Selector("& + div", [ new Box.BackgroundColor(TacoConstants.GrayLight.value) ]) ])) ]);
};
var expandableItemStyles = function (v) {
    return Box.boxStyle([ Box.FlexRow.value, Box.AlignCenter.value, Box.PaddingY.create(new TacoConstants.CustomSize("0")), Box.Style.create(React_Basic_Emotion.css()({
        border: React_Basic_Emotion["int"](0)
    })), Box.Style.create(React_Basic_Emotion.css()({
        transition: React_Basic_Emotion.str("all 0.2s ease-out")
    })), Data_Monoid.guard(Box.monoidBoxProp)(v.hasChildren)(new Box.Cursor(Box.Pointer.value)), Data_Monoid.guard(Box.monoidBoxProp)(v.expanded)(new Box.BackgroundColor(TacoConstants.GrayLight.value)), Data_Monoid.guard(Box.monoidBoxProp)(!v.hasChildren)(new Box.Hover([ new Box.BackgroundColor(TacoConstants.GrayLight.value) ])) ]);
};
var expandableItemListStyles = Box.boxStyle([ new Box.Selector(".expandable-item", [ Box.MarginLeft.create(new TacoConstants.CustomSize("1.75rem")) ]), new Box.Selector(".expandable-item-list", [ Box.MarginLeft.create(new TacoConstants.CustomSize("1.75rem")) ]), new Box.BackgroundColor(TacoConstants.GrayLight.value) ]);
module.exports = {
    listContainerStyles: listContainerStyles,
    expandableItemStyles: expandableItemStyles,
    expandableItemListStyles: expandableItemListStyles,
    iconContainerStyles: iconContainerStyles,
    itemStyles: itemStyles
};
