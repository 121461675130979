import { FeatureFlags } from "./FeatureFlags";
import { PropsWithChildren } from "react";
import { useFeatureEnabled } from "./useFeatureEnabled";

export interface FeatureFlagContainerProps {
  feature: FeatureFlags;
}
export function FeatureFlagContainer({
  feature,
  children,
}: PropsWithChildren<FeatureFlagContainerProps>) {
  const enabled = useFeatureEnabled(feature);
  if (enabled) {
    return <>{children}</>;
  }
  return <></>;
}
