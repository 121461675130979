import { useCallback, useRef } from "react";
import { WorkerRequest } from "ts-bindings/WorkerRequest";
import { sendWorkerRequest } from "../utils/stateUtils";

/**
 * React hook for dispatching requests to the worker.
 */
export const useWorkerRequest = <T extends unknown[]>(
  mkRequest: (...args: T) => WorkerRequest | undefined
): ((...args: T) => void) => {
  const mkRequestRef = useRef(mkRequest);
  mkRequestRef.current = mkRequest;

  const callback = useCallback((...args: T) => {
    const request = mkRequestRef.current(...args);
    if (request) sendWorkerRequest(request);
  }, []);

  return callback;
};
