// Generated by purs version 0.14.5
"use strict";
var Components_SubNavigation = require("../Components.SubNavigation/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var Types_FrontEndRoutes = require("../Types.FrontEndRoutes/index.js");
var items = [ new Components_SubNavigation.Link({
    title: "Rakenteet",
    color: TacoConstants.elementColors.border,
    active: true,
    href: "#",
    route: new Types_FrontEndRoutes.EstimationBuildingElementsRoute({
        projectId: 42,
        initialSelectedElement: Data_Maybe.Nothing.value
    }),
    id: "elements"
}), new Components_SubNavigation.Link({
    title: "Suoritteet",
    color: TacoConstants.componentColors.border,
    active: false,
    href: "#",
    route: new Types_FrontEndRoutes.EstimationFERoute({
        projectId: 42,
        initialSelectedComponent: Data_Maybe.Nothing.value
    }),
    id: "components"
}), new Components_SubNavigation.Disabled({
    title: "Panokset",
    id: "resources"
}), Components_SubNavigation.Separator.value, new Components_SubNavigation.Disabled({
    title: "Viitehankkeet",
    id: "ref-projects"
}), new Components_SubNavigation.Disabled({
    title: "Rakennekirjasto",
    id: "ref-elemens"
}), new Components_SubNavigation.Link({
    title: "Suoritekirjasto",
    color: TacoConstants.referenceComponentColors.border,
    active: false,
    href: "#",
    route: new Types_FrontEndRoutes.EstimationReferenceRoute({
        projectId: 42
    }),
    id: "ref-elements"
}) ];
var example1 = "import Components.SubNavigation as SubNavigation\x0aimport Components.SubNavigation (NavigationItem(..))\x0a\x0aitems :: Array NavigationItem\x0aitems =\x0a  [ Link\x0a      { title: \"Rakenteet\"\x0a      , color: elementColors.border\x0a      , active: true\x0a      , href: \"#\"\x0a      , route: EstimationBuildingElementsRoute { projectId: ProjectId 42 }\x0a      }\x0a  , Link\x0a      { title: \"Suoritteet\"\x0a      , color: componentColors.border\x0a      , active: false\x0a      , href: \"#\"\x0a      , route: EstimationFERoute { projectId: ProjectId 42 }\x0a      }\x0a  , Disabled\x0a      { title: \"Panokset\" }\x0a  , Separator\x0a  , Disabled\x0a      { title: \"Viitehankkeet\" }\x0a  , Disabled\x0a      { title: \"Rakennekirjasto\" }\x0a  , Link\x0a      { title: \"Suoritekirjasto\"\x0a      , color: referenceComponentColors.border\x0a      , active: false\x0a      , href: \"#\"\x0a      , route: EstimationReferenceRoute { projectId: ProjectId 42 }\x0a      }\x0a  ]\x0a\x0a[ SubNavigation.component { items } ]";
var sections = [ {
    title: "Default",
    view: [ Components_SubNavigation.component({
        items: items
    }) ],
    code: example1
} ];
var demoView = {
    sections: sections,
    title: "SubNavigation",
    description: "Sub navigation bar for navigating within a top-level route"
};
module.exports = {
    demoView: demoView
};
