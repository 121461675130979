// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var tutorialStyles = function (v) {
    return Box.boxStyle([ Data_Monoid.guard(Box.monoidBoxProp)(v.active)(Box.Style.create(React_Basic_Emotion.css()({
        animation: React_Basic_Emotion.str("tutorial-highlight 2s ease-in-out 1s infinite")
    }))) ]);
};
var component = TofuHooks.mkHookComponent("TutorialContainer")(function (v) {
    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        children: v.contents,
        className: "__TutorialContainer",
        css: tutorialStyles({
            active: v.active
        })
    }));
});
module.exports = {
    component: component,
    tutorialStyles: tutorialStyles
};
