import { Combobox, SelectItem } from "@tocoman/ui";
import { useCallback, useMemo, useState } from "react";

type Props = {
  label: string;
  items: string[];
  onValueChange: (value: string | null) => void;
  initialValue: string | null;
  disabled?: boolean;
  disabledTooltip?: string;
};

export const StringCombobox = ({
  label,
  items,
  onValueChange,
  initialValue,
  disabled = false,
  disabledTooltip,
}: Props) => {
  const mappedItems = useMemo(
    () => items.map((item) => ({ key: item, value: item, label: item })),
    [items]
  );
  const [selectedItem, setSelectedItem] = useState<SelectItem<string> | null>(
    null
  );

  const initialSelected = useMemo(
    () => mappedItems.find((item) => item.value === initialValue),
    [initialValue, mappedItems]
  );

  const [filterString, setFilterString] = useState<string>("");
  const filteredItems = useMemo(() => {
    return filterString === ""
      ? mappedItems
      : mappedItems.filter((item) =>
          item.label
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(filterString.toLowerCase().replace(/\s+/g, ""))
        );
  }, [mappedItems, filterString]);

  const onChange = useCallback(
    (value: SelectItem<string> | null) => {
      setSelectedItem(value);
      onValueChange(value ? value.value : null);
      setFilterString("");
    },
    [onValueChange]
  );

  return (
    <Combobox
      label={label}
      items={filteredItems}
      filterHandler={setFilterString}
      onValueChange={onChange}
      selected={initialSelected ?? selectedItem}
      disabled={disabled}
      disabledTooltip={disabledTooltip}
    />
  );
};
