// Generated by purs version 0.14.5
"use strict";
var Chart = require("../Chart/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_JSDate = require("../Data.JSDate/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Number = require("../Data.Number/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var DateFnsFFI = require("../DateFnsFFI/index.js");
var Effect = require("../Effect/index.js");
var Formatters = require("../Formatters/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Utils = require("../Utils/index.js");
var colors = {
    active: "#2284f0",
    inactive: "#c0c0c0",
    "default": "#909090"
};
var invoiceMonthlyFilterChart = TofuHooks.mkHookComponent("InvoiceMonthlyFilterChart")(function (props) {
    var dateToString = function (date) {
        return Data_Show.show(Data_Show.showInt)(DateFnsFFI.getYear(date)) + ("-" + Data_Show.show(Data_Show.showInt)(1 + DateFnsFFI.getMonth(date) | 0));
    };
    var eqMonth = function (a) {
        return function (b) {
            return dateToString(a) === dateToString(b);
        };
    };
    var isMonthInSet = function (month) {
        return function (set) {
            return !Data_Set.isEmpty(Data_Set.filter(Data_JSDate.ordJSDate)(eqMonth(month))(set));
        };
    };
    var onMonthToggle = function (event) {
        var yearStr = Data_Maybe.fromMaybe("")(Data_Array.head(Data_String_Common.split("-")(event.value)));
        var parsedYear = Data_Maybe.fromMaybe(0.0)(Data_Number.fromString(yearStr));
        var monthStr = Data_Maybe.fromMaybe("")(Data_Function.flip(Data_Array.index)(1)(Data_String_Common.split("-")(event.value)));
        var parsedMonth = Data_Maybe.fromMaybe(0.0)(Data_Number.fromString(monthStr));
        var toggledMonth = Data_JSDate.jsdate({
            year: parsedYear,
            month: parsedMonth - 1.0,
            day: 1.0,
            hour: 0.0,
            minute: 0.0,
            second: 0.0,
            millisecond: 0.0
        });
        var updatedSet = (function () {
            var $6 = isMonthInSet(toggledMonth)(props.selectedMonths);
            if ($6) {
                return Data_Set.empty;
            };
            return Data_Set.singleton(toggledMonth);
        })();
        return props.onMonthsSelect(updatedSet);
    };
    var selectedMonthLabels = Data_Set.map(Data_Ord.ordString)(dateToString)(props.selectedMonths);
    var toComparable = function ($14) {
        return dateToString(Utils.lossyParseJSDate($14));
    };
    var getInvoicesValueForMonth = function (monthStr) {
        return Data_Show.show(Data_Show.showInt)(Data_Int.round(Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)((function () {
            var $15 = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber);
            var $16 = Data_Functor.map(Data_Functor.functorArray)(function (v) {
                return v.value;
            });
            return function ($17) {
                return $15($16((function (v) {
                    return v.invoiceLines;
                })($17)));
            };
        })())(Data_Array.filter((function () {
            var $18 = Data_Eq.eq(Data_Eq.eqString)(monthStr);
            return function ($19) {
                return $18(toComparable((function (v) {
                    return v.date;
                })($19)));
            };
        })())(props.filteredInvoices)))));
    };
    var getInvoicesDataForMonth = function (monthStr) {
        var monthOrNoneSelected = Data_Set.isEmpty(props.selectedMonths) || Data_Set.member(Data_Ord.ordString)(monthStr)(selectedMonthLabels);
        return {
            value: getInvoicesValueForMonth(monthStr),
            itemStyle: {
                color: (function () {
                    if (monthOrNoneSelected) {
                        return colors.active;
                    };
                    return colors.inactive;
                })()
            }
        };
    };
    var compareDates = function (a) {
        return function (b) {
            return Data_Ord.compare(Data_Ord.ordString)(a.date)(b.date);
        };
    };
    var invoicesSortedByDate = Data_Array.sortBy(compareDates)(props.allInvoices);
    var latestInvoice = Data_Array.head(invoicesSortedByDate);
    var oldestInvoice = Data_Array.last(invoicesSortedByDate);
    var monthLabels = (function () {
        if (oldestInvoice instanceof Data_Maybe.Just && latestInvoice instanceof Data_Maybe.Just) {
            var oldestDate = Utils.lossyParseJSDate(oldestInvoice.value0.date);
            var mkDateGroup = function (date) {
                return {
                    value: dateToString(date),
                    textStyle: {
                        color: (function () {
                            var v = isMonthInSet(date)(props.selectedMonths);
                            var v1 = Data_Set.isEmpty(props.selectedMonths);
                            if (v1) {
                                return colors["default"];
                            };
                            if (v) {
                                return colors.active;
                            };
                            return colors.inactive;
                        })()
                    }
                };
            };
            var latestDate = Utils.lossyParseJSDate(latestInvoice.value0.date);
            return Data_Functor.map(Data_Functor.functorArray)(mkDateGroup)(DateFnsFFI.monthsBetween(latestDate)(new Data_Maybe.Just(oldestDate)));
        };
        return [  ];
    })();
    var monthlyInvoicesValue = Data_Functor.map(Data_Functor.functorArray)(getInvoicesDataForMonth)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
        return v.value;
    })(monthLabels));
    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_DOM_Generated.div()({
        className: "monthly-filter-chart",
        children: [ Chart.chart({
            groups: monthLabels,
            series: Control_Applicative.pure(Control_Applicative.applicativeArray)({
                type: "bar",
                data: monthlyInvoicesValue,
                name: "Laskukertym\xe4",
                barMaxWidth: 20
            }),
            valueFormatter: Formatters.formatCurrencyValueWithoutDecimals("EUR"),
            onClick: onMonthToggle
        }) ]
    }));
});
module.exports = {
    invoiceMonthlyFilterChart: invoiceMonthlyFilterChart
};
