import { useTranslation } from "react-i18next";
import { Modal, Text } from "@tocoman/ui";
import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useWorkerState } from "../../../../hooks/useWorkerState";
import { AdminetParams } from "../../../../../../ts-bindings/AdminetParams";
import { CostCalculationActions } from "./CostCalculationActions";
import {
  useAdminetTenants,
  useTransferCalculation,
} from "../http/AdminetHttpHandler";
import { SelectAdminetProject } from "./SelectAdminetProject";
import { CostCalculationResults } from "./CostCalculationResults";
import { LettersToBeMovedTable } from "./LettersToBeMovedTable";
import { mapUnitsAndAmountToComponentCostGroups } from "../mappers/UnitsAndAmountsToComponentCostGroupsMapper";
import { useFeatureEnabled } from "../../../../split/useFeatureEnabled";
import { FeatureFlags } from "../../../../split/FeatureFlags";
import { Controller, useForm } from "react-hook-form";
import { SelectField } from "../../../../components/SelectField";

const instructionsStyle: CSSProperties = {
  margin: "3px 0px 22px",
  whiteSpace: "pre-line",
};

export enum AdminetProjectsFetchStatus {
  NoError,
  Empty,
  Error,
}

type ImportCostCalculationToAdminetModalProps = {
  isOpen?: boolean;
  closeModal: () => void;
  projectId: number;
};

export const ImportCostCalculationToAdminetModal = ({
  closeModal = () => ({}),
  isOpen = true,
  projectId,
}: ImportCostCalculationToAdminetModalProps) => {
  const { t } = useTranslation("integration");

  const [multipleAdminetTenants, setMultipleAdminetTenants] = useState<boolean>(
    false
  );
  const {
    data: adminetTenants,
    isLoading: adminetTenantsLoading,
  } = useAdminetTenants(setMultipleAdminetTenants);
  const adminetTenantSelections = (adminetTenants ?? []).map(
    (tenant) => tenant.tenantId + ", " + tenant.tenantName
  );
  const { control, setValue, getValues } = useForm<{
    tenantId: string;
  }>({
    defaultValues: {
      tenantId: adminetTenantSelections[0] ?? "",
    },
  });
  const getAdminetTenant = useCallback(() => {
    const tenant = getValues("tenantId");
    if (tenant === undefined) {
      return "";
    }
    return tenant.split(",")[0];
  }, [getValues]);

  const adminetResourcesEnabled = useFeatureEnabled(
    FeatureFlags.ADMINET_INTEGRATION_RESOURCES
  );
  const componentsWithResources = useWorkerState(
    "ComponentsWithResourcesState",
    projectId
  );
  const componentCostGroups = useWorkerState(
    "ComponentCostGroupsDataState",
    projectId
  );

  const adminetParams = useMemo(() => {
    if (componentsWithResources !== null && componentCostGroups !== null) {
      const params: AdminetParams = {
        componentsWithResources,
        componentCostGroups,
        projectId,
      };
      return params;
    }
  }, [componentCostGroups, componentsWithResources, projectId]);

  const mapComponentCostGroups = useMemo(() => {
    return mapUnitsAndAmountToComponentCostGroups(
      adminetParams,
      adminetResourcesEnabled
    );
  }, [adminetParams, adminetResourcesEnabled]);

  if (adminetParams) {
    adminetParams.componentCostGroups = mapComponentCostGroups;
  }

  const [selectedAdminetProjectId, setSelectedAdminetProjectId] = useState<
    string
  >();

  const [adminetProjectErrorStatus, setAdminetProjectErrorStatus] = useState<
    AdminetProjectsFetchStatus
  >(AdminetProjectsFetchStatus.NoError);

  const [missingCostGroupCodes, setMissingCostGroupCodes] = useState<
    (string | undefined)[]
  >([]);
  const [
    noTransferableCostGroupsPresent,
    setNoTransferableCostGroupsPresent,
  ] = useState(false);

  const transferCalculation = useTransferCalculation(
    adminetParams,
    selectedAdminetProjectId,
    multipleAdminetTenants ? getAdminetTenant() : null
  );

  useEffect(() => {
    if (
      !adminetTenantsLoading &&
      adminetTenants.length > 0 &&
      getAdminetTenant() === ""
    ) {
      setValue("tenantId", adminetTenantSelections[0]);
    }
  }, [
    adminetTenantSelections,
    adminetTenants,
    adminetTenantsLoading,
    getAdminetTenant,
    setValue,
  ]);

  const resetTransferalStates = (): void => {
    transferCalculation.reset();
    setMissingCostGroupCodes([]);
    setNoTransferableCostGroupsPresent(false);
  };

  return (
    <Modal
      actions={
        <CostCalculationActions
          closeModal={closeModal}
          transferCalculation={transferCalculation}
          selectedAdminetProjectId={selectedAdminetProjectId}
          adminetParams={adminetParams}
          resetTransferalStates={resetTransferalStates}
          setNoTransferableCostGroupsPresent={
            setNoTransferableCostGroupsPresent
          }
          setMissingCostGroupCodes={setMissingCostGroupCodes}
          adminetTenantId={multipleAdminetTenants ? getAdminetTenant() : null}
        ></CostCalculationActions>
      }
      closeModal={() => {
        closeModal();
      }}
      isOpen={isOpen}
      width={960}
      title={t`adminet.transferCalculation.title`}
    >
      <div>
        <Text
          style={instructionsStyle}
          text={t`adminet.transferCalculation.instructions`}
        ></Text>

        {multipleAdminetTenants && adminetTenantSelections.length > 0 ? (
          <div className={"w-64 mb-2"}>
            <Controller
              control={control}
              name={"tenantId"}
              render={({ field: { onChange, value } }) => (
                <SelectField
                  items={adminetTenantSelections}
                  label={t`adminet.transferCalculation.tenant`}
                  onValueChange={onChange}
                  initialValue={value}
                />
              )}
            />
          </div>
        ) : null}
        {adminetTenantsLoading ? null : (
          <>
            <SelectAdminetProject
              disabled={transferCalculation.isLoading}
              resetTransferalStates={resetTransferalStates}
              value={selectedAdminetProjectId}
              onChange={setSelectedAdminetProjectId}
              onFetchStatusChange={setAdminetProjectErrorStatus}
              adminetTenantId={
                multipleAdminetTenants ? getAdminetTenant() : null
              }
            />

            <CostCalculationResults
              adminetProjectsStatus={adminetProjectErrorStatus}
              transferCalculationErrors={transferCalculation.error}
              transferCalculationSuccess={transferCalculation.isSuccess}
              foundMissingCostGroupCodes={missingCostGroupCodes}
              noTransferableCostGroupsPresent={noTransferableCostGroupsPresent}
            />

            <LettersToBeMovedTable
              letters={
                adminetParams
                  ? adminetParams.componentCostGroups
                  : componentCostGroups
              }
            />
          </>
        )}
      </div>
    </Modal>
  );
};
