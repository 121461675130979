// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Effect = require("../Effect/index.js");
var TacoFilter = require("../TacoFilter/index.js");
var example2 = "\x0atacoFilter\x0a  { filterValues: { state: FVSPreviouslySet, values: [\"Apple\"] }\x0a  , label: R.text \"Fruit:\"\x0a  , key: \"fruit\"\x0a  , onFilterUpdate: pure mempty\x0a  , onFilterRemove: pure mempty\x0a  }\x0a";
var example1 = "\x0atacoFilter\x0a  { filterValues: { state: FVSNeedsFocus, values: [\"Apple\"] }\x0a  , label: R.text \"Fruit:\"\x0a  , key: \"fruit\"\x0a  , onFilterUpdate: pure mempty\x0a  , onFilterRemove: pure mempty\x0a  }\x0a";
var sections = [ {
    title: "Default",
    view: [ TacoFilter.tacoFilter({
        filterValues: {
            state: TacoFilter.FVSNeedsFocus.value,
            values: [ "A" ]
        },
        label: "Alakohde:",
        id: "ak",
        onFilterUpdate: Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit))),
        onFilterRemove: Control_Applicative.pure(Effect.applicativeEffect)(Data_Monoid.mempty(Data_Monoid.monoidUnit)),
        testId: "sub-project",
        useTacoFilterText: TacoFilter.UseTacoFilterText.value
    }) ],
    code: example1
}, {
    title: "Example without focusing on mount",
    view: [ TacoFilter.tacoFilter({
        filterValues: {
            state: TacoFilter.FVSPreviouslySet.value,
            values: [ "A" ]
        },
        label: "Alakohde:",
        id: "ak",
        onFilterUpdate: Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit))),
        onFilterRemove: Control_Applicative.pure(Effect.applicativeEffect)(Data_Monoid.mempty(Data_Monoid.monoidUnit)),
        testId: "sub-project",
        useTacoFilterText: TacoFilter.UseTacoFilterText.value
    }) ],
    code: example2
} ];
var demoView = {
    sections: sections,
    title: "TacoFilter",
    description: "Component providing multiple filter inputs"
};
module.exports = {
    demoView: demoView
};
