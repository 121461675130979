import { Checkbox, ConfirmationModal, Header } from "@tocoman/ui";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { CostClass } from "ts-bindings/CostClass";
import { ProjectId } from "ts-bindings/ProjectId";
import { Table } from "src/client-ts/components/Table";
import {
  ColDef,
  ICellRendererParams,
  ValueParserParams,
  ValueSetterParams,
} from "ag-grid-community";
import { HourlyPricingEditor } from "./HourlyPricingEditor";
import { NewCostClassForm } from "./NewCostClassForm";
import { ErrorMessage } from "../../../components/ErrorMessage";
import { isNumber, isPositive } from "../../../utils/validations";
import { TFunction } from "i18next";
import { cloneDeep } from "lodash";
import { CcDiff } from "../ProjectActions/FileUpload/useEvaluateCcDiffs";

type CostClassesProps = {
  projectId?: ProjectId;
  costClasses: CostClass[];
  onChange: (costClasses: CostClass[]) => void;
  ccDiffs?: Record<string, CcDiff>;
  fileCcCount?: number;
};

export const CostClassesTab = ({
  projectId,
  costClasses,
  onChange,
  ccDiffs,
  fileCcCount,
}: CostClassesProps) => {
  const { t } = useTranslation("projects", { keyPrefix: "details" });

  const [validationError, setValidationError] = useState("");

  useEffect(() => {
    if (onChange !== undefined) onChange(costClasses);
  }, [costClasses, onChange]);

  const onColumnHourlyPricingChange = useCallback(
    (rowCode: string | undefined, value: boolean) => {
      onChange(
        costClasses.map((costClass) =>
          costClass.costClassCode === rowCode
            ? { ...costClass, hourlyPricing: value }
            : costClass
        )
      );
    },
    [costClasses, onChange]
  );

  const onRemoveCostClass = useCallback(
    (rowCode: string | undefined) => {
      rowCode !== undefined &&
        onChange(
          costClasses.filter((costClass) => rowCode !== costClass.costClassCode)
        );
    },
    [costClasses, onChange]
  );

  const cellEditorSelector = useCallback(() => {
    return {
      component: HourlyPricingEditor,
      popup: false,
    };
  }, []);

  const onCellValueChanged = useCallback(() => {
    // HACK: because we mutate data directly in the valueSetter, React won't see
    // any change by referential equality unless we clone the data here
    onChange(cloneDeep(costClasses));
  }, [onChange, costClasses]);

  const validateCostClassCodeAndLength = useCallback(
    (value: string | number | boolean | null): [string, boolean] => {
      const validLength = validateCostClassCodeLength(value);
      const validUnique = validateCostClassCode(value, costClasses);
      if (validLength && validUnique) {
        return ["all", true];
      } else if (!value) {
        return ["required", false];
      } else if (!validUnique) {
        return ["unique", false];
      } else if (!validLength) {
        return ["length", false];
      }
      return ["all", false];
    },
    [costClasses]
  );

  const columnDefs = useMemo<ColDef<CostClass>[]>(
    () => [
      {
        field: "costClassCode",
        type: ["editable"],
        headerName: t`costClasses.tableColumns.costClassCode`,
        valueSetter: (params: ValueSetterParams) => {
          const [errorType, valid] = validateCostClassCodeAndLength(
            params.newValue
          );
          if (valid) {
            // DANGER: this mutates data
            params.data.costClassCode = params.newValue;
            setValidationError("");
            return true;
          }
          setValidationError(costClassError(errorType, t));
          return false;
        },
      },
      {
        field: "name",
        headerName: t`costClasses.tableColumns.name`,
        type: ["editable"],
      },
      {
        field: "socialExpensePercentageInCostEstimation",
        headerName: t`costClasses.tableColumns.socialExpensePercentage`,
        valueParser: parseCostClassSocialExpensePercentage,
        type: ["percentage", "numericColumn", "editable"],
      },
      {
        field: "hourlyPricing",
        headerName: t`costClasses.tableColumns.hourlyPricing`,
        filter: false,
        editable: true,
        cellEditorSelector: cellEditorSelector,
        cellRenderer: (cellData: ICellRendererParams<CostClass>) => {
          return (
            <div className={"flex h-full"}>
              <Checkbox
                testId={"hourly-pricing-checkbox-" + cellData.data?.id ?? ""}
                className={"self-center"}
                checked={cellData.value}
                setChecked={(value) =>
                  onColumnHourlyPricingChange(
                    cellData.data?.costClassCode,
                    value
                  )
                }
              />
            </div>
          );
        },
        cellClass: "cell-with-inner-component",
      },
      {
        colId: "delete",
        headerName: "",
        filter: false,
        pinned: "right",
        width: 100,
        editable: false,
        lockVisible: true,
        lockPinned: true,
        lockPosition: true,
        suppressNavigable: true,
        resizable: false,
        cellRenderer: (cellData: ICellRendererParams<CostClass>) => {
          if (cellData.node.rowPinned) {
            return null;
          } else {
            return (
              <ConfirmationModal
                buttonProps={{ variant: "text", color: "danger" }}
                buttonText={t`costClasses.tableColumns.delete`}
                confirmText={t`costClasses.removeCostClass.confirm`}
                cancelText={t`costClasses.removeCostClass.cancel`}
                onConfirm={() =>
                  onRemoveCostClass(cellData.data?.costClassCode)
                }
                title={t`costClasses.removeCostClass.title`}
                prompt={
                  <Trans
                    ns="projects"
                    i18nKey={"details.costClasses.removeCostClass.prompt"}
                    values={{ name: cellData.data?.name }}
                  />
                }
              />
            );
          }
        },
      },
    ],
    [
      cellEditorSelector,
      onColumnHourlyPricingChange,
      onRemoveCostClass,
      t,
      validateCostClassCodeAndLength,
    ]
  );

  const defaultColDef: ColDef<CostClass> = useMemo(
    () => ({
      sortable: true,
      flex: 1,
      resizable: true,
      filter: true,
    }),
    []
  );

  const handleSubmit = (formValue: CostClass) => {
    onChange([...costClasses, formValue]);
  };

  const ccCount = costClasses.length;
  const importCcCount = fileCcCount ?? 0;

  return (
    <>
      <Header
        title={t`tabs.costClasses`}
      >{t`tabDescriptions.costClasses`}</Header>
      <Table<CostClass>
        className="w-full h-[261px]"
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowData={costClasses}
        stopEditingWhenCellsLoseFocus={true}
        onCellValueChanged={onCellValueChanged}
        context={{
          setValidationError,
          t,
        }}
      />
      <ErrorMessage errorMessage={validationError} />
      {importCcCount > 1 && ccCount !== fileCcCount && (
        <ErrorMessage
          ns="projects"
          i18nKey={"details.costClasses.errors.fileCcCountDiff"}
          values={{ ccCount: costClasses.length, fileCcCount: importCcCount }}
          testId={"fileCcCountDiff-error"}
        />
      )}
      {!ccDiffs
        ? null
        : Object.entries(ccDiffs).map(([ccCode, ccDiff], index) => (
            <div key={index}>
              {ccDiff.missing && (
                <ErrorMessage
                  ns="projects"
                  i18nKey={"details.costClasses.errors.missingCc"}
                  values={{ ccCode }}
                />
              )}
              {ccDiff.changedHourlyPricing !== undefined && (
                <ErrorMessage
                  ns="projects"
                  i18nKey={"details.costClasses.errors.changedHourlyPricing"}
                  values={{ ccCode }}
                />
              )}
              {ccDiff.changedSocExp !== undefined && (
                <ErrorMessage
                  ns="projects"
                  i18nKey={"details.costClasses.errors.changedSocExp"}
                  values={{
                    ccCode,
                    origSocExp: (ccDiff.origSocExp ?? 0) * 100,
                  }}
                />
              )}
            </div>
          ))}
      <NewCostClassForm
        costClasses={costClasses}
        projectId={projectId}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export function parseCostClassSocialExpensePercentage(
  params: ValueParserParams<CostClass>
) {
  const newValue = params.newValue.replace(",", ".");
  const { setValidationError, t } = params.context;
  if (!isNumber(newValue)) {
    setValidationError(t`costClasses.errors.notANumber`);
    return params.oldValue;
  } else if (!isPositive(newValue)) {
    setValidationError(t`costClasses.errors.notPositive`);
    return params.oldValue;
  } else if (Number(newValue) > 100) {
    setValidationError(t`costClasses.errors.tooLarge`);
    return params.oldValue;
  } else if (!newValue) {
    setValidationError("");
    return 0;
  }
  setValidationError("");
  return Number(newValue) / 100;
}

export function validateCostClassCode(
  value: string | number | boolean | null,
  costClasses: CostClass[]
) {
  return (
    costClasses.find((costClass) => costClass.costClassCode === value) ===
    undefined
  );
}

export function validateCostClassCodeLength(
  value: string | number | boolean | null
) {
  if (!value || typeof value === "boolean") {
    return false;
  }
  return typeof value === "string"
    ? value.length <= 2
    : value.toString().length <= 2;
}

export function costClassError(errorType: string, t: TFunction) {
  if (errorType === "required") {
    return t`costClasses.errors.required`;
  } else if (errorType === "length") {
    return t`costClasses.errors.costClassCodeLength`;
  } else if (errorType === "unique") {
    return t`costClasses.errors.costClassCodeNotUnique`;
  }
  return "";
}
