// Generated by purs version 0.14.5
"use strict";
var Common = require("../Common/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon = require("../TacoIcon/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoList_Styles = require("../TacoList.Styles/index.js");
var TacoText = require("../TacoText/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var TextValue = (function () {
    function TextValue(value0) {
        this.value0 = value0;
    };
    TextValue.create = function (value0) {
        return new TextValue(value0);
    };
    return TextValue;
})();
var JSXValue = (function () {
    function JSXValue(value0) {
        this.value0 = value0;
    };
    JSXValue.create = function (value0) {
        return new JSXValue(value0);
    };
    return JSXValue;
})();
var ExpandableItemWrapper = function (x) {
    return x;
};
var unExpandableItemWrapper = function (v) {
    return v;
};
var item = TofuHooks.mkHookComponent("Item")(function (props) {
    return function __do() {
        var css = TofuHooks.useMemo([ ReactHooksUtils.utf(props.onClick), ReactHooksUtils.utf(props.disabled) ])(function (v) {
            return TacoList_Styles.itemStyles({
                disabled: props.disabled,
                hasOnclick: Data_Maybe.isJust(props.onClick)
            });
        })();
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "item",
            css: css,
            onClick: Data_Maybe.maybe(ReactBasicUtils.noopHandler)(React_Basic_DOM_Events.capture_)(props.onClick),
            children: [ TacoText.component()()({
                children: (function () {
                    if (props.titleJSX instanceof Data_Maybe.Just) {
                        return [ props.titleJSX.value0 ];
                    };
                    if (props.titleJSX instanceof Data_Maybe.Nothing) {
                        return Common.emptyArray;
                    };
                    throw new Error("Failed pattern match at TacoList (line 67, column 25 - line 69, column 38): " + [ props.titleJSX.constructor.name ]);
                })(),
                text: (function () {
                    if (props.titleJSX instanceof Data_Maybe.Just) {
                        return "";
                    };
                    if (props.titleJSX instanceof Data_Maybe.Nothing) {
                        return props.title;
                    };
                    throw new Error("Failed pattern match at TacoList (line 70, column 21 - line 72, column 33): " + [ props.titleJSX.constructor.name ]);
                })(),
                inheritColor: true,
                testId: "taco-list-item-" + props.id
            }), (function () {
                if (props.value instanceof TextValue) {
                    return TacoText.component()()({
                        text: props.value.value0,
                        inheritColor: true,
                        testId: "taco-list-item-value-" + props.id
                    });
                };
                if (props.value instanceof JSXValue) {
                    return props.value.value0;
                };
                throw new Error("Failed pattern match at TacoList (line 76, column 11 - line 78, column 32): " + [ props.value.constructor.name ]);
            })() ]
        });
    };
});
var expandableItem = TofuHooks.mkHookComponent("ExpandableItem")(function (props) {
    return function __do() {
        var v = TofuHooks.useState(props.initExpanded)();
        var iconCss = TofuHooks.useMemo([ ReactHooksUtils.utf(props.hasItems) ])(function (v1) {
            return TacoList_Styles.iconContainerStyles({
                hasChildren: props.hasItems
            });
        })();
        var icon = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "icon-container",
            css: iconCss,
            children: [ TacoIcon.component()()()()({
                icon: TacoIcon_Icons_Types.IconGtV1.value,
                rotation: (function () {
                    if (v.value0) {
                        return 90;
                    };
                    return 0;
                })(),
                rotating: true,
                color: (function () {
                    if (props.hasItems) {
                        return TacoConstants.GrayDarkest.value;
                    };
                    return TacoConstants.Transparent.value;
                })(),
                size: TacoConstants.XXS.value
            }) ]
        });
        var expandableItemCss = TofuHooks.useMemo([ ReactHooksUtils.utf(v.value0), ReactHooksUtils.utf(props.hasItems), ReactHooksUtils.utf(props.disabled) ])(function (v1) {
            return TacoList_Styles.expandableItemStyles({
                expanded: v.value0,
                hasChildren: props.hasItems,
                disabled: props.disabled
            });
        })();
        return React_Basic.keyed(props.id)(React_Basic.fragment([ React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "expandable-item",
            css: expandableItemCss,
            onClick: React_Basic_DOM_Events.capture_(v.value1(function (value) {
                return !value;
            })),
            children: [ (function () {
                var $24 = !props.hasItems;
                if ($24) {
                    return React_Basic.empty;
                };
                return icon;
            })(), item(props) ]
        }), (function () {
            var $25 = !v.value0;
            if ($25) {
                return React_Basic.empty;
            };
            return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                className: "expandable-item-list",
                css: TacoList_Styles.expandableItemListStyles,
                children: (function () {
                    if (v.value0) {
                        return Data_Functor.map(Data_Functor.functorArray)(function ($33) {
                            return expandableItem(unExpandableItemWrapper($33));
                        })(props.getItems(Data_Unit.unit));
                    };
                    return [  ];
                })()
            });
        })() ]));
    };
});
var component = TofuHooks.mkHookComponent("TacoList")(function (props) {
    return function __do() {
        var list = TofuHooks.useMemo([ props.items ])(function (v) {
            return Data_Functor.map(Data_Functor.functorArray)(expandableItem)(props.items);
        })();
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "__TacoList",
            css: TacoList_Styles.listContainerStyles,
            children: list
        });
    };
});
module.exports = {
    component: component,
    TextValue: TextValue,
    JSXValue: JSXValue,
    ExpandableItemWrapper: ExpandableItemWrapper
};
