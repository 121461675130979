import { useMutation } from "react-query";
import axiosClient from "../../../../axiosConfig";
import { reportError } from "../../../../utils/error";
import { AxiosError } from "axios";

type ChangeSubprojectParams = {
  components: number[];
  subprojectId: number;
};
export function useChangeSubproject(projectId: number, onSave: () => void) {
  return useMutation(
    async (params: ChangeSubprojectParams) => {
      const res = await axiosClient.post(
        `/project/${projectId}/componentSubprojectUpdate`,
        params
      );
      return res.data;
    },
    {
      onSuccess: () => {
        onSave();
      },
      onError: (error) => {
        if (error instanceof AxiosError) {
          reportError(error.response?.data.msg, error);
        }
      },
    }
  );
}
