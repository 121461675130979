// Generated by purs version 0.14.5
"use strict";
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Traversable = require("../Data.Traversable/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var HtmlUtils = require("../HtmlUtils/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var TacoInput = require("../TacoInput/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var UseNullableRef = require("../UseNullableRef/index.js");
var useForm = function (handler) {
    return function __do() {
        var formRef = UseNullableRef.useNullableRef();
        var v = TofuHooks.useState(Data_Map_Internal.empty)();
        var v1 = TofuHooks.useState(false)();
        var updateFieldValidity = function (key) {
            return function (validity) {
                return function __do() {
                    v.value1(function (fields) {
                        if (validity instanceof TacoInput.Valid) {
                            return Data_Map_Internal["delete"](Data_Ord.ordString)(key)(fields);
                        };
                        if (validity instanceof TacoInput.Invalid) {
                            return Data_Map_Internal.insert(Data_Ord.ordString)(key)(validity)(fields);
                        };
                        throw new Error("Failed pattern match at UseForm (line 55, column 9 - line 57, column 54): " + [ validity.constructor.name ]);
                    })();
                    return Data_Unit.unit;
                };
            };
        };
        var scrollFormIntoView = function __do() {
            var mElement = HtmlUtils.readRefMaybeElement(formRef)();
            Data_Traversable.traverse(Data_Traversable.traversableMaybe)(Effect.applicativeEffect)(HtmlUtils.scrollIntoView)(mElement)();
            return Data_Unit.unit;
        };
        var onSubmit = React_Basic_DOM_Events.capture_((function () {
            var v2 = Data_Map_Internal.isEmpty(v.value0);
            if (v2) {
                return function __do() {
                    handler();
                    return v1.value1(function (v3) {
                        return false;
                    })();
                };
            };
            if (!v2) {
                return function __do() {
                    v1.value1(function (v3) {
                        return true;
                    })();
                    return scrollFormIntoView();
                };
            };
            throw new Error("Failed pattern match at UseForm (line 45, column 7 - line 51, column 29): " + [ v2.constructor.name ]);
        })());
        var isFieldError = function (key) {
            return v1.value0 && Data_Map_Internal.member(Data_Ord.ordString)(key)(v.value0);
        };
        return {
            onSubmit: onSubmit,
            updateFieldValidity: updateFieldValidity,
            isFieldError: isFieldError,
            formRef: formRef
        };
    };
};
module.exports = {
    useForm: useForm
};
