// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unfoldable = require("../Data.Unfoldable/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Foreign_Object = require("../Foreign.Object/index.js");
var Utils = require("../Utils/index.js");
var IntValue = (function () {
    function IntValue(value0) {
        this.value0 = value0;
    };
    IntValue.create = function (value0) {
        return new IntValue(value0);
    };
    return IntValue;
})();
var StringValue = (function () {
    function StringValue(value0) {
        this.value0 = value0;
    };
    StringValue.create = function (value0) {
        return new StringValue(value0);
    };
    return StringValue;
})();
var xor = function (dictEq) {
    return function (as) {
        return function (bs) {
            return Data_Array.difference(dictEq)(Data_Array.union(dictEq)(as)(bs))(Data_Array.intersect(dictEq)(as)(bs));
        };
    };
};
var toggleInArray = function (dictEq) {
    return function (value) {
        return function (arr) {
            var $12 = Data_Array.elem(dictEq)(value)(arr);
            if ($12) {
                return Data_Array["delete"](dictEq)(value)(arr);
            };
            return Data_Array.snoc(arr)(value);
        };
    };
};
var oneOf = function (trials) {
    var found = Data_Array.findMap(function (f) {
        return f(Data_Unit.unit);
    })(trials);
    return found;
};
var eqIntOrString = {
    eq: function (x) {
        return function (y) {
            if (x instanceof IntValue && y instanceof IntValue) {
                return x.value0 === y.value0;
            };
            if (x instanceof StringValue && y instanceof StringValue) {
                return x.value0 === y.value0;
            };
            return false;
        };
    }
};
var ordIntOrString = {
    compare: function (v) {
        return function (v1) {
            if (v instanceof IntValue && v1 instanceof StringValue) {
                return Data_Ordering.LT.value;
            };
            if (v instanceof StringValue && v1 instanceof IntValue) {
                return Data_Ordering.GT.value;
            };
            if (v instanceof IntValue && v1 instanceof IntValue) {
                return Data_Ord.compare(Data_Ord.ordInt)(v.value0)(v1.value0);
            };
            if (v instanceof StringValue && v1 instanceof StringValue) {
                return Data_Ord.compare(Data_Ord.ordString)(v.value0)(v1.value0);
            };
            throw new Error("Failed pattern match at ArrayUtils (line 19, column 1 - line 23, column 56): " + [ v.constructor.name, v1.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqIntOrString;
    }
};
var deduplicateBy = function (dictOrd) {
    return function (keyFn) {
        return function (xs) {
            var go = function (acc) {
                return function (x) {
                    var key = keyFn(x);
                    return Data_Map_Internal.insert(dictOrd)(key)(x)(acc);
                };
            };
            return Data_Functor.map(Data_Functor.functorArray)(Data_Tuple.snd)(Data_Map_Internal.toUnfoldableUnordered(Data_Unfoldable.unfoldableArray)(Data_Array.foldl(go)(Data_Map_Internal.empty)(xs)));
        };
    };
};
var compareUnknown = function (a) {
    return function (b) {
        return $foreign.compareUnknownImpl({
            lt: Data_Ordering.LT.value,
            gt: Data_Ordering.GT.value,
            eq: Data_Ordering.EQ.value
        })(a)(b);
    };
};
var compareByNullableProperty$prime = function (v) {
    return function (objX) {
        return function (objY) {
            var mY = Foreign_Object.lookup(v)(objY);
            var mX = Foreign_Object.lookup(v)(objX);
            if (mX instanceof Data_Maybe.Just && mY instanceof Data_Maybe.Nothing) {
                return Data_Ordering.LT.value;
            };
            if (mX instanceof Data_Maybe.Nothing && mY instanceof Data_Maybe.Just) {
                return Data_Ordering.GT.value;
            };
            if (mX instanceof Data_Maybe.Nothing && mY instanceof Data_Maybe.Nothing) {
                return Data_Ordering.EQ.value;
            };
            if (mX instanceof Data_Maybe.Just && mY instanceof Data_Maybe.Just) {
                return compareUnknown(mX.value0)(mY.value0);
            };
            throw new Error("Failed pattern match at ArrayUtils (line 48, column 3 - line 52, column 41): " + [ mX.constructor.name, mY.constructor.name ]);
        };
    };
};
var compareByNullableProperty = function (sortProperty) {
    return function (rX) {
        return function (rY) {
            var objY = Utils.recordToObject(rY);
            var objX = Utils.recordToObject(rX);
            return compareByNullableProperty$prime(sortProperty)(objX)(objY);
        };
    };
};
var bucket = function (n) {
    return function (xs) {
        var $38 = Data_Array["null"](xs);
        if ($38) {
            return [  ];
        };
        var last = Data_Array.drop(n)(xs);
        var first = Data_Array.take(n)(xs);
        return Data_Semigroup.append(Data_Semigroup.semigroupArray)([ first ])(bucket(n)(last));
    };
};
module.exports = {
    IntValue: IntValue,
    StringValue: StringValue,
    compareUnknown: compareUnknown,
    compareByNullableProperty: compareByNullableProperty,
    "compareByNullableProperty'": compareByNullableProperty$prime,
    xor: xor,
    bucket: bucket,
    toggleInArray: toggleInArray,
    oneOf: oneOf,
    deduplicateBy: deduplicateBy,
    eqIntOrString: eqIntOrString,
    ordIntOrString: ordIntOrString,
    compareUnknownImpl: $foreign.compareUnknownImpl
};
