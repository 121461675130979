// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var InvoiceUtils = require("../InvoiceUtils/index.js");
var Invoices_Styles = require("../Invoices.Styles/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_CostGroup = require("../Types.CostGroup/index.js");
var Types_Invoice = require("../Types.Invoice/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var Utils = require("../Utils/index.js");
var styleSet = TacoTable_Styles.foldingTableStyleSet;
var supplierInvoiceDetails = TofuHooks.mkHookComponent("SupplierInvoiceDetails")(function (props) {
    var isInvoiceLineChecked = function (invoiceLine) {
        return Data_Foldable.elem(Data_Foldable.foldableArray)(Types_Invoice.eqInvoiceRowId)(invoiceLine.id)(props.selectedInvoiceLineIds);
    };
    var columns = (function () {
        var invoiceNumberWithImageLink = function (invoiceLine) {
            return props.invoiceImgLinkComponent({
                invoiceNumber: Utils.defaultNull("")(invoiceLine.invoiceNumber),
                invoiceImg: Data_Nullable.toMaybe(invoiceLine.invoiceImg)
            });
        };
        var invoiceBunchIdColumn = (function () {
            if (props.showInvoiceBunchIds) {
                return [ {
                    key: "invoiceBunchId",
                    label: React_Basic_DOM.text("Tunnus"),
                    cell: TacoTable_Types.PlainTextCell.create((function () {
                        var $15 = Data_Newtype.un()(Types_Invoice.InvoiceBunchId);
                        var $16 = Utils.defaultNull("");
                        return function ($17) {
                            return $15($16((function (v) {
                                return v.invoiceBunchId;
                            })($17)));
                        };
                    })()),
                    sortProperty: new Data_Maybe.Just(InvoiceUtils.ByInvoiceBunchId.value),
                    width: new TacoTable_Types.Flex(5),
                    headerJSX: Data_Maybe.Nothing.value
                } ];
            };
            return [  ];
        })();
        var hoursColumn = (function () {
            var $9 = Data_Foldable.any(Data_Foldable.foldableArray)(Data_HeytingAlgebra.heytingAlgebraBoolean)(Utils.isSalary)(props.invoiceLinesForSupplier.invoiceLines);
            if ($9) {
                return [ {
                    key: "hours",
                    label: React_Basic_DOM.text("Tunnit"),
                    cell: TacoTable_Types.PlainNumberCell.create(1)((function () {
                        var $18 = Utils.defaultNull(0.0);
                        return function ($19) {
                            return $18((function (v) {
                                return v.hours;
                            })($19));
                        };
                    })()),
                    sortProperty: new Data_Maybe.Just(InvoiceUtils.ByHours.value),
                    width: new TacoTable_Types.Flex(7),
                    headerJSX: Data_Maybe.Nothing.value
                } ];
            };
            return [  ];
        })();
        var costClassElement = function (invoiceLine) {
            return Utils.defaultNull(Data_Newtype.un()(Types_Newtypes.CostClassCode)(invoiceLine.costClassCode))(invoiceLine.costClass);
        };
        var checkBoxElement = function (invoiceLine) {
            var isChecked = isInvoiceLineChecked(invoiceLine);
            var toggleF = (function () {
                if (isChecked) {
                    return props.onInvoiceLinesDeselect;
                };
                return props.onInvoiceLinesSelect;
            })();
            return TacoCheckbox.component()()({
                className: "no-margin",
                isChecked: isChecked,
                onToggle: TacoCheckbox.NoEvent.create(toggleF(Control_Applicative.pure(Control_Applicative.applicativeArray)(invoiceLine.id))),
                labelText: Data_Maybe.Nothing.value,
                testId: "toggle-" + Data_Show.show(Types_Invoice.showInvoiceRowId)(invoiceLine.id)
            });
        };
        var optionalCheckbox = (function () {
            if (props.showCheckboxes) {
                return [ {
                    key: "checkbox",
                    label: React_Basic_DOM.text(""),
                    cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], checkBoxElement),
                    sortProperty: new Data_Maybe.Just(InvoiceUtils.ByCostGroupCode.value),
                    width: new TacoTable_Types.Flex(2),
                    headerJSX: Data_Maybe.Nothing.value
                } ];
            };
            return [  ];
        })();
        return Data_Semigroup.append(Data_Semigroup.semigroupArray)(optionalCheckbox)(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ {
            key: "date",
            label: React_Basic_DOM.text("Pvm."),
            cell: TacoTable_Types.PlainDateStringCell.create((function () {
                var $20 = Utils.defaultNull("");
                return function ($21) {
                    return $20((function (v) {
                        return v.date;
                    })($21));
                };
            })()),
            sortProperty: new Data_Maybe.Just(InvoiceUtils.ByDate.value),
            width: new TacoTable_Types.Flex(10),
            headerJSX: Data_Maybe.Nothing.value
        } ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(invoiceBunchIdColumn)(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ {
            key: "invoiceNumber",
            label: React_Basic_DOM.text("Laskun nro."),
            cell: new TacoTable_Types.JSXCell([  ], invoiceNumberWithImageLink),
            sortProperty: new Data_Maybe.Just(InvoiceUtils.ByInvoiceNumber.value),
            width: new TacoTable_Types.Flex(10),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "costGroupCode",
            label: React_Basic_DOM.text("Littera"),
            cell: TacoTable_Types.PlainTextCell.create((function () {
                var $22 = Data_Newtype.un()(Types_CostGroup.CostGroupCode);
                var $23 = Utils.defaultNull("");
                return function ($24) {
                    return $22($23((function (v) {
                        return v.costGroupCode;
                    })($24)));
                };
            })()),
            sortProperty: new Data_Maybe.Just(InvoiceUtils.ByCostGroupCode.value),
            width: new TacoTable_Types.Flex(5),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "costClass",
            label: React_Basic_DOM.text("KL"),
            cell: new TacoTable_Types.PlainTextCell(costClassElement),
            sortProperty: new Data_Maybe.Just(InvoiceUtils.ByCostClassCode.value),
            width: new TacoTable_Types.Flex(6),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "lineDescription",
            label: React_Basic_DOM.text("Riviselite"),
            cell: TacoTable_Types.PlainTextCell.create((function () {
                var $25 = Utils.defaultNull("");
                return function ($26) {
                    return $25((function (v) {
                        return v.lineDescription;
                    })($26));
                };
            })()),
            sortProperty: new Data_Maybe.Just(InvoiceUtils.ByLineDescription.value),
            width: new TacoTable_Types.Flex(18),
            headerJSX: Data_Maybe.Nothing.value
        } ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(hoursColumn)([ {
            key: "value",
            label: React_Basic_DOM.text("Summa"),
            cell: new TacoTable_Types.PlainEuroCell(function (v) {
                return v.value;
            }),
            sortProperty: new Data_Maybe.Just(InvoiceUtils.ByValue.value),
            width: new TacoTable_Types.Flex(8),
            headerJSX: Data_Maybe.Nothing.value
        } ])))));
    })();
    var invoiceLinesTable = function (v) {
        var getInvoiceLineRow = function (invoiceLine) {
            return {
                rowData: invoiceLine,
                rowKey: Data_Show.show(Types_Invoice.showInvoiceRowId)(invoiceLine.id),
                onClick: function (v1) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                },
                className: (function () {
                    var $13 = isInvoiceLineChecked(invoiceLine);
                    if ($13) {
                        return "checked";
                    };
                    return "";
                })(),
                foldingElement: Data_Maybe.Nothing.value,
                columns: columns
            };
        };
        return TacoTable.tableSortable(InvoiceUtils.getNameInvoiceLineSortProperty)(InvoiceUtils.eqInvoiceLineSortProperty)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "value";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "unit";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "type";
            }
        })(Types_Invoice.eqInvoiceType))()({
            reflectSymbol: function () {
                return "supplierInvoiceNumber";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "supplierBusinessId";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "supplier";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "socialExpensesPercentage";
            }
        })(Types_Unit.eqPercentageOfHundred))()({
            reflectSymbol: function () {
                return "quantity";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
            reflectSymbol: function () {
                return "lineDescription";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "invoiceNumber";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "invoiceImg";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "invoiceId";
            }
        })(Types_Invoice.eqInvoiceId))()({
            reflectSymbol: function () {
                return "invoiceBunchId";
            }
        })(Data_Nullable.eqNullable(Types_Invoice.eqInvoiceBunchId)))()({
            reflectSymbol: function () {
                return "id";
            }
        })(Types_Invoice.eqInvoiceRowId))()({
            reflectSymbol: function () {
                return "hours";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
            reflectSymbol: function () {
                return "description";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "date";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "costGroupCode";
            }
        })(Data_Nullable.eqNullable(Types_CostGroup.eqCostGroupCode)))()({
            reflectSymbol: function () {
                return "costClassCode";
            }
        })(Types_Newtypes.eqCostClassCode))()({
            reflectSymbol: function () {
                return "costClass";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString))))(InvoiceUtils.invoiceLinesTableInstance)(new TacoTable_Types.SortingCriteria(TacoTable_Types.Ascending.value, new Data_Maybe.Just(InvoiceUtils.ByDate.value), InvoiceUtils.getInvoiceLineSortingValue))(Data_Maybe.Nothing.value)({
            rows: Data_Functor.map(Data_Functor.functorArray)(getInvoiceLineRow)(v.invoiceLines),
            columns: columns,
            showHeader: true,
            styleSet: styleSet
        });
    };
    var linesTable = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        className: "lines-table",
        css: Invoices_Styles.invoiceDetailsRowStyles,
        children: [ TacoText.component()()({
            text: "Rivitiedot",
            weight: TacoText_Types.Bold.value
        }), invoiceLinesTable({
            invoiceLines: props.invoiceLinesForSupplier.invoiceLines,
            selectedInvoiceLineIds: props.selectedInvoiceLineIds,
            onInvoiceLinesSelect: props.onInvoiceLinesSelect,
            onInvoiceLinesDeselect: props.onInvoiceLinesDeselect
        }) ]
    });
    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        className: "invoice-details",
        css: Invoices_Styles.invoiceDetailsStyles,
        children: [ linesTable ]
    }));
});
module.exports = {
    supplierInvoiceDetails: supplierInvoiceDetails
};
