// Generated by purs version 0.14.5
"use strict";
var Admin_Styles = require("../Admin.Styles/index.js");
var AllSet = require("../AllSet/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var Roles_Types = require("../Roles.Types/index.js");
var TacoChip = require("../TacoChip/index.js");
var Types_Project = require("../Types.Project/index.js");
var writableProjectsCell = function (capabilities) {
    return React_Basic_DOM_Generated.div()({
        className: "writable-projects",
        children: (function () {
            var v = AllSet.union(Types_Project.ordProjectId)(capabilities.ceWritableProjects)(capabilities.ccWritableProjects);
            if (v instanceof AllSet.All) {
                return Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM.text("Kaikki hankkeet"));
            };
            if (v instanceof AllSet.Some) {
                var readableProjectsCount = Data_Set.size(v.value0);
                var label = (function () {
                    var $4 = readableProjectsCount === 1;
                    if ($4) {
                        return " hanke";
                    };
                    return " hanketta";
                })();
                return Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM.text(Data_Show.show(Data_Show.showInt)(readableProjectsCount) + label));
            };
            throw new Error("Failed pattern match at Admin.Utils (line 51, column 7 - line 57, column 67): " + [ v.constructor.name ]);
        })()
    });
};
var userCapabilitiesFromRole = function (v) {
    var ceWritableProjects = (function () {
        if (v.ceWritePermission) {
            return AllSet.fromFoldable(Data_Foldable.foldableArray)(Types_Project.ordProjectId)([ Types_Project.ProjectId(-1 | 0) ]);
        };
        return AllSet.empty;
    })();
    var ceReadableProjects = (function () {
        if (v.ceReadPermission) {
            return AllSet.fromFoldable(Data_Foldable.foldableArray)(Types_Project.ordProjectId)([ Types_Project.ProjectId(-1 | 0) ]);
        };
        return AllSet.empty;
    })();
    var ccWritableProjects = (function () {
        if (v.ccWritePermission) {
            return AllSet.fromFoldable(Data_Foldable.foldableArray)(Types_Project.ordProjectId)([ Types_Project.ProjectId(-1 | 0) ]);
        };
        return AllSet.empty;
    })();
    var ccReadableProjects = (function () {
        if (v.ccReadPermission) {
            return AllSet.fromFoldable(Data_Foldable.foldableArray)(Types_Project.ordProjectId)([ Types_Project.ProjectId(-1 | 0) ]);
        };
        return AllSet.empty;
    })();
    return {
        createProjectsPermission: (function () {
            if (v.createProjectsPermission) {
                return Roles_Types.CreateProjectsAllowed.value;
            };
            return Roles_Types.CreateProjectsDisallowed.value;
        })(),
        costControlPermission: (function () {
            if (v.costControlPermission) {
                return Roles_Types.UsingCostControlAllowed.value;
            };
            return Roles_Types.UsingCostControlDisallowed.value;
        })(),
        costEstimationPermission: (function () {
            if (v.costEstimationPermission) {
                return Roles_Types.UsingCostEstimationAllowed.value;
            };
            return Roles_Types.UsingCostEstimationDisallowed.value;
        })(),
        costGroupTargetWritePermission: (function () {
            if (v.costGroupTargetWritePermission) {
                return Roles_Types.WriteCostGroupTargetAllowed.value;
            };
            return Roles_Types.WriteCostGroupTargetDisallowed.value;
        })(),
        isAdmin: (function () {
            if (v.isAdmin) {
                return Roles_Types.IsOrganizationAdmin.value;
            };
            return Roles_Types.NotOrganizationAdmin.value;
        })(),
        ccReadableProjects: ccReadableProjects,
        ccWritableProjects: ccWritableProjects,
        ceReadableProjects: ceReadableProjects,
        ceWritableProjects: ceWritableProjects
    };
};
var readableProjectsCell = function (capabilities) {
    return React_Basic_DOM_Generated.div()({
        className: "readable-projects",
        children: (function () {
            var v = AllSet.union(Types_Project.ordProjectId)(capabilities.ccReadableProjects)(capabilities.ceReadableProjects);
            if (v instanceof AllSet.All) {
                return Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM.text("Kaikki hankkeet"));
            };
            if (v instanceof AllSet.Some) {
                var readableProjectsCount = Data_Set.size(v.value0);
                var label = (function () {
                    var $17 = readableProjectsCount === 1;
                    if ($17) {
                        return " hanke";
                    };
                    return " hanketta";
                })();
                return Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM.text(Data_Show.show(Data_Show.showInt)(readableProjectsCount) + label));
            };
            throw new Error("Failed pattern match at Admin.Utils (line 38, column 7 - line 44, column 67): " + [ v.constructor.name ]);
        })()
    });
};
var createProjectsCell = function (capabilities) {
    if (capabilities.createProjectsPermission instanceof Roles_Types.CreateProjectsAllowed) {
        return "Kyll\xe4";
    };
    if (capabilities.createProjectsPermission instanceof Roles_Types.CreateProjectsDisallowed) {
        return "Ei";
    };
    throw new Error("Failed pattern match at Admin.Utils (line 30, column 3 - line 32, column 37): " + [ capabilities.createProjectsPermission.constructor.name ]);
};
var appChips = function (capabilities) {
    return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        className: "app-chips",
        css: Admin_Styles.chipListStyles,
        children: [ Data_Monoid.guard(React_Basic.monoidJSX)(Data_Eq.eq(Roles_Types.costControlPermissionEq)(capabilities.costControlPermission)(Roles_Types.UsingCostControlAllowed.value))(TacoChip.component()()({
            contents: Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM.text("Raportointi")),
            testId: "reporting-chip"
        })), Data_Monoid.guard(React_Basic.monoidJSX)(Data_Eq.eq(Roles_Types.costEstimationPermissionEq)(capabilities.costEstimationPermission)(Roles_Types.UsingCostEstimationAllowed.value))(TacoChip.component()()({
            contents: Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM.text("Laskenta")),
            testId: "reporting-chip"
        })) ]
    });
};
module.exports = {
    appChips: appChips,
    createProjectsCell: createProjectsCell,
    readableProjectsCell: readableProjectsCell,
    writableProjectsCell: writableProjectsCell,
    userCapabilitiesFromRole: userCapabilitiesFromRole
};
