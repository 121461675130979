import { DateTime } from "luxon";
import { ColDef, ValueFormatterParams } from "ag-grid-community";

export const isoDateColDef: ColDef = {
  filter: "agDateColumnFilter",
  filterParams: {
    buttons: ["apply", "reset"],
    comparator: isoDateComparator,
  },
  valueFormatter: isoDateFormatter,
  cellClass: "dateType",
};

function isoDateComparator(filterLocalDateAtMidnight: Date, cellValue: string) {
  const cellDate = DateTime.fromISO(cellValue);
  const filterDate = DateTime.fromJSDate(filterLocalDateAtMidnight);
  if (cellDate > filterDate) {
    return 1;
  }
  if (cellDate.hasSame(filterDate, "day")) {
    return 0;
  }
  return -1;
}

export function isoDateFormatter(data: ValueFormatterParams) {
  return data.value
    ? DateTime.fromISO(data.value).toFormat("dd'.'MM'.'yyyy")
    : "";
}
