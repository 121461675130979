// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Color = require("../Color/index.js");
var Color_Scheme_X11 = require("../Color.Scheme.X11/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var example1 = "import Box (BoxProp(..), box)\x0aimport Color as Color\x0aimport Color.Scheme.X11 as Color.Scheme.X11\x0aimport React.Basic.DOM as R\x0aimport TacoConstants (ColorScheme(..), Size(..))\x0a\x0a[ box \"exampleComponentName\"\x0a    [ BackgroundColor Color.Scheme.X11.maroon\x0a    , ForegroundColor Color.white\x0a    , Width (CustomSize \"8rem\")\x0a    , Height (CustomSize \"8rem\")\x0a    , Margin XXL\x0a    , Padding M\x0a    , PlaceCenter\x0a    , Hover [ BackgroundColor $ PrimaryBlue ]\x0a    ]\x0a    [ R.text \"Hello world\" ]\x0a]";
var sections = [ {
    title: "Default",
    view: [ Box.box("exampleComponentName")([ Box.BackgroundColor.create(new TacoConstants.CustomColor(Color_Scheme_X11.maroon)), Box.ForegroundColor.create(new TacoConstants.CustomColor(Color.white)), new Box.Width(new TacoConstants.CustomSize("8rem")), new Box.Height(new TacoConstants.CustomSize("8rem")), new Box.Margin(TacoConstants.XXL.value), new Box.Padding(TacoConstants.M.value), Box.PlaceCenter.value, new Box.Hover([ Box.BackgroundColor.create(TacoConstants.PrimaryBlue.value) ]) ])([ React_Basic_DOM.text("Hello world") ]) ],
    code: example1
} ];
var demoView = {
    sections: sections,
    title: "Box",
    description: "Container component for applying styles around child components"
};
module.exports = {
    demoView: demoView
};
