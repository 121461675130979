var React = require("react");

exports.createComponent = function (displayName) {
  let render;

  const Component = function (props) {
    // 2nd invocation runs effect
    return render(props)();
  };

  Component.displayName = displayName;
  Component.name = displayName;
  Component.toString = function () {
    return displayName;
  };
  Component.setRender = function (render_) {
    render = render_;
  };

  return Component;
};

exports.make_ = function (Component) {
  return function (render) {
    Component.setRender(render);

    // eslint-disable-next-line react/display-name
    return function (props) {
      return React.createElement(Component, props);
    };
  };
};

// args must stay constant length, which is guaranteed by typed purescript code
exports.memoizeArgsFn_ = function (origFn) {
  return function (uncurriedFn) {
    return function (args) {
      return function () {
        const argRefs = args.map(function () {
          return React.useRef(null);
        });

        const origFnRef = React.useRef(null);
        const resultRef = React.useRef(null);

        let refsInvalidated = false;

        for (let i = 0; i < argRefs.length; i++) {
          const ref = argRefs[i];
          const arg = args[i];

          if (ref.current === null || ref.current !== arg) {
            refsInvalidated = true;
            break;
          }
        }

        if (
          refsInvalidated ||
          origFnRef.current === null ||
          origFnRef.current !== origFn
        ) {
          for (let i = 0; i < argRefs.length; i++) {
            argRefs[i].current = args[i];
          }

          origFnRef.current = origFn;

          const result = uncurriedFn.apply(null, args);
          resultRef.current = result;

          return result;
        } else {
          return resultRef.current;
        }
      };
    };
  };
};

window.memoizeCache = {};

exports.memoizeByEqKey_ = function (fn) {
  return function (cacheKey) {
    if (!window.memoizeCache[cacheKey]) {
      window.memoizeCache[cacheKey] = {
        key: undefined,
        retVal: undefined,
      };
    }

    const cache = window.memoizeCache[cacheKey];

    return function (checkEq) {
      return function (key) {
        if (cache.key !== undefined && checkEq(key)(cache.key))
          return cache.retVal;

        cache.key = key;
        cache.retVal = fn();

        return cache.retVal;
      };
    };
  };
};

exports.unsafeSetDisplayName = function (displayName, component) {
  component.displayName = displayName;
  component.toString = function () {
    return displayName;
  };
  return component;
};
