// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var observe = function (dictUnion) {
    return $foreign["_observe"];
};
module.exports = {
    observe: observe,
    mutationObserver: $foreign.mutationObserver,
    disconnect: $foreign.disconnect,
    takeRecords: $foreign.takeRecords
};
