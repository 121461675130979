// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Client_WASM = require("../Client.WASM/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Except = require("../Control.Monad.Except/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Class_Console = require("../Effect.Class.Console/index.js");
var Foreign = require("../Foreign/index.js");
var Foreign_Index = require("../Foreign.Index/index.js");
var Grid = require("../Grid/index.js");
var LengthValidator = require("../LengthValidator/index.js");
var Locale = require("../Locale/index.js");
var Milkis = require("../Milkis/index.js");
var ProjectComponents = require("../ProjectComponents/index.js");
var ProjectComponents_Styles = require("../ProjectComponents.Styles/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var RequestUtils = require("../RequestUtils/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoInput_Styles = require("../TacoInput.Styles/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_Alert = require("../Types.Alert/index.js");
var Types_FrontEndRoutes = require("../Types.FrontEndRoutes/index.js");
var Types_ReasonableCoerce = require("../Types.ReasonableCoerce/index.js");
var UseForm = require("../UseForm/index.js");
var Utils = require("../Utils/index.js");
var WasmWorker = require("../WasmWorker/index.js");
var WasmWorker_WorkerRequest = require("../WasmWorker.WorkerRequest/index.js");
var component = TofuHooks.mkHookComponent("ImportEstimationProject")(function (v) {
    return function __do() {
        var dispatch = StateHooks.useDispatch();
        var v1 = Client_WASM.useEstimationProjects();
        var v2 = TofuHooks.useState("")();
        var v3 = TofuHooks.useState("")();
        var v4 = TofuHooks.useState("")();
        var v5 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var v6 = TofuHooks.useState(false)();
        var existingCodesAndVersions = ProjectComponents.getExistingCodesAndVersions(new Data_Maybe.Just(v1.value0))();
        TofuHooks.useEffect([ ReactHooksUtils.utf(v3.value0), ReactHooksUtils.utf(existingCodesAndVersions) ])((function () {
            var latestVersion = ProjectComponents.getLatestVersionForCode(v3.value0)(existingCodesAndVersions);
            return function __do() {
                (function () {
                    if (latestVersion instanceof Data_Maybe.Just) {
                        return v4.value1(function (v7) {
                            return Data_Show.show(Data_Show.showInt)(latestVersion.value0 + 1 | 0);
                        })();
                    };
                    if (latestVersion instanceof Data_Maybe.Nothing) {
                        return v4.value1(function (v7) {
                            return Data_Show.show(Data_Show.showInt)(1);
                        })();
                    };
                    throw new Error("Failed pattern match at ImportEstimationProject (line 80, column 5 - line 82, column 41): " + [ latestVersion.constructor.name ]);
                })();
                return Data_Monoid.mempty(Effect.monoidEffect(Effect.monoidEffect(Data_Monoid.monoidUnit)))();
            };
        })())();
        var v7 = UseForm.useForm((function () {
            var dispatch$prime = (function () {
                var $62 = Effect_Class.liftEffect(Effect_Aff.monadEffectAff);
                return function ($63) {
                    return $62(dispatch($63));
                };
            })();
            return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(v5.value0)(function (fileObj) {
                return Effect_Aff.launchAff_(Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(v6.value1(function (v7) {
                    return true;
                })))(function () {
                    return Control_Bind.bind(Effect_Aff.bindAff)(RequestUtils.getAuthorization)(function (authorization) {
                        return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)($foreign["_prepareImportEstimationProjectFormData"](v2.value0)(v3.value0)(v4.value0)("EUR")(fileObj)))(function (content) {
                            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(RequestUtils.fetch()(Milkis.URL("/api/importProjectFromFile"))({
                                method: Milkis.postMethod,
                                headers: Milkis.makeHeaders()({
                                    Authorization: authorization
                                }),
                                body: Types_ReasonableCoerce.reasonableCoerce("formData is a valid body content for fetch")(content)
                            })))(function (response) {
                                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(v6.value1(function (v7) {
                                    return false;
                                })))(function () {
                                    if (response instanceof Data_Either.Right) {
                                        return Control_Bind.bind(Effect_Aff.bindAff)(Milkis.text(response.value0))(function (value) {
                                            var $27 = Milkis.statusCode(response.value0) === 200;
                                            if ($27) {
                                                return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(dispatch$prime(new Actions.AddAlert(Types_Alert.AlertInfo.value, Locale.lookup_("new_project_added"))))(function () {
                                                    return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(dispatch$prime(new Actions.NavigateRoute(Types_FrontEndRoutes.EstimationProjectsRoute.value)))(function () {
                                                        return Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(WasmWorker.sendWasmWorkerRequest(new WasmWorker_WorkerRequest.RefreshEstimationProjects({})));
                                                    });
                                                });
                                            };
                                            return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Effect_Class_Console.error(Effect_Aff.monadEffectAff)("failed to upload file: " + value))(function () {
                                                return dispatch$prime(Actions.AddAlert.create(Types_Alert.AlertError.value)(Locale.lookup_("error_unknown_please_refresh")));
                                            });
                                        });
                                    };
                                    if (response instanceof Data_Either.Left) {
                                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Utils.printError(Effect_Aff.monadEffectAff)(response.value0))(function () {
                                            return dispatch$prime(Actions.AddAlert.create(Types_Alert.AlertError.value)(Locale.lookup_("error_unknown_please_refresh")));
                                        });
                                    };
                                    throw new Error("Failed pattern match at ImportEstimationProject (line 97, column 7 - line 111, column 71): " + [ response.constructor.name ]);
                                });
                            });
                        });
                    });
                }));
            });
        })())();
        TofuHooks.useEffect([ ReactHooksUtils.utf(v5.value0) ])(function __do() {
            v7.updateFieldValidity("file")((function () {
                if (v5.value0 instanceof Data_Maybe.Nothing) {
                    return new TacoInput.Invalid("file is requried");
                };
                if (v5.value0 instanceof Data_Maybe.Just) {
                    return TacoInput.Valid.value;
                };
                throw new Error("Failed pattern match at ImportEstimationProject (line 115, column 7 - line 117, column 24): " + [ v5.value0.constructor.name ]);
            })())();
            return Data_Monoid.mempty(Effect.monoidEffect(Effect.monoidEffect(Data_Monoid.monoidUnit)))();
        })();
        var versionField = TacoInput.stringInput()()(Data_Eq.eqString)({
            label: "Versio",
            validator: LengthValidator.lengthValidator(1)(3),
            required: true,
            value: v4.value0,
            onValidationChange: v7.updateFieldValidity("version"),
            error: v7.isFieldError("version"),
            onChange: v4.value1,
            testId: "project-version"
        });
        var submitBtn = TacoButton.component()()({
            text: "Tuo hanke",
            buttonStyle: TacoButton_Types.Outlined.value,
            submit: true,
            testId: "submit-project-button",
            title: (function () {
                if (v6.value0) {
                    return "Lataa...";
                };
                return "";
            })(),
            icon: (function () {
                if (v6.value0) {
                    return new Data_Maybe.Just(TacoIcon_Icons_Types.IconFetch.value);
                };
                return Data_Maybe.Nothing.value;
            })(),
            disabled: Data_String_Common["null"](v2.value0) || (Data_String_Common["null"](v3.value0) || (Data_String_Common["null"](v4.value0) || (Data_Maybe.isNothing(v5.value0) || v6.value0)))
        });
        var nameField = TacoInput.stringInput()()(Data_Eq.eqString)({
            label: "Nimi",
            validator: LengthValidator.lengthValidator(3)(50),
            value: v2.value0,
            onValidationChange: v7.updateFieldValidity("name"),
            error: v7.isFieldError("name"),
            onChange: v2.value1,
            testId: "project-name"
        });
        var handleFileTarget = function (target) {
            var v8 = function (v9) {
                if (Data_Boolean.otherwise) {
                    return function __do() {
                        Effect_Class_Console.error(Effect_Class.monadEffectEffect)("error reading files from event")();
                        return dispatch(Actions.AddAlert.create(Types_Alert.AlertError.value)(Locale.lookup_("error_unknown_please_refresh")))();
                    };
                };
                throw new Error("Failed pattern match at ImportEstimationProject (line 61, column 1 - line 61, column 29): " + [ target.constructor.name ]);
            };
            var $37 = TofuJSON.read(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "files";
                }
            })(TofuJSON.tofuJSONForeign)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "files";
                }
            })(TofuJSON.tofuJSONForeign)(TofuJSON.nilWriteForeignFields)()()()))(Foreign.unsafeToForeign(target));
            if ($37 instanceof Data_Either.Right) {
                var $38 = Control_Monad_Except.runExcept(Foreign_Index.readIndex(Data_Identity.monadIdentity)(0)($37.value0.files));
                if ($38 instanceof Data_Either.Right) {
                    var $39 = TofuJSON.read(TofuJSON.readMaybe(TofuJSON.tofuJSONForeign))($38.value0);
                    if ($39 instanceof Data_Either.Right) {
                        if ($39.value0 instanceof Data_Maybe.Just) {
                            return function __do() {
                                v7.updateFieldValidity("file")(TacoInput.Valid.value)();
                                v5.value1(function (v9) {
                                    return new Data_Maybe.Just($39.value0.value0);
                                })();
                                return Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(Data_String_Common["null"](v2.value0))($foreign["_readFileContentsAsJSON"]($39.value0.value0)(function (json) {
                                    return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableEither)(TofuJSON.read(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                                        reflectSymbol: function () {
                                            return "srcProjectDetails";
                                        }
                                    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                                        reflectSymbol: function () {
                                            return "Nimi";
                                        }
                                    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                                        reflectSymbol: function () {
                                            return "Nimi";
                                        }
                                    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                                        reflectSymbol: function () {
                                            return "srcProjectDetails";
                                        }
                                    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                                        reflectSymbol: function () {
                                            return "Nimi";
                                        }
                                    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
                                        reflectSymbol: function () {
                                            return "Nimi";
                                        }
                                    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))(TofuJSON.nilWriteForeignFields)()()()))(json))(function (v9) {
                                        return v2.value1(function (v10) {
                                            return v9.srcProjectDetails.Nimi;
                                        });
                                    });
                                }))();
                            };
                        };
                        if ($39.value0 instanceof Data_Maybe.Nothing) {
                            return function __do() {
                                v7.updateFieldValidity("file")(new TacoInput.Invalid("empty"))();
                                return v5.value1(function (v9) {
                                    return Data_Maybe.Nothing.value;
                                })();
                            };
                        };
                        throw new Error("Failed pattern match at ImportEstimationProject (line 195, column 11 - line 205, column 36): " + [ $39.value0.constructor.name ]);
                    };
                    return v8(true);
                };
                return v8(true);
            };
            return v8(true);
        };
        var fileField = React_Basic_Emotion.element(React_Basic_DOM_Generated["input'"]())({
            id: "file",
            css: TacoInput_Styles.inputStyles({
                align: "left",
                currency: false,
                error: v7.isFieldError("file"),
                loading: false,
                textarea: false,
                color: Data_Maybe.Nothing.value,
                fontWeight: Data_Maybe.Nothing.value,
                expandWhenActive: false,
                rendering: TacoInput_Styles.DefaultRendering.value,
                hasCustomComponent: false,
                height: Data_Maybe.Nothing.value
            }),
            className: "",
            type: "file",
            accept: ".tcm",
            onChange: React_Basic_Events.handler(React_Basic_DOM_Events.target)(handleFileTarget)
        });
        var fileFields = React_Basic_DOM_Generated.div_([ React_Basic_DOM_Generated.label()({
            htmlFor: "file",
            children: [ TacoText.component()()({
                text: "Tuo tiedostosta"
            }) ]
        }), fileField ]);
        var codeField = TacoInput.stringInput()()(Data_Eq.eqString)({
            label: "Tunnus",
            validator: LengthValidator.lengthValidator(1)(12),
            required: true,
            value: v3.value0,
            onValidationChange: v7.updateFieldValidity("code"),
            error: v7.isFieldError("code"),
            onChange: v3.value1,
            testId: "project-code"
        });
        var form = Grid.grid([ new Box.Gap(TacoConstants.S.value), Box.AlignStart.value, new Box.PaddingBottom(TacoConstants.L.value) ])([ [ new Grid.Auto(nameField) ], [ new Grid.Flex(6, codeField), Grid.Flex.create(3)(React_Basic_DOM_Generated.div_([  ])), new Grid.Flex(3, versionField) ], [ new Grid.Auto(fileFields) ] ]);
        return React_Basic_DOM_Generated.div()({
            className: "import-estimation-project-page",
            children: [ TacoText.component()()({
                text: "Tuo siirtotiedosto",
                weight: TacoText_Types.Bold.value,
                gutterBottom: true,
                variant: new TacoText_Types.Heading(1),
                size: new TacoConstants.CustomSize("14px")
            }), React_Basic_Emotion.element(React_Basic_DOM_Generated["form'"]())({
                className: "__ImportEstimationProjectForm",
                css: ProjectComponents_Styles.projectComponentsContainerStyles,
                children: [ form, submitBtn ],
                onSubmit: v7.onSubmit,
                ref: v7.formRef
            }) ]
        });
    };
});
module.exports = {
    component: component,
    "_prepareImportEstimationProjectFormData": $foreign["_prepareImportEstimationProjectFormData"],
    "_readFileContentsAsJSON": $foreign["_readFileContentsAsJSON"]
};
