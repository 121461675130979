// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var incomeItemDetailsStyles = Box.boxStyle([ Box.GridColumn.value, new Box.Gap(TacoConstants.M.value), Box.AlignCenter.value, Box.Style.create(React_Basic_Emotion.css()({
    gridTemplateColumns: React_Basic_Emotion.str("1fr 1fr")
})), new Box.PaddingBottom(TacoConstants.M.value) ]);
var incomeItemControlsStyles = Box.boxStyle([ Box.FlexRow.value, Box.Style.create(React_Basic_Emotion.css()({
    alignItems: React_Basic_Emotion.str("flex-end")
})) ]);
module.exports = {
    incomeItemControlsStyles: incomeItemControlsStyles,
    incomeItemDetailsStyles: incomeItemDetailsStyles
};
