// Generated by purs version 0.14.5
"use strict";
var Control_Category = require("../Control.Category/index.js");
var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Record_Builder = require("../Record.Builder/index.js");
var Type_Data_Row = require("../Type.Data.Row/index.js");
var Type_Data_RowList = require("../Type.Data.RowList/index.js");
var Type_Proxy = require("../Type.Proxy/index.js");
var reflectRecordProxyBuilderNil = {
    reflectRecordProxyBuilder: function (v) {
        return Control_Category.identity(Record_Builder.categoryBuilder);
    }
};
var reflectRecordProxyBuilder = function (dict) {
    return dict.reflectRecordProxyBuilder;
};
var reflectRecordProxyInst = function (dictRowToList) {
    return function (dictReflectRecordProxyBuilder) {
        return {
            reflectRecordProxy: (function () {
                var builder = reflectRecordProxyBuilder(dictReflectRecordProxyBuilder)(Type_Data_RowList.RLProxy.value);
                return Record_Builder.build(builder)({});
            })()
        };
    };
};
var reflectRecordProxy = function (dict) {
    return dict.reflectRecordProxy;
};
var reflectProxySProxy = {
    reflectProxy: Data_Symbol.SProxy.value
};
var reflectProxyRProxy = {
    reflectProxy: Type_Data_Row.RProxy.value
};
var reflectProxyRLProxy = {
    reflectProxy: Type_Data_RowList.RLProxy.value
};
var reflectProxyProxy = {
    reflectProxy: Type_Proxy["Proxy"].value
};
var reflectProxy = function (dict) {
    return dict.reflectProxy;
};
var reflectRecordProxyBuilderConsRoute = function (dictReflectRecordProxyBuilder) {
    return function (dictLacks) {
        return function (dictCons) {
            return function (dictReflectProxy) {
                return function (dictIsSymbol) {
                    return {
                        reflectRecordProxyBuilder: function (v) {
                            var rest = reflectRecordProxyBuilder(dictReflectRecordProxyBuilder)(Type_Data_RowList.RLProxy.value);
                            var first = Record_Builder.insert()()(dictIsSymbol)(Data_Symbol.SProxy.value)(reflectProxy(dictReflectProxy));
                            return Control_Semigroupoid.compose(Record_Builder.semigroupoidBuilder)(first)(rest);
                        }
                    };
                };
            };
        };
    };
};
module.exports = {
    reflectProxy: reflectProxy,
    reflectRecordProxy: reflectRecordProxy,
    reflectRecordProxyBuilder: reflectRecordProxyBuilder,
    reflectRecordProxyInst: reflectRecordProxyInst,
    reflectRecordProxyBuilderNil: reflectRecordProxyBuilderNil,
    reflectRecordProxyBuilderConsRoute: reflectRecordProxyBuilderConsRoute,
    reflectProxyProxy: reflectProxyProxy,
    reflectProxySProxy: reflectProxySProxy,
    reflectProxyRProxy: reflectProxyRProxy,
    reflectProxyRLProxy: reflectProxyRLProxy
};
