import { Button, Header, IconAddPlusV1, Input, Modal } from "@tocoman/ui";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { SelectOrganization } from "./SelectOrganization";
import { NewUserLine } from "./NewUserLine";
import {
  useCreateUserInSuperAdmin,
  UserData,
  useUpdateUserInSuperAdmin,
} from "../Tabs/useSuperAdminUsers";
import { DateTime } from "luxon";
import { useGetOrganizations } from "../Tabs/useSuperAdminOrganizations";

type UserInformationProps = {
  editUser: UserData;
  editUserOpen: boolean;
  setEditUser: (data: UserData | null) => void;
  newRowData: (newUsers: { email: string; superAdmin: boolean }[]) => void;
};

export type AllUserData = {
  newUsers?: {
    email: string;
    superAdmin: boolean;
  }[];
  expiresAt: Date | null;
  userOrganizations: string[];
};

type UserFormOrg = {
  name: string;
  selected: boolean;
};

export const UserInformation = ({
  editUserOpen,
  editUser,
  setEditUser,
  newRowData,
}: UserInformationProps) => {
  const { t } = useTranslation("superAdmin");
  const orgData = useGetOrganizations();
  const organizations = useMemo<UserFormOrg[]>(() => {
    return (orgData ?? []).map((org) => ({
      name: org.name,
      selected: editUser.organizations?.includes(org.name) ?? false,
    }));
  }, [orgData, editUser]);

  const isNew = editUser.email === "";

  const methods = useForm<AllUserData>({
    defaultValues: {
      newUsers: [{ email: "", superAdmin: false }],
    },
    mode: "onBlur",
  });

  const updateUser = useUpdateUserInSuperAdmin();
  const addUser = useCreateUserInSuperAdmin();

  const { handleSubmit, reset, watch, control, register } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "newUsers",
  });

  const handleAddUser = async (
    user: { email: string; superAdmin: boolean },
    newExpiresAt: Date | null,
    userOrgs: string[]
  ) => {
    const addData: UserData = {
      id: "",
      email: user.email.toLowerCase(),
      currentOrganization: userOrgs[0],
      organizations: userOrgs,
      createdAt: new Date(),
      isSuperAdmin: user.superAdmin,
      hasSSOLogin: false,
      expiresAt: newExpiresAt ? newExpiresAt : null,
    };
    await addUser.mutateAsync(addData);
  };

  const handleEditUser = async (
    userData: UserData,
    newUserData: AllUserData,
    userOrgs: string[]
  ) => {
    const updateData: UserData = {
      id: userData.id,
      email: userData.email,
      currentOrganization: userOrgs[0],
      createdAt: userData.createdAt,
      organizations: userOrgs,
      expiresAt: newUserData.expiresAt ? newUserData.expiresAt : null,
      isSuperAdmin: userData.isSuperAdmin,
      hasSSOLogin: userData.hasSSOLogin,
    };
    await updateUser.mutateAsync(updateData);
  };

  const onSubmit = (data: AllUserData) => {
    isNew
      ? data.newUsers &&
        data.newUsers.map((user) => {
          handleAddUser(user, data.expiresAt, data.userOrganizations).then(
            closeUserInformation
          );
        })
      : handleEditUser(editUser, data, data.userOrganizations).then(
          closeUserInformation
        );
    if (data.newUsers) {
      newRowData(data.newUsers);
    }
  };

  const closeUserInformation = () => {
    reset();
    setEditUser(null);
  };

  const dt = DateTime.fromISO(
    editUser.expiresAt ? editUser.expiresAt.toString() : "00.00.0000"
  );

  const editUserTitle = isNew
    ? t`addUsers`
    : t`editUserTitle` + " " + editUser.email;
  const editUserChildren = (
    <div>
      <FormProvider {...methods}>
        {!isNew && <Input disabled className={"mb-4"} value={editUser.email} />}
        <Header titleSize="small" title={t`organizations`} />
        <div className={"flex gap-5 mb-5"}>
          <Controller
            render={({ field: { onChange, value } }) => (
              <SelectOrganization
                organizations={organizations.map((item) => item.name)}
                value={value}
                onChange={onChange}
              />
            )}
            control={control}
            name={"userOrganizations"}
          />
        </div>
        {isNew && (
          <div>
            <Header titleSize="medium" title={t`users`} />
            {fields.map((formField, index) => {
              const watchEmail = watch(`newUsers.${index}.email`);
              return (
                <div key={formField.id}>
                  <NewUserLine
                    control={control}
                    index={index}
                    remove={remove}
                    watchEmail={watchEmail}
                    t={t}
                  />
                </div>
              );
            })}
            <Button
              testId="addLineIcon"
              variant="primary"
              color="normal"
              size="small"
              onClick={() => append({ email: "", superAdmin: false })}
              icon={IconAddPlusV1}
            ></Button>
          </div>
        )}
        <Header titleSize="small" title={t`expiresAt`} />
        <Input
          type="date"
          {...register("expiresAt", {
            valueAsDate: true,
            value: (dt.toISODate() as unknown) as Date,
          })}
        />
      </FormProvider>
    </div>
  );
  const editUserActions = (
    <>
      <div className={"flex justify-between w-full"}>
        <div>
          <Button
            color="normal"
            variant="text"
            size="large"
            onClick={() => closeUserInformation()}
          >
            {t`cancel`}
          </Button>
        </div>
        <div className={"flex justify-end"}>
          <Button color="normal" size="large" onClick={handleSubmit(onSubmit)}>
            {isNew ? t`addUsers` : t`save`}
          </Button>
        </div>
      </div>
    </>
  );

  useEffect(() => {
    if (editUser) {
      reset({
        userOrganizations: editUser.organizations,
      });
    }
  }, [editUser, reset]);

  useEffect(() => {
    if (fields.length === 0) {
      append({ email: "", superAdmin: false });
    }
  }, [append, fields.length]);

  return (
    <Modal
      isOpen={editUserOpen}
      closeModal={closeUserInformation}
      title={editUserTitle}
      actions={editUserActions}
      width={420}
    >
      {editUserChildren}
    </Modal>
  );
};
