import { ReactNode } from "react";
import { NavBar } from "../containers/NavBar/NavBar";

type Props = {
  children: ReactNode;
};

export const VerticalViewContainer = ({ children }: Props) => {
  return (
    <>
      <NavBar />
      <div className={"container mx-auto p-4 flex-1 flex flex-col min-h-0"}>
        {children}
      </div>
    </>
  );
};
