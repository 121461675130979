// Generated by purs version 0.14.5
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_ClassificationGroup = require("../Types.ClassificationGroup/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_JSDateString = require("../Types.JSDateString/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Project = require("../Types.Project/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var EstimationResourceWithComponents = function (x) {
    return x;
};
var EstimationComponentWithResources = function (x) {
    return x;
};
var EditMode = (function () {
    function EditMode() {

    };
    EditMode.value = new EditMode();
    return EditMode;
})();
var ReferenceMode = (function () {
    function ReferenceMode() {

    };
    ReferenceMode.value = new ReferenceMode();
    return ReferenceMode;
})();
var unECWR = function (v) {
    return v;
};
var tofuEstimationComponentWithResources = TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "checkboxState";
    }
})(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "component";
    }
})(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "amount";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "checked";
    }
})(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "classCode";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "code";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "componentType";
    }
})(TofuJSON.readInt)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "costGroupCode";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "costGroupDescription";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "description";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "flagged";
    }
})(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "group";
    }
})(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "memo";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "subProjectCode";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "subProjectId";
    }
})(TofuJSON.readNullable(Types_Project.tofuJSONSubprojectId))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "subProjectName";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "unit";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "amount";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "checked";
    }
})(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "classCode";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "code";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "componentType";
    }
})(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "costGroupCode";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "costGroupDescription";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "description";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "flagged";
    }
})(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "group";
    }
})(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "memo";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "subProjectCode";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "subProjectId";
    }
})(TofuJSON.readNullable(Types_Project.tofuJSONSubprojectId))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "subProjectName";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "unit";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "hourlyRate";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "hours";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "isSplitComponentWithMissingResources";
    }
})(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "locations";
    }
})(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "amount";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "amountSource";
    }
})(TofuJSON.readInt)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "createTime";
    }
})(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "description";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "elementSpecId";
    }
})(TofuJSON.readNullable(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "estimationComponentId";
    }
})(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "group";
    }
})(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "locationCode";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "sourceApplication";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "userName";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "amount";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "amountSource";
    }
})(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "createTime";
    }
})(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "description";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "elementSpecId";
    }
})(TofuJSON.readNullable(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "estimationComponentId";
    }
})(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "group";
    }
})(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "locationCode";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "sourceApplication";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "userName";
    }
})(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "projectOfOrigin";
    }
})(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "applicationMode";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "code";
    }
})(Types_Project.tofuJSONProjectCode)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "createdDate";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "currency";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "isReportingProject";
    }
})(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "name";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "programType";
    }
})(TofuJSON.readInt)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "projectGroup";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "state";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "subprojects";
    }
})(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "code";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "countInMeasurementTotal";
    }
})(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "name";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "projectId";
    }
})(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "code";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "countInMeasurementTotal";
    }
})(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Project.tofuJSONSubprojectId)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "name";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "projectId";
    }
})(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "version";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "applicationMode";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "code";
    }
})(Types_Project.tofuJSONProjectCode)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "createdDate";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "currency";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "isReportingProject";
    }
})(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "name";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "programType";
    }
})(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "projectGroup";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "state";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "subprojects";
    }
})(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "code";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "countInMeasurementTotal";
    }
})(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "name";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "projectId";
    }
})(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "code";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "countInMeasurementTotal";
    }
})(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Project.tofuJSONSubprojectId)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "name";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "projectId";
    }
})(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "version";
    }
})(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "resources";
    }
})(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "changeDate";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "contract";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "currencyId";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "discountGroupCode";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "discountPercentage";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "eanCode";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "estimationComponentId";
    }
})(TofuJSON.readNullable(Types_Component.tofuJSONComponentId))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "group";
    }
})(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "index";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "isCostClassPrice";
    }
})(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "multiplier";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "name";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "orgResourcePriceId";
    }
})(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "orgResourcePriceListId";
    }
})(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "pricePerUnit";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "priceWithDiscount";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "projectId";
    }
})(TofuJSON.readNullable(Types_Project.tofuJSONProjectId))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "resourceClassificationGroup";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "sortingNumber";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "taxPercentage";
    }
})(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "unit";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "unitConsumptionRate";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "usagesCount";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "validDate";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "vendor";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "vendorBatchSize";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "vendorCode";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "vendorUnit";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "wastePercentage";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "changeDate";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "contract";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "currencyId";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "discountGroupCode";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "discountPercentage";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "eanCode";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "estimationComponentId";
    }
})(TofuJSON.readNullable(Types_Component.tofuJSONComponentId))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "group";
    }
})(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "index";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "isCostClassPrice";
    }
})(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "multiplier";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "name";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "orgResourcePriceId";
    }
})(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "orgResourcePriceListId";
    }
})(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "pricePerUnit";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "priceWithDiscount";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "projectId";
    }
})(TofuJSON.readNullable(Types_Project.tofuJSONProjectId))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "resourceClassificationGroup";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "sortingNumber";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "taxPercentage";
    }
})(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "unit";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "unitConsumptionRate";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "usagesCount";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "validDate";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "vendor";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "vendorBatchSize";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "vendorCode";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "vendorUnit";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "wastePercentage";
    }
})(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "totalPrice";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "totalPriceByCostClasses";
    }
})(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "hours";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "totalPrice";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "totalPriceWithoutSocialExpenses";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "hours";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "totalPrice";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "totalPriceWithoutSocialExpenses";
    }
})(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "totalPriceWithoutSocialExpenses";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "checkboxState";
    }
})(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "component";
    }
})(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "amount";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "checked";
    }
})(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "classCode";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "code";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "componentType";
    }
})(TofuJSON.readInt)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "costGroupCode";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "costGroupDescription";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "description";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "flagged";
    }
})(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "group";
    }
})(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "memo";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "subProjectCode";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "subProjectId";
    }
})(TofuJSON.readNullable(Types_Project.tofuJSONSubprojectId))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "subProjectName";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "unit";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "amount";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "checked";
    }
})(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "classCode";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "code";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "componentType";
    }
})(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "costGroupCode";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "costGroupDescription";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "description";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "flagged";
    }
})(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "group";
    }
})(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "memo";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "subProjectCode";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "subProjectId";
    }
})(TofuJSON.readNullable(Types_Project.tofuJSONSubprojectId))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "subProjectName";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "unit";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "hourlyRate";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "hours";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "isSplitComponentWithMissingResources";
    }
})(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "locations";
    }
})(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "amount";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "amountSource";
    }
})(TofuJSON.readInt)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "createTime";
    }
})(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "description";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "elementSpecId";
    }
})(TofuJSON.readNullable(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "estimationComponentId";
    }
})(Types_Component.tofuJSONComponentId)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "group";
    }
})(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "locationCode";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "sourceApplication";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "userName";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "amount";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "amountSource";
    }
})(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "createTime";
    }
})(TofuJSON.readNullable(Types_JSDateString.tofuJSONJSDateString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "description";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "elementSpecId";
    }
})(TofuJSON.readNullable(Types_Newtypes.tofuJSONElementSpecId))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "estimationComponentId";
    }
})(Types_Component.tofuJSONComponentId)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "group";
    }
})(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Component.tofuJSONEstimationLocationId)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "locationCode";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "sourceApplication";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "userName";
    }
})(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "projectOfOrigin";
    }
})(TofuJSON.readMaybe(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "applicationMode";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "code";
    }
})(Types_Project.tofuJSONProjectCode)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "createdDate";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "currency";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "isReportingProject";
    }
})(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "name";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "programType";
    }
})(TofuJSON.readInt)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "projectGroup";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "state";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "subprojects";
    }
})(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "code";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "countInMeasurementTotal";
    }
})(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "name";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "projectId";
    }
})(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "code";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "countInMeasurementTotal";
    }
})(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Project.tofuJSONSubprojectId)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "name";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "projectId";
    }
})(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "version";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "applicationMode";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "code";
    }
})(Types_Project.tofuJSONProjectCode)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "createdDate";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "currency";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Project.tofuJSONProjectId)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "isReportingProject";
    }
})(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "name";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "programType";
    }
})(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "projectGroup";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "state";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "subprojects";
    }
})(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "code";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "countInMeasurementTotal";
    }
})(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Project.tofuJSONSubprojectId)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "name";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "projectId";
    }
})(Types_Project.tofuJSONProjectId)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "code";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "countInMeasurementTotal";
    }
})(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Project.tofuJSONSubprojectId)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "name";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "projectId";
    }
})(Types_Project.tofuJSONProjectId)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "version";
    }
})(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "resources";
    }
})(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "changeDate";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "contract";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "currencyId";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "discountGroupCode";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "discountPercentage";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "eanCode";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "estimationComponentId";
    }
})(TofuJSON.readNullable(Types_Component.tofuJSONComponentId))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "group";
    }
})(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "index";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "isCostClassPrice";
    }
})(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "multiplier";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "name";
    }
})(TofuJSON.readString)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "orgResourcePriceId";
    }
})(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "orgResourcePriceListId";
    }
})(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "pricePerUnit";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "priceWithDiscount";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "projectId";
    }
})(TofuJSON.readNullable(Types_Project.tofuJSONProjectId))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "resourceClassificationGroup";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "sortingNumber";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "taxPercentage";
    }
})(Types_Unit.tofuJSONTaxrate)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "unit";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "unitConsumptionRate";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "usagesCount";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "validDate";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "vendor";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "vendorBatchSize";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "vendorCode";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "vendorUnit";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "wastePercentage";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "changeDate";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "contract";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(TofuJSON.readNullable(Types_Newtypes.tofuJSONCostClassCode))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "currencyId";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "discountGroupCode";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "discountPercentage";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "eanCode";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "estimationComponentId";
    }
})(TofuJSON.readNullable(Types_Component.tofuJSONComponentId))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "group";
    }
})(TofuJSON.readNullable(Types_ClassificationGroup.tofuJSONClassificationGroupCode))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Component.tofuJSONEstimationResourceId)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "index";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "isCostClassPrice";
    }
})(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "multiplier";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "name";
    }
})(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "orgResourcePriceId";
    }
})(TofuJSON.readNullable(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "orgResourcePriceListId";
    }
})(TofuJSON.readNullable(Types_Component.tofuJSONOrgEstimationResourceId))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "pricePerUnit";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "priceWithDiscount";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "projectId";
    }
})(TofuJSON.readNullable(Types_Project.tofuJSONProjectId))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "resourceClassificationGroup";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "sortingNumber";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "taxPercentage";
    }
})(Types_Unit.tofuJSONTaxrate)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "unit";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "unitConsumptionRate";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "usagesCount";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "validDate";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "vendor";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "vendorBatchSize";
    }
})(TofuJSON.readNullable(TofuJSON.readNumber))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "vendorCode";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "vendorUnit";
    }
})(TofuJSON.readNullable(TofuJSON.readString))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "wastePercentage";
    }
})(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "totalPrice";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "totalPriceByCostClasses";
    }
})(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "hours";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "totalPrice";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "totalPriceWithoutSocialExpenses";
    }
})(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "hours";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "totalPrice";
    }
})(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "totalPriceWithoutSocialExpenses";
    }
})(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "totalPriceWithoutSocialExpenses";
    }
})(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()());
var newtypeResourceWithEstimationComponents = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeEstimationComponentWithResources = {
    Coercible0: function () {
        return undefined;
    }
};
var eqResourceWithEstimationComponents = Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "totalPriceByCostClasses";
    }
})(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "totalPriceWithoutSocialExpenses";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "totalPrice";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "hours";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(Types_Newtypes.eqCostClassCode)))))()({
    reflectSymbol: function () {
        return "resource";
    }
})(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "wastePercentage";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "vendorUnit";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "vendorCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "vendorBatchSize";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "vendor";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "validDate";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "usagesCount";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "unitConsumptionRate";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "unit";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "taxPercentage";
    }
})(Types_Unit.eqTaxrate))()({
    reflectSymbol: function () {
        return "sortingNumber";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "resourceClassificationGroup";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "projectId";
    }
})(Data_Nullable.eqNullable(Types_Project.eqProjectId)))()({
    reflectSymbol: function () {
        return "priceWithDiscount";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "pricePerUnit";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "orgResourcePriceListId";
    }
})(Data_Nullable.eqNullable(Types_Component.eqOrgEstimationResourceId)))()({
    reflectSymbol: function () {
        return "orgResourcePriceId";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqInt)))()({
    reflectSymbol: function () {
        return "name";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "multiplier";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "isCostClassPrice";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "index";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Component.eqEstimationResourceId))()({
    reflectSymbol: function () {
        return "group";
    }
})(Data_Nullable.eqNullable(Types_ClassificationGroup.eqClassificationGroupCode)))()({
    reflectSymbol: function () {
        return "estimationComponentId";
    }
})(Data_Nullable.eqNullable(Types_Component.eqComponentId)))()({
    reflectSymbol: function () {
        return "eanCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "discountPercentage";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "discountGroupCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "currencyId";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(Data_Nullable.eqNullable(Types_Newtypes.eqCostClassCode)))()({
    reflectSymbol: function () {
        return "contract";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "changeDate";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))))()({
    reflectSymbol: function () {
        return "projectOfOrigin";
    }
})(Data_Maybe.eqMaybe(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "version";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "subprojects";
    }
})(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "projectId";
    }
})(Types_Project.eqProjectId))()({
    reflectSymbol: function () {
        return "name";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Project.eqSubprojectId))()({
    reflectSymbol: function () {
        return "countInMeasurementTotal";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "code";
    }
})(Data_Eq.eqString)))))()({
    reflectSymbol: function () {
        return "state";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "projectGroup";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "programType";
    }
})(Data_Eq.eqInt))()({
    reflectSymbol: function () {
        return "name";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "isReportingProject";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Project.eqProjectId))()({
    reflectSymbol: function () {
        return "currency";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "createdDate";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "code";
    }
})(Types_Project.eqProjectCode))()({
    reflectSymbol: function () {
        return "applicationMode";
    }
})(Data_Eq.eqString)))))()({
    reflectSymbol: function () {
        return "hours";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "hourlyRate";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "components";
    }
})(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "unit";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "subProjectName";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "subProjectId";
    }
})(Data_Nullable.eqNullable(Types_Project.eqSubprojectId)))()({
    reflectSymbol: function () {
        return "subProjectCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "memo";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Component.eqComponentId))()({
    reflectSymbol: function () {
        return "group";
    }
})(Data_Nullable.eqNullable(Types_ClassificationGroup.eqClassificationGroupCode)))()({
    reflectSymbol: function () {
        return "flagged";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "description";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "costGroupDescription";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "costGroupCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "componentType";
    }
})(Data_Eq.eqInt))()({
    reflectSymbol: function () {
        return "code";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "classCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "checked";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "amount";
    }
})(Data_Eq.eqNumber)))))()({
    reflectSymbol: function () {
        return "checkboxState";
    }
})(Data_Eq.eqBoolean));
var eqEstimationComponentWithResources = Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "totalPriceWithoutSocialExpenses";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "totalPriceByCostClasses";
    }
})(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "totalPriceWithoutSocialExpenses";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "totalPrice";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "hours";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(Types_Newtypes.eqCostClassCode)))))()({
    reflectSymbol: function () {
        return "totalPrice";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "resources";
    }
})(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "wastePercentage";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "vendorUnit";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "vendorCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "vendorBatchSize";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "vendor";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "validDate";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "usagesCount";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "unitConsumptionRate";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "unit";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "taxPercentage";
    }
})(Types_Unit.eqTaxrate))()({
    reflectSymbol: function () {
        return "sortingNumber";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "resourceClassificationGroup";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "projectId";
    }
})(Data_Nullable.eqNullable(Types_Project.eqProjectId)))()({
    reflectSymbol: function () {
        return "priceWithDiscount";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "pricePerUnit";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "orgResourcePriceListId";
    }
})(Data_Nullable.eqNullable(Types_Component.eqOrgEstimationResourceId)))()({
    reflectSymbol: function () {
        return "orgResourcePriceId";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqInt)))()({
    reflectSymbol: function () {
        return "name";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "multiplier";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "isCostClassPrice";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "index";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Component.eqEstimationResourceId))()({
    reflectSymbol: function () {
        return "group";
    }
})(Data_Nullable.eqNullable(Types_ClassificationGroup.eqClassificationGroupCode)))()({
    reflectSymbol: function () {
        return "estimationComponentId";
    }
})(Data_Nullable.eqNullable(Types_Component.eqComponentId)))()({
    reflectSymbol: function () {
        return "eanCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "discountPercentage";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "discountGroupCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "currencyId";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "costClassCode";
    }
})(Data_Nullable.eqNullable(Types_Newtypes.eqCostClassCode)))()({
    reflectSymbol: function () {
        return "contract";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "changeDate";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString))))))()({
    reflectSymbol: function () {
        return "projectOfOrigin";
    }
})(Data_Maybe.eqMaybe(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "version";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "subprojects";
    }
})(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "projectId";
    }
})(Types_Project.eqProjectId))()({
    reflectSymbol: function () {
        return "name";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Project.eqSubprojectId))()({
    reflectSymbol: function () {
        return "countInMeasurementTotal";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "code";
    }
})(Data_Eq.eqString)))))()({
    reflectSymbol: function () {
        return "state";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "projectGroup";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "programType";
    }
})(Data_Eq.eqInt))()({
    reflectSymbol: function () {
        return "name";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "isReportingProject";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Project.eqProjectId))()({
    reflectSymbol: function () {
        return "currency";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "createdDate";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "code";
    }
})(Types_Project.eqProjectCode))()({
    reflectSymbol: function () {
        return "applicationMode";
    }
})(Data_Eq.eqString)))))()({
    reflectSymbol: function () {
        return "locations";
    }
})(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "userName";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "sourceApplication";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "locationCode";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Component.eqEstimationLocationId))()({
    reflectSymbol: function () {
        return "group";
    }
})(Data_Nullable.eqNullable(Types_ClassificationGroup.eqClassificationGroupCode)))()({
    reflectSymbol: function () {
        return "estimationComponentId";
    }
})(Types_Component.eqComponentId))()({
    reflectSymbol: function () {
        return "elementSpecId";
    }
})(Data_Nullable.eqNullable(Types_Newtypes.eqElementSpecId)))()({
    reflectSymbol: function () {
        return "description";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "createTime";
    }
})(Data_Nullable.eqNullable(Types_JSDateString.eqJSDateString)))()({
    reflectSymbol: function () {
        return "amountSource";
    }
})(Data_Eq.eqInt))()({
    reflectSymbol: function () {
        return "amount";
    }
})(Data_Eq.eqNumber)))))()({
    reflectSymbol: function () {
        return "isSplitComponentWithMissingResources";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "hours";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "hourlyRate";
    }
})(Data_Eq.eqNumber))()({
    reflectSymbol: function () {
        return "component";
    }
})(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
    reflectSymbol: function () {
        return "unit";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "subProjectName";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "subProjectId";
    }
})(Data_Nullable.eqNullable(Types_Project.eqSubprojectId)))()({
    reflectSymbol: function () {
        return "subProjectCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "memo";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "id";
    }
})(Types_Component.eqComponentId))()({
    reflectSymbol: function () {
        return "group";
    }
})(Data_Nullable.eqNullable(Types_ClassificationGroup.eqClassificationGroupCode)))()({
    reflectSymbol: function () {
        return "flagged";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "description";
    }
})(Data_Eq.eqString))()({
    reflectSymbol: function () {
        return "costGroupDescription";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "costGroupCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "componentType";
    }
})(Data_Eq.eqInt))()({
    reflectSymbol: function () {
        return "code";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "classCode";
    }
})(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
    reflectSymbol: function () {
        return "checked";
    }
})(Data_Eq.eqBoolean))()({
    reflectSymbol: function () {
        return "amount";
    }
})(Data_Eq.eqNumber))))()({
    reflectSymbol: function () {
        return "checkboxState";
    }
})(Data_Eq.eqBoolean));
var eqECViewMode = {
    eq: function (x) {
        return function (y) {
            if (x instanceof EditMode && y instanceof EditMode) {
                return true;
            };
            if (x instanceof ReferenceMode && y instanceof ReferenceMode) {
                return true;
            };
            return false;
        };
    }
};
var ecViewModeToString = function (v) {
    if (v instanceof EditMode) {
        return "EditMode";
    };
    if (v instanceof ReferenceMode) {
        return "ReferenceMode";
    };
    throw new Error("Failed pattern match at Types.CostEstimation (line 19, column 1 - line 19, column 43): " + [ v.constructor.name ]);
};
var showECViewMode = {
    show: ecViewModeToString
};
module.exports = {
    EditMode: EditMode,
    ReferenceMode: ReferenceMode,
    ecViewModeToString: ecViewModeToString,
    EstimationComponentWithResources: EstimationComponentWithResources,
    unECWR: unECWR,
    EstimationResourceWithComponents: EstimationResourceWithComponents,
    eqECViewMode: eqECViewMode,
    showECViewMode: showECViewMode,
    newtypeEstimationComponentWithResources: newtypeEstimationComponentWithResources,
    eqEstimationComponentWithResources: eqEstimationComponentWithResources,
    tofuEstimationComponentWithResources: tofuEstimationComponentWithResources,
    newtypeResourceWithEstimationComponents: newtypeResourceWithEstimationComponents,
    eqResourceWithEstimationComponents: eqResourceWithEstimationComponents
};
