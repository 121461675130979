import { Button, Modal } from "@tocoman/ui";
import { useTranslation } from "react-i18next";
import { StringCombobox } from "../../../components/StringCombobox";
import { useCallback, useMemo, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { AdjustPricingTab } from "./AdjustPricing";
import { ConvertToTopic } from "./ConvertToTopic";
import {
  LicenseFeature,
  useLicenseFeatureEnabled,
} from "../../../LicenseFeatures/useLicenseFeature";
import { EditGroup } from "./EditGroup";
import { EditQuantity } from "./EditQuantity";
import { EditCostGroup } from "./EditCostGroup";
import { DeleteComponentProperties } from "./DeleteComponentProperties";
import { ChangeSubproject } from "./ChangeSubproject";
import { AddComponentProperties } from "./AddComponentProperties";
import { MassPricing } from "./MassPricing";

type EditMultipleComponentsModalProps = {
  projectId: number;
  selectedComponents: SelectedComponent[];
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
};

export type SelectedComponent = {
  componentId: number;
  totalPrice: number;
};

export function EditMultipleComponentsModal({
  projectId,
  selectedComponents,
  onClose = () => ({}),
  onSave,
  isOpen = true,
}: EditMultipleComponentsModalProps) {
  const { t } = useTranslation("estimation", {
    keyPrefix: "componentView.editMultipleComponentsModal",
  });

  const selectedComponentIds = selectedComponents.map(
    (component) => component.componentId
  );

  const componentGroupsEnabled = useLicenseFeatureEnabled(
    LicenseFeature.COMPONENT_GROUPS
  );
  const subprojectsEnabled = useLicenseFeatureEnabled(
    LicenseFeature.SUBPROJECTS
  );

  const methods = useForm<{ selectedAction: string }>({
    mode: "onBlur",
  });
  const { control, watch, reset } = methods;

  const actions = [
    t`actions.adjustPricing`,
    t`actions.editCostGroup`,
    t`actions.editQuantity`,
    t`actions.convertToTopic`,
    t`actions.massPricing`,
    t`actions.delete`,
    t`actions.add`,
  ];

  if (componentGroupsEnabled) {
    actions.push(t`actions.editGroup`);
  }
  if (subprojectsEnabled) {
    actions.push(t`actions.changeSubproject`);
  }

  const watchAction = watch("selectedAction");

  const resetAndClose = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  const defaultModalActions = useMemo(() => {
    return (
      <div className="ml-auto flex justify-between w-full">
        <Button
          variant="text"
          onClick={() => resetAndClose()}
        >{t`cancel`}</Button>
        <Button testId={"project-save-btn"} disabled={true}>{t`save`}</Button>
      </div>
    );
  }, [resetAndClose, t]);
  const [modalActions, setModalActions] = useState(defaultModalActions);

  const childComponentProps = {
    projectId,
    componentIds: selectedComponentIds,
    onSave,
    closeModal: resetAndClose,
    setActionButtons: setModalActions,
  };
  const { componentIds, ...massPricingProps } = childComponentProps;

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeModal={resetAndClose}
        title={t`title`}
        width={650}
        actions={modalActions}
      >
        <FormProvider {...methods}>
          <div className={"w-[300px]"}>
            <Controller
              control={control}
              name={"selectedAction"}
              render={({ field: { onChange, value } }) => (
                <StringCombobox
                  label={t`form.selectAction`}
                  items={actions}
                  onValueChange={onChange}
                  initialValue={value}
                />
              )}
            />
          </div>
          <hr className={"text-disabled-dark-grey"} />
          {watchAction === t`actions.adjustPricing` && (
            <AdjustPricingTab {...childComponentProps} />
          )}
          {watchAction === t`actions.editCostGroup` && (
            <EditCostGroup {...childComponentProps} />
          )}
          {watchAction === t`actions.editQuantity` && (
            <EditQuantity {...childComponentProps} />
          )}
          {watchAction === t`actions.convertToTopic` && (
            <ConvertToTopic {...childComponentProps} />
          )}
          {watchAction === t`actions.editGroup` && (
            <EditGroup {...childComponentProps} />
          )}
          {watchAction === t`actions.massPricing` && (
            <MassPricing
              components={selectedComponents}
              {...massPricingProps}
            />
          )}
          {watchAction === t`actions.delete` && (
            <DeleteComponentProperties {...childComponentProps} />
          )}
          {watchAction === t`actions.add` && (
            <AddComponentProperties {...childComponentProps} />
          )}
          {watchAction === t`actions.changeSubproject` && (
            <ChangeSubproject {...childComponentProps} />
          )}
        </FormProvider>
      </Modal>
    </>
  );
}
