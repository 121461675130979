// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Client_WASM = require("../Client.WASM/index.js");
var ClientLogger = require("../ClientLogger/index.js");
var Common = require("../Common/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var CostEstimation_AddComponent = require("../CostEstimation.AddComponent/index.js");
var CostEstimation_AmountSourceSelect = require("../CostEstimation.AmountSourceSelect/index.js");
var CostEstimation_BuildingElementsView_Types = require("../CostEstimation.BuildingElementsView.Types/index.js");
var CostEstimation_Calculations = require("../CostEstimation.Calculations/index.js");
var CostEstimation_Styles = require("../CostEstimation.Styles/index.js");
var CostEstimationTableWrapper = require("../CostEstimationTableWrapper/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Exception = require("../Effect.Exception/index.js");
var ElementDetailsTable_Styles = require("../ElementDetailsTable.Styles/index.js");
var EstimationUtils = require("../EstimationUtils/index.js");
var FeatureHooks = require("../FeatureHooks/index.js");
var Formatters = require("../Formatters/index.js");
var Gap = require("../Gap/index.js");
var IconArrowIndicator45Deg = require("../IconArrowIndicator45Deg/index.js");
var QuantityFormula_Interpreter = require("../QuantityFormula.Interpreter/index.js");
var QuantityFormula_Parser = require("../QuantityFormula.Parser/index.js");
var QuantityFormula_Types = require("../QuantityFormula.Types/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var SocialExpenseIndicator = require("../SocialExpenseIndicator/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoInput_Styles = require("../TacoInput.Styles/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Cells = require("../TacoTable.Cells/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Alert = require("../Types.Alert/index.js");
var Types_ClassificationGroup = require("../Types.ClassificationGroup/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_ComponentsView = require("../Types.ComponentsView/index.js");
var Types_CostEstimation = require("../Types.CostEstimation/index.js");
var Types_Element = require("../Types.Element/index.js");
var Types_Estimation = require("../Types.Estimation/index.js");
var Types_FrontEndRoutes = require("../Types.FrontEndRoutes/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Project = require("../Types.Project/index.js");
var Utils = require("../Utils/index.js");
var toAddReferenceComponentRowData = function (component) {
    return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Nullable.toMaybe(component.code))(function (code) {
        return Control_Applicative.pure(Data_Maybe.applicativeMaybe)({
            code: code,
            description: component.description,
            unitPriceWithUnit: Data_Maybe.Nothing.value,
            componentId: component.id
        });
    });
};
var mkComponentInfoRow = function (columns) {
    return function (props) {
        return function (r) {
            var selected = Data_Eq.eq(Data_Maybe.eqMaybe(Data_Tuple.eqTuple(Types_Newtypes.eqElementSpecId)(Types_Component.eqComponentId)))(props.selectedIds)(new Data_Maybe.Just(new Data_Tuple.Tuple(r.spec.elementSpecId, r.resourcesByComponent.component.id)));
            var dixonElementSpecLinks = (function () {
                if (props.dixonOptions instanceof Data_Maybe.Just) {
                    return Data_Map_Internal.lookup(Types_Newtypes.ordElementSpecId)(r.spec.elementSpecId)(props.dixonOptions.value0.measurement_group_links_by_element_spec);
                };
                if (props.dixonOptions instanceof Data_Maybe.Nothing) {
                    return Data_Maybe.Nothing.value;
                };
                throw new Error("Failed pattern match at CostEstimation.BuildingElementsView.ComponentsTable (line 804, column 29 - line 806, column 25): " + [ props.dixonOptions.constructor.name ]);
            })();
            var checked = Data_Set.member(Types_Newtypes.ordElementSpecId)(r.spec.elementSpecId)(props.checkedSpecs);
            var active_measurement_groups = Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
                return v.active_measurement_groups;
            })(props.dixonOptions);
            var isActiveMgLink = (function () {
                if (active_measurement_groups instanceof Data_Maybe.Just && dixonElementSpecLinks instanceof Data_Maybe.Just) {
                    var matching_mg_ids = Data_Array.intersect(Data_Eq.eqInt)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
                        return v.measurement_group_id;
                    })(dixonElementSpecLinks.value0))(Data_Functor.map(Data_Functor.functorArray)(function (v) {
                        return v.id;
                    })(active_measurement_groups.value0));
                    return Data_Array.length(matching_mg_ids) > 0;
                };
                return false;
            })();
            var className = TacoTable_Cells.classNames([ Data_Monoid.guard(Data_Monoid.monoidString)(selected)("selected"), Data_Monoid.guard(Data_Monoid.monoidString)(isActiveMgLink)("dixon-link") ]);
            var activeDixonElementSpecLinks = (function () {
                if (props.dixonOptions instanceof Data_Maybe.Just && dixonElementSpecLinks instanceof Data_Maybe.Just) {
                    return Data_Maybe.Just.create(Data_Array.filter(function (mgl) {
                        return Data_Array.elem(Data_Eq.eqInt)(mgl.measurement_group_id)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
                            return v.id;
                        })(props.dixonOptions.value0.active_measurement_groups));
                    })(dixonElementSpecLinks.value0));
                };
                return Data_Maybe.Nothing.value;
            })();
            return {
                rowData: {
                    selectedIds: props.selectedIds,
                    r: r,
                    checked: checked,
                    dixonElementSpecLinks: dixonElementSpecLinks,
                    activeDixonElementSpecLinks: activeDixonElementSpecLinks,
                    active_measurement_groups: active_measurement_groups
                },
                rowKey: Data_Show.show(Types_Newtypes.showElementSpecId)(r.spec.elementSpecId),
                onClick: function (v) {
                    return props.setSelectedIds(function (v1) {
                        return new Data_Maybe.Just(new Data_Tuple.Tuple(r.spec.elementSpecId, r.resourcesByComponent.component.id));
                    });
                },
                className: className,
                foldingElement: Data_Maybe.Nothing.value,
                columns: columns
            };
        };
    };
};
var componentInfoTableStyleSet = CostEstimation_Styles.costEstimationTableStyleSet(TacoConstants.componentColors);
var componentInfoTableInst = TacoTable.mkTableStateless(TofuHooks.getNameVoid)("ElementComponentInfoTable");
var componentInfoTableCellHeight = TacoTable_Types.RowSmall.value;
var getComponentInfoColumns = function (props) {
    return function (classColumnEnabled) {
        var currencySymbol = EstimationUtils.getCurrencySymbol(props.projectCurrency);
        var component = (function () {
            var $276 = Data_Newtype.un()(Types_ComponentsView.WasmResourcesByComponent);
            return function ($277) {
                return (function (v) {
                    return v.component;
                })($276((function (v) {
                    return v.resourcesByComponent;
                })($277)));
            };
        })();
        var componentTestId = function (r) {
            return function (id) {
                return "component-" + (Data_Show.show(Types_Component.showComponentId)((component(r)).id) + ("-" + id));
            };
        };
        var _specId = function (v) {
            return v.elementSpecId;
        };
        var usedInOtherElements = function (v) {
            var $123 = v.r.componentReferenceCount > 1;
            if ($123) {
                return React_Basic_DOM_Generated.span_([ IconArrowIndicator45Deg.icon ]);
            };
            return React_Basic_DOM_Generated.span_([  ]);
        };
        var unitPriceLabelCell = function (v) {
            return TacoText.component()()({
                text: currencySymbol + ("/" + Utils.fromNullable("")(v.r.unit)),
                testId: componentTestId(v.r)("unit-price-label")
            });
        };
        var unitPriceCell = function (v) {
            return TacoText.component()()({
                text: Formatters.formatDecimalValue(2)(v.r.unitPrice),
                testId: componentTestId(v.r)("unit-price")
            });
        };
        var totalPrice = function (v) {
            return TacoText.component()()({
                text: Formatters.formatDecimalValue(2)(v.r.totalPrice),
                testId: componentTestId(v.r)("total-price")
            });
        };
        var totalLabel = function (v) {
            return TacoText.component()()({
                text: currencySymbol,
                testId: componentTestId(v.r)("total-label")
            });
        };
        var selectAllCheckbox = TacoCheckbox.component()()({
            className: "select-all-checkbox",
            isChecked: Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Set.isEmpty)(props.checkedSpecs),
            onToggle: new TacoCheckbox.NoEvent((function () {
                var $133 = Data_Set.isEmpty(props.checkedSpecs);
                if ($133) {
                    return props.setCheckedSpecs(function (v) {
                        return Data_Set.fromFoldable(Data_Foldable.foldableArray)(Types_Newtypes.ordElementSpecId)(Data_Functor.map(Data_Functor.functorArray)(_specId)(CostEstimation_BuildingElementsView_Types["_specs"](props.elementData)));
                    });
                };
                return props.setCheckedSpecs(function (v) {
                    return Data_Set.empty;
                });
            })()),
            testId: "select-all-components-checkbox"
        });
        var mkSocialExpenseLabel = function (text) {
            return Gap.gapX(TacoConstants.XS.value)([ Data_Monoid.guard(React_Basic.monoidJSX)(props.showSocialExpensesInCosts)(SocialExpenseIndicator.socialExpenseIndicator), React_Basic_DOM.text(text) ]);
        };
        var mkCopyAndEditAction = function (v) {
            return function (v1) {
                return function (v2) {
                    return function (v3) {
                        return function (v4) {
                            return function (v5) {
                                return function (v6) {
                                    if (v instanceof Data_Maybe.Just && v1 instanceof Data_Maybe.Just) {
                                        return new Actions.EstimationComponentActionRequest({
                                            projectId: props.projectId,
                                            params: new Types_Estimation.CopyComponentAndApplyEditAction({
                                                elementSpecId: v2,
                                                elementId: v1.value0,
                                                editActionParams: {
                                                    componentId: v3,
                                                    editAction: v4(v5)
                                                }
                                            }),
                                            handler: v6
                                        });
                                    };
                                    if (v instanceof Data_Maybe.Nothing) {
                                        return new Actions.AddAlert(Types_Alert.AlertError.value, "Suoritetta ei voida kopioida rakenteelle, koska alakohdetta ei ole m\xe4\xe4ritelty.");
                                    };
                                    if (v1 instanceof Data_Maybe.Nothing) {
                                        return new Actions.AddAlert(Types_Alert.AlertError.value, "Suoritetta ei voida kopioida rakenteelle, koska rakennetta ei ole m\xe4\xe4ritelty.");
                                    };
                                    throw new Error("Failed pattern match at CostEstimation.BuildingElementsView.ComponentsTable (line 523, column 5 - line 523, column 229): " + [ v.constructor.name, v1.constructor.name, v2.constructor.name, v3.constructor.name, v4.constructor.name, v5.constructor.name, v6.constructor.name ]);
                                };
                            };
                        };
                    };
                };
            };
        };
        var mkAction = function (componentId) {
            return function (mkEditAction) {
                return function (value) {
                    return function (handler) {
                        return new Actions.EstimationComponentActionRequest({
                            projectId: props.projectId,
                            params: new Types_Estimation.EditEstimationComponent({
                                componentId: componentId,
                                editAction: mkEditAction(value)
                            }),
                            handler: handler
                        });
                    };
                };
            };
        };
        var formulaValidator = function (formula) {
            var validatorContext = {
                defaultZero: true,
                variables: QuantityFormula_Interpreter.fromArray([  ])
            };
            var internalFormula = QuantityFormula_Parser.convertToInternalFormula(formula);
            var varNamesShortEnough = QuantityFormula_Interpreter.varNamesShorterThan(3)(internalFormula);
            var v1 = QuantityFormula_Interpreter.evalQuantityFormula(validatorContext)(internalFormula);
            if (v1 instanceof Data_Either.Right && (v1.value0 instanceof QuantityFormula_Types.QNum && varNamesShortEnough)) {
                return TacoInput.Valid.value;
            };
            if (v1 instanceof Data_Either.Right && (v1.value0 instanceof QuantityFormula_Types.QNum && !varNamesShortEnough)) {
                return new TacoInput.Invalid("");
            };
            if (v1 instanceof Data_Either.Right) {
                return new TacoInput.Invalid("");
            };
            if (v1 instanceof Data_Either.Left) {
                return new TacoInput.Invalid("");
            };
            throw new Error("Failed pattern match at CostEstimation.BuildingElementsView.ComponentsTable (line 660, column 10 - line 665, column 32): " + [ v1.constructor.name, varNamesShortEnough.constructor.name ]);
        };
        var es = function (r) {
            return Data_Newtype.un()(Types_Element.WasmElementSpec)(r.spec);
        };
        var isWriteProtected = function (r) {
            return (es(r)).amountSource === 1;
        };
        var mkOnFocus = function (row) {
            var justIds = Data_Maybe.Just.create(new Data_Tuple.Tuple((es(row)).elementSpecId, (component(row)).id));
            return Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(Data_Eq.notEq(Data_Maybe.eqMaybe(Data_Tuple.eqTuple(Types_Newtypes.eqElementSpecId)(Types_Component.eqComponentId)))(props.selectedIds)(justIds))(props.setSelectedIds(function (v) {
                return justIds;
            }));
        };
        var formulaCellInput = function (v) {
            return TacoInput.remoteStringField()()({
                value: Data_Newtype.unwrap()(QuantityFormula_Parser.convertToUserFormula(v.r.formula)),
                validator: formulaValidator,
                disabled: isWriteProtected(v.r),
                mkAction: function (newFormula) {
                    return function (handler) {
                        return new Actions.ElementActionRequest({
                            projectId: props.projectId,
                            params: new Types_Element.EditElementComponentFormula({
                                elementId: props.element.elementId,
                                elementSpecId: (es(v.r)).elementSpecId,
                                newFormula: QuantityFormula_Parser.convertToInternalFormula(newFormula)
                            }),
                            handler: handler
                        });
                    };
                },
                onFocus: mkOnFocus(v.r),
                stateKey: Data_Show.show(Types_Component.showComponentId)((function (v1) {
                    return v1.id;
                })(component(v.r))),
                rendering: new TacoInput_Styles.TableCellRendering(componentInfoTableCellHeight),
                testId: componentTestId(v.r)("formula-input")
            });
        };
        var elementUnitPriceLabel = function (v) {
            return TacoText.component()()({
                text: currencySymbol + ("/" + props.element.unit),
                testId: componentTestId(v.r)("element-unit-price-label")
            });
        };
        var elementUnitPrice = function (v) {
            return TacoText.component()()({
                text: Formatters.formatDecimalValue(2)(v.r.elementUnitPrice),
                testId: componentTestId(v.r)("element-unit-price")
            });
        };
        var detailsContextAmountInput = function (v) {
            return Box.box("amountInput")([ Box.FlexRow.value, Box.Width.create(new TacoConstants.CustomSize("100%")), Box.Style.create(React_Basic_Emotion.css()({
                flexWrap: React_Basic_Emotion.str("nowrap")
            })) ])([ (function () {
                if (v.activeDixonElementSpecLinks instanceof Data_Maybe.Just) {
                    var targetTypes = Data_Array.groupBy(Data_Function.on(Data_Eq.eq(Data_Eq.eqString))(function (v1) {
                        return v1.target_field;
                    }))(Data_Array.sortWith(Data_Ord.ordString)(function (v1) {
                        return v1.target_field;
                    })(v.activeDixonElementSpecLinks.value0));
                    var linkTypes = Data_Array.groupBy(Data_Function.on(Data_Eq.eq(Data_Eq.eqString))(function (v1) {
                        return v1.from_type;
                    }))(Data_Array.sortWith(Data_Ord.ordString)(function (v1) {
                        return v1.from_type;
                    })(v.activeDixonElementSpecLinks.value0));
                    return Box.box("linkTypeWrapper")([ Box.PaddingTop.create(new TacoConstants.CustomSize("3px")) ])(Control_Applicative.pure(Control_Applicative.applicativeArray)(TacoText.component()()({
                        wrap: TacoText_Types.NoWrap.value,
                        text: (function () {
                            var v1 = Data_Functor.map(Data_Maybe.functorMaybe)(Data_Functor.map(Data_Functor.functorFn)(function (v5) {
                                return v5.from_type;
                            })(Data_Array_NonEmpty.head))(Data_Array.head(linkTypes));
                            var v2 = Data_Array.length(linkTypes);
                            var v3 = Data_Functor.map(Data_Maybe.functorMaybe)(Data_Functor.map(Data_Functor.functorFn)(function (v5) {
                                return v5.target_field;
                            })(Data_Array_NonEmpty.head))(Data_Array.head(targetTypes));
                            var v4 = Data_Array.length(targetTypes);
                            if (v4 === 0 && v2 === 0) {
                                return "";
                            };
                            if (v4 === 1 && (v3 instanceof Data_Maybe.Just && v3.value0 === "consumption_rate")) {
                                return "";
                            };
                            if (v4 === 1 && (v1 instanceof Data_Maybe.Just && v1.value0 === "Formula")) {
                                return "(\u0192)";
                            };
                            if (v4 === 1 && (v1 instanceof Data_Maybe.Just && v1.value0 === "Area")) {
                                return "(A)";
                            };
                            if (v4 === 1 && (v1 instanceof Data_Maybe.Just && v1.value0 === "Perimeter")) {
                                return "(P)";
                            };
                            if (v4 === 1 && (v1 instanceof Data_Maybe.Just && v1.value0 === "NumVertices")) {
                                return "(N)";
                            };
                            return "(...)";
                        })()
                    })));
                };
                if (v.activeDixonElementSpecLinks instanceof Data_Maybe.Nothing) {
                    return React_Basic.empty;
                };
                throw new Error("Failed pattern match at CostEstimation.BuildingElementsView.ComponentsTable (line 548, column 9 - line 565, column 30): " + [ v.activeDixonElementSpecLinks.constructor.name ]);
            })(), TacoInput.remoteNumberField()()({
                value: v.r.amount,
                disabled: isWriteProtected(v.r),
                mkAction: function (newTotalQuantity) {
                    return function (handler) {
                        return new Actions.ElementActionRequest({
                            projectId: props.projectId,
                            params: new Types_Element.EditElementComponentTotalQuantity({
                                componentId: (component(v.r)).id,
                                elementId: props.element.elementId,
                                elementSpecId: (es(v.r)).elementSpecId,
                                newTotalQuantity: newTotalQuantity
                            }),
                            handler: handler
                        });
                    };
                },
                onFocus: mkOnFocus(v.r),
                precision: 1,
                align: TacoInput.RightAlign.value,
                rendering: new TacoInput_Styles.TableCellRendering(componentInfoTableCellHeight),
                testId: componentTestId(v.r)("details-context-amount-input")
            }) ]);
        };
        var consumptionRateLabelCell = function (v) {
            return TacoText.component()()({
                text: Utils.fromNullable("")(v.r.unit) + ("/" + props.element.unit),
                testId: componentTestId(v.r)("consumption-rate-label"),
                wrap: TacoText_Types.NoWrap.value
            });
        };
        var consumptionRateCellInput = function (v) {
            return Box.box("amountInputCR")([ Box.FlexRow.value, Box.Width.create(new TacoConstants.CustomSize("100%")), Box.Style.create(React_Basic_Emotion.css()({
                flexWrap: React_Basic_Emotion.str("nowrap")
            })) ])([ (function () {
                if (v.activeDixonElementSpecLinks instanceof Data_Maybe.Just) {
                    var targetTypes = Data_Array.groupBy(Data_Function.on(Data_Eq.eq(Data_Eq.eqString))(function (v1) {
                        return v1.target_field;
                    }))(Data_Array.sortWith(Data_Ord.ordString)(function (v1) {
                        return v1.target_field;
                    })(v.activeDixonElementSpecLinks.value0));
                    var linkTypes = Data_Array.groupBy(Data_Function.on(Data_Eq.eq(Data_Eq.eqString))(function (v1) {
                        return v1.from_type;
                    }))(Data_Array.sortWith(Data_Ord.ordString)(function (v1) {
                        return v1.from_type;
                    })(v.activeDixonElementSpecLinks.value0));
                    return Box.box("linkTypeWrapperCR")([ Box.PaddingTop.create(new TacoConstants.CustomSize("3px")) ])(Control_Applicative.pure(Control_Applicative.applicativeArray)(TacoText.component()()({
                        wrap: TacoText_Types.NoWrap.value,
                        text: (function () {
                            var v1 = Data_Functor.map(Data_Maybe.functorMaybe)(Data_Functor.map(Data_Functor.functorFn)(function (v5) {
                                return v5.from_type;
                            })(Data_Array_NonEmpty.head))(Data_Array.head(linkTypes));
                            var v2 = Data_Array.length(linkTypes);
                            var v3 = Data_Functor.map(Data_Maybe.functorMaybe)(Data_Functor.map(Data_Functor.functorFn)(function (v5) {
                                return v5.target_field;
                            })(Data_Array_NonEmpty.head))(Data_Array.head(targetTypes));
                            var v4 = Data_Array.length(targetTypes);
                            if (v4 === 0 && v2 === 0) {
                                return "";
                            };
                            if (v4 === 1 && (v3 instanceof Data_Maybe.Just && v3.value0 === "quantity")) {
                                return "";
                            };
                            if (v4 === 1 && (v1 instanceof Data_Maybe.Just && v1.value0 === "Formula")) {
                                return "(\u0192)";
                            };
                            if (v4 === 1 && (v1 instanceof Data_Maybe.Just && v1.value0 === "Area")) {
                                return "(A)";
                            };
                            if (v4 === 1 && (v1 instanceof Data_Maybe.Just && v1.value0 === "Perimeter")) {
                                return "(P)";
                            };
                            if (v4 === 1 && (v1 instanceof Data_Maybe.Just && v1.value0 === "NumVertices")) {
                                return "(N)";
                            };
                            return "(...)";
                        })()
                    })));
                };
                if (v.activeDixonElementSpecLinks instanceof Data_Maybe.Nothing) {
                    return React_Basic.empty;
                };
                throw new Error("Failed pattern match at CostEstimation.BuildingElementsView.ComponentsTable (line 713, column 9 - line 730, column 30): " + [ v.activeDixonElementSpecLinks.constructor.name ]);
            })(), TacoInput.remoteNumberField()()({
                value: v.r.consumptionRate,
                disabled: isWriteProtected(v.r),
                mkAction: function (newConsumptionRate) {
                    return function (handler) {
                        return new Actions.ElementActionRequest({
                            projectId: props.projectId,
                            params: new Types_Element.EditElementComponentConsumptionRate({
                                componentId: (component(v.r)).id,
                                elementId: props.element.elementId,
                                elementSpecId: (es(v.r)).elementSpecId,
                                newConsumptionRate: newConsumptionRate
                            }),
                            handler: handler
                        });
                    };
                },
                onFocus: mkOnFocus(v.r),
                precision: 3,
                align: TacoInput.RightAlign.value,
                rendering: new TacoInput_Styles.TableCellRendering(componentInfoTableCellHeight),
                testId: componentTestId(v.r)("consumption-rate-input")
            }) ]);
        };
        var componentUsedInMultipleElements = function (r) {
            return r.componentReferenceCount > 1;
        };
        var descriptionCellInput = function (v) {
            return TacoInput.remoteMultiActionStringField()()({
                value: (component(v.r)).description,
                title: (component(v.r)).description,
                actions: {
                    userActionSelectionNeeded: componentUsedInMultipleElements(v.r),
                    actionModalTitle: "Suorite on k\xe4yt\xf6ss\xe4 my\xf6s muilla rakenteilla",
                    actionModalText: "Suorite on k\xe4yt\xf6ss\xe4 my\xf6s muilla rakenteilla. Muutos voidaan tehd\xe4 joko kaikkiin suoritteeseen linkitettyihin rakenteisiin tai vain t\xe4h\xe4n rakenteeseen.",
                    primaryAction: {
                        actionButtonText: "Muokkaa kaikille rakenteille",
                        mkAction: mkAction((component(v.r)).id)(function (description) {
                            return new Types_Estimation.EditEstimationComponentDescription({
                                description: description
                            });
                        })
                    },
                    secondaryAction: {
                        actionButtonText: "Muokkaa vain t\xe4lle rakenteelle",
                        mkAction: mkCopyAndEditAction(Data_Nullable.toMaybe(props.element.subprojectId))(Data_Nullable.toMaybe((es(v.r)).elementId))((es(v.r)).elementSpecId)((component(v.r)).id)(function (description) {
                            return new Types_Estimation.EditEstimationComponentDescription({
                                description: description
                            });
                        })
                    }
                },
                onFocus: mkOnFocus(v.r),
                stateKey: Data_Show.show(Types_Component.showComponentId)((function (v1) {
                    return v1.id;
                })(component(v.r))),
                rendering: new TacoInput_Styles.TableCellRendering(componentInfoTableCellHeight),
                testId: componentTestId(v.r)("description-input")
            });
        };
        var unitCellInput = function (v) {
            return TacoInput.remoteMultiActionStringField()()({
                value: Utils.fromNullable("")((function (v1) {
                    return v1.unit;
                })(component(v.r))),
                disabled: isWriteProtected(v.r),
                actions: {
                    userActionSelectionNeeded: componentUsedInMultipleElements(v.r),
                    actionModalTitle: "Suorite on k\xe4yt\xf6ss\xe4 my\xf6s muilla rakenteilla",
                    actionModalText: "Suorite on k\xe4yt\xf6ss\xe4 my\xf6s muilla rakenteilla. Muutos voidaan tehd\xe4 joko kaikkiin suoritteeseen linkitettyihin rakenteisiin tai vain t\xe4h\xe4n rakenteeseen.",
                    primaryAction: {
                        actionButtonText: "Muokkaa kaikille rakenteille",
                        mkAction: mkAction((component(v.r)).id)(function (unit) {
                            return new Types_Estimation.EditEstimationComponentUnit({
                                unit: unit
                            });
                        })
                    },
                    secondaryAction: {
                        actionButtonText: "Muokkaa vain t\xe4lle rakenteelle",
                        mkAction: mkCopyAndEditAction(Data_Nullable.toMaybe(props.element.subprojectId))(Data_Nullable.toMaybe((es(v.r)).elementId))((es(v.r)).elementSpecId)((component(v.r)).id)(function (unit) {
                            return new Types_Estimation.EditEstimationComponentUnit({
                                unit: unit
                            });
                        })
                    }
                },
                onFocus: mkOnFocus(v.r),
                stateKey: Data_Show.show(Types_Component.showComponentId)((component(v.r)).id),
                rendering: new TacoInput_Styles.TableCellRendering(componentInfoTableCellHeight),
                testId: componentTestId(v.r)("unit-input")
            });
        };
        var componentGroupCodesCell = function (v) {
            return TacoText.component()()({
                text: (function () {
                    var mCode = CostEstimation_Calculations.extractClassificationGroupCodes(v.r.resourcesByComponent);
                    return Data_Maybe.maybe("")(Data_Newtype.un()(Types_ClassificationGroup.ClassificationGroupCode))(mCode);
                })(),
                testId: componentTestId(v.r)("group-codes")
            });
        };
        var codeCellInput = function (v) {
            var value = Utils.fromNullable("")((function (v1) {
                return v1.code;
            })(component(v.r)));
            var subprojectId = Data_Nullable.toMaybe((component(v.r)).subProjectId);
            return TacoInput.remoteMultiActionStringField()()({
                value: value,
                actions: {
                    userActionSelectionNeeded: componentUsedInMultipleElements(v.r),
                    actionModalTitle: "Suorite on k\xe4yt\xf6ss\xe4 my\xf6s muilla rakenteilla",
                    actionModalText: "Suorite on k\xe4yt\xf6ss\xe4 my\xf6s muilla rakenteilla. Muutos voidaan tehd\xe4 joko kaikkiin suoritteeseen linkitettyihin rakenteisiin tai vain t\xe4h\xe4n rakenteeseen.",
                    primaryAction: {
                        actionButtonText: "Muokkaa kaikille rakenteille",
                        mkAction: mkAction((component(v.r)).id)(function (code) {
                            return new Types_Estimation.EditEstimationComponentCode({
                                code: code,
                                subprojectId: subprojectId
                            });
                        })
                    },
                    secondaryAction: {
                        actionButtonText: "Muokkaa vain t\xe4lle rakenteelle",
                        mkAction: mkCopyAndEditAction(Data_Nullable.toMaybe(props.element.subprojectId))(Data_Nullable.toMaybe((es(v.r)).elementId))((es(v.r)).elementSpecId)((component(v.r)).id)(function (code) {
                            return new Types_Estimation.EditEstimationComponentCode({
                                code: code,
                                subprojectId: subprojectId
                            });
                        })
                    }
                },
                validator: function (s) {
                    var $194 = s !== value && Data_Set.member(Data_Ord.ordString)(s)(props.existingComponentCodesForEdit);
                    if ($194) {
                        return new TacoInput.Invalid("");
                    };
                    return TacoInput.Valid.value;
                },
                onFocus: mkOnFocus(v.r),
                stateKey: Data_Show.show(Types_Component.showComponentId)((component(v.r)).id),
                rendering: new TacoInput_Styles.TableCellRendering(componentInfoTableCellHeight),
                testId: componentTestId(v.r)("code-input")
            });
        };
        var classCell = new TacoTable_Types.JSXCell([  ], function (v) {
            return TacoText.component()()({
                text: Utils.fromNullable("")((function (v1) {
                    return v1.classCode;
                })(component(v.r))),
                testId: componentTestId(v.r)("class")
            });
        });
        var checked = function (r) {
            return Data_Function.flip(Data_Set.member(Types_Newtypes.ordElementSpecId))(props.checkedSpecs)((function (v) {
                return v.elementSpecId;
            })(es(r)));
        };
        var checkbox = function (v) {
            return TacoCheckbox.component()()({
                className: "toggle",
                isChecked: checked(v.r),
                onToggle: new TacoCheckbox.NoEvent(props.toggleCheckedSpec((function (v1) {
                    return v1.elementSpecId;
                })(es(v.r)))),
                labelText: Data_Maybe.Nothing.value,
                testId: "toggle-element-spec-" + Data_Show.show(Types_Newtypes.showElementSpecId)((function (v1) {
                    return v1.elementSpecId;
                })(es(v.r)))
            });
        };
        var canEdit = EstimationUtils.canEditView(props);
        var codeCell = (function () {
            if (canEdit) {
                return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], codeCellInput);
            };
            return new TacoTable_Types.JSXCell([  ], function (v) {
                return TacoText.component()()({
                    text: Utils.fromNullable("")((function (v1) {
                        return v1.code;
                    })(component(v.r))),
                    testId: componentTestId(v.r)("code")
                });
            });
        })();
        var consumptionRateCell = (function () {
            if (canEdit) {
                return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], consumptionRateCellInput);
            };
            return new TacoTable_Types.JSXCell([  ], function (v) {
                return TacoText.component()()({
                    text: Formatters.formatDecimalValue(2)(v.r.consumptionRate),
                    testId: componentTestId(v.r)("consumption-rate")
                });
            });
        })();
        var descriptionCell = (function () {
            if (canEdit) {
                return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], descriptionCellInput);
            };
            return new TacoTable_Types.JSXCell([  ], function (v) {
                return TacoText.component()()({
                    text: (component(v.r)).description,
                    title: (component(v.r)).description,
                    testId: componentTestId(v.r)("description")
                });
            });
        })();
        var detailsContextAmount = (function () {
            if (canEdit) {
                return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], detailsContextAmountInput);
            };
            return new TacoTable_Types.JSXCell([  ], function (v) {
                return TacoText.component()()({
                    text: Formatters.formatDecimalValue(1)(v.r.amount),
                    testId: componentTestId(v.r)("details-context-amount")
                });
            });
        })();
        var formulaCell = (function () {
            if (canEdit) {
                return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], formulaCellInput);
            };
            return new TacoTable_Types.JSXCell([  ], function (v) {
                return TacoText.component()()({
                    text: Data_Newtype.unwrap()(QuantityFormula_Parser.convertToUserFormula(v.r.formula)),
                    testId: componentTestId(v.r)("formula")
                });
            });
        })();
        var unitCell = (function () {
            if (canEdit) {
                return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], unitCellInput);
            };
            return new TacoTable_Types.JSXCell([  ], function (v) {
                return TacoText.component()()({
                    text: Utils.fromNullable("")((function (v1) {
                        return v1.unit;
                    })(component(v.r))),
                    testId: componentTestId(v.r)("unit")
                });
            });
        })();
        var amountSourceCell = (function () {
            if (canEdit) {
                return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (v) {
                    return CostEstimation_AmountSourceSelect.amountSourceSelect({
                        value: (es(v.r)).amountSource,
                        onChange: function (amountSource) {
                            return props.appDispatch(new Actions.ElementActionRequest({
                                projectId: props.projectId,
                                params: new Types_Element.EditElementComponentAmountSource({
                                    elementId: props.element.elementId,
                                    elementSpecId: (es(v.r)).elementSpecId,
                                    amountSource: amountSource
                                }),
                                handler: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit)))
                            }));
                        },
                        testId: componentTestId(v.r)("amount-source-select")
                    });
                });
            };
            return new TacoTable_Types.JSXCell([  ], function (v) {
                return TacoText.component()()({
                    text: Types_Component.amountSourceIntToLabel((es(v.r)).amountSource),
                    testId: componentTestId(v.r)("amount-source")
                });
            });
        })();
        return Data_Semigroup.append(Data_Semigroup.semigroupArray)([ {
            key: "checkbox",
            label: React_Basic_DOM.text(""),
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], checkbox),
            sortProperty: TacoTable_Types.noSortProperty,
            width: new TacoTable_Types.Fixed("2rem"),
            headerJSX: new Data_Maybe.Just(selectAllCheckbox)
        }, {
            key: "usedInOtherElements",
            label: React_Basic_DOM.text(""),
            cell: new TacoTable_Types.JSXCell([  ], usedInOtherElements),
            sortProperty: TacoTable_Types.noSortProperty,
            width: new TacoTable_Types.Fixed("2rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "group",
            label: React_Basic_DOM.text("Ryhm\xe4"),
            cell: new TacoTable_Types.JSXCell([  ], componentGroupCodesCell),
            sortProperty: TacoTable_Types.noSortProperty,
            width: new TacoTable_Types.FlexMinWidth(2, "6rem"),
            headerJSX: Data_Maybe.Nothing.value
        } ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_Monoid.guard(Data_Monoid.monoidArray)(classColumnEnabled)([ {
            key: "class",
            label: React_Basic_DOM.text("Luokka"),
            cell: classCell,
            sortProperty: TacoTable_Types.noSortProperty,
            width: new TacoTable_Types.FlexMinWidth(1, "5rem"),
            headerJSX: Data_Maybe.Nothing.value
        } ]))([ {
            key: "code",
            label: React_Basic_DOM.text("Koodi"),
            cell: codeCell,
            sortProperty: TacoTable_Types.noSortProperty,
            width: new TacoTable_Types.FlexMinWidth(1, "7rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "description",
            label: React_Basic_DOM.text("Selite"),
            cell: descriptionCell,
            sortProperty: TacoTable_Types.noSortProperty,
            width: new TacoTable_Types.FlexMinWidth(10, "16rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "quantity-source",
            label: React_Basic_DOM.text("L\xe4hde"),
            cell: amountSourceCell,
            sortProperty: TacoTable_Types.noSortProperty,
            width: new TacoTable_Types.FlexMinWidth(2, "6rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "formula",
            label: React_Basic_DOM.text("M\xe4\xe4r\xe4n kaava"),
            cell: formulaCell,
            sortProperty: TacoTable_Types.noSortProperty,
            width: new TacoTable_Types.FlexMinWidth(1, "8rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "consumptionRate",
            label: React_Basic_DOM.text("Menekki"),
            cell: consumptionRateCell,
            sortProperty: TacoTable_Types.noSortProperty,
            width: new TacoTable_Types.FlexMinWidth(1, "6rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "consumptionRateLabel",
            label: React_Basic_DOM.text(""),
            cell: new TacoTable_Types.JSXCell([  ], consumptionRateLabelCell),
            sortProperty: TacoTable_Types.noSortProperty,
            width: new TacoTable_Types.FlexMinWidth(1, "4rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "amount",
            label: React_Basic_DOM.text("M\xe4\xe4r\xe4"),
            cell: detailsContextAmount,
            sortProperty: TacoTable_Types.noSortProperty,
            width: new TacoTable_Types.FlexMinWidth(1, "6rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "unit",
            label: React_Basic_DOM.text("Yks."),
            cell: unitCell,
            sortProperty: TacoTable_Types.noSortProperty,
            width: new TacoTable_Types.FlexMinWidth(1, "4rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "unitPrice",
            label: React_Basic_DOM.text(""),
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value ], unitPriceCell),
            sortProperty: TacoTable_Types.noSortProperty,
            width: new TacoTable_Types.FlexMinWidth(1, "5rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "unitPriceLabel",
            label: mkSocialExpenseLabel(currencySymbol + "/suo."),
            cell: new TacoTable_Types.JSXCell([  ], unitPriceLabelCell),
            sortProperty: TacoTable_Types.noSortProperty,
            width: new TacoTable_Types.FlexMinWidth(1, "6rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "elementUnitPrice",
            label: React_Basic_DOM.text(""),
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value ], elementUnitPrice),
            sortProperty: TacoTable_Types.noSortProperty,
            width: new TacoTable_Types.FlexMinWidth(1, "7rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "elementUnitPriceLabel",
            label: mkSocialExpenseLabel(currencySymbol + "/rak."),
            cell: new TacoTable_Types.JSXCell([  ], elementUnitPriceLabel),
            sortProperty: TacoTable_Types.noSortProperty,
            width: new TacoTable_Types.FlexMinWidth(1, "6rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "totalPrice",
            label: mkSocialExpenseLabel("Yhteens\xe4"),
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value ], totalPrice),
            sortProperty: TacoTable_Types.noSortProperty,
            width: new TacoTable_Types.FlexMinWidth(1, "7rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "totalLabel",
            label: React_Basic_DOM.text(currencySymbol),
            cell: new TacoTable_Types.JSXCell([  ], totalLabel),
            sortProperty: TacoTable_Types.noSortProperty,
            width: new TacoTable_Types.Fixed("2rem"),
            headerJSX: Data_Maybe.Nothing.value
        } ]));
    };
};
var componentInfoTable = TofuHooks.mkHookComponent("ComponentInfoTable")(function (props) {
    return function __do() {
        var v = Client_WASM.useReferenceEstimationComponents(props.projectId)();
        var v1 = Client_WASM.useElementSpecs(props.projectId)();
        var clipboard = StateHooks.useSelector(function (v2) {
            return v2.clipboard;
        })();
        var v2 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var v3 = TofuHooks.useState(false)();
        var componentClassColumnEnabled = FeatureHooks.useFeatureEnabled("component-class-column")();
        var v4 = TofuHooks.useMemo([ ReactHooksUtils.utf(props.elementData.element.elementId), ReactHooksUtils.utf(v1.value0) ])(function (v4) {
            return Data_Set.fromFoldable(Data_Foldable.foldableArray)(Types_Component.ordComponentId)(Data_Array.mapMaybe(function (v5) {
                var $235 = Data_Eq.eq(Data_Maybe.eqMaybe(Types_Element.eqElementId))(Data_Nullable.toMaybe(v5.elementId))(new Data_Maybe.Just(props.elementData.element.elementId));
                if ($235) {
                    return Data_Nullable.toMaybe(v5.componentId);
                };
                return Data_Maybe.Nothing.value;
            })(v1.value0));
        })();
        var v5 = TofuHooks.useState(false)();
        var toggleCheckedSpec = function (id) {
            return props.setCheckedSpecs(Utils.toggleInSet(Types_Newtypes.ordElementSpecId)(id));
        };
        var elementAction = function (action) {
            return props.appDispatch(new Actions.ElementActionRequest({
                params: action,
                projectId: props.projectId,
                handler: function (v6) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                }
            }));
        };
        var deleteSelectedComponentsModalBody = TacoModal.modalBody({
            contents: [ React_Basic_DOM.text("Poistetaanko suorite / suoritteet rakenteelta?") ]
        });
        var copyComponentButton = TacoButton.component()()({
            text: (function () {
                var $237 = Data_Set.isEmpty(props.checkedSpecs);
                if ($237) {
                    return "Kopioi kaikki suoritteet";
                };
                return "Kopioi suoritteet";
            })(),
            color: TacoConstants.componentColors.border,
            onClick: new Data_Maybe.Just((function () {
                var specsToCopy$prime = (function () {
                    var $238 = Data_Set.isEmpty(props.checkedSpecs);
                    if ($238) {
                        return props.elementData.specs;
                    };
                    var matching = function (v6) {
                        var $240 = Data_Set.member(Types_Newtypes.ordElementSpecId)(v6.elementSpecId)(props.checkedSpecs);
                        if ($240) {
                            return new Data_Maybe.Just(v6);
                        };
                        return Data_Maybe.Nothing.value;
                    };
                    return Data_Array.mapMaybe(matching)(props.elementData.specs);
                })();
                var existingElementSpecIds = Data_Set.fromFoldable(Data_Foldable.foldableArray)(Types_Newtypes.ordElementSpecId)(Data_Functor.map(Data_Functor.functorArray)((function () {
                    var $278 = Data_Newtype.unwrap();
                    return function ($279) {
                        return (function (v6) {
                            return v6.elementSpecId;
                        })($278($279));
                    };
                })())(v1.value0));
                var isExistingSpec = function (v6) {
                    return Data_Set.member(Types_Newtypes.ordElementSpecId)(v6.elementSpecId)(existingElementSpecIds);
                };
                var specsToCopy = Data_Array.filter(isExistingSpec)(specsToCopy$prime);
                return props.appDispatch(Actions.CopyComponentsToClipboard.create(new Types_ComponentsView.ClipboardElementView({
                    elementId: props.elementData.element.elementId,
                    elementSpecs: specsToCopy
                })));
            })()),
            size: TacoConstants.L.value,
            buttonStyle: TacoButton_Types.Filled.value,
            testId: "copy-components-button"
        });
        var componentInfoProps = {
            element: props.elementData.element,
            selectedIds: props.selectedIds,
            setSelectedIds: props.setSelectedIds,
            existingComponentCodesForEdit: props.existingComponentCodesForEdit,
            appDispatch: props.appDispatch,
            projectId: props.projectId,
            projectCurrency: props.projectCurrency,
            checkedSpecs: props.checkedSpecs,
            toggleCheckedSpec: toggleCheckedSpec,
            viewMode: props.viewMode,
            programType: props.programType,
            isReportingProject: props.isReportingProject,
            elementData: props.elementData,
            setCheckedSpecs: props.setCheckedSpecs,
            showSocialExpensesInCosts: props.showSocialExpensesInCosts,
            dixonOptions: props.dixonOptions
        };
        var columns = getComponentInfoColumns(componentInfoProps)(componentClassColumnEnabled);
        var table = TacoTable.tableStateless(TofuHooks.getNameVoid)(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "selectedIds";
            }
        })(Data_Maybe.eqMaybe(Data_Tuple.eqTuple(Types_Newtypes.eqElementSpecId)(Types_Component.eqComponentId))))()({
            reflectSymbol: function () {
                return "r";
            }
        })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "unitPrice";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "unit";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "totalPrice";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "spec";
            }
        })(Types_Element.eqWasmElementSpec))()({
            reflectSymbol: function () {
                return "resourcesByComponent";
            }
        })(Types_ComponentsView.eqWasmResourcesByComponent))()({
            reflectSymbol: function () {
                return "group";
            }
        })(Data_Nullable.eqNullable(Types_ClassificationGroup.eqClassificationGroupCode)))()({
            reflectSymbol: function () {
                return "formula";
            }
        })(QuantityFormula_Types.eqInternalFormula))()({
            reflectSymbol: function () {
                return "elementUnitPrice";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "description";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "consumptionRate";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "componentReferenceCount";
            }
        })(Data_Eq.eqInt))()({
            reflectSymbol: function () {
                return "amount";
            }
        })(Data_Eq.eqNumber))))()({
            reflectSymbol: function () {
                return "dixonElementSpecLinks";
            }
        })(Data_Maybe.eqMaybe(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "target_field";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "measurement_group_id";
            }
        })(Data_Eq.eqInt))()({
            reflectSymbol: function () {
                return "id";
            }
        })(Data_Eq.eqInt))()({
            reflectSymbol: function () {
                return "from_type";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "element_spec_id";
            }
        })(Data_Maybe.eqMaybe(Types_Newtypes.eqElementSpecId)))()({
            reflectSymbol: function () {
                return "component_id";
            }
        })(Data_Maybe.eqMaybe(Types_Component.eqComponentId)))()({
            reflectSymbol: function () {
                return "building_element_id";
            }
        })(Data_Maybe.eqMaybe(Types_Element.eqElementId)))))))()({
            reflectSymbol: function () {
                return "checked";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "active_measurement_groups";
            }
        })(Data_Maybe.eqMaybe(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "unit";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "perimeter";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "num_vertices";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "name";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "linked_component_ids";
            }
        })(Data_Eq.eqArray(Types_Component.eqComponentId)))()({
            reflectSymbol: function () {
                return "linked_building_element_ids";
            }
        })(Data_Eq.eqArray(Types_Element.eqElementId)))()({
            reflectSymbol: function () {
                return "id";
            }
        })(Data_Eq.eqInt))()({
            reflectSymbol: function () {
                return "formula_sum";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "drawing_ids";
            }
        })(Data_Eq.eqArray(Data_Eq.eqInt)))()({
            reflectSymbol: function () {
                return "default_location";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "default_formula";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "color";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "code";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "area";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "active";
            }
        })(Data_Eq.eqBoolean))))))()({
            reflectSymbol: function () {
                return "activeDixonElementSpecLinks";
            }
        })(Data_Maybe.eqMaybe(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "target_field";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "measurement_group_id";
            }
        })(Data_Eq.eqInt))()({
            reflectSymbol: function () {
                return "id";
            }
        })(Data_Eq.eqInt))()({
            reflectSymbol: function () {
                return "from_type";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "element_spec_id";
            }
        })(Data_Maybe.eqMaybe(Types_Newtypes.eqElementSpecId)))()({
            reflectSymbol: function () {
                return "component_id";
            }
        })(Data_Maybe.eqMaybe(Types_Component.eqComponentId)))()({
            reflectSymbol: function () {
                return "building_element_id";
            }
        })(Data_Maybe.eqMaybe(Types_Element.eqElementId))))))))(componentInfoTableInst)({
            rows: Data_Functor.map(Data_Functor.functorArray)(mkComponentInfoRow(columns)(componentInfoProps))(props.elementData.components),
            columns: columns,
            sortProperty: Data_Maybe.Nothing.value,
            sortingOrder: TacoTable_Types.Ascending.value,
            onSort: function (v6) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            },
            showHeader: true,
            styleSet: componentInfoTableStyleSet,
            onResize: function (v6) {
                return function (v7) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                };
            },
            columnSizeOffsets: Data_Maybe.Nothing.value
        });
        var closeDeleteSelectedComponentsModal = v2.value1(function (v6) {
            return Data_Maybe.Nothing.value;
        });
        var deleteSelectedComponentsModalActions = function (elementSpecIds) {
            return TacoModal.modalActions({
                contents: [ TacoButton.component()()({
                    onClick: new Data_Maybe.Just(closeDeleteSelectedComponentsModal),
                    text: "Peruuta",
                    testId: "cancel-delete-selected-components-button"
                }), TacoButton.component()()({
                    text: "Poista suoritteet rakenteelta",
                    color: TacoConstants.SecondaryRed.value,
                    onClick: new Data_Maybe.Just(function __do() {
                        elementAction(new Types_Element.DeleteElementSpecs({
                            deleteComponents: false,
                            elementSpecIds: elementSpecIds
                        }))();
                        props.setCheckedSpecs(function (v6) {
                            return Data_Set.empty;
                        })();
                        return closeDeleteSelectedComponentsModal();
                    }),
                    testId: "submit-delete-selected-components-button"
                }), TacoButton.component()()({
                    text: "Poista suoritteet rakenteelta ja hankkeelta",
                    color: TacoConstants.SecondaryRed.value,
                    onClick: new Data_Maybe.Just(function __do() {
                        elementAction(new Types_Element.DeleteElementSpecs({
                            deleteComponents: true,
                            elementSpecIds: elementSpecIds
                        }))();
                        props.setCheckedSpecs(function (v6) {
                            return Data_Set.empty;
                        })();
                        return closeDeleteSelectedComponentsModal();
                    }),
                    testId: "submit-delete-selected-components-from-element-and-project-button"
                }) ]
            });
        };
        var deleteSelectedComponentsModal = (function () {
            if (v2.value0 instanceof Data_Maybe.Nothing) {
                return React_Basic.empty;
            };
            if (v2.value0 instanceof Data_Maybe.Just) {
                return TacoModal.component()()({
                    isActive: true,
                    onClose: closeDeleteSelectedComponentsModal,
                    heading: "Poista valitut suoriteet",
                    contents: [ deleteSelectedComponentsModalBody, deleteSelectedComponentsModalActions(v2.value0.value0) ]
                });
            };
            throw new Error("Failed pattern match at CostEstimation.BuildingElementsView.ComponentsTable (line 267, column 37 - line 277, column 10): " + [ v2.value0.constructor.name ]);
        })();
        var clipboardSpecs = (function () {
            if (clipboard.components instanceof Types_ComponentsView.ClipboardElementView) {
                return clipboard.components.value0.elementSpecs;
            };
            return Common.emptyArray;
        })();
        var pasteDisabled = Data_Array.length(clipboardSpecs) === 0;
        var pasteComponentButton = TacoButton.component()()({
            text: "Liit\xe4 suoritteet",
            color: TacoConstants.componentColors.border,
            onClick: new Data_Maybe.Just(function __do() {
                v5.value1(function (v6) {
                    return true;
                })();
                return props.appDispatch(new Actions.EstimationElementActionRequest({
                    projectId: props.projectId,
                    params: new Types_Estimation.AddExistingProjectElementSpecsToElement({
                        targetElementId: props.elementData.element.elementId,
                        elementSpecs: clipboardSpecs
                    }),
                    handler: function (v6) {
                        return v5.value1(function (v7) {
                            return false;
                        });
                    }
                }))();
            }),
            title: Data_Monoid.guard(Data_Monoid.monoidString)(v5.value0)("Lataa..."),
            disabled: pasteDisabled || v5.value0,
            size: TacoConstants.L.value,
            buttonStyle: TacoButton_Types.Filled.value,
            testId: "paste-components-button"
        });
        TofuHooks.useMemo([ ReactHooksUtils.utf(v.value0) ])(function (v6) {
            return Data_Array.mapMaybe(toAddReferenceComponentRowData)(v.value0);
        })();
        var openSelectedComponentButton = TacoButton.component()()({
            text: "Siirry valitulle suoritteelle",
            icon: Data_Maybe.Nothing.value,
            onClick: new Data_Maybe.Just(props.appDispatch(Actions.NavigateRoute.create(new Types_FrontEndRoutes.EstimationFERoute({
                projectId: props.projectId,
                initialSelectedComponent: Data_Functor.map(Data_Maybe.functorMaybe)(Data_Tuple.snd)(props.selectedIds)
            })))),
            buttonStyle: TacoButton_Types.Filled.value,
            size: TacoConstants.L.value,
            testId: "open-selected-component-button"
        });
        var hasCheckedSpecs = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Set.isEmpty)(props.checkedSpecs);
        var handleWithSubprojectId = function (subprojectIdAction) {
            var v6 = Data_Nullable.toMaybe(props.elementData.element.subprojectId);
            if (v6 instanceof Data_Maybe.Nothing) {
                return function __do() {
                    props.appDispatch(Actions.AddAlert.create(Types_Alert.AlertError.value)("Suoritetta ei voida lis\xe4t\xe4 rakenteelle, koska alakohdetta ei ole m\xe4\xe4ritelty. " + ("ProjectId: " + (Data_Show.show(Types_Project.showProjectId)(props.projectId) + (", " + ("ElementId: " + Data_Show.show(Types_Element.showElementId)(props.elementData.element.elementId)))))))();
                    return ClientLogger.logError(Effect_Class.monadEffectEffect)(Effect_Exception.error("User wanted to perform a component action for an element, but the subprojectId was Nothing for the element used. " + ("ProjectId: " + (Data_Show.show(Types_Project.showProjectId)(props.projectId) + (", " + ("ElementId: " + Data_Show.show(Types_Element.showElementId)(props.elementData.element.elementId)))))))();
                };
            };
            if (v6 instanceof Data_Maybe.Just) {
                return subprojectIdAction(v6.value0);
            };
            throw new Error("Failed pattern match at CostEstimation.BuildingElementsView.ComponentsTable (line 284, column 7 - line 295, column 48): " + [ v6.constructor.name ]);
        };
        var duplicateComponentButton = TacoButton.component()()({
            text: "Monista valitut suoriteet",
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconCopyToProjectV1.value),
            onClick: new Data_Maybe.Just(function __do() {
                handleWithSubprojectId(function (targetSubprojectId) {
                    return elementAction(new Types_Element.DuplicateComponentsWithElementSpecs({
                        elementSpecIds: Data_Array.fromFoldable(Data_Set.foldableSet)(props.checkedSpecs),
                        elementId: props.elementData.element.elementId,
                        targetProjectId: props.projectId,
                        targetSubprojectId: targetSubprojectId,
                        allowDuplicateComponents: false
                    }));
                })();
                return props.setCheckedSpecs(function (v6) {
                    return Data_Set.empty;
                })();
            }),
            buttonStyle: TacoButton_Types.Filled.value,
            size: TacoConstants.L.value,
            testId: "duplicate-selected-components-button"
        });
        var deleteSelectedComponentsButton = TacoButton.component()()({
            text: "Poista valitut suoriteet",
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconDelete24Px.value),
            onClick: new Data_Maybe.Just((function () {
                var elementSpecIds = Data_Array.fromFoldable(Data_Set.foldableSet)(props.checkedSpecs);
                return v2.value1(function (v6) {
                    return new Data_Maybe.Just(elementSpecIds);
                });
            })()),
            buttonStyle: TacoButton_Types.Filled.value,
            size: TacoConstants.L.value,
            testId: "delete-selected-components-button"
        });
        var canEdit = EstimationUtils.canEditView(props);
        var addComponentToElementModal = CostEstimation_AddComponent.component({
            projectId: props.projectId,
            setNewComponentId: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit))),
            onShowAddComponentModal: function (flag) {
                return v3.value1(function (v6) {
                    return flag;
                });
            },
            addComponentModalOpen: v3.value0,
            detailsElement: new Data_Maybe.Just(props.elementData)
        });
        var editViewControls = Data_Monoid.guard(Data_Monoid.monoidArray)(canEdit && (!props.isPanel && Data_Eq.eq(Types_CostEstimation.eqECViewMode)(props.viewMode)(Types_CostEstimation.EditMode.value)))([ addComponentToElementModal, Data_Monoid.guard(React_Basic.monoidJSX)(canEdit && !pasteDisabled)(pasteComponentButton), Data_Monoid.guard(React_Basic.monoidJSX)(canEdit)(copyComponentButton), Data_Monoid.guard(React_Basic.monoidJSX)(hasCheckedSpecs)(duplicateComponentButton), Data_Monoid.guard(React_Basic.monoidJSX)(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Utils.isNothing)(props.selectedIds))(openSelectedComponentButton), Data_Monoid.guard(React_Basic.monoidJSX)(hasCheckedSpecs)(deleteSelectedComponentsButton) ]);
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "__ComponentsTable",
            css: ElementDetailsTable_Styles.componentsTableStyles,
            children: [ Data_Monoid.guard(Data_Monoid.monoidFn(React_Basic.monoidJSX))(canEdit)(React_Basic.fragment)([ deleteSelectedComponentsModal ]), CostEstimationTableWrapper.component({
                colorSet: TacoConstants.componentColors,
                children: [ table ],
                floatingActionButtons: React_Basic.fragment(Data_Semigroup.append(Data_Semigroup.semigroupArray)(editViewControls)(props.extraControls))
            }) ]
        });
    };
});
module.exports = {
    componentInfoTable: componentInfoTable
};
