// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var AddIncomeGroup = require("../AddIncomeGroup/index.js");
var Controls = require("../Controls/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Measurements = require("../Measurements/index.js");
var ProjectComponents = require("../ProjectComponents/index.js");
var ProjectComponents_Styles = require("../ProjectComponents.Styles/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var ResponsibilityInfoContainer = require("../ResponsibilityInfoContainer/index.js");
var SocialExpensePercentages = require("../SocialExpensePercentages/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoLoader = require("../TacoLoader/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_CostGroup = require("../Types.CostGroup/index.js");
var Types_Project = require("../Types.Project/index.js");
var UseFetch = require("../UseFetch/index.js");
var component = TofuHooks.mkHookComponent("EditProject")(function (props) {
    return function __do() {
        var v = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var v1 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var appDispatch = StateHooks.useDispatch();
        var v2 = UseFetch.useFetchValueByProjectId(props.projectId)(function (v2) {
            return v2.selectedProject;
        })(Actions.GetProjectRequest.create)();
        var v3 = UseFetch.useFetchValueByProjectId(props.projectId)(function (v3) {
            return v3.vatExclusionCostGroups;
        })(Actions.GetVATExclusionCostGroupsRequest.create)();
        var v4 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v4) {
            return v4.costControlExcludeActualCostSocialExpenses;
        })(Actions.GetCostControlExcludeActualCostSocialExpensesRequest.value)();
        var costControlExcludeActualCostSocialExpenses = Data_Maybe.maybe(false)(function (v5) {
            return v5.costControlExcludeActualCostSocialExpenses;
        })(v4.value0);
        var resetCostGroupTargetItemsButton = TacoButton.component()()({
            text: "Palauta tavoite",
            title: "Palauta tavoite vastaamaan suoritteita",
            color: TacoConstants.SecondaryRed.value,
            onClick: Data_Maybe.Just.create(v1.value1(function (v5) {
                return new Data_Maybe.Just(props.projectId);
            }))
        });
        var modalBody = TacoModal.modalBody({
            contents: [ React_Basic_DOM.text("Haluatko varmasti palauttaa tavoitteen vastaamaan suoritteita?") ]
        });
        var mVATExclusionCostGroupsString = Data_Functor.map(Data_Maybe.functorMaybe)(Data_Functor.map(Data_Functor.functorFn)(Types_CostGroup.toCostGroupsListString)(function (v5) {
            return v5.items;
        }))(v3.value0);
        var mProject = Data_Functor.map(Data_Maybe.functorMaybe)(function (v5) {
            return v5.project;
        })(v2.value0);
        var closeResetCostGroupTargetItemsModal = v1.value1(function (v5) {
            return Data_Maybe.Nothing.value;
        });
        var modalActions = TacoModal.modalActions({
            contents: [ TacoButton.component()()({
                onClick: new Data_Maybe.Just(closeResetCostGroupTargetItemsModal),
                text: "Peruuta"
            }), TacoButton.component()()({
                text: "Poista",
                color: TacoConstants.SecondaryRed.value,
                onClick: new Data_Maybe.Just(function __do() {
                    appDispatch(new Actions.ResetCostGroupTargetItemsRequest({
                        projectId: props.projectId
                    }))();
                    return closeResetCostGroupTargetItemsModal();
                })
            }) ]
        });
        var resetCostGroupTargetItemsModal = (function () {
            if (v1.value0 instanceof Data_Maybe.Nothing) {
                return React_Basic.empty;
            };
            if (v1.value0 instanceof Data_Maybe.Just) {
                return TacoModal.component()()({
                    isActive: true,
                    onClose: closeResetCostGroupTargetItemsModal,
                    heading: "Palauta tavoite",
                    contents: [ modalBody, modalActions ]
                });
            };
            throw new Error("Failed pattern match at EditProject (line 72, column 38 - line 82, column 10): " + [ v1.value0.constructor.name ]);
        })();
        var closeArchiveModal = v.value1(function (v5) {
            return Data_Maybe.Nothing.value;
        });
        var archiveModalBody = TacoModal.modalBody({
            contents: [ React_Basic_DOM.text("Haluatko varmasti poistaa t\xe4m\xe4n hankkeen?") ]
        });
        var archiveModalActions = function (params) {
            return TacoModal.modalActions({
                contents: [ TacoButton.component()()({
                    onClick: new Data_Maybe.Just(closeArchiveModal),
                    text: "Peruuta"
                }), TacoButton.component()()({
                    text: "Poista",
                    color: TacoConstants.SecondaryRed.value,
                    onClick: new Data_Maybe.Just(function __do() {
                        appDispatch(new Actions.UpdateProjectArchiveStatusRequest(params))();
                        return closeArchiveModal();
                    })
                }) ]
            });
        };
        var archiveModal = (function () {
            if (v.value0 instanceof Data_Maybe.Nothing) {
                return React_Basic.empty;
            };
            if (v.value0 instanceof Data_Maybe.Just) {
                return TacoModal.component()()({
                    isActive: true,
                    onClose: closeArchiveModal,
                    heading: "Poista hanke",
                    contents: [ archiveModalBody, archiveModalActions(v.value0.value0) ]
                });
            };
            throw new Error("Failed pattern match at EditProject (line 110, column 20 - line 120, column 10): " + [ v.value0.constructor.name ]);
        })();
        var archiveButton = TacoButton.component()()({
            text: "Poista hanke",
            title: "Postettuihin hankkeisiin ei p\xe4\xe4se en\xe4\xe4 k\xe4siksi",
            color: TacoConstants.SecondaryRed.value,
            onClick: Data_Maybe.Just.create(v.value1(function (v5) {
                return Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(mProject)(function (project) {
                    return {
                        projectId: props.projectId,
                        value: {
                            setArchived: true,
                            applicationMode: Types_Project.ReportingMode.value,
                            projectCode: project.code,
                            projectVersion: project.version
                        }
                    };
                });
            })),
            testId: "archive-project-button"
        });
        var bottomButtons = Controls.component()()({
            rightControls: [ resetCostGroupTargetItemsButton, archiveButton, archiveModal ],
            topGutter: true
        });
        var v5 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v5) {
            return v5.constructionTypes;
        })(Actions.ConstructionTypesRequest.value)();
        var v6 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v6) {
            return v6.projectManagers;
        })(Actions.GetProjectManagersRequest.value)();
        var v7 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v7) {
            return v7.projectTypes;
        })(Actions.ProjectTypesRequest.value)();
        var v8 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v8) {
            return v8.orgAddressArea;
        })(Actions.OrgAddressAreaRequest.value)();
        var v9 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v9) {
            return v9.classifications;
        })(Actions.ClassificationsRequest.value)();
        var v10 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v10) {
            return v10.projectGroups;
        })(Actions.ProjectGroupsRequest.value)();
        var v11 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v11) {
            return v11.financingTypes;
        })(Actions.FinancingTypesRequest.value)();
        var v12 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v12) {
            return v12.contractTypes;
        })(Actions.ContractTypesRequest.value)();
        var v13 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v13) {
            return v13.projectList;
        })(Actions.CostControlProjectsRequest.value)();
        if (mProject instanceof Data_Maybe.Just) {
            return React_Basic_DOM_Generated.div()({
                className: "edit-project-page",
                children: [ TacoText.component()()({
                    text: "Hankkeen tiedot",
                    weight: TacoText_Types.Bold.value,
                    gutterBottom: true,
                    variant: new TacoText_Types.Heading(1)
                }), ProjectComponents.component({
                    projectComponentsMode: ProjectComponents.EditCostControlProjectMode.value,
                    mProjectId: new Data_Maybe.Just(props.projectId),
                    mProject: new Data_Maybe.Just(mProject.value0),
                    disableClassifications: true,
                    mVATExclusionCostGroupsString: mVATExclusionCostGroupsString,
                    mConstructionTypes: v5.value0,
                    mProjectManagers: v6.value0,
                    mProjectTypes: v7.value0,
                    mOrgAddressArea: v8.value0,
                    mClassifications: v9.value0,
                    mProjectGroups: v10.value0,
                    mFinancingTypes: v11.value0,
                    mContractTypes: v12.value0,
                    mProjectList: v13.value0
                }), React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                    className: "toggle-actual-social-costs-container",
                    css: ProjectComponents_Styles.projectDetailsSectionStyles,
                    children: [ TacoText.component()()({
                        text: "Sosiaalikuluja toteumaan (organisaatio)",
                        weight: TacoText_Types.Bold.value,
                        gutterBottom: true,
                        variant: TacoText_Types.Paragraph.value
                    }), TacoCheckbox.component()()({
                        className: "toggle-actual-social-costs-checkbox",
                        labelText: Data_Maybe.Just.create(new TacoCheckbox.StringLabel("Ei sosiaalikuluja toteumaan")),
                        isChecked: costControlExcludeActualCostSocialExpenses,
                        onToggle: new TacoCheckbox.NoEvent(appDispatch(new Actions.SetCostControlExcludeActualCostSocialExpenses({
                            costControlExcludeActualCostSocialExpenses: !costControlExcludeActualCostSocialExpenses
                        }))),
                        testId: "toggle-actual-social-costs-checkbox"
                    }) ]
                }), React_Basic_DOM_Generated.div()({
                    className: "incomeItem-groups-container",
                    children: [ AddIncomeGroup.component({
                        projectId: props.projectId
                    }) ]
                }), React_Basic_DOM_Generated.div()({
                    className: "social-expense-percentages-container",
                    children: [ SocialExpensePercentages.component({
                        projectId: props.projectId
                    }) ]
                }), React_Basic_DOM_Generated.div()({
                    className: "measurement-units-container",
                    children: [ Measurements.component({
                        projectId: props.projectId,
                        applicationMode: Types_Project.ReportingMode.value
                    }) ]
                }), React_Basic_DOM_Generated.div()({
                    className: "business-info-container",
                    children: [ ResponsibilityInfoContainer.component({
                        projectId: props.projectId
                    }) ]
                }), resetCostGroupTargetItemsModal, bottomButtons ]
            });
        };
        return TacoLoader.component()()({
            size: "1rem"
        });
    };
});
module.exports = {
    component: component
};
