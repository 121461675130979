// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Date = require("../Data.Date/index.js");
var Data_Date_Component = require("../Data.Date.Component/index.js");
var Data_DateTime = require("../Data.DateTime/index.js");
var Data_DateTime_Instant = require("../Data.DateTime.Instant/index.js");
var Data_Enum = require("../Data.Enum/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Time = require("../Data.Time/index.js");
var Data_Time_Component = require("../Data.Time.Component/index.js");
var Data_Time_Duration = require("../Data.Time.Duration/index.js");
var Foreign = require("../Foreign/index.js");
var toUTCString = function (dt) {
    return $foreign.dateMethod("toUTCString", dt);
};
var toTimeString = function (dt) {
    return $foreign.dateMethod("toTimeString", dt);
};
var toString = function (dt) {
    return $foreign.dateMethod("toString", dt);
};
var toInstant = Control_Bind.composeKleisliFlipped(Data_Maybe.bindMaybe)(function ($4) {
    return Data_DateTime_Instant.instant(Data_Time_Duration.Milliseconds($4));
})($foreign.toInstantImpl(Data_Maybe.Just.create)(Data_Maybe.Nothing.value));
var toISOString = function (dt) {
    return $foreign.dateMethodEff("toISOString", dt);
};
var toDateTime = Data_Functor.map(Data_Functor.functorFn)(Data_Functor.map(Data_Maybe.functorMaybe)(Data_DateTime_Instant.toDateTime))(toInstant);
var toDateString = function (dt) {
    return $foreign.dateMethod("toDateString", dt);
};
var toDate = Data_Functor.map(Data_Functor.functorFn)(Data_Functor.map(Data_Maybe.functorMaybe)(Data_DateTime.date))(toDateTime);
var readDate = Foreign.unsafeReadTagged(Data_Identity.monadIdentity)("Date");
var getUTCSeconds = function (dt) {
    return $foreign.dateMethod("getUTCSeconds", dt);
};
var getUTCMonth = function (dt) {
    return $foreign.dateMethod("getUTCMonth", dt);
};
var getUTCMinutes = function (dt) {
    return $foreign.dateMethod("getUTCMinutes", dt);
};
var getUTCMilliseconds = function (dt) {
    return $foreign.dateMethod("getUTCMilliseconds", dt);
};
var getUTCHours = function (dt) {
    return $foreign.dateMethod("getUTCHours", dt);
};
var getUTCFullYear = function (dt) {
    return $foreign.dateMethod("getUTCFullYear", dt);
};
var getUTCDay = function (dt) {
    return $foreign.dateMethod("getUTCDay", dt);
};
var getUTCDate = function (dt) {
    return $foreign.dateMethod("getUTCDate", dt);
};
var getTimezoneOffset = function (dt) {
    return $foreign.dateMethodEff("getTimezoneOffset", dt);
};
var getTime = function (dt) {
    return $foreign.dateMethod("getTime", dt);
};
var showJSDate = {
    show: function (a) {
        return "(fromTime " + (Data_Show.show(Data_Show.showNumber)(getTime(a)) + ")");
    }
};
var getSeconds = function (dt) {
    return $foreign.dateMethodEff("getSeconds", dt);
};
var getMonth = function (dt) {
    return $foreign.dateMethodEff("getMonth", dt);
};
var getMinutes = function (dt) {
    return $foreign.dateMethodEff("getMinutes", dt);
};
var getMilliseconds = function (dt) {
    return $foreign.dateMethodEff("getMilliseconds", dt);
};
var getHours = function (dt) {
    return $foreign.dateMethodEff("getHours", dt);
};
var getFullYear = function (dt) {
    return $foreign.dateMethodEff("getFullYear", dt);
};
var getDay = function (dt) {
    return $foreign.dateMethodEff("getDay", dt);
};
var getDate = function (dt) {
    return $foreign.dateMethodEff("getDate", dt);
};
var fromDateTime = function (v) {
    return $foreign.jsdate({
        year: Data_Int.toNumber(Data_Enum.fromEnum(Data_Date_Component.boundedEnumYear)(Data_Date.year(v.value0))),
        month: Data_Int.toNumber(Data_Enum.fromEnum(Data_Date_Component.boundedEnumMonth)(Data_Date.month(v.value0)) - 1 | 0),
        day: Data_Int.toNumber(Data_Enum.fromEnum(Data_Date_Component.boundedEnumDay)(Data_Date.day(v.value0))),
        hour: Data_Int.toNumber(Data_Enum.fromEnum(Data_Time_Component.boundedEnumHour)(Data_Time.hour(v.value1))),
        minute: Data_Int.toNumber(Data_Enum.fromEnum(Data_Time_Component.boundedEnumMinute)(Data_Time.minute(v.value1))),
        second: Data_Int.toNumber(Data_Enum.fromEnum(Data_Time_Component.boundedEnumSecond)(Data_Time.second(v.value1))),
        millisecond: Data_Int.toNumber(Data_Enum.fromEnum(Data_Time_Component.boundedEnumMillisecond)(Data_Time.millisecond(v.value1)))
    });
};
var eqJSDate = {
    eq: function (a) {
        return function (b) {
            return getTime(a) === getTime(b);
        };
    }
};
var ordJSDate = {
    compare: function (a) {
        return function (b) {
            return Data_Ord.compare(Data_Ord.ordNumber)(getTime(a))(getTime(b));
        };
    },
    Eq0: function () {
        return eqJSDate;
    }
};
module.exports = {
    readDate: readDate,
    fromDateTime: fromDateTime,
    toDateTime: toDateTime,
    toDate: toDate,
    toInstant: toInstant,
    getTime: getTime,
    getUTCDate: getUTCDate,
    getUTCDay: getUTCDay,
    getUTCFullYear: getUTCFullYear,
    getUTCHours: getUTCHours,
    getUTCMilliseconds: getUTCMilliseconds,
    getUTCMinutes: getUTCMinutes,
    getUTCMonth: getUTCMonth,
    getUTCSeconds: getUTCSeconds,
    getDate: getDate,
    getDay: getDay,
    getFullYear: getFullYear,
    getHours: getHours,
    getMilliseconds: getMilliseconds,
    getMinutes: getMinutes,
    getMonth: getMonth,
    getSeconds: getSeconds,
    getTimezoneOffset: getTimezoneOffset,
    toDateString: toDateString,
    toISOString: toISOString,
    toString: toString,
    toTimeString: toTimeString,
    toUTCString: toUTCString,
    eqJSDate: eqJSDate,
    ordJSDate: ordJSDate,
    showJSDate: showJSDate,
    isValid: $foreign.isValid,
    fromInstant: $foreign.fromInstant,
    jsdate: $foreign.jsdate,
    jsdateLocal: $foreign.jsdateLocal,
    now: $foreign.now,
    parse: $foreign.parse,
    fromTime: $foreign.fromTime
};
