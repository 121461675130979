// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var Record = require("../Record/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_CostGroup = require("../Types.CostGroup/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Project = require("../Types.Project/index.js");
var UseNullableRef = require("../UseNullableRef/index.js");
var ByDescription = (function () {
    function ByDescription() {

    };
    ByDescription.value = new ByDescription();
    return ByDescription;
})();
var ByProjectCode = (function () {
    function ByProjectCode() {

    };
    ByProjectCode.value = new ByProjectCode();
    return ByProjectCode;
})();
var ByNetSum = (function () {
    function ByNetSum() {

    };
    ByNetSum.value = new ByNetSum();
    return ByNetSum;
})();
var ByCreatedAt = (function () {
    function ByCreatedAt() {

    };
    ByCreatedAt.value = new ByCreatedAt();
    return ByCreatedAt;
})();
var ByUpdatedAt = (function () {
    function ByUpdatedAt() {

    };
    ByUpdatedAt.value = new ByUpdatedAt();
    return ByUpdatedAt;
})();
var ByExportDate = (function () {
    function ByExportDate() {

    };
    ByExportDate.value = new ByExportDate();
    return ByExportDate;
})();
var ByExternalId = (function () {
    function ByExternalId() {

    };
    ByExternalId.value = new ByExternalId();
    return ByExternalId;
})();
var ByInvoiceDate = (function () {
    function ByInvoiceDate() {

    };
    ByInvoiceDate.value = new ByInvoiceDate();
    return ByInvoiceDate;
})();
var BySupplier = (function () {
    function BySupplier() {

    };
    BySupplier.value = new BySupplier();
    return BySupplier;
})();
var ByInvoiceDesc = (function () {
    function ByInvoiceDesc() {

    };
    ByInvoiceDesc.value = new ByInvoiceDesc();
    return ByInvoiceDesc;
})();
var ByCostGroupCode = (function () {
    function ByCostGroupCode() {

    };
    ByCostGroupCode.value = new ByCostGroupCode();
    return ByCostGroupCode;
})();
var ByCostClassCode = (function () {
    function ByCostClassCode() {

    };
    ByCostClassCode.value = new ByCostClassCode();
    return ByCostClassCode;
})();
var styleSet = TacoTable_Styles.optimizedTableStyleSet;
var getNameImportInvoiceSortProperty = {
    getName: function (v) {
        return "ImportInvoiceSortProperty";
    }
};
var eqInvoiceLineSortProperty = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ByDescription && y instanceof ByDescription) {
                return true;
            };
            if (x instanceof ByProjectCode && y instanceof ByProjectCode) {
                return true;
            };
            if (x instanceof ByNetSum && y instanceof ByNetSum) {
                return true;
            };
            if (x instanceof ByCreatedAt && y instanceof ByCreatedAt) {
                return true;
            };
            if (x instanceof ByUpdatedAt && y instanceof ByUpdatedAt) {
                return true;
            };
            if (x instanceof ByExportDate && y instanceof ByExportDate) {
                return true;
            };
            if (x instanceof ByExternalId && y instanceof ByExternalId) {
                return true;
            };
            if (x instanceof ByInvoiceDate && y instanceof ByInvoiceDate) {
                return true;
            };
            if (x instanceof BySupplier && y instanceof BySupplier) {
                return true;
            };
            if (x instanceof ByInvoiceDesc && y instanceof ByInvoiceDesc) {
                return true;
            };
            if (x instanceof ByCostGroupCode && y instanceof ByCostGroupCode) {
                return true;
            };
            if (x instanceof ByCostClassCode && y instanceof ByCostClassCode) {
                return true;
            };
            return false;
        };
    }
};
var importInvoiceTableOptimized = function (dictEq) {
    return TacoTable.tableOptimized(getNameImportInvoiceSortProperty)(eqInvoiceLineSortProperty)(dictEq);
};
var mkImportInvoicesTable = TofuHooks.mkHookComponent("MkImportSalesInvoicesTable")(function (props) {
    var isSelected = function (id) {
        return Data_Array.elem(Data_Eq.eqInt)(id)(props.selected);
    };
    var getMappedRow = function (line) {
        var emptyInvoice = {
            id: 0,
            externalId: "",
            invoiceNumber: Data_Maybe.Nothing.value,
            voucherNumber: Data_Maybe.Nothing.value,
            invoiceDate: "",
            supplier: "",
            supplierBusinessId: Data_Maybe.Nothing.value,
            description: "",
            image: Data_Maybe.Nothing.value,
            isSalary: false,
            lines: [  ]
        };
        var invoice = Data_Maybe.fromMaybe(emptyInvoice)(Data_Array.find(function (i) {
            return i.id === line.invoiceId;
        })(props.invoices));
        return Record.merge()()(line)({
            externalId: invoice.externalId,
            supplier: invoice.supplier,
            invoiceDesc: invoice.description,
            invoiceDate: invoice.invoiceDate,
            invoiceDescription: invoice.description,
            image: invoice.image
        });
    };
    var mappedInvoiceRows = Data_Functor.map(Data_Functor.functorArray)(getMappedRow)(props.lines);
    var columns = (function () {
        var projectElement = function (line) {
            return Data_Newtype.un()(Types_Project.ProjectCode)(Data_Maybe.fromMaybe("")(line.projectCode));
        };
        var invoiceNumberWithImageLink = function (invoice) {
            return props.invoiceImgLinkComponent({
                invoiceNumber: invoice.externalId,
                invoiceImg: (function () {
                    if (invoice.image instanceof Data_Maybe.Just) {
                        var $24 = Data_String_Common["null"](invoice.image.value0);
                        if ($24) {
                            return Data_Maybe.Nothing.value;
                        };
                        return new Data_Maybe.Just(invoice.image.value0);
                    };
                    if (invoice.image instanceof Data_Maybe.Nothing) {
                        return Data_Maybe.Nothing.value;
                    };
                    throw new Error("Failed pattern match at ExpensesTable (line 93, column 27 - line 95, column 47): " + [ invoice.image.constructor.name ]);
                })()
            });
        };
        var checkBoxElement = function (v) {
            return TacoCheckbox.component()()({
                className: "",
                isChecked: isSelected(v.id),
                onToggle: TacoCheckbox.WithShift.create(props.toggleCheckbox(v.id)),
                labelText: Data_Maybe.Nothing.value,
                testId: "checkbox-" + Data_Show.show(Data_Show.showInt)(v.id)
            });
        };
        return [ {
            key: "checkbox",
            label: React_Basic_DOM.text(""),
            cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], checkBoxElement),
            sortProperty: new Data_Maybe.Just(ByProjectCode.value),
            width: new TacoTable_Types.Fixed("2rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "projectCode",
            label: React_Basic_DOM.text("Projektin tunnus"),
            cell: new TacoTable_Types.PlainTextCell(projectElement),
            sortProperty: new Data_Maybe.Just(ByProjectCode.value),
            width: new TacoTable_Types.FlexMinWidth(1, "8rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "externalId",
            label: React_Basic_DOM.text("Nro."),
            cell: new TacoTable_Types.JSXCell([  ], invoiceNumberWithImageLink),
            sortProperty: new Data_Maybe.Just(ByExternalId.value),
            width: new TacoTable_Types.FlexMinWidth(2, "15rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "invoiceDate",
            label: React_Basic_DOM.text("Laskunpvm."),
            cell: new TacoTable_Types.PlainDateStringCell(function (v) {
                return v.invoiceDate;
            }),
            sortProperty: new Data_Maybe.Just(ByInvoiceDate.value),
            width: new TacoTable_Types.FlexMinWidth(1, "10rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "supplier",
            label: React_Basic_DOM.text("Toimittaja"),
            cell: new TacoTable_Types.PlainTextCell(function (v) {
                return v.supplier;
            }),
            sortProperty: new Data_Maybe.Just(BySupplier.value),
            width: new TacoTable_Types.FlexMinWidth(3, "10rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "description",
            label: React_Basic_DOM.text("Riviselite"),
            cell: new TacoTable_Types.PlainTextCell(function (v) {
                return v.description;
            }),
            sortProperty: new Data_Maybe.Just(ByDescription.value),
            width: new TacoTable_Types.FlexMinWidth(4, "25rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "invoiceDesc",
            label: React_Basic_DOM.text("Laskun selite"),
            cell: new TacoTable_Types.PlainTextCell(function (v) {
                return v.invoiceDescription;
            }),
            sortProperty: new Data_Maybe.Just(ByInvoiceDesc.value),
            width: new TacoTable_Types.FlexMinWidth(4, "25rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "netSum",
            label: React_Basic_DOM.text("Summa"),
            cell: TacoTable_Types.PlainEuroCell.create(function (iv) {
                return iv.netSum / 100.0;
            }),
            sortProperty: new Data_Maybe.Just(ByNetSum.value),
            width: new TacoTable_Types.FlexMinWidth(1, "7rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "costGroupCode",
            label: React_Basic_DOM.text("Littera"),
            cell: TacoTable_Types.PlainTextCell.create((function () {
                var $36 = Data_Maybe.fromMaybe("");
                var $37 = Data_Functor.map(Data_Maybe.functorMaybe)(Data_Newtype.un()(Types_CostGroup.CostGroupCode));
                return function ($38) {
                    return $36($37((function (v) {
                        return v.costGroupCode;
                    })($38)));
                };
            })()),
            sortProperty: new Data_Maybe.Just(ByCostGroupCode.value),
            width: new TacoTable_Types.Flex(1),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "costClassCode",
            label: React_Basic_DOM.text("Kustannuslaji"),
            cell: TacoTable_Types.PlainTextCell.create((function () {
                var $39 = Data_Maybe.fromMaybe("");
                var $40 = Data_Functor.map(Data_Maybe.functorMaybe)(Data_Newtype.un()(Types_Newtypes.CostClassCode));
                return function ($41) {
                    return $39($40((function (v) {
                        return v.costClassCode;
                    })($41)));
                };
            })()),
            sortProperty: new Data_Maybe.Just(ByCostClassCode.value),
            width: new TacoTable_Types.Flex(1),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "createdAt",
            label: React_Basic_DOM.text("Siirtoaika"),
            cell: new TacoTable_Types.PlainDateStringCell(function (v) {
                return v.createdAt;
            }),
            sortProperty: new Data_Maybe.Just(ByCreatedAt.value),
            width: new TacoTable_Types.FlexMinWidth(1, "10rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "updatedAt",
            label: React_Basic_DOM.text("Muokkausaika"),
            cell: new TacoTable_Types.PlainDateStringCell(function (v) {
                return v.updatedAt;
            }),
            sortProperty: new Data_Maybe.Just(ByUpdatedAt.value),
            width: new TacoTable_Types.FlexMinWidth(1, "10rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "exportDate",
            label: React_Basic_DOM.text("Vientiaika"),
            cell: TacoTable_Types.PlainDateStringCell.create((function () {
                var $42 = Data_Maybe.fromMaybe("");
                return function ($43) {
                    return $42((function (v) {
                        return v.exportDate;
                    })($43));
                };
            })()),
            sortProperty: new Data_Maybe.Just(ByExportDate.value),
            width: new TacoTable_Types.FlexMinWidth(1, "10rem"),
            headerJSX: Data_Maybe.Nothing.value
        } ];
    })();
    var getInvoiceLineRow = function (v) {
        return function (row) {
            return {
                rowData: row,
                rowKey: Data_Show.show(Data_Show.showInt)(row.id),
                onClick: function (v1) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                },
                className: (function () {
                    var $28 = isSelected(row.id);
                    if ($28) {
                        return "isSelected";
                    };
                    return "";
                })(),
                foldingElement: Data_Maybe.Nothing.value,
                columns: columns
            };
        };
    };
    return function __do() {
        var tableRef = UseNullableRef.useNullableRef();
        return importInvoiceTableOptimized(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "vatPercent";
            }
        })(Data_Maybe.eqMaybe(Data_Eq.eqInt)))()({
            reflectSymbol: function () {
                return "updatedAt";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "unit";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "supplier";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "projectCode";
            }
        })(Data_Maybe.eqMaybe(Types_Project.eqProjectCode)))()({
            reflectSymbol: function () {
                return "netSum";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "lineNumber";
            }
        })(Data_Eq.eqInt))()({
            reflectSymbol: function () {
                return "invoiceId";
            }
        })(Data_Eq.eqInt))()({
            reflectSymbol: function () {
                return "invoiceDescription";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "invoiceDesc";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "invoiceDate";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "image";
            }
        })(Data_Maybe.eqMaybe(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "id";
            }
        })(Data_Eq.eqInt))()({
            reflectSymbol: function () {
                return "hours";
            }
        })(Data_Maybe.eqMaybe(Data_Eq.eqInt)))()({
            reflectSymbol: function () {
                return "externalId";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "exportDate";
            }
        })(Data_Maybe.eqMaybe(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "description";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "createdAt";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "costGroupCode";
            }
        })(Data_Maybe.eqMaybe(Types_CostGroup.eqCostGroupCode)))()({
            reflectSymbol: function () {
                return "costClassCode";
            }
        })(Data_Maybe.eqMaybe(Types_Newtypes.eqCostClassCode))))({
            containerRef: tableRef,
            rows: mappedInvoiceRows,
            columns: columns,
            mkRow: getInvoiceLineRow,
            sortProperty: new Data_Maybe.Just(ByDescription.value),
            sortingOrder: TacoTable_Types.Ascending.value,
            rowHeight: TacoTable_Types.RowLarge.value,
            onSort: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit))),
            showHeader: true,
            styleSet: styleSet,
            initialScrollRow: Data_Maybe.Nothing.value,
            onResize: function (v) {
                return function (v1) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                };
            },
            columnSizeOffsets: Data_Maybe.Nothing.value
        });
    };
});
var ordInvoiceLineSortProperty = {
    compare: function (x) {
        return function (y) {
            if (x instanceof ByDescription && y instanceof ByDescription) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByDescription) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByDescription) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByProjectCode && y instanceof ByProjectCode) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByProjectCode) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByProjectCode) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByNetSum && y instanceof ByNetSum) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByNetSum) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByNetSum) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByCreatedAt && y instanceof ByCreatedAt) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByCreatedAt) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByCreatedAt) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByUpdatedAt && y instanceof ByUpdatedAt) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByUpdatedAt) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByUpdatedAt) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByExportDate && y instanceof ByExportDate) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByExportDate) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByExportDate) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByExternalId && y instanceof ByExternalId) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByExternalId) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByExternalId) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByInvoiceDate && y instanceof ByInvoiceDate) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByInvoiceDate) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByInvoiceDate) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof BySupplier && y instanceof BySupplier) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof BySupplier) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof BySupplier) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByInvoiceDesc && y instanceof ByInvoiceDesc) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByInvoiceDesc) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByInvoiceDesc) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByCostGroupCode && y instanceof ByCostGroupCode) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByCostGroupCode) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByCostGroupCode) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByCostClassCode && y instanceof ByCostClassCode) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at ExpensesTable (line 156, column 1 - line 156, column 76): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqInvoiceLineSortProperty;
    }
};
module.exports = {
    mkImportInvoicesTable: mkImportInvoicesTable
};
