// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Color = require("../Color/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var StyleProperties = require("../StyleProperties/index.js");
var StyleUtils = require("../StyleUtils/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var tacoChipStyles = function (v) {
    var backgroundColor = Color.lighten(0.4)(TacoConstants.color(v.color));
    return Box.boxStyle([ Box.FlexRow.value, Box.PlaceCenter.value, new Box.PaddingX(TacoConstants.S.value), new Box.PaddingY(TacoConstants.XXS.value), Box.BackgroundColor.create(new TacoConstants.CustomColor(backgroundColor)), Box.Border.create(new TacoConstants.BorderCustom(v.color)), new Box.BorderRadius(TacoConstants.M.value), Box.Style.create(React_Basic_Emotion.css()({
        outline: StyleProperties.none
    })), Data_Monoid.guard(Box.monoidBoxProp)(Data_Maybe.isJust(v.onRemove))(Box.Style.create(StyleUtils.interactionStyles()()({
        primaryColor: new TacoConstants.CustomColor(backgroundColor)
    }))) ]);
};
var crossStyles = Box.boxStyle([ Box.FlexRow.value, Box.PlaceCenter.value, new Box.PaddingLeft(TacoConstants.XS.value) ]);
module.exports = {
    tacoChipStyles: tacoChipStyles,
    crossStyles: crossStyles
};
