// Generated by purs version 0.14.5
"use strict";
var Common = require("../Common/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var RemoteDataUtils = require("../RemoteDataUtils/index.js");
var StateHooks = require("../StateHooks/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Project = require("../Types.Project/index.js");
var useFetch = function (dictEq) {
    return function (key) {
        return function (remoteValueSelector) {
            return function (getNeedsFetching) {
                return function (requestAction) {
                    return function __do() {
                        var dispatch = StateHooks.useDispatch();
                        var remoteValue = StateHooks.useSelector(remoteValueSelector)();
                        var needsFetching = getNeedsFetching(remoteValue);
                        var keyMemoRef = TofuHooks.useRef(key)();
                        TofuHooks.useEffect([ ReactHooksUtils.utf(needsFetching), ReactHooksUtils.utf(key) ])(function __do() {
                            var keyMemo = React_Basic_Hooks.readRef(keyMemoRef)();
                            (function () {
                                if (requestAction instanceof Data_Maybe.Just) {
                                    return Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(needsFetching || Data_Eq.notEq(dictEq)(keyMemo)(key))(function __do() {
                                        dispatch(requestAction.value0)();
                                        return React_Basic_Hooks.writeRef(keyMemoRef)(key)();
                                    })();
                                };
                                return Data_Unit.unit;
                            })();
                            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                        })();
                        if (remoteValue instanceof RemoteDataUtils.Fetching) {
                            return new Data_Tuple.Tuple(Data_Maybe.Nothing.value, true);
                        };
                        if (remoteValue instanceof RemoteDataUtils.Standby) {
                            return new Data_Tuple.Tuple(Data_Maybe.Nothing.value, true);
                        };
                        if (remoteValue instanceof RemoteDataUtils.Fetched) {
                            return new Data_Tuple.Tuple(new Data_Maybe.Just(remoteValue.value0), false);
                        };
                        if (remoteValue instanceof RemoteDataUtils["Error"]) {
                            return new Data_Tuple.Tuple(Data_Maybe.Nothing.value, false);
                        };
                        throw new Error("Failed pattern match at UseFetch (line 148, column 3 - line 152, column 42): " + [ remoteValue.constructor.name ]);
                    };
                };
            };
        };
    };
};
var useFetchItemsByProjectId = function (projectId) {
    return function (remoteValueItemSelector) {
        return function (mkRequestAction) {
            var requestAction = new Data_Maybe.Just(mkRequestAction({
                projectId: projectId
            }));
            var needsFetching = RemoteDataUtils.needsFetchingByProjectId(projectId);
            return function __do() {
                var v = useFetch(Types_Project.eqProjectId)(projectId)(remoteValueItemSelector)(needsFetching)(requestAction)();
                return new Data_Tuple.Tuple(Data_Maybe.maybe(Common.emptyArray)(function (v1) {
                    return v1.items;
                })(v.value0), v.value1);
            };
        };
    };
};
var useFetchValue = function (dictEq) {
    return function (key) {
        return function (remoteValueSelector) {
            return function (requestAction) {
                var requestAction$prime = new Data_Maybe.Just(requestAction);
                return useFetch(dictEq)(key)(remoteValueSelector)(RemoteDataUtils.needsFetchingGeneral)(requestAction$prime);
            };
        };
    };
};
var useFetchValueByProjectId = function (projectId) {
    return function (remoteValueItemSelector) {
        return function (mkRequestAction) {
            var requestAction = new Data_Maybe.Just(mkRequestAction({
                projectId: projectId
            }));
            var needsFetching = RemoteDataUtils.needsFetchingByProjectId(projectId);
            return function __do() {
                var v = useFetch(Types_Project.eqProjectId)(projectId)(remoteValueItemSelector)(needsFetching)(requestAction)();
                return new Data_Tuple.Tuple(v.value0, v.value1);
            };
        };
    };
};
var useConditionalFetchValue = function (dictEq) {
    return function (key) {
        return function (remoteValueItemSelector) {
            return function (getAction) {
                var requestAction = getAction(key);
                return function __do() {
                    var v = useFetch(dictEq)(key)(remoteValueItemSelector)(RemoteDataUtils.needsFetchingGeneral)(requestAction)();
                    return new Data_Tuple.Tuple(v.value0, v.value1);
                };
            };
        };
    };
};
var defaultFetchValue = function ($$default) {
    return function (v) {
        var value = Data_Maybe.fromMaybe($$default)(v.value0);
        return new Data_Tuple.Tuple(value, v.value1);
    };
};
var useFetchItems = function (dictEq) {
    return function (key) {
        return function (remoteValueItemSelector) {
            return function (requestAction) {
                return Data_Functor.map(Effect.functorEffect)(defaultFetchValue(Common.emptyArray))(useFetchValue(dictEq)(key)(remoteValueItemSelector)(requestAction));
            };
        };
    };
};
module.exports = {
    defaultFetchValue: defaultFetchValue,
    useFetchItems: useFetchItems,
    useFetchValue: useFetchValue,
    useConditionalFetchValue: useConditionalFetchValue,
    useFetchItemsByProjectId: useFetchItemsByProjectId,
    useFetchValueByProjectId: useFetchValueByProjectId,
    useFetch: useFetch
};
