// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var FRP_Event = require("../FRP.Event/index.js");
var Record = require("../Record/index.js");
var Record_Builder = require("../Record.Builder/index.js");
var Type_Data_RowList = require("../Type.Data.RowList/index.js");
var replicateManyNil = {
    replicateMany: function (v) {
        return function (v1) {
            return function (v2) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            };
        };
    }
};
var replicateMany = function (dict) {
    return dict.replicateMany;
};
var replicateManyCons = function (dictIsSymbol) {
    return function (dictCons) {
        return function (dictCons1) {
            return function (dictReplicateMany) {
                return {
                    replicateMany: function (v) {
                        return function (sinks) {
                            return function (bundles) {
                                var sink = Record.get(dictIsSymbol)()(Data_Symbol.SProxy.value)(sinks);
                                var bundle = Record.get(dictIsSymbol)()(Data_Symbol.SProxy.value)(bundles);
                                return function __do() {
                                    FRP_Event.subscribe(sink)(bundle.push)();
                                    return replicateMany(dictReplicateMany)(Type_Data_RowList.RLProxy.value)(sinks)(bundles)();
                                };
                            };
                        };
                    }
                };
            };
        };
    };
};
var makeSinkProxiesNil = {
    makeSinkProxies: function (v) {
        return Control_Applicative.pure(Effect.applicativeEffect)(Control_Category.identity(Record_Builder.categoryBuilder));
    }
};
var makeSinkProxies = function (dict) {
    return dict.makeSinkProxies;
};
var makeSinkProxiesCons = function (dictIsSymbol) {
    return function (dictMakeSinkProxies) {
        return function (dictLacks) {
            return function (dictCons) {
                return {
                    makeSinkProxies: function (v) {
                        return Control_Apply.apply(Effect.applyEffect)(Data_Functor.map(Effect.functorEffect)(Data_Functor.map(Data_Functor.functorFn)(Control_Semigroupoid.compose(Record_Builder.semigroupoidBuilder))(Record_Builder.insert()()(dictIsSymbol)(Data_Symbol.SProxy.value)))(FRP_Event.create))(makeSinkProxies(dictMakeSinkProxies)(Type_Data_RowList.RLProxy.value));
                    }
                };
            };
        };
    };
};
var chocoPieItUp = function (dict) {
    return dict.chocoPieItUp;
};
var runChocoPie = function (dictChocoPieRecord) {
    return chocoPieItUp(dictChocoPieRecord);
};
var callDriversNil = {
    callDrivers: function (v) {
        return function (v1) {
            return function (v2) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Control_Category.identity(Record_Builder.categoryBuilder));
            };
        };
    }
};
var callDrivers = function (dict) {
    return dict.callDrivers;
};
var callDriversCons = function (dictIsSymbol) {
    return function (dictCallDrivers) {
        return function (dictCons) {
            return function (dictCons1) {
                return function (dictLacks) {
                    return function (dictCons2) {
                        return {
                            callDrivers: function (v) {
                                return function (drivers) {
                                    return function (bundle) {
                                        var driver = Record.get(dictIsSymbol)()(Data_Symbol.SProxy.value)(drivers);
                                        var bundleton = Record.get(dictIsSymbol)()(Data_Symbol.SProxy.value)(bundle);
                                        var getSource = driver(bundleton.event);
                                        return Control_Apply.apply(Effect.applyEffect)(Data_Functor.map(Effect.functorEffect)(Data_Functor.map(Data_Functor.functorFn)(Control_Semigroupoid.compose(Record_Builder.semigroupoidBuilder))(Record_Builder.insert()()(dictIsSymbol)(Data_Symbol.SProxy.value)))(getSource))(callDrivers(dictCallDrivers)(Type_Data_RowList.RLProxy.value)(drivers)(bundle));
                                    };
                                };
                            }
                        };
                    };
                };
            };
        };
    };
};
var chocoPieRecord = function (dictRowToList) {
    return function (dictRowToList1) {
        return function (dictMakeSinkProxies) {
            return function (dictCallDrivers) {
                return function (dictReplicateMany) {
                    return {
                        chocoPieItUp: function (main) {
                            return function (drivers) {
                                return function __do() {
                                    var sinkBuilder = makeSinkProxies(dictMakeSinkProxies)(Type_Data_RowList.RLProxy.value)();
                                    var sinkProxies = Record_Builder.build(sinkBuilder)({});
                                    var sourcesBuilder = callDrivers(dictCallDrivers)(Type_Data_RowList.RLProxy.value)(drivers)(sinkProxies)();
                                    var sinks = main(Record_Builder.build(sourcesBuilder)({}));
                                    replicateMany(dictReplicateMany)(Type_Data_RowList.RLProxy.value)(sinks)(sinkProxies)();
                                    return Data_Unit.unit;
                                };
                            };
                        }
                    };
                };
            };
        };
    };
};
module.exports = {
    callDrivers: callDrivers,
    chocoPieItUp: chocoPieItUp,
    makeSinkProxies: makeSinkProxies,
    replicateMany: replicateMany,
    runChocoPie: runChocoPie,
    chocoPieRecord: chocoPieRecord,
    makeSinkProxiesCons: makeSinkProxiesCons,
    makeSinkProxiesNil: makeSinkProxiesNil,
    callDriversCons: callDriversCons,
    callDriversNil: callDriversNil,
    replicateManyCons: replicateManyCons,
    replicateManyNil: replicateManyNil
};
