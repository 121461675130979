// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Controls = require("../Controls/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Show = require("../Data.Show/index.js");
var Effect = require("../Effect/index.js");
var Invoices_Styles = require("../Invoices.Styles/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var TacoAutocomplete = require("../TacoAutocomplete/index.js");
var TacoGroupToggle = require("../TacoGroupToggle/index.js");
var TacoInput = require("../TacoInput/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Invoice = require("../Types.Invoice/index.js");
var Types_JSDateString = require("../Types.JSDateString/index.js");
var invoiceFilterControls = TofuHooks.mkHookComponent("InvoiceFilterControls")(function (props) {
    var upperDateLimitInput = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        className: "search-field",
        css: Invoices_Styles.dateFieldStyles,
        children: Control_Applicative.pure(Control_Applicative.applicativeArray)(TacoInput.stringInput()()(Data_Eq.eqString)({
            value: Data_Maybe.maybe("")(Types_JSDateString.extractJSDateString)(props.upperDateLimit),
            type: "date",
            clickEventHandling: TacoInput.NoHandling.value,
            required: true,
            onChange: TacoInput.changeHandler(TacoInput.inputValueString)(function (value) {
                return props.setUpperDateLimit(Data_Either.hush(Types_JSDateString.makeJSDateString(value)));
            })
        }))
    });
    var typeFilters = TacoGroupToggle.component({
        groups: [ Types_Invoice.Invoice.value, Types_Invoice.Salary.value ],
        testActive: function (v) {
            if (v instanceof Types_Invoice.Invoice) {
                return props.showInvoiceItems;
            };
            if (v instanceof Types_Invoice.Salary) {
                return props.showSalaryItems;
            };
            return false;
        },
        getName: function (v) {
            if (v instanceof Types_Invoice.Invoice) {
                return "Laskut " + Data_Show.show(Data_Show.showInt)(props.invoicesCount);
            };
            if (v instanceof Types_Invoice.Salary) {
                return "Palkat " + Data_Show.show(Data_Show.showInt)(props.salariesCount);
            };
            return "";
        },
        handleToggleGroup: function (x) {
            return function (v) {
                return props.onInvoiceTypeToggle(x);
            };
        }
    });
    var searchField = (function () {
        var input = TacoInput.stringInput()()(Data_Eq.eqString)({
            placeholder: "Hae",
            onChange: TacoInput.changeHandler(TacoInput.inputValueString)(function (value) {
                return props.onFilterStringChange(new Data_Maybe.Just(value));
            }),
            value: props.filterString,
            clearButton: true
        });
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "search-field",
            css: Invoices_Styles.searchFieldStyles,
            children: [ input ]
        });
    })();
    var mkAutocompleteItem = function (item) {
        return {
            id: item,
            text: item
        };
    };
    var searchByCostGroupCode = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        className: "search-field",
        css: Invoices_Styles.costGroupSearchFieldStyles,
        children: Control_Applicative.pure(Control_Applicative.applicativeArray)(TacoAutocomplete.component()()({
            sections: [ {
                text: Data_Maybe.Nothing.value,
                list: props.costGroupsDropdownItems
            } ],
            clearButton: true,
            onSelect: function (item) {
                return props.setFilterCostGroupCode(Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
                    return v.id;
                })(item));
            },
            selected: Data_Functor.map(Data_Maybe.functorMaybe)(mkAutocompleteItem)(props.filterCostGroupCode),
            placeholder: "Hae litteran perusteella",
            testId: "filter-by-cost-group-dropdown"
        }))
    });
    var lowerDateLimitInput = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        className: "search-field",
        css: Invoices_Styles.dateFieldStyles,
        children: Control_Applicative.pure(Control_Applicative.applicativeArray)(TacoInput.stringInput()()(Data_Eq.eqString)({
            value: Data_Maybe.maybe("")(Types_JSDateString.extractJSDateString)(props.lowerDateLimit),
            type: "date",
            clickEventHandling: TacoInput.NoHandling.value,
            required: true,
            onChange: TacoInput.changeHandler(TacoInput.inputValueString)(function (value) {
                return props.setLowerDateLimit(Data_Either.hush(Types_JSDateString.makeJSDateString(value)));
            })
        }))
    });
    return Control_Applicative.pure(Effect.applicativeEffect)(Controls.component()()({
        leftControls: [ searchField, lowerDateLimitInput, upperDateLimitInput, searchByCostGroupCode ],
        rightControls: [ typeFilters ],
        gap: true
    }));
});
module.exports = {
    invoiceFilterControls: invoiceFilterControls
};
