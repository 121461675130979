import {
  Avatar,
  Badge,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { HubspotArticle } from "../../../server-ts/notifications/hubspot";

type NotificationsProps = {
  releaseNotes: Array<HubspotArticle>;
  readReleaseNoteIds: Array<number>;
};

export const Notifications = ({
  readReleaseNoteIds,
  releaseNotes,
}: NotificationsProps) => {
  return (
    <List className="flex-1 max-w-3xl overflow-y-auto">
      {releaseNotes.map((releaseNote) => (
        <ListItem key={releaseNote.id}>
          <ListItemButton href={releaseNote.url} target="_blank">
            <ListItemAvatar>
              <Badge
                color="primary"
                variant="dot"
                invisible={readReleaseNoteIds.includes(releaseNote.id)}
              >
                {releaseNote.featuredImageUrl && (
                  <Avatar>
                    <img
                      src={releaseNote.featuredImageUrl}
                      alt={"article-image"}
                    />
                  </Avatar>
                )}
              </Badge>
            </ListItemAvatar>
            <ListItemText
              primary={
                <span dangerouslySetInnerHTML={{ __html: releaseNote.title }} />
              }
              secondary={
                <span
                  dangerouslySetInnerHTML={{
                    __html: releaseNote.description,
                  }}
                />
              }
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};
