// Generated by purs version 0.14.5
"use strict";
var Utils = require("../Utils/index.js");
var mkResultMarginPercentages = function (v) {
    return function (v1) {
        return {
            name: "Kate-%",
            target: Utils.defaultZeroDiv(v1.target)(v.totals.target),
            estimate: Utils.defaultZeroDiv(v1.estimate)(v.totals.estimate),
            actual: Utils.defaultZeroDiv(v1.actual)(v.totals.actual)
        };
    };
};
var mkR200ResultMargin = function (incomeResults) {
    return function (r200CostGroupResults) {
        return {
            name: "Yhteens\xe4",
            target: incomeResults.totals.target - r200CostGroupResults.total.target,
            estimate: incomeResults.totals.estimate - r200CostGroupResults.total.estimate,
            actual: incomeResults.totals.actual - r200CostGroupResults.total.actual
        };
    };
};
module.exports = {
    mkR200ResultMargin: mkR200ResultMargin,
    mkResultMarginPercentages: mkResultMarginPercentages
};
