// Generated by purs version 0.14.5
"use strict";
var ComponentsViewUtils = require("../ComponentsViewUtils/index.js");
var CostEstimation_BuildingElementsView_Types = require("../CostEstimation.BuildingElementsView.Types/index.js");
var CostEstimation_ElementsReferenceView = require("../CostEstimation.ElementsReferenceView/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var ReferenceLibrarySidepanel_Styles = require("../ReferenceLibrarySidepanel.Styles/index.js");
var ReferenceLibrarySidepanel_Types = require("../ReferenceLibrarySidepanel.Types/index.js");
var ReferenceView = require("../ReferenceView/index.js");
var ResourcePriceListView = require("../ResourcePriceListView/index.js");
var StateHooks = require("../StateHooks/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var useActiveRefMode = function __do() {
    var v = TofuHooks.useState(Data_Maybe.Nothing.value)();
    var hasActiveRefMode = Data_Maybe.isJust(v.value0);
    return {
        activeRefMode: v.value0,
        setActiveRefMode: v.value1,
        hasActiveRefMode: hasActiveRefMode
    };
};
var component = TofuHooks.mkMemoHookComponent("ReferenceLibrarySidepanel")(function (props) {
    return function __do() {
        var updateStatuses = StateHooks.useSelector(function (v) {
            return v.updateStatuses;
        })();
        var selectedEditViewElementId = Data_Functor.map(Data_Maybe.functorMaybe)(CostEstimation_BuildingElementsView_Types["_elementId"])(props.selectedEditViewElement);
        var selectedEditViewComponentId = Data_Functor.map(Data_Maybe.functorMaybe)(ComponentsViewUtils.ecwrComponentId)(props.selectedEditViewComponent);
        var contents = (function () {
            if (props.activeRefMode instanceof Data_Maybe.Just && props.activeRefMode.value0 instanceof ReferenceLibrarySidepanel_Types.BuildingElementsReferenceMode) {
                return CostEstimation_ElementsReferenceView.component({
                    projectId: props.projectId,
                    project: props.project,
                    initialSelectedElement: Data_Maybe.Nothing.value,
                    panelOptions: new Data_Maybe.Just({
                        selectedEditViewElementId: selectedEditViewElementId,
                        selectedEditViewElement: props.selectedEditViewElement,
                        selectedEditViewElementSpecId: props.selectedEditViewElementSpecId,
                        selectedEditViewElementMeasures: props.selectedEditViewElementMeasures,
                        selectedEditViewComponentId: selectedEditViewComponentId,
                        setSelectedEditViewElement: Data_Maybe.Nothing.value
                    })
                });
            };
            if (props.activeRefMode instanceof Data_Maybe.Just && props.activeRefMode.value0 instanceof ReferenceLibrarySidepanel_Types.ComponentsReferenceMode) {
                return ReferenceView.component({
                    projectId: props.projectId,
                    project: props.project,
                    initialSelectedComponent: Data_Maybe.Nothing.value,
                    panelOptions: new Data_Maybe.Just({
                        selectedEditViewComponentId: selectedEditViewComponentId,
                        selectedEditViewElementSpecId: props.selectedEditViewElementSpecId,
                        selectedEditViewComponent: props.selectedEditViewComponent,
                        selectedEditViewElementId: selectedEditViewElementId,
                        selectedEditViewElement: props.selectedEditViewElement
                    }),
                    updateStatuses: updateStatuses
                });
            };
            if (props.activeRefMode instanceof Data_Maybe.Just && props.activeRefMode.value0 instanceof ReferenceLibrarySidepanel_Types.ResourcesReferenceMode) {
                return ResourcePriceListView.component({
                    projectId: props.projectId,
                    project: props.project,
                    panelOptions: new Data_Maybe.Just({
                        selectedEditViewComponentId: Data_Functor.map(Data_Maybe.functorMaybe)(ComponentsViewUtils.ecwrComponentId)(props.selectedEditViewComponent),
                        selectedEditViewElementId: selectedEditViewElementId,
                        selectedEditViewElementSpecId: props.selectedEditViewElementSpecId,
                        selectedEditViewResourceIds: props.selectedEditViewResourceIds,
                        selectedEditViewResource: props.selectedEditViewResource
                    })
                });
            };
            if (props.activeRefMode instanceof Data_Maybe.Nothing) {
                return React_Basic.empty;
            };
            throw new Error("Failed pattern match at ReferenceLibrarySidepanel (line 71, column 16 - line 111, column 26): " + [ props.activeRefMode.constructor.name ]);
        })();
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            children: [ contents ],
            className: "__ReferenceLibrarySidepanel",
            css: ReferenceLibrarySidepanel_Styles.referenceLibrarySidepanelStyles
        });
    };
});
module.exports = {
    useActiveRefMode: useActiveRefMode,
    component: component
};
