// Generated by purs version 0.14.5
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Database_Postgres_SqlValue = require("../Database.Postgres.SqlValue/index.js");
var Kishimen = require("../Kishimen/index.js");
var ReadParam = require("../ReadParam/index.js");
var Simple_JSON = require("../Simple.JSON/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var WriteCostGroupTargetAllowed = (function () {
    function WriteCostGroupTargetAllowed() {

    };
    WriteCostGroupTargetAllowed.value = new WriteCostGroupTargetAllowed();
    return WriteCostGroupTargetAllowed;
})();
var WriteCostGroupTargetDisallowed = (function () {
    function WriteCostGroupTargetDisallowed() {

    };
    WriteCostGroupTargetDisallowed.value = new WriteCostGroupTargetDisallowed();
    return WriteCostGroupTargetDisallowed;
})();
var WriteAllProjectsAllowed = (function () {
    function WriteAllProjectsAllowed() {

    };
    WriteAllProjectsAllowed.value = new WriteAllProjectsAllowed();
    return WriteAllProjectsAllowed;
})();
var WriteAllProjectsDisallowed = (function () {
    function WriteAllProjectsDisallowed() {

    };
    WriteAllProjectsDisallowed.value = new WriteAllProjectsDisallowed();
    return WriteAllProjectsDisallowed;
})();
var UnsafeVerifiedOrganizationAdmin = (function () {
    function UnsafeVerifiedOrganizationAdmin() {

    };
    UnsafeVerifiedOrganizationAdmin.value = new UnsafeVerifiedOrganizationAdmin();
    return UnsafeVerifiedOrganizationAdmin;
})();
var UserRoleId = function (x) {
    return x;
};
var UserRole = function (x) {
    return x;
};
var ReadAllProjectsAllowed = (function () {
    function ReadAllProjectsAllowed() {

    };
    ReadAllProjectsAllowed.value = new ReadAllProjectsAllowed();
    return ReadAllProjectsAllowed;
})();
var ReadAllProjectsDisallowed = (function () {
    function ReadAllProjectsDisallowed() {

    };
    ReadAllProjectsDisallowed.value = new ReadAllProjectsDisallowed();
    return ReadAllProjectsDisallowed;
})();
var CreateProjectsRole = (function () {
    function CreateProjectsRole() {

    };
    CreateProjectsRole.value = new CreateProjectsRole();
    return CreateProjectsRole;
})();
var AdminRole = (function () {
    function AdminRole() {

    };
    AdminRole.value = new AdminRole();
    return AdminRole;
})();
var CostControlRole = (function () {
    function CostControlRole() {

    };
    CostControlRole.value = new CostControlRole();
    return CostControlRole;
})();
var CostGroupTargetWriteRole = (function () {
    function CostGroupTargetWriteRole() {

    };
    CostGroupTargetWriteRole.value = new CostGroupTargetWriteRole();
    return CostGroupTargetWriteRole;
})();
var CCReadRole = (function () {
    function CCReadRole() {

    };
    CCReadRole.value = new CCReadRole();
    return CCReadRole;
})();
var CCWriteRole = (function () {
    function CCWriteRole() {

    };
    CCWriteRole.value = new CCWriteRole();
    return CCWriteRole;
})();
var CCReadAllProjectsRole = (function () {
    function CCReadAllProjectsRole() {

    };
    CCReadAllProjectsRole.value = new CCReadAllProjectsRole();
    return CCReadAllProjectsRole;
})();
var CCWriteAllProjectsRole = (function () {
    function CCWriteAllProjectsRole() {

    };
    CCWriteAllProjectsRole.value = new CCWriteAllProjectsRole();
    return CCWriteAllProjectsRole;
})();
var CostEstimationRole = (function () {
    function CostEstimationRole() {

    };
    CostEstimationRole.value = new CostEstimationRole();
    return CostEstimationRole;
})();
var CEReadRole = (function () {
    function CEReadRole() {

    };
    CEReadRole.value = new CEReadRole();
    return CEReadRole;
})();
var CEWriteRole = (function () {
    function CEWriteRole() {

    };
    CEWriteRole.value = new CEWriteRole();
    return CEWriteRole;
})();
var CEReadAllProjectsRole = (function () {
    function CEReadAllProjectsRole() {

    };
    CEReadAllProjectsRole.value = new CEReadAllProjectsRole();
    return CEReadAllProjectsRole;
})();
var CEWriteAllProjectsRole = (function () {
    function CEWriteAllProjectsRole() {

    };
    CEWriteAllProjectsRole.value = new CEWriteAllProjectsRole();
    return CEWriteAllProjectsRole;
})();
var ProjectWriteAllowed = (function () {
    function ProjectWriteAllowed() {

    };
    ProjectWriteAllowed.value = new ProjectWriteAllowed();
    return ProjectWriteAllowed;
})();
var ProjectWriteDisallowed = (function () {
    function ProjectWriteDisallowed() {

    };
    ProjectWriteDisallowed.value = new ProjectWriteDisallowed();
    return ProjectWriteDisallowed;
})();
var ProjectReadAllowed = (function () {
    function ProjectReadAllowed() {

    };
    ProjectReadAllowed.value = new ProjectReadAllowed();
    return ProjectReadAllowed;
})();
var ProjectReadDisallowed = (function () {
    function ProjectReadDisallowed() {

    };
    ProjectReadDisallowed.value = new ProjectReadDisallowed();
    return ProjectReadDisallowed;
})();
var IsOrganizationAdmin = (function () {
    function IsOrganizationAdmin() {

    };
    IsOrganizationAdmin.value = new IsOrganizationAdmin();
    return IsOrganizationAdmin;
})();
var NotOrganizationAdmin = (function () {
    function NotOrganizationAdmin() {

    };
    NotOrganizationAdmin.value = new NotOrganizationAdmin();
    return NotOrganizationAdmin;
})();
var CreateProjectsAllowed = (function () {
    function CreateProjectsAllowed() {

    };
    CreateProjectsAllowed.value = new CreateProjectsAllowed();
    return CreateProjectsAllowed;
})();
var CreateProjectsDisallowed = (function () {
    function CreateProjectsDisallowed() {

    };
    CreateProjectsDisallowed.value = new CreateProjectsDisallowed();
    return CreateProjectsDisallowed;
})();
var UsingCostEstimationAllowed = (function () {
    function UsingCostEstimationAllowed() {

    };
    UsingCostEstimationAllowed.value = new UsingCostEstimationAllowed();
    return UsingCostEstimationAllowed;
})();
var UsingCostEstimationDisallowed = (function () {
    function UsingCostEstimationDisallowed() {

    };
    UsingCostEstimationDisallowed.value = new UsingCostEstimationDisallowed();
    return UsingCostEstimationDisallowed;
})();
var UsingCostControlAllowed = (function () {
    function UsingCostControlAllowed() {

    };
    UsingCostControlAllowed.value = new UsingCostControlAllowed();
    return UsingCostControlAllowed;
})();
var UsingCostControlDisallowed = (function () {
    function UsingCostControlDisallowed() {

    };
    UsingCostControlDisallowed.value = new UsingCostControlDisallowed();
    return UsingCostControlDisallowed;
})();
var UnsafeCanWriteCostGroupTarget = (function () {
    function UnsafeCanWriteCostGroupTarget() {

    };
    UnsafeCanWriteCostGroupTarget.value = new UnsafeCanWriteCostGroupTarget();
    return UnsafeCanWriteCostGroupTarget;
})();
var UnsafeCanUseCostEstimation = (function () {
    function UnsafeCanUseCostEstimation() {

    };
    UnsafeCanUseCostEstimation.value = new UnsafeCanUseCostEstimation();
    return UnsafeCanUseCostEstimation;
})();
var UnsafeCanUseCostControl = (function () {
    function UnsafeCanUseCostControl() {

    };
    UnsafeCanUseCostControl.value = new UnsafeCanUseCostControl();
    return UnsafeCanUseCostControl;
})();
var UnsafeCanUseAnyApp = (function () {
    function UnsafeCanUseAnyApp() {

    };
    UnsafeCanUseAnyApp.value = new UnsafeCanUseAnyApp();
    return UnsafeCanUseAnyApp;
})();
var UnsafeCanCreateProject = (function () {
    function UnsafeCanCreateProject() {

    };
    UnsafeCanCreateProject.value = new UnsafeCanCreateProject();
    return UnsafeCanCreateProject;
})();
var UnsafeCECanWriteProject = (function () {
    function UnsafeCECanWriteProject(value0) {
        this.value0 = value0;
    };
    UnsafeCECanWriteProject.create = function (value0) {
        return new UnsafeCECanWriteProject(value0);
    };
    return UnsafeCECanWriteProject;
})();
var UnsafeCECanWriteAllProjects = (function () {
    function UnsafeCECanWriteAllProjects() {

    };
    UnsafeCECanWriteAllProjects.value = new UnsafeCECanWriteAllProjects();
    return UnsafeCECanWriteAllProjects;
})();
var UnsafeCECanReadProject = (function () {
    function UnsafeCECanReadProject(value0) {
        this.value0 = value0;
    };
    UnsafeCECanReadProject.create = function (value0) {
        return new UnsafeCECanReadProject(value0);
    };
    return UnsafeCECanReadProject;
})();
var UnsafeCECanReadAllProjects = (function () {
    function UnsafeCECanReadAllProjects() {

    };
    UnsafeCECanReadAllProjects.value = new UnsafeCECanReadAllProjects();
    return UnsafeCECanReadAllProjects;
})();
var UnsafeCCCanWriteProject = (function () {
    function UnsafeCCCanWriteProject(value0) {
        this.value0 = value0;
    };
    UnsafeCCCanWriteProject.create = function (value0) {
        return new UnsafeCCCanWriteProject(value0);
    };
    return UnsafeCCCanWriteProject;
})();
var UnsafeCCCanWriteAllProjects = (function () {
    function UnsafeCCCanWriteAllProjects() {

    };
    UnsafeCCCanWriteAllProjects.value = new UnsafeCCCanWriteAllProjects();
    return UnsafeCCCanWriteAllProjects;
})();
var UnsafeCCCanReadProject = (function () {
    function UnsafeCCCanReadProject(value0) {
        this.value0 = value0;
    };
    UnsafeCCCanReadProject.create = function (value0) {
        return new UnsafeCCCanReadProject(value0);
    };
    return UnsafeCCCanReadProject;
})();
var UnsafeCCCanReadAllProjects = (function () {
    function UnsafeCCCanReadAllProjects() {

    };
    UnsafeCCCanReadAllProjects.value = new UnsafeCCCanReadAllProjects();
    return UnsafeCCCanReadAllProjects;
})();
var UnsafeAnyCanWriteProject = (function () {
    function UnsafeAnyCanWriteProject(value0) {
        this.value0 = value0;
    };
    UnsafeAnyCanWriteProject.create = function (value0) {
        return new UnsafeAnyCanWriteProject(value0);
    };
    return UnsafeAnyCanWriteProject;
})();
var UnsafeAnyCanWriteAllProjects = (function () {
    function UnsafeAnyCanWriteAllProjects() {

    };
    UnsafeAnyCanWriteAllProjects.value = new UnsafeAnyCanWriteAllProjects();
    return UnsafeAnyCanWriteAllProjects;
})();
var UnsafeAnyCanReadProject = (function () {
    function UnsafeAnyCanReadProject(value0) {
        this.value0 = value0;
    };
    UnsafeAnyCanReadProject.create = function (value0) {
        return new UnsafeAnyCanReadProject(value0);
    };
    return UnsafeAnyCanReadProject;
})();
var UnsafeAnyCanReadAllProjects = (function () {
    function UnsafeAnyCanReadAllProjects() {

    };
    UnsafeAnyCanReadAllProjects.value = new UnsafeAnyCanReadAllProjects();
    return UnsafeAnyCanReadAllProjects;
})();
var AccessError = (function () {
    function AccessError(value0) {
        this.value0 = value0;
    };
    AccessError.create = function (value0) {
        return new AccessError(value0);
    };
    return AccessError;
})();
var writeUserRoleId = ReadParam.writeParamInt;
var writeCostGroupTargetPermissionGeneric = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return WriteCostGroupTargetAllowed.value;
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return WriteCostGroupTargetDisallowed.value;
        };
        throw new Error("Failed pattern match at Roles.Types (line 222, column 1 - line 222, column 98): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof WriteCostGroupTargetAllowed) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof WriteCostGroupTargetDisallowed) {
            return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
        };
        throw new Error("Failed pattern match at Roles.Types (line 222, column 1 - line 222, column 98): " + [ x.constructor.name ]);
    }
};
var writeCostGroupTargetPermissionEq = {
    eq: function (x) {
        return function (y) {
            if (x instanceof WriteCostGroupTargetAllowed && y instanceof WriteCostGroupTargetAllowed) {
                return true;
            };
            if (x instanceof WriteCostGroupTargetDisallowed && y instanceof WriteCostGroupTargetDisallowed) {
                return true;
            };
            return false;
        };
    }
};
var writeCostGroupTargetPermissionOrd = {
    compare: function (x) {
        return function (y) {
            if (x instanceof WriteCostGroupTargetAllowed && y instanceof WriteCostGroupTargetAllowed) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof WriteCostGroupTargetAllowed) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof WriteCostGroupTargetAllowed) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof WriteCostGroupTargetDisallowed && y instanceof WriteCostGroupTargetDisallowed) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at Roles.Types (line 221, column 1 - line 221, column 88): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return writeCostGroupTargetPermissionEq;
    }
};
var writeAllProjectsPermissionGeneric = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return WriteAllProjectsAllowed.value;
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return WriteAllProjectsDisallowed.value;
        };
        throw new Error("Failed pattern match at Roles.Types (line 236, column 1 - line 236, column 90): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof WriteAllProjectsAllowed) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof WriteAllProjectsDisallowed) {
            return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
        };
        throw new Error("Failed pattern match at Roles.Types (line 236, column 1 - line 236, column 90): " + [ x.constructor.name ]);
    }
};
var writeAllProjectsPermissionEq = {
    eq: function (x) {
        return function (y) {
            if (x instanceof WriteAllProjectsAllowed && y instanceof WriteAllProjectsAllowed) {
                return true;
            };
            if (x instanceof WriteAllProjectsDisallowed && y instanceof WriteAllProjectsDisallowed) {
                return true;
            };
            return false;
        };
    }
};
var writeAllProjectsPermissionOrd = {
    compare: function (x) {
        return function (y) {
            if (x instanceof WriteAllProjectsAllowed && y instanceof WriteAllProjectsAllowed) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof WriteAllProjectsAllowed) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof WriteAllProjectsAllowed) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof WriteAllProjectsDisallowed && y instanceof WriteAllProjectsDisallowed) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at Roles.Types (line 235, column 1 - line 235, column 80): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return writeAllProjectsPermissionEq;
    }
};
var userRoleIdWriteForeign = Simple_JSON.writeForeignInt;
var userRoleIdReadForeign = Simple_JSON.readInt;
var userRoleGeneric = {
    to: function (x) {
        return x;
    },
    from: function (x) {
        return x;
    }
};
var userRoleEq = {
    eq: function (x) {
        return function (y) {
            return x.ccReadAllProjectsPermission === y.ccReadAllProjectsPermission && x.ccReadPermission === y.ccReadPermission && x.ccWriteAllProjectsPermission === y.ccWriteAllProjectsPermission && x.ccWritePermission === y.ccWritePermission && x.ceReadAllProjectsPermission === y.ceReadAllProjectsPermission && x.ceReadPermission === y.ceReadPermission && x.ceWriteAllProjectsPermission === y.ceWriteAllProjectsPermission && x.ceWritePermission === y.ceWritePermission && x.costControlPermission === y.costControlPermission && x.costEstimationPermission === y.costEstimationPermission && x.costGroupTargetWritePermission === y.costGroupTargetWritePermission && x.createProjectsPermission === y.createProjectsPermission && x.isAdmin === y.isAdmin && x.name === y.name;
        };
    }
};
var tofuJSONWriteCostGroupTargetPermission = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "WriteCostGroupTargetAllowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "WriteCostGroupTargetDisallowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "WriteCostGroupTargetAllowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "WriteCostGroupTargetDisallowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))(writeCostGroupTargetPermissionGeneric)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "WriteCostGroupTargetAllowed";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "WriteCostGroupTargetDisallowed";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "WriteCostGroupTargetAllowed";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "WriteCostGroupTargetDisallowed";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(writeCostGroupTargetPermissionGeneric)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "WriteCostGroupTargetAllowed";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "WriteCostGroupTargetDisallowed";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "WriteCostGroupTargetAllowed";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "WriteCostGroupTargetDisallowed";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "WriteCostGroupTargetAllowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "WriteCostGroupTargetDisallowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "WriteCostGroupTargetAllowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "WriteCostGroupTargetDisallowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))
};
var tofuJSONWriteAllProjectsPermission = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "WriteAllProjectsAllowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "WriteAllProjectsDisallowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "WriteAllProjectsAllowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "WriteAllProjectsDisallowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))(writeAllProjectsPermissionGeneric)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "WriteAllProjectsAllowed";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "WriteAllProjectsDisallowed";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "WriteAllProjectsAllowed";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "WriteAllProjectsDisallowed";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(writeAllProjectsPermissionGeneric)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "WriteAllProjectsAllowed";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "WriteAllProjectsDisallowed";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "WriteAllProjectsAllowed";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "WriteAllProjectsDisallowed";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "WriteAllProjectsAllowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "WriteAllProjectsDisallowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "WriteAllProjectsAllowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "WriteAllProjectsDisallowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))
};
var tofuJSONUserRoleId = TofuJSON.readInt;
var tofuJSONUserRole = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UserRole";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "ccReadAllProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "ccReadPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "ccWriteAllProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "ccWritePermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "ceReadAllProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "ceReadPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "ceWriteAllProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "ceWritePermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costControlPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costEstimationPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costGroupTargetWritePermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "createProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "isAdmin";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "ccReadAllProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "ccReadPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "ccWriteAllProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "ccWritePermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "ceReadAllProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "ceReadPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "ceWriteAllProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "ceWritePermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costControlPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costEstimationPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costGroupTargetWritePermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "createProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "isAdmin";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))()(TofuJSON.readVariantNil))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UserRole";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "ccReadAllProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "ccReadPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "ccWriteAllProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "ccWritePermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "ceReadAllProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "ceReadPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "ceWriteAllProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "ceWritePermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costControlPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costEstimationPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costGroupTargetWritePermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "createProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "isAdmin";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "ccReadAllProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "ccReadPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "ccWriteAllProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "ccWritePermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "ceReadAllProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "ceReadPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "ceWriteAllProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "ceWritePermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costControlPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costEstimationPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costGroupTargetWritePermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "createProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "isAdmin";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))()(TofuJSON.nilWriteForeignVariant))(userRoleGeneric)(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UserRole";
        }
    })()(Kishimen.genericSumToVariantArgArguments)),
    readImpl: TofuJSON.readGenericSum(userRoleGeneric)(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UserRole";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UserRole";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "ccReadAllProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "ccReadPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "ccWriteAllProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "ccWritePermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "ceReadAllProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "ceReadPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "ceWriteAllProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "ceWritePermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costControlPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costEstimationPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costGroupTargetWritePermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "createProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "isAdmin";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "ccReadAllProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "ccReadPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "ccWriteAllProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "ccWritePermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "ceReadAllProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "ceReadPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "ceWriteAllProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "ceWritePermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costControlPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costEstimationPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costGroupTargetWritePermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "createProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "isAdmin";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))()(TofuJSON.readVariantNil))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UserRole";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "ccReadAllProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "ccReadPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "ccWriteAllProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "ccWritePermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "ceReadAllProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "ceReadPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "ceWriteAllProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "ceWritePermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costControlPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costEstimationPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "costGroupTargetWritePermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "createProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "isAdmin";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())()())()())()())()())()())()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "ccReadAllProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "ccReadPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "ccWriteAllProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "ccWritePermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "ceReadAllProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "ceReadPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "ceWriteAllProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "ceWritePermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costControlPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costEstimationPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "costGroupTargetWritePermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "createProjectsPermission";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "isAdmin";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()())()()()))()(TofuJSON.nilWriteForeignVariant))
};
var toSqlUserRoleId = Database_Postgres_SqlValue.isSqlValueInt;
var readUserRoleId = ReadParam.intReadParam;
var readAllProjectsPermissionGeneric = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return ReadAllProjectsAllowed.value;
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return ReadAllProjectsDisallowed.value;
        };
        throw new Error("Failed pattern match at Roles.Types (line 208, column 1 - line 208, column 88): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof ReadAllProjectsAllowed) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof ReadAllProjectsDisallowed) {
            return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
        };
        throw new Error("Failed pattern match at Roles.Types (line 208, column 1 - line 208, column 88): " + [ x.constructor.name ]);
    }
};
var tofuJSONReadAllProjectsPermission = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ReadAllProjectsAllowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ReadAllProjectsDisallowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ReadAllProjectsAllowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ReadAllProjectsDisallowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))(readAllProjectsPermissionGeneric)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ReadAllProjectsAllowed";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ReadAllProjectsDisallowed";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ReadAllProjectsAllowed";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ReadAllProjectsDisallowed";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(readAllProjectsPermissionGeneric)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ReadAllProjectsAllowed";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ReadAllProjectsDisallowed";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ReadAllProjectsAllowed";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ReadAllProjectsDisallowed";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ReadAllProjectsAllowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ReadAllProjectsDisallowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ReadAllProjectsAllowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ReadAllProjectsDisallowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))
};
var readAllProjectsPermissionEq = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ReadAllProjectsAllowed && y instanceof ReadAllProjectsAllowed) {
                return true;
            };
            if (x instanceof ReadAllProjectsDisallowed && y instanceof ReadAllProjectsDisallowed) {
                return true;
            };
            return false;
        };
    }
};
var readAllProjectsPermissionOrd = {
    compare: function (x) {
        return function (y) {
            if (x instanceof ReadAllProjectsAllowed && y instanceof ReadAllProjectsAllowed) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ReadAllProjectsAllowed) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ReadAllProjectsAllowed) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ReadAllProjectsDisallowed && y instanceof ReadAllProjectsDisallowed) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at Roles.Types (line 207, column 1 - line 207, column 78): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return readAllProjectsPermissionEq;
    }
};
var projectwritePermissionGeneric = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return ProjectWriteAllowed.value;
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return ProjectWriteDisallowed.value;
        };
        throw new Error("Failed pattern match at Roles.Types (line 201, column 1 - line 201, column 82): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof ProjectWriteAllowed) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof ProjectWriteDisallowed) {
            return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
        };
        throw new Error("Failed pattern match at Roles.Types (line 201, column 1 - line 201, column 82): " + [ x.constructor.name ]);
    }
};
var tofuJSONProjectWritePermission = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ProjectWriteAllowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ProjectWriteDisallowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ProjectWriteAllowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ProjectWriteDisallowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))(projectwritePermissionGeneric)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ProjectWriteAllowed";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ProjectWriteDisallowed";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ProjectWriteAllowed";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ProjectWriteDisallowed";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(projectwritePermissionGeneric)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ProjectWriteAllowed";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ProjectWriteDisallowed";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ProjectWriteAllowed";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ProjectWriteDisallowed";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ProjectWriteAllowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ProjectWriteDisallowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ProjectWriteAllowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ProjectWriteDisallowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))
};
var projectWritePermissionEq = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ProjectWriteAllowed && y instanceof ProjectWriteAllowed) {
                return true;
            };
            if (x instanceof ProjectWriteDisallowed && y instanceof ProjectWriteDisallowed) {
                return true;
            };
            return false;
        };
    }
};
var projectWritePermissionOrd = {
    compare: function (x) {
        return function (y) {
            if (x instanceof ProjectWriteAllowed && y instanceof ProjectWriteAllowed) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ProjectWriteAllowed) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ProjectWriteAllowed) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ProjectWriteDisallowed && y instanceof ProjectWriteDisallowed) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at Roles.Types (line 200, column 1 - line 200, column 72): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return projectWritePermissionEq;
    }
};
var projectReadPermissionGeneric = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return ProjectReadAllowed.value;
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return ProjectReadDisallowed.value;
        };
        throw new Error("Failed pattern match at Roles.Types (line 194, column 1 - line 194, column 80): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof ProjectReadAllowed) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof ProjectReadDisallowed) {
            return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
        };
        throw new Error("Failed pattern match at Roles.Types (line 194, column 1 - line 194, column 80): " + [ x.constructor.name ]);
    }
};
var tofuJSONProjectReadPermission = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ProjectReadAllowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ProjectReadDisallowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ProjectReadAllowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ProjectReadDisallowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))(projectReadPermissionGeneric)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ProjectReadAllowed";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ProjectReadDisallowed";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ProjectReadAllowed";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ProjectReadDisallowed";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(projectReadPermissionGeneric)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ProjectReadAllowed";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "ProjectReadDisallowed";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ProjectReadAllowed";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "ProjectReadDisallowed";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ProjectReadAllowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "ProjectReadDisallowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ProjectReadAllowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "ProjectReadDisallowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))
};
var projectReadPermissionEq = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ProjectReadAllowed && y instanceof ProjectReadAllowed) {
                return true;
            };
            if (x instanceof ProjectReadDisallowed && y instanceof ProjectReadDisallowed) {
                return true;
            };
            return false;
        };
    }
};
var projectReadPermissionOrd = {
    compare: function (x) {
        return function (y) {
            if (x instanceof ProjectReadAllowed && y instanceof ProjectReadAllowed) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ProjectReadAllowed) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ProjectReadAllowed) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ProjectReadDisallowed && y instanceof ProjectReadDisallowed) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at Roles.Types (line 193, column 1 - line 193, column 70): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return projectReadPermissionEq;
    }
};
var ordUserRoleId = Data_Ord.ordInt;
var newtypeUserRole = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeRoleId = {
    Coercible0: function () {
        return undefined;
    }
};
var isOrganizationAdminGeneric = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return IsOrganizationAdmin.value;
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return NotOrganizationAdmin.value;
        };
        throw new Error("Failed pattern match at Roles.Types (line 250, column 1 - line 250, column 76): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof IsOrganizationAdmin) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof NotOrganizationAdmin) {
            return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
        };
        throw new Error("Failed pattern match at Roles.Types (line 250, column 1 - line 250, column 76): " + [ x.constructor.name ]);
    }
};
var tofuJSONIsOrganizationAdmin = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "IsOrganizationAdmin";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "NotOrganizationAdmin";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "IsOrganizationAdmin";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "NotOrganizationAdmin";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))(isOrganizationAdminGeneric)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "IsOrganizationAdmin";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "NotOrganizationAdmin";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "IsOrganizationAdmin";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "NotOrganizationAdmin";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(isOrganizationAdminGeneric)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "IsOrganizationAdmin";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "NotOrganizationAdmin";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "IsOrganizationAdmin";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "NotOrganizationAdmin";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "IsOrganizationAdmin";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "NotOrganizationAdmin";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "IsOrganizationAdmin";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "NotOrganizationAdmin";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))
};
var isOrganizationAdminEq = {
    eq: function (x) {
        return function (y) {
            if (x instanceof IsOrganizationAdmin && y instanceof IsOrganizationAdmin) {
                return true;
            };
            if (x instanceof NotOrganizationAdmin && y instanceof NotOrganizationAdmin) {
                return true;
            };
            return false;
        };
    }
};
var isOrganizationAdminOrd = {
    compare: function (x) {
        return function (y) {
            if (x instanceof IsOrganizationAdmin && y instanceof IsOrganizationAdmin) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof IsOrganizationAdmin) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof IsOrganizationAdmin) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof NotOrganizationAdmin && y instanceof NotOrganizationAdmin) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at Roles.Types (line 249, column 1 - line 249, column 66): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return isOrganizationAdminEq;
    }
};
var eqUserRoleId = Data_Eq.eqInt;
var createProjectsPermissionGeneric = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return CreateProjectsAllowed.value;
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return CreateProjectsDisallowed.value;
        };
        throw new Error("Failed pattern match at Roles.Types (line 243, column 1 - line 243, column 86): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof CreateProjectsAllowed) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof CreateProjectsDisallowed) {
            return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
        };
        throw new Error("Failed pattern match at Roles.Types (line 243, column 1 - line 243, column 86): " + [ x.constructor.name ]);
    }
};
var tofuJSONCreateProjectsPermission = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CreateProjectsAllowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CreateProjectsDisallowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CreateProjectsAllowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CreateProjectsDisallowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))(createProjectsPermissionGeneric)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CreateProjectsAllowed";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CreateProjectsDisallowed";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CreateProjectsAllowed";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CreateProjectsDisallowed";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(createProjectsPermissionGeneric)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CreateProjectsAllowed";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "CreateProjectsDisallowed";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CreateProjectsAllowed";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "CreateProjectsDisallowed";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CreateProjectsAllowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "CreateProjectsDisallowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CreateProjectsAllowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "CreateProjectsDisallowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))
};
var createProjectsPermissionEq = {
    eq: function (x) {
        return function (y) {
            if (x instanceof CreateProjectsAllowed && y instanceof CreateProjectsAllowed) {
                return true;
            };
            if (x instanceof CreateProjectsDisallowed && y instanceof CreateProjectsDisallowed) {
                return true;
            };
            return false;
        };
    }
};
var createProjectsPermissionOrd = {
    compare: function (x) {
        return function (y) {
            if (x instanceof CreateProjectsAllowed && y instanceof CreateProjectsAllowed) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof CreateProjectsAllowed) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof CreateProjectsAllowed) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof CreateProjectsDisallowed && y instanceof CreateProjectsDisallowed) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at Roles.Types (line 242, column 1 - line 242, column 76): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return createProjectsPermissionEq;
    }
};
var costEstimationPermissionGeneric = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return UsingCostEstimationAllowed.value;
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return UsingCostEstimationDisallowed.value;
        };
        throw new Error("Failed pattern match at Roles.Types (line 229, column 1 - line 229, column 86): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof UsingCostEstimationAllowed) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof UsingCostEstimationDisallowed) {
            return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
        };
        throw new Error("Failed pattern match at Roles.Types (line 229, column 1 - line 229, column 86): " + [ x.constructor.name ]);
    }
};
var tofuJSONCostEstimationPermission = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UsingCostEstimationAllowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UsingCostEstimationDisallowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UsingCostEstimationAllowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UsingCostEstimationDisallowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))(costEstimationPermissionGeneric)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UsingCostEstimationAllowed";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UsingCostEstimationDisallowed";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UsingCostEstimationAllowed";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UsingCostEstimationDisallowed";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(costEstimationPermissionGeneric)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UsingCostEstimationAllowed";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UsingCostEstimationDisallowed";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UsingCostEstimationAllowed";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UsingCostEstimationDisallowed";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UsingCostEstimationAllowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UsingCostEstimationDisallowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UsingCostEstimationAllowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UsingCostEstimationDisallowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))
};
var costEstimationPermissionEq = {
    eq: function (x) {
        return function (y) {
            if (x instanceof UsingCostEstimationAllowed && y instanceof UsingCostEstimationAllowed) {
                return true;
            };
            if (x instanceof UsingCostEstimationDisallowed && y instanceof UsingCostEstimationDisallowed) {
                return true;
            };
            return false;
        };
    }
};
var costEstimationPermissionOrd = {
    compare: function (x) {
        return function (y) {
            if (x instanceof UsingCostEstimationAllowed && y instanceof UsingCostEstimationAllowed) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof UsingCostEstimationAllowed) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof UsingCostEstimationAllowed) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof UsingCostEstimationDisallowed && y instanceof UsingCostEstimationDisallowed) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at Roles.Types (line 228, column 1 - line 228, column 76): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return costEstimationPermissionEq;
    }
};
var costControlPermissionGeneric = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return UsingCostControlAllowed.value;
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return UsingCostControlDisallowed.value;
        };
        throw new Error("Failed pattern match at Roles.Types (line 215, column 1 - line 215, column 80): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof UsingCostControlAllowed) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof UsingCostControlDisallowed) {
            return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
        };
        throw new Error("Failed pattern match at Roles.Types (line 215, column 1 - line 215, column 80): " + [ x.constructor.name ]);
    }
};
var tofuJSONCostControlPermission = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UsingCostControlAllowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UsingCostControlDisallowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UsingCostControlAllowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UsingCostControlDisallowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))(costControlPermissionGeneric)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UsingCostControlAllowed";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UsingCostControlDisallowed";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UsingCostControlAllowed";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UsingCostControlDisallowed";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(costControlPermissionGeneric)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UsingCostControlAllowed";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "UsingCostControlDisallowed";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UsingCostControlAllowed";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "UsingCostControlDisallowed";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UsingCostControlAllowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "UsingCostControlDisallowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UsingCostControlAllowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "UsingCostControlDisallowed";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))
};
var costControlPermissionEq = {
    eq: function (x) {
        return function (y) {
            if (x instanceof UsingCostControlAllowed && y instanceof UsingCostControlAllowed) {
                return true;
            };
            if (x instanceof UsingCostControlDisallowed && y instanceof UsingCostControlDisallowed) {
                return true;
            };
            return false;
        };
    }
};
var costControlPermissionOrd = {
    compare: function (x) {
        return function (y) {
            if (x instanceof UsingCostControlAllowed && y instanceof UsingCostControlAllowed) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof UsingCostControlAllowed) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof UsingCostControlAllowed) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof UsingCostControlDisallowed && y instanceof UsingCostControlDisallowed) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at Roles.Types (line 214, column 1 - line 214, column 70): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return costControlPermissionEq;
    }
};
var convertCanUseCostEstimationToAnyApp = function (v) {
    return UnsafeCanUseAnyApp.value;
};
var convertCanUseCostControlToAnyApp = function (v) {
    return UnsafeCanUseAnyApp.value;
};
var ceCanWriteProject = function (f) {
    return function (v) {
        return f(v.ceProjectWritePerm.value0);
    };
};
var ceCanWriteProject$prime = Data_Function.flip(ceCanWriteProject);
var ceCanReadProject = function (f) {
    return function (v) {
        return f(v.ceProjectReadPerm.value0);
    };
};
var ceCanReadProject$prime = Data_Function.flip(ceCanReadProject);
var ccCanWriteProject = function (f) {
    return function (v) {
        return f(v.ccProjectWritePerm.value0);
    };
};
var ccCanWriteProject$prime = Data_Function.flip(ccCanWriteProject);
var ccCanReadProject = function (f) {
    return function (v) {
        return f(v.ccProjectReadPerm.value0);
    };
};
var ccCanReadProject$prime = Data_Function.flip(ccCanReadProject);
module.exports = {
    ProjectReadAllowed: ProjectReadAllowed,
    ProjectReadDisallowed: ProjectReadDisallowed,
    UnsafeAnyCanReadProject: UnsafeAnyCanReadProject,
    UnsafeCCCanReadProject: UnsafeCCCanReadProject,
    UnsafeCECanReadProject: UnsafeCECanReadProject,
    ProjectWriteAllowed: ProjectWriteAllowed,
    ProjectWriteDisallowed: ProjectWriteDisallowed,
    UnsafeAnyCanWriteProject: UnsafeAnyCanWriteProject,
    UnsafeCCCanWriteProject: UnsafeCCCanWriteProject,
    UnsafeCECanWriteProject: UnsafeCECanWriteProject,
    ReadAllProjectsAllowed: ReadAllProjectsAllowed,
    ReadAllProjectsDisallowed: ReadAllProjectsDisallowed,
    UnsafeAnyCanReadAllProjects: UnsafeAnyCanReadAllProjects,
    UnsafeCCCanReadAllProjects: UnsafeCCCanReadAllProjects,
    UnsafeCECanReadAllProjects: UnsafeCECanReadAllProjects,
    WriteAllProjectsAllowed: WriteAllProjectsAllowed,
    WriteAllProjectsDisallowed: WriteAllProjectsDisallowed,
    UnsafeAnyCanWriteAllProjects: UnsafeAnyCanWriteAllProjects,
    UnsafeCCCanWriteAllProjects: UnsafeCCCanWriteAllProjects,
    UnsafeCECanWriteAllProjects: UnsafeCECanWriteAllProjects,
    UnsafeCanCreateProject: UnsafeCanCreateProject,
    CreateProjectsAllowed: CreateProjectsAllowed,
    CreateProjectsDisallowed: CreateProjectsDisallowed,
    UnsafeCanUseCostControl: UnsafeCanUseCostControl,
    UsingCostControlAllowed: UsingCostControlAllowed,
    UsingCostControlDisallowed: UsingCostControlDisallowed,
    UnsafeCanUseCostEstimation: UnsafeCanUseCostEstimation,
    UsingCostEstimationAllowed: UsingCostEstimationAllowed,
    UsingCostEstimationDisallowed: UsingCostEstimationDisallowed,
    UnsafeCanWriteCostGroupTarget: UnsafeCanWriteCostGroupTarget,
    WriteCostGroupTargetAllowed: WriteCostGroupTargetAllowed,
    WriteCostGroupTargetDisallowed: WriteCostGroupTargetDisallowed,
    UnsafeCanUseAnyApp: UnsafeCanUseAnyApp,
    convertCanUseCostControlToAnyApp: convertCanUseCostControlToAnyApp,
    convertCanUseCostEstimationToAnyApp: convertCanUseCostEstimationToAnyApp,
    IsOrganizationAdmin: IsOrganizationAdmin,
    NotOrganizationAdmin: NotOrganizationAdmin,
    UnsafeVerifiedOrganizationAdmin: UnsafeVerifiedOrganizationAdmin,
    UserRoleId: UserRoleId,
    UserRole: UserRole,
    CreateProjectsRole: CreateProjectsRole,
    AdminRole: AdminRole,
    CostControlRole: CostControlRole,
    CostGroupTargetWriteRole: CostGroupTargetWriteRole,
    CCReadRole: CCReadRole,
    CCWriteRole: CCWriteRole,
    CCReadAllProjectsRole: CCReadAllProjectsRole,
    CCWriteAllProjectsRole: CCWriteAllProjectsRole,
    CostEstimationRole: CostEstimationRole,
    CEReadRole: CEReadRole,
    CEWriteRole: CEWriteRole,
    CEReadAllProjectsRole: CEReadAllProjectsRole,
    CEWriteAllProjectsRole: CEWriteAllProjectsRole,
    AccessError: AccessError,
    ccCanReadProject: ccCanReadProject,
    "ccCanReadProject'": ccCanReadProject$prime,
    ccCanWriteProject: ccCanWriteProject,
    "ccCanWriteProject'": ccCanWriteProject$prime,
    ceCanReadProject: ceCanReadProject,
    "ceCanReadProject'": ceCanReadProject$prime,
    ceCanWriteProject: ceCanWriteProject,
    "ceCanWriteProject'": ceCanWriteProject$prime,
    projectReadPermissionEq: projectReadPermissionEq,
    projectReadPermissionOrd: projectReadPermissionOrd,
    projectReadPermissionGeneric: projectReadPermissionGeneric,
    tofuJSONProjectReadPermission: tofuJSONProjectReadPermission,
    projectWritePermissionEq: projectWritePermissionEq,
    projectWritePermissionOrd: projectWritePermissionOrd,
    projectwritePermissionGeneric: projectwritePermissionGeneric,
    tofuJSONProjectWritePermission: tofuJSONProjectWritePermission,
    readAllProjectsPermissionEq: readAllProjectsPermissionEq,
    readAllProjectsPermissionOrd: readAllProjectsPermissionOrd,
    readAllProjectsPermissionGeneric: readAllProjectsPermissionGeneric,
    tofuJSONReadAllProjectsPermission: tofuJSONReadAllProjectsPermission,
    costControlPermissionEq: costControlPermissionEq,
    costControlPermissionOrd: costControlPermissionOrd,
    costControlPermissionGeneric: costControlPermissionGeneric,
    tofuJSONCostControlPermission: tofuJSONCostControlPermission,
    writeCostGroupTargetPermissionEq: writeCostGroupTargetPermissionEq,
    writeCostGroupTargetPermissionOrd: writeCostGroupTargetPermissionOrd,
    writeCostGroupTargetPermissionGeneric: writeCostGroupTargetPermissionGeneric,
    tofuJSONWriteCostGroupTargetPermission: tofuJSONWriteCostGroupTargetPermission,
    costEstimationPermissionEq: costEstimationPermissionEq,
    costEstimationPermissionOrd: costEstimationPermissionOrd,
    costEstimationPermissionGeneric: costEstimationPermissionGeneric,
    tofuJSONCostEstimationPermission: tofuJSONCostEstimationPermission,
    writeAllProjectsPermissionEq: writeAllProjectsPermissionEq,
    writeAllProjectsPermissionOrd: writeAllProjectsPermissionOrd,
    writeAllProjectsPermissionGeneric: writeAllProjectsPermissionGeneric,
    tofuJSONWriteAllProjectsPermission: tofuJSONWriteAllProjectsPermission,
    createProjectsPermissionEq: createProjectsPermissionEq,
    createProjectsPermissionOrd: createProjectsPermissionOrd,
    createProjectsPermissionGeneric: createProjectsPermissionGeneric,
    tofuJSONCreateProjectsPermission: tofuJSONCreateProjectsPermission,
    isOrganizationAdminEq: isOrganizationAdminEq,
    isOrganizationAdminOrd: isOrganizationAdminOrd,
    isOrganizationAdminGeneric: isOrganizationAdminGeneric,
    tofuJSONIsOrganizationAdmin: tofuJSONIsOrganizationAdmin,
    newtypeUserRole: newtypeUserRole,
    userRoleEq: userRoleEq,
    userRoleGeneric: userRoleGeneric,
    tofuJSONUserRole: tofuJSONUserRole,
    newtypeRoleId: newtypeRoleId,
    eqUserRoleId: eqUserRoleId,
    ordUserRoleId: ordUserRoleId,
    readUserRoleId: readUserRoleId,
    writeUserRoleId: writeUserRoleId,
    userRoleIdWriteForeign: userRoleIdWriteForeign,
    userRoleIdReadForeign: userRoleIdReadForeign,
    tofuJSONUserRoleId: tofuJSONUserRoleId,
    toSqlUserRoleId: toSqlUserRoleId
};
