// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var StateHooks = require("../StateHooks/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_FrontEndRoutes = require("../Types.FrontEndRoutes/index.js");
var UseEffectAlways = require("../UseEffectAlways/index.js");
var component = TofuHooks.mkHookComponent("AppRoot")(function (v) {
    return function __do() {
        var appDispatch = StateHooks.useDispatch();
        UseEffectAlways.useEffectAlways((function () {
            var navAction = (function () {
                if (v.appAccessRights.costControl) {
                    return new Actions.NavigateRoute(Types_FrontEndRoutes.ReportingProjectListRoute.value);
                };
                if (v.appAccessRights.estimation) {
                    return new Actions.NavigateRoute(Types_FrontEndRoutes.EstimationProjectsRoute.value);
                };
                if (v.appAccessRights.integration) {
                    return new Actions.NavigateRoute(Types_FrontEndRoutes.IntegrationFERoute.value);
                };
                if (v.appAccessRights.organization) {
                    return new Actions.NavigateRoute(Types_FrontEndRoutes.OrganizationFERoute.value);
                };
                if (Data_Boolean.otherwise) {
                    return new Actions.NavigateRoute(Types_FrontEndRoutes.ReportingProjectListRoute.value);
                };
                throw new Error("Failed pattern match at AppRoot (line 22, column 7 - line 27, column 70): " + [  ]);
            })();
            return function __do() {
                appDispatch(navAction)();
                return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
            };
        })())();
        return React_Basic.empty;
    };
});
module.exports = {
    component: component
};
