// Generated by purs version 0.14.5
"use strict";
var Components_Utils = require("../Components.Utils/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Category = require("../Control.Category/index.js");
var CostEstimation_Calculations = require("../CostEstimation.Calculations/index.js");
var DOMUtils = require("../DOMUtils/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var Formatters = require("../Formatters/index.js");
var HtmlUtils = require("../HtmlUtils/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var Record = require("../Record/index.js");
var StateHooks = require("../StateHooks/index.js");
var StringUtils = require("../StringUtils/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoInput_Styles = require("../TacoInput.Styles/index.js");
var TacoLoader = require("../TacoLoader/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var Types_JSDateString = require("../Types.JSDateString/index.js");
var UseNullableRef = require("../UseNullableRef/index.js");
var UseUUID = require("../UseUUID/index.js");
var Utils_ClassNames = require("../Utils.ClassNames/index.js");
var Web_DOM_Element = require("../Web.DOM.Element/index.js");
var Web_HTML_HTMLElement = require("../Web.HTML.HTMLElement/index.js");
var Web_HTML_HTMLInputElement = require("../Web.HTML.HTMLInputElement/index.js");
var Valid = (function () {
    function Valid() {

    };
    Valid.value = new Valid();
    return Valid;
})();
var Invalid = (function () {
    function Invalid(value0) {
        this.value0 = value0;
    };
    Invalid.create = function (value0) {
        return new Invalid(value0);
    };
    return Invalid;
})();
var DateInput = (function () {
    function DateInput() {

    };
    DateInput.value = new DateInput();
    return DateInput;
})();
var StringInput = (function () {
    function StringInput() {

    };
    StringInput.value = new StringInput();
    return StringInput;
})();
var NumberInput = (function () {
    function NumberInput() {

    };
    NumberInput.value = new NumberInput();
    return NumberInput;
})();
var NoHandling = (function () {
    function NoHandling() {

    };
    NoHandling.value = new NoHandling();
    return NoHandling;
})();
var PreventDefault = (function () {
    function PreventDefault() {

    };
    PreventDefault.value = new PreventDefault();
    return PreventDefault;
})();
var StopPropagation = (function () {
    function StopPropagation() {

    };
    StopPropagation.value = new StopPropagation();
    return StopPropagation;
})();
var Capture = (function () {
    function Capture() {

    };
    Capture.value = new Capture();
    return Capture;
})();
var LeftAlign = (function () {
    function LeftAlign() {

    };
    LeftAlign.value = new LeftAlign();
    return LeftAlign;
})();
var RightAlign = (function () {
    function RightAlign() {

    };
    RightAlign.value = new RightAlign();
    return RightAlign;
})();
var stringify = function (dict) {
    return dict.stringify;
};
var stringInput_ = ReactHooksUtils.createComponent("TacoStringInput");
var remoteStringField_ = ReactHooksUtils.createComponent("TacoInputRemoteStringField");
var remoteNumberField_ = ReactHooksUtils.createComponent("TacoInputRemoteNumberField");
var remoteMultiActionStringField_ = ReactHooksUtils.createComponent("TacoInputMultiAction1RemoteStringField");
var remoteMultiActionNumberField_ = ReactHooksUtils.createComponent("TacoInputMultiAction1RemoteNumberField");
var readStrToPrecisionNumber = function (precision) {
    return function (str) {
        return Data_Functor.map(Data_Maybe.functorMaybe)(CostEstimation_Calculations.toNPrecision(precision))(StringUtils.readStrToNumber(str));
    };
};
var readStrToDate = function ($208) {
    return Data_Either.hush(Types_JSDateString.makeJSDateString($208));
};
var orValueValidity = function (f) {
    return function (g) {
        return function (x) {
            var v = f(x);
            if (v instanceof Valid) {
                return Valid.value;
            };
            if (v instanceof Invalid) {
                return g(x);
            };
            throw new Error("Failed pattern match at TacoInput (line 70, column 3 - line 72, column 21): " + [ v.constructor.name ]);
        };
    };
};
var numberInput_ = ReactHooksUtils.createComponent("TacoNumberInput");
var mkDefaultProps = function (value) {
    return {
        onChange: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit))),
        onSave: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit))),
        onValidationChange: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit))),
        validator: function (v) {
            return Valid.value;
        },
        clickEventHandling: Capture.value,
        onFocus: Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)),
        onBlur: Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)),
        loading: false,
        disabled: false,
        title: "",
        value: value,
        label: "",
        placeholder: "",
        error: false,
        errorMessage: "",
        type: "text",
        testId: "",
        saveButton: false,
        textarea: false,
        expandWhenActive: false,
        stateKey: Data_Show.show(Data_Unit.showUnit)(Data_Unit.unit),
        align: LeftAlign.value,
        precision: 1,
        autoFocus: false,
        required: false,
        clearButton: false,
        onClear: Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)),
        currency: false,
        customComponent: Data_Maybe.Nothing.value,
        customComponentWrapperStyles: [  ],
        rendering: TacoInput_Styles.DefaultRendering.value,
        width: Data_Maybe.Nothing.value,
        height: Data_Maybe.Nothing.value,
        color: Data_Maybe.Nothing.value,
        fontWeight: Data_Maybe.Nothing.value
    };
};
var mkMultiActionRemoteField = function (dictUnion) {
    return function (dictNub) {
        return function (dictEq) {
            return function (remoteFieldHookComponent_) {
                return function (valueInput) {
                    return ReactHooksUtils.make_(remoteFieldHookComponent_)(function (props$prime) {
                        var props = Record.merge()()(props$prime)(mkDefaultProps(props$prime.value));
                        var innerProps = Record["delete"]({
                            reflectSymbol: function () {
                                return "actions";
                            }
                        })()()(Data_Symbol.SProxy.value)(props);
                        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(false))(function (v) {
                            return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(false))(function (v1) {
                                return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(props.value))(function (v2) {
                                    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(false))(function (v3) {
                                        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(Data_Maybe.Nothing.value))(function (v4) {
                                            return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useLayoutEffect(dictEq)(props.value)(function __do() {
                                                v2.value1(function (v5) {
                                                    return props.value;
                                                })();
                                                return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                                            }))(function () {
                                                return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks_Internal.unsafeHook(StateHooks.useDispatch))(function (dispatch) {
                                                    var handleSubmit = function (action) {
                                                        return function (saveValue) {
                                                            var resHandler = function (v5) {
                                                                if (v5 instanceof Data_Either.Left) {
                                                                    return function __do() {
                                                                        v2.value1(function (v6) {
                                                                            return props.value;
                                                                        })();
                                                                        v1.value1(function (v6) {
                                                                            return false;
                                                                        })();
                                                                        return v.value1(function (v6) {
                                                                            return true;
                                                                        })();
                                                                    };
                                                                };
                                                                if (v5 instanceof Data_Either.Right) {
                                                                    return function __do() {
                                                                        v1.value1(function (v6) {
                                                                            return false;
                                                                        })();
                                                                        return v.value1(function (v6) {
                                                                            return false;
                                                                        })();
                                                                    };
                                                                };
                                                                throw new Error("Failed pattern match at TacoInput (line 852, column 22 - line 861, column 35): " + [ v5.constructor.name ]);
                                                            };
                                                            return Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(Data_Eq.notEq(dictEq)(saveValue)(props.value))(function __do() {
                                                                v2.value1(function (v5) {
                                                                    return saveValue;
                                                                })();
                                                                v1.value1(function (v5) {
                                                                    return true;
                                                                })();
                                                                return dispatch(action(saveValue)(resHandler))();
                                                            });
                                                        };
                                                    };
                                                    var onSave = function (saveValue) {
                                                        return Control_Applicative.when(Effect.applicativeEffect)(!v1.value0 && Data_Eq.notEq(dictEq)(saveValue)(v2.value0))((function () {
                                                            if (props.actions.userActionSelectionNeeded) {
                                                                return function __do() {
                                                                    v4.value1(function (v5) {
                                                                        return new Data_Maybe.Just(saveValue);
                                                                    })();
                                                                    return v3.value1(function (v5) {
                                                                        return true;
                                                                    })();
                                                                };
                                                            };
                                                            return handleSubmit(props.actions.primaryAction.mkAction)(saveValue);
                                                        })());
                                                    };
                                                    var modalSubmit = function (action) {
                                                        if (v4.value0 instanceof Data_Maybe.Just) {
                                                            return function __do() {
                                                                handleSubmit(action)(v4.value0.value0)();
                                                                v3.value1(function (v5) {
                                                                    return false;
                                                                })();
                                                                return v4.value1(function (v5) {
                                                                    return Data_Maybe.Nothing.value;
                                                                })();
                                                            };
                                                        };
                                                        if (v4.value0 instanceof Data_Maybe.Nothing) {
                                                            return v3.value1(function (v5) {
                                                                return false;
                                                            });
                                                        };
                                                        throw new Error("Failed pattern match at TacoInput (line 896, column 26 - line 901, column 42): " + [ v4.value0.constructor.name ]);
                                                    };
                                                    var modalBody = TacoModal.modalBody({
                                                        contents: [ React_Basic_DOM.text(props.actions.actionModalText) ]
                                                    });
                                                    var modalActions = TacoModal.modalActions({
                                                        contents: [ TacoButton.component()()({
                                                            onClick: Data_Maybe.Just.create(function __do() {
                                                                v2.value1(function (v5) {
                                                                    return props.value;
                                                                })();
                                                                return v3.value1(function (v5) {
                                                                    return false;
                                                                })();
                                                            }),
                                                            text: "Peruuta",
                                                            testId: "remote-input-extra-action-cancel"
                                                        }), TacoButton.component()()({
                                                            onClick: Data_Maybe.Just.create(modalSubmit(props.actions.primaryAction.mkAction)),
                                                            disabled: Data_Maybe.isNothing(v4.value0),
                                                            buttonStyle: TacoButton_Types.Filled.value,
                                                            color: TacoConstants.PrimaryBlue.value,
                                                            text: props.actions.primaryAction.actionButtonText,
                                                            testId: "remote-input-extra-action-primary"
                                                        }), TacoButton.component()()({
                                                            onClick: Data_Maybe.Just.create(modalSubmit(props.actions.secondaryAction.mkAction)),
                                                            disabled: Data_Maybe.isNothing(v4.value0),
                                                            buttonStyle: TacoButton_Types.Filled.value,
                                                            color: TacoConstants.SecondaryRed.value,
                                                            text: props.actions.secondaryAction.actionButtonText,
                                                            testId: "remote-input-extra-action-secondary"
                                                        }) ]
                                                    });
                                                    var modal = TacoModal.component()()({
                                                        onClose: Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit),
                                                        isActive: v3.value0,
                                                        heading: props.actions.actionModalTitle,
                                                        contents: [ modalBody, modalActions ]
                                                    });
                                                    var input = valueInput({
                                                        onChange: innerProps.onChange,
                                                        onSave: onSave,
                                                        onValidationChange: innerProps.onValidationChange,
                                                        validator: innerProps.validator,
                                                        clickEventHandling: innerProps.clickEventHandling,
                                                        onFocus: innerProps.onFocus,
                                                        onBlur: innerProps.onBlur,
                                                        loading: v1.value0,
                                                        disabled: innerProps.disabled,
                                                        title: innerProps.title,
                                                        value: v2.value0,
                                                        label: innerProps.label,
                                                        placeholder: innerProps.placeholder,
                                                        error: v.value0,
                                                        errorMessage: innerProps.errorMessage,
                                                        type: innerProps.type,
                                                        testId: innerProps.testId,
                                                        saveButton: innerProps.saveButton,
                                                        textarea: innerProps.textarea,
                                                        expandWhenActive: innerProps.expandWhenActive,
                                                        stateKey: innerProps.stateKey,
                                                        align: innerProps.align,
                                                        precision: innerProps.precision,
                                                        autoFocus: innerProps.autoFocus,
                                                        required: innerProps.required,
                                                        clearButton: innerProps.clearButton,
                                                        onClear: innerProps.onClear,
                                                        currency: innerProps.currency,
                                                        customComponent: innerProps.customComponent,
                                                        customComponentWrapperStyles: innerProps.customComponentWrapperStyles,
                                                        rendering: innerProps.rendering,
                                                        width: innerProps.width,
                                                        height: innerProps.height,
                                                        color: innerProps.color,
                                                        fontWeight: innerProps.fontWeight
                                                    });
                                                    return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic.fragment([ input, Data_Monoid.guard(React_Basic.monoidJSX)(v3.value0)(modal) ]));
                                                });
                                            });
                                        });
                                    });
                                });
                            });
                        });
                    });
                };
            };
        };
    };
};
var mkRemoteField = function (dictUnion) {
    return function (dictNub) {
        return function (dictEq) {
            return function (remoteFieldHookComponent_) {
                return function (valueInput) {
                    return ReactHooksUtils.make_(remoteFieldHookComponent_)(function (props$prime) {
                        var props = Record.merge()()(props$prime)(mkDefaultProps(props$prime.value));
                        var innerProps = Record["delete"]({
                            reflectSymbol: function () {
                                return "mkAction";
                            }
                        })()()(Data_Symbol.SProxy.value)(props);
                        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(false))(function (v) {
                            return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(false))(function (v1) {
                                return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(props.value))(function (v2) {
                                    return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useLayoutEffect(dictEq)(props.value)(function __do() {
                                        v2.value1(function (v3) {
                                            return props.value;
                                        })();
                                        return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                                    }))(function () {
                                        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks_Internal.unsafeHook(StateHooks.useDispatch))(function (dispatch) {
                                            var onSave = function (saveValue) {
                                                var resHandler = function (v3) {
                                                    if (v3 instanceof Data_Either.Left) {
                                                        return function __do() {
                                                            v2.value1(function (v4) {
                                                                return props.value;
                                                            })();
                                                            v1.value1(function (v4) {
                                                                return false;
                                                            })();
                                                            return v.value1(function (v4) {
                                                                return true;
                                                            })();
                                                        };
                                                    };
                                                    if (v3 instanceof Data_Either.Right) {
                                                        return function __do() {
                                                            v1.value1(function (v4) {
                                                                return false;
                                                            })();
                                                            return v.value1(function (v4) {
                                                                return false;
                                                            })();
                                                        };
                                                    };
                                                    throw new Error("Failed pattern match at TacoInput (line 764, column 22 - line 773, column 33): " + [ v3.constructor.name ]);
                                                };
                                                return Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(Data_Eq.notEq(dictEq)(saveValue)(props.value))(function __do() {
                                                    v2.value1(function (v3) {
                                                        return saveValue;
                                                    })();
                                                    v1.value1(function (v3) {
                                                        return true;
                                                    })();
                                                    return dispatch(props.mkAction(saveValue)(resHandler))();
                                                });
                                            };
                                            return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(valueInput({
                                                onChange: innerProps.onChange,
                                                onSave: onSave,
                                                onValidationChange: innerProps.onValidationChange,
                                                validator: innerProps.validator,
                                                clickEventHandling: innerProps.clickEventHandling,
                                                onFocus: innerProps.onFocus,
                                                onBlur: innerProps.onBlur,
                                                loading: v1.value0,
                                                disabled: innerProps.disabled,
                                                title: innerProps.title,
                                                value: v2.value0,
                                                label: innerProps.label,
                                                placeholder: innerProps.placeholder,
                                                error: v.value0,
                                                errorMessage: innerProps.errorMessage,
                                                type: innerProps.type,
                                                testId: innerProps.testId,
                                                saveButton: innerProps.saveButton,
                                                textarea: innerProps.textarea,
                                                expandWhenActive: innerProps.expandWhenActive,
                                                stateKey: innerProps.stateKey,
                                                align: innerProps.align,
                                                precision: innerProps.precision,
                                                autoFocus: innerProps.autoFocus,
                                                required: innerProps.required,
                                                clearButton: innerProps.clearButton,
                                                onClear: innerProps.onClear,
                                                currency: innerProps.currency,
                                                customComponent: innerProps.customComponent,
                                                customComponentWrapperStyles: innerProps.customComponentWrapperStyles,
                                                rendering: innerProps.rendering,
                                                width: innerProps.width,
                                                height: innerProps.height,
                                                color: innerProps.color,
                                                fontWeight: innerProps.fontWeight
                                            }));
                                        });
                                    });
                                });
                            });
                        });
                    });
                };
            };
        };
    };
};
var inputValueString = {
    stringify: function (v) {
        return Control_Category.identity(Control_Category.categoryFn);
    },
    defaultValue: ""
};
var inputValueNumber = {
    stringify: function (r) {
        return Formatters.formatDecimalValue(r.precision);
    },
    defaultValue: 0.0
};
var inputValueDate = {
    stringify: function (v) {
        return Types_JSDateString.extractJSDateString;
    },
    defaultValue: Effect_Unsafe.unsafePerformEffect(Types_JSDateString.getJSDateStringToday)
};
var eqValueValidity = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Valid && y instanceof Valid) {
                return true;
            };
            if (x instanceof Invalid && y instanceof Invalid) {
                return x.value0 === y.value0;
            };
            return false;
        };
    }
};
var eqInputType = {
    eq: function (x) {
        return function (y) {
            if (x instanceof DateInput && y instanceof DateInput) {
                return true;
            };
            if (x instanceof StringInput && y instanceof StringInput) {
                return true;
            };
            if (x instanceof NumberInput && y instanceof NumberInput) {
                return true;
            };
            return false;
        };
    }
};
var defaultValue = function (dict) {
    return dict.defaultValue;
};
var transformInput = function (dictInputValue) {
    return function (transformer) {
        return function (handler) {
            return function (getter) {
                return handler(function (v) {
                    return transformer(getter(defaultValue(dictInputValue)));
                });
            };
        };
    };
};
var dateInput_ = ReactHooksUtils.createComponent("TacoDateInput");
var changeHandler = function (dictInputValue) {
    return function (handler) {
        return function (getter) {
            return handler(getter(defaultValue(dictInputValue)));
        };
    };
};
var andValueValidity = function (f) {
    return function (g) {
        return function (x) {
            var v = f(x);
            if (v instanceof Valid) {
                return g(x);
            };
            if (v instanceof Invalid) {
                return new Invalid(v.value0);
            };
            throw new Error("Failed pattern match at TacoInput (line 80, column 9 - line 82, column 31): " + [ v.constructor.name ]);
        };
    };
};
var alignToStr = function (v) {
    if (v instanceof LeftAlign) {
        return "left";
    };
    if (v instanceof RightAlign) {
        return "right";
    };
    throw new Error("Failed pattern match at TacoInput (line 86, column 1 - line 86, column 30): " + [ v.constructor.name ]);
};
var mkComponent = function (dictUnion) {
    return function (dictNub) {
        return function (dictEq) {
            return function (dictInputValue) {
                return function (component_) {
                    return function (inputType) {
                        return function (fromString) {
                            return function (defaultValue1) {
                                return ReactHooksUtils.make_(component_)(function (props) {
                                    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(false))(function (v) {
                                        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(false))(function (v1) {
                                            return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useRef(false))(function (changedSinceFocus) {
                                                var defaultProps_ = mkDefaultProps(defaultValue1);
                                                var v2 = Record.merge()()(props)(defaultProps_);
                                                var didChange = new Data_Tuple.Tuple(v2.value, new Data_Tuple.Tuple(v2.stateKey, v1.value0));
                                                return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useRef(v2.stateKey))(function (prevStateKey) {
                                                    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useRef(v2.onSave))(function (prevOnSave) {
                                                        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(stringify(dictInputValue)(v2)(v2.value)))(function (v3) {
                                                            return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useRef(stringify(dictInputValue)(v2)(v2.value)))(function (valueRef) {
                                                                var setValue = function (v4) {
                                                                    return function __do() {
                                                                        v3.value1(function (v5) {
                                                                            return v4;
                                                                        })();
                                                                        return React_Basic_Hooks.writeRef(valueRef)(v4)();
                                                                    };
                                                                };
                                                                return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(v2.validator(v3.value0)))(function (v4) {
                                                                    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks_Internal.unsafeHook(UseUUID.useUUID))(function (id) {
                                                                        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks_Internal.unsafeHook(UseNullableRef.useNullableRef))(function (ref) {
                                                                            return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks_Internal.unsafeHook(UseNullableRef.useNullableRef))(function (containerRef) {
                                                                                var handleSave = function __do() {
                                                                                    var value$prime = React_Basic_Hooks.readRef(valueRef)();
                                                                                    var onSave$prime = React_Basic_Hooks.readRef(prevOnSave)();
                                                                                    var v5 = fromString(v2)(value$prime);
                                                                                    if (v5 instanceof Data_Maybe.Just) {
                                                                                        return Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(Data_Eq.eq(eqValueValidity)(v4.value0)(Valid.value))(function __do() {
                                                                                            React_Basic_Hooks.writeRef(changedSinceFocus)(false)();
                                                                                            return onSave$prime(v5.value0)();
                                                                                        })();
                                                                                    };
                                                                                    if (v5 instanceof Data_Maybe.Nothing) {
                                                                                        return Data_Unit.unit;
                                                                                    };
                                                                                    throw new Error("Failed pattern match at TacoInput (line 374, column 7 - line 378, column 29): " + [ v5.constructor.name ]);
                                                                                };
                                                                                var changeValue = function (newValue) {
                                                                                    return function __do() {
                                                                                        setValue(newValue)();
                                                                                        var v5 = fromString(v2)(newValue);
                                                                                        if (v5 instanceof Data_Maybe.Just) {
                                                                                            return v2.onChange(function (v6) {
                                                                                                return v5.value0;
                                                                                            })();
                                                                                        };
                                                                                        if (v5 instanceof Data_Maybe.Nothing) {
                                                                                            return Data_Unit.unit;
                                                                                        };
                                                                                        throw new Error("Failed pattern match at TacoInput (line 354, column 7 - line 356, column 29): " + [ v5.constructor.name ]);
                                                                                    };
                                                                                };
                                                                                var handleChange = React_Basic_Events.handler(React_Basic_DOM_Events.targetValue)(function (maybeValue) {
                                                                                    return function __do() {
                                                                                        (function () {
                                                                                            if (maybeValue instanceof Data_Maybe.Just) {
                                                                                                return changeValue(maybeValue.value0)();
                                                                                            };
                                                                                            if (maybeValue instanceof Data_Maybe.Nothing) {
                                                                                                return Data_Unit.unit;
                                                                                            };
                                                                                            throw new Error("Failed pattern match at TacoInput (line 360, column 7 - line 362, column 29): " + [ maybeValue.constructor.name ]);
                                                                                        })();
                                                                                        v.value1(function (v5) {
                                                                                            return true;
                                                                                        })();
                                                                                        return React_Basic_Hooks.writeRef(changedSinceFocus)(true)();
                                                                                    };
                                                                                });
                                                                                return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useLayoutEffect(Data_Eq.eqString)(v2.stateKey)(function __do() {
                                                                                    var changedSinceFocus$prime = React_Basic_Hooks.readRef(changedSinceFocus)();
                                                                                    Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(changedSinceFocus$prime)(handleSave)();
                                                                                    return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                                                                                }))(function () {
                                                                                    var selectAll = function __do() {
                                                                                        var elem = HtmlUtils.readRefMaybeElement(ref)();
                                                                                        return Data_Maybe.maybe(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit))(function ($209) {
                                                                                            return Web_HTML_HTMLInputElement.select($209);
                                                                                        })(elem)();
                                                                                    };
                                                                                    return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useEffect(Data_Eq.eqString)(v2.stateKey)((function () {
                                                                                        if (v1.value0) {
                                                                                            return function __do() {
                                                                                                selectAll();
                                                                                                return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                                                                                            };
                                                                                        };
                                                                                        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)));
                                                                                    })()))(function () {
                                                                                        return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useLayoutEffect(Data_Tuple.eqTuple(dictEq)(Data_Tuple.eqTuple(Data_Eq.eqString)(Data_Eq.eqBoolean)))(didChange)(function __do() {
                                                                                            var prevStateKeyValue = React_Basic_Hooks.readRef(prevStateKey)();
                                                                                            var skipUpdate = v1.value0 && v2.stateKey === prevStateKeyValue;
                                                                                            Data_Monoid.guard(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit)))(!skipUpdate)(setValue)(stringify(dictInputValue)(v2)(v2.value))();
                                                                                            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                                                                                        }))(function () {
                                                                                            return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useEffect(Data_Eq.eqString)(v2.stateKey)(function __do() {
                                                                                                React_Basic_Hooks.writeRef(prevStateKey)(v2.stateKey)();
                                                                                                return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                                                                                            }))(function () {
                                                                                                return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks_Internal.unsafeHook(TofuHooks.useLayoutEffect([ ReactHooksUtils.utf(v2.onSave) ])(function __do() {
                                                                                                    React_Basic_Hooks.writeRef(prevOnSave)(v2.onSave)();
                                                                                                    return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                                                                                                })))(function () {
                                                                                                    return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useLayoutEffect(Data_Eq.eqString)(v3.value0)(function __do() {
                                                                                                        v4.value1(function (v5) {
                                                                                                            return v2.validator(v3.value0);
                                                                                                        })();
                                                                                                        return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                                                                                                    }))(function () {
                                                                                                        return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useLayoutEffect(eqValueValidity)(v4.value0)(function __do() {
                                                                                                            v2.onValidationChange(v4.value0)();
                                                                                                            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                                                                                                        }))(function () {
                                                                                                            return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useEffect(Data_Eq.eqUnit)(Data_Unit.unit)(Control_Applicative.pure(Effect.applicativeEffect)(function __do() {
                                                                                                                var changedSinceFocus$prime = React_Basic_Hooks.readRef(changedSinceFocus)();
                                                                                                                return Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(changedSinceFocus$prime)(handleSave)();
                                                                                                            })))(function () {
                                                                                                                return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useRef(v2.autoFocus))(function (autoFocusRef) {
                                                                                                                    return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useEffect(Data_Eq.eqBoolean)(v2.autoFocus)(function __do() {
                                                                                                                        var autoFocusRefVal = React_Basic_Hooks.readRef(autoFocusRef)();
                                                                                                                        Control_Applicative.when(Effect.applicativeEffect)(v2.autoFocus && !autoFocusRefVal)(function __do() {
                                                                                                                            var elem = HtmlUtils.readRefMaybeElement(ref)();
                                                                                                                            return Data_Maybe.maybe(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit))(Web_HTML_HTMLElement.focus)(elem)();
                                                                                                                        })();
                                                                                                                        React_Basic_Hooks.writeRef(autoFocusRef)(v2.autoFocus)();
                                                                                                                        return Data_Monoid.mempty(Effect.monoidEffect(Effect.monoidEffect(Data_Monoid.monoidUnit)))();
                                                                                                                    }))(function () {
                                                                                                                        var showClearButton = v2.clearButton && (v3.value0 !== "" && !v2.loading);
                                                                                                                        var loadingIndicator = React_Basic_Emotion.element(React_Basic_DOM_Generated["span'"]())({
                                                                                                                            className: "loading-indicator",
                                                                                                                            css: TacoInput_Styles.loadingIndicatorStyles({
                                                                                                                                loading: v2.loading
                                                                                                                            }),
                                                                                                                            children: [ TacoLoader.component()()({
                                                                                                                                size: "100%"
                                                                                                                            }) ]
                                                                                                                        });
                                                                                                                        var isError = v2.error || Data_Eq.notEq(eqValueValidity)(v4.value0)(Valid.value) && v.value0;
                                                                                                                        var inputLabel = function (l) {
                                                                                                                            return TacoText.component()()({
                                                                                                                                text: l,
                                                                                                                                variant: new TacoText_Types.Label(id),
                                                                                                                                color: TacoConstants.TextSecondary.value,
                                                                                                                                size: new TacoConstants.CustomSize("10px"),
                                                                                                                                align: (function () {
                                                                                                                                    if (v2.align instanceof LeftAlign) {
                                                                                                                                        return TacoText_Types.Left.value;
                                                                                                                                    };
                                                                                                                                    if (v2.align instanceof RightAlign) {
                                                                                                                                        return TacoText_Types.Right.value;
                                                                                                                                    };
                                                                                                                                    throw new Error("Failed pattern match at TacoInput (line 541, column 16 - line 543, column 35): " + [ v2.align.constructor.name ]);
                                                                                                                                })()
                                                                                                                            });
                                                                                                                        };
                                                                                                                        var handleFocus = function __do() {
                                                                                                                            React_Basic_Hooks.writeRef(changedSinceFocus)(false)();
                                                                                                                            Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(Data_Eq.eq(eqInputType)(inputType)(NumberInput.value))(selectAll)();
                                                                                                                            return v2.onFocus();
                                                                                                                        };
                                                                                                                        var handleContainerFocus = v1.value1(function (v5) {
                                                                                                                            return true;
                                                                                                                        });
                                                                                                                        var handleContainerBlur = React_Basic_Events.handler(ReactBasicUtils.domEvent)(function (event) {
                                                                                                                            return function __do() {
                                                                                                                                var targetContained = DOMUtils.isFocusEventTargetContained(event)();
                                                                                                                                return Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(!targetContained)(function __do() {
                                                                                                                                    v1.value1(function (v5) {
                                                                                                                                        return false;
                                                                                                                                    })();
                                                                                                                                    v2.onBlur();
                                                                                                                                    var changedSinceFocus$prime = React_Basic_Hooks.readRef(changedSinceFocus)();
                                                                                                                                    return Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(changedSinceFocus$prime)(handleSave)();
                                                                                                                                })();
                                                                                                                            };
                                                                                                                        });
                                                                                                                        var handleBlur = function __do() {
                                                                                                                            v.value1(function (v5) {
                                                                                                                                return true;
                                                                                                                            })();
                                                                                                                            var elem = HtmlUtils.readRefMaybeElement(ref)();
                                                                                                                            return Data_Maybe.maybe(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit))((function () {
                                                                                                                                var $210 = Web_DOM_Element.setScrollTop(0.0);
                                                                                                                                return function ($211) {
                                                                                                                                    return $210(Web_HTML_HTMLElement.toElement($211));
                                                                                                                                };
                                                                                                                            })())(elem)();
                                                                                                                        };
                                                                                                                        var errorMessage$prime = (function () {
                                                                                                                            if (v4.value0 instanceof Valid) {
                                                                                                                                return v2.errorMessage;
                                                                                                                            };
                                                                                                                            if (v4.value0 instanceof Invalid) {
                                                                                                                                return v4.value0.value0;
                                                                                                                            };
                                                                                                                            throw new Error("Failed pattern match at TacoInput (line 651, column 21 - line 653, column 31): " + [ v4.value0.constructor.name ]);
                                                                                                                        })();
                                                                                                                        var errorContainer = React_Basic_Emotion.element(React_Basic_DOM_Generated["span'"]())({
                                                                                                                            className: "error-container",
                                                                                                                            css: TacoInput_Styles.errorContainerStyles,
                                                                                                                            children: [ React_Basic_DOM.text(errorMessage$prime) ]
                                                                                                                        });
                                                                                                                        var currencyContainer = React_Basic_Emotion.element(React_Basic_DOM_Generated["span'"]())({
                                                                                                                            className: "currency-container",
                                                                                                                            css: TacoInput_Styles.currencyContainerStyles,
                                                                                                                            children: [ React_Basic_DOM.text("\u20ac") ]
                                                                                                                        });
                                                                                                                        var commonProps = {
                                                                                                                            value: v3.value0,
                                                                                                                            onChange: handleChange,
                                                                                                                            css: TacoInput_Styles.inputStyles({
                                                                                                                                align: alignToStr(v2.align),
                                                                                                                                currency: v2.currency,
                                                                                                                                textarea: v2.textarea,
                                                                                                                                error: isError,
                                                                                                                                loading: v2.loading,
                                                                                                                                expandWhenActive: v2.expandWhenActive,
                                                                                                                                rendering: v2.rendering,
                                                                                                                                hasCustomComponent: Data_Maybe.isJust(v2.customComponent),
                                                                                                                                height: v2.height,
                                                                                                                                color: v2.color,
                                                                                                                                fontWeight: v2.fontWeight
                                                                                                                            }),
                                                                                                                            id: id,
                                                                                                                            placeholder: v2.placeholder,
                                                                                                                            disabled: v2.disabled,
                                                                                                                            title: v2.title,
                                                                                                                            "_data": Components_Utils.mkTestIdAttrs(v2.testId),
                                                                                                                            className: Utils_ClassNames.mkClassName([ new Data_Maybe.Just("input"), Data_Monoid.guard(Data_Maybe.monoidMaybe(Data_Semigroup.semigroupString))(v2.loading)(new Data_Maybe.Just("loading")), Data_Monoid.guard(Data_Maybe.monoidMaybe(Data_Semigroup.semigroupString))(isError)(new Data_Maybe.Just("error")) ]),
                                                                                                                            onClick: (function () {
                                                                                                                                if (v2.clickEventHandling instanceof NoHandling) {
                                                                                                                                    return React_Basic_Events.handler_(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit));
                                                                                                                                };
                                                                                                                                if (v2.clickEventHandling instanceof PreventDefault) {
                                                                                                                                    return React_Basic_Events.handler(React_Basic_DOM_Events.preventDefault)(Control_Applicative.pure(Control_Applicative.applicativeFn)(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit)));
                                                                                                                                };
                                                                                                                                if (v2.clickEventHandling instanceof StopPropagation) {
                                                                                                                                    return React_Basic_Events.handler(React_Basic_DOM_Events.stopPropagation)(Control_Applicative.pure(Control_Applicative.applicativeFn)(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit)));
                                                                                                                                };
                                                                                                                                if (v2.clickEventHandling instanceof Capture) {
                                                                                                                                    return React_Basic_DOM_Events.capture_(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit));
                                                                                                                                };
                                                                                                                                throw new Error("Failed pattern match at TacoInput (line 515, column 18 - line 519, column 42): " + [ v2.clickEventHandling.constructor.name ]);
                                                                                                                            })(),
                                                                                                                            onFocus: React_Basic_Events.handler_(handleFocus),
                                                                                                                            onBlur: React_Basic_Events.handler_(handleBlur),
                                                                                                                            onKeyDown: React_Basic_Events.handler(React_Basic_DOM_Events.key)(function (key) {
                                                                                                                                return Control_Applicative.when(Effect.applicativeEffect)(!v2.textarea && Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(key)(new Data_Maybe.Just("Enter")))(handleSave);
                                                                                                                            }),
                                                                                                                            autoFocus: v2.autoFocus,
                                                                                                                            required: v2.required,
                                                                                                                            ref: ref
                                                                                                                        };
                                                                                                                        var input = (function () {
                                                                                                                            if (v2.textarea) {
                                                                                                                                return React_Basic_Emotion.element(React_Basic_DOM_Generated["textarea'"]())(commonProps);
                                                                                                                            };
                                                                                                                            return React_Basic_Emotion.element(React_Basic_DOM_Generated["input'"]())(Record.merge()()({
                                                                                                                                type: v2.type
                                                                                                                            })(commonProps));
                                                                                                                        })();
                                                                                                                        var closeInput = function __do() {
                                                                                                                            React_Basic_Hooks.writeRef(changedSinceFocus)(false)();
                                                                                                                            v1.value1(function (v5) {
                                                                                                                                return false;
                                                                                                                            })();
                                                                                                                            var elem = HtmlUtils.readRefMaybeElement(ref)();
                                                                                                                            v2.onBlur();
                                                                                                                            return Data_Maybe.maybe(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit))(Web_HTML_HTMLElement.blur)(elem)();
                                                                                                                        };
                                                                                                                        var customComponentWrapper = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                                                                                                                            className: "custom-component-wrapper",
                                                                                                                            css: TacoInput_Styles.customComponentWrapperStyles(v2.customComponentWrapperStyles),
                                                                                                                            tabIndex: 0,
                                                                                                                            children: [ (function () {
                                                                                                                                if (v2.customComponent instanceof Data_Maybe.Just) {
                                                                                                                                    return ReactBasicUtils.unsafeCloneElement(v2.customComponent.value0)({
                                                                                                                                        closeInput: closeInput
                                                                                                                                    });
                                                                                                                                };
                                                                                                                                if (v2.customComponent instanceof Data_Maybe.Nothing) {
                                                                                                                                    return React_Basic.empty;
                                                                                                                                };
                                                                                                                                throw new Error("Failed pattern match at TacoInput (line 611, column 15 - line 613, column 36): " + [ v2.customComponent.constructor.name ]);
                                                                                                                            })() ]
                                                                                                                        });
                                                                                                                        var clearButtonContainer = React_Basic_Emotion.element(React_Basic_DOM_Generated["span'"]())({
                                                                                                                            className: "clear-button",
                                                                                                                            css: TacoInput_Styles.clearButtonStyles({
                                                                                                                                rendering: v2.rendering
                                                                                                                            }),
                                                                                                                            children: [ TacoButton.component()()({
                                                                                                                                text: "",
                                                                                                                                icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconCloseCrossV1.value),
                                                                                                                                onClick: new Data_Maybe.Just(function __do() {
                                                                                                                                    v2.onClear();
                                                                                                                                    changeValue("")();
                                                                                                                                    return closeInput();
                                                                                                                                }),
                                                                                                                                testId: v2.testId + "-clear-button"
                                                                                                                            }) ]
                                                                                                                        });
                                                                                                                        var container = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                                                                                                                            className: "input-container",
                                                                                                                            css: TacoInput_Styles.inputContainerStyles({
                                                                                                                                rendering: v2.rendering,
                                                                                                                                focused: v1.value0,
                                                                                                                                loading: v2.loading,
                                                                                                                                error: isError,
                                                                                                                                hasCustomComponent: Data_Maybe.isJust(v2.customComponent),
                                                                                                                                textarea: v2.textarea
                                                                                                                            }),
                                                                                                                            ref: containerRef,
                                                                                                                            onBlur: handleContainerBlur,
                                                                                                                            onFocus: React_Basic_Events.handler_(handleContainerFocus),
                                                                                                                            children: [ input, Data_Monoid.guard(React_Basic.monoidJSX)(v2.loading)(loadingIndicator), Data_Monoid.guard(React_Basic.monoidJSX)(v2.currency)(currencyContainer), Data_Monoid.guard(React_Basic.monoidJSX)(showClearButton)(clearButtonContainer), Data_Monoid.guard(React_Basic.monoidJSX)(Data_Maybe.isJust(v2.customComponent) && v1.value0)(customComponentWrapper) ]
                                                                                                                        });
                                                                                                                        var popoutContainer = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                                                                                                                            className: "popout-container",
                                                                                                                            css: TacoInput_Styles.popoutContainerStyles({
                                                                                                                                rendering: v2.rendering,
                                                                                                                                expandWhenActive: v2.expandWhenActive
                                                                                                                            }),
                                                                                                                            children: [ container ]
                                                                                                                        });
                                                                                                                        var popoutPlaceholder = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                                                                                                                            className: "popout-placeholder",
                                                                                                                            css: TacoInput_Styles.popoutPlaceholderStyles({
                                                                                                                                height: v2.height,
                                                                                                                                rendering: v2.rendering,
                                                                                                                                textarea: v2.textarea,
                                                                                                                                expandWhenActive: v2.expandWhenActive
                                                                                                                            }),
                                                                                                                            children: [ popoutContainer ]
                                                                                                                        });
                                                                                                                        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                                                                                                                            className: "__TacoInput",
                                                                                                                            css: TacoInput_Styles.tacoInputStyles({
                                                                                                                                rendering: v2.rendering,
                                                                                                                                width: v2.width
                                                                                                                            }),
                                                                                                                            children: [ Data_Monoid.guard(Data_Monoid.monoidFn(React_Basic.monoidJSX))(v2.label !== "")(inputLabel)(v2.label), popoutPlaceholder, Data_Monoid.guard(React_Basic.monoidJSX)(isError)(errorContainer) ]
                                                                                                                        }));
                                                                                                                    });
                                                                                                                });
                                                                                                            });
                                                                                                        });
                                                                                                    });
                                                                                                });
                                                                                            });
                                                                                        });
                                                                                    });
                                                                                });
                                                                            });
                                                                        });
                                                                    });
                                                                });
                                                            });
                                                        });
                                                    });
                                                });
                                            });
                                        });
                                    });
                                });
                            };
                        };
                    };
                };
            };
        };
    };
};
var dateInput = function (dictUnion) {
    return function (dictNub) {
        return function (dictEq) {
            return function (props) {
                return mkComponent()()(dictEq)(inputValueDate)(dateInput_)(DateInput.value)(function (v) {
                    return readStrToDate;
                })(Effect_Unsafe.unsafePerformEffect(Types_JSDateString.getJSDateStringToday))(props);
            };
        };
    };
};
var numberInput = function (dictUnion) {
    return function (dictNub) {
        return function (dictEq) {
            return mkComponent()()(dictEq)(inputValueNumber)(numberInput_)(NumberInput.value)(function (props$prime) {
                return readStrToPrecisionNumber(props$prime.precision);
            })(0.0);
        };
    };
};
var remoteNumberField = function (dictUnion) {
    return function (dictNub) {
        return mkRemoteField()()(Data_Eq.eqNumber)(remoteNumberField_)(numberInput()()(Data_Eq.eqNumber));
    };
};
var stringInput = function (dictUnion) {
    return function (dictNub) {
        return function (dictEq) {
            return mkComponent()()(dictEq)(inputValueString)(stringInput_)(StringInput.value)(function (v) {
                return Data_Maybe.Just.create;
            })("");
        };
    };
};
var remoteMultiActionNumberField = function (dictUnion) {
    return function (dictNub) {
        return mkMultiActionRemoteField()()(Data_Eq.eqString)(remoteMultiActionNumberField_)(stringInput()()(Data_Eq.eqString));
    };
};
var remoteMultiActionStringField = function (dictUnion) {
    return function (dictNub) {
        return mkMultiActionRemoteField()()(Data_Eq.eqString)(remoteMultiActionStringField_)(stringInput()()(Data_Eq.eqString));
    };
};
var remoteStringField = function (dictUnion) {
    return function (dictNub) {
        return mkRemoteField()()(Data_Eq.eqString)(remoteStringField_)(stringInput()()(Data_Eq.eqString));
    };
};
module.exports = {
    defaultValue: defaultValue,
    stringify: stringify,
    Valid: Valid,
    Invalid: Invalid,
    orValueValidity: orValueValidity,
    andValueValidity: andValueValidity,
    LeftAlign: LeftAlign,
    RightAlign: RightAlign,
    alignToStr: alignToStr,
    NoHandling: NoHandling,
    PreventDefault: PreventDefault,
    StopPropagation: StopPropagation,
    Capture: Capture,
    mkDefaultProps: mkDefaultProps,
    changeHandler: changeHandler,
    transformInput: transformInput,
    stringInput_: stringInput_,
    stringInput: stringInput,
    numberInput_: numberInput_,
    readStrToPrecisionNumber: readStrToPrecisionNumber,
    numberInput: numberInput,
    dateInput_: dateInput_,
    readStrToDate: readStrToDate,
    dateInput: dateInput,
    DateInput: DateInput,
    StringInput: StringInput,
    NumberInput: NumberInput,
    mkComponent: mkComponent,
    remoteStringField_: remoteStringField_,
    remoteStringField: remoteStringField,
    remoteMultiActionStringField: remoteMultiActionStringField,
    remoteMultiActionNumberField: remoteMultiActionNumberField,
    remoteMultiActionStringField_: remoteMultiActionStringField_,
    remoteMultiActionNumberField_: remoteMultiActionNumberField_,
    remoteNumberField_: remoteNumberField_,
    remoteNumberField: remoteNumberField,
    mkRemoteField: mkRemoteField,
    mkMultiActionRemoteField: mkMultiActionRemoteField,
    eqValueValidity: eqValueValidity,
    inputValueString: inputValueString,
    inputValueNumber: inputValueNumber,
    inputValueDate: inputValueDate,
    eqInputType: eqInputType
};
