// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var SuperAdmin_Styles = require("../SuperAdmin.Styles/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var initEmails = [ "" ];
var component = TofuHooks.mkHookComponent("AddOrgUsers")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState(initEmails)();
        var mkEmailInput = function (idx) {
            return function (email) {
                return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                    className: "emailInput",
                    css: SuperAdmin_Styles.emailInputStyles,
                    children: [ TacoInput.stringInput()()(Data_Eq.eqString)({
                        placeholder: "nimi@tocoman.com",
                        value: email,
                        onSave: function (value) {
                            return v1.value1(Data_Array.updateAtIndices(Data_Foldable.foldableArray)([ new Data_Tuple.Tuple(idx, value) ]));
                        },
                        testId: "add-user-email-" + Data_Show.show(Data_Show.showInt)(idx)
                    }) ]
                });
            };
        };
        var createNewUser = (function () {
            var addUser = function (email) {
                return v.appDispatch(new Actions.OrgAddUsersRequest([ {
                    email: email,
                    hasSSO: false,
                    sendInvitation: true
                } ]));
            };
            return function __do() {
                Data_Foldable.traverse_(Effect.applicativeEffect)(Data_Foldable.foldableArray)(addUser)(Data_Array.filter((function () {
                    var $8 = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean);
                    return function ($9) {
                        return $8(Data_String_Common["null"]($9));
                    };
                })())(v1.value0))();
                return v.onClose();
            };
        })();
        var modalActions = TacoModal.modalActions({
            contents: [ TacoButton.component()()({
                onClick: new Data_Maybe.Just(v.onClose),
                text: "Peruuta",
                testId: "add-user-cancel"
            }), TacoButton.component()()({
                onClick: Data_Maybe.Just.create(createNewUser),
                buttonStyle: TacoButton_Types.Filled.value,
                text: "Luo k\xe4ytt\xe4j\xe4t",
                submit: true,
                testId: "add-user-submit"
            }) ]
        });
        var addMoreEmailsButton = TacoButton.component()()({
            text: "Lis\xe4\xe4 rivi",
            onClick: new Data_Maybe.Just(v1.value1(Data_Functor.flap(Data_Functor.functorFn)(Data_Array.snoc)(""))),
            title: "Lis\xe4\xe4 useampi k\xe4ytt\xe4j\xe4 kerralla"
        });
        var emailInputs = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "emailInputs",
            css: SuperAdmin_Styles.emailInputsStyles,
            children: [ TacoText.component()()({
                text: "S\xe4hk\xf6posti",
                weight: TacoText_Types.Bold.value,
                variant: new TacoText_Types.Heading(3),
                gutterBottom: true
            }), React_Basic.fragment(Data_Array.mapWithIndex(mkEmailInput)(v1.value0)), addMoreEmailsButton ]
        });
        var modalBody = TacoModal.modalBody({
            contents: [ emailInputs ]
        });
        return React_Basic_DOM_Generated.div_([ TacoModal.component()()({
            onClose: v.onClose,
            isActive: true,
            heading: "Luo uudet k\xe4ytt\xe4j\xe4t",
            contents: [ React_Basic_DOM_Generated.form()({
                onSubmit: React_Basic_DOM_Events.capture_(createNewUser),
                children: [ modalBody, modalActions ]
            }) ]
        }) ]);
    };
});
module.exports = {
    component: component
};
