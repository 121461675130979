// Generated by purs version 0.14.5
"use strict";
var Standby = (function () {
    function Standby() {

    };
    Standby.value = new Standby();
    return Standby;
})();
var Uploading = (function () {
    function Uploading() {

    };
    Uploading.value = new Uploading();
    return Uploading;
})();
var Done = (function () {
    function Done() {

    };
    Done.value = new Done();
    return Done;
})();
var $$Error = (function () {
    function $$Error() {

    };
    $$Error.value = new $$Error();
    return $$Error;
})();
module.exports = {
    Standby: Standby,
    Uploading: Uploading,
    Done: Done,
    "Error": $$Error
};
