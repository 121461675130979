// Generated by purs version 0.14.5
"use strict";
var Common = require("../Common/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Locale = require("../Locale/index.js");
var NotificationsMiddleware = require("../NotificationsMiddleware/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoTableUtils = require("../TacoTableUtils/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Alert = require("../Types.Alert/index.js");
var UseNullableRef = require("../UseNullableRef/index.js");
var ByText = (function () {
    function ByText() {

    };
    ByText.value = new ByText();
    return ByText;
})();
var ByTime = (function () {
    function ByTime() {

    };
    ByTime.value = new ByTime();
    return ByTime;
})();
var styleSet = TacoTable_Styles.optimizedTableStyleSet;
var getNotificationsortingValue = function (v) {
    return function (v1) {
        if (v instanceof ByText) {
            return new TacoTable_Types.StringValue(v1.text);
        };
        if (v instanceof ByTime) {
            return new TacoTable_Types.NumberValue(v1.time);
        };
        throw new Error("Failed pattern match at NotificationsContainer (line 105, column 1 - line 105, column 84): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var getNameNotificationsortProperty = {
    getName: function (v) {
        return "NotificationsortProperty";
    }
};
var eqNotificationsortProperty = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ByText && y instanceof ByText) {
                return true;
            };
            if (x instanceof ByTime && y instanceof ByTime) {
                return true;
            };
            return false;
        };
    }
};
var notificationsTableOptimized = function (dictEq) {
    return TacoTable.tableOptimized(getNameNotificationsortProperty)(eqNotificationsortProperty)(dictEq);
};
var ordNotificationsortProperty = {
    compare: function (x) {
        return function (y) {
            if (x instanceof ByText && y instanceof ByText) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByText) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByText) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByTime && y instanceof ByTime) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at NotificationsContainer (line 117, column 1 - line 117, column 76): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqNotificationsortProperty;
    }
};
var columns = [ {
    key: "level",
    label: React_Basic_DOM.text("Taso"),
    cell: TacoTable_Types.PlainTextCell.create(function ($37) {
        return Types_Alert.alertLevelToString((function (v) {
            return v.level;
        })($37));
    }),
    sortProperty: Data_Maybe.Nothing.value,
    width: new TacoTable_Types.Fixed("10rem"),
    headerJSX: Data_Maybe.Nothing.value
}, {
    key: "text",
    label: React_Basic_DOM.text(Locale.lookup_("action")),
    cell: new TacoTable_Types.PlainTextCell(function (v) {
        return v.text;
    }),
    sortProperty: new Data_Maybe.Just(ByText.value),
    width: new TacoTable_Types.Flex(3),
    headerJSX: Data_Maybe.Nothing.value
}, {
    key: "createdAt",
    label: React_Basic_DOM.text(Locale.lookup_("createdAt")),
    cell: new TacoTable_Types.PlainTextCell(function (v) {
        return v.formattedTime;
    }),
    sortProperty: new Data_Maybe.Just(ByTime.value),
    width: new TacoTable_Types.Flex(1),
    headerJSX: Data_Maybe.Nothing.value
} ];
var notificationsToRow = function (idx) {
    return function (notifications) {
        return {
            rowData: notifications,
            rowKey: Data_Show.show(Data_Show.showInt)(idx),
            className: "",
            onClick: Data_Function["const"](Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit)),
            foldingElement: Data_Maybe.Nothing.value,
            columns: columns
        };
    };
};
var notificationsTable = TofuHooks.mkHookComponent("NotificationsTable")(function (props) {
    return function __do() {
        var tableRef = UseNullableRef.useNullableRef();
        var v = TofuHooks.useState(new TacoTable_Types.SortingCriteria(TacoTable_Types.Descending.value, new Data_Maybe.Just(ByTime.value), getNotificationsortingValue))();
        var sortedRows = TofuHooks.useMemo([ ReactHooksUtils.utf(props.notifications), ReactHooksUtils.utf(v.value0) ])(function (v1) {
            return TacoTableUtils.sortByCriteria(v.value0)(props.notifications);
        })();
        var table = notificationsTableOptimized(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "time";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "text";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "level";
            }
        })(Types_Alert.eqAlertLevel))()({
            reflectSymbol: function () {
                return "id";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "formattedTime";
            }
        })(Data_Eq.eqString)))({
            rows: sortedRows,
            mkRow: notificationsToRow,
            columns: columns,
            showHeader: true,
            sortProperty: v.value0.value1,
            sortingOrder: v.value0.value0,
            onSort: Data_Monoid.mempty(Data_Monoid.monoidFn(Effect.monoidEffect(Data_Monoid.monoidUnit))),
            styleSet: styleSet,
            containerRef: tableRef,
            rowHeight: TacoTable_Types.RowLarge.value,
            initialScrollRow: Data_Maybe.Nothing.value,
            onResize: function (v1) {
                return function (v2) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                };
            },
            columnSizeOffsets: Data_Maybe.Nothing.value
        });
        return table;
    };
});
var component = TofuHooks.mkHookComponent("NotificationsContainer")(function (v) {
    return function __do() {
        var alertsState = StateHooks.useSelector(function (v1) {
            return v1.alerts;
        })();
        var v1 = TofuHooks.useState(Common.emptyArray)();
        TofuHooks.useEffect([ alertsState ])(function __do() {
            var alerts = NotificationsMiddleware.getAlerts();
            v1.value1(function (v2) {
                return alerts;
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Effect.monoidEffect(Data_Monoid.monoidUnit)))();
        })();
        return React_Basic.fragment([ notificationsTable({
            notifications: v1.value0
        }) ]);
    };
});
module.exports = {
    component: component
};
