import { ValueFormatterParams } from "ag-grid-community";
import { has } from "lodash";
import i18n from "i18next";

const defaultLanguage = "fi-FI";
const formatDifference = (params: ValueFormatterParams) => {
  const value = has(params.value, "toString")
    ? parseFloat(params.value.toString())
    : params.value;
  if (params.node?.group || params.node?.isRowPinned()) {
    if (value) {
      return value > 0
        ? "+" +
            parseFloat(value).toLocaleString(
              i18n?.language ?? defaultLanguage,
              {
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )
        : parseFloat(value).toLocaleString(i18n?.language ?? defaultLanguage, {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
    }
    return parseFloat("0").toLocaleString(i18n?.language ?? defaultLanguage, {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  return "";
};

export const differenceColDef = {
  valueFormatter: formatDifference,
  cellEditorParams: {
    useFormatter: true,
  },
  cellClass: "moneyFormat",
};
