// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function_Uncurried = require("../Data.Function.Uncurried/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Number = require("../Data.Number/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_String_Pattern = require("../Data.String.Pattern/index.js");
var stringContainsCaseIgnored = function (pattern) {
    return function (candidates) {
        var match = function (string) {
            return Data_String_CodeUnits.contains(Data_String_Pattern.Pattern(Data_String_Common.toLower(pattern)))(Data_String_Common.toLower(string));
        };
        return Data_Foldable.any(Data_Foldable.foldableArray)(Data_HeytingAlgebra.heytingAlgebraBoolean)(match)(candidates);
    };
};
var startsWith = function (searchStr) {
    return function (str) {
        return Data_Maybe.isJust(Data_String_CodeUnits.stripPrefix(searchStr)(str));
    };
};
var startsWithAnyPatternCaseIgnored = function (testPatterns) {
    return function (target) {
        var matchInTarget = function (testPattern) {
            return startsWith(Data_String_Pattern.Pattern(Data_String_Common.toLower(testPattern)))(Data_String_Common.toLower(target));
        };
        return Data_Array["null"](testPatterns) || Data_Foldable.any(Data_Foldable.foldableArray)(Data_HeytingAlgebra.heytingAlgebraBoolean)(matchInTarget)(testPatterns);
    };
};
var rightPad = Data_Function_Uncurried.runFn3($foreign.padEnd);
var removeNewlines = (function () {
    var $0 = Data_String_Common.replaceAll("\x0d")("");
    var $1 = Data_String_Common.replaceAll("\x0a")(" ");
    return function ($2) {
        return $0($1($2));
    };
})();
var readStrToNumber = (function () {
    var $3 = Data_String_Common.replaceAll(",")(".");
    var $4 = Data_String_Common.replaceAll("\u2212")("-");
    return function ($5) {
        return Data_Number.fromString($3($4($foreign.replaceSpaces($5))));
    };
})();
var readStrToNumberDefault0 = (function () {
    var $6 = Data_Maybe.fromMaybe(0.0);
    return function ($7) {
        return $6(readStrToNumber($7));
    };
})();
var readStrToInt = function (s) {
    return Data_Functor.map(Data_Maybe.functorMaybe)(Data_Int.round)(readStrToNumber(s));
};
var readStrToCents$prime = function (s) {
    return Data_Int.toNumber(Data_Int.round(readStrToNumberDefault0(s) * 100.0));
};
var readStrToCents = function (s) {
    return Data_Int.round(readStrToNumberDefault0(s) * 100.0);
};
var matchStringCaseIgnored = function (pattern) {
    return function (candidates) {
        var pattern$prime = Data_String_Common.toLower(pattern);
        var matchPattern = function (candidate) {
            return startsWith(pattern$prime)(Data_String_Common.toLower(candidate));
        };
        return Data_Foldable.any(Data_Foldable.foldableArray)(Data_HeytingAlgebra.heytingAlgebraBoolean)(matchPattern)(candidates);
    };
};
var leftPad = Data_Function_Uncurried.runFn3($foreign.padStart);
var zeroPad = function (n) {
    return leftPad(n)("0");
};
var endsWith = function (searchStr) {
    return function (str) {
        return Data_Maybe.isJust(Data_String_CodeUnits.stripSuffix(searchStr)(str));
    };
};
var decodeRTF = function (rtf) {
    return $foreign.convertRTFToPlain(rtf);
};
var containsAnyPatternCaseIgnored = function (testPatterns) {
    return function (target) {
        var matchInTarget = function (testPattern) {
            return Data_String_CodeUnits.contains(Data_String_Pattern.Pattern(Data_String_Common.toLower(testPattern)))(Data_String_Common.toLower(target));
        };
        return Data_Array["null"](testPatterns) || Data_Foldable.any(Data_Foldable.foldableArray)(Data_HeytingAlgebra.heytingAlgebraBoolean)(matchInTarget)(testPatterns);
    };
};
var capitalize = function (word) {
    return Data_String_Common.toUpper(Data_String_CodePoints.take(1)(word)) + Data_String_CodePoints.drop(1)(word);
};
module.exports = {
    capitalize: capitalize,
    containsAnyPatternCaseIgnored: containsAnyPatternCaseIgnored,
    decodeRTF: decodeRTF,
    endsWith: endsWith,
    leftPad: leftPad,
    matchStringCaseIgnored: matchStringCaseIgnored,
    readStrToCents: readStrToCents,
    "readStrToCents'": readStrToCents$prime,
    readStrToInt: readStrToInt,
    readStrToNumber: readStrToNumber,
    readStrToNumberDefault0: readStrToNumberDefault0,
    removeNewlines: removeNewlines,
    rightPad: rightPad,
    startsWith: startsWith,
    startsWithAnyPatternCaseIgnored: startsWithAnyPatternCaseIgnored,
    stringContainsCaseIgnored: stringContainsCaseIgnored,
    zeroPad: zeroPad,
    convertRTFToPlain: $foreign.convertRTFToPlain,
    replaceSpaces: $foreign.replaceSpaces,
    splitByWhitespace: $foreign.splitByWhitespace
};
