// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Common = require("../Common/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var StyleProperties = require("../StyleProperties/index.js");
var StyleUtils = require("../StyleUtils/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var DefaultRendering = (function () {
    function DefaultRendering() {

    };
    DefaultRendering.value = new DefaultRendering();
    return DefaultRendering;
})();
var TableCellRendering = (function () {
    function TableCellRendering(value0) {
        this.value0 = value0;
    };
    TableCellRendering.create = function (value0) {
        return new TableCellRendering(value0);
    };
    return TableCellRendering;
})();
var textareaHeight = new TacoConstants.CustomSize("8rem");
var tacoInputStyles = function (v) {
    return Box.boxStyle([ new Box.Position(Box.Relative.value), Box.FlexColumn.value, Box.JustifyCenter.value, Box.Width.create(Data_Maybe.fromMaybe(new TacoConstants.CustomSize("100%"))(v.width)) ]);
};
var normalBorder = Box.boxStyle([ new Box.Border(TacoConstants.InputBorderDefault.value) ]);
var loadingIndicatorStyles = function (props) {
    return Box.boxStyle([ new Box.Position(Box.Absolute.value), Box.PosRight.create(new TacoConstants.CustomSize("0")), Box.PosTop.create(new TacoConstants.CustomSize("0")), new Box.Height(TacoConstants.XL.value), new Box.Width(TacoConstants.XL.value), new Box.Padding(TacoConstants.XXS.value), new Box.Opacity((function () {
        if (props.loading) {
            return 1.0;
        };
        return 0.0;
    })()), new Box.Transition([ "opacity" ]) ]);
};
var loadingBorder = React_Basic_Emotion.css()({
    border: StyleProperties.border(TacoConstants.InputBorderLoading.value),
    boxShadow: React_Basic_Emotion.str("0 0 3px 1px " + TacoConstants.colorSchemeToStr(TacoConstants.InputBorderColorLoading.value))
});
var labelStyles = function (v) {
    return Box.boxStyle([ new Box.Display(Box.Block.value), new Box.ForegroundColor(TacoConstants.TextSecondary.value), new Box.PaddingBottom(TacoConstants.XXS.value), new Box.PaddingX(TacoConstants.XS.value), Box.Style.create(React_Basic_Emotion.css()({
        fontSize: React_Basic_Emotion.str("10px"),
        textAlign: React_Basic_Emotion.str(v.align)
    })) ]);
};
var hoverBorder = Box.boxStyle([ new Box.Border(TacoConstants.InputBorderFocused.value), Box.Style.create(React_Basic_Emotion.css()({
    boxShadow: React_Basic_Emotion.str("0 0 3px 1px " + TacoConstants.colorSchemeToStr(TacoConstants.GrayDark.value))
})) ]);
var focusBorder = Box.boxStyle([ new Box.Border(TacoConstants.InputBorderFocused.value), Box.Style.create(React_Basic_Emotion.css()({
    boxShadow: React_Basic_Emotion.str("0 0 3px 1px " + TacoConstants.colorSchemeToStr(TacoConstants.PrimaryBlue.value))
})) ]);
var expandedTextareaHeight = new TacoConstants.CustomSize("66px");
var popoutContainerStyles = function (v) {
    return Box.boxStyle([ new Box.Position(Box.Absolute.value), Box.Width.create(new TacoConstants.CustomSize("100%")), Box.Height.create(new TacoConstants.CustomSize("100%")), Box.Padding.create(new TacoConstants.CustomSize("1px")), Data_Monoid.guard(Box.monoidBoxProp)(v.expandWhenActive)(Box.Style.create(Box.boxStyle([ new Box.Transition([ "height", "background-color" ]), new Box.Selector(":focus-within", [ new Box.Height(expandedTextareaHeight), new Box.BackgroundColor(TacoConstants.White.value) ]) ]))) ]);
};
var errorContainerStyles = Box.boxStyle([ new Box.PaddingTop(TacoConstants.XS.value), new Box.ForegroundColor(TacoConstants.InputBorderColorError.value), new Box.Display(Box.Block.value) ]);
var errorBorder = React_Basic_Emotion.css()({
    border: StyleProperties.border(TacoConstants.InputBorderError.value),
    boxShadow: React_Basic_Emotion.str("0 0 3px 1px " + TacoConstants.colorSchemeToStr(TacoConstants.InputBorderColorError.value))
});
var inputContainerStyles = function (props) {
    return Box.boxStyle([ Box.FlexColumn.value, new Box.Position(Box.Relative.value), Box.JustifyCenter.value, Data_Monoid.guard(Box.monoidBoxProp)(props.focused)(new Box.ZIndex(TacoConstants.zIndexDropdown)), Box.BorderRadius.create(new TacoConstants.CustomSize("2px")), new Box.Style(normalBorder), new Box.Transition([ "background-color", "border-color", "box-shadow", "height" ]), new Box.BackgroundColor(TacoConstants.InputBackgroundDefault.value), new Box.Selector(":focus-within", [ new Box.Style(focusBorder), new Box.BackgroundColor(TacoConstants.White.value) ]), new Box.Selector(":hover", [ new Box.Style(hoverBorder), new Box.BackgroundColor(TacoConstants.White.value) ]), Data_Monoid.guard(Box.monoidBoxProp)(props.textarea)(Box.Height.create(new TacoConstants.CustomSize("100%"))), Data_Monoid.guard(Box.monoidBoxProp)(props.loading)(new Box.Style(loadingBorder)), Data_Monoid.guard(Box.monoidBoxProp)(props.error)(new Box.Style(errorBorder)) ]);
};
var eqRenderingStyle = {
    eq: function (x) {
        return function (y) {
            if (x instanceof DefaultRendering && y instanceof DefaultRendering) {
                return true;
            };
            if (x instanceof TableCellRendering && y instanceof TableCellRendering) {
                return Data_Eq.eq(TacoTable_Types.eqRowHeight)(x.value0)(y.value0);
            };
            return false;
        };
    }
};
var defaultInputHeight = 22;
var inputHeight = function (v) {
    if (v instanceof TableCellRendering) {
        return TacoConstants.CustomSize.create(Data_Show.show(Data_Show.showInt)(TacoTable_Types.rowHeightPixels(v.value0) - 4 | 0) + "px");
    };
    return TacoConstants.CustomSize.create(StyleUtils.intToPx(defaultInputHeight));
};
var popoutPlaceholderHeight = function (v) {
    if (v instanceof TableCellRendering) {
        return TacoTable_Types.rowHeightToSize(v.value0);
    };
    return TacoConstants.CustomSize.create(StyleUtils.intToPx(defaultInputHeight + 4 | 0));
};
var popoutPlaceholderStyles = function (v) {
    return Box.boxStyle([ new Box.Position(Box.Relative.value), Box.Height.create((function () {
        if (v.height instanceof Data_Maybe.Just) {
            return v.height.value0;
        };
        if (v.rendering instanceof TableCellRendering) {
            return popoutPlaceholderHeight(v.rendering);
        };
        if (v.textarea && !v.expandWhenActive) {
            return textareaHeight;
        };
        return popoutPlaceholderHeight(v.rendering);
    })()) ]);
};
var textareaElementStyles = function (props) {
    return Box.boxStyle([ Box.Style.create(React_Basic_Emotion.css()({
        resize: React_Basic_Emotion.str("none")
    })), Box.Height.create(new TacoConstants.CustomSize("100%")), Box.PaddingY.create(new TacoConstants.CustomSize("4px")), Data_Monoid.guard(Box.monoidBoxProp)(props.expandWhenActive)(Box.Style.create(Box.boxStyle([ new Box.Position(Box.Absolute.value), Box.PosTop.create(new TacoConstants.CustomSize("0px")), Box.PosLeft.create(new TacoConstants.CustomSize("0px")), new Box.Overflow(Box.Hidden.value, Box.Hidden.value), Box.PaddingY.create(new TacoConstants.CustomSize("0")), Box.Style.create(React_Basic_Emotion.css()({
        lineHeight: React_Basic_Emotion.str((function () {
            if (props.rendering instanceof TableCellRendering) {
                return Data_Show.show(Data_Show.showInt)(TacoTable_Types.rowHeightPixels(props.rendering.value0) - 4 | 0) + "px";
            };
            return StyleUtils.intToPx(defaultInputHeight);
        })())
    })), new Box.Selector(":focus-within", [ new Box.Height(expandedTextareaHeight), new Box.ZIndex(TacoConstants.zIndexDropdown), new Box.Overflow(Box.Auto.value, Box.Auto.value), Box.PaddingTop.create(new TacoConstants.CustomSize("4px")), Box.Style.create(React_Basic_Emotion.css()({
        lineHeight: React_Basic_Emotion.str("inherit")
    })) ]) ]))) ]);
};
var inputStyles = function (v) {
    var fontWeight = (function () {
        if (v.fontWeight instanceof Data_Maybe.Nothing) {
            return Common.emptyArray;
        };
        if (v.fontWeight instanceof Data_Maybe.Just) {
            return [ new Box.FontWeight(v.fontWeight.value0) ];
        };
        throw new Error("Failed pattern match at TacoInput.Styles (line 173, column 18 - line 175, column 33): " + [ v.fontWeight.constructor.name ]);
    })();
    var color = (function () {
        if (v.color instanceof Data_Maybe.Nothing) {
            return Common.emptyArray;
        };
        if (v.color instanceof Data_Maybe.Just) {
            return [ new Box.ForegroundColor(v.color.value0) ];
        };
        throw new Error("Failed pattern match at TacoInput.Styles (line 170, column 13 - line 172, column 38): " + [ v.color.constructor.name ]);
    })();
    return Box.boxStyle(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ new Box.Position(Box.Relative.value), Box.Style.create((function () {
        if (v.error) {
            return errorBorder;
        };
        return React_Basic_Emotion.css()({
            border: StyleProperties.none,
            outline: StyleProperties.none
        });
    })()), Box.Width.create(new TacoConstants.CustomSize("100%")), Box.PaddingLeft.create(new TacoConstants.CustomSize("4px")), new Box.PaddingRight((function () {
        if (v.currency) {
            return new TacoConstants.CustomSize("18px");
        };
        return new TacoConstants.CustomSize("4px");
    })()), Box.PaddingY.create(new TacoConstants.CustomSize("0")), new Box.BackgroundColor(TacoConstants.Transparent.value), Box.BorderRadius.create(new TacoConstants.CustomSize("2px")), new Box.Disabled([ new Box.BackgroundColor(TacoConstants.GrayLightest.value), new Box.ForegroundColor(TacoConstants.GrayDark.value), new Box.Cursor(Box.NotAllowed.value) ]), (function () {
        if (v.rendering instanceof TableCellRendering) {
            return Box.Style.create(Box.boxStyle([ Box.Height.create(TacoConstants.CustomSize.create(Data_Show.show(Data_Show.showInt)(TacoTable_Types.rowHeightPixels(v.rendering.value0) - 4 | 0) + "px")) ]));
        };
        return Box.Style.create(Box.boxStyle([ Box.PaddingY.create(new TacoConstants.CustomSize("4px")) ]));
    })(), Box.Style.create(React_Basic_Emotion.css()({
        textAlign: React_Basic_Emotion.str(v.align)
    })), Data_Monoid.guard(Box.monoidBoxProp)(v.textarea)(Box.Style.create(textareaElementStyles({
        expandWhenActive: v.expandWhenActive,
        rendering: v.rendering
    }))) ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(color)(fontWeight)));
};
var customComponentWrapperStyles = function (styles) {
    return Box.boxStyle(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ Box.Style.create(React_Basic_Emotion.css()({
        border: StyleProperties.none,
        outline: StyleProperties.none
    })) ])(styles));
};
var currencyContainerStyles = React_Basic_Emotion.css()({
    position: React_Basic_Emotion.str("absolute"),
    right: React_Basic_Emotion.str("8px"),
    top: React_Basic_Emotion.str("4px"),
    userSelect: StyleProperties.none,
    pointerEvents: StyleProperties.none
});
var clearButtonStyles = function (v) {
    return Box.boxStyle([ new Box.Position(Box.Absolute.value), Box.PosRight.create(new TacoConstants.CustomSize("0")), Box.PosTop.create(new TacoConstants.CustomSize("0")), Box.Height.create(new TacoConstants.CustomSize("100%")), new Box.Overflow(Box.Hidden.value, Box.Hidden.value) ]);
};
module.exports = {
    DefaultRendering: DefaultRendering,
    TableCellRendering: TableCellRendering,
    defaultInputHeight: defaultInputHeight,
    inputHeight: inputHeight,
    popoutPlaceholderHeight: popoutPlaceholderHeight,
    textareaHeight: textareaHeight,
    expandedTextareaHeight: expandedTextareaHeight,
    inputContainerStyles: inputContainerStyles,
    textareaElementStyles: textareaElementStyles,
    popoutContainerStyles: popoutContainerStyles,
    popoutPlaceholderStyles: popoutPlaceholderStyles,
    inputStyles: inputStyles,
    normalBorder: normalBorder,
    focusBorder: focusBorder,
    hoverBorder: hoverBorder,
    loadingBorder: loadingBorder,
    errorBorder: errorBorder,
    labelStyles: labelStyles,
    errorContainerStyles: errorContainerStyles,
    loadingIndicatorStyles: loadingIndicatorStyles,
    clearButtonStyles: clearButtonStyles,
    currencyContainerStyles: currencyContainerStyles,
    tacoInputStyles: tacoInputStyles,
    customComponentWrapperStyles: customComponentWrapperStyles,
    eqRenderingStyle: eqRenderingStyle
};
