// Generated by purs version 0.14.5
"use strict";
var Data_Number_Format = require("../Data.Number.Format/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Utils = require("../Utils/index.js");
var styles = React_Basic_Emotion.css()({
    backgroundColor: React_Basic_Emotion.str("greenyellow")
});
var component = TofuHooks.mkHookComponent("PursFromTs")(function (props) {
    return function __do() {
        var v = TofuHooks.useState(Utils.fromNullable(0.0)(props.initValue))();
        var increment = TofuHooks.useCallback([  ])(v.value1(function (v1) {
            return v1 + 1.0;
        }))();
        var text = "Counter value: " + Data_Number_Format.toString(v.value0);
        return React_Basic.fragment([ React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            css: styles,
            className: "counter",
            children: [ React_Basic_DOM.text(text) ]
        }), React_Basic_DOM_Generated.button()({
            onClick: React_Basic_Events.handler_(increment),
            children: [ React_Basic_DOM.text("Increment") ]
        }) ]);
    };
});
module.exports = {
    styles: styles,
    component: component
};
