// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var FileUpload = require("../FileUpload/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var StateHooks = require("../StateHooks/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_UploadStatus = require("../Types.UploadStatus/index.js");
var uploadSAPSalaries = TofuHooks.mkHookComponent("UploadSAPSalaries")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState(Types_UploadStatus.Standby.value)();
        var v2 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var appDispatch = StateHooks.useDispatch();
        var uploadSalariesInput = FileUpload.mkFileUpload({
            projectId: Data_Maybe.Nothing.value,
            uploadType: FileUpload.SAPSalariesUpload.value,
            endpointUrl: "/api/uploadSAPSalaries",
            uniqueId: "sap-salaries-upload-input",
            uploadStatus: v1.value0,
            setUploadStatus: v1.value1,
            filename: v2.value0,
            setFilename: v2.value1,
            onSuccess: Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(appDispatch(Actions.GetImportInvoicesRequest.value))
        });
        var uploadSalariesLabel = FileUpload.uploadFileLabel({
            filename: v2.value0,
            className: "upload-invoices",
            uniqueId: "sap-salaries-upload-input",
            label: "Tuo uusia palkkoja"
        });
        return React_Basic_DOM_Generated.div()({
            className: "flex-centered",
            children: [ uploadSalariesInput, uploadSalariesLabel, FileUpload.uploadStatusToJSX(v1.value0) ]
        });
    };
});
var uploadInvoices = TofuHooks.mkHookComponent("UploadInvoices")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState(Types_UploadStatus.Standby.value)();
        var v2 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var appDispatch = StateHooks.useDispatch();
        var uploadInvoicesInput = FileUpload.mkFileUpload({
            projectId: Data_Maybe.Nothing.value,
            uploadType: FileUpload.InvoicesUpload.value,
            endpointUrl: "/api/uploadInvoices",
            uniqueId: "invoices-upload-input",
            uploadStatus: v1.value0,
            setUploadStatus: v1.value1,
            filename: v2.value0,
            setFilename: v2.value1,
            onSuccess: Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(appDispatch(Actions.GetImportInvoicesRequest.value))
        });
        var uploadInvoicesLabel = FileUpload.uploadFileLabel({
            filename: v2.value0,
            className: "upload-invoices",
            uniqueId: "invoices-upload-input",
            label: "Tuo laskuja"
        });
        return React_Basic_DOM_Generated.div()({
            className: "flex-centered",
            children: [ uploadInvoicesInput, uploadInvoicesLabel, FileUpload.uploadStatusToJSX(v1.value0) ]
        });
    };
});
module.exports = {
    uploadInvoices: uploadInvoices,
    uploadSAPSalaries: uploadSAPSalaries
};
