// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var TacoText = require("../TacoText/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var component = TofuHooks.mkHookComponent("NewEstimationResourcesList")(function (v) {
    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic.fragment([ TacoText.component()()({
        text: "WIP - New estimation resources list"
    }) ]));
});
module.exports = {
    component: component
};
