exports._prepareImportEstimationProjectFormData = (name) => (code) => (
  version
) => (currency) => (file) => () => {
  let formData = new FormData();
  formData.append("name", name);
  formData.append("code", code);
  formData.append("version", version);
  formData.append("currency", currency);
  formData.append("file", file);
  formData.append("classificationChanged", false);
  return formData;
};

exports._readFileContentsAsJSON = (file) => (cb) => () => {
  let reader = new FileReader();
  reader.onloadend = (e) => {
    cb(JSON.parse(e.target.result))();
  };
  reader.readAsText(file);
};
