import clsx from "clsx";
import { Icon } from "@tocoman/ui";
import { IconCheck24Px, IconCloseCrossV1, IconFileUploadV1 } from "@tocoman/ui";
import { ChangeEvent, useRef, useState } from "react";

type SingleFileUploadProps = {
  onFileSelect: (file: File | null) => void;
  className?: string;
  buttonLabel: string;
  dragDropLabel: string;
};
export const SingleFileSelect = ({
  onFileSelect,
  dragDropLabel,
  buttonLabel,
  className,
}: SingleFileUploadProps) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target;

    if (fileInput.files && fileInput.files.length > 0) {
      const file = fileInput.files[0];
      setSelectedFile(file);
      onFileSelect(file);
    }
  };

  const handleRemoval = () => {
    setSelectedFile(null);
    onFileSelect(null);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    setSelectedFile(event.dataTransfer.files[0]);
    event.preventDefault();
  };

  return (
    <div
      className={clsx([
        "bg-white p-4 rounded-md border-light border border-dashed",
        className,
      ])}
      onDrop={handleDrop}
      onDragOver={(e) => e.preventDefault()}
    >
      <input
        type="file"
        accept="*/*"
        onChange={handleChange}
        style={{ display: "none" }}
        ref={fileInputRef}
      />
      <label
        tabIndex={0}
        htmlFor="file-select-input"
        className={clsx([
          "inline-block text-primary cursor-pointer bg-slate mr-4 py-2 px-4 rounded-md select-none",
          "hover:bg-primary-active",
        ])}
        onClick={() => fileInputRef.current?.click()}
      >
        <span>{buttonLabel}</span>
      </label>
      <span className="inline text-dark ml-3 mr-6">
        <Icon
          className="inline-block -mb-1 mr-2"
          scale={1.3}
          icon={IconFileUploadV1}
        />
        {dragDropLabel}
      </span>
      {selectedFile && (
        <div className="py-2 flex w-full" key={selectedFile.name}>
          <Icon
            className="mr-1 text-primary"
            scale={1.5}
            icon={IconCheck24Px}
          />
          <span>{selectedFile.name}</span>
          <Icon
            className="ml-auto text-gray hover:text-secondary-filled-hover active:text-secondary-filled-active"
            tabIndex={0}
            scale={1.5}
            onClick={handleRemoval}
            icon={IconCloseCrossV1}
          />
        </div>
      )}
    </div>
  );
};
