// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var SeparatorHeading_Styles = require("../SeparatorHeading.Styles/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var component = TofuHooks.mkHookComponent("SeparatorHeading")(function (props) {
    var text = TacoText.component()()({
        text: props.title,
        variant: new TacoText_Types.Heading(3),
        transform: TacoText_Types.Uppercase.value,
        weight: TacoText_Types.Bold.value,
        color: props.colorSet.border
    });
    var columnsJSX = function (children) {
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "columns",
            css: SeparatorHeading_Styles.columnsStyles,
            children: children
        });
    };
    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        className: "__SeparatorHeading",
        css: SeparatorHeading_Styles.separatorHeadingStyles(props),
        children: [ Box.box("LeftItemsContainer")([ Box.AlignCenter.value, Box.Width.create(new TacoConstants.CustomSize(props.titleWidth)) ])([ (function () {
            if (props.onClose instanceof Data_Maybe.Just) {
                return TacoButton.component()()({
                    onClick: new Data_Maybe.Just(props.onClose.value0),
                    color: props.colorSet.border,
                    icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconGt24Px.value),
                    iconRotation: 90,
                    iconSize: new TacoConstants.CustomSize("14px"),
                    children: new Data_Maybe.Just([ text ])
                });
            };
            if (props.onClose instanceof Data_Maybe.Nothing) {
                return text;
            };
            throw new Error("Failed pattern match at SeparatorHeading (line 43, column 15 - line 52, column 32): " + [ props.onClose.constructor.name ]);
        })() ]), Data_Maybe.maybe(Data_Monoid.mempty(React_Basic.monoidJSX))(columnsJSX)(props.columns) ]
    }));
});
module.exports = {
    component: component
};
