/**
 * Check if the given value is number
 */
export function isNumber(value: string | number | boolean | null): boolean {
  if (typeof value === "string") {
    return !isNaN(Number(value));
  }
  if (typeof value === "number") {
    return !isNaN(value);
  } else {
    return false;
  }
}

export function isPositive(value: string | number | boolean | null): boolean {
  if (isNumber(value)) {
    return Number(value) >= 0;
  } else {
    return false;
  }
}
