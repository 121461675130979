// Generated by purs version 0.14.5
"use strict";
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Formatters = require("../Formatters/index.js");
var FormattingHelpers = require("../FormattingHelpers/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var formatCellValue = function (v) {
    return function (v1) {
        if (v instanceof TacoTable_Types.PlainTextCell) {
            return React_Basic_DOM.text(v.value0(v1));
        };
        if (v instanceof TacoTable_Types.PlainNumberCell) {
            return React_Basic_DOM.text(Formatters.formatDecimalValue(v.value0)(v.value1(v1)));
        };
        if (v instanceof TacoTable_Types.MaybePlainNumberCell) {
            return Data_Maybe.maybe(React_Basic.empty)(React_Basic_DOM.text)(Data_Functor.map(Data_Maybe.functorMaybe)(Formatters.formatDecimalValue(v.value0))(v.value1(v1)));
        };
        if (v instanceof TacoTable_Types.PlainEuroCell) {
            return React_Basic_DOM.text(Formatters.formatCurrencyValue("EUR")(v.value0(v1)));
        };
        if (v instanceof TacoTable_Types.MaybePlainEuroCell) {
            return Data_Maybe.maybe(React_Basic.empty)(React_Basic_DOM.text)(Data_Functor.map(Data_Maybe.functorMaybe)(Formatters.formatCurrencyValue("EUR"))(v.value0(v1)));
        };
        if (v instanceof TacoTable_Types.ColoredEuroCell) {
            return FormattingHelpers.formatCurrencyDifferenceValue(v.value0(v1));
        };
        if (v instanceof TacoTable_Types.PlainCurrencyCell) {
            return React_Basic_DOM.text(Formatters.formatCurrencyValue(v.value0)(v.value1(v1)));
        };
        if (v instanceof TacoTable_Types.MaybePlainCurrencyCell) {
            return Data_Maybe.maybe(React_Basic.empty)(React_Basic_DOM.text)(Data_Functor.map(Data_Maybe.functorMaybe)(Formatters.formatCurrencyValue(v.value0))(v.value1(v1)));
        };
        if (v instanceof TacoTable_Types.PlainDateCell) {
            return React_Basic_DOM.text(Formatters.formatDateValue(v.value0(v1)));
        };
        if (v instanceof TacoTable_Types.PlainDateStringCell) {
            return React_Basic_DOM.text(Formatters.formatDateStringValue(v.value0(v1)));
        };
        if (v instanceof TacoTable_Types.PlainDateTimeStringCell) {
            return React_Basic_DOM.text(Formatters.formatDateTimeStringValue(v.value0(v1)));
        };
        if (v instanceof TacoTable_Types.PlainPercentageCell) {
            return React_Basic_DOM.text(Formatters.formatPercentageValue({
                precision: v.value0,
                value: v.value1(v1)
            }));
        };
        if (v instanceof TacoTable_Types.MaybePlainPercentageCell) {
            return Data_Maybe.maybe(React_Basic.empty)((function () {
                var $34 = Formatters["formatPercentageValue'"](v.value0);
                return function ($35) {
                    return React_Basic_DOM.text($34($35));
                };
            })())(v.value1(v1));
        };
        if (v instanceof TacoTable_Types.ColoredPercentageCell) {
            return FormattingHelpers.formatPercentageDifferenceValueJSX({
                precision: v.value0,
                value: Data_Newtype.un()(Types_Unit.PercentageOfZeroToOne)(v.value1(v1))
            });
        };
        if (v instanceof TacoTable_Types.ColoredEuroAndPercentCell) {
            return FormattingHelpers.formatCurrencyDifferenceAndPercentageValue(Data_Functor.map(Data_Functor.functorFn)(function (y) {
                return {
                    diff: y.euro,
                    margin: y.percent
                };
            })(v.value1)(v1));
        };
        if (v instanceof TacoTable_Types.JSXCell) {
            return v.value1(v1);
        };
        if (v instanceof TacoTable_Types.FoldingIndicatorPlaceholder) {
            return React_Basic.empty;
        };
        throw new Error("Failed pattern match at TacoTable.CellFormatting (line 18, column 1 - line 18, column 74): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var extractCellText = function (v) {
    return function (v1) {
        if (v instanceof TacoTable_Types.PlainTextCell) {
            return v.value0(v1);
        };
        return "";
    };
};
module.exports = {
    extractCellText: extractCellText,
    formatCellValue: formatCellValue
};
