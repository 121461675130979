// Generated by purs version 0.14.5
"use strict";
var TofuHooks = require("../TofuHooks/index.js");
var Utils_Listeners = require("../Utils.Listeners/index.js");
var useWindowResizeListener = function (handler) {
    return TofuHooks.useEffect([  ])(function __do() {
        var listenerF = Utils_Listeners.addWindowResizeListener(handler)();
        return Utils_Listeners.removeWindowResizeListener(listenerF);
    });
};
module.exports = {
    useWindowResizeListener: useWindowResizeListener
};
