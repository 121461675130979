exports._getKeyCode = function (event) {
  return event.key;
};

exports._preventDefault = function (event) {
  return function () {
    event.preventDefault();
  };
};

/**
 * Most recently registered key handlers will be in the beginning of the list.
 */
const registeredHandlers = [];

/**
 * Finds a registered key handler by unique key
 */
const findRegisteredKeyHandler = (key) =>
  registeredHandlers.findIndex((h) => h.key === key);

// enables debug logs, useful for debugging keyboard handler (de)registration order
const DEBUG = false;

/**
 * De-registers key handler by unique key
 */
exports._deregisterKeyHandler = (key) => {
  const existingRegistrationIndex = findRegisteredKeyHandler(key);

  if (existingRegistrationIndex === -1) {
    console.log(
      `Tried de-registering a key handler for key ${key} that was not previously registered.`
    );
  } else {
    // useful for debugging keyboard handler de-registration order
    if (DEBUG) {
      console.log(
        `De-registered key handler for ${key} (${registeredHandlers[existingRegistrationIndex].keyboardEventCodes})`
      );
    }

    registeredHandlers.splice(existingRegistrationIndex, 1);
  }
};

/**
 * Registers a new key handler which will receive only requested
 * keyboardEventCodes. The key handler can be de-registered with the provided
 * unique key.
 */
exports._registerKeyHandler = (handler) => (keyboardEventCodes) => (key) => {
  const existingRegistrationIndex = findRegisteredKeyHandler(key);

  if (existingRegistrationIndex !== -1) {
    console.log(
      `Existing key handler found for key ${key}, maybe this is a duplicate key or handler leak somewhere? Skipping registration.`
    );
  } else {
    const registeredHandler = { key, handler, keyboardEventCodes };
    registeredHandlers.unshift(registeredHandler);

    // useful for debugging keyboard handler registration order
    if (DEBUG) {
      console.log(`Registered key handler for ${key} (${keyboardEventCodes})`);
    }
  }
};

const capturesAllKeys = (registeredHandler) =>
  registeredHandler.keyboardEventCodes.includes("All");

window.addEventListener("keydown", (event) => {
  const keyboardEventCode = event.code;

  // Loop over all registered handlers and attempt to find ones where
  // keyboardEventCodes match
  for (const registeredHandler of registeredHandlers) {
    const keyboardEventCodeMatch =
      registeredHandler.keyboardEventCodes.includes(keyboardEventCode) ||
      capturesAllKeys(registeredHandler);

    if (keyboardEventCodeMatch && !event.repeat) {
      const handled = registeredHandler.handler(event);

      if (handled === false) {
        // If the handler returns false we keep propagating this event up the
        // stack
        continue;
      } else {
        // In all other cases, assume the event was handled and stop here
        break;
      }
    }
  }
});
