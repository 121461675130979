// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoModal = require("../TacoModal/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var UseFetch = require("../UseFetch/index.js");
var modal = TofuHooks.mkHookComponent("outageMessageModal")(function (v) {
    return function __do() {
        var v1 = UseFetch.useFetchValue(Data_Eq.eqBoolean)(v.isActive)(function (v1) {
            return v1.serverStatus;
        })(Actions.GetServerStatusAction.value)();
        var dispatch = StateHooks.useDispatch();
        var outageMessage = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function (v2) {
            return v2.outageMessage;
        })(v1.value0);
        var v2 = TofuHooks.useState(Data_Maybe.fromMaybe("")(outageMessage))();
        var setOutageMessage = function (message) {
            var newOutageMessage = (function () {
                var $12 = message === "";
                if ($12) {
                    return Data_Maybe.Nothing.value;
                };
                return new Data_Maybe.Just(message);
            })();
            return dispatch(new Actions.SetOutageMessageAction({
                outageMessage: newOutageMessage
            }));
        };
        var modalActions = TacoModal.modalActions({
            contents: [ TacoButton.component()()({
                onClick: new Data_Maybe.Just(v.closeModal),
                text: "Peruuta"
            }), TacoButton.component()()({
                text: "Tallenna",
                color: TacoConstants.SecondaryRed.value,
                buttonStyle: TacoButton_Types.Filled.value,
                onClick: new Data_Maybe.Just(function __do() {
                    setOutageMessage(v2.value0)();
                    return v.closeModal();
                })
            }) ]
        });
        var input = TacoInput.stringInput()()(Data_Eq.eqString)({
            label: "Virhetiedote, n\xe4kyy kaikille k\xe4ytt\xe4jille sivun yl\xe4laidassa. Tyhjenn\xe4 poistaaksesi.",
            value: v2.value0,
            clearButton: true,
            onChange: v2.value1
        });
        return TacoModal.component()()({
            onClose: v.closeModal,
            isActive: v.isActive,
            heading: "Aseta virhetiedote",
            contents: [ input, modalActions ]
        });
    };
});
var button = function (openModal) {
    return TacoButton.component()()({
        onClick: new Data_Maybe.Just(openModal),
        buttonStyle: TacoButton_Types.Filled.value,
        color: TacoConstants.SecondaryRed.value,
        text: "Aseta virhetiedote",
        testId: "outageMessageModalOpen"
    });
};
var component = TofuHooks.mkHookComponent("outageMessage")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState(false)();
        var openModal = v1.value1(function (v2) {
            return true;
        });
        var closeModal = v1.value1(function (v2) {
            return false;
        });
        return Box.box("outageMessageWrapper")([  ])([ button(openModal), modal({
            isActive: v1.value0,
            closeModal: closeModal
        }) ]);
    };
});
module.exports = {
    component: component
};
