// Generated by purs version 0.14.5
"use strict";
var Client_WASM = require("../Client.WASM/index.js");
var Common = require("../Common/index.js");
var ComponentUtils = require("../ComponentUtils/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var CostEstimation_Calculations = require("../CostEstimation.Calculations/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Array_NonEmpty_Internal = require("../Data.Array.NonEmpty.Internal/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var EstimationComponentsTable = require("../EstimationComponentsTable/index.js");
var Hierarchy = require("../Hierarchy/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var TacoTableUtils = require("../TacoTableUtils/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_ClassificationGroup = require("../Types.ClassificationGroup/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_ComponentsView = require("../Types.ComponentsView/index.js");
var Types_CostEstimation = require("../Types.CostEstimation/index.js");
var Types_Element = require("../Types.Element/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var UseKeydownListener = require("../UseKeydownListener/index.js");
var Utils = require("../Utils/index.js");
var Utils_Keyboard = require("../Utils.Keyboard/index.js");
var selected = function (v) {
    return v.checkboxState;
};
var mkTopicRow = function (costClassesWithHash) {
    return function (getName) {
        return function (xs) {
            var unECWR = function (v) {
                return v;
            };
            var name = Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.head(xs))(function (x) {
                return Data_Nullable.toMaybe(getName(x));
            });
            var all_total_price_by_cost_classes = Control_Bind.bindFlipped(Control_Bind.bindArray)(function ($148) {
                return (function (v) {
                    return v.totalPriceByCostClasses;
                })(unECWR($148));
            })(xs);
            var costClassSum = function (cc) {
                var eqCC = function (r) {
                    return Data_Eq.eq(Types_Newtypes.eqCostClassCode)(r.costClassCode)(cc.costClassCode);
                };
                var matches = Data_Array.filter(eqCC)(all_total_price_by_cost_classes);
                return {
                    costClassCode: cc.costClassCode,
                    hours: Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
                        return v.hours;
                    })(matches)),
                    totalPrice: Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
                        return v.totalPrice;
                    })(matches)),
                    totalPriceWithoutSocialExpenses: Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
                        return v.totalPriceWithoutSocialExpenses;
                    })(matches))
                };
            };
            var _total_price_without_social_expenses = function (v) {
                return v.totalPriceWithoutSocialExpenses;
            };
            var _total_price = function (v) {
                return v.totalPrice;
            };
            var _hours = function (v) {
                return v.hours;
            };
            var topicRow = (function () {
                var total_price_by_hourly_cost_classes = Data_Functor.map(Data_Functor.functorArray)(costClassSum)(Data_Array.filter(function (v) {
                    return v.hourlyPricing;
                })(costClassesWithHash.value));
                var total_price_by_cost_classes = Data_Functor.map(Data_Functor.functorArray)(costClassSum)(costClassesWithHash.value);
                var hours = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(_hours)(xs));
                var hourly_total_price = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
                    return v.totalPrice;
                })(total_price_by_hourly_cost_classes));
                var hourly_rate = Utils.defaultZeroDiv(hourly_total_price)(hours);
                return {
                    resources: Common.emptyArray,
                    component: {
                        id: 0,
                        componentType: Types_Component.componentTypeToInt(Types_Component.TopicComponent.value),
                        subProjectId: Utils.nullValue,
                        subProjectCode: Utils.nullValue,
                        subProjectName: Utils.nullValue,
                        checked: false,
                        flagged: false,
                        group: Utils.nullValue,
                        costGroupCode: Utils.nullValue,
                        costGroupDescription: Utils.nullValue,
                        code: Utils.nullValue,
                        classCode: Utils.nullValue,
                        description: Data_Maybe.fromMaybe("")(name),
                        unit: Utils.nullValue,
                        amount: 0.0,
                        memo: ""
                    },
                    locations: Common.emptyArray,
                    totalPrice: Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(_total_price)(xs)),
                    totalPriceWithoutSocialExpenses: Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(_total_price_without_social_expenses)(xs)),
                    totalPriceByCostClasses: total_price_by_cost_classes,
                    hours: hours,
                    hourlyRate: hourly_rate,
                    checkboxState: false,
                    projectOfOrigin: Data_Maybe.Nothing.value,
                    isSplitComponentWithMissingResources: false
                };
            })();
            return topicRow;
        };
    };
};
var mkBuildingDescriptionBySpecId = function (key) {
    return function (elements$prime) {
        return function (elementSpecs$prime) {
            var matchId = function (id) {
                return function (v) {
                    return Data_Eq.eq(Types_Element.eqElementId)(v.elementId)(id);
                };
            };
            var getDescription = function (v) {
                return {
                    text: Data_Newtype.unwrap()(v.code) + (" " + (v.description + (" - " + (v.rtCode + (" " + v.rtDescription))))),
                    elementId: v.elementId
                };
            };
            var findBuildingDescription = function (v) {
                var v1 = Data_Nullable.toMaybe(v.elementId);
                if (v1 instanceof Data_Maybe.Nothing) {
                    return Data_Maybe.Nothing.value;
                };
                if (v1 instanceof Data_Maybe.Just) {
                    return Data_Functor.map(Data_Maybe.functorMaybe)(Data_Functor.map(Data_Functor.functorFn)(Data_Tuple.Tuple.create(v.elementSpecId))(getDescription))(Data_Array.find(matchId(v1.value0))(elements$prime));
                };
                throw new Error("Failed pattern match at ComponentsViewUtils (line 509, column 54 - line 511, column 99): " + [ v1.constructor.name ]);
            };
            return function __do() {
                var value = ReactHooksUtils.usePersistMemo("mkBuildingDescriptionBySpecId-" + key)([ ReactHooksUtils.utf(elements$prime), ReactHooksUtils.utf(elementSpecs$prime) ])(function (v) {
                    var pairs = Data_Array.mapMaybe(findBuildingDescription)(elementSpecs$prime);
                    return Data_Map_Internal.fromFoldable(Types_Newtypes.ordElementSpecId)(Data_Foldable.foldableArray)(pairs);
                })();
                return value;
            };
        };
    };
};
var handleCursorNavigation = function (v) {
    return UseKeydownListener.useKeydownListener([  ])(v.isActive)([ Utils_Keyboard.ArrowUp.value, Utils_Keyboard.ArrowDown.value, Utils_Keyboard.Escape.value ])(ReactHooksUtils.utf("ComponentsViewDetailsNavigation"))(function (keyDownEvent) {
        if (keyDownEvent.key instanceof Utils_Keyboard.ArrowUp) {
            return function __do() {
                Utils_Keyboard.preventDefault(keyDownEvent.event)();
                return v.selectComponentRelative(-1 | 0)();
            };
        };
        if (keyDownEvent.key instanceof Utils_Keyboard.ArrowDown) {
            return function __do() {
                Utils_Keyboard.preventDefault(keyDownEvent.event)();
                return v.selectComponentRelative(1)();
            };
        };
        if (keyDownEvent.key instanceof Utils_Keyboard.Escape) {
            return v.selectComponent(false)(Data_Maybe.Nothing.value);
        };
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    });
};
var groupByLocation = function (projectLocations) {
    return function (costClassesWithHash) {
        return function (sortingCriteria) {
            return function (xs) {
                var transformMatch = function (matchCriteriaFn) {
                    return function (v) {
                        var filteredLocations = Data_Array.filter(matchCriteriaFn)(v.locations);
                        var filteredLocationsAmount = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v1) {
                            return v1.amount;
                        })(filteredLocations));
                        var ratio = Utils.defaultZeroDiv(filteredLocationsAmount)(v.component.amount);
                        var filteredRatioHours = ratio * v.hours;
                        var filteredRatioTotalPrice = ratio * v.totalPrice;
                        var filtered_ratio_total_price_by_cost_classes = Data_Functor.mapFlipped(Data_Functor.functorArray)(v.totalPriceByCostClasses)(function (r) {
                            return {
                                totalPrice: r.totalPrice * ratio,
                                costClassCode: r.costClassCode,
                                hours: r.hours,
                                totalPriceWithoutSocialExpenses: r.totalPriceWithoutSocialExpenses
                            };
                        });
                        var alteredComponent = {
                            amount: filteredLocationsAmount,
                            checked: v.component.checked,
                            classCode: v.component.classCode,
                            code: v.component.code,
                            componentType: v.component.componentType,
                            costGroupCode: v.component.costGroupCode,
                            costGroupDescription: v.component.costGroupDescription,
                            description: v.component.description,
                            flagged: v.component.flagged,
                            group: v.component.group,
                            id: v.component.id,
                            memo: v.component.memo,
                            subProjectCode: v.component.subProjectCode,
                            subProjectId: v.component.subProjectId,
                            subProjectName: v.component.subProjectName,
                            unit: v.component.unit
                        };
                        var $75 = Data_Array.length(filteredLocations) === 0 || filteredLocationsAmount === 0.0;
                        if ($75) {
                            return Data_Maybe.Nothing.value;
                        };
                        return Data_Maybe.Just.create(Types_CostEstimation.EstimationComponentWithResources({
                            resources: v.resources,
                            component: alteredComponent,
                            locations: filteredLocations,
                            totalPrice: filteredRatioTotalPrice,
                            totalPriceWithoutSocialExpenses: v.totalPriceWithoutSocialExpenses,
                            totalPriceByCostClasses: filtered_ratio_total_price_by_cost_classes,
                            hours: filteredRatioHours,
                            hourlyRate: v.hourlyRate,
                            checkboxState: v.checkboxState,
                            projectOfOrigin: v.projectOfOrigin,
                            isSplitComponentWithMissingResources: v.isSplitComponentWithMissingResources
                        }));
                    };
                };
                var split = function (code) {
                    return Data_String_Common.split(">")(code);
                };
                var matchCode = function (code) {
                    return function (v) {
                        return v.code === code;
                    };
                };
                var isPrefix = function (pattern) {
                    return function (str) {
                        return Data_Maybe.isJust(Data_String_CodeUnits.stripPrefix(pattern)(str));
                    };
                };
                var isPrefixedByLocation = function (prefix) {
                    var matchPrefix = function (loc) {
                        return isPrefix(prefix)(loc.locationCode);
                    };
                    return transformMatch(matchPrefix);
                };
                var getName = function (code) {
                    return Utils.pureNullable(Data_Maybe.fromMaybe(code)(Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.find(matchCode(code))(projectLocations))(function (v) {
                        return Data_Maybe.Just.create(code + (" - " + v.description));
                    })));
                };
                var exactPrefixMatch = function (prefix) {
                    var matchPrefix = function (loc) {
                        return prefix === loc.locationCode;
                    };
                    return transformMatch(matchPrefix);
                };
                var depth = function (code) {
                    return Data_Array.length(split(code));
                };
                var isDepth = function (n) {
                    return function (str) {
                        return depth(str) === n;
                    };
                };
                var filterAndSummarize = function (level) {
                    return function (codes) {
                        return function (prefix) {
                            var filtered = TacoTableUtils.sortByCriteria(sortingCriteria)(Data_Array.mapMaybe(isPrefixedByLocation(prefix))(xs));
                            var v = Data_Array_NonEmpty.fromArray(filtered);
                            if (v instanceof Data_Maybe.Nothing) {
                                return Common.emptyArray;
                            };
                            if (v instanceof Data_Maybe.Just) {
                                var topicRow = mkTopicRow(costClassesWithHash)(function (v1) {
                                    return getName(prefix);
                                })(filtered);
                                var childCodes = Data_Array.filter(isPrefix(prefix))(codes);
                                var nextLevelCodes = Data_Array.filter(isDepth(level + 1 | 0))(childCodes);
                                var children = (function () {
                                    var $80 = depth(prefix) >= 5 || Data_Array["null"](nextLevelCodes);
                                    if ($80) {
                                        return filtered;
                                    };
                                    var nextLevels = Control_Bind.bindFlipped(Control_Bind.bindArray)(filterAndSummarize(level + 1 | 0)(childCodes))(nextLevelCodes);
                                    var exact = TacoTableUtils.sortByCriteria(sortingCriteria)(Data_Array.mapMaybe(exactPrefixMatch(prefix))(filtered));
                                    return Data_Semigroup.append(Data_Semigroup.semigroupArray)(exact)(nextLevels);
                                })();
                                return Data_Semigroup.append(Data_Semigroup.semigroupArray)([ topicRow ])(children);
                            };
                            throw new Error("Failed pattern match at ComponentsViewUtils (line 483, column 7 - line 499, column 35): " + [ v.constructor.name ]);
                        };
                    };
                };
                var _locationCode = function (v) {
                    return v.code;
                };
                var locationCodes = Data_Array.sort(Data_Ord.ordString)(Data_Array.nub(Data_Ord.ordString)(Data_Array.filter(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_String_Common["null"]))(Data_Functor.map(Data_Functor.functorArray)(_locationCode)(projectLocations))));
                var mainLocationCodes = Data_Array.nub(Data_Ord.ordString)(Data_Array.sort(Data_Ord.ordString)(Data_Array.mapMaybe(function ($149) {
                    return Data_Array.head(split($149));
                })(locationCodes)));
                return Control_Bind.bindFlipped(Control_Bind.bindArray)(filterAndSummarize(1)(locationCodes))(mainLocationCodes);
            };
        };
    };
};
var groupByGroup = function (sep) {
    return function (groups$prime) {
        return function (costClassesWithHash) {
            return function (sortingCriteria) {
                return function (xs) {
                    var hourly_pricing_cost_class_codes = Data_Functor.map(Data_Functor.functorArray)(function (v) {
                        return v.costClassCode;
                    })(Data_Array.filter(function (v) {
                        return v.hourlyPricing;
                    })(costClassesWithHash.value));
                    var getGroups = function (v) {
                        return Data_Array.catMaybes(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ new Data_Maybe.Just(Utils.defaultNull("?????")(v.component.group)) ])(Data_Functor.map(Data_Functor.functorArray)(function ($150) {
                            return Data_Nullable.toMaybe((function (v1) {
                                return v1.group;
                            })($150));
                        })(v.resources)));
                    };
                    var groupCodes = Data_Array.nub(Types_ClassificationGroup.ordClassificationGroupCode)(Data_Array.sort(Types_ClassificationGroup.ordClassificationGroupCode)(Data_Array.filter((function () {
                        var $151 = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_String_Common["null"]);
                        var $152 = Data_Newtype.unwrap();
                        return function ($153) {
                            return $151($152($153));
                        };
                    })())(Control_Bind.bindFlipped(Control_Bind.bindArray)(getGroups)(xs))));
                    var transformMapMaybeGroup = function (groupCode) {
                        return function (v) {
                            var hasGroupMatch = function (res) {
                                return Data_Eq.eq(Data_Nullable.eqNullable(Types_ClassificationGroup.eqClassificationGroupCode))(res.group)(Utils.pureNullable(groupCode));
                            };
                            var componentGroupMatch = (function () {
                                var $86 = Utils.isNull(v.component.group);
                                if ($86) {
                                    return Data_Eq.eq(Types_ClassificationGroup.eqClassificationGroupCode)(groupCode)("?????");
                                };
                                return Data_Eq.eq(Data_Nullable.eqNullable(Types_ClassificationGroup.eqClassificationGroupCode))(v.component.group)(Utils.pureNullable(groupCode));
                            })();
                            var noGroup = function (res) {
                                return componentGroupMatch && Utils.isNull(res.group);
                            };
                            var resources = Data_Array.filter(Data_HeytingAlgebra.disj(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(noGroup)(hasGroupMatch))(v.resources);
                            var $87 = !componentGroupMatch && Data_Array["null"](resources);
                            if ($87) {
                                return Data_Maybe.Nothing.value;
                            };
                            var totalHours = CostEstimation_Calculations.componentTotalHours(v.component)(resources)(hourly_pricing_cost_class_codes);
                            var resourcePriceWithoutSocialCosts = function (resource) {
                                return CostEstimation_Calculations.resourceTotalPrice(Data_Map_Internal.empty)(v.component)(resource);
                            };
                            var resourcePrice = function (resource) {
                                return CostEstimation_Calculations.resourceTotalPrice(sep)(v.component)(resource);
                            };
                            var sumCosts = function (rs) {
                                var totalPriceWithoutSocialExpenses = Data_Foldable.sum(Data_Array_NonEmpty_Internal.foldableNonEmptyArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)(resourcePriceWithoutSocialCosts)(rs));
                                var totalPrice = Data_Foldable.sum(Data_Array_NonEmpty_Internal.foldableNonEmptyArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)(resourcePrice)(rs));
                                var hours = CostEstimation_Calculations.componentTotalHours(v.component)(Data_Array_NonEmpty.toArray(rs))(hourly_pricing_cost_class_codes);
                                var costs = function (v1) {
                                    return {
                                        costClassCode: v1,
                                        totalPrice: totalPrice,
                                        totalPriceWithoutSocialExpenses: totalPriceWithoutSocialExpenses,
                                        hours: hours
                                    };
                                };
                                return Data_Functor.map(Data_Maybe.functorMaybe)(costs)(Data_Nullable.toMaybe((function (v1) {
                                    return v1.costClassCode;
                                })(Data_Array_NonEmpty.head(rs))));
                            };
                            var hourlyPricedResources = Data_Array.filter(CostEstimation_Calculations.isHourlyPricedResource(hourly_pricing_cost_class_codes))(resources);
                            var hourlyPricedResourcePrice = CostEstimation_Calculations.componentTotalPrice(sep)(v.component)(hourlyPricedResources);
                            var hourlyRate = Utils.defaultZeroDiv(hourlyPricedResourcePrice)(totalHours);
                            var costClassGrouping = function (r1) {
                                return function (r2) {
                                    return Data_Eq.eq(Data_Nullable.eqNullable(Types_Newtypes.eqCostClassCode))(r1.costClassCode)(r2.costClassCode);
                                };
                            };
                            var ccGroupingCompare = function (r1) {
                                return function (r2) {
                                    return Data_Ord.compare(Data_Nullable.ordNullable(Types_Newtypes.ordCostClassCode))(r1.costClassCode)(r2.costClassCode);
                                };
                            };
                            var resourcesByCostClass = Data_Array.groupBy(costClassGrouping)(Data_Array.sortBy(ccGroupingCompare)(resources));
                            var resourcePricesByCostClass = Data_Array.catMaybes(Data_Functor.map(Data_Functor.functorArray)(sumCosts)(resourcesByCostClass));
                            return Data_Maybe.Just.create(Types_CostEstimation.EstimationComponentWithResources({
                                resources: resources,
                                component: v.component,
                                locations: v.locations,
                                totalPrice: CostEstimation_Calculations.componentTotalPrice(sep)(v.component)(resources),
                                totalPriceWithoutSocialExpenses: v.totalPriceWithoutSocialExpenses,
                                totalPriceByCostClasses: resourcePricesByCostClass,
                                hours: totalHours,
                                hourlyRate: hourlyRate,
                                checkboxState: v.checkboxState,
                                projectOfOrigin: v.projectOfOrigin,
                                isSplitComponentWithMissingResources: Data_Function.on(Data_Eq.notEq(Data_Eq.eqInt))(Data_Array.length)(v.resources)(resources)
                            }));
                        };
                    };
                    var defaultGroup = {
                        id: -1 | 0,
                        projectId: -1 | 0,
                        code: "?????",
                        description: Data_Nullable.toNullable(new Data_Maybe.Just("Ryhmittelem\xe4tt\xf6m\xe4t")),
                        memo: Data_Nullable.toNullable(Data_Maybe.Nothing.value)
                    };
                    var groups = Data_Semigroup.append(Data_Semigroup.semigroupArray)([ defaultGroup ])(groups$prime);
                    var getName = function (groupCode) {
                        return Utils.pureNullable(Data_Maybe.fromMaybe(Data_Newtype.unwrap()(groupCode) + " - muu ryhm\xe4")(Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.find((function () {
                            var $154 = Data_Eq.eq(Types_ClassificationGroup.eqClassificationGroupCode)(groupCode);
                            var $155 = Data_Newtype.unwrap();
                            return function ($156) {
                                return $154((function (v) {
                                    return v.code;
                                })($155($156)));
                            };
                        })())(groups))(function (v) {
                            return Data_Maybe.Just.create(Data_Newtype.unwrap()(v.code) + (" - " + Utils.defaultNull("")(v.description)));
                        })));
                    };
                    var filterAndSummarize = function (components) {
                        return function (groupCode) {
                            var filtered = TacoTableUtils.sortByCriteria(sortingCriteria)(Data_Array.mapMaybe(transformMapMaybeGroup(groupCode))(components));
                            var $89 = Data_Array["null"](filtered);
                            if ($89) {
                                return Common.emptyArray;
                            };
                            var topicRow = mkTopicRow(costClassesWithHash)(function (v) {
                                return getName(groupCode);
                            })(filtered);
                            return Data_Semigroup.append(Data_Semigroup.semigroupArray)([ topicRow ])(filtered);
                        };
                    };
                    return Control_Bind.bindFlipped(Control_Bind.bindArray)(filterAndSummarize(xs))(groupCodes);
                };
            };
        };
    };
};
var groupByClassificationClass = function (classifications) {
    return function (costClassesWithHash) {
        return function (sortingCriteria) {
            return function (components) {
                var sortedComponents = TacoTableUtils.sortByCriteria(sortingCriteria)(components);
                var matchCode = function (code) {
                    return function (v) {
                        return v.code === code;
                    };
                };
                var getName = function (code) {
                    return Utils.pureNullable(Data_Maybe.fromMaybe(code)(Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.find(matchCode(code))(classifications))(function (v) {
                        return Data_Maybe.Just.create(code + (" - " + v.description));
                    })));
                };
                var mkTopicRow$prime = function (label) {
                    return function (cs) {
                        return mkTopicRow(costClassesWithHash)(function (v) {
                            return getName(label);
                        })(cs);
                    };
                };
                var _code = function (v) {
                    return Data_Maybe.fromMaybe("")(Data_Nullable.toMaybe(v.component.code));
                };
                var valTuples = Data_Functor.map(Data_Functor.functorArray)(function (c) {
                    return new Data_Tuple.Tuple(_code(c), c);
                })(sortedComponents);
                var _classificationCode = function (v) {
                    return v.code;
                };
                var classificationClassCodes = Data_Array.filter(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_String_Common["null"]))(Data_Functor.map(Data_Functor.functorArray)(_classificationCode)(classifications));
                var hierarchy = Hierarchy.addValuesToHierarchy(valTuples)(Hierarchy.mkHierarchy(classificationClassCodes));
                var componentsWithTopicRows = Hierarchy.listHierarchy(mkTopicRow$prime)(function (x) {
                    return x;
                })(hierarchy);
                return componentsWithTopicRows;
            };
        };
    };
};
var updateComponentsList = function (v) {
    var selectedComponentMoved = function (mIdx) {
        return function (mOldId) {
            return function (sortedComponents) {
                if (mIdx instanceof Data_Maybe.Just) {
                    var newSelectedId = Data_Functor.map(Data_Maybe.functorMaybe)(ComponentUtils.getECWRId)(Data_Array.index(sortedComponents)(mIdx.value0));
                    return Data_Eq.notEq(Data_Maybe.eqMaybe(Types_Component.eqComponentId))(mOldId)(newSelectedId);
                };
                return false;
            };
        };
    };
    var addSummary = function (getName) {
        return function (xs) {
            var xs$prime = Data_Array_NonEmpty.toArray(xs);
            return Data_Semigroup.append(Data_Semigroup.semigroupArray)([ mkTopicRow(v.costClassesWithHash)(getName)(xs$prime) ])(TacoTableUtils.sortByCriteria(v.sortingCriteria)(xs$prime));
        };
    };
    var groupComponentsBy = function (getProperty) {
        return function (getName) {
            return function (components) {
                var pregroup = Data_Array.sortWith(Data_Nullable.ordNullable(Data_Ord.ordString))(getProperty)(components);
                var grouped = Data_Array.groupBy(Data_Function.on(Data_Eq.eq(Data_Nullable.eqNullable(Data_Eq.eqString)))(getProperty))(pregroup);
                var withSummary = Data_Functor.map(Data_Functor.functorArray)(addSummary(getName))(grouped);
                return Data_Array.concat(withSummary);
            };
        };
    };
    return function __do() {
        var v1 = Client_WASM.useReferenceComponentWithResources(v.projectId)();
        var v2 = Client_WASM.useComponentWithResources(v.projectId)();
        var v3 = TofuHooks.useMemo([ ReactHooksUtils.utf(v.viewMode), ReactHooksUtils.utf(v1.value0), ReactHooksUtils.utf(v2.value0), ReactHooksUtils.utf(v1.value1), ReactHooksUtils.utf(v2.value1) ])(function (v3) {
            var loadingCWR$prime = (function () {
                var $101 = Data_Eq.eq(Types_CostEstimation.eqECViewMode)(v.viewMode)(Types_CostEstimation.ReferenceMode.value);
                if ($101) {
                    return v1.value1;
                };
                return v2.value1;
            })();
            var cwr$prime = (function () {
                var $102 = Data_Eq.eq(Types_CostEstimation.eqECViewMode)(v.viewMode)(Types_CostEstimation.ReferenceMode.value);
                if ($102) {
                    return v1.value0;
                };
                return v2.value0;
            })();
            return new Data_Tuple.Tuple(cwr$prime, loadingCWR$prime);
        })();
        var v4 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var v5 = (function () {
            var $105 = Data_Eq.eq(Types_CostEstimation.eqECViewMode)(v.viewMode)(Types_CostEstimation.ReferenceMode.value);
            if ($105) {
                return Client_WASM.useFilteredRefComponentIds;
            };
            return Client_WASM.useFilteredComponentIds;
        })()(v.projectId)();
        var v6 = TofuHooks.useMemo([ ReactHooksUtils.utf(v5.value0) ])(function (v6) {
            return Data_Set.fromFoldable(Data_Foldable.foldableArray)(Types_Component.ordComponentId)(v5.value0);
        })();
        TofuHooks.useEffect_([ ReactHooksUtils.utf(v.estimationProjects), ReactHooksUtils.utf(v.referenceSubprojects), ReactHooksUtils.utf(v6), ReactHooksUtils.utf(v3.value0), ReactHooksUtils.utf(v.checkedComponents), ReactHooksUtils.utf(v.loading) ])((function () {
            var newUnsorted = EstimationComponentsTable.getComponentsToRender({
                loading: v.loading
            })(v3.value0)(v.estimationProjects)(v.referenceSubprojects)(v.checkedComponents);
            var newUnsorted$prime = Data_Array.filter(function (v7) {
                return Data_Set.member(Types_Component.ordComponentId)(v7.component.id)(v6);
            })(newUnsorted);
            return function __do() {
                Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(Data_Eq.notEq(Data_Maybe.eqMaybe(Data_Eq.eqArray(Types_CostEstimation.eqEstimationComponentWithResources)))(v4.value0)(new Data_Maybe.Just(newUnsorted$prime)))(function __do() {
                    (function () {
                        var $109 = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Array["null"])(newUnsorted$prime);
                        if ($109) {
                            return v.setUnsortedComponents(newUnsorted$prime)();
                        };
                        return v.setUnsortedComponents(Common.emptyArray)();
                    })();
                    return v4.value1(function (v7) {
                        return new Data_Maybe.Just(newUnsorted$prime);
                    })();
                })();
                return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
            };
        })())();
        return TofuHooks.useEffect_([ ReactHooksUtils.utf(v.unsortedComponents), ReactHooksUtils.utf(v.sortingCriteria), ReactHooksUtils.utf(v.groupBy), ReactHooksUtils.utf(v.classificationGroups), ReactHooksUtils.utf(v.classificationClasses), ReactHooksUtils.utf(v.planningCostGroups), ReactHooksUtils.utf(v.projectLocations), ReactHooksUtils.utf(v.costClassesWithHash), ReactHooksUtils.utf(v.socialExpensePercentagesByCostClassWithHash.hash) ])((function () {
            var newSortedComponents = (function () {
                if (v.groupBy instanceof Data_Maybe.Nothing) {
                    return TacoTableUtils.sortByCriteria(v.sortingCriteria)(v.unsortedComponents);
                };
                if (v.groupBy instanceof Data_Maybe.Just) {
                    if (v.groupBy.value0 instanceof Types_ComponentsView.GroupBySubproject) {
                        var _subprojectCode = function (v7) {
                            return v7.component.subProjectCode;
                        };
                        return groupComponentsBy(_subprojectCode)(_subprojectCode)(v.unsortedComponents);
                    };
                    if (v.groupBy.value0 instanceof Types_ComponentsView.GroupByGroup) {
                        return groupByGroup(v.socialExpensePercentagesByCostClassWithHash.value)(v.classificationGroups)(v.costClassesWithHash)(v.sortingCriteria)(v.unsortedComponents);
                    };
                    if (v.groupBy.value0 instanceof Types_ComponentsView.GroupByClassification) {
                        return groupByClassificationClass(v.classificationClasses)(v.costClassesWithHash)(v.sortingCriteria)(v.unsortedComponents);
                    };
                    if (v.groupBy.value0 instanceof Types_ComponentsView.GroupByCostGroup) {
                        var _costGroup = function (v7) {
                            return v7.component.costGroupCode;
                        };
                        var costGroupName = function (ecwr) {
                            return Utils.toNullable(Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Nullable.toMaybe(_costGroup(ecwr)))(function (costGroup) {
                                var eqCostGroup = function (r) {
                                    return r.code === costGroup;
                                };
                                var r = Data_Function.flip(Data_Array.find)(v.planningCostGroups)(eqCostGroup);
                                return new Data_Maybe.Just((function () {
                                    if (r instanceof Data_Maybe.Just) {
                                        return costGroup + (" " + r.value0.description);
                                    };
                                    if (r instanceof Data_Maybe.Nothing) {
                                        return costGroup;
                                    };
                                    throw new Error("Failed pattern match at ComponentsViewUtils (line 209, column 24 - line 211, column 41): " + [ r.constructor.name ]);
                                })());
                            }));
                        };
                        return groupComponentsBy(_costGroup)(costGroupName)(v.unsortedComponents);
                    };
                    if (v.groupBy.value0 instanceof Types_ComponentsView.GroupByLocation) {
                        return groupByLocation(v.projectLocations)(v.costClassesWithHash)(v.sortingCriteria)(v.unsortedComponents);
                    };
                    throw new Error("Failed pattern match at ComponentsViewUtils (line 189, column 11 - line 214, column 102): " + [ v.groupBy.value0.constructor.name ]);
                };
                throw new Error("Failed pattern match at ComponentsViewUtils (line 185, column 29 - line 214, column 102): " + [ v.groupBy.constructor.name ]);
            })();
            return function __do() {
                (function () {
                    var $117 = selectedComponentMoved(v.selectedComponentIdx)(v.selectedComponentId)(newSortedComponents);
                    if ($117) {
                        return v.unselectComponent();
                    };
                    return Data_Monoid.mempty(Data_Monoid.monoidUnit);
                })();
                v.setComponentsList(newSortedComponents)();
                return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
            };
        })())();
    };
};
var findNewIdxOfSelectedComponent = function (selectedComponentIdx) {
    return function (components) {
        return function (newComponentSet) {
            var findIdxOfComponent = function (comp) {
                return Data_Array.elemIndex(Types_CostEstimation.eqEstimationComponentWithResources)(comp)(newComponentSet);
            };
            var idxInNewComponentSet = function (curIdx) {
                return Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(findIdxOfComponent)(Data_Array.index(components)(curIdx));
            };
            return Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(idxInNewComponentSet)(selectedComponentIdx);
        };
    };
};
var ecwrComponentId = (function () {
    var $157 = Data_Newtype.un()(Types_CostEstimation.EstimationComponentWithResources);
    return function ($158) {
        return (function (v) {
            return v.component.id;
        })($157($158));
    };
})();
module.exports = {
    selected: selected,
    handleCursorNavigation: handleCursorNavigation,
    findNewIdxOfSelectedComponent: findNewIdxOfSelectedComponent,
    ecwrComponentId: ecwrComponentId,
    updateComponentsList: updateComponentsList,
    mkTopicRow: mkTopicRow,
    groupByGroup: groupByGroup,
    groupByClassificationClass: groupByClassificationClass,
    groupByLocation: groupByLocation,
    mkBuildingDescriptionBySpecId: mkBuildingDescriptionBySpecId
};
