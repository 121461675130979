// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var StyleProperties = require("../StyleProperties/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var headerElementStyles = function (paddingLeft) {
    return Box.boxStyle([ Data_Monoid.guard(Box.monoidBoxProp)(paddingLeft)(new Box.PaddingLeft(TacoConstants.M.value)), Box.PaddingRight.create(new TacoConstants.CustomSize("0")), Box.Style.create(React_Basic_Emotion.css()({
        flexShrink: React_Basic_Emotion["int"](0)
    })), new Box.ZIndex(TacoConstants.zIndexHeader), new Box.BackgroundColor(TacoConstants.AppBackground.value), new Box.ForegroundColor(TacoConstants.TextPrimary.value), new Box.Height(TacoConstants.headerHeight), Box.Style.create(React_Basic_Emotion.css()({
        borderBottom: StyleProperties.border(TacoConstants.BorderLight.value)
    })), Box.FlexRow.value, Box.JustifySpaceBetween.value, Box.AlignCenter.value, new Box.Position(Box.Sticky.value), Box.Style.create(React_Basic_Emotion.css()({
        top: React_Basic_Emotion["int"](0)
    })), new Box.Overflow(Box.Auto.value, Box.Hidden.value) ]);
};
module.exports = {
    headerElementStyles: headerElementStyles
};
