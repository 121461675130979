// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Edited = require("../Edited/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoText = require("../TacoText/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Estimation = require("../Types.Estimation/index.js");
var Utils = require("../Utils/index.js");
var mkEditOrgResourcePriceParams = function (props) {
    return function (state) {
        return {
            orgResourcePriceId: props.orgResourcePrice.orgResourcePriceId,
            resourceClassificationGroup: Edited.fromEdited(props.orgResourcePrice.resourceClassificationGroup)(state.resourceClassificationGroup),
            sortingNumber: Edited.fromEdited(props.orgResourcePrice.sortingNumber)(state.sortingNumber),
            name: Edited.fromEdited(Utils.defaultNull("")(props.orgResourcePrice.name))(state.name),
            unit: Edited.fromEdited(props.orgResourcePrice.unit)(state.unit),
            pricePerUnit: Edited.fromEdited(props.orgResourcePrice.price)(state.pricePerUnit),
            discountPercentage: Edited.fromEdited(props.orgResourcePrice.discountPercentage)(state.discountPercentage),
            ownCode: Edited.fromEdited(props.orgResourcePrice.ownCode)(state.ownCode),
            validDate: Edited.fromEdited(props.orgResourcePrice.validDate)(state.validDate),
            eanCode: Edited.fromEdited(props.orgResourcePrice.eanCode)(state.eanCode),
            taxRate: Edited.fromEdited(props.orgResourcePrice.taxRate)(state.taxRate),
            index: Edited.fromEdited(props.orgResourcePrice.index)(state.index),
            vendor: Edited.fromEdited(props.orgResourcePrice.vendor)(state.vendor),
            vendorCode: Edited.fromEdited(props.orgResourcePrice.vendorCode)(state.vendorCode),
            vendorUnit: Edited.fromEdited(props.orgResourcePrice.vendorUnit)(state.vendorUnit),
            vendorBatchSize: Edited.fromEdited(props.orgResourcePrice.vendorBatchSize)(state.vendorBatchSize)
        };
    };
};
var defaultEditResourcePriceModalState = {
    resourceClassificationGroup: Edited.Unedited.value,
    sortingNumber: Edited.Unedited.value,
    name: Edited.Unedited.value,
    unit: Edited.Unedited.value,
    pricePerUnit: Edited.Unedited.value,
    discountPercentage: Edited.Unedited.value,
    ownCode: Edited.Unedited.value,
    validDate: Edited.Unedited.value,
    eanCode: Edited.Unedited.value,
    taxRate: Edited.Unedited.value,
    index: Edited.Unedited.value,
    vendor: Edited.Unedited.value,
    vendorCode: Edited.Unedited.value,
    vendorUnit: Edited.Unedited.value,
    vendorBatchSize: Edited.Unedited.value
};
var editResourcePriceModal = TofuHooks.mkHookComponent("EditResourcePriceModal")(function (props) {
    return function __do() {
        var dispatch = StateHooks.useDispatch();
        var v = TofuHooks.useState(defaultEditResourcePriceModalState)();
        var save = TacoButton.component()()({
            onClick: new Data_Maybe.Just(dispatch(new Actions.EstimationResourcePriceListActionRequest({
                projectId: props.projectId,
                handler: function (v1) {
                    return function __do() {
                        v.value1(function (v2) {
                            return defaultEditResourcePriceModalState;
                        })();
                        return props.onClose();
                    };
                },
                params: new Types_Estimation.EditOrgResourcePrice(mkEditOrgResourcePriceParams(props)(v.value0))
            }))),
            buttonStyle: TacoButton_Types.Filled.value,
            text: "Tallenna",
            testId: "save-modal"
        });
        var mkStringInput = function (testId) {
            return function ($$default) {
                return function (edited) {
                    return function (setEdited) {
                        return TacoInput.stringInput()()(Data_Eq.eqString)({
                            value: Edited.fromEdited($$default)(edited),
                            onSave: setEdited,
                            testId: testId
                        });
                    };
                };
            };
        };
        var mkNumberInput = function (testId) {
            return function ($$default) {
                return function (edited) {
                    return function (setEdited) {
                        return TacoInput.numberInput()()(Data_Eq.eqNumber)({
                            value: Edited.fromEdited($$default)(edited),
                            precision: 2,
                            onSave: setEdited,
                            testId: testId
                        });
                    };
                };
            };
        };
        var mkNullableStringInput = function (testId) {
            return function ($$default) {
                return function (edited) {
                    return function (setEdited) {
                        return TacoInput.stringInput()()(Data_Eq.eqString)({
                            value: Utils.defaultNull("")(Edited.fromEdited($$default)(edited)),
                            onSave: setEdited,
                            testId: testId
                        });
                    };
                };
            };
        };
        var mkNullableNumberInput = function (testId) {
            return function ($$default) {
                return function (edited) {
                    return function (setEdited) {
                        return TacoInput.numberInput()()(Data_Eq.eqNumber)({
                            value: Utils.defaultNull(0.0)(Edited.fromEdited($$default)(edited)),
                            precision: 2,
                            onSave: setEdited,
                            testId: testId
                        });
                    };
                };
            };
        };
        var mkLabel = function (text) {
            return TacoText.component()()({
                text: text
            });
        };
        var modalBody = TacoModal.modalBody({
            contents: [ mkLabel("PRyhm\xe4"), mkNullableStringInput("resource-classification-group-input")(props.orgResourcePrice.resourceClassificationGroup)(v.value0.resourceClassificationGroup)(function (x) {
                return v.value1(function (v1) {
                    return {
                        resourceClassificationGroup: new Edited.Edited(Utils.pureNullable(x)),
                        sortingNumber: v1.sortingNumber,
                        name: v1.name,
                        unit: v1.unit,
                        pricePerUnit: v1.pricePerUnit,
                        discountPercentage: v1.discountPercentage,
                        ownCode: v1.ownCode,
                        validDate: v1.validDate,
                        eanCode: v1.eanCode,
                        taxRate: v1.taxRate,
                        index: v1.index,
                        vendor: v1.vendor,
                        vendorCode: v1.vendorCode,
                        vendorUnit: v1.vendorUnit,
                        vendorBatchSize: v1.vendorBatchSize
                    };
                });
            }), mkLabel("J\xe4rjestys"), mkNullableStringInput("resource-sorting-number-input")(props.orgResourcePrice.sortingNumber)(v.value0.sortingNumber)(function (x) {
                return v.value1(function (v1) {
                    return {
                        resourceClassificationGroup: v1.resourceClassificationGroup,
                        sortingNumber: new Edited.Edited(Utils.pureNullable(x)),
                        name: v1.name,
                        unit: v1.unit,
                        pricePerUnit: v1.pricePerUnit,
                        discountPercentage: v1.discountPercentage,
                        ownCode: v1.ownCode,
                        validDate: v1.validDate,
                        eanCode: v1.eanCode,
                        taxRate: v1.taxRate,
                        index: v1.index,
                        vendor: v1.vendor,
                        vendorCode: v1.vendorCode,
                        vendorUnit: v1.vendorUnit,
                        vendorBatchSize: v1.vendorBatchSize
                    };
                });
            }), mkLabel("Nimi"), mkStringInput("resource-name-input")(Utils.defaultNull("")(props.orgResourcePrice.name))(v.value0.name)(function (x) {
                return v.value1(function (v1) {
                    return {
                        resourceClassificationGroup: v1.resourceClassificationGroup,
                        sortingNumber: v1.sortingNumber,
                        name: new Edited.Edited(x),
                        unit: v1.unit,
                        pricePerUnit: v1.pricePerUnit,
                        discountPercentage: v1.discountPercentage,
                        ownCode: v1.ownCode,
                        validDate: v1.validDate,
                        eanCode: v1.eanCode,
                        taxRate: v1.taxRate,
                        index: v1.index,
                        vendor: v1.vendor,
                        vendorCode: v1.vendorCode,
                        vendorUnit: v1.vendorUnit,
                        vendorBatchSize: v1.vendorBatchSize
                    };
                });
            }), mkLabel("Yks"), mkNullableStringInput("resource-unit-input")(props.orgResourcePrice.unit)(v.value0.unit)(function (x) {
                return v.value1(function (v1) {
                    return {
                        resourceClassificationGroup: v1.resourceClassificationGroup,
                        sortingNumber: v1.sortingNumber,
                        name: v1.name,
                        unit: new Edited.Edited(Utils.pureNullable(x)),
                        pricePerUnit: v1.pricePerUnit,
                        discountPercentage: v1.discountPercentage,
                        ownCode: v1.ownCode,
                        validDate: v1.validDate,
                        eanCode: v1.eanCode,
                        taxRate: v1.taxRate,
                        index: v1.index,
                        vendor: v1.vendor,
                        vendorCode: v1.vendorCode,
                        vendorUnit: v1.vendorUnit,
                        vendorBatchSize: v1.vendorBatchSize
                    };
                });
            }), mkLabel("Hinta ilman sosiaalikuluja"), mkNullableNumberInput("resource-price-per-unit-input")(props.orgResourcePrice.price)(v.value0.pricePerUnit)(function (x) {
                return v.value1(function (v1) {
                    return {
                        resourceClassificationGroup: v1.resourceClassificationGroup,
                        sortingNumber: v1.sortingNumber,
                        name: v1.name,
                        unit: v1.unit,
                        pricePerUnit: new Edited.Edited(Utils.pureNullable(x)),
                        discountPercentage: v1.discountPercentage,
                        ownCode: v1.ownCode,
                        validDate: v1.validDate,
                        eanCode: v1.eanCode,
                        taxRate: v1.taxRate,
                        index: v1.index,
                        vendor: v1.vendor,
                        vendorCode: v1.vendorCode,
                        vendorUnit: v1.vendorUnit,
                        vendorBatchSize: v1.vendorBatchSize
                    };
                });
            }), mkLabel("Ale-%"), mkNumberInput("resource-discount-percentage-input")(props.orgResourcePrice.discountPercentage)(v.value0.discountPercentage)(function (x) {
                return v.value1(function (v1) {
                    return {
                        resourceClassificationGroup: v1.resourceClassificationGroup,
                        sortingNumber: v1.sortingNumber,
                        name: v1.name,
                        unit: v1.unit,
                        pricePerUnit: v1.pricePerUnit,
                        discountPercentage: new Edited.Edited(x),
                        ownCode: v1.ownCode,
                        validDate: v1.validDate,
                        eanCode: v1.eanCode,
                        taxRate: v1.taxRate,
                        index: v1.index,
                        vendor: v1.vendor,
                        vendorCode: v1.vendorCode,
                        vendorUnit: v1.vendorUnit,
                        vendorBatchSize: v1.vendorBatchSize
                    };
                });
            }), mkLabel("Oma Tunnus"), mkNullableStringInput("resource-own-code-input")(props.orgResourcePrice.ownCode)(v.value0.ownCode)(function (x) {
                return v.value1(function (v1) {
                    return {
                        resourceClassificationGroup: v1.resourceClassificationGroup,
                        sortingNumber: v1.sortingNumber,
                        name: v1.name,
                        unit: v1.unit,
                        pricePerUnit: v1.pricePerUnit,
                        discountPercentage: v1.discountPercentage,
                        ownCode: new Edited.Edited(Utils.pureNullable(x)),
                        validDate: v1.validDate,
                        eanCode: v1.eanCode,
                        taxRate: v1.taxRate,
                        index: v1.index,
                        vendor: v1.vendor,
                        vendorCode: v1.vendorCode,
                        vendorUnit: v1.vendorUnit,
                        vendorBatchSize: v1.vendorBatchSize
                    };
                });
            }), mkLabel("Voimassa"), mkNullableStringInput("resource-valid-date-input")(props.orgResourcePrice.validDate)(v.value0.validDate)(function (x) {
                return v.value1(function (v1) {
                    return {
                        resourceClassificationGroup: v1.resourceClassificationGroup,
                        sortingNumber: v1.sortingNumber,
                        name: v1.name,
                        unit: v1.unit,
                        pricePerUnit: v1.pricePerUnit,
                        discountPercentage: v1.discountPercentage,
                        ownCode: v1.ownCode,
                        validDate: new Edited.Edited(Utils.pureNullable(x)),
                        eanCode: v1.eanCode,
                        taxRate: v1.taxRate,
                        index: v1.index,
                        vendor: v1.vendor,
                        vendorCode: v1.vendorCode,
                        vendorUnit: v1.vendorUnit,
                        vendorBatchSize: v1.vendorBatchSize
                    };
                });
            }), mkLabel("EAN-koodi"), mkNullableStringInput("resource-ean-code-input")(props.orgResourcePrice.eanCode)(v.value0.eanCode)(function (x) {
                return v.value1(function (v1) {
                    return {
                        resourceClassificationGroup: v1.resourceClassificationGroup,
                        sortingNumber: v1.sortingNumber,
                        name: v1.name,
                        unit: v1.unit,
                        pricePerUnit: v1.pricePerUnit,
                        discountPercentage: v1.discountPercentage,
                        ownCode: v1.ownCode,
                        validDate: v1.validDate,
                        eanCode: new Edited.Edited(Utils.pureNullable(x)),
                        taxRate: v1.taxRate,
                        index: v1.index,
                        vendor: v1.vendor,
                        vendorCode: v1.vendorCode,
                        vendorUnit: v1.vendorUnit,
                        vendorBatchSize: v1.vendorBatchSize
                    };
                });
            }), mkLabel("Alv-%"), mkNullableNumberInput("resource-tax-rate-input")(props.orgResourcePrice.taxRate)(v.value0.taxRate)(function (x) {
                return v.value1(function (v1) {
                    return {
                        resourceClassificationGroup: v1.resourceClassificationGroup,
                        sortingNumber: v1.sortingNumber,
                        name: v1.name,
                        unit: v1.unit,
                        pricePerUnit: v1.pricePerUnit,
                        discountPercentage: v1.discountPercentage,
                        ownCode: v1.ownCode,
                        validDate: v1.validDate,
                        eanCode: v1.eanCode,
                        taxRate: new Edited.Edited(Utils.pureNullable(x)),
                        index: v1.index,
                        vendor: v1.vendor,
                        vendorCode: v1.vendorCode,
                        vendorUnit: v1.vendorUnit,
                        vendorBatchSize: v1.vendorBatchSize
                    };
                });
            }), mkLabel("Indeksi"), mkNullableNumberInput("resource-index-input")(props.orgResourcePrice.index)(v.value0.index)(function (x) {
                return v.value1(function (v1) {
                    return {
                        resourceClassificationGroup: v1.resourceClassificationGroup,
                        sortingNumber: v1.sortingNumber,
                        name: v1.name,
                        unit: v1.unit,
                        pricePerUnit: v1.pricePerUnit,
                        discountPercentage: v1.discountPercentage,
                        ownCode: v1.ownCode,
                        validDate: v1.validDate,
                        eanCode: v1.eanCode,
                        taxRate: v1.taxRate,
                        index: new Edited.Edited(Utils.pureNullable(x)),
                        vendor: v1.vendor,
                        vendorCode: v1.vendorCode,
                        vendorUnit: v1.vendorUnit,
                        vendorBatchSize: v1.vendorBatchSize
                    };
                });
            }), mkLabel("Toimittaja"), mkNullableStringInput("resource-vendor-input")(props.orgResourcePrice.vendor)(v.value0.vendor)(function (x) {
                return v.value1(function (v1) {
                    return {
                        resourceClassificationGroup: v1.resourceClassificationGroup,
                        sortingNumber: v1.sortingNumber,
                        name: v1.name,
                        unit: v1.unit,
                        pricePerUnit: v1.pricePerUnit,
                        discountPercentage: v1.discountPercentage,
                        ownCode: v1.ownCode,
                        validDate: v1.validDate,
                        eanCode: v1.eanCode,
                        taxRate: v1.taxRate,
                        index: v1.index,
                        vendor: new Edited.Edited(Utils.pureNullable(x)),
                        vendorCode: v1.vendorCode,
                        vendorUnit: v1.vendorUnit,
                        vendorBatchSize: v1.vendorBatchSize
                    };
                });
            }), mkLabel("Toimittaja oma"), mkNullableStringInput("resource-vendor-code-input")(props.orgResourcePrice.vendorCode)(v.value0.vendorCode)(function (x) {
                return v.value1(function (v1) {
                    return {
                        resourceClassificationGroup: v1.resourceClassificationGroup,
                        sortingNumber: v1.sortingNumber,
                        name: v1.name,
                        unit: v1.unit,
                        pricePerUnit: v1.pricePerUnit,
                        discountPercentage: v1.discountPercentage,
                        ownCode: v1.ownCode,
                        validDate: v1.validDate,
                        eanCode: v1.eanCode,
                        taxRate: v1.taxRate,
                        index: v1.index,
                        vendor: v1.vendor,
                        vendorCode: new Edited.Edited(Utils.pureNullable(x)),
                        vendorUnit: v1.vendorUnit,
                        vendorBatchSize: v1.vendorBatchSize
                    };
                });
            }), mkLabel("Myyntier\xe4n yksikk\xf6"), mkNullableStringInput("resource-vendor-unit-input")(props.orgResourcePrice.vendorUnit)(v.value0.vendorUnit)(function (x) {
                return v.value1(function (v1) {
                    return {
                        resourceClassificationGroup: v1.resourceClassificationGroup,
                        sortingNumber: v1.sortingNumber,
                        name: v1.name,
                        unit: v1.unit,
                        pricePerUnit: v1.pricePerUnit,
                        discountPercentage: v1.discountPercentage,
                        ownCode: v1.ownCode,
                        validDate: v1.validDate,
                        eanCode: v1.eanCode,
                        taxRate: v1.taxRate,
                        index: v1.index,
                        vendor: v1.vendor,
                        vendorCode: v1.vendorCode,
                        vendorUnit: new Edited.Edited(Utils.pureNullable(x)),
                        vendorBatchSize: v1.vendorBatchSize
                    };
                });
            }), mkLabel("Myyntier\xe4n nettosis\xe4lt\xf6"), mkNullableNumberInput("resource-vendor-batch-size")(props.orgResourcePrice.vendorBatchSize)(v.value0.vendorBatchSize)(function (x) {
                return v.value1(function (v1) {
                    return {
                        resourceClassificationGroup: v1.resourceClassificationGroup,
                        sortingNumber: v1.sortingNumber,
                        name: v1.name,
                        unit: v1.unit,
                        pricePerUnit: v1.pricePerUnit,
                        discountPercentage: v1.discountPercentage,
                        ownCode: v1.ownCode,
                        validDate: v1.validDate,
                        eanCode: v1.eanCode,
                        taxRate: v1.taxRate,
                        index: v1.index,
                        vendor: v1.vendor,
                        vendorCode: v1.vendorCode,
                        vendorUnit: v1.vendorUnit,
                        vendorBatchSize: new Edited.Edited(Utils.pureNullable(x))
                    };
                });
            }) ]
        });
        var cancel = TacoButton.component()()({
            onClick: new Data_Maybe.Just(props.onClose),
            text: "Peruuta",
            testId: "cancel-modal"
        });
        var modalActions = TacoModal.modalActions({
            contents: [ cancel, save ]
        });
        var modal = TacoModal.component()()({
            heading: "Muokkaa",
            isActive: true,
            onClose: props.onClose,
            contents: [ modalBody, modalActions ]
        });
        return modal;
    };
});
module.exports = {
    defaultEditResourcePriceModalState: defaultEditResourcePriceModalState,
    mkEditOrgResourcePriceParams: mkEditOrgResourcePriceParams,
    editResourcePriceModal: editResourcePriceModal
};
