// Generated by purs version 0.14.5
"use strict";
var Data_Array = require("../Data.Array/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Hash = require("../Hash/index.js");
var Kishimen = require("../Kishimen/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var EditCostClassName = (function () {
    function EditCostClassName(value0) {
        this.value0 = value0;
    };
    EditCostClassName.create = function (value0) {
        return new EditCostClassName(value0);
    };
    return EditCostClassName;
})();
var EditCostClassSocialExpensePercentageInCostEstimation = (function () {
    function EditCostClassSocialExpensePercentageInCostEstimation(value0) {
        this.value0 = value0;
    };
    EditCostClassSocialExpensePercentageInCostEstimation.create = function (value0) {
        return new EditCostClassSocialExpensePercentageInCostEstimation(value0);
    };
    return EditCostClassSocialExpensePercentageInCostEstimation;
})();
var EditCostClassMultiplier = (function () {
    function EditCostClassMultiplier(value0) {
        this.value0 = value0;
    };
    EditCostClassMultiplier.create = function (value0) {
        return new EditCostClassMultiplier(value0);
    };
    return EditCostClassMultiplier;
})();
var EditCostClassHourlyPricing = (function () {
    function EditCostClassHourlyPricing(value0) {
        this.value0 = value0;
    };
    EditCostClassHourlyPricing.create = function (value0) {
        return new EditCostClassHourlyPricing(value0);
    };
    return EditCostClassHourlyPricing;
})();
var CostClassId = function (x) {
    return x;
};
var DeleteCostClass = (function () {
    function DeleteCostClass(value0) {
        this.value0 = value0;
    };
    DeleteCostClass.create = function (value0) {
        return new DeleteCostClass(value0);
    };
    return DeleteCostClass;
})();
var AddCostClass = (function () {
    function AddCostClass(value0) {
        this.value0 = value0;
    };
    AddCostClass.create = function (value0) {
        return new AddCostClass(value0);
    };
    return AddCostClass;
})();
var EditCostClass = (function () {
    function EditCostClass(value0) {
        this.value0 = value0;
    };
    EditCostClass.create = function (value0) {
        return new EditCostClass(value0);
    };
    return EditCostClass;
})();
var tofuJSONCostClassId = TofuJSON.readInt;
var newtypeCostClassId = {
    Coercible0: function () {
        return undefined;
    }
};
var getEstimationSocialExpensePercentagesByCostClass = function (costClasses) {
    var toKeyValuePair = function (cc) {
        return Data_Functor.map(Data_Maybe.functorMaybe)((function () {
            var $46 = Data_Tuple.Tuple.create(cc.costClassCode);
            return function ($47) {
                return $46(Types_Unit.SocialExpensePercentage(Types_Unit.PercentageOfZeroToOne($47)));
            };
        })())(Data_Nullable.toMaybe(cc.socialExpensePercentageInCostEstimation));
    };
    return Data_Map_Internal.fromFoldable(Types_Newtypes.ordCostClassCode)(Data_Foldable.foldableArray)(Data_Array.catMaybes(Data_Functor.map(Data_Functor.functorArray)(toKeyValuePair)(costClasses)));
};
var getEstimationSocialExpensePercentagesByCostClassWithHash = function (xs) {
    var value = getEstimationSocialExpensePercentagesByCostClass(xs);
    var hash = Hash.sha1(value);
    return {
        value: value,
        hash: hash
    };
};
var genericEditCostClassAction = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new EditCostClassName(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new EditCostClassSocialExpensePercentageInCostEstimation(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl)) {
            return new EditCostClassMultiplier(x.value0.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && (x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr)) {
            return new EditCostClassHourlyPricing(x.value0.value0.value0);
        };
        throw new Error("Failed pattern match at Types.CostClass (line 110, column 1 - line 110, column 76): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof EditCostClassName) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof EditCostClassSocialExpensePercentageInCostEstimation) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof EditCostClassMultiplier) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0)));
        };
        if (x instanceof EditCostClassHourlyPricing) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0)));
        };
        throw new Error("Failed pattern match at Types.CostClass (line 110, column 1 - line 110, column 76): " + [ x.constructor.name ]);
    }
};
var tofuJSONEditCostClassAction = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditCostClassHourlyPricing";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "hourlyPricing";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "hourlyPricing";
        }
    })(TofuJSON.readBoolean)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditCostClassMultiplier";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditCostClassName";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditCostClassSocialExpensePercentageInCostEstimation";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "socialExpensePercentageInCostEstimation";
        }
    })(Types_Unit.tofuJSONPercentageOfHundred)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "socialExpensePercentageInCostEstimation";
        }
    })(Types_Unit.tofuJSONPercentageOfHundred)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantNil)))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditCostClassHourlyPricing";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "hourlyPricing";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "hourlyPricing";
        }
    })(TofuJSON.readBoolean)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditCostClassMultiplier";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditCostClassName";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditCostClassSocialExpensePercentageInCostEstimation";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "socialExpensePercentageInCostEstimation";
        }
    })(Types_Unit.tofuJSONPercentageOfHundred)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "socialExpensePercentageInCostEstimation";
        }
    })(Types_Unit.tofuJSONPercentageOfHundred)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.nilWriteForeignVariant)))))(genericEditCostClassAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditCostClassName";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditCostClassSocialExpensePercentageInCostEstimation";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditCostClassMultiplier";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditCostClassHourlyPricing";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditCostClassMultiplier";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditCostClassHourlyPricing";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditCostClassSocialExpensePercentageInCostEstimation";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditCostClassMultiplier";
        }
    }))({
        reflectSymbol: function () {
            return "EditCostClassHourlyPricing";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditCostClassName";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditCostClassSocialExpensePercentageInCostEstimation";
        }
    }))({
        reflectSymbol: function () {
            return "EditCostClassMultiplier";
        }
    }))({
        reflectSymbol: function () {
            return "EditCostClassHourlyPricing";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericEditCostClassAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditCostClassName";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditCostClassSocialExpensePercentageInCostEstimation";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditCostClassMultiplier";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditCostClassHourlyPricing";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditCostClassMultiplier";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditCostClassHourlyPricing";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditCostClassSocialExpensePercentageInCostEstimation";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditCostClassMultiplier";
        }
    }))({
        reflectSymbol: function () {
            return "EditCostClassHourlyPricing";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditCostClassName";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditCostClassSocialExpensePercentageInCostEstimation";
        }
    }))({
        reflectSymbol: function () {
            return "EditCostClassMultiplier";
        }
    }))({
        reflectSymbol: function () {
            return "EditCostClassHourlyPricing";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditCostClassHourlyPricing";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "hourlyPricing";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "hourlyPricing";
        }
    })(TofuJSON.readBoolean)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditCostClassMultiplier";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditCostClassName";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditCostClassSocialExpensePercentageInCostEstimation";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "socialExpensePercentageInCostEstimation";
        }
    })(Types_Unit.tofuJSONPercentageOfHundred)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "socialExpensePercentageInCostEstimation";
        }
    })(Types_Unit.tofuJSONPercentageOfHundred)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.readVariantNil)))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditCostClassHourlyPricing";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "hourlyPricing";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "hourlyPricing";
        }
    })(TofuJSON.readBoolean)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditCostClassMultiplier";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditCostClassName";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditCostClassSocialExpensePercentageInCostEstimation";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "socialExpensePercentageInCostEstimation";
        }
    })(Types_Unit.tofuJSONPercentageOfHundred)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "socialExpensePercentageInCostEstimation";
        }
    })(Types_Unit.tofuJSONPercentageOfHundred)(TofuJSON.nilWriteForeignFields)()()()))()(TofuJSON.nilWriteForeignVariant)))))
};
var genericCostClassAction = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return new DeleteCostClass(x.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return new AddCostClass(x.value0.value0);
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
            return new EditCostClass(x.value0.value0);
        };
        throw new Error("Failed pattern match at Types.CostClass (line 82, column 1 - line 82, column 68): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof DeleteCostClass) {
            return new Data_Generic_Rep.Inl(x.value0);
        };
        if (x instanceof AddCostClass) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(x.value0));
        };
        if (x instanceof EditCostClass) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(x.value0));
        };
        throw new Error("Failed pattern match at Types.CostClass (line 82, column 1 - line 82, column 68): " + [ x.constructor.name ]);
    }
};
var tofuJSONCostClassAction = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddCostClass";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "hourlyPricing";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "socialExpensePercentageInCostEstimation";
        }
    })(Types_Unit.tofuJSONPercentageOfHundred)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "hourlyPricing";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "socialExpensePercentageInCostEstimation";
        }
    })(Types_Unit.tofuJSONPercentageOfHundred)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DeleteCostClass";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "id";
        }
    })(tofuJSONCostClassId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "id";
        }
    })(tofuJSONCostClassId)(TofuJSON.nilWriteForeignFields)()()())))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditCostClass";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditCostClassAction)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "id";
        }
    })(tofuJSONCostClassId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditCostClassAction)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "id";
        }
    })(tofuJSONCostClassId)(TofuJSON.nilWriteForeignFields)()()())()()())))()(TofuJSON.readVariantNil))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddCostClass";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "hourlyPricing";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "socialExpensePercentageInCostEstimation";
        }
    })(Types_Unit.tofuJSONPercentageOfHundred)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "hourlyPricing";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "socialExpensePercentageInCostEstimation";
        }
    })(Types_Unit.tofuJSONPercentageOfHundred)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DeleteCostClass";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "id";
        }
    })(tofuJSONCostClassId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "id";
        }
    })(tofuJSONCostClassId)(TofuJSON.nilWriteForeignFields)()()())))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditCostClass";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditCostClassAction)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "id";
        }
    })(tofuJSONCostClassId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditCostClassAction)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "id";
        }
    })(tofuJSONCostClassId)(TofuJSON.nilWriteForeignFields)()()())()()())))()(TofuJSON.nilWriteForeignVariant))))(genericCostClassAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DeleteCostClass";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddCostClass";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditCostClass";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddCostClass";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditCostClass";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DeleteCostClass";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditCostClass";
        }
    }))({
        reflectSymbol: function () {
            return "AddCostClass";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericCostClassAction)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "DeleteCostClass";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "AddCostClass";
        }
    })()(Kishimen.genericSumToVariantArgArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "EditCostClass";
        }
    })()(Kishimen.genericSumToVariantArgArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "AddCostClass";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditCostClass";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "DeleteCostClass";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "EditCostClass";
        }
    }))({
        reflectSymbol: function () {
            return "AddCostClass";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "AddCostClass";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "hourlyPricing";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "socialExpensePercentageInCostEstimation";
        }
    })(Types_Unit.tofuJSONPercentageOfHundred)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "hourlyPricing";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "socialExpensePercentageInCostEstimation";
        }
    })(Types_Unit.tofuJSONPercentageOfHundred)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "DeleteCostClass";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "id";
        }
    })(tofuJSONCostClassId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "id";
        }
    })(tofuJSONCostClassId)(TofuJSON.nilWriteForeignFields)()()())))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "EditCostClass";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditCostClassAction)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "id";
        }
    })(tofuJSONCostClassId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditCostClassAction)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "id";
        }
    })(tofuJSONCostClassId)(TofuJSON.nilWriteForeignFields)()()())()()())))()(TofuJSON.readVariantNil))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "AddCostClass";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "code";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "hourlyPricing";
        }
    })(TofuJSON.readBoolean)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "socialExpensePercentageInCostEstimation";
        }
    })(Types_Unit.tofuJSONPercentageOfHundred)(TofuJSON.readFieldsNil)()())()())()())()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "code";
        }
    })(Types_Newtypes.tofuJSONCostClassCode)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "hourlyPricing";
        }
    })(TofuJSON.readBoolean)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(TofuJSON.readNumber)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "name";
        }
    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "socialExpensePercentageInCostEstimation";
        }
    })(Types_Unit.tofuJSONPercentageOfHundred)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()())()()())))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "DeleteCostClass";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "id";
        }
    })(tofuJSONCostClassId)(TofuJSON.readFieldsNil)()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "id";
        }
    })(tofuJSONCostClassId)(TofuJSON.nilWriteForeignFields)()()())))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "EditCostClass";
        }
    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditCostClassAction)(TofuJSON.readFieldsCons({
        reflectSymbol: function () {
            return "id";
        }
    })(tofuJSONCostClassId)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "editAction";
        }
    })(tofuJSONEditCostClassAction)(TofuJSON.consWriteForeignFields({
        reflectSymbol: function () {
            return "id";
        }
    })(tofuJSONCostClassId)(TofuJSON.nilWriteForeignFields)()()())()()())))()(TofuJSON.nilWriteForeignVariant))))
};
var eqCostClassId = {
    eq: function (x) {
        return function (y) {
            return x === y;
        };
    }
};
var ordCostClassId = {
    compare: function (x) {
        return function (y) {
            return Data_Ord.compare(Data_Ord.ordInt)(x)(y);
        };
    },
    Eq0: function () {
        return eqCostClassId;
    }
};
var emptySocialExpensePercentagesByCostClassWithHash = (function () {
    var hash = Hash.sha1(Data_Map_Internal.empty);
    return {
        value: Data_Map_Internal.empty,
        hash: hash
    };
})();
module.exports = {
    DeleteCostClass: DeleteCostClass,
    AddCostClass: AddCostClass,
    EditCostClass: EditCostClass,
    CostClassId: CostClassId,
    EditCostClassName: EditCostClassName,
    EditCostClassSocialExpensePercentageInCostEstimation: EditCostClassSocialExpensePercentageInCostEstimation,
    EditCostClassMultiplier: EditCostClassMultiplier,
    EditCostClassHourlyPricing: EditCostClassHourlyPricing,
    emptySocialExpensePercentagesByCostClassWithHash: emptySocialExpensePercentagesByCostClassWithHash,
    getEstimationSocialExpensePercentagesByCostClass: getEstimationSocialExpensePercentagesByCostClass,
    getEstimationSocialExpensePercentagesByCostClassWithHash: getEstimationSocialExpensePercentagesByCostClassWithHash,
    newtypeCostClassId: newtypeCostClassId,
    tofuJSONCostClassId: tofuJSONCostClassId,
    eqCostClassId: eqCostClassId,
    ordCostClassId: ordCostClassId,
    genericCostClassAction: genericCostClassAction,
    tofuJSONCostClassAction: tofuJSONCostClassAction,
    genericEditCostClassAction: genericEditCostClassAction,
    tofuJSONEditCostClassAction: tofuJSONEditCostClassAction,
    CostClassCode: Types_Newtypes.CostClassCode
};
