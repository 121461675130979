import { Button, Header, IconAddPlusV1, Input, Text } from "@tocoman/ui";
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useState } from "react";
import { ConfigurationModal } from "./ConfigurationModal";
import { ErrorMessage } from "../../../components/ErrorMessage";
import {
  PrintingConfigurationsParams,
  useCreatePrintingConfiguration,
  useDeletePrintingConfiguration,
  useUpdatePrintingConfiguration,
  useUpdatePublicPrintingConfiguration,
} from "./usePrintingConfigurations";
import { PrintingConfiguration } from "@prisma/client";
import { GridApi, RowGroupingDisplayType } from "ag-grid-community";
import { UserAppAccessRights } from "../../../../../ts-bindings/UserAppAccessRights";
import { reportError } from "../../../utils/error";
import { AxiosError } from "axios";
import { Popover } from "@mui/material";
import { AgGridConfigurationData } from "./useAgGridConfiguration";

type SaveSettingsToolPanelProps = {
  groupFooter: boolean;
  totalFooter: boolean;
  totalFooterWithSocial: boolean;
  includeResources: boolean;
  selectedConfiguration?: PrintingConfiguration | null;
  allConfigurations: PrintingConfiguration[];
  changeSelectedConfiguration: (configuration: PrintingConfiguration) => void;
  userAppRights: UserAppAccessRights;
  applyConfiguration: (configuration: string | AgGridConfigurationData) => void;
  groupDisplayType: RowGroupingDisplayType;
  api: GridApi;
};

export const SaveSettingsPopover = ({
  api,
  groupFooter,
  totalFooter,
  totalFooterWithSocial,
  includeResources,
  selectedConfiguration,
  allConfigurations,
  changeSelectedConfiguration,
  userAppRights,
  applyConfiguration,
  groupDisplayType,
}: SaveSettingsToolPanelProps) => {
  const { t } = useTranslation("estimation", {
    keyPrefix: "printing.saveSettings",
  });

  const [modelName, setModelName] = useState(selectedConfiguration?.name ?? "");
  const [isPublic, setIsPublic] = useState(false);
  const [configurationModalIsOpen, setConfigurationModalIsOpen] = useState(
    false
  );
  const [configurationNotFound, setConfigurationNotFound] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const createPrintingConfiguration = useCreatePrintingConfiguration();
  const updatePrintingConfiguration = useUpdatePrintingConfiguration();
  const deletePrintingConfiguration = useDeletePrintingConfiguration();
  const updatePublicPrintingConfiguration = useUpdatePublicPrintingConfiguration();

  const closePanel = useCallback(() => {
    setIsOpen(false);
  }, [api]);

  const modelExists = useCallback(() => {
    return allConfigurations.find(
      (model: Omit<PrintingConfiguration, "organization">) =>
        model.name.toLowerCase() === modelName.toLowerCase()
    );
  }, [allConfigurations, modelName]);

  const modelToEdit = modelExists();

  const saveConfigurationState = useCallback(async () => {
    const state = api.getState();

    // Remove unnecessary properties from the state
    delete state.sideBar;
    delete state.scroll;
    delete state.focusedCell;

    const agGridConfiguration = {
      state: state,
      groupFooter: groupFooter,
      totalFooter: totalFooter,
      totalFooterWithSocial: totalFooterWithSocial,
      groupDisplayType: groupDisplayType,
      includeResources: includeResources,
      version: "v2",
    };

    const saveParams: PrintingConfigurationsParams = {
      id: modelToEdit ? modelToEdit.id : null,
      agGridConfiguration: JSON.stringify(agGridConfiguration),
      isPublic,
      name: modelName,
    };

    const savedConfiguration = await (saveParams.id
      ? updatePrintingConfiguration.mutateAsync(saveParams)
      : createPrintingConfiguration.mutateAsync(saveParams));
    changeSelectedConfiguration(savedConfiguration);
    setTimeout(() => {
      savedConfiguration.agGridConfiguration &&
        applyConfiguration(savedConfiguration.agGridConfiguration);
    }, 0);
  }, [
    api,
    groupFooter,
    totalFooter,
    totalFooterWithSocial,
    includeResources,
    modelToEdit,
    isPublic,
    modelName,
    updatePrintingConfiguration,
    createPrintingConfiguration,
    changeSelectedConfiguration,
    applyConfiguration,
  ]);

  const handleDeleteClick = useCallback(async () => {
    const modelToDelete = modelExists();
    if (modelToDelete && modelToDelete.id !== null) {
      try {
        await deletePrintingConfiguration.mutateAsync(modelToDelete.id);
        changeSelectedConfiguration(
          allConfigurations[0].name !== selectedConfiguration?.name
            ? allConfigurations[0]
            : allConfigurations[1]
        );
      } catch (e) {
        if (e instanceof AxiosError) {
          reportError(e.response?.data.message, e);
        }
      }
    } else {
      setConfigurationNotFound(true);
    }
  }, [
    modelExists,
    deletePrintingConfiguration,
    changeSelectedConfiguration,
    allConfigurations,
    selectedConfiguration,
  ]);

  useEffect(() => {
    if (selectedConfiguration) {
      setModelName(selectedConfiguration.name);
      setIsPublic(selectedConfiguration.isPublic);
    }
  }, [selectedConfiguration]);

  const handlePublishClick = async (publish: boolean) => {
    const publishParams = {
      id: modelToEdit?.id ?? 0,
      isPublic: publish,
    };
    await updatePublicPrintingConfiguration.mutateAsync(publishParams);

    if (modelToEdit?.agGridConfiguration != null) {
      applyConfiguration(modelToEdit.agGridConfiguration);
    }
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(!isOpen);
  };
  const id = isOpen ? "save-settings-popover" : undefined;

  if (api.isDestroyed()) {
    return null;
  }
  return (
    <>
      <>
        <Button
          aria-describedby={id}
          label={t`saveSettings`}
          icon={IconAddPlusV1}
          iconPosition={"left"}
          color={"normal"}
          variant={"secondary"}
          className={"h-[30px] mr-3"}
          onClick={handleButtonClick}
          testId={"toggle-tool-panel"}
        />
        <Popover
          id={id}
          open={isOpen}
          anchorEl={anchorEl}
          onClose={() => setIsOpen(false)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{
            zIndex: 20,
            border: 1,
            borderColor: "lightgrey",
          }}
          disablePortal={true}
        >
          <div className={"flex-col max-w-[280px] p-4"}>
            <div>
              <div className={"mb-32"}>
                <Header title={t`saveSettings`} titleSize={"medium"} />
                <Text className={"pb-5"} text={t`description`} />
                <Input
                  label={t`name`}
                  className={"w-full"}
                  value={modelName}
                  onChange={(v) => {
                    setModelName(v.target.value);
                    setConfigurationNotFound(false);
                  }}
                  testId={"configuration-name-input"}
                />
                {configurationNotFound && (
                  <ErrorMessage errorMessage={t`configurationNotFound`} />
                )}
              </div>
              <div className={"flex justify-between w-full mb-2"}>
                <Button
                  label={t`cancel`}
                  variant={"secondary"}
                  onClick={closePanel}
                />
                <Button label={t`save`} onClick={saveConfigurationState} />
              </div>
            </div>
            <div className={"flex w-full flex-col"}>
              {userAppRights.superAdmin && modelToEdit && (
                <>
                  {!modelToEdit.isPublic && (
                    <Button
                      className={"w-full mt-2"}
                      label={t`publish`}
                      onClick={() => handlePublishClick(true)}
                      testId={"publish-btn"}
                    />
                  )}
                  {modelToEdit.isPublic && (
                    <Button
                      className={"w-full mt-2"}
                      label={t`unpublish`}
                      onClick={() => handlePublishClick(false)}
                      testId={"unpublish-btn"}
                    />
                  )}
                </>
              )}
              <Button
                className={"w-full mt-2"}
                label={t`exportImport`}
                onClick={() => setConfigurationModalIsOpen(true)}
              />
              {modelToEdit && !modelToEdit.isPublic && (
                <Button
                  label={t`delete`}
                  onClick={handleDeleteClick}
                  className={"w-full mt-2"}
                  color={"danger"}
                  testId={"delete-btn"}
                />
              )}
              {configurationModalIsOpen && (
                <ConfigurationModal
                  isOpen={configurationModalIsOpen}
                  closeModal={() => setConfigurationModalIsOpen(false)}
                  applyConfiguration={applyConfiguration}
                  api={api}
                  groupFooter={groupFooter}
                  totalFooter={totalFooter}
                  totalFooterWithSocial={totalFooterWithSocial}
                  groupDisplayType={
                    api.getGridOption("groupDisplayType") ?? "singleColumn"
                  }
                />
              )}
            </div>
          </div>
        </Popover>
      </>
    </>
  );
};
