// Generated by purs version 0.14.5
"use strict";
var Color = require("../Color/index.js");
var Data_Show = require("../Data.Show/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var Record = require("../Record/index.js");
var StyleProperties = require("../StyleProperties/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var BgModifier = (function () {
    function BgModifier() {

    };
    BgModifier.value = new BgModifier();
    return BgModifier;
})();
var FgModifier = (function () {
    function FgModifier() {

    };
    FgModifier.value = new FgModifier();
    return FgModifier;
})();
var BorderModifier = (function () {
    function BorderModifier() {

    };
    BorderModifier.value = new BorderModifier();
    return BorderModifier;
})();
var pageSidePadding = StyleProperties.size(TacoConstants.M.value);
var intToPx = (function () {
    var $13 = Data_Show.show(Data_Show.showInt);
    return function ($14) {
        return (function (v) {
            return v + "px";
        })($13($14));
    };
})();
var defaultInteractionStylesProps = {
    primaryColor: TacoConstants.PrimaryBlue.value,
    modifier: BgModifier.value,
    focusMix: -0.2,
    activeMix: 0.1
};
var interactionStyles = function (dictUnion) {
    return function (dictNub) {
        return function (props) {
            var v = Record.merge()()(props)(defaultInteractionStylesProps);
            var transition = (function () {
                if (v.modifier instanceof BgModifier) {
                    return React_Basic_Emotion.str("background-color 150ms ease-out");
                };
                if (v.modifier instanceof FgModifier) {
                    return React_Basic_Emotion.str("color 150ms ease-out");
                };
                if (v.modifier instanceof BorderModifier) {
                    return React_Basic_Emotion.str("border-color 150ms ease-out");
                };
                throw new Error("Failed pattern match at StyleUtils (line 57, column 18 - line 60, column 61): " + [ v.modifier.constructor.name ]);
            })();
            var primaryBrightness = Color.brightness(TacoConstants.color(v.primaryColor));
            var mixColor = (function () {
                var $6 = primaryBrightness > 0.8;
                if ($6) {
                    return Color.black;
                };
                return Color.white;
            })();
            var mix = Color.mix(Color.RGB.value)(TacoConstants.color(v.primaryColor))(mixColor);
            var focusColor = React_Basic_Emotion.color(mix(v.focusMix));
            var focus = (function () {
                if (v.modifier instanceof BgModifier) {
                    return React_Basic_Emotion.css()({
                        backgroundColor: focusColor
                    });
                };
                if (v.modifier instanceof FgModifier) {
                    return React_Basic_Emotion.css()({
                        color: focusColor
                    });
                };
                if (v.modifier instanceof BorderModifier) {
                    return React_Basic_Emotion.css()({
                        borderColor: focusColor
                    });
                };
                throw new Error("Failed pattern match at StyleUtils (line 49, column 13 - line 52, column 58): " + [ v.modifier.constructor.name ]);
            })();
            var activeColor = React_Basic_Emotion.color(mix(v.activeMix));
            var active = (function () {
                if (v.modifier instanceof BgModifier) {
                    return React_Basic_Emotion.css()({
                        backgroundColor: activeColor,
                        transition: React_Basic_Emotion.str("none")
                    });
                };
                if (v.modifier instanceof FgModifier) {
                    return React_Basic_Emotion.css()({
                        color: activeColor,
                        transition: React_Basic_Emotion.str("none")
                    });
                };
                if (v.modifier instanceof BorderModifier) {
                    return React_Basic_Emotion.css()({
                        borderColor: activeColor,
                        transition: React_Basic_Emotion.str("none")
                    });
                };
                throw new Error("Failed pattern match at StyleUtils (line 53, column 14 - line 56, column 86): " + [ v.modifier.constructor.name ]);
            })();
            return React_Basic_Emotion.css()({
                "&:hover, &:focus": React_Basic_Emotion.nested(focus),
                "&:active": React_Basic_Emotion.nested(active),
                transition: transition
            });
        };
    };
};
module.exports = {
    BgModifier: BgModifier,
    FgModifier: FgModifier,
    BorderModifier: BorderModifier,
    defaultInteractionStylesProps: defaultInteractionStylesProps,
    interactionStyles: interactionStyles,
    pageSidePadding: pageSidePadding,
    intToPx: intToPx
};
