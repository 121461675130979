// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var ColumnWidgets = require("../ColumnWidgets/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_List_Lazy_Types = require("../Data.List.Lazy.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Data_Validation_Semigroup = require("../Data.Validation.Semigroup/index.js");
var DeleteSalesInvoiceModal = require("../DeleteSalesInvoiceModal/index.js");
var Effect = require("../Effect/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var Foreign = require("../Foreign/index.js");
var Formatters = require("../Formatters/index.js");
var FormattingHelpers = require("../FormattingHelpers/index.js");
var Income_Styles = require("../Income.Styles/index.js");
var IncomeItemsControls = require("../IncomeItemsControls/index.js");
var Invoices_Styles = require("../Invoices.Styles/index.js");
var Locale = require("../Locale/index.js");
var NarrowLayout = require("../NarrowLayout/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_DOM_Internal = require("../React.Basic.DOM.Internal/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var Record = require("../Record/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoLoader = require("../TacoLoader/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoSelect = require("../TacoSelect/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Income = require("../Types.Income/index.js");
var Types_JSDateString = require("../Types.JSDateString/index.js");
var Types_Project = require("../Types.Project/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var UseFetch = require("../UseFetch/index.js");
var Utils = require("../Utils/index.js");
var WindowUtils = require("../WindowUtils/index.js");
var BySICode = (function () {
    function BySICode() {

    };
    BySICode.value = new BySICode();
    return BySICode;
})();
var BySIDescription = (function () {
    function BySIDescription() {

    };
    BySIDescription.value = new BySIDescription();
    return BySIDescription;
})();
var BySIValueWithoutVAT = (function () {
    function BySIValueWithoutVAT() {

    };
    BySIValueWithoutVAT.value = new BySIValueWithoutVAT();
    return BySIValueWithoutVAT;
})();
var BySIDate = (function () {
    function BySIDate() {

    };
    BySIDate.value = new BySIDate();
    return BySIDate;
})();
var IncomeItemDetails = (function () {
    function IncomeItemDetails() {

    };
    IncomeItemDetails.value = new IncomeItemDetails();
    return IncomeItemDetails;
})();
var UnallocatedSalesInvoices = (function () {
    function UnallocatedSalesInvoices() {

    };
    UnallocatedSalesInvoices.value = new UnallocatedSalesInvoices();
    return UnallocatedSalesInvoices;
})();
var ByGroupName = (function () {
    function ByGroupName() {

    };
    ByGroupName.value = new ByGroupName();
    return ByGroupName;
})();
var ByCode = (function () {
    function ByCode() {

    };
    ByCode.value = new ByCode();
    return ByCode;
})();
var ByName = (function () {
    function ByName() {

    };
    ByName.value = new ByName();
    return ByName;
})();
var ByAmountPaid = (function () {
    function ByAmountPaid() {

    };
    ByAmountPaid.value = new ByAmountPaid();
    return ByAmountPaid;
})();
var ByValueWithoutVAT = (function () {
    function ByValueWithoutVAT() {

    };
    ByValueWithoutVAT.value = new ByValueWithoutVAT();
    return ByValueWithoutVAT;
})();
var ByIsIncludedInTarget = (function () {
    function ByIsIncludedInTarget() {

    };
    ByIsIncludedInTarget.value = new ByIsIncludedInTarget();
    return ByIsIncludedInTarget;
})();
var ByDateOfPlannedInvoice = (function () {
    function ByDateOfPlannedInvoice() {

    };
    ByDateOfPlannedInvoice.value = new ByDateOfPlannedInvoice();
    return ByDateOfPlannedInvoice;
})();
var ByIsApproved = (function () {
    function ByIsApproved() {

    };
    ByIsApproved.value = new ByIsApproved();
    return ByIsApproved;
})();
var ByDateOfApproval = (function () {
    function ByDateOfApproval() {

    };
    ByDateOfApproval.value = new ByDateOfApproval();
    return ByDateOfApproval;
})();
var ColSelectionCheckbox = (function () {
    function ColSelectionCheckbox() {

    };
    ColSelectionCheckbox.value = new ColSelectionCheckbox();
    return ColSelectionCheckbox;
})();
var ColGroupName = (function () {
    function ColGroupName() {

    };
    ColGroupName.value = new ColGroupName();
    return ColGroupName;
})();
var ColCode = (function () {
    function ColCode() {

    };
    ColCode.value = new ColCode();
    return ColCode;
})();
var ColName = (function () {
    function ColName() {

    };
    ColName.value = new ColName();
    return ColName;
})();
var ColSum = (function () {
    function ColSum() {

    };
    ColSum.value = new ColSum();
    return ColSum;
})();
var ColDate = (function () {
    function ColDate() {

    };
    ColDate.value = new ColDate();
    return ColDate;
})();
var ColApproved = (function () {
    function ColApproved() {

    };
    ColApproved.value = new ColApproved();
    return ColApproved;
})();
var ColPaid = (function () {
    function ColPaid() {

    };
    ColPaid.value = new ColPaid();
    return ColPaid;
})();
var salesInvoiceStyleSet = TacoTable_Styles.foldingTableStyleSet;
var prepareIncomeItemColumns = function (f) {
    var prepare = function (v) {
        var v1 = f(v.col);
        return {
            key: v.key,
            label: v.label,
            width: v.width,
            cell: v1.cell,
            sortProperty: v1.sortProperty,
            headerJSX: Data_Maybe.Nothing.value
        };
    };
    return Data_Functor.map(Data_Functor.functorArray)(prepare)([ {
        col: ColSelectionCheckbox.value,
        key: "checkbox",
        label: React_Basic_DOM.text(""),
        width: new TacoTable_Types.Fixed("2em")
    }, {
        col: ColGroupName.value,
        key: "groupName",
        label: React_Basic_DOM.text(Locale.lookup_("table_incomeItems__group")),
        width: new TacoTable_Types.Flex(10)
    }, {
        col: ColCode.value,
        key: "code",
        label: React_Basic_DOM.text(Locale.lookup_("table_incomeItems__code")),
        width: new TacoTable_Types.Flex(7)
    }, {
        col: ColName.value,
        key: "name",
        label: React_Basic_DOM.text(Locale.lookup_("table_incomeItems__name")),
        width: new TacoTable_Types.Flex(25)
    }, {
        col: ColSum.value,
        key: "valueWithoutVAT",
        label: React_Basic_DOM.text(Locale.lookup_("table_incomeItems__valueWithoutVAT")),
        width: new TacoTable_Types.Flex(10)
    }, {
        col: ColDate.value,
        key: "dateOfPlannedInvoiceComponent",
        label: React_Basic_DOM.text(Locale.lookup_("table_incomeItems__dateOfPlannedInvoice")),
        width: new TacoTable_Types.Flex(15)
    }, {
        col: ColApproved.value,
        key: "isApprovedButton",
        label: React_Basic_DOM.text("Hyv."),
        width: new TacoTable_Types.Flex(5)
    }, {
        col: ColPaid.value,
        key: "amountPaid",
        label: React_Basic_DOM.text("Maks."),
        width: new TacoTable_Types.Flex(8)
    } ]);
};
var incomeItemStyleSet = TacoTable_Styles.foldingTableStyleSet;
var getSumOfSalesInvoiceRowData = function (xs) {
    return Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
        return v.valueWithoutVAT;
    })(xs));
};
var getSalesInvoiceSortPropertySortingValue = function (v) {
    if (v instanceof BySICode) {
        var $210 = Data_Maybe.maybe("")(Data_Newtype.unwrap());
        return function ($211) {
            return TacoTable_Types.StringValue.create($210((function (v1) {
                return v1.code;
            })($211)));
        };
    };
    if (v instanceof BySIDescription) {
        return function ($212) {
            return TacoTable_Types.StringValue.create((function (v1) {
                return v1.description;
            })($212));
        };
    };
    if (v instanceof BySIValueWithoutVAT) {
        return function ($213) {
            return TacoTable_Types.NumberValue.create((function (v1) {
                return v1.valueWithoutVAT;
            })($213));
        };
    };
    if (v instanceof BySIDate) {
        var $214 = Utils.defaultNull("");
        return function ($215) {
            return TacoTable_Types.StringValue.create($214((function (v1) {
                return v1.date;
            })($215)));
        };
    };
    throw new Error("Failed pattern match at IncomeItemsContainer (line 811, column 1 - line 811, column 110): " + [ v.constructor.name ]);
};
var getNameSalesInvoiceSortProperty = {
    getName: function (v) {
        return "SalesInvoiceSortProperty";
    }
};
var salesInvoiceTableInstance = TacoTable.mkTableSortable(getNameSalesInvoiceSortProperty)("SalesInvoice");
var getNameIncomeItemSortProperty = {
    getName: function (v) {
        return "IncomeItemSortProperty";
    }
};
var incomeItemTableInstance = TacoTable.mkTableSortable(getNameIncomeItemSortProperty)("IncomeItem");
var getIncomeItemSortPropertySortingValue = function (v) {
    if (v instanceof ByGroupName) {
        var $216 = Data_Newtype.unwrap();
        return function ($217) {
            return TacoTable_Types.StringValue.create($216((function (v1) {
                return v1.groupName;
            })((function (v1) {
                return v1.incomeItem;
            })($217))));
        };
    };
    if (v instanceof ByCode) {
        return function ($218) {
            return TacoTable_Types.StringValue.create((function (v1) {
                return v1.code;
            })((function (v1) {
                return v1.incomeItem;
            })($218)));
        };
    };
    if (v instanceof ByName) {
        return function ($219) {
            return TacoTable_Types.StringValue.create((function (v1) {
                return v1.name;
            })((function (v1) {
                return v1.incomeItem;
            })($219)));
        };
    };
    if (v instanceof ByAmountPaid) {
        return function ($220) {
            return TacoTable_Types.NumberValue.create((function (v1) {
                return v1.amountPaid;
            })((function (v1) {
                return v1.incomeItem;
            })($220)));
        };
    };
    if (v instanceof ByValueWithoutVAT) {
        return function ($221) {
            return TacoTable_Types.NumberValue.create((function (v1) {
                return v1.valueWithoutVAT;
            })((function (v1) {
                return v1.incomeItem;
            })($221)));
        };
    };
    if (v instanceof ByIsIncludedInTarget) {
        return function ($222) {
            return TacoTable_Types.BooleanValue.create((function (v1) {
                return v1.isIncludedInTarget;
            })((function (v1) {
                return v1.incomeItem;
            })($222)));
        };
    };
    if (v instanceof ByDateOfPlannedInvoice) {
        return function ($223) {
            return TacoTable_Types.StringValue.create((function (v1) {
                return v1.plannedInvoiceDate;
            })((function (v1) {
                return v1.incomeItem;
            })($223)));
        };
    };
    if (v instanceof ByIsApproved) {
        return function ($224) {
            return TacoTable_Types.BooleanValue.create((function (v1) {
                return v1.isApproved;
            })((function (v1) {
                return v1.incomeItem;
            })($224)));
        };
    };
    if (v instanceof ByDateOfApproval) {
        var $225 = Utils.defaultNull("");
        return function ($226) {
            return TacoTable_Types.StringValue.create($225((function (v1) {
                return v1.dateOfApproval;
            })((function (v1) {
                return v1.incomeItem;
            })($226))));
        };
    };
    throw new Error("Failed pattern match at IncomeItemsContainer (line 905, column 1 - line 905, column 104): " + [ v.constructor.name ]);
};
var eqSalesInvoiceSortProperty = {
    eq: function (x) {
        return function (y) {
            if (x instanceof BySICode && y instanceof BySICode) {
                return true;
            };
            if (x instanceof BySIDescription && y instanceof BySIDescription) {
                return true;
            };
            if (x instanceof BySIValueWithoutVAT && y instanceof BySIValueWithoutVAT) {
                return true;
            };
            if (x instanceof BySIDate && y instanceof BySIDate) {
                return true;
            };
            return false;
        };
    }
};
var ordSalesInvoiceSortProperty = {
    compare: function (x) {
        return function (y) {
            if (x instanceof BySICode && y instanceof BySICode) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof BySICode) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof BySICode) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof BySIDescription && y instanceof BySIDescription) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof BySIDescription) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof BySIDescription) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof BySIValueWithoutVAT && y instanceof BySIValueWithoutVAT) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof BySIValueWithoutVAT) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof BySIValueWithoutVAT) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof BySIDate && y instanceof BySIDate) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at IncomeItemsContainer (line 784, column 1 - line 784, column 76): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqSalesInvoiceSortProperty;
    }
};
var eqIncomeItemSortProperty = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ByGroupName && y instanceof ByGroupName) {
                return true;
            };
            if (x instanceof ByCode && y instanceof ByCode) {
                return true;
            };
            if (x instanceof ByName && y instanceof ByName) {
                return true;
            };
            if (x instanceof ByAmountPaid && y instanceof ByAmountPaid) {
                return true;
            };
            if (x instanceof ByValueWithoutVAT && y instanceof ByValueWithoutVAT) {
                return true;
            };
            if (x instanceof ByIsIncludedInTarget && y instanceof ByIsIncludedInTarget) {
                return true;
            };
            if (x instanceof ByDateOfPlannedInvoice && y instanceof ByDateOfPlannedInvoice) {
                return true;
            };
            if (x instanceof ByIsApproved && y instanceof ByIsApproved) {
                return true;
            };
            if (x instanceof ByDateOfApproval && y instanceof ByDateOfApproval) {
                return true;
            };
            return false;
        };
    }
};
var ordIncomeItemSortProperty = {
    compare: function (x) {
        return function (y) {
            if (x instanceof ByGroupName && y instanceof ByGroupName) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByGroupName) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByGroupName) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByCode && y instanceof ByCode) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByCode) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByCode) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByName && y instanceof ByName) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByName) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByName) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByAmountPaid && y instanceof ByAmountPaid) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByAmountPaid) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByAmountPaid) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByValueWithoutVAT && y instanceof ByValueWithoutVAT) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByValueWithoutVAT) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByValueWithoutVAT) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByIsIncludedInTarget && y instanceof ByIsIncludedInTarget) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByIsIncludedInTarget) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByIsIncludedInTarget) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByDateOfPlannedInvoice && y instanceof ByDateOfPlannedInvoice) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByDateOfPlannedInvoice) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByDateOfPlannedInvoice) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByIsApproved && y instanceof ByIsApproved) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByIsApproved) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByIsApproved) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByDateOfApproval && y instanceof ByDateOfApproval) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at IncomeItemsContainer (line 895, column 1 - line 895, column 72): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqIncomeItemSortProperty;
    }
};
var eqIncomeItemColumns = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ColSelectionCheckbox && y instanceof ColSelectionCheckbox) {
                return true;
            };
            if (x instanceof ColGroupName && y instanceof ColGroupName) {
                return true;
            };
            if (x instanceof ColCode && y instanceof ColCode) {
                return true;
            };
            if (x instanceof ColName && y instanceof ColName) {
                return true;
            };
            if (x instanceof ColSum && y instanceof ColSum) {
                return true;
            };
            if (x instanceof ColDate && y instanceof ColDate) {
                return true;
            };
            if (x instanceof ColApproved && y instanceof ColApproved) {
                return true;
            };
            if (x instanceof ColPaid && y instanceof ColPaid) {
                return true;
            };
            return false;
        };
    }
};
var ordIncomeItemColumns = {
    compare: function (x) {
        return function (y) {
            if (x instanceof ColSelectionCheckbox && y instanceof ColSelectionCheckbox) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ColSelectionCheckbox) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ColSelectionCheckbox) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ColGroupName && y instanceof ColGroupName) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ColGroupName) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ColGroupName) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ColCode && y instanceof ColCode) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ColCode) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ColCode) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ColName && y instanceof ColName) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ColName) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ColName) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ColSum && y instanceof ColSum) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ColSum) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ColSum) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ColDate && y instanceof ColDate) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ColDate) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ColDate) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ColApproved && y instanceof ColApproved) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ColApproved) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ColApproved) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ColPaid && y instanceof ColPaid) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at IncomeItemsContainer (line 841, column 1 - line 841, column 61): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqIncomeItemColumns;
    }
};
var component = TofuHooks.mkHookComponent("IncomeItemsContainer")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState(false)();
        var v2 = TofuHooks.useState(Data_Set.empty)();
        var v3 = TofuHooks.useState(Data_Set.empty)();
        var v4 = TofuHooks.useState(Data_Set.empty)();
        var v5 = TofuHooks.useState(Data_Set.empty)();
        var v6 = TofuHooks.useState(Data_Set.empty)();
        var v7 = TofuHooks.useState([  ])();
        var v8 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var v9 = TofuHooks.useState(false)();
        var v10 = UseFetch.useFetchItemsByProjectId(v.projectId)(function (v10) {
            return v10.incomeItems;
        })(Actions.GetIncomeItemsRequest.create)();
        var v11 = UseFetch.useFetchItemsByProjectId(v.projectId)(function (v11) {
            return v11.incomeGroups;
        })(Actions.GetIncomeGroupsRequest.create)();
        var v12 = UseFetch.useFetchItemsByProjectId(v.projectId)(function (v12) {
            return v12.salesInvoices;
        })(Actions.GetSalesInvoicesRequest.create)();
        var updateStatuses = StateHooks.useSelector(function (v13) {
            return v13.updateStatuses;
        })();
        var appDispatch = StateHooks.useDispatch();
        var removeSelectedIncomeItem = function (id) {
            return v2.value1(Data_Set["delete"](Types_Income.ordIncomeItemId)(id));
        };
        var removeActiveIncomeDetails = function (id) {
            return v4.value1(Data_Set["delete"](Types_Income.ordIncomeItemId)(id));
        };
        var prepareSIRowData = function (si) {
            return Record.merge()()({
                checked: Data_Foldable.elem(Data_Set.foldableSet)(Types_Income.eqSalesInvoiceId)(si.id)(v3.value0)
            })(si);
        };
        var toIncomeItemRowData = function (incomeItem) {
            return {
                incomeItem: incomeItem,
                checked: Data_Set.member(Types_Income.ordIncomeItemId)(incomeItem.id)(v2.value0),
                matchedSalesInvoices: Data_Functor.map(Data_Functor.functorArray)(prepareSIRowData)(Data_Array.filter(Utils.matchSalesInvoices(incomeItem))(v12.value0)),
                loadingIncomeItemTargetInclusionStatuses: Data_Array.elem(Types_Income.eqIncomeItemId)(incomeItem.id)(updateStatuses.loadingIncomeItemTargetInclusionStatuses),
                loadingIncomeItemApprovalStatuses: Data_Array.elem(Types_Income.eqIncomeItemId)(incomeItem.id)(updateStatuses.loadingIncomeItemApprovalStatuses),
                loadingIncomeItemPlannedInvoiceDates: Data_Array.elem(Types_Income.eqIncomeItemId)(incomeItem.id)(updateStatuses.loadingIncomeItemPlannedInvoiceDates),
                activeEditPlannedInvoiceDate: Data_Foldable.elem(Data_Set.foldableSet)(Types_Income.eqIncomeItemId)(incomeItem.id)(v6.value0),
                activeEditApprovalDate: Data_Foldable.elem(Data_Set.foldableSet)(Types_Income.eqIncomeItemId)(incomeItem.id)(v5.value0)
            };
        };
        var pageLoading = v10.value1 || v11.value1;
        var modalBody = function (selectedIds) {
            return TacoModal.modalBody({
                contents: (function () {
                    var mapMatchedIncomeItems = function (r) {
                        var $130 = Data_Array.elem(Types_Income.eqIncomeItemId)(r.id)(selectedIds);
                        if ($130) {
                            return new Data_Maybe.Just(r.code);
                        };
                        return Data_Maybe.Nothing.value;
                    };
                    var incomeItemCodes = Data_Array.sort(Data_Ord.ordString)(Data_Array.mapMaybe(mapMatchedIncomeItems)(v10.value0));
                    return [ Box.box("deleteConfirmation")([ new Box.MarginBottom(TacoConstants.M.value) ])([ React_Basic_DOM.text(Locale.lookup_("incomeItems_confirm_delete")) ]), Box.box("incomeItemCodes")([  ])([ React_Basic_DOM.text(Data_Array.intercalate(Data_Monoid.monoidString)(", ")(incomeItemCodes)) ]) ];
                })()
            });
        };
        var mkSummaryContainer = function (v13) {
            return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                className: "summary-container",
                css: Invoices_Styles.summaryContainerStyles,
                children: [ TacoText.component()()({
                    text: v13.text
                }), TacoText.component()()({
                    weight: TacoText_Types.Bold.value,
                    text: Formatters.formatCurrencyValue("EUR")(v13.value)
                }) ]
            });
        };
        var mkStrict100Percentage = function (percentage) {
            var $134 = percentage >= 0.9995 && percentage < 1.0;
            if ($134) {
                return 0.999;
            };
            return percentage;
        };
        var mkSalesInvoiceRow = function (columns) {
            return function (r) {
                return {
                    rowData: r,
                    rowKey: Data_Show.show(Types_Income.showSalesInvoiceId)(r.id),
                    onClick: function (v13) {
                        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                    },
                    className: "",
                    foldingElement: Data_Maybe.Nothing.value,
                    columns: columns
                };
            };
        };
        var makeIncomeItemDateElement = function (r) {
            return function (dateValue) {
                return function (v13) {
                    return function (action) {
                        if (v13.active) {
                            return Box.box("date-input")([ Box.FlexRow.value, Box.Width.create(new TacoConstants.CustomSize("100%")) ])([ TacoInput.dateInput()()(Types_JSDateString.eqJSDateString)({
                                type: "date",
                                clickEventHandling: TacoInput.NoHandling.value,
                                value: Data_Maybe.fromMaybe(Effect_Unsafe.unsafePerformEffect(Types_JSDateString.getJSDateStringToday))(Data_Either.hush(Types_JSDateString.makeJSDateString(Data_Maybe.fromMaybe("")(Data_Nullable.toMaybe(dateValue))))),
                                onSave: function (dateString) {
                                    return function __do() {
                                        appDispatch(action(r.projectId)([ {
                                            incomeItemId: r.id,
                                            date: Utils.pureNullable(dateString)
                                        } ]))();
                                        return v13.closeInput();
                                    };
                                },
                                width: Data_Maybe.Just.create(new TacoConstants.CustomSize("80%"))
                            }), (function () {
                                if (v13.loading) {
                                    return TacoLoader.component()()({
                                        size: "1rem"
                                    });
                                };
                                return TacoButton.component()()({
                                    icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconClose24Px.value),
                                    onClick: new Data_Maybe.Just(v13.closeInput)
                                });
                            })() ]);
                        };
                        var v14 = Data_Nullable.toMaybe(dateValue);
                        if (v14 instanceof Data_Maybe.Just) {
                            return TacoButton.component()()({
                                onClick: new Data_Maybe.Just(v13.openInput),
                                children: new Data_Maybe.Just([ React_Basic_DOM_Generated.span()({
                                    className: "label",
                                    children: [ FormattingHelpers.formatHelper.formatDateString(Foreign.unsafeToForeign(v14.value0)) ]
                                }) ]),
                                icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconEditV1.value)
                            });
                        };
                        if (v14 instanceof Data_Maybe.Nothing) {
                            return React_Basic.empty;
                        };
                        throw new Error("Failed pattern match at IncomeItemsContainer (line 671, column 14 - line 681, column 30): " + [ v14.constructor.name ]);
                    };
                };
            };
        };
        var incomeItemsControls = IncomeItemsControls.component({
            projectId: v.projectId,
            groups: v11.value0,
            disabledGroups: v7.value0,
            handleAddIncomeItemButton: v1.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean)),
            handleToggleGroup: function (group) {
                return function (isDisabled) {
                    if (isDisabled) {
                        return v7.value1(function (v13) {
                            return Data_Array.difference(Types_Income.eqIncomeGroupCode)(v7.value0)([ group ]);
                        });
                    };
                    return v7.value1(function (v13) {
                        return Data_Array.union(Types_Income.eqIncomeGroupCode)([ group ])(v7.value0);
                    });
                };
            }
        });
        var filteredIncomeItems = Data_Array.filter(function (i) {
            return Data_Array.notElem(Types_Income.eqIncomeGroupCode)(i.group)(v7.value0);
        })(v10.value0);
        var summaryRow = {
            groupName: "Yhteens\xe4",
            amountPaid: Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v13) {
                return v13.amountPaid;
            })(filteredIncomeItems)),
            valueWithoutVAT: Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v13) {
                return v13.valueWithoutVAT;
            })(filteredIncomeItems)),
            valueApprovedWithoutVAT: Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v13) {
                return v13.valueWithoutVAT;
            })(Data_Array.filter(function (v13) {
                return v13.isApproved;
            })(filteredIncomeItems)))
        };
        var summaryAcceptedContainer = mkSummaryContainer({
            text: "Hyv\xe4ksytty",
            value: summaryRow.valueApprovedWithoutVAT
        });
        var summaryPaidContainer = mkSummaryContainer({
            text: "Maksettu",
            value: summaryRow.amountPaid
        });
        var summarySumContainer = mkSummaryContainer({
            text: "Ennuste",
            value: summaryRow.valueWithoutVAT
        });
        var incomeItemsSummary = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "summary",
            css: Invoices_Styles.invoicesSummaryStyles,
            children: [ summarySumContainer, summaryAcceptedContainer, summaryPaidContainer ]
        });
        var deleteModal = DeleteSalesInvoiceModal.component({
            projectId: v.projectId,
            itemsToDelete: Data_Array.filter(function (i) {
                return Data_Foldable.elem(Data_Set.foldableSet)(Types_Income.eqSalesInvoiceId)(i.id)(v3.value0);
            })(v12.value0),
            modalOpen: v9.value0,
            setModalOpen: v9.value1
        });
        var deleteIncomeItemsBtn = TacoButton.component()()({
            onClick: new Data_Maybe.Just((function () {
                var selectedIncomeItemIds = Data_Array.fromFoldable(Data_Set.foldableSet)(v2.value0);
                return v8.value1(function (v13) {
                    return new Data_Maybe.Just(selectedIncomeItemIds);
                });
            })()),
            text: "Poista valitut tuloryhm\xe4t",
            color: TacoConstants.SecondaryRed.value,
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconDelete24Px.value)
        });
        var codeInputValidate = function (s) {
            var $148 = Data_String_CodePoints.length(s) > 10;
            if ($148) {
                return Data_Validation_Semigroup.invalid(Control_Applicative.pure(Data_List_Lazy_Types.applicativeNonEmptyList)("Sy\xf6tt\xe4m\xe4ss\xe4si tunnuksessa on " + (Data_Show.show(Data_Show.showInt)(Data_String_CodePoints.length(s) - 10 | 0) + " merkki\xe4 liikaa.")));
            };
            return Control_Applicative.pure(Data_Validation_Semigroup.applicativeV(Data_List_Lazy_Types.semigroupNonEmptyList))(s);
        };
        var codeInputMessage = "Sy\xf6t\xe4 uusi tunnus kassatapahtumalle. Max " + (Data_Show.show(Data_Show.showInt)(10) + " merkki\xe4.");
        var mkCodeInput = function (id) {
            return function (previousCode) {
                var handleUpdate = function (userInput) {
                    return appDispatch(new Actions.UpdateCodeForSalesInvoiceRequest({
                        projectId: v.projectId,
                        params: {
                            id: id,
                            code: userInput
                        }
                    }));
                };
                return ColumnWidgets.stringPrompt(previousCode)(Data_Maybe.Nothing.value)(codeInputMessage)(codeInputValidate)(handleUpdate);
            };
        };
        var mkSalesInvoiceColumns = function (instanceType) {
            var codeComponent = function (r) {
                return mkCodeInput(r.id)(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Newtype.un()(Types_Income.IncomeItemCode))(r.code));
            };
            var restOfTheColumns = [ {
                key: "codeComponent",
                label: React_Basic_DOM.text(Locale.lookup_("table_sales_invoices__code")),
                cell: new TacoTable_Types.JSXCell([  ], codeComponent),
                sortProperty: new Data_Maybe.Just(BySICode.value),
                width: new TacoTable_Types.Flex(1),
                headerJSX: Data_Maybe.Nothing.value
            }, {
                key: "description",
                label: React_Basic_DOM.text(Locale.lookup_("table_sales_invoices__description")),
                cell: new TacoTable_Types.PlainTextCell(function (v13) {
                    return v13.description;
                }),
                sortProperty: new Data_Maybe.Just(BySIDescription.value),
                width: new TacoTable_Types.Flex(3),
                headerJSX: Data_Maybe.Nothing.value
            }, {
                key: "valueWithoutVAT",
                label: React_Basic_DOM.text(Locale.lookup_("table_sales_invoices__amountPaid")),
                cell: new TacoTable_Types.PlainEuroCell(function (v13) {
                    return v13.valueWithoutVAT;
                }),
                sortProperty: new Data_Maybe.Just(BySIValueWithoutVAT.value),
                width: new TacoTable_Types.Flex(1),
                headerJSX: Data_Maybe.Nothing.value
            }, {
                key: "date",
                label: React_Basic_DOM.text(Locale.lookup_("table_sales_invoices__date")),
                cell: TacoTable_Types.PlainDateStringCell.create((function () {
                    var $227 = Utils.defaultNull("");
                    return function ($228) {
                        return $227((function (v13) {
                            return v13.date;
                        })($228));
                    };
                })()),
                sortProperty: new Data_Maybe.Just(BySIDate.value),
                width: new TacoTable_Types.Flex(1),
                headerJSX: Data_Maybe.Nothing.value
            } ];
            var checkbox = function (r) {
                return TacoCheckbox.component()()({
                    className: "no-margin",
                    isChecked: r.checked,
                    onToggle: new TacoCheckbox.NoEvent((function () {
                        if (r.checked) {
                            return v3.value1(function (s) {
                                return Data_Set["delete"](Types_Income.ordSalesInvoiceId)(r.id)(s);
                            });
                        };
                        return v3.value1(function (s) {
                            return Data_Set.insert(Types_Income.ordSalesInvoiceId)(r.id)(s);
                        });
                    })()),
                    labelText: Data_Maybe.Nothing.value,
                    testId: "toggle-selected-sales-invoice-" + Data_Show.show(Types_Income.showSalesInvoiceId)(r.id)
                });
            };
            var checkboxColumn = (function () {
                if (instanceType instanceof UnallocatedSalesInvoices) {
                    return [ {
                        key: "selectionCheckbox",
                        label: React_Basic_DOM.text(""),
                        cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], checkbox),
                        sortProperty: Data_Maybe.Nothing.value,
                        width: new TacoTable_Types.Flex(1),
                        headerJSX: Data_Maybe.Nothing.value
                    } ];
                };
                if (instanceType instanceof IncomeItemDetails) {
                    return [  ];
                };
                throw new Error("Failed pattern match at IncomeItemsContainer (line 586, column 26 - line 597, column 34): " + [ instanceType.constructor.name ]);
            })();
            return Data_Semigroup.append(Data_Semigroup.semigroupArray)(checkboxColumn)(restOfTheColumns);
        };
        var mkSalesInvoicesTable = function (instanceType) {
            return function (salesInvoiceDatas) {
                var salesInvoiceColumns = mkSalesInvoiceColumns(instanceType);
                return TacoTable.tableSortable(getNameSalesInvoiceSortProperty)(eqSalesInvoiceSortProperty)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "valueWithoutVAT";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "taxRate";
                    }
                })(Types_Unit.eqTaxrate))()({
                    reflectSymbol: function () {
                        return "id";
                    }
                })(Types_Income.eqSalesInvoiceId))()({
                    reflectSymbol: function () {
                        return "description";
                    }
                })(Data_Eq.eqString))()({
                    reflectSymbol: function () {
                        return "date";
                    }
                })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
                    reflectSymbol: function () {
                        return "code";
                    }
                })(Data_Maybe.eqMaybe(Types_Income.eqIncomeItemCode)))()({
                    reflectSymbol: function () {
                        return "checked";
                    }
                })(Data_Eq.eqBoolean)))(salesInvoiceTableInstance)(new TacoTable_Types.SortingCriteria(TacoTable_Types.Ascending.value, new Data_Maybe.Just(BySICode.value), getSalesInvoiceSortPropertySortingValue))(Data_Maybe.Nothing.value)({
                    rows: Data_Functor.map(Data_Functor.functorArray)(mkSalesInvoiceRow(salesInvoiceColumns))(salesInvoiceDatas),
                    columns: salesInvoiceColumns,
                    showHeader: true,
                    styleSet: salesInvoiceStyleSet
                });
            };
        };
        var mkIncomeItemRowDetailPanel = function (matchedSalesInvoices) {
            return function (v13) {
                var sumOfMatched = getSumOfSalesInvoiceRowData(matchedSalesInvoices);
                var salesInvoicesTableContent = (function () {
                    var $153 = Data_Array["null"](matchedSalesInvoices);
                    if ($153) {
                        return [ React_Basic_DOM_Generated.h3()({
                            className: "label-empty",
                            children: [ React_Basic_DOM.text(Locale.lookup_("incomeItem_details__no_sales_invoices")) ]
                        }) ];
                    };
                    return [ React_Basic_DOM_Generated.h3()({
                        className: "heading",
                        children: [ React_Basic_DOM_Generated.span()({
                            className: "label",
                            children: [ React_Basic_DOM.text(Locale.lookup_("incomeItem_details__sales_invoices")) ]
                        }), Box.box("margin")([ new Box.MarginRight(TacoConstants.S.value), new Box.Display(Box.Inline.value) ])([  ]), React_Basic_DOM_Generated.span()({
                            className: "count",
                            children: [ React_Basic_DOM.text(Data_Show.show(Data_Show.showInt)(Data_Array.length(matchedSalesInvoices))) ]
                        }) ]
                    }), mkSalesInvoicesTable(IncomeItemDetails.value)(matchedSalesInvoices) ];
                })();
                var mkIncomeGroupCodeOption = function (group) {
                    return {
                        value: Data_Newtype.un()(Types_Income.IncomeGroupCode)(group.code),
                        text: Data_Newtype.un()(Types_Income.IncomeGroupName)(group.name)
                    };
                };
                var updateIncomeGroupCode = TacoSelect.component()()({
                    label: "Tuloryhm\xe4:",
                    value: Data_Newtype.unwrap()(v13.incomeItem.group),
                    items: Data_Functor.map(Data_Functor.functorArray)(mkIncomeGroupCodeOption)(v11.value0),
                    onChange: TacoInput.changeHandler(TacoInput.inputValueString)(function (value) {
                        return appDispatch(new Actions.UpdateIncomeItemRequest({
                            projectId: v13.incomeItem.projectId,
                            params: {
                                incomeItemId: v13.incomeItem.id,
                                property: Types_Income.UpdateIncomeItemGroupCode.create(value)
                            }
                        }));
                    })
                });
                var isIncludedInTargetButton = (function () {
                    var $154 = Data_Array.elem(Types_Income.eqIncomeItemId)(v13.incomeItem.id)(updateStatuses.loadingIncomeItemTargetInclusionStatuses);
                    if ($154) {
                        return TacoLoader.component()()({
                            size: "1rem"
                        });
                    };
                    return TacoCheckbox.component()()({
                        className: "incomeItems-targetInclusion-toggle checkbox-toggle",
                        isChecked: v13.incomeItem.isIncludedInTarget,
                        labelText: Data_Maybe.Nothing.value,
                        onToggle: TacoCheckbox.NoEvent.create(appDispatch(new Actions.SetIncomeItemsInclusionInTargetRequest({
                            projectId: v13.incomeItem.projectId,
                            inclusionUpdates: [ {
                                incomeItemId: v13.incomeItem.id,
                                isIncludedInTarget: !v13.incomeItem.isIncludedInTarget
                            } ]
                        }))),
                        testId: "target-inclusion-" + Data_Show.show(Types_Income.showIncomeItemId)(v13.incomeItem.id)
                    });
                })();
                var incomeSumOfMatchedPercentage = mkStrict100Percentage(Utils.defaultZeroDiv(sumOfMatched)(v13.incomeItem.valueWithoutVAT));
                var handleUpdateSumWithoutVAT = function (defaultValue) {
                    var optionalWarning = (function () {
                        var $155 = Data_String_Common["null"](defaultValue);
                        if ($155) {
                            return "";
                        };
                        return Utils.getSumWithoutVATValidationWarning(defaultValue);
                    })();
                    return function __do() {
                        var m = WindowUtils.prompt(Locale.lookup_("insert_sumWithoutVAT_for_income_item") + (" \"" + (v13.incomeItem.name + ("\"" + optionalWarning))))(Formatters.formatDecimalValue(2)(v13.incomeItem.valueWithoutVAT))();
                        if (m instanceof Data_Maybe.Nothing) {
                            return Data_Unit.unit;
                        };
                        if (m instanceof Data_Maybe.Just) {
                            var v14 = Data_Validation_Semigroup.toEither(Utils.sumWithoutVATValidation(m.value0));
                            if (v14 instanceof Data_Either.Right) {
                                return appDispatch(new Actions.UpdateIncomeItemRequest({
                                    projectId: v13.incomeItem.projectId,
                                    params: {
                                        incomeItemId: v13.incomeItem.id,
                                        property: new Types_Income.UpdateIncomeItemSumWithoutVAT(v14.value0)
                                    }
                                }))();
                            };
                            if (v14 instanceof Data_Either.Left) {
                                return handleUpdateSumWithoutVAT(m.value0)();
                            };
                            throw new Error("Failed pattern match at IncomeItemsContainer (line 408, column 17 - line 417, column 56): " + [ v14.constructor.name ]);
                        };
                        throw new Error("Failed pattern match at IncomeItemsContainer (line 405, column 13 - line 417, column 56): " + [ m.constructor.name ]);
                    };
                };
                var updateSumWithoutVATBtn = React_Basic_DOM_Generated.div()({
                    className: "update-sumWithoutVAT",
                    children: Control_Applicative.pure(Control_Applicative.applicativeArray)(TacoButton.component()()({
                        onClick: Data_Maybe.Just.create(handleUpdateSumWithoutVAT("")),
                        icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconEditV1.value),
                        text: Locale.lookup_("edit_sumWithoutVAT")
                    }))
                });
                var handleUpdateCode = function (defaultValue) {
                    var optionalWarning = Utils.getCodeValidationWarning(defaultValue);
                    return function __do() {
                        var m = WindowUtils.prompt(Locale.lookup_("insert_code_for_income_item") + (" \"" + (v13.incomeItem.name + ("\"" + optionalWarning))))(v13.incomeItem.code)();
                        if (m instanceof Data_Maybe.Nothing) {
                            return Data_Unit.unit;
                        };
                        if (m instanceof Data_Maybe.Just && Data_Validation_Semigroup.isValid(Utils.codeValidation(m.value0)) === false) {
                            return handleUpdateCode(m.value0)();
                        };
                        if (m instanceof Data_Maybe.Just) {
                            return appDispatch(new Actions.UpdateIncomeItemRequest({
                                projectId: v13.incomeItem.projectId,
                                params: {
                                    incomeItemId: v13.incomeItem.id,
                                    property: Types_Income.UpdateIncomeItemCode.create(m.value0)
                                }
                            }))();
                        };
                        throw new Error("Failed pattern match at IncomeItemsContainer (line 436, column 13 - line 447, column 20): " + [ m.constructor.name ]);
                    };
                };
                var updateCodeBtn = React_Basic_DOM_Generated.div()({
                    className: "update-code",
                    children: Control_Applicative.pure(Control_Applicative.applicativeArray)(TacoButton.component()()({
                        onClick: Data_Maybe.Just.create(handleUpdateCode("")),
                        icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconEditV1.value),
                        text: Locale.lookup_("edit_code")
                    }))
                });
                var handleRename = function (defaultValue) {
                    var optionalWarning = Utils.getNameValidationWarning(defaultValue);
                    return function __do() {
                        var m = WindowUtils.prompt(Locale.lookup_("insert_name_for_income_item") + (" " + (v13.incomeItem.name + optionalWarning)))(v13.incomeItem.name)();
                        if (m instanceof Data_Maybe.Nothing) {
                            return Data_Unit.unit;
                        };
                        if (m instanceof Data_Maybe.Just && Data_Validation_Semigroup.isValid(Utils.nameValidation(m.value0)) === false) {
                            return handleRename(m.value0)();
                        };
                        if (m instanceof Data_Maybe.Just) {
                            return appDispatch(new Actions.UpdateIncomeItemRequest({
                                projectId: v13.incomeItem.projectId,
                                params: {
                                    incomeItemId: v13.incomeItem.id,
                                    property: new Types_Income.UpdateIncomeItemName(m.value0)
                                }
                            }))();
                        };
                        throw new Error("Failed pattern match at IncomeItemsContainer (line 462, column 13 - line 472, column 20): " + [ m.constructor.name ]);
                    };
                };
                var renameBtn = React_Basic_DOM_Generated.div()({
                    className: "rename",
                    children: Control_Applicative.pure(Control_Applicative.applicativeArray)(TacoButton.component()()({
                        onClick: Data_Maybe.Just.create(handleRename("")),
                        icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconEditV1.value),
                        text: Locale.lookup_("edit_name")
                    }))
                });
                var incomeItemControls = React_Basic_DOM_Generated.div_([ React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                    className: "income-item-controls",
                    css: Income_Styles.incomeItemControlsStyles,
                    children: [ React_Basic_DOM_Generated.div_([ updateIncomeGroupCode ]), updateSumWithoutVATBtn, updateCodeBtn, renameBtn ]
                }) ]);
                var dateOfApprovalComponent = makeIncomeItemDateElement(v13.incomeItem)(v13.incomeItem.dateOfApproval)({
                    active: v13.activeEditApprovalDate,
                    loading: v13.loadingIncomeItemApprovalStatuses,
                    openInput: v5.value1(function (v14) {
                        return Data_Set.insert(Types_Income.ordIncomeItemId)(v13.incomeItem.id)(v5.value0);
                    }),
                    closeInput: v5.value1(function (v14) {
                        return Data_Set["delete"](Types_Income.ordIncomeItemId)(v13.incomeItem.id)(v5.value0);
                    })
                })(function (projectId$prime) {
                    return function (dates) {
                        return new Actions.SetIncomeItemApprovalDatesRequest({
                            projectId: projectId$prime,
                            dates: dates
                        });
                    };
                });
                var incomeItemDetails = React_Basic_DOM_Generated.div_([ React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                    className: "heading",
                    css: Income_Styles.incomeItemDetailsStyles,
                    children: [ TacoText.component()()({
                        text: "Tavoite",
                        weight: TacoText_Types.Bold.value
                    }), ReactBasicUtils["span'"]("content")([ isIncludedInTargetButton ]) ]
                }), React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                    className: "heading",
                    css: Income_Styles.incomeItemDetailsStyles,
                    children: [ TacoText.component()()({
                        text: "Hyv. pvm",
                        weight: TacoText_Types.Bold.value
                    }), ReactBasicUtils["span'"]("content")([ dateOfApprovalComponent ]) ]
                }), React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                    className: "heading",
                    css: Income_Styles.incomeItemDetailsStyles,
                    children: [ TacoText.component()()({
                        text: "Yhteens\xe4",
                        weight: TacoText_Types.Bold.value
                    }), ReactBasicUtils["span'"]("content")([ React_Basic_DOM.text(Formatters.formatCurrencyValue("EUR")(sumOfMatched)), React_Basic_DOM.text(" ("), React_Basic_DOM.text(Formatters.formatPercentageValue({
                        precision: 1,
                        value: incomeSumOfMatchedPercentage
                    })), React_Basic_DOM.text(")") ]) ]
                }) ]);
                return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
                    className: "incomeItem-details-panel",
                    css: Invoices_Styles.invoiceDetailsStyles,
                    children: [ Box.box("detail-contents")([ Box.FlexColumn.value ])([ Box.box("controls-row")([ Box.FlexRow.value, Box.JustifySpaceBetween.value ])([ incomeItemDetails, incomeItemControls ]), Box.box("sales-invoices")([ new Box.MarginTop(TacoConstants.M.value) ])(salesInvoicesTableContent) ]) ]
                });
            };
        };
        var unknownSalesInvoicesTable = (function () {
            var unallocatedSalesInvoices = Data_Functor.map(Data_Functor.functorArray)(prepareSIRowData)(Data_Array.filter(Utils.isUnknownSalesInvoice(v10.value0))(v12.value0));
            var unallocatedSalesInvoicesSum = FormattingHelpers.formatHelper.formatCurrencyValue(Foreign.unsafeToForeign(Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v13) {
                return v13.valueWithoutVAT;
            })(unallocatedSalesInvoices))));
            var selectAllCheckbox = TacoCheckbox.component()()({
                className: "select-all-checkbox",
                isChecked: !Data_Set.isEmpty(v3.value0),
                onToggle: new TacoCheckbox.NoEvent((function () {
                    var $168 = Data_Set.isEmpty(v3.value0);
                    if ($168) {
                        return v3.value1(function (v13) {
                            return Data_Set.fromFoldable(Data_Foldable.foldableArray)(Types_Income.ordSalesInvoiceId)(Data_Functor.map(Data_Functor.functorArray)(function (v14) {
                                return v14.id;
                            })(unallocatedSalesInvoices));
                        });
                    };
                    return v3.value1(function (v13) {
                        return Data_Set.empty;
                    });
                })()),
                labelText: Data_Maybe.Just.create(TacoCheckbox.StringLabel.create((function () {
                    var v13 = Data_Set.size(v3.value0);
                    if (v13 === 0) {
                        return "Valitse kaikki";
                    };
                    return Data_Show.show(Data_Show.showInt)(v13) + " valittu";
                })())),
                testId: "select-all-checkbox"
            });
            var deleteButton = TacoButton.component()()({
                onClick: Data_Maybe.Just.create(v9.value1(function (v13) {
                    return true;
                })),
                icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconDelete24Px.value),
                text: Locale.lookup_("common_delete"),
                color: TacoConstants.SecondaryRed.value
            });
            var allocationDispatch = function (userInput) {
                var dispatchOne = function (id) {
                    return appDispatch(new Actions.UpdateCodeForSalesInvoiceRequest({
                        projectId: v.projectId,
                        params: {
                            id: id,
                            code: userInput
                        }
                    }));
                };
                return function __do() {
                    Data_Foldable.sequence_(Effect.applicativeEffect)(Data_Foldable.foldableArray)(Data_Functor.map(Data_Functor.functorArray)(dispatchOne)(Data_Array.fromFoldable(Data_Set.foldableSet)(v3.value0)))();
                    return v3.value1(function (v13) {
                        return Data_Set.empty;
                    })();
                };
            };
            var allocationButton = TacoButton.component()()({
                onClick: Data_Maybe.Just.create(ColumnWidgets.promptValue("")(codeInputMessage)(codeInputValidate)(allocationDispatch)),
                icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconEditV1.value),
                text: Locale.lookup_("allocate_selected")
            });
            var $170 = Data_Array.length(unallocatedSalesInvoices) > 0;
            if ($170) {
                return React_Basic_DOM_Generated.div()({
                    className: "unknown-sales-invoices",
                    children: [ React_Basic_Emotion.element(React_Basic_DOM_Generated["h3'"]())({
                        className: "heading",
                        css: Box.boxStyle([ new Box.Display(Box.Flex.value), Box.AlignCenter.value, new Box.Selector("& > *", [ new Box.MarginRight(TacoConstants.M.value) ]) ]),
                        children: [ React_Basic_DOM_Generated.span()({
                            className: "label",
                            children: [ React_Basic_DOM.text(Locale.lookup_("incomeItems__unknown_sales_invoices")) ]
                        }), selectAllCheckbox, (function () {
                            var $171 = Data_Set.isEmpty(v3.value0);
                            if ($171) {
                                return React_Basic.empty;
                            };
                            return allocationButton;
                        })(), (function () {
                            var $172 = Data_Set.isEmpty(v3.value0);
                            if ($172) {
                                return React_Basic.empty;
                            };
                            return deleteButton;
                        })(), deleteModal, React_Basic_DOM_Generated.span()({
                            className: "count",
                            children: [ React_Basic_DOM.text(Data_Show.show(Data_Show.showInt)(Data_Array.length(unallocatedSalesInvoices))), React_Basic_DOM.text(" " + Locale.lookup_("pcs")) ]
                        }), React_Basic_DOM_Generated.span()({
                            className: "sum",
                            children: [ React_Basic_DOM.text(Locale.lookup_("totalling") + " "), unallocatedSalesInvoicesSum ]
                        }) ]
                    }), React_Basic_DOM_Generated.div()({
                        className: "table-container",
                        children: [ mkSalesInvoicesTable(UnallocatedSalesInvoices.value)(unallocatedSalesInvoices) ]
                    }) ]
                });
            };
            return React_Basic.empty;
        })();
        var closeDeleteIncomeItemsModal = v8.value1(function (v13) {
            return Data_Maybe.Nothing.value;
        });
        var modalActions = function (selectedIds) {
            return TacoModal.modalActions({
                contents: [ TacoButton.component()()({
                    onClick: new Data_Maybe.Just(closeDeleteIncomeItemsModal),
                    text: "Peruuta"
                }), TacoButton.component()()({
                    text: "Poista",
                    color: TacoConstants.SecondaryRed.value,
                    onClick: new Data_Maybe.Just(function __do() {
                        appDispatch(new Actions.RemoveIncomeItemsRequest({
                            projectId: v.projectId,
                            params: Data_Functor.map(Data_Functor.functorArray)(function (v13) {
                                return {
                                    incomeItemId: v13
                                };
                            })(selectedIds)
                        }))();
                        v2.value1(function (v13) {
                            return Data_Set.empty;
                        })();
                        return closeDeleteIncomeItemsModal();
                    })
                }) ]
            });
        };
        var deleteIncomeItemsModal = (function () {
            if (v8.value0 instanceof Data_Maybe.Nothing) {
                return React_Basic.empty;
            };
            if (v8.value0 instanceof Data_Maybe.Just) {
                return TacoModal.component()()({
                    isActive: true,
                    onClose: closeDeleteIncomeItemsModal,
                    heading: "Poistaa maksuer\xe4t",
                    contents: [ modalBody(v8.value0.value0), modalActions(v8.value0.value0) ]
                });
            };
            throw new Error("Failed pattern match at IncomeItemsContainer (line 134, column 30 - line 144, column 12): " + [ v8.value0.constructor.name ]);
        })();
        var selectionControls = Box.box("selectionControls")([ new Box.PaddingY(TacoConstants.M.value), new Box.MarginLeft(new TacoConstants.CustomSize("auto")) ])([ ReactBasicUtils["div'"]("action-buttons")([ deleteIncomeItemsModal, deleteIncomeItemsBtn ]) ]);
        var controls = (function () {
            var $175 = Data_Set.isEmpty(v2.value0);
            if ($175) {
                return incomeItemsControls;
            };
            return selectionControls;
        })();
        var addSelectedIncomeItem = function (id) {
            return v2.value1(Data_Set.insert(Types_Income.ordIncomeItemId)(id));
        };
        var incomeItemColumns = (function () {
            var isApprovedButton = function (r) {
                var $176 = Data_Array.elem(Types_Income.eqIncomeItemId)(r.incomeItem.id)(updateStatuses.loadingIncomeItemApprovalStatuses);
                if ($176) {
                    return TacoLoader.component()()({
                        size: "1rem"
                    });
                };
                return TacoCheckbox.component()()({
                    className: "incomeItems-approval-toggle checkbox-toggle",
                    isChecked: r.incomeItem.isApproved,
                    onToggle: TacoCheckbox.NoEvent.create(appDispatch(new Actions.SetIncomeItemApprovalStatusesRequest({
                        projectId: r.incomeItem.projectId,
                        statuses: Control_Applicative.pure(Control_Applicative.applicativeArray)({
                            incomeItemId: r.incomeItem.id,
                            isApproved: !r.incomeItem.isApproved
                        })
                    }))),
                    labelText: Data_Maybe.Nothing.value,
                    testId: "approval-toggle-" + Data_Show.show(Types_Income.showIncomeItemId)(r.incomeItem.id)
                });
            };
            var dateOfPlannedInvoiceComponent = function (r) {
                return makeIncomeItemDateElement(r.incomeItem)(Utils.pureNullable(r.incomeItem.plannedInvoiceDate))({
                    active: r.activeEditPlannedInvoiceDate,
                    loading: r.loadingIncomeItemPlannedInvoiceDates,
                    openInput: v6.value1(function (v13) {
                        return Data_Set.insert(Types_Income.ordIncomeItemId)(r.incomeItem.id)(v6.value0);
                    }),
                    closeInput: v6.value1(function (v13) {
                        return Data_Set["delete"](Types_Income.ordIncomeItemId)(r.incomeItem.id)(v6.value0);
                    })
                })(function (projectId$prime) {
                    return function (dates) {
                        return new Actions.SetIncomeItemPlannedInvoiceDatesRequest({
                            projectId: projectId$prime,
                            dates: dates
                        });
                    };
                });
            };
            var checkbox = function (r) {
                return TacoCheckbox.component()()({
                    className: "",
                    isChecked: r.checked,
                    onToggle: new TacoCheckbox.NoEvent((function () {
                        if (r.checked) {
                            return removeSelectedIncomeItem(r.incomeItem.id);
                        };
                        return addSelectedIncomeItem(r.incomeItem.id);
                    })()),
                    labelText: Data_Maybe.Nothing.value,
                    testId: "toggle-selected-income-item-" + Data_Show.show(Types_Income.showIncomeItemId)(r.incomeItem.id)
                });
            };
            return prepareIncomeItemColumns(function (v13) {
                if (v13 instanceof ColSelectionCheckbox) {
                    return {
                        cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], checkbox),
                        sortProperty: Data_Maybe.Nothing.value
                    };
                };
                if (v13 instanceof ColGroupName) {
                    return {
                        cell: TacoTable_Types.PlainTextCell.create((function () {
                            var $229 = Data_Newtype.unwrap();
                            return function ($230) {
                                return $229((function (v14) {
                                    return v14.incomeItem.groupName;
                                })($230));
                            };
                        })()),
                        sortProperty: new Data_Maybe.Just(ByGroupName.value)
                    };
                };
                if (v13 instanceof ColCode) {
                    return {
                        cell: new TacoTable_Types.PlainTextCell(function (v14) {
                            return v14.incomeItem.code;
                        }),
                        sortProperty: new Data_Maybe.Just(ByCode.value)
                    };
                };
                if (v13 instanceof ColName) {
                    return {
                        cell: new TacoTable_Types.PlainTextCell(function (v14) {
                            return v14.incomeItem.name;
                        }),
                        sortProperty: new Data_Maybe.Just(ByName.value)
                    };
                };
                if (v13 instanceof ColSum) {
                    return {
                        cell: new TacoTable_Types.PlainEuroCell(function (v14) {
                            return v14.incomeItem.valueWithoutVAT;
                        }),
                        sortProperty: new Data_Maybe.Just(ByValueWithoutVAT.value)
                    };
                };
                if (v13 instanceof ColDate) {
                    return {
                        cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], dateOfPlannedInvoiceComponent),
                        sortProperty: new Data_Maybe.Just(ByDateOfPlannedInvoice.value)
                    };
                };
                if (v13 instanceof ColApproved) {
                    return {
                        cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], isApprovedButton),
                        sortProperty: new Data_Maybe.Just(ByIsApproved.value)
                    };
                };
                if (v13 instanceof ColPaid) {
                    return {
                        sortProperty: new Data_Maybe.Just(ByAmountPaid.value),
                        cell: new TacoTable_Types.JSXCell([  ], function (r) {
                            var sumOfMatched = getSumOfSalesInvoiceRowData(r.matchedSalesInvoices);
                            var incomeSumOfMatchedPercentage = mkStrict100Percentage(Utils.defaultZeroDiv(sumOfMatched)(r.incomeItem.valueWithoutVAT));
                            return Data_Monoid.guard(React_Basic.monoidJSX)(r.incomeItem.amountPaid < 0.0 || r.incomeItem.amountPaid > 0.0)(React_Basic.fragment([ Data_Monoid.guard(React_Basic.monoidJSX)(incomeSumOfMatchedPercentage >= 1.0)(React_Basic_DOM_Generated.img()({
                                src: "/icon-check_circle-v1.svg",
                                style: React_Basic_DOM_Internal.css({
                                    marginRight: "1em"
                                })
                            })), React_Basic_DOM.text(Formatters.formatPercentageValue({
                                precision: 1,
                                value: incomeSumOfMatchedPercentage
                            })) ]));
                        })
                    };
                };
                throw new Error("Failed pattern match at IncomeItemsContainer (line 535, column 32 - line 555, column 12): " + [ v13.constructor.name ]);
            });
        })();
        var addActiveIncomeDetails = function (id) {
            return v4.value1(Data_Set.insert(Types_Income.ordIncomeItemId)(id));
        };
        var mkIncomeItemRow = function (v13) {
            var detailsShown = Data_Set.member(Types_Income.ordIncomeItemId)(v13.incomeItem.id)(v4.value0);
            var foldedStatus = (function () {
                if (detailsShown) {
                    return TacoTable_Types.Unfolded.value;
                };
                return TacoTable_Types.Folded.value;
            })();
            return {
                rowData: v13,
                rowKey: Data_Show.show(Types_Income.showIncomeItemId)(v13.incomeItem.id),
                onClick: function (v14) {
                    var fn = (function () {
                        if (detailsShown) {
                            return removeActiveIncomeDetails;
                        };
                        return addActiveIncomeDetails;
                    })();
                    return fn(v13.incomeItem.id);
                },
                className: "",
                foldingElement: Data_Maybe.Just.create(new TacoTable_Types.FoldingElement(foldedStatus, mkIncomeItemRowDetailPanel(v13.matchedSalesInvoices))),
                columns: incomeItemColumns
            };
        };
        var incomeItemsTacoTable = TacoTable.tableSortable(getNameIncomeItemSortProperty)(eqIncomeItemSortProperty)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "matchedSalesInvoices";
            }
        })(Data_Eq.eqArray(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "valueWithoutVAT";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "taxRate";
            }
        })(Types_Unit.eqTaxrate))()({
            reflectSymbol: function () {
                return "id";
            }
        })(Types_Income.eqSalesInvoiceId))()({
            reflectSymbol: function () {
                return "description";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "date";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "code";
            }
        })(Data_Maybe.eqMaybe(Types_Income.eqIncomeItemCode)))()({
            reflectSymbol: function () {
                return "checked";
            }
        })(Data_Eq.eqBoolean)))))()({
            reflectSymbol: function () {
                return "loadingIncomeItemTargetInclusionStatuses";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "loadingIncomeItemPlannedInvoiceDates";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "loadingIncomeItemApprovalStatuses";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "incomeItem";
            }
        })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "valueWithoutVAT";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "taxRate";
            }
        })(Data_Nullable.eqNullable(Types_Unit.eqTaxrate)))()({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.eqProjectId))()({
            reflectSymbol: function () {
                return "plannedInvoiceDate";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "name";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "isIncludedInTarget";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "isApproved";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "importDate";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "id";
            }
        })(Types_Income.eqIncomeItemId))()({
            reflectSymbol: function () {
                return "groupName";
            }
        })(Types_Income.eqIncomeGroupName))()({
            reflectSymbol: function () {
                return "group";
            }
        })(Types_Income.eqIncomeGroupCode))()({
            reflectSymbol: function () {
                return "dateOfApproval";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqString)))()({
            reflectSymbol: function () {
                return "code";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "amountPaid";
            }
        })(Data_Eq.eqNumber))))()({
            reflectSymbol: function () {
                return "checked";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "activeEditPlannedInvoiceDate";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "activeEditApprovalDate";
            }
        })(Data_Eq.eqBoolean)))(incomeItemTableInstance)(new TacoTable_Types.SortingCriteria(TacoTable_Types.Ascending.value, new Data_Maybe.Just(ByCode.value), getIncomeItemSortPropertySortingValue))(Data_Maybe.Nothing.value)({
            rows: Data_Functor.map(Data_Functor.functorArray)(mkIncomeItemRow)(Data_Functor.map(Data_Functor.functorArray)(toIncomeItemRowData)(filteredIncomeItems)),
            columns: incomeItemColumns,
            showHeader: true,
            styleSet: incomeItemStyleSet
        });
        var incomeItemsTable = React_Basic_DOM_Generated.div()({
            className: "incomeItems-table",
            children: [ incomeItemsTacoTable, incomeItemsSummary ]
        });
        var incomeItemsContent = React_Basic_DOM_Generated.div()({
            className: "incomeItems-content",
            children: [ incomeItemsTable, unknownSalesInvoicesTable ]
        });
        return NarrowLayout.component()()({
            children: [ controls, (function () {
                if (pageLoading) {
                    return TacoLoader.component()()({});
                };
                return incomeItemsContent;
            })() ],
            verticalPadding: true
        });
    };
});
module.exports = {
    component: component
};
