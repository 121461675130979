// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var ClientLogger = require("../ClientLogger/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_JSDate = require("../Data.JSDate/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Effect_Aff = require("../Effect.Aff/index.js");
var Effect_Class = require("../Effect.Class/index.js");
var Effect_Exception = require("../Effect.Exception/index.js");
var FetchFile = require("../FetchFile/index.js");
var Milkis = require("../Milkis/index.js");
var RequestUtils = require("../RequestUtils/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_Alert = require("../Types.Alert/index.js");
var Types_Project = require("../Types.Project/index.js");
var Utils = require("../Utils/index.js");
var downloadZipInvoices = function (v) {
    return function (v1) {
        if (v1 instanceof Actions.ZipInvoiceImgsRequest) {
            return Effect_Aff.launchAff_(Control_Bind.bind(Effect_Aff.bindAff)(RequestUtils.mkHeaders)(function (headers) {
                var fetch = RequestUtils.fetch()(Milkis.URL("/api/invoices/zip-invoice-imgs"))({
                    method: Milkis.postMethod,
                    headers: headers,
                    body: TofuJSON.writeJSON(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                        reflectSymbol: function () {
                            return "files";
                        }
                    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                        reflectSymbol: function () {
                            return "filename";
                        }
                    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                        reflectSymbol: function () {
                            return "name";
                        }
                    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
                        reflectSymbol: function () {
                            return "filename";
                        }
                    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                        reflectSymbol: function () {
                            return "name";
                        }
                    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())))(TofuJSON.readFieldsCons({
                        reflectSymbol: function () {
                            return "projectId";
                        }
                    })(TofuJSON.readMaybe(Types_Project.tofuJSONProjectId))(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
                        reflectSymbol: function () {
                            return "files";
                        }
                    })(TofuJSON.readArray(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                        reflectSymbol: function () {
                            return "filename";
                        }
                    })(TofuJSON.readString)(TofuJSON.readFieldsCons({
                        reflectSymbol: function () {
                            return "name";
                        }
                    })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
                        reflectSymbol: function () {
                            return "filename";
                        }
                    })(TofuJSON.readString)(TofuJSON.consWriteForeignFields({
                        reflectSymbol: function () {
                            return "name";
                        }
                    })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())))(TofuJSON.consWriteForeignFields({
                        reflectSymbol: function () {
                            return "projectId";
                        }
                    })(TofuJSON.readMaybe(Types_Project.tofuJSONProjectId))(TofuJSON.nilWriteForeignFields)()()())()()()))(v1.value0)
                });
                return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Aff.attempt(fetch))(function (response) {
                    if (response instanceof Data_Either.Right) {
                        var $6 = Milkis.statusCode(response.value0) === 200;
                        if ($6) {
                            return Control_Bind.bind(Effect_Aff.bindAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)(Data_JSDate.now))(function (date) {
                                var dateString = Utils.lossyToHtmlDateString(date);
                                var _filename = "invoice-exports_" + dateString;
                                var filename = Data_String_Common.replaceAll(" ")("_")(_filename);
                                return Control_Monad_Error_Class.catchError(Effect_Aff.monadErrorAff)(FetchFile.createFileDownload(response.value0)(filename))(function (v2) {
                                    return v(new Actions.AddAlert(Types_Alert.AlertError.value, "Ei voitu luoda zip-tiedostoa: tarkista tiedostojen oikeellisuus."));
                                });
                            });
                        };
                        return Control_Monad_Error_Class.throwError(Effect_Aff.monadThrowAff)(Effect_Exception.error("Failed to zip invoice images"));
                    };
                    if (response instanceof Data_Either.Left) {
                        return Control_Bind.discard(Control_Bind.discardUnit)(Effect_Aff.bindAff)(Utils.printError(Effect_Aff.monadEffectAff)(response.value0))(function () {
                            return ClientLogger.logError(Effect_Aff.monadEffectAff)(response.value0);
                        });
                    };
                    throw new Error("Failed pattern match at FetchZip (line 34, column 5 - line 49, column 32): " + [ response.constructor.name ]);
                });
            }));
        };
        return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
    };
};
module.exports = {
    downloadZipInvoices: downloadZipInvoices
};
