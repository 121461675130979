import { useEffect, useState } from "react";
import { useScript } from "usehooks-ts";
import { useUserDataQuery } from "../containers/Projects/useUserData";
import { useLocation } from "react-router-dom";
import { useChangeUserLanguage } from "../hooks/queries/useUserLanguage";

export const IN_CONTEXT_LANGUAGE = "zu";
export const DEFAULT_LANGUAGE = "fi";

export function CrowdInEditor() {
  const changeLanguage = useChangeUserLanguage();
  const user = useUserDataQuery();
  const userLanguage = user?.userData.language;

  // Load Crowdin In-Context Script only if user language is reserved for In-Context translation
  useScript("https://cdn.crowdin.com/jipt/jipt.js", {
    shouldPreventLoad: !(userLanguage === IN_CONTEXT_LANGUAGE),
  });

  const location = useLocation();
  const searchParams = location.search;
  const disableCrowdIn = new URLSearchParams(searchParams).has(
    "disableCrowdIn"
  );

  // Disable In-Context translation if query parameter is present. Revert back to default language
  useEffect(() => {
    if (disableCrowdIn && userLanguage === IN_CONTEXT_LANGUAGE) {
      changeLanguage(DEFAULT_LANGUAGE);
    }
  }, [disableCrowdIn, userLanguage]);

  // Reload page if user language is changed to or from In-Context language
  const [, setLanguageTracker] = useState<string | null>();
  useEffect(() => {
    if (!userLanguage) return;
    setLanguageTracker((prev) => {
      try {
        return userLanguage;
      } finally {
        if (
          prev &&
          prev === IN_CONTEXT_LANGUAGE &&
          userLanguage !== IN_CONTEXT_LANGUAGE
        ) {
          // When user language is changed from In-Context language to another language, redirect to home page
          window.location.href = "/";
        }
        if (
          prev &&
          prev !== IN_CONTEXT_LANGUAGE &&
          userLanguage === IN_CONTEXT_LANGUAGE
        ) {
          // When user language is changed to In-Context language, reload the page
          window.location.reload();
        }
      }
    });
  }, [userLanguage]);

  return <></>;
}
