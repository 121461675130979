import { GridApi } from "ag-grid-community";
import { Button, Modal } from "@tocoman/ui";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AgGridConfigurationData } from "./useAgGridConfiguration";

type ConfigurationModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  applyConfiguration: (configuration: AgGridConfigurationData) => void;
  api: GridApi;
  groupFooter: boolean;
  totalFooter: boolean;
  totalFooterWithSocial: boolean;
  groupDisplayType: string;
};

export const ConfigurationModal = ({
  isOpen,
  closeModal,
  groupFooter,
  totalFooter,
  totalFooterWithSocial,
  groupDisplayType,
  api,
  applyConfiguration,
}: ConfigurationModalProps) => {
  const { t } = useTranslation("estimation", {
    keyPrefix: "printing.saveSettings",
  });
  const [configuration, setConfiguration] = useState(
    JSON.stringify({
      state: api.getState(),
      groupFooter: groupFooter,
      totalFooter: totalFooter,
      totalFooterWithSocial: totalFooterWithSocial,
      groupDisplayType: groupDisplayType,
      version: "v2",
    })
  );

  const handleSubmitConfiguration = useCallback(() => {
    const conf: AgGridConfigurationData = JSON.parse(configuration);
    applyConfiguration(conf);
    closeModal();
  }, [configuration, applyConfiguration, closeModal]);

  const actions = useMemo(
    () => (
      <div className={"flex justify-between w-full"}>
        <Button
          onClick={() => closeModal()}
          variant={"text"}
        >{t`cancel`}</Button>
        <Button
          onClick={handleSubmitConfiguration}
        >{t`useConfiguration`}</Button>
      </div>
    ),
    [closeModal, t, handleSubmitConfiguration]
  );

  return (
    <Modal
      isOpen={isOpen}
      closeModal={() => closeModal()}
      title={t`exportImportTitle`}
      width={960}
      actions={actions}
    >
      <textarea
        value={configuration}
        onChange={(v) => setConfiguration(v.target.value)}
        className="w-full h-64 outline-none border border-light rounded box-border p-1 hover:shadow focus-within:blue-500 focus-within:hover:shadow-none"
      />
    </Modal>
  );
};
