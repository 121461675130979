// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Effect = require("../Effect/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var Grid = require("../Grid/index.js");
var LengthValidator = require("../LengthValidator/index.js");
var Locale = require("../Locale/index.js");
var NumberValidator = require("../NumberValidator/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoSelect = require("../TacoSelect/index.js");
var TacoText = require("../TacoText/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_CostGroup = require("../Types.CostGroup/index.js");
var Types_JSDateString = require("../Types.JSDateString/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Project = require("../Types.Project/index.js");
var UseForm = require("../UseForm/index.js");
var mkCostClassItems = function (costClasses) {
    var nullOption = {
        value: "",
        text: Locale.lookup_("not_chosen")
    };
    var getOptions$prime = function (costClass) {
        return {
            value: Data_Newtype.unwrap()(costClass.costClassCode),
            text: Data_Newtype.unwrap()(costClass.costClassCode) + (": " + costClass.name)
        };
    };
    return Data_Array.cons(nullOption)(Data_Functor.map(Data_Functor.functorArray)(getOptions$prime)(costClasses));
};
var getBusinessId = function (supplier) {
    return function (suppliers) {
        var match = Data_Array.find(function (s) {
            return Data_Eq.eq(Types_Project.eqSupplier)(s.name)(supplier);
        })(suppliers);
        if (match instanceof Data_Maybe.Just) {
            return new Data_Maybe.Just(match.value0.businessId);
        };
        if (match instanceof Data_Maybe.Nothing) {
            return Data_Maybe.Nothing.value;
        };
        throw new Error("Failed pattern match at AddInvoice (line 269, column 6 - line 271, column 23): " + [ match.constructor.name ]);
    };
};
var component = TofuHooks.mkHookComponent("AddInvoice")(function (props) {
    return function __do() {
        var v = TofuHooks.useState("")();
        var v1 = TofuHooks.useState("")();
        var v2 = TofuHooks.useState("LAS")();
        var v3 = TofuHooks.useState("")();
        var v4 = TofuHooks.useState("")();
        var v5 = TofuHooks.useState(Effect_Unsafe.unsafePerformEffect(Types_JSDateString.getJSDateStringToday))();
        var v6 = TofuHooks.useState(0.0)();
        var v7 = TofuHooks.useState(Data_Maybe.fromMaybe("")(Data_Functor.map(Data_Maybe.functorMaybe)(function (v7) {
            return v7.name;
        })(Data_Array.head(props.suppliers))))();
        var v8 = TofuHooks.useState("")();
        var v9 = TofuHooks.useState("")();
        var v10 = TofuHooks.useState("")();
        var v11 = TofuHooks.useState("3")();
        var resetForm = function __do() {
            v.value1(function (v12) {
                return "";
            })();
            v1.value1(function (v12) {
                return "";
            })();
            v2.value1(function (v12) {
                return "LAS";
            })();
            v3.value1(function (v12) {
                return "";
            })();
            v4.value1(function (v12) {
                return "";
            })();
            v5.value1(function (v12) {
                return Effect_Unsafe.unsafePerformEffect(Types_JSDateString.getJSDateStringToday);
            })();
            v6.value1(function (v12) {
                return 0.0;
            })();
            v7.value1(function (v12) {
                return Data_Maybe.fromMaybe("")(Data_Functor.map(Data_Maybe.functorMaybe)(function (v13) {
                    return v13.name;
                })(Data_Array.head(props.suppliers)));
            })();
            v8.value1(function (v12) {
                return "";
            })();
            v9.value1(function (v12) {
                return "";
            })();
            v10.value1(function (v12) {
                return "";
            })();
            v11.value1(function (v12) {
                return "3";
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        };
        TofuHooks.useEffect([ props.isActive ])(resetForm)();
        var v12 = UseForm.useForm((function () {
            var insertSupplier = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_String_Common["null"])(Data_Newtype.un()(Types_Project.Supplier)(v8.value0));
            var invoiceValue = {
                projectCode: props.projectCode,
                supplier: (function () {
                    if (insertSupplier) {
                        return v8.value0;
                    };
                    return v7.value0;
                })(),
                invoiceNumber: v3.value0,
                description: v.value0,
                lineDescription: v1.value0,
                invoiceImg: "",
                label: v2.value0,
                type: (function () {
                    var $49 = v2.value0 === "LAS";
                    if ($49) {
                        return 0;
                    };
                    return 1;
                })(),
                costGroupCode: v4.value0,
                value: v6.value0,
                costClassCode: v11.value0,
                date: Types_JSDateString.extractJSDateString(v5.value0),
                insertSupplier: insertSupplier,
                businessId: v10.value0
            };
            var action = new Actions.InsertInvoiceRequest({
                projectId: props.projectId,
                value: invoiceValue
            });
            return function __do() {
                props.dispatch(action)();
                return props.toggleView();
            };
        })())();
        var valueInput = TacoInput.numberInput()()(Data_Eq.eqNumber)({
            label: "Summa",
            value: v6.value0,
            precision: 2,
            onChange: v6.value1,
            validator: NumberValidator.numberValidator,
            onValidationChange: v12.updateFieldValidity("value"),
            error: v12.isFieldError("value")
        });
        var newSupplierNameInput = TacoInput.stringInput()()(Data_Eq.eqString)({
            label: "Nimi",
            value: Data_Newtype.un()(Types_Project.Supplier)(v8.value0),
            onChange: TacoInput.changeHandler(TacoInput.inputValueString)(function (value$prime) {
                return function __do() {
                    v8.value1(function (v13) {
                        return value$prime;
                    })();
                    return v7.value1(function (v13) {
                        return Types_Project.Supplier(Locale.lookup_("not_chosen"));
                    })();
                };
            }),
            validator: LengthValidator.lengthValidator(0)(50),
            onValidationChange: v12.updateFieldValidity("newSupplier"),
            error: v12.isFieldError("newSupplier")
        });
        var newSupplierBusinessIdInput = TacoInput.stringInput()()(Data_Eq.eqString)({
            label: "Y-Tunnus",
            value: Data_Newtype.un()(Types_Project.BusinessId)(v10.value0),
            onChange: TacoInput.transformInput(TacoInput.inputValueString)(Types_Project.BusinessId)(v10.value1),
            validator: LengthValidator.lengthValidator(0)(9),
            onValidationChange: v12.updateFieldValidity("newSupplierBusinessId"),
            error: v12.isFieldError("newSupplierBusinessId")
        });
        var lineDescriptionInput = TacoInput.stringInput()()(Data_Eq.eqString)({
            label: "Riviselite",
            value: v1.value0,
            onChange: v1.value1,
            validator: LengthValidator.lengthValidator(1)(200),
            onValidationChange: v12.updateFieldValidity("lineDescription"),
            error: v12.isFieldError("lineDescription")
        });
        var invoiceTypeInput = TacoSelect.component()()({
            items: [ {
                value: "LAS",
                text: "Lasku"
            }, {
                value: "PAL",
                text: "Palkka"
            } ],
            label: "Tositetyyppi",
            value: v2.value0,
            onChange: v2.value1
        });
        var invoiceNumberInput = TacoInput.stringInput()()(Data_Eq.eqString)({
            label: "Tositenumero",
            value: v3.value0,
            onChange: v3.value1,
            validator: LengthValidator.lengthValidator(1)(50),
            onValidationChange: v12.updateFieldValidity("invoiceNumber"),
            error: v12.isFieldError("invoiceNumber")
        });
        var existingSupplierNameInput = TacoSelect.component()()({
            label: "Nimi",
            value: Data_Newtype.un()(Types_Project.Supplier)(v7.value0),
            items: Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Data_Functor.functorFn)(Data_Functor.map(Data_Functor.functorFn)(function (name) {
                return {
                    value: name,
                    text: name
                };
            })(Data_Newtype.unwrap()))(function (v13) {
                return v13.name;
            }))(props.suppliers),
            onChange: TacoInput.changeHandler(TacoInput.inputValueString)(function (value$prime) {
                return function __do() {
                    v7.value1(function (v13) {
                        return value$prime;
                    })();
                    v8.value1(function (v13) {
                        return "";
                    })();
                    return v10.value1(function (v13) {
                        return "";
                    })();
                };
            })
        });
        var existingSupplierBusinessIdInput = TacoInput.stringInput()()(Data_Eq.eqString)({
            label: "Y-tunnus",
            disabled: true,
            value: Data_Maybe.fromMaybe("")(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Newtype.unwrap())(getBusinessId(v7.value0)(props.suppliers)))
        });
        var supplierFields = (function () {
            var newSupplierHeading = TacoText.component()()({
                text: "Tai luo uusi toimittaja"
            });
            var existingSupplierHeading = TacoText.component()()({
                text: "Valitse toimittaja"
            });
            return Box.box("supplierFields")([ Box.MaxWidth.create(new TacoConstants.CustomSize("500px")) ])(Control_Applicative.pure(Control_Applicative.applicativeArray)(Grid.grid([ new Box.Gap(TacoConstants.S.value), Box.AlignStart.value, Box.Width.create(new TacoConstants.CustomSize("100%")) ])([ [ new Grid.Auto(existingSupplierHeading), new Grid.Auto(newSupplierHeading) ], [ new Grid.Auto(existingSupplierNameInput), new Grid.Auto(newSupplierNameInput) ], [ new Grid.Auto(existingSupplierBusinessIdInput), new Grid.Auto(newSupplierBusinessIdInput) ] ])));
        })();
        var descriptionInput = TacoInput.stringInput()()(Data_Eq.eqString)({
            label: "Selite",
            value: v.value0,
            onChange: v.value1,
            validator: LengthValidator.lengthValidator(1)(50),
            onValidationChange: v12.updateFieldValidity("description"),
            error: v12.isFieldError("description")
        });
        var dateInput = TacoInput.dateInput()()(Types_JSDateString.eqJSDateString)({
            label: "P\xe4iv\xe4ys",
            type: "date",
            clickEventHandling: TacoInput.NoHandling.value,
            value: v5.value0,
            onChange: v5.value1
        });
        var costGroupCodeInput = TacoInput.stringInput()()(Data_Eq.eqString)({
            label: "Littera",
            validator: LengthValidator.lengthValidator(1)(10),
            value: Data_Newtype.un()(Types_CostGroup.CostGroupCode)(v4.value0),
            onChange: TacoInput.transformInput(TacoInput.inputValueString)(Types_CostGroup.CostGroupCode)(v4.value1),
            onValidationChange: v12.updateFieldValidity("costGroupCode"),
            error: v12.isFieldError("costGroupCode")
        });
        var costClassInput = TacoSelect.component()()({
            label: "Kustannuslaji",
            value: Data_Newtype.un()(Types_Newtypes.CostClassCode)(v11.value0),
            items: mkCostClassItems(props.costClasses),
            onChange: TacoInput.transformInput(TacoInput.inputValueString)(Types_Newtypes.CostClassCode)(v11.value1)
        });
        var form = Box.box("formFields")([ Box.MaxWidth.create(new TacoConstants.CustomSize("500px")) ])(Control_Applicative.pure(Control_Applicative.applicativeArray)(Grid.grid([ new Box.Gap(TacoConstants.S.value), Box.AlignStart.value, Box.Width.create(new TacoConstants.CustomSize("100%")) ])([ [ new Grid.Auto(invoiceTypeInput) ], [ new Grid.Auto(invoiceNumberInput) ], [ new Grid.Auto(costGroupCodeInput), new Grid.Auto(costClassInput) ], [ new Grid.Auto(valueInput), new Grid.Auto(dateInput) ], [ new Grid.Auto(descriptionInput) ], [ new Grid.Auto(lineDescriptionInput) ] ])));
        var modalBody = TacoModal.modalBody({
            contents: [ form, supplierFields ]
        });
        var cancelBtn = TacoButton.component()()({
            onClick: new Data_Maybe.Just(props.toggleView),
            text: "Peruuta",
            color: TacoConstants.SecondaryRed.value
        });
        var addBtn = TacoButton.component()()({
            submit: true,
            text: "Tallenna",
            color: TacoConstants.PrimaryBlue.value
        });
        var modalActions = TacoModal.modalActions({
            contents: [ cancelBtn, addBtn ]
        });
        return TacoModal.component()()({
            heading: "Luo uusi lasku",
            onClose: props.toggleView,
            isActive: props.isActive,
            contents: [ React_Basic_DOM_Generated.form()({
                onSubmit: v12.onSubmit,
                ref: v12.formRef,
                children: [ modalBody, modalActions ]
            }) ]
        });
    };
});
module.exports = {
    component: component
};
