// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var AddCostGroup = require("../AddCostGroup/index.js");
var Box = require("../Box/index.js");
var Calculation_CostGroup = require("../Calculation.CostGroup/index.js");
var Calculation_Income = require("../Calculation.Income/index.js");
var Calculation_Utils = require("../Calculation.Utils/index.js");
var Client_WASM = require("../Client.WASM/index.js");
var Control_Alt = require("../Control.Alt/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var CostControlTable = require("../CostControlTable/index.js");
var CostControlTableControls = require("../CostControlTableControls/index.js");
var CostGroupDetails_Types = require("../CostGroupDetails.Types/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Formatters = require("../Formatters/index.js");
var Locale = require("../Locale/index.js");
var NarrowLayout = require("../NarrowLayout/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var StateHooks = require("../StateHooks/index.js");
var StringUtils = require("../StringUtils/index.js");
var TacoLoader = require("../TacoLoader/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_CostGroup = require("../Types.CostGroup/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_UploadStatus = require("../Types.UploadStatus/index.js");
var UploadCostGroups = require("../UploadCostGroups/index.js");
var UseFetch = require("../UseFetch/index.js");
var UseLocalStorage = require("../UseLocalStorage/index.js");
var Utils = require("../Utils/index.js");
var localStorageKeys = {
    hideEmptyCostGroups: "ui:json:hide_empty_cost_groups_in_list",
    showCostGroupCodeTwoLevelNesting: "ui:json:cg:show_cost_group_code_two_level_nesting",
    displayCompletionPercentage: "ui:json:cg:display_completion_percentage",
    displayMemo: "ui:json:cg:display_memo",
    displayEstimateRemaining: "ui:json:cg:display_estimate_remaining",
    displayActualToEstimateRatio: "ui:json:cg:display_actual_to_estimate_ratio",
    descriptionDisplay: "ui:json:cg:description_display",
    displayFullWidth: "ui:json:cg:display_full_width",
    disableSocialCostToActual: "ui:json:cg:disable_social_cost_to_actual"
};
var component = TofuHooks.mkHookComponent("CostControlContainer")(function (v) {
    return function __do() {
        var v1 = UseFetch.useFetchValueByProjectId(v.projectId)(function (v1) {
            return v1.selectedProject;
        })(Actions.GetProjectRequest.create)();
        var maybeProject = Data_Functor.map(Data_Maybe.functorMaybe)(function (v2) {
            return v2.project;
        })(v1.value0);
        var loadingAddNewCostGroup = StateHooks.useSelector(function (v2) {
            return v2.updateStatuses.loadingAddNewCostGroup;
        })();
        var updateStatuses = StateHooks.useSelector(function (v2) {
            return v2.updateStatuses;
        })();
        var appDispatch = StateHooks.useDispatch();
        var v2 = UseFetch.useFetchItemsByProjectId(v.projectId)(function (v2) {
            return v2.invoices;
        })(Actions.GetInvoicesRequest.create)();
        var v3 = UseFetch.useFetchItemsByProjectId(v.projectId)(function (v3) {
            return v3.costGroupInfos;
        })(Actions.CostGroupInfosRequest.create)();
        var v4 = UseFetch.useFetchItemsByProjectId(v.projectId)(function (v4) {
            return v4.costGroupCategories;
        })(Actions.ProjectCostGroupCategoriesRequest.create)();
        var v5 = UseFetch.useFetchItemsByProjectId(v.projectId)(function (v5) {
            return v5.estimateItems;
        })(Actions.ProjectEstimateItemsRequest.create)();
        var v6 = UseFetch.useFetchItemsByProjectId(v.projectId)(function (v6) {
            return v6.targetItems;
        })(Actions.ProjectTargetItemsRequest.create)();
        var v7 = Client_WASM.useCostClasses(v.projectId)();
        var v8 = UseFetch.useFetchItemsByProjectId(v.projectId)(function (v8) {
            return v8.reportingResources;
        })(Actions.GetReportingResourcesRequest.create)();
        var v9 = UseFetch.useFetchItemsByProjectId(v.projectId)(function (v9) {
            return v9.reportingComponents;
        })(Actions.GetReportingComponentsRequest.create)();
        var v10 = TofuHooks.useState("")();
        var v11 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)(localStorageKeys.showCostGroupCodeTwoLevelNesting)(false)(false)();
        var v12 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)(localStorageKeys.hideEmptyCostGroups)(false)(false)();
        var v13 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)(localStorageKeys.displayCompletionPercentage)(false)(false)();
        var v14 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)(localStorageKeys.displayEstimateRemaining)(false)(false)();
        var v15 = UseLocalStorage.useLocalStorageJSON(CostGroupDetails_Types.tofuJSONDescriptionDisplay)(localStorageKeys.descriptionDisplay)(CostGroupDetails_Types.ShowInvoiceDescriptions.value)(false)();
        var v16 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)(localStorageKeys.displayFullWidth)(false)(false)();
        var v17 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)(localStorageKeys.displayMemo)(false)(false)();
        var v18 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readBoolean)(localStorageKeys.displayActualToEstimateRatio)(false)(false)();
        var v19 = UseFetch.useFetchValue(Data_Eq.eqUnit)(Data_Unit.unit)(function (v19) {
            return v19.costControlExcludeActualCostSocialExpenses;
        })(Actions.GetCostControlExcludeActualCostSocialExpensesRequest.value)();
        var disableSocialCostToActual = (function () {
            if (v19.value0 instanceof Data_Maybe.Just && v19.value0.value0.costControlExcludeActualCostSocialExpenses) {
                return Calculation_Income.DisableSocialCostToActual.value;
            };
            return Calculation_Income.AllowSocialCostToActual.value;
        })();
        var v20 = TofuHooks.useState(false)();
        var v21 = TofuHooks.useState(true)();
        var v22 = TofuHooks.useState(true)();
        var v23 = TofuHooks.useState(true)();
        var v24 = TofuHooks.useState(true)();
        var v25 = TofuHooks.useState(true)();
        var v26 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var v27 = TofuHooks.useState(Types_UploadStatus.Standby.value)();
        var v28 = TofuHooks.useState([  ])();
        var uploadButtonProps = {
            filename: v26.value0,
            setFilename: v26.value1,
            uploadStatus: v27.value0,
            setUploadStatus: v27.value1,
            projectId: v.projectId,
            uniqueId: "costgroups-upload-input",
            className: ""
        };
        var selectCostGroups = function (codes) {
            return v28.value1(function (s) {
                return Data_Array.union(Data_Eq.eqString)(s)(codes);
            });
        };
        var removeSelectedCostGroups = function (codes) {
            return v28.value1(function (s) {
                return Data_Array.difference(Data_Eq.eqString)(s)(codes);
            });
        };
        var noCostGroups = (function () {
            var v29 = function (v30) {
                if (Data_Boolean.otherwise) {
                    return false;
                };
                throw new Error("Failed pattern match at CostControlContainer (line 81, column 1 - line 81, column 29): " + [  ]);
            };
            var $98 = !v3.value1;
            if ($98) {
                var $99 = !loadingAddNewCostGroup;
                if ($99) {
                    var $100 = Data_Array.length(v3.value0) === 0;
                    if ($100) {
                        return true;
                    };
                    return v29(true);
                };
                return v29(true);
            };
            return v29(true);
        })();
        var noContent = Data_Monoid.guard(Data_Monoid.monoidFn(Data_Maybe.monoidMaybe(React_Basic.semigroupJSX)))(noCostGroups)(Data_Maybe.Just.create)(React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "no-cost-control-items",
            css: Box.boxStyle([ Box.FlexColumn.value, Box.AlignCenter.value ]),
            children: [ React_Basic_DOM_Generated.h3()({
                children: Control_Applicative.pure(Control_Applicative.applicativeArray)(React_Basic_DOM.text(Locale.lookup_("no_cost_control_items")))
            }), UploadCostGroups.uploadCostGroups({
                uploadStatus: uploadButtonProps.uploadStatus,
                setUploadStatus: uploadButtonProps.setUploadStatus,
                filename: uploadButtonProps.filename,
                setFilename: uploadButtonProps.setFilename,
                projectId: uploadButtonProps.projectId,
                uniqueId: "controls-costGroups-upload-input",
                className: "flex-centered"
            }) ]
        }));
        var mkCostGroupUnitAndCostClasses = (function () {
            var withOrWithoutSocialExpenses = (function () {
                if (v25.value0) {
                    return Calculation_CostGroup.WithSocialExpenses.value;
                };
                return Calculation_CostGroup.WithoutSocialExpenses.value;
            })();
            var targetItemsForCGU = function (v29) {
                return Data_Array.filter(function (x) {
                    return Data_Eq.eq(Types_CostGroup.eqCostGroupCode)(x.costGroupCode)(v29.code);
                })(v6.value0);
            };
            var resourcesForCGU = function (v29) {
                return Data_Array.filter((function () {
                    var $185 = Data_Eq.eq(Data_Maybe.eqMaybe(Types_CostGroup.eqCostGroupCode))(new Data_Maybe.Just(v29.code));
                    var $186 = Data_Newtype.un()(Types_CostGroup.ReportingResource);
                    return function ($187) {
                        return $185((function (v30) {
                            return v30.costGroupCode;
                        })($186($187)));
                    };
                })())(v8.value0);
            };
            var matchResourceComponent = function (v29) {
                return StringUtils.stringContainsCaseIgnored(v10.value0)([ v29.description ]);
            };
            var invoiceItemsForCGU = function (v29) {
                return Data_Array.filter(function (x) {
                    return Data_Eq.eq(Data_Nullable.eqNullable(Types_CostGroup.eqCostGroupCode))(x.costGroupCode)(Utils.pureNullable(v29.code));
                })(v2.value0);
            };
            var getComponentIdCostGroupAssociation = function (v29) {
                return Control_Apply.apply(Data_Maybe.applyMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Tuple.Tuple.create)(v29.componentId))(v29.costGroupCode);
            };
            var filterWords = Data_Functor.map(Data_Functor.functorArray)(Data_String_Common.trim)(Data_String_Common.split(",")(v10.value0));
            var estimateItemsForCGU = function (v29) {
                return Data_Array.filter(function (x) {
                    return Data_Eq.eq(Types_CostGroup.eqCostGroupCode)(x.costGroupCode)(v29.code);
                })(v5.value0);
            };
            var isCGUEmpty = function (costGroupUnit) {
                return Utils.nearZero(Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v29) {
                    return v29.value;
                })(estimateItemsForCGU(costGroupUnit)))) && (Utils.nearZero(Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v29) {
                    return v29.value;
                })(targetItemsForCGU(costGroupUnit)))) && (Utils.nearZero(Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v29) {
                    return v29.value;
                })(invoiceItemsForCGU(costGroupUnit)))) && Utils.nearZero(Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)((function () {
                    var $188 = Data_Maybe.fromMaybe(0.0);
                    var $189 = Data_Newtype.un()(Types_CostGroup.ReportingResource);
                    return function ($190) {
                        return $188((function (v29) {
                            return v29.value;
                        })($189($190)));
                    };
                })())(resourcesForCGU(costGroupUnit))))));
            };
            var filterEmptyCGUs = (function () {
                var $108 = v12.value0 && !v11.value0;
                if ($108) {
                    return Data_Array.filter((function () {
                        var $191 = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean);
                        return function ($192) {
                            return $191(isCGUEmpty((function (v29) {
                                return v29.costGroupUnit;
                            })($192)));
                        };
                    })());
                };
                return Control_Category.identity(Control_Category.categoryFn);
            })();
            var costGroupItems = Data_Semigroup.append(Data_Semigroup.semigroupArray)(v6.value0)(v5.value0);
            var componentIdCostGroupCodeAssocations = Data_Map_Internal.fromFoldable(Types_Component.ordComponentId)(Data_Foldable.foldableArray)(Data_Array.mapMaybe(getComponentIdCostGroupAssociation)(v8.value0));
            var filterStringComponentMatches = (function () {
                var $109 = Data_String_Common["null"](v10.value0);
                if ($109) {
                    return Data_Set.empty;
                };
                return Data_Set.fromFoldable(Data_Foldable.foldableArray)(Types_CostGroup.ordCostGroupCode)(Data_Array.mapMaybe(function (v29) {
                    return Control_Bind.bind(Data_Maybe.bindMaybe)(v29.componentId)(Data_Functor.flap(Data_Functor.functorFn)(Data_Map_Internal.lookup(Types_Component.ordComponentId))(componentIdCostGroupCodeAssocations));
                })(Data_Array.filter(matchResourceComponent)(v9.value0)));
            })();
            var matchFilterWord = function (v29) {
                return function (filterWord) {
                    return StringUtils.matchStringCaseIgnored(filterWord)([ Data_Newtype.un()(Types_CostGroup.CostGroupCode)(v29.costGroupUnit.code) ]) || (StringUtils.stringContainsCaseIgnored(filterWord)([ v29.costGroupUnit.name ]) || Data_Set.member(Types_CostGroup.ordCostGroupCode)(v29.costGroupUnit.code)(filterStringComponentMatches));
                };
            };
            var matchFilterString = function (row) {
                return Data_Array.any(matchFilterWord(row))(filterWords);
            };
            var filterByString = (function () {
                var $115 = Data_String_Common["null"](v10.value0);
                if ($115) {
                    return Control_Category.identity(Control_Category.categoryFn);
                };
                return Data_Array.filter(matchFilterString);
            })();
            var costGroupUnitAndCostClasses = filterEmptyCGUs(filterByString(Data_Functor.map(Data_Functor.functorArray)(Calculation_CostGroup.mkCostGroupWithCostClasses(disableSocialCostToActual)(costGroupItems)(v2.value0)(v7.value0)(withOrWithoutSocialExpenses)(Calculation_Utils.NoDateLimit.value))(v3.value0)));
            return costGroupUnitAndCostClasses;
        })();
        var mkCostGroupDetailProps = function (cguacc) {
            return {
                costGroupUnitAndCostClasses: cguacc,
                costGroupCategories: v4.value0,
                resources: v8.value0,
                reportingComponents: v9.value0,
                isCollapsed: false,
                costClasses: v7.value0,
                rawEstimateItems: v5.value0,
                rawTargetItems: v6.value0,
                withSocialExpenses: v25.value0,
                descriptionDisplay: v15.value0
            };
        };
        var tableControls = CostControlTableControls.costControlTableControls({
            handleShowAddCostGroup: v20.value1(function (v29) {
                return true;
            }),
            displayTarget: v21.value0,
            displayEstimate: v22.value0,
            displayTargetToEstimate: v23.value0,
            displayFullWidth: v16.value0,
            displayActual: v24.value0,
            displayMemo: v17.value0,
            displayActualToEstimateRatio: v18.value0,
            toggleTargetDisplay: v21.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean)),
            toggleEstimateDisplay: v22.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean)),
            toggleTargetToEstimateDisplay: v23.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean)),
            toggleToggleDisplayFullWidth: v16.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean)),
            toggleActualDisplay: v24.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean)),
            toggleMemoDisplay: v17.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean)),
            toggleActualToEstimateRatioDisplay: v18.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean)),
            withSocialExpenses: v25.value0,
            toggleSocialExpenses: v25.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean)),
            displayCompletionPercentage: v13.value0,
            toggleCompletionPercentage: v13.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean)),
            nestCostGroupItemsBy3CodeDigits: v11.value0,
            toggleNestCostGroupItemsBy3CodeDigits: v11.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean)),
            hideEmptyCostGroups: v12.value0,
            toggleHideEmptyCostGroups: v12.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean)),
            fileUpload: UploadCostGroups.uploadCostGroups(uploadButtonProps),
            displayEstimateRemaining: v14.value0,
            toggleEstimateRemaining: v14.value1(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean)),
            displayLineDescriptions: (function () {
                if (v15.value0 instanceof CostGroupDetails_Types.ShowLineDescriptions) {
                    return true;
                };
                if (v15.value0 instanceof CostGroupDetails_Types.ShowInvoiceDescriptions) {
                    return false;
                };
                throw new Error("Failed pattern match at CostControlContainer (line 297, column 34 - line 299, column 43): " + [ v15.value0.constructor.name ]);
            })(),
            toggleDescriptionDisplay: (function () {
                var valueAfterToggling = (function () {
                    if (v15.value0 instanceof CostGroupDetails_Types.ShowLineDescriptions) {
                        return CostGroupDetails_Types.ShowInvoiceDescriptions.value;
                    };
                    if (v15.value0 instanceof CostGroupDetails_Types.ShowInvoiceDescriptions) {
                        return CostGroupDetails_Types.ShowLineDescriptions.value;
                    };
                    throw new Error("Failed pattern match at CostControlContainer (line 302, column 32 - line 304, column 60): " + [ v15.value0.constructor.name ]);
                })();
                return v15.value1(function (v29) {
                    return valueAfterToggling;
                });
            })(),
            exportCostGroupsAction: (function () {
                if (maybeProject instanceof Data_Maybe.Just) {
                    var costGroupUnits = Data_Functor.map(Data_Functor.functorArray)(function (v29) {
                        return v29.costGroupUnit;
                    })(mkCostGroupUnitAndCostClasses);
                    return appDispatch(new Actions.GetCostGroupsExportRequest({
                        costGroupUnits: costGroupUnits,
                        projectCode: maybeProject.value0.code
                    }));
                };
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            })(),
            filterString: v10.value0,
            setFilterString: v10.value1,
            selectedCostGroupCodes: v28.value0,
            resetFilterAndSelection: function __do() {
                Data_Functor["void"](Effect.functorEffect)(removeSelectedCostGroups(v28.value0))();
                return Data_Functor["void"](Effect.functorEffect)(v10.value1(function (v29) {
                    return "";
                }))();
            }
        });
        var loading = (function () {
            if (v3.value1 || (v4.value1 || (v7.value1 || (v5.value1 || (v6.value1 || v2.value1))))) {
                return Data_Maybe.Just.create(TacoLoader.component()()({}));
            };
            if (Data_Boolean.otherwise) {
                return Data_Maybe.Nothing.value;
            };
            throw new Error("Failed pattern match at CostControlContainer (line 336, column 5 - line 344, column 28): " + [  ]);
        })();
        var findMatchingCC = function (il) {
            return Data_Array.find(function (cc) {
                return Data_Eq.eq(Types_Newtypes.eqCostClassCode)(cc.costClassCode)(il.costClassCode);
            })(v7.value0);
        };
        var getILValueWithSocialCosts = function (il) {
            var mMatchingCC = findMatchingCC(il);
            var mSEP = Data_Functor.map(Data_Maybe.functorMaybe)((function () {
                var $193 = Utils.fromNullable(0.0);
                return function ($194) {
                    return $193((function (v29) {
                        return v29.socialExpensePercentageInCostControlForEstimatesAndActuals;
                    })($194));
                };
            })())(mMatchingCC);
            if (mSEP instanceof Data_Maybe.Just && v25.value0) {
                return il.value * (1.0 + mSEP.value0);
            };
            if (mSEP instanceof Data_Maybe.Nothing && v25.value0) {
                return il.value;
            };
            if (!v25.value0) {
                return il.value;
            };
            throw new Error("Failed pattern match at CostControlContainer (line 238, column 7 - line 241, column 29): " + [ mSEP.constructor.name, v25.value0.constructor.name ]);
        };
        var existingCostGroupCodes = Data_Set.fromFoldable(Data_Foldable.foldableArray)(Types_CostGroup.ordCostGroupCode)(Data_Functor.map(Data_Functor.functorArray)((function () {
            var $195 = Data_Newtype.un()(Types_CostGroup.CostGroupUnit);
            return function ($196) {
                return (function (v29) {
                    return v29.code;
                })($195((function (v29) {
                    return v29.costGroupUnit;
                })($196)));
            };
        })())(mkCostGroupUnitAndCostClasses));
        var costGroupNotFound = function (il) {
            var v29 = Data_Nullable.toMaybe(il.costGroupCode);
            if (v29 instanceof Data_Maybe.Just) {
                return Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean)))(Data_Set.member(Types_CostGroup.ordCostGroupCode))(v29.value0)(existingCostGroupCodes);
            };
            if (v29 instanceof Data_Maybe.Nothing) {
                return false;
            };
            throw new Error("Failed pattern match at CostControlContainer (line 228, column 28 - line 230, column 23): " + [ v29.constructor.name ]);
        };
        var missingCostGroupCodeInvoiceLines = Data_Array.filter(costGroupNotFound)(v2.value0);
        var groupedMissingCostGroupCodeInvoiceLines = Data_Array.groupBy(function (x) {
            return function (y) {
                return Data_Eq.eq(Data_Nullable.eqNullable(Types_CostGroup.eqCostGroupCode))(x.costGroupCode)(y.costGroupCode);
            };
        })(missingCostGroupCodeInvoiceLines);
        var totalMissingCostGroupCodesInvoiceLinesAmount = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(getILValueWithSocialCosts)(missingCostGroupCodeInvoiceLines));
        var missingCostGroups = (function () {
            var $126 = Data_Array["null"](groupedMissingCostGroupCodeInvoiceLines);
            if ($126) {
                return React_Basic.empty;
            };
            var mkText = function (text) {
                return TacoText.component()()({
                    text: text,
                    weight: TacoText_Types.Bold.value
                });
            };
            var getCostGroupCode = function (il) {
                return Utils.foldNullable(Data_Maybe.Nothing.value)((function () {
                    var $197 = Data_Newtype.unwrap();
                    return function ($198) {
                        return Data_Maybe.Just.create($197($198));
                    };
                })())(il.costGroupCode);
            };
            var missingCostGroupCodes = Data_Array.mapMaybe(function ($199) {
                return getCostGroupCode(Data_Array_NonEmpty.head($199));
            })(groupedMissingCostGroupCodeInvoiceLines);
            return Box.box("")([ Box.FlexColumn.value ])([ mkText("Puuttuvat litterat: " + (Data_Array.intercalate(Data_Monoid.monoidString)(", ")(missingCostGroupCodes) + (", yhteens\xe4: " + Formatters.formatCurrencyValue("EUR")(totalMissingCostGroupCodesInvoiceLinesAmount)))) ]);
        })();
        var mkTable = function (costGroupUnitAndCostClasses) {
            return CostControlTable.mkCostControlTable({
                costGroupUnitAndCostClasses: costGroupUnitAndCostClasses,
                displayTarget: v21.value0,
                displayEstimate: v22.value0,
                displayTargetToEstimate: v23.value0,
                displayActual: v24.value0,
                mkCostGroupDetailProps: mkCostGroupDetailProps,
                displayCompletionPercentage: v13.value0,
                displayEstimateRemaining: v14.value0,
                displayMemo: v17.value0,
                displayActualToEstimateRatio: v18.value0,
                nestCostGroupItemsBy3CodeDigits: v11.value0,
                withSocialExpenses: v25.value0,
                descriptionDisplay: v15.value0,
                projectId: v.projectId,
                updateStatuses: updateStatuses,
                totalMissingCostGroupCodesInvoiceLinesAmount: totalMissingCostGroupCodesInvoiceLinesAmount,
                includeMissingCostGroupsInActualSummary: !Data_String_Common["null"](v10.value0),
                selectCostGroups: selectCostGroups,
                removeSelectedCostGroups: removeSelectedCostGroups,
                selectedCostGroups: v28.value0
            });
        };
        var table = mkTable(mkCostGroupUnitAndCostClasses);
        var content = React_Basic.fragment([ missingCostGroups, table ]);
        var container = function (children) {
            return NarrowLayout.component()()({
                fullWidth: v16.value0,
                verticalPadding: true,
                children: children
            });
        };
        var addCostGroupControl = (function () {
            if (v20.value0) {
                return AddCostGroup.addCostGroup({
                    handleHideAddCostGroup: v20.value1(function (v29) {
                        return false;
                    }),
                    projectId: v.projectId
                });
            };
            return React_Basic.empty;
        })();
        return container([ tableControls, Data_Maybe.fromMaybe(TacoLoader.component()()({}))(Control_Alt.alt(Data_Maybe.altMaybe)(Control_Alt.alt(Data_Maybe.altMaybe)(loading)(noContent))(new Data_Maybe.Just(content))), addCostGroupControl ]);
    };
});
module.exports = {
    component: component
};
