import { AxiosError } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axiosClient from "src/client-ts/axiosConfig";
import { reportError } from "src/client-ts/utils/error";

const queryUrl = "/organization/management/integration-client";

export function useIntegrationUserMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      const { data } = await axiosClient.post(`${queryUrl}`);
      return data;
    },
    onError: (error: AxiosError) => {
      reportError("Error while creating integration", error);
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryUrl);
    },
  });
}

export function useDeleteIntegrationUserMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      const { data } = await axiosClient.delete(`${queryUrl}`);
      return data;
    },
    onError: (error: AxiosError) => {
      const errorData = error.response?.data;
      if (
        typeof errorData === "object" &&
        errorData &&
        "message" in errorData &&
        typeof errorData.message === "string"
      ) {
        reportError(errorData.message, error);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryUrl);
    },
  });
}

export function useIntegrationUserQuery() {
  return useQuery({
    queryKey: queryUrl,
    queryFn: async () => {
      const { data } = await axiosClient.get(`${queryUrl}`);
      return data;
    },
    onError: (error: AxiosError) => {
      reportError("Error while fetching integration data", error);
    },
  });
}
