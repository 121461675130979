import { useQuery, UseQueryResult } from "react-query";
import axiosClient from "src/client-ts/axiosConfig";
import { ProjectId } from "ts-bindings/ProjectId";

export const useProjectExportQuery = (
  projectId?: ProjectId
): UseQueryResult<Blob> => {
  const endpoint = `/project/${projectId}/exportProjectToFile`;

  return useQuery(
    ["getProjectExport"],
    async () => {
      const { data } = await axiosClient.get<Blob>(endpoint, {
        responseType: "blob",
      });

      return data;
    },
    {
      refetchOnMount: false,
      enabled: false,
      cacheTime: 0,
    }
  );
};
