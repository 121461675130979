// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var CostEstimation_BuildingElementsView_ComponentsTable = require("../CostEstimation.BuildingElementsView.ComponentsTable/index.js");
var CostEstimation_BuildingElementsView_ResourcesTable = require("../CostEstimation.BuildingElementsView.ResourcesTable/index.js");
var CostEstimation_Layout = require("../CostEstimation.Layout/index.js");
var CostEstimationLayout_Types = require("../CostEstimationLayout.Types/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var ElementDetails_Styles = require("../ElementDetails.Styles/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var StateHooks = require("../StateHooks/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Component = require("../Types.Component/index.js");
var elementDetails = TofuHooks.mkHookComponent("ElementDetails")(function (props) {
    return function __do() {
        var appDispatch = StateHooks.useDispatch();
        var panelProps = CostEstimation_Layout.useCostEstimationLayout({
            leftPanelInitMode: CostEstimationLayout_Types.MinimizedMode.value,
            rightPanelInitMode: CostEstimationLayout_Types.MinimizedMode.value,
            detailsPanelInitMode: CostEstimationLayout_Types.NormalMode.value,
            localStorageKey: "ElementDetails"
        })();
        var componentInfoTable = CostEstimation_BuildingElementsView_ComponentsTable.componentInfoTable({
            elementData: props.elementData,
            selectedIds: props.props.selectedComponentIds,
            setSelectedIds: props.props.setSelectedComponentIds,
            projectId: props.projectId,
            projectCurrency: props.projectCurrency,
            appDispatch: appDispatch,
            checkedSpecs: props.checkedSpecs,
            setCheckedSpecs: props.setCheckedSpecs,
            socialExpensePercentagesByCostClass: props.socialExpensePercentagesByCostClass,
            showSocialExpensesInCosts: props.showSocialExpensesInCosts,
            viewMode: props.props.viewMode,
            programType: props.props.programType,
            isReportingProject: props.props.isReportingProject,
            isPanel: props.props.isPanel,
            extraControls: props.extraComponentControls,
            existingComponentCodesForEdit: props.props.existingComponentCodesForEdit,
            dixonOptions: props.props.dixonOptions
        });
        var componentById = function (v) {
            return function (v1) {
                return Data_Eq.eq(Types_Component.eqComponentId)(v1.resourcesByComponent.component.id)(v);
            };
        };
        var buildingElementComponents = [ componentInfoTable, Data_Maybe.fromMaybe(React_Basic.empty)(Control_Bind.bind(Data_Maybe.bindMaybe)(props.props.selectedComponentIds)(function (v) {
            return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.find(componentById(v.value1))(props.elementData.components))(function (v1) {
                return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(CostEstimation_BuildingElementsView_ResourcesTable.component({
                    componentSummary: v1,
                    socialExpensePercentagesByCostClass: props.socialExpensePercentagesByCostClass,
                    showSocialExpensesInCosts: props.showSocialExpensesInCosts,
                    showWorkAchievement: props.showWorkAchievement,
                    projectId: props.projectId,
                    viewMode: props.props.viewMode,
                    programType: props.props.programType,
                    isReportingProject: props.props.isReportingProject,
                    panelMode: panelProps.detailsPanelMode,
                    setPanelMode: panelProps.setDetailsPanelMode,
                    setParentSelectedResourceIds: props.setParentSelectedResourceIds,
                    extraResourceControls: props.extraResourceControls,
                    componentSplitParams: props.componentSplitParams,
                    projectCurrency: props.projectCurrency
                }));
            });
        })) ];
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "__ElementDetails",
            css: ElementDetails_Styles.elementDetailsContainerStyles,
            children: buildingElementComponents
        });
    };
});
module.exports = {
    elementDetails: elementDetails
};
