import { useMutation } from "react-query";
import axiosClient from "../../../../axiosConfig";

type MassUpdateComponentsParams = {
  components: number[];
  sourceProjectId: number;
  sourceSubprojectId: number;
};

export function useMassPriceUpdate(onSave: () => void, projectId: number) {
  return useMutation(
    async (params: MassUpdateComponentsParams) => {
      const res = await axiosClient.post(
        `/project/${projectId}/componentPriceMassUpdate`,
        params
      );
      return res.data;
    },
    {
      onSuccess: () => {
        onSave();
      },
    }
  );
}
