// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var CostEstimation_BuildingElementsView_Types = require("../CostEstimation.BuildingElementsView.Types/index.js");
var CostEstimation_Styles = require("../CostEstimation.Styles/index.js");
var CostEstimationTableWrapper = require("../CostEstimationTableWrapper/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Unfoldable = require("../Data.Unfoldable/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Edited = require("../Edited/index.js");
var Effect = require("../Effect/index.js");
var Formatters = require("../Formatters/index.js");
var LocationEditor = require("../LocationEditor/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoAutocomplete = require("../TacoAutocomplete/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_BuildingElementsView = require("../Types.BuildingElementsView/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_CostEstimation = require("../Types.CostEstimation/index.js");
var Types_Element = require("../Types.Element/index.js");
var Types_Estimation = require("../Types.Estimation/index.js");
var Types_FrontEndRoutes = require("../Types.FrontEndRoutes/index.js");
var Types_JSDateString = require("../Types.JSDateString/index.js");
var Types_Location = require("../Types.Location/index.js");
var Utils = require("../Utils/index.js");
var ByLocationCode = (function () {
    function ByLocationCode() {

    };
    ByLocationCode.value = new ByLocationCode();
    return ByLocationCode;
})();
var ByDescription = (function () {
    function ByDescription() {

    };
    ByDescription.value = new ByDescription();
    return ByDescription;
})();
var ByAmount = (function () {
    function ByAmount() {

    };
    ByAmount.value = new ByAmount();
    return ByAmount;
})();
var ByDateAndUser = (function () {
    function ByDateAndUser() {

    };
    ByDateAndUser.value = new ByDateAndUser();
    return ByDateAndUser;
})();
var tableStyleSet = TacoTable_Styles.foldingTableStyleSet;
var prepareLocationsMatrixParams = function (elementId) {
    return function (m) {
        var xs = Data_Map_Internal.toUnfoldable(Data_Unfoldable.unfoldableArray)(m);
        var newAmounts = Data_Function.flip(Data_Array.mapMaybe)(xs)(function (v) {
            if (v.value1.cell instanceof LocationEditor.NewLocation && v.value1.cell.value0.amount instanceof Data_Maybe.Just) {
                return new Data_Maybe.Just({
                    locationCode: v.value1.cell.value0.code,
                    amount: v.value1.cell.value0.amount.value0,
                    description: LocationEditor.getDescriptionString(v.value1.cell.value0.description)
                });
            };
            if (v.value1.cell instanceof LocationEditor.NewLocation && v.value1.cell.value0.description.edited instanceof Data_Maybe.Just) {
                return new Data_Maybe.Just({
                    locationCode: v.value1.cell.value0.code,
                    amount: 0.0,
                    description: v.value1.cell.value0.description.edited.value0
                });
            };
            return Data_Maybe.Nothing.value;
        });
        var modifiedAmounts = Data_Function.flip(Data_Array.mapMaybe)(xs)(function (v) {
            if (v.value1.cell instanceof LocationEditor.ExistingLocation && v.value1.cell.value1.amount instanceof Edited.Edited) {
                return new Data_Maybe.Just({
                    elementLocationId: v.value1.cell.value0.id,
                    amount: v.value1.cell.value1.amount.value0,
                    description: LocationEditor.getDescriptionString(v.value1.cell.value1.description)
                });
            };
            if (v.value1.cell instanceof LocationEditor.ExistingLocation && v.value1.cell.value1.description.edited instanceof Data_Maybe.Just) {
                return new Data_Maybe.Just({
                    elementLocationId: v.value1.cell.value0.id,
                    amount: v.value1.cell.value0.amount,
                    description: v.value1.cell.value1.description.edited.value0
                });
            };
            return Data_Maybe.Nothing.value;
        });
        return {
            newAmounts: newAmounts,
            modifiedAmounts: modifiedAmounts,
            elementId: elementId
        };
    };
};
var prepareLocText = function (loc) {
    var time = Types_JSDateString.extractMaybeJSDateString(Data_Nullable.toMaybe(loc.time));
    return time + (" " + loc.user);
};
var mkSaveChangesAction = function (projectId) {
    return function (elementId) {
        return function (setRequestInProgress) {
            return function (appDispatch) {
                return function (cells) {
                    var params = prepareLocationsMatrixParams(elementId)(cells);
                    var action = new Types_Estimation.ElementLocationsMatrixAction(params);
                    return function __do() {
                        setRequestInProgress(function (v) {
                            return true;
                        })();
                        return appDispatch(new Actions.EstimationElementActionRequest({
                            projectId: projectId,
                            params: action,
                            handler: function (v) {
                                return setRequestInProgress(function (v1) {
                                    return false;
                                });
                            }
                        }))();
                    };
                };
            };
        };
    };
};
var mkAction = function (projectId) {
    return function (mkEditAction) {
        return function (value) {
            return function (handler) {
                return new Actions.EstimationElementActionRequest({
                    projectId: projectId,
                    params: mkEditAction(value),
                    handler: handler
                });
            };
        };
    };
};
var getSortingValue = function (sortKey) {
    return function (r) {
        if (sortKey instanceof ByLocationCode) {
            return new TacoTable_Types.StringValue(r.location.locationCode);
        };
        if (sortKey instanceof ByDescription) {
            return new TacoTable_Types.StringValue(r.location.description);
        };
        if (sortKey instanceof ByAmount) {
            return new TacoTable_Types.NumberValue(r.location.amount);
        };
        if (sortKey instanceof ByDateAndUser) {
            return TacoTable_Types.StringValue.create(prepareLocText(r.location));
        };
        throw new Error("Failed pattern match at CostEstimation.BuildingElementsView.LocationsTable (line 336, column 29 - line 340, column 52): " + [ sortKey.constructor.name ]);
    };
};
var getSortProperty = {
    getName: function (v) {
        return "SortProperty";
    }
};
var getElementId = function (props) {
    return (function (v) {
        return v.elementId;
    })(Data_Newtype.un()(Types_Element.WasmEstimationElement)((function (v) {
        return v.element;
    })(Data_Newtype.un()(Types_BuildingElementsView.WasmElementsData)(props.elementData))));
};
var locationColumns = function (props) {
    var testIdPrefix = function (v) {
        return function (id) {
            return Data_Show.show(Types_CostEstimation.showECViewMode)(props.viewMode) + ("-location-" + (Data_Show.show(Types_Element.showElementLocationId)(v.elementLocationId) + ("-" + id)));
        };
    };
    var unitCell = new TacoTable_Types.JSXCell([  ], function (r) {
        return TacoText.component()()({
            text: (function (v) {
                return v.unit;
            })(Data_Newtype.un()(Types_Element.WasmEstimationElement)((function (v) {
                return v.element;
            })(r))),
            testId: testIdPrefix(r.location)("unit")
        });
    });
    var renderAmountSourceCode = (function () {
        var render = function (v) {
            if (v instanceof Data_Maybe.Just) {
                return Types_Component.amountSourceIntToDescription(v.value0);
            };
            if (v instanceof Data_Maybe.Nothing) {
                return "-";
            };
            throw new Error("Failed pattern match at CostEstimation.BuildingElementsView.LocationsTable (line 204, column 9 - line 204, column 63): " + [ v.constructor.name ]);
        };
        return function ($111) {
            return render(Data_Int.fromNumber($111));
        };
    })();
    var mkEstimationLocationAction = function (action) {
        return function (handler) {
            return new Actions.EstimationElementActionRequest({
                projectId: props.projectId,
                params: action,
                handler: handler
            });
        };
    };
    var locationSelectChoices = Data_Functor.mapFlipped(Data_Functor.functorArray)(props.uniqueLocationCodes)(function (code) {
        return {
            text: code,
            id: code
        };
    });
    var groupCell = new TacoTable_Types.JSXCell([  ], function (r) {
        return TacoText.component()()({
            text: (function (v) {
                return v.group;
            })(Data_Newtype.un()(Types_Element.WasmElementLocation)((function (v) {
                return v.location;
            })(r))),
            testId: testIdPrefix(r.location)("group")
        });
    });
    var getLocationCode = function (r) {
        return (function (v) {
            return v.locationCode;
        })(Data_Newtype.un()(Types_Element.WasmElementLocation)(r.location));
    };
    var getElementLocationId = function (r) {
        return (function (v) {
            return v.elementLocationId;
        })(Data_Newtype.un()(Types_Element.WasmElementLocation)(r.location));
    };
    var dispatchEstimationElementLocationAction = function (action) {
        return props.appDispatch(mkEstimationLocationAction(action)(Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)))));
    };
    var dateAndUserCell = new TacoTable_Types.JSXCell([  ], function (r) {
        return TacoText.component()()({
            text: prepareLocText(Data_Newtype.un()(Types_Element.WasmElementLocation)((function (v) {
                return v.location;
            })(r))),
            testId: testIdPrefix(r.location)("date-and-user")
        });
    });
    var checkDixonLocation = function (r) {
        return (Data_Newtype.un()(Types_Element.WasmElementLocation)(r.location)).amountSourceCode === 1.0;
    };
    var checkbox = function (r) {
        var locationId = (function (v) {
            return v.elementLocationId;
        })(Data_Newtype.un()(Types_Element.WasmElementLocation)(r.location));
        return TacoCheckbox.component()()({
            className: "location-checkbox",
            isChecked: r.isChecked,
            labelText: Data_Maybe.Nothing.value,
            onToggle: (function () {
                var $80 = checkDixonLocation(r);
                if ($80) {
                    return new TacoCheckbox.Disabled("Voit muokata digitoituja m\xe4\xe4ri\xe4 vain digitoinnissa.");
                };
                return new TacoCheckbox.NoEvent(props.toggleSelection(locationId));
            })(),
            testId: testIdPrefix(r.location)("checkbox")
        });
    };
    var descriptionInput = function (r) {
        var value = (function (v) {
            return v.description;
        })(Data_Newtype.un()(Types_Element.WasmElementLocation)(r.location));
        var editDescriptionAction = mkAction(props.projectId)(function (newDescription) {
            return new Types_Estimation.EditElementLocationDescription({
                elementId: getElementId(props),
                elementLocationId: getElementLocationId(r),
                description: newDescription
            });
        });
        return TacoInput.remoteStringField()()({
            value: value,
            mkAction: editDescriptionAction,
            precision: 2,
            disabled: checkDixonLocation(r),
            title: (function () {
                var $81 = checkDixonLocation(r);
                if ($81) {
                    return "Voit muokata digitoituja m\xe4\xe4ri\xe4 vain digitoinnissa.";
                };
                return value;
            })(),
            testId: testIdPrefix(r.location)("description-input")
        });
    };
    var description = new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], descriptionInput);
    var locationCodeSelect = function (r) {
        var $82 = checkDixonLocation(r);
        if ($82) {
            return TacoInput.stringInput()()(Data_Eq.eqString)({
                disabled: true,
                title: "Voit muokata digitoituja m\xe4\xe4ri\xe4 vain digitoinnissa.",
                value: getLocationCode(r),
                testId: testIdPrefix(r.location)("code-select")
            });
        };
        return TacoAutocomplete.component()()({
            sections: [ {
                text: Data_Maybe.Nothing.value,
                list: locationSelectChoices
            } ],
            clearButton: false,
            placeholder: "",
            onSelect: function (item) {
                if (item instanceof Data_Maybe.Just) {
                    return dispatchEstimationElementLocationAction(new Types_Estimation.EditElementLocationCode({
                        locationCode: item.value0.id,
                        elementLocationId: getElementLocationId(r),
                        elementId: getElementId(props)
                    }));
                };
                if (item instanceof Data_Maybe.Nothing) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                };
                throw new Error("Failed pattern match at CostEstimation.BuildingElementsView.LocationsTable (line 295, column 13 - line 302, column 35): " + [ item.constructor.name ]);
            },
            selected: Data_Maybe.Just.create({
                id: getLocationCode(r),
                text: getLocationCode(r)
            }),
            testId: testIdPrefix(r.location)("location-code-select"),
            expanded: new Data_Maybe.Just(true)
        });
    };
    var locationCode = new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], locationCodeSelect);
    var amountSource = function (r) {
        var loc$prime = Data_Newtype.un()(Types_Element.WasmElementLocation)(r.location);
        var $86 = loc$prime.amountSourceCode === 1.0;
        if ($86) {
            var v = TofuJSON.readJSON_(TofuJSON.readRecord()(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "drawing_id";
                }
            })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "file_id";
                }
            })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "measurement_group_id";
                }
            })(TofuJSON.readInt)(TofuJSON.readFieldsCons({
                reflectSymbol: function () {
                    return "text";
                }
            })(TofuJSON.readString)(TofuJSON.readFieldsNil)()())()())()())()())(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "drawing_id";
                }
            })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "file_id";
                }
            })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "measurement_group_id";
                }
            })(TofuJSON.readInt)(TofuJSON.consWriteForeignFields({
                reflectSymbol: function () {
                    return "text";
                }
            })(TofuJSON.readString)(TofuJSON.nilWriteForeignFields)()()())()()())()()())()()()))(loc$prime.sourceApplication);
            if (v instanceof Data_Maybe.Just) {
                return TacoButton.component()()({
                    buttonStyle: TacoButton_Types.Link.value,
                    text: v.value0.text,
                    onClick: Data_Maybe.Just.create(props.appDispatch(Actions.NavigateRoute.create(new Types_FrontEndRoutes.DixonFERoute({
                        projectId: props.projectId,
                        mFileId: new Data_Maybe.Just(v.value0.file_id),
                        mDrawingId: new Data_Maybe.Just(v.value0.drawing_id),
                        mMeasurementGroupId: new Data_Maybe.Just(v.value0.measurement_group_id)
                    })))),
                    testId: testIdPrefix(r.location)("dixon-link")
                });
            };
            if (v instanceof Data_Maybe.Nothing) {
                return TacoText.component()()({
                    text: renderAmountSourceCode(loc$prime.amountSourceCode),
                    testId: testIdPrefix(r.location)("amount-source")
                });
            };
            throw new Error("Failed pattern match at CostEstimation.BuildingElementsView.LocationsTable (line 249, column 11 - line 262, column 146): " + [ v.constructor.name ]);
        };
        return TacoText.component()()({
            text: renderAmountSourceCode(loc$prime.amountSourceCode),
            testId: testIdPrefix(r.location)("amount-source")
        });
    };
    var amountInput = function (r) {
        var value = (function (v) {
            return v.amount;
        })(Data_Newtype.un()(Types_Element.WasmElementLocation)(r.location));
        var editAmountAction = mkAction(props.projectId)(function (newAmount) {
            return new Types_Estimation.EditElementLocationAmount({
                elementId: getElementId(props),
                elementLocationId: getElementLocationId(r),
                amount: newAmount
            });
        });
        return TacoInput.remoteNumberField()()({
            value: value,
            mkAction: editAmountAction,
            precision: 2,
            testId: testIdPrefix(r.location)("amount-input")
        });
    };
    var amount = new TacoTable_Types.JSXCell([  ], function (r) {
        var $89 = checkDixonLocation(r);
        if ($89) {
            return TacoText.component()()({
                text: Formatters.formatDecimalValue(2)((Data_Newtype.un()(Types_Element.WasmElementLocation)(r.location)).amount),
                testId: testIdPrefix(r.location)("amount")
            });
        };
        return amountInput(r);
    });
    return Data_Semigroup.append(Data_Semigroup.semigroupArray)((function () {
        var $90 = Data_Eq.eq(Types_CostEstimation.eqECViewMode)(props.viewMode)(Types_CostEstimation.EditMode.value);
        if ($90) {
            return [ {
                key: "checkbox",
                label: React_Basic_DOM.text(""),
                cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], checkbox),
                sortProperty: Data_Maybe.Nothing.value,
                width: new TacoTable_Types.Fixed("2rem"),
                headerJSX: Data_Maybe.Nothing.value
            } ];
        };
        return [  ];
    })())([ {
        sortProperty: new Data_Maybe.Just(ByLocationCode.value),
        key: "locationCode",
        label: React_Basic_DOM.text("Sijainti"),
        width: new TacoTable_Types.Flex(10),
        cell: locationCode,
        headerJSX: Data_Maybe.Nothing.value
    }, {
        sortProperty: new Data_Maybe.Just(ByDescription.value),
        key: "description",
        label: React_Basic_DOM.text("Selite"),
        width: new TacoTable_Types.Flex(20),
        cell: description,
        headerJSX: Data_Maybe.Nothing.value
    }, {
        sortProperty: new Data_Maybe.Just(ByAmount.value),
        key: "amount",
        label: React_Basic_DOM.text("M\xe4\xe4r\xe4"),
        width: new TacoTable_Types.Flex(10),
        cell: amount,
        headerJSX: Data_Maybe.Nothing.value
    }, {
        sortProperty: Data_Maybe.Nothing.value,
        key: "unit",
        label: React_Basic_DOM.text("Yksikk\xf6"),
        width: new TacoTable_Types.Flex(10),
        cell: unitCell,
        headerJSX: Data_Maybe.Nothing.value
    }, {
        sortProperty: Data_Maybe.Nothing.value,
        key: "amountSource",
        label: React_Basic_DOM.text("M\xe4\xe4r\xe4l\xe4hde"),
        width: new TacoTable_Types.Flex(40),
        cell: new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], amountSource),
        headerJSX: Data_Maybe.Nothing.value
    }, {
        sortProperty: Data_Maybe.Nothing.value,
        key: "group",
        label: React_Basic_DOM.text("Ryhm\xe4"),
        width: new TacoTable_Types.Flex(10),
        cell: groupCell,
        headerJSX: Data_Maybe.Nothing.value
    }, {
        sortProperty: new Data_Maybe.Just(ByDateAndUser.value),
        key: "date-and-user",
        label: React_Basic_DOM.text("Luontiaika ja k\xe4ytt\xe4j\xe4"),
        width: new TacoTable_Types.Flex(15),
        cell: dateAndUserCell,
        headerJSX: Data_Maybe.Nothing.value
    } ]);
};
var getRow = function (props) {
    return function (r) {
        return {
            className: "element-resource-row",
            rowData: r,
            foldingElement: Data_Maybe.Nothing.value,
            onClick: function (v) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            },
            rowKey: Data_Show.show(Types_Element.showElementLocationId)(r.location.elementLocationId),
            columns: locationColumns(props)
        };
    };
};
var eqSortProperty = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ByLocationCode && y instanceof ByLocationCode) {
                return true;
            };
            if (x instanceof ByDescription && y instanceof ByDescription) {
                return true;
            };
            if (x instanceof ByAmount && y instanceof ByAmount) {
                return true;
            };
            if (x instanceof ByDateAndUser && y instanceof ByDateAndUser) {
                return true;
            };
            return false;
        };
    }
};
var ordSortProperty = {
    compare: function (x) {
        return function (y) {
            if (x instanceof ByLocationCode && y instanceof ByLocationCode) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByLocationCode) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByLocationCode) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByDescription && y instanceof ByDescription) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByDescription) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByDescription) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByAmount && y instanceof ByAmount) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByAmount) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByAmount) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByDateAndUser && y instanceof ByDateAndUser) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at CostEstimation.BuildingElementsView.LocationsTable (line 327, column 1 - line 327, column 52): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqSortProperty;
    }
};
var elementLocationsTableInstance = TacoTable.mkTableSortable(getSortProperty)("ElementLocationsTable");
var deleteLocationsModal = function (props) {
    if (props.showConfirmLocationsModal instanceof Data_Maybe.Nothing) {
        return React_Basic.empty;
    };
    if (props.showConfirmLocationsModal instanceof Data_Maybe.Just) {
        var modalBody = TacoModal.modalBody({
            contents: [ React_Basic_DOM.text("Oletko varma?") ]
        });
        var modalActions = TacoModal.modalActions({
            contents: [ TacoButton.component()()({
                onClick: new Data_Maybe.Just(props.closeDeleteLocationsModal),
                text: "Peruuta",
                testId: "cancel-delete-locations-button"
            }), TacoButton.component()()({
                text: "Poista",
                color: TacoConstants.SecondaryRed.value,
                onClick: new Data_Maybe.Just(function __do() {
                    props.appDispatch(mkAction(props.projectId)(Types_Estimation.DeleteElementLocations.create)({
                        elementLocationIds: Data_Array.fromFoldable(Data_Set.foldableSet)(props.selectedLocations),
                        elementId: getElementId(props)
                    })(Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)))))();
                    return props.closeDeleteLocationsModal();
                }),
                testId: "submit-delete-locations-button"
            }) ]
        });
        var deleteLocationsText = (function () {
            var $97 = Data_Set.size(props.showConfirmLocationsModal.value0) > 1;
            if ($97) {
                return "Poista sijainnit";
            };
            return "Poista Sijainti";
        })();
        return TacoModal.component()()({
            isActive: true,
            onClose: props.closeDeleteLocationsModal,
            heading: deleteLocationsText,
            contents: [ modalBody, modalActions ]
        });
    };
    throw new Error("Failed pattern match at CostEstimation.BuildingElementsView.LocationsTable (line 372, column 30 - line 409, column 8): " + [ props.showConfirmLocationsModal.constructor.name ]);
};
var deleteLocationsButton = function (props) {
    return function (v) {
        return Data_Monoid.guard(Data_Monoid.monoidFn(React_Basic.monoidJSX))(!Data_Set.isEmpty(props.selectedLocations))(TacoButton.component()())({
            text: "Poista valitut sijaintirivit",
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconDelete24Px.value),
            color: TacoConstants.elementColors.border,
            buttonStyle: TacoButton_Types.Filled.value,
            size: TacoConstants.L.value,
            onClick: new Data_Maybe.Just(props.openDeleteLocationsModal(props.selectedLocations)),
            testId: "delete-selected-locations"
        });
    };
};
var addLocationButton = function (props) {
    return function (element) {
        return TacoButton.component()()({
            text: "Lis\xe4\xe4 sijaintirivi",
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconAddPlusV1.value),
            color: TacoConstants.elementColors.border,
            buttonStyle: TacoButton_Types.Filled.value,
            size: TacoConstants.L.value,
            onClick: new Data_Maybe.Just(props.appDispatch(mkAction(props.projectId)(Types_Estimation.AddElementLocation.create)({
                elementId: (function (v) {
                    return v.elementId;
                })(Data_Newtype.un()(Types_Element.WasmEstimationElement)((function (v) {
                    return v.element;
                })(Data_Newtype.un()(Types_BuildingElementsView.WasmElementsData)(element))))
            })(Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)))))),
            testId: "add-element-location-button"
        });
    };
};
var locationsTable = function (props) {
    var locationMatrixButton = TacoButton.component()()({
        text: "Lis\xe4\xe4 monta sijaintirivi\xe4",
        icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconEditV1.value),
        onClick: Data_Maybe.Just.create(props.setShowLocationsMatrixModal(function (v) {
            return true;
        })),
        color: TacoConstants.elementColors.border,
        buttonStyle: TacoButton_Types.Filled.value,
        size: TacoConstants.L.value,
        testId: "locations-matrix"
    });
    var tableContents = (function () {
        var mkRowData = function (element) {
            return function (v) {
                return {
                    location: v,
                    element: element,
                    isChecked: Data_Set.member(Types_Element.ordElementLocationId)(v.elementLocationId)(props.selectedLocations)
                };
            };
        };
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            children: [ TacoTable.tableSortable(getSortProperty)(eqSortProperty)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "location";
                }
            })(Types_Element.eqWasmElementLocation))()({
                reflectSymbol: function () {
                    return "isChecked";
                }
            })(Data_Eq.eqBoolean))()({
                reflectSymbol: function () {
                    return "element";
                }
            })(Types_Element.eqWasmElement)))(elementLocationsTableInstance)(new TacoTable_Types.SortingCriteria(TacoTable_Types.Ascending.value, new Data_Maybe.Just(ByLocationCode.value), getSortingValue))(Data_Maybe.Nothing.value)({
                rows: Data_Functor.map(Data_Functor.functorArray)((function () {
                    var $112 = getRow(props);
                    var $113 = mkRowData(props.elementData.element);
                    return function ($114) {
                        return $112($113($114));
                    };
                })())(props.elementData.locations),
                columns: locationColumns(props),
                showHeader: true,
                styleSet: tableStyleSet
            }) ],
            className: "__LocationsTable",
            css: (function (v) {
                return v.tableStyles;
            })(CostEstimation_Styles.costEstimationTableStyleSet(TacoConstants.elementColors))
        });
    })();
    return Box.box("LocationsList")([ Box.FlexColumn.value, Box.Height.create(new TacoConstants.CustomSize("100%")) ])([ CostEstimationTableWrapper.component({
        colorSet: TacoConstants.elementColors,
        children: [ tableContents ],
        floatingActionButtons: React_Basic.fragment([ addLocationButton(props)(props.elementData), deleteLocationsButton(props)(props.elementData), locationMatrixButton ])
    }), deleteLocationsModal(props), (function () {
        if (props.showLocationsMatrixModal) {
            var elementId = CostEstimation_BuildingElementsView_Types["_elementId"](props.elementData);
            return LocationEditor.locationsMatrix(Types_Element.showElementLocationId)(Types_Element.eqElementLocationId)({
                locations: Data_Array.catMaybes(Data_Functor.map(Data_Functor.functorArray)(LocationEditor.fromWasmElementLocation)(props.elementData.locations)),
                projectLocations: props.projectLocations,
                projectId: props.projectId,
                setShowLocationsMatrixModal: props.setShowLocationsMatrixModal,
                saveLocationMatrixChanges: mkSaveChangesAction(props.projectId)(elementId),
                unit: props.elementData.element.unit
            });
        };
        return React_Basic.empty;
    })() ]);
};
var mkElementLocationDetails = TofuHooks.mkHookComponent("ElementLocations")(function (props) {
    return function __do() {
        var appDispatch = StateHooks.useDispatch();
        var v = TofuHooks.useState({
            selectedLocations: Data_Set.empty,
            showConfirmLocationsModal: Data_Maybe.Nothing.value
        })();
        var uniqueLocationCodes = Data_Functor.map(Data_Functor.functorArray)(function (loc) {
            return (function (v1) {
                return v1.code;
            })(Data_Newtype.un()(Types_Location.ProjectLocation)(loc));
        })(props.projectLocations);
        var elementLocations = (function (v1) {
            return v1.locations;
        })(Data_Newtype.un()(Types_BuildingElementsView.WasmElementsData)(props.elementData));
        var locationIdKeys = Data_String_Common.joinWith(",")(Data_Functor.map(Data_Functor.functorArray)(function (v1) {
            return Data_Show.show(Data_Show.showInt)(Data_Newtype.un()(Types_Element.ElementLocationId)(v1.elementLocationId));
        })(elementLocations));
        TofuHooks.useEffect([ locationIdKeys ])(function __do() {
            v.value1(function (s) {
                return {
                    selectedLocations: Data_Set.empty,
                    showConfirmLocationsModal: Data_Maybe.Nothing.value
                };
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        })();
        var toggleElementChecked = TofuHooks.useMemo([  ])(function (v1) {
            return function (elemLocId) {
                return v.value1(function (s) {
                    return {
                        selectedLocations: Utils.toggleInSet(Types_Element.ordElementLocationId)(elemLocId)(s.selectedLocations),
                        showConfirmLocationsModal: s.showConfirmLocationsModal
                    };
                });
            };
        })();
        var openDeleteLocationsModal = TofuHooks.useMemo([  ])(function (v1) {
            return function (locations) {
                return v.value1(function (s) {
                    return {
                        selectedLocations: s.selectedLocations,
                        showConfirmLocationsModal: new Data_Maybe.Just(locations)
                    };
                });
            };
        })();
        var closeDeleteLocationsModal = TofuHooks.useMemo([  ])(function (v1) {
            return v.value1(function (s) {
                return {
                    selectedLocations: s.selectedLocations,
                    showConfirmLocationsModal: Data_Maybe.Nothing.value
                };
            });
        })();
        var v1 = TofuHooks.useState(false)();
        return locationsTable({
            appDispatch: appDispatch,
            selectedLocations: v.value0.selectedLocations,
            toggleSelection: toggleElementChecked,
            closeDeleteLocationsModal: closeDeleteLocationsModal,
            showConfirmLocationsModal: v.value0.showConfirmLocationsModal,
            openDeleteLocationsModal: openDeleteLocationsModal,
            showLocationsMatrixModal: v1.value0,
            setShowLocationsMatrixModal: v1.value1,
            uniqueLocationCodes: uniqueLocationCodes,
            projectId: props.projectId,
            viewMode: props.viewMode,
            elementData: props.elementData,
            projectLocations: props.projectLocations
        });
    };
});
module.exports = {
    mkElementLocationDetails: mkElementLocationDetails
};
