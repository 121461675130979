// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Effect = require("../Effect/index.js");
var FeatureHooks = require("../FeatureHooks/index.js");
var Header_Styles = require("../Header.Styles/index.js");
var HeaderTitle = require("../HeaderTitle/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var UserDropdown = require("../UserDropdown/index.js");
var header = TofuHooks.mkHookComponent("Header")(function (v) {
    return function __do() {
        var newNavbarEnabled = FeatureHooks.useFeatureEnabled("new-navigation-bar")();
        var v1 = TofuHooks.useState(false)();
        var v2 = TofuHooks.useState(false)();
        TofuHooks.useEffect([ v.applicationMode ])(function __do() {
            v2.value1(function (v3) {
                return false;
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        })();
        var userDropdown = UserDropdown.component({
            setShowUserActions: v1.value1,
            showUserActions: v1.value0,
            currentRoute: v.currentRoute,
            applicationMode: v.applicationMode
        });
        var newNavbar = (function () {
            if (v.subNavigation instanceof Data_Maybe.Nothing) {
                return React_Basic.empty;
            };
            if (v.subNavigation instanceof Data_Maybe.Just) {
                return React_Basic_Emotion.element(React_Basic_DOM_Generated["header'"]())({
                    className: "headerElement",
                    css: Header_Styles.headerElementStyles(false),
                    children: [ v.subNavigation.value0 ]
                });
            };
            throw new Error("Failed pattern match at Header (line 56, column 17 - line 63, column 24): " + [ v.subNavigation.constructor.name ]);
        })();
        var headerTitle = HeaderTitle.component({
            projectId: v.projectId,
            showModeSelection: v2.value0,
            setShowModeSelection: v2.value1,
            applicationMode: v.applicationMode
        });
        var oldNavbar = React_Basic_Emotion.element(React_Basic_DOM_Generated["header'"]())({
            className: "headerElement",
            css: Header_Styles.headerElementStyles(true),
            children: [ headerTitle, ReactBasicUtils.maybeJSX(React_Basic.monoidJSX)(v.subNavigation), React_Basic_DOM_Generated.div()({
                className: "flex flex-row items-center",
                children: [ React_Basic.element($foreign.notificationPanel)({}), userDropdown ]
            }) ]
        });
        if (newNavbarEnabled) {
            return newNavbar;
        };
        return oldNavbar;
    };
});
module.exports = {
    header: header
};
