import { CostClass } from "../../../../../ts-bindings/CostClass";
import { TFunction } from "react-i18next";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import { PremiumOfferData } from "./Premium/PremiumOfferPage";

export const costClassColumnDefinitions = (
  costClasses: CostClass[],
  t: TFunction<"estimation", "offerPage">
): ColDef<PremiumOfferData>[] => {
  const costClassColumns: ColDef<PremiumOfferData>[] = costClasses.map(
    (costClass, costClassIndex) => {
      let costClassHeader;

      if (
        costClass.socialExpensePercentageInCostEstimation !== null &&
        costClass.socialExpensePercentageInCostEstimation !== 0
      ) {
        costClassHeader =
          costClass.costClassCode +
          ": " +
          costClass.name +
          " (" +
          costClass.socialExpensePercentageInCostEstimation * 100 +
          "%)";
      } else {
        costClassHeader = costClass.costClassCode + ": " + costClass.name;
      }

      return {
        colId: `costClasses.${costClassIndex}.total`,
        headerName: costClassHeader,
        type: ["money"],
        initialAggFunc: "sum",
        initialWidth: 140,
        initialHide: isCostClassHidden(costClassIndex),
        valueGetter: (params: ValueGetterParams<PremiumOfferData>) => {
          if (!params.data) {
            return null;
          }
          if (!params.data.costClasses) {
            return null;
          }
          return params.data.costClasses[costClassIndex].total;
        },
      };
    }
  );

  // Column will show the sum of all the rest of costclass prices after the 4th costclass
  const restOfCostClassesColumn: ColDef[] = [
    {
      colId: "restOfCostClassesSummed",
      headerName: t`columns.restOfCostClasses`,
      type: ["money"],
      initialWidth: 140,
      valueGetter: (params: ValueGetterParams) =>
        getSummedRestOfCostClassesTotalPrice(params),
      initialAggFunc: "sum",
    },
  ];

  return [...costClassColumns, ...restOfCostClassesColumn];
};

function isCostClassHidden(costClassIndex: number) {
  return costClassIndex > 3;
}

function getSummedRestOfCostClassesTotalPrice(
  params: ValueGetterParams<PremiumOfferData>
) {
  if (!params.data) {
    return null;
  }

  const { costClasses } = params.data;
  if (!costClasses) {
    return null;
  }
  const totalPriceByCostClasses = Object.values(costClasses);
  return totalPriceByCostClasses
    .slice(4)
    .reduce((acc, currentValue) => acc + currentValue.total, 0);
}
