// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Effect = require("../Effect/index.js");
var Effect_Console = require("../Effect.Console/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoInput = require("../TacoInput/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var wrapper = (function () {
    var $0 = Box.box("tacoInputExampleWrapper")([ Box.MaxWidth.create(new TacoConstants.CustomSize("12rem")) ]);
    return function ($1) {
        return Data_Array.singleton($0(Data_Array.singleton($1)));
    };
})();
var imports = "import TacoInput as TacoInput\x0a\x0a";
var example8 = imports + "TacoInput.stringInput { value: \"Clearable message\", clearButton: true, onChange: TacoInput.changeHandler $ Console.log <<< append \"The new value is: \"";
var example7 = imports + "TacoInput.numberInput { value: 42.5, precision: DecimalPrecision 2, currency: true, align: TacoInput.RightAlign, onSave: \\a -> Console.log $ \"onSave called with: \" <> show a }";
var example6 = imports + "TacoInput.numberInput { value: 42.5, precision: DecimalPrecision 2, onSave: \\a -> Console.log $ \"onSave called with: \" <> show a }";
var example5 = imports + "TacoInput.stringInput { value: \"\", textarea: true }";
var example4 = imports + "TacoInput.stringInput { value: \"Cannot be edited\", disabled: true }";
var example3 = imports + "TacoInput.stringInput { value: \"\", loading: true }";
var example2 = imports + "TacoInput.stringInput { value: \"Incorrect input\", error: true, errorMessage: \"An error occurred.\" }";
var example1_label = imports + "TacoInput.stringInput { value: \"Hello world\", label: R.text \"Example label\", onSave: \\a -> Console.log $ \"onSave called with: \" <> a }";
var example1 = imports + "TacoInput.stringInput { value: \"Hello world\", onSave: \\a -> Console.log $ \"onSave called with: \" <> a }";
var closeButton = TofuHooks.mkHookComponent("TacoInputDemo_CloseButton")(function (props) {
    return Control_Applicative.pure(Effect.applicativeEffect)(TacoButton.component()()({
        text: "Hello world",
        disableFocusHack: true,
        onClick: new Data_Maybe.Just(props.closeInput)
    }));
});
var sections = [ {
    title: "Default",
    view: wrapper(TacoInput.stringInput()()(Data_Eq.eqString)({
        value: "Hello world",
        onSave: function (a) {
            return Effect_Console.log("onSave called with: " + a);
        }
    })),
    code: example1
}, {
    title: "With label",
    view: wrapper(TacoInput.stringInput()()(Data_Eq.eqString)({
        value: "Hello world",
        label: "Example label",
        onSave: function (a) {
            return Effect_Console.log("onSave called with: " + a);
        }
    })),
    code: example1_label
}, {
    title: "Error state",
    view: wrapper(TacoInput.stringInput()()(Data_Eq.eqString)({
        value: "Incorrect input",
        error: true,
        errorMessage: "An error occurred."
    })),
    code: example2
}, {
    title: "Loading state",
    view: wrapper(TacoInput.stringInput()()(Data_Eq.eqString)({
        value: "",
        loading: true
    })),
    code: example3
}, {
    title: "Disabled state",
    view: wrapper(TacoInput.stringInput()()(Data_Eq.eqString)({
        value: "Cannot be edited",
        disabled: true
    })),
    code: example4
}, {
    title: "Number input",
    view: wrapper(TacoInput.numberInput()()(Data_Eq.eqNumber)({
        value: 42.5,
        precision: 2,
        onSave: function (a) {
            return Effect_Console.log("onSave called with: " + Data_Show.show(Data_Show.showNumber)(a));
        }
    })),
    code: example6
}, {
    title: "Currency input",
    view: wrapper(TacoInput.numberInput()()(Data_Eq.eqNumber)({
        value: 42.5,
        precision: 2,
        currency: true,
        align: TacoInput.RightAlign.value,
        onSave: function (a) {
            return Effect_Console.log("onSave called with: " + Data_Show.show(Data_Show.showNumber)(a));
        }
    })),
    code: example7
}, {
    title: "With clear button",
    view: wrapper(TacoInput.stringInput()()(Data_Eq.eqString)({
        value: "Clearable message",
        clearButton: true,
        onChange: TacoInput.changeHandler(TacoInput.inputValueString)((function () {
            var $2 = Data_Semigroup.append(Data_Semigroup.semigroupString)("The new value is: ");
            return function ($3) {
                return Effect_Console.log($2($3));
            };
        })())
    })),
    code: example8
}, {
    title: "Expanding custom contents",
    view: wrapper(TacoInput.stringInput()()(Data_Eq.eqString)({
        value: "",
        customComponent: Data_Maybe.Just.create(closeButton({
            closeInput: Effect_Console.log("yay")
        })),
        onSave: function (a) {
            return Effect_Console.log("onSave called with: " + a);
        }
    })),
    code: example5
}, {
    title: "TextArea",
    view: wrapper(TacoInput.stringInput()()(Data_Eq.eqString)({
        value: "",
        textarea: true
    })),
    code: example5
}, {
    title: "TextArea which expands when active",
    view: wrapper(TacoInput.stringInput()()(Data_Eq.eqString)({
        value: "hello\x0aworld\x0a!",
        textarea: true,
        expandWhenActive: true
    })),
    code: example5
}, {
    title: "Date picker",
    view: wrapper(TacoInput.stringInput()()(Data_Eq.eqString)({
        value: "",
        type: "date",
        clickEventHandling: TacoInput.NoHandling.value
    })),
    code: example5
} ];
var demoView = {
    sections: sections,
    title: "TacoInput",
    description: "Generic input field"
};
module.exports = {
    demoView: demoView
};
