import { Measurement } from "../../../../../../ts-bindings/Measurement";
import { useWorkerState } from "../../../../hooks/useWorkerState";
import { OrgMeasurement } from "../../../../../../ts-bindings/OrgMeasurement";
import { useRef } from "react";

// Rules for combining organization's classification's measurements and imported file's measurements (25.08.2023):
// 1. If the imported measurement has a corresponding measurement in the org measurements, they are combined, and the values are kept
// 2. If the imported measurement has a value, it is kept
// 3. Remove all measurements that have no values and no corresponding org measurement
export function useMeasurementsFromClassification(
  classification: string,
  onChange: (measurementUnits: Measurement[]) => void,
  measurements: Measurement[]
) {
  const prevClassification = useRef<string>();

  const orgMeasurements = useWorkerState("OrgMeasurementsState") ?? [];
  const classificationMeasurements = orgMeasurements.filter(
    (measurement) => measurement.orgClassificationId === classification
  );

  if (prevClassification.current !== classification) {
    if (prevClassification.current !== undefined) {
      const processedMeasurements = processMeasurements(
        classificationMeasurements,
        measurements
      );
      onChange(processedMeasurements);
    }

    prevClassification.current = classification;
  }
}

export function processMeasurements(
  orgMeasurements: OrgMeasurement[],
  measurements: Measurement[]
) {
  const filteredMeasurements = filterMissingOrEmptyMeasurements(
    orgMeasurements,
    measurements
  );
  const missingOrgMeasurements = findMissingOrgMeasurements(
    orgMeasurements,
    filteredMeasurements
  );
  return filteredMeasurements.concat(missingOrgMeasurements);
}

export const filterMissingOrEmptyMeasurements = (
  orgMeasurements: OrgMeasurement[],
  measurements: Measurement[]
) =>
  measurements.filter((measurement) => {
    return (
      orgMeasurements.find((orgMeasurement) =>
        isOrgMeasurementEqualToMeasurement(measurement, orgMeasurement)
      ) !== undefined ||
      (measurement.values.length !== 0 &&
        measurement.values.some((value) => value.value !== 0))
    );
  });

export const findMissingOrgMeasurements = (
  orgMeasurements: OrgMeasurement[],
  measurements: Measurement[]
) => {
  const missingMeasurements = orgMeasurements.filter((orgMeasurement) => {
    return (
      measurements.find((measurement) =>
        isOrgMeasurementEqualToMeasurement(measurement, orgMeasurement)
      ) === undefined
    );
  });

  return missingMeasurements.map((measurement) => ({
    unit: {
      id: null,
      name: measurement.description,
      unit: measurement.code,
      projectId: null,
      mandatory: false,
    },
    values: [],
  }));
};

const isOrgMeasurementEqualToMeasurement = (
  measurement: Measurement,
  orgMeasurement: OrgMeasurement
) =>
  measurement.unit.name.toLowerCase() ===
    orgMeasurement.description.toLowerCase() &&
  measurement.unit.unit.toLowerCase() === orgMeasurement.code.toLowerCase();
