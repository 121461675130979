// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var rightControlsStyles = Box.boxStyle([ Box.FlexRow.value, Box.AlignCenter.value ]);
var leftControlsStyles = function (props) {
    return Box.boxStyle([ Box.FlexRow.value, Box.AlignCenter.value, new Box.PaddingLeft(TacoConstants.XS.value), new Box.FlexGrow(1), Data_Monoid.guard(Box.monoidBoxProp)(props.gap)(new Box.Selector("& > *", [ new Box.PaddingRight(TacoConstants.M.value) ])) ]);
};
var controlsContainerStyles = function (props) {
    return Box.boxStyle([ Box.FlexRow.value, Box.JustifySpaceBetween.value, Box.Width.create(new TacoConstants.CustomSize("100%")), Data_Monoid.guard(Box.monoidBoxProp)(props.bottomGutter)(new Box.PaddingBottom(TacoConstants.M.value)), Data_Monoid.guard(Box.monoidBoxProp)(props.topGutter)(new Box.PaddingTop(TacoConstants.M.value)) ]);
};
module.exports = {
    controlsContainerStyles: controlsContainerStyles,
    leftControlsStyles: leftControlsStyles,
    rightControlsStyles: rightControlsStyles
};
