// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var TofuJSON = require("../TofuJSON/index.js");
var WasmWorker_WorkerRequest = require("../WasmWorker.WorkerRequest/index.js");
var sendWasmWorkerRequest = function (workerRequest) {
    var request = TofuJSON.write(WasmWorker_WorkerRequest.tofuJSONWorkerRequest)(workerRequest);
    return $foreign.sendWasmWorkerRequest_(request);
};
var setWasmWorkerState = function (stateUpdate) {
    return function (strategy) {
        return sendWasmWorkerRequest(new WasmWorker_WorkerRequest.StateUpdate({
            state: [ stateUpdate ],
            strategy: strategy
        }));
    };
};
module.exports = {
    sendWasmWorkerRequest: sendWasmWorkerRequest,
    setWasmWorkerState: setWasmWorkerState,
    sendWasmWorkerRequest_: $foreign.sendWasmWorkerRequest_
};
