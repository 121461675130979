// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var ReducerUtils = require("../ReducerUtils/index.js");
var Types_ComponentsView = require("../Types.ComponentsView/index.js");
var update = function (s) {
    return function (v) {
        if (v instanceof Actions.CopyResourcesToClipboard) {
            return {
                resources: v.value0,
                components: s.components
            };
        };
        if (v instanceof Actions.CopyComponentsToClipboard) {
            return {
                resources: s.resources,
                components: v.value0
            };
        };
        return s;
    };
};
var initialState = {
    resources: [  ],
    components: Types_ComponentsView.ClipboardEmpty.value
};
var reducer = ReducerUtils.mkReducer(initialState)(update);
module.exports = {
    initialState: initialState,
    update: update,
    reducer: reducer
};
