import {
  IRowNode,
  ValueGetterParams,
  ValueSetterParams,
} from "ag-grid-community";
import { Dispatch, RefObject, SetStateAction } from "react";
import { AgGridReact } from "ag-grid-react";
import { Measurement } from "../../../../../../ts-bindings/Measurement";
import { TFunction } from "i18next";
import { safeDivide } from "../../../../utils/safeDivide";
import { CostClass } from "../../../../../../ts-bindings/CostClass";
import { OfferParams } from "../useOfferParamsQuery";
import {
  AdditionalRow,
  CostItemCostClass,
  CostItemRow,
  PremiumOfferSumRowData,
  SumRowCostClass,
} from "./PremiumOfferPageTable";
import { PercentageType } from "./EditRowModal";
import { formatOfferValueToNumber } from "../OfferPageCommonFunctions";

export function getPercentageOfTotal(params: ValueGetterParams) {
  if (!params.data) {
    return null;
  }
  if (
    params.data.code === "grandTotal" ||
    params.data.code === "social" ||
    params.data.code === "grandTotalWithSocial" ||
    params.data.code === "costItems" ||
    params.data.code === "VAT" ||
    params.data.code === "grandTotalWithCostItems"
  ) {
    return params.data.percentageOfTotal;
  }
  let grandTotal = 0;
  params.api?.forEachNodeAfterFilter((node) => {
    if (
      node.data.code !== "grandTotal" &&
      node.data.code !== "social" &&
      node.data.code !== "grandTotalWithSocial" &&
      node.data.code !== "costItems" &&
      node.data.code === "VAT" &&
      node.data.code !== "grandTotalWithCostItems"
    ) {
      grandTotal += node.data.total;
    }
  });
  return safeDivide(params.data.total, grandTotal);
}

export function getMeasurementValue(
  params: ValueGetterParams,
  unit: Measurement
) {
  if (!params.data) {
    return null;
  }
  if (!unit) {
    return null;
  }
  if (unit.values.length === 0 || unit.values[0].value === 0) {
    return 0;
  }

  return safeDivide(params.data.total, unit.values[0].value);
}

export function getTotalRowWithoutSocial(
  gridRef: RefObject<AgGridReact>,
  t: TFunction
) {
  let hourlyPrice = 0;
  let hours = 0;
  let total = 0;
  const costClasses: SumRowCostClass[] = [];
  gridRef.current?.api?.forEachNodeAfterFilter((node) => {
    if (
      node.data.code !== "grandTotal" &&
      node.data.code !== "social" &&
      node.data.code !== "grandTotalWithSocial" &&
      node.data.code !== "costItems" &&
      node.data.code !== "VAT"
    ) {
      hourlyPrice += node.data.hourlyPrice;
      hours += node.data.hours;
      total += node.data.total;
      const costClassesAmount = node.data.costClasses?.length;
      if (!costClasses.length) {
        for (let i = 0; i < costClassesAmount; i++) {
          costClasses.push({
            costClassCode: "",
            name: "",
            baseTotal: 0,
            total: 0,
            socialExpensePrice: 0,
            totalWithSocial: 0,
          });
        }
      }
      if (node.data.costClasses) {
        for (let i = 0; i < costClasses.length; i++) {
          const socialPercentage =
            getSocialPercentage(
              gridRef.current?.api?.getGridOption("context")?.costClasses,
              node,
              i
            ) ?? 0;
          costClasses[i].costClassCode = node.data.costClasses[i].costClassCode;
          costClasses[i].name = node.data.costClasses[i].name;
          costClasses[i].baseTotal += node.data.costClasses[i].total;
          costClasses[i].total += node.data.costClasses[i].total;
          costClasses[i].socialExpensePrice +=
            node.data.costClasses[i].total * socialPercentage;
          costClasses[i].totalWithSocial +=
            node.data.costClasses[i].total +
            node.data.costClasses[i].total * socialPercentage;
        }
      }
    }
  });
  const totalWithSocial = costClasses.reduce(
    (a, b) => a + b.totalWithSocial,
    0
  );
  const totalHourlyRate = safeDivide(hourlyPrice, hours);
  const percentageOfTotal = safeDivide(total, totalWithSocial);
  const measurements = gridRef.current?.api?.getGridOption("context")
    ?.measurementUnits;
  return {
    code: "grandTotal",
    description: t("grandTotal"),
    hours: hours,
    hourlyRate: totalHourlyRate,
    total,
    percentageOfTotal,
    costClasses,
    measurements,
  };
}
export function getSocialRow(gridRef: RefObject<AgGridReact>, t: TFunction) {
  const costClasses: SumRowCostClass[] = [];
  const contextCostClasses = gridRef.current?.api?.getGridOption("context")
    ?.costClasses;
  gridRef.current?.api?.forEachNodeAfterFilter((node) => {
    if (
      node.data.code !== "grandTotal" &&
      node.data.code !== "social" &&
      node.data.code !== "grandTotalWithSocial" &&
      node.data.code !== "costItems" &&
      node.data.code !== "VAT"
    ) {
      const costClassesAmount = node.data.costClasses?.length;
      if (!costClasses.length) {
        for (let i = 0; i < costClassesAmount; i++) {
          costClasses.push({
            costClassCode: "",
            name: "",
            baseTotal: 0,
            total: 0,
            socialExpensePrice: 0,
            socialPercentage: 0,
            totalWithSocial: 0,
          });
        }
      }
      if (node.data.costClasses) {
        for (let i = 0; i < costClasses.length; i++) {
          const socialPercentage =
            getSocialPercentage(contextCostClasses, node, i) ?? 0;
          costClasses[i].costClassCode = node.data.costClasses[i].costClassCode;
          costClasses[i].name = node.data.costClasses[i].name;
          costClasses[i].baseTotal += node.data.costClasses[i].total;
          costClasses[i].total +=
            node.data.costClasses[i].total * socialPercentage;
          costClasses[i].socialExpensePrice +=
            node.data.costClasses[i].total * socialPercentage;
          costClasses[i].socialPercentage = socialPercentage;
          costClasses[i].totalWithSocial +=
            node.data.costClasses[i].total +
            node.data.costClasses[i].total * socialPercentage;
        }
      }
    }
  });
  const totalSocialExpenses = costClasses.reduce(
    (acc, curr) => acc + curr.socialExpensePrice,
    0
  );
  const total = costClasses.reduce(
    (acc, curr) => acc + curr.totalWithSocial,
    0
  );
  const percentageOfTotal = safeDivide(totalSocialExpenses, total);
  const measurements = gridRef.current?.api?.getGridOption("context")
    ?.measurementUnits;
  return {
    code: "social",
    description: t("social"),
    hours: null,
    hourlyRate: null,
    total: totalSocialExpenses,
    percentageOfTotal,
    costClasses,
    measurements,
  };
}

export function getTotalRowWithSocial(
  gridRef: RefObject<AgGridReact>,
  t: TFunction
) {
  const totalRowWithoutSocial = getTotalRowWithoutSocial(gridRef, t);
  const socialRow = getSocialRow(gridRef, t);
  const totalWithSocial = totalRowWithoutSocial.total + socialRow.total;
  const hours = totalRowWithoutSocial.hours;
  const contextCostClasses = gridRef.current?.api?.getGridOption("context")
    ?.costClasses;
  const withHourlyPricing = contextCostClasses?.filter(
    (costClass: { hourlyPricing: boolean }) => {
      return costClass.hourlyPricing;
    }
  );
  const totalsWithHourlyPricing = totalRowWithoutSocial.costClasses
    .map((costClass) => {
      if (
        withHourlyPricing?.find(
          (c: { costClassCode: string }) =>
            c.costClassCode === costClass.costClassCode
        )
      ) {
        return costClass.totalWithSocial;
      }
      return null;
    })
    .filter((total) => total !== null);
  const socialTotal = totalsWithHourlyPricing.reduce(
    (a: number, b) => a + (b ?? 0),
    0
  );
  const hourlyRate = safeDivide(socialTotal, hours);
  const percentagesOfTotal =
    totalRowWithoutSocial.percentageOfTotal + socialRow.percentageOfTotal;
  const costClasses = totalRowWithoutSocial.costClasses.map((costClass) => {
    const socialExpensePrice =
      socialRow.costClasses.find(
        (socialCostClass) =>
          socialCostClass.costClassCode === costClass.costClassCode
      )?.socialExpensePrice ?? 0;
    return {
      ...costClass,
      total: costClass.total + socialExpensePrice,
    };
  });
  const measurements = totalRowWithoutSocial.measurements;
  return {
    code: "grandTotalWithSocial",
    description: t("grandTotalWithSocial"),
    hours: hours,
    hourlyRate: hourlyRate,
    total: totalWithSocial,
    percentageOfTotal: percentagesOfTotal,
    costClasses,
    measurements,
  };
}

function getSocialPercentage(
  contextCostClasses: CostClass[],
  node: IRowNode,
  i: number
) {
  if (node.data.costClasses) {
    return (
      contextCostClasses?.find(
        (costClass: CostClass) =>
          costClass.costClassCode === node.data.costClasses[i]?.costClassCode
      )?.socialExpensePercentageInCostEstimation ?? 0
    );
  }
}

export function getCostItemsRow(rows: AdditionalRow[], t: TFunction) {
  const lastTotal = rows[rows.length - 1]?.totalWithAdditionalCosts ?? 0;
  const firstBaseTotal = rows[0]?.baseTotal ?? 0;
  const percentageOfTotal = safeDivide(lastTotal, firstBaseTotal);
  return {
    code: "costItems",
    description: t("costItemsTotal"),
    hours: null,
    hourlyRate: null,
    total: lastTotal,
    percentageOfTotal: percentageOfTotal,
  };
}

export function getRiskAndChangeRows(
  offerParams: OfferParams,
  totalRow: PremiumOfferSumRowData,
  visible: boolean,
  type: string,
  description: string | null,
  t: TFunction
) {
  let percentageType: PercentageType = "riskPercentages";
  switch (type) {
    case "risk":
      percentageType = "riskPercentages";
      break;
    case "change":
      percentageType = "changePercentages";
      break;
    default:
      break;
  }
  const summaryPercentage = offerParams?.[percentageType]?.find(
    (percentage) => percentage.key === "summary"
  )?.value;
  const totalPercentage =
    offerParams?.[percentageType]?.find(
      (percentage) => percentage.key === "total"
    )?.value ??
    summaryPercentage ??
    0;
  const costClasses = totalRow.costClasses.map((costClass) => {
    const costClassAdditionalPercentage = offerParams?.[percentageType]?.find(
      (percentage) => percentage.key === costClass.costClassCode
    )?.value;
    const socialPercentage =
      offerParams?.[percentageType]?.find(
        (percentage) => percentage.key === "social"
      )?.value ?? 0;
    const percentageInUse =
      summaryPercentage ?? costClassAdditionalPercentage ?? 0;
    const additionalTotal = costClass.total * percentageInUse;
    return {
      ...costClass,
      baseTotal: costClass.baseTotal,
      total: costClass.total * (1 + percentageInUse),
      socialExpensesWithAdditionalCosts:
        costClass.socialExpensePrice *
        (1 + (summaryPercentage ?? socialPercentage)),
      totalWithSocial:
        costClass.total * (1 + percentageInUse) +
        costClass.socialExpensePrice *
          (1 + (summaryPercentage ?? socialPercentage)),
      additionalTotal,
    };
  });
  const socialTotal =
    costClasses?.reduce((acc, curr) => acc + curr.socialExpensePrice, 0) ?? 0;
  const socialPercentage =
    offerParams?.[percentageType]?.find(
      (percentage) => percentage.key === "social"
    )?.value ?? 0;
  costClasses?.push({
    costClassCode: "social",
    name: t("social"),
    baseTotal: socialTotal,
    total: socialTotal * (1 + (summaryPercentage ?? socialPercentage)),
    socialExpensePrice: socialTotal,
    socialExpensesWithAdditionalCosts:
      socialTotal * (1 + (summaryPercentage ?? socialPercentage)),
    totalWithSocial:
      socialTotal * (1 + (summaryPercentage ?? socialPercentage)),
    additionalTotal: socialTotal * (summaryPercentage ?? socialPercentage),
  });
  const baseTotal = totalRow.total + socialTotal;
  return {
    code: type,
    description: description,
    baseTotal: baseTotal,
    additionalPercentage: summaryPercentage ?? totalPercentage,
    additionalTotal: totalPercentage * baseTotal,
    totalWithAdditionalCosts:
      baseTotal * (1 + (summaryPercentage ?? totalPercentage)),
    visible,
    costClasses,
    measurements: totalRow.measurements,
  };
}

export function getGeneralRow(
  offerParams: OfferParams,
  subtotalRow: AdditionalRow,
  riskRow: AdditionalRow,
  changeRow: AdditionalRow,
  specialContractRows: AdditionalRow[],
  visible: boolean,
  generalDescription: string | null
) {
  const previousRowTotal = subtotalRow.totalWithAdditionalCosts ?? 0;
  const summaryPercentage = offerParams.generalPercentages?.find(
    (risk) => risk.key === "summary"
  )?.value;
  const totalPercentage =
    offerParams.generalPercentages.find((risk) => risk.key === "total")
      ?.value ??
    summaryPercentage ??
    0;
  const generalCostClasses: CostItemCostClass[] = [];
  const costClasses = subtotalRow.costClasses?.map((costClass) => {
    const costClassGeneralPercentage = offerParams.generalPercentages.find(
      (p) => p.key === costClass.costClassCode
    )?.value;
    const generalSocialPercentage =
      offerParams.generalPercentages.find((p) => p.key === "social")?.value ??
      0;
    const percentageInUse =
      summaryPercentage ?? costClassGeneralPercentage ?? 0;
    const additionalTotal = costClass.baseTotal * percentageInUse;
    generalCostClasses.push({
      costClassCode: costClass.costClassCode,
      name: costClass.name,
      baseTotal: costClass.baseTotal,
      general: additionalTotal,
    });
    return {
      ...costClass,
      total: costClass.baseTotal + additionalTotal,
      socialExpensesWithAdditionalCosts:
        (costClass.socialExpensesWithAdditionalCosts ??
          costClass.socialExpensePrice) *
        (1 + (summaryPercentage ?? generalSocialPercentage)),
      totalWithSocial:
        costClass.total * (1 + percentageInUse) +
        costClass.socialExpensePrice *
          (1 + (summaryPercentage ?? generalSocialPercentage)),
      additionalTotal,
    };
  });

  const ccGeneralTotalsSum =
    costClasses?.reduce((acc, curr) => acc + curr.additionalTotal, 0) ?? 0;

  const riskCostClasses: CostItemCostClass[] =
    riskRow.costClasses?.map((costClass) => {
      return {
        costClassCode: costClass.costClassCode,
        name: costClass.name,
        baseTotal: costClass.baseTotal,
        risk: costClass.additionalTotal,
      };
    }) ?? [];
  const riskRowAdditionalBase =
    riskCostClasses.reduce((acc, curr) => acc + (curr.risk ?? 0), 0) ?? 0;
  const riskRowGeneralAddition =
    riskRowAdditionalBase * (summaryPercentage ?? totalPercentage);
  const riskCostItemRow: CostItemRow = {
    code: riskRow.code,
    description: riskRow.description,
    baseTotal: riskRowAdditionalBase,
    additionalTotal: riskRowGeneralAddition,
    total: riskRowAdditionalBase + riskRowGeneralAddition,
    visible: riskRow.visible,
    costClasses: riskCostClasses,
  };

  const changeCostClasses: CostItemCostClass[] =
    changeRow.costClasses?.map((costClass) => {
      return {
        costClassCode: costClass.costClassCode,
        name: costClass.name,
        baseTotal: costClass.baseTotal,
        change: costClass.additionalTotal,
      };
    }) ?? [];
  const changeRowAdditionalBase =
    changeCostClasses.reduce((acc, curr) => acc + (curr.change ?? 0), 0) ?? 0;
  const changeRowGeneralAddition =
    changeRowAdditionalBase * (summaryPercentage ?? totalPercentage);
  const changeCostItemRow: CostItemRow = {
    code: changeRow.code,
    description: changeRow.description,
    baseTotal: changeRowAdditionalBase,
    additionalTotal: changeRowGeneralAddition,
    total: changeRowAdditionalBase + changeRowGeneralAddition,
    visible: changeRow.visible,
    costClasses: changeCostClasses,
  };
  const specialContractCostItemRows =
    specialContractRows.map((specialContractRow) => {
      return {
        code: specialContractRow.code,
        description: specialContractRow.description,
        baseTotal: specialContractRow.baseTotal,
        additionalTotal: specialContractRow.additionalTotal,
        total: null,
        visible: specialContractRow.visible,
        costClasses: [],
      };
    }) ?? [];

  const specialContractRowsAdditions = specialContractRows.map(
    (specialContractRow) => {
      return (
        specialContractRow.additionalTotal *
        (summaryPercentage ?? totalPercentage)
      );
    }
  );
  const additionalTotal =
    ccGeneralTotalsSum +
    riskRowGeneralAddition +
    changeRowGeneralAddition +
    specialContractRowsAdditions.reduce((acc, curr) => acc + curr, 0);
  const generalCostItemRow: CostItemRow = {
    code: "general",
    description: generalDescription,
    baseTotal: additionalTotal,
    additionalTotal: 0,
    total: additionalTotal,
    visible,
    costClasses: generalCostClasses,
  };

  const costItems = [
    riskCostItemRow,
    changeCostItemRow,
    ...specialContractCostItemRows,
    generalCostItemRow,
  ];

  const visibleCostItems = costItems.filter((ci) => ci.visible);
  return {
    code: "general",
    description: generalDescription,
    baseTotal: previousRowTotal,
    additionalPercentage: summaryPercentage ?? totalPercentage,
    additionalTotal,
    totalWithAdditionalCosts: previousRowTotal + additionalTotal,
    visible,
    costClasses,
    costItems: visibleCostItems,
  };
}

export function getVATRow(
  gridRef: RefObject<AgGridReact>,
  additionalRows: AdditionalRow[],
  VAT: number,
  t: TFunction
) {
  const VATPercentage = VAT / 100;
  const totalRowWithSocial = getTotalRowWithSocial(gridRef, t);
  const costItemsRow = getCostItemsRow(additionalRows, t);
  const total = costItemsRow.total * VATPercentage;
  const percentageOfTotal = safeDivide(total, totalRowWithSocial.total);
  return {
    code: "VAT",
    description: t("VAT") + " " + VAT + "%",
    hours: null,
    hourlyRate: null,
    total,
    percentageOfTotal,
  };
}

export function getSpecialRow(
  offerParams: OfferParams,
  visible: boolean,
  specialDescription: string | null
) {
  const specialContractValue =
    offerParams.specialPercentages?.find(
      (value) => value.key === "specialContract"
    )?.value ?? 0;
  return {
    code: "specialContract",
    description: specialDescription,
    baseTotal: 0,
    additionalPercentage: 0,
    additionalTotal: specialContractValue,
    totalWithAdditionalCosts: 0,
    visible,
  };
}

export function getMarginRow(
  offerParams: OfferParams,
  generalRow: AdditionalRow & {
    costItems: CostItemRow[];
  },
  visible: boolean,
  t: TFunction
) {
  const previousRowTotal = generalRow.totalWithAdditionalCosts ?? 0;
  const summaryPercentage = offerParams.marginPercentages?.find(
    (value) => value.key === "summary"
  )?.value;
  const totalPercentage =
    offerParams.marginPercentages.find((value) => value.key === "total")
      ?.value ??
    summaryPercentage ??
    0;
  const costClasses = generalRow.costClasses?.map((costClass) => {
    const costClassMarginPercentage = offerParams.marginPercentages.find(
      (value) => value.key === costClass.costClassCode
    )?.value;
    const marginSocialPercentage =
      offerParams.marginPercentages.find((value) => value.key === "social")
        ?.value ?? 0;
    const percentageInUse = summaryPercentage ?? costClassMarginPercentage ?? 0;
    return {
      ...costClass,
      baseTotal: costClass.baseTotal,
      total: costClass.baseTotal * (1 + percentageInUse),
      socialExpensesWithAdditionalCosts:
        (costClass.socialExpensesWithAdditionalCosts ??
          costClass.socialExpensePrice) *
        (1 + (summaryPercentage ?? marginSocialPercentage)),
      totalWithSocial:
        costClass.baseTotal * (1 + percentageInUse) +
        costClass.socialExpensePrice *
          (1 + (summaryPercentage ?? marginSocialPercentage)),
    };
  });
  const percentageInUse = summaryPercentage ?? totalPercentage;
  const economicBaseTotal = safeDivide(previousRowTotal, 1 - percentageInUse);
  const baseTotalInUse = offerParams.showEconomicMargin
    ? economicBaseTotal
    : previousRowTotal;
  const additionalTotal = baseTotalInUse * percentageInUse;
  return {
    code: "margin",
    description: offerParams.showEconomicMargin
      ? t("margin")
      : t("columns.technicalMargin"),
    baseTotal: previousRowTotal,
    additionalPercentage: percentageInUse,
    additionalTotal,
    totalWithAdditionalCosts: previousRowTotal + additionalTotal,
    visible,
    costClasses,
    costItems: generalRow.costItems,
  };
}

export function getSubtotalRow(
  totalRow: PremiumOfferSumRowData,
  riskRow: AdditionalRow,
  changeRow: AdditionalRow,
  specialRows: AdditionalRow[],
  t: TFunction
) {
  const baseTotal = totalRow.total;
  const specialRowsAdditions = specialRows.reduce(
    (acc, curr) => acc + curr.additionalTotal,
    0
  );
  const additionalTotal =
    riskRow.additionalTotal + changeRow.additionalTotal + specialRowsAdditions;
  const totalWithAdditionalCosts = baseTotal + additionalTotal;
  const additionalPercentage = safeDivide(additionalTotal, baseTotal);
  const visible = riskRow.visible || changeRow.visible;
  const costClasses = riskRow.costClasses?.map((costClass) => {
    const riskAddition = costClass.additionalTotal ?? 0;
    const changeAddition =
      changeRow.costClasses?.find(
        (changeCostClass) =>
          changeCostClass.costClassCode === costClass.costClassCode
      )?.additionalTotal ?? 0;
    return {
      ...costClass,
      additionalTotal: riskAddition + changeAddition,
      total: costClass.total + riskAddition + changeAddition,
    };
  });
  return {
    code: "subtotal",
    description: t("subtotal"),
    baseTotal,
    additionalPercentage,
    additionalTotal,
    totalWithAdditionalCosts,
    visible,
    costClasses,
    measurements: totalRow.measurements,
  };
}

export function getBaseTotal(params: ValueGetterParams) {
  if (!params.data || params.data.code === "specialContract") {
    return null;
  }
  return params.data.baseTotal;
}

export function getAdditionalPercentage(params: ValueGetterParams) {
  if (!params.data || params.data.code === "specialContract") {
    return null;
  }
  return params.data.additionalPercentage;
}

export function getTotalWithAdditionalCosts(params: ValueGetterParams) {
  if (
    !params.data ||
    params.data.code === "risk" ||
    params.data.code === "change" ||
    params.data.code === "specialContract"
  ) {
    return null;
  }
  return params.data.totalWithAdditionalCosts;
}

export function getAdditionalTotal(params: ValueGetterParams) {
  if (!params.data) {
    return null;
  }
  return params.data.additionalTotal;
}

export function setSpecialAddition(
  params: ValueSetterParams,
  valueChange: Dispatch<SetStateAction<{ key: string; value: number }[] | null>>
) {
  const newValue = formatOfferValueToNumber(params);
  if (!params.node || params.newValue === params.oldValue) {
    return params.oldValue;
  }
  if (params.node?.data.code === "specialContract") {
    params.node.data.additionalTotal = newValue;

    const newSpecialContractValue = [
      { key: "specialContract", value: newValue },
    ];
    valueChange(newSpecialContractValue);
  }
}

export function getGrandTotalWithCostItems(
  costItemsRowTotal: number,
  totalRowWithSocialTotal: number,
  VATRowTotal: number,
  t: TFunction
) {
  const total = costItemsRowTotal + VATRowTotal;
  const percentageOfTotal = safeDivide(total, totalRowWithSocialTotal);
  return {
    code: "grandTotalWithCostItems",
    description: t("columns.grandTotalWithCostItems"),
    hours: null,
    hourlyRate: null,
    total,
    percentageOfTotal,
  };
}
