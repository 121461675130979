// Generated by purs version 0.14.5
"use strict";
var Client_WASM = require("../Client.WASM/index.js");
var Common = require("../Common/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Formatters = require("../Formatters/index.js");
var Gap = require("../Gap/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var SocialExpenseIndicator = require("../SocialExpenseIndicator/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoList = require("../TacoList/index.js");
var TacoText = require("../TacoText/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_CostEstimation = require("../Types.CostEstimation/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var mkComponentSummaryItem = function (props) {
    var pbccToItem = function (pbcc) {
        var title = Data_Newtype.un()(Types_Newtypes.CostClassCode)(pbcc.costClass.costClassCode) + (". " + pbcc.costClass.name);
        return {
            title: title,
            titleJSX: props.mkTitleJSX(React_Basic_DOM_Generated.var_([ React_Basic_DOM.text(title) ])),
            value: TacoList.TextValue.create(Formatters.formatCurrencyValue(props.currency)(pbcc.totalPrice)),
            disabled: false,
            onClick: Data_Maybe.Nothing.value,
            getItems: function (v) {
                return [  ];
            },
            hasItems: false,
            initExpanded: false,
            id: title
        };
    };
    var countStrValues = (function () {
        if (props.componentsSummary instanceof Data_Maybe.Just) {
            return {
                count: " (" + Data_Show.show(Data_Show.showInt)(props.componentsSummary.value0.count),
                countStrEnding: (function () {
                    var $10 = props.componentsSummary.value0.count === 1;
                    if ($10) {
                        return " suorite)";
                    };
                    return " suoritetta)";
                })()
            };
        };
        if (props.componentsSummary instanceof Data_Maybe.Nothing) {
            return {
                count: "",
                countStrEnding: ""
            };
        };
        throw new Error("Failed pattern match at ComponentSummaries (line 96, column 22 - line 98, column 51): " + [ props.componentsSummary.constructor.name ]);
    })();
    var totalSocialExpensePrice = Data_Maybe.fromMaybe(0.0)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
        return v.totalSocialExpensePrice;
    })(props.componentsSummary));
    var socialExpenseText = function (isValue) {
        return function (text) {
            return TacoText.component()()({
                text: text,
                color: TacoConstants.GrayDark.value,
                testId: props.label + ((function () {
                    if (isValue) {
                        return "-value";
                    };
                    return "";
                })() + "-total-social-expense-cost")
            });
        };
    };
    var socialExpenseRowItem = {
        title: "Sosiaalikulut (ei mukana summissa)",
        titleJSX: Data_Maybe.Just.create(socialExpenseText(false)("Sosiaalikulut (ei mukana summissa)")),
        value: TacoList.JSXValue.create(socialExpenseText(true)(Formatters.formatCurrencyValue(props.currency)(totalSocialExpensePrice))),
        disabled: false,
        onClick: Data_Maybe.Nothing.value,
        getItems: function (v) {
            return [  ];
        },
        hasItems: false,
        initExpanded: false,
        id: "Sosiaalikulut (ei mukana summissa)"
    };
    var socialExpenseRow = (function () {
        if (props.showSocialExpensesInCosts) {
            return Common.emptyArray;
        };
        return [ socialExpenseRowItem ];
    })();
    var title = (function () {
        var $14 = Data_Maybe.isJust(props.componentsSummary);
        if ($14) {
            return props.label + (countStrValues.count + countStrValues.countStrEnding);
        };
        return props.label + " (loading)";
    })();
    return {
        title: title,
        titleJSX: (function () {
            var $15 = Data_Maybe.isJust(props.componentsSummary);
            if ($15) {
                return props.mkTitleJSX(React_Basic_DOM_Generated.div_([ React_Basic_DOM.text(props.label), React_Basic_DOM_Generated.var_([ React_Basic_DOM.text(countStrValues.count) ]), React_Basic_DOM.text(countStrValues.countStrEnding) ]));
            };
            return props.mkTitleJSX(React_Basic_DOM.text(props.label + " (loading)"));
        })(),
        value: (function () {
            if (props.componentsSummary instanceof Data_Maybe.Just) {
                return TacoList.TextValue.create(Formatters.formatCurrencyValue(props.currency)(props.componentsSummary.value0.totalPrice));
            };
            if (props.componentsSummary instanceof Data_Maybe.Nothing) {
                return new TacoList.TextValue("(loading)");
            };
            throw new Error("Failed pattern match at ComponentSummaries (line 143, column 12 - line 145, column 48): " + [ props.componentsSummary.constructor.name ]);
        })(),
        disabled: false,
        onClick: Data_Maybe.Nothing.value,
        initExpanded: false,
        getItems: (function () {
            if (props.componentsSummary instanceof Data_Maybe.Just) {
                return function (v) {
                    return Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_Functor.map(Data_Functor.functorArray)(pbccToItem)(props.componentsSummary.value0.costClasses))(socialExpenseRow);
                };
            };
            if (props.componentsSummary instanceof Data_Maybe.Nothing) {
                return function (v) {
                    return [  ];
                };
            };
            throw new Error("Failed pattern match at ComponentSummaries (line 149, column 15 - line 151, column 26): " + [ props.componentsSummary.constructor.name ]);
        })(),
        hasItems: (function () {
            if (props.componentsSummary instanceof Data_Maybe.Just) {
                return Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(Data_Array["null"])(props.componentsSummary.value0.costClasses);
            };
            if (props.componentsSummary instanceof Data_Maybe.Nothing) {
                return false;
            };
            throw new Error("Failed pattern match at ComponentSummaries (line 152, column 15 - line 154, column 23): " + [ props.componentsSummary.constructor.name ]);
        })(),
        id: props.label
    };
};
var component = TofuHooks.mkMemoHookComponent("ComponentSummaries")(function (props) {
    return function __do() {
        var v = Client_WASM.useEstimationComponentsTotals(props.projectId)();
        var totalSummaryLabel = (function () {
            var $23 = Data_Eq.eq(Types_CostEstimation.eqECViewMode)(props.estimationViewMode)(Types_CostEstimation.ReferenceMode.value);
            if ($23) {
                return "Kaikki hankkeet";
            };
            return "Koko hanke";
        })();
        var mkTitleJSX = function (jsx) {
            if (props.showSocialExpensesInCosts) {
                return Data_Maybe.Just.create(Gap.gapX(TacoConstants.XS.value)([ SocialExpenseIndicator.socialExpenseIndicator, jsx ]));
            };
            return new Data_Maybe.Just(jsx);
        };
        var totalSummary = mkComponentSummaryItem({
            label: totalSummaryLabel,
            mkTitleJSX: mkTitleJSX,
            componentsSummary: Data_Functor.map(Data_Maybe.functorMaybe)(function (v1) {
                return v1.totalComponentsSummary;
            })(v.value0),
            showSocialExpensesInCosts: props.showSocialExpensesInCosts,
            currency: props.projectCurrency
        });
        var selectedSummary = mkComponentSummaryItem({
            label: "Valinta",
            mkTitleJSX: mkTitleJSX,
            componentsSummary: Data_Functor.map(Data_Maybe.functorMaybe)(function (v1) {
                return v1.selectedComponentsSummary;
            })(v.value0),
            showSocialExpensesInCosts: props.showSocialExpensesInCosts,
            currency: props.projectCurrency
        });
        var filteredSummary = mkComponentSummaryItem({
            label: "Listatut suoritteet",
            mkTitleJSX: mkTitleJSX,
            componentsSummary: Data_Functor.map(Data_Maybe.functorMaybe)(function (v1) {
                return v1.filteredComponentsSummary;
            })(v.value0),
            showSocialExpensesInCosts: props.showSocialExpensesInCosts,
            currency: props.projectCurrency
        });
        props.setLoading("ComponentSummaries")(v.value1)();
        return ReactBasicUtils["div'"]("__ComponentSummaries")([ TacoList.component({
            items: [ totalSummary, filteredSummary, selectedSummary ]
        }) ]);
    };
});
module.exports = {
    component: component,
    mkComponentSummaryItem: mkComponentSummaryItem
};
