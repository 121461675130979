// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Admin_Utils = require("../Admin.Utils/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var EditUserRole = require("../EditUserRole/index.js");
var Effect = require("../Effect/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var Roles_Constants = require("../Roles.Constants/index.js");
var Roles_Types = require("../Roles.Types/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var ById = (function () {
    function ById() {

    };
    ById.value = new ById();
    return ById;
})();
var ByName = (function () {
    function ByName() {

    };
    ByName.value = new ByName();
    return ByName;
})();
var ByApps = (function () {
    function ByApps() {

    };
    ByApps.value = new ByApps();
    return ByApps;
})();
var ByCreate = (function () {
    function ByCreate() {

    };
    ByCreate.value = new ByCreate();
    return ByCreate;
})();
var ByWrites = (function () {
    function ByWrites() {

    };
    ByWrites.value = new ByWrites();
    return ByWrites;
})();
var ByReads = (function () {
    function ByReads() {

    };
    ByReads.value = new ByReads();
    return ByReads;
})();
var roleTableStyleSet = TacoTable_Styles.foldingTableStyleSet;
var getRoleSortingValue = function (v) {
    return function (v1) {
        if (v instanceof ById) {
            return TacoTable_Types.IntValue.create(Data_Newtype.unwrap()(v1.id));
        };
        if (v instanceof ByName) {
            return TacoTable_Types.StringValue.create((function (v2) {
                return v2.name;
            })(Data_Newtype.unwrap()(v1.role)));
        };
        if (v instanceof ByApps) {
            var appsValue = (function () {
                var v2 = (Data_Newtype.unwrap()(v1.role)).costEstimationPermission;
                var v3 = (Data_Newtype.unwrap()(v1.role)).costControlPermission;
                if (v3 && v2) {
                    return -2 | 0;
                };
                if (!v3 && !v2) {
                    return 0;
                };
                return -1 | 0;
            })();
            return TacoTable_Types.IntValue.create(appsValue);
        };
        if (v instanceof ByCreate) {
            return new TacoTable_Types.BooleanValue(v1.role.createProjectsPermission);
        };
        if (v instanceof ByWrites) {
            var vals = [ (function () {
                if (v1.role.ccWritePermission) {
                    return 1;
                };
                return 0;
            })(), (function () {
                if (v1.role.ceWritePermission) {
                    return 1;
                };
                return 0;
            })(), (function () {
                if (v1.role.ccWriteAllProjectsPermission) {
                    return 3;
                };
                return 0;
            })(), (function () {
                if (v1.role.ceWriteAllProjectsPermission) {
                    return 3;
                };
                return 0;
            })() ];
            return TacoTable_Types.IntValue.create(Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringInt)(vals));
        };
        if (v instanceof ByReads) {
            var vals = [ (function () {
                if (v1.role.ccReadPermission) {
                    return 1;
                };
                return 0;
            })(), (function () {
                if (v1.role.ceReadPermission) {
                    return 1;
                };
                return 0;
            })(), (function () {
                if (v1.role.ccReadAllProjectsPermission) {
                    return 3;
                };
                return 0;
            })(), (function () {
                if (v1.role.ceReadAllProjectsPermission) {
                    return 3;
                };
                return 0;
            })() ];
            return TacoTable_Types.IntValue.create(Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringInt)(vals));
        };
        throw new Error("Failed pattern match at UserRolesTable (line 153, column 1 - line 153, column 77): " + [ v.constructor.name, v1.constructor.name ]);
    };
};
var getNameRoleSortProperty = {
    getName: function (v) {
        return "RoleSortProperty";
    }
};
var roleTableInstance = TacoTable.mkTableSortable(getNameRoleSortProperty)("Role");
var eqRoleSortProperty = {
    eq: function (x) {
        return function (y) {
            if (x instanceof ById && y instanceof ById) {
                return true;
            };
            if (x instanceof ByName && y instanceof ByName) {
                return true;
            };
            if (x instanceof ByApps && y instanceof ByApps) {
                return true;
            };
            if (x instanceof ByCreate && y instanceof ByCreate) {
                return true;
            };
            if (x instanceof ByWrites && y instanceof ByWrites) {
                return true;
            };
            if (x instanceof ByReads && y instanceof ByReads) {
                return true;
            };
            return false;
        };
    }
};
var ordRoleSortProperty = {
    compare: function (x) {
        return function (y) {
            if (x instanceof ById && y instanceof ById) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ById) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ById) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByName && y instanceof ByName) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByName) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByName) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByApps && y instanceof ByApps) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByApps) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByApps) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByCreate && y instanceof ByCreate) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByCreate) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByCreate) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByWrites && y instanceof ByWrites) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof ByWrites) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof ByWrites) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof ByReads && y instanceof ByReads) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at UserRolesTable (line 149, column 1 - line 149, column 60): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqRoleSortProperty;
    }
};
var component = TofuHooks.mkHookComponent("UserRolesTable")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        var mkUpdateRoleAction = function (id) {
            return function (role) {
                return function (handler) {
                    return new Actions.UpdateUserRoleRequest({
                        id: id,
                        role: role,
                        organization: v.organization,
                        handler: handler
                    });
                };
            };
        };
        var updateRole = function (id) {
            return function (role) {
                return v.dispatch((function () {
                    if (id instanceof Data_Maybe.Just) {
                        return mkUpdateRoleAction(id.value0)(role)(Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit))));
                    };
                    if (id instanceof Data_Maybe.Nothing) {
                        return new Actions.AddUserRoleRequest({
                            role: role,
                            organization: v.organization
                        });
                    };
                    throw new Error("Failed pattern match at UserRolesTable (line 38, column 7 - line 40, column 69): " + [ id.constructor.name ]);
                })());
            };
        };
        var columns = (function () {
            var editButton = function (v2) {
                return TacoButton.component()()({
                    text: "Muokkaa",
                    buttonStyle: TacoButton_Types.Outlined.value,
                    onClick: Data_Maybe.Just.create(v1.value1(function (v3) {
                        return new Data_Maybe.Just({
                            id: new Data_Maybe.Just(v2.id),
                            role: v2.role
                        });
                    }))
                });
            };
            return [ {
                key: "id",
                label: React_Basic_DOM.text("Id"),
                cell: TacoTable_Types.PlainTextCell.create((function () {
                    var $60 = Data_Show.show(Data_Show.showInt);
                    var $61 = Data_Newtype.un()(Roles_Types.UserRoleId);
                    return function ($62) {
                        return $60($61((function (v2) {
                            return v2.id;
                        })($62)));
                    };
                })()),
                sortProperty: new Data_Maybe.Just(ById.value),
                width: new TacoTable_Types.Flex(2),
                headerJSX: Data_Maybe.Nothing.value
            }, {
                key: "name",
                label: React_Basic_DOM.text("Roolin nimi"),
                cell: TacoTable_Types.PlainTextCell.create((function () {
                    var $63 = Data_Newtype.un()(Roles_Types.UserRole);
                    return function ($64) {
                        return (function (v2) {
                            return v2.name;
                        })($63((function (v2) {
                            return v2.role;
                        })($64)));
                    };
                })()),
                sortProperty: new Data_Maybe.Just(ByName.value),
                width: new TacoTable_Types.Flex(6),
                headerJSX: Data_Maybe.Nothing.value
            }, {
                key: "apps",
                label: React_Basic_DOM.text("Sovellukset"),
                cell: TacoTable_Types.JSXCell.create([  ])(function ($65) {
                    return Admin_Utils.appChips((function (row) {
                        return Admin_Utils.userCapabilitiesFromRole(row.role);
                    })($65));
                }),
                sortProperty: new Data_Maybe.Just(ByApps.value),
                width: new TacoTable_Types.Flex(10),
                headerJSX: Data_Maybe.Nothing.value
            }, {
                key: "create",
                label: React_Basic_DOM.text("Hankkeen luontioikeus"),
                cell: TacoTable_Types.PlainTextCell.create(function ($66) {
                    return Admin_Utils.createProjectsCell((function (row) {
                        return Admin_Utils.userCapabilitiesFromRole(row.role);
                    })($66));
                }),
                sortProperty: new Data_Maybe.Just(ByCreate.value),
                width: new TacoTable_Types.Flex(4),
                headerJSX: Data_Maybe.Nothing.value
            }, {
                key: "readable_projects",
                label: React_Basic_DOM.text("Lukuoikeus"),
                cell: TacoTable_Types.JSXCell.create([  ])(function ($67) {
                    return Admin_Utils.readableProjectsCell((function (row) {
                        return Admin_Utils.userCapabilitiesFromRole(row.role);
                    })($67));
                }),
                sortProperty: new Data_Maybe.Just(ByReads.value),
                width: new TacoTable_Types.Flex(5),
                headerJSX: Data_Maybe.Nothing.value
            }, {
                key: "writable_projects",
                label: React_Basic_DOM.text("Muokkausoikeus"),
                cell: TacoTable_Types.JSXCell.create([  ])(function ($68) {
                    return Admin_Utils.writableProjectsCell((function (row) {
                        return Admin_Utils.userCapabilitiesFromRole(row.role);
                    })($68));
                }),
                sortProperty: new Data_Maybe.Just(ByWrites.value),
                width: new TacoTable_Types.Flex(5),
                headerJSX: Data_Maybe.Nothing.value
            }, {
                key: "edit",
                label: React_Basic_DOM.text(""),
                cell: new TacoTable_Types.JSXCell([  ], editButton),
                sortProperty: Data_Maybe.Nothing.value,
                width: new TacoTable_Types.Flex(5),
                headerJSX: Data_Maybe.Nothing.value
            } ];
        })();
        var roleToRow = function (v2) {
            return {
                rowData: v2,
                rowKey: Data_Show.show(Data_Show.showInt)(Data_Newtype.un()(Roles_Types.UserRoleId)(v2.id)),
                onClick: Data_Function["const"](Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit)),
                className: "",
                foldingElement: Data_Maybe.Nothing.value,
                columns: columns
            };
        };
        var closeModal = v1.value1(function (v2) {
            return Data_Maybe.Nothing.value;
        });
        var editUserModal = (function () {
            if (v1.value0 instanceof Data_Maybe.Just) {
                return EditUserRole.modal({
                    roleId: v1.value0.value0.id,
                    role: v1.value0.value0.role,
                    organization: v.organization,
                    dispatch: v.dispatch,
                    onClose: closeModal,
                    onSave: updateRole,
                    isOpen: true
                });
            };
            return React_Basic.empty;
        })();
        var addButton = TacoButton.component()()({
            text: "Luo rooli",
            buttonStyle: TacoButton_Types.Outlined.value,
            onClick: Data_Maybe.Just.create(v1.value1(function (v2) {
                return new Data_Maybe.Just({
                    id: Data_Maybe.Nothing.value,
                    role: Roles_Constants.emptyUserRole
                });
            }))
        });
        return React_Basic_DOM_Generated.div()({
            className: "role-table",
            children: [ TacoText.component()()({
                text: "Roolit",
                variant: new TacoText_Types.Heading(2),
                weight: TacoText_Types.Bold.value,
                gutterBottom: true
            }), addButton, TacoTable.tableSortable(getNameRoleSortProperty)(eqRoleSortProperty)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "role";
                }
            })(Roles_Types.userRoleEq))()({
                reflectSymbol: function () {
                    return "id";
                }
            })(Roles_Types.eqUserRoleId)))(roleTableInstance)(new TacoTable_Types.SortingCriteria(TacoTable_Types.Ascending.value, new Data_Maybe.Just(ById.value), getRoleSortingValue))(Data_Maybe.Nothing.value)({
                rows: Data_Functor.map(Data_Functor.functorArray)(roleToRow)(v.userRoles),
                columns: columns,
                showHeader: true,
                styleSet: roleTableStyleSet
            }), editUserModal ]
        });
    };
});
module.exports = {
    component: component,
    ById: ById,
    ByName: ByName,
    ByApps: ByApps,
    ByCreate: ByCreate,
    ByWrites: ByWrites,
    ByReads: ByReads,
    getRoleSortingValue: getRoleSortingValue,
    roleTableInstance: roleTableInstance,
    roleTableStyleSet: roleTableStyleSet,
    eqRoleSortProperty: eqRoleSortProperty,
    ordRoleSortProperty: ordRoleSortProperty,
    getNameRoleSortProperty: getNameRoleSortProperty
};
