// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Client_WASM = require("../Client.WASM/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Show = require("../Data.Show/index.js");
var RefProjectsTable = require("../RefProjectsTable/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoModal = require("../TacoModal/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var Types_Project = require("../Types.Project/index.js");
var UseLocalStorage = require("../UseLocalStorage/index.js");
var useRefProjects = function (projectId) {
    return function __do() {
        var v = Client_WASM.useEstimationProjects();
        var v1 = Client_WASM.useReferenceSubprojects(projectId)();
        var matchProject = function (id) {
            return Data_Array.find(function (ep) {
                return Data_Foldable.or(Data_Foldable.foldableArray)(Data_HeytingAlgebra.heytingAlgebraBoolean)(Data_Functor.map(Data_Functor.functorArray)(Data_Eq.eq(Types_Project.eqSubprojectId)(id))(Data_Functor.map(Data_Functor.functorArray)(function (v2) {
                    return v2.id;
                })(ep.subprojects)));
            })(v.value0);
        };
        var refProjects = Data_Array.nubBy(Data_Function.on(Data_Ord.compare(Types_Project.ordProjectId))(function (v2) {
            return v2.id;
        }))(Data_Array.catMaybes(Data_Functor.map(Data_Functor.functorArray)(Data_Functor.map(Data_Functor.functorFn)(matchProject)(function (v2) {
            return v2.id;
        }))(v1.value0)));
        var localStorageKey = "selectedRefProjects-project-" + Data_Show.show(Types_Project.showProjectId)(projectId);
        var initialValueLoading = v.value1 || v1.value1;
        var initialValue = Data_Functor.map(Data_Functor.functorArray)(function (v2) {
            return v2.id;
        })(refProjects);
        var v2 = UseLocalStorage.useLocalStorageJSON(TofuJSON.readArray(Types_Project.tofuJSONProjectId))(localStorageKey)(initialValue)(initialValueLoading)();
        Client_WASM.useSetActiveRefProjects(v2.value0)();
        var setSelectedRefProjects = function (id) {
            return v2.value1(function (prevRefProjects) {
                var $13 = Data_Array.elem(Types_Project.eqProjectId)(id)(prevRefProjects);
                if ($13) {
                    return Data_Array["delete"](Types_Project.eqProjectId)(id)(prevRefProjects);
                };
                return Data_Array.insert(Types_Project.ordProjectId)(id)(prevRefProjects);
            });
        };
        var refProjectsTable$prime = RefProjectsTable.refProjectsTable({
            refProjects: refProjects,
            selectedRefProjects: v2.value0,
            setSelectedRefProjects: setSelectedRefProjects,
            estimationProjects: v.value0,
            projectId: projectId,
            autoCompleteFirst: true,
            containerFlex: true,
            hideHeader: true
        });
        var v3 = TofuHooks.useState(false)();
        var openModal = v3.value1(function (v4) {
            return true;
        });
        var refProjectsModalButton = TacoButton.component()()({
            onClick: new Data_Maybe.Just(openModal),
            text: "Valitse viitehanke",
            testId: "select-ref-projects-button"
        });
        var modalBody = TacoModal.modalBody({
            contents: [ Box.box("__RefProjectsTableModalBox")([ new Box.Height(new TacoConstants.CustomSize("40rem")), new Box.Width(new TacoConstants.CustomSize("100%")) ])([ refProjectsTable$prime ]) ]
        });
        var emptySelectedRefProjectsButton = Box.box("empty-selected-ref-projects-message")([ Box.AlignCenter.value, Box.JustifyCenter.value, Box.Width.create(new TacoConstants.CustomSize("100%")) ])([ refProjectsModalButton ]);
        var closeModal = v3.value1(function (v4) {
            return false;
        });
        var modalActions = TacoModal.modalActions({
            contents: [ TacoButton.component()()({
                onClick: new Data_Maybe.Just(closeModal),
                text: "Peruuta",
                testId: "close-ref-projects-modal-button"
            }) ]
        });
        var refProjectsModal = TacoModal.component()()({
            onClose: closeModal,
            isActive: v3.value0,
            heading: "Viitehankkeet",
            contents: [ modalBody, modalActions ]
        });
        return {
            refProjectsTable: refProjectsTable$prime,
            selectedRefProjects: v2.value0,
            refProjectsModal: refProjectsModal,
            refProjectsModalButton: refProjectsModalButton,
            emptySelectedRefProjectsButton: emptySelectedRefProjectsButton
        };
    };
};
module.exports = {
    useRefProjects: useRefProjects
};
