import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Input, Text } from "@tocoman/ui";
import { StringCombobox } from "../../../components/StringCombobox";
import { useCallback, useState } from "react";
import {
  AdjustPricingParams,
  useAdjustPricingQuery,
} from "./hooks/useAdjustPricing";
import { formatNumberToLocaleString } from "../../../utils/formatting";
import { useDefault } from "../../../hooks/useDefault";
import { useWorkerState } from "../../../hooks/useWorkerState";
import { useModalActionButtons } from "./hooks/useModalActionButtons";
import { useRunOnce } from "../../../hooks/useRunOnce";
import { reportError } from "../../../utils/error";

type AdjustPricingProps = {
  projectId: number;
  componentIds: number[];
  onSave: () => void;
  closeModal: () => void;
  setActionButtons: (actions: JSX.Element) => void;
};

type AdjustPricingForm = {
  priceMultiplier: number;
  multiplierCostClass: string;
};

export const AdjustPricingTab = ({
  projectId,
  componentIds,
  onSave,
  closeModal,
  setActionButtons,
}: AdjustPricingProps) => {
  const { t } = useTranslation("estimation", {
    keyPrefix: "componentView.editMultipleComponentsModal",
  });

  const costClasses = useDefault(
    useWorkerState("EstimationCostClassesState"),
    []
  );
  const { register, control, watch, setValue, handleSubmit } = useForm<
    AdjustPricingForm
  >();

  const costClassItems = costClasses.map(
    (costClass) => `${costClass.costClassCode}: ${costClass.name}`
  );

  const { mutateAsync: submitPricingAdjustment } = useAdjustPricingQuery();

  const handleDifference = useCallback(
    async (multiplier: number) => {
      const adjustPricingData: AdjustPricingParams = {
        projectId: projectId,
        componentIds: componentIds,
        costClassCode: watch("multiplierCostClass").split(": ")[0],
        dryRun: true,
        priceMultiplier: Number(multiplier),
      };
      try {
        const result = await submitPricingAdjustment(adjustPricingData);
        setDifference(result.priceDifference);
      } catch (e) {
        reportError("Error while calculating price adjustment", e as Error);
      }
    },
    [componentIds, projectId, submitPricingAdjustment, watch]
  );

  const handleSave = async (data: AdjustPricingForm) => {
    try {
      await submitPricingAdjustment({
        projectId: projectId,
        componentIds: componentIds,
        costClassCode: data.multiplierCostClass.split(": ")[0],
        dryRun: false,
        priceMultiplier: Number(data.priceMultiplier),
      });
      onSave();
    } catch (e) {
      reportError("Error while submitting price adjustment", e as Error);
    }
  };

  const [difference, setDifference] = useState(0);

  let differenceString = formatNumberToLocaleString(difference, 2);
  if (difference <= 0) {
    differenceString = `${differenceString}`;
  }
  if (difference > 0) {
    differenceString = `+${differenceString}`;
  }

  const setActions = useModalActionButtons(
    setActionButtons,
    handleSubmit(handleSave),
    closeModal
  );

  useRunOnce(() => {
    setActions(false);
  });

  return (
    <>
      {t`form.multiplierDescription`}

      <div className={"w-[300px]"}>
        <Controller
          control={control}
          name={"multiplierCostClass"}
          defaultValue={costClassItems[0]}
          render={({ field: { onChange, value } }) => (
            <StringCombobox
              label={t`form.costClass`}
              items={costClassItems}
              onValueChange={(value) => {
                setDifference(0);
                setValue("priceMultiplier", 1);
                onChange(value);
              }}
              initialValue={value ?? costClassItems[0]}
            />
          )}
        />

        <Text text={t`form.multiplier`} className={"text-dark mt-1"} />
        <Input
          {...register("priceMultiplier", {
            onChange: (e) => handleDifference(e.target.value),
          })}
          testId={"price-multiplies-input"}
          className={"w-full"}
          defaultValue={1.0}
          type={"number"}
        />

        <Text text={t`form.difference`} className={"text-dark mt-3"} />
        <Text
          text={differenceString}
          className={
            difference === 0
              ? "text-gray"
              : difference > 0
              ? "text-green-600"
              : "text-red-600"
          }
          testId={"difference-value-text"}
        />
      </div>
    </>
  );
};
