// Generated by purs version 0.14.5
"use strict";
var Data_Maybe = require("../Data.Maybe/index.js");
var SeparatorSubHeading = require("../SeparatorSubHeading/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var example2 = "import SeparatorSubHeading as SeparatorSubHeading\x0aimport TacoConstants (componentColors)\x0a\x0aSeparatorSubHeading.component\x0a  { colorSet: Just componentColors\x0a  , quantity: 123\x0a  , title: \"Components\"\x0a  }";
var example1 = "import SeparatorSubHeading as SeparatorSubHeading\x0a\x0aSeparatorSubHeading.component\x0a  { colorSet: Nothing\x0a  , quantity: 42\x0a  , title: \"Building elements\"\x0a  }";
var sections = [ {
    title: "Default",
    view: [ SeparatorSubHeading.component({
        colorSet: Data_Maybe.Nothing.value,
        quantity: 42,
        title: "Building elements"
    }) ],
    code: example1
}, {
    title: "With bottom left border",
    view: [ SeparatorSubHeading.component({
        colorSet: new Data_Maybe.Just(TacoConstants.componentColors),
        quantity: 123,
        title: "Components"
    }) ],
    code: example2
} ];
var demoView = {
    sections: sections,
    title: "SeparatorSubHeading",
    description: "Separator between non top-level sections"
};
module.exports = {
    demoView: demoView
};
