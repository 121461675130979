// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var StyleProperties = require("../StyleProperties/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var uiDemoViewStyles = Box.boxStyle([ new Box.Padding(TacoConstants.M.value) ]);
var uiDemoViewContainerStyles = Box.boxStyle([ new Box.FlexGrow(1), Box.Height.create(new TacoConstants.CustomSize("100vh")), new Box.Padding(TacoConstants.M.value), new Box.Overflow(Box.Auto.value, Box.Auto.value) ]);
var uiDemoSectionsStyles = Box.boxStyle([ new Box.PaddingY(TacoConstants.M.value) ]);
var uiDemoSectionStyles = Box.boxStyle([ new Box.Border(TacoConstants.BorderLight.value) ]);
var uiDemoLayoutStyles = Box.boxStyle([ Box.FlexRow.value ]);
var uiDemoCodeStyles = Box.boxStyle([ new Box.BackgroundColor(TacoConstants.GrayLightest.value), Box.Style.create(React_Basic_Emotion.css()({
    borderTop: StyleProperties.border(TacoConstants.BorderLight.value)
})), new Box.Padding(TacoConstants.M.value), Box.Margin.create(new TacoConstants.CustomSize("0")) ]);
module.exports = {
    uiDemoLayoutStyles: uiDemoLayoutStyles,
    uiDemoViewContainerStyles: uiDemoViewContainerStyles,
    uiDemoSectionsStyles: uiDemoSectionsStyles,
    uiDemoSectionStyles: uiDemoSectionStyles,
    uiDemoViewStyles: uiDemoViewStyles,
    uiDemoCodeStyles: uiDemoCodeStyles
};
