// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var textStyles = function (props) {
    var variantSpecificStyles = (function () {
        if (props.variant instanceof TacoText_Types.Label) {
            return [ new Box.Display(Box.Block.value), new Box.PaddingX(TacoConstants.XS.value), new Box.PaddingY(TacoConstants.XXS.value), Box.MinHeight.create(new TacoConstants.CustomSize("1.2em")), Box.Style.create(React_Basic_Emotion.css()({
                boxSizing: React_Basic_Emotion.str("content-box")
            })) ];
        };
        if (props.variant instanceof TacoText_Types.Heading) {
            var headingFontSize = (function () {
                if (props.variant.value0 === 2) {
                    return "1.2rem";
                };
                return "1rem";
            })();
            return [ new Box.Display(Box.Block.value), Box.Style.create(React_Basic_Emotion.css()({
                fontWeight: React_Basic_Emotion.str("bold"),
                fontSize: React_Basic_Emotion.str(headingFontSize)
            })) ];
        };
        return [  ];
    })();
    var overflowStyles = (function () {
        if (props.overflow instanceof TacoText_Types.TextOverflowVisible) {
            return [  ];
        };
        if (props.overflow instanceof TacoText_Types.TextOverflowEllipsis) {
            return [ Box.Style.create(React_Basic_Emotion.css()({
                overflow: React_Basic_Emotion.str("hidden"),
                textOverflow: React_Basic_Emotion.str("ellipsis"),
                whiteSpace: React_Basic_Emotion.str("nowrap")
            })) ];
        };
        throw new Error("Failed pattern match at TacoText.Styles (line 60, column 22 - line 68, column 10): " + [ props.overflow.constructor.name ]);
    })();
    return Box.boxStyle(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ Data_Monoid.guard(Box.monoidBoxProp)(props.gutter)(new Box.MarginBottom(TacoConstants.M.value)), Box.Style.create(React_Basic_Emotion.css()({
        fontWeight: React_Basic_Emotion.str(TacoText_Types.weightToStr(props.weight)),
        fontSize: React_Basic_Emotion.str(TacoConstants.sizeStr(props.size)),
        textTransform: React_Basic_Emotion.str(TacoText_Types.textTransformToStr(props.transform)),
        textAlign: React_Basic_Emotion.str(TacoText_Types.textAlignToStr(props.align)),
        color: React_Basic_Emotion.str(props.color),
        backgroundColor: React_Basic_Emotion.str(props.backgroundColor),
        whiteSpace: React_Basic_Emotion.str(TacoText_Types.textWrapToStr(props.wrap))
    })) ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(variantSpecificStyles)(overflowStyles)));
};
module.exports = {
    textStyles: textStyles
};
