// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Effect = require("../Effect/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var TacoCheckbox = require("../TacoCheckbox/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var component = TofuHooks.mkHookComponent("SocialExpensesControls")(function (props) {
    return Control_Applicative.pure(Effect.applicativeEffect)(ReactBasicUtils["div'"]("__SocialExpensesControls")([ TacoCheckbox.component()()({
        className: "toggle-show-prices-with-social-expenses",
        isChecked: props.isSocialExpensesToggled,
        onToggle: (function () {
            if (props.disabled instanceof Data_Maybe.Just) {
                return new TacoCheckbox.Disabled(props.disabled.value0);
            };
            if (props.disabled instanceof Data_Maybe.Nothing) {
                return new TacoCheckbox.NoEvent(props.onToggle);
            };
            throw new Error("Failed pattern match at SocialExpensesControls (line 26, column 23 - line 28, column 61): " + [ props.disabled.constructor.name ]);
        })(),
        labelText: Data_Maybe.Just.create(TacoCheckbox.StringLabel.create("N\xe4yt\xe4 hinnat sosiaalikuluineen")),
        labelTextWeight: (function () {
            if (props.isSocialExpensesToggled) {
                return TacoText_Types.Bold.value;
            };
            return TacoText_Types.Normal.value;
        })(),
        testId: "toggle-show-prices-with-social-expenses"
    }) ]));
});
module.exports = {
    component: component
};
