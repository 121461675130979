// Generated by purs version 0.14.5
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var Kishimen = require("../Kishimen/index.js");
var ReadParam = require("../ReadParam/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var MigratePurchaseInvoices = (function () {
    function MigratePurchaseInvoices() {

    };
    MigratePurchaseInvoices.value = new MigratePurchaseInvoices();
    return MigratePurchaseInvoices;
})();
var MigrateSalesInvoices = (function () {
    function MigrateSalesInvoices() {

    };
    MigrateSalesInvoices.value = new MigrateSalesInvoices();
    return MigrateSalesInvoices;
})();
var MigrateInvoices = function (x) {
    return x;
};
var Invoice = (function () {
    function Invoice() {

    };
    Invoice.value = new Invoice();
    return Invoice;
})();
var Salary = (function () {
    function Salary() {

    };
    Salary.value = new Salary();
    return Salary;
})();
var Unknown = (function () {
    function Unknown() {

    };
    Unknown.value = new Unknown();
    return Unknown;
})();
var InvoiceLineId = function (x) {
    return x;
};
var InvoiceId = function (x) {
    return x;
};
var InvoiceBunchId = function (x) {
    return x;
};
var writeParamInvoiceRowId = ReadParam.writeParamInt;
var writeParamInvoiceId = ReadParam.writeParamInt;
var writeParamInvoiceBunchId = ReadParam.writeParamString;
var tofuJSONInvoiceRowId = TofuJSON.readInt;
var tofuJSONInvoiceId = TofuJSON.readInt;
var tofuJSONInvoiceBunchId = TofuJSON.readString;
var showInvoiceType = {
    show: function (v) {
        if (v instanceof Invoice) {
            return "Invoice";
        };
        if (v instanceof Salary) {
            return "Salary";
        };
        if (v instanceof Unknown) {
            return "Unknown";
        };
        throw new Error("Failed pattern match at Types.Invoice (line 341, column 1 - line 344, column 27): " + [ v.constructor.name ]);
    }
};
var showInvoiceRowId = Data_Show.showInt;
var showInvoiceId = Data_Show.showInt;
var showInvoiceBunchId = Data_Show.showString;
var readParamInvoiceRowId = ReadParam.intReadParam;
var readParamInvoiceId = ReadParam.intReadParam;
var readParamInvoiceBunchId = ReadParam.stringReadParam;
var ordInvoiceRowId = Data_Ord.ordInt;
var ordInvoiceId = Data_Ord.ordInt;
var ordInvoiceBunchId = Data_Ord.ordString;
var newtypeInvoiceRowId = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeInvoiceId = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeInvoiceBunchId = {
    Coercible0: function () {
        return undefined;
    }
};
var genericMigrateInvoicesProperty = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return MigratePurchaseInvoices.value;
        };
        if (x instanceof Data_Generic_Rep.Inr) {
            return MigrateSalesInvoices.value;
        };
        throw new Error("Failed pattern match at Types.Invoice (line 352, column 1 - line 352, column 84): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof MigratePurchaseInvoices) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof MigrateSalesInvoices) {
            return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
        };
        throw new Error("Failed pattern match at Types.Invoice (line 352, column 1 - line 352, column 84): " + [ x.constructor.name ]);
    }
};
var tofuJSONMigrateInvoicesProperty = {
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "MigratePurchaseInvoices";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "MigrateSalesInvoices";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "MigratePurchaseInvoices";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "MigrateSalesInvoices";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))(genericMigrateInvoicesProperty)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "MigratePurchaseInvoices";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "MigrateSalesInvoices";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "MigratePurchaseInvoices";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "MigrateSalesInvoices";
        }
    }))),
    readImpl: TofuJSON.readGenericSum(genericMigrateInvoicesProperty)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "MigratePurchaseInvoices";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "MigrateSalesInvoices";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "MigratePurchaseInvoices";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "MigrateSalesInvoices";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "MigratePurchaseInvoices";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "MigrateSalesInvoices";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil)))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "MigratePurchaseInvoices";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "MigrateSalesInvoices";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))
};
var tofuJSONMigrateInvoices = TofuJSON.readRecord()(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "ids";
    }
})(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.readFieldsCons({
    reflectSymbol: function () {
        return "property";
    }
})(tofuJSONMigrateInvoicesProperty)(TofuJSON.readFieldsNil)()())()())(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "ids";
    }
})(TofuJSON.readArray(TofuJSON.readInt))(TofuJSON.consWriteForeignFields({
    reflectSymbol: function () {
        return "property";
    }
})(tofuJSONMigrateInvoicesProperty)(TofuJSON.nilWriteForeignFields)()()())()()());
var genericInvoiceType = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return Invoice.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return Salary.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
            return Unknown.value;
        };
        throw new Error("Failed pattern match at Types.Invoice (line 347, column 1 - line 347, column 60): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof Invoice) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof Salary) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
        };
        if (x instanceof Unknown) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value));
        };
        throw new Error("Failed pattern match at Types.Invoice (line 347, column 1 - line 347, column 60): " + [ x.constructor.name ]);
    }
};
var tofuJSONInvoiceType = {
    readImpl: TofuJSON.readGenericSum(genericInvoiceType)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "Invoice";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "Salary";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "Unknown";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "Salary";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "Unknown";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "Invoice";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "Unknown";
        }
    }))({
        reflectSymbol: function () {
            return "Salary";
        }
    })))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "Invoice";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "Salary";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "Unknown";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "Invoice";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "Salary";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "Unknown";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))),
    writeImpl: TofuJSON.writeGenericSum()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "Invoice";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "Salary";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
        reflectSymbol: function () {
            return "Unknown";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil))))(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "Invoice";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "Salary";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
        reflectSymbol: function () {
            return "Unknown";
        }
    })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant))))(genericInvoiceType)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "Invoice";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "Salary";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
        reflectSymbol: function () {
            return "Unknown";
        }
    })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "Salary";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "Unknown";
        }
    })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "Invoice";
        }
    }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
        reflectSymbol: function () {
            return "Unknown";
        }
    }))({
        reflectSymbol: function () {
            return "Salary";
        }
    })))
};
var eqMigrateInvoicesProperty = {
    eq: function (x) {
        return function (y) {
            if (x instanceof MigratePurchaseInvoices && y instanceof MigratePurchaseInvoices) {
                return true;
            };
            if (x instanceof MigrateSalesInvoices && y instanceof MigrateSalesInvoices) {
                return true;
            };
            return false;
        };
    }
};
var eqMigrateInvoices = {
    eq: function (x) {
        return function (y) {
            return Data_Eq.eq(Data_Eq.eqArray(Data_Eq.eqInt))(x.ids)(y.ids) && Data_Eq.eq(eqMigrateInvoicesProperty)(x.property)(y.property);
        };
    }
};
var eqInvoiceType = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Invoice && y instanceof Invoice) {
                return true;
            };
            if (x instanceof Salary && y instanceof Salary) {
                return true;
            };
            if (x instanceof Unknown && y instanceof Unknown) {
                return true;
            };
            return false;
        };
    }
};
var ordInvoiceType = {
    compare: function (x) {
        return function (y) {
            if (x instanceof Invoice && y instanceof Invoice) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Invoice) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Invoice) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Salary && y instanceof Salary) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof Salary) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof Salary) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof Unknown && y instanceof Unknown) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at Types.Invoice (line 346, column 1 - line 346, column 50): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqInvoiceType;
    }
};
var eqInvoiceRowId = Data_Eq.eqInt;
var eqInvoiceId = Data_Eq.eqInt;
var eqInvoiceBunchId = Data_Eq.eqString;
module.exports = {
    InvoiceBunchId: InvoiceBunchId,
    InvoiceId: InvoiceId,
    InvoiceLineId: InvoiceLineId,
    Invoice: Invoice,
    Salary: Salary,
    Unknown: Unknown,
    MigratePurchaseInvoices: MigratePurchaseInvoices,
    MigrateSalesInvoices: MigrateSalesInvoices,
    MigrateInvoices: MigrateInvoices,
    eqMigrateInvoicesProperty: eqMigrateInvoicesProperty,
    eqMigrateInvoices: eqMigrateInvoices,
    newtypeInvoiceBunchId: newtypeInvoiceBunchId,
    readParamInvoiceBunchId: readParamInvoiceBunchId,
    writeParamInvoiceBunchId: writeParamInvoiceBunchId,
    tofuJSONInvoiceBunchId: tofuJSONInvoiceBunchId,
    showInvoiceBunchId: showInvoiceBunchId,
    eqInvoiceBunchId: eqInvoiceBunchId,
    ordInvoiceBunchId: ordInvoiceBunchId,
    newtypeInvoiceId: newtypeInvoiceId,
    readParamInvoiceId: readParamInvoiceId,
    writeParamInvoiceId: writeParamInvoiceId,
    tofuJSONInvoiceId: tofuJSONInvoiceId,
    showInvoiceId: showInvoiceId,
    eqInvoiceId: eqInvoiceId,
    ordInvoiceId: ordInvoiceId,
    newtypeInvoiceRowId: newtypeInvoiceRowId,
    readParamInvoiceRowId: readParamInvoiceRowId,
    writeParamInvoiceRowId: writeParamInvoiceRowId,
    tofuJSONInvoiceRowId: tofuJSONInvoiceRowId,
    showInvoiceRowId: showInvoiceRowId,
    eqInvoiceRowId: eqInvoiceRowId,
    ordInvoiceRowId: ordInvoiceRowId,
    showInvoiceType: showInvoiceType,
    eqInvoiceType: eqInvoiceType,
    ordInvoiceType: ordInvoiceType,
    genericInvoiceType: genericInvoiceType,
    tofuJSONInvoiceType: tofuJSONInvoiceType,
    genericMigrateInvoicesProperty: genericMigrateInvoicesProperty,
    tofuJSONMigrateInvoicesProperty: tofuJSONMigrateInvoicesProperty,
    tofuJSONMigrateInvoices: tofuJSONMigrateInvoices
};
