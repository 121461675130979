exports._createFileDownload = function (response, filename) {
  return function () {
    return response.blob().then(function (blob) {
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.style = "display: none";
      a.href = url;
      a.download = filename;
      document.body.append(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    });
  };
};
