// Generated by purs version 0.14.5
"use strict";
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var hasNonCostClassPriceResourceForCode = function (v) {
    return function (code) {
        var isNonCostClassPriceForCode = function (resource) {
            return Data_Eq.eq(Data_Nullable.eqNullable(Types_Newtypes.eqCostClassCode))(resource.costClassCode)(Data_Nullable.toNullable(new Data_Maybe.Just(code))) && !resource.isCostClassPrice;
        };
        return Data_Array.any(isNonCostClassPriceForCode)(v.resources);
    };
};
var getECWRId = (function () {
    var $7 = Data_Newtype.unwrap();
    return function ($8) {
        return (function (v) {
            return v.component.id;
        })($7($8));
    };
})();
var getCostClassPriceResourceForCode = function (v) {
    return function (code) {
        var isCostClassPriceForCode = function (resource) {
            return Data_Eq.eq(Data_Nullable.eqNullable(Types_Newtypes.eqCostClassCode))(resource.costClassCode)(Data_Nullable.toNullable(new Data_Maybe.Just(code))) && resource.isCostClassPrice;
        };
        return Data_Array.find(isCostClassPriceForCode)(v.resources);
    };
};
module.exports = {
    getECWRId: getECWRId,
    hasNonCostClassPriceResourceForCode: hasNonCostClassPriceResourceForCode,
    getCostClassPriceResourceForCode: getCostClassPriceResourceForCode
};
