// Generated by purs version 0.14.5
"use strict";
var $foreign = require("./foreign.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");
var writeRef = $foreign.writeRef_;
var useState$prime = function (initialState) {
    return $foreign.useStateEffect_(Data_Tuple.Tuple.create)(initialState);
};
var useState = function (initialState) {
    return $foreign.useState_(Data_Tuple.Tuple.create)(initialState);
};
var useRef = $foreign.useRef_;
var useMemo = $foreign.useMemo_;
var useLayoutEffect = $foreign.useLayoutEffect_;
var useEffectTimeout = $foreign.useEffectTimeout_;
var useEffect = $foreign.useEffect_;
var useContext = $foreign.useContext_;
var useCallback = $foreign.useCallback_;
var unsafeDiscardRenderEffects = Unsafe_Coerce.unsafeCoerce;
var readRef = $foreign.readRef_;
var mkMemoHookComponent = $foreign.mkHookComponent_(true);
var mkHookComponent = $foreign.mkHookComponent_(false);
var getNameVoid = {
    getName: function (v) {
        return "Void";
    }
};
var getName = function (dict) {
    return dict.getName;
};
module.exports = {
    getName: getName,
    mkHookComponent: mkHookComponent,
    mkMemoHookComponent: mkMemoHookComponent,
    useState: useState,
    "useState'": useState$prime,
    useEffect: useEffect,
    useEffectTimeout: useEffectTimeout,
    useLayoutEffect: useLayoutEffect,
    useCallback: useCallback,
    useMemo: useMemo,
    useRef: useRef,
    writeRef: writeRef,
    readRef: readRef,
    useContext: useContext,
    unsafeDiscardRenderEffects: unsafeDiscardRenderEffects,
    getNameVoid: getNameVoid,
    mkHookComponent_: $foreign.mkHookComponent_,
    useState_: $foreign.useState_,
    useStateEffect_: $foreign.useStateEffect_,
    useEffect_: $foreign.useEffect_,
    useEffectTimeout_: $foreign.useEffectTimeout_,
    useLayoutEffect_: $foreign.useLayoutEffect_,
    useCallback_: $foreign.useCallback_,
    useMemo_: $foreign.useMemo_,
    useRef_: $foreign.useRef_,
    writeRef_: $foreign.writeRef_,
    readRef_: $foreign.readRef_,
    useContext_: $foreign.useContext_
};
