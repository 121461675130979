// eslint-disable-next-line no-restricted-imports
import axios from "axios";
import { getAccessToken } from "./auth/accessToken";

/**
 * This is a base URL to be used in unit tests.
 */
export const testBaseURL = "http://localhost/api";

/**
 * This base URL is used in production and development.
 *
 * The /api endpoint is in the same domain as the frontend and needs to be
 * proxied to the backend for this to work.
 */
const proxiedBaseURL = "/api";

const baseURL = process.env.NODE_ENV === "test" ? testBaseURL : proxiedBaseURL;
const axiosClient = axios.create({
  baseURL,
});

axiosClient.interceptors.request.use(async (config) => {
  if (process.env.NODE_ENV === "test") {
    return config;
  }

  config.headers.Authorization = await getAccessToken();
  return config;
});

export default axiosClient;
