// Generated by purs version 0.14.5
"use strict";
var ErrorPages_Styles = require("../ErrorPages.Styles/index.js");
var Locale = require("../Locale/index.js");
var NarrowLayout = require("../NarrowLayout/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Project = require("../Types.Project/index.js");
var component = TofuHooks.mkHookComponent("Unauthorized")(function (props) {
    return function __do() {
        StateHooks.useDispatch();
        var modeStr = (function () {
            if (props.applicationMode instanceof Types_Project.ReportingMode) {
                return Locale.lookup_("mode_name_reporting");
            };
            if (props.applicationMode instanceof Types_Project.EstimationMode) {
                return Locale.lookup_("mode_name_estimation");
            };
            if (props.applicationMode instanceof Types_Project.IntegrationMode) {
                return Locale.lookup_("mode_name_integration");
            };
            if (props.applicationMode instanceof Types_Project.OrganizationMode) {
                return Locale.lookup_("mode_name_organization");
            };
            if (props.applicationMode instanceof Types_Project.AdminMode) {
                return Locale.lookup_("mode_name_admin");
            };
            if (props.applicationMode instanceof Types_Project.SuperAdminMode) {
                return Locale.lookup_("mode_name_superadmin");
            };
            if (props.applicationMode instanceof Types_Project.EtcMode) {
                return Locale.lookup_("mode_name_etc");
            };
            throw new Error("Failed pattern match at Unauthorized (line 28, column 7 - line 35, column 50): " + [ props.applicationMode.constructor.name ]);
        })();
        var heading = "Ei oikeuksia: " + modeStr;
        var msgBox = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "__Unauthorized",
            css: ErrorPages_Styles.unauthorizedContainerStyles,
            children: [ TacoText.component()()({
                size: TacoConstants.L.value,
                variant: new TacoText_Types.Heading(2),
                text: "\u26d4\ufe0f",
                gutterBottom: true
            }), TacoText.component()()({
                size: TacoConstants.L.value,
                weight: TacoText_Types.Bold.value,
                variant: new TacoText_Types.Heading(2),
                text: heading,
                gutterBottom: true
            }), TacoText.component()()({
                text: "Sinulla ei ole oikeuksia k\xe4ytt\xe4\xe4 t\xe4t\xe4 osaa ohjelmasta. Pyyd\xe4 oikeudet organisaatiosi p\xe4\xe4k\xe4ytt\xe4j\xe4lt\xe4.",
                variant: TacoText_Types.Paragraph.value,
                gutterBottom: true
            }) ]
        });
        return NarrowLayout.component()()({
            verticalPadding: true,
            children: [ msgBox ]
        });
    };
});
module.exports = {
    component: component
};
