import { Checkbox, Header } from "@tocoman/ui";
import { Trans, useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import { User } from "../../../../../ts-bindings/User";
import { TabIds, UserRolesData } from "./EditUserRolesModal";
import { PermissionAccordion } from "./PermissionAccordion";
import { useCallback } from "react";
import { LicenseFeatureContainer } from "../../../LicenseFeatures/LicenseFeatureContainer";
import { LicenseFeature } from "../../../LicenseFeatures/useLicenseFeature";

type PermissionTypeContainerProps = {
  application: TabIds;
  disabled: boolean;
  user: User | null;
};

export const PermissionTypeContainers = ({
  application,
  disabled,
  user,
}: PermissionTypeContainerProps) => {
  const keyPrefix = `users.edit.form.${application}`;
  const { t } = useTranslation("admin", {
    keyPrefix,
  });

  const { control, watch, setValue, getValues } = useFormContext<
    UserRolesData
  >();
  const watchAppPermission = watch(`${application}.appPermission`);

  const getProjectAndProjectGroupRows = useCallback(() => {
    const projects = getValues(`${application}.project`);
    const projectGroups = getValues(`${application}.projectGroup`);
    return { projects, projectGroups };
  }, [application, getValues]);

  // These functions will set all read/write permissions to false when the read/write all checkbox is unchecked
  const denyAllReadPermissions = useCallback(() => {
    const { projects, projectGroups } = getProjectAndProjectGroupRows();
    projectGroups.forEach((_, index) => {
      setValue(`${application}.projectGroup.${index}.writePermission`, false);
      setValue(`${application}.projectGroup.${index}.readPermission`, false);
    });

    projects.forEach((_, index) => {
      setValue(`${application}.project.${index}.writePermission`, false);
      setValue(`${application}.project.${index}.readPermission`, false);
    });
  }, [application, getProjectAndProjectGroupRows, setValue]);

  const denyAllWritePermissions = useCallback(() => {
    const { projects, projectGroups } = getProjectAndProjectGroupRows();
    projectGroups.forEach((_, index) => {
      setValue(`${application}.projectGroup.${index}.writePermission`, false);
    });
    projects.forEach((_, index) => {
      setValue(`${application}.project.${index}.writePermission`, false);
    });
  }, [application, getProjectAndProjectGroupRows, setValue]);

  return (
    <>
      <div className="flex flex-col">
        <Header titleSize="medium">
          <Trans
            t={t}
            i18nKey={`description`}
            values={{ user: user?.userData.email }}
          />
        </Header>
        <Controller
          control={control}
          name={`${application}.appPermission`}
          render={({ field: { value, onChange } }) => (
            <Checkbox
              disabled={disabled}
              checked={value}
              setChecked={onChange}
              label={t`appPermission`}
            />
          )}
        />
        <div className="h-4" />

        <Header titleSize="medium">{t`writePermissionLicenseDisclaimer`}</Header>
        <div className="flex flex-col w-full gap-4">
          <Controller
            control={control}
            name={`${application}.readAll`}
            render={({ field: { value, onChange } }) => (
              <Checkbox
                disabled={!watchAppPermission}
                checked={value}
                setChecked={(checked) => {
                  onChange(checked);
                  if (!checked) {
                    setValue(`${application}.writeAll`, false);
                    denyAllReadPermissions();
                  }
                }}
                label={t`readAll`}
              />
            )}
          />
          <Controller
            control={control}
            name={`${application}.writeAll`}
            render={({ field: { value, onChange } }) => (
              <Checkbox
                disabled={!watchAppPermission}
                checked={value}
                setChecked={(checked) => {
                  onChange(checked);
                  if (checked) {
                    setValue(`${application}.readAll`, true);
                  } else {
                    denyAllWritePermissions();
                  }
                }}
                label={t`writeAll`}
              />
            )}
          />
          <LicenseFeatureContainer feature={LicenseFeature.USER_ROLES}>
            <PermissionAccordion user={user} application={application} />
          </LicenseFeatureContainer>
        </div>
      </div>
    </>
  );
};
