const packageJson = require("../../package.json");

exports.clientVersion = packageJson.version;

// a mutable object of GET requests we have in progress
window.requestsInProgress = {};
exports.requestsInProgress = window.requestsInProgress;

exports.addRequestInProgress_ = function (url) {
  return function () {
    window.requestsInProgress[url] = true;
  };
};

exports.removeRequestInProgress_ = function (url) {
  return function () {
    delete window.requestsInProgress[url];
  };
};

exports.isMemberRequestInProgress_ = function (url) {
  return function () {
    // eslint-disable-next-line no-prototype-builtins
    return window.requestsInProgress.hasOwnProperty(url);
  };
};
