import { useWorkerState } from "../../../hooks/useWorkerState";
import { useMemo } from "react";
import { FullProjectData } from "./Report";

export const useProjectFullData = (projectId: number): FullProjectData => {
  const componentsClassData = useWorkerState(
    "ProjectClassificationClassesState",
    projectId
  );
  const componentsWithResourcesData = useWorkerState(
    "ComponentsWithResourcesState",
    projectId
  );
  const subProjects = useWorkerState("EstimationSubprojectsState", projectId);
  const projectData = useWorkerState("ProjectDetailsDataState", projectId);

  const locations = useWorkerState("ProjectLocationsState", projectId);
  const costClasses = useWorkerState("EstimationCostClassesState", projectId);

  const elements = useWorkerState("ElementsDataState", projectId);

  const measurements = useWorkerState("MeasurementsState", projectId);

  const allDataReady =
    componentsWithResourcesData !== null &&
    componentsClassData !== null &&
    projectData !== null &&
    subProjects !== null &&
    locations !== null &&
    costClasses !== null &&
    elements !== null &&
    measurements !== null;

  return useMemo(() => {
    if (!allDataReady) {
      return null;
    }

    const fullReportData: FullProjectData = {
      componentsClassData,
      componentsWithResourcesData,
      projectData,
      subProjects,
      locations,
      costClasses,
      elements,
      measurements,
    };
    return fullReportData;
  }, [
    allDataReady,
    componentsClassData,
    componentsWithResourcesData,
    projectData,
    subProjects,
    locations,
    costClasses,
    elements,
    measurements,
  ]);
};
