import { useState } from "react";
import { PricelistUpdateForm } from "../PricelistUpdate/PricelistUpdateForm";
import { PricelistUpdateStatus } from "../PricelistUpdate/PricelistUpdateStatus";
import { IconCheck24Px, Notification } from "@tocoman/ui";
import { useTranslation } from "react-i18next";

export function PricelistUpdateTab() {
  const { t } = useTranslation("superAdmin", { keyPrefix: "pricelist" });
  const [updateStarted, setUpdateStarted] = useState(false);

  const [selectedOrgs, setSelectedOrgs] = useState<string[]>([]);

  return (
    <div className="flex flex-col">
      {updateStarted && (
        <div className="flex flex-col w-full mb-3">
          <Notification
            title={t("pricingUpdateStarted")}
            description={t("pricingUpdateStartedDescription")}
            type={"success"}
            icon={IconCheck24Px}
            onCloseFn={() => setUpdateStarted(false)}
            className={"my-3"}
          />
        </div>
      )}
      <div className="flex flex-row">
        <PricelistUpdateStatus
          onOrgSelectionChange={setSelectedOrgs}
          updateStarted={updateStarted}
        />
        <PricelistUpdateForm
          selectedOrgs={selectedOrgs}
          onUpdateStarted={() => setUpdateStarted(true)}
        />
      </div>
    </div>
  );
}
