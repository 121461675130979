// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var HtmlUtils = require("../HtmlUtils/index.js");
var Locale = require("../Locale/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoDropdown = require("../TacoDropdown/index.js");
var TacoDropdown_Types = require("../TacoDropdown.Types/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var example1 = "import TacoDropdown as TacoDropdown\x0a\x0aTacoDropdown.component\x0a  { sections:\x0a      [ { title: \"Maksuer\xe4t\"\x0a        , items: [ R.text \"item\" ]\x0a        }\x0a      ]\x0a  , text: Nothing\x0a  }";
var dropdownDemo = TofuHooks.mkHookComponent("DropdownDemo")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState(false)();
        var v2 = TofuHooks.useState(Data_Maybe.Nothing.value)();
        return React_Basic_DOM_Generated.div_([ TacoDropdown.component({
            sections: [ {
                title: new Data_Maybe.Just("Maksuer\xe4t"),
                items: [ TacoButton.component()()({
                    text: "hello world",
                    align: TacoButton_Types.Left.value
                }) ]
            } ],
            toggled: v1.value0,
            setToggled: v1.value1,
            align: TacoDropdown_Types.AlignLeft.value,
            anchorEl: v2.value0
        }), TacoButton.component()()({
            onClickEventHandler: Data_Maybe.Just.create(React_Basic_Events.handler(React_Basic_DOM_Events.currentTarget)(function (target) {
                return function __do() {
                    v1.value1(function (s) {
                        return !s;
                    })();
                    return v2.value1(function (v3) {
                        return Data_Maybe.Just.create(HtmlUtils.coerceEventTargetToHTMLElement(target));
                    })();
                };
            })),
            text: Locale.lookup_("actions"),
            icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconMenuV2.value)
        }) ]);
    };
});
var sections = [ {
    title: "Default",
    view: [ Box.box("tacoDropdownExampleContainer")([ Box.FlexRow.value, Box.JustifyEnd.value, Box.Width.create(new TacoConstants.CustomSize("300px")) ])([ dropdownDemo({}) ]) ],
    code: example1
} ];
var demoView = {
    sections: sections,
    title: "TacoDropdown",
    description: "Dropdown component with custom contents"
};
module.exports = {
    demoView: demoView
};
