exports.fi = {
  mode_name_reporting: "Raportointi",
  mode_name_estimation: "Laskenta",
  mode_name_organization: "Ylläpito",
  mode_name_integration: "Integraatio",
  mode_name_dixon: "Määrälaskenta",
  mode_name_admin: "Käyttäjähallinta",
  mode_name_superadmin: "SuperAdmin",
  mode_name_etc: "Muu",
  notifications: "Ilmoitukset",
  action: "Toiminto",
  function: "Funktio",
  user: "Käyttäjä",
  actions: "Toiminnot",
  createdAt: "Aikaleima",
  log_body: "Data",
  input_date: "Valitse pvm",
  save: "Tallenna",
  edit: "Muokkaa",
  close: "Sulje",
  create_new: "Luo uusi",
  add_new_project: "Lisää uusi hanke",
  new_project_added: "Uusi hanke lisätty",
  project_info_updated: "Hankkeen tiedot päivitetty",
  new_income_group_added: "Uusi maksueräryhmä lisätty",
  new_invoice_added: "Uusi lasku lisätty",
  new_component: "Uusi suorite",
  new_element: "Uusi rakenne",
  cost_group_target_items_have_been_reset:
    "Tavoite palautettu vastaamaan suoritteita",
  add_project_manager: "Lisää projektipäällikkö",
  new_project_manager_has_been_added: "Uusi projektipäällikkö lisätty",
  project_manager_has_been_edited: "Projektipäällikön nimen muokkaus onnistui",
  project_manager_has_been_deleted:
    "Projektipäällikkö poistettiin onnistuneesti",
  confirm_project_manager_deletion:
    "Haluatko varmasti poistaa projektipäällikön nimeltä ",
  confirm_project_manager_edit: "Anna uusi nimi projektipäällikölle ",
  error_loading_projects_please_refresh:
    "Virhe ladattessa projekteja. Voit kokeilla sivun lataamista uudelleen.",
  error_add_alakohde_duplicate_row: "Antamasi alakohteen tunnus on jo käytössä",
  error_add_cost_duplicate_row: "Antamasi ryhmä tunnus on jo käytössä",
  error_add_cost_group_duplicate_row: "Antamasi litteratunnus on jo käytössä",
  error_add_element_measure_duplicate_code:
    "You tried to add a code that already exists",
  error_add_project_duplicate_code_and_version:
    "Projekti samalla tunnuksella ja versiolla on jo olemassa.",
  error_add_incomeItem_duplicate_project_and_group:
    "Maksuryhmä samalla projektilla ja ryhmällä on jo olemassa.",
  error_add_new_project_tax_rate_error:
    "ALV pitää olla numero esim. 25.5 tai 0",
  error_unknown_please_refresh: "Tuntematon virhe, päivitä sivu.",
  error_component_code_reserved: "Koodi on jo varattu toiselle suoritteelle",
  error_formula_evaluation_failed: "Virhe kaavan laskennassa",
  error_copy_project_duplicate_code_and_version_error:
    "Projekti samalla tunnuksella ja versiolla on jo olemassa.",
  error_cant_convert_to_topic_component:
    "Suoritetta ei voi muuntaa otsikkoriviksi jos sillä on määrä tai panoksia.",
  error_invalid_excel_payload_field_too_long:
    "Lataus epäonnistui: tekstit ovat liian pitkiä esim. Selite",
  error_user_does_not_have_read_all_projects_perm:
    "Sinulla ei ole lukuoikeuksia kaikkiin projekteihin.",
  error_user_does_not_have_write_all_projects_perm:
    "Sinulla ei ole kirjoitusoikeuksia kaikkiin projekteihin.",
  error_user_does_not_have_project_rights:
    "Sinulla ei ole oikeuksia tähän hankkeeseen.",
  error_user_does_not_have_project_write_perm:
    "Sinulla ei ole oikeutta muokata tätä projektia.",
  error_user_does_not_have_project_read_perm:
    "Sinulla ei ole oikeutta lukea tätä projektia.",
  error_user_does_not_have_cc_perm:
    "Sinulla ei ole oikeuksia käyttää Raportointia.",
  error_user_does_not_have_ce_perm:
    "Sinulla ei ole oikeuksia käyttää Laskentaa.",
  error_user_does_not_have_any_app_perm:
    "Sinulla ei ole oikkeuksia käyttää mitään sovellusta.",
  error_user_is_not_admin: "Sinulla ei ole pääkäyttäjäoikeuksia.",
  error_user_does_not_have_app_rights:
    "Sinulla ei ole oikeuksia tämän sovelluksen käyttöön.",
  error_user_does_not_have_project_create_rights:
    "Sinulla ei ole oikeuksia luoda uusia projekteja.",
  error_user_does_not_have_write_cost_group_targets_perm:
    "Sinulla ei ole oikeuksia muokata litterakohtaisia tavoitteita",
  error_generic_no_rights: "Sinulla ei ole oikeuksia tähän toimintoon.",
  error_user_quota_exceeded:
    "Organisaatiolla ei ole tarpeeksi lisensoituja käyttäjiä",
  error_read_write_user_quota_exceeded:
    "Organisaatiolla ei ole tarpeeksi lisensoituja kirjoitusoikeudellisia käyttäjiä",
  error_add_new_project_name_error: "Nimen tulee olla vähintään 3 merkkiä",
  error_add_new_project_code_error: "Tunnus on pakollinen",
  error_add_new_project_version_error: "Version tulee olla vähintään 1 merkki",
  error_add_new_project_vat_exclusion_cost_groups:
    "Litteratunnusten tulee olla pilkulla erotettuja",
  error_app_versions_mismatch_old_backend: "Ohjelmistoa päivitetään.",
  error_app_versions_mismatch_old_frontend:
    "Uusi versio saatavilla. Päivitä sivu.",
  error_duplicate_project_managers:
    "Projektipäällikkö on jo olemassa annetulla nimellä!",
  error_empty_pm_name: "Projektipäällikön nimi on tyhjä!",
  error_too_long_pm_name:
    "Projektipäällikön nimi on liian pitkä (max 50 merkkiä)",
  error_pm_doesnt_exist:
    "Projektipäällikköä, jota yritettiin poistaa, ei löytynyt tietokannasta!",
  upload_incomeItems_excel: "Tuo maksuerätaulukko",
  upload_costgroups_excel: "Tuo litterakartta",
  no_cost_control_items: "Ei löytynyt litteroita.",
  rename_main_cost_groups: "Nimeä pääryhmät",
  action_rename_cost_group: "Nimeä littera",
  done: "Ladattu",
  error: "Virhe",
  preview_content_msg:
    "Tämä on demosivu, joka esittelee tulevaa toiminnallisuutta. Tällä sivulla tehdyt muutokset eivät tallennu tai muuta tietoja.",
  login_account_verification_heading: "Vahvista ensin sähköpostisi",
  login_account_verification_text:
    "Klikkaa rekisteröitymisen yhteydessä saamassasi sähköpostissa olevaa linkkiä.",
  login_account_verification_login_link_text: "Siirry kirjautumiseen",
  project_list: "Hankelista",
  project: "Hanke",
  project_name: "Nimi",
  project_start_date: "Alku",
  project_end_date: "Loppu",
  project_state: "Vaihe",
  project_code: "Tunnus",
  project_version: "Versio",
  project_description: "Selite",
  project_new_cost_group: "Uusi littera",
  project_new_reservation: "Uusi ennustemuutos",
  project_reservation: "Ennustemuutos",
  project_add_cost_group: "Lisää",
  project_add_row: "Lisää",
  project_select_columns: "Muokkaa sarakkeita",
  project_cost_class: "PL",
  project_rights_info:
    "Jos listasta puuttuu hanke, sinulla ei ehkä ole oikeuksia nähdä kyseisen hankkeen tietoja. Hanki oikeudet ottamalla yhteyttä tukeen.",
  portfolio_header_projects: "Hankkeet",
  portfolio_report: "Portfolioraportti",
  remove_all_filters: "Poista kaikki rajaukset",
  remove_filter: "Poista rajaus",
  not_chosen: "Ei valittu",
  project_group: "Hankeryhmä",
  common_project_manager: "Projektipäällikkö",
  common_project_managers: "Projektipäälliköt",
  common_select_project: "Valitse hanke",
  common_no_projects_in_portfolio:
    "Portfoliossasi ei ole hankkeita - valitse hankkeita portfolioosi",
  common_not_found: "Ei löydy.",
  common_not_found_description: "Sivua, jota yritit hakea ei löytynyt.",
  common_cancel: "Peruuta",
  common_delete: "Poista",
  common_delete_selected: "Poista valitut",
  common_estimate: "Ennuste",
  common_target: "Tavoite",
  common_actual: "Toteuma",
  target_costs: "Tav. kulut",
  target_income: "Tav. tulot",
  target_diff_and_margin: "Tav. kate (%)",
  target_diff_and_margin_tooltip:
    "Tavoitteen kate on tulojen ja kulujen erotus, suluissa kateprosentti eli katteen osuus tuloista",
  actual_costs: "Tot. kulut",
  actual_income: "Tot. tulot",
  actual_income_approved: "Tot. tulot (hyv.)",
  actual_diff_and_margin_approved: "Tot. kate (%) (hyv.)",
  actual_diff_and_margin_approved_tooltip:
    "Toteuman (hyv.) kate on hyväksyttyjen tulojen ja kulujen erotus, suluissa kateprosentti eli katteen osuus tuloista",
  actual_diff: "Tot. kate",
  actual_diff_and_margin: "Tot. kate (%)",
  actual_diff_and_margin_tooltip:
    "Toteuman kate on tulojen ja kulujen erotus, suluissa kateprosentti eli katteen osuus tuloista",
  estimated_diff_and_margin: "Enn. kate (%)",
  estimated_diff_and_margin_tooltip:
    "Ennusteen kate on tulojen ja kulujen erotus, suluissa kateprosentti eli katteen osuus tuloista",
  estimate_costs: "Enn. kulut",
  estimate_income: "Enn. tulot",
  common_sum: "Summa",
  common_desc: "Selite",
  common_diff: "Erotus",
  common_start_date: "Aloituspäivä",
  common_created_date: "Luotu",
  common_end_date: "Lopetuspäivä",
  common_contract_type: "Toteutusmuoto",
  common_project_type: "Hanketyyppi",
  common_financing_type: "Rahoitusmuoto",
  common_construction_type: "Rakennustyyppi",
  common_contract_price: "Urakkasumma",
  common_state: "Tila",
  common_own_project: "Oma kohde",
  common_tax_rate: "ALV",
  own_project_true: "Kyllä",
  own_project_false: "Ei",
  project_state_0: "Rakenteilla",
  project_state_1: "Valmis",
  project_state_2: "Suunnitteilla",
  project_state_3: "Takuuaika",
  address: "Osoite",
  postal_code: "Postinumero",
  city: "Kaupunki",
  memo: "Muistio",
  wbs_ResourceType: "Panoslaji",
  find_new_prices: "Etsi uudet hinnat",
  report_download_report_excel: "Vie Exceliin",
  new_invoice: "Uusi lasku",
  cost_control_invoice: "Lasku",
  cost_control_memo: "Muistio",
  cost_control_InvoiceNumber: "Numero",
  cost_control_InvoiceDate: "Päiväys",
  cost_control_Description: "Selite",
  cost_control_Price: "Kustannus",
  cost_control_costGroupCode: "Littera",
  cost_control_CostClass: "PL",
  cost_control_Supplier: "Toimittaja",
  cost_control_Hours: "Tunnit",
  cost_control_TotalCost: "Kustannus",
  cost_control_TotalHours: "Tunnit",
  cost_group_default_name: "Litteran nimi",
  cost_group_code: "Litteran tunnus",
  cost_group_default_code: "0000",
  cost_group_confirm_delete: "Haluatko varmasti poistaa tämän litteran? 🤔",
  incomeItems_add_new: "Uusi tuloryhmä",
  incomeItems_confirm_delete:
    "Haluatko varmasti poistaa valitut tuloryhmät? 🤔",
  incomeItems_code: "Tunnus",
  incomeItems_group: "Ryhmä",
  incomeItems_name: "Nimi",
  incomeItems_value: "Summa (alv 0%)",
  incomeItems_change_status: "Hyväksytty",
  incomeItems_date_of_approval: "Hyväksymispäivä",
  incomeItems_schedule: "Suun. laskutuspvm",
  navigation_incomeItems: "Tulot",
  navigation_reports: "Raportit",
  navigation_r200_report: "Työmaaraportti",
  navigation_cost_control: "Litterat",
  navigation_invoices: "Menot",
  navigation_monthly_report: "Kuukausiraportti",
  navigation_project_details: "Hankkeen tiedot",
  own_tax_rate: "Kulujen ALV-korjaus",
  report_taxes: "Omien kohteiden Alv",
  report_totals_with_taxes: "Kustannukset yhteensä",
  report_raw_totals: "Yhteensä",
  report_margin_header: "Katelaskelma",
  report_margin_income_total: "Tulot yhteensä",
  report_margin_cost_total: "Kustannukset yhteensä",
  report_margin_margin_total: "Kate",
  report_margin_margin_percent: "Kate-%",
  report_costs_by_maingroup: "Kustannukset pääryhmittäin",
  report_income_groups: "Tuloryhmät",
  report_costs_target: "Tavoite",
  report_costs_estimate: "Ennuste",
  report_costs_diff_target_to_estimate_ratio_tooltip: "Erotus / Ennuste",
  report_costs_diff_target_to_estimate_tooltip: "Tavoite - Ennuste",
  report_costs_actual: "Toteuma",
  report_costs_actual_to_estimate_ratio: "Tot / Enn",
  report_costs_actual_to_estimate_ratio_tooltip: "Toteuma / Ennuste",
  "report_cost_group_main_class--0": "Rakennuttajan kustannukset",
  "report_cost_group_main_class--1": "Maa- ja pohjarakennus",
  "report_cost_group_main_class--2": "Perustukset ja ulkopuoliset rakenteet",
  "report_cost_group_main_class--3": "Runko- ja vesikattorakenteet",
  "report_cost_group_main_class--4": "Täydentävät rakenteet",
  "report_cost_group_main_class--5": "Pintarakenteet",
  "report_cost_group_main_class--6": "Kalusteet, varusteet ja laitteet",
  "report_cost_group_main_class--7": "Konetekniset työt",
  "report_cost_group_main_class--8": "Työmaan käyttökustannukset",
  "report_cost_group_main_class--9": "Työmaan yhteiskustannukset",
  report_action_toggle_actual_income_based_on_approved_incomeItems_only:
    "Toteuma hyväksyttyjen maksuerien perusteella",
  report_action_toggle_completion_percentage_column: "Näytä valmiusasteet",
  report_costs_completion_percentage: "Työmaan valmiusaste",
  report_costs_completion_percentage_tooltip:
    "Valmiusaste (painotettu keskiarvo ennusteen mukaan laskettuna)",
  report_dropdown_actions_category__view: "Näytä",
  report_dropdown_actions_category__report: "Raportti",

  smart_table_search: "Hae",
  smart_table_no_search_results: "Ei hakutuloksia termillä",
  smart_table_rows_per_pages: "Rivejä per sivu:",
  smart_table_no_rows_available: "Ei tietoa tarjolla",
  toggle_target_cost_group: "Näytä tavoite",
  toggle_estimate_cost_group: "Näytä ennuste",
  toggle_targetToEstimate_cost_group: "Näytä erotus",
  toggle_actualToEstimate_cost_group: "Näytä tot/enn",
  toggle_fullWidth_cost_group: "Näytä täysleveä näkymä",
  toggle_actual_cost_group: "Näytä toteuma",
  toggle_memo_cost_group: "Näytä muistiot",
  toggle_completion_percentage_cost_group: "Näytä valmiusasteet",
  toggle_estimate_remaining_column: "Näytä jäljellä oleva ennuste",
  toggle_invoice_line_descriptions: "Näytä laskujen riviselitteet",
  toggle_social_expenses_cost_group: "Näytä sosiaalikulut",
  toggle_hide_empty_cost_groups: "Piilota tyhjät litterat",
  toggle_show_sum_of_unallocated_sales_invoices:
    "Näytä kohdistamattomat kassatapahtumat",
  social_expenses: "Sosiaalikulut",
  social_expense_percentages: "Sosiaalikuluprosentit",
  measurement_units: "Laajuustiedot",
  measurement_unit_name: "Nimi",
  measurement_unit_id: "Tunnus",
  measurement_unit_value: "arvo",
  measurement_total: "Koko hanke",
  not_included_in_measurement_total: "Ei sisällytetä koko hankkeelle",
  in_cost_estimation: "Kustannuslaskennassa",
  for_estimates_and_actuals: "Ennusteille ja toteumille",
  for_components: "Suoritteille",
  for_targets: "Tavoitteille",
  cost_increases: "Kustannusten nousuvaraus",
  additional_work: "Lisätyöt",
  invoice: "Lasku",
  select_some_invoices: "Yhtään laskua ei ole valittu!",
  single_invoice_selected: "1 laskurivi valittuna",
  multiple_invoices_selected: "laskuriviä valittuna",
  invoice_associate_with_bunch: "Siirrä koontilaskulle...",
  invoice_create_new_bunch: "Luo uusi koontilasku",
  invoice_error_image_download_blocked_by_popup:
    "Kaikkia tiedostoja ei voitu avata. Salli selaimen asetuksista (osoitepalkin oikea reuna) ponnahdusikkunoiden avaaminen tältä sivulta.",
  invoice_download_single_image_not_available: "Laskun kuva ei ole saatavilla",
  invoice_download_single_image: "Lataa laskun kuva",
  invoice_download_multiple_images_not_available:
    "Laskujen kuvia ei ole saatavilla",
  invoice_download_multiple_images: "Lataa laskujen kuvat",
  invoice_filter_tooltip:
    "Suodata laskurivit toimittajan (pl. Palkka) tai litteran perusteella",
  invoice_confirm_delete: "Haluatko varmasti poistaa valitun laskun?",
  invoices_confirm_delete: "Haluatko varmasti poistaa kaikki valitut laskut?",
  invoices_have_been_deleted: "Lasku(t) poistettu",
  pcs: "kpl",
  salary: "Palkka",
  category: "Kategoria",
  unknown: "Muu",
  costClass: "Kustannuslaji",
  groupBySupplier: "Toimittajan mukaan",
  groupByInvoiceBunchId: "Koontilaskun mukaan",
  invoice_bunch_ids: "Koontilaskutunnukset",
  invoice_supplier_invoice_numbers: "Supplier invoice numbers",
  invoice_quantity_column: "Määrä",
  invoice_unit_column: "Yks.",
  invoice_unitprice_column: "€/Yks.",
  nestByInvoice: "Laskuittain",
  cost_control_estimateItemName: "Muutoksen syy",
  cost_control_estimateItemCostClass: "Panoslaji",
  cost_control_estimateItemModificationDate: "Päivämäärä",
  cost_control_estimateItemValue: "Muutos",
  cost_control_targetItemCostClass: "Panoslaji",
  cost_control_targetItemModificationDate: "Päivämäärä",
  cost_control_targetItemValue: "Summa",
  cost_control_invoiceItemDate: "Päivämäärä",
  cost_control_invoiceItemType: "Tyyppi",
  cost_control_invoiceItemSum: "Summa",
  cost_control_invoiceItemLineDescription: "Riviselite",
  cost_control_invoiceItemDescription: "Selite",
  cost_control_sum_of_selected: "Valittujen summa",
  cost_control_sum_of_targets: "Summa",

  table_invoices__costGroupCode: "Littera",
  table_invoices__costClass: "KL",
  table_invoices__costClass__tooltip: "Kustannuslaji",
  table_invoices__supplier: "Toimittaja",
  table_invoices__supplier_business_id: "Y-tunnus",
  table_invoices__invoice_bunch_id: "Koontilaskutunnus",
  table_invoices__invoiceItemDescription: "Selite",
  table_invoices__invoiceItemRowDescription: "Riviselite",
  table_invoices__invoiceNumber: "Tositenumero",
  table_invoices__invoiceDate: "Päiväys",
  table_invoices__invoiceDate__tooltip: "Laskun päiväys",
  table_invoices__sumWithoutVAT: "Summa",
  table_invoices__sumWithoutVAT__tooltip: "Summa (alv. 0%)",
  table_invoices__invoiceline: "laskurivi",
  table_invoices__invoicelines: "laskuriviä",

  project_role: "Rooli",
  company: "Yritys",

  table_incomeItems__group: "Ryhmä",
  table_incomeItems__code: "Tunnus",
  table_incomeItems__name: "Nimi",
  table_incomeItems__amountPaid: "Maksettu",
  table_incomeItems__valueWithoutVAT: "Summa",
  table_incomeItems__valueWithoutVAT__tooltip: "Summa (alv. 0%)",
  table_incomeItems__dateOfPlannedInvoice: "Suun. pvm",
  table_incomeItems__dateOfPlannedInvoice__tooltip:
    "Suunniteltu laskutuspäivämäärä",
  table_incomeItems__isApproved: "Hyväksytty",
  table_incomeItems__dateOfApproval: "Hyväksymispäivä",

  table_sales_invoices__code: "Tunnus",
  table_sales_invoices__description: "Selite",
  table_sales_invoices__amountPaid: "Maksettu",
  table_sales_invoices__date: "Päiväys",

  incomeItem_details__sales_invoices: "Kassatapahtumat",
  incomeItem_details__no_sales_invoices: "Ei kassatapahtumia",
  incomeItems__unknown_sales_invoices: "Muut kassatapahtumat",
  unallocated_sales_invoices: "Kohdistamattomat kassatapahtumat",
  allocate_selected: "Kohdista valitut",

  incomeItems_no_income_groups_warning:
    "Lisää ensin tuloryhmä hankkeen tiedoista.",

  insert_sumWithoutVAT_for_income_item:
    "Syötä uusi summa (ilman ALV) täysinä euroina",
  insert_name_for_income_item: "Anna uusi nimi tuloerälle",
  insert_code_for_income_item: "Anna uusi tunnus tuloerälle",
  insert_cost_group: "Syötä littera",
  insert_cost_class: "Syötä kustannuslaji",
  insert_project: "Syötä projektitunnus tai nimi",
  edit_sumWithoutVAT: "Muokkaa summaa",
  edit_name: "Muokkaa nimeä",
  edit_code: "Muokkaa tunnusta",
  non_numeric_sumWithoutVAT_error: "Summan tulee olla täysiä euroja",
  too_long_name_error: "Virhe: Antamasi nimi on liian pitkä (max 50 merkkiä).",
  empty_name_error: "Virhe: Antamasi nimi on tyhjä",
  too_long_code_error:
    "Virhe: Antamasi tunnus on liian pitkä (max 10 merkkiä).",

  totalling: "Yhteensä",

  cost_group_detail_tab_invoices: "Laskut ja palkat",
  cost_group_detail_tab_components: "Suoritteet",
  cost_group_detail_tab_estimateItems: "Ennuste",
  change_selected_cost_group: "Vaihda valittujen litteraa",
  change_selected_cost_class: "Vaihda valittujen kustannuslajia",
  components_code: "Koodi",
  months: "Kuukaudesta",

  not_found__project_not_found:
    "Hakemaasi hanketta ei löydy tai sinulla ei ole oikeuksia nähdä sen tietoja.",
  not_found__navigate_to_projects: "Siirry hankelistaukseen",

  target_items__no_target_items_cannot_modify_targets:
    "Tavoitteita ei voi muokata, koska tavoitteita ei ole määritelty.",

  prompt_input_costGroup_completion_percentage:
    "Syötä valmiusasteprosentti välillä 0,0 - 100,0 litteralle",

  portfolio_summary__heading: "Hankkeet yhteensä",
  portfolio_summary__income_sum_label: "Tulot yhteensä",
  portfolio_summary__costs_sum_label: "Kustannukset yhteensä",
  portfolio_summary__margin_sum_label: "Kate yhteensä",

  monthly_report__actual_income_and_costs_chart_title:
    "Työmaaraportin kumulatiiviset tulot ja menot",
  monthly_report__actual_costs_chart_legend: "Toteutuneet menot",
  monthly_report__actual_income_chart_legend: "Toteutuneet tulot",
  monthly_report__estimated_costs_chart_legend: "Ennustetut menot",
  monthly_report__estimated_income_chart_legend: "Ennustetut tulot",

  monthly_report__select_project_start_and_end_date:
    "Valitse hankkeen tiedoista projektille alku- ja loppupäivämäärä",
};
