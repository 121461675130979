// Generated by purs version 0.14.5
"use strict";
var Control_Bind = require("../Control.Bind/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var UseCallbackRef = require("../UseCallbackRef/index.js");
var Web_HTML_HTMLElement = require("../Web.HTML.HTMLElement/index.js");
var emptyDimensions = {
    bottom: 0.0,
    height: 0.0,
    left: 0.0,
    right: 0.0,
    top: 0.0,
    width: 0.0
};
var useMeasure = function __do() {
    var v = TofuHooks.useState(emptyDimensions)();
    var v1 = TofuHooks.useState(Data_Maybe.Nothing.value)();
    var callbackRef = UseCallbackRef.useCallbackRef([  ])(function (node$prime) {
        return v1.value1(function (v2) {
            return node$prime;
        });
    })();
    TofuHooks.useLayoutEffect([ ReactHooksUtils.utf(v1.value0) ])((function () {
        var maybeElement = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Web_HTML_HTMLElement.fromNode)(v1.value0);
        var maybeRect = Data_Functor.map(Data_Maybe.functorMaybe)(Web_HTML_HTMLElement.getBoundingClientRect)(maybeElement);
        return function __do() {
            (function () {
                if (maybeRect instanceof Data_Maybe.Just) {
                    var dim = maybeRect.value0();
                    var $9 = Data_Eq.eq(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                        reflectSymbol: function () {
                            return "width";
                        }
                    })(Data_Eq.eqNumber))()({
                        reflectSymbol: function () {
                            return "top";
                        }
                    })(Data_Eq.eqNumber))()({
                        reflectSymbol: function () {
                            return "right";
                        }
                    })(Data_Eq.eqNumber))()({
                        reflectSymbol: function () {
                            return "left";
                        }
                    })(Data_Eq.eqNumber))()({
                        reflectSymbol: function () {
                            return "height";
                        }
                    })(Data_Eq.eqNumber))()({
                        reflectSymbol: function () {
                            return "bottom";
                        }
                    })(Data_Eq.eqNumber)))(dim)(v.value0);
                    if ($9) {
                        return Data_Unit.unit;
                    };
                    return v.value1(function (v2) {
                        return dim;
                    })();
                };
                if (maybeRect instanceof Data_Maybe.Nothing) {
                    return Data_Unit.unit;
                };
                throw new Error("Failed pattern match at UseMeasure (line 36, column 5 - line 44, column 27): " + [ maybeRect.constructor.name ]);
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        };
    })())();
    return {
        callbackRef: callbackRef,
        dimensions: v.value0,
        node: v1.value0
    };
};
var useMeasure$prime = function (element) {
    return function __do() {
        var v = TofuHooks.useState(emptyDimensions)();
        var maybeRect = Data_Functor.map(Data_Maybe.functorMaybe)(Web_HTML_HTMLElement.getBoundingClientRect)(element);
        (function () {
            if (maybeRect instanceof Data_Maybe.Just) {
                var dim = maybeRect.value0();
                var $17 = Data_Eq.eq(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                    reflectSymbol: function () {
                        return "width";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "top";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "right";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "left";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "height";
                    }
                })(Data_Eq.eqNumber))()({
                    reflectSymbol: function () {
                        return "bottom";
                    }
                })(Data_Eq.eqNumber)))(dim)(v.value0);
                if ($17) {
                    return Data_Unit.unit;
                };
                return v.value1(function (v1) {
                    return dim;
                })();
            };
            if (maybeRect instanceof Data_Maybe.Nothing) {
                return Data_Unit.unit;
            };
            throw new Error("Failed pattern match at UseMeasure (line 57, column 3 - line 64, column 25): " + [ maybeRect.constructor.name ]);
        })();
        return v.value0;
    };
};
module.exports = {
    useMeasure: useMeasure,
    "useMeasure'": useMeasure$prime
};
