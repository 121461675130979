import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { AuthenticatedUser } from "../auth/CurrentUserProvider";

export function reportErrorDatadog(err: unknown, context: object | undefined) {
  datadogRum.addError(err, context);
}

export function infoLogDatadog(msg: string) {
  datadogLogs.logger.info(msg);
}

export const datadogUserMetadata = (user: AuthenticatedUser) => {
  return {
    email: user.email,
    organization: user.organization,
    id: user.userId,
  };
};
