// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Client_WASM = require("../Client.WASM/index.js");
var Common = require("../Common/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var CostEstimation_Calculations = require("../CostEstimation.Calculations/index.js");
var CostEstimation_SelectResource_SelectResourceTable = require("../CostEstimation.SelectResource.SelectResourceTable/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Array_NonEmpty_Internal = require("../Data.Array.NonEmpty.Internal/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Function = require("../Data.Function/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Map_Internal = require("../Data.Map.Internal/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_String_Pattern = require("../Data.String.Pattern/index.js");
var Effect = require("../Effect/index.js");
var EstimationUtils = require("../EstimationUtils/index.js");
var FetchHooks = require("../FetchHooks/index.js");
var LengthValidator = require("../LengthValidator/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_DOM_Internal = require("../React.Basic.DOM.Internal/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var ReactHooksUtils = require("../ReactHooksUtils/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoAutocomplete = require("../TacoAutocomplete/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoTable_Header = require("../TacoTable.Header/index.js");
var TacoTable_Row = require("../TacoTable.Row/index.js");
var TacoTable_Styles = require("../TacoTable.Styles/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_ClassificationGroup = require("../Types.ClassificationGroup/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_CostClass = require("../Types.CostClass/index.js");
var Types_Estimation = require("../Types.Estimation/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_Project = require("../Types.Project/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var UseLicenseFeature = require("../UseLicenseFeature/index.js");
var Utils = require("../Utils/index.js");
var prepareReferenceProjectResources = function (projectId) {
    return function (resources) {
        var resourceRows = Data_Functor.map(Data_Functor.functorArray)(CostEstimation_SelectResource_SelectResourceTable.fromWasmEstimationResource)(CostEstimation_SelectResource_SelectResourceTable.removeCostClassPrices(resources));
        var groupedResources = Data_Array.groupBy(function (a) {
            return function (b) {
                return a.name === b.name;
            };
        })(resourceRows);
        var deduplicateResourceGroup = function (group$prime) {
            var ownProjectResource = Data_Foldable.find(Data_Array_NonEmpty_Internal.foldableNonEmptyArray)(function (r) {
                return Data_Eq.eq(Data_Nullable.eqNullable(Types_Project.eqProjectId))(r.projectId)(Utils.pureNullable(projectId));
            })(group$prime);
            var firstResource = Data_Array_NonEmpty.head(group$prime);
            return Data_Maybe.fromMaybe(firstResource)(ownProjectResource);
        };
        var deduplicatedResources = Data_Functor.map(Data_Functor.functorArray)(deduplicateResourceGroup)(groupedResources);
        var v = Data_Array.partition(function (r) {
            return r.usagesCount > 0.0;
        })(deduplicatedResources);
        return Data_Array.nubBy(CostEstimation_SelectResource_SelectResourceTable.compareWithoutIds()()()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()({
            reflectSymbol: function () {
                return "wastePercentage";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
            reflectSymbol: function () {
                return "vendorUnit";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "vendorCode";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "vendorBatchSize";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
            reflectSymbol: function () {
                return "vendor";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "validDate";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "usagesCount";
            }
        })(Data_Ord.ordNumber))()({
            reflectSymbol: function () {
                return "unitConsumptionRate";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
            reflectSymbol: function () {
                return "unit";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "taxPercentage";
            }
        })(Types_Unit.ordTaxrate))()({
            reflectSymbol: function () {
                return "sortingNumber";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "resourceClassificationGroup";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Data_Nullable.ordNullable(Types_Project.ordProjectId)))()({
            reflectSymbol: function () {
                return "priceWithDiscount";
            }
        })(Data_Ord.ordNumber))()({
            reflectSymbol: function () {
                return "pricePerUnit";
            }
        })(Data_Ord.ordNumber))()({
            reflectSymbol: function () {
                return "orgResourcePriceListId";
            }
        })(Data_Nullable.ordNullable(Types_Component.ordOrgEstimationResourceId)))()({
            reflectSymbol: function () {
                return "orgResourcePriceId";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordInt)))()({
            reflectSymbol: function () {
                return "name";
            }
        })(Data_Ord.ordString))()({
            reflectSymbol: function () {
                return "multiplier";
            }
        })(Data_Ord.ordNumber))()({
            reflectSymbol: function () {
                return "index";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
            reflectSymbol: function () {
                return "group";
            }
        })(Data_Nullable.ordNullable(Types_ClassificationGroup.ordClassificationGroupCode)))()({
            reflectSymbol: function () {
                return "eanCode";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "discountPercentage";
            }
        })(Data_Ord.ordNumber))()({
            reflectSymbol: function () {
                return "discountGroupCode";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "currency";
            }
        })(Data_Nullable.ordNullable(Types_Project.ordCurrency)))()({
            reflectSymbol: function () {
                return "costClassCode";
            }
        })(Data_Nullable.ordNullable(Types_Newtypes.ordCostClassCode)))()({
            reflectSymbol: function () {
                return "contract";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
            reflectSymbol: function () {
                return "changeDate";
            }
        })(Data_Nullable.ordNullable(Data_Ord.ordString))))(v.no);
    };
};
var prepareProjectResources = function (resources) {
    return Data_Array.nubBy(CostEstimation_SelectResource_SelectResourceTable.compareWithoutIds()()()(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordCons(Data_Ord.ordRecordNil)()({
        reflectSymbol: function () {
            return "wastePercentage";
        }
    })(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
        reflectSymbol: function () {
            return "vendorUnit";
        }
    })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
        reflectSymbol: function () {
            return "vendorCode";
        }
    })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
        reflectSymbol: function () {
            return "vendorBatchSize";
        }
    })(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
        reflectSymbol: function () {
            return "vendor";
        }
    })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
        reflectSymbol: function () {
            return "validDate";
        }
    })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
        reflectSymbol: function () {
            return "usagesCount";
        }
    })(Data_Ord.ordNumber))()({
        reflectSymbol: function () {
            return "unitConsumptionRate";
        }
    })(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
        reflectSymbol: function () {
            return "unit";
        }
    })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
        reflectSymbol: function () {
            return "taxPercentage";
        }
    })(Types_Unit.ordTaxrate))()({
        reflectSymbol: function () {
            return "sortingNumber";
        }
    })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
        reflectSymbol: function () {
            return "resourceClassificationGroup";
        }
    })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
        reflectSymbol: function () {
            return "projectId";
        }
    })(Data_Nullable.ordNullable(Types_Project.ordProjectId)))()({
        reflectSymbol: function () {
            return "priceWithDiscount";
        }
    })(Data_Ord.ordNumber))()({
        reflectSymbol: function () {
            return "pricePerUnit";
        }
    })(Data_Ord.ordNumber))()({
        reflectSymbol: function () {
            return "orgResourcePriceListId";
        }
    })(Data_Nullable.ordNullable(Types_Component.ordOrgEstimationResourceId)))()({
        reflectSymbol: function () {
            return "orgResourcePriceId";
        }
    })(Data_Nullable.ordNullable(Data_Ord.ordInt)))()({
        reflectSymbol: function () {
            return "name";
        }
    })(Data_Ord.ordString))()({
        reflectSymbol: function () {
            return "multiplier";
        }
    })(Data_Ord.ordNumber))()({
        reflectSymbol: function () {
            return "index";
        }
    })(Data_Nullable.ordNullable(Data_Ord.ordNumber)))()({
        reflectSymbol: function () {
            return "group";
        }
    })(Data_Nullable.ordNullable(Types_ClassificationGroup.ordClassificationGroupCode)))()({
        reflectSymbol: function () {
            return "eanCode";
        }
    })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
        reflectSymbol: function () {
            return "discountPercentage";
        }
    })(Data_Ord.ordNumber))()({
        reflectSymbol: function () {
            return "discountGroupCode";
        }
    })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
        reflectSymbol: function () {
            return "currency";
        }
    })(Data_Nullable.ordNullable(Types_Project.ordCurrency)))()({
        reflectSymbol: function () {
            return "costClassCode";
        }
    })(Data_Nullable.ordNullable(Types_Newtypes.ordCostClassCode)))()({
        reflectSymbol: function () {
            return "contract";
        }
    })(Data_Nullable.ordNullable(Data_Ord.ordString)))()({
        reflectSymbol: function () {
            return "changeDate";
        }
    })(Data_Nullable.ordNullable(Data_Ord.ordString))))(Data_Functor.map(Data_Functor.functorArray)(CostEstimation_SelectResource_SelectResourceTable.fromWasmEstimationResource)(CostEstimation_SelectResource_SelectResourceTable.removeCostClassPrices(resources)));
};
var prepareOrgResources = function (resources) {
    return Data_Functor.map(Data_Functor.functorArray)(CostEstimation_SelectResource_SelectResourceTable.fromOrgResourcePrice)(resources);
};
var getResourceColumns = function (props) {
    return function (groupsEnabled) {
        var unitCell = (function () {
            var v = Data_Functor.map(Data_Maybe.functorMaybe)(function (v1) {
                return v1.unitInput;
            })(props.inputs);
            if (v instanceof Data_Maybe.Just) {
                return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (v1) {
                    return v.value0;
                });
            };
            if (v instanceof Data_Maybe.Nothing) {
                return new TacoTable_Types.PlainTextCell(function (row) {
                    return row.resourceValues.unit;
                });
            };
            throw new Error("Failed pattern match at CostEstimation.SelectResource (line 741, column 16 - line 743, column 63): " + [ v.constructor.name ]);
        })();
        var resourceClassificationGroupCell = (function () {
            var v = Data_Functor.map(Data_Maybe.functorMaybe)(function (v1) {
                return v1.groupInput;
            })(props.inputs);
            if (v instanceof Data_Maybe.Just) {
                return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (v1) {
                    return v.value0;
                });
            };
            if (v instanceof Data_Maybe.Nothing) {
                return new TacoTable_Types.PlainTextCell(function (v1) {
                    return v1.resourceValues.group;
                });
            };
            throw new Error("Failed pattern match at CostEstimation.SelectResource (line 722, column 39 - line 724, column 54): " + [ v.constructor.name ]);
        })();
        var resourceGroupColumn = (function () {
            var $97 = !groupsEnabled;
            if ($97) {
                return Common.emptyArray;
            };
            return [ {
                key: "resourceGroup",
                label: React_Basic_DOM.text("Ryhm\xe4"),
                cell: resourceClassificationGroupCell,
                sortProperty: TacoTable_Types.noSortProperty,
                width: new TacoTable_Types.FlexMinWidth(1, "6rem"),
                headerJSX: Data_Maybe.Nothing.value
            } ];
        })();
        var unitConsumptionRateCell = (function () {
            var v = Data_Functor.map(Data_Maybe.functorMaybe)(function (v1) {
                return v1.unitConsumptionRateInput;
            })(props.inputs);
            if (v instanceof Data_Maybe.Just) {
                return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (v1) {
                    return v.value0;
                });
            };
            if (v instanceof Data_Maybe.Nothing) {
                var consumptionRate = function (row) {
                    if (row.resourceValues.isCostClassPrice) {
                        return 1.0;
                    };
                    return row.resourceValues.unitConsumptionRate;
                };
                return new TacoTable_Types.PlainNumberCell(3, consumptionRate);
            };
            throw new Error("Failed pattern match at CostEstimation.SelectResource (line 731, column 31 - line 737, column 56): " + [ v.constructor.name ]);
        })();
        var pricePerUnitCell = (function () {
            var v = Data_Functor.map(Data_Maybe.functorMaybe)(function (v1) {
                return v1.pricePerUnitInput;
            })(props.inputs);
            if (v instanceof Data_Maybe.Just) {
                return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (v1) {
                    return v.value0;
                });
            };
            if (v instanceof Data_Maybe.Nothing) {
                return new TacoTable_Types.PlainNumberCell(2, function (v1) {
                    return v1.resourceValues.pricePerUnit;
                });
            };
            throw new Error("Failed pattern match at CostEstimation.SelectResource (line 728, column 24 - line 730, column 84): " + [ v.constructor.name ]);
        })();
        var wastePercentageCell = (function () {
            var v = Data_Functor.map(Data_Maybe.functorMaybe)(function (v1) {
                return v1.wastePercentageInput;
            })(props.inputs);
            if (v instanceof Data_Maybe.Just) {
                return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (v1) {
                    return v.value0;
                });
            };
            if (v instanceof Data_Maybe.Nothing) {
                return new TacoTable_Types.PlainNumberCell(2, function (v1) {
                    return v1.resourceValues.wastePercentage;
                });
            };
            throw new Error("Failed pattern match at CostEstimation.SelectResource (line 738, column 27 - line 740, column 86): " + [ v.constructor.name ]);
        })();
        var nameCell = (function () {
            var v = Data_Functor.map(Data_Maybe.functorMaybe)(function (v1) {
                return v1.nameInput;
            })(props.inputs);
            if (v instanceof Data_Maybe.Just) {
                return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (v1) {
                    return v.value0;
                });
            };
            if (v instanceof Data_Maybe.Nothing) {
                return new TacoTable_Types.PlainTextCell(function (v1) {
                    return v1.resourceValues.name;
                });
            };
            throw new Error("Failed pattern match at CostEstimation.SelectResource (line 719, column 16 - line 721, column 53): " + [ v.constructor.name ]);
        })();
        var currencySymbol = EstimationUtils.getCurrencySymbol(props.projectCurrency);
        var pricePerUnitLabelCell = new TacoTable_Types.PlainTextCell(function (v) {
            return currencySymbol + ("/" + v.resourceValues.unit);
        });
        var costClassCell = (function () {
            var v = Data_Functor.map(Data_Maybe.functorMaybe)(function (v1) {
                return v1.costClassInput;
            })(props.inputs);
            if (v instanceof Data_Maybe.Just) {
                return new TacoTable_Types.JSXCell([ TacoTable_Types.Input.value ], function (v1) {
                    return v.value0;
                });
            };
            if (v instanceof Data_Maybe.Nothing) {
                return new TacoTable_Types.PlainTextCell(function (r) {
                    if (r.costClass instanceof Data_Maybe.Just) {
                        return Data_Newtype.unwrap()(r.costClass.value0.costClassCode) + (": " + r.costClass.value0.name);
                    };
                    if (r.costClass instanceof Data_Maybe.Nothing) {
                        return "";
                    };
                    throw new Error("Failed pattern match at CostEstimation.SelectResource (line 715, column 38 - line 717, column 22): " + [ r.costClass.constructor.name ]);
                });
            };
            throw new Error("Failed pattern match at CostEstimation.SelectResource (line 713, column 21 - line 717, column 22): " + [ v.constructor.name ]);
        })();
        return Data_Semigroup.append(Data_Semigroup.semigroupArray)([ {
            key: "costClass",
            label: React_Basic_DOM.text("PL"),
            cell: costClassCell,
            sortProperty: TacoTable_Types.noSortProperty,
            width: new TacoTable_Types.FlexMinWidth(1, "10rem"),
            headerJSX: Data_Maybe.Nothing.value
        } ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(resourceGroupColumn)([ {
            key: "name",
            label: React_Basic_DOM.text("Nimi"),
            cell: nameCell,
            sortProperty: TacoTable_Types.noSortProperty,
            width: new TacoTable_Types.FlexMinWidth(10, "8rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "unit",
            label: React_Basic_DOM.text("Yks."),
            cell: unitCell,
            sortProperty: TacoTable_Types.noSortProperty,
            width: new TacoTable_Types.FlexMinWidth(1, "4rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "pricePerUnit",
            label: React_Basic_DOM.text("Hinta"),
            cell: pricePerUnitCell,
            sortProperty: TacoTable_Types.noSortProperty,
            width: new TacoTable_Types.FlexMinWidth(2, "5rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "pricePerUnitLabel",
            label: React_Basic_DOM.text(""),
            cell: pricePerUnitLabelCell,
            sortProperty: TacoTable_Types.noSortProperty,
            width: new TacoTable_Types.FlexMinWidth(1, "4rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "unitConsumptionRate",
            label: React_Basic_DOM.text("Menekki"),
            cell: unitConsumptionRateCell,
            sortProperty: TacoTable_Types.noSortProperty,
            width: new TacoTable_Types.FlexMinWidth(1, "5rem"),
            headerJSX: Data_Maybe.Nothing.value
        }, {
            key: "wastePercentage",
            label: React_Basic_DOM.text("Lis\xe4-%"),
            cell: wastePercentageCell,
            sortProperty: TacoTable_Types.noSortProperty,
            width: new TacoTable_Types.FlexMinWidth(1, "5rem"),
            headerJSX: Data_Maybe.Nothing.value
        } ]));
    };
};
var getMatchingResources = function (isNewResource) {
    return function (name) {
        return function (filter) {
            return function (resources) {
                var matchesName = function (r) {
                    return Data_String_CodeUnits.contains(Data_String_Pattern.Pattern(Data_String_Common.toLower((function () {
                        if (isNewResource) {
                            return name;
                        };
                        return filter;
                    })())))(Data_String_Common.toLower(r.name));
                };
                var predicate = function (r) {
                    return Data_Foldable.and(Data_Foldable.foldableArray)(Data_HeytingAlgebra.heytingAlgebraBoolean)([ matchesName(r) ]);
                };
                return Data_Array.filter(predicate)(resources);
            };
        };
    };
};
var selectResourceComponent = TofuHooks.mkHookComponent("SelectResourceImpl")(function (props) {
    var resourceActionType = (function () {
        if (props.componentSplitParams instanceof Data_Maybe.Nothing) {
            return Types_Estimation.InPlaceResourceUpdate.value;
        };
        if (props.componentSplitParams instanceof Data_Maybe.Just) {
            return new Types_Estimation.SplitElementComponent(props.componentSplitParams.value0);
        };
        throw new Error("Failed pattern match at CostEstimation.SelectResource (line 153, column 26 - line 155, column 60): " + [ props.componentSplitParams.constructor.name ]);
    })();
    var isNewResource = Data_Maybe.isNothing(props.activeResource);
    return function __do() {
        var v = Client_WASM.useEstimationResources(props.projectId)();
        var v1 = Client_WASM.useReferenceEstimationResources(props.projectId)();
        var v2 = FetchHooks.useEstimationResourcePriceList(props.projectId)();
        var memoOrgResources = TofuHooks.useMemo([ ReactHooksUtils.utf(v2.value0) ])(function (v3) {
            return prepareOrgResources(v2.value0);
        })();
        var memoReferenceEstimationResources = TofuHooks.useMemo([ ReactHooksUtils.utf(v1.value0) ])(function (v3) {
            return prepareReferenceProjectResources(props.projectId)(v1.value0);
        })();
        var memoProjectEstimationResources = TofuHooks.useMemo([ ReactHooksUtils.utf(v.value0) ])(function (v3) {
            return prepareProjectResources(v.value0);
        })();
        var v3 = TofuHooks.useState("")();
        var v4 = Client_WASM.useCostClasses(props.projectId)();
        var costClassesRef = TofuHooks.useRef(v4.value0)();
        TofuHooks.useLayoutEffect([ ReactHooksUtils.utf(v4.value0) ])(function __do() {
            React_Basic_Hooks.writeRef(costClassesRef)(v4.value0)();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        })();
        var v5 = TofuHooks.useState(props.selectedCostClassCode)();
        TofuHooks.useEffect([ props.selectedCostClassCode ])(function __do() {
            Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(props.selectedCostClassCode)(function (cc) {
                return v5.value1(function (v6) {
                    return new Data_Maybe.Just(cc);
                });
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Effect.monoidEffect(Data_Monoid.monoidUnit)))();
        })();
        var v6 = TofuHooks.useState("")();
        var v7 = TofuHooks.useState(0.0)();
        var v8 = TofuHooks.useState(0.0)();
        var v9 = TofuHooks.useState(0.0)();
        var v10 = TofuHooks.useState("")();
        var v11 = TofuHooks.useState(0.0)();
        var v12 = TofuHooks.useState("")();
        var v13 = TofuHooks.useState(0.0)();
        var v14 = TofuHooks.useState(false)();
        var appDispatch = StateHooks.useDispatch();
        var groupsLicenseEnabled = UseLicenseFeature.useLicenseFeature(UseLicenseFeature.ComponentGroups.value)();
        TofuHooks.useLayoutEffect([ props.modalOpen ])(function __do() {
            Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(!props.modalOpen)(function __do() {
                v3.value1(function (v15) {
                    return "";
                })();
                v5.value1(function (v15) {
                    return Data_Maybe.Nothing.value;
                })();
                v6.value1(function (v15) {
                    return "";
                })();
                v7.value1(function (v15) {
                    return 0.0;
                })();
                v8.value1(function (v15) {
                    return 0.0;
                })();
                v9.value1(function (v15) {
                    return 0.0;
                })();
                v10.value1(function (v15) {
                    return "";
                })();
                v11.value1(function (v15) {
                    return 0.0;
                })();
                v12.value1(function (v15) {
                    return "";
                })();
                v13.value1(function (v15) {
                    return 0.0;
                })();
                return v14.value1(function (v15) {
                    return false;
                })();
            })();
            return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
        })();
        var filteredReferenceEstimationResources = getMatchingResources(isNewResource)(v6.value0)(v3.value0)(memoReferenceEstimationResources);
        var filteredProjectEstimationResources = getMatchingResources(isNewResource)(v6.value0)(v3.value0)(memoProjectEstimationResources);
        var filteredOrgResources = getMatchingResources(isNewResource)(v6.value0)(v3.value0)(memoOrgResources);
        var selectResource = function (resource) {
            return function __do() {
                var costClasses$prime = React_Basic_Hooks.readRef(costClassesRef)();
                var costClass$prime = Data_Array.find(function (cc) {
                    return Data_Eq.eq(Data_Nullable.eqNullable(Types_Newtypes.eqCostClassCode))(Utils.pureNullable(cc.costClassCode))(resource.costClassCode);
                })(costClasses$prime);
                v5.value1(function (v15) {
                    return costClass$prime;
                })();
                v6.value1(function (v15) {
                    return resource.name;
                })();
                v7.value1(function (v15) {
                    return resource.priceWithDiscount;
                })();
                v8.value1(function (v15) {
                    return 0.0;
                })();
                v9.value1(function (v15) {
                    return 0.0;
                })();
                Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(Data_Nullable.toMaybe(resource.unit))(function (unit$prime) {
                    return v10.value1(function (v15) {
                        return unit$prime;
                    });
                })();
                v11.value1(function (v15) {
                    return 0.0;
                })();
                Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(Data_Nullable.toMaybe(resource.group))(function (v15) {
                    return v12.value1(function (v16) {
                        return v15;
                    });
                })();
                return v13.value1(function (v15) {
                    return 0.0;
                })();
            };
        };
        var replaceResource = function (resource) {
            var targetResourceId$prime = Data_Functor.map(Data_Maybe.functorMaybe)(function (v15) {
                return v15.id;
            })(props.activeResource);
            if (targetResourceId$prime instanceof Data_Maybe.Nothing) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Monoid.mempty(Data_Monoid.monoidUnit));
            };
            if (targetResourceId$prime instanceof Data_Maybe.Just && resource.databaseRowId instanceof CostEstimation_SelectResource_SelectResourceTable.PriceListRowId) {
                return appDispatch(new Actions.EstimationResourceActionRequest({
                    projectId: props.projectId,
                    params: {
                        actionType: resourceActionType,
                        actionParams: new Types_Estimation.ReplaceComponentResourceFromOrgPriceList([ {
                            componentId: props.componentId,
                            replaceTargetResourceId: targetResourceId$prime.value0,
                            orgResourcePriceIds: [ resource.databaseRowId.value0 ]
                        } ])
                    },
                    handler: function (v15) {
                        return props.closeModal;
                    }
                }));
            };
            if (targetResourceId$prime instanceof Data_Maybe.Just && resource.databaseRowId instanceof CostEstimation_SelectResource_SelectResourceTable.ProjectResourceId) {
                return appDispatch(new Actions.EstimationResourceActionRequest({
                    projectId: props.projectId,
                    params: {
                        actionType: resourceActionType,
                        actionParams: new Types_Estimation.ReplaceComponentResourceFromProject({
                            componentId: props.componentId,
                            replaceTargetResourceId: targetResourceId$prime.value0,
                            newResourceId: resource.databaseRowId.value0
                        })
                    },
                    handler: function (v15) {
                        return props.closeModal;
                    }
                }));
            };
            throw new Error("Failed pattern match at CostEstimation.SelectResource (line 230, column 7 - line 258, column 14): " + [ targetResourceId$prime.constructor.name, resource.databaseRowId.constructor.name ]);
        };
        var orgResourcesTable = CostEstimation_SelectResource_SelectResourceTable.selectResourceTable({
            resources: filteredOrgResources,
            selectResource: (function () {
                if (isNewResource) {
                    return selectResource;
                };
                return replaceResource;
            })(),
            dispatch: appDispatch,
            projectId: props.projectId,
            componentId: props.componentId,
            defaultCostClassCode: Data_Maybe.maybe(Data_Newtype.wrap()("1"))(function (v15) {
                return v15.costClassCode;
            })(v5.value0),
            componentSplitParams: props.componentSplitParams,
            projectCurrency: props.projectCurrency
        });
        var matchingResourcesWithoutUsages = CostEstimation_SelectResource_SelectResourceTable.selectResourceTable({
            resources: filteredReferenceEstimationResources,
            selectResource: (function () {
                if (isNewResource) {
                    return selectResource;
                };
                return replaceResource;
            })(),
            dispatch: appDispatch,
            projectId: props.projectId,
            componentId: props.componentId,
            defaultCostClassCode: Data_Maybe.maybe(Data_Newtype.wrap()("1"))(function (v15) {
                return v15.costClassCode;
            })(v5.value0),
            componentSplitParams: props.componentSplitParams,
            projectCurrency: props.projectCurrency
        });
        var existingProjectResources = CostEstimation_SelectResource_SelectResourceTable.selectResourceTable({
            resources: filteredProjectEstimationResources,
            selectResource: (function () {
                if (isNewResource) {
                    return selectResource;
                };
                return replaceResource;
            })(),
            dispatch: appDispatch,
            projectId: props.projectId,
            componentId: props.componentId,
            defaultCostClassCode: Data_Maybe.maybe(Data_Newtype.wrap()("1"))(function (v15) {
                return v15.costClassCode;
            })(v5.value0),
            componentSplitParams: props.componentSplitParams,
            projectCurrency: props.projectCurrency
        });
        var saveNewResource = (function () {
            if (v5.value0 instanceof Data_Maybe.Just) {
                var resourcesForCostClass = (function () {
                    var justEqId = function (resource) {
                        var v15 = Data_Nullable.toMaybe(resource.costClassCode);
                        if (v15 instanceof Data_Maybe.Just) {
                            return Data_Eq.eq(Types_Newtypes.eqCostClassCode)(v15.value0)(v5.value0.value0.costClassCode);
                        };
                        return false;
                    };
                    return Data_Array.filter(justEqId)(props.resourcesForComponent);
                })();
                var mkSetResourcePriceAction = function (params) {
                    var actionParams = {
                        projectId: props.projectId,
                        estimationComponentId: params.estimationComponentId,
                        costClassCode: params.costClassCode,
                        pricePerUnit: params.pricePerUnit,
                        estimationResourceId: params.estimationResourceId,
                        handler: params.handler
                    };
                    var action = new Actions.SetEstimationComponentCostClassPriceRequest(actionParams);
                    return action;
                };
                var onSetResourcePrice = function (params) {
                    return appDispatch(mkSetResourcePriceAction(params));
                };
                var onPriceUpdate = function (newPrice) {
                    return onSetResourcePrice({
                        estimationComponentId: props.componentId,
                        costClassCode: v5.value0.value0.costClassCode,
                        pricePerUnit: newPrice,
                        estimationResourceId: Data_Functor.map(Data_Maybe.functorMaybe)(function (v15) {
                            return v15.id;
                        })(Data_Array.find(function (v15) {
                            return v15.isCostClassPrice;
                        })(resourcesForCostClass)),
                        handler: Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)))
                    });
                };
                return function __do() {
                    Control_Applicative.when(Effect.applicativeEffect)(v14.value0)(onPriceUpdate(0.0))();
                    appDispatch(new Actions.EstimationResourceActionRequest({
                        projectId: props.projectId,
                        params: {
                            actionType: resourceActionType,
                            actionParams: new Types_Estimation.AddEstimationResource({
                                estimationComponentId: props.componentId,
                                costClassCode: v5.value0.value0.costClassCode,
                                name: v6.value0,
                                price: v7.value0,
                                unit: (function () {
                                    var $145 = v10.value0 === "";
                                    if ($145) {
                                        return Data_Maybe.Nothing.value;
                                    };
                                    return new Data_Maybe.Just(v10.value0);
                                })(),
                                group: (function () {
                                    var $146 = v12.value0 === "";
                                    if ($146) {
                                        return Data_Maybe.Nothing.value;
                                    };
                                    return Data_Maybe.Just.create(v12.value0);
                                })(),
                                isCostClassResource: false,
                                index: Data_Maybe.Nothing.value,
                                orgResourcePriceId: Data_Maybe.Nothing.value,
                                orgResourcePriceListId: Data_Maybe.Nothing.value,
                                details: new Data_Maybe.Just({
                                    unitConsumptionRate: v11.value0,
                                    resourceClassificationGroup: Data_Maybe.Nothing.value,
                                    sortingNumber: Data_Maybe.Nothing.value,
                                    wastePercentage: v8.value0,
                                    discountPercentage: v9.value0,
                                    multiplier: 1.0,
                                    taxPercentage: v13.value0,
                                    currency: Data_Maybe.Nothing.value,
                                    vendor: Data_Maybe.Nothing.value,
                                    discountGroupCode: Data_Maybe.Nothing.value,
                                    changeDate: Data_Maybe.Nothing.value,
                                    validDate: Data_Maybe.Nothing.value,
                                    contract: Data_Maybe.Nothing.value,
                                    eanCode: Data_Maybe.Nothing.value,
                                    vendorCode: Data_Maybe.Nothing.value,
                                    vendorBatchSize: Data_Maybe.Nothing.value
                                })
                            })
                        },
                        handler: Control_Applicative.pure(Control_Applicative.applicativeFn)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)))
                    }))();
                    return props.closeModal();
                };
            };
            if (v5.value0 instanceof Data_Maybe.Nothing) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Monoid.mempty(Data_Monoid.monoidUnit));
            };
            throw new Error("Failed pattern match at CostEstimation.SelectResource (line 348, column 7 - line 424, column 31): " + [ v5.value0.constructor.name ]);
        })();
        var prepareCostClassDropdownListItem = function (i) {
            var code = Data_Newtype.un()(Types_Newtypes.CostClassCode)(i.costClassCode);
            return {
                id: code,
                text: code + (": " + i.name)
            };
        };
        var copyPriceFromCostClassResourceRow = function (mCostClass) {
            if (mCostClass instanceof Data_Maybe.Nothing) {
                return Control_Applicative.pure(Effect.applicativeEffect)(Data_Monoid.mempty(Data_Monoid.monoidUnit));
            };
            if (mCostClass instanceof Data_Maybe.Just) {
                var resourcesForCostClass = (function () {
                    var justEqId = function (resource) {
                        var v15 = Data_Nullable.toMaybe(resource.costClassCode);
                        if (v15 instanceof Data_Maybe.Just) {
                            return Data_Eq.eq(Types_Newtypes.eqCostClassCode)(v15.value0)(mCostClass.value0.costClassCode);
                        };
                        return false;
                    };
                    return Data_Array.filter(justEqId)(props.resourcesForComponent);
                })();
                var pricePerComponent = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(CostEstimation_Calculations.resourceUnitPrice(props.socialExpensePercentagesByCostClass))(resourcesForCostClass));
                var costClassResourceRow = Data_Array.find(function (v15) {
                    return v15.isCostClassPrice;
                })(resourcesForCostClass);
                var actualResourceRows = Data_Array.filter(Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean))(function (v15) {
                    return v15.isCostClassPrice;
                }));
                var resourcesCount = Data_Array.length(actualResourceRows(resourcesForCostClass));
                var defaultGroup = (function () {
                    var $151 = resourcesCount === 0;
                    if ($151) {
                        return Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function ($211) {
                            return Data_Nullable.toMaybe((function (v15) {
                                return v15.group;
                            })($211));
                        })(costClassResourceRow);
                    };
                    return Data_Maybe.Nothing.value;
                })();
                return function __do() {
                    var defaultPrice = (function () {
                        var $152 = resourcesCount === 0;
                        if ($152) {
                            return pricePerComponent;
                        };
                        return 0.0;
                    })();
                    Data_Monoid.guard(Effect.monoidEffect(Data_Monoid.monoidUnit))(defaultPrice > 0.0)(function __do() {
                        v7.value1(function (v15) {
                            return defaultPrice;
                        })();
                        return v12.value1(function (v15) {
                            return Data_Maybe.fromMaybe("")(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Newtype.un()(Types_ClassificationGroup.ClassificationGroupCode))(defaultGroup));
                        })();
                    })();
                    return v14.value1(function (v15) {
                        return Data_Maybe.isJust(costClassResourceRow);
                    })();
                };
            };
            throw new Error("Failed pattern match at CostEstimation.SelectResource (line 323, column 7 - line 345, column 64): " + [ mCostClass.constructor.name ]);
        };
        var selectCostClass = (function () {
            if (props.activeResource instanceof Data_Maybe.Just) {
                var selectedCostClass = Data_Array.find(function (cc) {
                    return Data_Eq.eq(Data_Nullable.eqNullable(Types_Newtypes.eqCostClassCode))(Utils.pureNullable(cc.costClassCode))(props.activeResource.value0.costClassCode);
                })(v4.value0);
                return TacoAutocomplete.component()()({
                    sections: [ {
                        text: Data_Maybe.Nothing.value,
                        list: Data_Functor.map(Data_Functor.functorArray)(prepareCostClassDropdownListItem)(v4.value0)
                    } ],
                    clearButton: false,
                    onSelect: function (item) {
                        return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(item)(function (v15) {
                            return appDispatch(new Actions.EstimationResourceActionRequest({
                                projectId: props.projectId,
                                params: {
                                    actionType: resourceActionType,
                                    actionParams: new Types_Estimation.SetEstimationResourcesCostClass({
                                        estimationResourceIds: [ props.activeResource.value0.id ],
                                        costClassCode: v15.id
                                    })
                                },
                                handler: function (v16) {
                                    return props.closeModal;
                                }
                            }));
                        });
                    },
                    selected: Data_Maybe.Nothing.value,
                    placeholder: Data_Maybe.fromMaybe("Hae...")(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Functor.map(Data_Functor.functorFn)(function (v15) {
                        return v15.text;
                    })(prepareCostClassDropdownListItem))(selectedCostClass)),
                    testId: "change-cost-class-dropdown"
                });
            };
            if (props.activeResource instanceof Data_Maybe.Nothing) {
                return TacoAutocomplete.component()()({
                    sections: [ {
                        text: Data_Maybe.Nothing.value,
                        list: Data_Functor.map(Data_Functor.functorArray)(prepareCostClassDropdownListItem)(v4.value0)
                    } ],
                    clearButton: false,
                    onSelect: function (item) {
                        return Data_Foldable.for_(Effect.applicativeEffect)(Data_Foldable.foldableMaybe)(item)(function (v15) {
                            var costClass$prime = Data_Array.find(function (cc) {
                                return Data_Eq.eq(Types_Newtypes.eqCostClassCode)(cc.costClassCode)(v15.id);
                            })(v4.value0);
                            return function __do() {
                                v5.value1(function (v16) {
                                    return costClass$prime;
                                })();
                                return copyPriceFromCostClassResourceRow(costClass$prime)();
                            };
                        });
                    },
                    selected: Data_Functor.map(Data_Maybe.functorMaybe)(prepareCostClassDropdownListItem)(v5.value0),
                    placeholder: Data_Maybe.fromMaybe("Hae...")(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Functor.map(Data_Functor.functorFn)(function (v15) {
                        return v15.text;
                    })(prepareCostClassDropdownListItem))(v5.value0)),
                    testId: "select-cost-class-dropdown"
                });
            };
            throw new Error("Failed pattern match at CostEstimation.SelectResource (line 427, column 23 - line 465, column 16): " + [ props.activeResource.constructor.name ]);
        })();
        var wastePercentageInput = TacoInput.numberInput()()(Data_Eq.eqNumber)({
            value: v8.value0,
            onChange: v8.value1,
            precision: 2,
            testId: "select-resource-waste-percentage-input"
        });
        var unitInput = TacoInput.stringInput()()(Data_Eq.eqString)({
            value: v10.value0,
            onChange: v10.value1,
            testId: "select-resource-unit-input"
        });
        var unitConsumptionRateInput = TacoInput.numberInput()()(Data_Eq.eqNumber)({
            value: v11.value0,
            onChange: v11.value1,
            precision: 2,
            testId: "select-resource-unit-consumption-rate-input"
        });
        var taxPercentageInput = TacoInput.numberInput()()(Data_Eq.eqNumber)({
            value: v13.value0,
            onChange: v13.value1,
            precision: 2,
            testId: "select-resource-tax-percentage-input"
        });
        var resourceFilter = TacoInput.stringInput()()(Data_Eq.eqString)({
            value: v3.value0,
            onChange: v3.value1,
            placeholder: "Suodata panoksia",
            autoFocus: true,
            width: Data_Maybe.Just.create(new TacoConstants.CustomSize("16rem")),
            testId: "resource-filter-input"
        });
        var pricePerUnitInput = TacoInput.numberInput()()(Data_Eq.eqNumber)({
            value: v7.value0,
            onChange: v7.value1,
            precision: 2,
            testId: "select-resource-price-per-unit-input"
        });
        var nameInput = TacoInput.stringInput()()(Data_Eq.eqString)({
            value: v6.value0,
            onChange: v6.value1,
            placeholder: "Sy\xf6t\xe4 panoksen nimi",
            autoFocus: true,
            validator: LengthValidator.lengthValidator(0)(100),
            testId: "select-resource-name-input"
        });
        var modalActions = TacoModal.modalActions({
            contents: [ TacoButton.component()()({
                onClick: new Data_Maybe.Just(props.closeModal),
                text: "Sulje",
                color: TacoConstants.SecondaryRed.value,
                testId: "close-select-resource-modal-button"
            }), Data_Monoid.guard(React_Basic.monoidJSX)(isNewResource)(TacoButton.component()()({
                onClick: Data_Maybe.Just.create(saveNewResource),
                text: "Tallenna",
                testId: "submit-select-resource-modal-button"
            })) ]
        });
        var inputsStyles = [ new Box.Border(TacoConstants.BorderLight.value), Box.Width.create(new TacoConstants.CustomSize("100%")), Box.FlexColumn.value, Box.AlignStretch.value ];
        var groupInput = TacoInput.stringInput()()(Data_Eq.eqString)({
            value: v12.value0,
            onChange: v12.value1,
            testId: "select-resource-group-input"
        });
        var discountPercentageInput = TacoInput.numberInput()()(Data_Eq.eqNumber)({
            value: v9.value0,
            onChange: v9.value1,
            precision: 2,
            testId: "select-resource-discount-percentage-input"
        });
        var inputColumns = getResourceColumns({
            inputs: (function () {
                if (isNewResource) {
                    return new Data_Maybe.Just({
                        nameInput: nameInput,
                        pricePerUnitInput: pricePerUnitInput,
                        wastePercentageInput: wastePercentageInput,
                        unitConsumptionRateInput: unitConsumptionRateInput,
                        groupInput: groupInput,
                        unitInput: unitInput,
                        discountPercentageInput: discountPercentageInput,
                        taxPercentageInput: taxPercentageInput,
                        costClassInput: selectCostClass
                    });
                };
                return Data_Maybe.Nothing.value;
            })(),
            componentAmount: props.componentAmount,
            componentUnit: props.componentUnit,
            socialExpensePercentagesByCostClass: props.socialExpensePercentagesByCostClass,
            projectCurrency: props.projectCurrency
        })(groupsLicenseEnabled);
        var header = TacoTable_Header.simpleHeader(TofuHooks.getNameVoid)(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "socialExpensePercentage";
            }
        })(Data_Maybe.eqMaybe(Types_Unit.eqSocialExpensePercentage)))()({
            reflectSymbol: function () {
                return "showWorkAchievement";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "resourceValues";
            }
        })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "wastePercentage";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "unitConsumptionRate";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "unit";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "taxPercentage";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "pricePerUnit";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "name";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "multiplier";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "isCostClassPrice";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "group";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "discountPercentage";
            }
        })(Data_Eq.eqNumber))))()({
            reflectSymbol: function () {
                return "isSelected";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "isNewResource";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "costClass";
            }
        })(Data_Maybe.eqMaybe(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "socialExpensePercentageInCostEstimation";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
            reflectSymbol: function () {
                return "socialExpensePercentageInCostControlForTargets";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
            reflectSymbol: function () {
                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
            reflectSymbol: function () {
                return "socialExpensePercentageInCostControlForComponents";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.eqProjectId))()({
            reflectSymbol: function () {
                return "name";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "multiplier";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "id";
            }
        })(Types_CostClass.eqCostClassId))()({
            reflectSymbol: function () {
                return "hourlyPricing";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "costClassCode";
            }
        })(Types_Newtypes.eqCostClassCode)))))()({
            reflectSymbol: function () {
                return "componentAmount";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "classificationGroupCodePlaceholder";
            }
        })(Data_Eq.eqString)))(inputColumns)(TacoTable_Styles.defaultHeaderStyleSet);
        var row = TacoTable_Row.simpleRow(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "socialExpensePercentage";
            }
        })(Data_Maybe.eqMaybe(Types_Unit.eqSocialExpensePercentage)))()({
            reflectSymbol: function () {
                return "showWorkAchievement";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "resourceValues";
            }
        })(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "wastePercentage";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "unitConsumptionRate";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "unit";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "taxPercentage";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "pricePerUnit";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "name";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "multiplier";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "isCostClassPrice";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "group";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "discountPercentage";
            }
        })(Data_Eq.eqNumber))))()({
            reflectSymbol: function () {
                return "isSelected";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "isNewResource";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "costClass";
            }
        })(Data_Maybe.eqMaybe(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "socialExpensePercentageInCostEstimation";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
            reflectSymbol: function () {
                return "socialExpensePercentageInCostControlForTargets";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
            reflectSymbol: function () {
                return "socialExpensePercentageInCostControlForEstimatesAndActuals";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
            reflectSymbol: function () {
                return "socialExpensePercentageInCostControlForComponents";
            }
        })(Data_Nullable.eqNullable(Data_Eq.eqNumber)))()({
            reflectSymbol: function () {
                return "projectId";
            }
        })(Types_Project.eqProjectId))()({
            reflectSymbol: function () {
                return "name";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "multiplier";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "id";
            }
        })(Types_CostClass.eqCostClassId))()({
            reflectSymbol: function () {
                return "hourlyPricing";
            }
        })(Data_Eq.eqBoolean))()({
            reflectSymbol: function () {
                return "costClassCode";
            }
        })(Types_Newtypes.eqCostClassCode)))))()({
            reflectSymbol: function () {
                return "componentAmount";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "classificationGroupCodePlaceholder";
            }
        })(Data_Eq.eqString)))("select-resource-inputs-row")(inputColumns)(TacoTable_Styles.defaultRowStyleSet)({
            resourceValues: (function () {
                if (props.activeResource instanceof Data_Maybe.Just) {
                    return {
                        name: props.activeResource.value0.name,
                        group: Utils.nullable("")(Data_Newtype.un()(Types_ClassificationGroup.ClassificationGroupCode))(props.activeResource.value0.group),
                        unit: Utils.fromNullable("")(props.activeResource.value0.unit),
                        pricePerUnit: Utils.fromNullable(0.0)(props.activeResource.value0.pricePerUnit),
                        unitConsumptionRate: props.activeResource.value0.unitConsumptionRate,
                        wastePercentage: props.activeResource.value0.wastePercentage,
                        isCostClassPrice: props.activeResource.value0.isCostClassPrice,
                        discountPercentage: props.activeResource.value0.discountPercentage,
                        taxPercentage: Data_Newtype.un()(Types_Unit.Taxrate)(props.activeResource.value0.taxPercentage),
                        multiplier: props.activeResource.value0.multiplier
                    };
                };
                if (props.activeResource instanceof Data_Maybe.Nothing) {
                    return {
                        name: v6.value0,
                        group: v12.value0,
                        unit: v10.value0,
                        pricePerUnit: v7.value0,
                        unitConsumptionRate: v11.value0,
                        wastePercentage: v8.value0,
                        isCostClassPrice: v14.value0,
                        discountPercentage: v9.value0,
                        taxPercentage: v13.value0,
                        multiplier: 1.0
                    };
                };
                throw new Error("Failed pattern match at CostEstimation.SelectResource (line 554, column 27 - line 578, column 16): " + [ props.activeResource.constructor.name ]);
            })(),
            isSelected: props.isSelected,
            socialExpensePercentage: (function () {
                if (props.activeResource instanceof Data_Maybe.Just) {
                    return Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Data_Function.flip(Data_Map_Internal.lookup(Types_Newtypes.ordCostClassCode))(props.socialExpensePercentagesByCostClass))(Data_Nullable.toMaybe(props.activeResource.value0.costClassCode));
                };
                if (props.activeResource instanceof Data_Maybe.Nothing) {
                    return Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(Data_Function.flip(Data_Map_Internal.lookup(Types_Newtypes.ordCostClassCode))(props.socialExpensePercentagesByCostClass))(Data_Functor.map(Data_Maybe.functorMaybe)(function (v15) {
                        return v15.costClassCode;
                    })(v5.value0));
                };
                throw new Error("Failed pattern match at CostEstimation.SelectResource (line 580, column 36 - line 582, column 117): " + [ props.activeResource.constructor.name ]);
            })(),
            showWorkAchievement: props.showWorkAchievement,
            componentAmount: props.componentAmount,
            classificationGroupCodePlaceholder: props.classificationGroupCodePlaceholder,
            isNewResource: isNewResource,
            costClass: (function () {
                if (props.activeResource instanceof Data_Maybe.Nothing) {
                    return v5.value0;
                };
                if (props.activeResource instanceof Data_Maybe.Just) {
                    return Data_Array.find(function (cc) {
                        return Data_Eq.eq(Data_Nullable.eqNullable(Types_Newtypes.eqCostClassCode))(Utils.pureNullable(cc.costClassCode))(props.activeResource.value0.costClassCode);
                    })(v4.value0);
                };
                throw new Error("Failed pattern match at CostEstimation.SelectResource (line 587, column 22 - line 590, column 97): " + [ props.activeResource.constructor.name ]);
            })()
        });
        var inputs = Box.box("selct-resource-inputs")(inputsStyles)([ header, row ]);
        var modalBody = TacoModal.modalBody({
            contents: [ TacoText.component()()({
                text: "Panoksen tiedot",
                weight: TacoText_Types.Bold.value
            }), inputs, TacoText.component()()({
                text: "Hankkeella jo olevat panokset ",
                children: [ React_Basic_DOM_Generated.var_([ React_Basic_DOM.text(Data_Show.show(Data_Show.showInt)(Data_Array.length(filteredProjectEstimationResources))) ]) ],
                weight: TacoText_Types.Bold.value
            }), Data_Monoid.guard(React_Basic.monoidJSX)(!isNewResource)(resourceFilter), existingProjectResources, TacoText.component()()({
                text: "Viitehankkeiden panokset, joita ei viel\xe4 hankkeella ",
                children: [ React_Basic_DOM_Generated.var_([ React_Basic_DOM.text(Data_Show.show(Data_Show.showInt)(Data_Array.length(filteredReferenceEstimationResources))) ]) ],
                weight: TacoText_Types.Bold.value
            }), matchingResourcesWithoutUsages, TacoText.component()()({
                text: "Panoskirjasto ",
                children: [ React_Basic_DOM_Generated.var_([ React_Basic_DOM.text(Data_Show.show(Data_Show.showInt)(Data_Array.length(filteredOrgResources))) ]) ],
                weight: TacoText_Types.Bold.value
            }), orgResourcesTable ]
        });
        var modal = TacoModal.component()()({
            contents: [ React_Basic_DOM_Generated.form()({
                children: [ modalBody, modalActions ],
                style: React_Basic_DOM_Internal.css({
                    height: React_Basic_Emotion.str("100%")
                })
            }) ],
            onClose: props.closeModal,
            isActive: props.modalOpen,
            heading: (function () {
                if (isNewResource) {
                    return "Lis\xe4\xe4 panos";
                };
                return "Vaihda panos";
            })(),
            containerStyles: [ new Box.Width(new TacoConstants.CustomSize("90vw")), new Box.Height(new TacoConstants.CustomSize("90vh")) ]
        });
        return modal;
    };
});
module.exports = {
    prepareOrgResources: prepareOrgResources,
    prepareProjectResources: prepareProjectResources,
    prepareReferenceProjectResources: prepareReferenceProjectResources,
    getMatchingResources: getMatchingResources,
    selectResourceComponent: selectResourceComponent,
    getResourceColumns: getResourceColumns
};
