export const getCurrencyToLocale = (currency: string | null | undefined) => {
  if (currency && isCurrencyInAllowed(currency)) {
    return getCurrencySymbol(currency);
  }
  console.warn(
    `Currency ${currency} not supported, defaulting to ${defaultCurrency}`
  );
  return getCurrencySymbol(defaultCurrency);
};
const getCurrencySymbol = (currency: keyof typeof currencyToLocale) => {
  const numberFormat = new Intl.NumberFormat(currencyToLocale[currency], {
    style: "currency",
    currency,
  });
  return numberFormat.formatToParts(1).find((part) => part.type === "currency")
    ?.value;
};

const isCurrencyInAllowed = (
  currency: string
): currency is keyof typeof currencyToLocale => currency in currencyToLocale;

export const defaultCurrency: keyof typeof currencyToLocale = "EUR";

export const currencyToLocale = {
  EUR: "fi-FI",
  SEK: "sv-SE",
  NOK: "nb-NO",
  DKK: "da-DK",
  GBP: "en-GB",
  USD: "en-US",
};
export const allowedCurrencies = Object.keys(currencyToLocale);

const makeCurrencyFormatter = (locale: string, currency: string) =>
  new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

const currencyFormatters = Object.fromEntries(
  Object.entries(currencyToLocale).map(([currency, locale]) => [
    currency,
    makeCurrencyFormatter(locale, currency),
  ])
);

const makeCurrencyFormatterWithoutDecimals = (
  locale: string,
  currency: string
) =>
  new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

const currencyFormattersWithoutDecimals = Object.fromEntries(
  Object.entries(currencyToLocale).map(([currency, locale]) => [
    currency,
    makeCurrencyFormatterWithoutDecimals(locale, currency),
  ])
);

export const formatCurrencyValue = (currency: string, value: number) => {
  if (currency in currencyFormatters) {
    return currencyFormatters[currency].format(value);
  } else {
    return currencyFormatters["EUR"].format(value);
  }
};
export const formatCurrencyValueWithoutDecimals = (
  currency: string,
  value: number
) => {
  if (currency in currencyFormatters) {
    return currencyFormattersWithoutDecimals[currency].format(value);
  } else {
    return currencyFormattersWithoutDecimals["EUR"].format(value);
  }
};
