// Generated by purs version 0.14.5
"use strict";
var Text = (function () {
    function Text() {

    };
    Text.value = new Text();
    return Text;
})();
var Paragraph = (function () {
    function Paragraph() {

    };
    Paragraph.value = new Paragraph();
    return Paragraph;
})();
var Heading = (function () {
    function Heading(value0) {
        this.value0 = value0;
    };
    Heading.create = function (value0) {
        return new Heading(value0);
    };
    return Heading;
})();
var Label = (function () {
    function Label(value0) {
        this.value0 = value0;
    };
    Label.create = function (value0) {
        return new Label(value0);
    };
    return Label;
})();
var NormalWrap = (function () {
    function NormalWrap() {

    };
    NormalWrap.value = new NormalWrap();
    return NormalWrap;
})();
var NoWrap = (function () {
    function NoWrap() {

    };
    NoWrap.value = new NoWrap();
    return NoWrap;
})();
var None = (function () {
    function None() {

    };
    None.value = new None();
    return None;
})();
var Uppercase = (function () {
    function Uppercase() {

    };
    Uppercase.value = new Uppercase();
    return Uppercase;
})();
var Lowercase = (function () {
    function Lowercase() {

    };
    Lowercase.value = new Lowercase();
    return Lowercase;
})();
var Capitalize = (function () {
    function Capitalize() {

    };
    Capitalize.value = new Capitalize();
    return Capitalize;
})();
var TextOverflowVisible = (function () {
    function TextOverflowVisible() {

    };
    TextOverflowVisible.value = new TextOverflowVisible();
    return TextOverflowVisible;
})();
var TextOverflowEllipsis = (function () {
    function TextOverflowEllipsis() {

    };
    TextOverflowEllipsis.value = new TextOverflowEllipsis();
    return TextOverflowEllipsis;
})();
var Left = (function () {
    function Left() {

    };
    Left.value = new Left();
    return Left;
})();
var Center = (function () {
    function Center() {

    };
    Center.value = new Center();
    return Center;
})();
var Right = (function () {
    function Right() {

    };
    Right.value = new Right();
    return Right;
})();
var Normal = (function () {
    function Normal() {

    };
    Normal.value = new Normal();
    return Normal;
})();
var Bold = (function () {
    function Bold() {

    };
    Bold.value = new Bold();
    return Bold;
})();
var weightToStr = function (v) {
    if (v instanceof Normal) {
        return "normal";
    };
    if (v instanceof Bold) {
        return "bold";
    };
    throw new Error("Failed pattern match at TacoText.Types (line 6, column 1 - line 6, column 36): " + [ v.constructor.name ]);
};
var textWrapToStr = function (v) {
    if (v instanceof NormalWrap) {
        return "normal";
    };
    if (v instanceof NoWrap) {
        return "nowrap";
    };
    throw new Error("Failed pattern match at TacoText.Types (line 31, column 1 - line 31, column 36): " + [ v.constructor.name ]);
};
var textTransformToStr = function (v) {
    if (v instanceof None) {
        return "none";
    };
    if (v instanceof Uppercase) {
        return "uppercase";
    };
    if (v instanceof Lowercase) {
        return "lowercase";
    };
    if (v instanceof Capitalize) {
        return "capitalize";
    };
    throw new Error("Failed pattern match at TacoText.Types (line 12, column 1 - line 12, column 46): " + [ v.constructor.name ]);
};
var textAlignToStr = function (v) {
    if (v instanceof Left) {
        return "left";
    };
    if (v instanceof Center) {
        return "center";
    };
    if (v instanceof Right) {
        return "right";
    };
    throw new Error("Failed pattern match at TacoText.Types (line 21, column 1 - line 21, column 38): " + [ v.constructor.name ]);
};
var eqTextAlign = {
    eq: function (x) {
        return function (y) {
            if (x instanceof Left && y instanceof Left) {
                return true;
            };
            if (x instanceof Center && y instanceof Center) {
                return true;
            };
            if (x instanceof Right && y instanceof Right) {
                return true;
            };
            return false;
        };
    }
};
module.exports = {
    Normal: Normal,
    Bold: Bold,
    weightToStr: weightToStr,
    None: None,
    Uppercase: Uppercase,
    Lowercase: Lowercase,
    Capitalize: Capitalize,
    textTransformToStr: textTransformToStr,
    Left: Left,
    Center: Center,
    Right: Right,
    textAlignToStr: textAlignToStr,
    Text: Text,
    Paragraph: Paragraph,
    Heading: Heading,
    Label: Label,
    NormalWrap: NormalWrap,
    NoWrap: NoWrap,
    textWrapToStr: textWrapToStr,
    TextOverflowVisible: TextOverflowVisible,
    TextOverflowEllipsis: TextOverflowEllipsis,
    eqTextAlign: eqTextAlign
};
