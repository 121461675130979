// Generated by purs version 0.14.5
"use strict";
var Data_Monoid = require("../Data.Monoid/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var useUpdateOnChange = function (dictEq) {
    return function (value) {
        return function (setValue) {
            return function __do() {
                var cachedValue = TofuHooks.unsafeDiscardRenderEffects(React_Basic_Hooks.useEqCache(dictEq)(value))();
                return TofuHooks.useLayoutEffect([ cachedValue ])(function __do() {
                    setValue(function (v) {
                        return value;
                    })();
                    return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                })();
            };
        };
    };
};
module.exports = {
    useUpdateOnChange: useUpdateOnChange
};
