// Generated by purs version 0.14.5
"use strict";
var Data_Eq = require("../Data.Eq/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Tuple = require("../Data.Tuple/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var useUpdatingState$prime = function (dictEq) {
    return function (key) {
        return function (initialValue) {
            return function __do() {
                var v = TofuHooks.useState(initialValue)();
                TofuHooks.useEffect(key)(function __do() {
                    (function () {
                        var $7 = Data_Eq.notEq(dictEq)(v.value0)(initialValue);
                        if ($7) {
                            return v.value1(function (v1) {
                                return initialValue;
                            })();
                        };
                        return Data_Unit.unit;
                    })();
                    return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
                })();
                return new Data_Tuple.Tuple(v.value0, v.value1);
            };
        };
    };
};
var useUpdatingState = function (dictEq) {
    return function (initialValue) {
        return function __do() {
            var v = TofuHooks.useState(initialValue)();
            TofuHooks.useEffect([ initialValue ])(function __do() {
                (function () {
                    var $11 = Data_Eq.notEq(dictEq)(v.value0)(initialValue);
                    if ($11) {
                        return v.value1(function (v1) {
                            return initialValue;
                        })();
                    };
                    return Data_Unit.unit;
                })();
                return Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit));
            })();
            return new Data_Tuple.Tuple(v.value0, v.value1);
        };
    };
};
module.exports = {
    useUpdatingState: useUpdatingState,
    "useUpdatingState'": useUpdatingState$prime
};
