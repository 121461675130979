// Generated by purs version 0.14.5
"use strict";
var Foreign_Object = require("../Foreign.Object/index.js");
var mkTestIdAttrs = function (testId) {
    var $0 = testId === "";
    if ($0) {
        return Foreign_Object.empty;
    };
    return Foreign_Object.fromHomogeneous()({
        testid: testId
    });
};
module.exports = {
    mkTestIdAttrs: mkTestIdAttrs
};
