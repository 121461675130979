// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var CostEstimation_ResourcesView_MatchResourcesModal = require("../CostEstimation.ResourcesView.MatchResourcesModal/index.js");
var CostEstimation_ResourcesView_SelectResourceUpdateCriteriaModal = require("../CostEstimation.ResourcesView.SelectResourceUpdateCriteriaModal/index.js");
var CostEstimation_ResourcesView_Types = require("../CostEstimation.ResourcesView.Types/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Set = require("../Data.Set/index.js");
var Locale = require("../Locale/index.js");
var React_Basic = require("../React.Basic/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoIcon_Icons_Types = require("../TacoIcon.Icons.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var component = TofuHooks.mkHookComponent("CostEstimation.ResourcesView.UpdateResources")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState(CostEstimation_ResourcesView_Types.Closed.value)();
        var dispatch = StateHooks.useDispatch();
        var openMatchResourcesModal = function (matchingRule) {
            return function (priceList) {
                var mergedResourceIds = Data_Functor.map(Data_Functor.functorArray)(function (v2) {
                    return v2.id;
                })(Data_Array.fromFoldable(Data_Set.foldableSet)(v.selectedResources));
                var priceMatchActionParams = {
                    resourceIds: mergedResourceIds,
                    pricelistName: priceList.orgPriceListId,
                    matchingRule: matchingRule,
                    projectId: v.projectId
                };
                return function __do() {
                    dispatch(new Actions.EstimationResourcePriceMatchActionRequest(priceMatchActionParams))();
                    return v1.value1(function (v2) {
                        return CostEstimation_ResourcesView_Types.MatchResources.value;
                    })();
                };
            };
        };
        var findPricesButton = (function () {
            var $8 = Data_Set.isEmpty(v.selectedResources);
            if ($8) {
                return React_Basic.empty;
            };
            return TacoButton.component()()({
                onClick: Data_Maybe.Just.create(v1.value1(function (v2) {
                    return CostEstimation_ResourcesView_Types.SelectUpdateCriteria.value;
                })),
                text: Locale.lookup_("find_new_prices"),
                icon: new Data_Maybe.Just(TacoIcon_Icons_Types.IconFetch.value),
                color: TacoConstants.componentColors.border,
                buttonStyle: TacoButton_Types.Filled.value,
                size: TacoConstants.L.value,
                testId: "find-new-prices-button"
            });
        })();
        var closeModal = v1.value1(function (v2) {
            return CostEstimation_ResourcesView_Types.Closed.value;
        });
        var modal = (function () {
            if (v1.value0 instanceof CostEstimation_ResourcesView_Types.SelectUpdateCriteria) {
                return CostEstimation_ResourcesView_SelectResourceUpdateCriteriaModal.component({
                    closeModal: closeModal,
                    openMatchResourcesModal: openMatchResourcesModal
                });
            };
            if (v1.value0 instanceof CostEstimation_ResourcesView_Types.MatchResources) {
                return CostEstimation_ResourcesView_MatchResourcesModal.component({
                    closeModal: closeModal,
                    estimationResources: v.selectedResources,
                    projectId: v.projectId
                });
            };
            return React_Basic.empty;
        })();
        return React_Basic.fragment([ findPricesButton, modal ]);
    };
});
module.exports = {
    component: component
};
