// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var ReducerUtils = require("../ReducerUtils/index.js");
var Types_Alert = require("../Types.Alert/index.js");
var onNonEmptyMsg = function (s) {
    return function (level) {
        return function (msg) {
            var $2 = Data_String_Common["null"](msg);
            if ($2) {
                return s;
            };
            return Data_Array.cons(Types_Alert.mkAlert(level)(msg))(s);
        };
    };
};
var update = function (s) {
    return function (v) {
        if (v instanceof Actions.AddAlert) {
            return onNonEmptyMsg(s)(v.value0)(v.value1);
        };
        if (v instanceof Actions.DismissAlert) {
            if (v.value0.mId instanceof Data_Maybe.Just) {
                return Data_Array.filter((function () {
                    var $11 = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraFunction(Data_HeytingAlgebra.heytingAlgebraBoolean)))(Data_Eq.eq(Data_Eq.eqString))(v.value0.mId.value0);
                    return function ($12) {
                        return $11((function (v1) {
                            return v1.id;
                        })($12));
                    };
                })())(s);
            };
            if (v.value0.mId instanceof Data_Maybe.Nothing) {
                return Data_Maybe.fromMaybe([  ])(Data_Array.tail(s));
            };
            throw new Error("Failed pattern match at AlertsReducer (line 25, column 3 - line 27, column 43): " + [ v.value0.mId.constructor.name ]);
        };
        return s;
    };
};
var initialState = [  ];
var reducer = ReducerUtils.mkReducer(initialState)(update);
module.exports = {
    initialState: initialState,
    onNonEmptyMsg: onNonEmptyMsg,
    update: update,
    reducer: reducer
};
