// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var tableTabsViewStyles = Box.boxStyle([ new Box.PaddingRight(TacoConstants.M.value), new Box.PaddingBottom(TacoConstants.M.value) ]);
var memoContainerStyles = Box.boxStyle([ Box.Width.create(new TacoConstants.CustomSize("100%")), new Box.Selector("textarea", [ Box.Width.create(new TacoConstants.CustomSize("100%")) ]), new Box.Padding(TacoConstants.M.value), Box.PaddingTop.create(new TacoConstants.CustomSize("0")) ]);
module.exports = {
    tableTabsViewStyles: tableTabsViewStyles,
    memoContainerStyles: memoContainerStyles
};
