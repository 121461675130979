// Generated by purs version 0.14.5
"use strict";
var Calculation_Income = require("../Calculation.Income/index.js");
var Calculation_Utils = require("../Calculation.Utils/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Array_NonEmpty = require("../Data.Array.NonEmpty/index.js");
var Data_Array_NonEmpty_Internal = require("../Data.Array.NonEmpty.Internal/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_JSDate = require("../Data.JSDate/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Nullable = require("../Data.Nullable/index.js");
var Data_Number = require("../Data.Number/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ring = require("../Data.Ring/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodePoints = require("../Data.String.CodePoints/index.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var DateFnsFFI = require("../DateFnsFFI/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var Foreign_Object = require("../Foreign.Object/index.js");
var OwnProjectTaxes = require("../OwnProjectTaxes/index.js");
var Record = require("../Record/index.js");
var StringUtils = require("../StringUtils/index.js");
var Types_CostGroup = require("../Types.CostGroup/index.js");
var Types_Newtypes = require("../Types.Newtypes/index.js");
var Types_R200 = require("../Types.R200/index.js");
var Types_ReasonableCoerce = require("../Types.ReasonableCoerce/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var Utils = require("../Utils/index.js");
var R200SocialExpensesRow = function (x) {
    return x;
};
var R200CostGroupItemPlaceholder = function (x) {
    return x;
};
var toR200CostGroupItemPlaceholderArray = Types_ReasonableCoerce.reasonableCoerce("we know that we are passing in cost group items");
var toR200CostGroupItemPlaceholder = function (r) {
    return Types_ReasonableCoerce.reasonableCoerce("restricting available fields")(r);
};
var takeMainCostGroupDigit = function (v) {
    return Data_String_CodePoints.take(1)(v);
};
var newtypeR200SocialExpensesRow = {
    Coercible0: function () {
        return undefined;
    }
};
var newtypeCGIP = {
    Coercible0: function () {
        return undefined;
    }
};
var nameS = Data_Symbol.SProxy.value;
var memoS = Data_Symbol.SProxy.value;
var itemsS = Data_Symbol.SProxy.value;
var getMCG89ChartData = function (r) {
    var sumByTargetCosts = (function () {
        var $52 = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber);
        var $53 = Data_Functor.map(Data_Functor.functorArray)(function (v) {
            return v.targetCosts;
        });
        return function ($54) {
            return $52($53($54));
        };
    })();
    var selectedMonths = DateFnsFFI.monthsBetween(r.startMonth)(r.endMonth);
    var mcg9actuals = Data_Array.filter(function (i) {
        return StringUtils.startsWith("9")(Data_Maybe.fromMaybe("")(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Newtype.unwrap())(Data_Nullable.toMaybe(i.costGroupCode))));
    })(r.invoices);
    var mcg9CostItems = Data_Array.filter(function (i) {
        return StringUtils.startsWith("9")(i.costGroupCode);
    })(r.costItems);
    var mcg9TotalTarget = sumByTargetCosts(mcg9CostItems);
    var mcg8actuals = Data_Array.filter(function (i) {
        return StringUtils.startsWith("8")(Data_Maybe.fromMaybe("")(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Newtype.unwrap())(Data_Nullable.toMaybe(i.costGroupCode))));
    })(r.invoices);
    var mcg8CostItems = Data_Array.filter(function (i) {
        return StringUtils.startsWith("8")(i.costGroupCode);
    })(r.costItems);
    var mcg8TotalTarget = sumByTargetCosts(mcg8CostItems);
    var getActualsBeforeDate = function (actuals) {
        return function (target) {
            return function (date) {
                var sumByValue = (function () {
                    var $55 = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber);
                    var $56 = Data_Functor.map(Data_Functor.functorArray)(function (v) {
                        return v.value;
                    });
                    return function ($57) {
                        return $55($56($57));
                    };
                })();
                var filterDate = Data_Array.filter(function (x) {
                    return Data_Ord.lessThan(Data_Nullable.ordNullable(Data_JSDate.ordJSDate))(Utils.mapNullable(Utils.lossyParseJSDate)(x.date))(Utils.pureNullable(date));
                });
                return (sumByValue(filterDate(actuals)) / target) * 100.0;
            };
        };
    };
    var getMCG8ActualsByMonth = Data_Functor.map(Data_Functor.functorArray)(getActualsBeforeDate(mcg8actuals)(mcg8TotalTarget))(selectedMonths);
    var getMCG9ActualsByMonth = Data_Functor.map(Data_Functor.functorArray)(getActualsBeforeDate(mcg9actuals)(mcg9TotalTarget))(selectedMonths);
    var chartData = [ {
        data: getMCG8ActualsByMonth,
        name: "8 P\xe4\xe4ryhm\xe4n toteutuneet"
    }, {
        data: getMCG9ActualsByMonth,
        name: "9 P\xe4\xe4ryhm\xe4n toteutuneet"
    } ];
    var v = Data_Nullable.toMaybe(r.projectEndDate);
    var v1 = Data_Nullable.toMaybe(r.projectStartDate);
    if (v1 instanceof Data_Maybe.Just && v instanceof Data_Maybe.Just) {
        var startDate$prime = Effect_Unsafe.unsafePerformEffect(Data_JSDate.parse(v1.value0));
        var endDate$prime = Effect_Unsafe.unsafePerformEffect(Data_JSDate.parse(v.value0));
        var constructionTime = Data_Int.toNumber(DateFnsFFI.differenceInCalendarDays(endDate$prime)(startDate$prime));
        var getDaysPassedBeforeDate = function (date) {
            return (Data_Int.toNumber(DateFnsFFI.differenceInCalendarDays(date)(startDate$prime)) / constructionTime) * 100.0;
        };
        var daysPassedByMonth = Data_Functor.map(Data_Functor.functorArray)(getDaysPassedBeforeDate)(selectedMonths);
        return Data_Semigroup.append(Data_Semigroup.semigroupArray)(chartData)([ {
            data: daysPassedByMonth,
            name: "Toteutunut aika"
        } ]);
    };
    return chartData;
};
var fromR200CostGroupItemPlaceholder = Data_Newtype.un()(R200CostGroupItemPlaceholder);
var displayNameS = Data_Symbol.SProxy.value;
var displayCodeS = Data_Symbol.SProxy.value;
var defaultZero = function (number) {
    var $34 = Data_Number["isFinite"](number);
    if ($34) {
        return number;
    };
    return 0.0;
};
var defaultZeroDiv = function (x) {
    return function (y) {
        return defaultZero(x / y);
    };
};
var getDiffRatios = function (r) {
    var diffOfTargetToEstimate = r.targetCosts - r.estimatedCosts;
    return {
        diffOfTargetToEstimate: diffOfTargetToEstimate,
        diffOfTargetToEstimateRatio: defaultZeroDiv(diffOfTargetToEstimate)(r.targetCosts),
        actualToEstimateRatio: defaultZeroDiv(r.actualCosts)(r.estimatedCosts)
    };
};
var getMonthlyReportData = function (r) {
    var socialExpensesForTargetCosts = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
        return v.targetSocialExpenses;
    })(r.socialExpenses));
    var targetCostsSum = OwnProjectTaxes.applyTaxesForCosts(r.projectTaxStatus)(socialExpensesForTargetCosts + Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (v) {
        return v.targetCosts;
    })(r.costItems)));
    var selectedMonths = DateFnsFFI.monthsBetween(r.startMonth)(r.endMonth);
    var prepareDiffChartDatum = function (cd) {
        var prepareInnerData = function (monthIndex) {
            return function (item) {
                if (monthIndex === 0) {
                    return 0.0;
                };
                var v = function (v1) {
                    if (Data_Boolean.otherwise) {
                        return 0.0;
                    };
                    throw new Error("Failed pattern match at R200Calculations (line 374, column 1 - line 400, column 23): " + [ monthIndex.constructor.name, item.constructor.name ]);
                };
                var $41 = Data_Array.index(cd.data)(monthIndex - 1 | 0);
                if ($41 instanceof Data_Maybe.Just) {
                    return item - $41.value0;
                };
                return v(true);
            };
        };
        return {
            data: Data_Array.mapWithIndex(prepareInnerData)(cd.data)
        };
    };
    var mcg89ChartData = getMCG89ChartData({
        invoices: r.invoices,
        startMonth: r.startMonth,
        endMonth: r.endMonth,
        currentDate: r.currentDate,
        costItems: r.costItems,
        projectStartDate: r.projectStartDate,
        projectEndDate: r.projectEndDate
    });
    var incomeInfo = Calculation_Income.mkR200IncomeInformationResults(Calculation_Income.UseAnyIncomeItemsforActual.value)(r.projectTaxStatus)(Calculation_Income.ShowUnallocatedSalesInvoices.value)(r.incomeItems)([  ])(Calculation_Utils.NoDateLimit.value);
    var targetIncomeSum = OwnProjectTaxes.TotalWithTaxes((function (v) {
        return v.target;
    })(Data_Newtype.un()(Types_R200.R200IncomeInformationResult)(incomeInfo.totals)));
    var getValueWithEstimateActualsSocialExpenses = function (estimateItem) {
        var matchCostClassCode = function (costClass) {
            return Data_Eq.eq(Types_Newtypes.eqCostClassCode)(costClass.costClassCode)(estimateItem.costClassCode);
        };
        var mSocialExpensePercentage = Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(function ($58) {
            return Data_Nullable.toMaybe((function (v) {
                return v.socialExpensePercentageInCostControlForEstimatesAndActuals;
            })($58));
        })(Data_Array.find(matchCostClassCode)(r.costClasses));
        var socialExpensePercentage = Data_Maybe.fromMaybe(0.0)(mSocialExpensePercentage);
        return socialExpensePercentage * estimateItem.value + estimateItem.value;
    };
    var sumUsingSocialExpenses = (function () {
        var $59 = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber);
        var $60 = Data_Functor.map(Data_Functor.functorArray)(getValueWithEstimateActualsSocialExpenses);
        return function ($61) {
            return $59($60($61));
        };
    })();
    var getEstimateBeforeDate = function (costItem) {
        return function (date) {
            var mainCostGroupCodeDigit = Data_String_CodePoints.take(1)(costItem.displayCode);
            var filterDate = Data_Array.filter(function (x) {
                return Data_Ord.lessThan(Data_Nullable.ordNullable(Data_JSDate.ordJSDate))(Utils.mapNullable(Utils.lossyParseJSDate)(x.modifyDate))(Utils.pureNullable(date));
            });
            var filterCostGroupCode = Data_Array.filter(function (x) {
                return takeMainCostGroupDigit(x.costGroupCode) === mainCostGroupCodeDigit;
            });
            return sumUsingSocialExpenses(filterDate(filterCostGroupCode(r.estimateItems)));
        };
    };
    var getEstimateSumBeforeDate = function (date) {
        return (function (v) {
            return v.total;
        })(OwnProjectTaxes.applyTaxesForCosts(r.projectTaxStatus)(Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(function (costItem) {
            return getEstimateBeforeDate(costItem)(date);
        })(r.costItems))));
    };
    var prepareChartDatum = function (costItem) {
        var estimatesByMonth = Data_Functor.map(Data_Functor.functorArray)(getEstimateBeforeDate(costItem))(selectedMonths);
        return {
            data: Data_Semigroup.append(Data_Semigroup.semigroupArray)([ costItem.targetCosts + costItem.targetSocialExpenses ])(estimatesByMonth),
            name: costItem.displayName
        };
    };
    var getCumulativeProfitMarginRatio = function (incomeSum) {
        return function (costSum) {
            var difference = Data_Ring.sub(OwnProjectTaxes.ringTotalWithTaxes)(incomeSum)(costSum);
            return Data_Newtype.over2()()(OwnProjectTaxes.TotalWithTaxes)(defaultZeroDiv)(difference)(incomeSum);
        };
    };
    var targetProfitMargin = getCumulativeProfitMarginRatio(targetIncomeSum)(targetCostsSum.total);
    var formattedMonths = Data_Functor.map(Data_Functor.functorArray)(function (month) {
        return DateFnsFFI.format(month)("M/YY");
    })(selectedMonths);
    var estimateIncomeSum = (function (v) {
        return v.total;
    })(Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringRecord()(Data_Semiring.semiringRecordCons({
        reflectSymbol: function () {
            return "taxes";
        }
    })()(Data_Semiring.semiringRecordCons({
        reflectSymbol: function () {
            return "total";
        }
    })()(Data_Semiring.semiringRecordNil)(OwnProjectTaxes.semiringTotalWithTaxes))(Data_Semiring.semiringNumber)))(Data_Functor.map(Data_Functor.functorArray)(OwnProjectTaxes.applyTaxesForIncomeItems(r.projectTaxStatus)(function (v) {
        return v.valueWithoutVAT;
    }))(r.incomeItems)));
    var getCumulativeProfitMarginBeforeDate = function (date) {
        var estimateSum = getEstimateSumBeforeDate(date);
        return getCumulativeProfitMarginRatio(estimateIncomeSum)(estimateSum);
    };
    var cumulativeProfitMargins = Data_Semigroup.append(Data_Semigroup.semigroupArray)([ targetProfitMargin ])(Data_Functor.map(Data_Functor.functorArray)(getCumulativeProfitMarginBeforeDate)(selectedMonths));
    var chartDataSumHorizontal = (function () {
        var targetCosts = {
            data: Data_Functor.map(Data_Functor.functorArray)(function (item) {
                return item.targetCosts + item.targetSocialExpenses;
            })(r.costItems),
            name: "Tavoite"
        };
        var getEstimateCost = function (month) {
            var estimatesBeforeDateByCostItem = Data_Functor.map(Data_Functor.functorArray)(function (x) {
                return getEstimateBeforeDate(x)(month);
            })(r.costItems);
            return {
                name: DateFnsFFI.format(month)("M/YY"),
                data: estimatesBeforeDateByCostItem
            };
        };
        var estimateCosts = Data_Functor.map(Data_Functor.functorArray)(getEstimateCost)(selectedMonths);
        return Data_Semigroup.append(Data_Semigroup.semigroupArray)([ targetCosts ])(estimateCosts);
    })();
    var chartData = Data_Functor.map(Data_Functor.functorArray)(prepareChartDatum)(r.costItems);
    var diffChartData = Data_Functor.map(Data_Functor.functorArray)(prepareDiffChartDatum)(chartData);
    var _earliestEstimateItem = Data_Foldable.minimumBy(Data_Foldable.foldableArray)(function (a) {
        return function (b) {
            return Data_Ord.compare(Data_Nullable.ordNullable(Data_Ord.ordString))(a.modifyDate)(b.modifyDate);
        };
    })(r.estimateItems);
    var _earliestEstimateItemMonth = Data_Functor.map(Data_Maybe.functorMaybe)(function ($62) {
        return DateFnsFFI.startOfMonth((function (v) {
            return v.modifyDate;
        })($62));
    })(_earliestEstimateItem);
    var availableMonths = (function () {
        if (_earliestEstimateItemMonth instanceof Data_Maybe.Just) {
            return DateFnsFFI.monthsBetween(_earliestEstimateItemMonth.value0)(new Data_Maybe.Just(DateFnsFFI["endOfMonth'"](r.currentDate)));
        };
        if (_earliestEstimateItemMonth instanceof Data_Maybe.Nothing) {
            return [  ];
        };
        throw new Error("Failed pattern match at R200Calculations (line 415, column 23 - line 417, column 20): " + [ _earliestEstimateItemMonth.constructor.name ]);
    })();
    var timelineSlicer = r.makeTimelineSlicer({
        availableMonths: availableMonths,
        selectedMonths: selectedMonths,
        isSameMonth: DateFnsFFI.isSameMonth,
        state: r.state,
        setState: r.setState
    });
    return {
        chartData: chartData,
        chartDataSumHorizontal: chartDataSumHorizontal,
        diffChartData: diffChartData,
        timelineSlicer: timelineSlicer,
        formattedMonths: formattedMonths,
        mcg89ChartData: mcg89ChartData,
        cumulativeProfitMargins: cumulativeProfitMargins
    };
};
var updateRatio = function (r) {
    var diffOfTargetToEstimate = r.targetCosts - r.estimatedCosts;
    var $45 = {};
    for (var $46 in r) {
        if ({}.hasOwnProperty.call(r, $46)) {
            $45[$46] = r[$46];
        };
    };
    $45.diffOfTargetToEstimate = diffOfTargetToEstimate;
    $45.diffOfTargetToEstimateRatio = defaultZeroDiv(diffOfTargetToEstimate)(r.targetCosts);
    $45.actualToEstimateRatio = defaultZeroDiv(r.actualCosts)(r.estimatedCosts);
    return $45;
};
var costGroupToR200CostGroupItem = function (r) {
    var value = updateRatio({
        targetCosts: r.targetCosts,
        targetSocialExpenses: r.targetSocialExpenses,
        estimatedCosts: r.estimatedCosts,
        estimatedSocialExpenses: r.estimatedSocialExpenses,
        actualCosts: r.actualCosts,
        diffOfTargetToEstimate: 0.0,
        diffOfTargetToEstimateRatio: 0.0,
        actualToEstimateRatio: 0.0,
        id: Data_Show.show(Types_CostGroup.showCostGroupId)(r.id),
        name: r.name,
        displayName: Data_Newtype.un()(Types_CostGroup.CostGroupCode)(r.code) + (" " + r.name),
        displayCode: Data_Newtype.un()(Types_CostGroup.CostGroupCode)(r.code),
        category: r.category,
        costGroupCode: Data_Newtype.un()(Types_CostGroup.CostGroupCode)(r.code),
        memo: r.memo,
        items: Utils.nullValue,
        completionPercentage: Utils.convertPercentage100to0(r.completionPercentage)
    });
    return value;
};
var costGroupCodeS = Data_Symbol.SProxy.value;
var completionPercentageS = Data_Symbol.SProxy.value;
var combineIncomeItemsAndSalesInvoices = function (incomeItems) {
    return function (salesInvoices) {
        var convertOtherSalesInvoice = function (i) {
            return {
                amountPaid: i.valueWithoutVAT,
                valueWithoutVAT: 0.0,
                revenueGroup: Utils.unknownIncomeGroup,
                isApproved: false,
                isIncludedInTarget: false,
                taxRate: i.taxRate
            };
        };
        var convertIncomeItem = function (i) {
            return {
                amountPaid: i.amountPaid,
                valueWithoutVAT: i.valueWithoutVAT,
                revenueGroup: Data_Newtype.unwrap()(i.groupName),
                isApproved: i.isApproved,
                isIncludedInTarget: i.isIncludedInTarget,
                taxRate: Utils.defaultNull(0.0)(i.taxRate)
            };
        };
        return Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_Functor.map(Data_Functor.functorArray)(convertIncomeItem)(incomeItems))(Data_Functor.map(Data_Functor.functorArray)(convertOtherSalesInvoice)(salesInvoices));
    };
};
var categoryS = Data_Symbol.SProxy.value;
var aggregatedCompletionPercentageByEstimate = function (items) {
    var estimatedCostsWithSocExp = function (i) {
        return i.estimatedCosts + i.estimatedSocialExpenses;
    };
    var totalCosts = Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(estimatedCostsWithSocExp)(items));
    var weightedPercentage = function (item) {
        return defaultZeroDiv(estimatedCostsWithSocExp(item))(totalCosts) * Data_Newtype.un()(Types_Unit.PercentageOfZeroToOne)(item.completionPercentage);
    };
    return Types_Unit.PercentageOfZeroToOne(Data_Foldable.sum(Data_Foldable.foldableArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Functor.functorArray)(weightedPercentage)(items)));
};
var prepareGrouped = function (items) {
    var prepareItems = (function () {
        var $63 = Data_Array.sortWith(Data_Ord.ordString)(function (v) {
            return v.displayCode;
        });
        var $64 = Data_Array.fromFoldable(Data_Array_NonEmpty_Internal.foldableNonEmptyArray);
        return function ($65) {
            return Utils.pureNullable(toR200CostGroupItemPlaceholderArray($63($64($65))));
        };
    })();
    var parentValues = updateRatio({
        targetCosts: Data_Foldable.sum(Data_Array_NonEmpty_Internal.foldableNonEmptyArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)(function (v) {
            return v.targetCosts;
        })(items)),
        targetSocialExpenses: Data_Foldable.sum(Data_Array_NonEmpty_Internal.foldableNonEmptyArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)(function (v) {
            return v.targetSocialExpenses;
        })(items)),
        estimatedCosts: Data_Foldable.sum(Data_Array_NonEmpty_Internal.foldableNonEmptyArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)(function (v) {
            return v.estimatedCosts;
        })(items)),
        estimatedSocialExpenses: Data_Foldable.sum(Data_Array_NonEmpty_Internal.foldableNonEmptyArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)(function (v) {
            return v.estimatedSocialExpenses;
        })(items)),
        actualCosts: Data_Foldable.sum(Data_Array_NonEmpty_Internal.foldableNonEmptyArray)(Data_Semiring.semiringNumber)(Data_Functor.map(Data_Array_NonEmpty_Internal.functorNonEmptyArray)(function (v) {
            return v.actualCosts;
        })(items)),
        diffOfTargetToEstimate: 0.0,
        diffOfTargetToEstimateRatio: 0.0,
        actualToEstimateRatio: 0.0
    });
    var head = Data_Array_NonEmpty.head(items);
    var parentDisplay = {
        id: head.displayCode,
        name: head.name,
        displayName: head.displayCode,
        displayCode: head.displayCode,
        costGroupCode: head.costGroupCode,
        category: head.category,
        items: prepareItems(items),
        memo: "",
        completionPercentage: aggregatedCompletionPercentageByEstimate(Data_Array_NonEmpty.toArray(items))
    };
    var value = Record.merge()()(parentDisplay)(parentValues);
    return value;
};
var getMainR200CostGroupItems = function (r200CostGroupItems) {
    return function (mainCostGroups) {
        var names = Foreign_Object.insert("Kohdistamattomat")("")(Data_Functor.map(Foreign_Object.functorObject)(function (v) {
            return v.name;
        })(mainCostGroups));
        var updateDisplayName = function (r) {
            return {
                displayName: r.id + (" " + Data_Maybe.fromMaybe("")(Foreign_Object.lookup(r.id)(names))),
                id: r.id,
                actualCosts: r.actualCosts,
                actualToEstimateRatio: r.actualToEstimateRatio,
                category: r.category,
                completionPercentage: r.completionPercentage,
                costGroupCode: r.costGroupCode,
                diffOfTargetToEstimate: r.diffOfTargetToEstimate,
                diffOfTargetToEstimateRatio: r.diffOfTargetToEstimateRatio,
                displayCode: r.displayCode,
                estimatedCosts: r.estimatedCosts,
                estimatedSocialExpenses: r.estimatedSocialExpenses,
                items: r.items,
                memo: r.memo,
                name: r.name,
                targetCosts: r.targetCosts,
                targetSocialExpenses: r.targetSocialExpenses
            };
        };
        var addDisplayCode = function (x) {
            var v = function (v1) {
                if (Data_Boolean.otherwise) {
                    return {
                        displayCode: "Kohdistamattomat",
                        costGroupCode: x.costGroupCode,
                        actualCosts: x.actualCosts,
                        actualToEstimateRatio: x.actualToEstimateRatio,
                        category: x.category,
                        completionPercentage: x.completionPercentage,
                        diffOfTargetToEstimate: x.diffOfTargetToEstimate,
                        diffOfTargetToEstimateRatio: x.diffOfTargetToEstimateRatio,
                        displayName: x.displayName,
                        estimatedCosts: x.estimatedCosts,
                        estimatedSocialExpenses: x.estimatedSocialExpenses,
                        id: x.id,
                        items: x.items,
                        memo: x.memo,
                        name: x.name,
                        targetCosts: x.targetCosts,
                        targetSocialExpenses: x.targetSocialExpenses
                    };
                };
                throw new Error("Failed pattern match at R200Calculations (line 183, column 1 - line 183, column 104): " + [ x.constructor.name ]);
            };
            var $51 = Data_String_CodePoints.take(1)(x.costGroupCode);
            return {
                displayCode: $51,
                costGroupCode: x.costGroupCode,
                actualCosts: x.actualCosts,
                actualToEstimateRatio: x.actualToEstimateRatio,
                category: x.category,
                completionPercentage: x.completionPercentage,
                diffOfTargetToEstimate: x.diffOfTargetToEstimate,
                diffOfTargetToEstimateRatio: x.diffOfTargetToEstimateRatio,
                displayName: x.displayName,
                estimatedCosts: x.estimatedCosts,
                estimatedSocialExpenses: x.estimatedSocialExpenses,
                id: x.id,
                items: x.items,
                memo: x.memo,
                name: x.name,
                targetCosts: x.targetCosts,
                targetSocialExpenses: x.targetSocialExpenses
            };
        };
        var r200CostGroupItems$prime = Data_Array.groupBy(function (a) {
            return function (b) {
                return a.displayCode === b.displayCode;
            };
        })(Data_Array.sortWith(Data_Ord.ordString)(function (v) {
            return v.displayCode;
        })(Data_Functor.map(Data_Functor.functorArray)(addDisplayCode)(r200CostGroupItems)));
        return Data_Functor.map(Data_Functor.functorArray)(function ($66) {
            return updateDisplayName(prepareGrouped($66));
        })(r200CostGroupItems$prime);
    };
};
module.exports = {
    combineIncomeItemsAndSalesInvoices: combineIncomeItemsAndSalesInvoices,
    R200CostGroupItemPlaceholder: R200CostGroupItemPlaceholder,
    toR200CostGroupItemPlaceholder: toR200CostGroupItemPlaceholder,
    toR200CostGroupItemPlaceholderArray: toR200CostGroupItemPlaceholderArray,
    fromR200CostGroupItemPlaceholder: fromR200CostGroupItemPlaceholder,
    costGroupToR200CostGroupItem: costGroupToR200CostGroupItem,
    nameS: nameS,
    displayNameS: displayNameS,
    displayCodeS: displayCodeS,
    itemsS: itemsS,
    memoS: memoS,
    costGroupCodeS: costGroupCodeS,
    categoryS: categoryS,
    completionPercentageS: completionPercentageS,
    R200SocialExpensesRow: R200SocialExpensesRow,
    getMainR200CostGroupItems: getMainR200CostGroupItems,
    prepareGrouped: prepareGrouped,
    getDiffRatios: getDiffRatios,
    updateRatio: updateRatio,
    defaultZeroDiv: defaultZeroDiv,
    defaultZero: defaultZero,
    getMCG89ChartData: getMCG89ChartData,
    getMonthlyReportData: getMonthlyReportData,
    takeMainCostGroupDigit: takeMainCostGroupDigit,
    aggregatedCompletionPercentageByEstimate: aggregatedCompletionPercentageByEstimate,
    newtypeCGIP: newtypeCGIP,
    newtypeR200SocialExpensesRow: newtypeR200SocialExpensesRow
};
