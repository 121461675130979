import { RegisterOptions, useFormContext } from "react-hook-form";
import { ProjectDetailsData } from "../../../../../ts-bindings/ProjectDetailsData";
import { useTranslation } from "react-i18next";

export const MemoTab = () => {
  const { t } = useTranslation("projects", { keyPrefix: "details" });
  const { register } = useFormContext<ProjectDetailsData>();

  const mkInputProps = (
    field: keyof ProjectDetailsData,
    registerOptions?: RegisterOptions<ProjectDetailsData, typeof field>
  ) => ({
    label: t(`form.${field}`),
    ...register(field, registerOptions),
  });
  return (
    <textarea
      className="w-full h-64 outline-none border border-light rounded box-border p-1 hover:shadow focus-within:blue-500 focus-within:hover:shadow-none"
      {...mkInputProps("memo")}
    />
  );
};
