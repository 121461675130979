// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Foreign_Object = require("../Foreign.Object/index.js");
var Gap = require("../Gap/index.js");
var Locale = require("../Locale/index.js");
var NarrowLayout = require("../NarrowLayout/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoInput = require("../TacoInput/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var UseFetch = require("../UseFetch/index.js");
var component = TofuHooks.mkHookComponent("RenameMainCostGroups")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState(Foreign_Object.empty)();
        var v2 = UseFetch.useFetchValueByProjectId(v.projectId)(function (v2) {
            return v2.mainCostGroups;
        })(Actions.MainCostGroupsRequest.create)();
        var appDispatch = StateHooks.useDispatch();
        var row = function (key) {
            return function (mcg) {
                var inputValue = Foreign_Object.lookup(key)(v1.value0);
                var inputValueChanged = (function () {
                    if (inputValue instanceof Data_Maybe.Just) {
                        return inputValue.value0 !== mcg.name;
                    };
                    if (inputValue instanceof Data_Maybe.Nothing) {
                        return false;
                    };
                    throw new Error("Failed pattern match at RenameMainCostGroups (line 54, column 29 - line 56, column 27): " + [ inputValue.constructor.name ]);
                })();
                return Control_Applicative.pure(Control_Applicative.applicativeArray)(TacoInput.stringInput()()(Data_Eq.eqString)({
                    label: mcg.name + (function () {
                        if (inputValueChanged) {
                            return "*";
                        };
                        return "";
                    })(),
                    required: true,
                    placeholder: mcg.name,
                    value: Data_Maybe.fromMaybe("")(inputValue),
                    onChange: TacoInput.changeHandler(TacoInput.inputValueString)(function (mNew) {
                        var alter = Foreign_Object.alter(function (x) {
                            var $13 = Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(new Data_Maybe.Just(mNew))(new Data_Maybe.Just("")) || Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(x)(new Data_Maybe.Just(mNew));
                            if ($13) {
                                return Data_Maybe.Nothing.value;
                            };
                            return new Data_Maybe.Just(mNew);
                        });
                        return v1.value1(function (v3) {
                            return alter(key)(v1.value0);
                        });
                    })
                }));
            };
        };
        var mainCostGroups = Data_Maybe.fromMaybe(Foreign_Object.empty)(Data_Functor.map(Data_Maybe.functorMaybe)(function (v3) {
            return v3.items;
        })(v2.value0));
        var heading = React_Basic_DOM_Generated.h2_([ React_Basic_DOM.text(Locale.lookup_("rename_main_cost_groups")) ]);
        var handleFetched = function (items) {
            return Foreign_Object.foldMap(Data_Monoid.monoidArray)(row)(items);
        };
        var inputs = handleFetched(mainCostGroups);
        var getParam = function (key) {
            return function (value) {
                var v3 = Foreign_Object.lookup(key)(mainCostGroups);
                if (v3 instanceof Data_Maybe.Just) {
                    return [ {
                        mainCostGroupId: v3.value0.id,
                        name: value
                    } ];
                };
                if (v3 instanceof Data_Maybe.Nothing) {
                    return [  ];
                };
                throw new Error("Failed pattern match at RenameMainCostGroups (line 45, column 26 - line 47, column 20): " + [ v3.constructor.name ]);
            };
        };
        var saveButton = TacoButton.component()()({
            text: Locale.lookup_("save"),
            onClick: new Data_Maybe.Just((function () {
                var params = Foreign_Object.foldMap(Data_Monoid.monoidArray)(getParam)(v1.value0);
                return appDispatch(new Actions.EditMainCostGroupNamesRequest({
                    projectId: v.projectId,
                    params: params
                }));
            })())
        });
        return NarrowLayout.component()()({
            children: Control_Applicative.pure(Control_Applicative.applicativeArray)(Gap.gapY(TacoConstants.M.value)(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ heading ])(Data_Semigroup.append(Data_Semigroup.semigroupArray)(inputs)([ saveButton ]))))
        });
    };
});
module.exports = {
    component: component
};
