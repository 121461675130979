// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var CostEstimationLayout_Types = require("../CostEstimationLayout.Types/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var SummariesPanel_Styles = require("../SummariesPanel.Styles/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var component = TofuHooks.mkMemoHookComponent("CostEstimation.SummariesSidebar")(function (v) {
    var active = Data_Eq.notEq(CostEstimationLayout_Types.eqPanelMode)(v.panelMode)(CostEstimationLayout_Types.MinimizedMode.value);
    var toggleSidebarBtn = TacoButton.component()()({
        text: "Yhteenveto",
        buttonStyle: new TacoButton_Types.Sidebar((function () {
            if (active) {
                return TacoButton_Types.SidebarActive.value;
            };
            return TacoButton_Types.SidebarInactive.value;
        })()),
        onClick: Data_Maybe.Just.create(v.setPanelMode(function (v1) {
            if (active) {
                return CostEstimationLayout_Types.MinimizedMode.value;
            };
            return CostEstimationLayout_Types.NormalMode.value;
        })),
        vertical: true,
        color: (function () {
            if (active) {
                return TacoConstants.PrimaryBlue.value;
            };
            return TacoConstants.TextSecondary.value;
        })(),
        testId: "summaries-sidepanel-button"
    });
    var sidebar = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        children: [ toggleSidebarBtn ],
        className: "__SummariesSidebar",
        css: SummariesPanel_Styles.sidebarStyles
    });
    return Control_Applicative.pure(Effect.applicativeEffect)(sidebar);
});
module.exports = {
    component: component
};
