import {
  ColGroupDef,
  IAggFuncParams,
  ITooltipParams,
  ValueGetterParams,
} from "ag-grid-community";
import { Measurement } from "ts-bindings/Measurement";
import { ComponentSummary } from "./Printing";
import { Tooltip } from "../../../components/Tooltip";
import { Subproject } from "../../../../../ts-bindings/Subproject";
import { getCurrencyToLocale } from "src/client-ts/utils/currency";

export const measurementColumnDefinitions = (
  measurements: Measurement[],
  currency: string
) => {
  return measurements.map((measurement) => {
    const measurementHeader = measurement.unit.name;
    const measurementSubHeader =
      getCurrencyToLocale(currency) + "/" + measurement.unit.unit;
    const measurementColumn: ColGroupDef<ComponentSummary> = {
      headerName: measurementHeader,
      groupId: measurementHeader,
      children: [
        {
          field: `measurements.${measurement.unit.name}.priceRate`,
          headerName: measurementSubHeader,
          aggFunc: "measurementPriceRate",
          initialAggFunc: "measurementPriceRate",
          allowedAggFuncs: ["measurementPriceRate"],
          valueGetter: getMeasurementRate,
          initialWidth: 160,
          type: ["money"],
          tooltipValueGetter: getMeasurementToolTipValue,
          tooltipComponent: Tooltip,
          filter: "agNumberColumnFilter",
          initialHide: true,
        },
      ],
    };
    return measurementColumn;
  });
};

const getMeasurementName = (field: string) =>
  field.substring(field.indexOf(".") + 1, field.lastIndexOf("."));

const getMeasurementToolTipValue = (params: ITooltipParams) =>
  params.node?.group
    ? params.context.t`tooltip.measurementGroup`
    : params.context.t`tooltip.measurement`;

/**
 * Divides the total price without social expenses by the measurement value of the components subproject.
 * @param params
 */
export function getMeasurementRate(
  params: ValueGetterParams<ComponentSummary>
) {
  if (!params.data || params.node?.group || params.node?.rowPinned) {
    return null;
  }
  const subprojects: Subproject[] = params.context.subprojects;
  const measurement =
    params.data.measurements[getMeasurementName(params.colDef.field ?? "")];

  if (!measurement || !subprojects) {
    return null;
  }
  const subProjectId = params.data.component.subProjectId;

  const measurementValue = measurement.values.find((value) => {
    return value.subprojectId === subProjectId;
  });
  return {
    totalPriceWithoutSocialExpenses:
      params.data.totalPriceWithoutSocialExpenses,
    measurement: measurement,
    toString: () =>
      measurementValue && params.data
        ? params.data.totalPriceWithoutSocialExpenses / measurementValue.value
        : 0,
  };
}

export type MeasurementValue = ReturnType<typeof getMeasurementRate>;

/**
 * Calculates total price of the row group without expenses from sum of rows.
 * Finds the project measurement amount from the measurements.
 * Divides the total price without social expenses by the whole project measurement value.
 * @param params
 */
export function aggMeasurementRate(
  params: IAggFuncParams<ComponentSummary, MeasurementValue>
): MeasurementValue {
  // Read measurement from first row
  const measurement = params.values[0]?.measurement;
  if (!measurement) return null;

  const totalPriceSum = params.values.reduce((previousValue, currentValue) => {
    return previousValue + (currentValue?.totalPriceWithoutSocialExpenses ?? 0);
  }, 0);

  const projectMeasurement = measurement.values.find(
    (value) => !value.subprojectId
  );

  return {
    totalPriceWithoutSocialExpenses: totalPriceSum,
    measurement: measurement,
    toString: () =>
      projectMeasurement && projectMeasurement.value !== 0
        ? totalPriceSum / projectMeasurement.value
        : 0,
  };
}
