// Generated by purs version 0.14.5
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Newtype = require("../Data.Newtype/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Set = require("../Data.Set/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Formatters = require("../Formatters/index.js");
var Locale = require("../Locale/index.js");
var R200TableGroup_Styles = require("../R200TableGroup.Styles/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var ReactBasicUtils = require("../ReactBasicUtils/index.js");
var TacoTable = require("../TacoTable/index.js");
var TacoTable_Cells = require("../TacoTable.Cells/index.js");
var TacoTable_Types = require("../TacoTable.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_R200 = require("../Types.R200/index.js");
var Types_Unit = require("../Types.Unit/index.js");
var Utils = require("../Utils/index.js");
var IncomeTable = (function () {
    function IncomeTable() {

    };
    IncomeTable.value = new IncomeTable();
    return IncomeTable;
})();
var CostGroupTable = (function () {
    function CostGroupTable() {

    };
    CostGroupTable.value = new CostGroupTable();
    return CostGroupTable;
})();
var MarginTable = (function () {
    function MarginTable() {

    };
    MarginTable.value = new MarginTable();
    return MarginTable;
})();
var CostClassesTable = (function () {
    function CostClassesTable() {

    };
    CostClassesTable.value = new CostClassesTable();
    return CostClassesTable;
})();
var CostGroupData = function (x) {
    return x;
};
var ColFIPlaceholder = (function () {
    function ColFIPlaceholder() {

    };
    ColFIPlaceholder.value = new ColFIPlaceholder();
    return ColFIPlaceholder;
})();
var ColName = (function () {
    function ColName() {

    };
    ColName.value = new ColName();
    return ColName;
})();
var ColTarget = (function () {
    function ColTarget() {

    };
    ColTarget.value = new ColTarget();
    return ColTarget;
})();
var ColEstimate = (function () {
    function ColEstimate() {

    };
    ColEstimate.value = new ColEstimate();
    return ColEstimate;
})();
var ColTargetToEstimate = (function () {
    function ColTargetToEstimate() {

    };
    ColTargetToEstimate.value = new ColTargetToEstimate();
    return ColTargetToEstimate;
})();
var ColTargetToEstimateRatio = (function () {
    function ColTargetToEstimateRatio() {

    };
    ColTargetToEstimateRatio.value = new ColTargetToEstimateRatio();
    return ColTargetToEstimateRatio;
})();
var ColActual = (function () {
    function ColActual() {

    };
    ColActual.value = new ColActual();
    return ColActual;
})();
var ColActualToEstimateRatio = (function () {
    function ColActualToEstimateRatio() {

    };
    ColActualToEstimateRatio.value = new ColActualToEstimateRatio();
    return ColActualToEstimateRatio;
})();
var ColCompletionPercentage = (function () {
    function ColCompletionPercentage() {

    };
    ColCompletionPercentage.value = new ColCompletionPercentage();
    return ColCompletionPercentage;
})();
var ColEstimateRemaining = (function () {
    function ColEstimateRemaining() {

    };
    ColEstimateRemaining.value = new ColEstimateRemaining();
    return ColEstimateRemaining;
})();
var unMargin = Data_Newtype.un()(Types_R200.R200ResultMargin);
var r200SummedItemColumnSpecRowInst = TacoTable.mkTableStateless(TofuHooks.getNameVoid)("R200SummedItemColumnSpecRow");
var r200ResultMarginInst = TacoTable.mkTableStateless(TofuHooks.getNameVoid)("R200ResultMargin");
var r200IncomeInformationResultInst = TacoTable.mkTableStateless(TofuHooks.getNameVoid)("R200IncomeInformationResult");
var r200IncomeColumnSpecRowInst = TacoTable.mkTableStateless(TofuHooks.getNameVoid)("R200IncomeColumnSpecRow");
var r200CostClassesColumnSpecRowInst = TacoTable.mkTableStateless(TofuHooks.getNameVoid)("R200CostClassesColumnSpecRow");
var newtypeCostGroupData = {
    Coercible0: function () {
        return undefined;
    }
};
var mkRow = function (v) {
    return {
        className: v.className,
        rowData: v.rowData,
        foldingElement: Data_Maybe.Nothing.value,
        onClick: function (v1) {
            return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
        },
        rowKey: v.rowKey,
        columns: v.columns
    };
};
var mkPrepareColumns = function (withPlaceholder) {
    return function (tableType) {
        return function (flags) {
            return function (fn) {
                var targetToEstimateLabel = React_Basic_DOM.text((function () {
                    if (tableType instanceof IncomeTable) {
                        return "";
                    };
                    return Locale.lookup_("common_diff");
                })());
                var onFlag = function ($$boolean) {
                    return function (value) {
                        if ($$boolean) {
                            return new Data_Maybe.Just(value);
                        };
                        return Data_Maybe.Nothing.value;
                    };
                };
                var nameLabel = React_Basic_DOM.text((function () {
                    if (tableType instanceof IncomeTable) {
                        return "Tuloryhm\xe4t";
                    };
                    if (tableType instanceof CostGroupTable) {
                        return "Kustannukset p\xe4\xe4ryhmitt\xe4in";
                    };
                    if (tableType instanceof MarginTable) {
                        return "Katelaskelma";
                    };
                    if (tableType instanceof CostClassesTable) {
                        return "Kustannukset panoslajeittain";
                    };
                    throw new Error("Failed pattern match at R200Table (line 281, column 24 - line 285, column 57): " + [ tableType.constructor.name ]);
                })());
                var mkCol = function (v) {
                    var cell = fn(v.col);
                    return {
                        sortProperty: TacoTable_Types.noSortProperty,
                        key: v.key,
                        label: v.label,
                        width: v.width,
                        cell: cell,
                        headerJSX: Data_Maybe.Nothing.value
                    };
                };
                var estimateRemainingLabel = React_Basic_DOM.text((function () {
                    if (tableType instanceof IncomeTable) {
                        return "";
                    };
                    return "J\xe4ljell\xe4";
                })());
                var completionPercentageLabel = React_Basic_DOM.text((function () {
                    if (tableType instanceof CostGroupTable) {
                        return Locale.lookup_("report_costs_completion_percentage");
                    };
                    return "";
                })());
                var optionals = Data_Array.catMaybes([ onFlag(flags.displayCompletionPercentage)({
                    col: ColCompletionPercentage.value,
                    key: "completionPercentage",
                    label: completionPercentageLabel,
                    width: new TacoTable_Types.Flex(10)
                }), onFlag(flags.displayEstimateRemaining)({
                    col: ColEstimateRemaining.value,
                    key: "estimateRemaining",
                    label: estimateRemainingLabel,
                    width: new TacoTable_Types.Flex(15)
                }) ]);
                return Data_Functor.map(Data_Functor.functorArray)(mkCol)(Data_Semigroup.append(Data_Semigroup.semigroupArray)((function () {
                    if (withPlaceholder) {
                        return [ {
                            col: ColFIPlaceholder.value,
                            key: "placeholder",
                            label: React_Basic_DOM.text(""),
                            width: new TacoTable_Types.Flex(0)
                        } ];
                    };
                    return [  ];
                })())(Data_Semigroup.append(Data_Semigroup.semigroupArray)([ {
                    col: ColName.value,
                    key: "name",
                    label: nameLabel,
                    width: new TacoTable_Types.Flex(22)
                }, {
                    col: ColTarget.value,
                    key: "target",
                    label: React_Basic_DOM.text(Locale.lookup_("report_costs_target")),
                    width: new TacoTable_Types.Flex(15)
                }, {
                    col: ColEstimate.value,
                    key: "estimate",
                    label: React_Basic_DOM.text(Locale.lookup_("report_costs_estimate")),
                    width: new TacoTable_Types.Flex(15)
                }, {
                    col: ColTargetToEstimate.value,
                    key: "target-to-estimate",
                    label: targetToEstimateLabel,
                    width: new TacoTable_Types.Flex(15)
                }, {
                    col: ColTargetToEstimateRatio.value,
                    key: "target-to-estimate-ratio",
                    label: React_Basic_DOM.text(""),
                    width: new TacoTable_Types.Flex(9)
                }, {
                    col: ColActual.value,
                    key: "actual",
                    label: React_Basic_DOM.text(Locale.lookup_("report_costs_actual")),
                    width: new TacoTable_Types.Flex(15)
                }, {
                    col: ColActualToEstimateRatio.value,
                    key: "actual-to-estimate-ratio",
                    label: React_Basic_DOM.text("Tot/Enn"),
                    width: new TacoTable_Types.Flex(9)
                } ])(optionals)));
            };
        };
    };
};
var mkR200IncomeTotalTable = function (flags) {
    return function (xs) {
        var columns = (function () {
            var unResult = Data_Newtype.un()(Types_R200.R200IncomeInformationResult);
            return mkPrepareColumns(false)(MarginTable.value)(flags)(function (v) {
                if (v instanceof ColName) {
                    return TacoTable_Types.PlainTextCell.create(function ($114) {
                        return (function (v1) {
                            return v1.name;
                        })(unResult($114));
                    });
                };
                if (v instanceof ColTarget) {
                    return TacoTable_Types.PlainEuroCell.create(function ($115) {
                        return (function (v1) {
                            return v1.target;
                        })(unResult($115));
                    });
                };
                if (v instanceof ColEstimate) {
                    return TacoTable_Types.PlainEuroCell.create(function ($116) {
                        return (function (v1) {
                            return v1.estimate;
                        })(unResult($116));
                    });
                };
                if (v instanceof ColTargetToEstimate) {
                    return new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value ], function (v1) {
                        return React_Basic.empty;
                    });
                };
                if (v instanceof ColActual) {
                    return TacoTable_Types.PlainEuroCell.create(function ($117) {
                        return (function (v1) {
                            return v1.actual;
                        })(unResult($117));
                    });
                };
                if (v instanceof ColActualToEstimateRatio) {
                    return new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value ], function (v1) {
                        return React_Basic.empty;
                    });
                };
                if (v instanceof ColEstimateRemaining) {
                    return new TacoTable_Types.JSXCell([ TacoTable_Types.Numeric.value ], function (v1) {
                        return React_Basic.empty;
                    });
                };
                return TacoTable_Types.emptyCell;
            });
        })();
        var getRow = function (v) {
            return mkRow({
                className: "__R200IncomeTotalTableRow",
                rowData: v,
                rowKey: v.name,
                columns: columns
            });
        };
        return TacoTable["tableStateless'"](TofuHooks.getNameVoid)(Data_Eq.eqVoid)(Types_R200.eqR200IncomeInformationResult)(true)(r200IncomeInformationResultInst)(Data_Functor.map(Data_Functor.functorArray)(getRow)(xs))(columns);
    };
};
var mkR200ResultMarginTable = function (flags) {
    return function (xs) {
        var columns = mkPrepareColumns(false)(MarginTable.value)(flags)(function (v) {
            if (v instanceof ColName) {
                return TacoTable_Types.PlainTextCell.create(function ($118) {
                    return (function (v1) {
                        return v1.name;
                    })(unMargin($118));
                });
            };
            if (v instanceof ColTarget) {
                return TacoTable_Types.ColoredEuroCell.create(function ($119) {
                    return (function (v1) {
                        return v1.target;
                    })(unMargin($119));
                });
            };
            if (v instanceof ColEstimate) {
                return TacoTable_Types.ColoredEuroCell.create(function ($120) {
                    return (function (v1) {
                        return v1.estimate;
                    })(unMargin($120));
                });
            };
            if (v instanceof ColActual) {
                return TacoTable_Types.ColoredEuroCell.create(function ($121) {
                    return (function (v1) {
                        return v1.actual;
                    })(unMargin($121));
                });
            };
            return TacoTable_Types.emptyCell;
        });
        var getRow = function (v) {
            return mkRow({
                className: "__R200ResultMarginTableRow",
                rowData: v,
                rowKey: v.name,
                columns: columns
            });
        };
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "_R200ResultTableRow",
            css: R200TableGroup_Styles.r200ResultTableRowStyles,
            children: [ TacoTable["tableStateless'"](TofuHooks.getNameVoid)(Data_Eq.eqVoid)(Types_R200.eqR200ResultMargin)(false)(r200ResultMarginInst)(Data_Functor.map(Data_Functor.functorArray)(getRow)(xs))(columns) ]
        });
    };
};
var eqCostGroupData = {
    eq: function (x) {
        return function (y) {
            return Data_Eq.eq(TacoTable_Types.eqFoldingState)(x.foldingState)(y.foldingState) && Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqArray(eqCostGroupData)))(x.items)(y.items) && (x.row.actualCosts === y.row.actualCosts && x.row.actualToEstimateRatio === y.row.actualToEstimateRatio && Data_Eq.eq(Types_Unit.eqPercentageOfZeroToOne)(x.row.completionPercentage)(y.row.completionPercentage) && x.row.diffOfTargetToEstimate === y.row.diffOfTargetToEstimate && x.row.diffOfTargetToEstimateRatio === y.row.diffOfTargetToEstimateRatio && x.row.displayName === y.row.displayName && x.row.estimateRemaining === y.row.estimateRemaining && x.row.estimatedCosts === y.row.estimatedCosts && Data_Eq.eq(Data_Maybe.eqMaybe(Types_R200.eqCostGroupColumnSpecRowItems))(x.row.items)(y.row.items) && x.row.targetCosts === y.row.targetCosts);
        };
    }
};
var decimalPrecision = 1;
var mkR200CostClassTable = function (flags) {
    return function (xs) {
        var columns = mkPrepareColumns(false)(CostClassesTable.value)(flags)(function (v) {
            if (v instanceof ColName) {
                return new TacoTable_Types.PlainTextCell(function (v1) {
                    return v1.displayName;
                });
            };
            if (v instanceof ColTarget) {
                return new TacoTable_Types.PlainEuroCell(function (v1) {
                    return v1.targetCosts;
                });
            };
            if (v instanceof ColEstimate) {
                return new TacoTable_Types.PlainEuroCell(function (v1) {
                    return v1.estimatedCosts;
                });
            };
            if (v instanceof ColTargetToEstimate) {
                return new TacoTable_Types.ColoredEuroCell(function (v1) {
                    return v1.diffOfTargetToEstimate;
                });
            };
            if (v instanceof ColTargetToEstimateRatio) {
                return TacoTable_Types.ColoredPercentageCell.create(decimalPrecision)(function ($122) {
                    return Types_Unit.PercentageOfZeroToOne((function (v1) {
                        return v1.diffOfTargetToEstimateRatio;
                    })($122));
                });
            };
            if (v instanceof ColActual) {
                return new TacoTable_Types.PlainEuroCell(function (v1) {
                    return v1.actualCosts;
                });
            };
            if (v instanceof ColActualToEstimateRatio) {
                return TacoTable_Types.PlainPercentageCell.create(decimalPrecision)(function ($123) {
                    return Types_Unit.PercentageOfZeroToOne((function (v1) {
                        return v1.actualToEstimateRatio;
                    })($123));
                });
            };
            if (v instanceof ColEstimateRemaining) {
                return new TacoTable_Types.PlainEuroCell(function (v1) {
                    return v1.estimateRemaining;
                });
            };
            return TacoTable_Types.emptyCell;
        });
        var getRow = function (r) {
            return mkRow({
                className: "__R200CostClassTableRow",
                rowData: r,
                rowKey: r.displayName,
                columns: columns
            });
        };
        return TacoTable["tableStateless'"](TofuHooks.getNameVoid)(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "targetCosts";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "estimatedCosts";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "estimateRemaining";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "displayName";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "diffOfTargetToEstimateRatio";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "diffOfTargetToEstimate";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "actualToEstimateRatio";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "actualCosts";
            }
        })(Data_Eq.eqNumber)))(true)(r200CostClassesColumnSpecRowInst)(Data_Functor.map(Data_Functor.functorArray)(getRow)(xs))(columns);
    };
};
var mkR200IncomeTable = function (flags) {
    return function (xs) {
        var columns = mkPrepareColumns(false)(IncomeTable.value)(flags)(function (v) {
            if (v instanceof ColName) {
                return new TacoTable_Types.PlainTextCell(function (v1) {
                    return v1.name;
                });
            };
            if (v instanceof ColTarget) {
                return new TacoTable_Types.PlainEuroCell(function (v1) {
                    return v1.target;
                });
            };
            if (v instanceof ColEstimate) {
                return new TacoTable_Types.PlainEuroCell(function (v1) {
                    return v1.estimate;
                });
            };
            if (v instanceof ColActual) {
                return new TacoTable_Types.PlainEuroCell(function (v1) {
                    return v1.actual;
                });
            };
            if (v instanceof ColActualToEstimateRatio) {
                return TacoTable_Types.PlainPercentageCell.create(decimalPrecision)(function ($124) {
                    return Types_Unit.PercentageOfZeroToOne((function (v1) {
                        return v1.actualToEstimate;
                    })($124));
                });
            };
            return TacoTable_Types.emptyCell;
        });
        var getIncomeTableRow = function (r) {
            return mkRow({
                className: "__R200IncomeTableRow",
                rowData: r,
                rowKey: r.name,
                columns: columns
            });
        };
        return TacoTable["tableStateless'"](TofuHooks.getNameVoid)(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
            reflectSymbol: function () {
                return "target";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "name";
            }
        })(Data_Eq.eqString))()({
            reflectSymbol: function () {
                return "estimate";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "actualToEstimate";
            }
        })(Data_Eq.eqNumber))()({
            reflectSymbol: function () {
                return "actual";
            }
        })(Data_Eq.eqNumber)))(true)(r200IncomeColumnSpecRowInst)(Data_Functor.map(Data_Functor.functorArray)(getIncomeTableRow)(xs))(columns);
    };
};
var mkR200ResultMarginPercentageTable = function (flags) {
    return function (xs) {
        var columns = mkPrepareColumns(false)(MarginTable.value)(flags)(function (v) {
            if (v instanceof ColName) {
                return TacoTable_Types.PlainTextCell.create(function ($125) {
                    return (function (v1) {
                        return v1.name;
                    })(unMargin($125));
                });
            };
            if (v instanceof ColTarget) {
                return TacoTable_Types.ColoredPercentageCell.create(decimalPrecision)(function ($126) {
                    return Types_Unit.PercentageOfZeroToOne((function (v1) {
                        return v1.target;
                    })(unMargin($126)));
                });
            };
            if (v instanceof ColEstimate) {
                return TacoTable_Types.ColoredPercentageCell.create(decimalPrecision)(function ($127) {
                    return Types_Unit.PercentageOfZeroToOne((function (v1) {
                        return v1.estimate;
                    })(unMargin($127)));
                });
            };
            if (v instanceof ColActual) {
                return TacoTable_Types.ColoredPercentageCell.create(decimalPrecision)(function ($128) {
                    return Types_Unit.PercentageOfZeroToOne((function (v1) {
                        return v1.actual;
                    })(unMargin($128)));
                });
            };
            return TacoTable_Types.emptyCell;
        });
        var getRow = function (v) {
            return mkRow({
                className: "__R200ResultMarginPercentageTableRow",
                rowData: v,
                rowKey: v.name,
                columns: columns
            });
        };
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "_R200ResultTableRow",
            css: R200TableGroup_Styles.r200ResultTableRowStyles,
            children: [ TacoTable["tableStateless'"](TofuHooks.getNameVoid)(Data_Eq.eqVoid)(Types_R200.eqR200ResultMargin)(false)(r200ResultMarginInst)(Data_Functor.map(Data_Functor.functorArray)(getRow)(xs))(columns) ]
        });
    };
};
var mkR200SummedItemTable = function (flags) {
    return function (xs) {
        var mkPercentageCell = function (percentage) {
            return React_Basic_DOM.text(Formatters.formatPercentageValue({
                precision: decimalPrecision,
                value: percentage
            }));
        };
        var completionPercentageCell = function (r) {
            return Data_Maybe.maybe(React_Basic.empty)(mkPercentageCell)(r.completionPercentage);
        };
        var columns = mkPrepareColumns(false)(MarginTable.value)(flags)(function (v) {
            if (v instanceof ColName) {
                return new TacoTable_Types.PlainTextCell(function (v1) {
                    return v1.name;
                });
            };
            if (v instanceof ColTarget) {
                return new TacoTable_Types.PlainEuroCell(function (v1) {
                    return v1.targetCosts;
                });
            };
            if (v instanceof ColEstimate) {
                return new TacoTable_Types.PlainEuroCell(function (v1) {
                    return v1.estimatedCosts;
                });
            };
            if (v instanceof ColTargetToEstimate) {
                return new TacoTable_Types.ColoredEuroCell(function (v1) {
                    return v1.diffOfTargetToEstimate;
                });
            };
            if (v instanceof ColTargetToEstimateRatio) {
                return TacoTable_Types.ColoredPercentageCell.create(decimalPrecision)(function ($129) {
                    return Types_Unit.PercentageOfZeroToOne((function (v1) {
                        return v1.diffOfTargetToEstimateRatio;
                    })($129));
                });
            };
            if (v instanceof ColActual) {
                return new TacoTable_Types.PlainEuroCell(function (v1) {
                    return v1.actualCosts;
                });
            };
            if (v instanceof ColActualToEstimateRatio) {
                return TacoTable_Types.PlainPercentageCell.create(decimalPrecision)(function ($130) {
                    return Types_Unit.PercentageOfZeroToOne((function (v1) {
                        return v1.actualToEstimateRatio;
                    })($130));
                });
            };
            if (v instanceof ColCompletionPercentage) {
                return TacoTable_Types.JSXCell.create([ TacoTable_Types.Numeric.value ])(completionPercentageCell);
            };
            if (v instanceof ColEstimateRemaining) {
                return new TacoTable_Types.PlainEuroCell(function (v1) {
                    return v1.estimateRemaining;
                });
            };
            return TacoTable_Types.emptyCell;
        });
        var getRow = function (r) {
            return mkRow({
                className: "__R200SummedItemTableRow",
                rowData: r,
                rowKey: r.name,
                columns: columns
            });
        };
        return React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "_R200ResultTableRow",
            css: R200TableGroup_Styles.r200ResultTableRowStyles,
            children: [ TacoTable["tableStateless'"](TofuHooks.getNameVoid)(Data_Eq.eqVoid)(Data_Eq.eqRec()(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowCons(Data_Eq.eqRowNil)()({
                reflectSymbol: function () {
                    return "targetCosts";
                }
            })(Data_Eq.eqNumber))()({
                reflectSymbol: function () {
                    return "name";
                }
            })(Data_Eq.eqString))()({
                reflectSymbol: function () {
                    return "estimatedCosts";
                }
            })(Data_Eq.eqNumber))()({
                reflectSymbol: function () {
                    return "estimateRemaining";
                }
            })(Data_Eq.eqNumber))()({
                reflectSymbol: function () {
                    return "diffOfTargetToEstimateRatio";
                }
            })(Data_Eq.eqNumber))()({
                reflectSymbol: function () {
                    return "diffOfTargetToEstimate";
                }
            })(Data_Eq.eqNumber))()({
                reflectSymbol: function () {
                    return "completionPercentage";
                }
            })(Data_Maybe.eqMaybe(Types_Unit.eqPercentageOfZeroToOne)))()({
                reflectSymbol: function () {
                    return "actualToEstimateRatio";
                }
            })(Data_Eq.eqNumber))()({
                reflectSymbol: function () {
                    return "actualCosts";
                }
            })(Data_Eq.eqNumber)))(false)(r200SummedItemColumnSpecRowInst)(Data_Functor.map(Data_Functor.functorArray)(getRow)(xs))(columns) ]
        });
    };
};
var costGroupDataTableInst = TacoTable.mkTableStateless(TofuHooks.getNameVoid)("CostGroupDataTableInst");
var mkR200CostGroupUnitTable = TofuHooks.mkHookComponent("CostGroupUnitTable")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState(Data_Set.empty)();
        var toggleUnfoldedItem = function (id) {
            return v1.value1(Utils.toggleInSet(Data_Ord.ordString)(id));
        };
        var unCG = Data_Newtype.un()(CostGroupData);
        var renderTable = function (showHeader) {
            return TacoTable["tableStateless'"](TofuHooks.getNameVoid)(Data_Eq.eqVoid)(eqCostGroupData)(showHeader)(costGroupDataTableInst);
        };
        var mkCostGroupData = function (row) {
            return {
                row: row,
                foldingState: (function () {
                    var $98 = Data_Set.member(Data_Ord.ordString)(row.displayName)(v1.value0);
                    if ($98) {
                        return TacoTable_Types.Unfolded.value;
                    };
                    return TacoTable_Types.Folded.value;
                })(),
                items: Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(row.items)(function (v2) {
                    return Data_Functor.map(Data_Functor.functorArray)(mkCostGroupData)(v2);
                })
            };
        };
        var costGroupData = Data_Functor.map(Data_Functor.functorArray)(mkCostGroupData)(v.rows);
        var columns = function (withPlaceholder) {
            return mkPrepareColumns(withPlaceholder)(CostGroupTable.value)(v.displayFlags)(function (v2) {
                if (v2 instanceof ColFIPlaceholder) {
                    return TacoTable_Types.FoldingIndicatorPlaceholder.value;
                };
                if (v2 instanceof ColName) {
                    return TacoTable_Types.PlainTextCell.create(function ($131) {
                        return (function (v3) {
                            return v3.row.displayName;
                        })(unCG($131));
                    });
                };
                if (v2 instanceof ColTarget) {
                    return TacoTable_Types.PlainEuroCell.create(function ($132) {
                        return (function (v3) {
                            return v3.row.targetCosts;
                        })(unCG($132));
                    });
                };
                if (v2 instanceof ColEstimate) {
                    return TacoTable_Types.PlainEuroCell.create(function ($133) {
                        return (function (v3) {
                            return v3.row.estimatedCosts;
                        })(unCG($133));
                    });
                };
                if (v2 instanceof ColTargetToEstimate) {
                    return TacoTable_Types.ColoredEuroCell.create(function ($134) {
                        return (function (v3) {
                            return v3.row.diffOfTargetToEstimate;
                        })(unCG($134));
                    });
                };
                if (v2 instanceof ColTargetToEstimateRatio) {
                    return TacoTable_Types.ColoredPercentageCell.create(decimalPrecision)(function ($135) {
                        return Types_Unit.PercentageOfZeroToOne((function (v3) {
                            return v3.row.diffOfTargetToEstimateRatio;
                        })(unCG($135)));
                    });
                };
                if (v2 instanceof ColActual) {
                    return TacoTable_Types.PlainEuroCell.create(function ($136) {
                        return (function (v3) {
                            return v3.row.actualCosts;
                        })(unCG($136));
                    });
                };
                if (v2 instanceof ColActualToEstimateRatio) {
                    return TacoTable_Types.PlainPercentageCell.create(decimalPrecision)(function ($137) {
                        return Types_Unit.PercentageOfZeroToOne((function (v3) {
                            return v3.row.actualToEstimateRatio;
                        })(unCG($137)));
                    });
                };
                if (v2 instanceof ColCompletionPercentage) {
                    return TacoTable_Types.PlainPercentageCell.create(decimalPrecision)(function ($138) {
                        return (function (v3) {
                            return v3.row.completionPercentage;
                        })(unCG($138));
                    });
                };
                if (v2 instanceof ColEstimateRemaining) {
                    return TacoTable_Types.PlainEuroCell.create(function ($139) {
                        return (function (v3) {
                            return v3.row.estimateRemaining;
                        })(unCG($139));
                    });
                };
                throw new Error("Failed pattern match at R200Table (line 92, column 68 - line 102, column 84): " + [ v2.constructor.name ]);
            });
        };
        var mkFoldingElement = function (v2) {
            if (v2.items instanceof Data_Maybe.Nothing) {
                return Data_Maybe.Nothing.value;
            };
            if (v2.items instanceof Data_Maybe.Just) {
                return Data_Maybe.Just.create(new TacoTable_Types.FoldingElement(v2.foldingState, function (v3) {
                    return ReactBasicUtils["div'"]("__R200NestedCostGroup")([ renderTable(false)(Data_Functor.map(Data_Functor.functorArray)(getRow(true))(v2.items.value0))(columns(true)) ]);
                }));
            };
            throw new Error("Failed pattern match at R200Table (line 72, column 64 - line 75, column 95): " + [ v2.items.constructor.name ]);
        };
        var getRow = function (withPlaceholder) {
            return function (v2) {
                return {
                    className: TacoTable_Cells.classNames([ "__R200CostGroupUnitTableRow", Data_Monoid.guard(Data_Monoid.monoidString)(Data_Maybe.isNothing(v2.items))("no-items") ]),
                    rowData: v2,
                    rowKey: v2.row.displayName,
                    foldingElement: mkFoldingElement(v2),
                    onClick: function (v3) {
                        return toggleUnfoldedItem(v2.row.displayName);
                    },
                    columns: columns(withPlaceholder)
                };
            };
        };
        return renderTable(true)(Data_Functor.map(Data_Functor.functorArray)(getRow(false))(costGroupData))(columns(false));
    };
});
module.exports = {
    mkR200CostGroupUnitTable: mkR200CostGroupUnitTable,
    mkR200IncomeTable: mkR200IncomeTable,
    mkR200IncomeTotalTable: mkR200IncomeTotalTable,
    mkR200SummedItemTable: mkR200SummedItemTable,
    mkR200ResultMarginTable: mkR200ResultMarginTable,
    mkR200ResultMarginPercentageTable: mkR200ResultMarginPercentageTable,
    mkR200CostClassTable: mkR200CostClassTable
};
