import { useEffect, useState } from "react";

export const retryRun = {};
export function useRunOnce(callback: () => unknown) {
  const [firstRun, setFirstRun] = useState<boolean>(true);
  useEffect(() => {
    if (!firstRun) return;

    const retval = callback();

    if (retval != retryRun) {
      setFirstRun(false);
    }
  }, [callback, firstRun, setFirstRun]);
}
