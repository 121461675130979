// Generated by purs version 0.14.5
"use strict";
var Color = require("../Color/index.js");
var XXS = (function () {
    function XXS() {

    };
    XXS.value = new XXS();
    return XXS;
})();
var XS = (function () {
    function XS() {

    };
    XS.value = new XS();
    return XS;
})();
var S = (function () {
    function S() {

    };
    S.value = new S();
    return S;
})();
var M = (function () {
    function M() {

    };
    M.value = new M();
    return M;
})();
var L = (function () {
    function L() {

    };
    L.value = new L();
    return L;
})();
var XL = (function () {
    function XL() {

    };
    XL.value = new XL();
    return XL;
})();
var XXL = (function () {
    function XXL() {

    };
    XXL.value = new XXL();
    return XXL;
})();
var CustomSize = (function () {
    function CustomSize(value0) {
        this.value0 = value0;
    };
    CustomSize.create = function (value0) {
        return new CustomSize(value0);
    };
    return CustomSize;
})();
var AppBackground = (function () {
    function AppBackground() {

    };
    AppBackground.value = new AppBackground();
    return AppBackground;
})();
var ElementPurpleDark = (function () {
    function ElementPurpleDark() {

    };
    ElementPurpleDark.value = new ElementPurpleDark();
    return ElementPurpleDark;
})();
var ElementPurpleLight = (function () {
    function ElementPurpleLight() {

    };
    ElementPurpleLight.value = new ElementPurpleLight();
    return ElementPurpleLight;
})();
var ComponentBlueDark = (function () {
    function ComponentBlueDark() {

    };
    ComponentBlueDark.value = new ComponentBlueDark();
    return ComponentBlueDark;
})();
var ComponentBlueLight = (function () {
    function ComponentBlueLight() {

    };
    ComponentBlueLight.value = new ComponentBlueLight();
    return ComponentBlueLight;
})();
var ResourceTealDark = (function () {
    function ResourceTealDark() {

    };
    ResourceTealDark.value = new ResourceTealDark();
    return ResourceTealDark;
})();
var ResourceTealLight = (function () {
    function ResourceTealLight() {

    };
    ResourceTealLight.value = new ResourceTealLight();
    return ResourceTealLight;
})();
var TextPrimary = (function () {
    function TextPrimary() {

    };
    TextPrimary.value = new TextPrimary();
    return TextPrimary;
})();
var TextSecondary = (function () {
    function TextSecondary() {

    };
    TextSecondary.value = new TextSecondary();
    return TextSecondary;
})();
var TextInactive = (function () {
    function TextInactive() {

    };
    TextInactive.value = new TextInactive();
    return TextInactive;
})();
var InputBackgroundDefault = (function () {
    function InputBackgroundDefault() {

    };
    InputBackgroundDefault.value = new InputBackgroundDefault();
    return InputBackgroundDefault;
})();
var InputBorderColorDefault = (function () {
    function InputBorderColorDefault() {

    };
    InputBorderColorDefault.value = new InputBorderColorDefault();
    return InputBorderColorDefault;
})();
var InputBorderColorFocused = (function () {
    function InputBorderColorFocused() {

    };
    InputBorderColorFocused.value = new InputBorderColorFocused();
    return InputBorderColorFocused;
})();
var InputBorderColorDark = (function () {
    function InputBorderColorDark() {

    };
    InputBorderColorDark.value = new InputBorderColorDark();
    return InputBorderColorDark;
})();
var InputBorderColorLoading = (function () {
    function InputBorderColorLoading() {

    };
    InputBorderColorLoading.value = new InputBorderColorLoading();
    return InputBorderColorLoading;
})();
var InputBorderColorError = (function () {
    function InputBorderColorError() {

    };
    InputBorderColorError.value = new InputBorderColorError();
    return InputBorderColorError;
})();
var ButtonBorderColorDefault = (function () {
    function ButtonBorderColorDefault() {

    };
    ButtonBorderColorDefault.value = new ButtonBorderColorDefault();
    return ButtonBorderColorDefault;
})();
var ButtonBackgroundHover = (function () {
    function ButtonBackgroundHover() {

    };
    ButtonBackgroundHover.value = new ButtonBackgroundHover();
    return ButtonBackgroundHover;
})();
var ButtonBorderColorHover = (function () {
    function ButtonBorderColorHover() {

    };
    ButtonBorderColorHover.value = new ButtonBorderColorHover();
    return ButtonBorderColorHover;
})();
var ButtonBackgroundNormal = (function () {
    function ButtonBackgroundNormal() {

    };
    ButtonBackgroundNormal.value = new ButtonBackgroundNormal();
    return ButtonBackgroundNormal;
})();
var ButtonTextPrimary = (function () {
    function ButtonTextPrimary() {

    };
    ButtonTextPrimary.value = new ButtonTextPrimary();
    return ButtonTextPrimary;
})();
var ButtonTextSecondary = (function () {
    function ButtonTextSecondary() {

    };
    ButtonTextSecondary.value = new ButtonTextSecondary();
    return ButtonTextSecondary;
})();
var DangerRedDark = (function () {
    function DangerRedDark() {

    };
    DangerRedDark.value = new DangerRedDark();
    return DangerRedDark;
})();
var DangerRedLight = (function () {
    function DangerRedLight() {

    };
    DangerRedLight.value = new DangerRedLight();
    return DangerRedLight;
})();
var DangerRedBorder = (function () {
    function DangerRedBorder() {

    };
    DangerRedBorder.value = new DangerRedBorder();
    return DangerRedBorder;
})();
var Gray = (function () {
    function Gray() {

    };
    Gray.value = new Gray();
    return Gray;
})();
var Transparent = (function () {
    function Transparent() {

    };
    Transparent.value = new Transparent();
    return Transparent;
})();
var CustomColor = (function () {
    function CustomColor(value0) {
        this.value0 = value0;
    };
    CustomColor.create = function (value0) {
        return new CustomColor(value0);
    };
    return CustomColor;
})();
var GrayDarkest = (function () {
    function GrayDarkest() {

    };
    GrayDarkest.value = new GrayDarkest();
    return GrayDarkest;
})();
var GrayDark = (function () {
    function GrayDark() {

    };
    GrayDark.value = new GrayDark();
    return GrayDark;
})();
var GrayLight = (function () {
    function GrayLight() {

    };
    GrayLight.value = new GrayLight();
    return GrayLight;
})();
var GrayLightest = (function () {
    function GrayLightest() {

    };
    GrayLightest.value = new GrayLightest();
    return GrayLightest;
})();
var GrayMedium = (function () {
    function GrayMedium() {

    };
    GrayMedium.value = new GrayMedium();
    return GrayMedium;
})();
var Green = (function () {
    function Green() {

    };
    Green.value = new Green();
    return Green;
})();
var GreenLight = (function () {
    function GreenLight() {

    };
    GreenLight.value = new GreenLight();
    return GreenLight;
})();
var LightBlue = (function () {
    function LightBlue() {

    };
    LightBlue.value = new LightBlue();
    return LightBlue;
})();
var PrimaryBlue = (function () {
    function PrimaryBlue() {

    };
    PrimaryBlue.value = new PrimaryBlue();
    return PrimaryBlue;
})();
var SecondaryRed = (function () {
    function SecondaryRed() {

    };
    SecondaryRed.value = new SecondaryRed();
    return SecondaryRed;
})();
var Orange = (function () {
    function Orange() {

    };
    Orange.value = new Orange();
    return Orange;
})();
var OrangeLight = (function () {
    function OrangeLight() {

    };
    OrangeLight.value = new OrangeLight();
    return OrangeLight;
})();
var Red = (function () {
    function Red() {

    };
    Red.value = new Red();
    return Red;
})();
var StatusGreen = (function () {
    function StatusGreen() {

    };
    StatusGreen.value = new StatusGreen();
    return StatusGreen;
})();
var StatusOrange = (function () {
    function StatusOrange() {

    };
    StatusOrange.value = new StatusOrange();
    return StatusOrange;
})();
var Purple = (function () {
    function Purple() {

    };
    Purple.value = new Purple();
    return Purple;
})();
var PurpleLight = (function () {
    function PurpleLight() {

    };
    PurpleLight.value = new PurpleLight();
    return PurpleLight;
})();
var White = (function () {
    function White() {

    };
    White.value = new White();
    return White;
})();
var Black = (function () {
    function Black() {

    };
    Black.value = new Black();
    return Black;
})();
var InputBorderDefault = (function () {
    function InputBorderDefault() {

    };
    InputBorderDefault.value = new InputBorderDefault();
    return InputBorderDefault;
})();
var InputBorderFocused = (function () {
    function InputBorderFocused() {

    };
    InputBorderFocused.value = new InputBorderFocused();
    return InputBorderFocused;
})();
var InputBorderLoading = (function () {
    function InputBorderLoading() {

    };
    InputBorderLoading.value = new InputBorderLoading();
    return InputBorderLoading;
})();
var InputBorderError = (function () {
    function InputBorderError() {

    };
    InputBorderError.value = new InputBorderError();
    return InputBorderError;
})();
var BorderLight = (function () {
    function BorderLight() {

    };
    BorderLight.value = new BorderLight();
    return BorderLight;
})();
var BorderSemiTransparent = (function () {
    function BorderSemiTransparent() {

    };
    BorderSemiTransparent.value = new BorderSemiTransparent();
    return BorderSemiTransparent;
})();
var BorderTransparent = (function () {
    function BorderTransparent() {

    };
    BorderTransparent.value = new BorderTransparent();
    return BorderTransparent;
})();
var BorderCustom = (function () {
    function BorderCustom(value0) {
        this.value0 = value0;
    };
    BorderCustom.create = function (value0) {
        return new BorderCustom(value0);
    };
    return BorderCustom;
})();
var zIndexTooltip = 60;
var zIndexTableHeader = 20;
var zIndexSubNavigation = 30;
var zIndexSelectedRow = 10;
var zIndexNotification = 90;
var zIndexModal = 70;
var zIndexLoadingSpinner = 80;
var zIndexHeader = 40;
var zIndexDropdown = 50;
var subNavigationHeight = new CustomSize("3rem");
var sizeStr = function (v) {
    if (v instanceof CustomSize) {
        return v.value0;
    };
    if (v instanceof XXS) {
        return "0.25rem";
    };
    if (v instanceof XS) {
        return "0.5rem";
    };
    if (v instanceof S) {
        return "0.75rem";
    };
    if (v instanceof M) {
        return "1rem";
    };
    if (v instanceof L) {
        return "1.5rem";
    };
    if (v instanceof XL) {
        return "2rem";
    };
    if (v instanceof XXL) {
        return "3rem";
    };
    throw new Error("Failed pattern match at TacoConstants (line 18, column 1 - line 18, column 26): " + [ v.constructor.name ]);
};
var resourcePriceListColors = {
    background: CustomColor.create(Color.rgb(255)(235)(252)),
    border: CustomColor.create(Color.rgb(203)(45)(178)),
    foreground: GrayDarkest.value
};
var resourceColors = {
    background: CustomColor.create(Color.rgb(235)(255)(252)),
    border: CustomColor.create(Color.rgb(27)(119)(75)),
    foreground: GrayDarkest.value
};
var referenceComponentColors = {
    background: CustomColor.create(Color.rgb(241)(211)(171)),
    border: CustomColor.create(Color.rgb(237)(155)(47)),
    foreground: GrayDarkest.value
};
var pageContentWidth = new CustomSize("1024px");
var headerHeight = new CustomSize("3rem");
var elementColors = {
    background: CustomColor.create(Color.rgb(251)(242)(255)),
    border: CustomColor.create(Color.rgb(125)(11)(239)),
    foreground: GrayDarkest.value
};
var elementActionColors = {
    background: elementColors.background,
    border: elementColors.border,
    foreground: elementColors.foreground
};
var defaultCheckboxColorsWithBg = {
    background: CustomColor.create(Color.rgb(225)(238)(253)),
    border: CustomColor.create(Color.rgb(34)(132)(240)),
    foreground: PrimaryBlue.value
};
var defaultCheckboxColors = {
    background: Transparent.value,
    border: CustomColor.create(Color.rgb(34)(132)(240)),
    foreground: PrimaryBlue.value
};
var componentColors = {
    background: CustomColor.create(Color.rgb(237)(247)(255)),
    border: CustomColor.create(Color.rgb(28)(70)(214)),
    foreground: GrayDarkest.value
};
var color = function (v) {
    if (v instanceof AppBackground) {
        return Color.rgb(251)(251)(251);
    };
    if (v instanceof ElementPurpleDark) {
        return Color.rgb(122)(42)(154);
    };
    if (v instanceof ElementPurpleLight) {
        return Color.rgb(251)(242)(255);
    };
    if (v instanceof ComponentBlueDark) {
        return Color.rgb(44)(110)(182);
    };
    if (v instanceof ComponentBlueLight) {
        return Color.rgb(237)(247)(255);
    };
    if (v instanceof ResourceTealDark) {
        return Color.rgb(45)(203)(178);
    };
    if (v instanceof ResourceTealLight) {
        return Color.rgb(235)(255)(252);
    };
    if (v instanceof TextPrimary) {
        return Color.rgb(64)(64)(64);
    };
    if (v instanceof TextSecondary) {
        return Color.rgb(117)(117)(117);
    };
    if (v instanceof TextInactive) {
        return Color.rgb(175)(175)(175);
    };
    if (v instanceof InputBackgroundDefault) {
        return Color.rgb(255)(255)(255);
    };
    if (v instanceof InputBorderColorDefault) {
        return Color.rgb(220)(220)(220);
    };
    if (v instanceof InputBorderColorFocused) {
        return Color.rgb(28)(70)(214);
    };
    if (v instanceof InputBorderColorDark) {
        return Color.rgb(184)(184)(184);
    };
    if (v instanceof InputBorderColorLoading) {
        return Color.rgb(98)(192)(34);
    };
    if (v instanceof InputBorderColorError) {
        return Color.rgb(192)(34)(98);
    };
    if (v instanceof ButtonBorderColorDefault) {
        return Color.rgb(34)(132)(240);
    };
    if (v instanceof ButtonBackgroundHover) {
        return Color.rgb(224)(232)(255);
    };
    if (v instanceof ButtonBorderColorHover) {
        return Color.rgb(218)(227)(255);
    };
    if (v instanceof ButtonBackgroundNormal) {
        return Color.rgb(238)(249)(255);
    };
    if (v instanceof ButtonTextPrimary) {
        return Color.rgb(28)(70)(214);
    };
    if (v instanceof ButtonTextSecondary) {
        return Color.rgb(68)(68)(68);
    };
    if (v instanceof DangerRedDark) {
        return Color.rgb(215)(66)(99);
    };
    if (v instanceof DangerRedLight) {
        return Color.rgb(252)(237)(241);
    };
    if (v instanceof DangerRedBorder) {
        return Color.rgb(252)(233)(237);
    };
    if (v instanceof GrayDark) {
        return Color.rgb(159)(159)(159);
    };
    if (v instanceof Gray) {
        return Color.rgb(202)(202)(202);
    };
    if (v instanceof GrayLight) {
        return Color.rgb(223)(223)(223);
    };
    if (v instanceof Transparent) {
        return Color.rgba(0)(0)(0)(0.0);
    };
    if (v instanceof CustomColor) {
        return v.value0;
    };
    if (v instanceof GrayDarkest) {
        return Color.rgb(49)(53)(65);
    };
    if (v instanceof GrayMedium) {
        return Color.rgb(117)(117)(117);
    };
    if (v instanceof GrayLightest) {
        return Color.rgb(242)(242)(242);
    };
    if (v instanceof Green) {
        return Color.rgb(0)(116)(45);
    };
    if (v instanceof GreenLight) {
        return Color.rgb(47)(191)(103);
    };
    if (v instanceof PrimaryBlue) {
        return Color.rgb(28)(70)(214);
    };
    if (v instanceof LightBlue) {
        return Color.rgb(244)(249)(254);
    };
    if (v instanceof Orange) {
        return Color.rgb(250)(102)(4);
    };
    if (v instanceof OrangeLight) {
        return Color.rgb(237)(155)(47);
    };
    if (v instanceof Purple) {
        return Color.rgb(122)(42)(154);
    };
    if (v instanceof PurpleLight) {
        return Color.rgb(251)(242)(255);
    };
    if (v instanceof Red) {
        return Color.rgb(244)(81)(80);
    };
    if (v instanceof SecondaryRed) {
        return Color.rgb(215)(66)(99);
    };
    if (v instanceof StatusGreen) {
        return Color.rgb(16)(128)(16);
    };
    if (v instanceof StatusOrange) {
        return Color.rgb(230)(54)(54);
    };
    if (v instanceof White) {
        return Color.rgb(255)(255)(255);
    };
    if (v instanceof Black) {
        return Color.rgb(0)(0)(0);
    };
    throw new Error("Failed pattern match at TacoConstants (line 92, column 1 - line 92, column 30): " + [ v.constructor.name ]);
};
var colorSchemeToStr = function ($9) {
    return Color.cssStringRGBA(color($9));
};
var borderStr = function (v) {
    if (v instanceof InputBorderDefault) {
        return "1px solid " + Color.cssStringRGBA(color(InputBorderColorDefault.value));
    };
    if (v instanceof InputBorderFocused) {
        return "1px solid " + Color.cssStringRGBA(color(InputBorderColorFocused.value));
    };
    if (v instanceof InputBorderLoading) {
        return "1px solid " + Color.cssStringRGBA(color(InputBorderColorLoading.value));
    };
    if (v instanceof InputBorderError) {
        return "1px solid " + Color.cssStringRGBA(color(InputBorderColorError.value));
    };
    if (v instanceof BorderLight) {
        return "1px solid " + Color.cssStringRGBA(color(GrayLight.value));
    };
    if (v instanceof BorderSemiTransparent) {
        return "1px solid rgba(0, 0, 0, 0.1)";
    };
    if (v instanceof BorderTransparent) {
        return "1px solid transparent";
    };
    if (v instanceof BorderCustom) {
        return "1px solid " + colorSchemeToStr(v.value0);
    };
    throw new Error("Failed pattern match at TacoConstants (line 234, column 1 - line 234, column 35): " + [ v.constructor.name ]);
};
module.exports = {
    XXS: XXS,
    XS: XS,
    S: S,
    M: M,
    L: L,
    XL: XL,
    XXL: XXL,
    CustomSize: CustomSize,
    sizeStr: sizeStr,
    AppBackground: AppBackground,
    ElementPurpleDark: ElementPurpleDark,
    ElementPurpleLight: ElementPurpleLight,
    ComponentBlueDark: ComponentBlueDark,
    ComponentBlueLight: ComponentBlueLight,
    ResourceTealDark: ResourceTealDark,
    ResourceTealLight: ResourceTealLight,
    TextPrimary: TextPrimary,
    TextSecondary: TextSecondary,
    TextInactive: TextInactive,
    InputBackgroundDefault: InputBackgroundDefault,
    InputBorderColorDefault: InputBorderColorDefault,
    InputBorderColorFocused: InputBorderColorFocused,
    InputBorderColorDark: InputBorderColorDark,
    InputBorderColorLoading: InputBorderColorLoading,
    InputBorderColorError: InputBorderColorError,
    ButtonBorderColorDefault: ButtonBorderColorDefault,
    ButtonBackgroundHover: ButtonBackgroundHover,
    ButtonBorderColorHover: ButtonBorderColorHover,
    ButtonBackgroundNormal: ButtonBackgroundNormal,
    ButtonTextPrimary: ButtonTextPrimary,
    ButtonTextSecondary: ButtonTextSecondary,
    DangerRedDark: DangerRedDark,
    DangerRedLight: DangerRedLight,
    DangerRedBorder: DangerRedBorder,
    Gray: Gray,
    Transparent: Transparent,
    CustomColor: CustomColor,
    GrayDarkest: GrayDarkest,
    GrayDark: GrayDark,
    GrayLight: GrayLight,
    GrayLightest: GrayLightest,
    GrayMedium: GrayMedium,
    Green: Green,
    GreenLight: GreenLight,
    LightBlue: LightBlue,
    PrimaryBlue: PrimaryBlue,
    SecondaryRed: SecondaryRed,
    Orange: Orange,
    OrangeLight: OrangeLight,
    Red: Red,
    StatusGreen: StatusGreen,
    StatusOrange: StatusOrange,
    Purple: Purple,
    PurpleLight: PurpleLight,
    White: White,
    Black: Black,
    color: color,
    colorSchemeToStr: colorSchemeToStr,
    elementActionColors: elementActionColors,
    defaultCheckboxColors: defaultCheckboxColors,
    defaultCheckboxColorsWithBg: defaultCheckboxColorsWithBg,
    elementColors: elementColors,
    componentColors: componentColors,
    resourceColors: resourceColors,
    resourcePriceListColors: resourcePriceListColors,
    referenceComponentColors: referenceComponentColors,
    InputBorderDefault: InputBorderDefault,
    InputBorderFocused: InputBorderFocused,
    InputBorderLoading: InputBorderLoading,
    InputBorderError: InputBorderError,
    BorderLight: BorderLight,
    BorderSemiTransparent: BorderSemiTransparent,
    BorderTransparent: BorderTransparent,
    BorderCustom: BorderCustom,
    borderStr: borderStr,
    headerHeight: headerHeight,
    subNavigationHeight: subNavigationHeight,
    zIndexNotification: zIndexNotification,
    zIndexLoadingSpinner: zIndexLoadingSpinner,
    zIndexModal: zIndexModal,
    zIndexTooltip: zIndexTooltip,
    zIndexDropdown: zIndexDropdown,
    zIndexHeader: zIndexHeader,
    zIndexSubNavigation: zIndexSubNavigation,
    zIndexTableHeader: zIndexTableHeader,
    zIndexSelectedRow: zIndexSelectedRow,
    pageContentWidth: pageContentWidth
};
