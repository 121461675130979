// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Effect = require("../Effect/index.js");
var Gap = require("../Gap/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var StateHooks = require("../StateHooks/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_FrontEndRoutes = require("../Types.FrontEndRoutes/index.js");
var UIDemoSidebar_Styles = require("../UIDemoSidebar.Styles/index.js");
var sidebarItem = TofuHooks.mkHookComponent("SidebarItem")(function (props) {
    return function __do() {
        var dispatch = StateHooks.useDispatch();
        return TacoButton.component()()({
            text: props.view.title,
            onClick: Data_Maybe.Just.create(dispatch(Actions.NavigateRoute.create(new Types_FrontEndRoutes.UIDemoFERoute({
                componentId: new Data_Maybe.Just(props.view.title)
            })))),
            color: (function () {
                var $2 = Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
                    return v.title;
                })(props.activeView))(new Data_Maybe.Just(props.view.title));
                if ($2) {
                    return TacoConstants.SecondaryRed.value;
                };
                return TacoConstants.PrimaryBlue.value;
            })()
        });
    };
});
var component = TofuHooks.mkHookComponent("Sidebar")(function (props) {
    var items = Data_Functor.map(Data_Functor.functorArray)(function (view) {
        return sidebarItem({
            activeView: props.activeView,
            view: view
        });
    })(props.demoViews);
    return Control_Applicative.pure(Effect.applicativeEffect)(React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
        className: "__Sidebar",
        css: UIDemoSidebar_Styles.uiDemoSidebarContainerStyles,
        children: Control_Applicative.pure(Control_Applicative.applicativeArray)(Gap.gapY(TacoConstants.XXS.value)(items))
    }));
});
module.exports = {
    component: component
};
