// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";

export function getBackBtn() {
  return (
    <button
      type="button"
      className="back-button"
      onClick={() => this.changeState("signIn")}
    >
      <span>{"< palaa kirjautumiseen"}</span>
    </button>
  );
}
