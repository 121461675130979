// Generated by purs version 0.14.5
"use strict";
var Control_Alt = require("../Control.Alt/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Locale = require("../Locale/index.js");
var Routing_Match = require("../Routing.Match/index.js");
var StringUtils = require("../StringUtils/index.js");
var Types_Component = require("../Types.Component/index.js");
var Types_Element = require("../Types.Element/index.js");
var Types_Project = require("../Types.Project/index.js");
var RootRoute = (function () {
    function RootRoute() {

    };
    RootRoute.value = new RootRoute();
    return RootRoute;
})();
var TsRoute = (function () {
    function TsRoute() {

    };
    TsRoute.value = new TsRoute();
    return TsRoute;
})();
var ReportingProjectListRoute = (function () {
    function ReportingProjectListRoute() {

    };
    ReportingProjectListRoute.value = new ReportingProjectListRoute();
    return ReportingProjectListRoute;
})();
var EstimationProjectsRoute = (function () {
    function EstimationProjectsRoute() {

    };
    EstimationProjectsRoute.value = new EstimationProjectsRoute();
    return EstimationProjectsRoute;
})();
var EstimationProjectDetailsFERoute = (function () {
    function EstimationProjectDetailsFERoute(value0) {
        this.value0 = value0;
    };
    EstimationProjectDetailsFERoute.create = function (value0) {
        return new EstimationProjectDetailsFERoute(value0);
    };
    return EstimationProjectDetailsFERoute;
})();
var PortfolioReportFERoute = (function () {
    function PortfolioReportFERoute() {

    };
    PortfolioReportFERoute.value = new PortfolioReportFERoute();
    return PortfolioReportFERoute;
})();
var AddCostControlProjectFERoute = (function () {
    function AddCostControlProjectFERoute() {

    };
    AddCostControlProjectFERoute.value = new AddCostControlProjectFERoute();
    return AddCostControlProjectFERoute;
})();
var AddEstimationProjectFERoute = (function () {
    function AddEstimationProjectFERoute() {

    };
    AddEstimationProjectFERoute.value = new AddEstimationProjectFERoute();
    return AddEstimationProjectFERoute;
})();
var ImportEstimationProjectFERoute = (function () {
    function ImportEstimationProjectFERoute() {

    };
    ImportEstimationProjectFERoute.value = new ImportEstimationProjectFERoute();
    return ImportEstimationProjectFERoute;
})();
var ReportsFERoute = (function () {
    function ReportsFERoute(value0) {
        this.value0 = value0;
    };
    ReportsFERoute.create = function (value0) {
        return new ReportsFERoute(value0);
    };
    return ReportsFERoute;
})();
var IncomeFERoute = (function () {
    function IncomeFERoute(value0) {
        this.value0 = value0;
    };
    IncomeFERoute.create = function (value0) {
        return new IncomeFERoute(value0);
    };
    return IncomeFERoute;
})();
var CostControlFERoute = (function () {
    function CostControlFERoute(value0) {
        this.value0 = value0;
    };
    CostControlFERoute.create = function (value0) {
        return new CostControlFERoute(value0);
    };
    return CostControlFERoute;
})();
var InvoicesFERoute = (function () {
    function InvoicesFERoute(value0) {
        this.value0 = value0;
    };
    InvoicesFERoute.create = function (value0) {
        return new InvoicesFERoute(value0);
    };
    return InvoicesFERoute;
})();
var MonthlyReportFERoute = (function () {
    function MonthlyReportFERoute(value0) {
        this.value0 = value0;
    };
    MonthlyReportFERoute.create = function (value0) {
        return new MonthlyReportFERoute(value0);
    };
    return MonthlyReportFERoute;
})();
var CostControlPrintingFERoute = (function () {
    function CostControlPrintingFERoute(value0) {
        this.value0 = value0;
    };
    CostControlPrintingFERoute.create = function (value0) {
        return new CostControlPrintingFERoute(value0);
    };
    return CostControlPrintingFERoute;
})();
var RenameMainCostGroupsFERoute = (function () {
    function RenameMainCostGroupsFERoute(value0) {
        this.value0 = value0;
    };
    RenameMainCostGroupsFERoute.create = function (value0) {
        return new RenameMainCostGroupsFERoute(value0);
    };
    return RenameMainCostGroupsFERoute;
})();
var ProjectDetailsFERoute = (function () {
    function ProjectDetailsFERoute(value0) {
        this.value0 = value0;
    };
    ProjectDetailsFERoute.create = function (value0) {
        return new ProjectDetailsFERoute(value0);
    };
    return ProjectDetailsFERoute;
})();
var PrintingFERoute = (function () {
    function PrintingFERoute(value0) {
        this.value0 = value0;
    };
    PrintingFERoute.create = function (value0) {
        return new PrintingFERoute(value0);
    };
    return PrintingFERoute;
})();
var OrganizationFERoute = (function () {
    function OrganizationFERoute() {

    };
    OrganizationFERoute.value = new OrganizationFERoute();
    return OrganizationFERoute;
})();
var EstimationFERoute = (function () {
    function EstimationFERoute(value0) {
        this.value0 = value0;
    };
    EstimationFERoute.create = function (value0) {
        return new EstimationFERoute(value0);
    };
    return EstimationFERoute;
})();
var EstimationReferenceRoute = (function () {
    function EstimationReferenceRoute(value0) {
        this.value0 = value0;
    };
    EstimationReferenceRoute.create = function (value0) {
        return new EstimationReferenceRoute(value0);
    };
    return EstimationReferenceRoute;
})();
var ReferenceEstimationElementsRoute = (function () {
    function ReferenceEstimationElementsRoute(value0) {
        this.value0 = value0;
    };
    ReferenceEstimationElementsRoute.create = function (value0) {
        return new ReferenceEstimationElementsRoute(value0);
    };
    return ReferenceEstimationElementsRoute;
})();
var EstimationBuildingElementsRoute = (function () {
    function EstimationBuildingElementsRoute(value0) {
        this.value0 = value0;
    };
    EstimationBuildingElementsRoute.create = function (value0) {
        return new EstimationBuildingElementsRoute(value0);
    };
    return EstimationBuildingElementsRoute;
})();
var EstimationResourcesRoute = (function () {
    function EstimationResourcesRoute(value0) {
        this.value0 = value0;
    };
    EstimationResourcesRoute.create = function (value0) {
        return new EstimationResourcesRoute(value0);
    };
    return EstimationResourcesRoute;
})();
var NewEstimationResourcesRoute = (function () {
    function NewEstimationResourcesRoute(value0) {
        this.value0 = value0;
    };
    NewEstimationResourcesRoute.create = function (value0) {
        return new NewEstimationResourcesRoute(value0);
    };
    return NewEstimationResourcesRoute;
})();
var EstimationResourcePriceListRoute = (function () {
    function EstimationResourcePriceListRoute(value0) {
        this.value0 = value0;
    };
    EstimationResourcePriceListRoute.create = function (value0) {
        return new EstimationResourcePriceListRoute(value0);
    };
    return EstimationResourcePriceListRoute;
})();
var EstimationReportsRoute = (function () {
    function EstimationReportsRoute(value0) {
        this.value0 = value0;
    };
    EstimationReportsRoute.create = function (value0) {
        return new EstimationReportsRoute(value0);
    };
    return EstimationReportsRoute;
})();
var OfferPageRoute = (function () {
    function OfferPageRoute(value0) {
        this.value0 = value0;
    };
    OfferPageRoute.create = function (value0) {
        return new OfferPageRoute(value0);
    };
    return OfferPageRoute;
})();
var LogsFERoute = (function () {
    function LogsFERoute() {

    };
    LogsFERoute.value = new LogsFERoute();
    return LogsFERoute;
})();
var NotificationsFERoute = (function () {
    function NotificationsFERoute() {

    };
    NotificationsFERoute.value = new NotificationsFERoute();
    return NotificationsFERoute;
})();
var AdminFERoute = (function () {
    function AdminFERoute() {

    };
    AdminFERoute.value = new AdminFERoute();
    return AdminFERoute;
})();
var SuperAdminFERoute = (function () {
    function SuperAdminFERoute() {

    };
    SuperAdminFERoute.value = new SuperAdminFERoute();
    return SuperAdminFERoute;
})();
var IntegrationFERoute = (function () {
    function IntegrationFERoute() {

    };
    IntegrationFERoute.value = new IntegrationFERoute();
    return IntegrationFERoute;
})();
var ReleaseNotesFERoute = (function () {
    function ReleaseNotesFERoute() {

    };
    ReleaseNotesFERoute.value = new ReleaseNotesFERoute();
    return ReleaseNotesFERoute;
})();
var UIDemoFERoute = (function () {
    function UIDemoFERoute(value0) {
        this.value0 = value0;
    };
    UIDemoFERoute.create = function (value0) {
        return new UIDemoFERoute(value0);
    };
    return UIDemoFERoute;
})();
var DixonFERoute = (function () {
    function DixonFERoute(value0) {
        this.value0 = value0;
    };
    DixonFERoute.create = function (value0) {
        return new DixonFERoute(value0);
    };
    return DixonFERoute;
})();
var OauthCode = (function () {
    function OauthCode() {

    };
    OauthCode.value = new OauthCode();
    return OauthCode;
})();
var NotFoundFERoute = (function () {
    function NotFoundFERoute() {

    };
    NotFoundFERoute.value = new NotFoundFERoute();
    return NotFoundFERoute;
})();
var getRouteAppName = function (route) {
    var superadmin = Locale.lookup_("mode_name_superadmin");
    var reporting = Locale.lookup_("mode_name_reporting");
    var integration = Locale.lookup_("mode_name_integration");
    var estimation = Locale.lookup_("mode_name_estimation");
    var dixon = Locale.lookup_("mode_name_dixon");
    var admin = Locale.lookup_("mode_name_admin");
    if (route instanceof RootRoute) {
        return Data_Maybe.Nothing.value;
    };
    if (route instanceof TsRoute) {
        return new Data_Maybe.Just("Tocoman");
    };
    if (route instanceof ReportingProjectListRoute) {
        return new Data_Maybe.Just(reporting);
    };
    if (route instanceof EstimationProjectsRoute) {
        return new Data_Maybe.Just(estimation);
    };
    if (route instanceof EstimationProjectDetailsFERoute) {
        return new Data_Maybe.Just(estimation);
    };
    if (route instanceof PortfolioReportFERoute) {
        return new Data_Maybe.Just(reporting);
    };
    if (route instanceof AddCostControlProjectFERoute) {
        return new Data_Maybe.Just(reporting);
    };
    if (route instanceof PrintingFERoute) {
        return new Data_Maybe.Just(estimation);
    };
    if (route instanceof AddEstimationProjectFERoute) {
        return new Data_Maybe.Just(estimation);
    };
    if (route instanceof ImportEstimationProjectFERoute) {
        return new Data_Maybe.Just(estimation);
    };
    if (route instanceof ReportsFERoute) {
        return new Data_Maybe.Just(reporting);
    };
    if (route instanceof IncomeFERoute) {
        return new Data_Maybe.Just(reporting);
    };
    if (route instanceof CostControlFERoute) {
        return new Data_Maybe.Just(reporting);
    };
    if (route instanceof InvoicesFERoute) {
        return new Data_Maybe.Just(reporting);
    };
    if (route instanceof MonthlyReportFERoute) {
        return new Data_Maybe.Just(reporting);
    };
    if (route instanceof CostControlPrintingFERoute) {
        return new Data_Maybe.Just(reporting);
    };
    if (route instanceof RenameMainCostGroupsFERoute) {
        return new Data_Maybe.Just(reporting);
    };
    if (route instanceof ProjectDetailsFERoute) {
        return new Data_Maybe.Just(reporting);
    };
    if (route instanceof OrganizationFERoute) {
        return new Data_Maybe.Just(reporting);
    };
    if (route instanceof EstimationFERoute) {
        return new Data_Maybe.Just(estimation);
    };
    if (route instanceof EstimationReferenceRoute) {
        return new Data_Maybe.Just(estimation);
    };
    if (route instanceof ReferenceEstimationElementsRoute) {
        return new Data_Maybe.Just(estimation);
    };
    if (route instanceof EstimationBuildingElementsRoute) {
        return new Data_Maybe.Just(estimation);
    };
    if (route instanceof EstimationResourcesRoute) {
        return new Data_Maybe.Just(estimation);
    };
    if (route instanceof NewEstimationResourcesRoute) {
        return new Data_Maybe.Just(estimation);
    };
    if (route instanceof EstimationResourcePriceListRoute) {
        return new Data_Maybe.Just(estimation);
    };
    if (route instanceof EstimationReportsRoute) {
        return new Data_Maybe.Just(estimation);
    };
    if (route instanceof OfferPageRoute) {
        return new Data_Maybe.Just(estimation);
    };
    if (route instanceof LogsFERoute) {
        return Data_Maybe.Nothing.value;
    };
    if (route instanceof NotificationsFERoute) {
        return Data_Maybe.Nothing.value;
    };
    if (route instanceof AdminFERoute) {
        return new Data_Maybe.Just(admin);
    };
    if (route instanceof SuperAdminFERoute) {
        return new Data_Maybe.Just(superadmin);
    };
    if (route instanceof IntegrationFERoute) {
        return new Data_Maybe.Just(integration);
    };
    if (route instanceof ReleaseNotesFERoute) {
        return Data_Maybe.Nothing.value;
    };
    if (route instanceof UIDemoFERoute) {
        return Data_Maybe.Nothing.value;
    };
    if (route instanceof DixonFERoute) {
        return new Data_Maybe.Just(dixon);
    };
    if (route instanceof OauthCode) {
        return Data_Maybe.Nothing.value;
    };
    if (route instanceof NotFoundFERoute) {
        return Data_Maybe.Nothing.value;
    };
    throw new Error("Failed pattern match at Types.FrontEndRoutes (line 62, column 25 - line 100, column 29): " + [ route.constructor.name ]);
};
var fragments = {
    reports: "reports",
    ts: "new",
    printing: "printing",
    "cost-control-printing": "cost-control-printing",
    "project-list": "project-list",
    income: "income",
    "cost-control": "cost-control",
    invoices: "invoices",
    "monthly-report": "monthly-report",
    "rename-main-cost-groups": "rename-main-cost-groups",
    "add-project": "add-project",
    "add-estimation-project": "add-estimation-project",
    "import-estimation-project": "import-estimation-project",
    "project-details": "project-details",
    "estimation-project-details": "estimation-project-details",
    organization: "organization",
    estimation: "estimation",
    "estimation-reference": "estimation-reference",
    "estimation-reference-elements": "estimation-reference-elements",
    "estimation-resources": "estimation-resources",
    "estimation-resource-price-list": "estimation-resource-price-list",
    offer: "offer",
    "estimation-building-elements": "estimation-building-elements",
    buildings: "buildings",
    logs: "logs",
    notifications: "notifications",
    admin: "admin",
    superadmin: "superadmin",
    integration: "integration",
    "release-notes": "release-notes",
    uiDemo: "ui-demo",
    dixon: "dixon",
    measurements: "measurements",
    "portfolio-report": "portfolio-report",
    "estimation-reports": "estimation-reports"
};
var eqFERoute = {
    eq: function (x) {
        return function (y) {
            if (x instanceof RootRoute && y instanceof RootRoute) {
                return true;
            };
            if (x instanceof TsRoute && y instanceof TsRoute) {
                return true;
            };
            if (x instanceof ReportingProjectListRoute && y instanceof ReportingProjectListRoute) {
                return true;
            };
            if (x instanceof EstimationProjectsRoute && y instanceof EstimationProjectsRoute) {
                return true;
            };
            if (x instanceof EstimationProjectDetailsFERoute && y instanceof EstimationProjectDetailsFERoute) {
                return Data_Eq.eq(Types_Project.eqProjectId)(x.value0.projectId)(y.value0.projectId);
            };
            if (x instanceof PortfolioReportFERoute && y instanceof PortfolioReportFERoute) {
                return true;
            };
            if (x instanceof AddCostControlProjectFERoute && y instanceof AddCostControlProjectFERoute) {
                return true;
            };
            if (x instanceof AddEstimationProjectFERoute && y instanceof AddEstimationProjectFERoute) {
                return true;
            };
            if (x instanceof ImportEstimationProjectFERoute && y instanceof ImportEstimationProjectFERoute) {
                return true;
            };
            if (x instanceof ReportsFERoute && y instanceof ReportsFERoute) {
                return Data_Eq.eq(Types_Project.eqProjectId)(x.value0.projectId)(y.value0.projectId);
            };
            if (x instanceof IncomeFERoute && y instanceof IncomeFERoute) {
                return Data_Eq.eq(Types_Project.eqProjectId)(x.value0.projectId)(y.value0.projectId);
            };
            if (x instanceof CostControlFERoute && y instanceof CostControlFERoute) {
                return Data_Eq.eq(Types_Project.eqProjectId)(x.value0.projectId)(y.value0.projectId);
            };
            if (x instanceof InvoicesFERoute && y instanceof InvoicesFERoute) {
                return Data_Eq.eq(Types_Project.eqProjectId)(x.value0.projectId)(y.value0.projectId);
            };
            if (x instanceof MonthlyReportFERoute && y instanceof MonthlyReportFERoute) {
                return Data_Eq.eq(Types_Project.eqProjectId)(x.value0.projectId)(y.value0.projectId);
            };
            if (x instanceof CostControlPrintingFERoute && y instanceof CostControlPrintingFERoute) {
                return Data_Eq.eq(Types_Project.eqProjectId)(x.value0.projectId)(y.value0.projectId);
            };
            if (x instanceof RenameMainCostGroupsFERoute && y instanceof RenameMainCostGroupsFERoute) {
                return Data_Eq.eq(Types_Project.eqProjectId)(x.value0.projectId)(y.value0.projectId);
            };
            if (x instanceof ProjectDetailsFERoute && y instanceof ProjectDetailsFERoute) {
                return Data_Eq.eq(Types_Project.eqProjectId)(x.value0.projectId)(y.value0.projectId);
            };
            if (x instanceof PrintingFERoute && y instanceof PrintingFERoute) {
                return Data_Eq.eq(Types_Project.eqProjectId)(x.value0.projectId)(y.value0.projectId);
            };
            if (x instanceof OrganizationFERoute && y instanceof OrganizationFERoute) {
                return true;
            };
            if (x instanceof EstimationFERoute && y instanceof EstimationFERoute) {
                return Data_Eq.eq(Data_Maybe.eqMaybe(Types_Component.eqComponentId))(x.value0.initialSelectedComponent)(y.value0.initialSelectedComponent) && Data_Eq.eq(Types_Project.eqProjectId)(x.value0.projectId)(y.value0.projectId);
            };
            if (x instanceof EstimationReferenceRoute && y instanceof EstimationReferenceRoute) {
                return Data_Eq.eq(Types_Project.eqProjectId)(x.value0.projectId)(y.value0.projectId);
            };
            if (x instanceof ReferenceEstimationElementsRoute && y instanceof ReferenceEstimationElementsRoute) {
                return Data_Eq.eq(Types_Project.eqProjectId)(x.value0.projectId)(y.value0.projectId);
            };
            if (x instanceof EstimationBuildingElementsRoute && y instanceof EstimationBuildingElementsRoute) {
                return Data_Eq.eq(Data_Maybe.eqMaybe(Types_Element.eqElementId))(x.value0.initialSelectedElement)(y.value0.initialSelectedElement) && Data_Eq.eq(Types_Project.eqProjectId)(x.value0.projectId)(y.value0.projectId);
            };
            if (x instanceof EstimationResourcesRoute && y instanceof EstimationResourcesRoute) {
                return Data_Eq.eq(Types_Project.eqProjectId)(x.value0.projectId)(y.value0.projectId);
            };
            if (x instanceof NewEstimationResourcesRoute && y instanceof NewEstimationResourcesRoute) {
                return Data_Eq.eq(Types_Project.eqProjectId)(x.value0.projectId)(y.value0.projectId);
            };
            if (x instanceof EstimationResourcePriceListRoute && y instanceof EstimationResourcePriceListRoute) {
                return Data_Eq.eq(Types_Project.eqProjectId)(x.value0.projectId)(y.value0.projectId);
            };
            if (x instanceof EstimationReportsRoute && y instanceof EstimationReportsRoute) {
                return Data_Eq.eq(Types_Project.eqProjectId)(x.value0.projectId)(y.value0.projectId);
            };
            if (x instanceof OfferPageRoute && y instanceof OfferPageRoute) {
                return Data_Eq.eq(Types_Project.eqProjectId)(x.value0.projectId)(y.value0.projectId);
            };
            if (x instanceof LogsFERoute && y instanceof LogsFERoute) {
                return true;
            };
            if (x instanceof NotificationsFERoute && y instanceof NotificationsFERoute) {
                return true;
            };
            if (x instanceof AdminFERoute && y instanceof AdminFERoute) {
                return true;
            };
            if (x instanceof SuperAdminFERoute && y instanceof SuperAdminFERoute) {
                return true;
            };
            if (x instanceof IntegrationFERoute && y instanceof IntegrationFERoute) {
                return true;
            };
            if (x instanceof ReleaseNotesFERoute && y instanceof ReleaseNotesFERoute) {
                return true;
            };
            if (x instanceof UIDemoFERoute && y instanceof UIDemoFERoute) {
                return Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqString))(x.value0.componentId)(y.value0.componentId);
            };
            if (x instanceof DixonFERoute && y instanceof DixonFERoute) {
                return Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqInt))(x.value0.mDrawingId)(y.value0.mDrawingId) && Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqInt))(x.value0.mFileId)(y.value0.mFileId) && Data_Eq.eq(Data_Maybe.eqMaybe(Data_Eq.eqInt))(x.value0.mMeasurementGroupId)(y.value0.mMeasurementGroupId) && Data_Eq.eq(Types_Project.eqProjectId)(x.value0.projectId)(y.value0.projectId);
            };
            if (x instanceof OauthCode && y instanceof OauthCode) {
                return true;
            };
            if (x instanceof NotFoundFERoute && y instanceof NotFoundFERoute) {
                return true;
            };
            return false;
        };
    }
};
var appRoute = Data_Foldable.oneOf(Data_Foldable.foldableArray)(Routing_Match.matchPlus)([ Control_Apply.applySecond(Routing_Match.matchApply)(Routing_Match.root)(Data_Foldable.oneOf(Data_Foldable.foldableArray)(Routing_Match.matchPlus)([ Control_Apply.applyFirst(Routing_Match.matchApply)(Data_Functor.map(Routing_Match.matchFunctor)(function (i) {
    return new ReportsFERoute({
        projectId: i
    });
})(Routing_Match["int"]))(Routing_Match.lit(fragments.reports)), Data_Functor.map(Routing_Match.matchFunctor)(function (v) {
    return TsRoute.value;
})(Routing_Match.lit(fragments.ts)), Control_Apply.applyFirst(Routing_Match.matchApply)(Data_Functor.map(Routing_Match.matchFunctor)(function (i) {
    return new IncomeFERoute({
        projectId: i
    });
})(Routing_Match["int"]))(Routing_Match.lit(fragments.income)), Control_Apply.applyFirst(Routing_Match.matchApply)(Data_Functor.map(Routing_Match.matchFunctor)(function (i) {
    return new CostControlFERoute({
        projectId: i
    });
})(Routing_Match["int"]))(Routing_Match.lit(fragments["cost-control"])), Control_Apply.applyFirst(Routing_Match.matchApply)(Data_Functor.map(Routing_Match.matchFunctor)(function (i) {
    return new InvoicesFERoute({
        projectId: i
    });
})(Routing_Match["int"]))(Routing_Match.lit(fragments.invoices)), Control_Apply.applyFirst(Routing_Match.matchApply)(Data_Functor.map(Routing_Match.matchFunctor)(function (i) {
    return new MonthlyReportFERoute({
        projectId: i
    });
})(Routing_Match["int"]))(Routing_Match.lit(fragments["monthly-report"])), Control_Apply.applyFirst(Routing_Match.matchApply)(Data_Functor.map(Routing_Match.matchFunctor)(function (i) {
    return new CostControlPrintingFERoute({
        projectId: i
    });
})(Routing_Match["int"]))(Routing_Match.lit(fragments["cost-control-printing"])), Control_Apply.applyFirst(Routing_Match.matchApply)(Data_Functor.map(Routing_Match.matchFunctor)(function (i) {
    return new RenameMainCostGroupsFERoute({
        projectId: i
    });
})(Routing_Match["int"]))(Routing_Match.lit(fragments["rename-main-cost-groups"])), Control_Apply.applyFirst(Routing_Match.matchApply)(Data_Functor.map(Routing_Match.matchFunctor)(function (i) {
    return new ProjectDetailsFERoute({
        projectId: i
    });
})(Routing_Match["int"]))(Routing_Match.lit(fragments["project-details"])), Control_Apply.applyFirst(Routing_Match.matchApply)(Data_Functor.map(Routing_Match.matchFunctor)(function (i) {
    return new EstimationProjectDetailsFERoute({
        projectId: i
    });
})(Routing_Match["int"]))(Routing_Match.lit(fragments["estimation-project-details"])), Data_Functor.map(Routing_Match.matchFunctor)(function (v) {
    return OrganizationFERoute.value;
})(Routing_Match.lit(fragments.organization)), (function () {
    var handle = function (i) {
        return function (v) {
            return function (initialSelectedComponent) {
                return new EstimationFERoute({
                    projectId: i,
                    initialSelectedComponent: Data_Functor.map(Data_Maybe.functorMaybe)(Types_Component.ComponentId)(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(StringUtils.readStrToInt)(initialSelectedComponent))
                });
            };
        };
    };
    return Control_Apply.apply(Routing_Match.matchApply)(Control_Apply.apply(Routing_Match.matchApply)(Data_Functor.map(Routing_Match.matchFunctor)(handle)(Routing_Match["int"]))(Routing_Match.lit(fragments.estimation)))(Routing_Match.optionalMatch(Routing_Match.param("componentId")));
})(), (function () {
    var handle = function (i) {
        return function (v) {
            return function (initialSelectedElement) {
                return new EstimationBuildingElementsRoute({
                    projectId: i,
                    initialSelectedElement: Data_Functor.map(Data_Maybe.functorMaybe)(Types_Element.ElementId)(Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(StringUtils.readStrToInt)(initialSelectedElement))
                });
            };
        };
    };
    return Control_Apply.apply(Routing_Match.matchApply)(Control_Apply.apply(Routing_Match.matchApply)(Data_Functor.map(Routing_Match.matchFunctor)(handle)(Routing_Match["int"]))(Routing_Match.lit(fragments["estimation-building-elements"])))(Routing_Match.optionalMatch(Routing_Match.param("elementId")));
})(), Data_Functor.map(Routing_Match.matchFunctor)(function (v) {
    return AddCostControlProjectFERoute.value;
})(Routing_Match.lit(fragments["add-project"])), Data_Functor.map(Routing_Match.matchFunctor)(function (v) {
    return AddEstimationProjectFERoute.value;
})(Routing_Match.lit(fragments["add-estimation-project"])), Data_Functor.map(Routing_Match.matchFunctor)(function (v) {
    return ImportEstimationProjectFERoute.value;
})(Routing_Match.lit(fragments["import-estimation-project"])), Data_Functor.map(Routing_Match.matchFunctor)(function (v) {
    return LogsFERoute.value;
})(Routing_Match.lit(fragments.logs)), Data_Functor.map(Routing_Match.matchFunctor)(function (v) {
    return NotificationsFERoute.value;
})(Routing_Match.lit(fragments.notifications)), Data_Functor.map(Routing_Match.matchFunctor)(function (v) {
    return AdminFERoute.value;
})(Routing_Match.lit(fragments.admin)), Data_Functor.map(Routing_Match.matchFunctor)(function (v) {
    return SuperAdminFERoute.value;
})(Routing_Match.lit(fragments.superadmin)), Data_Functor.map(Routing_Match.matchFunctor)(function (v) {
    return IntegrationFERoute.value;
})(Routing_Match.lit(fragments.integration)), Data_Functor.map(Routing_Match.matchFunctor)(function (v) {
    return ReleaseNotesFERoute.value;
})(Routing_Match.lit(fragments["release-notes"])), Data_Functor.map(Routing_Match.matchFunctor)(function (i) {
    return new UIDemoFERoute({
        componentId: new Data_Maybe.Just(i)
    });
})(Control_Apply.applySecond(Routing_Match.matchApply)(Routing_Match.lit(fragments.uiDemo))(Routing_Match.str)), Data_Functor.map(Routing_Match.matchFunctor)(function (v) {
    return new UIDemoFERoute({
        componentId: Data_Maybe.Nothing.value
    });
})(Routing_Match.lit(fragments.uiDemo)), Data_Functor.map(Routing_Match.matchFunctor)(function (v) {
    return PortfolioReportFERoute.value;
})(Routing_Match.lit(fragments["portfolio-report"])), Data_Functor.map(Routing_Match.matchFunctor)(function (v) {
    return ReportingProjectListRoute.value;
})(Routing_Match.lit(fragments["cost-control"])), Data_Functor.map(Routing_Match.matchFunctor)(function (v) {
    return EstimationProjectsRoute.value;
})(Routing_Match.lit(fragments.estimation)), Control_Apply.applyFirst(Routing_Match.matchApply)(Data_Functor.map(Routing_Match.matchFunctor)(function (i) {
    return new EstimationReferenceRoute({
        projectId: i
    });
})(Routing_Match["int"]))(Routing_Match.lit(fragments["estimation-reference"])), Control_Apply.applyFirst(Routing_Match.matchApply)(Data_Functor.map(Routing_Match.matchFunctor)(function (i) {
    return new PrintingFERoute({
        projectId: i
    });
})(Routing_Match["int"]))(Routing_Match.lit(fragments.printing)), Control_Apply.applyFirst(Routing_Match.matchApply)(Data_Functor.map(Routing_Match.matchFunctor)(function (i) {
    return new ReferenceEstimationElementsRoute({
        projectId: i
    });
})(Routing_Match["int"]))(Routing_Match.lit(fragments["estimation-reference-elements"])), Control_Apply.applyFirst(Routing_Match.matchApply)(Data_Functor.map(Routing_Match.matchFunctor)(function (i) {
    return new NewEstimationResourcesRoute({
        projectId: i
    });
})(Routing_Match["int"]))(Control_Apply.applyFirst(Routing_Match.matchApply)(Routing_Match.lit(fragments["estimation-resources"]))(Routing_Match.lit("new"))), Control_Apply.applyFirst(Routing_Match.matchApply)(Data_Functor.map(Routing_Match.matchFunctor)(function (i) {
    return new EstimationResourcesRoute({
        projectId: i
    });
})(Routing_Match["int"]))(Routing_Match.lit(fragments["estimation-resources"])), Control_Apply.applyFirst(Routing_Match.matchApply)(Data_Functor.map(Routing_Match.matchFunctor)(function (i) {
    return new EstimationResourcePriceListRoute({
        projectId: i
    });
})(Routing_Match["int"]))(Routing_Match.lit(fragments["estimation-resource-price-list"])), Control_Apply.applyFirst(Routing_Match.matchApply)(Data_Functor.map(Routing_Match.matchFunctor)(function (i) {
    return new OfferPageRoute({
        projectId: i
    });
})(Routing_Match["int"]))(Routing_Match.lit(fragments.offer)), Control_Apply.applyFirst(Routing_Match.matchApply)(Data_Functor.map(Routing_Match.matchFunctor)(function (i) {
    return new EstimationReportsRoute({
        projectId: i
    });
})(Routing_Match["int"]))(Routing_Match.lit(fragments["estimation-reports"])), (function () {
    var handle = function (i) {
        return function (v) {
            return function (mFileId) {
                return function (mDrawingId) {
                    return function (mMeasurementGroupId) {
                        return new DixonFERoute({
                            projectId: i,
                            mFileId: Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(StringUtils.readStrToInt)(mFileId),
                            mDrawingId: Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(StringUtils.readStrToInt)(mDrawingId),
                            mMeasurementGroupId: Control_Bind.bindFlipped(Data_Maybe.bindMaybe)(StringUtils.readStrToInt)(mMeasurementGroupId)
                        });
                    };
                };
            };
        };
    };
    return Control_Apply.apply(Routing_Match.matchApply)(Control_Apply.apply(Routing_Match.matchApply)(Control_Apply.apply(Routing_Match.matchApply)(Control_Apply.apply(Routing_Match.matchApply)(Data_Functor.map(Routing_Match.matchFunctor)(handle)(Routing_Match["int"]))(Control_Alt.alt(Routing_Match.matchAlt)(Routing_Match.lit(fragments.dixon))(Routing_Match.lit(fragments.measurements))))(Routing_Match.optionalMatch(Routing_Match.param("fileId"))))(Routing_Match.optionalMatch(Routing_Match.param("drawingId"))))(Routing_Match.optionalMatch(Routing_Match.param("measurementGroupId")));
})(), Control_Apply.applyFirst(Routing_Match.matchApply)(Data_Functor.map(Routing_Match.matchFunctor)(function (v) {
    return OauthCode.value;
})(Routing_Match.lit("oauth2")))(Routing_Match.lit("redirect")) ])), Data_Functor.map(Routing_Match.matchFunctor)(function (v) {
    return RootRoute.value;
})(Control_Apply.applySecond(Routing_Match.matchApply)(Routing_Match.root)(Routing_Match.end)), Control_Applicative.pure(Routing_Match.matchApplicative)(NotFoundFERoute.value) ]);
module.exports = {
    RootRoute: RootRoute,
    TsRoute: TsRoute,
    ReportingProjectListRoute: ReportingProjectListRoute,
    EstimationProjectsRoute: EstimationProjectsRoute,
    EstimationProjectDetailsFERoute: EstimationProjectDetailsFERoute,
    PortfolioReportFERoute: PortfolioReportFERoute,
    AddCostControlProjectFERoute: AddCostControlProjectFERoute,
    AddEstimationProjectFERoute: AddEstimationProjectFERoute,
    ImportEstimationProjectFERoute: ImportEstimationProjectFERoute,
    ReportsFERoute: ReportsFERoute,
    IncomeFERoute: IncomeFERoute,
    CostControlFERoute: CostControlFERoute,
    InvoicesFERoute: InvoicesFERoute,
    MonthlyReportFERoute: MonthlyReportFERoute,
    CostControlPrintingFERoute: CostControlPrintingFERoute,
    RenameMainCostGroupsFERoute: RenameMainCostGroupsFERoute,
    ProjectDetailsFERoute: ProjectDetailsFERoute,
    PrintingFERoute: PrintingFERoute,
    OrganizationFERoute: OrganizationFERoute,
    EstimationFERoute: EstimationFERoute,
    EstimationReferenceRoute: EstimationReferenceRoute,
    ReferenceEstimationElementsRoute: ReferenceEstimationElementsRoute,
    EstimationBuildingElementsRoute: EstimationBuildingElementsRoute,
    EstimationResourcesRoute: EstimationResourcesRoute,
    NewEstimationResourcesRoute: NewEstimationResourcesRoute,
    EstimationResourcePriceListRoute: EstimationResourcePriceListRoute,
    EstimationReportsRoute: EstimationReportsRoute,
    OfferPageRoute: OfferPageRoute,
    LogsFERoute: LogsFERoute,
    NotificationsFERoute: NotificationsFERoute,
    AdminFERoute: AdminFERoute,
    SuperAdminFERoute: SuperAdminFERoute,
    IntegrationFERoute: IntegrationFERoute,
    ReleaseNotesFERoute: ReleaseNotesFERoute,
    UIDemoFERoute: UIDemoFERoute,
    DixonFERoute: DixonFERoute,
    OauthCode: OauthCode,
    NotFoundFERoute: NotFoundFERoute,
    getRouteAppName: getRouteAppName,
    fragments: fragments,
    appRoute: appRoute,
    eqFERoute: eqFERoute
};
