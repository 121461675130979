import { ConfirmationModal, Header, Text } from "@tocoman/ui";
import { Trans, useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { Measurement } from "ts-bindings/Measurement";
import { NewMeasurementUnitForm } from "./NewMeasurementUnitForm";
import { Subproject } from "ts-bindings/Subproject";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  ICellRendererParams,
  ValueGetterParams,
  ValueSetterParams,
} from "ag-grid-community";
import { MeasurementValue } from "../../../../../ts-bindings/MeasurementValue";
import { isNumber } from "src/client-ts/utils/validations";
import { Table } from "../../../components/Table";
import { ErrorMessage } from "../../../components/ErrorMessage";
import { useMeasurementValidations } from "./useMeasurementValidations";

type MeasurementUnitsProps = {
  projectId?: number;
  onChange: (measurementUnits: Measurement[]) => void;
  measurementUnits: Measurement[];
  subprojects: Subproject[];
  transportFileMeasurements?: boolean;
};

export const MeasurementUnitsTab = ({
  projectId,
  onChange,
  measurementUnits,
  subprojects,
  transportFileMeasurements,
}: MeasurementUnitsProps) => {
  const { t } = useTranslation("projects", { keyPrefix: "details" });

  const [validationError, setValidationError] = useState("");
  const {
    validateMeasurementName,
    measurementError,
  } = useMeasurementValidations();

  useEffect(() => {
    onChange(measurementUnits);
  }, [measurementUnits, onChange]);

  const columnDefs: (ColDef | ColGroupDef)[] = useMemo(() => {
    const onColumnNameChange = (rowId: string | undefined, name: string) => {
      onChange(
        measurementUnits.map((measurementUnit, index) => {
          if (index !== Number(rowId)) {
            return measurementUnit;
          }
          return {
            ...measurementUnit,
            unit: { ...measurementUnit.unit, name },
          };
        })
      );
    };

    const onColumnUnitChange = (rowId: string | undefined, unit: string) => {
      onChange(
        measurementUnits.map((measurementUnit, index) => {
          if (index !== Number(rowId)) {
            return measurementUnit;
          }
          return {
            ...measurementUnit,
            unit: { ...measurementUnit.unit, unit },
          };
        })
      );
    };

    const onColumnSubprojectQuantityChange = (
      rowId: string | undefined,
      newValue: string,
      subprojectId: number
    ) => {
      const subproject = subprojects.find(
        (subproject) => subproject.id === subprojectId
      );

      onChange(
        measurementUnits.map((measurementUnit, index) => {
          if (index !== Number(rowId)) {
            return measurementUnit;
          }

          const measurementValues = createProjectQuantityMeasurement(
            measurementUnit.values,
            subproject?.countInMeasurementTotal ?? false,
            measurementUnit.unit.id
          );

          return {
            ...measurementUnit,
            values: measurementValues.map((measurementValue) => {
              if (
                !measurementValue.subprojectId &&
                subproject?.countInMeasurementTotal
              ) {
                return {
                  ...measurementValue,
                  value: calculateProjectValue(
                    measurementUnit.values,
                    subprojectId,
                    newValue
                  ),
                };
              }
              if (subprojectId !== measurementValue.subprojectId) {
                return measurementValue;
              }
              return {
                ...measurementValue,
                value: parseFloat(newValue),
                measurementId: measurementUnit.unit.id,
                id: measurementValue.id ?? 0,
              };
            }),
          };
        })
      );
    };

    const calculateProjectValue = (
      values: MeasurementValue[],
      subprojectId: number,
      newValue: string
    ) => {
      const measurementsToSum = values.filter((value) => {
        const subproject = subprojects.find(
          (subproject) => subproject.id === value.subprojectId
        );
        return (
          value.subprojectId !== subprojectId &&
          value.subprojectId !== null &&
          subproject?.countInMeasurementTotal
        );
      });

      return (
        measurementsToSum.reduce((acc, value) => acc + value.value, 0) +
        parseFloat(newValue)
      );
    };

    const onRemoveMeasurementUnit = (rowId: string | undefined) => {
      rowId &&
        onChange(
          measurementUnits.filter((_, index) => parseInt(rowId) !== index)
        );
    };

    const deleteConfirmationModal = (
      cellData: ICellRendererParams<Measurement>
    ) => {
      return (
        <ConfirmationModal
          buttonProps={{ variant: "text", color: "danger" }}
          buttonText={t`measurementUnits.tableColumns.delete`}
          confirmText={t`measurementUnits.removeMeasurement.confirm`}
          cancelText={t`measurementUnits.removeMeasurement.cancel`}
          onConfirm={() => onRemoveMeasurementUnit(cellData.node.id)}
          title={t`measurementUnits.removeMeasurement.title`}
          prompt={
            <Trans
              ns="projects"
              i18nKey={"details.measurementUnits.removeMeasurement.prompt"}
              values={{ name: cellData.node.data?.unit.name }}
            />
          }
        />
      );
    };

    const setName = (params: ValueSetterParams) => {
      const [errorType, valid] = validateMeasurementName(
        params.newValue,
        measurementUnits
      );
      if (valid) {
        params.data.unit.name = params.newValue;
        onColumnNameChange(params.node?.id, params.data.unit.name);
        setValidationError("");
        return true;
      }
      setValidationError(measurementError(errorType));
      return false;
    };

    const setUnit = (params: ValueSetterParams) => {
      params.data.unit.unit = params.newValue;
      onColumnUnitChange(params.node?.id, params.data.unit.unit);
      return true;
    };

    const setSubprojectQuantity = (
      params: ValueSetterParams,
      subprojectId: number
    ) => {
      const newValue = params.newValue.replace(",", ".");
      if (!isNumber(newValue)) {
        return false;
      }
      const existingValueIndex = params.data.values.findIndex(
        (value: MeasurementValue) => value.subprojectId === subprojectId
      );

      if (existingValueIndex === -1) {
        const pushedArrayLength = params.data.values.push({
          value: newValue,
          subprojectId,
        });
        onColumnSubprojectQuantityChange(
          params.node?.id,
          params.data.values[pushedArrayLength - 1].value,
          subprojectId
        );
        return true;
      }

      params.data.values[existingValueIndex].value = newValue;
      onColumnSubprojectQuantityChange(
        params.node?.id,
        params.data.values[existingValueIndex].value,
        subprojectId
      );
      return true;
    };

    const getMeasurementColorCode = (params: CellClassParams<Measurement>) => {
      if (params.data?.unit.id !== null && transportFileMeasurements) {
        return { color: "#11812c" };
      }
    };

    const columns: (ColDef<Measurement> | ColGroupDef<Measurement>)[] = [
      {
        field: "unit.name",
        headerName: t`measurementUnits.tableColumns.name`,
        enableRowGroup: true,
        type: "editable",
        valueSetter: setName,
        cellStyle: getMeasurementColorCode,
      },
      {
        field: "unit.unit",
        headerName: t`measurementUnits.tableColumns.unit`,
        type: "editable",
        width: 140,
        valueSetter: setUnit,
      },
      {
        headerName: t`measurementUnits.tableColumns.projectQuantity`,
        valueGetter: getProjectQuantity,
        type: ["numericColumn", "twoDecimal"],
        width: 140,
      },
      {
        colId: "delete",
        headerName: "",
        width: 89,
        sortable: false,
        lockVisible: true,
        lockPinned: true,
        pinned: "right" as const,
        resizable: false,
        cellRenderer: deleteConfirmationModal,
      },
    ];

    const subprojectColumns: ColDef<Measurement>[] = subprojects.map(
      (subproject) => {
        const header = `${subproject.code}${
          subproject.countInMeasurementTotal ? "*" : ""
        }`;
        return {
          headerName: header,
          valueGetter: (params: ValueGetterParams) =>
            getSubprojectValue(params, subproject.id),
          width: 140,
          type: ["numericColumn", "twoDecimal", "editable"],
          valueSetter: (params: ValueSetterParams) => {
            return setSubprojectQuantity(params, subproject.id);
          },
        };
      }
    );

    return columns.concat(subprojectColumns);
  }, [
    t,
    subprojects,
    onChange,
    measurementUnits,
    validateMeasurementName,
    measurementError,
    transportFileMeasurements,
  ]);

  const defaultColDef: ColDef<Measurement> = useMemo(
    () => ({
      flex: 1,
      resizable: true,
    }),
    []
  );

  const onHandleSubmit = (formValue: Measurement) => {
    onChange([...measurementUnits, formValue]);
  };

  return (
    <>
      <Header
        title={t`tabs.measurementUnits`}
      >{t`tabDescriptions.measurementUnits`}</Header>

      {transportFileMeasurements ? (
        <Text text={t`measurementUnits.transportColorInfo`} />
      ) : null}

      <div className="ag-theme-alpine w-full h-128">
        <Table<Measurement>
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowData={measurementUnits}
          stopEditingWhenCellsLoseFocus={true}
          className={"h-full"}
          context={{ subprojects }}
        />
      </div>
      {validationError !== "" && (
        <ErrorMessage errorMessage={validationError} />
      )}
      <div>
        <NewMeasurementUnitForm
          onNewMeasurement={onHandleSubmit}
          projectId={projectId}
          subprojects={subprojects}
          measurementUnits={measurementUnits}
        />
      </div>
      <Text text={t`measurementUnits.countInMeasurementInfo`} />
    </>
  );
};

const getProjectQuantity = (params: ValueGetterParams<Measurement>) => {
  const { subprojects } = params.context;
  if (!subprojects || !params.data) {
    return null;
  }

  const projectMeasurement = params.data.values.find(
    (value) => !value.subprojectId
  );
  return projectMeasurement?.value ?? 0;
};

function createProjectQuantityMeasurement(
  measurementValues: MeasurementValue[],
  countInMeasurementTotal: boolean,
  unitId: number | null
): MeasurementValue[] {
  const projectMeasurementField = measurementValues.find(
    (value) => !value.subprojectId
  );

  if (!projectMeasurementField && countInMeasurementTotal) {
    return measurementValues.concat({
      value: 0,
      measurementId: unitId,
      subprojectId: null,
      id: 0,
    });
  }
  return measurementValues;
}
export function getSubprojectValue(
  params: ValueGetterParams<Measurement>,
  subprojectId: number
) {
  const subprojectValue = params.data?.values.find(
    (value: MeasurementValue) => subprojectId === value.subprojectId
  );
  return subprojectValue ? subprojectValue.value : 0;
}
