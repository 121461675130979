// Generated by purs version 0.14.5
"use strict";
var Box = require("../Box/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var sidebarStyles = Box.boxStyle([ Box.FlexColumn.value ]);
var referenceLibrarySidepanelStyles = Box.boxStyle([ Box.FlexRow.value, Box.Height.create(new TacoConstants.CustomSize("100%")) ]);
module.exports = {
    referenceLibrarySidepanelStyles: referenceLibrarySidepanelStyles,
    sidebarStyles: sidebarStyles
};
