// Generated by purs version 0.14.5
"use strict";
var Actions = require("../Actions/index.js");
var Box = require("../Box/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Events = require("../React.Basic.DOM.Events/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Emotion = require("../React.Basic.Emotion/index.js");
var SuperAdmin_Styles = require("../SuperAdmin.Styles/index.js");
var TacoAutocomplete = require("../TacoAutocomplete/index.js");
var TacoButton = require("../TacoButton/index.js");
var TacoButton_Types = require("../TacoButton.Types/index.js");
var TacoChip = require("../TacoChip/index.js");
var TacoConstants = require("../TacoConstants/index.js");
var TacoInput = require("../TacoInput/index.js");
var TacoModal = require("../TacoModal/index.js");
var TacoText = require("../TacoText/index.js");
var TacoText_Types = require("../TacoText.Types/index.js");
var TofuHooks = require("../TofuHooks/index.js");
var Types_Organization = require("../Types.Organization/index.js");
var component = TofuHooks.mkHookComponent("EditUser")(function (v) {
    return function __do() {
        var v1 = TofuHooks.useState(v.user.organizations)();
        var orgs = Data_Array.sort(Types_Organization.ordOrganization)(v.user.organizations);
        var orgChip = function (v2) {
            return Box.box("chipWrapper")([ new Box.MarginY(TacoConstants.XXS.value) ])([ TacoChip.component()()({
                contents: [ React_Basic_DOM.text(v2) ],
                onRemove: Data_Maybe.Just.create(v1.value1(Data_Array["delete"](Types_Organization.eqOrganization)(v2))),
                testId: "org-chip-" + (v2 + "-remove")
            }) ]);
        };
        var emailInput = React_Basic_Emotion.element(React_Basic_DOM_Generated["div'"]())({
            className: "emailInput",
            css: SuperAdmin_Styles.emailInputsStyles,
            children: [ TacoInput.stringInput()()(Data_Eq.eqString)({
                label: "S\xe4hk\xf6posti",
                placeholder: "nimi@tocoman.com",
                value: v.user.userData.email
            }) ]
        });
        var doEditUser = function __do() {
            v.appDispatch(new Actions.SetUserOrganizationsRequest({
                organizations: v1.value0,
                userId: v.user.id
            }))();
            return v.onClose();
        };
        var modalActions = TacoModal.modalActions({
            contents: [ TacoButton.component()()({
                onClick: Data_Maybe.Just.create(v.onClose),
                text: "Peruuta",
                testId: "edit-user-cancel"
            }), TacoButton.component()()({
                onClick: Data_Maybe.Just.create(doEditUser),
                buttonStyle: TacoButton_Types.Filled.value,
                text: "Tallenna",
                testId: "edit-user-submit"
            }) ]
        });
        var addToOrganization = Box.box("addToOrgWrapper")([ Box.FlexColumn.value ])([ TacoText.component()()({
            text: "Organisaatiot",
            weight: TacoText_Types.Bold.value,
            variant: new TacoText_Types.Heading(3),
            gutterBottom: true
        }), Box.box("orgChipWrapper")([ Box.AlignStart.value, Box.FlexColumn.value ])([ Box.box("orgChipList")([ Box.GridColumn.value, new Box.Gap(TacoConstants.XS.value), new Box.PaddingBottom(TacoConstants.M.value) ])(Data_Functor.map(Data_Functor.functorArray)(orgChip)(v1.value0)), TacoAutocomplete.component()()({
            sections: [ {
                text: Data_Maybe.Nothing.value,
                list: Data_Functor.map(Data_Functor.functorArray)(function (v2) {
                    return {
                        id: v2,
                        text: v2
                    };
                })(Data_Array.difference(Types_Organization.eqOrganization)(v.organizations)(orgs))
            } ],
            clearButton: false,
            selected: Data_Maybe.Nothing.value,
            onSelect: function (item) {
                if (item instanceof Data_Maybe.Just) {
                    return v1.value1(Data_Array.insert(Types_Organization.ordOrganization)(item.value0.id));
                };
                if (item instanceof Data_Maybe.Nothing) {
                    return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
                };
                throw new Error("Failed pattern match at EditUser (line 63, column 38 - line 65, column 41): " + [ item.constructor.name ]);
            },
            placeholder: "Lis\xe4\xe4 organisaatio",
            testId: "edit-user-org-dropdown"
        }) ]) ]);
        var modalBody = TacoModal.modalBody({
            contents: [ addToOrganization, emailInput ]
        });
        return React_Basic_DOM_Generated.div_([ TacoModal.component()()({
            onClose: v.onClose,
            isActive: true,
            heading: "Muokataan k\xe4ytt\xe4j\xe4\xe4: " + v.user.userData.email,
            contents: [ React_Basic_DOM_Generated.form()({
                className: "add-users-form",
                onSubmit: React_Basic_DOM_Events.capture_(doEditUser),
                children: [ modalBody, modalActions ]
            }) ]
        }) ]);
    };
});
module.exports = {
    component: component
};
