// Generated by purs version 0.14.5
"use strict";
var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Variant_Internal = require("../Data.Variant.Internal/index.js");
var DateFnsFFI = require("../DateFnsFFI/index.js");
var Effect_Unsafe = require("../Effect.Unsafe/index.js");
var Formatters = require("../Formatters/index.js");
var Hash = require("../Hash/index.js");
var Kishimen = require("../Kishimen/index.js");
var TofuJSON = require("../TofuJSON/index.js");
var AlertError = (function () {
    function AlertError() {

    };
    AlertError.value = new AlertError();
    return AlertError;
})();
var AlertWarning = (function () {
    function AlertWarning() {

    };
    AlertWarning.value = new AlertWarning();
    return AlertWarning;
})();
var AlertInfo = (function () {
    function AlertInfo() {

    };
    AlertInfo.value = new AlertInfo();
    return AlertInfo;
})();
var unAlert = function (r) {
    return r.text;
};
var mkAlert = function (level) {
    return function (text) {
        var time = Effect_Unsafe.unsafePerformEffect(DateFnsFFI.getTime);
        var formattedTime = Formatters.formatDateTime(time);
        return {
            level: level,
            text: text,
            time: time,
            formattedTime: formattedTime,
            id: Hash.sha1(text + formattedTime)
        };
    };
};
var genericAlertLevel = {
    to: function (x) {
        if (x instanceof Data_Generic_Rep.Inl) {
            return AlertError.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
            return AlertWarning.value;
        };
        if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
            return AlertInfo.value;
        };
        throw new Error("Failed pattern match at Types.Alert (line 50, column 1 - line 50, column 58): " + [ x.constructor.name ]);
    },
    from: function (x) {
        if (x instanceof AlertError) {
            return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
        };
        if (x instanceof AlertWarning) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
        };
        if (x instanceof AlertInfo) {
            return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value));
        };
        throw new Error("Failed pattern match at Types.Alert (line 50, column 1 - line 50, column 58): " + [ x.constructor.name ]);
    }
};
var tofuJSONAlertLevel = {
    writeImpl: (function () {
        var $17 = TofuJSON.writeImpl(TofuJSON.tofuJSONVariant()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "AlertError";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "AlertInfo";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "AlertWarning";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil))))(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "AlertError";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "AlertInfo";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "AlertWarning";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))));
        var $18 = Kishimen.genericSumToVariant(genericAlertLevel)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "AlertError";
            }
        })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "AlertWarning";
            }
        })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "AlertInfo";
            }
        })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "AlertWarning";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "AlertInfo";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "AlertError";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "AlertWarning";
            }
        }))({
            reflectSymbol: function () {
                return "AlertInfo";
            }
        })));
        return function ($19) {
            return $17($18($19));
        };
    })(),
    readImpl: (function () {
        var $20 = Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Kishimen.variantToGenericSum(genericAlertLevel)(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "AlertError";
            }
        })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantSum(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "AlertWarning";
            }
        })()(Kishimen.genericSumToVariantArgNoArguments))(Kishimen.genericSumToVariantConstructor({
            reflectSymbol: function () {
                return "AlertInfo";
            }
        })()(Kishimen.genericSumToVariantArgNoArguments))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "AlertWarning";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "AlertInfo";
            }
        })))()()()()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "AlertError";
            }
        }))()(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsCons(Data_Variant_Internal.variantTagsNil)({
            reflectSymbol: function () {
                return "AlertWarning";
            }
        }))({
            reflectSymbol: function () {
                return "AlertInfo";
            }
        }))));
        var $21 = TofuJSON.readImpl(TofuJSON.tofuJSONVariant()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "AlertError";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "AlertInfo";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantCons({
            reflectSymbol: function () {
                return "AlertWarning";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.readVariantNil))))(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "AlertError";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "AlertInfo";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.consWriteForeignVariant({
            reflectSymbol: function () {
                return "AlertWarning";
            }
        })(TofuJSON.readRecord()(TofuJSON.readFieldsNil)(TofuJSON.nilWriteForeignFields))()(TofuJSON.nilWriteForeignVariant)))));
        return function ($22) {
            return $20($21($22));
        };
    })()
};
var eqAlertLevel = {
    eq: function (x) {
        return function (y) {
            if (x instanceof AlertError && y instanceof AlertError) {
                return true;
            };
            if (x instanceof AlertWarning && y instanceof AlertWarning) {
                return true;
            };
            if (x instanceof AlertInfo && y instanceof AlertInfo) {
                return true;
            };
            return false;
        };
    }
};
var ordAlertLevel = {
    compare: function (x) {
        return function (y) {
            if (x instanceof AlertError && y instanceof AlertError) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AlertError) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AlertError) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AlertWarning && y instanceof AlertWarning) {
                return Data_Ordering.EQ.value;
            };
            if (x instanceof AlertWarning) {
                return Data_Ordering.LT.value;
            };
            if (y instanceof AlertWarning) {
                return Data_Ordering.GT.value;
            };
            if (x instanceof AlertInfo && y instanceof AlertInfo) {
                return Data_Ordering.EQ.value;
            };
            throw new Error("Failed pattern match at Types.Alert (line 19, column 1 - line 19, column 48): " + [ x.constructor.name, y.constructor.name ]);
        };
    },
    Eq0: function () {
        return eqAlertLevel;
    }
};
var alertLevelToString = function (x) {
    if (x instanceof AlertError) {
        return "Virhe";
    };
    if (x instanceof AlertWarning) {
        return "Varoitus";
    };
    if (x instanceof AlertInfo) {
        return "Info";
    };
    throw new Error("Failed pattern match at Types.Alert (line 22, column 24 - line 25, column 22): " + [ x.constructor.name ]);
};
module.exports = {
    AlertError: AlertError,
    AlertWarning: AlertWarning,
    AlertInfo: AlertInfo,
    alertLevelToString: alertLevelToString,
    mkAlert: mkAlert,
    unAlert: unAlert,
    eqAlertLevel: eqAlertLevel,
    ordAlertLevel: ordAlertLevel,
    genericAlertLevel: genericAlertLevel,
    tofuJSONAlertLevel: tofuJSONAlertLevel
};
