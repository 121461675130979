import { UpdateActionState } from "ts-bindings/UpdateActionState";
import { WorkerRequest } from "ts-bindings/WorkerRequest";
import { StateNodes } from "../utils/state";
import { KeysMatching } from "../utils/typescript";
import { useWorkerRequest } from "./useWorkerRequest";
import { useWorkerState } from "./useWorkerState";

/**
 * State keys that contain an UpdateActionState
 */
type UpdateActionStateKeys = KeysMatching<StateNodes, UpdateActionState | null>;

/**
 * React hook for dispatching actions to the worker.
 *
 * TODO: restructure so that the (updateActionStateKey, mkRequest) pair is more
 * tightly coupled, and not up to the caller to get right.
 */
export const useWorkerAction = <
  K extends UpdateActionStateKeys,
  T extends unknown[]
>(
  updateActionStateKey: K,
  mkRequest: (...args: T) => WorkerRequest | undefined
): [UpdateActionState["status"], (...args: T) => void] => {
  const status =
    // There may be a way to avoid using `any` here, but this should be fine
    // since UpdateActionStateKeys already only contains state keys that resolve
    // to UpdateActionState values.

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (useWorkerState(updateActionStateKey) as UpdateActionState | null)
      ?.status ?? "Idle";

  const callback = useWorkerRequest(mkRequest);

  return [status, callback];
};
